import { createSelector } from 'reselect';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { addProduct } from 'actions/ShoppingCart/addProduct';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import {
  getLastProductIndex,
  getProductInOrderByIndex,
} from 'reducers/ShoppingCart';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getProductsUniversal } from 'actions/productsDB';
import { Product } from 'types/Product';
import useProducts from 'utils/hooks/useProducts';

import * as components from './components';
import ComponentConfiguration from './components/ComponentConfiguration';

type GPUresult = {
  products: Product[];
  productsDict: Record<string | number, Product>;
  total: number;
};
const preCoreWorkflow: PosPlugin = {
  id: 'pre-core-workflow',
  name: 'PNP - returning old core',
  getStatus: () => ({ type: 'valid', message: '' }),
  info:
    'When a customer has a core to return, they are exempted from the core charge when purchasing a new part which has a core charge.',
  keywords: ['pnp', 'core-charge', 'return'],
  ComponentConfiguration,
  combineConfiguration: (...args) => {
    return args[0];
  },
  onAddProduct: {
    after: (params, { product }) => async (dispatch, getState) => {
      const state = getState();
      const {
        products: [originalProduct],
      } = ((await dispatch(
        getProductsUniversal({ productID: product.productID }),
      )) as unknown) as GPUresult;
      // if containerProd.productGroupID is included in product groups, then do the following else return
      const productOrderIndex = getLastProductIndex(getState());
      const { coreGroupIDs = [] } =
        getPluginConfiguration<{
          coreGroupIDs: string[];
        }>('pre-core-workflow')(state) ?? {};

      if (Number(originalProduct?.containerID)) {
        const {
          products: [containerProd],
        } = ((await dispatch(
          getProductsUniversal({ productID: originalProduct.containerID }),
        )) as unknown) as GPUresult;
        if (coreGroupIDs.includes(String(containerProd.groupID))) {
          new Promise((resolve, reject) =>
            dispatch(
              openModalPage({
                component: modalPages.pluginModal,
                isPopup: true,
                modalClassName: 'confirmation',
                props: {
                  name: 'ConfirmCoreReturn',
                  resolve,
                  reject,
                  productName: originalProduct.name,
                },
              }),
            ),
          )
            .then(() => {
              dispatch(
                addProduct({
                  productID: originalProduct.containerID,
                  parentRowID: productOrderIndex,
                  amount: -1,
                }),
              );
            })
            .catch(() => undefined)
            .finally(() => dispatch(previousModalPage()));
        }
      }
    },
  },
  onUpdateOrderAmount: {
    /**
     * Pre-core (negative core on positive parent) amount can only be
     * between 0 and -n, where n is the amount of the parent
     * Smaller than -n is not allowed because you can't pre-core for more cores than you are purchasing
     * and larger than 0 is not allowed because there are already automatically n cores on the sale
     */
    before: ({ amount, orderIndex }) => async (dispatch, getState) => {
      const order = getProductInOrderByIndex(orderIndex)(getState());
      const parent = getProductInOrderByIndex(order?.parentRowID)(getState());
      const { coreGroupIDs } = getPluginConfiguration<{
        coreGroupIDs: string[];
      }>('pre-core-workflow')(getState()) ?? { coreGroupIDs: [] };
      const {
        products: [productToCheck],
      } = ((await dispatch(
        getProductsUniversal({ productID: order.productID }),
      )) as unknown) as GPUresult;
      const isCore = coreGroupIDs.includes(String(productToCheck.groupID));

      if (!isCore) return;
      if (!parent) {
        console.warn('Edited pre-core product has no parent!');
        return;
      }

      const limit = -Math.abs(parent.amount);
      const validAmount = R.clamp(limit, 0, -Math.abs(Number(amount)));

      if (amount === validAmount) return;

      throw new Error(
        `Invalid amount ${amount} for precore at ${orderIndex}, restarting with ${validAmount}`,
      );
    },
  },
  components,
  UIQuantityActionsContainer: ({ children, product }) => {
    const { coreGroupIDs } = useSelector(
      getPluginConfiguration<{
        coreGroupIDs: string[];
      }>('pre-core-workflow') ?? { coreGroupIDs: [] },
    );
    const {
      products: [productToCheck],
    } = useProducts({ productID: product.productID });
    const isCore = coreGroupIDs.includes(String(productToCheck.groupID));

    if (isCore) return null;
    return children;
  },
  selectorOverrides: {
    getOrders: base =>
      createSelector(base, orders =>
        // Prevent return of the positive half of a core exchange
        // (same parent, opposite signs)
        R.map(row => {
          const returnedCoreProd = orders.find(
            p =>
              p.productID === row.productID &&
              p.parentRowID === row.parentRowID &&
              row.originalAmount > 0 &&
              p.originalAmount < 0,
          );
          if (!returnedCoreProd) return row;

          return R.assoc(
            'remainingAmount',
            Number(row.remainingAmount) +
              Number(returnedCoreProd.originalAmount),
          )(row);
        })(orders),
      ),
  },
};

export default preCoreWorkflow;
