import { getLoggedInEmployeeID } from 'reducers/Login';
import { OfflinePrintParams } from 'actions/integrations/printer/types';
import {
  getCurrencyFormatter,
  getCurrencyFormatterNoSymbol,
  getCurrencySymbol,
  getFallbackLanguagesFor,
  getSetting,
} from 'reducers/configs/settings';
import prepareOfflineData from 'actions/integrations/printer/prepareOfflineData';
import { prepareReceiptData } from 'actions/integrations/printer/preparePrintoutData';
import { PosPlugin } from 'plugins/plugin';
import { getAllPayments } from 'reducers/Payments';
import { CancelActionOverrideError } from 'plugins/pluginUtils';
import { getLastSalesDocument } from 'reducers/sales';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { AppliedPromotionRecordResponse } from 'types/Promotions';
import { createDebugConsole } from 'utils/debug';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';

import {
  fromOfflineData,
  fromOnlineData,
  generateReceipt,
} from './generateReceipt';
import { mmReceiptTranslations } from './pieces/translations/translations';
import {
  assignGivexCardBalances,
  getSpecialPromotionPriceLists,
} from './utils';

const Console = createDebugConsole('M&M').extend('printCustomReceipt');
/**
 * Replace default POS printing with a custom receipt, that supports both online and offline mode
 *
 * The offline mode receipt has an extra disclaimer up top
 */
export const generateCustomReceipt: Required<
  Required<PosPlugin>['onGenerateReceipt']
>['before'] = p => async (dispatch, getState) => {
  Console.log('Starting to generate MM receipt', p);
  const translations = getFallbackLanguagesFor('receipt')(getState())
    .map(l => mmReceiptTranslations[l])
    .find(a => a);
  if (!translations) {
    const options = Object.keys(mmReceiptTranslations).join(',');
    const preferences = getFallbackLanguagesFor('receipt')(getState()).join(
      ',',
    );

    throw new Error(
      `Unable to print receipt, no matching translations found. Available options:${options} but is configured to use: ${preferences}`,
    );
  }
  const currencyFormatterNoSymbol = getCurrencyFormatterNoSymbol(getState());
  const currencyFormatter = getCurrencyFormatter(getState());
  const currencySymbol = getCurrencySymbol(getState());
  const CURR = {
    ...currencyFormatterNoSymbol,
    symbol: currencySymbol,
    stringifyWithSymbol: currencyFormatter,
  };

  // M&M request: always show logged in employee on receipt
  const loggedInEmployeeId = getLoggedInEmployeeID(getState());
  const loggedInEmployee = getEmployeeById(loggedInEmployeeId)(getState());

  try {
    Console.debug('Params imply online mode, attempting to fetch promotions');
    const promotionRecords = await doClientRequest<
      AppliedPromotionRecordResponse
    >({
      request: 'getAppliedPromotionRecords',
      invoiceIDs: p.salesDocument.id ?? (p.salesDocument as any).invoiceID,
    });
    const promotions = (p.salesDocument.rows as {
      stableRowID: number;
    }[]).map(row =>
      promotionRecords.filter(
        p => String(p.stableRowID) === String(row.stableRowID),
      ),
    );
    Console.debug('Got', promotionRecords, 'and parsed into', promotions);

    Console.debug(
      'Grouping applied promotions by customer group price lists and event price lists',
    );
    const specialPriceLists = await dispatch(
      getSpecialPromotionPriceLists(promotions),
    );
    Console.debug('Grouped price list ids:', specialPriceLists);

    Console.debug('Letting core POS generate receipt data');
    const receiptData = await dispatch(prepareReceiptData(p));

    Console.debug('Adding Givex card balances to payments in receipt data');
    await assignGivexCardBalances(
      receiptData,
      p.salesDocument.id ?? (p.salesDocument as any).invoiceID,
      CURR,
    );

    Console.debug('Got receipt data', receiptData, 'extracting mm data');
    const mmReceiptData = fromOnlineData(
      receiptData,
      promotions,
      specialPriceLists,
      CURR,
      loggedInEmployee,
      getSetting('invoiceExtraFooterLine')(getState()),
      getReasonCodes(REASONS.DISCOUNT)(getState()),
      getAllPaymentTypes(getState()),
    );

    Console.debug('got mm data', mmReceiptData, 'generating receipt printout');
    const printout = generateReceipt(
      mmReceiptData,
      translations,
      { ...p.options },
      CURR,
    );

    Console.log('Done generating MM receipt (online)', printout);
    throw new CancelActionOverrideError('M&M custom printing', {
      hasActualReportsTemplate: false,
      patchScript: printout,
    });
  } catch (e) {
    if (e instanceof CancelActionOverrideError) throw e;
    Console.warn(e);
    Console.debug('Online print failed with above error, trying offline print');

    const lastInfo = getLastSalesDocument(getState());
    const isLastSale =
      Number(lastInfo.salesDoc.invoiceID) ===
      Number((p.salesDocument as any).invoiceID);
    const payments = isLastSale
      ? lastInfo.payments
      : getAllPayments(getState());
    Console.debug('Loaded last receipt data from memory', {
      lastInfo,
      isLastSale,
      payments,
    });

    Console.debug('Letting core POS generate offline data');
    const offlineData = await dispatch(
      prepareOfflineData(p as OfflinePrintParams),
    );

    Console.debug('got', offlineData, 'extracting mm data');
    const mmReceiptData = fromOfflineData(
      offlineData,
      payments,
      CURR,
      loggedInEmployee,
      getSetting('invoiceExtraFooterLine')(getState()),
      getAllPaymentTypes(getState()),
    );

    Console.debug('got mm data', mmReceiptData, 'generating receipt printout');
    const printout = generateReceipt(
      mmReceiptData,
      translations,
      p.options,
      CURR,
    );

    Console.log('Done generating MM receipt (offline)', printout);
    throw new CancelActionOverrideError('M&M custom printing', {
      hasActualReportsTemplate: false,
      patchScript: printout,
    });
  }
};
