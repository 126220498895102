/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import axios, { AxiosResponse } from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

import {
  CheckBalanceParams,
  CheckBalanceResponse,
  LocalStorageNSW,
  RedeemVoucherParams,
  RedeemVoucherResponse,
  SessionTokenParams,
  SessionTokenResponse,
  TestVoucherParams,
  TestVoucherResponse,
  VoidRedemptionParams,
  VoidRedemptionResponse,
} from '../types';
import { Configuration } from '../../types';

const voucherV2Link =
  'https://api-psm.g.testservicensw.net/var-voucher-redemptions-external-api/v2/vouchers/BTS';

export async function generateNSWSessionToken(
  params: Partial<SessionTokenParams>,
): Promise<SessionTokenResponse | undefined> {
  if (!params?.client_id || !params.client_secret) {
    throw new TypeError(
      'Failed to generate an access token. Insufficient credentials',
    );
  }
  const formData = new URLSearchParams();
  formData.append('grant_type', 'client_credentials');
  formData.append('client_id', params.client_id);
  formData.append('client_secret', params.client_secret);

  return axios
    .post(
      `https://api-psm.g.testservicensw.net/v1/identity/oauth/client-credentials/token`,
      formData,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
          accept: 'application/json',
        },
      },
    )
    .then((res: AxiosResponse<SessionTokenResponse>) => res.data);
}

/**
 * @deprecated Only used for testing
 */
export async function createTestVoucher({
  params,
  config,
}: {
  params?: TestVoucherParams;
  config: Configuration;
}): Promise<TestVoucherResponse | undefined> {
  const lsi = localStorage.getItem('NSW'); // LocalStorageInfo
  const plsi: LocalStorageNSW = JSON.parse(lsi || '{}'); // ParsedLocalStorageInfo
  const sessionKey = plsi.access_token;
  if (!sessionKey || !config?.businessID) {
    throw new TypeError(
      'Test voucher creation failed - config or session key missing',
    );
  }
  const proxy = getMSEndpointFromLS('nsw');
  const reqInstance = axios.create({
    headers: {
      Authorization: `Bearer ${sessionKey}`,
      'x-business-id': config.businessID,
    },
  });

  const body: TestVoucherParams = {
    tempCode: params?.tempCode ?? false,
    voucherType: params?.voucherType ?? 'BTS',
  };

  return reqInstance
    .get(
      `${proxy}?redirect=${voucherV2Link}/sample?tempCode=${body.tempCode}&voucherType=${body.voucherType}`,
    )
    .then((res: AxiosResponse<TestVoucherResponse>) => res.data);
}

export async function checkBalance({
  params,
  config,
}: {
  params: CheckBalanceParams;
  config: Configuration;
}): Promise<CheckBalanceResponse | undefined> {
  if (!params.code) {
    throw new TypeError(
      'Cannot check balance - missing required parameter "Code"',
    );
  }
  const lsi = localStorage.getItem('NSW'); // LocalStorageInfo
  const plsi: LocalStorageNSW = JSON.parse(lsi || '{}'); // ParsedLocalStorageInfo
  const sessionKey = plsi.access_token;

  if (!sessionKey) {
    throw new TypeError(
      'Cannot check balance - missing required parameter "session key"',
    );
  }

  const data = {
    code: params.code,
    voucherType: params.voucherType ?? 'BTS',
  };

  const proxy = getMSEndpointFromLS('nsw');
  const reqInstance = axios.create({
    headers: {
      'x-business-id': config?.businessID,
      Authorization: `Bearer ${sessionKey}`,
    },
  });

  return reqInstance
    .get(
      `${proxy}?redirect=${voucherV2Link}/balance?code=${data.code}&voucherType=${data.voucherType}`,
    )
    .then((res: AxiosResponse<CheckBalanceResponse>) => res.data)
    .catch(err => {
      console.error('Check balance failed. Error:', err);
      // Generating UI friendly error
      const newErrMsg =
        err?.response?.data?.message?.message ?? err?.response?.data?.message;
      if (newErrMsg) throw new Error(newErrMsg);
      throw err;
    });
}

export async function redeemVoucher({
  params,
  config,
}: {
  params: RedeemVoucherParams;
  config: Configuration;
}): Promise<RedeemVoucherResponse | undefined> {
  const lsi = localStorage.getItem('NSW'); // LocalStorageInfo
  const plsi: LocalStorageNSW = JSON.parse(lsi || '{}'); // ParsedLocalStorageInfo
  const sessionKey = plsi.access_token;

  if (!sessionKey) {
    throw new TypeError(
      'Cannot redeem voucher - missing required parameter "Session Key"',
    );
  }
  if (!params.voucherCode) {
    throw new TypeError(
      'Cannot redeem voucher - missing required parameter "Voucher Code"',
    );
  }
  if (!params.externalReference) {
    throw new TypeError(
      'Cannot redeem voucher - missing required parameter "External reference"',
    );
  }
  if (!params.providerIdentifier) {
    throw new TypeError(
      'Cannot redeem voucher - missing required parameter "Provider Identifier"',
    );
  }
  if (!params.amount) {
    throw new TypeError(
      'Cannot redeem voucher - missing required parameter "Amount"',
    );
  }
  if (!params.totalAmount) {
    throw new TypeError(
      'Cannot redeem voucher - missing required parameter "Total Amount"',
    );
  }

  const proxy = getMSEndpointFromLS('nsw');

  const reqInstance = axios.create({
    headers: {
      'x-business-id': config?.businessID,
      Authorization: `Bearer ${sessionKey}`,
      'Content-Type': 'application/json',
    },
  });

  return reqInstance
    .post(`${proxy}?redirect=${voucherV2Link}/redeem`, params)
    .then((res: AxiosResponse<RedeemVoucherResponse>) => res.data)
    .catch(err => {
      console.error('Voucher redemption failed. Error:', err);
      // Generating UI friendly error
      const newErrMsg =
        err?.response?.data?.message?.message ?? err?.response?.data?.message;
      if (newErrMsg) throw new Error(newErrMsg);
      throw err;
    });
}

export async function voidRedemption({
  params,
  config,
}: {
  params: VoidRedemptionParams;
  config: Configuration;
}): Promise<VoidRedemptionResponse | undefined> {
  if (!params.transactionCode) {
    throw new TypeError(
      'Cannot void redemeption - missing required parameter "Transaction code"',
    );
  }
  const lsi = localStorage.getItem('NSW'); // LocalStorageInfo
  const plsi: LocalStorageNSW = JSON.parse(lsi || '{}'); // ParsedLocalStorageInfo
  const sessionKey = plsi.access_token;

  if (!sessionKey) {
    throw new TypeError(
      'Cannot void redemeption - missing required parameter "Session Key"',
    );
  }

  const proxy = getMSEndpointFromLS('nsw');

  return axios
    .get(
      `${proxy}?redirect=${voucherV2Link}/redeem/${params.transactionCode}/void`,
      {
        headers: {
          'x-business-id': config?.businessID,
          Authorization: `Bearer ${sessionKey}`,
        },
      },
    )
    .then((res: AxiosResponse<VoidRedemptionResponse>) => res.data)
    .catch(err => {
      console.error('Void redemption failed. Error:', err);
      // Generating UI friendly error
      const newErrMsg =
        err?.response?.data?.message?.message ?? err?.response?.data?.message;
      if (newErrMsg) throw new Error(newErrMsg);
      throw err;
    });
}
