import { mapBirthday } from './common';

const mapAddress = address =>
  address.map(
    ({
      addedTime,
      addressLine1,
      addressLine2,
      addressTypeID,
      changedTime,
      city,
      country,
      customerId,
      id,
      postalCode,
      state,
    }) => ({
      typeID: `${addressTypeID}`,
      address: addressLine1,
      address2: addressLine2,
      city,
      country,
      addressID: id,
      postalCode,
      state,
    }),
  );

const mapCustomer = ({
  addresses,
  birthdayDay,
  birthdayMonth,
  birthdayYear,
  companyName,
  countryID,
  createdAt,
  customerCardNumber,
  email,
  emailConsentState,
  emailConsentStateTimestamp,
  emailOptOut,
  firstName,
  gender,
  groupID,
  homeStoreID,
  householdCode,
  id,
  lastName,
  peopleInHousehold,
  signUpStoreID,
  updatedAt,
}) => ({
  id,
  birthday: mapBirthday(birthdayYear, birthdayMonth, birthdayDay),
  addresses: mapAddress(addresses),
  customerCardNumber,
  customerID: id,
  customerType: firstName || lastName ? 'PERSON' : 'COMPANY',
  firstName,
  companyName,
  added: createdAt,
  groupID,
  countryID,
  signUpStoreID,
  lastName,
  gender,
  fullName: firstName || lastName ? `${firstName}, ${lastName}` : companyName,
  lastModified: updatedAt,
  email,
});
const mapCustomers = customers => {
  return customers.map(customer => mapCustomer(customer));
};

export default ({
  data: {
    result: { customers },
  },
}) => mapCustomers(customers);
