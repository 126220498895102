import React from 'react';
import { useSelector } from 'react-redux';

import { ShortcutScope } from 'utils/hooks/keyboard/useShortcut';

import { getShouldLock } from '../redux/selectors';

// @ts-ignore
const DisabledShortcuts: React.FC = ({ children }) => {
  const shouldLock = useSelector(getShouldLock);
  if (!shouldLock) return children;

  return (
    <ShortcutScope defaultPriority={10} surpress>
      {children}
    </ShortcutScope>
  );
};

export default DisabledShortcuts;
