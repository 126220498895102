import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { getTotalNet } from 'reducers/ShoppingCart';
import { suretaxReducer } from 'plugins/suretax/redux';
import { PosPlugin } from 'plugins/plugin';

/**
 * Hide when suretax is not calculated
 *
 * If in debug mode, show expected tax from suretax as a comparison
 */
export const UITableTotalRow: PosPlugin['UITableTotalRow'] = ({ children }) => {
  const netTotal = Number(useSelector(getTotalNet));
  const taxes = Number(useSelector(suretaxReducer.getCurrent)?.TotalTax);
  // Hide if not calculated
  if (Number.isNaN(taxes)) return null;
  // Otherwise return default if debug is not enabled
  if (!localStorage.debug) return children;
  // Or inject debug rows if it is
  return R.map(
    R.assocPath(
      ['props', 'extraTaxRows'],
      [
        {
          taxName: '(debug) Suretax expected tax',
          taxAmount: taxes,
        },
        {
          taxName: '(debug) Suretax expected total',
          taxAmount: netTotal + taxes,
        },
      ],
    ),
  )(React.Children.toArray(children));
};
