export const pluginID = 'estonian-business-registry';
export interface CompanyRecord {
  company_id: number;
  historical_names: string[];
  legal_address: string;
  name: string;
  reg_code: number;
  status: string;
  url: string;
  zip_code: null | number;
}

export interface Response {
  data: CompanyRecord[];
}

export interface CompanyRecordByCode {
  ariregistri_kood: number;
  ettevotja_aadress: {
    indeks_ettevotja_aadressis: string;
    ads_adr_id: number;
    ads_adob_id: string;
  };
  ads_adob_id: string;
  ads_adr_id: number;
  ads_koodaadress: string;
  ads_normaliseeritud_taisaadress: string;
  ads_normaliseeritud_taisaadress_tapsustus: any;
  ads_oid: string;
  ads_tyyp: any;
  asukoha_ehak_kood: string;
  asukoha_ehak_tekstina: string;
  asukoht_ettevotja_aadressis: string;
  indeks_ettevotja_aadressis: string;
  ettevotja_esmakande_kpv: any;
  ettevotja_kustutamise_kpv: any;
  ettevotja_oiguslik_vorm: any;
  ettevotja_oiguslik_vorm_alaliik: any;
  ettevotja_staatus: string;
  ettevotja_staatus_tekstina: string;
  info_kohustuse_taitmise_kohta: any;
  kmkr_nr: string;
  majandusaasta_aruanded: any;
  maksuvola_info: any;
  nimi: string;
  teabesysteemi_link: string;
}
