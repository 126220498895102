import React, { FC } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Field } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { seniorTagID } from 'plugins/mm/constants';
import { PosPlugin } from 'plugins/plugin';

const getHasSeniorTag = createSelector(
  getSelectedCustomer,
  customer => !!customer.tags?.find(tag => tag.id === seniorTagID),
);

type UICustomerFormBetaProps = Parameters<
  Required<PosPlugin>['UICustomerFormBeta']
>[0];

interface Props {
  customer: UICustomerFormBetaProps['customer'] & { senior?: 0 | 1 };
  handleChange: UICustomerFormBetaProps['handleChange'];
}

const SeniorField: FC<Props> = ({ customer, handleChange }) => {
  const { t } = useTranslation('createCustomer');
  const selectedCustomer = useSelector(getSelectedCustomer);
  const customerHasSeniorTag = useSelector(getHasSeniorTag);
  const isEditingCustomer = customer.id === selectedCustomer.id;
  return (
    <Field name="senior" wrapper={Grid} wrapperProps={{ item: true, xs: 12 }}>
      <FormControlLabel
        control={
          <Checkbox
            name="senior"
            checked={Boolean(
              customer.senior ??
                (isEditingCustomer ? customerHasSeniorTag : false),
            )}
            onChange={handleChange}
          />
        }
        label={t('fields.senior')}
      />
    </Field>
  );
};

export default SeniorField;
