/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useReducer, useContext } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader';
import { Section } from 'containers/Forms/Settings/components/Inputs';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import * as tripos from 'paymentIntegrations/triPOS/requests/triposRequests';
import InputField from 'components/FieldTypes/InputField';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { isEmpty } from 'utils';
import { sanitizePrice } from 'actions/CreateNew';
import TestButtonBlock from 'components/UIElements/PaymentTestBlock';
import QuestionMark from 'components/QuestionMark';

import { Ctx } from '../../../../components/CtxInputs';

import { TerminalActivation, TerminalSelection } from './components';
import styles from './TriposConfiguration.module.scss';

const inputClass = classNames([
  formStyles.formInput,
  formStyles.mediumTitle,
  'triPOS-form-input',
]);

// TODO: Refactor triPOS Integration Form

const TriposConfiguration = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.tripos.${text}`, ...rest);

  const superContext = useContext(Ctx);
  const {
    configuration,
    setEnableUpdate,
    initialIntegrationState,
  } = superContext.values;
  const allSaved = R.equals(configuration, initialIntegrationState);
  const { LaneId } = configuration;

  const handleLaneIdChange = value =>
    superContext.onChange('configuration', { ...configuration, LaneId: value });

  const [testingAmount, setTestingAmount] = useState('0.01');

  const [currentAction, setCurrentAction] = useState('');
  const [isOngoing, setIsOngoing] = useState(false);
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);
  const [lastTransactionId, setLastTransactionId] = useState('');
  const [lastTransactionAmount, setLastTransactionAmount] = useState('');
  const [lastPaymentType, setLastPaymentType] = useState('');

  // const [LaneId, setLaneSelected] = useState(-1);
  const [nextLaneId, setNextLaneId] = useState(0);
  const { warehouseID, pointOfSaleID: posID } = useSelector(getSelectedPos);

  const setSuccess = key => setStatus({ ...status, [key]: 'Success' });
  const setFailure = key => setStatus({ ...status, [key]: 'Failure' });

  const testButtons = [
    {
      variant: 'secondary',
      title: t('testConnection'),
      type: 'connection',
      info: t('testConnectionHint'),
      disabled: isOngoing || !Number(testingAmount) > 0,
      action: () => tripos.requestCloudConnection({}, LaneId),
    },
    {
      variant: 'danger',
      title: t('pay', { testingAmount }),
      type: 'sale',
      info: t('payHint'),
      disabled: isOngoing || !Number(testingAmount) > 0,
      action: () => tripos.requestSale({ amount: testingAmount }),
    },
    {
      variant: 'secondary',
      title: t('return', {
        lastTransactionAmount: lastTransactionAmount || '',
      }),
      type: 'return',
      info: t('returnHint'),
      disabled: isOngoing || !(lastTransactionId && lastTransactionAmount),
      action: () =>
        tripos.requestCancel({
          amount: lastTransactionAmount,
          referenceNumber: lastTransactionId,
          paymentType: lastPaymentType,
        }),
    },
    {
      variant: 'secondary',
      title: t('reversePrevTransaction'),
      type: 'reverse',
      info: t('reversePrevTransactionHint'),
      disabled: isOngoing || !(lastTransactionId && lastTransactionAmount),
      action: () =>
        tripos.requestReversal({
          amount: lastTransactionAmount,
          referenceNumber: lastTransactionId,
          paymentType: lastPaymentType,
        }),
    },
  ].map(
    // If configuration is unsaved, disable all test buttons
    allSaved ? R.identity : R.assoc('disabled', true),
  );

  const onClickHandler = ({ type, action }) => {
    setIsOngoing(true);
    setCurrentAction(type);
    action()
      .then(({ data }) => {
        if (type === 'sale') {
          setLastTransactionId(data.transactionId);
          setLastPaymentType(String(data.paymentType).toLowerCase());
          setLastTransactionAmount(data.approvedAmount);
        }
        if (['return', 'reverse'].includes(type)) {
          setLastTransactionId(null);
          setLastTransactionAmount(null);
        }
        if (type === 'connection' && data?.status !== 'Open') {
          setIsOngoing(false);
          setFailure(type);
          return;
        }
        setIsOngoing(false);
        setSuccess(type);
      })
      .catch(err => {
        console.error('Error in TriPOS payment integration', err);
        setIsOngoing(false);
        setFailure(type);
      });
  };
  const returnTestButtons = testButtons.map(b => (
    <TestButtonBlock
      key={b.type}
      clickHandler={() => onClickHandler({ action: b.action, type: b.type })}
      button={b}
      processing={isOngoing && b.type === currentAction}
      status={status[b.type]}
    />
  ));

  const lanesReducer = (state, { payload, type }) => {
    switch (type) {
      case 'setLanes':
        return [...payload];
      case 'addLane':
        return [...state, payload];
      default:
        return state;
    }
  };
  const [lanes, laneAction] = useReducer(lanesReducer, []);

  useEffect(() => {
    if (LaneId < 0) setEnableUpdate(false);
    else setEnableUpdate(true);

    (async () => {
      if (LaneId >= 0) {
        const savingConfig = {
          LaneId,
          TriPosCloud: true,
          Production: false,
        };
        await tripos.saveTriposConfig({
          warehouseID,
          config: savingConfig,
          posID,
        });
      }
    })();
  }, [LaneId, posID, setEnableUpdate, warehouseID]);

  // Find last existing lane ID and increment the next one to be registered with 1
  useEffect(() => {
    if (lanes.length > 0) {
      const prevLaneId = lanes[lanes.length - 1].LaneId;
      setNextLaneId(prevLaneId + 1);
    }
  }, [lanes]);

  // Fetch all the lanes if MS is running
  useEffect(() => {
    if (loading)
      (async () => {
        try {
          const { data: lanes } = await tripos.requestGetLanes();
          lanes.sort((a, b) => (a.laneId > b.laneId ? 1 : -1));
          lanes.forEach(lane => {
            lane.LaneId = lane.laneId;
            delete lane.laneId;
          });
          laneAction({ type: 'setLanes', payload: lanes });
          setLoading(false);
        } catch (err) {
          setConnectionError(true);
          setEnableUpdate(true);
          setLoading(false);
        }
      })();
  }, [loading, setEnableUpdate]);

  return (
    <div className={styles['triPOS-configuration']}>
      <QuestionMark
        style={{ padding: '0px 8px' }}
        color="#444"
        code={1279}
        groupID={54}
      />
      {(() => {
        if (loading)
          return (
            <div className="tripos-loader">
              <Loader show={loading}>
                <div />
              </Loader>
            </div>
          );
        return !connectionError ? (
          <div className="tripos-loader">
            <h4>{t('validation.connection')}</h4>
          </div>
        ) : (
          <div>
            <span>{t('title')}</span>
            <TerminalSelection
              lanes={lanes}
              laneSelected={LaneId}
              laneAction={laneAction}
              setLaneSelected={handleLaneIdChange}
            />
            <TerminalActivation
              nextLaneId={nextLaneId}
              laneAction={laneAction}
            />

            <h2 style={{ marginTop: '15px' }}>{`${t('testsTitle')}:`}</h2>
            <InputField
              type="text"
              title={t('testingAmount')}
              value={testingAmount}
              onChange={e => setTestingAmount(sanitizePrice(e.target.value))}
              size="lg"
              className={inputClass}
            />
            <Section>
              <div className="my-2">
                <span className="mx-2">
                  {isOngoing
                    ? t('testButtonStatus', {
                        currentAction: t(`actionTypes.${currentAction}`),
                      })
                    : t('testButtonInfo', {
                        context: allSaved ? '' : 'needsSave',
                      })}
                </span>
              </div>
              {returnTestButtons}
            </Section>
          </div>
        );
      })()}
    </div>
  );
};

export default TriposConfiguration;
