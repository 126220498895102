/* eslint-disable no-console */
import { v4 as uuidv4 } from 'uuid';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { openPluginModalPage } from 'actions/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getProductByID } from 'reducers/cachedItems/products';
import { modalPages as mp } from 'constants/modalPage';
import { notUndefinedOrNull } from 'utils';
import {
  getProductInOrderByIndex,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import { addError, addWarning } from 'actions/Error';
import { PluginError } from 'plugins/pluginUtils';
import { createConfirmation } from 'actions/Confirmation';
import { deletePayment } from 'actions/Payments';
import { getCurrentSalesDocPayments, getReturnTotal } from 'reducers/sales';
import { getPayments, getPaymentSelected } from 'reducers/Payments';
import { getProductsUniversal } from 'actions/productsDB';
import { RootState } from 'reducers';
import { getCurrencyCode } from 'reducers/configs/settings';
import { getCurrency } from 'reducers/configs/currency';
import { Order } from 'types/ShoppingCart';

import { pluginID, modals } from './constants';
import {
  ComponentConfiguration,
  ComponentHeader,
  UICustomGiftCardSerial,
} from './Components';
import { Configuration, viiPaymentJson, viiProductJson } from './types';
import ViiBalance from './modals/ViiBalance';
import ViiHistory from './modals/ViiHistory';
import KeyPad from './payment/Keypad';
import GiftCard from './payment/GiftCard';
import { customPaymentIntegration } from './payment/CustomPaymentIntegration';
import ViiGiftCardAmountInputModal from './modals/ViiGiftCardAmountInputModal';
import ViiGiftCardPurchaseOnReturnModal from './modals/ViiGiftCardPurchaseOnReturnModal';
import reduxReducer, {
  addViiProductData,
  getViiGiftCardProduct,
  getViiPayments,
  getViiProducts,
  removeViiPaymentDataById,
  removeViiProductByRowId,
  resetPluginState,
} from './redux';
import { saveViiPaymentsToJson, saveViiProductsToJson } from './jsonAPI';
import { cancelLoad, load } from './API/viiAPI';
import ViiGiftCardPurchaseEdit from './modals/ViiGiftCardPurchaseEdit';
import ViiGiftCardReturnEdit from './modals/ViiGiftCardReturnEdit';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

/*
 Usages:
  PreAuthRequest = Paying with a Gift Card in Erply
  PreAuthCancellation = Voiding a Gift Card payment (non-refund)
  Redemption = Paying with Gift Card in Erply or voiding a refund
  Load = Creating a new Gift Card or returning to a Gift Card
  CancelLoad = Gift Card refund/cancellation
 */

// Gift card with ton of balance: 502904197000961868, 5525
//                                502904197000962551, 8907

const ViiGiftCards: PosPlugin = {
  id: pluginID,
  name: 'Vii Gift Cards',
  keywords: ['vii', 'gift card', 'giftcard', 'gift', 'card', 'payment'],
  // eslint-disable-next-line global-require
  infoMDUrl: require('./documentation.md'),
  ComponentConfiguration,
  getStatus: createSelector(
    getPluginConfiguration<Configuration>(pluginID),
    currentConfig => {
      if (!currentConfig)
        return {
          type: 'error',
          message: 'Missing configuration',
        };
      const { viiUserName = '', viiPassword = '', url = '' } = currentConfig;
      if (
        !viiUserName?.trim()?.length ||
        !viiPassword?.trim()?.length ||
        !url?.trim()?.length
      ) {
        return {
          type: 'error',
          message: 'Password, username or url are not configured',
        };
      }
      return {
        type: 'valid',
        message: 'Ready',
      };
    },
  ),
  selectorOverrides: {
    getFunctionButtons: base =>
      createSelector(
        base,
        state => getPluginConfiguration<Configuration>(pluginID)(state),
        state => getViiGiftCardProduct(state),
        (baseButtons, conf, cachedViiProduct) => [
          ...baseButtons,
          {
            id: uuidv4(),
            name: `${cachedViiProduct?.name ?? 'Vii'} check balance`,
            actionType: 'action',
            action: async (
              dispatch: ThunkDispatch<RootState, unknown, Action>,
            ) => {
              if (!conf)
                return dispatch(
                  addError('Cannot check Vii balance - plugin not configured!'),
                );
              if (!conf.giftCardCode) {
                return dispatch(addError('Vii product not configured!'));
              }
              return dispatch(
                openPluginModalPage(modals.balance)({
                  isPopup: true,
                }),
              );
            },
          },
          {
            id: uuidv4(),
            name: `${cachedViiProduct?.name ?? 'Vii'} check history`,
            actionType: 'action',
            hide: conf?.hideCheckHistory,
            action: async (
              dispatch: ThunkDispatch<RootState, unknown, Action>,
            ) => {
              // Shouldn't be possible to have this button rendered since Plugin cannot be turned on without config, but just in case
              if (!conf) {
                return dispatch(
                  addError('Cannot check Vii history - plugin not configured!'),
                );
              }
              if (!conf?.giftCardCode) {
                return dispatch(addError('Vii product not configured!'));
              }

              return dispatch(
                openPluginModalPage(modals.history)({
                  isPopup: true,
                }),
              );
            },
          },
        ],
      ),
  },
  UIKeyPad: KeyPad,
  UIGiftCard: GiftCard,
  components: {
    [modals.balance]: ViiBalance,
    [modals.history]: ViiHistory,
    ViiGiftCardAmountInputModal,
    ViiGiftCardPurchaseEdit,
    ViiGiftCardReturnEdit,
    ViiGiftCardPurchaseOnReturnModal,
  },
  ComponentHeader,
  reduxReducer,
  onSaveSalesDocumentAttrToJsonApi: {
    after: ({ savedSaleDocument, savedPayments }) => async (
      _dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      try {
        const rows = savedSaleDocument.rows.slice(0);
        const viiPayments = getViiPayments(getState());
        const viiProducts = getViiProducts(getState());
        const shoppingCartProducts = getProductsInShoppingCart(getState());
        // Save Payments to JSON
        if (
          Object.keys(viiPayments)?.length > 0 ||
          Object.keys(viiProducts)?.length > 0
        ) {
          let requests: (viiPaymentJson | viiProductJson)[] = [];
          if (Object.keys(viiPayments)?.length > 0) {
            const paymentRequests: any[] = Object.values(viiPayments)
              .map(value => {
                const erplyAPIPayment = savedPayments.find(
                  sp =>
                    sp.cardNumber === value.CardNumber?.slice(-4) &&
                    sp.cardType === 'ViiGiftCards',
                );
                if (R.isEmpty(erplyAPIPayment)) {
                  return undefined;
                }
                const ret = {
                  id: Number(erplyAPIPayment.paymentID),
                  type: 'payment' as viiPaymentJson['type'],
                  payload: {
                    CardNumber: value.CardNumber,
                    ExternalReference: value.ExternalReference,
                    viiReceiptNumber: value.viiReceiptNumber,
                    AuthCode: value.AuthCode,
                    viiTranId: value.viiTranId,
                    viiUserName: value.viiUserName,
                  },
                };
                // eslint-disable-next-line no-useless-return
                return ret;
              })
              .filter(notUndefinedOrNull);
            requests = requests.concat(paymentRequests);
          }
          // Save New gift Cards to JSON API
          if (Object.keys(viiProducts)?.length > 0) {
            const productRequests: any[] = Object.entries(viiProducts)
              .map(([key, value]) => {
                const productIndex = shoppingCartProducts.findIndex(
                  order => String(order.orderIndex) === String(key),
                );
                const recordID = rows[productIndex]?.stableRowID;
                if (!recordID) return undefined;
                const ret = {
                  id: Number(recordID),
                  type: 'product' as viiProductJson['type'],
                  payload: {
                    CardNumber: value.CardNumber,
                    ExternalReference: value.ExternalReference,
                    viiReceiptNumber: value.viiReceiptNumber,
                    AuthCode: Number(value.AuthCode),
                    viiTranId: value.viiTranId,
                    viiUserName: value.viiUserName,
                  },
                };
                return ret;
              })
              .filter(notUndefinedOrNull);
            requests = requests.concat(productRequests);
          }

          requests.map(async rq => {
            try {
              if (rq.type === 'payment') {
                await saveViiPaymentsToJson(rq.id, rq.payload);
              } else {
                await saveViiProductsToJson(rq.id, rq.payload);
              }
            } catch (err) {
              console.error(err);
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  // Handles logic to issue a gift card after it was purchased as product and paid for
  onSaveSalesDocument: {
    on: ({ progress }, requests) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const viiProducts = getViiProducts(getState());
      if (!R.isEmpty(viiProducts)) {
        let previous: Promise<unknown> = Promise.resolve();

        Object.entries(viiProducts).map(([k, v]) => {
          const { Amount, CardNumber, ExternalReference, viiReceiptNumber } = v;
          /*
              When selling a gift card it needs to be Loaded (init gift card).
              Load only those gift cards that have not yet been loaded.
            */
          if (Number(Amount) > 0 && !viiReceiptNumber) {
            previous = previous
              .then(() =>
                dispatch(
                  load({
                    Amount,
                    CardNumber,
                    ExternalReference,
                  }),
                ),
              )
              .then(async res => {
                // Received approval from Vii API that the card was issued out
                if (res && Number(res.ResponseCode?.[0]) === 0) {
                  // Save the gift card information into plugin's redux state to sync to JSON API.
                  return dispatch(
                    addViiProductData({
                      orderIndex: Number(k),
                      vii: {
                        ...v,
                        CardNumber: res.CardNumber[0],
                        Amount: res.Amount[0],
                        ExternalReference: res.ExternalReference[0],
                        ...(res.AuthCode ? { AuthCode: res.AuthCode[0] } : {}),
                        IssuanceDate: res.IssuanceDate[0],
                        ExpiryDate: res.ExpiryDate[0],
                        CardStatusId: res.CardStatusId[0],
                        viiReceiptNumber: res.viiReceiptNumber[0],
                        viiTranId: res.viiTranId[0],
                        viiUserName: res.viiUserName[0],
                      },
                    }),
                  );
                }
                await dispatch(progress.halt);
                await dispatch(
                  addError(
                    res
                      ? `Failed to issue gift card. Error: ${res.ResponseMessage?.[0]}`
                      : 'Error issuing gift card',
                  ),
                );
                await dispatch(
                  openPluginModalPage('ViiGiftCardPurchaseEdit')({
                    isPopup: true,
                    props: {
                      isPopup: true,
                      card: v,
                      orderIndex: Number(k),
                    },
                  }),
                )
                  .then(() => {
                    return dispatch(progress.resume);
                  })
                  .catch(() => {
                    throw new Error('Gift card editing failed');
                  });
                return v;
              });
          }
          /*
             When returning the gift card, it needs to be cancelled
            */
          if (Number(Amount) < 0 && !viiReceiptNumber) {
            previous = previous
              .then(() =>
                dispatch(
                  cancelLoad({
                    Amount: Amount.replace('-', ''),
                    CardNumber,
                    ExternalReference,
                  }),
                ),
              )
              .then(async res => {
                // Received approval from Vii API that the card was issued out
                if (res && Number(res.ResponseCode?.[0]) === 0) {
                  // Save the gift card information into plugin's redux state to sync to JSON API.
                  return dispatch(
                    addViiProductData({
                      orderIndex: Number(k),
                      vii: {
                        ...v,
                        CardNumber: res.CardNumber[0],
                        Amount: res.Amount[0],
                        ExternalReference: res.ExternalReference[0],
                        ...(res.AuthCode ? { AuthCode: res.AuthCode[0] } : {}),
                        CardStatusId: res.CardStatusId[0],
                        viiReceiptNumber: res.viiReceiptNumber[0],
                        viiTranId: res.viiTranId[0],
                        viiUserName: res.viiUserName[0],
                        SettlementDate: res.SettlementDate[0],
                      },
                    }),
                  );
                }
                // In case API responded with no approval, show card number edit modal.
                await dispatch(progress.halt);
                await dispatch(
                  addError(
                    res
                      ? `Failed to return gift card. Error: ${res?.ResponseMessage?.[0]}`
                      : 'Error returning gift card',
                  ),
                );
                await dispatch(
                  openPluginModalPage('ViiGiftCardReturnEdit')({
                    isPopup: true,
                    props: {
                      isPopup: true,
                      card: v,
                      orderIndex: Number(k),
                    },
                  }),
                )
                  .then(() => {
                    return dispatch(progress.resume);
                  })
                  .catch(() => {
                    throw new Error('Gift card editing failed');
                  });
                return v;
              });
          }
          /*
             If gift card has been saved (has viiReceiptNumber) then there's nothing to do
            */
          return v;
        });

        await previous
          .then(() => {
            return requests;
          })
          .catch(err => {
            console.log('Caught error:', err);
            throw new PluginError(err.message, err.messageForUI);
          });
      }
      return requests;
    },
    after: () => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      dispatch(resetPluginState());
    },
  },
  onRemoveOrder: {
    after: ({ orderIndex }) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      dispatch(removeViiProductByRowId(orderIndex));
    },
  },
  onResetShoppingCart: {
    after: () => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      dispatch(resetPluginState());
    },
  },
  customPaymentIntegration,
  onUpdateOrderAmount: {
    on: (p, ap) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const state = getState();
      const order: Order = getProductInOrderByIndex(p.orderIndex)(state);
      const viiProducts = getViiProducts(getState());
      if (
        Object.keys(viiProducts).some(
          k => Number(k) === Number(p.orderIndex),
        ) &&
        Number(ap.amount) !== Number(order.amount)
      ) {
        dispatch(
          addWarning(
            `Cannot edit ${order?.name ?? 'Vii gift card'} product amount`,
          ),
        );
        return {
          ...ap,
          amount: order.amount,
        };
      }
      return ap;
    },
  },
  // On payment modal go through the products and update amounts in plugin redux to match cart prices
  onOpenPaymentModal: {
    on: (_p, ap) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const state = getState();
      const viiProducts = getViiProducts(state);
      const cart = getProductsInShoppingCart(state);
      cart.forEach(({ orderIndex, rowTotal }) => {
        const viiProd = viiProducts[orderIndex];
        if (viiProd) {
          dispatch(
            addViiProductData({
              orderIndex,
              vii: {
                ...viiProd,
                Amount: rowTotal.toFixed(2),
              },
            }),
          );
        }
      });
      return ap;
    },
  },
  // If a vii payment is clicked on - we do not allow edit, we either remove or keep it
  onPaymentClick: {
    before: ({ paymentKey, payment }) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      const { caption = 'Vii' } = payment;
      // We do not allow editing of Vii payments since we would need to fetch balance each time it's updated
      if (payment.paymentIntegration === 'vii' && !payment.paid) {
        await new Promise((resolve, reject) =>
          dispatch(
            createConfirmation(resolve, reject, {
              title: `${caption} payment edit not allowed`,
              body: `Editing the amount of ${caption} payment is not allowed. Payment needs to be removed. \nConfirm to proceed.`,
            }),
          ),
        ).then(
          async () => {
            await dispatch(deletePayment({ key: paymentKey }));
            await dispatch(removeViiPaymentDataById(paymentKey));
            throw new Error(`${caption} payment was removed`);
          },
          () => {
            throw new Error(`${caption} payment kept unedited`);
          },
        );
      }
    },
  },
  // If the sale was paid with a gift card, need to prompt a new gift card input
  onAddReturnProducts: {
    after: (p, _ap) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const { orders, returnDocument } = p;
      const { rows: docRows } = returnDocument;
      const config = getPluginConfiguration<Configuration>(pluginID)(
        getState(),
      );
      const currentDocPayments = getCurrentSalesDocPayments(getState());
      const returnTotal = getReturnTotal(getState());
      const currencyCode = getCurrencyCode(getState());
      const currency = getCurrency(currencyCode)(getState());

      const { original } = currentDocPayments;

      const paidByVii = original.reduce((acc, cur) => {
        if (cur?.cardType === 'ViiGiftCards') {
          return acc + Number(cur.sum);
        }
        return acc;
      }, 0);

      // For each returnable Vii gift card, need to populate vii rdx
      orders.forEach(o => {
        const origDocRow = docRows.find(dr => dr.stableRowID === o.stableRowID);
        if (origDocRow && origDocRow.jdoc && origDocRow.jdoc['vii-brazil']) {
          const viiInfo = origDocRow.jdoc['vii-brazil'];
          dispatch(
            addViiProductData({
              orderIndex: Number(o.orderIndex),
              vii: {
                Amount: o.finalPriceWithVAT.toString(),
                CardNumber: viiInfo.CardNumber,
                ExternalReference: viiInfo.ExternalReference,
                viiTranId: viiInfo.viiTranId,
              },
            }),
          );
        }
      });
      // ATM, if there was a vii gift card used, always show popup
      if (paidByVii && Math.abs(returnTotal) >= 20) {
        if (!config) {
          dispatch(
            addError(
              'Vii gift card product addition failed. Check console for details.',
            ),
          );
          console.error(
            'Sale was originally paid by Vii gift card, but Vii Gift Card plugin configuration is missing. Reconfigure the plugin and restart the return to return to Vii gift card.',
          );
          return;
        }
        await new Promise((resolve, reject) =>
          dispatch(
            createConfirmation(resolve, reject, {
              title: 'Giftcard transaction',
              body: `This transaction was paid by gift card. The amount paid by gift card was ${currency.symbol}${paidByVii}. Load a new gift card to refund the ${currency.symbol}${paidByVii}.`,
            }),
          ),
        )
          .then(async () => {
            const { products } = await dispatch(
              getProductsUniversal({ code: config.giftCardCode }),
            );
            if (!products || !products[0]) {
              dispatch(
                addError(
                  `Failed to add gift card to return: Configured product (${config.giftCardCode}) not found`,
                ),
              );
            } else {
              dispatch(
                openModalPage({
                  component: mp.pluginModal,
                  props: {
                    name: 'ViiGiftCardPurchaseOnReturnModal',
                    closeModal: () => {
                      dispatch(previousModalPage());
                    },
                    card: {
                      amount: paidByVii,
                    },
                    product: products[0],
                    lockAmount: true,
                  },
                  isPopup: true,
                }),
              );
            }
          })
          .catch(() => {
            console.error('Exited by user');
          });
      }
    },
  },
  // In order to avoid confusing customers, when a Vii payment information is being entered, hide all the payment related buttons
  UIPaymentActions: ({ children }) => {
    const selectedPaymentKey = useSelector(getPaymentSelected);
    const selectedPayment = useSelector(getPayments)[selectedPaymentKey];
    if (
      selectedPaymentKey === 'giftcard-vii' ||
      selectedPayment?.paymentIntegration === 'vii'
    ) {
      return null;
    }
    return children;
  },
  UICustomGiftCardSerial,
};

export default ViiGiftCards;
