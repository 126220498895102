import { ErplyAttributes } from 'utils';
import { notUndefinedOrNull } from 'utils/tsHelpers';

import { PNPRateObjects } from './types';

/**
 * Rounds number .
 * @param {num} number that needs to be rounded.
 * @param {precision} precision The number of decimal places to preserve.
 * @returns {number} Rounded number.
 */

export const roundUp = (num: number, precision = 2): number => {
  // eslint-disable-next-line no-restricted-properties
  const localPrecision = Math.pow(10, precision);
  return Math.round(num * localPrecision) / localPrecision;
};

// TODO: maybe we could extract this as an action
// both times the func is called, results from calculateShoppingCart API call are passed as params
// we could either put the request inside the func and make as an action
// or we could perhaps memoize the product card and not send request to API at all
// + that could be good for offline capabilities
export const calculateAdmissionTaxAmount = (
  calculatedProduct: any[],
  vatrate: string | number,
): number => {
  if (calculatedProduct.length === 0) {
    return 0;
  }
  return roundUp(
    (calculatedProduct[0].rows[1].finalPrice / 100) * Number(vatrate),
  );
};

export const defineEnvFeeRate = (
  customerRateObj: any,
  customerGroupRateObj: any,
  warehouseEnvFeeRateObj: any,
): number => {
  if (notUndefinedOrNull(customerRateObj) && customerRateObj !== '') {
    return Number(customerRateObj);
  }

  if (notUndefinedOrNull(customerGroupRateObj) && customerGroupRateObj !== '') {
    return Number(customerGroupRateObj);
  }

  if (
    notUndefinedOrNull(warehouseEnvFeeRateObj) &&
    warehouseEnvFeeRateObj !== ''
  ) {
    return Number(warehouseEnvFeeRateObj);
  }
  return 0;
};

export const getPNPEnvFeeRateObjects = (
  warehouse: any,
  customerGroup: any,
  currentCustomer: any,
): PNPRateObjects => {
  const warehouseEnvFeeRateObject = new ErplyAttributes(
    warehouse?.attributes,
  ).get('pnp_env_fee_rate_warehouse');

  const customerGroupRateObject = new ErplyAttributes(
    customerGroup?.attributes,
  )?.get('pnp_env_fee_rate_customer_group');

  const customerRateObject = new ErplyAttributes(
    currentCustomer?.attributes,
  )?.get('pnp_env_fee_rate_customer');

  return {
    warehouseEnvFeeRateObject,
    customerGroupRateObject,
    customerRateObject,
  };
};

/** Filters out from the shopping cart the environmental fee product and california admission tax product.
 * @param {string} shoppingCart Products in the shopping cart.
 * @param {arrayOf(String)} productsWaivingCAFee Names of the products that waive the admission tax.
 * @returns {number} Number of regular products in the cart ().
 */
export const defineNumberOfWaiverProductsInCart = (
  shoppingCart: any[],
  productsWaivingCAFee: string[],
): number => {
  const waiverProductsReducer = (accumulator, currentValue) => {
    if (productsWaivingCAFee.includes(currentValue.code)) {
      return accumulator + 1;
    }
    return accumulator;
  };

  return shoppingCart.reduce(waiverProductsReducer, 0);
};

/** Filters out from the shopping cart the environmental fee product and california admission tax product.
 * @param {string} products Products in the shopping cart.
 * @param {string} CATaxProductName Name of the california tax product.
 * @param {string} EnvFeeProductName Name of the environmentsl fee product.
 * @returns {number} Number of regular products in the cart ().
 */
export const defineRegularProductsInCartLength = (
  products: any[],
  CATaxProductName: string,
  EnvFeeProductName: string,
): number => {
  const regularProducts = products.filter(
    product =>
      product.name.toUpperCase() !== EnvFeeProductName?.toUpperCase().trim() &&
      product.name.toUpperCase() !== CATaxProductName?.toUpperCase().trim(),
  );

  return regularProducts.length;
};

interface TaxRowsToDisplayParams {
  shouldAddEnvFee: boolean;
  shouldAddAdmissionTax: boolean;
  envFeeAmount: number;
  admissionFeeTaxAmount: number;
}

export const defineTaxRowsToDisplay = (
  params: TaxRowsToDisplayParams,
): any[] => {
  const {
    shouldAddEnvFee,
    shouldAddAdmissionTax,
    envFeeAmount,
    admissionFeeTaxAmount,
  } = params;

  if (shouldAddEnvFee && shouldAddAdmissionTax) {
    return [
      { taxAmount: envFeeAmount, taxName: 'environmental fee' },
      { taxAmount: admissionFeeTaxAmount, taxName: 'admission tax' },
    ];
  }

  if (shouldAddEnvFee && !shouldAddAdmissionTax) {
    return [{ taxAmount: envFeeAmount, taxName: 'environmental fee' }];
  }

  if (!shouldAddEnvFee && shouldAddAdmissionTax) {
    return [{ taxAmount: admissionFeeTaxAmount, taxName: 'admission tax' }];
  }

  return [];
};
