import querystring from 'querystring';

import { printPatchscript } from 'actions/integrations/printer';
import { createConfirmation } from 'actions/Confirmation';

/**
 * Printing action for adyen terminals without printer
 *
 * @param {Array} data - array with 2 elements - client and merchant receipts
 */
export const printReceipt = data => async dispatch => {
  const [cashierReceipt, customerReceipt] = data;

  // Print merchant copy of the payment transaction receipt
  await dispatch(printPatchscript(cashierReceipt)).catch((e) => {
    console.error('Error while printing adyen receipt',e);
  });
  // Ask for confirmation to print client copy of the payment transaction receipt
  // and print one if needed
  await new Promise((resolve, reject) =>
    dispatch(
      createConfirmation(resolve, reject, {
        title: 'Print Client Receipt',
        body: 'Print receipt copy for the client?',
      }),
    ),
  )
    .then(() => dispatch(printPatchscript(customerReceipt)))
    .catch((e) => {
      console.error('Error while printing adyen receipt',e);
    });
};

export const creationOfAdyenPatchscript = receipts =>
  receipts.map(rec => [
    {
      align: 'center',
      type: 'text',
      pieces: [{ text: rec.DocumentQualifier }],
    },
    /* {
      type: 'text',
      pieces: [
        { text: 'IntegratedPrintFlag: ' },
        { text: rec.IntegratedPrintFlag ? 'true' : 'false' },
      ],
    },
    {
      type: 'text',
      pieces: [
        { text: 'RequiredSignatureField: ' },
        { text: rec.RequredSignatureField ? 'true' : 'false' },
      ],
    }, */
    {
      type: 'table',
      columns: [
        { baseWidth: 0, weight: 1 },
        { baseWidth: 0, weight: 1 },
      ],
      rows: rec.OutputContent.OutputText.map(({ Text }) =>
        querystring.parse(Text),
      ).map(({ name, value, key }) => {
        if (name && value) {
          return {
            type: 'normal',
            cells: [
              { pieces: [{ text: name }] },
              { pieces: [{ text: value }] },
            ],
          };
        } else if (name) {
          return { type: 'normal', cells: [{ pieces: [{ text: ' ' }] }] };
        } else {
          return {
            type: 'normal',
            cells: [{ pieces: [{ text: name }], align: 'center' }, 'colspan'],
          };
        }
      }),
    },
  ]);
