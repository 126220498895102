import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getUserRights } from 'reducers/Login';

import { isNonPrimitive } from './utils';

const HideStockData: PosPlugin = {
  id: 'hide-stock-data-table',
  name: 'Hide stock data table',
  keywords: ['hide', 'stock', 'data', 'table'],
  info:
    'This plugin will hide the stock data tables from the POS views for the users that do not have the permissions to see them. Permission is `Configuration` in `Other main modules`',
  getStatus: () => {
    return {
      type: 'valid',
      message: `Ready`,
    };
  },
  UIStockDataTable: ({ children }) => {
    const perms = useSelector(getUserRights);
    const stockPermission = perms.modules.settings.view > 0;

    return <>{stockPermission ? children : null}</>;
  },
  UIProductResultHeaderCell: ({ children }) => {
    const perms = useSelector(getUserRights);
    const stockPermission = perms.modules.settings.view > 0;

    const matches = e => {
      return !(
        isNonPrimitive(e) &&
        e?.props?.['data-testid'] === 'search-result-header-stock' &&
        !stockPermission
      );
    };

    return R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(React.Children.toArray, R.filter(matches)),
      ),
    )(React.Children.toArray(children));
  },
  UIProductResultRow: ({ children }) => {
    const perms = useSelector(getUserRights);
    const stockPermission = perms.modules.settings.view > 0;
    const matches = e => {
      return !(
        isNonPrimitive(e) &&
        e?.props?.children?.props?.['data-testid'] === 'search-result-stock' &&
        !stockPermission
      );
    };
    return R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(React.Children.toArray, R.filter(matches)),
      ),
    )(React.Children.toArray(children));
  },
  UIStockAndPriceListTableHeaderRow: ({ children }) => {
    const perms = useSelector(getUserRights);
    const stockPermission = perms.modules.settings.view > 0;
    const matches = e => {
      return !(
        isNonPrimitive(e) &&
        ['qty', 'store-qty'].includes(e?.props?.['data-testid']) &&
        !stockPermission
      );
    };
    return R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(React.Children.toArray, R.filter(matches)),
      ),
    )(React.Children.toArray(children));
  },
  UIStockAndPriceListTableRow: ({ children }) => {
    const perms = useSelector(getUserRights);
    const stockPermission = perms.modules.settings.view > 0;
    const matches = e => {
      return !(
        isNonPrimitive(e) &&
        ['total-in-store', 'total-in-stock'].includes(
          e?.props?.['data-testid'],
        ) &&
        !stockPermission
      );
    };
    return R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(React.Children.toArray, R.filter(matches)),
      ),
    )(React.Children.toArray(children));
  },
};

export default HideStockData;
