import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { History } from '@material-ui/icons';

import { addError } from 'actions/Error';
import { openPluginModalPage } from 'actions/modalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import Loader from 'components/Loader';
import { GivexCard } from 'plugins/givexHeartland/types';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

import { GivexCardInput } from '../components/GivexCardInput';
import { getBalance } from '../API/givexAPI';
import {
  getGivexConfiguration,
  getValidateGivexCard,
} from '../configuration/Configuration';

import { modals } from '.';

/**
 * The balance view allows the user to request balance (and expiration data) for a given card.
 *
 * It also has a link on each result to go to the {@link GivexHistory history view}
 * @see {@link GivexHistory}
 */
const GivexBalance = () => {
  const [card, setCard] = useState<GivexCard>({
    cardNo: '',
    pin: '',
  });
  const [loading, setLoading] = useState(false);

  const [cardInfo, setCardInfo] = useState<{
    card: GivexCard;
    balance: string;
    expires: string | null;
  } | null>(null);
  const dispatch = useDispatch();
  const { displayName } = useSelector(getGivexConfiguration);
  const givexValidationErrorMessage = useSelector(getValidateGivexCard)(
    card,
    'balance',
  );

  const handleClose = () => dispatch(previousModalPage());

  const handleSearch = async () => {
    if (givexValidationErrorMessage) {
      dispatch(addError(givexValidationErrorMessage));
      return;
    }
    setLoading(true);
    getBalance(card)
      .then(res =>
        setCardInfo({
          card,
          balance: res.certificateBalance,
          expires: res.certificateExpirationDate,
        }),
      )
      .catch(error => dispatch(addError(error.message)))
      .finally(() => setLoading(false));
  };

  const handleSelect = (cardNo: string) => {
    dispatch(
      openPluginModalPage(modals.giftCardHistory)({
        props: { cardNo },
      }),
    );
  };

  return (
    <div data-testid="givex-balance">
      <Modal.Header>
        <Modal.Title>
          <h3 data-testid="title">
            <b>Check {displayName} balance</b>
          </h3>
        </Modal.Title>
        <div className="d-flex align-content-center">
          <CloseButton action={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <GivexCardInput value={card} onChange={setCard} type="balance" />
        <Button onClick={handleSearch}>Search</Button>
        <Loader withPadding loadingText="Loading..." block show={loading}>
          <Grid container>
            <Grid item xs={4}>
              <Typography style={{ fontWeight: 'bold' }}>
                Card number
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 'bold' }}>
                {/* prettier-ignore */}
                Balance
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: 'bold' }}>
                Expiration date
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                {/* prettier-ignore */}
                History
              </Typography>
            </Grid>
          </Grid>
          {cardInfo ? (
            <Grid container>
              <Grid item xs={4}>
                <Typography>{cardInfo.card.cardNo}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{cardInfo.balance}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{cardInfo.expires ?? 'No expiration'}</Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  size="small"
                  title="View transaction history"
                  onClick={() => handleSelect(card.cardNo)}
                >
                  <History />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}
        </Loader>
      </Modal.Body>
    </div>
  );
};

export default GivexBalance;
