/* eslint-disable array-callback-return */
import { currentConfigs$ } from '@pos-refacto/installer-rxjs';
import * as rxop from 'rxjs/operators';
import { assoc } from 'ramda';
import { useObservable } from 'react-use';

import { getAllMSEndpointsFromLS } from 'reducers/installer';
import { REDUX_INSTALLER_MS_ENDPOINTS } from 'constants/persistence';

const BASE_URL = 'https://localhost.erply.com';

const useSaveMSEndpointsToLS = () => {
  useObservable(
    currentConfigs$.pipe(
      rxop.map((configs: Record<string, Record<string | 'Port', any>>) => {
        Object.entries(configs).forEach(([ms, conf]) => {
          const current = getAllMSEndpointsFromLS();
          const port = conf.Port || conf.port;
          const url = port ? `${BASE_URL}:${port}` : '';
          try {
            localStorage.setItem(
              REDUX_INSTALLER_MS_ENDPOINTS,
              JSON.stringify(assoc(ms, url, current)),
            );
          } catch (e) {
            console.error(
              'Failed to cache microservice endpoints in localStorage',
              e,
            );
          }
        });
      }),
    ),
  );
};

export default useSaveMSEndpointsToLS;
