import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import MuiDatePicker from 'components/MuiDatePicker';
import { getDateFormatFromBO } from 'reducers/configs/settings';

export type DateRange = { from: Date | null; to: Date | null };

const DateRangeInput = ({
  titleFrom,
  titleTo,
  value,
  onChange,
  dataTestKey,
  ...props
}: {
  titleFrom: string;
  titleTo: string;
  value: DateRange;
  onChange: (newValue: DateRange) => void;
  dataTestKey: string;
}) => {
  const { t } = useTranslation('customer');
  const format = useSelector(getDateFormatFromBO);
  // Do not live-update the customer ever, only use the initial value
  return (
    <Box data-testid="date-range-input" data-test-key={dataTestKey}>
      <MuiDatePicker
        onChange={from => onChange({ ...value, from })}
        value={value.from}
        format={format}
        label={t('fields.dateFrom')}
        disableFuture={true}
        data-testid="from"
        {...props}
      />
      <MuiDatePicker
        onChange={to => onChange({ ...value, to })}
        value={value.to}
        format={format}
        label={t('fields.dateTo')}
        disableFuture={true}
        data-testid="to"
        {...props}
      />
    </Box>
  );
};

export default DateRangeInput;
