import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Table } from 'react-bootstrap';

import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { getProductByID } from 'reducers/cachedItems/products';
import { getPluginConfiguration } from 'reducers/Plugins';

import constants, { RelatedProdPlugConfig } from '../../constants';
import './uiRelatedProducts.scss';

const UIRelatedProducts = ({
  productID,
  parentRowID,
  relatedProducts,
  replacementProducts,
  tableStyle,
}) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(state => state);
  const product = getProductByID(productID)(reduxState);
  if (!product) {
    dispatch(previousModalPage());
  }
  const kittedItemsConfig =
    getPluginConfiguration<RelatedProdPlugConfig>(constants.plugin.id)(
      reduxState,
    )?.[productID] ?? {};

  const warranties = useMemo(
    () => relatedProducts.filter(pr => pr.code.startsWith('EW')),
    [relatedProducts],
  );
  const kitted = useMemo(
    () => relatedProducts.filter(pr => !pr.code.startsWith('EW')),
    [relatedProducts],
  );

  const [showWarranties, setShowWarranties] = useState(!!warranties.length);

  const onClose = () =>
    showWarranties ? setShowWarranties(false) : dispatch(previousModalPage());

  const closeAll = () => dispatch(closeModalPage('RELATED_PRODUCTS'));

  const related = showWarranties ? warranties : kitted;
  return (
    <div data-testid="related-products">
      <Modal.Header className="pnp-related-products">
        <span className="product-name-title">
          {showWarranties
            ? `Warranties for ${product?.name}`
            : `Products related to ${product?.name}`}
        </span>

        <div className="ml-auto">
          <UIButton
            data-testid="done-btn"
            text="Done"
            variant="POS"
            action={onClose}
          />
          <UIButton
            data-testid="close-btn"
            text="Close all"
            variant="POS"
            action={closeAll}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        {!related.length ? null : (
          <Table
            data-testid="related-products-table"
            hover
            className={tableStyle}
          >
            <thead data-testid="table-headings">
              <tr>
                <th>Name</th>
                <th>Code</th>
                <th className="text-right">{`${
                  !showWarranties ? 'Original' : ''
                } Price (net)`}</th>
                {!showWarranties && (
                  <th className="text-right">Sold Separate Acc (net)</th>
                )}
              </tr>
            </thead>
            <tbody data-testid="body">
              {related
                .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                .map(({ productID, code, name, priceListPrice }) => {
                  const kittedPrice =
                    kittedItemsConfig?.customPrices?.[productID] ??
                    kittedItemsConfig?.defaultKitItemPrice ??
                    priceListPrice;
                  return (
                    <tr
                      key={productID}
                      data-testid="product-row"
                      data-test-key={productID}
                      onClick={() =>
                        dispatch(addProduct({ productID, parentRowID }))
                      }
                    >
                      <td>{name}</td>
                      <td>{code}</td>
                      <td className="text-right">
                        {Number(priceListPrice).toFixed(2)}
                      </td>
                      {!showWarranties && (
                        <td className="text-right">
                          {Number(kittedPrice).toFixed(2)}
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
        {relatedProducts.length && replacementProducts.length ? (
          <>
            <hr />
            <h3 style={{ fontWeight: 'bold' }}>Substitutes products</h3>
          </>
        ) : null}

        {!replacementProducts.length ? null : (
          <Table
            data-testid="replacement-products-table"
            hover
            className={tableStyle}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Code</th>
                <th>Price</th>
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {replacementProducts
                .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                .map(
                  ({
                    productID,
                    code,
                    name,
                    priceListPriceWithVat: price,
                    stock,
                  }) => (
                    <tr
                      key={productID}
                      data-testid="product-row"
                      data-test-key={productID}
                      onClick={() => dispatch(addProduct({ productID }))}
                    >
                      <td>{name}</td>
                      <td>{code}</td>
                      <td>{price.toFixed(2)}</td>
                      <td>{stock}</td>
                    </tr>
                  ),
                )}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </div>
  );
};

export default UIRelatedProducts;
