import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';

import { getAttrs } from 'plugins/taxProducts/utils/getAttrs';
import { removeTaxProduct } from 'plugins/taxProducts/redux/redux';
import {
  getCurrencyFormatter,
  getShowPricesWithTax,
} from 'reducers/configs/settings';

export const ProductRow = ({ product }) => {
  const dispatch = useDispatch();
  const format = useSelector(getCurrencyFormatter);
  const showTax = useSelector(getShowPricesWithTax);
  return (
    <tr>
      <td style={{ width: 0 }}>
        <IconButton
          onClick={() => dispatch(removeTaxProduct(product.productID))}
        >
          <Delete color="error" />
        </IconButton>
      </td>
      {product ? (
        <>
          <td>{product.name}</td>
          <td>{getAttrs(product).percentage}%</td>
          <td>
            {R.ifElse(
              R.equals(undefined),
              R.always('...'),
              format,
            )(product.taxPrice.net + (showTax ? product.taxPrice.tax : 0))}
          </td>
        </>
      ) : (
        <td colSpan={3}>
          Loading...
        </td>
      )}
    </tr>
  );
};
