/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';

import {
  getKeyboardIsEnabled,
  getKeyboardIsShow,
} from '../../reducers/OnScreenKeyboard';
import InputField from '../FieldTypes/InputField';
import { CLASS_KEYBOARD } from '../../constants/OnScreenKeyboard';
import { useActiveElement } from '../../utils/hooks/useActiveElement';
import layers from '../../assets/layers.module.css';

import {
  typeIntoField,
  deleteFromField,
  preventFocus,
  submit as doSubmit,
} from './util';

const Row = props => (
  <div
    style={{ display: 'flex', flex: 'row nowrap', justifyContent: 'center' }}
    {...props}
  />
);
const Button = ({
  value,
  style,
  colspan = 1,
  isDelete = false,
  action = isDelete ? { left: 1 } : { left: value.toString() },
  ...rest
}) => (
  <BootstrapButton
    style={{
      border: '1px solid lightgray',
      height: 46,
      width: (46 + 4) * colspan - 4,
      margin: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 24,
      ...style,
    }}
    variant="light"
    onClick={() => {
      if (isDelete) {
        deleteFromField(document.activeElement, action);
      } else typeIntoField(document.activeElement, action);
    }}
    onMouseDown={e => e.preventDefault()}
    {...rest}
  >
    {value}
  </BootstrapButton>
);
const Shift = ({ toggleShift, shift }) => (
  <Button
    value="↑"
    onClick={toggleShift}
    variant={shift ? 'secondary' : 'gray'}
  />
);

/**
 * An on screen keyboard component - include this at the top level of your app
 *
 * Will not display if getKeyboardIsEnabled is false
 * <br/> Will also not display unless either an input field is selected or getKeyboardIsShow is true
 *
 * The keyboard enables typing into any input component on the page -
 * displaying a copy of it on top (in case the keyboard is in the way)
 *
 */
const OnScreenKeyboard = () => {
  const [minimal, setMinimal] = useState(false);
  const [error, setError] = useState(null);
  const doError = useCallback(
    e => {
      if (!error) {
        Sentry.captureException(e);
        setError(e);
      }
    },
    [error],
  );
  const [shift, setShift] = useState(false);
  const toggleShift = useCallback(() => setShift(!shift), [shift]);
  const [symbol, setSymbol] = useState(false);
  const toggleSymbol = () => setSymbol(!symbol);
  const enabled = useSelector(getKeyboardIsEnabled);
  const forceShow = useSelector(getKeyboardIsShow);

  const target = useActiveElement();

  const submit = () => {
    doSubmit(document.activeElement);
  };

  const [attrs, setAttrs] = useState({ value: '' });

  useEffect(() => {
    if (!target) {
      return () => {};
    }
    const i = setInterval(() => {
      try {
        const newAttrs = { value: '' };
        if (target && target.attributes) {
          [...target.attributes].forEach(({ nodeName, nodeValue }) => {
            newAttrs[nodeName] = nodeValue;
          });
        }
        delete newAttrs.style;
        delete newAttrs.class;
        newAttrs.value = target.value;
        [...Object.keys(attrs), ...Object.keys(newAttrs)].find(key => {
          if (newAttrs[key] !== attrs[key]) {
            setAttrs(newAttrs);
            return true;
          }
          return false;
        });
      } catch (e) {
        doError(e);
      }
    }, 50);
    return () => {
      clearInterval(i);
    };
  }, [forceShow, target, attrs, doError]);

  const invalidTarget =
    !target ||
    ['INPUT', 'TEXTAREA'].indexOf(target.nodeName) < 0 ||
    ['checkbox', 'radio'].indexOf(target.type.toLowerCase()) >= 0;
  if (!enabled || (!forceShow && invalidTarget)) {
    return <></>;
  }

  if (minimal) {
    return (
      <div
        style={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          padding: 4,
          backgroundColor: 'rgba(220,220,220,0.5)',
        }}
        /* Allow this element to catch focus events so we can stop them */
        tabIndex={-1}
        onFocus={preventFocus}
        className={`${CLASS_KEYBOARD} ${layers['on-screen-keyboard']}`}
      >
        <Button variant="accent" value="↑" onClick={() => setMinimal(false)} />
      </div>
    );
  }
  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        bottom: 0,
        padding: 4,
        backgroundColor: 'rgba(220,220,220,0.5)',
        minHeight: 'auto',
      }}
      /* Allow this element to catch focus events so we can stop them */
      tabIndex={-1}
      onFocus={preventFocus}
      className={`${CLASS_KEYBOARD} ${layers['on-screen-keyboard']}`}
    >
      <div style={{ padding: 16 }}>
        <InputField
          {...attrs}
          value={attrs.value || ''}
          size="lg"
          onChange={() => {}}
        />
      </div>

      <Row>
        <Button value="1" />
        <Button value="2" />
        <Button value="3" />
        <Button value="4" />
        <Button value="5" />
        <Button value="6" />
        <Button value="7" />
        <Button value="8" />
        <Button value="9" />
        <Button value="0" />
        <Button variant="gray" value="←" isDelete={true} action={{ left: 1 }} />
      </Row>
      <Row>
        <Button value={symbol ? '@' : shift ? 'Q' : 'q'} />
        <Button value={symbol ? '[' : shift ? 'W' : 'w'} />
        <Button value={symbol ? ']' : shift ? 'E' : 'e'} />
        <Button value={symbol ? '{' : shift ? 'R' : 'r'} />
        <Button value={symbol ? '}' : shift ? 'T' : 't'} />
        <Button value={symbol ? '(' : shift ? 'Y' : 'y'} />
        <Button value={symbol ? ')' : shift ? 'U' : 'u'} />
        <Button value={symbol ? '^' : shift ? 'I' : 'i'} />
        <Button value={symbol ? '-' : shift ? 'O' : 'o'} />
        <Button value={symbol ? '+' : shift ? 'P' : 'p'} />
        <Button value={symbol ? '=' : shift ? 'Ü' : 'ü'} />
        {symbol ? null : <Button value={shift ? 'Õ' : 'õ'} />}
      </Row>
      <Row>
        <Button value={symbol ? '*' : shift ? 'A' : 'a'} />
        <Button value={symbol ? '_' : shift ? 'S' : 's'} />
        <Button value={symbol ? '/' : shift ? 'D' : 'd'} />
        <Button value={symbol ? '\\' : shift ? 'F' : 'f'} />
        <Button value={symbol ? '|' : shift ? 'G' : 'g'} />
        <Button value={symbol ? '~' : shift ? 'H' : 'h'} />
        <Button value={symbol ? '<' : shift ? 'J' : 'j'} />
        <Button value={symbol ? '>' : shift ? 'K' : 'k'} />
        <Button value={symbol ? '$' : shift ? 'L' : 'l'} />
        <Button value={symbol ? '£' : shift ? 'Ö' : 'ö'} />
        <Button value={symbol ? '€' : shift ? 'Ä' : 'ä'} />
        <Button variant="secondary" value="⏎" onClick={submit} />
      </Row>
      <Row>
        {symbol ? (
          <Button value=":" />
        ) : (
          <Shift toggleShift={toggleShift} shift={shift} />
        )}
        <Button value={symbol ? ';' : shift ? 'Z' : 'z'} />
        <Button value={symbol ? '?' : shift ? 'X' : 'x'} />
        <Button value={symbol ? '!' : shift ? 'C' : 'c'} />
        <Button value={symbol ? "'" : shift ? 'V' : 'v'} />
        <Button value={symbol ? '"' : shift ? 'B' : 'b'} />
        <Button value={symbol ? '#' : shift ? 'N' : 'n'} />
        <Button value={symbol ? '&' : shift ? 'M' : 'm'} />
        <Button value={symbol ? ',' : shift ? '!' : ','} />
        <Button value={symbol ? '.' : shift ? '?' : '.'} />
        {symbol ? (
          <Button value="%" />
        ) : (
          <Shift toggleShift={toggleShift} shift={shift} />
        )}
      </Row>
      <Row>
        <Button
          variant="gray"
          value={symbol ? 'abc' : '@.?'}
          onClick={toggleSymbol}
          colspan={1.25}
        />
        <Button variant="gray" value=" " colspan={7} />
        <Button value="@" />
        <Button variant="accent" value="↓" onClick={() => setMinimal(true)} />
      </Row>
    </div>
  );
};

export default OnScreenKeyboard;
