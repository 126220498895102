import {
  ProductGroup,
  ZipActionTypes,
  SET_ZIP_CAPTURE_PRODUCT_GROUPS,
  SET_ZIP_CODE,
  REMOVE_ZIP_CODE,
} from './types';
import { combineReducers } from 'redux';

const initialState: ProductGroup[] = [];

const all = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_ZIP_CAPTURE_PRODUCT_GROUPS:
      return payload;
    default:
      return state;
  }
};

const initialZip = '';

const zip = (state = initialZip, { type, payload }: any) => {
  switch (type) {
    case SET_ZIP_CODE:
      return payload;
    case REMOVE_ZIP_CODE:
      return '';
    default:
      return state;
  }
};

const reducer = combineReducers({
  all,
  zip,
});

export type ZipState = ReturnType<typeof reducer>;

export default reducer;
