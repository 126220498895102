import React from 'react';
import { useSelector } from 'react-redux';

import { getUseCreateCustomerBeta } from 'reducers/configs/settings';

import CreateCustomerBeta from '../CreateCustomerBeta';

import CreateCustomer from './CreateCustomer';

export default props => {
  const shouldUseCreateCustomerBeta = useSelector(getUseCreateCustomerBeta);
  if (shouldUseCreateCustomerBeta) return <CreateCustomerBeta {...props} />;
  return <CreateCustomer {...props} />;
};
