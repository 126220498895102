import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentSalesDocument } from 'reducers/sales';
import { removeProduct } from 'actions/ShoppingCart';
import TableBill from 'components/tableBill/TableBill';

import { setAdmissionTaxInShoppingCartcounter } from '../index';
import { SHOULD_ADD_ADMISSION_TAX } from '../types';
import {
  getAdmissionTaxFromCart,
  getConfiguration,
  getEnvProductFromShoppingCart,
} from '../rdx';

const UITableBill = ({ products }) => {
  const dispatch = useDispatch();
  const currentConfig = useSelector(getConfiguration);

  const { taxExemptCertificateNumber } = useSelector(getCurrentSalesDocument);
  const environmentalFeeProduct = useSelector(getEnvProductFromShoppingCart);
  const admissionTaxProduct = useSelector(getAdmissionTaxFromCart);

  useEffect(() => {
    if (taxExemptCertificateNumber?.trim()?.length && admissionTaxProduct) {
      dispatch({
        type: SHOULD_ADD_ADMISSION_TAX,
        payload: false,
      });
      dispatch(removeProduct(admissionTaxProduct.orderIndex));
      setAdmissionTaxInShoppingCartcounter(0);
    }
  }, [taxExemptCertificateNumber]);

  let productsToDisplay: Array<any> = [...products];

  if (currentConfig.envFeeEnabled) {
    if (
      !currentConfig ||
      !currentConfig.envFeeName ||
      currentConfig.envFeeName === ''
    ) {
      return <TableBill shoppingCartProducts={products} />;
    }

    productsToDisplay = products.filter(
      prod => prod.productID !== environmentalFeeProduct?.productID,
    );
  }

  if (currentConfig.CAFeeEnabled) {
    if (
      !currentConfig ||
      !currentConfig.admissionFeeName ||
      currentConfig.admissionFeeName === '' ||
      !currentConfig.admissionTaxName ||
      currentConfig.admissionTaxName === ''
    ) {
      return <TableBill shoppingCartProducts={products} />;
    }

    productsToDisplay = productsToDisplay.filter(
      prod => prod.productID !== admissionTaxProduct?.productID,
    );
  }

  return <TableBill shoppingCartProducts={productsToDisplay} />;
};

export default UITableBill;
