import React, { Children } from 'react';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { matches } from '../utils';

const UIAdvancedSearchTableColumns: PosPlugin['UIAdvancedSearchTableColumns'] = ({
  children,
}) => {
  return R.pipe(Children.toArray, R.reject(matches(/birthday/)))(children);
};

export default UIAdvancedSearchTableColumns;
