import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import { getAllWarehouses } from 'reducers/warehouses';

export const WarehouseInput = (
  props: TextFieldProps & { allowNone?: boolean },
) => {
  const warehouses = useSelector(getAllWarehouses);

  return (
    <TextField select {...props}>
      {props?.allowNone && (
        <MenuItem key="-" value={undefined}>
          -
        </MenuItem>
      )}
      {warehouses.map(({ name, warehouseID }) => (
        <MenuItem key={warehouseID} value={warehouseID}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};
