import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getIsEditMode } from 'reducers/UI/productEditMode';
import { disableProductEditMode, enableProductEditMode } from 'actions/UI';

import HeaderIcon from '../Icon';

const ProductEditMode = () => {
  const { t } = useTranslation('header');
  const isEditMode = useSelector(getIsEditMode);
  const dispatch = useDispatch();
  const onClick = useCallback(
    () =>
      isEditMode
        ? dispatch(disableProductEditMode())
        : dispatch(enableProductEditMode()),
    [isEditMode],
  );
  return (
    <HeaderIcon
      className="erply-header__icon--edit"
      data-testid="header-edit"
      icon="edit"
      title={t('titles.edit')}
      onClick={onClick}
    />
  );
};

export default ProductEditMode;
