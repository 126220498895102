import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  List,
  ListItem,
  styled,
  Typography,
} from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getHasRightToReturn } from 'reducers/Login';
import {
  cancelLayaway,
  payFullyForLayaway,
  payPartiallyForLayaway,
} from 'actions/sales';
import { RootState } from 'reducers';

import { SaleDocumentResponse as SalesDocument } from '../../../types/SalesDocument';

const StyledListItem = styled(ListItem)({
  borderRadius: '6px',
});

const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
});

const ActionSelection: FC<{ sale: SalesDocument; onClose: () => void }> = ({
  sale,
  onClose,
}) => {
  const { t } = useTranslation('layaway', { keyPrefix: 'actionSelector' });
  const hasRightToReturn = useSelector(getHasRightToReturn);
  const dispatch = useDispatch<
    ThunkDispatch<RootState, unknown, Action<string>>
  >();

  async function cancel() {
    await dispatch(cancelLayaway(sale));
  }

  async function payFully() {
    await dispatch(payFullyForLayaway(sale));
  }

  async function partialPay() {
    await dispatch(payPartiallyForLayaway(sale));
  }

  return (
    <Box padding="1rem" data-testid="layaway-action-selection-modal">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box data-testid="layaway-action-selection-title">
          <Typography variant="h5">{t('title')}</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <CloseButton
            style={{ transform: 'scale(0.85)' }}
            action={onClose}
            key={`layaway-${sale.id}-action-selection-close-button`}
            data-testid="layaway-action-selection-close-button"
          />
        </Box>
      </Box>
      <Divider style={{ marginBottom: '1em' }} />
      <StyledList>
        <StyledListItem
          button
          className="dark_list_item"
          onClick={payFully}
          key={`layaway-${sale.id}-action-selection-fullyPay-button`}
          data-testid="layaway-action-selection-fullyPay-button"
        >
          <Typography variant="h6">{t('payFully')}</Typography>
        </StyledListItem>
        <StyledListItem
          button
          className="dark_list_item"
          onClick={partialPay}
          key={`layaway-${sale.id}-action-selection-partiallyPay-button`}
          data-testid="layaway-action-selection-partiallyPay-button"
        >
          <Typography variant="h6">{t('payPartially')}</Typography>
        </StyledListItem>
        <StyledListItem
          button
          className="dark_list_item"
          style={{ cursor: 'pointer' }}
          onClick={cancel}
          disabled={!hasRightToReturn}
          key={`layaway-${sale.id}-action-selection-cancel-button`}
          data-testid="layaway-action-selection-cancel-button"
        >
          <Typography variant="h6">{t('cancel')}</Typography>
        </StyledListItem>
      </StyledList>
    </Box>
  );
};

export default ActionSelection;
