export const UPDATE_REASON_CODES = 'reasonCodesDB:UPDATE';

export const REASONS = {
  WRITEOFF: 'WRITEOFF',
  RETURN: 'RETURN',
  DISCOUNT: 'DISCOUNT',
  REGISTRATION: 'REGISTRATION',
  EOD_VARIANCE: 'EOD_VARIANCE',
  TAX_EXEMPTION: 'TAX_EXEMPTION',
  CASH_IN: 'CASH_IN',
  CASH_OUT: 'CASH_OUT',
  PROMOTION: 'PROMOTION',
} as const;
