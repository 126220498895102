import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

import DropdownSearch from '../../DropdownSearch';

import './CustomTable.sass';

const HeaderCell = ({
  title,
  setSorting,
  setFiltering,
  active,
  ascending,
  keyValue,
}) => {
  const [show, setShow] = useState(false);
  const setFilter = options => setFiltering({ ...options, keyValue });
  return (
    <th onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      <div className={`header-cell header-cell-${keyValue}`}>
        <div
          style={{
            fontWeoght: active ? 700 : 400,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
          onClick={setSorting}
        >
          <div>{title}</div>
          {active && (
            <span style={{ marginLeft: 'auto', marginRight: '.5em' }}>
              <span className={ascending ? 'arrow_down' : 'arrow_up'} />
            </span>
          )}
        </div>
        <DropdownSearch keyValue={keyValue} show={show} setFilter={setFilter} />
      </div>
    </th>
  );
};
const TableHead = ({ columns = [], creteria, ascending, setSorting }) => (
  <thead>
    <tr>
      {columns.map(({ key, title }) => (
        <HeaderCell
          key={key}
          keyValue={key}
          title={title}
          creteria={creteria}
          ascending={ascending}
          active={key === creteria}
          setSorting={() => setSorting(key)}
        />
      ))}
    </tr>
  </thead>
);

const TableRow = ({ columns = [], state = {} }) => (
  <tr>
    {columns.map(({ key }) => (
      <td key={key}>{state[key]}</td>
    ))}
  </tr>
);

const TableBody = ({ columns = [], data = [] }) => (
  <tbody>
    {data.map(d => (
      <TableRow key={d.id} columns={columns} state={d} />
    ))}
  </tbody>
);

const CustomTable = ({
  columns = [],
  data = [],
  setSorting,
  creteria,
  ascending,
  ...props
}) => {
  return (
    <Table {...props}>
      <TableHead
        columns={columns}
        setSorting={setSorting}
        creteria={creteria}
        ascending={ascending}
      />
      <TableBody columns={columns} data={data} />
    </Table>
  );
};

export default CustomTable;
