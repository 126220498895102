import { HIDE, SHOW, ENABLE, DISABLE } from 'constants/OnScreenKeyboard';

export function hideKeyboard() {
  return {
    type: HIDE,
  };
}
export function showKeyboard() {
  return {
    type: SHOW,
  };
}
export function setKeyboardVisibility(visible) {
  return {
    type: visible ? SHOW : HIDE,
  };
}

export function enableKeyboard() {
  return {
    type: ENABLE,
  };
}
export function disableKeyboard() {
  return {
    type: DISABLE,
  };
}
export function setKeyboardEnabled(enabled) {
  return {
    type: enabled ? ENABLE : DISABLE,
  };
}
