import React, { FC } from 'react';
import { ButtonBase, ButtonBaseProps, styled, Theme } from '@material-ui/core';

type StyledButtonProps = ButtonBaseProps & {
  color?: keyof Theme['palette'];
  hasIcon?: boolean;
};

const StyledButton = styled(ButtonBase)<Theme, StyledButtonProps>(
  ({ theme, hasIcon, color }) => {
    const buttonColor = color ? theme.palette[color] : null;
    const buttonColorStyles =
      !!buttonColor && typeof buttonColor === 'object' && 'main' in buttonColor
        ? {
            color: theme.palette.getContrastText(buttonColor.main),
            backgroundColor: buttonColor.main,
            '&:hover': {
              color: theme.palette.getContrastText(buttonColor.dark),
              backgroundColor: buttonColor.dark,
            },
            '&:focus, &:active': {
              color: theme.palette.getContrastText(buttonColor.light),
              backgroundColor: buttonColor.light,
            },
            '&:disabled': {
              color: theme.palette.action.disabled,
              backgroundColor: theme.palette.action.disabledBackground,
            },
          }
        : {};
    return {
      position: 'relative',
      height: '65px',
      width: '100%',
      padding: '0.5rem',
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      fontWeight: 400,
      ...buttonColorStyles,

      '& > span': {
        display: 'block',
        width: '100%',
        paddingTop: hasIcon ? '2rem' : 0,
      },

      '& .shortcut': {
        position: 'absolute',
        top: '2px',
        fontSize: '12px',
      },

      '& .icon': {
        position: 'absolute',
        bottom: '-5px',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        '& svg': {
          stroke: theme.typography.button.color,
        },
      },
    };
  },
);

type Props = StyledButtonProps & {
  title: string;
  shortcut?: string;
  action: () => void;
  icon?: JSX.Element;
};

const ConfirmOptionButton: FC<Props> = ({
  title,
  shortcut,
  children,
  style,
  action,
  disabled,
  icon,
  ...props
}) => {
  return (
    <StyledButton
      disableRipple
      disableTouchRipple
      hasIcon={!!icon}
      onClick={() => action && action()}
      style={{ ...style }}
      disabled={disabled}
      {...props}
    >
      <span>
        {shortcut && <p className="shortcut">{shortcut}</p>}
        {icon && <p className="icon">{icon}</p>}
        {title}
        {children}
      </span>
    </StyledButton>
  );
};

export default ConfirmOptionButton;
