import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowProps,
  Theme,
  styled,
} from '@material-ui/core';

import { getDocSelected, getSalesDocs } from 'reducers/returnProducts';
import { setDocSelected } from 'actions/returnProducts';
import { PluginComponent } from 'plugins';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { addWarning } from 'actions/Error';
import { cancelLayaway } from 'actions/sales';

const StyledTableRow = styled(TableRow)<
  Theme,
  TableRowProps & { disabled: boolean }
>(({ disabled }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.4 : 1,
}));

const DocumentSelection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('return');

  const salesDocs: SaleDocumentResponse[] = useSelector(getSalesDocs);
  const docSelected = useSelector(getDocSelected);

  const beautifyType = type =>
    t('documentTypes.documentType', { context: type });

  return (
    <PluginComponent
      hookname="UISalesDocumentSelection"
      props={{ salesDocs, docSelected, beautifyType }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell data-testid="document-number-header">
              {t('headers.number')}
            </TableCell>
            <TableCell data-testid="client-name-header">
              {t('headers.customer')}
            </TableCell>
            <TableCell data-testid="document-type-header">
              {t('headers.type')}
            </TableCell>
            <TableCell data-testid="document-date-header">
              {t('headers.date')}
            </TableCell>
            <TableCell data-testid="document-total-header">
              {t('headers.sum')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salesDocs.map(document => {
            const typeSupportsStandardReturn = ![
              'ORDER',
              'CREDITINVOICE',
              'QUOTE',
              'OFFER',
              'WAYBILL',
            ].includes(document?.type);
            const isLayawayCancellation = document?.type === 'PREPAYMENT';
            return (
              <StyledTableRow
                hover
                disabled={!typeSupportsStandardReturn}
                selected={docSelected === document.id}
                key={document.id}
                data-testid="document-row"
                data-test-key={document.id}
                onClick={() => {
                  if (!typeSupportsStandardReturn) return;
                  if (isLayawayCancellation) {
                    if (document.invoiceState === 'CANCELLED') {
                      dispatch(addWarning(t('alerts.alreadyCancelled')));
                    } else {
                      dispatch(cancelLayaway(document));
                    }
                    return;
                  }
                  dispatch(setDocSelected(document.id));
                }}
              >
                <TableCell
                  data-testid="document-number"
                  data-test-key={document.number}
                >
                  {document.number}
                </TableCell>
                <TableCell
                  data-testid="client-name"
                  data-test-key={document.clientName}
                >
                  {document.clientName}
                </TableCell>
                <TableCell
                  data-testid="document-type"
                  data-test-key={document.type}
                >
                  {beautifyType(document.type)}
                </TableCell>
                <TableCell
                  data-testid="document-date"
                  data-test-key={document.date}
                >
                  {document.date}
                </TableCell>
                <TableCell
                  data-testid="document-total"
                  data-test-key={document.total}
                >
                  {parseFloat(String(document.total)).toFixed(2)}
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </PluginComponent>
  );
};

export default DocumentSelection;
