/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getLoggedInEmployeeLastClockInTime } from 'reducers/clockInOut';
import {
  getCurrencyFormatterNoSymbol,
  getCurrencySymbol,
} from 'reducers/configs/settings';
import UserBadge from 'components/userBadge/UserBadge';
import TableStats from 'components/tableStats/TableStats';
import { getLoggedInEmployeeID, getUserSalesData } from 'reducers/Login';
import useEmployeeHook from 'utils/hooks/useEmployeeHook';
import { getEmployeesStats } from 'services/ErplyAPI/api';
import './employeeStats.scss';
import { setUserSalesData } from 'actions/Login';
import { useRelativeTime } from 'utils/hooks/useRelativeTime';
import { Employee } from 'types/Employee';

const pad = (s: number) => s.toString().padStart(2, '0');

const millisToTime = millis => {
  const time = Math.floor(millis / 1000);
  const minutes = Math.floor(time / 60);
  const hours = Math.floor(minutes / 60);
  return `${hours}:${pad(minutes % 60)}`;
};

const decimalToMillis = (decimal: number | string) => {
  const normalized = decimal?.toString().replace(/[:,]/g, '.');
  const hours = parseFloat(normalized);
  return hours * 60 * 60 * 1000;
};

const formatLargeNumber = (number: number) => {
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } as Intl.NumberFormatOptions);
  return formatter.format(number);
};

const formatSales = (totalSales, CURR) => {
  return {
    value:
      totalSales >= 1e6
        ? formatLargeNumber(totalSales)
        : CURR.stringify(totalSales),
    fullValue: CURR.stringify(totalSales),
  };
};

const EmployeeStats = () => {
  const loggedInEmployeeLastClockInTime = useSelector(
    getLoggedInEmployeeLastClockInTime,
  );

  const dispatch = useDispatch();

  const { t } = useTranslation('employeeStats');
  const { t: t2 } = useTranslation('common');
  const { t: t3 } = useTranslation('customer');

  const loggedInEmployeeID = useSelector(getLoggedInEmployeeID);
  const salesData = useSelector(getUserSalesData);
  const [employeeAtWork, loadingEmployee, employeeError] = useEmployeeHook(
    loggedInEmployeeID,
  );
  const [fetchingEmployeeStats, setFetchingEmployeeStats] = useState(false);
  const [employeeStatsError, setEmployeeStatsError] = useState(false);

  const {
    totalSalesThisMonth,
    totalSalesThisWeek,
    totalSalesYesterday,
    totalSalesToday,
    timeWorkedThisMonth,
    timeWorkedThisWeek,
    timeWorkedYesterday,
    timeWorkedToday,
    lastClockInUnixTime,
  } = salesData ?? {};

  const currentTimeWorked =
    useRelativeTime({
      anchorTime: lastClockInUnixTime * 1000 || undefined,
      resolution: 60e3, // One minute resolution
    }) ?? 0;

  const loading = loadingEmployee || fetchingEmployeeStats;

  const setEmployeeData = useCallback(() => {
    setFetchingEmployeeStats(true);
    getEmployeesStats()
      .then(data => {
        if (!data?.length) throw new Error('employeeStats response is empty');

        setEmployeeStatsError(false);
        dispatch(setUserSalesData(data[0]));
      })
      .catch(e => {
        console.error('Failed to update user sales data', e);
        setEmployeeStatsError(true);
      })
      .finally(() => setFetchingEmployeeStats(false));
  }, [dispatch]);

  useEffect(() => {
    if (salesData === null) {
      setEmployeeData();
    }
  }, [salesData, setEmployeeData]);

  // Do math
  const today = decimalToMillis(timeWorkedToday ?? 0);
  const yesterday = decimalToMillis(timeWorkedYesterday ?? 0);
  const week = decimalToMillis(timeWorkedThisWeek ?? 0);
  const month = decimalToMillis(timeWorkedThisMonth ?? 0);

  // For use in the sales header
  const currentCurrencySymbol = useSelector(getCurrencySymbol);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);
  const times = ['today', 'yesterday', 'week', 'month'];

  const dataHeaders = useMemo(
    () => times.map(name => t(`hours.headers.${name}`).toUpperCase()),
    [times, t],
  );

  const salesHeaders = useMemo(
    () => times.map(name => t(`sales.headers.${name}`).toUpperCase()),
    [times, t],
  );

  const saleData = useMemo(
    () => [
      formatSales(totalSalesToday ?? 0, CURR),
      formatSales(totalSalesYesterday ?? 0, CURR),
      formatSales(totalSalesThisWeek ?? 0, CURR),
      formatSales(totalSalesThisMonth ?? 0, CURR),
    ],
    [
      totalSalesToday,
      CURR,
      totalSalesYesterday,
      totalSalesThisWeek,
      totalSalesThisMonth,
    ],
  );

  const title = t('hours.title');
  const salesTitle = t('sales.title', {
    currencySymbol: currentCurrencySymbol,
  });

  const data = useMemo(
    () => [
      millisToTime(today + currentTimeWorked),
      millisToTime(yesterday),
      millisToTime(week + currentTimeWorked),
      millisToTime(month + currentTimeWorked),
    ],
    [today, yesterday, week, month, currentTimeWorked],
  );

  const getCustomerName = () => {
    if (loading && !employeeAtWork) {
      return t2('loading');
    }

    if (Boolean(employeeError || employeeStatsError) && !loading) {
      return t('failedToFetch');
    }

    if (employeeAtWork) {
      const { firstName, lastName } = employeeAtWork;
      return `${firstName} ${lastName}`;
    }

    return t3('noSelectedCustomer');
  };

  return (
    <Container>
      <>
        <Row>
          <Container className="customer-stats-container">
            <h4>{t('title').toUpperCase()}</h4>
          </Container>
        </Row>
        <Row>
          <Col>
            <UserBadge
              customerName={getCustomerName()}
              customerGroup=""
              customerRewardPoints={0}
              isCustomer={false}
              totalVisitsToStore={0}
              isDefaultCustomer={true}
              customerCardNumber=""
              loggedInEmployeeLastClockInTime={loggedInEmployeeLastClockInTime}
              isEmployeeError={Boolean(employeeError || employeeStatsError)}
              onRetryEmployeeData={setEmployeeData}
              isLoadingEmployee={loading}
            />
          </Col>
        </Row>
        <Row>
          <TableStats
            title={title}
            dataHeaders={dataHeaders}
            data={data}
            isCustomer={false}
          />
        </Row>
        <Row>
          <TableStats
            title={salesTitle}
            dataHeaders={salesHeaders}
            data={saleData}
            isCustomer={false}
          />
        </Row>
      </>
    </Container>
  );
};

export default React.memo(EmployeeStats);
