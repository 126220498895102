import React from 'react';
import {
  Theme,
  capitalize,
  withStyles,
  lighten,
  darken,
} from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { AlertProps, Alert as MuiAlert, Color } from '@material-ui/lab';

export function styles(theme: Theme) {
  const severities: Color[] = ['success', 'info', 'warning', 'error'];

  return Object.fromEntries(
    severities.flatMap(colorName => {
      const color: PaletteColor = theme.palette[colorName];
      const getBackgroundColor =
        theme.palette.type === 'light' ? lighten : darken;
      return [
        [
          `filled${capitalize(colorName)}-contrast`,
          {
            color: color.contrastText,
            '&.MuiPaper-root': {
              borderColor: '#0000',
            },
          },
        ],
        [
          `standard${capitalize(colorName)}-contrast`,
          {
            background: getBackgroundColor(color.main, 0.8),
          },
        ],
      ];
    }),
  );
}

function Alert(props: AlertProps) {
  const { classes, variant, color, severity } = props;
  return (
    <MuiAlert
      {...props}
      className={
        classes?.[`${variant}${capitalize(color || severity || '')}-contrast`]
      }
    />
  );
}

export default withStyles(styles, { name: 'MuiAlert' })(Alert);
