import React, { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

export default () => {
  const [state, setState] = useState<(Record<string, string> | string)[][]>([]);

  useEffect(() => {
    const original = document.body.ondblclick;
    document.body.ondblclick = () => setState([]);
    window.visibleLog = (...params) => setState(l => [...l, params]);

    return () => {
      document.body.ondblclick = original;
      delete window.visibleLog;
    };
  }, []);

  const memoizedState = useMemo(
    () =>
      state.map(params => (
        <div>
          {params
            .map(param =>
              param instanceof Object ? JSON.stringify(param) : param,
            )
            .join(' ')}
        </div>
      )),
    [state],
  );

  return ReactDOM.createPortal(
    <div
      style={{
        whiteSpace: 'pre-wrap',
        opacity: 0.5,
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 10000,
        overflow: 'hidden',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      {memoizedState}
    </div>,
    document.body,
  );
};
