import React, { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { getAllMSEndpointsFromLS } from 'reducers/installer';

import { DEFiscalIntegration } from '../API/types/DE/DE';
import { getPluginConfiguration } from '../../../reducers/Plugins';
import {
  EFSTA_ID,
  EFSTAConf,
  EFSTAConfCompany,
  EFSTAConfPos,
  EFSTAConfUser,
  EFSTAConfWarehouse,
} from '../types';
import { PosPlugin } from '../../plugin';
import { NULLFiscalIntegration } from '../API/FiscalIntegration';

const getSelectedCountry = createSelector(
  getPluginConfiguration<EFSTAConf>(EFSTA_ID),
  state => state.country,
);
export const getFiscalIntegrationForCountry = country => {
  switch (country) {
    case 'DE':
      return DEFiscalIntegration;
    default:
      return NULLFiscalIntegration;
  }
};
export const getFiscalIntegrationForSelectedCountry = createSelector(
  getSelectedCountry,
  country => getFiscalIntegrationForCountry(country),
);

// IMPORTANT: Not using createSelector on purpose to ensure LS changes are picked up
// DO NOT ADD createSelector/other memoization
export const getEFSTAEndpoint = state =>
  (getPluginConfiguration<EFSTAConf>(EFSTA_ID)(state)?.endpoint ||
    getAllMSEndpointsFromLS()['efsta-fiscal-proxy']) ??
  `https://localhost.erply.com:10123/`;

// Todo add translations
const ComponentConfiguration: PosPlugin<
  EFSTAConf,
  EFSTAConfCompany,
  EFSTAConfWarehouse,
  EFSTAConfPos,
  EFSTAConfUser
>['ComponentConfiguration'] = params => {
  const {
    byLevel: { company, pos },
  } = params;

  const fromCurrent = useMemo(
    () => ({
      country: company.current?.country,
      endpoint: pos.current?.endpoint,
    }),
    [company.current?.country, pos.current?.endpoint],
  );
  const [conf, setConf] = useState(fromCurrent);
  useEffect(() => setConf(R.assoc('country', fromCurrent?.country)), [
    fromCurrent.country,
  ]);
  useEffect(() => setConf(R.assoc('endpoint', fromCurrent?.endpoint)), [
    fromCurrent.endpoint,
  ]);
  const Conf: any = getFiscalIntegrationForCountry(conf.country)
    .ComponentConfiguration;

  return (
    <Grid container spacing={2}>
      <Box>
        <Button
          disabled={R.equals(fromCurrent, conf)}
          onClick={() => {
            if (!R.equals(conf.country, fromCurrent.country))
              company.save({ country: conf.country });
            if (!R.equals(conf.endpoint, fromCurrent.endpoint))
              pos.save({ endpoint: conf.endpoint });
          }}
        >
          Save
        </Button>
      </Box>
      <TextField
        fullWidth
        placeholder="https://localhost.erply.com:10123/"
        label="Custom endpoint to use (leave blank if using EFSTA via installer)"
        value={conf?.endpoint ?? ''}
        onChange={R.pipe(
          R.path(['target', 'value']),
          R.assoc('endpoint'),
          setConf,
        )}
      />
      <Box>
        <Select
          value={conf.country ?? ''}
          onChange={R.pipe(
            R.path(['target', 'value']),
            R.assoc('country'),
            setConf,
          )}
          label="Select country"
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value="DE">Deutchland</MenuItem>
        </Select>
      </Box>
      {Conf && <Conf {...params} />}
    </Grid>
  );
};

// Sanity checks for configuration
// * Payment mapping ?
// * Tax rates have accounting codes A through I

export default ComponentConfiguration;
// * Container products register as TPY=Dep
// * When company is customer, include company information
