import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getProductGroups } from 'reducers/productGroupsDB';
import { addWarning } from 'actions/Error';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import { ErplyAttributes } from 'utils';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { RootState } from 'reducers';

import { Ctx, Check } from '../../components/CtxInputs';

const ProductGroupsEditMode: React.FC<{
  onClose: () => void;
  children?: never;
}> = ({ onClose }) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { t } = useTranslation('productGroupEditTexts');

  const productGroups = useSelector(getProductGroups).map(g => ({
    ...g,
    attributes: new ErplyAttributes(g.attributes),
  }));

  const hiddenGroups =
    useSelector(
      getCafaEntry<
        '',
        'posHiddenProductGroups',
        { hiddens: Record<number, number> }
      >('posHiddenProductGroups', ''),
    )?.value.hiddens ?? {};

  const [hiddens, setHiddens] = useState(hiddenGroups);

  const changeVisibility = (key: string, value: unknown) =>
    setHiddens(curr => ({ ...curr, [key]: value }));

  const onSave = () => {
    dispatch(addWarning('Saving changes'));
    dispatch(
      saveCafaConfig({
        integrationName: 'posHiddenProductGroups',
        integrationType: '',
        config: {
          hiddens,
        },
      }),
    ).then(onClose);
  };

  return (
    <Ctx.Provider value={{ values: hiddens, onChange: changeVisibility }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="1rem"
        data-testid="hide-product-groups"
      >
        <Typography data-testid="title" variant="h5">
          {t('title')}
        </Typography>
        <Box display="flex" alignItems="center">
          <SaveButton action={onSave} variant="POS" data-testid="save-btn" />
          <CloseButton action={onClose} />
        </Box>
      </Box>
      <Table data-testid="table">
        <TableHead data-testid="table-head">
          <TableRow>
            <TableCell data-testid="name">{t('texts.name')}</TableCell>
            <TableCell data-testid="parent-group-id">
              {t('texts.parentGroupID')}
            </TableCell>
            <TableCell data-testid="hide-button">
              {t('texts.hideButton')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="table-body">
          {productGroups?.map(({ name, parentGroupID, id }) => (
            <TableRow data-testid="table-row" data-test-key={name} key={id}>
              <TableCell style={{ padding: '0 1rem' }} data-testid="name">
                {name}
              </TableCell>
              <TableCell
                style={{ padding: '0 1rem' }}
                data-testid="parent-group-id"
              >
                {Number(parentGroupID) || ''}
              </TableCell>
              <TableCell style={{ padding: '0 1rem' }} data-testid="checkbox">
                <Check name={id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Ctx.Provider>
  );
};

export default ProductGroupsEditMode;
