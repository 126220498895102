import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getSelectedCustomerID } from 'reducers/customerSearch';
import { addError, addWarning } from 'actions/Error';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';

import { PosPlugin } from '../../../plugin';

const PREVIOUS_CUSTOMER_ID = 'previousCustomerID';

export const savePreviousCustomerIdToLocalStorage: Required<
  PosPlugin
>['onSaveSalesDocument']['after'] = (p, o) => async () => {
  const customerID = o.requests.find(
    request => request.requestName === 'saveSalesDocument',
  )?.customerID;
  if (customerID) {
    localStorage.setItem(PREVIOUS_CUSTOMER_ID, customerID);
  }
};

const selectPreviousCustomer = async (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
  getState,
) => {
  const previousCustomerId = localStorage.getItem(PREVIOUS_CUSTOMER_ID);
  if (!previousCustomerId || !previousCustomerId?.length) {
    dispatch(addError('No previous customer'));
    return;
  }
  const selectedCustomerId = getSelectedCustomerID(getState());
  if (previousCustomerId === String(selectedCustomerId)) {
    dispatch(addWarning('Previous customer already selected'));
    return;
  }

  dispatch(setCustomer({ data: previousCustomerId }));
};

export const previousCustomerButton = {
  id: 'previousCustomer',
  actionType: 'action' as 'action',
  action: selectPreviousCustomer,
};

export const clearPreviousCustomerId: Required<
  PosPlugin
>['onCloseDay']['after'] = () => async () => {
  localStorage.removeItem(PREVIOUS_CUSTOMER_ID);
};
