import {
  API,
  currentConfigs$,
  InstallerStatus,
  MSTransitionalStatus,
} from '@pos-refacto/installer-rxjs';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useInterval, useObservable } from 'react-use';

import { getIsLoadingCafa } from 'reducers/cafaConfigs';
import { intersect, intersectEquals, sleep } from 'utils';

import {
  useMSAccessible,
  useMSRequirements,
  useOtherMSRequirements,
} from './useMSRequirements';

const getVersionToInstall = async (
  entity: string,
  currentVersion: string | undefined,
  targetVersion: string | undefined,
) => {
  if (!targetVersion && currentVersion) return currentVersion;

  const builds = await API.getIntegrationVersion(entity).then(
    ({ data: { builds } }) => builds,
  );
  const latest = builds.slice(-1)[0];
  if (targetVersion === 'latest') return latest;
  if (!targetVersion) return latest;

  if (!builds.includes(targetVersion))
    throw new Error(
      `Config requires ${entity} version to be ${targetVersion}, but only the following versions are available: ${builds}`,
    );

  return targetVersion;
};

const manualUpdateIntegration = async (
  entity: string,
  currentVersion: string,
  targetVersion: string,
) => {
  let config = localStorage.getItem(`20FEB23-workaround-${entity}`);
  if (!config) {
    config = await API.getIntegrationConfigValue(entity, currentVersion)
      .then(res => res.data.config)
      .then(
        conf => {
          localStorage.setItem(`20FEB23-workaround-${entity}`, conf as string);
          return conf;
        },
        () => {
          console.warn(`Unable to get conf of ${entity} that needs update`);
          return null;
        },
      );
  }
  await API.removeIntegration(entity, currentVersion);
  await API.installIntegration(entity, targetVersion);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  await setConfigIfInterrupted(entity, targetVersion);
};

const setConfigIfInterrupted = async (entity: string, version: string) => {
  const config = localStorage.getItem(`20FEB23-workaround-${entity}`);
  if (config) {
    await API.saveIntegrationConfig(entity, version, config);
    localStorage.removeItem(`20FEB23-workaround-${entity}`);
  }
};

const updateMS = (
  status: InstallerStatus,
  expected: { [entity: string]: { version?: string; config: any } },
) => {
  const allUpdateFns = Object.entries(expected).map(
    ([entity, { version: targetVersion, config: conf }]) => async () => {
      const integration = status.data.integrations?.find(
        int => int.name === entity,
      );
      const currentStatus = integration?.presumedStatus ?? integration?.status;

      // Already processing, stop
      if (Object.values(MSTransitionalStatus).includes(currentStatus as any)) {
        return false;
      }
      // Target version
      const version = await getVersionToInstall(
        entity,
        integration?.version,
        targetVersion,
      );
      // Install if not correct version
      if (currentStatus === undefined) {
        await API.installIntegration(entity, version);
      } else if (version !== integration?.version) {
        await manualUpdateIntegration(
          entity,
          integration?.version as string,
          version,
        );
      }
      await sleep(0.5);
      await setConfigIfInterrupted(entity, version);
      // Check config
      const config = await API.getIntegrationConfigValue(
        entity,
        version,
      ).then(res => JSON.parse(res.data.config));
      const needsConfUpdate = !intersectEquals(conf, config);
      // Stop if needs conf update
      if (currentStatus === 'StatusRunning' && needsConfUpdate) {
        await API.stopIntegrationProcess(entity, version);
      }

      // Update conf if needed
      if (needsConfUpdate) {
        await API.saveIntegrationConfig(
          entity,
          version,
          JSON.stringify(
            R.mergeDeepRight(config, intersect(config, conf)),
            undefined,
            4,
          ),
        );
      }

      // start integration
      if (currentStatus !== 'StatusRunning' || needsConfUpdate) {
        await API.startIntegrationProcess(entity, version);
      }
      return true;
    },
  );

  // Process all updates
  //  * in sequence
  //  * Continuing anyway even if there's an error
  //  * Return whether all succeeded
  return allUpdateFns.reduce(
    (prev, fn) =>
      prev
        .catch(err => {
          console.error(err);
          return false;
        })
        .then(resultSoFar =>
          fn().then(currentResult => resultSoFar && currentResult),
        ),
    Promise.resolve(true),
  );
};

type InfoResponse = ReturnType<typeof API.getInfo> extends Promise<infer X>
  ? X
  : never;
const useInstallerStatus = () => {
  const [status, setStatus] = useState<InfoResponse | undefined>(undefined);
  const [latest, setLatest] = useState<InfoResponse | Error | undefined>(
    undefined,
  );

  const update = useCallback(
    () =>
      API.getInfo().then(
        res => {
          setStatus(res);
          setLatest(res);
        },
        err => {
          setLatest(err);
        },
      ),
    [],
  );
  useEffect(() => {
    update();
  }, [update]);

  return {
    status: status as InstallerStatus,
    latest: latest as InstallerStatus,
    update,
  };
};

export const useAutoInstallMS = () => {
  const [result, setResult] = useState<true | undefined | Error>(true);
  const [blockAutoInstall, setBlockAutoInstall] = useState(false);
  const {
    status: installerStatus,
    latest: installerStatusLatest,
    update: refreshStatus,
  } = useInstallerStatus();
  const expected = useMSRequirements(installerStatus);
  const nonRequired = useOtherMSRequirements(installerStatus);
  const msAccessible = useMSAccessible(installerStatus, expected);
  const configs = useObservable(currentConfigs$) ?? {};
  const loadingCafa = useSelector(getIsLoadingCafa);

  const expectedInConfig = useMemo(
    () =>
      R.mapObjIndexed((value, entity) =>
        R.evolve({
          config: R.pipe(
            // Take only the fields already present in configs
            intersect(configs[entity] ?? {}),
            // Cast them to the same types
            // (wrap them in the constructors of the currently existing values)
            // NB: Not the same as calling the constructors with new
            //  compare new String("2") and String("2")
            // Allow failures (f.ex. if value in config is null, then constructor won't exist)
            R.mapObjIndexed((val, key) => {
              const existingValue = configs?.[entity]?.[key];
              if (!existingValue?.constructor) return val;
              return existingValue?.constructor(val);
            }),
          ),
        })(value),
      )(expected),
    [expected, configs],
  );

  const nonRequiredInConfig = useMemo(
    () =>
      R.mapObjIndexed((value, entity) =>
        R.evolve({
          config: R.pipe(
            // Take only the fields already present in configs
            intersect(configs[entity] ?? {}),
            // Cast them to the same types
            // (wrap them in the constructors of the currently existing values)
            // NB: Not the same as calling the constructors with new
            //  compare new String("2") and String("2")
            // Allow failures (f.ex. if value in config is null, then constructor won't exist)
            R.mapObjIndexed((val, key) => {
              const existingValue = configs?.[entity]?.[key];
              if (!existingValue?.constructor) return val;
              return existingValue?.constructor(val);
            }),
          ),
        })(value),
      )(nonRequired),
    [nonRequired, configs],
  );

  const configMismatch = useMemo(
    () =>
      Object.keys(expected).some(
        k => !intersectEquals(configs[k] ?? {}, expectedInConfig[k].config),
      ),
    [configs, expected, expectedInConfig],
  );
  const statusMismatch = useMemo(
    () =>
      Object.keys(expected).some(
        k =>
          !installerStatus?.data?.integrations.some(
            int => int.name === k && int.status === 'StatusRunning',
          ),
      ) ||
      Object.keys(nonRequired).some(
        k =>
          !installerStatus?.data?.integrations.some(
            int => int.name === k && int.status === 'StatusRunning',
          ),
      ),
    [expected, nonRequired, installerStatus],
  );
  const versionMismatch = useMemo(
    () =>
      Object.keys(expected).some(k => {
        if (!expected[k].version) return false; // no restrictions
        const int = installerStatus?.data.integrations.find(
          int => int.name === k,
        );
        return expected[k].version !== int?.version;
      }) ||
      Object.keys(nonRequired).some(k => {
        if (!nonRequired[k].version) return false; // no restrictions
        const int = installerStatus?.data.integrations.find(
          int => int.name === k,
        );
        return nonRequired[k].version !== int?.version;
      }),
    [expected, nonRequired, installerStatus],
  );
  const someMsInaccessible = Object.values(msAccessible).includes(false);
  const needsUpdates =
    configMismatch || statusMismatch || versionMismatch || someMsInaccessible;

  useEffect(() => {
    if (result === undefined) return;
    if (!needsUpdates) return;
    if (!installerStatus) return;
    if (blockAutoInstall) return;
    if (sessionStorage.getItem('debug: disable-auto-install')) return;
    // Do not pre-install versions until CAFA finishes loading since this may cause older version to get installed (PBIB-5908)
    if (loadingCafa) return;
    setResult(undefined);
    updateMS(installerStatus, { ...expectedInConfig, ...nonRequiredInConfig })
      .finally(refreshStatus)
      .then(
        () => setResult(true),
        err => {
          setBlockAutoInstall(true);
          setResult(err);
        },
      );
  }, [
    blockAutoInstall,
    installerStatus,
    needsUpdates,
    loadingCafa,
    result,
    expectedInConfig,
    refreshStatus,
    nonRequiredInConfig,
  ]);

  useInterval(refreshStatus, 30e3);
  return {
    errors: {
      status: statusMismatch,
      config: configMismatch,
      version: versionMismatch,
      msCert: someMsInaccessible,
      autoInstall: result instanceof Error ? result : false,
    },
    info: {
      installerStatus: installerStatusLatest,
      expectedConfigs: expectedInConfig,
      actualConfigs: configs,
    },
    processing: result === undefined,
    retry: blockAutoInstall ? () => setBlockAutoInstall(false) : undefined,
  };
};
