import React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

import useProducts from 'utils/hooks/useProducts';
import { getVatRatesFetching } from 'reducers/vatRatesDB';
import {
  getCurrencyFormatter,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { useKeyState } from 'utils/hooks/keyboard/useKeyState';
import Icon from 'components/Icon';

type Props = {
  product: ReturnType<typeof useProducts>['products'][number];
};

const ProductPrice: React.FC<Props> = ({ product }) => {
  const vatRatesFetching = useSelector(getVatRatesFetching);
  const isWithTax = useSelector(getShowPricesWithTax);

  const ALT = useKeyState('Alt');

  const listPrice = parseFloat(
    isWithTax
      ? product.priceListPriceWithVat ?? product.priceWithVat
      : product.priceListPrice ?? product.price,
  );

  const formatCurrency = useSelector(getCurrencyFormatter);
  const price = !Number.isNaN(listPrice) ? formatCurrency(listPrice) : null;

  if (!Object.keys(product).length) return null;
  if (price === null) return null;

  if (product.productID && ALT) {
    return (
      <Icon
        name="icon_document_alt"
        data-testid="icon-document"
        style={{ fontSize: '12px', margin: '2px' }}
      />
    );
  }

  const jsx = (
    <span
      style={{
        alignSelf: 'flex-end',
        fontWeight: 'bold',
        textAlign: 'right',
        padding: '0 2px',
      }}
      data-testid="product-price"
    >
      {price}
    </span>
  );
  return vatRatesFetching ? <Skeleton>{jsx}</Skeleton> : jsx;
};

export default ProductPrice;
