import { Box, Button, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from 'reducers';
import { StripeLocation } from 'paymentIntegrations/stripe/types';
import { createReader } from 'paymentIntegrations/stripe/API';
import { addError, addSuccess } from 'actions/Error';
import Loader from 'components/Loader';

import useAllStripeLocations from './hooks/useAllStripeLocations';

const ReaderCreationSection = () => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { t } = useTranslation('settingsPayment', {
    keyPrefix: 'integrations.stripe',
  });

  const { locations, loading, error: locationError } = useAllStripeLocations();

  const [location, setLocation] = useState<StripeLocation | null>(null);
  const [label, setLabel] = useState('');
  const [code, setCode] = useState('');

  const cannotCreateReader = !code || !code.length || !location;

  const commonStyle = { marginBottom: '20px' };

  const createReaderAction = async () => {
    if (!location) {
      dispatch(addError(t('errors.readerRequiresLocation')));
      return null;
    }
    if (!code) {
      dispatch(addError(t('errors.readerRequiresCode')));
      return null;
    }
    return dispatch(
      // eslint-disable-next-line @typescript-eslint/camelcase
      createReader({ location: location.id, label, registration_code: code }),
    )
      .then(reader => {
        if (reader) {
          dispatch(addSuccess(t('readerCreationSuccess')));
          setLocation(null);
          setLabel('');
          setCode('');
          return reader;
        }
        dispatch(addError(t('errors.terminalCreationError')));
        return null;
      })
      .catch(e => {
        dispatch(addError(e.message));
        // eslint-disable-next-line no-console
        console.error('Failed to create a reader: ', { e });
      });
  };

  return (
    <Loader show={loading}>
      {locationError ? (
        <Typography variant="h6" color="error">
          {locationError.message}
        </Typography>
      ) : (
        <Box>
          <Autocomplete
            options={locations}
            getOptionLabel={option => option.display_name}
            value={location}
            aria-required
            onChange={(_, newLocation) => {
              setLocation(newLocation);
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                label={t('labels.readerLocation')}
              />
            )}
            style={commonStyle}
          />
          <TextField
            fullWidth
            variant="outlined"
            value={label}
            label={t('labels.readerLabel')}
            onChange={e => setLabel(e.target.value)}
            style={commonStyle}
          />
          <TextField
            fullWidth
            variant="outlined"
            value={code}
            label={t('labels.readerCode')}
            onChange={e => setCode(e.target.value)}
            style={commonStyle}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={cannotCreateReader}
            onClick={() => createReaderAction()}
          >
            {t('createReader')}
          </Button>
        </Box>
      )}
    </Loader>
  );
};

export default ReaderCreationSection;
