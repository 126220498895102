import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

import Loader from 'components/Loader';
import { openProductViewForItem } from 'actions/productsDB';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { useBreakpoints } from 'utils/hooks/UI';

import { EmbeddedProduct } from './index';

const useStyles = makeStyles({
  row: {
    backgroundColor: '#F9CA26',
  },
  cell: {
    fontWeight: 700,
  },
  crossOverPrice: {
    textDecoration: 'line-through',
    textDecorationColor: 'red',
    marginRight: '0.25em',
  },
  embeddedPrice: {
    color: 'red',
  },
});

const ProductResultRow: FC<{
  product: EmbeddedProduct;
  addProduct: (p: EmbeddedProduct) => void;
  close: () => void;
}> = ({ product, addProduct, close }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const gridIsOpened = useSelector(getGridIsOpened);
  const isMobile = !useBreakpoints().mdplus;

  const openProductView = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(openProductViewForItem(product));
  };

  const addProd = () => {
    addProduct(product);
    close();
  };

  return (
    <TableRow className={classes.row} onClick={addProd}>
      <TableCell className={classes.cell}>
        <Typography
          data-testid="search-result-name"
          data-test-key={product.productID}
        >
          {product.name}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography
          data-testid="search-result-code"
          data-test-key={product.productID}
        >
          {product.code}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography
          data-testid="search-result-amount"
          data-test-key={product.productID}
        >
          {product.amount ?? 1}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography
          data-testid="search-result-price"
          data-test-key={product.productID}
        >
          <span className={classes.crossOverPrice}>{product.price}</span>
          <span className={classes.embeddedPrice}>{product.embeddedPrice}</span>
        </Typography>
      </TableCell>
      {!isMobile && !gridIsOpened && (
        <TableCell className={classes.cell}>
          <Typography
            data-testid="search-result-stock"
            data-test-key={product.productID}
          >
            {product.free === Number.MAX_VALUE ? 0 : product.free}
          </Typography>
        </TableCell>
      )}
      <TableCell
        className={classes.cell}
        align="right"
        onClick={openProductView}
      >
        <SearchIcon />
      </TableCell>
    </TableRow>
  );
};

const BarcodeEmbeddedProductResults: FC<{
  products: EmbeddedProduct[];
  loading: boolean;
  addProduct: (p: EmbeddedProduct) => void;
  close: () => void;
}> = ({ products, loading, addProduct, close }) => {
  const { t } = useTranslation('search');
  const gridIsOpened = useSelector(getGridIsOpened);
  const isMobile = !useBreakpoints().mdplus;

  return (
    <Loader show={loading} block>
      <TableContainer component={Paper}>
        <Table
          data-testid="product-barcode-search-results"
          aria-label="product barcode results"
        >
          <TableHead data-testid="product-barcode-search-results-headers">
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="h6">
                  {t('products.barcodeTitle')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              {(!isMobile && !gridIsOpened
                ? ['name', 'code', 'amount', 'price', 'stock']
                : ['name', 'code', 'amount', 'price']
              )
                .map(name => t(`products.headers.${name}`))
                .concat(' ')
                .map(header => (
                  <TableCell variant="head" key={header}>
                    <Typography variant="h6">{header}</Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody data-testid="product-barcode-search-results-body">
            {products.length ? (
              products.map(product => (
                <ProductResultRow
                  key={product.productID}
                  product={product}
                  addProduct={addProduct}
                  close={close}
                />
              ))
            ) : (
              <TableRow>
                <TableCell>
                  <Typography>{t('noResultsFor')}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Loader>
  );
};

export default BarcodeEmbeddedProductResults;
