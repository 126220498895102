import { getPluginConfiguration, getPluginSelector } from 'reducers/Plugins';
import { getProductByID } from 'reducers/cachedItems/products';
import { RESET_SHOPPING_CART } from 'constants/ShoppingCart';

import { USDL_CONST, USDLPluginConfiguration } from './types';
import { combineConfiguration } from './utils';

export const pluginPrefix = 'PLUGIN:USDL_AGE_VERIFICATION:';
export const VERIFIED_CLIENT_AGE = `${pluginPrefix}VERIFIED_CLIENT_AGE`;

export const setUserAgeVerified = (age: number) => ({
  type: VERIFIED_CLIENT_AGE,
  age,
});

const reducer = (state = 0, action) => {
  switch (action.type) {
    case VERIFIED_CLIENT_AGE:
      return action.age;
    case RESET_SHOPPING_CART:
      return 0;
    default:
      return state;
  }
};

export default reducer;

export const getUSDLClientAge = getPluginSelector<number>(USDL_CONST.ID)(
  slice => slice,
);

export const getUSDLProductConfiguration = productID => state => {
  const {
    products: productsConfigs = {},
    productGroups: productGroupConfigs = {},
  } =
    getPluginConfiguration<USDLPluginConfiguration>(USDL_CONST.ID)(state) ?? {};

  const productCard = getProductByID(productID)(state);

  const productConfiguration = productsConfigs[productID];
  const productGroupConfiguration = productGroupConfigs[productCard.groupID];

  if (!(productConfiguration || productGroupConfiguration)) return undefined;

  return combineConfiguration(productConfiguration, productGroupConfiguration);
};
