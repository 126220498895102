import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import CloseButton from 'components/CustomButtons/CloseButton';
import Loader from 'components/Loader';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import { fetchAllWaivers } from '../rdx/actions';
import { getWaiversReduxState } from '../rdx/reducers';
import { ADD_TO_SELECTED_WAIVER } from '../rdx/actionTypes';
import { setReceiptToWaiver } from '../requests';

const EWaiverModal = ({
  isSecondAttempt = false,
  invoiceNumber,
  apiKey,
  onCancel,
  closeModal,
  resolve,
}: {
  currentWarehouse: string;
  isSecondAttempt: boolean;
  invoiceNumber: string;
  apiKey: string;
  onCancel: () => void;
  closeModal: () => void;
  resolve: (assignedWaiverId?: string) => string;
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [shouldTryAgain, toggleTryAgain] = useState(false);

  const waiversState = useSelector(getWaiversReduxState);

  const loadWaivers = async (): Promise<void> => {
    setLoading(true);
    await dispatch(fetchAllWaivers());
    setLoading(false);
  };

  useEffect(() => {
    loadWaivers();
  }, []);

  const succesfulWaiverAssignment = async (waiver) => {
    await dispatch({
      type: ADD_TO_SELECTED_WAIVER,
      payload: waiver,
    });

    toggleTryAgain(false);
    resolve(waiver);
    closeModal();
  }

  const unsuccessfulWaiverAssignment = (): void => {
    loadWaivers();
    toggleTryAgain(true);
  };


  useShortcut('Escape', onCancel, 100);

  const displayWaivers = ({ waiversToDisplay, displaySelectedWaivers }) => {
    if (waiversToDisplay.length) {

      const selectedWaiverIDs = waiversState.selectedWaivers.map(waiver => waiver.waiver_id)

      const freeWaivers = displaySelectedWaivers ? waiversToDisplay : waiversToDisplay.filter(
        waiver => !selectedWaiverIDs.includes(waiver.waiver_id),
      );

      if (freeWaivers.length) {
        return (
          <Table striped hover>
            <thead>
              <tr>
                {!displaySelectedWaivers ? <th>Select</th> : null}
                <th>Name</th>
                <th>Picture</th>
                <th>See eWaiver</th>
              </tr>
            </thead>
            <tbody>
              {freeWaivers.map(waiver => (
                <tr key={waiver.waiver_id}>
                  {!displaySelectedWaivers ? <td>
                    <Button
                      onClick={async () => {
                        if (isSecondAttempt) {
                          const result = await setReceiptToWaiver({
                            invoiceNumber,
                            waiver,
                            apiKey,
                          });

                          if (result.success) {
                            return succesfulWaiverAssignment(waiver)
                          }

                          return unsuccessfulWaiverAssignment()
                        }

                        await dispatch({
                          type: ADD_TO_SELECTED_WAIVER,
                          payload: waiver,
                        });

                        resolve(waiver);
                        return closeModal();
                      }}
                      disabled={loading}
                      size="sm"
                      variant="primary"
                      style={{ height: "40px", width: "70px", fontWeight: 500, letterSpacing: "1px" }}
                    >
                      SELECT
                    </Button>
                  </td> : null}
                  <td>{waiver.names}</td>
                  <td>
                    <div
                      style={{
                        height: '150px',
                        width: '150px',
                        backgroundImage: `url(${waiver.images})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  </td>
                  <td>
                    <a
                      href={waiver.waiverURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }

      return (
        <p style={{ textAlign: 'center', marginTop: '25px' }}>
          No unassigned waivers
        </p>
      );
    }

    return (
      <p style={{ textAlign: 'center', marginTop: '25px' }}>
        No waivers were retrieved
      </p>
    );
  };

  return (
    <>
      <Loader show={loading}>
        <style>{`[data-testid='alert-container'] {z-index:100 !important}`}</style>
        <Modal.Header>
          <Modal.Title>Assign eWaiver to the sale</Modal.Title>
          {!isSecondAttempt ? <CloseButton action={onCancel} /> : null}
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>Available eWaivers</h4>
            <Button
              onClick={loadWaivers}
              disabled={loading}
              size="sm"
              variant="warning"
            >
              REFRESH
            </Button>
          </div>
          {shouldTryAgain ? <p style={{ margin: "15px 0", color: "red", textAlign: "center" }}>The selected waiver is not available anymore, please select a new one</p> : null}
          {displayWaivers({ waiversToDisplay: waiversState.waivers, displaySelectedWaivers: false })}
          {waiversState.selectedWaivers.length ? <>
            <h3>Current assigned waivers</h3>{displayWaivers({ waiversToDisplay: waiversState.selectedWaivers, displaySelectedWaivers: true })}</> : null}
        </Modal.Body>
      </Loader>
    </>
  );
};

export default EWaiverModal;
