import React, { useMemo, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';

import { useRelativeTime } from 'utils/hooks/useRelativeTime';

type Props = {
  time: number;
  increments: number;
  onDone: () => boolean | void;
};
/**
 * A progress bar that automatically counts up to 100% in a set amount of time, and then runs a callback
 */
export const TimerBar: React.FC<Props> = props => {
  const { increments, time, onDone } = props;

  /** The exact point in time this component was mounted */
  const start = useMemo(() => Date.now(), []);
  /** The amount of time that has this component has been mounted for */
  const elapsed =
    useRelativeTime({
      anchorTime: start,
      resolution: increments,
    }) ?? 0;

  // Detect when we target time is reached, trigger the callback
  const finished = elapsed >= time;
  useEffect(() => {
    if (finished) {
      onDone();
    }
  }, [finished, onDone]);

  // Render a progress bar
  return (
    <LinearProgress variant="determinate" value={100 * (elapsed / time)} />
  );
};
