import { createSelector } from 'reselect';

import { addProduct } from 'actions/ShoppingCart/addProduct';

import { getChannelName } from 'plugins/customerDisplay';
import { RootState } from 'reducers';
import {
  getProductsInShoppingCart,
  getShoppingCartDiscountsDict,
  getTotal,
  getTotalNet,
  getTotalTax,
  getVisibleTotalDiscount,
} from 'reducers/ShoppingCart';
import { publish } from 'services/CentrifugoWS';
import {
  getCurrencyFormatter,
  getShowPricesWithTax,
} from 'reducers/configs/settings';

interface ProductToAdd {
  id: string;
  qty: number;
}

function getProductAmountsInCart(productsToAdd: ProductToAdd[]) {
  return function innerSelector(state: RootState) {
    const productsInCart = getProductsInShoppingCart(state);

    return productsInCart
      .filter(product =>
        productsToAdd.some(
          productToAdd => productToAdd.id === product.productID,
        ),
      )
      .reduce((all, product) => {
        return {
          ...all,
          [product.productID]:
            Number(product.amount) + (all[product.productID] ?? 0),
        };
      }, {}) as Record<ProductToAdd['id'], ProductToAdd['qty']>;
  };
}

const getCustomerDisplayData = createSelector(
  state => getShowPricesWithTax(state),
  state => getProductsInShoppingCart(state),
  state => getVisibleTotalDiscount(state),
  state => getTotalTax(state),
  state => getTotalNet(state),
  state => getTotal(state),
  state => getCurrencyFormatter(state),
  state => getShoppingCartDiscountsDict(state),
  (
    isWithTax,
    shoppingCart,
    totalDiscount,
    totalVat,
    totalNet,
    total,
    formatCurrency,
    shoppingCartDiscounts,
  ) => {
    return {
      products: shoppingCart.map(o => ({
        id: o.productID,
        name: o.name,
        code: o.code,
        qty: o.amount,
        disc: (shoppingCartDiscounts[o.rowNumber] || [])
          ?.map(a => `${a.affected}x -${a.percentage}%`)
          .join('\n'),
        price: isWithTax
          ? formatCurrency(Number(o.priceListPriceWithVAT))
          : formatCurrency(Number(o.priceListPrice)),
      })),
      summary: {
        disc: formatCurrency(totalDiscount, { roundDown: true }),
        tax: formatCurrency(totalVat),
        net: formatCurrency(totalNet),
        total: formatCurrency(total),
      },
    };
  },
);

export const handleSelectedUpsellProducts = (
  productsToAdd: ProductToAdd[],
) => async (dispatch, getState) => {
  const channelName = getChannelName(getState());
  const channel = `customer_display:${channelName}`;

  const productsInCartBefore = getProductAmountsInCart(productsToAdd)(
    getState(),
  );

  try {
    await Promise.all(
      productsToAdd.map(product =>
        // @ts-ignore (amount expects type undefined)
        dispatch(addProduct({ productID: product.id, amount: product.qty })),
      ),
    );
  } catch (error) {
    console.error(error);
  }
  const productsInCartAfter = getProductAmountsInCart(productsToAdd)(
    getState(),
  );

  const expectedQtyAdded = productsToAdd.every(expectedProduct => {
    const qtyBefore = productsInCartBefore[expectedProduct.id] ?? 0;
    const qtyAfter = productsInCartAfter[expectedProduct.id] ?? 0;
    return qtyAfter - qtyBefore === expectedProduct.qty;
  });

  if (expectedQtyAdded) {
    const data = getCustomerDisplayData(getState());
    publish({
      channel,
      message: {
        action: 'PURCHASE_UPSELL_PRODUCTS_SUCCESSFUL',
        onlyUpsellData: false,
        showAgain: true,
        ...data, // Unsure if this is necessary
      },
    });
    return;
  }

  publish({
    channel,
    message: {
      action: 'PURCHASE_UPSELL_PRODUCTS_FAILED',
      reason: '', // Current addProduct action implementation does not have any simple way to get reason/alert/error message
      showAgain: true,
    },
  });
};
