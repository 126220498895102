import { mapBirthday } from './common';

const mapAddress = address =>
  address.map(
    ({
      addedTime,
      addressLine1,
      addressLine2,
      addressTypeID,
      changedTime,
      city,
      country,
      customerId,
      id,
      postalCode,
      state,
    }) => ({
      typeID: `${addressTypeID}`,
      address: `${addressLine1 && `${addressLine1}, `}${addressLine2 &&
        `${addressLine2}, `}${city && `${city}, `}${state &&
        `${state}, `}${postalCode && `${postalCode}, `}${country &&
        `${country}, `}`.slice(0, -2),
      street: addressLine1,
      address2: addressLine2,
      city,
      country,
      addressID: id,
      postalCode,
      state,
    }),
  );

const mapCustomer = ({
  addedByTenant,
  addedTime,
  addresses,
  bankAccountNumber,
  bankIBAN,
  bankName,
  bankSWIFT,
  birthdayDay,
  birthdayMonth,
  birthdayYear,
  businessAreaID,
  changedBy,
  changedByTenant,
  changedTime,
  companyName,
  countryID,
  creditLimit,
  customerBalanceDisabled,
  customerCardNumber,
  customerManagerID,
  deliveryTypeID,
  email,
  emailConsentState,
  emailConsentStateTimestamp,
  emailOptOut,
  euCustomerType,
  firstName,
  gender,
  groupID,
  homeStoreID,
  householdCode,
  id,
  integrationCode,
  integrationID,
  jobTitleID,
  language,
  lastName,
  maritalStatus,
  mobilePhone,
  notes,
  oldCardNumbers,
  payerRecordID,
  paymentDays,
  paysViaFactoring,
  penaltyPerDay,
  peopleInHousehold,
  phone,
  posCouponsDisabled,
  primaryStoreID,
  referenceNumber,
  registryCode,
  rewardPointsDisabled,
  salesForCashOnly,
  saveTransactions,
  secondaryStoreID,
  shipGoodsWithWaybill,
  signUpStoreID,
  suspended,
  tags,
  vatNumber,
}) => ({
  // TODO lang:language mapping
  id,
  birthday: mapBirthday(birthdayYear, birthdayMonth, birthdayDay),
  addresses: mapAddress(addresses),
  customerCardNumber,
  notes,
  paymentDays,
  euCustomerType,
  jobTitleID,
  integrationCode,
  deliveryTypeID,
  language,
  customerID: id,
  code: registryCode,
  firstName,
  payerID: payerRecordID,
  clientManagerID: customerManagerID,
  referenceNumber,
  added: addedTime,
  customerType: firstName || lastName ? 'PERSON' : 'COMPANY',
  phone,
  penaltyPerDay,
  mobile: mobilePhone,
  companyName,
  groupID,
  countryID,
  signUpStoreID,
  businessAreaID,
  householdCode,
  homeStoreID,
  posCouponsDisabled: posCouponsDisabled ? 1 : 0,
  salesBlocked: salesForCashOnly ? 1 : 0,
  lastName,
  paysViaFactoring: paysViaFactoring ? 1 : 0,
  rewardPointsDisabled: rewardPointsDisabled ? 1 : 0,
  creditLimit,
  gender: gender === 'UNIDENTIFIED' ? '' : gender,
  fullName: firstName || lastName ? `${firstName}, ${lastName}` : companyName,
  lastModified: changedTime,
  email,
  emailOptOut: emailOptOut ? 1 : 0,
  shipGoodsWithWaybills: shipGoodsWithWaybill ? 1 : 0,
  vatNumber,
  bankAccountNumber,
  bankIBAN,
  bankName,
  bankSWIFT,
  suspended,
  tags,
});

export default ({ data: { result: customer } }) => [mapCustomer(customer)];
