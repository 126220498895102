import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pusher, { Channel, PresenceChannel } from 'pusher-js';

import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getClientCode } from 'reducers/Login';
import { addWarning } from 'actions/Error';

import { OrderNotificationConfig } from './types';

if (process.env.NODE_ENV === 'development') {
  Pusher.logToConsole = true;
}

const useChannel = <C extends Channel>(pusher: Pusher, channelName: string) => {
  const channel = useMemo(() => pusher.subscribe(channelName) as C, [
    pusher,
    channelName,
  ]);
  useEffect(() => {
    return () => {
      channel.unsubscribe();
    };
  }, [channel]);
  return channel;
};

const useListenOnChannel = <C extends Channel>(
  pusher: Pusher,
  channelName: string,
  eventName: string,
  onMessage: (msg: any, channel: C) => void,
) => {
  const ref = useRef<(msg: any, channel: C) => void>(onMessage);
  useEffect(() => {
    ref.current = onMessage;
  }, [onMessage]);

  const channel = useChannel<C>(pusher, channelName);
  useEffect(() => {
    const h = msg => ref.current(msg, channel);
    channel.bind(eventName, h);
    return () => {
      channel.unbind(eventName, h);
    };
  }, [channel, eventName, ref]);
  return channel;
};

export const usePusherNotifications = ({
  key,
  cluster,
}: OrderNotificationConfig) => {
  const dispatch = useDispatch();
  const warehouseID = useSelector(getSelectedWarehouseID);
  const clientCode = useSelector(getClientCode);

  const pusher = useMemo(() => new Pusher(key, { cluster }), [key, cluster]);

  const notify = msg => {
    dispatch(
      addWarning(msg.message, { dismissible: true, selfDismiss: false }),
    );
  };

  useListenOnChannel<PresenceChannel>(
    pusher,
    'presence-berlin-pos',
    'pusher:subscription_succeeded',
    (msg, channel) => {
      console.log('subscribed as', channel.members.me);
    },
  );
  useListenOnChannel(pusher, `taf-berlin-${warehouseID}`, 'taf-notify', notify);
  useListenOnChannel(pusher, `taf-berlin-all`, 'taf-notify-all', notify);
  useListenOnChannel(
    pusher,
    `taf-ecom-${clientCode}${warehouseID.slice(-4)}`,
    'taf-ecom',
    notify,
  );
};
