import { Box, TextField, TextFieldProps, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getPluginConfiguration } from 'reducers/Plugins';
import { getSalesDocuments } from 'services/ErplyAPI';
import { addWarning } from 'actions/Error';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { printReceipt } from 'actions/integrations/printer';

import {
  handleShopCopyPrint,
  handleTaxFreePrint,
} from '../printing/handlePrint';
import { Configuration, pluginID } from '../constants';

import Header from './Header';

const FullWidthField: React.FC<TextFieldProps> = props => (
  <TextField variant="outlined" fullWidth {...props} />
);

const TaxFreeModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [document, setDocument] = useState<SaleDocumentResponse | null>(null);

  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();

  const [fields, setFields] = useState({
    documentId: '',
    passportNumber: '',
    domicile: '',
    numberOfPackages: '',
    lastName: '',
    firstName: '',
  });

  useEffect(() => {
    getSalesDocuments({
      pageNo: 1,
      recordsOnPage: 1,
      confirmed: 1,
    }).then(([salesDocument]) =>
      setFields(prevFields => ({
        ...prevFields,
        documentId: salesDocument.number,
      })),
    );
  }, []);

  useDebounce(
    () => {
      getSalesDocuments({
        number: fields.documentId,
      }).then(([salesDocument]) => {
        if (salesDocument?.total) {
          setDocument(salesDocument);
        } else {
          dispatch(addWarning('Document not found'));
          setDocument(null);
        }
      });
    },
    500,
    [fields.documentId],
  );

  const { minPurchaseAmount } = useSelector(
    getPluginConfiguration<Configuration>(pluginID),
  );

  const documentTotal = document?.total ?? 0;

  const onChange = (key: keyof typeof fields) => (event: React.ChangeEvent) =>
    R.pipe(R.path(['target', 'value']), R.assoc(key), setFields)(event);

  return (
    <Box padding="1rem" display="flex" gridGap="1rem" flexDirection="column">
      <Header title="Tax Free Printing Form" onClose={onClose} />
      <FullWidthField
        value={fields.documentId}
        label="Document ID"
        onChange={onChange('documentId')}
      />
      <FullWidthField
        value={fields.passportNumber}
        label="Passport number"
        onChange={onChange('passportNumber')}
      />
      <FullWidthField
        label="Domicile"
        value={fields.domicile}
        onChange={onChange('domicile')}
      />
      <FullWidthField
        label="Nr of packages"
        value={fields.numberOfPackages}
        onChange={onChange('numberOfPackages')}
      />
      <Box display="flex" gridGap="1rem">
        <FullWidthField
          label="Last name"
          value={fields.lastName}
          onChange={onChange('lastName')}
        />
        <FullWidthField
          label="First name"
          value={fields.firstName}
          onChange={onChange('firstName')}
        />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        disabled={Number(minPurchaseAmount) > documentTotal}
        onClick={async () => {
          if (document) {
            // @ts-ignore
            await dispatch(printReceipt(document));
            await dispatch(handleShopCopyPrint(fields, document));
            await dispatch(handleTaxFreePrint(fields, document));
          }
        }}
      >
        Print
      </Button>
    </Box>
  );
};

export default TaxFreeModal;
