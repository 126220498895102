import i18next from 'i18next';
import * as R from 'ramda';

import { setPayment } from 'actions/Payments/setPayment';
import { PosPlugin } from 'plugins/plugin';
import { getPaymentsCurrency } from 'reducers/Payments';
import { add } from 'utils';
import { getPluginState, setDebitPaymentReturnAmount } from 'plugins/mm/rdx';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';

export const clearCachedDebitReturnPaymentAmount: Required<
  PosPlugin
>['onOpenPaymentModal']['before'] = () => async dispatch => {
  dispatch(setDebitPaymentReturnAmount(0));
};

function isDebitReturnPayment(payment) {
  return payment.type === 'CARD' && payment.original?.cardType === 'Debit';
}

export function isDebitPayment(payment) {
  return payment.type === 'CARD' && payment.cardType === 'Debit';
}

function isCashReturnPayment(payment) {
  return payment.type === 'CASH' && !!payment.original;
}

export const removePrefilledDebitReturnPayments: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (_p, ap) => async dispatch => {
  const originalSaleWasPaidByDebitCard = ap.originalPayments?.some(
    isDebitPayment,
  );
  if (!originalSaleWasPaidByDebitCard) return ap;

  const cardLimit =
    ap.paymentLimits.find(limit => limit.type === 'CARD')?.amount ?? null;
  const updatedAp = {
    ...ap,
    paymentLimits: ap.paymentLimits.map(limit => {
      if (limit.type !== 'CASH') return limit;
      return {
        ...limit,
        amount: [cardLimit, limit.amount].includes(null)
          ? null
          : cardLimit + Math.max(limit.amount, 0),
      };
    }),
  };

  const paymentList: any[] = Object.values(ap.payments);
  const debitPayments = paymentList.filter(isDebitReturnPayment);
  if (!debitPayments.length) return updatedAp;

  const cashPayments = paymentList.filter(isCashReturnPayment);
  const cashPaymentSum = [...debitPayments, ...cashPayments]
    .map(payment => Number(payment.amount) * (payment.currencyRate ?? 1))
    .reduce(add, 0);
  dispatch(setDebitPaymentReturnAmount(cashPaymentSum));

  const paymentsWithoutDebitReturns = R.pipe(
    R.toPairs,
    R.filter(
      ([, payment]) =>
        !isDebitReturnPayment(payment) && !isCashReturnPayment(payment),
    ),
    R.fromPairs,
  )(ap.payments);

  updatedAp.payments = paymentsWithoutDebitReturns;
  return updatedAp;
};

export const addCashPaymentAsDebitReturn: Required<
  PosPlugin
>['onOpenPaymentModal']['after'] = () => async (dispatch, getState) => {
  const { debitPaymentReturnAmount } = getPluginState(getState());
  if (!debitPaymentReturnAmount) return;

  const currency = getPaymentsCurrency(getState());
  await dispatch(
    setPayment({
      amount: debitPaymentReturnAmount.toFixed(2),
      key: `${currency.code}-cash`,
      caption: i18next.t('payment:tenders.cash'),
      type: 'CASH',
      currency: currency.code,
      currencyRate: Number(currency.rate ?? 1),
    }),
  );
  dispatch(setPaymentSelected(''));
  dispatch(setDebitPaymentReturnAmount(0));
};
