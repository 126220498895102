import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addWarning } from 'actions/Error';
import {
  getInvoiceFullyReturned,
  getReturnDocument,
} from 'reducers/returnProducts';
import { resetReturnDocument } from 'actions/returnProducts';
import { useConfirmation } from 'components/Confirmation';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { cancelOrderAction, cancelLayaway } from 'actions/sales';

export default function useOrderLayawayCancellation() {
  const dispatch = useDispatch();
  const returnDocument = useSelector(getReturnDocument);
  const { t } = useTranslation(['return', 'common']);
  const confirm = useConfirmation();
  const invoiceFullyReturned = useSelector(getInvoiceFullyReturned);

  const handleCancel = useCallback(async () => {
    dispatch(resetReturnDocument());

    switch (returnDocument.type) {
      case 'ORDER':
        await confirm({
          title: t('alerts.orderNotSupported.title', { ns: 'return' }),
          body: t('alerts.orderNotSupported.body', { ns: 'return' }),
          confirmText: t('yes', { ns: 'common' }),
          cancelText: t('close', { ns: 'common' }),
        });
        dispatch(closeModalPage());
        dispatch(cancelOrderAction(returnDocument));
        break;
      case 'PREPAYMENT':
        await confirm({
          title: t('alerts.prepaymentNotSupported.title', { ns: 'return' }),
          body: t('alerts.prepaymentNotSupported.body', { ns: 'return' }),
          confirmText: t('yes', { ns: 'common' }),
          cancelText: t('close', { ns: 'common' }),
        });
        dispatch(closeModalPage());
        await dispatch(cancelLayaway(returnDocument));
        break;

      default:
        throw new Error('This should never happen');
    }
  }, [confirm, dispatch, returnDocument, t]);

  const checkDocumentType = useCallback(async () => {
    if (!returnDocument) return;

    const { type, invoiceState } = returnDocument;

    if (type === 'ORDER' || type === 'PREPAYMENT') {
      switch (invoiceState) {
        case 'FULFILLED':
          dispatch(addWarning(t('alerts.alreadyFulfilled', { ns: 'return' })));
          return;
        case 'CANCELLED':
          dispatch(addWarning(t('alerts.alreadyCancelled', { ns: 'return' })));
          return;
        default:
          try {
            await handleCancel();
          } catch (error) {
            dispatch(resetReturnDocument());
          }
          return;
      }
    }

    if (invoiceFullyReturned) {
      dispatch(addWarning(t('alerts.alreadyReturned', { ns: 'return' })));
      dispatch(resetReturnDocument());
    }
  }, [dispatch, handleCancel, invoiceFullyReturned, returnDocument, t]);

  useEffect(() => {
    checkDocumentType();
  }, [checkDocumentType]);
}
