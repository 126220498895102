import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';

import { ComponentConfiguration, Configuration } from './Configuration';

export const pluginID = 'default-groups-plugin';

const defaultGroups: PosPlugin = {
  id: pluginID,
  name: 'Default Groups',
  info: 'Sets default group on customer/company creation',
  keywords: ['customer', 'UI', 'customer form', 'company'],
  getStatus: state => {
    const conf = getPluginConfiguration<Configuration>(pluginID)(state) ?? {};
    if (
      !Object.keys(conf.defaultCompanyGroup ?? {}).length ||
      !Object.keys(conf.defaultCustomerGroup ?? {}).length
    ) {
      return {
        message: 'No default customer groups configured',
        type: 'error',
      };
    }
    return {
      type: 'valid',
      message: 'Ready',
    };
  },
  ComponentConfiguration,
  UICustomerForm: ({ isCompany, onChange, children }) => {
    const conf = useSelector(getPluginConfiguration<Configuration>(pluginID));
    const { defaultCompanyGroup, defaultCustomerGroup } = conf ?? {};

    useEffect(() => {
      if (isCompany) {
        return onChange(v => ({
          ...v,
          groupID: defaultCompanyGroup.customerGroupID,
        }));
      }
      onChange(v => ({
        ...v,
        groupID: defaultCustomerGroup.customerGroupID,
      }));
    }, [isCompany]);

    return children;
  },
};
export default defaultGroups;
