import * as R from 'ramda';

import libSound from './libSound';

export type SoundProvider<Config = any> = {
  /** Unique ID for the sound provider, saved next to the config to know which provider the config should be used with */
  id: string;

  /** React input component used to edit the configuration for the sound if this provider is selected */
  SoundInput: React.FC<{ value: Config | undefined; setValue: (value: Config) => void }>;

  /** Trigger the audio provider to start playing a sound */
  playSound: (config: Config) => void;

  /**
   * Hint to the audio provider that a config was created/updated.
   * This is a good time to fetch and prepare anything that might otherwise cause a delay
   * For example if a sound is configured by URL, this callback should start loading the URL immediately if it has not already
   */
  onConfigUpdated?: (config: Config) => void;
};

export const soundProviders = R.pipe<any, any, any>(
  R.map((provider: SoundProvider) => [provider.id, provider]),
  R.fromPairs,
)([libSound]) as Record<string, SoundProvider>;
