import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getModalPages } from 'reducers/modalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

export const useAutocloseModalPage = (component, children, autoClose) => {
  const topComponent = useSelector(getModalPages).slice(-1)[0]?.component;

  const dispatch = useDispatch();
  const shouldClose = component === topComponent && autoClose;
  useEffect(() => {
    if (shouldClose) dispatch(previousModalPage({component}));
  }, [shouldClose]);

  return autoClose ? <div>This should not be visible</div> : children;
};
