import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useAsync } from 'react-use';
import { AxiosError } from 'axios';
import { Alert } from '@material-ui/lab';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import { applyCoupons } from 'actions/ShoppingCart/applyCoupons';
import CloseButton from 'components/CustomButtons/CloseButton';
import Loader from 'components/Loader';
import { RootState } from 'reducers';
import { getClientCode } from 'reducers/Login';
import { getCurrentPosLanguage } from 'reducers/configs/settings';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getConfiguration } from 'plugins/mm/configuration';
import { getIssuedCoupons } from 'services/ErplyAPI/campaigns';

import CouponCard from './Coupon';
import { getCoupons } from './api';
import { generateToken } from './utils';

const useStyles = makeStyles({
  modalBody: {
    padding: '1.25rem',
    minHeight: '80px',
    maxHeight: '750px',
    overflow: 'auto',
    fontSize: '1.1rem',
  },
  alignCenter: {
    textAlign: 'center',
    '& .MuiAlert-message': {
      width: '100%',
    },
  },
  subtitle: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
});

function LoadCoupons({ onClose }) {
  const styles = useStyles();
  const { t } = useTranslation('campaigns');
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const customer = useSelector(getSelectedCustomer);
  const clientCode = useSelector(getClientCode);
  const language = useSelector(getCurrentPosLanguage);
  const { isProduction, couponServiceTokenSalt } = useSelector(
    getConfiguration,
  );

  const { loading, error, value: coupons } = useAsync(async () => {
    const token = await generateToken(
      customer.customerID,
      couponServiceTokenSalt,
    );
    return getCoupons({
      token,
      customerId: customer.customerID,
      clientCode,
      langCode: language,
      isProduction,
    });
  }, [
    customer.customerID,
    clientCode,
    language,
    isProduction,
    couponServiceTokenSalt,
  ]);

  const { value: erplyCoupons } = useAsync(
    async () =>
      getIssuedCoupons({
        issuedCustomerID: customer.customerID,
        recordsOnPage: 100,
        status: 'ACTIVE',
      }),
    [customer.customerID],
  );

  const customerHasNoCoupons = coupons?.length === 0 && !error;
  const customerName =
    customer.customerType === 'PERSON'
      ? customer.fullName
      : customer.companyName;

  function getErrorMessage(error: AxiosError) {
    return error?.response?.data?.error ?? error?.message;
  }

  function closeModal() {
    if (erplyCoupons?.length) {
      dispatch(applyCoupons(erplyCoupons));
    }
    onClose();
  }

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1.25rem"
        paddingY="0.75rem"
        borderBottom="1px solid #dee2e6"
      >
        <Box fontSize="24px" fontWeight={700}>
          {t('loadCoupons.title')}
        </Box>

        <Box display="flex">
          <CloseButton action={closeModal} />
        </Box>
      </Box>
      <Loader show={loading} block>
        <div className={styles.modalBody}>
          {error ? (
            <div className={styles.alignCenter}>
              <Alert
                severity="error"
                icon={false}
                style={{ textAlign: 'center' }}
              >
                <Typography variant="h6">
                  {t('loadCoupons.failed.title')}
                </Typography>
              </Alert>
              <Box marginY="1rem">
                <Typography variant="body1">
                  {t('loadCoupons.failed.body', { customerName })}
                </Typography>
              </Box>
              <div>
                <b>
                  {t('loadCoupons.failed.reason', {
                    reason: getErrorMessage(error as AxiosError),
                  })}
                </b>
              </div>
            </div>
          ) : null}
          {coupons ? (
            <>
              {customerHasNoCoupons ? (
                <div className={styles.subtitle}>
                  <Trans t={t} i18nKey="loadCoupons.messages.noCoupons">
                    There are no offers for customer number{' '}
                    <strong>
                      {{ customerCardNumber: customer.customerCardNumber }}
                    </strong>{' '}
                    ({{ customerName }})
                  </Trans>
                </div>
              ) : (
                <div className={styles.subtitle}>
                  <Trans t={t} i18nKey="loadCoupons.messages.foundCoupons">
                    The following offers have been loaded for customer number{' '}
                    <strong>
                      {{ customerCardNumber: customer.customerCardNumber }}
                    </strong>{' '}
                    ({{ customerName }})
                  </Trans>
                </div>
              )}
              <div>
                {coupons.map(coupon => (
                  <CouponCard key={coupon.offerId} coupon={coupon} />
                ))}
              </div>
            </>
          ) : null}
        </div>
      </Loader>
    </div>
  );
}

export default LoadCoupons;
