import React from 'react';
import { useSelector } from 'react-redux';
import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';

import { getProductGroupsTreeOrdered } from 'reducers/productGroupsDB';

export const ProductGroupInput = (
  props: TextFieldProps & { allowNone?: boolean },
) => {
  const groups = useSelector(getProductGroupsTreeOrdered);

  return (
    <TextField select {...props}>
      {props?.allowNone && (
        <MenuItem key="-" value={undefined}>
          -
        </MenuItem>
      )}
      {groups.map(({ name, value }) => (
        <MenuItem key={value} value={value}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};
