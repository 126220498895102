import React, { FC } from 'react';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { useAsync } from 'react-use';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getSetting } from 'reducers/configs/settings';

import { getHistory } from '../API/givexAPI';
import { getGivexConfiguration } from '../configuration/Configuration';

dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  cardNo: string;
};

/**
 * The history view can only be accessed from {@link GivexBalance balance view}, and shows recent transactions of the selected card.
 *
 * The history does not include cancelled charges or the cancellations themselves, but does include all other charges, refills, and activations
 * @see GivexBalance
 */
const GivexHistory: FC<Props> = ({ cardNo }) => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(previousModalPage());
  const { value: cardDetails, loading, error } = useAsync(
    () => getHistory({ cardNo }),
    [cardNo],
  );
  const accTimezone = useSelector(getSetting('timezone'));
  const { displayName } = useSelector(getGivexConfiguration);

  return (
    <>
      <Modal.Header>
        <Modal.Title>{displayName} history</Modal.Title>
        <CloseButton action={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {loading ? <LinearProgress variant="indeterminate" /> : null}
        {error ? <div style={{ color: 'salmon' }}>{error.message}</div> : null}
        <div style={{ fontWeight: 'bold', fontSize: 24 }}>
          Card no: {cardNo}
        </div>
        <div style={{ fontWeight: 'bold', fontSize: 24 }}>
          Balance:{' '}
          {R.cond([
            [R.equals(undefined), () => '...'],
            [R.T, balance => Number(balance).toFixed(2)],
          ])(cardDetails?.certificateBalance as any)}
        </div>
        <div style={{ fontWeight: 'bold', fontSize: 24 }}>
          Expiration date:{' '}
          {R.cond([
            [R.equals(undefined), () => '...'],
            [R.equals('None'), () => 'No expiration'],
            [R.T, date => new Date(date).toLocaleDateString()],
          ])(cardDetails?.certificateExpirationDate as any)}
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Time</th>
              <th scope="col">Entered</th>
              <th scope="col">Method</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {cardDetails?.transactionHistory.transactions?.map((h, i) => {
              const timeZonedDateAndTime = dayjs.tz(
                `${h.date} ${h.time}`,
                accTimezone,
              );
              return (
                <tr key={i}>
                  <td>{timeZonedDateAndTime.format('YYYY-MM-DD')}</td>
                  <td>{timeZonedDateAndTime.format('HH:mm:ss')}</td>
                  <td>{h.customer}</td>
                  <td>{h.givexMethod}</td>
                  <td>{h.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal.Body>
    </>
  );
};

export default GivexHistory;
