/* eslint-disable no-await-in-loop */
import { proxy, wrap } from 'comlink';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!../../utils/workers/cloudSyncWorker';

import { SOName } from './types';

const syncWorkers: Partial<Record<SOName | 'common', typeof Worker>> = {};

export const stopSync = async (SO_NAME: SOName) => {
  try {
    await syncWorkers.common?.stopSync(SO_NAME);
  } catch (err) {
    console.error('Failed to stop sync worker', SO_NAME, err);
  }
};

const syncDB = async (clientCode: string, SO_NAME, callbacks) => {
  if (!syncWorkers.common) {
    syncWorkers.common = wrap(new Worker());
  }
  const worker = syncWorkers.common;

  return worker.sync(clientCode, SO_NAME, proxy(callbacks));
};

export default syncDB;
