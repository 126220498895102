import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getIsManualViewMode } from 'reducers/UI/manualViewMode';
import { toggleManualViewMode } from 'actions/UI';

import HeaderIcon from '../Icon';

const ManualViewMode = () => {
  const dispatch = useDispatch();
  const isManualViewMode = useSelector(getIsManualViewMode);

  const { t } = useTranslation('header');
  const onClick = useCallback(
    () => dispatch(toggleManualViewMode(!isManualViewMode)),
    [isManualViewMode, toggleManualViewMode],
  );

  return (
    <HeaderIcon
      icon="info_alt"
      style={{ color: isManualViewMode ? '#007bff' : '' }}
      title={t('titles.help')}
      data-testid="header-manual-mode"
      onClick={onClick}
    />
  );
};

export default ManualViewMode;
