import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { addError, addWarning } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { PluginError } from 'plugins/pluginUtils';
import { RootState } from 'reducers';

import { addViiProductData, getViiProducts } from '../redux/actions';
import { ReduxViiProduct } from '../types';
import { cancelLoad } from '../API/viiAPI';
import { noResponse } from '../constants';

type Props = {
  children?: never;
  resolve: (value: unknown | undefined) => void;
  reject: (value: Error) => void;
  onClose: () => void;
  orderIndex: number;
  card: ReduxViiProduct;
};

const ViiGiftCardReturnEdit: React.FC<Props> = ({
  onClose,
  resolve,
  reject,
  card,
  orderIndex,
}) => {
  const viiProducts = useSelector(getViiProducts);
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState(card.CardNumber);
  const [lastAttempt, setLastAttempt] = useState(cardNumber);

  const withProcessing = action => () => {
    setProcessing(true);
    if (processing) return;
    action()
      .catch(e => dispatch(addError(e.message)))
      .finally(() => setProcessing(false));
  };

  const handleClose = () => {
    onClose();
    reject(new PluginError(`User closed`, `User closed`));
  };

  const handleSearch = async () => {
    // Block duplicate card from being added
    if (
      Object.entries(viiProducts).some(
        ([idx, vgcp]) =>
          vgcp.CardNumber === cardNumber && Number(idx) !== orderIndex,
      )
    ) {
      dispatch(addWarning('This gift card has already been added to cart!'));
    } else {
      dispatch(
        cancelLoad({
          Amount: card.Amount.replace('-', ''),
          CardNumber: cardNumber,
          ExternalReference: card.ExternalReference,
        }),
      ).then(res => {
        // Received approval from Vii API that the card was cancelled
        if (res && Number(res.ResponseCode?.[0]) === 0) {
          const newCard = {
            ...card,
            CardNumber: res.CardNumber[0],
            Amount: res.Amount[0],
            ExternalReference: res.ExternalReference[0],
            ...(res.AuthCode ? { AuthCode: res.AuthCode[0] } : {}),
            CardStatusId: res.CardStatusId[0],
            viiReceiptNumber: res.viiReceiptNumber[0],
            viiTranId: res.viiTranId[0],
            viiUserName: res.viiUserName[0],
          };
          // Save the gift card information into plugin's redux state to sync to JSON API.
          dispatch(
            addViiProductData({
              orderIndex,
              vii: newCard,
            }),
          );
          resolve(newCard);
          dispatch(previousModalPage());
        }
        setLastAttempt(cardNumber);
        dispatch(addWarning(res?.ResponseMessage?.[0] ?? noResponse));
      });
    }
  };
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">Failed Returning Gift Card</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <Button
            disabled={processing || !cardNumber}
            onClick={withProcessing(handleSearch)}
            variant="contained"
            color="secondary"
            style={{ position: 'relative' }}
          >
            {processing && (
              <LinearProgress
                variant="indeterminate"
                style={{ position: 'absolute', inset: 0 }}
              />
            )}
            Retry Refund
          </Button>
          <CloseButton action={() => handleClose()} />
        </Box>
      </Box>
      <Divider style={{ marginBottom: '1em' }} />
      <Typography>{`${lastAttempt} Failed, Scan a NEW GiftCard`}</Typography>
      <TextField
        fullWidth
        variant="outlined"
        inputRef={inputRef}
        autoFocus={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">Serial Number</InputAdornment>
          ),
        }}
        value={cardNumber}
        onChange={e => setCardNumber(e.target.value)}
        error={!cardNumber}
      />
    </Box>
  );
};

export default ViiGiftCardReturnEdit;
