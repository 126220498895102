import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';

export const getCustomerTableFields: Required<
  PosPlugin
>['selectorOverrides']['getCustomerTableFields'] = base =>
  createSelector(base, baseFields => {
    const newFields = { ...baseFields };
    if (newFields.PERSON.doNotSell) {
      newFields.PERSON.suspended = newFields.PERSON.doNotSell;
      delete newFields.PERSON.doNotSell;
    }
    if (newFields.COMPANY.doNotSell) {
      newFields.COMPANY.suspended = newFields.COMPANY.doNotSell;
      delete newFields.COMPANY.doNotSell;
    }
    return newFields;
  });
