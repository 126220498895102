import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Icon from 'components/Icon';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getSetting } from 'reducers/configs/settings';
import { PluginComponent } from 'plugins';
import { RecentSalesList } from 'containers/Forms/RecentSalesList';
import { Customer } from 'types/Customer';

import Icons from './Icons';
import CustomerTable from './CustomerTable';
import ContactsTable from './ContactsTable';
import { AddCouponsQuickAction } from './CustomerQuickActions';

const style = {
  profileIcon: {
    fontSize: '64px',
  },
};

const CustomerView: React.FC<{ customer?: Customer }> = ({ customer }) => {
  const activeCustomer = useSelector(getSelectedCustomer);
  // Do not live-update the customer ever, only use the initial value
  const displayedCustomer = useMemo(() => customer || activeCustomer, [
    activeCustomer,
    customer,
  ]);
  const { companyName, notes, firstName, lastName } = displayedCustomer;

  /* -------- */
  const { t } = useTranslation('customer');

  const currentLocationOnly = useSelector(
    getSetting('touchpos_recent_sales_only_from_current_location'),
  );

  const currentWarehouseID = useSelector(getSelectedWarehouseID);

  const modalTitle =
    lastName && firstName ? `${firstName} ${lastName}` : `${companyName}`;

  const memoizedProps = useMemo(
    () => ({
      showCustomer: false,
      morePages: true,
      loadMore: true,
      showFilter: true,
      warehouseNumber: currentLocationOnly ? currentWarehouseID : undefined,
      clientID: displayedCustomer.id,
    }),
    [currentLocationOnly, currentWarehouseID, displayedCustomer.id],
  );

  return (
    <div data-testid="customer-view-container">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        borderBottom={0}
        padding="15px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexShrink={1}
          style={{
            wordBreak: 'break-all',
          }}
        >
          <Icon name="icon_profile" style={style.profileIcon} />
          <Box data-testid="modal-title" fontWeight={700} fontSize="1.5rem">
            {modalTitle}
          </Box>
        </Box>
        <Icons customer={displayedCustomer} />
      </Box>
      <Box padding="16px 20px 20px 20px">
        {/* TODO: ViewTable hook also contains notes and QA section  */}
        <PluginComponent
          hookname="UICustomerViewTable"
          props={{ customer: displayedCustomer }}
        >
          <>
            {/* Table of information */}
            <CustomerTable customer={displayedCustomer} />
            {/* Quick actions section */}
            {activeCustomer.customerID === displayedCustomer.customerID && (
              <div data-testid="quick-actions">
                <AddCouponsQuickAction />
              </div>
            )}

            <div>
              <Box fontWeight={700} padding="5px 10px" margin="16px 0 10px">
                <strong data-testid="customer-notes-header">
                  {t('customerView.notes')}
                </strong>
              </Box>
            </div>
            <Box
              component="p"
              margin="0 0 10px"
              data-testid="customer-notes"
              style={{
                wordBreak: 'break-all',
              }}
            >
              {notes}
            </Box>
          </>
        </PluginComponent>

        {/* Contacts section */}
        {/* TODO: Is this relevant for non-company customers? */}
        <Box margin="16px 0 16px">
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon data-testid="customer-contacts-expand-icon" />
              }
              aria-controls="contacts-panel-content"
              id="contacts-panel-header"
            >
              <Typography>
                <strong>{t('customerView.contacts.title')}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ContactsTable customerID={displayedCustomer.id} />
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* Previous purchases section */}
        <PluginComponent
          hookname="UICustomerViewPreviousPurchasesTable"
          props={{
            customer: displayedCustomer,
            showFilter: false,
            loadMore: false,
            onMore: false,
            done: false,
          }}
        >
          <RecentSalesList queryProps={memoizedProps} />
        </PluginComponent>
      </Box>
    </div>
  );
};

export default CustomerView;
