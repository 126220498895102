/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import {
  applySaleDiscount,
  clearSaleDiscount,
  clearDiscountReasons,
} from 'actions/ShoppingCart';
import InputField from 'components/FieldTypes/InputField';
import {
  getAppliedDiscount,
  getCartHasNonDiscountableProducts,
  getCartHasReferencedReturnRow,
  getHasRightToEditDocument,
} from 'reducers/ShoppingCart';
import {
  getCurrencySymbol,
  getDiscountLimit,
  getDiscountPercentages,
  getPriceEditingAllowedForExchangesAndReturns,
} from 'reducers/configs/settings';
import { float, positive } from 'components/FieldTypes/formatters';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import './SaleDiscount.scss';
import { modalPages as mp } from 'constants/modalPage';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';
import { addWarning } from 'actions/Error';
import { getCurrentSalesDocument } from 'reducers/sales';

const SaleDiscount = () => {
  const { t } = useTranslation('discount');
  const { t: t2 } = useTranslation('shoppingCart');
  const symbol = useSelector(getCurrencySymbol);
  const dispatch = useDispatch();
  const [{ amount, percentage }, setDiscounts] = useState(
    useSelector(getAppliedDiscount),
  );
  const setAmount = amount => setDiscounts(st => ({ ...st, amount }));
  const setPercentage = percentage =>
    setDiscounts(st => ({ ...st, percentage }));
  const maxDiscount = useSelector(getDiscountLimit);

  const reasonCodes = useSelector(getReasonCodes(REASONS.DISCOUNT));

  const cartHasNonDiscountableProducts = useSelector(
    getCartHasNonDiscountableProducts,
  );

  const { type } = useSelector(getCurrentSalesDocument);

  const canEditDocument = useSelector(getHasRightToEditDocument);

  const cartHasReferencedReturnRow = useSelector(getCartHasReferencedReturnRow);
  const allowPriceEditingOnReturns = useSelector(
    getPriceEditingAllowedForExchangesAndReturns,
  );

  const discountPercentages = useSelector(getDiscountPercentages);

  const save = async () => {
    // Prevent discount addition during return with allowPriceEditingForExchangesAndReturns disabled
    if (cartHasReferencedReturnRow && !allowPriceEditingOnReturns) {
      dispatch(
        addWarning(
          t2('alerts.referencedReturnRowsLockedReturnEditsDisabled', {
            context: 'saleDiscount',
          }),
        ),
      );
      return;
    }
    // Prevent discount addition during order pickup when user has insufficient rights to change confirmed order
    if (!canEditDocument) {
      dispatch(
        addWarning(
          t2('alerts.noRightsToDiscountConfirmedDocument', { context: type }),
          { selfDismiss: 3000 },
        ),
      );
      return;
    }
    if (reasonCodes.length > 1 && (amount || percentage)) {
      dispatch(
        openModalPage({
          component: mp.discountPopup,
          isPopup: true,
          props: {
            reasonCodes,
            amount,
            percentage,
          },
        }),
      );
      return;
    }
    if (Number(amount) || Number(percentage)) {
      await dispatch(applySaleDiscount({ amount, percentage }));
    } else {
      await dispatch(clearSaleDiscount());
      dispatch(clearDiscountReasons());
    }
    dispatch(previousModalPage());
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      save();
    }
  };

  return (
    <div data-testid="sale-discount-modal">
      <Modal.Header>
        <span style={{ fontSize: '1.75em', fontWeight: 700 }}>
          {t('title')}
        </span>

        <span style={{ flexGrow: 1 }} />
        <SaveButton data-testid="save-btn" action={save} />
        <CloseButton
          action={() => {
            dispatch(previousModalPage());
          }}
        />
      </Modal.Header>

      <Modal.Body>
        {cartHasNonDiscountableProducts && (
          <div className="mb-3">
            <strong className="text-danger">{t('nonDiscount')}</strong>
          </div>
        )}
        <Form>
          <Form.Group>
            <InputField
              autoFocus={true}
              title="%"
              className={classnames([styles.formInput, styles.shortTitle])}
              placeholder={t('fields.percentage', { context: 'placeholder' })}
              size="lg"
              data-testid="percentage-input"
              value={percentage || ''}
              onChange={e => setPercentage(e.target.value)}
              formatter={float.and(positive)}
              onKeyDown={onKeyDown}
            />
          </Form.Group>
          <Form.Group>
            <InputField
              title={symbol}
              className={classnames([styles.formInput, styles.shortTitle])}
              placeholder={t('fields.amount', { context: 'placeholder' })}
              size="lg"
              data-testid="amount-input"
              value={amount || ''}
              onChange={e => setAmount(e.target.value)}
              formatter={float.and(positive)}
              onKeyDown={onKeyDown}
            />
          </Form.Group>
        </Form>
        {!cartHasNonDiscountableProducts && (
          <div className="sale-discount-custom-percentages">
            <ToggleButtonGroup
              defaultValue={Number(percentage || '')}
              value={Number(percentage || '')}
              name="sale-discount-custom-percentages"
              type="radio"
              onChange={discount => setPercentage(discount)}
              data-testid="sale-discount-custom-percentages"
            >
              {discountPercentages.map(discount => (
                <ToggleButton
                  key={uuid.v1()}
                  variant="light"
                  disabled={discount > maxDiscount}
                  value={discount}
                  data-testid="percentage"
                  data-test-key={discount}
                >
                  {discount !== 0 ? `-${discount}%` : `${discount}%`}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        )}
      </Modal.Body>
    </div>
  );
};
SaleDiscount.displayName = 'SaleDiscount';
export default SaleDiscount;
