/* eslint-disable import/prefer-default-export */
import { PaymentType } from 'types/PaymentType';

import { doBulkRequest, doClientRequest } from './core/ErplyAPI';
import { GetGiftCardParams, GetGiftCardResponse } from './core/types';

export const bulkPaymentRequests = queryProps =>
  doBulkRequest(JSON.stringify(queryProps));

export const getGiftCards = (params: GetGiftCardParams) =>
  doClientRequest<GetGiftCardResponse>({ ...params, request: 'getGiftCards' });

export const savePayment = params =>
  doClientRequest({ ...params, request: 'savePayment' });

export const getLastDocAllPayments = ({ documentID }) =>
  doClientRequest({ documentID, request: 'getPayments' });

export const getPaymentTypes = params =>
  doClientRequest<PaymentType>({ ...params, request: 'getPaymentTypes' });

export const savePaymentType = params =>
  doClientRequest<{ paymentTypeID: number }>({
    ...params,
    request: 'savePaymentType',
  });
