/* eslint-disable @typescript-eslint/camelcase */
export default {
  addressForm: {
    placeholders: {
      postalCode_Canada: 'Postal Code',
      state_Canada: 'Province',
    },
    lookUpAddress: {
      title: 'Look up address',
      saveButton: 'OK',
      fields: {
        country: 'Country',
        address: 'Address',
      },
      extra: {
        loading: 'Loading',
        noResults: 'No results',
      },
      alerts: {
        failedFind: 'Failed to retrieve and set address',
        failedRetrieve: 'Failed to find addresses',
      },
    },
    errors: {
      country: 'Country must be Canada or USA',
    },
  },
  createCustomer: {
    titles: {
      updateEmail: 'Update e-mail',
    },
    countries: {
      select: 'Select a country',
      usa: 'USA',
      canada: 'Canada',
    },
    errorText: {
      loyaltyCardNumber: {
        invalid: 'This field must be a valid 7, 8 or 9 digit number',
        checking: 'Checking...',
        alreadyExists: 'Customer card number already exists',
        generate_progress: 'Generating...',
        generate: 'Generate',
      },
      birthday: {
        empty: 'Date is not valid',
      },
      name: {
        requiredField: "This field can't be blank",
      },
      phone: {
        invalidLength: 'Phone number must be 10 characters long',
        invalidFormat: 'Phone needs to contain only numbers and no space',
      },
      mobile: {
        invalidLength: 'Mobile phone must be 10 characters long',
        invalidFormat: 'Mobile needs to contain only numbers and no space.',
      },
      requiredField: "This field can't be blank",
    },
    fields: { language: 'Language', senior: 'Senior', doNotSell: 'Suspended' },
    languages: {
      english: 'English',
      french: 'French',
    },
    buttons: {
      changeEmail: 'Change Email',
    },
  },
  gridButtons: {
    alerts: {
      giftCardOnPendingSaleNotAllowed:
        'Vouchers cannot be stored on pending sale',
    },
    functions: {
      previousCustomer: 'Previous customer',
      loadCoupons: 'Load coupons',
      giftcard: 'Check Voucher Balance',
    },
  },
  campaigns: {
    loadCoupons: {
      title: 'Load coupons',
      messages: {
        foundCoupons:
          'The following offers have been loaded for customer number <strong>{{customerCardNumber}}</strong> ({{customerName}})',
        noCoupons:
          'There are no offers for customer number <strong>{{customerCardNumber}}</strong> ({{customerName}})',
      },
      failed: {
        title: 'Failed to load coupons',
        body:
          'POS failed to get coupons for customer ({{customerName}}). Try again later.',
        reason: 'Reason: {{reason}}',
      },
      coupon: {
        expired: 'Expired',
        expirationDate: 'Expires {{date}}',
        salePrice: 'Sale price: ',
        pluList: 'PLU list: ',
        redeemed: 'Redeemed at {{homeStoreName}} store on {{date}}',
        redeemedUnknownLocation: 'Redeemed at unknown location on {{date}}',
      },
    },
  },
  /**
   * Serial gift card -> Voucher / Cash card
   * Regular gift card -> Gift certificate
   */
  giftcard: {
    balance: {
      title: 'Check voucher balance',
    },
    serial: {
      title: 'Enter voucher serial',
    },
    fields: {
      serial_placeholder: 'Voucher / Cash card serial number',
    },
    alerts: {
      fetchingSerial: 'Searching for voucher {{serial}}...',
      serialAlreadyAdded:
        'A voucher with the serial {{serial}} is already added',
      serialAlreadyUsed: 'The voucher {{serial}} is already in use',
      missingSerial: 'Add the voucher serial',
      serialNotFound: 'The voucher {{serial}} was not found',
    },
    errors: {
      regexMismatch:
        'Provided number ({{serial}}) does not match configured pattern:  {{pattern}}',
      empty: 'Voucher serial is empty',
    },
  },
  modalpage: {
    modalpageName_giftCardBalance: 'Voucher balance page',
    modalpageName_giftCardSerial: 'Voucher number popup',
  },
  payment: {
    buttons: {
      giftcard_combined: '<0></0><1>Gift certificate / Voucher</1>',
      giftcard: '<0></0><1>Gift certificate</1>',
      giftCard: 'Gift certificate / Voucher',
    },
    tenders: {
      regularGiftCard: 'Gift certificate',
      serialGiftCard: 'Voucher / Cash card',
    },
    alerts: {
      serialGiftcardDisabled: 'Voucher disabled!',
      giftcardAmountRequired: 'Enter the gift certificate amount!',
      regularGiftcardDisabled: 'Gift certificate disabled!',
      giftcardSerialRequired: 'Enter the voucher / cash card code or number!',
      fetchingSerialGiftcard:
        'Searching for voucher / cash card #{{serial}}...',
      giftcardSerialAlreadyAdded:
        'Voucher /cash card #{{serial}} is already added!',
      giftcardSerialNotFound:
        'Error! No voucher / cash card #{{serial}} found!',
      giftcardExpired: 'Error! Voucher / Cash card is expired!',
      giftcardInsufficientBalance:
        'Error! Voucher / Cash card #{{serial}} balance is {{balance}}!',
      giftcardMinimumSpend:
        'Voucher / Cash Card #{{serial}} can only be used if cart total is more than {{minimumSpend}}!',
      giftcardUnnecessary: 'No need to use a voucher / cash card!',
      removeGiftcard_title: 'Remove voucher / cash card',
      giftcardWrongType: 'Added {{toType}} from {{fromType}} button',
      removeGiftcardSerial_body:
        'Confirm removal of voucher / cash card #<0>{{serial}}</0>',
      removeGiftcardRegular_body:
        'Confirm removal of gift certificate with sum: <0>{{amount}}</0>',
      giftCardRequired: 'Gift certificate is required',
      failedToFetchGiftCard: 'Failed to fetch voucher / cash card #{{serial}}',
      notVoucherOwner:
        'Cannot use the voucher. The voucher does not belong to the selected customer.',
    },
    serialGiftcard: {
      title: 'Voucher / Cash card',
      title_type: '{{type}}',
      info:
        'Chosen voucher balance: {{balance}}. Please enter amount you want to use into text field below',
      fields: {
        code_placeholder: 'Voucher code',
        paymentAmount: {
          errors: {
            exceedsGiftCardBalance:
              'Cannot be greater than the voucher balance',
          },
        },
      },
    },
    regularGiftcard: {
      title: 'Gift certificate',
      fields: {
        amount_placeholder: 'Gift certificate amount',
      },
    },
  },
  settingsConfiguration: {
    section: {
      giftcard: 'Voucher',
    },
    posUseGiftcardsWithSerialNumbers: 'Allow vouchers',
    posDisableGenerateNumberButton:
      'Disable generate number button when selling a voucher',
    posUseSerialGiftCardPaymentAmountInput: 'Use voucher payment amount input',
    posSerialGiftcardPattern:
      'Voucher serial number pattern (Regular Expression)',
    posSerialGiftcardPattern_regex:
      "A voucher with the number 'POS-vs4A-acWjAb-6b6X' can be randomly generated with the following pattern: POS-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{4}\nThe individual characters are always the same, in this case the first letters 'POS' and all the dashes ('-'). The [a-zA-Z0-9] allows the generation of any character that is specified inside of the brackets, in this case both lower and upper case characters (a-z and A-Z), and numbers (0-9) can be randomly generated. The following number inside the curly brackets specifies the number of characters for that group.\nThis is just a simple example, REGEX patterns can be much more advanced and additional information can be found from the web.",
  },
  settingsPayment: {
    sale: {
      tenders: {
        giftcardSerial: 'Voucher / Cash card',
        giftcardRegular: 'Gift certificate',
      },
    },
  },
  settingsUserInterface: {
    functions: {
      hideGiftCardBalance: 'Check voucher balance',
    },
  },
  openCloseDay: {
    closeDay: {
      tenders: {
        tenders: {
          'GIFTCARD-GIFT_CERTIFICATE': 'Gift certificate',
          'GIFTCARD-CASH_CARD': 'Cash card',
          'GIFTCARD-VOUCHER': 'Voucher',
        },
      },
    },
  },
  alerts: {
    updatingCustomerGroup:
      'Customer group and e-mail do not match. Updating customer group...',
    customerGroupUpdated: 'Customer group was set to {{groupName}}',
    failedToSaveInventoryWriteOffs:
      'Failed to send inventory write-off requests',
  },
  orderNotifications: {
    title: 'Order Notification Manager',
    newOrders: 'New orders ({{count}})',
    moreNewOrders: 'and {{count}} more',
  },
};
