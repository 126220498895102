import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { PrinterScriptPreview } from 'Systems/PrinterScript/exports';

export default ({ script, onClose }) => (
  <Modal.Body
    style={{
      margin: 0,
      padding: 0,
      maxWidth: 'fit-content',
      cursor: onClose ? 'pointer' : undefined,
    }}
    onClick={onClose}
  >
    <PrinterScriptPreview script={script} />
  </Modal.Body>
);
