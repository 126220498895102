import { deletePayment } from 'actions/Payments';
import {
  doShift4Payment,
  doShift4Refund,
  doShift4Void,
} from 'paymentIntegrations/shift4/doPayment';
import { addError, addWarning, dismissType } from 'actions/Error';
import { getCurrencyFormatter } from 'reducers/configs/settings';

/** Mapping of event names to event handlers */
const onClick: Partial<{ [key: string]: () => void }> = {};

export const functions = [
  {
    actionOnClick: hooks => () => onClick.checkStatus?.(),
    name: 'checkStatus',
    text: 'Recheck status',
    variant: 'success',
  },
  {
    actionOnClick: hooks => () => onClick.cancel?.(),
    name: 'cancel',
    text: 'Confirm transaction failed',
    variant: 'danger',
  },
] as const;
// endregion

export const initPayments = hooks => async (dispatch, getState) => {
  const {
    cardPayments,
    updateMessage,
    setTitle,
    beforeDocSave,
    resolvePayments,
    rejectPayments,
    enableButtons,
  } = hooks;
  enableButtons([]);

  const state = getState();

  const paymentsToProcess = cardPayments.filter(p => !p.paid);

  const getOption = () =>
    new Promise<'checkStatus' | 'cancel'>(res => {
      enableButtons(['checkStatus', 'cancel']);
      const handleStatus = () => {
        res('checkStatus');
        delete onClick.checkStatus;
        delete onClick.cancel;
        enableButtons([]);
      };
      const handleCancel = () => {
        res('cancel');
        delete onClick.checkStatus;
        delete onClick.cancel;
        enableButtons([]);
      };
      onClick.checkStatus = handleStatus;
      onClick.cancel = handleCancel;
    });

  const formatCurrency = getCurrencyFormatter(getState());
  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const pmt of paymentsToProcess) {
      setTitle(`Shift4: ${formatCurrency(pmt.amount)}`);
      // eslint-disable-next-line no-await-in-loop
      const res = await dispatch(
        pmt.amount > 0
          ? doShift4Payment(pmt.amount, updateMessage, getOption)
          : doShift4Refund(pmt.amount, updateMessage, getOption),
      );
      // eslint-disable-next-line no-await-in-loop
      await beforeDocSave({
        key: pmt.key,
        paid: true,
        type: 'CARD',
        caption: 'CARD',
        ...res,
      });
    }
    resolvePayments();
  } catch (e) {
    console.error('Failed to process shift4 payments', e);
    dispatch(addError(e.message));
    rejectPayments();
  }
};

/**
 * Void current successful payments in case the user wants to abort the current purchase
 */
export const voidPayments = ({ cardPayments }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(
      addWarning('Voiding payments...', {
        selfDismiss: false,
        dismissible: false,
        errorType: 'shift4',
      }),
    );
    const toProcess = cardPayments.filter(pmt => pmt.paid);
    // eslint-disable-next-line no-restricted-syntax
    for (const pmt of toProcess) {
      // eslint-disable-next-line no-await-in-loop
      await dispatch(doShift4Void(pmt.attributes.refNo));

      dispatch(deletePayment({ key: pmt.key }));
    }
  } catch (e) {
    dispatch(addError(e.message));
  } finally {
    dispatch(dismissType('shift4'));
  }
};
