/*
 *  "Emergency" update on 20APR20, pressured by Tarmo Henri Tamm & Priit
 *  * cardType is now read from paymentType field of response (cardType was "NONE")
 *  * removed paymentType field because that doesn't seem to be a thing in the savePayment request
 */
export const toPaymentObj = response => ({
  cardHolder: response.cardHolder,
  cardNumber: response.cardNumber,
  cardType: response.paymentType,
  authCode: response.authCode,
  aid: response.aid,
  transactionId: response.referenceNumber,
  amount:
    response.transactionType === 'REFUND'
      ? -Math.abs(response.approvedAmount)
      : Number(response.approvedAmount),
  ecrReferenceNumber: response.ecrReferenceNumber,
  attributes: {
    refNo: response.referenceNumber,
    authCode: response.authCode,
    hostReferenceNumber: response.hostReferenceNumber,
    cardNumber: response.cardNumber,
    cardType: response.paymentType,
    entryMode: response.entryMode,
    approvalCode: response.hostCode,
    transactionType: response.transactionType,
    aid: response.aid,
  },
  paid: true,
});
