import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn, useDebounce } from 'react-use';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getCustomers } from 'services/ErplyAPI';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { RootState } from 'reducers';
import { PosPlugin } from 'plugins/plugin';
import { AddressTypes } from 'types/Address';
import { getSelectedCustomer } from 'reducers/customerSearch';

import { api } from '../../api';
import { CompanyRecord } from '../../constants';

import { createUseErplyRequest, sortCountriesByName } from './utils';

type UICustomerFormBetaProps = Parameters<
  Required<PosPlugin>['UICustomerFormBeta']
>[0];
type UICustomerFormProps = Parameters<Required<PosPlugin>['UICustomerForm']>[0];

type Customer =
  | UICustomerFormBetaProps['customer']
  | UICustomerFormProps['value'];
type OnChange =
  | UICustomerFormBetaProps['onChange']
  | UICustomerFormProps['onChange'];

export function useCompaniesAutocomplete(
  customer: Customer,
  onChange: OnChange,
  addressTypes: AddressTypes[],
) {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const warehouseID = useSelector(getSelectedWarehouseID);
  const selectedCustomer = useSelector(getSelectedCustomer);

  const [companies, setCompanies] = useState<CompanyRecord[]>([]);
  const [chosenCompany, setChosenCompany] = useState<CompanyRecord | undefined>(
    undefined,
  );
  const { id, customerType, companyName } = customer;
  const isEditing = Boolean(id);

  useEffect(() => {
    if (!companyName?.length) {
      setChosenCompany(undefined);
    }
  }, [companyName]);

  const useCountryOptions = createUseErplyRequest(
    'getCountries',
    sortCountriesByName,
  );

  const countryOptions = useCountryOptions({
    name: 'loading...',
    value: customer.countryID,
    code: '',
  });

  // Update form state with chosen company data
  const chooseCompany = async (company: CompanyRecord | null) => {
    if (!company) {
      setChosenCompany(undefined);
      return;
    }

    const address = company.legal_address.split(',');

    // if customer already exits, select it
    const [customer] = await getCustomers({
      searchRegistryCode: company.reg_code,
    });
    if (customer) {
      if (!isEditing || selectedCustomer.id !== customer.id) {
        await dispatch(
          setCustomer({
            data: customer,
          }),
        );
        dispatch(
          openModalPage({
            component: modalPages.customerView,
          }),
        );
        return;
      }
    }

    const countryID = countryOptions.find(
      ({ name }) => name === 'Estonia' || name === 'Eesti',
    )?.value;

    const registryAddressTypeID = addressTypes?.find(
      ({ name }) => name === 'registered address',
    )?.id;

    onChange(v => ({
      ...v,
      companyName: company.name,
      code: String(company.reg_code),
      countryID,
      vatNumber: '',
      homeStoreID: warehouseID,
      addresses: [
        {
          street: address[3],
          city: address[1],
          state: address[0],
          country: 'Eesti',
          postalCode: company.zip_code,
          typeID: registryAddressTypeID,
        },
      ],
      address2: '',
      addressTypeID: '1',
      city: '',
      country: '',
      customerCardNumber: '',
      email: '',
      groupName: '',
      mobile: '',
      newCompany: {},
      notes: '',
      phone: '',
      postalCode: '',
      state: '',
      street: '',
    }));
    setChosenCompany(company);
    setCompanies([]);
  };

  const [companySearchState, searchForCompanies] = useAsyncFn(() => {
    if (companyName?.trim()) {
      return dispatch(api.getCompanyByName(companyName?.trim())).then(
        response => response?.data,
      );
    }
    return Promise.resolve(undefined);
  }, [dispatch, companyName]);

  // Search for company when user enters company name
  useDebounce(
    () => {
      if (
        customerType === 'COMPANY' &&
        companyName?.trim().length &&
        !chosenCompany
      ) {
        searchForCompanies();
      }
    },
    500,
    [customerType, companyName?.trim(), !chosenCompany],
  );

  useEffect(() => {
    setCompanies(companySearchState.value ?? []);
  }, [companySearchState.value]);

  // Fetch and set VAT number for chosen company
  useEffect(() => {
    if (!chosenCompany) return;
    dispatch(api.getCompanyByCode(chosenCompany.reg_code)).then(data => {
      const hasVatNr = typeof data?.kmkr_nr === 'string';
      if (hasVatNr) return onChange(R.assoc('vatNumber', data?.kmkr_nr));
    });
  }, [chosenCompany, dispatch, onChange]);

  return {
    companies,
    chooseCompany,
    chosenCompany,
    loading: companySearchState.loading,
  };
}
