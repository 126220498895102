import { INTEGRATION_TYPES } from 'constants/CAFA';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { printPatchscript } from 'actions/integrations/printer';

const magicCreationOfPatchscript = (patchscript: string) => [
  {
    type: 'table',
    columns: [
      {
        weight: 1,
        baseWidth: 0,
      },
      {
        weight: 1,
        baseWidth: 0,
      },
    ],
    rows: patchscript
      .split('\n')
      .map(text => {
        const spaceInMiddle = /\S\s{2,}\S/.test(text);
        const spaceOnLeft = /^\s{2,}/.test(text);
        const spaceOnRight = /\s{2,}$/.test(text);
        if (spaceInMiddle) {
          const [a, b, c] = text.split(/\s{2,}/);
          const [left, right] = a ? [a, b] : [b, c];
          return [
            {
              align: a ? 'left' : 'right',
              pieces: [{ text: left.trim(), meta: { bold: true } }],
            },
            { align: 'right', pieces: [{ text: right.trim() }] },
          ];
        }
        if (spaceOnLeft) {
          if (spaceOnRight) {
            return [
              { align: 'center', pieces: [{ text: text.trim() }] },
              'colspan',
            ];
          }
          return [
            { align: 'right', pieces: [{ text: text.trim() }] },
            'colspan',
          ];
        }
        return [{ pieces: [{ text: text.trim() }] }, 'colspan'];
      })
      .map(cells => ({ type: 'normal', cells })),
  },
];

export const printReceipts = receipts => (dispatch, getState) => {
  const swedbankConfig = getCafaEntry<
    typeof INTEGRATION_TYPES.payment,
    'swedbank',
    { printingDevice: string; printReceipt: '0' | '1' | '2' }
  >(
    'swedbank',
    INTEGRATION_TYPES.payment,
  )(getState())?.value ?? { printReceipt: '0', printingDevice: '' };

  const { printReceipt, printingDevice } = swedbankConfig;

  if (printingDevice === '0' && printReceipt !== '2') {
    // Printing with POS printer NOT with terminal printer
    const merchantReceiptPatchscript = magicCreationOfPatchscript(
      receipts.merchantReceipt,
    );
    const customerReceiptPatchscript = magicCreationOfPatchscript(
      receipts.customerReceipt,
    );

    if (printReceipt === '1' && receipts.customerReceipt.length) {
      dispatch(printPatchscript(customerReceiptPatchscript));
    }

    if (receipts.merchantReceipt.length) {
      dispatch(printPatchscript(merchantReceiptPatchscript));
    }
  }
};
