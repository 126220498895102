import React, {
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync, useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { getCustomers } from 'services/ErplyAPI';
import { addWarning } from 'actions/Error';
import {
  getCustomerSearchFilterOption,
  getSetting,
} from 'reducers/configs/settings';
import { doCustomerSearch } from 'actions/searchCustomer';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { Customer } from 'types/Customer';
import { PluginComponent } from 'plugins';
import { RootState } from 'reducers';

import SearchComponent from '../components/SearchComponent';
import PopOverContainer from '../components/PopOverContainer';

import CustomerResults from './CustomerResults';
import { mapContactsToCompanies, sortByCompanyOrFullName } from './util';

const CustomerSearch = React.memo(
  React.forwardRef<
    HTMLInputElement | null,
    {
      active: boolean;
      closePopover: () => void;
      focusKey?: string;
      isPopover?: boolean;
    }
  >(({ active, focusKey, closePopover, isPopover = true }, outerRef) => {
    const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
    const warehouseID = useSelector(getSelectedWarehouseID);
    const queryFilterOption = useSelector(getCustomerSearchFilterOption);
    const { t } = useTranslation('search');

    const searchMinLength = useSelector(
      getSetting('customer_search_min_length'),
    );
    const defaultRef = useRef<HTMLInputElement | null>(null);
    const ref = outerRef ?? defaultRef;

    const filterParameters = useMemo(() => {
      switch (queryFilterOption) {
        case 'byHomeStore':
          return { homeStoreID: warehouseID };
        case 'bySignupStore':
          return { signUpStoreID: warehouseID };
        default:
          return {};
      }
    }, [queryFilterOption, warehouseID]);

    const [searchValue, setSearchValue] = useState('');

    const [searchChangeAllowed, setSearchChangeAllowed] = useState(true);
    const [customerPromise, setCustomerPromise] = useState(
      Promise.resolve<Customer[]>([]),
    );

    const memoizedMapContactsToCompanies = useCallback(
      (customerArray: Customer[]) => {
        if (customerArray[0]?.companyID === undefined) return customerArray;
        const getContactCompanyIds = contactCompanyIds =>
          getCustomers({
            recordsOnPage: 100,
            customerIDs: contactCompanyIds,
            ...filterParameters,
          });
        return mapContactsToCompanies(customerArray, getContactCompanyIds);
      },
      [filterParameters],
    );

    const { value: customers = [], loading } = useAsync(
      () =>
        customerPromise
          // sort by company or (first + last name), case-insensitive
          .then(sortByCompanyOrFullName)
          // inject company contacts into the contact object
          .then(memoizedMapContactsToCompanies),
      [customerPromise, memoizedMapContactsToCompanies],
    );

    const doSearch = useCallback(
      (searchValue: string) => {
        if (searchValue.length < searchMinLength) {
          return Promise.resolve<Customer[]>([]);
        }

        const customersPromise: Promise<Customer[]> = dispatch(
          doCustomerSearch({
            recordsOnPage: 100,
            getAddresses: 1,
            getBalanceInfo: 1,
            getBalanceWithoutPrepayments: 1,
            searchFromMiddle: 1,
            responseMode: 'detail',
            searchNameIncrementally: searchValue,
            searchValue,
            mode: 'ALL',
            ...filterParameters,
          }),
        );

        setCustomerPromise(customersPromise);

        return customersPromise;
      },
      [searchMinLength, dispatch, filterParameters],
    );

    const [isReady, cancel] = useDebounce(() => doSearch(searchValue), 400, [
      searchValue,
      doSearch,
    ]);

    const close = useCallback(() => {
      setSearchValue('');
      closePopover();
      (document.activeElement as HTMLElement).blur();
    }, [closePopover]);

    const onSearchChange = (text: string) => {
      if (searchChangeAllowed) {
        setSearchValue(text);
      }
    };

    const addCustomer = (customer?: Customer) => {
      if (customer) {
        dispatch(
          setCustomer({
            data: customer.customerID,
            firstName: customer.firstName,
            lastName: customer.lastName,
            recalculateShoppingCart: true,
            displayCouponsInfo: true,
            fetchRewardPoints: true,
          }),
        );
        close();
      } else {
        close();
        dispatch(addWarning(t('noResultsFor')));
      }
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
        // Select search results for keyboard navigation
        document
          .querySelector<HTMLElement>(
            '[data-testid=customer-search-results]', // ID is correct - both search results components are labelled 'product-search-results'
          )
          ?.focus();
        e.preventDefault(); // Do not scroll
        return;
      }
      if (e.key === 'Enter' && searchChangeAllowed) {
        setSearchChangeAllowed(false);
        if (isReady() === false) {
          cancel();
          doSearch(searchValue).then(([p]) => addCustomer(p));
        } else {
          customerPromise.then(([p]) => addCustomer(p));
        }
      }
    };

    const onFocus = useCallback(() => setSearchChangeAllowed(true), []);

    return (
      <>
        <PluginComponent
          hookname="UICustomerSearch"
          props={{
            searchChangeAllowed,
            setSearchChangeAllowed,
            isReady,
            cancel,
            addCustomer,
            doSearch,
            searchValue,
            customerPromise,
            setSearchValue,
            ref,
            close,
            active,
          }}
        >
          <SearchComponent
            testId="customer-search-input"
            active={active}
            focusKey={focusKey}
            ref={ref}
            close={close}
            searchValue={searchValue}
            setSearchValue={onSearchChange}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            placeholder="customers"
          />
        </PluginComponent>
        <PluginComponent
          hookname="UICustomerResults"
          props={{ close, customerPromise, searchValue }}
        >
          {isPopover ? (
            <PopOverContainer show={active}>
              <CustomerResults
                close={close}
                customers={customers}
                loading={loading}
                searchValue={searchValue}
              />
            </PopOverContainer>
          ) : (
            <CustomerResults
              close={close}
              customers={customers}
              loading={loading}
              searchValue={searchValue}
            />
          )}
        </PluginComponent>
      </>
    );
  }),
);

export default CustomerSearch;
