import { getPluginSelector } from 'reducers/Plugins';

import {
  RESET_FISCAL_STATE,
  SHOULD_SAVE_TAX_INVOICE,
  SET_CUSTOMER_DATA,
  SET_DOCUMENT_TYPE,
} from './actionTypes';

type FiscalIntegrationState = {
  shouldSaveTaxInvoice: boolean;
  documentType: string;
  customerData: Record<string, any>;
};

const fiscalIntegrationReducer = (
  state = {
    shouldSaveTaxInvoice: false,
    documentType: '',
    customerData: {},
  },
  action,
): FiscalIntegrationState => {
  switch (action.type) {
    case SHOULD_SAVE_TAX_INVOICE:
      return { ...state, shouldSaveTaxInvoice: action.payload };
    case SET_DOCUMENT_TYPE:
      return { ...state, documentType: action.payload };
    case SET_CUSTOMER_DATA:
      return { ...state, customerData: { ...action.payload } };
    case RESET_FISCAL_STATE:
      return {
        shouldSaveTaxInvoice: false,
        documentType: '',
        customerData: {},
      };
    default:
      return state;
  }
};

export const getFiscalIntegrationState = state =>
  getPluginSelector<FiscalIntegrationState>('fiscal-integration')(n => n)(
    state,
  );

export default fiscalIntegrationReducer;
