/**
 * @file Transformations between saveSalesDocument API request format and an easier to use internal version
 */

type Flat = {};
type PromotionRule = {
  amount: string | number;
  finalPrice: string | number;
  totalDiscount: string | number;
  campaignType: string | number;
  campaignDiscountValue: string | number;
  campaignDiscountPercentage: string | number;
  campaignID: string | number;
};
type StructuredCart = {
  cart: {
    amount?: number | string;
    price?: number | string;
    discount?: number | string;
    promotionRules: Partial<PromotionRule>[];
    [key: string]: any;
  }[];
  [key: string]: any;
};

export const unspreadPromotionRules = (
  row: any,
): StructuredCart['cart'][number] => {
  const output = { promotionRules: [] as Partial<PromotionRule>[] };
  Object.entries(row).forEach(([key, value]) => {
    const promotionRuleMatch = key.match(/^promotionRule(\d+)(\D+)(\d+)/);
    if (!promotionRuleMatch) {
      output[key] = value;
      return;
    }
    const [, g2, g3, g4] = promotionRuleMatch;
    const index = Number(g4) - 1;
    const name = g3;
    if (!output.promotionRules[index]) output.promotionRules[index] = {};
    output.promotionRules[index][name] = value;
  });
  return output;
};
export const spreadPromotionRules = (
  index,
  { promotionRules, ...rest }: StructuredCart['cart'][number],
) => ({
  ...rest,
  ...Object.fromEntries(
    promotionRules.flatMap((rule, ruleIndex) =>
      Object.entries(rule).map(([key, value]) => {
        return [`promotionRule${index + 1}${key}${ruleIndex + 1}`, value];
      }),
    ),
  ),
});

export const unspreadCart = (request: Flat): StructuredCart => {
  // NB: By creating the object locally, we are guaranteed to be the only code
  // with access to the object, thus we are allowed to mutate it as much as we want
  // as long as we haven't passed it to anyone else yet
  //
  // Using mutations instead of ramda for readability and performance reasons
  const unspread: StructuredCart = { cart: [] };
  Object.entries(request).forEach(([key, value]) => {
    const promotionRuleMatch = key.match(/^promotionRule(\d+)(\D+)(\d+)/);
    const cartPropMatch = key.match(/^(\D+)(\d+)$/);
    if (promotionRuleMatch) {
      const [, g1, g2, g3] = promotionRuleMatch;
      const cartIdx = Number(g1) - 1;
      const ruleIdx = Number(g3) - 1;
      const ruleKey = g2;

      if (!unspread.cart[cartIdx])
        unspread.cart[cartIdx] = { promotionRules: [] };
      const cartItem = unspread.cart[cartIdx];

      // Guaranteed to exist because we start with an empty object,
      // and whenever we add a cart item we immediately initialize it with promotionRules prop
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const rules = cartItem.promotionRules!;

      if (!rules[ruleIdx]) rules[ruleIdx] = {};
      const rule = rules[ruleIdx];

      rule[ruleKey] = value;
    } else if (cartPropMatch) {
      const [, g1, g2] = cartPropMatch;
      const cartKey = g1;
      const cartIdx = Number(g2) - 1;

      if (!unspread.cart[cartIdx])
        unspread.cart[cartIdx] = { promotionRules: [] };
      const cartItem = unspread.cart[cartIdx];

      cartItem[cartKey] = value;
    } else {
      unspread[key] = value;
    }
  });
  return unspread;
};
export const spreadCart = (
  grouped: Omit<StructuredCart, 'cart'> & {
    cart: Partial<StructuredCart['cart'][number]>[];
  },
): Flat => {
  // NB: by spreading the ...rest, 'spread' will be a new local object
  // Thus until we pass it to any other code we are guaranteed to be the sole owner
  // and it is safe to build it up using mutations
  //
  // Using mutations instead of ramda for readability and performance reasons
  const { cart, ...spread } = grouped;

  // For each item in cart
  cart.forEach(({ promotionRules, ...cartProps }, cartIndex) => {
    // Extract the regular props
    Object.entries(cartProps).forEach(([key, value]) => {
      spread[`${key}${cartIndex + 1}`] = value;
    });
    // Extract the promotion rules
    promotionRules?.forEach((rule, ruleIndex) => {
      Object.entries(rule).forEach(([key, value]) => {
        spread[`promotionRule${cartIndex + 1}${key}${ruleIndex + 1}`] = value;
      });
    });
  });
  return spread;
};
