import React from 'react';
import { Box, Typography, Chip, Checkbox, ListItem } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ClassNameMap } from '@material-ui/styles';

import { IssuedCoupon } from 'types/Coupon';
import Icon from 'components/Icon';
import styles from 'containers/Forms/Campaigns/index.module.scss';

const getDate = (date: IssuedCoupon['expiryDate']) =>
  date === '0000-00-00'
    ? 'No expiry date set'
    : format(new Date(date), 'yyyy-MM-dd');

interface Props {
  coupon: IssuedCoupon;
  toggleCoupon: (id: string) => void;
  removeCoupon: (id: string) => void;
  checked: boolean;
  used: boolean;
  classes: ClassNameMap;
}

function Coupon({
  coupon,
  toggleCoupon,
  removeCoupon,
  checked,
  used,
  classes,
}: Props) {
  const { t } = useTranslation('campaigns');

  return (
    <ListItem
      data-testid="coupon-item"
      data-test-key={coupon.uniqueIdentifier}
      className={`${styles.CouponItem} dark_coupon_item ${classes.listItem}`}
    >
      <Box width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="0.5rem"
        >
          <Box display="flex" alignItems="center">
            <Box marginRight="1rem">
              <Checkbox
                className={classes.checkBox}
                checked={checked}
                onChange={() => toggleCoupon(coupon.uniqueIdentifier)}
              />
            </Box>
            <div>
              <Typography variant="h5">
                <b>{coupon.uniqueIdentifier}</b>
              </Typography>
            </div>
          </Box>

          <Box className={styles.buttons} gridGap="1rem">
            <Typography variant="h6">{getDate(coupon.expiryDate)}</Typography>
            <Chip
              className={[
                styles.badge,
                used ? 'badge-success' : 'badge-error',
              ].join(' ')}
              size="small"
              label={t('coupons.badge', {
                context: used ? 'applied' : 'notApplied',
              })}
            />
            <Icon
              name="icon_trash_alt"
              action={e => {
                e.stopPropagation();
                removeCoupon(coupon.uniqueIdentifier);
              }}
            />
          </Box>
        </Box>
        <Typography>{coupon.campaignName}</Typography>
      </Box>
    </ListItem>
  );
}

export default Coupon;
