import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import ListItem from 'components/ListItem';
import MuiDatePicker from 'components/MuiDatePicker';
import { deletePayment } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { addSuccess, addWarning, dismissType } from 'actions/Error';
import { getPaymentSelected } from 'reducers/Payments';
import InputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import {
  getDateFormatFromBO,
  getAPIDateFormatter,
  getDateFormatter,
} from 'reducers/configs/settings';

// import { generateCustomPaymentKey } from 'utils/payments';

const PayCheck = () => {
  const { t } = useTranslation('payCheckForm');

  const [number, setNumber] = useState('');
  const [date, setDate] = useState(null);
  const [bankName, setBankName] = useState('');
  const [bankBSB, setBankBSB] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [drawer, setDrawer] = useState('');
  const [amount, setAmount] = useState('0.00');
  const [notAllowed, setNotAllowed] = useState(false);

  const dateFormatter = useSelector(getDateFormatter);
  const formatDateForAPI = useSelector(getAPIDateFormatter);
  const format = useSelector(getDateFormatFromBO);

  const formattedDate = dateFormatter(date);

  const paymentKey = useSelector(getPaymentSelected);

  const dispatch = useDispatch();

  const goBack = () => {
    dispatch([setPaymentSelected('')]);
    dispatch(deletePayment({ key: paymentKey }));
  };

  const numberEmpty = () => {
    if (!number?.length > 0) {
      dispatch(dismissType('payCheck'));
      dispatch(
        addWarning(t('alerts.payCheckNumberIsEmpty'), {
          dismissible: false,
          errorType: 'payCheck',
          selfDismiss: 2000,
        }),
      );
      return true;
    }
    return false;
  };

  const dateError = () => {
    if (formattedDate === 'Invalid Date' || !formattedDate) {
      dispatch(dismissType('payCheck'));
      dispatch(
        addWarning(t('alerts.payCheckDateRequired'), {
          dismissible: false,
          errorType: 'payCheck',
          selfDismiss: 2000,
        }),
      );
      return true;
    }
    return false;
  };

  const bankNameError = () => {
    if (!bankName?.trim()) {
      dispatch(dismissType('payCheck'));
      dispatch(
        addWarning(t('alerts.payCheckBankNameRequired'), {
          dismissible: false,
          errorType: 'payCheck',
          selfDismiss: 2000,
        }),
      );
      return true;
    }
    return false;
  };

  const bankBSBError = () => {
    if (!bankBSB?.trim()) {
      dispatch(dismissType('payCheck'));
      dispatch(
        addWarning(t('alerts.payCheckBankBSBRequired'), {
          dismissible: false,
          errorType: 'payCheck',
          selfDismiss: 2000,
        }),
      );
      return true;
    }
    return false;
  };

  const accountNoError = () => {
    if (!accountNo?.length > 0) {
      dispatch(dismissType('payCheck'));
      dispatch(
        addWarning(t('alerts.payCheckAccountNumberRequired'), {
          dismissible: false,
          errorType: 'payCheck',
          selfDismiss: 2000,
        }),
      );
      return true;
    }
    return false;
  };

  const drawerNameError = () => {
    const cDrawer = String(drawer);
    if (!cDrawer.trim() || !drawer?.length > 0) {
      dispatch(dismissType('payCheck'));
      dispatch(
        addWarning(t('alerts.payCheckDrawerRequired'), {
          dismissible: false,
          errorType: 'payCheck',
          selfDismiss: 2000,
        }),
      );
      return true;
    }
    return false;
  };

  const paymentAmountError = () => {
    const paymentAmount = Number(amount);
    if (paymentAmount <= 0 || !paymentAmount) {
      dispatch(dismissType('payCheck'));
      dispatch(
        addWarning(t('alerts.payCheckAmountNegative'), {
          dimissible: false,
          errorType: 'payCheck',
          selfDismiss: 2000,
        }),
      );
      return true;
    }
    return false;
  };

  useEffect(() => {
    const notValidated =
      !Number(amount) ||
      !drawer?.length > 0 ||
      !number?.length > 0 ||
      !accountNo?.length > 0 ||
      !bankBSB?.length > 0 ||
      !bankName?.length > 0 ||
      !date === null;
    setNotAllowed(notValidated);
  }, [amount, drawer, number, accountNo, bankBSB, bankName, date]);

  const setPayCheckPayment = () => {
    if (
      numberEmpty() ||
      dateError() ||
      bankBSBError() ||
      bankNameError() ||
      accountNoError() ||
      drawerNameError() ||
      paymentAmountError()
    ) {
      return;
    }

    // Send out payment

    const paymentAmount = Number(amount);
    const generateID = new Date();
    const payment = {
      amount: paymentAmount,
      key: paymentKey,
      // key: paymentSelectedKey,
      type: 'CHECK',
      caption: t('caption'),

      attributes: {
        payCheckID: generateID.getTime(),
        drawerName: drawer,
        checkNumber: number,
        accountNumber: accountNo,
        date: formatDateForAPI(date, format),
        bankBSB,
        bankName,
      },
    };

    setNumber(number);
    setDate(date);
    setBankBSB(bankBSB);
    setBankName(bankName);
    setAccountNo(accountNo);
    setDrawer(drawer);
    setAmount(amount);
    dispatch(addSuccess('Check payment added'));
    dispatch(setPayment(payment)).then(() => dispatch(setPaymentSelected('')));
  };

  useShortcut('F8', setPayCheckPayment, 40);

  return (
    <Form className="form-payCheck">
      <h3>{t('title')}</h3>

      <Form.Group className="form-horizontal p-sm-4 p-0 pb-4">
        <InputField
          placeholder={t('placeHolders.checkNumber')}
          formatter={newval => newval.replace(/[^0-9\s\-]/gi, '')}
          className={classnames(styles.formInput, styles.longTitle)}
          size="lg"
          type="text"
          title={t('titles.checkNumber')}
          name="checkNumber"
          onChange={e => setNumber(e.target.value)}
          onBlur={numberEmpty}
          value={number}
        />
        <MuiDatePicker
          onChange={e => setDate(e)}
          style={{ height: '39px' }}
          format={format}
          value={date}
          onBlur={dateError}
          label={t('titles.checkDate')}
        />
        <InputField
          formatter={newval => newval.replace(/[^A-Za-z\s\'\-]/gi, '')}
          placeholder={t('placeHolders.checkBankName')}
          className={classnames(styles.formInput, styles.longTitle)}
          size="lg"
          title={t('titles.checkBankName')}
          name="checkBankName"
          type="text"
          float="left"
          onChange={e => setBankName(e.target.value)}
          onBlur={bankNameError}
          value={bankName}
        />
        <InputField
          placeholder={t('placeHolders.checkBankBSB')}
          className={classnames(styles.formInput, styles.longTitle)}
          size="lg"
          title={t('titles.checkBankBSB')}
          name="checkBankBSB"
          type="text"
          onChange={e => setBankBSB(e.target.value)}
          onBlur={bankBSBError}
          value={bankBSB}
        />
        <InputField
          placeholder={t('placeHolders.checkAccountNo')}
          className={classnames(styles.formInput, styles.longTitle)}
          formatter={newval => newval.replace(/[^0-9\s\'\-]/gi, '')}
          size="lg"
          title={t('titles.checkAccountNo')}
          name="checkAccountNo"
          type="text"
          onChange={e => setAccountNo(e.target.value)}
          onBlur={accountNoError}
          value={accountNo}
        />
        <InputField
          placeholder={t('placeHolders.checkAccountDrawer')}
          className={classnames(styles.formInput, styles.longTitle)}
          size="lg"
          title={t('titles.checkAccountDrawer')}
          name="checkDrawer"
          type="text"
          formatter={newval => newval.replace(/[^A-Za-z\s\'\-]/gi, '')}
          // this code doesn't allow any numbers to be entered
          // in drawer's name
          onChange={e => setDrawer(e.target.value)}
          onBlur={drawerNameError}
          value={drawer}
        />
        <InputField
          className={classnames(styles.formInput, styles.longTitle)}
          size="lg"
          title={t('titles.checkAmount')}
          name="checkAmount"
          type="number"
          onChange={e => setAmount(e.target.value)}
          onBlur={paymentAmountError}
          value={amount}
        />
        <div
          style={{
            display: 'flex',
            marginTop: '10px',
            marginBottom: '-40px',
            justifyContent: 'space-around',
          }}
        >
          <ListItem
            action={() => {
              goBack();
            }}
            variant="grid_red"
          >
            <span className="icon_close" style={{ fontSize: '250%' }} />
          </ListItem>
          <ListItem
            action={setPayCheckPayment}
            disabled={notAllowed}
            variant="grid_green"
          >
            <span
              className="func-key"
              style={{
                top: '5px',
                left: '8px',
                fontSize: '13px',
                fontWeight: '700',
                position: 'absolute',
              }}
            >
              F8
            </span>
            <span className="icon_check" style={{ fontSize: '250%' }} />
          </ListItem>
        </div>
      </Form.Group>
    </Form>
  );
};

export default PayCheck;
