import { createSelector } from 'reselect';

import {
  TYPE_SET_CONNECTION_HEALTH,
  TYPE_SET_CONNECTION_STATUS,
  TYPE_SET_FORCE_OFFLINE,
} from 'constants/connectivity';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getUserLoggedIn } from 'reducers/Login';

const initialState = {
  health: true,
  forcedOfflineMode: false,
  status: 'good',
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case TYPE_SET_CONNECTION_HEALTH:
      return { ...state, health: payload };
    case TYPE_SET_FORCE_OFFLINE:
      return { ...state, forcedOfflineMode: payload };
    case TYPE_SET_CONNECTION_STATUS:
      return { ...state, status: payload };
    default:
      return state;
  }
}

export default reducer;

export function getConnection(state) {
  return state.connectivity.connection;
}
/** @return {boolean} */
export const getConnectionHealth = createSelector(
  getConnection,
  slice => slice.health && !slice.forcedOfflineMode,
);
/** @return {'verygood'|'good'|'average'|'weak'|'veryweak'|'error'} */
export const getConnectionStatus = createSelector(
  getConnection,
  slice => slice.status,
);

export const getIsForcedOfflineMode = createSelector(
  getConnection,
  slice => slice.forcedOfflineMode,
);

export const getIsOnlineAndLoggedIn = createSelector(
  getConnectionHealth,
  getIsForcedOfflineMode,
  getSelectedPos,
  getUserLoggedIn,
  (connectionHealth, isForceOfflineMode, selectedPOS, loggedInUser) => {
    const isLoggedIn = Boolean(selectedPOS && loggedInUser);
    const isOnlineMode = connectionHealth && !isForceOfflineMode;
    return isOnlineMode && isLoggedIn;
  },
);
