import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { Customer, GetCustomersParams } from 'types/Customer';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { getCustomers } from 'services/ErplyAPI';
import { RootState } from 'reducers';

export function doCustomerSearch(
  params: GetCustomersParams,
): ThunkAction<Promise<Customer[]>, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();

    const { before, on, after } = getPluginLifecycleHook('onDoCustomerSearch')(
      state,
    );

    const promises: Promise<Customer[]>[] = [];

    try {
      await dispatch(before(params));

      const newCustomerPromise = getCustomers({ ...params });

      promises.push(newCustomerPromise);

      const modifiedPromises = await dispatch(on(params, { promises }));

      const finalPromise = Promise.all([
        ...modifiedPromises.promises,
      ]).then(promises => promises.flatMap(promise => promise));

      return finalPromise.then(results => {
        dispatch(
          after(params, {
            finalPromise,
          }),
        );

        return results;
      });
    } catch (error) {
      console.error('Failed to fetch customers for search', error);
      return [];
    }
  };
}
