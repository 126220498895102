import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'components/Icon';

import { getCustomerDisplayState } from '../rdx/reducer';
import { SET_SELECTED_SCREEN } from '../rdx/actionTypes';

const ScreenSelectMenu = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { hasPermission, screens, selectedScreen } = useSelector(
    getCustomerDisplayState,
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectScreen = useCallback(
    (screen, index) => {
      dispatch({ type: SET_SELECTED_SCREEN, payload: { screen, index } });
      const { left, top, width, height } = screen;
      localStorage.setItem(
        'plugin:customerDisplay:screen',
        [left, top, width, height].join(','),
      );
      handleClose();
    },
    [dispatch],
  );

  /** If any screen matches the settings stored in localStorage, autoselect that option */
  useEffect(() => {
    const saved = localStorage
      .getItem('plugin:customerDisplay:screen')
      ?.split(',')
      ?.map(Number);
    if (saved) {
      const index = screens.findIndex(scr =>
        R.equals(saved, [scr.left, scr.top, scr.width, scr.height]),
      );
      if (0 <= index) selectScreen(screens[index], index);
    }
  }, [screens, selectScreen]);

  if (!hasPermission) {
    return (
      <Icon
        name="icon_error-triangle_alt"
        style={{ color: 'yellow' }}
        title="Multi-screen window placement permission denied or not supported"
      />
    );
  }

  return (
    <>
      <Button
        onClick={e => setAnchorEl(e.currentTarget)}
        style={{ color: 'white', padding: '0 4px' }}
      >
        {selectedScreen
          ? `Screen ${selectedScreen.index + 1}`
          : 'No screen selected'}
        <Icon name="arrow_triangle-down" style={{ color: 'white' }} />
      </Button>
      <Menu
        id="screen-select-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        style={{ top: '33px' }}
      >
        {screens.map((screen, index) => (
          <MenuItem
            key={String(index)}
            onClick={() => selectScreen(screen, index)}
          >
            Screen {index + 1} ({screen.label})
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ScreenSelectMenu;
