/* eslint-disable eqeqeq */
import i18next from 'i18next';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { batch } from 'react-redux';

import * as api from 'services/ErplyAPI/sales';
import { addWarning } from 'actions/Error';
import * as actionTypes from 'constants/returnProducts';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { RootState } from 'reducers';
import {
  getDocSelected,
  getHasItemsSelected,
  getOrdersSelected,
  getReturnDocument,
  getSalesDocs,
  getIsGiftReturn,
} from 'reducers/returnProducts';
import { REASONS } from 'constants/reasonCodesDB';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { PosPlugin } from 'plugins/plugin';
import { openManagerOverride } from 'containers/Forms/ManagerOverride/actions';

import { getProductsUniversal } from './productsDB';
import { addReturnProducts } from './ShoppingCart/addReturnProducts';
import { closeModalPage } from './ModalPage/closeModalPage';
import { getRightsForUserID } from './Login';

export interface FetchSalesDocsParams {
  getReturnedPayments: number;
  number: string;
  search_invoice_by_regular_and_custom_number: number;
  types?: string;
}

export function defineReturnedRows(
  originalRows: SaleDocumentResponse['rows'],
  remainingRows: SaleDocumentResponse['rows'],
) {
  return originalRows.map(row => {
    const remaining =
      remainingRows?.find(nrow => nrow.stableRowID === row.stableRowID)
        ?.amount ?? 0;
    return {
      ...row,
      // read: The difference starting from 'row.amount' to get to 'remaining'
      // eg: row.amount:5, remaining:3, to get from 5→3 is (-2)
      amount: Number(remaining) - Number(row.amount),
    };
  });
}

export function resetProductReturn() {
  return {
    type: actionTypes.RESET_TO_DEFAULT,
  };
}

export function setReturnDocument(documentID) {
  return {
    type: actionTypes.SET_RETURN_DOCUMENT,
    payload: documentID,
  };
}

export function setLoading(bool) {
  return {
    type: actionTypes.SET_LOADING,
    payload: bool,
  };
}

export function resetReturnDocument() {
  return {
    type: actionTypes.RESET_RETURN_DOCUMENT,
  };
}

export function resetSalesDocs() {
  return {
    type: actionTypes.RESET_SALES_DOCS,
  };
}

function isDocumentEligibleForReturn(document: SaleDocumentResponse) {
  const isInvoiceOrInvoiceWaybill = ['INVWAYBILL', 'INVOICE'].includes(
    document.type,
  );
  if (isInvoiceOrInvoiceWaybill) return document.paymentStatus === 'PAID';

  return true;
}

export function fetchSalesDocs(
  params: FetchSalesDocsParams,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook(
      'onFetchSalesDocsForReturn',
    )(getState()) as Required<Required<PosPlugin>['onFetchSalesDocsForReturn']>;

    await dispatch(before(params));

    dispatch(resetProductReturn());
    try {
      const [
        salesDocuments,
        salesDocumentsWithNonReturnedItemsOnly,
      ] = await Promise.all([
        api.getSalesDocuments({
          ...params,
          number: params.number.replace(/K$/g, ''),
        }),
        api.getSalesDocuments({
          ...params,
          nonReturnedItemsOnly: 1,
          number: params.number.replace(/K$/g, ''),
        }),
      ]);

      const returnableSalesDocuments = salesDocuments.filter(
        isDocumentEligibleForReturn,
      );

      if (returnableSalesDocuments.length > 0) {
        await dispatch(
          getProductsUniversal(
            {
              productIDs: returnableSalesDocuments[0]?.rows?.map(r =>
                Number(r.productID),
              ),
            },
            {
              addToCachedItems: true,
            },
          ),
        );
      }

      const nonReturnedRows =
        salesDocumentsWithNonReturnedItemsOnly.filter(
          isDocumentEligibleForReturn,
        )?.[0]?.rows ?? [];

      const returnedRows = defineReturnedRows(
        returnableSalesDocuments?.[0]?.rows ?? [],
        nonReturnedRows,
      );

      const handleNoRecordsRetrieved = () => {
        dispatch(
          addWarning(i18next.t('return:alerts.noneFound'), {
            selfDismiss: true,
          }),
        );
        batch(() => {
          dispatch({ type: actionTypes.SET_SALES_DOCS, payload: [] });
          dispatch({
            type: actionTypes.RESET_RETURN_DOCUMENT,
          });
        });
      };

      const handleSingleRecord = (
        records: SaleDocumentResponse[],
        returnedRecordsrows: SaleDocumentResponse['rows'],
      ) => {
        batch(() => {
          dispatch({ type: actionTypes.SET_SALES_DOCS, payload: records });
          dispatch({
            type: actionTypes.SET_RETURNED_ROWS,
            payload: Object.values(returnedRecordsrows).sort((a, b) =>
              Number(a.amount) < Number(b.amount) ? -1 : 1,
            ),
          });
          dispatch({
            type: actionTypes.SET_RETURN_DOCUMENT,
            payload: records[0].id,
          });
        });
      };

      const handleMultipleRecords = (records: SaleDocumentResponse[]) => {
        batch(() => {
          dispatch({ type: actionTypes.SET_SALES_DOCS, payload: records });
          dispatch({
            type: actionTypes.RESET_RETURN_DOCUMENT,
          });
        });
      };

      const updatedPayloads = await dispatch(
        on(params, {
          payload: returnableSalesDocuments,
          returnedRows,
        }),
      );

      const defineActionFinalization = (
        records: SaleDocumentResponse[],
        returnedRows: SaleDocumentResponse['rows'],
      ) => {
        if (records.length === 0) {
          return handleNoRecordsRetrieved();
        }

        if (records.length === 1) {
          return handleSingleRecord(records, returnedRows);
        }

        return handleMultipleRecords(records);
      };

      const finalizeAction = defineActionFinalization(
        updatedPayloads.payload,
        updatedPayloads.returnedRows,
      );

      await dispatch(after(params, finalizeAction));

      return finalizeAction;
    } catch (err) {
      console.error('Failed to load data for document return', err);
    }
    return undefined;
  };
}

export function setDocSelected(docSelected) {
  return {
    type: actionTypes.SET_DOC_SELECTED,
    payload: docSelected,
  };
}

export function resetDocSelected() {
  return {
    type: actionTypes.RESET_DOC_SELECTED,
  };
}

export function setOneOrderSelected(id, order) {
  return {
    type: actionTypes.SET_ONE_ORDER_SELECTED,
    payload: {
      id,
      order,
    },
  };
}

export function setOrdersSelected(ordersSelected) {
  return {
    type: actionTypes.SET_ORDERS_SELECTED,
    payload: ordersSelected,
  };
}

export function addSelectedOrdersToShoppingCart(): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> {
  return async (dispatch, getState) => {
    const state = getState();
    const hasItemsSelected = getHasItemsSelected(state);
    const salesDocs = getSalesDocs(state);
    const returnDocument = getReturnDocument(state);
    const docSelected = getDocSelected(state);
    const ordersSelected = getOrdersSelected(state);
    const isGiftReturn = getIsGiftReturn(state);

    await i18next.loadNamespaces('return');

    const returnReasonCodes = getReasonCodes(REASONS.RETURN)(state);

    if (salesDocs.length > 1 && docSelected && !returnDocument) {
      dispatch(setReturnDocument(docSelected));
      return;
    }
    if (salesDocs.length === 1 && docSelected && hasItemsSelected) {
      dispatch(setReturnDocument(docSelected));
      return;
    }
    if (!returnDocument) {
      dispatch(addWarning(i18next.t('return:alerts.documentRequired')));
      return;
    }
    if (!hasItemsSelected) {
      dispatch(addWarning(i18next.t('return:alerts.productsRequired')));
      return;
    }

    // prompt manager override in case user has no right to do referenced return
    await i18next.loadNamespaces('managerOverride');
    await dispatch(
      openManagerOverride([
        {
          key: 'returnWithReceipt',
          text: i18next.t('managerOverride:reasons.returnWithReceipt'),
          isPermitted: user =>
            getRightsForUserID(Number(user.userID)).then(
              ({ rightMakePOSRefunds }) => !!Number(rightMakePOSRefunds),
            ),
        },
      ]),
    );

    const orders = Object.values(ordersSelected).map(order => ({
      name: order.itemName,
      productID: order.productID,
      amount: -parseFloat(order.amount.toString()),
      needsWeightPopup: false,
      finalPriceWithVAT: order.finalPriceWithVAT,
      finalNetPrice: order.finalNetPrice,
      stableRowID: order.stableRowID,
      orderIndex: order.stableRowID,
      parentRowID: order.parentRowID,
      returnReasonID: order.returnReasonID,
      addContainerOverride: false,
      vatrateID: order.vatrateID,
      notes: order.notes,
      discount: order.discount,
      price: order.price,
    }));

    // remove the employeeID/employeeName as the return is processed by new employee
    const { employeeID, employeeName, ...restOfReturnDoc } =
      returnDocument ?? {};

    const ordersWithoutReason = Object.values(ordersSelected).filter(
      order => !Number(order.returnReasonID),
    );

    if (ordersWithoutReason.length > 0 && returnReasonCodes.length > 0) {
      dispatch(addWarning(i18next.t('return:alerts.reasonRequired')));
      return;
    }
    localStorage.setItem('returnDocID', returnDocument.id);
    await dispatch(
      addReturnProducts({
        returnDocument: restOfReturnDoc,
        orders,
        isGiftReturn,
      }),
    );
    dispatch(closeModalPage());
  };
}

export function setIsGiftReturn(payload) {
  return {
    type: actionTypes.SET_IS_GIFT_RETURN,
    payload,
  };
}
