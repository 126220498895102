import { createSelector } from 'reselect';
import uuidv4 from 'uuid/v4';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import i18next from 'i18next';

import { addWarning } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { setPayment } from 'actions/Payments/setPayment';
import { PosPlugin } from 'plugins/plugin';
import {
  getCurrentSalesDocument,
  getIsCurrentSaleAReturn,
  getIsUnreferencedReturn,
} from 'reducers/sales';
import { timestampInSeconds } from 'utils';
import { getAllPayments, getBalance } from 'reducers/Payments';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getLoggedInEmployeeID } from 'reducers/Login';

import { GiftCard } from './UIGiftCard';
import documentation from './documentation.md';
import { Config, Configuration, getConfiguration } from './configuration';
import { pluginID } from './constants';
import {
  getRemainingAmount,
  getReturnPaymentLimits,
  getShouldShowIssueGiftCardSection,
  getWasSaleCompletedMoreThan12MonthsAgo,
} from './selectors';
import OriginalPayment from './UIOriginalPayment';

const hammacherGiftCardRefundPlugin: PosPlugin<Configuration> = {
  id: pluginID,
  name: 'Hammacher - Issue new gift card on return',
  infoMDUrl: documentation,
  keywords: [
    'gift',
    'card',
    'refund',
    'return',
    'customer-specific',
    'hammacher',
  ],

  ComponentConfigurationByLevel: {
    Company: Config,
  },
  combineConfiguration: c =>
    R.mergeDeepLeft(c)({
      giftCardTypeID: 0,
    }),
  // Adds UI to issue new gift card as return payment
  UIGiftCard: GiftCard,
  // Disabled original serial gift card payment onClick functionality
  UIOriginalPayment: OriginalPayment,
  // Adds payment to redux
  onAddSerialGiftCardProduct: {
    on: (p, ap) => async (
      dispatch: ThunkDispatch<unknown, unknown, Action>,
      getState,
    ) => {
      const { giftCardTypeID } = getConfiguration(getState());
      const shouldMakeChanges = getShouldShowIssueGiftCardSection(getState());
      if (!shouldMakeChanges) return ap;

      const currentPayments = getAllPayments(getState());
      const serialAlreadyInUse = !!currentPayments.find(
        pmt => pmt.serial === p.giftCardSerial,
      );
      if (serialAlreadyInUse) {
        dispatch(
          addWarning(
            i18next.t('giftcard:alerts.serialAlreadyAdded', {
              serial: p.giftCardSerial,
            }),
            {
              dismissible: true,
              errorType: 'giftCardSerialAlreadyAdded',
              selfDismiss: 3000,
            },
          ),
        );
        throw new Error('Serial already in use');
      }

      const onDone = () => {
        dispatch(setPaymentSelected(''));
        dispatch(previousModalPage());
      };

      const isUnreferencedReturn = getIsUnreferencedReturn(getState());
      const remainingAmount = getRemainingAmount(getState());
      const balance = getBalance(getState());

      const amount = isUnreferencedReturn
        ? -balance
        : Math.max(-remainingAmount, -balance);

      await dispatch(
        setPayment({
          key: uuidv4(),
          type: 'GIFTCARD',
          caption: 'Gift card refund',
          amount,
          serial: p.giftCardSerial,
          // Needed to later identify request in onSaveSalesDocument.
          // This is the best solution I found that does not require plugin redux
          giftCardID: -1,
          giftCardTypeID,
        }),
      ).then(onDone, onDone);
      throw new Error('No need to add gift card to cart');
    },
  },
  // Modifies requests to removed giftCardID and properly save gift card
  onSaveSalesDocument: {
    on: (p, ap) => async (dispatch, getState) => {
      const currentSaleDoc = getCurrentSalesDocument(getState());
      const { pointOfSaleID, warehouseID } = getSelectedPos(getState());
      const customerID = getSelectedCustomerID(getState());
      const employeeID =
        currentSaleDoc?.employee?.id ?? getLoggedInEmployeeID(getState());
      return R.map(
        R.when(
          R.propEq('giftCardID', -1),
          R.pipe(
            R.dissoc('giftCardID'),
            R.when(
              R.propEq('requestName', 'saveGiftCard'),
              R.pipe(
                request => R.assoc('value', request.balance, request),
                R.mergeLeft({
                  purchasingCustomerID: customerID,
                  purchaseDateTime: timestampInSeconds(),
                  purchaseWarehouseID: warehouseID,
                  purchasePointOfSaleID: pointOfSaleID,
                  purchaseEmployeeID: employeeID,
                  purchaseInvoiceID: 'CURRENT_INVOICE_ID',
                  added: timestampInSeconds(),
                }),
              ),
            ),
          ),
        ),
      )(ap);
    },
  },
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      const isReferencedReturn = getIsCurrentSaleAReturn(getState());
      if (!isReferencedReturn) return ap;

      const newLimits = getReturnPaymentLimits(getState());
      const saleWasCompletedMoreThan12MonthsAgo = getWasSaleCompletedMoreThan12MonthsAgo(
        getState(),
      );

      const paymentTypes = [
        'CASH',
        'CARD',
        'GIFTCARD',
        'GIFTCARD',
        'STORECREDIT',
        'CHECK',
        'TIP',
      ];

      return R.evolve({
        paymentLimits: R.ifElse(
          R.always(saleWasCompletedMoreThan12MonthsAgo),
          // Only allow returns to new gift card
          R.always(
            R.pipe(
              R.map(type => ({ type, amount: -1 })),
              R.append({ type: 'GIFTCARD', serial: true, amount: -ap.total }),
            )(paymentTypes),
          ),
          R.pipe(
            // Do not allow return to store credit unless originally paid by store credit
            R.reject(R.propEq('type', 'STORECREDIT')),
            R.append({ type: 'STORECREDIT', amount: -1 }),
            // Remove limits that collide with newLimits
            R.reject(limit =>
              R.any(
                R.both(R.eqProps('type', limit), R.eqProps('serial', limit)),
                newLimits,
              ),
            ),
            // Remaining payment amounts by tender as limits
            R.concat(newLimits),
          ),
        ),
      })(ap);
    },
  },
  selectorOverrides: {
    getActiveAllowedTendersConfig: base =>
      createSelector(
        base,
        R.assocPath(
          ['config', 'allowOnlyOriginalTendersOnReturnWithReceipt'],
          true,
        ),
      ),
  },
};

export default hammacherGiftCardRefundPlugin;
