import React, { FC, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

type Props = {
  resolve: any;
  reject: any;
  dispatch: any;
  productName: string;
  current: number;
  total: number;
  tags: string[];
};

const EnterTagModal: FC<Props> = ({
  resolve,
  reject,
  productName,
  current,
  total,
}) => {
  const [tag, setTag] = useState('');
  const [checkError, setCheckError] = useState<boolean>(false);
  const error =
    tag.length >= 6 && tag.length <= 8
      ? undefined
      : 'Invalid tag - return tag should be between 6 and 8 numeric characters.';

  const handleChange = e => {
    const val = e.target.value.replace(/\D/g, '').slice(0, 8);
    setTag(val);
  };
  const onCancel = () => {
    reject();
  };

  const onSave = () => {
    setCheckError(true);
    if (tag.length >= 6 && tag.length <= 8) {
      resolve(tag);
    }
  };

  useShortcut('Escape', onCancel, 100);
  useShortcut('Enter', onSave, 100);

  return (
    <div data-testid="return-tag-container">
      <Modal.Header>
        <h3 className="font-weight-bold" data-testid="header">
          Enter Return Tag
        </h3>
      </Modal.Header>
      <Modal.Body data-testid="body">
        <p data-testid="product-name">{`Product name: ${productName} ${
          total > 1 ? `${current} / ${total}` : ''
        }`}</p>
        <OverlayTrigger
          placement="right"
          data-testid="overlay-trigger"
          overlay={
            <Tooltip id="tooltip-tag">
              Serial number (Tag) should be numeric and between 6-8 digits
              length.
            </Tooltip>
          }
        >
          <InputField
            autoFocus
            errorText={(checkError && error) || ''}
            onChange={handleChange}
            value={tag}
            data-testid="field"
            isInvalid={checkError && !!error}
          />
        </OverlayTrigger>

        <div className="text-right mt-3">
          <UIButton
            className="btn-cancel mr-1"
            action={onCancel}
            text="Cancel"
            data-testid="cancel"
          />
          <UIButton
            data-testid="save"
            className="save-button-view"
            action={onSave}
            text="Save"
          />
        </div>
      </Modal.Body>
    </div>
  );
};

export default EnterTagModal;
