import { getErrorMessage } from 'paymentIntegrations';
import { cancelPayment } from 'paymentIntegrations/adyen/requests/adyenRequests';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';

const cancelAdyenPayment = async params => {
  const {
    enableButtons,
    updateMessage,
    displayWarning,
    employeeID,
    isPaymentOrReturn,
  } = params;

  try {
    const cancelRequest = await cancelPayment(employeeID, isPaymentOrReturn);

    if (cancelRequest.resultStatus === 'Success') {
      return updateMessage('Payment transaction cancelled');
    }
  } catch (error) {
    enableButtons([FUNC_BUTTONS.CHECK_STATUS]);
    updateMessage(
      getErrorMessage(
        error,
        'Cancellation failed. Please check that payment transaction has been cancelled in terminal',
      ),
    );
    displayWarning(error.message);
    return false;
  }

  return updateMessage(
    'Cancellation failed. Please check that payment transaction has been cancelled in terminal',
  );
};

export default cancelAdyenPayment;
