import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SelectField = ({
  size,
  title,
  options = [],
  value,
  onChange,
  skin,
  isValid,
  isInvalid,
  selectIcon: SelectIcon = UpDownArrows,
  style,
  className,
  errorText,
  ...rest
}) => {
  const optionElements = ((options instanceof Array && options) || []).map(
    optionToElement,
  );
  return (
    <div
      className={classNames([(className || '').split(' ')], {
        valid: isValid,
        invalid: isInvalid,
      })}
    >
      <InputGroup size={size} style={skin.InputGroup}>
        {title ? (
          <InputGroup.Prepend style={skin.Prepend}>
            <InputGroup.Text style={skin.PrependText}>{title}</InputGroup.Text>
          </InputGroup.Prepend>
        ) : null}
        <FormControl
          as="select"
          style={{
            height: 'auto',
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            cursor: 'pointer',
            WebkitAppearance: 'none',
            ...skin.Input,
          }}
          {...{ isValid, isInvalid, value, onChange, ...rest }}
        >
          {optionElements}
        </FormControl>
        <SelectIcon />
        <FormControl.Feedback
          type="invalid"
          style={{ minHeight: '1em', display: 'block', marginBottom: '-1em' }}
        >
          {errorText || ''}
        </FormControl.Feedback>
      </InputGroup>
    </div>
  );
};
export default SelectField;

export const UpDownArrows = ({ style }) => (
  <div style={style}>
    <InputGroup.Append
      style={{
        position: 'absolute',
        right: 5,
        top: 5,
        bottom: 5,
        zIndex: 5,
        pointerEvents: 'none',
      }}
    >
      <InputGroup.Text
        style={{
          width: 0,
          flexDirection: 'column',
          justifyContent: 'center',
          border: 0,
          backgroundColor: 'transparent',
        }}
      >
        <span
          className="dropdown-arrow"
          style={{
            fontSize: 18,
            lineHeight: '12px',
            fontFamily: 'ElegantIcons',
          }}
        >
          B
        </span>
        <span
          className="dropdown-arrow"
          style={{
            fontSize: 18,
            lineHeight: '12px',
            fontFamily: 'ElegantIcons',
          }}
        >
          C
        </span>
      </InputGroup.Text>
    </InputGroup.Append>
  </div>
);

/* PropTypes.node is Any renderable object (str,num,node,fragment, etc.) */
SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.node,
      }),
    ]),
  ),
};

function optionToElement(opt, i) {
  // eslint-disable-next-line prefer-const
  let { name, value, selected = false } = opt;
  if (value !== undefined) {
    if (name === undefined) {
      name = opt.value;
      console.warn(
        "<InputField type='select'> received option with a value but no name",
        opt,
      );
    }
    return (
      <option
        data-testid="option"
        data-test-key={value}
        key={`${i}-${value}`}
        value={value}
      >
        {name}
      </option>
    );
  }

  const val = opt instanceof Object ? JSON.stringify(opt) : opt;

  return (
    <option key={val} value={val} selected={val}>
      {val}
    </option>
  );
}
