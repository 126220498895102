import * as R from 'ramda';

import { openPaymentModal } from 'actions/modalPage';

import { SET_MULTIPLE_DOCUMENTS, SET_PURCHASE_ORDER } from './actionTypes';

export const setPurchaseOrderNumber = (number: string) => {
  return {
    type: SET_PURCHASE_ORDER,
    payload: number,
  };
};
export const setSelectedSales = selectedSales => {
  return {
    type: SET_MULTIPLE_DOCUMENTS,
    payload: selectedSales,
  };
};
export const clearSelectedSales = setSelectedSales({});
export const multipleInvoicePayments = (
  sales: Record<string, any>[],
) => async dispatch => {
  const toPay = Object.fromEntries(
    sales.map(doc => [doc.id, doc.total - doc.paid]),
  );
  dispatch(setSelectedSales(toPay));
  const total = sales.map(s => s.total).reduce(R.add, 0);
  const paid = sales.map(s => s.paid).reduce(R.add, 0);
  dispatch(
    openPaymentModal({
      props: {
        // If current salesdoc has paid, then POS will assign/overwrite a 'paid' payment
        // see the constructor of InitialState
        // To avoid this, we pass the document without a .paid flag

        // Future: Ideally we would want to not pass any document at all, as we just want to collect unassociated payments
        // but POS expects there to be a target document, and failure to specify one will probably cause a new one to be created
        salesDocument: R.assoc('paid', 0)(sales[0]),
        total,
        payments:
          paid.toFixed(2) === '0.00'
            ? {}
            : {
                paid: { type: 'PAID', amount: paid.toFixed(2) },
              },
      },
    }),
  );
};
