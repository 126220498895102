import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useThrottle } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { handleScanning } from 'actions/scanner';
import {
  getIdCardReaderInput$,
  getScaleScannerInput$,
  registerCustomerDisplay$,
} from 'actions/integrations/extdev/index';
import { getProductsInShoppingCart, getTotal } from 'reducers/ShoppingCart';
import { useBehaviourSubject } from 'utils/hooks/rxjsHooks';
import { getShowPricesWithTax } from 'reducers/configs/settings';
import { handleIdCardRead } from 'actions/cardReader';
import { getComponents } from 'reducers/modalPage';
import { idCardReaderWhiteList } from 'constants/modalPage';

export const useMagellanScanner = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const sub = getScaleScannerInput$.subscribe({
      next: val => {
        // change the input value if an input was focused
        if (val?.BarVal) {
          dispatch(handleScanning(val?.BarVal));
        }
      },
    });
    return () => sub.unsubscribe();
  }, []);
};

export const useIdCardReader = () => {
  const dispatch = useDispatch();
  const modals = useSelector(getComponents);
  const modalPagesCount = modals.filter(
    c => !idCardReaderWhiteList.includes(c.component),
  )?.length;

  useEffect(() => {
    // While blacklisted pages are open, do not do anything
    if (modalPagesCount) return () => undefined;

    // Otherwise subscribe to ID cards
    const sub = getIdCardReaderInput$.subscribe({
      next: val => {
        if (val?.personalIdentificationCode) {
          dispatch(handleIdCardRead(val));
        }
      },
    });
    return () => sub.unsubscribe();
  }, [dispatch, modalPagesCount]);
};
export const useGlancetronCustomerDisplay = () => {
  // Calculate data
  const isWithTax = useSelector(getShowPricesWithTax);
  const cart = useSelector(getProductsInShoppingCart);
  const total = useSelector(getTotal);
  const data = useMemo(
    () => ({
      products: cart.map(r => ({
        id: Number(r.productID),
        name: r.name,
        qty: r.amount?.toString(),
        final: (isWithTax ? r.finalPriceWithVAT : r.finalPrice)?.toFixed(2),
      })),
      total: total.toFixed(2),
    }),
    [total, cart, isWithTax],
  );

  const memoized = useThrottle(data, 500);

  const behavSubj$ = useBehaviourSubject(memoized);

  useEffect(() => {
    registerCustomerDisplay$(behavSubj$);
  }, [behavSubj$]);
};
