import React from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';

export const UICustomReturnDiscountButtons: PosPlugin['UICustomReturnDiscountButtons'] = ({
  children,
  order,
  value,
  isProductNonDiscountable,
  max,
  onChange,
}) => {
  const membershipProductId = useSelector(
    getSetting('plugin_dsc_membership_product_id'),
  );
  const isMemberShipProduct =
    Number(order.productID) === Number(membershipProductId);

  if (!isMemberShipProduct) return children;
  return (
    <ToggleButtonGroup
      defaultValue={value}
      value={value}
      style={{ flexWrap: 'wrap' }}
      name="discount-percentage"
      type="radio"
      onChange={onChange}
      data-testid="discount-percentages"
    >
      {[0, 100].map(discount => (
        <ToggleButton
          key={uuid.v1()}
          variant="light"
          disabled={max < discount || isProductNonDiscountable}
          value={discount}
          data-testid="percentage"
          data-test-key={discount}
        >
          {discount !== 0 ? `-${discount}%` : `${discount}%`}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
