import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getSavedPosTimeoutSettings,
  getSavedSetting,
} from 'reducers/configs/settings';
import { getIsLoading, getUserLoggedIn } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';

export const useIsLoggedIn = () => {
  const selectedPOS = useSelector(getSelectedPos);
  const loggedInUser = useSelector(getUserLoggedIn);
  const notLoading = !useSelector(getIsLoading);

  const settings: {
    timeout: number;
    settings: {
      browsingSales: boolean;
      customerSelected: boolean;
      inputs: boolean;
      mousemove: boolean;
      openCloseDay: boolean;
      productsInCart: boolean;
      stockLevels: boolean;
      zReport: boolean;
      action: 'hardLogout' | 'softLogout';
    };
  } = {
    timeout: useSelector(getSavedSetting('pos_timeout')),
    settings: useSelector(getSavedPosTimeoutSettings),
  };

  const shouldLogout: boolean = useMemo(() => {
    const lastTimestamp = Number(localStorage.getItem('lastTimestamp'));
    return (
      lastTimestamp > 0 &&
      Number(settings.timeout) > 0 &&
      Date.now() - lastTimestamp > Number(settings.timeout) * 1e3
    );
  }, [loggedInUser, settings.timeout]);

  return useMemo(
    () => selectedPOS && loggedInUser && !shouldLogout && notLoading,
    [selectedPOS, loggedInUser, shouldLogout, notLoading],
  );
};
