// region Workaround script
// Behaviour of the workaround script
//   * What checks to perform, what dates / versions to compare against
//   * Whether to add artificial slowdown for UX
//   * Whether to check certs too or only versions
import { CAFA_ENTRY } from 'constants/CAFA';

/** Installer version older than this is treated as an issue and will trigger the update */
export const TARGET_INSTALLER_VERSION = 'v2.4.0';
/** Certificates which expire before this date are treated as an error and will trigger the update */
export const TARGET_CERT_DATE = new Date('2024-02-17T09:57:29Z');
/** All requests to the installer/microservices are slowed down by this many ms. This can leave the illusion of the application trying a bit harder */
export const SLOWDOWN_FACTOR = 0;
/**
 * * strict - OK only if request succeeds and gives sufficiently late certs
 * * loose - also OK if the request fails, fails only if request reports certs that are too old
 * * log-only - Always ok, request performed only for logging purposes
 * * false - Requests skipped completely
 */
export const CHECK_MS_CERTS: 'strict' | 'loose' | 'log-only' | false =
  'log-only';
// endregion

// region cookie
// The cookie is used to track whether the current device has already been updated, to prevent showing the workaround again.
// The cookie is also used to temporarily dismiss the workaround in case of failure (in that case the cookie is set with an expiration
/**
 * A number to indicate the version of this workaround code.
 * This should be incremented if we discover an issue that makes an 'ok' result not trustworthy
 */
export const WORKAROUND_VERSION = 1;
/** Name of the cookie to set on success / bypass */
export const COOKIE_NAME = 'brazil_cert_2024';
/** In case of error, user has the option to temporarily bypass the install to deal with it later. If they choose to do so, the cookie is set for this amount of time */
export const SNOOZE_EXPIRATION_TIME = 10 * 60 * 60 * 1e3;
// endregion

// region Rollout
// Initially, only marked accounts should run the workaround
// After the cutoff date, all accounts should run the workaround (as safety in case we miss some)
/** The date after which the workaround should become active for all users regardless of setting */
export const FORCED_ROLLOUT_CUTOFF_DATE = new Date('2024-01-30T00:00:00Z');
/** The setting to look for in getConfParameters to determine whether the workaround should run for any given account */
export const ROLLOUT_CONF_PARAM_NAME = 'brazil_2024_cert_rollout';
// endregion

// region Logging

/**
 * Spec for cafa record to save when workaround script finishes successfully
 * If set to false, then no logs are recorded on success
 */
export const CAFA_FOR_SUCCESS: Partial<CAFA_ENTRY> | false = {
  name: 'brazil_2024_cert_logs',
  level: 'Pos',
  value: {
    isSuccess: true,
  },
};
/**
 * Spec for cafa record to save when workaround script fails
 * If set to false, then no logs are recorded on failure
 */
export const CAFA_FOR_ERROR: Partial<CAFA_ENTRY> | false = {
  name: 'brazil_2024_cert_logs',
  level: 'Pos',
  value: {
    isSuccess: false,
  },
};
// endregion
