import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import * as MUI from '@material-ui/core';

import { openModalPage } from 'actions/ModalPage/openModalPage';
import InputField from 'components/FieldTypes/InputField';
import { PostableLink } from 'containers/Header/components/PostableLink';

/**
 * @fileoverview
 * Wrappers for various types of common form components
 * Inputs' 'title' properties are displayed on the previous row by default, unless prependTitle is also passed
 * Inputs are 'lg' size by default
 * (removed) Inputs have red color if no onChange is specified to highlight unimplemented inputs
 *
 * Settings-specific components <Action>, <Page> and <Section>
 * */

/** All state setters of currently mounted Section components */
const sectionStateSetters = new Set();
/** Collapse every {@link Section} component that is currently mounted */
export const collapseAllSections = () =>
  Array.from(sectionStateSetters).forEach(set => set(false));
/**
 * A wrapper for a bunch of Inputs that groups them under a common title
 *
 * Can be individually expanded and collapsed, will also collapse when {@link collapseAllSections} is called
 * @see {@link collapseAllSections}
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 * @param {string} [props.title]
 *
 */
export const Section = ({ title = undefined, children = [] }) => {
  const [show, setShow] = useState(true);
  // Collect the setter for 'collapseAllSections'
  useEffect(() => {
    sectionStateSetters.add(setShow);
    return () => sectionStateSetters.delete(setShow);
  }, [setShow]);

  // Not using useShortcut because it only allows specifying the key
  // While i can check modifiers in the callback, it will still block
  // other useShortcuts on the same key even if i choose to do nothing
  // Therefore, until useShortcut supports specifying modifiers / filter conditions / returning false to skip,
  // It should not be used here so it doesn't block other plus/minus shortcuts
  useEffect(() => {
    const listener = e => {
      if (e.code === 'NumpadSubtract' && e.ctrlKey && e.shiftKey)
        setShow(false);
      if (e.code === 'NumpadAdd' && e.ctrlKey && e.shiftKey) setShow(true);
    };
    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [setShow]);

  /** Chevron icon button to toggle collapse status */
  const ch = (
    <MUI.IconButton onClick={() => setShow(c => !c)}>
      {show ? <ExpandLess /> : <ExpandMore />}
    </MUI.IconButton>
  );
  return (
    <>
      {title ? (
        // TODO: for quality-of-life, allow caller to specify header level
        //  if not specified, inherit level + 1 from ancestor
        //  if no ancestor, use default starting level
        <h4 style={{ fontSize: 16, display: 'flex', alignItems: 'center' }}>
          {ch}
          {title}
        </h4>
      ) : (
        ch
      )}
      <MUI.Collapse in={show}>
        <Table size="sm">
          <tbody>
            {React.Children.map(children, (child, i) => (
              <tr key={(child && child.props && child.props.name) || i}>
                <td>{child}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </MUI.Collapse>
    </>
  );
};

/** A fullwidth button to perform some action */
export const Action = ({ title, onClick, ...rest }) => (
  <ListGroup.Item
    action
    as="li"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
    {...rest}
  >
    {title}
  </ListGroup.Item>
);

export const ExternalPage = ({ title, href, post, ...rest }) => (
  <PostableLink href={href} post={post}>
    <Page
      title={
        <>
          {title}
          <sup style={{ fontFamily: 'FontAwesome' }}>{' \uf08e'}</sup>
        </>
      }
      data-testid="external-page"
      data-test-key={`${href}${post}`}
      data-test-post={JSON.stringify(post)}
      {...rest}
    />
  </PostableLink>
);
/**
 * A fullwidth button that redirects the user to a subpage
 * @param {Object} props
 * @param {string} props.title The title to display on the button
 * @param {string} props.value The page to open when clicked
 * @param {Object=} props.props The props to pass to the opened page
 */
export const Page = ({ title, value, props = {}, ...rest }) => {
  const dispatch = useDispatch();
  const action = useCallback(
    () => dispatch(openModalPage({ component: value, props })),
    [value, dispatch, props],
  );

  return useMemo(
    () => (
      <Action
        data-testid="settings-page"
        data-test-key={value}
        style={{ whiteSpace: 'pre-line' }}
        title={
          <>
            {title}
            {value && (
              <i
                className="arrow arrow_carrot-right"
                style={{ fontSize: 24, float: 'right' }}
              />
            )}
          </>
        }
        onClick={value && action}
        {...rest}
      />
    ),
    [title, value, action],
  );
};

/** A check box */
export const Checkbox = ({
  title = undefined,
  name,
  value,
  onChange,
  children = undefined,
  customCallback = undefined,
  ...rest
}) => (
  <>
    <InputField
      type="checkbox"
      size="lg"
      value={value}
      onChange={useCallback(e => onChange(name, e.target.value), [
        name,
        onChange,
      ])}
      title={title}
      customCallback={customCallback}
      {...rest}
    >
      <div style={{ color: name ? '' : 'red' }}>{children}</div>
    </InputField>
  </>
);

/**
 * A Text field
 * @param props
 * @param {string} props.title A title to display in front of the text field (or in the text field if prependTitle is also passed
 * @param {string} [props.prependTitle] A title to display in front of the field instead of 'title' - if this is defined then title is displayed as the PrependText of the input instead
 */
export const TextField = ({
  title = undefined,
  name,
  value,
  onChange,
  prependTitle,
  ...rest
}) => (
  <>
    {title && !prependTitle ? <span>{title}</span> : undefined}

    <InputField
      size="lg"
      title={prependTitle ? title : undefined}
      value={value}
      onChange={useCallback(e => onChange(name, e.target.value), [
        name,
        onChange,
      ])}
      {...rest}
    />
  </>
);

/**
 * A multiline text field
 * @param props
 * @param {string} props.title A title to display in front of the text field (or in the text field if prependTitle is also passed
 * @param {string} [props.prependTitle] A title to display in front of the field instead of 'title' - if this is defined then title is displayed as the PrependText of the input instead
 */
export const TextArea = ({
  title = undefined,
  name,
  value,
  onChange,
  ...rest
}) => (
  <>
    {title ? <span>{title}</span> : undefined}
    <InputField
      size="lg"
      as="textarea"
      value={value}
      style={{ fontSize: 16 }}
      onChange={useCallback(e => onChange(name, e.target.value), [
        name,
        onChange,
      ])}
      rows={4}
      {...rest}
    />
  </>
);

/**
 * A Select field
 * @param props
 * @param {string} props.title A title to display in front of the text field (or in the text field if prependTitle is also passed
 * @param {string} [props.prependTitle] A title to display in front of the field instead of 'title' - if this is defined then title is displayed as the PrependText of the input instead
 */
export const SelectField = ({
  title = undefined,
  name,
  options,
  value,
  prependTitle,
  onChange,
  ...rest
}) => (
  <>
    {title && !prependTitle ? <span>{title}</span> : undefined}
    <InputField
      size="lg"
      title={prependTitle ? title : undefined}
      onChange={useCallback(e => onChange(name, e.target.value), [
        name,
        onChange,
      ])}
      value={value}
      options={options}
      type="select"
      {...rest}
    />
  </>
);
