import { addError, addSuccess, addWarning } from 'actions/Error';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { timestampInSeconds } from 'utils';
import { printCashInOut } from 'actions/printTemplates';
import { openCashDrawer } from 'actions/integrations/printer';
import i18n from 'containers/App/i18n';
import { createConfirmation } from 'actions/Confirmation';
import {
  getCurrencyCode,
  getCurrencyFormatter,
  getSetting,
} from 'reducers/configs/settings';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getEmployeeID } from 'reducers/Payments';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { modalPages } from 'constants/modalPage';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';

import { openModalPage } from './ModalPage/openModalPage';

/**
 * @param sum Amount of cash to put in (negative values to take cash out)
 */
export function tryCashInOut(sum: number, comment: string) {
  return async (
    dispatch,
    getState,
  ): Promise<boolean> => {
    const { before, on, after } = getPluginLifecycleHook('onTryCashInOut')(
      getState(),
    );

    await dispatch(before({ sum, comment }));

    const cashInReasons = getReasonCodes(REASONS.CASH_IN)(getState());
    const cashOutReasons = getReasonCodes(REASONS.CASH_OUT)(getState());
    const reasons = sum < 0 ? cashOutReasons : cashInReasons;
    const absAmount = Math.abs(sum);
    const type = sum < 0 ? 'out' : 'in';
    const t = i18n.getFixedT(null, 'cashInOut');
    const format = getCurrencyFormatter(getState());
    if (!sum) {
      dispatch(
        addWarning(t('enterAmount'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
      throw new Error('Amount missing');
    } else {
      try {
        const { reasonID, name: reasonName } = (await new Promise((res, rej) =>
          dispatch(
            createConfirmation(res, rej, {
              title: t('confirm.title', { type, amount: format(absAmount) }),
              body: t('confirm.body', { type, amount: format(absAmount) }),
            }),
          ),
        ).then(
          () =>
            new Promise(resolve => {
              if (reasons.length === 0) return resolve({});
              dispatch(
                openModalPage({
                  component: modalPages.reasonPopup,
                  isPopup: true,
                  props: {
                    resolve,
                    reasonCodes: reasons,
                    title: t(`reasonTitle`, { type }),
                  },
                }),
              );
            }),
        )) as any;

        const params = await dispatch(
          on(
            { sum, comment },
            {
              warehouseID: getSelectedWarehouseID(getState()),
              employeeID: getEmployeeID(getState()),
              currencyCode: getCurrencyCode(getState()),
              comment: reasonName ? `${comment}\n${reasonName}` : `${comment}`,
              reasonID,
              sum,
              pointOfSaleID: getSelectedPosID(getState()),
              added: timestampInSeconds(),
            },
          ),
        );

        await doClientRequest({
          ...params,
          sum: Math.abs(sum),
          request: params.sum < 0 ? 'POSCashOUT' : 'POSCashIN',
        });
        dispatch(after({ sum, comment }, undefined));
        dispatch(
          addSuccess(t('done'), {
            selfDismiss: true,
            dismissible: false,
          }),
        );

        if (getSetting('touchpos_print_cash_in_out_receipt')(getState())) {
          dispatch(printCashInOut({ comment, sum, type, reasonID }));
        }
        if (getSetting('cash_inout_open_drawer')(getState())) {
          dispatch(openCashDrawer());
        }

        return true;
      } catch (err) {
        if (err) {
          console.error('Error performing cash in/out', err);
          dispatch(
            addError(t('alerts:genericError'), {
              selfDismiss: true,
              dismissible: false,
            }),
          );
        }
        throw err;
      }
    }
  };
}
