import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import * as R from 'ramda';
import classNames from 'classnames';

import {
  Answer,
  AnswerWithExtraInput,
  AnswerWithExtraInputDict,
  Question,
} from '../types';
import { dictToArrayByOrder } from '../utils';

const useStyles = makeStyles(theme => ({
  questionCard: {
    width: '100%',
    marginBottom: '1rem',
    padding: '1.5rem 1.25rem',

    '& label': {
      marginBottom: '0',
    },
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  invalidCard: {
    borderColor: theme.palette.error.main,
  },
  invalidMessage: {
    marginTop: '1rem',
    '& .MuiSvgIcon-root': {
      marginRight: '0.5rem',
    },
  },
}));

type AnswersComponentProps = {
  answers: AnswerWithExtraInput[];
  selectedAnswers: Answer['id'][];
  toggleAnswer: (id: Answer['id']) => void;
  updateExtraAnswerInput: (
    id: Answer['id'],
    extraInput: AnswerWithExtraInput['extraInput'],
  ) => void;
};

const CheckboxAnswers: FC<AnswersComponentProps> = ({
  answers,
  selectedAnswers,
  toggleAnswer,
  updateExtraAnswerInput,
}) => (
  <>
    {answers.map(a => {
      const isChecked = selectedAnswers.includes(a.id);
      const isMissingExtraInput = !a.extraInput && isChecked;
      return (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={() => toggleAnswer(a.id)}
              />
            }
            label={a.content}
          />
          {a.needsInput && (
            <TextField
              multiline
              fullWidth
              variant="outlined"
              disabled={!isChecked}
              value={a.extraInput ?? ''}
              onChange={e => updateExtraAnswerInput(a.id, e.target.value)}
              error={isMissingExtraInput}
              helperText={isMissingExtraInput ? 'Required' : ''}
            />
          )}
        </Grid>
      );
    })}
  </>
);

const RadioButtonAnswers: FC<AnswersComponentProps> = ({
  answers,
  selectedAnswers,
  toggleAnswer,
  updateExtraAnswerInput,
}) => (
  <Grid item xs={12}>
    <RadioGroup
      aria-labelledby="answers"
      value={selectedAnswers[0] ?? ''}
      onChange={e => toggleAnswer(e.target.value)}
      name="answers"
    >
      {answers.map(a => {
        const isChecked = selectedAnswers[0] !== a.id;
        const isMissingExtraInput = !a.extraInput && isChecked;
        return (
          <>
            <FormControlLabel
              value={a.id}
              control={<Radio />}
              label={a.content}
            />
            {a.needsInput && (
              <TextField
                multiline
                fullWidth
                variant="outlined"
                disabled={isChecked}
                value={a.extraInput ?? ''}
                onChange={e => updateExtraAnswerInput(a.id, e.target.value)}
                error={isMissingExtraInput}
                helperText={isMissingExtraInput ? 'Required' : ''}
              />
            )}
          </>
        );
      })}
    </RadioGroup>
  </Grid>
);

const FormFeedbackQuestion: FC<{
  question: Question;
  hasValidAnswer: boolean;
  feedbackState?: AnswerWithExtraInputDict;
  updateFeedback: (
    question: Question,
    newAnswers: AnswerWithExtraInputDict,
  ) => void;
}> = ({ question, hasValidAnswer, feedbackState = {}, updateFeedback }) => {
  const styles = useStyles();

  const [answers, setAnswers] = useState<AnswerWithExtraInputDict>(
    R.mergeDeepRight(question.answers, feedbackState),
  );
  useEffect(() => {
    setAnswers(prev => R.mergeDeepRight(prev, feedbackState));
  }, [feedbackState]);

  const selectedA = useMemo(() => Object.keys(feedbackState), [feedbackState]);

  const toggleAnswer = (id: Answer['id']) => {
    // remove already existing
    if (feedbackState?.[id]) {
      updateFeedback(question, R.dissoc(id, feedbackState));
      // add new if multi answers
    } else if (question.multiOptions) {
      updateFeedback(question, R.assoc(id, answers[id], feedbackState));
      // replace existing if single answer
    } else {
      updateFeedback(question, R.assoc(id, answers[id], {}));
    }
  };

  const updateExtraAnswerInput = (
    id: Answer['id'],
    extraInput: AnswerWithExtraInput['extraInput'],
  ) => {
    const updatedAnswer = R.assocPath(
      [id, 'extraInput'],
      extraInput,
      feedbackState,
    );
    updateFeedback(question, updatedAnswer);
  };

  return (
    <Card
      variant="outlined"
      className={classNames({
        [styles.questionCard]: true,
        [styles.invalidCard]: !hasValidAnswer,
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{question.content}</Typography>
        </Grid>
        {question.multiOptions ? (
          <CheckboxAnswers
            answers={dictToArrayByOrder(answers)}
            selectedAnswers={selectedA}
            toggleAnswer={toggleAnswer}
            updateExtraAnswerInput={updateExtraAnswerInput}
          />
        ) : (
          <RadioButtonAnswers
            answers={dictToArrayByOrder(answers)}
            selectedAnswers={selectedA}
            toggleAnswer={toggleAnswer}
            updateExtraAnswerInput={updateExtraAnswerInput}
          />
        )}
        {!hasValidAnswer && (
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            className={styles.invalidMessage}
          >
            <ErrorOutlineIcon color="error" />
            <Typography color="error" component="span">
              This is a required question
            </Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default FormFeedbackQuestion;
