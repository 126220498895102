import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import * as R from 'ramda';

import { FormFieldConfiguration } from '../../../types';

import DisabledOnSelect from './DisabledOnSelect';
import useFieldConfiguration, { Props } from './useFieldConfiguration';

const useStyles = makeStyles(theme => ({
  formField: {
    padding: '0.5rem 1rem',
    backgroundColor: theme.palette.background.paper,
    marginTop: '0.5rem',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

function FieldConfigurationMobile({
  field,
  label,
  config = {} as FormFieldConfiguration,
  updateConfig,
}: Props) {
  const styles = useStyles();
  const {
    editMode: [editMode, setEditMode],
    title: [title, { set: setTitle, reset: resetTitle, save: saveTitle }],
    defaultValue: {
      Input: DefaultValueInput,
      update: updateDefaultValue,
      isSupported: supportsDefaultValue,
      isDisableable: isAllowedToDisable,
    },
  } = useFieldConfiguration({
    field,
    label,
    config,
    updateConfig,
  });

  return (
    <Grid key={field} item xs={12} container className={styles.formField}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {editMode ? (
            <TextField
              fullWidth
              label="Field name"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          ) : (
            <b>{title ?? label}</b>
          )}
          <div>
            {editMode ? (
              <IconButton onClick={saveTitle} title="Save">
                <SaveIcon color="secondary" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setEditMode(true)} title="Edit">
                <EditIcon color="primary" />
              </IconButton>
            )}
            {config.label && (
              <IconButton onClick={resetTitle} title="Reset">
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            )}
          </div>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {supportsDefaultValue ? (
          <DefaultValueInput
            label="Default value"
            value={config.defaultValue}
            onChange={updateDefaultValue}
          />
        ) : null}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              disabled={!isAllowedToDisable}
              checked={config.enabled ?? false}
              onChange={() =>
                updateConfig(field, R.assoc('enabled', !config.enabled, config))
              }
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControlLabel
          label="Required"
          control={
            <Checkbox
              disabled={!isAllowedToDisable}
              checked={config.required ?? false}
              onChange={() =>
                updateConfig(
                  field,
                  R.assoc('required', !config.required, config),
                )
              }
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DisabledOnSelect
          value={config.disabledOn}
          disabled={!isAllowedToDisable}
          label="Disabled on"
          onChange={e =>
            updateConfig(field, R.assoc('disabledOn', e.target.value, config))
          }
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(FieldConfigurationMobile);
