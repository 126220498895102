import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import QuestionMark from 'components/QuestionMark';
import useErrorState, { translateError } from 'utils/hooks/useErrorState';
import fetchSplitsConfigByID, { setMarketplaceSPS } from 'actions/splits';

import RenderFormItem from '../../../../components/FormFieldCtx';

import { adyenFields } from './formShapes';
import './adyenSettings.scss';

const AdyenSettings = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.adyen.${text}`, ...rest);
  const dispatch = useDispatch();
  const superContext = useContext(Ctx);
  const { configuration, setEnableUpdate } = superContext.values;

  const validationObject = { ...adyenFields };
  const { errors, hasErrors } = useErrorState(configuration, validationObject);

  const [displayMarketplaceFields, toggleMarketplacefields] = useState(false);

  const [fetchedSplits, setFetchedSplits] = useState(null);
  const [splitsConfig, setSplitsConfig] = useState([]);
  const [accountOptions, setAccountOptions] = useState([
    { name: '', value: '' },
  ]);

  useEffect(() => {
    if (
      superContext.values &&
      superContext.values.initialIntegrationState &&
      superContext.values.initialIntegrationState.enableMarketplace &&
      superContext.values.initialIntegrationState.enableMarketplace === 1
    ) {
      return toggleMarketplacefields(true);
    }
    return toggleMarketplacefields(false);
  }, []);

  // If there are errors disable update button
  useEffect(() => {
    setEnableUpdate(!hasErrors);
  }, [hasErrors]);

  useEffect(() => {
    if (splitsConfig.length) {
      dispatch(setMarketplaceSPS(splitsConfig));
    }
  }, [splitsConfig]);

  const obtainSplitsConfig = async value => {
    const configsInCafa = await dispatch(fetchSplitsConfigByID(value));

    if (configsInCafa && typeof configsInCafa === 'object') {
      setFetchedSplits(configsInCafa);
      const accounts = Object.keys(configsInCafa);

      const defaultOption = { name: 'Select account', value: '' };
      const optionsFromConfig = [defaultOption];
      accounts.forEach(account =>
        optionsFromConfig.push({ name: account, value: account }),
      );
      return setAccountOptions(optionsFromConfig);
    }
    setAccountOptions([{ name: '', value: '' }]);
    return false;
  };

  // Update configuration fields
  const onChange = (key, value) => {
    if (key === 'enableMarketplace') {
      toggleMarketplacefields(!displayMarketplaceFields);
    }

    if (key === 'marketplaceID') {
      obtainSplitsConfig(value);
    }
    if (key === 'marketplaceAccount') {
      setSplitsConfig([...fetchedSplits[value]]);
    }
    superContext.onChange('configuration', { ...configuration, [key]: value });
  };

  useEffect(() => {
    if (
      superContext.values &&
      superContext.values.initialIntegrationState &&
      superContext.values.initialIntegrationState.marketplaceID &&
      superContext.values.initialIntegrationState.marketplaceAccount
    ) {
      obtainSplitsConfig(
        superContext.values.initialIntegrationState.marketplaceID,
      );
    }
  }, []);

  return (
    <Ctx.Provider value={{ values: configuration, onChange }}>
      <span>{t('title')}</span>
      <QuestionMark
        className="ml-2 mb-1"
        style={{ padding: '0px 8px' }}
        color="#444"
        code={1277}
        groupID={61}
      />
      <QuestionMark
        className="ml-5 mb-1"
        style={{ padding: '0px 8px' }}
        color="#444"
        code={1278}
        groupID={61}
      />

      <div>
        {Object.values(adyenFields).map(({ validate, ...rest }) => {
          if (rest.id === 'marketplaceID' || rest.id === 'marketplaceAccount') {
            if (displayMarketplaceFields) {
              return (
                <RenderFormItem
                  key={rest.id}
                  title={t(rest.id)}
                  error={translateError(errors[rest.id], t)}
                  options={accountOptions}
                  {...rest}
                />
              );
            }
            return false;
          }

          return (
            <RenderFormItem
              key={rest.id}
              title={t(rest.id)}
              error={translateError(errors[rest.id], t)}
              options={accountOptions}
              {...rest}
            />
          );
        })}
      </div>
    </Ctx.Provider>
  );
};

export default AdyenSettings;
