import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setShowPaymentInput } from 'actions/Payments';
import {
  getShowPaymentInput,
  getIsPaymentDisabled,
  getPayments,
  getBalance,
  getCustomer,
} from 'reducers/Payments';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import { addWarning } from 'actions/Error';

const AddStoreCredit = () => {
  const dispatch = useDispatch();
  const { showPaymentsInput } = useSelector(getShowPaymentInput);
  const { t } = useTranslation('payment');
  const checkPaymentIsDisabled = useSelector(getIsPaymentDisabled);
  const payments = useSelector(getPayments);
  const balance = useSelector(getBalance);
  const { availableCredit } = useSelector(getCustomer);
  const isDefaultCustomer = useSelector(getIsDefaultCustomer);

  let storeCreditDisabled = false;
  if (
    (payments.storeCredit && Number(payments.storeCredit.amount) < 0) ||
    balance > 0
  ) {
    storeCreditDisabled =
      (Object.keys(payments).length === 1 && payments.storeCredit) ||
      balance <= 0;
  } else {
    storeCreditDisabled =
      !(balance < 0 && availableCredit && availableCredit > 0) || balance === 0;
  }
  storeCreditDisabled = storeCreditDisabled || isDefaultCustomer;

  const isStoreCreditDisabled =
    storeCreditDisabled || checkPaymentIsDisabled('STORE_CREDIT');

  return (
    <li className="payment-item cash-payment" style={{ cursor: 'pointer' }}>
      <span
        tabIndex={0}
        className="button"
        role="button"
        style={{
          cursor: isStoreCreditDisabled && 'not-allowed',
          backgroundColor: isStoreCreditDisabled && '#eee',
        }}
        onClick={() => {
          if (isStoreCreditDisabled) {
            dispatch(addWarning(t('alerts.storeCredit')));
            return;
          }
          dispatch(setShowPaymentInput(!showPaymentsInput, 'STORECREDIT'));
        }}
      >
        <span className="title">{t('buttons.storeCredit')}</span>
      </span>
    </li>
  );
};

export default AddStoreCredit;
