import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SaveButton } from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';
import { useAutoFocus } from 'utils/hooks/focus';
import {
  getCurrencyFormatterNoSymbol,
  getDayCountedByDrawer,
  getSetting,
} from 'reducers/configs/settings';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getCustomPaymentTypes } from 'reducers/PaymentTypes';
import { float, positive, toFixed } from 'components/FieldTypes/formatters';
import {
  getAllowedDifference,
  getCheckOnlyCashOnEOD,
  getShowExpected,
} from 'reducers/OpenCloseDay';
import { ReasonCode } from 'types/ReasonCodes';

import { TendersByCurrency } from '../OCDTypes';
import { isWithinAllowedDifference, getDifference } from '../utils';

const usePaymentTypes = (
  expected: { type: string; expected: number }[],
): { name?: string; type: string; expected: number }[] => {
  const others = expected.filter(e => !/^CARD-/.test(e.type));
  const cards = expected.filter(e => /^CARD-/.test(e.type));
  const giftCards = expected.filter(e => /^GIFTCARD-/.test(e.type));
  const possibleCustomPaymentTypes = useSelector(getCustomPaymentTypes);

  return [
    // CASH
    others.find(({ type }) => type === 'CASH') ?? {
      type: 'CASH',
      expected: 0,
    },

    // All cards sorted by type
    ...R.pipe(
      R.map(({ type, expected }) => ({
        name: type.replace(/CARD-/, '') || 'CARD',
        type,
        expected,
      })),
      R.sortBy(R.prop('name')),
    )(cards),

    // Other special types
    {
      type: 'TRANSFER',
      expected: (others.find(o => o.type === 'TRANSFER') || { expected: 0 })
        .expected,
    },

    // Custom gift card based tenders
    ...R.pipe(
      R.map(({ type, expected }) => ({
        name: type.replace('GIFTCARD-', '') || 'GIFTCARD',
        type,
        expected,
      })),
      R.sortBy(R.prop('name')),
    )(giftCards),

    {
      type: 'CHECK',
      expected: (others.find(o => o.type === 'CHECK') || { expected: 0 })
        .expected,
    },

    // All custom payment tenders
    ...R.pipe(
      R.map(pt => ({
        name: pt.name,
        type: pt.type,
        expected: (others.find(o => o.type === pt.type) || { expected: 0 })
          .expected,
      })),
      R.sortBy(R.prop('name')),
    )(possibleCustomPaymentTypes),
  ];
};

const useStyles = makeStyles({
  table: {
    'table-layout': 'fixed',
    '& td, & th': {
      'border-bottom': 'none',
    },
    '& th': {
      'font-weight': 700,
    },
  },
});

interface Props {
  onChange: (type: string, newVal: { counted: string; reason: number }) => void;
  onDone: () => void;
  onCancel: () => void;
  reasons?: ReasonCode[];
  missingReasons?: string[];
  expected?: { [tender: string]: number };
  tenders?: TendersByCurrency[string];
  selectedCurrency: string;
}

const Tenders: React.FC<Props> = ({
  onChange,
  onDone,
  onCancel,
  reasons = [],
  missingReasons = [],
  expected = {},
  tenders = {},
  selectedCurrency,
}) => {
  const classes = useStyles();

  const { t: preT } = useTranslation('openCloseDay');
  const t = (text: string, options?: any) =>
    preT(`closeDay.tenders.${text}`, options);
  const { t: tCommon } = useTranslation('common');

  const drawerCountUsed = useSelector(getDayCountedByDrawer);

  const expectedTenders = Object.entries(expected).map(([type, expected]) => ({
    type,
    expected,
  }));

  const showExpected = useSelector(getShowExpected);
  const allowedDifference = useSelector(getAllowedDifference);
  const checkOnlyCash = useSelector(getCheckOnlyCashOnEOD);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);
  const reasonRequired = useSelector(
    getSetting('touchpos_eod_reason_required'),
  );

  const paymentTypes = usePaymentTypes(expectedTenders).filter(({ type }) =>
    checkOnlyCash ? type === 'CASH' : type,
  );

  const isInvalid = (expected: number, counted: string, reason: number) => {
    if (!showExpected || !reasonRequired) {
      return false;
    }

    if (!isWithinAllowedDifference(expected, counted, allowedDifference)) {
      return reason < 0;
    }
    return false;
  };

  useAutoFocus('firstTender');
  return (
    <div data-testid="tenders">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="8px"
        paddingTop="2px"
        marginX="14px"
      >
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="nowrap"
          paddingTop="1em"
        >
          <Box
            component="span"
            fontSize="1.75em"
            fontWeight={700}
            data-testid="register-drawer-title"
          >
            {drawerCountUsed ? t('drawerTitle') : t('registerTitle')}{' '}
          </Box>
          <Box component="span" data-testid="pos-currency">
            Currency: {selectedCurrency}
          </Box>
        </Box>
        <Box display="flex">
          <SaveButton
            text={tCommon('done')}
            data-testid="submit-btn"
            variant="POS"
            // @ts-ignore (remove when UIButton refactor reaches master-fixes)
            onClick={onDone}
          />
          <CloseButton data-testid="close-btn" action={onCancel} />
        </Box>
      </Box>
      <Box padding="1rem">
        <Table className={classes.table}>
          <TableHead data-testid="headers" className="tenders_thead">
            <TableRow>
              <TableCell data-testid="type-header">
                {t('headers.type')}
              </TableCell>
              <TableCell data-testid="counted-header">
                {t('headers.counted')}
              </TableCell>
              {showExpected ? (
                <>
                  <TableCell data-testid="expected-header">
                    {t('headers.expected')}
                  </TableCell>
                  <TableCell data-testid="diff-header">
                    {t('headers.difference')}
                  </TableCell>
                  <TableCell data-testid="reason-header">
                    {t('headers.reason')}
                  </TableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentTypes
              .map(({ name, type, expected }) => ({
                name,
                type,
                expected,
                counted: '',
                reason: -1,
                ...((tenders[type] as
                  | typeof tenders[string]
                  | undefined
                  | {}) || {}),
              }))
              .map(({ name, type, expected, counted, reason }, i) => (
                <TableRow key={type}>
                  <TableCell data-testid="input-header" data-test-key={type}>
                    {t(`tenders.${type}`, name)}
                  </TableCell>
                  <TableCell>
                    <InputField
                      className={i === 0 ? 'firstTender' : undefined}
                      data-testid="input"
                      data-test-key={type}
                      name={type}
                      isInvalid={isInvalid(expected, counted, reason)}
                      formatter={
                        expected < 0
                          ? // (this comment pushes ts-ignore down a line and makes it work)
                            // @ts-ignore
                            float.and(toFixed(2))
                          : // (this comment pushes ts-ignore down a line and makes it work)
                            // @ts-ignore
                            float.and(positive).and(toFixed(2))
                      }
                      value={counted === '0.00' ? '' : counted}
                      onChange={e =>
                        onChange(type, {
                          counted: e.target.value,
                          reason,
                        })
                      }
                    />
                  </TableCell>
                  {showExpected && (
                    <>
                      <TableCell
                        data-testid="expected"
                        style={{ textAlign: 'right' }}
                        data-test-key={type}
                      >
                        {CURR.stringify(expected)}
                      </TableCell>
                      <TableCell
                        data-testid="diff"
                        data-test-key={type}
                        style={{ textAlign: 'right' }}
                      >
                        {CURR.stringify(getDifference(expected, counted), {
                          skipRounding: true,
                        })}
                      </TableCell>
                      <TableCell>
                        <InputField
                          type="select"
                          value={reason}
                          isInvalid={missingReasons.includes(type)}
                          data-testid="reason-select"
                          data-test-key={type}
                          onChange={e =>
                            onChange(type, {
                              counted,
                              reason: e.target.value,
                            })
                          }
                          options={[
                            { name: '-', reasonID: -1 },
                            ...reasons,
                          ].map(({ name, reasonID }) => ({
                            name,
                            value: reasonID,
                          }))}
                        />
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
};

export default Tenders;
