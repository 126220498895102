import { Tabs as MuiTabs, withStyles } from '@material-ui/core';
import React, {
  ComponentProps,
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

const StyledTabs = withStyles(theme => ({
  root: {
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiTab-root': {
      textTransform: 'uppercase',
      minWidth: 0,
      marginBottom: '-1px',
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',

      '&.Mui-selected': {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider}`,
        borderBottomColor: theme.palette.background.default,
      },
    },
  },
  indicator: {
    display: 'none',
  },
}))(MuiTabs);

const TabContext = createContext<{
  activeTab: number | string;
  setActiveTab: Dispatch<SetStateAction<number | string>> | null;
}>({ activeTab: 0, setActiveTab: null });

interface TabContainerProps {
  defaultTab: number | string;
}

export const TabContainer: FC<TabContainerProps> = ({
  defaultTab,
  children,
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </TabContext.Provider>
  );
};

type TabsProps = ComponentProps<typeof MuiTabs> & {
  onSelect?: (value: number | string) => void;
};

export const Tabs: FC<PropsWithChildren<TabsProps>> = ({
  children,
  onSelect,
  ...rest
}) => {
  const { activeTab, setActiveTab } = useContext(TabContext);

  return (
    <StyledTabs
      value={activeTab}
      onChange={(event, value) => {
        setActiveTab?.(value);
        onSelect?.(value);
      }}
      {...rest}
    >
      {children}
    </StyledTabs>
  );
};

interface TabPanelProps {
  value: number | string;
}

export const TabPanel: FC<TabPanelProps> = ({ value, children }) => {
  const { activeTab } = useContext(TabContext);
  return (
    <div role="tabpanel" hidden={activeTab !== value}>
      {activeTab === value ? children : null}
    </div>
  );
};
