import React from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import ListItem from 'components/ListItem';
import {
  getBalance,
  getPaymentsTotal,
  getHasPaymentsOfType,
} from 'reducers/Payments';
import { PosPlugin } from 'plugins/plugin';
import { addError, addWarning } from 'actions/Error';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { getPluginConfiguration } from 'reducers/Plugins';

import { getHasViiProductInCart } from '../redux/reducers';
import { pluginID } from '../constants';
import { Configuration } from '../types';
import { getViiGiftCardProduct } from '../redux';

const ButtonItem = ({ style = {}, ...props }) => (
  <ListItem
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontFamily: 'Proxima Nova',
      minWidth: 80,
      minHeight: 76,
      height: 'inherit',
      width: 'inherit',
      ...style,
    }}
    {...props}
  />
);

const KeyPad: PosPlugin['UIKeyPad'] = ({ children }) => {
  const dispatch = useDispatch();
  const balance = useSelector(getBalance);
  const viiGiftCardInCart = useSelector(getHasViiProductInCart);
  const config = useSelector(getPluginConfiguration<Configuration>(pluginID));
  const isSaleAReturn = useSelector(getPaymentsTotal) < 0;
  const paidWithOnlinePayment = useSelector(getHasPaymentsOfType('ONLINE'));
  const isOnlineReturn = paidWithOnlinePayment && isSaleAReturn;
  const viiProduct = useSelector(getViiGiftCardProduct);

  const { t } = useTranslation('payment');
  const addViiPayment = () => {
    if (!config) {
      dispatch(addError('Cannot use Vii payment: Plugin not configured'));
    } else if (balance !== 0) {
      dispatch(setPaymentSelected('giftcard-vii'));
    } else {
      dispatch(
        addWarning(t('alerts.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
  };
  return R.pipe(
    R.prepend(
      <ButtonItem
        variant="grid_yellow"
        // Due to customer request in PBIB-5679 is disabled and not showing an alert.
        disabled={viiGiftCardInCart || isOnlineReturn}
        action={addViiPayment}
      >
        {viiProduct?.name ?? 'Vii'}
      </ButtonItem>,
    ),
  )(React.Children.toArray(children));
};

export default KeyPad;
