import * as R from 'ramda';

import { SO } from 'services/DB/types';
import { Product } from 'types/Product';
import { getPluginSelector } from 'reducers/Plugins';

import { pluginID } from '../constants';

type State = {
  products: Record<string, Product>;
};

// actions
const types = {
  SAVE_PRODUCTS: `plugin ${pluginID}: SAVE_PRODUCTS`,
};

export const saveProducts = (products: Record<string, Product>) => ({
  type: types.SAVE_PRODUCTS,
  payload: products,
});

// reducer
const initState: State = {
  products: {},
};

export const reducer = (
  state: State = initState,
  { type, payload }: { type: string; [any: string]: unknown },
): State => {
  switch (type) {
    case types.SAVE_PRODUCTS:
      return { ...state, products: R.mergeDeepLeft(payload, state.products) };
    default:
      return state;
  }
};

// selectors
export const getProductByID = (id: string) =>
  getPluginSelector<State>(pluginID)((state: State) => state.products[id]);
