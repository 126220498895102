import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab } from '@material-ui/core';

import { ReturnRow } from 'types/ReturnTable';
import PreviousPurchases from 'containers/Forms/Customers/views/CustomerView/sales/PreviousPurchases';
import DateRangeInput, {
  DateRange,
} from 'containers/Forms/RecentSalesList/DateRangeInput';
import { getIsModuleEnabled, getSetting } from 'reducers/configs/settings';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { addWarning } from 'actions/Error';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { TabContainer, TabPanel, Tabs } from 'components/Tabs';

import { formatDate, getSalesDocumentsWithReturnedRows } from './util';

export { default } from 'containers/Forms/RecentSalesList/DateRangeInput';
export interface Sales {
  docs: SaleDocumentResponse[];
  returnedRows: ReturnRow[];
}

const emptySales: Sales = {
  docs: [],
  returnedRows: [],
};

const salesPerPage = 20;

export const RecentSalesList = ({ queryProps: props }) => {
  const [queryProps, setQueryProps] = useState(props);

  useEffect(() => {
    if (
      Object.entries(queryProps).some(([k, v]) => props[k] !== v) ||
      Object.entries(props).some(([k, v]) => queryProps[k] !== v)
    ) {
      setQueryProps(props);
    }
  }, [queryProps, props]);

  const { t } = useTranslation('customer');
  // Inputs
  const [dateRange, setDateRange] = useState<DateRange>({
    from: null,
    to: null,
  });

  const showName = queryProps?.showCustomer;
  const taxComponentsEnabled = useSelector(getIsModuleEnabled('subvatrates'));

  // Fetching state
  const [fetching, setFetching] = useState(false);
  const [done, setDone] = useState({
    invoices: false,
    invwaybills: false,
  });

  // Output
  const [sales, setSales] = useState<Sales>(emptySales);
  const [invWayBills, setInvWayBills] = useState<Sales>(emptySales);

  const currentLocationOnly = useSelector(
    getSetting('touchpos_recent_sales_only_from_current_location'),
  );
  const warehouseID = useSelector(getSelectedWarehouseID);
  const dispatch = useDispatch();

  const fetchSales = (first: boolean, type = 'invoices') => {
    if (currentLocationOnly && !warehouseID) {
      console.error(
        'There was an error with fetching the current warehouse. This can happen if menu is accessed too quickly. Please wait and retry',
      );
      dispatch(previousModalPage());
      dispatch(addWarning('Error fetching current warehouse. Please retry'));
      return;
    }
    setFetching(true);

    getSalesDocumentsWithReturnedRows({
      recordsOnPage: salesPerPage,
      getReturnedPayments: 1,
      presentCoupons: 1,
      types:
        type === 'invwaybills'
          ? 'INVWAYBILL'
          : 'CASHINVOICE,CREDITINVOICE,INVOICE',
      confirmed: 1,
      orderBy: type === 'invwaybills' ? 'lastChanged' : undefined,
      warehouseID: currentLocationOnly ? warehouseID : undefined,
      getRowsForAllInvoices: 1,
      dateFrom: formatDate(dateRange.from),
      dateTo: formatDate(dateRange.to),
      clientID: queryProps?.clientID,
      getTaxComponents: taxComponentsEnabled ? 1 : undefined, // most accounts don't have taxComponent module enabled, in this case we set the param to undefined
      pageNo: first
        ? 1
        : Math.floor(
            (type === 'invwaybills' ? invWayBills : sales).docs.length /
              salesPerPage,
          ) + 1, // 1-indexed
      ...queryProps,
    })
      .then(data => {
        const setData = s => ({
          docs: [...s.docs, ...data.docs],
          returnedRows: [...s.returnedRows, ...data.returnedRows],
        });
        if (type === 'invoices') {
          setSales(setData);
        }
        if (type === 'invwaybills') {
          setInvWayBills(setData);
        }
        if (data.docs.length !== salesPerPage) {
          setDone(prevState => ({
            ...prevState,
            [type]: true,
          }));
        }
      })
      .finally(() => setFetching(false));
  };

  const resetAndFetchFirst = (type = 'invoices') => {
    if (type === 'invwaybills') {
      setInvWayBills(emptySales);
    }
    if (type === 'invoices') {
      setSales(emptySales);
    }
    setDone(prevState => ({
      ...prevState,
      [type]: false,
    }));
    fetchSales(true, type);
  };

  // Whenever query props are updated, start from scratch
  useEffect(() => {
    resetAndFetchFirst();
  }, [queryProps]);
  const options = ['invoices', 'invwaybills'];

  const handleTabSelection = type => {
    if (type === 'invoices' && !sales.docs.length) {
      resetAndFetchFirst(type);
    } else if (type === 'invwaybills' && !invWayBills.docs.length) {
      resetAndFetchFirst(type);
    }
  };

  const onMore = (opt: string) => {
    if (
      queryProps?.limit !==
      (opt === 'invwaybills' ? invWayBills : sales).docs.length
    ) {
      fetchSales(false, opt);
    }
  };

  return (
    <TabContainer defaultTab="invoices">
      <Tabs id="recentSalesTabs" onSelect={handleTabSelection}>
        {options.map(opt => (
          <Tab
            key={opt}
            value={opt}
            label={t(`fields.tabs.${opt}`)}
            data-testid={`tab-${opt}`}
          />
        ))}
      </Tabs>
      {options.map(opt => (
        <TabPanel key={opt} value={opt}>
          <Box
            display={!queryProps.showFilter ? 'none' : 'block'}
            marginTop="1rem"
          >
            <DateRangeInput
              titleFrom="from"
              titleTo="to"
              value={dateRange}
              onChange={setDateRange}
              dataTestKey="date-picker"
            />
            <Button
              data-testid="filter-purchases"
              onClick={() => resetAndFetchFirst(opt)}
            >
              {t('customerView.buttons.filter')}
            </Button>
          </Box>
          <PreviousPurchases
            title={t('customerView.purchases.title')}
            sales={opt === 'invwaybills' ? invWayBills : sales}
            name={showName ?? false}
            loading={fetching ? t('customerView.loading') : ''}
            done={!queryProps.morePages || done[opt]}
            onMore={() => onMore(opt)}
            showFilter={queryProps.showFilter}
          />
        </TabPanel>
      ))}
    </TabContainer>
  );
};
