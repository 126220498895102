import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './BottomPopup.css';

import { modalPages as mp } from 'constants/modalPage';
import { hardLogout } from 'actions/Login';
import { startNewSale, saveSale } from 'actions/sales';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getLastSalesDocument } from 'reducers/sales';
import { isEmpty } from 'utils';
import { addWarning } from 'actions/Error';
import * as PrinterIntegration from 'actions/integrations/printer';
import { getHasRightToOpenAndCloseDay } from 'reducers/Login';

const MainView = ({ setOpen, showPage }) => {
  const dispatch = useDispatch();
  const hasRightsToCloseDay = useSelector(getHasRightToOpenAndCloseDay);
  const thenClose = func => async () => {
    await dispatch(func());
    setOpen(false);
  };
  const { t } = useTranslation('gridButtons');
  const { salesDoc: lastInvoice, payments: receiptPayments } = useSelector(
    getLastSalesDocument,
  );
  const reprintLastReceipt = () => {
    if (!lastInvoice.receiptLink && isEmpty(receiptPayments)) {
      return dispatch(
        addWarning(t('alerts.noLastSale'), { selfDismiss: true }),
      );
    }
    return dispatch(
      PrinterIntegration.printReceipt({
        payments: receiptPayments,
        ...lastInvoice,
      }),
    );
  };

  return (
    <>
      <span className="mobile-menu-action" onClick={thenClose(startNewSale)}>
        {t('saleOptions.newSale')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={thenClose(saveSale)}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.saveSale')}
      </span>
      <span
        className="mobile-menu-action btn-save"
        onClick={reprintLastReceipt}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.lastReceipt')}
      </span>
      {hasRightsToCloseDay && (
        <span
          className="mobile-menu-action btn-save"
          onClick={() => {
            dispatch(
              openModalPage({
                component: mp.closeDay,
                isPopup: true,
                modalClassName: 'openCloseDay',
              }),
            );
            setOpen(false);
          }}
          style={{ marginTop: '14px' }}
        >
          {t('functions.closeDay')}
        </span>
      )}
      <span
        className="mobile-menu-action btn-group"
        onClick={() => showPage('saveAs')}
        style={{ marginTop: '28px' }}
      >
        {t('groups.saveAs')}
      </span>
      <span
        className="mobile-menu-action btn-group"
        onClick={() => showPage('openSale')}
        style={{ marginTop: '14px' }}
      >
        {t('groups.openSale')}
      </span>
      <span
        className="mobile-menu-action btn-group"
        onClick={() => showPage('quickPrint')}
        style={{ marginTop: '14px' }}
      >
        {t('groups.quickPrint')}
      </span>
      <span
        className="mobile-menu-action btn-group"
        onClick={() => showPage('currentSale')}
        style={{ marginTop: '14px' }}
      >
        {t('groups.currentSale')}
      </span>
      <span
        className="mobile-menu-action btn-group"
        onClick={() => showPage('info')}
        style={{ marginTop: '14px' }}
      >
        {t('groups.info')}
      </span>
      <span
        className="mobile-menu-action btn-group"
        onClick={() => showPage('misc')}
        style={{ marginTop: '14px' }}
      >
        {t('groups.misc')}
      </span>
      <span
        className="mobile-menu-action btn-save"
        onClick={() => dispatch(hardLogout())}
        style={{ marginTop: '28px' }}
      >
        {t('functions.logout')}
      </span>
      <span
        className="btn-cancel mobile-menu-action"
        onClick={() => setOpen(false)}
        style={{ marginTop: '28px' }}
      >
        {t('common:cancel')}
      </span>
    </>
  );
};

export default MainView;
