import { useSelector } from 'react-redux';

import { Customer, FormCustomer } from 'types/Customer';
import {
  getCustomerGroupByID,
  getCustomerRewardPointsSelector,
  getPersonTitles,
} from 'reducers/customerSearch';
import { getHomeStoreById } from 'reducers/warehouses';
import { RootState } from 'reducers';
import { HomeStore } from 'types/Warehouse';
import {
  getCurrencyFormatter,
  getDateFormatter,
} from 'reducers/configs/settings';

export function useCustomerFieldMapping(customer: Customer | FormCustomer) {
  const homeStore = useSelector<RootState, HomeStore | undefined>(
    getHomeStoreById(customer?.homeStoreID || customer?.homeStore),
  );
  const personTitles = useSelector(getPersonTitles);
  const currencyFormat = useSelector(getCurrencyFormatter);
  const rewardPoints = useSelector(getCustomerRewardPointsSelector);
  const formatDate = useSelector(getDateFormatter);
  const customerGroup = useSelector(getCustomerGroupByID(customer.groupID));

  function customerBirthdayVisualizer(date?: string) {
    if (!date || date === '0000-00-00') return '';
    // Unspecified year is stored as `9999` and should be displayed as `----`
    return formatDate(date)?.replace(/9999/, '----');
  }

  function getData(fieldName: string) {
    switch (fieldName) {
      case 'addresses':
        return customer?.[fieldName]?.[0]?.address;
      case 'address':
      case 'address2':
      case 'city':
      case 'state':
      case 'country':
      case 'postalCode':
      case 'street':
        return customer?.addresses?.[0]?.[fieldName];
      case 'birthday':
        return customerBirthdayVisualizer(customer[fieldName]);
      case 'homeStoreID':
        return homeStore?.name ?? '';
      case 'groupID':
        return customer.groupName || customerGroup.name;
      case 'emailOptOut':
      case 'doNotSell':
      case 'suspended':
      case 'salesBlocked':
        return Number(customer[fieldName] ?? 0) === 1 ? '✓' : 'x';
      case 'personTitleID':
        return (
          personTitles.find(title => title.id === customer[fieldName])?.name ??
          ''
        );
      case 'availableCredit':
        return currencyFormat(customer[fieldName]);
      case 'rewardPoints':
        return rewardPoints;
      default:
        return customer[fieldName] ?? '';
    }
  }

  return getData;
}
