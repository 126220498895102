import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsAReturn, getReturnTotal } from 'reducers/sales';
import { getProductsInShoppingCart, getTotal } from 'reducers/ShoppingCart';
import { removeProduct, updateOrderAmount } from 'actions/ShoppingCart';
import TableTotal from 'components/tableTotal/TableTotal';
import { DebugDisplayVariables } from 'components/Dev/Debug';

import { EnvFeeState, SHOULD_ADD_ADMISSION_TAX } from '../types';
import { setAdmissionTaxInShoppingCartcounter } from '../index';
import { defineTaxRowsToDisplay } from '../utils';
import {
  addEnvFeeProductToCart,
  calculateNetTotal,
  getAdmissionTaxFromCart,
  getEnvProductFromShoppingCart,
  getEnvState,
  getExchangeState,
  removeDuplicatedAdmissionProductFromCart,
} from '../rdx';

const UITableTotalRow = () => {
  const dispatch = useDispatch();

  const [counter, setCounter] = useState(0);

  const envFeeState: EnvFeeState = useSelector(getEnvState);
  const {
    shouldAddEnvFee,
    envFeeAmount,
    returnAllBoughtItems,
    priceHasBeenUpdated,
    shouldAddAdmissionTax,
    admissionFeeTaxAmount,
  } = envFeeState;
  const isReturn = useSelector(getIsAReturn);
  const returnTotal = useSelector(getReturnTotal);
  const sum = useSelector(getTotal);
  const productsInCart = useSelector(getProductsInShoppingCart);
  const exchangeState = useSelector(getExchangeState);
  const net = useSelector(calculateNetTotal);
  const environmentalFeeProductInCart = useSelector(
    getEnvProductFromShoppingCart,
  );
  const admissionTaxInCart: object | any = useSelector(getAdmissionTaxFromCart);

  useEffect(() => {
    dispatch(addEnvFeeProductToCart());

    // Logic related to unreferenced refunds and admission tax
    if (sum < 0 && admissionTaxInCart) {
      dispatch({
        type: SHOULD_ADD_ADMISSION_TAX,
        payload: false,
      });
      dispatch(removeProduct(admissionTaxInCart.orderIndex));
      setAdmissionTaxInShoppingCartcounter(0);
    }

    if (
      sum < 0 &&
      environmentalFeeProductInCart &&
      counter === 0 &&
      exchangeState.exchangeStep === 0
    ) {
      dispatch(updateOrderAmount(-1, environmentalFeeProductInCart.orderIndex));

      setCounter(1);
    }

    if (counter === 1) {
      setCounter(0);
    }
  }, [sum]);

  useEffect(() => {
    dispatch(removeDuplicatedAdmissionProductFromCart());
  }, [productsInCart]);

  const defineTotalAmount = () => {
    if (isReturn && returnTotal !== 0 && priceHasBeenUpdated) {
      const total = productsInCart.reduce((accumulator, currentValue) => {
        return accumulator + Math.abs(currentValue.rowTotal);
      }, 0);
      return -total;
    }

    if (isReturn && returnTotal !== 0 && !returnAllBoughtItems) {
      return returnTotal;
    }

    if (isReturn && returnTotal !== 0) {
      return returnTotal;
    }

    return sum;
  };

  const extraTaxRows = useMemo(
    () =>
      defineTaxRowsToDisplay({
        shouldAddEnvFee,
        shouldAddAdmissionTax,
        envFeeAmount,
        admissionFeeTaxAmount,
      }),
    [
      shouldAddEnvFee,
      shouldAddAdmissionTax,
      envFeeAmount,
      admissionFeeTaxAmount,
    ],
  );

  const customTotalAmount = defineTotalAmount();

  return (
    <>
      <TableTotal
        customTotalAmount={customTotalAmount}
        extraTaxRows={extraTaxRows}
        customNetAmount={net}
      />
      <DebugDisplayVariables
        {...{
          defineNetAmount: net,
          defineTotalAmount: defineTotalAmount(),
        }}
      />
    </>
  );
};

export default UITableTotalRow;
