import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { useBreakpoints } from 'utils/hooks/UI';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';

import ExtraColumn from './components/ExtraColumn';
import ExtraHeader from './components/ExtraHeader';

const netRowTotalColumn: PosPlugin = {
  id: 'show-net-row-total',
  name: 'Show row total',
  getStatus: () => ({ type: 'valid', message: '' }),
  info:
    'When enabled, will add an extra column that shows row total in shopping cart.',
  keywords: ['extra-column', 'shoppingcart'],
  combineConfiguration: (...args) => {
    return args[0];
  },
  UICustomTableBillHeader: ({ children }) => {
    const isMobileView = !useBreakpoints().mdplus;
    const gridDisplayOpened = useSelector(getGridIsOpened);
    const position = gridDisplayOpened ? 4 : 7;

    if (!children) return null;
    if (isMobileView) return children;

    return R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(
          React.Children.toArray,
          R.insert(
            position,
            <ExtraHeader key="ext-price" header="Ext. Price" />,
          ),
        ),
      ),
    )(React.Children.toArray(children));
  },
  UICustomTableBillRow: ({ children, order }) => {
    const isMobileView = !useBreakpoints().mdplus;
    const gridDisplayOpened = useSelector(getGridIsOpened);
    const position = gridDisplayOpened ? 4 : 7;

    if (!children) return null;
    if (
      isMobileView ||
      (!children?.[0]?.props?.children?.length &&
        !(children as any)?.props?.children?.length)
    )
      return children;

    return R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(
          React.Children.toArray,
          R.insert(
            position,
            <ExtraColumn
              key={order.orderIndex}
              orderIndex={order.orderIndex}
              rowNetTotal={order.rowNetTotal}
              rowTotal={order.rowTotal}
            />,
          ),
        ),
      ),
    )(React.Children.toArray(children));
  },
};

export default netRowTotalColumn;
