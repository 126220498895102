/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, Row } from 'react-bootstrap';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import Loader from 'components/Loader';
import './index.css';
import { toggleManualEditMode } from 'actions/UI';
import { getIsManualEditMode } from 'reducers/UI/manualEditMode';
import { saveProduct } from 'actions/productsDB';
import { getClientCode } from 'reducers/Login';
import { clientCode } from 'constants/manuals';

import fetchProducts from './fetchProducts';
import products from './products';
import MainView from './MainView';

const Manuals = ({ code }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isManualEditMode = useSelector(getIsManualEditMode);
  const dispatch = useDispatch();

  const loggedUserClientcode = useSelector(getClientCode);

  // eslint-disable-next-line radix
  const hasRights = clientCode === parseInt(loggedUserClientcode);

  const fetchData = hasRights ? fetchProducts() : products;

  useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line import/prefer-default-export
    fetchData
      .then(prods => {
        const product =
          code && prods.find(prod => prod.code === code.toString());
        if (product === undefined) {
          dispatch(toggleManualEditMode());
        }
        setData(product || {});
        setIsLoading(false);
      })
      .catch(() => {
        setData({});
        setIsLoading(false);
      });
  }, []);

  const { name, longdesc, productID } = data;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const save = content => {
    if (content.trim() === longdesc) {
      dispatch(toggleManualEditMode());
      return false;
    }

    if (productID) {
      dispatch(
        saveProduct({
          productID,
          longdesc: content,
        }),
      );
      setData({ ...data, name, longdesc: content });
    }
    dispatch(toggleManualEditMode());
  };

  const description = longdesc || '<h4>No content</h4>';

  return (
    <div className="manualModal">
      <Loader show={isLoading} kind="bars" block>
        <Modal.Header>
          <Modal.Title>{name}</Modal.Title>
          <div className="mr-auto ml-2">
            {!isManualEditMode &&
              !isLoading &&
              hasRights &&
              Object.keys(data).length > 0 && (
                <Button
                  onClick={() => dispatch(toggleManualEditMode())}
                  variant="warning"
                >
                  Edit
                </Button>
              )}
          </div>
          <CloseButton
            action={() => {
              // eslint-disable-next-line no-unused-expressions
              isManualEditMode && dispatch(toggleManualEditMode());
              dispatch(previousModalPage());
            }}
          />
        </Modal.Header>
        <Modal.Body className="manualModalBody">
          {!isLoading && isManualEditMode && hasRights ? (
            <MainView save={save} content={longdesc} />
          ) : (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: !isLoading ? description : '',
              }}
            />
          )}
        </Modal.Body>
      </Loader>
    </div>
  );
};

export default Manuals;
