export const SET_SALES_DOCS = 'RETURNS: SET_SALES_DOCS';
export const SET_RETURN_DOCUMENT = 'RETURNS: SET_RETURN_DOCUMENT';
export const RESET_RETURN_DOCUMENT = 'RETURNS: RESET_RETURN_DOCUMENT';
export const SET_SALES_DOC_NUMBER = 'RETURNS: SET_SALES_DOC_NUMBER';
export const SET_DOC_SELECTED = 'RETURNS: SET_DOC_SELECTED';
export const SET_ORDERS_SELECTED = 'RETURNS: SET_ORDERS_SELECTED';
export const SET_ONE_ORDER_SELECTED = 'RETURNS: SET_ONE_ORDER_SELECTED';
export const DELETE_ONE_ORDER_SELECTED = 'RETURNS: DELETE_ONE_ORDER_SELECTED';
export const SET_STATE = 'RETURNS: SET_STATE';
export const RESET_SALES_DOCS = 'RETURNS: RESET_SALES_DOCS';
export const RESET_DOC_SELECTED = 'RETURNS: RESET_DOC_SELECTED';
export const RESET_TO_DEFAULT = 'RETURNS: RESET_TO_DEFAULT';
export const SET_RETURNED_ROWS = 'RETURNS: SET_RETURNED_ROWS';
export const SET_LOADING = 'RETURNS: SET_LOADING';
export const SET_IS_GIFT_RETURN = 'RETURNS: SET_IS_GIFT_RETURN';
