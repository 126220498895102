import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Table, Tabs } from 'react-bootstrap';
import { useAsync } from 'react-use';

import UIButton from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import InputField from 'components/FieldTypes/InputField';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { addError, addSuccess, addWarning, dismissType } from 'actions/Error';
import {
  getAllWarehouses,
  getSelectedWarehouseID,
  getWarehouseById,
} from 'reducers/warehouses';
import { startNewSale } from 'actions/sales';
import { getEmployeeID } from 'reducers/Payments';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import Loader from 'components/Loader';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { syncLocalDatabaseSO } from 'actions/connectivity';
import { SO } from 'services/DB/types';
import useProducts from 'utils/hooks/useProducts';

export default () => {
  const { t } = useTranslation('stockTransfer');
  const dispatch = useDispatch();
  const close = () => {
    dispatch(previousModalPage());
  };
  return (
    <div data-testid="stock-transfer-modal">
      <Modal.Header>
        <h3 style={{ fontWeight: 'bold' }}>{t('title')}</h3>
        <span style={{ flexGrow: 1 }} />
        <CloseButton action={close} />
      </Modal.Header>
      <Modal.Body>
        <Tabs id="direction">
          <Tab eventKey="outbound" title={t('tabs.outbound')}>
            <Outbound />
          </Tab>
          <Tab eventKey="inbound" title={t('tabs.inbound')}>
            <Inbound />
          </Tab>
        </Tabs>
      </Modal.Body>
    </div>
  );
};

const TransferComponent = ({ transfer, onAccept }) => {
  const { t } = useTranslation('stockTransfer');
  const { rows } = transfer;
  const productIDs = useMemo(() => rows?.map(row => Number(row.productID)), [
    rows,
  ]);
  const { products } = useProducts(
    useMemo(() => ({ productIDs }), [productIDs]),
  );
  const warehouseName = useSelector(getWarehouseById(transfer.warehouseFromID))
    ?.name;
  // prettier-ignore
  return (
    transfer && (
      <tr data-testid="transfer"
        data-test-key={transfer.inventoryTransferID} key={transfer.inventoryTransferID}>
        <td>{transfer.inventoryTransferNo}</td>
        <td>{transfer.date}</td>
        <td>{warehouseName}</td>
        <td>
          {rows?.map(row => (
            <div>
              {row.amount}x {products.find(prod => Number(prod.productID) === Number(row.productID))?.name}
            </div>
          ))}
        </td>
        <td>
          <UIButton data-testid="accept-btn" text={t("inbound.buttons.accept")} action={onAccept} />
        </td>
      </tr>
    )
  );
};

const Outbound = () => {
  const employeeID = useSelector(getEmployeeID);
  const currentWarehouseID = useSelector(getSelectedWarehouseID);
  const otherWarehouses = useSelector(getAllWarehouses).filter(
    wh => wh.warehouseID !== currentWarehouseID,
  );
  const [text, setText] = useState('');
  const shoppingCart = useSelector(getProductsInShoppingCart);
  const [cart, setCart] = useState<any[]>(shoppingCart);
  const [destinationID, setDestinationID] = useState<number>(
    otherWarehouses[0]?.warehouseID,
  );
  const useProductsProps = useMemo(
    () => ({
      productIDs: shoppingCart.map(({ productID }) => productID),
      getLocalStockInfo: 1 as const,
    }),
    [shoppingCart],
  );

  const { products, loading } = useProducts(useProductsProps);
  const { t } = useTranslation('stockTransfer');
  const dispatch = useDispatch();
  useShortcut('Enter', () => {
    if (products.length === 1) {
      setText('');
      setCart(c => c.concat({ ...products[0], amount: 1 }));
      dispatch(addSuccess(t('outbound.alerts.scanned')));
    } else if (products.length > 1) {
      dispatch(addError(t('outbound.alerts.scanned', { context: 'multiple' })));
    } else {
      dispatch(addError(t('outbound.alerts.scanned', { context: 'none' })));
    }
  });

  const productsWithNegativeStock = useMemo(
    () =>
      cart
        .map(item => {
          const product = products.find(
            ({ productID }) => Number(productID) === Number(item.productID),
          );
          const stock = (product?.free ?? 0) + (product?.reserved ?? 0);
          return { ...item, stock };
        })
        .filter(item => item.stock <= 0),
    [cart, products],
  );

  const send = () => {
    dispatch(addWarning(t('outbound.alerts.sending')));
    if (productsWithNegativeStock.length) {
      return dispatch(
        addError(
          t('outbound.alerts.no_stock', {
            code: productsWithNegativeStock[0].code,
            amount: productsWithNegativeStock[0].amount,
            stock: productsWithNegativeStock[0].stock,
          }),
        ),
      );
    }
    dispatch(previousModalPage());
    doClientRequest({
      request: 'saveInventoryTransfer',
      creatorID: employeeID,
      type: 'TRANSFER_ORDER',
      warehouseFromID: currentWarehouseID,
      confirmed: 1,
      warehouseToID: destinationID,
      ...Object.fromEntries(
        cart
          .filter(i => !i.removed)
          .flatMap((c, i) => [
            [`productID${i + 1}`, c.productID],
            [`amount${i + 1}`, c.amount],
          ]),
      ),
    }).then(() => {
      dispatch(dismissType('transferorder'));
      dispatch(addSuccess(t('outbound.alerts.sending', { context: 'done' })));
      dispatch(previousModalPage());
      dispatch(syncLocalDatabaseSO(SO.PRODUCT_STOCK.NAME));
      dispatch(startNewSale());
    });
  };
  return (
    <Loader show={loading} block>
      <div data-testid="send-stock-tab">
        <UIButton
          text={t('outbound.copyFromCart')}
          data-testid="copy-from-cart-btn"
          action={e => setCart(shoppingCart)}
        />
        <Table>
          <tr>
            <th>{t('outbound.headers.name')}</th>
            <th>{t('outbound.headers.code')}</th>
            <th>{t('outbound.headers.stock')}</th>
            <th>{t('outbound.headers.amount')}</th>
          </tr>
          {cart.map((c, i) => {
            const product = products.find(
              ({ productID }) => Number(productID) === Number(c.productID),
            );
            const stock = (product?.free ?? 0) + (product?.reserved ?? 0);

            return (
              <tr key={i}>
                <td>{c.name}</td>
                <td>{c.code}</td>
                <td>{stock}</td>
                <td>
                  <InputField
                    type="number"
                    value={c.amount}
                    min={0}
                    max={stock}
                    data-testid="amount-input"
                    onBlur={
                      Number(c.amount) === 0
                        ? () => {
                            setCart(
                              cart.filter(
                                item => item.orderIndex !== c.orderIndex,
                              ),
                            );
                          }
                        : null
                    }
                    onChange={e =>
                      setCart(
                        cart
                          .slice(0, i)
                          .concat({ ...c, amount: e.target.value })
                          .concat(cart.slice(i + 1)),
                      )
                    }
                  />
                </td>
              </tr>
            );
          })}
        </Table>
        <InputField
          type="select"
          value={destinationID}
          data-testid="destionation-select"
          onChange={e => setDestinationID(e.target.value)}
          options={otherWarehouses.map(({ name, warehouseID }) => ({
            name,
            value: warehouseID,
          }))}
        />
        <UIButton
          text={t('outbound.buttons.send')}
          variant="POS"
          action={send}
          data-testid="send-btn"
          disabled={!cart.length}
        />
      </div>
    </Loader>
  );
};

const Inbound = () => {
  const { t } = useTranslation('stockTransfer');
  const warehouseID = useSelector(getSelectedWarehouseID);
  const warehouses = useSelector(getAllWarehouses);
  const warehouseIDs = new Set(warehouses.map(wh => wh.warehouseID));
  const dispatch = useDispatch();

  const { value: transfers = [], error } = useAsync(() => {
    return doClientRequest<Transfer>({
      confirmed: 1,
      fulfilled: 0,
      type: 'TRANSFER_ORDER',
      warehouseToID: warehouseID,
      request: 'getInventoryTransfers',
    });
  }, [warehouseID]);

  const transfersToExisting = useMemo(
    () =>
      transfers?.filter(transfer =>
        warehouseIDs.has(String(transfer.warehouseToID)),
      ),
    [transfers],
  );

  const accept = useCallback((transfer: Transfer) => {
    dispatch(
      addWarning(
        t('inbound.alerts.accepting', {
          dismissible: false,
          selfDismiss: false,
          errorType: 'stockTransfer2',
        }),
      ),
    );
    doClientRequest({
      request: 'saveInventoryTransfer',
      inventoryTransferOrderID: transfer.inventoryTransferID,
      warehouseFromID: transfer.warehouseFromID,
      warehouseToID: transfer.warehouseToID,
      ...Object.fromEntries(
        transfer.rows.flatMap((r, i) =>
          Object.entries(r).map(([k, v]) => [`${k}${i + 1}`, v]),
        ),
      ),
      confirmed: 1,
      type: 'TRANSFER',
    })
      .finally(() => {
        dispatch(dismissType('stockTransfer2'));
        dispatch(syncLocalDatabaseSO(SO.PRODUCTS.NAME));
        dispatch(syncLocalDatabaseSO(SO.PRODUCT_STOCK.NAME));
      })
      .then(() => {
        const idx = transfersToExisting.findIndex(
          t => t.inventoryTransferID === transfer.inventoryTransferID,
        );
        transfersToExisting.splice(idx, 1);
        dispatch(
          addSuccess(t('inbound.alerts.accepting', { context: 'done' })),
        );
      })
      .catch(() => {
        dispatch(
          addSuccess(t('inbound.alerts.accepting', { context: 'fail' })),
        );
      });
  }, []);

  if (error) {
    return <span style={{ color: 'red' }}>{error.message}</span>;
  }
  return (
    <Loader
      block
      show={transfers === undefined}
      loadingText={t('inbound.loading')}
    >
      <Table>
        <tr>
          <th>{t('inbound.headers.number')}</th>
          <th>{t('inbound.headers.date')}</th>
          <th>{t('inbound.headers.from')}</th>
          <th>{t('inbound.headers.items')}</th>
          <th>{t('inbound.headers.accept')}</th>
        </tr>
        {transfersToExisting?.map(transfer => (
          <TransferComponent
            key={transfer.inventoryTransferID}
            transfer={transfer}
            onAccept={() => accept(transfer)}
          />
        ))}
      </Table>
    </Loader>
  );
};

type Transfer = {
  inventoryTransferID: string;
  inventoryTransferNo: string;
  date: string;
  warehouseFromID: string;
  warehouseToID: string;
  type: 'TRANSFER';
  rows: {
    productID: string;
    amount: string;
  }[];
};
