import React, { ReactChild, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import dayjs from 'dayjs';
import { LinearProgress, Button, Box } from '@material-ui/core';
import { AxiosError } from 'axios';

import { openPluginModalPage } from 'actions/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { PosPlugin } from 'plugins/plugin';
import {
  getCustomerGroupByID,
  getSelectedCustomerID,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getWarehouseById } from 'reducers/warehouses';
import { getCurrencyFormatter } from 'reducers/configs/settings';
import Loader from 'components/Loader';
import { addError, addWarning, dismissType } from 'actions/Error';
import { Expiration } from 'plugins/wbu/react';
import {
  useCustomerExtraData,
  useCustomerRewardPoints,
} from 'plugins/wbu/salesHistory/hooks';
import { updateCustomer } from 'plugins/wbu/redux';
import { modalPages } from 'constants/modalPage';
import { RootState } from 'reducers';

import WBUAPI from '../API/API';

import { PreviousSales, SalesList } from './components';
import styles from './index.module.scss';
import RenewalTooltip from './components/RenewalTooltip';
import { AddCouponsQuickAction } from 'containers/Forms/Customers/views/CustomerView/CustomerQuickActions';

const Left: React.FC<{ title: ReactChild }> = ({ children, title }) => (
  <div style={{ gridColumn: 1, wordBreak: 'break-word' }} className="p-2">
    <span style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
      {title}:{' '}
    </span>
    {children}
  </div>
);
const Right: React.FC<{ title: ReactChild }> = ({ children, title }) => (
  <div style={{ gridColumn: 2 }} className="p-2">
    <span style={{ fontWeight: 'bold' }}>{title}: </span>
    {children}
  </div>
);

const DataTable = () => {
  const customer = useSelector(getSelectedCustomer);
  const { value: extraData } = useCustomerExtraData(customer.customerID);
  const formatCurrency = useSelector(getCurrencyFormatter);
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const currentDate = dayjs(extraData?.dscExpiration ?? new Date()).format(
    'YYYY-MM-DD',
  );

  const [date, setDate] = useState<string>(currentDate);

  // When changing/updating customer, reset the date input field to the current date value
  useEffect(() => setDate(currentDate), [currentDate]);

  const expiration = extraData?.dscExpiration
    ? dayjs(extraData?.dscExpiration)
    : undefined;
  const expired = dayjs().isAfter(expiration ?? dayjs('1970-01-01T00:00:00'));

  const isSelectedCustomer =
    String(useSelector(getSelectedCustomerID)) === String(customer.customerID);
  const renewMembership = useCallback(async () => {
    if (expired) {
      dispatch(
        addWarning(
          'Cant renew customer membership, because he is not a member',
        ),
      );
      return;
    }
    try {
      await dispatch(WBUAPI.renewMembership(customer.id, date));
    } catch (error) {
      const message =
        (error as AxiosError).response?.data.message ||
        'Failed to renew customer membership';
      dispatch(
        addError(message, {
          selfDismiss: 5000,
          errorType: 'membership-renewal',
        }),
      );
      return;
    }
    dispatch(dismissType('membership-renewal'));
    if (isSelectedCustomer) await dispatch(updateCustomer(customer.id));
    // Wish we had react-query so i could just trigger a 'refetch' of the extra data
    dispatch(
      openModalPage({
        component: modalPages.customerView,
        replace: true,
        props: { customer },
      }),
    );
  }, [expired, dispatch, isSelectedCustomer, customer, date]);

  const customerRewardPoints = useCustomerRewardPoints(customer.customerID);
  const homeStore = useSelector(getWarehouseById(customer.homeStoreID));
  const group = useSelector(getCustomerGroupByID(customer.groupID));

  const lastVisit = extraData?.last_visit
    ? dayjs(extraData?.last_visit)
    : undefined;

  const ifExtraData = (fn: (ed: NonNullable<typeof extraData>) => any) => {
    if (!extraData) return <LinearProgress />;
    return fn(extraData);
  };
  return (
    <Loader block loadingText="Loading.." show={!extraData}>
      <div style={{ display: 'grid', gridAutoFlow: 'column' }}>
        <Left title="Card number">{customer.customerCardNumber}</Left>
        <Left title="Group">{group?.name}</Left>
        <Left title="Birth note">{customer.householdCode}</Left>
        <Left title="E-mail">{customer.email}</Left>
        <Left title="Phone">{customer.phone}</Left>
        <Left title="Mobile">{customer.mobile}</Left>
        <Left title="Address">{customer.addresses?.[0]?.address}</Left>
        <Right title="Reward points">
          {customerRewardPoints?.value === undefined ? (
            <LinearProgress />
          ) : (
            customerRewardPoints.value.toFixed(2)
          )}
        </Right>
        <Right title="Code">{customer.code}</Right>
        <Right title="Home store">{homeStore?.name}</Right>
        <Right title="Store credit">
          {' '}
          {formatCurrency(customer.availableCredit ?? 0)}
        </Right>
        <Right title="Last visit">{lastVisit?.format('MM/DD/YYYY')}</Right>
        <Right title="Total sales">
          {ifExtraData(ed => formatCurrency(ed.total_sales ?? 0))}
        </Right>
        <Right title="Total visits">{ifExtraData(ed => ed.total_visits)}</Right>
        <Right title="DSC membership end date">
          {ifExtraData(ed => (
            <Expiration expires={ed.dscExpiration} />
          ))}
        </Right>
        {!expired &&
          ifExtraData(ed => (
            <Right
              title={
                <>
                  <span>Membership renewal</span>
                  <RenewalTooltip />
                </>
              }
            >
              <Box display="inline-block">
                <input
                  type="date"
                  value={date}
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                  min={dayjs(ed.lastPurchaseStartTime).format('YYYY-MM-DD')}
                  onChange={e => setDate(e.target.value)}
                  max="9999-12-31"
                />
                <Button
                  onClick={renewMembership}
                  disabled={date === currentDate}
                >
                  SAVE
                </Button>
              </Box>
            </Right>
          ))}
      </div>
    </Loader>
  );
};

export const UICustomerViewTable: PosPlugin['UICustomerViewTable'] = ({
  customer,
}) => {
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  const activeCustomer = useSelector(getSelectedCustomer);

  return (
    <>
      <DataTable />
      {activeCustomer.customerID === customer.customerID && (
        <div data-testid="quick-actions">
          <AddCouponsQuickAction />
          {/* Add more quick actions here */}
        </div>
      )}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() =>
          dispatch(
            openPluginModalPage('PreviousSales')({
              isPopup: true,
              modalClassName: styles.modal,
              props: {
                customerID: activeCustomer.id,
              },
            }),
          )
        }
      >
        Open previously purchased products
      </Button>
    </>
  );
};
export const components = { PreviousSales, SalesList };
