// set active Customer
export const SET_CUSTOMER = 'CUSTOMERS:SET_CUSTOMER';
export const SET_CUSTOMER_SUCCESS = 'CUSTOMERS:SET_CUSTOMER_SUCCESS';
export const SET_CUSTOMER_ERROR = 'CUSTOMERS:SET_CUSTOMER_ERROR';
export const RESET_CUSTOMER = 'CUSTOMERS:RESET_CUSTOMER';

// save customer
export const SAVE_CUSTOMER = 'CUSTOMERS:SAVE_CUSTOMER';
export const SAVE_CUSTOMER_SUCCESS = 'CUSTOMERS:SAVE_CUSTOMER_SUCCESS';
export const SAVE_CUSTOMER_ERROR = 'CUSTOMERS:SAVE_CUSTOMER_ERROR';
// save address
export const SAVE_ADDRESS = 'CUSTOMERS:SAVE_ADDRESS';
export const SAVE_ADDRESS_SUCCESS = 'CUSTOMERS:SAVE_ADDRESS_SUCCESS';

// delete address
export const DELETE_ADDRESS = 'CUSTOMERS:DELETE_ADDRESS';
export const DELETE_ADDRESS_SUCCESS = 'CUSTOMERS:DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_ERROR = 'CUSTOMERS:DELETE_ADDRESS_ERROR';

// save one customer
export const SET_ONE_CUSTOMER = 'CUSTOMERS:SET_ONE_CUSTOMER';
export const SET_ONE_CUSTOMER_SUCCESS = 'CUSTOMERS:SET_ONE_CUSTOMER_SUCCESS';
export const SET_ONE_CUSTOMER_ERROR = 'CUSTOMERS:SET_ONE_CUSTOMER_ERROR';

// get customer groups
export const GET_CUSTOMER_GROUPS = 'CUSTOMERS:GET_CUSTOMER_GROUPS';
export const GET_CUSTOMER_GROUPS_SUCCESS =
  'CUSTOMERS:GET_CUSTOMER_GROUPS_SUCCESS';
export const GET_CUSTOMER_GROUPS_ERROR = 'CUSTOMERS:GET_CUSTOMER_GROUPS_ERROR';

// get address types
export const GET_ADDRESS_TYPES_START = 'CUSTOMERS:GET_ADDRESS_TYPES_START';
export const GET_ADDRESS_TYPES_SUCCESS = 'CUSTOMERS:GET_ADDRESS_TYPES_SUCCESS';
export const GET_ADDRESS_TYPES_ERROR = 'CUSTOMERS:GET_ADDRESS_TYPES_ERROR';

// get person titles
export const GET_PERSON_TITLES_START = 'CUSTOMERS:GET_PERSON_TITLES_START';
export const GET_PERSON_TITLES_SUCCESS = 'CUSTOMERS:GET_PERSON_TITLES_SUCCESS';
export const GET_PERSON_TITLES_ERROR = 'CUSTOMERS:GET_PERSON_TITLES_ERROR';

// get job titles
export const GET_JOB_TITLES_START = 'CUSTOMERS:GET_JOB_TITLES_START';
export const GET_JOB_TITLES_SUCCESS = 'CUSTOMERS:GET_JOB_TITLES_SUCCESS';
export const GET_JOB_TITLES_ERROR = 'CUSTOMERS:GET_JOB_TITLES_ERROR';

// get customer reward points
export const GET_CUSTOMER_REWARD_POINTS =
  'CUSTOMERS:GET_CUSTOMER_REWARD_POINTS';
export const GET_CUSTOMER_REWARD_POINTS_SUCCESS =
  'CUSTOMERS:GET_CUSTOMER_REWARD_POINTS_SUCCESS';
export const GET_CUSTOMER_REWARD_POINTS_ERROR =
  'CUSTOMERS:GET_CUSTOMER_REWARD_POINTS_ERROR';

// get customer store credit
export const GET_CUSTOMER_STORE_CREDIT = 'CUSTOMERS:GET_CUSTOMER_STORE_CREDIT';
export const GET_CUSTOMER_STORE_CREDIT_SUCCESS =
  'CUSTOMERS:GET_CUSTOMER_STORE_CREDIT_SUCCESS';

export const GET_DEFAULT_CUSTOMER = {
  START: 'CUSTOMERS:GET_DEFAULT_CUSTOMER_START',
  SUCCESS: 'CUSTOMERS:GET_DEFAULT_CUSTOMER_SUCCESS',
  ERROR: 'CUSTOMERS:GET_DEFAULT_CUSTOMER_ERROR',
};

export const SET_CUSTOMER_ISSUED_COUPONS = {
  START: 'CUSTOMERS:SET_CUSTOMER_ISSUED_COUPONS_START',
  SUCCESS: 'CUSTOMERS:SET_CUSTOMER_ISSUED_COUPONS_SUCCESS',
  ERROR: 'CUSTOMERS:SET_CUSTOMER_ISSUED_COUPONS_ERROR',
};

export const SAVE_CUSTOMER_CARD_TOKEN_INFO =
  'CUSTOMERS:SAVE_CUSTOMER_CARD_TOKEN_INFO';
