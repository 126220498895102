import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';

import { DisabledOn } from '../../../types';

type Props = TextFieldProps & {
  value?: DisabledOn;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
};

const DisabledOnSelect: React.FC<Props> = ({
  value = DisabledOn.NEVER,
  onChange,
  label,
  ...props
}) => {
  return (
    <TextField
      select
      onChange={onChange}
      value={value}
      fullWidth
      label={label}
      {...props}
    >
      <MenuItem value={DisabledOn.ALWAYS}>Always</MenuItem>
      <MenuItem value={DisabledOn.NEVER}>Never</MenuItem>
      <MenuItem value={DisabledOn.ON_EDIT}>On edit</MenuItem>
    </TextField>
  );
};

export default DisabledOnSelect;
