import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import * as R from 'ramda';

import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';

import { defaultFontConfig, fontWeightOptions } from './constants';
import { Configuration, FontConfig as FontConfigType } from './types';

import { getCustomerDisplayPluginConfiguration } from '.';

interface Props {
  config?: Configuration['config']['fontConfig'];
  save: (config: Configuration['config']['fontConfig']) => void;
}

export const FontConfig: React.FC<Props> = ({ config, save }) => {
  const { t } = useTranslation('customerDisplay');
  const existingConf = useSelector(getCustomerDisplayPluginConfiguration);

  const [fontConfig, setFontConfig] = useState(config ?? {});
  const [errors, setErrors] = useState({});

  const validateFontSize = (fontSize, name) => {
    if (fontSize > 60 || fontSize < 14) {
      setErrors(R.assoc(name, t('fontConfig.fontSizeValidationMessage')));
    } else {
      setErrors(R.dissoc(name));
    }
  };

  const handleChange = e => {
    const { name, value, type } = e.target;
    const sanitizedValue =
      type === 'number' && value.length ? Number(value) : value;
    const [key, prop] = name.split('.');
    validateFontSize(sanitizedValue, name);
    setFontConfig(R.assocPath([key, prop], sanitizedValue));
  };

  const tempFontConfig: FontConfigType = R.mergeRight(
    defaultFontConfig,
    fontConfig,
  );

  return (
    <>
      <h4>{t('titles.fontConfig')}</h4>
      <Table>
        <thead>
          <tr>
            <th>{t('fontConfig.headers.name')}</th>
            <th>{t('fontConfig.headers.fontSize')}</th>
            <th>{t('fontConfig.headers.fontWeight')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(tempFontConfig).map(([key, value]) => (
            <tr key={key}>
              <td>{t(`fontConfig.names.${key}`)}</td>
              <td>
                <InputField
                  value={value?.fontSize}
                  name={`${key}.fontSize`}
                  onChange={handleChange}
                  errorText={errors[`${key}.fontSize`]}
                  size="md"
                  type="number"
                />
              </td>

              <td>
                <InputField
                  value={value?.fontWeight}
                  name={`${key}.fontWeight`}
                  type="select"
                  options={fontWeightOptions}
                  onChange={handleChange}
                  size="md"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <UIButton
        text={t(`buttons.saveFontConfig`)}
        action={() => save(fontConfig as FontConfigType)}
        disabled={
          R.equals(fontConfig, existingConf?.config?.fontConfig) ||
          !!Object.keys(errors).length
        }
      />
      <hr />
    </>
  );
};
