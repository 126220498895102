/* eslint-disable no-console */
import i18next from 'i18next';
import { batch } from 'react-redux';

import {
  setPickupInProgress,
  setCurrentSalesDocument,
  setIsCurrentSaleAReturn,
  setIsCurrentSaleGiftReturn,
  setUsedRewardPoints,
  setCurrentSalesDocPayments,
} from 'actions/sales';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { openManagerOverride } from 'containers/Forms/ManagerOverride/actions';
import { getRightsForUserID } from 'actions/Login';
import {
  resetShoppingCart,
  addMultiProducts,
  addMultiProductsFailure,
} from 'actions/ShoppingCart';
import { getProductsUniversal } from 'actions/productsDB';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { addError } from 'actions/Error';

export function addReturnProducts({
  returnDocument,
  orders,
  isGiftReturn = false,
}) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onAddReturnProducts')(
      getState(),
    );
    const selectedCustomerID = getSelectedCustomerID(getState());
    try {
      dispatch(setPickupInProgress(true));
      await i18next.loadNamespaces('managerOverride').then(() =>
        dispatch(
          openManagerOverride([
            {
              key: 'startReturnWithInvoice',
              text: i18next.t('managerOverride:reasons.returnWithReceipt'),
              isPermitted: async user =>
                getRightsForUserID(Number(user.userID)).then(
                  rights => Number(rights.rightMakePOSRefunds ?? 0) > 0,
                ),
            },
          ]),
        ),
      );
      await dispatch(before({ returnDocument, orders, isGiftReturn }));
      await dispatch(resetShoppingCart());
      await dispatch(
        getProductsUniversal(
          { productIDs: orders.map(o => Number(o.productID)) },
          { addToCachedItems: true },
        ),
      );
      await batch(async () => {
        try {
          const {
            returnDocument: retDoc,
            orders: ord,
            isGiftReturn: isGiftRet,
          } = await dispatch(
            on(
              { returnDocument, orders, isGiftReturn },
              { returnDocument, orders, isGiftReturn },
            ),
          ).catch(() => {
            // eslint-disable-next-line no-throw-literal
            throw 'pluginerror';
          });
          await dispatch(setCurrentSalesDocument(retDoc));
          await dispatch(setIsCurrentSaleAReturn(true));
          await dispatch(setIsCurrentSaleGiftReturn(isGiftRet));
          await dispatch(setUsedRewardPoints(retDoc));

          if (!isGiftRet) {
            await dispatch(
              setCurrentSalesDocPayments({
                documentID: retDoc.id,
                paymentIDs: retDoc.returnedPayments?.length
                  ? retDoc.returnedPayments.flatMap(p => p.ids)
                  : null,
              }),
            );
          }

          const customerAlreadySelected =
            retDoc.clientID === selectedCustomerID;
          if (!isGiftRet && !customerAlreadySelected) {
            await dispatch(
              setCustomer({
                data: retDoc.clientID,
                recalculateShoppingCart: false,
              }),
            );
          }
          await dispatch(addMultiProducts(ord));
          await dispatch(
            after(
              { returnDocument, orders, isGiftReturn },
              { returnDocument: retDoc, orders: ord, isGiftReturn: isGiftRet },
            ),
          );
        } catch (error) {
          if (error !== 'pluginerror')
            console.error('Failed to load return products', error);
        }
      });
    } catch (error) {
      console.error('Failed to add return products', error);
      const errorText = error?.message ?? error;
      dispatch(addError(errorText));
      dispatch(addMultiProductsFailure(error));
    } finally {
      dispatch(setPickupInProgress(false));
    }
  };
}
