import React, {
  SyntheticEvent,
  IframeHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import { getGoErpTemplateBaseUrl } from 'reducers/configs/serviceEnpoints';

interface Props {
  title: IframeHTMLAttributes<HTMLIFrameElement>['title'];
  url: string;
  onMessage: MessageChannel['port1']['onmessage'];
  onLoad: (loadEvent: SyntheticEvent<HTMLIFrameElement, Event>) => void;
  onStartLoading?: () => void;
  iframeProps?: IframeHTMLAttributes<HTMLIFrameElement>;
}

function GoErpApp({
  title,
  url,
  onMessage,
  onLoad,
  onStartLoading,
  iframeProps,
}: Props) {
  const { t } = useTranslation('alerts');

  const templateBaseUrl = useSelector(getGoErpTemplateBaseUrl);
  const appUrl = templateBaseUrl ? `${templateBaseUrl}${url}` : null;
  const lastUrl = useRef<typeof appUrl>(null);

  useEffect(() => {
    if (appUrl && appUrl !== lastUrl.current) {
      lastUrl.current = appUrl;
      onStartLoading?.();
    }
  }, [appUrl, onStartLoading]);

  function establishCommunication(
    loadEvent: SyntheticEvent<HTMLIFrameElement, Event>,
  ) {
    if (!appUrl) {
      throw new Error('Cannot establish communication. No app url provided.');
    }
    const channel = new window.MessageChannel();
    channel.port1.onmessage = onMessage;
    // Send port2 to iframe so that it can use it to communicate with POS
    (loadEvent.target as HTMLIFrameElement).contentWindow?.postMessage(
      'init',
      appUrl,
      [channel.port2],
    );
  }

  function handleLoad(loadEvent: SyntheticEvent<HTMLIFrameElement, Event>) {
    onLoad(loadEvent);
    establishCommunication(loadEvent);
  }

  if (!appUrl) {
    return (
      <Alert severity="error">
        <AlertTitle>{t('missingGoErpAppUrl.title')}</AlertTitle>
        {t('missingGoErpAppUrl.body')}
      </Alert>
    );
  }

  return (
    <iframe
      title={title}
      frameBorder="0"
      src={appUrl}
      onLoad={handleLoad}
      {...iframeProps}
    />
  );
}

export default GoErpApp;
