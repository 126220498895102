import { PosPlugin } from 'plugins/plugin';
import { pluginID } from 'plugins/taf/customizations/constants';

import VoucherInputModal from './VoucherInputModal';
import VoucherErrorModal from './VoucherError';

export const modals = {
  voucherInputModal: `${pluginID}-SHOW-VOUCHER-MODAL`,
  voucherErrorModal: `${pluginID}-SHOW-VOUCHER-ERROR-MODAL`,
};

export const components: PosPlugin['components'] = {
  [modals.voucherInputModal]: VoucherInputModal,
  [modals.voucherErrorModal]: VoucherErrorModal,
};
