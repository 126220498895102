import { doClientRequest } from './core/ErplyAPI';

// eslint-disable-next-line import/prefer-default-export
export const getAssortmentProducts = (warehouseID, assortmentID) => {
  return doClientRequest({
    request: 'getAssortmentProducts',
    warehouseID,
    assortmentID,
  });
};
