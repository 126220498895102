/**
 * Multiple actions provived as a list get dispatched separately
 * (unpacks deeply nested lists as well)
 */
export default ({ dispatch }) => next => action => {
  if (Array.isArray(action)) {
    return Promise.all(action.map(dispatch));
  }
  return next(action);
};
