/* eslint-disable eqeqeq */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { miniUuid } from 'utils';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { setPayment } from 'actions/Payments/setPayment';
import { PluginComponent } from 'plugins';
import {
  getCurrencyFormatterNoSymbol,
  getPosRoundCashFunction,
  getPosRoundCash,
} from 'reducers/configs/settings';
import {
  getBalance,
  getIsPaymentDisabled,
  getCurrentSalesDocPaymentsCombo,
  getCurrentSalesDocument,
  getPayments,
} from 'reducers/Payments';

import styles from '../PaymentList/PaymentList.module.scss';

const OriginalPaymentList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('payment');
  const salesDoc = useSelector(getCurrentSalesDocument) ?? {};
  const balance = useSelector(getBalance);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);
  const originalPaymentsEnhanced = useSelector(getCurrentSalesDocPaymentsCombo);
  const checkPaymentIsDisabled = useSelector(getIsPaymentDisabled);
  const roundCashFunction = useSelector(getPosRoundCashFunction);
  const roundCashConfig = useSelector(getPosRoundCash);
  const currentPayments = useSelector(getPayments);

  const handlePaymentItemClick = async payment => {
    const amount = payment.remaining;
    if (balance <= 0) return;
    if (amount === 0 || payment.type === 'TIP') return;
    let amountToAssign = -Math.abs(amount > balance ? balance : amount);
    const newKey = `orig.${payment.paymentID}.${miniUuid()}`;
    const attributes = payment.attributes?.map
      ? Object.fromEntries(
          (payment.attributes ?? []).map(attr => [
            attr.attributeName,
            attr.attributeValue,
          ]),
        )
      : payment.attributes;
    if (payment.type === 'CASH') {
      const cashPaymentCanBeAdded =
        balance > 0 && !Object.keys(currentPayments).length;
      const rounded = roundCashFunction(amountToAssign);
      if (Number(rounded) === 0) {
        if (cashPaymentCanBeAdded) {
          amountToAssign = -roundCashConfig;
        } else {
          return;
        }
      } else {
        amountToAssign = -Math.abs(rounded);
      }
    }
    await dispatch(
      setPayment({
        type: payment.type,
        amount: amountToAssign,
        cardNumber: payment.cardNumber,
        caption: payment.caption ?? payment.type,
        paymentIntegration: payment.paymentIntegration,
        key: newKey,
        serial: payment.type === 'GIFTCARD' ? payment.cardNumber : undefined,
        giftCardBalance: payment.giftCardBalance,
        attributes: {
          originalPaymentID: payment.paymentID,
        },
        original: {
          ...payment,
          attributes,
        },
        currencyCode: payment.currencyCode,
        currencyRate: Number(payment.currencyRate ?? 1),
      }),
    );
    await dispatch(setPaymentSelected(`orig.${payment.paymentID}`));
  };

  return (
    <div className={styles['payment-list']} data-testid="original-payment-list">
      <div className="payment-total">
        <span className="payment-total-label" data-testid="payment-total">
          {t('originalPayments.total', {
            total: CURR.stringify(salesDoc.paid),
          })}
        </span>
      </div>
      <div className="payments" data-testid="payments">
        <ul className="payment-list-group">
          {originalPaymentsEnhanced
            ? originalPaymentsEnhanced.map(payment => {
                const paymentKey = payment.paymentID;
                // eslint-disable-next-line yoda
                const isUsed = 0 < payment.spent;
                const isUsedCompletely = payment.remaining <= 0;
                const isTipPayment =
                  payment.type === 'TIP' ||
                  payment.attributes?.cardIsTip === '1';
                const disabled =
                  Number(balance) <= 0 ||
                  isUsedCompletely ||
                  isTipPayment ||
                  checkPaymentIsDisabled(payment.type);
                const title =
                  (disabled &&
                    checkPaymentIsDisabled(payment.type) &&
                    t('alerts.paymentDisabled')) ||
                  (disabled &&
                    isUsedCompletely &&
                    t('alerts.usedCompletely')) ||
                  (disabled && isTipPayment && t('alerts.tipPayment')) ||
                  (disabled && t('alerts.amountCovered')) ||
                  undefined;
                return (
                  <li
                    key={paymentKey}
                    className="payment-item"
                    data-testid="original-payment-item"
                    data-test-type={payment.type}
                    data-test-partiallyused={isUsed}
                    data-test-completelyused={isUsedCompletely}
                    data-test-serial={!!payment.serial} // Requested to be boolean by Daniel
                  >
                    <PluginComponent
                      hookname="UIOriginalPayment"
                      props={{
                        payment,
                        disabled,
                        onClick: () => handlePaymentItemClick(payment),
                      }}
                    >
                      <div
                        style={{
                          cursor: disabled ? 'not-allowed' : 'pointer',
                          // eslint-disable-next-line no-nested-ternary
                          borderColor: isUsedCompletely
                            ? 'maroon'
                            : isUsed
                            ? 'orange'
                            : undefined,
                          opacity: isUsedCompletely ? 0.7 : 1,
                        }}
                        tabIndex={0}
                        disabled={disabled}
                        title={title}
                        data-testid="payment-item-container"
                        onClick={
                          disabled
                            ? undefined
                            : () => handlePaymentItemClick(payment)
                        }
                        className="button"
                        role="button"
                      >
                        <div className="type">
                          <span data-testid="payment-type">{payment.type}</span>
                          <PluginComponent hookname="UIOriginalPaymentCurrency">
                            <span className="small text-muted">
                              {payment.currencyCode}
                            </span>
                          </PluginComponent>

                          {payment.cardNumber ? (
                            <span data-testid="payment-card-number">
                              {payment.cardNumber}
                            </span>
                          ) : null}
                        </div>
                        <span data-testid="payment-amount" className="amount">
                          {CURR.stringify(payment.sum)}
                        </span>
                      </div>
                    </PluginComponent>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      <div className="payment-balance" data-testid="payment-change">
        <span className="payment-balance-value pull-right" />
      </div>
    </div>
  );
};

export default OriginalPaymentList;
