import React from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

import { getExchangeState } from '../rdx';

const UIProductAmountInShoppingCart: PosPlugin['UIProductAmountInShoppingCart'] = (
  props: any,
) => {
  const { amount, children } = props;
  const exchangeState = useSelector(getExchangeState);

  return (
    <>
      {exchangeState.exchangeStep > 0 ? (
        <td className="amount">{amount}</td>
      ) : (
        children
      )}
    </>
  );
};

export default UIProductAmountInShoppingCart;
