import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';

import { modalPages as modals } from 'constants/modalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';

const CreateNew = () => {
  const { t } = useTranslation('createNew');
  const dispatch = useDispatch();
  const canAddGroups = useSelector(
    state => state.Login.rights?.modules.prodgroups?.add,
  );
  return (
    <div className="erply-settings">
      <Modal.Header>
        <h3>
          <b>{t('choose.title')}</b>
        </h3>
        <CloseButton
          action={() => {
            dispatch(closeModalPage());
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <ListGroup as="ul">
          <ListGroup.Item
            style={{ cursor: 'pointer' }}
            action
            as="li"
            data-testid="create-product"
            onClick={() => {
              dispatch(
                openModalPage({
                  component: modals.createNewItem,
                  props: { type: 'product' },
                }),
              );
            }}
          >
            {t('choose.addProduct')}
            <i className="arrow arrow_carrot-right" />
          </ListGroup.Item>
          {canAddGroups > 0 ? (
            <ListGroup.Item
              style={{ cursor: 'pointer' }}
              action
              data-testid="create-group"
              as="li"
              onClick={() => {
                dispatch(
                  openModalPage({
                    component: modals.createNewItem,
                    props: { type: 'group' },
                  }),
                );
              }}
            >
              {t('choose.addGroup')}
              <i className="arrow arrow_carrot-right" />
            </ListGroup.Item>
          ) : null}
        </ListGroup>
      </Modal.Body>
    </div>
  );
};

export default CreateNew;
