import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './BottomPopup.css';
import {
  saveAsLayaway,
  saveAsOrder,
  saveAsWaybill,
  saveSale,
  saveAsAccountSale,
} from 'actions/sales';
import { getUISettings } from 'reducers/configs/settings';
import { useAsyncFunctionNoParallelExecution } from 'utils/hooks/useSingleUseFunction';

const SaveAsView = ({ goBack, close, isSaveAs }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('gridButtons');
  const uiSettings = useSelector(getUISettings);

  const [
    processSale,
    { isUsed: isSavingSale },
  ] = useAsyncFunctionNoParallelExecution(() => dispatch(saveSale()));

  return (
    <>
      <span
        className="mobile-menu-action"
        onClick={() => {
          dispatch(saveAsOrder());
          close();
        }}
      >
        {t('saleOptions.saveAsOrder')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={() => {
          if (isSavingSale) {
            return;
          }
          processSale();
          close();
        }}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.saveSale')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={() => {
          dispatch(saveAsWaybill());
          close();
        }}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.saveAsWaybill')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={() => {
          dispatch(saveAsLayaway());
          close();
        }}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.saveAsLayaway')}
      </span>
      {uiSettings.hideAccountSales ? null : (
        <span
          className="mobile-menu-action"
          onClick={() => {
            dispatch(saveAsAccountSale());
            close();
          }}
          style={{ marginTop: '14px' }}
        >
          {t('saleOptions.accountSales')}
        </span>
      )}

      {/* Handle back and cancel buttons inside both menus */}
      {isSaveAs ? (
        <span
          className="btn-cancel mobile-menu-action"
          onClick={() => close()}
          style={{ marginTop: '28px' }}
        >
          {t('common:cancel')}
        </span>
      ) : (
        <span
          className="btn-cancel mobile-menu-action"
          onClick={() => goBack()}
          style={{ marginTop: '28px' }}
        >
          {t('common:back')}
        </span>
      )}
    </>
  );
};

export default SaveAsView;
