import { PosPlugin } from 'plugins/plugin';
import { openPluginModalPage } from 'actions/modalPage';

import { CreateSpecialOrderModal } from './CreateSpecialOrderModal';

/**
 * If the document was saved from the ["Create Special Order" button]{@link import('plugins/nohble/SpecialOrders/SaveAsSpecialOrder/GridButton.ts').addSaveOrderButton}, then after the core flow for saving the order is finished, open {@link CreateSpecialOrderModal} for the now-saved document
 */
export const onSaveOrder: Required<PosPlugin>['onSaveSalesDocument'] = {
  /**
   * If {@link wasOrder} was set by {@link onSaveOrder.on}, opens {@link CreateSpecialOrderModal}, unsetting the flag in the process
   */
  after: (p, o) => async (dispatch, getState) => {
    if (p.salesDocument?.['plugin:isSpecialOrder']) {
      dispatch(
        openPluginModalPage(CreateSpecialOrderModal.id)({
          props: {
            salesDocument: o.salesDocument,
          },
        }),
      );
    }
  },
};
