import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import * as rxjs from 'rxjs';
import * as rxop from 'rxjs/operators';
import { Observable } from 'redux';

import { getMSEndpointFromLS } from 'reducers/installer';

type Response<Func extends string, Data> = {
  function: Func;
  id: string;
  data: Data;
  statusCode: '';
  statusMessage: 'success';
  error: '';
};

type PrintResponse = Response<'pluginCD', { Message: 'OK' }>;
type ScaleResponse = Response<
  'ScaleWeight',
  { weight: string; measure: string }
>;
type ScanResponse = Response<
  'ScaleStartScan',
  { BarType: 'Code128' | string; BarVal: string }
>;

export type SmartCardResponse = Response<
  'SmartCardStart',
  {
    documentNumber: string;
    firstName: string;
    personalIdentificationCode: string;
    surname: string;
  }
>;

type ResponseMessage =
  | PrintResponse
  | ScaleResponse
  | ScanResponse
  | SmartCardResponse;
type RequestMessage = {
  id: string;
  function:
    | 'ScaleStartScan'
    | 'ScaleStopScan'
    | 'ScaleWeight'
    | 'pluginCD'
    | 'SmartCardStart'
    | 'SmartCardStop'
    | 'ShowIdleScreen';
  data?: any;
};
function getExtDevUrl() {
  const endpoint = getMSEndpointFromLS('extdevmicroservice');
  return (
    endpoint?.replace('http', 'ws')?.toString() ||
    'wss://localhost.erply.com:5658'
  );
}
// NB: Mutable variable that is reassigned whenver the URL changes! Do not copy/cache this value
// To use it as an observable, use `rxjs.defer(() => ws$)` instead
let ws$: WebSocketSubject<ResponseMessage | RequestMessage> | undefined;

rxjs
  .interval(1e3)
  .pipe(rxop.map(getExtDevUrl), rxop.distinctUntilChanged())
  .subscribe({
    next: url => {
      ws$?.unsubscribe();
      ws$ = webSocket(url);
    },
  });

let id = 1;
/**
 @returns {Promise<null | number>} Resolves to the current value of the connected scale - or null if no scale is connected (or scale is reading 0.00)
 */
export const getScaleInput$ = rxjs
  .defer(() =>
    ws$?.multiplex(
      () => ({
        function: 'ScaleWeight',
        // eslint-disable-next-line no-plusplus
        id: String(id++),
      }),
      () => ({}),
      msg => msg.function === 'ScaleWeight',
    ),
  )
  .pipe(
    rxop.repeatWhen(complete => complete.pipe(rxop.delay(1e3))),
    rxop.map(res => res.data as ScaleResponse['data']),
    rxop.tap(() =>
      ws$?.next({
        function: 'ScaleWeight',
        // eslint-disable-next-line no-plusplus
        id: String(id++),
      }),
    ),
    rxop.share(),
  );

export const getIdCardReaderInput$ = rxjs
  .defer(() =>
    ws$?.multiplex(
      // eslint-disable-next-line no-plusplus
      () => ({ id: String(id++), function: 'SmartCardStart' }),
      // eslint-disable-next-line no-plusplus
      () => ({ id: String(id++), function: 'SmartCardStop' }),
      msg => msg.function === 'SmartCardStart',
    ),
  )
  .pipe(
    rxop.repeatWhen(complete => complete.pipe(rxop.delay(1e3))),
    rxop.map(res => res.data as SmartCardResponse['data']),
    rxop.share(),
  );

export const getScaleScannerInput$ = rxjs
  .defer(() =>
    ws$?.multiplex(
      // eslint-disable-next-line no-plusplus
      () => ({ id: String(id++), function: 'ScaleStartScan' }),
      // eslint-disable-next-line no-plusplus
      () => ({ id: String(id++), function: 'ScaleStopScan' }),
      msg => msg.function === 'ScaleStartScan',
    ),
  )
  .pipe(
    rxop.repeatWhen(complete => complete.pipe(rxop.delay(1e3))),
    rxop.map(res => res.data as ScanResponse['data']),
    rxop.share(),
  );

type ExtdevCustomerDisplayMessage = {
  products: [
    {
      id: number;
      name: string;
      qty: string;
      final: string;
    },
  ];
  total: string;
};
export function registerCustomerDisplay$(
  source$: Observable<ExtdevCustomerDisplayMessage>,
) {
  source$.subscribe({
    next: data => {
      // if products are empty show idle screen msg
      if (data.products.length < 1) {
        ws$?.next({
          function: 'ShowIdleScreen',
          // eslint-disable-next-line no-plusplus
          id: String(id++),
        });
      } else {
        ws$?.next({
          function: 'pluginCD',
          // eslint-disable-next-line no-plusplus
          id: String(id++),
          data,
        });
      }
    },
  });
}
