import { getComponents } from 'reducers/modalPage';
import { modalPages as mp, idCardReaderWhiteList } from 'constants/modalPage';
import * as api from 'services/ErplyAPI/customers';
import { openModalPage } from 'actions/ModalPage/openModalPage';

import { setCustomer } from './CustomerSearch/setCustomer';
import { addWarning } from './Error';
import { SmartCardResponse } from './integrations/extdev';

export function handleIdCardRead(param: SmartCardResponse['data']) {
  return async (
    dispatch,
    getState,
  ): Promise<void> => {
    try {
      const modalPagesCount = getComponents(getState()).filter(
        c => !idCardReaderWhiteList.includes(c.component),
      )?.length;
      // if you're not on a whitelisted modal page, then scanning returns nothing
      // else continue scanning
      if (!param || modalPagesCount) {
        return;
      }
      const [customer] = await api.getCustomers({
        searchNameIncrementally: param?.personalIdentificationCode,
      });
      const getBirthdayFromIdCode = idcode => {
        const [idCode, id, yr, mnth, day, uniqueIdentifier] = idcode.match(
          /([0-9]{1})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{4})/,
        );

        switch (id) {
          case '1':
          case '2':
            return `18${yr}-${mnth}-${day}`;
          case '3':
          case '4':
            return `19${yr}-${mnth}-${day}`;
          case '5':
          case '6':
            return `20${yr}-${mnth}-${day}`;
          default:
            return '0000-00-00';
        }
      };

      if (customer) {
        dispatch(
          addWarning(`Customer ${customer.fullName} found`, {
            dismissible: true,
            selfDismiss: true,
          }),
        );
        dispatch(setCustomer({ data: customer }));
      } else {
        dispatch(
          openModalPage({
            component: mp.createCustomer,
            props: {
              newCustomer: true,
              newCustomerData: {
                firstName: param.firstName,
                lastName: param.surname,
                code: param.personalIdentificationCode,
                birthday: getBirthdayFromIdCode(param.personalIdentificationCode),
              },
            },
          }),
        );
      }
    } catch (e) {
      console.error('Error when handling ID card read', e);
    }
  };
}
