import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useSelector } from 'react-redux';

import { getDateFormatFromBOKeysOrdered } from 'reducers/configs/settings';

import generateRange from '../../utils/generateRange';
import { UpDownArrows } from '../FieldTypes/types/select';

const currentYear = new Date().getFullYear();

const options = {
  date: [' ', ...generateRange(1, 31)],
  month: [' ', ...generateRange(1, 12)],
  year: [' ', ...generateRange(currentYear - 120, currentYear).reverse()],
};

/** The component creates date selectors according to the needs of usage */
const DatePicker = ({
  onChange,
  value = { date: ' ', month: ' ', year: ' ' },
  title,
  labels,
  formGroupStyle = {},
  inputGroupStyle = {},
  inputStyle = {},
  labelStyle = {},
  validation = { date: () => false, month: () => false, year: () => false },
  size,
  controlledId = 'controlledId',
  ...restProps
}) => {
  const boFormattedFields = useSelector(getDateFormatFromBOKeysOrdered);

  const handleChange = e => {
    const { target } = e;
    onChange({ ...value, [target.name]: target.value });
  };
  // check which fields to be included in the datepicker
  const fields = boFormattedFields.filter(el =>
    Object.keys(restProps).includes(el.key),
  );
  // add default field year
  fields.length === 0 && fields.push('year');

  labels = labels || {};

  return (
    <Form.Group
      style={{ ...formGroupStyle, ...styles.formGroup }}
      controlId={controlledId}
    >
      {title && (
        <Form.Label style={{ ...styles.formLabel }}>{title}</Form.Label>
      )}
      {fields.map(({ key }) => {
        return (
          <InputGroup
            key={key}
            style={{
              ...inputGroupStyle,
              marginLeft: title ? '.25em' : 0,
            }}
          >
            <InputGroup.Prepend>
              <InputGroup.Text
                style={{
                  ...labelStyle,
                  ...{
                    ...(validation[key] &&
                      validation[key](value[key]) && {
                        borderColor: '#dc3545',
                      }),
                  },
                }}
              >
                {labels[key] || key}
              </InputGroup.Text>
            </InputGroup.Prepend>

            <FormControl
              style={{
                ...inputStyle,
                appearance: 'none',
                WebkitAppearance: 'none',
                cursor: 'pointer',
                backgroundImage: 'none',
              }}
              data-testid={key}
              isInvalid={validation[key] && validation[key](value[key])}
              as="select"
              value={value[key]}
              name={key}
              onChange={handleChange}
              size={size}
            >
              {options[key].map(o => (
                <option
                  data-testid={labels[key]}
                  data-test-key={o}
                  key={o}
                  value={o}
                >
                  {o}
                </option>
              ))}
            </FormControl>
            <FormControl.Feedback type="invalid">
              {validation[key](value[key]) || ''}
            </FormControl.Feedback>
            <UpDownArrows
              style={{
                position: 'absolute',
                top: '7px',
                right: '0',
              }}
            />
          </InputGroup>
        );
      })}
    </Form.Group>
  );
};

const styles = {
  formGroup: {
    display: 'flex',
    alignItems: 'baseline',
  },
  formLabel: { padding: '1em 1em 1em 0', minWidth: '30%' },
};

DatePicker.propTypes = {
  /** function to handle the changes, DatePicker would return an object of the same shape as value
   * <pre>{
   *   date: '02',
   *   month: '11',
   *   year: '2018'
   * }</pre>
   */
  onChange: PropTypes.func,
  /** Pass it as empty prop and the component will add the field to the DatePicker component */
  date: PropTypes.any,
  /** Same as date */
  month: PropTypes.any,
  /** Same as date */
  year: PropTypes.any,
  /** If you want the Datepicker component to be prepended with label, pass `title` prop to the `label` */
  title: PropTypes.node,
  /** If you want the datepicker to show names different from the default (eg. 'translation'),
   * add the name to labels object as follows in the example.
   */
  labels: PropTypes.shape({
    date: PropTypes.node,
    month: PropTypes.node,
    year: PropTypes.node,
  }),
  /** pass extra styles to customize the <Form.Group> outlook by passing jsx styles objects */
  formGroupStyle: PropTypes.object,
  /** pass extra styles to customize the <Form.Label> (title) outlook by passing jsx styles objects */
  labelStyle: PropTypes.object,
  /** pass extra styles to customize the <InputGroup> outlook by passing jsx styles objects */
  inputGroupStyle: PropTypes.object,
  /** pass extra styles to customize the <Form.Control> outlook by passing jsx styles objects */
  inputStyle: PropTypes.object,
};

export default DatePicker;
