import { v4 as uuidv4 } from 'uuid';

import { CardPaymentHooks } from 'paymentIntegrations/types';
import { getClientCode } from 'reducers/Login';
import { getCardPaymentsForIntegration, getTotal } from 'reducers/Payments';
import { getTotalTax } from 'reducers/ShoppingCart';
import { lastInvoiceNumbers } from 'services/localDB';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { getCurrentSalesDocument } from 'reducers/sales';
import { ErplyAttributes, sleep } from 'utils';
import { toPaymentObj } from 'paymentIntegrations/pax/handlers/toPaymentObj';
import { addSuccess } from 'actions/Error';
import { getErrorMessage, withWaitingForTerminal } from 'paymentIntegrations';

import { makePayment } from '../api2';

import { handleFail } from './handleFail';

export enum TransactionType {
  Sale = 'Sale',
  Refund = 'Refund',
  Void = 'Void',
}

export const generateInputData = (
  payment,
  initialTransactionType?: TransactionType,
) => async (dispatch, getState) => {
  const totalTax = getTotalTax(getState());
  const total = getTotal(getState());

  // If sale total is 0, not possible to calculate, default to 0.00
  // Probably true tbh
  // Example use case: Givex plugin increment product
  const taxAmount = Number(total)
    ? ((Number(payment.amount) * Number(totalTax)) / Number(total)).toFixed(2)
    : '0.00';

  const clientCode = getClientCode(getState());
  const pointOfSaleID = getSelectedPosID(getState());
  const lastInvoiceNumber = Number(
    (await lastInvoiceNumbers.getItem({
      key: pointOfSaleID,
    })) ?? String(new Date().getTime()),
  );
  const currentSale = getCurrentSalesDocument(getState());

  const paymentAttributes = new ErplyAttributes(payment.attributes);

  const hostReferenceNumber =
    paymentAttributes.get('hostReferenceNumber') ?? uuidv4();
  const referenceNumber = paymentAttributes.get('refNo') ?? uuidv4();
  const authCode = paymentAttributes.get('authCode');

  const ecrReferenceNumber = String(new Date().getTime());

  const isNegativeAmount = Number(payment.amount) < 0;

  const transactionType = (() => {
    if (initialTransactionType) return initialTransactionType;
    if (payment.paid && payment.shouldProcess) return TransactionType.Void;
    if (isNegativeAmount) return TransactionType.Refund;
    return TransactionType.Sale;
  })();

  const commonProps = {
    requestID: uuidv4(),
    clientCode,
    ecrReferenceNumber,
  };

  switch (transactionType) {
    case TransactionType.Sale:
      return {
        transactionType: TransactionType.Sale,
        invoiceNumber: String(lastInvoiceNumber + 1),
        amount: payment.amount,
        taxAmount,
        ...commonProps,
      };
    case TransactionType.Refund:
      return {
        transactionType: TransactionType.Refund,
        invoiceNumber: `${lastInvoiceNumber + 1}K`,
        referenceNumber,
        amount: payment.amount,
        authCode,
        ...commonProps,
      };
    case TransactionType.Void:
      return {
        transactionType: TransactionType.Void,
        invoiceNumber: currentSale?.number ?? String(lastInvoiceNumber + 1),
        referenceNumber,
        hostReferenceNumber,
        authCode,
        ...commonProps,
      };
    default:
      throw new Error('This should not happen');
  }
};

export const processPayments = ({
  beforeDocDelete,
  beforeDocSave,
  updateMessage,
}: CardPaymentHooks) => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('pax')(getState());
  const shouldVoid = cardPayments.every(cp => cp.paid && cp.shouldProcess);

  return cardPayments
    .filter(p => (shouldVoid ? p.paid : !p.paid))
    .reduce((prevPayment, currentPayment) => {
      return prevPayment.then(async ({ data, errors }) => {
        if (!errors.length) {
          await dispatch(
            withWaitingForTerminal(async () => {
              try {
                updateMessage(`Processing payment of ${currentPayment.amount}`);
                const inputData = await dispatch(
                  generateInputData(currentPayment),
                );
                const {
                  records: [record],
                } = await makePayment(inputData);

                if (record.resultCode === '0') {
                  if (shouldVoid) {
                    beforeDocDelete(currentPayment.key);
                    dispatch(addSuccess('Void successful'));
                  } else {
                    await beforeDocSave({
                      ...currentPayment,
                      ...record,
                      ...toPaymentObj(record),
                    });
                  }
                  data.push(record);
                } else {
                  await dispatch(
                    handleFail({
                      record,
                      updateMessage,
                      payment: currentPayment,
                    }),
                  );
                  errors.push(record.statusMessage);
                }
              } catch (error) {
                updateMessage(
                  getErrorMessage(error, 'Payment processing failed'),
                );
                console.error(error);
                errors.push(error);
              } finally {
                await sleep(1);
              }
            }),
          );
        }

        return { data, errors };
      });
    }, Promise.resolve({ data: [], errors: [] }));
};
