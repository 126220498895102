import React from 'react';
import * as R from 'ramda';
import { Typography } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';
import { reactcursively } from 'utils/react';

import { pluginName } from '../constants';

const regex = /layaway-cancellation-config+/;

const UILayawayCancellationConfig: PosPlugin['UILayawayCancellationConfig'] = ({
  children,
}) => {
  return R.pipe(
    reactcursively(
      R.when(el => {
        const rgxMatch = (
          el?.props?.InputProps?.inputProps?.['data-testid'] ?? ''
        ).match(regex);
        return rgxMatch;
      }, R.assocPath(['props', 'disabled'], true)),
    ),
    R.append(
      <Typography style={{ fontWeight: 'bold' }}>
        NB! Configuration is disabled because {pluginName} plugin is enabled
      </Typography>,
    ),
  )(React.Children.toArray(children));
};

export default UILayawayCancellationConfig;
