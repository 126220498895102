import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { VIEW_MODE } from 'constants/persistence';

import { closeGridWithConditions, openGridView } from '../../../../actions/UI';
import HeaderIcon from '../Icon';

const ViewChanger = () => {
  const gridDisplayOpened = useSelector(getGridIsOpened);
  const dispatch = useDispatch();

  const setViewMode = value => window.localStorage.setItem(VIEW_MODE, value);

  const onClick = useCallback(() => {
    if (gridDisplayOpened) {
      setViewMode('retail');
      dispatch(closeGridWithConditions());
    } else {
      setViewMode('grid');
      dispatch(openGridView());
    }
  }, [gridDisplayOpened]);

  const { t } = useTranslation('header');
  return (
    <HeaderIcon
      title={t('titles.changeView')}
      data-testid="header-change-view"
      icon={gridDisplayOpened ? 'erply_hamburger' : 'grid-3x3'}
      onClick={onClick}
    />
  );
};

export default ViewChanger;
