import React from 'react';
import i18next from 'i18next';

import * as customerActionsTypes from 'constants/customerSearch';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import * as api from 'services/ErplyAPI/customers';
import { addError } from 'actions/Error';
import { getSetting } from 'reducers/configs/settings';
import {
  getSelectedCustomerID,
  getCustomerIssuedCoupons,
} from 'reducers/customerSearch';
import { getAllAppliedCoupons } from 'reducers/ShoppingCart';
import { calculate } from 'actions/ShoppingCart/calculate';
import { getIsAReturn } from 'reducers/sales';
import { createConfirmation } from 'actions/Confirmation';
import { addTooltip, resetTooltips } from 'actions/UI';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { hasBirthday } from 'utils';
import { applyCoupons } from 'actions/ShoppingCart/applyCoupons';
import { modalPages as mp } from 'constants/modalPage';

import { getCustomerRewardPoints } from './getCustomerRewardPoints';
import { setCustomerIssuedCoupons } from './setCustomerIssuedCoupons';

export function setCustomer({
  data,
  firstName = '',
  lastName = '',
  recalculateShoppingCart = true,
  displayCouponsInfo = false,
  fetchRewardPoints = true,
  companyName = '',
}) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onSetCustomer')(
      getState(),
    );
    dispatch({ type: customerActionsTypes.SET_CUSTOMER });
    try {
      await dispatch(
        before({
          data,
          firstName,
          lastName,
          recalculateShoppingCart,
          displayCouponsInfo,
          fetchRewardPoints,
          companyName,
        }),
      );
      const customerID = typeof data === 'object' ? data.customerID : data;
      let isOffline = false;
      const payload = await (typeof data === 'object'
        ? Promise.resolve(data)
        : api
            .getCustomers({
              customerID,
              getAddresses: 1,
              getBalanceInfo: 1,
              getBalanceWithoutPrepayments: 1,
            })
            .then(a => {
              return a[0];
            })
      ).catch(async err => {
        // prettier-ignore
        console.warn('Unable to load customer', customerID, ', assuming connecing issue', err);
        isOffline = true;
      });
      if (isOffline) throw new Error('Action cancelled');
      if (!customerID) {
        dispatch(addError('Can not set customer without `customerID`'));
        throw new Error('Action cancelled');
      }
      const allowInvoicesForContactPersons = getSetting(
        'allowCreateInvForContactPerson',
      )(getState());
      if (!!payload.companyID && !allowInvoicesForContactPersons) {
        dispatch(
          addError(
            i18next.t('alerts:customers.invoicesForContactPersonNotAllowed'),
          ),
        );
        throw new Error('Action cancelled');
      }

      const previousCustomerID = getSelectedCustomerID(getState());
      const previousAppliedCoupons = getAllAppliedCoupons(getState());

      let props = {
        data,
        firstName,
        lastName,
        recalculateShoppingCart,
        displayCouponsInfo,
        fetchRewardPoints,
      };

      const pluginData = await dispatch(on(props, { payload, props }));
      props = pluginData.props;
      const customerData = pluginData.payload;
      // Initial set without rewardPoints
      await dispatch({
        type: customerActionsTypes.SET_CUSTOMER_SUCCESS,
        data: { rewardPoints: 0, availableCredit: 0, ...customerData },
      });
      // Fetch rewardPoints and storeCredit following in parallel
      if (props.fetchRewardPoints) dispatch(getCustomerRewardPoints(customerID));
      // Display tooltip in case a customer has active coupons
      await dispatch(setCustomerIssuedCoupons({ customerID }));

      if (props.recalculateShoppingCart) await dispatch(calculate());

      const customerIssuedCoupons = getCustomerIssuedCoupons(getState());
      const isAReturn = getIsAReturn(getState());
      const showNotesPopup = getSetting('touchpos_display_notes_popup')(
        getState(),
      );
      const message = i18next.t('alerts:customerNotes.message', {
        note: customerData.notes,
      });
      if (customerData?.notes?.trim() && showNotesPopup) {
        dispatch(
          createConfirmation(
            () => {
              /* do nothing */
            },
            null,
            {
              title: i18next.t('alerts:customerNotes.title'),
              body: message,
            },
          ),
        );
      }

      if (props.displayCouponsInfo) {
        const totalCoupons = customerIssuedCoupons.length;
        if (totalCoupons && !isAReturn) {
          const displayLimit = 6;
          const coupons = customerIssuedCoupons
            .map(coupon => coupon.uniqueIdentifier)
            .slice(0, displayLimit)
            .join(',\n');
          const content = (
            <p style={{ margin: 0 }}>
              {i18next.t('alerts:customerTooltips.activeCoupons', {
                context: totalCoupons < displayLimit ? 'few' : 'many',
                coupons,
                extra: Math.max(0, totalCoupons - displayLimit),
              })}
            </p>
          );

          if (!getSetting('touchpos_disable_unused_coupons_popup')(getState())) {
            new Promise((res, rej) =>
              dispatch(
                createConfirmation(res, rej, {
                  title: 'Confirmation',
                  body: i18next.t(
                    'alerts:customerTooltips.unusedCoupons',
                    customerData,
                  ),
                }),
              ),
            ).then(() =>
              dispatch(
                openModalPage({
                  component: mp.saleCoupons,
                  props: { apply: customerIssuedCoupons },
                }),
              ),
            );
          }
        } else if (
          customerData.customerType === 'PERSON' &&
          hasBirthday(customerData.birthday)
        ) {
          const content = (
            <p style={{ margin: 0 }}>
              {i18next.t('alerts:customerTooltips.birthday')}
            </p>
          );
          dispatch(addTooltip({ content }));
        } else {
          dispatch(resetTooltips());
        }
      } else if (previousCustomerID === customerID) {
        const couponsToApply = previousAppliedCoupons.filter(
          coupon => coupon.issuedCustomerID === customerID,
        );
        if (couponsToApply.length) dispatch(applyCoupons(couponsToApply));
      }

      // PLUGIN - after action hook
      dispatch(after(props, customerData));
    } catch (err) {
      console.error('Unable to set customer', err);
      dispatch({
        type: customerActionsTypes.SET_CUSTOMER_ERROR,
        data: err,
      });
    }
  };
}
