import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { getCFRState } from '../rdx';

const lockPaymentsOnOnlineReturn: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (p, ap) => async (dispatch, getState) => {
  const { isOnlineReturn } = getCFRState(getState());

  if (!isOnlineReturn) return ap;

  return {
    ...ap,
    payments: R.map(R.assoc('locked', true), ap.payments),
  };
};

export default lockPaymentsOnOnlineReturn;
