import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';

import customerNotesPopup from './components/CustomerNotesPopup';
import { PosPlugin } from '../../plugin';

/**
 * This plugin provides functionality for showing internal notes for PNP every time when a customer is selected
 */

const customerNotes: PosPlugin = {
  id: 'pnp-customer-notes',
  name: 'PnP - Customer notes',
  info:
    'If notes are set on the customer card, when the customer is added to the sale at the POS, a pop-up shows the content of the notes field.',
  keywords: ['pnp', 'customer-note', 'popup'],
  getStatus: () => ({ type: 'valid', message: '' }),
  components: {
    customerNotesPopup,
  },
  onSetCustomer: {
    after: (props, customerData) => async dispatch => {
      // TODO: PNP wants to toggle on and off this feature from BO, not POS
      if (customerData?.bankName) {
        await dispatch(
          openModalPage({
            component: modalPages.pluginModal,
            isPopup: true,
            props: {
              name: 'customerNotesPopup',
              dispatch,
              notes: customerData.bankName,
              fullName: customerData.fullName,
            },
          }),
        );
      }
    },
  },
};

export default customerNotes;
