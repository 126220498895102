import { createSelector } from 'reselect';

import { Currency } from 'types/Currencies';
import {
  getCurrencyCode,
  getPosCurrencyUnits,
  getSetting,
} from 'reducers/configs/settings';
import { roundCurrency } from 'utils';

import * as c from '../../constants/configs';
import { RootState } from '../index';

interface State {
  all: Currency[];
  default: Currency | null;
}

/** @deprecated */
function mmWorkaroundFormat(n: string|number, options: any, format: FormatFn) {
  // TODO: To be replaced by proper solution from merge request !3467
  //  Currency symbols should be positioned based on the language they are used in, not the currency itself
  //  The proper fix for that is in progress in !3467
  //  .
  //  M&M requires this fixed for their french-canadian stores NOW, so since the proper fix will not be ready in time
  //  this hack will fix just that specific combination (french + CAD) while leaving all other code unaffected
  //  By checking document directly, rather than adding any new dependencies to the selector
  //  we also safeguard against any potential memoization issues such as were found in the proper MR
  //  .
  //  This does mean that the formatting will not update instantly on language change.
  //  This is fine for M&M because they have separate french accounts, they don't switch languages in the middle of the day
  if (document.documentElement.lang === 'fr') {
    return `${format(n, options)}\xa0$`;
  }
  // Default case
  return `$${format(n, options)}`;
}

const currencyState: State = { all: [], default: null };

export function currency(
  state = currencyState,
  { type, data }: { type: string; data: Currency[] },
): State {
  switch (type) {
    case c.GET_CURRENCY_SUCCESS:
      return {
        ...state,
        all: data,
        default: data.find(c => Number(c.default)) ?? data[0],
      };
    default:
      return state;
  }
}

export default currency;

function cents(...arr: number[]) {
  return arr.map(c => c / 100);
}

export function getCurrencyDenominations(code: string) {
  return (state: RootState) => {
    const currencyCode = code ?? getCurrencyCode(state);
    const denominations = getPosCurrencyUnits(state);
    const {
      names = {},
      denominations: defaultDenoms,
      wholeFormat,
      centsFormat,
    } = getCurrency(currencyCode)(state);

    const res = denominations
      ? denominations.map(({ name, value }) => {
          /*
    Supported formats - 1) [500=200,100=100,10=10,0.50=0.5] (aka $display_value = $functional_value)
    so if you have denominations entered then pressing on 500 will actually add a payment of 200
    2) 100,50,25,10,5,1,0.1 - treats this as [100=100,50=50, etc]
  */
          return {
            value,
            mark:
              name ||
              names[value] ||
              (Math.abs(value) < 1
                ? centsFormat(value)
                : wholeFormat(value, { preserveInteger: true })),
          };
        })
      : defaultDenoms.map(d => ({
          value: d,
          mark:
            names[d] ||
            (Math.abs(d) < 1
              ? centsFormat(d)
              : wholeFormat(d, { preserveInteger: true })),
        }));
    return res;
  };
}

export function getAllCurrencies(state: RootState) {
  return state.configs.currency.all;
}

export const getAllCurrencyCodes = createSelector(
  getAllCurrencies,
  currencies => currencies.map(currency => currency.code),
);

export function getCurrencyByCode(code: string) {
  return (state: RootState) =>
    getAllCurrencies(state).find(cur => cur.code === code);
}

type FormatFn = (
  n: number | string,
  options?: {
    skipRounding?: boolean;
    roundDown?: boolean;
    preserveInteger?: boolean;
  },
) => string;
type UnformatFn = (str?: string) => number;

interface PosCurrency {
  symbol: string;
  formatNoSymbol: FormatFn;
  unformatNoSymbol: UnformatFn;
  format: FormatFn;
  wholeFormat: FormatFn;
  centsFormat: FormatFn;
  names?: Record<number, string>;
  denominations: number[];
  isoNumCode: string;
}

const getCurrencyFormatFns = createSelector<
  RootState,
  string,
  string,
  { format: FormatFn; unFormat: UnformatFn }
>(
  state => getSetting('numberformat')(state),
  state => getSetting('overwrite_money_decimals')(state),
  ([decimalSep, thousandsSep], overwriteMoneyDecimals) => {
    const format: FormatFn = (num, options) => {
      const n = typeof num === 'string' ? Number(num) : num;

      const fractionDigits =
        options?.preserveInteger && Math.floor(n) === n
          ? 0
          : Number(overwriteMoneyDecimals || 2);

      const roundedNum = options?.skipRounding
        ? num
        : roundCurrency(n, options?.roundDown);

      // prettier-ignore
      return roundedNum
        /* de-DE = 23.456.789,123456123 */
        .toLocaleString('de-DE', {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        })
        .replace(/[,.]/g, (match) => ({
          '.': thousandsSep,
          ',': decimalSep,
        }[match]));
    };

    const unFormat: UnformatFn = str =>
      Number(
        (str ?? '')
          .toString()
          .split(thousandsSep)
          .map(tp => tp.split(decimalSep).join('.'))
          .join(''),
      );

    return { format, unFormat };
  },
);

const getDefaultCurrencyFn = createSelector<
  RootState,
  { format: FormatFn; unFormat: UnformatFn },
  (code: string) => Record<string, PosCurrency>
  // @ts-ignore
>(getCurrencyFormatFns, ({ format, unFormat }) => code => {
  return {
    symbol: code,
    format: (n, options) => `${format(n, options)}${code}`,
    formatNoSymbol: format,
    unformatNoSymbol: unFormat,
    wholeFormat: (n, options) => format(n, options),
    centsFormat: (n, options) => format(n, options),
    denominations: [
      500,
      200,
      100,
      50,
      20,
      10,
      5,
      2,
      1,
      ...cents(50, 20, 10, 5, 2),
    ],
    isoNumCode: '',
  };
});

const getKnownCurrencies = createSelector<
  RootState,
  { format: FormatFn; unFormat: UnformatFn },
  Record<string, PosCurrency>
>(getCurrencyFormatFns, ({ format, unFormat }) => {
  return {
    USD: {
      symbol: '$',
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      format: (n, options) => `$${format(n, options)}`,
      wholeFormat: (n, options) => `$${format(n, options)}`,
      centsFormat: (n, options) => `$${format(n, options)}`,
      names: {
        0.25: 'quarter',
        0.1: 'dime',
        0.05: 'nickel',
        0.01: 'penny',
      },
      denominations: [100, 50, 20, 10, 5, 1, ...cents(50, 25, 10, 5, 1)],
      isoNumCode: '840',
    },
    CAD: {
      symbol: '$',
      format: (n, options) =>
        mmWorkaroundFormat(n, options, format),
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) =>
        mmWorkaroundFormat(n, options, format),
      centsFormat: n => `${Number(n) * 100}¢`,
      denominations: [100, 50, 20, 10, 5, 2, 1, ...cents(50, 25, 10, 5)],
      isoNumCode: '124',
    },
    AUD: {
      symbol: '$',
      format: (n, options) => `$${format(n, options)}`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `$${format(n, options)}`,
      centsFormat: n => `${Number(n) * 100}c`,
      denominations: [100, 50, 20, 10, 5, 2, 1, ...cents(50, 20, 10, 5)],
      isoNumCode: '036',
    },
    GBP: {
      symbol: '£',
      format: (n, options) => `£${format(n, options)}`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `£${format(n, options)}`,
      centsFormat: n => `${Number(n) * 100}p`,
      // prettier-ignore
      denominations: [100, 50, 20, 10, 5, 2, 1, ...cents(50, 20, 10, 5, 2, 1)],
      isoNumCode: '826',
    },
    NZD: {
      symbol: '$',
      format: (n, options) => `$${format(n, options)}`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `$${format(n, options)}`,
      centsFormat: n => `${Number(n) * 100}c`,
      denominations: [100, 50, 20, 10, 5, 2, 1, ...cents(50, 20, 10)],
      isoNumCode: '554',
    },
    DKK: {
      symbol: 'kr.',
      format: (n, options) => `${format(n, options)}kr.`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `${format(n, options)}kr.`,
      centsFormat: n => `${Number(n) * 100}-øre`,
      denominations: [1000, 500, 200, 100, 50, 20, 10, 5, 2, 1, ...cents(50)],
      isoNumCode: '208',
    },
    SGD: {
      symbol: '$',
      format: (n, options) => `$${format(n, options)}`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `$${format(n, options)}`,
      centsFormat: n => `${Number(n) * 100}c`,
      // prettier-ignore
      denominations: [1000, 500, 100, 50, 20, 10, 5, 2, 1, ...cents(50, 20, 10, 5)],
      isoNumCode: '702',
    },
    EUR: {
      symbol: '€',
      format: (n, options) => `${format(n, options)}€`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `${format(n, options)}€`,
      centsFormat: (n, options) => `${format(n, options)}€`,
      // prettier-ignore
      denominations: [100, 50, 20, 10, 5, 2, 1, ...cents(50, 20, 10, 5, 2, 1)],
      isoNumCode: '978',
    },
    CZK: {
      symbol: 'Kč',
      format: (n, options) => `${format(n, options)}Kč`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `${format(n, options)}Kč`,
      centsFormat: (n, options) => `${format(n, options)}Kč`,
      // prettier-ignore
      denominations: [5000,2000,1000,500,200,100, 50, 20, 10, 5, 2, 1],
      isoNumCode: '203',
    },
    CLP: {
      symbol: '$',
      format: (n, options) => `$${format(n, options)}`,
      formatNoSymbol: format,
      unformatNoSymbol: unFormat,
      wholeFormat: (n, options) => `$${format(n, options)}`,
      centsFormat: (n, options) => `$${format(n, options)}`,
      // prettier-ignore
      denominations: [20000, 10000, 5000, 2000, 1000, 500, 100, 50, 10, 5, 1],
      isoNumCode: '152',
    },
  };
});

const getAllCurrencyObjects = createSelector<
  RootState,
  Record<string, PosCurrency>,
  string[],
  (code: string) => Record<string, PosCurrency>,
  Record<string, PosCurrency>
>(
  getKnownCurrencies,
  getAllCurrencyCodes,
  getDefaultCurrencyFn,
  (knownCurrencies, allCurrencyCodes, getDefaultCurrency) => {
    const unknownCurrencyEntries = allCurrencyCodes
      .filter(currencyCode => !knownCurrencies[currencyCode])
      .map(currencyCode => [currencyCode, getDefaultCurrency(currencyCode)]);

    if (!unknownCurrencyEntries.length) return knownCurrencies;
    return {
      ...knownCurrencies,
      ...Object.fromEntries(unknownCurrencyEntries),
    };
  },
);

export function getCurrency(currencyCode: string) {
  return createSelector<
    RootState,
    Record<string, PosCurrency>,
    (code: string) => Record<string, PosCurrency>,
    PosCurrency
  >(
    getAllCurrencyObjects,
    getDefaultCurrencyFn,
    (currencies, getDefaultCurrency) => {
      return currencies[currencyCode] ?? getDefaultCurrency(currencyCode);
    },
  );
}
