import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from 'containers/App';

import './index.css';
import 'services/UrlControl/behaviour';

import * as serviceWorker from './serviceWorker';
import configStore from './store';

// Workaround for PBIB-6624, can be removed after sandbox 4.52.0-rc.18 or later is merged in
//   This technically prevents reverify from working, but
//   1) SessionExpiration should already make it exceedingly unlikely to be able to trigger it
//   2) If it is triggered, it anyway only works for users who didn't log in via login app
//
//  So in a very rare scenario, it could make the difference between a session continued and a session aborted
//  ...except even in that case, while reverify might be able to save the session, the user is still forced to log out by the non-dismissable confirmation popup
//     so really it doesn't make a difference at all
//
// if (version < 4.52.0-rc.18) {

localStorage.removeItem('REDUX-password');
localStorage.removeItem('REDUX-pin');
// }

// There better be a very good reason to export this
// Otherwise, consume it from the standard sources like hooks, actions, or selectors
const store = configStore();
const rootEl = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl,
);
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./containers/App').default;
    ReactDOM.render(
      <Provider store={store}>
        <NextApp />
      </Provider>,
      rootEl,
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV === 'development') {
  serviceWorker.unregister();
} else if (process.env.NODE_ENV === 'production') {
  serviceWorker.register();
}
