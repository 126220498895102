import * as R from 'ramda';

import { KeysOfType } from 'utils';

type SettingSpec<T = any> = {
  defaultValue: T;
  stringify: (value: T) => string;
  parse: (value: number | string) => T;
  validate: (v: T | string) => v is T;
};

const common = {
  /**
   * Bool type, previously stored in the API as either
   * true|false
   * "1"|"0"
   * 1|0
   * true|false
   *
   * Now stored strictly as "1"|"0" for legacy compatibility,
   * but parsed by all the possible old formats
   */
  bool: (defaultValue = false) => ({
    defaultValue,
    stringify: (t: boolean) => (t ? '1' : '0'),
    parse: (t): boolean => {
      switch (t) {
        case 0:
        case '0':
        case false:
        case 'false':
          return false;
        case 1:
        case '1':
        case true:
        case 'true':
          return true;
        default:
          return !!t;
      }
    },
    validate: (v: boolean | string): v is boolean => typeof v === 'boolean',
  }),
  /** Plain string, no special behaviour */
  string: (defaultValue = '') => ({
    defaultValue,
    stringify: (t: string) => t,
    parse: (t: number | string) => String(t),
    validate: (v): v is string => true,
  }),
  /** String value with fixed number of options */
  enum: <T>(defaultValue: T) => ({
    defaultValue,
    stringify: (t: T) => t,
    parse: (t: number | string) => (t as any) as T,
    validate: (v): v is T => true,
  }),
  /** Regular number, no special behaviour, just conversion */
  number: (defaultValue: number) => ({
    defaultValue,
    stringify: (t: number) => String(t),
    parse: (t: number | string) => Number(t),
    validate: (v): v is number => typeof v === 'number',
  }),
  /** JSON object of the given shape - read via JSON.parse and written via JSON.stringify */
  json: <T>(defaultValue: T) => ({
    defaultValue,
    stringify: (t: T) => JSON.stringify(t),
    parse: (t: number | string) => {
      try {
        return JSON.parse(String(t)) as T;
      } catch (e) {
        return defaultValue;
      }
    },
    validate: (v): v is T => typeof v === 'object',
  }),
  /**
   * Meta-settings. These should (can?) not be saved, but are generated by the API from other data
   *
   * for example, locale_uses_price_with_tax is generated as true or false based on the account region
   */
  readonly: <T>(defaultValue: T) => ({
    defaultValue,
    stringify: (t: T): string => {
      throw new Error('Readonly setting, not to be saved');
    },
    parse: (t: number | string) => (t as unknown) as T,
    validate: (v): v is T => true,
  }),
};

function listOf<T>(
  spec: SettingSpec<T>,
  defaultValue: T[],
  delimiter = ',',
  allowEmpty = true,
): SettingSpec<T[]> {
  return {
    defaultValue,
    stringify: v => v.map(spec.stringify).join(delimiter),

    parse: s =>
      String(s)
        .split(delimiter)
        .map(spec.parse),

    validate: (v): v is T[] => {
      if (typeof v === 'string') {
        return false;
      }
      if (!allowEmpty && v.length === 1 && v[0] === spec.parse('')) {
        return false;
      }
      return true;
    },
  };
}

/* eslint-disable @typescript-eslint/camelcase */
const settingsSpec = {
  use_default_language: common.bool(true),
  'reset-localCampaignsDB': common.number(0),
  'reset-localCouponsDB': common.number(0),
  brazil_2024_cert_rollout: common.bool(false),
  'reset-localEmployeesDB': common.number(0),
  'reset-localPricelistsDB': common.number(0),
  'reset-localProductsDB': common.number(0),
  /** swedbank settings - these should be stored in CAFA */
  'terminalIP-swedbank': common.string(),
  /** swedbank settings - these should be stored in CAFA */
  'terminalPort-swedbank': common.string(),
  allowCreateInvForContactPerson: common.bool(true),
  /** True if it is allowed to have positive quantities on return documents, false otherwise */
  allow_exchange_products_on_return: common.bool(true),
  allow_fallback_to_external_integration: common.bool(false),
  allow_fractional_product_quantities: common.bool(true),
  allow_price_editing_for_exchanges_and_returns: common.bool(false),
  append_gift_card_number_to_product_name: common.bool(false),
  barcode_code_length: common.number(0),
  barcode_measurement_length: common.number(0),
  barcode_price_prefix: common.string(''),
  barcode_type: common.enum<'weight' | 'price' | ''>(''),
  barcode_weight_decimals: common.number(0),
  barcode_weight_prefix: common.string(''),
  brazil_allow_overcharge: common.bool(true),
  brazil_disable_create_company: common.bool(false),
  brazil_enable_save_state: common.bool(false),
  brazil_use_local_product_db: common.bool(true),
  cash_inout_open_drawer: common.bool(false),
  closing_day_notes_mandatory: common.bool(false),
  copy_creator_to_linked_sales: common.bool(false),
  currentPrinterIntegration: common.enum<'Star' | 'Epson' | 'Bixolon'>('Star'),
  customer_search_min_length: common.number(0),
  customer_search_results_configuration: common.json({
    name: 1,
    email: 1,
    retail_name: 1,
    retail_email: 1,
    retail_phone: 1,
    retail_cardNr: 1,
    customerID: 0,
    retail_customerID: 0,
    retail_mobile: 0,
    retail_postalCode: 0,
    phone: 0,
    cardNr: 0,
    mobile: 0,
    postalCode: 0,
    address: 0,
    retail_address: 0,
  }),
  dateformat: common.string('d-m-Y'),
  day_startend_open_drawer: common.bool(false),
  /**
   * Unknown: Based on usage, it looks like this has to be set to the value '%012d'
   * Not sure why there's a setting with only one legal value - perhaps this is used by a different application?
   */
  db_idFormat: common.string(),
  default_currency: common.string(''),
  detailed_layaways: common.bool(false),
  detailed_pending_sales: common.bool(false),
  disableCashBack: common.bool(false),
  /** Default identifier to print on the receipt (determines value of `employeeIdentifier` in patchscript dataset */
  employee_identifier_on_receipt: common.enum<
    '' | 'first_name' | 'first_name_and_initial' | 'id' | 'full_name'
  >(''),
  enabled_custom_payments: common.json<any>({}),
  giftcard_period_of_validity: common.bool(false),
  givex_payments: common.number(0),
  hide_negative_discounts: common.bool(false),
  hide_offline_inventory_for_stock_lookup: common.bool(false),
  invoiceExtraFooterLine: common.string(''),
  invoice_mail_template: common.string(''),
  locale_uses_price_with_tax: common.readonly<boolean>(false),
  mail_subject_contains_company_name: common.bool(true),
  /** Number format - first character is decimal separator, second character is group separator */
  numberformat: common.string('. '),
  overwrite_money_decimals: common.string(''),
  pos_allow_return_receipt_original_tender_only: common.bool(false),
  pos_allow_selling_only_from_pricelist1: common.bool(false),
  pos_brazil_allow_scanning_products_from_order_qty_field: common.bool(false),
  pos_brazil_do_not_calculate_shopping_cart_on_change: common.bool(false),
  pos_brazil_enabled_version_control: common.bool(false),
  pos_brazil_show_current_document_type_above_cart: common.bool(false),
  pos_brazil_show_expected_change_in_payment_modal: common.bool(false),
  pos_brazil_use_customer_creation_beta: common.bool(false),
  pos_cart_rows_to_bottom: common.bool(false),
  pos_custom_extra_card_payment_types: common.string(''),
  pos_customer_search_filter: common.enum<string>('disabled'),
  pos_currency_units: common.string(''),
  pos_disable_generate_number_button: common.bool(false),
  pos_use_serial_gift_card_payment_amount_input: common.bool(true),
  pos_expand_product_button: common.enum<string>('disabled'),
  pos_images_source: common.enum<'erply' | 'cdn' | 'both'>('erply'),
  pos_external_extra_card_payment_types: common.string(''),
  pos_focus_amount_for_last_item_in_shopping_cart: common.bool(false),
  pos_hide_add_credit_store_from_customer_view: common.bool(false),
  pos_hide_price_list_from_discounts: common.bool(false),
  pos_product_quick_notes: listOf(common.string(''), [], '|noteEnd|'),
  pos_product_quick_notes_preview_count: common.number(6),
  pos_round_algo: common.enum<
    | 'true,+inf'
    | 'false,+inf'
    | 'true,-inf'
    | 'false,-inf'
    | 'true,inf'
    | 'false,inf'
    | 'true,0'
    | 'false,0'
    | 'true,even'
    | 'false,even'
    | 'true,odd'
    | 'false,odd'
  >('false,-inf'),
  pos_round_cash_to_nearest_x: common.number(0),
  invoice_rounding: common.number(0),
  pos_serial_giftcard_pattern: common.string(''),
  /** Don't know what the actual default value is - edit this typedef if you find out! */
  pos_shifts_counted: common.enum<'BY_DRAWER' | 'default'>('default'),
  pos_show_prices_in_cart_with_quantity: common.bool(false),
  pos_timeout: common.number(0),
  pos_timeout_config: common.json({
    inputs: false,
    mousemove: false,
    openCloseDay: true,
    stockLevels: true,
    zReport: true,
    customerSelected: true,
    productsInCart: true,
    browsingSales: false,
    creatingCustomer: true,
  }),
  pos_use_giftcards_with_serial_numbers: common.bool(false),
  printer_go_ms_width: common.number(48),
  product_code_unique: common.bool(true),
  receiptLogoURL: common.string(''),
  receipt_footer: common.string(''),
  sale_extra_note1: common.string(''),
  sale_extra_note2: common.string(''),
  sale_extra_note3: common.string(''),
  sale_extra_note1_required: common.bool(false),
  sale_extra_note2_required: common.bool(false),
  sale_extra_note3_required: common.bool(false),
  search_product_by_all_codes: common.bool(false),
  send_emails_from_company_address: common.bool(true),
  serial_name_title: common.string('SN'),
  /** Configured serial number length. 0 if turned off */
  serial_number_length: common.number(0),
  serial_number_operator: common.enum<'minLength' | 'equals' | 'maxLength'>(
    'minLength',
  ),
  showConfirmationOnOpenDay: common.bool(false),
  show_change_on_confirmation: common.bool(false),
  show_customer_store_credit_balance_on_receipt: common.bool(false),
  show_invoice_no_on_confirmation: common.bool(false),
  show_notes_on_main_receipt: common.bool(false),
  show_original_price_on_main_receipt: common.bool(false),
  show_tax_rate_instead_of_name: common.bool(false),
  single_product_result_adds_to_shopping_cart: common.bool(false),
  skip_age_verification: common.bool(false),
  sync_terminal_batch_to_day_openings: common.bool(true),
  text_around_serial_number: common.string('(,)'),
  timezone: common.string(''),
  total_discount_label: common.string('You saved'),
  touchpos_allow_editing_existing_layaways: common.bool(true),
  /**
   * The `layaway_cancellation_fee_` settings determine behaviour of an extra fee during layaway cancellation.
   * When the percentage is non-zero, the user will be changed a cancellation based on the
   * {@link settingsSpec.layaway_cancellation_fee_percentage} and {@link settingsSpec.layaway_cancellation_fee_type}
   *
   * After payment, the cancellation charge is recorded as a positive payment of {@link settingsSpec.layaway_cancellation_fee_payment_type|_payment_type}
   * @example
   * // Charges 10% of the sale total during cancellation, recording that as the payment with typeID='12'
   * {
   *   layaway_cancellation_fee_percentage: 10,
   *   layaway_cancellation_type: 'total',
   *   layaway_cancellation_payment_type: '12'
   * }
   * @see settingsSpec.layaway_cancellation_fee_percentage
   * @see settingsSpec.layaway_cancellation_fee_type
   * @see settingsSpec.layaway_cancellation_fee_payment_type
   */
  layaway_cancellation_fee_percentage: common.number(0),
  // Now-deprecated plugin had default payment type as -1, which in core translates to 'CANCELFEE'
  layaway_cancellation_fee_payment_type: common.string('-1'),
  // Layaway cancellation fee plugin has default type as 'paid', thus here it's the same
  layaway_cancellation_fee_type: common.enum<'total' | 'paid' | 'unpaid'>(
    'paid',
  ),
  touchpos_allow_offline_login: common.bool(false),
  touchpos_allow_offline_mode: common.bool(false),
  touchpos_always_item_in_new_row: common.bool(false),
  touchpos_always_open_drawer_after_sale: common.bool(false),
  touchpos_autoclose_checkout_final_confirmaton_popup: common.bool(false),
  touchpos_autoclose_line_after_discount_selected: common.bool(false),
  touchpos_autoidle_after_sale: common.bool(false),
  touchpos_check_only_cash: common.bool(false),
  touchpos_choose_employee_manually: common.bool(false),
  touchpos_close_after_print: common.bool(false),
  touchpos_close_to_minimum_stock_confirmation: common.bool(false),
  touchpos_custom_css: common.string(''),
  touchpos_custom_discount_percentages: common.string(''),
  touchpos_default_layby_percent: common.number(0),
  touchpos_default_send_receipt_to_email_addresses: common.string(''),
  touchpos_disable_default_print_the_receipt: common.bool(false),
  touchpos_disable_deposit_on_eod: common.bool(false),
  touchpos_disable_product_added_notification: common.bool(false),
  touchpos_disable_product_image_display: common.bool(false),
  touchpos_disable_product_row_auto_open: common.bool(false),
  touchpos_disable_related_products_popup: common.bool(false),
  touchpos_disable_selling_to_default_customer: common.bool(false),
  touchpos_disable_unused_coupons_popup: common.bool(false),
  touchpos_disable_zero_price_auto_open: common.bool(false),
  touchpos_display_code_during_scanning: common.bool(false),
  touchpos_display_id_in_customer_details: common.bool(false),
  touchpos_display_notes_popup: common.bool(false),
  touchpos_enable_check_extra_fields: common.bool(false),
  touchpos_enable_custom_payments: common.bool(false),
  touchpos_enable_edit_name_in_order: common.bool(false),
  touchpos_eod_allowed_difference: common.string(''),
  touchpos_eod_disable_count_all_payment_types: common.bool(false),
  touchpos_eod_disable_show_expected: common.bool(false),
  touchpos_eod_fill_counted: common.bool(true),
  touchpos_eod_notes_required: common.bool(false),
  touchpos_eod_reason_required: common.bool(false),
  touchpos_fetch_only_products_in_stock: common.bool(true),
  touchpos_filter_products_button: common.bool(false),
  touchpos_get_report_only_last_shift: common.bool(false),
  touchpos_get_short_report: common.bool(false),
  touchpos_group_items_in_row_by: common.enum<'consecutive' | 'any'>(
    'consecutive',
  ),
  touchpos_group_products_in_shopping_cart: common.bool(false),
  touchpos_hide_employee_stats: common.bool(false),
  touchpos_hide_employees_from_other_location: common.bool(false),
  touchpos_hide_xreport_on_end_day: common.bool(false),
  touchpos_invoices_only_from_current_location: common.bool(false),
  touchpos_javascript_plugin_mandatory: common.bool(false),
  touchpos_javascript_plugin_path: common.string(''),
  touchpos_laybys_only_from_current_location: common.bool(false),
  touchpos_no_container_fee_on_return: common.bool(false),
  touchpos_non_discountable_products: common.bool(false),
  touchpos_offers_only_from_current_location: common.bool(false),
  touchpos_order_products_by_in_product_group: common.enum<
    'name' | 'code' | 'productID' | 'price' | 'changed' | 'added'
  >('changed'),
  touchpos_order_products_direction_in_product_group: common.enum<
    'asc' | 'desc'
  >('asc'),
  touchpos_orders_only_from_current_location: common.bool(false),
  touchpos_out_of_stock_warning: common.enum<string>(''),
  touchpos_print_cash_in_out_receipt: common.bool(false),
  touchpos_print_kitchen_bar_receipts: common.bool(false),
  touchpos_print_layaway_two_times: common.bool(false),
  touchpos_prefill_home_store_during_customer_creation: common.bool(false),
  touchpos_recent_sales_only_from_current_location: common.bool(false),
  touchpos_remove_grouped_products_in_shopping_cart: common.bool(false),
  touchpos_remove_pending_sales_before_close_day: common.bool(false),
  touchpos_require_auth_code_on_payments: common.bool(false),
  touchpos_require_card_type_on_external_payment: common.bool(false),
  touchpos_sale_additional_currencies: common.string(''),
  touchpos_search_product_code_from_middle: common.bool(false),
  touchpos_set_printing_language_priority: common.string(
    'eng,est,rus,fre,spa,ger,ita',
  ),
  touchpos_sell_only_matrix_in_stock: common.bool(false),
  touchpos_sort_products_by: common.enum<
    'name' | 'code' | 'productID' | 'price' | 'changed' | 'added'
  >('code'),
  touchpos_show_all_registers: common.bool(false),
  touchpos_show_card_data_on_receipt: common.bool(false),
  touchpos_show_change_on_receipt: common.bool(false),
  touchpos_show_discount_total: common.bool(false),
  touchpos_show_print_attempt_number: common.bool(false),
  touchpos_show_product_code_on_receipt: common.bool(false),
  touchpos_show_total_basket_quantity: common.bool(false),
  touchpos_ui_configuration: common.json<
    Partial<{
      hideCustomerInfo: 0 | 1;
      hideCustomerSearch: 0 | 1;
      hideAddProductOrGroup: 0 | 1;
      hideAddCustomer: 0 | 1;
      hideRecentSales: 0 | 1;
      hidePendingSales: 0 | 1;
      hideOrders: 0 | 1;
      hideLayaways: 0 | 1;
      hideCloseDay: 0 | 1;
      hideXReport: 0 | 1;
      hideCashInOut: 0 | 1;
      hidePriceLookup: 0 | 1;
      hideClockInOut: 0 | 1;
      hideGiftCardBalance: 0 | 1;
      hidePrintCoupons: 0 | 1;
      hideNewSale: 0 | 1;
      hideSaveSale: 0 | 1;
      hideDiscount: 0 | 1;
      hideTaxExcempt: 0 | 1;
      hideNotes: 0 | 1;
      hideLastReceiptPrint: 0 | 1;
      hideOpenCashDrawer: 0 | 1;
      hidePromotions: 0 | 1;
      hideCoupons: 0 | 1;
      hidePrintGiftcardReceipt: 0 | 1;
      hideAddShipping: 0 | 1;
      hideSwitchUser: 0 | 1;
      isCustomerHomeStoreDisabled: 0 | 1;
      hideSaveOrder: 0 | 1;
      hideSaveLayaway: 0 | 1;
      hideWaybills: 0 | 1;
      hideSaleCommission: 0 | 1;
      hideProductCommission: 0 | 1;
      hideSaveAsOffer: 0 | 1;
      hideStockTransfer: 0 | 1;
      hideAccountSales: 0 | 1;
      hideOffers: 0 | 1;
      hideUnpaidInvoices: 0 | 1;
    }>
  >({}),
  touchpos_unfinished_sales_only_from_current_location: common.bool(false),
  touchpos_use_age_verification: common.bool(false),
  touchpos_use_dark_theme: common.bool(false),
  touchpos_warehouse_select: common.bool(false),
  touchpos_show_company_reg_vat_number: common.bool(true),
  truncate_product_search_results_texts: common.bool(false),
  update_document_creator_when_saving_pending_sales: common.bool(false),
  update_document_date_when_saving_pending_sales: common.bool(false),
  use_actual_reports_templates: common.bool(false),
  use_cayan_capture: common.bool(false),
  use_standalone_customer_registry: common.bool(false),
  // TODO: Implement template keys if we ever manage to upgrade to typescript 4
  //   `mailtemplate_inv${docTypeId}_${lang}`
  //   `mailtemplate_default_${lang}`
  //   `invoice_announcement_${lang}`
  //   🟩 `pos_allow_${docType}_${pmtType}`
  //   `pos_allow_${docType}_${pmtType}_limit`
  //   `actual_reports_template_for_${docType.toLowerCase()}` (default 0)
  actual_reports_template_for_invoice: common.number(0),
  actual_reports_template_for_cashinvoice: common.number(0),
  actual_reports_template_for_creditinvoice: common.number(0),
  actual_reports_template_for_prepayment: common.number(0),
  actual_reports_template_for_order: common.number(0),
  actual_reports_template_for_invwaybill: common.number(0),
  actual_reports_template_for_waybill: common.number(0),
  actual_reports_template_for_offer: common.number(0),
  actual_reports_template_for_reservation: common.number(0),
  actual_reports_template_for_giftreceipt: common.number(0),
  pos_allow_sale_cash: common.bool(true),
  pos_allow_sale_card: common.bool(true),
  pos_allow_sale_giftcard_serial: common.bool(true),
  pos_allow_sale_giftcard_regular: common.bool(true),
  pos_allow_sale_storecredit: common.bool(true),
  pos_allow_sale_check: common.bool(true),
  pos_allow_sale_tip: common.bool(true),
  pos_allow_return_receipt_cash: common.bool(true),
  pos_allow_return_receipt_card: common.bool(true),
  pos_allow_return_receipt_giftcard_serial: common.bool(true),
  pos_allow_return_receipt_giftcard_regular: common.bool(true),
  pos_allow_return_receipt_storecredit: common.bool(true),
  pos_allow_return_receipt_check: common.bool(true),
  pos_allow_return_receipt_tip: common.bool(true),
  pos_allow_return_cash: common.bool(true),
  pos_allow_return_card: common.bool(true),
  pos_allow_return_giftcard_serial: common.bool(true),
  pos_allow_return_giftcard_regular: common.bool(true),
  pos_allow_return_storecredit: common.bool(true),
  pos_allow_return_check: common.bool(true),
  pos_allow_return_tip: common.bool(true),
  pos_associations: common.json<{
    /** Ignore setting if disabled */
    enabled: boolean;
    /**
     * If true, prevent unassociated devices from logging in, unless they have config perms
     * If false, then unassociated devices can log into any locations, just like default
     */
    allowOnlyAssociatedDevices: boolean;
    /** Mapping from device ID (from metrics collector MS) to POS ID */
    associations: { [deviceID: string]: number };
  }>({
    enabled: false,
    allowOnlyAssociatedDevices: false,
    associations: {},
  }),
  pos_enable_special_handling_of_gift_returns: common.bool(false),

  /**
   * Units which should prompt the cashier to enter an amount (usually weight)
   *
   * @example
   *  In our code: ['kg','g','lbs']
   *  In API: "kg,g,lbs"
   *
   * @example
   *  In our code: []
   *  In API: ""
   */
  units_for_prompt: {
    defaultValue: [],
    parse: v =>
      String(v)
        .split(',')
        .filter(Boolean),
    stringify: units => {
      if (units.length === 0) return '';
      return units.join(',');
    },
    validate: (v): v is string[] => v?.every?.(s => typeof s === 'string'),
  },
  /* Plugin-specific settings (these should not be settings) */
  touchpos_customer_association_group_id: common.string(''),
  touchpos_customer_proffessional_group_id: common.string(''),
  plugin_dsc_loyalty_membership_base_url: common.string(''),
  plugin_dsc_membership_product_id: common.string(''),
  plugin_reorder_worksheet_api_endpoint: common.string(''),
  plugin_gcr_cash_card_type_id: common.number(0),
  productCodeToShowOnReturnModal: common.string('code'),
  use_dsc_membership_service: common.bool(false),
  capture_check_numbers: common.bool(false),
  /**
   * M&M plugin sends saveInventoryWriteOff requests per each returned row
   * that has a return reason code specified by this setting
   */
  automatically_transfered_reason_code_ids: listOf(common.string(''), [], ','),
  /** M&M plugin uses this setting as warehouseID in saveInventoryWriteOff requests */
  reason_code_transfer_warehouse_id: common.string(''),
  /** M&M plugin uses this setting to hide return reasons */
  hidden_reason_codes: listOf(common.string(''), [], ','),
  // Pseudo settings provided by API but not editable
  additionalModules: common.readonly<{ name: string; enabled: 0 | 1 }[]>([]),
  pos_default_language: common.readonly({
    isoCode: '',
    code: '',
    legacyIdentifier: '',
  }),
  country: common.readonly<string>(''),
  /**
   * The name of a property to use as the 'code' value on a receipt
   * @example "code5"
   */
  code_on_receipt: common.readonly<any>(''),
  /**
   * Account's special URLs for help pages and knowledge bases according to account's reseller and country.
   * Each row (ie. each array element) has the following fields:
   *
   * NB: API docs claim this is an array, but it is not
   */
  account_links: common.readonly<{
    /** Default help URL */
    help: string;
    /** Help URL displayed in Berlin POS */
    helpPOS: string;
    /** URL to Erply terms of Service */
    terms_of_service: string;
    /** URL to Erply knowledge base */
    knowledge_base: string;
  }>({
    help: '',
    helpPOS: '',
    terms_of_service: '',
    knowledge_base: '',
  }),
  default_language: common.string(''),
  // TODO: Move out of settings into separate part of state
  pos_languages: listOf(
    common.json<{ isoCode: string; code: string; legacyIdentifier: string }>({
      isoCode: '',
      code: '',
      legacyIdentifier: '',
    }),
    [],
  ),
  pir_active_reason_codes: listOf(common.string(''), [], ','),
  allowed_types_on_return: listOf(
    common.enum<
      'INVWAYBILL' | 'CASHINVOICE' | 'PREPAYMENT' | 'ORDER' | 'INVOICE'
    >('CASHINVOICE'), // schema for one element
    ['CASHINVOICE', 'INVWAYBILL', 'PREPAYMENT', 'ORDER', 'INVOICE'], // default value for the whole setting (if nothing stored in API or if it fails to parse)
    ',', // Separator to use when converting between string (api) and list (memory)
    false, // Does not allow empty config - treat it as default
  ),
};

type ApiSettings = Partial<
  {
    [K in keyof typeof settingsSpec]: string | number;
  }
>;
export type Settings = {
  [K in KeysOfType<
    typeof settingsSpec,
    SettingSpec<any>
  >]: typeof settingsSpec[K] extends SettingSpec<infer T> ? T : never;
};

/** Default settings, {[key]: value} */
export const defaultSettings: Settings = R.pluck('defaultValue', settingsSpec);

/**
 * Convert settings from API (strings and numbers) to our internal settings type
 *
 */
export const settingsFromApi: (
  settings: Partial<ApiSettings>,
) => Settings = R.pipe(
  R.evolve(R.pluck('parse', settingsSpec)),
  // Create array from dictionary
  Object.entries,
  // Filter through array and check if each element passes the validation
  R.filter(([key, val]) =>
    settingsSpec[key] ? settingsSpec[key].validate(val) : false,
  ),
  // Convert array back into dictionary
  Object.fromEntries,
);
/**
 * Convert settings from our internal settings type to strings for saving to the API
 */
export function settingToApi<K extends keyof Settings>(
  name: K,
  value: Settings[K],
) {
  return (settingsSpec[name] as SettingSpec<Settings[K]>).stringify(value);
}
