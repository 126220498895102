import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Container } from 'react-bootstrap';

import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import { Ctx, Select } from 'containers/Forms/Settings/components/CtxInputs';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getSelectedWarehouse } from 'reducers/warehouses';
import { getEmployees } from 'reducers/cachedItems/employees';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { setOrderEmployeeID } from 'actions/ShoppingCart';
import { addError, addSuccess } from 'actions/Error';
import { Employee } from 'types/Employee';
import { ShoppingCart } from 'types/ShoppingCart';
import './saleCommission.scss';

type Props = {
  employees: Employee[];
  selectedEmployee: Employee | null;
  setSelectedEmployee: React.Dispatch<React.SetStateAction<Employee | null>>;
  children?: never;
};

export const EmployeesList: React.FC<Props> = ({
  employees,
  selectedEmployee,
  setSelectedEmployee,
}) => {
  const currentWarehouse = useSelector(getSelectedWarehouse);

  return (
    <div className="employees-chips-container">
      {employees.map(employee => {
        const employeeIsAssignedToCurrentWarehouse = employee.warehouses.find(
          warehouse => warehouse.id === Number(currentWarehouse.warehouseID),
        );

        if (employeeIsAssignedToCurrentWarehouse) {
          return (
            <Button
              variant="light"
              onClick={() => setSelectedEmployee(employee)}
              className={
                selectedEmployee?.id === employee.id
                  ? 'selected-for-commission'
                  : ''
              }
              key={employee.id}
              style={{ marginRight: '15px' }}
              data-testid="employee-btn"
              data-test-key={employee.id}
            >
              {employee.fullName}
            </Button>
          );
        }
        return null;
      })}
    </div>
  );
};

const SaleCommission: React.FC<{ children?: never; onClose: () => void }> = ({
  onClose,
}) => {
  const { t } = useTranslation('sale');
  const dispatch = useDispatch();

  const singleItemCommission = 'SINGLE';
  const allItemsCommission = 'All';

  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const [selectedItemOrderIndex, setSelectedItemOrderIndex] = useState(0);
  const [singleOrFullCommission, setSingleOrFullCommission] = useState('');

  const employees = Object.values(useSelector(getEmployees) || {});
  const productsInShoppingCart = useSelector(getProductsInShoppingCart);

  const generateProductsOptions = (products: ShoppingCart) => {
    const finalValues = [{ name: t('saleCommission.defaultOption'), value: 0 }];

    products.forEach(product => {
      if (product.orderIndex) {
        finalValues.push({
          name: product.name,
          value: Number(product.orderIndex),
        });
      }
    });

    return finalValues;
  };

  const resetCommissionProcess = () => {
    setSelectedEmployee(null);
    setSelectedItemOrderIndex(0);
  };

  const startCommissionProcess = (singleOrFullCommission: string) => {
    if (productsInShoppingCart.length === 0) {
      resetCommissionProcess();
      return dispatch(addError(t('saleCommission.alerts.emptyCart')));
    }

    setSelectedEmployee(null);
    return setSingleOrFullCommission(singleOrFullCommission);
  };

  const assignEmployeeIDToItems = async () => {
    if (productsInShoppingCart.length === 0) {
      resetCommissionProcess();
      return dispatch(addError(t('saleCommission.alerts.emptyCart')));
    }

    try {
      if (
        singleOrFullCommission === allItemsCommission &&
        selectedEmployee?.id
      ) {
        productsInShoppingCart.forEach(product =>
          dispatch(
            setOrderEmployeeID(String(selectedEmployee.id), product.orderIndex),
          ),
        );
      }

      if (
        singleOrFullCommission === singleItemCommission &&
        selectedEmployee?.id
      ) {
        await dispatch(
          setOrderEmployeeID(
            String(selectedEmployee.id),
            selectedItemOrderIndex,
          ),
        );
      }

      resetCommissionProcess();
      return dispatch(
        addSuccess(t('saleCommission.alerts.successfulAssignment')),
      );
    } catch (error) {
      console.error('Failed to assign commission to items', error);
      return dispatch(
        addError(t('saleCommission.alerts.assignmentUnsuccessful')),
      );
    }
  };

  return (
    <div data-testid="sale-commision-modal">
      <Modal.Header>
        <span style={{ fontSize: '1.75em', fontWeight: 700 }}>
          {t('saleCommission.title')}
        </span>
        <span style={{ flexGrow: 1 }} />
        <CloseButton action={onClose} />
      </Modal.Header>

      <Modal.Body>
        <div id="sale-commission-container">
          <p>{t('saleCommission.assignCommission')}:</p>

          <Container className="ctas-container">
            <Button
              variant={
                singleOrFullCommission === allItemsCommission
                  ? 'info'
                  : 'outline-info'
              }
              onClick={() => {
                startCommissionProcess(allItemsCommission);
              }}
              data-testid="all-items-btn"
            >
              {t('saleCommission.allItems')}
            </Button>
            <Button
              variant={
                singleOrFullCommission === singleItemCommission
                  ? 'info'
                  : 'outline-info'
              }
              onClick={() => {
                startCommissionProcess(singleItemCommission);
              }}
              data-testid="single-item-btn"
            >
              {t('saleCommission.singleItem')}
            </Button>

            <div className="conditional-buttons-container">
              {selectedEmployee ? (
                <Button
                  className="confirm-commission"
                  onClick={assignEmployeeIDToItems}
                  data-testid="confirm-commission-btn"
                >
                  {t('saleCommission.confirmCommission')}: <br />
                  <strong>{selectedEmployee.fullName} </strong>
                </Button>
              ) : null}
            </div>
          </Container>

          {singleOrFullCommission ? (
            <div
              data-testid="sale-commission-employees"
              id="sale-commission-employees-container"
            >
              {singleOrFullCommission === singleItemCommission ? (
                <Container className="select-product-container">
                  <p>{t('saleCommission.selectItem')}:</p>
                  <Ctx.Provider
                    value={{
                      values: {
                        selectedItemOrderIndex,
                      },
                      onChange: setSelectedItemOrderIndex,
                    }}
                  >
                    <Select
                      title={t('saleCommission.inputTitle')}
                      prependTitle
                      name="selectedItemOrderIndex"
                      options={generateProductsOptions(productsInShoppingCart)}
                      className={classNames([
                        formStyles.formInput,
                        formStyles.mediumTitle,
                        'select-sale-item-input',
                      ])}
                      onChange={(name, value) =>
                        setSelectedItemOrderIndex(Number(value))
                      }
                    />
                  </Ctx.Provider>
                </Container>
              ) : null}

              {singleOrFullCommission === allItemsCommission ? (
                <>
                  <p>{t('saleCommission.selectEmployeeFullSale')}:</p>
                  <EmployeesList
                    employees={employees}
                    selectedEmployee={selectedEmployee}
                    setSelectedEmployee={setSelectedEmployee}
                  />
                </>
              ) : null}

              {singleOrFullCommission === singleItemCommission &&
              selectedItemOrderIndex !== 0 ? (
                <>
                  <p>{t('saleCommission.selectEmployeeSingleItem')}:</p>
                  <EmployeesList
                    employees={employees}
                    selectedEmployee={selectedEmployee}
                    setSelectedEmployee={setSelectedEmployee}
                  />
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </Modal.Body>
    </div>
  );
};

export default SaleCommission;
