import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import {
  getCustomer,
  getIsCheckAndGiftCardMenuSelected,
} from 'reducers/Payments';
import { getCheckBehavior } from 'reducers/configs/settings';
import QuestionMark from 'components/QuestionMark';
import Currencies from 'containers/Forms/Payment/components/AdditionalCurrency';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';

import { PaymentActions, PaymentContent } from '../../components';

import './PaymentDesktop.module.scss';

type Props = {
  voidHandler: () => void;
};

const PaymentDesktop: FC<Props> = ({ voidHandler }) => {
  const { t } = useTranslation('payment');

  const checkBehaviour = useSelector(getCheckBehavior);

  const {
    companyName,
    firstName,
    lastName,
    availableCredit,
    fullName,
  } = useSelector(getCustomer);

  const useStoreCredit = availableCredit && availableCredit > 0;
  const credit = useStoreCredit && availableCredit.toFixed(2);

  const alertBeforeRefresh = e => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertBeforeRefresh);
    return () => {
      window.removeEventListener('beforeunload', alertBeforeRefresh);
    };
  }, []);

  const getName = () => {
    if (companyName) return companyName;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return fullName;
  };

  const name = getName();

  const title = t('title', {
    context: useStoreCredit ? 'credit' : undefined,
    name,
    credit,
  });

  const dispatch = useDispatch();
  const exitGiftcard = () => dispatch(setPaymentSelected(''));

  const { isGiftCardMenuSelected, isCheckMenuSelected } = useSelector(
    getIsCheckAndGiftCardMenuSelected,
  );

  return (
    <div data-testid="payment-desktop" className="payment-desktop">
      <Modal.Header>
        <Modal.Title>
          <span data-testid="payment-desktop-title" className="h4">
            {title}
            <Currencies />
          </span>
          <QuestionMark
            className="mt-2 ml-2 py-0 px-2"
            color="#444"
            code={1200}
          />
        </Modal.Title>
        <CloseButton
          action={isGiftCardMenuSelected ? exitGiftcard : voidHandler}
        />
      </Modal.Header>

      <Modal.Body>
        <Container>
          <Row className="no-gutters">
            <PaymentContent />
          </Row>
        </Container>
      </Modal.Body>
      {!(checkBehaviour === 'OLD' && isCheckMenuSelected) && (
        <Modal.Footer className="flex-column">
          <PaymentActions voidHandler={voidHandler} />
        </Modal.Footer>
      )}
    </div>
  );
};

export default PaymentDesktop;
