import { useEffect, useState } from 'react';
import { renameKeys } from 'ramda-adjunct';
import * as R from 'ramda';

import { requestCompleteResponse } from 'services/ErplyAPI/core/ErplyAPI';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI2';

export function createUseErplyRequest(requestName, transformer) {
  return function useErplyRequest(defaultValue) {
    const [value, setValue] = useState<typeof defaultValue>([defaultValue]);
    useEffect(() => {
      requestCompleteResponse({
        request: requestName,
        recordsOnPage: 1,
      })
        .then(({ status: { recordsTotal } }) =>
          Promise.all(
            Array(Math.ceil(recordsTotal / 100))
              .fill(0)
              .map((z, i) => i + 1)
              .map(pageNo =>
                doClientRequest({
                  request: requestName,
                  recordsOnPage: 100,
                  pageNo,
                }),
              ),
          ),
        )
        .then(res => res.flatMap(a => a.records))
        .then(transformer)
        .then(res => setValue(res));
    }, []);
    return value;
  };
}

export const sortCountriesByName = R.pipe(
  R.map(
    renameKeys({
      countryID: 'value',
      countryName: 'name',
      countryCode: 'code',
    }),
  ),
  R.sortBy(R.prop('name')),
);
