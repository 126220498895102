import {
  REDUX_CLIENTCODE,
  REDUX_SESSIONKEY,
  REDUX_POSID,
} from 'constants/persistence';
import { proxy } from 'services/shared';
import { ErplyAttributes, ErplyLongAttributes } from 'utils';

const partnerKey = process.env.REACT_APP_PARTNER_KEY;

const urlEncode = dict =>
  Object.entries(dict)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

class RequestHandler {
  constructor(mode) {
    this.responseMode = mode;
  }

  static handlers = {};

  static getHandlerForLangAndMode(lang, mode) {
    if (!RequestHandler.handlers[lang]) {
      RequestHandler.handlers[lang] = {};
    }
    if (!RequestHandler.handlers[lang][mode]) {
      RequestHandler.handlers[lang][mode] = new RequestHandler(mode);
    }
    return RequestHandler.handlers[lang][mode];
  }

  requests = [];

  handled = [];

  handleRequests() {
    this.handled.push(this.requests.length);
    if (this.requests.length === 1) {
      this.handleOneRequest(this.requests.pop());
    } else if (this.requests.length > 1) {
      this.handleBulkRequest([...this.requests]);
      this.requests = [];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleOneRequest({ params, resolve, reject }) {
    const sessionKey =
      params.sessionKey ?? JSON.parse(localStorage.getItem(REDUX_SESSIONKEY));
    const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE));
    const posID = JSON.parse(localStorage.getItem(REDUX_POSID));

    window
      .fetch(`${proxy}https://${clientCode}.erply.com/api/`, {
        method: 'POST',
        body: urlEncode({
          ...params,
          partnerKey,
          sessionKey,
          clientCode,
          ...(posID ? { posID } : {}),
        }),
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      })
      .then(data => data.json())
      .then(resolve, reject);
  }

  handleBulkRequest(batch) {
    const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY));
    const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE));
    const posID = JSON.parse(localStorage.getItem(REDUX_POSID));
    window
      .fetch(`${proxy}https://${clientCode}.erply.com/api/`, {
        method: 'POST',
        body: urlEncode({
          partnerKey,
          sessionKey,
          clientCode,
          ...(posID ? { posID } : {}),
          responseMode: this.responseMode ?? '',
          requests: JSON.stringify(
            batch.map(({ params: { request: requestName, ...rest } }) => ({
              requestName,
              clientCode,
              sessionKey, // Usually not needed inside the request itself, but is needed for switchUser
              ...rest,
            })),
          ),
        }),
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      })
      .then(data => data.json())
      .then(json => {
        batch.forEach(({ resolve }, i) => {
          resolve(json.requests[i]);
        });
      })
      .catch(error => {
        console.error('Error in bulk request', { batch }, error);
        batch.map(({ reject }) => reject(error));
      });
  }

  enqueueRequest(params) {
    if (params.attributes) {
      Object.assign(
        params,
        new ErplyAttributes(params.attributes).merge(
          ErplyAttributes.fromFlatArray(params),
        ).asFlatArray,
      );
      // eslint-disable-next-line no-param-reassign
      delete params.attributes;
    }
    if (params.longAttributes) {
      Object.assign(
        params,
        new ErplyLongAttributes(params.longAttributes).merge(
          ErplyLongAttributes.fromFlatArray(params),
        ).asFlatArray,
      );
      // eslint-disable-next-line no-param-reassign
      delete params.longAttributes;
    }
    const promise = new Promise((resolve, reject) =>
      this.requests.push({ params, resolve, reject }),
    ).then(res => {
      // saveConfParameter returns records as null
      // getReports returns records as a dict
      /* eslint-disable no-unused-expressions */
      res.records?.forEach?.(record => {
        if (record.attributes) {
          // eslint-disable-next-line no-param-reassign
          record.attributes = new ErplyAttributes(record.attributes).asDict;
        }
      });
      return res;
    });
    if (this.requests.length === 1) {
      setTimeout(this.handleRequests.bind(this), 0);
    }
    return promise;
  }
}

window.getSavings = () =>
  Object.entries(RequestHandler.handlers).forEach(([lang, map]) => {
    Object.entries(map).forEach(([mode, handler]) => {
      const reqs = handler.handled;
      const totalReqs = reqs?.reduce((a, b) => a + b, 0) ?? 0;
      const totalCost = reqs
        .map(n => Math.ceil(n / 10))
        .reduce((a, b) => a + b, 0);

      // Debug-only function, therefore okay to use console
      // eslint-disable-next-line no-console
      console.log(`=== Savings for lang:${lang} ===`);
      // eslint-disable-next-line no-console
      console.log(`Total requests: ${totalReqs}`);
      // eslint-disable-next-line no-console
      console.log(`Actual requests: ${reqs.length}`);
      // eslint-disable-next-line no-console
      console.log(`Request cost: ${totalCost}`);
      // eslint-disable-next-line no-console
      console.log(`Savings: ${(1 - totalCost / totalReqs) * 100}%`);
    });
  });

// eslint-disable-next-line import/prefer-default-export
export const doClientRequest = params =>
  RequestHandler.getHandlerForLangAndMode(
    params.lang,
    params.responseMode,
  ).enqueueRequest(params);
