import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';

import { getGroupOptionsWithNameHierarchy } from 'containers/Forms/Customers/views/CreateCustomerBeta/utils';
import { getSearchCustomerGroups } from 'reducers/customerSearch';
import { getAllHomeStores } from 'reducers/warehouses';
import countries from 'containers/Login/countries.json';

const commonProps: TextFieldProps = {
  fullWidth: true,
};

interface Props {
  value: string | number;
  onChange: (val: string | number) => void;
  label?: string;
}

export function CustomerTypeField({ value, onChange, label }: Props) {
  return (
    <TextField
      {...commonProps}
      label={label}
      select
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
    >
      <MenuItem value="">Select option</MenuItem>
      <MenuItem value="DOMESTIC">DOMESTIC</MenuItem>
      <MenuItem value="EU">EU</MenuItem>
      <MenuItem value="OUTSIDE_EU">OUTSIDE_EU</MenuItem>
    </TextField>
  );
}

export function GroupField({ value, onChange, label }: Props) {
  const customerGroups = useSelector(getSearchCustomerGroups);
  const groupOptionsWithNameHierarchy: any[] = useMemo(
    () => getGroupOptionsWithNameHierarchy(customerGroups),
    [customerGroups],
  );
  return (
    <TextField
      {...commonProps}
      label={label}
      select
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
    >
      <MenuItem value="">Select option</MenuItem>
      {groupOptionsWithNameHierarchy.map(({ name, value }) => (
        <MenuItem key={value} value={value}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
}

export const HomeStoreField = ({
  value,
  onChange,
  label = '',
}: {
  value: string | number | null | undefined;
  onChange: (val: string | number | null) => void;
  label: string;
}) => {
  const homeStores = useSelector(getAllHomeStores);
  return (
    <Autocomplete
      disablePortal
      id="homeStoreID"
      options={homeStores.map(store => store.warehouseID)}
      renderInput={params => <TextField {...params} label={label} fullWidth />}
      getOptionLabel={option => {
        const selectedStore = homeStores.find(
          store => store.warehouseID === option,
        );
        return selectedStore ? selectedStore.name : '';
      }}
      onChange={(e, newValue) => onChange(newValue)}
      value={value}
    />
  );
};

export const CountryField = ({
  value,
  onChange,
  label = '',
}: {
  value: string | number | null | undefined;
  onChange: (val: string | number | null) => void;
  label: string;
}) => {
  const options = countries.map(c => c.name);
  return (
    <Autocomplete
      disablePortal
      id="country"
      options={options}
      renderInput={params => <TextField {...params} label={label} fullWidth />}
      onChange={(e, newValue) => onChange(newValue)}
      value={value}
    />
  );
}

export function CheckField({ value, onChange, label }: Props) {
  return (
    <TextField
      {...commonProps}
      label={label}
      select
      value={value ?? ''}
      onChange={e => onChange(e.target.value)}
    >
      <MenuItem value="">Select option</MenuItem>
      <MenuItem value={1}>Checked</MenuItem>
      <MenuItem value={0}>Unchecked</MenuItem>
    </TextField>
  );
}
