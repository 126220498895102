import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';

import * as api from 'services/ErplyAPI/customers';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { addError, addSuccess } from 'actions/Error';
import * as customerActionTypes from 'constants/customerSearch';
import './MountUnmountCard.scss';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import { ErplyLongAttributes } from 'utils';
import { Customer } from 'types/Customer';

import { Card, CardsTableProps, MessageProps, TableHeaderProps } from './types';
import { mountOrUnmountCard, removeCard } from './utils';

type CustomerProps = { props: Customer };

const TableHeader = ({ onSave, shouldShowSaveBtn }: TableHeaderProps) => {
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  const { t } = useTranslation('customer');
  return (
    <Box alignItems="center" borderBottom="1px solid">
      <div className="header">
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          {t('mountCardsModal.title')}
        </Typography>
        <Box className="headerBtns">
          {shouldShowSaveBtn && (
            <SaveButton data-testid="save-btn" action={() => onSave()} />
          )}
          <CloseButton
            data-testid="close-btn"
            action={() => dispatch(previousModalPage())}
          />
        </Box>
      </div>
    </Box>
  );
};

const CardsTable = ({
  cards,
  onRemoveCard,
  onMountOrUnmountCard,
}: CardsTableProps) => {
  const { t } = useTranslation('customer');
  return (
    <TableContainer className="tableContainer">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">
              {t('mountCardsModal.cardNbr')}
            </TableCell>
            <TableCell className="tableCell">
              {t('mountCardsModal.status')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {cards.map(card => (
            <TableRow key={card.cardNumber}>
              <TableCell>{card.cardNumber}</TableCell>
              <TableCell>
                {(card.mounted
                  ? t('mountCardsModal.mounted')
                  : t('mountCardsModal.unmounted')
                ).toUpperCase()}
              </TableCell>
              <TableCell className="cellBtn">
                <Button
                  color="primary"
                  variant="contained"
                  className="mountBtn"
                  onClick={() => onMountOrUnmountCard(card.cardNumber)}
                  data-testid="mount-or-unmount-btn"
                >
                  {card.mounted
                    ? t('mountCardsModal.unmountBtn')
                    : t('mountCardsModal.mountBtn')}
                </Button>
                <Box>
                  <Icon
                    key="icon_trash_alt"
                    name="icon_trash_alt"
                    title="Remove card"
                    action={() => onRemoveCard(card.cardNumber)}
                    data-testid="remove-card"
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Message = ({ text, children }: MessageProps) => (
  <Box className="verticalCenteredBox">
    <Box p={2}>
      <Typography variant="h6">{text}</Typography>
    </Box>
    {children}
  </Box>
);
export default function MountUnmountCard({
  props: { customerID },
}: CustomerProps) {
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  const [cards, setCards] = useState<Card[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const { t } = useTranslation('customer');
  const areCardsAvailable = cards.length > 0;

  const { loading: isLoading, value: savedCards, error } = useAsync(
    () =>
      api.getCustomers({ customerID }).then(([data]) => {
        return JSON.parse(
          new ErplyLongAttributes(data.longAttributes).get('cardToken'),
        );
      }),
    [customerID],
  );

  useEffect(() => {
    setCards(savedCards ?? []);
  }, [savedCards]);

  const onRemoveCard = (cardNbr: string) => {
    const updatedCards = removeCard(cards, cardNbr);
    setHasChanges(true);
    setCards(updatedCards);
  };

  const onMountOrUnmountCard = (cardNbr: string) => {
    setHasChanges(true);
    setCards(prevCards => {
      return mountOrUnmountCard(prevCards, cardNbr);
    });
  };

  const onSave = async () => {
    try {
      await api.saveCustomer({
        customerID,
        longAttributeName1: 'cardToken',
        longAttributeValue1: JSON.stringify(cards),
      });
      dispatch({
        type: customerActionTypes.SAVE_CUSTOMER_CARD_TOKEN_INFO,
        data: cards,
      });
      dispatch(addSuccess(t('mountCardsModal.changesSaved')));
    } catch (e) {
      dispatch(addError(t('mountCardsModal.changesNotSaved')));
    }
  };

  return (
    <Loader show={isLoading} style={{ flex: 1 }} block>
      <Box>
        <TableHeader onSave={onSave} shouldShowSaveBtn={hasChanges} />
        {!areCardsAvailable && !isLoading && (
          <Message text={t('mountCardsModal.noCardsBoarded')} />
        )}
        {error && <Message text={t('mountCardsModal.error')} />}
        {areCardsAvailable && (
          <CardsTable
            cards={cards}
            onRemoveCard={onRemoveCard}
            onMountOrUnmountCard={onMountOrUnmountCard}
          />
        )}
      </Box>
    </Loader>
  );
}
