import React, { useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import InputField from 'components/FieldTypes/InputField';
import { Configuration } from 'plugins/customButtons/index';
import { PosPlugin } from 'plugins/plugin';
import useProducts from 'utils/hooks/useProducts';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';

export const ComponentConfiguration: PosPlugin<
  Configuration
>['ComponentConfiguration'] = ({ current, save }) => {
  const [products, setProducts] = useState(current?.products ?? []);
  const [buttons, setButtons] = useState(current?.buttons ?? []);

  return (
    <div data-testid="custom-buttons-config">
      <button data-testid="save" onClick={() => save({ buttons, products })}>
        Save
      </button>
      <hr />
      <h2>URL buttons (function buttons area)</h2>
      <LinkButtons value={buttons} onChange={setButtons} />
      <hr />
      <h2>Product buttons (saleoptions area)</h2>
      <ProductButtons value={products} onChange={setProducts} />
    </div>
  );
};

const ProductButtons = ({ value, onChange }) => {
  const set = (index, name) => e => {
    const lens = R.lensPath([index, name]);
    onChange(R.set(lens, e.target.value));
  };
  const productIDs = value.map(p => p.productID);
  const { products } = useProducts({ productIDs });
  const shoppingCart = useSelector(getProductsInShoppingCart);

  const name = id =>
    products.find(p => String(p.productID) === String(id))?.name;
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Display name</th>
            <th>Enabled</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {value.map(({ productID, enabled }, i) => (
            <tr data-test-key={productID}>
              <td>{name(productID)}</td>
              <td>
                <InputField
                  data-testid="enabled"
                  type="checkbox"
                  value={enabled}
                  onChange={set(i, 'enabled')}
                />
              </td>

              <td>
                <button
                  data-testid="remove"
                  onClick={() => onChange(R.remove(i, 1))}
                >
                  REMOVE
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {shoppingCart.length ? (
        shoppingCart.map(p => {
          const alreadyAdded = value.some(
            v => String(v.productID) === String(p.productID),
          );
          return (
            <button
              disabled={alreadyAdded}
              onClick={() =>
                onChange(R.append({ enabled: true, productID: p.productID }))
              }
            >
              {alreadyAdded
                ? `${p.name}: Already added`
                : `${p.name}: Add as button`}
            </button>
          );
        })
      ) : (
        <span>To add new product buttons, add them to the shopping cart</span>
      )}
    </>
  );
};
const LinkButtons = ({ value, onChange }) => {
  const set = (index, name) => e => {
    const lens = R.lensPath([index, name]);
    onChange(R.set(lens, e.target.value));
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Display name</th>
            <th>Link</th>
            <th>Enabled</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {value.map(({ name, url, enabled }, i) => (
            <tr data-testid="row">
              <td>
                <InputField
                  data-testid="name"
                  data-test-key={name}
                  value={name}
                  onChange={set(i, 'name')}
                />
              </td>
              <td>
                <InputField
                  data-testid="url"
                  data-test-key={url}
                  value={url}
                  onChange={set(i, 'url')}
                />
              </td>
              <td>
                <InputField
                  data-testid="enabled"
                  data-test-key={`${name}-enabled`}
                  type="checkbox"
                  value={enabled}
                  onChange={set(i, 'enabled')}
                />
              </td>

              <td>
                <button
                  data-testid="remove"
                  data-test-key={`${name}-remove`}
                  onClick={() => onChange(R.remove(i, 1))}
                >
                  REMOVE
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <button
        data-testid="add-new"
        onClick={() => onChange(R.append({ name: '', url: '', enabled: true }))}
      >
        Add new link button
      </button>
    </>
  );
};
