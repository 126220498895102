import { createSelector } from 'reselect';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getClientCode, getCompany } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getPluginConfiguration } from 'reducers/Plugins';
import { UniquePrefixWordlist } from 'utils/diceware';
import { publish } from 'services/CentrifugoWS';
import {
  getCurrencyFormatter,
  getCurrencySymbol,
  getCurrentPosLanguage,
  getSetting,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { getSelectedWarehouse } from 'reducers/warehouses';
import defaultTranslations from 'plugins/customerDisplay/constants/defaultTranslations';
import { getProductsUniversal } from 'actions/productsDB';
import { Product } from 'types/Product';

import { ComponentHeader } from './ComponentHeader';
import { ComponentConfiguration } from './ComponentConfiguration';
import {
  completePurchaseCentrifugoMessage,
  customerCreationAcceptedCentrifugoMessage,
  defaultCustomerCreationFieldsDisplay,
  defaultQrCodeConfig,
  finishedSaleCentrifugoMessage,
} from './constants';
import DuplicateCustomersSelectionForm from './DuplicateCustomersSelectionForm';
import {
  Config,
  Configuration,
  CustomerCreationField,
  QrCodeConfig,
} from './types';
import customerDisplayReducer from './rdx/reducer';
import { sendUpsellProductsToDisplay } from './features/upsellProducts';

export const pluginID = 'customerDisplay';

export const getCustomerDisplayPluginConfiguration = createSelector(
  state => getPluginConfiguration<Configuration>(pluginID)(state),
  config => {
    return config || {};
  },
);

export const getDefaultRegisterCustomerFieldTranslations = createSelector(
  state => getCurrentPosLanguage(state),
  (lang: string) => {
    const fallbackTranslations = defaultTranslations.en.registerCustomerForm;
    if (!lang || lang === 'en') {
      return fallbackTranslations;
    }
    const translatedTexts =
      defaultTranslations[['en', 'et'].includes(`${lang}`) ? lang : 'en']
        .registerCustomerForm;
    return R.mergeDeepLeft(translatedTexts, fallbackTranslations);
  },
);

export const getFieldTranslations = createSelector(
  state => getCustomerDisplayPluginConfiguration(state),
  state => getDefaultRegisterCustomerFieldTranslations(state),
  (conf, defaultTranslations) => {
    if (!conf || !conf?.fieldTranslations) {
      return defaultTranslations;
    }
    return R.mergeDeepLeft(conf.fieldTranslations, defaultTranslations);
  },
);

export const getFieldsToShowConfig = state =>
  getCustomerDisplayPluginConfiguration(state)?.fields ?? [];

export const getFieldsToShow = createSelector(
  state => getFieldsToShowConfig(state),
  state => getFieldTranslations(state),
  (config, translations) => {
    const fieldsToShow = Object.values(defaultCustomerCreationFieldsDisplay)
      .filter(field => !!config[field.name])
      .map(field => {
        let obj: CustomerCreationField = {
          ...field,
          label:
            translations.label[field.name] ??
            defaultCustomerCreationFieldsDisplay[field.name].label,
          placeholder:
            translations.placeholder[field.name] ??
            defaultCustomerCreationFieldsDisplay[field.name].placeholder,
          requiredMessage:
            translations.requiredMessage[field.name] ??
            defaultCustomerCreationFieldsDisplay[field.name].requiredMessage,
        };
        if (field.name === 'termsAndConditions') {
          obj = R.assoc(
            'message',
            translations.message[field.name] ??
              defaultCustomerCreationFieldsDisplay[field.name].message ??
              '',
          )(obj);
        } else if (field.name === 'privacy') {
          obj = R.assoc(
            'link',
            translations.link[field.name] ??
              defaultCustomerCreationFieldsDisplay[field.name].link ??
              '',
          )(obj);
        }
        return obj;
      });
    return fieldsToShow;
  },
);

export const getCustomerCreationMessage = createSelector(
  state => getFieldsToShow(state),
  fieldsToShow => {
    return {
      action: 'REGISTER_CUSTOMER',
      fields: fieldsToShow,
    };
  },
);

export const getLocaleFieldsFromConfig = createSelector(
  getCustomerDisplayPluginConfiguration,
  (config: Configuration) => config?.config?.localizationConstants,
);

export const getDefaultConfigForCentrifugoMessage = createSelector(
  state => getCompany(state),
  state => getSetting('receiptLogoURL')(state),
  state => getSelectedWarehouse(state),
  state => getCurrencySymbol(state),
  state => getCustomerCreationMessage(state),
  state => getCurrentPosLanguage(state),
  (
    company,
    brandLogo,
    warehouse,
    currencySymbol,
    customerCreationMessage,
    languageCode,
  ) =>
    ({
      appName: 'Customer Display App',
      language: 'en',
      localizationConstants: {
        currencySymbol,
        brandText: company.name,
        brandLogo,
        brandLogoType: 'JPG',
        locationText: (warehouse ?? {}).name,
        promotionsDefaultImage:
          'https://cdn.erply.com/images/469357/March-2021-newsletter.jpg',
        ...defaultTranslations.en,
        ...(defaultTranslations[languageCode] ?? {}),
      },
      theme: {
        mode: 'light',
        color: {
          background: '#FFFFFF',
          primary: '#32B17E',
          accent: '#009AD4',
          line: '#CCCCCC',
          headerBackground: '#EEEFF1',
          headerPrimaryFont: '#000000',
          headerSecondaryFont: '#585757',
          headerLine: '#CCCCCC',
          promotionsBackground: '#FFFFFF',
          promotionsFont: '#969696',
          promotionsLine: '#CCCCCC',
          productsBackground: '#FFFFFF',
          productsPrimaryFont: '#969696',
          productsSecondaryFont: '#585757',
          productsLine: '#EEEFF1',
          productsSummaryBackground: '#FFFFFF',
          productsSummaryPrimaryFont: '#969696',
          productsSummarySecondaryFont: '#585757',
          productsSummaryLine: '#EEEFF1',
          donationBackground: '#EEEFF1',
          donationPrimaryFont: '#000000',
          donationSecondaryFont: '#969696',
          donationInputBackground: '#FFFFFF',
          donationInputFont: '#000000',
          donationInputHintFont: '#969696',
          donationButton: '#32B17E',
          donationButtonFont: '#FFFFFF',
          donationSecondaryButton: '#FFFFFF',
          donationSecondaryButtonFont: '#000000',
          donationKeyboardBackground: '#FFFFFF',
          donationKeyBackground: '#EFF1F3',
          donationKeyFont: '#000000',
          donationThankingBackground: '#EEEFF1',
          donationThankingFont: '#000000',
          donationThankingLine: '#CCCCCC',
          tipBackground: '#EEEFF1',
          tipPrimaryFont: '#000000',
          tipSecondaryFont: '#969696',
          tipInputBackground: '#FFFFFF',
          tipInputFont: '#000000',
          tipInputHintFont: '#969696',
          tipButton: '#32B17E',
          tipButtonFont: '#FFFFFF',
          tipSecondaryButton: '#FFFFFF',
          tipSecondaryButtonFont: '#000000',
          tipKeyboardBackground: '#FFFFFF',
          tipKeyBackground: '#EFF1F3',
          tipKeyFont: '#000000',
          tipThankingBackground: '#EEEFF1',
          tipThankingFont: '#000000',
          tipThankingLine: '#CCCCCC',
          idleScreenBackground: '#FFFFFF',
          idleScreenFont: '#000000',
          idleScreenLine: '#CCCCCC',
          emailLookupBackground: '#EEEFF1',
          emailLookupPrimaryFont: '#000000',
          emailLookupSecondaryFont: '#969696',
          emailLookupInputBackground: '#FFFFFF',
          emailLookupInputFont: '#000000',
          emailLookupInputHintFont: '#969696',
          emailLookupButton: '#32B17E',
          emailLookupButtonFont: '#FFFFFF',
          phoneLookupBackground: '#EEEFF1',
          phoneLookupPrimaryFont: '#000000',
          phoneLookupSecondaryFont: '#969696',
          phoneLookupInputBackground: '#FFFFFF',
          phoneLookupInputFont: '#000000',
          phoneLookupInputHintFont: '#969696',
          phoneLookupButton: '#32B17E',
          phoneLookupButtonFont: '#FFFFFF',
          registerCustomerBackground: '#FFFFFF',
          registerCustomerPrimaryFont: '#000000',
          registerCustomerSecondaryFont: '#969696',
          registerCustomerButton: '#32B17E',
          registerCustomerButtonFont: '#FFFFFF',
          registerCustomerSecondaryButton: '#FFFFFF',
          registerCustomerSecondaryButtonFont: '#000000',
          registerCustomerInputLabelFont: '#000000',
          registerCustomerInputBackground: '#FFFFFF',
          registerCustomerInputFont: '#000000',
          registerCustomerInputHintFont: '#969696',
          registerCustomerInputErrorFont: '#FF6262',
          registerCustomerFormBackground: '#EEEFF1',
          registerCustomerFormPrimaryFont: '#000000',
          registerCustomerFormLine: '#CCCCCC',
          registerCustomerSuccessBackground: '#EEEFF1',
          registerCustomerSuccessFont: '#000000',
          registerCustomerSuccessLine: '#CCCCCC',
          registerCustomerFailedBackground: '#EEEFF1',
          registerCustomerFailedFont: '#000000',
          registerCustomerFailedLine: '#CCCCCC',
          couponBackground: '#EFF1F3',
          couponPrimaryFont: '#000000',
          couponSecondaryFont: '#969696',
          couponInputBackground: '#FFFFFF',
          couponInputFont: '#000000',
          couponInputHintFont: '#969696',
          couponButton: '#32B17E',
          couponButtonFont: '#FFFFFF',
          couponSecondaryButton: '#FFFFFF',
          couponSecondaryButtonFont: '#000000',
          couponSuccessBackground: '#FFFFFF',
          couponSuccessFont: '#000000',
          couponSuccessLine: '#EFF1F3',
          couponFailedBackground: '#FFFFFF',
          couponFailedFont: '#000000',
          couponFailedLine: '#EFF1F3',
          upsellBackground: '#FFFFFF',
          upsellPrimaryFont: '#000000',
          upsellSecondaryFont: '#969696',
          upsellButton: '#32B17E',
          upsellButtonFont: '#FFFFFF',
          upsellSecondaryButton: '#FFFFFF',
          upsellSecondaryButtonFont: '#000000',
          upsellFormBackground: '#EFF1F3',
          upsellFormLine: '#CCCCCC',
          upsellProductPrimaryFont: '#000000',
          upsellProductSecondaryFont: '#969696',
          upsellProductBackground: '#FFFFFF',
          upsellProductLine: '#32B17E',
          upsellSuccessBackground: '#EFF1F3',
          upsellSuccessFont: '#000000',
          upsellSuccessLine: '#EFF1F3',
          upsellFailedBackground: '#EFF1F3',
          upsellFailedFont: '#000000',
          upsellFailedLine: '#CCCCCC',
          thankYouScreenBackground: '#FFFFFF',
          thankYouScreenFont: '#000000',
          thankYouScreenLine: '#CCCCCC',
          qrCodeBackground: '#FFFFFF',
          qrCodeForeground: '#000000',
          qrPrimaryFont: '#000000',
          qrSecondaryFont: '#969696',
          rateServiceBackground: '#FFFFFF',
          rateServiceHeaderBackground: '#FBFBFB',
          rateServiceLine: '#EEEFF1',
          rateServiceStarUnselected: '#CCCCCC',
          rateServiceStarSelected: '#F2C94C',
          rateServicePrimaryFont: '#000000',
          rateServiceSecondaryFont: '#969696',
          rateServiceInputFont: '#000000',
          rateServiceInputHintFont: '#585757',
          rateServicePrimaryButton: '#009AD4',
          rateServicePrimaryButtonFont: '#FFFFFF',
          rateServiceSecondaryButton: '#FFFFFF',
          rateServiceSecondaryButtonFont: '#585757',
          customerCheckInHeaderBackground: '#FBFBFB',
          customerCheckInBackground: '#FFFFFF',
          customerCheckInLine: '#EEEFF1',
          customerCheckInPrimaryFont: '#000000',
          customerCheckInSecondaryFont: '#969696',
          customerCheckInAddCustomerBackground: '#FF6262',
          customerCheckInAddCustomerFont: '#FFFFFF',
          customerCheckInLookUpCustomerBackground: '#009AD4',
          customerCheckInLookUpCustomerFont: '#FFFFFF',
          customerCheckInCouponBackground: '#0E9B62',
          customerCheckInCouponFont: '#FFFFFF',
        },
      },
      settings: {
        purchaseInactivityTimeout: 60000,
        showDefaultContent: 'PROMOTIONS',
        showWideKeyboard: false,
        defaultPhoneSmallKeyboardState: 'NUMBERS',
        dateTimeConfig: {
          dateFormat: 'EU',
          timeFormat: '24H',
        },
        checkInConfig: {
          addCustomerMessage: customerCreationMessage,
          lookUpCustomerMessage: {
            action: 'GET_CUSTOMER_PHONE',
          },
          couponMessage: {
            action: 'ENTER_COUPON_CODE',
            type: 'ALPHANUMERIC',
          },
        },
      },
      fontConfig: {
        productsSummaryPrimary: {
          fontSize: 14,
          fontWeight: 'REGULAR',
        },
        productsSummarySecondary: {
          fontSize: 18,
          fontWeight: 'BOLD',
        },
      },
    } as Configuration['config']),
);

export const getShouldShowOpenCustomerDisplayButton = createSelector(
  state => getCustomerDisplayPluginConfiguration(state),
  displayConfig => {
    return displayConfig?.shouldShowOpenCustomerDisplayButton ?? true;
  },
);

export const getChannelPasscode = state =>
  getCustomerDisplayPluginConfiguration(state)?.passCode2;

export const getConfigForDisplay = state => {
  const defaultConfig = getDefaultConfigForCentrifugoMessage(state);
  const existingConf =
    getCustomerDisplayPluginConfiguration(state)?.config ?? {};
  return R.mergeDeepLeft(existingConf, defaultConfig) as Partial<Config>;
};

export const getSetConfigCentrifugoMessage = createSelector(
  state => getConfigForDisplay(state),
  state => getDefaultConfigForCentrifugoMessage(state),
  (config, defaultConfig) => {
    // default config contains the correct addCustomerMessage fields
    const configToSet: Partial<Config> = R.assocPath(
      ['settings', 'checkInConfig', 'addCustomerMessage', 'fields'],
      defaultConfig.settings?.checkInConfig?.addCustomerMessage?.fields ??
        config.settings?.checkInConfig?.addCustomerMessage?.fields,
    )(config);
    const msg = {
      action: 'SET_CONFIG',
      config: configToSet,
    };
    return msg;
  },
);
export const getLocalizationConstantsFromDisplayConfig = state => {
  return getConfigForDisplay(state).localizationConstants;
};

export const getSettingsFromDisplayConfig = state => {
  return getConfigForDisplay(state).settings;
};

export const getPromotionsArray = createSelector(
  state => getCustomerDisplayPluginConfiguration(state),
  config => {
    return config?.promotions || [];
  },
);

export const getSmallPromotionsArray = createSelector(
  state => getCustomerDisplayPluginConfiguration(state),
  config => {
    return config?.smallPromotions || [];
  },
);

export const getSetPromotionsMessage = createSelector(
  state => getPromotionsArray(state),
  promotions => {
    return {
      action: 'SET_PROMOTIONS',
      promotions,
    };
  },
);

export const getSetSmallPromotionsMessage = createSelector(
  state => getSmallPromotionsArray(state),
  promotions => {
    return {
      action: 'SET_SMALL_PROMOTIONS',
      promotions,
    };
  },
);

export const getDonationProductId = state => {
  return getCustomerDisplayPluginConfiguration(state)?.donationProductId || 0;
};
export const getDiscountAsPercentage = state => {
  return (
    getCustomerDisplayPluginConfiguration(state)?.rowDiscountAsPercentage ??
    false
  );
};

export const getAfterSaleAction = state => {
  return (
    getCustomerDisplayPluginConfiguration(state)?.afterSaleAction ?? 'nothing'
  );
};

export const getOpenCustomerFormOnCreate = state => {
  return (
    getCustomerDisplayPluginConfiguration(state)?.openCustomerFormOnCreate ??
    false
  );
};

export const getQRCodeConfig = (state): QrCodeConfig => {
  return (
    getCustomerDisplayPluginConfiguration(state)?.qrConfig ||
    defaultQrCodeConfig
  );
};

export const getCustomQRCodeMessage = createSelector(
  state => getQRCodeConfig(state),
  qrConf => {
    const { qrCodeData = '', qrTitle = '', qrSubtitle = '' } = qrConf;
    return {
      action: 'SHOW_QR',
      qrCodeData,
      qrTitle,
      qrSubtitle,
    };
  },
);

export const getChannelPassphrase = state => {
  const passCode = getChannelPasscode(state);
  const code: string[] = Array(Math.ceil(passCode.length / 4))
    .fill(0)
    .map((z, i) => passCode.slice(i * 4, (i + 1) * 4));
  return code.map(c => UniquePrefixWordlist.decode(c));
};

export const getChannelName = state => {
  const clientCode = getClientCode(state);
  const posID = getSelectedPos(state)?.pointOfSaleID;
  const passCode = getChannelPasscode(state);
  return passCode ? `${clientCode}${posID}${passCode}` : null;
};

const customerDisplayPlugin: PosPlugin = {
  id: pluginID,
  name: 'Customer display',
  ComponentConfigurationByLevel: {
    Pos: ComponentConfiguration,
  },
  getStatus: createSelector(getChannelName, channelName => {
    if (!channelName) {
      return {
        type: 'error',
        message: 'Channel not configured',
      };
    }
    return {
      type: 'valid',
      message: `Channel name: ${channelName}`,
    };
  }),
  combineConfiguration: (company = {}, warehouse = {}, pos = {}, user = {}) =>
    R.reduce(R.mergeDeepRight, {}, [company, warehouse, pos, user]),
  onSaveSalesDocument: {
    after: (p, ep) => async (dispatch, getState) => {
      const afterSaleAction = getAfterSaleAction(getState());
      const channelName = getChannelName(getState());
      const QRCodeConfig = getQRCodeConfig(getState());
      if (afterSaleAction === 'email') {
        publish({
          channel: `customer_display:${channelName}`,
          message: completePurchaseCentrifugoMessage,
        });
      } else if (afterSaleAction === 'customQR') {
        publish({
          channel: `customer_display:${channelName}`,
          message: {
            action: 'SHOW_QR',
            qrCodeData: QRCodeConfig.qrCodeData,
            qrTitle: QRCodeConfig.qrTitle,
            qrSubtitle: QRCodeConfig.qrSubtitle,
            isAfterSaleQR: true,
          },
        });
      } else if (afterSaleAction === 'receiptQR') {
        publish({
          channel: `customer_display:${channelName}`,
          message: {
            action: 'SHOW_QR',
            qrCodeData: ep.salesDocument.receiptLink,
            qrTitle: QRCodeConfig.qrTitle,
            qrSubtitle: QRCodeConfig.qrSubtitle,
            isAfterSaleQR: true,
          },
        });
      } else {
        publish({
          channel: `customer_display:${channelName}`,
          message: finishedSaleCentrifugoMessage,
        });
      }
    },
  },
  onSaveCustomer: {
    after: () => async (dispatch, getState) => {
      const channelName = getChannelName(getState());
      publish({
        channel: `customer_display:${channelName}`,
        message: customerCreationAcceptedCentrifugoMessage,
      });
    },
  },
  components: {
    DuplicateCustomersSelectionForm,
  },
  onSoftLogout: {
    before: () => async (_dispatch, getState) => {
      const channelName = getChannelName(getState());
      const channel = `customer_display:${channelName}`;
      publish({
        channel,
        message: finishedSaleCentrifugoMessage,
      });
    },
  },
  onHardLogout: {
    before: () => async (_dispatch, getState) => {
      const channelName = getChannelName(getState());
      const channel = `customer_display:${channelName}`;
      publish({
        channel,
        message: finishedSaleCentrifugoMessage,
      });
    },
  },
  onAddProduct: {
    on: sendUpsellProductsToDisplay,
  },
  ComponentHeader,
  reduxReducer: customerDisplayReducer,
};
export default customerDisplayPlugin;
