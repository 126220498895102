import React from 'react';
import { Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import './style.scss';

const MyComponent = ({ resolve, reject, productName }) => {
  useShortcut('Enter', resolve, 100);
  useShortcut('Escape', reject, 100);
  return (
    <>
      <Modal.Header
        data-testid="core-return-header"
        className="core-return-header"
      >
        Confirmation
      </Modal.Header>
      <Modal.Body>
        <Container>
          <p data-testid="core-return-title">{`Does the customer have a core to return today for this ${productName}?`}</p>
        </Container>
        <Container className="core-return-container">
          <Row
            data-testid="core-return-buttons"
            style={{ justifyContent: 'center' }}
          >
            <Col style={{ display: 'flex' }}>
              <Button
                className="core-return-button"
                // @ts-ignore
                variant="grid_light"
                onClick={reject}
                data-testid="no-option-button"
              >
                <span
                  data-testid="button-icon-no"
                  className="core-return-button-icon"
                >
                  esc
                </span>
                <span
                  data-testid="button-text-no"
                  className="core-return-button-text"
                >
                  No
                </span>
              </Button>
            </Col>
            <Col xs="auto" style={{ display: 'flex' }}>
              <Button
                className="core-return-button"
                // @ts-ignore
                variant="grid_green"
                onClick={resolve}
                data-testid="yes-option-button"
              >
                <span
                  data-testid="button-icon-yes"
                  className="core-return-button-icon"
                >
                  ⏎
                </span>
                <span
                  data-testid="button-text-yes"
                  className="core-return-button-text"
                  style={{ fontWeight: 700 }}
                >
                  Yes
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </>
  );
};

export default MyComponent;
