import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as R from 'ramda';
import { Box, Typography } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';
import Icon from 'components/Icon';
import { RootState } from 'reducers';
import MuiButton from 'components/CustomButtons/MuiButton';

import { getTafReduxState } from '../rdx/reducers';
import { resetEntityInProduct, setEntityForOrderIndex } from '../rdx/actions';
import singlePrompt from '../utils';

const EntityRows = ({ order, isEven }) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { productsState } = useSelector(getTafReduxState);
  const currentProduct = productsState[order.orderIndex] ?? {};
  const hasBothEntityIDs =
    currentProduct.medicalEntity?.entityID &&
    currentProduct.schoolEntity?.entityID;

  return (
    <>
      {Object.keys(currentProduct).map((key, index) => {
        const { entityID, entityName, entityType } = currentProduct[key];

        return entityID ? (
          <tr
            style={{
              cursor: 'pointer',
              borderBottom:
                hasBothEntityIDs && index === 0 ? 'none' : '1px solid white',
            }}
            className={classNames({ isEven, grouped: true })}
          >
            <td colSpan={11} style={{ borderTop: 'none' }}>
              <Box>
                <Icon
                  style={{ marginRight: '15px' }}
                  name="erply_delete_color"
                  action={() =>
                    dispatch(resetEntityInProduct(order.orderIndex, entityType))
                  }
                />
                <MuiButton
                  color="UIButton"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    dispatch(
                      singlePrompt('TafCustomerSearchModal', {
                        type: key as 'schoolEntity' | 'medicalEntity',
                        selected: entityID,
                      }),
                    ).then(
                      c =>
                        dispatch(
                          setEntityForOrderIndex(order.orderIndex, {
                            entityType:
                              key === 'schoolEntity'
                                ? 'association'
                                : 'proffesional',
                            entityName: c.fullName ?? c.companyName,
                            entityID: c.id,
                          }),
                        ),
                      () => undefined,
                    );
                  }}
                  style={{
                    height: '1rem',
                    minWidth: '56px',
                    fontSize: '0.875rem',
                    marginRight: '15px',
                    padding: '0 5px',
                    outline: 'none',
                    textTransform: 'uppercase',
                  }}
                >
                  Edit
                </MuiButton>
                <Typography component="span">{entityName}</Typography>
              </Box>
            </td>
          </tr>
        ) : null;
      })}
    </>
  );
};

const UICustomTableBillRow: PosPlugin['UICustomTableBillRow'] = ({
  children,
  order,
  isEven,
}) => {
  return R.pipe(
    React.Children.toArray,
    R.insert(1, <EntityRows order={order} isEven={isEven} />),
  )(children);
};

export default UICustomTableBillRow;
