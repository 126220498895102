import axios from 'axios';

import { version } from 'external_data';

import { defineSplitString } from '../utils';

const baseURL = 'https://localhost.erply.com:10108/api/v1';

// Host reference number needs to be 6 characters long and unique in any request
let hostReferenceNumber;

export const requestPayment = async (
  cp,
  config,
  shopperTransactionID = null,
  splitsConf,
  employeeID,
) => {
  const { currencyCode, adyenRecon, customerEmail } = config;

  const shopperID = Math.floor(Date.now() / 1000).toString();
  hostReferenceNumber = shopperID.slice(-6);

  const transactionISOTime = new Date().toISOString();
  const dateDataForReferenceNumber = transactionISOTime.split('T');

  const paymentData = {
    CardAcquisitionReference: {
      TransactionID: shopperTransactionID,
      TimeStamp: transactionISOTime,
    },
  };

  const requestBody = {
    transactionType: 'SALE',
    amount: String(cp.amount),
    employeeID,
    invoiceNumber: String(cp.id),
    softwareName: 'Erply Brazil POS',
    softwareVersion: version,
    currency: currencyCode,
    referenceNumber: `${dateDataForReferenceNumber[0]}-${employeeID}-${dateDataForReferenceNumber[1]}`,
    hostReferenceNumber,
  };

  if (splitsConf && splitsConf.length) {
    // Adds split string
    requestBody.SaleToPOIRequest.PaymentRequest.SaleData.SaleToAcquirerData = defineSplitString(
      splitsConf,
      cp,
      currencyCode,
    );
  }

  if (adyenRecon) {
    if (customerEmail && customerEmail !== ' ' && customerEmail !== '') {
      requestBody.SaleToPOIRequest.PaymentRequest.SaleData.SaleToAcquirerData = `shopperEmail=${customerEmail}&shopperReference=${shopperID}&recurringContract=RECURRING`;
      requestBody.SaleToPOIRequest.PaymentRequest.SaleData.TokenRequestedType =
        'Customer';
    }

    if (shopperTransactionID) {
      requestBody.SaleToPOIRequest.PaymentRequest.PaymentData = paymentData;
    }
  }

  const settings = {
    url: `${baseURL}/payment`,
    method: 'POST',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  };

  const data = requestBody;
  let payload = {};

  await axios({ ...settings, data })
    .then(response => {
      payload = { ...response.data.records[0] };
    })
    .catch(error => {
      payload = {
        statusMessage:
          'Error while performing payment transaction, please try again',
      };
    });

  return payload;
};

export const cancelPayment = async (employeeID, isPaymentOrReturn) => {
  const abortRequest = {
    manageType: 'cancelTransaction',
    data: `{"employeeID": "${employeeID}", "serviceID": "${hostReferenceNumber}", "messageCategory":"${isPaymentOrReturn}"}`,
  };

  const settings = {
    url: `${baseURL}/manage`,
    method: 'POST',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  };

  return axios({ ...settings, data: abortRequest }).then(
    response => response.data.records[0],
  );
};

export const checkTransactionStatus = async employeeID => {
  const data = {
    manageType: 'status',
    data: `{"employeeID": "${employeeID}", "serviceID":"${hostReferenceNumber}", "messageCategory":"Payment"}`,
  };

  const settings = {
    url: `${baseURL}/manage`,
    method: 'POST',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  };

  return axios({ ...settings, data }).then(response => {
    return response.data.records[0];
  });
};

export const voidRequest = async paymentData => {
  const referenceNumber =
    paymentData.attributes?.refNo ??
    paymentData.original?.referenceNumber ??
    '';
  const data = {
    transactionType: 'VOID',
    referenceNumber,
  };
  const settings = {
    url: `${baseURL}/payment`,
    method: 'POST',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  };
  return axios({ ...settings, data }).then(
    response => response.data.records[0],
  );
};

export const refundRequest = async (paymentData, currencyCode, employeeID) => {
  const { sum } = paymentData.original ?? {};
  const referenceNumber =
    paymentData.attributes?.refNo ??
    paymentData.original?.referenceNumber ??
    '';
  const amount = Math.abs(Number(paymentData.amount));
  hostReferenceNumber = Math.floor(Date.now() / 1000)
    .toString()
    .slice(-6);

  const data = {
    transactionType: 'REFUND',
    employeeID,
    amount: String(amount.toFixed(2)),
    referenceNumber,
    origDateTime: new Date().toISOString(),
    currency: currencyCode,
    origAmount: sum || '',
    hostReferenceNumber,
  };

  const settings = {
    url: `${baseURL}/payment`,
    method: 'POST',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  };

  return axios({ ...settings, data }).then(
    response => response.data.records[0],
  );
};

export const terminalDiagnosis = async () => {
  const settings = {
    url: `${baseURL}/testConnection`,
    method: 'GET',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  };

  return axios({ ...settings }).then(response => response.data.records[0]);
};

export const serviceInfo = async () => {
  return axios({
    url: `${baseURL}/service/info`,
    method: 'GET',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  });
};

export const getCustomerData = async (cardPayment, config) => {
  const { terminalId } = config;
  const newUniqueIdVal = Math.floor(Date.now() / 1000).toString();
  const requestBody = {
    SaleToPOIRequest: {
      MessageHeader: {
        ProtocolVersion: '3.0',
        MessageClass: 'Service',
        MessageCategory: 'CardAcquisition',
        MessageType: 'Request',
        SaleID: newUniqueIdVal,
        ServiceID: newUniqueIdVal.toString().slice(-6),
        POIID: terminalId,
      },
      CardAcquisitionRequest: {
        SaleData: {
          SaleTransactionID: {
            TransactionID: newUniqueIdVal.toString().slice(-4),
            TimeStamp: new Date().toISOString(),
          },
          TokenRequestedType: 'Customer',
        },
        CardAcquisitionTransaction: {
          TotalAmount: Number(cardPayment.amount),
        },
      },
    },
  };

  const settings = {
    url: `${baseURL}/payment`,
    method: 'POST',
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  };

  const data = requestBody;
  let decryptedResponse;

  await axios({ ...settings, data }).then(response => {
    decryptedResponse = response;
  });
  return decryptedResponse;
};
