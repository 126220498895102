import { createSelector } from 'reselect';

import { GIFTCARDS_UPDATE, GIFTCARDTYPES_UPDATE } from 'constants/giftcards';
import { createOverrideSelector } from 'reducers/Plugins';
import { getNamesByLanguage, getNameInLanguage } from 'utils/languages';

import {
  getCurrentPosLanguageOverride,
  getCurrentPosLanguage,
  getSetting,
} from './configs/settings';

export type GiftCardType = {
  id: number;
  order: number;
  added: number;
  addedByID: number;
  lastModified: number;
  lastModifiedByID: number;
  nameEN: string;
  nameET: string;
  nameRU: string;
};

type State = {
  regularGiftcards: any[] | null;
  giftcardTypes: GiftCardType[];
};
const initialState: State = {
  regularGiftcards: null,
  giftcardTypes: [],
};

function reducer(state = initialState, { type, payload }): State {
  switch (type) {
    case GIFTCARDS_UPDATE:
      return { ...state, regularGiftcards: payload };
    case GIFTCARDTYPES_UPDATE:
      return {
        ...state,
        giftcardTypes: payload,
      };
    default:
      return state;
  }
}

export default reducer;

function rootState(state): State {
  return state.giftcards;
}
// TODO: Giftcards are just products - currently being fetched by an explicit request
// Since we have a local database we should probably just use a selector off of that?
export const getRegularGiftcards = createOverrideSelector(
  'getRegularGiftcards',
  createSelector(rootState, state => state.regularGiftcards),
);

export const getGiftcardTypes = createSelector(
  rootState,
  getCurrentPosLanguageOverride,
  getCurrentPosLanguage,
  getSetting('pos_default_language'),
  (state, langOverride, lang, defaultLang) => {
    return state.giftcardTypes.map(gc => {
      const names = getNamesByLanguage(gc);
      const name = getNameInLanguage(
        names,
        langOverride || lang,
        defaultLang.isoCode,
      );

      return {
        ...gc,
        name,
        names,
      };
    });
  },
);
