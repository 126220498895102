import { v1 as uuidv1 } from 'uuid';

export const TYPE_SETLOADING = `Login: SET_LOADING→${uuidv1()}`;
export const TYPE_LOGIN = `Login: LOGIN→${uuidv1()}`;
export const TYPE_AUTO_LOGIN = `Login: AUTOLOGIN→${uuidv1()}`;
export const TYPE_LOGOUT_START = `Login: LOGOUT_START→${uuidv1()}`;
export const TYPE_LOGOUT_ERROR = `Login: LOGOUT_ERROR→${uuidv1()}`;
export const TYPE_LOGOUT_SOFT_START = `Login: LOGOUT_SOFT_START→${uuidv1()}`;
export const TYPE_LOGOUT_SOFT_ERROR = `Login: LOGOUT_SOFT_ERROR→${uuidv1()}`;
export const TYPE_LOGOUT = `Login: LOGOUT→${uuidv1()}`;
export const TYPE_LOGOUT_SOFT = `Login: LOGOUT_SOFT→${uuidv1()}`;
export const TYPE_LOGOUT_OFFLINE = `Login: LOCK_OFFLINE→${uuidv1()}`;
export const TYPE_LOGIN_OFFLINE = `Login: UNLOCK_OFFLINE→${uuidv1()}`;
export const TYPE_SETUSERRIGHTS = `Login: SET_USERRIGHTS→${uuidv1()}`;
export const TYPE_SET_USER_SALES_DATA = `Login: SET_USER_SALES_DATA=${uuidv1()}`;
export const TYPE_EDIT_EMPLOYEE_DATA = `Login: TYPE_EDIT_EMPLOYEE_DATA=${uuidv1()}`;

export const GET_COMAPNY_INFO = {
  START: 'GET_COMAPNY_INFO_START',
  SUCCESS: 'GET_COMAPNY_INFO_SUCCESS',
  ERROR: 'GET_COMAPNY_INFO_ERROR',
};

export const INIT_SESSION = {
  START: 'INIT_SESSTION_START',
  SUCCESS: 'INIT_SESSION_SUCCESS',
  ERROR: 'INIT_SESSION_ERROR',
};
export const CONNECT_SSO = {
  START: 'CONNECT_SSO_START',
  SUCCESS: 'CONNECT_SSO_SUCCESS',
  ERROR: 'CONNECT_SSO_ERROR',
};

export const LOGIN_URL = 'https://launchpad-sb.erply.com/';
