import { getCurrentModalPage, getCurrentModalPopup } from 'reducers/modalPage';
import * as c from 'constants/modalPage';

export function previousModalPage(props?: { component?: unknown }) {
  return async (
    dispatch,
    getState,
  ) => {
    const modalPage = getCurrentModalPage(getState());
    const modalPopup = getCurrentModalPopup(getState());
    const current = modalPopup || modalPage;
    if (!current) return undefined;
    if (props?.component && current.component !== props?.component) {
      return undefined;
    }
    return dispatch({
      type: c.PREVIOUS_MODAL_PAGE,
      payload: {},
    });
  };
}
