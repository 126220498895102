import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { getModalPopups } from 'reducers/modalPage';
import { modalPages } from 'constants/modalPage';
import {
  setPaymentButtonClicked,
  setPaymentSalesDocument,
} from 'actions/Payments';
import { openPluginModalPage } from 'actions/modalPage';
import {
  getSalesDocument as getPaymentsSalesDocument,
} from 'reducers/Payments';

import { cloneAfter } from '../utils/fn';

import { CreateSpecialOrderModal } from './CreateSpecialOrderModal';

const saveAsSpecialOrder = saveAsOrder => async (dispatch, getState) => {
  await dispatch(saveAsOrder);
  const hasPaymentOpen = getModalPopups(getState()).some(
    p => p.component === modalPages.Payment,
  );
  if (hasPaymentOpen) {
    dispatch(setPaymentButtonClicked(true));
    dispatch(
      setPaymentSalesDocument({
        ...getPaymentsSalesDocument(getState()),
        'plugin:isSpecialOrder': true,
      }),
    );
  }
};

/**
 * Debug action for easier testing during development. Must not be used in production
 * @deprecated
 */
const skipToTransferOrder = openPluginModalPage(CreateSpecialOrderModal.id)({
  props: {
    salesDocument: {
      invoiceID: 10380,
      invoiceNo: '101200100000170',
      customNumber: '',
      invoiceLink:
        'https://t10.erply.com/503120/popup.php?print=invoice&invoice_idRec=10380&lang=eng&checksum=%242y%2405%24Z5X9ecCHYLpNW.hGIBxQDuyGH2.QPLBkjITkGhEw0VUqwLwX6e7WW&exp=1700666700',
      receiptLink:
        'https://t10.erply.com/503120/popup.php?print=invoice&invoice_idRec=10380&lang=eng&checksum=%242y%2405%24Z5X9ecCHYLpNW.hGIBxQDuyGH2.QPLBkjITkGhEw0VUqwLwX6e7WW&exp=1700666700&size=receipt',
      net: 34.2,
      vat: 5.03,
      rounding: 0.04,
      total: 39.27,
      rows: [
        {
          rowID: 17571,
          stableRowID: 17571,
          productID: 1,
          serviceID: 0,
          amount: 3,
        },
        {
          rowID: 17572,
          stableRowID: 17572,
          productID: 3,
          serviceID: 0,
          amount: 2,
        },
        {
          rowID: 17573,
          stableRowID: 17573,
          productID: 13,
          serviceID: 0,
          amount: 7,
        },
        {
          rowID: 17574,
          stableRowID: 17574,
          productID: 3,
          serviceID: 0,
          amount: 7,
        },
      ],
    },
  },
});

/**
 * Add a "Save as special order" button after the built-in "save order" one
 *
 * When clicked, the button will trigger the save as order flow, but also set "wasPaymentClicked" to require full payment
 * and attach an extra flag to the document to signal [onSaveOrder]{@link import('plugins/nohble/SpecialOrders/SaveAsSpecialOrder/onSaveOrder.ts').onSaveOrder} that this is a special order
 */
export const addSaveOrderButton: Required<
  PosPlugin
>['selectorOverrides']['getSaleOptionButtons'] = base =>
  createSelector(base, buttons =>
    cloneAfter(
      btn => btn.id === 'saveAsOrder' && btn.action !== undefined,
      ({ action }) => ({
        id: 'N5-SpecialOrderSave',
        name: 'Save as special order',
        actionType: 'action',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        action: () => saveAsSpecialOrder(action!()),
        // action: () => skipToTransferOrder,
      }),
      buttons,
    ),
  );
