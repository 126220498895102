import { createSelector } from 'reselect';

import { getPluginConfiguration } from 'reducers/Plugins';

import { pluginID } from '../constants';

export type Configuration = {
  /** This refers to the company's KRA Registration number */
  invoice_pin: string;
  /** API endpoint */
  apiEndpoint: string;
  /** Id of template to use for invoice sent by e-mail and SMS */
  templateId: number;
  /** OAF e-mail for sending an invoice copy to */
  email: string;
};

export const getConfiguration = state => {
  const res = getPluginConfiguration<Configuration>(pluginID)(state);
  return res;
};

export const getInvoicePin = createSelector(
  getConfiguration,
  conf => conf.invoice_pin,
);

export const getEndpoint = createSelector(
  getConfiguration,
  conf => conf.apiEndpoint,
);

export const getTemplateId = createSelector(
  getConfiguration,
  conf => conf.templateId,
);

export const getEmail = createSelector(getConfiguration, conf => conf.email);

export { default as Config } from './Configuration';
