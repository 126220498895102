import debug from 'debug';

export const pluginID = 'suretax';
export interface Configuration {
  clientNumber: string;
  validationKey: string;
  endpoint: string;
}

export const suretaxLog = debug('plugin: suretax');
