import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { openPluginModalPage } from 'actions/modalPage';
import { SignatureInput } from 'plugins/signatureCapture/components/SignatureField';
import { savePayment } from 'services/ErplyAPI';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

const EMPTY_IMAGE_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
const SignatureCapture = ({ invoiceLink, resolve }) => {
  const receipt = useAsync(
    () => window.fetch(invoiceLink).then(res => res.text()),
    [invoiceLink],
  );
  const [sig, setSig] = useState<any>(EMPTY_IMAGE_URL);
  const dispatch = useDispatch();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {receipt.loading && <CircularProgress />}
      {receipt.error && (
        <Typography color="error">{receipt.error.message}</Typography>
      )}
      {receipt.value && (
        <div style={{ all: 'initial' }}>
          <div
            ref={el => {
              if (!el) return;
              if ((el as any).hasShadowRoot) return;
              (el as any).hasShadowRoot = true;
              el.attachShadow({ mode: 'closed' }).innerHTML = receipt.value;
            }}
            style={{
              border: '1px solid black',
              display: 'block',
              backgroundColor: 'white',
            }}
          />
        </div>
      )}
      <h1>Sign here</h1>
      <SignatureInput value={sig} setValue={setSig} />
      <Box
        margin={1}
        display="flex"
        justifyContent="space-between"
        alignSelf="stretch"
      >
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setSig(EMPTY_IMAGE_URL)}
        >
          Clear
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            resolve(sig.replace(/^data:image\/png;base64,/, ''));
            dispatch(previousModalPage());
          }}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
};

export const SignatureCapturePlugin: PosPlugin<void> = {
  id: 'signatureCapture',
  name: 'Capture signature on POS device',
  getStatus: createSelector(getAllPaymentTypes, types =>
    types.some(t => t.type === 'SIGNATURE')
      ? {
          type: 'valid',
          message: 'Signatures will be saved as payment type SIGNATURE',
        }
      : {
          type: 'warning',
          message:
            'Signatures will be saved as payment type "by wire transfer" because no payment type with the type "SIGNATURE" has been created',
        },
  ),
  components: {
    signatureCapture: SignatureCapture,
  },
  onSaveSalesDocument: {
    after: (p, r) => async (dispatch, getState) => {
      const { invoiceID, invoiceLink } = r.salesDocument;
      new Promise(resolve => {
        dispatch(
          openPluginModalPage('signatureCapture')({
            isPopup: true,
            props: { invoiceLink, resolve },
          }),
        );
      }).then(signature =>
        savePayment({
          documentID: invoiceID,
          type: 'SIGNATURE',
          signature,
          sum: '0.00',
        }),
      );
    },
  },
};
