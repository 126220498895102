import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { getScaleInput$ } from 'actions/integrations/extdev';
import { usePosOnMessage, usePosSendMessage } from 'utils/hooks/useWrapper';

const useScaleInput = (active = true) => {
  const [ext, setExt] = useState<string | null | number>(null);
  const [wrapper, setWrapper] = useState(null);
  // From extdev
  useEffect(() => {
    if (active) {
      const sub = getScaleInput$.subscribe({
        next: val => setExt(val?.weight ?? 0),
        error: () => setExt(null),
      });
      return () => sub.unsubscribe();
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [setExt, getScaleInput$, active]);

  // From wrapper
  /* https://erply.atlassian.net/browse/PBIB-2318 */
  usePosOnMessage('scale:result', ({ weight }) => setWrapper(weight));
  const wrapperRequestWeight = usePosSendMessage('scale:weight');
  useInterval(() => wrapperRequestWeight({}), active ? 200 : null);
  return ext ?? wrapper;
};

export default useScaleInput;
