import { createSelector } from 'reselect';

import { getPluginConfiguration, getPluginSelector } from 'reducers/Plugins';
import { getCustomPaymentTypes } from 'reducers/PaymentTypes';

import {
  ENABLE_EXTERNAL_PAYMENT,
  DISABLE_EXTERNAL_PAYMENT,
  PLUGIN_ID,
  PluginConfiguration,
} from './types';

export default (state = false, { type }) => {
  switch (type) {
    case ENABLE_EXTERNAL_PAYMENT:
      return true;
    case DISABLE_EXTERNAL_PAYMENT:
      return false;
    default:
      return state;
  }
};

export const getHasExternalPaymentsForProcessing = state =>
  getPluginSelector(PLUGIN_ID)(enabled => enabled)(state);

export const getCustomPaymentTypeID = createSelector(
  getPluginConfiguration<PluginConfiguration>(PLUGIN_ID),
  s => s?.customPaymentTypeID,
);

export const getExternalPaymentProviderDomain = createSelector(
  getPluginConfiguration<PluginConfiguration>(PLUGIN_ID),
  s => s?.domain,
);

export const getSelectedCustomPaymentType = createSelector(
  getCustomPaymentTypes,
  getCustomPaymentTypeID,
  (customPaymentTypes, customPaymentTypeID) =>
    customPaymentTypes.find(p => p.id === customPaymentTypeID)?.type,
);
