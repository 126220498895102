import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { getScanningAlgorithm } from 'reducers/cafaConfigs';
import { getAllowFractionalProductQuantities } from 'reducers/configs/settings';
import { removeProduct, updateOrderAmount } from 'actions/ShoppingCart';
import { handleScanning } from 'actions/scanner';
import { Order } from 'types/ShoppingCart';

import {
  connectLastCharsInRange,
  getAutoFocusInputValue,
  getDisableOrderQty,
  getOrderAmount,
  isScannerInput,
  sanitizeValue,
} from '../utils';

const ScanningRowQuantity = React.memo<Pick<Order, 'orderIndex'>>(
  ({ orderIndex }) => {
    const dispatch = useDispatch();
    const { minScanLength, timeBeforeScanTest } = useSelector(
      getScanningAlgorithm,
    );
    const orderAmount = useSelector(getOrderAmount(orderIndex));
    const autoFocus = useSelector(getAutoFocusInputValue(orderIndex));
    const disabled = useSelector(getDisableOrderQty(orderIndex));
    const isFractionalQuantityAllowed = useSelector(
      getAllowFractionalProductQuantities,
    );

    const [previousValue, setPreviousValue] = useState(orderAmount);
    const [tempAmount, setTempAmount] = useState<typeof orderAmount | null>(
      null,
    );
    const [ts, setTs] = useState<number>(0);
    const [scanValueMap, setScanValueMap] = useState<[number, string][]>([]);
    const ref = useRef<HTMLInputElement>(null);

    // Avoids dispatching parallel calculate actions while user is typing
    const [isUpdatingOrderAmount] = useDebounce(
      async () => {
        if (
          !([null, '', '-'] as Array<typeof tempAmount>).includes(tempAmount)
        ) {
          await dispatch(updateOrderAmount(tempAmount, orderIndex));
          setTempAmount(prev => (prev !== tempAmount ? prev : null));
        }
      },
      700,
      [tempAmount],
    );

    const handleBlur = async () => {
      const [lastScanTS = 0] = scanValueMap[0] ?? [];

      if (
        String(tempAmount).trim().length &&
        ts &&
        lastScanTS < ts &&
        lastScanTS !== 0
      ) {
        dispatch(updateOrderAmount(tempAmount, orderIndex));
      }

      if (tempAmount === '-') {
        dispatch(updateOrderAmount(-1, orderIndex));
        return;
      }
      if (tempAmount === '') {
        setTempAmount(orderAmount);
      } else if (tempAmount !== null && !isUpdatingOrderAmount()) {
        await dispatch(updateOrderAmount(tempAmount, orderIndex));
        setTempAmount(null);
      }

      if (Number(orderAmount) === 0) {
        dispatch(removeProduct(orderIndex));
      }

      setTs(0);
      setScanValueMap([]);
    };

    useDebounce(
      () => {
        if (!scanValueMap.length) return;

        const [, scanVal] = connectLastCharsInRange(
          scanValueMap,
          timeBeforeScanTest,
        );
        if (scanVal.length >= minScanLength) {
          setTempAmount(previousValue);
          dispatch(handleScanning(scanVal));
          ref.current?.blur();
        } else if (scanVal.length === 1) {
          setScanValueMap([]);
        } else {
          setTempAmount(previousValue);
          ref.current?.blur();
        }
      },
      timeBeforeScanTest,
      [scanValueMap.length, ref.current],
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
        timeStamp,
      } = e;
      const [, scanVal] = connectLastCharsInRange(
        scanValueMap,
        timeBeforeScanTest,
      );

      // if we are not scanning (scanValue up to 1 char), we update the tempAmount state
      if (scanVal.length <= 1) {
        const qty = sanitizeValue(value);
        const isAcceptableValue = qty === '-' || !Number.isNaN(Number(qty));
        const respectsFractionalQtySetting =
          isFractionalQuantityAllowed ||
          (!isFractionalQuantityAllowed && !qty.includes('.'));

        if (
          isAcceptableValue &&
          respectsFractionalQtySetting &&
          qty !== tempAmount
        ) {
          if (tempAmount !== null) setPreviousValue(tempAmount);
          setTempAmount(qty);
        }
      }
      // we update the state of the timeStamp  regardless if it was a scan or user input to properly calculate the next input interval
      setTs(timeStamp);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      const { key, timeStamp } = e;

      if (key === 'Enter') {
        // only blur on user pressing Enter, if scanner ends with /r, blur would be handled by useDebounce
        if (!isScannerInput(ts, timeStamp, timeBeforeScanTest)) {
          ref.current?.blur();
        }
        return;
      }

      if (key === 'Backspace' || key === 'Delete') {
        setScanValueMap([]);
        return;
      }

      // filter out special keyboard characters - `Enter`, `Shift`, etc.
      const char = key.length === 1 ? key : '';

      if (char) {
        setScanValueMap(s => [[timeStamp, char], ...s]);
      }
    };

    return (
      <input
        type="text"
        ref={ref}
        id="autoFocusInput"
        value={tempAmount !== null ? tempAmount : orderAmount}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onClick={e => e.stopPropagation()}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        autoComplete="off"
        onFocus={autoFocus ? e => e.target.select() : undefined}
      />
    );
  },
);

export default ScanningRowQuantity;
