import { doClientRequest } from './core/ErplyAPI';

export const getProductGroups = params =>
  doClientRequest({ request: 'getProductGroups', ...params });

/**
 * @param {{
 *   productGroupID: string,
 *   name: string,
 *   nameEST: string,
 *   nameENG: string,
 *   nameLAT: string,
 *   nameRUS: string,
 *   nameFIN: string,
 *   parentGroupID: string,
 *   showInWebshop: number,
 * }} params
 * @returns {Promise<any>}
 */
export const saveProductGroup = params =>
  doClientRequest({ request: 'saveProductGroup', ...params });
