import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InputFieldBase from 'components/FieldTypes/InputField';
import { version } from 'external_data';
import loginskin from 'components/FieldTypes/skins/skins.module.scss';
import CloseButton from 'components/CustomButtons/CloseButton';

import { styles } from '../util';
import * as Validators from '../validations';

const InputField = props => (
  <InputFieldBase size="lg" className={loginskin.loginInput} {...props} />
);

const SignUpDialog = ({
  onCancel,
  onOpenDemo,
  onOpenFullAccountForm,
  email,
  country,
  countries,
  errorField,
  updateEmail,
  updateCountry,
}) => {
  const [showErrors, toggleShowErrors] = useState(false);
  const { t } = useTranslation('login');
  const isValidEmail = email => !Validators.validateEmail(email).length;
  const isValidSelect = select => !Validators.validateSelect(select).length;

  return (
    <Card style={styles.card}>
      <Card.Header style={styles.header}>
        {t('signup.title')}
        <CloseButton action={onCancel} />
      </Card.Header>
      <Card.Body className="p-sm-4 p-0 pb-4">
        <InputField
          title="country"
          name={t('inputs.country')}
          type="select"
          autoFocus={true}
          options={countries}
          value={country}
          onChange={event => updateCountry(event.target.value)}
          isRequired={true}
          isInvalid={
            showErrors && (!isValidSelect(country) || errorField === 'country')
          }
        />
        <InputField
          title={t('inputs.email')}
          name="email"
          type="email"
          value={email}
          onChange={event => updateEmail(event.target.value)}
          isRequired={true}
          isInvalid={
            showErrors && (!isValidEmail(email) || errorField === 'email')
          }
        />
        <Container>
          <Row className="dark-row">
            <Col sm={6} xs={12}>
              <Button
                disabled={!isValidEmail(email) || !isValidSelect(country)}
                onClick={() => {
                  onOpenDemo(country, email).then(() => toggleShowErrors(true));
                }}
                style={{ flexGrow: 1, ...styles.button }}
                size="lg"
                variant="secondary"
              >
                {t('quickDemoButton')}
              </Button>
            </Col>
            <Col sm={6} xs={12}>
              <Button
                disabled={!isValidEmail(email) || !isValidSelect(country)}
                onClick={() => {
                  onOpenFullAccountForm(country, email);
                  toggleShowErrors(true);
                }}
                style={{ flexGrow: 1, ...styles.button }}
                size="lg"
                variant="primary"
              >
                {t('registerAccountButton')}
              </Button>
            </Col>
          </Row>
        </Container>
        <span style={styles.version}>{version}</span>
      </Card.Body>
    </Card>
  );
};

SignUpDialog.propTypes = {
  onCancel: PropTypes.func,
  onOpenDemo: PropTypes.func,
  onOpenFullAccountForm: PropTypes.func,
  email: PropTypes.string,
  country: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  countries: PropTypes.arrayOf(PropTypes.object),
  errorField: PropTypes.string,
  updateEmail: PropTypes.func,
  updateCountry: PropTypes.func,
};

export default SignUpDialog;
