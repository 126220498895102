import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { createSelector } from 'reselect';
import { Action } from 'redux';

import { getClientCode, getSessionKey } from 'reducers/Login';
import { RootState } from 'reducers';

import { FindAddressResponse, RetrieveAddressResponse } from './types';

const baseUrl = 'https://p2-plugin17s.erply.com/v1/address-completions/';

const getHeaders = createSelector(
  getClientCode,
  getSessionKey,
  (clientCode, sessionKey) => ({
    'Erply-Api-Client-Code': clientCode,
    'Erply-Api-Session-Key': sessionKey,
  }),
);

export const findAddresses = (
  country: string,
  searchTerm: string,
  lastId = '',
) => async (dispatch: ThunkDispatch<RootState, unknown, Action>, getState) => {
  const headers = getHeaders(getState);
  const response = await axios.get<FindAddressResponse>(`${baseUrl}find`, {
    params: {
      LastId: lastId,
      SearchFor: 'Everything',
      Country: country,
      SearchTerm: searchTerm,
    },
    headers,
  });
  return response.data.Items;
};

export const retrieveAddress = (id: string) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState,
) => {
  const headers = getHeaders(getState);
  const response = await axios.get<RetrieveAddressResponse>(
    `${baseUrl}retrieve`,
    {
      params: {
        Id: id,
      },
      headers,
    },
  );
  return response.data.Items?.[0];
};
