import { getEmployeeIdentifierOnReceipt } from 'reducers/configs/settings';
import { trimComma } from 'utils';
import { getEmployeeByIDAsync } from 'utils/hooks/useEmployeeHook';

export function getEmployeeIdentifierAsync(employeeID: string | number) {
  return async (dispatch, getState) => {
    const receiptNameOption = getEmployeeIdentifierOnReceipt(getState());
    const employee = await dispatch(getEmployeeByIDAsync(employeeID));
    if (!employee) {
      console.warn('Unable to find employee by id', employeeID);
      return '';
    }

    switch (receiptNameOption) {
      case 'first_name':
        return trimComma(`${employee.firstName}`);
      case 'id':
        return trimComma(`${employee.id}`);
      case 'first_name_and_initial':
        return trimComma(
          `${employee.firstName}, ${employee.lastName?.charAt(0)}`,
        );
      default: // if nothing selected on BO, default to fullName
      case 'full_name':
        return trimComma(`${employee.fullName}`);
    }
  };
}
