import { PosPlugin } from 'plugins/plugin';

const UIReturnOrderProduct: PosPlugin['UIReturnOrderProduct'] = ({
  row,
  children,
}) => {
  if (
    !row.remainingAmount &&
    row.children.every(nestedRow => !nestedRow.remainingAmount)
  ) {
    return null;
  }

  return children;
};

export default UIReturnOrderProduct;
