import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { v4 as uuidv4 } from 'uuid';

const positionOf = element => {
  const rect = element.getBoundingClientRect();
  return (rect.left + rect.right) / 2;
};

const columnHeader = uuidv4();
const Reorderable = ({ children, moveTo, prependCols }) => {
  const [dragging, setDragging] = useState(false);

  const handleDrop = e => {
    const headerElements = [...document.getElementsByClassName(columnHeader)];

    const newOrder = headerElements
      .sort((a, b) => positionOf(b) - positionOf(a))
      .reverse()
      .map(header => Number(header.dataset.index));

    setDragging(null);
    moveTo(
      dragging,
      newOrder.findIndex(index => index === dragging),
    );
  };

  return (
    <>
      {prependCols}
      {React.Children.map(children, (child, i) => (
        <Draggable
          axis="x"
          onStart={() => setDragging(i)}
          position={dragging ? null : { x: 0, y: 0 }}
          onStop={handleDrop}
          cancel=".cancel-draggable"
          key={child.key}
        >
          {React.cloneElement(child, { className: columnHeader, dataindex: i })}
        </Draggable>
      ))}
    </>
  );
};
export default Reorderable;
