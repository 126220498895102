import { SOName } from './types';
import getDB from './getDB';

/*
 * "What is the type of the primary key in the indexedDB table": a summary
 *
 * products           - number
 * coupons            - number
 * productStockLevels - [number, number]
 * campaings          - number
 * priceLists         - number
 * assortments        - [???,???]
 * employees          - string
 *
 * Wasn't able to check assortments because i don't have any saved
 */
const removeItemsFromSO = async (clientCode, SO_NAME: SOName, items: any[]) => {
  switch (SO_NAME) {
    /** type = string */
    case 'employees':
      // eslint-disable-next-line no-param-reassign
      items = items.map(String);
      break;

    /** type = [???,???] */
    case 'productStockLevels':
    case 'assortments':
      console.warn(
        'Unhandled type',
        SO_NAME,
        'for getUserOperationsLog (deleted). Database uses compound keys but API returns',
        JSON.stringify(items),
      );
      break;

    /** type = number */
    default:
      // Indexed by number in indexedDB, already OK
      break;
  }
  // if there's anything to remove and if client code is provided, delete
  if (items?.length && clientCode) {
    const db = await getDB(clientCode);
    const tx = db.transaction(SO_NAME, 'readwrite');
    await Promise.all([
      ...items.map((item: any) => tx.store.delete(item)),
      tx.done,
    ]);
  }
};

export default removeItemsFromSO;
