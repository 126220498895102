export enum DisabledOn {
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  ON_EDIT = 'ON_EDIT',
}

/* eslint-disable @typescript-eslint/camelcase */
export type FormFieldConfiguration = {
  enabled: boolean;
  required: boolean;
  label?: string;
  order: number;
  defaultValue?: string | number;
  disabledOn?: DisabledOn;
};
export const ADDRESS_FIELDS = [
  'addressTypeID',
  'street',
  'address2',
  'postalCode',
  'city',
  'state',
  'country',
] as const;
const PERSON_FIELDS = [
  'firstName',
  'lastName',
  'personTitleID',
  'gender',
  'jobTitleID',
  'customerCardNumber',
  'euCustomerType',
  'groupID',
  'homeStoreID',
  'email',
  'phone',
  'fax',
  'mobile',
  'birthday',
  'id',
  ...ADDRESS_FIELDS,
  'code',
  'notes',
  'emailOptOut',
  'taxExempt',
  'doNotSell',
  'salesBlocked',
] as const;
const COMPANY_FIELDS = [
  'companyName',
  'id',
  'vatNumber',
  'customerCardNumber',
  'euCustomerType',
  'groupID',
  'homeStoreID',
  'email',
  'phone',
  'fax',
  'mobile',
  ...ADDRESS_FIELDS,
  'code',
  'notes',
  'emailOptOut',
  'taxExempt',
  'doNotSell',
  'salesBlocked',
] as const;

type FormFields = {
  PERSON: Record<typeof PERSON_FIELDS[number], FormFieldConfiguration>;
  COMPANY: Record<typeof COMPANY_FIELDS[number], FormFieldConfiguration>;
};
export type FormFieldName =
  | typeof PERSON_FIELDS[number]
  | typeof COMPANY_FIELDS[number];
export type CustomerType = 'PERSON' | 'COMPANY';
export type CustomerFormConfiguration = {
  allowedCustomerTypes: CustomerType | '';
  formFields: FormFields;
};

export type CustomerPOSConfiguration = {
  customer_form_configuration: CustomerFormConfiguration;
};

export const CUSTOMER_TYPES = {
  PERSON: 'PERSON',
  COMPANY: 'COMPANY',
} as const;

export const FORM_FIELDS = {
  [CUSTOMER_TYPES.PERSON]: PERSON_FIELDS,
  [CUSTOMER_TYPES.COMPANY]: COMPANY_FIELDS,
} as const;

export const FIELDS_WITH_DEFAULT_VALUES = [
  'euCustomerType',
  'groupID',
  'homeStoreID',
  'emailOptOut',
  'taxExempt',
  'doNotSell',
  'salesBlocked',
  'country',
];

export const NAME_FIELDS = ['firstName', 'lastName', 'companyName'];
