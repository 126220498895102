import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { displayView } from 'constants/UI';
import {
  getGridIsOpened,
  getGridView,
  getIsRootProductGroupSelected,
} from 'reducers/UI/gridDisplay';
import { PluginComponent } from 'plugins';

import MainView from './GridViews/MainView';
import MixedView from './GridViews/MixedView';
import ProductGroupsView from './GridViews/ProductGroupsView';
import Actions from './Actions';
import QuickSelectView from './GridViews/QuickSelectView';

import './index.css';

const Col2 = () => {
  const opened = useSelector(getGridIsOpened);
  const view = useSelector(getGridView);
  const isRootGroup = useSelector(getIsRootProductGroupSelected);

  const viewToShow = useMemo(() => {
    switch (view) {
      case displayView.main:
        return isRootGroup ? <MainView /> : <MixedView />;
      case displayView.productGroups:
        return <ProductGroupsView />;
      case displayView.quickSelect:
        return <QuickSelectView />;
      default:
        return <MainView />;
    }
  }, [view, isRootGroup]);
  return (
    <PluginComponent hookname="UICol2">
      <div className="col-2-container">
        {opened && viewToShow}
        <Actions isLinear={!opened} />
      </div>
    </PluginComponent>
  );
};

export default Col2;
