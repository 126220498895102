export const GET_SALES_DOCUMENTS = 'SALES:GET_SALES_DOCUMENTS';
export const GET_SALES_DOCUMENTS_SUCCESS = 'SALES:GET_SALES_DOCUMENTS_SUCCESS';
export const GET_SALES_DOCUMENTS_FULL_RESPONSE_SUCCESS =
  'SALES:GET_SALES_DOCUMENTS_FULL_RESPONSE_SUCCESS';
export const GET_SALES_DOCUMENTS_ERROR = 'SALES:GET_SALES_DOCUMENTS_ERROR';

export const SAVE_SALES_DOCUMENT = {
  START: 'SALES:SAVE_SALES_DOCUMENT_START',
  SUCCESS: 'SALES:SAVE_SALES_DOCUMENT_SUCCESS',
  ERROR: 'SALES:SAVE_SALES_DOCUMENT_ERROR',
};

export const GET_PREPAYMENT_PERCENTAGES = {
  START: 'SALES:GET_PREPAYMENT_PERCENTAGES_START',
  SUCCESS: 'SALES:GET_PREPAYMENT_PERCENTAGES_SUCCESS',
  ERROR: 'SALES:GET_PREPAYMENT_PERCENTAGES_ERROR',
};

export const UPDATE_CURRENT_SALE_DOC = 'SALES:UPDATE_CURRENT_SALE_DOC';
export const SET_CURRENT_SALE_DOC = 'SALES:SET_CURRENT_SALE_DOC';
export const RESET_CURRENT_SALES_DOC = 'SALES:RESET_CURRENT_SALES_DOC';

export const SET_CURRENT_SALES_DOC_PAYMENTS = {
  START: 'SALES:SET_CURRENT_SALES_DOC_PAYMENTS_START',
  SUCCESS: 'SALES:SET_CURRENT_SALES_DOC_PAYMENTS_SUCCESS',
  ERROR: 'SALES:SET_CURRENT_SALES_DOC_PAYMENTS_ERROR',
};
export const RESET_CURRENT_SALES_DOC_PAYMENTS =
  'SALES:RESET_CURRENT_SALES_DOC_PAYMENTS';

export const SET_CURRENT_SALE_AS_RETURN = 'SALES:SET_CURRENT_SALE_AS_RETURN';
export const RESET_CURRENT_SALE_AS_RETURN =
  'SALES:RESET_CURRENT_SALE_AS_RETURN';

export const SET_CURRENT_SALE_AS_GIFT_RETURN =
  'SALES:SET_CURRENT_SALE_AS_GIFT_RETURN';

export const SET_LAST_SALES_DOC = 'SALES:SET_LAST_SALE_DOC';
export const RESET_LAST_SALES_DOC = 'SALES:RESET_LAST_SALE_DOC';

export const SET_PAYMENT_SALE_DOC = 'PAYMENT: SET_PAYMENT_SALE_DOC';
export const UPDATE_PAYMENT_SALE_DOC = 'PAYMENT: UPDATE_PAYMENT_SALE_DOC';
export const RESET_PAYMENT_SALES_DOC = 'PAYMENT: RESET_PAYMENT_SALES_DOC';

export const SET_LAST_PAYMENTS = 'SALES:SET_LAST_PAYMENTS';
export const DELETE_LAST_PAYMENT_BY_KEY = 'SALES:DELETE_LAST_PAYMENT_BY_KEY';

export const SET_SALE_EMPLOYEE = 'SALES:SET_SALE_EMPLOYEE';

export const SET_USED_REWARD_POINTS = 'SALES:SET_USED_REWARD_POINTS';

export const SET_PICKUP_IN_PROGRESS = 'SALES:SET_PICKUP_IN_PROGRESS';
