import { getJunctionAPI } from '../requests';
import { JunctionAPIResponse } from '../types';
import { functions } from '../index';
import { validateSuccess } from '../errors/validateSuccess';
import { parseError } from '../errors/parseError';

const mockPayment = (amount: string, refNo: string) => ({
  transactionStatus: '',
  resultCode: '0',
  approvedAmount: amount,
  authCode: 'force-succeeded by cashier',
  cardHolder: '',
  cardNumber: '',
  paymentType: '',
  entryMode: '',
  statusMessage: `Force-succeeded by cashier`,
  referenceNumber: refNo,
  dateTime: '',
  transactionType: '',
  aid: '',
  applicationLabel: '',
  pinStatement: '',
  expirationDate: '',
  cryptogramType: '',
  cryptogram: '',
  signature: '',
  signatureRequired: '',
  hostCode: '0',
  hostResponse: '',
  cardBalance: '',
  cardType: '',
  hostReferenceNumber: refNo,
  cardholderVerificationMethod: '',
  invoiceNumber: '',
  authID: '',
  additionalData: {
    seq: '',
    receipt: [],
    issuerAuthenticationData: '',
    pos: '',
    storeId: '',
    terminalVerificationResults: '',
    transactionStatusInformation: '',
    merchantId: '',
  },
});

/**
 * Enable the invisible-by-default forceCancel and forceSuccess buttons, return a promise that will resolve or reject when / based on which button is pressed
 *
 * Massive hack to implement dynamic buttons. Force buttons hidden to not distract the user during normal workflow
 * and not tempt cashiers to start clicking on them
 */
const getForceButton = enableButtons => {
  const btnSuccess = functions.find(btn => btn.name === 'forceSuccess')!;
  const btnCancel = functions.find(btn => btn.name === 'forceCancel')!;
  btnSuccess.variant = 'danger';
  btnCancel.variant = 'danger';
  btnSuccess.text = 'Succeeded';
  btnCancel.text = 'Failed / Cancelled / Voided';
  enableButtons(['forceCancel', 'forceSuccess']);
  return new Promise(resolve => {
    btnSuccess.actionOnClick = () => async () => resolve(true);
    btnCancel.actionOnClick = () => async () => resolve(false);
  }).finally(() => {
    delete btnSuccess.actionOnClick;
    delete btnCancel.actionOnClick;
    btnSuccess.text = '';
    btnCancel.text = '';
    btnSuccess.variant = 'plain';
    btnCancel.variant = 'plain';
    enableButtons([]);
  });
};

/**
 * Attempt automatic void, then if that fails offer the cashier to either force succeed or force cancel the transaction
 *
 * NB: Force success returns a fake response object
 */
export const attemptRecovery = (
  refNo: string,
  payment: { uuid: string; clientCode: string; key: string; amount: string },
  cause: Error,
  updateMessage,
  enableButtons: (buttons: string[]) => void,
) => async (dispatch, getState): Promise<{ data: JunctionAPIResponse }> => {
  updateMessage(
    `Initial request failed due to:\n${cause}\n\nattempting recovery`,
  );
  const api = await dispatch(getJunctionAPI);
  // Attempt to void
  return api
    .voidTransaction(refNo, payment.uuid, payment.clientCode)
    .then(validateSuccess, parseError)
    .then(
      // Void successful, throw an error to signify the original transaction has 'failed'
      () => {
        updateMessage(
          `Initial request failed due to:\n${cause}\n\nTransaction successfully voided`,
        );
        throw new Error('Transaction voided');
      },
      // Void failed, require the cashier to choose an outcome using the forceSuccess/forceCancel buttons
      async e => {
        updateMessage(
          `Initial request failed due to:\n${cause}\n\nRecovery failed due to:\n${e}\n\nThe POS can not determine if the payment went through or not - please verify the outcome manually and press the correspending button`,
        );

        const isForceSuccess = await getForceButton(enableButtons);
        if (isForceSuccess) {
          // Cashier requested success, return a fake response to 'succeed' the original transaction
          return {
            data: {
              utcDateTime: '',
              recordsCount: 1,
              records: [mockPayment(payment.amount, refNo)],
            },
          };
        }

        // Cashier requested failure, throw an error to signify the original transaction has failed
        throw new Error('Force-cancelled by cashier after failed recovery', {
          cause: e,
        });
      },
    );
};
