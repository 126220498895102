import dayjs from 'dayjs';
import debug from 'debug';

import { getSuretaxApi } from 'plugins/suretax/Configuration';
import { getProductsUniversal } from 'actions/productsDB';
import { getLoggedInWarehouse } from 'reducers/warehouses';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { TaxGrouping } from 'plugins/suretax/api/types/util/TaxGrouping';
import { ProviderTypeCode } from 'plugins/suretax/api/types/codes/ProviderTypeCode';
import { suretaxReducer } from 'plugins/suretax/redux';
import { TaxExemptionRecordIndicator } from 'plugins/suretax/api/types/codes/TaxExemptionRecordIndicator';
import { TaxSitusRule } from 'plugins/suretax/api/types/codes/TaxSitusRule';
import { UnitTypeCodes } from 'plugins/suretax/api/types/codes/UnitTypeCodes';
import { getTotalNet } from 'reducers/ShoppingCart';
import { PosPlugin } from 'plugins/plugin';

const suretaxLog = debug('plugin: suretax');
const log = suretaxLog.extend('updateSuretaxFromOnCalculate');

type AP = Parameters<Required<Required<PosPlugin>['onCalculate']>['after']>[1];
/**
 * Given onCalculate props (at least the ap)
 * * Send a Suretax Q request
 * * Save the results in suretaxReducer
 */
export const fetchSuretaxInfo = (ap: AP) => async (dispatch, getState) => {
  // Get API
  const api = getSuretaxApi(getState());

  // Load product information (for codes)
  const { productsDict } = await dispatch(
    getProductsUniversal({
      productIDs: Object.values(ap.rows)
        .map(r => r.computed.productID)
        .map(Number),
    }),
  );

  // Prepare other necessary information
  const now = dayjs();
  const fullDate = now.format('MM/DD/YYYY');
  const year = now.format('YYYY');
  const month = now.format('MM');

  const warehouse = getLoggedInWarehouse(getState());
  const customer = getSelectedCustomer(getState());

  // Prepare full suretax request
  const request: Parameters<typeof api.makeRequest>[0] = {
    CmplDataMonth: month,
    CmplDataYear: year,
    DataMonth: month,
    DataYear: year,
    ResponseGroup: TaxGrouping.T_00,
    ResponseType: '1D2',
    ReturnFileCode: 'Q',
    STAN: '',
    TotalRevenue: getTotalNet(getState()),
    ItemList: Object.values(ap.rows).map(({ computed: item }) => ({
      // fallback onto customerID explicitly discussed and requested
      // Justification: Useful for debugging/cross-reference, customer codes (from D360) will be much larger than Erply customerIDs and thus people will not confluse the two
      CustomerNumber: customer.code || customer.customerID,
      LineNumber: String(item.rowNumber),
      LocationCode: warehouse.code,
      OrigNumber: '', // not required with TaxSitusRule SHIP_TO_ADDR
      RegulatoryCode: ProviderTypeCode.RETAIL,
      Revenue: item.rowNetTotal.toFixed(4),
      SalesTypeCode: 'R',
      ShipToAddress: {
        PostalCode:
          suretaxReducer.getShippingZip(getState()) ?? warehouse.ZIPcode, // Default to in-store pickup, but allow override from suretaxReducer
        VerifyAddress: '0', // Not sending an address, nothing to verify
      },
      TaxExemptionCodeList: [TaxExemptionRecordIndicator.NONE],
      TaxIncludedCode: '0',
      TaxSitusOverrideCode: '',
      TaxSitusRule: TaxSitusRule.SHIP_TO_ADDR,
      TransDate: fullDate,
      TermNumber: '',
      TransTypeCode: productsDict[item.productID].code.slice(0, 5), // Hammacher requested to only send first 5 characters of product code
      UnitType: UnitTypeCodes.DEFAULT,
      Units: String(item.amount),
    })),
  };

  log('Checking against suretax', { request });
  const response = await api.makeRequest(request);
  log('Updating current in redux', response);
  await dispatch(suretaxReducer.setCurrent(request, response));
  return response;
};
