import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { PosPlugin } from 'plugins/plugin';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { CAFA_ENTRY, CAFA_LEVELS, INTEGRATION_TYPES } from 'constants/CAFA';
import { Customer } from 'types/Customer';
import { getSelectedPosID } from 'reducers/PointsOfSale';

import { getPluginHooksByName } from '../reducers/Plugins';

import { saveCafaConfig } from './integrations/CafaConfigs';

// @ts-ignore
export function disablePlugin(plugin: PosPlugin): Promise {
  return async (dispatch, getState) => {
    const config = getCafaEntry(plugin.id, INTEGRATION_TYPES.brazilPlugin, {
      level: CAFA_LEVELS.Pos,
      id: getSelectedPosID(getState()),
    })(getState());
    if (plugin.onUnmount) {
      dispatch(plugin.onUnmount());
    }
    dispatch(
      saveCafaConfig({
        integrationType: INTEGRATION_TYPES.brazilPlugin,
        integrationName: plugin.id,
        config: {
          ...(config?.value as any),
          enabled: false,
        },
      }),
    );
  };
}

// @ts-ignore
export function enablePlugin(plugin: PosPlugin): Promise {
  return async (dispatch, getState) => {
    const config = getCafaEntry(plugin.id, INTEGRATION_TYPES.brazilPlugin, {
      level: CAFA_LEVELS.Pos,
      id: getSelectedPosID(getState()),
    })(getState());
    if (plugin.onMount) {
      dispatch(plugin.onMount());
    }
    dispatch(
      saveCafaConfig({
        integrationType: INTEGRATION_TYPES.brazilPlugin,
        integrationName: plugin.id,
        config: {
          ...(config?.value as any),
          enabled: true,
        },
      }),
    );
  };
}

export function savePluginConfig(
  plugin: PosPlugin,
  config,
  level?: {
    level: CAFA_ENTRY['level'];
    id: CAFA_ENTRY['level_id'];
  },
) {
  return async (dispatch, getState) => {
    const entry = getCafaEntry(
      plugin.id,
      INTEGRATION_TYPES.brazilPlugin,
      level,
    )(getState());
    dispatch(
      saveCafaConfig({
        integrationType: INTEGRATION_TYPES.brazilPlugin,
        integrationName: plugin.id,
        config: {
          ...(entry?.value as any),
          config,
        },
        level,
      }),
    );
  };
}

/**
 * Given the name of a custom action (defined in PosPlugin.reduxActions[actionName])
 * dispatches those actions from all plugins that have handlers for them
 * @param {string} actionName Name of the actions as defined in the plugin's reduxActions object
 * @param {any} params Parameters to pass to the actions
 * @returns {(dispatch, getState) => Promise<any[]>} A thunk action that when dispatched,
 * returns an array of all the return values from all the plugins' actions that were called
 * @example
 * // yourPlugin.ts
 * const MyPlugin: PosPlugin {
 *    reduxActions: {
 *      sayCheese: (level: number, message:string) => async (dispatch, getState) => {
 *        console.log(`Lvl ${level} cheese:`, message);
 *      }
 *    }
 * }
 * // Somewhere else in the codebase
 * dispatch(pluginAction('sayCheese', 2, 'Louise')) // logs 'Lvl 2 cheese: Louise'
 */
export function pluginAction(
  actionName: keyof NonNullable<PosPlugin['reduxActions']>,
) {
  return (...params: any) => async (dispatch, getState) => {
    const thunks = getPluginHooksByName<PosPlugin['reduxActions']>(
      'reduxActions',
    )(getState()).flatMap(acts => acts[actionName] ?? []);

    return Promise.all(thunks.map(t => dispatch(t(...params)))).catch(e => {
      if (process.env.NODE_ENV === 'development') {
        console.error('Plugin action', actionName, 'failed with error', e);
      }
      throw e;
    });
  };
}

/**
 * Collects all onMount actions from all active plugins and dispatches them
 */
export function pluginSetupOnMount() {
  return async (dispatch, getState) => {
    try {
      const actions = getPluginHooksByName<PosPlugin['onMount']>('onMount')(
        getState(),
      );
      actions.forEach(act => dispatch(act()));
    } catch (err) {
      console.error('Plugin onMount action failed.');
    }
  };
}

/**
 * Collects all onMount actions from all active plugins and dispatches them
 */
export function pluginCleanupOnUnmount() {
  return async (dispatch, getState) => {
    try {
      const actions = getPluginHooksByName<PosPlugin['onUnmount']>('onUnmount')(
        getState(),
      );
      actions.map(act => dispatch(act()));
    } catch (err) {
      console.error('Plugin onUnmount action failed.');
    }
  };
}

export function afterSuccessfulPaymentConfirmation(props: any = undefined) {
  return async (dispatch: any, getState: any) => {
    const funcs = getPluginHooksByName<any>('afterSuccessfulPaymentConfirmation')(
      getState(),
    );
    await Promise.all(funcs.map(f => dispatch(f(props))));
  };
}

export function onTaxExempt(props) {
  return async (dispatch: any, getState: any) => {
    const funcs = getPluginHooksByName<any>('onTaxExempt')(getState());
    await Promise.all(funcs.map(f => dispatch(f(props))));
  };
}
// TODO - refactor and extract this hook so it follows the hook standards
export function beforeShowCustomerDetails(customer: Customer) {
  return async (
    dispatch: ThunkDispatch<void, unknown, Action>,
    getState: () => unknown,
  ) => {
    const funcs = getPluginHooksByName<
      (customer: Customer) => ThunkAction<unknown, unknown, unknown, Action>
    >('beforeShowCustomerDetails')(getState());
    await Promise.all(funcs.map(f => dispatch(f(customer))));
  };
}
