import React from 'react';
import { useSelector } from 'react-redux';

import { useBreakpoints } from 'utils/hooks/UI';
import { getIsPaymentDisabled, getCashPayment } from 'reducers/Payments';
import { getActiveCustomPaymentTypes } from 'reducers/PaymentTypes';
import {
  getIsCashWithdrawalDisabled,
  getIsCustomPaymentEnabled,
} from 'reducers/configs/settings';
import { ExternalPaymentActions } from 'containers/Forms/IntegrationUIComponents/External/External';
import { getActivePaymentIntegration } from 'reducers/cafaConfigs';
import { PluginComponent } from 'plugins';

import {
  AddChangePayment,
  AddCardPayment,
  AddCashPayment,
  AddFullCardPayment,
  AddFullCashPayment,
  AddTip,
  AddCheckPayment,
  ConfirmPayment,
  ClosePayment,
  AddGiftCard,
  AddStoreCredit,
} from './components';
import styles from './PaymentActions.module.scss';
import CustomPayment from './components/CustomPayment';

const PaymentActions = ({ voidHandler }) => {
  const isMobileView = !useBreakpoints().mdplus;
  const checkPaymentIsDisabled = useSelector(getIsPaymentDisabled);
  const cashPayment = useSelector(getCashPayment);
  const activeCustomPaymentTypes = useSelector(getActiveCustomPaymentTypes);
  const isCustomPaymentEnabled = useSelector(getIsCustomPaymentEnabled);
  const selectedIntegration = useSelector(getActivePaymentIntegration);

  const hasSelectedPositiveCash = cashPayment && cashPayment.type === 'CASH';
  const isCashWithdrawalDisabled = useSelector(getIsCashWithdrawalDisabled);

  const addCashOrChangePayment =
    hasSelectedPositiveCash && !isCashWithdrawalDisabled ? (
      <AddChangePayment disabled={checkPaymentIsDisabled('CHANGE')} />
    ) : (
      <AddCashPayment disabled={checkPaymentIsDisabled('CASH')} />
    );
  return (
    <PluginComponent hookname="UIPaymentActions" props={{ voidHandler }}>
      {isMobileView ? (
        <div className={styles['payment-actions']}>
          <ul className="actions-list-group">
            {!checkPaymentIsDisabled('CASH') && addCashOrChangePayment}
            {!checkPaymentIsDisabled('CARD') && <AddCardPayment />}
            {!checkPaymentIsDisabled('CARD') && <AddFullCardPayment />}
            {!checkPaymentIsDisabled('CASH') && <AddFullCashPayment />}
            {!checkPaymentIsDisabled('CHECK') && <AddCheckPayment />}
            {!checkPaymentIsDisabled('GIFTCARD') && <AddGiftCard />}
            {!checkPaymentIsDisabled('STORECREDIT') && <AddStoreCredit />}
            {!checkPaymentIsDisabled('TIP') && <AddTip />}
            {activeCustomPaymentTypes &&
              isCustomPaymentEnabled &&
              activeCustomPaymentTypes.map(type => (
                <CustomPayment key={type} type={type} />
              ))}
          </ul>
          <ConfirmPayment />
        </div>
      ) : (
        <>
          <div className={styles['payment-actions']}>
            <div style={{ float: 'left' }}>
              {!checkPaymentIsDisabled('CARD') && <AddCardPayment />}
              {!checkPaymentIsDisabled('CASH') && addCashOrChangePayment}
              {!checkPaymentIsDisabled('CARD') && <AddFullCardPayment />}
              {!checkPaymentIsDisabled('CASH') && <AddFullCashPayment />}
            </div>
            <div style={{ float: 'right' }}>
              <ClosePayment voidHandler={voidHandler} />
              <ConfirmPayment />
            </div>
          </div>
          <div className={styles['payment-actions']}>
            <div style={{ float: 'left' }}>
              {activeCustomPaymentTypes &&
                isCustomPaymentEnabled &&
                activeCustomPaymentTypes.map(type => (
                  <CustomPayment key={type} type={type} />
                ))}
            </div>
          </div>
          {selectedIntegration === 'external' && <ExternalPaymentActions />}
        </>
      )}
    </PluginComponent>
  );
};

export default PaymentActions;
