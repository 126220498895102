import { terminalDiagnosis } from 'paymentIntegrations/adyen/requests/adyenRequests';
import { withWaitingForTerminal } from 'paymentIntegrations';
import { getCardPaymentsForIntegration } from 'reducers/Payments';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';

import { setCurrentPayment } from '../utils';

import adyenPayment from './payment';
import adyenRefund from './fullRefund';
import adyenVoid from './void';

const process = async (payment, params, splits) => {
  const { checkTerminalBeforeOperation } = params.adyenConfig;

  if (checkTerminalBeforeOperation) {
    const terminalStatus = await terminalDiagnosis();

    // On successful diagnosis will always return resultCode: "0"
    if (terminalStatus.resultCode !== '0') {
      const { enableButtons, updateMessage } = params;
      enableButtons([
        FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
        FUNC_BUTTONS.RETRY,
        FUNC_BUTTONS.CLOSE,
      ]);
      return updateMessage(
        `Terminal connection is not OK!\n ${terminalStatus.statusMessage}`,
      );
    }
  }

  if (payment.paid && payment.shouldProcess) {
    return adyenVoid(payment, params);
  }

  if (payment.amount < 0 && !payment.paid) {
    return adyenRefund(payment, params);
  }

  if (!payment.paid) {
    const cardPaymentData = {
      id: payment.id,
      amount: payment.amount,
      key: payment.key,
    };

    setCurrentPayment(payment);

    params.updateMessage('Performing payment...');
    params.enableButtons([FUNC_BUTTONS.CANCEL]);

    return adyenPayment(cardPaymentData, params, splits);
  }

  return Promise.resolve(true);
};

const generateID = () => {
  return Math.floor(Date.now())
    .toString()
    .slice(3);
};

export default params => async (dispatch, getState) => {
  let errors;
  const cardPayments = getCardPaymentsForIntegration('adyen')(getState());
  const shouldVoid = cardPayments.every(cp => cp.paid && cp.shouldProcess);

  const data = await cardPayments
    .filter(p => (shouldVoid ? p.paid : !p.paid))
    .map((payment, index) => ({
      ...payment,
      clientCode: params.clientCode,
      paymentID: generateID() + index,
    }))
    .reduce((previous, next) => {
      return previous.then(acc => {
        if (!errors) {
          return dispatch(
            withWaitingForTerminal(async () => {
              try {
                const res = await process(next, params, params.splits);
                if (res) {
                  return [...acc, next];
                }
                errors = true;
                return acc;
              } catch (err) {
                console.error(err);
                errors = true;
                return acc;
              }
            }),
          );
        }
      });
    }, Promise.resolve([]));
  return { errors, data };
};
