/* eslint-disable no-console */
import { Product } from 'types/Product';
import { notUndefinedOrNull } from 'utils/tsHelpers';

import { SO, SOName } from './types';
import getIndexedDB from './getDB';

const getAllWords = (text: string) => {
  const allWordsIncludingDups = text.split(' ');
  const wordSet = allWordsIncludingDups
    .filter(word => word?.trim()?.length)
    .reduce((prev, current) => {
      // eslint-disable-next-line no-param-reassign
      prev[current.toUpperCase()] = true;
      return prev;
    }, {});
  return Object.keys(wordSet).concat(text.toUpperCase());
};

const getAllCodes = (item: Product) => {
  const splitCodes = (code: string) => code?.split(',') ?? [];
  return [
    ...splitCodes(item.code),
    ...splitCodes(item.code2),
    ...splitCodes(item.code3),
    ...splitCodes(item.supplierCode),
    ...splitCodes(item.code5),
    ...splitCodes(item.code6),
    ...splitCodes(item.code7),
    ...splitCodes(item.code8),
  ].filter(code => code?.trim()?.length > 0);
};

const prepareItemForSO = (
  SO_NAME: SOName,
  item,
  meta?: { warehouseID?: number; assortmentID?: number },
) => {
  const { warehouseID, assortmentID } = meta ?? {};
  switch (SO_NAME) {
    case SO.PRODUCT_STOCK.NAME:
      return { ...item, warehouseID };
    case SO.PRODUCTS.NAME:
      return {
        ...item,
        nameArray: getAllWords(item.name), // for product search by partial match
        capitalisedName: item.name.toUpperCase(), // for groupAndName index
        codes: getAllCodes(item), // splits legacy solution for having 2 codes in 1 code field separated by `,`
      };
    case SO.ASSORTMENTS.NAME:
      return {
        ...item,
        assortmentID,
      };
    default:
      return item;
  }
};

const saveItemsToSO = async (
  clientCode,
  SO_NAME: SOName,
  items: any[],
  meta?: { warehouseID?: number; assortmentID?: number },
) => {
  if (!items?.length) return;
  const db = await getIndexedDB(clientCode);
  const tx = db.transaction(SO_NAME, 'readwrite');
  await Promise.all([
    ...(items
      .filter(notUndefinedOrNull)
      .map((item: any) =>
        tx.store.put(prepareItemForSO(SO_NAME, item, meta)),
      ) as []),
    tx.done,
  ]);
};

export default saveItemsToSO;
