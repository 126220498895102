export const paxFields = {
  deviceIp: {
    id: 'deviceIp',
    type: 'text',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'deviceIp' } },
  },
  devicePort: {
    id: 'devicePort',
    type: 'text',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'devicePort' } },
  },
  deviceTimeout: {
    id: 'deviceTimeout',
    type: 'text',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'deviceTimeout' } },
  },
};
