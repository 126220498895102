import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  ChipProps,
  FormControlLabel,
  makeStyles,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import i18next from 'i18next';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import {
  Error,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@material-ui/icons';

import { PosPlugin } from 'plugins/plugin';
import { addSuccess } from 'actions/Error';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getUserLoggedIn } from 'reducers/Login';
import {
  getProductsInShoppingCart,
  getRawProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import {
  getCurrentSalesDocOriginalPayments,
  getIsCurrentSaleAReturn,
} from 'reducers/sales';
import { getAllowOnlyOriginalTendersOnReturnWithReceipt } from 'reducers/configs/settings';
import SaveButton from 'components/CustomButtons/SaveButton';
import { RootState } from 'reducers';
import useProducts from 'utils/hooks/useProducts';
import { getIsReturnPayment } from 'reducers/Payments';
import { Product } from 'types/Product';

import { Conf, GivexCard, GivexInputType, LegacyConfig } from '../types';
import { pluginID } from '../constants';
import { isGivexIncrementProduct } from '../utils';

export const getGivexConfiguration = createSelector(
  state => getPluginConfiguration<LegacyConfig>(pluginID)(state),
  legacyConfig => {
    const conf = { ...legacyConfig };

    // Load legacy isPinRequired config if missing
    if (!conf.isPinRequiredPerType) {
      const current = conf.isPinRequired;
      conf.isPinRequiredPerType = {
        'transfer-from': current,
        'transfer-to': false,
        deactivate: current,
        balance: current,
        adjust: current,
        'payment-process-refill': current,
        'payment-process-charge': current,
        'payment-search': current,
      };
    }
    // Make any unconfigured types default to pin-required so that new feature
    // releases don't result in a drop of security
    conf.isPinRequiredPerType = new Proxy(conf.isPinRequiredPerType, {
      get(target, p) {
        return target[p] ?? true;
      },
    });

    if (!conf.giftcardProductIDs) {
      conf.giftcardProductIDs = String(conf.giftcardProductID);
    }
    const parsedGiftCardProductIDs = (typeof conf.giftcardProductIDs ===
    'string'
      ? conf.giftcardProductIDs.split(',')
      : []
    ).filter(Boolean);

    const result: Conf = {
      ...conf,
      giftcardProductIDs: parsedGiftCardProductIDs,
    };
    return result;
  },
);

/** Show 'Givex transfer' button in POS main grid */
export const getIsTransferRequired = createSelector(
  getGivexConfiguration,
  ({ isTransferEnabled }) => isTransferEnabled,
);

/** Current user is a givex admin (based on configured admin group ID) */
export const getisGivexAdmin = createSelector(
  getGivexConfiguration,
  state => getUserLoggedIn(state),
  (conf, { groupID }) => String(conf?.adminId) === String(groupID),
);
export const defaultGivexUrl = 'wss://localhost.erply.com:5658';
/** URL of the givex microservice */
export const getGivexURL = createSelector(
  getGivexConfiguration,
  conf => conf.givexUrl ?? defaultGivexUrl,
);
export const getHasGivexProductInCart = createSelector(
  getProductsInShoppingCart,
  getGivexConfiguration,
  (cart, conf) => cart.some(p => isGivexIncrementProduct(p.productID, conf)),
);

// When noPin is set to true, we do not need to check for pin. Used only in transfers for now.
export const getValidateGivexCard = createSelector(
  getGivexConfiguration,
  ({ isPinRequiredPerType }) => (card: GivexCard, useCase: GivexInputType) => {
    const checkPin = isPinRequiredPerType[useCase];

    if (!card.cardNo) return i18next.t('payment:alerts.giftcardNoCardNbrError');
    if (checkPin === true) {
      if (!card.pin) {
        return i18next.t('payment:alerts.giftcardNoPinError');
      }
    }
    return null;
  },
);

export const getIsAllowed = createSelector(
  state => getIsCurrentSaleAReturn(state),
  state => getIsReturnPayment(state),
  state => getAllowOnlyOriginalTendersOnReturnWithReceipt(state),
  state => getCurrentSalesDocOriginalPayments(state),
  state => getGivexConfiguration(state),
  (
    isReferencedReturn,
    isUnreferencedReturn,
    originalTenderOnly,
    currentSalesDocPayments,
    conf,
  ) => {
    if (isReferencedReturn) {
      if (originalTenderOnly) {
        return currentSalesDocPayments.some(
          originalPayment => originalPayment.cardType === 'GIVEX',
        );
      }
      return conf.isAllowedOnReturnWithReceipt;
    }
    if (isUnreferencedReturn) {
      return conf.isAllowedOnReturnWithoutReceipt;
    }
    return conf.isAllowedOnSale;
  },
);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiFormControlLabel-root': {
      display: 'block',
    },
  },
  chipError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  chipIconError: {
    color: theme.palette.error.main,
  },
}));
const Rows = styled(Box)(({ theme }) => ({
  '& > *': {
    padding: theme.spacing(0, 1),
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.focus,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export const ComponentConfiguration: Required<
  PosPlugin<Conf>
>['ComponentConfigurationByLevel']['Company'] = ({ save }) => {
  const styles = useStyles();
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const originalTendersOnly = useSelector(
    getAllowOnlyOriginalTendersOnReturnWithReceipt,
  );

  const currentConfig = useSelector(getGivexConfiguration);
  const [config, setConfig] = useState(currentConfig);

  const cart = useSelector(getRawProductsInShoppingCart);

  const selectedIncrementProductIDs = config.giftcardProductIDs.map(Number);
  const currentIncrementProductIDs = currentConfig.giftcardProductIDs.map(
    Number,
  );
  const selectedRefundProductID = Number(config.giftcardRefundProductID);
  const currentRefundProductID = Number(currentConfig.giftcardRefundProductID);

  const { productsDict } = useProducts({
    productIDs: R.uniq([
      ...selectedIncrementProductIDs,
      ...currentIncrementProductIDs,
      selectedRefundProductID,
      currentRefundProductID,
      ...cart.map(row => row.productID),
    ]).sort(),
  });

  const {
    [selectedRefundProductID]: selectedRefundProduct,
    [currentRefundProductID]: savedRefundProduct,
  } = productsDict;

  const incrementProductOptions: number[] = useMemo(
    () =>
      R.pipe(
        R.reject(R.isNil),
        R.map(Number),
        R.uniq,
      )([
        ...currentIncrementProductIDs,
        ...selectedIncrementProductIDs,
        ...cart.map(row => row.productID),
      ]),
    [cart, currentIncrementProductIDs, selectedIncrementProductIDs],
  );
  const refundProductOptions: Product[] = useMemo(
    () =>
      R.pipe(
        R.reject(R.isNil),
        R.uniqBy(R.prop('productID')),
      )([savedRefundProduct, selectedRefundProduct, ...cart]),
    [savedRefundProduct, selectedRefundProduct, cart],
  );

  const saveConfig = () => {
    save(
      R.evolve({
        givexUrl: a => a || undefined,
        giftcardProductIDs: ids => ids.join(','),
      })(config),
    );
    dispatch(addSuccess('Configuration saved'));
  };

  const onChangeProp = (prop: keyof Conf) => (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const { type, value, checked } = e.target;
    const parsedValue = type === 'checkbox' ? checked : value;
    setConfig(R.assoc(prop, parsedValue));
  };

  function isProductRefundable(productId: number) {
    return productsDict[productId] && !productsDict[productId]?.nonRefundable;
  }

  const hasRefundableIncrementProducts = selectedIncrementProductIDs.some(
    isProductRefundable,
  );

  return (
    <div className={styles.root}>
      <h5>Givex MS</h5>
      <TextField
        fullWidth
        variant="outlined"
        label="Givex Microservice"
        placeholder={defaultGivexUrl}
        type="text"
        onChange={onChangeProp('givexUrl')}
        value={config.givexUrl}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Admin id"
        type="text"
        onChange={onChangeProp('adminId')}
        value={config.adminId}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Default card number"
        type="text"
        onChange={onChangeProp('defaultCardNumber')}
        value={config.defaultCardNumber}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Default pin"
        type="text"
        onChange={onChangeProp('defaultPin')}
        value={config.defaultPin}
      />
      <h5 className="mt-3">Pin input visibility:</h5>
      <Rows>
        {([
          ['balance', 'Check balance'],
          ['adjust', 'Adjust balance'],
          ['payment-process-refill', 'Refill card'],
          ['payment-process-charge', 'Charge card'],
          ['payment-search', ' ↳ Search for givex card to pay with'],
          ['deactivate', ' ↳ Deactivate card'],
          ['transfer-from', 'Transfer from'],
          ['transfer-to', 'Transfer to'],
        ] as const).map(([type, label]) => (
          <Box
            key={type}
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Box>{label}</Box>
            <UsePinInput type={type} setConfig={setConfig} config={config} />
          </Box>
        ))}
      </Rows>
      <FormControlLabel
        label="Enable transfer"
        control={
          <Checkbox
            onChange={onChangeProp('isTransferEnabled')}
            checked={config.isTransferEnabled}
          />
        }
      />
      <FormControlLabel
        label="Use visual input field"
        control={
          <Checkbox
            onChange={onChangeProp('useFancyInput')}
            checked={config.useFancyInput}
          />
        }
      />
      <Autocomplete
        multiple
        value={selectedIncrementProductIDs}
        onChange={(event, value) =>
          setConfig(prev => ({
            ...prev,
            giftcardProductIDs: value.map(String),
          }))
        }
        options={[-1, ...incrementProductOptions]}
        getOptionLabel={productID => {
          if (productID === -1) {
            return cart.length === 0
              ? 'Add a product to cart first'
              : 'Select non-refundable product...';
          }
          return productsDict[productID]?.name ?? '';
        }}
        getOptionDisabled={productID =>
          productID === -1 || isProductRefundable(productID)
        }
        renderTags={(values, getTagProps) =>
          values.map((productID, index) => {
            const tagProps: ChipProps = getTagProps({ index });
            const isInvalid = isProductRefundable(productID);
            function getIcon() {
              if (isInvalid) return <Error className={styles.chipIconError} />;
              if (index === 0) return <RadioButtonChecked />;
              return (
                <RadioButtonUnchecked
                  titleAccess="Move to the start of the list"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sortedSelectedProductIDs = [productID].concat(
                      selectedIncrementProductIDs.filter(
                        id => id !== productID,
                      ),
                    );
                    setConfig(prev => ({
                      ...prev,
                      giftcardProductIDs: sortedSelectedProductIDs.map(String),
                    }));
                  }}
                />
              );
            }
            return (
              <Chip
                {...tagProps}
                label={productsDict[productID]?.name ?? ''}
                icon={getIcon()}
                className={classNames(tagProps.className, {
                  [styles.chipError]: isInvalid,
                })}
              />
            );
          })
        }
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            label="Products to use as givex increment"
            error={hasRefundableIncrementProducts}
            helperText={
              hasRefundableIncrementProducts
                ? 'Some of the selected products are not non-refundable'
                : ''
            }
          />
        )}
      />
      <Typography paragraph>
        Only the first givex increment product will be added to shopping cart
        when "Givex increment" grid button is clicked
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Product to use as givex deactivation refund"
        select
        value={config.giftcardRefundProductID ?? '-1'}
        onChange={onChangeProp('giftcardRefundProductID')}
      >
        <MenuItem key="-1" value="-1">
          {cart.length === 0
            ? 'Add the product to cart first'
            : 'Select product...'}
        </MenuItem>
        {refundProductOptions.map(o => (
          <MenuItem key={o.productID} value={o.productID}>
            {o.name}
          </MenuItem>
        ))}
      </TextField>
      <h5 className="mt-3">Allow Givex tender:</h5>
      <FormControlLabel
        label="Allow on sale"
        control={
          <Checkbox
            onChange={onChangeProp('isAllowedOnSale')}
            checked={config.isAllowedOnSale}
          />
        }
      />
      <FormControlLabel
        label="Allow on return with receipt"
        control={
          <Checkbox
            disabled={!!originalTendersOnly}
            onChange={onChangeProp('isAllowedOnReturnWithReceipt')}
            checked={config.isAllowedOnReturnWithReceipt}
          />
        }
      />
      {originalTendersOnly ? (
        <Box paddingLeft="0.75rem" marginTop="-0.5rem" marginBottom="0.5rem">
          Overridden by <b>Only original tender types are allowed</b> setting
          (Payment Configuration)
        </Box>
      ) : null}
      <FormControlLabel
        label="Allow on return without receipt"
        control={
          <Checkbox
            onChange={onChangeProp('isAllowedOnReturnWithoutReceipt')}
            checked={config.isAllowedOnReturnWithoutReceipt}
          />
        }
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Display name for Givex"
        type="text"
        onChange={onChangeProp('displayName')}
        value={config.displayName}
      />
      <SaveButton
        action={saveConfig}
        disabled={hasRefundableIncrementProducts}
        title="Save"
      />
    </div>
  );
};

function UsePinInput({
  type,
  setConfig,
  config,
}: {
  type: GivexInputType;
  setConfig: (v: Conf) => void;
  config: Conf;
}) {
  // Spread to eliminate the proxy - A proxy is used in the config selector to automatically return 'true' for all missing values
  // but here in the config screen, we can display those as having no selection to prompt the user that this is a new item
  // and that they should make an explicit choice here
  const value = { ...config.isPinRequiredPerType }[type];

  // Some types are logically required to be at least as restrictive as others
  // For example, if charging a gift card requires pin, then selecting a gift card to charge must also require pin
  const requiresPin = (function checkIfRequriesPinDueToDependencies() {
    switch (type) {
      case 'payment-search':
        return config.isPinRequiredPerType['payment-process-charge'] === true;
      case 'deactivate':
        return config.isPinRequiredPerType['payment-process-charge'] === true;
      default:
        return false;
    }
  })();
  useEffect(() => {
    if (requiresPin)
      setConfig(R.assocPath(['isPinRequiredPerType', type], true));
  }, [requiresPin, setConfig, type]);
  return (
    <ButtonGroup>
      {([
        ['No input', false],
        ['Optional', 'optional'],
        ['Required', true],
      ] as const).map(([k, v]) => {
        const selected = v === value;
        return (
          <Button
            key={k}
            style={{ width: '10ch' }}
            variant={selected ? 'contained' : 'outlined'}
            color={selected ? 'secondary' : undefined}
            disabled={requiresPin}
            onClick={() =>
              setConfig(R.assocPath(['isPinRequiredPerType', type], v))
            }
          >
            {k}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
