import * as api from 'services/ErplyAPI/assortmentProducts';

import * as constants from '../constants/assortmentProducts';

// eslint-disable-next-line import/prefer-default-export
export function getAssortmentProducts(warehouseID, assortmentID) {
  return async dispatch => {
    if (!assortmentID) {
      return;
    }
    dispatch({ type: constants.GET_ASSORTMENT_PRODUCTS });
    try {
      const payload = await api.getAssortmentProducts(warehouseID, assortmentID);
      dispatch({
        type: constants.GET_ASSORTMENT_PRODUCTS_SUCCESS,
        payload,
      });
    } catch (err) {
      console.error('Failed to fetch assortment products', err);
      dispatch({ type: constants.GET_ASSORTMENT_PRODUCTS_ERROR, payload: err });
    }
  };
}
