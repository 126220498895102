import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import Loader from 'components/Loader';
import { addError, addSuccess } from 'actions/Error';
import CloseButton from 'components/CustomButtons/CloseButton';
import { Ctx, Select } from 'containers/Forms/Settings/components/CtxInputs';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import WrapperPaymentConfiguration from 'containers/Forms/Settings/views/Payment/components/Wrapper';
import QuestionMark from 'components/QuestionMark';
import {
  saveActiveIntegration,
  saveCafaConfig,
} from 'actions/integrations/CafaConfigs';
import {
  getCafaEntry,
  getActivePaymentIntegration,
} from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import InputField from 'components/FieldTypes/InputField';
import {
  getSetting,
  getSyncBatchToDayOpenings,
} from 'reducers/configs/settings';
import { saveTempSetting, commitTempSettings } from 'actions/configs';
import { DebugDisplayVariables } from 'components/Dev/Debug';
import Pax from 'containers/Forms/Settings/views/Payment/components/Pax';
import Shift4PaymentConfiguration from 'containers/Forms/Settings/views/Payment/components/Shift4';
import { SaveButton } from 'components/UIElements/UIButton';

import {
  AdyenConfiguration,
  CayanConfiguration,
  ChaseConfiguration,
  NetsConfiguration,
  PCEftposConfiguration,
  SwedbankConfiguration,
  TriposConfiguration,
  TSYSCanadaConfiguration,
  VerifoneConfiguration,
  Junction,
  Moneris,
  Stripe,
} from '../../components';

import styles from './PaymentGateways.module.scss';

/**
 * Component for rendering all Payment integration forms
 *
 * It gets initial data from redux to populate default state for currently selected Payment integration and the its configuration
 * After that the user can switch between integrations and add different values to the form fields. Values will be stored in local state
 * Each Integration form receives `setEnableUpdate` function that prevents the user from storing forms with missing or incorrect fields.
 * Validation should happen in the Integration forms
 * @returns {*}
 * @constructor
 */

const PaymentGateways = () => {
  const { t } = useTranslation('settingsPayment');
  const dispatch = useDispatch();

  // Currently active integration
  const currentIntegration = useSelector(getActivePaymentIntegration);

  // Loader
  const [saving, setSaving] = useState(false);

  const [state, setState] = useState({
    // Local variable for the integration
    integration: '',
    // enableUpdate prevents the user from saving
    // and selecting payment integration before all required fields are present
    enableUpdate: {
      none: true,
    },
    // Local variable for preserving incomplete changes in different integration config inputs
    config: {},
  });

  const { integration, enableUpdate, config } = state;

  const enableCurrentIntegrationUpdate = enableUpdate[integration];

  // Current configuration for the active integration
  const integrationConfig = useSelector(
    getCafaEntry(integration, INTEGRATION_TYPES.payment),
  );

  // Update integration on load
  useEffect(() => {
    if (currentIntegration && !integration)
      setState(oldState => ({ ...oldState, integration: currentIntegration }));
  }, [currentIntegration, integration]);

  // update config of current integration on load
  useEffect(() => {
    if (
      integrationConfig &&
      !state.config[state.integration] &&
      state.integration !== 'none'
    ) {
      setState(oldState => ({
        ...oldState,
        config: {
          ...oldState.config,
          [integration]: integrationConfig?.value,
        },
      }));
    }
  }, [integration, integrationConfig, state.config, state.integration]);

  const shouldEnableUpdate = shouldUpdate => {
    if (shouldUpdate !== enableCurrentIntegrationUpdate) {
      setState(oldState => ({
        ...oldState,
        enableUpdate: {
          ...oldState.enableUpdate,
          [integration]: shouldUpdate,
        },
      }));
    }
  };

  const integrations = [
    { name: t('integrations.noIntegration'), value: 'none' },
    { name: 'Adyen', value: 'adyen' },
    { name: 'Cayan - Merchant Warehouse', value: 'cayan' },
    { name: 'CHASE', value: 'chase' },
    // { name: 'Linkpos', value: 'linkpos' },
    { name: 'triPOS', value: 'triPOS' },
    { name: 'TSYS Canada', value: 'tsysCanada' },
    { name: 'Verifone FI', value: 'verifone' },
    { name: 'Swedbank: Ingenico', value: 'swedbank' },
    { name: 'NETS FI', value: 'netsCloud' },
    { name: 'Native wrapper (IOS/Android)', value: 'wrapper' },
    { name: 'External Integration', value: 'external' },
    { name: 'PC-Eftpos', value: 'pcEftpos' },
    { name: 'Junction', value: 'junction' },
    { name: 'PAX-Heartland', value: 'pax' },
    { name: 'Moneris', value: 'moneris' },
    { name: 'Shift4', value: 'shift4' },
    { name: 'Stripe', value: 'stripe' },
  ];

  // Closing implemented via state and useEffect in order to render the forms one
  // last time with reverted values
  // This allows wrapperForm to notify the wrapper that changes have been rolled back
  const [closing, setClosing] = useState(0);
  useEffect(() => {
    switch (closing) {
      case 1:
        setState(s => ({
          ...s,
          config: { ...s.config, [integration]: integrationConfig?.value },
        }));
        setClosing(2);
        break;
      case 2:
        dispatch(previousModalPage());
        break;
      default:
        break;
    }
  }, [closing, dispatch, integration, integrationConfig]);
  const onClose = () => setClosing(1);

  const onSave = async () => {
    // Abort if selected integration is missing required fields
    if (!enableCurrentIntegrationUpdate) return;
    setSaving(true);
    try {
      const configuration = config[integration];
      const jobs = [];
      // TODO: Move this block to triPOS form onchange handler
      // General Payment integration component should not have integration specific code
      if (integration === 'triPOS') {
        const { AcceptorId, AccountId } = configuration;
        configuration.AcceptorId = parseInt(AcceptorId, 10);
        configuration.AccountId = parseInt(AccountId, 10);
      }
      // if there is a config and the config has been modified save it to CAFA
      if (configuration && integrationConfig?.value !== configuration)
        jobs.push(
          dispatch(
            saveCafaConfig({
              integrationName: integration,
              integrationType: INTEGRATION_TYPES.payment,
              config: configuration,
            }),
          ),
        );

      // If active payment integration has been modified, update activeIntegrations in CAFA
      if (
        currentIntegration !== integration &&
        (integration !== 'none' || integration !== '')
      )
        jobs.push(
          dispatch(
            saveActiveIntegration({
              integrationType: INTEGRATION_TYPES.payment,
              value: integration,
            }),
          ),
        );

      await Promise.all(jobs).then(() =>
        dispatch(
          commitTempSettings([
            'allow_fallback_to_external_integration',
            'sync_terminal_batch_to_day_openings',
          ]),
        ),
      );
      dispatch(addSuccess(t('alerts.configSaved')));
    } catch (e) {
      console.error('Failed to save payment configuration', e);
      dispatch(addError(e.message, { selfDismiss: true }));
    } finally {
      setSaving(false);
    }
  };

  const onChange = useCallback(
    (key, value) => {
      switch (key) {
        case 'integration':
          setState(oldState => ({
            ...oldState,
            integration: value,
            enableUpdate: { ...state.enableUpdate, [value]: true },
          }));
          break;
        case 'configuration':
          setState(oldState => ({
            ...oldState,
            config: { ...oldState.config, [oldState.integration]: value },
          }));
          break;
        default:
          break;
      }
    },
    [state.enableUpdate],
  );

  const values = {
    integration: state.integration,
    configuration: state.config[state.integration] ?? {},
    setEnableUpdate: shouldEnableUpdate,
    initialIntegrationState: integrationConfig?.value,
    forceSave: onSave,
  };

  const allowExternalFallback = useSelector(
    getSetting('allow_fallback_to_external_integration'),
  );
  const setAllowExternalFallback = value =>
    dispatch(saveTempSetting('allow_fallback_to_external_integration', value));
  const syncBatchToDayOpenings = useSelector(getSyncBatchToDayOpenings);
  const setSyncBatchToDayOpenings = value =>
    dispatch(saveTempSetting('sync_terminal_batch_to_day_openings', value));
  return (
    <div className={styles['payment-gateways']}>
      <Loader
        show={saving}
        loadingText={t('common:saving')}
        style={{ display: 'block', height: '100%', maxHeight: '100%' }}
      >
        <Modal.Header
          style={{
            fontWeight: '700',
            fontSize: '1.75em',
          }}
        >
          <span>{t('general.setupGateways')}</span>
          <QuestionMark
            className="mt-1 ml-1"
            color="#444"
            code={1210}
            groupID={51}
            style={{
              position: 'relative',
              padding: '1px 8px',
              fontSize: '16px',
            }}
          />
          <span style={{ flexGrow: '1' }} />
          <SaveButton
            disabled={!enableCurrentIntegrationUpdate}
            variant="POS"
            action={onSave}
          />
          <CloseButton action={onClose} />
        </Modal.Header>
        <Modal.Body>
          <Ctx.Provider value={{ values, onChange }}>
            <div style={{ maxHeight: '85vh' }}>
              <Select
                title={t('integrations.select')}
                prependTitle
                name="integration"
                options={integrations}
                className={classNames([
                  formStyles.formInput,
                  formStyles.mediumTitle,
                  'current-integration-input',
                ])}
              />
              <InputField
                onChange={e => setAllowExternalFallback(e.target.value)}
                value={allowExternalFallback}
                type="checkbox"
                disabled={values.integration === 'external'}
              >
                {t('general.externalFallback')}
              </InputField>
              <InputField
                onChange={e => setSyncBatchToDayOpenings(e.target.value)}
                value={syncBatchToDayOpenings}
                type="checkbox"
              >
                {t('general.syncBatchToDayOpenings')}
              </InputField>
              {(() => {
                switch (values.integration) {
                  case 'adyen':
                    return <AdyenConfiguration />;
                  case 'cayan':
                    return <CayanConfiguration />;
                  case 'chase':
                    return <ChaseConfiguration />;
                  case 'verifone':
                    return <VerifoneConfiguration />;
                  case 'swedbank':
                    return <SwedbankConfiguration />;
                  case 'triPOS':
                    return <TriposConfiguration />;
                  case 'tsysCanada':
                    return <TSYSCanadaConfiguration />;
                  /* case 'linkpos':
                    return <LinkposConfiguration />; */
                  case 'netsCloud':
                    return <NetsConfiguration />;
                  case 'wrapper':
                    return <WrapperPaymentConfiguration />;
                  case 'pcEftpos':
                    return <PCEftposConfiguration />;
                  case 'junction':
                    return <Junction />;
                  case 'pax':
                    return <Pax />;
                  case 'moneris':
                    return <Moneris />;
                  case 'shift4':
                    return <Shift4PaymentConfiguration />;
                  case 'stripe':
                    return <Stripe />;
                  case 'none':
                  default:
                    return <span>{t('integrations.noSettings')}</span>;
                }
              })()}
            </div>
            <DebugDisplayVariables configuration={config} />
          </Ctx.Provider>
        </Modal.Body>
      </Loader>
    </div>
  );
};

export default PaymentGateways;
