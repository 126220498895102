import debug from 'debug';

const data: Record<string, any> = {};

const unchanged = Symbol('UNCHANGED');
/** @deprecated For debug use only */
export const traceChanges = <T extends any>(items: Record<string, T>) => {
  // Debug code, should not be run in production therefore console is fine
  // eslint-disable-next-line no-console
  console.log(
    Object.fromEntries(
      Object.entries(items).map(([k, v]) => {
        const isUnchanged = data[k] === v;
        data[k] = v;
        return [k, isUnchanged ? unchanged : v] as const;
      }),
    ),
  );
};

const fairlyRoundArray = (array: number[], round: (number) => number) => {
  let err = 0;
  return array.map(val => {
    const target = val + err;
    const value = round(target);
    err = target - value;
    return value;
  });
};

type DebugConsole = typeof console & {
  extend: (namespace: string) => DebugConsole;
};
const wrapDebugWithConsole = (debug: any) =>
  new Proxy(debug, {
    get(target: any, p: PropertyKey, receiver: any): any {
      if (Object.hasOwnProperty.call(console, p)) {
        return (...params) => {
          // eslint-disable-next-line no-param-reassign
          target.log = console[p].bind(console);
          // @ts-ignore
          target(...params);
        };
      }
      switch (p) {
        case 'extend':
          // @ts-ignore
          return (namespace: string) => wrapDebugWithConsole(debug.extend(namespace));
        default:
          return target[p];
      }
    },
  }) as DebugConsole;

export const createDebugConsole = (namespace: string) =>
  wrapDebugWithConsole(debug(namespace));
