import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getTaxProducts } from 'plugins/taxProducts/redux/redux';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getCachedItemsPerType } from 'reducers/cachedItems';
import { SO } from 'services/DB/types';
import { getAttrs } from 'plugins/taxProducts/utils/getAttrs';

export const getTaxProductsWithPrices = createSelector(
  getProductsInShoppingCart,
  getTaxProducts,
  state => getCachedItemsPerType(SO.PRODUCTS.NAME)(state),
  (cart, taxProds, cachedProducts) => {
    const taxProducts = taxProds.map(id => cachedProducts[id]);
    const prices = Object.fromEntries(
      taxProducts.map(prod => [prod.productID, undefined]),
    );

    const { manual = [], auto = [], ...rest } = R.groupBy(
      R.pipe(getAttrs, R.prop('applyTo')),
    )(taxProducts);

    const lvls = R.pipe(R.keys, R.sortBy(Number))(rest);
    const total = cart
      .filter(p => !taxProds.includes(Number(p.productID)))
      .map(r => r.rowTotal)
      .reduce(R.add, 0);

    /* Calculate numbered level tax products */
    lvls.forEach(lvl => {
      const currentTotal =
        total +
        Object.values(prices)
          .filter(a => a !== undefined)
          .map((a: any) => a.net + a.tax)
          .reduce(R.add, 0);
      rest[lvl].forEach(p => {
        const net = (currentTotal * getAttrs(p).percentage) / 100;
        const tax = net * (p.vatrate / 100);
        prices[p.productID] = { net, tax };
      });
    });
    /* Calculate auto-tax products */
    let maxCnt = 200;
    while (auto.some(p => prices[p.productID] === undefined)) {
      if (maxCnt-- <= 0) {
        console.error(
          'Over 200 iterations, broke out of calculation for safety - THIS SHOULD NEVER HAPPEN',
        );
        break;
      }
      const updates = auto
        .filter(ap => prices[ap.productID] === undefined)
        .map(ap => {
          let price = 0;
          cart
            .filter(p =>
              getAttrs(cachedProducts[p.productID]).taxedBy.includes(
                ap.productID,
              ),
            )
            .forEach(p => {
              price += p.rowTotal;
            });
          taxProducts
            .filter(p => getAttrs(p).taxedBy.includes(ap.productID))
            .forEach(p => {
              price += prices[p.productID].net + prices[p.productID].tax;
            });
          if (Number.isFinite(price)) {
            const net = (price * getAttrs(ap).percentage) / 100;
            const tax = net * (ap.vatrate / 100);
            prices[ap.productID] = { net, tax };
            return true;
          }
          return false;
        });
      if (!updates.some(R.identity)) break;
    }
    const ret = taxProducts.map(p =>
      R.assoc('taxPrice', prices[p.productID])(p),
    );
    return ret;
  },
);
