import { PosPlugin } from 'plugins/plugin';
import { addWarning } from 'actions/Error';

import { getSAINumber } from '../redux/selectors';

const preventAddProductIfNoSAINumber: Required<
  PosPlugin
>['onAddProduct']['before'] = () => async (dispatch, getState) => {
  const SAINumber = getSAINumber(getState());
  if (SAINumber !== null) return;

  const message = 'Can not add product until SAI number is set';
  dispatch(addWarning(message));
  throw new Error(message);
};

export default preventAddProductIfNoSAINumber;
