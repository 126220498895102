import { useSelector } from 'react-redux';

import { getGridButtonSizeConfig } from 'reducers/cafaConfigs';

export interface GridButtonSize {
  width: number;
  height: number;
  fontSize: number;
}

const DEFAULT_WIDTH = 95;
const DEFAULT_HEIGHT = 65;

function useGridButtonSize(): GridButtonSize {
  const { buttonSize, fontSize } = useSelector(getGridButtonSizeConfig);

  return {
    width: Number(buttonSize) * DEFAULT_WIDTH,
    height: Number(buttonSize) * DEFAULT_HEIGHT,
    fontSize: Number(fontSize),
  };
}

export default useGridButtonSize;
