import React, { useEffect } from 'react';
import classNames from 'classnames';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import InputField from 'components/FieldTypes/InputField';

import { validateRegistryCode } from './actions';
import GarminInputs from './garminInputs';

const inputClass = classNames([styles.formInput, styles.longTitle]);
const validate = val => {
  return val ? undefined : 'This field can not be blank';
};
const blockify = isCompany => child => {
  if (isCompany) {
    return R.pipe(R.assocPath(['props', 'field', 'disabled'], true))(child);
  }
  return child;
};
const requirify = isCompany => child => {
  if (isCompany) {
    return R.pipe(R.assocPath(['props', 'field', 'validate'], validate))(child);
  }
  return child;
};
const matches = regexes => c => {
  return regexes.some(reg => reg.test(c?.key));
};
const replaceAt = (
  field: RegExp,
  value,
  handleChange,
  handleBlur,
  isCompany,
) => children => {
  const indexOf = (f: RegExp) => children.findIndex(c => f.test(c.key));

  const targetIndex = indexOf(field);
  const arrayCopy = [...children];
  if (isCompany) {
    arrayCopy[targetIndex] = (
      <>
        <InputField
          size="lg"
          className={inputClass}
          name="code"
          type="text"
          key="code"
          data-testid="code"
          value={value}
          title="code"
          onChange={handleChange}
          isInvalid={!value}
          onBlur={handleBlur}
          errorText={validate(value)}
          {...field}
        >
          Code
        </InputField>
        <br />
      </>
    );
  }

  return arrayCopy;
};
const moveAfter = (field: RegExp, fieldAfter: RegExp) => (children: any[]) => {
  const indexOf = (f: RegExp) => children.findIndex(c => f.test(c.key));
  const from = indexOf(field);
  const to = indexOf(fieldAfter) + 1;
  // @ts-ignore
  return R.move(from, to, children);
};

const garminCustomizations: PosPlugin = {
  id: 'garmin-custom-plugin',
  name: 'Garmin - Customization',
  keywords: [
    'garmin',
    'create',
    'customer',
    'form',
    'validation',
    'registry',
    'code',
  ],
  getStatus: () => ({ message: 'Ready', type: 'valid' }),
  UICustomerForm: ({ value, children, onChange, isCompany }) => {
    const dispatch = useDispatch();
    const addresses = value.addresses ?? [];
    const handleChange = e => {
      const { name, value } = e.target;
      onChange(c => ({ ...c, [name]: value }));
    };
    const addressOnChange = addresses => {
      return onChange({ ...value, addresses });
    };
    const handleBlur = async () => {
      if (isCompany && value.code) {
        await dispatch(
          validateRegistryCode(
            value.code,
            onChange,
            addressOnChange,
            addresses,
          ),
        );
      }
    };
    const tinNumberIsValid = !!value.vatNumber;
    const isCodeValid = !!value.code;
    const isValid = tinNumberIsValid && isCodeValid;
    useEffect(() => {
      if (!isValid !== value.invalid) {
        onChange(R.assoc('invalid', !isValid && isCompany, value));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.invalid, isValid, isCompany]);

    return R.pipe(
      moveAfter(/\$code$/, /\$vatNumber$/),
      replaceAt(
        /\$code$/,
        (value as any).code,
        handleChange,
        handleBlur,
        isCompany,
      ),
      R.append(
        <InputField
          size="lg"
          className={inputClass}
          name="taxExempt"
          type="checkbox"
          key="taxExempt"
          data-testid="taxExempt"
          value={(value as any).taxExempt}
          onChange={handleChange}
        >
          Tax Exempt
        </InputField>,
      ),
      R.map(R.when(matches([/code/, /vatNumber/]), requirify(isCompany))),
      R.map(R.when(matches([/vatNumber/, /companyName/]), blockify(isCompany))),
    )(React.Children.toArray(children));
  },
  getTranslationOverrides: createSelector(
    state => null,
    () => ({
      createCustomer: {
        fields: {
          vatNumber: 'TIN Number',
        },
      },
    }),
  ),
  components: {
    garminInputs: GarminInputs,
  },
};

export default garminCustomizations;
