import * as R from 'ramda';
import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { onSaveSalesDocument } from 'plugins/suretax/features/onSaveSalesDocument';

import { UICustomerBadgeCustomRegion } from './features/UICustomerBadgeCustomRegion';
import { UITableTotalRow } from './features/UITableTotalRow';
import { UITableBill } from './features/UITableBill';
import { afterCalculate } from './features/onCalculateApplySuretax';
import { ComponentHeader } from './features/ComponentHeader';
import { UICustomTableBillRow } from './features/UICustomTableBillRow';
import { suretaxReducer } from './redux';
import { CompanyConfiguration } from './Configuration';
import * as meta from './meta';

export const SuretaxPlugin: PosPlugin<meta.Configuration> = {
  id: meta.pluginID,
  name: 'Suretax integration',
  // eslint-disable-next-line global-require
  infoMDUrl: require('./info.md'),

  // Configuration and data
  reduxReducer: suretaxReducer,
  ComponentConfigurationByLevel: { Company: CompanyConfiguration },
  selectorOverrides: {
    getSettings: base =>
      createSelector(
        base,
        R.pipe(
          R.assoc('pos_brazil_do_not_calculate_shopping_cart_on_change', true),
          R.assoc('locale_uses_price_with_tax', false),
        ),
      ),
  },
  combineConfiguration: c =>
    R.mergeDeepLeft(c, {
      endpoint: '',
      validationKey: '',
      clientNumber: '',
    }),

  // UI display
  ComponentHeader,
  UITableBill,
  UITableTotalRow,
  UICustomerBadgeCustomRegion,
  UICustomTableBillRow,

  // Behaviour
  onCalculate: {
    after: afterCalculate,
  },

  onSaveSalesDocument: {
    on: onSaveSalesDocument,
  },
};
