export const adyenFields = {
  // Terminal ID
  terminalId: {
    id: 'terminalId',
    type: 'text',
    validate: val =>
      val && val.length
        ? null
        : { message: 'isMissing', dict: { field: 'terminalId' } },
  },
  // Terminal key identifier
  terminalKeyIdentifier: {
    id: 'terminalKeyIdentifier',
    type: 'text',
    validate: val =>
      val?.toString().length ? null : 'Terminal key passphrase is required.',
  },
  // Terminal key passphrase
  terminalKeyPassphrase: {
    id: 'terminalKeyPassphrase',
    type: 'password',
    validate: val =>
      val?.toString().length ? null : 'Terminal key passphrase is required.',
  },
  terminalKeyVersion: {
    id: 'terminalKeyVersion',
    title: 'Terminal Key Version',
    type: 'text',
    validate: val =>
      val?.toString().length ? null : 'Terminal key version is required.',
  },
  apiKey: {
    id: 'apiKey',
    type: 'password',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'adyenAPIKey' } },
  },
  // Region
  region: {
    id: 'region',
    type: 'select',
    options: [
      { name: 'Select Environment', value: '' },
      { name: 'Test', value: 'test' },
      { name: 'Europe', value: 'europe' },
      { name: 'Australia', value: 'australia' },
      { name: 'US', value: 'us' },
    ],
    validate: val =>
      val?.length ? null : { message: 'isMissing', dict: { field: 'region' } },
  },
  checkTerminalBeforeOpration: {
    id: 'checkTerminalBeforeOperation',
    type: 'checkbox',
    numeric: false,
  },
  connectionType: {
    id: 'connectionType',
    type: 'select',
    options: [
      { name: 'Select Environment', value: '' },
      { name: 'Cloud, Local', value: 'Cloud, Local' },
      { name: 'Local, Cloud', value: 'Local, Cloud' },
      { name: 'Cloud', value: 'Cloud' },
      { name: 'Local', value: 'Local' },
    ],
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'connectionType' } },
  },
  adyenOnlyUnreferencedReturns: {
    id: 'adyenOnlyUnreferencedReturns',
    type: 'checkbox',
  },
  enableMarketplace: {
    id: 'enableMarketplace',
    type: 'checkbox',
  },
  marketplaceID: {
    id: 'marketplaceID',
    type: 'text',
  },
  marketplaceAccount: {
    id: 'marketplaceAccount',
    type: 'select',
  },
};
