export enum TransactionType {
  VOID = 'VOID',
  REFUND = 'REFUND',
  SALE = 'SALE',
}

type Transaction = {
  employeeID: string;
  customerID: string;
  transactionType: TransactionType;
};

export type VoidTransaction = Transaction & {
  referenceNumber: string;
};

export type RefundTransaction = Transaction & {
  amount: string;
};

export type SaleTransaction = Transaction & {
  amount: string;
  softwareName: string;
  softwareVersion: string;
};

type ChaseResponse<T> = {
  utcDateTime: string;
  recordsCount: number;
  records: T[];
};

export type TestConnectionResponse = ChaseResponse<{
  resultStatus: string;
  resultCode: string;
  statusMessage: string;
}>;

export type GetConfigurationResponse = ChaseResponse<{ configJSON: string }>;

export type CloseBatchResponse = ChaseResponse<{
  resultStatus: string;
  resultCode: string;
  statusMessage: string;
}>;

export type ChaseTransactionResponse = ChaseResponse<{
  requestID: string;
  transactionStatus: string;
  resultCode: '0' | '1' | '2' | '117';
  approvedAmount: string;
  authCode: string;
  cardHolder: string;
  cardNumber: string;
  paymentType: string;
  entryMode: string;
  statusMessage: string;
  referenceNumber: string;
  dateTime: string;
  transactionType: string;
  aid: string;
  applicationLabel: string;
  pinStatement: string;
  expirationDate: string;
  cryptogramType: string;
  cryptogram: string;
  signature: string;
  signatureRequired: string;
  hostCode: string;
  hostResponse: string;
  cardBalance: string;
  cardType: string;
  hostReferenceNumber: string;
  cardholderVerificationMethod: string;
  invoiceNumber: string;
  additionalData: null;
  rawResponse: {
    TrxStatus: string;
    MultiTrxFlag: number;
    ClerkID: number;
    TrxType: string;
    TrxDate: string;
    TrxTime: string;
    TrxAmt: number;
    TipAmt: number;
    CashBackAmt: number;
    SurchargeAmt: number;
    TaxAmt: number;
    TotalAmt: number;
    InvoiceNr: string;
    PurchaseOrderNr: string;
    ReferenceNr: string;
    TrxSequenceNr: number;
    TicketNr: string;
    EmployeeID: string;
    NumberOfCards: string;
    GiftCardRefNr: string;
    OriginalTrxType: string;
    CustomerCardType: string;
    CustomerAccountNr: string;
    CustomerCardEntryMode: string;
    CardNotPresent: number;
    WebCardType: string;
    WebTrxInformation: string;
    AuthCode: string;
    HostResponseCode: string;
    HostResponseText: string;
    HostResponseISOCode: string;
    AmtDue: string;
    CardBalance: string;
    HostTrxRefNr: string;
    AcquirerSpecificDataFromHostResponse: string;
    AcquirerSupplementaryData: string;
    DemoMode: number;
    TerminalID: string;
    MerchantReceiptFileName: string;
    CustomerReceiptFileName: string;
  };
}>;
