import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { Trans, useTranslation } from 'react-i18next';
import * as Rx from 'rxjs';
import * as Op from 'rxjs/operators';
import { useObservable } from 'react-use';
import { Switch, withStyles } from '@material-ui/core';

import { add } from 'utils/index';
import Icon from 'components/Icon';
import {
  getConnectionHealth,
  getConnectionStatus,
  getIsForcedOfflineMode,
  getIsOnlineAndLoggedIn,
} from 'reducers/connectivity/connection';
import {
  setForcedOfflineStatus,
  syncPendingRequest,
} from 'actions/connectivity';
import { addWindowFetchOverride } from 'utils/windowFetchOverrides';
import { getHasRightToEditSettings, getSessionKey } from 'reducers/Login';
import './Connectivity.scss';
import { fetchCampaignsAndCoupons } from 'actions/CampaignsDB';
import { campaignDataNotFetched } from 'reducers/CampaignsDB';

import { checkSessionNotExpiring } from '../UserMenu/SessionExpiration/SessionExpiration';
import { HeaderIconButton } from '../Icon';

import ConnectivityInfoPopup from './ConnectivityInfoPopup';

const networkUpdates$ = new Rx.BehaviorSubject(0);
const networkStatus$ = new Rx.BehaviorSubject(0);
networkUpdates$
  .pipe(
    Op.scan(add, 0),
    Op.bufferTime(500),
    Op.filter(a => 0 < a.length),
    Op.map(arr => Math.max(...arr)),
  )
  .subscribe(networkStatus$);

const countRequestsOverride = orig => (...params) => {
  networkUpdates$.next(1);
  const ret = orig.apply(window, params);
  ret
    .catch(() => {
      // empty catch
    })
    .finally(() => {
      networkUpdates$.next(-1);
    });
  return ret;
};

addWindowFetchOverride('countRequestsOverride', countRequestsOverride);

let isForcedOffline = false;
const forceOfflineOverride = orig => (...params) => {
  if (!isForcedOffline) return orig.apply(window, params);
  const url = params?.[0];
  if (url.match(/((\/health$)|(localhost.erply))/))
    return orig.apply(window, params);
  return Promise.reject(new Error('Forced offline mode is enabled'));
};

addWindowFetchOverride('forceOfflineOverride', forceOfflineOverride);

const useForceOffline = () => {
  const isForcedOfflineMode = useSelector(getIsForcedOfflineMode);
  useEffect(() => {
    isForcedOffline = isForcedOfflineMode;
  }, [isForcedOfflineMode]);
};

const useSyncPendingRequests = () => {
  const dispatch = useDispatch();
  const isOnlineAndLoggedIn = useSelector(getIsOnlineAndLoggedIn);
  const sessionKey = useSelector(getSessionKey);

  useEffect(() => {
    if (isOnlineAndLoggedIn) {
      dispatch(checkSessionNotExpiring(sessionKey)).then(sessionOK => {
        if (sessionOK) {
          dispatch(syncPendingRequest());
        }
      });
    }
  }, [isOnlineAndLoggedIn, sessionKey, dispatch]);
};

const useCoupons = () => {
  const dispatch = useDispatch();
  const isOnlineAndLoggedIn = useSelector(getIsOnlineAndLoggedIn);
  const couponsEmpty = useSelector(campaignDataNotFetched);

  useEffect(() => {
    if (couponsEmpty && isOnlineAndLoggedIn) {
      dispatch(fetchCampaignsAndCoupons());
    }
  }, [isOnlineAndLoggedIn, couponsEmpty, dispatch]);
};

const RedSwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.error.main,
    '&$checked': {
      color: theme.palette.error.light,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.error.light,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const HeaderConnectivity = () => {
  const dispatch = useDispatch();

  const connectionStatus = useSelector(getConnectionStatus);
  const connectionHealth = useSelector(getConnectionHealth);
  const isForcedOfflineMode = useSelector(getIsForcedOfflineMode);
  const settingsPerms = useSelector(getHasRightToEditSettings);
  const activity = useObservable(networkStatus$, 0);

  const { t } = useTranslation(['header', 'alerts']);

  const connectionActivity = useMemo(
    () =>
      new Array(activity)
        .fill(0)
        .map((z, i) => (
          <i
            key={String(i)}
            className="erply-header__connection-activity arrow_up-down_alt"
          />
        )),
    [activity],
  );

  const popperConfig = useMemo(
    () => ({
      /* GPU acceleration is achieved by using translate3D,
      which causes text to be rendered unsharply at zoom levels other than 100% */
      modifiers: { computeStyle: { gpuAcceleration: false } },
    }),
    [],
  );

  const onChange = useCallback(
    () => dispatch(setForcedOfflineStatus(!isForcedOfflineMode)),
    [dispatch, isForcedOfflineMode],
  );

  const style = useMemo(() => ({ padding: 0 }), []);

  useForceOffline();
  useSyncPendingRequests();
  useCoupons();

  const classNames = `erply-header__connection ${!connectionHealth &&
    'erply-header__offline-mode'} erply-header__icon erply-header__connection--${connectionStatus}`;
  return (
    <Dropdown
      className="erply-header__dropdown erply-header__dropdown--sync"
      alignRight
      data-testid="header-connectivity"
    >
      <Dropdown.Toggle as="div">
        <div className={classNames} title={t('titles.connectivity')}>
          {connectionActivity}
          <HeaderIconButton size="small">
            <Icon name="erply_wifi" />
          </HeaderIconButton>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu popperConfig={popperConfig}>
        <Dropdown.Item as="div">
          <strong>
            {`${t('connectionStatuses.mode', { ns: 'alerts' })} ${
              connectionHealth
                ? 'Online'
                : `${isForcedOfflineMode && ' Forced '}Offline`
            }`}
          </strong>
        </Dropdown.Item>
        <Dropdown.Item as="div">
          {connectionStatus === 'error' || isForcedOfflineMode ? (
            <Trans
              i18nKey="lostConnection.description"
              ns="alerts"
              values={{ type: isForcedOfflineMode ? 'forced' : '' }}
              components={{ title: <strong />, p: <p /> }}
            />
          ) : (
            <strong>
              {t('connectionStatuses.status', { ns: 'alerts' })}
              <span className={`connection-status-color ${connectionStatus}`}>
                {connectionStatus}
              </span>
            </strong>
          )}
        </Dropdown.Item>
        {settingsPerms ? (
          <Dropdown.Item as="div">
            <div onClick={e => e.stopPropagation()} className="force-offline">
              <strong>Force Offline</strong>
              <RedSwitch checked={isForcedOfflineMode} onChange={onChange} />
            </div>
          </Dropdown.Item>
        ) : null}
        <Dropdown.Item as="div" className="text-center" style={style}>
          <ConnectivityInfoPopup />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderConnectivity;
