import React from 'react';
import { Box, Typography } from '@material-ui/core';

import MuiButton from 'components/CustomButtons/MuiButton';

function VoucherErrorModal({
  errors,
  addPayment,
  voidAll,
}: {
  errors: string[];
  addPayment: () => void;
  voidAll?: () => void;
}) {
  return (
    <Box
      padding="1rem"
      justifyContent="center"
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{ fontWeight: 'bold' }}
    >
      <Typography variant="h5" style={{ marginTop: '30px' }}>
        Error!
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: '30px', marginBottom: '30px' }}
      >
        The below vouchers are not valid.
      </Typography>
      {errors.map(ev => (
        <Typography variant="h5">{ev}</Typography>
      ))}
      <Box
        display="flex"
        justifyContent="space-around"
        style={{ width: '400px', marginTop: '30px' }}
      >
        <MuiButton
          style={{
            fontSize: '1.25rem',
            width: '120px',
            height: '70px',
          }}
          color="grid_green"
          variant="contained"
          size="large"
          onClick={addPayment}
        >
          Continue
        </MuiButton>
        <MuiButton
          style={{
            fontSize: '1.25rem',
            width: '120px',
            height: '70px',
          }}
          color="grid_red"
          variant="contained"
          size="large"
          onClick={voidAll}
        >
          Void all
        </MuiButton>
      </Box>
    </Box>
  );
}
export default VoucherErrorModal;
