import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './BottomPopup.css';
import {
  fetchLayawaySalesAndOpenModal,
  fetchOrderSalesAndOpenModal,
  fetchPendingSalesAndOpenModal,
  fetchRecentSalesAndOpenModal,
} from 'actions/sales';

const OpenSaleView = ({ goBack, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('gridButtons');
  const openPendingSales = () => {
    dispatch(fetchPendingSalesAndOpenModal());
    close();
  };
  const openRecentSales = () => {
    dispatch(fetchRecentSalesAndOpenModal());
    close();
  };

  const openLayaways = () => {
    dispatch(fetchLayawaySalesAndOpenModal());
    close();
  };

  const openOrders = () => {
    dispatch(fetchOrderSalesAndOpenModal());
    close();
  };

  return (
    <>
      <span className="mobile-menu-action" onClick={openPendingSales}>
        {t('functions.pendingSales')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={openRecentSales}
        style={{ marginTop: '14px' }}
      >
        {t('functions.completedSales')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={openLayaways}
        style={{ marginTop: '14px' }}
      >
        {t('functions.layawaySales')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={openOrders}
        style={{ marginTop: '14px' }}
      >
        {' '}
        {t('functions.orderSales')}
      </span>
      <span
        className="btn-cancel mobile-menu-action"
        onClick={() => goBack()}
        style={{ marginTop: '28px' }}
      >
        {t('common:back')}
      </span>
    </>
  );
};

export default OpenSaleView;
