import { modalPages, OPEN_MODAL_PAGE } from 'constants/modalPage';
import { getPluginSelector } from 'reducers/Plugins';

export default (state = 0, { type, ...rest }): number => {
  if (type === OPEN_MODAL_PAGE) {
    const { component, props } = rest;
    if (component === modalPages.PaymentConfirmation) {
      return props.change ?? 0;
    }
  }
  return state;
};
export const getLastChange = state =>
  getPluginSelector<number>('last-sale-change-in-header')(n => n)(state);
