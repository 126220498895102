import { PosPlugin } from 'plugins/plugin';
import { addWarning } from 'actions/Error';

import { getCFRState } from '../rdx';

const forbidNewProductsOnOnlineReturn: Required<
  PosPlugin
>['onAddProduct']['on'] = (p, ap) => async (dispatch, getState) => {
  const { isOnlineReturn } = getCFRState(getState());

  if (isOnlineReturn && ap.product.amount > 0) {
    const message =
      'Not allowed to add products to online return. Please make a new sale for exchange.';
    dispatch(
      addWarning(message, {
        dismissible: false,
        selfDismiss: true,
      }),
    );
    throw new Error(message);
  }

  return ap;
};

export default forbidNewProductsOnOnlineReturn;
