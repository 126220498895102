/* eslint-disable eqeqeq,@typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary,no-case-declarations,no-plusplus */
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { renameKeys } from 'ramda-adjunct';

import { getSelectedPos } from 'reducers/PointsOfSale';
import * as types from 'constants/ShoppingCart';
import { TYPE_LOGOUT } from 'constants/Login';
import {
  SET_CUSTOMER_SUCCESS,
  SET_ONE_CUSTOMER_SUCCESS,
} from 'constants/customerSearch';
import { add, ErplyAttributes, notUndefinedOrNull, round } from 'utils';
import { nestGroupedProducts } from 'utils/tsHelpers';
import { SO } from 'services/DB/types';
import {
  getCurrentSalesDocument,
  getIsCurrentSaleAReturn,
  getIsSavedSale,
} from 'reducers/sales';
import { getProductsUniversal } from 'actions/productsDB';
import { unspreadPromotionRules } from 'utils/apiTransformations/saveSalesDocumentCart';

import { getProductByID } from './cachedItems/products';
import {
  getIsNonDiscountableProductsEnabled,
  getSetting,
  getShowPricesWithTax,
  getSkipCalculateShoppingCartOnChange,
} from './configs/settings';
import {
  getSelectedPosVatRate,
  getVatRates,
  getTaxFreeVatRate,
  getVatRateByID,
  getProductGroupVatRateForCachedProducts,
} from './vatRatesDB';
import { getConnectionHealth } from './connectivity/connection';
import {
  getCachedItemsPerType,
  getCachedItemsPerTypeByIDs,
} from './cachedItems';
import { createOverrideSelector } from './Plugins';
import { getSelectedCustomer } from './customerSearch';
import { getLoggedInEmployeeID, getRightToEditConfirmedOrders } from './Login';

function computed(state = {}, { type, payload }) {
  switch (type) {
    case TYPE_LOGOUT:
    case types.CALCULATE_FAILURE:
      return {
        ...state,
        total: undefined,
        netTotal: undefined,
        vatTotal: undefined,
      };
    case types.RESET_SHOPPING_CART:
      return {};
    case types.CALCULATE_SUCCESS:
      return {
        ...payload,
        rows: undefined,
      };
    default:
      return state;
  }
}

function updateOrderRowByIndex(index, payload) {
  return R.over(
    R.lensPath([index, 'user']),
    R.evolve({
      amount: R.add(payload.product.amount),
      addContainerOverride: R.or(payload.product.addContainerOverride),
    }),
  );
}
function rows(state = [], { type, payload }) {
  let orderIndex =
    Math.max(0, ...state.map(({ user: { orderIndex } }) => orderIndex)) + 1;
  switch (type) {
    case types.ADD_PRODUCT_SUCCESS:
      // Merge with existing product
      if (payload.options.merge) {
        const sameProdIndex = R.findLastIndex(
          R.pathEq(['user', 'productID'], payload.product.productID),
        )(state);
        // identical product in cart
        if (sameProdIndex > -1) {
          if (
            // identical product is the last in ShC
            sameProdIndex === state.length - 1 &&
            // only merge consecutive identical products
            payload.options.mergeCriteria === 'consecutive'
          )
            return updateOrderRowByIndex(sameProdIndex, payload)(state);
          // merge any identical products
          if (payload.options.mergeCriteria === 'any')
            return updateOrderRowByIndex(sameProdIndex, payload)(state);
        }
      }

      // Add new product
      return [
        ...state,
        {
          user: { ...payload.product, orderIndex: orderIndex++ },
          computed: {},
        },
      ];

    case types.ADD_MULTI_PRODUCTS_SUCCESS:
      return [
        ...state,
        ...payload.map(order => ({
          user: {
            ...order,
            manualDiscount: Number(order.manualDiscount),
            orderIndex: order.orderIndex || orderIndex++,
          },
          computed: {},
        })),
      ];
    case types.REMOVE_PRODUCT:
      return state.filter(prod => prod.user.orderIndex !== payload);

    case types.CALCULATE_SUCCESS:
      return state.map(({ user }) => ({
        user,
        computed: payload.rows[user.orderIndex].computed,
        container: payload.rows[user.orderIndex].container,
      }));
    case types.DECREASE_ORDER_AMOUNT:
      return state.map(order => {
        if (order.user.orderIndex === payload) {
          return {
            ...order,
            user: {
              ...order.user,
              amount:
                Number(order.user.amount) - 1 === 0
                  ? Number(order.user.amount) - 2
                  : Number(order.user.amount) - 1,
            },
          };
        }
        return order;
      });
    case types.INCREASE_ORDER_AMOUNT:
      return state.map(order => {
        if (order.user.orderIndex === payload) {
          return {
            ...order,
            user: {
              ...order.user,
              amount:
                Number(order.user.amount) + 1 === 0
                  ? Number(order.user.amount) + 2
                  : Number(order.user.amount) + 1,
            },
          };
        }
        return order;
      });
    case types.UPDATE_ORDER_AMOUNT:
      return state.map(order => {
        if (order.user.orderIndex === payload.orderIndex) {
          return {
            ...order,
            user: { ...order.user, amount: payload.amount },
          };
        }
        return order;
      });
    case types.UPDATE_ORDER_DISCOUNT:
      return state.map(order => {
        if (order.user.orderIndex === payload.orderIndex) {
          return {
            ...order,
            user: { ...order.user, manualDiscount: payload.manualDiscount },
          };
        }
        return order;
      });
    case types.UPDATE_ORDER_NAME:
      return state.map(order =>
        order.user.orderIndex !== payload.orderIndex
          ? order
          : {
              ...order,
              user: { ...order.user, name: payload.name },
            },
      );
    case types.UPDATE_ORDER_PRICE:
      return state.map(order => {
        if (order.user.orderIndex === payload.orderIndex) {
          return {
            ...order,
            user: {
              ...order.user,
              finalPriceWithVAT: payload.priceVAT,
              price: payload.price,
            },
          };
        }
        return order;
      });
    case types.CLEAR_ORDER_PRICE:
      return state.map(order => {
        if (order.user.orderIndex === payload.orderIndex) {
          return R.pipe(
            R.dissocPath(['user', 'finalPriceWithVAT']),
            R.dissocPath(['user', 'price']),
          )(order);
        }
        return order;
      });
    case types.UPDATE_ORDER_OTHER:
      return state.map(order => {
        if (order.user.orderIndex === payload.orderIndex) {
          return {
            ...order,
            user: {
              ...order.user,
              ...payload,
            },
          };
        }
        return order;
      });
    case types.UPDATE_NO_PRODUCT_CONTAINER:
      return state.map(r =>
        r.user.orderIndex === payload.orderIndex
          ? {
              ...r,
              user: {
                ...r.user,
                addContainerOverride: payload.addContainerOverride,
              },
            }
          : r,
      );
    case types.SET_EMPLOYEE:
      return state.map(order =>
        order.user.orderIndex !== payload.orderIndex
          ? order
          : {
              ...order,
              user: { ...order.user, employeeID: payload.employeeID },
            },
      );
    case types.RESET_DISCOUNT:
      return state.map(item => ({
        ...item,
        user: { ...item.user, manualDiscount: 0 },
      }));
    case types.APPLY_DISCOUNT:
      return state.map(item => ({
        ...item,
        user: {
          ...item.user,
          manualDiscount: 100 * payload.actual,
        },
      }));
    case types.UPDATE_PRODUCT_NOTES:
      // eslint decided that this looks better, so i left it as is
      return state.map(item =>
        String(item.user.orderIndex) === String(payload.orderIndex)
          ? {
              ...item,
              user: { ...item.user, notes: payload.notes },
            }
          : item,
      );
    case types.RESET_SHOPPING_CART:
    case TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}

function calculateOffline(state = false, { type, payload }) {
  if (type === types.SET_CALCULATE_OFFLINE) {
    return payload;
  }
  return state;
}

/**
 * Increments every time the Shopping Cart is mutated
 * @returns {number}
 */
function calcIndex(state = 0, { type }) {
  switch (type) {
    case TYPE_LOGOUT:
    case types.CALCULATE_SUCCESS:
    case types.RESET_SHOPPING_CART:
      return -Math.abs(state);

    /*
     * Each action whose action type increments calcIndex should be followed by recalculation
     * Wrapping in recalculateAfter might be preferable
     * Otherwise calcIndex gets incremented and it breaks `calculating` state
     */
    case types.CALCULATE:
    case types.ADD_PRODUCT_SUCCESS:
    case types.ADD_MULTI_PRODUCTS_SUCCESS:
    case types.REMOVE_PRODUCT:
    case types.DECREASE_ORDER_AMOUNT:
    case types.INCREASE_ORDER_AMOUNT:
    case SET_CUSTOMER_SUCCESS:
    case SET_ONE_CUSTOMER_SUCCESS:
    case types.UPDATE_ORDER_AMOUNT:
    case types.UPDATE_ORDER_DISCOUNT:
    case types.UPDATE_ORDER_PRICE:
    case types.UPDATE_ORDER_OTHER:
    case types.UPDATE_ORDER_NAME:
    case types.UPDATE_NO_PRODUCT_CONTAINER:
    case types.SET_EMPLOYEE:
    case types.RESET_DISCOUNT:
    case types.APPLY_DISCOUNT:
    case types.UPDATE_PRODUCT_NOTES:
      return Math.abs(state) + 1;
    default:
      return state;
  }
}

function pendingCalculate(state = false, { type }) {
  switch (type) {
    case types.ADD_PRODUCT_SUCCESS:
      return true;
    case types.CALCULATE:
      return false;
    default:
      return state;
  }
}

function selectedOrder(state = null, { type, payload }) {
  if (type === TYPE_LOGOUT) {
    return null;
  }
  if (type === types.SET_SELECTED_ORDER) {
    return payload;
  }
  return state;
}

function discountApplied(state = false, { type, payload }) {
  switch (type) {
    case types.APPLY_DISCOUNT:
      return payload.amount
        ? { amount: payload.amount }
        : { percentage: payload.percentage };
    case types.RESET_SHOPPING_CART:
    case types.RESET_DISCOUNT:
    case TYPE_LOGOUT:
      return false;
    default:
      return state;
  }
}

function couponsApplied(state = [], { type, payload }) {
  switch (type) {
    case types.APPLY_COUPONS:
      return [
        ...state.filter(i =>
          payload.every(p => i.uniqueIdentifier !== p.uniqueIdentifier),
        ),
        ...payload,
      ];
    case types.SET_COUPONS:
      return payload;
    case types.REMOVE_COUPON:
      return state.filter(i => i.uniqueIdentifier !== payload);
    case types.RESET_SHOPPING_CART:
    case SET_CUSTOMER_SUCCESS:
    case TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}
function promotionsApplied(state = [], { type, payload }) {
  switch (type) {
    case types.APPLY_PROMOTION:
      return [
        ...state.filter(p => p.campaignID !== payload.campaignID),
        payload,
      ];
    case types.REMOVE_PROMOTION:
      return state.filter(p => p.campaignID !== payload);
    case types.RESET_SHOPPING_CART:
    case TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}
function vatRate(state = null, { type, payload }) {
  switch (type) {
    case types.SET_VATRATE:
      return payload;
    case types.RESET_SHOPPING_CART:
    case TYPE_LOGOUT:
      return null;
    default:
      return state;
  }
}

function selectedOffers(state = [], { type, payload }) {
  switch (type) {
    case types.SET_SELECTED_OFFERS:
      return [...state, payload];
    case types.IS_NOT_OFFER:
    case types.RESET_SHOPPING_CART:
    case TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}

function calculating(state = false, { type }) {
  switch (type) {
    case types.CALCULATE:
      return true;
    case types.CALCULATE_SUCCESS:
    case types.CALCULATE_FAILURE:
    case types.RESET_SHOPPING_CART:
    case TYPE_LOGOUT:
      return false;
    default:
      return state;
  }
}

/**
 * Discount Reasons reducer
 * <br />
 *
 * Dict that keeps track of all discount reasons in the shopping cart.
 * Each Shopping Cart product discount reason is indexed by the product orderIndex in the shopping cart.
 * Global Discount reason is indexed with 0 - orderIndex always starts from 1
 *
 * @see {@link rows} for orderIndex generation
 * @see {@link discountApplied} for the actual applied discount amount
 */

function discountReasons(state = {}, { type, payload }) {
  switch (type) {
    case types.SET_DISCOUNT_REASON:
      return R.assoc(payload.orderIndex ?? 0, payload, state);
    case types.CLEAR_GLOBAL_DISCOUNT_REASON:
      return R.dissoc(0, state);
    case types.CLEAR_DISCOUNT_REASON:
      return R.dissoc(payload, state);
    case types.CLEAR_DISCOUNT_REASONS:
    case TYPE_LOGOUT:
      return {};
    default:
      return state;
  }
}

function returnReasons(state = [], { type, payload }) {
  switch (type) {
    case types.SET_RETURN_REASON:
      return [...state]
        .filter(item => Number(item.orderIndex) !== Number(payload.orderIndex))
        .concat(payload);
    case types.CLEAR_RETURN_REASONS:
      return [];
    case types.CLEAR_RETURN_REASON:
      return [...state].filter(
        item => Number(item.orderIndex) !== Number(payload),
      );
    case types.CLEAR_GLOBAL_RETURN_REASON:
      return [...state].map(item => !!item.orderIndex);
    default:
      return state;
  }
}

/**
 * rows: {user: {...}, computed:{...}}[]
 * calcIndex: number
 * selectedOrder: ???
 * discountApplied: {amount: number} | {percentage: number}
 */
const combined = combineReducers({
  computed,
  rows,
  calcIndex,
  calculateOffline,
  selectedOrder,
  discountApplied,
  promotionsApplied,
  couponsApplied,
  vatRate,
  selectedOffers,
  calculating,
  discountReasons,
  returnReasons,
  pendingCalculate,
});

export default combined;

export function getShouldCalculateOffline(state) {
  return state.shoppingCart.calculateOffline;
}

export function getIsCalculatingShoppingCart(state) {
  return state.shoppingCart.calculating;
}

export const getCartIsPendingCalculate = createSelector(
  state => state.shoppingCart.pendingCalculate,
  state =>
    getSetting(
      'pos_brazil_do_not_calculate_shopping_cart_on_change',
      false,
    )(state),
  (isPendingCalculate, skipCalculate) => {
    if (skipCalculate) {
      return false;
    }
    return isPendingCalculate;
  },
);

export function getHasProductsInShoppingCart(state) {
  return state.shoppingCart.rows.length > 0;
}

export function getShoppingCartRows(state) {
  return state.shoppingCart.rows;
}

export function shoppingCartPromotions(state) {
  return (
    (state.shoppingCart.computed &&
      state.shoppingCart.computed.appliedPromotions) ||
    []
  );
}

export function getHasProductDiscountReasonByIndex(orderIndex) {
  return state => !!state.shoppingCart.discountReasons[orderIndex];
}

export function getHasProductReturnReasonByIndex(orderIndex) {
  return state => {
    const { returnReasons } = state.shoppingCart;
    return !!returnReasons.some(
      dr => Number(dr.orderIndex) === Number(orderIndex),
    );
  };
}

export function getAppliedPromotions(state) {
  return state.shoppingCart.promotionsApplied.map(prom => ({
    ...prom,
    count:
      (
        (state.shoppingCart.computed.appliedPromotions || []).find(
          p => p.promotionID === prom.campaignID,
        ) || {}
      ).count || 0,
  }));
}

export function getAllPromotions(state) {
  const spPromo = shoppingCartPromotions(state);
  const apPromo = getAppliedPromotions(state);
  const allPromotions = {};
  if (apPromo.length) {
    for (let i = 0; i < apPromo.length; i++) {
      Object.assign(allPromotions, {
        [`promotionID${i + 1}`]: apPromo[i].promotionID,
        [`promotionCount${i + 1}`]: apPromo[i].count,
      });
    }
  }
  if (spPromo.length) {
    for (let i = 0; i < spPromo.length; i++) {
      Object.assign(allPromotions, {
        [`promotionID${i + 1}`]: spPromo[i].promotionID,
        [`promotionCount${i + 1}`]: spPromo[i].count,
      });
    }
  }
  return allPromotions;
}

export const getAllPromotionsRewardPointCost = createSelector(
  state => shoppingCartPromotions(state),
  rowProm => rowProm.map(prom => prom.count * prom.rewardPoints).reduce(add, 0),
);

export const getAppliedPromotionCount = createSelector(
  getAppliedPromotions,
  promotions => promotions.reduce((sum, prom) => sum + prom.count, 0),
);

export function getAllAppliedCoupons(state) {
  return state.shoppingCart.couponsApplied;
}

/**
 * Coupons that have been 'applied' by the cashier.
 *
 * This includes coupons whose requirements are not currently fulfilled
 *
 * See the `.used` property to check whether each particular coupon was actually
 * used on the sale
 */
export const getAppliedCoupons = createSelector(
  state => state.shoppingCart.couponsApplied,
  state => state.shoppingCart.computed.usedCouponIdentifiers,
  (coupons, uniqueIdentifiers) =>
    coupons.map(coup => ({
      ...coup,
      used: (uniqueIdentifiers || '')
        .split(',')
        .some(a => a === coup.uniqueIdentifier),
    })),
);

export const getAppliedPromotionCoupons = createSelector(
  state => state.shoppingCart.computed.appliedPromotions,
  state => state.shoppingCart.computed.usedCouponIdentifiers,
  (appliedPromotions, uniqueIdentifiers) => {
    return appliedPromotions
      ?.map(p => p.promotionID)
      .map(promotionID => {
        const uniqueIdentifier = (uniqueIdentifiers || '')
          .split(',')
          .find(identifier => identifier.includes(promotionID));
        return {
          uniqueIdentifier,
          // Not entirely sure how promotions coupons work
          // hopefully this is correct
          used: !!uniqueIdentifier,
        };
      });
  },
);

export const getAppliedCouponCount = createSelector(
  getAppliedCoupons,
  coupons => coupons.filter(coup => coup.used).length,
);

function hasNumericAttr(item, attrName) {
  return Number(new ErplyAttributes(item?.attributes).get(attrName)) === 1;
}
export function getHasKitchenItems(state) {
  return state.shoppingCart.rows?.some(product =>
    hasNumericAttr(
      getProductByID(product.user.productID)(state),
      'kitchen_item',
    ),
  );
}
export function getHasBarItems(state) {
  return (
    // prettier-ignore
    state.shoppingCart.rows?.some(product =>
      hasNumericAttr(
        getProductByID(product.user.productID)(state),
        "bar_item"
      )
    )
  );
}

export const getRawProductsInShoppingCart = createSelector(
  state => state.shoppingCart.rows,
  state => getCachedItemsPerType(SO.PRODUCTS.NAME)(state),
  (rows, products) =>
    rows.flatMap(row => {
      const product = products[row.user.productID];
      if (!row.container) return product;
      const containerProducts = row.container.map(
        container => products[container.productID],
      );
      return [product, ...containerProducts];
    }),
);

export const getProductsInShoppingCartBase = createSelector(
  state => state.shoppingCart.rows,
  state => getCachedItemsPerType(SO.PRODUCTS.NAME)(state),
  getConnectionHealth,
  getShouldCalculateOffline,
  getIsSavedSale,
  getVatRates,
  state => getGetVatRateIdByRow(state),
  (
    rows,
    products,
    connectionHealth,
    shouldCalculateOffline,
    isSavedSale,
    vatRates,
    getVatRateID,
  ) => {
    const removeIllegalValues = obj => {
      const newObj = {};
      Object.entries(obj).forEach(([key, value]) => {
        if (
          value !== undefined &&
          (typeof value === 'number' ? !Number.isNaN(Number(value)) : true)
        ) {
          newObj[key] = value;
        }
      });
      return newObj;
    };
    // isContainer - prevent manually entered price from being passed to container
    const fromProduct = (user, product, isContainer = false) => {
      const vatRateID = getVatRateID(user);
      const vatRatePercent = vatRateID
        ? vatRates.find(({ id }) => String(id) === vatRateID)?.rate ?? 0
        : 0;

      const discountPrice = price =>
        Number(price * (1 - (user.discount ?? 0) / 100));

      const getFinalPrice = () => {
        if (connectionHealth) {
          if (isContainer) {
            return discountPrice(product.priceListPrice ?? product.price);
          }
          return discountPrice(
            user.price ?? product.priceListPrice ?? product.price,
          );
        }
        return discountPrice(user.price ?? product.priceListPrice);
      };

      // price = manual / priceList / productCart
      const finalPrice = getFinalPrice();

      // priceVAT - manual + vat / priceListWithVAT / productCartWithVAT
      const finalPriceWithVAT = Number(
        finalPrice + finalPrice * (vatRatePercent / 100),
      );

      const rowNetTotal = user.amount * finalPrice;

      const rowTotal = user.amount * finalPriceWithVAT;

      const rowVATTotal = rowTotal - rowNetTotal;

      return {
        name: product.name,
        code: product.code,
        productID: product.productID,
        description: product.description,
        longDesc: product.longDesc,
        basePriceWithVAT: product.priceWithVat,
        basePriceNet: Number(product.price),
        basePrice: product.price,
        priceListPrice: product.priceListPrice,
        priceListPriceWithVAT: product.priceListPriceWithVat,
        finalPrice,
        finalPriceWithVAT,
        rowTotal,
        rowNetTotal,
        rowVATTotal,
        vatRate: vatRatePercent,
      };
    };
    const fromComputed = (user, computed) =>
      computed
        ? {
            basePriceWithVAT: computed.originalPriceWithVAT,
            basePrice: computed.originalPrice,
            vatrateID: computed.vatrateID,
            vatRate: computed.vatRate,
            manualDiscount: computed.manualDiscount,
            promotionDiscount: computed.promotionDiscount,
            totalDiscount: computed.discount,
            totalDiscountAmountWithVAT:
              (computed.discount / 100) * computed.originalPriceWithVAT,
            totalDiscountAmount:
              (computed.discount / 100) * computed.originalPrice,
            finalPriceWithVAT: computed.finalPriceWithVAT,
            finalPrice: computed.finalPrice,
            rowNetTotal: computed.rowNetTotal,
            rowVATTotal: computed.rowVAT,
            rowTotal: computed.rowTotal,
            rowNumber: computed.rowNumber,
          }
        : {};
    const withUser = (user, product) => ({
      manualDiscount: user.discount,
      totalDiscount: user.discount,
      finalPriceWithVAT: (product.priceWithVat * user.discount) / 100,
      finalPrice: (product.price * user.discount) / 100,
    });
    return rows.flatMap(
      ({ user: { ...user }, computed = {}, container = [] }) => {
        const product = products[user.productID] || {};
        // eslint-disable-next-line no-param-reassign
        user.discount = user.discount || user.manualDiscount;

        // if saved sale, remove illegal values for user object earlier in order to display correct
        // price if promotion does not exists anymore
        const data = isSavedSale
          ? {
              ...withUser(user, product),
              ...removeIllegalValues(user),
              ...removeIllegalValues(fromProduct(user, product)),
              ...removeIllegalValues(
                shouldCalculateOffline ? {} : fromComputed(user, computed),
              ),
            }
          : {
              ...withUser(user, product),
              ...removeIllegalValues(fromProduct(user, product)),
              ...removeIllegalValues(
                shouldCalculateOffline ? {} : fromComputed(user, computed),
              ),
              ...removeIllegalValues(user),
            };

        const ineligibleForDiscount =
          product.isGiftCard || product.isRegularGiftCard;

        return [
          {
            ...data,
            // prettier-ignore
            ...(ineligibleForDiscount ? { manualDiscount: 0, discount: 0 } : {}),
            orderIndex: user.orderIndex,
            userVatrateID: user.vatrateID,
            computed: false,
          },
          ...(user.addContainerOverride === false ? [] : container).map(
            (cnt, i) => ({
              name: products[cnt.productID]?.name,
              ...cnt,
              computed: true,
              parentRowID: user.orderIndex,
              orderIndex: `${user.orderIndex}-${i + 1}`,
              rowVATTotal: cnt.rowVAT,
            }),
          ),
        ];
      },
    );
  },
);
/**
 * @returns {Array<{
 *   orderIndex: number,
 *   name: string,
 *   code: string,
 *   amount: string | number,
 *   productID: string,
 *   description: string,
 *   longDesc: string,
 *   basePriceWithVAT: any,
 *   basePriceNet: any,
 *   basePrice: any,
 *   manualDiscount: any,
 *   totalDiscount: any,
 *   finalPriceWithVAT: any,
 *   finalPrice: any,
 *   rowNetTotal: any,
 *   rowVATTotal: any,
 *   rowTotal: any,
 *   priceListPrice: any,
 *   priceListPriceWithVAT: any
 * }>}
 */
export const getProductsInShoppingCart = createOverrideSelector(
  'getProductsInShoppingCart',
  getProductsInShoppingCartBase,
);

/** Rename keys AND remove any extra keys that are not mentioned */
const renameKeysPick = R.curry((mapping, obj) =>
  R.pipe(renameKeys(mapping), R.pick(Object.values(mapping)))(obj),
);

/**
 * Returns items in the shopping cart formatted into valid parameters for saveSalesDocuments
 * Not flattened, but to flatten all that you have to do is
 * ```
 * mergeAll(returnValue.map(
 *   (row,i) => renameKeysWith(k => `${k}${i+1}`)(row)
 * )
 * ```
 * @example
 * [
 *   {
 *     productID: 1,
 *     amount: 1,
 *   },
 *   {
 *     productID: 7,
 *     amount: 4,
 *     itemName: 'Edited name of product 7'
 *   }
 * }
 */
export const getShoppingCartForSalesDocument = createSelector(
  state => state.shoppingCart.rows,
  state => state.shoppingCart.returnReasons,
  state => state.shoppingCart.discountReasons,
  state => getGlobalReturnReason(state),
  state => getGlobalDiscountReason(state),
  state => getCachedItemsPerType(SO.PRODUCTS.NAME)(state),
  getShouldCalculateOffline,
  state => getGetVatRateIdByRow(state),
  (
    rows,
    returnReasons,
    discountReasons,
    globalReturnReason,
    globalDiscountReason,
    products,
    shouldCalculateOffline,
    getVatRateID,
  ) => {
    return rows.flatMap(row => {
      // Copy fields which do not go through calculate
      const main = R.pipe(
        // TODO: Push this up to calculate / shopping cart reducer
        //  So that inside our code we are always dealing with the structured format
        unspreadPromotionRules,
        // These normally already exist in computed, but they don't in offline mode
        R.assoc('productID', row.user.productID),
        R.assoc('amount', row.user.amount),

        R.assoc('itemName', row.user.name ?? row.user.itemName),
        R.assoc('employeeID', row.user.employeeID),
        // user.price provided as fallback for offline mode
        R.assoc(
          'price',
          row.computed.originalPrice ??
            row.user.price ??
            products[row.user.productID]?.priceListPrice,
        ),
        // Assign the correct re-fetch vatRateID if computed one is undefined
        R.assoc('vatrateID', row.computed.vatrateID ?? getVatRateID(row.user)),
        R.ifElse(
          // If the cart was not recalculated after entering the payment screen and connection health was changed - ALWAYS save data as it was in the cart (PBIB-6777)
          R.always(shouldCalculateOffline),
          R.pipe(
            R.assoc('discount', row.user.manualDiscount),
            R.assoc('promotionRules', []),
          ),
          R.pipe(
            R.modifyPath(
              ['promotionRules', 0], // TODO: Maybe find which one is the manual promotion
              R.mergeDeepLeft({
                manualDiscountPercentage: row.user.manualDiscount,
                manualDiscountReasonID:
                  row.user.manualDiscountReasonCodeID ??
                  discountReasons[row.user.orderIndex]?.reason?.reasonID ??
                  globalDiscountReason?.reasonID,
              }),
            ),
            R.omit('promotionPrice'),
            R.omit('promotionPriceWithVAT'),
          ),
          R.assoc('discount', row.computed.discount),
        ),
        R.assoc('orderIndex', row.user.orderIndex),
        R.assoc('returnReasonID', row.user.returnReasonID),
        R.assoc('stableRowID', row.user.stableRowID),
      )(row.computed);

      // Add containers
      const container = row.container
        ? R.map(R.assoc('employeeID', row.user.employeeID))(row.container)
        : [];
      return (
        R.concat([main], container)
          .map(row => {
            let rowToReturn = row;

            const returnReasonID =
              row.returnReasonID ||
              (returnReasons.find(r => r.orderIndex === row.orderIndex)?.reason
                ?.reasonID ??
                globalReturnReason?.reasonID);
            const discountReasonID =
              discountReasons[row.orderIndex]?.reason?.reasonID ??
              globalDiscountReason?.reasonID;

            /** A reason for returning the item (if document is a return),
            or for discount (if document is a regular sale)
            For simplicity: Per API - discount reason for regular sale, return reason for return. */
            const reasonID = row.amount > 0 ? discountReasonID : returnReasonID;

            const { manualDiscount } = row;
            if (reasonID)
              rowToReturn = R.assoc('returnReasonID', reasonID, rowToReturn);
            if (manualDiscount) {
              rowToReturn = R.pipe(
                // If no discounts/promotions are applied by the API then manually apply discount/promotion
                R.when(
                  r => r.promotionRules.length === 0,
                  R.modify(
                    'promotionRules',
                    R.append({
                      manualDiscountPercentage: manualDiscount,
                      manualDiscountReasonID: discountReasonID,
                      amount: row.amount,
                      finalPrice:
                        Math.sign(row.amount) * Math.abs(row.finalPriceWithVAT),
                      totalDiscount:
                        Math.sign(row.amount) *
                        Math.abs(
                          row.originalPriceWithVAT - row.finalPriceWithVAT,
                        ),
                    }),
                  ),
                ),
              )(rowToReturn);
            }
            return rowToReturn;
          })
          .map(R.dissoc('orderIndex'))
          // Remove fields which can cause API error
          .map(
            R.evolve({
              sourceWaybillID: v => (Number(v) === 0 ? undefined : v),
            }),
          )
      );
    });
  },
);

/**
 * Returns the current shopping cart as api parameters for sending to calculateShoppingCart
 * Not flattened, but to flatten all that you have to do is
 * ```
 * mergeAll(returnValue.map(
 *   (row,i) => renameKeysWith(k => `${k}${i+1}`)(row)
 * )
 * ```
 * @example
 * [
 *   {
 *     productID: 1,
 *     amount: 1,
 *   },
 *   {
 *     productID: 7,
 *     amount: 4,
 *   }
 * }
 */
export async function prepareShoppingCartForCalculate(dispatch, getState) {
  const state = getState();
  const { rows } = state.shoppingCart;
  const { discountReasons } = state.shoppingCart;
  const globalDiscountReason = getGlobalDiscountReason(state);
  const currentSalesDocument = getCurrentSalesDocument(state);
  const {
    taxExemptCertificateNumber,
    isPartialExempt: customerHasPartialExemptionNr,
  } = currentSalesDocument;
  const noContainerFeeOnReturn = getSetting(
    'touchpos_no_container_fee_on_return',
  )(state);

  const calculateContainerProducts = async user => {
    const containers = [];

    // In case if Invoice-Waybills API does not allow to add/edit rows
    // thus to avoid adding containers that were not added on sale document creation
    // assign containers based on existing rows
    if (currentSalesDocument.type === 'INVWAYBILL') {
      const { rows: saleDocRows } = currentSalesDocument;
      let index = saleDocRows.findIndex(
        row => row.stableRowID === user.stableRowID,
      );
      // If such row was not found (index: -1), then there's nothing to check for, thus returning []
      if (index === -1) return containers;
      let count = 1;
      while (
        Number(saleDocRows[index].containerID) ===
        Number(saleDocRows[index + 1]?.productID)
      ) {
        const container = saleDocRows[index + 1];
        containers.push({
          productID: Number(container.productID),
          parentRowID: user.orderIndex,
          amount: Number(container.amount),
          userVatrateID: container.vatrateID,
          orderIndex: `${user.orderIndex}-${count}`,
          discount: container.discount,
        });
        index++;
        count++;
      }
      return containers;
    }

    // Override to false
    if (user.addContainerOverride === false) return containers;
    // Not overridden to true and is a return
    if (!user.addContainerOverride && user.amount < 0) return containers;
    const usedIDs = new Set();
    let id = Number(user.productID);
    // Dealing with free-tex line products. These items have an ID value of 0. In this case the product is not cached so it should be passed directly form the order.
    const prod = id === 0 ? user : getProductByID(id)(getState());
    if (prod.containerID === 0) return containers.slice(0, -1);
    let amount = Number(user.amount);
    while (!usedIDs.has(id)) {
      usedIDs.add(id);
      const {
        products: [product],
        // eslint-disable-next-line no-await-in-loop
      } = await dispatch(
        getProductsUniversal({ productID: id }, { addToCachedItems: true }),
      );
      id = Number(product.containerID);
      amount *= Number(product.containerAmount);
      if (id === 0) return containers;
      if (!user.addContainerOverride) {
        // This check doesn't seem to match the name, but that's how it was implemented previously as two separate conditions
        if (noContainerFeeOnReturn && user.amount < 0) return containers;
      }

      containers.push({
        productID: id,
        parentRowID: user.orderIndex,
        amount: Number(amount),
        userVatrateID: user.vatrateID,
        orderIndex: `${user.orderIndex}-${usedIDs.size}`,
      });
    }
    return containers.slice(0, -1); // dependency cycle, exit
  };

  return (
    await Promise.all(
      rows.map(async row => {
        return [row.user, ...(await calculateContainerProducts(row.user))]
          .map(
            renameKeysPick({
              orderIndex: 'orderIndex',
              productID: 'productID',
              amount: 'amount',
              basePrice: 'price',
              vatrateID: 'vatrateID',
              reasonID: 'reasonID',
              manualDiscount: 'manualDiscount',
              returnReasonID: 'returnReasonID',
              stableRowID: 'stableRowID',
            }),
          )
          .map(
            R.pickBy(
              value => notUndefinedOrNull(value) && !Number.isNaN(value),
            ),
          );
      }),
    ).catch(e => console.error('Failed to calculate container products', e))
  )
    .flatMap(a => a)
    .map(row => {
      const discountReason =
        row.reasonID ??
        discountReasons[row.orderIndex]?.reason ??
        globalDiscountReason;
      if (discountReason?.reasonID)
        return R.assoc(
          'manualDiscountReasonCodeID',
          discountReason?.reasonID,
          row,
        );
      return row;
    })
    .map(row => ({
      ...row,
      vatrateID: getRowVatRateID(row)(getState()),
    }))
    .map(
      R.when(
        R.either(
          R.pipe(R.prop('vatrateID'), R.isNil),
          R.always(
            taxExemptCertificateNumber && !customerHasPartialExemptionNr,
          ),
        ),
        R.dissoc('vatrateID'),
      ),
    );
}

/**
 * Tax priority:
 * 1. Tax free in all locations
 * 2. Partial tax exemption (reduced tax rate is calculated according to the same priority (steps 4-9))
 * 3. Full tax exemption
 * 4. Explicitly set row tax rate
 * 5. Sale tax rate
 * 6. Product group tax rate (TODO: implement)
 * 7. Multi-Tier location tax rate
 * 8. POS tax rate
 * 9. Product tax rate
 *
 * Reference: {@link https://wiki.erply.com/article/1570-taxes wiki}
 *
 * @param row - Shopping cart row
 * @returns Vat rate id for the given row
 */
export function getRowVatRateID(row) {
  return state => {
    const saleVatRate = getCurrentSaleVatrate(state);
    const posVatRate = getSelectedPosVatRate(state)?.id;
    const {
      partialTaxExemption: customerIsPartiallyExempt,
    } = getSelectedCustomer(state);
    const { rows } = state.shoppingCart;
    const rowProducts = getCachedItemsPerTypeByIDs(
      SO.PRODUCTS.NAME,
      rows.map(row => row.user.productID),
    )(state);
    const {
      taxExemptCertificateNumber,
      isPartialExempt: customerHasPartialExemptionNr,
    } = getCurrentSalesDocument(state);

    const product = rowProducts[row.productID];
    // Priority 1
    if (product?.taxFree) {
      return getTaxFreeVatRate(state)?.id;
    }
    const existing = row.vatrateID;
    // Priority 2
    if (
      customerIsPartiallyExempt ||
      (taxExemptCertificateNumber && customerHasPartialExemptionNr)
    ) {
      const baseVatRateID =
        existing ?? saleVatRate ?? posVatRate ?? product.vatrateID;
      return getVatRateByID(baseVatRateID)(state)?.gstExemptTaxRateID;
    }
    // Priority 3
    if (taxExemptCertificateNumber && !customerHasPartialExemptionNr)
      return getTaxFreeVatRate(state)?.id;
    // Priority 4
    if (existing) return existing;
    // Priority 5
    if (saleVatRate) return saleVatRate;
    // Priority 6
    // TODO: implement
    // Priority 7
    // TODO: implement
    // Priority 8 and 9: Applied automatically by API
    return undefined;
  };
}

const getGetVatRateIdByRow = createSelector(
  state => getCachedItemsPerType(SO.PRODUCTS.NAME)(state),
  getCurrentSalesDocument,
  getSelectedPosVatRate,
  getProductGroupVatRateForCachedProducts,
  (products, salesDoc, posVatRate, productGroupVatRates) => {
    const { taxExemptCertificateNumber, isPartialExempt } = salesDoc ?? {};
    const { gstExemptTaxRateID, id: locationVatRateID } = posVatRate ?? {};
    return function getVatRateID(row) {
      const product = products[row.productID] || {};
      if (isPartialExempt && gstExemptTaxRateID) {
        return String(gstExemptTaxRateID);
      }
      if (taxExemptCertificateNumber && !isPartialExempt) return null;

      if (product.taxFree) return null;
      return String(
        row.vatrateID ||
          productGroupVatRates[product.productID] ||
          locationVatRateID ||
          product.vatrateID,
      );
    };
  },
);

export const getShoppingCartNeedsCalculation = createSelector(
  state => getSkipCalculateShoppingCartOnChange(state),
  state => state.shoppingCart.calcIndex,
  (skipCalculate, calcIndex) => skipCalculate && Number(calcIndex) > 0,
);

/**
 * Returns nested products from shopping cart
 */
export const getNestedProductsInShoppingCart = createSelector(
  state => getProductsInShoppingCart(state),
  products =>
    nestGroupedProducts(products, {
      parentIndicator: 'parentRowID',
      idIndicator: 'orderIndex',
    }),
);

export function getLastProductIndex(state) {
  return (
    state.shoppingCart.rows[state.shoppingCart.rows.length - 1] || {
      user: { productID: undefined },
    }
  ).user.orderIndex;
}

export function getLastProduct(state) {
  return getProductInOrderByIndex(getLastProductIndex(state))(state) || {};
}

export function getLastProductID(state) {
  return getLastProduct(state).productID;
}

export const getTotal = createSelector(
  state => state.shoppingCart.computed.total,
  getProductsInShoppingCart,
  (total, shoppingCartProducts) => {
    if (notUndefinedOrNull(total)) return total;

    return shoppingCartProducts.reduce(
      (total, nextRow) => total + Number(nextRow.rowTotal),
      0,
    );
  },
);

export function getTotalQuantity(state) {
  return getProductsInShoppingCart(state).reduce(
    (sum, row) => sum + Number(row.amount),
    0,
  );
}

export const getTotalTax = createSelector(
  state => state.shoppingCart.computed.vatTotal,
  getProductsInShoppingCart,
  (total, shoppingCartProducts) => {
    if (total) return total;

    return shoppingCartProducts.reduce(
      (total, nextRow) => total + Number(nextRow.rowVATTotal),
      0,
    );
  },
);

export const getTotalNet = createSelector(
  state => state.shoppingCart.computed.netTotal,
  getProductsInShoppingCart,
  (total, shoppingCartProducts) => {
    if (total) return total;

    return shoppingCartProducts.reduce(
      (total, nextRow) => total + Number(nextRow.rowNetTotal),
      0,
    );
  },
);

export function getNumberOfProductsInCart(state) {
  return state.shoppingCart.rows.length;
}

export function getTotalDiscount(state) {
  return getProductsInShoppingCart(state)
    .flatMap(({ rowNumber }) =>
      getProductDiscounts(rowNumber)(state).map(disc => disc.value),
    )
    .reduce((a, b) => a + b, 0);
}

export function getVisibleTotalDiscount(state) {
  return getProductsInShoppingCart(state)
    .flatMap(({ rowNumber }) =>
      getVisibleProductDiscounts(rowNumber)(state).map(disc => disc.value),
    )
    .reduce((a, b) => a + b, 0);
}

export function getRounding(state) {
  return state.shoppingCart.computed.rounding || 0;
}

export function getOpenedOrderIndex(state) {
  return state.shoppingCart.selectedOrder;
}

export function getProductInOrderByIndex(index) {
  return state =>
    getProductsInShoppingCart(state).find(
      ({ orderIndex }) => orderIndex === index,
    );
}

export function getSelectedOrder(state) {
  return getProductInOrderByIndex(getOpenedOrderIndex(state))(state);
}

/**
 * @typedef Discount
 * @property {String} type
 * @property {Number} affected
 * @abstract
 */

/**
 * @typedef ManualDiscount
 * @extends Discount
 * @property {"manual"} type
 * @property {Number} percentage
 */

/**
 * @typedef CampaignDiscount
 * @extends Discount
 * @property {"campaign"} type
 * @property {Number} value
 */
/**
 * @typedef PriceListDiscount
 * @extends Discount
 * @property {string} name
 * @property {"priceList"} type
 * @property {Number} value
 */

export const getPromoRulesProperties = createSelector(
  state => state.shoppingCart.rows,
  state => state.shoppingCart.calculateOffline,
  getConnectionHealth,
  (rows, shouldCalculateOffline, isOnline) => {
    const recalculatedAfterGoingOnline = isOnline && !shouldCalculateOffline;
    return R.mergeAll(
      rows
        .flatMap(row =>
          (row.container ?? []).concat(
            recalculatedAfterGoingOnline ? row.computed ?? [] : [],
          ),
        )
        .map(apiParams =>
          R.pickBy((v, k) => /^promotionRule(\d+)(\D+)(\d+)$/.test(k))(
            apiParams,
          ),
        ),
    );
  },
);

export function getReturnReasonByOrderIndex(index) {
  return state =>
    state.shoppingCart.returnReasons.find(item => item.orderIndex === index)
      ?.reason || getGlobalReturnReason(state);
}

export function getGlobalReturnReason(state) {
  return state.shoppingCart.returnReasons.find(item => !item.orderIndex)
    ?.reason;
}

export function getDiscountReasonByOrderIndex(index) {
  return state => state.shoppingCart.discountReasons[index]?.reason;
}

export function getGlobalDiscountReason(state) {
  return state.shoppingCart.discountReasons[0]?.reason;
}

export const getShoppingCartDiscountsDict = createSelector(
  state => state.shoppingCart.discountReasons,
  state => getShowPricesWithTax(state),
  state => state.shoppingCart.rows,
  state => getGlobalDiscountReason(state),
  (discountReasons, displayWithTax, rows, globalDiscountReason) => {
    const computedData = rows
      .flatMap(({ computed, container = [], user: { orderIndex } }) => [
        { ...computed, orderIndex },
        ...container,
      ])
      .filter(a => a);

    if (computedData === undefined) {
      return [];
    }
    const appliedDiscounts = {};
    computedData.forEach(row => {
      const rowSpecificDiscounts = [];
      const { rowNumber } = row;
      const rowDiscountReasonName =
        discountReasons[row.orderIndex]?.reason?.name;

      for (let i = 0; i <= 100; i += 1) {
        if (!row[`promotionRule${rowNumber}amount${i + 1}`]) {
          break;
        }
        const priceListId = row[`promotionRule${rowNumber}priceListID${i + 1}`];
        const campaignId = row[`promotionRule${rowNumber}campaignID${i + 1}`];
        const name = row[`promotionRule${rowNumber}name${i + 1}`];
        const affected = row[`promotionRule${rowNumber}amount${i + 1}`];
        const campaignValue =
          row[`promotionRule${rowNumber}campaignDiscountValue${i + 1}`];
        const priceListValue =
          row[`promotionRule${rowNumber}priceListDiscountValue${i + 1}`];
        const campaignPercentage =
          row[`promotionRule${rowNumber}campaignDiscountPercentage${i + 1}`];
        const priceListPercentage =
          row[`promotionRule${rowNumber}priceListDiscountPercentage${i + 1}`];
        const manualPercentage =
          row[`promotionRule${rowNumber}manualDiscountPercentage${i + 1}`];
        const type = campaignValue
          ? 'campaign'
          : priceListValue
          ? 'priceList'
          : 'manual';

        const discountPercentage =
          campaignPercentage || priceListPercentage || manualPercentage;
        const discountFraction = discountPercentage / 100;

        const value =
          discountPercentage === 0
            ? 0
            : row[`promotionRule${rowNumber}totalDiscount${i + 1}`];

        const vatRateFraction = Number(row.vatRate) / 100;

        const discount = value || discountFraction * computedData.priceWithVat;
        const compDiscount = Number.isNaN(discount) ? 0 : discount;

        const compNetDiscount = compDiscount / (1 + vatRateFraction);

        const discountToApply = {
          priceListId,
          campaignId,
          name:
            name ||
            globalDiscountReason?.name ||
            rowDiscountReasonName ||
            'Adjustment',
          affected,
          type,
          value: compDiscount,
          displayValue: displayWithTax ? compDiscount : compNetDiscount,
          percentage: discountPercentage,
        };
        rowSpecificDiscounts.push(discountToApply);
      }
      appliedDiscounts[rowNumber] = rowSpecificDiscounts;
    });

    return appliedDiscounts;
  },
);

export function getProductDiscounts(rowNumber) {
  return state => {
    if (!rowNumber) {
      return [];
    }
    const appliedDiscounts = getShoppingCartDiscountsDict(state)[rowNumber];
    if (!appliedDiscounts) {
      return [];
    }
    return appliedDiscounts;
  };
}

export function getHasOrderPromotionsApplied(rowNumber) {
  return state => {
    return getProductDiscounts(rowNumber)(state).some(
      discount => discount.campgainId,
    );
  };
}

export function getVisibleProductDiscounts(rowNumber) {
  return createSelector(
    getProductDiscounts(rowNumber),
    state => getSetting('pos_hide_price_list_from_discounts')(state),
    (discounts, hidePriceListDiscounts) =>
      hidePriceListDiscounts
        ? discounts.filter(d => !d.priceListId)
        : discounts,
  );
}

export function getHasAppliedDiscount(state) {
  const { percentage, amount } = state.shoppingCart.discountApplied;
  return !!(percentage || amount);
}

/** @returns {{amount?:number, percentage?: number} | boolean} */
export function getAppliedDiscount(state) {
  const { percentage, amount } = state.shoppingCart.discountApplied || {};
  return { percentage, amount };
}

function checkAllProductComponentsAvailability(product) {
  return state => {
    if (!product?.productComponents?.length) return true;
    return product?.productComponents.every(({ amount, componentID }) => {
      const component = getProductByID(componentID)(state);
      const stock = component?.free || 0;
      const totalInCart = getTotalCountInCart(component?.productID)(state);
      const orderAmount = product?.orderAmount * amount;
      return (
        component?.nonStockProduct === 1 ||
        totalInCart - orderAmount + Number(amount) * product?.amount <= stock
      );
    });
  };
}

export function checkAvailability(productID, amount = 1, orderIndex) {
  return state => {
    const product = getProductByID(productID)(state);
    const stock = product?.free || 0;
    const totalInCart = getTotalCountInCart(productID)(state);
    const orderAmount =
      getProductInOrderByIndex(orderIndex)(state)?.amount || 0;
    const componentsAvailable = checkAllProductComponentsAvailability({
      ...product,
      amount,
      totalInCart,
      orderAmount,
    })(state);
    return (
      product?.nonStockProduct === 1 ||
      totalInCart - orderAmount + Number(amount) <= stock ||
      // bundles don't have their own stock
      (product?.productComponents?.length && componentsAvailable)
    );
  };
}

export function checkStockFarFromMinimum(productID, amount = 1, orderIndex) {
  return state => {
    const shouldShowPopup = getSetting(
      'touchpos_close_to_minimum_stock_confirmation',
    )(state);
    if (!shouldShowPopup) return true;
    const product = getProductByID(productID)(state);
    const stock = product?.free || 0;
    const totalInCart = getTotalCountInCart(productID)(state);
    const orderAmount =
      getProductInOrderByIndex(orderIndex)(state)?.amount || 0;
    const reorderPoint = Number(product?.reorderPoint) || 0;
    const available = checkAvailability(productID, amount, orderIndex)(state);
    if (
      reorderPoint === 0 ||
      product?.nonStockProduct === 1 ||
      !available ||
      !shouldShowPopup
    )
      return true;
    return stock - reorderPoint > totalInCart - orderAmount + Number(amount);
  };
}

export function getCurrentSaleVatrate(state) {
  return state.shoppingCart.vatRate;
}
export function getHasCurrentSaleVatRate(state) {
  return !!getCurrentSaleVatrate(state);
}
export const getLastOrder = createSelector(
  getProductsInShoppingCart,
  prods => prods[prods.length - 1],
);

export function getSelectedOffersSelector(state) {
  return state.shoppingCart.selectedOffers;
}

export function getTotalCountInCart(productID) {
  return state => {
    const directAmount = getProductsInShoppingCart(state)
      .filter(p => p.productID === productID)
      .map(p => Number(p.amount))
      .reduce(add, 0);

    const amountInBundles = getProductsInShoppingCart(state)
      .map(p => {
        const product = getProductByID(p.productID)(state);
        const productInBundle = product?.productComponents?.find(
          pc => pc.componentID === productID,
        );
        return (productInBundle?.amount ?? 0) * p.amount;
      })
      .reduce(add, 0);

    return directAmount + amountInBundles;
  };
}

export function getTotalCountInCartByGroupID(groupID) {
  return state => {
    const productsInShC = getProductsInShoppingCart(state);
    const productCards = getCachedItemsPerTypeByIDs(
      SO.PRODUCTS.NAME,
      productsInShC.map(({ productID }) => productID),
    )(state);
    return productsInShC
      .map(p => ({ ...p, groupID: productCards[p.productID]?.groupID }))
      .filter(p => p.groupID === groupID)
      .map(p => Number(p.amount))
      .reduce(add, 0);
  };
}

export function getIsProductNonDiscountable(order) {
  return state => {
    const product = getProductByID(order.productID)(state) || {};
    const isNonDiscountableProductsEnabled = getIsNonDiscountableProductsEnabled(
      state,
    );
    const discountByAttribute =
      isNonDiscountableProductsEnabled &&
      Number(
        new ErplyAttributes(product.attributes).get(
          'notification_not_discountable',
        ),
      ) === 1;
    return (
      discountByAttribute ||
      !!product.nonDiscountable ||
      !!order.nonDiscountable ||
      !!product.isRegularGiftCard ||
      !!product.isGiftCard
    );
  };
}

export function getCartHasNonDiscountableProducts(state) {
  const cart = getProductsInShoppingCart(state);
  return cart.some(prod => getIsProductNonDiscountable(prod)(state));
}

export const getCartHasSerializedGiftCardProduct = createSelector(
  getProductsInShoppingCart,
  cart => cart.some(prod => prod?.giftCardSerial),
);

export const getHasOnlyNegativeRowAmounts = createSelector(
  state => state.shoppingCart.rows,
  rows => rows.length > 0 && rows.every(row => Number(row.computed.amount) < 0),
);

export function getIsOpenedOrderOnConfirmedDocument(order) {
  return state => {
    const { confirmed, rows = [] } = getCurrentSalesDocument(state);

    return (
      Number(confirmed) === 1 &&
      rows.some(row => row.stableRowID === order.stableRowID)
    );
  };
}

export function getIsOpenedOrderOnReferencedReturn(order) {
  return state => {
    const isOpenedOrderOnConfirmedDocument = getIsOpenedOrderOnConfirmedDocument(
      order,
    )(state);
    const total = getTotal(state);
    const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);
    const isAReturn = isCurrentSaleAReturn || total < 0;

    return isOpenedOrderOnConfirmedDocument && isAReturn;
  };
}

export function getCartHasReferencedReturnRow(state) {
  const cart = getProductsInShoppingCart(state);

  return cart.some(prod => getIsOpenedOrderOnReferencedReturn(prod)(state));
}

export const getShoppingCartProductIDs = createSelector(
  state => getCachedItemsPerType(SO.PRODUCTS.NAME)(state),
  state => state.shoppingCart.rows,
  (products, rows) => {
    return rows
      .map(row => products[row.user.productID])
      .filter(Boolean)
      .flatMap(product => [
        product.productID,
        product.relatedProducts,
        product.replacementProducts,
      ])
      .filter(Boolean)
      .map(Number);
  },
);

export const getHasRightToEditDocument = createSelector(
  state => getCurrentSalesDocument(state),
  state => getLoggedInEmployeeID(state),
  state => getRightToEditConfirmedOrders(state),
  (currentSale, loggedInEmployeeID, rightToEditConfirmedOrder) => {
    const { confirmed, type, id, employeeID } = currentSale;
    const isConfirmedAndIsPickedUpDocument = Number(confirmed) === 1 && id;
    if (type === 'ORDER') {
      return (
        !isConfirmedAndIsPickedUpDocument ||
        !(id && employeeID && Number(loggedInEmployeeID) === Number(employeeID)
          ? Number(rightToEditConfirmedOrder) < 1
          : Number(rightToEditConfirmedOrder) < 2)
      );
    }
    return true;
  },
);
