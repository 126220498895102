import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  TextField,
  Accordion,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useAsync } from 'react-use';

import { RootState } from 'reducers';
import {
  Configuration,
  Reader,
  StripeLocation,
} from 'paymentIntegrations/stripe/types';
import { getAllOnlineReaders } from 'paymentIntegrations/stripe/API';

import ReaderCreationSection from './ReaderCreationSection';
import ReaderElement from './ReaderElement';
import useAllStripeLocations from './hooks/useAllStripeLocations';

const Readers = ({
  configuration = {},
  changeReader,
}: {
  configuration: Configuration;
  changeReader: (reader: Reader) => void;
}) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { t } = useTranslation('settingsPayment', {
    keyPrefix: 'integrations.stripe',
  });

  const [locationFilter, setLocationFilter] = useState<StripeLocation | null>(
    null,
  );

  const { locations, error: locationsError } = useAllStripeLocations();

  const { value: readers = [], error } = useAsync(
    () => dispatch(getAllOnlineReaders({ location: locationFilter?.id })),
    [dispatch, locationFilter],
  );

  const errorMessage = (error ?? locationsError)?.message;

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h5">{t('titles.existingReaders')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {errorMessage ? (
            <Typography variant="h6" color="error">
              {errorMessage}
            </Typography>
          ) : (
            <Box>
              <Autocomplete
                options={locations}
                getOptionLabel={option => option.display_name}
                value={locationFilter}
                onChange={(_, newLocation) => {
                  setLocationFilter(newLocation);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    label={t('labels.filterByLocation')}
                  />
                )}
              />
              {readers.map(r => (
                <ReaderElement
                  key={r.id}
                  configuration={configuration}
                  reader={r}
                  changeReader={changeReader}
                />
              ))}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h5">{t('titles.createNew')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReaderCreationSection />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Readers;
