import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import { getAllWarehouses } from 'reducers/warehouses';
import Loader from 'components/Loader';
import { getProducts } from 'services/ErplyAPI';
import {
  getCurrencyFormatter,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { PluginComponent } from 'plugins';

const ProductInformation = ({ productItem }) => {
  const { t } = useTranslation('product');
  const format = useSelector(getCurrencyFormatter);

  const { value: override, error, loading } = useAsync(async () => {
    if (!productItem || override) return null;
    const response = await getProducts({
      productID: productItem.productID,
      from: 0,
      count: 1,
      getStockInfo: 1,
    });
    return response.records[0];
  }, [productItem]);

  const shouldUsePriceWithVAT = useSelector(getShowPricesWithTax);

  const warehouses = useSelector(getAllWarehouses);
  const displayStockByWarehouse = useCallback(
    (retailerWarehouses, productInWarehouse) => {
      return retailerWarehouses.map(warehouse => {
        const stock = productInWarehouse?.[`${warehouse.warehouseID}`];
        const asNumber = a => (a !== undefined ? Number(a) : '?');
        return (
          <tr data-testid="stock-row" key={`stockIn${warehouse.warehouseID}`}>
            <td data-testid="warehouse-name">{warehouse.name}</td>
            <td data-testid="total-stock">{asNumber(stock?.totalInStock)}</td>
            <td data-testid="reserver">{asNumber(stock?.reserved)}</td>
            <td data-testid="free">{asNumber(stock?.free)}</td>
          </tr>
        );
      });
    },
    [],
  );

  return (
    <div data-testid="product-info">
      <h4>{t('productView.title')}</h4>
      <Table className="mt-2">
        <tbody>
          <tr>
            <td>{t('relatedProducts.headers.name')}</td>
            <td data-testid="name">{productItem?.name}</td>
          </tr>
          <tr>
            <td>{t('relatedProducts.headers.code')}</td>
            <td data-testid="code">{productItem?.code}</td>
          </tr>
          {productItem?.code2 && (
            <tr>
              <td>{t('relatedProducts.headers.ean')}</td>
              <td data-testid="code2">{productItem?.code2}</td>
            </tr>
          )}
          <tr>
            <td>{t('relatedProducts.headers.price')}</td>
            <td data-testid="price">
              {format(
                shouldUsePriceWithVAT
                  ? productItem?.priceWithVat
                  : productItem?.price,
              )}
            </td>
          </tr>
          <tr>
            <td>{t('relatedProducts.headers.priceListPrice')}</td>
            <td data-testid="price-list-price">
              {format(
                shouldUsePriceWithVAT
                  ? productItem?.priceListPriceWithVat
                  : productItem?.priceListPrice,
              )}
            </td>
          </tr>
          {productItem?.groupName && (
            <tr>
              <td>{t('relatedProducts.headers.group')}</td>
              <td data-testid="group-name">{productItem?.groupName}</td>
            </tr>
          )}
          {productItem?.categoryName && (
            <tr>
              <td>{t('relatedProducts.headers.category')}</td>
              <td data-testid="category">{productItem?.categoryName}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <PluginComponent hookname="UIStockDataTable">
        <h4>{t('relatedProducts.headers.stockInfo.title')}</h4>
        {!error ? (
          <Loader show={loading} block>
            <Table>
              <thead>
                <tr>
                  <th>{t('relatedProducts.headers.stockInfo.warehouse')}</th>
                  <th>{t('relatedProducts.headers.stockInfo.total')}</th>
                  <th>{t('relatedProducts.headers.stockInfo.reserved')}</th>
                  <th>{t('relatedProducts.headers.stockInfo.free')}</th>
                </tr>
              </thead>
              <tbody>
                {displayStockByWarehouse(warehouses, override?.warehouses)}
              </tbody>
            </Table>
          </Loader>
        ) : (
          <h5>{t('relatedProducts.stockInfo.failedToFetch')}</h5>
        )}
      </PluginComponent>
    </div>
  );
};

export default ProductInformation;
