/* eslint-disable no-nested-ternary */
import i18next from 'i18next';
import debug from 'debug';

import * as actionTypes from 'constants/Payments';
import {
  getPosRoundCashFunction,
  getCurrencyCode,
  getSetting,
} from 'reducers/configs/settings';
import { round, timestampInSeconds } from 'utils';
import {
  getPayments,
  getIsReturnPayment,
  getCustomer,
  getBalance,
} from 'reducers/Payments';
import { getPaymentLimits } from 'reducers/PaymentLimits';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { Payment } from 'types/Payment';
import { addWarning } from 'actions/Error';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';

function noEmpty(payments: (Payment & { amount: string | number })[]) {
  return Object.fromEntries(
    Object.entries(payments).filter(([, { amount }]) => Number(amount) !== 0),
  );
}

export function setPayment(payload) {
  return async (dispatch, getState) => {
    const {
      key,
      amount = undefined,
      currencyCode = undefined,
      serial = null,
      vatrateID = null,
      giftCardID = undefined,
      typeID = undefined,
      added = timestampInSeconds(),
      giftCardBalance = null,
      cardHolder = null,
      forceAmount = false,
      ignoreCurrentAttributes = false,
      ...rest
    } = payload;
    const { type } = rest;
    /* Payment types recongnizible by ERPLY: CASH, TRANSFER, CARD, CREDIT, GIFTCARD, CHECK, TIP */
    const state = getState();

    const { before, on, after } = getPluginLifecycleHook('onSetPayment')(state);
    await dispatch(before(payload));

    const oldPayments: (Payment & { amount: string | number })[] = getPayments(
      state,
    );
    const { [key]: value, ...restPayments } = oldPayments;
    const paidInGivenType = Object.values(restPayments)
      ?.filter(op => type === op.type && op.key !== key)
      ?.reduce((a, b) => a + Number(b?.amount || 0), 0);

    const { amount: tenderLimit } = getPaymentLimits(state).find(limit => {
      return (
        limit.type === type &&
        !!limit.serial === !!serial &&
        Number.isFinite(Number(limit.amount))
      );
    }) || { amount: Infinity };

    const remainingLimit =
      (tenderLimit ?? Infinity) - Math.abs(paidInGivenType);
    if (remainingLimit <= 0) {
      dispatch(
        addWarning(
          i18next.t('alerts:payments.tenderLimit', {
            tender: type.toLowerCase(),
          }),
        ),
      );
      return dispatch(setPaymentSelected(''));
    }
    const isReturnPayment = getIsReturnPayment(state);
    dispatch(setPaymentSelected(key));

    const oldPayment = oldPayments[key] || { amount: '0.00' };

    const withOriginalPaymentIDAttribute = ignoreCurrentAttributes
      ? {}
      : isReturnPayment
      ? { originalPaymentID: -1, ...oldPayment.attributes }
      : oldPayment.attributes;

    const newPayments = {
      ...noEmpty(oldPayments),
      [key]: {
        ...oldPayment,
        key,
        amount: Math.abs(Number(amount)),
        giftCardID,
        typeID,
        cardHolder,
        added,
        currencyCode: currencyCode || getCurrencyCode(state),
        ...rest,
        attributes: {
          ...withOriginalPaymentIDAttribute,
          ...rest.attributes,
        },
      },
    };
    const newPayment = newPayments[key];

    /*
     * Apply tender limit
     */
    if (Number(newPayment.amount) > Number(remainingLimit)) {
      dispatch(
        addWarning(
          i18next.t('alerts:payments.tenderLimit', {
            tender: type.toLowerCase(),
          }),
        ),
      );
      newPayment.amount = Number(remainingLimit);
    }
    /*
     * Apply storecredit limit
     */
    if (type === 'STORECREDIT') {
      const { availableCredit } = getCustomer(state);
      if (
        !isReturnPayment &&
        Number(newPayment.amount) > Number(availableCredit)
      ) {
        dispatch(addWarning(i18next.t('alerts:payments.storeCreditLimit')));
        newPayment.amount = Number(availableCredit);
      }
    }
    /*
     * Giftcard-specific fields
     */
    const gcattributes = {
      ...(giftCardID && { giftCardID }),
      ...(typeID && { typeID }),
    };
    if (type === 'GIFTCARD') {
      Object.assign(newPayment, {
        ...(serial && { serial }),
        vatrateID,
        ...(giftCardBalance && { giftCardBalance }),
        cardHolder,
        currencyCode,
        attributes: gcattributes,
      });
      if (
        giftCardBalance &&
        Number(newPayment.amount) > Number(giftCardBalance)
      ) {
        dispatch(
          addWarning(
            i18next.t(
              'payment:serialGiftcard.fields.paymentAmount.errors.exceedsGiftCardBalance',
            ),
          ),
        );
        newPayment.amount = Number(giftCardBalance);
      }
    }
    const shouldRound = !!getSetting('pos_round_cash_to_nearest_x')(getState());
    const roundCash = getPosRoundCashFunction(getState());

    /*
     * Not allowed to exceed balance with these tenders
     */
    const cardOrStoreCredit = ['CARD', 'STORECREDIT'].includes(type);
    const cashOnReturn = type === 'CASH' && isReturnPayment;
    const isCustomPayment = ![
      'TIP',
      'GIFTCARD',
      'CHANGE',
      'CASH',
      'CARD',
      'STORECREDIT',
    ].includes(type);
    const balance = getBalance(getState());
    const paymentAmountExceedsTotal =
      roundCash(balance, undefined) - Number(amount) <= 0;
    if (
      cardOrStoreCredit ||
      // Block overpayment by cash in case of return - customer wouldn't be the one giving out the change
      (cashOnReturn && (!shouldRound || paymentAmountExceedsTotal)) ||
      // The row below is the solution for custom payments (which can't be overpaid)
      isCustomPayment
    ) {
      // Rounding only applies to cash
      const balanceOrRoundedBalance = cashOnReturn
        ? roundCash(balance, undefined)
        : balance;
      const remaining =
        Math.abs(balanceOrRoundedBalance) + Math.abs(Number(oldPayment.amount));
      newPayment.amount = Math.min(newPayment.amount, remaining);
    }

    /*
     * Negative if return
     */
    if (isReturnPayment) {
      newPayment.amount *= -1;
    }
    /*
     * Invert if tip or change
     */
    if (newPayment.type === 'TIP' || newPayment.type === 'CHANGE') {
      newPayment.amount *= -1;
    }
    /* Apply cash rounding if applicable */
    if (newPayment.type === 'CASH') {
      newPayment.amount = getPosRoundCashFunction(getState())(
        newPayment.amount,
        undefined,
      );
    }
    /* Limit to two decimal places */
    newPayment.amount = round(newPayment.amount);

    // When called with force, ignore previously applied limits and just set all the values directly
    // TODO: TEST, REVIEW, REFACTOR
    if (forceAmount) {
      Object.assign(newPayment, {
        key,
        amount,
        currencyCode,
        serial,
        vatrateID,
        giftCardID,
        typeID,
        added,
        giftCardBalance,
        cardHolder,
        ...rest,
        attributes: {
          ...withOriginalPaymentIDAttribute,
          ...rest.attributes,
        },
      });
    }

    try {
      await dispatch({
        type: actionTypes.SET_PAYMENT,
        payload: await dispatch(on(payload, newPayment)),
      });

      dispatch(after(payload, undefined));
      return newPayment;
    } catch (error) {
      // Cancelled from plugin
      const warn = debug('setPayment');
      warn.log = console.warn.bind(console);
      warn(error);
    }
  };
}
