import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { addError, addWarning } from 'actions/Error';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import { CAFA_LEVELS, INTEGRATIONS, INTEGRATION_TYPES } from 'constants/CAFA';
import { Tabs } from 'components/Tabs';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import {
  Section,
  collapseAllSections,
} from 'containers/Forms/Settings/components/Inputs';
import { getSelectedPos } from 'reducers/PointsOfSale';
import {
  getApplicableAllowedTendersConfig,
  getIsLoadingCafa,
  sortLevels,
} from 'reducers/cafaConfigs';
import { useAppDispatch } from 'reducers';

import TenderConfig from './TenderConfig';
import { returnTenders, salesTenders } from './constants';
import { AllowedTenderConfiguration, ConfigLevel } from './types';

interface Props {
  onClose(): void;
}

function AllowedTenders({ onClose }: Props) {
  const { t } = useTranslation('settingsPayment');
  const dispatch = useAppDispatch();

  const isLoadingCafa = useSelector(getIsLoadingCafa);
  const { pointOfSaleID, warehouseID } = useSelector(getSelectedPos);
  const savedConfig = useSelector(getApplicableAllowedTendersConfig);

  const [isSaving, setIsSaving] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState<ConfigLevel>(
    CAFA_LEVELS.Company,
  );
  const [configByLevel, setConfigByLevel] = useState<
    Record<ConfigLevel, AllowedTenderConfiguration>
  >(savedConfig);

  // Sync saved data to local state
  useEffect(() => {
    setConfigByLevel(savedConfig);
  }, [savedConfig]);

  // Autoselect the currently active level
  useEffect(() => {
    const lowestEnabledLevel = Object.values(CAFA_LEVELS)
      .filter(level => savedConfig[level]?.enabled)
      .sort(sortLevels(true))[0];
    setSelectedLevel(lowestEnabledLevel as ConfigLevel);
  }, [savedConfig]);

  const alertIfAllTendersAreDisabled = useCallback(
    (documentType: string) => (
      config: Record<typeof selectedLevel, AllowedTenderConfiguration>,
    ) => {
      const allTendersDisabled = R.pipe(
        R.values,
        R.none(R.prop('allowed')),
      )(config[selectedLevel].config[documentType]);

      if (!allTendersDisabled) return;

      dispatch(
        addWarning(
          t('allowedTenders.alerts.allTendersDisabled', {
            context: documentType,
          }),
        ),
      );
    },
    [dispatch, selectedLevel, t],
  );

  const handleChange = useCallback(
    (documentType: string) => (
      tender: string,
      fieldName: string,
      fieldValue: string | boolean,
    ) => {
      const changeHandlers = {
        allowed: (value: boolean) =>
          R.pipe(
            R.assoc('allowed', value),
            R.when(R.always(!value), R.assoc('limit', '')),
          ),
        limit: (value: string) =>
          R.assoc(
            'limit',
            !Number.isNaN(Number(value)) && Number(value) > 0 ? value : '',
          ),
      };
      setConfigByLevel(
        R.pipe(
          R.over(
            R.lensPath([selectedLevel, 'config', documentType, tender]),
            changeHandlers[fieldName]?.(fieldValue),
          ),
          R.tap(alertIfAllTendersAreDisabled(documentType)),
        ),
      );
    },
    [alertIfAllTendersAreDisabled, selectedLevel],
  );

  const save = useCallback(async () => {
    setIsSaving(true);
    const integrationType = INTEGRATION_TYPES.posConfigurations;
    const integrationName = INTEGRATIONS[integrationType].allowedTenders;
    const levelsToSave = [
      { level: CAFA_LEVELS.Company, id: '' },
      { level: CAFA_LEVELS.Warehouse, id: warehouseID },
      { level: CAFA_LEVELS.Pos, id: pointOfSaleID },
    ] as const;
    try {
      const promises = levelsToSave.map(({ level, id }) => {
        if (R.equals(savedConfig[level], configByLevel[level]))
          return Promise.resolve();
        return dispatch(
          saveCafaConfig({
            integrationName,
            integrationType,
            config: configByLevel[level],
            level: { level, id },
          }),
        );
      });
      await Promise.all(promises);
      onClose();
    } catch (error) {
      dispatch(addError(t('allowedTenders.alerts.failedToSave')));
    } finally {
      setIsSaving(false);
    }
  }, [
    warehouseID,
    pointOfSaleID,
    onClose,
    savedConfig,
    configByLevel,
    dispatch,
    t,
  ]);

  return (
    <div data-testid="allowed-tenders">
      <Box display="flex" alignItems="center" padding="1rem">
        <Typography variant="h5">{t('allowedTenders.title')}</Typography>
        <Box flexGrow={1} />
        <SaveButton
          data-testid="save-allowed-tenders-btn"
          action={save}
          disabled={isLoadingCafa || isSaving}
          loading={isSaving}
        />
        <CloseButton action={onClose} />
      </Box>
      <Divider />
      <Box padding="1rem">
        {isLoadingCafa ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            padding="1rem"
          >
            <CircularProgress />
            <Typography>{t('allowedTenders.loading')}</Typography>
          </Box>
        ) : (
          <>
            <Tabs
              value={selectedLevel}
              onSelect={value => setSelectedLevel(value)}
              data-testid="allowed-tenders-tabs"
            >
              <Tab
                value={CAFA_LEVELS.Company}
                label="Company"
                data-test-key={CAFA_LEVELS.Company}
              />
              <Tab
                value={CAFA_LEVELS.Warehouse}
                label="Warehouse"
                data-test-key={CAFA_LEVELS.Warehouse}
              />
              <Tab
                value={CAFA_LEVELS.Pos}
                label="POS"
                data-test-key={CAFA_LEVELS.Pos}
              />
            </Tabs>
            <div>
              <Box
                marginTop="1rem"
                display="flex"
                justifyContent="space-between"
              >
                <FormControlLabel
                  style={{
                    visibility:
                      selectedLevel !== CAFA_LEVELS.Company
                        ? 'visible'
                        : 'hidden',
                  }}
                  label={t('allowedTenders.enableConfigLevel')}
                  control={
                    <Switch
                      checked={configByLevel[selectedLevel].enabled}
                      onChange={e =>
                        setConfigByLevel(
                          R.assocPath(
                            [selectedLevel, 'enabled'],
                            e.target.checked,
                          ),
                        )
                      }
                    />
                  }
                />
                <Button variant="text" onClick={collapseAllSections}>
                  {t('settings:buttons.collapseAllSections')}
                </Button>
              </Box>
              <Section title={t('sale.title')}>
                <TenderConfig
                  translationPrefix="sale"
                  tenders={salesTenders}
                  configuration={configByLevel[selectedLevel].config.sale}
                  onChange={handleChange('sale')}
                  disabled={!configByLevel[selectedLevel].enabled}
                />
              </Section>

              <Section title={t('returnWithReceipt.title')}>
                <FormControlLabel
                  label={t('returnWithReceipt.originalTenderOnly')}
                  control={
                    <Checkbox
                      checked={
                        configByLevel[selectedLevel].config
                          .allowOnlyOriginalTendersOnReturnWithReceipt
                      }
                      onChange={e =>
                        setConfigByLevel(
                          R.assocPath(
                            [
                              selectedLevel,
                              'config',
                              'allowOnlyOriginalTendersOnReturnWithReceipt',
                            ],
                            e.target.checked,
                          ),
                        )
                      }
                    />
                  }
                  data-testid="ctxinput-checkbox"
                  data-test-key="pos_allow_return_receipt_original_tender_only"
                />
                <TenderConfig
                  translationPrefix="returnWithReceipt"
                  tenders={returnTenders}
                  configuration={
                    configByLevel[selectedLevel].config.returnWithReceipt
                  }
                  onChange={handleChange('returnWithReceipt')}
                  disabled={
                    configByLevel[selectedLevel].config
                      .allowOnlyOriginalTendersOnReturnWithReceipt ||
                    !configByLevel[selectedLevel].enabled
                  }
                />
              </Section>

              <Section title={t('returnWithoutReceipt.title')}>
                <TenderConfig
                  translationPrefix="returnWithoutReceipt"
                  tenders={returnTenders}
                  configuration={configByLevel[selectedLevel].config.return}
                  onChange={handleChange('return')}
                  disabled={!configByLevel[selectedLevel].enabled}
                />
              </Section>
            </div>
          </>
        )}
      </Box>
    </div>
  );
}

export default AllowedTenders;
