import { PosPlugin } from 'plugins/plugin';
import { ComponentHeader } from 'plugins/cayanCustomerDisplay/ComponentHeader';

const pluginID = 'cayanCustomerDisplay';

const cayanCustomerDisplayPlugin: PosPlugin = {
  id: pluginID,
  name: 'Cayan/TSYS/Genius terminal customer display',
  ComponentHeader,
};

export default cayanCustomerDisplayPlugin;
