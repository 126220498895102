import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import {
  getActivePriceListsByIDsAsync,
  ProductRequestParams,
} from 'actions/productsDB';
import { Product } from 'types/Product';
import { getActivePricelistIDsForPOS } from 'reducers/cachedItems/priceLists';
import { getClientCode } from 'reducers/Login';
import { getAllWarehouses, getSelectedWarehouseID } from 'reducers/warehouses';
import { getQuickSelectProductIDs } from 'reducers/PointsOfSale';

import { SO, ValueOf } from './types';
import getDB from './getDB';

import { getItemsFromSO } from './index';

// region indexedDB helpers
const getProductDBTransaction = async (clientCode: string) => {
  return (await getDB(clientCode)).transaction<typeof SO.PRODUCTS.NAME>(
    SO.PRODUCTS.NAME,
  );
};

const getProductDBIndex = async (
  clientCode: string,
  INDEX: ValueOf<typeof SO.PRODUCTS.INDEXES>['indexName'],
) => {
  return (await getProductDBTransaction(clientCode)).store.index(INDEX);
};
// endregion

// region Helper functions
export const combineStatuses = (a, b) => {
  const toPerm = {
    ACTIVE: 0b11,
    NOT_FOR_SALE: 0b01,
    NO_LONGER_ORDERED: 0b10,
    ARCHIVED: 0b00,
    // check for active and no_longer_ordered
  };
  const fromPerm = R.invertObj(toPerm);
  // eslint-disable-next-line no-bitwise
  return fromPerm[toPerm[a] & toPerm[b]];
};

const combineProductAndAssortmentStatuses = (products: Product[]) => async (
  _dispatch,
  getState,
) => {
  const clientCode = getClientCode(getState());
  const warehouseID = getSelectedWarehouseID(getState());
  const allWarehouses = getAllWarehouses(getState());

  const { assortmentID = 0 } =
    allWarehouses?.find(wh => String(wh.warehouseID) === String(warehouseID)) ??
    {};

  const assortmentProducts = await (await getDB(clientCode))
    .transaction(SO.ASSORTMENTS.NAME)
    .store.index(SO.ASSORTMENTS.INDEXES.ASSORTMENT_ID.indexName)
    .getAll(assortmentID);

  let result = products;
  if (assortmentProducts.length) {
    result = products.map(product => {
      const assortmentStatus =
        assortmentProducts.find(ap => product.productID === ap.productID)
          ?.status ?? 'ARCHIVED';

      return R.assoc(
        'status',
        combineStatuses(assortmentStatus, product.status),
        product,
      );
    });
  }
  return result;
};

/**
 * Checks if option sell only form price list 1 is enabled and filters accordingly
 */
export const applyArchivedStatusForNonPriceListProducts = (
  products: Product[],
  parameters: ProductRequestParams,
) => async (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
  getState,
): Promise<Product[]> => {
  if (!parameters.getItemsFromFirstPriceListOnly) return products;

  const activePriceListIDs = getActivePricelistIDsForPOS(getState());
  const priceListIDs = [...new Set(activePriceListIDs.map(id => String(id)))];

  const priceListRules = await dispatch(
    getActivePriceListsByIDsAsync(priceListIDs),
  );

  const productAndServiceRules = Object.fromEntries(
    priceListRules
      .filter(r => r.type === 'PRODUCT' || r.type === 'SERVICE')
      .map(r => [r.id, r.price]),
  );

  if (!priceListRules.length) return products;

  const productIsInPriceList = (product: Product) =>
    !!productAndServiceRules[product.productID];
  const productHasVariationInPriceList = (product: Product) =>
    product.productVariations?.some(pid => !!productAndServiceRules[pid]);

  return R.map(
    R.when(
      // products which are not in the pricelist nor have a variation in it
      R.complement(
        R.either(productIsInPriceList, productHasVariationInPriceList),
      ),
      // are to be treated as archived
      R.assoc('status', 'ARCHIVED'),
    ),
  )(products);
};

/**
 * A filter to retrieve only sellable products, or only products that can be reordered from supplier.
 */
export const filterOutArchivedProducts = (
  products: Product[],
  parameters: ProductRequestParams,
) => {
  const getProductsForFilters = {
    SALES: ({ status }) => ['ACTIVE', 'NO_LONGER_ORDERED'].includes(status),
    ORDERING: ({ status }) => ['ACTIVE', 'NOT_FOR_SALE'].includes(status),
  };
  return parameters.getProductsFor || parameters.getItemsFromFirstPriceListOnly
    ? products.filter(
        getProductsForFilters[parameters?.getProductsFor ?? 'SALES'],
      )
    : products;
};

// endregion

// region Search helpers
export const searchProductByName = (
  parameters: ProductRequestParams,
  exact = false,
) => async (dispatch: ThunkDispatch<unknown, unknown, Action>, getState) => {
  const clientCode = getClientCode(getState());
  const query =
    parameters.name ??
    parameters.searchNameIncrementally ??
    parameters.fullTextSearchPhrase ??
    '';
  const limit = parameters.recordsOnPage ?? 1000;

  const keyRange = exact
    ? window.IDBKeyRange.only(query.toUpperCase())
    : window.IDBKeyRange.bound(
        query.toUpperCase(),
        `${query.toUpperCase()}uffff`,
        false,
        false,
      );
  const index = await getProductDBIndex(
    clientCode,
    SO.PRODUCTS.INDEXES.NAME.indexName,
  );

  const total = await index.count(keyRange);
  let products = await index.getAll(keyRange, limit);

  products = await dispatch(combineProductAndAssortmentStatuses(products));

  products = await dispatch(
    applyArchivedStatusForNonPriceListProducts(products, parameters),
  );

  products = filterOutArchivedProducts(products, parameters);

  return { products, total };
};

export const searchProductByCode = (
  parameters: ProductRequestParams,
  exact = false,
) => async (dispatch: ThunkDispatch<unknown, unknown, Action>, getState) => {
  const clientCode = getClientCode(getState());
  const query =
    parameters.code ??
    parameters.code2 ??
    parameters.code3 ??
    parameters.supplierCode ??
    parameters.code5 ??
    parameters.code6 ??
    parameters.code7 ??
    parameters.code8 ??
    parameters.searchNameIncrementally ??
    parameters.fullTextSearchPhrase ??
    '';
  const limit = parameters.recordsOnPage ?? 1000;

  const keyRange = exact
    ? window.IDBKeyRange.only(query)
    : window.IDBKeyRange.bound(query, `${query}uffff`, false, false);

  const index = await getProductDBIndex(
    clientCode,
    SO.PRODUCTS.INDEXES.CODE.indexName,
  );

  const total = await index.count(keyRange);
  let products = await index.getAll(keyRange, limit);

  products = await dispatch(combineProductAndAssortmentStatuses(products));

  products = await dispatch(
    applyArchivedStatusForNonPriceListProducts(products, parameters),
  );

  products = filterOutArchivedProducts(products, parameters);
  return { products, total };
};

export const searchProductsByNameOrCode = (
  parameters: ProductRequestParams,
) => async (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
): Promise<{ products: Product[]; total: number }> => {
  const byNamePerWord = dispatch(searchProductByName(parameters));
  const byCode = dispatch(searchProductByCode(parameters));
  const byFullName = dispatch(searchProductByName(parameters, true));

  const { products } = await Promise.all([byNamePerWord, byCode, byFullName])
    .then(data =>
      data.reduce(
        (a, b) => {
          const temp: any = {};
          temp.products = [...a.products, ...b.products];
          temp.total = a.total + b.total;
          return temp;
        },
        { products: [], total: 0 },
      ),
    )
    .catch(err => {
      console.error(
        'Failed to perform aggregate search for product by',
        parameters,
        err,
      );
      return { products: [], total: 0 };
    });

  const uniqueProducts = R.uniq(products);
  return {
    products: uniqueProducts.slice(0, parameters.recordsOnPage ?? 1000),
    total: uniqueProducts.length,
  };
};

export const searchProductsByGroupID = (
  parameters: ProductRequestParams,
) => async (dispatch, getState) => {
  const clientCode = getClientCode(getState());
  const { groupID = 0, recordsOnPage = 100, pageNo = 1 } = parameters;

  let indexName;
  if (parameters.orderBy === 'name') {
    indexName = 'groupAndName';
  } else if (parameters.orderBy === 'code') {
    indexName = 'groupAndCode';
  } else {
    indexName = parameters.orderBy;
  }

  const index = await getProductDBIndex(clientCode, indexName);

  let products: Product[] = await index.getAll(
    window.IDBKeyRange.bound([groupID], [groupID, []]),
  );

  if (parameters.orderBy && parameters.orderByDir === 'desc') {
    products = products.reverse();
  }

  products = await dispatch(combineProductAndAssortmentStatuses(products));

  products = await dispatch(
    applyArchivedStatusForNonPriceListProducts(products, parameters),
  );

  products = filterOutArchivedProducts(products, parameters);

  // Removes matrix products
  if (!parameters.includeMatrixVariations) {
    const filterMatrixVariations = (pr: Product) => !pr.parentProductID;
    products = products.filter(filterMatrixVariations);
  }

  return {
    products: products.slice(
      (pageNo - 1) * recordsOnPage,
      pageNo * recordsOnPage,
    ),
    total: products.length,
  };
};

export const searchProductsByProductIDs = (
  parameters: ProductRequestParams,
) => async (dispatch, getState) => {
  const clientCode = getClientCode(getState());
  const { productIDs, productID } = parameters;

  let products = await getItemsFromSO<Product>(
    clientCode,
    SO.PRODUCTS.NAME,
    productID ? [productID] : productIDs,
  );

  products = await dispatch(combineProductAndAssortmentStatuses(products));

  products = await dispatch(
    applyArchivedStatusForNonPriceListProducts(products, parameters),
  );

  products = filterOutArchivedProducts(products, parameters);

  return { products, total: products.length };
};

export const searchQuickSelectProducts = (
  parameters: ProductRequestParams,
) => async (dispatch: ThunkDispatch<unknown, unknown, Action>, getState) => {
  const quickSelectProductIDs = getQuickSelectProductIDs(getState());

  if (!quickSelectProductIDs.length) return { products: [], total: 0 };

  const { pageNo = 1, recordsOnPage = 0 } = parameters;

  const getIDSlice = R.pipe(
    R.when(
      () => R.is(Number, pageNo) && pageNo > 0 && R.is(Number, recordsOnPage),
      R.pipe(R.drop((pageNo - 1) * recordsOnPage), R.take(recordsOnPage)),
    ),
  );

  const replaceQuickProdWithIDs = R.pipe(
    R.dissoc('quickPosProducts'),
    R.assoc('productIDs', getIDSlice(quickSelectProductIDs)),
  );
  return dispatch(
    // @ts-ignore
    searchProductsByProductIDs(replaceQuickProdWithIDs(parameters)),
  );
};

// endregion

type ProductSearchOption = {
  priceListOneOnly?: boolean;
  exact?: boolean;
};

// region General Search function
export const searchProductsDB = (
  parameters: ProductRequestParams,
  options: ProductSearchOption = {},
) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  const { exact = false } = options;

  switch (true) {
    case Object.hasOwnProperty.call(parameters, 'code'):
    case Object.hasOwnProperty.call(parameters, 'code2'):
    case Object.hasOwnProperty.call(parameters, 'code3'):
    case Object.hasOwnProperty.call(parameters, 'code5'):
    case Object.hasOwnProperty.call(parameters, 'code6'):
    case Object.hasOwnProperty.call(parameters, 'code7'):
    case Object.hasOwnProperty.call(parameters, 'code8'):
    case Object.hasOwnProperty.call(parameters, 'supplierCode'):
      return dispatch(searchProductByCode(parameters, exact));
    case Object.hasOwnProperty.call(parameters, 'productID'):
    case Object.hasOwnProperty.call(parameters, 'productIDs'):
      return dispatch(searchProductsByProductIDs(parameters));
    case Object.hasOwnProperty.call(parameters, 'name'):
      return dispatch(searchProductByName(parameters, exact));
    case Object.hasOwnProperty.call(parameters, 'groupID'):
      return dispatch(searchProductsByGroupID(parameters));
    case Object.hasOwnProperty.call(parameters, 'quickPosProducts'):
      return dispatch(searchQuickSelectProducts(parameters));
    case Object.hasOwnProperty.call(parameters, 'searchNameIncrementally'):
    default:
      return dispatch(searchProductsByNameOrCode(parameters));
  }
};
// endregion
