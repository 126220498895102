/* eslint comma-dangle: ["error", "always-multiline"] */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getPluginHooksByName } from 'reducers/Plugins';
import { SignatureCapturePlugin } from 'plugins/signatureCapture/SignatureCapturePlugin';
import { oneAcrePergamonFiscalPlugin } from 'plugins/oneAcrePergamonFiscal';
import { SuretaxPlugin } from 'plugins/suretax';
import oneAcreMPesa from 'plugins/oneacre';
import { OnOffWarrantyPlugin } from 'plugins/onoff/warrantyPlugin';
import { MetricsCollectorPlugin } from 'plugins/metricsCollector';

import customerDisplayPlugin from './customerDisplay';
import { zamzowLawncarePlugin } from './zamzow';
import actualReportsPlugin from './actualReports';
import customTranslationsPlugin from './customTranslations/customTranslations';
import { noDiscountStackingPlugin } from './noDiscountStacking/noDiscountStackingPlugin';
import cayanCustomerDisplayPlugin from './cayanCustomerDisplay/cayanCustomerDisplayPlugin';
import victorinoxCustomizationsPlugin from './victorinoxCustomizations';
import { LotteryProductPlugin } from './lotteryProduct/lotteryProduct';
import { TaxProductsPlugin } from './taxProducts/TaxProductsPlugin';
import { CancellationFeePlugin } from './cancellationFee/cancellationFeePlugin';
import { traditionalPlugin } from './traditionalPlugin';
import { CustomComponent, HookOfType, PosPlugin } from './plugin';
import lastChangePlugin from './lastChange';
import todoPlugin from './todoPlugin';
import * as pnpPlugins from './pnp';
import * as tafPlugins from './taf';
import givexPlugin from './givexHeartland';
import wbuPlugin from './wbu';
import customButtonsPlugin from './customButtons';
import pnpCoreChargeNoTax from './coreCharge';
import SMSTelerivet from './smsTelerivet';
import CFR from './crossFranchiseReturn';
import removeCoreChangeWhenCustomerHasPriceList from './removeCoreChangeWhenCustomerHasPricelist';
import duplicateCustomerWarning from './duplicateCustomerWarning';
import fiscalIntegration from './fiscalIntegration';
import hideGlobalAlerts from './hideGlobalAlerts';
import tsteCustomizations from './tste';
import garminCustomizations from './garmin';
import SecurityPlugin from './security';
import estonianBusinessRegistry from './estonianBusinessRegistry';
import CurrencyConeverterPlugin from './currencyConverter';
import inventoryWriteOff from './inventoryWriteOff';
import iDealPlugin from './ideal';
import defaultGroups from './defaultGroups';
import orderAssignment from './orderAssignment';
import EFSTAFiscalIntegration from './EFSTAFiscalIntegration';
import rowTotalColumn from './rowTotal';
import hidePaymentCurrency from './hidePaymentCurrency';
import alternativeProductButtonText from './alternativeProductButtonText';
import customerSearchAutoFocus from './customerSearchAutofocus';
import serviceApp from './serviceApp';
import USDLAgeVerification from './USDLAgeVerification';
import customerFormStateAutocompletePlugin from './customerFormStateAutocomplete';
import hideStockDataTable from './hideStockData';
import stickyProductGroups from './stickyProductGroups';
import customerFormFeedbackSection from './customerFormFeedbackSection';
import giftCardPrinting from './giftCardPrinting';
import lancasterPlugin from './lancaster';
import taxFreeForm from './taxFreeForm';
import externalPaymentMoMo from './externalPaymentMoMo';
import hammacherGiftCardRefundPlugin from './hammacherGiftCardRefund';
import giftCardRefundPlugin from './giftCardRefund';
import netsuitePlugin from './netsuite';
import mmPlugin from './mm';
import hipPocketPlugin from './hipPocket';
import ViiGiftCards from './viiGiftCard';
import saiNumberCapture from './saiNumberCapture';
import { NohblePlugin } from './nohble';

// prettier-ignore
const plugins: PosPlugin[] = [
  duplicateCustomerWarning,
  lastChangePlugin,
  todoPlugin,
  hideGlobalAlerts,
  ...Object.values(pnpPlugins),
  ...Object.values(tafPlugins),
  customButtonsPlugin,
  pnpCoreChargeNoTax,
  removeCoreChangeWhenCustomerHasPriceList,
  SMSTelerivet,
  customerDisplayPlugin,
  zamzowLawncarePlugin,
  traditionalPlugin,
  actualReportsPlugin,
  CFR,
  hideStockDataTable,
  customTranslationsPlugin,
  noDiscountStackingPlugin,
  cayanCustomerDisplayPlugin,
  victorinoxCustomizationsPlugin,
  hidePaymentCurrency,
  alternativeProductButtonText,
  wbuPlugin,
  givexPlugin,
  fiscalIntegration,
  tsteCustomizations,
  garminCustomizations,
  SecurityPlugin,
  estonianBusinessRegistry,
  iDealPlugin,
  CurrencyConeverterPlugin,
  inventoryWriteOff,
  defaultGroups,
  orderAssignment,
  EFSTAFiscalIntegration,
  rowTotalColumn,
  customerSearchAutoFocus,
  serviceApp,
  LotteryProductPlugin,
  customerFormStateAutocompletePlugin,
  USDLAgeVerification,
  TaxProductsPlugin,
  stickyProductGroups,
  CancellationFeePlugin,
  SignatureCapturePlugin,
  customerFormFeedbackSection,
  netsuitePlugin,
  oneAcrePergamonFiscalPlugin,
  taxFreeForm,
  externalPaymentMoMo,
  lancasterPlugin,
  giftCardPrinting,
  SuretaxPlugin,
  oneAcreMPesa,
  hammacherGiftCardRefundPlugin,
  giftCardRefundPlugin,
  mmPlugin,
  OnOffWarrantyPlugin,
  hipPocketPlugin,
  ViiGiftCards,
  MetricsCollectorPlugin,
  saiNumberCapture,
  NohblePlugin,
];

export default plugins;

type ComponentType = Exclude<
  Required<HookOfType<CustomComponent>>,
  | 'getStatus'
  | 'onMount'
  | 'onUnmount'
  | 'afterSuccessfulPaymentConfirmation'
  | 'getCustomButtons'
  | 'onTaxExempt'
  | 'getTranslationOverrides'
  | 'beforeShowCustomerDetails'
  | undefined
>;

type Props<T extends ComponentType> = T extends ComponentType
  ? Omit<Parameters<Required<PosPlugin>[T]>[0], 'children'>
  : never;

export const PluginComponent = <K extends ComponentType>({
  hookname,
  props = {} as Props<K>,
  children = null,
}: {
  hookname: K;
  props?: Props<K>;
  children?: React.ReactNode;
}) => {
  const hooks = useSelector(getPluginHooksByName<CustomComponent>(hookname));
  const SubComponent = useCallback(
    ({ children, ...props }) =>
      hooks.reduce(
        (Prev, Comp) => Comp({ ...props, children: Prev }),
        children as any,
      ),

    [hooks],
  );

  return <SubComponent {...props}>{children}</SubComponent>;
};
