import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getIDealConfiguration } from 'plugins/ideal/Configuration';
import { getProductByID } from 'reducers/cachedItems/products';
import { getProductGroups } from 'reducers/productGroupsDB';

const getGroupsInCart = state => {
  const ids = state.shoppingCart.rows.map(r => r.user.productID);
  // getProductByID is returning undefined, thus the filtering
  return (
    ids
      .map(id => getProductByID(id)(state))
      ?.filter(p => p)
      ?.map(prod => prod.groupID) || []
  );
};

const hasEmployeeSelectProduct = createSelector(
  getGroupsInCart,
  getProductGroups,
  getIDealConfiguration,
  (groupIDs, allGroups, conf) => {
    function* getAllGroupIDs(id) {
      if (!id) return;

      yield id;
      const parentID = allGroups.find(group => group.id === id)?.parentGroupID;
      yield* getAllGroupIDs(parentID);
    }
    return groupIDs
      .flatMap(id => Array.from(getAllGroupIDs(String(id))))
      .map(Number)
      .includes(conf.employeeSelectProductGroup);
  },
);

export const employeeSelectOnProductGroup = {
  selectorOverrides: {
    getSettings: base =>
      createSelector(base, hasEmployeeSelectProduct, (base: object, hasProd) =>
        R.when(
          () => hasProd,
          R.assoc('touchpos_choose_employee_manually', true),
        )(base),
      ),
  },
};
