import React from 'react';

import TextEditor from 'components/Editor';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MainView = ({ content, save }) => (
  <TextEditor save={save} content={content} />
);

export default MainView;
