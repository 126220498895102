import { MONERIS_CONST } from 'paymentIntegrations/moneris/types';

export type FormShapeRecord = {
  id: string;
  type: string;
  options?: string[];
  validate: (
    p: string | number | boolean,
  ) => null | { message: string; dict?: Record<'field', string> };
} & Record<string, any>;

export const monerisFields: Record<string, FormShapeRecord> = {
  ConnectionType: {
    id: 'ConnectionType',
    type: 'select',
    options: ['ethernet'],
    validate: () => null,
  },
  EntryMode: {
    id: MONERIS_CONST.CONFIG.ENTRY_MODE.KEY,
    type: 'select',
    defaultValue: MONERIS_CONST.CONFIG.ENTRY_MODE.OPTIONS.NEVER,
    options: Object.values(MONERIS_CONST.CONFIG.ENTRY_MODE.OPTIONS),
    validate: () => null,
  },
  Receipt: {
    id: MONERIS_CONST.CONFIG.RECEIPT.KEY,
    type: 'select',
    defaultValue: MONERIS_CONST.CONFIG.RECEIPT.OPTIONS.NONE,
    options: Object.values(MONERIS_CONST.CONFIG.RECEIPT.OPTIONS),
    validate: () => null,
  },
  DeviceTimeout: {
    id: 'DeviceTimeout',
    type: 'text',
    validate: val => {
      if (val?.toString().length) {
        if (val?.toString().match(/[^\d]/g)?.length) {
          return { message: 'invalidTimeout' };
        }
        if (val < 0)
          return { message: 'isNegative', dict: { field: 'DeviceTimeout' } };
        return null;
      }
      return { message: 'isMissing', dict: { field: 'DeviceTimeout' } };
    },
  },
  TerminalIPAddress: {
    id: 'TerminalIPAddress',
    type: 'text',
    validate: val =>
      val?.toString().length
        ? null
        : { message: 'isMissing', dict: { field: 'TerminalIPAddress' } },
  },
  TerminalPort: {
    id: 'TerminalPort',
    type: 'text',
    validate: val => {
      if (val > 0) {
        if (val >= 1024) {
          return null;
        }
        return { message: 'invalidPort', dict: { field: 'TerminalPort' } };
      }
      return { message: 'isMissing', dict: { field: 'TerminalPort' } };
    },
  },
  TerminalID: {
    id: 'TerminalID',
    type: 'text',
    validate: val =>
      val?.toString().length
        ? null
        : { message: 'isMissing', dict: { field: 'TerminalID' } },
  },
  MerchantID: {
    id: 'MerchantID',
    type: 'text',
    validate: val =>
      val?.toString().length
        ? null
        : { message: 'isMissing', dict: { field: 'MerchantID' } },
  },
};
