import React, { useMemo, useState } from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { useAsync, useDebounce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Box, LinearProgress, TextField } from '@material-ui/core';

import { Customer } from 'types/Customer';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getCustomerSearchFilterOption } from 'reducers/configs/settings';
import { doCustomerSearch } from 'actions/searchCustomer';
import { sortByCompanyOrFullName } from 'containers/Col1/Search/Customers/util';

// TODO: Add a 'search' button to the end, like in backoffice
//   When clicked, open a MUI dialog containing a more advanced search
//   Where different filters can be set and which shows seveeral columns of data
const CustomerInput: React.FC<{
  value: Customer | number | null;
  setValue: (v: Customer | null) => void;
  extraParams?: any;
  modifyOptions?: (options: Customer[], text?: string) => Customer[];
  autoFocus?: boolean;
} & Partial<
  Omit<AutocompleteProps<number, false, undefined, false>, 'value' | 'onChange'>
>> = ({
  value,
  setValue,
  extraParams,
  modifyOptions = c => c,
  autoFocus,
  ...rest
}) => {
  const dispatch: ThunkDispatch<any, any, Action> = useDispatch();
  // Selectors
  const warehouseID = useSelector(getSelectedWarehouseID);
  const queryFilterOption = useSelector(getCustomerSearchFilterOption);
  const filterParameters = useMemo(() => {
    switch (queryFilterOption) {
      case 'byHomeStore':
        return { homeStoreID: warehouseID };
      case 'bySignupStore':
        return { signUpStoreID: warehouseID };
      default:
        return {};
    }
  }, [queryFilterOption, warehouseID]);

  // State
  const [input, setInput] = useState('');
  const [searchText, setSearchText] = useState('');
  useDebounce(() => setSearchText(input), 400, [input]);
  const { value: customers, loading: isFetching, error: fetchError } = useAsync(
    () =>
      dispatch(
        doCustomerSearch({
          recordsOnPage: 100,
          getAddresses: 1,
          getBalanceInfo: 1,
          getBalanceWithoutPrepayments: 1,
          searchFromMiddle: 1,
          responseMode: 'detail',
          searchNameIncrementally: searchText,
          searchValue: searchText,
          mode: 'ALL',
          ...filterParameters,
          ...extraParams,
        }),
      ).then(sortByCompanyOrFullName),
    [searchText, filterParameters, extraParams],
  );

  const isWaitingForDebounce = input !== searchText;
  const loading = isFetching || isWaitingForDebounce;
  const loadingText = isWaitingForDebounce
    ? 'Waiting until typing finished'
    : `Searching for "${searchText}"`;
  const noOptionsText = fetchError
    ? `Failed to fetch: ${fetchError.message}`
    : `No results for "${searchText}"`;

  const modifiedOptions = useMemo(() => {
    if (loading) return modifyOptions([], loadingText);
    if (!customers || customers.length === 0) return modifyOptions([], noOptionsText);
    return modifyOptions(customers);
  }, [modifyOptions, loadingText, noOptionsText, loading]);

  return (
    <Autocomplete
      inputValue={input}
      onInputChange={(e, v) => setInput(v)}
      loading={loading}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      value={typeof value === 'number' ? value : value?.id}
      onChange={(e, v) =>
        setValue(modifiedOptions.find(o => o.id === v) ?? null)
      }
      options={modifiedOptions.map(o => o.id)}
      renderInput={props => (
        <Box>
          <TextField variant="outlined" {...props} autoFocus={autoFocus} />
          {/* Visibility instead of conditional rendering to prevent layout shifts */}
          <Box visibility={loading ? 'visible' : 'hidden'}>
            <LinearProgress />
          </Box>
        </Box>
      )}
      getOptionLabel={(id: number) => {
        const customer = modifiedOptions.find(c => c.id === id);
        if (!customer) return '';
        return (
          customer.companyName || `${customer.firstName} ${customer.lastName}`
        );
      }}
      {...rest}
    />
  );
};
export default CustomerInput;
