import axios from 'axios';
import { isEmpty } from 'ramda';
import i18next from 'i18next';

import {
  REDUX_CUSTOMER_REGISTRY_URL,
  REDUX_CUSTOMER_REGISTRY_TOKEN,
} from 'constants/persistence';

import * as mapResponse from './mapResponse';
import * as mapRequest from './mapRequest';

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

/**
 * Find customers by list of ids
 *
 * @param {Array} list - Array of customer ids
 */

const getBaseUrl = () =>
  `${localStorage.getItem(REDUX_CUSTOMER_REGISTRY_URL)}V1`;

const getAuthFormData = () => {
  const token = `${localStorage.getItem(REDUX_CUSTOMER_REGISTRY_TOKEN)}`;
  const formData = new FormData();
  formData.append('api[jwt]', token);
  return formData;
};
export const findByIdList = ({ list = '' } = {}) => {
  const formData = getAuthFormData();
  list.split(',').forEach(item => {
    formData.append('parameters[list][]', item);
  });

  return axios({
    method: 'post',
    url: `${getBaseUrl()}/Customer/findByIdList`,
    data: formData,
    headers,
  })
    .then(response => mapResponse.findByIdList(response))
    .catch(err => {
      throw err;
    });
};

export const customerSearch = ({ searchTerm = '' }) => {
  const formData = getAuthFormData();
  formData.append('parameters[searchTerm]', searchTerm);
  return axios({
    method: 'post',
    url: `${getBaseUrl()}/Customer/search`,
    data: formData,
    headers,
  })
    .then(response => mapResponse.customerSearch(response))
    .catch(err => {
      throw err;
    });
};

export const getMasterRecord = ({ id }) => {
  const formData = getAuthFormData();
  formData.append('parameters[id]', id);
  return axios({
    method: 'post',
    url: `${getBaseUrl()}/Customer/getMasterRecord`,
    data: formData,
    headers,
  })
    .then(response => mapResponse.getMasterRecord(response))
    .catch(err => {
      throw err;
    });
};

export const createCustomer = async params => {
  const formData = getAuthFormData();
  Object.entries(mapRequest.createCustomer(params)).forEach(([key, value]) =>
    formData.append(`parameters[${key}]`, String(value)),
  );

  try {
    const { data } = await axios.post(
      `${getBaseUrl()}/Customer/create`,
      formData,
      { headers },
    );

    if (data.error.code !== null) {
      throw new Error(data.error.message);
    }
    return mapResponse.createCustomer(data);
  } catch (error) {
    await i18next.loadNamespaces('createCustomer');
    throw new Error(i18next.t('createCustomer:errorText.failedToSave'), {
      cause: error,
    });
  }
};

export const updateCustomer = async params => {
  const formData = getAuthFormData();
  Object.entries(mapRequest.updateCustomer(params)).forEach(([key, value]) =>
    formData.append(`parameters[${key}]`, String(value)),
  );
  try {
    const { data } = await axios.post(
      `${getBaseUrl()}/Customer/update`,
      formData,
      {
        headers,
      },
    );

    if (data.error.code !== null) {
      throw new Error(data.error.message);
    }

    return mapResponse.updateCustomer(data);
  } catch (error) {
    await i18next.loadNamespaces('createCustomer');
    throw new Error(i18next.t('createCustomer:errorText.failedToUpdate'), {
      cause: error,
    });
  }
};
export const updateAddress = params => {
  const formData = getAuthFormData();
  Object.entries(mapRequest.updateAddress(params)).forEach(([key, value]) =>
    formData.append(`parameters[${key}]`, String(value)),
  );
  return axios({
    method: 'post',
    url: `${getBaseUrl()}/Address/update`,
    data: formData,
    headers,
  })
    .then(response => mapResponse.updateAddress(response))
    .catch(err => {
      throw err;
    });
};
export const createAddress = params => {
  const formData = getAuthFormData();
  Object.entries(mapRequest.createAddress(params)).forEach(([key, value]) =>
    formData.append(`parameters[${key}]`, String(value)),
  );
  return axios({
    method: 'post',
    url: `${getBaseUrl()}/Address/create`,
    data: formData,
    headers,
  })
    .then(response => mapResponse.createAddress(response))
    .catch(err => {
      throw err;
    });
};

export const deleteAddress = ({ addressID }) => {
  const formData = getAuthFormData();
  formData.append('parameters[id]', addressID);
  return axios({
    method: 'post',
    url: `${getBaseUrl()}/Address/delete`,
    data: formData,
    headers,
  })
    .then(response => mapResponse.deleteAddress(response))
    .catch(err => {
      throw err;
    });
};

export const advancedSearch = async params => {
  const formData = getAuthFormData();

  if (params && !isEmpty(params))
    Object.entries(params).forEach(([key, value]) =>
      formData.append(`parameters[${key}]`, String(value)),
    );

  const response = await axios.post(
    `${getBaseUrl()}/Customer/advancedSearch`,
    formData,
    { headers },
  );

  const { code, message } = response.data.error;
  if (code) throw new Error(message);

  return mapResponse.advancedSearch(response);
};

export const addTag = async ({
  customerId,
  tagId,
}: {
  customerId: number;
  tagId: number;
}) => {
  const formData = getAuthFormData();
  formData.append(`parameters[customerId]`, String(customerId));
  formData.append(`parameters[tagId]`, String(tagId));

  const response = await axios.post(
    `${getBaseUrl()}/Customer/addTag`,
    formData,
    {
      headers,
    },
  );

  const { error } = response.data;
  if (error.code) throw new Error(error.message);
};

export const removeTag = async ({
  customerId,
  tagId,
}: {
  customerId: number;
  tagId: number;
}) => {
  const formData = getAuthFormData();
  formData.append(`parameters[customerId]`, String(customerId));
  formData.append(`parameters[tagId]`, String(tagId));

  const response = await axios.post(
    `${getBaseUrl()}/Customer/removeTag`,
    formData,
    {
      headers,
    },
  );

  const { error } = response.data;
  if (error.code) throw new Error(error.message);
};
