/**
 * Given a react focus event, prevents the focus by immediately returing focus to the previously-focused element insteadt
 */
export const preventFocus = (focusEvent: React.SyntheticEvent<FocusEvent>) => {
  const e = focusEvent as any;

  e.nativeEvent.target.blur();
  if (
    e.nativeEvent.relatedTarget &&
    e.nativeEvent.relatedTarget !== e.nativeEvent.target
  ) {
    e.nativeEvent.relatedTarget.focus();
  }
};

// eslint-disable-next-line no-undef
const INPUT_ELEMENT_TYPES = [HTMLInputElement, HTMLTextAreaElement] as const;

/** True if the passed in object is an input element - also narrows the type for typescript */
const validateElement = (
  element: any,
): element is HTMLInputElement | HTMLTextAreaElement => {
  return INPUT_ELEMENT_TYPES.some(t => element instanceof t);
};

/** Simulate an ENTER key */
export const submit = element => {
  if (!validateElement(element)) return;

  const config = {
    key: 'Enter',
    code: 'Enter',
    keyCode: 13,
    which: 13,
  };
  // eslint-disable-next-line no-undef
  element.dispatchEvent(new KeyboardEvent('keydown', config));
  // element.dispatchEvent(new KeyboardEvent('keyup', config));
};

/** Move the cursor to the given position */
const setCaret = (element, position) => {
  if (!validateElement(element)) return;

  const proto = Object.getPrototypeOf(element);
  Object.getOwnPropertyDescriptor(proto, 'selectionStart')?.set?.call(
    element,
    position,
  );
  Object.getOwnPropertyDescriptor(proto, 'selectionEnd')?.set?.call(
    element,
    position,
  );
};

/** Update the input value using the given transformation function */
const setNewInputValue = (element, transform) => {
  if (!validateElement(element)) return;

  // Transform the value
  Object.getOwnPropertyDescriptor(
    Object.getPrototypeOf(element) as typeof INPUT_ELEMENT_TYPES[number],
    'value',
  )?.set?.call(element, transform(element.value));

  // Trigger the event (we want this to trigger react's onChange)
  element.dispatchEvent(
    // eslint-disable-next-line no-undef
    new InputEvent('input', {
      inputType: 'insertText',
      data: element.value,
      bubbles: true,
      composed: true,
    }),
  );
};

/**
 * Simulate typing into the field
 */
export function typeIntoField(
  element,
  {
    /** Text prepended at the start of the field, regardless of caret position */
    prepend = '',
    /** Text added to the left of the cursor, as with normal typing */
    left = '',
    /** Text added to the right of the cursor, as if typing in reverse */
    right = '',
    /** Text appended to the end of the field, regardless of catet position */
    append = '',
  },
) {
  if (!validateElement(element)) return;

  const { selectionStart: start, selectionEnd: end } = element;
  // Cannot type into non-selectable input (f.ex. input type=button)
  if (start === null) return;
  if (end === null) return;

  // Change the value correctly
  setNewInputValue(
    element,
    () =>
      prepend +
      element.value.slice(0, start) +
      left +
      right +
      element.value.slice(end) +
      append,
  );

  setCaret(element, start + prepend.length + left.length);
}

/**
 * Simulate deleting from a field
 */
export function deleteFromField(
  element,
  {
    /** Number of characters to delete from the start of the field, regardless of caret position */
    prepend = 0,
    /** Number of characters to delete to the left of the caret (like backspace) */
    left = 0,
    /** Number of characters to delete to the right of the caret (like delete) */
    right = 0,
    /** Number of characters to delete from the end of the field, regardless of caret position */
    append = 0,
  },
) {
  if (!validateElement(element)) return;

  const { selectionStart: start, selectionEnd: end } = element;
  // Cannot type into non-selectable input (f.ex. input type=button)
  if (start === null) return;
  if (end === null) return;

  // Change the value correctly
  setNewInputValue(
    element,
    () =>
      element.value.slice(prepend, start - left) +
      element.value.slice(end + right, element.value.length - append),
  );

  setCaret(element, start - left + prepend);
}
