import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { PosPlugin } from 'plugins/plugin';
import Checkbox from 'components/Checkbox';

import { Configuration } from '../types';

const ComponentConfiguration: PosPlugin<
  Configuration
>['ComponentConfiguration'] = ({ current, save }) => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState(current ?? {});
  const error = {
    url: !config.url ? 'empty' : '',
    viiPassword: !config.viiPassword ? 'empty' : '',
    viiUserName: !config.viiUserName ? 'empty' : '',
    StoreId:
      !config.StoreId && !(config.AIIC && config.CATI && config.CAIC)
        ? 'empty'
        : '',
    AIIC: !config.StoreId ? 'storeId missing' : 'optional',
    CATI: !config.StoreId ? 'storeId missing' : 'optional',
    CAIC: !config.StoreId ? 'storeId missing' : 'optional',
    giftCardCode: !config.giftCardCode ? 'empty' : '',
    hideCheckHistory: '',
  };

  const saveButtonDisabled = useMemo(
    () =>
      !!error.url ||
      !!error.viiPassword ||
      !!error.viiUserName ||
      !!error.giftCardCode ||
      error.StoreId === 'empty',
    [
      error.url,
      error.viiPassword,
      error.viiUserName,
      error.StoreId,
      error.giftCardCode,
    ],
  );

  const saveConf = () => {
    save(config);
    dispatch(previousModalPage());
  };

  const onChange = e => {
    const { target } = e;
    if (target.name === 'hideCheckHistory') {
      return setConfig(c => ({
        ...c,
        [target.name]: target.checked,
      }));
    }
    return setConfig(c => ({
      ...c,
      [target.name]: target.value,
    }));
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={saveConf}
        disabled={saveButtonDisabled}
      >
        Save
      </Button>
      <TextField
        fullWidth
        margin="normal"
        label="Url"
        variant="outlined"
        value={config.url}
        key="url"
        name="url"
        type="text"
        onChange={onChange}
        helperText={error.url}
        error={!!error.url}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Vii User Name"
        variant="outlined"
        value={config.viiUserName}
        key="username"
        name="viiUserName"
        type="text"
        onChange={onChange}
        helperText={error.viiUserName}
        error={!!error.viiUserName}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Vii User Password"
        variant="outlined"
        value={config.viiPassword}
        key="password"
        name="viiPassword"
        type="text"
        onChange={onChange}
        helperText={error.viiPassword}
        error={!!error.viiPassword}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Gift Card Product code"
        variant="outlined"
        value={config.giftCardCode}
        key="giftCardCode"
        name="giftCardCode"
        type="text"
        onChange={onChange}
        helperText="Enter code of the product to use as Vii gift card. Product must be tax-free, zero priced and marked as a serial numbered gift card."
        error={!!error.giftCardCode}
      />
      <Checkbox
        label="Hide 'Check History' functionality"
        value={!!config.hideCheckHistory}
        key="hideCheckHistory"
        name="hideCheckHistory"
        onChange={onChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Store ID"
        variant="outlined"
        value={config.StoreId}
        key="StoreId"
        name="StoreId"
        type="text"
        onChange={onChange}
        helperText={error.StoreId}
        error={error.StoreId === 'empty'}
      />
      <TextField
        fullWidth
        margin="normal"
        label="AIIC"
        variant="outlined"
        value={config.AIIC}
        key="AIIC"
        name="AIIC"
        type="text"
        onChange={onChange}
        error={
          error.StoreId === 'empty' &&
          error.AIIC === 'storeId missing' &&
          !config.AIIC
        }
        helperText={error.AIIC}
      />
      <TextField
        fullWidth
        margin="normal"
        label="CATI"
        variant="outlined"
        value={config.CATI}
        key="CATI"
        name="CATI"
        type="text"
        onChange={onChange}
        helperText={error.CATI}
        error={
          error.StoreId === 'empty' &&
          error.CATI === 'storeId missing' &&
          !config.CATI
        }
      />
      <TextField
        fullWidth
        margin="normal"
        label="CAIC"
        variant="outlined"
        value={config.CAIC}
        key="CAIC"
        name="CAIC"
        type="text"
        onChange={onChange}
        error={
          error.StoreId === 'empty' &&
          error.CAIC === 'storeId missing' &&
          !config.CAIC
        }
        helperText={error.CAIC}
      />
    </>
  );
};

export default ComponentConfiguration;
