import React, { ComponentProps, FC } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import './MuiDatePicker.scss';

type Props = ComponentProps<typeof KeyboardDatePicker> & {
  disabledProp?: boolean;
  appendTitle?: string;
  onChange: (date: MaterialUiPickersDate) => void;
  format: string;
  value: Date | null;
  label?: string;
  'data-testid'?: string;
};

const MuiDatePicker: FC<Props> = ({
  disabledProp = false,
  appendTitle = '',
  onChange,
  format,
  value,
  label = '',
  disableFuture,
  disablePast = false,
  'data-testid': dataTestId,
  ...rest
}) => (
  <div className="mui-date-date-picker">
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="prepend-title">
        <span className="prepend-text">{label}</span>
      </div>

      <KeyboardDatePicker
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
        disabled={disabledProp}
        margin="dense"
        disableToolbar={true}
        disablePast={disablePast}
        disableFuture={disableFuture}
        label={(!value && format?.toLowerCase()) || ''}
        inputVariant="outlined"
        format={format}
        value={value}
        onChange={onChange}
        variant="inline"
        inputProps={{ 'data-testid': dataTestId }}
        {...rest}
      />
      {appendTitle ? (
        <div style={{ minWidth: '47.5px' }}>{appendTitle}</div>
      ) : null}
    </MuiPickersUtilsProvider>
  </div>
);

export default MuiDatePicker;
