/* eslint-disable no-await-in-loop */
import { v4 as uuidv4 } from 'uuid';

import { isEmpty } from 'utils';
import { getCardPaymentsForIntegration } from 'reducers/Payments';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { markForProcessing, deletePayment } from 'actions/Payments';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { getClientCode } from 'reducers/Login';
import { withWaitingForTerminal } from 'paymentIntegrations';

import { handleErrors, withResponseErrors } from './errors';

export const title = 'Tripos Terminal Interface';

const handleSuccess = async ({
  payment,
  response,
  beforeDocSave,
  beforeDocDelete,
  updateMessage,
  displaySuccess,
  mode = 'sale',
}) => {
  if (response.transactionStatus.indexOf('Approved') >= 0) {
    updateMessage(`Transaction successful!`);
    displaySuccess(`Transaction successful!`);
    if (mode === 'sale' || mode === 'refund') {
      await beforeDocSave({
        key: payment.key,
        paid: true,
        type: 'CARD',
        amount:
          mode === 'refund'
            ? `-${Number(response.approvedAmount).toFixed(2)}`
            : Number(response.approvedAmount).toFixed(2),
        cardType: response.cardType,
        cardHolder: response.cardHolder,
        cardNumber: response.cardNumber,
        signature: response.signature || null,
        attributes: {
          applicationLabel: response.applicationLabel,
          aid: response.aid,
          authCode: response.authCode,
          paymentType: response.paymentType,
          dateTime: response.dateTime,
          merchantId: response.merchanId,
          referenceNumber: response.referenceNumber,
        },
      });
    } else if (mode === 'void') {
      beforeDocDelete(payment.key);
    }
  }
};
const makeRequest = ({
  request,
  params,
  mode,
  payment,
  beforeDocSave,
  beforeDocDelete,
  updateMessage,
  enableButtons,
  displaySuccess,
  displayError,
}) => async dispatch => {
  return dispatch(
    withWaitingForTerminal(() => {
      return new Promise((resolve, reject) => {
        withResponseErrors({
          request,
          params,
        })
          .then(
            async res => {
              await handleSuccess({
                payment,
                response: res.data,
                beforeDocSave,
                beforeDocDelete,
                updateMessage,
                enableButtons,
                displaySuccess,
                mode,
              });
              resolve(res);
            },
            err => {
              throw err;
            },
          )
          .catch(err => {
            handleErrors({
              err,
              displayError,
              updateMessage,
              enableButtons,
            });
            reject(err);
          });
      });
    }),
  );
};

export const initPayments = ({
  enableButtons,
  cardPayments,
  updateMessage,
  beforeDocSave,
  beforeDocDelete,
  resolvePayments,
  rejectPayments,
  displayError,
  displaySuccess,
}) => async (dispatch, getState) => {
  const state = getState();
  const clientCode = getClientCode(state);
  const { value: config } =
    getCafaEntry('triPOS', INTEGRATION_TYPES.payment)(state) || {};

  if (isEmpty(config)) {
    displayError('Tripos not configured.');
    rejectPayments();
    return;
  }
  const configuration = {
    laneId: `${config.LaneId}`,
  };

  const shouldVoid = cardPayments.every(cp => cp.paid && cp.shouldProcess);
  const cardPaymentsToProcess = cardPayments.filter(p =>
    shouldVoid ? p.paid : !p.paid,
  );
  for (let i = 0; i < cardPaymentsToProcess.length; i++) {
    const {
      amount,
      shouldProcess,
      paid,
      attributes = {},
    } = cardPaymentsToProcess[i];
    const { referenceNumber } = attributes;
    const paymentType = (attributes?.paymentType || '').toLowerCase();
    const mode = Number(amount) < 0.01 ? 'refund' : 'sale';

    if (mode === 'sale') {
      if (shouldProcess && paid) {
        // Handle payment voiding
        if (
          paymentType.toLowerCase() === 'credit' ||
          paymentType.toLowerCase() === 'debit'
        ) {
          updateMessage(`Processing void payment of ${amount}...`);
          await dispatch(
            makeRequest({
              request: 'requestReversal',
              params: {
                requestID: uuidv4(),
                clientCode,
                amount: `${amount}`,
                referenceNumber,
                paymentType,
                configuration,
              },
              payment: cardPaymentsToProcess[i],
              beforeDocSave,
              beforeDocDelete,
              updateMessage,
              enableButtons,
              displaySuccess,
              displayError,
              mode: 'void',
            }),
          );
        } else {
          updateMessage(`Refunding card payment of ${amount}...`);

          await dispatch(
            makeRequest({
              request: 'requestReturn',
              params: {
                amount: `${Number(-amount)}`,
                configuration,
              },
              payment: cardPaymentsToProcess[i],
              beforeDocSave,
              updateMessage,
              enableButtons,
              displaySuccess,
              displayError,
              mode: 'void',
            }),
          );
        }
      }

      // Handle regular sale
      else {
        updateMessage(`Processing card payment of ${amount}...`);
        await dispatch(
          makeRequest({
            request: 'requestSale',
            params: {
              requestID: uuidv4(),
              clientCode,
              amount: `${amount}`,
              configuration,
            },
            payment: cardPaymentsToProcess[i],
            beforeDocSave,
            updateMessage,
            enableButtons,
            displaySuccess,
            displayError,
            mode: 'sale',
          }),
        );
      }
    } else if (mode === 'refund') {
      console.log(
        'REFUNDING ',
        cardPaymentsToProcess[i],
        ' mode: refund, request: requestReturn',
      );
      updateMessage(`Refunding card payment of ${amount}...`);
      await dispatch(
        makeRequest({
          request: 'requestReturn',
          params: {
            requestID: uuidv4(),
            clientCode,
            amount: `${Number(-amount)}`,
            configuration,
          },
          payment: cardPaymentsToProcess[i],
          beforeDocSave,
          updateMessage,
          enableButtons,
          displaySuccess,
          displayError,
          mode: 'refund',
        }),
      );
    }
  }

  // if product.paid  product.shouldProcess
  if (shouldVoid) {
    rejectPayments();
  } else {
    resolvePayments();
  }
};

export const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('triPOS')(getState());
  cardPayments.forEach(({ key, paid }) =>
    paid
      ? dispatch(markForProcessing({ key }))
      : dispatch(deletePayment({ key })),
  );

  const finalPayments = getCardPaymentsForIntegration('triPOS')(getState());
  if (finalPayments.length) {
    await dispatch(openTerminalIntegration());
  }
};

export const retryPayment = params => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('triPOS')(getState());
  if (cardPayments.every(c => c.paid)) {
    Promise.all(
      cardPayments.map(c => dispatch(markForProcessing({ key: c.key }))),
    );
  }
  const cardPaymentsToRetry = getCardPaymentsForIntegration('triPOS')(
    getState(),
  );
  params.enableButtons();
  await dispatch(
    initPayments({ ...params, cardPayments: cardPaymentsToRetry }),
  );
};

export const returnBack = params => async () => params.rejectPayments();

export const functions = [
  {
    actionOnClick: retryPayment,
    text: 'Retry',
    name: 'retry-payment',
    variant: 'success',
  },
  {
    actionOnClick: returnBack,
    text: 'Return back',
    name: 'return-back',
    variant: 'info',
  },
];
