import React, { useMemo, useState } from 'react';
import {
  Button,
  Grid,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { PosPlugin } from 'plugins/plugin';

const ComponentConfiguration: PosPlugin['ComponentConfiguration'] = ({
  current = {},
  save,
}) => {
  const [mssg, setMssg] = useState('');
  const [search, setSearch] = useState('');
  const [error, setError] = useState('');

  const filteredMessages = useMemo(
    () =>
      current.alertBlackList?.filter(msg =>
        msg.toLowerCase().includes(search.toLowerCase()),
      ) ?? [],
    [search, current.alertBlackList],
  );

  const handleChange = e => {
    setError('');
    setMssg(e.target.value);
  };

  const removeMessage = message => {
    save({
      ...current,
      alertBlackList: current.alertBlackList.filter(m => m !== message),
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!mssg.trim().length) {
      setError('Message cannot be empty.');
      return;
    }
    if (current.alertBlackList?.includes(mssg)) {
      setError('Message already exists');
      return;
    }
    save({
      ...current,
      alertBlackList: [...(current.alertBlackList ?? []), mssg],
    });
    setMssg('');
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography>
          Add the alert messages that you do NOT want to see in the Global Alert
          component to the list below. Make sure the message is exactly the same
          as the alert. The plugin does not support translations, so if you
          switch the POS language, you also would have to add the message in the
          new POS language to the configuration below.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <strong>Message</strong>
                  <InputBase
                    onChange={e => setSearch(e.target.value)}
                    placeholder="Search for message"
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMessages.length > 0 ? (
                filteredMessages.map(msg => {
                  return (
                    <TableRow key={msg}>
                      <TableCell>
                        <p style={{ wordBreak: 'break-word' }}>{msg}</p>
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => removeMessage(msg)}>
                          <DeleteOutlinedIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell>
                    <p>No records</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container onSubmit={handleSubmit} spacing={2}>
        <Grid item xs={12}>
          <TextField
            style={{ width: '100%', flexGrow: 1 }}
            multiline
            rows={4}
            label="Message"
            placeholder="Add new message"
            variant="outlined"
            value={mssg}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            onKeyDown={handleEnter}
          />
        </Grid>
        <Grid
          xs={12}
          item
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Button color="primary" variant="outlined" onClick={handleSubmit}>
            Add message
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComponentConfiguration;
