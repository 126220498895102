import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getHasGivexProductInCart } from 'plugins/givexHeartland/configuration/Configuration';
import { pluginID } from 'plugins/givexHeartland/constants';

import { getWbuConfiguration } from '../configuration';
import { getEnabledPlugins } from '../../../reducers/Plugins';

const getAmount = (allow, amount) => {
  if (!allow) return -1;
  if (!amount) return null;
  if (amount === '') return null;
  if (Number.isNaN(amount)) return null;
  if (Number(amount) <= 0) return null;
  return Number(amount);
};

export const insertCustomPaymentLimits: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (p, ap) => async (dispatch, getState) => {
  const { customPayments } = getWbuConfiguration(getState());
  const customPaymentLimits = customPayments.map(cp => ({
    type: cp.name,
    amount: getAmount(cp.enabled, cp.limit),
  }));
  const paymentLimits = ap.paymentLimits.concat(customPaymentLimits);
  // same for sales and returns because returns aren't allowed anyway
  return { ...ap, paymentLimits };
};

export const convertCustomPaymentsToGiftCard: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, ap) => async (dispatch, getState) => {
  const { customPayments } = getWbuConfiguration(getState());

  return ap.map(request => {
    if (request.requestName !== 'savePayment') return request;

    const customPayment = customPayments.find(cp => cp.name === request.type);
    if (customPayment) {
      return R.pipe(
        R.assoc('type', 'GIFTCARD'),
        R.assoc('cardType', customPayment.cardType),
      )(request);
    }
    return request;
  });
};

export const convertOriginalGiftCardPaymentsBackToCustom: Required<
  PosPlugin
>['onSetCurrentSalesDocPayments']['on'] = (p, ap) => async (
  dispatch,
  getState,
) => {
  const { customPayments } = getWbuConfiguration(getState());
  return R.evolve({
    original: R.map(payment => {
      const customPayment = customPayments.find(
        cp => cp.cardType === payment.cardType,
      );
      if (customPayment) {
        return R.assoc('type', customPayment.name, payment);
      }
      return payment;
    }),
  })(ap);
};

export const disableGiftcardTenderIfGiftcardProductsInCart: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (p, ap) => async (dispatch, getState) => {
  const isGivexPluginEnabled = getEnabledPlugins(getState()).some(
    plugin => plugin.plugin?.id === pluginID,
  );
  if (!isGivexPluginEnabled) return ap;

  const withoutGiftcards = R.map(
    R.when(
      R.propEq('type', 'GIFTCARD'),
      R.mergeDeepLeft({
        amount: -1,
        total: -1,
      }),
    ),
  );
  if (getHasGivexProductInCart(getState()))
    return R.evolve({
      paymentLimits: withoutGiftcards,
    })(ap);
  return ap;
};
