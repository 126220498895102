import { useMemo, useState, useEffect } from 'react';
import { useSelector, Selector } from 'react-redux';
import { useThrottle } from 'react-use';

import { getClientCode } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getLoggedInWarehouse } from 'reducers/warehouses';
import { getSelectedCustomer } from 'reducers/customerSearch';
import {
  getProductsInShoppingCart,
  getTotal,
  getTotalNet,
  getTotalTax,
  getVisibleTotalDiscount,
} from 'reducers/ShoppingCart';
import { getBalance, getPayments, getPaymentsTotal } from 'reducers/Payments';

export const useDelphiCustomerDisplayData = () => {
  const clientCode = useSelector(getClientCode);
  const POS = useSelector(getSelectedPos);
  const warehouse = useSelector(getLoggedInWarehouse);
  const customer = useSelector(getSelectedCustomer);
  const shoppingCart = useSelector(getProductsInShoppingCart);
  const total = useSelector(getTotal);
  const totalNet = useSelector(getTotalNet);
  const totalVat = useSelector(getTotalTax);
  const totalDiscount = useSelector(getVisibleTotalDiscount);

  const payments = useSelector(getPayments);

  const paymentChange = useSelector(getBalance);
  const paymentTotal = useSelector(getPaymentsTotal);
  /** @type CustomerDisplayMessage */
  const genericUpdate = useMemo(
    () => ({
      warehouse: warehouse?.name,
      warehouse_id: Number.parseInt(warehouse?.warehouseID, 10),
      pointofsale: POS?.name,
      pointofsale_id: Number.parseInt(POS?.pointOfSaleID, 10),
      customer: customer?.fullName,
      customer_id: Number.parseInt(customer?.customerID, 10),
      total,
      totalDiscount,
      totalNet,
      totalTax: totalVat,
      payment_change: paymentChange,
      payment_total: paymentTotal,
      lang: 'ee',
      clientCode,
    }),
    [
      warehouse,
      POS,
      customer,
      total,
      totalDiscount,
      totalNet,
      totalVat,
      paymentChange,
      paymentTotal,
      clientCode,
    ],
  );
  // prettier-ignore
  const numericKeys = useMemo(
    () => ['manualDiscount', 'totalDiscount', 'finalPriceWithVAT', 'finalPrice', 'rowNetTotal', 'rowVATTotal', 'rowTotal', 'productID', 'basePriceWithVAT', 'basePriceNet', 'basePrice', 'priceListPrice', 'priceListPriceWithVAT', 'vatrateID', 'rowNumber', 'amount'],
    [],
  );
  const saleUpdate = useMemo(
    () => ({
      action: 'sale',
      rows: (shoppingCart ?? []).map(product =>
        Object.fromEntries(
          Object.entries(product).map(([k, v]) => [
            k,
            numericKeys.indexOf(k) >= 0 ? Number(v) : v,
          ]),
        ),
      ),
    }),
    [shoppingCart, numericKeys],
  );
  const paymentUpdate = useMemo(
    () => ({
      action: 'payment',
      payments: Object.values(payments ?? []).map((pmt: any) => ({
        type: pmt.type,
        caption: pmt.caption,
        amount: Number.parseInt(pmt.amount, 10),
      })),
    }),
    [payments],
  );
  const saleEndUpdate = useMemo(
    () => ({
      action: 'saleend',
    }),
    [],
  );

  const update = useMemo(
    () => ({
      ...genericUpdate,
      ...saleEndUpdate,
      ...(shoppingCart.length ? saleUpdate : {}),
      ...(Object.values(payments).length ? paymentUpdate : {}),
    }),
    [
      genericUpdate,
      saleEndUpdate,
      shoppingCart.length,
      saleUpdate,
      Object.values(payments).length,
      paymentUpdate,
    ],
  );

  const returnVal = useThrottle(update, 500);
  return returnVal;
};
