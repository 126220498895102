import React from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

import { getExchangeState } from '../rdx';

const UICartProductEditShortcut: PosPlugin['UICartProductEditShortcut'] = ({
  children,
}) => {
  const exchangeState = useSelector(getExchangeState);

  if (exchangeState.exchangeStep >= 1) return null;
  return children;
};

export default UICartProductEditShortcut;
