import { getEndpointForService } from 'reducers/configs/serviceEnpoints';
import { REDUX_SERVICE_ENDPOINTS } from 'constants/persistence';

import { getServiceEndpoints } from '../services/ErplyAPI/getServiceEndpoints';
import { FETCH_SERVICE_ENDPOINTS } from '../constants/serviceEndpoints';

/**
 * Fetch service endpoints
 */
// eslint-disable-next-line import/prefer-default-export
export function fetchServiceEndpoints() {
  return async dispatch => {
    dispatch({ type: FETCH_SERVICE_ENDPOINTS.START });
    try {
      const [payload] = await getServiceEndpoints();
      await dispatch({ type: FETCH_SERVICE_ENDPOINTS.SUCCESS, payload });
    } catch (err) {
      console.error('Failed to load service endpoints', err);
      await dispatch({ type: FETCH_SERVICE_ENDPOINTS.ERROR });
    }
  };
}

export async function getUrlForService(serviceName) {
  // try to get url endpoint locally
  const localEndpoint = getEndpointForService(serviceName);
  if (localEndpoint?.url) return localEndpoint.url;

  // try to get endpoint from api
  const [endpointsFromAPI] = await getServiceEndpoints();

  // set to localstorage to avoid fetching endpoint over and over again
  localStorage.setItem(
    REDUX_SERVICE_ENDPOINTS,
    JSON.stringify(endpointsFromAPI),
  );

  const endpointFromAPI = endpointsFromAPI[serviceName];

  return endpointFromAPI?.url;
}
