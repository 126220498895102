/* eslint-disable max-classes-per-file */
import axios, { AxiosInstance } from 'axios';
import * as R from 'ramda';

import {
  RequestRequest,
  RequestResponse,
} from 'plugins/suretax/api/types/Request';
import { TaxAdjustmentRequest } from 'plugins/suretax/api/types/TaxAdjustment';
import {
  CancelRequest,
  CancelResponse,
} from 'plugins/suretax/api/types/Cancel';
import { ReturnRequest } from 'plugins/suretax/api/types/Return';

class SuretaxError extends Error {
  constructor(public data: any) {
    super(`SureTax api error response: ${JSON.stringify(data)}`);
  }
}

const overrideWithDebugTaxes = res => {
  const original = JSON.parse(JSON.stringify(res));
  res.data?.GroupList?.forEach((gr, i) =>
    gr.TaxList.forEach(tax => {
      // eslint-disable-next-line no-param-reassign
      tax.TaxRate *= 0.01 * i;
      // eslint-disable-next-line no-param-reassign
      tax.FeeRate *= 0.01 * i;
    }),
  );
  res.data.TotalTax = res.data.GroupList.map(gr =>
    gr.TaxList.map(tax => tax.TaxRate || tax.FeeRate).reduce(R.add, 0),
  ).reduce(R.add, 0);
};

export class SuretaxApi {
  private ax: AxiosInstance;

  constructor(endpoint: string, clientNumber: string, validationKey: string) {
    this.ax = axios.create({ baseURL: endpoint });
    this.ax.interceptors.request.use(req => {
      const mainPayload = JSON.stringify({
        ClientNumber: clientNumber,
        ValidationKey: validationKey,
        ...req.data,
      });
      if (req.url?.includes('CancelPostRequest')) {
        req.data = { requestCancel: mainPayload };
      } else {
        req.data = { request: mainPayload };
      }
      return req;
    });
    this.ax.interceptors.response.use(res => {
      try {
        res.data = JSON.parse(res.data.d);
        // // DO NOT RELEASE WITH THIS UNCOMMENTED
        // // override response to contain different tax rates for each item (to test tax rate generation)
        // if (localStorage.debug) {
        //   overrideWithDebugTaxes(res);
        // }
        return res;
      } catch (e) {
        throw new Error('Suretax response did not contain valid JSON');
      }
    });
  }

  private static throwIfError<
    T extends { ResponseCode: string; HeaderMessage: string }
  >(data: T) {
    if (data.ResponseCode === '9999') return data;
    throw new SuretaxError(data);
  }

  /**
   * Request and response specification for general sales, communications or energy transaction.
   * The request method is used to create a transaction to calculate taxes
   * @see https://developer.taxrating.net/articles/v07/request.html?tabs=JSON
   */
  public makeRequest(
    data: Omit<RequestRequest, 'ClientNumber' | 'ValidationKey'>,
  ) {
    return this.ax
      .post<RequestResponse>('/Services/V07/SureTax.asmx/PostRequest', data)
      .then(res => res.data)
      .then(SuretaxApi.throwIfError);
  }

  /**
   * The TaxAdjustmentRequest method is used to create a transaction to force or adjust a given tax amount
   * @see https://developer.taxrating.net/articles/v07/adjust.html?tabs=JSON
   */
  public makeTaxAdjustment(
    data: Omit<TaxAdjustmentRequest, 'ClientNumber' | 'ValidationKey'>,
  ) {
    return this.ax
      .post<RequestResponse>(
        '/Services/V07/SureTax.asmx/PostTaxAdjustmentRequest',
        data,
      )
      .then(res => res.data)
      .then(SuretaxApi.throwIfError);
  }

  /**
   * The ReturnRequest method is used to create a "return" a transaction.
   * @see https://developer.taxrating.net/articles/v07/return.html?tabs=JSON
   */
  public makeReturnRequest(
    data: Omit<ReturnRequest, 'ClientNumber' | 'ValidationKey'>,
  ) {
    return this.ax
      .post<RequestResponse>(
        '/Services/V07/SureTax.asmx/ReturnPostRequest',
        data,
      )
      .then(res => res.data)
      .then(SuretaxApi.throwIfError);
  }

  /**
   * The cancel request method is used to cancel a previous web request tax calculation process.
   * Only valid web requests for the current and prior calendar months can be cancelled.
   * Transactions older than 60 days cannot be cancelled.
   *
   * @note After a successful cancel request method is performed, all reporting data associated with the cancelled web request is removed from the system and will no longer be included on any reports.
   * @see https://developer.taxrating.net/articles/v07/cancel.html?tabs=JSON%2CPHP
   */
  public makeCancelRequest(
    data: Omit<CancelRequest, 'ClientNumber' | 'ValidationKey'>,
  ) {
    return this.ax
      .post<CancelResponse>(
        '/Services/V07/SureTax.asmx/CancelPostRequest',
        data,
      )
      .then(res => res.data)
      .then(SuretaxApi.throwIfError);
  }
}
