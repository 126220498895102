import debug from 'debug';

import { INTEGRATIONS, INTEGRATION_TYPES } from 'constants/CAFA';

export const apiUrl = 'https://api.stripe.com/v1';

export const minimumFees = {
  USD: 0.5,
  AED: 2.0,
  AUD: 0.5,
  BGN: 1.0,
  BRL: 0.5,
  CAD: 0.5,
  CHF: 0.5,
  CZK: 15.0,
  DKK: 2.5,
  EUR: 0.5,
  GBP: 0.3,
  HKD: 4.0,
  HUF: 175.0,
  INR: 0.5,
  JPY: 50,
  MXN: 10,
  MYR: 2,
  NOK: 3.0,
  NZD: 0.5,
  PLN: 2.0,
  RON: 2.0,
  SEK: 3.0,
  SGD: 0.5,
  THB: 10,
};

export const zeroDecimalCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const threeDecimalCurrencies = ['BHD', 'JOD', 'KWD', 'OMR', 'TND'];

export const requiredAddressFields = ['country', 'displayName'];

export const integrationType = INTEGRATION_TYPES.payment;
export const integrationName = INTEGRATIONS[integrationType].stripe;

export const baseLog = debug('stripe');
