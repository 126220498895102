import React, { useState } from 'react';

import UIButton from 'components/UIElements/UIButton';

const ComponentConfiguration = ({ byLevel: { company, pos } }) => {
  const [envFeeEnabled, toggleEnvFee] = useState(
    pos.current?.envFeeEnabled || false,
  );
  const [envFeeName, setEnvFeeName] = useState(pos.current?.envFeeName);
  const [CAFeeEnabled, toggleCAFee] = useState(
    pos.current?.CAFeeEnabled || false,
  );
  const [admissionFeeName, setFeeName] = useState(
    pos.current?.admissionFeeName,
  );
  const [admissionTaxName, setTaxName] = useState(
    company.current?.admissionTaxName,
  );
  const [code5names, setCode5Names] = useState(company.current?.code5names);
  const [productsThatWaiveAdmisisonFee, setWaivingProducts] = useState(
    company.current?.productsThatWaiveAdmisisonFee,
  );

  return (
    <div data-testid="pnp-env-fee-config">
      <input
        type="checkbox"
        id="checkbox-ENV"
        style={{ marginRight: '15px' }}
        checked={envFeeEnabled}
        onChange={() => toggleEnvFee(!envFeeEnabled)}
        data-testid="env-fee-enabled"
      />
      <label htmlFor="checkbox-ENV">Enable Environmental fee</label>
      {envFeeEnabled ? (
        <>
          <p>
            To be able to use this plugin one product needs to be created in the
            back office that will behave as the environmental fee. The product
            can be named as desired, but it must be <strong>non-stock</strong>.
            The environmental fee product should have a price of{' '}
            <strong>0</strong> and will be taxable{' '}
          </p>
          <p>
            <strong>
              The exact same name of the environmental fee product created in
              back office needs to be input in this menu.{' '}
            </strong>
          </p>
          <p>Write the name of the Environmental fee product</p>
          <input
            value={envFeeName}
            data-testid="env-fee-name"
            onChange={e => setEnvFeeName(e.target.value)}
            style={{ marginBottom: '10px' }}
          />

          <p style={{ marginTop: '10px' }}>
            The environmental fee applies only on products identified by these
            code 5 (comma delimited)(codes are company wide configuration):
          </p>
          <input
            value={code5names}
            data-testid="code5-names"
            onChange={e => setCode5Names(e.target.value)}
          />
        </>
      ) : null}
      <hr />
      <div>
        <input
          type="checkbox"
          id="checkbox-CA"
          style={{ marginRight: '15px' }}
          checked={CAFeeEnabled}
          onChange={() => toggleCAFee(!CAFeeEnabled)}
          data-testid="CA-fee-enabled"
        />
        <label htmlFor="checkbox-CA">Enable California admission fee</label>
        {CAFeeEnabled ? (
          <>
            <p>
              To be able to use this plugin two products need to be created, one
              that will behave as the admission fee and another one that will
              behave as the admission tax, the products can be named as desired,
              but both products need to be non-stock, and they should be VAT
              free in all locations. The admission fee product should have the{' '}
              <strong>desired price</strong>. The admission tax product should
              have a price of <strong>0</strong> and be non-returnable.
              <strong>
                The exact same names of the products need to be input in this
                menu.{' '}
              </strong>
            </p>
            <p>
              <strong>
                IMPORTANT: both products need to be configured as VAT free
                across all locations in the BO.
              </strong>
            </p>

            <p>Write the name of the Admission fee product</p>
            <input
              value={admissionFeeName}
              onChange={e => setFeeName(e.target.value)}
              data-testid="admission-fee-name"
            />

            <p style={{ marginTop: '10px' }}>
              Write the name of the Admission tax product
            </p>
            <input
              value={admissionTaxName}
              onChange={e => setTaxName(e.target.value)}
              data-testid="admission-tax-name"
            />

            <p style={{ marginTop: '10px' }}>
              Waive the Admissions Tax for the following products(Product Code,
              comma separated):
            </p>
            <input
              value={productsThatWaiveAdmisisonFee}
              onChange={e => setWaivingProducts(e.target.value)}
              data-testid="waiving-products"
            />
          </>
        ) : null}
      </div>
      <hr />
      <UIButton
        variant="POS"
        type="button"
        text="Save configuration"
        data-testid="save-btn"
        action={() => {
          pos.save({
            envFeeEnabled,
            envFeeName,
            CAFeeEnabled,
            admissionFeeName,
          });
          company.save({
            code5names,
            productsThatWaiveAdmisisonFee,
            admissionTaxName,
          });
        }}
      />
    </div>
  );
};

export default ComponentConfiguration;
