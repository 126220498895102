import { useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useAutoFocus(className = 'autofocus') {
  useEffect(() => {
    const elements = document.getElementsByClassName(className);
    if (elements.length > 1) {
      console.warn(
        'Multiple elements found with the given autofocus class - ',
        className,
        elements,
        'Focus was given to the first match by default',
        'To fix this error, use a unique classname or ensure that the other elements are not on the page at the same time',
      );
    }
    if (elements.length > 0) {
      document.getElementsByClassName(className)[0].focus();
    }
  }, [className]);
}
