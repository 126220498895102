import { useState, useEffect } from 'react';

const defaultPoints = [311, 768, 992, 1030, 1200];

export const useBreakpoints = () => {
  const getBreakpoint = () =>
    defaultPoints.filter(
      // 29JUN20: Replaced .offsetWidth with .clientWidth since i couldn't find any online tools to test offsetWidth
      // and it was reportedly not working even with force full width (doubt) - breaking after app minimize+reopen
      // Testing on https://ryanve.com/lab/dimensions/ seemed like only window.outerWidth was unstable
      n => n <= document.documentElement.clientWidth,
    ).length;

  const [bp, setBP] = useState(getBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      setBP(getBreakpoint());
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    // 01JUL20 - Can't find the root cause
    // Backup check for mobile devices which, upon reopening the app, provide the wrong size
    // at first and no event is fired to notify when the size updates to normal
    const i = setInterval(() => {
      const should = getBreakpoint();
      if (should !== bp) {
        setBP(should);
      }
    }, 1000);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      clearInterval(i);
    };
  }, []);

  /**
   * @deprecated
   * @type {never}
   */
  const forceWide = localStorage.getItem('Debug: forceWide');

  return {
    xxs: 0 <= bp,
    xs: 1 <= bp,
    sm: 2 <= bp,
    md: 3 <= bp,
    mdplus: 3 <= bp,
    lg: 5 <= bp,
  };
};

export const useSize = (debounce_frequency = 0) => {
  const [width, setWidth] = useState(document.documentElement.offsetWidth);
  const [height, setHeight] = useState(document.documentElement.offsetHeight);

  useEffect(() => {
    let i = null;
    const handleResize = () => {
      if (i) {
        clearTimeout(i);
      }
      i = setTimeout(() => {
        if (width !== document.documentElement.offsetWidth) {
          setWidth(document.documentElement.offsetWidth);
        }
        if (height !== document.documentElement.offsetHeight) {
          setHeight(document.documentElement.offsetHeight);
        }
        i = null;
      }, debounce_frequency);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      if (i) clearTimeout(i);
    };
  }, [height, width]);

  return { width, height };
};
