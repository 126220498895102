import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';

import { getCurrencyFormatter } from 'reducers/configs/settings';
import { Section } from 'containers/Forms/Settings/components/Inputs';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import { getVerifoneApi } from 'paymentIntegrations/verifone/requests';
import InputField from 'components/FieldTypes/InputField';
import { sanitizePrice } from 'actions/CreateNew';
import TestButtonBlock from 'components/UIElements/PaymentTestBlock';

const inputClass = classNames([
  formStyles.formInput,
  formStyles.mediumTitle,
  'verifone-form-input',
]);

const VerifoneConfiguration = () => {
  const { value: verifoneApi, loading } = useAsync(getVerifoneApi, []);

  const format = useSelector(getCurrencyFormatter);
  const formatCurrency = amount => format(Number(amount));

  const [testingAmount, setTestingAmount] = useState('0.01');
  const [testingRefNo, setTestingRefNo] = useState('');
  const [testingTimestamp, setTestingTimestamp] = useState('');
  const [testOutput, setTestOutput] = useState('');
  const [voidsList, setVoidsList] = useState([]);
  const [currentAction, setCurrentAction] = useState('');
  const [isOngoing, setIsOngoing] = useState(false);
  const [status, setStatus] = useState({});
  const setSuccess = key => setStatus({ ...status, [key]: 'Success' });
  const setFailure = key => setStatus({ ...status, [key]: 'Failure' });

  const isOngoingOrLoading = isOngoing === true || loading;
  const testFields = [
    {
      title: 'Amount',
      value: testingAmount,
      action: e => setTestingAmount(sanitizePrice(e.target.value)),
      disabled: isOngoing === true,
    },
    {
      title: 'Reference #',
      value: testingRefNo,
      action: e => setTestingRefNo(e.target.value.replace(/[^\d]/g, '')),
      disabled: isOngoing === true,
    },
    {
      title: 'Timestamp',
      value: testingTimestamp,
      action: e => setTestingTimestamp(e.target.value.replace(/[^\d]/g, '')),
      disabled: isOngoing === true,
    },
    {
      title: 'Output',
      value: testOutput,
      action: () => {
        // do nothing
      },
      disabled: true,
    },
  ];

  const testButtons = [
    {
      variant: 'secondary',
      title: `Test connection`,
      type: 'connection',
      info: 'Test the connection between the POS and the terminal',
      disabled: isOngoingOrLoading,
      action: async () => verifoneApi.testConnection(),
    },
    {
      variant: 'secondary',
      title: `Pay ${formatCurrency(testingAmount)}`,
      type: 'sale',
      info: 'Attempt a sale',
      disabled: isOngoingOrLoading || !Number(testingAmount) > 0,
      action: async () => verifoneApi.requestSale({ amount: testingAmount }),
    },
    {
      variant: 'secondary',
      title: `Return ${formatCurrency(testingAmount)}`,
      type: 'return',
      info: 'Attempt a refund',
      disabled: isOngoingOrLoading || !Number(testingAmount) > 0,
      action: async () => verifoneApi.requestReturn({ amount: testingAmount }),
    },
    {
      variant: 'secondary',
      title: `Reversal`,
      type: 'reversal',
      info: 'Attempt a transaction reversal',
      disabled:
        isOngoingOrLoading ||
        !Number(testingAmount) > 0 ||
        !testingRefNo ||
        !testingTimestamp,
      action: async () =>
        verifoneApi.requestVoid({
          amount: testingAmount,
          referenceNumber: testingRefNo,
          timestamp: testingTimestamp,
        }),
    },
    {
      variant: 'light',
      title: `Start`,
      type: 'start',
      info: 'Start the microservice',
      disabled: true,
      action: async () => verifoneApi.requestStart(),
    },
    {
      variant: 'danger',
      title: `Abort`,
      type: 'abort',
      info: 'Abort the current transaction/process',
      disabled: currentAction === 'connection' || !isOngoing || loading,
      action: async () => verifoneApi.cancelRequest(),
    },
  ];

  const handleTest = ({ type, action }) => {
    const out = msg => setTestOutput(`${type.toUpperCase()}: ${msg}`);
    setIsOngoing(true);
    setCurrentAction(type);
    out(`processing, please wait...`);
    action()
      .then(r => {
        if (['sale', 'return'].includes(type)) {
          if (
            r.data &&
            r.data.transactionType &&
            r.data.transactionType !== 'UNKNOWN'
          ) {
            out(r.data.statusMessage || r.message || 'Success');
            const update = [...voidsList, { ...r.data }];
            setVoidsList(update);
          } else {
            out(r.data.statusMessage || r.message);
            setIsOngoing(false);
            setFailure(type);
            return;
          }
        }
        if (type === 'reversal') {
          // v1 successful responses don't have resultCode, thus fallback is safe
          if (r.data && !Number(r.data.resultCode ?? 0)) {
            setVoidsList(
              voidsList.filter(v => v.referenceNumber !== testingRefNo),
            );
            out(r.data.statusMessage || r.message || 'Success');
            setTestingRefNo('');
            setTestingTimestamp('');
          } else {
            out(r.data.statusMessage || r.message);
            setIsOngoing(false);
            setFailure(type);
            return;
          }
        }
        if (type === 'connection') {
          if (r.data) {
            if (Number(r.data.resultCode ?? 0)) {
              throw new Error(r.data.statusMessage || r.message);
            }
            out(r.data.statusMessage || r.message || 'Success');
          } else {
            setIsOngoing(false);
            setFailure(type);
            return;
          }
        }
        setIsOngoing(false);
        setSuccess(type);
      })
      .catch(err => {
        out(err.message || err.statusMessage);
        console.error('Error in verifone payment integration', err);
        setIsOngoing(false);
        setFailure(type);
      });
  };

  const handleVoid = v => {
    const { referenceNumber, dateTime, approvedAmount } = v;
    setTestingAmount(approvedAmount);
    setTestingRefNo(referenceNumber);
    setTestingTimestamp(dateTime);
  };

  const returnTestFields = testFields.map(f => {
    return (
      <InputField
        type={f.type ? f.type : 'text'}
        key={f.title}
        title={f.title}
        value={f.value}
        onChange={f.action}
        size="lg"
        className={inputClass}
        disabled={f.disabled}
      />
    );
  });

  const returnTestButtons = testButtons.map(b => {
    let { variant } = b;
    const processing = isOngoing && b.type === currentAction;
    if (processing && b.type !== 'reversal') {
      variant = 'primary';
    } else if (b.disabled) {
      variant = 'light';
    }
    return (
      <TestButtonBlock
        key={b.type}
        clickHandler={() => handleTest({ action: b.action, type: b.type })}
        button={{ ...b, variant }}
        processing={processing}
        status={status[b.type]}
      />
    );
  });

  const returnVoids = voidsList.map(v => {
    const voidHandler = () => handleVoid(v);
    let btnVariant = 'light';
    if (
      v.approvedAmount === testingAmount &&
      v.referenceNumber === testingRefNo &&
      v.dateTime === testingTimestamp
    ) {
      btnVariant = 'primary';
    }
    return (
      <Button
        key={v.referenceNumber}
        className="mr-2 mb-2"
        variant={btnVariant}
        onClick={voidHandler}
      >
        {`${v.transactionType} | ${v.entryMode} | #${v.referenceNumber}`}
      </Button>
    );
  });

  return (
    <div className="verifone-settings">
      <h3>
        <b>Tests:</b>
      </h3>
      <Section>
        {returnTestFields}
        {returnTestButtons}
        <div>
          {voidsList.length ? (
            <>
              <h4>Current processed payments:</h4>
              {returnVoids}
            </>
          ) : (
            <h4>No processed payments</h4>
          )}
        </div>
      </Section>
    </div>
  );
};

export default VerifoneConfiguration;
