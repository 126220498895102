import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isAlphanumeric, isEmail, isMobilePhone, matches } from 'validator';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getSearchCustomerGroups } from 'reducers/customerSearch';
import { getAllHomeStores } from 'reducers/warehouses';
import { Address } from 'types/Address';
import { PluginComponent } from 'plugins';
import Checkbox from 'components/Checkbox';
import Autocomplete from 'components/Autocomplete';

import MultiAddressInput from '../../components/AddressFormBeta/MultiAddressForm';

import { Field } from './components';
import { getGroupOptionsWithNameHierarchy } from './utils';

const CompanyForm = ({ customer, handleChange, onChange }) => {
  const { t } = useTranslation('createCustomer');
  const homeStores = useSelector(getAllHomeStores);

  const customerGroups = useSelector(getSearchCustomerGroups);
  const groupOptionsWithNameHierarchy: any[] = useMemo(
    () => getGroupOptionsWithNameHierarchy(customerGroups),
    [customerGroups],
  );

  const anyPhoneNumberRegex = new RegExp(
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  );
  return (
    <Grid container spacing={2}>
      <PluginComponent
        hookname="UICustomerFormBeta"
        props={{ customerType: 'COMPANY', customer, handleChange, onChange }}
      >
        <Field
          name="companyName"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            fullWidth
            data-testid="field"
            data-test-key="companyName"
            name="companyName"
            variant="outlined"
            label={t('fields.companyName')}
            value={customer.companyName}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="vatNumber"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            data-testid="field"
            data-test-key="vatNumber"
            name="vatNumber"
            fullWidth
            variant="outlined"
            label={t('fields.vatNumber')}
            value={customer.vatNumber}
            error={customer.vatNumber && !isAlphanumeric(customer.vatNumber)}
            helperText={
              customer.vatNumber &&
              !isAlphanumeric(customer.vatNumber) &&
              t('errorText.invalidInput')
            }
            onChange={handleChange}
          />
        </Field>
        <Field name="code" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="code"
            name="code"
            fullWidth
            variant="outlined"
            label={t('fields.code')}
            error={
              customer.code && !matches(customer.code, /^[a-zA-Z0-9-_]+$/gi)
            }
            helperText={
              customer.code &&
              !matches(customer.code, /^[a-zA-Z0-9-_]+$/gi) &&
              t('errorText.invalidInput')
            }
            value={customer.code}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="customerCardNumber"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            data-testid="field"
            data-test-key="customerCardNumber"
            name="customerCardNumber"
            fullWidth
            variant="outlined"
            label={t('fields.customerCardNumber')}
            value={customer.customerCardNumber}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="euCustomerType"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            label={t('fields.euCustomerType')}
            data-testid="field"
            data-test-key="euCustomerType"
            name="euCustomerType"
            variant="outlined"
            fullWidth
            value={customer.euCustomerType ?? ''}
            onChange={handleChange}
          >
            <MenuItem value="">Select option</MenuItem>
            <MenuItem value="DOMESTIC">DOMESTIC</MenuItem>
            <MenuItem value="EU">EU</MenuItem>
            <MenuItem value="OUTSIDE_EU">OUTSIDE_EU</MenuItem>
          </TextField>
        </Field>
        <Field
          name="groupID"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            data-testid="field"
            data-test-key="groupID"
            name="groupID"
            label={t('fields.groupID')}
            fullWidth
            variant="outlined"
            value={customer.groupID ?? ''}
            onChange={handleChange}
          >
            {groupOptionsWithNameHierarchy.map(({ name, value }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Field>
        <Field
          name="homeStoreID"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <Autocomplete
            disablePortal
            id="homeStoreID"
            options={homeStores.map(store => store.warehouseID)}
            getOptionSelected={(option, value) =>
              Number(option) === Number(value)
            }
            inputProps={{
              'data-testid': 'field',
              'data-test-key': 'homeStoreID',
              label: t('fields.homeStoreID'),
              variant: 'outlined',
              fullWidth: true,
            }}
            getOptionLabel={option => {
              const selectedStore = homeStores.find(
                store => Number(store.warehouseID) === Number(option),
              );
              return selectedStore ? selectedStore.name : '';
            }}
            value={customer.homeStoreID}
            onChange={(event, newValue) => {
              onChange(customer => ({
                ...customer,
                homeStoreID: Number(newValue),
              }));
            }}
          />
        </Field>
        <Field name="email" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="email"
            name="email"
            fullWidth
            variant="outlined"
            label={t('fields.email')}
            error={customer.email && !isEmail(customer.email)}
            helperText={
              customer.email &&
              !isEmail(customer.email) &&
              t('errorText.invalidInput')
            }
            value={customer.email}
            onChange={handleChange}
          />
        </Field>
        <Field name="phone" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="phone"
            name="phone"
            fullWidth
            variant="outlined"
            label={t('fields.phone')}
            error={
              customer.phone && !matches(customer.phone, anyPhoneNumberRegex)
            }
            helperText={
              customer.phone &&
              !matches(customer.phone, anyPhoneNumberRegex) &&
              t('errorText.invalidInput')
            }
            value={customer.phone}
            onChange={handleChange}
          />
        </Field>
        <Field name="fax" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="fax"
            name="fax"
            fullWidth
            variant="outlined"
            label={t('fields.fax')}
            error={customer.fax && !matches(customer.fax, anyPhoneNumberRegex)}
            helperText={
              customer.fax &&
              !matches(customer.fax, anyPhoneNumberRegex) &&
              t('errorText.invalidInput')
            }
            value={customer.fax}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="mobile"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            data-testid="field"
            data-test-key="mobile"
            name="mobile"
            fullWidth
            variant="outlined"
            label={t('fields.mobile')}
            error={customer.mobile && !isMobilePhone(customer.mobile)}
            helperText={
              customer.mobile &&
              !isMobilePhone(customer.mobile) &&
              t('errorText.invalidInput')
            }
            value={customer.mobile}
            onChange={handleChange}
          />
        </Field>
        <MultiAddressInput
          key="addresses"
          customer={customer}
          onChange={addresses =>
            onChange(prev => ({
              ...prev,
              addresses:
                typeof addresses === 'function'
                  ? (addresses as (prevAddresses: Address[]) => Address[])(
                      prev.addresses,
                    )
                  : addresses,
            }))
          }
        />
        <Field
          name="notes"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <TextField
            multiline
            rows={3}
            data-testid="field"
            data-test-key="notes"
            name="notes"
            fullWidth
            variant="outlined"
            label={t('fields.notes')}
            value={customer.notes}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="emailOptOut"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            data-testid="field"
            data-test-key="emailOptOut"
            name="emailOptOut"
            value={!!customer.emailOptOut}
            onChange={handleChange}
            label={t('fields.emailOptOut')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
        <Field
          name="taxExempt"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            data-testid="field"
            data-test-key="taxExempt"
            name="taxExempt"
            value={!!customer.taxExempt}
            onChange={handleChange}
            label={t('fields.taxExempt')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
        <Field
          name="doNotSell"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            data-testid="field"
            data-test-key="doNotSell"
            name="doNotSell"
            value={!!customer.doNotSell}
            onChange={handleChange}
            label={t('fields.doNotSell')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
        <Field
          name="salesBlocked"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            id="salesBlocked"
            name="salesBlocked"
            value={!!customer.salesBlocked}
            onChange={handleChange}
            label={t('fields.salesBlocked')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
      </PluginComponent>
    </Grid>
  );
};

export default CompanyForm;
