import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrdersSelected } from 'reducers/returnProducts';
import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';
import { setOneOrderSelected } from 'actions/returnProducts';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';

import { getExchangeState } from '../rdx';

const UIReturnOrderProduct: PosPlugin['UIReturnOrderProduct'] = ({
  row,
  children,
  rowLocked,
  // isSelected,
  returnReasonID,
}) => {
  const dispatch = useDispatch();
  const selectedOrders = useSelector(getOrdersSelected);
  const returnReasons = useSelector(getReasonCodes(REASONS.RETURN));
  const currentConfig: any =
    useSelector(getPluginConfiguration('environmental-fee')) ?? {};
  const currentExchangeConfig: any =
    useSelector(state =>
      getPluginConfiguration('pnp-process-exchanges')(state),
    ) ?? {};

  const currentReturnIsExchange =
    useSelector(getExchangeState).exchangeStep > 0;

  const reason = currentReturnIsExchange
    ? currentExchangeConfig.selectedReturnReason
    : returnReasons[0]?.reasonID;

  useEffect(() => {
    if (
      row.itemName === currentConfig.envFeeName &&
      selectedOrders[row.stableRowID] === undefined
    ) {
      dispatch(
        setOneOrderSelected(row.stableRowID, {
          ...row,
          returnReasonID: reason,
          // selected: true,
        }),
      );
    }
  }, [row.itemName]);

  useEffect(() => {
    if (
      !selectedOrders[row.stableRowID] &&
      currentReturnIsExchange /* &&
      row.nonRefundable === 1 */
    ) {
      dispatch(
        setOneOrderSelected(row.stableRowID, {
          ...row,
          returnReasonID: reason,
          // selected: true,
        }),
      );
    } else {
      // const selectedForExchange =
      // selectedOrders[row.stableRowID]?.selected && currentReturnIsExchange;
      dispatch(
        setOneOrderSelected(row.stableRowID, {
          ...row,
          returnReasonID,
          // selected: currentReturnIsExchange ? !selectedForExchange : isSelected,
        }),
      );
    }
  }, [
    row.stableRowID,
    rowLocked,
    row.nonRefundable,
    currentReturnIsExchange,
    reason,
  ]);

  const defineRowToDisplay = () => {
    if (
      row.itemName.toUpperCase() === currentConfig?.envFeeName?.toUpperCase()
    ) {
      return null;
    }

    if (
      row.itemName.toUpperCase() ===
      currentConfig?.admissionTaxName?.toUpperCase()
    ) {
      return null;
    }

    return children;
  };

  return <>{defineRowToDisplay()}</>;
};

export default UIReturnOrderProduct;
