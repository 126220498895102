import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Fuse from 'fuse.js';

import FilterBlock from './FilterBlock';

import './DropdownSearch.sass';

const ToggleIcon = ({ show, action }) => (
  <span
    className={`filter-show-on-hover ${show ? 'show' : ''}`}
    onClick={action}
  >
    <span className="icon_menu pointer" />
  </span>
);

// Search should query by indexedDB indexes and cursors
const searchByCondition = (filter, productsToSearchFrom, text, keyValue) => {
  switch (filter) {
    case 'equals': {
      return productsToSearchFrom.filter(product => text === product[keyValue]);
    }
    case 'not-equals': {
      return productsToSearchFrom.filter(product => text !== product[keyValue]);
    }
    case 'starts': {
      return productsToSearchFrom.filter(product => {
        if (product[keyValue]) {
          return product[keyValue].startsWith(text);
        }
        return false;
      });
    }
    case 'ends': {
      return productsToSearchFrom.filter(product => {
        if (product[keyValue]) {
          product[keyValue].endsWith(text);
        }
        return false;
      });
    }
    case 'contains': {
      const options = {
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 2,
        keys: [keyValue],
      };
      const fuse = new Fuse(productsToSearchFrom, options);
      return fuse.search(text);
    }
    case 'not-contains': {
      return productsToSearchFrom.filter(
        product => !product[keyValue].includes(text),
      );
    }
    default:
      return productsToSearchFrom;
  }
};

const reduceResults = (allFilters, allProducts, callback) => {
  const reducedResults = allFilters.reduce((acc, currentValue) => {
    return searchByCondition(
      currentValue.condition,
      acc,
      currentValue.text,
      currentValue.key,
    );
  }, allProducts);
  return callback(reducedResults);
};

const DropdownSearch = ({
  show = false,
  setItems,
  keyForSearch,
  filters,
  setFilters,
}) => {
  const [showFilterBlock, toggleFilterblock] = useState(false);

  // Adds or removes selected filters
  const applyFilter = async options => {
    const addFilter = () => {
      if (keyForSearch === 'add') {
        return false;
      }
      return setFilters([
        ...filters,
        {
          key: keyForSearch,
          text: options.value,
          condition: options.filterCondition,
        },
      ]);
    };

    const removeFilter = () => {
      const newfilters = filters.filter(filter => filter.key !== keyForSearch);
      setFilters([...newfilters]);
    };

    if (filters.length) {
      // Checks if the filter has been already added
      const filterIncluded = filters.filter(
        filter => filter.key === keyForSearch,
      );

      if (filterIncluded.length && options.value !== '') {
        filterIncluded[0].text = options.value;
        return false;
      }
    }

    if (options.value !== '') {
      addFilter();
    } else {
      removeFilter();
    }

    return true;
  };

  // TODO - replace products with idnexDB products
  const products = [];

  return (
    <Dropdown show={showFilterBlock}>
      <Dropdown.Toggle as="div" bsPrefix="search-displayed">
        <ToggleIcon
          show={show}
          action={() => toggleFilterblock(!showFilterBlock)}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ padding: '.5em' }} className="cancel-draggable">
        <FilterBlock
          applyFilter={applyFilter}
          filter={() => reduceResults(filters, products, setItems)}
          // filters={filters}
          // reduceResults={reduceResults}
          // setItems={setItems}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSearch;
