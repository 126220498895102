import React, { useState, useEffect } from 'react';
import styles from './ageline.module.scss';

const Age = ({ age, diff, index }) => {
  const [color, setColor] = useState(age < 50 ? '#f1d9da' : '#d6eaef');
  const [borderColor, setBorderColor] = useState(
    age < 50 ? ' #ea5257' : '#94d4e4',
  );
  useEffect(() => {
    if (diff >= age) {
      setColor('#e3e8d0');
      setBorderColor('#a5c536');
    } else {
      setColor(age < 50 ? '#f1d9da' : '#d6eaef');
      setBorderColor(age < 50 ? ' #ea5257' : '#94d4e4');
    }
  }, [diff]);

  return (
    <div
      style={{
        backgroundColor: color,
        border: `3px solid ${borderColor}`,
        position: index === 3 && 'absolute',
        right: index === 3 && '0',
        marginLeft: (index === 2 || index === 1) && '3ch',
      }}
      className={styles['timeline-item']}
    >
      <div className={styles['timeline-content']}>{age}</div>
    </div>
  );
};

export default Age;
