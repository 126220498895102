import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import {
  AnswerWithExtraInput,
  AnswerWithExtraInputDict,
  CustomerFormFeedbackConfig,
  Question,
  RecordWithOrderAndIDProp,
  ValueOf,
} from './types';

/**
 * Takes a dict and returns the highest order prop value
 */
export const getHighestOrderPropValue = <T extends RecordWithOrderAndIDProp>(
  dict: T,
): number =>
  R.pipe(Object.values, R.map(R.prop('order')), R.reduce(R.max, 0))(dict);

/**
 * Turns dict into an array and sorts it by the order prop
 */
export const dictToArrayByOrder = <T extends RecordWithOrderAndIDProp>(
  dict: T,
): ValueOf<T>[] => R.pipe(Object.values, R.sortBy(R.prop('order')))(dict);

/**
 * Takes a dict, delletes it's member by given id, updates the order prop on each member
 */
export const deleteMemberOfDictAndUpdateOrderProp = <
  T extends RecordWithOrderAndIDProp
>(
  id: string,
  dict: T,
): T => {
  const setOrderBasedOnIndex = (val, index) => R.assoc('order', index + 1, val);
  const sortByOrder = R.sortBy(R.prop('order'));
  const updateOrderProp = R.addIndex(R.map)(setOrderBasedOnIndex);

  return R.pipe(
    R.dissoc(id),
    Object.values,
    sortByOrder,
    updateOrderProp,
    R.map(v => [v.id, v]),
    Object.fromEntries,
  )(dict);
};

/**
 * Takes a dict and moves its member by given id in the specified direction - up or down
 */
export const swapDictMembersByOrder = <T extends RecordWithOrderAndIDProp>(
  id: keyof T,
  dict: T,
  direction: 'up' | 'down',
): T => {
  const newDict = { ...dict };

  direction === 'up' ? (newDict[id].order -= 1) : (newDict[id].order += 1);
  Object.values(newDict).forEach(q => {
    if (newDict[id].order === q.order && q.id !== id) {
      if (direction === 'up') {
        newDict[q.id].order += 1;
      } else {
        newDict[q.id].order -= 1;
      }
    }
  });
  return newDict;
};

export const generateAttributeName = (qid: Question['id']) =>
  `pos_brazil_feedback_plugin_question_${qid}`;

type CustomerFormProps = Parameters<Required<PosPlugin>['UICustomerForm']>[0];
type CustomerFormBetaProps = Parameters<
  Required<PosPlugin>['UICustomerFormBeta']
>[0];
type Customer = CustomerFormProps['value'] | CustomerFormBetaProps['customer'];

/**
 * Given a set of answers for a question, saves the extraInput values of the answers in an attribute on the customer
 */
export const setCustomerQAnswers = (
  customer: Customer,
  question: Question,
  newAnswers: AnswerWithExtraInputDict,
) => {
  const attributeName =
    question.attributeName ?? generateAttributeName(question.id);

  const attributeValue = R.map(R.pick(['content', 'extraInput']), newAnswers);
  return R.assocPath(
    ['attributes', attributeName],
    JSON.stringify(attributeValue),
    customer,
  );
};

export const extractAnswerToState = (
  question: Question,
  attributes?: Customer['attributes'],
): Record<AnswerWithExtraInput['id'], AnswerWithExtraInput> => {
  try {
    const attributeName =
      question.attributeName ?? generateAttributeName(question?.id);
    const attributeValue = JSON.parse(attributes?.[attributeName] ?? '{}');
    const answers = R.mapObjIndexed(
      (answer: AnswerWithExtraInput, id: string) =>
        R.mergeDeepRight(question.answers[id], answer),
    )(attributeValue);
    return (
      answers ??
      ({} as Record<AnswerWithExtraInput['id'], AnswerWithExtraInput>)
    );
  } catch (err) {
    console.error('Failed to load answers from for question', question, err);
    return {} as Record<AnswerWithExtraInput['id'], AnswerWithExtraInput>;
  }
};

export const checkIfQuestionHasValidAnswers = (
  question: Question,
  answers: Record<AnswerWithExtraInput['id'], AnswerWithExtraInput>,
): boolean =>
  R.pipe(
    Object.keys,
    R.map((answerID: string) => answers?.[answerID]),
    R.reject(R.isNil),
    R.ifElse(
      R.isEmpty, // Question is not answered
      R.always(!question.required), // Valid if answer is not required
      R.all(
        (answer: AnswerWithExtraInput) =>
          !answer.needsInput || !!answer.extraInput,
      ),
    ),
  )(question.answers);

export const getQuestionsValidityMap = (
  questions?: CustomerFormFeedbackConfig['questions'],
  attributes?: Customer['attributes'],
) =>
  Object.fromEntries(
    dictToArrayByOrder(questions ?? {}).map(question => {
      const answers = extractAnswerToState(question, attributes);
      const hasValidAnswers = checkIfQuestionHasValidAnswers(question, answers);
      return [question.id, hasValidAnswers];
    }),
  );
