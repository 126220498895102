const rounding = {
  '0': v => Math.trunc(v),
  inf: v => Math.ceil(Math.abs(v)) * Math.sign(v),
  '+inf': v => Math.ceil(v),
  '-inf': v => Math.floor(v),
  even: v => 2 * Math.round(v / 2),
  odd: v => rounding.even(v + 1) - 1,
};
export type RoundingOptions = {
  /**
   * Whether the current sale has a positive total ('sale') or a negative total ('refund')
   */
  direction: 'sale' | 'refund';

  /** Whether we are rounding the payment/total or change/balance */
  type: 'payment' | 'change';
  // 0 - Round towards zero
  // inf - Round away from zero (towards either infinity)
  // +inf - Round up (towards positive infinity)
  // -inf - Round down (towards negative infinity)
  // even - Round to even
  // odd  - Round to odd
  /** The algorithm to use */
  algorithm: '0' | 'inf' | '+inf' | '-inf' | 'even' | 'odd';
  /**
   * If true, apply rounding algorithm to all values
   * If false, round to nearest if possible and only use algorithm as a tiebreaker
   */
  wholeRounding: boolean;
};
export const applyRounding = (
  value: number,
  {
    direction = 'sale',
    type = 'payment',
    wholeRounding = false,
    algorithm = 'inf',
  }: RoundingOptions,
) => {
  let algo = algorithm;

  // If invalid target specified, use away-from-zero
  if (!['0', 'inf', '+inf', '-inf', 'even', 'odd'].includes(algo)) {
    algo = 'inf';
  }
  switch (algo) {
    // For algorithms that need to know the direction of the total, calculate that now
    //
    // Rounding based only on the value-to-be-rounded would cause inconsistencies when rounding the balance
    // For example:
    //    ROUND TOWARDS INFINITY
    //   Paid      Total    To pay   100% cash button
    //   5.00      21.15     16.15              16.20
    //  21.20      21.15     -0.05              -0.10 // Expect after adding 100% cash for this to be zero!!
    case '0':
      algo = direction === 'sale' ? '-inf' : '+inf';
      break;
    case 'inf':
      algo = direction === 'sale' ? '+inf' : '-inf';
      break;

    // For rounding towards odd, calculating the change needs to be done rounding-to-even
    // This is because the payment itself was rounded to odd, which flips the parity of the balance
    // For example:
    //   ROUND TOWARDS ODD
    //   Paid      Total    To pay   100% cash button
    //   5.00      21.15     16.15              16.10
    //  21.10      21.15      0.05               0.10 // Expect after adding 100% cash for this to be zero!!
    //
    // Mathematically, round-towards-odd always creates an odd number, and by adding the odd number to paid,
    // it always switches the parity of the 'to pay' amount.
    // Therefore what used to be correct in round-to-odd should *now* be correct round-to-even
    case 'odd':
      algo = type === 'payment' ? 'odd' : 'even';
      break;
  }

  const fraction = Math.abs(Math.abs(value) % 1);
  const distanceFromWhole = Math.min(fraction, 1 - fraction);
  const distanceFromHalf = 0.5 - distanceFromWhole;
  const isWhole = distanceFromWhole < 1e-4;
  const isHalf = distanceFromHalf < 1e-4;
  // If already on a whole value, do not round (important for odd/even rounding that this check is first!)
  if (isWhole) return Math.round(value);

  // Otherwise, round always with the algorithm if 'whole' rounding
  if (wholeRounding) return rounding[algo](value);
  // Or only use it for tiebreakers if 'half' rounding
  return isHalf
    ? rounding[algo](value) // use algorithm for tiebreakers
    : Math.round(value); // but otherwise round to the closest value by default
};
