/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import List from 'components/List';
import QuestionMark from 'components/QuestionMark';
import { isString } from 'utils';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getFunctionButtons } from 'reducers/UI/gridButtons';
import { GridButton } from 'components/GridButton';

import styles from './index.module.scss';

const actionTypes = {
  page: 'page',
  popup: 'popup',
  action: 'action',
};

const FuncItem = ({ item, beforeSelect = null }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const close = () => setShow(false);
  let action;
  useShortcut(item.hotkey, item.action);

  switch (item.actionType) {
    case actionTypes.page:
      action = () => {
        if (item.modalProps.component) {
          dispatch(openModalPage({ ...item.modalProps }));
        }
      };
      break;
    case actionTypes.popup:
      action = () => setShow(true);
      break;
    case actionTypes.action:
      action = () => item.action && dispatch(item.action);
      break;
    default:
      action = item.action;
      break;
  }

  const name = item.trans ?? item.name;
  const key = useMemo(
    () =>
      item.id
        ? item.id
        : Object.keys(item).length
        ? 'function-extend-btn'
        : 'empty-btn',
    [item],
  );

  const onClick = () => {
    if (beforeSelect) {
      beforeSelect();
      return action();
    }
    action();
  };
  return (
    <GridButton
      key={item.name}
      title={isString(name) ? name : undefined}
      onClick={() => onClick()}
      btnColor={item.type === 'special' ? 'grid_green' : 'grid_dark'}
      disabled={(!item.action && !item.trans) || item.disabled}
      data-testid="function-button"
      data-test-key={key}
    >
      {(item.action || item.trans) && (
        <QuestionMark
          code={item.code}
          position={{ top: '3px', right: '3px' }}
          color="#666"
          data-testid="function-question-mark"
        />
      )}
      {item.hotkey ? (
        <span data-testid="function-hotkey" className={styles.hotkey}>
          {item.hotkey}
        </span>
      ) : null}
      <span
        data-testid="function-badge"
        className={
          item.badge && item.badge.split ? styles.badgeText : styles.badge
        }
      >
        {item.badge}
      </span>
      {item.icon ? (
        <div
          data-testid="function-icon-container"
          className={styles.iconButton}
        >
          <span />
          <Icon data-testid="function-icon" name={item.icon} />
          <QuestionMark
            code={item.code}
            position={{ top: '3px', right: '3px' }}
            color="#666"
            data-testid="function-icon-questionmark"
          />
          <span data-testid="function-name">{name}</span>
        </div>
      ) : item.view ? (
        item.view(show, close)
      ) : (
        <span data-testid="function-name">{name}</span>
      )}
    </GridButton>
  );
};

const Functions = props => {
  const buttons = useSelector(getFunctionButtons);

  const { t } = useTranslation('gridButtons');

  const buttonsWithTranslations = useMemo(
    () =>
      buttons
        .filter(({ hide }) => !hide)
        .map(button => ({
          ...button,
          trans: t(`functions.${button.id}`, button.name),
        })),
    [buttons, t],
  );

  return (
    <List
      items={buttonsWithTranslations}
      itemComponent={<FuncItem />}
      {...props}
    />
  );
};

export default Functions;
