import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import { SET_CUSTOMER_DATA } from "../rdx/actionTypes";

const GarminForm = ({ resolveModal }: { resolveModal: () => void }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('fiscalIntegration');

    const [rutReceiver, setRut] = useState("");
    const [validRut, toggleValidRut] = useState(false);

    const [recipientName, setName] = useState("");
    const [recipientDraft, setCompanyName] = useState("");
    const [recipientBillingAddress, setStreet] = useState("");
    const [recipientBillingCommune, setTown] = useState("");
    const [recipientBillingCity, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [glosa, setGlosa] = useState("");


    const defineErrorMessage = () => {
        if (!rutReceiver.length) {
            return <Form.Text style={{ color: "red" }}>
                {t("requiredField")}
            </Form.Text>
        }

        if (!validRut) {
            return <Form.Text style={{ color: "red" }}>
                {t("invalidvalue")}
            </Form.Text>
        }

        return null
    }

    const enableButton = (): boolean => {
        if (
            validRut &&
            recipientName.length &&
            recipientDraft.length &&
            recipientBillingAddress.length &&
            recipientBillingCommune.length &&
            recipientBillingCity.length) {
            return true
        }

        return false
    }

    return (

        <Form>
            <Form.Group controlId="RUT">
                <Form.Label>{t("RUT")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 10) {
                        const isValid = /^\d{7,8}-[\dkK]$/gm.test(event.target.value);
                        toggleValidRut(isValid);
                        setRut(event.target.value)
                    }
                }} value={rutReceiver} />
                {defineErrorMessage()}
            </Form.Group>

            <Form.Group controlId="name">
                <Form.Label>{t("Name")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 100) {
                        setName(event.target.value)
                    }
                }} value={recipientName} />
                {!recipientName.length ? <Form.Text style={{ color: "red" }}>
                    {t("requiredField")}
                </Form.Text> : null}
            </Form.Group>

            <Form.Group controlId="companyName">
                <Form.Label>{t("receivingCompanyName")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 40) {
                        setCompanyName(event.target.value)
                    }
                }} value={recipientDraft} />
                {!recipientDraft.length ? <Form.Text style={{ color: "red" }}>
                    {t("requiredField")}
                </Form.Text> : null}
            </Form.Group>

            <Form.Group controlId="street">
                <Form.Label>{t("billingStreet")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 70) {
                        setStreet(event.target.value)
                    }
                }} value={recipientBillingAddress} />
                {!recipientBillingAddress.length ? <Form.Text style={{ color: "red" }}>
                    {t("requiredField")}
                </Form.Text> : null}
            </Form.Group>

            <Form.Group controlId="town">
                <Form.Label>{t("billingTown")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 40) {
                        setTown(event.target.value)
                    }
                }} value={recipientBillingCommune} />
                {!recipientBillingCommune.length ? <Form.Text style={{ color: "red" }}>
                    {t("requiredField")}
                </Form.Text> : null}
            </Form.Group>

            <Form.Group controlId="city">
                <Form.Label>{t("billingCity")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 20) {
                        setCity(event.target.value)
                    }
                }} value={recipientBillingCity} />
                {!recipientBillingCity.length ? <Form.Text style={{ color: "red" }}>
                    {t("requiredField")}
                </Form.Text> : null}
            </Form.Group>

            <Form.Group controlId="phone">
                <Form.Label>{t("phone")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 20) {
                        const isValid = /^\d*$/gm.test(event.target.value);
                        if (isValid) {
                            setPhone(event.target.value)
                        }
                    }
                }} value={phone} />
                {!phone.length ? <Form.Text style={{ color: "red" }}>
                    {t("requiredField")}
                </Form.Text> : null}
            </Form.Group>

            <Form.Group controlId="glosa">
                <Form.Label>{t("glosa")}</Form.Label>
                <Form.Control type="text" onChange={event => {
                    if (event.target.value.length <= 20) {
                        setGlosa(event.target.value)
                    }
                }} value={glosa} />
                {!glosa.length ? <Form.Text style={{ color: "red" }}>
                    {t("requiredField")}
                </Form.Text> : null}
            </Form.Group>

            <Button
                disabled={!enableButton()}
                onClick={() => {
                    dispatch({
                        type: SET_CUSTOMER_DATA, payload: {
                            rutReceiver,
                            recipientName,
                            recipientDraft,
                            recipientBillingAddress,
                            recipientBillingCommune,
                            recipientBillingCity,
                            fono: phone,
                            glosa
                        }
                    });
                    resolveModal();
                }}
                variant="primary"
                size="lg"
                block>
                {t("submit")}
            </Button>
        </Form>
    )
}

export default GarminForm;