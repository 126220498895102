import { Configuration } from './types';

export const pluginID = 'endless-aisle';

export const defaultValues: Configuration = {
  clientSecret: '',
  groupID: '',
  password: '',
  username: '',
};

export const ENDLESS_AISLE_CONSTANTS = {
  FLUENT_ACCOUNT: 'RCG',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
} as const;

export const END_POINTS = {
  TAF_SERVER_NZ: (productID: number) =>
    `https://7hc4qkofk8.execute-api.ap-southeast-2.amazonaws.com/prodnz/stock-check/${productID}`,
  TAF_SERVER_AU: (productID: number) =>
    `https://lmraxxuvn0.execute-api.ap-southeast-2.amazonaws.com/prodau/fluent-stock-check/${productID}`,
  GET_SESSION_TOKEN: ({
    username,
    password,
    clientSecret,
  }: Omit<Configuration, 'groupID'>) =>
    `https://rcg.sandbox.api.fluentretail.com/oauth/token?username=${username}&password=${password}&scope=api&client_id=${ENDLESS_AISLE_CONSTANTS.FLUENT_ACCOUNT}&client_secret=${clientSecret}&grant_type=password`,
} as const;

export const DEFAULT_NOTES = 'Endless Aisle';
export const EA_SALE = 'EA Sale';

export const MIN_TOTALS = {
  AU: 129.99,
  NZ: 119.99,
} as const;

/** Minimum global stock for Endless Aisle functionality - When stock is below this amount, EA is disabled */
export const EA_MIN_STOCK = 6;
