import { getPaymentTypes } from 'services/ErplyAPI';
import * as actionTypes from 'constants/Payments';
import { savePaymentType } from 'services/ErplyAPI/payment';

// eslint-disable-next-line import/prefer-default-export
export function getPaymentTypesAction(params) {
  return async dispatch => {
    const payload = await getPaymentTypes(params).then(data => data);
    dispatch({
      type: actionTypes.GET_PAYMENT_TYPES,
      payload,
    });
  };
}

export function createPaymentType(params = {}) {
  return async dispatch => {
    await savePaymentType({ ...params });
    dispatch(getPaymentTypesAction());
  };
}
