import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';

import { getClockedInEmployees } from 'services/AccountAdmin';
import { PosPlugin } from 'plugins/plugin';
import EmployeeSelectField from 'containers/Forms/clockIn/SelectField';
import { reactcursively } from 'utils/react';

const ClockIn: PosPlugin['UIClockIn'] = ({
  children,
  employeeToLogin,
  onChangeEmployee,
}) => {
  const { t } = useTranslation('clockInOut');

  const { value = [] } = useAsync(getClockedInEmployees, []);

  return reactcursively(
    R.when(
      R.both(
        R.pathEq(['props', 'data-testid'], 'input'),
        R.pathEq(['props', 'data-test-key'], 'employee'),
      ),
      R.always(
        <EmployeeSelectField
          label={t('fields.employee')}
          value={employeeToLogin}
          options={value}
          style={{ marginBottom: '10px' }}
          onChange={(e, chosenEmployee) => {
            e.preventDefault();
            onChangeEmployee(chosenEmployee);
          }}
        />,
      ),
    ),
  )(children);
};

export default ClockIn;
