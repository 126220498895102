/* eslint-disable no-console */
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import * as customerActionsTypes from 'constants/customerSearch';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import * as campaignsApi from 'services/ErplyAPI/campaigns';
import { RootState } from 'reducers';

export function setCustomerIssuedCoupons({ customerID }) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    dispatch({ type: customerActionsTypes.SET_CUSTOMER_ISSUED_COUPONS.START });
    try {
      const issuedCoupons = getIsDefaultCustomer(getState())
        ? []
        : await campaignsApi.getIssuedCoupons({
            issuedCustomerID: customerID,
            recordsOnPage: 100,
            status: 'ACTIVE',
          });
      dispatch({
        type: customerActionsTypes.SET_CUSTOMER_ISSUED_COUPONS.SUCCESS,
        data: issuedCoupons,
      });
    } catch (error) {
      console.error(
        'Unable to load issued coupons for customer',
        customerID,
        error,
      );
      dispatch({
        type: customerActionsTypes.SET_CUSTOMER_ISSUED_COUPONS.ERROR,
        error,
      });
    }
  };
}
