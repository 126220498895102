/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-children-prop */
import React, { useContext } from 'react';

import {
  SelectField,
  TextField,
  Checkbox,
  TextArea,
  Action,
} from 'containers/Forms/Settings/components/Inputs';
import { defaultSettings } from '../../../../reducers/configs/defaultSettings';

/**
 * @fileoverview
 * A bunch of inputs that are capable of taking their values and onChange from a provided context
 * This allows for long forms to be DRY without having the fields declared elsewhere
 *
 * These inputs extend the inputs in ./Inputs
 * @example
 * // With ctx inputs
 * const MyComponent = () => {
 *    const onChange = ...
 *    const values = useState({...});
 *
 *    return (<Ctx.Provider values={values} onChange={onChange}>
 *      <Check name='useHydrogen' title='Use hydrogen'/>
 *      <Check name='useOxygen' title='Use oxygen'/>
 *      <Check name='useHeat' title='Use heat'/>
 *      <Select name='mixType' title='Mixing method' options={[
 *              { value: 'surface_wind', name: 'Surface wind', },
 *              { value: 'spool',        name: 'Mix with hermetic spool', },
 *              { value: 'centrifuge',   name: 'Centrifuge', },
 *            ]}
 *      />
 *    </>)
 * }
 *
 * // Without ctx inputs
 * const MyComponent = () => {
 *    const onChange = ...
 *    const values = useState({...});
 *
 *    return (<>
 *      <Check name='useHydrogen'
 *          title='Use hydrogen'
 *          values={values}
 *          onChange={onChange} />
 *      <Check name='useOxygen'
 *          title='Use oxygen'
 *          values={values}
 *          onChange={onChange} />
 *      <Check name='useHeat'
 *          title='Use heat'
 *          values={values}
 *          onChange={onChange} />
 *      <Select name='mixType'
 *          title='Mixing method'
 *          values={values}
 *          onChange={onChange}
 *          options={[
 *              { value: 'surface_wind', name: 'Surface wind', },
 *              { value: 'spool',        name: 'Mix with hermetic spool', },
 *              { value: 'centrifuge',   name: 'Centrifuge', },
 *            ]}/>
 *    </>)
 * }
 *
 */

/**
 * The context that all ctxInputs are expecting to receive
 * This must be provided or ctxInputs will not work
 * @type React.Context<{
 *    values: any,
 *    onChange: (k,v) => any,
 *    getError?: (field: string) => void,
 *    setError?: (field: string, error: string) => void,
 *    removeError?: (field: string) => void,
 *    inputProps?: {[name: string]: {[propName:string]: any}},
 *    sharedProps?: {[propName:string]: any}
 * }>
 */
export const Ctx = React.createContext({
  /** @type (key: string, value: any) => void */
  onChange: () => console.error('CTX input used but context not provided'),
  values: defaultSettings,
  getError: (n) => '',
  setError: (n, v) => {},
  removeError: (n) => {},
  sharedProps: {},
  inputProps: {},
});
/**
 * A select input that gets values and onChange from context
 * @param props
 * @param props.name The name is used as the key to get the value for this input out of context.values
 * @param props.prependTitle A title to display before the input - if this is specified then title displays normally inside the input
 * @see SelectField
 */
export const Select = ({ name, prependTitle = false, ...rest }) => {
  const {
    onChange,
    values,
    getError,
    inputProps = {},
    sharedProps = {},
  } = useContext(Ctx);
  return (
    <SelectField
      onChange={onChange}
      value={values[name]}
      isInvalid={!!getError?.(name)}
      name={name}
      prependTitle={prependTitle}
      data-testid="ctxinput-select"
      data-test-key={name}
      {...sharedProps}
      {...inputProps[name]}
      {...rest}
    />
  );
};

/**
 * A text input that gets values and onChange from context
 * @param props
 * @param props.name The name is used as the key to get the value for this input out of context.values
 * @param props.prependTitle A title to display before the input - if this is specified then title displays normally inside the input
 * @extends TextField
 */
export const Text = ({
  name,
  title,
  formatter = (newVal, oldVal) => newVal,
  prependTitle = false,
  default: def = '',
  ...rest
}) => {
  const {
    onChange,
    values,
    getError,
    inputProps = {},
    sharedProps = {},
  } = useContext(Ctx);

  return (
    <TextField
      onChange={onChange}
      value={values[name] === undefined ? def : values[name]}
      isInvalid={!!getError?.(name)}
      name={name}
      title={title}
      formatter={formatter}
      prependTitle={prependTitle}
      data-testid="ctxinput-text"
      data-test-key={name}
      {...sharedProps}
      {...inputProps[name]}
      {...rest}
    />
  );
};

/**
 * A checkbox input that gets values and onChange from context
 * @param props
 * @param props.name The name is used as the key to get the value for this input out of context.values
 * @param props.prependTitle A title to display before the input - if this is specified then title displays normally inside the input
 * @extends Checkbox
 */
export const Check = ({
  name,
  invert = false,
  title = '',
  customCallback = undefined,
  default: def = false,
  ...rest
}) => {
  const {
    onChange,
    values,
    getError,
    inputProps = {},
    sharedProps = {},
  } = useContext(Ctx);
  const value = !!(values[name] ?? def);
  return (
    <Checkbox
      onChange={(key, value) => onChange(key, invert ? !value : value)}
      value={invert ? !value : value}
      isInvalid={!!getError?.(name)}
      name={name}
      children={[title]}
      customCallback={customCallback}
      data-testid="ctxinput-checkbox"
      data-test-key={name}
      {...sharedProps}
      {...inputProps[name]}
      {...rest}
    />
  );
};

/**
 * A multiline text input that gets values and onChange from context
 * @param props
 * @param props.name The name is used as the key to get the value for this input out of context.values
 * @param props.prependTitle A title to display before the input - if this is specified then title displays normally inside the input
 * @extends TextArea
 */
export const LongText = ({ name, title, value, ...rest }) => {
  const {
    onChange,
    values,
    getError,
    inputProps = {},
    sharedProps = {},
  } = useContext(Ctx);
  return (
    <TextArea
      title={title}
      name={name}
      value={values[name]}
      isInvalid={!!getError?.(name)}
      onChange={onChange}
      data-testid="ctxinput-textarea"
      data-test-key={name}
      {...sharedProps}
      {...inputProps[name]}
      {...rest}
    />
  );
};

/**
 * A full width button
 * @param props
 * @param props.name The name is used as the key to get the value for this input out of context.values
 * @param props.prependTitle A title to display before the input - if this is specified then title displays normally inside the input
 * @extends Action
 */
export const Button = ({ name, title, onClick, ...rest }) => {
  return (
    <Action
      title={title}
      name={name}
      onClick={onClick}
      data-testid="ctxinput-button"
      data-test-key={name}
      {...rest}
    />
  );
};
