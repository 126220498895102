import { FUNC_BUTTONS } from 'paymentIntegrations/types';

import { checkTransactionStatus } from '../requests/adyenRequests';
import { creationOfAdyenPatchscript } from '../printing';

const checkAdyenTransactionStatus = params => async () => {
  const {
    updateMessage,
    enableButtons,
    beforeDocSave,
    displayWarning,
    adyenConfig,
    printReceipt,
    cardPayments,
    resolvePayments,
    employeeID,
  } = params;

  try {
    const statusResponse = await checkTransactionStatus(employeeID);
    const {
      resultStatus,
      resultCode,
      additionalData,
      statusMessage,
    } = statusResponse;

    const { approvedAmount, cardType, cardNumber, referenceNumber } =
      additionalData ?? {};

    // dependinig on the terminal, some return statusMessage, others resultStatus
    const messageText = statusMessage || resultStatus;
    updateMessage(messageText);

    if (Number(resultCode) === 0) {
      // find the key of a payment that matches
      // filter out payments with different amounts
      const sameAmountPayments = cardPayments.filter(
        p => p.amount === approvedAmount,
      );

      if (messageText.toUpperCase() === 'CANCEL') {
        return enableButtons([
          FUNC_BUTTONS.CHECK_STATUS,
          FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
          FUNC_BUTTONS.RETRY,
          FUNC_BUTTONS.CLOSE,
        ]);
      }

      if (
        messageText.toUpperCase().includes('NOTFOUND') ||
        messageText.toUpperCase().includes('NOT FOUND')
      ) {
        return enableButtons([FUNC_BUTTONS.CHECK_STATUS, FUNC_BUTTONS.RETRY]);
      }

      if (
        messageText.toUpperCase().includes('UNCOMPLETED TRANSACTION') ||
        messageText.toUpperCase().includes('INPROGRESS')
      ) {
        return enableButtons([FUNC_BUTTONS.CHECK_STATUS]);
      }

      if (messageText === 'Success') {
        const { key } = sameAmountPayments.find(p => !p.paid);
        await beforeDocSave({
          key,
          type: 'CARD',
          amount: approvedAmount,
          cardType,
          cardNumber: cardNumber.slice(-4),
          // signature: signature ? base64String : null,
          attributes: {
            refNo: referenceNumber,
          },
          paid: true,
        });

        if (adyenConfig.usePOSGeneralPrinter) {
          const parsedAdditionalData = JSON.parse(
            additionalData.additionalData,
          );
          const { receipts } = parsedAdditionalData;

          const receiptsPatchscript = creationOfAdyenPatchscript(receipts);
          printReceipt(receiptsPatchscript);
        }

        updateMessage('Payment successful');
        return resolvePayments();
      }
      return enableButtons([FUNC_BUTTONS.CHECK_STATUS]);
    }
  } catch (error) {
    console.error('Failed to get transaction status', error);
    enableButtons([FUNC_BUTTONS.CHECK_STATUS]);
    updateMessage(error.message);
    displayWarning(error.message);
    return false;
  }
};

export default checkAdyenTransactionStatus;
