import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { commitTempSettings, dropTempSettings } from 'actions/configs';
import { getGeneralCustomerSettings } from 'reducers/cafaConfigs';
import { getTempSettings } from 'reducers/configs/settings';
import { CAFA_LEVELS, INTEGRATION_TYPES, INTEGRATIONS } from 'constants/CAFA';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import { integer, positive } from 'components/FieldTypes/formatters';
import { Page } from 'containers/Forms/Settings/components/Inputs';
import { modalPages } from 'constants/modalPage';
import {
  Ctx,
  Check,
  Select,
  Text,
} from 'containers/Forms/Settings/components/CtxInputs';

import { useSettings } from '../../hooks/useSettings';

import CustomerFormConfiguration from './ConfigurableCustomerForm';

const useCustomerFormConfig = () => {
  const configuration = useSelector(getGeneralCustomerSettings);
  const [customerConfig, setCustomerConfig] = useState(
    configuration.customer_form_configuration,
  );

  useEffect(() => {
    setCustomerConfig(configuration.customer_form_configuration);
  }, [configuration.customer_form_configuration]);

  return [customerConfig, setCustomerConfig] as const;
};

const Customer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsCustomer');
  const { t: t2 } = useTranslation('settingsConfiguration');

  const configuration = useSelector(getGeneralCustomerSettings);
  const tempSettings = useSelector(getTempSettings);

  const [customerConfig, setCustomerConfig] = useCustomerFormConfig();

  const { values, onChange, changed } = useSettings();

  const newConfiguration = useMemo(
    () =>
      R.mergeAll([
        configuration,
        // eslint-disable-next-line @typescript-eslint/camelcase
        { customer_form_configuration: customerConfig },
      ]),
    [configuration, customerConfig],
  );

  const onClose = () => {
    dispatch(dropTempSettings(Object.keys(changed)));
    dispatch(previousModalPage());
  };

  const onSave = async () => {
    await dispatch(
      saveCafaConfig({
        integrationName: INTEGRATION_TYPES.posConfigurations,
        integrationType: INTEGRATIONS.posConfigurations.customer,
        level: { level: CAFA_LEVELS.Company, id: undefined },
        config: newConfiguration,
      }),
    );
    if (!R.isEmpty(tempSettings))
      await dispatch(
        commitTempSettings(
          Object.keys(changed).concat([
            'pos_brazil_use_customer_creation_beta',
          ]),
        ),
      );
    onClose();
  };

  const disableSaveSettings = useMemo(
    () => R.isEmpty(tempSettings) && R.equals(newConfiguration, configuration),
    [configuration, newConfiguration, tempSettings],
  );

  return (
    <div className="erply-settings" style={{ overflowY: 'auto' }}>
      <Modal.Header>
        <span>Customer Settings</span>
        <span style={{ flexGrow: 1 }} />
        <SaveButton
          action={onSave}
          disabled={disableSaveSettings}
          variant="POS"
        />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <CustomerFormConfiguration
          config={customerConfig}
          updateConfig={setCustomerConfig}
        />
        <Ctx.Provider
          value={useMemo(() => ({ values, onChange }), [values, onChange])}
        >
          <Check
            name="touchpos_display_notes_popup"
            title={t('touchposDisplayNotesPopup')}
          />
          <Check
            name="touchpos_prefill_home_store_during_customer_creation"
            title={t('touchposPrefillHomeStoreDuringCustomerCreation')}
            errorText={t('hints.homeStoreDuringCustomerCreation')}
          />
          <Check
            name="pos_hide_add_credit_store_from_customer_view"
            title={t('posHideAddCreditStoreFromCustomerView')}
          />
          <Select
            defaultValue="disabled"
            name="pos_customer_search_filter"
            title={t('posCustomerSearchFilter')}
            options={useMemo(() => {
              const key = 'posCustomerSearchFilter';
              const values = ['disabled', 'byHomeStore', 'bySignupStore'];
              return values.map(opt => ({
                name: t(key, { context: opt }),
                value: opt,
              }));
            }, [t])}
          />
          <Text
            title={t('customerSearchMinLength')}
            name="customer_search_min_length"
            placeholder="3"
            // @ts-ignore
            formatter={useCallback(integer.and(positive), [integer, positive])}
          />
          <Check
            name="touchpos_display_id_in_customer_details"
            title={t2('touchposDisplayIdInCustomerDetails')}
          />
        </Ctx.Provider>
        <Page
          title={t('buttons.customerSearchResults')}
          value={modalPages.CustomerSearchResults}
        />
      </Modal.Body>
    </div>
  );
};

export default Customer;
