import { combineReducers } from 'redux';

import { settings, tempSettings, objects } from './settings';
import integrations from './integrations';
import currency from './currency';
import serviceEnpoints from './serviceEnpoints';
import marketplace from './marketplace';

export default combineReducers({
  currency,
  settings,
  tempSettings,
  integrations,
  objects,
  serviceEnpoints,
  marketplace,
});
