import * as R from 'ramda';

import { getClientCode } from 'reducers/Login';
import { PosPlugin } from 'plugins/plugin';
import { getCurrentSalesDocument } from 'reducers/sales';
import { ErplyAttributes } from 'utils';

import { getCFRState } from '../rdx';

import { generateOriginalInvoiceAttribute } from './utils';

const setSalesDocumentAttributes: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, requests) => async (
  dispatch,
  getState,
) => {
  const state = getState();

  const { isOnlineReturn, canPerformCFRReturn } = getCFRState(state);

  if (!isOnlineReturn) return requests;

  const clientCode = getClientCode(state);
  const currentSalesDocument = getCurrentSalesDocument(state);
  const salesDocID = currentSalesDocument.id;

  return requests.map(request => {
    if (request.requestName !== 'saveSalesDocument') return request;
    const sdocNoAttrs = ErplyAttributes.withoutFlatArray(request);
    const previousAttributes = ErplyAttributes.fromFlatArray(request);
    const hasOriginalInvoiceNumber = previousAttributes.get(
      'original-invoice-number',
    );

    const originalInvoiceID = generateOriginalInvoiceAttribute(
      clientCode,
      salesDocID,
      !!canPerformCFRReturn,
    );
    previousAttributes.set('original-invoice-id', originalInvoiceID);

    if (!hasOriginalInvoiceNumber) {
      previousAttributes.set(
        'original-invoice-number',
        currentSalesDocument.number,
      );
    }

    return R.mergeDeepRight(sdocNoAttrs, previousAttributes.asFlatArray);
  });
};

export default setSalesDocumentAttributes;
