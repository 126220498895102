import React, { useState } from 'react';
import * as R from 'ramda';
import { Box, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import UIButton from 'components/UIElements/UIButton';
import { PaymentTypeInput } from 'components/inputs/paymentTypeInput';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { PaymentType } from 'types/PaymentType';

import { pluginId } from './constants';

export type Conf = {
  cancellationFeePaymentType: PaymentType['id'];
  cancellationFeePercentage: number;
  cancellationFeeType: 'total' | 'paid' | 'unpaid';
};

export const combineConfiguration: PosPlugin<
  Conf
>['combineConfiguration'] = c =>
  R.mergeDeepLeft(c)({
    cancellationFeePaymentType: '-1',
    cancellationFeePercentage: -1,
    cancellationFeeType: 'paid',
  });

const getConf = getPluginConfiguration<Conf>(pluginId);

export const getCancellationFeePaymentTypeId = createSelector(
  getConf,
  conf => conf.cancellationFeePaymentType,
);
export const getCancellationFeePaymentType = createSelector(
  getCancellationFeePaymentTypeId,
  getAllPaymentTypes,
  (paymentTypeId, pt) => pt.find(t => t.id === paymentTypeId),
);
export const getCancellationFeeType = createSelector(
  getConf,
  conf => conf.cancellationFeeType,
);
export const getCancellationFeePercentage = createSelector(
  getConf,
  conf => conf.cancellationFeePercentage,
);

export const Configuration = ({ save }) => {
  // Using the selector so that combineConfiguration would apply correctly
  // otherwise the 'current' passed in by PluginConfiguration will not include the default values and they would have to be duplicated here
  // TODO: Update PluginConfiguration component to use `.combineConfiguration` too
  const current = useSelector(getConf);
  const [id, setId] = useState(current.cancellationFeePaymentType);
  const [percentage, setPercentage] = useState(
    R.ifElse(
      v => v < 0,
      R.always(''),
      v => String(v),
    )(current.cancellationFeePercentage),
  );
  const [type, setType] = useState(current.cancellationFeeType);

  const noChanges = [
    id === current.cancellationFeePaymentType,
    Number(percentage) === Number(current.cancellationFeePercentage),
    type === current.cancellationFeeType,
  ].every(a => a);

  const isValid = [
    0 < Number(id),
    0 < Number(percentage) && Number(percentage) < 100,
    ['total', 'paid', 'unpaid'].includes(type),
  ].every(a => a);

  const doSave = () =>
    save({
      cancellationFeePaymentType: id,
      cancellationFeePercentage: Number(percentage),
      cancellationFeeType: type,
    });

  return (
    <>
      <Box display="flex" justifyContent="end">
        <UIButton
          disabled={noChanges || !isValid}
          text="save"
          action={doSave}
        />
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        Charge the customer by
        <TextField
          type="number"
          size="small"
          variant="outlined"
          style={{ width: '7rem', margin: '0 8px' }}
          value={percentage}
          onChange={e => setPercentage(e.target.value)}
          InputProps={{
            inputProps: { style: { textAlign: 'right' } },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        of the
        <TextField
          select
          size="small"
          variant="outlined"
          style={{ margin: '0 8px' }}
          value={type}
          onChange={e => setType(e.target.value as 'paid' | 'unpaid' | 'total')}
        >
          <MenuItem value="paid">Paid</MenuItem>
          <MenuItem value="unpaid">Unpaid</MenuItem>
          <MenuItem value="total">Total</MenuItem>
        </TextField>
        amount, and record the extra charge as a payment of type
        <Box marginLeft="8px">
          <PaymentTypeInput
            size="small"
            variant="outlined"
            value={id ?? '-1'}
            onChange={e => setId(e.target.value)}
            onlyCustom
          />
        </Box>
      </Box>
    </>
  );
};
