/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import i18next from 'i18next';
import * as Sentry from '@sentry/browser';
import { Box, Collapse, Typography, Button, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import * as types from 'constants/CAFA';
import * as api from 'services/CAFA';
import { getSelectedPos, getSelectedPosID } from 'reducers/PointsOfSale';
import {
  getActiveIntegrationsConfig,
  getCafaEntry,
  getIsLoadingCafa,
} from 'reducers/cafaConfigs';
import { getUserLoggedIn } from 'reducers/Login';
import { REDUX_APP_NAME } from 'constants/persistence';
import { createConfirmation } from 'actions/Confirmation';
import { openManagerOverride } from 'containers/Forms/ManagerOverride/actions';
import { getRightsForUserID } from 'actions/Login';
import { waitForCondition } from 'utils';

import { addError, addWarning, dismissType } from '../Error';

const BigText = styled(Typography)({
  fontSize: '1.1rem',
});

// TODO move out of actions - no components in action files
function CafaErrorDetails({ failedConfigs }) {
  const { t } = useTranslation('cafa', { keyPrefix: 'cafaPopup' });
  const [show, setShow] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="0.75rem"
      alignItems="flex-start"
    >
      <BigText>{t('info', { context: 'main' })}</BigText>
      <BigText>{t('info', { context: 'reload' })}</BigText>
      <BigText>{t('info', { context: 'proceed' })}</BigText>
      <BigText>{t('info', { context: 'help' })}</BigText>
      <Button
        onClick={() => setShow(prev => !prev)}
        variant="text"
        startIcon={show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        disableRipple
      >
        {t('details')}
      </Button>
      <Collapse in={show} unmountOnExit>
        {failedConfigs.map((config, idx) => (
          <Typography key={config.reason.config.params.level}>
            {t('error', {
              index: idx + 1,
              level: config.reason.config.params.level,
              reason:
                config.reason.response?.data?.message ?? config.reason.message,
            })}
          </Typography>
        ))}
      </Collapse>
    </Box>
  );
}

export function makeActions(type) {
  return {
    start: () => ({ type: type.START }),
    success: payload => ({ type: type.SUCCESS, payload }),
    error: payload => ({ type: type.ERROR, payload }),
  };
}

export const fetchCafaConfigAction = makeActions(types.FETCH_CAFA_CONFIGS);

export const saveCafaConfigAction = makeActions(types.SAVE_CAFA_CONFIGS);

function handleMissingConfigs(failedConfigs) {
  return async dispatch => {
    try {
      await i18next.loadNamespaces('cafa');
      await new Promise((resolve, reject) =>
        dispatch(
          createConfirmation(resolve, reject, {
            title: i18next.t('cafa:cafaPopup.title'),
            body: <CafaErrorDetails failedConfigs={failedConfigs} />,
            confirmText: i18next.t('cafa:cafaPopup.reload'),
            cancelText: i18next.t('cafa:cafaPopup.proceed'),
          }),
        ),
      );
      window.location.reload();
    } catch (error) {
      await i18next.loadNamespaces('managerOverride');
      await dispatch(
        openManagerOverride([
          {
            key: 'ignoreCAFAError',
            text: i18next.t('managerOverride:reasons.cafaFail'),
            isPermitted: async user => {
              const rights = await getRightsForUserID(user.userID);
              return Number(rights.rightPOSManagerOverride) === 1;
            },
          },
        ]),
      ).catch(() => {
        dispatch(handleMissingConfigs(failedConfigs));
      });
      dispatch(addWarning(i18next.t('cafa:cafaPopup.manager_proceeded')));
    }
  };
}

function fetchCafaConfigPerLevel({ level, level_id }) {
  return async dispatch => {
    dispatch(fetchCafaConfigAction.start());
    const { data } = await api.getBulkConfigurations({
      level,
      level_id,
    });

    const newData = {};
    data.forEach(c => {
      newData[c.id] = c;
    });
    dispatch(fetchCafaConfigAction.success(newData));
  };
}

/**
 * Fetches all configurations from CAFA related to the current POS, Warehouse and Company it belongs to
 */

export function fetchAllRelatedCafaConfigs(posOnly = false) {
  return async (
    dispatch,
    getState,
  ) => {
    const selectedPos = getSelectedPos(getState());
    const { userID } = getUserLoggedIn(getState());
    if (!selectedPos) {
      console.error(
        'Error getting current POS - this can happen when logging out too soon after just logging in',
      );
      return;
    }
    const { warehouseID, pointOfSaleID } = selectedPos;
    let promises = [
      dispatch(
        fetchCafaConfigPerLevel({
          level: types.CAFA_LEVELS.Pos,
          level_id: pointOfSaleID,
        }),
      ),
      dispatch(
        fetchCafaConfigPerLevel({
          level: types.CAFA_LEVELS.User,
          level_id: userID,
        }),
      ),
    ];
    if (!posOnly) {
      promises = promises.concat([
        dispatch(
          fetchCafaConfigPerLevel({
            level: types.CAFA_LEVELS.Warehouse,
            level_id: warehouseID,
          }),
        ),
        dispatch(
          fetchCafaConfigPerLevel({
            level: types.CAFA_LEVELS.Company,
            level_id: '0',
          }),
        ),
      ]);
    }

    const configs = await Promise.allSettled(promises);

    // get rejected promises
    const failedConfigs = configs.filter(config => config.status === 'rejected');

    // if everything was successful, return early
    if (failedConfigs.length === 0) return;

    Sentry.captureException(
      new Error('Failed to fetch some configurations from CAFA', {
        errors: failedConfigs.map(config => config.reason),
      }),
    );

    await dispatch(handleMissingConfigs(failedConfigs));
  };
}

/**
 * Delete CAFA configuration
 * @param integrationName - integration name
 * @param integrationType - integration type
 */
export function deleteCAFAConfig({
  integrationName,
  integrationType,
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const integration = getCafaEntry(integrationName, integrationType)(state);

    // If integration does not exist, abort deletion request
    if (!integration.name) {
      return dispatch(addError('Integration configuration does not exist'));
    }

    const { id, level, name, type, level_id } = integration;
    // Only allow deletion of integrations configured for the current POS
    if (![types.CAFA_LEVELS.Pos, types.CAFA_LEVELS.User].includes(level)) {
      return dispatch(
        addError(
          'Integration configuration is inherited and can not be deleted.',
        ),
      );
    }
    dispatch({ type: types.DELETE_CAFA_CONFIG.START });
    try {
      await api.deleteConfiguration(id);
      dispatch({ type: types.DELETE_CAFA_CONFIG.SUCCESS, payload: integration });
    } catch (err) {
      console.error(
        'Unable to delete cafa record',
        { level, name, type, level_id },
        err,
      );
      dispatch({ type: types.DELETE_CAFA_CONFIG.ERROR, payload: err.message });
    }
    return true;
  };
}

/**
 * Save CAFA configuration
 *
 * If configuration with such name already exists , updateCafaConfig will be dispatched
 * @param params {object}
 * @param params.integrationName {string}
 * @param params.integrationType {string}
 * @param [params.level] {undefined | {level: 'Warehouse'|'Company'|'Pos'|'User', id: string|undefined}}
 * @param params.config {object | string | boolean}
 */
export function saveCafaConfig({
  integrationName,
  integrationType = '',
  level = undefined,
  config,
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const posID = getSelectedPosID(state);

    const searchLevel = {
      id: level ? level.id : posID,
      level: level ? level.level : types.CAFA_LEVELS.Pos,
    };
    const integration = getCafaEntry(
      integrationName,
      integrationType,
      searchLevel,
    )(state);

    dispatch(saveCafaConfigAction.start());
    try {
      const params = {
        ...integration,
        application: REDUX_APP_NAME,
        level: searchLevel.level,
        level_id: searchLevel.id,
        type: integrationType,
        name: integrationName,
        value: config,
      };
      if (!params.value) {
        if (params?.id) {
          await dispatch(deleteCAFAConfig({ integrationName, integrationType }));
        } else {
          console.warn('Cannot save empty configuration to CAFA.');
          return;
        }
      } else if (params?.id) {
        await api.updateConfiguration(params);
      } else {
        const response = await api.saveConfiguration(params);
        params.id = response?.data?.id;
      }
      dispatch(saveCafaConfigAction.success(params));
    } catch (err) {
      dispatch(saveCafaConfigAction.error(err.message));
      throw new Error(`Failed to save cafa configuration`, { cause: err });
    }
  };
}

/**
 * Save selected integration as default for the selected integration type
 * @param {INTEGRATION_TYPES} integrationType - type of the integration
 * @param {string} value - selected payment integration, example: 'adyen'
 */
export function saveActiveIntegration({ integrationType, value }) {
  return async (
    dispatch,
    getState,
  ) => {
    const state = getState();
    const posID = getSelectedPosID(getState(state));
    const activeIntegrationsConfig = getActiveIntegrationsConfig(state);

    dispatch(saveCafaConfigAction.start());

    const newActiveIntegrationConfig = {
      ...activeIntegrationsConfig,
      value: {
        ...activeIntegrationsConfig?.value,
        [integrationType]: value,
      },
      type: types.INTEGRATION_TYPES.integrations,
      name: types.INTEGRATIONS[types.INTEGRATION_TYPES.integrations].active,
      level: types.CAFA_LEVELS.Pos,
      level_id: posID,
    };

    try {
      if (
        activeIntegrationsConfig?.id &&
        activeIntegrationsConfig?.level === types.CAFA_LEVELS.Pos
      ) {
        await api.updateConfiguration(newActiveIntegrationConfig);
      } else {
        await api.saveConfiguration(newActiveIntegrationConfig);
      }
      dispatch(saveCafaConfigAction.success(newActiveIntegrationConfig));
    } catch (err) {
      console.error('Failed to save active integration to CAFA', err);
      dispatch(saveCafaConfigAction.error(err.message));
    }
  };
}

export function waitForCafaToLoad() {
  return async (dispatch, getState) => {
    const s = 1000;
    const testFn = () => !getIsLoadingCafa(getState());
    const callback = () =>
      dispatch(
        addWarning(i18next.t('alerts:loading.cafa'), {
          selfDismiss: false,
          errorType: 'cafa-waiting',
        }),
      );

    try {
      await waitForCondition({
        testFn,
        timeout: 60 * s,
        stability: 0.75 * s,
        checkInterval: 0.1 * s,
        callbacks: {
          [1 * s]: callback,
        },
      });
    } catch (error) {
      dispatch(
        addError(i18next.t('alerts:loading.cafa_error'), {
          selfDismiss: 5000,
          dismissible: true,
        }),
      );
      throw error;
    } finally {
      dispatch(dismissType('cafa-waiting'));
    }
  };
}
