import i18next from 'i18next';

import { createConfirmation } from 'actions/Confirmation';
import { ErplyAttributes } from 'utils';

export function checkForLimitAmountPerSale(
  productToEvaluate,
  shoppingCartItems,
  amount = null,
  orderIndex = null,
) {
  return async dispatch => {
    const attrs = new ErplyAttributes(productToEvaluate.attributes);
    const limitPerSale = attrs.get('limit_amount_per_sale');
    const instancesInCart = shoppingCartItems.map(item =>
      item.name === productToEvaluate.name ? item : {},
    );
    const totalProductAmountInCart = instancesInCart.length
      ? instancesInCart.reduce((a, c) => {
          if (orderIndex === c.orderIndex && amount) {
            return a + Number(amount);
          }
          return a + Number(c.amount);
        }, 0)
      : null;

    const defineIfShouldAskForConfirmation = () => {
      if (amount) {
        if (
          Number(amount) <= Number(limitPerSale) &&
          totalProductAmountInCart <= Number(limitPerSale)
        ) {
          return false;
        }
      }

      if (totalProductAmountInCart + 1 > Number(limitPerSale)) {
        return true;
      }

      return false;
    };

    if (defineIfShouldAskForConfirmation()) {
      await i18next.loadNamespaces('limitPerSale');
      const amountLimitConfirmation = async dispatch =>
        new Promise((resolve, reject) => {
          dispatch(
            createConfirmation(resolve, reject, {
              title: i18next.t('limitPerSale:title', { limitPerSale }),
              body: i18next.t('limitPerSale:body'),
              confirmText: i18next.t('limitPerSale:confirmText'),
              cancelText: i18next.t('limitPerSale:cancelText'),
            }),
          );
        });

      const shoudlAddProduct = await dispatch(amountLimitConfirmation).then(
        () => true,
        () => false,
      );

      return shoudlAddProduct;
    }

    return true;
  };
}
