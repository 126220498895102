import { printPatchscript } from 'actions/integrations/printer';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { RItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { getCafaEntry } from 'reducers/cafaConfigs';

import { ApiResponse, IntegrationConfig } from '../types';

export const parseJSONPTerminalResponse = (
  jsonpString: string,
): ApiResponse => {
  const main = JSON.parse(
    jsonpString.match(/^jsonpResponse\((.+)\);?$/)?.[1] ?? '{}',
  );
  main.RcmResponse = JSON.parse(main.RcmResponse);
  return main;
};

const magicCreationOfPatchscript = string => [
  {
    type: 'table',
    columns: [
      {
        weight: 1,
        baseWidth: 0,
      },
      {
        weight: 1,
        baseWidth: 0,
      },
    ],
    rows: string
      .split('\n')
      .map(text => {
        const spaceInMiddle = /\S\s{2,}\S/.test(text);
        const spaceOnLeft = /^\s{2,}/.test(text);
        // const spaceOnRight = /\s{2,}$/.test(text);
        if (spaceInMiddle) {
          const [a, b, c] = text.split(/\s{2,}/);
          const [left, right] = a ? [a, b] : [b, c];
          return [
            {
              align: a ? 'left' : 'right',
              pieces: [{ text: left.trim(), meta: { bold: true } }],
            },
            { align: 'right', pieces: [{ text: right.trim() }] },
          ];
        }
        if (spaceOnLeft) {
          return [
            { align: 'center', pieces: [{ text: text.trim() }] },
            'colspan',
          ];
        }
        return [{ pieces: [{ text: text.trim() }] }, 'colspan'];
      })
      .map(cells => ({ type: 'normal', cells })),
  },
];

export const printReceipts = receiptString => async (dispatch, getState) => {
  const merchantReceiptPatchscript = magicCreationOfPatchscript(receiptString);
  const customerReceiptPatchscript = magicCreationOfPatchscript(receiptString);

  const { value: tsysCanadaConfig } = getCafaEntry<
    'payment',
    'tsysCanada',
    IntegrationConfig
  >(
    'tsysCanada',
    INTEGRATION_TYPES.payment,
  )(getState()) ?? { value: {} as IntegrationConfig };

  const { printReceipt } = tsysCanadaConfig;

  const typeOfReceipt = type => {
    return {
      type: 'normal',
      cells: [
        {
          align: 'center',
          pieces: [
            {
              text:
                type === 'merchant' ? 'Merchant Receipt' : 'Customer Receipt',
            },
          ],
        },
        'colspan',
      ],
    };
  };

  const emptySpace = {
    type: 'normal',
    cells: [
      {
        pieces: [
          {
            text: '',
          },
        ],
      },
      'colspan',
    ],
  };

  merchantReceiptPatchscript[0].rows.push(typeOfReceipt('merchant'));
  merchantReceiptPatchscript[0].rows.push(emptySpace);

  customerReceiptPatchscript[0].rows.push(typeOfReceipt('customer'));
  customerReceiptPatchscript[0].rows.push(emptySpace);

  if (printReceipt !== '2') {
    if (printReceipt === '1' && customerReceiptPatchscript.length) {
      dispatch(printPatchscript(customerReceiptPatchscript as RItem[]));
    }

    if (merchantReceiptPatchscript.length) {
      dispatch(printPatchscript(merchantReceiptPatchscript as RItem[]));
    }
  }
};
