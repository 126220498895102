import { CardPaymentHooks } from 'paymentIntegrations/types';
import { lastInvoiceNumbers } from 'services/localDB';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { ErplyAttributes, sleep } from 'utils';
import { getErrorMessage, withWaitingForTerminal } from 'paymentIntegrations';
import { getCardPaymentsForIntegration } from 'reducers/Payments';

import { MONERIS } from '../requests/monerisTransactions';
import { Type, TransactionRequest } from '../types';
import { getManualEntryModeParams } from '../manualEntryMode';

import { handleSuccess } from './handleSuccess';
import { handleFail } from './handleFail';

export const generateInputData = (payment, initialType: Type = null) => async (
  dispatch,
  getState,
): Promise<TransactionRequest> => {
  const pointOfSaleID = getSelectedPosID(getState());
  const lastInvoiceNumber = Number(
    (await lastInvoiceNumbers.getItem({
      key: pointOfSaleID,
    })) ?? String(new Date().getTime()),
  );

  const attributes =
    Number(payment.amount) < 0 && payment.original
      ? payment.original.attributes
      : payment.attributes;

  const paymentAttributes = new ErplyAttributes(attributes);

  const referenceNumber = paymentAttributes.get('refNo');

  const transactionType =
    // eslint-disable-next-line no-nested-ternary
    (initialType || Number(payment.amount) < 0) && !payment.paid
      ? 'REFUND'
      : payment.paid && payment.shouldProcess
      ? 'VOID'
      : 'SALE';

  const entryModeParams = await dispatch(getManualEntryModeParams());

  switch (transactionType) {
    case 'SALE':
    default:
      return {
        transactionType: 'SALE',
        invoiceNumber: String(lastInvoiceNumber + 1),
        amount: Number(payment.amount).toFixed(2),
        ...entryModeParams,
      };
    case 'REFUND':
      if (referenceNumber) {
        return {
          transactionType: 'REFUND',
          referenceNumber,
          amount: Number(payment.amount).toFixed(2),
          ...entryModeParams,
        };
      }
      return {
        transactionType: 'REFUND',
        amount: Number(payment.amount).toFixed(2),
        ...entryModeParams,
      };
    case 'VOID':
      if (referenceNumber) {
        return {
          transactionType: 'VOID',
          referenceNumber,
          amount: Number(payment.amount).toFixed(2),
          ...entryModeParams,
        };
      }
      return {
        transactionType: 'VOID',
        amount: Number(payment.amount).toFixed(2),
        ...entryModeParams,
      };
  }
};

export const processPayments = (params: CardPaymentHooks) => async (
  dispatch,
  getState,
) => {
  const {
    enableButtons,
    beforeDocSave,
    updateMessage,
    beforeDocDelete,
  } = params;
  // use payments returned from getCardPaymentsForIntegration, because cardPayments from params are outdated
  const cardPayments = getCardPaymentsForIntegration('moneris')(getState());
  const shouldVoid = cardPayments.every(cp => cp.paid && cp.shouldProcess);

  return cardPayments
    .filter(p => (shouldVoid ? p.paid : !p.paid))
    .reduce((prevPayment, currentPayment) => {
      return prevPayment.then(async ({ data, errors }) => {
        if (!errors.length) {
          try {
            await dispatch(
              withWaitingForTerminal(async () => {
                const inputData = await dispatch(
                  generateInputData(currentPayment),
                );
                if (inputData.transactionType === 'VOID') {
                  updateMessage(
                    `Processing void of ${Number(currentPayment.amount).toFixed(
                      2,
                    )}`,
                  );
                } else if (inputData.transactionType === 'REFUND') {
                  updateMessage(
                    `Processing refund of ${Number(
                      currentPayment.amount,
                    ).toFixed(2)}`,
                  );
                } else {
                  updateMessage(
                    `Processing payment of ${Number(
                      currentPayment.amount,
                    ).toFixed(2)}`,
                  );
                }
                let record;
                if (inputData.transactionType === 'REFUND') {
                  const {
                    records: [result],
                  } = await MONERIS.startRefund(inputData);
                  record = result;
                } else {
                  const {
                    records: [result],
                  } = await MONERIS.startPayment(inputData);
                  record = result;
                }

                if (record.resultCode === '0') {
                  await dispatch(
                    handleSuccess({
                      payment: currentPayment,
                      response: record,
                      inputData,
                      beforeDocDelete,
                      beforeDocSave,
                    }),
                  );
                  data.push(record);
                } else {
                  await dispatch(handleFail({ record, updateMessage }));
                  errors.push(record.statusMessage);
                }
              }),
            );
          } catch (e) {
            const error = e as Error;
            updateMessage(getErrorMessage(error, 'Payment processing failed'));
            enableButtons(['close', 'retry-payment']);
            await dispatch(
              handleFail({
                record: { statusMessage: error.message },
                updateMessage,
              }),
            );
            errors.push(error.message);
            console.error('Failed to process payments in Moneris', error);
          } finally {
            await sleep(1);
          }
        }

        return { data, errors };
      });
    }, Promise.resolve({ data: [], errors: [] }));
};
