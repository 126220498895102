import React, { useState, useMemo } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import * as MUI from '@material-ui/core';
import { isURL } from 'validator';
import { useDebounce } from 'react-use';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getUISettings, getSetting } from 'reducers/configs/settings';
import { SaveButton } from 'components/UIElements/UIButton';
import {
  saveTempSetting,
  commitTempSettings,
  dropTempSettings,
} from 'actions/configs';
import { modalPages } from 'constants/modalPage';
import { snakeToCamel } from 'utils';

import './Configuration.scss';

import ThemeDropdown from 'components/ThemeDropdown';
import { getThemeSlice } from 'reducers/UI/theme';

import {
  Checkbox,
  Section,
  Page,
  collapseAllSections,
  SelectField,
} from '../../components/Inputs';

/**
 * Returns a {value, setValue} pair for a given setting in the 'touchpos_ui_configuration' stringified object
 */
export const useUISetting = name => {
  const dispatch = useDispatch();
  const settings = useSelector(getUISettings);
  const value = settings[name];
  const setValue = value =>
    dispatch(
      saveTempSetting(
        'touchpos_ui_configuration',
        R.assoc(name, value, settings),
      ),
    );
  return { value, setValue };
};
/**
 * Returns a checkbox corresponding to a given key in the 'touchpos_ui_configuration' stringified object
 */
const UICheck = ({ name, func, opt, ...rest }) => {
  const { value, setValue } = useUISetting(name);
  const { t } = useTranslation('settingsUserInterface');

  // Memo for readability, not performance
  const prefix = useMemo(() => {
    if (func) return 'functions.';
    if (opt) return 'saleOptions.';
    return '';
  }, [func, opt]);

  return (
    <Checkbox
      name={name}
      value={value}
      onChange={(k, v) => setValue(v)}
      {...rest}
    >
      {t(`${prefix}${name}`)}
    </Checkbox>
  );
};
const UIURLInput = React.memo(({ name, label, ...props }) => {
  const { value, setValue } = useUISetting(name);
  const [localValue, setLocalValue] = useState(value);

  const isValid = !localValue || isURL(localValue);
  useDebounce(() => isValid && setValue(localValue), 500, [localValue]);
  const isSynced = localValue === value;

  return (
    <MUI.TextField
      fullWidth
      label={label}
      variant="outlined"
      onChange={e => setLocalValue(e.target.value)}
      error={!isValid}
      // Important to indicate sync status for cyrpress, lest automatic tests fail because the 500ms debounce has not been reached
      // but might be convenient for users too
      helperText={(!isValid && 'Invalid URL') || (!isSynced && '...') || ''}
      value={localValue}
      name={name}
      {...props}
    />
  );
});

const UIThemeDropdown = ({ name, ...props }) => {
  const { t } = useTranslation('settingsTheme');

  const { value, setValue } = useUISetting(name);
  const useDarkLegacy = getSetting('touchpos_use_dark_theme');
  const actualValue = value ?? (useDarkLegacy ? 'dark' : 'light');

  const { theme: deviceTheme, persist } = useSelector(getThemeSlice);
  const getHelperText = () => {
    if (!deviceTheme || deviceTheme === actualValue) return '';
    return persist
      ? t('overriddenByDeviceSetting')
      : t('overriddenByMediaQuery');
  };

  return (
    <ThemeDropdown
      value={actualValue}
      onChange={e => setValue(e.target.value)}
      name={name}
      helperText={getHelperText()}
      {...props}
    />
  );
};

const Check = ({ name, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsUserInterface');
  const value = useSelector(getSetting(name));
  const setValue = v => dispatch(saveTempSetting(name, v));

  return (
    <Checkbox
      name={name}
      value={value}
      onChange={(k, v) => setValue(v)}
      {...rest}
    >
      {t(`base.${snakeToCamel(name)}`)}
    </Checkbox>
  );
};

const Dropdown = ({ name, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsUserInterface');
  const value = useSelector(getSetting(name));
  const setValue = v => dispatch(saveTempSetting(name, v));

  return (
    <SelectField
      title={t(`${snakeToCamel(name)}`)}
      name={name}
      value={value}
      onChange={(k, v) => setValue(v)}
      {...rest}
    >
      {t(`${snakeToCamel(name)}`)}
    </SelectField>
  );
};

export default ({ onClose: close }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['settingsUserInterface', 'settingsTheme']);

  // TODO: Better system for managing temp settings so we don't have to list all the keys
  //  Sketch of potential idea:
  //    // Provider keeps track of changed fields and provides a 'save' callback
  //    const SettingsScope = () => {
  //      const settings = useSelector(getSettings)
  //      const [dirtySet, dirty] = useSet(new Set());
  //      const ctx = {
  //        value: settings,
  //        setValue: (k,v) => {dirty.add(k); dispatch(setTempSettings(k,v)}
  //        resetValue: (k) => {dirty.remove(k); dispatch(dropTempSettings([k])),
  //        commit: () => {dirty.reset(); dispatch(saveTempSettings(Array.from(dirtySet))},
  //        reset: () => {dirty.reset(); dispatch(dropTempSettings(Array.from(dirtySet))},
  //      }
  //      return <Settings.Provider value={ctx}>{children}</Settings.Provider>
  //    }
  //  .
  //    // Save Button just uses it
  //    <SettingsScope>
  //      <Header><SaveButton/></Header>
  //      <Body><Check/><Check/><Check/></Body>
  //    <SettingsScope>
  //  .
  //    const SaveButton = () => {}
  //      const {commit: save} = useContext(Settings)
  //      return <Button onClick={save}/>
  //    }

  const keys = [
    'touchpos_ui_configuration',
    'touchpos_use_dark_theme',
    'touchpos_hide_employee_stats',
    'productCodeToShowOnReturnModal',
  ];
  const onClose = () => {
    dispatch(dropTempSettings(keys));
    close();
  };

  const onSave = () => {
    dispatch(commitTempSettings(keys)).then(onClose);
  };

  return (
    <div
      className="erply-settings"
      style={{ overflowY: 'auto' }}
      data-testid="erply-settings"
    >
      <Modal.Header>
        <span>{t('title')}</span>
        <span style={{ flexGrow: 1 }} />
        <SaveButton action={onSave} variant="POS" data-testid="save-btn" />
        <CloseButton action={onClose} data-testid="close-btn" />
      </Modal.Header>
      <Modal.Body>
        <MUI.Grid container>
          <MUI.Grid item xs />
          <MUI.Button variant="text" onClick={collapseAllSections}>
            {t('settings:buttons.collapseAllSections')}
          </MUI.Button>
        </MUI.Grid>
        <Section title={t('sections.editProductGroups')}>
          <Page
            title={t('buttons.editProductGroups')}
            value={modalPages.ProductGroupsEditMode}
            data-testid="edit-product-groups"
          />
        </Section>
        <Section title="Grid buttons">
          <Page
            title={t('buttons.editGridButtonSize')}
            value={modalPages.GridButtonSize}
            data-testid="grid-button-size"
          />
        </Section>
        <Section title={t('sections.misc')}>
          <UIThemeDropdown
            name="color_theme"
            data-testid="color_theme"
            style={{ marginTop: '0.5rem' }}
          />
          <UICheck name="isFullScreen" data-testid="is-full-screen" />
          <UICheck name="isEvenColumns" data-testid="is-even-columns" />
          <UICheck
            name="touchposPaymentButtonsHighlight"
            data-testid="highlight-full-payment-buttons"
          />
          <UICheck
            name="hideProductCommission"
            data-testid="hide-product-commission"
          />
          <Check
            name="touchpos_hide_employee_stats"
            data-testid="touchpos_hide_employee_stats"
          />
        </Section>
        <Section title={t('sections.signIn')}>
          <UICheck name="hideSignUpButton" data-testid="hide-sign-up-button" />
          <UICheck
            name="hideLoginAppButton"
            data-testid="hide-login-app-button"
          />
          <UIURLInput
            name="touchpos_app_background_url"
            data-testid="background-url-input"
            label={t('touchpos_app_background_url')}
          />
        </Section>
        <Section title={t('sections.customerSearch')}>
          <UICheck name="hideCustomerInfo" data-testid="hide-customer-info" />
          <UICheck
            name="hideCustomerSearch"
            data-testid="hide-customer-search"
          />
        </Section>
        <Section title={t('sections.returnModal')}>
          <Dropdown
            name="productCodeToShowOnReturnModal"
            data-testid="product-code-to-show"
            options={useMemo(
              () => [
                {
                  value: 'code',
                  name: t('productCodeToShowOnReturnModalCode'),
                },
                {
                  value: 'barcode',
                  name: t('productCodeToShowOnReturnModalUpc'),
                },
              ],
              [t],
            )}
          />
        </Section>

        <Section title={t('sections.customerEdit')}>
          <UICheck
            name="isCustomerHomeStoreDisabled"
            data-testid="hide-home-store"
          />
        </Section>

        <Section title={t('sections.hideButtonsFunctions')}>
          <UICheck
            func
            name="hideAddProductOrGroup"
            data-testid="hide-add-product-or-group"
          />
          <UICheck
            func
            name="hideAddCustomer"
            data-testid="hide-add-customer"
          />
          <UICheck
            func
            name="hideRecentSales"
            data-testid="hide-recent-sales"
          />
          <UICheck
            func
            name="hidePendingSales"
            data-testid="hide-pending-sales"
          />
          <UICheck func name="hideOrders" data-testid="hide-orders" />
          <UICheck func name="hideLayaways" data-testid="hide-layaways" />
          <UICheck func name="hideCloseDay" data-testid="hide-close-day" />
          <UICheck func name="hideXReport" data-testid="hide-x-report" />
          <UICheck func name="hideCashInOut" data-testid="hide-cash-in-out" />
          <UICheck
            func
            name="hidePriceLookup"
            data-test-id="hide-price-lookup"
          />
          <UICheck func name="hideClockInOut" data-testid="hide-clock-in-out" />
          <UICheck
            func
            name="hideGiftCardBalance"
            data-testid="hide-gift-card-balance"
          />
          <UICheck
            func
            name="hidePrintCoupons"
            data-test-id="hide-print-coupons"
          />
          <UICheck func name="hideOffers" data-testid="hide-offers" />
          <UICheck
            func
            name="hideUnpaidInvoices"
            data-testid="hide-unpaid-invoices"
          />
        </Section>

        <Section title={t('sections.hideButtonsOptions')}>
          <UICheck
            opt
            name="hideAccountSales"
            data-testid="hide-account-sales"
          />
          <UICheck opt name="hideNewSale" data-testid="hide-new-sale" />
          <UICheck opt name="hideSaveSale" data-testid="hide-save-sale" />
          <UICheck opt name="hideSaveOrder" data-testid="hide-save-order" />
          <UICheck opt name="hideSaveLayaway" data-testid="hide-save-layaway" />
          <UICheck opt name="hideWaybills" data-testid="hide-waybills" />
          <UICheck opt name="hideDiscount" data-testid="hide-discount" />
          <UICheck opt name="hideTaxExcempt" data-testid="hide-tax-exempt" />
          <UICheck opt name="hideNotes" data-testid="hide-notes" />
          <UICheck
            opt
            name="hideLastReceiptPrint"
            data-testid="hide-last-receipt-print"
          />
          <UICheck
            opt
            name="hideOpenCashDrawer"
            data-testid="hide-open-cash-drawer"
          />
          <UICheck opt name="hidePromotions" data-testid="hide-promotions" />
          <UICheck opt name="hideCoupons" data-testid="hide-coupons" />
          <UICheck
            opt
            name="hideSaleCommission"
            data-testid="hide-sale-commission"
          />
          <UICheck
            opt
            name="hidePrintGiftcardReceipt"
            data-testid="hide-print-gift-card-receipt"
          />
          <UICheck opt name="hideAddShipping" data-testid="hide-add-shipping" />
          <UICheck
            opt
            name="hideSaveAsOffer"
            data-testid="hide-save-as-offer"
          />
          <UICheck
            opt
            name="hideStockTransfer"
            data-testid="hide-stock-transfter"
          />
        </Section>

        <Section title={t('sections.hideButtonsMisc')}>
          <UICheck name="hideSwitchUser" data-testid="hide-switch-user" />
        </Section>
      </Modal.Body>
    </div>
  );
};
