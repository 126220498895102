/* eslint-disable no-param-reassign */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useErrorState, { translateError } from 'utils/hooks/useErrorState';

import { Ctx } from '../../../../components/CtxInputs';
import RenderFormItem from '../../../../components/FormFieldCtx';

import { defaultOptions, junctionFields } from './formSchema';

const Junction = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.junction.${text}`, ...rest);
  const superContext = useContext(Ctx);
  const { configuration, setEnableUpdate } = superContext.values;

  const { provider, logging, printing } = junctionFields;
  const { errors, hasErrors } = useErrorState(configuration, {
    ...provider,
    ...logging,
    ...printing,
  });

  // If there are errors disable update button
  useEffect(() => {
    setEnableUpdate(!hasErrors);
  }, [hasErrors]);

  // Update configuration fields
  const onChange = (key, value) => {
    switch (key) {
      case 'apiPort':
        value = Number(value.replace(/\D/g, ''));
        break;
      default:
        value = String(value);
        break;
    }
    superContext.onChange('configuration', {
      ...configuration,
      [key]: value,
    });
  };

  return (
    <div>
      <Ctx.Provider
        value={{
          values: { ...defaultOptions, ...configuration },
          onChange,
        }}
      >
        <h2>Junction Integration Form</h2>
        <section className="my-4">
          <h5>Provider settings</h5>
          {Object.values(provider).map(({ validate, ...rest }) => {
            return (
              <RenderFormItem
                key={rest.id}
                title={t(rest.id)}
                name={rest.id}
                error={translateError(errors[rest.id], t)}
                {...rest}
              />
            );
          })}
        </section>
        <section className="my-4">
          <h5>Logging settings</h5>
          {Object.values(logging).map(({ validate, ...rest }) => {
            return (
              <RenderFormItem
                key={rest.id}
                title={t(rest.id)}
                name={rest.id}
                error={translateError(errors[rest.id], t)}
                {...rest}
              />
            );
          })}
        </section>
        <section className="my-4">
          <h5>Printing settings</h5>
          {Object.values(printing).map(({ validate, ...rest }) => (
            <RenderFormItem
              key={rest.id}
              title={t(rest.id)}
              name={rest.id}
              error={translateError(errors[rest.id], t)}
              {...rest}
            />
          ))}
        </section>
      </Ctx.Provider>
    </div>
  );
};

export default Junction;
