import { doClientRequest } from './core/ErplyAPI';

export const getWarehouses = params =>
  doClientRequest({ ...params, request: 'getWarehouses' });

export const saveWarehouse = params =>
  doClientRequest({ ...params, request: 'saveWarehouse' });

export const getHomeStores = params =>
  doClientRequest({
    request: 'getHomeStores',
    ...params,
  });
