import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';

import { Question, QuestionConfig } from '../types';
import {
  deleteMemberOfDictAndUpdateOrderProp,
  dictToArrayByOrder,
  swapDictMembersByOrder,
} from '../utils';

const Row: FC<{
  row: Question;
  deleteQuestion: (id: Question['id']) => void;
  selectQuestion: (id: Question['id']) => void;
  moveQuestion: (id: Question['id'], direction: 'up' | 'down') => void;
  isLastRow: boolean;
}> = ({ row, deleteQuestion, selectQuestion, moveQuestion, isLastRow }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: '10%' }}>
          <IconButton
            aria-label="expand answers"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ display: 'flex', alignItems: 'baseline' }}
        >
          <Typography style={{ flex: '1 1 0' }}>{row.content}</Typography>
          <IconButton
            disabled={row.order <= 1}
            onClick={() => moveQuestion(row.id, 'up')}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            disabled={isLastRow}
            onClick={() => moveQuestion(row.id, 'down')}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton onClick={() => selectQuestion(row.id)}>
            <EditOutlined />
          </IconButton>
          <IconButton onClick={() => deleteQuestion(row.id)}>
            <DeleteOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 1 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" gutterBottom>
                  Answers
                </Typography>
                <Box textAlign="end">
                  {row.required && <div>Answer is required</div>}
                  {row.multiOptions && <div>Multiple answers allowed</div>}
                </Box>
              </Box>
              <Table aria-label="answers">
                <TableBody>
                  {dictToArrayByOrder(row.answers).map(answer => (
                    <TableRow key={answer.id}>
                      <TableCell component="th" scope="row">
                        <Typography
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <span>{answer.content}</span>
                          {answer.needsInput && (
                            <span
                              style={{
                                fontStyle: 'italic',
                                marginLeft: 'auto',
                              }}
                            >
                              Requires input
                            </span>
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const QuestionsTable: FC<{
  questions: QuestionConfig;
  setQuestions: Dispatch<SetStateAction<QuestionConfig>>;
  selectQuestion: (q: Question['id']) => void;
}> = ({ questions, setQuestions, selectQuestion }) => {
  const deleteQuestion = (qid: Question['id']) => {
    setQuestions(deleteMemberOfDictAndUpdateOrderProp(qid, questions));
  };

  const moveQuestion = (qid: Question['id'], direction: 'up' | 'down') => {
    setQuestions(swapDictMembersByOrder(qid, questions, direction));
  };

  const orderedQuestions = useMemo(() => dictToArrayByOrder(questions), [
    questions,
  ]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="feedback questions table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography variant="h5">Questions</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedQuestions.map(q => (
            <Row
              key={q.id}
              row={q}
              deleteQuestion={deleteQuestion}
              selectQuestion={selectQuestion}
              moveQuestion={moveQuestion}
              isLastRow={q.order === orderedQuestions.length}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QuestionsTable;
