import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';

import { getAllWarehouses } from 'reducers/warehouses';

import { getPointsOfSale } from '../../reducers/PointsOfSale';
import { Warehouse } from '../../types/Warehouse';

const getPointsOfSaleById = createSelector(
  getPointsOfSale,
  registers =>
    new Map<
      number,
      { name: string; pointOfSaleID: number; warehouseID: number }
    >(registers.map(register => [register.pointOfSaleID, register])),
);
const getWarehousesById = createSelector(
  getAllWarehouses,
  warehouses =>
    new Map<number, Warehouse>(
      warehouses.map(wh => [Number(wh.warehouseID), wh]),
    ),
);

export const RegisterInput = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: Partial<
    AutocompleteProps<number, Multiple, DisableClearable, FreeSolo>
  >,
) => {
  const { t: tCommon } = useTranslation('common');
  const warehouses = useSelector(getWarehousesById);
  const registers = useSelector(getPointsOfSaleById);
  const options = useMemo(() => {
    const options = Array.from(registers.values());
    options.sort((a, b) => {
      if (a.warehouseID === b.warehouseID)
        return a.pointOfSaleID - b.pointOfSaleID;
      return a.warehouseID - b.warehouseID;
    });
    return options.map(r => r.pointOfSaleID);
  }, [registers]);

  return (
    <Autocomplete
      renderInput={props => <TextField variant="outlined" {...props} />}
      options={options}
      getOptionLabel={id => registers.get(id)?.name ?? tCommon('loading')}
      groupBy={id => {
        const reg = registers.get(id);
        if (!reg) return tCommon('loading');
        const wh = warehouses.get(reg.warehouseID);
        if (!wh) return tCommon('loading');
        return wh.name;
      }}
      filterOptions={createFilterOptions({
        stringify: id => {
          // NB: Cannot use fuzzy matching here, because the data is not one-dimensional
          // see footnote 1
          const reg = registers.get(id);
          const wh = warehouses.get(reg?.warehouseID ?? NaN);
          return `${wh?.name} ${reg?.name}`;
        },
      })}
      {...props}
    />
  );
};

/*
Footnote 1:
  Because the search could be intended for register or warehouse, and we don't know which, there is no obvious way to sort the data

   Example (fictional):
              No search                          Search, sorted separately                     Search, sorted together
         [                                ]  [ warehouse|                    ]               [ warehouse|                           ]

                                            (score)
         001-Main warehouse                   ( 80)  001-Main warehouse                                001-Main warehouse
           001-001 HQ                         ( 70)    001-004 Import warehouse              (80 + 70)   001-004 Import warehouse
           001-002 Main store                 ( 20)    001-002 Main store                    (80 + 20)   001-002 Main store
           001-003 VIP backrooms              ( 10)    001-003 VIP backrooms
           001-004 Import warehouse           (  0)    001-001 HQ                                      003-Parent's house
                                                                                             (40 + 65)   003-003 Parent's warehouse
         002-Downtown                         ( 30)  003-Parent's house
           002-001 Chicory convenience store  ( 65)    003-003 Parent's warehouse                      001-Main warehouse
           002-002 Maple Market               ( 40)    003-001 Parent's house                (80 + 10)   001-003 VIP backrooms
           002-003 Blackberry bar             ( 10)    003-002 Parent's garage               (80 +  0)   001-001 HQ
           002-004 Coastal warehouse
                                               ( 10)  002-Downtown                                     002-Downtown
         003-Parent's house                    ( 70)    002-004 Coastal warehouse            (10 + 70)   002-004 Coastal warehouse
           003-001 Parent's house              ( 25)    002-001 Chicory convenience store
           003-002 Parent's garage             ( 22)    002-003 Blackberry bar                         003-Parent's house
           003-003 Parent's warehouse          ( 20)    002-002 Maple Market                 (40 + 40)   003-001 Parent's house
                                                                                             (40 + 10)   003-002 Parent's garage

                                                                                                       002-Downtown
                                                                                             (10 + 25)   002-001 Chicory convenience store
                                                                                             (10 + 22)   002-003 Blackberry bar
                                                                                             (10 + 20)   002-002 Maple Market

  Register-by-register jumbles the results together very badly, regardless if you're searching by warehouse or register

  Sorting separately is better, but
    If the search is meant to find a register,
      then it is frustrating that the warehouses are not in the correct order and have their full register lists to scroll past
    If the search is meant to find warehouses,
      then it's frustrating that the registers inside are not in the correct order

  Strict matching avoids these issues by removing items from the results instead of just sorting it.
  Fuzzy matching could have a cutoff too, but there is no ideal threshold and the results are very hard for the user to understand
*/
