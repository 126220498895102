import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './BottomPopup.css';
import { startNewSale } from 'actions/sales';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { getUserRights } from 'reducers/Login';
import { addWarning } from 'actions/Error';

const CurrentSaleView = ({ goBack, close, disabledInOffline }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('gridButtons');
  const hasPermissions = useSelector(getUserRights).rightApplyPromotions;

  const newSale = () => {
    dispatch(startNewSale());
    close();
  };

  const showNotesPopup = () => {
    dispatch(
      openModalPage({
        component: mp.saleNotes,
        modalClassName: 'saleNotes',
      }),
    );
    close();
  };

  const showTaxExemptPopup = () => {
    dispatch(openModalPage({ component: mp.taxExempt }));
    close();
  };

  const showShippingPopup = () => {
    dispatch(openModalPage({ component: mp.shipping }));
    close();
  };

  const showSaleCommissionPopup = () => {
    dispatch(openModalPage({ component: mp.SaleCommission }));
    close();
  };

  const showCouponPopup = () => {
    hasPermissions
      ? dispatch(openModalPage({ component: mp.saleCoupons }))
      : dispatch(
          addWarning(t('validation:ErplyAPI.error.permissions'), {
            selfDismiss: true,
          }),
        );
    close();
  };

  const showDiscountPopup = () => {
    dispatch(openModalPage({ component: mp.SaleDiscount }));
    close();
  };

  const showPromotionsPopup = () => {
    hasPermissions
      ? dispatch(openModalPage({ component: mp.salePromotions }))
      : dispatch(
          addWarning(t('validation:ErplyAPI.error.permissions'), {
            selfDismiss: true,
          }),
        );
    close();
  };

  return (
    <>
      <span className="mobile-menu-action" onClick={newSale}>
        {t('saleOptions.newSale')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={showNotesPopup}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.notes')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={showTaxExemptPopup}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.taxExempt')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={showShippingPopup}
        style={{ ...disabledInOffline, marginTop: '14px' }}
      >
        {t('saleOptions.addShipping')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={showSaleCommissionPopup}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.saleCommission')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={showCouponPopup}
        style={{ ...disabledInOffline, marginTop: '14px' }}
      >
        {t('saleOptions.coupons')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={showDiscountPopup}
        style={{ ...disabledInOffline, marginTop: '14px' }}
      >
        {t('saleOptions.discount')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={showPromotionsPopup}
        style={{ ...disabledInOffline, marginTop: '14px' }}
      >
        {t('saleOptions.promotions')}
      </span>
      <span
        className="btn-cancel mobile-menu-action"
        onClick={() => goBack()}
        style={{ marginTop: '28px' }}
      >
        {t('common:back')}
      </span>
    </>
  );
};

export default CurrentSaleView;
