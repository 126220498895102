import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseButton from 'components/CustomButtons/CloseButton';
import { updateOrderAmount } from 'actions/ShoppingCart';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import UIButton from 'components/UIElements/UIButton';

const PackageAmountPopup: FC<{
  amounts: string[];
  orderIndex: string | number;
}> = ({ amounts = [], orderIndex = null }) => {
  const { t } = useTranslation('product');
  const dispatch = useDispatch();

  const handleClick = amount => {
    dispatch(updateOrderAmount(Number(amount), orderIndex));
    dispatch(previousModalPage());
  };
  return (
    <div data-testid="package-amount-popup">
      <Modal.Header>
        <Modal.Title>{t('package.title')}</Modal.Title>
        <CloseButton
          action={() => {
            dispatch(previousModalPage());
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Grid
          container
          style={{
            rowGap: '10px',
            overflowY: 'scroll',
            maxHeight: 450,
            justifyContent: 'space-evenly',
          }}
          spacing={0}
        >
          {amounts.map(amount => (
            <Grid item>
              <UIButton
                variant="secondary"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: 16,
                  fontFamily: 'Proxima Nova',
                  minWidth: 80,
                  minHeight: 76,
                }}
                action={() => handleClick(amount)}
                text={amount}
              />
            </Grid>
          ))}
        </Grid>
      </Modal.Body>
    </div>
  );
};

export default PackageAmountPopup;
