import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

import { SalesList } from './index';

type Props = {
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
  customerID: number | string;
};

const PreviousSales: FC<Props> = ({ resolve, reject, customerID }) => {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(previousModalPage());
    resolve();
  };
  return (
    <>
      <Modal.Header>
        <h3 className="font-weight-bold">Previously purchased products</h3>
        <span style={{ flexGrow: 1 }} />
        <CloseButton action={close} />
      </Modal.Header>
      <Modal.Body>
        <SalesList customerID={customerID} />
      </Modal.Body>
    </>
  );
};

export default PreviousSales;
