import { PosPlugin } from 'plugins/plugin';
import { ErplyAttributes } from 'utils';

import { ATTRIBUTES } from '../constants';
import { setSAINumber } from '../redux/actions';

const setSAINumberToReturnDocument: Required<
  PosPlugin
>['onAddReturnProducts']['on'] = (props, actionParams) => async dispatch => {
  const attributes = new ErplyAttributes(props.returnDocument.attributes);
  const SAINumber = attributes.get(ATTRIBUTES.SAI_NUMBER);

  if (SAINumber) dispatch(setSAINumber(SAINumber));

  return actionParams;
};

export default setSAINumberToReturnDocument;
