import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import Table from 'react-bootstrap/Table';

import { sleep, round } from 'utils';

function copyAllStyles(from, to) {
  let stylesheets = from.document.querySelectorAll(
    'style, link[rel="stylesheet"]',
  );
  stylesheets = Array.prototype.slice.call(stylesheets);
  for (let i = 0; i < stylesheets.length; i++) {
    copyStyle(from, to, stylesheets[i]);
  }
}
function copyStyle(from, to, node) {
  const doc = to.document;
  const newStyle = doc.createElement(node.tagName);
  if (node.textContent) {
    newStyle.textContent = node.textContent;
  } else if (node.innerText) {
    newStyle.innerText = node.innerText;
  }
  newStyle.type = node.type;
  newStyle.src = node.src;
  newStyle.rel = node.rel;
  doc.getElementsByTagName('head')[0].appendChild(newStyle);
}

const LATENCY = 300;
const CustomerDisplaySimulator = () => {
  const [queue, setQueue] = useState(Promise.resolve());
  const [popup, setPopup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  useEffect(() => {
    const port = window.integrations.customerDisplay.port2;
    port.onmessage = msg => {
      setQueue(q =>
        q
          .then(sleep(0))
          .then(() => setLoading(true))
          .then(sleep(LATENCY / 1000))
          .then(() => setLastMessage(msg.data))
          .then(() => setLoading(false)),
      );
    };
    return () => (port.onmessage = () => {});
  }, [window && window.integrations && window.integrations.customerDisplay]);

  useEffect(() => {
    const newWin = window.open(
      '',
      '',
      'width=400,height=900,location=0,dependent=1,alwaysRaised=1',
    );
    setPopup(newWin);
    // newWin.focus();
    const originalWindowOnBeforeUnload = window.onbeforeunload;
    const cleanup = () => newWin.close();
    window.onbeforeunload = (...params) => {
      cleanup.apply(window, params);
      originalWindowOnBeforeUnload.apply(window, params);
    };
    copyAllStyles(window, newWin);
    return () => {
      cleanup();
      window.onbeforeunload = originalWindowOnBeforeUnload;
    };
  }, []);

  if (!popup) {
    return null;
  }
  if (!lastMessage) {
    return ReactDOM.createPortal('No connection', popup.document.body);
  }

  /** @type CustomerDisplayMessage */
  const data = lastMessage;

  return ReactDOM.createPortal(
    <div>
      <div
        style={{
          height: 24,
          width: '100%',
          backgroundColor: 'gray',
          position: 'absolute',
          transition: loading && `${LATENCY / 1000}s`,
          top: loading ? 900 : -24,
        }}
      />
      Settings: Clientcode={data.clientCode} Language={data.lang}
      <Table size="sm">
        <thead>
          <tr>
            <th>Client</th>
            <th>Warehouse</th>
            <th>POS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.customer}</td>
            <td>{data.warehouse}</td>
            <td>{data.pointofsale}</td>
          </tr>
          <tr>
            <td>{data.customer_id.toString()}</td>
            <td>{data.warehouse_id.toString()}</td>
            <td>{data.pointofsale_id.toString()}</td>
          </tr>
        </tbody>
      </Table>
      <hr />
      {{
        payment: PayingOrder(data),
        sale: CreatingOrder(data),
        saleend: 'Thank you for the purchase',
      }[data.action] || null}
    </div>,
    popup.document.body,
  );
};

const CreatingOrder = data => (
  <>
    <h2>Rows: </h2>
    <Table size="sm">
      <thead>
        <tr>
          <th>Qty</th>
          <th>Name</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {[...(data.rows || [])].reverse().map(row => (
          <tr>
            <td>{row.amount}</td>
            <td>{row.name}</td>
            <td>{round(row.rowTotal, 2)}</td>
          </tr>
        ))}
        <tr>
          <th colSpan={2} style={{ textAlign: 'right' }}>
            Net:
          </th>
          <td>{round(data.totalNet, 2)}</td>
        </tr>
        <tr>
          <th colSpan={2} style={{ textAlign: 'right' }}>
            VAT:
          </th>
          <td>{round(data.totalTax, 2)}</td>
        </tr>
        <tr>
          <th colSpan={2} style={{ textAlign: 'right' }}>
            Discount:
          </th>
          <td>{round(data.totalDiscount, 2)}</td>
        </tr>
        <tr>
          <th colSpan={2} style={{ textAlign: 'right' }}>
            TOTAL:
          </th>
          <td>{round(data.total, 2)}</td>
        </tr>
      </tbody>
    </Table>
  </>
);
const PayingOrder = data => (
  <>
    <h2>Payments: </h2>
    <Table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Caption</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {(data.payments || []).map(pmt => (
          <tr>
            <td>{pmt.type}</td>
            <td>{pmt.caption}</td>
            <td>{round(pmt.amount, 2)}</td>
          </tr>
        ))}

        <tr>
          <td />
          <th colSpan={2} style={{ textAlign: 'right' }}>
            Net:
          </th>
          <td>{round(data.totalNet, 2)}</td>
        </tr>
        <tr>
          <td />
          <th colSpan={2} style={{ textAlign: 'right' }}>
            VAT:
          </th>
          <td>{round(data.totalTax, 2)}</td>
        </tr>
        <tr>
          <td />
          <th colSpan={2} style={{ textAlign: 'right' }}>
            Discount:
          </th>
          <td>{round(data.totalDiscount, 2)}</td>
        </tr>
        <tr>
          <td />
          <th colSpan={2} style={{ textAlign: 'right' }}>
            TOTAL:
          </th>
          <td>{round(data.total, 2)}</td>
        </tr>
      </tbody>
    </Table>
  </>
);

export default CustomerDisplaySimulator;
