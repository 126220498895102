import { printPatchscript } from 'actions/integrations/printer';
import { PatchScript } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';

export const printMerchantReceipt = (
  receipt: { key: string; printName?: string; printValue: string }[],
) => {
  const ps: PatchScript = [
    {
      type: 'table',
      columns: [
        { baseWidth: 0, weight: 1 },
        { baseWidth: 2, weight: 0 },
        { baseWidth: 0, weight: 1 },
      ],
      rows: receipt.map(({ key, printName, printValue }) => ({
        type: 'normal',
        cells: [
          {
            align: 'right',
            pieces: [{ text: printName || key }, { text: ':' }],
          },
          null,
          { align: 'left', pieces: [{ text: printValue }] },
        ],
      })),
    },
  ];
  return printPatchscript(ps);
};
