import {
  getProductInOrderByIndex,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';
import { getProductByID } from 'reducers/cachedItems/products';
import { updateOrderPrice, updateProductOrder } from 'actions/ShoppingCart';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getSelectedPos } from 'reducers/PointsOfSale';

import { getProductKittedItemPrice, mergeLevel } from './utils';
import constants from './constants';
import {
  BulkEditPrice,
  ComponentConfiguration,
  UIProductFormBody,
  UIRelatedProducts,
} from './components';

const relatedItems: PosPlugin = {
  id: constants.plugin.id,
  name: constants.plugin.name,
  info:
    'Implements a related product suggestions. The suggested products are visible on the right side pane. The suggestion is unidirectional, meaning that Product B is suggested while selling product A, but product A is not suggested when selling product B. Products sold in kits can have preferential  prices. Those can be configured in the plugin Configuration.',
  keywords: ['pnp', 'related products', 'kitted items'],
  getStatus: () => ({
    type: 'valid',
    message: 'Ready for testing',
  }),
  combineConfiguration: (...args) => {
    let final = {};
    args.forEach(config => {
      final = mergeLevel(config, final);
    });
    return final;
  },
  ComponentConfiguration,
  onAddProduct: {
    on: (props, { product: order, options, ...rest }) => async (dispatch, getState) => {
      const { vatrateID } = getSelectedPos(getState());
      const updatedOrder = { ...order };
      const product = getProductByID(order.productID)(getState());

      // if the product is warranty
      if (product.code.startsWith('EW'))
        return {
          options,
          product: {
            ...updatedOrder,
            price: product.priceListPrice, // Note: Validate if this is necessary
            vatrateID,
          },
          ...rest,
        };
      const parentOrder = getProductInOrderByIndex(order.parentRowID)(
        getState(),
      );
      // if product has no parent, then it is not a related product sell
      if (!parentOrder) return { product: updatedOrder, options, ...rest };
      const configuration = getPluginConfiguration<{ [key: string]: any }>(
        constants.plugin.id,
      )(getState())[parentOrder?.productID];
      // check if the current order product is in related products of its parent order product
      const parentProduct = getProductByID(parentOrder.productID)(getState());
      const orderIsRelatedProductToParent =
        [...(parentProduct?.relatedProducts ?? [])].filter(
          n => n.toString() === updatedOrder.productID.toString(),
        ).length > 0;

      // if there is no configuration for this product(no cross sell rule in config)
      if (!configuration || !orderIsRelatedProductToParent)
        return { product: updatedOrder, options, ...rest };

      // If the sale is to a volume buyer (PBIB-692) do nothing
      const customer = getSelectedCustomer(getState());
      const isVolumeBuyer = customer.groupName === 'Volume';
      if (isVolumeBuyer) return { product: updatedOrder, options, ...rest };

      // Otherwise apply kitted item price and prevent further discounts
      const price = getProductKittedItemPrice(
        parentOrder?.productID,
        order.productID,
      )(getState());
      if (price) {
        updatedOrder.price = price;
        updatedOrder.nonDiscountable = true;
      }
      return { product: updatedOrder, options, ...rest };
    },
  },
  onSetCustomer: {
    before: () => async (dispatch, getState) => {
      const products = getProductsInShoppingCart(getState());
      products.forEach(prod => delete prod.vatrateID);
    },
    after: () => async (dispatch, getState) => {
      const customer = getSelectedCustomer(getState());
      const isVolumeBuyer = customer.groupName === 'Volume';
      const orders = getProductsInShoppingCart(getState());

      if (orders.length === 0) return;

      // If set to a volume buyer, remove kitted item pricings
      // Assumption: .price field on nested rows only set by this plugin
      if (isVolumeBuyer) {
        orders
          .filter(p => p.parentRowID !== 0)
          .forEach(order => {
            dispatch(updateOrderPrice(undefined, undefined, order.orderIndex));
            dispatch(
              updateProductOrder({
                orderIndex: order.orderIndex,
                nonDiscountable: undefined,
              }),
            );
          });
      } else {
        // If set to a Non-volume buyer, reinstate custom pricing
        orders.forEach(order => {
          const parentOrder = getProductInOrderByIndex(order.parentRowID)(
            getState(),
          );
          const price = getProductKittedItemPrice(
            parentOrder?.productID,
            order.productID,
          )(getState());
          if (price) {
            dispatch(updateOrderPrice(price, undefined, order.orderIndex));
            dispatch(
              updateProductOrder({
                orderIndex: order.orderIndex,
                nonDiscountable: true,
              }),
            );
          }
        });
      }
    },
  },
  UIProductFormBody,
  UIRelatedProducts,
  components: {
    BulkEditPrice,
  },
};

export default relatedItems;
