import {
  CardPaymentHooks,
  FUNC_BUTTONS,
  PaymentObj,
} from 'paymentIntegrations/types';

import { ChaseTransactionResponse } from '../types';

type Params = {
  payment: PaymentObj;
  record: ChaseTransactionResponse['records'][number];
  hooks: CardPaymentHooks;
};

export default async function handleTransactionResponse({
  hooks,
  payment,
  record,
}: Params) {
  switch (record.resultCode) {
    case '0': {
      if (payment.paid && payment.shouldProcess) {
        await hooks.beforeDocDelete(payment.key);
        break;
      }
      await hooks.beforeDocSave({
        ...payment,
        paid: true,
        amount:
          Number(payment.amount) < 0
            ? `-${record.approvedAmount}`
            : record.approvedAmount,
        cardType: record.cardType,
        cardHolder: record.cardHolder,
        cardNumber: record.cardNumber,
        signature: record.signature,
        attributes: {
          aid: record.aid,
          paymentType: record.paymentType,
          dateTime: record.dateTime,
          refNo: record.referenceNumber,
        },
      });
      break;
    }
    case '2':
      throw new Error(record.transactionStatus);
    case '117':
    case '1':
      throw new Error(record.statusMessage || record.transactionStatus);
    default:
      hooks.updateMessage(record.transactionStatus);
      hooks.enableButtons([FUNC_BUTTONS.RETRY]);
  }
}
