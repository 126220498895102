import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PosPlugin } from 'plugins/plugin';
import InputField from 'components/FieldTypes/InputField';
import { addWarning } from 'actions/Error';
import { getOrders } from 'reducers/returnProducts';
import { setOneOrderSelected } from 'actions/returnProducts';

import { getExchangeState } from '../rdx';

const UISingleReturnRowCheckbox: PosPlugin['UISingleReturnRowCheckbox'] = ({
  children,
  isSelected,
  row,
  rowLocked,
  globalReturnReason,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('return');
  const exchangeState = useSelector(getExchangeState);
  const orders: Record<string, any> = useSelector(getOrders);
  const selectedOrder = orders[row.stableRowID];

  const toggleReturnRowWithoutRemovingReturnReason = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (row.nonRefundable) {
        return dispatch(
          addWarning(
            t('alerts:nonRefundableProductError', {
              name: row.itemName,
            }),
          ),
        );
      }
      return dispatch(
        setOneOrderSelected(row.stableRowID, {
          ...(selectedOrder ?? row),
          selected: !selectedOrder?.selected,
        }),
      );
    },
    [row, selectedOrder, globalReturnReason],
  );

  return (
    <>
      {exchangeState.exchangeStep >= 1 ? (
        <th className="align-middle">
          <InputField
            type="checkbox"
            disabled={row.nonRefundable}
            value={isSelected}
            className={`${rowLocked && 'invisible'}`}
            data-testid="product-row-toggle"
            onChange={toggleReturnRowWithoutRemovingReturnReason}
          />
        </th>
      ) : (
        children
      )}
    </>
  );
};

export default UISingleReturnRowCheckbox;
