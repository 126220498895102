import { PatchScript } from '@pos-refacto/patchscript-with-react';

import { getCurrencyFormatterNoSymbol } from 'reducers/configs/settings';

import { mmReceiptTranslations } from './translations/translations';

type TableCell = Extract<
  PatchScript[number],
  { type: 'table' }
>['rows'][number]['cells'][number];
const textL = (s: any): TableCell => ({
  pieces: [{ text: String(s) }],
});
const textR = (s: any): TableCell => ({
  pieces: [{ text: String(s) }],
  align: 'right',
});

export const metaPaymentsPrintout = (
  payment: {
    name: string;
    cardNumber: string;
    sum: number;
    balance: number;
    date: string;
    time: string;
  },
  CURR: ReturnType<typeof getCurrencyFormatterNoSymbol>,
  { givexRefill: t } = mmReceiptTranslations.en,
): PatchScript => {
  function line(label, value): PatchScript[number] {
    return {
      type: 'text',
      pieces: [{ text: label }, { text: ': ' }, { text: value }],
    };
  }

  return [
    { type: 'text', pieces: [{ text: ' ' }] },
    line(
      t.account,
      `${payment.name} (${payment.cardNumber
        .slice(0, -4)
        .replace(/\d/g, '*')}${payment.cardNumber.slice(-4)})`,
    ),
    line(t.amount, CURR.stringify(payment.sum)),
    line(t.balance, CURR.stringify(payment.balance)),
    line(t.date, payment.date),
    line(t.time, payment.time),
  ];
};
