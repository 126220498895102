export const btnTextContainerStyle = {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
} as const;

export const btnTextStyle = {
  fontSize: '20px',
  textTransform: 'uppercase',
  fontWeight: 700,
  display: 'inline-flex',
  alignItems: 'center',
} as const;
