import React, { useMemo, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import propTypes, { oneOfType } from 'prop-types';
import { useTranslation } from 'react-i18next';

import InputFieldBase from 'components/FieldTypes/InputField';
import { version } from 'external_data';
import loginskin from 'components/FieldTypes/skins/skins.module.scss';
import CloseButton from 'components/CustomButtons/CloseButton';

import { styles } from '../util';
import * as Validators from '../validations';

const InputField = props => (
  <InputFieldBase size="lg" className={loginskin.loginInput} {...props} />
);
// eslint-disable-next-line react/prefer-stateless-function
const SignUpDialog = ({
  onCancel,
  onCreateAccount,
  errorField,
  email,
  country,
  countries,
  updateEmail,
  updateCountry,
  updateErrorField,
}) => {
  const [agreeTOS, toggleAgreeTOS] = useState(false);
  const [firstName, updateFirstName] = useState('');
  const [lastName, updateLastName] = useState('');
  const [companyName, updateCompanyName] = useState('');
  const [phone, updatePhone] = useState('');
  const [website, updatewebsite] = useState('');
  const [password, updatePassword] = useState('');
  const [nrStores, updateNrStores] = useState('');

  const storeOptions = [
    ' - ',
    ...Array(10)
      .fill(0)
      .map((_, i) => i + 1), // 0 to 10
    ...Array(4)
      .fill(0)
      .map((_, i) => i + 1)
      .map(num => `${num}1 - ${num + 1}0`), // 11-20 to 41-50
    '50+',
  ];

  const { t } = useTranslation('login');

  const fields = useMemo(
    () =>
      [
        {
          name: 'firstName',
          title: t('inputs.firstName'),
          value: firstName,
          validator: Validators.validateFirstName,
          onChangeFunc: updateFirstName,
        },
        {
          name: 'lastName',
          title: t('inputs.lastName'),
          value: lastName,
          validator: Validators.validateLastName,
          onChangeFunc: updateLastName,
        },
        {
          name: 'companyName',
          title: t('inputs.company'),
          value: companyName,
          validator: Validators.validateCompany,
          onChangeFunc: updateCompanyName,
        },
        {
          name: 'email',
          title: t('inputs.email'),
          value: email,
          validator: Validators.validateEmail,
          onChangeFunc: updateEmail,
        },
        {
          name: 'phone',
          title: t('inputs.phone'),
          value: phone,
          validator: Validators.validatePhone,
          onChangeFunc: updatePhone,
        },
        {
          name: 'country',
          title: t('inputs.country'),
          type: 'select',
          options: countries,
          value: country,
          validator: Validators.validateSelect,
          onChangeFunc: updateCountry,
        },
        {
          name: 'website',
          title: t('inputs.website'),
          value: website,
          isRequired: false,
          validator: Validators.validateWebsite,
          onChangeFunc: updatewebsite,
        },
        {
          name: 'password',
          title: t('inputs.password'),
          type: 'password',
          value: password,
          validator: Validators.validatePassword,
          onChangeFunc: updatePassword,
          autoComplete: 'new-password',
        },
        {
          name: 'nrStores',
          title: t('inputs.nrStores'),
          type: 'select',
          value: nrStores,
          options: storeOptions,
          validator: Validators.validateSelect,
          onChangeFunc: updateNrStores,
        },
      ].map(({ validator, ...props }, i) => {
        const errorText = validator(props.value);
        return {
          onChange: event => {
            if (event.target.name === errorField) {
              updateErrorField('');
            }
            props.onChangeFunc(event.target.value);
          },
          autoFocus: i === 0,
          isRequired: true,
          isInvalid:
            // eslint-disable-next-line react/destructuring-assignment
            !!errorText.length || errorField === props.name,
          ...props,
          errorText,
        };
      }),
    [
      firstName,
      lastName,
      companyName,
      email,
      phone,
      country,
      website,
      password,
      nrStores,
      errorField,
    ],
  );

  const fieldsAreValid = useMemo(
    () => fields.every(field => !field.isInvalid),
    [fields],
  );

  return (
    <Card style={styles.card}>
      <Card.Header style={styles.header}>
        {t('signup.title')}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <CloseButton action={onCancel} />
      </Card.Header>
      <Card.Body
        className="p-sm-4 p-0 pb-4"
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          height: 'calc(100vh - 234px)',
          marginBottom: '20px',
        }}
      >
        {fields.map(props => (
          <InputField key={props.name} {...props} />
        ))}
        <InputField
          type="checkbox"
          value={agreeTOS}
          onChange={() => toggleAgreeTOS(!agreeTOS)}
          size="lg"
          style={{ margin: '0 16px', height: 'auto' }}
        >
          <a
            className="text-info"
            href="https://erply.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: 14 }}
          >
            {t('inputs.agreeToTOS')}
          </a>
        </InputField>

        <Row style={styles.buttons}>
          <Col xs={12}>
            <Button
              disabled={!agreeTOS || !fieldsAreValid}
              onClick={() =>
                onCreateAccount({
                  firstName,
                  lastName,
                  companyName,
                  country,
                  email,
                  phone,
                  website,
                  password,
                  nrStores,
                })
              }
              style={{ flexGrow: 1, ...styles.button }}
              size="lg"
              variant="primary"
            >
              {t('createAccount.title')}
            </Button>
          </Col>
        </Row>
        <span style={styles.version}>{version}</span>
      </Card.Body>
    </Card>
  );
};

SignUpDialog.propTypes = {
  onCancel: propTypes.func,
  onCreateAccount: propTypes.func,
  errorField: propTypes.string,
  email: propTypes.string,
  country: oneOfType([propTypes.string, propTypes.object]),
  countries: propTypes.arrayOf(propTypes.object),
  updateEmail: propTypes.func,
  updateCountry: propTypes.func,
  updateErrorField: propTypes.func,
};

export default SignUpDialog;
