import { sleep } from 'utils';

import swedbankRequest from './index';

const getActMessage = () =>
  swedbankRequest
    .post('manage', { manageType: 'status' })
    .then(res => res.data.records[0].deviceState);

(window as any).getATC = getActMessage;
/** Controlled by the user - whether the loop should be running right now */
let isStarted = false;
/** Controlled by the loop - whether an instance of the loop is currently executing */
let isRunning = false;

const actLoop = {
  get isRunning() {
    return isRunning;
  },
  start: async (updateMessage: (msg: string) => void) => {
    // eslint-disable-next-line no-await-in-loop
    isStarted = true; // Register intent to loop
    if (isRunning) return; // If previous loop still alive, leave it to work

    isRunning = true; // else register that we have taken over and start looping

    while (true) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(1);
      // eslint-disable-next-line no-await-in-loop
      const msg = await getActMessage();
      if (!isStarted) break;
      updateMessage(msg);
    }
    isRunning = false;
  },
  stop: () => {
    isStarted = false; // register intent to stop loop
  },
};
export default actLoop;
