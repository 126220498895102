import React from 'react';
import { Box, Typography, Divider, TextField, Button } from '@material-ui/core';

import CloseButton from 'components/CustomButtons/CloseButton';
import Loader from 'components/Loader';

import { useSAINumber } from '../hooks';

type Props = {
  onClose(): void;
  resolve(saiNumber?: string): void;
  reject(reason: string): void;
  initialSAINumber?: string;
};

const SAIModal: React.FC<Props> = ({
  onClose,
  resolve,
  reject,
  initialSAINumber,
}) => {
  const {
    validateForDuplicates,
    loading,
    errorMessage,
    isValidSAINUmber,
    SAINumber,
    onChange,
    ref,
  } = useSAINumber(initialSAINumber);

  function close() {
    reject('SAI number modal was closed');
    onClose();
  }

  async function confirm() {
    if (!isValidSAINUmber || SAINumber === undefined) return;
    const isAvailable = await validateForDuplicates(SAINumber);
    if (!isAvailable) return;
    resolve(SAINumber);
    onClose();
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') confirm();
  }

  return (
    <Loader show={loading} loadingText="Validating SAI number..." block>
      <Box
        display="flex"
        flexDirection="column"
        gridGap="0.75rem"
        padding="1rem"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">SAI number</Typography>
          <CloseButton action={close} />
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" gridGap="0.75rem">
          <TextField
            value={SAINumber}
            onChange={onChange}
            label="Please enter SAI number"
            fullWidth
            autoFocus
            onKeyDown={onKeyDown}
            inputRef={ref}
            error={errorMessage !== null}
            helperText={errorMessage}
            variant="outlined"
          />
          <Box display="flex" gridGap="0.75rem">
            <Button fullWidth variant="contained" onClick={close}>
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={confirm}
              disabled={errorMessage !== null}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Loader>
  );
};

export default SAIModal;
