import { REDUX_CUSTOMER_REGISTRY_URL } from 'constants/persistence';

import {
  CUSTOMER_TYPES,
  FIELDS_WITH_DEFAULT_VALUES,
  NAME_FIELDS,
} from './types';

const defaultPerson = {
  regular: {
    firstName: { enabled: true, required: true, order: 0 },
    lastName: { enabled: true, required: true, order: 1 },
    id: { enabled: false, required: false, order: 2 },
    personTitleID: { enabled: true, required: false, order: 3 },
    gender: { enabled: true, required: false, order: 4 },
    jobTitleID: { enabled: true, required: false, order: 5 },
    customerCardNumber: { enabled: true, required: false, order: 6 },
    euCustomerType: { enabled: true, required: false, order: 7 },
    groupID: { enabled: true, required: false, order: 8 },
    homeStoreID: { enabled: true, required: false, order: 9 },
    email: { enabled: true, required: false, order: 10 },
    phone: { enabled: true, required: false, order: 11 },
    fax: { enabled: true, required: false, order: 12 },
    mobile: { enabled: true, required: false, order: 13 },
    birthday: { enabled: true, required: false, order: 14 },
    addressTypeID: { enabled: true, required: false, order: 15 },
    street: { enabled: true, required: false, order: 16 },
    address2: { enabled: true, required: false, order: 17 },
    postalCode: { enabled: true, required: false, order: 18 },
    city: { enabled: true, required: false, order: 19 },
    state: { enabled: true, required: false, order: 20 },
    country: { enabled: true, required: false, order: 21 },
    code: { enabled: true, required: false, order: 22 },
    notes: { enabled: true, required: false, order: 23 },
    emailOptOut: { enabled: true, required: false, order: 24 },
    taxExempt: { enabled: true, required: false, order: 25 },
    doNotSell: { enabled: true, required: false, order: 26 },
    salesBlocked: { enabled: true, required: false, order: 27 },
  },
  customerRegistery: {
    firstName: { enabled: true, required: true, order: 0 },
    lastName: { enabled: true, required: true, order: 1 },
    id: { enabled: false, required: false, order: 2 },
    personTitleID: { enabled: true, required: false, order: 3 },
    gender: { enabled: true, required: true, order: 4 },
    jobTitleID: { enabled: true, required: false, order: 5 },
    customerCardNumber: { enabled: true, required: false, order: 6 },
    euCustomerType: { enabled: true, required: true, order: 7 },
    groupID: { enabled: true, required: false, order: 8 },
    homeStoreID: { enabled: true, required: false, order: 9 },
    email: { enabled: true, required: false, order: 10 },
    phone: { enabled: true, required: false, order: 11 },
    fax: { enabled: true, required: false, order: 12 },
    mobile: { enabled: true, required: false, order: 13 },
    birthday: { enabled: true, required: false, order: 14 },
    addressTypeID: { enabled: true, required: false, order: 15 },
    street: { enabled: true, required: false, order: 16 },
    address2: { enabled: true, required: false, order: 17 },
    postalCode: { enabled: true, required: false, order: 18 },
    city: { enabled: true, required: false, order: 19 },
    state: { enabled: true, required: false, order: 20 },
    country: { enabled: true, required: false, order: 21 },
    code: { enabled: true, required: false, order: 22 },
    notes: { enabled: true, required: false, order: 23 },
    emailOptOut: { enabled: true, required: false, order: 24 },
    taxExempt: { enabled: true, required: false, order: 25 },
    doNotSell: { enabled: true, required: false, order: 26 },
    salesBlocked: { enabled: true, required: false, order: 27 },
  },
};

function getKey() {
  return localStorage.getItem(REDUX_CUSTOMER_REGISTRY_URL)
    ? 'customerRegistery'
    : 'regular';
}

export function getDefaultCustomerFormConfiguration() {
  const key = getKey();

  return {
    allowedCustomerTypes: '',
    formFields: {
      [CUSTOMER_TYPES.PERSON]: defaultPerson[key],
      // customer registry API is not meant for company creations?
      [CUSTOMER_TYPES.COMPANY]: {
        companyName: { enabled: true, required: true, order: 0 },
        id: { enabled: false, required: false, order: 1 },
        vatNumber: { enabled: true, required: false, order: 2 },
        customerCardNumber: { enabled: true, required: false, order: 3 },
        euCustomerType: { enabled: true, required: false, order: 4 },
        groupID: { enabled: true, required: false, order: 5 },
        homeStoreID: { enabled: true, required: false, order: 6 },
        email: { enabled: true, required: false, order: 7 },
        phone: { enabled: true, required: false, order: 8 },
        fax: { enabled: true, required: false, order: 9 },
        mobile: { enabled: true, required: false, order: 10 },
        addressTypeID: { enabled: true, required: false, order: 11 },
        street: { enabled: true, required: false, order: 12 },
        address2: { enabled: true, required: false, order: 13 },
        postalCode: { enabled: true, required: false, order: 14 },
        city: { enabled: true, required: false, order: 15 },
        state: { enabled: true, required: false, order: 16 },
        country: { enabled: true, required: false, order: 17 },
        code: { enabled: true, required: false, order: 18 },
        notes: { enabled: true, required: false, order: 19 },
        emailOptOut: { enabled: true, required: false, order: 20 },
        taxExempt: { enabled: true, required: false, order: 21 },
        doNotSell: { enabled: true, required: false, order: 22 },
        salesBlocked: { enabled: true, required: false, order: 23 },
      },
    },
  };
}

export function getRequiredApiFields() {
  const key = getKey();

  return {
    regular: ['firstName', 'lastName', 'companyName'],
    customerRegistery: [
      'firstName',
      'lastName',
      'companyName',
      'gender',
      'euCustomerType',
      'birthday',
    ],
  }[key];
}

export function getIsAllowedToBeDisabled(fieldName: string, defaultValue) {
  const isNameField = NAME_FIELDS.includes(fieldName);
  if (isNameField) return false;
  const isRequiredByApi = getRequiredApiFields().includes(fieldName);
  const supportsDefaultValue = FIELDS_WITH_DEFAULT_VALUES.includes(fieldName);
  if (isRequiredByApi && supportsDefaultValue && !defaultValue) return false;
  return true;
}
