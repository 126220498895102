import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  Checkbox as MuiCheckbox,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  label: {
    marginBottom: 0,
  },
  helperText: {
    marginTop: '-3px',
  },
});

type Props = Omit<FormControlLabelProps, 'control'> & {
  value: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  formControlProps?: FormControlProps;
};

function Checkbox({
  value,
  required,
  error,
  helperText,
  formControlProps,
  ...rest
}: Props) {
  const styles = useStyles();
  return (
    <FormControl {...formControlProps} required={required} error={error}>
      <FormControlLabel
        className={styles.label}
        {...rest}
        control={<MuiCheckbox checked={value} />}
      />
      {helperText ? (
        <FormHelperText className={styles.helperText}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

export default Checkbox;
