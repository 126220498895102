import { createSelector } from 'reselect';

import { modalPages } from 'constants/modalPage';
import { getComponents } from 'reducers/modalPage';
import { getIsAReturn } from 'reducers/sales';

export const getShouldShowIssueGiftCardSection = createSelector(
  getIsAReturn,
  getComponents,
  (isAReturn, components) => {
    const paymentModalOpen = components
      .map(comp => comp.component)
      .includes(modalPages.Payment);
    return paymentModalOpen && isAReturn;
  },
);
