import React from 'react';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

export const SalesResults: PosPlugin['UIRecentSalesPreviousPurchasesTable'] = ({
  children,
}) => {
  return R.pipe(
    React.Children.toArray,
    R.map(
      R.over(
        R.lensPath(['props', 'queryProps']),
        R.assoc('getCustomerInformation', 1),
      ),
    ),
  )(children);
};
