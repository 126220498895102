import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { v1 as uuidv1 } from 'uuid';
import { ThunkDispatch } from 'redux-thunk';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { addError, addSuccess } from 'actions/Error';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import SaveButton from 'components/CustomButtons/SaveButton';
import { RootState } from 'reducers';

import { checkBalance } from '../API/viiAPI';
import { CheckBalanceResponse } from '../types';
import { noResponse, statusMapper } from '../constants';
import { getViiGiftCardProduct } from '../redux';

const ViiBalance = () => {
  /**
   * Test info:
   * number: 502904197000961538,
   * pin: 8216
   */
  const product = useSelector(getViiGiftCardProduct);
  const [cardNumber, setCardNumber] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const [response, setResponse] = useState<CheckBalanceResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const handleClose = () => dispatch(previousModalPage());

  const handleSearch = async () => {
    dispatch(
      checkBalance({
        CardNumber: cardNumber,
        viiTranId: uuidv1(),
        PIN: pin,
      }),
    )
      .then(res => {
        if (res && Number(res?.ResponseCode?.[0]) === 0) {
          dispatch(addSuccess('Card found'));
          setResponse(res);
          setError(null);
        } else {
          dispatch(addError(res?.ResponseMessage[0] ?? noResponse));
          setError(res?.ResponseMessage[0] ?? noResponse);
          setResponse(null);
        }
        return res;
      })
      .catch(err => {
        dispatch(addError(err.message));
        setError(err.message);
        setResponse(null);
      });
  };
  useShortcut('Enter', handleSearch);
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  return (
    <div data-testid="vii-balance">
      <Modal.Header>
        <Modal.Title>
          <h3 data-testid="title">
            <b>{`Check ${product?.name ?? 'Vii gift card'} balance`}</b>
          </h3>
        </Modal.Title>
        <SaveButton action={handleSearch} title="get balance" />
        <div className="d-flex align-content-center">
          <CloseButton action={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Grid container>
          <TextField
            inputRef={inputRef}
            fullWidth
            variant="outlined"
            autoFocus={true}
            value={cardNumber}
            onChange={e => setCardNumber(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '20%' }}>
                  CARD NUMBER
                </InputAdornment>
              ),
            }}
            style={{
              marginBottom: '20px',
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            value={pin}
            type="password"
            onChange={e => setPin(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '20%' }}>
                  CARD PIN
                </InputAdornment>
              ),
            }}
          />
          {response && (
            <>
              <Grid container>
                <Grid item xs={5}>
                  <Typography>Card Status</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>
                    {statusMapper[response.CardStatusId[0]]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <Typography>Available Balance</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>{response.AvailableBalance[0]}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <Typography>Current Balance</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>{response.CurrentBalance[0]}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <Typography>Issuance Date</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>{response.IssuanceDate[0]}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <Typography>ExpiryDate Date</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography>{response.ExpiryDate[0]}</Typography>
                </Grid>
              </Grid>
            </>
          )}
          {error && (
            <Grid container>
              <Grid item xs={12}>
                <Typography align="center" color="error">
                  {error}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Modal.Body>
    </div>
  );
};

export default ViiBalance;
