import crypto from 'crypto';

import axios from 'axios';
import uuid from 'uuid/v1';
import xml2js from 'xml2js';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getPluginConfiguration } from 'reducers/Plugins';
import { addError } from 'actions/Error';
import { getMSEndpointFromLS } from 'reducers/installer';
import { RootState } from 'reducers';

import { pluginID } from '../constants';
import {
  CancelLoadRequest,
  CancelLoadResponse,
  CheckBalanceRequest,
  CheckBalanceResponse,
  Configuration,
  LoadRequest,
  LoadResponse,
  RedemptionRequest,
  RedemptionResponse,
  Request,
  UndoRequest,
  UndoResponse,
  UndoFuncArguments,
  CheckBalanceFuncArguments,
  LoadFuncArguments,
  CancelLoadFuncArguments,
  RedemptionFuncArguments,
  PreAuthFuncArguments,
  PreAuthRequestRequest,
  PreAuthRequestResponse,
  PreAuthCancellationFuncArguments,
  PreAuthCancellationRequest,
  PreAuthCancellationResponse,
  GetTransactionHistoryFuncArguments,
  GetTransactionHistoryResponse,
  GetTransactionHistoryRequest,
  GetEODTotalsFuncArguments,
  GetEODTotalsResponse,
  GetEODTotalsRequest,
  OrderActivationFuncArguments,
  OrderActivationResponse,
  OrderActivationRequest,
  OrderPartialActivationFuncArguments,
  OrderPartialActivationResponse,
  OrderPartialActivationRequest,
  VerifyStoreFuncArguments,
  VerifyStoreRequest,
  OrderLoadRequest,
  OrderLoadResponse,
  OrderLoadFuncArguments,
  OrderPartialLoadResponse,
  OrderPartialLoadFuncArguments,
  OrderPartialLoadRequest,
  GetBurnPropositionsResponse,
  GetBurnPropositionsRequest,
  GetBurnPropositionsFuncArguments,
  Response,
  VerifyStoreResponse,
} from '../types';

const generateAuth = (conf: Configuration) => {
  const { StoreId, AIIC, CATI, CAIC } = conf ?? {}; // fallback to empty object would lead to undefined being returned
  if (StoreId) {
    return { StoreId };
  }
  if (AIIC && CATI && CAIC) {
    return {
      AIIC,
      CATI,
      CAIC,
    };
  }
  return undefined;
};

const asyncMakeRequest = <ReturnType extends Response = Response>(
  params: Request,
) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
): Promise<ReturnType | undefined> => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig || !pluginConfig.viiPassword || !pluginConfig.viiUserName) {
    throw new Error(
      'Request to Vii failed. Both user name and password require to be configured in plugin.',
    );
  }
  const proxy = getMSEndpointFromLS('vii');
  const builder = new xml2js.Builder();
  const parser = new xml2js.Parser();

  const payload2 = builder.buildObject({ Request: params });
  const hmac = crypto
    .createHmac('sha256', pluginConfig.viiPassword)
    .update(payload2)
    .digest('base64');
  const headers = {
    'Content-Type': 'text/xml',
    Authorization: `VII3-HMAC-SHA256 Credentials=${window.btoa(
      pluginConfig.viiUserName,
    )},Signature=${hmac}`,
  };
  return axios
    .post(`${proxy}?redirect=${pluginConfig.url}`, payload2, { headers })
    .then(resp => {
      if (resp.data) {
        return parser.parseStringPromise(resp.data).then(res => res.Response);
      }
      throw new Error('Request to Vii failed');
    });
};

/**
 * The following request returns the gift card amount balance and status of a gift card.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const checkBalance = (args: CheckBalanceFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }
  const { CardNumber, PIN, viiTranId } = args;
  // At this point in code we know that these are 100% provided
  const payload: CheckBalanceRequest = {
    RequestType: 'CheckBalance',
    CardNumber,
    PIN,
    ...auth,
    viiTranId: viiTranId || uuid(),
  };

  return dispatch(asyncMakeRequest<CheckBalanceResponse>(payload));
};

/**
 * A gift card load request loads value onto the gift card.
 * This request is used for issuing a gift card and refunding a return of a product back onto the original gift card.
 * The first load onto the gift card is the issuance transaction.
 * All future loads are considered as refunds.
 * Refunds are not allowed to exceed the value of the original issuance transaction.
 * Therefore, the balance of the gift card is not allowed to be greater than the original issuance.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const load = (args: LoadFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }
  const extraArguments: Partial<LoadRequest> = {};
  // Required arguments
  const { CardNumber, viiTranId, Amount, ExternalReference } = args;
  // Optional arguments
  if (args.Discount) {
    extraArguments.Discount = args.Discount;
  }
  if (args.IsBonus) {
    Object.assign(extraArguments, {
      IsBonus: args.IsBonus,
    });
  }
  if (args.Stan) {
    Object.assign(extraArguments, {
      Stan: args.Stan,
    });
  }
  if (args.TransactionDate) {
    Object.assign(extraArguments, {
      TransactionDate: args.TransactionDate,
    });
  }
  if (args.TransactionTime) {
    Object.assign(extraArguments, {
      TransactionTime: args.TransactionTime,
    });
  }
  if (args.Offline) {
    Object.assign(extraArguments, {
      // Since Offline can either be 10 or not provided, set it to 10 whatever value it was provided with
      Offline: 10,
    });
  }

  const payload: LoadRequest = {
    RequestType: 'Load',
    ExternalReference,
    Amount,
    CardNumber,
    ...auth,
    viiTranId: viiTranId || uuid(),
    ...extraArguments,
  };
  return dispatch(asyncMakeRequest<LoadResponse>(payload));
};

/**
 * A cancel gift card load request will remove funds of a card and mark the card as cancelled.
 * This request is only used for cancelling a gift card once a customer returns to the store requesting a gift card cancellation and a refund of funds that was used to purchase the gift card.
 * This transaction will only be approved if the original load amount matches the available balance of the gift card.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const cancelLoad = (args: CancelLoadFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }
  const extraArguments: Partial<CancelLoadRequest> = {};
  // Required arguments
  const { CardNumber, viiTranId, Amount, ExternalReference } = args;

  if (args.Stan) {
    Object.assign(extraArguments, {
      Stan: args.Stan,
    });
  }
  if (args.TransactionDate) {
    Object.assign(extraArguments, {
      TransactionDate: args.TransactionDate,
    });
  }
  if (args.TransactionTime) {
    Object.assign(extraArguments, {
      TransactionTime: args.TransactionTime,
    });
  }
  if (args.Offline) {
    Object.assign(extraArguments, {
      // Since Offline can either be 10 or not provided, set it to 10 whatever value it was provided with
      Offline: 10,
    });
  }
  const payload: CancelLoadRequest = {
    RequestType: 'CancelLoad',
    viiTranId: viiTranId || uuid(),
    Amount,
    CardNumber,
    ...auth,
    ExternalReference,
    ...extraArguments,
  };
  return dispatch(asyncMakeRequest<CancelLoadResponse>(payload));
};

/**
 * This request is used to redeem value from gift card.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const redemption = (args: RedemptionFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }
  const extraArguments: Partial<RedemptionRequest> = {};
  // Required arguments
  const {
    CardNumber,
    viiTranId,
    Amount,
    PIN,
    ExternalReference,
    PreAuthCode,
  } = args;
  // Optional arguments
  if (args.Stan) {
    Object.assign(extraArguments, {
      Stan: args.Stan,
    });
  }
  if (PreAuthCode) {
    Object.assign(extraArguments, {
      PreAuthCode,
    });
  }
  if (args.TransactionDate) {
    Object.assign(extraArguments, {
      TransactionDate: args.TransactionDate,
    });
  }
  if (args.TransactionTime) {
    Object.assign(extraArguments, {
      TransactionTime: args.TransactionTime,
    });
  }
  if (args.Offline) {
    Object.assign(extraArguments, {
      // Since Offline can either be 10 or not provided, set it to 10 whatever value it was provided with
      Offline: 10,
    });
  }
  // At this point in code we know that these are 100% provided
  const payload: RedemptionRequest = {
    RequestType: 'Redemption',
    viiTranId: viiTranId ?? uuid(),
    Amount,
    CardNumber,
    ...auth,
    PIN,
    ExternalReference,
    ...extraArguments,
  };
  return dispatch(asyncMakeRequest<RedemptionResponse>(payload));
};

/**
 * This request is required when a previous transaction has timed-out or a response has not been received on a previous request.
 * This request will reverse out the previous transaction requested.
 * This transaction should not be used to apply a refund to a gift card.
 * The undo process is required to be implemented as an automated asynchronous background process, that polls Vii periodically (every 30 seconds - 1 minute) until a response is received.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const undo = (args: UndoFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const { CardNumber, viiTranIdToUndo, viiTranId } = args;

  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }

  const payload: UndoRequest = {
    RequestType: 'Undo',
    viiTranId: viiTranId ?? uuid(),
    CardNumber,
    ...auth,
    viiTranIdToUndo,
  };
  return dispatch(asyncMakeRequest<UndoResponse>(payload));
};

/**
 * A pre-auth will hold value of a specified amount on the gift-card for a period of 5 days.
 * Once the redemption is to be performed the redemption request will need to include the pre-auth code so it can be linked to the frozen funds.
 * If the pre-auth code is not used by the redemption request within 5 days the funds will be returned to the card.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const preAuth = (args: PreAuthFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }
  const { CardNumber, viiTranId, Amount, ExternalReference, PIN } = args;

  const payload: PreAuthRequestRequest = {
    RequestType: 'PreAuthRequest',
    viiTranId: viiTranId ?? uuid(),
    CardNumber,
    ...auth,
    Amount,
    ExternalReference,
    PIN,
  };
  return dispatch(asyncMakeRequest<PreAuthRequestResponse>(payload));
};

/**
 * The following request removes the hold of funds if the hold is no longer required.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const preAuthCancellation = (
  args: PreAuthCancellationFuncArguments,
) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }

  const { CardNumber, viiTranId, PreAuthCode } = args;

  const payload: PreAuthCancellationRequest = {
    RequestType: 'PreAuthCancellation',
    viiTranId: viiTranId ?? uuid(),
    CardNumber,
    ...auth,
    PreAuthCode,
  };
  return dispatch(asyncMakeRequest<PreAuthCancellationResponse>(payload));
};

/**
 * The following request returns the gift card amount balance and status of a gift card as well as the entire transaction history since the card was issued.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const getTransactionHistory = (
  args: GetTransactionHistoryFuncArguments,
) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }

  const extraArguments: Partial<GetTransactionHistoryRequest> = {};
  // Required arguments
  const { CardNumber, viiTranId, PIN } = args;
  // Optional arguments
  if (args.PageNumber) {
    Object.assign(extraArguments, {
      PageNumber: Number(args.PageNumber),
    });
  }
  if (args.ResultsPerPage) {
    Object.assign(extraArguments, {
      ResultsPerPage: Number(args.ResultsPerPage),
    });
  }
  if (args.NewestFirst) {
    Object.assign(extraArguments, {
      NewestFirst: Number(args.NewestFirst),
    });
  }

  const payload: GetTransactionHistoryRequest = {
    RequestType: 'GetTransactionHistory',
    viiTranId: viiTranId ?? uuid(),
    CardNumber,
    ...auth,
    PIN,
    ...extraArguments,
    // Figure out how to pass either StoreId or AIIC & CATI & CAIC
  };
  return dispatch(asyncMakeRequest<GetTransactionHistoryResponse>(payload));
};

/**
 * The following request returns the summary of transactions for a particular store or Terminal for the current day.
 * If terminal details are entered it will return figures for that terminal only.
 * If storeId is entered will return figures for the entire store.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const getEODTotals = (args: GetEODTotalsFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig) throw new Error('Vii plugin not configured!');
  const { viiPassword, viiUserName } = pluginConfig;
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }

  if (!(viiPassword && viiUserName)) {
    throw new Error('Vii user name and/or password not configured');
  }
  // Required arguments
  const { viiTranId, BIN } = args;

  const payload: GetEODTotalsRequest = {
    RequestType: 'GetEODTotals',
    viiTranId: viiTranId ?? uuid(),
    ...auth,
    viiPassword,
    viiUserName,
    BIN,
    // Figure out how to pass either StoreId or AIIC & CATI & CAIC
  };
  return dispatch(asyncMakeRequest<GetEODTotalsResponse>(payload));
};

/**
 * The following request activates an existing order to be activated on the system.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const orderActivation = (args: OrderActivationFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig) throw new Error('Vii plugin not configured');
  const { viiPassword, viiUserName } = pluginConfig;
  if (!(viiPassword && viiUserName)) {
    throw new Error('Vii user name and/or password not configured');
  }

  // Required arguments
  const { viiTranId, ActivationPin, OrderNumber } = args;
  // At this point in code we know that these are 100% provided
  const payload: OrderActivationRequest = {
    RequestType: 'OrderActivation',
    viiTranId: viiTranId ?? uuid(),
    viiPassword,
    viiUserName,
    OrderNumber,
    ActivationPin,
  };
  return dispatch(asyncMakeRequest<OrderActivationResponse>(payload));
};

/**
 * The following request activates an existing order to be activated on the system.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const orderPartialActivation = (
  args: OrderPartialActivationFuncArguments,
) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig) throw new Error('Vii plugin not configured');
  const { viiPassword, viiUserName } = pluginConfig;
  if (!(viiPassword && viiUserName)) {
    throw new Error('Vii user name and/or password not configured');
  }

  // Required arguments
  const {
    viiTranId,
    ActivationPin,
    OrderNumber,
    StartCardNumber,
    EndCardNumber,
    Quantity,
  } = args;

  const payload: OrderPartialActivationRequest = {
    RequestType: 'OrderPartialActivation',
    viiUserName,
    viiPassword,
    viiTranId: viiTranId ?? uuid(),
    OrderNumber,
    ActivationPin,
    StartCardNumber,
    EndCardNumber,
    Quantity,
  };
  return dispatch(asyncMakeRequest<OrderPartialActivationResponse>(payload));
};

/**
 * This request verifies a store id entered. This allows pre-validation on entry before a pre-auth or redemption request is entered.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const VerifyStore = (args: VerifyStoreFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig) throw new Error('Vii plugin not configured');
  const { viiPassword, viiUserName, StoreId } = pluginConfig;
  if (!StoreId) {
    throw new Error('Store ID not configured. Contact Vii team to get one.');
  }
  if (!(viiPassword && viiUserName)) {
    throw new Error('Vii user name and/or password not configured');
  }
  // Required arguments
  // StoreGroupId maybe also a conf value
  const { viiTranId, StoreGroupId } = args;

  const payload: VerifyStoreRequest = {
    RequestType: 'VerifyStore',
    viiTranId: viiTranId ?? uuid(),
    StoreId,
    viiPassword,
    viiUserName,
    StoreGroupId,
  };
  return dispatch(asyncMakeRequest<VerifyStoreResponse>(payload));
};

/**
 * The following request activates an existing order to be activated on the system.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const OrderLoad = (args: OrderLoadFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig) throw new Error('Vii plugin not configured');
  const { viiPassword, viiUserName } = pluginConfig;
  if (!(viiPassword && viiUserName)) {
    throw new Error('Vii user name and/or password not configured');
  }
  // Required arguments
  const { viiTranId, ActivationPin, OrderNumber } = args;

  const payload: OrderLoadRequest = {
    RequestType: 'OrderLoad',
    viiTranId: viiTranId ?? uuid(),
    viiPassword,
    viiUserName,
    ActivationPin,
    OrderNumber,
  };
  return dispatch(asyncMakeRequest<OrderLoadResponse>(payload));
};

/**
 * The following request activates an existing order to be activated on the system.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const OrderPartialLoad = (args: OrderPartialLoadFuncArguments) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig) throw new Error('Vii plugin not configured');
  const { viiPassword, viiUserName } = pluginConfig;
  if (!(viiPassword && viiUserName)) {
    throw new Error('Vii user name and/or password not configured');
  }
  // Required arguments
  const {
    viiTranId,
    ActivationPin,
    OrderNumber,
    StartCardNumber,
    EndCardNumber,
    Quantity,
  } = args;

  const payload: OrderPartialLoadRequest = {
    RequestType: 'OrderPartialLoad',
    viiUserName,
    viiPassword,
    viiTranId: viiTranId ?? uuid(),
    OrderNumber,
    ActivationPin,
    StartCardNumber,
    EndCardNumber,
    Quantity,
  };
  return dispatch(asyncMakeRequest<OrderPartialLoadResponse>(payload));
};

/**
 * The following request returns the points to dollar burn options available to the loyalty member based on the requested amount.
 * @param args - Necessary arguments for the request. Some must be provided to the function via form, others are optional and are provided by default
 * @returns API response in a promise
 */

export const GetBurnPropositions = (
  args: GetBurnPropositionsFuncArguments,
) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const pluginConfig = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  if (!pluginConfig) throw new Error('Vii plugin not configured');
  const { viiPassword, viiUserName } = pluginConfig;
  const auth = generateAuth(pluginConfig);

  if (!auth) {
    throw new Error('Faulty config');
  }

  if (!(viiPassword && viiUserName)) {
    throw new Error('Vii user name and/or password not configured');
  }
  const extraArguments: Partial<GetBurnPropositionsRequest> = {};
  // Required arguments
  const { CardNumber, Amount, TransactionDate, TransactionTime } = args;
  if (args.PIN) {
    extraArguments.PIN = args.PIN;
  }

  const payload: GetBurnPropositionsRequest = {
    RequestType: 'GetBurnPropositions',
    ...auth,
    CardNumber,
    Amount,
    TransactionDate,
    TransactionTime,
    ...extraArguments,
  };
  return dispatch(asyncMakeRequest<GetBurnPropositionsResponse>(payload));
};
