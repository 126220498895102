import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { buildZReportPatchscript } from 'actions/integrations/printer/preparePrintoutData';
import Loader from 'components/Loader';
import { PatchscriptRender } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/printer/sampleReactPrinter';
import UIButton from 'components/UIElements/UIButton';
import ZReport from 'components/ZReport';
import CloseButton from 'components/CustomButtons/CloseButton';
import { hardLogout } from 'actions/Login';
import { getHasDayOpen } from 'reducers/OpenCloseDay';

const ZReportForm = ({ logoutAfterClose = false }) => {
  const { t } = useTranslation('zReport');
  const dispatch = useDispatch();
  const hasDayOpened = useSelector(getHasDayOpen);

  const [print, setPrint] = useState(false);

  const [browserPrint, setBrowserPrint] = useState(false);

  const logout = () => dispatch(hardLogout());

  const onClose = () => {
    dispatch(previousModalPage());
    if (logoutAfterClose) {
      logout();
    }
  };

  const doPrint = () => {
    setPrint(false);
    setTimeout(() => setPrint(true), 0);
  };

  const saveReport = () => {
    setBrowserPrint(false);
    setTimeout(() => setBrowserPrint(true), 0);
  };

  const onClickAway = e => {
    if (!e.target.closest('#z-report-panel') && !hasDayOpened) onClose();
  };

  useEffect(() => {
    window.addEventListener('click', onClickAway);
    return () => window.removeEventListener('click', onClickAway);
  }, [hasDayOpened, onClickAway]);

  return (
    <div
      id="z-report-panel"
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Modal.Header>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('title')}
        </span>

        <div style={{ flex: 1 }} />
        <UIButton text={t('common:print')} variant="POS" action={doPrint} />
        <UIButton text={t('common:save')} variant="POS" action={saveReport} />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 'max(500px, calc(100vh - 220px))',
        }}
      >
        <span style={{ flex: 1 }}>
          <ZReport print={print} save={browserPrint} />
        </span>
        {localStorage.getItem('Debug: zReportComparison') ? (
          <span style={{ flex: 1 }}>
            <ZReportPatchscript />
          </span>
        ) : null}
      </Modal.Body>
    </div>
  );
};
export default ZReportForm;

const ZReportPatchscript = () => {
  const [ps, setPs] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!ps) {
      dispatch(buildZReportPatchscript()).then(res => setPs(res));
    }
  }, [dispatch, ps]);
  if (!ps) return <Loader show={true} />;
  return <PatchscriptRender receiptData={ps} />;
};
