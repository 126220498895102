import { CloseBatchResponse } from '../types';

import instance from './instance';

export default async function closeBatch() {
  try {
    const response = await instance.get<CloseBatchResponse>('closeBatch');
    return response;
  } catch (err) {
    throw new Error('Chase /closeBatch request failed', { cause: err });
  }
}
