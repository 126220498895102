import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, styled, Divider } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';
import { getCustomerRewardPointsSelector } from 'reducers/customerSearch';
import {
  getTotalVisitsToStore,
  getCurrentSalesDocument,
  getIsAReturn,
} from 'reducers/sales';
import { EA_SALE } from 'plugins/taf/endlessAisle/constants';

const FlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  padding: '1rem 0',
});

const CapitalizedText = styled(Typography)({
  fontWeight: 700,
  textTransform: 'capitalize',
});

const Databox: React.FC<{
  header: string;
  body: string | number;
  bodyColor?: string;
  children?: never;
}> = ({ header, body, bodyColor }) => {
  return (
    <Box>
      <Typography>{header}</Typography>
      <CapitalizedText variant="h5" style={{ color: bodyColor ?? 'inherit' }}>
        {body}
      </CapitalizedText>
    </Box>
  );
};

const formatName = (documentType: string) => {
  switch (documentType) {
    case 'PREPAYMENT':
      return 'LAYBY';
    case 'CREDITINVOICE':
      return 'RETURN';
    default:
      return documentType;
  }
};

const UITableStats: PosPlugin['UITableStats'] = () => {
  const customerRewardPoints = useSelector(getCustomerRewardPointsSelector);
  const totalVisitsToStore = useSelector(getTotalVisitsToStore);
  const document = useSelector(getCurrentSalesDocument);
  const isReturn = useSelector(getIsAReturn);
  const documentType = isReturn ? 'CREDITINVOICE' : document?.type ?? 'SALE';
  const formattedDocumentType = formatName(documentType).toLowerCase();

  const color = (() => {
    if (isReturn || documentType === 'PREPAYMENT') return 'red';
    if (documentType === EA_SALE) return '#bd8f08';
    return 'inherit';
  })();

  return (
    <FlexBox>
      <Databox
        header="DOCUMENT"
        body={formattedDocumentType}
        bodyColor={color}
      />
      <Divider orientation="vertical" />
      <Databox header="MyFit POINTS" body={customerRewardPoints} />
      <Divider orientation="vertical" />
      <Databox header="VISIT" body={totalVisitsToStore} />
    </FlexBox>
  );
};

export default UITableStats;
