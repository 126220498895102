import { useCallback, useRef } from 'react';
import { useUpdate } from 'react-use';

/**
 * The value UNUSED represents that the function has not been called yet
 * Any other value (including undefined, null, NaN) represents the return value of the function
 */
const UNUSED = Symbol('UNUSED');

/**
 * Wraps the given function so that it can only be called once, even if the component rerenders and the function changes
 *
 * Subsequent calls skip the function and just return the same value the original one did
 * NB: This, naturally, cannot stop outside code from running - most notably if the passed in function returns a promise,
 * then the caller can attach new callbacks to it as many times as it wants
 *
 * @example
 * // Basic usage
 * const [submit, {used}] = useSingleUseFunction(async () => {
 *   await api.save(config)
 *   dispatch(previousModalPage())
 * })
 *
 * <Button onClick={submit} used={shouldDisable || used}>
 *   Submit
 * </Button>
 *
 *
 * // Advanced usage
 * const [getDefaultValues, {isUsed, reset}] = useSingleUseFunction(() => api.getDefaults());
 *
 * // Function itself only runs once (getDefaultValues), but the .then() happens every click
 * // This means we can reset as many times as we want, but we only *fetch* the data on the first click
 * <Button onClick={() =>
 *   getDefaultValues()
 *     .then(setValues) // Once the fetch succeeds, set the form values
 *     .catch(reset) // If the fetching fails, then reenable the function so we can retry
 * }>
 *   Reset to defaults
 * </Button>
 *
 *
 */
export const useSingleUseFunction = <F extends Function>(fn: F) => {
  // Using a ref guarantees we can't get race conditions
  const usedRef = useRef<F | typeof UNUSED>(UNUSED);
  // But we still need to rerender to update the return value
  // to f.ex. disable/enable buttons
  const rerender = useUpdate();
  const wrappedFn = (useCallback(
    (...params) => {
      if (usedRef.current !== UNUSED) return usedRef.current;

      const value = fn(...params);
      usedRef.current = value;
      rerender();
      return value;
    },
    [fn, rerender],
  ) as any) as F;

  return [
    wrappedFn,
    {
      isUsed: usedRef.current !== UNUSED,
      returnValue: usedRef.current,
      reset: () => {
        usedRef.current = UNUSED;
        rerender();
      },
    },
  ] as const;
};

/**
 * Wraps an async function so that it cannot be called a second time before the first is done executing.
 * Trying to do so will simply return the same promise as the original
 */
export const useAsyncFunctionNoParallelExecution = <F extends Function>(
  fn: F,
) => {
  const [wrappedFunction, actions] = useSingleUseFunction((...params) =>
    fn(...params).finally(actions.reset),
  );
  return [wrappedFunction, actions] as const;
};
