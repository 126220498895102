import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { TextField, Button, MenuItem } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';
import { addError, addSuccess } from 'actions/Error';
import { getARTemplates } from 'services/SalesAPI';
import { ARTemplateResponse } from 'services/SalesAPI/types';

import { Configuration } from '.';

export const Config: Required<
  PosPlugin
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const dispatch = useDispatch();

  const [templates, setTemplates] = useState<ARTemplateResponse[]>([]);

  useEffect(() => {
    getARTemplates().then(res => setTemplates(res));
  }, []);

  const [value, setValue] = useState<Configuration | undefined>(current);

  const onChange = key =>
    R.pipe(
      e =>
        typeof e.target.value === 'string'
          ? e.target.value.trim()
          : e.target.value,
      R.assoc(key),
      setValue,
    );

  const saveConfig = () => {
    if (!value || !Object.keys(value).length) {
      dispatch(addError('Configuration is empty'));
      return;
    }
    save(value);
    dispatch(addSuccess('Configuration saved'));
  };

  return (
    <>
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="KRA registration number (invoice_pin)"
        value={value?.invoice_pin ?? ''}
        onChange={onChange('invoice_pin')}
      />
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="Fiscal endpoint"
        value={value?.apiEndpoint ?? ''}
        onChange={onChange('apiEndpoint')}
      />
      <TextField
        select
        fullWidth
        margin="normal"
        variant="outlined"
        label="Template for e-mail and SMS invoice"
        value={value?.templateId ?? 0}
        onChange={onChange('templateId')}
      >
        <MenuItem key={0} value={0} disabled>
          <em>Select</em>
        </MenuItem>
        {templates.map(template => (
          <MenuItem key={template.id} value={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        type="email"
        margin="normal"
        variant="outlined"
        label="E-mail"
        value={value?.email ?? ''}
        onChange={onChange('email')}
        helperText="Invoice copy sent to customer will also be sent to this e-mail"
      />

      <Button variant="contained" color="primary" onClick={saveConfig}>
        Save
      </Button>
    </>
  );
};

export default Config;
