import { addError } from 'actions/Error';
import { PosPlugin } from 'plugins/plugin';
import { getCountryInUse } from 'reducers/configs/settings';
import { ThunkAction } from 'reducers';
import {
  getIsDefaultCustomer,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getCompany } from 'reducers/Login';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { updateCurrentSaleDocument } from 'actions/sales';
import { getCurrentSalesDocument } from 'reducers/sales';
import { getAppliedCouponCount, getTotal } from 'reducers/ShoppingCart';
import { ErplyAttributes, updateNotes } from 'utils';
import { validateEmail } from 'containers/Login/validations';
import { withContainingProgress } from 'actions/actionUtils';
import { endlessAisleValidateAddressPrompt } from 'plugins/taf/endlessAisle/components/EAPopup';

import { DEFAULT_NOTES, EA_SALE, MIN_TOTALS } from '../constants';

function alertWithError(message: string): ThunkAction {
  return function alert(dispatch) {
    dispatch(addError(message, { selfDismiss: 5000 }));
    throw new Error(message);
  };
}

const markDocAsStoreDelivery: ThunkAction = async function markDocAsStoreDelivery(
  dispatch,
  getState,
) {
  const { attributes, notes, internalNotes } = getCurrentSalesDocument(
    getState(),
  );
  const newAttributes = new ErplyAttributes(attributes);
  newAttributes.set('deliver_to_store', '1');
  const newNotes = `${DEFAULT_NOTES} - Deliver to store`;
  dispatch(
    updateCurrentSaleDocument({
      notes: updateNotes(newNotes, notes, { replace: DEFAULT_NOTES }),
      internalNotes: updateNotes(newNotes, internalNotes, {
        replace: DEFAULT_NOTES,
      }),
      attributes: newAttributes.asFlatArray,
    }),
  );
};

export const beforeOpenPaymentModal: Required<
  PosPlugin
>['onOpenPaymentModal']['before'] = withContainingProgress(
  p => async (dispatch, getState, progress) => {
    const { type, attributes, notes, internalNotes } = getCurrentSalesDocument(
      getState(),
    );
    if (type !== EA_SALE) return;

    const isDefaultCustomer = getIsDefaultCustomer(getState());
    if (isDefaultCustomer) {
      await dispatch(progress.halt);
      dispatch(
        alertWithError(
          'Default customer can not be assigned to endless aisle.',
        ),
      );
    }

    const {
      city,
      address,
      state,
      street,
      postalCode,
      email,
      mobile,
    } = getSelectedCustomer(getState());

    if (!city || !address || !state || !street || !postalCode) {
      await dispatch(progress.halt);
      dispatch(
        alertWithError(
          'Customer address details are not complete. Please check street address, city, state and postcode.',
        ),
      );
    }

    if (validateEmail(email).length > 0) {
      await dispatch(progress.halt);
      dispatch(alertWithError('Customer needs to have a valid email address.'));
    }

    if (!mobile) {
      await dispatch(progress.halt);
      dispatch(alertWithError('Customer needs a mobile number'));
    }

    const country = getCountryInUse(getState());
    const minTotal = MIN_TOTALS[country];
    const total = getTotal(getState());
    const appliedCouponCount = getAppliedCouponCount(getState());

    const couponsValue = appliedCouponCount * 30;

    if (minTotal > total + couponsValue) {
      await dispatch(progress.halt);
      dispatch(
        alertWithError(
          `Total sale must exceed $${minTotal} to complete an Endless Aisle Sale.`,
        ),
      );
    }

    const company = getCompany(getState());

    await dispatch(progress.halt);
    const choice = await dispatch(
      endlessAisleValidateAddressPrompt({
        companyAddress: company.address,
        customerAddress: address,
      }),
    );
    dispatch(progress.resume);

    switch (choice) {
      default: {
        // Assigning to 'never' will cause a typescript error if the switch
        // doesn't handle all the cases - basically it's an exhaustiveness check
        const _: never = choice;
        throw new Error(`Unknown choice received: ${_}`);
      }
      case 'deliverToStore':
        dispatch(markDocAsStoreDelivery);
        return;

      case 'deliverToCustomer':
        return;

      case 'updateCustomer':
        dispatch(
          openModalPage({
            component: modalPages.createCustomer,
          }),
        );
        throw new Error(
          'User chose to update customer, not opening payment modal',
        );
    }
  },
);
