import { SET_SAI_NUMBER, SET_SCANNED_QUERY } from './constants';

export function setSAINumber(saiNumber: string | null) {
  return {
    type: SET_SAI_NUMBER,
    payload: saiNumber,
  } as const;
}

export function setScannedQuery(query: string | null) {
  return {
    type: SET_SCANNED_QUERY,
    payload: query,
  } as const;
}
