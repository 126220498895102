import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { reactcursivelyMap } from 'utils/react';

const UIUserBadge: PosPlugin['UIUserBadge'] = ({ isCustomer, children }) => {
  if (!isCustomer) return children;

  return reactcursivelyMap(
    R.reject(R.pathEq(['props', 'data-testid'], 'customer-card-number')),
  )(children);
};

export default UIUserBadge;
