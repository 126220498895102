import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import {
  PatchscriptTemplateEditor,
  PatchscriptPreview,
  PatchscriptTemplate,
} from '@pos-refacto/patchscript-with-react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import {
  saveCafaConfig,
  deleteCAFAConfig,
} from 'actions/integrations/CafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import {
  getTemplateForType,
  sampleDataForType,
  hasTemplateForType,
  getDefaultTemplateForType,
} from 'reducers/patchScript';
import Loader from 'components/Loader';
import UIButton from 'components/UIElements/UIButton';
import { composeReceiptWithSchema } from 'reducers/patchScript/composeReceiptWithSchema';
import { getClientCode, getSessionKey } from 'reducers/Login';
import { clearCachedTemplate } from 'actions/integrations/printer/receiptPrintoutApi/api';

const importReactNewWindow = () =>
  import('react-new-window').then(a => a.default);

type ReactNewWindowLib = ReturnType<
  typeof importReactNewWindow
> extends Promise<infer T>
  ? T
  : never;

export const ReceiptSchema = ({
  receiptType = 'salesReceipt',
}: {
  receiptType: Parameters<typeof sampleDataForType>[0];
}) => {
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();

  /* Dynamically loaded libraries */
  const [[Window], setWindow] = useState<[undefined | ReactNewWindowLib]>([
    undefined,
  ]);
  /* Sample data - last receipt */
  const [sampleData, setSampleData] = useState<any>({});
  useEffect(() => {
    dispatch(sampleDataForType(receiptType)).then(setSampleData);
  }, [dispatch, receiptType]);
  /* Template - editable by the user but resets if changed externally */
  const [template, setTemplate] = useState<PatchscriptTemplate>([]);
  const hasTemplate = useSelector(hasTemplateForType(receiptType));
  const currentTemplate = useSelector(getTemplateForType(receiptType));
  const defaultTemplate = useSelector(getDefaultTemplateForType(receiptType));
  useEffect(() => setTemplate(currentTemplate), [currentTemplate]);

  const sessionKey = useSelector(getSessionKey);
  const clientCode = useSelector(getClientCode);

  const persist = () => {
    if (template === undefined) {
      dispatch(
        deleteCAFAConfig({
          integrationName: receiptType,
          integrationType: INTEGRATION_TYPES.patchScriptTemplate,
        }),
      );
    } else {
      dispatch(
        saveCafaConfig({
          integrationName: receiptType,
          integrationType: INTEGRATION_TYPES.patchScriptTemplate,
          config: template,
        }),
      );
    }
    clearCachedTemplate(sessionKey, clientCode);
  };
  const openEditor = async () => {
    setWindow([await import('react-new-window').then(a => a.default)]);
  };
  const loadFromClipboard = () =>
    navigator.clipboard
      .readText()
      .then(JSON.parse)
      .then(setTemplate);
  const saveToClipboard = () =>
    navigator.clipboard.writeText(JSON.stringify(template));

  return (
    <>
      <button type="button" onClick={loadFromClipboard}>
        Load schema from clipboard
      </button>
      <button type="button" onClick={saveToClipboard}>
        Copy schema to clipboard
      </button>
      <br />

      {currentTemplate !== template ? (
        <>
          <button type="button" onClick={persist}>
            Save schema
          </button>
          <br />
        </>
      ) : null}
      {Window ? (
        <>
          <Window onUnload={() => setWindow([undefined])}>
            <ErrorBoundary
              fallback={({ error, resetError }) => (
                <>
                  <h1>An error has occured in the patchscript editor</h1>
                  <p>{error?.message}</p>
                  <button type="button" onClick={resetError}>
                    Retry
                  </button>
                  <button type="button" onClick={() => setWindow([undefined])}>
                    Close
                  </button>
                </>
              )}
            >
              <div style={{ height: '100vh', overflowY: 'auto' }}>
                <PatchscriptTemplateEditor
                  slice={template ?? defaultTemplate}
                  onChange={setTemplate}
                  scope={sampleData}
                />
              </div>
            </ErrorBoundary>
          </Window>
        </>
      ) : (
        <button type="button" onClick={openEditor}>
          Open schema editor
        </button>
      )}
      <div
        style={{
          color: 'black',
          backgroundColor: 'white',
          padding: '1ch 2ch 1ch 1ch',
        }}
      >
        <Loader
          loadingText="Loading last invoice"
          show={Object.keys(sampleData).length === 0}
        >
          <ErrorBoundary
            fallback={({ error, resetError }) => (
              <>
                <h1>An error has occured trying to preview the receipt</h1>
                <p>{error?.message}</p>
                <button type="button" onClick={resetError}>
                  Retry
                </button>
              </>
            )}
          >
            <PatchscriptPreview
              receiptData={composeReceiptWithSchema(
                template ?? defaultTemplate,
                sampleData,
              )}
            />
          </ErrorBoundary>
        </Loader>
      </div>
      {hasTemplate ? (
        <UIButton
          text="Revert to default schema"
          variant="danger"
          action={() => setTemplate(undefined)}
        />
      ) : null}
    </>
  );
};
