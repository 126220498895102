import { Address } from 'types/Address';

export type Person = {
  firstName: string;
  lastName: string;
  customerCardNumber: string;
  groupID: string;
  homeStoreID: number;
  birthday: string;
  email: string;
  phone: string;
  mobile: string;
  street: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  code: string;
  notes: string;
  emailOptOut: number;
  customerType: 'PERSON';
  addressTypeID: string;
  salesBlocked: number;
  addresses?: Address[];
  newAddress?: Address;
  [key: string]: unknown;
};
export const personInitState: Person = {
  firstName: '',
  lastName: '',
  customerCardNumber: '',
  groupID: '',
  homeStoreID: 0,
  birthday: '',
  email: '',
  phone: '',
  mobile: '',
  street: '',
  address2: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  code: '',
  notes: '',
  emailOptOut: 0,
  customerType: 'PERSON',
  addressTypeID: '',
  salesBlocked: 0,
};

export type Company = {
  companyName: string;
  vatNumber: string;
  customerCardNumber: string;
  groupName: string;
  homeStoreID: number;
  email: string;
  phone: string;
  mobile: string;
  street: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  code: string;
  notes: string;
  emailOptOut: number;
  customerType: 'COMPANY';
  addressTypeID: string;
  salesBlocked: number;
  addresses?: Address[];
  newAddress?: Address;
  [key: string]: unknown;
};
export const companyInitState: Company = {
  companyName: '',
  vatNumber: '',
  customerCardNumber: '',
  groupName: '',
  homeStoreID: 0,
  email: '',
  phone: '',
  mobile: '',
  street: '',
  address2: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  code: '',
  notes: '',
  emailOptOut: 0,
  customerType: 'COMPANY',
  addressTypeID: '',
  salesBlocked: 0,
};
