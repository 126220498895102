import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { Action } from 'redux';
import * as R from 'ramda';

import { UrlControl } from 'services/UrlControl/UrlControl';
import { softLogout } from 'actions/Login';
import {
  setCurrentSalesDocument,
  setIsCurrentSaleAReturn,
  cancelLayaway,
} from 'actions/sales';
import * as salesApi from 'services/ErplyAPI/sales';
import { selectOneCustomer } from 'actions/customerSearch';
import { openPaymentModal } from 'actions/modalPage';

/**
 * Call with the result when some UrlControl action has finished
 *
 * Sends the result to the caller and does configured cleanup action
 */
const afterActionFinished = (
  result: any,
): ThunkAction<void, unknown, unknown, Action> => async dispatch => {
  const redirect = UrlControl.afterActionFinished;

  // If have connection to opener, send result directly
  if (window.opener) window.opener.postMessage(result);

  // If specified as URL, redirect and include result as query params
  if (redirect instanceof URL) {
    redirect.search = qs.stringify(result);
    window.location.href = redirect.href;
    return;
  }
  // Other cases are constant strings
  switch (redirect) {
    // If stay, do nothing, let POS continue
    case 'stay':
      return;

    // If unknown, warn in console and fall back to 'close' behaviour by default
    default:
      console.error(
        `unknown UrlControl action ${redirect}, defaulting to close`,
      );
    // fallthrough
    case 'close':
      window.close();
      // Because window.close may refuse on certain browsers
      // f.ex. chrome only allows it if the page is opened by javascript in the first place
      // we should also 'close' the POS via redirect
      dispatch(softLogout()).then(() => {
        alert('You may now close this tab');
        window.location.replace('about:blank');
      });
  }
};

export const UrlControlActions = {
  /** Callbacks to resolve or reject a payment action */
  payment: {
    resolve: (data: unknown): void => undefined,
    reject: (error: unknown): void => undefined,
  },
  /**
   * If there is a URLControl action to open payment, load the relevant data and open it now
   *
   * This should be called when the main POS has loaded
   */
  initRelevantPayment: async (dispatch) => {
    if (UrlControl.actionCancelLayaway) {
      const { id } = UrlControl.actionCancelLayaway;
      const [document] = await salesApi.getSalesDocuments({
        id,
        getUnfulfilledDocuments: 1,
        getReturnedPayments: 1,
      });
      return new Promise((resolve, reject) => {
        UrlControlActions.payment = { resolve, reject };
        dispatch(cancelLayaway(document));
      }).then(
        data => dispatch(afterActionFinished({ result: 'succeeded', data })),
        error => dispatch(afterActionFinished({ result: 'failed', error })),
      );
    }

    if (UrlControl.actionReturnSale) {
      const {
        id,
        returnReasonID,
        extraParams
      } = UrlControl.actionReturnSale;
      const [document] = await salesApi.getSalesDocuments({
        id,
        getReturnedPayments: 1
      });
      const returnRows = UrlControl.actionReturnSale.returnRows ??
      // If return rows not specified, do a full return by default
        document.rows.map(R.evolve({
          amount: a => -a,
          stableRowID: String
        }));

      // Workaround - POS requires both of these states in order to consider showing the original payments list
      // TODO: prop 'total' should override it
      dispatch(setCurrentSalesDocument(document));
      dispatch(setIsCurrentSaleAReturn(true));

      const originalPayments = salesApi.getPayments({ documentID: document.id });
      const returnedPayments = document.returnedPayments.length
        ? salesApi.getPayments({
          paymentIDs: document.returnedPayments.flatMap(p => p.ids).join(",")
        })
        : Promise.resolve([]);

      // Loading the customer allows showing their name and allowing
      // the customer's store credit to be used
      // TODO: openPaymentModal, when passed clientID, should be capable of loading this data
      const customer = dispatch(
        selectOneCustomer({
          customerID: extraParams?.clientID ?? document.clientID
        })
      );

      const returnDocument = {
        creditToDocumentID: id,
        type: "CREDITINVOICE",
        // Necessary to avoid 'by credit invoice' payments being autogenerated
        isCashInvoice: ["INVWAYBILL", "CASHINVOICE"].includes(document.type)
          ? 1
          : 0,
        // Cannot use .rows because several pieces of POS code assume that .rows
        // must contain the ORIGINAL rows of the source document
        // Potential todo: Split storage of *current* sales document and *original* sales document

        modifiedRows: document.rows
          .map((row, i) => {
            const rr = returnRows.find(rr => Number(rr.stableRowID) === Number(row.stableRowID));
            const rowNumber = returnRows.indexOf(rr!) + 1;
            if (!rr) return undefined;
            return {
              productID: row.productID,
              itemName: row.itemName,
              price: row.price,
              vatrateID: row.vatrateID,
              stableRowID: row.stableRowID,
              discount: row.discount,
              rowNumber, // only present in calculateShoppingCart request, but necessary to create valid creditedStableRowID links
              // ↑ from original document ↑
              // ↓ from return parameters ↓
              amount: -rr.amount,
              returnReasonID: rr.returnReasonID ?? returnReasonID,
            };
          })
          .filter(a => a),
        total: -Math.min(
          Number(document.paid),
          document.rows
            .map(row => {
              const rr = returnRows.find(
                rr => String(rr.stableRowID) === String(row.stableRowID)
              );
              if (!rr) return 0;
              return row.rowTotal * (rr.amount / row.amount);
            })
            .reduce((a, b) => a + b, 0)
        ),
        ...extraParams
      };
      UrlControl.log('from document', document, 'created return params', returnDocument);
      const start = openPaymentModal({
        props: {
          salesDocument: returnDocument,
          originalPayments: await originalPayments,
          returnedPayments: await returnedPayments,
          total: returnDocument.total,
          customer: await customer,
        },
      });
      return new Promise((resolve, reject) => {
        UrlControlActions.payment = { resolve, reject };
        dispatch(start);
      }).then(
        data => dispatch(afterActionFinished({ result: 'succeeded', data })),
        error => dispatch(afterActionFinished({ result: 'failed', error })),
      );
    }
  },
};
