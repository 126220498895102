import React, { useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import { v1 as uuidv1 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { ProductItem } from 'containers/Col2/Products';
import { ComponentConfiguration } from 'plugins/customButtons/Configuration';
import { getProductsUniversal } from 'actions/productsDB';

import { PosPlugin } from '../plugin';
import { getPluginConfiguration } from '../../reducers/Plugins';

import { reducer, saveProducts, getProductByID } from './redux';
import { pluginID } from './constants';

export type Configuration = {
  buttons: {
    name: string;
    url: string;
    enabled: boolean;
  }[];
  products: {
    productID: string;
    enabled: true;
  }[];
};

const LoadingProductItem = ({ productID }) => {
  const product = useSelector(getProductByID(productID));

  return (
    <ProductItem
      item={product ?? { productID, name: 'Loading...', price: '0.00' }}
    />
  );
};

const getConfiguration = state =>
  getPluginConfiguration<Configuration>('customButtons')(state);
const customButtonsPlugin: PosPlugin = {
  id: pluginID,
  name: 'Custom POS buttons',
  // language=Markdown
  info: `Enables adding custom buttons to the saleOptions section of the POS which open a user-defined URL when
  clicked`,
  keywords: ['common', 'UI', 'saleOptions', 'grid', 'buttons'],
  ComponentHeader: ({ children }) => {
    const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();

    const conf = useSelector(getConfiguration);
    const productIDs = useMemo(
      () => conf?.products?.map(product => Number(product.productID)),
      [conf],
    );

    useEffect(() => {
      dispatch(
        getProductsUniversal({ productIDs }, { addToCachedItems: true }),
      ).then(({ products, productsDict }) => {
        if (products.length) {
          dispatch(saveProducts(productsDict));
        }
      });
    }, [productIDs, dispatch]);

    return children;
  },
  getStatus: createSelector(getConfiguration, (conf: Configuration) => {
    if (!conf) return { type: 'error', message: 'No configuration' };
    if (!conf?.buttons?.length && !conf?.products?.length)
      return { type: 'error', message: 'No buttons configured' };
    if (
      !conf?.buttons?.some(btn => btn.enabled) &&
      !conf.products?.some(btn => btn.enabled)
    )
      return { type: 'warning', message: 'No buttons enabled' };
    return { type: 'valid', message: '' };
  }),
  selectorOverrides: {
    getFunctionButtons: base =>
      createSelector(base, getConfiguration, (buttons, conf) =>
        buttons.concat(
          conf?.buttons
            .filter(b => b.enabled)
            .map(b => ({
              id: uuidv1(),
              name: b.name,
              actionType: 'action',
              action: () => window.open(b.url),
            })) ?? [],
        ),
      ),
    getSaleOptionButtons: base =>
      createSelector(base, getConfiguration, (buttons, conf) =>
        buttons.concat(
          conf?.products
            ?.filter(b => b.enabled)
            .map(p => p.productID)
            .map(id => ({
              id,
              view: () => <LoadingProductItem productID={id} />,
            })) ?? [],
        ),
      ),
  },
  ComponentConfiguration,

  // Store products in redux to prevent them from disappearing on cache reset
  reduxReducer: (state, action) => {
    return reducer(state, action);
  },
};
export default customButtonsPlugin;
