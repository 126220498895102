import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import * as R from 'ramda';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';

import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import { CustomerDisplayLocaleField } from 'plugins/customerDisplay/components';

import { CheckInConfigType, Configuration, DateTimeConfigType } from './types';
import { customerCreationFields } from './constants';

import {
  getLocalizationConstantsFromDisplayConfig,
  getSettingsFromDisplayConfig,
} from '.';

type VisualConfigProps = {
  fieldTranslations: Configuration['fieldTranslations'];
  saveCustomerFormTranslations: (
    nc: Configuration['fieldTranslations'],
  ) => void;
  config: Configuration['config'];
  saveLocales: (nc: Configuration['config']['localizationConstants']) => void;
  saveSettings: (nc: Configuration['config']['settings']) => void;
  resetConfig: () => void;
};

export const VisualsConfig: FC<VisualConfigProps> = ({
  config,
  fieldTranslations,
  saveCustomerFormTranslations,
  saveLocales,
  saveSettings,
  resetConfig,
}) => {
  const inputClass = classNames([styles.formInput, styles.longTitle]);
  const { t } = useTranslation('customerDisplay');

  const localizationConstants = useSelector(
    getLocalizationConstantsFromDisplayConfig,
  );
  const settingsDefault = useSelector(getSettingsFromDisplayConfig);
  const isBrandField = (_, key) =>
    key.includes('brandLogo') || key.includes('brandText');
  const isCouponField = (_, key) => key.includes('coupon');
  const isDonationField = (_, key) => key.includes('donation');
  const isLookupField = (_, key) => key.includes('Lookup');
  const isRegisterCustomerField = (_, key) =>
    key.includes('registerCustomer') && key !== 'registerCustomerForm';
  const isUpsellField = (_, key) => key.includes('upsell');
  const isGeneralField = (_, key) =>
    key.includes('thankYou') ||
    key.includes('summary') ||
    key.includes('table');
  const isCheckInField = (_, key) => key.includes('CheckIn');

  const originalBrandConf = R.pickBy(isBrandField, config);
  const brandDefault = R.pickBy(isBrandField, localizationConstants);
  const originalDonationConf = R.pickBy(isDonationField, config);
  const donationDefault = R.pickBy(isDonationField, localizationConstants);
  const originalCouponConf = R.pickBy(isCouponField, config);
  const couponDefault = R.pickBy(isCouponField, localizationConstants);
  const originalLookupConf = R.pickBy(isLookupField, config);
  const lookupDefault = R.pickBy(isLookupField, localizationConstants);
  const originalRegisterCustomerConf = R.pickBy(
    isRegisterCustomerField,
    config,
  );
  const originalRegisterCustomerFormFields = fieldTranslations;

  const registerCustomerDefault = R.pickBy(
    isRegisterCustomerField,
    localizationConstants,
  );
  const originalUpsellConf = R.pickBy(isUpsellField, config);
  const upsellDefault = R.pickBy(isUpsellField, localizationConstants);
  const originalGeneralConf = R.pickBy(isGeneralField, config);
  const generalDefault = R.pickBy(isGeneralField, localizationConstants);
  const originalCheckInConf = R.pickBy(isCheckInField, config);
  const checkInDefault = R.pickBy(isCheckInField, localizationConstants);

  const [brandConfState, setBrandConf] = useState(originalBrandConf ?? {});
  const [donationConfState, setDonationConf] = useState(
    originalDonationConf ?? {},
  );
  const [couponConfState, setCouponConf] = useState(originalCouponConf ?? {});
  const [lookupConfState, setLookupConf] = useState(originalLookupConf ?? {});
  const [registerCustomerConfState, setRegisterCustomerConf] = useState(
    originalRegisterCustomerConf ?? {},
  );
  const [registerCustomerFormState, setRegisterCustomerFormState] = useState(
    originalRegisterCustomerFormFields ?? {},
  );
  const [upsellConfState, setUpsellConf] = useState(originalUpsellConf ?? {});
  const [generalConfState, setGeneralConf] = useState(
    originalGeneralConf ?? {},
  );
  const [settingsConfState, setSettingsConf] = useState(
    R.mergeRight(settingsDefault, config?.settings),
  );
  const [checkInConfState, setCheckInConf] = useState(originalCheckInConf);

  const lookUpCustomerMessageOptions = [
    {
      value: 'GET_CUSTOMER_PHONE',
      name: t('headerIcon.searchCustomerByPhone', {
        context: 'phone',
      }),
    },
    {
      value: 'GET_CUSTOMER_EMAIL',
      name: t('headerIcon.searchCustomerByEmail', {
        context: 'email',
      }),
    },
  ];

  const handleSave = (type: string) => {
    if (type === 'brand') {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...brandConfState,
      });
    } else if (type === 'donation') {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...donationConfState,
      });
    } else if (type === 'coupon') {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...couponConfState,
      });
    } else if (type === 'lookup') {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...lookupConfState,
      });
    } else if (type === 'registerCustomer') {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...registerCustomerConfState,
      });
    } else if (type === 'upsell') {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...upsellConfState,
      });
    } else if (type === 'settings') {
      saveSettings({
        ...(config?.settings ?? {}),
        ...settingsConfState,
      });
    } else if (type === 'checkIn') {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...checkInConfState,
      });
    } else if (type === 'registerCustomerForm') {
      saveCustomerFormTranslations(registerCustomerFormState);
    } else {
      saveLocales({
        ...(config?.localizationConstants ?? {}),
        ...generalConfState,
      });
    }
  };

  const isDisabled = type => {
    if (type === 'brand') {
      return R.equals(R.pickBy(isBrandField, config), brandConfState);
    }
    if (type === 'donation') {
      return R.equals(R.pickBy(isDonationField, config), donationConfState);
    }
    if (type === 'coupon') {
      return R.equals(R.pickBy(isCouponField, config), couponConfState);
    }
    if (type === 'lookup') {
      return R.equals(R.pickBy(isLookupField, config), lookupConfState);
    }
    if (type === 'registerCustomer') {
      return R.equals(
        R.pickBy(isRegisterCustomerField, config),
        registerCustomerConfState,
      );
    }
    if (type === 'upsell') {
      return R.equals(R.pickBy(isUpsellField, config), upsellConfState);
    }
    if (type === 'settings') {
      return R.equals(config.settings, settingsConfState);
    }
    if (type === 'checkIn') {
      return R.equals(R.pickBy(isCheckInField, config), checkInConfState);
    }
    if (type === 'registerCustomerForm') {
      return R.equals(registerCustomerFormState, originalRegisterCustomerConf);
    }
    return R.equals(R.pickBy(isGeneralField, config), generalConfState);
  };

  const handleChange = (key, value, type) => {
    if (type === 'brand') {
      setBrandConf({
        ...brandConfState,
        [key]: value,
      });
    } else if (type === 'donation') {
      setDonationConf({
        ...donationConfState,
        [key]: value,
      });
    } else if (type === 'coupon') {
      setCouponConf({
        ...couponConfState,
        [key]: value,
      });
    } else if (type === 'lookup') {
      setLookupConf({
        ...lookupConfState,
        [key]: value,
      });
    } else if (type === 'registerCustomer') {
      setRegisterCustomerConf({
        ...registerCustomerConfState,
        [key]: value,
      });
    } else if (type === 'upsell') {
      setUpsellConf({
        ...upsellConfState,
        [key]: value,
      });
    } else if (type === 'settings') {
      if (['dateFormat', 'timeFormat'].includes(key)) {
        setSettingsConf({
          ...settingsConfState,
          dateTimeConfig: {
            ...settingsConfState?.dateTimeConfig,
            [key]: value,
          },
        });
      }
      if (key === 'lookUpCustomerMessage') {
        setSettingsConf({
          ...settingsConfState,
          checkInConfig: {
            ...settingsConfState?.checkInConfig,
            [key]: {
              action: value,
            },
          },
        });
      } else {
        setSettingsConf({
          ...settingsConfState,
          [key]: key === 'purchaseInactivityTimeout' ? Number(value) : value,
        });
      }
    } else if (type === 'checkIn') {
      setCheckInConf({
        ...checkInConfState,
        [key]: value,
      });
    } else if (type === 'registerCustomerForm') {
      const [inputType, inputKey] = key.split('_');
      setRegisterCustomerFormState({
        ...registerCustomerFormState,
        [inputType]: {
          ...(registerCustomerFormState[inputType] ?? {}),
          [inputKey]: value,
        },
      });
    } else {
      setGeneralConf({
        ...generalConfState,
        [key]: value,
      });
    }
  };

  const brandGroup = {
    id: 'brand',
    values: R.mergeRight(brandDefault, brandConfState),
  };
  const donationGroup = {
    id: 'donation',
    values: R.mergeRight(donationDefault, donationConfState),
  };
  const couponGroup = {
    id: 'coupon',
    values: R.mergeRight(couponDefault, couponConfState),
  };
  const lookupGroup = {
    id: 'lookup',
    values: R.mergeRight(lookupDefault, lookupConfState),
  };
  const registerCustomerGroup = {
    id: 'registerCustomer',
    values: R.mergeRight(registerCustomerDefault, registerCustomerConfState),
  };
  const upsellGroup = {
    id: 'upsell',
    values: R.mergeRight(upsellDefault, upsellConfState),
  };
  const generalGroup = {
    id: 'general',
    values: R.mergeRight(generalDefault, generalConfState),
  };
  const settingsGroup = {
    id: 'settings',
    values: R.mergeRight(settingsDefault, settingsConfState),
  };
  const checkInGroup = {
    id: 'checkIn',
    values: R.mergeRight(checkInDefault, checkInConfState),
  };
  const registerCustomerFormGroup = {
    id: 'registerCustomerForm',
    values: registerCustomerFormState,
  };
  const allGroups = [
    brandGroup,
    donationGroup,
    couponGroup,
    lookupGroup,
    checkInGroup,
    upsellGroup,
    generalGroup,
    settingsGroup,
    registerCustomerGroup,
  ];

  return (
    <>
      <h4>{t('titles.displayAppConfig')}</h4>
      {allGroups.map(group => {
        return (
          <Accordion key={group.id}>
            <AccordionSummary>
              <Typography>
                {t(`configurationForm.groups.${group.id}`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }}>
              {Object.entries(group.values).map(([key, value]) => {
                if (key === 'defaultPhoneSmallKeyboardState') {
                  return (
                    <InputField
                      key={key}
                      className={inputClass}
                      title={t(`configurationForm.${key}`)}
                      value={value}
                      options={['NUMBERS']}
                      type="select"
                      onChange={e =>
                        handleChange(key, e.target.value, group.id)
                      }
                      size="md"
                    />
                  );
                }
                if (key === 'showWideKeyboard') {
                  return (
                    <InputField
                      key={key}
                      title={t(`configurationForm.${key}`)}
                      value={value}
                      type="checkbox"
                      onChange={e =>
                        handleChange(key, e.target.value, group.id)
                      }
                      size="lg"
                    >
                      {t(`configurationForm.${key}`)}
                    </InputField>
                  );
                }
                if (key === 'showDefaultContent') {
                  return (
                    <InputField
                      key={key}
                      title={t(`configurationForm.${key}`)}
                      value={'PROMOTIONS' === value}
                      type="checkbox"
                      onChange={e =>
                        handleChange(
                          key,
                          e.target.value ? 'PROMOTIONS' : 'NONE',
                          group.id,
                        )
                      }
                      size="lg"
                    >
                      {t(`configurationForm.${key}`)}
                    </InputField>
                  );
                }
                if (key === 'checkInConfig') {
                  return Object.entries((value ?? {}) as CheckInConfigType).map(
                    ([conf, confValue]) => {
                      // Currently, show only conf for looking customer up
                      if (conf === 'lookUpCustomerMessage') {
                        return (
                          <InputField
                            key="lookUpCustomerMessage"
                            className={inputClass}
                            title={t('configurationForm.lookUpCustomerMessage')}
                            value={confValue.action}
                            options={lookUpCustomerMessageOptions}
                            type="select"
                            onChange={e => {
                              handleChange(
                                'lookUpCustomerMessage',
                                e.target.value,
                                group.id,
                              );
                            }}
                            size="md"
                          />
                        );
                      }
                      return null;
                    },
                  );
                }
                if (group.id === 'settings') {
                  if (key === 'dateTimeConfig') {
                    return Object.entries(
                      (value ?? {}) as DateTimeConfigType,
                    ).map(([dateTimeKey, dateTimeVal]) => {
                      return (
                        <InputField
                          key={dateTimeKey}
                          className={inputClass}
                          title={t(`configurationForm.${dateTimeKey}`)}
                          value={dateTimeVal}
                          options={
                            dateTimeKey === 'dateFormat'
                              ? ['EU', 'US']
                              : ['24H', '12H']
                          }
                          type="select"
                          onChange={e =>
                            handleChange(dateTimeKey, e.target.value, group.id)
                          }
                          size="md"
                        />
                      );
                    });
                  }
                  return (
                    <InputField
                      key={key}
                      className={inputClass}
                      title={t(`configurationForm.${key}`)}
                      value={value}
                      type="text"
                      onChange={e =>
                        handleChange(key, e.target.value, group.id)
                      }
                      size="md"
                    />
                  );
                }
                return (
                  <CustomerDisplayLocaleField
                    key={key}
                    name={key}
                    label={t(`configurationForm.${key}`)}
                    value={value as string | undefined}
                    type="text"
                    setValue={v => handleChange(key, v, group.id)}
                  />
                );
              })}
            </AccordionDetails>
            <AccordionActions>
              <UIButton
                text="Save config"
                action={() => handleSave(group.id)}
                disabled={isDisabled(group.id)}
              />
            </AccordionActions>
          </Accordion>
        );
      })}
      <Accordion key={registerCustomerFormGroup.id}>
        <AccordionSummary>
          <Typography>
            {t(`configurationForm.groups.${registerCustomerFormGroup.id}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {customerCreationFields.map(key => (
            <>
              <CustomerDisplayLocaleField
                key={`registerCustomerForm.label.${key}`}
                name={`registerCustomerForm.label.${key}`}
                // Do not confuse t function path with the key/name path
                label={t(`configurationForm.customerRegistration.label.${key}`)}
                value={registerCustomerFormGroup.values?.label?.[key]}
                type="text"
                setValue={v =>
                  handleChange(`label_${key}`, v, registerCustomerFormGroup.id)
                }
              />
              {['emailOptOut', 'termsAndConditions', 'privacy'].some(
                s => s === key,
              ) ? null : (
                <CustomerDisplayLocaleField
                  key={`registerCustomerForm.requiredMessage.${key}`}
                  name={`registerCustomerForm.requiredMessage.${key}`}
                  // Do not confuse t function path with the key/name path
                  label={t(
                    `configurationForm.customerRegistration.requiredMessage.${key}`,
                  )}
                  value={
                    registerCustomerFormGroup.values?.requiredMessage?.[key]
                  }
                  type="text"
                  setValue={v =>
                    handleChange(
                      `requiredMessage_${key}`,
                      v,
                      registerCustomerFormGroup.id,
                    )
                  }
                />
              )}
              {['emailOptOut', 'termsAndConditions', 'privacy'].some(
                s => s === key,
              ) ? null : (
                <CustomerDisplayLocaleField
                  key={`registerCustomerForm.placeholder.${key}`}
                  name={`registerCustomerForm.placeholder.${key}`}
                  // Do not confuse t function path with the key/name path
                  label={t(
                    `configurationForm.customerRegistration.placeholder.${key}`,
                  )}
                  value={registerCustomerFormGroup.values?.placeholder?.[key]}
                  type="text"
                  setValue={v =>
                    handleChange(
                      `placeholder_${key}`,
                      v,
                      registerCustomerFormGroup.id,
                    )
                  }
                />
              )}
              {key === 'termsAndConditions' ? (
                <CustomerDisplayLocaleField
                  key={`registerCustomerForm.message.${key}`}
                  name={`registerCustomerForm.message.${key}`}
                  // Do not confuse t function path with the key/name path
                  label={t(
                    `configurationForm.customerRegistration.message.${key}`,
                  )}
                  value={registerCustomerFormGroup.values?.message?.[key]}
                  type="text"
                  multiline
                  maxRows={20}
                  setValue={v =>
                    handleChange(
                      `message_${key}`,
                      v,
                      registerCustomerFormGroup.id,
                    )
                  }
                />
              ) : null}
              {key === 'privacy' ? (
                <CustomerDisplayLocaleField
                  key={`registerCustomerForm.link.${key}`}
                  name={`registerCustomerForm.link.${key}`}
                  // Do not confuse t function path with the key/name path
                  label={t(
                    `configurationForm.customerRegistration.link.${key}`,
                  )}
                  value={registerCustomerFormGroup.values?.link?.[key]}
                  type="text"
                  setValue={v =>
                    handleChange(`link_${key}`, v, registerCustomerFormGroup.id)
                  }
                />
              ) : null}
            </>
          ))}
        </AccordionDetails>
        <AccordionActions>
          <UIButton
            text="Save config"
            action={() => handleSave(registerCustomerFormGroup.id)}
            disabled={isDisabled(registerCustomerFormGroup.id)}
          />
        </AccordionActions>
      </Accordion>
      <UIButton text={t('buttons.resetConfig')} action={resetConfig} />
      <hr />
    </>
  );
};
