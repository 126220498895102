import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useDebounce, useInterval } from 'react-use';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useTranslation } from 'react-i18next';

import { openPluginModalPage } from 'actions/modalPage';
import { modals } from 'plugins/mm/constants';
import { getCurrentModalPage } from 'reducers/modalPage';
import Icon from 'components/Icon';
import { getHasOnlineOrdersToView, getOnlineOrderAmount } from 'plugins/mm/rdx';
import { RootState } from 'reducers';
import { getCurrencyFormatterNoSymbol } from 'reducers/configs/settings';
import { HeaderIconButton } from 'containers/Header/components/Icon';

import { fetchOnlineOrderData, OnlineOrders } from '../actions';

import styles from './OrderNotifications.module.scss';

function OrderNotificationsIcon() {
  const { t } = useTranslation('orderNotifications');
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const [newOnlineOrders, setNewOnlineOrders] = useState<OnlineOrders[]>([]);
  const [amountOfNewOrders, setAmountOfNewOrders] = useState<number>(0);
  const ref = useRef(null);

  const orderAmount = useSelector(getOnlineOrderAmount);
  const hasOnlineOrdersToView = useSelector(getHasOnlineOrdersToView);
  const openedModalPage = useSelector(getCurrentModalPage);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);

  useDebounce(
    () => {
      dispatch(fetchOnlineOrderData()).then(
        ({ newOrders, amountOfNewOrders }) => {
          if (!amountOfNewOrders) return;
          setNewOnlineOrders(newOrders);
          setAmountOfNewOrders(amountOfNewOrders);
        },
      );
    },
    /**
     * Big interval means that even though this component will remount a handful of times on startup
     * (because parent component (header) re-renders), the inner function will be called only once.
     */
    5e3,
    [dispatch],
  );

  useInterval(() => {
    dispatch(fetchOnlineOrderData()).then(
      ({ newOrders, amountOfNewOrders }) => {
        if (!amountOfNewOrders) return;
        setNewOnlineOrders(newOrders);
        setAmountOfNewOrders(amountOfNewOrders);
      },
    );
  }, 60e3);

  function openOrderNotifications() {
    if (openedModalPage?.props?.name === modals.ORDER_NOTIFICATIONS) return;
    dispatch(
      openPluginModalPage(modals.ORDER_NOTIFICATIONS)({
        modalClassName: styles['order-notifications-modal'],
      }),
    );
  }

  return (
    <>
      <li>
        <Badge
          badgeContent={orderAmount}
          color="primary"
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <HeaderIconButton
            size="small"
            onClick={openOrderNotifications}
            ref={ref}
          >
            <Icon
              title="Order notifications"
              name="icon_bag_alt"
              style={hasOnlineOrdersToView ? { color: 'red' } : {}}
            />
          </HeaderIconButton>
        </Badge>
      </li>
      <Popover
        open={!!newOnlineOrders.length}
        anchorEl={ref.current}
        onClose={() => {
          setNewOnlineOrders([]);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ zIndex: 2 }}
      >
        <TableContainer style={{ minWidth: '100', width: '300px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('newOrders', { count: amountOfNewOrders })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newOnlineOrders.map(order => (
                <TableRow key={order.id}>
                  <TableCell>#{order.webShopOrderNumbers[0]}</TableCell>
                  <TableCell>{CURR.stringify(order.total)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {amountOfNewOrders > newOnlineOrders.length ? (
              <caption>
                {t('moreNewOrders', {
                  count: amountOfNewOrders - newOnlineOrders.length,
                })}
              </caption>
            ) : null}
          </Table>
        </TableContainer>
      </Popover>
    </>
  );
}

export default OrderNotificationsIcon;
