/* eslint-disable import/prefer-default-export */
import { Payment } from 'types/Payment';
import {
  PrepaymentPercentage,
  SaleDocumentResponse,
  SaveTransferParams,
  Transfer,
} from 'types/SalesDocument';

import { doClientRequest, requestCompleteResponse } from './core/ErplyAPI';
import { CalculateShoppingCartResponse } from './core/types';

export const getSalesDocuments = queryProps =>
  doClientRequest<SaleDocumentResponse>({
    ...queryProps,
    request: 'getSalesDocuments',
  });
export const getPayments = queryProps =>
  doClientRequest<Payment>({
    ...queryProps,
    request: 'getPayments',
  });
export const saveSalesDocument = queryProps =>
  doClientRequest({
    ...queryProps,
    request: 'saveSalesDocument',
  });

export const deleteSalesDocument = documentID =>
  doClientRequest({
    request: 'deleteSalesDocument',
    documentID,
  });

export const getSalesDocumentsCompleteResponse = queryProps =>
  requestCompleteResponse({
    ...queryProps,
    request: 'getSalesDocuments',
  });

export const calculateShoppingCart = queryProps =>
  doClientRequest<CalculateShoppingCartResponse>({
    ...queryProps,
    request: 'calculateShoppingCart',
  });

export const getPrepaymentPercentages = params =>
  doClientRequest<PrepaymentPercentage>({
    ...params,
    request: 'getPrepaymentPercentages',
  });

export const getSalesDocumentActualReportsDataset = queryProps =>
  doClientRequest<any>({
    ...queryProps,
    request: 'getSalesDocumentActualReportsDataset',
  });

export const saveInventoryTransfer = (queryProps: SaveTransferParams) =>
  doClientRequest<{ inventoryTransferID: number }>({
    ...queryProps,
    request: 'saveInventoryTransfer',
  });
export const getInventoryTransfers = (
  queryProps: Partial<{
    inventoryTransferID: number;
    creatorID: number;
    warehouseFromID: number;
    warehouseToID: number;
    deliveryAddressID: number;
    currencyCode: string;
    type: 'TRANSFER' | 'TRANSFER_ORDER';
    date: string;
    shippingDate: string;
    shippingDateActual: string;
    inventoryTransferOrderID: number;
    status: 'CREATED' | 'ACCEPTED' | 'REJECTED' | 'FULFILLED';
    notes: string;
    confirmed: 0 | 1;
    temporaryUUID: string;
    [flatParams: string]: any;
  }>,
) =>
  doClientRequest<Transfer>({
    ...queryProps,
    request: 'getInventoryTransfers',
  });
