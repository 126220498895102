import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import './styles.scss';

export type NoteButtonsProps = {
  notes: string[];
  previewCount: number;
  currentNote: string;
  onClick: (string, notes?: string[]) => void;
  onExpandClick?: (e: any) => void;
};

export const NoteButtonsComponent: FC<NoteButtonsProps> = ({
  notes,
  previewCount,
  currentNote,
  onClick,
  onExpandClick,
}) => {
  const hasExtraNotes = notes.length > previewCount;
  const previewNotes = notes.slice(
    0,
    hasExtraNotes ? previewCount - 1 : previewCount,
  );

  if (!notes.length) return null;

  return (
    <div className="note-buttons" data-testid="note-buttons">
      <ToggleButtonGroup
        name="note"
        type="radio"
        value={currentNote}
        onChange={note => onClick(note)}
        data-testid="note-buttons"
      >
        {previewNotes.slice(0, previewCount).map(note => (
          <ToggleButton
            variant="light"
            value={note}
            data-testid="note-button"
            data-test-key={note}
          >
            {note}
          </ToggleButton>
        ))}
        {hasExtraNotes && (
          <ToggleButton
            variant="light"
            value="..."
            onClick={onExpandClick}
            data-testid="more"
          >
            ...
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </div>
  );
};
export default NoteButtonsComponent;
