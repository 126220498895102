/* eslint-disable @typescript-eslint/camelcase */
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { addError, addSuccess, addWarning, dismissType } from 'actions/Error';
import {
  getFiscalRecord,
  getShouldSaveToFiscal,
  resetRecord,
  setRecord,
  setShouldSaveToFiscal,
} from 'plugins/oneAcrePergamonFiscal/redux';
import {
  PergamonFiscalRecord,
  PergamonFiscalRequest,
  PergamonFiscalResponse,
} from 'plugins/oneAcrePergamonFiscal/types';
import { shouldSaveSaleDocToFiscal } from 'plugins/oneAcrePergamonFiscal/utils';
import { PosPlugin } from 'plugins/plugin';
import { PluginError } from 'plugins/pluginUtils';

import { recordSaleInPergamonFiscal } from './API';
import { saveFiscalDataToInvoiceInJsonApi } from './jsonAPI';

export const saveSaleToFiscalService: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, ap) => async (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
) => {
  // check if finalized sale
  const saleDoc = ap.find(
    request => request.requestName === 'saveSalesDocument',
  );
  if (saleDoc && shouldSaveSaleDocToFiscal(saleDoc)) {
    try {
      await dispatch(
        addWarning('Saving sale to Pergamon Fiscal...', {
          dismissible: false,
          selfDismiss: false,
          errorType: 'pergamon-fiscal',
        }),
      );
      dispatch(setShouldSaveToFiscal(true));

      const pergamonData: {
        response: PergamonFiscalResponse | string;
        request: PergamonFiscalRequest;
      } = await dispatch(recordSaleInPergamonFiscal(saleDoc, p.customerID));

      // Save fiscal data to redux
      const { customer_pin, invoice_pin } = pergamonData.request;
      const { verify_url, cu_serial_number, cu_invoice_number } =
        // workaround because currently API returns invalid JSON string
        typeof pergamonData.response === 'string'
          ? JSON.parse((pergamonData.response as string).replace(/\\/g, ''))
          : pergamonData.response;

      const [serialNumber, date, time] = cu_serial_number.split(' ');
      // TODO when 'XX' is removed from date string
      // const cu_datetime = dayjs(`${date} ${time}`).format(
      //   'YYYY-MM-DD HH:mm:ss',
      // );

      const fiscalData: PergamonFiscalRecord = {
        cu_datetime: `${date} ${time}`,
        cu_serial_number: serialNumber,
        cu_invoice_number,
        verify_url,
        customer_pin,
        invoice_pin,
      };

      dispatch(setRecord(fiscalData));

      await dispatch(addSuccess('Sale saved to Pergamon Fiscal!'));
    } catch (e) {
      dispatch(setShouldSaveToFiscal(false));

      const messageForUI = !e.response
        ? 'Unable to get a response from the fiscal server'
        : e.response?.data?.error_status;

      throw new PluginError(
        e.message,
        `Failed to save the sale to Pergamon Fiscal.${
          messageForUI ? `\n${messageForUI}` : ''
        }`,
      );
    } finally {
      await dispatch(dismissType('pergamon-fiscal'));
    }
  }
  return ap;
};

export const saveFiscalDataToJsonApi: Required<
  PosPlugin
>['onSaveSalesDocumentAttrToJsonApi']['on'] = (p, ap) => async (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
  getState,
) => {
  if (getShouldSaveToFiscal(getState())) {
    const fiscalRecord = getFiscalRecord(getState());
    try {
      if (!fiscalRecord) {
        throw new Error('No fiscal receipt data found');
      }
      await saveFiscalDataToInvoiceInJsonApi(
        Number(p.savedSaleDocument.invoiceID),
        fiscalRecord,
      );
      dispatch(resetRecord);
      dispatch(setShouldSaveToFiscal(false));
    } catch (error) {
      await dispatch(
        addError(`Failed to save the fiscal receipt data: ${error.message}`, {
          dismissible: true,
          selfDismiss: false,
        }),
      );
      dispatch(setShouldSaveToFiscal(false));
      throw new Error('Failed to save the fiscal receipt data');
    }
  }
  return ap;
};

export const resetState: Required<PosPlugin>['onClosePayments']['on'] = (
  p,
  ap,
) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  dispatch(resetRecord);
  dispatch(setShouldSaveToFiscal(false));
  return ap;
};
