import React from 'react';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import OrderNotificationsIcon from './OrderNotificationsIcon';

const WithOrderNotificationsIcon: Required<PosPlugin>['ComponentHeader'] = ({
  children,
}) => {
  return R.pipe(
    React.Children.toArray,
    R.insert(2, <OrderNotificationsIcon />),
  )(children);
};

export default WithOrderNotificationsIcon;
