import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import UIButton from 'components/UIElements/UIButton';
import NumberPad from 'components/Keyboards/NumberPad';
import { openDay } from 'actions/OpenCloseDay';
import { addError, addSuccess, addWarning } from 'actions/Error';
import {
  float,
  integer,
  positive,
  toFixed,
} from 'components/FieldTypes/formatters';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { useConfirmation } from 'components/Confirmation';
import { createConfirmation } from 'actions/Confirmation';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import {
  getCurrencySymbol,
  getDayCountedByDrawer,
  getDenominations,
  getSetting,
  getDefaultCurrency,
  getEnabledCurrencies,
} from 'reducers/configs/settings';
import { softLogout } from 'actions/Login';
import { openCashDrawer } from 'actions/integrations/printer';
import Loader from 'components/Loader';
import { getHasDayOpen, getIsOpenDayReady } from 'reducers/OpenCloseDay';
import { getUserLoggedIn } from 'reducers/Login';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { round } from 'utils';
import { useBreakpoints } from 'utils/hooks/UI';
import { RootState } from 'reducers';
import { selectPos } from 'actions/PointsOfSale/selectPos';
import { deselectWarehouse } from 'actions/warehouses';

import ActiveCurrencySelect from './components/ActiveCurrencySelect';
import { largeInputAdornment, useOCDInputStyles } from './components/OCDInput';
import { getDefaultCountedInRegister } from './utils';

const useBillAmounts = (selectedCurrency: string) => {
  const denominations: { value: number; mark: string }[] = useSelector(
    getDenominations(selectedCurrency),
  );

  const currencies: string[] = useSelector(getEnabledCurrencies);

  const initialBillAmounts: {
    [currency: string]: { [denomination: number]: string };
  } = useMemo(
    () =>
      currencies.reduce(
        (acc, currency) => ({
          ...acc,
          [currency]: {
            ...acc[currency],
            ...denominations.reduce(
              (acc, { value }) => ({
                ...acc,
                ...acc[currency],
                [value]: '',
              }),
              {},
            ),
          },
        }),
        {},
      ),
    [currencies, denominations],
  );

  const [billAmounts, setBillAmounts] = useState(initialBillAmounts);

  const updateBillAmount = useCallback(
    (i, value) =>
      setBillAmounts(prev => {
        return {
          ...prev,
          [selectedCurrency]: {
            ...prev[selectedCurrency],
            [i]: value,
          },
        };
      }),
    [selectedCurrency],
  );

  return { billAmounts, updateBillAmount };
};

export const OpenDayComponent = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('openCloseDay');
  const styles = useOCDInputStyles();
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const confirm = useConfirmation();
  const [openingDay, setOpeningDay] = useState(false);
  const [openDayConfirmationIsOpen, toggleOpenDayConf] = useState(false);

  const defaultCurrency: string = useSelector(getDefaultCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

  const shouldOpenCashDrawer = !!useSelector(
    getSetting('day_startend_open_drawer'),
  );
  const hasToSelectWarehouseOnLogin = !!useSelector(
    getSetting('touchpos_warehouse_select'),
  );
  const { name: posName }: { name: string } = useSelector(getSelectedPos);
  const { employeeID }: { employeeID: string } = useSelector(getUserLoggedIn);
  const drawerCountUsed = useSelector(getDayCountedByDrawer);
  const { id, drawerID } = useSelector(getEmployeeById(employeeID)) ?? {};
  const name = drawerCountUsed
    ? t('openDay.name', { name: drawerID })
    : posName;

  useEffect(() => {
    if (shouldOpenCashDrawer) dispatch(openCashDrawer());
  }, [dispatch, shouldOpenCashDrawer]);

  // region Prevent employees without drawer ID to open Drawer counted day(s)
  useEffect(() => {
    // check if its drawer counted day, getEmployeeById has fetched the employee data (id exists) and there is no drawerID
    if (drawerCountUsed && id && !drawerID) {
      dispatch(softLogout());
      dispatch(addError('Employee has no drawer ID'));
    }
  }, [drawerCountUsed, id, drawerID, dispatch]);
  // endregion

  const loading = useSelector(getIsOpenDayReady);

  const showConfirm = useSelector(getSetting('showConfirmationOnOpenDay'));

  const hasOpenDay = useSelector(getHasDayOpen);

  const denominations = useSelector(getDenominations(selectedCurrency));

  const currencySymbol = useSelector(getCurrencySymbol);

  const [register, setRegister] = useState<{ [currency: string]: string }>({
    [defaultCurrency]: useSelector(getDefaultCountedInRegister),
  });

  const { billAmounts, updateBillAmount } = useBillAmounts(selectedCurrency);

  /* Miscellaneous */
  const total = useMemo(
    () =>
      Object.entries(billAmounts?.[selectedCurrency] ?? {})
        .map(([value, amount]) => Number(value) * parseFloat(amount) || 0)
        .reduce((a, b) => a + b, 0),
    [billAmounts, selectedCurrency],
  );

  const onLogout = () => {
    dispatch(selectPos(null));
    // If user has to first select warehouse, then he should be thrown to the POS selection screen, WH should remain the same
    if (!hasToSelectWarehouseOnLogin) {
      dispatch(deselectWarehouse());
    }
  };

  const onOpenDay = ({
    totalsForCurrencies,
    registerTotalsForCurrencies,
  }: {
    totalsForCurrencies: { [currency: string]: number };
    registerTotalsForCurrencies: { [currency: string]: string };
  }) => {
    setOpeningDay(true);
    return dispatch(
      openDay({ totalsForCurrencies, registerTotalsForCurrencies }),
    )
      .then(() =>
        dispatch(
          addSuccess(t('alerts.dayOpenedSuccess'), { selfDismiss: 3000 }),
        ),
      )
      .finally(() => setOpeningDay(false));
  };

  const doRegister = async () => {
    if (drawerCountUsed && !drawerID) {
      dispatch(
        addWarning(t('alerts.dayOpenMissingDrawerID'), { selfDismiss: 3500 }),
      );
      return;
    }
    const totalsForCurrencies: { [currency: string]: number } = Object.entries(
      billAmounts,
    ).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Object.entries(value)
          .map(([value, amount]) => {
            return Number(value) * parseFloat(amount) || 0;
          })
          .reduce((a, b) => a + b, 0),
      }),
      {},
    );

    const sumEntered =
      Object.values(totalsForCurrencies).every(val => val > 0) ||
      Object.values(register).every(val => Number(val) > 0);

    if (!sumEntered && showConfirm) {
      const title = t('openDay.alerts.confirmationTitle');
      const body = t('openDay.alerts.confirmationBody');

      toggleOpenDayConf(true);

      const shouldStopOpenDay = await new Promise((resolve, reject) =>
        dispatch(
          createConfirmation(resolve, reject, {
            title,
            body,
            enableShortcuts: false,
          }),
        ),
      )
        .then(() => true)
        .catch(() => false);

      toggleOpenDayConf(false);

      if (!shouldStopOpenDay) return;
    }

    onOpenDay({
      totalsForCurrencies,
      registerTotalsForCurrencies: register,
    }).then(onClose);
  };

  // Use those functions as promises
  const logoutWithConfirm = () => {
    if (!openDayConfirmationIsOpen) {
      confirm({
        title: t('alerts.confirmLogout', { context: 'title' }),
        body: t('alerts.confirmLogout', { context: 'body' }),
      })
        .then(onLogout)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  };

  const openWithConfirm = () => {
    if (!openDayConfirmationIsOpen) {
      confirm({
        title: t('alerts.confirmOpen', { context: 'title' }),
        body: t('alerts.confirmOpen', { context: 'body' }),
      })
        .then(doRegister)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  };

  useShortcut('Enter', openWithConfirm, 20);
  useShortcut('Escape', logoutWithConfirm, 20);

  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (!loading && hasOpenDay) {
      onClose();
    }
  }, [loading, hasOpenDay, onClose]);

  const isXSMobile = !useBreakpoints().sm;

  return (
    <Loader show={loading} loadingText={t('common:loading')}>
      <div data-testid="open-day-container">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="2px 1.25rem 8px 1.25rem"
        >
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
            paddingTop="1em"
          >
            <ActiveCurrencySelect
              selected={selectedCurrency}
              setSelected={setSelectedCurrency}
              title={t('openDay.title')}
            />
            {name}
          </Box>

          <Box flex={1} />
          <UIButton
            text={
              openingDay
                ? t('openDay.buttons.opening')
                : t('openDay.buttons.confirm')
            }
            variant="POS"
            disabled={openingDay}
            data-testid="confirm-button"
            action={doRegister}
          />

          <span
            className="icon_close Clickable-icon"
            data-testid="icon-close"
            style={{ fontSize: '42px' }}
            onClick={onLogout}
          />
        </Box>
        <Divider />
        <Box
          display="flex"
          flexDirection={isXSMobile ? 'column' : 'row'}
          padding="1.25rem"
        >
          <Box flex={1} marginRight="1.25rem">
            {!isXSMobile && (
              <Grid
                container
                direction="row"
                spacing={2}
                className={styles.billsContainer}
                data-testid="denominations"
              >
                {denominations.map(({ mark, value }, i) => (
                  <Grid item xs={6} key={value}>
                    <TextField
                      inputRef={i === 0 ? inputRef : null}
                      fullWidth
                      variant="outlined"
                      className={styles.input}
                      placeholder="0"
                      value={billAmounts?.[selectedCurrency]?.[value] || ''}
                      onChange={e => {
                        e.persist();
                        // @ts-ignore
                        const formatter = integer.and(positive);
                        setRegister(prevRegister => ({
                          ...prevRegister,
                          [selectedCurrency]: '',
                        }));
                        updateBillAmount(value, formatter(e.target.value));
                      }}
                      inputProps={{
                        'data-testid': 'bill',
                        'data-test-key': mark,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box>{mark.toString()}</Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            <TextField
              fullWidth
              variant="outlined"
              className={styles.largeInput}
              placeholder={round(total, 2)}
              value={register?.[selectedCurrency] || ''}
              onChange={e => {
                e.persist();
                // @ts-ignore
                const formatter = float.and(positive).and(toFixed(2));
                setRegister(prevRegister => ({
                  ...prevRegister,
                  [selectedCurrency]: formatter(e.target.value),
                }));
              }}
              inputProps={{
                'data-testid': 'drawer-or-register-field',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={largeInputAdornment(
                t(`openDay.fields.${drawerCountUsed ? 'drawer' : 'register'}`),
                currencySymbol,
              )}
            />
          </Box>
          <div data-testid="openday-numberpad-container">
            <NumberPad />
          </div>
        </Box>
      </div>
    </Loader>
  );
};

export default OpenDayComponent;
