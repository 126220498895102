import { sortBy } from 'ramda';

/**
 * Sorting algorithm for dates
 * @param {Date} date1 - first eleemnt to be compared
 * @param {Date} date2 - second element to be compared
 */

export const sortDate = (date1, date2) => {
  try {
    const _date1 = new Date(date1);
    const _date2 = new Date(date2);
    if (new Date(_date1) > new Date(_date2)) return 1;
    if (new Date(_date1) > new Date(_date2)) return -1;
    return 0;
  } catch (err) {
    return undefined;
  }
};

/**
 * Sorting algorithm for international strings
 *
 * @param {String} a - first element to be sorted
 * @param {String} b - second element to be compared
 */
export const sortIternationalString = (a, b) => {
  if (typeof a !== 'string') return undefined;
  return a.localeCompare(b);
};

/**
 * Sorting algorithm for number vakues
 *
 * @param {Number} a - first element in array
 * @param {Number} b - next number in array
 */

export const sortNumber = (a, b) => {
  try {
    const _a = Number(a);
    const _b = Number(b);
    if (_a > _b) return 1;
    if (_a < _b) return -1;
    return 0;
  } catch (err) {
    return undefined;
  }
};

/**
 * Sorting function handler
 * @param {Array} data - array of objects to be sorted
 * @param {String} creteria - name of the key the data would be sorted by
 * @param {Boolean} ascending - flag indicating the ascending or decending order of the sort function
 */

export const sort = (data, creteria, ascending) => {
  // check if any of the date  elements has field matching the creteria
  if (data.some(a => a[creteria])) {
    const firstElWithCreteriaKey = data.find(el => el[creteria]);
    // find the creteria type
    let creteriaType = '';
    // check if the creteria value is a date
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(Date.parse(firstElWithCreteriaKey[creteria]))) {
      creteriaType = 'date';
    } else {
      creteriaType = typeof firstElWithCreteriaKey[creteria];
    }
    const sortFunctions = {
      string: sortIternationalString,
      number: sortNumber,
      date: sortDate,
    };
    const _data = data.sort((a, b) =>
      sortFunctions[creteriaType](a[creteria], b[creteria]),
    );
    if (ascending) {
      return _data;
    }
    return _data.reverse();
  }
  return data;
};
