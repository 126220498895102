import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import { attemptPinLogin, hardLogout } from 'actions/Login';
import { getIsOfflineLocked } from 'reducers/Login';
import { version } from 'external_data';
import { RootState } from 'reducers';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getConnectionHealth } from 'reducers/connectivity/connection';

/**
 * Partially copied from {@link psrc/containers/Login/util.js} to replicate ping login screen
 */
const styles = {
  content: {
    borderRadius: '8px',
    minWidth: '464px',
  },
  header: {
    fontWeight: 700,
    fontSize: 24,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    height: 62,
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'uppercase',
    fontSize: 14,
    padding: 12,
    margin: 16,
    marginTop: 8,
    width: '100%',
  },
  version: {
    position: 'absolute',
    right: '0.5ch',
    bottom: 0,
    fontSize: 13,
  },
} as const;

export const LockscreenOverlay = () => {
  const { t } = useTranslation('offlineLogin');
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const hasOfflinePin = useSelector(getIsOfflineLocked);
  const isOnline = useSelector(getConnectionHealth);

  const [input, setInput] = useState('');
  const [submit, setSubmit] = useState<null | string>(null);
  const [error, setError] = useState<null | Error>(null);

  useEffect(() => {
    if (submit) {
      // attemptPinLogin by default catches all errors to show as an erply alert
      // Here we use the raw action in order to display the errors in the lock screen because the regular alerts are hidden behind the backdrop
      dispatch(attemptPinLogin(submit).rawAction)
        .catch(error =>
          setError(
            isOnline
              ? error
              : new Error(t('cannotUnlockOffline'), { cause: error }),
          ),
        )
        .finally(() => setSubmit(null));
    }
  }, [dispatch, isOnline, submit, t]);

  const onSetSubmit = () => setSubmit(input);

  useShortcut('Enter', onSetSubmit, 100);

  return (
    <Dialog open={true}>
      <DialogTitle style={styles.header}>
        <h2 style={styles.header}>{t('lockscreen.title')}</h2>
      </DialogTitle>
      <DialogContent style={styles.content}>
        <TextField
          label={t('lockscreen.inputs.pin', {
            context: hasOfflinePin ? 'offline' : '',
          })}
          fullWidth
          autoFocus
          name="pin"
          type="password"
          variant="outlined"
          value={input}
          onChange={e => setInput(e.target.value)}
          helperText={error?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ flexGrow: 1, ...styles.button }}
          size="lg"
          variant="secondary"
          onClick={onSetSubmit}
          disabled={!!submit}
        >
          {t('lockscreen.buttons.unlock')}
        </Button>
        <Button
          onClick={() => dispatch(hardLogout())}
          disabled={!!submit}
          style={{ flexGrow: 1, ...styles.button }}
          size="lg"
          variant="primary"
        >
          {t('lockscreen.buttons.logout')}
        </Button>
        <span style={styles.version}>{version}</span>
      </DialogActions>
    </Dialog>
  );
};
