import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { PluginComponent } from 'plugins';
import Icon from 'components/Icon';
import { openPaymentModal } from 'actions/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { addWarning, dismissType } from 'actions/Error';
import { cancelOrderAction } from 'actions/sales';
import { printReceipt } from 'actions/integrations/printer';
import { modalPages } from 'constants/modalPage';
import { useAsyncFunctionNoParallelExecution } from 'utils/hooks/useSingleUseFunction';
import { createConfirmation } from 'actions/Confirmation';
import { updateOrderAmount } from 'actions/ShoppingCart';
import { setPaymentButtonClicked } from 'actions/Payments';
import { getHasUndismissedErrorsOfType } from 'reducers/Error';
import { getCurrentSalesDocument, getLastSalesDocument } from 'reducers/sales';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import {
  getIsSellingToDefaultCustomerBlocked,
  getShouldChooseEmployeeManually,
} from 'reducers/configs/settings';
import { RootState } from 'reducers';
import {
  getHasProductsInShoppingCart,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import { getIsModalOpened } from 'reducers/modalPage';
import { getHasActiveConfirmation } from 'reducers/Confirmation';
import { isEmpty } from 'utils';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import StyledButton from './StyledButton';

const GreenButton: React.FC<{ width: number | string; height?: number }> = ({
  width,
  height,
}) => {
  const { t } = useTranslation(['alerts', 'return', 'common', 'gridButtons']);

  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const hasItemsInCart = useSelector(getHasProductsInShoppingCart);
  const items = useSelector(getProductsInShoppingCart);

  const hasSavingDocumentError = useSelector(
    getHasUndismissedErrorsOfType('savingDocument'),
  );

  const { salesDoc: lastInvoice, payments: receiptPayments } = useSelector(
    getLastSalesDocument,
  );

  const isModalOpened = useSelector(getIsModalOpened);
  const activeConfirmation = useSelector(getHasActiveConfirmation);
  const canPrintLastReceipt =
    (lastInvoice.receiptLink || !isEmpty(receiptPayments)) && !hasItemsInCart;
  const isDefaultCustomer = useSelector(getIsDefaultCustomer);
  const disallowDefaultCustomer = useSelector(
    getIsSellingToDefaultCustomerBlocked,
  );
  const shouldChooseEmployeeManually = useSelector(
    getShouldChooseEmployeeManually,
  );
  const currentSaleDoc = useSelector(getCurrentSalesDocument);

  const [processPayment] = useAsyncFunctionNoParallelExecution(async () => {
    if (hasItemsInCart) {
      if (
        currentSaleDoc.type === 'ORDER' &&
        items.some(item => item.amount < 0)
      ) {
        try {
          await new Promise((resolve, reject) => {
            dispatch(
              createConfirmation(resolve, reject, {
                title: t('alerts.orderNotSupported.title', { ns: 'return' }),
                body: t('alerts.orderNotSupported.body', { ns: 'return' }),
                confirmText: t('yes', { ns: 'common' }),
                cancelText: t('close', { ns: 'common' }),
              }),
            );
          });
          return dispatch(cancelOrderAction(currentSaleDoc));
        } catch (error) {
          await Promise.all(
            items
              .filter(item => item.amount < 0)
              .map(item => {
                const originalRow = currentSaleDoc.rows.find(
                  row => row.stableRowID === item.stableRowID,
                );
                if (!originalRow) return null;
                return dispatch(
                  updateOrderAmount(originalRow.amount, item.orderIndex),
                );
              })
              .filter(Boolean),
          );
          return dispatch(
            addWarning(t('cartWillBeReset'), { selfDismiss: false }),
          );
        }
      }

      if (isDefaultCustomer && disallowDefaultCustomer) {
        return dispatch(
          addWarning(t('noCustomerSelected'), {
            selfDismiss: true,
            errorType: 'defaultCustomer',
          }),
        );
      }
      dispatch(dismissType('defaultCustomer'));

      if (shouldChooseEmployeeManually) {
        await dispatch(
          openModalPage({
            component: modalPages.employeeSelect,
            isPopup: true,
          }),
        );
      }

      if (!isModalOpened && !activeConfirmation && !hasSavingDocumentError) {
        dispatch(setPaymentButtonClicked(true));
        try {
          return dispatch(
            openPaymentModal({ props: { payButtonClicked: true } }),
          );
        } catch (e) {
          dispatch(setPaymentButtonClicked(false));
        }
      }
    } else if (canPrintLastReceipt) {
      return dispatch(
        // @ts-ignore
        printReceipt({ payments: receiptPayments, ...lastInvoice }),
      );
    } else {
      return dispatch(
        addWarning(t('shoppingCartEmpty'), { selfDismiss: true }),
      );
    }
    return undefined;
  });

  useShortcut('F2', canPrintLastReceipt ? null : processPayment, 20);

  return (
    <PluginComponent hookname="UICustomPayButton">
      <StyledButton
        width={width}
        height={height}
        variant="contained"
        onClick={processPayment}
        data-testid="payment-button"
        color="grid_green"
      >
        <span
          style={{
            fontSize: '20px',
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
        >
          {canPrintLastReceipt ? (
            <Trans i18nKey="actions.printLast" ns="gridButtons">
              <Icon name="icon_printer" />
            </Trans>
          ) : (
            t('actions.pay', { ns: 'gridButtons' })
          )}
        </span>
        {canPrintLastReceipt ? null : (
          <span
            style={{
              top: '5px',
              left: '8px',
              fontWeight: 700,
              position: 'absolute',
            }}
            className="func-key"
          >
            F2
          </span>
        )}
        {/* Temporarily disabled as per https://erply.atlassian.net/browse/PBIB-1750?focusedCommentId=187006 */}
        {/* <TipAfterPayment /> */}
      </StyledButton>
    </PluginComponent>
  );
};

export default GreenButton;
