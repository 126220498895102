import React, { useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { getReasonCodes } from 'reducers/reasonCodesDB';
import {
  getIsGiftReturn,
  getOrders,
  getReturnDocument,
  getReturnableOrdersFn,
  getReturnedRowIDsAndAmounts,
} from 'reducers/returnProducts';
import {
  getIsSpecialHandlingOfGiftReturnsEnabled,
  getProductCodeToShowOnReturnModal,
} from 'reducers/configs/settings';
import InputField from 'components/FieldTypes/InputField';
import { setIsGiftReturn, setOrdersSelected } from 'actions/returnProducts';
import { PluginComponent } from 'plugins';
import {
  getCanProductBeReturned,
  isRowFullyReturned,
  nestGroupedProducts,
} from 'utils';
import useProducts from 'utils/hooks/useProducts';
import { REASONS } from 'constants/reasonCodesDB';
import { SaleDocumentResponse } from 'types/SalesDocument';

import SetBulkReturnReasons from './SetBulkReturnReasons';
import OrderProduct from './OrderProduct';

import './returnTable.scss';

const ReturnTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('return');

  // Document to make the return for, and the rows (original and returns)
  const returnDocument: SaleDocumentResponse = useSelector(getReturnDocument);
  const returnedRows = useSelector(getReturnedRowIDsAndAmounts);
  const allRows = useSelector(getOrders);

  const codeToShow = useSelector(getProductCodeToShowOnReturnModal);

  const returnCodeReasons = useSelector(getReasonCodes(REASONS.RETURN));
  const isSpecialHandlingOfGiftReturnsEnabled = useSelector(
    getIsSpecialHandlingOfGiftReturnsEnabled,
  );
  const isGiftReturn = useSelector(getIsGiftReturn);

  const [globalReturnReason, setGlobalReturnReason] = useState<
    undefined | string
  >();
  /**
   * - all: Forcibly updates all rows to this reason
   * - unassigned: Only applies reason to rows which do not have a reason yet
   */
  const [globalReasonRule, setGlobalReasonRule] = useState<
    'all' | 'unassigned'
  >('unassigned');

  // Fetch products and filter out non-refundable and such

  const { products } = useProducts({
    productIDs: allRows?.map(row => Number(row.productID)),
  });

  const returnableRows = useSelector(getReturnableOrdersFn)(products);

  // Used only for display
  const computedRows = useMemo(
    () =>
      // Link up products based on parentRowID
      nestGroupedProducts(allRows, {
        parentIndicator: 'parentRowID',
        idIndicator: 'stableRowID',
      }),
    [allRows],
  );

  const areAllSelected: boolean =
    returnableRows.length > 0 && returnableRows.every(r => r?.selected);

  // toggle on/off all rows in the return document
  const toggleAllRows = () => {
    if (areAllSelected) {
      dispatch(setOrdersSelected({}));
    } else {
      dispatch(
        setOrdersSelected(
          Object.fromEntries(
            returnableRows.map(row => [
              row.stableRowID,
              R.assoc('selected', true, row),
            ]),
          ),
        ),
      );
    }
  };

  // update the reasons based on the criteria
  const applyGlobalReasonCode = () => {
    dispatch(
      setOrdersSelected(
        Object.fromEntries(
          returnableRows.map(row => [
            row.stableRowID,
            R.assoc(
              'returnReasonID',
              globalReasonRule === 'unassigned'
                ? Number(row.returnReasonID) || globalReturnReason
                : globalReturnReason,
            )(row),
          ]),
        ),
      ),
    );
  };

  return (
    <div>
      {isSpecialHandlingOfGiftReturnsEnabled ? (
        <FormControlLabel
          label={t('fields.isGiftReturn')}
          style={{ marginLeft: '2px' }}
          control={
            <Checkbox
              checked={isGiftReturn}
              onChange={e => dispatch(setIsGiftReturn(e.target.checked))}
            />
          }
        />
      ) : null}

      <PluginComponent
        hookname="UISetBulkReturnReasons"
        props={{
          setGlobalReturnReason,
          applyCodeChange: applyGlobalReasonCode,
          globalReasonRule,
          setGlobalReasonRule,
          globalReturnReason,
        }}
      >
        <SetBulkReturnReasons
          changeRowsReturnReason={setGlobalReturnReason}
          applyCodeChange={applyGlobalReasonCode}
          globalReasonRule={globalReasonRule}
          setGlobalReasonRule={setGlobalReasonRule}
          globalReturnReason={globalReturnReason}
        />
      </PluginComponent>

      <PluginComponent
        hookname="UIReturnTableExtraData"
        props={{
          returnDocument,
        }}
      />

      <Table className="return-table" data-testid="return-table">
        <PluginComponent hookname="UIReturnTableHeaders">
          <thead data-testid="table-header">
            <tr>
              <th className="align-middle" data-testid="toggle-rows">
                <InputField
                  onChange={toggleAllRows}
                  value={areAllSelected}
                  type="checkbox"
                  data-testid="toggle-rows-input"
                />
              </th>
              <th className="align-middle" data-testid="product">
                {t('headings.product')}
              </th>
              <th className="align-middle" data-testid="barcode">
                {t(`headings.${codeToShow}`)}
              </th>
              <th className="align-middle" data-testid="original-quantity">
                {t('headings.originalQty')}
              </th>
              <th className="align-middle" data-testid="quantity">
                {t('headings.qty')}
              </th>
              {returnCodeReasons.length ? (
                <th data-testid="reason" className="align-middle">
                  {t('headings.reason')}
                </th>
              ) : null}
            </tr>
          </thead>
        </PluginComponent>
        <tbody data-testid="table-body">
          {computedRows.map(row => {
            const productCard = products.find(
              prod => String(row.productID) === String(prod.productID),
            );

            const canBeReturned = getCanProductBeReturned(productCard);
            const isFullyReturned = isRowFullyReturned(row, returnedRows);

            return (
              <OrderProduct
                key={row.rowID}
                row={row}
                isEven={row.isEven}
                globalReturnReason={globalReturnReason}
                disabled={!canBeReturned || isFullyReturned}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ReturnTable;
