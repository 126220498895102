import { round } from 'utils';

import { PaymentProps, VoidProps, RefundProps } from '../types';

import * as oldAPI from './verifoneRequestsV1';
import * as newAPI from './verifoneRequestsV2';

export const getVerifoneApi = async (usev2prop?: boolean) => {
  const usev2 =
    typeof usev2prop === 'boolean' ? usev2prop : await oldAPI.getUseV2();
  const api = usev2 ? newAPI : oldAPI;

  // Functionality is the same except for the endpoint it pings
  const testConnection = async () => {
    return api.testConnection();
  };

  // v2 requires "transactionType: 'SALE'" to be sent in the payload to MS
  const requestSale = async (params: PaymentProps) => {
    const amount = round(params.amount);
    if (!amount) {
      throw new Error('Amount incorrect');
    }
    const payload = { amount };
    return api.requestSale(payload);
  };

  const requestReturn = async (params: RefundProps) => {
    return api.requestReturn(params);
  };

  const cancelRequest = async () => {
    return api.cancelRequest();
  };

  const requestVoid = async (params: VoidProps) => {
    return api.requestVoid(params);
  };

  const requestStart = async () => {
    return api.requestStart();
  };

  return {
    testConnection,
    requestSale,
    requestReturn,
    requestVoid,
    requestStart,
    cancelRequest,
  };
};
