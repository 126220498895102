import { getPluginSelector } from 'reducers/Plugins';

import { pluginID } from '../constants';
import { ReduxViiPayment, ReduxViiProduct, ViiGiftCard } from '../types';

import { ViiState, initState } from './reducers';
import {
  ENTER_PAYMENT_DATA,
  ENTER_PRODUCT_DATA,
  RESET_PLUGIN_STATE,
  REMOVE_VII_PRODUCT,
  REMOVE_VII_PAYMENT,
  ADD_VII_GIFT_CARD_PRODUCT_TO_CACHE,
  REMOVE_VII_GIFT_CARD_PRODUCT_FROM_CACHE,
} from './types';

export const addViiPaymentData = (id: string, body: ReduxViiPayment) => ({
  type: ENTER_PAYMENT_DATA,
  payload: {
    id,
    body,
  },
});

export const removeViiPaymentDataById = (id: string) => ({
  type: REMOVE_VII_PAYMENT,
  payload: {
    id,
  },
});

export const addViiProductData = (args: {
  orderIndex: number;
  vii: ReduxViiProduct;
}) => async dispatch => {
  const { orderIndex, vii } = args;
  dispatch({
    type: ENTER_PRODUCT_DATA,
    payload: {
      id: orderIndex,
      body: vii,
    },
  });
};

export const addViiGiftCardProduct = (product: ViiGiftCard) => ({
  type: ADD_VII_GIFT_CARD_PRODUCT_TO_CACHE,
  payload: product,
});

export const removeViiGiftCardProduct = () => ({
  type: REMOVE_VII_GIFT_CARD_PRODUCT_FROM_CACHE,
});

export const getViiProducts = getPluginSelector<ViiState>(pluginID)(
  (state = initState) =>
    (state?.products ?? {}) as {
      [id: number]: ReduxViiProduct;
    },
);
export const getViiPayments = getPluginSelector<ViiState>(pluginID)(
  (state = initState) => state?.payments ?? {},
);

export const resetPluginState = () => ({
  type: RESET_PLUGIN_STATE,
});

export const removeViiProductByRowId = (id: string | number) => ({
  type: REMOVE_VII_PRODUCT,
  payload: {
    id,
  },
});

export const getViiGiftCardProduct = getPluginSelector<ViiState>(pluginID)(
  (state = initState) => state?.viiProduct,
);
