import * as R from 'ramda';

import { addError } from 'actions/Error';
import { seniorTagID } from 'plugins/mm/constants';
import { PosPlugin } from 'plugins/plugin';
import { addTag, removeTag } from 'services/CustomerRegistery/index';

export const assocSeniorStatus: Required<PosPlugin>['onSetCustomer']['on'] = (
  _p,
  ap,
) => async () => {
  const hasSeniorTag = !!ap.payload.tags?.find(tag => tag.id === seniorTagID);

  return R.assocPath(['payload', 'senior'], hasSeniorTag)(ap);
};

export const addSeniorTagToCustomer: Required<
  PosPlugin
>['onSaveCustomer']['after'] = (p, customerId) => async dispatch => {
  /**
   * This code is left here to prevent "optimizations" - when creating a customer, without the assocSeniorStatus they have `senior` as undefined, thus the tag is never saved for new customers
   * if (p.senior === undefined) return;
   */
  const hasSeniorTag = p.tags?.find(tag => tag.id === seniorTagID);

  if (!!p.senior && !hasSeniorTag) {
    try {
      await addTag({ customerId, tagId: seniorTagID });
    } catch (error) {
      dispatch(addError('Failed to assign senior status to customer'));
    }
  } else if (!p.senior && hasSeniorTag) {
    try {
      await removeTag({ customerId, tagId: seniorTagID });
    } catch (error) {
      dispatch(addError('Failed to remove senior status from customer'));
    }
  }
};
