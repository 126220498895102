import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography, Divider } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import uuidv4 from 'uuid/v4';

import { round } from 'utils';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { deletePayment, setShowPaymentInput } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { getLastPayment, getShowPaymentInput } from 'reducers/Payments';

const formatNumber = (number: string) => {
  let formatedNumber = number;
  formatedNumber = formatedNumber.replace(/[,]/g, '.');
  formatedNumber = formatedNumber.replace(/[^0-9-.]/g, '');
  if (
    formatedNumber.match(/^(-{0,1})([0-9]{0,1}|[1-9]{1}[0-9]*).{0,1}[0-9]*$/g)
  ) {
    if (formatedNumber.match(/[-]/g)) {
      formatedNumber = `-${formatedNumber.replace(/[-]/g, '')}`;
    }
    if (formatedNumber.match(/^[.]/g)) {
      formatedNumber = formatedNumber.replace(/^[.]/g, '0.');
    }
    if (formatedNumber.match(/^[-]+[.]/g)) {
      formatedNumber = formatedNumber.replace(/^[-]+[.]/g, '-0.');
    }
    if (formatedNumber.match(/^[0]+[1-9]/g)) {
      formatedNumber = formatedNumber.replace(/^[0]/g, '');
    }
    return formatedNumber;
  }
  return '';
};

const PaymentInput: React.FC = () => {
  const { t } = useTranslation('payment');
  const [amount, setAmount] = useState('');
  const { lastPayment, lastKey } = useSelector(getLastPayment);
  const { paymentType } = useSelector(getShowPaymentInput);
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();

  const handleSave = async () => {
    const amountValue = amount;
    if (
      amountValue &&
      !Number.isNaN(Number(amountValue)) &&
      Number(amountValue) > 0
    ) {
      if (lastPayment.amount === '0.00') {
        dispatch(deletePayment({ key: lastKey }));
      }

      await dispatch(
        setPayment({
          key: ['TIP', 'CHECK', 'CASH'].includes(paymentType)
            ? paymentType
            : uuidv4(),
          amount: round(amountValue),
          caption: paymentType,
          type: paymentType,
        }),
      );
      await dispatch(setShowPaymentInput(false, paymentType));
    }
  };

  return (
    <Box padding="1.5rem">
      <Box display="flex" alignItems="center" marginBottom="1rem">
        <Typography variant="h5">
          {t('bill.inputs.paymentAmount', { context: 'title' })}
        </Typography>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <SaveButton color="secondary" action={handleSave} />
          <CloseButton
            action={() => {
              dispatch(setShowPaymentInput(false, null));
            }}
          />
        </Box>
      </Box>
      <Divider style={{ margin: '0.75rem 0' }} />
      <TextField
        label={t('bill.inputs.paymentAmount', {
          context: 'placeholder',
        })}
        fullWidth
        variant="outlined"
        size="small"
        value={amount}
        onChange={e => setAmount(formatNumber(e.target.value))}
        onFocus={event => event.preventDefault()}
      />
    </Box>
  );
};

export default PaymentInput;
