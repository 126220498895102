import { Children } from 'react';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

const WithoutReasonColumn: PosPlugin['UIReturnTableHeaders'] = ({
  children,
}) => {
  return R.map(
    R.over(
      R.lensPath(['props', 'children', 'props', 'children']),
      R.pipe(
        Children.toArray,
        R.reject(R.pathEq(['props', 'data-testid'], 'reason')),
      ),
    ),
  )(Children.toArray(children));
};
export default WithoutReasonColumn;
