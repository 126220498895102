import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isAscii, isPostalCode, isEmpty } from 'validator';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import InputField from 'components/FieldTypes/InputField';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import styles from 'components/FieldTypes/skins/skins.module.scss';

import './Shipping.sass';
import { fetchOrCreateVatRateForZipCode } from 'actions/vatRatesDB';
import { setVatRateForSale } from 'actions/ShoppingCart';
import Loader from 'components/Loader';
import { addError, addSuccess } from 'actions/Error';

import { useTranslation } from 'react-i18next';

const Shipping = () => {
  const { t } = useTranslation('shipping');
  const [zip, setZip] = useState('');
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const onSave = async zip => {
    setSaving(true);
    dispatch(fetchOrCreateVatRateForZipCode(zip))
      .then(({ id }) => {
        if (id) return id;
        throw new Error('Failed to get/create vatRate');
      })
      .then(id => dispatch(setVatRateForSale(id)))
      .then(() => dispatch(previousModalPage()))
      .then(() => {
        dispatch(addSuccess(t('alerts.taxRateApplied'), { selfDismiss: true }));
      })
      // Curly braces because we don't need to wait for the error to dismiss
      .catch(e => {
        dispatch(addError(e.message, { selfDismiss: true }));
      })
      .finally(() => setSaving(false));
  };

  const formatter = (newVal, oldVal) =>
    isAscii(newVal) || isEmpty(newVal) ? newVal : oldVal;

  const handleSubmit = e => {
    e.preventDefault();
    if (isPostalCode(zip, 'any')) {
      onSave(zip);
    }
  };

  return (
    <Loader
      style={{ display: 'block', height: '100%' }}
      loadingText={t('loading')}
      show={saving}
      className="shipping"
    >
      <div data-testid="shipping-modal">
        <Modal.Header>
          <Modal.Title>{t('title')}</Modal.Title>
          <div className="shipping-action-buttons">
            <SaveButton
              action={() => onSave(zip)}
              disabled={zip === '' || saving}
              data-testid="save-btn"
            />
            <CloseButton action={() => dispatch(previousModalPage())} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <InputField
                size="lg"
                title={t('fields.zip')}
                data-testid="zip-input"
                value={zip}
                placeholder={t('fields.zip', { context: 'placeholder' })}
                type="text"
                onChange={e => setZip(e.target.value)}
                formatter={formatter}
                className={styles.formInput}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
      </div>
    </Loader>
  );
};

export default Shipping;
