import { PaymentType } from 'types/PaymentType';

export const PLUGIN_ID = 'external-payment-momo';

export type PluginConfiguration = {
  customPaymentTypeID: PaymentType['id'];
  domain: string;
};

const pluginPrefix = 'PLUGIN:EXTERNAL_MOMO:';
export const ENABLE_EXTERNAL_PAYMENT = `${pluginPrefix}ENABLE_EXTERNAL_PAYMENT`;
export const DISABLE_EXTERNAL_PAYMENT = `${pluginPrefix}DISABLE_EXTERNAL_PAYMENT`;
