/* eslint-disable react/button-has-type, @typescript-eslint/camelcase */
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import {
  getProductsInShoppingCart,
  getTotalNet,
  getTotalTax,
} from 'reducers/ShoppingCart';
import { getProductByID } from 'reducers/cachedItems/products';
import { calculateShoppingCart } from 'services/ErplyAPI/sales';
import { getCurrentSalesDocument, getIsAReturn } from 'reducers/sales';
import { addError } from 'actions/Error';
import {
  removeProduct,
  updateOrderAmount,
  updateOrderPrice,
} from 'actions/ShoppingCart';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { startNewSale } from 'actions/sales';
import {
  getSelectedCustomer,
  getSelectedCustomerID,
} from 'reducers/customerSearch';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getClientCode, getSessionKey } from 'reducers/Login';
import { getCurrencyFormatter } from 'reducers/configs/settings';
import { notUndefinedOrNull } from 'utils/tsHelpers';
import { ErplyAttributes } from 'utils';
import { PosPlugin } from 'plugins/plugin';

import { getWaiversReduxState } from '../eWaiver/rdx/reducers';

import { calculateAdmissionTaxAmount } from './utils';
import {
  ADMISSION_FEE_NET_PRICE,
  ADMISSION_FEE_TAX_AMOUNT,
  CALCULATING_ENV_OR_ADM_TAX_FEES,
  EnvFeeState,
  HAS_REMOVED_ITEM,
  PRICE_HAS_BEEN_UPDATED,
  SET_ENV_FEE_AMOUNT,
  SET_ENV_FEE_RATE,
  SET_INITIAL_ENV_FEE,
  SHOULD_ADD_ADMISSION_TAX,
  SHOULD_ADD_ENV_FEE,
} from './types';
import envAndCAFeeReducer, {
  addEnvFeeProductToCart,
  calculateNetTotal,
  checkIfReturningAllProducts,
  defineAdmissionTaxAmount,
  defineIfAdmissionTaxShouldBeWaived,
  getAdmissionFeeFromCart,
  getAdmissionFeeProducts,
  getAdmissionTaxFromCart,
  getAdmissionTAXProduct,
  getConfiguration,
  getEnvAndCAFeePluginStatus,
  getEnvFeeAmount,
  getEnvFeeRate,
  getEnvironmentalFeeProduct,
  getEnvProductFromShoppingCart,
  getEnvState,
  getExchangeState,
  resetEnvFeeState,
  shouldAddEnvProductToCartBasedOnCode5Names,
} from './rdx';
import {
  ComponentConfiguration,
  UICustomPayButton,
  UIRecentSalesInfoRow,
  UIReturnOrderProduct,
  UITableBill,
  UITableTotalRow,
} from './components';

// TODO should be moved to rdx
let admissionTaxInShoppingCartCounter = 0;

dayjs.extend(isSameOrBefore);

export const setAdmissionTaxInShoppingCartcounter = (n: number) => {
  admissionTaxInShoppingCartCounter = n;
};

export const getAdmissionTaxInShoppingCartcounter = () => {
  return admissionTaxInShoppingCartCounter;
};

const EnvironmentalFee: PosPlugin = {
  id: 'environmental-&-CA-fees',
  name: 'Environmental and CA admission fee',
  keywords: ['pnp', 'environmental', 'fee', 'tax'],
  info:
    'This plugin enables the environmental fee to be added to sales and returns. The environmental fee is calculated according to the net total of a sale or return.',
  getStatus: getEnvAndCAFeePluginStatus,
  reduxReducer: envAndCAFeeReducer as PosPlugin['reduxReducer'],
  combineConfiguration: (c = {}, w = {}, p = {}, u) => {
    return {
      ...c,
      ...p,
      ...(c.admissionTaxName ? { admissionTaxName: c.admissionTaxName } : {}),
    };
  },
  ComponentConfiguration,
  UITableTotalRow,
  UITableBill,
  UICustomPayButton,
  UIRecentSalesInfoRow,
  UIReturnOrderProduct,
  onAddProduct: {
    before: product => async (dispatch, getState) => {
      const state = getState();
      const isReturn = getIsAReturn(state);
      const currentConfig = getConfiguration(state);
      const addedProduct = getProductByID(product.productID)(state);
      const productsInCart = getProductsInShoppingCart(state);
      const environmentalFeeProductInCart = getEnvProductFromShoppingCart(
        state,
      );
      const admissionTaxProductInCart = getAdmissionTaxFromCart(state);

      if (environmentalFeeProductInCart?.productID === product.productID) {
        throw new Error('Trying to add duplicated environmental fee item');
      }

      if (admissionTaxProductInCart?.productID === product.productID) {
        throw new Error('Trying to add duplicated admission tax fee item');
      }

      if (isReturn) {
        if (
          addedProduct.name.toUpperCase() !==
          currentConfig.envFeeName.toUpperCase()
        ) {
          throw new Error(
            'Unable to add new products during a return due to Environmental fee',
          );
        }
      }
    },
    after: (params, { product }) => async (dispatch, getState) => {
      const state = getState();

      const { taxExemptCertificateNumber } = getCurrentSalesDocument(state);
      const isReturn = getIsAReturn(state);
      const currentConfig = getConfiguration(state);
      const currentCustomer = getSelectedCustomer(state);
      const currentProduct = getProductByID(product.productID)(state);

      const envFeeState: EnvFeeState = getEnvState(state);
      const exchangeState = getExchangeState(state);
      const shouldWaiveAdmissionTax = defineIfAdmissionTaxShouldBeWaived(state);
      const environmentalFeeProductInCart = getEnvProductFromShoppingCart(
        state,
      );
      const rateToApply = getEnvFeeRate(state);
      const admissionTaxProductInCart = getAdmissionTaxFromCart(state);
      const admissionFeeProductInCart = getAdmissionFeeFromCart(state);

      const { shouldAddAdmissionTax } = envFeeState;
      const { CAFeeEnabled, envFeeEnabled } = currentConfig;

      // check if configuration has been done for the selected functionality
      if (envFeeEnabled) {
        if (
          !currentConfig ||
          !currentConfig.envFeeName ||
          currentConfig.envFeeName === ''
        ) {
          dispatch(
            addError('Environmental fee plugin not configured correctly'),
          );
          return;
        }
      }

      // Set up validation logic if california admisison fee is enabled
      if (CAFeeEnabled) {
        if (
          !currentConfig ||
          !currentConfig.admissionTaxName ||
          currentConfig.admissionTaxName === '' ||
          !currentConfig.admissionFeeName ||
          currentConfig.admissionFeeName === ''
        ) {
          dispatch(
            addError('CA admission Tax plugin not configured correctly'),
          );
          return;
        }
      }

      if (CAFeeEnabled || envFeeEnabled) {
        dispatch({
          type: CALCULATING_ENV_OR_ADM_TAX_FEES,
          payload: true,
        });
      }

      // Specific logic if environmental fee has been enabled
      if (currentConfig.envFeeEnabled) {
        dispatch({ type: SET_ENV_FEE_RATE, payload: rateToApply });

        let envFeeAmount;

        if (rateToApply >= 0) {
          envFeeAmount = getEnvFeeAmount(rateToApply)(state);
        } else {
          if (typeof rateToApply !== 'number') {
            dispatch(addError('Environmental fee rate is missing in BO.'));
          }
          return;
        }

        // Ensure that environmental fee is negative amount during step 1 of exchange
        if (exchangeState.exchangeStep === 1 && environmentalFeeProductInCart) {
          if (environmentalFeeProductInCart.amount === 1) {
            dispatch(
              updateOrderAmount(-1, environmentalFeeProductInCart.orderIndex),
            );
          }
        }

        if (environmentalFeeProductInCart) {
          await dispatch({
            type: SET_ENV_FEE_AMOUNT,
            payload: envFeeAmount,
          });

          await dispatch(
            updateOrderPrice(
              Math.abs(Number(envFeeAmount)),
              Math.abs(Number(envFeeAmount)),
              environmentalFeeProductInCart.orderIndex,
            ),
          );

          dispatch({ type: SHOULD_ADD_ENV_FEE, payload: true });
        }

        if (!environmentalFeeProductInCart) {
          // Add environmental fee to cart if it is not present already in cart
          const shouldAddEnvProductToCart =
            currentConfig.code5names && currentConfig.code5names !== ''
              ? currentConfig.code5names
                  .split(',')
                  .some(
                    code =>
                      code.toUpperCase().trim() ===
                      currentProduct.code5?.toUpperCase().trim(),
                  )
              : undefined;
          await dispatch({
            type: SET_ENV_FEE_AMOUNT,
            payload: envFeeAmount,
          });

          if (shouldAddEnvProductToCart) {
            const envProductInDB: any = await dispatch(
              getEnvironmentalFeeProduct(),
            );

            // Ensure that environmental fee is not added two times
            if (
              envProductInDB &&
              envProductInDB.productID === product.productID
            ) {
              dispatch({ type: SHOULD_ADD_ENV_FEE, payload: true });
              return;
            }

            await addEnvFeeProductToCart();
            dispatch({ type: SHOULD_ADD_ENV_FEE, payload: true });

            if (!envProductInDB) {
              dispatch({ type: SHOULD_ADD_ENV_FEE, payload: false });
              dispatch({
                type: CALCULATING_ENV_OR_ADM_TAX_FEES,
                payload: false,
              });
              return;
            }
          }
        }

        if (!CAFeeEnabled) {
          dispatch({
            type: CALCULATING_ENV_OR_ADM_TAX_FEES,
            payload: false,
          });
          return;
        }
      }

      // Specific logic if california fee has been enabled
      const { tax_exempt_expiration_date } = new ErplyAttributes(
        currentCustomer.attributes,
      ).asDict;

      const date = dayjs(
        new Date(tax_exempt_expiration_date),
        'MM/DD/YYYY',
        true,
      );
      const dateIsActive = dayjs().isSameOrBefore(date, 'date');

      if (
        CAFeeEnabled &&
        !taxExemptCertificateNumber?.trim()?.length &&
        !dateIsActive
      ) {
        if (!admissionFeeProductInCart && !shouldWaiveAdmissionTax) {
          if (!isReturn && !shouldAddAdmissionTax) {
            dispatch({
              type: SHOULD_ADD_ADMISSION_TAX,
              payload: true,
            });
          }

          if (!admissionTaxProductInCart) {
            const taxProductInDB: any = await dispatch(
              getAdmissionTAXProduct(),
            );

            let calculatedAdmissionFeeTaxAmount;

            if (envFeeState.admissionFeeTaxAmount === 0) {
              calculatedAdmissionFeeTaxAmount = await dispatch(
                defineAdmissionTaxAmount(),
              );
            } else {
              calculatedAdmissionFeeTaxAmount =
                envFeeState.admissionFeeTaxAmount;
            }

            if (
              taxProductInDB &&
              !envFeeEnabled &&
              getAdmissionTaxInShoppingCartcounter() < 1
            ) {
              taxProductInDB.price = calculatedAdmissionFeeTaxAmount;
              await dispatch(addProduct(taxProductInDB));
              setAdmissionTaxInShoppingCartcounter(
                getAdmissionTaxInShoppingCartcounter() + 1,
              );
            }

            if (
              taxProductInDB &&
              envFeeEnabled &&
              getAdmissionTaxInShoppingCartcounter() < 1
            ) {
              if (
                !shouldWaiveAdmissionTax &&
                getAdmissionTaxInShoppingCartcounter() < 1 &&
                !admissionTaxProductInCart
              ) {
                taxProductInDB.price = calculatedAdmissionFeeTaxAmount;
                await dispatch(addProduct(taxProductInDB));
                setAdmissionTaxInShoppingCartcounter(
                  getAdmissionTaxInShoppingCartcounter() + 1,
                );
              }
            }

            if (!taxProductInDB) {
              if (!isReturn) {
                dispatch({
                  type: SHOULD_ADD_ADMISSION_TAX,
                  payload: false,
                });
              }
              dispatch({
                type: CALCULATING_ENV_OR_ADM_TAX_FEES,
                payload: false,
              });
              return;
            }
          }

          dispatch({
            type: CALCULATING_ENV_OR_ADM_TAX_FEES,
            payload: false,
          });
          return;
        }

        if (admissionFeeProductInCart) {
          if (!isReturn && !shouldAddAdmissionTax) {
            dispatch({
              type: SHOULD_ADD_ADMISSION_TAX,
              payload: true,
            });
          }
          await dispatch(
            updateOrderPrice(
              admissionFeeProductInCart.finalPrice,
              admissionFeeProductInCart.finalPrice,
              admissionFeeProductInCart.orderIndex,
            ),
          );
        }

        // Remove admission tax product if tax exempt
        if (getAdmissionTaxFromCart(state)) {
          await dispatch(removeProduct(admissionTaxProductInCart?.orderIndex));
          setAdmissionTaxInShoppingCartcounter(0);
        }

        if (!isReturn) {
          dispatch({
            type: SHOULD_ADD_ADMISSION_TAX,
            payload: false,
          });
        }
      }
      dispatch({
        type: CALCULATING_ENV_OR_ADM_TAX_FEES,
        payload: false,
      });
    },
  },
  onRemoveOrder: {
    after: () => async (dispatch, getState) => {
      const state = getState();

      const shouldAddEnvProductToCart = shouldAddEnvProductToCartBasedOnCode5Names(
        state,
      );

      const currentSaleDoc = getCurrentSalesDocument(state);
      const productsInCart = getProductsInShoppingCart(state);
      const net = calculateNetTotal(state);
      const environmentalFeeProductInCart = getEnvProductFromShoppingCart(
        state,
      );
      const rateToApply = getEnvFeeRate(state);

      const shouldWaiveAdmissionTax = defineIfAdmissionTaxShouldBeWaived(state);
      const admisionTaxProductInCart = getAdmissionTaxFromCart(state);

      let envFeeAmount;
      if (rateToApply) {
        envFeeAmount = getEnvFeeAmount(rateToApply)(state);
      } else {
        if (typeof rateToApply !== 'number')
          dispatch(addError('Environmental fee rate is missing in BO.'));
        return;
      }

      if (currentSaleDoc) {
        await dispatch(checkIfReturningAllProducts());
      }

      await dispatch({
        type: HAS_REMOVED_ITEM,
        payload: true,
      });

      await dispatch({
        type: SET_ENV_FEE_AMOUNT,
        payload: envFeeAmount,
      });

      await dispatch(
        updateOrderPrice(
          Math.abs(envFeeAmount),
          Math.abs(envFeeAmount),
          environmentalFeeProductInCart?.orderIndex,
        ),
      );

      if (!shouldAddEnvProductToCart && environmentalFeeProductInCart) {
        dispatch(removeProduct(environmentalFeeProductInCart.orderIndex));
        dispatch(resetEnvFeeState());
      }

      if (shouldWaiveAdmissionTax && admisionTaxProductInCart) {
        dispatch({
          type: SHOULD_ADD_ADMISSION_TAX,
          payload: false,
        });
        dispatch({
          type: ADMISSION_FEE_TAX_AMOUNT,
          payload: 0,
        });
        dispatch(removeProduct(admisionTaxProductInCart.orderIndex));
        setAdmissionTaxInShoppingCartcounter(0);
      }

      if (productsInCart.length === 1 && environmentalFeeProductInCart) {
        dispatch(startNewSale({ askForConfirmation: false }));
        dispatch(resetEnvFeeState());
      }

      if (productsInCart.length === 1 && admisionTaxProductInCart) {
        dispatch(startNewSale({ askForConfirmation: false }));
        dispatch({
          type: SHOULD_ADD_ADMISSION_TAX,
          payload: false,
        });
      }

      if (
        productsInCart.length === 2 &&
        environmentalFeeProductInCart &&
        admisionTaxProductInCart
      ) {
        dispatch(startNewSale({ askForConfirmation: false }));
        dispatch({
          type: SHOULD_ADD_ADMISSION_TAX,
          payload: false,
        });
        dispatch({
          type: ADMISSION_FEE_TAX_AMOUNT,
          payload: 0,
        });
        dispatch(resetEnvFeeState());
      }

      if (net === 0 && environmentalFeeProductInCart) {
        dispatch(removeProduct(environmentalFeeProductInCart.orderIndex));
        dispatch(resetEnvFeeState());
      }
    },
  },
  onSetCustomer: {
    after: (props, customerData) => async (dispatch, getState) => {
      const state = getState();

      const productsInCart = getProductsInShoppingCart(state);
      // const { taxExemptCertificateNumber } = getCurrentSalesDocument(state);
      const isReturn = getIsAReturn(state);
      const currentConfig = getConfiguration(state);
      const envFeeState: EnvFeeState = getEnvState(state);

      const shouldAddEnvProductToCart = shouldAddEnvProductToCartBasedOnCode5Names(
        state,
      );

      const admissionTaxProductInCart = getAdmissionTaxFromCart(state);

      const { shouldAddAdmissionTax } = envFeeState;

      // region Remove admission tax if the customer is tax exempt

      const { tax_exempt_expiration_date } = new ErplyAttributes(
        customerData.attributes,
      ).asDict;

      const date = dayjs(
        new Date(tax_exempt_expiration_date),
        'MM/DD/YYYY',
        true,
      );
      const dateIsActive = dayjs().isSameOrBefore(date, 'date');

      if (admissionTaxProductInCart && dateIsActive) {
        dispatch({
          type: SHOULD_ADD_ADMISSION_TAX,
          payload: false,
        });
        dispatch(removeProduct(admissionTaxProductInCart.orderIndex));
        setAdmissionTaxInShoppingCartcounter(0);
      }

      if (
        currentConfig.CAFeeEnabled &&
        productsInCart.length &&
        !dateIsActive &&
        shouldAddAdmissionTax
      ) {
        if (!admissionTaxProductInCart) {
          const taxProduct: any = await dispatch(getAdmissionTAXProduct());

          const calculatedAdmissionFeeTaxAmount = await dispatch(
            defineAdmissionTaxAmount(),
          );

          if (taxProduct && getAdmissionTaxInShoppingCartcounter() < 1) {
            taxProduct.price = calculatedAdmissionFeeTaxAmount;
            await dispatch(addProduct(taxProduct));
            setAdmissionTaxInShoppingCartcounter(
              getAdmissionTaxInShoppingCartcounter() + 1,
            );
          }

          if (!taxProduct) {
            if (!isReturn) {
              dispatch({
                type: SHOULD_ADD_ADMISSION_TAX,
                payload: false,
              });
            }
          }
        }
      }
      // endregion

      if (shouldAddEnvProductToCart) {
        dispatch(addEnvFeeProductToCart);
      }
    },
  },
  onAddMultiProducts: {
    after: (p, orders) => async (dispatch, getState) => {
      const state = getState();

      const productsInCart = getProductsInShoppingCart(state);
      const envFeeState: EnvFeeState = getEnvState(state);
      const { admissionFeeTaxAmount } = envFeeState;
      const currentConfig = getConfiguration(state);
      const currentSaleDoc = getCurrentSalesDocument(state);
      const isReturn = getIsAReturn(state);
      const exchangeState = getExchangeState(state);
      const { warehouseID, pointOfSaleID, vatrate } = getSelectedPos(state);
      const customerID = getSelectedCustomerID(state);
      const clientCode = getClientCode(state);
      const sessionKey = getSessionKey(state);
      const shouldWaiveAdmissionTax = defineIfAdmissionTaxShouldBeWaived(state);
      const net = calculateNetTotal(state);
      const envProduct = getEnvProductFromShoppingCart(state);
      const rateToApply = getEnvFeeRate(state);
      const admissionTaxProductInOrders = getAdmissionTaxFromCart(
        state,
        orders,
      );

      const addedProducts: any = [];

      orders.forEach(orderProduct => {
        const fullProduct = getProductByID(orderProduct.productID)(state);
        fullProduct.finalPrice = orderProduct.finalNetPrice;
        fullProduct.amount = orderProduct.amount;
        fullProduct.vatrateID = orderProduct.vatrateID;

        addedProducts.push(fullProduct);
      });

      const envFeeProductInOriginalSalesDoc = currentSaleDoc.rows.find(
        product =>
          product.itemName.toUpperCase() ===
          currentConfig.envFeeName.toUpperCase(),
      );

      if (
        !currentConfig ||
        !currentConfig.envFeeName ||
        currentConfig.envFeeName === ''
      ) {
        dispatch(addError('Environmental fee plugin not configured correctly'));
        return;
      }

      if (
        !shouldWaiveAdmissionTax &&
        !isReturn &&
        admissionTaxProductInOrders
      ) {
        if (!admissionFeeTaxAmount || admissionFeeTaxAmount === 0) {
          const admissionProducts = await dispatch(getAdmissionFeeProducts());

          try {
            if (admissionProducts) {
              const finalPrice = await calculateShoppingCart({
                warehouseID,
                pointOfSaleID,
                sessionKey,
                clientCode,
                customerID,
                productID1: admissionProducts[0].productID,
                amount1: 1,
              });

              dispatch({
                type: ADMISSION_FEE_NET_PRICE,
                payload: finalPrice[0].rows[1].finalPrice,
              });

              const admissionTax = calculateAdmissionTaxAmount(
                finalPrice,
                vatrate,
              );

              await dispatch({
                type: ADMISSION_FEE_TAX_AMOUNT,
                payload: admissionTax,
              });
              await dispatch({
                type: SHOULD_ADD_ADMISSION_TAX,
                payload: true,
              });
            } else {
              throw new Error('Unable to find admission tax product');
            }
          } catch (error) {
            console.error('CAFees plugin: failed to calculate total', error);
            dispatch(
              addError('Error while calculating total - CA Admission fee'),
            );
            return;
          }
        }

        await dispatch({
          type: ADMISSION_FEE_TAX_AMOUNT,
          payload:
            !isReturn && admissionTaxProductInOrders
              ? admissionTaxProductInOrders?.price
              : admissionFeeTaxAmount,
        });
        await dispatch({
          type: SHOULD_ADD_ADMISSION_TAX,
          payload: true,
        });
      }

      if (!isReturn && productsInCart.length) {
        const { exchangeStep, itemsToExchange } = exchangeState;
        if (exchangeStep === 2 && itemsToExchange.length) {
          dispatch({ type: SHOULD_ADD_ENV_FEE, payload: true });
          dispatch({
            type: SET_ENV_FEE_AMOUNT,
            payload: envProduct?.price ? envProduct.price : 0,
          });
        }
        return;
      }

      let envFeeAmount;

      if (rateToApply) {
        envFeeAmount = getEnvFeeAmount(rateToApply)(state);
      } else {
        if (typeof rateToApply !== 'number') {
          dispatch(addError('Environmental fee rate is missing in BO.'));
        }
        return;
      }

      await dispatch({
        type: SET_ENV_FEE_AMOUNT,
        payload: envFeeAmount,
      });

      if (envProduct) {
        await dispatch(
          updateOrderPrice(
            Math.abs(envFeeAmount),
            Math.abs(envFeeAmount),
            envProduct.orderIndex,
          ),
        );

        await dispatch(checkIfReturningAllProducts());

        if (envFeeState.returnAllBoughtItems) {
          await dispatch({ type: SET_INITIAL_ENV_FEE, payload: envFeeAmount });
        } else {
          const originalEnvProduct = currentSaleDoc.rows.filter(
            item => item.productID === envProduct.productID,
          );
          await dispatch({
            type: SET_INITIAL_ENV_FEE,
            payload: originalEnvProduct[0].rowTotal,
          });
        }

        dispatch({ type: SHOULD_ADD_ENV_FEE, payload: true });
        return;
      }

      const envProductInDB: any = await dispatch(getEnvironmentalFeeProduct());

      if (envProductInDB && Math.abs(net) > 0) {
        await dispatch({
          type: SET_ENV_FEE_AMOUNT,
          payload: envFeeAmount,
        });
        await dispatch({ type: SET_INITIAL_ENV_FEE, payload: envFeeAmount });
        await dispatch(
          addProduct({
            ...envProductInDB,
            amount: -1,
            vatrateID: envFeeProductInOriginalSalesDoc?.vatrateID,
          }),
        );
        dispatch({ type: SHOULD_ADD_ENV_FEE, payload: true });

        return;
      }

      dispatch({ type: SHOULD_ADD_ENV_FEE, payload: false });
    },
  },

  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      const state = getState();

      const isReturn = getIsAReturn(state);
      const envFeeState = getEnvState(state);
      const { returnAllBoughtItems, priceHasBeenUpdated } = envFeeState;
      const productsInCart = getProductsInShoppingCart(state);
      const currentConfig = getConfiguration(state);
      const envProductInCart = getEnvProductFromShoppingCart(state);

      if (isReturn && envProductInCart && priceHasBeenUpdated) {
        const total = productsInCart.reduce((accumulator, currentValue) => {
          return accumulator + Math.abs(currentValue.rowTotal);
        }, 0);
        return {
          ...ap,
          total: -total,
        };
      }

      if (isReturn && envProductInCart && !returnAllBoughtItems) {
        return {
          ...ap,
        };
      }

      if (isReturn && envProductInCart) {
        return {
          ...ap,
        };
      }

      if (currentConfig.CAFeeEnabled && getAdmissionTaxFromCart(state)) {
        return {
          ...ap,
        };
      }

      return {
        ...ap,
      };
    },
  },
  onSaveSalesDocument: {
    before: (p: any) => async (dispatch, getState) => {
      const state = getState();

      const isReturn = getIsAReturn(state);
      const envFeeState: EnvFeeState = getEnvState(state);
      const { isAnAdmission } = getWaiversReduxState(state);

      if (!isReturn && !isAnAdmission) {
        // eslint-disable-next-line no-param-reassign
        p.salesDocument.attributes = {
          ...p.salesDocument.attributes,
          'original-env-fee-rate': envFeeState.envFeeRate.toString(),
        };
      }
    },
  },

  onResetShoppingCart: {
    after: () => async dispatch => {
      dispatch(resetEnvFeeState());
      dispatch({
        type: HAS_REMOVED_ITEM,
        payload: false,
      });
      dispatch({ type: PRICE_HAS_BEEN_UPDATED, payload: false });
      dispatch({
        type: SHOULD_ADD_ADMISSION_TAX,
        payload: false,
      });
      dispatch({
        type: ADMISSION_FEE_TAX_AMOUNT,
        payload: 0,
      });
      dispatch({
        type: SET_ENV_FEE_RATE,
        payload: 0,
      });
      setAdmissionTaxInShoppingCartcounter(0);
    },
  },
  onTaxExempt: () => async (dispatch, getState) => {
    const state = getState();
    const admissionTaxProduct = getAdmissionTaxFromCart(state);
    dispatch({
      type: SHOULD_ADD_ADMISSION_TAX,
      payload: false,
    });
    if (admissionTaxProduct) {
      dispatch(removeProduct(admissionTaxProduct.orderIndex));
      setAdmissionTaxInShoppingCartcounter(0);
    }
  },
  customHooks: {
    customerDisplayGetData: createSelector(
      state => getConfiguration(state).envFeeName,
      state => getConfiguration(state).admissionFeeName,
      state => getConfiguration(state).admissionTaxName,
      state => getEnvState(state).envFeeAmount,
      state => getEnvState(state).admissionFeeNetPrice,
      state => getEnvState(state).admissionFeeTaxAmount,
      state => getEnvState(state).shouldAddAdmissionTax,
      state => getTotalTax(state),
      state => getTotalNet(state),
      state => getCurrencyFormatter(state),
      (state, props) => props,
      (
        envFeeName,
        admFeeName,
        admTaxName,
        envFeeAmount,
        admFeeAmount,
        admTaxAmount,
        shouldAddAdmTax,
        coreTaxAmount,
        coreNetAmount,
        formatCurrency,
        props,
      ) => {
        return {
          ...props,
          products: props.products.filter(p =>
            [envFeeName, admTaxName]
              .filter(notUndefinedOrNull)
              .map(s => s.toUpperCase())
              .every(n => n !== p?.name?.toUpperCase()),
          ),
          net: formatCurrency(
            coreNetAmount - (shouldAddAdmTax ? admTaxAmount : 0) - envFeeAmount,
          ),
          surcharges: props.surcharges.concat(
            [
              shouldAddAdmTax &&
                admTaxAmount && {
                  id: 'pnpAdmissionTax',
                  name: 'Admissions tax',
                  amount: formatCurrency(admTaxAmount),
                },
              envFeeAmount && {
                id: 'pnpEnvironmentalFee',
                name: 'Environmental fee',
                amount: formatCurrency(envFeeAmount),
              },
            ].filter(a => a),
          ),
          surcharge: formatCurrency(
            admTaxAmount + envFeeAmount + coreTaxAmount,
          ),
        };
      },
    ),
  },
};

export default EnvironmentalFee;
