import { getPluginSelector } from 'reducers/Plugins';

import { SET_PRODUCTS_FOR_ASSIGNMENT } from './actionTypes';

export type OderAssignmentState = {
  itemsForAssignment: any[];
};

export const getAssignmentState = state =>
  getPluginSelector<OderAssignmentState>('order-assignment')(n => n ?? [])(
    state,
  );

export default (
  state = {
    itemsForAssignment: [],
  },
  action,
): OderAssignmentState => {
  switch (action.type) {
    case SET_PRODUCTS_FOR_ASSIGNMENT:
      return {
        ...state,
        itemsForAssignment: [...action.payload],
      };
    default:
      return state;
  }
};
