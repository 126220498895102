/* eslint-disable no-console */

import uuid from 'uuid';

import { setCloudStatus } from 'actions/connectivity';

import LocalDatabase from './core';

let dispatch = () => {};
// eslint-disable-next-line no-return-assign
export const storePlugin = store => ({ dispatch } = store);

export default class PendingRequests extends LocalDatabase {
  constructor() {
    super({ TABLE_NAME: 'pendingRequests' });
  }

  async add(request) {
    try {
      const store = {
        ...this.localData[this.CLIENT_CODE][this.storeKey],
        [uuid.v1()]: request,
      };
      dispatch(
        setCloudStatus('pendingRequests', {
          todo: Object.keys(store).length,
        }),
      );
      await this.saveItem({ value: store });
    } catch (e) {
      console.error('Failed to add offline request', request, e);
    }
    return null;
  }

  async remove(id) {
    try {
      const { [id]: deleted, ...store } = this.localData[this.CLIENT_CODE][
        this.storeKey
      ];
      dispatch(
        setCloudStatus('pendingRequests', {
          todo: Object.keys(store).length,
        }),
      );
      await this.saveItem({ value: store });
    } catch (e) {
      console.error('Failed to remove pending request', id, e);
    }
    return null;
  }
}
