// FEATURE: Enable <InputField formatter={}> to maintain caret position

/**
 * @typedef {(neVal: string, oldVal?: string) => string} Formatter
 *
 */

/**
 * @typedef {Formatter & {and: (other: Formatter) => ChainableFormatter}} ChainableFormatter
 */

/** @type {ChainableFormatter} */
function chain(first, ...others) {
  this.chained = this.chained ?? [];
  if (first === undefined) return this;
  const cached = this.chained.find(([other, _]) => other === first);
  if (cached) return cached[1];
  const newFunc = (newval, oldval) => this(first(newval, oldval), oldval);
  this.chained.push([first, newFunc]);
  newFunc.and = chain;
  return newFunc;
}

/**
 * Allow numbers and a leading minus only, all other characters are removed
 * @type {ChainableFormatter}
 */
const integer = (newval, oldval) => `${newval}`.replace(/\D|^-/g, '');

/**
 * First period or comma becomes the decimal point
 * Minus signs are moved to the front and cancel each other out pairwise
 * all other characters that aren't numbers are removed
 * @type {ChainableFormatter}
 */
const float = (nv, oldval) => {
  const newval = `${nv}`;
  const shouldBeNegative =
    (newval.length - newval.replace(/-/g, '').length) % 2;

  const periodsOnly = `${shouldBeNegative ? '-' : ''}${newval
    .replace(/,/g, '.')
    .replace(/-/g, '')}`;

  const numbersOnly = periodsOnly.replace(/[^0-9.-]/g, '');
  const [whole, ...fractions] = numbersOnly.split('.');
  if (fractions.length === 0) {
    return whole;
  }
  return `${whole}.${fractions.join('')}`;
};

/**
 * Dissallow the minus sign
 * @type {ChainableFormatter}
 */
const positive = (newval, oldval) => `${newval}`.replace(/^-/g, '');

/** @type {ChainableFormatter} */
const numberArray = (newval, oldval) =>
  `${newval}`.toString().replace(/[^\d,]/, '');

/** @type {ChainableFormatter} */
const percentage = (newval, oldval) => {
  if (Number(newval) > 100) {
    return '100';
  }
  // when user types something, remove the leading 0
  if (oldval === '0') {
    return newval.replace(/^0+/, '');
  }
  return newval;
};

/**
 * Allow a max of 'precision' character after the last decimal point
 * @type {ChainableFormatter}
 */
const toFixed = precision => {
  const formatter = (newval, oldval) => {
    const parts = `${newval}`.split('.');
    if (parts.length > 1) {
      parts[parts.length - 1] = parts[parts.length - 1].slice(0, precision);
    }
    return parts.join('.');
  };
  formatter.and = chain;
  return formatter;
};

/**
 * Block any changes that would cause the length to go above len characters
 * @type {ChainableFormatter}
 */
const maxLength = len => {
  const formatter = (newval, oldval) => {
    if (`${newval}`.length > len) {
      return oldval;
    }
    return newval;
  };
  formatter.and = chain;
  return formatter;
};

/** @type {ChainableFormatter} */
const hexCode = (newval, oldval) =>
  /^(#[0-9a-fA-F]{0,6})?$/.test(`${newval}`) ? newval : oldval;

/** @type {ChainableFormatter} */
const upperCase = newval => `${newval}`.toUpperCase();
/** @type {ChainableFormatter} */
const lowerCase = newval => `${newval}`.toLowerCase();

/* Formatter creators - have .and */
export { toFixed, maxLength };

// eslint-disable-next-line no-param-reassign, no-return-assign
Object.values({ integer, float, positive, hexCode, upperCase, lowerCase, numberArray, percentage }).forEach(
  form => (form.and = chain),
);
export { integer, float, positive, hexCode, upperCase, lowerCase, numberArray, percentage };
