import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getCurrentPrinterIntegration,
  getSupportedPrinterIntegrations,
  getSetting,
} from 'reducers/configs/settings';
import {
  PrinterScript,
  printSampleScript,
  PrinterScriptPreview as ReceiptPreview,
} from 'Systems/PrinterScript/exports';
import { tryPrintRawData, testPrinter } from 'actions/integrations/printer';
import InputField from 'components/FieldTypes/InputField';
import { saveSetting } from 'actions/configs';
import UIButton from 'components/UIElements/UIButton';
import { integer, positive } from 'components/FieldTypes/formatters';

import { useCheckboxToggle } from './utils';

export const PrintingMSConf = () => {
  const { t } = useTranslation('settingsPrinter');
  const printerInt = useSelector(getCurrentPrinterIntegration);
  const printerIntOptions = useSelector(getSupportedPrinterIntegrations);

  const [script, setScript] = useState(
    printSampleScript(new PrinterScript(printerInt))
      .commands.concat(['OPENCASHDRAWER'])
      .join('\n'),
  );
  const savedWidth = useSelector(getSetting('printer_go_ms_width'));
  const [width, setWidth] = useState(savedWidth);
  const dispatch = useDispatch();
  const [useLegacy, toggle] = useCheckboxToggle('MS.print.legacy');

  useEffect(() => {
    dispatch(
      saveSetting({
        parameterName: 'printer_go_ms_width',
        parameterValue: width,
      }),
    );
  }, [width]);
  const doPrint = () => {
    const s = new PrinterScript(printerInt);
    s.commands = script.split('\n');
    dispatch(tryPrintRawData(s.toString())).catch(() => {
      // do nothing
    });
  };
  return (
    <Card.Body>
      <h3>{t('goMS.title')}</h3>
      <InputField onChange={() => toggle()} type="checkbox" value={useLegacy}>
        {t('goMS.useLegacy')}
      </InputField>
      {useLegacy ? (
        <>
          <div>
            {t('goMS.steps.step', { nr: '1' })} {t('goMS.steps.testPrint')}
          </div>
          <UIButton
            text={t('goMS.buttons.identify')}
            action={() => dispatch(testPrinter())}
            variant="POS"
          />
          <div>
            {t('goMS.steps.step', { nr: '2' })} {t('goMS.steps.measureWidth')}
          </div>
          <InputField
            type="text"
            value={width}
            onChange={e => setWidth(e.target.value)}
            formatter={integer.and(positive)}
          />
          {t('goMS.current', { savedWidth })}
          <div>
            {t('goMS.steps.step', { nr: '3' })} {t('goMS.steps.selectPrinter')}
          </div>
          <InputField
            type="select"
            value={printerInt.name}
            onChange={e =>
              dispatch(
                saveSetting({
                  parameterName: 'currentPrinterIntegration',
                  parameterValue: e.target.value,
                }),
              )
            }
            options={printerIntOptions.map(opt => opt.name)}
          />
          <div style={{ marginTop: '2ch' }}>{t('goMS.debug')}</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'noWrap',
            }}
          >
            <textarea
              rows={24}
              value={script}
              onChange={e => setScript(e.target.value)}
              style={{ width: '100%', fontSize: 12, flex: '1 1' }}
            />
            <ReceiptPreview script={script} style={{ flex: '0 0' }} />
          </div>
          <hr />
          <UIButton
            text={t('goMS.buttons.print')}
            action={doPrint}
            variant="POS"
          />
        </>
      ) : null}
    </Card.Body>
  );
};
