import {
  GetApp,
  PowerSettingsNew,
  SettingsInputComponent,
} from '@material-ui/icons';
import React, { useState } from 'react';
import * as R from 'ramda';
import {
  InstallerStatus,
  MSTransitionalStatus,
} from '@pos-refacto/installer-rxjs';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Popover,
  Table,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useSet } from 'react-use';
import { Theme } from '@material-ui/core/styles/createTheme';
import classNames from 'classnames';
import { Alert } from '@material-ui/lab';

import { intersectEquals } from 'utils';
import {
  UrgentAlert,
  useUnhideUrgentAlert,
} from 'containers/Header/components/Installer/UrgentAlert';
import { useRegisterDevice } from 'containers/Header/components/Installer/useRegisterDevice';

import { HeaderIconButton } from '../Icon';

import { useMSRequirements } from './useMSRequirements';
import { useAutoInstallMS } from './useAutoInstallMS';
import useSaveMSEndpointsToLS from './useSaveMSEndpointsToLS';

const P = ({ children }) => (
  <Typography component="span">{children}</Typography>
);

const useStyles = makeStyles<Theme, object>(theme => ({
  red: {
    color: theme.palette.error.main,
  },
  orange: {
    color: theme.palette.orange?.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
  lime: {
    color: theme.palette.lime?.main,
  },
  green: {
    color:
      theme.palette.success?.[theme.palette.type === 'dark' ? 'light' : 'dark'],
  },
  gray: {
    color: theme.palette.text.disabled,
  },
  highlight: {
    filter: 'drop-shadow(0 0 4px white) saturate(0.5) brightness(2)',
  },
}));

const statusCheck = (onStatuses: any[], workingStatuses: any[]) => (
  status,
  expectedStatus,
) => {
  return {
    on: onStatuses.includes(expectedStatus ?? status),
    working: workingStatuses.includes(expectedStatus ?? status),
  };
};

const installed = statusCheck(
  [
    MSTransitionalStatus.STARTING,
    MSTransitionalStatus.STOPPING,
    MSTransitionalStatus.UPDATING_CONFIG,
    MSTransitionalStatus.REMOVING,
    'StatusRunning',
    'StatusStopped',
  ],
  [
    MSTransitionalStatus.DOWNLOADING,
    MSTransitionalStatus.INSTALLING,
    MSTransitionalStatus.REMOVING,
  ],
);
const running = statusCheck(
  ['StatusRunning', MSTransitionalStatus.STOPPING],
  [MSTransitionalStatus.STARTING, MSTransitionalStatus.STOPPING],
);

const StatusLight = ({
  presumed = undefined,
  status = '',
  configMatchesExpected,
}: {
  presumed:
    | MSTransitionalStatus
    | 'StatusRunning'
    | 'StatusStopped'
    | undefined;
  status: '' | 'StatusRunning' | 'StatusStopped';
  configMatchesExpected: boolean;
}) => {
  const styles = useStyles();

  const classesFor = ({ on, working }) => {
    return classNames({
      [styles.lime]: configMatchesExpected,
      [styles.orange]: !configMatchesExpected,
      [styles.gray]: !on,
      [styles.highlight]: working,
    });
  };
  return (
    <Box
      padding={1}
      borderRadius={4}
      style={{ background: 'black', color: 'white' }}
    >
      <GetApp className={classesFor(installed(status, presumed))} />
      <PowerSettingsNew className={classesFor(running(status, presumed))} />
    </Box>
  );
};
const InstallerStatusPanel = ({
  status,
  actualConfigs = {},
  expectedConfigs = {},
}: {
  status: InstallerStatus | Error | undefined;
  actualConfigs: object;
  expectedConfigs: object;
}) => {
  const styles = useStyles();

  const [expanded, { toggle }] = useSet(new Set());
  const msRequirements = useMSRequirements(status);

  if (!status) return <CircularProgress variant="indeterminate" />;
  if (status instanceof Error)
    return <Typography color="error">{status.message}</Typography>;

  return (
    <Box margin="1rem" width={500}>
      <Box marginBottom="1rem">
        {status.presumedStatus && <LinearProgress variant="indeterminate" />}
        <div>
          <b>HTTP:</b>{' '}
          <P>
            {status.httpCode} {status.presumedStatus ?? status.status}
          </P>
        </div>
        <div>
          <b>Time:</b>{' '}
          <P>
            {status.dateTime.replace(
              /(....)(..)(..)(..)(..)(..)/,
              '$1-$2-$3 $4:$5:$6',
            )}
          </P>
        </div>
        <div>
          <b>Message:</b> <P>{status.message}</P>
        </div>
        <div>
          <b>Version:</b> <P>{status.data.version}</P>
        </div>
        <div>
          <b>Description:</b> <P>{status.data.description}</P>
        </div>
        <div>
          <b>Config:</b>{' '}
          <P>
            {JSON.stringify(
              JSON.parse(status.data.config ?? '{}'),
              undefined,
              4,
            )}
          </P>
        </div>
      </Box>
      <Divider />
      <Box marginTop="1rem">
        <Table>
          <tbody>
            <tr>
              <td colSpan={3}>
                <b>Integrations required</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>
                <b>Version</b>
              </td>
              <td>
                <b>Status</b>
              </td>
            </tr>
            {Object.keys(expectedConfigs)
              .sort((a, b) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              })
              .map(k => {
                const int = status.data.integrations.find(i => i.name === k);
                return (
                  <React.Fragment key={`${k}-1`}>
                    <tr>
                      <td onClick={() => toggle(k)}>
                        <P>{k}</P>
                      </td>
                      <td>
                        <P>{int?.version}</P>
                      </td>
                      <td>
                        <Box display="flex" flexDirection="column">
                          <StatusLight
                            presumed={int?.presumedStatus}
                            status={int?.status ?? ''}
                            configMatchesExpected={intersectEquals(
                              expectedConfigs[k].config,
                              actualConfigs[k],
                            )}
                          />
                        </Box>
                      </td>
                    </tr>
                    {expanded.has(k) && (
                      <tr>
                        <td colSpan={3}>
                          <Box margin={2}>
                            <Table>
                              <tbody>
                                <tr>
                                  <td>Key</td>
                                  <td>Actual value</td>
                                  <td>Expected value</td>
                                </tr>
                                {Array.from(
                                  new Set(
                                    Object.keys({
                                      ...actualConfigs[k],
                                      ...expectedConfigs[k].config,
                                    }),
                                  ),
                                ).map((key: string) => {
                                  const [a, b] = [
                                    actualConfigs[k][key],
                                    expectedConfigs[k].config[key],
                                  ];
                                  const managed = ![a, b].includes(undefined);

                                  const color = R.when<any, string | undefined>(
                                    () => managed,
                                    () =>
                                      R.equals(a, b)
                                        ? styles.green
                                        : styles.red, //
                                  )(undefined);

                                  return (
                                    <tr
                                      key={k}
                                      style={{
                                        opacity: !managed ? 0.5 : undefined,
                                      }}
                                    >
                                      <td>{key}</td>
                                      <td className={color}>
                                        {JSON.stringify(a)}
                                      </td>
                                      <td>{JSON.stringify(b)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Box>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}

            <tr>
              <td colSpan={3}>
                <b>Other installed integrations</b>
              </td>
            </tr>
            {status.data.integrations
              .filter(({ name }) => !msRequirements[name])
              .map(int => (
                <React.Fragment key={`${int.name}-1`}>
                  <tr>
                    <td onClick={() => toggle(int.name)}>
                      <P>{int.name}</P>
                    </td>
                    <td>
                      <P>{int.version}</P>
                    </td>
                    <td>
                      <Box display="flex" flexDirection="column">
                        <StatusLight
                          presumed={int.presumedStatus}
                          status={int.status}
                          configMatchesExpected={false}
                        />
                      </Box>
                    </td>
                  </tr>
                  {expanded.has(int.name) && (
                    <tr>
                      <td colSpan={3}>
                        <Box margin={2}>
                          <Table>
                            <tr>
                              <td>Key</td>
                              <td>Value</td>
                            </tr>
                            {Object.keys(actualConfigs[int.name]).map(
                              (key: string) => {
                                const a = actualConfigs[int.name][key];

                                return (
                                  <tr key={int.name} style={{ opacity: 0.5 }}>
                                    <td>{key}</td>
                                    <td>{JSON.stringify(a)}</td>
                                  </tr>
                                );
                              },
                            )}
                          </Table>
                        </Box>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
};

export const HeaderInstaller = () => {
  const {
    info: { installerStatus, actualConfigs, expectedConfigs },
    errors,
    processing: autoInstalling,
    retry,
  } = useAutoInstallMS();
  const styles = useStyles();
  const someMsAreRequired = !!Object.values(expectedConfigs).length;

  useSaveMSEndpointsToLS();

  useRegisterDevice();

  const color = (() => {
    if (autoInstalling) return styles.yellow;

    const criticalIssue =
      errors.status ||
      errors.autoInstall ||
      errors.msCert ||
      errors.version ||
      installerStatus instanceof Error ||
      installerStatus?.httpCode !== 200;
    const configIssue = errors.config;
    const loading = installerStatus === undefined;

    if (someMsAreRequired) {
      if (criticalIssue) return styles.red;
      if (configIssue) return styles.orange;
      if (loading) return styles.orange;
    } else {
      if (criticalIssue) return styles.gray;
      if (configIssue) return styles.gray;
      if (loading) return styles.gray;
    }

    return styles.lime;
  })();

  const unhideUrgentAlert = useUnhideUrgentAlert();
  const [open, setOpen] = useState<HTMLSpanElement | null>(null);

  const errorMessages = [
    errors.status ? 'Some required MS are not running' : null,
    errors.autoInstall
      ? `Automatic installation has failed: 
    ${(errors.autoInstall as Error)?.message}`
      : null,
    errors.version ? 'Some MS has incorrect version and will be updated' : null,
    errors.msCert ? 'Some MS is inacessible and will be updated' : null,
    installerStatus instanceof Error
      ? `Auto-install failed with error: ${installerStatus?.message}`
      : null,
    !(installerStatus instanceof Error) && installerStatus?.httpCode !== 200
      ? `Failed to connect to installer: ${installerStatus?.message}`
      : null,
  ].filter(Boolean);

  return (
    <>
      <UrgentAlert
        isRelevant={installerStatus instanceof Error && someMsAreRequired}
      />
      <HeaderIconButton
        size="small"
        onClick={e => {
          setOpen(e.currentTarget);
          unhideUrgentAlert();
        }}
        id="header-installer-toggle"
      >
        <SettingsInputComponent className={color} />
      </HeaderIconButton>
      <Popover
        open={!!open}
        anchorEl={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className="erply-header__dropdown erply-header__dropdown--installer"
        data-testid="installer"
        onClose={() => setOpen(null)}
      >
        {installerStatus && (
          <InstallerStatusPanel
            status={installerStatus}
            actualConfigs={actualConfigs}
            expectedConfigs={expectedConfigs}
          />
        )}

        <Box marginX="1rem">
          <Divider />
        </Box>

        <Box margin="1rem">
          {autoInstalling ? (
            <Alert severity="info">Auto-install in progress...</Alert>
          ) : null}
          {errors.config ? (
            <Alert severity="warning">
              Some MS has incorrect configuration
            </Alert>
          ) : null}
          {errorMessages.length ? (
            <Alert severity="error">
              {errorMessages.map(error => (
                <p>{error}</p>
              ))}
            </Alert>
          ) : null}

          {retry ||
            (true && (
              <Button
                onClick={retry}
                color="secondary"
                variant="contained"
                disabled={autoInstalling}
              >
                Rerun auto-install
              </Button>
            ))}
        </Box>
      </Popover>
    </>
  );
};
