import { getUrlForService } from 'actions/serviceEndpoints';

export async function getImageUrl(clientCode: string) {
  const baseURL = await getUrlForService('cdn');
  return `${baseURL}images/${clientCode}`;
}

export function addSizeToUrl(baseUrl: string, size: number) {
  return `${baseUrl}?width=${size}&height=${size}`;
}
