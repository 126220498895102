import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import List from 'components/List';
import QuestionMark from 'components/QuestionMark';
import { getSaleOptionButtons } from 'reducers/UI/gridButtons';
import { isString } from 'utils';
import { GridButton } from 'components/GridButton';

import styles from './index.module.scss';

const FunctionItem = React.memo(({ item }) => {
  useShortcut(item.hotkey, item.action);
  const [processing, setProcessing] = useState(false);

  const onClick = async () => {
    if (!item.action) {
      return;
    }
    setProcessing(true);

    await item.action(); // be careful not to return unresolved promises from item.action
    setProcessing(false);
  };

  const key = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      item.id && item.id !== 'extendBttn'
        ? item.id
        : Object.keys(item).length
        ? 'saleoption-extend-btn'
        : 'empty-btn',
    [item],
  );

  if (item.view) {
    const { view: Component, ...rest } = item;
    return <Component {...rest} />;
  }

  return (
    <GridButton
      onClick={onClick}
      btnColor={item.type === 'special' ? 'grid_green' : 'grid_light'}
      disabled={(!item.action && item.name) || processing || item.disabled}
      title={isString(item.name) ? item.name : undefined}
      data-testid="sale-option-button"
      data-test-key={key}
    >
      {item.hotkey ? (
        <span
          data-testid="sale-option-hotkey"
          data-test-key={`sale-option-hotkey-${item.id}`}
          className={styles.hotkey}
        >
          {item.hotkey}
        </span>
      ) : null}
      {item.badge ? (
        <span
          data-testid="sale-option-badge"
          data-test-key={`sale-option-badge-${item.id}`}
          className={styles.badge}
        >
          {item.badge}
        </span>
      ) : null}

      {item.icon ? (
        <div
          data-testid="sale-option-icon-container"
          data-test-key={`sale-option-icon-container-${item.id}`}
          className={styles.iconButton}
        >
          <span />
          <Icon
            data-testid="sale-option-icon"
            data-test-key={`sale-option-icon-${item.id}`}
            name={item.icon}
          />

          <span
            data-testid="sale-option-name"
            data-test-key={`sale-option-name-${item.id}`}
          >
            {item.name}
          </span>
        </div>
      ) : (
        <span
          data-testid="sale-option-name"
          data-test-key={`sale-option-name-${item.id}`}
        >
          {item.name}
        </span>
      )}
      {item.action && (
        <QuestionMark
          data-testid="sale-option-question-mark"
          data-test-key={`question-mark-${item.id}`}
          code={item.code}
          position={{ top: '1px', right: '1px' }}
          color="#333"
        />
      )}
    </GridButton>
  );
});

FunctionItem.propTypes = {
  item: PropTypes.object,
};

const SaleOptions = ({ beforeSelect = null, ...props }) => {
  const dispatch = useDispatch();

  const baseButtons = useSelector(getSaleOptionButtons);
  const { t } = useTranslation('gridButtons');

  const buttons = useMemo(
    () =>
      baseButtons.map(item => ({
        ...item,
        name: item.name ?? t(`saleOptions.${item.id}`),
        action: item.action
          ? async (...args) => {
              beforeSelect && (await beforeSelect());
              await dispatch(item.action(...args));
            }
          : undefined,
      })),
    [baseButtons, beforeSelect, dispatch, t],
  );

  return <List items={buttons} itemComponent={<FunctionItem />} {...props} />;
};

export default SaleOptions;
