import axios from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

export const defaultChaseUrl = 'https://localhost.erply.com:10100/api/v1/';

const instance = axios.create();

function getChaseUrl() {
  const endpoint = getMSEndpointFromLS('chase');
  return endpoint ? `${endpoint}api/v1/` : defaultChaseUrl;
}

instance.interceptors.request.use(config => {
  return {
    ...config,
    baseURL: getChaseUrl(),
  };
});

export default instance;
