import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { PluginComponent } from 'plugins';
import { useAppDispatch } from 'reducers';
import { startNewSale } from 'actions/sales';
import { getHasProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getIsCurrentSaleAReturn } from 'reducers/Payments';
import QuestionMark from 'components/QuestionMark';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import Icon from 'components/Icon';
import { getConnectionHealth } from 'reducers/connectivity/connection';

import { btnTextContainerStyle, btnTextStyle } from './styles';
import StyledButton from './StyledButton';

const RedButton: React.FC<{ width: number | string; height?: number }> = ({
  width,
  height,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('gridButtons');

  const hasCart = useSelector(getHasProductsInShoppingCart);
  const isCurrentSaleAReturn = useSelector(getIsCurrentSaleAReturn);
  const connectionHealth = useSelector(getConnectionHealth);

  const btnIsNewSale = hasCart && !isCurrentSaleAReturn;

  function processReturn() {
    dispatch(
      openModalPage({
        component: modalPages.ProductReturn,
        modalClassName: 'product-return-modal',
      }),
    );
  }

  return btnIsNewSale ? (
    <StyledButton
      variant="contained"
      onClick={() => dispatch(startNewSale())}
      data-testid="new-sale-btn"
      width={width}
      height={height}
      color="grid_red"
    >
      <span style={btnTextContainerStyle}>
        <span style={btnTextStyle}>
          <Trans i18nKey="actions.cancelSale" ns="gridButtons">
            <Icon name="icon_trash_alt" />
          </Trans>
        </span>
      </span>
    </StyledButton>
  ) : (
    <PluginComponent hookname="UICustomReturnCTA">
      <StyledButton
        variant="contained"
        data-testid="return-btn"
        onClick={processReturn}
        disabled={!connectionHealth}
        width={width}
        height={height}
        color="grid_red"
      >
        <QuestionMark code={9} position={{ top: 5, right: 5 }} color="blue" />
        <span style={btnTextContainerStyle}>
          <span style={btnTextStyle}>{t('actions.return')}</span>
        </span>
      </StyledButton>
    </PluginComponent>
  );
};

export default RedButton;
