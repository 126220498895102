import { Box, styled, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { modalPages } from 'constants/modalPage';
import { Employee } from 'types/Employee';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import Icon from 'components/Icon';

import { BoldText } from '.';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '1rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    background: '#b7e2f3',
    color: '#000',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
  },
}));

type Props = {
  employees: Employee[];
};

const ClockedInEmployees: React.FC<Props> = ({ employees }) => {
  const { t } = useTranslation('clockInOut');
  const dispatch = useDispatch();

  const clockOutEmployee = (employee: Employee) => {
    dispatch(
      openModalPage({
        component: modalPages.clockOut,
        props: {
          clockedInEmployee: employee,
          username: employee.username,
        },
        // @ts-ignore
        groupID: modalPages.clockOut,
        replace: true,
      }),
    );
  };

  return employees.length > 0 ? (
    <Box data-testid="employee-container" marginTop="1.5rem">
      <BoldText variant="h5" paragraph>
        {t('clockedIn.title')}
      </BoldText>
      {employees.map(employee => (
        <StyledBox
          key={employee.employeeID}
          tabIndex={0}
          role="button"
          onClick={() => clockOutEmployee(employee)}
          onKeyDown={e => {
            if (e.key === 'Enter') clockOutEmployee(employee);
          }}
          data-test-key={employee.employeeName}
        >
          <Box>
            <Typography variant="h5">{employee.employeeName}</Typography>
            <Typography variant="h6">{employee.clockInTime}</Typography>
          </Box>
          <Box>
            <Icon name="arrow_right" />
          </Box>
        </StyledBox>
      ))}
    </Box>
  ) : (
    <Typography
      style={{ marginTop: '10px' }}
      variant="h6"
      data-testid="no-clocked-in-employees"
    >
      {t('clockedIn.none')}
    </Typography>
  );
};

export default ClockedInEmployees;
