import { openPluginModalPage } from 'actions/modalPage';
import { modals } from 'plugins/mm/constants';
import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';

import styles from './PirForm.module.scss';

export const openPirFormIfReturn: Required<
  PosPlugin
>['onSaveSalesDocument']['after'] = (_p, o) => async (dispatch, getState) => {
  const saveSalesDocumentRequest = o.requests.find(
    request => request.requestName === 'saveSalesDocument',
  );
  const isReturn = saveSalesDocumentRequest?.type === 'CREDITINVOICE';
  if (!isReturn) return;

  const pirActiveReasonCodes = getSetting('pir_active_reason_codes')(
    getState(),
  );

  const shouldOpenPirForm = Object.entries(saveSalesDocumentRequest ?? {})
    .filter(([key]) => /returnReasonID/.test(key))
    .some(([, value]) => pirActiveReasonCodes.includes(String(value)));

  if (!shouldOpenPirForm) return;

  dispatch(
    openPluginModalPage(modals.PIR_FORM)({
      isPopup: true,
      modalClassName: styles['pir-form-modal'],
      props: {
        documentID: o.salesDocument.invoiceID,
      },
    }),
  );
};
