import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PluginComponent } from 'plugins';

type Props = {
  wideTable: boolean;
  currencySymbol: any;
  isMobileView: boolean;
};

const TableBillHeader: FC<Props> = ({
  wideTable,
  currencySymbol,
  isMobileView,
}) => {
  const { t } = useTranslation('billTable');
  return (
    <thead>
      <PluginComponent hookname="UICustomTableBillHeader">
        {/* Column headers */}
        <tr>
          <th>{t('headers.name')}</th>
          <th>{t('headers.code')}</th>
          <th>{t('headers.qty')}</th>
          <th>{t('headers.price')}</th>
          {wideTable ? (
            <th>{t('headers.discount', { context: 'percentage' })}</th>
          ) : null}
          {wideTable ? (
            <th>
              {t('headers.discount', { context: 'amount', currencySymbol })}
            </th>
          ) : null}
          {wideTable ? <th>{t('headers.vat')}</th> : null}
          <th />
          {!isMobileView && (
            <>
              <th />
              <th />
            </>
          )}
        </tr>
      </PluginComponent>
    </thead>
  );
};

export default TableBillHeader;
