import i18next from 'i18next';

import {
  CardPaymentHooks,
  FUNC_BUTTONS,
  PaymentObj,
} from 'paymentIntegrations/types';
import { PAYMENT_TYPES } from 'constants/Payments';

import {
  TransactionDeclinedResponse,
  TransactionSuccessResponse,
} from '../types';

import printNets from './printing';

export default ({
  response,
  payment,
  hooks,
}: {
  response: TransactionSuccessResponse | TransactionDeclinedResponse;
  payment: PaymentObj;
  hooks: CardPaymentHooks;
}) => async dispatch => {
  const { enableButtons, updateMessage, beforeDocSave } = hooks;
  if (!response)
    throw new Error(
      i18next.t('paymentIntegrations:nets.alerts.missingResponse'),
    );

  if (!('result' in response)) throw response;

  const [res] = response.result;

  enableButtons([FUNC_BUTTONS.CANCEL, FUNC_BUTTONS.VOID]);
  updateMessage(
    i18next.t('paymentIntegrations:transactionMessages.successful'),
  );
  if (payment.shouldProcess && payment.paid) {
    hooks.beforeDocDelete(payment.key);
    return true;
  }
  await beforeDocSave({
    paid: true,
    key: payment.key,
    type: PAYMENT_TYPES.CARD,
    amount: payment.amount,
    cardType: res.localModeEventArgs.CardIssuerName,
    cardNumber: res.cardNumber.slice(-4),
    attributes: {
      refNo: res.transactionId,
    },
  });

  await dispatch(
    printNets(
      response.result[0].merchantReceipt,
      response.result[0].customerReceipt,
    ),
  );

  return true;
};
