import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Button,
  TextFieldProps,
  MenuItem,
  Box,
} from '@material-ui/core';
import { reject, equals } from 'ramda';
import { getDaysInMonth } from 'date-fns';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import * as c from 'constants/modalPage';
import { advancedSearch } from 'services/CustomerRegistery';
import { PluginComponent } from 'plugins';

const defaultTextfieldValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobilePhone: '',
  state: '',
  city: '',
  postalCode: '',
  birthdayDay: '',
  birthdayMonth: '',
  birthdayYear: '',
};

type SelectProps = {
  options: string[];
  name: keyof typeof defaultTextfieldValues;
} & TextFieldProps;

const SelectField: React.FC<SelectProps> = ({ options, ...props }) => (
  <TextField style={{ margin: '5px 0' }} select {...props}>
    {options.map(option => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </TextField>
);

type Props = {
  children?: never;
};

const getOptions = (length: number) =>
  new Array(length).fill('').map((_v, i) => String(i + 1).padStart(2, '0'));

const getYears = (count: number) => {
  const now = new Date().getUTCFullYear();
  return new Array(now - (now - count))
    .fill('')
    .map((v, idx) => String(now - idx));
};

const CustomerSearch: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('advancedCustomerSearch');

  const [formState, setFormState] = useState(defaultTextfieldValues);

  const { birthdayMonth, birthdayDay, birthdayYear } = formState;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setFormState(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const currentDate = new Date();
  const yearToPass = birthdayYear || currentDate.getFullYear();
  const monthToPass = birthdayMonth || currentDate.getMonth();
  const daysInMonth = getDaysInMonth(
    new Date(Number(yearToPass), Number(monthToPass) - 1),
  );

  const handleSubmit = async () => {
    try {
      const customers = await advancedSearch(reject(equals(''))(formState));
      dispatch(
        openModalPage({
          component: c.modalPages.advancedSearchResults,
          // @ts-ignore
          groupID: c.modalPages.advancedCustomerSearch,
          replace: true,
          isPopup: true,
          modalClassName: 'advanced-customer-search-results',
          props: { customers },
        }),
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to submit customer search', err);
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t('titles.search')}</Modal.Title>
        <Box display="flex">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t('buttons.search')}
          </Button>
          <CloseButton action={() => dispatch(previousModalPage())} />
        </Box>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
        {useMemo(
          () =>
            Object.entries(formState)
              .slice(0, 8)
              .map(([key, value]) => (
                <TextField
                  key={key}
                  style={{ margin: '5px 0' }}
                  label={t(`fields.${key}`)}
                  value={value}
                  name={key}
                  onChange={onChange}
                  variant="outlined"
                />
              )),
          [formState, t],
        )}
        <PluginComponent hookname="UICustomBirthdayInput">
          <Box display="flex" gridGap="1rem">
            <SelectField
              label={t('fields.birthdayMonth')}
              value={birthdayMonth}
              name="birthdayMonth"
              fullWidth
              onChange={onChange}
              options={getOptions(12)}
            />
            <SelectField
              label={t('fields.birthdayDay')}
              value={birthdayDay}
              name="birthdayDay"
              fullWidth
              onChange={onChange}
              options={getOptions(daysInMonth)}
            />
            <SelectField
              label={t('fields.birthdayYear')}
              value={birthdayYear}
              fullWidth
              onChange={onChange}
              options={getYears(100)}
              name="birthdayYear"
            />
          </Box>
        </PluginComponent>
      </Modal.Body>
    </>
  );
};

export default CustomerSearch;
