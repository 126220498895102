export const chaseFields = {
  devicePort: {
    id: 'devicePort',
    type: 'text',
    validate: val =>
      val?.length
        ? val?.match(/[^\d]/g)?.length
        ? { message: 'invalidDevicePort' }
        : null
        : { message: 'isMissing', dict: { field: 'devicePort' } },
  },
  deviceIP: {
    id: 'deviceIP',
    type: 'text',
    validate: val =>
      val?.length
        ? val?.match(/[^\da-zA-Z.-]/g)?.length
        ? { message: 'invalidDeviceIP' }
        : null
        : { message: 'isMissing', dict: { field: 'deviceIP' } },
  },
};