import { SAIState, Action } from './types';
import { SET_SAI_NUMBER, SET_SCANNED_QUERY } from './constants';

const initialState: SAIState = {
  SAINumber: null,
  scannedQuery: null,
};

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case SET_SAI_NUMBER:
      return {
        ...state,
        SAINumber: action.payload,
      };
    case SET_SCANNED_QUERY:
      return {
        ...state,
        scannedQuery: action.payload,
      };
    default:
      return state;
  }
}
