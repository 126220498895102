import * as api from 'services/ErplyAPI/warehouses';
import * as AccountAPI from 'services/AccountAdmin';
import { addError } from 'actions/Error';
import * as warehousesActionTypes from 'constants/warehouses';
import { REDUX_WAREHOUSE, REDUX_WAREHOUSEID } from 'constants/persistence';
import { getAllWarehouses } from 'reducers/warehouses';

export function fetchStarted() {
  return {
    type: warehousesActionTypes.FETCH_STARTED,
  };
}

export function fetchSuccess(warehouses) {
  return {
    type: warehousesActionTypes.FETCH_SUCCESS,
    warehouses,
  };
}

export function fetchFailure(error) {
  return {
    type: warehousesActionTypes.FETCH_FAILURE,
    error,
  };
}

export function getWarehouses(params) {
  return async dispatch => {
    dispatch(fetchStarted());
    try {
      const warehouses = await api.getWarehouses(params);
      // TODO: Request Acc Admin WHs to get updated to include all the fields from Erply API
      const accountAdminWarehousesPromise = AccountAPI.fetchWarehouses()
        .then(res => res)
        .catch(err => {
          console.error('Failed to fetch account admin warehouses. Error: ', {
            err,
          });
          return { warehouses: [] };
        });
      const {
        warehouses: accountAdminWarehouses,
      } = await accountAdminWarehousesPromise;
      const warehousesToSet = warehouses?.map(wh => {
        const accAdminWarehouse = accountAdminWarehouses.find(
          aaw => Number(aaw.id) === Number(wh.warehouseID),
        );
        if (accAdminWarehouse) {
          return {
            ...wh,
            receiptFooterText: accAdminWarehouse.receiptFooterText,
          };
        }
        return wh;
      });
      dispatch(fetchSuccess(warehousesToSet));
    } catch (e) {
      console.error('Unable to load warehouses', e);
      dispatch(addError(e.message));
      dispatch(fetchFailure(e));
    }
  };
}

export function selectWarehouse(id) {
  return async (dispatch, getState) => {
    localStorage.setItem(REDUX_WAREHOUSEID, JSON.stringify(id));
    const found =
      getAllWarehouses(getState()).find(
        warehouse => warehouse.warehouseID === id,
      ) || null;
    if (found) {
      localStorage.setItem(REDUX_WAREHOUSE, JSON.stringify(found));
    }

    dispatch({
      type: warehousesActionTypes.SELECT_WAREHOUSE,
      payload: id,
    });
  };
}

export function deselectWarehouse() {
  return async dispatch => {
    localStorage.removeItem(REDUX_WAREHOUSEID);
    localStorage.removeItem(REDUX_WAREHOUSE);

    dispatch({
      type: warehousesActionTypes.DESELECT_WAREHOUSE,
    });
  };
}

export function fetchHomeStoresSuccess(homeStores) {
  return {
    type: warehousesActionTypes.FETCH_HOMESTORES_SUCCESS,
    payload: homeStores,
  };
}

export function getHomeStores() {
  return async dispatch => {
    try {
      const homeStores = await api.getHomeStores();
      dispatch(fetchHomeStoresSuccess(homeStores));
    } catch (e) {
      console.error('Unable to load home stores', e);
      dispatch(addError(e.message));
    }
  };
}
