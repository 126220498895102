import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core';
import { isEmail } from 'validator';

import { saveCustomer } from 'actions/customerSearch';
import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import Icon from 'components/Icon';
import {
  getCustomerIssuedCoupons,
  getIsDefaultCustomer,
  getSearchCustomerGroups,
  getSelectedCustomer,
  getSelectedCustomerID,
} from 'reducers/customerSearch';
import {
  getClientCode,
  getLoggedInEmployeeID,
  getSessionKey,
  getUserLoggedIn,
} from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { RootState } from 'reducers';
import { Customer } from 'types/Customer';
import { getSetting } from 'reducers/configs/settings';
import CustomerInput from 'components/inputs/CustomerInput/CustomerInput';
import MuiButton from 'components/CustomButtons/MuiButton';

import singlePrompt, { couponsPrompt } from '../utils';
import {
  getMedicalEntitySelected,
  getSchoolEntitySelected,
  getTafReduxState,
} from '../rdx/reducers';
import { setGlobalMedical, setGlobalSchool } from '../rdx/actions';

import './components.scss';

const CustomerData = () => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { address, mobile, email } = useSelector(getSelectedCustomer);
  const issuedCoupons = useSelector(getCustomerIssuedCoupons);
  const customer = useSelector(getSelectedCustomer);
  const customerID = useSelector(getSelectedCustomerID);

  return (
    <div className="taf-customer-data-container">
      {issuedCoupons?.length > 0 ? (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <p
          className="vouchers"
          onClick={() => {
            dispatch(openModalPage({ component: mp.saleCoupons }));
          }}
        >
          {issuedCoupons.length} vouchers available
        </p>
      ) : null}
      <p>
        <strong>Customer ID: </strong>
        {customerID}
      </p>
      <p>{address}</p>
      {mobile ? (
        <p>
          <strong>Phone:</strong> {mobile}
        </p>
      ) : null}
      <p>
        <strong>E-mail:</strong> {email}
      </p>
      <span
        className="taf-user-badge-icon-span icon_plus_alt"
        onClick={() => {
          return dispatch(couponsPrompt());
        }}
      />
      <Icon
        style={{ paddingLeft: '12px', paddingRight: '12px' }}
        key="taf-icon-edit"
        name="icon_pencil-edit"
        title="taf-icon-edit"
        action={() =>
          dispatch(
            openModalPage({
              component: mp.createCustomer,
              props: { customer },
            }),
          )
        }
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  tafCustomerDetailsContainer: {
    flexGrow: 1,
    maxWidth: 'none',
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'visible',
    border:
      theme.palette.type === 'dark'
        ? '1px solid rgba(255,255,255 , 0.23)'
        : '1px solid rgba(0,0,0 , 0.23)',
    boxShadow: 'none',
  },
  customerDataContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    padding: '10px',
    '& > div': {
      minHeight: '50%',
    },
  },
  arrowIcon: {
    position: 'absolute',
    fontSize: '3em',
    zIndex: 99,
    [theme.breakpoints.up('sm')]: {
      top: '40%',
      left: '-25px',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
      left: 'calc(50% - 21px)',
      top: '-25px',
    },
  },
  buttonsContainer: {
    marginTop: '10px',
  },
  actionButton: {
    width: '100%',
    height: '38px',
  },
  customerResults: {
    maxHeight: 'calc(100vh - 270px)',
    marginBottom: '54px',
  },
}));

const TafCustomerDetails = () => {
  const styles = useStyles();
  const isDefaultCustomer = useSelector(getIsDefaultCustomer);
  const schoolSelected = useSelector(getSchoolEntitySelected);
  const medicalSelected = useSelector(getMedicalEntitySelected);
  const currentCustomer = useSelector(getSelectedCustomer);
  const customerGroups = useSelector(getSearchCustomerGroups);
  const { customerGroupID } =
    customerGroups.find(group => group.name.toUpperCase() === 'CLUBFIT') ?? {};

  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const shouldHideActionButtons =
    isDefaultCustomer || currentCustomer.groupID !== customerGroupID;

  return (
    <>
      <Paper className={styles.tafCustomerDetailsContainer}>
        <div className={styles.customerDataContainer}>
          {isDefaultCustomer ? null : (
            <div>
              <CustomerData />
            </div>
          )}
        </div>
        <ArrowLeftIcon className={styles.arrowIcon} />
      </Paper>
      {shouldHideActionButtons ? null : (
        <Grid container item spacing={1} className={styles.buttonsContainer}>
          <Grid item xs={6}>
            <MuiButton
              color={schoolSelected ? 'grid_green' : 'grid_red'}
              className={styles.actionButton}
              variant="contained"
              onClick={async () => {
                await dispatch(
                  singlePrompt('TafCustomerSearchModal', {
                    type: 'schoolEntity',
                    selected: schoolSelected,
                  }),
                ).then(
                  c => dispatch(setGlobalSchool(c)),
                  // If user cancels, ignore
                  () => undefined,
                );
              }}
              disableElevation
            >
              LINK SCHOOL
            </MuiButton>
          </Grid>
          <Grid item xs={6}>
            <MuiButton
              color={medicalSelected ? 'grid_green' : 'grid_red'}
              className={styles.actionButton}
              variant="contained"
              onClick={async () => {
                await dispatch(
                  singlePrompt('TafCustomerSearchModal', {
                    type: 'medicalEntity',
                    selected: medicalSelected,
                  }),
                ).then(
                  c => dispatch(setGlobalMedical(c)),
                  // If user cancels, ignore
                  () => undefined,
                );
              }}
              disableElevation
            >
              LINK MEDICAL
            </MuiButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const TafCustomerSearchModal = ({
  props: { type, selected },
  resolve,
  reject,
}) => {
  const [customer, setCustomer] = useState<Customer | number | null>(selected);
  const {
    customerLastUsedEntities: {
      [type === 'schoolEntity'
        ? 'previousAssociations'
        : 'previousProffesionals']: previousCustomers,
    },
  } = useSelector(getTafReduxState);
  const groupId = useSelector(
    getSetting(
      type === 'schoolEntity'
        ? 'touchpos_customer_association_group_id'
        : 'touchpos_customer_proffessional_group_id',
    ),
  );

  const extraParams = useMemo(
    () => ({
      getAssociationsAndProfessionals: 1,
      groupIDWithSubgroups: groupId,
      mode: 'CUSTOMERS',
    }),
    [groupId],
  );

  const styles = useStyles();
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  // Send previous associations with negative IDs so that they can be distinguished from search results
  const previousCustomersWithCustomIDs = previousCustomers.map(c => ({
    ...c,
    id: -c.id,
  }));
  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">Select customer</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <CloseButton
            action={() => {
              dispatch(previousModalPage());
              reject(new Error('User cancelled action'));
            }}
          />
        </Box>
      </Box>
      <Box className={`${styles.customerResults} taf-customer-search-box`}>
        <CustomerInput
          open
          autoFocus
          // @ts-ignore
          value={customer}
          modifyOptions={(c, text) => {
            if (text) {
              return previousCustomersWithCustomIDs.concat({
                id: 0,
                companyName: text,
              } as Customer);
            }
            return [...previousCustomersWithCustomIDs, ...c];
          }}
          groupBy={(id: number) =>
            id < 0 ? 'Previous associations' : 'Search results'
          }
          setValue={customer => {
            setCustomer(customer);
            // Allow user to clear the field
            // Only consider it a selection if a new customer is chosen
            if (customer) {
              // Undo negation in case a 'previous association' was selected
              resolve({ ...customer, id: Math.abs(customer.id) });
              dispatch(previousModalPage());
            }
          }}
          // the 'Loading results' option should be disabled
          getOptionDisabled={o => o === 0}
          // Ignore negation when determining whether to highlight
          getOptionSelected={(a, b) => Math.abs(a) === Math.abs(b)}
          extraParams={extraParams}
          // Do not filter options in realtime (let the API handle it, and always show previous associations and the 'loading results
          filterOptions={o => o}
        />
      </Box>
    </Box>
  );
};

export const TafCouponsPoints = ({ closeModal }) => {
  const { loginUrl, userID } = useSelector(getUserLoggedIn);
  const { customerID } = useSelector(getSelectedCustomer);
  const employeeID = useSelector(getLoggedInEmployeeID);
  const { pointOfSaleID, warehouseID } = useSelector(getSelectedPos);
  const sessionKey = useSelector(getSessionKey);
  const code = useSelector(getClientCode);

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">Points</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <CloseButton action={closeModal} />
        </Box>
      </Box>
      <Box>
        <iframe
          title="test"
          src={`${loginUrl}hosted_plugin.php?plugin=AdjustRewardPointsInPOS&code=${code}&sessionKey=${sessionKey}&customerID=${customerID}&userID=${userID}&employeeID=${employeeID}&pointOfSaleID=${pointOfSaleID}&warehouseID=${warehouseID}`}
          style={{ height: '350px', borderStyle: 'none' }}
        />
      </Box>
    </Box>
  );
};

export const TafContactUpdateForm = ({ closeModal, customer }) => {
  const {
    email,
    mobile,
    firstName,
    lastName,
    customerID,
    companyName,
    customerType,
  } = customer;
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const [updatedEmail, setEmail] = useState(email);
  const [updatedMobile, setMobile] = useState(mobile);

  const shouldDisableButton = updatedEmail && !isEmail(updatedEmail);

  const updateCustomer = () =>
    dispatch(
      saveCustomer(
        {
          customerID,
          email: updatedEmail,
          mobile: updatedMobile,
        },
        closeModal,
      ),
    );

  return (
    <Box overflow="hidden" display="flex" flexDirection="column" height="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1.25rem"
      >
        <Typography variant="h5">Update contact information</Typography>
        <Box display="flex">
          <SaveButton
            data-testid="save-btn"
            action={() => updateCustomer()}
            disabled={shouldDisableButton}
          />
          <CloseButton action={closeModal} />
        </Box>
      </Box>

      <Box style={{ padding: '0 15px' }}>
        {customerType === 'PERSON' ? (
          <>
            <TextField
              name="First Name"
              fullWidth
              variant="outlined"
              style={{ margin: '5px 0' }}
              label="First Name"
              disabled
              value={firstName}
            />
            <TextField
              name="Last Name"
              fullWidth
              variant="outlined"
              style={{ margin: '5px 0' }}
              label="Last Name"
              disabled
              value={lastName}
            />
          </>
        ) : (
          <TextField
            name="Company Name"
            fullWidth
            variant="outlined"
            style={{ margin: '5px 0' }}
            label="Company Name"
            disabled
            value={companyName}
          />
        )}

        <TextField
          name="mobile"
          fullWidth
          variant="outlined"
          style={{ margin: '5px 0' }}
          label="mobile"
          value={updatedMobile}
          onChange={event => setMobile(event.target.value)}
        />

        <TextField
          name="email"
          fullWidth
          variant="outlined"
          style={{ margin: '5px 0 15px' }}
          label="email"
          value={updatedEmail}
          onChange={event => setEmail(event.target.value)}
          error={shouldDisableButton}
          helperText={shouldDisableButton && 'Input is not valid'}
        />
      </Box>
    </Box>
  );
};

export default TafCustomerDetails;
