import {
  getCustomerData,
  requestPayment,
} from 'paymentIntegrations/adyen/requests/adyenRequests';
import {
  calculateSplitsAmounts,
  // encodeSignatureToBase64,
  offlineListener,
  onlineListener,
  parseQueryString,
} from 'paymentIntegrations/adyen/utils';
import { getCustomers } from 'services/ErplyAPI/customers';
import { float } from 'components/FieldTypes/formatters';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';

import { creationOfAdyenPatchscript } from '../printing';

/**
 * Requests a single payment to Adyen terminal and handles the reponse from the same terminal.
 * @param {Object} paymentData Data for the payment request.
 * @param {Object} hooks Functions to manipulate the card payment UI component.
 */
const adyenPayment = async (paymentData, hooks, splits) => {
  const {
    updateMessage,
    enableButtons,
    beforeDocSave,
    displayWarning,
    adyenConfig,
    printReceipt,
    employeeID,
  } = hooks;
  let customerTransactionID = null;
  let recurrentCustomerEmail = null;

  offlineListener(enableButtons, updateMessage);
  onlineListener(enableButtons, updateMessage);
  try {
    if (adyenConfig.adyenRecon === 1) {
      /** *** Get data from recurrent customer ****** */
      updateMessage(
        'Present card to obtain recurrent customer details and start payment.',
      );
      enableButtons();
      const decryptedCustomer = await getCustomerData(paymentData, adyenConfig);

      const dataRetrievalResult =
        decryptedCustomer.SaleToPOIResponse.CardAcquisitionResponse.Response
          .Result;
      if (dataRetrievalResult === 'Success') {
        /** *** Obtain transactionID and shopper email **** */
        customerTransactionID =
          decryptedCustomer.SaleToPOIResponse.CardAcquisitionResponse.POIData
            .POITransactionID.TransactionID;
        recurrentCustomerEmail = decodeURIComponent(
          parseQueryString(
            decryptedCustomer.SaleToPOIResponse.CardAcquisitionResponse.Response
              .AdditionalResponse,
          ).shopperEmail,
        );
      }

      /** *** Retrieve matching user from Erply API and set it as the current customer **** */
      if (recurrentCustomerEmail && recurrentCustomerEmail !== undefined) {
        const erplyCustomerResponse = await getCustomers({
          searchEmail: recurrentCustomerEmail,
        });
        let id;
        if (erplyCustomerResponse.length) {
          id = erplyCustomerResponse[0].id;
          if (id && id !== '' && id !== ' ') {
            adyenConfig.setCustomer({ data: erplyCustomerResponse[0] });
          }
        }
      }
    }

    /** *** Perform a payment transaction ****** */
    updateMessage(
      `Performing payment in amount of ${float(paymentData.amount)}`,
    );
    let amountsToPay;

    if (adyenConfig.enableMarketplace === 1) {
      amountsToPay = calculateSplitsAmounts(paymentData.amount, splits);
      if (!amountsToPay.length) {
        throw new Error('Missing Adyen marketplace configuration');
      }
    }

    // If the adyen marketplace has not been configured it would not be posible to perform a payment

    const payment = await requestPayment(
      paymentData,
      adyenConfig,
      customerTransactionID,
      amountsToPay,
      employeeID,
    );

    const {
      transactionStatus,
      cardType,
      statusMessage,
      cardNumber,
      referenceNumber,
      approvedAmount,
      additionalData,
      cardHolder,
      authCode,
    } = payment;

    if (transactionStatus === 'Success') {
      await beforeDocSave({
        key: paymentData.key,
        type: 'CARD',
        amount: approvedAmount,
        cardType,
        cardNumber,
        cardHolder,
        // signature: signature ? base64String : null,
        attributes: {
          refNo: referenceNumber,
          authCode,
        },
        paid: true,
      });

      if (adyenConfig.usePOSGeneralPrinter) {
        const parsedAdditionalData = JSON.parse(additionalData);
        const { receipts } = parsedAdditionalData;

        const receiptsPatchscript = creationOfAdyenPatchscript(receipts);
        printReceipt(receiptsPatchscript);
      }

      updateMessage('Payment successful');
      enableButtons();
    } else {
      let paymentStatusMessage = statusMessage;
      try {
        const parsedAdditionalData = JSON.parse(additionalData);
        const { responseMessage } = parsedAdditionalData;
        if (responseMessage && responseMessage.length) {
          paymentStatusMessage = responseMessage;
        }
      } catch {
        // eslint-disable-next-line no-console
        console.warn(
          'Adyen response does not have additional data. Returning statusMessage',
        );
      }
      updateMessage(`Current payment status: ${paymentStatusMessage}`);

      if (statusMessage === 'Busy') {
        enableButtons([
          FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
          FUNC_BUTTONS.CHECK_STATUS,
          FUNC_BUTTONS.CANCEL,
        ]);
        return false;
      }
      enableButtons([
        FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
        FUNC_BUTTONS.RETRY,
        FUNC_BUTTONS.CLOSE,
      ]);
      return false;
    }
  } catch (error) {
    enableButtons([FUNC_BUTTONS.CHECK_STATUS]);
    updateMessage(error.message);
    displayWarning(error.message);
    return false;
  }
  window.removeEventListener('offline', offlineListener, true);
  window.removeEventListener('offline', onlineListener, true);
  return true;
};

export default adyenPayment;
