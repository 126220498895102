import React, {
  FC,
  useContext,
  useEffect,
  useCallback,
  ComponentProps,
} from 'react';
import * as R from 'ramda';
import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Customer } from 'types/Customer';

import {
  DisabledOn,
  FormFieldConfiguration,
} from '../../../Settings/views/Customers/types';

export const FormContext = React.createContext<{
  config: Record<string, FormFieldConfiguration>;
  setFieldIsValid: (
    fieldName: keyof Customer | 'senior' | 'suspended' | 'language',
    isValid: boolean,
  ) => void;
  isEditingCustomer: boolean;
}>({ config: {}, setFieldIsValid: () => null, isEditingCustomer: false });

export const ConfigurableForm: FC<{
  config: Record<string, FormFieldConfiguration>;
  setFieldIsValid: (
    fieldName: keyof Customer | 'senior' | 'suspended' | 'language',
    isValid: boolean,
  ) => void;
  isEditingCustomer: boolean;
}> = ({ children, config, setFieldIsValid, isEditingCustomer }) => {
  return (
    <FormContext.Provider
      value={{ config, setFieldIsValid, isEditingCustomer }}
    >
      {children}
    </FormContext.Provider>
  );
};

// adjust type if needed
type Wrapper = typeof Grid | typeof Box;
type WrapperProps<TWrapper extends Wrapper> = ComponentProps<TWrapper>;

export function Field<TWrapper extends Wrapper>({
  name,
  wrapper: Wrapper,
  children,
  wrapperProps,
}: {
  name: keyof Customer | 'senior' | 'suspended' | 'language';
  wrapper?: TWrapper;
  wrapperProps?: WrapperProps<TWrapper>;
  children: React.ReactNode;
}) {
  const { config, setFieldIsValid, isEditingCustomer } = useContext(
    FormContext,
  );
  const {
    [name]: { enabled, required, label, disabledOn = DisabledOn.NEVER } = {
      enabled: true,
      required: false,
      label: '',
      disabledOn: DisabledOn.NEVER,
    },
  } = config;

  const child = React.Children.toArray(children)?.[0];

  const isNonPrimitive = (arg: typeof child): arg is React.ReactElement =>
    !['string', 'number'].includes(typeof arg);

  const disabledPropValue: boolean | null = isNonPrimitive(child)
    ? child.props.disabled
    : null;

  const disabled =
    disabledPropValue ??
    {
      [DisabledOn.NEVER]: false,
      [DisabledOn.ALWAYS]: true,
      [DisabledOn.ON_EDIT]: isEditingCustomer,
    }[disabledOn];

  const { t } = useTranslation('createCustomer', { keyPrefix: 'errorText' });

  // allow custom validation to be passed on from config
  const validate = useCallback(
    child => {
      const { value = '', error, helperText } = child?.props ?? {};
      const check = typeof value === 'string' ? !value.trim() : !value;
      if (required && check) return helperText || t('requiredField');
      if (error) return helperText || t('invalidValue');
      return '';
    },
    [required, t],
  );

  const isFieldValid = validate(child);

  useEffect(() => {
    if (enabled) {
      setFieldIsValid(name, !isFieldValid);
    }
  }, [enabled, name, setFieldIsValid, isFieldValid]);

  if (!enabled) return null;

  function updateProps(pathToProps: string[]) {
    return R.pipe(
      R.assocPath([...pathToProps, 'required'], required),
      R.assocPath([...pathToProps, 'disabled'], disabled),
      R.when(() => !!label, R.assocPath([...pathToProps, 'label'], label)),
      R.when(
        validate,
        R.pipe(R.assocPath([...pathToProps, 'error'], true), child =>
          R.assocPath([...pathToProps, 'helperText'], validate(child), child),
        ),
      ),
    );
  }

  return (
    // @ts-ignore - was not able to figure out why ts yells here in short amount of time
    <Wrapper {...wrapperProps}>
      {R.map(
        R.when(
          isNonPrimitive,
          R.ifElse(
            R.path(['props', 'inputProps']),
            // workaround for autocomplete component
            R.pipe(
              R.assocPath(['props', 'disabled'], disabled),
              updateProps(['props', 'inputProps']),
            ),
            updateProps(['props']),
          ),
        ),
      )(React.Children.toArray(children))}
    </Wrapper>
  );
}
