import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { getSettings } from 'reducers/configs/settings';
import { SaveButton } from 'components/UIElements/UIButton';
import {
  commitTempSettings,
  saveTempSetting,
  dropTempSettings,
} from 'actions/configs';
import { Check } from 'containers/Forms/Settings/components/CtxInputs';
import { Section } from 'containers/Forms/Settings/components/Inputs';

import * as CtxInputs from '../../components/CtxInputs';

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsAgeVerification');
  const [changed, setChanged] = useState({});

  const onClose = () => {
    dispatch(dropTempSettings(Object.keys(changed)));
    dispatch(previousModalPage());
  };

  const onSave = () =>
    dispatch(commitTempSettings(Object.keys(changed))).then(onClose);

  const currentSettings = useSelector(getSettings);
  const current = useMemo(() => {
    const withOverrides = { ...currentSettings };
    return withOverrides;
  }, [currentSettings]);

  const onChange = useCallback(
    (key, value) => {
      if (current[key] === value) {
        setChanged(state => {
          dispatch(dropTempSettings([key]));
          const newState = { ...state };
          delete newState[key];
          return newState;
        });
      } else {
        dispatch(saveTempSetting(key, value));
        setChanged(state => ({ ...state, [key]: value }));
      }
      return undefined;
    },
    [current],
  );

  const values = {
    ...current,
    ...changed,
  };

  return (
    <>
      <Modal.Header>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('title')}
        </span>

        <span style={{ flexGrow: 1 }} />
        <SaveButton action={onSave} variant="POS" />
        <CloseButton action={onClose} />
      </Modal.Header>
      {/* prettier-ignore */}
      <Modal.Body>

        <CtxInputs.Ctx.Provider value={{ values, onChange }}>
        <div style={{ overflow: 'auto', maxHeight: '85vh' }}>
          <Section title={t('ageVerification')}>
          <Check
            name="skip_age_verification"
            title={t('skipAgeVerification')}
             />
          </Section>

         
        </div>
        </CtxInputs.Ctx.Provider>
      </Modal.Body>
    </>
  );
};
