import { mapBirthday } from './common';

const mapCustomer = ({
  addedTime,
  birthdayDay,
  birthdayMonth,
  birthdayYear,
  changedTime,
  customerCardNumber,
  customerId,
  email,
  emailConsentState,
  companyName,
  firstName,
  groupID,
  lastName,
  mobilePhone,
  phone,
  suspended,
}) => ({
  // TODO lang:language mapping
  customerID: customerId,
  companyName,
  birthday: mapBirthday(birthdayYear, birthdayMonth, birthdayDay),
  customerCardNumber,
  added: addedTime,
  customerType: firstName || lastName ? 'PERSON' : 'COMPANY',
  phone,
  mobile: mobilePhone,
  groupID,
  fullName: firstName || lastName ? `${firstName}, ${lastName}` : companyName,
  firstName,
  lastName,
  lastModified: changedTime,
  email,
});
const mapCustomers = customers => {
  return customers.map(customer => mapCustomer(customer));
};

export default response => mapCustomers(response.data?.result?.records ?? []);
