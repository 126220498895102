import { combineReducers } from 'redux';

import gridDisplay from './gridDisplay';
import productEditMode from './productEditMode';
import manualViewMode from './manualViewMode';
import manualEditMode from './manualEditMode';
import customerTooltip from './customerTooltip';
import theme from './theme';

export default combineReducers({
  gridDisplay,
  productEditMode,
  manualViewMode,
  customerTooltip,
  manualEditMode,
  theme,
});
