import { Transaction } from './types';

export const pluginID = 'Vii-GiftCards';

export const modals = {
  balance: `${pluginID} - Balance`,
  history: `${pluginID} - History`,
};

export const sampleHistoryData: Transaction = {
  Amount: ['12.00'],
  Balance: ['0.00'],
  Date: ['12/07/2022'],
  ReceiptNumber: ['111'],
  StoreName: ['Tere'],
  TransactionType: ['Cancelled'],
  LockoutEndTime: ['12:33'],
};

export const statusMapper = {
  0: 'Not Issued',
  1: 'Active',
  2: 'Expired',
  3: 'Suspended',
  4: 'Cancelled',
};

export const noResponse = 'Failed to get response from Vii.';
