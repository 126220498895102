/* eslint-disable react/no-children-prop */
import React from 'react';

import ListItem from '../ListItem';

import { typeIntoField, deleteFromField, preventFocus } from './util';

const ButtonItem = ({ style, ...props }) => (
  <ListItem
    style={{
      fontSize: 24,
      minWidth: 80,
      minHeight: 76,
      height: 'inherit',
      width: 'inherit',
      ...style,
    }}
    onFocus={preventFocus}
    {...props}
  />
);

/**
 * A Numberpad/Keypad that allows touch/mouse typing of numbers
 *
 * Has no callbacks - uses magic to affect whichever input is selected at the time
 */
export default () => {
  const area = n => ({
    gridArea: n,
    alignSelf: 'stretch',
    justifySelf: 'stretch',
  });

  return (
    <div
      style={{
        display: 'inline-grid',
        gridTemplateAreas: `'clr  bcs  bcs' 'n1  n2  n3 '  ' n4  n5  n6 ' ' n7  n8  n9 ' ' dec  n0  sub '`,
      }}
    >
      <ButtonItem
        children="CLR"
        variant="grid_green"
        data-testid="clear"
        data-test-key="clear"
        style={area('clr')}
        action={() => {
          deleteFromField(document.activeElement, {
            left: Infinity,
            right: Infinity,
          });
        }}
      />
      <ButtonItem
        children="⌫"
        variant="grid_green"
        data-testid="remove"
        data-test-key="remove"
        style={area('bcs')}
        action={() => {
          deleteFromField(document.activeElement, { left: 1 });
        }}
      />
      <ButtonItem
        children="."
        variant="grid_dark"
        data-testid="decimal"
        data-test-key="."
        style={{ ...area('dec'), fontSize: 24 }}
        action={() => {
          typeIntoField(document.activeElement, { left: '.' });
        }}
      />
      {/* This one's hidden because it was useless on openDay */}
      <ButtonItem
        variant="grid_green"
        style={{ ...area('sub'), fontSize: 48, visibility: 'hidden' }}
        action={() => {
          typeIntoField(document.activeElement, { prepend: '-' });
        }}
      >
        <span
          style={{
            display: 'inline-block',
            position: 'absolute',
            top: 'calc(50% - 1px)',
            left: 'calc(50% - 6px)',
            height: 2,
            width: 12,
            backgroundColor: 'black',
            fontSize: 1,
          }}
        >
          -
        </span>
      </ButtonItem>

      {Array(10)
        .fill(0)
        .map((_, i) => (
          <ButtonItem
            /* eslint-disable-next-line react/no-array-index-key */
            key={i}
            children={i}
            variant="grid_light"
            data-test-key={i}
            data-testid="numpad-button"
            style={area(`n${i}`)}
            onFocus={e => {
              // e.nativeEvent.preventDefault();
              e.nativeEvent.relatedTarget.focus();
            }}
            action={() => {
              typeIntoField(document.activeElement, { left: i.toString() });
            }}
          />
        ))}
    </div>
  );
};
