import * as R from 'ramda';

import { calculateFairSplits } from 'plugins/hipPocket/payForInvoices/fairSplit';

/**
 * Split a single savePayment request into multiple, based on a provided mapping
 * of document ids to their remaining unpaid amounts
 *
 * Separate method to split a single payment, because once refacto comes out,
 * payments will have to be processed one at a time anyway
 */
export const splitPayment = (
  savePayment: { requestName: 'savePayment'; sum: number },
  remainingToPay: { [id: string]: number },
) => {
  const keys = Object.keys(remainingToPay);
  const values = Object.values(remainingToPay);
  const splits = calculateFairSplits(Number(savePayment.sum), values);

  const newRemainingToPay = { ...remainingToPay };
  splits.forEach((s, i) => {
    newRemainingToPay[keys[i]] -= s;
  });
  const requests = splits.map((s, i) => {
    const scale = s / savePayment.sum;
    const newRequest = R.evolve({
      documentID: R.always(keys[i]),
      sum: R.multiply(scale),
      cashPaid: R.multiply(scale),
      cashChange: R.multiply(scale),
    })(savePayment);
    // Recalculate cashChange in case of rounding errors
    newRequest.cashChange = newRequest.cashPaid - newRequest.sum;
    return newRequest;
  });
  return [requests, newRemainingToPay] as const;
};
