import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getProductsUniversal } from 'actions/productsDB';
import {
  RTableRow,
  TItem,
} from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { getCompany } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getWarehouseById } from 'reducers/warehouses';
import {
  getSalesDocumentActualReportsDataset,
  getSalesDocuments,
} from 'services/ErplyAPI';
import { getCurrencyByCode } from 'reducers/configs/currency';
import { getCurrencyCode } from 'reducers/configs/settings';
import { RootState } from 'reducers';

import { getCurrency } from './redux';

export const doEstonianBankPrintout = (salesDocumentID: number) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  try {
    const [salesDocument] = await getSalesDocuments({
      id: salesDocumentID,
    });
    const [arData] = await getSalesDocumentActualReportsDataset({
      salesDocumentID,
    });

    const currencyCode = getCurrencyCode(getState());
    const defaultCurrency = getCurrencyByCode(currencyCode)(getState());

    const chosenCurrency =
      getCurrency(getState()) ??
      getCurrencyByCode(salesDocument.currencyCode)(getState());
    const company = getCompany(getState());
    const pointOfSale = getSelectedPos(getState());
    const warehouse = getWarehouseById(pointOfSale.warehouseID)(getState());

    const { rows } = salesDocument;

    const { products } = await dispatch(
      getProductsUniversal(
        {
          productIDs: rows.map(row => Number(row.productID)),
        },
        { localFirst: true },
      ),
    );
    const isRegularGiftCardAndTaxFree = products.some(
      product => product.isRegularGiftCard && Number(product.vatrate) === 0,
    );

    if (isRegularGiftCardAndTaxFree) {
      const {
        companyName,
        companyPhone,
        companyAddressStreetAndBuilding,
        companyAddressTownOrCity,
        companyEmail,
        documentNumber,
        documentTime,
        documentDate,
        totalAmount,
        payments: [{ sum: paymentSum }],
        currencySymbol,
        total,
      } = arData;

      const sum = Math.abs(
        Number(paymentSum.replace(',', '.').replace(' ', '')),
      );

      const productRows = (r: any) => {
        const codeRows = [
          {
            type: 'normal',
            cells: [
              { align: 'left', pieces: [{ text: r.productName }] },
              { align: 'right', pieces: [{ text: r.finalPriceWithVAT }] },
              {
                align: 'right',
                pieces: [{ text: Math.abs(Number(r.amount)) }],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: Math.abs(
                      Number(r.rowTotal.replace(',', '.').replace(' ', '')),
                    ).toFixed(2),
                  },
                ],
              },
            ],
          },
        ] as RTableRow[];

        return codeRows;
      };

      return [
        {
          align: 'center',
          pieces: [
            {
              meta: {
                bold: false,
                size: 3,
                underline: false,
              },
              text: companyName,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: `${companyAddressStreetAndBuilding}, ${companyAddressTownOrCity}`,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              text: warehouse.name,
            },
            {
              text: ' - ',
            },
            {
              text: pointOfSale.name,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: 'Reg. nr',
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: ': ',
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: company.code,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: 'Tel. ',
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: `${companyPhone}, e-post: ${companyEmail}`,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              text: 'Arve nr: ',
              meta: {
                bold: true,
                underline: true,
                size: 2,
              },
            },
            {
              text: documentNumber,
              meta: {
                bold: false,
                underline: false,
              },
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              text: 'Kuupäev: ',
              meta: {
                bold: false,
                underline: false,
              },
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: `${documentDate} `,
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: documentTime,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: false,
                size: 1,
                underline: false,
              },
              text: ' ',
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: '',
            },
          ],
          type: 'text',
        },
        {
          type: 'table',
          columns: [
            { baseWidth: 0, weight: 3 },
            { baseWidth: 0, weight: 1 },
            { baseWidth: 6, weight: 0 },
            { baseWidth: 10, weight: 0 },
          ],
          rows: [
            {
              type: 'header',
              cells: [
                { align: 'left', pieces: [{ text: 'Toode' }] },
                { align: 'right', pieces: [{ text: 'Hind' }] },
                { align: 'right', pieces: [{ text: 'Arv' }] },
                { align: 'right', pieces: [{ text: 'Kokku' }] },
              ],
            },
            {
              type: 'header',
              cells: ['colspan', 'colspan', 'colspan'],
            },
            ...arData.documentRows.flatMap(productRows),
            {
              cells: [
                { align: 'left', pieces: [] },
                { align: 'left', pieces: [] },
                { align: 'left', pieces: [] },
                { align: 'left', pieces: [] },
              ],
              type: 'sectionStart',
            },
          ],
        },
        {
          align: 'right',
          type: 'text',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: `Kokku ${chosenCurrency?.code}: `,
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: Math.abs(total),
            },
          ],
        },
        {
          align: 'left',
          pieces: [],
          type: 'text',
        },
        {
          align: 'left',
          type: 'text',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: 'Tooteid kokku: ',
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: Math.abs(Number(totalAmount)).toFixed(),
            },
          ],
        },
        {
          align: 'left',
          pieces: [],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: true,
                size: 2,
                underline: false,
              },
              text: 'Kliendile makstud: ',
            },
            {
              meta: {
                bold: true,
                size: 1,
                underline: false,
              },
              text: `${sum} ${defaultCurrency?.code ?? ''}`,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: true,
                size: 2,
                underline: false,
              },
              text: 'Sularahas',
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: 'Klient andis: ',
            },
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: `${Math.abs(total).toString()} ${currencySymbol}`,
            },
          ],
          type: 'text',
        },
        {
          align: 'left',
          pieces: [
            {
              meta: {
                bold: false,
                underline: false,
              },
              text: `1 ${defaultCurrency?.code ?? ''} = ${Number(
                chosenCurrency?.rate,
              ) / Number(defaultCurrency?.rate ?? 1)} ${chosenCurrency?.code}`,
            },
          ],
          type: 'text',
        },
        {
          align: 'center',
          data: documentNumber,
          format: 'Code39FullASCII',
          type: 'barcode',
        },
        {
          align: 'center',
          pieces: [
            {
              meta: {
                bold: false,
                size: 1,
                underline: false,
              },
              text: 'Külastage meid jälle!',
            },
          ],
          type: 'text',
        },
      ] as TItem[];
    }
  } catch (error) {
    console.error('Failed to create printout', error);
  }
};
