export const pluginPrefix = 'PLUGIN:ENV_FEE:';
export const SHOULD_ADD_ENV_FEE = `${pluginPrefix}SHOULD_ADD_ENV_FEE`;
export const SET_ENV_FEE_AMOUNT = `${pluginPrefix}SET_ENV_FEE_AMOUNT`;
export const SET_INITIAL_ENV_FEE = `${pluginPrefix}SET_INITIAL_ENV_FEE`;
export const SET_ENV_FEE_RATE = `${pluginPrefix}SET_ENV_FEE_RATE`;
export const STORE_ENV_PRODUCT = `${pluginPrefix}STORE_ENV_PRODUCT`;
export const HAS_REMOVED_ITEM = `${pluginPrefix}HAS_REMOVED_ITEM`;
export const PRICE_HAS_BEEN_UPDATED = `${pluginPrefix}PRICE_HAS_BEEN_UPDATED`;
export const RETURN_SAME_AMOUNT_OF_ITEMS_BOUGHT = `${pluginPrefix}RETURN_SAME_AMOUNT_OF_ITEMS_BOUGHT`;
export const SHOULD_ADD_ADMISSION_TAX = `${pluginPrefix}SHOULD_ADD_ADMISSION_TAX`;
export const SET_ADMISSION_TAX_PRODUCT = `${pluginPrefix}SET_ADMISSION_TAX_PRODUCTS`;
export const ADMISSION_FEE_NET_PRICE = `${pluginPrefix}ADMISSION_FEE_NET_PRICE`;
export const ADMISSION_FEE_TAX_AMOUNT = `${pluginPrefix}ADMISSION_FEE_TAX_AMOUNT`;
export const CALCULATING_ENV_OR_ADM_TAX_FEES = `${pluginPrefix}CALCULATING_ENV_OR_ADM_TAX_FEES`;

export interface EnvFeeState {
  shouldAddEnvFee: boolean;
  envFeeAmount: number;
  returnAllBoughtItems: boolean;
  hasRemovedItem: boolean;
  priceHasBeenUpdated: boolean;
  shouldAddAdmissionTax: boolean;
  admissionFeeNetPrice: any;
  admissionFeeTaxAmount: number;
  initialEnvFeeAmount: number;
  envFeeRate: number;
  envFeeProduct: null | Record<string, any>;
  admissionFeeProduct: null | Record<string, any>;
  calculatingEnvOrAdmTaxFees: boolean;
}

export interface PNPRateObjects {
  warehouseEnvFeeRateObject: string | undefined;
  customerGroupRateObject: string | undefined;
  customerRateObject: string | undefined;
}
