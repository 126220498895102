import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import InputField from 'components/FieldTypes/InputField';
import { softLogout } from 'actions/Login';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import * as api from 'services/ErplyAPI/api';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import { getUserLoggedIn, getClientCode } from 'reducers/Login';
import { sleep } from 'utils';
import { addWarning } from 'actions/Error';
import Icon from 'components/Icon';

const getErrorForPin = pin => {
  if (!/^\d+$/.test(pin)) {
    return i18next.t('validation:changePin.numeric');
  }
  if (pin.length === 0) {
    return i18next.t('validation:generic.required');
  }
  if (pin.length !== 6) {
    return i18next.t('validation:generic.exactLength', { exact: 6 });
  }
  return false;
};
const getErrorForPassword = password => {
  if (password.length === 0) {
    return i18next.t('validation:generic.required');
  }
  if (password.length < 8) {
    return i18next.t('validation:generic.minLength', { min: 8 });
  }
  return false;
};

export default () => {
  const [pswd, setPswd] = useState('');
  const [newPin1, setNewPin1] = useState('');
  const [newPin2, setNewPin2] = useState('');
  const [errorOverride, setErrorOverride] = useState(false);
  const [successOverride, setSuccessOverride] = useState(false);

  const { t } = useTranslation('changePin');
  const passwordLength = getErrorForPassword(pswd);
  const pinLength = getErrorForPin(newPin1);
  const { userID, userName } = useSelector(getUserLoggedIn);
  const clientCode = useSelector(getClientCode);
  const pinMismatch =
    getErrorForPin(newPin2) ||
    (newPin1 !== newPin2 && t('validation:changePin.noMatch'));

  const dispatch = useDispatch();
  // softLogout for the user to be able to use PIN straight away
  const doLogout = useCallback(() => dispatch(softLogout()), [dispatch]);
  const closePopup = useCallback(() => dispatch(previousModalPage()), [
    dispatch,
  ]);

  useEffect(() => {
    setErrorOverride(false);
  }, [pswd, newPin1, newPin2]);

  const trySave = async () => {
    if (passwordLength || pinLength || pinMismatch) {
      return;
    }
    try {
      await api.loginWithPass({
        clientCode,
        username: userName,
        password: pswd,
      });
      await api.changePin({ userID, cardCode: newPin1 });
      await setSuccessOverride('You will be logged out in 2 seconds');
      await sleep(2);
      closePopup();
      doLogout();
      dispatch(addWarning('PIN was changed. Please login again'), {
        selfDismiss: 2000,
      });
    } catch (e) {
      setErrorOverride(e.message);
    }
  };

  const anyError = passwordLength || pinLength || pinMismatch || errorOverride;
  return (
    <>
      <Modal.Header>
        <Modal.Title style={{ fontSize: '24px', fontWeight: 700 }}>
          {t('title')}
        </Modal.Title>
        <span style={{ flexGrow: 1 }} />
        <SaveButton action={trySave} disabled={anyError} />
        <CloseButton action={closePopup} />
      </Modal.Header>
      <Modal.Body>
        <InputField
          type="password"
          size="lg"
          className={styles.changePassInput}
          title={t('inputs.current')}
          value={pswd}
          onChange={e => setPswd(e.target.value)}
          errorText={passwordLength}
        />
        <InputField
          type="password"
          size="lg"
          className={styles.changePassInput}
          title={t('inputs.new1')}
          value={newPin1}
          onChange={e => setNewPin1(e.target.value)}
          errorText={pinLength}
        />
        <InputField
          type="password"
          size="lg"
          className={styles.changePassInput}
          title={t('inputs.new2')}
          value={newPin2}
          onChange={e => setNewPin2(e.target.value)}
          errorText={pinMismatch}
        />
        {errorOverride ? (
          <div style={{ color: 'red', minHeight: '1em' }}>{errorOverride}</div>
        ) : null}
        {successOverride ? (
          <div style={{ color: 'green', minHeight: '1em' }}>
            {successOverride}
          </div>
        ) : null}

        <br />
        <p>
          <Icon name="icon_info" style={{ fontSize: 12 }} />
          {t('logoutNote')}
        </p>
      </Modal.Body>
    </>
  );
};
