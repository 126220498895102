import React, { createContext, useContext } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { getWarrantyGroupId } from 'plugins/onoff/warrantyPlugin/selectors';
import useProducts from 'utils/hooks/useProducts';
import { getSelectedOrder } from 'reducers/ShoppingCart';
import { useAutocloseModalPage } from 'plugins/onoff/warrantyPlugin/hooks';
import { modalPages } from 'constants/modalPage';

const IsWarranty = createContext(false);

/**
 * For warranty products in the cart, disable all interaction except the delete button
 */
export const preventWarrantyEdits: Partial<PosPlugin> = {
  // Provide to children whether this row is a warranty
  UICustomTableBillRow: ({ children, order }) => {
    const { productID } = order;
    const warrantyGroupId = useSelector(getWarrantyGroupId);
    const {
      products: [product],
    } = useProducts({ productID });
    const isWarranty = product?.groupID === warrantyGroupId;
    return (
      <IsWarranty.Provider value={isWarranty}>{children}</IsWarranty.Provider>
    );
  },

  // Hide buttons if warranty
  UICartProductEditShortcut: ({ children }) =>
    useContext(IsWarranty) ? (
      <span style={{ visibility: 'hidden' }}>{children}</span>
    ) : (
      children
    ),
  UICartDiscountShortcut: ({ children }) =>
    useContext(IsWarranty) ? (
      <span style={{ visibility: 'hidden' }}>{children}</span>
    ) : (
      children
    ),
  // Reenable button
  UIDeleteProductShortcut: ({ children }) =>
    React.Children.map(
      children,
      R.assocPath(['props', 'style', 'pointerEvents'], 'auto'),
    ),

  /** Close ProductOrderEdit view if any of the related products are warranty products */
  UICustomProductCard: ({ children }) => {
    // TODO: When props from !2011 are merged in, get the order from the props instead
    const { productID } = useSelector(getSelectedOrder) || {};
    const warrantyGroupId = useSelector(getWarrantyGroupId);
    const {
      products: [product],
    } = useProducts({ productID });

    const shouldClose = product?.groupID === warrantyGroupId;
    return useAutocloseModalPage(
      modalPages.ProductOrderEdit,
      children,
      shouldClose,
    );
  },
};
