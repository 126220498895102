import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import i18next from 'i18next';
import { Tab, Tabs } from 'react-bootstrap';
import { useSessionStorage } from 'react-use';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';
import { getSetting } from 'reducers/configs/settings';
import { saveSetting } from 'actions/configs';
import Loader from 'components/Loader';
import { round } from 'utils';
import { getEnabledPlugins, getPluginStatus } from 'reducers/Plugins';
import { disablePlugin, enablePlugin } from 'actions/Plugins';
import { ReceiptSchema } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema';
import SizeDebug from 'containers/Forms/Settings/views/Debug/components/SizeDebug';
import { semanticVersion } from 'external_data';
import { SoundEventConfiguration } from 'services/Audio/Sound';
import { modalPages } from 'constants/modalPage';

import styles from './Debug.module.scss';

export default () => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(previousModalPage());

  const theme = useSelector(getSetting('touchpos_use_dark_theme'));
  const flashTheme = async () => {
    dispatch(
      saveSetting({
        parameterName: 'touchpos_use_dark_theme',
        parameterValue: !theme,
      }),
    );
    dispatch(
      saveSetting({
        parameterName: 'touchpos_use_dark_theme',
        parameterValue: theme,
      }),
    );
  };
  return (
    <>
      <Modal.Header className={`header ${styles.header}`}>
        <span>Debug options</span>

        <span style={{ flexGrow: 1 }} />
        <UIButton
          text="Changes are saved automatically"
          disabled
          variant="POS"
        />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body className={styles.debugSettings}>
        {/* prettier-ignore */}
        <Card.Body>
          <h3>Debug</h3>

          <LocalStorageBoolean
            name="visibleconsole:paymentIntegration/wrapper"
            title="Show debug output for wrapper integration"
          />
          <p>
            Logs all messages sent between the POS and the wrapper to the screen
            <br />
            Double clicking (-tapping) anywhere on the page clears the screen
          </p>
          <LocalStorageBoolean
            name="Debug: zReportComparison"
            title="Show a comparison of BO and patchscript zReports in zreport menu"
          />
        </Card.Body>
        <Card.Body>
          <h3>Translations</h3>

          <LocalStorageBoolean
            name="Debug: translationHighlight"
            title="Enable translations highlighting"
            refresh={() => {
              const original = i18next.language;
              i18next
                .changeLanguage('tmp')
                .then(i18next.changeLanguage(original));
            }}
          />
          <p>
            Wraps all translated strings with ::Translated:: or{' '}
            {'{{missing translation}}'}
          </p>
          <LocalStorageBoolean
            name="Debug: translationBraille"
            title="Enable translations debug mode"
            value={true}
          />
          <p>
            Pressing pause/break toggles through the following debug languages:
            <ul>
              <li>None:</li>
              <li>Braille: easier to spot untranslated text</li>
              <li>Key: shows which translation is used in which place</li>
            </ul>
          </p>
          <div>
            <a
              target="_top"
              href={`${process.env.PUBLIC_URL}/brazil_language_pack_${semanticVersion}.zip`}
            >
              Download all language packs
            </a>
          </div>
        </Card.Body>

        <Card.Body>
          <SizeDebug />
        </Card.Body>

        <Card.Body>
          <SoundEventConfiguration />
        </Card.Body>

        <Card.Body>
          <h3>User Interface</h3>
          <Deprecated hide={true}>
            <LocalStorageBoolean
              name="Debug: forceWide"
              title="Force page to render in wide mode regardless of screen size"
              refresh={() => window.dispatchEvent(new Event('resize'))}
              value={false}
            />
          </Deprecated>
          <Zoom />
          <SessionStorageBoolean title="Enable debug views" name="debug" />
        </Card.Body>
        {/* No debug settings are available at the moment */}
        {/* <Section title="Integrations"> */}
        {/* </Section> */}
        <Deprecated hide={true}>
          <Card.Body>
            <h3>Hardware integration simulations</h3>
            <LocalStorageBoolean
              name="Debug: mockScale"
              title="Scale MS - Simulate scale input with perlin noise"
            />
            <LocalStorageBoolean
              name="Debug: mockCustomerDisplay"
              title="Customer display MS - Simulate customer display using a popup"
            />
            <LocalStorageBoolean
              name="Debug: mockPrinter"
              title="Printer MS - Simulate printing receipts by showing them on screen"
            />
          </Card.Body>
        </Deprecated>
        <Card.Body>
          <ReceiptSchemae />
        </Card.Body>
      </Modal.Body>
    </>
  );
};

const Deprecated = ({ children, hide = false }) => {
  if (hide) return null;
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          pointerEvents: 'none',
          color: 'red',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: 'rgba(0,0,0,0.7)',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>Deprecated:</div>
        <div style={{ fontSize: '0.8em' }}>
          <div>
            This option is marked for deprecation and will soon be removed
          </div>
          <div>
            If you are still using this option please let us know immediately
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

const ReceiptSchemae = () => {
  const options = [
    'salesReceipt',
    'giftReceipt',
    'zReport',
    'coupon',
    'giftCard',
  ];
  return (
    <>
      <h3>PatchScript templates for printing</h3>
      <Tabs defaultActiveKey={options[0]}>
        {options.map(opt => (
          <Tab eventKey={opt} key={opt} title={opt}>
            <ReceiptSchema receiptType={opt} />
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

const Zoom = () => {
  const [n, setN] = useState(
    Number(localStorage.getItem('Debug: zoom') ?? '1'),
  );
  const dispatch = useDispatch();
  return (
    <>
      <label>ZOOM level: {round(n, 2)}</label>
      <br />
      <input
        style={{ width: '100%' }}
        type="range"
        min={0.5}
        max={2}
        step={0.05}
        value={n}
        onChange={e => setN(e.target.value)}
      />
      <br />
      <button
        onClick={() => {
          localStorage.setItem('Debug: zoom', n);
          dispatch({ type: 'noop', payload: undefined });
        }}
      >
        Apply
      </button>
    </>
  );
};
const LocalStorageBoolean = ({ name, ...rest }) => {
  // For backwards compat, manually implemented setter to remove localstorage
  // Ideally code using this would know to check for 'false'
  const [_, setState] = useState(0);
  const value = localStorage.getItem(name);
  const deletingSetValue = newValue => {
    if (newValue) {
      localStorage.setItem(name, newValue);
    } else {
      localStorage.removeItem(name);
    }
    setState(i => i + 1); // Rerender
  };
  return <StateBoolean state={[value, deletingSetValue]} {...rest} />;
};
const SessionStorageBoolean = ({ name, ...rest }) => {
  const [value, setValue] = useSessionStorage(name);
  return <StateBoolean state={[value, setValue]} {...rest} />;
};
const StateBoolean = ({
  state: [value, setValue],
  title,
  value: forcedValue,
  refresh,
}) => (
  <InputField
    size="lg"
    onChange={e => {
      setValue(!value);
      if (refresh) refresh();
    }}
    value={forcedValue === undefined ? value : forcedValue}
    type="checkbox"
    disabled={forcedValue !== undefined}
  >
    {title}
  </InputField>
);

/** @param {PosPlugin} plugin */
const PluginRow = ({ plugin }) => {
  const [loading, setLoading] = useState(false);

  const enabled = useSelector(getEnabledPlugins);
  const dispatch = useDispatch();
  const isEnabled = plugin => enabled.some(({ plugin: p }) => p === plugin);
  useEffect(() => {
    if (!loading) return;
    if (isEnabled(loading)) {
      dispatch(disablePlugin(loading)).then(() => setLoading(null));
    } else {
      dispatch(enablePlugin(loading)).then(() => setLoading(null));
    }
  }, [loading]);

  const toggle = plugin => () => {
    if (loading) return;
    setLoading(plugin);
  };

  const status = useSelector(getPluginStatus(plugin.id));
  return (
    <tr>
      <td>
        {status.type}
        <br />
        {status.message}
      </td>
      <td>
        <UIButton
          style={{
            visibility:
              plugin.ComponentConfiguration ||
              plugin.ComponentConfigurationByLevel
                ? 'visible'
                : 'hidden',
          }}
          text="Configure plugin"
          action={() =>
            dispatch(
              openModalPage({
                component: modalPages.ConfigurePlugin,
                props: { plugin },
              }),
            )
          }
        />
      </td>
      <td>
        <Loader block show={loading === plugin}>
          <InputField
            type="checkbox"
            onChange={toggle(plugin)}
            value={isEnabled(plugin)}
          >
            {plugin.name}
          </InputField>
        </Loader>
      </td>
    </tr>
  );
};
