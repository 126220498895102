import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';

import { ENDLESS_AISLE_CONSTANTS, pluginID } from '../constants';
import { Configuration } from '../types';
import { generateHeaders } from '../utils';

const ComponentHeader: Required<PosPlugin>['ComponentHeader'] = ({
  children,
}) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem(
    ENDLESS_AISLE_CONSTANTS.ACCESS_TOKEN,
  );
  const { clientSecret, password, username } = useSelector(
    getPluginConfiguration<Configuration>(pluginID),
  );

  const confExists = clientSecret && password && username;
  useEffect(() => {
    if (!accessToken && confExists) {
      dispatch(generateHeaders());
    }
  }, [dispatch, accessToken, confExists]);
  return children;
};

export default ComponentHeader;
