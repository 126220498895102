import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { Button, Typography } from '@material-ui/core';

import RenderFormItem from 'containers/Forms/Settings/components/FormFieldCtx';
import useErrorState, { translateError } from 'utils/hooks/useErrorState';
import { MONERIS } from 'paymentIntegrations/moneris/requests/monerisTransactions';
import { addError } from 'actions/Error';

import { Ctx } from '../../../../components/CtxInputs';
import TestButton from '../testButton';

import { FormShapeRecord, monerisFields } from './formShapes';

const MonerisSettings = () => {
  const dispatch = useDispatch();
  const { t: rawT } = useTranslation('settingsPayment');
  const t: typeof rawT = (text, ...rest) => {
    return rawT(`integrations.moneris.${text}`, ...rest);
  };
  const superContext = useContext(Ctx);
  const {
    configuration,
    setEnableUpdate,
    initialIntegrationState,
  } = superContext.values;
  const onChange = (key, value) => {
    if (key === 'DeviceTimeout' && Number(value)) {
      superContext.onChange('configuration', {
        ...configuration,
        [key]: Number(value),
      });
    } else {
      superContext.onChange('configuration', {
        ...configuration,
        [key]: value,
      });
    }
  };
  const activateTerminal = () => {
    if (!configuration.TerminalID) {
      dispatch(addError(t('activateTerminal.terminalIdMissing')));
      return;
    }
    if (!configuration.MerchantID) {
      dispatch(addError(t('activateTerminal.merchantIdMissing')));
      return;
    }
    MONERIS.activateTerminal({
      TerminalID: configuration.TerminalID,
      MerchantID: configuration.MerchantID,
    });
  };

  const activationFields: Record<string, FormShapeRecord> = R.pick([
    'TerminalID',
    'MerchantID',
  ])(monerisFields);
  const configFields: Record<string, FormShapeRecord> = R.omit([
    'TerminalID',
    'MerchantID',
  ])(monerisFields);

  const { errors, hasErrors } = useErrorState(configuration, configFields);
  useEffect(() => setEnableUpdate(!hasErrors), [hasErrors, setEnableUpdate]);

  const configFieldsSaved = R.eqBy(
    R.pick(Object.values(configFields).map(v => v.id)),
    configuration ?? {},
    initialIntegrationState ?? {},
  );
  return (
    <div>
      <Ctx.Provider value={{ values: configuration, onChange }}>
        {Object.values(configFields).map(({ validate, title, ...rest }) => {
          if (rest.type === 'select' && rest.options?.length) {
            const updatedOptions = rest.options.map(opt => ({
              name: t(`options.${opt}`),
              value: opt,
            }));
            Object.assign(rest, { options: updatedOptions });
          }
          return (
            <RenderFormItem
              key={rest.id}
              title={t(rest.id)}
              error={translateError(errors[rest.id], t)}
              {...rest}
            />
          );
        })}
        <TestButton
          disabled={!configFieldsSaved}
          testAction={() =>
            MONERIS.ping().then(res =>
              res ? t('testConnection.ok') : t('testConnection.fail'),
            )
          }
          text={t('testConnection.button')}
          helperText={
            configFieldsSaved
              ? ''
              : t('testConnection.button', { context: 'needsSave' })
          }
        />
        <Typography variant="h4" style={{ marginTop: '1em' }}>
          {t('activateTerminal.title')}
        </Typography>
        {Object.values(activationFields).map(({ validate, title, ...rest }) => (
          <RenderFormItem
            key={rest.id}
            title={t(rest.id)}
            error={translateError(errors[rest.id], t)}
            {...rest}
          />
        ))}
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          onClick={activateTerminal}
        >
          {t('activateTerminal.button')}
        </Button>
      </Ctx.Provider>
    </div>
  );
};

export default MonerisSettings;
