import { TestConnectionResponse } from '../types';

import instance from './instance';

export default async function testConnection() {
  try {
    const response = await instance.get<TestConnectionResponse>(
      'testConnection',
    );
    return response;
  } catch (err) {
    throw new Error('Chase: /testConnection request failed', { cause: err });
  }
}
