// tslint:disable
/* eslint-disable */
/**
 * Reports API
 * Handles statistical data requests, either getting data for reports or getting data for graphs.
 *
 * OpenAPI spec version: v1.12.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url';
import { Configuration } from './configuration';
const portableFetch = window.fetch;

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration!: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface GeterrordescriptionsErrorOutput
 */
export interface GeterrordescriptionsErrorOutput {
    /**
     *
     * @type {GeterrordescriptionsStatus}
     * @memberof GeterrordescriptionsErrorOutput
     */
    status?: GeterrordescriptionsStatus;
}

/**
 *
 * @export
 * @interface GeterrordescriptionsOutput
 */
export interface GeterrordescriptionsOutput {
    /**
     *
     * @type {string}
     * @memberof GeterrordescriptionsOutput
     */
    data?: string;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GeterrordescriptionsOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GeterrordescriptionsOutputStatus
 */
export interface GeterrordescriptionsOutputStatus {
    /**
     *
     * @type {number}
     * @memberof GeterrordescriptionsOutputStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GeterrordescriptionsOutputStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GeterrordescriptionsOutputStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GeterrordescriptionsStatus
 */
export interface GeterrordescriptionsStatus {
    /**
     *
     * @type {number}
     * @memberof GeterrordescriptionsStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GeterrordescriptionsStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GeterrordescriptionsStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GetposdaysErrorOutput
 */
export interface GetposdaysErrorOutput {
    /**
     *
     * @type {GetposdaysStatus}
     * @memberof GetposdaysErrorOutput
     */
    status?: GetposdaysStatus;
}

/**
 *
 * @export
 * @interface GetposdaysOutput
 */
export interface GetposdaysOutput {
    /**
     *
     * @type {Array<PointofsalehelperRecord>}
     * @memberof GetposdaysOutput
     */
    data?: Array<PointofsalehelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GetposdaysOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GetposdaysStatus
 */
export interface GetposdaysStatus {
    /**
     *
     * @type {number}
     * @memberof GetposdaysStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GetposdaysStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GetposdaysStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GetsalesbyassociateErrorOutput
 */
export interface GetsalesbyassociateErrorOutput {
    /**
     *
     * @type {GetsalesbyassociateStatus}
     * @memberof GetsalesbyassociateErrorOutput
     */
    status?: GetsalesbyassociateStatus;
}

/**
 *
 * @export
 * @interface GetsalesbyassociateOutput
 */
export interface GetsalesbyassociateOutput {
    /**
     *
     * @type {Array<SalesassociatehelperRecord>}
     * @memberof GetsalesbyassociateOutput
     */
    data?: Array<SalesassociatehelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GetsalesbyassociateOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GetsalesbyassociateStatus
 */
export interface GetsalesbyassociateStatus {
    /**
     *
     * @type {number}
     * @memberof GetsalesbyassociateStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GetsalesbyassociateStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GetsalesbyassociateStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GetsalesrevenuedailyErrorOutput
 */
export interface GetsalesrevenuedailyErrorOutput {
    /**
     *
     * @type {GetsalesrevenuedailyStatus}
     * @memberof GetsalesrevenuedailyErrorOutput
     */
    status?: GetsalesrevenuedailyStatus;
}

/**
 *
 * @export
 * @interface GetsalesrevenuedailyOutput
 */
export interface GetsalesrevenuedailyOutput {
    /**
     *
     * @type {Array<SalesrevenuehelperRecord>}
     * @memberof GetsalesrevenuedailyOutput
     */
    data?: Array<SalesrevenuehelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GetsalesrevenuedailyOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GetsalesrevenuedailyStatus
 */
export interface GetsalesrevenuedailyStatus {
    /**
     *
     * @type {number}
     * @memberof GetsalesrevenuedailyStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GetsalesrevenuedailyStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GetsalesrevenuedailyStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GetsalesrevenuemonthlyErrorOutput
 */
export interface GetsalesrevenuemonthlyErrorOutput {
    /**
     *
     * @type {GetsalesrevenuemonthlyStatus}
     * @memberof GetsalesrevenuemonthlyErrorOutput
     */
    status?: GetsalesrevenuemonthlyStatus;
}

/**
 *
 * @export
 * @interface GetsalesrevenuemonthlyOutput
 */
export interface GetsalesrevenuemonthlyOutput {
    /**
     *
     * @type {Array<SalesrevenuehelperRecord>}
     * @memberof GetsalesrevenuemonthlyOutput
     */
    data?: Array<SalesrevenuehelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GetsalesrevenuemonthlyOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GetsalesrevenuemonthlyStatus
 */
export interface GetsalesrevenuemonthlyStatus {
    /**
     *
     * @type {number}
     * @memberof GetsalesrevenuemonthlyStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GetsalesrevenuemonthlyStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GetsalesrevenuemonthlyStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GetstocktakingsummaryErrorOutput
 */
export interface GetstocktakingsummaryErrorOutput {
    /**
     *
     * @type {GetstocktakingsummaryStatus}
     * @memberof GetstocktakingsummaryErrorOutput
     */
    status?: GetstocktakingsummaryStatus;
}

/**
 *
 * @export
 * @interface GetstocktakingsummaryOutput
 */
export interface GetstocktakingsummaryOutput {
    /**
     *
     * @type {Array<StocktakingsummaryhelperRecord>}
     * @memberof GetstocktakingsummaryOutput
     */
    data?: Array<StocktakingsummaryhelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GetstocktakingsummaryOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GetstocktakingsummaryStatus
 */
export interface GetstocktakingsummaryStatus {
    /**
     *
     * @type {number}
     * @memberof GetstocktakingsummaryStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GetstocktakingsummaryStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GetstocktakingsummaryStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GettransactionaddedcreditErrorOutput
 */
export interface GettransactionaddedcreditErrorOutput {
    /**
     *
     * @type {GettransactionaddedcreditStatus}
     * @memberof GettransactionaddedcreditErrorOutput
     */
    status?: GettransactionaddedcreditStatus;
}

/**
 *
 * @export
 * @interface GettransactionaddedcreditOutput
 */
export interface GettransactionaddedcreditOutput {
    /**
     *
     * @type {Array<TransactioncredithelperRecord>}
     * @memberof GettransactionaddedcreditOutput
     */
    data?: Array<TransactioncredithelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GettransactionaddedcreditOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GettransactionaddedcreditStatus
 */
export interface GettransactionaddedcreditStatus {
    /**
     *
     * @type {number}
     * @memberof GettransactionaddedcreditStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GettransactionaddedcreditStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GettransactionaddedcreditStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GettransactioncashflowErrorOutput
 */
export interface GettransactioncashflowErrorOutput {
    /**
     *
     * @type {GettransactioncashflowStatus}
     * @memberof GettransactioncashflowErrorOutput
     */
    status?: GettransactioncashflowStatus;
}

/**
 *
 * @export
 * @interface GettransactioncashflowOutput
 */
export interface GettransactioncashflowOutput {
    /**
     *
     * @type {Array<TransactioncashflowhelperRecord>}
     * @memberof GettransactioncashflowOutput
     */
    data?: Array<TransactioncashflowhelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GettransactioncashflowOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GettransactioncashflowStatus
 */
export interface GettransactioncashflowStatus {
    /**
     *
     * @type {number}
     * @memberof GettransactioncashflowStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GettransactioncashflowStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GettransactioncashflowStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GettransactionpaidcreditErrorOutput
 */
export interface GettransactionpaidcreditErrorOutput {
    /**
     *
     * @type {GettransactionpaidcreditStatus}
     * @memberof GettransactionpaidcreditErrorOutput
     */
    status?: GettransactionpaidcreditStatus;
}

/**
 *
 * @export
 * @interface GettransactionpaidcreditOutput
 */
export interface GettransactionpaidcreditOutput {
    /**
     *
     * @type {Array<TransactioncredithelperRecord>}
     * @memberof GettransactionpaidcreditOutput
     */
    data?: Array<TransactioncredithelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GettransactionpaidcreditOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GettransactionpaidcreditStatus
 */
export interface GettransactionpaidcreditStatus {
    /**
     *
     * @type {number}
     * @memberof GettransactionpaidcreditStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GettransactionpaidcreditStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GettransactionpaidcreditStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GettransactionsErrorOutput
 */
export interface GettransactionsErrorOutput {
    /**
     *
     * @type {GettransactionsStatus}
     * @memberof GettransactionsErrorOutput
     */
    status?: GettransactionsStatus;
}

/**
 *
 * @export
 * @interface GettransactionsOutput
 */
export interface GettransactionsOutput {
    /**
     *
     * @type {Array<TransactionhelperRecord>}
     * @memberof GettransactionsOutput
     */
    data?: Array<TransactionhelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GettransactionsOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GettransactionsStatus
 */
export interface GettransactionsStatus {
    /**
     *
     * @type {number}
     * @memberof GettransactionsStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GettransactionsStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GettransactionsStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GettransactionstotalbytypeErrorOutput
 */
export interface GettransactionstotalbytypeErrorOutput {
    /**
     *
     * @type {GettransactionstotalbytypeStatus}
     * @memberof GettransactionstotalbytypeErrorOutput
     */
    status?: GettransactionstotalbytypeStatus;
}

/**
 *
 * @export
 * @interface GettransactionstotalbytypeOutput
 */
export interface GettransactionstotalbytypeOutput {
    /**
     *
     * @type {Array<TransactiontotalbytypehelperRecord>}
     * @memberof GettransactionstotalbytypeOutput
     */
    data?: Array<TransactiontotalbytypehelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GettransactionstotalbytypeOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GettransactionstotalbytypeStatus
 */
export interface GettransactionstotalbytypeStatus {
    /**
     *
     * @type {number}
     * @memberof GettransactionstotalbytypeStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GettransactionstotalbytypeStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GettransactionstotalbytypeStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface GettransactionvoidErrorOutput
 */
export interface GettransactionvoidErrorOutput {
    /**
     *
     * @type {GettransactionvoidStatus}
     * @memberof GettransactionvoidErrorOutput
     */
    status?: GettransactionvoidStatus;
}

/**
 *
 * @export
 * @interface GettransactionvoidOutput
 */
export interface GettransactionvoidOutput {
    /**
     *
     * @type {Array<TransactionvoidhelperRecord>}
     * @memberof GettransactionvoidOutput
     */
    data?: Array<TransactionvoidhelperRecord>;
    /**
     *
     * @type {GeterrordescriptionsOutputStatus}
     * @memberof GettransactionvoidOutput
     */
    status?: GeterrordescriptionsOutputStatus;
}

/**
 *
 * @export
 * @interface GettransactionvoidStatus
 */
export interface GettransactionvoidStatus {
    /**
     *
     * @type {number}
     * @memberof GettransactionvoidStatus
     */
    errorCode?: number;
    /**
     *
     * @type {number}
     * @memberof GettransactionvoidStatus
     */
    requestUnixTime?: number;
    /**
     *
     * @type {string}
     * @memberof GettransactionvoidStatus
     */
    responseStatus?: string;
}

/**
 *
 * @export
 * @interface PointofsalehelperCurrency
 */
export interface PointofsalehelperCurrency {
    /**
     *
     * @type {string}
     * @memberof PointofsalehelperCurrency
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperCurrency
     */
    id?: number;
}

/**
 *
 * @export
 * @interface PointofsalehelperRecord
 */
export interface PointofsalehelperRecord {
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperRecord
     */
    closedAtUnix?: number;
    /**
     *
     * @type {PointofsalehelperUser}
     * @memberof PointofsalehelperRecord
     */
    closedBy?: PointofsalehelperUser;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperRecord
     */
    closedDeposited?: number;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperRecord
     */
    closedLeftAsChange?: number;
    /**
     *
     * @type {PointofsalehelperCurrency}
     * @memberof PointofsalehelperRecord
     */
    currency?: PointofsalehelperCurrency;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperRecord
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperRecord
     */
    initialAmount?: number;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperRecord
     */
    openedAtUnix?: number;
    /**
     *
     * @type {PointofsalehelperUser}
     * @memberof PointofsalehelperRecord
     */
    openedBy?: PointofsalehelperUser;
    /**
     *
     * @type {PointofsalehelperSalepoint}
     * @memberof PointofsalehelperRecord
     */
    pointOfSale?: PointofsalehelperSalepoint;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperRecord
     */
    totalTransactions?: number;
    /**
     *
     * @type {PointofsalehelperWarehouse}
     * @memberof PointofsalehelperRecord
     */
    warehouse?: PointofsalehelperWarehouse;
}

/**
 *
 * @export
 * @interface PointofsalehelperSalepoint
 */
export interface PointofsalehelperSalepoint {
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperSalepoint
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PointofsalehelperSalepoint
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PointofsalehelperSalepoint
     */
    shopName?: string;
}

/**
 *
 * @export
 * @interface PointofsalehelperUser
 */
export interface PointofsalehelperUser {
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperUser
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof PointofsalehelperUser
     */
    name?: string;
}

/**
 *
 * @export
 * @interface PointofsalehelperWarehouse
 */
export interface PointofsalehelperWarehouse {
    /**
     *
     * @type {string}
     * @memberof PointofsalehelperWarehouse
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof PointofsalehelperWarehouse
     */
    id?: number;
}

/**
 *
 * @export
 * @interface SalesassociatehelperRecord
 */
export interface SalesassociatehelperRecord {
    /**
     *
     * @type {number}
     * @memberof SalesassociatehelperRecord
     */
    employeeId?: number;
    /**
     *
     * @type {string}
     * @memberof SalesassociatehelperRecord
     */
    employeeName1?: string;
    /**
     *
     * @type {string}
     * @memberof SalesassociatehelperRecord
     */
    employeeName2?: string;
    /**
     *
     * @type {number}
     * @memberof SalesassociatehelperRecord
     */
    total?: number;
}

/**
 *
 * @export
 * @interface SalesrevenuehelperRecord
 */
export interface SalesrevenuehelperRecord {
    /**
     *
     * @type {string}
     * @memberof SalesrevenuehelperRecord
     */
    date?: string;
    /**
     *
     * @type {number}
     * @memberof SalesrevenuehelperRecord
     */
    total?: number;
}

/**
 *
 * @export
 * @interface StocktakingsummaryhelperGroup
 */
export interface StocktakingsummaryhelperGroup {
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperGroup
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StocktakingsummaryhelperGroup
     */
    string?: string;
}

/**
 *
 * @export
 * @interface StocktakingsummaryhelperProduct
 */
export interface StocktakingsummaryhelperProduct {
    /**
     *
     * @type {string}
     * @memberof StocktakingsummaryhelperProduct
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperProduct
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StocktakingsummaryhelperProduct
     */
    name?: string;
}

/**
 *
 * @export
 * @interface StocktakingsummaryhelperRecord
 */
export interface StocktakingsummaryhelperRecord {
    /**
     *
     * @type {StocktakingsummaryhelperUser}
     * @memberof StocktakingsummaryhelperRecord
     */
    byUser?: StocktakingsummaryhelperUser;
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperRecord
     */
    costDifference?: number;
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperRecord
     */
    count?: number;
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperRecord
     */
    difference?: number;
    /**
     *
     * @type {StocktakingsummaryhelperGroup}
     * @memberof StocktakingsummaryhelperRecord
     */
    group?: StocktakingsummaryhelperGroup;
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperRecord
     */
    inventoryId?: number;
    /**
     *
     * @type {StocktakingsummaryhelperProduct}
     * @memberof StocktakingsummaryhelperRecord
     */
    product?: StocktakingsummaryhelperProduct;
    /**
     *
     * @type {StocktakingsummaryhelperSeries}
     * @memberof StocktakingsummaryhelperRecord
     */
    series?: StocktakingsummaryhelperSeries;
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperRecord
     */
    stocktakingStartTimeUnix?: number;
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperRecord
     */
    total?: number;
    /**
     *
     * @type {StocktakingsummaryhelperUnit}
     * @memberof StocktakingsummaryhelperRecord
     */
    unit?: StocktakingsummaryhelperUnit;
    /**
     *
     * @type {StocktakingsummaryhelperWarehouseS}
     * @memberof StocktakingsummaryhelperRecord
     */
    warehouse?: StocktakingsummaryhelperWarehouseS;
}

/**
 *
 * @export
 * @interface StocktakingsummaryhelperSeries
 */
export interface StocktakingsummaryhelperSeries {
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperSeries
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StocktakingsummaryhelperSeries
     */
    name?: string;
}

/**
 *
 * @export
 * @interface StocktakingsummaryhelperUnit
 */
export interface StocktakingsummaryhelperUnit {
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperUnit
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StocktakingsummaryhelperUnit
     */
    name?: string;
}

/**
 *
 * @export
 * @interface StocktakingsummaryhelperUser
 */
export interface StocktakingsummaryhelperUser {
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperUser
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StocktakingsummaryhelperUser
     */
    name?: string;
}

/**
 *
 * @export
 * @interface StocktakingsummaryhelperWarehouseS
 */
export interface StocktakingsummaryhelperWarehouseS {
    /**
     *
     * @type {number}
     * @memberof StocktakingsummaryhelperWarehouseS
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof StocktakingsummaryhelperWarehouseS
     */
    name?: string;
}

/**
 *
 * @export
 * @interface TransactioncashflowhelperCurrency
 */
export interface TransactioncashflowhelperCurrency {
    /**
     *
     * @type {string}
     * @memberof TransactioncashflowhelperCurrency
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactioncashflowhelperCurrency
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactioncashflowhelperRecord
 */
export interface TransactioncashflowhelperRecord {
    /**
     *
     * @type {number}
     * @memberof TransactioncashflowhelperRecord
     */
    amount?: number;
    /**
     *
     * @type {TransactioncashflowhelperUser}
     * @memberof TransactioncashflowhelperRecord
     */
    by?: TransactioncashflowhelperUser;
    /**
     *
     * @type {TransactioncashflowhelperCurrency}
     * @memberof TransactioncashflowhelperRecord
     */
    currency?: TransactioncashflowhelperCurrency;
    /**
     *
     * @type {number}
     * @memberof TransactioncashflowhelperRecord
     */
    dateTimeUnix?: number;
    /**
     *
     * @type {number}
     * @memberof TransactioncashflowhelperRecord
     */
    id?: number;
    /**
     *
     * @type {TransactioncashflowhelperSalepoint}
     * @memberof TransactioncashflowhelperRecord
     */
    pointOfSale?: TransactioncashflowhelperSalepoint;
    /**
     *
     * @type {TransactioncashflowhelperWarehouse}
     * @memberof TransactioncashflowhelperRecord
     */
    warehouse?: TransactioncashflowhelperWarehouse;
}

/**
 *
 * @export
 * @interface TransactioncashflowhelperSalepoint
 */
export interface TransactioncashflowhelperSalepoint {
    /**
     *
     * @type {number}
     * @memberof TransactioncashflowhelperSalepoint
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactioncashflowhelperSalepoint
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TransactioncashflowhelperSalepoint
     */
    shopName?: string;
}

/**
 *
 * @export
 * @interface TransactioncashflowhelperUser
 */
export interface TransactioncashflowhelperUser {
    /**
     *
     * @type {number}
     * @memberof TransactioncashflowhelperUser
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactioncashflowhelperUser
     */
    name?: string;
}

/**
 *
 * @export
 * @interface TransactioncashflowhelperWarehouse
 */
export interface TransactioncashflowhelperWarehouse {
    /**
     *
     * @type {string}
     * @memberof TransactioncashflowhelperWarehouse
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactioncashflowhelperWarehouse
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactioncredithelperCurrency
 */
export interface TransactioncredithelperCurrency {
    /**
     *
     * @type {string}
     * @memberof TransactioncredithelperCurrency
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactioncredithelperCurrency
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactioncredithelperDocument
 */
export interface TransactioncredithelperDocument {
    /**
     *
     * @type {string}
     * @memberof TransactioncredithelperDocument
     */
    documentNo?: string;
    /**
     *
     * @type {number}
     * @memberof TransactioncredithelperDocument
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof TransactioncredithelperDocument
     */
    typeId?: number;
}

/**
 *
 * @export
 * @interface TransactioncredithelperRecord
 */
export interface TransactioncredithelperRecord {
    /**
     *
     * @type {number}
     * @memberof TransactioncredithelperRecord
     */
    amount?: number;
    /**
     *
     * @type {TransactioncredithelperCurrency}
     * @memberof TransactioncredithelperRecord
     */
    currency?: TransactioncredithelperCurrency;
    /**
     *
     * @type {number}
     * @memberof TransactioncredithelperRecord
     */
    dateTimeUnix?: number;
    /**
     *
     * @type {TransactioncredithelperDocument}
     * @memberof TransactioncredithelperRecord
     */
    document?: TransactioncredithelperDocument;
    /**
     *
     * @type {TransactioncredithelperSalepoint}
     * @memberof TransactioncredithelperRecord
     */
    pointOfSale?: TransactioncredithelperSalepoint;
    /**
     *
     * @type {TransactioncredithelperWarehouse}
     * @memberof TransactioncredithelperRecord
     */
    warehouse?: TransactioncredithelperWarehouse;
}

/**
 *
 * @export
 * @interface TransactioncredithelperSalepoint
 */
export interface TransactioncredithelperSalepoint {
    /**
     *
     * @type {number}
     * @memberof TransactioncredithelperSalepoint
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactioncredithelperSalepoint
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TransactioncredithelperSalepoint
     */
    shopName?: string;
}

/**
 *
 * @export
 * @interface TransactioncredithelperWarehouse
 */
export interface TransactioncredithelperWarehouse {
    /**
     *
     * @type {string}
     * @memberof TransactioncredithelperWarehouse
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactioncredithelperWarehouse
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactionhelperCurrency
 */
export interface TransactionhelperCurrency {
    /**
     *
     * @type {string}
     * @memberof TransactionhelperCurrency
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactionhelperCurrency
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactionhelperDocument
 */
export interface TransactionhelperDocument {
    /**
     *
     * @type {number}
     * @memberof TransactionhelperDocument
     */
    type?: number;
    /**
     *
     * @type {string}
     * @memberof TransactionhelperDocument
     */
    documentNo?: string;
    /**
     *
     * @type {number}
     * @memberof TransactionhelperDocument
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactionhelperPaymentType
 */
export interface TransactionhelperPaymentType {
    /**
     *
     * @type {string}
     * @memberof TransactionhelperPaymentType
     */
    cardType?: string;
    /**
     *
     * @type {number}
     * @memberof TransactionhelperPaymentType
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactionhelperPaymentType
     */
    type?: string;
}

/**
 *
 * @export
 * @interface TransactionhelperRecord
 */
export interface TransactionhelperRecord {
    /**
     *
     * @type {number}
     * @memberof TransactionhelperRecord
     */
    amount?: number;
    /**
     *
     * @type {string}
     * @memberof TransactionhelperRecord
     */
    cardNumber?: string;
    /**
     *
     * @type {TransactionhelperCurrency}
     * @memberof TransactionhelperRecord
     */
    currency?: TransactionhelperCurrency;
    /**
     *
     * @type {number}
     * @memberof TransactionhelperRecord
     */
    dateTimeUnix?: number;
    /**
     *
     * @type {TransactionhelperDocument}
     * @memberof TransactionhelperRecord
     */
    document?: TransactionhelperDocument;
    /**
     *
     * @type {number}
     * @memberof TransactionhelperRecord
     */
    id?: number;
    /**
     *
     * @type {TransactionhelperPaymentType}
     * @memberof TransactionhelperRecord
     */
    paymentType?: TransactionhelperPaymentType;
    /**
     *
     * @type {TransactionhelperSalepoint}
     * @memberof TransactionhelperRecord
     */
    pointOfSale?: TransactionhelperSalepoint;
    /**
     *
     * @type {TransactionhelperWarehouse}
     * @memberof TransactionhelperRecord
     */
    warehouse?: TransactionhelperWarehouse;
}

/**
 *
 * @export
 * @interface TransactionhelperSalepoint
 */
export interface TransactionhelperSalepoint {
    /**
     *
     * @type {number}
     * @memberof TransactionhelperSalepoint
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactionhelperSalepoint
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TransactionhelperSalepoint
     */
    shopName?: string;
}

/**
 *
 * @export
 * @interface TransactionhelperWarehouse
 */
export interface TransactionhelperWarehouse {
    /**
     *
     * @type {string}
     * @memberof TransactionhelperWarehouse
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactionhelperWarehouse
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactiontotalbytypehelperCurrency
 */
export interface TransactiontotalbytypehelperCurrency {
    /**
     *
     * @type {string}
     * @memberof TransactiontotalbytypehelperCurrency
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperCurrency
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactiontotalbytypehelperPaymentType
 */
export interface TransactiontotalbytypehelperPaymentType {
    /**
     *
     * @type {string}
     * @memberof TransactiontotalbytypehelperPaymentType
     */
    cardType?: string;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperPaymentType
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactiontotalbytypehelperPaymentType
     */
    type?: string;
}

/**
 *
 * @export
 * @interface TransactiontotalbytypehelperRecord
 */
export interface TransactiontotalbytypehelperRecord {
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperRecord
     */
    closedAtUnix?: number;
    /**
     *
     * @type {TransactiontotalbytypehelperCurrency}
     * @memberof TransactiontotalbytypehelperRecord
     */
    currency?: TransactiontotalbytypehelperCurrency;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperRecord
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperRecord
     */
    openedAtUnix?: number;
    /**
     *
     * @type {TransactiontotalbytypehelperSalepoint}
     * @memberof TransactiontotalbytypehelperRecord
     */
    pointOfSale?: TransactiontotalbytypehelperSalepoint;
    /**
     *
     * @type {Array<TransactiontotalbytypehelperTransactionTotal>}
     * @memberof TransactiontotalbytypehelperRecord
     */
    transactionTotals?: Array<TransactiontotalbytypehelperTransactionTotal>;
    /**
     *
     * @type {TransactiontotalbytypehelperWarehouse}
     * @memberof TransactiontotalbytypehelperRecord
     */
    warehouse?: TransactiontotalbytypehelperWarehouse;
}

/**
 *
 * @export
 * @interface TransactiontotalbytypehelperSalepoint
 */
export interface TransactiontotalbytypehelperSalepoint {
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperSalepoint
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactiontotalbytypehelperSalepoint
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TransactiontotalbytypehelperSalepoint
     */
    shopName?: string;
}

/**
 *
 * @export
 * @interface TransactiontotalbytypehelperTransactionTotal
 */
export interface TransactiontotalbytypehelperTransactionTotal {
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    counted?: number;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    expected?: number;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    income?: number;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    initialAmount?: number;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    overShortAmount?: number;
    /**
     *
     * @type {TransactiontotalbytypehelperPaymentType}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    paymentType?: TransactiontotalbytypehelperPaymentType;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    totalCashInAndOut?: number;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperTransactionTotal
     */
    varianceReasonID?: number;
}

/**
 *
 * @export
 * @interface TransactiontotalbytypehelperWarehouse
 */
export interface TransactiontotalbytypehelperWarehouse {
    /**
     *
     * @type {string}
     * @memberof TransactiontotalbytypehelperWarehouse
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactiontotalbytypehelperWarehouse
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactionvoidhelperCurrency
 */
export interface TransactionvoidhelperCurrency {
    /**
     *
     * @type {string}
     * @memberof TransactionvoidhelperCurrency
     */
    code?: string;
    /**
     *
     * @type {number}
     * @memberof TransactionvoidhelperCurrency
     */
    id?: number;
}

/**
 *
 * @export
 * @interface TransactionvoidhelperDocument
 */
export interface TransactionvoidhelperDocument {
    /**
     *
     * @type {string}
     * @memberof TransactionvoidhelperDocument
     */
    documentNo?: string;
    /**
     *
     * @type {number}
     * @memberof TransactionvoidhelperDocument
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof TransactionvoidhelperDocument
     */
    typeId?: number;
}

/**
 *
 * @export
 * @interface TransactionvoidhelperPaymentType
 */
export interface TransactionvoidhelperPaymentType {
    /**
     *
     * @type {string}
     * @memberof TransactionvoidhelperPaymentType
     */
    cardType?: string;
    /**
     *
     * @type {number}
     * @memberof TransactionvoidhelperPaymentType
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactionvoidhelperPaymentType
     */
    type?: string;
}

/**
 *
 * @export
 * @interface TransactionvoidhelperRecord
 */
export interface TransactionvoidhelperRecord {
    /**
     *
     * @type {number}
     * @memberof TransactionvoidhelperRecord
     */
    amount?: number;
    /**
     *
     * @type {TransactionvoidhelperCurrency}
     * @memberof TransactionvoidhelperRecord
     */
    currency?: TransactionvoidhelperCurrency;
    /**
     *
     * @type {number}
     * @memberof TransactionvoidhelperRecord
     */
    dateTimeUnix?: number;
    /**
     *
     * @type {TransactionvoidhelperDocument}
     * @memberof TransactionvoidhelperRecord
     */
    document?: TransactionvoidhelperDocument;
    /**
     *
     * @type {TransactionvoidhelperPaymentType}
     * @memberof TransactionvoidhelperRecord
     */
    paymentType?: TransactionvoidhelperPaymentType;
    /**
     *
     * @type {TransactionvoidhelperSalepoint}
     * @memberof TransactionvoidhelperRecord
     */
    pointOfSale?: TransactionvoidhelperSalepoint;
}

/**
 *
 * @export
 * @interface TransactionvoidhelperSalepoint
 */
export interface TransactionvoidhelperSalepoint {
    /**
     *
     * @type {number}
     * @memberof TransactionvoidhelperSalepoint
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof TransactionvoidhelperSalepoint
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof TransactionvoidhelperSalepoint
     */
    shopName?: string;
}


/**
 * GeneralApi - fetch parameter creator
 * @export
 */
export const GeneralApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get error code descriptions
         * @summary Get error code descriptions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ErrorDescriptionsGet(sessionKey: string, clientCode: string, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1ErrorDescriptionsGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1ErrorDescriptionsGet.');
            }
            const localVarPath = `/v1/error-descriptions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get error code descriptions
         * @summary Get error code descriptions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ErrorDescriptionsGet(sessionKey: string, clientCode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GeterrordescriptionsOutput> {
            const localVarFetchArgs = GeneralApiFetchParamCreator(configuration).v1ErrorDescriptionsGet(sessionKey, clientCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get error code descriptions
         * @summary Get error code descriptions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1ErrorDescriptionsGet(sessionKey: string, clientCode: string, options?: any) {
            return GeneralApiFp(configuration).v1ErrorDescriptionsGet(sessionKey, clientCode, options)(fetch, basePath);
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * Get error code descriptions
     * @summary Get error code descriptions
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public v1ErrorDescriptionsGet(sessionKey: string, clientCode: string, options?: any) {
        return GeneralApiFp(this.configuration).v1ErrorDescriptionsGet(sessionKey, clientCode, options)(this.fetch, this.basePath);
    }

}

/**
 * SalesApi - fetch parameter creator
 * @export
 */
export const SalesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get sales by associate data for a period
         * @summary Get sales by associate data for a period
         * @param {string} sessionKey ERPLY session key.
         * @param {string} clientCode ERPLY client code.
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {string} [warehouseIds] Warehouse ids comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesAssociateTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, warehouseIds?: string, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1SalesAssociateTotalsGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1SalesAssociateTotalsGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling v1SalesAssociateTotalsGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1SalesAssociateTotalsGet.');
            }
            const localVarPath = `/v1/sales/associate-totals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sales revenue as daily totals NOTE! The returned totals contain tax.
         * @summary Get sales revenue as daily totals
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesDailyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1SalesDailyTotalsGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1SalesDailyTotalsGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling v1SalesDailyTotalsGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1SalesDailyTotalsGet.');
            }
            const localVarPath = `/v1/sales/daily-totals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
         * @summary Get sales revenue as monthly totals
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesMonthlyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1SalesMonthlyTotalsGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1SalesMonthlyTotalsGet.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling v1SalesMonthlyTotalsGet.');
            }
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling v1SalesMonthlyTotalsGet.');
            }
            const localVarPath = `/v1/sales/monthly-totals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesApi - functional programming interface
 * @export
 */
export const SalesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get sales by associate data for a period
         * @summary Get sales by associate data for a period
         * @param {string} sessionKey ERPLY session key.
         * @param {string} clientCode ERPLY client code.
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {string} [warehouseIds] Warehouse ids comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesAssociateTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, warehouseIds?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetsalesbyassociateOutput> {
            const localVarFetchArgs = SalesApiFetchParamCreator(configuration).v1SalesAssociateTotalsGet(sessionKey, clientCode, startDate, endDate, warehouseIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get sales revenue as daily totals NOTE! The returned totals contain tax.
         * @summary Get sales revenue as daily totals
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesDailyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetsalesrevenuedailyOutput> {
            const localVarFetchArgs = SalesApiFetchParamCreator(configuration).v1SalesDailyTotalsGet(sessionKey, clientCode, startDate, endDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
         * @summary Get sales revenue as monthly totals
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesMonthlyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetsalesrevenuemonthlyOutput> {
            const localVarFetchArgs = SalesApiFetchParamCreator(configuration).v1SalesMonthlyTotalsGet(sessionKey, clientCode, startDate, endDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SalesApi - factory interface
 * @export
 */
export const SalesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get sales by associate data for a period
         * @summary Get sales by associate data for a period
         * @param {string} sessionKey ERPLY session key.
         * @param {string} clientCode ERPLY client code.
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {string} [warehouseIds] Warehouse ids comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesAssociateTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, warehouseIds?: string, options?: any) {
            return SalesApiFp(configuration).v1SalesAssociateTotalsGet(sessionKey, clientCode, startDate, endDate, warehouseIds, options)(fetch, basePath);
        },
        /**
         * Get sales revenue as daily totals NOTE! The returned totals contain tax.
         * @summary Get sales revenue as daily totals
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesDailyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options?: any) {
            return SalesApiFp(configuration).v1SalesDailyTotalsGet(sessionKey, clientCode, startDate, endDate, options)(fetch, basePath);
        },
        /**
         * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
         * @summary Get sales revenue as monthly totals
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {string} startDate Start date: yyyy-mm-dd
         * @param {string} endDate End date: yyyy-mm-dd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SalesMonthlyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options?: any) {
            return SalesApiFp(configuration).v1SalesMonthlyTotalsGet(sessionKey, clientCode, startDate, endDate, options)(fetch, basePath);
        },
    };
};

/**
 * SalesApi - object-oriented interface
 * @export
 * @class SalesApi
 * @extends {BaseAPI}
 */
export class SalesApi extends BaseAPI {
    /**
     * Get sales by associate data for a period
     * @summary Get sales by associate data for a period
     * @param {string} sessionKey ERPLY session key.
     * @param {string} clientCode ERPLY client code.
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public v1SalesAssociateTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, warehouseIds?: string, options?: any) {
        return SalesApiFp(this.configuration).v1SalesAssociateTotalsGet(sessionKey, clientCode, startDate, endDate, warehouseIds, options)(this.fetch, this.basePath);
    }

    /**
     * Get sales revenue as daily totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as daily totals
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public v1SalesDailyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options?: any) {
        return SalesApiFp(this.configuration).v1SalesDailyTotalsGet(sessionKey, clientCode, startDate, endDate, options)(this.fetch, this.basePath);
    }

    /**
     * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as monthly totals
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesApi
     */
    public v1SalesMonthlyTotalsGet(sessionKey: string, clientCode: string, startDate: string, endDate: string, options?: any) {
        return SalesApiFp(this.configuration).v1SalesMonthlyTotalsGet(sessionKey, clientCode, startDate, endDate, options)(this.fetch, this.basePath);
    }

}

/**
 * StocktakingApi - fetch parameter creator
 * @export
 */
export const StocktakingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get summary of stocktakings
         * @summary Get summary of stocktakings
         * @param {string} sessionKey ERPLY session key.
         * @param {string} clientCode ERPLY client code.
         * @param {number} unixTimeStart UNIX start time.
         * @param {number} unixTimeEnd UNIX end time.
         * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
         * @param {string} [inventoryReportIds] Inventory report ids comma separated.
         * @param {string} [warehouseIds] Warehouse ids comma separated.
         * @param {string} [seriesIds] Series ids comma separated.
         * @param {string} [groupIds] Product group ids comma separated.
         * @param {string} [productCodes] Product codes comma separated.
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StocktakingSummaryGet(sessionKey: string, clientCode: string, unixTimeStart: number, unixTimeEnd: number, languageCode: string, inventoryReportIds?: string, warehouseIds?: string, seriesIds?: string, groupIds?: string, productCodes?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1StocktakingSummaryGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1StocktakingSummaryGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1StocktakingSummaryGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1StocktakingSummaryGet.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1StocktakingSummaryGet.');
            }
            const localVarPath = `/v1/stocktaking/summary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }

            if (inventoryReportIds !== undefined) {
                localVarQueryParameter['inventoryReportIds'] = inventoryReportIds;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (seriesIds !== undefined) {
                localVarQueryParameter['seriesIds'] = seriesIds;
            }

            if (groupIds !== undefined) {
                localVarQueryParameter['groupIds'] = groupIds;
            }

            if (productCodes !== undefined) {
                localVarQueryParameter['productCodes'] = productCodes;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StocktakingApi - functional programming interface
 * @export
 */
export const StocktakingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get summary of stocktakings
         * @summary Get summary of stocktakings
         * @param {string} sessionKey ERPLY session key.
         * @param {string} clientCode ERPLY client code.
         * @param {number} unixTimeStart UNIX start time.
         * @param {number} unixTimeEnd UNIX end time.
         * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
         * @param {string} [inventoryReportIds] Inventory report ids comma separated.
         * @param {string} [warehouseIds] Warehouse ids comma separated.
         * @param {string} [seriesIds] Series ids comma separated.
         * @param {string} [groupIds] Product group ids comma separated.
         * @param {string} [productCodes] Product codes comma separated.
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StocktakingSummaryGet(sessionKey: string, clientCode: string, unixTimeStart: number, unixTimeEnd: number, languageCode: string, inventoryReportIds?: string, warehouseIds?: string, seriesIds?: string, groupIds?: string, productCodes?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetstocktakingsummaryOutput> {
            const localVarFetchArgs = StocktakingApiFetchParamCreator(configuration).v1StocktakingSummaryGet(sessionKey, clientCode, unixTimeStart, unixTimeEnd, languageCode, inventoryReportIds, warehouseIds, seriesIds, groupIds, productCodes, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StocktakingApi - factory interface
 * @export
 */
export const StocktakingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get summary of stocktakings
         * @summary Get summary of stocktakings
         * @param {string} sessionKey ERPLY session key.
         * @param {string} clientCode ERPLY client code.
         * @param {number} unixTimeStart UNIX start time.
         * @param {number} unixTimeEnd UNIX end time.
         * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
         * @param {string} [inventoryReportIds] Inventory report ids comma separated.
         * @param {string} [warehouseIds] Warehouse ids comma separated.
         * @param {string} [seriesIds] Series ids comma separated.
         * @param {string} [groupIds] Product group ids comma separated.
         * @param {string} [productCodes] Product codes comma separated.
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1StocktakingSummaryGet(sessionKey: string, clientCode: string, unixTimeStart: number, unixTimeEnd: number, languageCode: string, inventoryReportIds?: string, warehouseIds?: string, seriesIds?: string, groupIds?: string, productCodes?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return StocktakingApiFp(configuration).v1StocktakingSummaryGet(sessionKey, clientCode, unixTimeStart, unixTimeEnd, languageCode, inventoryReportIds, warehouseIds, seriesIds, groupIds, productCodes, pageNo, recordsOnPage, options)(fetch, basePath);
        },
    };
};

/**
 * StocktakingApi - object-oriented interface
 * @export
 * @class StocktakingApi
 * @extends {BaseAPI}
 */
export class StocktakingApi extends BaseAPI {
    /**
     * Get summary of stocktakings
     * @summary Get summary of stocktakings
     * @param {string} sessionKey ERPLY session key.
     * @param {string} clientCode ERPLY client code.
     * @param {number} unixTimeStart UNIX start time.
     * @param {number} unixTimeEnd UNIX end time.
     * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
     * @param {string} [inventoryReportIds] Inventory report ids comma separated.
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {string} [seriesIds] Series ids comma separated.
     * @param {string} [groupIds] Product group ids comma separated.
     * @param {string} [productCodes] Product codes comma separated.
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StocktakingApi
     */
    public v1StocktakingSummaryGet(sessionKey: string, clientCode: string, unixTimeStart: number, unixTimeEnd: number, languageCode: string, inventoryReportIds?: string, warehouseIds?: string, seriesIds?: string, groupIds?: string, productCodes?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return StocktakingApiFp(this.configuration).v1StocktakingSummaryGet(sessionKey, clientCode, unixTimeStart, unixTimeEnd, languageCode, inventoryReportIds, warehouseIds, seriesIds, groupIds, productCodes, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

}

/**
 * ZReportsApi - fetch parameter creator
 * @export
 */
export const ZReportsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get POS days
         * @summary Get POS days
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, salesManagerIds?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1POSDayGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1POSDayGet.');
            }
            // verify required parameter 'currencyId' is not null or undefined
            if (currencyId === null || currencyId === undefined) {
                throw new RequiredError('currencyId','Required parameter currencyId was null or undefined when calling v1POSDayGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1POSDayGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1POSDayGet.');
            }
            const localVarPath = `/v1/POSDay`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyId !== undefined) {
                localVarQueryParameter['currencyId'] = currencyId;
            }

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (pointOfSaleIds !== undefined) {
                localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (salesManagerIds !== undefined) {
                localVarQueryParameter['salesManagerIds'] = salesManagerIds;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get POS transactions cashflow
         * @summary Get POS transactions cashflow
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCashflowGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1POSDayTransactionCashflowGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1POSDayTransactionCashflowGet.');
            }
            // verify required parameter 'currencyId' is not null or undefined
            if (currencyId === null || currencyId === undefined) {
                throw new RequiredError('currencyId','Required parameter currencyId was null or undefined when calling v1POSDayTransactionCashflowGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionCashflowGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionCashflowGet.');
            }
            const localVarPath = `/v1/POSDay/transaction/cashflow`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyId !== undefined) {
                localVarQueryParameter['currencyId'] = currencyId;
            }

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (pointOfSaleIds !== undefined) {
                localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get POS transactions added credit
         * @summary Get POS transactions added credit
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCreditAddedGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1POSDayTransactionCreditAddedGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1POSDayTransactionCreditAddedGet.');
            }
            // verify required parameter 'currencyId' is not null or undefined
            if (currencyId === null || currencyId === undefined) {
                throw new RequiredError('currencyId','Required parameter currencyId was null or undefined when calling v1POSDayTransactionCreditAddedGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionCreditAddedGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionCreditAddedGet.');
            }
            const localVarPath = `/v1/POSDay/transaction/credit-added`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyId !== undefined) {
                localVarQueryParameter['currencyId'] = currencyId;
            }

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (pointOfSaleIds !== undefined) {
                localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get POS transactions paid credit
         * @summary Get POS transactions paid credit
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCreditPaidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1POSDayTransactionCreditPaidGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1POSDayTransactionCreditPaidGet.');
            }
            // verify required parameter 'currencyId' is not null or undefined
            if (currencyId === null || currencyId === undefined) {
                throw new RequiredError('currencyId','Required parameter currencyId was null or undefined when calling v1POSDayTransactionCreditPaidGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionCreditPaidGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionCreditPaidGet.');
            }
            const localVarPath = `/v1/POSDay/transaction/credit-paid`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyId !== undefined) {
                localVarQueryParameter['currencyId'] = currencyId;
            }

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (pointOfSaleIds !== undefined) {
                localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get POS transactions
         * @summary Get POS transactions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [paymentType] Payment type: CASH, CARD etc
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, paymentType?: string, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1POSDayTransactionGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1POSDayTransactionGet.');
            }
            // verify required parameter 'currencyId' is not null or undefined
            if (currencyId === null || currencyId === undefined) {
                throw new RequiredError('currencyId','Required parameter currencyId was null or undefined when calling v1POSDayTransactionGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionGet.');
            }
            const localVarPath = `/v1/POSDay/transaction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyId !== undefined) {
                localVarQueryParameter['currencyId'] = currencyId;
            }

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (paymentType !== undefined) {
                localVarQueryParameter['paymentType'] = paymentType;
            }

            if (pointOfSaleIds !== undefined) {
                localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get POS transactions total by type
         * @summary Get POS transactions totals by type
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionTotalByTypeGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1POSDayTransactionTotalByTypeGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1POSDayTransactionTotalByTypeGet.');
            }
            // verify required parameter 'currencyId' is not null or undefined
            if (currencyId === null || currencyId === undefined) {
                throw new RequiredError('currencyId','Required parameter currencyId was null or undefined when calling v1POSDayTransactionTotalByTypeGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionTotalByTypeGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionTotalByTypeGet.');
            }
            const localVarPath = `/v1/POSDay/transaction/total-by-type`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyId !== undefined) {
                localVarQueryParameter['currencyId'] = currencyId;
            }

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (pointOfSaleIds !== undefined) {
                localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
            }

            if (warehouseIds !== undefined) {
                localVarQueryParameter['warehouseIds'] = warehouseIds;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get POS void transactions
         * @summary Get POS void transactions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionVoidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, pageNo?: number, recordsOnPage?: number, options: any = {}): FetchArgs {
            // verify required parameter 'sessionKey' is not null or undefined
            if (sessionKey === null || sessionKey === undefined) {
                throw new RequiredError('sessionKey','Required parameter sessionKey was null or undefined when calling v1POSDayTransactionVoidGet.');
            }
            // verify required parameter 'clientCode' is not null or undefined
            if (clientCode === null || clientCode === undefined) {
                throw new RequiredError('clientCode','Required parameter clientCode was null or undefined when calling v1POSDayTransactionVoidGet.');
            }
            // verify required parameter 'currencyId' is not null or undefined
            if (currencyId === null || currencyId === undefined) {
                throw new RequiredError('currencyId','Required parameter currencyId was null or undefined when calling v1POSDayTransactionVoidGet.');
            }
            // verify required parameter 'unixTimeStart' is not null or undefined
            if (unixTimeStart === null || unixTimeStart === undefined) {
                throw new RequiredError('unixTimeStart','Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionVoidGet.');
            }
            // verify required parameter 'unixTimeEnd' is not null or undefined
            if (unixTimeEnd === null || unixTimeEnd === undefined) {
                throw new RequiredError('unixTimeEnd','Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionVoidGet.');
            }
            const localVarPath = `/v1/POSDay/transaction/void`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currencyId !== undefined) {
                localVarQueryParameter['currencyId'] = currencyId;
            }

            if (unixTimeStart !== undefined) {
                localVarQueryParameter['unixTimeStart'] = unixTimeStart;
            }

            if (unixTimeEnd !== undefined) {
                localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
            }

            if (pointOfSaleIds !== undefined) {
                localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (recordsOnPage !== undefined) {
                localVarQueryParameter['recordsOnPage'] = recordsOnPage;
            }

            if (sessionKey !== undefined && sessionKey !== null) {
                localVarHeaderParameter['sessionKey'] = String(sessionKey);
            }

            if (clientCode !== undefined && clientCode !== null) {
                localVarHeaderParameter['clientCode'] = String(clientCode);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZReportsApi - functional programming interface
 * @export
 */
export const ZReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get POS days
         * @summary Get POS days
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, salesManagerIds?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetposdaysOutput> {
            const localVarFetchArgs = ZReportsApiFetchParamCreator(configuration).v1POSDayGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, salesManagerIds, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get POS transactions cashflow
         * @summary Get POS transactions cashflow
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCashflowGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GettransactioncashflowOutput> {
            const localVarFetchArgs = ZReportsApiFetchParamCreator(configuration).v1POSDayTransactionCashflowGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get POS transactions added credit
         * @summary Get POS transactions added credit
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCreditAddedGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GettransactionaddedcreditOutput> {
            const localVarFetchArgs = ZReportsApiFetchParamCreator(configuration).v1POSDayTransactionCreditAddedGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get POS transactions paid credit
         * @summary Get POS transactions paid credit
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCreditPaidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GettransactionpaidcreditOutput> {
            const localVarFetchArgs = ZReportsApiFetchParamCreator(configuration).v1POSDayTransactionCreditPaidGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get POS transactions
         * @summary Get POS transactions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [paymentType] Payment type: CASH, CARD etc
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, paymentType?: string, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GettransactionsOutput> {
            const localVarFetchArgs = ZReportsApiFetchParamCreator(configuration).v1POSDayTransactionGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, paymentType, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get POS transactions total by type
         * @summary Get POS transactions totals by type
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionTotalByTypeGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GettransactionstotalbytypeOutput> {
            const localVarFetchArgs = ZReportsApiFetchParamCreator(configuration).v1POSDayTransactionTotalByTypeGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get POS void transactions
         * @summary Get POS void transactions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionVoidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, pageNo?: number, recordsOnPage?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GettransactionvoidOutput> {
            const localVarFetchArgs = ZReportsApiFetchParamCreator(configuration).v1POSDayTransactionVoidGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, pageNo, recordsOnPage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ZReportsApi - factory interface
 * @export
 */
export const ZReportsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get POS days
         * @summary Get POS days
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, salesManagerIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return ZReportsApiFp(configuration).v1POSDayGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, salesManagerIds, pageNo, recordsOnPage, options)(fetch, basePath);
        },
        /**
         * Get POS transactions cashflow
         * @summary Get POS transactions cashflow
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCashflowGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return ZReportsApiFp(configuration).v1POSDayTransactionCashflowGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(fetch, basePath);
        },
        /**
         * Get POS transactions added credit
         * @summary Get POS transactions added credit
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCreditAddedGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return ZReportsApiFp(configuration).v1POSDayTransactionCreditAddedGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(fetch, basePath);
        },
        /**
         * Get POS transactions paid credit
         * @summary Get POS transactions paid credit
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionCreditPaidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return ZReportsApiFp(configuration).v1POSDayTransactionCreditPaidGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(fetch, basePath);
        },
        /**
         * Get POS transactions
         * @summary Get POS transactions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [paymentType] Payment type: CASH, CARD etc
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, paymentType?: string, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return ZReportsApiFp(configuration).v1POSDayTransactionGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, paymentType, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(fetch, basePath);
        },
        /**
         * Get POS transactions total by type
         * @summary Get POS transactions totals by type
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionTotalByTypeGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return ZReportsApiFp(configuration).v1POSDayTransactionTotalByTypeGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(fetch, basePath);
        },
        /**
         * Get POS void transactions
         * @summary Get POS void transactions
         * @param {string} sessionKey ERPLY session key
         * @param {string} clientCode ERPLY client code
         * @param {number} currencyId Currency Id
         * @param {number} unixTimeStart UNIX start time
         * @param {number} unixTimeEnd UNIX end time
         * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
         * @param {number} [pageNo] Page number. Default is 1.
         * @param {number} [recordsOnPage] Records on page. Default is 100.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1POSDayTransactionVoidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
            return ZReportsApiFp(configuration).v1POSDayTransactionVoidGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, pageNo, recordsOnPage, options)(fetch, basePath);
        },
    };
};

/**
 * ZReportsApi - object-oriented interface
 * @export
 * @class ZReportsApi
 * @extends {BaseAPI}
 */
export class ZReportsApi extends BaseAPI {
    /**
     * Get POS days
     * @summary Get POS days
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZReportsApi
     */
    public v1POSDayGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, salesManagerIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return ZReportsApiFp(this.configuration).v1POSDayGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, salesManagerIds, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

    /**
     * Get POS transactions cashflow
     * @summary Get POS transactions cashflow
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZReportsApi
     */
    public v1POSDayTransactionCashflowGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return ZReportsApiFp(this.configuration).v1POSDayTransactionCashflowGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

    /**
     * Get POS transactions added credit
     * @summary Get POS transactions added credit
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZReportsApi
     */
    public v1POSDayTransactionCreditAddedGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return ZReportsApiFp(this.configuration).v1POSDayTransactionCreditAddedGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

    /**
     * Get POS transactions paid credit
     * @summary Get POS transactions paid credit
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZReportsApi
     */
    public v1POSDayTransactionCreditPaidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return ZReportsApiFp(this.configuration).v1POSDayTransactionCreditPaidGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

    /**
     * Get POS transactions
     * @summary Get POS transactions
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [paymentType] Payment type: CASH, CARD etc
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZReportsApi
     */
    public v1POSDayTransactionGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, paymentType?: string, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return ZReportsApiFp(this.configuration).v1POSDayTransactionGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, paymentType, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

    /**
     * Get POS transactions total by type
     * @summary Get POS transactions totals by type
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZReportsApi
     */
    public v1POSDayTransactionTotalByTypeGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, warehouseIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return ZReportsApiFp(this.configuration).v1POSDayTransactionTotalByTypeGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, warehouseIds, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

    /**
     * Get POS void transactions
     * @summary Get POS void transactions
     * @param {string} sessionKey ERPLY session key
     * @param {string} clientCode ERPLY client code
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZReportsApi
     */
    public v1POSDayTransactionVoidGet(sessionKey: string, clientCode: string, currencyId: number, unixTimeStart: number, unixTimeEnd: number, pointOfSaleIds?: string, pageNo?: number, recordsOnPage?: number, options?: any) {
        return ZReportsApiFp(this.configuration).v1POSDayTransactionVoidGet(sessionKey, clientCode, currencyId, unixTimeStart, unixTimeEnd, pointOfSaleIds, pageNo, recordsOnPage, options)(this.fetch, this.basePath);
    }

}

