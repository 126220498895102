import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { isInt, isFloat, isEmpty } from 'validator';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { round } from 'utils';
import { getTotal } from 'reducers/ShoppingCart';
import { getCurrentSalesDocument } from 'reducers/sales';
import { openPaymentModal } from 'actions/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { addWarning } from 'actions/Error';
import { saveSalesDocument } from 'actions/sales';
import InputField from 'components/FieldTypes/InputField';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { getSelectedCustomer } from 'reducers/customerSearch';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { modalPages } from 'constants/modalPage';
import { PluginComponent } from 'plugins';

import fields from './formFields';

/*
  TODO: Copy the functionality from Layaway.
  A person can pickup the Account Sales, pay for it partially multiple times instead of paying once the remaining amount
*/
const AccountSales = () => {
  const dispatch = useDispatch();
  const total = useSelector(getTotal);
  const currentSaleDoc = useSelector(getCurrentSalesDocument);

  // const date = new Date();
  const initState = {
    advancePayment: '0.00',
    prepaymentPercent: '0.00',
    invoiceTotal: round(total, 2),
    paid:
      (currentSaleDoc.type === 'PREPAYMENT' && currentSaleDoc.paid) || '0.00',
  };

  const [state, setState] = useState(initState);

  const close = () => {
    dispatch(closeModalPage());
  };
  const { t } = useTranslation('accountSales');

  const currentCustomer = useSelector(getSelectedCustomer);

  const onSave = () => {
    if (state.advancePayment < 0) {
      dispatch(
        addWarning(t('alerts.missingPayment'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
      /* if no prepayment, then still create a salesDoc as invoicewaybill
      and show a confirmation menu for it */
    } else if (state.advancePayment === '0.00') {
      dispatch(closeModalPage());
      dispatch(
        saveSalesDocument({
          salesDocument: {
            ...currentSaleDoc,
            type: 'INVWAYBILL',
            confirmed: 1,
          },
          onSuccess: ({
            invoiceID,
            invoiceLink,
            // invoiceNo,
            receiptLink,
            ...salesDocument
          }) => {
            dispatch(
              openModalPage({
                component: modalPages.PaymentConfirmation,
                modalClassName: 'confirmation',
                isPopup: true,
                props: {
                  invoiceID,
                  invoiceLink,
                  customer: currentCustomer,
                  // invoiceNo,
                  receiptLink,
                  balance: 0,
                  type: salesDocument.type,
                  ...salesDocument,
                },
              }),
            );
          },
        }),
      );
    } else {
      dispatch(closeModalPage());
      dispatch(
        openPaymentModal({
          props: {
            total: Number(state.advancePayment) + Number(state.paid),
            salesDocument: {
              // passdown salesID to compare to currentSalesDocID
              type: 'INVWAYBILL',
              advancePayment: Number(state.advancePayment) + Number(state.paid),
            },
          },
          showNotes: false,
        }),
      );
    }
  };

  const roundOnBlur = name => {
    if (name === 'advancePayment') {
      setState({
        ...state,
        advancePayment: round(state.advancePayment, 2),
      });
    }
    if (name === 'prepaymentPercent') {
      setState({
        ...state,
        prepaymentPercent: Number(state.prepaymentPercent),
      });
    }
  };

  const handleChange = (e, inputKey) => {
    const { value } = e.target;

    if (inputKey === 'prepaymentPercent') {
      setState({
        ...state,
        prepaymentPercent: value,
        // calculate the percent from the total but not bigger than the outstanding amount
        advancePayment: round((state.invoiceTotal * e.target.value) / 100, 2),
      });
      return;
    }

    if (inputKey === 'advancePayment') {
      if (isFloat(value) || isInt(value) || isEmpty(value)) {
        setState({
          ...state,
          advancePayment: value,
          prepaymentPercent: round((value * 100) / state.invoiceTotal, 2),
        });
      }
    }
  };

  return (
    <div className="accountSales-sales" data-testid="account-sales-modal">
      <Modal.Header>
        <Modal.Title>{t('save.title')}</Modal.Title>
        <div
          className="accountSales-sales-header-bttn-wrapper"
          style={{
            marginLeft: 'auto',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <SaveButton data-testid="save-btn" action={() => onSave()} />
          <CloseButton action={() => close()} />
        </div>
      </Modal.Header>
      <Modal.Body className="body">
        <PluginComponent
          hookname="UIAccountSalesForm"
          props={{ fields, handleChange, state, roundOnBlur }}
        >
          <Form>
            {fields.map(field => {
              return (
                <Form.Group key={field.key} controlId={field.key}>
                  <InputField
                    size="lg"
                    data-testid="input-field"
                    data-test-key={field.key}
                    title={t(`fields.${field.key}`)}
                    value={state[field.key] || ''}
                    name={field.key}
                    type={field.type}
                    options={field.options}
                    onChange={e =>
                      field.disabled ? null : handleChange(e, field.key)
                    }
                    autoComplete="off"
                    onBlur={() =>
                      ['advancePayment', 'prepaymentPercent'].includes(
                        field.key,
                      )
                        ? roundOnBlur(field.key)
                        : null
                    }
                    readOnly={field.disabled}
                    className={classNames([
                      styles.formInput,
                      styles.noGrayOnDisable,
                      styles.longTitle,
                    ])}
                  />
                </Form.Group>
              );
            })}
          </Form>
        </PluginComponent>
      </Modal.Body>
    </div>
  );
};

export default AccountSales;
