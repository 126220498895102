import React, { useRef, useState } from 'react';
import * as R from 'ramda';
import { useDebounce } from 'react-use';

import { lerp } from './util';

interface Point {
  /** X coordinate */
  x: number;
  /** Y coordinate */
  y: number;
  /** Pressure (0.5 for mouose click, 0..1 on supported devices) */
  p: number;
}

type Signature = string;

const HEIGHT = 200;
const WIDTH = 400;
export const SignatureInput = ({
  value,
  setValue,
}: {
  value: Signature;
  setValue: (s: Signature) => void;
}) => {
  const [prev, setPrev] = useState<{ x: number; y: number; p: number } | null>(
    null,
  );
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const onPointerMove = e => {
    const {
      offsetX: nx,
      offsetY: ny,
      pressure: np,
      buttons: b,
    } = e.nativeEvent;
    // SAFARI WORKAROUND
    // Normal browsers report a mouse click as pressure = 0.5
    // Safari reports it as pressure = 0
    const pressure = R.when(
      p =>
        // eslint-disable-next-line no-bitwise
        b & 1 && // If primary mouse button is pressed
        p === 0 && // but there is no pressure
        [0, 0.5, undefined].includes(prev?.p), // And there is no evidence of pressure being supported
      R.always(0.5), // then use 0.5 as the pressure
    )(np);
    setPrev(prev => {
      if (pressure === 0 && prev === null) return null;
      const context = canvasRef.current?.getContext('2d', {
        desynchronized: true,
      });
      if (context) {
        if (prev !== null) {
          const distance = Math.sqrt((prev.x - nx) ** 2 + (prev.y - ny) ** 2);
          Array(Math.floor(distance))
            .fill(0)
            .forEach((z, i) => {
              const fraction = i / distance;
              const p = lerp(prev.p, pressure, fraction);
              const x = lerp(prev.x, nx, fraction);
              const y = lerp(prev.y, ny, fraction);
              const w = 3 * p;

              context.beginPath();
              context.arc(x, y, w, 0, 2 * Math.PI);
              context.fillStyle = 'blue';
              context.fill();
              context.closePath();
            });
        }
      }
      if (pressure === 0) return null;
      return { x: nx, y: ny, p: pressure };
    });
  };
  useDebounce(
    () => {
      if (!canvasRef.current) return;
      setValue(canvasRef.current?.toDataURL());
    },
    HEIGHT,
    [prev],
  );
  // useEffect(() => {
  //   if (value === '' && canvasRef.current) {
  //     canvasRef.current.getContext('2d')?.clearRect(0, 0, WIDTH, HEIGHT);
  //   }
  // }, [value]);
  // @ts-ignore
  return (
    <>
      <div
        style={{
          width: WIDTH,
          height: HEIGHT,
          position: 'relative',
          border: '2px solid black',
          backgroundColor: 'white',
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            position: 'absolute',
            inset: '0',
            width: WIDTH,
            height: HEIGHT,
            filter: 'grayscale(100%) brightness(200%)',
          }}
          width={WIDTH}
          height={HEIGHT}
          onPointerMove={onPointerMove}
        />
        <img
          src={value}
          style={{
            position: 'absolute',
            inset: '0',
            pointerEvents: 'none',
          }}
          onLoad={e => {
            const context = canvasRef.current?.getContext('2d');
            context?.clearRect(0, 0, WIDTH, HEIGHT);
            context?.drawImage(e.target as HTMLImageElement, 0, 0);
          }}
        />
      </div>
    </>
  );
};
