import React, {
  createContext,
  FC,
  FunctionComponent,
  HTMLAttributes,
  useContext,
  useRef,
  useState,
} from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Box, CircularProgress, List, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Address } from '../types';

// #region Virtualized list that works with MUI Autocomplete
const Row = ({ data, index, style }: ListChildComponentProps) => {
  return React.cloneElement(data[index], { style });
};

const OuterElementContext = createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const VirtualizedList = React.forwardRef<HTMLDivElement>(
  ({ children, ...rest }, ref) => {
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const gridRef = useRef<FixedSizeList>(null);
    const outerProps = { ...rest };
    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={outerProps}>
          <FixedSizeList
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType={List}
            width="100%"
            height={400}
            itemCount={itemCount}
            itemSize={49}
            overscanCount={5}
            itemData={itemData}
          >
            {Row}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);
// #endregion

interface Props {
  loading: boolean;
  options: Address[];
  value: Address | null;
  onOptionSelect: (address: Address | null) => boolean;
  inputValue: string;
  onInputChange: (event) => void;
  label: string;
  noOptionsText: string;
  loadingText: string;
}

const AddressAutocomplete: FC<Props> = ({
  loading,
  options,
  value,
  onOptionSelect,
  inputValue,
  onInputChange,
  label,
  noOptionsText,
  loadingText,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Autocomplete
      id="address-autocomplete"
      debug
      disableListWrap
      open={open}
      loading={loading}
      options={options}
      filterOptions={options => options}
      value={value}
      noOptionsText={noOptionsText}
      loadingText={loadingText}
      onChange={(_, newAddress) => {
        if (onOptionSelect(newAddress)) setOpen(false);
      }}
      getOptionLabel={address => `${address.Text} ${address.Description}`}
      ListboxComponent={
        VirtualizedList as FunctionComponent<HTMLAttributes<HTMLElement>>
      }
      renderOption={(address, idx) => (
        <Box key={`${address.Id}${idx}`} width="100%">
          <Box fontWeight={700} paddingRight={2}>
            {address.Text}
          </Box>
          <Box>{address.Description}</Box>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          label={label}
          value={inputValue}
          onChange={onInputChange}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          inputProps={{
            ...params.inputProps,
            value: inputValue,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AddressAutocomplete;
