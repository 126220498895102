import { createSelector } from 'reselect';

import { VatRate } from 'types/VatRate';
import * as c from 'constants/vatRatesDB';
import { SO } from 'services/DB/types';
import { Product } from 'types/Product';

import { getSelectedPos } from './PointsOfSale';
import { getSelectedWarehouseID } from './warehouses';
import { getProductGroupByID } from './productGroupsDB';
import { getCachedItemsPerType } from './cachedItems';

export default (state: VatRate[] | null = null, { type, payload }) => {
  switch (type) {
    case c.SET_VATRATES:
      return payload;
    default:
      return state;
  }
};

export function getVatRatesFetching(state) {
  return !state.vatRatesDB;
}
export function getVatRates(state): VatRate[] {
  return state.vatRatesDB ?? [];
}
export const getActiveVatRates = createSelector(
  state => getVatRates(state),
  rates => rates.filter(rate => Number(rate.active) !== 0),
);
export const getTaxFreeVatRate = createSelector(
  state => getActiveVatRates(state),
  rates => rates.find(rate => Number(rate.rate) === 0),
);
export function getVatRateByID(id) {
  return createSelector(getVatRates, vatRates =>
    vatRates.find(vRate => String(vRate.id) === String(id)),
  );
}

export function getSelectedPosVatRate(state) {
  const selectedPOS = getSelectedPos(state);
  return getVatRateByID(selectedPOS?.vatrateID)(state);
}

export function getProductGroupVatRateInSelectedWarehouse(id) {
  return state => {
    const warehouseID = getSelectedWarehouseID(state);
    const group = getProductGroupByID(id)(state);
    const vatRateID = group.vatrates?.find(
      vatRate => Number(vatRate.warehouseID) === Number(warehouseID),
    )?.vatrateID;
    return getVatRateByID(vatRateID)(state);
  };
}

export function getProductGroupVatRateForCachedProducts(state) {
  const warehouseID = getSelectedWarehouseID(state);
  const products: Product[] = Object.values(
    getCachedItemsPerType(SO.PRODUCTS.NAME)(state),
  );
  return Object.fromEntries(
    products
      .map(product => {
        const group = getProductGroupByID(product.groupID)(state);
        const vatRateID = group.vatrates?.find(
          vatRate => Number(vatRate.warehouseID) === Number(warehouseID),
        )?.vatrateID;
        const vatRate = getVatRateByID(vatRateID)(state);
        return [product.productID, vatRate?.id];
      })
      .filter(([, vatRateId]) => !!vatRateId),
  );
}
