import { getPluginSelector } from 'reducers/Plugins';

import { SET_MULTIPLE_DOCUMENTS, SET_PURCHASE_ORDER } from './actionTypes';

type HpState = {
  purchaseOrderNumber: string;

  /** {[id]: unpaidAmount} of sales selected for bulk payment */
  selectedSales: {
    [invoiceID: string]: number;
  };
};

const hpReducer = (
  state: HpState = {
    purchaseOrderNumber: '',
    selectedSales: {},
  },
  action,
): HpState => {
  switch (action.type) {
    case SET_PURCHASE_ORDER:
      return { ...state, purchaseOrderNumber: action.payload };
    case SET_MULTIPLE_DOCUMENTS:
      return { ...state, selectedSales: action.payload };
    default:
      return state;
  }
};

export const getHipPocketState = state =>
  getPluginSelector<HpState>('hip-pocket-plugin')(n => n)(state);

export default hpReducer;
