/* eslint-disable no-console */
import i18next from 'i18next';

import { openTerminalIntegration } from 'actions/integrations/terminal';
import {
  deletePayment,
  markForProcessing,
  unmarkFromProcessing,
} from 'actions/Payments';
import { INTEGRATIONS, INTEGRATION_TYPES } from 'constants/CAFA';
import { CardPaymentHooks, FUNC_BUTTONS } from 'paymentIntegrations/types';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { getCardPaymentsForIntegration } from 'reducers/Payments';
import { isEmpty } from 'utils';
import { printPatchscript } from 'actions/integrations/printer';

import { processPayments } from './handlers/processPayments';
import { MONERIS } from './requests/monerisTransactions';
import { Config } from './types';
import { generatePatchscriptFromString } from './utils';

const integrationType = INTEGRATION_TYPES.payment;
const integrationName = INTEGRATIONS[integrationType].moneris;

export const initPayments = (params: CardPaymentHooks) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const {
    enableButtons,
    rejectPayments,
    resolvePayments,
    displayError,
  } = params;
  const { value: config } =
    getCafaEntry<typeof integrationType, typeof integrationName, Config>(
      integrationName,
      integrationType,
    )(state) ?? {};
  if (isEmpty(config)) {
    displayError('Moneris not configured.');
    rejectPayments();
    return;
  }
  enableButtons([FUNC_BUTTONS.CANCEL]);

  dispatch(processPayments(params))
    .then(({ errors, data }) => {
      // use payments returned from getCardPaymentsForIntegration, because cardPayments from params are outdated
      const shouldVoid = getCardPaymentsForIntegration('moneris')(
        getState(),
      ).every(p => p.shouldProcess && p.paid);

      if (errors.length || (!data.length && !errors.length)) {
        enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CANCEL]);
      } else if (shouldVoid) {
        rejectPayments();
      } else {
        resolvePayments();
      }
    })
    .catch(error =>
      console.error(
        'Failed to process payment with Moneris integration',
        error,
      ),
    );
};
const cancelCurrentPayment = ({
  enableButtons,
  enabledButtons,
  rejectPayments,
  updateMessage,
}: CardPaymentHooks) => async (dispatch, getState) => {
  const integrationCardPayments = getCardPaymentsForIntegration('moneris')(
    getState(),
  );
  const hasRetry = enabledButtons.includes(FUNC_BUTTONS.RETRY);
  await Promise.all(
    integrationCardPayments.map(p =>
      dispatch(unmarkFromProcessing({ key: p.key })),
    ),
  );
  enableButtons([]);
  if (!hasRetry) {
    return MONERIS.cancel();
  }
  return rejectPayments();
};
export const cancelPayments = (params: CardPaymentHooks) => async dispatch =>
  dispatch(cancelCurrentPayment(params)).catch(e =>
    console.error('Failed to cancel payments with moneris'),
  );

export const retryPayments = (params: CardPaymentHooks) => async (
  dispatch,
  getState,
) => {
  const cardPayments = getCardPaymentsForIntegration('moneris')(getState());
  if (cardPayments.every(c => c.paid)) {
    Promise.all(
      cardPayments.map(c => dispatch(markForProcessing({ key: c.key }))),
    );
  }
  const cardPaymentsToRetry = getCardPaymentsForIntegration('moneris')(
    getState(),
  );
  dispatch(initPayments({ ...params, cardPayments: cardPaymentsToRetry }));
};

export const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('moneris')(getState());
  cardPayments.forEach(({ key, paid }) =>
    paid
      ? dispatch(markForProcessing({ key }))
      : dispatch(deletePayment({ key })),
  );

  const finalPayments = getCardPaymentsForIntegration('moneris')(getState());
  if (finalPayments.length) {
    await dispatch(openTerminalIntegration());
  }
};

export const closeBatch = () => async () => {
  try {
    const closeBatchResult = await MONERIS.closeDay();
    return closeBatchResult;
  } catch (err) {
    console.error('Failed to close batch in Moneris', err);
    return false;
  }
};
export const functions = [
  {
    actionOnClick: retryPayments,
    text: 'Retry',
    name: FUNC_BUTTONS.RETRY,
    variant: 'warning',
  },
  {
    actionOnClick: cancelPayments,
    text: 'Cancel',
    name: FUNC_BUTTONS.CANCEL,
    variant: 'danger',
  },
];

type AdditionalData = {
  cardholder?: string;
  merchant?: string;
};

export const printReceipts = (receipts: AdditionalData) => dispatch => {
  const { cardholder, merchant } = receipts;

  const cardholderPatchscript = cardholder
    ? generatePatchscriptFromString(cardholder)
    : null;
  const merchantPatchscript = merchant
    ? generatePatchscriptFromString(merchant)
    : null;

  if (cardholderPatchscript) {
    dispatch(printPatchscript(cardholderPatchscript));
  }
  if (merchantPatchscript) {
    dispatch(printPatchscript(merchantPatchscript));
  }
};
