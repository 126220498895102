/* eslint-disable no-console */
import axios from 'axios';
import { compare } from 'compare-versions';

import { round, sleep } from 'utils';
import { getMSEndpointFromLS } from 'reducers/installer';

import { MINIMUM_MS_VERSION_FOR_V2 } from '../constants';
import { VoidProps, PaymentProps, RefundProps } from '../types';

const defaultVerifoneUrl = 'https://localhost.erply.com:7878/api/v1/';

const getVerifoneUrl = () => {
  const endpoint = getMSEndpointFromLS('verifone');
  return endpoint ? `${endpoint}api/v1/` : defaultVerifoneUrl;
};

const getServiceInfo = async () => {
  try {
    const response = await window
      .fetch(`${getVerifoneUrl()}service/info`)
      .then(data => data.json());
    return response;
  } catch (e) {
    console.error('Could not fetch service info. ', e);
    throw e;
  }
};

const checkIfVersionAllowsV2 = (version: string) => {
  const installedVersion = version.replace('v', '');
  return compare(installedVersion, MINIMUM_MS_VERSION_FOR_V2, '>=');
};

export const getUseV2 = async () => {
  try {
    const info = await getServiceInfo();
    const version = info.records[0].Version;
    return checkIfVersionAllowsV2(version);
  } catch (e) {
    return false;
  }
};

const verifoneRequest = axios.create({
  baseURL: getVerifoneUrl(),
  headers: {
    Accept: 'application/json',
  },
});

const { CancelToken } = axios;
let cancel;

export const testConnection = async () => {
  try {
    const response = await verifoneRequest.get('testConnection');
    return response;
  } catch (e) {
    console.error('Failed to test connection to verifone', e);
    throw e;
  }
};

export const requestSale = async (params: PaymentProps) => {
  const amount = round(params.amount);
  try {
    const saleResponse = await verifoneRequest.post(
      'payment',
      { ...params, amount },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      },
    );
    // Added delay to ensure terminal is free
    await sleep(0.2);
    return saleResponse;
  } catch (e) {
    console.error('Failed to process payment with verifone', e);
    throw e;
  }
};

export const requestReturn = async (params: RefundProps) => {
  const amount = round(params.amount);
  try {
    const refundResponse = await verifoneRequest.post(
      'refund',
      { ...params, amount },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      },
    );
    // Added delay to ensure terminal is free
    await sleep(0.2);
    return refundResponse;
  } catch (e) {
    console.error('Failed to process refund with verifone', e);
    throw e;
  }
};

export const requestVoid = async (params: VoidProps) => {
  const amount = round(params.amount);
  const referenceNumber = params.referenceNumber.replace(/^0+/, '');
  try {
    const voidResponse = await verifoneRequest.post(
      'reversal',
      { ...params, amount, referenceNumber },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      },
    );
    await sleep(0.2);
    return voidResponse;
  } catch (e) {
    console.error('Failed to process reversal with verifone', e);
    throw e;
  }
};

export const requestStart = async () => {
  try {
    const startResponse = await verifoneRequest.get('start');
    return startResponse;
  } catch (e) {
    console.error('Failed to process start with verifone', e);
    throw e;
  }
};

export const cancelRequest = () => {
  if (cancel) {
    cancel('Cancelled by user');
    cancel = undefined;
  }
  return Promise.resolve();
};
