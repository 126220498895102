export const TYPE_ADDERROR = `Error: ERR`;
export const TYPE_DISMISS = `Error: DISMISS`;
export const TYPE_DISMISS_TYPE = `Error: DISMISS_TYPE`;
export const TYPE_DISMISS_ALL = `TYPE_DISMISS_ALL`;

export const TYPE_RESET = 'Notification: RESET';
export const TYPE_READ = 'Notification: READ';
export const TYPE_SET_UNREAD = 'Notification: SET_UNREAD';
export const TYPE_READ_ALL = 'Notification: READ_ALL';

export const TYPES = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};
