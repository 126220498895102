import { createSelector } from 'reselect';

import { getPluginSelector } from 'reducers/Plugins';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { Currency } from 'types/Currencies';

const SET_CURRENCY = 'SET_CURRENCY';

export const setCurrency = (payload: Currency | null) => ({
  type: SET_CURRENCY,
  payload,
});

const initialState: Currency | null = null;

export const getCurrency = getPluginSelector<Currency | null>(
  'currency-converter',
)(n => n);

export const getIsRegularCardAndTaxFree = createSelector(
  getProductsInShoppingCart,
  products =>
    products.some(p => p.isRegularGiftCard && Number(p.vatRate) === 0),
);

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENCY':
      return action.payload;
    default:
      return state;
  }
};
