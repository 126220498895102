import { useState } from 'react';

const useXStorage = storage => (key, def) => {
  const save = (key, value) =>
    value
      ? storage.setItem(key, JSON.stringify(value))
      : storage.removeItem(key);
  const load = key => JSON.parse(storage.getItem(key));

  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const [state, setState] = useState(load(key) || def);

  return [
    state,
    val => {
      let result;
      try {
        result = val(state);
      } catch (e) {
        result = val;
      }
      save(key, result);
      setState(result);
    },
  ];
};

export const useSessionStorage = useXStorage(sessionStorage);
export const useLocalStorage = useXStorage(localStorage);
