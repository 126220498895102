// Unnecessary blocks are used to fence off sections of the code dealing with one feature each
/* eslint-disable no-lone-blocks */
import * as R from 'ramda';
import debug from 'debug';

import { urlParams } from './parsedUrlParameters';

const baseLog = debug('UrlControl');
const baseErr = debug('UrlControl');
// eslint-disable-next-line no-console
baseErr.log = console.warn.bind(console);

interface TUrlControl {
  /**
   * POS will automatically update the enabled namespaces for the {@link https://www.npmjs.com/package/debug|debug} library
   */
  debug: string;

  /**
   * POS will attempt to automatically log in using a session cookie
   *
   * To trigger this, the POS must be opened with the following query parameters
   * * auth = `"cookie"`
   * * clientCode = 'the client code to log in to'
   *
   * and the cookie `apisession_clientcode` must contain the sessionkey (it is allowed to be httponly)
   *
   * If your application uses login.erply.com to log in, the cookie will already be set and you only need to pass auth and clientcode to the POS in order to forward the session
   *
   * @see https://login.erply.com Erply Login app which uses this feature
   */
  resumeSession: {
    clientCode: string;
  };

  /**
   * POS will automatically log in with the provided credentials
   *
   * @deprecated not implemented yet
   * @deprecated Not currently planned to implement due to security implications
   */
  createSession: {
    clientCode: string;
    username: string;
    password: string;
  };

  /**
   * POS will automatically select the warehouse & POS
   *
   * To trigger this, the POS must be opened with one of the following query parameters
   * * warehouseID
   * * pointOfSaleID
   *
   * If pointOfSaleID is provided, the warehouseID will be inferred
   * If both are provided, the provided warehouseID is ignored and will still be inferred from the pointOfSaleID instead
   *
   * There is no need to pass both, but if both are passed, only the pointOfSaleId will take effect
   */
  autoSelectPos:
    | {
        warehouseID: number;
      }
    | {
        pointOfSaleID: number;
      };

  /**
   * Perform a return immediately after the POS is logged in to.
   *
   * 1) Load the document
   * 2) Apply extraparams
   * 3) Update rows based on returnReasonID / returnRows
   * 4) Directly open Payment modal with the modified document
   */
  actionReturnSale: {
    id: number;
    /** Default returnReasonID - May be omitted only if returnReasonID is provided for every row individually */
    returnReasonID?: string;
    /** If not sent, assume full return with default returnReasonID for all rows */
    returnRows?: {
      stableRowID: string;
      amount: number;
      /** If missing, use default returnReasonID */
      returnReasonID?: string;
    }[];
    /** Extra fields for the return document's saveSalesDocument request */
    extraParams?: Record<string, unknown>;
  };

  /**
   * Perform a layaway cancellation immediately after the POS is logged in to
   *
   * 1) Load the document
   * 2) Directly open payment modal with the cancellation (reuse existing code from "Layaways" menu
   */
  actionCancelLayaway: {
    id: number;
  };

  /**
   * If an "action" is provided, do something when the action is finished
   *
   * * If missing / invalid: TODO: Discuss
   * * 'stay': Do nothing, remain in the POS
   * * 'close': Close the POS
   * * a valid URL: Redirect to the given URL, passing in any data returned by the action
   */
  afterActionFinished: 'close' | 'stay' | URL;

  log: ReturnType<typeof debug>;
}
/**
 * Utilities related to the URL API
 *
 * The URL API allows other web applications to attach extra commands when redirecting to Brazil POS
 * These extra commands may instruct the POS to automatically log in, select a certain warehouse, start a return for a given document, etc.
 */
export const UrlControl: Partial<TUrlControl> = {
  log: debug('UrlControl'),
};

/** debug */
{
  const { debug } = urlParams;
  if (debug) {
    UrlControl.debug = debug;
  }
}

/** resumeSession */
{
  const { auth, clientCode } = urlParams;

  if (auth === 'cookie' && clientCode)
    UrlControl.resumeSession = { clientCode };
}

/** autoSelectPos */
{
  const { autoSelectPos } = R.evolve({
    autoSelectPos: {
      pointOfSaleID: Number,
      warehouseID: Number,
    },
  })(urlParams);

  try {
    if (!Number.isFinite(autoSelectPos.pointOfSaleID ?? 0))
      throw new Error('pointOfSaleID must be a number');
    if (!Number.isFinite(autoSelectPos.warehouseID ?? 0))
      throw new Error('warehouseID must be a number');

    if (autoSelectPos.pointOfSaleID) delete autoSelectPos.warehouseID;

    UrlControl.autoSelectPos = autoSelectPos;
  } catch (e) {
    baseErr('Failed to parse autoSelectPos: ', e);
  }
}

/** actionReturnSale */
{
  try {
    /* Parsing */
    const { actionReturnSale } = R.evolve({
      actionReturnSale: {
        id: Number,
        returnRows: R.map(
          R.evolve({
            amount: Number,
          }),
        ),
      },
    })(urlParams);

    if (actionReturnSale) {
      /* Validation */
      if (!Number.isFinite(actionReturnSale.id))
        throw new Error('ID must be an integer');
      if (
        actionReturnSale.extraParams &&
        typeof actionReturnSale.extraParams !== 'object'
      )
        throw new Error('optional extraParams must be an object');

      if (!actionReturnSale.returnRows.every(row => row.returnReasonID)) {
        if (!actionReturnSale.returnReasonID) {
          throw new Error(
            'Require either a returnReasonID on every row, or a default returnReasonID for the whole document',
          );
        }
      }
      if (
        !actionReturnSale.returnRows.every(row =>
          Number.isFinite(Number(row.stableRowID)),
        )
      )
        throw new Error('returnRows stableRowIDs must be numbers');
      if (
        !actionReturnSale.returnRows.every(row => Number.isFinite(row.amount))
      )
        throw new Error('returnRows amounts must be numbers');

      /* Return */
      UrlControl.actionReturnSale = actionReturnSale;
    }
  } catch (e) {
    baseErr('Failed to parse actionReturnSale:', e);
  }
}

/** actionCancelLayaway */
{
  const id = Number(urlParams.actionCancelLayaway?.id);
  if (id) {
    if (Number.isFinite(id)) {
      UrlControl.actionCancelLayaway = { id };
    } else {
      baseErr(
        'failed to parse actionCancelLayaway:',
        'expected id to be a number but received',
        id,
      );
    }
  }
}

/** afterActionFinished */
{
  const { afterActionFinished } = urlParams;
  if (afterActionFinished) {
    switch (afterActionFinished) {
      case 'stay':
      case 'close':
        UrlControl.afterActionFinished = afterActionFinished;
        break;
      default:
        try {
          UrlControl.afterActionFinished = new URL(afterActionFinished);
        } catch (e) {
          baseErr(
            'Failed to parse afterActionFinished. Expected a valid URL, "stay", or "close", but received',
            afterActionFinished,
          );
        }
    }
  }
}

baseLog('Parsed UrlControl data is', UrlControl);
