import {
  SET_EXCHANGE_PROCESS_STEP,
  SET_ORIGINAL_SALES_DOCUMENT,
  SET_PRODUCTS_FOR_EXCHANGE,
} from './actionTypes';

export type ExchangeProcessState = {
  exchangeStep: number;
  itemsToExchange: any[];
  originalSalesDoc: { [key: string]: any };
};

export default (
  state = {
    exchangeStep: 0,
    itemsToExchange: [],
    originalSalesDoc: {},
  },
  action,
): ExchangeProcessState => {
  switch (action.type) {
    case SET_EXCHANGE_PROCESS_STEP:
      return { ...state, exchangeStep: action.payload };
    case SET_PRODUCTS_FOR_EXCHANGE:
      return {
        ...state,
        itemsToExchange: [...action.payload],
      };
    case SET_ORIGINAL_SALES_DOCUMENT:
      return {
        ...state,
        originalSalesDoc: { ...action.payload },
      };
    default:
      return state;
  }
};
