import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import InputField from 'components/FieldTypes/InputField';
import CloseButton from 'components/CustomButtons/CloseButton';
import { updateCurrentSaleDocument } from 'actions/sales';
import { getCurrentSalesDocument } from 'reducers/sales';

const LabelledInput = ({ placeholder, label, value, setter, ...rest }) => (
  <>
    <label style={{ fontSize: 18, fontWeight: 600 }}>{label}</label>
    <InputField
      as="textarea"
      placeholder={placeholder}
      rows="3"
      onChange={e => setter(e.target.value)}
      value={value}
      size="lg"
      {...rest}
    />
    <br />
  </>
);

const SaleNotes = () => {
  const { t } = useTranslation('saleNotes');
  const [receipt, setReceipt] = useState(
    useSelector(getCurrentSalesDocument).notes,
  );
  const [internal, setInternal] = useState(
    useSelector(getCurrentSalesDocument).internalNotes,
  );
  const dispatch = useDispatch();
  const close = () => dispatch(previousModalPage());
  const onDone = () => {
    dispatch(
      updateCurrentSaleDocument({
        notes: receipt,
        internalNotes: internal,
      }),
    );
    close();
  };
  return (
    <div data-testid="sales-notes-modal">
      <Modal.Header>
        <h3 style={{ fontWeight: 'bold' }}>{t('title')}</h3>
        <span style={{ flexGrow: 1 }} />
        <UIButton
          data-testid="confirm-btn"
          text={t('common:done')}
          action={onDone}
          variant="POS"
        />
        <CloseButton action={close} />
      </Modal.Header>
      <Modal.Body>
        <LabelledInput
          label={t('fields.receipt')}
          placeholder={t('fields.receipt', { context: 'placeholder' })}
          value={receipt}
          setter={setReceipt}
          data-testid="receipt-notes-input"
        />
        <LabelledInput
          label={t('fields.internal')}
          placeholder={t('fields.internal', { context: 'placeholder' })}
          value={internal}
          setter={setInternal}
          data-testid="internal-notes-input"
        />
      </Modal.Body>
    </div>
  );
};

export default SaleNotes;
