/* eslint-disable no-param-reassign,@typescript-eslint/camelcase */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable-next-line import/prefer-default-export */
import i18next from 'i18next';

import * as api from 'services/ErplyAPI/configs';
import { getTempSettings, getSavedSettings } from 'reducers/configs/settings';
import { getClientCode } from 'reducers/Login';

import * as c from '../constants/configs';
import {
  settingsFromApi,
  settingToApi,
} from '../reducers/configs/defaultSettings';

import { addWarning } from './Error';

/** Fetch and save in redux the currencies for this account */
export function getCurrency() {
  return async dispatch => {
    dispatch({ type: c.GET_CURRENCY });
    try {
      const data = await api.getCurrency();
      const hasDefaultCurrency = !!data?.find(cur => cur.default === '1');
      const dataFound = !!data.length;
      if (dataFound) {
        if (!hasDefaultCurrency) {
          dispatch(
            addWarning(
              i18next.t('alerts:noDefaultCurrencyError', {
                currencyCode: data[0].code,
              }),
            ),
          );
        }
      }
      if (!dataFound)
        dispatch(addWarning(i18next.t('alerts:noCurrenciesFound')));

      dispatch({ type: c.GET_CURRENCY_SUCCESS, data });
    } catch (err) {
      console.error('Unable to load currencies', err);
      dispatch({ type: c.GET_CURRENCY_ERROR, data: err });
    }
  };
}

/**
 * Fetch the settings for this account from Erply API
 * Save any missing settings back to the erply API
 * Save all the settings in redux
 * */
export function setSettings() {
  return async dispatch => {
    dispatch({ type: c.SET_SETTINGS });
    try {
      let [data] = await api.getConfParameters();
      data = settingsFromApi(data);

      const [defaultLang] = await api.getDefaultLanguage();

      const settings = {
        ...Object.assign(
          {},
          ...Object.entries(data)
            .filter(([key]) => !key.match(/^CONFIGURATION: /))
            .map(([key, value]) => {
              return { [key]: value };
            }),
        ),
        pos_default_language: defaultLang,
      };
      dispatch({ type: c.SET_SETTINGS_SUCCESS, data: settings });
    } catch (err) {
      console.error('Unable to load POS configuration parameters', err);
      dispatch({ type: c.SET_SETTINGS_ERROR, data: err });
    }
  };
}

/** Fetch and save in redux the objects from Erply API */
export function setObjects() {
  return async dispatch => {
    dispatch({ type: c.SET_OBJECTS.START });
    try {
      const objects = await api.getObjects();

      dispatch({ type: c.SET_OBJECTS.SUCCESS, data: objects });
    } catch (err) {
      console.error('Unable to load "objects"', err);
      dispatch({ type: c.SET_OBJECTS.ERROR, data: err });
    }
  };
}

/** Save an individual setting (key-value pair) in Erply API (or localStorage if it begins with 'CONFIGURATION: ' */
export function saveSetting(params) {
  return async (dispatch, getState) => {
    dispatch({ type: c.SAVE_SETTING });
    try {
      const match = params.parameterName.match(/^CONFIGURATION: (.+)/);
      if (match) {
        const [_, setting] = match;
        const key = `CONFIGURATION: ${getClientCode(getState())} - ${setting}`;
        dispatch({
          type: c.SAVE_SETTING_LOCALSTORAGE,
          data: {
            key,
            value:
              params.parameterValue === undefined
                ? undefined
                : JSON.stringify(params.parameterValue),
          },
        });
        dispatch({ type: c.SAVE_SETTING_SUCCESS, data: {} });
      } else {
        await api.saveConfParameter({
          parameterName: params.parameterName,
          parameterValue: settingToApi(
            params.parameterName,
            params.parameterValue,
          ),
        });
        dispatch({
          type: c.SAVE_SETTING_SUCCESS,
          data: { [params.parameterName]: params.parameterValue },
        });
      }
    } catch (err) {
      console.error('Unable to save setting', params, err);
      dispatch({ type: c.SAVE_SETTING_ERROR, data: err });
    }
  };
}

/**
 * Save a setting temporarily
 * All temporary settings can be saved permanently to the Erply API by dispatching {@link commitTempSettings}
 * or discarded by dispatching {@link dropTempSettings}
 *
 * Temporary settings are used the get a preview for the effect that changing a setting has while still being able to undo it if the user cancels out
 */
export function saveTempSetting(key, value) {
  return async (dispatch, getState) => {
    const existingValue = getSavedSettings(getState())[key];
    if (existingValue === value) {
      dispatch({
        type: c.TEMP_SETTINGS_DROP,
        payload: [key],
      });
    } else {
      dispatch({
        type: c.TEMP_SETTINGS_SET,
        payload: {
          [key]: value,
        },
      });
    }
  };
}

/** Permamently save all temporary settings */
export function commitTempSettings(keys) {
  return async (dispatch, getState) => {
    const toSave = Object.entries(getTempSettings(getState())).filter(
      ([key]) => keys.indexOf(key) >= 0,
    );

    await Promise.all(
      toSave
        .map(([k, v]) => ({
          parameterName: k,
          parameterValue: v,
        }))
        // TODO: a bulk 'saveSettings' action that sends all requests before updating redux in one go
        //  This way if any settings might cause a POS refresh (version control, logout on inactivity, etc.),
        //  we still finish all the requests first before that happens
        //  It would also optimize the number of actions dispatched to redux
        .map(saveSetting)
        .map(dispatch),
    );

    dispatch({ type: c.TEMP_SETTINGS_DROP, payload: keys });
  };
}

/** Undo all temporary settings */
export function dropTempSettings(keys) {
  return {
    type: c.TEMP_SETTINGS_DROP,
    payload: keys,
  };
}

export function setPosLanguage(lang) {
  return saveSetting({
    parameterName: 'CONFIGURATION: lang',
    parameterValue: lang,
  });
}
