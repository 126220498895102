import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import * as R from 'ramda';

import { getProductsUniversal } from 'actions/productsDB';
import { createConfirmation } from 'actions/Confirmation';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { getLastProductIndex } from 'reducers/ShoppingCart';
import { addError } from 'actions/Error';

import { baseLog, TEXTS } from './meta';
import {
  getMainWarehouseId,
  getWarrantyGroupId,
  getWarrantyRatesForGroup,
} from './selectors';

const promptAddWarranty = (p: {
  warrantyName: string;
  priceWithVat: number;
}) => async dispatch =>
  new Promise((resolve, reject) =>
    dispatch(
      createConfirmation(resolve, reject, {
        title: TEXTS.confirmation(p.warrantyName, p.priceWithVat),
        confirmText: TEXTS.confirmationYes,
        cancelText: TEXTS.confirmationNo,
        body: ' ',
        noReject: false,
      }),
    ),
  );
/**
 * Check the related products for any warranties
 * and check the product group hierarchy for warranty pricing information
 *
 * If both are present, prompt cashier whether they want to add warranty
 * If they choose yes, attach the warranty productID as 'pluginOnoffUseWarranty' property
 */
export const promptToAddRelatedWarranty = productID => async (
  dispatch: ThunkDispatch<any, any, Action>,
  getState,
) => {
  const log = baseLog.extend('getRelatedWarrantyIdElseThrow');

  log('Checking product for warranties...');
  log('Loading product information');
  const warehouseID = getMainWarehouseId(getState());
  if (warehouseID === -1) throw new Error('warehouse id not configured');

  const {
    products: [prod],
  } = await dispatch(
    getProductsUniversal(
      { productID, getRelatedProducts: 1, getPriceListPrices: 1, warehouseID },
      // we must ask with localFirst: false because
      // products are synced without relatedProduct information
      // If we don't, we get cached data even though the cache is missing that field
      { localFirst: false },
    ),
  );
  log('Got product information', prod);
  if (prod.relatedProducts?.length === 0)
    throw new Error('No related products to check');

  log('Loading related product information', prod.relatedProducts);
  const { products: relatedProducts } = await dispatch(
    getProductsUniversal({
      productIDs: prod.relatedProducts?.map(Number) ?? [],
    }),
  );
  log('Got related products', relatedProducts);
  const warrantyGroupId = getWarrantyGroupId(getState());
  const warrantyProducts = relatedProducts.filter(
    p => Number(p.groupID) === warrantyGroupId,
  );
  log('Of those, filtering out warranties by group id', warrantyGroupId);
  log('Leaving us with', warrantyProducts);
  if (warrantyProducts.length === 0)
    throw new Error('None of the related products are warranty products');

  const rate = getWarrantyRatesForGroup(prod.groupID)(getState());
  log('Determining the warranty rate for the current product:', rate);
  if (!rate) {
    dispatch(addError(TEXTS.error.attributesNotFound));
    throw new Error(
      'No warranty rate information found in product group hierarchy',
    );
  }

  const warranty = warrantyProducts[0];
  log('Found, Prompting user about warranty product', warranty, rate);
  const calculatedPrice = Math.floor(
    rate.basePrice + prod.priceListPriceWithVat * rate.percentage * 0.01,
  );
  const userChoseToAdd = await dispatch(
    promptAddWarranty({
      warrantyName: warranty.name,
      priceWithVat: calculatedPrice,
    }),
  ).then(R.always(true), R.always(false));
  if (!userChoseToAdd) throw new Error('User chose to not add warranty');

  const calculatedPriceWithoutVat =
    calculatedPrice / (0.01 * (100 + prod.vatrate));
  log(
    'Adding warranty product',
    warranty,
    calculatedPrice,
    calculatedPriceWithoutVat,
  );
  dispatch(
    addProduct({
      productID: warranty.productID,
      price: calculatedPriceWithoutVat,
      parentRowID: getLastProductIndex(getState()),
    }),
  );
};
