import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';

import {
  ComponentConfiguration,
  ComponentHeader,
  endlessAisleComponents,
} from './components';
import { defaultValues, pluginID } from './constants';
import { Configuration } from './types';
import documentation from './documentation.md';
import {
  onSaveSalesDocument,
  beforeUpdateOrderAmount,
  onAddProduct,
  beforeAddProduct,
  beforeOpenPaymentModal,
} from './hooks';

const endlessAisle: PosPlugin<Configuration> = {
  id: pluginID,
  name: 'Endless Aisle',
  infoMDUrl: documentation,
  getStatus: state => {
    const conf = getPluginConfiguration<Configuration>(pluginID)(state);

    const allValuesFilled = Object.values(conf).every(Boolean);

    return {
      message: allValuesFilled
        ? 'Ready'
        : 'Please fill all values in configuration',
      type: allValuesFilled ? 'valid' : 'error',
    };
  },
  ComponentConfigurationByLevel: {
    Company: ComponentConfiguration,
  },
  components: {
    ...endlessAisleComponents,
  },
  combineConfiguration: c => c ?? defaultValues,
  ComponentHeader,
  onAddProduct: {
    on: onAddProduct,
    before: beforeAddProduct,
  },
  onOpenPaymentModal: {
    before: beforeOpenPaymentModal,
  },
  onUpdateOrderAmount: {
    before: beforeUpdateOrderAmount,
  },
  onSaveSalesDocument: {
    on: onSaveSalesDocument,
  },
};

export default endlessAisle;
