export const FETCH_STARTED = 'warehouses/FETCH_STARTED';
export const FETCH_SUCCESS = 'warehouses/FETCH_SUCCESS';
export const FETCH_FAILURE = 'warehouses/FETCH_FAILURE';

// getWarehouses
export const SELECT_WAREHOUSE = 'warehouses/SELECT_WAREHOUSE';
export const DESELECT_WAREHOUSE = 'warehouses/DESELECT_WAREHOUSE';

// getHomeStores
export const FETCH_HOMESTORES_SUCCESS = 'warehouses/FETCHED_HOME_STORES';
