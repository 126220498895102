import React from 'react';

import { dismissType } from 'actions/Error';
import { posSendMessage, posAddOnMessage } from 'utils/hooks/useWrapper';
import { ZReportsApi } from 'services/Reports';
import { getClientCode, getSessionKey } from 'reducers/Login';
import { getEndpointForService } from 'reducers/configs/serviceEnpoints';
import { getPosLoggedIn } from 'reducers/PointsOfSale';
import { proxy } from 'services/shared';

export function sendOpenCashDrawerToWrapper() {
  return async () => {
    window.wrapperMessageChannel.port1.postMessage({
      type: 'printing:openCashDrawer',
      payload: {},
    });
  };
}

export function sendZReportDataToWrapper({ data, link, html }) {
  return async (
    dispatch,
    getState,
  ) => {
    if (!window.wrapperMessageChannel) {
      return;
    }
    // TODO: Distant future
    // posSendMessage('printing:zReportNewschema')({schema:composeReceiptWithSchema});
    const api = new ZReportsApi(
      {},
      `${proxy}${getEndpointForService('reports').url.replace(/.$/g, '')}`,
      window.fetch,
    );
    const sk = getSessionKey(getState());
    const cc = getClientCode(getState());
    const start = 1;
    const end = 9999999999;
    const posID = getPosLoggedIn(getState()).pointOfSaleID;
    const params = [
      sk,
      cc,
      1,
      start,
      end,
      posID,
      undefined,
      undefined,
      undefined,
      undefined,
    ];
    const [day, transactions, cashflow, totalByType] = await Promise.all([
      api.v1POSDayGet(...params).then(a => a.data),
      api.v1POSDayTransactionGet(...params).then(a => a.data),
      api.v1POSDayTransactionCashflowGet(...params).then(a => a.data),
      api.v1POSDayTransactionTotalByTypeGet(...params).then(a => a.data),
    ]);
    try {
      const newData = { day, transactions, cashflow, totalByType };
      posSendMessage('printing:zReportData')(newData);
      if (link) posSendMessage('printing:printLink')({ link });
      if (html) posSendMessage('printing:printHtml')({ html });
    } catch (e) {
      console.error('Failed to send printing data to wrapper', e);
    }
  };
}

export function sendPatchscriptToWrapper(data) {
  return async (dispatch, getState) => {
    const stringifyTexts = obj => {
      if (obj?.constructor === Object) {
        return Object.fromEntries(
          Object.entries(obj).map(([k, v]) =>
            k === 'text' ? [k, String(v)] : [k, stringifyTexts(v)],
          ),
        );
      }
      if (obj?.map) {
        return obj.map(stringifyTexts);
      }
      return obj;
    };
    const sanitizedData = stringifyTexts(data);
    return posSendMessage('printing:patchScript')({ patchScript: sanitizedData });
  };
}

export function sendReceiptDataToWrapper({ receiptLink, data }, options) {
  return async (dispatch, getState) => {
    if (!window.wrapperMessageChannel) {
      return () => {
        // do nothing
      };
    }

    posSendMessage('printing:receiptData')(
      JSON.parse(JSON.stringify({ data, options })),
    );
    return posSendMessage('printing:receiptLink')({
      receiptLink: `${receiptLink}&giftreceipt=${options.giftReceipt ? 1 : 0}`,
    });
    // @TODO update the handler for printing from wrapper once its implemented
    const remove = posAddOnMessage('printing:done', data => {
      dispatch(dismissType('receipt_print'));
      remove();
    });
  };
}
