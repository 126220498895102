export const UPDATE_INSTALLER = {
  START: 'UPDATE_INSTALLER_START',
  SUCCESS: 'UPDATE_INSTALLER_SUCCESS',
  ERROR: 'UPDATE_INSTALLER_ERROR',
};

export const SET_MS_LOCAL_ENDPOINT = 'SET_MS_LOCAL_ENDPOINT';

export const FETCH_INSTALLER_INFO = {
  START: 'FETCH_INSTALLER_INFO_START',
  SUCCESS: 'FETCH_INSTALLER_INFO_SUCCESS',
  ERROR: 'FETCH_INSTALLER_INFO_ERROR',
};

export const FETCH_AVAILABLE_MS = {
  START: 'FETCH_AVAILABLE_MS_START',
  SUCCESS: 'FETCH_AVAILABLE_MS_SUCCESS',
  ERROR: 'FETCH_AVAILABLE_MS_ERROR',
};

export const FETCH_MS_VERSIONS = {
  START: 'FETCH_MS_VERSIONS_START',
  SUCCESS: 'FETCH_MS_VERSIONS_SUCCESS',
  ERROR: 'FETCH_MS_VERSIONS_ERROR',
};

export const INSTALLER_REMOVE_MS = {
  START: 'INSTALLER_REMOVE_MS_START',
  SUCCESS: 'INSTALLER_REMOVE_MS_SUCCESS',
  ERROR: 'INSTALLER_REMOVE_MS_ERROR',
};

export const INSTALLER_INSTALL_MS = {
  START: 'INSTALLER_INSTALL_MS_START',
  SUCCESS: 'INSTALLER_INSTALL_MS_SUCCESS',
  ERROR: 'INSTALLER_INSTALL_MS_ERROR',
};

export const INSTALLER_START_MS = {
  START: 'INSTALLER_START_MS_START',
  SUCCESS: 'INSTALLER_START_MS_SUCCESS',
  ERROR: 'INSTALLER_START_MS_ERROR',
};

export const INSTALLER_STOP_MS = {
  START: 'INSTALLER_STOP_MS_START',
  SUCCESS: 'INSTALLER_STOP_MS_SUCCESS',
  ERROR: 'INSTALLER_STOP_MS_ERROR',
};

export const INSTALLER_FETCH_MS_CONFIG = {
  START: 'INSTALLER_FETCH_MS_CONFIG_START',
  SUCCESS: 'INSTALLER_FETCH_MS_CONFIG_SUCCESS',
  ERROR: 'INSTALLER_FETCH_MS_CONFIG_ERROR',
};

export const INSTALLER_SET_MS_CONFIG = {
  START: 'INSTALLER_SET_MS_CONFIG_START',
  SUCCESS: 'INSTALLER_SET_MS_CONFIG_SUCCESS',
  ERROR: 'INSTALLER_SET_MS_CONFIG_ERROR',
};

export const integrationMapper = {
  chase: {
    id: 'chase',
    name: 'Chase',
    type: 'payment',
    entity: '',
  },
  wrapper: {
    id: 'wrapper',
    name: 'Wrapper',
    type: 'payment',
    entity: '',
  },
  netsCloud: {
    id: 'netsCloud',
    name: 'Nets Finland',
    type: 'payment',
    entity: '',
  },
  adyen: {
    id: 'adyen',
    name: 'Adyen',
    type: 'payment',
    entity: 'adyen-integration',
  },
  verifone: {
    id: 'verifone',
    name: 'Verifone',
    type: 'payment',
    entity: 'verifone-fin',
  },
  triPOS: {
    id: 'triPOS',
    name: 'TriPOS',
    type: 'payment',
    entity: 'vantiv-tripos-golang-microservice',
  },
  fiscalRegister: {
    id: 'fiscalRegister',
    name: 'Fiscal Register France',
    type: 'fiscalRegister',
    entity: 'transaction-chain',
  },
  miniScale: {
    id: 'miniScale',
    name: 'Mini Scale',
    type: 'scale',
    entity: 'sweda34f-scale',
  },
  pcEftpos: {
    id: 'pcEftpos',
    name: 'PC-Eftpos',
    type: 'payment',
    entity: 'pc-eftpos-integration',
  },
  cayan: {
    id: 'cayan',
    name: 'Cayan',
    type: 'payment',
    entity: 'cayan-integration',
  },
  swedbank: {
    id: 'swedbank',
    name: 'Swedbank Ingenico',
    type: 'payment',
    entity: 'swedbank-ingenico',
  },
  /* linkpos: {
    id: 'linkpos',
    name: 'Linkpos (Thai)',
    type: 'payment',
    entity: 'linkpos-integration',
  }, */
  givexDirectDriver: {
    id: 'givexDirectDriver',
    name: 'Givex Direct driver',
    type: '',
    entity: 'givex-direct-driver',
  },
  junction: {
    id: 'junction',
    name: 'Junction',
    type: 'payment',
    entity: 'pos-junction-integration',
  },
  extdevmicroservice: {
    id: 'extdev',
    name: 'extdev',
    type: '',
    entity: 'extdev-microservice',
  },
  goMS: {
    id: 'goMS',
    name: 'Go Printing MS',
    type: 'printer',
    entity: 'printing-microservice',
  },
  pax: {
    id: 'pax',
    name: 'Pax Heartland',
    type: 'payment',
    entity: 'pax-poslink-integration',
  },
  moneris: {
    id: 'moneris',
    name: 'Moneris',
    type: 'payment',
    entity: 'moneris-integration',
  },
  shift4: {
    id: 'shift4',
    name: 'shift4',
    type: 'payment',
    entity: 'local-proxy-ms',
  },
  metricsCollector: {
    id: 'metrics-collector-ms',
    name: 'Metrics collector',
    type: '',
    entity: 'metrics-collector-ms',
  },
  /**
   * Plugin specific, needs to be refactored and moved out of core code
   */
  vii: {
    id: 'vii',
    name: 'vii',
    type: 'payment',
    entity: 'local-proxy-ms',
  },
  /**
   * Plugin specific, needs to be refactored and moved out of core code
   */
  nsw: {
    id: 'nsw',
    name: 'nsw',
    type: 'payment',
    entity: 'local-proxy-ms',
  },
  receiptPrintoutApi: {
    id: 'receipt-printout-api',
    name: 'Receipt Printout API',
    type: 'printer',
    entity: 'receipt-printout-api',
  },
  'logger-ms': {
    id: 'logger-ms',
    name: 'Logger MS',
    type: '',
    entity: 'logger-ms',
  },
} as const;

export const entityMapper = Object.fromEntries(
  Object.entries(integrationMapper)
    .filter(([k, v]) => v.entity)
    .map(([key, value]) => [value.entity, { ...value, integration: key }]),
);
