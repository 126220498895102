import React from 'react';

import { PluginComponent } from 'plugins';
import TableTotal from 'components/tableTotal/TableTotal';

import './tableTotalContainer.css';

const TableTotalContainer = () => {
  return (
    <div className="table-total-container">
      <PluginComponent hookname="UITableTotalRow">
        <TableTotal />
      </PluginComponent>
    </div>
  );
};

export default React.memo(TableTotalContainer);
