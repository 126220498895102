import { v4 as uuidv4 } from 'uuid';

import { getCafaEntry } from 'reducers/cafaConfigs';
import { getCardPaymentsForIntegration } from 'reducers/Payments';
import { markForProcessing, deletePayment } from 'actions/Payments';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { getMSEndpointFromLS } from 'reducers/installer';
import { getClientCode } from 'reducers/Login';
import { withWaitingForTerminal } from 'paymentIntegrations';

import { handleErrors, withResponseErrors } from './errors';

export const title = 'PC-Eftpos Terminal Interface';

const handleSuccess = async ({
  payment,
  response,
  beforeDocSave,
  beforeDocDelete,
  updateMessage,
  displaySuccess,
  mode = 'sale',
}) => {
  if (response.transactionStatus.indexOf('APPROVED') >= 0) {
    updateMessage(`Transaction successful!`);
    displaySuccess(`Transaction successful!`);
    if (mode === 'sale' || mode === 'refund') {
      await beforeDocSave({
        key: payment.key,
        paid: true,
        type: 'CARD',
        amount:
          mode === 'refund'
            ? -Math.abs(response.approvedAmount)
            : response.approvedAmount,
        cardType: response.paymentType,
        cardHolder: response.cardHolder,
        cardNumber: response.cardNumber,
        signature: response.signature || null,
        attributes: {
          aid: response.aid,
          authCode: response.authCode,
          paymentType: response.paymentType,
          dateTime: response.dateTime,
          referenceNumber: response.referenceNumber,
        },
      });
    } else if (mode === 'void') {
      await beforeDocDelete(payment.key);
    }
  } else {
    throw new Error('Transaction failed');
  }
};

const makeRequest = ({
  request,
  params,
  mode,
  payment,
  beforeDocSave,
  beforeDocDelete,
  updateMessage,
  endpoint,
  enableButtons,
  displaySuccess,
  displayError,
}) => async dispatch => {
  return dispatch(
    withWaitingForTerminal(() => {
      return new Promise((resolve, reject) => {
        withResponseErrors({
          request,
          endpoint,
          params,
        })
          .then(
            async res => {
              await handleSuccess({
                payment,
                response: res.data.records[0],
                beforeDocSave,
                updateMessage,
                enableButtons,
                beforeDocDelete,
                displaySuccess,
                mode,
              });
              resolve(res);
            },
            err => {
              throw err;
            },
          )
          .catch(err => {
            handleErrors({
              err,
              displayError,
              updateMessage,
              enableButtons,
            });
            reject(err);
          });
      });
    }),
  );
};

export const initPayments = ({
  updateMessage,
  cardPayments,
  enableButtons,
  resolvePayments,
  beforeDocSave,
  beforeDocDelete,
  displayError,
  rejectPayments,
  displaySuccess,
}) => async (dispatch, getState) => {
  const clientCode = getClientCode(getState());
  const { value: configuration } = getCafaEntry('pcEftpos')(getState()) ?? {};
  const endpoint = getMSEndpointFromLS('pcEftpos');
  // console.log("InitPayments within pceftpos",{cardPayments});
  for (let i = 0; i < cardPayments.length; i++) {
    const { amount, shouldProcess, paid, attributes = {} } = cardPayments[i];
    const { referenceNumber } = attributes;
    const mode = Number(amount) < 0.01 ? 'refund' : 'sale';

    if (mode === 'sale') {
      // Handle regular sale
      if (shouldProcess && paid) {
        // console.log("VOIDING ", cardPayments[i]);
        updateMessage(`Processing card payment of ${amount}...`);
        await dispatch(
          makeRequest({
            request: 'requestVoid',
            endpoint,
            params: {
              requestID: uuidv4(),
              clientCode,
              amount: `${amount}`,
              configuration,
              referenceNumber,
            },
            payment: cardPayments[i],
            beforeDocSave,
            beforeDocDelete,
            updateMessage,
            enableButtons,
            displaySuccess,
            displayError,
            mode: 'void',
          }),
        );

        // console.log("Voided ", cardPayments[i] );
      } else {
        // console.log("PAYING ", cardPayments[i]);
        updateMessage(`Processing card payment of ${amount}...`);
        await dispatch(
          makeRequest({
            request: 'requestSale',
            endpoint,
            params: {
              requestID: uuidv4(),
              clientCode,
              amount: `${amount}`,
              configuration,
            },
            payment: cardPayments[i],
            beforeDocSave,
            updateMessage,
            enableButtons,
            displaySuccess,
            displayError,
            mode: 'sale',
          }),
        );
        // console.log("PAID ", cardPayments[i] );
      }
    } else if (mode === 'refund') {
      // console.log("REFUNDING ", cardPayments[i]);
      updateMessage(`Processing card payment of ${amount}...`);
      await dispatch(
        makeRequest({
          request: 'requestRefund',
          endpoint,
          params: {
            requestID: uuidv4(),
            clientCode,
            amount: `${amount}`,
            configuration,
            referenceNumber,
          },
          payment: cardPayments[i],
          beforeDocSave,
          updateMessage,
          enableButtons,
          displaySuccess,
          displayError,
          mode: 'refund',
        }),
      );
    }
  }

  const shouldVoid = cardPayments.every(pmt => pmt.shouldProcess && pmt.paid);
  if (shouldVoid) {
    rejectPayments();
  }

  resolvePayments();
};

/**
 * Void current successful payments in case the user wants to abort the current purchase
 */
export const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('pcEftpos')(getState());
  cardPayments.forEach(({ key, paid }) =>
    paid
      ? dispatch(markForProcessing({ key }))
      : dispatch(deletePayment({ key })),
  );

  const finalPayments = getCardPaymentsForIntegration('pcEftpos')(getState());
  if (finalPayments.length) {
    await dispatch(openTerminalIntegration());
  }
};
export const retryPayment = params => async (dispatch, getState) => {
  params.enableButtons();
  const cardPayments = getCardPaymentsForIntegration('pcEftpos')(
    getState(),
  ).filter(pmt => !pmt.paid);
  await dispatch(initPayments({ ...params, cardPayments }));
};

export const returnBack = params => async () => params.rejectPayments();

export const functions = [
  {
    actionOnClick: retryPayment,
    text: 'Retry',
    name: 'retry-payment',
    variant: 'success',
  },
  {
    actionOnClick: returnBack,
    text: 'Return back',
    name: 'return-back',
    variant: 'info',
  },
];
