export const comapnyInitState = () => {};

export const form = {
  company: [
    { title: 'Name', key: 'name', type: 'text' },
    { title: 'Reg. Code', key: 'code', type: 'text' },
    { title: 'Tin No.', key: 'VAT', type: 'text' },
    { title: 'Client Code', key: 'cc', type: 'text', disabled: true },
  ],
  location: [
    { title: 'Shop Name', key: 'posName', type: 'text', disabled: true },
    { title: 'Warehouse', key: 'warehouseName', type: 'text' },
    { title: 'Phone', key: 'phone', type: 'text' },
  ],
  address: [
    {
      key: 'street',
      type: 'text',
      placeholder: 'ADDRESS LINE 1',
      // style: { width: '100%' },
    },
    {
      key: 'address2',
      type: 'text',
      placeholder: 'ADDRESS LINE 2',
      // style: { width: '70%' },
    },
    {
      key: 'zip',
      type: 'text',
      placeholder: 'ZIP',
      // style: { width: '30%' },
    },
    {
      key: 'city',
      type: 'text',
      placeholder: 'CITY',
      // style: { width: '40%' },
    },
    {
      key: 'state',
      type: 'text',
      placeholder: 'STATE',
      // style: { width: '30%' },
    },
    {
      key: 'country',
      type: 'text',
      placeholder: 'COUNTRY',
      // style: { width: '40%' },
    },
  ],
};
