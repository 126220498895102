import React, { ReactChild, useContext } from 'react';
import * as R from 'ramda';
import i18next from 'i18next';

import { PosPlugin } from 'plugins/plugin';
import { FormContext } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';

import LanguageField from './languageField';
import { SeniorField } from './seniorStatus';
import LoyaltyCardNumberField from './customerCardNumber';
import { SuspendedField } from './suspendedField';
import EmailField from './email/EmailField';

type Children = ReturnType<typeof React.Children.toArray>;

const UICustomerFormBeta: Required<PosPlugin>['UICustomerFormBeta'] = ({
  customerType,
  customer,
  handleChange,
  onChange,
  children,
}) => {
  const { config } = useContext(FormContext);

  /**
   * Adds 'language' field
   */
  const addLanguageField = R.insert(
    5,
    <LanguageField
      customer={(customer as unknown) as { language: string }}
      handleChange={handleChange}
    />,
  );

  /**
   * If 'customerCardNumber' field is in the right side column,
   * swaps it with the next field so that it ends up on the left instead
   */
  const moveCardNoToLeftColumn = (fields: Children): Children => {
    /**
     * Disabled fields still exist in fields, they just render as null
     * Fields that are not present in context (e.g. added by plugin) count as enabled
     */
    const isEnabledInConfig = R.pipe(
      R.pipe(
        R.path(['props', 'name']),
        R.prop(R.__, config),
        R.either(R.prop('enabled'), R.isNil),
      ),
    );
    const visibleItems: [ReactChild, number][] = R.pipe(
      // Attach indexes of original list
      R.addIndex(R.map)((item, index) => [item, index]),
      // Filter out visible items
      R.filter(([item]) => isEnabledInConfig(item)),
    )(fields);
    const visibleIndex = visibleItems.findIndex(([item]) =>
      R.pathEq(['props', 'name'], 'customerCardNumber', item),
    );

    if (visibleIndex < 0) return fields;
    if (visibleIndex % 2 === 0) return fields; // Already in left column

    // Swap with next (or previous) visible item
    const targetIndex = visibleItems[visibleIndex][1];
    const nextIndex =
      visibleItems[visibleIndex + 1]?.[1] ??
      visibleItems[visibleIndex - 1]?.[1];
    return R.move(targetIndex, nextIndex, fields);
  };

  /**
   * Replaces 'customerCardNumber' field with our custom implementation
   */
  const replaceCardNumberField: (children: Children) => Children = R.map(
    R.when(
      R.pathEq(['props', 'name'], 'customerCardNumber'),
      R.always(
        <LoyaltyCardNumberField
          customer={customer}
          handleChange={handleChange}
          onChange={onChange}
        />,
      ),
    ),
  );

  /**
   * Replace "email field"
   */

  const replaceEmailField: (children: Children) => Children = R.map(
    R.when(
      R.pathEq(['props', 'name'], 'email'),
      R.always(<EmailField customer={customer} onChange={onChange} />),
    ),
  );

  /**
   * Replaces 'doNotSell' field with our custom implementation
   */
  const replaceDoNotSellField: (children: Children) => Children = R.map(
    R.when(
      R.pathEq(['props', 'name'], 'doNotSell'),
      R.always(
        <SuspendedField
          suspended={Boolean(customer?.suspended ?? customer?.doNotSell)}
          handleChange={handleChange}
        />,
      ),
    ),
  );

  /**
   * Change error message for first and last name if input empty
   */
  const modifyNameErrorMessage: (children: Children) => Children = R.map(
    R.when(
      R.both(
        R.either(
          R.pathEq(['props', 'name'], 'firstName'),
          R.pathEq(['props', 'name'], 'lastName'),
        ),
        R.pathEq(['props', 'children', 'props', 'value'], ''),
      ),
      R.assocPath(
        ['props', 'children', 'props', 'helperText'],
        i18next.t('createCustomer:errorText.name.requiredField'),
      ),
    ),
  );

  /**
   * validate phone number
   *
   * number must be 10 characters long and consist of numbers only
   */
  function validatePhone(phoneNumber: string, type: 'phone' | 'mobile') {
    const isEmpty = phoneNumber.length === 0;

    if (isEmpty) {
      return {
        isValid: true,
        message: null,
      };
    }

    const properLength = phoneNumber.length === 10;
    if (!properLength) {
      return {
        isValid: false,
        message: i18next.t(`createCustomer:errorText.${type}.invalidLength`),
      };
    }

    const isValidNumber = /^\d+$/.test(phoneNumber);
    if (!isValidNumber) {
      return {
        isValid: false,
        message: i18next.t(`createCustomer:errorText.${type}.invalidFormat`),
      };
    }

    return {
      isValid: true,
      message: null,
    };
  }

  function setError(field: unknown) {
    const phoneNumber = R.path(['props', 'children', 'props', 'value'], field);
    const type = R.path(['props', 'name'], field);

    const { isValid, message } = validatePhone(phoneNumber, type);
    return R.unless(
      R.always(isValid),
      R.pipe(
        R.assocPath(['props', 'children', 'props', 'error'], true),
        R.assocPath(['props', 'children', 'props', 'helperText'], message),
      ),
    )(field);
  }

  /**
   * Add validation to phone and mobile fields
   */
  const addValidationToPhoneNumbers: (children: Children) => Children = R.map(
    R.when(
      R.either(
        R.pathEq(['props', 'name'], 'mobile'),
        R.pathEq(['props', 'name'], 'phone'),
      ),
      setError,
    ),
  );

  /**
   * Add validation to birthday
   */
  const addValidationToBirthday: (children: Children) => Children = R.map(
    R.when(
      R.both(
        R.pathEq(['props', 'name'], 'birthday'),
        R.pathEq(['props', 'children', 'props', 'error'], true),
      ),
      R.assocPath(
        ['props', 'children', 'props', 'helperText'],
        i18next.t('createCustomer:errorText.birthday.empty'),
      ),
    ),
  );

  /**
   * Adds 'Senior' checkbox
   */
  const addSeniorField = R.append(
    <SeniorField
      customer={(customer as unknown) as typeof customer & { senior?: 0 | 1 }}
      handleChange={handleChange}
    />,
  );

  return R.pipe(
    React.Children.toArray,
    replaceEmailField,
    addLanguageField,
    customerType === 'PERSON' ? addSeniorField : R.identity,
    customer.id ? R.identity : moveCardNoToLeftColumn,
    // Assuming MM always uses Customer Registry instead of Erply API for customers
    replaceDoNotSellField,
    replaceCardNumberField,
    modifyNameErrorMessage,
    addValidationToPhoneNumbers,
    addValidationToBirthday,
  )(children);
};

export default UICustomerFormBeta;
