export const pluginPrefix = 'PLUGIN:TAF_CUSTOM:';
export const SELECTED_ENTITY_TYPE = `${pluginPrefix}SELECTED_ENTITY_TYPE`;
export const SET_GLOBAL_ENTITY_BY_TYPE = `${pluginPrefix}SET_GLOBAL_ENTITY_BY_TYPE`;
export const SET_PRODUCTS_STATE = `${pluginPrefix}SET_PRODUCTS_STATE`;
export const SET_CUSTOMER_LAST_USED_ENTITIES = `${pluginPrefix}SET_CUSTOMER_LAST_USED_ENTITIES`;
export const DISPLAY_PREVIOUS_ENTITIES = `${pluginPrefix}DISPLAY_PREVIOUS_ENTITIES`;
export const SET_FIT_ID = `${pluginPrefix}SET_FIT_ID`;
export const RESET_STATE = `${pluginPrefix}RESET_STATE`;
export const SET_PAYMENT_TYPE_IDS = `${pluginPrefix}: SET_PAYMENT_TYPE_IDS`;
export const RESET_VOUCHERS = `${pluginPrefix}RESET_VOUCHERS`;
export const SET_VOUCHERS = `${pluginPrefix}SET_VOUCHERS`;
export const SET_CUSTOMER_ID_TAF = `${pluginPrefix}SET_CUSTOMER_ID_TAF`;
export const RESET_CUSTOMER_ID_TAF = `${pluginPrefix}RESET_CUSTOMER_ID_TAF`;
export const SET_PRINT_SECOND_RECEIPT = `${pluginPrefix}SET_DOUBLE_PRINT_STEP`;
export const SET_COUPONS = `${pluginPrefix}SET_COUPONS`;
export const SET_QFF_CARD_NUMBER = `${pluginPrefix}SET_QFF_CARD_NUMBER`;
