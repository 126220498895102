import { createSelector } from 'reselect';

import { FETCH_SERVICE_ENDPOINTS } from 'constants/serviceEndpoints';
import { REDUX_SERVICE_ENDPOINTS } from 'constants/persistence';
import { getClientCode } from 'reducers/Login';

import { getCurrentPosLanguage } from './settings';

const initState = [];

export default (state = initState, { type, payload }) => {
  if (type === FETCH_SERVICE_ENDPOINTS.SUCCESS) {
    localStorage.setItem(REDUX_SERVICE_ENDPOINTS, JSON.stringify(payload));
    return payload;
  }
  return state;
};

export const getServiceEndpoints = createSelector(
  () => localStorage.getItem(REDUX_SERVICE_ENDPOINTS) ?? 'null',
  str => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  },
);

const unknown = {};

// TODO: Add the empty object to the declared return type and see what else breaks
export function getEndpointForService(
  name: string,
): { url: string; documentation: string } {
  return (
    // Reselect selectors will recalculate only if the passed in state has changed
    //   So omitting the state argument, or passing in some constant will not work
    //   Thus we pass in a unique symbol
    (getServiceEndpoints(Symbol('uniq')) ?? {})[name] || unknown
  );
}

export function getCAFAEndpoint() {
  return getEndpointForService('cafa');
}

export const getGoErpTemplateBaseUrl = createSelector(
  () => getEndpointForService('goerp'),
  state => getCurrentPosLanguage(state),
  getClientCode,
  (endpoint, lang, clientCode) => {
    try {
      const url = new URL(endpoint?.url);
      return `${url.origin}/${clientCode}/${lang}`;
    } catch (e) {
      return null;
    }
  },
);
