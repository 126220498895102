import { getSelectedProductGroupID } from 'reducers/UI/gridDisplay';
import { TYPE_LOGOUT } from 'constants/Login';
import { createSelector } from 'reselect';
import * as c from '../constants/productGroupsDB';
import { getCafaEntry } from './cafaConfigs';

const DEFAULT_GROUP = { subGroups: [] };

export default (state = { '0': DEFAULT_GROUP }, { type, payload }) => {
  switch (type) {
    case c.SAVE_PRODUCT_GROUPS_SUCCESS:
      return {
        ...state,
        [payload.productGroupID || 0]: payload,
      };
    case c.GET_PRODUCT_GROUPS_SUCCESS:
      return {
        ...state,
        ...payload,
        '0': {
          subGroups: [
            ...new Set([
              ...state['0'].subGroups,
              ...Object.values(payload)
                .filter(gr => gr.parentGroupID === '0')
                .sort((a, b) => a.positionNo - b.positionNo)
                .map(gr => gr.id),
            ]),
          ],
        },
      };
    case TYPE_LOGOUT:
      return { '0': DEFAULT_GROUP };
    default:
      return state;
  }
};

export function getProductGroupsDBSlice(state) {
  return state.productGroupsDB;
}

export function getProductGroupByID(id) {
  return state => state.productGroupsDB[id] || {};
}

export const getSelectedProductGroup = createSelector(
  getProductGroupsDBSlice,
  getSelectedProductGroupID,
  (groups, currentGroupID) => groups[currentGroupID] ?? null,
);

export function getDefaultProductGroupID(state) {
  return getProductGroupsDBSlice(state)[0]?.subGroups?.[0];
}

export const getProductGroups = createSelector(
  state => getProductGroupsDBSlice(state),
  productGroupSlices => {
    return Object.values(productGroupSlices ?? {})
      .filter(gr => gr.id)
      .sort((a, b) => a.positionNo - b.positionNo);
  },
);

export function getProductGroupSubgroups(id) {
  return state =>
    getProductGroupByID(id)(state).subGroups
      ? getProductGroupByID(id)(state).subGroups.map(id =>
          getProductGroupByID(id)(state),
        )
      : [];
}

export const getSelectedProductGroupSubgroups = createSelector(
  getProductGroupsDBSlice,
  getSelectedProductGroupID,
  getCafaEntry('posHiddenProductGroups', ''),
  (groups, selectedGroupID, hiddenGroupsConfig) => {
    const hiddenGroups = hiddenGroupsConfig?.value?.hiddens ?? {};
    return (
      groups[selectedGroupID]?.subGroups
        ?.filter(groupId => hiddenGroups[groupId] !== 1)
        .reduce((allGroups, id) => [...allGroups, groups[id]], []) ?? []
    );
  },
);

/** @type {function(state): {name: string, value: string}[]} */
export function getProductGroupsTreeOrdered(state) {
  function getTree(group) {
    if (!group) return [];
    return [
      { depth: 0, group },
      ...(group.subGroups || [])
        .map(id => getProductGroupByID(id)(state))
        .map(getTree)
        .flatMap(a => a)
        .map(({ depth, group }) => ({ depth: depth + 1, group })),
    ];
  }

  return getTree(getProductGroupByID('0')(state))
    .slice(1) /* Ignore root pseudo-group */
    .map(({ depth, group: { productGroupID, name } }) => ({
      name: `${'→ '.repeat(depth - 1)}${name}`,
      value: productGroupID,
    }));
}

export function hasProductGroupLoaded(id) {
  return state =>
    state.productGroupsDB[id] !== undefined &&
    state.productGroupsDB[id] !== DEFAULT_GROUP;
}
