import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  ensureCayanInstalledAndRunning,
  getCayanEndpointURL,
} from '../actions';
import * as API from '../api';

/**
 * @returns {boolean} True when the microservice is running
 */
export function useCayanMicroservice() {
  // Keep API pointing to correct endpoint
  const endpoint = useSelector(getCayanEndpointURL);
  useEffect(() => {
    API.updateEndpoint(endpoint);
  }, [endpoint]);

  // Ensure cayan is installed
  const isWrapper = !!window.wrapperInfo;
  const [alreadyAttempted, setAlreadyAttempted] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!alreadyAttempted && !endpoint && !isWrapper) {
      const t = setTimeout(() => {
        dispatch(ensureCayanInstalledAndRunning());
        setAlreadyAttempted(true);
      }, 2000);
      return () => clearTimeout(t);
    }
    return () => {};
  }, [endpoint]);
  return !!endpoint || isWrapper;
}
