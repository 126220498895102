import { voidRequest } from 'paymentIntegrations/adyen/requests/adyenRequests';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';

import { creationOfAdyenPatchscript } from '../printing';

const adyenVoid = async (payment, hooks) => {
  const {
    updateMessage,
    enableButtons,
    beforeDocDelete,
    displayWarning,
    adyenConfig,
    employeeID,
    printReceipt,
  } = hooks;
  updateMessage('Voiding purchase...');

  try {
    const voidResponse = await voidRequest(
      payment,
      adyenConfig.currencyCode,
      employeeID,
    );

    const { transactionStatus, statusMessage, additionalData } = voidResponse;

    if (transactionStatus === 'Success') {
      updateMessage(`Success. Void has been successfully performed.`);
      await beforeDocDelete(payment.key);

      if (adyenConfig.usePOSGeneralPrinter) {
        const parsedAdditionalData = JSON.parse(additionalData);
        const { receipts } = parsedAdditionalData;

        const receiptsPatchscript = creationOfAdyenPatchscript(receipts);
        printReceipt(receiptsPatchscript);
      }

      return true;
    }

    enableButtons([FUNC_BUTTONS.CLOSE]);
    updateMessage(`Unsuccessful Void: ${statusMessage}.`);
    return false;
  } catch (error) {
    enableButtons([FUNC_BUTTONS.CHECK_STATUS]);
    updateMessage(error.message);
    displayWarning(error.message);
    return false;
  }
};

export default adyenVoid;
