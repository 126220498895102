import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { ProductGroupInput } from 'components/inputs/ProductGroupInput';
import { PosPlugin } from 'plugins/plugin';
import { WarehouseInput } from 'components/inputs/WarehouseInput';

import { Configuration } from './meta';

const defaults: Configuration = {
  mainWarehouseId: 0,
  warrantyProductGroupId: 0,
};
export const CompanyConfiguration: Required<
  PosPlugin<Configuration, Configuration, null, null, null>
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  return (
    <Box>
      <Typography color="primary">
        NB: Changes will save automatically
      </Typography>
      <ProductGroupInput
        fullWidth
        name="productGroup"
        label="Products in exactly this group will be considered warranty products"
        value={current?.warrantyProductGroupId ?? ''}
        onChange={e =>
          save({
            ...defaults,
            ...current,
            warrantyProductGroupId: Number(e.target.value),
          })
        }
      />
      <WarehouseInput
        fullWidth
        name="warehouse"
        label="This warehouse will be used as the base price for calculating warranty prices"
        value={current?.mainWarehouseId ?? ''}
        onChange={e =>
          save({
            ...defaults,
            ...current,
            mainWarehouseId: Number(e.target.value),
          })
        }
      />
    </Box>
  );
};
