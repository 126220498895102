import React, { useEffect, useContext, useState, useMemo } from 'react';
import {
  TextField,
  InputAdornment,
  Button,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Shift4Api } from '@pos-refacto/shift4-integration';
import { Autocomplete } from '@material-ui/lab';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Delete } from '@material-ui/icons';

import { usePosSendMessage } from 'utils/hooks/useWrapper';
import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import { semanticVersion } from 'external_data';
import { useRelativeTime } from 'utils/hooks/useRelativeTime';

import allTimezones from './allTimezones.json';

dayjs.extend(utc);
dayjs.extend(timezone);
/** Just a random date object used to align the refreshes to whole seconds */
const Time = ({ tz }) => {
  useRelativeTime(
    useMemo(
      () => ({
        anchorTime: new Date('00-00-00 00:00:00'),
        resolution: 60e3,
      }),
      [],
    ),
  );
  return (
    <div>
      Current time:{' '}
      {dayjs()
        .tz(tz ?? dayjs.tz.guess())
        .format('dddd HH:mm')}
    </div>
  );
};

const defaultConfig = {
  accessToken: '',
  endpoint: '',
  terminalId: '',
  authToken: '',
  timezone: '',
  printMerchantReceipt: false,
};

const Shift4PaymentConfiguration = () => {
  const superContext = useContext(Ctx);
  const setConfig = usePosSendMessage('config:setConfig');
  const valuesInErply = superContext.values.configuration;
  useEffect(() => {
    if (superContext.values.integration === 'shift4') {
      superContext.values.setEnableUpdate(true);
    }
  }, [superContext.values, superContext.values.integration]);
  const onChange = (key, value) =>
    superContext.onChange('configuration', { ...valuesInErply, [key]: value });
  const setValue = newValue =>
    superContext.onChange('configuration', { ...valuesInErply, ...newValue });

  useEffect(() => setConfig(valuesInErply), [setConfig, valuesInErply]);
  const [authToken, setAuthToken] = useState('');
  const [timezoneInput, setTimezoneInput] = useState(valuesInErply.timezone);

  const exchange = () =>
    Shift4Api.accessTokenExchange(
      `https://localhost.erply.com:10902/?redirect=${valuesInErply.endpoint}`,
      authToken,
      process.env.REACT_APP_SHIFT4_CLIENTGUID,
      dayjs()
        .tz(timezoneInput ?? dayjs.tz.guess())
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      { name: 'Brazil POS', version: semanticVersion },
      'Erply',
    ).then(
      ({
        data: {
          result: [
            {
              credential: { accessToken },
            },
          ],
        },
      }) => {
        onChange('accessToken', accessToken);
      },
    );
  useEffect(() => {
    if (
      valuesInErply.accessToken !==
      superContext.values.initialIntegrationState?.accessToken
    ) {
      superContext.values.forceSave();
    }
  }, [superContext.values, valuesInErply.accessToken]);
  return (
    <>
      <TextField
        fullWidth
        label="UTG endpoint"
        value={valuesInErply.endpoint ?? defaultConfig.endpoint}
        onChange={e => onChange('endpoint', e.target.value)}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        margin="normal"
      />
      <TextField
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        margin="normal"
        label="Auth token"
        value={authToken}
        type="password"
        autoComplete="one-time-code"
        onChange={e => setAuthToken(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button onClick={exchange} variant="outlined">
                Exchange
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <div>
        Access token: {valuesInErply.accessToken}
        <IconButton onClick={() => onChange('accessToken', undefined)}>
          <Delete />
        </IconButton>
      </div>
      <TextField
        fullWidth
        label="Terminal ID"
        value={valuesInErply.terminalId ?? defaultConfig.terminalId}
        onChange={e => onChange('terminalId', e.target.value)}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <br />
      <Autocomplete
        options={allTimezones}
        value={valuesInErply.timezone ?? defaultConfig.timezone}
        onChange={(e, newValue) => onChange('timezone', newValue)}
        inputValue={timezoneInput}
        onInputChange={(e, newValue) => setTimezoneInput(newValue)}
        getOptionLabel={option => option}
        renderInput={params => (
          <TextField
            label="Shift4 account time zone"
            variant="outlined"
            margin="normal"
            {...params}
          />
        )}
        margin="normal"
      />
      <Time tz={valuesInErply.timezone} />
      <br />
      <FormControlLabel
        label="Print merchant receipt"
        control={
          <Checkbox
            checked={
              valuesInErply.printMerchantReceipt ??
              defaultConfig.printMerchantReceipt
            }
            onChange={e => onChange('printMerchantReceipt', e.target.checked)}
          />
        }
      />
    </>
  );
};
export default Shift4PaymentConfiguration;
