import { mergeDeepLeft } from 'ramda';
import axios from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

import {
  BasePaymentTemplate,
  CayanData,
  CayanPaymentResponse,
  CayanStatusResponse,
  CloseBatchResponse,
} from './types';
import { doCancelRequest, doPaymentRequest } from './resultParsers';

type Timeout = null | number;

let deviceTimeout: Timeout = null;

const getDeviceTimeout = async () => {
  if (deviceTimeout) return deviceTimeout;
  const endpoint =
    getMSEndpointFromLS('cayan') || 'https://localhost.erply.com:10103';
  try {
    await axios.get(`${endpoint}api/v1/config`).then(res => {
      const result = res?.data?.records?.[0];
      if (result) {
        const parsedJSON = JSON.parse(result.configJSON ?? '{}');
        const { terminalTimeout } = parsedJSON;
        if (terminalTimeout) {
          // +20 is an extra - if MS timeouts on it's side, POS will receive a response, otherwise we'd be able to have a bit of an extra window
          const newTimeout: Timeout = Number(terminalTimeout + 20) * 1000;
          deviceTimeout = newTimeout;
        }
      }
    });
  } catch {
    // empty catch
  }
  return deviceTimeout;
};

const getAxiosInstance = (timeout: Timeout) => {
  const endpoint =
    getMSEndpointFromLS('cayan') || 'https://localhost.erply.com:10103';
  const instance = axios.create({
    baseURL: `${endpoint}api/v1/`,
    timeout: timeout ?? 200e3,
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  });
  return instance;
};

// region Transaction requests
const paymentTemplate = (
  transactionType: string,
  cardPayment: {
    amount: string;
    uuid: string;
    boardCard: boolean;
    cardToken: string;
    additionalData?: BasePaymentTemplate['additionalData'];
  },
  cayanData: {
    loggedInEmployeeID: string;
    currentCustomer: string;
    clientCode: string;
  },
) => {
  // software name and version should be exactly these values to satisfy requiremnts in MS
  const basePaymentTemplate: BasePaymentTemplate = {
    clientCode: cayanData.clientCode,
    requestID: cardPayment.uuid,
    transactionType,
    amount: Math.abs(parseFloat(cardPayment.amount)).toString(),
    tenderType: 'CREDIT',
    employeeID: cayanData.loggedInEmployeeID,
    invoiceNumber: '1234567',
    customerID: `${cayanData.currentCustomer}`,
    softwareName: 'Erply POS',
    softwareVersion: '3.23.3',
  };
  if (cardPayment.boardCard) {
    basePaymentTemplate.additionalData = {
      storeCardInVault: true,
    };
  } else if (cardPayment.cardToken) {
    basePaymentTemplate.additionalData = {
      vaultToken: cardPayment.cardToken,
    };
  }
  return basePaymentTemplate;
};

const startPayment = async (cardPayment, cayanData) => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout).post(
    'payment',
    paymentTemplate('SALE', cardPayment, cayanData),
  );
};

const startVoid = async (cardPayment, cayanData) => {
  const refNo = /(.+)\.(.+)/.test(cardPayment.attributes.refNo)
    ? cardPayment.attributes.refNo.split('.', 2)[1]
    : cardPayment.attributes.refNo;
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout).post(
    'payment',
    mergeDeepLeft({
      referenceNumber: refNo,
    })(paymentTemplate('VOID', cardPayment, cayanData)),
  );
};

const startRefund = async (cardPayment, cayanData) => {
  const timeout = await getDeviceTimeout();
  if (!cardPayment.original) {
    return getAxiosInstance(timeout).post(
      'payment',
      paymentTemplate('REFUND', cardPayment, cayanData),
    );
  }
  const refNo = /(.+)\.(.+)/.test(cardPayment.original.attributes.refNo)
    ? cardPayment.original.attributes.refNo.split('.', 2)[1]
    : cardPayment.original.attributes.refNo;
  return getAxiosInstance(timeout).post(
    'payment',
    mergeDeepLeft({
      referenceNumber: refNo,
      cardHolder: cardPayment.original.cardHolder,
      cardType: cardPayment.original.cardType,
      cardNumber: cardPayment.original.cardNumber,
    })(paymentTemplate('REFUND', cardPayment, cayanData)),
  );
};
// endregion

// region Transaction modifier requests

const cayanCancel = async (): Promise<void> => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout).post('manage', {
    manageType: 'cancelTransaction',
  });
};

const updateToKeyedEntry = async (): Promise<void> => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout).post('manage', { manageType: 'KeyedEntry' });
};

const cayanCheckTransactionStatus = async (
  refno: string,
): Promise<CayanPaymentResponse> => {
  const timeout = await getDeviceTimeout();

  return getAxiosInstance(timeout).post('payment', {
    transactionType: 'TransactionStatus',
    hostReferenceNumber: refno,
  });
};

const cayanGetStatus = async (): Promise<{ data: CayanStatusResponse }> => {
  const timeout = await getDeviceTimeout();

  return getAxiosInstance(timeout).post('manage', {
    manageType: 'status',
  });
};
// endregion

// region Misc / Status requests

const pingTerminal = async () => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout)
    .get('testConnection')
    .then(
      async response =>
        response.data.records[0].transactionStatus === 'Success',
    );
};

/** @deprecated */
type Config = {
  deviceIPOrHost: string;
  devicePort: string;
  merchantName: string;
  merchantSiteID: string;
  merchantKey: string;
  secureDeviceConnection: boolean;
};
/** @deprecated */
const configTerminal = async (params: Config) => {
  const timeout = await getDeviceTimeout();
  const configString = JSON.stringify({
    deviceIPOrHost: params.deviceIPOrHost,
    devicePort: params.devicePort,
    merchantName: params.merchantName,
    merchantSiteID: params.merchantSiteID,
    merchantKey: params.merchantKey,
    secureDeviceConnection: params.secureDeviceConnection,
  });

  getAxiosInstance(timeout)
    .post('/config', { configJSON: configString })
    .then(
      response =>
        response.status === 200 && response.data?.records[0]?.configJSON === '',
    );
};

const closeBatch = async () => {
  const timeout = await getDeviceTimeout();
  const {
    data: {
      records: [{ transactionStatus }],
    },
  } = await getAxiosInstance(timeout).get<CloseBatchResponse>('closeBatch');
  return transactionStatus === 'Approved';
};

// endregion

export const CAYAN = {
  /** Begin a payment */
  startPayment: (payment, cayanData: CayanData) =>
    doPaymentRequest(startPayment(payment, cayanData)),

  /** Begin a referenced return */
  startReturn: (payment, cayanData: CayanData) =>
    doPaymentRequest(startRefund(payment, cayanData)),

  /** Begin a void (immediate refund, not possible after batch closed) */
  startVoid: (payment, cayanData: CayanData) =>
    doPaymentRequest(startVoid(payment, cayanData)),

  /** Check status of the current payment by refno, returns the status */
  checkStatus: (payment, cayanData: CayanData, refno) =>
    doPaymentRequest(cayanCheckTransactionStatus(refno)),

  /** Get current status (current screen) of the terminal device */
  status: () => cayanGetStatus().then(res => res.data),

  /** Switch the terminal to keyed entry mode for the current payment */
  enableKeyedEntry: () => updateToKeyedEntry(),

  /** Cancel the payment, returns the cancellation status *and* affects the original request as well */
  cancel: () => doCancelRequest(cayanCancel()),

  closeBatch,

  /** Check if terminal can be reached */
  ping: () =>
    pingTerminal().then(
      () => true,
      () => false,
    ),

  /** @deprecated unused??? */
  config: (conf: Config) => configTerminal(conf),
};
