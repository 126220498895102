import { Grid, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';

import { countries } from './constants';

const invalid = 'invalid';

type Props = {
  country: string;
  handleChange: (key: string, value: string) => void;
};

function useErrorMessage(value: string) {
  const { t } = useTranslation('addressForm', { keyPrefix: 'errors' });
  const isValidCountry = Object.values(countries).some(
    country => country === value,
  );
  if (!isValidCountry) {
    return t('country');
  }
  return '';
}

const CountrySelect: React.FC<Props> = ({ country, handleChange }) => {
  const { t } = useTranslation('createCustomer');

  const errorMessage = useErrorMessage(country);
  const hasError = errorMessage.length > 0;

  return (
    <Field name="country" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
      <TextField
        label={t('fields.country')}
        select
        name="country"
        variant="outlined"
        fullWidth
        value={hasError ? invalid : country ?? invalid}
        helperText={errorMessage}
        error={hasError}
        onChange={e => handleChange('country', e.target.value)}
      >
        <MenuItem disabled value={invalid}>
          {t('countries.select')}
        </MenuItem>
        <MenuItem value={countries.Canada}>{t('countries.canada')}</MenuItem>
        <MenuItem value={countries.USA}>{t('countries.usa')}</MenuItem>
      </TextField>
    </Field>
  );
};

export default CountrySelect;
