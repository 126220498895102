import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPluginComponentsByName } from '../../../reducers/Plugins';
import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

const PluginModal = ({ name, ...rest }) => {
  const Component: any =
    useSelector(getPluginComponentsByName(name))[0];
  // eslint-disable-next-line react/jsx-props-no-spreading
  const dispatch = useDispatch();
  if (Component) return <Component {...rest} />;
  return (
    <div>
      Failed to load plugin component: {name}
      <UIButton text="Close" action={() => dispatch(previousModalPage())} />
    </div>
  )
};

export default PluginModal;
