import { PosPlugin } from 'plugins/plugin';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import {
  getProductInOrderByIndex,
  getProductsInShoppingCart,
  getTotalCountInCart,
  getTotalCountInCartByGroupID,
} from 'reducers/ShoppingCart';
import { addWarning } from 'actions/Error';
import { modalPages as mp } from 'constants/modalPage';
import { getProductByID } from 'reducers/cachedItems/products';

import { USDL_CONST } from './types';
import reduxReducer, {
  getUSDLClientAge,
  getUSDLProductConfiguration,
  setUserAgeVerified,
} from './redux';
import { ComponentConfiguration, USDLAgeVerificationModal } from './components';

const USDLAgeVerification: PosPlugin = {
  id: USDL_CONST.ID,
  name: USDL_CONST.NAME,
  keywords: USDL_CONST.KEYWORDS,
  info: USDL_CONST.INFO,
  reduxReducer,
  ComponentConfiguration,
  combineConfiguration: company => company,
  onAddProduct: {
    on: (params, ap) => async (dispatch, getState) => {
      const productCard = getProductByID(ap.product.productID)(getState());

      const configuration = getUSDLProductConfiguration(ap.product.productID)(
        getState(),
      );

      if (!configuration) return ap;

      if (configuration.maxAmount > 0) {
        const currentAmountInSC = Math.max(
          getTotalCountInCart(ap.product.productID)(getState()),
          getTotalCountInCartByGroupID(productCard.groupID)(getState()),
        );

        const allowedProdAmount = configuration.maxAmount - currentAmountInSC;

        if (allowedProdAmount <= 0) {
          dispatch(addWarning('Purchase Limit Exceeded'));
          throw new Error('Purchase Limit Exceeded');
        }

        if (allowedProdAmount - ap.product.amount < 0) {
          dispatch(
            addWarning(
              `Product amount reduced from ${ap.product.amount} to ${allowedProdAmount} to meet Purchase Limit`,
            ),
          );
          // eslint-disable-next-line no-param-reassign
          ap.product.amount = allowedProdAmount;
        }
      }

      if (getUSDLClientAge(getState()) >= configuration.minAge) return ap;

      return (
        dispatch(
          openModalPage({
            component: mp.pluginModal,
            props: {
              name: 'USDLAgeVerificationModal',
              product: ap.product,
              minAge: configuration.minAge,
            },
            isPopup: true,
          }),
        )
          // @ts-ignore
          .then(age => {
            dispatch(setUserAgeVerified(age));
            return ap;
          })
      );
    },
  },
  onUpdateOrderAmount: {
    on: (p, ap) => async (dispatch, getState) => {
      const orderItem = getProductInOrderByIndex(ap.orderIndex)(getState());
      const productCard = getProductByID(orderItem.productID)(getState());

      const configuration = getUSDLProductConfiguration(orderItem.productID)(
        getState(),
      );

      if (!configuration) return ap;

      const currentAmountInSC = Math.max(
        getTotalCountInCart(orderItem.productID)(getState()),
        getTotalCountInCartByGroupID(productCard.groupID)(getState()),
      );

      const allowedProdAmount =
        configuration.maxAmount - (currentAmountInSC - orderItem.amount);

      if (allowedProdAmount <= 0) {
        throw new Error('Purchase Limit Exceeded');
      }

      if (allowedProdAmount < Number(ap.amount)) {
        // eslint-disable-next-line no-param-reassign
        ap.amount = allowedProdAmount;
        dispatch(
          addWarning(
            `New product amount reduced from ${p.amount} to ${allowedProdAmount} to meet Purchase Limit`,
          ),
        );
      }

      return ap;
    },
  },
  onRemoveOrder: {
    after: () => async (dispatch, getState) => {
      const shoppingCartProducts = getProductsInShoppingCart(getState());
      if (!shoppingCartProducts.length) {
        dispatch(setUserAgeVerified(0));
      }
    },
  },
  components: {
    USDLAgeVerificationModal,
  },
};

export default USDLAgeVerification;
