import React, { FC, useState, useMemo } from 'react';
import {
  Box,
  Typography,
  DialogTitle,
  List,
  ListItem,
  styled,
} from '@material-ui/core';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { ReasonCode } from 'types/ReasonCodes';

type Props = {
  resolve: (reason: ReasonCode | null) => void;
  reasonCodes: ReasonCode[];
  type: string;
  title: string;
  onClose: () => void;
};

const ScrollableList = styled(List)({
  height: '50vh',
  overflowY: 'scroll',
});

const StyledListItem = styled(ListItem)({
  '&:hover': {
    background: 'lightblue',
  },
});

const ReasonPopup: FC<Props> = ({ resolve, title, reasonCodes, onClose }) => {
  const [selectedReasonID, setSelectedReasonID] = useState<number | null>(null);

  const onSave = () => {
    const reason = reasonCodes.find(r => r.reasonID === selectedReasonID);
    if (reason) {
      onClose();
      resolve(reason);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
        <Box display="flex" marginRight="10px">
          <SaveButton
            action={onSave}
            disabled={!selectedReasonID}
            data-testid="save-btn"
          />
          <CloseButton data-testid="close-btn" action={onClose} />
        </Box>
      </Box>
      <ScrollableList>
        {useMemo(
          () =>
            reasonCodes.map(({ name, reasonID }) => (
              <StyledListItem
                button
                className="dark_list_item"
                onClick={() => setSelectedReasonID(reasonID)}
                key={reasonID}
                data-testid="reason-code"
                data-test-key={reasonID}
              >
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="h6">{name}</Typography>
                  {selectedReasonID === reasonID ? (
                    <span
                      style={{ color: '#333' }}
                      className="icon_check fa-2x dark_icon"
                    />
                  ) : null}
                </Box>
              </StyledListItem>
            )),
          [reasonCodes, selectedReasonID],
        )}
      </ScrollableList>
    </>
  );
};

export default ReasonPopup;
