import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ListItem from 'components/ListItem';
import {
  deletePayment,
  setPaymentEditValue,
  setShowPaymentInput,
} from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { getLastPayment, getPaymentsCurrency } from 'reducers/Payments';
import { useBreakpoints } from 'utils/hooks/UI';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

const AddChangePayment = ({ disabled = false }) => {
  const { t } = useTranslation('payment');
  const dispatch = useDispatch();
  const isMobileView = !useBreakpoints().mdplus;
  const { lastPayment, lastKey } = useSelector(getLastPayment);
  const { code: currencyCode, rate } = useSelector(getPaymentsCurrency);

  const addChangePayment = () => {
    (async () => {
      await dispatch(setPaymentEditValue(''));

      if (lastPayment.amount === '0.00')
        dispatch(deletePayment({ key: lastKey }));

      await dispatch(
        setPayment({
          amount: '-0.00',
          key: `${currencyCode}-cash`,
          caption: t('tenders.change'),
          type: 'CHANGE',
          currencyCode,
          currencyRate: Number(rate),
        }),
      );
      await dispatch(setPaymentSelected(`${currencyCode}-cash`));
    })();
  };
  useShortcut('F4', addChangePayment, 20);

  return isMobileView ? (
    <li className="payment-item card-payment" style={{ cursor: 'pointer' }}>
      <div
        tabIndex={0}
        className="button"
        role="button"
        onClick={() => dispatch(setShowPaymentInput(true, 'CHANGE'))}
      >
        <span className="title">{t('buttons.enterChange')}</span>
      </div>
    </li>
  ) : (
    <ListItem
      disabled={disabled}
      // Despite this now being cash-withdrawal, test-id kept same to not ruin testing
      data-testid="cash-back-button"
      variant="grid_dark"
      action={addChangePayment}
    >
      <span className="cash-withdrawal">{t('buttons.change')}</span>
      <span className="func-key">F4</span>
    </ListItem>
  );
};

export default AddChangePayment;
