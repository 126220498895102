import { createSelector } from 'reselect';
import * as R from 'ramda';

import {
  GET_COMAPNY_INFO,
  TYPE_EDIT_EMPLOYEE_DATA,
  TYPE_LOGIN,
  TYPE_LOGIN_OFFLINE,
  TYPE_LOGOUT,
  TYPE_LOGOUT_OFFLINE,
  TYPE_LOGOUT_SOFT,
  TYPE_SETLOADING,
  TYPE_SETUSERRIGHTS,
  TYPE_SET_USER_SALES_DATA,
} from 'constants/Login';
import {
  REDUX_CLIENTCODE,
  REDUX_OFFLINELOGOUTPIN,
  REDUX_SESSIONKEY,
  REDUX_SOFTLOGOUT,
  REDUX_USER,
  REDUX_USERNAME,
} from 'constants/persistence';

import loadingReducer from './util/loadingReducer';

const initialState = {
  user: null,
  company: null,
  password: false,
  loading: false,
  rights: {
    modules: {
      products: {},
      settings: {},
      employees: {},
      customers: {},
      invoices: {},
      payments: {},
      warehouses: {},
      salepoints: {},
    },
  },
  salesData: null,
};

function save(key, value) {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.removeItem(key);
  }
}

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case TYPE_EDIT_EMPLOYEE_DATA:
    case TYPE_LOGIN:
      // NOTE: Wanted for at least another month as of 06MAY20 (@liis.kalmet)
      if (localStorage.getItem('mockPasswordExpired')) {
        payload.user.isPasswordExpired = 1;
      }
      save(REDUX_SESSIONKEY, payload.user.sessionKey);
      save(REDUX_USERNAME, payload.username);
      save(REDUX_USER, payload.user);
      localStorage.removeItem(REDUX_SOFTLOGOUT);
      localStorage.removeItem(REDUX_OFFLINELOGOUTPIN);
      return state;
    case TYPE_LOGOUT:
      return { loading: false, ...initialState };

    case GET_COMAPNY_INFO.SUCCESS:
      return { ...state, company: payload };

    case TYPE_LOGOUT_SOFT:
      localStorage.setItem(REDUX_SOFTLOGOUT, 'true');
      return state;

    case TYPE_LOGOUT_OFFLINE:
      localStorage.setItem(REDUX_OFFLINELOGOUTPIN, payload.pin);
      return state;
    case TYPE_LOGIN_OFFLINE:
      if (
        String(localStorage.getItem(REDUX_OFFLINELOGOUTPIN)) ===
        String(payload.pin)
      )
        localStorage.removeItem(REDUX_OFFLINELOGOUTPIN);
      return state;

    case TYPE_SETUSERRIGHTS:
      return { ...state, rights: payload };

    case TYPE_SET_USER_SALES_DATA:
      return { ...state, salesData: payload };

    case TYPE_SETLOADING:
      return {
        ...state,
        loading: loadingReducer.reduce(state.loading, payload),
      };

    default:
      return state;
  }
}

export default reducer;

function getStored(key) {
  return createSelector(
    () => localStorage.getItem(key),
    data => {
      try {
        return JSON.parse(data);
      } catch (e) {
        return null;
      }
    },
  );
}

export const getClientCode = getStored(REDUX_CLIENTCODE);
export const getUsername = getStored(REDUX_USERNAME);
export const getSessionKey = getStored(REDUX_SESSIONKEY);

export const getAccount = createSelector(
  getClientCode,
  getUsername,
  (clientCode, username) => ({ clientCode, username }),
);

export function isSoftLoggedOut(state) {
  return (localStorage.getItem(REDUX_SOFTLOGOUT) ||
    localStorage.getItem(REDUX_OFFLINELOGOUTPIN)) &&
  getSessionKey(state);
}
export function getIsOfflineLocked(state) {
  return !!localStorage.getItem(REDUX_OFFLINELOGOUTPIN);
}

export function getCanAddProducts(state) {
  return state.Login.rights?.modules.products?.add;
}

export function getCanEditProducts(state) {
  return state.Login.rights?.modules.products?.edit;
}

export function getCanAddCustomers(state) {
  return state.Login.rights?.modules.customers?.add;
}

export function getCanEditCustomers(state) {
  return state.Login.rights?.modules.customers?.edit;
}

export function getHasRightToOpenAndCloseDay(state) {
  return !!state.Login.rights?.modules.day_openings_closings?.add;
}

export function getHasRightToCashInOut(state) {
  return !!state.Login.rights?.modules.cash_ins_outs?.add;
}

/** @type {function(state): boolean} */
export const getIsPasswordExpired = createSelector(
  state => getStored(REDUX_USER)(state),
  state => getSessionKey(state),
  (user, sk) => user?.sessionKey === sk && user?.isPasswordExpired,
);

export function getIsLoading(state) {
  return loadingReducer.getIsLoading(state.Login.loading);
}

export const getUserLoggedIn = createSelector(
  getStored(REDUX_USER),
  state => getSessionKey(state),
  (stored, sessionKey) => {
    if (stored && stored.sessionKey === sessionKey) return stored;
    return null;
  },
);

export function getCompany(state) {
  return state.Login.company || {};
}

/**
 * @typedef {{
 *   view: 0|1|2,
 *   add: 0|1,
 *   editUnconfirmed: 0|1|2
 *   confirm: 0|1
 *   edit: 0|1|2,
 *   deleteUnconfirmed: 0|1|2
 *   delete: 0|1|2,
 * }} Permission
 */

/**
 * @type {function(state): {
 *  userID: number,
 *  userName: string,
 *  groupID: number,
 *  maxDiscount: number,
 *  cardCode: string,
 *  rightGiveCustomerCredit: Integer,
 *  rightCreateInventoryRegistrations: Integer,
 *  rightCreateInventoryAmortizations: Integer,
 *  rightMakePOSRefunds: Integer,
 *  rightChangePrices: number,
 *  rightEditConfirmedInvoices: Integer,
 *  rightChangeInvoiceDate: number,
 *  rightApplyPromotions: number,
 *  added: number,
 *  addedByUserName: string,
 *  lastModified: number,
 *  lastModifiedByUserName: string,
 *  rightMakePOSReturnsWithoutReceipt: number,
 *  rightEditStockAndProductCost: number,
 *  rightPOSManagerOverride: number,
 *  rightChangePricesOnPurchaseOrders: number,
 *  rightChangeConfirmedPurchaseInvoices: number,
 *  rightMakeDiscountInPOS: number,
 *  rightEditPriceOnReturnWithoutReceiptInPOS: number,
 *  rightOpenAndCloseDay: number,
 *  rightViewPendingSalesInPOS: number,
 *  rightAddShippingInPOS: number,
 *  rightTaxExemptionInPOS: number,
 *  rightEditActualReports: number,
 *  rightAddRewardPoints: number,
 *  rightEditRetailChainPriceLists: number,
 *  rightChangePOSVersion: number,
 *  warehouses: {
 *    [string]: {
 *      warehouseID: number,
 *      right: '0'|'1'
 *    }
 *  },
 *  modules: {
 *    customers: Permission,
 *    assignments: Permission,
 *    reports: {
 *      view: 0|1|2
 *    },
 *    z_report: {
 *      view: 0|1|2
 *    },
 *    report_invoice_over: Permission,
 *    report_empl: Permission,
 *    report_promotions: Permission,
 *    finance: Permission,
 *    report_generator: Permission,
 *    quotes: Permission,
 *    orders: Permission,
 *    invoices: Permission,
 *    payments: Permission,
 *    purchases: Permission,
 *    products: Permission,
 *    pricelists: Permission,
 *    suppliers: Permission,
 *    products_quick_docs: Permission,
 *    warehouses: Permission,
 *    salepoints: Permission,
 *    prodgroups: Permission,
 *    prodseries: Permission,
 *    brands: Permission,
 *    parameter_groups: Permission,
 *    delivery_conditions: Permission,
 *    prodmoves: Permission,
 *    prodins: Permission,
 *    prodamorts: Permission,
 *    stock: Permission,
 *    services: Permission,
 *    webshop_giftcards: Permission,
 *    campaigns: Permission,
 *    coupons: Permission,
 *    issuedcoupons: Permission,
 *    webshop_pages: Permission,
 *    employees: Permission,
 *    settings: Permission,
 *    posconfs: Permission,
 *    day_openings_closings: Permission,
 *    cash_ins_outs: Permission,
 *    transaction_registry: Permission,
 *  }
 * }}
 */
export function getUserRights(state) {
  return state.Login.rights;
}

export const getUserRightsAreFetched = createSelector(
  getUserRights,
  userRights => !R.equals(userRights, initialState.rights),
);

export const getLoggedInEmployeeID = createSelector(
  state => getUserLoggedIn(state),
  user => {
    return user ? user.employeeID : null;
  },
);

export const getUserSalesData = createSelector(
  state => state.Login.salesData,
  salesData => salesData,
);

/**
 * Configuration module permission in BO is only one checkbox that allows user to edit settings
 * and this checkbox corresponds to 'view' property.
 */
export const getHasRightToEditSettings = createSelector(
  getUserRights,
  userRights => userRights.modules.settings.view > 0,
);

export const getHasRightToEditProducts = createSelector(
  getUserRights,
  userRights => userRights.modules.products.edit,
);

export const getHasRightToReturn = createSelector(
  getUserRights,
  userRights => !!Number(userRights.rightMakePOSRefunds),
);

export const getWarehouseRights = createSelector(getUserRights, userRights =>
  Object.values(userRights?.warehouses || {}),
);
export const getHasRightToReturnWithoutReceipt = createSelector(
  getUserRights,
  userRights => !!Number(userRights.rightMakePOSReturnsWithoutReceipt),
);

export const getInvoiceModuleRights = createSelector(
  state => getUserRights(state),
  userRights => userRights?.modules?.invoices,
);

export const getHasRightToDeleteSale = createSelector(
  state => getInvoiceModuleRights(state),
  invoiceModules => invoiceModules?.delete,
);

export const getHasRightToDeleteUnconfirmedSale = createSelector(
  state => getInvoiceModuleRights(state),
  invoiceModules => invoiceModules?.deleteUnconfirmed,
);

/**
 * Checks if the current user has sufficient rights to make a sale
 * Condition based on   // Condition for minimum sales rights derived from
 * https://manual.erply.com/eng/back-office-features/back-office-settings/user-rights/minimum-rights-required-to-make-a-sale
 */
export const getHasRightToMakeASale = createSelector(
  state => getUserRights(state),
  userRights => {
    const {
      customers,
      invoices,
      payments,
      warehouses,
      salepoints,
    } = userRights.modules;

    const hasCustomerRights = Number(customers?.view) === 2;

    const hasPaymentsRights =
      Number(payments?.view) === 2 && Number(payments?.add) === 1;
    const hasInvoiceRights =
      Number(invoices?.view) === 2 && Number(invoices?.add) === 1;

    const hasLocationRights = Number(warehouses?.view) === 2;
    const hasRegisterRights = Number(salepoints?.view) === 2;

    return (
      hasCustomerRights &&
      hasPaymentsRights &&
      hasInvoiceRights &&
      hasLocationRights &&
      hasRegisterRights
    );
  },
);

export const getHasRightToViewPromotions = createSelector(
  state => getUserRights(state),
  userRights => userRights?.modules?.campaigns?.view,
);

export const getHasRightToApplyPromotions = createSelector(
  state => getUserRights(state),
  userRights => userRights?.rightApplyPromotions,
);

export const getRightToEditConfirmedOrders = createSelector(
  state => getUserRights(state),
  userRights => userRights?.modules?.orders?.edit,
);

export const getHasRightToViewZReport = createSelector(
  state => getUserRights(state),
  userRights => userRights?.modules?.z_report?.view,
);
