export const pluginID = 'inventory-write-off';

export const modals = {
  inventoryOffModal: `${pluginID} - INVENTORY_MODAL`,
};

export interface ReasonCode {
  added: number;
  code: string;
  lastModified: null | string;
  manualDiscountDisablesPromotionTiers: any[];
  name: string;
  purpose: string;
  reasonID: number;
}
