import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync, useDebounce } from 'react-use';

import { getClientCode } from 'reducers/Login';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { getCachedItemsPerTypeIsReady } from 'reducers/cachedItems';
import { getItemsFromSO } from 'services/DB';
import { SO } from 'services/DB/types';
import { Employee } from 'types/Employee';

export const getEmployeeByIDAsync = employeeID => async (
  dispatch,
  getState,
) => {
  const clientCode = getClientCode(getState());
  let employee;
  try {
    if (getCachedItemsPerTypeIsReady(SO.EMPLOYEES.NAME)) {
      [employee] = await getItemsFromSO(clientCode, SO.EMPLOYEES.NAME, [
        employeeID,
      ]);
    }
    if (employee) return employee;

    [employee] = await doClientRequest({
      request: 'getEmployees',
      employeeID,
    });
  } catch (error) {
    [employee] = await getItemsFromSO(clientCode, SO.EMPLOYEES.NAME, [
      employeeID,
    ]);
  }
  return employee;
};

const useEmployeeHook = (employeeID: number) => {
  const dispatch = useDispatch();
  const employeesUpdated = useSelector(
    getCachedItemsPerTypeIsReady(SO.EMPLOYEES.NAME),
  );

  const [employeePromise, setEmployeePromise] = useState<
    Promise<Employee | null>
  >(Promise.resolve(null));

  const { value: employee, loading, error } = useAsync<Employee | null>(
    () => employeePromise,
    [employeePromise],
  );
  useDebounce(
    () => setEmployeePromise(dispatch(getEmployeeByIDAsync(employeeID)) as any),
    300,
    [employeeID, employeesUpdated],
  );

  return [employee, !employee && loading, error] as const;
};

export default useEmployeeHook;
