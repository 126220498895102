import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import TimelineIcon from '@material-ui/icons/Timeline';
import { useDispatch } from 'react-redux';

import { addWarning } from 'actions/Error';

import {
  filterOutVersionBeforeSemVer,
  getPOSAvailableVersions,
  redirectPOSToVersion,
  verifyTargetAvailable,
} from '../utils';

type VersionControlFormProps = {
  currentVersion?: string;
  saveVersion: (newVersion: string) => Promise<void>;
  close: () => void;
};

const VersionControlForm: FC<VersionControlFormProps> = ({
  currentVersion,
  saveVersion,
  close,
}) => {
  const { t } = useTranslation('posVersionControl');
  const dispatch = useDispatch();

  const selectCurrentVersion = (e: ChangeEvent<HTMLInputElement>) => {
    const version = e.target.value || 'latest';
    verifyTargetAvailable(version)
      .then(() => saveVersion(version))
      .then(() => {
        redirectPOSToVersion(version);
        close();
      })
      .catch(err => {
        console.error(err);
        dispatch(
          addWarning(t('warnings.failedToSaveVersion', { version }), {
            selfDismiss: false,
            dismissible: true,
          }),
        );
      });
  };

  const { value: versions, loading } = useAsync(
    () =>
      getPOSAvailableVersions().then(versions =>
        filterOutVersionBeforeSemVer('4.43.0', versions),
      ),
    [getPOSAvailableVersions, filterOutVersionBeforeSemVer],
  );
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={close} variant="outlined" color="primary">
          Close
        </Button>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h4">{t('title')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>{t('description')}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6">Current POS Version</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          select
          fullWidth
          value={currentVersion}
          onChange={selectCurrentVersion}
          InputProps={{ style: { textAlign: 'right' } }}
        >
          {loading ? (
            <MenuItem value="">{t('loading')}</MenuItem>
          ) : (
            versions?.map(v => <MenuItem value={v}>{v}</MenuItem>)
          )}
        </TextField>
      </Grid>

      <Grid item xs={12} />
    </Grid>
  );
};

const defaultStyle: CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '800px',
  border: '2px solid #000',
  boxShadow: '24px',
  padding: '1em',
  margin: '4em auto',
};

type VersionControlModalProps = {
  currentVersion?: string;
  saveVersion: (newVersion: string) => Promise<void>;
  style?: CSSProperties;
  hasAccess: boolean;
  onNoAccess: () => void;
};

const VersionControlModal: FC<VersionControlModalProps> = ({
  currentVersion,
  saveVersion,
  style,
  hasAccess,
  onNoAccess,
}) => {
  const [open, setOpen] = useState(false);

  const openVersionControl = () => {
    if (hasAccess) {
      setOpen(true);
    } else {
      onNoAccess();
    }
  };

  const closeVersionControl = () => {
    setOpen(false);
  };

  return (
    <>
      <TimelineIcon
        style={{ cursor: 'pointer' }}
        onClick={openVersionControl}
      />
      <Modal open={open} onClose={closeVersionControl}>
        <Box component={Paper} style={{ ...defaultStyle, ...style }}>
          <VersionControlForm
            currentVersion={currentVersion}
            saveVersion={saveVersion}
            close={closeVersionControl}
          />
        </Box>
      </Modal>
    </>
  );
};

export default VersionControlModal;
