import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import ComponentConfigurationByLevel from './components/ConfigurePlugin';
import UICustomerForm from './components/UICustomerForm';
import UICustomerFormBeta from './components/UICustomerFormBeta';
import UICustomerFormSaveButton from './components/UICustomerFormSaveButton';

/**
 * This plugin enables the user to add custom questions to the default Customer Creation form
 *
 * The selected answers would be stored to customer card attributes
 */

const customerFormFeedbackSection: PosPlugin = {
  id: 'customer-creation-form-feedback-section',
  name: 'Feedback Section for Customer Creation Form',
  info: `This plugin allows the user to configure a custom feedback questions.
  The questions will be presented as a dropdown input field in the Customer Creation Form.
  The answers would be stored to the customer card as attributes`,
  keywords: ['customer', 'form', 'feedback', 'questions'],
  ComponentConfigurationByLevel: {
    Company: ComponentConfigurationByLevel,
  },
  combineConfiguration: R.mergeAll,
  UICustomerForm,
  UICustomerFormBeta,
  UICustomerFormSaveButton,
  UICustomerFormBetaSaveButton: UICustomerFormSaveButton,
};

export default customerFormFeedbackSection;
