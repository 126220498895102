/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from 'reselect';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getCardPaymentsForIntegration } from 'reducers/Payments';
import { markForProcessing, deletePayment } from 'actions/Payments';
import { isEmpty } from 'utils';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { CardPaymentHooks, FUNC_BUTTONS } from 'paymentIntegrations/types';
import { addError } from 'actions/Error';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { RootState } from 'reducers';

import { baseLog, integrationName, integrationType } from './constants';
import {
  cancelCurrentReaderAction,
  processPayments,
} from './handlers/processPayments';
import { cancelPaymentIntent } from './API';
import { Configuration } from './types';

export const getStripeConfiguration = createSelector(
  getCafaEntry(integrationName, integrationType),
  (e): undefined | Configuration => e?.value ?? {},
);

export const getRequestHeaders = createSelector(
  getStripeConfiguration,
  conf => ({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${conf?.secret}`,
  }),
);

export const voidPayments = () => async (dispatch, getState) => {
  const log = baseLog.extend('voidPayments');
  const integrationCardPayments = getCardPaymentsForIntegration('stripe')(
    getState(),
  );
  log(
    'Void payments called for the following payments: ',
    integrationCardPayments,
  );

  integrationCardPayments.forEach(({ key, paid }) =>
    paid
      ? dispatch(markForProcessing({ key }))
      : dispatch(deletePayment({ key })),
  );
  const finalPayments = getCardPaymentsForIntegration('stripe')(getState());
  log(
    'Some payments were marked for processing, while some were deleted. Final payments: ',
    finalPayments,
  );
  if (finalPayments.length) {
    log('Final payments exist. Opening terminal integration.');
    await dispatch(openTerminalIntegration());
  }
};

export const initPayments = (params: CardPaymentHooks) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const state = getState();

  const {
    enableButtons,
    rejectPayments,
    resolvePayments,
    displayError,
  } = params;

  const config = getStripeConfiguration(state);

  if (isEmpty(config)) {
    displayError('Stripe not configured.');
    rejectPayments();
    return;
  }
  enableButtons([]);

  dispatch(processPayments(params))
    .then(async ({ errors }: { errors: string[] }) => {
      const cardPayments = getCardPaymentsForIntegration('stripe')(getState());
      const shouldVoid = cardPayments.every(p => p.shouldProcess && p.paid);

      if (errors.length) {
        enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CANCEL]);
      } else if (shouldVoid) {
        // Before rejecting need to map through the payments and cancel all payments that have
        await Promise.all(
          cardPayments
            .filter(c => c.attributes.stripePaymentId)
            .map(async d => {
              try {
                const result = await dispatch(
                  cancelPaymentIntent({
                    payment_intent: d.attributes.stripePaymentId,
                    reason: 'requested_by_customer',
                  }),
                );
                if (result) {
                  dispatch(deletePayment({ key: d.key }));
                }
                return result;
              } catch (e) {
                if (e instanceof Error) {
                  params.updateMessage(e.message);
                } else if (typeof e === 'string') {
                  params.updateMessage(e);
                }
                throw e;
              }
            }),
        );
        rejectPayments();
      } else {
        resolvePayments();
      }
    })
    .catch(error => {
      console.error('Failed to process payment with Stripe integration', error);
      displayError(
        `Failed to process payment with Stripe integration: ${error}`,
      );
      enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CANCEL]);
      throw error;
    });
};

const cancelCurrentPayment = ({
  enableButtons,
  rejectPayments,
}: CardPaymentHooks) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
) => {
  await dispatch(cancelCurrentReaderAction());
  enableButtons([]);
  rejectPayments();
};
export const cancelPayments = (params: CardPaymentHooks) => async dispatch =>
  dispatch(cancelCurrentPayment(params)).catch(e => {
    console.error('Failed to cancel payments with Stripe: ', e);
    dispatch(addError(`Failed to cancel payments with Stripe: ${e}`));
  });

export const retryPayments = (params: CardPaymentHooks) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const cardPayments = getCardPaymentsForIntegration('stripe')(getState());
  const needToVoid = cardPayments.every(c => c.paid);
  if (needToVoid) {
    cardPayments.forEach(c => dispatch(markForProcessing({ key: c.key })));
  }
  const cardPaymentsToRetry = getCardPaymentsForIntegration('stripe')(
    getState(),
  );
  return dispatch(
    initPayments({ ...params, cardPayments: cardPaymentsToRetry }),
  );
};

export const functions = [
  {
    actionOnClick: cancelPayments,
    text: 'Cancel',
    name: FUNC_BUTTONS.CANCEL,
    variant: 'danger',
  },
  {
    actionOnClick: retryPayments,
    text: 'Retry',
    name: FUNC_BUTTONS.RETRY,
    variant: 'warning',
  },
];
