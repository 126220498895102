import { createSelector } from 'reselect';

import { getLastOrder, getProductInOrderByIndex } from 'reducers/ShoppingCart';
import { getSetting } from 'reducers/configs/settings';
import { getIsCurrentSaleAReturn } from 'reducers/sales';

export const sanitizeValue = (value: string): string => {
  let temp = value.replace(/[,]/g, '.').replace(/[^0-9-.]/g, '');
  const isMatch = temp.match(/^(-{0,1})([0-9]*).{0,1}[0-9]*$/g);
  if (isMatch) {
    if (temp.match(/[-]/g)) temp = `-${temp.replace(/[-]/g, '')}`;
    if (temp.match(/^[.]/g)) temp = temp.replace(/^[.]/g, '0.');
    if (temp.match(/^[-]+[.]/g)) temp = temp.replace(/^[-]+[.]/g, '-0.');
    if (temp.match(/^[0]+[1-9]/g)) temp = temp.replace(/^[0]/g, '');
  }
  return temp;
};

/**
 * Reduces over charTimeStampArray, trying to find the latest sequence of inputs within the specified allowedRange.
 *
 * Returns a tuple [timeStamp, string], where timeStamp is the timestamp of the first (oldest) element in the aforementioned sequence
 * and string is the string value of all elements within range(based on their timeStamp and allowedRange), concatenated in chronological order (smallest timestamp goes first)
 *
 * @example
 * const charTimeStampArray = [[1234, "a"], [1244, "b"], [5555, "c"]];
 * const allowedRange = 20;
 * const result = connectLastCharsInRange(charTimeStampArray, allowedRange);
 * // result = [1234, "ba"]
 */
export const connectLastCharsInRange = (
  charTimeStampArray: [number, string][],
  allowedRange: number,
) =>
  charTimeStampArray.reduce(
    (acc, nextTs) => {
      const [prevTimeStamp, prevValue] = acc;
      if (prevTimeStamp < 0) return acc;
      const [timeStamp, value] = nextTs;
      if (prevTimeStamp - timeStamp < allowedRange && value) {
        return [timeStamp, value + prevValue];
      }
      return [-1, prevValue];
    },
    [0, ''],
  );

/**
 * Checks if two timestamps are within range to detect if
 *
 * If the 2 input timestamps are within range smaller or equal than the allowedRange for scanner, the inputs are from scanning
 * @param oldTS - first input timestamp
 * @param newTS - new input timestamp
 * @param allowedRange - allowed range between 2 inputs to consider the inputs a scanner entry
 */
export const isScannerInput = (
  oldTS: number,
  newTS: number,
  allowedRange = 100,
) => oldTS && newTS - oldTS <= allowedRange;

export const getAutoFocusInputValue = (orderIndex: string) =>
  createSelector(
    getProductInOrderByIndex(orderIndex),
    getLastOrder,
    getSetting('pos_focus_amount_for_last_item_in_shopping_cart'),
    (order, { orderIndex: lastOrderIndex }, focusAmountInputForCurrentItem) =>
      order.basePriceWithVAT > 0 &&
      !!Number(focusAmountInputForCurrentItem) &&
      orderIndex === lastOrderIndex &&
      order.amount === 1 &&
      !order.addedByScanner,
  );

export const getOrderAmount = (orderIndex: string) =>
  createSelector(
    getProductInOrderByIndex(orderIndex),
    order => order?.amount as string | number,
  );

export const getDisableOrderQty = (orderIndex: string) =>
  createSelector(
    getOrderAmount(orderIndex),
    getIsCurrentSaleAReturn,
    (amount, isCurrentSaleAReturn) => isCurrentSaleAReturn && amount < 0,
  );
