import React, { FC, useState } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import { Button } from '@material-ui/core';

const responsive: ResponsiveType = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 750 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 750, min: 0 },
    items: 2,
  },
};

type ThumbnailCarouselProps = {
  imageUrls: string[];
  setCurrentItem: (arg: number) => void;
};

const ThumbnailCarousel: FC<ThumbnailCarouselProps> = ({
  imageUrls,
  setCurrentItem,
}) => {
  const { t } = useTranslation('shoppingCart', { keyPrefix: 'productForm' });
  const [showThumbnails, setShowThumbnails] = useState(false);

  if (imageUrls.length < 2) return null;

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={() => setShowThumbnails(v => !v)}
      >
        {showThumbnails
          ? t('hide_image_navigation')
          : t('show_image_navigation')}
      </Button>
      {showThumbnails ? (
        <div className="thumbnails-container">
          <Carousel responsive={responsive}>
            {imageUrls.map((imageUrl, index) => (
              <div
                key={imageUrl}
                className="carousel-thumbnail-item"
                onClick={() => setCurrentItem(index)}
              >
                <img src={imageUrl} alt={imageUrl} />
              </div>
            ))}
          </Carousel>
        </div>
      ) : null}
    </>
  );
};

export default ThumbnailCarousel;
