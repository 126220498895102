import { addWarning } from 'actions/Error';
import {
  getCardPayments,
  getCardPaymentsForIntegration,
  getEmployeeID,
} from 'reducers/Payments';
import { getMarketplaceSPS } from 'reducers/configs/marketplace';
import {
  deletePayment,
  markForProcessing,
  unmarkFromProcessing,
} from 'actions/Payments';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA.ts';
import { getClientCode } from 'reducers/Login';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';

import { setCustomer } from 'actions/CustomerSearch/setCustomer';

import {
  cancelAdyenPayment,
  checkAdyenTransactionStatus,
  checkTerminalDiagnosis,
  initialSetup,
  processPayments,
  waitServiceAvailable,
} from './requestHandlers';
import { printReceipt as print } from './printing';

export const title = 'Adyen Terminal Interface';

export const initPayments = params => async (dispatch, getState) => {
  const state = getState();
  const { value: adyenConfig } = getCafaEntry(
    'adyen',
    INTEGRATION_TYPES.payment,
  )(state);
  adyenConfig.currencyCode = params.currencyCode;
  adyenConfig.setCustomer = props => dispatch(setCustomer(props));
  const clientCode = getClientCode(state);
  const splits = getMarketplaceSPS(state);
  const employeeID = getEmployeeID(state);
  const printReceipt = data => dispatch(print(data));
  const cardPayments = getCardPayments(getState());

  await waitServiceAvailable(params);
  await dispatch(
    processPayments({
      ...params,
      clientCode,
      cardPayments,
      printReceipt,
      adyenConfig,
      splits,
      employeeID,
    }),
  )
    .then(({ errors, data }) => {
      const shouldVoid = getCardPaymentsForIntegration('adyen')(
        getState(),
      ).every(p => p.shouldProcess && p.paid);
      if (errors) {
        console.error('adyen:processPayments:errors\n', { errors, data });
        params.enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
      } else if (shouldVoid) {
        params.rejectPayments();
      } else {
        params.resolvePayments();
      }
    })
    .catch(err => {
      console.error('adyen:processPayments', err);
    });
};

/**
 * Check current transaction status
 * @param {Object}  params - hooks and state from cardPaymentUI
 */
const handleStatusCheck = params => async (dispatch, getState) => {
  const { value: adyenConfig } = getCafaEntry(
    'adyen',
    INTEGRATION_TYPES.payment,
  )(getState());
  const printReceipt = data => dispatch(print(data));
  const employeeID = getEmployeeID(getState());
  dispatch(
    checkAdyenTransactionStatus({
      ...params,
      printReceipt,
      adyenConfig,
      employeeID,
    }),
  );
};

/**
 * Cancel ongoing transaction
 *
 * @param {Object}  params - hooks and state from cardPaymentUI
 */

const handleCancel = params => async (dispatch, getState) => {
  const state = getState();
  const employeeID = getEmployeeID(state);
  const { value: adyenConfig } = getCafaEntry(
    'adyen',
    INTEGRATION_TYPES.payment,
  )(getState());

  const sumPmtAmounts = params.cardPayments
    .map(pmt => Number(pmt.amount))
    .reduce((a, b) => a + b, 0);
  // TODO: This distinction should probably be per-payment, not per-sale
  const isPaymentOrReturn = sumPmtAmounts < 0 ? 'Reversal' : 'Payment';

  await cancelAdyenPayment({
    ...params,
    adyenConfig,
    employeeID,
    isPaymentOrReturn,
  });
};

/**
 * Retry last failed / cancelled transaction
 *
 */
const handleRetry = params => async (dispatch, getState) => {
  const { value: adyenConfig } = getCafaEntry(
    'adyen',
    INTEGRATION_TYPES.payment,
  )(getState());
  await dispatch(initPayments({ ...params, adyenConfig }));
};

/**
 * Close Payments Button handler
 *
 */

const closeModal = params => async (dispatch, getState) => {
  const integrationCardPayments = getCardPaymentsForIntegration('adyen')(
    getState(),
  );
  try {
    const state = getState();
    const employeeID = getEmployeeID(state);

    const { value: adyenConfig } = getCafaEntry(
      'adyen',
      INTEGRATION_TYPES.payment,
    )(getState());

    const sumPmtAmounts = params.cardPayments
      .map(pmt => Number(pmt.amount))
      .reduce((a, b) => a + b, 0);
    // TODO: This distinction should probably be per-payment, not per-sale
    const isPaymentOrReturn = sumPmtAmounts < 0 ? 'Reversal' : 'Payment';

    await cancelAdyenPayment({
      ...params,
      adyenConfig,
      employeeID,
      isPaymentOrReturn,
    });
  } catch (err) {
    console.error('Failed to cancel adyen payment', err);
  } finally {
    await Promise.all(
      integrationCardPayments.map(p =>
        dispatch(unmarkFromProcessing({ key: p.key })),
      ),
    );
    await params.rejectPayments();
  }
};

/**
 * Handles close modal from cardPaymentUI top right corner `X` button
 *
 * Disabled in PBIB-4840 because it was possible to use this to
 * exit to the payment screen without the transaction actually getting cancelled.
 * There are already function buttons to do the same thing,
 * but unlike the 'x' those are enabled/disabled at appropriate times,
 * so no reason to have a more dangerous duplicate up here.
 *
 * If we want to have the x button anyway, then the logic needs to validate that
 * the transaction is actually cancelled and not exit out otherwise
 */
const cancelPayments = params => async (dispatch, getState) => {
  const { value: adyenConfig } = getCafaEntry(
    'adyen',
    INTEGRATION_TYPES.payment,
  )(getState());
  dispatch(closeModal({ ...params, adyenConfig }));
};

/**
 * Void successful payments when closing the Payment window
 *
 */

export const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('adyen')(getState());
  cardPayments.forEach(({ key, paid }) =>
    paid
      ? dispatch(markForProcessing({ key }))
      : dispatch(deletePayment({ key })),
  );

  const finalPayments = getCardPaymentsForIntegration('adyen')(getState());
  if (finalPayments.length) {
    await dispatch(openTerminalIntegration());
  }
};

/**
 * Terminal Diagnosis
 */

const terminalDiagnosis = params => async (dispatch, getState) => {
  const { value: adyenConfig } = getCafaEntry(
    'adyen',
    INTEGRATION_TYPES.payment,
  )(getState());
  checkTerminalDiagnosis({ ...params, adyenConfig });
};

/**
 * Function buttons in CardPaymentUI
 *
 */

export const functions = [
  {
    actionOnClick: handleStatusCheck,
    name: FUNC_BUTTONS.CHECK_STATUS,
    text: 'Payment Status',
    variant: 'warning',
  },
  {
    actionOnClick: handleCancel,
    name: FUNC_BUTTONS.CANCEL,
    text: 'Cancel Payment',
    variant: 'danger',
  },
  {
    actionOnClick: handleRetry,
    name: FUNC_BUTTONS.RETRY,
    text: 'Retry Payment',
    variant: 'warning',
  },
  {
    actionOnClick: terminalDiagnosis,
    name: FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
    text: 'Terminal Diagnosis',
    variant: 'warning',
  },
  {
    actionOnClick: closeModal,
    name: FUNC_BUTTONS.CLOSE,
    text: 'Close',
    variant: 'danger',
  },
];

export const integrationSetup = () => async dispatch => {
  const warn = warning =>
    dispatch(
      addWarning(warning, {
        dismissible: false,
        selfDismiss: 3500,
      }),
    );
  dispatch(initialSetup(warn));
};
