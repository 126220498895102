import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setPayment } from 'actions/Payments/setPayment';
import { chainPromises } from 'utils';
import { RootState } from 'reducers';
import { Payment } from 'types/Payment';

import { getIntegration } from './getIntegration';

export function voidWithIntegration(integration: string, payments: Payment[]) {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    if (integration === 'none') {
      return chainPromises(
        ...payments.map(pmt => () =>
          dispatch(setPayment({ ...pmt, paid: false })),
        ),
      );
    }
    return dispatch(getIntegration(integration)).then(data =>
      dispatch(data.voidPayments({ cardPayments: payments })),
    );
  };
}
