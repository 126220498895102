import { doClientRequest, requestAllRecords } from './core/ErplyAPI';

/**
 * @param params
 * @param from Product index to fetch from
 * @param count How many products to fetch after the index (at most)
 * @returns {Promise<{records: *, status: *}>}
 */
export const getProducts = (params, from = 0, count = Infinity) =>
  requestAllRecords({ ...params, request: 'getProducts' }, from, count);

export const saveProduct = params =>
  doClientRequest({ ...params, request: 'saveProduct' });
