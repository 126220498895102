import { getPluginSelector } from 'reducers/Plugins';

import {
  SET_SCREENS,
  SET_FETCHED_INITIAL_SCREEN_DETAILS,
  SET_SELECTED_SCREEN,
  SET_HAS_PERMISSION,
} from './actionTypes';

import { pluginID } from '..';

interface ScreenDetailed extends Screen {
  availLeft: number;
  availTop: number;
  left: number;
  top: number;
  isPrimary: boolean;
  isInternal: boolean;
  devicePixelRatio: number;
  label: string;
}

type CustomerDisplayState = {
  hasPermission: boolean | null;
  fetchedInitialScreenDetails: boolean;
  screens: ScreenDetailed[];
  selectedScreen: { screen: ScreenDetailed; index: number } | null;
};

const customerDisplayReducer = (
  state = {
    hasPermission: null,
    fetchedInitialScreenDetails: false,
    screens: [],
    selectedScreen: null,
  },
  action,
): CustomerDisplayState => {
  switch (action.type) {
    case SET_HAS_PERMISSION:
      return { ...state, hasPermission: action.payload };
    case SET_FETCHED_INITIAL_SCREEN_DETAILS:
      return { ...state, fetchedInitialScreenDetails: action.payload };
    case SET_SCREENS:
      return { ...state, screens: action.payload };
    case SET_SELECTED_SCREEN:
      return { ...state, selectedScreen: action.payload };
    default:
      return state;
  }
};

export const getCustomerDisplayState = state =>
  getPluginSelector<CustomerDisplayState>(pluginID)(n => n)(state);

export default customerDisplayReducer;
