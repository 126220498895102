import { ThunkAction } from 'redux-thunk';

import { PosPlugin } from '../../plugin';
import { EFSTAConf } from '../types';

export interface FiscalIntegration {
  onOpenPaymentModal: Required<Required<PosPlugin>['onOpenPaymentModal']>['on'];
  onSaveDocument(requests: any): ThunkAction<any, any, any, any>;
  onCashInOut(sum: any): ThunkAction<any, any, any, any>;
  onCloseDay(): ThunkAction<any, any, any, any>;
  ComponentConfiguration: PosPlugin<EFSTAConf>['ComponentConfiguration'];
  ComponentHeader: PosPlugin['ComponentHeader'];
  UICustomTableBillRow?: PosPlugin['UICustomTableBillRow'];
  getStatus: PosPlugin['getStatus'];
}

export const NULLFiscalIntegration: FiscalIntegration = {
  ComponentConfiguration: () => null,
  ComponentHeader: () => null,
  onCloseDay: () => async () => {
    throw new Error('No fiscal integration selected');
  },
  onCashInOut: (sum: any) => async () => {
    throw new Error('No fiscal integration selected');
  },
  onSaveDocument: (requests: any) => async () => {
    throw new Error('No fiscal integration selected');
  },
  onOpenPaymentModal: (p, ap) => async () => {
    throw new Error('No fiscal integration selected');
  },
  getStatus: () => ({
    type: 'error',
    message: 'No country selected',
  }),
};
