import swedbankRequest from './index';

const closeDay = async () => {
  try {
    const reconciliation = await swedbankRequest.get('closeBatch');

    if (reconciliation && reconciliation.data.records.length) {
      const result = reconciliation.data.records[0];
      if (result.transactionStatus === 'Operation succeeded') {
        return true;
      }
      throw new Error('Error while reconciling payments.');
    }
  } catch (error) {
    return false;
  }
  return true;
};

export default closeDay;
