import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Divider } from '@material-ui/core';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getCustomerSearchResultsConfiguration } from 'reducers/configs/settings';
import {
  saveTempSetting,
  commitTempSettings,
  dropTempSettings,
} from 'actions/configs';
import { useAppDispatch } from 'reducers';
import SaveButton from 'components/CustomButtons/SaveButton';

import { Checkbox, Section } from '../../components/Inputs';

function CustomerSearchResults({ onClose: goToPreviousModalPage }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('settingsCustomerSearchResults');

  const CONFIG_NAME = 'customer_search_results_configuration';

  const onClose = () => {
    dispatch(dropTempSettings([CONFIG_NAME]));
    goToPreviousModalPage();
  };

  const config = useSelector(getCustomerSearchResultsConfiguration);

  const set = <
    TKey extends keyof typeof config,
    TValue extends typeof config[TKey]
  >(
    key: TKey,
    value: TValue,
  ) => {
    dispatch(
      saveTempSetting(CONFIG_NAME, {
        ...config,
        [key]: value ? 1 : 0,
      }),
    );
  };

  const onSave = () => {
    dispatch(commitTempSettings([CONFIG_NAME])).then(onClose);
  };

  return (
    <>
      <Box
        padding="1.5rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">{t('title')}</Typography>
        <Box display="flex" alignItems="center">
          <SaveButton action={onSave} />
          <CloseButton action={onClose} />
        </Box>
      </Box>
      <Divider />

      <Box padding="1rem">
        <Typography variant="h5">{t('introduction')}</Typography>
        <Typography variant="h6">{t('instructions')}</Typography>
        <Section title={t('sections.normalGrid')}>
          <Checkbox onChange={set} name="customerID" value={config.customerID}>
            {t('customerID')}
          </Checkbox>
          <Checkbox onChange={set} name="name" value={config.name}>
            {t('name')}
          </Checkbox>
          <Checkbox onChange={set} name="email" value={config.email}>
            {t('email')}
          </Checkbox>
          <Checkbox onChange={set} name="phone" value={config.phone}>
            {t('phone')}
          </Checkbox>
          <Checkbox onChange={set} name="mobile" value={config.mobile}>
            {t('mobile')}
          </Checkbox>
          <Checkbox onChange={set} name="postalCode" value={config.postalCode}>
            {t('postalCode')}
          </Checkbox>
          <Checkbox onChange={set} name="cardNr" value={config.cardNr}>
            {t('cardNr')}
          </Checkbox>
          <Checkbox onChange={set} name="address" value={config.address}>
            {t('address')}
          </Checkbox>
        </Section>

        <Section title={t('sections.retailGrid')}>
          <Checkbox
            onChange={set}
            name="retail_customerID"
            value={config.retail_customerID}
          >
            {t('customerID')}
          </Checkbox>
          <Checkbox
            onChange={set}
            name="retail_name"
            value={config.retail_name}
          >
            {t('name')}
          </Checkbox>
          <Checkbox
            onChange={set}
            name="retail_email"
            value={config.retail_email}
          >
            {t('email')}
          </Checkbox>
          <Checkbox
            onChange={set}
            name="retail_phone"
            value={config.retail_phone}
          >
            {t('phone')}
          </Checkbox>
          <Checkbox
            onChange={set}
            name="retail_mobile"
            value={config.retail_mobile}
          >
            {t('mobile')}
          </Checkbox>
          <Checkbox
            onChange={set}
            name="retail_postalCode"
            value={config.retail_postalCode}
          >
            {t('postalCode')}
          </Checkbox>
          <Checkbox
            onChange={set}
            name="retail_cardNr"
            value={config.retail_cardNr}
          >
            {t('cardNr')}
          </Checkbox>
          <Checkbox
            onChange={set}
            name="retail_address"
            value={config.retail_address}
          >
            {t('address')}
          </Checkbox>
        </Section>
      </Box>
    </>
  );
}

export default CustomerSearchResults;
