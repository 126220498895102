import {
  Box,
  TextField,
  TextFieldProps,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { useDebounce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getCustomers } from 'services/ErplyAPI';
import { setPayment } from 'actions/Payments/setPayment';
import { Customer } from 'types/Customer';
import {
  getPaymentsSum,
  getCurrentSalesDocPaymentsCombo,
  getCurrentSalesDocument,
  getPaymentsTotal,
} from 'reducers/Payments';
import { addError } from 'actions/Error';
import { sanitizePrice } from 'actions/CreateNew';
import { RootState } from 'reducers';

import { getTafReduxState } from '../rdx/reducers';
import {
  PARTNER_ORGANISATION_GROUP_ID,
  ASSOCIATION_COMPANY_TYPE_ID,
} from '../constants';

type State = {
  company: Customer | null;
  poNumber: string | null;
  companyPaidAmount: number | null;
  invoiceTotal: number;
  outstandingAmount: number;
  paid: number;
};

const round = (num: number) => Math.round(num * 100) / 100;

const FullWidthField: React.FC<TextFieldProps> = props => {
  return <TextField variant="outlined" fullWidth {...props} />;
};

const PartnerModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const total = useSelector(getPaymentsTotal);
  const { total: currentSalesDocumentTotal } = useSelector(
    getCurrentSalesDocument,
  );
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const reduxState = useSelector(getTafReduxState);

  const paymentsSum = useSelector(getPaymentsSum);
  const originalPaymentsEnhanced = useSelector(getCurrentSalesDocPaymentsCombo);

  const [fields, setFields] = useState<State>({
    company: null,
    poNumber: null,
    companyPaidAmount: null,
    invoiceTotal: round(Math.abs(currentSalesDocumentTotal ?? total)),
    outstandingAmount: round(Math.abs(total) - Math.abs(paymentsSum)),
    paid: Number(
      originalPaymentsEnhanced.find(pmt => pmt.type === 'PARTNER')?.sum ?? 0,
    ),
  });

  const [searchValue, setSearchValue] = useState('');
  const [customers, setCustomers] = useState<Customer[]>([]);

  useDebounce(
    async () => {
      const records = await getCustomers({
        searchName: searchValue,
        groupID: PARTNER_ORGANISATION_GROUP_ID,
        recordsOnPage: 50,
        companyTypeID: ASSOCIATION_COMPANY_TYPE_ID,
      });
      setCustomers(records);
    },
    500,
    [searchValue],
  );

  const {
    company,
    poNumber,
    companyPaidAmount,
    invoiceTotal,
    outstandingAmount,
    paid,
  } = fields;

  useEffect(() => {
    setFields(prevFields => ({
      ...prevFields,
      paid: round(companyPaidAmount || 0),
      outstandingAmount: round(
        invoiceTotal - Math.abs(paymentsSum) - (companyPaidAmount || 0),
      ),
    }));
  }, [companyPaidAmount, paymentsSum, invoiceTotal]);

  const onChange = useCallback(
    (key: keyof typeof fields) => (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const { name, value } = event.target;
      const isNumber = name === 'numberField';
      const sanitizedValue = isNumber ? sanitizePrice(value) : value;
      return R.pipe(R.assoc(key), setFields)(sanitizedValue);
    },
    [setFields, fields],
  );

  const errors = useMemo(() => {
    // All required
    const errors = {
      company: company ? null : 'Please choose company',
      poNumber: poNumber ? null : 'Please choose PO/ID number',
      companyPaidAmount: companyPaidAmount ? null : 'Please choose amount',
    };
    // Extra validation rules
    if (Number(companyPaidAmount) > Number(invoiceTotal))
      errors.companyPaidAmount = 'Paid amount can not be bigger than total';

    return errors;
  }, [company, poNumber, companyPaidAmount, invoiceTotal]);

  return (
    <Box padding="1rem">
      <Box
        display="flex"
        padding="0.75rem 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">Partner</Typography>
        <Box display="flex" alignItems="center">
          <Button
            disabled={Object.values(errors).some(Boolean)}
            onClick={async () => {
              if (!reduxState.paymentTypeID) {
                dispatch(
                  addError('Please verify that payment type PARTNER exists.'),
                );
                return;
              }

              await dispatch(
                setPayment({
                  amount: Number(companyPaidAmount).toFixed(2),
                  key: 'PARTNER',
                  caption: 'PARTNER',
                  typeID: reduxState.paymentTypeID,
                  customerID: poNumber,
                  info: String(company?.email), // Not requested, but used to send email later
                  company,
                }),
              );
              onClose();
            }}
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
          <CloseButton action={onClose} />
        </Box>
      </Box>
      <Divider />
      <Box gridGap="1rem" display="flex" flexDirection="column">
        <Autocomplete
          options={customers}
          inputValue={searchValue}
          value={fields.company}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(_event, company, reason) => {
            if (company?.customerID) {
              setFields(prevFields => ({
                ...prevFields,
                company,
              }));
              return;
            }
            if (reason === 'clear') {
              setFields(prevFields => ({ ...prevFields, company: null }));
            }
          }}
          onInputChange={(e, newValue) => setSearchValue(newValue)}
          getOptionLabel={option => option.fullName}
          renderInput={params => (
            <FullWidthField
              label="Search customer"
              error={!!errors.company}
              helperText={errors.company}
              {...params}
            />
          )}
        />
        <FullWidthField
          value={poNumber}
          helperText={errors.poNumber}
          error={!!errors.poNumber}
          onChange={onChange('poNumber')}
          label="PO/ID number"
        />
        <FullWidthField
          onChange={onChange('companyPaidAmount')}
          label="Company paid amount"
          name="numberField"
          helperText={errors.companyPaidAmount}
          error={!!errors.companyPaidAmount}
          value={companyPaidAmount}
        />
        <FullWidthField
          onChange={onChange('invoiceTotal')}
          label="Invoice total"
          value={invoiceTotal}
          disabled
        />
        <FullWidthField
          onChange={onChange('outstandingAmount')}
          label="Outstanding amount"
          value={outstandingAmount}
          disabled
        />
        <FullWidthField
          onChange={onChange('paid')}
          label="Paid"
          value={paid}
          disabled
        />
      </Box>
    </Box>
  );
};

export default PartnerModal;
