import React, { useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import InputField from 'components/FieldTypes/InputField';
import { PosPlugin } from 'plugins/plugin';
import { getCustomerGroups } from 'reducers/customerSearch';
import { CustomerGroup } from 'types/CustomerGroup';
import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { getPluginConfiguration } from 'reducers/Plugins';
import { ProductGroupInput } from 'components/inputs/ProductGroupInput';

export type Configuration = {
  defaultCustomerGroup: CustomerGroup['customerGroupID'];
  defaultCompanyGroup: CustomerGroup['customerGroupID'];
  employeeSelectProductGroup: number;
};

export const ComponentConfiguration: PosPlugin<
  Configuration
>['ComponentConfiguration'] = ({ current, save }) => {
  const groups = useSelector(getCustomerGroups) as CustomerGroup[];

  const [conf, setConf] = useState(
    R.mergeDeepLeft(current, {
      defaultCustomerGroup: groups[0]?.customerGroupID,
      defaultCompanyGroup: groups[0]?.customerGroupID,
    }),
  );

  const { defaultCompanyGroup, defaultCustomerGroup } = conf;

  const dispatch = useDispatch();

  const options = groups.map(group => ({
    name: group.name,
    value: group.customerGroupID,
  }));

  const handleSave = () => {
    save(conf);
    dispatch(previousModalPage());
  };

  const onChange = ({ target: { name, value } }) => {
    setConf(R.assoc(name, value));
  };

  return (
    <>
      <UIButton variant="POS" action={() => handleSave()} text="SAVE" />
      <InputField
        type="select"
        value={defaultCustomerGroup}
        className="mt-2"
        options={options}
        title="Person"
        name="defaultCustomerGroup"
        onChange={onChange}
      />
      <InputField
        type="select"
        value={defaultCompanyGroup}
        options={options}
        title="Company"
        name="defaultCompanyGroup"
        className="mt-2"
        onChange={onChange}
      />
      <Box margin={1}>
        <ProductGroupInput
          value={conf.employeeSelectProductGroup}
          fullWidth
          allowNone
          label="Product group that triggers employee select"
          name="employeeSelectProductGroup"
          variant="outlined"
          onChange={onChange}
        />
      </Box>
    </>
  );
};
export const pluginID = 'idealCustomizations';
export const getIDealConfiguration = getPluginConfiguration<Configuration>(
  pluginID,
);
