import { ThemeOptions, createTheme } from '@material-ui/core';
import * as R from 'ramda';

import sharedCssColors from 'assets/themes/colors.scss';
import highContrastCssColors from 'assets/themes/highContrast/colors.scss';

import {
  addThemeColors,
  baseThemeOptions,
  createAugmentColorsFunction,
} from './utils';

const augmentColors = createAugmentColorsFunction({
  palette: {
    contrastThreshold: 7,
  },
});

const sharedColors = augmentColors(sharedCssColors);
const highContrastColors = augmentColors(highContrastCssColors);

const themeOptions: ThemeOptions = {
  palette: {
    contrastThreshold: 7,
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: highContrastColors.background.paper,
        border: `1px solid ${highContrastColors.divider}`,
      },
    },
    MuiInputLabel: {
      root: {
        color: highContrastColors.divider,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: highContrastColors.input?.main,
        '&$disabled': {
          backgroundColor: highContrastColors.input?.light,
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: highContrastColors.divider,
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: highContrastColors.divider,
        backgroundColor: highContrastColors.background.paper,
      },
      head: {
        borderWidth: '2px',
        backgroundColor: highContrastColors.background.default,
        borderTop: `1px solid ${highContrastColors.divider}`,
      },
      stickyHeader: {
        backgroundColor: highContrastColors.background.default,
      },
    },
    MuiButtonGroup: {
      groupedContainedHorizontal: {
        '&:not(:last-child)': {
          borderRightColor: `${highContrastColors.background.default} !important`,
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: highContrastColors.background.paper,
        color: highContrastColors['text-primary'],
        border: `1px solid ${highContrastColors.divider}`,
      },
      deleteIcon: {
        color: highContrastColors.divider,
      },
    },
  },
};

const highContrastThemeOptions = R.pipe(
  R.mergeDeepRight(themeOptions),
  R.assocPath(['palette', 'type'], 'dark'),
  addThemeColors(highContrastColors),
  addThemeColors(sharedColors),
)(baseThemeOptions);

export const highContrastTheme = createTheme(highContrastThemeOptions);
