/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import localForage from 'localforage';

let dispatch = () => {};
// eslint-disable-next-line no-return-assign
export const storePlugin = store => ({ dispatch } = store);

// Each client is assigned an instance of a local database which can have multiple tables in which multiple stores can exist.
export default class LocalDatabase {
  constructor({ TABLE_NAME }) {
    this.TABLE_NAME = TABLE_NAME;
    this.storeKey = 'store';
    this.databaseName = 'POS Brazil DB';
    this.localForageInstances = {};
    this.localData = {};
  }

  async initiate({ CLIENT_CODE }) {
    this.CLIENT_CODE = CLIENT_CODE;
    await this.getDB();
  }

  async getDB() {
    try {
      if (this.CLIENT_CODE) {
        if (!this.localForageInstances[this.CLIENT_CODE]) {
          this.localForageInstances[
            this.CLIENT_CODE
          ] = localForage.createInstance({
            name: `${this.databaseName} ${this.CLIENT_CODE}`,
            storeName: this.TABLE_NAME,
          });
        }
        const instance = this.localForageInstances[this.CLIENT_CODE];

        this.localData[this.CLIENT_CODE] = {};
        this.localData[this.CLIENT_CODE] = await this.getItems();

        return instance;
      }
    } catch (e) {
      console.error('Failed to create indexedDB instance', this, e);
    }
    return null;
  }

  async getItem({ key = this.storeKey } = { key: this.storeKey }) {
    try {
      if (this.CLIENT_CODE) {
        const instance = await this.getDB();
        return instance.getItem(key);
      }
    } catch (e) {
      console.error('Failed to read item from indexedDB', { key }, this, e);
    }
    return null;
  }

  async getItems() {
    try {
      const instance = this.localForageInstances[this.CLIENT_CODE];
      const allItems = {};
      await instance.iterate((value, key) => {
        Object.assign(allItems, { ...allItems, [key]: value });
      });
      return allItems;
    } catch (e) {
      console.error('Failed to read items from indexedDB', this, e);
    }
    return null;
  }

  async saveItem({ key = this.storeKey, value }) {
    try {
      this.localData[this.CLIENT_CODE][key] = value;
      const instance = this.localForageInstances[this.CLIENT_CODE];
      await instance.setItem(key, value);
      dispatch({ type: 'Local database updated', payload: {} });
    } catch (e) {
      console.error(
        'Failed to write item to indexedDB',
        { [key]: value },
        this,
        e,
      );
    }
    return null;
  }

  async removeItem({ key = this.storeKey } = { key: this.storeKey }) {
    try {
      this.localData[this.CLIENT_CODE][key] = null;
      const instance = this.localForageInstances[this.CLIENT_CODE];
      await instance.removeItem(key);
      dispatch({ type: 'Local database updated', payload: {} });
    } catch (e) {
      console.error('Failed to delete item from indexedDB', key, this, e);
    }
    return null;
  }

  async resetTable() {
    try {
      const instance = this.localForageInstances[this.CLIENT_CODE];
      if (!instance) {
        console.error(
          'Tried to reset table',
          this,
          this.clientCode,
          'but not instance could be found',
        );
        return;
      }
      await instance.clear();
      delete this.localData[this.clientCode];
      dispatch({ type: 'Local database updated', payload: {} });
    } catch (e) {
      console.error('Failed to reset indexedDB table', this, e);
    }
  }
}
