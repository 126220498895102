import { useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

const posHandlers = [];
const wrapperHandlers = [];
const mc = window.MessageChannel
  ? new window.MessageChannel()
  : { port1: {}, port2: {} };

const log = (...params) => {
  if (!localStorage.getItem('visibleconsole:paymentIntegration/wrapper'))
    return;
  window.visibleLog &&
    window.visibleLog(
      ...params.map(param => (param.substring ? param : JSON.stringify(param))),
    );
  // Only in explicit debug mode, therefore OK to use console
  // eslint-disable-next-line no-console
  console.log('visibleConsole', ...params);
};

window.wrapperMessageChannel = mc;
const original1 = mc.port1.postMessage;
mc.port1.postMessage = msg => {
  original1.apply(mc.port1, [msg]);
  if (window?.webkit?.messageHandlers?.wrapperListener) {
    window.webkit.messageHandlers.wrapperListener.postMessage(msg);
  }
};

// region stringifyOption
const original = mc.port1.postMessage;
mc.port1.postMessage = (msg, ...rest) => {
  original.apply(mc.port1, [msg, ...rest]);
  if (window.wrapperStringifyOnMessage) {
    window.wrapperStringifyOnMessage.apply(null, [
      JSON.stringify(msg),
      ...rest,
    ]);
  }
  log('Sent message', msg);
};
window.wrapperStringifyPostMessage = msg => {
  mc.port2.postMessage(JSON.parse(msg));
};
// endregion stringifyOption

const original2 = mc.port2.postMessage;
mc.port2.postMessage = msg => {
  original2.apply(mc.port2, [msg]);
};
window.wrapperMessageChannel.port1.onmessage = e => {
  if (posHandlers.filter(h => h.type === e.data.type).length === 0) {
    log('Received message that POS was not listening for', e.data);
  } else {
    log('Received message', e.data);
  }
  posHandlers
    .filter(h => h.type === e.data.type)
    .forEach(h => h.handler(e.data));
};
window.wrapperMessageChannel.port2.onmessage = e => {
  wrapperHandlers
    .filter(h => h.type === e.data.type)
    .forEach(h => h.handler(e.data));
};
window.test = { posHandlers, wrapperHandlers };

const addOnMessage = (handlers, type, handler) => {
  const h = {
    type,
    handler: data => {
      if (data.type === type) {
        handler(data.payload);
      }
    },
  };
  handlers.push(h);
  return () => handlers.splice(handlers.indexOf(h), 1);
};

export const posAddOnMessage = (type, handler) =>
  addOnMessage(posHandlers, type, handler);
export const posSendMessage = type => payload =>
  mc.port1.postMessage({ type, payload });

const useOnMessage = (handlers, type, handler) => {
  useEffect(() => addOnMessage(handlers, type, handler), [
    handlers,
    type,
    handler,
  ]);
};
export const useWrapperOnMessage = (type, handler) => {
  useOnMessage(wrapperHandlers, type, handler);
};
export const usePosOnMessage = (type, handler) => {
  useOnMessage(posHandlers, type, handler);
};
export const useWrapperSendMessage = type =>
  useCallback(
    payload =>
      window.wrapperMessageChannel.port2.postMessage({
        type,
        requestID: uuidv4(),
        payload,
      }),
    [type],
  );

export const usePosSendMessage = type =>
  useCallback(
    (payload = {}) => {
      window.wrapperMessageChannel.port1.postMessage({
        type,
        requestID: uuidv4(),
        payload,
      });
    },
    [type],
  );
