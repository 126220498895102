import React, { ReactNode } from 'react';

import { PosPlugin } from 'plugins/plugin';

import { isDebitPayment } from './overrides';

const WithDisabledDebitPayments: PosPlugin['UIOriginalPayment'] = ({
  payment,
  children,
}) => {
  if (!isDebitPayment(payment)) return children;
  return React.Children.map<ReactNode, ReactNode>(children, child => {
    if (!React.isValidElement(child)) return child;
    return React.cloneElement(child, {
      disabled: true,
      onClick: undefined,
      style: { cursor: 'not-allowed' },
    });
  });
};

export default WithDisabledDebitPayments;
