import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './BottomPopup.css';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { fetchClockedInEmployees } from 'actions/clockInOut';
import * as PrinterIntegration from 'actions/integrations/printer';
import { getHasRightToOpenAndCloseDay } from 'reducers/Login';

const MiscView = ({ goBack, close }) => {
  const dispatch = useDispatch();
  const hasRightsToCloseDay = useSelector(getHasRightToOpenAndCloseDay);
  const { t } = useTranslation('gridButtons');

  const openCloseDayPopup = () => {
    dispatch(
      openModalPage({
        component: mp.closeDay,
        isPopup: true,
        modalClassName: 'openCloseDay',
      }),
    );
    close();
  };

  const openCashInOutPopup = () => {
    dispatch(
      openModalPage({
        component: mp.cashInOut,
      }),
    );
    close();
  };

  const openNewCustomerPopup = () => {
    dispatch(
      openModalPage({
        component: mp.createCustomer,
        props: { newCustomer: true },
        groupID: mp.createCustomer,
        replace: true,
      }),
    );
    close();
  };

  const openClockInOutPopup = () => {
    dispatch(fetchClockedInEmployees());
    close();
  };

  const openCashDrawer = () => {
    dispatch(PrinterIntegration.openCashDrawer());
    close();
  };

  return (
    <>
      {hasRightsToCloseDay && (
        <span className="mobile-menu-action" onClick={openCloseDayPopup}>
          {t('functions.closeDay')}
        </span>
      )}
      <span
        className="mobile-menu-action"
        onClick={openCashInOutPopup}
        style={{ marginTop: '14px' }}
      >
        {t('functions.cashInOut')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={openNewCustomerPopup}
        style={{ marginTop: '14px' }}
      >
        {t('functions.addCustomer')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={openClockInOutPopup}
        style={{ marginTop: '14px' }}
      >
        {t('functions.clockInOut')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={openCashDrawer}
        style={{ marginTop: '14px' }}
      >
        {t('functions.closeDrawer')}
      </span>
      <span
        className="btn-cancel mobile-menu-action"
        onClick={() => goBack()}
        style={{ marginTop: '28px' }}
      >
        {t('common:back')}
      </span>
    </>
  );
};

export default MiscView;
