import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { PluginComponent } from 'plugins';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import Pagination from 'components/Pagination';
import { modalPages as mp } from 'constants/modalPage';

import './AdvancedSearchResults.scss';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';

const AdvancedSearchResults = ({ customers }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('advancedCustomerSearch');
  const headers = [
    'fullName',
    'email',
    'birthday',
    'mobile',
    'phone',
    'customerCardNumber',
  ];

  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  const customersPerPage = customers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <div>
      <Modal.Header>
        <Modal.Title>{t(`titles.results`)}</Modal.Title>
        <CloseButton action={() => dispatch(previousModalPage())} />
      </Modal.Header>

      <Modal.Body>
        <Pagination
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalRecords={customers.length}
          setPage={setCurrentPage}
        >
          <table>
            <thead>
              <tr>
                <PluginComponent hookname="UIAdvancedSearchTableColumns">
                  {headers.map(key => {
                    return <th key={key}>{t(`fields.${key}`)}</th>;
                  })}
                </PluginComponent>
              </tr>
            </thead>
            <tbody>
              {customersPerPage.map(customer => {
                return (
                  <tr
                    key={customer.customerID}
                    onClick={() => {
                      dispatch(
                        setCustomer({
                          data: customer.customerID,
                          firstName: customer.firstName,
                          lastName: customer.lastName,
                          recalculateShoppingCart: true,
                          displayCouponsInfo: true,
                          fetchRewardPoints: true,
                        }),
                      );
                      dispatch(closeModalPage(mp.advancedCustomerSearch));
                    }}
                  >
                    <PluginComponent hookname="UIAdvancedSearchTableColumns">
                      {headers.map(key => (
                        <td key={key}>{customer[key]}</td>
                      ))}
                    </PluginComponent>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Pagination>
      </Modal.Body>
    </div>
  );
};

export default AdvancedSearchResults;
