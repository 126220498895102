import dayjs from 'dayjs';

import { getSalesDocuments } from 'services/ErplyAPI/sales';
import { defineReturnedRows } from 'actions/returnProducts';

export const getSalesDocumentsWithReturnedRows = async params => {
  const [payload, payloadFiltered] = await Promise.all([
    getSalesDocuments({
      ...params,
    }),
    getSalesDocuments({
      ...params,
      nonReturnedItemsOnly: 1,
    }),
  ]);

  const returnedRows = defineReturnedRows(
    payload?.flatMap(p => p.rows) ?? [],
    payloadFiltered?.flatMap(pf => pf.rows) ?? [],
  );

  return { docs: payload, returnedRows };
};

export const formatDate = (date: Date | null) => {
  const dateToReturn = dayjs(date as Date).format('YYYY-MM-DD');
  return !date ? '' : dateToReturn;
};
