import React, { useState, Fragment, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import uuidv1 from 'uuid/v1';
import { useTranslation } from 'react-i18next';
import { Button, Grid, makeStyles } from '@material-ui/core';

import {
  getAddressTypes,
  getSelectedCustomerAddresses,
} from 'reducers/customerSearch';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import {
  AddressForm as AddressFormLegacy,
  AddressFormBeta,
} from 'containers/Forms/Customers';
import { getSetting } from 'reducers/configs/settings';
import { updateAddresses } from 'actions/customerSearch';
import Loader from 'components/Loader';

import RemoveIcon from './RemoveIcon';

import './ExtraAddresses.sass';

const useStyles = makeStyles({
  labelContainer: {
    height: '68px',
    '& div:has(> #address-label)': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

const ExtraAddress = ({ customer, updateAddresses: updAdrPrm }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation('addressForm');
  const shouldUseNewCustomerForm = useSelector(
    getSetting('pos_brazil_use_customer_creation_beta'),
  );

  // Throw user out of the menu in case customer is not defined
  useEffect(() => {
    if (!customer) {
      dispatch(previousModalPage());
    }
  }, [customer]);

  const addressTypes = useSelector(getAddressTypes);
  const selectedCustomerAddresses = useSelector(getSelectedCustomerAddresses);
  const [addresses, setAddresses] = useState(
    (customer?.addresses || []).map(ca => {
      const initialAddress = (selectedCustomerAddresses || []).find(
        sca => sca.addressID === ca.addressID,
      );
      return {
        ...ca,
        _id: ca.addressID,
        ownerID: customer.id,
        /**
         * Stringified version of this address as it is currently stored to erply
         * Used in comparisons to detect whether there are unsaved changes
         */
        savedToErplyStringified: JSON.stringify({
          ...(initialAddress ?? {}),
          ownerID: customer.id,
        }),
      };
    }),
  );
  const [toBeRemoved, setToBeRemoved] = useState([]);
  const [saving, setSaving] = useState(false);

  const initAddressState = () => ({
    _id: uuidv1(),
    ownerID: customer.customerID,
    typeID: addressTypes[0].id,
    street: '',
    city: '',
    address2: '',
    postalCode: '',
    state: '',
    country: '',
  });

  const addAddress = () => {
    setAddresses([initAddressState(), ...addresses]);
  };

  const removeAddress = address => {
    if (address.addressID) {
      setToBeRemoved(toBeRemoved.concat(address.addressID));
    } else {
      setAddresses(addresses.filter(a => a._id !== address._id));
    }
  };

  const undoRemoveAddress = addressID =>
    setToBeRemoved(toBeRemoved.filter(address => address !== addressID));

  const handleChange = (id, key, value) => {
    setAddresses(prevAddresses =>
      prevAddresses.map(a => (a._id === id ? { ...a, [key]: value } : a)),
    );
  };

  const onSave = async () => {
    setSaving(true);
    const forDeletion = [];
    const forSaving = [];
    // Spread intentional to avoid addresses flipping in the UI
    [...addresses].reverse().forEach(address => {
      // check if the address is old
      if (address.addressID) {
        // if the id is among the deletion list , add for deleting
        if (toBeRemoved.includes(address.addressID)) {
          forDeletion.push(address);
        } else {
          const { _id, savedToErplyStringified, ...a } = address;
          /* if the starting stringified version of the address is different from the current
            add it for updating */
          if (JSON.stringify(a) !== savedToErplyStringified) {
            forSaving.push(a);
          }
        }
      } else {
        const { _id, ...a } = address;
        forSaving.push(a);
      }
    });

    if (forSaving.length || forDeletion.length) {
      const updatedAddresses = await dispatch(
        updateAddresses(forSaving, forDeletion),
      );
      if (updatedAddresses) updAdrPrm(updatedAddresses);
    }
    dispatch(previousModalPage());
    setSaving(false);
  };

  const AddressForm = useMemo(
    () => (shouldUseNewCustomerForm ? AddressFormBeta : AddressFormLegacy),
    [shouldUseNewCustomerForm, AddressFormLegacy, AddressFormBeta],
  );

  return (
    <div className="extra-addresses">
      <Loader show={saving} block>
        <Modal.Header>
          <Modal.Title>{t('extra.title')}</Modal.Title>
          <div className="extra-addresses-action-buttons">
            <SaveButton action={onSave} disabled={saving} />
            <CloseButton action={() => dispatch(previousModalPage())} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Grid container spacing={2}>
            <Grid item container justifyContent="center">
              <Button variant="outlined" type="primary" onClick={addAddress}>
                {t('extra.linkAddNew')}
              </Button>
            </Grid>
            <Grid item container spacing={2}>
              {addresses.map(address => (
                <Fragment key={address.addressID || address._id}>
                  <Grid
                    item
                    container
                    justify="space-between"
                    alignContent="center"
                    className={styles.labelContainer}
                  >
                    <Grid item>
                      <strong id="address-label">{t('extra.subtitle')}</strong>
                    </Grid>
                    <Grid item>
                      <RemoveIcon
                        isNew={!address.addressID}
                        remove={() => removeAddress(address)}
                        willBeRemoved={toBeRemoved.includes(address.addressID)}
                        undoRemoveAddress={() =>
                          undoRemoveAddress(address.addressID)
                        }
                      />
                    </Grid>
                  </Grid>

                  <AddressForm
                    address={address}
                    onChange={(k, v) => handleChange(address._id, k, v)}
                    addressTypes={addressTypes}
                  />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Modal.Body>
      </Loader>
    </div>
  );
};

export default ExtraAddress;
