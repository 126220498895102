import { getPluginConfiguration } from 'reducers/Plugins';
import { addError } from 'actions/Error';
import { getSelectedWarehouse } from 'reducers/warehouses';

import { requestAllWaivers, setReceiptToWaiver } from '../requests';
import singlePrompt from '../utils';

import { getWaiversReduxState } from './reducers';
import { SET_WAIVERS, DESELECT_WAIVER } from './actionTypes';

export const fetchAllWaivers = () => async (dispatch, getState) => {
  const state = getState();

  const currentConfig: any = getPluginConfiguration('pnp-ewaiver')(state) ?? {};
  const location = getSelectedWarehouse(state).code;

  const date = new Date();

  // const testUnixStart = 1597774259;
  // const testUnixStart = 1593781199;
  // const testUnixEnd = 1597860671;
  // const testUnixEnd = 1596459599;
  // const testLocation = 'location003';

  const startDateUnix = String(date.setHours(0, 0, 1) / 1000);
  const startDate = parseInt(startDateUnix, 10);

  const endDateUnix = String(Date.now() / 1000);
  const endDate = parseInt(endDateUnix, 10);

  try {
    const waivers = await requestAllWaivers({
      startDate,
      endDate,
      location: `location${location}`,
      apiKey: currentConfig.apiKey,
    });

    await dispatch({
      type: SET_WAIVERS,
      payload: [...waivers],
    });

    return [...waivers];
  } catch (error) {
    dispatch(addError(`Error while fetching waivers - ${error}`));
  }

  return [];
};

// check for ensuring that the selected waivers are still available in PNP api meaning that they have not been assigned in another sale
export const validateSelectedWaiverAndFinalizeSale = confirmHandler => async (
  dispatch,
  getState,
) => {
  const state = getState();

  const currentConfig: any = getPluginConfiguration('pnp-ewaiver')(state) ?? {};
  const { selectedWaivers } = getWaiversReduxState(state);
  const selectedWaiverIDs = selectedWaivers.map(waiver => waiver.waiver_id);

  const allWaivers = await dispatch(fetchAllWaivers());

  // Check in Waivers api that the selected waivers are still available
  const availableWaivers = selectedWaiverIDs.map(selectedWaiverID => {
    const currentWaiver = allWaivers.find(
      waiver => waiver.waiver_id === selectedWaiverID,
    );

    if (currentWaiver === undefined) {
      return { isAvailable: false, waiverID: selectedWaiverID };
    }

    return { ...currentWaiver, isAvailable: true };
  });

  const unavailableWaivers = availableWaivers.filter(
    waiver => !waiver.isAvailable,
  );

  if (unavailableWaivers.length) {
    const retryWaiverSelection = async () => {
      return dispatch(
        singlePrompt({
          apiKey: currentConfig.apiKey,
        }),
      );
    };

    const promiseMap = (inputValues, mapper) => {
      const reducer = (accumulator, inputValue) =>
        accumulator.then(async acc => {
          await dispatch({
            type: DESELECT_WAIVER,
            payload: inputValue.waiverID,
          });

          return mapper().then(result => {
            return acc.push(result) && acc;
          });
        });

      return inputValues.reduce(reducer, Promise.resolve([]));
    };

    await promiseMap(unavailableWaivers, retryWaiverSelection);
  }

  confirmHandler();
};

export const assignReceiptToWaiver = (invoiceNumber: string) => async (
  dispatch,
  getState,
): Promise<any[]> => {
  const state = getState();

  const currentConfig: any = getPluginConfiguration('pnp-ewaiver')(state) ?? {};
  const { selectedWaivers } = getWaiversReduxState(state);

  let assignmentResults: any;

  const promiseMap = (inputValues, mapper) => {
    const reducer = (accumulator, inputValue) =>
      accumulator.then(acc =>
        mapper({
          invoiceNumber,
          waiver: inputValue,
          apiKey: currentConfig.apiKey,
        }).then(async result => {
          const { success, waiver } = result;

          if (!success) {
            await dispatch({
              type: DESELECT_WAIVER,
              payload: waiver.waiver_id,
            });
          }

          return acc.push(result) && acc;
        }),
      );

    return inputValues.reduce(reducer, Promise.resolve([]));
  };

  await promiseMap(selectedWaivers, setReceiptToWaiver).then(values => {
    assignmentResults = values;
  });

  return assignmentResults;
};
