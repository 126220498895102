/* eslint-disable no-console */
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import * as customerActionsTypes from 'constants/customerSearch';
import * as api from 'services/ErplyAPI/customers';
import { RootState } from 'reducers';

export function getCustomerRewardPoints(customerID) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
  ) => {
    dispatch({ type: customerActionsTypes.GET_CUSTOMER_REWARD_POINTS });
    try {
      const [{ points }] = await api.getCustomerRewardPoints(customerID);
      dispatch({
        type: customerActionsTypes.GET_CUSTOMER_REWARD_POINTS_SUCCESS,
        data: points,
      });
    } catch (error) {
      console.error(
        'Unable to load reward points for customer',
        customerID,
        error,
      );
      dispatch({
        type: customerActionsTypes.GET_CUSTOMER_REWARD_POINTS_ERROR,
        error,
      });
    }
  };
}
