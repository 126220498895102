import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';

import { getProductGroupsTreeOrdered } from 'reducers/productGroupsDB';
import {
  getPluginConfiguration,
  getPluginConfigurationAtLevel,
} from 'reducers/Plugins';
import { unique } from 'utils/tsHelpers';
import { getActiveVatRates } from 'reducers/vatRatesDB';
import { ErplyAttributes } from 'utils';
import { getProductByID } from 'reducers/cachedItems/products';
import { getAllWarehouses, getSelectedWarehouse } from 'reducers/warehouses';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getReturnDocument } from 'reducers/returnProducts';

import { PosPlugin } from '../plugin';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipsContainer: {
      minHeight: '50px',
      paddingLeft: 0,
      marginTop: '20px',
    },
    chip: {
      marginRight: '15px',
      marginBottom: '15px',
    },
    information: {
      marginLeft: '10px',
    },
    errorMessage: {
      marginLeft: '10px',
      color: 'red',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 175,
      backgroundColor: '#fff',
    },
    checkBoxFormControl: {
      margin: theme.spacing(2),
      display: 'flex',
    },
    checkboxesContainer: {
      display: 'block',
    },
    saveButton: {
      backgroundColor: '#f2b71e',
      fontWeight: 400,
      '&:hover': {
        backgroundColor: '#dda30d',
        borderColor: '#d19a0c',
      },
    },
  }),
);

type Configuration = {
  taxFreeProductGroupsList: string[];
  taxFreeVatrateID: string;
  taxFreeStatesStatus: Record<string, boolean>;
};

interface SaveParams {
  newTaxFreeGroups?: string[];
  newVatrate?: string;
  newTaxFreeStatesStatus?: Record<string, boolean>;
}

const defaultConfiguration: Configuration = {
  taxFreeProductGroupsList: [],
  taxFreeVatrateID: '',
  taxFreeStatesStatus: {},
};
const pluginId = 'pnp-no-tax-return';

const getProductIsCore = id => state => {
  const { coreGroupIDs } =
    getPluginConfiguration<{
      coreGroupIDs: string[];
    }>('pre-core-workflow')(state) ?? {};
  return coreGroupIDs.includes(String(getProductByID(id)(state).groupID));
};

const getTaxExemptVatRates = state =>
  getActiveVatRates(state).filter(v => Number(v.rate) === 0);

const defineIfTaxShouldBeremoved = (state, p, ap) => {
  const conf =
    getPluginConfigurationAtLevel<Configuration>(
      'pnp-no-tax-return',
      'Company',
      '',
    )(state) ?? {};

  const { taxFreeVatrateID, taxFreeStatesStatus } = conf;

  return {
    ...ap,
    orders: ap.orders.map(p => {
      const id = p.productID;
      const product = getProductByID(id)(state);
      const isAssociatedReturn = p.stableRowID;
      const isReturn = p.amount < 0;
      const isTaxFreeProductGroup = conf.taxFreeProductGroupsList?.includes(
        String(product.groupID),
      );
      const isCore = getProductIsCore(p.productID)(state);
      const isReturnedSolo = !ap.orders.some(
        o => String(o.stableRowID) === String(p.parentRowID),
      );
      const newP = {
        ...p,
        attributes: new ErplyAttributes(p.attributes).asDict,
      };

      const returnDocument = getReturnDocument(state);
      const allWarehouses = getAllWarehouses(state);
      const originalSaleWarehouse = allWarehouses.find(
        warehouse => warehouse.name === returnDocument.warehouseName,
      );
      const originalSaleWarehouseIsExempt =
        taxFreeStatesStatus?.[originalSaleWarehouse?.state];

      if (isReturn) {
        if (
          // When a core is returned alone, it should be tax free.
          // When  making a referenced return and the original warehouse is tax exempt, it should be tax free.
          // When it is selected as a tax free group in the plugin configuration, it should be tax free.
          isCore &&
          isReturnedSolo &&
          isAssociatedReturn &&
          originalSaleWarehouseIsExempt &&
          isTaxFreeProductGroup
        ) {
          // Remove tax
          Object.assign(newP, {
            taxWasRemoved: true,
            rowVATTotal: 0,
            priceListPriceWithVAT: p.finalNetPrice,
            finalPriceWithVAT: p.finalNetPrice,
            rowTotal: p.rowNetTotal,
            vatrateID: taxFreeVatrateID, // API
          });
        }

        // Default return to credit slip
        if (!new ErplyAttributes(newP.attributes).asDict.return_tender) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          newP.attributes.return_tender = 'GIFTCARD';
        }
      }
      return newP;
    }),
  };
};

const Validation = () => {
  const conf = useSelector(getPluginConfiguration<Configuration>(pluginId));
  const taxExemptVatRates = useSelector(getTaxExemptVatRates);

  return (
    <>
      {[
        taxExemptVatRates.length > 0
          ? null
          : 'A VAT rate must be configured in the BO that has a rate of 0\nThis rate is applied to products in order to exempt them from tax',
        conf.taxFreeProductGroupsList.length === 0
          ? 'No groups have been selected for tax exemption, this plugin will have no effect on product groups that have not been selected'
          : null,
      ]
        .filter(a => a)
        .map(err => (
          <Alert variant="danger">{err}</Alert>
        ))}
    </>
  );
};
/**
 * 2) Returns of core charge skip tax
 */
const pnpCoreChargeNoTax: PosPlugin<Configuration> = {
  id: pluginId,
  name: '[PNP] Tax-free returns on products',
  // language=Markdown
  info: `This plugin allows you to define which products will not get their tax refunded when returned For example to
  prevent core charge from refunding taxes `,
  keywords: ['pnp', 'tax', 'return'],
  getStatus: state => {
    const conf = getPluginConfiguration<Configuration>(pluginId)(state);
    const locations = Object.values(conf.taxFreeStatesStatus).some(Boolean);

    const taxExemptVatRates = getTaxExemptVatRates(state);

    if (taxExemptVatRates.length === 0)
      return {
        type: 'error',
        message: 'Account does not have any vatrates with a rate of 0',
      };

    if (conf.taxFreeProductGroupsList.length === 0) {
      return {
        type: 'warning',
        message: 'No product groups have been selected to brefund without tax',
      };
    }

    if (!locations)
      return {
        type: 'error',
        message:
          'No products have been configured for tax-free return at any state',
      };

    return {
      type: 'valid',
      message: `Some products configured to refund without tax`,
    };
  },
  combineConfiguration: company => ({
    ...defaultConfiguration,
    ...company,
  }),
  ComponentConfiguration: ({
    byLevel: {
      company: { current = {}, save },
    },
  }) => {
    const classes = useStyles();
    const taxExemptVatRates = useSelector(getTaxExemptVatRates);

    const [taxFreeGroups, setTaxFreeGroups] = useState<string[]>(
      current.taxFreeProductGroupsList ?? [],
    );
    const [vatrate, selectVatrate] = useState(
      current.taxFreeVatrateID ?? taxExemptVatRates[0].id ?? '',
    );

    const states = unique(
      useSelector(getAllWarehouses)
        .flatMap(wh => wh.state)
        .filter(gn => gn !== '')
        .sort(),
    );

    const createDefaultStatesStatus = (
      states: string[],
    ): Record<string, boolean> => {
      const statesStatus = {};

      states.forEach(stateName => {
        statesStatus[stateName] = false;
      });

      return statesStatus;
    };

    const defaultStateStatus = createDefaultStatesStatus(states);

    const [taxFreeStatesStatus, setStateStatus] = useState<
      Record<string, boolean>
    >(
      current.taxFreeStatesStatus &&
        Object.keys(current.taxFreeStatesStatus).length > 0
        ? current.taxFreeStatesStatus
        : {
          ...defaultStateStatus,
        },
    );

    const allGroups: { name; value }[] = useSelector(
      getProductGroupsTreeOrdered,
    );

    const findGroupName = (value: string): string | undefined => {
      const foundGroup = allGroups.find(group => group.value === Number(value));
      return foundGroup?.name;
    };

    const doSave = (params: SaveParams): void => {
      const { newTaxFreeGroups, newVatrate, newTaxFreeStatesStatus } = params;

      save({
        taxFreeProductGroupsList: newTaxFreeGroups || taxFreeGroups,
        taxFreeVatrateID: newVatrate || vatrate,
        taxFreeStatesStatus: newTaxFreeStatesStatus || taxFreeStatesStatus,
      });
    };

    const handleCheckboxesChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const newTaxFreeStatesStatus = {
        ...taxFreeStatesStatus,
        [event.target.name]: event.target.checked,
      };

      setStateStatus(newTaxFreeStatesStatus);
      doSave({
        newTaxFreeStatesStatus,
      });
    };

    const handleVatRateChange = (
      event: React.ChangeEvent<{ value: unknown }>,
      setter: (val: string) => void,
    ) => {
      const newVatrate = event.target.value as string;

      setter(newVatrate);
      doSave({ newVatrate });
    };

    const handleProductGroupChange = (
      event: React.ChangeEvent<{ value: unknown }>,
    ) => {
      const newTaxFreeGroups = [...taxFreeGroups, String(event.target.value)];
      setTaxFreeGroups(newTaxFreeGroups);
      doSave({
        newTaxFreeGroups,
      });
    };

    const handleProductGroupDelete = (
      groupNumber: string,
      taxFreeGroups: string[],
    ): void => {
      const indexOfGroup = taxFreeGroups.indexOf(groupNumber);

      if (indexOfGroup < 0) {
        return;
      }
      const updatedGroups = [...taxFreeGroups];
      updatedGroups.splice(indexOfGroup, 1);
      setTaxFreeGroups([...updatedGroups]);
      doSave({ newTaxFreeGroups: [...updatedGroups] });
    };

    return (
      <div>
        <Validation />

        <h4>Product groups that are refunded without tax:</h4>
        <Container className={classes.chipsContainer}>
          {taxFreeGroups.length > 0 ? (
            taxFreeGroups.map(group => (
              <Chip
                className={classes.chip}
                variant="outlined"
                key={group}
                onDelete={() => handleProductGroupDelete(group, taxFreeGroups)}
                label={findGroupName(group)}
              />
            ))
          ) : (
            <p className={classes.errorMessage}>No product groups selected</p>
          )}
        </Container>

        <p className={classes.information}>Add another product group</p>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="product-group-select">Select group</InputLabel>
          <Select
            labelId="product-group-select"
            id="product-group-select"
            value=""
            onChange={handleProductGroupChange}
            label="product-group"
          >
            {allGroups
              .filter(group => !taxFreeGroups.includes(String(group.value)))
              .map(gr => (
                <MenuItem value={gr.value} key={gr.value}>
                  {gr.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <h4>0% tax rate to use on these product refunds:</h4>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="tax-rate-select">Select rate</InputLabel>
          <Select
            labelId="tax-rate-select"
            id="tax-rate-select"
            value={vatrate}
            onChange={event => handleVatRateChange(event, selectVatrate)}
            label="tax-rate"
          >
            {taxExemptVatRates.map(({ name, id }) => (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <h4>States where these products are refunded as tax-free</h4>
        <FormControl
          component="fieldset"
          className={classes.checkBoxFormControl}
        >
          <FormGroup className={classes.checkboxesContainer}>
            {Object.keys(taxFreeStatesStatus).map(stateName => (
              <FormControlLabel
                key={stateName}
                control={
                  <Checkbox
                    checked={taxFreeStatesStatus[stateName]}
                    onChange={handleCheckboxesChange}
                    name={stateName}
                    color="primary"
                  />
                }
                label={stateName}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
    );
  },
  onAddReturnProducts: {
    on: (p, ap) => async (dispatch, getState) => {
      const state = getState();

      const retval = defineIfTaxShouldBeremoved(state, p, ap);
      return retval;
    },
  },
  onCalculate: {
    on: (extraParams, rows) => async (dispatch, getState) => {
      const state = getState();
      const {
        taxFreeProductGroupsList,
        taxFreeVatrateID,
        taxFreeStatesStatus,
      } =
        getPluginConfigurationAtLevel<Configuration>(
          pluginId,
          'Company',
          '',
        )(state) ?? {};
      const currentWarehouse = getSelectedWarehouse(state);
      const currentWarehouseIsTaxExempt = taxFreeStatesStatus
        ? taxFreeStatesStatus[currentWarehouse.state]
        : false;

      const updatedRows = rows.map(row => {
        if (row.amount > 0) {
          return row;
        }
        const product = getProductByID(row.productID)(state);
        const isTaxFreeProductGroup = taxFreeProductGroupsList?.includes(
          String(product.groupID),
        );

        const isPreCore = rows.find(
          r =>
            Number(r.amount) === -Number(row.amount) &&
            String(r.productID) === String(product.productID),
        );

        if (currentWarehouseIsTaxExempt) {
          // Only affect configured groups
          if (!isTaxFreeProductGroup) return row;

          if (isPreCore) {
            // Remove tax
            return {
              ...row,
              vatrateID: taxFreeVatrateID,
              price: product.price,
            };
          }

          // Do not affect if nested
          const { parentRowID } = getProductsInShoppingCart(state).find(
            r => r.orderIndex === row.orderIndex,
          );
          if (parentRowID !== 0) return row;

          // Remove tax
          return {
            ...row,
            vatrateID: taxFreeVatrateID,
          };
        }
        if (isPreCore) {
          return {
            ...row,
            price: product.price,
          };
        }

        return row;
      });
      return updatedRows;
    },
  },
};
export default pnpCoreChargeNoTax;
