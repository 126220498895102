import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextXhrBackend from 'i18next-xhr-backend';

const BRAILLE =
  ' ⠸⠤⠠⠰⠱⠮⠹⠨⠷⠪⠈⠡⠌⠄⠐⠳⠯⠩⠘⠬⠣⠜⠫⠴⠂⠆⠒⠲⠢⠖⠶⠦⠔⠁⠃⠉⠙⠑⠋⠛⠓⠊⠚⠅⠇⠍⠝⠕⠏⠟⠗⠎⠞⠥⠧⠺⠭⠵⠻⠼⠽⠾⠿';
const ASCII =
  ' _-,;:!?.([@*/\'"\\&%^+<>$0123456789ABCDEFGHIJKLMNOPQRSTUVWXZ]#Y)=  }';
const toBraille = text =>
  text
    .toUpperCase()
    .split('')
    .map(c => (ASCII.indexOf(c) >= 0 ? BRAILLE[ASCII.indexOf(c)] : ' '))
    .join('');

let pausedLanguage = null;
document.addEventListener('keydown', e => {
  if (e.key === 'Pause') {
    if (pausedLanguage) {
      if (i18n.language === 'braille') {
        i18n.changeLanguage('key');
      } else if (i18n.language === 'key') {
        i18n.changeLanguage(pausedLanguage);
        pausedLanguage = null;
      }
    } else {
      pausedLanguage = i18n.language;
      i18n.changeLanguage('braille');
    }
    e.stopImmediatePropagation();
  }
});
const braillePostProcessor = {
  type: 'postProcessor',
  name: 'braillePostProcessor',
  process: (value, key = '', options = {}, translator) => {
    const censor = translator.language === 'braille' ? toBraille : a => a;
    return censor(value);
  },
};
const keyPostProcessor = {
  type: 'postProcessor',
  name: 'keyPostProcessor',
  process: (value, key, options, translator) =>
    translator.language === 'key' ? `t('${options.ns}:${key}')` : value,
};
const highlightingPostProcessor = {
  type: 'postProcessor',
  name: 'highlightingPostProcessor',
  process: (value, key = '', options = {}, translator) => {
    if (!localStorage.getItem('Debug: translationHighlight')) {
      return value;
    }
    if (options.marking) {
      return value;
    }
    const hasTrans =
      i18n.t(key, 'MISSING', { ...options, marking: true }) !== 'MISSING';
    if (hasTrans) {
      return `::${value}::`;
    }
    return `{{${value}}}`;
  },
};
i18n
  .use(I18NextXhrBackend)
  .use(initReactI18next)
  .use(braillePostProcessor)
  .use(highlightingPostProcessor)
  .use(keyPostProcessor)
  .init({
    postProcess: [
      'braillePostProcessor',
      'highlightingPostProcessor',
      'keyPostProcessor',
    ],
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    // Explicitly specifying the languages prevents requests from being sent to meta-languages that are going to 404 anyway
    // for example this will skip the 'plugin' language
    // supportedLangs not used because it affects not only the backend, but prevents languages from being used entirely
    // f.ex. 'plugin' language which has data added directly via 'addResourceBundle' nevertheless would not work if omitted
    // supportedLngs: [
    //   'ar',
    //   'ces',
    //   'cs',
    //   'de',
    //   'dev',
    //   'en',
    //   'en-AU',
    //   'en-GB',
    //   'en-ZA',
    //   'eng',
    //   'es',
    //   'et',
    //   'fi',
    //   'fo',
    //   'fr',
    //   'it',
    //   'tr',
    // ],
    react: {
      useSuspense: false,
    },
    namespaces: [], // see App.js for loading namespaces automatically

    /**
     * NB: fallbackLng is controlled by a useEffect in the App component
     * @see containers/App/App.js
     */
    fallbackLng: ['en', 'dev'],
    debug: localStorage.getItem('Configuration: i18n.debug'),
    returnEmptyString: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default,
      format: (value, format, lng) => {
        if (value instanceof Date) return value.toISOString();
        return value;
      },
    },
    keySeparator: '.',
    nsSeparator: ':',
  });

export default i18n;
