import React from 'react';
import classNames from 'classnames';

import { Section } from 'containers/Forms/Settings/components/Inputs';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import InputField from 'components/FieldTypes/InputField';
import { useTranslation } from 'react-i18next';

const inputClass = classNames([
  formStyles.formInput,
  formStyles.mediumTitle,
  'tripos-form-input',
]);

const TerminalSelection = ({ lanes, laneSelected, setLaneSelected }) => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.tripos.${text}`, ...rest);
  const handleLaneSelection = e => {
    setLaneSelected(Number(e.target.value));
  };

  const getOptions = () => {
    const options =
      lanes.map(({ LaneId, modelNumber, terminalId }) => ({
        value: LaneId,
        name: `${modelNumber} - ${terminalId}`,
      })) || [];
    options.unshift({ name: t('notSelected'), value: -1 });
    return options;
  };
  return (
    <div>
      <h2>{`${t('terminalSelection')}:`}</h2>
      <Section>
        <InputField
          title={t("selectTerminal")}
          type="select"
          size="lg"
          options={getOptions()}
          value={laneSelected}
          onChange={handleLaneSelection}
          className={inputClass}
        />
      </Section>
    </div>
  );
};

export default TerminalSelection;
