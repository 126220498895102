import React from 'react';
import './Icon.css';
import classNames from 'classnames';

import * as customIcons from 'assets/customIcons';

import { Props } from './types';

/**
 * Icon component.
 * If action is passed the component will have cursor: pointer, will have opacity: 0.7 on hover and execute the action on click
 * If no action is added, opacity won't change on hover and component won't be clickable
 */
const Icon: React.FC<Props> = ({
  style,
  name,
  className,
  title,
  action,
  invert = false,
  small = false,
  ...props
}) => {
  const customIconName = `Erply_${name.replace(/^icon_|erply_/g, '')}`;

  const IconSvg = customIcons[customIconName];
  if (IconSvg) {
    return (
      <IconSvg
        onClick={e => action?.(e)}
        style={{
          ...(small ? { fontSize: 'inherit' } : {}),
          height: '1em',
          width: '1em',
          cursor: action ? 'pointer' : 'inherit',
          margin: 0,
          whiteSpace: 'pre',
          ...style,
        }}
        title={title}
        className={classNames(className, {
          [name]: true,
          'icon-button': true,
          hoverable: action,
          invert,
        })}
        {...props}
      />
    );
  }
  return (
    <i
      onClick={e => action?.(e)}
      style={{
        ...(small ? { fontSize: 'inherit' } : {}),
        cursor: action ? 'pointer' : 'inherit',
        margin: 0,
        whiteSpace: 'pre',
        ...style,
      }}
      title={title ?? ''}
      className={classNames(className, {
        [name]: true,
        'icon-button': true,
        hoverable: action,
        invert,
      })}
      {...props}
    />
  );
};

export default Icon;
