import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Chip,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core';

import CampaignView from 'containers/Forms/Campaigns/components/ModalPageTemplate';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import styles from 'containers/Forms/Campaigns/index.module.scss';
import Icon from 'components/Icon';
import { verifyCoupon } from 'services/ErplyAPI/campaigns';
import { addError, addWarning } from 'actions/Error';
import { applyCoupon, removeCoupon } from 'actions/ShoppingCart';
import { applyCoupons } from 'actions/ShoppingCart/applyCoupons';
import { getAppliedCoupons } from 'reducers/ShoppingCart';
import { getDateFormatter } from 'reducers/configs/settings';
import { IssuedCoupon } from 'types/Coupon';
import { PluginComponent } from 'plugins';

interface Props {
  apply: IssuedCoupon[];
  passedId: string;
  resolve: (value?: unknown) => void;
}

const Coupons: React.FC<Props> = ({ apply = [], passedId = '', resolve }) => {
  const dispatch = useDispatch();
  const close = () => {
    dispatch(previousModalPage());
    resolve();
  };
  const { t } = useTranslation('campaigns');

  const [id, setId] = useState(passedId);
  const coupons = useSelector(getAppliedCoupons);
  const dateFormatter = useSelector(getDateFormatter);

  useEffect(() => {
    // apply.map(applyCoupon).map(dispatch);
    dispatch(applyCoupons(apply));
  }, [dispatch]);

  const findAndApplyCoupon = () => {
    if (coupons.find(coup => coup.uniqueIdentifier === id)) {
      dispatch(
        addWarning(t('alerts:promotions.couponAlreadyApplied'), {
          selfDismiss: true,
        }),
      );
      return;
    }
    verifyCoupon(id)
      .then(res => res[0])
      .then(coupon => dispatch(applyCoupon(coupon)))
      .catch(err => {
        dispatch(addError(err.message, { selfDismiss: true }));
      });
  };
  const unapply = id => dispatch(removeCoupon(id));

  return (
    <div data-testid="coupons-modal">
      <PluginComponent hookname="UICoupons" props={{ id }}>
        <CampaignView title={t('coupons.title')} onClose={close}>
          <TextField
            fullWidth
            key="coupons_text_field"
            aria-label="Product name or code"
            placeholder={t('coupons.inputs.number')}
            type="text"
            value={id}
            onKeyDown={e => (e.key === 'Enter' ? findAndApplyCoupon() : null)}
            onChange={e => setId(e.target.value)}
            data-testid="coupon-number-input"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className="search-button"
                  onClick={findAndApplyCoupon}
                  data-testid="find-coupon-btn"
                >
                  <i className="flip-horizontal  icon_search" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />

          <List data-testid="coupons" disablePadding>
            {coupons.map(
              ({
                uniqueIdentifier: id,
                campaignID,
                campaignName: name,
                used,
                expiryDate,
              }) => {
                return (
                  <ListItem
                    key={campaignID}
                    data-testid="coupon-item"
                    data-test-key={id}
                    className={`${styles.CouponItem} dark_coupon_item`}
                    button
                  >
                    <div className={styles.details}>
                      <Typography variant="h4">
                        <b>{id}</b>
                      </Typography>
                      <Typography paragraph className={styles.expiryDate}>
                        {t('coupons.expiryDate', {
                          expiryDate: dateFormatter(expiryDate),
                        })}
                      </Typography>
                      <Typography>{name}</Typography>
                    </div>

                    <div className={styles.buttons}>
                      <Chip
                        className={[
                          styles.badge,
                          used ? 'badge-success' : 'badge-error',
                        ].join(' ')}
                        size="small"
                        label={t('coupons.badge', {
                          context: used ? 'applied' : 'notApplied',
                        })}
                      />
                      <Icon
                        name="icon_trash_alt"
                        className={styles.check}
                        action={() => unapply(id)}
                      />
                    </div>
                  </ListItem>
                );
              },
            )}
          </List>
        </CampaignView>
      </PluginComponent>
    </div>
  );
};

export default Coupons;
