import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import * as R from 'ramda';

import * as types from 'constants/CAFA';
import {
  ScanningAlgorithm,
  ScanningNoSearchResultsHandler,
} from 'actions/scanner';
import loadingReducer from 'reducers/util/loadingReducer';
import { PrintingMSSettings } from 'types/PrintingMS';
import {
  CustomerPOSConfiguration,
  CUSTOMER_TYPES,
  FormFieldConfiguration,
} from 'containers/Forms/Settings/views/Customers/types';
import { AllowedTenderConfiguration } from 'containers/Forms/Settings/views/Payment/views/AllowedTenders/types';
import { withDefaultValue } from 'containers/Forms/Settings/views/Payment/views/AllowedTenders/utils';
import { RootState } from 'reducers';
import {
  getDefaultCustomerFormConfiguration,
  getIsAllowedToBeDisabled,
} from 'containers/Forms/Settings/views/Customers/utils';

import { DefaultDict, ValueOf } from '../utils';
import { DisabledOn } from '../containers/Forms/Settings/views/Customers/types';

import { getSelectedPosID } from './PointsOfSale';
import { getUserLoggedIn } from './Login';
import { getSelectedWarehouseID } from './warehouses';
import {
  getAllLimitSettings,
  getPrefillHomeStoreOnCustomerCreation,
  getUseCreateCustomerBeta,
} from './configs/settings';
import { createOverrideSelector } from './Plugins';
import { getCustomerRegistryUrl } from './customerSearch';

export function integrations(state = {}, { type, payload }) {
  switch (type) {
    case types.FETCH_CAFA_CONFIGS.SUCCESS:
      return { ...state, ...payload };
    case types.SAVE_CAFA_CONFIGS.SUCCESS:
      return {
        ...state,
        [payload.id]: payload,
      };
    case types.DELETE_CAFA_CONFIG.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const stateCopy = { ...state };
      delete stateCopy[payload.id];
      return stateCopy;
    default:
      return state;
  }
}

const loadingCafa = loadingReducer.createReducer(
  [types.FETCH_CAFA_CONFIGS.START],
  [types.FETCH_CAFA_CONFIGS.SUCCESS, types.FETCH_CAFA_CONFIGS.ERROR],
  true,
);

export default combineReducers({ integrations, loadingCafa });

export const getIsLoadingCafa = createSelector(
  (state: any) => state.cafaConfigs.loadingCafa,
  loadingState =>
    loadingReducer.getIsLoading(loadingState) ||
    loadingReducer.getIsInitState(loadingState),
);

/**
 * Maps a CAFA level to a number signifying that level's priority
 * This can be used for comparisons or sorting
 */
function rankLevel(lvl: types.CAFA_ENTRY['level']): number {
  return {
    [types.CAFA_LEVELS.Company]: 1,
    [types.CAFA_LEVELS.Warehouse]: 2,
    [types.CAFA_LEVELS.Pos]: 3,
    [types.CAFA_LEVELS.User]: 4,
  }[lvl];
}

export function sortLevels(ascending = false) {
  return (firstLevel, secondLevel) =>
    ascending
      ? rankLevel(secondLevel) - rankLevel(firstLevel)
      : rankLevel(firstLevel) - rankLevel(secondLevel);
}

/**
 * Given a list of cafa entries, filters out those which are shadowed by more specific rules
 */
function hideDuplicates(integrations: types.CAFA_ENTRY[]) {
  return integrations.filter(
    int =>
      !integrations
        .filter(i2 => i2.type === int.type && i2.name === int.name)
        .some(int2 => rankLevel(int2.level) > rankLevel(int.level)),
  );
}

function getCafaSlice(state) {
  return state.cafaConfigs;
}

/** Returns all entries that are stored in CAFA */
export const getAllCafaEntries = createSelector(
  getCafaSlice,
  slice => Object.values(slice?.integrations || {}) as types.CAFA_ENTRY[],
);

const getStored = createSelector(
  () => localStorage.getItem('cache: CAFA'),
  str => {
    if (!str) return [];
    try {
      return JSON.parse(str);
    } catch (e) {
      return [];
    }
  },
);

/**
 * Returns all CAFA entries that match the current company/warehouse/pos/user
 * Includes shadowed entries, but does not include entries from other locations
 */
export const getApplicableCafaEntries = createSelector(
  getAllCafaEntries,
  getSelectedPosID,
  getSelectedWarehouseID,
  getUserLoggedIn,
  (ints, posID, whID, user) => {
    return ints.filter(
      int =>
        int.level === 'Company' ||
        String(int.level_id) ===
          String(
            {
              Warehouse: whID,
              Pos: posID,
              User: user?.userID,
            }[int.level],
          ),
    );
  },
);

/** Returns all entries that are stored in CAFA that are active (no shadowed entries) */
export const getUnobstructedCafaEntries = createSelector(
  getApplicableCafaEntries,
  getIsLoadingCafa,
  getStored,
  (ints, loading, stored) => {
    const unobstructedEntries = hideDuplicates(
      (loading ? stored : ints).flatMap(i => (i ? [i] : [])),
    );
    localStorage.setItem('cache: CAFA', JSON.stringify(unobstructedEntries));
    return unobstructedEntries as types.CAFA_ENTRY[];
  },
);

/**
 * Returns the currently active entry for a given integration + type
 * If level is specified, returns the entry from that level
 * Otherwise returns only the topmost (unshadowed) entry
 */
export function getCafaEntry<
  T extends types.CAFA_ENTRY['type'],
  N extends types.CAFA_ENTRY['name'],
  V extends types.CAFA_ENTRY['value']
>(
  name: N,
  type?: T,
  level?:
    | undefined
    | { level: types.CAFA_ENTRY['level']; id: types.CAFA_ENTRY['level_id'] },
) {
  return (state): undefined | types.CAFA_ENTRY<T, N, V> =>
    level === undefined
      ? getUnobstructedCafaEntries(state).find(
          (ent): ent is types.CAFA_ENTRY<T, N, V> =>
            (ent.type ? ent.type === type : !type) && ent.name === name,
        )
      : getAllCafaEntries(state).find(
          (ent): ent is types.CAFA_ENTRY<T, N, V> =>
            (ent.type ? ent.type === type : !type) &&
            ent.name === name &&
            ent.level === level.level &&
            (level.level === 'Company' ||
              String(ent.level_id) === String(level.id)),
        );
}

const getCafaAsDict = createSelector(getUnobstructedCafaEntries, entries => {
  const byIntByType = DefaultDict(() => DefaultDict(() => null));
  entries.forEach(int => {
    byIntByType[int.type as any][int.name] = int.value;
  });
  return byIntByType as { [type: string]: { [name: string]: any } };
});
/**
 * Returns the value of the currently active entry for a given integration + type
 * @deprecated Possible duplicate
 */
export function getCafaEntry2<ConfigType = unknown>(
  integration,
  type = '',
  defaultValue: ConfigType = {} as ConfigType,
) {
  return (state): ConfigType =>
    getCafaAsDict(state)[type][integration] ?? defaultValue;
}

export const getGeneralPrintingSettings = createSelector(
  getCafaEntry<
    'printing',
    any,
    {
      forcePatchscriptRender: {
        enabled: boolean;
        pixelWidth: number;
        scaleMultiplier: number;
      };
      zReport: {
        useBoReceipt: boolean;
      };
    }
  >('printing', undefined),
  conf =>
    R.mergeDeepLeft(conf?.value as any)({
      forcePatchscriptRender: {
        enabled: false,
        pixelWidth: 400,
        scaleMultiplier: 1,
      },
      zReport: {
        useBoReceipt: true,
      },
    }),
);

export const getScanningAlgorithm = createSelector(
  getCafaEntry<
    typeof types.INTEGRATION_TYPES.scanner,
    'scanning_algorithm',
    {
      steps: ScanningAlgorithm;
      noResultsHandler: ScanningNoSearchResultsHandler;
      minScanLength: number;
      avgTimeByChar: number;
      timeBeforeScanTest: number;
    }
  >('scanning_algorithm', types.INTEGRATION_TYPES.scanner, {
    level: 'Company',
    id: '',
  }),
  (
    conf,
  ): {
    steps: ScanningAlgorithm;
    noResultsHandler: ScanningNoSearchResultsHandler;
    minScanLength: number;
    avgTimeByChar: number;
    timeBeforeScanTest: number;
  } =>
    conf?.value ?? {
      steps: [
        [{ request: 'products', by: 'code2' }],
        [{ request: 'products', by: 'code' }],
        [{ request: 'products', by: 'name' }],
      ],
      noResultsHandler: 'notification',
      minScanLength: 2,
      avgTimeByChar: 100,
      timeBeforeScanTest: 100,
    },
);

function getCustomersConfigsFromCafa(state: RootState) {
  return getCafaEntry<
    typeof types.INTEGRATIONS.posConfigurations.customer,
    typeof types.INTEGRATION_TYPES.posConfigurations,
    CustomerPOSConfiguration
  >(
    types.INTEGRATION_TYPES.posConfigurations,
    types.INTEGRATIONS.posConfigurations.customer,
  )(state);
}

/**
 * Returns Customer Settings in POS from CAFA
 *
 * POS is gradually moving towards CAFA.
 * This Configuration is storing Customer specific Settings for the POS
 */
export const getGeneralCustomerSettings = createSelector<
  RootState,
  ReturnType<typeof getCustomersConfigsFromCafa>,
  ReturnType<typeof getCustomerRegistryUrl>,
  CustomerPOSConfiguration
>(
  getCustomersConfigsFromCafa,
  getCustomerRegistryUrl,
  (conf, customerRegisteryUrl) => {
    // ensure that fields that are required by customer registry api are always enabled and required
    const config =
      customerRegisteryUrl === null
        ? conf
        : R.over(
            R.lensPath([
              'value',
              'customer_form_configuration',
              'formFields',
              'PERSON',
            ]),
            R.mapObjIndexed((obj, key) => {
              return R.when(
                R.always(!getIsAllowedToBeDisabled(key, obj.defaultValue)),
                R.mergeLeft({
                  enabled: true,
                  required: true,
                  disabledOn: DisabledOn.NEVER,
                }),
              )(obj);
            }),
          )(conf);

    return R.mergeDeepLeft(config?.value ?? {})({
      // eslint-disable-next-line @typescript-eslint/camelcase
      customer_form_configuration: getDefaultCustomerFormConfiguration(),
    });
  },
);

export const getDefaultCustomerFormValues = createSelector(
  getGeneralCustomerSettings,
  ({ customer_form_configuration: conf }) => {
    const getDefaultValueObject = R.pipe(
      R.filter(R.has('defaultValue')),
      R.map(R.prop('defaultValue')),
    );
    return {
      [CUSTOMER_TYPES.PERSON]: getDefaultValueObject(
        conf.formFields?.[CUSTOMER_TYPES.PERSON],
      ),
      [CUSTOMER_TYPES.COMPANY]: getDefaultValueObject(
        conf.formFields?.[CUSTOMER_TYPES.COMPANY],
      ),
    };
  },
);

const defaultConfig: FormFieldConfiguration = {
  enabled: true,
  required: false,
  order: 100,
};

const getCustomerTableFieldsBase = createSelector(
  getGeneralCustomerSettings,
  getCustomerRegistryUrl,
  ({ customer_form_configuration: configuration }, customerRegistry) => {
    const personFields = configuration?.formFields?.PERSON;
    const companyFields = configuration?.formFields?.COMPANY;

    // Fields that are not present on customer form
    const otherFields = customerRegistry
      ? { rewardPoints: defaultConfig }
      : {
          rewardPoints: defaultConfig,
          availableCredit: defaultConfig,
          creditLimit: defaultConfig,
        };

    function filterAndAppendOtherFields(
      customerFields: typeof personFields | typeof companyFields,
    ): Record<string, FormFieldConfiguration> {
      if (!customerFields) return {};
      const combined = {
        ...customerFields,
        ...otherFields,
      };
      return Object.fromEntries(
        Object.entries(combined).filter(
          ([key, value]) => value?.enabled && key !== 'notes',
        ),
      ) as Record<string, FormFieldConfiguration>;
    }

    return {
      [CUSTOMER_TYPES.PERSON]: filterAndAppendOtherFields(personFields),
      [CUSTOMER_TYPES.COMPANY]: filterAndAppendOtherFields(companyFields),
    };
  },
);

export const getCustomerTableFields = createOverrideSelector(
  'getCustomerTableFields',
  getCustomerTableFieldsBase,
);

// TODO: Move to separate file?
/**
 * Returns the current config (as stored in CAFA) for the cdDelphi customer display
 */
export const getDelphiCustomerDisplayConfig = createSelector(
  getCafaEntry('cdDelphi', 'customerDisplay'),
  (conf): any => conf?.value ?? {},
);

/**
 * Returns CAFA integration for selected integrations per type (level: "Company", ... value {payment: "x", printing: "y", etc...})
 */
export const getActiveIntegrationsConfig = getCafaEntry<
  any,
  any,
  { [integrationName: string]: any }
>('active-integrations', types.INTEGRATION_TYPES.integrations);

/**
 * Returns the object of CAFA integration for selected integrations per type ({payment: "x", printing: "y", etc...})
 */
export const getActiveIntegrationsValue = createSelector(
  getActiveIntegrationsConfig,
  (slice): { [integrationName: string]: string } => slice?.value ?? {},
);

export const getActivePaymentIntegration = createSelector(
  getActiveIntegrationsValue,
  slice => slice?.payment ?? 'external',
);

// In case we have more integrations supporting vault, a list with all vault supported integrations must be created
// and we need to check if the current integration is in the list. Instead of only looking for cayan
export const getIsCardBoardingEnabled = createSelector(
  getAllCafaEntries,
  getActiveIntegrationsValue,
  (entries, activeIntegration) => {
    const vaultEntry = entries.find(entry => entry.name === 'cayan');
    return !!(
      vaultEntry?.value?.cayanVault && activeIntegration.payment === 'cayan'
    );
  },
);

type CAFAPrintingMSSettings = types.CAFA_ENTRY<
  string,
  string,
  PrintingMSSettings
>;

/**
 * Returns topmost entry of Go Printing Microservice Settings.
 *
 * Any missing fields will be filled with values from lower level entries
 */
export function getPrintingMSSettings(state): CAFAPrintingMSSettings | null {
  const type = types.INTEGRATION_TYPES.printer;
  const name = types.INTEGRATIONS[type].goMS;
  const printingMSSettings = getApplicableCafaEntries(state)
    .filter(
      (ent): ent is CAFAPrintingMSSettings =>
        ent.name === name && ent.type === type,
    )
    .sort((a, b) => rankLevel(b.level) - rankLevel(a.level))
    .reduce(
      (merged, current) =>
        R.mergeDeepLeft(merged, {
          ...current,
          value: { enabled: 0, ...current.value },
        }),
      {} as CAFAPrintingMSSettings,
    );
  return Object.keys(printingMSSettings).length ? printingMSSettings : null;
}

/**
 * POS Brazil Version selected by the user for the whole company
 */
export const getPOSVersionsDictFromCAFA = createSelector(
  getCafaEntry<
    typeof types.INTEGRATION_TYPES.global,
    typeof types.INTEGRATIONS.global.posBrazilVersion,
    {
      develop: string;
      sandbox: string;
      production: string;
    }
  >(
    types.INTEGRATIONS.global.posBrazilVersion,
    types.INTEGRATION_TYPES.global,
    {
      level: types.CAFA_LEVELS.Company,
      id: '',
    },
  ),
  conf =>
    conf?.value ?? {
      develop: '',
      sandbox: '',
      production: '',
    },
);

export const DEFAULT_GRID_BUTTON_SIZE = 1;
export const DEFAULT_GRID_BUTTON_FONT_SIZE = 14;

type GridButtonSizeConfig = { buttonSize: number; fontSize: number };

export const getGridButtonSizeConfig = createSelector(
  state =>
    getCafaEntry<
      typeof types.INTEGRATION_TYPES.posConfigurations,
      typeof types.INTEGRATIONS.posConfigurations.gridButton,
      GridButtonSizeConfig
    >(
      types.INTEGRATIONS.posConfigurations.gridButton,
      types.INTEGRATION_TYPES.posConfigurations,
    )(state),
  config => ({
    buttonSize: DEFAULT_GRID_BUTTON_SIZE,
    fontSize: DEFAULT_GRID_BUTTON_FONT_SIZE,
    ...((config?.value ?? {}) as GridButtonSizeConfig),
  }),
);

export const getApplicableAllowedTendersConfig = createSelector(
  state => getApplicableCafaEntries(state),
  state => getAllLimitSettings(state),
  (cafaEntries, legacySettings) => {
    const integrationType = types.INTEGRATION_TYPES.posConfigurations;
    const integrationName = types.INTEGRATIONS[integrationType].allowedTenders;

    const allowedTendersEntries = cafaEntries.filter(
      entry =>
        entry.name === types.INTEGRATIONS.posConfigurations.allowedTenders,
    ) as types.CAFA_ENTRY<
      typeof integrationType,
      typeof integrationName,
      Record<keyof typeof types.CAFA_LEVELS, AllowedTenderConfiguration>
    >[];

    function getEntryAtLevel(level: ValueOf<typeof types.CAFA_LEVELS>) {
      const config = allowedTendersEntries.find(entry => entry.level === level)
        ?.value;
      const configToUse =
        !config && level === types.CAFA_LEVELS.Company
          ? legacySettings
          : config;
      return withDefaultValue(configToUse) as AllowedTenderConfiguration;
    }

    return {
      [types.CAFA_LEVELS.Company]: getEntryAtLevel(types.CAFA_LEVELS.Company),
      [types.CAFA_LEVELS.Warehouse]: getEntryAtLevel(
        types.CAFA_LEVELS.Warehouse,
      ),
      [types.CAFA_LEVELS.Pos]: getEntryAtLevel(types.CAFA_LEVELS.Pos),
    };
  },
);

const getActiveAllowedTendersConfigBase = createSelector(
  state => getApplicableAllowedTendersConfig(state),
  config => {
    if (config[types.CAFA_LEVELS.Pos].enabled) {
      return config[types.CAFA_LEVELS.Pos];
    }
    if (config[types.CAFA_LEVELS.Warehouse].enabled) {
      return config[types.CAFA_LEVELS.Warehouse];
    }
    return config[types.CAFA_LEVELS.Company];
  },
);

export const getActiveAllowedTendersConfig = createOverrideSelector(
  'getActiveAllowedTendersConfig',
  getActiveAllowedTendersConfigBase,
);

export const getPatchScriptReceiptTemplates = createSelector(
  getApplicableCafaEntries,
  cafaConfigs =>
    cafaConfigs.filter(
      config => config.type === types.INTEGRATION_TYPES.patchScriptTemplate,
    ),
);

export function getDefaultHomeStoreID(customerType) {
  return state => {
    const shouldPrefillHomeStore = getPrefillHomeStoreOnCustomerCreation(state);
    const selectedWarehouseID = getSelectedWarehouseID(state);

    // if the user is using Configurable Customer form, check for the configured home store id
    if (getUseCreateCustomerBeta(state)) {
      const configuredDefault = getDefaultCustomerFormValues(state)?.[
        customerType
      ]?.homeStoreID;

      return configuredDefault ?? shouldPrefillHomeStore
        ? selectedWarehouseID
        : undefined;
    }
    // if legacy setting from config store is enabled, return current warehouseID
    if (shouldPrefillHomeStore) {
      return selectedWarehouseID;
    }
    // return undefined if no config
    return undefined;
  };
}
