import { getPluginSelector } from 'reducers/Plugins';
import { pluginID } from 'plugins/saiNumberCapture/constants';
import { RootState } from 'reducers';
import { getIsCurrentSaleAReturn } from 'reducers/sales';

import { SAIState } from './types';

export function getSAINumber(state: RootState) {
  const selector = getPluginSelector<SAIState>(pluginID);
  return selector(state => state.SAINumber)(state);
}

export function getScannedQuery(state: RootState) {
  const selector = getPluginSelector<SAIState>(pluginID);
  return selector(state => state.scannedQuery)(state);
}

export function getShouldLock(state: RootState) {
  const SAINumber = getSAINumber(state);
  const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);
  return !isCurrentSaleAReturn && SAINumber === null;
}
