import cookie from 'js-cookie';
import dayjs from 'dayjs';
import {
  COOKIE_NAME,
  SNOOZE_EXPIRATION_TIME,
  TARGET_CERT_DATE,
  WORKAROUND_VERSION,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/CONFIGURATION';

export const hasCookie = !!cookie.get(COOKIE_NAME);
export const setCookiePermanent = () => {
  cookie.set(COOKIE_NAME, String(WORKAROUND_VERSION), {
    domain: 'erply.com',
    // Add an extra week to give us time to disable the serverside redirect logic before the cookies expire
    // We don't keep the cookie permanently, because they would get sent with every future request
    expires: dayjs(TARGET_CERT_DATE).add(1, 'week').toDate(),
  });
};
export const setCookieTemporary = () => {
  if (!hasCookie) {
    cookie.set(COOKIE_NAME, 'snoozed', {
      domain: 'erply.com',
      expires: new Date(Date.now() + SNOOZE_EXPIRATION_TIME),
    });
  }
};
