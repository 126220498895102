import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { getCardPaymentsForIntegration } from 'reducers/Payments';
import { markForProcessing, unmarkFromProcessing } from 'actions/Payments';
import { CardPaymentHooks, FUNC_BUTTONS } from 'paymentIntegrations/types';
import { RootState } from 'reducers';
import { addError } from 'actions/Error';
import { openTerminalIntegration } from 'actions/integrations/terminal';

import { processPayments } from './actions';
import { closeBatch as chaseCloseBatch } from './requests';

export const title = 'Chase Terminal Interface';

export const initPayments = (
  hooks: CardPaymentHooks,
): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => async dispatch => {
  try {
    await dispatch(processPayments(hooks));

    hooks.resolvePayments();
  } catch (error) {
    dispatch(
      addError(error instanceof Error ? error.message : 'Something went wrong'),
    );
    hooks.rejectPayments();
  }
};

export const voidPayments = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => async (dispatch, getState) => {
  // mark paid payments for processing
  getCardPaymentsForIntegration('chase')(getState()).forEach(p => {
    if (p.paid) dispatch(markForProcessing({ key: p.key }));
  });

  const paymentsToProcess = getCardPaymentsForIntegration('chase')(
    getState(),
  ).filter(payment => payment.paid && payment.shouldProcess);

  if (paymentsToProcess.length > 0) {
    await dispatch(openTerminalIntegration());
    // remove shouldProcess prop from payments after voiding is finished or cancelled
    getCardPaymentsForIntegration('chase')(getState()).forEach(p => {
      dispatch(unmarkFromProcessing({ key: p.key }));
    });
  }
};

export const closeBatch = () => async () => {
  try {
    const {
      data: {
        records: [record],
      },
    } = await chaseCloseBatch();
    return record.resultCode === '0';
  } catch (err) {
    return false;
  }
};

export const functions = [
  {
    actionOnClick: initPayments,
    text: 'Retry',
    name: FUNC_BUTTONS.RETRY,
    variant: 'success',
  },
  {
    actionOnClick: (hooks: CardPaymentHooks) => async () =>
      hooks.rejectPayments(),
    text: 'Close',
    name: FUNC_BUTTONS.CLOSE,
    variant: 'danger',
  },
  {
    actionOnClick: (hooks: CardPaymentHooks) => async () =>
      hooks.displayError(
        'Cancelling transaction from POS not supported, please cancel transaction from terminal',
      ),
    text: 'Cancel',
    name: FUNC_BUTTONS.CANCEL,
    variant: 'danger',
  },
];
