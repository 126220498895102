import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { addError, addSuccess, dismissAll } from 'actions/Error';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { pickupPendingSale, resetCurrentSalesDocument } from 'actions/sales';
import {
  getSetting,
  getDateFormatter,
  getCurrencyFormatter,
} from 'reducers/configs/settings';
import { getUserLoggedIn, getUserRights } from 'reducers/Login';
import Icon from 'components/Icon';
import { useConfirmation } from 'components/Confirmation';
import { deleteSalesDocument } from 'services/ErplyAPI';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { getCurrentSalesDocument } from 'reducers/sales';

const StyledTable = styled(Table)(({ theme }) => ({
  '& td, & th': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  '& th': {
    borderBottom: `2px solid ${theme.palette.divider}`,
    fontWeight: 700,
  },
}));

interface Props {
  sales: SaleDocumentResponse[];
}

const PendingSales: FC<Props> = ({ sales }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('sale');
  const formatCurrency = useSelector(getCurrencyFormatter);
  const detailedPendingSales = useSelector(
    getSetting('detailed_pending_sales'),
  );
  const [salesState, setSalesState] = useState(sales);
  const confirm = useConfirmation();
  const currentSalesDocument = useSelector(getCurrentSalesDocument);

  const user = useSelector(getUserLoggedIn);

  const {
    modules: {
      invoices: { deleteUnconfirmed: canDelete },
    },
  } = useSelector(getUserRights);

  const canDeleteOwnPendingSale = canDelete === 1;
  const canDeleteAllPendingSales = canDelete === 2;

  const close = useCallback(() => {
    dispatch(dismissAll());
    dispatch(closeModalPage());
  }, [dispatch]);

  const selectSale = (sale: SaleDocumentResponse) => {
    dispatch(pickupPendingSale(sale));
    dispatch(closeModalPage());
  };

  const removePendingSale = async (e, row) => {
    e.stopPropagation();
    const isConfirmed = await confirm({
      body: 'This document will be deleted permanently',
    });
    if (isConfirmed) {
      try {
        deleteSalesDocument(row.id);
        setSalesState(prevSales =>
          prevSales.filter(sale => sale.id !== row.id),
        );
        if (currentSalesDocument.id === row.id) {
          dispatch(resetCurrentSalesDocument());
        }
        dispatch(addSuccess('Sale deleted'));
      } catch (err) {
        dispatch(addError((err as Error).message));
      }
    }
  };

  const formatDate = useSelector(getDateFormatter);

  return (
    <div data-testid="pending-sales-modal">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1.25rem"
        paddingY="0.75rem"
      >
        <Box paddingLeft="5px" fontSize="24px" fontWeight={700}>
          {t('pendingSales.title')}
        </Box>
        <div>
          <CloseButton
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignContent: 'center',
            }}
            action={close}
          />
        </div>
      </Box>
      <Divider />
      <Box padding="1rem">
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('pendingSales.headers.date')}</TableCell>
              <TableCell>{t('pendingSales.headers.customer')}</TableCell>
              {detailedPendingSales ? (
                <TableCell>{t('pendingSales.headers.notes')}</TableCell>
              ) : null}
              <TableCell>{t('pendingSales.headers.total')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {salesState.map(r => (
              <TableRow
                key={r.id}
                onClick={() => selectSale(r)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{`${formatDate(r.date)} ${
                  detailedPendingSales ? `- ${r.time}` : ''
                }`}</TableCell>
                <TableCell>{r.clientName}</TableCell>
                {detailedPendingSales ? <TableCell>{r.notes}</TableCell> : null}
                <TableCell>{formatCurrency(r.total)}</TableCell>
                {(canDeleteAllPendingSales ||
                  (Number(user.employeeID) === Number(r.employeeID) &&
                    canDeleteOwnPendingSale)) && (
                  <TableCell>
                    <Icon
                      name="icon_trash"
                      action={e => removePendingSale(e, r)}
                      title="trash"
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
    </div>
  );
};

export default PendingSales;
