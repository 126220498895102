import * as api from 'services/ErplyAPI/vatrates';
import * as c from 'constants/vatRatesDB';
import * as tax from 'services/tax';
import { getSessionKey, getClientCode } from 'reducers/Login';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getWarehouseById } from 'reducers/warehouses';
import { getActiveVatRates, getVatRateByID } from 'reducers/vatRatesDB';

export function fetchVatRates() {
  return async dispatch => {
    const recordsOnPage = 1000;
    const {
      status: { recordsTotal: total },
    } = await api.getVatRates({ pageNo: 1, recordsOnPage: 1 });
    const vatRates = await Promise.all(
      Array(Math.ceil(total / recordsOnPage))
        .fill(0)
        .map((z, i) =>
          api.getVatRates({
            request: 'getVatRates',
            recordsOnPage,
            pageNo: i + 1,
          }),
        ),
    ).then(res => res.flatMap(({ records }) => records));

    dispatch({
      type: c.SET_VATRATES,
      payload: vatRates,
    });
  };
}

export function fetchOrCreateVatRateForZipCode(toZip) {
  return async (
    dispatch,
    getState,
  ) => {
    const reduxState = getState();
    const sessionKey = getSessionKey(reduxState);
    const clientCode = getClientCode(reduxState);
    const clientID = getSelectedCustomer(reduxState).customerID;
    const { pointOfSaleID: posID, warehouseID } = getSelectedPos(reduxState);
    const fromZip = getWarehouseById(warehouseID)(reduxState).ZIPcode;

    const {
      State: state,
      County: county,
      City: city,
      StateSalesTax: stateTaxRate,
      CountySalesTax: countyTaxRate,
      CitySalesTax: cityTaxRate,
      TotalSalesTax: rate,
      ZIPCode,
    } = await tax.getTaxRate({
      sessionKey,
      clientCode,
      clientID,
      posID,
      toZip,
      fromZip,
    });
    // prettier-ignore
    const vatRateName = `${[state, county, city, ZIPCode].join(' / ',)} - ${rate}%`;
    const existing = getActiveVatRates(reduxState).find(
      vatRate => vatRate.name === vatRateName,
    );
    if (existing) {
      return existing;
    }
    const { vatRateID } = await api.saveVatRate({
      name: vatRateName,
      state,
      county,
      city,
      stateTaxRate,
      countyTaxRate,
      cityTaxRate,
      rate,
      ZIPCode,
    });
    await dispatch(fetchVatRates());
    return getVatRateByID(vatRateID)(getState());
  };
}

export function saveVatRate(vatrate) {
  return async dispatch => {
    const vatRate = await api.saveVatRate(vatrate);
    // TODO: Save to redux directly without a second request
    await dispatch(fetchVatRates());
  };
}
