import { SO } from './types';
import getDB from './getDB';

export const saveToSharedSO = async (
  clientCode: string,
  items: { key: string; value: string | number }[],
) => {
  if (!items?.length) return;
  const db = await getDB(clientCode);
  const tx = db.transaction(SO.SHARED.NAME, 'readwrite');
  await Promise.all([
    ...(items.map((item: any) => {
      return tx.store.put(item.value, item.key);
    }) as []),
    tx.done,
  ]);
};

export const getItemsFromSharedSO = async (
  clientCode: string,
  items: string | string[],
) => {
  const db = await getDB(clientCode);
  const tx = db.transaction(SO.SHARED.NAME, 'readonly');
  const _items = items instanceof Array ? items : [items];
  return Promise.all([
    ..._items.map(item => {
      return tx.store.get(item);
    }),
    tx.done,
  ]);
};
