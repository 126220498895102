import { AppliedPromotionRecordResponse } from 'types/Promotions';
import { getCachedItemsPerTypeByIDs } from 'reducers/cachedItems';
import { SO } from 'services/DB/types';
import { PriceList } from 'types/PriceList';
import { ErplyAttributes } from 'utils';
import { getPayments } from 'services/ErplyAPI';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { RootDispatch, RootGetState } from 'reducers';
import { PaymentType } from 'types/PaymentType';

import { CoreReceiptData, CurrencyUtil } from './types';

export function getSpecialPromotionPriceLists(
  promotions: AppliedPromotionRecordResponse[][],
) {
  return async (dispatch: RootDispatch, getState: RootGetState) => {
    const priceListIds = [
      ...new Set(
        promotions
          .flat()
          .map(promotion => Number(promotion.priceListID))
          .filter(Boolean)
          .map(String),
      ),
    ];
    const cachedPriceLists = getCachedItemsPerTypeByIDs(
      SO.PRICE_LIST.NAME,
      priceListIds,
    )(getState()) as Record<string, PriceList>;
    const cachedPriceListIds = Object.keys(cachedPriceLists);
    // POS does not cache inactive and expired price lists
    const nonCachedPriceListIds = priceListIds.filter(
      id => !cachedPriceListIds.includes(id),
    );
    const nonCachedPriceLists = nonCachedPriceListIds.length
      ? await doClientRequest<PriceList>({
          request: 'getPriceLists',
          priceListIDs: nonCachedPriceListIds.join(','),
        }).catch(() => [])
      : [];

    const memberRewards: number[] = [];
    const flyerPrices: number[] = [];

    [...Object.values(cachedPriceLists), ...nonCachedPriceLists].forEach(
      priceList => {
        const priceListAttributes = new ErplyAttributes(priceList.attributes);
        if (priceListAttributes.get('customerGroupId') > 0) {
          memberRewards.push(priceList.pricelistID);
        } else if (priceListAttributes.get('eventId') > 0) {
          flyerPrices.push(priceList.pricelistID);
        }
      },
    );

    return { memberRewards, flyerPrices };
  };
}

export async function assignGivexCardBalances(
  receiptData: CoreReceiptData,
  documentId: string | number,
  CURR: CurrencyUtil,
) {
  const hasGivexPayments = receiptData.billTable.payments.some(
    payment => payment.cardType === 'GIVEX',
  );
  if (!hasGivexPayments) return;

  const payments = await getPayments({
    documentID: documentId,
  }).catch(() => []);

  (payments as any[])
    .filter(payment => payment.cardType === 'GIVEX')
    .forEach(givexPayment => {
      const matchingPaymentInReceiptData = receiptData.billTable.payments.find(
        payment => {
          return (
            payment.cardType === 'GIVEX' &&
            payment.cardNumber.slice(-4) ===
              givexPayment.cardNumber.slice(-4) &&
            CURR.parse(payment.sum) === Number(givexPayment.sum)
          );
        },
      );
      if (matchingPaymentInReceiptData) {
        matchingPaymentInReceiptData.certificateBalance =
          givexPayment.certificateBalance;
      }
    });
}

export function assignPaymentTypeProps(payment, paymentTypes: PaymentType[]) {
  /* 
  Payment type names are not unique but this should be good enough.
  Alternatively type can be determined by sending a getPayments request
  (getSalesDocumentActualReportsDataset only returns type name translation) 
  but this will slow down printing 
  */
  const paymentTypeName =
    paymentTypes.find(type => type.name === payment.type)?.type ?? '';

  const isCard = payment.type === 'CARD' || paymentTypeName === 'CARD';
  const isGiftcard =
    payment.type === 'GIFTCARD' || paymentTypeName === 'GIFTCARD';

  let typeNameRaw;
  if (isGiftcard && payment.cardType) {
    typeNameRaw = payment.cardType;
  } else {
    typeNameRaw = payment.type;
  }

  return {
    ...payment,
    isCard,
    typeNameRaw,
  };
}
