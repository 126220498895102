import { createSelector } from 'reselect';

import {
  TYPE_SET_CLOUD_STATUS,
  TYPE_REMOVE_FROM_CLOUD,
} from 'constants/connectivity';

const initialState = [];

function reducer(state = initialState, { type, payload }) {
  if (type === TYPE_SET_CLOUD_STATUS) {
    const { name, statusUpdate } = payload;
    return {
      ...state,
      [name]: {
        ...state[name],
        ...statusUpdate,
        time: new Date(),
      },
    };
  }
  if (type === TYPE_REMOVE_FROM_CLOUD) {
    return Object.fromEntries(
      Object.entries(state).filter(([key]) => key !== payload),
    );
  }
  return state;
}

export default reducer;

/**
 * @returns {state => {
 *   done: number,
 *   progress: number,
 *   todo: number,
 *   name: string,
 * }[]}
 */
export const getCloudSync = createSelector(
  state => state.connectivity.cloudSync,
  cloudSync => Object.entries(cloudSync).map(([k, v]) => ({ ...v, name: k })),
);

export const getRequestsToSync = createSelector(
  state =>
    Object.entries(state.connectivity.cloudSync).filter(
      ([k, v]) => k === 'pendingRequests',
    ),
  ([pendingRequests]) => {
    return (pendingRequests && pendingRequests[1].todo) || 0;
  },
);

export function getCloudSyncTotalPercentageGetter(state) {
  const cloudSync = getCloudSync(state);
  if (cloudSync.length === 0) return () => 0;
  return () => {
    const { total, done, progress } = cloudSync.reduce(
      (
        { total: t, done: d, progress: p },
        { done, progress, todo, name, start, next },
      ) => {
        const now = new Date().getTime();
        const frac = (now - start) / (next - start);
        const prog = progress * Math.min(0.999, frac || 0);
        return {
          total: name !== 'pendingRequests' ? t + done + progress + todo : t,
          progress: name !== 'pendingRequests' ? p + prog : p,
          done: name !== 'pendingRequests' ? d + done : d,
        };
      },
      { total: 0, done: 0, progress: 0 },
    );
    const fraction = (done + progress) / total;
    return Number.isNaN(fraction) ? 0 : fraction;
  };
}
