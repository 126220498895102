import React, { ChangeEvent, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useMethods } from 'react-use';
import {
  Box,
  InputAdornment,
  MenuItem,
  MuiThemeProvider,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { openManagerOverride } from 'containers/Forms/ManagerOverride/actions';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { positive } from 'components/FieldTypes/formatters';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { addWarning } from 'actions/Error';
import {
  getAllowFractionalProductQuantities,
  getCurrencySymbol,
  getPriceEditingAllowedForExchangesAndReturns,
  getSetting,
  getShowPricesWithTax,
  getUISetting,
} from 'reducers/configs/settings';
import {
  clearDiscountReason,
  removeProduct,
  setDiscountReason,
  updateProductOrder,
} from 'actions/ShoppingCart';
import {
  getHasOrderPromotionsApplied,
  getIsProductNonDiscountable,
  getSelectedOrder,
  getCartHasReferencedReturnRow,
  getHasRightToEditDocument,
} from 'reducers/ShoppingCart';
import { getProductByID } from 'reducers/cachedItems/products';
import { getUserRights } from 'reducers/Login';
import { modalPages } from 'constants/modalPage';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { PluginComponent } from 'plugins';
import { round } from 'utils/index';
import { getRightsForUserID } from 'actions/Login';
import { REASONS } from 'constants/reasonCodesDB';
import {
  calculateDiscountForms,
  formatNumber,
} from 'containers/Forms/ProductOrderEdit/discountFormulas';
import {
  getCommissionable,
  getOtherCommissionable,
} from 'reducers/cachedItems/employees';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

const percent: (num: number) => number = R.multiply(1 / 100);

/** Default styles for this form */
const inputTheme = R.mergeDeepLeft({
  props: {
    MuiTextField: {
      fullWidth: true,
      variant: 'outlined',
    },
  },
});

/** Right align text (for numeric input fields) */
const rightAlignProps = {
  style: {
    textAlign: 'right',
  },
} as const;

/** Wrap input adornments in required boilerplate */
const adornments = (start?: string, end?: string) => ({
  startAdornment: <InputAdornment position="start">{start}</InputAdornment>,
  endAdornment: <InputAdornment position="end">{end}</InputAdornment>,
});

/** an onFocus handler to select the entire field (useful for numeric inputs) */
const selectAll = (
  e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
) => e.target?.select?.();

const useResettingState = <T,>(
  value: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState(value);
  useEffect(() => setState(value), [value]);
  return [state, setState];
};

const ProductEdit: React.FC = () => {
  const { t } = useTranslation('shoppingCart');
  const { t: mt } = useTranslation('managerOverride');
  const { t: dt } = useTranslation('discountPopup');
  const { t: ct } = useTranslation('common');
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();

  // region data from redux
  const { maxDiscount, rightMakeDiscountInPOS } = useSelector(getUserRights);
  const order = useSelector(getSelectedOrder) || {};
  const onClose = useCallback(() => dispatch(closeModalPage('PRODUCT_VIEW')), [
    dispatch,
  ]);
  const isWithTax = useSelector(getShowPricesWithTax);
  const currencySymbol = useSelector(getCurrencySymbol);
  const currentProduct = useSelector(getProductByID(order.productID)) || {};
  const isNonDiscount = useSelector(getIsProductNonDiscountable(order));
  const isZeroPriced = !currentProduct.price;
  const recommendedCommissions = useSelector(getCommissionable);
  const nonRecommendedCommissions = useSelector(getOtherCommissionable);
  const reasonCodes = useSelector(getReasonCodes(REASONS.DISCOUNT));
  const uiShow = {
    name: !!useSelector(getSetting('touchpos_enable_edit_name_in_order')),
    commission: !useSelector(getUISetting('hideProductCommission')),
  };
  const cartHasReferencedReturnRow = useSelector(getCartHasReferencedReturnRow);
  const allowPriceEditingOnReturns = useSelector(
    getPriceEditingAllowedForExchangesAndReturns,
  );
  const canEditGivenDocument = useSelector(getHasRightToEditDocument);

  const shouldMultiplyWithPromotionDiscount = useSelector(
    getHasOrderPromotionsApplied(order?.rowNumber),
  );
  const isFractionalQuantityAllowed = useSelector(
    getAllowFractionalProductQuantities,
  );
  // endregion

  // region state
  const [name, setName] = useResettingState(order.name);
  const [isCommisionFocused, setIsCommisionFocused] = useState<boolean>(false);
  const currentBasePrice: number = R.pipe(
    // Use the relevant base price from the order
    R.prop(isWithTax ? 'basePriceWithVAT' : 'basePrice'),
    // If there's a promotion, apply it to get the with-promotion base price
    // NB: 'promotionDiscount' can become 100 even without a promotion if the user sets a manual price of 0 or less - hence the condition
    R.when(
      () => shouldMultiplyWithPromotionDiscount,
      R.multiply(percent(100 - (order.promotionDiscount ?? 0))),
    ),
  )(order);

  const [basePrice, setBasePrice] = useResettingState<number | string>(
    currentBasePrice,
  );
  const [commissionTo, setCommissionTo] = useResettingState(order.employeeID);
  const [amount, setAmount] = useResettingState(String(order.amount));

  const [discount, discountActions] = useMethods<
    {
      setPrice: (v: string) => void;
      setDc: (v: string) => void;
      setDcAmount: (v: string) => void;
    },
    { price?: string; discount?: string; discountValue?: string }
  >(
    () => ({
      setPrice: price => ({
        price,
        discount: undefined,
        discountValue: undefined,
      }),
      setDc: discount => ({
        price: undefined,
        discount,
        discountValue: undefined,
      }),
      setDcAmount: discountValue => ({
        price: undefined,
        discount: undefined,
        discountValue,
      }),
    }),
    {
      price: undefined,
      discount: Number(order?.manualDiscount || 0).toFixed(2),
      discountValue: undefined,
    },
  );
  // endregion

  // region computed data
  const productCardPrice = Number(
    currentProduct[isWithTax ? 'priceListPriceWithVat' : 'priceListPrice'],
  );
  const isGiftCard = Boolean(order.giftCardSerial || order.isRegularGiftCard);
  const calculated = R.pipe(
    // Prepare props for calculation
    R.reject(R.isNil),
    R.assoc('basePrice', basePrice),
    R.map(Number),
    R.map(R.when(Number.isNaN, R.always(0))),
    // Calculate other discount forms from the one entered
    R.evolve({ discount: R.multiply(1 / 100) }),
    calculateDiscountForms,
    R.evolve({ discount: R.multiply(100) }),
  )(discount) as ReturnType<typeof calculateDiscountForms>;

  const basePriceIsZero = Number(basePrice) === 0;

  const canEditBasePrice =
    (productCardPrice === 0 ||
      isGiftCard ||
      currentProduct.cashierMustEnterPrice) &&
    canEditGivenDocument;
  const discountDisabled =
    !rightMakeDiscountInPOS ||
    isNonDiscount ||
    isGiftCard ||
    basePriceIsZero ||
    !canEditGivenDocument;

  // endregion

  // region behaviour
  useEffect(() => {
    if (R.isEmpty(currentProduct) || !order.productID) {
      onClose();
    }
  }, [currentProduct, onClose, order.productID]);

  const handleBlur = () => {
    if (!isFractionalQuantityAllowed) {
      setAmount(a => {
        const _a = Number(a);
        const roundedAmount = Math.round(_a);
        // minimum amount after rounding is 1/-1
        return roundedAmount === 0 && _a !== 0
          ? `${Math.sign(_a)}`
          : `${roundedAmount}`;
      });
    }
  };

  const handleSave = async () => {
    if (Number(amount) === 0) {
      dispatch(removeProduct(order.orderIndex));
      onClose();
      return;
    }
    const hasDiscount = calculated.discountValue > 0;
    const hadDiscountPreviously = Number(order.discount) > 0;

    if (cartHasReferencedReturnRow && !allowPriceEditingOnReturns) {
      // if price was changed
      if (calculated.price !== Number(order.price)) {
        // prevent saving
        dispatch(
          addWarning(t('alerts.referencedReturnRowsLockedReturnEditsDisabled')),
        );
        return;
      }
    }

    // Remove discount reason if discount is removed
    if (!hasDiscount) dispatch(clearDiscountReason(order.orderIndex));
    // Add / prompt for discount reason if required
    if (hasDiscount && !hadDiscountPreviously) {
      if (reasonCodes.length > 1) {
        try {
          const reason = await new Promise(resolve =>
            dispatch(
              openModalPage({
                component: modalPages.reasonPopup,
                isPopup: true,
                props: {
                  resolve,
                  reasonCodes,
                  title: dt('title'),
                },
              }),
            ),
          );
          dispatch(setDiscountReason(reason, order?.orderIndex));
        } catch (e) {
          // eslint-disable-next-line consistent-return
          return undefined;
        }
      } else if (reasonCodes.length === 1) {
        dispatch(setDiscountReason({ ...reasonCodes[0] }, order?.orderIndex));
      } else {
        // No reason codes, no need to set reason
      }
    }

    // Validate amount
    if (!Number.isFinite(Number(amount)) || !amount) {
      dispatch(addWarning(t('alerts.amountRequired'), { selfDismiss: 3000 }));
      return;
    }
    // Validate max discount & prompt for manager override if needed
    dispatch(
      openManagerOverride([
        {
          key: 'maxDiscount',
          text: mt('managerOverride:reasons.maxDiscount', {
            target: calculated.discount,
            limit: maxDiscount,
          }),
          isPermitted: user =>
            getRightsForUserID(Number(user.userID)).then(
              ({ maxDiscount }) =>
                calculated.discount === 0 || // Not setting a discount
                maxDiscount === 0 || // No discount limit
                Math.abs(calculated.discount) <= maxDiscount, // Discount is below limit
            ),
        },
      ]),
    )
      // Use desired discount if got permission
      // otherwise use current user limit
      .then(
        () => calculated.discount,
        () =>
          Math.sign(calculated.discount) *
          Math.min(
            Math.max(0, maxDiscount), // Negative limits count as no discount allowed
            Math.abs(calculated.discount), // Otherwise take either the desired discount or max discount, whichever is smaller
          ),
      )
      // Create params for updateProductOrder action
      .then(manualDiscount => ({
        name,
        amount,
        price: basePrice,
        manualDiscount,
        employeeID: commissionTo,
        orderIndex: order.orderIndex,
      }))
      .then(
        !!productCardPrice && !isGiftCard
          ? // Do not send price if price is non-editable)
            R.pipe(R.dissoc('price'), R.assoc('clearPrice', true))
          : // Else remove tax from the price on tax-enabled accounts
            R.when(
              R.always(isWithTax),
              R.evolve({
                price: (p: string) =>
                  String(Number(p) / (1 + order.vatRate / 100)),
              }),
            ),
      )
      .then(params => dispatch(updateProductOrder(params)))
      .then(onClose);
  };
  // endregion

  const handleCommissionOnChange = e => {
    if (e.target.value) {
      setCommissionTo(e.target.value);
      setIsCommisionFocused(false);
      setTimeout(() => {
        (document.activeElement as HTMLElement).blur(); // Blurs the TextField after an item is selected
      }, 0);
    }
  };

  useShortcut(
    'Enter',
    !isCommisionFocused ? handleSave : handleCommissionOnChange,
    30,
  );

  if (!order.productID) return null;

  const numberFromEvent: (e: ChangeEvent) => string = R.pipe(
    R.path(['target', 'value']),
    formatNumber,
  );

  return (
    <PluginComponent hookname="UICustomProductCard">
      <div data-testid="product-order-edit">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
          data-testid="header"
        >
          <Typography data-testid="order-name" variant="h5">
            {order.name}
          </Typography>
          <Box display="flex">
            {Number(amount) === 0 ? (
              <Button
                data-testid="remove-item-button"
                variant="contained"
                onClick={handleSave}
              >
                {ct('remove')}
              </Button>
            ) : (
              <SaveButton data-testid="save-button" action={handleSave} />
            )}
            <CloseButton action={onClose} />
          </Box>
        </Box>
        <Divider />
        <Box marginTop="0.75rem">
          <MuiThemeProvider theme={inputTheme}>
            <Table size="small">
              {/* Name on receipt */}
              {uiShow.name && (
                <TableRow>
                  <TableCell>{t('inputs.nameOnReceipt')}</TableCell>
                  <TableCell colSpan={2}>
                    <TextField
                      inputProps={{
                        'data-testid': 'order-name',
                      }}
                      disabled={!canEditGivenDocument}
                      value={name}
                      onChange={e => {
                        setName(e.target.value);
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}

              {/* Base price */}
              <TableRow>
                <TableCell>{t('inputs.basePrice')}</TableCell>
                <TableCell>
                  <TextField
                    color={canEditBasePrice ? undefined : 'secondary'}
                    InputProps={{
                      ...adornments(undefined, currencySymbol),
                      inputProps: {
                        ...rightAlignProps,
                        'data-testid': 'order-base-price',
                      },
                    }}
                    disabled={!canEditBasePrice}
                    helperText={
                      canEditBasePrice
                        ? undefined
                        : t('inputs.helperTexts.basePrice', {
                            context: 'readonly',
                          })
                    }
                    autoFocus={isZeroPriced && !calculated.price}
                    placeholder={round(calculated.price)}
                    value={basePrice}
                    onChange={e => setBasePrice(positive(numberFromEvent(e)))}
                    onFocus={selectAll}
                  />
                </TableCell>
                <TableCell />
              </TableRow>

              {/* Discounts */}
              <TableRow data-testid="order-discounts">
                <TableCell>{t('inputs.discount')}</TableCell>
                <TableCell>
                  <TextField
                    label={t('inputs.labels.discount', {
                      context: 'amount',
                    })}
                    InputProps={{
                      ...adornments(undefined, currencySymbol),
                      inputProps: {
                        ...rightAlignProps,
                        'data-testid': 'by-amount',
                      },
                    }}
                    disabled={discountDisabled}
                    placeholder={round(calculated.discountValue)}
                    value={discount.discountValue ?? ''}
                    onChange={e =>
                      discountActions.setDcAmount(numberFromEvent(e))
                    }
                    onFocus={selectAll}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    label={t('inputs.labels.discount', {
                      context: 'percentage',
                    })}
                    InputProps={{
                      ...adornments(undefined, '%'),
                      inputProps: {
                        ...rightAlignProps,
                        'data-testid': 'by-percentage',
                      },
                    }}
                    disabled={discountDisabled}
                    placeholder={round(calculated.discount)}
                    value={discount.discount ?? ''}
                    onChange={e => discountActions.setDc(numberFromEvent(e))}
                    onFocus={selectAll}
                  />
                </TableCell>
              </TableRow>
              {/* Discounted price */}
              <TableRow>
                <TableCell>{t('inputs.unitPrice')}</TableCell>
                <TableCell>
                  <TextField
                    InputProps={{
                      ...adornments(undefined, currencySymbol),
                      inputProps: {
                        ...rightAlignProps,
                        'data-testid': 'order-unit-price',
                      },
                    }}
                    disabled={discountDisabled}
                    placeholder={round(calculated.price)}
                    value={discount.price ?? ''}
                    onChange={e => discountActions.setPrice(numberFromEvent(e))}
                    onFocus={selectAll}
                  />
                </TableCell>
                <TableCell />
              </TableRow>

              {/* Amount */}
              <PluginComponent
                hookname="UIProductAmountInProductOrderEdit"
                props={{ currentProduct }}
              >
                <TableRow>
                  <TableCell>{t('inputs.amount')}</TableCell>
                  <TableCell>
                    <TextField
                      InputProps={{
                        ...adornments(undefined, currentProduct.unitName),
                        inputProps: {
                          ...rightAlignProps,
                          'data-testid': 'order-amount',
                        },
                      }}
                      disabled={currentProduct.giftCardSerial}
                      value={amount}
                      onChange={e => setAmount(numberFromEvent(e))}
                      onFocus={selectAll}
                      onBlur={handleBlur}
                    />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </PluginComponent>

              {/* Commission to */}
              {uiShow.commission && (
                <TableRow>
                  <TableCell>{t('inputs.commission')}</TableCell>
                  <TableCell colSpan={2}>
                    <TextField
                      inputProps={{
                        'data-testid': 'order-commmission',
                      }}
                      select
                      onBlur={() => {
                        setIsCommisionFocused(false);
                        setTimeout(() => {
                          (document.activeElement as HTMLElement).blur(); // Blurs the TextField after an item is selected
                        }, 0);
                      }}
                      onFocus={() => {
                        setIsCommisionFocused(true);
                      }}
                      value={commissionTo}
                      onChange={e => {
                        handleCommissionOnChange(e);
                      }}
                    >
                      {recommendedCommissions.map(commission => {
                        const { employeeID, employeeName } = commission;
                        return (
                          <MenuItem key={employeeID} value={employeeID}>
                            {employeeName}
                          </MenuItem>
                        );
                      })}
                      {!!nonRecommendedCommissions.length && (
                        <MenuItem disabled>──────────</MenuItem>
                      )}
                      {nonRecommendedCommissions.map(commission => {
                        const { employeeID, employeeName } = commission;
                        return (
                          <MenuItem key={employeeID} value={employeeID}>
                            {employeeName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </MuiThemeProvider>
        </Box>
      </div>
    </PluginComponent>
  );
};

export default ProductEdit;
