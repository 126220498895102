import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { matches } from 'plugins/ideal/features/customerMultiOptIn/utils';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { Customer, OnChange } from 'plugins/ideal/types';

/**
 * a 'default' value guaranteed to be different from whatever the user enters
 *
 * Used to ensure that new accounts get changelogs generated regardless of whether they're saved with checked true/false/undefined
 * Technically NaN would work just as fine, but this seems more semantic
 */
const NEW = Symbol('NEW');

function useGenerateChangelog() {
  const employeeID = useSelector(getLoggedInEmployeeID);
  const { firstName, lastName } = useSelector(getEmployeeById(employeeID));

  const generateChangelog = useCallback(() => {
    const date = dayjs().format('DD.MM.YYYY');
    const time = dayjs().format('HH:mm:ss');
    const name = `${firstName} ${lastName}`;
    return `${date} ${time} ${name}`;
  }, [firstName, lastName]);

  return generateChangelog;
}

interface OptOutCheckboxProps {
  value: boolean;
  originalValue?: boolean | symbol;
  onChange(): void;
  changelog: string;
  originalChangelog?: string;
  setChangelog(changelog?: string): void;
  label: string;
}

const OptOutCheckbox = ({
  value,
  originalValue,
  onChange,
  changelog,
  originalChangelog,
  setChangelog,
  label,
}: OptOutCheckboxProps) => {
  const { t } = useTranslation('createCustomer');
  const generateChangelog = useGenerateChangelog();

  const handleChange = () => {
    onChange();
    // Update / revert changelogs as inputs are toggled
    const newValue = !value;
    const parsedOriginalValue =
      typeof originalValue === 'symbol'
        ? originalValue
        : Boolean(originalValue);
    if (newValue === parsedOriginalValue) {
      setChangelog(originalChangelog);
    } else {
      setChangelog(generateChangelog());
    }
  };

  const asChangelog = R.unless(R.isNil, changelog =>
    t('changelog', { changelog }),
  );

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={handleChange} />}
        label={label}
      />
      <FormHelperText>{asChangelog(changelog)}</FormHelperText>
    </FormControl>
  );
};

const lenses = {
  optOut1: R.lensProp('emailOptOut'),
  changelog1: R.lensPath(['attributes', 'lastChangedMailOptOut']),
  optOut2: R.lensPath(['attributes', 'mailOptOutVk']),
  changelog2: R.lensPath(['attributes', 'lastChangedMailOptOutVk']),
};

interface UseMultiOptInProps {
  onChange: OnChange;
  value: Customer;
  isCompany: boolean;
}

export const useMultiOptIn = ({
  onChange,
  value,
  isCompany,
}: UseMultiOptInProps) => {
  const generateChangelog = useGenerateChangelog();

  const [newChangelog] = useState(generateChangelog);

  const isEditView = value.customerID;

  // Apply default values if creating new customer
  useEffect(() => {
    if (isEditView) return;
    if (!R.view(lenses.changelog1, value)) {
      onChange(
        R.pipe(
          R.set(lenses.changelog1, newChangelog),
          R.set(lenses.optOut1, true),
        ),
      );
    }
    if (!R.view(lenses.changelog2, value)) {
      onChange(
        R.pipe(
          R.set(lenses.changelog2, newChangelog),
          R.set(lenses.optOut2, true),
        ),
      );
    }
  }, [isCompany, isEditView, newChangelog, onChange, value]);

  const [originalCustomer, setOriginalCustomer] = useState<{
    optOut1: boolean | symbol;
    optOut2: boolean | symbol;
    changelog1: string | undefined;
    changelog2: string | undefined;
  } | null>(null);

  // Initialize original values
  useEffect(() => {
    if (originalCustomer !== null) return;
    if (isEditView)
      setOriginalCustomer({
        optOut1: R.view(lenses.optOut1, value),
        optOut2: R.view(lenses.optOut2, value),
        changelog1: R.view(lenses.changelog1, value),
        changelog2: R.view(lenses.changelog2, value),
      });
    // If no customerID, then originalCustomer is the value with both opt outs set to NEW
    else {
      setOriginalCustomer({
        optOut1: NEW,
        optOut2: NEW,
        changelog1: undefined,
        changelog2: undefined,
      });
    }
  }, [isEditView, newChangelog, originalCustomer, value]);

  return {
    optOutCheckboxes: (
      <>
        <OptOutCheckbox
          value={R.view(lenses.optOut1, value) ?? false}
          originalValue={originalCustomer?.optOut1}
          onChange={() => onChange(R.over(lenses.optOut1, a => !a))}
          label="Klient ei soovi saada e-posti (iDeal)"
          changelog={R.view(lenses.changelog1, value)}
          originalChangelog={originalCustomer?.changelog1}
          setChangelog={(v?: string) => {
            if (!!v && v !== R.view(lenses.changelog1, value))
              onChange(R.set(lenses.changelog1, v));
          }}
        />
        <OptOutCheckbox
          value={R.view(lenses.optOut2, value) ?? false}
          originalValue={originalCustomer?.optOut2}
          onChange={() => onChange(R.over(lenses.optOut2, a => !a))}
          label="Klient ei soovi saada e-posti (Valge Klaar)"
          changelog={R.view(lenses.changelog2, value)}
          originalChangelog={originalCustomer?.changelog2}
          setChangelog={(v?: string) => {
            if (!!v && v !== R.view(lenses.changelog2, value))
              onChange(R.set(lenses.changelog2, v));
          }}
        />
      </>
    ),
    removeDefaultEmailOptOut: R.reject(matches(/emailOptOut/)),
  };
};
