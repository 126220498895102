import * as R from 'ramda';

export const shouldSaveSaleDocToFiscal = saleDoc =>
  (Number(saleDoc.confirmed) || Number(saleDoc.confirmInvoice)) &&
  // client wanted credit-note functionality removed (at least for now)
  ['CASHINVOICE', 'CREDITINVOICE'].includes(saleDoc.type);

export const matches = regex => c => {
  const key = c?.key?.replace(/\W/gi, '').replace('0', '');
  return regex.test(key);
};

export const insertAfter = R.curry((regex, item, list) => {
  const index = R.findIndex(matches(regex), list) + 1;
  return R.insert(index, item, list);
});
