import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import {
  getCartHasReferencedReturnRow,
  getHasRightToEditDocument,
  getIsProductNonDiscountable,
} from 'reducers/ShoppingCart';
import {
  getDiscountPercentages,
  getPriceEditingAllowedForExchangesAndReturns,
} from 'reducers/configs/settings';
import { Order } from 'types/ShoppingCart';
import { PluginComponent } from 'plugins';
import {
  getCurrentSalesDocument,
  getIsCurrentSaleAReturn,
} from 'reducers/sales';
import useProducts from 'utils/hooks/useProducts';

export type ReturnDiscountButtonsProps = {
  order: Order;
  value: number;
  onChange: (value: unknown) => void;
  max: number;
};

const ReturnDiscountButtons: FC<ReturnDiscountButtonsProps> = ({
  order,
  value,
  onChange,
  max,
}) => {
  const isProductNonDiscountable = useSelector(
    getIsProductNonDiscountable(order),
  );
  const discountPercentages: number[] = useSelector(getDiscountPercentages);

  const { confirmed, rows = [] } = useSelector(getCurrentSalesDocument);
  const canEditGivenDocument = useSelector(getHasRightToEditDocument);

  const isAReturn = useSelector(getIsCurrentSaleAReturn);

  const cartHasReferencedReturnRow = useSelector(getCartHasReferencedReturnRow);
  const allowPriceEditingOnReturns = useSelector(
    getPriceEditingAllowedForExchangesAndReturns,
  );

  const isOpenedOrderOnConfirmedDocument =
    Number(confirmed) === 1 &&
    rows.some(row => row.stableRowID === order.stableRowID);

  const {
    products: [product],
  } = useProducts({
    productID: order.productID,
  });

  const isZeroPricedProduct =
    // Check for priceListPrice since a zero-priced (price = 0) product may be in a price list that has a price > 0
    product?.priceListPrice === 0 && Number(order.finalPrice) === 0;

  return (
    <PluginComponent
      hookname="UICustomReturnDiscountButtons"
      props={{
        value,
        order,
        isProductNonDiscountable,
        max,
        onChange,
        discountPercentages,
      }}
    >
      <ToggleButtonGroup
        defaultValue={value}
        value={value}
        style={{ flexWrap: 'wrap' }}
        name="discount-percentage"
        type="radio"
        onChange={onChange}
        data-testid="discount-percentages"
      >
        {discountPercentages.map(discount => (
          <ToggleButton
            key={uuid.v1()}
            variant="light"
            disabled={
              max < discount ||
              isProductNonDiscountable ||
              // if is referenced return, check setting, otherwise check if opened doc
              (isAReturn && rows.length
                ? cartHasReferencedReturnRow && !allowPriceEditingOnReturns
                : isOpenedOrderOnConfirmedDocument && !canEditGivenDocument) ||
              isZeroPricedProduct
            }
            value={discount}
            data-testid="percentage"
            data-test-key={discount}
          >
            {discount !== 0 ? `-${discount}%` : `${discount}%`}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </PluginComponent>
  );
};

export default ReturnDiscountButtons;
