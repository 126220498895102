/**
 * Generates a SHA256 hash of the following string: “salt|customerId|salt”
 */
export const generateToken = async (
  customerId: string,
  salt: string,
): Promise<string> => {
  const data = `${salt}|${customerId}|${salt}`;
  const dataAsBuffer = new TextEncoder().encode(data);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', dataAsBuffer);
  return new Uint8Array(hashBuffer).reduce(
    (a, b) => a + b.toString(16).padStart(2, '0'),
    '',
  );
};
