import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { useMethods } from 'react-use';

import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { getSalesDocumentsCompleteResponse } from 'actions/sales';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import {
  getCustomerSearchFilterOption,
  getCustomerSearchResultsConfiguration,
  getSetting,
} from 'reducers/configs/settings';
import Loader from 'components/Loader';
import { Customer } from 'types/Customer';
import { getCustomers } from 'services/ErplyAPI';
import { addWarning } from 'actions/Error';
import { getSelectedWarehouseID, getWarehouseById } from 'reducers/warehouses';
import { beforeShowCustomerDetails } from 'actions/Plugins';

import { useSearchResultStyles } from '../index.styles';

import {
  defineComponentToDisplay,
  getCustomerName,
  getRowsToDisplay,
} from './util';

const CustomerResultRow = React.memo(
  React.forwardRef<
    HTMLTableRowElement,
    {
      customer: Customer;
      selected?: boolean;
      onSelect: (c: Customer) => void;
      close: () => void;
      location?: string;
      companyName?: string;
    }
  >(({ customer, selected, onSelect, location, companyName }, ref) => {
    const dispatch = useDispatch();
    const gridIsOpened = useSelector(getGridIsOpened);
    const classes = useSearchResultStyles();
    const customerResultsConfig = useSelector(
      getCustomerSearchResultsConfiguration,
    );

    const onShowCustomerDetails = async (e: MouseEvent) => {
      e.stopPropagation();
      try {
        // todo refactor so the hook follows the hook standard in POS
        await dispatch(beforeShowCustomerDetails(customer));
        dispatch(
          openModalPage({
            component: mp.customerView,
            groupID: mp.customerView,
            replace: true,
            props: { customer },
          }),
        );
      } catch (error) {
        // Plugin errors don't get printed
      }
    };

    return (
      <TableRow
        data-testid="search-results-row"
        data-test-key={`${customer.firstName}-${customer.lastName}-${customer.email}`}
        key={customer.customerID}
        onClick={() => onSelect(customer)}
        className={classes.resultRow}
        selected={selected}
        ref={ref}
      >
        {defineComponentToDisplay({
          customer,
          location,
          companyName,
          rowsToDisplay: getRowsToDisplay(customerResultsConfig, !gridIsOpened),
        })}
        <TableCell>
          <Typography
            data-testid="customer-icon"
            onClick={onShowCustomerDetails}
          >
            <ContactMailIcon />
          </Typography>
        </TableCell>
      </TableRow>
    );
  }),
);

const NoResultsRow = ({ text }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography>{text}</Typography>
      </TableCell>
    </TableRow>
  );
};

const DisplayCustomersPerStore: FC<{
  customers: Customer[];
  warehouseID: number;
  close: () => void;
  onSelect: (customer: Customer) => void;
}> = ({ customers, warehouseID, close, onSelect }) => {
  const { t } = useTranslation('search');
  const fallBackLocationName = t('customers.otherLocations');
  const { name = fallBackLocationName } =
    useSelector(getWarehouseById(warehouseID)) ?? {};
  return customers.length ? (
    <>
      {customers.map(customer => (
        <CustomerResultRow
          key={customer.customerID}
          customer={customer}
          close={close}
          onSelect={onSelect}
          location={name}
        />
      ))}
    </>
  ) : null;
};

const CustomersFromOtherLocations: FC<{
  close: () => void;
  searchValue: string;
  onSelect: (customer: Customer) => void;
}> = ({ close, searchValue, onSelect }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [customersDict, setCustomersDict] = useState<
    Record<string, Customer[]>
  >({});
  const warehouseID = useSelector(getSelectedWarehouseID);
  const customerFilterOption = useSelector(getCustomerSearchFilterOption);
  const customersAreFiltered = customerFilterOption !== 'disabled';

  useEffect(() => {
    setShow(false);
    setCustomersDict({});
  }, [searchValue]);

  const showCustomersFromOtherLocations = () => {
    const keyProp = {
      byHomeStore: 'homeStoreID',
      bySignupStore: 'signUpStoreID',
    }[customerFilterOption];

    getCustomers({
      recordsOnPage: 100,
      getAddresses: 1,
      getBalanceInfo: 1,
      getBalanceWithoutPrepayments: 1,
      searchFromMiddle: 1,
      responseMode: 'detail',
      searchNameIncrementally: searchValue,
    })
      .then(data => {
        setShow(true);
        setCustomersDict(
          data.reduce((acc, d) => {
            const key = d[keyProp];
            if (Number(key) === Number(warehouseID)) return acc;
            return { ...acc, [key]: [...(acc[key] ?? []), d] };
          }, {}),
        );
      })
      .catch(err => {
        console.error('Failed to load customers from other locations', err);
        dispatch(addWarning('Fetching more customers failed'));
      });
  };

  if (!customersAreFiltered) return null;

  if (!show)
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <Button
            style={{ width: '100%' }}
            onClick={showCustomersFromOtherLocations}
          >
            Show results from other stores
          </Button>
        </TableCell>
      </TableRow>
    );

  const moveZeroLocationInTheEnd = (arr: string[]) => {
    const index = arr.findIndex(n => n === '0');
    if (index === -1) return arr;
    return [...arr.slice(0, index), ...arr.slice(index + 1, arr.length), 0];
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={4}>
          <h4>Customers from other locations:</h4>
        </TableCell>
      </TableRow>
      {moveZeroLocationInTheEnd(Object.keys(customersDict)).map(key => (
        <DisplayCustomersPerStore
          key={key}
          close={close}
          warehouseID={Number(key)}
          customers={customersDict[key]}
          onSelect={onSelect}
        />
      ))}
    </>
  );
};

const inheritMaxHeight = { maxHeight: 'inherit' };

const CustomerResults: FC<{
  close: () => void;
  customers: Customer[];
  loading: boolean;
  searchValue: string;
  selectedIndex?: number;
}> = ({ close, customers, loading, searchValue, selectedIndex = 0 }) => {
  const { t } = useTranslation('search');
  const gridIsOpened = useSelector(getGridIsOpened);
  const searchMinLength = useSelector(getSetting('customer_search_min_length'));
  const ref = useRef<Array<HTMLTableRowElement | null>>([]);
  const customerResultsConfig = useSelector(
    getCustomerSearchResultsConfiguration,
  );

  const dispatch = useDispatch();
  const select = useCallback(
    (customer: Customer) => {
      if (!customer) return;
      dispatch(
        getSalesDocumentsCompleteResponse({
          clientID: customer.id,
          type: 'CASHINVOICE',
          confirmed: 1,
          getRowsForAllInvoices: 1,
        }),
      );
      dispatch(
        setCustomer({
          data: customer.customerID,
          firstName: customer.firstName,
          lastName: customer.lastName,
          recalculateShoppingCart: true,
          displayCouponsInfo: true,
          fetchRewardPoints: true,
          companyName: customer.companyName,
        }),
      );
      close();
    },
    [close, dispatch],
  );
  const getText = () => {
    if (searchValue.length >= searchMinLength) return t('noResultsFor');
    return t('notEnoughCharacters', { minLength: searchMinLength });
  };
  const text = getText();

  const [selected, { CustomSet, ...keyHandlers }] = useMethods(
    n => ({
      CustomSet: (b: number) => {
        return b;
      },
      ArrowUp: () => (n - 1 + customers.length) % customers.length,
      ArrowDown: () => (n + 1 + customers.length) % customers.length,
      PageUp: () => (n - 10 + customers.length) % customers.length,
      PageDown: () => (n + 10 + customers.length) % customers.length,
      Enter: () => {
        select(customers[n]);
        return n;
      },
    }),
    0,
  );

  useEffect(() => {
    CustomSet(selectedIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const onKeyDown = useCallback(
    (e: { preventDefault: () => void; key: string }) => {
      if (keyHandlers[e.key]) {
        keyHandlers[e.key]();
        e.preventDefault();
      }
    },
    [keyHandlers],
  );

  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    ref.current[selected]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [selected]);

  return (
    <Loader show={loading} block style={inheritMaxHeight}>
      <TableContainer component={Paper} style={inheritMaxHeight}>
        <Table
          data-testid="customer-search-results"
          aria-label="customer results"
          stickyHeader
          style={inheritMaxHeight}
          tabIndex={0}
          onKeyDown={onKeyDown}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        >
          <TableHead data-testid="product-results-headers">
            <TableRow>
              {getRowsToDisplay(customerResultsConfig, !gridIsOpened)
                .map(name => t(`customers.headers.${name}`))
                .map(header => (
                  <TableCell
                    key={header}
                    data-testid="product-search-header"
                    data-test-key={header}
                  >
                    <Typography variant="h6">{header}</Typography>
                  </TableCell>
                ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody data-testid="customer-results-body">
            {customers.length > 0 && searchValue.length >= searchMinLength ? (
              customers.map((customer, i) => (
                <>
                  <CustomerResultRow
                    key={customer.customerID}
                    customer={customer}
                    selected={hasFocus && selected === i}
                    onSelect={select}
                    close={close}
                    ref={el => {
                      ref.current[i] = el;
                    }}
                  />
                  {customer.contactPersons?.map(contact => (
                    <CustomerResultRow
                      key={contact.customerID}
                      customer={contact}
                      close={close}
                      onSelect={select}
                      companyName={getCustomerName(customer)}
                      ref={el => {
                        ref.current[i] = el;
                      }}
                    />
                  ))}
                </>
              ))
            ) : (
              <NoResultsRow text={text} />
            )}
            <CustomersFromOtherLocations
              close={close}
              searchValue={searchValue}
              onSelect={select}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Loader>
  );
};

export default CustomerResults;
