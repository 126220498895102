import React from 'react';
import './SaveButton.css';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Box,
  makeStyles,
  ButtonProps,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import MuiButton from './MuiButton';

const useProgressStyle = makeStyles(() => ({
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const StyledButton = withStyles({
  root: {
    textTransform: 'uppercase',
  },
})(MuiButton);

interface Props extends ButtonProps {
  action?: () => void;
  title?: string;
  loading?: boolean;
}

const SaveButton: React.FC<Props> = ({
  action = undefined,
  title = undefined,
  loading = false,
  ...otherProps
}) => {
  const { buttonProgress } = useProgressStyle();
  const { t } = useTranslation('common');
  return (
    <Box position="relative">
      <StyledButton
        {...otherProps}
        color="UIButton"
        variant="contained"
        onClick={action}
        className="save-button-view"
      >
        {title || t('save', { context: 'changes' })}
      </StyledButton>
      {loading && <CircularProgress size={24} className={buttonProgress} />}
    </Box>
  );
};

export default SaveButton;
