import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Box, Typography } from '@material-ui/core';

import CloseButton from 'components/CustomButtons/CloseButton';
import { removeProduct, updateOrderAmount } from 'actions/ShoppingCart';
import { addWarning } from 'actions/Error';
import { round } from 'utils';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getProductInOrderByIndex } from 'reducers/ShoppingCart';

import useScaleInput from './useScaleInput';

type Props = {
  onClose: () => void;
  orderIndex: number;
  unit: string;
};

const ProductWeight: React.FC<Props> = ({ orderIndex, unit, onClose }) => {
  const selectedProduct = useSelector(getProductInOrderByIndex(orderIndex));
  const { t } = useTranslation('product');

  const dispatch = useDispatch();
  const [localAmount, setLocalAmount] = useState<string | null>(null);
  const scaleAmount = useScaleInput(!localAmount);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  const amount = localAmount || round(scaleAmount, 3) || '';

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let qty = event.target.value;
      qty = qty.replace(/[,]/g, '.');
      qty = qty.replace(/[^0-9-.]/g, '');
      if (qty.match(/^(-{0,1})([0-9]{0,1}|[1-9]{1}[0-9]*).{0,1}[0-9]*$/g)) {
        if (qty.match(/[-]/g)) qty = `-${qty.replace(/[-]/g, '')}`;
        if (qty.match(/^[.]/g)) qty = qty.replace(/^[.]/g, '0.');
        if (qty.match(/^[-]+[.]/g)) qty = qty.replace(/^[-]+[.]/g, '-0.');
        if (qty.match(/^[0]+[1-9]/g)) qty = qty.replace(/^[0]/g, '');
        setLocalAmount(qty);
      }
    },
    [setLocalAmount],
  );

  const handleSave = useCallback(
    event => {
      event.preventDefault();
      if (Number.isNaN(Number(amount)) || !Number(amount)) {
        dispatch(
          addWarning(t('weight.alerts.noWeight'), { selfDismiss: 3000 }),
        );
      } else {
        dispatch(
          updateOrderAmount(
            Number(amount) + Number(selectedProduct.amount),
            orderIndex,
          ),
        );
        onClose();
      }
    },
    [dispatch, orderIndex, amount],
  );

  useShortcut('Enter', handleSave, 100);

  return (
    <div data-testid="product_weight_popup">
      <Box display="flex" p={2}>
        <Typography variant="h5">{t('weight.title')}</Typography>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            data-testid="product_weight_popup_save"
          >
            {t('save')}
          </Button>
          <CloseButton
            action={() => {
              // If the product was added to cart and is now being edited, then remove
              // else don't remove it and keep old amount
              if (Number(selectedProduct.amount) === 0) {
                dispatch(
                  addWarning(t('weight.alerts.productRemoved'), {
                    selfDismiss: 3000,
                  }),
                );
                dispatch(removeProduct(orderIndex));
              }
              onClose();
            }}
          />
        </Box>
      </Box>
      <Box p={2}>
        <TextField
          data-testid="product_weight_popup_input"
          variant="outlined"
          fullWidth
          autoFocus
          inputRef={inputRef}
          label={t('weight.fields.productWeight', { unit: unit.toUpperCase() })}
          value={amount}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
};

export default ProductWeight;
