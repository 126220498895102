import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import * as R from 'ramda';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getProductGroups } from 'reducers/productGroupsDB';
import { notUndefinedOrNull } from 'utils';

const pluginID = 'stickyProductGroups';
const getConfiguration = state =>
  getPluginConfiguration<pluginConfiguration>(pluginID)(state);

type groupConf = {
  id: boolean;
};
type pluginConfiguration = {
  groups: groupConf[];
  placement: 'before' | 'after';
};

/**
 *  This plugin is used to add fixated/sticky product groups to the list of the product group buttons in the POS.
 *  This is useful for adding shortcut for often used product groups.
 *
 *  The plugin uses the following hook(s):
 *
 *  @UIProductGroups - Edits the ProductGroup component by changing the array of the product groups from which the buttons are generated.
 *  This is done by editing the `items` of the children. The items are changed in the following way:
 *  First the group ids from the configuration are received, then they are filtered to get the enabled ones.
 *  Then we get the list of the actual groups by their IDs by mapping throught the configured groups and `find`ing the correct group from the list of all groups.
 *  Lastly we combine the groups that are supposed to be shown and the ones coming from the plugin by either adding the plugin ones to the beginning or the end of the list and filtering the resulting list so that it has only 1 instance of a group.
 *
 *  @Configuration
 *  1. `groups` - Configuration of which groups are set to be "sticky". Value of the configuration is a dictionary where the key is the ID of the group and value is a boolean. Example: `"groups": { "13": true, "24": false }`.
 *  2. `placement` - Configuration which determines how the "sticky" groups are added to the list of the Product Groups buttons. The value is either `before` or `after`. `before` adds the "sticky" groups before the visible groups, `after` adds the "sticky" groups after the visible groups (unless "sticky" group is already present in the list of the Product Groups buttons, then it will remain in its original position). Default value is `before`.
 */

const stickyProductGroups: PosPlugin = {
  id: pluginID,
  name: 'Sticky product groups',
  keywords: ['product group', 'fixed', 'sticky'],
  // eslint-disable-next-line global-require
  infoMDUrl: require('./documentation.md'),
  getStatus: () => ({ type: 'valid', message: 'Ready' }),
  ComponentConfiguration: ({ current, save }) => {
    const allGroups = useSelector(getProductGroups);
    const [groups, setGroups] = useState(current?.groups ?? []);
    const [placement, setPlacement] = useState(current?.placement ?? 'before');
    const dispatch = useDispatch();

    const handleChange = e => {
      const { target } = e;
      const { value, name, checked } = target;
      if (name === 'placement') {
        setPlacement(value);
      } else {
        setGroups({
          ...groups,
          [name]: checked,
        });
      }
    };

    const onSave = () => {
      save({ ...current, groups, placement });
      dispatch(previousModalPage());
    };

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={R.equals({ groups, placement }, current)}
        >
          Save
        </Button>
        <FormGroup style={{ overflow: 'hidden' }}>
          <InputLabel id="placement-selection">
            Select how the sticky groups should be placed in relation to main
            groups
          </InputLabel>
          <Select
            id="placement-selection"
            labelId="placement-selection"
            name="placement"
            value={placement}
            onChange={handleChange}
            label="placement-selection"
          >
            <MenuItem value="before" key="placement-before">
              Before main groups
            </MenuItem>
            <MenuItem value="after" key="placement-after">
              After main groups
            </MenuItem>
          </Select>
          <InputLabel id="placement-selection">
            Select groups that will be displayed in every group selection menu
          </InputLabel>
          {allGroups.map(gr => (
            <FormControlLabel
              key={gr.id}
              control={
                <Checkbox
                  name={gr.id}
                  checked={!!groups?.[gr?.id]}
                  onChange={handleChange}
                />
              }
              label={gr.name}
            />
          ))}
        </FormGroup>
      </>
    );
  },
  UIProductGroups: ({ productGroups, children }) => {
    const allGroups = useSelector(getProductGroups);
    const conf = useSelector(getConfiguration) || { groups: [] };
    const { groups: additionalGroups, placement } = conf;
    const enabledGroupIds = Object.entries(additionalGroups)
      .filter(([k, v]) => v)
      .map(([k, v]) => k);
    const child = (Array.isArray(children) ? children[0] : children) || null;
    const enabledGroups = enabledGroupIds
      .map(id => allGroups.find(ag => ag.id === id))
      .filter(notUndefinedOrNull);
    const resultingGroups =
      (placement || 'before') === 'before'
        ? [...enabledGroups, ...productGroups]
        : [...productGroups, ...enabledGroups];

    return (
      child &&
      children &&
      // @ts-ignore
      React.cloneElement(child, {
        /*
         * Already-existing groups will move to the 'before' section but not the 'after' section
         * e.g. viewing [a,x,b], with sticky groups [x,y]
         *  when adding 'before': [x,y,a,b]
         *  when adding 'after':  [a,x,b,y]
         * This difference is intentional, because:
         *   Users who choose 'before' want all the plugin configured groups to be in the beginning for easy access
         *   Users who choose 'after' want to keep the default groups as they are and add shortcuts to the missing ones at the end
         * source: PBIB-3501 and @Dorrit on slack
         */
        items: R.uniq(resultingGroups),
      })
    );
  },
};

export default stickyProductGroups;
