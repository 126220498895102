/* eslint-disable eqeqeq */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import InputField from 'components/FieldTypes/InputField';
import Loader from 'components/Loader';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { saveProduct } from 'actions/productsDB';
import { getProductUnits } from 'reducers/productUnitsDB';
import { getActiveVatRates } from 'reducers/vatRatesDB';
import { getProductGroupsTreeOrdered } from 'reducers/productGroupsDB';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import useProducts from 'utils/hooks/useProducts';

import styles from './InputStyle.module.scss';

const ProductEdit = ({ productID }) => {
  const {
    products: [product],
    loading,
  } = useProducts({ productID });
  const dispatch = useDispatch();

  const productGroups = useSelector(getProductGroupsTreeOrdered);
  const productUnits = useSelector(getProductUnits).map(({ unitID, name }) => ({
    name,
    value: unitID,
  }));
  const vatRates = useSelector(getActiveVatRates).map(({ id, name }) => ({
    name,
    value: id,
  }));

  const [overrides, setOverrides] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const get = key =>
    overrides[key] !== undefined ? overrides[key] : product?.[key];
  const dName = get('name');
  const dCode = get('code');
  const dGroupID = get('groupID');
  const dVatrateID = get('vatrateID');
  const dPrice = get('priceWithVat');
  const dUnitID = get('unitID');
  const dNonStock = get('nonStockProduct');

  const onChange = useCallback(e => {
    const {
      target: { name, value },
    } = e;
    setOverrides(p => ({ ...p, [name]: value }));
  }, []);

  const save = useCallback(() => {
    setIsSaving(true);
    const fixedOverrides = {
      ...overrides,
      priceWithVat: undefined,
      priceWithVAT: overrides.priceWithVat,
    };
    const changes = Object.keys(fixedOverrides)
      .filter(key => fixedOverrides[key] !== product[key])
      .map(key => ({ [key]: fixedOverrides[key] }));
    if (changes.length === 0) {
      return;
    }

    dispatch(
      saveProduct({
        productID,
        ...Object.assign({}, ...changes),
      }),
    )
      .then(hadError => {
        if (!hadError) {
          dispatch(previousModalPage());
        } else {
          console.error('There was an error saving the product');
        }
      })
      .finally(() => setIsSaving(false));
  }, [product, overrides, dispatch]);

  useShortcut('Enter', save, 30);

  const ProductInputField = useCallback(
    ({ title, name, value, ...props }) => (
      <div className={styles.inputContainer}>
        <InputField
          size="lg"
          title={title}
          name={name}
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
    ),
    [onChange],
  );
  const { t } = useTranslation('product');
  const priceConflict = product?.price != product?.priceListPrice;

  if (!loading && !product?.productID) {
    dispatch(previousModalPage());
    return null;
  }

  return (
    <Loader
      show={isSaving || loading}
      loadingText={loading ? t('loading') : t('saving')}
      style={{ display: 'block' }}
    >
      <div className={classNames(['product-edit', styles.productEdit])}>
        <Modal.Header>
          <span style={{ fontSize: '1.75em', fontWeight: 700 }}>
            {product?.name}
          </span>

          <span style={{ flexGrow: 1 }} />
          <SaveButton action={save} />
          <CloseButton
            action={() => {
              dispatch(previousModalPage());
            }}
          />
        </Modal.Header>

        <Modal.Body>
          <>
            <ProductInputField
              title={t('inputs.name')}
              name="name"
              value={dName}
            />

            <ProductInputField
              title={t('inputs.code')}
              name="code"
              value={dCode}
            />

            <ProductInputField
              title={t('inputs.groupID')}
              name="groupID"
              value={dGroupID}
              type="select"
              options={productGroups}
            />

            <ProductInputField
              title={t('inputs.vatrateID')}
              name="vatrateID"
              value={dVatrateID}
              type="select"
              options={vatRates}
            />

            <ProductInputField
              title={t('inputs.priceWithVat')}
              name="priceWithVat"
              value={dPrice}
            />
            {priceConflict && (
              <div className={styles.note}>
                <div>{t('notePriceListsAreActive')}</div>
              </div>
            )}
            <ProductInputField
              title={t('inputs.unitID')}
              name="unitID"
              value={dUnitID}
              type="select"
              options={[{ name: '', value: '0' }].concat(productUnits)}
            />
          </>

          <hr />
          <div>{t('inputs.attributes', { context: 'title' })}</div>
          <InputField
            size="lg"
            type="checkbox"
            numeric
            name="nonStockProduct"
            value={dNonStock}
            onChange={onChange}
          >
            {t('inputs.nonStockProduct')}
          </InputField>
        </Modal.Body>
      </div>
    </Loader>
  );
};
ProductEdit.displayName = 'ProductEdit';
export default ProductEdit;
