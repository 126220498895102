import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import {
  getGivexConfiguration,
  getIsAllowed,
} from 'plugins/givexHeartland/configuration/Configuration';
import { getPayments } from 'reducers/Payments';
import { addProgress, addSuccess, dismissType } from 'actions/Error';
import { CartItem } from 'containers/Forms/GiftCardSerial/types';
import { getCurrencyFormatter, getLimitsFor } from 'reducers/configs/settings';
import { createConfirmation } from 'actions/Confirmation';
import { ThunkAction } from 'reducers';
import { getReason } from 'utils';
import { PluginError } from 'plugins/pluginUtils';

import {
  generateGivexIncrementProductNames,
  isGivexIncrementProduct,
} from '../utils';
import {
  getCardToDeactivate,
  getCardsToRefill,
  removeCardToRefill,
  resetState,
  setCardToDeactivate,
} from '../rdx';
import * as api from '../API/givexAPI';
import { GivexCard } from '../types';

/**
 * Removes givex refill and deactivation refund payments from the payments list (so that the totals would add up),
 * and converts givex charges to be of type giftcard
 */
export const postProcessPayments: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, requests) => async (
  dispatch,
  getState,
) => {
  const products = getProductsInShoppingCart(getState());
  const payments = getPayments(getState());

  const isSavePayment = R.propEq('requestName', 'savePayment');
  const isGivexPayment = R.both(isSavePayment, R.propEq('cardType', 'GIVEX'));
  const isSaveSalesDoc = R.propEq('requestName', 'saveSalesDocument');
  const productNames = generateGivexIncrementProductNames(products, payments);

  const isGivexMetaPayment = R.both(isSavePayment, R.propEq('type', 'META'));

  return R.pipe(
    // Remove refill and deactivation refund payments from payments list
    R.filter(R.complement(isGivexMetaPayment)),
    // Convert charges to type GIFTCARD
    R.map(R.when(isGivexPayment, R.assoc('type', 'GIFTCARD'))),
    R.map(R.when(isSaveSalesDoc, R.mergeLeft(productNames))),
  )(requests) as typeof requests;
};

export const attemptToDeactivate = (
  cardInfo: GivexCard,
): ThunkAction<boolean> => async (dispatch, getState) => {
  await dispatch(
    addProgress('Deactivating the gift card', 'givex-deactivation'),
  );
  const { displayName } = getGivexConfiguration(getState());
  try {
    await api.deactivateGiftcard(cardInfo);
    dispatch(dismissType('givex-deactivation'));
    return true;
  } catch {
    dispatch(dismissType('givex-deactivation'));
    return new Promise<boolean>(resolve =>
      dispatch(
        createConfirmation(
          async () => {
            resolve(await dispatch(attemptToDeactivate(cardInfo)));
          },
          () => resolve(false),
          {
            title: 'Warning',
            body: `Something went wrong and ${displayName.toLowerCase()} deactivation failed.`,
            confirmText: 'Retry',
            cancelText: 'Close',
          },
        ),
      ),
    );
  }
};

export const refillGiftCards: Required<
  Required<PosPlugin>['onSaveSalesDocument']
>['on'] = (p, ap) => async (dispatch, getState) => {
  const cardsToRefill = getCardsToRefill(getState());
  if (!cardsToRefill.length) return ap;

  const results = await Promise.allSettled(
    cardsToRefill.map(async card => {
      const { amount, ...cardInfo } = card;
      return api.refillGiftcard(cardInfo, amount);
    }),
  );

  const cardsWithResults = cardsToRefill.map((card, index) => ({
    ...card,
    refillResult: results[index],
  }));

  cardsWithResults
    .filter(card => card.refillResult.status === 'fulfilled')
    .forEach(card => dispatch(removeCardToRefill(card.key)));

  const failedCards = cardsWithResults.filter(
    card => card.refillResult.status === 'rejected',
  );

  if (!failedCards.length) return ap;

  const formatCurrency = getCurrencyFormatter(getState());
  const failedCardInfo = failedCards
    .map(
      card =>
        `Card number: ${card.cardNo}\nAmount: ${formatCurrency(
          card.amount,
        )}\nReason: ${getReason(card.refillResult)}\n`,
    )
    .join('\n');

  const message = `Failed to refill Givex card(s)\n\n${failedCardInfo}`;

  throw new PluginError(message, message);
};

/**
 * Sends request to deactivate the card stored in redux. Prompts to retry if deactivation fails.
 */
export const deactivateGiftcard: Required<
  PosPlugin
>['onSaveSalesDocument']['after'] = () => async (dispatch, getState) => {
  const cardInfo = getCardToDeactivate(getState());
  if (!cardInfo) return;
  const didDeactivate = await dispatch(attemptToDeactivate(cardInfo));
  if (didDeactivate) {
    dispatch(addSuccess('Successfully deactivated the gift card'));
  }
  dispatch(setCardToDeactivate(null));
};

export const resetReduxState: Required<
  PosPlugin
>['onClosePayments']['after'] = () => async dispatch => {
  dispatch(resetState());
};

export const removeOriginalGivexPaymentsIfGivexDisallowed: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (p, ap) => async (dispatch, getState) => {
  const isGivexAllowed = getIsAllowed(getState());
  if (!isGivexAllowed) {
    const withoutGivex = (
      payments: Record<
        string,
        { paymentIntegration: string; original?: { paymentID: number } }
      >,
    ) => {
      const filteredPayments = Object.entries(payments).filter(
        ([_, payment]) =>
          !payment.original?.paymentID ||
          payment.paymentIntegration !== 'givex',
      );
      return Object.fromEntries(filteredPayments);
    };
    return R.evolve({ payments: withoutGivex })(ap);
  }
  return ap;
};

/**
 * Add locked negative 'refill' payments for each gift card in cart,
 * and subtract that amount from the total so the balance would remain unaffected
 */
export const addGivexRefillPaymentsFromCart: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (p, ap) => async (_dispatch, getState) => {
  if (p.props.ignoreCurrent) return ap;

  const givexConfig = getGivexConfiguration(getState());
  const cards: CartItem[] = getProductsInShoppingCart(getState()).filter(p =>
    isGivexIncrementProduct(p.productID, givexConfig),
  );
  const refillAmounts = cards.map(p => Number(p.finalPriceWithVAT));

  const refillData = cards.map(p => ({
    name: p.name,
    amount: Number(p.finalPriceWithVAT),
    uuid: p.uuid,
    rowNumber: p.rowNumber,
  }));

  const payments = refillData.map(({ name, amount, uuid, rowNumber }) => ({
    produuid: uuid,
    rowNumber,
    locked: true,
    type: 'META',
    paymentIntegration: 'givex',
    cardType: 'GIVEX',
    amount: (-amount).toFixed(2),
    caption: name,
  }));

  const newAP = R.evolve({
    total: R.subtract(R.__, R.sum(refillAmounts)),
    shoppingCartTotal: R.subtract(R.__, R.sum(refillAmounts)),
    payments: R.mergeDeepLeft(
      R.fromPairs(payments.map(pmt => [pmt.produuid, pmt])),
    ),
  } as any)(ap) as typeof ap;
  return newAP;
};

export const setPaymentLimitsForCardDeactivation: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (p, ap) => async (dispatch, getState) => {
  const cardToDeactivate = getCardToDeactivate(getState());
  if (!cardToDeactivate) return ap;
  return { ...ap, paymentLimits: getLimitsFor('return')(getState()) };
};

export const convertOriginalGiftcardPaymentsBackToGivex: Required<
  PosPlugin
>['onSetCurrentSalesDocPayments']['on'] = (p, ap) => async (
  dispatch,
  getState,
) => {
  const { displayName } = getGivexConfiguration(getState());
  return R.evolve({
    original: R.map(
      R.when(
        (p: any) => p.cardType === 'GIVEX',
        R.pipe(
          R.assoc('type', 'CARD'),
          R.assoc('paymentIntegration', 'givex'),
          R.assoc('caption', `${displayName.toUpperCase()}`),
        ),
      ),
    ),
  })(ap);
};

/**
 * Sorts grouped payments so that givex refill is processed the last.
 * This avoids refilling Givex card before customer pays for it.
 */
export const makeGivexRefillLastPayment: Required<
  PosPlugin
>['onProcessPayments']['on'] = (p, ap) => async (dispatch, getState) => {
  const { displayName } = getGivexConfiguration(getState());
  const hasGivexRefills = payments =>
    !!payments.find(p => p.caption === `${displayName.toUpperCase()} refill`);
  return R.sort(([_, prev], [__, curr]) => {
    if (hasGivexRefills(prev)) return 1;
    if (hasGivexRefills(curr)) return -1;
    return 0;
  })(ap);
};

export const applyGivexCardBalance: Required<
  PosPlugin
>['onSetPayment']['on'] = (_p, ap) => async (_dispatch, getState) => {
  if (ap.paymentIntegration !== 'givex') return ap;
  const payment = getPayments(getState())[ap.key];
  if (payment?.paid && !ap.paid) return ap; // Payment is being voided, ignore balance

  const applyBalance = amount => {
    const cardBalance = !ap.GIVEX
      ? ap.original?.certificateBalance
      : ap.GIVEX?.card.balance;
    return Number(amount) > Number(cardBalance) ? cardBalance : amount;
  };
  return R.evolve({ amount: applyBalance })(ap);
};
