import React, { useMemo } from 'react';
import {
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { yellow, lightGreen, amber, red } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import {
  getCurrencyFormatter,
  getCurrencyFormatterNoSymbol,
  getNumberFormatFunction,
} from 'reducers/configs/settings';
import { modalPages as mp } from 'constants/modalPage';
import { Expiration } from 'plugins/wbu/react';
import { getWbuExtraData } from 'plugins/wbu/redux';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getCustomerIssuedCoupons } from 'reducers/customerSearch';

import { generateExpirationValues } from '../utils';

import ExpandableCustomerBadgeRegion from './ExpandableCustomerBadgeRegion';

const Item = ({
  title,
  children,
  style,
}: {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => (
  <>
    <Typography
      component="span"
      style={{
        whiteSpace: 'normal',
        marginRight: '0.5em',
        ...(style ?? {}),
      }}
    >
      <span style={{ fontWeight: 'bold' }}>{title}: </span>
      {children}
    </Typography>
  </>
);

const overrideTheme = createTheme({
  palette: {
    secondary: amber,
    error: red,
  },
});

const WbuBadgeTheme = R.mergeDeepLeft({
  palette: {
    secondary: overrideTheme.palette.secondary,
    error: overrideTheme.palette.error,
  },
  overrides: overrideTheme.overrides,
});

export const UICustomerBadgeCustomRegion: PosPlugin['UICustomerBadgeCustomRegion'] = ({
  currentCustomer,
  customerRewardPoints,
}) => {
  const dispatch = useDispatch();
  /* Formatters */
  const formatCurrency = useSelector(getCurrencyFormatter);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);
  const formatNumber = useSelector(getNumberFormatFunction);

  /* WBU specific APIs */
  const wbuExtraData = useSelector(getWbuExtraData);

  /* Expiration information to color the component */
  const { expiration, expired, expiresSoon } = generateExpirationValues(
    wbuExtraData?.dscExpiration,
  );

  const commonStyle = {
    // 100% would create an oval, because it would use a larger radius on the x axis
    // but 100vh is scaled down to make perfectly-circular end caps ᑕ____ᑐ
    borderRadius: '100vh',
    padding: '5px',
  };

  const styles = {
    expiration: {
      backgroundColor:
        (expiration && expired && red[400]) ||
        (expiresSoon && amber[500]) ||
        (expiration && lightGreen[500]) ||
        red[400],
      ...commonStyle,
    },
    credit:
      (currentCustomer.availableCredit ?? 0) > 0
        ? {
            backgroundColor: yellow[500],
            ...commonStyle,
          }
        : {},
  };

  const getSavingsInfo = () => {
    if (!wbuExtraData?.dscExpiration) {
      return {
        title: 'Potential savings',
        body: formatCurrency(wbuExtraData?.potential_savings ?? 0),
      };
    }
    return undefined;
  };

  const customerIssuedCoupons = useSelector(getCustomerIssuedCoupons);
  const unusedBB = customerIssuedCoupons.filter(cip => cip.couponCode === 'BB')
    .length;

  const savings = getSavingsInfo();

  const totalVisits = formatNumber(Number(wbuExtraData?.total_visits || 0));
  const totalSales = formatCurrency(
    Number(wbuExtraData?.total_sales || 0).toFixed(0),
    {
      preserveInteger: true,
    }
  );

  const wbuVisitsSaleRatio = useMemo(() => {
    if (!totalVisits) return '0';

    return `${totalVisits} / ${totalSales}`;
  }, [totalSales, totalVisits]);

  return (
    <ThemeProvider theme={WbuBadgeTheme}>
      <ExpandableCustomerBadgeRegion
        minHeight={130}
        maxHeight={500}
        loading={!wbuExtraData}
        onClick={() => {
          dispatch(
            openModalPage({
              component: mp.customerView,
              // @ts-ignore
              groupID: mp.customerView,
              replace: true,
            }),
          );
        }}
      >
        <Grid container style={{ fontSize: 14 }}>
          <Grid container>
            <Grid item xs={6}>
              <Item title="DSC exp" style={styles.expiration}>
                <Expiration
                  expires={wbuExtraData?.dscExpiration}
                  customStyle={{ color: 'inherit' }}
                />
              </Item>
            </Grid>
            <Grid item xs={3}>
              {expiration ? (
                <Item title="DSC Pts">
                  {CURR.stringify(customerRewardPoints)}
                </Item>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              {expiration ? <Item title="Unused BB">{unusedBB}</Item> : null}
            </Grid>
          </Grid>

          {savings ? (
            <Grid container>
              <Grid item xs={6}>
                <Item title={savings.title}>{savings.body}</Item>
              </Grid>
              <Grid item xs={6}>
                <Item title="Visits">{wbuVisitsSaleRatio}</Item>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={6}>
                {/* Space for a future metric */}
              </Grid>
              <Grid item xs={6}>
                <Item title="Visits">{wbuVisitsSaleRatio}</Item>
              </Grid>
            </Grid>
          )}

          <Grid container>
            <Grid item xs={6}>
              <Item title="Email">{currentCustomer?.email}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item title="Birth">{currentCustomer?.householdCode}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item title="Credit" style={styles.credit}>
                {formatCurrency(currentCustomer.availableCredit ?? 0)}
              </Item>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Item title="Note">{currentCustomer.notes ?? ''}</Item>
            </Grid>
          </Grid>
        </Grid>
      </ExpandableCustomerBadgeRegion>
    </ThemeProvider>
  );
};
