import React, { useMemo, useState } from 'react';
import { Pagination, FormControl } from 'react-bootstrap';
import { isInt, isEmpty } from 'validator';
import PropTypes from 'prop-types';

/**
 * Pagination Coponent, given total records and records per page, the component would calculate and return the pages
 *
 */

const Pages = ({ currentPage, totalRecords, itemsPerPage, setPage }) => {
  // controll the value from "go to page" input
  const [jumpPage, setJumpPage] = useState('');

  // calculate how many pages there should be based on the records per page
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  // generate an array of pages based on the totalPages
  const allPages = useMemo(() => {
    const pages = [];
    for (let i = 1; i < totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }, [totalPages]);

  // allow only positive numbers and empty strings as 'go to' input values
  const handleInputChange = e => {
    const { value } = e.target;
    if (isEmpty(value)) {
      setJumpPage(value);
    }

    if (isInt(value.toString())) {
      if (value > totalPages) {
        setJumpPage(totalPages);
        return;
      }
      if (value <= 0) {
        setJumpPage(1);
        return;
      }
      setJumpPage(value);
    }
  };

  // fire setPage when 'go to' input is focused and 'Enter' is pressed
  const onEnterInput = e => {
    if (e.keyCode === 13) {
      if (isInt(jumpPage.toString())) setPage(Number(jumpPage));
    }
  };

  // based on the currentPage, show the nearest pages
  const visiblePages = () => {
    // totalPages more than visible Pagination items
    if (totalPages <= 5) {
      return allPages;
    }
    if (currentPage < 3) {
      return [...allPages.slice(0, 3), 'next'];
    }
    if (totalPages - currentPage > 3) {
      return ['prev', currentPage - 1, currentPage, currentPage + 1, 'next'];
    }
    return ['prev', totalPages - 3, totalPages - 2, totalPages - 1];
  };

  return (
    <Pagination className="pages">
      {
        <Pagination.First
          disabled={currentPage === 1}
          onClick={() => setPage(1)}
        />
      }
      {
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => setPage(Math.max(currentPage - 1, 1))}
        />
      }
      {visiblePages().map(p => {
        if (isInt(p.toString())) {
          return (
            <Pagination.Item
              key={p}
              active={currentPage === p}
              onClick={() => setPage(p)}
              style={{ cursor: p === currentPage ? 'auto' : 'pointer' }}
            >
              {p}
            </Pagination.Item>
          );
        }
        return (
          <Pagination.Ellipsis
            key={p}
            onClick={() =>
              p === 'next'
                ? setPage(Math.min(currentPage + 5, totalPages))
                : setPage(Math.max(currentPage - 5, 1))
            }
            style={{ cursor: 'pointer' }}
          />
        );
      })}
      <Pagination.Item
        key={totalPages}
        active={currentPage === totalPages}
        onClick={() => setPage(totalPages)}
        style={{ cursor: totalPages === currentPage ? 'auto' : 'pointer' }}
      >
        {totalPages}
      </Pagination.Item>
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() => setPage(Math.min(currentPage + 1, totalPages))}
      />
      <Pagination.Last
        disabled={currentPage === totalPages}
        onClick={() => setPage(totalPages)}
      />
      <li style={{ display: 'flex', padding: '0 .375em' }}>
        <div style={{ alignSelf: 'center' }}>Go to</div>
      </li>
      <li>
        <FormControl
          type="input"
          value={jumpPage}
          onChange={handleInputChange}
          onKeyDown={onEnterInput}
          style={{ width: '5ch' }}
        />
      </li>
    </Pagination>
  );
};

Pages.propTypes = {
  /** current page */
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** total records */
  totalRecords: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** number of items shown per page */
  itemsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** current page setter function */
  setPage: PropTypes.func.isRequired,
};

export default Pages;
