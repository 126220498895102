import React from 'react';
import { batch } from 'react-redux';
import * as R from 'ramda';
import { renameKeysWith } from 'ramda-adjunct';

import * as salesAPI from 'services/ErplyAPI/sales';
import * as shoppingCartActionTypes from 'constants/ShoppingCart';
import { getShoppingCartTooltip } from 'reducers/UI/customerTooltip';
import { getSkipCalculateShoppingCartOnChange } from 'reducers/configs/settings';
import { setShoppingCartTooltip } from 'actions/UI';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import {
  prepareShoppingCartForCalculate,
  getShouldCalculateOffline,
  getIsProductNonDiscountable,
  getAppliedPromotions,
  getAppliedCoupons,
} from 'reducers/ShoppingCart';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getClientCode, getSessionKey } from 'reducers/Login';
import { getIsAReturn, getCurrentSalesDocument } from 'reducers/sales';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { CalculateShoppingCartRow } from 'services/ErplyAPI/core/types';

import { addProduct } from './addProduct';

export function calculateSuccess(calculated) {
  return {
    type: shoppingCartActionTypes.CALCULATE_SUCCESS,
    payload: calculated,
  };
}

function calculateFailure(error) {
  return {
    type: shoppingCartActionTypes.CALCULATE_FAILURE,
    payload: error,
  };
}
function calculateOffline(shouldCalculate) {
  return {
    type: shoppingCartActionTypes.SET_CALCULATE_OFFLINE,
    payload: shouldCalculate,
  };
}

/**
 * Calculate Shopping Cart action
 */
export function calculate({
  ...extraParams
}: {
  forceCalculate?: boolean;
  suretaxRerun?: boolean;
  manualPromotionIDs?: string;
  couponIdentifiers?: string;
  customerID?: number;
} = {}) {
  return async (dispatch, getState) => {
    const hasTooltip = getShoppingCartTooltip(getState());
    if (
      getSkipCalculateShoppingCartOnChange(getState()) &&
      !extraParams.forceCalculate
    )
      return;
    batch(() => {
      dispatch({
        type: shoppingCartActionTypes.CALCULATE,
      });
      hasTooltip && dispatch(setShoppingCartTooltip(null));
    });
    try {
      const state = getState();
      const { before, on, after } = getPluginLifecycleHook('onCalculate')(
        state,
      );
      await dispatch(before({ ...extraParams }));
      const { calcIndex } = state.shoppingCart;
      let rows = await dispatch(prepareShoppingCartForCalculate);
      const shouldCalculateOffline = getShouldCalculateOffline(getState());
      const customerID = getSelectedCustomerID(state);
      const clientCode = getClientCode(state);
      const isReturn = getIsAReturn(state);
      const sessionKey = getSessionKey(state);
      const { warehouseID, pointOfSaleID } = getSelectedPos(state);
      const currentSalesDocument = getCurrentSalesDocument(state);
      const {
        taxExemptCertificateNumber: taxExNr,
        isPartialExempt,
        type,
        baseDocumentIDs,
        id,
        rows: curSalesDocRows,
      } = currentSalesDocument;

      rows = await dispatch(on({ ...extraParams }, rows));

      let params: {
        taxExempt?: 0 | 1;
        doNotApplyInvoiceLevelPromotions?: 0 | 1;
        doNotApplyPromotions?: 0 | 1;
      } = {};

      const isSavedLayaway = id && type === 'PREPAYMENT';

      rows
        .map(item => {
          const isNonDiscountable = getIsProductNonDiscountable(item)(
            getState(),
          );

          if (isNonDiscountable && !item.forceDiscount && !isSavedLayaway) {
            return R.dissoc('discount', item);
          }

          /**
           * When order/layaway/account sale is picked up promotion discount and manual discounts
           * are combined and sum percentage is returned from API in the discount field.
           *
           * Pending sales have their own promotion discount handling (they reject discount field).
           */
          const getDiscount = () => {
            if (!id) return item.discount ?? item.manualDiscount;
            if (!item.manualDiscount && !item.discount) return null;

            const discount =
              (1 - (item.manualDiscount ?? 0) / 100) *
              (1 - (item.discount ?? 0) / 100);

            return Math.min((1 - discount) * 100, 100);
          };

          const getPrice = () => {
            if (isReturn) {
              const existingRow = curSalesDocRows?.find(
                origRow => origRow.stableRowID === item.stableRowID,
              );
              const price = existingRow?.price;
              return price ?? item.price;
            }
            return item.price;
          };

          const discount = getDiscount();

          return R.pipe(
            R.ifElse(
              R.always(R.isNil(discount)),
              R.dissoc('discount'),
              R.assoc('discount', discount),
            ),
            // Calculate shopping cart ignores these fields, thus they are removed
            R.omit(['manualDiscount', 'returnReasonID', 'stableRowID']),
            R.assoc('price', getPrice()),
          )(item);
        })
        .map((row, i) => renameKeysWith(k => `${k}${i + 1}`)(row))
        .forEach(item => Object.assign(params, item));

      const manualPromotionIDs = getAppliedPromotions(
        state,
      ).flatMap(({ amount, campaignID }) => Array(amount).fill(campaignID));

      const couponIdentifiers = getAppliedCoupons(state).map(
        coup => coup.uniqueIdentifier,
      );

      if (taxExNr && !isPartialExempt) {
        params.taxExempt = 1;
        // Remove vatrateID properties as they cause api to calculate the tax
        params = R.pickBy((value, key) => !/vatrateID\d+/.test(key))(params);
      }

      if ((id && type === 'ORDER') || baseDocumentIDs) {
        params.doNotApplyPromotions = 1;
      }

      // Type === 'INVOICE' check added to fix issue in 4212
      if (
        type === 'PREPAYMENT' ||
        type === 'INVOICE' ||
        type === 'INVWAYBILL'
      ) {
        params.doNotApplyInvoiceLevelPromotions = 1;
      }

      const [data] = await salesAPI.calculateShoppingCart({
        ...params,
        ...(manualPromotionIDs.length > 0 ? { manualPromotionIDs } : {}),
        ...(couponIdentifiers.length > 0 ? { couponIdentifiers } : {}),
        warehouseID,
        pointOfSaleID,
        getAutomaticCoupons: 1,
        sessionKey,
        clientCode,
        customerID,
        ...extraParams,
      });

      if (shouldCalculateOffline) {
        dispatch(calculateOffline(false));
      }

      const newCalcIndex = getState().shoppingCart.calcIndex;
      if (newCalcIndex === calcIndex) {
        const ret = {} as {
          [key: string]: {
            container: CalculateShoppingCartRow[];
            computed: CalculateShoppingCartRow;
          };
        };
        rows.forEach((row, i) => {
          const [
            orderIndex,
            subOrderIndex = undefined,
          ] = `${row.orderIndex}`.split('-');
          ret[orderIndex] = ret[orderIndex] || { container: [] };
          if (subOrderIndex) {
            ret[orderIndex].container.push(data.rows[i + 1]);
          } else {
            ret[orderIndex].computed = data.rows[i + 1];
          }
        });
        await dispatch(calculateSuccess({ ...data, rows: ret, calcIndex }));
        await dispatch(
          after({ ...params, ...extraParams }, { ...data, rows: ret }),
        );

        if (data.freeExtraProductID) {
          await dispatch(
            setShoppingCartTooltip(
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href=""
                onClick={e => {
                  e.preventDefault();
                  dispatch(
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    addProduct({ productID: data.freeExtraProductID }),
                  );
                }}
              >
                {data.freeExtraNotification}
              </a>
            ),
          );
        }
      }
      // If more changes have occurred, ignore the results from this request
    } catch (e) {
      dispatch(calculateOffline(true));
      console.error('Failed to calculate shopping cart', e);
      dispatch(calculateFailure(e));
    }
  };
}

export function recalculateAfter(action) {
  return async dispatch => {
    await dispatch(action);
    return dispatch(calculate());
  };
}
