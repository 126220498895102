import React, { ChangeEvent, useState } from 'react';
import * as R from 'ramda';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import uuidv1 from 'uuid/v1';
import { createSelector } from 'reselect';
import * as Rx from 'rxjs';
import { concatMap, delay } from 'rxjs/operators';

import SaveButton from 'components/CustomButtons/SaveButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { getProductsUniversal } from 'actions/productsDB';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { getPluginConfiguration } from 'reducers/Plugins';
import { getProducts } from 'services/ErplyAPI';
import { GridButtonSaleOption } from 'reducers/UI/gridButtons';

import { PosPlugin } from '../plugin';

type Configuration = {
  numberOfProducts: number;
  interval: number;
  searchBy: string;
};

const defaultConfiguration: Configuration = {
  numberOfProducts: 10,
  interval: 500,
  searchBy: 'productID',
};

const pluginID = 'todo';
const getConfiguration = state =>
  getPluginConfiguration<Configuration>(pluginID)(state);

const todoPlugin: PosPlugin = {
  id: 'todo',
  name: 'Add bulk products button',
  info:
    'Adds a button to sale options section. When pressed the button adds a preconfigured number of products in a preconfigured interval.',
  getStatus: () => ({ type: 'valid', message: 'Ready' }),
  ComponentConfiguration: ({ current, save }) => {
    const dispatch = useDispatch();
    const [numberOfProducts, setNumberOfProducts] = useState(
      current?.numberOfProducts ?? 0,
    );
    const [interval, setAddInterval] = useState(current?.interval ?? 0);
    const [searchBy, setSearchBy] = useState(current?.searchBy ?? 'productID');

    const onSave = () => {
      save({ numberOfProducts, interval, searchBy });
      dispatch(previousModalPage());
    };

    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <SaveButton
            action={onSave}
            disabled={R.equals(current, {
              numberOfProducts,
              interval,
              searchBy,
            })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            value={numberOfProducts}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNumberOfProducts(Math.max(Number(e.target.value), 0))
            }
            label="Number of Products"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="number"
            inputProps={{ min: 0, max: 1000, step: 10 }}
            value={interval}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAddInterval(Math.max(Number(e.target.value), 0))
            }
            label="Interval in ms"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            value={searchBy}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchBy(e.target.value)
            }
            label="Search By property"
          >
            <MenuItem value="productID">Product ID</MenuItem>
            <MenuItem value="code">Code</MenuItem>
            <MenuItem value="code2">Code 2</MenuItem>
            <MenuItem value="code3">Code 3</MenuItem>
            <MenuItem value="supplierCode">Supplier Code</MenuItem>
            <MenuItem value="code5">Code 5</MenuItem>
            <MenuItem value="code6">Code 6</MenuItem>
            <MenuItem value="code7">Code 7</MenuItem>
            <MenuItem value="code8">Code 8</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    );
  },
  getCustomButtons: createSelector(
    getConfiguration,
    (conf: Configuration = defaultConfiguration) => {
      const action = () => dispatch => {
        getProducts(
          {
            recordsOnPage: Math.min(conf.numberOfProducts, 1000),
            getFields: conf.searchBy,
          },
          0,
          conf.numberOfProducts,
        ).then(({ records }) => {
          const observable = Rx.from<Record<string, string>[]>(records).pipe(
            concatMap(item => Rx.of(item).pipe(delay(conf.interval))),
          );

          observable.subscribe(searchValue => {
            dispatch(getProductsUniversal(searchValue)).then(({ products }) => {
              if (products.length) {
                dispatch(addProduct({ productID: products[0].productID }));
              }
            });
          });
        });
      };

      const addBulkProducts: GridButtonSaleOption = {
        id: uuidv1(),
        name: 'Add Multiple Products',
        actionType: 'action',
        action,
      };

      return {
        functions: [],
        saleOptions: [addBulkProducts],
      };
    },
  ),
};

// const todoPlugin: PosPlugin = {
//   id: 'todo',
//   name: 'TODO plugin',
//   ComponentHeader: ({ children }) => {
//     const channelName = useSelector(getChannelName);
//     const channelValue = `customer_display:${channelName}`;
//     return (
//       <>
//         <span>{channelValue}</span>
//         {children}
//       </>
//     );
//   },
//   getStatus: state => {
//     const conf = getPluginConfiguration<number>('todo')(state);
//     return conf % 2 === 0
//       ? { type: 'valid', message: `(${conf})` }
//       : { type: 'error', message: 'Conf must be even' };
//   },
//   ComponentConfiguration: () => {
//     const msgForPromos = {
//       action: 'SET_PROMOTIONS',
//       promotions: [
//         {
//           type: 'IMAGE',
//           format: 'JPEG',
//           src: 'https://i.imgur.com/0UXQHF3.jpg',
//         },
//         {
//           type: 'IMAGE',
//           format: 'GIF',
//           src: 'https://media0.giphy.com/media/3o7WICIxT3S4Xb9NSw/giphy.gif',
//         },
//         {
//           type: 'VIDEO',
//           format: 'OGG',
//           src:
//             'https://upload.wikimedia.org/wikipedia/commons/e/e6/Typing_example.ogv',
//         },
//         {
//           type: 'IMAGE',
//           format: 'PNG',
//           src: 'https://i.imgur.com/N7Ldpje.png',
//         },
//       ],
//     };
//     const channelName = useSelector(getChannelName);
//     const channel = `customer_display:${channelName}`;
//     const msgStartNewPurchase = {
//       action: 'START_NEW',
//     };
//     const { postMessage } = useCentrifugoClientKrisVersion({ channel });
//
//     const setConfigAction = () => {
//       postMessage(getSetConfigCentrifugoMessage);
//     };
//     const startNewPurchaseAction = () => {
//       postMessage(msgStartNewPurchase);
//     };
//     const sendPromoAction = () => {
//       postMessage(msgForPromos);
//     };
//     return (
//       <div>
//         <button type="button" onClick={() => setConfigAction()}>
//           CONFIG
//         </button>
//         <button type="button" onClick={() => sendPromoAction()}>
//           PROMO
//         </button>
//         <button type="button" onClick={() => startNewPurchaseAction()}>
//           NEW PURCHASE
//         </button>
//       </div>
//     );
//   },
//   UICustomerBadgeCustomRegion: () => {
//     const dispatch = useDispatch();
//
//     const add = action => () =>
//       dispatch(
//         action('This is an alert', { selfDismiss: true, dismissible: true }),
//       );
//     const addLocked = action => () =>
//       dispatch(
//         action('This is an alert', { selfDismiss: false, dismissible: true }),
//       );
//     const cardPayments = useSelector(getCardPayments);
//     const darkTheme = useSelector(getSetting('touchpos_use_dark_theme'));
//     return ReactDOM.createPortal(
//       // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//       // @ts-ignore
//       <div style={{ position: 'fixed', left: 0, bottom: 0, zIndex: 10000 }}>
//         <Button
//           onClick={() =>
//             dispatch(saveTempSetting('touchpos_use_dark_theme', !darkTheme))
//           }
//         >
//           THEME
//         </Button>
//         <Button
//           variant="success"
//           onClick={add(addSuccess)}
//           onContextMenu={addLocked(addSuccess)}
//         >
//           Add
//         </Button>
//         <Button
//           variant="warning"
//           onClick={add(addWarning)}
//           onContextMenu={addLocked(addWarning)}
//         >
//           Add
//         </Button>
//         <Button
//           variant="danger"
//           onClick={add(addError)}
//           onContextMenu={addLocked(addError)}
//         >
//           Add
//         </Button>
//         <hr />
//         {cardPayments.map(pmt => (
//           <div>
//             <Button
//               variant="danger"
//               onClick={() => dispatch(setPayment({ ...pmt, locked: true }))}
//             >
//               {pmt.amount}
//             </Button>
//             <Button
//               variant="success"
//               onClick={() => dispatch(setPayment({ ...pmt, locked: false }))}
//             >
//               {pmt.amount}
//             </Button>
//           </div>
//         ))}
//       </div>,
//       document.body,
//     );
//   },
//   onOpenPaymentModal: {
//     on: (p, ap) => async () => {
//       if (Math.abs(Number(ap.total ?? ap.shoppingCartTotal)) < 10) {
//         throw new Error('Refusing sale with total < 10');
//       }
//       return {
//         ...ap,
//         total: 100000 * Math.random() * ap.total,
//       };
//     },
//   },
//   UICustomerForm: ({ children }) => {
//     return (
//       <>
//         {React.Children.toArray(children).map((c: any) => (
//           <div key={c.key}>
//             {c.props.name}
//             {c}
//           </div>
//         ))}
//       </>
//     );
//   },
// };

export default todoPlugin;
