import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Modal } from 'react-bootstrap';

import UIButton from 'components/UIElements/UIButton';
import NumberPad from 'components/Keyboards/NumberPad';
import InputField from 'containers/Forms/OpenCloseDay/components/OCDInput';
import { float, positive, toFixed } from 'components/FieldTypes/formatters';
import QuestionMark from 'components/QuestionMark';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import {
  getEnabledCurrencies,
  getIsModuleEnabled,
} from 'reducers/configs/settings';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getUserLoggedIn } from 'reducers/Login';
import { getEmployeeById } from 'reducers/cachedItems/employees';

// TODO: add props to type Props
type Props = any;

const MyComponent: FC<Props> = ({
  children,
  bills,
  onChangeBill,
  counted,
  countedComputed,
  updateCounted,
  register,
  registerComputed,
  updateRegister,
  deposit,
  depositComputed,
  updateDeposit,
  expectedAmount,
  onClickTenders,
  onClickSubmit,
  selectedCurrency,
  setSelectedCurrency,
  drawerCountedDay,
  currencySymbol,
  showDeposit,
  showExpected,
  pluginSubmit,
}) => {
  const [first, ..._] = React.Children.toArray(children);

  const { t: rootT } = useTranslation('openCloseDay');
  const t = (text, options) => String(rootT(`closeDay.main.${text}`, options))
  const { name: posName } = useSelector(getSelectedPos);
  const { employeeID } = useSelector(getUserLoggedIn);
  const { drawerID } = useSelector(getEmployeeById(employeeID)) ?? {};
  const name = drawerCountedDay ? t('name', { name: drawerID }) : posName;
  const dispatch = useDispatch();
  const SelectActiveCurrency = ({ title, selected, setSelected }) => {
    const currencies = useSelector(getEnabledCurrencies);
    const isMultiCurrencyEnabled = useSelector(
      getIsModuleEnabled('pos_multicurrency'),
    );
    return (
      <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
        {title}
        {isMultiCurrencyEnabled &&
          currencies.map(currency => (
            <UIButton
              key={currency}
              variant="POS"
              style={{
                ...(currency !== selected && { background: 'transparent' }),
              }}
              text={currency}
              action={() => {
                setSelected(currency);
              }}
            />
          ))}
      </span>
    );
  };
  return (
    <>
      {/* Had to create the header anew because had to change onSubmit function */}
      <Modal.Header
        style={{ alignItems: 'center', paddingBottom: 8, paddingTop: 2 }}
      >
        <div
          className="title"
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            paddingTop: '1em',
          }}
        >
          <SelectActiveCurrency
            title={t('drawerTitle', '')}
            selected={selectedCurrency}
            setSelected={setSelectedCurrency}
          />

          {name}
        </div>
        <QuestionMark position={{ left: 150, top: 20 }} code={35} color="red" />

        <div style={{ flex: 1 }} />
        <UIButton
          text={t('buttons.tenders', '')}
          variant="POS"
          action={onClickTenders}
        />
        <UIButton
          text={t('buttons.submit', '')}
          variant="POS"
          action={pluginSubmit}
        />
        <CloseButton action={() => dispatch(previousModalPage())} />
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          <Container style={{ flex: 0, flexBasis: 300 }}>
            <NumberPad />
            <InputField
              title={drawerCountedDay ? 'Left in drawer' : 'Register'}
              large
              appendTitle={currencySymbol}
              value={showDeposit ? register : counted}
              placeholder={0}
              // @ts-ignore
              formatter={float.and(positive).and(toFixed(2))}
              onChange={e => {
                // set register and update counted to register + deposit
                updateRegister(e.target.value);
                updateCounted(Number(deposit) + Number(e.target.value));
              }}
            />
            <InputField
              title="Deposit"
              large
              appendTitle={currencySymbol}
              value={deposit}
              placeholder={0}
              // @ts-ignore
              formatter={float.and(positive).and(toFixed(2))}
              onChange={e => {
                // set deposit and update counted to register + deposit
                updateDeposit(e.target.value);
                updateCounted(Number(register) + Number(e.target.value));
              }}
            />
          </Container>
        </div>
      </Modal.Body>
    </>
  );
};

export default MyComponent;
