import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import UIButton from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getCustomPaymentTypes } from 'reducers/PaymentTypes';
import InputField from 'components/FieldTypes/InputField';
import { getSetting } from 'reducers/configs/settings';
import { round } from 'utils';
import { getCheckOnlyCashOnEOD } from 'reducers/OpenCloseDay';

// TODO: add props types
type Props = any;

const PluginTenders: FC<Props> = ({
  expected = {},
  missingReasons,
  onCancel,
  onChange,
  onDone,
  reasons,
  tenders,
}) => {
  const defaultCardTypes = [
    {
      name: 'CARD',
      type: 'CARD-',
    },
  ];

  const expectedTenders = Object.entries(expected).map(([type, expected]) => ({
    type,
    expected,
  }));

  const usePaymentTypes = expected => {
    const others = expected.filter(e => !/^CARD-/.test(e.type));
    const cards = expected.filter(e => /^CARD-/.test(e.type));
    const possibleCustomPaymentTypes = useSelector(getCustomPaymentTypes);
    return [
      {
        ...(others.find(({ type }) => type === 'CASH') || {
          type: 'CASH',
          expected: 0,
        }),
      },
      ...defaultCardTypes.map(pt => ({
        name: pt.name,
        ...(cards.find(({ type }) => type === pt.type) || {
          type: pt.type,
          expected: 0,
        }),
      })),
      // Cards not in defaults list
      ...cards
        .filter(({ type }) => defaultCardTypes.every(pt => pt.type !== type))
        .map(({ type, expected }) => ({
          name: type === 'CARD-' ? 'Card' : type.replace('CARD-', ''),
          type,
          expected,
        })),
      {
        type: 'TRANSFER',
        expected: (others.find(o => o.type === 'TRANSFER') || { expected: 0 })
          .expected,
      },
      {
        type: 'GIFTCARD',
        expected: (others.find(o => o.type === 'GIFTCARD') || { expected: 0 })
          .expected,
      },
      {
        type: 'CHECK',
        expected: (others.find(o => o.type === 'CHECK') || { expected: 0 })
          .expected,
      },
      // All custom payment tenders
      ...possibleCustomPaymentTypes.map(pt => ({
        name: pt.name,
        type: pt.type,
        expected: (others.find(o => o.type === pt.type) || { expected: 0 })
          .expected,
      })),
    ];
  };

  const checkOnlyCash = useSelector(getCheckOnlyCashOnEOD);

  const paymentTypes = usePaymentTypes(expectedTenders).filter(({ type }) =>
    checkOnlyCash ? type === 'CASH' : type,
  );

  const { t: preT } = useTranslation('openCloseDay');
  const t = (text, options) => preT(`closeDay.tenders.${text}`, options);
  const { t: tCommon } = useTranslation('common');

  const isInvalid = ({ type, expected, counted, reason }) => {
    if (type === 'CASH') return false;
    if (counted - Number(round(expected)) !== 0) {
      return reason < 0;
    }
    return false;
  };

  return (
    <Ctx.Provider
      value={{
        values: '',
        onChange: (k, v) => onChange(c => ({ ...c, [k]: v })),
      }}
    >
      <Modal.Header>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('drawerTitle', '')}
        </span>

        <span style={{ flexGrow: 1 }} />
        <UIButton text={tCommon('done')} variant="POS" action={onDone} />
        <CloseButton action={onCancel} />
      </Modal.Header>
      <Modal.Body>
        <Table borderless style={{ tableLayout: 'fixed' }}>
          <thead className="tenders_thead">
            <tr>
              <th>{t('headers.type', '')}</th>
              <th>{t('headers.counted', '')}</th>
              <th>{t('headers.expected', '')}</th>
              <th>{t('headers.difference', '')}</th>
              <th>{t('headers.reason', '')}</th>
            </tr>
          </thead>
          <tbody>
            {paymentTypes
              .map(({ name, type, expected }) => ({
                name,
                type,
                expected,
                counted: '',
                reason: -1,
                ...(tenders[type] || {}),
              }))
              .map(({ name, type, expected, counted, reason }, i) => (
                <tr key={type}>
                  <td>{t(`tenders.${type}`, name)}</td>
                  <td>
                    <InputField
                      className={i === 0 ? 'firstTender' : undefined}
                      name={type}
                      readOnly={type === 'CASH'}
                      isInvalid={isInvalid({
                        type,
                        expected,
                        counted,
                        reason,
                      })}
                      // include formatter
                      value={counted === '0.00' ? '' : counted}
                      placeholder="0.00"
                      onChange={e =>
                        onChange(type, {
                          counted: e.target.value,
                          reason,
                        })
                      }
                    />
                  </td>
                  {type !== 'CASH' && (
                    <>
                      <td style={{ textAlign: 'right' }}>
                        {expected.toFixed(2)}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {((parseFloat(counted) || 0) - expected).toFixed(2)}
                      </td>
                      <td>
                        <InputField
                          type="select"
                          value={reason}
                          isInvalid={missingReasons.includes(type)}
                          onChange={e =>
                            onChange(type, {
                              counted,
                              reason: e.target.value,
                            })
                          }
                          options={[
                            { name: '-', reasonID: -1 },
                            ...reasons,
                          ].map(({ name, reasonID }) => ({
                            name,
                            value: reasonID,
                          }))}
                        />
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Ctx.Provider>
  );
};

export default PluginTenders;
