import { AxiosResponse } from 'axios';

import { PaymentObj } from 'paymentIntegrations/types';

import { getJunctionAPI } from '../requests';
import { JunctionAPIResponse } from '../types';

const makePayment = ({
  payment,
  updateMessage,
}: {
  payment: PaymentObj & {
    uuid: string;
    clientCode: string;
    attributes: { refNo: string };
  };
  updateMessage: (string) => void;
}) => async (dispatch): Promise<AxiosResponse<JunctionAPIResponse>> => {
  updateMessage(`Processing payment of ${payment.amount}`);
  const { refNo } = payment.attributes;
  const api = await dispatch(getJunctionAPI);
  if (Number(payment.amount) < 0)
    return api.refundTransaction(
      payment.amount,
      refNo,
      payment.uuid,
      payment.clientCode,
    );
  if (payment.paid && payment.shouldProcess) {
    return api.voidTransaction(refNo, payment.uuid, payment.clientCode);
  }
  return api.saleTransaction(
    payment.amount,
    refNo,
    payment.uuid,
    payment.clientCode,
  );
};
export default makePayment;
