import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import { Typography, Theme } from '@material-ui/core';

import useProducts from 'utils/hooks/useProducts';
import { getSetting } from 'reducers/configs/settings';
import useProductImages from 'utils/hooks/useProductImages';

import ProductPrice from './ProductPrice';
import { useTranslation } from 'react-i18next';

const Container = styled('span')<Theme, { backgroundUrl?: string }>(
  ({ backgroundUrl }) => {
    const backgroundProps: React.CSSProperties = backgroundUrl
      ? {
          background: `url(${backgroundUrl}) center center / contain no-repeat`,
        }
      : {
          textAlign: 'left',
          textOverflow: 'ellipsis',
          flexBasis: 0,
          overflow: 'hidden',
        };

    return {
      flexGrow: 1,
      ...backgroundProps,
    };
  },
);

const Text = styled(Typography)({
  height: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SkeletonData: React.FC<{
  loading: boolean;
  children: React.ReactElement;
}> = ({ loading, children }) => {
  if (!loading) return children;
  return (
    <Skeleton width="100%" height="100%" variant="rect">
      {children}
    </Skeleton>
  );
};

type Props = {
  product: ReturnType<typeof useProducts>['products'][number];
};

const ProductInfo: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation('gridButtons', { keyPrefix: 'products' });
  const shouldHideImages = useSelector(
    getSetting('touchpos_disable_product_image_display'),
  );
  const { value, loading } = useProductImages(product, {
    enabled: !shouldHideImages,
  });

  const [hideImage, setHideImage] = useState(true);

  const imageUrl = value?.[0]?.xs;

  return (
    <>
      <Container
        data-testid="product-container"
        backgroundUrl={shouldHideImages ? undefined : imageUrl}
      >
        {shouldHideImages ? (
          <Text>{product.name}</Text>
        ) : (
          <SkeletonData loading={loading}>
            {imageUrl ? (
              <img
                alt={imageUrl}
                src={imageUrl}
                width="100%"
                onError={() => {
                  setHideImage(false);
                }}
                style={hideImage ? { display: 'none' } : {}}
                height="100%"
                data-testid={`product-image-${product.code}`}
              />
            ) : (
              <Text>{product.name}</Text>
            )}
          </SkeletonData>
        )}
      </Container>
      {product.productVariations ? t('matrixOptions', {count: product.productVariations.length}) : <ProductPrice product={product} />}

    </>
  );
};

export default ProductInfo;
