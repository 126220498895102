import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

import { JunctionAPIResponse } from '../types';
import { withWaitingForTerminal } from '../../index';

const defaultJunctionEndpoint = `https://localhost:10107/`;

const getJunctionUrl = () =>
  `${getMSEndpointFromLS('junction') || defaultJunctionEndpoint}api/v1/`;

let done = false;
/**
 * Attach this as a 'then' handler to any requests to simulate them failing
 * @deprecated NOT TO BE USED IN PRODUCTION
 */
const simulateError = (res: AxiosResponse<JunctionAPIResponse>) => {
  res.data.records[0].hostCode = '999';
  res.data.records[0].resultCode = '999';
  res.data.records[0].transactionStatus = 'FAILED';
  res.data.records[0].statusMessage = done
    ? '[DEV][107] - Cannot void, referenceNumber not found'
    : '[DEV][96] - System malfunction.';
  done = true;
  return res;
};

// region TEST CONNECTION
const testConnection = () => axios.get(`${getJunctionUrl()}testConnection`);
// endregion

// region GET CONFIG
const getConfiguration = () => axios.get(`${getJunctionUrl()}config`);
// endregion

// region SET CONFIG
const setConfiguration = config => {
  let configJSON = '';
  try {
    configJSON = JSON.stringify(config);
  } catch (err) {
    console.error(err);
  }
  return axios.post(`${getJunctionUrl()}config`, { configJSON });
};
// endregion

// region PAYMENT
const saleTransaction = (
  amount: string,
  referenceNumber: string,
  uuid: string,
  clientCode: string,
): AxiosPromise<JunctionAPIResponse> => {
  return axios.post(`${getJunctionUrl()}payment`, {
    requestID: uuid,
    clientCode,
    transactionType: 'SALE',
    amount,
    referenceNumber,
  });
};
// endregion

// region VOID PAYMENT
const voidTransaction = (
  referenceNumber: string,
  uuid: string,
  clientCode: string,
): AxiosPromise<JunctionAPIResponse> => {
  return axios.post(`${getJunctionUrl()}payment`, {
    requestID: uuid,
    clientCode,
    transactionType: 'VOID',
    referenceNumber,
  });
};
// endregion

// region REFUND PAYMENT
const refundTransaction = (
  amount: string,
  referenceNumber: string,
  uuid: string,
  clientCode: string,
): AxiosPromise<JunctionAPIResponse> => {
  return axios.post(`${getJunctionUrl()}payment`, {
    requestID: uuid,
    clientCode,
    transactionType: 'REFUND',
    amount,
    referenceNumber,
  });
};
// endregion

export const getJunctionAPI = async dispatch => {
  /**
   * Wrap the async api methods so that they will run 'withWaitingForTerminal',
   * causing the loading bar to display ONLY when an actual API request is active
   * but not for the whole process (f.ex. when we are waiting for the cashier to choose a force-outcome)
   */
  const wrap = <Params extends any[], Return>(
    fn: (...params: Params) => Return,
  ) => {
    return (...params: Params) =>
      dispatch(
        withWaitingForTerminal(async () => {
          return fn(...params);
        }),
      );
  };
  return {
    testConnection: wrap(testConnection),
    voidTransaction: wrap(voidTransaction),
    refundTransaction: wrap(refundTransaction),
    saleTransaction: wrap(saleTransaction),
    getConfiguration: wrap(getConfiguration),
    setConfiguration: wrap(setConfiguration),
  };
};
