import i18next from 'i18next';

import { addWarning, dismissType } from 'actions/Error';

import { saveProductGroup } from './productGroupsDB';
import { saveProduct } from './productsDB';
import { closeModalPage } from './ModalPage/closeModalPage';

export function sanitizePrice(raw) {
  let clean = raw
    .replace(/[,]/g, '.')
    .replace(/[^0-9-.]/g, '')
    .replace(/[.](?=.*[.])/g, '');
  if (clean.match(/[-]/g)) clean = `-${clean.replace(/[-]/g, '')}`;
  if (clean.match(/^[.]/g)) clean = clean.replace(/^[.]/g, '0.');
  if (clean.match(/^[-]+[.]/g)) clean = clean.replace(/^[-]+[.]/g, '-0.');
  if (clean.match(/^[0]+[1-9]/g)) clean = clean.replace(/^[0]/g, '');
  return clean;
}

export function saveItem({ params, itemType }) {
  return async dispatch => {
    dispatch(
      addWarning(i18next.t('createNew:warning.saving', { context: itemType }), {
        errorType: 'createnew.saveitem',
        dismissible: false,
        selfDismiss: false,
      }),
    );

    let threwError = false;
    switch (itemType) {
      case 'group':
        threwError = await dispatch(saveProductGroup(params));
        break;
      case 'product':
        threwError = await dispatch(saveProduct(params));
        break;
      default:
        break;
    }
    if (!threwError) dispatch(closeModalPage());

    dispatch(dismissType('createnew.saveitem'));
  };
}
