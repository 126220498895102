import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useErrorState, { translateError } from 'utils/hooks/useErrorState';

import { Ctx } from '../../../../components/CtxInputs';
import RenderFormItem from '../../../../components/FormFieldCtx';
import TestButton from '../testButton';
import * as R from 'ramda';
import pingTerminal from 'paymentIntegrations/swedbank/requests/pingTerminal';

const swedbankFields = {
  deviceIp: {
    id: 'deviceIp',
    type: 'text',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'deviceIp' } },
  },
  devicePort: {
    id: 'devicePort',
    type: 'text',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'devicePort' } },
  },
  printReceipt: {
    id: 'printReceipt',
    type: 'select',
    options: [
      { name: 'Select an option', value: '' },
      { name: 'Merchant and Customer', value: '1' },
      { name: 'Merchant', value: '0' },
      { name: 'None', value: '2' },
    ],
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'printReceipt' } },
  },
  printingDevice: {
    id: 'printingDevice',
    type: 'select',
    options: [
      { name: 'Select an option', value: '' },
      { name: 'Internal terminal printer', value: '1' },
      { name: 'External POS printer', value: '0' },
    ],
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'printingDevice' } },
  },
};

const SwedbankConfiguration = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.swedbank.${text}`, ...rest);

  const superContext = useContext(Ctx);
  const {
    configuration,
    setEnableUpdate,
    initialIntegrationState,
  } = superContext.values;

  const { errors, hasErrors } = useErrorState(configuration, swedbankFields);

  useEffect(() => setEnableUpdate(!hasErrors), [hasErrors]);

  const onChange = (key, value) => {
    superContext.onChange('configuration', { ...configuration, [key]: value });
  };

  const configFieldsSaved = R.equals(configuration, initialIntegrationState);

  return (
    <div>
      <span>{t('title')}</span>
      <Ctx.Provider value={{ values: configuration, onChange }}>
        {Object.values(swedbankFields).map(({ validate, ...rest }) => (
          <RenderFormItem
            key={rest.id}
            title={t(rest.id)}
            name={rest.id}
            value={configuration[rest.id] ?? ''}
            error={translateError(errors[rest.id], t)}
            {...rest}
          />
        ))}
        <TestButton
          disabled={!configFieldsSaved}
          testAction={() =>
            pingTerminal().then(res => res ?? t('testConnection.fail'))
          }
          text={t('testConnection.button')}
          helperText={
            configFieldsSaved
              ? ''
              : t('testConnection.button', { context: 'needsSave' })
          }
        />
      </Ctx.Provider>
    </div>
  );
};

export default SwedbankConfiguration;
