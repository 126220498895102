import i18next from 'i18next';

import { isSoftLoggedOut } from 'reducers/Login';
import { setSettings } from 'actions/configs';
import {
  checkSessionNotExpiring,
  getSessionUser,
} from 'containers/Header/components/UserMenu/SessionExpiration/SessionExpiration';
import {
  getSavedPosTimeoutSettings,
  getSavedSetting,
} from 'reducers/configs/settings';
import { updateSessionKey } from 'utils';
import { UrlControl } from 'services/UrlControl/UrlControl';
import { addWarning } from 'actions/Error';
import { loadPointsOfSale } from 'actions/PointsOfSale/loadPointsOfSale';

import {
  hardLogout,
  attemptCookieLogin,
  getUserRights,
  softLogout,
  setAutoLogin,
} from '../../actions/Login';
import {
  REDUX_CLIENTCODE,
  REDUX_USERNAME,
  REDUX_PIN,
  REDUX_SESSIONKEY,
} from '../../constants/persistence';

export default async store => {
  const get = key => JSON.parse(localStorage.getItem(key));

  // #region logout on inactivity
  const lastTimestamp = get('lastTimestamp');
  const settings = {
    timeout: getSavedSetting('pos_timeout')(store.getState()),
    settings: getSavedPosTimeoutSettings(store.getState()),
  };
  const action =
    settings.settings?.action === 'hardLogout'
      ? hardLogout()
      : softLogout({ resetPOS: false });
  const logoutOnInactivityIsEnabled = Number(settings.timeout) > 0;
  const timeSinceLastTimestamp = Date.now() - lastTimestamp;
  const timeoutMs = Number(settings.timeout) * 1e3;
  if (logoutOnInactivityIsEnabled) {
    if (lastTimestamp && timeSinceLastTimestamp > timeoutMs) {
      // Prevents auto login that immediately auto logs out after Header component is loaded and triggers logout on inactivity
      localStorage.removeItem('lastTimestamp');
      await store.dispatch(action);
      return;
    }
  }
  // #endregion

  if (UrlControl.resumeSession) {
    const { clientCode } = UrlControl.resumeSession;
    UrlControl.log.extend('resumeSession')(
      'Attempting cookie login with',
      clientCode,
    );
    await store.dispatch(attemptCookieLogin(clientCode));
    return;
  }

  const isLoggedOut = isSoftLoggedOut(store.getState());
  if (isLoggedOut) {
    store.dispatch(hardLogout());
    return;
  }

  const clientCode = get(REDUX_CLIENTCODE);
  const sessionKey = get(REDUX_SESSIONKEY);
  const username = get(REDUX_USERNAME);
  const pin = get(REDUX_PIN);
  // const pos = get(REDUX_POSID);
  if (sessionKey) {
    // First check if session is far from expiry
    if (await store.dispatch(checkSessionNotExpiring(sessionKey))) {
      // It is, now check if there's a user tied to this session
      const sessionUser = await getSessionUser(sessionKey);
      // If there is a valid user, call the autoLogin. Otherwise fallthru to error
      if (sessionUser) {
        await updateSessionKey(clientCode, sessionKey);
        await store.dispatch([
          setSettings(),
          getUserRights(),
          setAutoLogin({
            user: sessionUser,
            clientCode,
            username,
            pin,
          }),
          loadPointsOfSale(),
        ]);
        return;
      }
    }
    // Logout clears alerts, thus alert is dispatched after the action
    await store.dispatch(hardLogout());
    store.dispatch(
      addWarning(
        i18next.t('login:errors.verification', {
          context: 'sessionAboutToExpire',
        }),
      ),
    );
  }
};
