import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v1 } from 'uuid';
import { Table } from 'react-bootstrap';

import {
  getCurrencyFormatter,
  getProductCodeToShowOnReturnModal,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { SaleDocumentRow } from 'types/SalesDocument';

type Props = {
  saleRows: SaleDocumentRow[];
  saleNumber?: string;
};

const SaleInfoRows: React.FC<Props> = ({ saleRows, saleNumber }) => {
  const isWithTax = useSelector(getShowPricesWithTax);
  const formatCurrency = useSelector(getCurrencyFormatter);
  const codeToShow = useSelector(getProductCodeToShowOnReturnModal);

  const getItemPrice = useCallback(
    (item: SaleDocumentRow) =>
      formatCurrency(!isWithTax ? item.rowNetTotal : item.rowTotal),
    [formatCurrency, isWithTax],
  );

  const dispatch = useDispatch();
  const { t } = useTranslation('customer');
  return (
    <Table borderless size="sm">
      <thead style={{ padding: 0 }}>
        <tr>
          <th style={{ width: '250px', paddingLeft: '1em' }}>
            <span>{t('customerView.purchases.headers.name')}</span>
          </th>
          <th style={{ width: '150px' }}>
            <span>{t(`customerView.purchases.headers.${codeToShow}`)}</span>
          </th>
          <th style={{ width: '50px', textAlign: 'center' }}>
            <span>{t('customerView.purchases.headers.amount')}</span>
          </th>
          <th style={{ textAlign: 'right', paddingRight: '1em' }}>
            <span>{t('customerView.purchases.headers.total')}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {saleRows.map(item => (
          <tr
            data-testid="recent-sale-row"
            data-test-key={`recent-sale-row-${saleNumber}`}
            key={v1()}
            onClick={() => {
              dispatch(
                addProduct({
                  productID: item.productID,
                  needsWeightPopup: false,
                }),
              );
            }}
          >
            <td style={{ width: '250px', paddingLeft: '1em' }}>
              <span
                data-testid="item-name"
                data-test-key={`item-name-${saleNumber}`}
              >
                {item.itemName}
              </span>
            </td>
            <td style={{ width: '150px' }}>
              <span
                data-testid="item-code"
                data-test-key={`item-code-${saleNumber}`}
              >
                {item[codeToShow]}
              </span>
            </td>
            <td style={{ width: '50px', textAlign: 'center' }}>
              <span
                data-testid="item-amount"
                data-test-key={`item-amount-${saleNumber}`}
              >
                {item.amount}
              </span>
            </td>
            <td style={{ textAlign: 'right', paddingRight: '1em' }}>
              <span
                data-testid="item-price"
                data-test-key={`item-price-${saleNumber}`}
              >
                {getItemPrice(item)}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SaleInfoRows;
