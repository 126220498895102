import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';
import { getProductsUniversal } from 'actions/productsDB';

import { Configuration } from '../types';
import { pluginID } from '../constants';
import {
  addViiGiftCardProduct,
  getViiGiftCardProduct,
  removeViiGiftCardProduct,
} from '../redux';

const ComponentHeader: PosPlugin['ComponentHeader'] = ({ children }) => {
  const config = useSelector(getPluginConfiguration<Configuration>(pluginID));
  const viiProduct = useSelector(getViiGiftCardProduct);
  const dispatch: ThunkDispatch<any, any, Action> = useDispatch();

  const configuredCode = config?.giftCardCode;
  const cachedCode = viiProduct?.code;

  useEffect(() => {
    if (configuredCode && cachedCode !== configuredCode) {
      dispatch(
        getProductsUniversal(
          { code: configuredCode },
          { addToCachedItems: true },
        ),
      ).then(({ products }) => {
        if (products.length) {
          const { name, code, productID } = products[0];
          dispatch(addViiGiftCardProduct({ code, name, productID }));
        } else {
          dispatch(removeViiGiftCardProduct());
        }
      });
    }
  }, [configuredCode, dispatch, cachedCode]);

  return children;
};

export default ComponentHeader;
