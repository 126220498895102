import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, ButtonProps } from '@material-ui/core';

import { setPaymentEditValue, updateCurrency } from 'actions/Payments';
import { getAllCurrencies } from 'reducers/configs/currency';
import {
  getAdditionalCurrencies,
  getIsModuleEnabled,
} from 'reducers/configs/settings';
import { getBalance, getPaymentsCurrency } from 'reducers/Payments';
import { Currency } from 'types/Currencies';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';

const AdditionalCurrency: FC<ButtonProps & {
  currency: Currency;
  selected: boolean;
}> = ({ currency, selected, ...props }) => {
  const dispatch = useDispatch();
  const currencies = useSelector(getAllCurrencies);

  const action = async () => {
    const newRate =
      currencies.find(cur => String(cur.code) === String(currency.code))
        ?.rate || 1;
    await dispatch(updateCurrency(currency));
    await dispatch(setPaymentSelected(`${currency.code}-cash`));
    await dispatch(setPaymentEditValue(''));
    await dispatch(
      setPayment({
        amount: '0.00',
        giftCardID: undefined,
        key: `${currency.code}-cash`,
        caption: 'CASH',
        type: 'CASH',
        currencyCode: currency.code,
        currencyRate: Number(newRate),
      }),
    );
  };
  return (
    <Button
      onClick={action}
      data-testid="additional-currency"
      data-test-key={currency.code}
      {...props}
      variant={selected ? 'contained' : 'outlined'}
      color={selected ? 'secondary' : undefined}
    >
      {currency.code.toUpperCase()}
    </Button>
  );
};

const Currencies: React.FC = () => {
  const { code } = useSelector(getPaymentsCurrency);
  const currencies = useSelector(getAllCurrencies);
  const additionalCurrencies = useSelector(getAdditionalCurrencies);
  const balance = useSelector(getBalance);
  const isMultiCurrencyEnabled = useSelector(
    getIsModuleEnabled('pos_multicurrency'),
  );

  if (!isMultiCurrencyEnabled) return null;

  const filteredCurrencies = currencies.filter(currency =>
    additionalCurrencies.includes(currency.code),
  );
  return (
    <ButtonGroup style={{ marginLeft: '1rem' }} disableElevation>
      {filteredCurrencies.map(currency => (
        <AdditionalCurrency
          key={currency.code}
          selected={code === currency.code}
          currency={currency}
          disabled={balance === 0}
        />
      ))}
    </ButtonGroup>
  );
};

export default Currencies;
