import { Table, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'components/Icon';
import UIButton from 'components/UIElements/UIButton';
import { getProductsUniversal } from 'actions/productsDB';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import InputField from 'components/FieldTypes/InputField';

export const DonationProductSearch = ({ handleDonationProductSelection }) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [searchText, setSearchText] = useState('');
  const warehouseID = useSelector(getSelectedWarehouseID);
  const searchProducts = async () => {
    const { products: matchingProducts } = (await dispatch(
      getProductsUniversal({
        orderBy: 'price',
        recordsOnPage: 100,
        searchNameIncrementally: searchText,
        orderByDir: 'asc',
        getProductsFor: 'SALES',
        warehouseID,
      }),
    )) as any;
    setProducts(matchingProducts);
  };
  const { t } = useTranslation('customerDisplay');

  const productIsDiscountable = p => {
    return p.nonDiscountable === 0;
  };
  const productIsZeroPriced = p => {
    return Number(p.price) === 0;
  };
  const productIsRefundable = p => {
    return p.nonRefundable === 0;
  };
  const showSelectButton = p => {
    return (
      !productIsDiscountable(p) &&
      productIsZeroPriced(p) &&
      !productIsRefundable(p)
    );
  };

  const handleChange = e => {
    setSearchText(e.target.value);
  };
  return (
    <>
      <Form
        onSubmit={async e => {
          e.preventDefault();
          await searchProducts();
        }}
        style={{ marginTop: '15px' }}
      >
        {/* TODO: Add a label instead of this */}
        {t('titles.searchDonationProductLabel')}
        <br />
        {t('titles.searchDonationProductLabel2')}
        <Form.Group>
          <InputField
            className="flex-fill"
            onChange={handleChange}
            value={searchText}
            data-testid="search-text"
          />
        </Form.Group>
        <UIButton
          variant="POS"
          type="submit"
          text={t('buttons.search')}
          action={searchProducts}
          data-testid="search-btn"
        />
      </Form>
      <Table size="sm" style={{ color: 'black' }}>
        <thead style={{ background: '#f5f5f5', padding: 0 }}>
          <tr>
            <th style={{ width: '250px', paddingLeft: '1em' }}>
              <span>{t('productsTable.headers.name')}</span>
            </th>
            <th style={{ width: '200px' }}>
              <span>{t('productsTable.headers.code')}</span>
            </th>
            <th style={{ width: '200px' }}>
              <span>{t('productsTable.headers.notDiscountable')}</span>
            </th>
            <th style={{ width: '200px' }}>
              <span>{t('productsTable.headers.zeroPriced')}</span>
            </th>
            <th style={{ width: '200px' }}>
              <span>{t('productsTable.headers.notRefundable')}</span>
            </th>
            <th style={{ width: '200px' }}>
              <span>{t('productsTable.headers.setAsDonation')}</span>
            </th>
          </tr>
        </thead>
        <tbody style={{ background: '#f5f5f5', padding: 0 }}>
          {products.map(p => (
            <tr
              key={p.productID}
              data-testid="product"
              data-test-key={p.productID}
            >
              <td>
                <span>{p.name}</span>
              </td>
              <td>
                <span>{p.code}</span>
              </td>
              <td>
                <Icon
                  name={productIsDiscountable(p) ? 'icon_close' : 'icon_check'}
                  style={{
                    color: productIsDiscountable(p) ? 'red' : 'green',
                  }}
                />
              </td>
              <td>
                <Icon
                  name={!productIsZeroPriced(p) ? 'icon_close' : 'icon_check'}
                  style={{
                    color: !productIsZeroPriced(p) ? 'red' : 'green',
                  }}
                />
              </td>
              <td>
                <Icon
                  name={productIsRefundable(p) ? 'icon_close' : 'icon_check'}
                  style={{
                    color: productIsRefundable(p) ? 'red' : 'green',
                  }}
                />
              </td>
              <td>
                <UIButton
                  disabled={!showSelectButton(p)}
                  action={() => handleDonationProductSelection(p.productID)}
                  text={t('productsTable.actions.set')}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
