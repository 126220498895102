export const FETCH_CAFA_CONFIGS = {
  START: 'hardware:FETCH_CAFA_CONFIGS_START',
  SUCCESS: 'hardware:FETCH_CAFA_CONFIGS_SUCCESS',
  ERROR: 'hardware:FETCH_CAFA_CONFIGS_ERROR',
};

export const SAVE_CAFA_CONFIGS = {
  START: 'hardware:SAVE_CAFA_CONFIGS_START',
  SUCCESS: 'hardware:SAVE_CAFA_CONFIGS_SUCCESS',
  ERROR: 'hardware:SAVE_CAFA_CONFIGS_ERROR',
};

export const UPDATE_CAFA_CONFIGS = {
  START: 'hardware:UPDATE_CAFA_CONFIGS_START',
  SUCCESS: 'hardware:UPDATE_CAFA_CONFIGS_SUCCESS',
  ERROR: 'hardware:UPDATE_CAFA_CONFIGS_ERROR',
};

export const DELETE_CAFA_CONFIG = {
  START: 'hardware:DELETE_CAFA_CONFIGS_START',
  SUCCESS: 'hardware:DELETE_CAFA_CONFIGS_SUCCESS',
  ERROR: 'hardware:DELETE_CAFA_CONFIGS_ERROR',
};

export const FETCH_CURRENT_INTEGRATIONS = {
  START: 'hardware:FETCH_CURRENT_INTEGRATIONS_START',
  SUCCESS: 'hardware:FETCH_CURRENT_INTEGRATIONS_SUCCESS',
  ERROR: 'hardware:FETCH_CURRENT_INTEGRATIONS_ERROR',
};
export const FETCH_ACTIVE_INTEGRATIONS = {
  START: 'hardware:FETCH_ACTIVE_INTEGRATIONS_START',
  SUCCESS: 'hardware:FETCH_ACTIVE_INTEGRATIONS_SUCCESS',
  ERROR: 'hardware:FETCH_ACTIVE_INTEGRATIONS_ERROR',
};

export const SAVE_ACTIVE_INTEGRATIONS = {
  START: 'hardware:SAVE_ACTIVE_INTEGRATIONS_START',
  SUCCESS: 'hardware:SAVE_ACTIVE_INTEGRATIONS_SUCCESS',
  ERROR: 'hardware:SAVE_ACTIVE_INTEGRATIONS_ERROR',
};

export const UPDATE_ACTIVE_INTEGRATIONS = {
  START: 'hardware:UPDATE_ACTIVE_INTEGRATIONS_START',
  SUCCESS: 'hardware:UPDATE_ACTIVE_INTEGRATIONS_SUCCESS',
  ERROR: 'hardware:UPDATE_ACTIVE_INTEGRATIONS_ERROR',
};

export const FETCH_ADYEN_SPLITS_BY_ID = {
  START: 'FETCH_ADYEN_SPLITS_BY_ID_START',
  SUCCESS: 'FETCH_ADYEN_SPLITS_BY_ID_SUCCESS',
  ERROR: 'FETCH_ADYEN_SPLITS_BY_ID_ERROR',
};

/**
 * Enum Configurations Operating level
 * @enum {string} [company]
 */
export const CAFA_LEVELS = {
  User: 'User',
  Pos: 'Pos',
  Warehouse: 'Warehouse',
  Company: 'Company',
} as const;

/**
 * Enum Hardware INTEGRATION_TYPES
 * @enum {string}
 */
export const INTEGRATION_TYPES = {
  payment: 'payment',
  scale: 'scale',
  printer: 'printer',
  customerDisplay: 'customerDisplay',
  cashDrawer: 'cashDrawer',
  integrations: 'integrations',
  brazilPlugin: 'brazilPlugin',
  patchScriptTemplate: 'patchScriptTemplate',
  scanner: 'scanner',
  posConfigurations: 'posConfigurations',
  global: 'global',
} as const;
export const INTEGRATIONS = {
  [INTEGRATION_TYPES.global]: {
    posBrazilVersion: 'posBrazilVersion',
  },
  [INTEGRATION_TYPES.payment]: {
    none: 'none',
    adyen: 'adyen',
    cayan: 'cayan',
    chase: 'chase',
    tripos: 'triPOS',
    verifone: 'verifone',
    swedbank: 'swedbank',
    netsCloud: 'netsCloud',
    wrapper: 'wrapper',
    external: 'external',
    stripe: 'stripe',
    pceftpos: 'pcEftpos',
    tsysCanada: 'tsysCanada',
    moneris: 'moneris',
  },
  [INTEGRATION_TYPES.integrations]: {
    active: 'active-integrations',
  },
  [INTEGRATION_TYPES.posConfigurations]: {
    customer: 'customer',
    language: 'language',
    gridButton: 'gridButton',
    allowedTenders: 'allowedTenders',
  },
  [INTEGRATION_TYPES.patchScriptTemplate]: {
    salesReceipt: 'salesReceipt',
    giftReceipt: 'giftReceipt',
    zReport: 'zReport',
    coupon: 'coupon',
    kitchen: 'kitchen',
    bar: 'bar',
    giftCard: 'giftCard',
  },

  [INTEGRATION_TYPES.scale]: {},
  [INTEGRATION_TYPES.printer]: {
    goMS: 'goMS',
  },
  [INTEGRATION_TYPES.customerDisplay]: {},
  [INTEGRATION_TYPES.cashDrawer]: {},
  [INTEGRATION_TYPES.brazilPlugin]: {},
} as const;

type CAFA_LEVEL = 'User' | 'Pos' | 'Warehouse' | 'Company';

export type CAFA_ENTRY<
  T extends string | undefined = string | undefined,
  N extends string = string,
  V = any
> = {
  id?: string;
  level: CAFA_LEVEL;
  level_id: string;
  type?: T;
  name: N;
  value: V;
  changed: number;
  changedby_id: number;
  added: number;
  addedby_id: number;
};
