import React from 'react';
import { Box, IconButton, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Delete } from '@material-ui/icons';

import { OrderNotificationConfig } from './types';

export const OrderNotificationConfigurationInput = ({
  value,
  onChange,
}: {
  value: Partial<OrderNotificationConfig> | undefined;
  onChange: (newValue: Partial<OrderNotificationConfig> | undefined) => void;
}) => {
  return (
    <Box display="flex">
      <IconButton
        style={{ visibility: value === undefined ? 'hidden' : 'visible' }}
        onClick={() => onChange(undefined)}
      >
        <Delete />
      </IconButton>
      <TextField
        label="API KEY"
        value={value?.key ?? ''}
        onChange={e => onChange({ ...value, key: e.target.value })}
        variant="outlined"
      />
      <Autocomplete
        freeSolo
        value={value?.cluster ?? ''}
        onChange={(e, v) => onChange({ ...value, cluster: v ?? undefined })}
        renderInput={props => (
          <TextField
            variant="outlined"
            label="cluster"
            {...props}
            style={{ width: '10ch' }}
          />
        )}
        options={['mt1', 'us2', 'us3', 'eu', 'ap1', 'ap2', 'ap3', 'ap4', 'sa1']}
      />
    </Box>
  );
};
