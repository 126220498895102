import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { getShouldLock } from '../redux/selectors';

const UICustomPayButton: Required<PosPlugin>['UICustomPayButton'] = ({
  children,
}) => {
  const shouldLock = useSelector(getShouldLock);

  if (!shouldLock) return children;
  return R.assocPath(['props', 'disabled'], true, children);
};

export default UICustomPayButton;
