import { SALTS } from './constants';
import { Params } from './types';

function hexString(buffer: ArrayBuffer) {
  const byteArray = new Uint8Array(buffer);
  const hexCodes = [...byteArray]
    .map(value => value.toString(16).padStart(2, '0'))
    .join('');

  return hexCodes;
}

async function sha256(message: string) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer);

  return hexString(hashBuffer);
}

export async function generateToken(
  customerId: string,
  environment: keyof typeof SALTS,
) {
  const SALT = SALTS[environment];
  const input = `${SALT}|${customerId}|${SALT}`;

  return sha256(input);
}

const baseURL = 'https://dev-poscrm.mmms.ca/';

export function getURL(params: Params) {
  const url = new URL('EmailAcquisition', baseURL);

  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });

  return url;
}
