import { PosPlugin } from 'plugins/plugin';

import { UICloseDayMain, PluginTenders } from './components';

const closeDay: PosPlugin = {
  id: 'custom-close-day',
  name: 'Custom Close day',
  UICloseDayMain,
  PluginTenders,
};

export default closeDay;
