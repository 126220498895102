import dayjs from 'dayjs';

import { PosPlugin } from 'plugins/plugin';

import * as API from './API/givexAPI';
import { GivexCard } from './types';
import { getGivexConfiguration } from './configuration/Configuration';

type OnOpenPaymentModalAp = Parameters<
  Required<Required<PosPlugin>['onOpenPaymentModal']>['on']
>[1];

export function addCardNumberToProductName(
  productName: string,
  cardNumber?: string,
) {
  if (!cardNumber) return productName;
  return `${productName} (*****${cardNumber.slice(-5, -1)}*)`;
}

export function generateGivexIncrementProductNames(
  products,
  payments: Record<
    string,
    {
      paymentIntegration: string;
      cardType: string;
      amount: number;
      produuid?: string;
      GIVEX?: {
        card: GivexCard;
      };
    }
  >,
):
  | {
      [key: string]: string;
    }
  | {} {
  const names = {};
  Object.values(payments).forEach(p => {
    if (p.cardType === 'GIVEX' && p.amount < 0) {
      const prodIdx = products.findIndex(
        prod => prod.uuid && prod.uuid === p.produuid,
      );
      if (prodIdx >= 0) {
        // Need to increment by 1 since order starts from 1 when saving to BO
        names[`itemName${prodIdx + 1}`] = addCardNumberToProductName(
          products[prodIdx].name,
          p.GIVEX?.card.cardNo,
        );
      }
    }
  });
  return names;
}

export async function checkCardCanBeRefilled(card: GivexCard) {
  try {
    const response = await API.getBalance(card);

    if (!response.certificateExpirationDate) return true;
    const expirationDate = dayjs(
      response.certificateExpirationDate,
      'YYYY-MM-DD',
    );
    const now = dayjs();
    return now.isBefore(expirationDate);
  } catch (error) {
    if (!(error instanceof API.GivexError)) throw error;
    const cardCanBeActivated = Number(error.response?.errorCode) === 40;
    return cardCanBeActivated;
  }
}

export function isGivexIncrementProduct(
  productID: string | number,
  givexConfig: ReturnType<typeof getGivexConfiguration>,
): boolean {
  return givexConfig.giftcardProductIDs.includes(String(productID));
}
