import { PosPlugin } from 'plugins/plugin';

import { pluginID } from '../constants';

import GivexBalance from './GivexBalance';
import GivexTransfer from './GivexTransfer';
import GivexAdjust from './GivexAdjust';
import GivexHistory from './GivexHistory';
import GivexDeactivation from './GivexDeactivation';

export const modals = {
  addGivexPayment: `${pluginID}-ADD-GIVEX-PAYMENT`,
  giftCardBalance: `${pluginID}-GIFTCARD-BALANCE`,
  giftCardTransfer: `${pluginID}-GIFTCARD-TRANSFER`,
  giftCardAdjust: `${pluginID}-GIFTCARD-ADJUST`,
  giftCardHistory: `${pluginID}-GIFTCARD-HISTORY`,
  giftCardRefill: `${pluginID}-GIFTCARD-REFILL`,
  giftCardDeactivation: `${pluginID}-GIFTCARD-DEACTIVATION`,
};

export const modalComponents: PosPlugin['components'] = {
  [modals.giftCardBalance]: GivexBalance,
  [modals.giftCardTransfer]: GivexTransfer,
  [modals.giftCardAdjust]: GivexAdjust,
  [modals.giftCardHistory]: GivexHistory,
  [modals.giftCardDeactivation]: GivexDeactivation,
};
