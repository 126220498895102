import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedPos } from 'reducers/PointsOfSale';
import {
  getClientCode,
  getLoggedInEmployeeID,
  getUserLoggedIn,
} from 'reducers/Login';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';

import { TSPOS } from '.';

const useModel = (name: string, selector) => {
  const pos = useSelector(selector);
  useEffect(() => {
    TSPOS.Model[name] = pos;
    return () => {
      TSPOS.Model[name] = 'Plugin offline...';
    };
  }, [pos]);
};
const useModelAction = (name: string, actionCreator) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (actionCreator) {
      TSPOS.Model[name] = (...params) => dispatch(actionCreator(...params));
      return () => {
        TSPOS.Model[name] = () => {
          throw new Error('Plugin offline...');
        };
      };
    }
  }, []);
};

/*
 * TSPOS.Model.POS.attributes
 * TSPOS.Model.POS.pointOfSaleID
 */
const useModelPos = () => {
  useModel('pointOfSale', getSelectedPos);
};
/*
 * TSPOS.Model.Employee.employeeID
 * TSPOS.Model.Employee.employeeName
 */
const useModelEmployee = () => {
  useModel('employee', state =>
    getEmployeeById(getLoggedInEmployeeID(state))(state),
  );
};
/*
 * TSPOS.Model.Document.data
 */
const useModelShoppingCart = () => {
  useModel('shoppingCart', getProductsInShoppingCart);
};
/*
 * TSPOS.Model.Customer.id
 * TSPOS.Model.Customer.setCustomerInfo
 */
const useModelCustomer = () => {
  useModel('customer', getSelectedCustomer);
  useModelAction('setCustomer', (id: number) => setCustomer({ data: id }));
};

const useUser = () => {
  useModel('user', state => ({
    ...getUserLoggedIn(state),
    clientCode: getClientCode(state),
  }));
};
/*
 * X ErplyAPI.getCustomers
 */

export const hooks = [
  useModelPos,
  useModelEmployee,
  useModelCustomer,
  useModelShoppingCart,
  useUser,
];
