import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getClientCode, getSessionKey } from 'reducers/Login';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { version } from 'external_data';

import { getConfiguration } from './Configuration';

const ax = axios.create({
  baseURL: 'https://localhost.erply.com:10901/api/v1',
});

const getHeaders = createSelector(getClientCode, getSessionKey, (cc, sk) => ({
  clientCode: cc,
  sessionKey: sk,
  'Content-Type': 'application/json',
}));

const getBody = createSelector(
  getSelectedPosID,
  getConfiguration,
  (posId, { frequency }) => ({
    sendingDataPeriodMinutes: frequency,
    posId: String(posId),
    posVersion: `Brazil POS: ${version}`,
  }),
);

const getRequest = createSelector(getHeaders, getBody, (headers, body) =>
  // R.once wraps the function so that even if it is called multiple times (because the component is remounted) then the duplicate invocations will be ignored anyway
  R.once(() => ax.post('/setMonitoringParameters', body, { headers })),
);

export const useMetricsCollector = () => {
  const request = useSelector(getRequest);

  useEffect(() => {
    request();
  }, [request]);
};
