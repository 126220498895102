import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { getActivePricelistIDsForPOS } from 'reducers/cachedItems/priceLists';

export function getActivePriceListIDsAsString(state: RootState) {
  return getActivePricelistIDsForPOS(state).join(',');
}

/**
 * Store region and its price lists can load after products have been fetched.
 * In such case fetched products will not have prices from store region price lists
 * applied to them. To avoid that check if active price lists have change since
 * the hook first ran (active price lists only change after warehouse and
 * store region are fetched) and reapply price lists
 */
export function useReapplyPriceLists() {
  const activePriceLists = useSelector(getActivePriceListIDsAsString);
  const previousActivePriceLists = useRef(activePriceLists);

  const shouldReapplyPriceLists =
    previousActivePriceLists.current !== activePriceLists
      ? !!activePriceLists.length
      : false;

  previousActivePriceLists.current = activePriceLists;

  return shouldReapplyPriceLists;
}
