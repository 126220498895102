import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useList, useSet } from 'react-use';

import { PosPlugin } from 'plugins/plugin';
import Icon from 'components/Icon';
import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { getProductGroupsTreeOrdered } from 'reducers/productGroupsDB';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';

import { Configuration } from './index';

const ImagePreview = ({ image, height = 64, width = 64, ...rest }) => (
  <div
    style={{
      height,
      width,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${image})`,
      border: '1px solid gray',
      margin: 8,
    }}
    {...rest}
  />
);
function convertToPng(image) {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  canvas.getContext('2d')?.drawImage(image, 0, 0);

  // eslint-disable-next-line no-undef
  return canvas.toDataURL('image/png,base64');
}

const ImagePicker = ({
  current,
  newImage,
  setNewImage,
  promoteImage,
  width,
  height,
}) => {
  const [id, _] = useState(Math.random().toString());
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>
        <ImagePreview
          image={`data:image/png;base64,${current}`}
          width={width}
          height={height}
          data-testid="current-image"
        />
        <span>Current image</span>
      </span>
      <span>
        <Icon name="arrow_carrot-2left_alt2" action={promoteImage} />
        <div>Set</div>
      </span>
      <label htmlFor={id}>
        <ImagePreview
          image={`data:image/png;base64,${newImage}`}
          width={width}
          height={height}
          data-testid="new-image"
        />
        <span>Click ↑ to upload</span>
      </label>
      <input
        id={id}
        style={{ display: 'none' }}
        type="file"
        onChange={e => {
          const { files } = e.target;
          if (!files?.length) return;
          const fr = new FileReader();
          fr.onloadend = () => {
            setNewImage('');
            // eslint-disable-next-line no-undef
            const img = new Image();
            img.onload = () => {
              setNewImage(
                convertToPng(img).replace(/data:image\/png;base64,/, ''),
              );
            };
            // @ts-ignore
            img.src = fr.result;
          };
          fr.readAsDataURL(files[0]);
        }}
      />
    </div>
  );
};

const FooterTextsPicker = ({
  current = {},
  newTexts = {},
  setNewTexts,
}: {
  current: any;
  newTexts: any;
  setNewTexts: any;
}) => {
  const productGroups = useSelector(getProductGroupsTreeOrdered).map(
    ({ name, value }) => ({
      name: ` ${newTexts[value] ? '█' : '░'} ${name}`,
      value,
    }),
  );
  const [selected, setSelected] = useState(productGroups[0].value);
  return (
    <>
      <InputField
        type="select"
        data-testid="footer-text-picker"
        value={selected}
        options={productGroups}
        onChange={e => setSelected(e.target.value)}
      />
      <FormControl
        as="textarea"
        value={newTexts[selected] ?? ''}
        data-testid="selected"
        onChange={(e: any) => {
          const { [selected]: _, ...rest } = newTexts;
          setNewTexts(
            e.target.value ? { ...rest, [selected]: e.target.value } : rest,
          );
        }}
      />
    </>
  );
};

export const ComponentConfiguration: PosPlugin<
  Configuration
>['ComponentConfiguration'] = ({
  current = {
    customLogo: '',
    customStampLogo: '',
    footerTexts: {},
    preFooterText: '',
    headerText: '',
    gcTypes: [],
    noPrintProductGroups: [],
  },
  save,
}) => {
  const [image, setImage] = useState(current.customLogo);
  const [stampImage, setStampImage] = useState(current.customStampLogo);
  const [footerTexts, setFooterTexts] = useState(current.footerTexts);
  const [preFooterText, setPreFooterText] = useState(current.preFooterText);
  const [headerText, setHeaderText] = useState(current.headerText);

  const [gcTypes, { toggle: toggleGcType }] = useSet(new Set(current.gcTypes));
  const [noPrintProductGroups, { toggle: toggleNoPrintGroup }] = useSet(
    new Set(current.noPrintProductGroups),
  );
  const paymentTypes = useSelector(getAllPaymentTypes);
  const productGroups = useSelector(getProductGroupsTreeOrdered);

  const areSame = (a: any[], b: Set<any>) =>
    a.length === b.size && a.every(item => b.has(item));

  const isUnmodified =
    image === current.customLogo &&
    stampImage === current.customStampLogo &&
    footerTexts === current.footerTexts &&
    preFooterText === current.preFooterText &&
    headerText === current.headerText &&
    areSame(current.gcTypes, gcTypes) &&
    areSame(current.noPrintProductGroups, noPrintProductGroups);

  return (
    <div>
      <UIButton
        text="save"
        disabled={isUnmodified}
        action={() => {
          save({
            customLogo: image,
            customStampLogo: stampImage,
            footerTexts,
            preFooterText,
            headerText,
            gcTypes: [...gcTypes],
            noPrintProductGroups: [...noPrintProductGroups],
          });
        }}
        data-testid="save-btn"
      />
      <h3>Header logo on receipt</h3>
      <ImagePicker
        width={128}
        height={128}
        promoteImage={() => save({ ...current, customLogo: image })}
        current={current.customLogo}
        newImage={image}
        setNewImage={setImage}
      />
      <h3>Stamp area on receipt</h3>
      <ImagePicker
        width={256}
        height={128}
        promoteImage={() => save({ ...current, customStampLogo: stampImage })}
        current={current.customStampLogo}
        newImage={stampImage}
        setNewImage={setStampImage}
      />
      <h3>Footer text by product groups</h3>
      <FooterTextsPicker
        current={current.footerTexts}
        newTexts={footerTexts}
        setNewTexts={setFooterTexts}
      />
      <h3>Header text</h3>
      <InputField
        as="textarea"
        value={headerText}
        data-testid="header-text"
        onChange={e => setHeaderText(e.target.value)}
      />
      <h3>Pre-footer text</h3>
      <InputField
        as="textarea"
        value={preFooterText}
        data-testid="pre-footer-text"
        onChange={e => setPreFooterText(e.target.value)}
      />
      <h3>Gift card payment types</h3>
      {paymentTypes.map(type => (
        <InputField
          type="checkbox"
          value={gcTypes.has(type.id)}
          onChange={() => toggleGcType(type.id)}
          data-testid="payment-type"
          data-test-key={type.name}
        >
          {type.print_name} ({type.type})
        </InputField>
      ))}
      <h3>Products that do not need an automatic receipt to be printed</h3>
      {productGroups.map(gr => (
        <InputField
          type="checkbox"
          value={noPrintProductGroups.has(Number(gr.value))}
          onChange={() => toggleNoPrintGroup(Number(gr.value))}
          data-testid="product-group"
          data-test-key={gr.name}
        >
          {gr.name}
        </InputField>
      ))}
    </div>
  );
};
