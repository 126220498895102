import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isInt, isEmpty, isFloat } from 'validator';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import InputField from 'components/FieldTypes/InputField';
import { openPaymentModal } from 'actions/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import CloseButton from 'components/CustomButtons/CloseButton';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import './StoreCredit.sass';
import { addWarning, dismissType } from 'actions/Error';
import { getSelectedCustomer } from 'reducers/customerSearch';

import { useTranslation } from 'react-i18next';

import { AddButton } from 'components/UIElements/UIButton';
import { setManualStoreCredit } from 'actions/Payments';
import { round } from 'utils';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

const StoreCredit = ({ customer }) => {
  const { t } = useTranslation('payment');
  const selectedCustomer = useSelector(state => getSelectedCustomer(state));
  const [amount, setAmount] = useState('');
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const goToPayments = storeCreditAmount => {
    dispatch(closeModalPage());
    return new Promise((resolve, reject) => {
      resolve(
        dispatch(
          openPaymentModal({
            props: {
              payments: {
                storeCredit: {
                  amount: round(-storeCreditAmount, 2),
                  key: 'storeCredit',
                  caption: t('tenders.storecredit').toUpperCase(),
                  type: 'STORECREDIT',
                  locked: true,
                },
              },
            },
          }),
        ),
      );
    }).then(() => {
      dispatch(setManualStoreCredit(true));
      dispatch(dismissType('addToStoreCredit'));
    });
  };

  const onSave = storeCreditAmount => {
    setDisabled(true);
    dispatch(addWarning(t('alerts.confirmingPrices')), {
      dismissible: false,
      selfDismiss: false,
      errorType: 'addToStoreCredit',
    });
    if (selectedCustomer.customerID !== customer.customerID) {
      dispatch(setCustomer({ data: customer })).then(() => {
        goToPayments(storeCreditAmount);
      });
    } else goToPayments(storeCreditAmount);
  };

  const handleChange = e => {
    const { value } = e.target;
    if (
      (isInt(value) || isEmpty(value) || isFloat(value)) &&
      Number(value) >= 0
    )
      setAmount(value);
  };

  const roundOnBlur = () => setAmount(round(amount, 2) ?? '');

  const handleSubmit = e => {
    e.preventDefault();
    onSave(amount);
  };

  useEffect(() => {
    if (amount && amount !== '' && Number(amount) !== 0) setDisabled(false);
    else setDisabled(true);
  }, [amount]);

  return (
    <div className="store-credit" data-testid="store-credit">
      <Modal.Header>
        <Modal.Title>
          {t('storeCredit.title', { name: customer.fullName })}
        </Modal.Title>
        <div className="store-credit-action-buttons">
          <AddButton
            action={() => onSave(amount)}
            variant="POS"
            data-testid="add-btn"
            disabled={disabled}
          />
          <CloseButton action={() => dispatch(previousModalPage())} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <InputField
              size="lg"
              title={t('storeCredit.fields.total')}
              data-testid="store-credit"
              value={amount}
              placeholder={t('storeCredit.fields.total', {
                context: 'placeholder',
              })}
              type="text"
              onChange={handleChange}
              onBlur={roundOnBlur}
              className={styles.storeCreditInput}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </div>
  );
};

export default StoreCredit;
