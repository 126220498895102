/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  getCardPayments,
  getCardPaymentsForIntegration,
} from 'reducers/Payments';
import { markForProcessing } from 'actions/Payments';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { getIsAReturn, getReturnVATTotal } from 'reducers/sales';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { getTotalTax, getTotal } from 'reducers/ShoppingCart';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { CardPaymentHooks, PaymentObj } from 'paymentIntegrations/types';
import { getErrorMessage, withWaitingForTerminal } from 'paymentIntegrations';

import { getCafaEntry } from '../../reducers/cafaConfigs';

import {
  IntegrationConfig,
  IntegrationData,
  PaymentProcessResult,
  TenderType,
} from './types';
import tsysCanadaPayment from './requests/payment';
import tsysCanadaReturn from './requests/return';
import tsysCanadaVoid from './requests/void';
import handlePaymentSuccess from './requestHandlers/paymentHandler';
import handleReturnTerminalResponse from './requestHandlers/returnHandler';
import handleVoidTerminalResponse from './requestHandlers/voidHandler';
import { parseJSONPTerminalResponse } from './requestHandlers/utils';

export const title = 'TSYS Canada Terminal Interface';

let cardTypeForReturn: TenderType | '' = '';

const resetCardTypeForReturn = () => {
  cardTypeForReturn = '';
};
/**
 * If a payment has been not been sent for reconciliation then a void must be performed to cancel the payment.
 * @param cardPayments The returns to be proccessed
 * @param hooks Methods to manipulate the CardPaymentUI component
 * @param tsysCanadaData TSYS Canada specific data.
 * @param voidAll Determines wheather confirmed payments should be voided.
 * @param returnType Defines if current transaction is a debit or credit card
 */
const processVoidPayments = (
  cardPayments: PaymentObj[],
  hooks: CardPaymentHooks,
  tsysCanadaData: IntegrationData,
  voidAll = false,
  returnType: TenderType,
) => async (dispatch): Promise<PaymentProcessResult> => {
  const { enableButtons, updateMessage } = hooks;
  let errors;
  const paymentsToVoid = cardPayments.filter(p =>
    voidAll ? p.shouldProcess && p.paid : !p.paid,
  );

  const completedPayments = await paymentsToVoid.reduce(
    (pr, payment, index) =>
      pr.then(async acc => {
        if (!errors) {
          updateMessage(
            `Voiding transaction for ${payment.amount} ${
              tsysCanadaData.currencyCode
            } ...
              Transaction ${index + 1} / ${paymentsToVoid.length}`,
          );
          enableButtons([]);

          return dispatch(
            withWaitingForTerminal(async () => {
              try {
                const res = await tsysCanadaVoid(
                  payment,
                  tsysCanadaData,
                  returnType,
                );
                const successfulVoid = await dispatch(
                  handleVoidTerminalResponse(res, payment, hooks),
                );
                if (successfulVoid?.falsePositive) {
                  if (successfulVoid.RcmResponse?.RESPONSE.RESULTMSG) {
                    updateMessage(
                      successfulVoid.RcmResponse.RESPONSE.RESULTMSG,
                    );
                  }
                  resetCardTypeForReturn();
                  updatefunctionButtons(defaultFunctionButtons);
                  enableButtons(['retry', 'close']);

                  errors = true;
                  return acc;
                }

                if (successfulVoid) {
                  return [...acc, payment.key];
                }

                enableButtons(['retry', 'close']);
                errors = true;
                return acc;
              } catch (err) {
                errors = true;
                updateMessage(
                  getErrorMessage(
                    err,
                    'There was an error while voiding the payment. Please try again.',
                  ),
                );
                enableButtons(['retry', 'close']);
                return acc;
              }
            }),
          );
        }
        return acc;
      }),
    Promise.resolve([] as string[]),
  );
  return { errors, completedPayments };
};

/**
 * Performs returns for TSYS Canada integration, it asks to the user which type of card will be used for the transaction (Credit or debit), based on the selection the correspondant request will be sent.
 * @param cardPayments The returns to be proccessed
 * @param hooks Methods to manipulate the CardPaymentUI component
 * @param tsysCanadaData TSYS Canada specific data.
 * @param returnType Defines if current transaction is a debit or credit card
 */
const processReturns = (
  cardPayments: PaymentObj[],
  hooks: CardPaymentHooks,
  tsysCanadaData: IntegrationData,
  returnType: TenderType,
) => async (dispatch): Promise<PaymentProcessResult> => {
  const { enableButtons, updateMessage } = hooks;
  let errors;
  const completedPayments = await cardPayments
    .filter(p => !p.paid)
    .reduce(
      (pr, payment, index) =>
        pr.then(async acc => {
          if (!errors) {
            updateMessage(
              `Performing refund for ${payment.amount} ${
                tsysCanadaData.currencyCode
              } ...
              Transaction ${index + 1} / ${cardPayments.length}`,
            );
            enableButtons([]);

            return dispatch(
              withWaitingForTerminal(async () => {
                try {
                  const res = await tsysCanadaReturn(
                    payment,
                    tsysCanadaData,
                    returnType,
                  );
                  const successfulRefund = await dispatch(
                    handleReturnTerminalResponse(res, payment, hooks),
                  );
                  if (successfulRefund) {
                    resetCardTypeForReturn();
                    return [...acc, payment.key];
                  }

                  errors = true;

                  if (res) {
                    const parsedResponse = parseJSONPTerminalResponse(res);

                    if (parsedResponse.RcmResponse?.RESPONSE?.RESULTMSG) {
                      resetCardTypeForReturn();
                      updatefunctionButtons(defaultFunctionButtons);
                      enableButtons(['retry', 'close']);
                      updateMessage(
                        parsedResponse.RcmResponse.RESPONSE.RESULTMSG,
                      );
                      return acc;
                    }
                  }

                  resetCardTypeForReturn();
                  updatefunctionButtons(defaultFunctionButtons);
                  updateMessage(
                    'There was an error while performing the payment. Please try again.',
                  );
                  enableButtons(['retry', 'close']);
                  return acc;
                } catch (err) {
                  errors = true;
                  updateMessage(
                    getErrorMessage(
                      err,
                      "There was an error while performing the refund. Please try again.'",
                    ),
                  );
                  enableButtons(['retry', 'close']);
                  return acc;
                }
              }),
            );
          }
          return acc;
        }),
      Promise.resolve([] as string[]),
    );
  return { errors, completedPayments };
};

const processPayments = (
  hooks: CardPaymentHooks,
  tsysCanadaData: IntegrationData,
) => async (dispatch, getState): Promise<PaymentProcessResult> => {
  const state = getState();
  const { enableButtons, updateMessage } = hooks;
  let errors;

  const cardPayments = getCardPaymentsForIntegration('tsysCanada')(state);
  const unpaidCardPayments = cardPayments.filter(p => !p.paid);

  const completedPayments = await unpaidCardPayments.reduce(
    (pr, payment, index) =>
      pr.then(async acc => {
        if (!errors) {
          updateMessage(
            `Performing payment for ${payment.amount} ${
              tsysCanadaData.currencyCode
            } ...
              Transaction ${index + 1} / ${unpaidCardPayments.length}`,
          );

          enableButtons([]);

          return dispatch(
            withWaitingForTerminal(async () => {
              try {
                const res = await tsysCanadaPayment(payment, tsysCanadaData);
                const successfulPayment = await dispatch(
                  handlePaymentSuccess(res, payment, hooks),
                );
                if (successfulPayment) {
                  return [...acc, successfulPayment];
                }
                errors = true;

                if (res) {
                  const parsedResponse = parseJSONPTerminalResponse(res);

                  if (parsedResponse.RcmResponse?.RESPONSE?.RESULTMSG) {
                    updatefunctionButtons(defaultFunctionButtons);
                    enableButtons(['retry', 'close']);
                    updateMessage(
                      parsedResponse.RcmResponse.RESPONSE.RESULTMSG,
                    );
                    return acc;
                  }
                }
                updatefunctionButtons(defaultFunctionButtons);
                enableButtons(['retry', 'close']);
                return acc;
              } catch (err) {
                errors = true;
                updateMessage(
                  getErrorMessage(
                    err,
                    'There was an error while performing the payment. Please try again.',
                  ),
                );
                enableButtons(['retry', 'close']);
                return acc;
              }
            }),
          );
        }
        return acc;
      }),
    Promise.resolve([] as string[]),
  );
  return { errors, completedPayments };
};

export const initPayments = (params: CardPaymentHooks) => async (
  dispatch,
  getState,
) => {
  const {
    enableButtons,
    resolvePayments,
    updateMessage,
    currencyCode,
  } = params;

  const state = getState();
  const total = getTotal(state);
  const isCurrentSaleAReturn = getIsAReturn(state);
  const returnVatTotal = getReturnVATTotal(state);
  const vat = getTotalTax(state);
  const taxesAmount =
    isCurrentSaleAReturn && returnVatTotal !== 0 ? returnVatTotal : vat;
  const { value: tsysCanadaConfig } = getCafaEntry<
    typeof INTEGRATION_TYPES.payment,
    'tsysCanada',
    IntegrationConfig
  >(
    'tsysCanada',
    INTEGRATION_TYPES.payment,
  )(state) ?? { value: {} as IntegrationConfig };
  const cardPayments = getCardPaymentsForIntegration('tsysCanada')(state);

  const tsysCanadaData = { ...tsysCanadaConfig, currencyCode, taxesAmount };

  const shouldVoid = cardPayments.some(
    payment => payment.shouldProcess === true,
  );

  enableButtons([]);

  if (shouldVoid) {
    if (cardTypeForReturn === 'DEBIT' || cardTypeForReturn === 'CREDIT') {
      return dispatch(
        processVoidPayments(
          cardPayments,
          params,
          tsysCanadaData,
          true,
          cardTypeForReturn,
        ),
      )
        .then(data => {
          if (data.errors) {
            enableButtons(['retry', 'close']);
            return false;
          }

          updateMessage('Voiding Successful');
          return setTimeout(() => {
            resolvePayments();
            dispatch(previousModalPage());
          }, 2000);
        })
        .catch(err => {
          updateMessage(
            getErrorMessage(
              err,
              'Error while proccessing TSYS Canada payments',
            ),
          );
          console.error('Error while proccessing TSYS Canada payments', err);
        });
    }

    updateMessage('Select a card type');
    updatefunctionButtons([
      {
        actionOnClick: startDebitReturnProcess,
        name: 'debit',
        text: 'DEBIT',
        variant: 'info',
      },
      {
        actionOnClick: startCreditReturnProcess,
        name: 'credit',
        text: 'CREDIT',
        variant: 'info',
      },
    ]);

    return enableButtons(['credit', 'debit']);
  }

  if (total < 0) {
    if (cardTypeForReturn === 'DEBIT' || cardTypeForReturn === 'CREDIT') {
      return dispatch(
        processReturns(cardPayments, params, tsysCanadaData, cardTypeForReturn),
      )
        .then(data => {
          if (data.errors) {
            enableButtons(['retry', 'close']);
          } else {
            updateMessage('Refund Successful');
            resetCardTypeForReturn();
            setTimeout(() => resolvePayments(), 1000);
          }
        })
        .catch(err => {
          updateMessage(
            getErrorMessage(
              err,
              'Error while proccessing TSYS Canada payments',
            ),
          );
          console.error('Error while proccessing TSYS Canada payments', err);
        });
    }

    updateMessage('Select a card type');
    updatefunctionButtons([
      {
        actionOnClick: startDebitReturnProcess,
        name: 'debit',
        text: 'DEBIT',
        variant: 'info',
      },
      {
        actionOnClick: startCreditReturnProcess,
        name: 'credit',
        text: 'CREDIT',
        variant: 'info',
      },
    ]);

    return enableButtons(['credit', 'debit']);
  }

  return dispatch(processPayments(params, tsysCanadaData))
    .then(data => {
      if (data.errors) {
        enableButtons(['retry', 'close']);
      } else {
        updateMessage('Payment Successful');
        setTimeout(() => resolvePayments(), 1000);
      }
    })
    .catch(err => {
      updateMessage(
        getErrorMessage(err, 'Error while proccessing TSYS Canada payments'),
      );
      console.error('Error while proccessing TSYS Canada payments', err);
    });
};

const closeModal = params => async () => {
  params.rejectPayments();
};

/**
 * Void current successful payments in case the user wants to abort the current purchase
 */
export const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('tsysCanada')(
    getState(),
  ).filter(p => p.paid);

  cardPayments.forEach(p => dispatch(markForProcessing({ key: p.key })));
  if (cardPayments.length) {
    await dispatch(openTerminalIntegration());
  }
  return true;
};

export const functions = [
  {
    actionOnClick: initPayments,
    name: 'retry',
    text: 'Retry Payment',
    variant: 'warning',
  },
  {
    actionOnClick: closeModal,
    name: 'close',
    text: 'Close',
    variant: 'danger',
  },
];

const defaultFunctionButtons = [
  {
    actionOnClick: initPayments,
    name: 'retry',
    text: 'Retry Payment',
    variant: 'warning',
  },
  {
    actionOnClick: closeModal,
    name: 'close',
    text: 'Close',
    variant: 'danger',
  },
];

const startCreditReturnProcess = params => dispatch => {
  cardTypeForReturn = 'CREDIT';
  params.enableButtons();
  updatefunctionButtons(defaultFunctionButtons);
  dispatch(initPayments(params));
};

const startDebitReturnProcess = params => dispatch => {
  cardTypeForReturn = 'DEBIT';
  params.enableButtons();
  updatefunctionButtons(defaultFunctionButtons);
  dispatch(initPayments(params));
};

const updatefunctionButtons = newValues =>
  functions.splice(0, Infinity, ...newValues);
