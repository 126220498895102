import React, { useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import Icon from 'components/Icon';
import { getManualPromotions } from 'reducers/CampaignsDB';
import { getAppliedPromotions } from 'reducers/ShoppingCart';
import { applyPromotion, removePromotion } from 'actions/ShoppingCart';
import styles from 'containers/Forms/Campaigns/index.module.scss';
import { getUserRights } from 'reducers/Login';
import { addWarning } from 'actions/Error';
import { modalPages } from 'constants/modalPage';

import ModalPageTemplate from './components/ModalPageTemplate';

const MultiApplyText = ({ onMultiApply }) => (
  <Trans i18nKey="campaigns:promotions.multiApplyText">
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onMultiApply();
      }}
    />
  </Trans>
);

export default () => {
  // prettier-ignore
  const { t } = useTranslation('campaigns');
  const campaigns = useSelector(getManualPromotions);
  const appliedPromotions = useSelector(getAppliedPromotions);

  const dispatch = useDispatch();
  const unapply = promotion => () =>
    dispatch(removePromotion(promotion.campaignID));
  const apply = promotion =>
    appliedPromotions.find(prom => prom.promotionID === promotion.campaignID)
      ? () => {}
      : () => dispatch(applyPromotion({ ...promotion, amount: 1 }));
  const applyMulti = promotion => () =>
    dispatch(
      openModalPage({
        component: modalPages.PromotionAmountPrompt,
        isPopup: true,
        props: { promotion },
      }),
    );
  const close = () => dispatch(previousModalPage());

  if (campaigns.length === 0) {
    return (
      <ModalPageTemplate title={t('promotions.title')} onClose={close}>
        <p>{t('promotions.noneActive')}</p>
      </ModalPageTemplate>
    );
  }
  return (
    <div data-testid="promotions-modal">
      <ModalPageTemplate title={t('promotions.title')} onClose={close}>
        <ListGroup data-testid="promotions">
          {campaigns.map(promotion => {
            const {
              campaignID,
              name,
              canBeAppliedManuallyMultipleTimes: multi,
            } = promotion;

            const appliedPromotion = appliedPromotions.find(
              pr => pr.campaignID === campaignID,
            );
            const amountApplied = appliedPromotion ? appliedPromotion.count : 0;
            return (
              <ListGroup.Item
                action
                key={campaignID}
                className={`${styles.PromotionItem} dark_list_item`}
                onClick={
                  appliedPromotion ? unapply(promotion) : apply(promotion)
                }
                data-testid="promotion"
                data-test-key={promotion.campaignID}
              >
                <div className={styles.details}>
                  <h2>{name}</h2>
                  {!multi ? null : (
                    <MultiApplyText
                      campaignID={campaignID}
                      onMultiApply={applyMulti(promotion)}
                    />
                  )}
                </div>

                {appliedPromotion && (
                  <div className={styles.buttons}>
                    <Badge
                      variant={amountApplied ? 'success' : 'danger'}
                      className={styles.badge}
                    >
                      {t('promotions.badge', {
                        context: amountApplied ? 'applied' : 'notApplied',
                        amount: amountApplied,
                      })}
                    </Badge>
                    <Icon
                      style={{ fontWeight: 'bold' }}
                      name="icon_check"
                      className={styles.check}
                    />
                  </div>
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </ModalPageTemplate>
    </div>
  );
};
