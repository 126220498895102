import { useEffect, useState } from 'react';

/**
 * Custom hook that checks if a given object passes all the tests from given validation object
 * @param watchedValues - object with values to be validated
 * @param validationRules - validation rules object
 * @returns {{hasErrors: boolean, loading: boolean, errors: {}}}
 */

export default  (watchedValues, validationRules) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    let next = false;
    Promise.all(
      Object.entries(validationRules).map(async ([key, value]) => {
        const error = value?.validate
          ? await value.validate(watchedValues[key])
          : null;
        return [key, error];
      }),
    ).then(errors => {
      setErrors(
        Object.fromEntries(
          errors
            .filter(([key, error]) => error)
            .map(([key, { message, dict }]) => {
              return [
                key,
                {
                  message: `validation.${message}`,
                  dict,
                },
              ];
            }),
        ),
      );
      if (!next) {
        setLoading(false);
      }
    });
    return () => (next = true);
  }, [watchedValues]);
  return { errors, hasErrors: !!Object.keys(errors).length, loading };
};

/**
 * Translate Integration validation error
 * with the following format
 * ```
 * {
 *   message: 'Error message',
 *   dict: {
 *     key: 'value',
 *     key_1: 'value_1'
 *   }
 * }
 *
 * ```
 * @param error
 * @param error.message {string} - error message as existing in the translations
 * @param error.dict {object} - dictionary for i18 translation function
 * @param t {function} - translation function based on the file scope
 * @returns {string}
 */
export const translateError = (error, t) => {
  if (!error) return '';
  // handle error of type string
  if (typeof error === 'string') {
    if (!t) return error;
    return t(error);
  }
  // handle error of object type
  if (!t) return error.message || '';

  if (!error.dict) return t(error.message);
  const dictionary = {};
  Object.entries(error.dict).forEach(([k, v]) => {
    dictionary[k] = typeof v === 'string' ? t(v) : v;
  });
  return error.message ? t(error.message, dictionary) : '';
};
