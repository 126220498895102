import React, { useState } from 'react';
import classNames from 'classnames';
import { Box, InputAdornment, makeStyles } from '@material-ui/core';

import BaseInputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';

export default ({ large = false, ...props }) => {
  const [hasFocus, setFocus] = useState(false);
  return (
    <BaseInputField
      {...props}
      className={classNames({
        [large ? styles.OCDLargeInput : styles.OCDDenominationInput]: true,
        [styles.focused]: hasFocus,
      })}
      size="lg"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    />
  );
};

export const useOCDInputStyles = makeStyles({
  input: {
    '& .MuiInputBase-root': {
      '& input': {
        textAlign: 'right',
        fontSize: '1.25rem',
        padding: '1rem',
      },
    },
  },
  largeInput: {
    '& .MuiInputBase-root': {
      '& input': {
        textAlign: 'right',
        fontSize: '1.25rem',
      },
    },
    marginBottom: '1rem',
  },
  billsContainer: {
    marginBottom: '.5rem',
  },
});

export const largeInputAdornment = (start, end) => ({
  startAdornment: (
    <InputAdornment position="start">
      <Box fontSize="1.25rem" fontWeight={700}>
        {start}
      </Box>
    </InputAdornment>
  ),
  endAdornment: (
    <InputAdornment position="end">
      <Box fontSize="1.25rem">{end}</Box>
    </InputAdornment>
  ),
});
