import { PosPlugin } from 'plugins/plugin';
import {
  getAppliedPromotions,
  getProductDiscounts,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import { addWarning } from 'actions/Error';
import { updateOrderDiscount } from 'actions/ShoppingCart';

const pluginID = 'no-discount-stacking';
export const noDiscountStackingPlugin: PosPlugin = {
  id: pluginID,
  name: 'No discount stacking plugin',
  keywords: ['discounts', 'promotions', 'pricing', 'price', 'stacking'],
  onCalculate: {
    after: (p, ap) => async (dispatch, getState) => {
      Object.values(ap.rows)
        .map(row => row.computed)
        .forEach(row => {
          if (Number(row.manualDiscount) === 0) return;
          const discounts = getProductDiscounts(row.rowNumber)(getState());
          const promos = discounts.filter(dc => dc.campaignId);
          if (promos.length) {
            const { orderIndex } = getProductsInShoppingCart(getState()).find(
              p => p.rowNumber === row.rowNumber,
            );
            dispatch(updateOrderDiscount(0, orderIndex));
            dispatch(
              addWarning(
                `Removing discount from product with applied promotion`,
                { selfDismiss: 3000 },
              ),
            );
          }
        });
    },
  },
};
