import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { getSelectedWarehouse } from 'reducers/warehouses';

// eslint-disable-next-line import/prefer-default-export

type Configuration = {
  currentLocationOnly: boolean;
};

const pnpNoDefaultCustomerPurchaseHistory: PosPlugin<Configuration> = {
  id: 'pnp-no-default-customer-purchase-history',
  name: '[PNP] Hide default customer purchases',
  info: `PNP specific plugin that changes how Recent Sales and Customer Card display recent sales. Customer card will not
  show anything if current customer is default customer, customer card shows only 25 recent sales does not have any filtering options available.
  Recent sales has the filtering disabled and shows sales made only at the current location, during the same day, limiting the amount to 25.
  Searching by number ignores the filters
  `,
  keywords: ['pnp', 'recent sales', 'default customer', 'filter', 'limit'],
  getStatus: () => ({
    type: 'valid',
    message:
      'Plugin enables "get recent sales only from current location" setting!',
  }),
  UICustomerViewPreviousPurchasesTable: ({ children }) => {
    const [newCh, setNewCh] = useState<any>([]);
    useEffect(() => {
      setNewCh(
        React.Children.map(children, (c: any) => ({
          ...c,
          props: {
            ...c.props,
            queryProps: {
              ...c.props.queryProps,
              showFilter: false,
              loadMore: false,
              noDefault: true,
              recordsOnPage: 25,
              warehouseID: undefined,
            },
          },
        })),
      );
    }, [children]);
    return <>{newCh}</>;
  },
  UIRecentSalesPreviousPurchasesTable: ({ children }) => {
    const whID = useSelector(getSelectedWarehouse).warehouseID;
    const [newCh, setNewCh] = useState<any>([]);
    const currentDay = dayjs().format('YYYY-MM-DD');

    useEffect(() => {
      setNewCh(
        React.Children.map(children, (c: any) => {
          const numberless = !c.props.queryProps.number;
          return {
            ...c,
            props: {
              ...c.props,
              showFilter: true,
              loadMore: true,
              noDefault: true,
              queryProps: {
                ...c.props.queryProps,
                showFilter: false,
                loadMore: false,
                dateFrom: numberless ? currentDay : undefined,
                dateTo: numberless ? currentDay : undefined,
                recordsOnPage: 25,
                warehouseID: numberless ? whID : undefined,
                limit: 25,
              },
            },
          };
        }),
      );
    }, [children]);
    return <>{newCh}</>;
  },
  selectorOverrides: {
    getSettings: base =>
      createSelector(base, before => ({
        ...before,
        // eslint-disable-next-line @typescript-eslint/camelcase
        touchpos_recent_sales_only_from_current_location: true,
      })),
  },
};

export default pnpNoDefaultCustomerPurchaseHistory;
