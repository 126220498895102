import * as R from 'ramda';

const round = n => Number(n.toFixed(2));
export const calculateFairSplits = (amount: number, weights: number[]) => {
  let target = weights.reduce(R.add, 0);
  let budget = amount;

  return weights.map(w => {
    const amount = round((w / target) * budget);
    target -= w;
    budget -= amount;
    return amount;
  });
};
