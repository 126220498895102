import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import uuidv4 from 'uuid/v4';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import CloseButton from 'components/CustomButtons/CloseButton';
import { openPluginModalPage } from 'actions/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { setPayment } from 'actions/Payments/setPayment';
import { createConfirmation } from 'actions/Confirmation';
import { getTotal } from 'reducers/Payments';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';
import { addError } from 'actions/Error';

import { RedeemVoucherParams, Voucher } from '../types';
import { setVouchers } from '../../rdx/actions';
import { getTafReduxState } from '../../rdx/reducers';
import { Configuration } from '../../types';
import { pluginID } from '../../constants';
import { checkBalance, redeemVoucher, voidRedemption } from '../API';

import { modals } from '.';

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function VoucherInputModal({ customer, paymentType }) {
  const configs = useSelector(
    getPluginConfigurationAtLevel<Configuration>(pluginID, 'Company', ''),
  );
  const total = useSelector(getTotal);
  const { vouchers } = useSelector(getTafReduxState);
  const [voucherState, setVoucherState] = useState(
    vouchers.length
      ? vouchers
      : [{ id: uuidv4(), code: '', status: 'new' } as Voucher],
  );
  const [error, setError] = useState(0);
  const { companyName, firstName, lastName, fullName, id } = customer || {};

  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();

  useEffect(() => {
    if (voucherState.length === 0) {
      setError(3);
    } else if (voucherState.some(v => v.code.length > 10)) {
      if (voucherState.some(v => !v.code.length)) {
        setError(12);
      } else {
        setError(1);
      }
    } else if (voucherState.some(v => !v.code.length)) {
      setError(2);
    } else {
      setError(0);
    }
  }, [voucherState]);

  const onChange = (index: number, newCode: string) => {
    let code = newCode;
    if (
      code.match(
        /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/,
      )
    ) {
      const parsedCode = window.atob(code);
      if (isJsonString(parsedCode)) {
        const parsedJson = JSON.parse(parsedCode);
        code = parsedJson?.v?.d?.slice(0, 10) || code;
      }
    }
    const newVouchers = [...voucherState];
    newVouchers[index] = { ...newVouchers[index], code };
    setVoucherState(newVouchers);
  };

  const save = async () => {
    // Integrated voucher solution
    if (configs?.UseIntegratedVouchers) {
      const errors: string[] = [];
      const successes: {
        shortCode: string;
        transactionCode: string;
        id: string;
      }[] = [];
      const lastIndex = voucherState.length - 1;
      const lastVoucher = voucherState[lastIndex];
      if (lastVoucher.status !== 'valid') {
        await checkBalance({
          params: { code: lastVoucher.code },
          config: configs,
        }).then(res => {
          if (!res) {
            setVoucherState(vouchers =>
              R.adjust(lastIndex, R.assoc('status', 'error'))(vouchers),
            );
            dispatch(addError('Could not get balance info'));
            throw new Error(`Could not get balance info`);
          }
          if (res.balance >= 50) {
            setVoucherState(vouchers =>
              // Change last voucher to 'Valid'
              R.adjust(lastIndex, R.assoc('status', 'valid'))(vouchers),
            );
          } else {
            setVoucherState(vouchers =>
              R.adjust(lastIndex, R.assoc('status', 'nofunds'))(vouchers),
            );
            dispatch(addError(`Voucher ${lastVoucher.code} lacks funds`));
            throw new Error(`Voucher ${lastVoucher.code} lacks funds`);
          }
        });
      }
      await Promise.all(
        voucherState.map(async v => {
          try {
            if (!configs.businessID) {
              throw new Error('Business ID not configured');
            } else if (!v.code.length) {
              throw new Error('Voucher code missing');
            }
            const data: RedeemVoucherParams = {
              amount: 50,
              providerIdentifier: configs.businessID,
              externalReference: '123456789',
              totalAmount: total,
              voucherCode: v.code,
            };
            const resp = await redeemVoucher({
              params: data,
              config: configs,
            });
            if (!resp) throw new Error('Unknown error');
            if (!('transactionCode' in resp)) {
              if (resp.code === 'access_token_expired') {
                throw new Error('Access Token expired');
              }
              if (typeof resp.message === 'object') {
                throw new Error(resp.message?.message ?? 'Unknown error');
              }
              if (typeof resp.message === 'string') {
                throw new Error(resp.message);
              }
              throw new Error('Unknown error');
            }
          } catch (e) {
            console.error('Error adding voucher', e);
            errors.push(v.code);
          }
        }),
      );
      if (errors.length) {
        dispatch(
          openPluginModalPage(modals.voucherErrorModal)({
            isPopup: true,
            modalClassName: 'nsw-error-modal',
            groupID: 'nsw-modal',
            props: {
              voucherState,
              errors,
              addPayment: () => {
                const vouchersToSet = voucherState.filter(voucher =>
                  successes.some(s => s.shortCode === voucher.code),
                );
                // Save the nsw payment only if there was at least one successful payment
                if (vouchersToSet.length) {
                  dispatch(setVouchers(vouchersToSet));
                  dispatch(
                    setPayment({
                      typeId: paymentType.id,
                      type: paymentType.type,
                      amount: vouchersToSet.length * 50,
                      caption: paymentType.name,
                      currencyRate: 1,
                    }),
                  );
                }
                dispatch(closeModalPage('nsw-modal'));
              },
              voidAll: async () => {
                if (successes.length > 0) {
                  await Promise.all(
                    successes.map(s =>
                      voidRedemption({
                        params: { transactionCode: s.transactionCode },
                        config: configs,
                      }).then(res => {
                        if (res) {
                          if (typeof res === 'string' && res === 'VOID') {
                            setVoucherState(vouchers =>
                              R.reject((v: Voucher) => v.id === s.id)(vouchers),
                            );
                          } else {
                            const errMessage = () => {
                              if (typeof res.message === 'string') {
                                if (res.code === 'access_token_expired') {
                                  return 'Access Token expired';
                                }
                                return res.message;
                              }
                              return res.message.message;
                            };
                            throw new Error(`Voiding failed: ${errMessage()}`);
                          }
                        } else {
                          throw new Error('Could not void');
                        }
                      }),
                    ),
                  );
                } else {
                  setVoucherState([]);
                  dispatch(closeModalPage('nsw-modal'));
                }
              },
            },
          }),
        );
      } else {
        // SUCCESS
        dispatch(setVouchers(voucherState));
        dispatch(
          setPayment({
            typeId: paymentType.id,
            type: paymentType.type,
            amount: voucherState.length * 50,
            caption: paymentType.name,
            currencyRate: 1,
          }),
        );
        dispatch(closeModalPage('nsw-modal'));
      }
    }
    // Non-integrated solution section
    else {
      dispatch(
        createConfirmation(
          async () => {
            dispatch(setVouchers(voucherState));
            await dispatch(
              setPayment({
                key: 'NSW',
                typeId: paymentType.id,
                type: paymentType.type,
                amount: voucherState.length * 50,
                caption: paymentType.name,
                currencyRate: 1,
              }),
            );
            dispatch(setPaymentSelected(''));
            dispatch(previousModalPage());
          },
          null,
          {
            title: 'Confirmation',
            body: `Please make sure you have redeemed the Service NSW vouchers in the app on the iPad before finalising the sale.\n\n`.concat(
              voucherState.map(v => v.code).join('\n'),
            ),
          },
        ),
      );
    }
  };

  const close = () => {
    dispatch(setVouchers(voucherState.filter(v => v.code.length > 0)));
    dispatch(previousModalPage());
  };

  const handleAdd = async () => {
    if (configs?.UseIntegratedVouchers) {
      const lastIndex = voucherState.length - 1;
      const lastVoucher = voucherState[lastIndex];
      // If there's no last voucher - add a new one with status 'new'
      if (!lastVoucher) {
        setVoucherState(vouchers =>
          R.append({ id: uuidv4(), code: '', status: 'new' })(vouchers),
        );
      } else if (
        // Check balance of previous voucher if it is 'new' or any other incorrect status
        ['new', 'error', 'nofunds', undefined].includes(lastVoucher.status)
      ) {
        try {
          await checkBalance({
            params: { code: lastVoucher.code },
            config: configs,
          }).then(res => {
            if (!res) {
              setVoucherState(vouchers =>
                R.adjust(lastIndex, R.assoc('status', 'error'))(vouchers),
              );
              dispatch(addError('Could not get balance info'));
            } else if (res.balance >= 50) {
              setVoucherState(vouchers =>
                R.pipe(
                  // Change last voucher to 'Valid'
                  R.adjust(lastIndex, R.assoc('status', 'valid')),
                  // Add a 'new' voucher to the list
                  R.append({ id: uuidv4(), code: '', status: 'new' }),
                )(vouchers),
              );
            } else {
              setVoucherState(vouchers =>
                R.adjust(lastIndex, R.assoc('status', 'nofunds'))(vouchers),
              );
              dispatch(addError(`Voucher ${lastVoucher.code} lacks funds`));
            }
          });
        } catch (e) {
          const body =
            (e as any)?.message ??
            'Please check provided codes. \nNote: codes are case sensitive';
          console.error('Voucher addition failed with error ', e);
          setVoucherState(vouchers =>
            R.adjust(lastIndex, R.assoc('status', 'error'))(vouchers),
          );
          dispatch(
            createConfirmation(
              () => {
                // do nothing
              },
              null,
              {
                title: 'Voucher addition failed',
                body,
              },
            ),
          );
        }
      } else {
        setVoucherState(vouchers =>
          R.adjust(lastIndex, R.assoc('status', 'valid'))(vouchers),
        );
      }
    } else {
      setVoucherState(vouchers =>
        R.append({ id: uuidv4(), code: '', status: 'new' })(vouchers),
      );
    }
  };

  const handleDelete = (voucher: Voucher) => {
    setVoucherState(vouchers =>
      R.reject((v: Voucher) => v.id === voucher.id)(vouchers),
    );
  };

  const getName = useCallback(() => {
    if (!id) return '';
    if (companyName) return companyName;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return fullName;
  }, [companyName, firstName, fullName, id, lastName]);

  const errorText = useMemo(() => {
    switch (error) {
      case 1:
        return 'Length of Service NSW Vouchers should be up to 10 characters';
      case 2:
        return 'NSW Voucher length should be at least 1 character';
      case 12:
        return 'Length of Service NSW Vouchers should be up to 10 characters\n\nNSW Voucher length should be at least 1 character';
      case 3:
        return 'There should be at least one Voucher to complete the addition';
      default:
        return '';
    }
  }, [error]);

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">Payment - {getName()}</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <CloseButton action={close} />
        </Box>
      </Box>
      <Divider />
      <Box style={{ marginTop: '20px' }}>
        <Typography variant="h5">Service NSW Voucher</Typography>
        {voucherState.map((v, i) => {
          return (
            <TextField
              fullWidth
              variant="outlined"
              key={v.id}
              error={
                v.code.length > 10 ||
                !v.code.length ||
                v.status === 'error' ||
                v.status === 'nofunds'
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => handleDelete(v)}
                  >
                    <DeleteOutlinedIcon
                      style={{ color: 'red', cursor: 'pointer' }}
                    />
                  </InputAdornment>
                ),
              }}
              style={{
                marginBottom: '15px',
              }}
              value={v.code}
              // Disable editing of a previously added voucher
              disabled={v.status === 'valid'}
              onChange={e => onChange(i, e.target.value)}
            />
          );
        })}
        <Button variant="contained" onClick={handleAdd}>
          + Add another voucher
        </Button>
        <Box
          display="flex"
          justifyContent="flex-start"
          flex={1}
          marginTop="40px"
        >
          <Button
            onClick={save}
            variant="contained"
            color="primary"
            style={{
              display: error ? 'none' : 'block',
              marginRight: error ? '0' : '20px',
            }}
          >
            ADD
          </Button>
          <Button onClick={close} variant="contained" color="default">
            CANCEL
          </Button>
        </Box>
        {error ? (
          <Box marginTop="20px">
            <Typography color="error">{errorText}</Typography>
          </Box>
        ) : null}
        <Box marginTop="20px">
          <TextField
            disabled
            fullWidth
            variant="outlined"
            key="vouchers-total"
            value={50 * voucherState.length}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">TOTAL</InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default VoucherInputModal;
