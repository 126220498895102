export const ADD_PRODUCT = 'SHOPPINGCART/ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'SHOPPINGCART/ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'SHOPPINGCART/ADD_PRODUCT_FAILURE';

export const ADD_MULTI_PRODUCTS_START = 'SHOPPINGCART/ADD_MULTI_PRODUCTS_START';
export const ADD_MULTI_PRODUCTS_SUCCESS =
  'SHOPPINGCART/ADD_MULTI_PRODUCTS_SUCCESS';
export const ADD_MULTI_PRODUCTS_FAILURE =
  'SHOPPINGCART/ADD_MULTI_PRODUCTS_FAILURE';

export const REMOVE_PRODUCT = 'SHOPPINGCART/REMOVE_PRODUCT';

export const CALCULATE = 'SHOPPINGCART/CALCULATE';
export const CALCULATE_SUCCESS = 'SHOPPINGCART/CALCULATE_SUCCESS';
export const CALCULATE_FAILURE = 'SHOPPINGCART/CALCULATE_FAILURE';

export const INCREASE_ORDER_AMOUNT = 'SHOPPINGCART/INCREASE_ORDER_AMOUNT';
export const DECREASE_ORDER_AMOUNT = 'SHOPPINGCART/DECREASE_ORDER_AMOUNT';
export const UPDATE_ORDER_AMOUNT = 'SHOPPINGCART/UPDATE_ORDER_AMOUNT';

export const UPDATE_ORDER_DISCOUNT = 'SHOPPINGCART/UPDATE_ORDER_DISCOUNT';
export const UPDATE_PRODUCT_NOTES = 'SHOPPINGCART/UPDATE_PRODUCT_NOTES';
export const UPDATE_ORDER_PRICE = 'SHOPPINGCART/UPDATE_ORDER_PRICE';
export const CLEAR_ORDER_PRICE = 'SHOPPINGCART/CLEAR_ORDER_PRICE';
export const UPDATE_ORDER_NAME = 'SHOPPINGCART/UPDATE_ORDER_NAME';
export const UPDATE_ORDER_OTHER = 'SHOPPINGCART/UPDATE_ORDER_OTHER';
export const SET_EMPLOYEE = 'SHOPPINGCART/SET_EMPLOYEE';

export const UPDATE_NO_PRODUCT_CONTAINER = 'UPDATE_NO_PRODUCT_CONTAINER';

export const SET_DISCOUNT_REASON = 'SHOPPINGCART/SET_DISCOUNT_REASON';
export const CLEAR_DISCOUNT_REASONS = 'SHOPPINGCART/CLEAR_DISCOUNT_REASONS';
export const CLEAR_DISCOUNT_REASON = 'SHOPPINGCART/CLEAR_DISCOUNT_REASON';
export const CLEAR_GLOBAL_DISCOUNT_REASON =
  'SHOPPINGCART/CLEAR_GLOBAL_DISCOUNT_REASON';

export const SET_RETURN_REASON = 'SHOPPINGCART/SET_RETURN_REASON';
export const CLEAR_RETURN_REASONS = 'SHOPPINGCART/CLEAR_RETURN_REASONS';
export const CLEAR_RETURN_REASON = 'SHOPPINGCART/CLEAR_RETURN_REASON';
export const CLEAR_GLOBAL_RETURN_REASON =
  'SHOPPINGCART/CLEAR_GLOBAL_RETURN_REASON';

export const SET_SELECTED_ORDER = 'SHOPPINGCART/SET_SELECTED_ORDER';
export const RESET_SHOPPING_CART = 'SHOPPINGCART/RESET_SHOPPING_CART';

export const APPLY_DISCOUNT = 'SHOPPINGCART/APPLY_DISCOUNT';
export const APPLY_PROMOTION = 'SHOPPINGCART/APPLY_PROMOTION';
export const REMOVE_PROMOTION = 'SHOPPINGCART/REMOVE_PROMOTION';
export const APPLY_COUPONS = 'SHOPPINGCART/APPLY_COUPONS';
export const SET_COUPONS = 'SHOPPINGCART/SET_COUPONS';
export const REMOVE_COUPON = 'SHOPPINGCART/REMOVE_COUPON';
export const RESET_DISCOUNT = 'SHOPPINGCART/RESET_DISCOUNT';

export const ADD_PRODUCT_VARIATION_TO_CART =
  'SHOPPINGCART/ADD_PRODUCT_VARIATION_TO_CART';
export const ADD_PRODUCT_VARIATION_TO_CART_SUCCESS =
  'SHOPPINGCART/ADD_PRODUCT_VARIATION_TO_CART_SUCCESS';

export const SET_VATRATE = 'SHOPPINGCART/SET_VATRATE';

export const IS_NOT_OFFER = 'SHOPPINGCART/IS_NOT_OFFER';
export const SET_SELECTED_OFFERS = 'SHOPPINGCART/SET_SELECTED_OFFERS';

export const SET_CALCULATE_OFFLINE = 'SET_CALCULATE_OFFLINE';
