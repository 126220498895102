import { urlEncode } from 'utils';
import { proxy } from 'services/shared';

/**
 * @typedef TaxDetails
 * @property {string} State "NY"
 * @property {string} County "ORANGE"
 * @property {string} City "BULLVILLE"
 * @property {string} ZIPCode "10915"
 * @property {number} TotalSalesTax 8.125
 * @property {number} CitySalesTax 0
 * @property {number} CountySalesTax 4.125
 * @property {number} StateSalesTax 4
 *
 * @property {string} TaxShippingAlone "Y"
 * @property {string} TaxShippingHandling "Y"
 */

/**
 * @param sessionKey
 * @param clientCode
 * @param clientID
 * @param posID
 * @param toZip
 * @param fromZip
 * @returns {Promise<TaxDetails>}
 */
export const getTaxRate = ({ clientCode, clientID, posID, toZip, fromZip }) =>
  window
    .fetch(`${proxy}https://tax.erply.com/`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: urlEncode({
        key: 'Yj36dh5D8T8YHESsJ3O5fT76hDd6WYd',
        fromMerchant: clientCode,
        toZIP: toZip,
        fromZIP: fromZip,
        clientID,
        fromPOSID: posID,
      }),
    })
    .then(res => res.json());
