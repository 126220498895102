import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { modalPages as mp } from 'constants/modalPage';
import { PosPlugin } from 'plugins/plugin';

import { getViiGiftCardProduct, getViiProducts } from '../redux/actions';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

const UICustomGiftCardSerial: PosPlugin['UICustomGiftCardSerial'] = ({
  onClose,
  setGiftCardSerial,
  productID,
  children,
}) => {
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  const viiGiftCardProducts = useSelector(getViiProducts);
  const productCard = useSelector(getViiGiftCardProduct);

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleSearch = async () => {
    // Do nothing if there's an error
    if (!!helperText()) {
      return;
    }
    // Block duplicate card from being added
    if (
      Object.values(viiGiftCardProducts ?? {}).some(
        vgcp => vgcp.CardNumber === code,
      )
    ) {
      setError('This gift card has already been added to cart!');
      return;
    }
    await dispatch(
      openModalPage({
        groupID: 'ViiGiftCardInputModal',
        component: mp.pluginModal,
        props: {
          name: 'ViiGiftCardAmountInputModal',
          productCard,
          cardNumber: code,
        },
        isPopup: true,
      }),
    );
    onClose();
  };

  useShortcut('Enter', () => {
    handleSearch()
  }, 100);

  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  const helperText = () => {
    if (!code) return 'Code must be entered';
    if (error?.length) return error;
    return undefined;
  };

  if (
    !productCard ||
    Number(productCard.productID ?? -1) !== Number(productID)
  ) {
    return children;
  }

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">
            {`Enter ${productCard.name ?? 'Vii Giftcard'} Number`}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <Button
            disabled={!!helperText()}
            onClick={handleSearch}
            variant="contained"
            color="secondary"
            style={{ alignItems: 'flex-end', position: 'relative' }}
          >
            SAVE
          </Button>
          <CloseButton
            action={() => {
              dispatch(previousModalPage());
            }}
          />
        </Box>
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        inputRef={inputRef}
        autoFocus={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">Code</InputAdornment>
          ),
        }}
        value={code}
        onChange={e => {
          setGiftCardSerial(e.target.value);
          setCode(e.target.value);
          setError('');
        }}
        error={!!helperText()}
        helperText={helperText()}
      />
    </Box>
  );
};
export default UICustomGiftCardSerial;
