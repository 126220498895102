import React, {
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync, useDebounce } from 'react-use';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import * as R from 'ramda';

import { Product } from 'types/Product';
import { addProduct } from 'actions/ShoppingCart/addProduct';
import { getProductsUniversal } from 'actions/productsDB';
import { getBarcodeDataExtractorFn } from 'reducers/cachedItems/products';
import { getSetting, getSortProductsBy } from 'reducers/configs/settings';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { addWarning } from 'actions/Error';
import { isEmpty } from 'utils';
import { RootState } from 'reducers';
import { getActivePriceListIDsAsString } from 'utils/hooks/useReapplyPriceLists';
import { getSelectedCustomerID } from 'reducers/customerSearch';

import SearchComponent from '../components/SearchComponent';
import PopOverContainer from '../components/PopOverContainer';

import ProductResults from './ProductResults';
import BarcodeEmbeddedProductResults from './BarcodeEmbeddedProductResults';

export type EmbeddedProduct = Product & { embeddedPrice?: number };

const sortDir = {
  name: 'asc',
  price: 'asc',
  productID: 'desc',
  added: 'desc',
  changed: 'desc',
};

const useAutoAddProduct = (
  onlyOneProductFound: boolean,
  product: EmbeddedProduct,
  searchChangeAllowed: boolean,
  close: () => void,
) => {
  const dispatch = useDispatch();
  const shouldAddProductOnSingleResult = useSelector(
    getSetting('single_product_result_adds_to_shopping_cart'),
  );

  useEffect(() => {
    if (
      onlyOneProductFound &&
      shouldAddProductOnSingleResult &&
      searchChangeAllowed
    ) {
      dispatch(
        addProduct({
          productID: product.productID,
          amount: product.amount,
          manualDiscount: 0,
          price: product.embeddedPrice || undefined,
          needsWeightPopup: !product.amount,
        }),
      );
      close();
    }
  }, [
    product,
    shouldAddProductOnSingleResult,
    searchChangeAllowed,
    onlyOneProductFound,
    dispatch,
    close,
  ]);
};

const mapProp = prop => {
  switch (prop) {
    case 'price':
      return 'priceListPriceWithVat';
    default:
      return prop;
  }
};
const toLowerIfString = (val: string | number) =>
  typeof val === 'string' ? R.toLower(val) : val;

const sortProducts = ({
  sortProductsBy,
  products,
}: {
  sortProductsBy: string;
  products: EmbeddedProduct[];
}): EmbeddedProduct[] => {
  const comparator =
    {
      asc: R.ascend,
      desc: R.descend,
    }[sortDir[sortProductsBy]] ?? R.ascend;
  return R.sort(
    comparator(R.compose(toLowerIfString, R.prop(mapProp(sortProductsBy)))),
  )(products);
};

const ProductSearch = React.memo(
  React.forwardRef<
    HTMLInputElement | null,
    {
      active: boolean;
      closePopover: () => void;
      focusKey?: string;
    }
  >(({ active, closePopover, focusKey }, ref) => {
    const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
    const [searchVal, setSearchVal] = useState('');
    const [showRegularProducts, setShowRegularProducts] = useState(false);

    const warehouseID = useSelector(getSelectedWarehouseID);
    const extractDataFromCode = useSelector(getBarcodeDataExtractorFn);
    const embeddedBarcodeProduct = useMemo(
      () => extractDataFromCode(searchVal),
      [extractDataFromCode, searchVal],
    );
    const { weight, embeddedPrice, type, prodCode } = embeddedBarcodeProduct;

    const { t } = useTranslation('search');

    const getItemsFromFirstPriceListOnly = useSelector(
      getSetting('pos_allow_selling_only_from_pricelist1'),
    )
      ? 1
      : 0;

    const sortProductsBy = useSelector(getSortProductsBy);
    const searchCodeFromMiddle = useSelector(
      getSetting('touchpos_search_product_code_from_middle'),
    )
      ? 1
      : 0;

    const searchByAllCodes = useSelector(
      getSetting('search_product_by_all_codes'),
    );

    const [searchChangeAllowed, setSearchChangeAllowed] = useState(true);
    const [productsPromise, setProductsPromise] = useState<
      Promise<{
        products: (Product & { embeddedPrice?: number })[];
        total: number;
      }>
    >(
      Promise.resolve({
        products: [],
        total: 0,
      }),
    );
    const { value: products = [], loading } = useAsync(async () => {
      const { products } = await productsPromise;
      return sortProducts({ sortProductsBy, products });
    }, [productsPromise, sortProductsBy]);

    const activePricelists = useSelector(getActivePriceListIDsAsString);
    const clientID = useSelector(getSelectedCustomerID);

    const doSearch = useCallback(
      searchVal => {
        const selectedAPISearchProp = searchByAllCodes
          ? 'fullTextSearchPhrase'
          : 'searchNameIncrementally';
        const { prodCode: newPromCode = searchVal } = extractDataFromCode(
          searchVal,
        );
        const prodPromise = dispatch(
          getProductsUniversal(
            {
              [selectedAPISearchProp]: newPromCode,
              searchCodeFromMiddle,
              getProductsFor: 'SALES',
              warehouseID,
              recordsOnPage: 40,
              orderBy: sortProductsBy,
              orderByDir: sortDir[sortProductsBy] ?? 'asc',
              getItemsFromFirstPriceListOnly,
              getPriceListPrices: 1,
              clientID,
              getLocalStockInfo: 1,
            },
            { reapplyPriceLists: activePricelists.length > 0 },
          ),
        );
        setProductsPromise(prodPromise);
        return prodPromise;
      },
      [
        searchByAllCodes,
        extractDataFromCode,
        dispatch,
        searchCodeFromMiddle,
        warehouseID,
        sortProductsBy,
        getItemsFromFirstPriceListOnly,
        activePricelists,
        clientID,
      ],
    );

    const [isReady, cancel] = useDebounce(
      () => {
        doSearch(searchVal);
      },
      400,
      [doSearch, searchVal],
    );

    const close = useCallback(() => {
      setShowRegularProducts(false);
      setSearchVal('');
      closePopover();
      (document.activeElement as HTMLElement).blur();
    }, [closePopover]);

    const onSearchChange = useCallback(
      text => {
        if (searchChangeAllowed) {
          setSearchVal(text);
        }
      },
      [searchChangeAllowed],
    );

    useAutoAddProduct(
      products.length === 1,
      products[0],
      searchChangeAllowed,
      close,
    );

    const addProd = (p: Product) => {
      dispatch(
        addProduct({
          productID: p.productID,
          amount: type === 'weight' && weight ? weight : undefined,
          price: type === 'price' ? embeddedPrice : undefined,
          manualDiscount: 0,
          // check if the product that is being added is a weightedBarcode product
          needsWeightPopup: isEmpty(embeddedBarcodeProduct),
        }),
      );
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
        // Select search results for keyboard navigation
        document
          .querySelector<HTMLInputElement>(
            '[data-testid=product-search-results]',
          )
          ?.focus();
        e.preventDefault(); // Do not scroll
        return;
      }
      if (e.key === 'Enter' && searchChangeAllowed) {
        setSearchChangeAllowed(false);
        if (isReady() === false) {
          cancel();
          doSearch(searchVal).then(({ products: [p] }) => {
            if (p) {
              addProd(p);
            } else {
              dispatch(addWarning(t('noResultsFor')));
            }
            close();
          });
        } else {
          productsPromise.then(({ products: [p] }) => {
            if (p) {
              addProd(p);
            } else {
              dispatch(addWarning(t('noResultsFor')));
            }
            close();
          });
        }
      }
    };

    // only one result and the single result is a embedded barcode product
    if (products.length === 1 && !isEmpty(embeddedBarcodeProduct)) {
      // if embedded with price - attach price with qty 1
      if (type === 'price') {
        products[0].embeddedPrice = embeddedPrice;
        products[0].amount = 1;
        // if embedded weight - attach qty equal to embedded weight
      } else {
        products[0].amount = weight;
      }
    }

    const focusHandler = useCallback(() => {
      if (!searchChangeAllowed) {
        setSearchChangeAllowed(true);
      }
    }, [searchChangeAllowed]);

    const barcodeEmbeddedSearchProducts = useMemo<
      EmbeddedProduct[] | undefined
    >(() => {
      if (type) {
        return products
          .filter(p =>
            [
              p.code,
              p.code2,
              p.code3,
              p.code5,
              p.code6,
              p.code7,
              p.code8,
            ].includes(prodCode),
          )
          .map(p => ({ ...p, embeddedPrice, amount: weight }));
      }
      return undefined;
    }, [embeddedPrice, prodCode, products, type, weight]);

    return (
      <>
        <SearchComponent
          testId="product-search-input"
          active={active}
          focusKey={focusKey}
          ref={ref}
          close={close}
          searchValue={searchVal}
          setSearchValue={onSearchChange}
          onKeyDown={onKeyDown}
          onFocus={focusHandler}
          placeholder="products"
        />
        <PopOverContainer show={active}>
          {barcodeEmbeddedSearchProducts && (
            <>
              <BarcodeEmbeddedProductResults
                addProduct={addProd}
                products={barcodeEmbeddedSearchProducts}
                loading={loading}
                close={close}
              />
              <Button
                color="primary"
                variant="contained"
                style={{ width: '100%' }}
                onClick={() => setShowRegularProducts(!showRegularProducts)}
              >
                {showRegularProducts
                  ? t('products.expandButton', {
                      context: 'close',
                    })
                  : t('products.expandButton', {
                      context: 'open',
                    })}
              </Button>
            </>
          )}
          {(!barcodeEmbeddedSearchProducts || showRegularProducts) && (
            <ProductResults
              products={products}
              loading={loading}
              close={close}
            />
          )}
        </PopOverContainer>
      </>
    );
  }),
);

export default ProductSearch;
