import { SaveInventoryWriteOffParams } from 'types/InventoryWriteOff';

import { doClientRequest } from './core/ErplyAPI';

export function saveInventoryWriteOff(queryProps: SaveInventoryWriteOffParams) {
  return doClientRequest<{ inventoryWriteOffID?: number }>({
    ...queryProps,
    request: 'saveInventoryWriteOff',
  });
}
