export const GET_CURRENCY = `CONFIG:GET_CURRENCY`;
export const GET_CURRENCY_SUCCESS = `CONFIG:GET_CURRENCY_SUCCESS`;
export const GET_CURRENCY_ERROR = `CONFIG:GET_CURRENCY_ERROR`;
export const SET_SETTINGS = `CONFIG:SET_SETTINGS`;
export const SAVE_SETTING = `CONFIG:SAVE_SETTINGS`;
export const SET_SETTINGS_ERROR = `CONFIG:SET_SETTINGS_ERROR`;
export const SET_SETTINGS_SUCCESS = `CONFIG:SET_SETTINGS_SUCCESS`;
export const SAVE_SETTING_LOCALSTORAGE = `CONFIG:SET_SETTING_LOCALSTORAGE`;
export const SAVE_SETTING_ERROR = `CONFIG:SAVE_SETTINGS_ERROR`;
export const SAVE_SETTING_SUCCESS = `CONFIG:SAVE_SETTINGS_SUCCESS`;
export const TEMP_SETTINGS_SET = 'CONFIG:TEMP_SETTINGS_SET';
export const TEMP_SETTINGS_DROP = 'CONFIG:TEMP_SETTINGS_DROP';
export const SET_OBJECTS = {
  START: 'CONFIG:SET_OBJECTS_START',
  ERROR: 'CONFIG:SET_OBJESTS_ERROR',
  SUCCESS: 'CONFIG:SET_OBJECTS_SUCCESS',
};
