import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import './TaxRates.scss';
import { AddButton } from 'components/UIElements/UIButton';
import { modalPages as mp } from 'constants/modalPage';
import { getActiveVatRates } from 'reducers/vatRatesDB';

const TaxRates = () => {
  const taxRates = useSelector(getActiveVatRates);

  const dispatch = useDispatch();
  const close = () => dispatch(previousModalPage());
  const editTaxRate = id =>
    dispatch(
      openModalPage({
        component: mp.taxRatesSettingsEdit,
        props: { id },
      }),
    );

  const { t } = useTranslation('settingsTaxRates');

  return (
    <div className="erply-settings__tax-rates">
      <Modal.Header>
        <span>{t('title')}</span>
        <span style={{ flexGrow: 1 }} />
        <AddButton action={() => editTaxRate()} variant="POS" />
        <CloseButton action={close} />
      </Modal.Header>
      <Modal.Body>
        <Table hover>
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('rate')}</th>
              <th>{t('code')}</th>
            </tr>
          </thead>
          <tbody>
            {taxRates.map(({ id, name, rate, code }) => (
              <tr
                key={id}
                onClick={() => editTaxRate(id)}
                style={{ cursor: 'pointer' }}
              >
                <td>{name}</td>
                <td>{rate}</td>
                <td>{code}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </div>
  );
};

export default TaxRates;
