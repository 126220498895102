import React, { useState, useEffect } from 'react';

import './TaxRates.scss';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import FormGroup from 'react-bootstrap/FormGroup';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { SaveButton } from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';
import { getVatRateByID } from 'reducers/vatRatesDB';
import { saveVatRate } from 'actions/vatRatesDB';
import { addError } from 'actions/Error';
import Loader from 'components/Loader';
import { positive, float } from 'components/FieldTypes/formatters';
import styles from 'components/FieldTypes/skins/skins.module.scss';

const vatInputClass = classNames([styles.formInput, styles.shortTitle]);
/**
 * @param id id of the vatrate to edit
 */
const TaxRatesEdit = ({ id }) => {
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();
  const close = () => dispatch(previousModalPage());

  const vatRate = useSelector(getVatRateByID(id));
  const [name, setName] = useState('');
  const [rate, setRate] = useState('');
  const [code, setCode] = useState('');
  useEffect(() => {
    setName(vatRate?.name ?? '');
    setRate(vatRate?.rate ?? '');
    setCode(vatRate?.code ?? '');
  }, [vatRate]);
  const save = async () => {
    setSaving(true);
    try {
      await dispatch(saveVatRate({ id, name, rate, code }));
      close();
    } catch (e) {
      console.error('Failed to save vat rate', e);
      dispatch(addError(e.message, { selfDismiss: true }));
    } finally {
      setSaving(false);
    }
  };

  const onNameChange = e => setName(e.target.value);
  const onRateChange = e => setRate(e.target.value);
  const onCodeChange = e => setCode(e.target.value);

  const { t } = useTranslation('settingsTaxRates');

  return (
    <div className="erply-settings">
      <Loader
        show={saving}
        loadingText="Saving..."
        style={{ display: 'block', height: '100%' }}
      >
        <Modal.Header>
          <span>{id ? t('edit.title.edit') : t('edit.title.add')}</span>
          <span style={{ flexGrow: 1 }} />
          <SaveButton variant="POS" action={save} disabled={!(name && rate)} />
          <CloseButton action={close} />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <InputField
              className={vatInputClass}
              size="lg"
              title={t('name')}
              value={name}
              isInvalid={!name}
              errorText={!name && t('edit.validation.required')}
              onChange={onNameChange}
            />
          </FormGroup>
          <FormGroup>
            <InputField
              className={vatInputClass}
              formatter={float.and(positive)}
              size="lg"
              title={t('rate')}
              disabled={id}
              value={rate}
              isInvalid={!rate}
              errorText={!rate && t('edit.validation.required')}
              onChange={onRateChange}
            />
          </FormGroup>
          <FormGroup>
            <InputField
              className={vatInputClass}
              size="lg"
              title={t('code')}
              value={code}
              onChange={onCodeChange}
            />
          </FormGroup>
        </Modal.Body>
      </Loader>
    </div>
  );
};

export default TaxRatesEdit;
