/* eslint-disable no-console */
import jwtDecode from 'jwt-decode';
import i18next from 'i18next';

import { getCafaEntry2 } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';

import nets from '../requests';
import { NETS_CONST, NetsConfig } from '../types';

const pingTerminal = async (config, warn) => {
  try {
    const { data } = await nets.getTerminalInfo({
      config,
      params: { terminalId: config.terminal },
    });
    if (data) {
      sessionStorage.setItem(NETS_CONST.TERMINAL, config.terminal);
    } else {
      throw new Error('No connection');
    }
  } catch (err) {
    warn(
      i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.noConnectionToTerminal`, {
        terminal: config.terminal,
      }),
    );
  }
};

const initSetup = warnFunction => async (dispatch, getState) => {
  let token;
  let decoded;

  try {
    await i18next.loadNamespaces('paymentIntegrations', err =>
      console.error('Failed to load translations for paymentintegrations', err),
    );
  } catch (err) {
    console.error('Failed to load translations for paymentintegrations', err);
  }

  const warn = warning =>
    warnFunction ? warnFunction(warning) : () => undefined;
  const config = getCafaEntry2<Required<NetsConfig>>(
    NETS_CONST.CAFA_CONFIG_NAME,
    INTEGRATION_TYPES.payment,
  )(getState());

  if (config.environment)
    sessionStorage.setItem(NETS_CONST.ENV, config.environment);

  try {
    token = sessionStorage.getItem(NETS_CONST.TOKEN);
    decoded = jwtDecode(token);
  } catch (err) {
    console.error('Failed to decode JWT token for NETS', err);
  }

  if (
    token &&
    config.terminal &&
    decoded?.exp * 1000 - new Date().getTime() > 3600000
  ) {
    warn(i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.checkTerminalCurrency`));
    return pingTerminal(config, warn);
  }

  try {
    if (!(config.username && config.password))
      return warn(
        i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.missingUsernamePassword`),
      );
    const {
      data: { token, username, terminals },
    } = await nets.login({
      username: config.username,
      password: config.password,
    });

    if (!token)
      return warn(
        i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.incorrectUsernamePassword`),
      );

    sessionStorage.setItem(NETS_CONST.TOKEN, token);

    if (!terminals.length)
      return warn(
        i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.noTerminals`, {
          username,
        }),
      );

    sessionStorage.setItem(NETS_CONST.ALL_TERMINALS, JSON.stringify(terminals));
    if (!config.terminal)
      return warn(
        i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.noSelectedTerminal`),
      );

    if (!terminals.includes(config.terminal))
      return warn(
        i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.userNoAccessToTerminal`, {
          username,
          terminal: config.terminal,
        }),
      );
    await pingTerminal(config, warn);
    warn(i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.checkTerminalCurrency`));
  } catch (err) {
    console.error('Failed to initialize NETS integration', err);
  }
  return true;
};

export default initSetup;
