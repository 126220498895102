import * as R from 'ramda';
import i18next from 'i18next';

import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';
import { getCustomer, getPayments } from 'reducers/Payments';
import { ErplyAttributes } from 'utils';
import { PaymentObj } from 'paymentIntegrations/types';
import { Payment } from 'types/Payment';
import { addError } from 'actions/Error';

type PaymentRequest = Payment & { requestName: string };

export const assignCardTypesToGiftCardPayments: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, requests) => async (
  dispatch,
  getState,
) => {
  const payments: PaymentObj[] = Object.values(getPayments(getState()));
  const cashCardTypeID = getSetting('plugin_gcr_cash_card_type_id')(getState());

  function isGiftCardPaymentRequest(request: PaymentRequest) {
    return (
      request.requestName === 'savePayment' &&
      request.type === 'GIFTCARD' &&
      !request.cardType
    );
  }

  function getPayment(request: PaymentRequest) {
    const attributes = new ErplyAttributes(request);
    return payments.find(
      payment =>
        Number(payment.giftCardID) === Number(attributes.asDict.giftCardID),
    );
  }

  function isCashCard(payment?: PaymentObj) {
    return Number(cashCardTypeID) === Number(payment?.giftCardTypeID);
  }

  return requests.map(request => {
    if (!isGiftCardPaymentRequest(request as PaymentRequest)) return request;

    const giftCardPayment = getPayment(request as PaymentRequest);
    if (!giftCardPayment?.serial)
      return R.assoc('cardType', 'GIFT_CERTIFICATE', request);

    if (isCashCard(giftCardPayment))
      return R.assoc('cardType', 'Cash_Card', request);

    return R.assoc('cardType', 'Voucher', request);
  });
};

export const enforceVoucherOwnership: Required<
  PosPlugin
>['onAddSerialGiftCardPayment']['on'] = (p, ap) => async (
  dispatch,
  getState,
) => {
  const cashCardTypeID = getSetting('plugin_gcr_cash_card_type_id')(getState());
  const isCashCard = Number(cashCardTypeID) === Number(ap.giftCardTypeID);

  if (isCashCard) return ap;

  const selectedCustomerID = getCustomer(getState())?.id;

  const isSelectedCustomerOwner =
    Number(p.card.purchasingCustomerID) === Number(selectedCustomerID);

  if (isSelectedCustomerOwner) return ap;

  dispatch(
    addError(i18next.t('payment:alerts.notVoucherOwner'), {
      selfDismiss: 8000,
    }),
  );
  throw new Error(
    'Cannot use the voucher. The selected customer is not the voucher owner.',
  );
};
