import { Box, TextField, MenuItem } from '@material-ui/core';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAllCurrencies } from 'reducers/configs/currency';

import { getIsRegularCardAndTaxFree, setCurrency } from '../redux';

const CurrencySelect: React.FC<{ children?: never }> = () => {
  const isRegularGiftCardAndTaxFree = useSelector(getIsRegularCardAndTaxFree);
  const currencies = useSelector(getAllCurrencies) ?? [];
  const dispatch = useDispatch();

  return isRegularGiftCardAndTaxFree ? (
    <Box>
      <TextField
        select
        variant="outlined"
        fullWidth
        label="Vali valuuta"
        onChange={e => {
          const currency = currencies.find(cur => cur.code === e.target.value);
          if (currency) dispatch(setCurrency(currency));
        }}
      >
        {currencies.map(({ code }) => (
          <MenuItem key={code} value={code}>
            {code}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  ) : null;
};

export default CurrencySelect;
