import React, { useCallback, useState } from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';

import { addError, addWarning, dismissType } from 'actions/Error';
import { getClientCode } from 'reducers/Login';
import { Field } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';
import { PosPlugin } from 'plugins/plugin';
import { getCustomers } from 'services/ErplyAPI';
import MuiButton from 'components/CustomButtons/MuiButton';

import { getConfiguration } from '../../../configuration';

import { generateCustomerCardNumber } from './api';
import { getValidationMessage, validate } from './utils';

type Props = Omit<
  Parameters<Required<PosPlugin>['UICustomerFormBeta']>[0],
  'customerType'
>;

/**
 * If new customer is being created adds a button that generates a new loyalty card number
 * and applies custom validations to loyalty card number field. When number is valid checks
 * if the number is not already  in use by another customer.
 *
 * If editing existing customer disables loyalty card number field.
 */
const LoyaltyCardNumberField: React.FC<Props> = ({
  customer,
  handleChange,
  onChange,
}) => {
  const { t } = useTranslation('createCustomer');
  const dispatch = useDispatch();

  const storeAccountNumber = useSelector(getClientCode);
  const { isProduction } = useSelector(getConfiguration);

  const [isGenerating, setGenerating] = useState(false);
  const [isChecking, setChecking] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);

  const isValid = customer.customerID
    ? true
    : validate(customer.customerCardNumber ?? '');

  const getCustomerCardNumber = useCallback(async () => {
    try {
      setGenerating(true);
      dispatch(
        addWarning('Generating customer card number...', {
          selfDismiss: false,
          errorType: 'mmCustomerCardNumber',
        }),
      );
      const cardNumber = await generateCustomerCardNumber({
        isProduction,
        storeAccountNumber,
      });
      onChange({ ...customer, customerCardNumber: String(cardNumber) });
    } catch (error) {
      dispatch(dismissType('mmCustomerCardNumber'));
      dispatch(addError('Failed to generate customer card number'));
    } finally {
      dispatch(dismissType('mmCustomerCardNumber'));
      setGenerating(false);
    }
  }, [dispatch, storeAccountNumber, customer, onChange, isProduction]);

  useDebounce(
    () => {
      setAlreadyExists(false);
      if (isValid && !customer.customerID) {
        setChecking(true);
        // NB! M&M uses custom customer registry
        getCustomers({
          searchNameIncrementally: customer.customerCardNumber,
        })
          .then(customers => {
            if (customers.length) {
              setAlreadyExists(true);
            }
            setChecking(false);
          })
          .catch(() => {
            setChecking(false);
            dispatch(addError('Failed to check if number is already in use'));
          });
      }
    },
    400,
    [isValid, customer.customerID, customer.customerCardNumber],
  );

  return (
    <Field
      name="customerCardNumber"
      wrapper={Grid}
      wrapperProps={{ item: true, xs: customer.customerID ? 6 : 12 }}
    >
      <TextField
        name="customerCardNumber"
        variant="outlined"
        label={t('fields.customerCardNumber')}
        fullWidth
        value={customer.customerCardNumber ?? ''}
        onChange={event => {
          setChecking(true);
          handleChange(event);
        }}
        error={!isValid || isChecking || alreadyExists}
        helperText={getValidationMessage(isValid, isChecking, alreadyExists)}
        InputProps={{
          endAdornment: customer.customerID ? null : (
            <InputAdornment position="end">
              <MuiButton
                color="UIButton"
                variant="contained"
                size="large"
                style={{
                  marginRight: '-14px',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  zIndex: 2,
                  minWidth: '130px',
                }}
                disabled={isGenerating}
                onClick={getCustomerCardNumber}
              >
                {t('errorText.loyaltyCardNumber.generate', {
                  context: isGenerating ? 'progress' : undefined,
                })}
              </MuiButton>
            </InputAdornment>
          ),
        }}
      />
    </Field>
  );
};

export default LoyaltyCardNumberField;
