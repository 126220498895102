import React, { CSSProperties, FC, useEffect, useState } from 'react';
import marked from 'marked';
import classNames from 'classnames';

type Props = {
  url?: string;
  markdown?: string;
  className?: string;
  id?: string;
  style?: CSSProperties;
};

const MarkDownToHtml: FC<Props> = ({
  url,
  markdown = '',
  className = '',
  id = '',
  style = {},
}) => {
  const [state, setState] = useState(url ? '' : markdown);
  useEffect(() => {
    if (url) {
      window
        .fetch(url)
        .then(response => response.text())
        .then(data => setState(data))
        .catch(err => console.error(err));
    }
  }, [url]);

  return (
    <div
      id={id}
      style={style}
      className={classNames('makrdown-to-html', className)}
      dangerouslySetInnerHTML={{ __html: marked(state) }}
    />
  );
};

export default MarkDownToHtml;
