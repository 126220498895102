import { useSelector } from 'react-redux';

import { PosPlugin } from '../../../plugin';
import { getExchangeState } from '../rdx';

const UIReturnOrderProductSelectReason: PosPlugin['UIReturnOrderProductSelectReason'] = ({
  children,
}) => {
  const { exchangeStep } = useSelector(getExchangeState);
  if (exchangeStep === 0) return children;
  return null;
};

export default UIReturnOrderProductSelectReason;
