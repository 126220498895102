import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { fetchPendingSalesAndOpenModal, startNewSale } from 'actions/sales';
import { GridButton } from 'components/GridButton';

import QffGridButton from '../QFF/QffGridButton';

const PopupBttn = ({
  name,
  resetSale = false,
}: {
  name: string;
  resetSale?: boolean;
}) => {
  const dispatch = useDispatch();

  return (
    <GridButton
      key="action-btn-functions"
      customColor
      style={{ backgroundColor: 'rgb(195, 177, 225)' }}
      onClick={() => {
        if (resetSale) {
          return dispatch(startNewSale());
        }
        return dispatch(fetchPendingSalesAndOpenModal());
      }}
    >
      <span style={{ color: 'black' }}>{name}</span>
    </GridButton>
  );
};

const UIExtraActionButtons = () => {
  const { t } = useTranslation('gridButtons');
  const opened = useSelector(getGridIsOpened);

  return opened ? null : (
    <>
      <div>
        <PopupBttn name="Fit Pad Sales" />
        <PopupBttn name={t('saleOptions.newSale')} resetSale />
      </div>
      <div>
        <QffGridButton />
      </div>
    </>
  );
};

export default UIExtraActionButtons;
