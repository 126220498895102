import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';

import { getSalesDocs, getReturnDocument } from 'reducers/returnProducts';
import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import {
  setDocSelected,
  setReturnDocument,
  resetProductReturn,
} from 'actions/returnProducts';
import { PosPlugin } from 'plugins/plugin';

import { getCFRState, setIsOnlineReturn } from '../rdx';

const UIProductReturnCloseButton: PosPlugin['UIProductReturnCloseButton'] = ({
  close,
  children,
}) => {
  const dispatch = useDispatch();
  const salesDocs = useSelector(getSalesDocs);
  const returnDocument = useSelector(getReturnDocument);
  const { isOnlineReturn } = useSelector(getCFRState);

  function closeAndResetCFRReturn() {
    close();
    dispatch(setIsOnlineReturn(false));
  }

  if (!isOnlineReturn) {
    return R.assocPath(['props', 'action'], closeAndResetCFRReturn)(children);
  }

  return (
    <>
      {salesDocs.length > 1 && returnDocument ? (
        <SaveButton
          data-testid="save-return-btn"
          title="Back"
          style={{ marginLeft: '1rem' }}
          action={() => {
            dispatch(setDocSelected(null));
            dispatch(setReturnDocument(null));
          }}
        />
      ) : null}
      <CloseButton
        action={() => {
          close();
          dispatch(setIsOnlineReturn(false));
          dispatch(resetProductReturn());
        }}
      />
    </>
  );
};

export default UIProductReturnCloseButton;
