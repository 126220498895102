import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
  FormHelperText,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { SaveButton } from 'components/UIElements/UIButton';
import {
  saveTempSetting,
  commitTempSettings,
  dropTempSettings,
} from 'actions/configs';
import { getCustomPaymentTypes, getTypes } from 'reducers/PaymentTypes';

export default () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('settingsCustomPayment');
  const paymentTypes = useSelector(getCustomPaymentTypes);

  const enabledCustomPayments = useSelector(getTypes);

  const onClose = () => {
    dispatch(
      dropTempSettings([
        'enabled_custom_payments',
        'pos_custom_extra_card_payment_types',
      ]),
    );
    dispatch(previousModalPage());
  };

  const toggleCustomPaymentInPaymentView = (key, value) => {
    dispatch(
      saveTempSetting(
        'enabled_custom_payments',
        Object.fromEntries(
          paymentTypes
            .map(pt => pt.type)
            .filter(type =>
              type === key ? value : enabledCustomPayments[type],
            )
            .map(type => [type, 1]),
        ),
      ),
    );
  };

  const onSave = () => {
    dispatch(commitTempSettings(['enabled_custom_payments'])).then(onClose);
  };

  /**
   * Checkbox with label
   *
   * Includes a warning to the user if given a type that is not in a compatible format
   * Incompatible types are not correctly reported in Close day → Tenders and must be fixed in the BO
   */
  const Check = useCallback(
    React.memo(
      ({ name, type }) => {
        const normalizedType = type
          .replace(/[ _]+/g, '_')
          .replace(/[^a-zA-Z0-9_]/g, '');
        return (
          <FormGroup>
            <FormControlLabel
              label={name}
              labelPlacement="end"
              control={
                <Checkbox
                  onChange={e =>
                    toggleCustomPaymentInPaymentView(type, e.target.checked)
                  }
                  checked={enabledCustomPayments[type]}
                />
              }
            />
            {normalizedType !== type && (
              <FormHelperText error={true}>
                <p>{t('invalidType')}</p>
                <p>
                  {t('invalidTypeRecommendation', {
                    current: type,
                    recommended: normalizedType,
                  })}
                </p>
              </FormHelperText>
            )}
          </FormGroup>
        );
      },
      [toggleCustomPaymentInPaymentView],
    ),
  );

  return (
    <div className="erply-settings">
      <Modal.Header>
        <span>{t('title')}</span>

        <span style={{ flexGrow: 1 }} />
        <SaveButton action={onSave} variant="POS" />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        {paymentTypes?.length > 0 ? (
          paymentTypes?.map(paymentType => (
            <Check name={paymentType.name} type={paymentType.type} />
          ))
        ) : (
          <h2>{t('noCustomPayments')}</h2>
        )}
      </Modal.Body>
    </div>
  );
};
