import React from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getProductUnits } from 'reducers/productUnitsDB';
import { getActiveVatRates, getVatRates } from 'reducers/vatRatesDB';
import { getProductGroupsTreeOrdered } from 'reducers/productGroupsDB';
import InputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import { notUndefinedOrNull } from 'utils';

import { forms } from './formShapes';

export const GroupForm = ({ onChange, handleSaveItem }) => {
  const handleChange = e => {
    const { name, value } = e.target;
    onChange(name, value);
  };
  const { t } = useTranslation('createNew');

  const handleSubmit = e => {
    e.preventDefault();
    handleSaveItem();
  };

  const { groupForm } = forms;
  return (
    <Form data-testid="create-group-form" onSubmit={handleSubmit}>
      {groupForm.map(field => (
        <Form.Group key={field.key} controlId="groupFields">
          <InputField
            size="lg"
            title={t(`group.inputs.${field.key}`, { context: 'title' })}
            name={field.key}
            type={field.type}
            data-testid="group-field"
            data-test-key={field.key}
            placeholder={t(`group.inputs.${field.key}`, {
              context: 'placeholder',
            })}
            className={classNames([styles.formInput, styles.mediumTitle])}
            onChange={handleChange}
          />
        </Form.Group>
      ))}
      <input hidden type="submit" />
    </Form>
  );
};

export const ProductForm = ({ state, onChange, handleSaveItem }) => {
  const { t } = useTranslation('createNew');
  const allGroups = useSelector(getProductGroupsTreeOrdered);
  const vatRates = useSelector(getVatRates).sort(
    (a, b) => Number(a.id) - Number(b.id),
  );
  const fallbackActiveRate = vatRates?.[0];
  const activeVatRates = useSelector(getActiveVatRates);
  const allRates = (activeVatRates.length
    ? activeVatRates
    : [fallbackActiveRate]
  )
    .filter(notUndefinedOrNull)
    .map(item => ({
      name: item.name,
      value: item.id,
    }));
  const allUnits = [
    { name: '', value: '0' },
    ...(useSelector(getProductUnits).map(item => ({
      name: item.name,
      value: item.unitID,
    })) ?? []),
  ];

  const returnOptions = key => {
    let option;
    switch (key) {
      case 'groupID':
        option = allGroups;
        break;
      case 'vatrateID':
        option = allRates;
        break;
      case 'unitID':
        option = allUnits;
        break;
      default:
        break;
    }
    return option;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleSaveItem();
  };

  const { productForm } = forms;
  return (
    <Form data-testid="create-product-form" onSubmit={e => handleSubmit(e)}>
      {productForm.list.map(field => (
        <Form.Group key={field.key} controlId="groupFields">
          <InputField
            size="lg"
            title={t(`product.fields.${field.key}`, { context: 'title' })}
            name={field.key}
            type={field.type}
            placeholder={t(`product.fields.${field.key}`, {
              context: 'placeholder',
            })}
            data-testid="product-field"
            data-test-key={field.key}
            options={returnOptions(field.key)}
            className={classNames([styles.formInput, styles.mediumTitle])}
            value={state[field.key] || ''}
            isRequired={field.required}
            onChange={handleChange}
            readOnly={field.readOnly}
          />
        </Form.Group>
      ))}
      <span>{t('product.attributes')}</span>
      <hr />
      <InputField
        type="checkbox"
        name="nonStockProduct"
        data-testid="non-stock-product"
        value={state.nonStockProduct || false}
        onChange={handleChange}
        size="lg"
      >
        {t(`product.fields.${productForm.checkbox.key}`)}
      </InputField>
      <input hidden type="submit" />
    </Form>
  );
};
