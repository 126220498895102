import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { getShouldLock } from '../redux/selectors';

const UIProducts: Required<PosPlugin>['UIProducts'] = ({ children }) => {
  const shouldLock = useSelector(getShouldLock);

  if (!shouldLock) return children;

  return R.pipe(
    R.over(
      R.lensPath(['props', 'items']),
      R.map(
        R.pipe(
          R.assoc('disabled', true),
          R.assoc('style', { opacity: 0.25, pointerEvents: 'none' }),
        ),
      ),
    ),
  )(children);
};

export default UIProducts;
