import { CustomerCreationField, customerCreationFieldKey } from '../types';

export const defaultPromotion = {
  type: 'IMAGE',
  format: 'JPG',
  src: 'https://cdn.erply.com/images/469357/March-2021-newsletter.jpg',
  duration: 2,
};

export const promoTypes = ['IMAGE', 'VIDEO'];

export const promoOptions = {
  IMAGE: ['GIF', 'JPEG', 'PNG', 'JPG'],
  VIDEO: ['OGG'],
};

export const customerCreationFields = [
  'firstName',
  'lastName',
  'email',
  'birthday',
  'zip',
  'code',
  'customerCardNumber',
  'phone',
  'mobile',
  'emailOptOut',
  'termsAndConditions',
  'privacy',
] as const;

export const defaultCustomerCreationFieldsDisplay: Record<
  customerCreationFieldKey,
  CustomerCreationField
> = {
  firstName: {
    name: 'firstName',
    type: 'TEXT',
    label: 'Enter your first name',
    placeholder: 'Insert your first name here',
    required: true,
    requiredMessage: 'First name is required',
  },
  lastName: {
    name: 'lastName',
    type: 'TEXT',
    label: 'Enter your last name',
    placeholder: 'Insert your last name here',
    required: true,
    requiredMessage: 'Last name is required',
  },
  email: {
    name: 'email',
    type: 'EMAIL',
    label: 'Enter your email',
    placeholder: 'Insert your email here',
    required: true,
    requiredMessage: 'Email is required',
  },
  birthday: {
    name: 'birthday',
    type: 'DATE',
    label: 'Birthday',
    required: false,
    requiredTrue: false,
  },
  zip: {
    name: 'zip',
    type: 'NUMBER',
    signed: false,
    decimal: false,
    label: 'Enter your zip code',
    placeholder: 'Insert your zip code here',
    required: false,
    requiredMessage: 'Zip is required',
  },
  code: {
    name: 'code',
    type: 'TEXT',
    label: 'Enter your ID code',
    placeholder: 'Insert your ID code here',
    required: false,
    requiredMessage: 'ID code required',
  },
  customerCardNumber: {
    name: 'customerCardNumber',
    type: 'TEXT',
    label: 'Enter your loyalty card number',
    placeholder: 'Insert loyalty card number here',
    required: false,
    requiredMessage: 'Loyalty card number required',
  },
  phone: {
    name: 'phone',
    type: 'TEXT',
    label: 'Enter your phone number',
    placeholder: 'Insert phone number here',
    required: false,
    requiredMessage: 'Phone number required',
  },
  mobile: {
    name: 'mobile',
    type: 'TEXT',
    label: 'Enter your mobile number',
    placeholder: 'Insert mobile number here',
    required: false,
    requiredMessage: 'Mobile number required',
  },
  emailOptOut: {
    name: 'emailOptOut',
    type: 'CHECKBOX',
    label: 'Subscribe to email list',
    required: false,
    requiredTrue: false,
  },
  termsAndConditions: {
    name: 'termsAndConditions',
    type: 'CHECKBOX',
    label: 'I agree to terms and conditions (Message)',
    message:
      'This is a text shown in modal for terms and conditions. \\n\\n1. Test \\n\\n2. Test',
    requiredTrue: false,
    required: false,
  },
  privacy: {
    name: 'privacy',
    type: 'CHECKBOX',
    label: 'I agree to terms and conditions (Link)',
    link: 'https://www.erply.com',
    requiredTrue: false,
    required: false,
  },
};

export const startNewSaleCentrifugoMessage = { action: 'START_NEW' };

export const hideMiscContentMessage = {
  action: 'HIDE_MISC_CONTENT',
};

export const getDonationMessage = { action: 'GET_DONATION' };

export const completePurchaseCentrifugoMessage = {
  action: 'COMPLETE_PURCHASE',
  getCustomerData: 'EMAIL',
  message: 'Please enter your email address to receive digital receipt',
};

export const finishedSaleCentrifugoMessage = { action: 'FINISHED' };

export const customerEmailSearchMessage = { action: 'GET_CUSTOMER_EMAIL' };

export const customerPhoneSearchMessage = { action: 'GET_CUSTOMER_PHONE' };

export const customerCreationAcceptedCentrifugoMessage = {
  action: 'REGISTER_CUSTOMER_SUCCESSFUL',
};

export const enterCouponMessage = { action: 'ENTER_COUPON_CODE' };

export const couponDeclinedMessage = {
  action: 'COUPON_CODE_FAILED',
  reason: 'Cashier rejected the code',
  showAgain: false,
};

export const customerCreationRejectionMessage = {
  action: 'REGISTER_CUSTOMER_FAILED',
  reason: 'Cashier rejected your info',
  showAgain: false,
};

export const posConnectedCentrifugoMessage = { action: 'POS_CONNECTED' };

export const defaultQrCodeConfig = {
  qrCodeData: 'QR Code Data Text',
  qrTitle: 'This is QR title',
  qrSubtitle: 'This is QR subtitle',
  qrTimeout: 15,
};

export const fontWeightOptions = ['REGULAR', 'SEMIBOLD', 'MEDIUM', 'BOLD'];

export const defaultFontConfig = {
  productsSummaryPrimary: {
    fontSize: 14,
    fontWeight: 'REGULAR',
  },
  productsSummarySecondary: {
    fontSize: 18,
    fontWeight: 'BOLD',
  },
};
