import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { getCompany, getClientCode } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getWarehouseById } from 'reducers/warehouses';
import { saveCompanyAndLocation } from 'actions/Settings';

import CompanyForm from './CompanyForm';
import './Company.scss';

const CompanySettings = () => {
  const dispatch = useDispatch();
  const ci = useSelector(getCompany);
  const cc = useSelector(getClientCode);
  const sp = useSelector(getSelectedPos);
  const cw = useSelector(getWarehouseById(sp.warehouseID));
  const initialState = {
    companyID: ci.id,
    name: ci.name,
    code: ci.code,
    VAT: ci.VAT,
    cc,
    warehouseID: sp.warehouseID,
    posName: sp.name,
    warehouseName: sp.warehouseName,
    phone: cw.phone,
    addressID: cw.addressID,
    street: cw.street,
    address2: cw.address2,
    zip: cw.ZIPcode,
    city: cw.city,
    state: cw.state,
    country: cw.country,
  };
  const [state, setState] = useState(initialState);

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const saveChanges = () => {
    dispatch(
      saveCompanyAndLocation(
        {
          companyID: state.companyID,
          name: state.name,
          code: state.code,
          VAT: state.VAT,
        },
        {
          warehouseID: state.warehouseID,
          name: state.warehouseName,
          phone: state.phone,
        },
        {
          addressID: state.addressID,
          street: state.street,
          address2: state.address2,
          postalCode: state.zip,
          city: state.city,
          state: state.state,
          country: state.country,
          typeID: state.addressID ? undefined : '1',
          ownerID: state.addressID ? undefined : ci.id,
        },
      ),
    );
  };

  const backToSettings = () => {
    dispatch(previousModalPage());
  };

  const { t } = useTranslation('settingsCompany');

  return (
    <div>
      <Modal.Header
        style={{
          display: 'flex',
        }}
      >
        <Modal.Title style={{ fontSize: '24px', fontWeight: 700 }}>
          {t('title')}
        </Modal.Title>
        <span style={{ flexGrow: 1 }} />
        <SaveButton
          action={saveChanges}
          disabled={
            JSON.stringify(state) === JSON.stringify(initialState) ||
            !state.name.replace(/\s/g, '')
          }
        />
        <CloseButton action={backToSettings} />
      </Modal.Header>
      <Modal.Body>
        <CompanyForm state={state} onChange={handleChange} />
      </Modal.Body>
    </div>
  );
};

export default CompanySettings;
