import { IDBPDatabase, openDB, deleteDB, IDBPTransaction } from 'idb';

import { REDUX_WAREHOUSEID } from 'constants/persistence';

import { DB_NAME, getTableMeta, MyDB, SO, ValueOf, VERSION } from './types';
/**
 * WORKFLOW FOR UPDATING INDEXEDDB
 *
 * Open and update indexed database
 * If there should be changes in the DB schema:
 * - update types
 * - update the version
 * - Optional: Create an update db function - updateDBFromV[x]toV[y], where x is the oldVersion and y the new version - needed for data stored in indexedDB only that should not be lost during version upgrade
 * - TIP: use `addMissingSOs` and `createSOAndIndexes` helper functions
 * - add the new update function to the version switch in getDB function below
 *
 */

/**
 * Creates Store Object and Index(es) for given SO schema
 * @param CURRENT_SO
 * @param database
 */
const createSOAndIndexes = (
  CURRENT_SO: ValueOf<typeof SO>,
  database: IDBPDatabase<MyDB>,
) => {
  // add keyPath only for SO that need/have it
  const store = CURRENT_SO.KEY_PATH
    ? database.createObjectStore(
        CURRENT_SO.NAME,
        CURRENT_SO.KEY_PATH as IDBObjectStoreParameters,
      )
    : database.createObjectStore(CURRENT_SO.NAME);
  // create indexes for SO that need/have it
  Object.values(CURRENT_SO.INDEXES ?? {})?.forEach(
    ({ indexName, keyPath, options }) => {
      store.createIndex(indexName, keyPath, options);
    },
  );
};

/**
 * Maps over the indexedDB schema and creates database store objects for every non-existing SO
 * Should be called in each updateDBFromV[x]toV[y] function
 * @param database
 */
const addMissingSOs = (database: IDBPDatabase<MyDB>) => {
  Object.values(SO).forEach((CURRENT_SO: ValueOf<typeof SO>) => {
    // check if the SO already exists
    if (!database.objectStoreNames.contains(CURRENT_SO.NAME)) {
      createSOAndIndexes(CURRENT_SO, database);
    }
  });
};

function fullIdbReset(database: IDBPDatabase<MyDB>) {
  // deletes all syncable SOs (aside from SHARED) and applies
  // if you need to reset Shared - add an exception BUT keep in mind that resetting Shared causes resync to fail -
  // because sessionKey is deleted from shared and is not set on time to continue resync
  // happens when POS is refreshed while the user is logged in and new version of indexedDB is released
  Array.from(database.objectStoreNames).forEach(so => {
    if (so !== SO.SHARED.NAME) {
      database.deleteObjectStore(so);
    }
  });
  // and generates new SOs from the SO const from ./types.ts
  addMissingSOs(database);
}

/**
 * Changes in v2:
 * 1. Campaigns and coupons SO has been removed
 * 2. Price lists are now filtered: only active and not expired price lists are fetched
 *
 * Upgrade steps:
 * 1. Delete removed SOs
 * 2. Clear price list SO (existing data might not be needed)
 * 3. Remove price list metadata to trigger resync
 */
async function upgradeIdbFromV1ToV2(
  database: IDBPDatabase<MyDB>,
  transaction: IDBPTransaction<MyDB>,
) {
  Array.from(database.objectStoreNames).forEach(so => {
    if (['campaigns', 'coupons'].includes(so)) {
      database.deleteObjectStore(so);
    }
  });

  const priceListStore = transaction.objectStore(SO.PRICE_LIST.NAME);
  priceListStore.clear();

  const sharedStore = transaction.objectStore(SO.SHARED.NAME);
  const warehouseId = await sharedStore.get(REDUX_WAREHOUSEID);
  const PRICE_LIST_META = getTableMeta(SO.PRICE_LIST.NAME, warehouseId);
  const metaStore = transaction.objectStore(SO.META.NAME);
  Object.values(PRICE_LIST_META).map(entryKey => metaStore.delete(entryKey));
}

const attemptOpeningIndexedDB = (clientCode: string) =>
  openDB<MyDB>(`${DB_NAME}-${clientCode}`, VERSION, {
    async upgrade(database, oldVersion, nv, transaction) {
      switch (oldVersion) {
        case 1: {
          upgradeIdbFromV1ToV2(database, transaction);
          break;
        }
        default:
          fullIdbReset(database);
          break;
      }
    },
  });

// endregion
const dbInstances = {};
/**
 * Returns db object
 *
 * The function will first check if there has already been a db created and stored in memory.
 * If not then it will:
 *  - generate a new one
 *  - store it in memory for future usage
 *  - return the db
 * If the existing version has higher version than the current DB version, the older database will be deleted
 * and new database with the current db version will be created and returned instead
 * @param clientCode
 */
const getDB = async (clientCode: string): Promise<IDBPDatabase<MyDB>> => {
  if (!clientCode) {
    throw new Error('Attempt to get indexedDB instance without clientCode');
  }
  if (!dbInstances[clientCode]) {
    /**
     * Attempt to open indexedDB 5 times
     * If we fail after the fifth time
     */
    let attemptsToOpenIndexedDB = 0;
    dbInstances[clientCode] = await attemptOpeningIndexedDB(clientCode).catch(
      async err => {
        if (err.name === 'VersionError' && attemptsToOpenIndexedDB < 5) {
          console.error(
            `Opening indexedDB failed, will proceed with deleting the ${`${DB_NAME}-${clientCode}`} and retrying`,
            err,
          );
          await deleteDB(`${DB_NAME}-${clientCode}`);
          attemptsToOpenIndexedDB += 1;
          return attemptOpeningIndexedDB(clientCode);
        }
        console.error('Failed to open indexedDB', err);
        return null;
      },
    );
  }
  return dbInstances[clientCode];
};
export default getDB;
