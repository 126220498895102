import React, { useCallback } from 'react';
import * as Sentry from '@sentry/browser';

import { useConfirmation } from 'components/Confirmation';

import HeaderIcon from '../Icon';
import i18next from 'containers/App/i18n';

import { useTranslation } from 'react-i18next';

const Feedback = () => {
  const confirm = useConfirmation();

  const { t } = useTranslation(['Sentry', 'header']);

  const openFeedbackEmail = useCallback(
    () =>
      confirm({
        title: t('manualBugReport.confirmation.title'),
        body: t('manualBugReport.confirmation.description'),
      })
        .then(() =>
          Sentry.captureException(new Error('User triggered bug report form'), {
            lastEvent: Sentry.lastEventId(),
          }),
        )
        .then(() =>
          Sentry.showReportDialog({
            labelComments: t('manualBugReport.dialog.labelComments'),
            title: t('manualBugReport.dialog.title'),
            subtitle: t('manualBugReport.dialog.subtitle'),
            subtitle2: t('manualBugReport.dialog.subtitle2'),
            lang: i18next.language,
            labelSubmit: t('manualBugReport.dialog.labelSubmit'),
            onLoad: () => {
              const input = document.getElementById('id_comments');
              if (input) {
                input.setAttribute(
                  'placeholder',
                  t('manualBugReport.dialog.input', {
                    context: 'default',
                  }),
                );
                input.value = t('manualBugReport.dialog.input', {
                  context: 'placeholder',
                });
              }
            },
          }),
        )
        .catch(() => {
          // User cancelled opening bug report form
        }),
    [],
  );

  return (
    <HeaderIcon
      icon="message"
      data-testid="header-feedback"
      title={t('titles.feedback', { ns: 'header' })}
      onClick={openFeedbackEmail}
    />
  );
};

export default Feedback;
