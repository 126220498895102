import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { SaveButton } from 'components/UIElements/UIButton';
import { getPointsOfSale } from 'reducers/PointsOfSale';
import InputField from 'components/FieldTypes/InputField';
import { getSetting } from 'reducers/configs/settings';
import { saveSetting } from 'actions/configs';

import styles from './AssociatePOS.module.scss';

const AssociatePOS = () => {
  const { t } = useTranslation('settingsAssociatePos');

  const [pos, setPos] = useState(
    useSelector(getSetting('CONFIGURATION: associated-pos')),
  );
  const pointsOfSale = useSelector(getPointsOfSale);
  const dispatch = useDispatch();
  const close = () => dispatch(previousModalPage());
  const save = () => {
    dispatch(
      saveSetting({
        parameterName: 'CONFIGURATION: associated-pos',
        parameterValue: pos,
      }),
    );
    close();
  };

  // noinspection EqualityComparisonWithCoercionJS
  return (
    <div>
      <Modal.Header className={styles.header}>
        <span>{t('title')}</span>

        <span style={{ flexGrow: 1 }} />
        <SaveButton action={save} variant="POS" />
        <CloseButton action={close} />
      </Modal.Header>
      <Modal.Body>
        <InputField
          type="checkbox"
          size="lg"
          value={!pos}
          onChange={() => setPos(null)}
        >
          {t('none')}
        </InputField>
        {pointsOfSale.map(({ pointOfSaleID: id, name }) => (
          <InputField
            key={id}
            size="lg"
            type="checkbox"
            value={id == pos}
            onChange={() => setPos(id)}
          >
            {name}
          </InputField>
        ))}
      </Modal.Body>
    </div>
  );
};

export default AssociatePOS;
