import i18next from 'i18next';

import { CardPaymentHooks, PaymentObj } from 'paymentIntegrations/types';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { getCafaEntry2 } from 'reducers/cafaConfigs';

import nets from '../requests';
import { NETS_CONST, NetsConfig } from '../types';

const makePayment = ({
  payment,
  hooks,
  allowPinBypass,
}: {
  payment: PaymentObj;
  hooks: CardPaymentHooks;
  allowPinBypass: boolean;
}) => async (dispatch, getState) => {
  const config = getCafaEntry2<Required<NetsConfig>>(
    NETS_CONST.CAFA_CONFIG_NAME,
    INTEGRATION_TYPES.payment,
  )(getState());

  hooks.updateMessage(
    i18next.t(`${NETS_CONST.TRANS_PATH}.paymentUI.processingPayment`, {
      amount: payment.amount,
    }),
  );
  const { refNo } = payment.attributes ?? {};
  const amount = Number(payment.amount.replace(/-/g, ''));
  if (Number(payment.amount) < 0)
    return nets.returnTransaction({
      config,
      params: {
        amount,
        terminalId: config.terminal,
      },
    });
  if (payment.paid && payment.shouldProcess) {
    return nets.voidTransaction({
      config,
      params: {
        terminalId: config.terminal,
        transactionId: refNo,
        amount,
      },
    });
  }
  return nets.createTransaction({
    config,
    params: {
      amount,
      terminalId: config.terminal,
      allowPinBypass,
    },
  });
};

export default makePayment;
