import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getConnectionHealth } from 'reducers/connectivity/connection';
import { checkHealthStatus } from 'actions/connectivity';

export const useTrackConnectionStatus = () => {
  const dispatch = useDispatch();
  const connectionHealth = useSelector(getConnectionHealth);
  useEffect(() => {
    dispatch(checkHealthStatus(connectionHealth ? 0 : 5));
    let healthInterval;
    // Non-round intervals are less likely to coincide with keep-alive expiration
    // Trying to make a request exactly when keep-alive is about to expire is fine in most browsers,
    // but in safari if the connection closes the browser will not reattempt and will report it as a failure
    // 30000 was perfectly hitting that timeout every third request (0->30->60s)
    // read more: https://developer.apple.com/forums/thread/16707
    connectionHealth
      ? (healthInterval = 28.913 * 1000)
      : (healthInterval = 2.91 * 1000);
    const checkHealth = () => {
      dispatch(checkHealthStatus(connectionHealth ? 0 : 5));
      return true;
    };
    const healthStatus = window.setInterval(checkHealth, healthInterval);

    window.addEventListener('online', checkHealth);
    window.addEventListener('offline', checkHealth);

    return () => {
      window.clearInterval(healthStatus);
      window.removeEventListener('offline', checkHealth);
      window.removeEventListener('offline', checkHealth);
    };
  }, [connectionHealth, checkHealthStatus, dispatch]);
};
