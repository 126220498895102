import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

import { getSelectedPos } from 'reducers/PointsOfSale';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { saveIssuedCoupon } from 'services/ErplyAPI/campaigns';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import Icon from 'components/Icon';
import { timestampInSeconds } from 'utils';

import './Coupons.sass';
import { printCoupon } from 'actions/integrations/printer';

const Coupons = ({ coupons }) => {
  const dispatch = useDispatch();
  const { pointOfSaleID, warehouseID } = useSelector(getSelectedPos);
  const employeeID = useSelector(getLoggedInEmployeeID);
  const createNewCoupon = async ({
    couponID,
    uniqueIdentifier,
    expiryDate,
    timestamp = timestampInSeconds(),
  }) => {
    const data = {
      couponID,
      uniqueIdentifier,
      warehouseID,
      pointOfSaleID,
      employeeID,
      timestamp,
      expiryDate,
    };
    const [newCoupon] = await saveIssuedCoupon(data);
    // TODO: Print coupon for specific customer - add customer data to data
    dispatch(printCoupon({ ...data, ...newCoupon }));
  };
  const { t } = useTranslation('printCoupons');
  return (
    <div className="available-coupons">
      <Modal.Header>
        <Modal.Title>{t('title')}</Modal.Title>
        <div className="available-coupons-action-buttons">
          <CloseButton action={() => dispatch(previousModalPage())} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>{t('headers.name')}</th>
              <th>{t('headers.code')}</th>
              <th style={{ width: '8ch' }} />
            </tr>
          </thead>
          <tbody>
            {coupons.map(r => (
              <tr key={r} style={{ cursor: 'pointer' }}>
                <td style={{ width: '60%' }}>{r.name}</td>
                <td style={{ width: '30%' }}>{r.code}</td>
                <td style={{ width: '10%' }}>
                  <Icon
                    invert
                    action={() => createNewCoupon(r)}
                    name="icon_printer"
                    style={{ fontSize: '24px' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </div>
  );
};

export default Coupons;
