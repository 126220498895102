import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';

import './carousels.scss';

type SingleImageP = {
  show: boolean;
  productName: string;
  close: () => void;
  imageUrl: string;
};

const SingleImageView: FC<SingleImageP> = ({
  show,
  imageUrl,
  productName,
  close,
}) => {
  return (
    <Modal
      show={show}
      scrollable
      centered
      animation
      onHide={close}
      onEscapeKeyDown={close}
      dialogClassName="single-product-image"
    >
      <Modal.Header closeButton>
        <Modal.Title>{productName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img src={imageUrl} alt={imageUrl} />
      </Modal.Body>
    </Modal>
  );
};

export default SingleImageView;
