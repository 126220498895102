import i18next from 'i18next';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { printPatchscript } from 'actions/integrations/printer';
import { RItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getWarehouseById } from 'reducers/warehouses';
import { addWarning } from 'actions/Error';
import { createConfirmation } from 'actions/Confirmation';
import { getCurrencyCode } from 'reducers/configs/settings';
import { getCurrency } from 'reducers/configs/currency';
import { RootState } from 'reducers';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';

import { JunctionAPIResponse } from '../types';

/**
 * Printing function for payment receipt - TJ terminal do not handle printing themselves
 * The func takes the payment response (PR) and creates a patchscript based on TJ documentation
 * Currently the patchscript generates short receipt - customer requirement
 * Mandatory fields should always be printed, Optional field only when returned by the PR
 */

// Kept for future ref -> some fields still missing in MS response

// const printFields = [
//   'POSDate', // Erply POS date -> TJ specifications
//   'POSTime', // Erply POS time  -> TJ specifications
//   'MerchantID', // src.MerchantId => additionalData.merchantId
//   'PosID', // src.Pos -> additionalData.pos (terminalID)
//   'PAN', // src.Card -> cardNumber
//   'STAN', // src.Seq -> additionalData.seq
//   'CardName', // src.CardName => paymentType
//   'EntryMode', // cardEntryMessages[src.CardEntry] => entryMode
//   'RetRefNum', // src.Reference => hostReferenceNumber ?? not sure
//   'AuthID', // src.AuthID -> authCode
//   '<<PosData.Key = BankMID>>', // omit not integrated yet by TJ team
//   '<<Cryptogram Type>>', // src.CryptogramType => cryptogramType
//   ' <<Cryptogram>>', // src.Cryptogram => cryptogram
//   '<<APL>>', // src.Apl => applicationLabel
//   '<<TVR>>', // src.Tvr => terminalVerificationResults
//   '<<AID>>', // src.Aid ? => aid
//   '<<TSI>>', // src.Tsi => transactionStatusInformation
//   '<<PINStatement>>', // src.PinStatement => pinStatement
//   '<<CVMResult>>', // src.CvmResults => cardholderVerificationMethod
//   'IAD: <<IAD>>', // src.Iad =>  issuerAuthenticationData
//   'UTI: <<UTI>>', // src.Reference -> referenceNumber
// ];

const getPosDate = (format: 'date' | 'time' = 'date', date?: string) => {
  const newDate = date ? new Date(date) : new Date();
  if (format === 'date') {
    return `${newDate.getFullYear()}${newDate.getMonth() +
      1}${newDate.getDate()}`;
  }
  return `${newDate.getHours()}${newDate.getMinutes() +
    1}${newDate.getSeconds()}`;
};

const makeCenteredText = (textItems: string[], bold = true): RItem => ({
  type: 'text',
  align: 'center',
  pieces: textItems.map(str => ({
    text: str,
    meta: { overflow: 'newline', bold },
  })),
});

const makeTable = (
  columns: number[],
  rows: (string | undefined)[][],
): RItem => {
  return {
    type: 'table',
    columns: columns.map(weight => ({ baseWidth: 0, weight })),
    // map through all the rows for the receipt
    rows: rows.map(pair => ({
      type: 'normal',
      cells: pair.map(item => ({
        align: 'left',
        pieces: [
          {
            text: item ?? '',
          },
        ],
      })),
    })),
  };
};

// Success patchscript
const createTJPatchScript = (data, isCustomerCopy): RItem[] => {
  const fields = {
    success: {
      mandatory: [
        [data.posDate, data.posTime],
        [data.additionalData.merchantId, data.additionalData.pos],
        [data.cardNumber, data.additionalData.seq],
        [data.paymentType, data.entryMode],
        [data.referenceNumber, ''],
        [data.authCode, ''],
      ],
      optional: [
        [`${data.cryptogramType}:`, data.cryptogram],
        [
          data.applicationLabel,
          data.additionalData.terminalVerificationResults,
        ],
        [data.aid, data.additionalData.transactionStatusInformation],
        [data.pinStatement, data.cardholderVerificationMethod],
        ['IAD:', data.additionalData.issuerAuthenticationData],
        ['UTI:', data.referenceNumber],
      ],
    },
    fail: {
      mandatory: [
        [data.posDate, data.posTime],
        [data.additionalData.merchantId, data.posID],
        [data.cardNumber, data.stan],
      ],
      optional: [
        [`${data.cryptogramType}:`, data.cryptogram],
        [
          data.applicationLabel,
          data.additionalData.terminalVerificationResults,
        ],
        ['AID:', data.aid],
        ['CVM Results:', data.cardholderVerificationMethod],
        ['UTI:', data.referenceNumber],
      ],
    },
  };

  const transactionStatuses = {
    0: 'Approved',
    10: 'Partial approval',
  };

  const isSuccessfulPayment = [0, 10].includes(Number(data.resultCode));
  const src = isSuccessfulPayment ? fields.success : fields.fail;
  return [
    makeCenteredText([data.transactionType]),
    makeCenteredText([`${isCustomerCopy ? 'Customer' : 'Merchant'} copy`]),
    makeCenteredText([
      transactionStatuses[Number(data.resultCode)] ?? 'Transaction Declined',
      '  ',
      data.hostCode,
    ]),
    makeCenteredText([data.address], false),
    makeTable([2, 1], src.mandatory),
    makeTable([1, 1], src.optional),
    makeCenteredText(['']),
    {
      type: 'table',
      columns: [
        { baseWidth: 0, weight: 1 },
        { baseWidth: 0, weight: 1 },
      ],
      rows: [
        {
          type: 'normal',
          cells: [
            {
              align: 'left',
              pieces: [
                {
                  text: `${isSuccessfulPayment ? 'Total' : 'Declined amount'}:`,
                  meta: { bold: true },
                },
              ],
            },
            {
              align: 'right',
              pieces: [
                {
                  text: data.amount,
                  meta: { bold: true },
                },
              ],
            },
          ],
        },
      ],
    },
    makeCenteredText(['']),
    makeCenteredText(['Please keep this for your records']),
  ];
};

const printReceipt = (
  response: JunctionAPIResponse,
  type: 'merchant' | 'customer',
) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const { warehouseID } = getSelectedPos(getState());
  const { street, city, name } =
    getWarehouseById(warehouseID)(getState()) ?? {};
  const [record] = response?.records ?? [];
  const currencyCode = getCurrencyCode(getState());
  const currency = getCurrency(currencyCode)(getState());

  const data = {
    ...record,
    address: `${name}, ${street}, ${city}`,
    posDate: getPosDate('date'),
    posTime: getPosDate('time'),
    amount: currency.format(Number(record.approvedAmount)),
  };

  const receiptPatchScript = createTJPatchScript(data, type === 'customer');
  await dispatch(printPatchscript(receiptPatchScript));
};

const handlePrint = ({ response }: { response: JunctionAPIResponse }) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  if (!response?.records?.length) {
    dispatch(
      addWarning(i18next.t('payment:cardPayments.junction.alerts.printFail')),
    );
    return;
  }

  const { value: junctionConfig } =
    getCafaEntry('junction', INTEGRATION_TYPES.payment)(getState()) ?? {};

  // eslint-disable-next-line no-restricted-syntax
  for await (const x of ['merchant', 'customer'] as const) {
    if (junctionConfig?.print === 'automatic') {
      await dispatch(printReceipt(response, x));
    } else {
      await new Promise((res, rej) =>
        dispatch(
          createConfirmation(res, rej, {
            title: 'Confirmation',
            body: `Print ${x} receipt?`,
            automaticAction: 'resolve',
            automaticActionAfter: 15000,
          }),
        ),
      )
        .then(async () => {
          await dispatch(printReceipt(response, x));
        })
        .catch(() => false);
    }
  }
};

export default handlePrint;
