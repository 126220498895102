import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';

import { QrCodeConfig } from './types';

import { getQRCodeConfig } from '.';

type QrCodeConfProps = {
  saveQRConfig: (config: Partial<QrCodeConfig>) => void;
  config: Partial<QrCodeConfig>;
};

export const QRCodeConfig: FC<QrCodeConfProps> = ({ config, saveQRConfig }) => {
  const { t } = useTranslation('customerDisplay');
  const existingQRConfig = useSelector(getQRCodeConfig);

  const [localQrCodeConfig, setLocalQrCodeConfig] = useState<
    Partial<QrCodeConfig>
  >(config ?? {});

  const handleQrCodeChange = e => {
    const { name, value } = e.target;
    const numericValue = Number(value) || 0;
    setLocalQrCodeConfig({
      ...localQrCodeConfig,
      [name]: name === 'qrTimeout' ? numericValue : value,
    });
  };

  const tempConfig = useMemo(() => {
    return R.mergeRight(existingQRConfig, localQrCodeConfig);
  }, [existingQRConfig, localQrCodeConfig]);

  return (
    <>
      <h4>{t('qrForm.title')}</h4>
      <Table>
        <thead>
          <tr>
            <th>{t('qrForm.qrTitle')}</th>
            <th>{t('qrForm.qrSubtitle')}</th>
            <th>{t('qrForm.qrTimeout')}</th>
            <th>{t('qrForm.qrData')}</th>
          </tr>
          <tr>
            <td>
              <InputField
                type="text"
                onChange={handleQrCodeChange}
                size="md"
                name="qrTitle"
                placeholder={existingQRConfig.qrTitle}
                value={tempConfig.qrTitle}
              />
            </td>
            <td>
              <InputField
                type="text"
                onChange={handleQrCodeChange}
                size="md"
                name="qrSubtitle"
                placeholder={existingQRConfig.qrSubtitle}
                value={tempConfig.qrSubtitle}
              />
            </td>
            <td>
              <InputField
                type="text"
                onChange={handleQrCodeChange}
                size="md"
                name="qrTimeout"
                placeholder={existingQRConfig.qrTimeout}
                value={tempConfig.qrTimeout}
              />
            </td>
            <td>
              <InputField
                type="text"
                onChange={handleQrCodeChange}
                size="md"
                name="qrCodeData"
                placeholder={existingQRConfig.qrCodeData}
                value={tempConfig.qrCodeData}
              />
            </td>
          </tr>
        </thead>
      </Table>

      <UIButton
        disabled={R.equals(config, localQrCodeConfig)}
        action={() => saveQRConfig(localQrCodeConfig)}
        text={t('buttons.saveQR')}
      />
    </>
  );
};
