import React from 'react';

import { PosPlugin } from 'plugins/plugin';

import DisabledShortcuts from './DisabledShortcuts';

const UICol2: Required<PosPlugin>['UICol2'] = ({ children }) => {
  return <DisabledShortcuts>{children}</DisabledShortcuts>;
};

export default UICol2;
