import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import * as connectivity from 'actions/connectivity';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { storePlugin as localDb } from 'services/localDB/core';
import { storePlugin as pendingRequests } from 'services/localDB/pendingRequests';
import loggingMiddleware from 'store/middleware/loggingMiddleware';
import { reapplyPriceListsToCachedProducts } from 'store/middleware/reapplyPriceListsToCachedProducts';

import rootReducer from '../reducers';

import listActionMiddleware from './middleware/listActionMiddleware';
import autoLogin from './plugins/autoLogin';
import tabTitle from './plugins/tabTitle';

const devtoolsOptions = {
  // Display a custom name for this store - makes it easier to find when you have multiple tabs
  name: `POSV2 @${new Date().toTimeString().split(' ')[0]}`,

  // For every action, include a stacktrace showing why/where that action was triggered
  trace: true,
  traceLimit: 30,

  // Hide certain actions that are fired often
  actionsBlacklist: [
    ...Object.values(connectivity).filter(v => typeof v === 'string'),
    'CONNECTION:SET_ACTIVITY',
    'CLOUD:SET_STATUS',
  ].filter(() => true), // NOTE: Having actions blacklisted causes off-by-n errors when trying to use time travel

  // Snip off everything past → in the action's name
  // This enables putting a uuidv1 in every action to pre-empt typos, without cluttering the devtools
  actionSanitizer: ({ type, ...rest }) => {
    if (/products-db/.test(type)) {
      return { type, rest: 'HIDDEN FOR PERFORMANCE' };
    }
    if (/PLUGIN/.test(type)) {
      const [pluginPrefix, pluginName, actionName] = type.split(':');
      return {
        type: `${pluginPrefix}:${actionName}`,
        plugin: pluginName,
        rest,
      };
    }
    return { type: type.split('→')[0], ...rest };
  },
  // ProductsDB can get huge with large datasets, past 200 products show IDs only
  // maxAge: 20,
  stateSanitizer: state => ({
    ...state,
    productSearch: {
      // products: 'HIDDEN FOR PERFORMANCE',
      ...state.productSearch,
    },
    productsDB: {
      ...state.productsDB,
      // all: 'HIDDEN FOR PERFORMANCE',
    },
  }),

  // These must not be lambdas and have simple positional arguments with no defaults
  // otherwise redux-devtools will not understand them
  actionCreators: {
    openModalPage(page) {
      return openModalPage(
        page || {
          component: modalPages.PaymentConfirmation,
          modalClassName: 'confirmation',
          isPopup: true,
        },
      );
    },
    setCurrency(curr) {
      return {
        type: 'CONFIG:GET_CURRENCY_SUCCESS',
        data: [
          {
            currencyID: '-1',
            name: 'Euro (overridden by devtools)',
            code: curr || 'EUR',
            rate: '1.000000000000',
            nameShort: 'euro/euro (overridden by devtools)',
            nameFraction: 'cent/cent (overridden by devtools)',
            default: '1',
            added: '0',
            lastModified: '0',
          },
        ],
      };
    },
  },
};

const configStore = () => {
  const middleWare = [
    listActionMiddleware,
    thunk,
    loggingMiddleware,
    reapplyPriceListsToCachedProducts,
  ];
  const plugins = [autoLogin, tabTitle, localDb, pendingRequests];

  const store = createStore(
    rootReducer,
    composeWithDevTools(devtoolsOptions)(applyMiddleware(...middleWare)),
  );
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers/index').default; // eslint-disable-line global-require

      store.replaceReducer(nextReducer);
    });
  }

  plugins.forEach(extra => extra(store));

  return store;
};

export default configStore;
