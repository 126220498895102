import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import SearchIcon from '@material-ui/icons/Search';
import {
  Box,
  Button,
  TextField,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  withStyles,
} from '@material-ui/core';

import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { fetchOrderSales } from 'actions/sales';
import CloseButton from 'components/CustomButtons/CloseButton';
import { dismissAll } from 'actions/Error';
import {
  getDateFormatter,
  getSetting,
  getCurrencyFormatterNoSymbol,
} from 'reducers/configs/settings';
import { getSelectedPos } from 'reducers/PointsOfSale';
import Icon from 'components/Icon';
import { printReceipt } from 'actions/integrations/printer';
import { RootState } from 'reducers';
import { SaleDocumentResponse } from 'types/SalesDocument';

const ScrollableTableContainer = withStyles({
  root: {
    flexGrow: 1,
    marginTop: '1rem',
    '& th, & td': { padding: '0.75rem 0.5rem' },
    '& th': {
      fontWeight: 700,
    },
  },
})(TableContainer);

const NoRecordsTableRow: React.FC<{ children?: never }> = () => {
  const { t } = useTranslation('order');
  return (
    <TableRow>
      <TableCell data-testid="no-orders" colSpan={6}>
        {t('load.noneFound')}
      </TableCell>
    </TableRow>
  );
};

const PickupOrders: React.FC<{
  children?: never;
  sales: SaleDocumentResponse[];
}> = ({ sales = [] }) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { t } = useTranslation('order');
  const [pickupOrders, setPickupOrders] = useState(sales);
  const [value, setValue] = useState('');
  const onlyCurrentLoc = useSelector(
    getSetting('touchpos_orders_only_from_current_location'),
  );
  // @ts-ignore
  const { warehouseID } = useSelector(getSelectedPos);

  const searchSale = async searchVal => {
    // if searchVal is null or consists of spaces set to initial sales, passed to the pickupOrders function
    if (!searchVal || !searchVal.trim().length) {
      setPickupOrders(sales);
    } else {
      dispatch(
        fetchOrderSales({
          numberOrCustomer: searchVal.trim(),
          warehouseID: onlyCurrentLoc ? warehouseID : undefined,
        }),
      ).then(setPickupOrders);
    }
  };

  // @ts-ignore
  const print = async ({ order = true, sale } = {}) => {
    // @ts-ignore
    dispatch(printReceipt({ ...sale }, { order }));
  };

  useDebounce(
    () => {
      searchSale(value);
    },
    1000,
    [value],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
  };

  const close = () => {
    dispatch(dismissAll());
    dispatch(closeModalPage());
  };

  const selectOrder = (sale: SaleDocumentResponse) =>
    dispatch(
      openModalPage({
        component: modalPages.orderActionSelection,
        isPopup: true,
        props: {
          sale,
        },
      }),
    );

  const formatDate = useSelector(getDateFormatter);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);

  return (
    <Box
      overflow="hidden"
      display="flex"
      flexDirection="column"
      height="100%"
      padding="1.25rem"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t('load.title')}</Typography>
        <CloseButton action={close} />
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        style={{ margin: '1rem 0' }}
        data-testid="pickup-order-search"
        label={t('load.fields.number', { context: 'placeholder' })}
        value={value}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <Button
              onClick={() => searchSale(value)}
              data-testid="pickup-order-search-submit"
            >
              <SearchIcon />
            </Button>
          ),
        }}
      />
      <ScrollableTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('load.headers.number')}</TableCell>
              <TableCell>{t('load.headers.date')}</TableCell>
              <TableCell>{t('load.headers.customer')}</TableCell>
              <TableCell>{t('load.headers.email')}</TableCell>
              <TableCell>{t('load.headers.paid')}</TableCell>
              <TableCell>{t('load.headers.total')}</TableCell>
              <TableCell>{t('load.headers.print')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pickupOrders.length ? (
              pickupOrders.map(r => (
                <TableRow
                  key={r.id}
                  data-testid="order-row"
                  data-test-key={r.number}
                  onClick={() => selectOrder(r)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell data-testid="number">{r.number}</TableCell>
                  <TableCell data-testid="date">{formatDate(r.date)}</TableCell>
                  <TableCell data-testid="client">{r.clientName}</TableCell>
                  <TableCell data-testid="email">
                    {r.clientEmail || ''}
                  </TableCell>
                  <TableCell data-testid="paid">
                    {CURR.stringify(r.paid ?? 0)}
                  </TableCell>
                  <TableCell data-testid="total">
                    {CURR.stringify(r.total)}
                  </TableCell>
                  <TableCell>
                    <span>
                      <Icon
                        invert
                        name="icon_printer"
                        action={e => {
                          print({ sale: r });
                          e.stopPropagation();
                        }}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoRecordsTableRow />
            )}
          </TableBody>
        </Table>
      </ScrollableTableContainer>
    </Box>
  );
};

export default PickupOrders;
