import React, { ChangeEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getShowExpected } from 'reducers/OpenCloseDay';
import { getCurrencyFormatterNoSymbol } from 'reducers/configs/settings';

type Props = {
  counted: number;
  tenders: number;
  notes: string;
  onChangeNotes: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onConfirm: () => void;
  onCancel: () => void;
  closingDayNotesMandatory: boolean;
  allCashAmountsCovered: boolean;
};

const Confirmation: React.FC<Props> = ({
  counted,
  tenders,
  notes,
  onChangeNotes,
  onConfirm,
  onCancel,
  closingDayNotesMandatory,
  allCashAmountsCovered,
}) => {
  const CURR = useSelector(getCurrencyFormatterNoSymbol);
  const showExpected = useSelector(getShowExpected);
  const { t: preT } = useTranslation('openCloseDay');
  const t = (text, options = {}) => preT(`closeDay.confirm.${text}`, options);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <div data-testid="confirmation">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="8px"
        paddingTop="2px"
        marginX="14px"
      >
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="nowrap"
          paddingTop="1em"
        >
          <Box
            component="span"
            fontSize="1.75em"
            fontWeight={700}
            data-testid="register-drawer-title"
          >
            {t('title')}
          </Box>
        </Box>
        <Box display="flex" paddingTop="1em">
          <SaveButton
            data-testid="cancel"
            text={t('buttons.cancel')}
            variant="POS"
            action={onCancel}
          />
          <SaveButton
            text={t('buttons.confirm')}
            variant="POS"
            data-testid="confirm"
            action={onConfirm}
            disabled={
              closingDayNotesMandatory &&
              !allCashAmountsCovered &&
              !notes.trim().length
            }
          />
          <CloseButton data-testid="close-btn" action={onCancel} />
        </Box>
      </Box>
      <Box padding="1rem">
        {showExpected ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  data-testid="counted-header"
                  style={{ fontWeight: 600 }}
                >
                  {t('headers.cash')}
                </TableCell>
                <TableCell
                  data-testid="counted-value"
                  style={{ textAlign: 'right' }}
                >
                  {CURR.stringify(counted)}
                </TableCell>
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell
                  data-testid="other-header"
                  style={{ fontWeight: 600 }}
                >
                  {t('headers.other')}
                </TableCell>
                <TableCell
                  data-testid="not-counted"
                  style={{ textAlign: 'right' }}
                >
                  {tenders > 0 ? CURR.stringify(tenders) : t('notCounted')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
        <TextField
          inputRef={inputRef}
          variant="outlined"
          fullWidth
          multiline
          minRows={12}
          maxRows={12}
          data-testid="notes"
          placeholder={t('fields.notes', { context: 'placeholder' })}
          onChange={onChangeNotes}
        >
          {notes}
        </TextField>
      </Box>
    </div>
  );
};

export default Confirmation;
