import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmail, isMobilePhone, matches } from 'validator';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  getJobTitles,
  getPersonTitles,
  getSearchCustomerGroups,
} from 'reducers/customerSearch';
import { getAllHomeStores } from 'reducers/warehouses';
import { PersonTitle } from 'types/Customer';
import { PluginComponent } from 'plugins';
import { Address } from 'types/Address';
import Checkbox from 'components/Checkbox';
import Autocomplete from 'components/Autocomplete';

import MultiAddressInput from '../../components/AddressFormBeta/MultiAddressForm';

import { Field } from './components';
import { getGroupOptionsWithNameHierarchy, getBirthdayError } from './utils';

const PersonForm = ({ customer, handleChange, onChange }) => {
  const { t } = useTranslation('createCustomer');
  const personTitles: PersonTitle[] = useSelector(getPersonTitles);
  const jobTitles: PersonTitle[] = useSelector(getJobTitles);
  const homeStores = useSelector(getAllHomeStores);

  const customerGroups = useSelector(getSearchCustomerGroups);
  const groupOptionsWithNameHierarchy = useMemo(
    () => getGroupOptionsWithNameHierarchy(customerGroups),
    [customerGroups],
  );

  const anyPhoneNumberRegex = new RegExp(
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  );

  return (
    <Grid container spacing={2}>
      <PluginComponent
        hookname="UICustomerFormBeta"
        props={{ customerType: 'PERSON', customer, handleChange, onChange }}
      >
        <Field
          name="firstName"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            data-testid="field"
            data-test-key="firstName"
            name="firstName"
            variant="outlined"
            label={t('fields.firstName')}
            fullWidth
            value={customer.firstName ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="lastName"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            data-testid="field"
            data-test-key="lastName"
            name="lastName"
            variant="outlined"
            label={t('fields.lastName')}
            fullWidth
            value={customer.lastName ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field name="code" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="code"
            name="code"
            fullWidth
            variant="outlined"
            label={t('fields.code')}
            error={
              customer.code && !matches(customer.code, /^[a-zA-Z0-9-_]+$/gi)
            }
            helperText={
              customer.code &&
              !matches(customer.code, /^[a-zA-Z0-9-_]+$/gi) &&
              t('errorText.invalidInput')
            }
            value={customer.code ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="personTitleID"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            data-testid="field"
            data-test-key="personTitleID"
            name="personTitleID"
            label={t('fields.personTitleID')}
            variant="outlined"
            fullWidth
            value={customer.personTitleID ?? ''}
            onChange={handleChange}
          >
            <MenuItem value="">Select option</MenuItem>
            {personTitles.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Field>
        <Field
          name="gender"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            label={t('fields.gender')}
            variant="outlined"
            data-testid="field"
            data-test-key="gender"
            name="gender"
            fullWidth
            value={(customer.gender ?? '').toLowerCase()}
            onChange={handleChange}
          >
            <MenuItem value="">Select option</MenuItem>
            <MenuItem value="female">FEMALE</MenuItem>
            <MenuItem value="male">MALE</MenuItem>
          </TextField>
        </Field>
        <Field
          name="jobTitleID"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            label={t('fields.jobTitleID')}
            data-testid="field"
            data-test-key="jobTitleID"
            name="jobTitleID"
            variant="outlined"
            fullWidth
            value={customer.jobTitleID || ''}
            onChange={handleChange}
          >
            <MenuItem value="">Select option</MenuItem>
            {jobTitles.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Field>
        <Field
          name="customerCardNumber"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            data-testid="field"
            data-test-key="customerCardNumber"
            name="customerCardNumber"
            variant="outlined"
            label={t('fields.customerCardNumber')}
            fullWidth
            value={customer.customerCardNumber ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="euCustomerType"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            label={t('fields.euCustomerType')}
            data-testid="field"
            data-test-key="euCustomerType"
            name="euCustomerType"
            variant="outlined"
            fullWidth
            value={customer.euCustomerType ?? ''}
            onChange={handleChange}
          >
            <MenuItem value="">Select option</MenuItem>
            <MenuItem value="DOMESTIC">DOMESTIC</MenuItem>
            <MenuItem value="EU">EU</MenuItem>
            <MenuItem value="OUTSIDE_EU">OUTSIDE_EU</MenuItem>
          </TextField>
        </Field>
        <Field
          name="groupID"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            data-testid="field"
            data-test-key="groupID"
            name="groupID"
            fullWidth
            label={t('fields.groupID')}
            variant="outlined"
            value={customer.groupID ?? ''}
            onChange={handleChange}
          >
            {groupOptionsWithNameHierarchy.map(({ name, value }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Field>
        <Field
          name="homeStoreID"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <Autocomplete
            disablePortal
            id="homeStoreID"
            options={homeStores.map(store => store.warehouseID)}
            getOptionSelected={(option, value) =>
              Number(option) === Number(value)
            }
            inputProps={{
              'data-testid': 'field',
              'data-test-key': 'homeStoreID',
              label: t('fields.homeStoreID'),
              variant: 'outlined',
              fullWidth: true,
            }}
            getOptionLabel={option => {
              const selectedStore = homeStores.find(
                store => Number(store.warehouseID) === Number(option),
              );
              return selectedStore ? selectedStore.name : '';
            }}
            value={customer.homeStoreID}
            onChange={(event, newValue) => {
              onChange(customer => ({
                ...customer,
                homeStoreID: Number(newValue),
              }));
            }}
          />
        </Field>
        <Field name="email" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="email"
            name="email"
            fullWidth
            variant="outlined"
            label={t('fields.email')}
            error={customer.email && !isEmail(customer.email)}
            helperText={
              customer.email &&
              !isEmail(customer.email) &&
              t('errorText.invalidInput')
            }
            value={customer.email ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field name="phone" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="phone"
            name="phone"
            fullWidth
            variant="outlined"
            label={t('fields.phone')}
            error={
              customer.phone && !matches(customer.phone, anyPhoneNumberRegex)
            }
            helperText={
              customer.phone &&
              !matches(customer.phone, anyPhoneNumberRegex) &&
              t('errorText.invalidInput')
            }
            value={customer.phone ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field name="fax" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            data-testid="field"
            data-test-key="fax"
            name="fax"
            fullWidth
            variant="outlined"
            label={t('fields.fax')}
            error={customer.fax && !matches(customer.fax, anyPhoneNumberRegex)}
            helperText={
              customer.fax &&
              !matches(customer.fax, anyPhoneNumberRegex) &&
              t('errorText.invalidInput')
            }
            value={customer.fax ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="mobile"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            data-testid="field"
            data-test-key="mobile"
            name="mobile"
            fullWidth
            variant="outlined"
            label={t('fields.mobile')}
            error={customer.mobile && !isMobilePhone(customer.mobile)}
            helperText={
              customer.mobile &&
              !isMobilePhone(customer.mobile) &&
              t('errorText.invalidInput')
            }
            value={customer.mobile ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="birthday"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            type="date"
            label={t('fields.birthday')}
            value={customer.birthday}
            InputLabelProps={{
              shrink: true,
            }}
            name="birthday"
            data-testid="field"
            data-test-key="birthday"
            fullWidth
            variant="outlined"
            error={getBirthdayError(customer.birthday || null)}
            onChange={handleChange}
          />
        </Field>
        <MultiAddressInput
          key="addresses"
          onChange={addresses =>
            onChange(prev => ({
              ...prev,
              addresses:
                typeof addresses === 'function'
                  ? (addresses as (prevAddresses: Address[]) => Address[])(
                      prev.addresses,
                    )
                  : addresses,
            }))
          }
          customer={customer}
        />
        <Field
          name="notes"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <TextField
            data-testid="field"
            data-test-key="notes"
            name="notes"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            label={t('fields.notes')}
            value={customer.notes ?? ''}
            onChange={handleChange}
          />
        </Field>
        <Field
          name="emailOptOut"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            data-testid="field"
            data-test-key="emailOptOut"
            name="emailOptOut"
            value={!!customer.emailOptOut}
            onChange={handleChange}
            label={t('fields.emailOptOut')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
        <Field
          name="taxExempt"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            data-testid="field"
            data-test-key="taxExempt"
            name="taxExempt"
            value={!!customer.taxExempt}
            onChange={handleChange}
            label={t('fields.taxExempt')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
        <Field
          name="doNotSell"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            data-testid="field"
            data-test-key="doNotSell"
            name="doNotSell"
            value={!!customer.doNotSell}
            onChange={handleChange}
            label={t('fields.doNotSell')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
        <Field
          name="salesBlocked"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 12 }}
        >
          <Checkbox
            id="salesBlocked"
            name="salesBlocked"
            value={!!customer.salesBlocked}
            onChange={handleChange}
            label={t('fields.salesBlocked')}
            formControlProps={{ fullWidth: true }}
          />
        </Field>
      </PluginComponent>
    </Grid>
  );
};

export default PersonForm;
