import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';

import { sleep } from 'utils';

type Props = {
  testAction?: (() => Promise<string>) | null;
  text: string;
  helperText?: string;
  disabled?: boolean;
  children?: never;
};

const TestButton: React.FC<Props> = ({
  testAction = null,
  text,
  disabled = false,
  helperText,
}) => {
  const [testMessage, updateMessage] = useState('');
  const [displayMessage, toggleMessage] = useState(false);
  const handleClick = async () => {
    if (testAction !== null) {
      const outcome = await testAction();
      updateMessage(outcome);
      toggleMessage(true);
      await sleep(2);
      toggleMessage(false);
    }
  };

  return (
    <>
      {testAction !== null ? (
        <Button
          color="secondary"
          variant="contained"
          fullWidth
          disabled={disabled}
          onClick={handleClick}
        >
          {text}
        </Button>
      ) : null}
      {helperText ? (
        <Typography variant="subtitle1" align="center">
          {helperText}
        </Typography>
      ) : null}
      {displayMessage ? (
        <Typography
          align="center"
          style={{
            marginTop: '15px',
            marginBottom: '15px',
            fontSize: '16px',
            fontWeight: 900,
          }}
        >
          {testMessage}
        </Typography>
      ) : null}
    </>
  );
};
export default TestButton;
