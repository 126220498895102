import React, { ChangeEvent, FC, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { makeStyles } from '@material-ui/styles';
import { assocPath, difference, dissocPath } from 'ramda';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getIsLoadingCafa, getScanningAlgorithm } from 'reducers/cafaConfigs';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import Loader from 'components/Loader';
import {
  ScanningAlgorithmMiniStep,
  ScanningAlgorithmStep,
} from 'actions/scanner';
import { addWarning } from 'actions/Error';
import { INTEGRATION_TYPES } from 'constants/CAFA';

const MINI_STEP_BY_OPTIONS: Record<
  ScanningAlgorithmMiniStep['request'],
  ScanningAlgorithmMiniStep['by'][]
> = {
  customers: ['searchNameIncrementally', 'searchCode', 'searchRegistryCode'],
  products: [
    'searchNameIncrementally',
    'fullTextSearchPhrase',
    'name',
    'code',
    'code2',
    'code3',
    'supplierCode',
    'code5',
    'code6',
    'code7',
    'code8',
  ],
};

const MINI_STEP_REQUEST_OPTIONS: ScanningAlgorithmMiniStep['request'][] = [
  'products',
  'customers',
];

const noResultsHandlerOptions = ['notification', 'popup', 'creation'];

const SelectRequestOption: FC<{
  current: string;
  handleChange: (e) => void;
}> = ({ current, handleChange }) => {
  return (
    <Select
      labelId="scanning-step-select-request"
      value={current}
      onChange={handleChange}
      style={{ width: '100%' }}
    >
      <MenuItem value="" disabled>
        <em>Select</em>
      </MenuItem>
      {MINI_STEP_REQUEST_OPTIONS.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

const SelectByPropOption: FC<{
  current: string;
  handleChange: (e) => void;
  request: ScanningAlgorithmMiniStep['request'];
}> = ({ current, handleChange, request }) => {
  return (
    <Select
      labelId="scanning-step-select-request"
      value={current}
      onChange={handleChange}
      style={{ width: '100%' }}
    >
      <MenuItem value="" disabled>
        <em>Select</em>
      </MenuItem>
      {MINI_STEP_BY_OPTIONS[request].map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeStyles({
  accordionSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  longButton: {
    width: '100%',
  },
});

const Scanner = () => {
  const { t } = useTranslation('settingsScanner');
  const dispatch = useDispatch();
  const loadingCafa = useSelector(getIsLoadingCafa);
  const {
    steps: scanningAlgorithm,
    noResultsHandler,
    minScanLength,
    avgTimeByChar,
    timeBeforeScanTest,
  } = useSelector(getScanningAlgorithm);
  const classes = useStyles();

  const [steps, setSteps] = useState(scanningAlgorithm);
  const [noResultsHandlerState, setNoResultsHandlerState] = useState(
    noResultsHandler,
  );

  const [scanLength, setScanLength] = useState(minScanLength ?? 2);
  const [timePerChar, setTimeByChar] = useState(avgTimeByChar ?? 100);
  const [timeBeforeScan, setTimeBeforeScan] = useState(
    timeBeforeScanTest ?? 100,
  );

  const onClose = () => {
    dispatch(previousModalPage());
  };

  const onSave = () => {
    const stepsAreValid = steps.every(
      step =>
        step.length && step.every(miniStep => miniStep.request && miniStep.by),
    );
    if (
      stepsAreValid &&
      scanLength > 0 &&
      timePerChar > 0 &&
      timeBeforeScan > 0
    ) {
      dispatch(
        saveCafaConfig({
          integrationName: 'scanning_algorithm',
          integrationType: INTEGRATION_TYPES.scanner,
          level: { level: 'Company', id: undefined },
          config: {
            steps,
            noResultsHandler: noResultsHandlerState,
            minScanLength: scanLength,
            avgTimeByChar: timePerChar,
            timeBeforeScanTest: timeBeforeScan,
          },
        }),
      )
        // @ts-ignore
        .then(onClose);
    } else {
      dispatch(addWarning(t('warnings.misconfiguredAlgoSteps')));
    }
  };

  const addStep = () => {
    const newBasicStep: ScanningAlgorithmStep = [
      { request: 'products', by: 'code' },
    ];
    setSteps(steps.concat([newBasicStep]));
  };

  const addMiniStep = (stepIndex: number) => {
    const step = steps[stepIndex];
    const newMiniStep = {} as ScanningAlgorithmMiniStep;

    const [unusedRequestInStep] = difference(
      MINI_STEP_REQUEST_OPTIONS,
      step.map(({ request }) => request),
    );
    newMiniStep.request = unusedRequestInStep ?? 'products';
    const [unusedByPropInStep] = unusedRequestInStep
      ? MINI_STEP_BY_OPTIONS[unusedRequestInStep]
      : difference(
          MINI_STEP_BY_OPTIONS[newMiniStep.request],
          step.map(({ by }) => by),
        );
    newMiniStep.by = unusedByPropInStep;
    if (!unusedByPropInStep) {
      dispatch(addWarning(t('warnings.duplicateRequestInAlgoStep')));
      return;
    }

    setSteps(assocPath([stepIndex, step.length], newMiniStep));
  };

  const handleMiniStepChange = (
    e: ChangeEvent<HTMLSelectElement>,
    stepIndex,
    miniStepIndex,
    miniStepProp: keyof ScanningAlgorithmMiniStep,
  ) => {
    const currentStep = steps[stepIndex];
    const currentMiniStep = currentStep[miniStepIndex];
    const { value } = e.target;
    if (
      miniStepProp === 'by' &&
      currentStep.some(
        ms => ms.request === currentMiniStep.request && ms.by === value,
      )
    ) {
      dispatch(addWarning(t('warnings.duplicateRequestInAlgoStep')));
      return;
    }
    const updatedMiniStep =
      miniStepProp === 'request'
        ? { request: e.target.value, by: '' }
        : { request: currentMiniStep.request, by: e.target.value };

    setSteps(assocPath([stepIndex, miniStepIndex], updatedMiniStep) as any);
  };

  const handleNoResultHandlerChange = e => {
    setNoResultsHandlerState(e.target.value);
  };

  return (
    <>
      <Modal.Header>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('title')}
        </span>
        <span style={{ flexGrow: 1 }} />
        <SaveButton action={onSave} variant="POS" />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <h2>{t('subTitle')}</h2>
          </Grid>
          <Grid item>
            <Loader block show={loadingCafa && false}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <h4>{t('noResultsHandler.title')}</h4>
                </Grid>
                <Grid item>
                  <Box
                    component="div"
                    whiteSpace="pre-wrap"
                    color="textPrimary"
                  >
                    {t('noResultsHandler.description')}
                  </Box>
                </Grid>
                <Grid item>
                  <Select
                    labelId="scanning-no-result-handler"
                    value={noResultsHandlerState}
                    onChange={handleNoResultHandlerChange}
                    style={{ width: '100%' }}
                  >
                    <MenuItem value="" disabled>
                      <em>{t('select')}</em>
                    </MenuItem>
                    {noResultsHandlerOptions.map(option => (
                      <MenuItem key={option} value={option}>
                        {t(`noResultsHandler.${option}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <h4>{t('minScanLabel')}</h4>
                  <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 2,
                        style: { textAlign: 'right' },
                      },
                    }}
                    onChange={e => setScanLength(Number(e.target.value))}
                    value={scanLength}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4>{t('avgTimeByChar')}</h4>
                  <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 1,
                        style: { textAlign: 'right' },
                      },
                    }}
                    onChange={e => setTimeByChar(Number(e.target.value))}
                    value={timePerChar}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h4>{t('timeBeforeScanTest')}</h4>
                  <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 1,
                        style: { textAlign: 'right' },
                      },
                    }}
                    onChange={e => setTimeBeforeScan(Number(e.target.value))}
                    value={timeBeforeScan}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <h4>{t('scanningAlgorithm.title')}</h4>
                </Grid>
                <Grid item>
                  <Box
                    component="div"
                    whiteSpace="pre-wrap"
                    color="textPrimary"
                  >
                    {t('scanningAlgorithm.description')}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <Box component="div" whiteSpace="pre-wrap">
                      {t('scanningAlgorithm.defaultBehaviourInfo')}
                    </Box>
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  {steps.map((step, stepIndex) => (
                    <Accordion key={stepIndex}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className={classes.accordionSummary}>
                          <Typography>
                            {t('scanningAlgorithm.stepHeader', {
                              index: stepIndex + 1,
                            })}
                          </Typography>
                          <ButtonBase>
                            <DeleteOutlinedIcon
                              onClick={e => {
                                e.stopPropagation();
                                setSteps(dissocPath([stepIndex]) as any);
                              }}
                            />
                          </ButtonBase>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    {t('scanningAlgorithm.searchingFor')}
                                  </TableCell>
                                  <TableCell>
                                    {t('scanningAlgorithm.searchingFor')}
                                  </TableCell>
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {step.map((miniStep, miniStepIndex) => (
                                  <TableRow
                                    key={`${miniStep.request}-${miniStep.by}-${miniStepIndex}`}
                                  >
                                    <TableCell>
                                      <SelectRequestOption
                                        current={miniStep.request}
                                        handleChange={e =>
                                          handleMiniStepChange(
                                            e,
                                            stepIndex,
                                            miniStepIndex,
                                            'request',
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <SelectByPropOption
                                        current={miniStep.by}
                                        handleChange={e =>
                                          handleMiniStepChange(
                                            e,
                                            stepIndex,
                                            miniStepIndex,
                                            'by',
                                          )
                                        }
                                        request={miniStep.request}
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <ButtonBase>
                                        <DeleteOutlinedIcon
                                          onClick={e => {
                                            e.stopPropagation();
                                            setSteps(
                                              dissocPath([
                                                stepIndex,
                                                miniStepIndex,
                                              ]) as any,
                                            );
                                          }}
                                        />
                                      </ButtonBase>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.longButton}
                              onClick={e => addMiniStep(stepIndex)}
                            >
                              {t('scanningAlgorithm.addRequest')}
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    className={classes.longButton}
                    color="primary"
                    onClick={addStep}
                  >
                    {t('scanningAlgorithm.addStep')}
                  </Button>
                </Grid>
              </Grid>
            </Loader>
          </Grid>
        </Grid>
      </Modal.Body>
    </>
  );
};

export default Scanner;
