import { getClientCode } from 'reducers/Login';
import { getVatRates, saveVatRate } from 'services/ErplyAPI';

interface ErplyTaxRateApiForSuretax {
  getTaxRate(rate: number): Promise<number | null>;
  findTaxRate(
    net: number,
    vat: number,
    precision: number,
  ): Promise<number | null>;

  createTaxRate(rate: number): Promise<number>;
}

type TaxRate = {
  id: string;
  name: string;
  rate: string;
  [other: string]: string;
};
const taxCache = {} as Record<
  string,
  Promise<Record<string, TaxRate>> | Record<string, TaxRate>
>;

const loadTaxes = async (clientCode: string) => {
  if (taxCache[clientCode]) return taxCache[clientCode];

  // eslint-disable-next-line no-async-promise-executor
  taxCache[clientCode] = new Promise<Record<string, TaxRate>>(async resolve => {
    const rates = {};
    let i = 0;
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const res = await getVatRates({
        recordsOnPage: 1000,
        pageNo: i,
      });
      res.records.forEach(rate => {
        rates[Number(rate.rate).toFixed(5)] = rate;
      });
      if (res.status.recordsTotal <= 1000 * (i + 1)) break;
      i += 1;
    }
    taxCache[clientCode] = rates;
    resolve(rates);
  });
  return taxCache[clientCode];
};

const createTax = async (rate: number) => {
  taxCache[getClientCode(null)][rate] = new Promise(resolve => {
    saveVatRate({
      rate,
      cityTaxRate: 0,
      city: '',
      countyTaxRate: 0,
      county: '',
      stateTaxRate: 0,
      state: '',
      otherTaxRate: rate,
      otherTaxName: 'Suretax API',
      code: `ST${rate}`,
      name: `Auto-generated vat rate for suretax (${rate})`,
      ZIPCode: '',
      active: 0,
    })
      .then(({ vatRateID }) =>
        getVatRates({
          id: vatRateID,
          pageNo: 1,
          recordsOnPage: 1,
        }),
      )
      .then(({ records: [fullVat] }) => {
        taxCache[getClientCode(null)][rate] = fullVat;
        resolve(fullVat);
      });
  });
  return taxCache[getClientCode(null)][rate];
};

export const ErplyApi: ErplyTaxRateApiForSuretax = {
  getTaxRate: async (n: number) => {
    const taxes = await loadTaxes(getClientCode(null));
    // Erply API rounds tax rates to 5 decimal places
    const rounded = Number(n.toFixed(5));
    const existing = taxes[rounded];
    if (existing) return existing.id;
    return null;
  },
  /**
   * Find and return a tax rate, such that when applied to the provided net,
   * it would result in the provided vat (or at least closer than the given precision)
   *
   * @example
   * const exactTwenty = findTaxRate(100, 120, 0) // returns vat rate between 19.995% and 20.004999%
   * (100 * exactTwenty).toFixed(0) // guaranteed to be 20
   *
   * const exactTwelveCents  = findTaxRate(100, 100.12, 2) // returns vat rate between 0.115% and 0.124999%
   * (100 * exactTwelveCents).toFixed(2) // guaranteed to be 0.12
   */
  findTaxRate: async (net: number, vat: number, precision = 2) => {
    const taxes = await loadTaxes(getClientCode(null));
    return Object.values(taxes).find(tax => {
      const expected = net + vat;
      const actual = net * ((100 + Number(tax.rate)) / 100);
      return expected.toFixed(precision) === actual.toFixed(precision);
    })?.id;
  },
  createTaxRate: async (n: number) => {
    const newTax = await createTax(n);
    return newTax.id;
  },
} as any;
