import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Table } from 'react-bootstrap';

import { getProductGroups } from 'reducers/productGroupsDB';
import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { notUndefinedOrNull } from 'utils/tsHelpers';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

const ComponentConfiguration = ({ byLevel }) => {
  const dispatch = useDispatch();
  const { current = {}, save = () => {} } = byLevel?.company ?? {};
  const productGroups = useSelector(getProductGroups);

  const productGroupsDict = useMemo(
    () => Object.fromEntries(productGroups.map(pg => [pg.id, pg])),
    [productGroups],
  );

  const initialState = current?.coreGroupIDs?.length
    ? Object.fromEntries(
        current.coreGroupIDs
          .map(gr =>
            productGroupsDict[gr] ? [gr, productGroupsDict[gr]] : undefined,
          )
          .filter(notUndefinedOrNull),
      )
    : {};
  const [selectedPG, setSelectedPG] = useState(initialState);
  const [searchVal, setSearchVal] = useState('');

  const handleChange = e => {
    const { name } = e.target;
    const newState = { ...selectedPG };
    if (newState[name]) {
      delete newState[name];
    } else {
      newState[name] = productGroupsDict[name];
    }
    setSelectedPG(newState);
  };

  const saveConf = () => {
    save({ coreGroupIDs: Object.keys(selectedPG) });
    dispatch(previousModalPage());
  };

  return (
    <div>
      <Alert variant="warning">
        Currently, Core product groups can only be configured by Company level.
      </Alert>
      <div className="d-flex align-items-center justify-content-between">
        <InputField
          onChange={e => setSearchVal(e.target.value)}
          value={searchVal}
        />
        <UIButton variant="POS" text="Save" action={saveConf} />
      </div>
      <Table>
        <thead>
          <tr>
            <th>Enable</th>
            <th>Group name</th>
          </tr>
        </thead>
        <tbody>
          {productGroups
            .filter(pg =>
              searchVal.trim().length
                ? pg.name.toUpperCase().includes(searchVal.toUpperCase())
                : true,
            )
            .map(pg => (
              <tr>
                <td>
                  <InputField
                    type="checkbox"
                    onChange={handleChange}
                    name={pg.id}
                    value={!!selectedPG[pg.id]}
                  />
                </td>
                <td>{pg.name}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ComponentConfiguration;
