export enum TaxGrouping {
  /** Tax grouped by line items. */
  T_00 = '00',
  /** Tax grouped by source geocode and invoice number. */
  T_01 = '01',
  /** Tax grouped by source geocode and customer number. */
  T_02 = '02',
  /** Tax grouped by source geocode, invoice number, and customer number. */
  T_03 = '03',
  /** Tax grouped by state code, geocode, and invoice number. */
  T_11 = '11',
  /** Tax grouped by state code, geocode, and customer number. */
  T_12 = '12',
  /** Tax grouped by state code, geocode, invoice number, and customer number. */
  T_13 = '13',
  /** Tax grouped by state code and invoice number. */
  T_21 = '21',
  /** Tax grouped by state code and customer number. */
  T_22 = '22',
  /** Tax grouped by state code, invoice number, and customer number. */
  T_23 = '23',
}

export const taxGroupingBy = ({
  sourceGeocode = false,
  invoiceNumber = false,
  customerNumber = false,
  stateCode = false,
}): TaxGrouping => {
  let value = 0;
  if (!sourceGeocode) value += 10;
  if (stateCode) value += 10;
  if (customerNumber) value += 2;
  if (invoiceNumber) value += 1;

  const enumValue = TaxGrouping[`T_${value}`];
  if (!enumValue) throw new Error('No such tax grouping possible');
  return enumValue;
};
