import React, { useState } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useInterval } from 'react-use';
import { useSelector } from 'react-redux';

import { getPluginConfigurationAtLevel } from 'reducers/Plugins';

import { Configuration } from '../types';
import { pluginID } from '../constants';

// @ts-ignore
import { ReactComponent as PusherLogo } from './logo.svg';
import { usePusherNotifications } from './connection';
import { OrderNotificationConfig } from './types';

const style = { height: 24, width: 24, color: 'white' };

const OrderNotificationHeaderComponent = ({
  credentials,
}: {
  credentials: OrderNotificationConfig;
}) => {
  usePusherNotifications(credentials);

  return (
    <IconButton title="Connected to Pusher for order notifications">
      <PusherLogo style={style} />
    </IconButton>
  );
};

/**
 * Shows a loading spinner for a while before *actually* rendering the component
 * This allows us to use a component that doesn't want to be rapidly unmounted/remounted into a context where that would otherwise happen
 *
 * For example, a component that connects and subscribes to pusher, mounted in the header where it would normally connect and disconnect multiple times as CAFA is slowly loaded
 *
 * NB: This causes a delay before the component actually starts working
 */
const DebouncedComponent = ({
  children,
  time,
  sections,
}: React.PropsWithChildren<{
  time: number;
  sections: number;
}>) => {
  const [timer, setTimer] = useState(0);
  const timePerSection = time / sections;
  const isDone = timer >= sections;
  useInterval(() => setTimer(t => t + 1), isDone ? Infinity : timePerSection);

  if (isDone) return <>{children}</>;
  return (
    <CircularProgress
      variant="determinate"
      thickness={10}
      size={24}
      value={(timer / sections) * 100}
    />
  );
};

const isFullCredentials = (
  creds: Partial<OrderNotificationConfig> | undefined,
): creds is OrderNotificationConfig => {
  return Boolean(creds?.key && creds?.cluster);
};

export default () => {
  const config = useSelector(
    getPluginConfigurationAtLevel<Configuration>(pluginID, 'Company', ''),
  );
  if (!config) return null;
  if (!isFullCredentials(config?.orderNotification)) return null;
  return (
    <DebouncedComponent sections={100} time={5e3}>
      <OrderNotificationHeaderComponent
        credentials={config.orderNotification}
      />
    </DebouncedComponent>
  );
};
