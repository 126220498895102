import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getCurrentSalesDocument, getIsAReturn } from 'reducers/sales';

import { EA_SALE } from '../constants';

export const onSaveSalesDocument: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, ap) => async (dispatch, getState) => {
  const { type } = getCurrentSalesDocument(getState());
  const isReturn = getIsAReturn(getState());
  if (type !== EA_SALE || isReturn) return ap;

  return R.map(
    R.when(
      R.propEq('requestName', 'saveSalesDocument'),
      R.assoc('type', 'CASHINVOICE'),
    ),
  )(ap);
};
