import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import ListItem from 'components/ListItem';
import { useBreakpoints } from 'utils/hooks/UI';
import UIButton from 'components/UIElements/UIButton';
import {
  getBalance,
  getIsPartialPaymentAllowed,
  getIsEligibleForCashRounding,
  getChange,
  getIsReturnPayment,
} from 'reducers/Payments';
import {
  processPayments,
  updateCurrency,
  askForCheckNumbers,
} from 'actions/Payments';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import {
  getDefaultCurrency,
  getUseAgeVerification,
} from 'reducers/configs/settings';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { PluginComponent } from 'plugins';
import { getCurrencyByCode } from 'reducers/configs/currency';
import { useAsyncFunctionNoParallelExecution } from 'utils/hooks/useSingleUseFunction';

const ConfirmPayment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('payment');
  const isMobileView = !useBreakpoints().mdplus;
  const isReturnPayment = useSelector(getIsReturnPayment);
  const balance = useSelector(getBalance);
  const change = useSelector(getChange);
  const useAgeVerification = useSelector(getUseAgeVerification);
  const defaultCurrencyCode = useSelector(getDefaultCurrency);
  const defaultCurrency = useSelector(getCurrencyByCode(defaultCurrencyCode));

  const isEligibleForCashRounding = useSelector(getIsEligibleForCashRounding);

  const isPartialPaymentAllowed = useSelector(getIsPartialPaymentAllowed);

  const shouldConfirmBeDisabled = useMemo(() => {
    if (isPartialPaymentAllowed) return false;

    // If eligible for rounding, then change not being negative means the customer is close enough to the total
    // otherwise, they must reach the total perfectly so we check the unrounded balance instead
    // i.e.
    //   Total | Payments | Allow
    //   5.25    4.00       Block regardless because balance is -1.25 and change is -1.00
    //   5.25    5.00       Allow if eligible for rounding, because change is -0.00
    //   5.25    6.00       Allow always because customer has overpaid
    const compare = isEligibleForCashRounding ? change : balance;
    return isReturnPayment
      ? 0 < compare // For returns, underpayment is when change/balance is positive
      : compare < 0; // For sales, underpayment is when change/balance is negative
  }, [
    isPartialPaymentAllowed,
    isEligibleForCashRounding,
    isReturnPayment,
    balance,
    change,
  ]);

  const [
    confirmHandler,
    { isUsed: isConfirming },
  ] = useAsyncFunctionNoParallelExecution(async () => {
    // if user has entered foreign currency and skipped pressing OK,
    // update the currency back to default before payment is processed
    await dispatch(updateCurrency(defaultCurrency));

    if (useAgeVerification) {
      await dispatch(
        openModalPage({
          component: mp.ageVerification,
          isPopup: true,
          props: {
            setPaymentSelected,
            processPayments,
          },
        }),
      );
      return;
    }
    if (!shouldConfirmBeDisabled) {
      await dispatch(askForCheckNumbers);
      await dispatch(setPaymentSelected(''));
      await dispatch(processPayments());
      setTimeout(() => localStorage.removeItem('selectedReason'), 10000);
    }
  });

  useShortcut('F8', confirmHandler, 20);

  return isMobileView ? (
    <PluginComponent
      hookname="UIMobilePaymentsModalPayButton"
      props={{
        disabled: shouldConfirmBeDisabled,
        confirmHandler,
      }}
    >
      <UIButton
        disabled={shouldConfirmBeDisabled || isConfirming}
        text={t('buttons.confirmPayment')}
        block={true}
        action={confirmHandler}
        className="confirm-payment"
      />
    </PluginComponent>
  ) : (
    <PluginComponent
      hookname="UIPaymentsModalPayButton"
      props={{
        disabled: shouldConfirmBeDisabled,
        confirmHandler,
      }}
    >
      <ListItem
        disabled={shouldConfirmBeDisabled || isConfirming}
        variant="grid_green"
        // @ts-ignore
        action={confirmHandler}
        data-testid="confirm-payment-button"
      >
        {/* @ts-ignore */}
        <>
          <span className="icon_check" />
          <span data-testid="confirm-payment-shortcut" className="func-key">
            F8
          </span>
        </>
      </ListItem>
    </PluginComponent>
  );
};

export default ConfirmPayment;
