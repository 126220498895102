import axios, { AxiosResponse } from 'axios';
import * as R from 'ramda';
import * as rxjs from 'rxjs';
import * as rxop from 'rxjs/operators';

import {
  CreatePaymentRequest,
  CreatePaymentResponse,
  GetPaymentStatusRequest,
  GetPaymentStatusResponse,
} from './types/schema';

const ax = axios.create({
  baseURL: 'https://erply.azure-api.net/',
});

export const createPayment = (
  data: CreatePaymentRequest,
  { url: baseURL, apiKey },
) =>
  ax.post<CreatePaymentRequest, AxiosResponse<CreatePaymentResponse>>(
    '/erply/payments',
    data,
    { baseURL, headers: { 'api-key': apiKey } },
  );

export const getPaymentStatus = (uuid: string, { url: baseURL, apiKey }) =>
  ax.get<GetPaymentStatusRequest, AxiosResponse<GetPaymentStatusResponse>>(
    `/erply/payment-status/${uuid}`,
    {
      baseURL,
      headers: { 'api-key': apiKey },
      validateStatus: R.always(true),
    },
  );

class MpesaPaymentStatusGeneric {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public data: GetPaymentStatusResponse,
    public remoteTransactionReference: string,
  ) {}
}
export class MpesaPaymentSuccess extends MpesaPaymentStatusGeneric {}
export class MpesaPaymentIndeterminate extends MpesaPaymentStatusGeneric {}
export class MpesaPaymentFailed extends MpesaPaymentStatusGeneric {}

export type MpesaPaymentStatus =
  | MpesaPaymentSuccess
  | MpesaPaymentIndeterminate
  | MpesaPaymentFailed;

const processPaymentReal$ = (
  pmt: CreatePaymentRequest,
  options,
): rxjs.Observable<MpesaPaymentStatus & {
  remoteTransactionReference: string;
}> => {
  const ref = pmt.remoteTransactionReference;
  const create = rxjs.defer(() => createPayment(pmt, options));
  const pingForStatus: rxjs.Observable<AxiosResponse<
    GetPaymentStatusResponse
  >> = rxjs.defer(() =>
    getPaymentStatus(pmt.remoteTransactionReference, options),
  );

  return create.pipe(
    rxop.catchError(err => rxjs.of(err)),
    rxop.switchMap(prev => {
      if (prev instanceof Error)
        return rxjs.of(
          new MpesaPaymentFailed(
            {
              status: 'FAILED',
              note: '',
              eventId: '',
              dateTime: '',
              message: prev.message,
            },
            ref,
          ),
        );
      return pingForStatus.pipe(
        rxop.repeatWhen(rxop.delay(1000)),
        rxop.retryWhen(rxop.delay(1000)),
        rxop.map(response => {
          switch (response.status) {
            /*
              Source: https://docs.google.com/spreadsheets/d/1xGNOZx-ZNm9QmoMI-nq-5UxEPNbPN-7AeQ4Y2D6784Q/edit#gid=130773340
              400 = FAILED (failed)
              308 = FAILED (expired)
              200 = FULFILLED
              402 = PENDING
            */
            case 400:
            case 308:
              return new MpesaPaymentFailed(response.data, ref);
            case 200:
              return new MpesaPaymentSuccess(response.data, ref);
            default:
              console.warn(
                'MPesa API returned unknown response status',
                response,
                'treating it as unknown (like 402)',
              );
            // fallthrough
            case 402:
              return new MpesaPaymentIndeterminate(response.data, ref);
          }
        }),
        rxop.takeWhile(
          R.is(MpesaPaymentIndeterminate),
          true, // also emit the fulfilled/failed status causing the observable to complete
        ),
      );
    }),
  );
};

const processPaymentMock$: typeof processPaymentReal$ = (pmt, options) => {
  const ref = pmt.remoteTransactionReference;
  /** Last three digits of phone number */
  const [a, b, c] = pmt.client.phoneNumber.split('').slice(-3);
  const stepsUntilDone = Number(b + c);
  const success = Number(a) < 5;
  return rxjs.interval(2000).pipe(
    rxop.delay(5000),
    rxop.map(n => {
      if (n === stepsUntilDone && n !== 99) {
        if (success) {
          return new MpesaPaymentSuccess(
            {
              dateTime: new Date().toISOString(),
              eventId: 'id',
              note: 'The payment was a mocked success',
              message:
                'If you can see this, then the mock implementation was accidentally left in - Please inform the developers immediately',
              status: 'successful',
            },
            ref,
          );
        }
        return new MpesaPaymentFailed(
          {
            dateTime: new Date().toISOString(),
            eventId: 'id',
            note: 'The payment was a mocked failure',
            message:
              'If you can see this, then the mock implementation was accidentally left in - Please inform the developers immediately',
            status: 'failure',
          },
          ref,
        );
      }
      return new MpesaPaymentIndeterminate(
        {
          dateTime: new Date().toISOString(),
          eventId: 'id',
          note: 'reticulating splines',
          message:
            'If you can see this, then the mock implementation was accidentally left in - Please inform the developers immediately',
          status: 'processing',
        },
        ref,
      );
    }),
    rxop.takeWhile(
      R.is(MpesaPaymentIndeterminate),
      true, // also emit the fulfilled/failed status causing the observable to complete
    ),
  );
};

// export const processPayment$ = processPaymentMock$;
export const processPayment$ = processPaymentReal$;
