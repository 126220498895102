import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Table from 'react-bootstrap/Table';
import uuid from 'uuid';
import { useTranslation } from 'react-i18next';

import {
  getDiscountReasonByOrderIndex,
  getGlobalDiscountReason,
  getIsProductNonDiscountable,
  getLastOrder,
  getSelectedOrder,
} from 'reducers/ShoppingCart';
import { modalPages, modalPages as mp } from 'constants/modalPage';
import tableStyles from 'assets/styles/table.module.scss';
import {
  getProductComponents,
  getProductUnitname,
} from 'reducers/cachedItems/products';
import {
  getCustomPromptsForProduct,
  getDiscountLimit,
  getSetting,
  getUISetting,
} from 'reducers/configs/settings';
import ProductInformation from 'components/ProductInformation';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import {
  clearDiscountReason,
  decreaseOrderAmount,
  increaseOrderAmount,
  setDiscountReason,
  setOrderEmployeeID,
  updateOrderDiscount,
} from 'actions/ShoppingCart';
import { PluginComponent } from 'plugins';
import { addWarning } from 'actions/Error';
import { getProductImages } from 'utils';
import {
  getCommissionable,
  getOtherCommissionable,
} from 'reducers/cachedItems/employees';
import useProducts from 'utils/hooks/useProducts';
import { getWeightedProductUnits } from 'reducers/productUnitsDB';
import Loader from 'components/Loader';
import { REASONS } from 'constants/reasonCodesDB';
import { Product } from 'types/Product';

import { Notes } from '../Notes';

import ProductImages from './productCarousel';
import ReturnDiscountButtons from './ReturnDiscountButtons';
import SerialNumberEditForm from './SerialNumberEditForm';

import './ProductForm.scss';

const ProductForm = () => {
  const { t } = useTranslation('shoppingCart');
  const dispatch = useDispatch();
  let order = useSelector(getSelectedOrder);
  const lastOrder = useSelector(getLastOrder);
  if (!order) {
    order = lastOrder;
  }
  const {
    products: [tempProduct],
  } = useProducts(
    {
      productID: order?.productID,
    },
    {
      addToCachedItems: true,
    },
  );
  /* 
    useProducts can return empty array, first element of which would be undefined.
    Type overridden via `as` since otherwise product would still have Product type
  */
  const product = tempProduct as Product | undefined;
  const recommendedCommissions = useSelector(getCommissionable);
  const nonRecommendedCommissions = useSelector(getOtherCommissionable);
  const autoCloseOnDiscountSelect = useSelector(
    getSetting('touchpos_autoclose_line_after_discount_selected'),
  );

  const images = useMemo(() => (product ? getProductImages(product) : []), [
    product,
  ]);
  const productUnitName = useSelector(getProductUnitname(order.productID));
  const productComponents = useSelector(
    useMemo(() => getProductComponents(order?.productID), [order?.productID]),
  );
  const {
    products: componentProducts,
    loading: loadingComponents,
  } = useProducts({ productIDs: productComponents.map(c => c.componentID) });

  const maxDiscount = useSelector(getDiscountLimit);
  const hideCommission = useSelector(getUISetting('hideProductCommission'));

  const globalDiscountReason = useSelector(getGlobalDiscountReason);

  const discountReasonID = useSelector(
    getDiscountReasonByOrderIndex(order.orderIndex),
  )?.reasonID;

  const isZeroPricedProduct =
    // Check for priceListPrice since a zero-priced (price = 0) product may be in a price list that has a price > 0
    product?.priceListPrice === 0 && Number(order.finalPrice) === 0;

  const reasonCodes = useSelector(getReasonCodes(REASONS.DISCOUNT));

  const updateDiscount = useCallback(
    value => {
      if (Number(value) === 0) {
        dispatch(clearDiscountReason(order.orderIndex));
      }

      if (reasonCodes.length > 1 && Number(value) > 0) {
        if (!discountReasonID && !globalDiscountReason) {
          dispatch(
            openModalPage({
              component: mp.discountPopup,
              isPopup: true,
              props: {
                reasonCodes,
                order,
                value,
              },
            }),
          );

          return;
        }
      }

      if (reasonCodes.length === 1 && !globalDiscountReason) {
        dispatch(setDiscountReason({ ...reasonCodes[0] }));
      }
      dispatch(updateOrderDiscount(Number(value), order.orderIndex));
      if (autoCloseOnDiscountSelect) {
        dispatch(previousModalPage());
      }
    },
    [updateOrderDiscount, order.orderIndex, discountReasonID],
  );
  const isProductNonDiscountable = useSelector(
    getIsProductNonDiscountable(order),
  );

  const units = useSelector(getWeightedProductUnits);

  const customPrompts = useSelector(
    getCustomPromptsForProduct(order?.productID),
  ) as string[];

  const shouldDisabledDiscountButtons =
    isProductNonDiscountable || isZeroPricedProduct;

  return (
    <div className="product-order-form" data-testid="product-order-form">
      <div
        className="product-header"
        style={images && images.length ? {} : { alignItems: 'flex-end' }}
      >
        {product && <ProductImages product={product} />}
        <div
          className="product-info"
          style={
            images && images.length
              ? { flexBasis: '40%' }
              : { flex: '1 1 100%' }
          }
        >
          <h2 data-testid="order-name">{`${order.name?.substr(0, 50)}`}</h2>
          <h4 data-testid="order-code">{order.code}</h4>
          <p
            style={
              order.description
                ? {
                    fontStyle: 'normal',
                    color: 'inherit',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    height: 'auto',
                  }
                : undefined
            }
            data-testid="order-description"
          >
            {order.description
              ? `${order.description}`
              : t('productForm.description_placeholder')}
          </p>
        </div>
      </div>
      <PluginComponent hookname="UIProductFormBody" props={order}>
        <div className="product-body">
          {product &&
            (!!customPrompts.length || !!product?.hasSerialNumbers) && (
              <SerialNumberEditForm product={product} order={order} />
            )}
          <div className="product-quantity">
            <PluginComponent
              hookname="UIQuantityActionsContainer"
              props={{
                product,
              }}
            >
              <>
                <div className="quantity-header">
                  <h3>{t('inputs.quantity')}</h3>
                </div>
                <div className="quantity-actions">
                  <Button
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    variant="grid_light"
                    onClick={e => {
                      e.preventDefault();
                      // eslint-disable-next-line eqeqeq
                      if (units.includes(productUnitName)) {
                        dispatch(
                          openModalPage({
                            component: modalPages.ProductWeight,
                            props: {
                              orderIndex: order.orderIndex,
                              unit: productUnitName,
                            },
                            modalClassName: 'product-weight-modal',
                            isPopup: true,
                          }),
                        );
                      } else {
                        dispatch(
                          addWarning(t('alerts.notWeightProduct'), {
                            selfDismiss: 3000,
                          }),
                        );
                      }
                    }}
                    disabled={!units.includes(productUnitName)}
                    data-testid="weight-btn"
                  >
                    <span className="icon_balance" />
                  </Button>
                  <Button
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    variant="grid_light"
                    onClick={() => {
                      dispatch(decreaseOrderAmount(order.orderIndex));
                    }}
                    data-testid="decrease-btn"
                    disabled={order.giftCardSerial}
                  >
                    <span className="icon_minus-06" />
                  </Button>
                  <span className="amount" data-testid="amount">
                    {Math.round(order.amount * 100) / 100 || 0}
                  </span>
                  <Button
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    variant="grid_light"
                    onClick={() =>
                      dispatch(increaseOrderAmount(order.orderIndex))
                    }
                    disabled={order.giftCardSerial}
                    data-testid="increase-btn"
                  >
                    <span className="icon_plus" />
                  </Button>
                </div>
              </>
            </PluginComponent>
          </div>
          <div className="product-discount">
            {shouldDisabledDiscountButtons ? (
              <span data-testid="non-discountable" className="nondiscount">
                {t('alerts.nonDiscountable')}
              </span>
            ) : null}
            <span className="hidden">
              {t('inputs.discount', { context: 'impossible' })}
            </span>
            <PluginComponent
              hookname="UIDiscountButtons"
              props={{
                value: order.manualDiscount,
                order,
                onChange: updateDiscount,
              }}
            >
              <ReturnDiscountButtons
                value={order.manualDiscount}
                onChange={updateDiscount}
                max={maxDiscount}
                order={order}
              />
            </PluginComponent>
          </div>
          <Notes key="notes" />
          {productComponents.length > 0 && (
            <div
              className="product-components"
              data-testid="product-components"
            >
              <h3>Bundle components</h3>

              <Loader show={loadingComponents} block>
                <Table className={tableStyles.minimalTable}>
                  <thead>
                    <tr>
                      <th>{t('headers.name')}</th>
                      <th>{t('headers.code')}</th>
                      <th>{t('headers.amount')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productComponents
                      .map(c => ({
                        ...c,
                        ...componentProducts.find(
                          p => String(p.productID) === String(c.componentID),
                        ),
                      }))
                      .map(({ componentID, name, code, amount }) => (
                        <tr key={componentID}>
                          <td>{name}</td>
                          <td>{code}</td>
                          <td>{amount}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Loader>
            </div>
          )}
          <div
            className="commission"
            style={{
              display: hideCommission ? 'none' : undefined,
            }}
            data-testid="commission"
          >
            <h3>{t('commission.title')}</h3>
            <ToggleButtonGroup
              name="company"
              type="radio"
              value={order.employeeID}
              onChange={id =>
                dispatch(setOrderEmployeeID(id, order.orderIndex))
              }
              data-testid="commission-buttons"
            >
              {Array(8)
                .fill(0)
                .map((_, i) => recommendedCommissions[i] || {})
                .map(({ employeeID, firstName, lastName }) => (
                  <ToggleButton
                    variant="light"
                    value={employeeID}
                    disabled={!employeeID}
                    key={uuid.v1()}
                    data-testid="commission-button"
                    data-test-key={employeeID}
                  >
                    {firstName} {lastName}
                  </ToggleButton>
                ))}
              <ToggleButton
                variant="light"
                value="..."
                onClick={e => {
                  e.preventDefault();
                  dispatch(
                    openModalPage({
                      component: modalPages.ProductCommissions,
                      props: {
                        order,
                        recommendedCommissions,
                        nonRecommendedCommissions,
                      },
                    }),
                  );
                }}
                data-testid="more"
              >
                ...
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </PluginComponent>
      <ProductInformation productItem={product} />
    </div>
  );
};

export default ProductForm;
