import { useCallback, useMemo } from 'react';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useMap } from 'react-use';

import { saveTempSetting } from 'actions/configs';
import { getSettings, getTempSettings } from 'reducers/configs/settings';
import { RootState } from 'reducers/index';

export function useSettings() {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const [
    errors,
    { get: getError, set: setError, remove: removeError },
  ] = useMap<Record<string, string>>();

  const changed = useSelector(getTempSettings);
  const current = useSelector(getSettings);

  const onChange = useCallback(
    (key, value) => {
      dispatch(saveTempSetting(key, value));
    },
    [dispatch],
  );

  const values = useMemo(
    () => ({
      ...current,
      ...changed,
    }),
    [current, changed],
  );

  const allValid = useMemo(() => !Object.keys(errors).length, [errors]);

  return {
    values,
    onChange,
    changed,
    getError,
    setError,
    removeError,
    allValid,
  };
}
