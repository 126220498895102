import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Box, Divider, makeStyles, Tab, Typography } from '@material-ui/core';
import { PatchScript } from '@pos-refacto/patchscript-with-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import classNames from 'classnames';

import { TabContainer, TabPanel, Tabs } from 'components/Tabs';
import { SaveSalesDocumentResponse } from 'types/SalesDocument';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { generateReceipt } from 'actions/integrations/printer/generateReceipt';
import { addError } from 'actions/Error';
import Loader from 'components/Loader';
import { RootState } from 'reducers';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import { getOfflinePatchScript } from 'actions/integrations/printer/utils';
import {
  OfflineSalesDocument,
  SalesDocument,
} from 'actions/integrations/printer/types';

import { PatchscriptRender } from '../Settings/views/Debug/components/ReceiptSchema/Editor/printer/sampleReactPrinter';

const useStyles = makeStyles({
  loader: { height: 'calc(100vh - 270px)' },
  iframeContainer: {
    width: '600px',
    maxWidth: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  htmlReceiptContainer: {
    overflow: 'auto !important',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > div:first-child': {
      position: 'relative',
      height: '100%',
      overflow: 'auto !important',
      background: '#fff',
      '& > div:first-child': {
        position: 'relative',
      },
    },
  },
  patchScriptReceiptContainer: {
    overflow: 'auto !important',
    height: '100%',
    padding: '1.25rem',
    background: '#fff',
  },
  boReceiptContainer: {
    position: 'absolute',
    width: '600px',
    height: '100%',
    border: 'none',
    background: '#fff',
  },
});

interface Props {
  salesDocument: SalesDocument;
}

const ReceiptPreview: FC<Props> = ({ salesDocument }) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation('receiptPreview');
  const isOnline = useSelector(getConnectionHealth);

  const [emailReceiptLoading, setEmailReceiptLoading] = useState(false);
  const [boReceipt, setBoReceipt] = useState('');

  const [printedReceiptLoading, setPrintedReceiptLoading] = useState(false);
  const [actualReportsReceipt, setActualReportsReceipt] = useState('');
  const [patchScriptReceipt, setPatchScriptReceipt] = useState<PatchScript>([]);
  const [urlReceipt, setUrlReceipt] = useState('');

  const receiptContainerRef = useRef<HTMLIFrameElement | null>(null);
  const receiptContainerElement = receiptContainerRef?.current;

  const width = useMemo(() => {
    if (actualReportsReceipt.length < 0 || !receiptContainerElement) return 0;

    const pages = receiptContainerRef?.current?.querySelectorAll('[data-page]');

    if (!pages?.length) return 0;

    return R.pipe(
      R.map(R.pipe(R.path(['style', 'width']), parseFloat)),
      R.reduce(R.max, 0),
    )(Array.from(pages));
  }, [actualReportsReceipt.length, receiptContainerElement]);

  useEffect(() => {
    const getEmailReceipt = async () => {
      if (!salesDocument.receiptLink) return;
      setEmailReceiptLoading(true);
      try {
        // Does not work locally due to CORS policy
        const { data: html } = await axios.get(salesDocument.receiptLink);
        if (typeof html === 'string') {
          setBoReceipt(html);
        }
      } catch (err) {
        dispatch(addError(t('alerts.failedToFetchEmailReceipt')));
      } finally {
        setEmailReceiptLoading(false);
      }
    };

    const getReceipt = async () => {
      setPrintedReceiptLoading(true);
      try {
        const receipt = await dispatch(
          generateReceipt({
            salesDocument: salesDocument as SaveSalesDocumentResponse,
          }),
        );
        if (receipt.html) {
          setActualReportsReceipt(receipt.html);
          return;
        }
        if (receipt.patchScript) {
          setPatchScriptReceipt(receipt.patchScript);
        } else if (receipt.url) {
          const { data: html } = await axios.get(receipt.url);
          setUrlReceipt(html);
        }
        getEmailReceipt();
      } catch (err) {
        if (!isOnline) {
          const patchScript = await dispatch(
            getOfflinePatchScript(salesDocument as OfflineSalesDocument),
          );
          setPatchScriptReceipt(patchScript ?? []);
        } else {
          dispatch(addError(t('alerts.failedToFetchPrintedReceipt')));
        }
      } finally {
        setPrintedReceiptLoading(false);
      }
    };

    getReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid="receipt-preview">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1.25rem"
        paddingY="0.75rem"
      >
        <Box paddingLeft="5px">
          <Typography variant="h5">{t('title')}</Typography>
        </Box>
        <div>
          <CloseButton
            style={{ transform: 'scale(0.85)' }}
            action={() => dispatch(previousModalPage())}
          />
        </div>
      </Box>
      <Divider />
      <Box padding="1.25rem">
        {actualReportsReceipt.length ? (
          <Loader show={printedReceiptLoading} block className={styles.loader}>
            <div className={styles.htmlReceiptContainer}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: actualReportsReceipt }}
                ref={receiptContainerRef}
                style={{
                  width: `${width || 400}px`,
                }}
              />
            </div>
          </Loader>
        ) : (
          <TabContainer defaultTab={0}>
            <Tabs id="receiptPreviewTabs">
              <Tab value={0} label={t('tabs.printedReceipt')} />
              <Tab value={1} label={t('tabs.emailReceipt')} />
            </Tabs>
            <TabPanel value={0}>
              <Loader
                show={printedReceiptLoading}
                block
                className={classNames(styles.loader, {
                  [styles.iframeContainer]: urlReceipt,
                })}
              >
                {patchScriptReceipt.length ? (
                  <div className={styles.patchScriptReceiptContainer}>
                    <PatchscriptRender
                      pixelWidth={400}
                      receiptData={patchScriptReceipt}
                    />
                  </div>
                ) : null}
                {urlReceipt ? (
                  <iframe
                    title="iframe"
                    className={styles.boReceiptContainer}
                    srcDoc={urlReceipt}
                  />
                ) : null}
              </Loader>
            </TabPanel>
            <TabPanel value={1}>
              <Loader
                show={emailReceiptLoading}
                block
                className={classNames(styles.loader, {
                  [styles.iframeContainer]: boReceipt,
                })}
              >
                {boReceipt ? (
                  <iframe
                    title="iframe"
                    className={styles.boReceiptContainer}
                    srcDoc={boReceipt}
                  />
                ) : (
                  <Box paddingTop="2rem" textAlign="center">
                    {t('boReceiptUnavailable')}
                  </Box>
                )}
              </Loader>
            </TabPanel>
          </TabContainer>
        )}
      </Box>
    </div>
  );
};

export default ReceiptPreview;
