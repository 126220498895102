import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PosPlugin } from 'plugins/plugin';
import { getLastProductIndex } from 'reducers/ShoppingCart';
import CloseButton from 'components/CustomButtons/CloseButton';
import { sanitizeValue } from 'components/tableBill/components/RowQuantity/utils';
import { modalPages } from 'constants/modalPage';
import { getComponents } from 'reducers/modalPage';

import { addFreeGiftCard } from '../redux';

const UICustomGiftCardSerial: PosPlugin['UICustomGiftCardSerial'] = ({
  giftCardSerial,
  handleSubmit,
  generate,
  helperText,
  isValid,
  onClose,
  shouldHideGenerateButton,
  setGiftCardSerial,
  children,
}) => {
  const { t } = useTranslation('giftcard');
  const [percentage, setPercentage] = useState('');
  const dispatch = useDispatch();
  const lastProductIndex = useSelector(getLastProductIndex);
  const isPaymentModalOpen = useSelector(getComponents)
    .map(comp => comp.component)
    .includes(modalPages.Payment);

  if (isPaymentModalOpen) return children;

  const customHandleSubmit = () => {
    if (percentage) {
      dispatch(
        addFreeGiftCard(Number(lastProductIndex ?? 0) + 1, {
          code: giftCardSerial,
          percentage,
        }),
      );
    }
    handleSubmit();
  };

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">{t('serial.title')}</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <Button
            onClick={customHandleSubmit}
            disabled={!giftCardSerial || !isValid}
            variant="contained"
            color="secondary"
          >
            {t('buttons.save')}
          </Button>
          <CloseButton style={{ transform: 'scale(0.85)' }} action={onClose} />
        </Box>
      </Box>
      <TextField
        label={t('fields.serial', { context: 'placeholder' })}
        value={giftCardSerial}
        autoFocus
        error={!isValid}
        helperText={helperText}
        style={{ marginBottom: '0.75rem' }}
        onChange={e => setGiftCardSerial(e.target.value.replace(/(\s*)/g, ''))}
        variant="outlined"
        fullWidth
      />
      <TextField
        label="will deduct this percentage from gift card value"
        value={percentage}
        style={{ marginBottom: '0.75rem' }}
        type="number"
        size="small"
        InputProps={{
          inputProps: {
            max: 100,
            min: 0,
          },
        }}
        onChange={e => {
          const { value } = e.target;
          const numValue = Number(value);
          if (!value.length || (numValue <= 100 && numValue >= 0)) {
            setPercentage(sanitizeValue(value));
          }
        }}
        variant="outlined"
        fullWidth
      />
      {!shouldHideGenerateButton && (
        <Button
          variant="contained"
          onClick={generate}
          fullWidth
          color="secondary"
        >
          {t('buttons.generateNumber')}
        </Button>
      )}
    </Box>
  );
};
export default UICustomGiftCardSerial;
