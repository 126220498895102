import { combineReducers } from 'redux';
import compareVersions from 'compare-versions';
import { createSelector } from 'reselect';

import * as types from 'constants/installer';
import {
  getAllCafaEntries,
  getUnobstructedCafaEntries,
} from 'reducers/cafaConfigs';

import { REDUX_INSTALLER_MS_ENDPOINTS } from '../constants/persistence';

export const initState = {};

export function getAllMSEndpointsFromLS() {
  try {
    return JSON.parse(
      localStorage.getItem(REDUX_INSTALLER_MS_ENDPOINTS) ?? '{}',
    );
  } catch (err) {
    console.error(
      'Failed to load microservice endpoints from localstorage',
      err,
    );
    return {};
  }
}

export function getMSEndpointFromLS(integrationName) {
  if (!integrationName) return null;
  const ms = types.integrationMapper[integrationName];
  if (!ms.entity) return null;
  const endpoints = getAllMSEndpointsFromLS();
  if (endpoints[ms.entity]) return `${endpoints[ms.entity]}/`;
  return null;
}

/**
 * @deprecated unused
 */
export const getRequiredMicroservices = createSelector(
  state => getUnobstructedCafaEntries(state),
  entries => {
    return Object.fromEntries(
      entries
        .filter(e => !!types.integrationMapper[e.name])
        .map(e => [types.integrationMapper[e.name], e]),
    );
  },
);
