import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentPrinterIntegration } from 'reducers/configs/settings';

/**
 * A react component that accepts a printing MS script and displays a preview of what that script would look like when printed
 * @param {string} props.script
 * @returns {React.Component}
 */
const ReceiptPreviewInternal = ({ script, ...params }) => {
  const { SP } = useSelector(getCurrentPrinterIntegration)
  const { bold, underline } = params;
  if (script === '') return null;

  const [line, ...rest] = script.split('\n');
  const [cmd, ...args] = line.split(' ');
  switch (cmd) {
    case 'BOLD':
      return (
        <ReceiptPreviewInternal
          {...params}
          script={rest.join('\n')}
          bold={args[0] === '1'}
        />
      );
    case 'UNDERLINE':
      return (
        <ReceiptPreviewInternal
          {...params}
          script={rest.join('\n')}
          underline={args[0] === '1'}
        />
      );
    case 'PRINT':
      return (
        <>
          <span
            style={{
              fontWeight: bold ? 'bold' : null,
              textDecoration: underline ? 'underline' : null,
            }}
          >
            {args.join(' ').split(SP).join(' ')}
          </span>
          <ReceiptPreviewInternal {...params} script={rest.join('\n')} />
        </>
      );
    case 'LF':
      return (
        <>
          <br />
          <ReceiptPreviewInternal {...params} script={rest.join('\n')} />
        </>
      );
    default:
      return <ReceiptPreviewInternal {...params} script={rest.join('\n')} />;
  }
};

export const ReceiptPreview = ({ script }) => (
  <div
    style={{
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
      width: '53ch',
      border: '1px solid black',
      fontSize: '10px',
      backgroundColor: '#ffffff',
      color: '#000000',
      padding: '1ch 2ch 1ch 1ch',
      lineHeight: '1.6',
    }}
  >
    <ReceiptPreviewInternal script={script} />
  </div>
);

/* eslint-disable no-irregular-whitespace */
const psample = `
   0123 4567 89ab cdef
2:  !"# $%&' ()*+ ,-./
3: 0123 4567 89:; <=>?
4: @ABC DEFG HIJK LMNO
5: PQRS TUVW XYZ[ \\]^_
6: \`abc defg hijk lmno
7: pqrs tuvw xyz{ |}~
8:    
9:    
a:  ¡¢£ ¤¥¦§ ¨©ª« ¬­®¯
b: °±²³ ´µ¶· ¸¹º» ¼½¾¿
c: ÀÁÂÃ ÄÅÆÇ ÈÉÊË ÌÍÎÏ
d: ÐÑÒÓ ÔÕÖ× ØÙÚÛ ÜÝÞß
e: àáâã äåæç èéêë ìíîï
f: ðñòó ôõö÷ øùúû üýþÿ`;
const jsample = `
   0123 4567 89ab cdef
2:  !"# $%&' ()*+ ,-./
3: 0123 4567 89:; <=>?
4: @ABC DEFG HIJK LMNO
5: PQRS TUVW XYZ[ \\]^_
6: \`abc defg hijk lmno
7: pqrs tuvw xyz{ |}~ 
8:                    
9:                    
a: ÄÖÜß §              
b: éèẽě  íìĩ ǐöóò õǒüú
c: ùũũ  ¿          äáÁ
d: ãǎ°          X     
e:                    
f:                    `;
/* eslint-enable no-irregular-whitespace */

const jtop = printerText => {
  return printerText
    .split('')
    .map(c => psample[jsample.indexOf(c)])
    .join('');
};
const ptoj = printerText => {
  return printerText
    .split('')
    .map(c => jsample[psample.indexOf(c)] || ' ')
    .join('');
};
