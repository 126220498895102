import SET_MARKETPLACE_SPS from 'constants/marketplace';

const initialState = { marketplaceSPS: [] };

function marketplace(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MARKETPLACE_SPS.SUCCESS:
      return {
        marketplaceSPS: [...payload],
      };
    default:
      return state;
  }
}

export function getMarketplaceSPS(state) {
  return state.configs.marketplace.marketplaceSPS;
}

export default marketplace;
