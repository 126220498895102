import React from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

import { getExchangeState } from '../rdx';

const UIDeleteProductShortcut: PosPlugin['UIDeleteProductShortcut'] = ({
  children,
}) => {
  const exchangeState = useSelector(getExchangeState);

  if (exchangeState.exchangeStep >= 2) return null;
  return children;
};

export default UIDeleteProductShortcut;
