import React from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

import IconButton from 'components/IconButton';
import { getCurrencyFormatter, getSetting } from 'reducers/configs/settings';
import { useBreakpoints } from 'utils/hooks/UI';
import {
  getRounding,
  getTotal,
  getTotalQuantity,
  getTotalNet,
  getTotalTax,
  getVisibleTotalDiscount,
} from 'reducers/ShoppingCart';
import {
  getIsAReturn,
  getReturnTotal,
  getReturnVATTotal,
  getReturnNetTotal,
} from 'reducers/sales';
import { round } from 'utils';

import './tableTotal.scss';

const TableTotal = ({
  extraTaxRows = [],
  sumTaxes = true,
  customTotalAmount = null,
  customNetAmount = null,
}) => {
  const { t } = useTranslation('billTable');
  const isMobileView = !useBreakpoints().mdplus;
  const isAReturn = useSelector(getIsAReturn);
  const returnTotal = useSelector(getReturnTotal);
  const returnVatTotal = useSelector(getReturnVATTotal);
  const returnNetTotal = useSelector(getReturnNetTotal);
  const net = useSelector(getTotalNet);
  const vat = useSelector(getTotalTax);
  const sum = useSelector(getTotal);

  const defineTotalToDisplay = () => {
    if (customTotalAmount) {
      return customTotalAmount;
    }

    if (isAReturn && returnTotal !== 0) {
      return returnTotal;
    }
    return sum;
  };

  const defineNetToDisplay = () => {
    if (customNetAmount) {
      return customNetAmount;
    }

    if (isAReturn && returnNetTotal !== 0) {
      return returnNetTotal;
    }

    return net;
  };

  const total = { sum: 0, discount: 0, rounding: 0, vat: 0, net: 0 };
  total.sum = defineTotalToDisplay();
  total.sum = sumTaxes ? total.sum : net;
  total.rounding = useSelector(getRounding);
  total.vat = isAReturn && returnVatTotal !== 0 ? returnVatTotal : vat;
  total.vat = sumTaxes ? total.vat : 0;
  total.discount = useSelector(getVisibleTotalDiscount);
  total.net = defineNetToDisplay();
  total.quantity = useSelector(getTotalQuantity);
  const format = useSelector(getCurrencyFormatter);
  const showQuantity = useSelector(
    getSetting('touchpos_show_total_basket_quantity'),
  );

  const hideOnMobile = isMobileView ? 'hidden' : '';

  return !isMobileView ? (
    <Table className="table-total" data-testid="table-total">
      <tbody data-testid="table-total-body">
        <tr data-testid="bill-total-row" className="bill-total">
          <td data-testid="table-total-sum-label" className={hideOnMobile}>
            {t('footers.total').toUpperCase()}
          </td>
          <td data-testid="table-total-sum">{format(total.sum)}</td>
        </tr>
        {total.discount > 0 ? (
          <tr data-testid="bill-discount-total" className="bill-discount-total">
            <td data-testid="bill-discount-label" className={hideOnMobile}>
              {t('footers.discount').toUpperCase()}
            </td>
            <td data-testid="bill-discount-sum">
              {format(total.discount, { roundDown: true })}
            </td>
          </tr>
        ) : null}
        {total.rounding > 0 && (
          <tr data-testid="bill-rounding-total" className="bill-rounding-total">
            <td data-testid="bill-rounding-label" className={hideOnMobile}>
              {t('footers.rounding').toUpperCase()}
            </td>
            <td data-testid="bill-rounding-sum">{format(total.rounding)}</td>
          </tr>
        )}
        <tr
          data-testid="bill-tax-total"
          data-test-key="regular-tax"
          className="bill-tax-total"
        >
          <td data-testid="bill-tax-total-label" className={hideOnMobile}>
            {t('footers.vat').toUpperCase()}
          </td>
          <td data-testid="bill-tax-total-sum">{format(total.vat)}</td>
        </tr>
        {extraTaxRows.length > 0 &&
          extraTaxRows.map(row => (
            <tr
              data-testid="bill-tax-total"
              data-test-key={row.taxName}
              className="bill-tax-total"
              key={row.taxName}
            >
              <td
                data-testid="bill-tax-total-label"
                data-test-key={row.taxName}
                className={hideOnMobile}
              >
                {t(row.taxName).toUpperCase()}
              </td>
              <td data-testid="bill-tax-total-sum" data-test-key={row.taxName}>
                {format(row.taxAmount)}
              </td>
            </tr>
          ))}
        <tr data-testid="bill-net-total" className="bill-net-total">
          <td data-testid="bill-net-total-label" className={hideOnMobile}>
            {t('footers.net').toUpperCase()}
          </td>
          <td data-testid="bill-net-total-sum">{format(total.net)}</td>
        </tr>
        {!!showQuantity && (
          <tr data-testid="bill-quantity-total" className="bill-quantity-total">
            <td
              data-testid="bill-quantity-total-label"
              className={hideOnMobile}
            >
              {t('footers.amount').toUpperCase()}
            </td>
            <td data-testid="bill-quantity-total-sum">{total.quantity}</td>
          </tr>
        )}
      </tbody>
    </Table>
  ) : (
    // eslint-ignore
    <div data-testid="table-total" className="table-total py-2">
      <div
        data-testid="table-total-body"
        className="d-flex align-items-center justify-content-between"
      >
        <div data-testid="bill-total" className="bill-total">
          <span data-testid="bill-total-label" className={hideOnMobile}>
            {t('footers.total').toUpperCase()}
          </span>
          <span data-testid="bill-total-sum" style={{ padding: 0 }}>
            {format(total.sum)}
          </span>
        </div>
        <div>
          <IconButton
            data-testid="discount-icon"
            className="my-1 h-50"
            variant="warning"
          >
            {total.discount > 0 && (
              <div
                data-testid="bill-discount-total"
                className="bill-discount-total"
              >
                <span data-testid="bill-discount-total-label">
                  {t('footers.discount').toUpperCase()}
                </span>
                <span data-testid="bill-discount-total-sum">
                  {format(total.discount, { roundDown: true })}
                </span>
              </div>
            )}
            {total.rounding > 0 && (
              <div
                data-testid="bill-rounding-total"
                className="bill-rounding-total"
              >
                <span data-testid="bill-rounding-total-label">
                  {t('footers.rounding').toUpperCase()}
                </span>
                <span data-testid="bill-rounding-total-sum">
                  {format(total.rounding)}
                </span>
              </div>
            )}
            <div data-testid="bill-tax-total" className="bill-tax-total">
              <span data-testid="bill-tax-total-label">
                {t('footers.vat').toUpperCase()}
              </span>
              <span data-testid="bill-tax-total-sum">{format(total.vat)}</span>
            </div>
            {extraTaxRows.length > 0 &&
              extraTaxRows.map(row => (
                <div
                  data-testid="bill-tax-total"
                  data-test-key={row.taxName}
                  className="bill-tax-total"
                >
                  <span
                    data-testid="bill-tax-total-label"
                    data-test-key={row.taxName}
                  >
                    {t(row.taxName).toUpperCase()}
                  </span>
                  <span
                    data-testid="bill-tax-total-sum"
                    data-test-key={row.taxName}
                  >
                    {format(row.taxAmount)}
                  </span>
                </div>
              ))}
            <div data-testid="bill-net-total" className="bill-net-total">
              <span data-testid="bill-net-total-label">
                {t('footers.net').toUpperCase()}
              </span>
              <span data-testid="bill-net-total-sum">{format(total.net)}</span>
            </div>
            {!!showQuantity && (
              <div
                data-testid="bill-quantity-total"
                className="bill-quantity-total"
              >
                <span
                  data-testid="bill-quantity-total-label"
                  className={hideOnMobile}
                >
                  {t('footers.amount').toUpperCase()}
                </span>
                <span data-testid="bill-quantity-total-sum">
                  {total.quantity}
                </span>
              </div>
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default TableTotal;
