import React from 'react';
import PropTypes from 'prop-types';

import SelectRecordsPerPage from './SelectRecordsPerPage';
import Records from './Records';
import Pages from './Pages';

import './Pagination.sass';

/**
 * Pagination component - Can be used for any Data rendering component. The pagination would be wrapped around the rendering component(passed as children)
 * @param {*} props
 */

const CustomPagination = props => {
  const { children, itemsPerPage, setItemsPerPage } = props;
  return (
    <div className="pagination">
      <div className="pagination-top">
        <SelectRecordsPerPage
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      </div>
      {children}
      <div className="pagination-bottom">
        <Pages {...props} />
        <Records {...props} />
      </div>
    </div>
  );
};

CustomPagination.propTypes = {
  /** The component(s) being paginated */
  children: PropTypes.object.isRequired,
  /** items/records per page */
  itemsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** setter function for items per page */
  setItemsPerPage: PropTypes.func.isRequired,
};

export default CustomPagination;
