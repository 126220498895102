/* eslint-disable no-console */
import { notUndefinedOrNull } from 'utils/tsHelpers';
import { REDUX_WAREHOUSEID } from 'constants/persistence';

import getIndexedDB from './getDB';
import { getTableMeta, SO, SOName } from './types';
import { getItemsFromSharedSO } from './sharedSO';

const prepareParamsForSOQuery = (
  SO_NAME: SOName,
  id: string | number,
  meta?: { warehouseID?: number; assortmentID?: number },
) => {
  const { warehouseID, assortmentID } = meta ?? {};
  switch (SO_NAME) {
    case SO.ASSORTMENTS.NAME:
      return [id, assortmentID];
    case SO.PRODUCT_STOCK.NAME:
      return [id, warehouseID];
    default:
      return id;
  }
};

/**
 * Get items by id from indexedDB StoreObject
 * If no ids are passed, all records in the SO will be returned
 */

const getItemsFromSO = async <T extends any>(
  clientCode,
  SO_NAME: SOName,
  ids?: any[] | null,
  meta?: { warehouseID?: number; assortmentID?: number },
): Promise<T[]> => {
  const db = await getIndexedDB(clientCode);
  if (!ids?.length) return db.getAll(SO_NAME);
  const tx = db.transaction(SO_NAME, 'readonly');
  return Promise.all([
    ...ids.map(id => tx.store.get(prepareParamsForSOQuery(SO_NAME, id, meta))),
    tx.done,
  ]).then(data => data.filter(notUndefinedOrNull));
};

export const getSyncIsReadyForType = async (
  clientCode,
  SO_NAME: SOName,
  meta?: { warehouseID?: number; assortmentID?: number },
) => {
  const [warehouseID] = meta?.warehouseID
    ? [meta.warehouseID]
    : await getItemsFromSharedSO(clientCode, REDUX_WAREHOUSEID);
  const TABLE_META = getTableMeta(SO_NAME, warehouseID);
  const [isReady] = await getItemsFromSO(clientCode, SO.META.NAME, [
    TABLE_META.READY,
  ]);
  return isReady;
};

export default getItemsFromSO;
