import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getUserLoggedIn } from 'reducers/Login';

import HeaderIcon from '../Icon';

const BackOfficeRedirect = () => {
  const { t } = useTranslation('header');
  const userInfo = useSelector(getUserLoggedIn);
  return (
    <HeaderIcon
      icon="briefcase"
      title={t('titles.productCatalog')}
      data-testid="header-back-office"
      isLink={true}
      href={`${userInfo.loginUrl}?section=products`}
      post={{
        authKey: userInfo.sessionKey,
      }}
      target="_blank"
    />
  );
};

export default BackOfficeRedirect;
