// tslint:disable
/* eslint-disable */
/**
 * Reports API
 * Handles statistical data requests, either getting data for reports or getting data for graphs.
 *
 * OpenAPI spec version: v1.16.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as url from 'url';
import { Configuration } from './configuration';

const portableFetch = window.fetch;

const BASE_PATH = 'https://localhost'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration!: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = portableFetch,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name!: 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface ModelsDocument
 */
export interface ModelsDocument {
  /**
   *
   * @type {string}
   * @memberof ModelsDocument
   */
  documentNo?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsDocument
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsDocument
   */
  typeId?: number;
}

/**
 *
 * @export
 * @interface ModelsLogLoginRecord
 */
export interface ModelsLogLoginRecord {
  /**
   *
   * @type {string}
   * @memberof ModelsLogLoginRecord
   */
  datetime?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsLogLoginRecord
   */
  domain?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsLogLoginRecord
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsLogLoginRecord
   */
  ipAddress?: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelsLogLoginRecord
   */
  loginSuccess?: boolean;
  /**
   *
   * @type {string}
   * @memberof ModelsLogLoginRecord
   */
  username?: string;
}

/**
 *
 * @export
 * @interface ModelsOutputError
 */
export interface ModelsOutputError {
  /**
   *
   * @type {ModelsStatusError}
   * @memberof ModelsOutputError
   */
  status?: ModelsStatusError;
}

/**
 *
 * @export
 * @interface ModelsOutputGetLoginLogs
 */
export interface ModelsOutputGetLoginLogs {
  /**
   *
   * @type {Array<ModelsLogLoginRecord>}
   * @memberof ModelsOutputGetLoginLogs
   */
  data?: Array<ModelsLogLoginRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetLoginLogs
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetPosDays
 */
export interface ModelsOutputGetPosDays {
  /**
   *
   * @type {Array<ModelsPointOfSaleRecord>}
   * @memberof ModelsOutputGetPosDays
   */
  data?: Array<ModelsPointOfSaleRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetPosDays
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetSalesBy
 */
export interface ModelsOutputGetSalesBy {
  /**
   *
   * @type {Array<ModelsSalesByRecord>}
   * @memberof ModelsOutputGetSalesBy
   */
  data?: Array<ModelsSalesByRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetSalesBy
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetSalesByAssociate
 */
export interface ModelsOutputGetSalesByAssociate {
  /**
   *
   * @type {Array<ModelsSalesAssosiateRecord>}
   * @memberof ModelsOutputGetSalesByAssociate
   */
  data?: Array<ModelsSalesAssosiateRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetSalesByAssociate
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetSalesRevenueDaily
 */
export interface ModelsOutputGetSalesRevenueDaily {
  /**
   *
   * @type {Array<ModelsSalesRevenueRecord>}
   * @memberof ModelsOutputGetSalesRevenueDaily
   */
  data?: Array<ModelsSalesRevenueRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetSalesRevenueDaily
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetSalesRevenueMonthly
 */
export interface ModelsOutputGetSalesRevenueMonthly {
  /**
   *
   * @type {Array<ModelsSalesRevenueRecord>}
   * @memberof ModelsOutputGetSalesRevenueMonthly
   */
  data?: Array<ModelsSalesRevenueRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetSalesRevenueMonthly
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetStocktakingSummary
 */
export interface ModelsOutputGetStocktakingSummary {
  /**
   *
   * @type {Array<ModelsStockTakingSummaryRecord>}
   * @memberof ModelsOutputGetStocktakingSummary
   */
  data?: Array<ModelsStockTakingSummaryRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetStocktakingSummary
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetTransactionAddedCredit
 */
export interface ModelsOutputGetTransactionAddedCredit {
  /**
   *
   * @type {Array<ModelsTransactionCreditRecord>}
   * @memberof ModelsOutputGetTransactionAddedCredit
   */
  data?: Array<ModelsTransactionCreditRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetTransactionAddedCredit
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetTransactionCashflow
 */
export interface ModelsOutputGetTransactionCashflow {
  /**
   *
   * @type {Array<ModelsTransactionCashflowRecord>}
   * @memberof ModelsOutputGetTransactionCashflow
   */
  data?: Array<ModelsTransactionCashflowRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetTransactionCashflow
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetTransactionPaidCredit
 */
export interface ModelsOutputGetTransactionPaidCredit {
  /**
   *
   * @type {Array<ModelsTransactionCreditRecord>}
   * @memberof ModelsOutputGetTransactionPaidCredit
   */
  data?: Array<ModelsTransactionCreditRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetTransactionPaidCredit
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetTransactionTotalByType
 */
export interface ModelsOutputGetTransactionTotalByType {
  /**
   *
   * @type {Array<ModelsTransactionTotalByTypeRecord>}
   * @memberof ModelsOutputGetTransactionTotalByType
   */
  data?: Array<ModelsTransactionTotalByTypeRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetTransactionTotalByType
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetTransactionVoid
 */
export interface ModelsOutputGetTransactionVoid {
  /**
   *
   * @type {Array<ModelsTransactionVoidRecord>}
   * @memberof ModelsOutputGetTransactionVoid
   */
  data?: Array<ModelsTransactionVoidRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetTransactionVoid
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputGetTransactions
 */
export interface ModelsOutputGetTransactions {
  /**
   *
   * @type {Array<ModelsTransactionRecord>}
   * @memberof ModelsOutputGetTransactions
   */
  data?: Array<ModelsTransactionRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputGetTransactions
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputOK
 */
export interface ModelsOutputOK {
  /**
   *
   * @type {Array<any>}
   * @memberof ModelsOutputOK
   */
  data?: Array<any>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputOK
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputPackagingPurchases
 */
export interface ModelsOutputPackagingPurchases {
  /**
   *
   * @type {Array<ModelsPackagingPurchaseRecord>}
   * @memberof ModelsOutputPackagingPurchases
   */
  data?: Array<ModelsPackagingPurchaseRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputPackagingPurchases
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsOutputPackagingSales
 */
export interface ModelsOutputPackagingSales {
  /**
   *
   * @type {Array<ModelsPackagingSalesRecord>}
   * @memberof ModelsOutputPackagingSales
   */
  data?: Array<ModelsPackagingSalesRecord>;
  /**
   *
   * @type {ModelsStatusOk}
   * @memberof ModelsOutputPackagingSales
   */
  status?: ModelsStatusOk;
}

/**
 *
 * @export
 * @interface ModelsPOSCurrency
 */
export interface ModelsPOSCurrency {
  /**
   *
   * @type {string}
   * @memberof ModelsPOSCurrency
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPOSCurrency
   */
  id?: number;
}

/**
 *
 * @export
 * @interface ModelsPOSSalepoint
 */
export interface ModelsPOSSalepoint {
  /**
   *
   * @type {number}
   * @memberof ModelsPOSSalepoint
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPOSSalepoint
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsPOSSalepoint
   */
  shopName?: string;
}

/**
 *
 * @export
 * @interface ModelsPOSUser
 */
export interface ModelsPOSUser {
  /**
   *
   * @type {number}
   * @memberof ModelsPOSUser
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPOSUser
   */
  name?: string;
}

/**
 *
 * @export
 * @interface ModelsPOSWarehouse
 */
export interface ModelsPOSWarehouse {
  /**
   *
   * @type {string}
   * @memberof ModelsPOSWarehouse
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPOSWarehouse
   */
  id?: number;
}

/**
 *
 * @export
 * @interface ModelsPackagingPurchaseRecord
 */
export interface ModelsPackagingPurchaseRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  groupPackageMetal?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  groupPackagePaper?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  groupPackagePlastic?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  groupPackageWood?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingPurchaseRecord
   */
  productCode?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  productId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingPurchaseRecord
   */
  productName?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingPurchaseRecord
   */
  productUPC?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingPurchaseRecord
   */
  purchaseDocumentDate?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  purchaseDocumentId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingPurchaseRecord
   */
  purchaseDocumentNumber?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  purchaseDocumentSupplierId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingPurchaseRecord
   */
  purchaseDocumentSupplierName?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackageCardboard?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackageClearBrownGlass?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackageGreenOtherGlass?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackageMetalAl?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackageMetalFe?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackageOtherMetal?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackagePlasticPet?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackagePlasticPpPe?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  salesPackageWood?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  totalGrossWeight?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  totalNetWeight?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  transportPackageCardboard?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  transportPackagePlastic?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingPurchaseRecord
   */
  transportPackageWood?: number;
}

/**
 *
 * @export
 * @interface ModelsPackagingSalesRecord
 */
export interface ModelsPackagingSalesRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  groupPackageMetal?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  groupPackagePaper?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  groupPackagePlastic?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  groupPackageWood?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingSalesRecord
   */
  productCode?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  productId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingSalesRecord
   */
  productName?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingSalesRecord
   */
  productUPC?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingSalesRecord
   */
  purchaseDocumentDate?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesDocumentId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPackagingSalesRecord
   */
  salesDocumentNumber?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackageCardboard?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackageClearBrownGlass?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackageGreenOtherGlass?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackageMetalAl?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackageMetalFe?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackageOtherMetal?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackagePlasticPet?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackagePlasticPpPe?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  salesPackageWood?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  totalGrossWeight?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  totalNetWeight?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  transportPackageCardboard?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  transportPackagePlastic?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPackagingSalesRecord
   */
  transportPackageWood?: number;
}

/**
 *
 * @export
 * @interface ModelsPaymentType
 */
export interface ModelsPaymentType {
  /**
   *
   * @type {string}
   * @memberof ModelsPaymentType
   */
  cardType?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPaymentType
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsPaymentType
   */
  type?: string;
}

/**
 *
 * @export
 * @interface ModelsPointOfSaleRecord
 */
export interface ModelsPointOfSaleRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsPointOfSaleRecord
   */
  closedAtUnix?: number;
  /**
   *
   * @type {ModelsPOSUser}
   * @memberof ModelsPointOfSaleRecord
   */
  closedBy?: ModelsPOSUser;
  /**
   *
   * @type {number}
   * @memberof ModelsPointOfSaleRecord
   */
  closedDeposited?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPointOfSaleRecord
   */
  closedLeftAsChange?: number;
  /**
   *
   * @type {ModelsPOSCurrency}
   * @memberof ModelsPointOfSaleRecord
   */
  currency?: ModelsPOSCurrency;
  /**
   *
   * @type {Array<ModelsPOSUser>}
   * @memberof ModelsPointOfSaleRecord
   */
  drawerEmployees?: Array<ModelsPOSUser>;
  /**
   *
   * @type {string}
   * @memberof ModelsPointOfSaleRecord
   */
  drawerId?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsPointOfSaleRecord
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPointOfSaleRecord
   */
  initialAmount?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsPointOfSaleRecord
   */
  openedAtUnix?: number;
  /**
   *
   * @type {ModelsPOSUser}
   * @memberof ModelsPointOfSaleRecord
   */
  openedBy?: ModelsPOSUser;
  /**
   *
   * @type {ModelsPOSSalepoint}
   * @memberof ModelsPointOfSaleRecord
   */
  pointOfSale?: ModelsPOSSalepoint;
  /**
   *
   * @type {number}
   * @memberof ModelsPointOfSaleRecord
   */
  totalTransactions?: number;
  /**
   *
   * @type {ModelsPOSWarehouse}
   * @memberof ModelsPointOfSaleRecord
   */
  warehouse?: ModelsPOSWarehouse;
}

/**
 *
 * @export
 * @interface ModelsSTGroup
 */
export interface ModelsSTGroup {
  /**
   *
   * @type {number}
   * @memberof ModelsSTGroup
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsSTGroup
   */
  string?: string;
}

/**
 *
 * @export
 * @interface ModelsSTProduct
 */
export interface ModelsSTProduct {
  /**
   *
   * @type {string}
   * @memberof ModelsSTProduct
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsSTProduct
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsSTProduct
   */
  name?: string;
}

/**
 *
 * @export
 * @interface ModelsSTSeries
 */
export interface ModelsSTSeries {
  /**
   *
   * @type {number}
   * @memberof ModelsSTSeries
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsSTSeries
   */
  name?: string;
}

/**
 *
 * @export
 * @interface ModelsSTUnit
 */
export interface ModelsSTUnit {
  /**
   *
   * @type {number}
   * @memberof ModelsSTUnit
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsSTUnit
   */
  name?: string;
}

/**
 *
 * @export
 * @interface ModelsSTWarehouse
 */
export interface ModelsSTWarehouse {
  /**
   *
   * @type {number}
   * @memberof ModelsSTWarehouse
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsSTWarehouse
   */
  name?: string;
}

/**
 *
 * @export
 * @interface ModelsSalesAssosiateRecord
 */
export interface ModelsSalesAssosiateRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsSalesAssosiateRecord
   */
  employeeId?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsSalesAssosiateRecord
   */
  employeeName1?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsSalesAssosiateRecord
   */
  employeeName2?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsSalesAssosiateRecord
   */
  total?: number;
}

/**
 *
 * @export
 * @interface ModelsSalesByRecord
 */
export interface ModelsSalesByRecord {
  /**
   *
   * @type {string}
   * @memberof ModelsSalesByRecord
   */
  dateTimeFrom?: string;
  /**
   *
   * @type {string}
   * @memberof ModelsSalesByRecord
   */
  dateTimeTo?: string;
  /**
   *
   * @type {Array<ModelsSalesItem>}
   * @memberof ModelsSalesByRecord
   */
  items?: Array<ModelsSalesItem>;
}

/**
 *
 * @export
 * @interface ModelsSalesItem
 */
export interface ModelsSalesItem {
  /**
   *
   * @type {number}
   * @memberof ModelsSalesItem
   */
  avg?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsSalesItem
   */
  count?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsSalesItem
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsSalesItem
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsSalesItem
   */
  total?: number;
}

/**
 *
 * @export
 * @interface ModelsSalesRevenueRecord
 */
export interface ModelsSalesRevenueRecord {
  /**
   *
   * @type {string}
   * @memberof ModelsSalesRevenueRecord
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof ModelsSalesRevenueRecord
   */
  total?: number;
}

/**
 *
 * @export
 * @interface ModelsStatusError
 */
export interface ModelsStatusError {
  /**
   *
   * @type {number}
   * @memberof ModelsStatusError
   */
  errorCode?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsStatusError
   */
  requestUnixTime?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsStatusError
   */
  responseStatus?: string;
}

/**
 *
 * @export
 * @interface ModelsStatusOk
 */
export interface ModelsStatusOk {
  /**
   *
   * @type {number}
   * @memberof ModelsStatusOk
   */
  errorCode?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsStatusOk
   */
  requestUnixTime?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsStatusOk
   */
  responseStatus?: string;
}

/**
 *
 * @export
 * @interface ModelsStockTakingSummaryRecord
 */
export interface ModelsStockTakingSummaryRecord {
  /**
   *
   * @type {ModelsPOSUser}
   * @memberof ModelsStockTakingSummaryRecord
   */
  byUser?: ModelsPOSUser;
  /**
   *
   * @type {number}
   * @memberof ModelsStockTakingSummaryRecord
   */
  costDifference?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsStockTakingSummaryRecord
   */
  count?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsStockTakingSummaryRecord
   */
  difference?: number;
  /**
   *
   * @type {ModelsSTGroup}
   * @memberof ModelsStockTakingSummaryRecord
   */
  group?: ModelsSTGroup;
  /**
   *
   * @type {number}
   * @memberof ModelsStockTakingSummaryRecord
   */
  inventoryId?: number;
  /**
   *
   * @type {ModelsSTProduct}
   * @memberof ModelsStockTakingSummaryRecord
   */
  product?: ModelsSTProduct;
  /**
   *
   * @type {ModelsSTSeries}
   * @memberof ModelsStockTakingSummaryRecord
   */
  series?: ModelsSTSeries;
  /**
   *
   * @type {number}
   * @memberof ModelsStockTakingSummaryRecord
   */
  stocktakingStartTimeUnix?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsStockTakingSummaryRecord
   */
  total?: number;
  /**
   *
   * @type {ModelsSTUnit}
   * @memberof ModelsStockTakingSummaryRecord
   */
  unit?: ModelsSTUnit;
  /**
   *
   * @type {ModelsSTWarehouse}
   * @memberof ModelsStockTakingSummaryRecord
   */
  warehouse?: ModelsSTWarehouse;
}

/**
 *
 * @export
 * @interface ModelsTransactionCashflowRecord
 */
export interface ModelsTransactionCashflowRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionCashflowRecord
   */
  amount?: number;
  /**
   *
   * @type {ModelsPOSUser}
   * @memberof ModelsTransactionCashflowRecord
   */
  by?: ModelsPOSUser;
  comment?: string;
  /**
   *
   * @type {ModelsPOSCurrency}
   * @memberof ModelsTransactionCashflowRecord
   */
  currency?: ModelsPOSCurrency;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionCashflowRecord
   */
  dateTimeUnix?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionCashflowRecord
   */
  id?: number;
  /**
   *
   * @type {ModelsPOSSalepoint}
   * @memberof ModelsTransactionCashflowRecord
   */
  pointOfSale?: ModelsPOSSalepoint;
  /**
   *
   * @type {ModelsPOSWarehouse}
   * @memberof ModelsTransactionCashflowRecord
   */
  warehouse?: ModelsPOSWarehouse;
}

/**
 *
 * @export
 * @interface ModelsTransactionCreditRecord
 */
export interface ModelsTransactionCreditRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionCreditRecord
   */
  amount?: number;
  /**
   *
   * @type {ModelsPOSCurrency}
   * @memberof ModelsTransactionCreditRecord
   */
  currency?: ModelsPOSCurrency;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionCreditRecord
   */
  dateTimeUnix?: number;
  /**
   *
   * @type {ModelsDocument}
   * @memberof ModelsTransactionCreditRecord
   */
  document?: ModelsDocument;
  /**
   *
   * @type {ModelsPOSSalepoint}
   * @memberof ModelsTransactionCreditRecord
   */
  pointOfSale?: ModelsPOSSalepoint;
  /**
   *
   * @type {ModelsPOSWarehouse}
   * @memberof ModelsTransactionCreditRecord
   */
  warehouse?: ModelsPOSWarehouse;
}

/**
 *
 * @export
 * @interface ModelsTransactionRecord
 */
export interface ModelsTransactionRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionRecord
   */
  amount?: number;
  /**
   *
   * @type {string}
   * @memberof ModelsTransactionRecord
   */
  cardNumber?: string;
  /**
   *
   * @type {ModelsPOSCurrency}
   * @memberof ModelsTransactionRecord
   */
  currency?: ModelsPOSCurrency;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionRecord
   */
  dateTimeUnix?: number;
  /**
   *
   * @type {ModelsDocument}
   * @memberof ModelsTransactionRecord
   */
  document?: ModelsDocument;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionRecord
   */
  id?: number;
  /**
   *
   * @type {ModelsPaymentType}
   * @memberof ModelsTransactionRecord
   */
  paymentType?: ModelsPaymentType;
  /**
   *
   * @type {ModelsPOSSalepoint}
   * @memberof ModelsTransactionRecord
   */
  pointOfSale?: ModelsPOSSalepoint;
  /**
   *
   * @type {ModelsPOSWarehouse}
   * @memberof ModelsTransactionRecord
   */
  warehouse?: ModelsPOSWarehouse;
}

/**
 *
 * @export
 * @interface ModelsTransactionTotal
 */
export interface ModelsTransactionTotal {
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotal
   */
  counted?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotal
   */
  expected?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotal
   */
  income?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotal
   */
  initialAmount?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotal
   */
  overShortAmount?: number;
  /**
   *
   * @type {ModelsPaymentType}
   * @memberof ModelsTransactionTotal
   */
  paymentType?: ModelsPaymentType;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotal
   */
  totalCashInAndOut?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotal
   */
  varianceReasonID?: number;
}

/**
 *
 * @export
 * @interface ModelsTransactionTotalByTypeRecord
 */
export interface ModelsTransactionTotalByTypeRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotalByTypeRecord
   */
  closedAtUnix?: number;
  /**
   *
   * @type {ModelsPOSCurrency}
   * @memberof ModelsTransactionTotalByTypeRecord
   */
  currency?: ModelsPOSCurrency;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotalByTypeRecord
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionTotalByTypeRecord
   */
  openedAtUnix?: number;
  /**
   *
   * @type {ModelsPOSSalepoint}
   * @memberof ModelsTransactionTotalByTypeRecord
   */
  pointOfSale?: ModelsPOSSalepoint;
  /**
   *
   * @type {Array<ModelsTransactionTotal>}
   * @memberof ModelsTransactionTotalByTypeRecord
   */
  transactionTotals?: Array<ModelsTransactionTotal>;
  /**
   *
   * @type {ModelsPOSWarehouse}
   * @memberof ModelsTransactionTotalByTypeRecord
   */
  warehouse?: ModelsPOSWarehouse;
}

/**
 *
 * @export
 * @interface ModelsTransactionVoidRecord
 */
export interface ModelsTransactionVoidRecord {
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionVoidRecord
   */
  amount?: number;
  /**
   *
   * @type {ModelsPOSCurrency}
   * @memberof ModelsTransactionVoidRecord
   */
  currency?: ModelsPOSCurrency;
  /**
   *
   * @type {number}
   * @memberof ModelsTransactionVoidRecord
   */
  dateTimeUnix?: number;
  /**
   *
   * @type {ModelsDocument}
   * @memberof ModelsTransactionVoidRecord
   */
  document?: ModelsDocument;
  /**
   *
   * @type {ModelsPaymentType}
   * @memberof ModelsTransactionVoidRecord
   */
  paymentType?: ModelsPaymentType;
  /**
   *
   * @type {ModelsPOSSalepoint}
   * @memberof ModelsTransactionVoidRecord
   */
  pointOfSale?: ModelsPOSSalepoint;
}

/**
 * GeneralApi - fetch parameter creator
 * @export
 */
export const GeneralApiFetchParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     * Get error code descriptions
     * @summary Get error code descriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorDescriptionsGet(options: any = {}): FetchArgs {
      const localVarPath = `/error-descriptions`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get error code descriptions
     * @summary Get error code descriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorDescriptionsGet(
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ModelsOutputOK> {
      const localVarFetchArgs = GeneralApiFetchParamCreator(
        configuration,
      ).errorDescriptionsGet(options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function(
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Get error code descriptions
     * @summary Get error code descriptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    errorDescriptionsGet(options?: any) {
      return GeneralApiFp(configuration).errorDescriptionsGet(options)(
        fetch,
        basePath,
      );
    },
  };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
  /**
   * Get error code descriptions
   * @summary Get error code descriptions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralApi
   */
  public errorDescriptionsGet(options?: any) {
    return GeneralApiFp(this.configuration).errorDescriptionsGet(options)(
      this.fetch,
      this.basePath,
    );
  }
}

/**
 * LogsApi - fetch parameter creator
 * @export
 */
export const LogsApiFetchParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     * Get login logs Need to have the 'um_log_logins' permission to be able to perform this request
     * @summary Get login logs
     * @param {string} [startTimestamp] Filter up to timestamp: yyyy-mm-dd hh:ii:ss
     * @param {string} [endTimestamp] Filter until timestamp: yyyy-mm-dd hh:ii:ss
     * @param {string} [ipAddress] Filter by ip address
     * @param {string} [domain] Filter by domain
     * @param {string} [username] Filter by username
     * @param {boolean} [loginSuccessful] Filter by successful or failed logins
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1LogsLoginsGet(
      startTimestamp?: string,
      endTimestamp?: string,
      ipAddress?: string,
      domain?: string,
      username?: string,
      loginSuccessful?: boolean,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/v1/logs/logins`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startTimestamp !== undefined) {
        localVarQueryParameter['startTimestamp'] = startTimestamp;
      }

      if (endTimestamp !== undefined) {
        localVarQueryParameter['endTimestamp'] = endTimestamp;
      }

      if (ipAddress !== undefined) {
        localVarQueryParameter['ipAddress'] = ipAddress;
      }

      if (domain !== undefined) {
        localVarQueryParameter['domain'] = domain;
      }

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (loginSuccessful !== undefined) {
        localVarQueryParameter['loginSuccessful'] = loginSuccessful;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get login logs Need to have the 'um_log_logins' permission to be able to perform this request
     * @summary Get login logs
     * @param {string} [startTimestamp] Filter up to timestamp: yyyy-mm-dd hh:ii:ss
     * @param {string} [endTimestamp] Filter until timestamp: yyyy-mm-dd hh:ii:ss
     * @param {string} [ipAddress] Filter by ip address
     * @param {string} [domain] Filter by domain
     * @param {string} [username] Filter by username
     * @param {boolean} [loginSuccessful] Filter by successful or failed logins
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1LogsLoginsGet(
      startTimestamp?: string,
      endTimestamp?: string,
      ipAddress?: string,
      domain?: string,
      username?: string,
      loginSuccessful?: boolean,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetLoginLogs> {
      const localVarFetchArgs = LogsApiFetchParamCreator(
        configuration,
      ).v1LogsLoginsGet(
        startTimestamp,
        endTimestamp,
        ipAddress,
        domain,
        username,
        loginSuccessful,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function(
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Get login logs Need to have the 'um_log_logins' permission to be able to perform this request
     * @summary Get login logs
     * @param {string} [startTimestamp] Filter up to timestamp: yyyy-mm-dd hh:ii:ss
     * @param {string} [endTimestamp] Filter until timestamp: yyyy-mm-dd hh:ii:ss
     * @param {string} [ipAddress] Filter by ip address
     * @param {string} [domain] Filter by domain
     * @param {string} [username] Filter by username
     * @param {boolean} [loginSuccessful] Filter by successful or failed logins
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1LogsLoginsGet(
      startTimestamp?: string,
      endTimestamp?: string,
      ipAddress?: string,
      domain?: string,
      username?: string,
      loginSuccessful?: boolean,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return LogsApiFp(configuration).v1LogsLoginsGet(
        startTimestamp,
        endTimestamp,
        ipAddress,
        domain,
        username,
        loginSuccessful,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
  /**
   * Get login logs Need to have the 'um_log_logins' permission to be able to perform this request
   * @summary Get login logs
   * @param {string} [startTimestamp] Filter up to timestamp: yyyy-mm-dd hh:ii:ss
   * @param {string} [endTimestamp] Filter until timestamp: yyyy-mm-dd hh:ii:ss
   * @param {string} [ipAddress] Filter by ip address
   * @param {string} [domain] Filter by domain
   * @param {string} [username] Filter by username
   * @param {boolean} [loginSuccessful] Filter by successful or failed logins
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LogsApi
   */
  public v1LogsLoginsGet(
    startTimestamp?: string,
    endTimestamp?: string,
    ipAddress?: string,
    domain?: string,
    username?: string,
    loginSuccessful?: boolean,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return LogsApiFp(this.configuration).v1LogsLoginsGet(
      startTimestamp,
      endTimestamp,
      ipAddress,
      domain,
      username,
      loginSuccessful,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * PackagingApi - fetch parameter creator
 * @export
 */
export const PackagingApiFetchParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     * Get packaging materials report from purchase documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
     * @summary Get packaging materials report from purchase documents
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [supplierIds] Supplier ID&#39;s. Comma separated list.
     * @param {string} [purchaseDocumentIds] Purchase document ID&#39;s. Comma separated list.
     * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
     * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1PackagingPurchaseGet(
      startDate: string,
      endDate: string,
      supplierIds?: string,
      purchaseDocumentIds?: string,
      language?: string,
      showProductsWithoutPackages?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling v1PackagingPurchaseGet.',
        );
      }
      // verify required parameter 'endDate' is not null or undefined
      if (endDate === null || endDate === undefined) {
        throw new RequiredError(
          'endDate',
          'Required parameter endDate was null or undefined when calling v1PackagingPurchaseGet.',
        );
      }
      const localVarPath = `/v1/packaging/purchase`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (supplierIds !== undefined) {
        localVarQueryParameter['supplierIds'] = supplierIds;
      }

      if (purchaseDocumentIds !== undefined) {
        localVarQueryParameter['purchaseDocumentIds'] = purchaseDocumentIds;
      }

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      if (showProductsWithoutPackages !== undefined) {
        localVarQueryParameter[
          'showProductsWithoutPackages'
        ] = showProductsWithoutPackages;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get packaging materials report from sales documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
     * @summary Get packaging materials report from sales documents
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [salesDocumentIds] Sales document ID&#39;s. Comma separated list.
     * @param {'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU'} [revenueType] Revenue type
     * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
     * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1PackagingSalesGet(
      startDate: string,
      endDate: string,
      salesDocumentIds?: string,
      revenueType?: 'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU',
      language?: string,
      showProductsWithoutPackages?: boolean,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling v1PackagingSalesGet.',
        );
      }
      // verify required parameter 'endDate' is not null or undefined
      if (endDate === null || endDate === undefined) {
        throw new RequiredError(
          'endDate',
          'Required parameter endDate was null or undefined when calling v1PackagingSalesGet.',
        );
      }
      const localVarPath = `/v1/packaging/sales`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (salesDocumentIds !== undefined) {
        localVarQueryParameter['salesDocumentIds'] = salesDocumentIds;
      }

      if (revenueType !== undefined) {
        localVarQueryParameter['revenueType'] = revenueType;
      }

      if (language !== undefined) {
        localVarQueryParameter['language'] = language;
      }

      if (showProductsWithoutPackages !== undefined) {
        localVarQueryParameter[
          'showProductsWithoutPackages'
        ] = showProductsWithoutPackages;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PackagingApi - functional programming interface
 * @export
 */
export const PackagingApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get packaging materials report from purchase documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
     * @summary Get packaging materials report from purchase documents
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [supplierIds] Supplier ID&#39;s. Comma separated list.
     * @param {string} [purchaseDocumentIds] Purchase document ID&#39;s. Comma separated list.
     * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
     * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1PackagingPurchaseGet(
      startDate: string,
      endDate: string,
      supplierIds?: string,
      purchaseDocumentIds?: string,
      language?: string,
      showProductsWithoutPackages?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputPackagingPurchases> {
      const localVarFetchArgs = PackagingApiFetchParamCreator(
        configuration,
      ).v1PackagingPurchaseGet(
        startDate,
        endDate,
        supplierIds,
        purchaseDocumentIds,
        language,
        showProductsWithoutPackages,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get packaging materials report from sales documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
     * @summary Get packaging materials report from sales documents
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [salesDocumentIds] Sales document ID&#39;s. Comma separated list.
     * @param {'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU'} [revenueType] Revenue type
     * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
     * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1PackagingSalesGet(
      startDate: string,
      endDate: string,
      salesDocumentIds?: string,
      revenueType?: 'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU',
      language?: string,
      showProductsWithoutPackages?: boolean,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputPackagingSales> {
      const localVarFetchArgs = PackagingApiFetchParamCreator(
        configuration,
      ).v1PackagingSalesGet(
        startDate,
        endDate,
        salesDocumentIds,
        revenueType,
        language,
        showProductsWithoutPackages,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * PackagingApi - factory interface
 * @export
 */
export const PackagingApiFactory = function(
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Get packaging materials report from purchase documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
     * @summary Get packaging materials report from purchase documents
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [supplierIds] Supplier ID&#39;s. Comma separated list.
     * @param {string} [purchaseDocumentIds] Purchase document ID&#39;s. Comma separated list.
     * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
     * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1PackagingPurchaseGet(
      startDate: string,
      endDate: string,
      supplierIds?: string,
      purchaseDocumentIds?: string,
      language?: string,
      showProductsWithoutPackages?: boolean,
      options?: any,
    ) {
      return PackagingApiFp(configuration).v1PackagingPurchaseGet(
        startDate,
        endDate,
        supplierIds,
        purchaseDocumentIds,
        language,
        showProductsWithoutPackages,
        options,
      )(fetch, basePath);
    },
    /**
     * Get packaging materials report from sales documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
     * @summary Get packaging materials report from sales documents
     * @param {string} startDate Start date: yyyy-mm-dd
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [salesDocumentIds] Sales document ID&#39;s. Comma separated list.
     * @param {'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU'} [revenueType] Revenue type
     * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
     * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1PackagingSalesGet(
      startDate: string,
      endDate: string,
      salesDocumentIds?: string,
      revenueType?: 'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU',
      language?: string,
      showProductsWithoutPackages?: boolean,
      options?: any,
    ) {
      return PackagingApiFp(configuration).v1PackagingSalesGet(
        startDate,
        endDate,
        salesDocumentIds,
        revenueType,
        language,
        showProductsWithoutPackages,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * PackagingApi - object-oriented interface
 * @export
 * @class PackagingApi
 * @extends {BaseAPI}
 */
export class PackagingApi extends BaseAPI {
  /**
   * Get packaging materials report from purchase documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
   * @summary Get packaging materials report from purchase documents
   * @param {string} startDate Start date: yyyy-mm-dd
   * @param {string} endDate End date: yyyy-mm-dd
   * @param {string} [supplierIds] Supplier ID&#39;s. Comma separated list.
   * @param {string} [purchaseDocumentIds] Purchase document ID&#39;s. Comma separated list.
   * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
   * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackagingApi
   */
  public v1PackagingPurchaseGet(
    startDate: string,
    endDate: string,
    supplierIds?: string,
    purchaseDocumentIds?: string,
    language?: string,
    showProductsWithoutPackages?: boolean,
    options?: any,
  ) {
    return PackagingApiFp(this.configuration).v1PackagingPurchaseGet(
      startDate,
      endDate,
      supplierIds,
      purchaseDocumentIds,
      language,
      showProductsWithoutPackages,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get packaging materials report from sales documents You need to have the 'reports' access to be able to use this request. Note that the maximum allowed date range for this report is 1 year. API returns code 2021 when the range is exceeded.
   * @summary Get packaging materials report from sales documents
   * @param {string} startDate Start date: yyyy-mm-dd
   * @param {string} endDate End date: yyyy-mm-dd
   * @param {string} [salesDocumentIds] Sales document ID&#39;s. Comma separated list.
   * @param {'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU'} [revenueType] Revenue type
   * @param {string} [language] Get translatable values in selected language. Default account language is used when not specified.
   * @param {boolean} [showProductsWithoutPackages] When set to true API will also return product rows where no packages have been defined.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackagingApi
   */
  public v1PackagingSalesGet(
    startDate: string,
    endDate: string,
    salesDocumentIds?: string,
    revenueType?: 'DOMESTIC' | 'EU' | 'EU-VAT' | 'NON-EU',
    language?: string,
    showProductsWithoutPackages?: boolean,
    options?: any,
  ) {
    return PackagingApiFp(this.configuration).v1PackagingSalesGet(
      startDate,
      endDate,
      salesDocumentIds,
      revenueType,
      language,
      showProductsWithoutPackages,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * SalesByApi - fetch parameter creator
 * @export
 */
export const SalesByApiFetchParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     * Get sales results bucketed by employees and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by employees
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {string} [warehouseIds] Warehouse/Location id&#39;s as a comma separated string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByEmployeesGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      warehouseIds?: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDateTime' is not null or undefined
      if (startDateTime === null || startDateTime === undefined) {
        throw new RequiredError(
          'startDateTime',
          'Required parameter startDateTime was null or undefined when calling v1SalesByEmployeesGet.',
        );
      }
      // verify required parameter 'endDateTime' is not null or undefined
      if (endDateTime === null || endDateTime === undefined) {
        throw new RequiredError(
          'endDateTime',
          'Required parameter endDateTime was null or undefined when calling v1SalesByEmployeesGet.',
        );
      }
      // verify required parameter 'granularity' is not null or undefined
      if (granularity === null || granularity === undefined) {
        throw new RequiredError(
          'granularity',
          'Required parameter granularity was null or undefined when calling v1SalesByEmployeesGet.',
        );
      }
      const localVarPath = `/v1/sales/by-employees`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDateTime !== undefined) {
        localVarQueryParameter['startDateTime'] = startDateTime;
      }

      if (endDateTime !== undefined) {
        localVarQueryParameter['endDateTime'] = endDateTime;
      }

      if (granularity !== undefined) {
        localVarQueryParameter['granularity'] = granularity;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get sales results bucketed by locations and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by locations
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByLocationsGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDateTime' is not null or undefined
      if (startDateTime === null || startDateTime === undefined) {
        throw new RequiredError(
          'startDateTime',
          'Required parameter startDateTime was null or undefined when calling v1SalesByLocationsGet.',
        );
      }
      // verify required parameter 'endDateTime' is not null or undefined
      if (endDateTime === null || endDateTime === undefined) {
        throw new RequiredError(
          'endDateTime',
          'Required parameter endDateTime was null or undefined when calling v1SalesByLocationsGet.',
        );
      }
      // verify required parameter 'granularity' is not null or undefined
      if (granularity === null || granularity === undefined) {
        throw new RequiredError(
          'granularity',
          'Required parameter granularity was null or undefined when calling v1SalesByLocationsGet.',
        );
      }
      const localVarPath = `/v1/sales/by-locations`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDateTime !== undefined) {
        localVarQueryParameter['startDateTime'] = startDateTime;
      }

      if (endDateTime !== undefined) {
        localVarQueryParameter['endDateTime'] = endDateTime;
      }

      if (granularity !== undefined) {
        localVarQueryParameter['granularity'] = granularity;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get sales results bucketed by registers and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by registers
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByRegistersGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDateTime' is not null or undefined
      if (startDateTime === null || startDateTime === undefined) {
        throw new RequiredError(
          'startDateTime',
          'Required parameter startDateTime was null or undefined when calling v1SalesByRegistersGet.',
        );
      }
      // verify required parameter 'endDateTime' is not null or undefined
      if (endDateTime === null || endDateTime === undefined) {
        throw new RequiredError(
          'endDateTime',
          'Required parameter endDateTime was null or undefined when calling v1SalesByRegistersGet.',
        );
      }
      // verify required parameter 'granularity' is not null or undefined
      if (granularity === null || granularity === undefined) {
        throw new RequiredError(
          'granularity',
          'Required parameter granularity was null or undefined when calling v1SalesByRegistersGet.',
        );
      }
      const localVarPath = `/v1/sales/by-registers`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDateTime !== undefined) {
        localVarQueryParameter['startDateTime'] = startDateTime;
      }

      if (endDateTime !== undefined) {
        localVarQueryParameter['endDateTime'] = endDateTime;
      }

      if (granularity !== undefined) {
        localVarQueryParameter['granularity'] = granularity;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SalesByApi - functional programming interface
 * @export
 */
export const SalesByApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get sales results bucketed by employees and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by employees
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {string} [warehouseIds] Warehouse/Location id&#39;s as a comma separated string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByEmployeesGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      warehouseIds?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetSalesBy> {
      const localVarFetchArgs = SalesByApiFetchParamCreator(
        configuration,
      ).v1SalesByEmployeesGet(
        startDateTime,
        endDateTime,
        granularity,
        warehouseIds,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get sales results bucketed by locations and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by locations
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByLocationsGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetSalesBy> {
      const localVarFetchArgs = SalesByApiFetchParamCreator(
        configuration,
      ).v1SalesByLocationsGet(startDateTime, endDateTime, granularity, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get sales results bucketed by registers and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by registers
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByRegistersGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetSalesBy> {
      const localVarFetchArgs = SalesByApiFetchParamCreator(
        configuration,
      ).v1SalesByRegistersGet(startDateTime, endDateTime, granularity, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * SalesByApi - factory interface
 * @export
 */
export const SalesByApiFactory = function(
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Get sales results bucketed by employees and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by employees
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {string} [warehouseIds] Warehouse/Location id&#39;s as a comma separated string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByEmployeesGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      warehouseIds?: string,
      options?: any,
    ) {
      return SalesByApiFp(configuration).v1SalesByEmployeesGet(
        startDateTime,
        endDateTime,
        granularity,
        warehouseIds,
        options,
      )(fetch, basePath);
    },
    /**
     * Get sales results bucketed by locations and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by locations
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByLocationsGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      options?: any,
    ) {
      return SalesByApiFp(configuration).v1SalesByLocationsGet(
        startDateTime,
        endDateTime,
        granularity,
        options,
      )(fetch, basePath);
    },
    /**
     * Get sales results bucketed by registers and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
     * @summary Get sales results bucketed by registers
     * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
     * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
     * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesByRegistersGet(
      startDateTime: string,
      endDateTime: string,
      granularity:
        | 'RANGE'
        | 'HOUR'
        | 'DAY'
        | 'WEEK'
        | 'MONTH'
        | 'QUARTER'
        | 'YEAR',
      options?: any,
    ) {
      return SalesByApiFp(configuration).v1SalesByRegistersGet(
        startDateTime,
        endDateTime,
        granularity,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * SalesByApi - object-oriented interface
 * @export
 * @class SalesByApi
 * @extends {BaseAPI}
 */
export class SalesByApi extends BaseAPI {
  /**
   * Get sales results bucketed by employees and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
   * @summary Get sales results bucketed by employees
   * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
   * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
   * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
   * @param {string} [warehouseIds] Warehouse/Location id&#39;s as a comma separated string
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesByApi
   */
  public v1SalesByEmployeesGet(
    startDateTime: string,
    endDateTime: string,
    granularity:
      | 'RANGE'
      | 'HOUR'
      | 'DAY'
      | 'WEEK'
      | 'MONTH'
      | 'QUARTER'
      | 'YEAR',
    warehouseIds?: string,
    options?: any,
  ) {
    return SalesByApiFp(this.configuration).v1SalesByEmployeesGet(
      startDateTime,
      endDateTime,
      granularity,
      warehouseIds,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get sales results bucketed by locations and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
   * @summary Get sales results bucketed by locations
   * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
   * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
   * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesByApi
   */
  public v1SalesByLocationsGet(
    startDateTime: string,
    endDateTime: string,
    granularity:
      | 'RANGE'
      | 'HOUR'
      | 'DAY'
      | 'WEEK'
      | 'MONTH'
      | 'QUARTER'
      | 'YEAR',
    options?: any,
  ) {
    return SalesByApiFp(this.configuration).v1SalesByLocationsGet(
      startDateTime,
      endDateTime,
      granularity,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get sales results bucketed by registers and requested granularity. You need to have the 'reports' access to be able to use this request. The results contain tax. The granularities have set range limits. API will respond with code 2021 when this limit is exceeded. - RANGE   - 1825 days - HOUR    - 7 days - DAY     - 365 days - WEEK    - 365 days - MONTH   - 730 days - QUARTER - 1825 days - YEAR    - 1825 days
   * @summary Get sales results bucketed by registers
   * @param {string} startDateTime Start datetime: yyyy-mm-dd HH:ii:ss.
   * @param {string} endDateTime End datetime: yyyy-mm-dd HH:ii:ss
   * @param {'RANGE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR'} granularity Time granularity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesByApi
   */
  public v1SalesByRegistersGet(
    startDateTime: string,
    endDateTime: string,
    granularity:
      | 'RANGE'
      | 'HOUR'
      | 'DAY'
      | 'WEEK'
      | 'MONTH'
      | 'QUARTER'
      | 'YEAR',
    options?: any,
  ) {
    return SalesByApiFp(this.configuration).v1SalesByRegistersGet(
      startDateTime,
      endDateTime,
      granularity,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * SalesTotalsApi - fetch parameter creator
 * @export
 */
export const SalesTotalsApiFetchParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     * Get sales by associate data for a period
     * @summary Get sales by associate data for a period
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesAssociateTotalsGet(
      startDate: string,
      endDate: string,
      warehouseIds?: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling v1SalesAssociateTotalsGet.',
        );
      }
      // verify required parameter 'endDate' is not null or undefined
      if (endDate === null || endDate === undefined) {
        throw new RequiredError(
          'endDate',
          'Required parameter endDate was null or undefined when calling v1SalesAssociateTotalsGet.',
        );
      }
      const localVarPath = `/v1/sales/associate-totals`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get sales revenue as daily totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as daily totals
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesDailyTotalsGet(
      startDate: string,
      endDate: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling v1SalesDailyTotalsGet.',
        );
      }
      // verify required parameter 'endDate' is not null or undefined
      if (endDate === null || endDate === undefined) {
        throw new RequiredError(
          'endDate',
          'Required parameter endDate was null or undefined when calling v1SalesDailyTotalsGet.',
        );
      }
      const localVarPath = `/v1/sales/daily-totals`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as monthly totals
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 24 months.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesMonthlyTotalsGet(
      startDate: string,
      endDate: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'startDate' is not null or undefined
      if (startDate === null || startDate === undefined) {
        throw new RequiredError(
          'startDate',
          'Required parameter startDate was null or undefined when calling v1SalesMonthlyTotalsGet.',
        );
      }
      // verify required parameter 'endDate' is not null or undefined
      if (endDate === null || endDate === undefined) {
        throw new RequiredError(
          'endDate',
          'Required parameter endDate was null or undefined when calling v1SalesMonthlyTotalsGet.',
        );
      }
      const localVarPath = `/v1/sales/monthly-totals`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] = endDate;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SalesTotalsApi - functional programming interface
 * @export
 */
export const SalesTotalsApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get sales by associate data for a period
     * @summary Get sales by associate data for a period
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesAssociateTotalsGet(
      startDate: string,
      endDate: string,
      warehouseIds?: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetSalesByAssociate> {
      const localVarFetchArgs = SalesTotalsApiFetchParamCreator(
        configuration,
      ).v1SalesAssociateTotalsGet(startDate, endDate, warehouseIds, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get sales revenue as daily totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as daily totals
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesDailyTotalsGet(
      startDate: string,
      endDate: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetSalesRevenueDaily> {
      const localVarFetchArgs = SalesTotalsApiFetchParamCreator(
        configuration,
      ).v1SalesDailyTotalsGet(startDate, endDate, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as monthly totals
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 24 months.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesMonthlyTotalsGet(
      startDate: string,
      endDate: string,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetSalesRevenueMonthly> {
      const localVarFetchArgs = SalesTotalsApiFetchParamCreator(
        configuration,
      ).v1SalesMonthlyTotalsGet(startDate, endDate, options);
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * SalesTotalsApi - factory interface
 * @export
 */
export const SalesTotalsApiFactory = function(
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Get sales by associate data for a period
     * @summary Get sales by associate data for a period
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesAssociateTotalsGet(
      startDate: string,
      endDate: string,
      warehouseIds?: string,
      options?: any,
    ) {
      return SalesTotalsApiFp(configuration).v1SalesAssociateTotalsGet(
        startDate,
        endDate,
        warehouseIds,
        options,
      )(fetch, basePath);
    },
    /**
     * Get sales revenue as daily totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as daily totals
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesDailyTotalsGet(startDate: string, endDate: string, options?: any) {
      return SalesTotalsApiFp(configuration).v1SalesDailyTotalsGet(
        startDate,
        endDate,
        options,
      )(fetch, basePath);
    },
    /**
     * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
     * @summary Get sales revenue as monthly totals
     * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 24 months.
     * @param {string} endDate End date: yyyy-mm-dd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1SalesMonthlyTotalsGet(startDate: string, endDate: string, options?: any) {
      return SalesTotalsApiFp(configuration).v1SalesMonthlyTotalsGet(
        startDate,
        endDate,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * SalesTotalsApi - object-oriented interface
 * @export
 * @class SalesTotalsApi
 * @extends {BaseAPI}
 */
export class SalesTotalsApi extends BaseAPI {
  /**
   * Get sales by associate data for a period
   * @summary Get sales by associate data for a period
   * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
   * @param {string} endDate End date: yyyy-mm-dd
   * @param {string} [warehouseIds] Warehouse ids comma separated.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesTotalsApi
   */
  public v1SalesAssociateTotalsGet(
    startDate: string,
    endDate: string,
    warehouseIds?: string,
    options?: any,
  ) {
    return SalesTotalsApiFp(this.configuration).v1SalesAssociateTotalsGet(
      startDate,
      endDate,
      warehouseIds,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get sales revenue as daily totals NOTE! The returned totals contain tax.
   * @summary Get sales revenue as daily totals
   * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 90 days.
   * @param {string} endDate End date: yyyy-mm-dd
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesTotalsApi
   */
  public v1SalesDailyTotalsGet(
    startDate: string,
    endDate: string,
    options?: any,
  ) {
    return SalesTotalsApiFp(this.configuration).v1SalesDailyTotalsGet(
      startDate,
      endDate,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get sales revenue as monthly totals NOTE! The returned totals contain tax.
   * @summary Get sales revenue as monthly totals
   * @param {string} startDate Start date: yyyy-mm-dd. Maximum allowed range is 24 months.
   * @param {string} endDate End date: yyyy-mm-dd
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesTotalsApi
   */
  public v1SalesMonthlyTotalsGet(
    startDate: string,
    endDate: string,
    options?: any,
  ) {
    return SalesTotalsApiFp(this.configuration).v1SalesMonthlyTotalsGet(
      startDate,
      endDate,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * StocktakingApi - fetch parameter creator
 * @export
 */
export const StocktakingApiFetchParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     * Get summary of stocktakings
     * @summary Get summary of stocktakings
     * @param {number} unixTimeStart UNIX start time.
     * @param {number} unixTimeEnd UNIX end time.
     * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
     * @param {string} [inventoryReportIds] Inventory report ids comma separated.
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {string} [seriesIds] Series ids comma separated.
     * @param {string} [groupIds] Product group ids comma separated.
     * @param {string} [productCodes] Product codes comma separated.
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1StocktakingSummaryGet(
      unixTimeStart: number,
      unixTimeEnd: number,
      languageCode: string,
      inventoryReportIds?: string,
      warehouseIds?: string,
      seriesIds?: string,
      groupIds?: string,
      productCodes?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1StocktakingSummaryGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1StocktakingSummaryGet.',
        );
      }
      // verify required parameter 'languageCode' is not null or undefined
      if (languageCode === null || languageCode === undefined) {
        throw new RequiredError(
          'languageCode',
          'Required parameter languageCode was null or undefined when calling v1StocktakingSummaryGet.',
        );
      }
      const localVarPath = `/v1/stocktaking/summary`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (languageCode !== undefined) {
        localVarQueryParameter['languageCode'] = languageCode;
      }

      if (inventoryReportIds !== undefined) {
        localVarQueryParameter['inventoryReportIds'] = inventoryReportIds;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      if (seriesIds !== undefined) {
        localVarQueryParameter['seriesIds'] = seriesIds;
      }

      if (groupIds !== undefined) {
        localVarQueryParameter['groupIds'] = groupIds;
      }

      if (productCodes !== undefined) {
        localVarQueryParameter['productCodes'] = productCodes;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StocktakingApi - functional programming interface
 * @export
 */
export const StocktakingApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get summary of stocktakings
     * @summary Get summary of stocktakings
     * @param {number} unixTimeStart UNIX start time.
     * @param {number} unixTimeEnd UNIX end time.
     * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
     * @param {string} [inventoryReportIds] Inventory report ids comma separated.
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {string} [seriesIds] Series ids comma separated.
     * @param {string} [groupIds] Product group ids comma separated.
     * @param {string} [productCodes] Product codes comma separated.
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1StocktakingSummaryGet(
      unixTimeStart: number,
      unixTimeEnd: number,
      languageCode: string,
      inventoryReportIds?: string,
      warehouseIds?: string,
      seriesIds?: string,
      groupIds?: string,
      productCodes?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetStocktakingSummary> {
      const localVarFetchArgs = StocktakingApiFetchParamCreator(
        configuration,
      ).v1StocktakingSummaryGet(
        unixTimeStart,
        unixTimeEnd,
        languageCode,
        inventoryReportIds,
        warehouseIds,
        seriesIds,
        groupIds,
        productCodes,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * StocktakingApi - factory interface
 * @export
 */
export const StocktakingApiFactory = function(
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Get summary of stocktakings
     * @summary Get summary of stocktakings
     * @param {number} unixTimeStart UNIX start time.
     * @param {number} unixTimeEnd UNIX end time.
     * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
     * @param {string} [inventoryReportIds] Inventory report ids comma separated.
     * @param {string} [warehouseIds] Warehouse ids comma separated.
     * @param {string} [seriesIds] Series ids comma separated.
     * @param {string} [groupIds] Product group ids comma separated.
     * @param {string} [productCodes] Product codes comma separated.
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1StocktakingSummaryGet(
      unixTimeStart: number,
      unixTimeEnd: number,
      languageCode: string,
      inventoryReportIds?: string,
      warehouseIds?: string,
      seriesIds?: string,
      groupIds?: string,
      productCodes?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return StocktakingApiFp(configuration).v1StocktakingSummaryGet(
        unixTimeStart,
        unixTimeEnd,
        languageCode,
        inventoryReportIds,
        warehouseIds,
        seriesIds,
        groupIds,
        productCodes,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * StocktakingApi - object-oriented interface
 * @export
 * @class StocktakingApi
 * @extends {BaseAPI}
 */
export class StocktakingApi extends BaseAPI {
  /**
   * Get summary of stocktakings
   * @summary Get summary of stocktakings
   * @param {number} unixTimeStart UNIX start time.
   * @param {number} unixTimeEnd UNIX end time.
   * @param {string} languageCode Language code of the language to return. Example: eng, est etc.
   * @param {string} [inventoryReportIds] Inventory report ids comma separated.
   * @param {string} [warehouseIds] Warehouse ids comma separated.
   * @param {string} [seriesIds] Series ids comma separated.
   * @param {string} [groupIds] Product group ids comma separated.
   * @param {string} [productCodes] Product codes comma separated.
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StocktakingApi
   */
  public v1StocktakingSummaryGet(
    unixTimeStart: number,
    unixTimeEnd: number,
    languageCode: string,
    inventoryReportIds?: string,
    warehouseIds?: string,
    seriesIds?: string,
    groupIds?: string,
    productCodes?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return StocktakingApiFp(this.configuration).v1StocktakingSummaryGet(
      unixTimeStart,
      unixTimeEnd,
      languageCode,
      inventoryReportIds,
      warehouseIds,
      seriesIds,
      groupIds,
      productCodes,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }
}

/**
 * ZReportsApi - fetch parameter creator
 * @export
 */
export const ZReportsApiFetchParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     * Get POS days Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS days
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {number} [dayId] Day ID.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: number,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      salesManagerIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'currencyId' is not null or undefined
      if (currencyId === null || currencyId === undefined) {
        throw new RequiredError(
          'currencyId',
          'Required parameter currencyId was null or undefined when calling v1POSDayGet.',
        );
      }
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1POSDayGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1POSDayGet.',
        );
      }
      const localVarPath = `/v1/POSDay`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (currencyId !== undefined) {
        localVarQueryParameter['currencyId'] = currencyId;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (dayId !== undefined) {
        localVarQueryParameter['dayId'] = dayId;
      }

      if (pointOfSaleIds !== undefined) {
        localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      if (salesManagerIds !== undefined) {
        localVarQueryParameter['salesManagerIds'] = salesManagerIds;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get POS transactions cashflow Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions cashflow
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCashflowGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'currencyId' is not null or undefined
      if (currencyId === null || currencyId === undefined) {
        throw new RequiredError(
          'currencyId',
          'Required parameter currencyId was null or undefined when calling v1POSDayTransactionCashflowGet.',
        );
      }
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionCashflowGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionCashflowGet.',
        );
      }
      const localVarPath = `/v1/POSDay/transaction/cashflow`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (currencyId !== undefined) {
        localVarQueryParameter['currencyId'] = currencyId;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (dayId !== undefined) {
        localVarQueryParameter['dayId'] = dayId;
      }

      if (pointOfSaleIds !== undefined) {
        localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get POS transactions added credit Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions added credit
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCreditAddedGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'currencyId' is not null or undefined
      if (currencyId === null || currencyId === undefined) {
        throw new RequiredError(
          'currencyId',
          'Required parameter currencyId was null or undefined when calling v1POSDayTransactionCreditAddedGet.',
        );
      }
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionCreditAddedGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionCreditAddedGet.',
        );
      }
      const localVarPath = `/v1/POSDay/transaction/credit-added`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (currencyId !== undefined) {
        localVarQueryParameter['currencyId'] = currencyId;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (dayId !== undefined) {
        localVarQueryParameter['dayId'] = dayId;
      }

      if (pointOfSaleIds !== undefined) {
        localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get POS transactions paid credit Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions paid credit
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCreditPaidGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'currencyId' is not null or undefined
      if (currencyId === null || currencyId === undefined) {
        throw new RequiredError(
          'currencyId',
          'Required parameter currencyId was null or undefined when calling v1POSDayTransactionCreditPaidGet.',
        );
      }
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionCreditPaidGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionCreditPaidGet.',
        );
      }
      const localVarPath = `/v1/POSDay/transaction/credit-paid`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (currencyId !== undefined) {
        localVarQueryParameter['currencyId'] = currencyId;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (dayId !== undefined) {
        localVarQueryParameter['dayId'] = dayId;
      }

      if (pointOfSaleIds !== undefined) {
        localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get POS transactions Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [paymentType] Payment type: CASH, CARD etc
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      paymentType?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'currencyId' is not null or undefined
      if (currencyId === null || currencyId === undefined) {
        throw new RequiredError(
          'currencyId',
          'Required parameter currencyId was null or undefined when calling v1POSDayTransactionGet.',
        );
      }
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionGet.',
        );
      }
      const localVarPath = `/v1/POSDay/transaction`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (currencyId !== undefined) {
        localVarQueryParameter['currencyId'] = currencyId;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (dayId !== undefined) {
        localVarQueryParameter['dayId'] = dayId;
      }

      if (paymentType !== undefined) {
        localVarQueryParameter['paymentType'] = paymentType;
      }

      if (pointOfSaleIds !== undefined) {
        localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get POS transactions total by type Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions totals by type
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionTotalByTypeGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'currencyId' is not null or undefined
      if (currencyId === null || currencyId === undefined) {
        throw new RequiredError(
          'currencyId',
          'Required parameter currencyId was null or undefined when calling v1POSDayTransactionTotalByTypeGet.',
        );
      }
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionTotalByTypeGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionTotalByTypeGet.',
        );
      }
      const localVarPath = `/v1/POSDay/transaction/total-by-type`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (currencyId !== undefined) {
        localVarQueryParameter['currencyId'] = currencyId;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (dayId !== undefined) {
        localVarQueryParameter['dayId'] = dayId;
      }

      if (pointOfSaleIds !== undefined) {
        localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
      }

      if (warehouseIds !== undefined) {
        localVarQueryParameter['warehouseIds'] = warehouseIds;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get POS void transactions Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS void transactions
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionVoidGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'currencyId' is not null or undefined
      if (currencyId === null || currencyId === undefined) {
        throw new RequiredError(
          'currencyId',
          'Required parameter currencyId was null or undefined when calling v1POSDayTransactionVoidGet.',
        );
      }
      // verify required parameter 'unixTimeStart' is not null or undefined
      if (unixTimeStart === null || unixTimeStart === undefined) {
        throw new RequiredError(
          'unixTimeStart',
          'Required parameter unixTimeStart was null or undefined when calling v1POSDayTransactionVoidGet.',
        );
      }
      // verify required parameter 'unixTimeEnd' is not null or undefined
      if (unixTimeEnd === null || unixTimeEnd === undefined) {
        throw new RequiredError(
          'unixTimeEnd',
          'Required parameter unixTimeEnd was null or undefined when calling v1POSDayTransactionVoidGet.',
        );
      }
      const localVarPath = `/v1/POSDay/transaction/void`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication ClientCode required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('clientCode')
            : configuration.apiKey;
        localVarHeaderParameter['clientCode'] = localVarApiKeyValue;
      }

      // authentication SessionKey required
      if (configuration && configuration.apiKey) {
        const localVarApiKeyValue =
          typeof configuration.apiKey === 'function'
            ? configuration.apiKey('sessionKey')
            : configuration.apiKey;
        localVarHeaderParameter['sessionKey'] = localVarApiKeyValue;
      }

      if (currencyId !== undefined) {
        localVarQueryParameter['currencyId'] = currencyId;
      }

      if (unixTimeStart !== undefined) {
        localVarQueryParameter['unixTimeStart'] = unixTimeStart;
      }

      if (unixTimeEnd !== undefined) {
        localVarQueryParameter['unixTimeEnd'] = unixTimeEnd;
      }

      if (dayId !== undefined) {
        localVarQueryParameter['dayId'] = dayId;
      }

      if (pointOfSaleIds !== undefined) {
        localVarQueryParameter['pointOfSaleIds'] = pointOfSaleIds;
      }

      if (pageNo !== undefined) {
        localVarQueryParameter['pageNo'] = pageNo;
      }

      if (recordsOnPage !== undefined) {
        localVarQueryParameter['recordsOnPage'] = recordsOnPage;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ZReportsApi - functional programming interface
 * @export
 */
export const ZReportsApiFp = function(configuration?: Configuration) {
  return {
    /**
     * Get POS days Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS days
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {number} [dayId] Day ID.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: number,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      salesManagerIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetPosDays> {
      const localVarFetchArgs = ZReportsApiFetchParamCreator(
        configuration,
      ).v1POSDayGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        salesManagerIds,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get POS transactions cashflow Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions cashflow
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCashflowGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetTransactionCashflow> {
      const localVarFetchArgs = ZReportsApiFetchParamCreator(
        configuration,
      ).v1POSDayTransactionCashflowGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get POS transactions added credit Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions added credit
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCreditAddedGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetTransactionAddedCredit> {
      const localVarFetchArgs = ZReportsApiFetchParamCreator(
        configuration,
      ).v1POSDayTransactionCreditAddedGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get POS transactions paid credit Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions paid credit
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCreditPaidGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetTransactionPaidCredit> {
      const localVarFetchArgs = ZReportsApiFetchParamCreator(
        configuration,
      ).v1POSDayTransactionCreditPaidGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get POS transactions Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [paymentType] Payment type: CASH, CARD etc
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      paymentType?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetTransactions> {
      const localVarFetchArgs = ZReportsApiFetchParamCreator(
        configuration,
      ).v1POSDayTransactionGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        paymentType,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get POS transactions total by type Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions totals by type
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionTotalByTypeGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetTransactionTotalByType> {
      const localVarFetchArgs = ZReportsApiFetchParamCreator(
        configuration,
      ).v1POSDayTransactionTotalByTypeGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     * Get POS void transactions Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS void transactions
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionVoidGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ): (
      fetch?: FetchAPI,
      basePath?: string,
    ) => Promise<ModelsOutputGetTransactionVoid> {
      const localVarFetchArgs = ZReportsApiFetchParamCreator(
        configuration,
      ).v1POSDayTransactionVoidGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        pageNo,
        recordsOnPage,
        options,
      );
      return (
        fetch: FetchAPI = portableFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ZReportsApi - factory interface
 * @export
 */
export const ZReportsApiFactory = function(
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     * Get POS days Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS days
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {number} [dayId] Day ID.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: number,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      salesManagerIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return ZReportsApiFp(configuration).v1POSDayGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        salesManagerIds,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
    /**
     * Get POS transactions cashflow Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions cashflow
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCashflowGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return ZReportsApiFp(configuration).v1POSDayTransactionCashflowGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
    /**
     * Get POS transactions added credit Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions added credit
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCreditAddedGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return ZReportsApiFp(configuration).v1POSDayTransactionCreditAddedGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
    /**
     * Get POS transactions paid credit Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions paid credit
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionCreditPaidGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return ZReportsApiFp(configuration).v1POSDayTransactionCreditPaidGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
    /**
     * Get POS transactions Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [paymentType] Payment type: CASH, CARD etc
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      paymentType?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return ZReportsApiFp(configuration).v1POSDayTransactionGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        paymentType,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
    /**
     * Get POS transactions total by type Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS transactions totals by type
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionTotalByTypeGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      warehouseIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return ZReportsApiFp(configuration).v1POSDayTransactionTotalByTypeGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        warehouseIds,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
    /**
     * Get POS void transactions Need to have the 'report_cash' permission to be able to perform this request
     * @summary Get POS void transactions
     * @param {number} currencyId Currency Id
     * @param {number} unixTimeStart UNIX start time
     * @param {number} unixTimeEnd UNIX end time
     * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
     * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
     * @param {number} [pageNo] Page number. Default is 1.
     * @param {number} [recordsOnPage] Records on page. Default is 100.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1POSDayTransactionVoidGet(
      currencyId: number,
      unixTimeStart: number,
      unixTimeEnd: number,
      dayId?: string,
      pointOfSaleIds?: string,
      pageNo?: number,
      recordsOnPage?: number,
      options?: any,
    ) {
      return ZReportsApiFp(configuration).v1POSDayTransactionVoidGet(
        currencyId,
        unixTimeStart,
        unixTimeEnd,
        dayId,
        pointOfSaleIds,
        pageNo,
        recordsOnPage,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * ZReportsApi - object-oriented interface
 * @export
 * @class ZReportsApi
 * @extends {BaseAPI}
 */
export class ZReportsApi extends BaseAPI {
  /**
   * Get POS days Need to have the 'report_cash' permission to be able to perform this request
   * @summary Get POS days
   * @param {number} currencyId Currency Id
   * @param {number} unixTimeStart UNIX start time
   * @param {number} unixTimeEnd UNIX end time
   * @param {number} [dayId] Day ID.
   * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
   * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
   * @param {string} [salesManagerIds] Comma separated sales manager id&#39;s
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ZReportsApi
   */
  public v1POSDayGet(
    currencyId: number,
    unixTimeStart: number,
    unixTimeEnd: number,
    dayId?: number,
    pointOfSaleIds?: string,
    warehouseIds?: string,
    salesManagerIds?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return ZReportsApiFp(this.configuration).v1POSDayGet(
      currencyId,
      unixTimeStart,
      unixTimeEnd,
      dayId,
      pointOfSaleIds,
      warehouseIds,
      salesManagerIds,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get POS transactions cashflow Need to have the 'report_cash' permission to be able to perform this request
   * @summary Get POS transactions cashflow
   * @param {number} currencyId Currency Id
   * @param {number} unixTimeStart UNIX start time
   * @param {number} unixTimeEnd UNIX end time
   * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
   * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
   * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ZReportsApi
   */
  public v1POSDayTransactionCashflowGet(
    currencyId: number,
    unixTimeStart: number,
    unixTimeEnd: number,
    dayId?: string,
    pointOfSaleIds?: string,
    warehouseIds?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return ZReportsApiFp(this.configuration).v1POSDayTransactionCashflowGet(
      currencyId,
      unixTimeStart,
      unixTimeEnd,
      dayId,
      pointOfSaleIds,
      warehouseIds,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get POS transactions added credit Need to have the 'report_cash' permission to be able to perform this request
   * @summary Get POS transactions added credit
   * @param {number} currencyId Currency Id
   * @param {number} unixTimeStart UNIX start time
   * @param {number} unixTimeEnd UNIX end time
   * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
   * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
   * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ZReportsApi
   */
  public v1POSDayTransactionCreditAddedGet(
    currencyId: number,
    unixTimeStart: number,
    unixTimeEnd: number,
    dayId?: string,
    pointOfSaleIds?: string,
    warehouseIds?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return ZReportsApiFp(this.configuration).v1POSDayTransactionCreditAddedGet(
      currencyId,
      unixTimeStart,
      unixTimeEnd,
      dayId,
      pointOfSaleIds,
      warehouseIds,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get POS transactions paid credit Need to have the 'report_cash' permission to be able to perform this request
   * @summary Get POS transactions paid credit
   * @param {number} currencyId Currency Id
   * @param {number} unixTimeStart UNIX start time
   * @param {number} unixTimeEnd UNIX end time
   * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
   * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
   * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ZReportsApi
   */
  public v1POSDayTransactionCreditPaidGet(
    currencyId: number,
    unixTimeStart: number,
    unixTimeEnd: number,
    dayId?: string,
    pointOfSaleIds?: string,
    warehouseIds?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return ZReportsApiFp(this.configuration).v1POSDayTransactionCreditPaidGet(
      currencyId,
      unixTimeStart,
      unixTimeEnd,
      dayId,
      pointOfSaleIds,
      warehouseIds,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get POS transactions Need to have the 'report_cash' permission to be able to perform this request
   * @summary Get POS transactions
   * @param {number} currencyId Currency Id
   * @param {number} unixTimeStart UNIX start time
   * @param {number} unixTimeEnd UNIX end time
   * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
   * @param {string} [paymentType] Payment type: CASH, CARD etc
   * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
   * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ZReportsApi
   */
  public v1POSDayTransactionGet(
    currencyId: number,
    unixTimeStart: number,
    unixTimeEnd: number,
    dayId?: string,
    paymentType?: string,
    pointOfSaleIds?: string,
    warehouseIds?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return ZReportsApiFp(this.configuration).v1POSDayTransactionGet(
      currencyId,
      unixTimeStart,
      unixTimeEnd,
      dayId,
      paymentType,
      pointOfSaleIds,
      warehouseIds,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get POS transactions total by type Need to have the 'report_cash' permission to be able to perform this request
   * @summary Get POS transactions totals by type
   * @param {number} currencyId Currency Id
   * @param {number} unixTimeStart UNIX start time
   * @param {number} unixTimeEnd UNIX end time
   * @param {string} [dayId] Day ID
   * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
   * @param {string} [warehouseIds] Comma separated warehouse id&#39;s
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ZReportsApi
   */
  public v1POSDayTransactionTotalByTypeGet(
    currencyId: number,
    unixTimeStart: number,
    unixTimeEnd: number,
    dayId?: string,
    pointOfSaleIds?: string,
    warehouseIds?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return ZReportsApiFp(this.configuration).v1POSDayTransactionTotalByTypeGet(
      currencyId,
      unixTimeStart,
      unixTimeEnd,
      dayId,
      pointOfSaleIds,
      warehouseIds,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   * Get POS void transactions Need to have the 'report_cash' permission to be able to perform this request
   * @summary Get POS void transactions
   * @param {number} currencyId Currency Id
   * @param {number} unixTimeStart UNIX start time
   * @param {number} unixTimeEnd UNIX end time
   * @param {string} [dayId] Day ID. When this is used then pointOfSaleIds parameter is ignored.
   * @param {string} [pointOfSaleIds] Comma separated POS id&#39;s
   * @param {number} [pageNo] Page number. Default is 1.
   * @param {number} [recordsOnPage] Records on page. Default is 100.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ZReportsApi
   */
  public v1POSDayTransactionVoidGet(
    currencyId: number,
    unixTimeStart: number,
    unixTimeEnd: number,
    dayId?: string,
    pointOfSaleIds?: string,
    pageNo?: number,
    recordsOnPage?: number,
    options?: any,
  ) {
    return ZReportsApiFp(this.configuration).v1POSDayTransactionVoidGet(
      currencyId,
      unixTimeStart,
      unixTimeEnd,
      dayId,
      pointOfSaleIds,
      pageNo,
      recordsOnPage,
      options,
    )(this.fetch, this.basePath);
  }
}
