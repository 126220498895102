import { PosPlugin } from 'plugins/plugin';
import { addError } from 'actions/Error';
import { getCurrentModalPopup } from 'reducers/modalPage';

import { getSAINumber } from '../redux/selectors';
import { modals } from '../constants';
import { setScannedQuery } from '../redux/actions';

const preventScanIfNoSAINumber: Required<
  PosPlugin
>['onScan']['before'] = query => async (dispatch, getState) => {
  const SAINumber = getSAINumber(getState());
  if (SAINumber !== null) return;

  const currentPopup = getCurrentModalPopup(getState());

  if (currentPopup?.props?.name !== modals.SAINumberModal) {
    const errorMessage =
      'Please start a sale with a SAI number before scanning';
    dispatch(addError(errorMessage));

    throw new Error(errorMessage);
  }

  dispatch(setScannedQuery(query));
  throw new Error('Cancel further scanning action');
};

export default preventScanIfNoSAINumber;
