import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import {
  LinearProgress,
  Typography,
  Box,
  Divider,
  styled,
} from '@material-ui/core';

import { deletePayment } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { addError, addSuccess, addWarning } from 'actions/Error';
import {
  getCurrencyCode,
  getCurrencyFormatter,
} from 'reducers/configs/settings';
import { getIsCurrentSaleAReturn, getReturnIsPartial } from 'reducers/sales';
import CloseButton from 'components/CustomButtons/CloseButton';
import ListItem from 'components/ListItem';
import { getIsWaitingForTerminal } from 'reducers/Payments';

const SentryCategory = 'cardpayment/ui-hook';

const PreLinedText = styled(Typography)({
  whiteSpace: 'pre-line',
});

const DividerWithMargin = styled(Divider)({
  margin: '1rem',
});

const ContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 15rem)',
  whiteSpace: 'pre-line',
});

const ProgressBar = () => {
  const isWaitingForTerminal = useSelector(getIsWaitingForTerminal);
  const { t } = useTranslation('payment');
  return isWaitingForTerminal ? (
    <Box>
      <LinearProgress />
      <Typography variant="h6">
        {t('cardPaymentUI.waitingForTerminalResponse')}
      </Typography>
    </Box>
  ) : null;
};

const CardPaymentUI = ({
  integration,
  payments: cardPayments,
  resolvePayments,
  rejectPayments,
}) => {
  const { message, title, initPayments, cancelPayments } = integration;
  const dispatch = useDispatch();
  const { t } = useTranslation('payment');

  // Set the title of the Payment UI component
  const [titleText, setTitleText] = useState(
    title || t('cardPaymentUI.defaultTitle'),
  );

  // Set the text message shown in the Payment UI Component Body
  const [messageText, setMessage] = useState(
    message || t('cardPaymentUI.defaultMessage'),
  );
  // Enable/disable buttons in the Payment UI component
  const [enabledButtons, setEnabledButtons] = useState([]);

  const currencyCode = useSelector(getCurrencyCode);
  const format = useSelector(getCurrencyFormatter);
  const formatCurrency = amount => format(Number(amount));
  const isCurrentSaleAReturn = useSelector(getIsCurrentSaleAReturn);
  const isPartialReturn = useSelector(getReturnIsPartial);

  /** ********** Hooks ************ */

  /**
   * @param {string} integrationName Name of current selected integration.
   * @returns Specific data for the current selected integration.
   */

  /**
   *
   * @param {string} message Text that will be displayed as a message in the card payment UI.
   */
  const updateMessage = message => {
    Sentry.addBreadcrumb({
      category: SentryCategory,
      level: 'debug',
      message: 'Message updated',
      data: { message },
    });
    setMessage(message);
  };

  /**
   * @param {arrayOf([string])} array Array of strings that match the name attribute value of the buttons you want to enable.
   */
  const enableButtons = array => {
    Sentry.addBreadcrumb({
      category: SentryCategory,
      level: 'debug',
      message: 'Active buttons changed',
      data: {
        buttons: array,
      },
    });
    if (array && array.length) {
      setEnabledButtons(array);
    } else {
      setEnabledButtons([]);
    }
  };

  /**
   * @param {string} text Sets the title of the modal
   */
  const setTitle = text => {
    Sentry.addBreadcrumb({
      category: SentryCategory,
      level: 'debug',
      message: 'Title changed',
      data: { text },
    });
    setTitleText(text);
  };

  /**
   * @param {string} message Text that will be displayed as an alert.
   */
  const displayWarning = message => {
    dispatch(
      addWarning(message, {
        selfDismiss: true,
      }),
    );
  };

  /**
   * @param {string} message Text that will be displayed as an alert.
   */
  const displayError = message => {
    dispatch(
      addError(message, {
        selfDismiss: true,
      }),
    );
  };

  /**
   * @param {string} message Text that will be displayed as an alert.
   */
  const displaySuccess = message => {
    dispatch(
      addSuccess(message, {
        selfDismiss: true,
      }),
    );
  };

  /**
   * Updates the data that will be saved in the BO before it is saved.
   * @param {Object} params Data that will be passed to the sales document to be saved in the BO.
   */
  const beforeDocSave = async params => {
    Sentry.addBreadcrumb({
      category: SentryCategory,
      level: 'log',
      message: 'Payment success',
      data: { params },
    });
    dispatch(addSuccess(`Payment successful`));

    return dispatch(
      setPayment({
        ...params,
        forceAmount: true,
      }),
    ).then(() => dispatch(setPaymentSelected('')));
  };

  /**
   * Delete the payment that has been voided/returned in case of cancelled Payment operation
   * @param {String} key The key of the successfully returned/voided payment
   */
  const beforeDocDelete = key => {
    Sentry.addBreadcrumb({
      category: SentryCategory,
      level: 'log',
      message: 'Void success',
      data: { key },
    });
    dispatch(deletePayment({ key }));
    dispatch(addSuccess(`Payment voided`, { selfDismiss: true }));
  };

  useEffect(() => {
    // do nothing
  }, [enabledButtons]);

  const cardPaymentHooks = {
    displayWarning,
    displayError,
    updateMessage,
    enabledButtons,
    enableButtons,
    resolvePayments,
    rejectPayments,
    setTitle,
    cardPayments,
    // TODO: These hooks should be based on state.Payment, not state.sales
    //  1) Create selectors for these values that are based on payment state¹
    //  2.a) Remove these, use the selectors instead
    //  2.b) Replace the implementation of these with the new selectors
    //  .
    //  ¹isCurrentSaleAReturn: Based on document type, or total
    //   isPartialReturn: Based on total compared to original document total
    isCurrentSaleAReturn,
    isPartialReturn,
    beforeDocSave,
    beforeDocDelete,
    displaySuccess,
    formatCurrency,
    currencyCode,
  };

  useEffect(() => {
    i18next
      .loadNamespaces('paymentIntegrations')
      .catch(e =>
        console.error(
          'Failed to load translations for payment integrations',
          e,
        ),
      )
      .finally(() => {
        if (initPayments) {
          Sentry.addBreadcrumb({
            category: SentryCategory,
            level: 'log',
            message: 'initPayments called',
          });
          dispatch(initPayments(cardPaymentHooks));
        }
      });
    // passing proper deps here resolves in infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayFunctionButtons = (buttons = []) => {
    return buttons.map(func => (
      <ListItem
        action={() => dispatch(func.actionOnClick(cardPaymentHooks))}
        variant={func.variant}
        key={func.name}
        name={func.name}
        disabled={!enabledButtons.includes(func.name)}
      >
        {func.text}
      </ListItem>
    ));
  };

  return (
    <Box padding="1rem" height="546px">
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <PreLinedText variant="h4">{titleText}</PreLinedText>
          {cancelPayments && (
            <CloseButton
              action={() => {
                dispatch(cancelPayments(cardPaymentHooks));
              }}
            />
          )}
        </Box>
        <DividerWithMargin />
      </Box>
      <ContentBox>
        <Typography variant="h5">{messageText}</Typography>
        <ProgressBar />
      </ContentBox>
      <Box position="absolute" bottom="0" width="95%">
        <DividerWithMargin />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          marginBottom="1rem"
        >
          {displayFunctionButtons(integration.functions)}
          <Box>
            <Typography variant="body1">
              {t('cardPaymentUI.refreshWarning')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CardPaymentUI.defaultProps = {
  integration: {
    initPayments: () => {
      // do nothing
    },
    functions: [],
  },
  resolvePayments: () => {
    // do nothing
  },
  rejectPayments: () => {
    // do nothing
  },
};

CardPaymentUI.propTypes = {
  integration: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    functions: PropTypes.arrayOf(PropTypes.object),
  }),
  resolvePayments: PropTypes.func,
  rejectPayments: PropTypes.func,
};

export default CardPaymentUI;
