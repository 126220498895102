import { uniq } from 'ramda';

import { languageCodeToLegacyIdMapping } from 'utils/languages';
import { doBulkRequest } from 'services/ErplyAPI/core/ErplyAPI';

import { UPDATE_REASON_CODES } from '../constants/reasonCodesDB';
import { getHasReasonCodes } from '../reducers/reasonCodesDB';

// eslint-disable-next-line import/prefer-default-export
export function fetchReasonCodes({ lazy = true, purpose }) {
  return async (dispatch, getState) => {
    const state = getState();
    if (lazy && getHasReasonCodes(purpose)(state)) {
      return;
    }
    const filteredLangsArray = uniq(
      Object.values(languageCodeToLegacyIdMapping),
    );

    const params = filteredLangsArray.map((lang, i) => ({
      requestName: 'getReasonCodes',
      requestID: i,
      purpose,
      // Note to do: Fetch reason codes based on the amount of the account.
      recordsOnPage: 100,
      lang,
    }));
    const results = await doBulkRequest(params);
    const reasonCodesFromAPI = results?.requests
      ?.map(req => req.records)
      .map(records =>
        records.filter(record => record.purpose === purpose.toUpperCase()),
      )
      .filter(arr => arr.length);

    const reasonCodesWithLangDict = Object.assign(
      {},
      ...reasonCodesFromAPI.map((val, idx) => {
        return { [filteredLangsArray[idx]]: val };
      }),
    );

    dispatch({
      type: UPDATE_REASON_CODES,
      payload: {
        [purpose]: { ...reasonCodesWithLangDict },
      },
    });
  };
}
