import { createSelector } from 'reselect';

import { getPluginSelector } from 'reducers/Plugins';
import { RootState } from 'reducers';
import { SAVE_SALES_DOCUMENT } from 'constants/sales';
import { SaveInventoryWriteOffParams } from 'types/InventoryWriteOff';

import { pluginID } from '../constants';

export const ONLINE_ORDER_IDS = 'onlineOrderIds';
export const VIEWED_ONLINE_ORDER_IDS = 'viewedOnlineOrderIds';

// types
type State = {
  onlineOrderAmount: number;
  newOnlineOrderIds: number[];
  viewedOnlineOrderIds: number[];
  inventoryWriteOffRequests: SaveInventoryWriteOffParams[];
  debitPaymentReturnAmount: number;
  accessToken: string;
};

// action types
const SET_ONLINE_ORDER_AMOUNT = `plugin ${pluginID}: SET_ONLINE_ORDER_AMOUNT`;
const SET_NEW_ONLINE_ORDER_IDS = `plugin ${pluginID}: SET_NEW_ONLINE_ORDER_IDS`;
const SET_VIEWED_ONLINE_ORDER_IDS = `plugin ${pluginID}: SET_VIEWED_ONLINE_ORDER_IDS`;
const MARK_ONLINE_ORDER_IDS_AS_VIEWED = `plugin ${pluginID}: MARK_ONLINE_ORDER_IDS_AS_VIEWED`;
const SET_INVENTORY_WRITE_OFF_REQUESTS = `plugin ${pluginID}: SET_INVENTORY_WRITE_OFF_REQUESTS`;
const SET_DEBIT_PAYMENT_RETURN_AMOUNT = `plugin ${pluginID}: SET_DEBIT_PAYMENT_RETURN_AMOUNT`;
const SET_ACCESS_TOKEN = `plugin ${pluginID}: SET_ACCESS_TOKEN`;

// actions
export function setOnlineOrderAmount(payload: State['onlineOrderAmount']) {
  return {
    type: SET_ONLINE_ORDER_AMOUNT,
    payload,
  };
}

export function setNewOnlineOrderIds(payload: State['newOnlineOrderIds']) {
  return {
    type: SET_NEW_ONLINE_ORDER_IDS,
    payload,
  };
}

export function setViewedOnlineOrderIds(
  payload: State['viewedOnlineOrderIds'],
) {
  return {
    type: SET_VIEWED_ONLINE_ORDER_IDS,
    payload,
  };
}

export function markOnlineOrderIdsAsViewed() {
  return {
    type: MARK_ONLINE_ORDER_IDS_AS_VIEWED,
  };
}

export function setInventoryWriteOffRequests(
  requests: State['inventoryWriteOffRequests'],
) {
  return {
    type: SET_INVENTORY_WRITE_OFF_REQUESTS,
    payload: requests,
  };
}

export function setDebitPaymentReturnAmount(
  payload: State['debitPaymentReturnAmount'],
) {
  return {
    type: SET_DEBIT_PAYMENT_RETURN_AMOUNT,
    payload,
  };
}

export function setAccessToken(payload: State['accessToken']) {
  return {
    type: SET_ACCESS_TOKEN,
    payload,
  };
}

// reducer

const newOnlineOrderIds = localStorage
  .getItem(ONLINE_ORDER_IDS)
  ?.split(',')
  .map(Number);

const viewedOnlineOrderIds = localStorage
  .getItem(VIEWED_ONLINE_ORDER_IDS)
  ?.split(',')
  .map(Number);

const initState: State = {
  onlineOrderAmount: 0,
  newOnlineOrderIds: newOnlineOrderIds || [],
  viewedOnlineOrderIds: viewedOnlineOrderIds || [],
  inventoryWriteOffRequests: [],
  debitPaymentReturnAmount: 0,
  accessToken: '',
};

function reducer(state = initState, action): State {
  const { type, payload } = action;
  switch (type) {
    case SET_ONLINE_ORDER_AMOUNT:
      return { ...state, onlineOrderAmount: payload };
    case SET_NEW_ONLINE_ORDER_IDS:
      localStorage.setItem(ONLINE_ORDER_IDS, payload.join(','));
      return { ...state, newOnlineOrderIds: payload };
    case SET_VIEWED_ONLINE_ORDER_IDS:
      localStorage.setItem(VIEWED_ONLINE_ORDER_IDS, payload.join(','));
      return { ...state, viewedOnlineOrderIds: payload };
    case MARK_ONLINE_ORDER_IDS_AS_VIEWED:
      localStorage.setItem(
        VIEWED_ONLINE_ORDER_IDS,
        state.newOnlineOrderIds.join(','),
      );
      return { ...state, viewedOnlineOrderIds: state.newOnlineOrderIds };
    case SET_INVENTORY_WRITE_OFF_REQUESTS:
      return { ...state, inventoryWriteOffRequests: payload };
    case SAVE_SALES_DOCUMENT.START:
      return { ...state, inventoryWriteOffRequests: [] };
    case SET_DEBIT_PAYMENT_RETURN_AMOUNT:
      return { ...state, debitPaymentReturnAmount: payload };
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: payload };
    default:
      return state;
  }
}

export default reducer;

// selectors
export function getPluginState(state: RootState) {
  return getPluginSelector<State>(pluginID)(n => n)(state);
}

export const getOnlineOrderAmount = createSelector(
  getPluginState,
  state => state.onlineOrderAmount,
);

export const getHasOnlineOrdersToView = createSelector(
  getPluginState,
  state => {
    if (!state.newOnlineOrderIds.length) return false;
    return state.newOnlineOrderIds.some(
      order => !state.viewedOnlineOrderIds.includes(order),
    );
  },
);

export const getInventoryWriteOffRequests = createSelector(
  getPluginState,
  state => state.inventoryWriteOffRequests,
);
