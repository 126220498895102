import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './BottomPopup.css';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';

const InfoView = ({ goBack, close, disabledInOffline }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('gridButtons');

  const openGiftCardBalancePopup = () => {
    document.activeElement.blur();
    dispatch(openModalPage({ component: mp.giftCardBalance, isPopup: true }));
    close();
  };

  const openStockAndPricePopup = () => {
    dispatch(openModalPage({ component: mp.stockAndPriceList }));
    close();
  };

  return (
    <>
      <span
        className="mobile-menu-action"
        onClick={openGiftCardBalancePopup}
        style={disabledInOffline}
      >
        {t('functions.giftcard')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={openStockAndPricePopup}
        style={{ ...disabledInOffline, marginTop: '14px' }}
      >
        {t('functions.stockAndPrice')}
      </span>
      <span
        className="btn-cancel mobile-menu-action"
        onClick={() => goBack()}
        style={{ marginTop: '28px' }}
      >
        {t('common:back')}
      </span>
    </>
  );
};

export default InfoView;
