import { addWarning } from 'actions/Error';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { deletePayment, markForProcessing } from 'actions/Payments';
import * as paxApi from 'paymentIntegrations/pax/api2';
import { CardPaymentHooks, FUNC_BUTTONS } from 'paymentIntegrations/types';
import { getCardPaymentsForIntegration } from 'reducers/Payments';
import { getErrorMessage } from 'paymentIntegrations';

import { processPayments } from './handlers/processPayments';

const checkStatus = ({ updateMessage }: CardPaymentHooks) => async () => {
  updateMessage(`Checking status...`);
  try {
    const connection = await paxApi.testConnection();
    updateMessage(
      connection.recordsCount ? 'Connection OK' : `Connection not OK`,
    );
  } catch (error) {
    console.error('Failed to check connection for PAX integration', error);
    updateMessage(
      getErrorMessage(error, "Connection error, something went wrong'"),
    );
  }
};

const cancelPayment = ({ updateMessage }: CardPaymentHooks) => async () => {
  try {
    await paxApi.manageRequest('CancelTransaction');
  } catch (error) {
    updateMessage(
      getErrorMessage(error, 'Request failed with status code 500'),
    );
    console.error('Failed to cancel PAX transaction', error);
  }
};

const terminalDiagnosis = ({ updateMessage }: CardPaymentHooks) => async () => {
  try {
    const {
      records: [diagnosis],
    } = await paxApi.manageRequest();
    if (diagnosis.resultCode === '0') {
      updateMessage(`Terminal connection works. ${diagnosis.deviceState}`);
    } else {
      updateMessage(`Cant connect to terminal: ${diagnosis.deviceState}`);
    }
  } catch (error) {
    updateMessage(
      getErrorMessage(error, 'Request failed with status code 500'),
    );
    console.error('Failed to diagnose PAX', error);
  }
};
export const cancelPayments = ({
  rejectPayments,
  enabledButtons,
}: CardPaymentHooks) => async dispatch => {
  const retryEnabled = enabledButtons.includes(FUNC_BUTTONS.RETRY);
  const closeEnabled = enabledButtons.includes(FUNC_BUTTONS.CLOSE);
  if (retryEnabled || closeEnabled) {
    rejectPayments();
  } else {
    dispatch(addWarning('Transaction can be closed only from terminal'));
  }
};

export const title = 'Pax Terminal Interface';

export const initPayments = (params: CardPaymentHooks) => async (
  dispatch,
  getState,
) => {
  const { enableButtons, rejectPayments, resolvePayments } = params;
  enableButtons([FUNC_BUTTONS.CANCEL]);

  dispatch(processPayments(params))
    .then(({ errors }) => {
      // use payments returned from getCardPaymentsForIntegration, because cardPayments from params are outdated
      const shouldVoid = getCardPaymentsForIntegration('pax')(getState()).every(
        cp => cp.paid && cp.shouldProcess,
      );
      if (errors.length) {
        enableButtons([
          FUNC_BUTTONS.RETRY,
          FUNC_BUTTONS.CHECK_STATUS,
          FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
          FUNC_BUTTONS.CLOSE,
        ]);
      } else if (shouldVoid) {
        rejectPayments();
      } else {
        resolvePayments();
      }
    })
    .catch(error => console.error('Error in pax heartland', error));
};

export const retryPayments = (params: CardPaymentHooks) => async (
  dispatch,
  getState,
) => {
  const cardPayments = getCardPaymentsForIntegration('pax')(getState()).filter(
    pmt => !pmt.paid,
  );
  dispatch(initPayments({ ...params, cardPayments }));
};

export const voidPayments = () => async (dispatch, getState) => {
  getCardPaymentsForIntegration('pax')(getState()).forEach(({ key, paid }) => {
    if (paid) {
      dispatch(markForProcessing({ key }));
    } else {
      dispatch(deletePayment({ key }));
    }
  });

  if (getCardPaymentsForIntegration('pax')(getState()).some(pmt => pmt.paid)) {
    await dispatch(openTerminalIntegration());
  }
};

export const functions = [
  {
    actionOnClick: checkStatus,
    name: FUNC_BUTTONS.CHECK_STATUS,
    text: 'Check Status',
    variant: 'warning',
  },
  {
    actionOnClick: cancelPayment,
    name: FUNC_BUTTONS.CANCEL,
    text: 'Cancel Payment',
    variant: 'danger',
  },
  {
    actionOnClick: retryPayments,
    name: FUNC_BUTTONS.RETRY,
    text: 'Retry Payment',
    variant: 'warning',
  },
  {
    actionOnClick: terminalDiagnosis,
    name: FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
    text: 'Terminal Diagnosis',
    variant: 'warning',
  },
  {
    actionOnClick: cancelPayments,
    name: FUNC_BUTTONS.CLOSE,
    text: 'Close',
    variant: 'danger',
  },
];

export const closeBatch = () => async (dispatch, getState) =>
  paxApi.closeBatch().then(
    () => true,
    err => {
      const res = err.toJSON();
      const code = Number(res.data?.records[0]?.resultCode);
      switch (code) {
        case 0: // Successful close batch
        case 1: // Failed to close batch because batch is empty (no pax transactions)
          return true;

        default:
          console.error('Pax failed to close batch', err);
          return false;
      }
    },
  );
