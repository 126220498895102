import * as R from 'ramda';
import { useEffect } from 'react';

import { PosPlugin } from 'plugins/plugin';
import { reactcursively } from 'utils/react';

const eventKey = 'perItem';

function matches(value) {
  return R.pathEq(['props', 'eventKey'], value);
}

const WithoutPerItemTab: PosPlugin['UIProductReturnReasonReturnTypesBody'] = ({
  children,
  setActiveTab,
}) => {
  useEffect(() => {
    setActiveTab('perSale');
  }, [setActiveTab]);
  return reactcursively(
    R.when(matches(eventKey), () => {
      return null;
    }),
  )(children);
};

export default WithoutPerItemTab;
