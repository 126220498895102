import { PosPlugin } from 'plugins/plugin';

export const applyDefaultSuspendedValue: Required<
  PosPlugin
>['onSaveCustomer']['on'] = (_p, ap) => async () => {
  if ('suspended' in ap.params) return ap;
  return {
    ...ap,
    params: {
      ...ap.params,
      suspended: ap.params.doNotSell,
    },
  };
};
