/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import i18next from 'i18next';
import * as R from 'ramda';
import debug from 'debug';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { ErplyAttributes, round, timestampInSeconds } from 'utils';
import { resetCurrentSalesDocument, saveSalesDocument } from 'actions/sales';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { addError, addSuccess, addWarning, dismissType } from 'actions/Error';
import {
  getAdditionalCurrencies,
  getCurrencyCode,
  getDefaultCurrency,
  getIsModuleEnabled,
  getPosRoundCash,
  getSetting,
  getUseAgeVerification,
  getPosRoundCashFunction,
  getCurrencyFormatter,
  getCheckBehavior,
} from 'reducers/configs/settings';
import {
  getBalance,
  getCardPayments,
  getCustomer,
  getEmployeeID,
  getIsUsingManualStoreCredit,
  getPayments,
  getPaymentsCurrency,
  getPaymentsTotal,
  getResetSalesDocumentOnClose,
  getSalesDocument,
  getAllPayments,
  getPaymentSelected,
  getHasPayButtonBeenClicked,
  getCashAmountToPay,
  getPaymentsExceededTotal,
  getCashPaymentsExceededTotal,
  getIsPartialPaymentAllowed,
  getChange,
  getIsEligibleForCashRounding,
  getPaymentsSum,
} from 'reducers/Payments';
import { openCashDrawer } from 'actions/integrations/printer';
import * as actionTypes from 'constants/Payments';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { createConfirmation } from 'actions/Confirmation';
import { getCustomerAndContactFieldsForSaveSalesDocument } from 'utils/payments';
import { lastReceiptData } from 'services/localDB';
import { getActivePaymentIntegration } from 'reducers/cafaConfigs';
import {
  getAppliedCoupons,
  getAppliedPromotionCoupons,
  getHasOnlyNegativeRowAmounts,
} from 'reducers/ShoppingCart';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { UrlControl } from 'services/UrlControl/UrlControl';
import { getCurrentSalesDocument, getIsAReturn } from 'reducers/sales';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { UrlControlActions } from 'services/UrlControl/actions';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getNameForCustomPaymentTypeById } from 'reducers/PaymentTypes';
import {
  getSelectedCustomerAllCardTokenAttribute,
  getSelectedCustomerID,
} from 'reducers/customerSearch';
import * as customerAPI from 'services/ErplyAPI/customers';

import { add } from '../utils';

import { voidWithIntegration } from './integrations/Terminal/voidWithIntegration';
import { setPayment } from './Payments/setPayment';
import { setPaymentSelected } from './Payments/setPaymentSelected';
import { processWithIntegration } from './integrations/Terminal/processWithIntegration';

const baseLog = debug('actions');

export function setManualStoreCredit(payload) {
  return {
    type: actionTypes.SET_MANUAL_STORE_CREDIT,
    payload,
  };
}

export function markForProcessing({ key }) {
  return {
    type: actionTypes.MARK_FOR_PROCESSING,
    payload: key,
  };
}

export function unmarkFromProcessing({ key }) {
  return {
    type: actionTypes.UNMARK_FROM_PROCESSING,
    payload: key,
  };
}

export function setWaitingForTerminal(payload) {
  return {
    type: actionTypes.SET_WAITING_FOR_TERMINAL,
    payload,
  };
}

export function deletePayment({ key }) {
  return (dispatch, getState) => {
    const state = getState();
    const { [key]: value, ...newPayments } = getPayments(state);
    dispatch({ type: actionTypes.SET_PAYMENTS, payload: newPayments });
  };
}

export function deletePayments({ keys }) {
  return async (dispatch, getState) => {
    const state = getState();
    const oldPayments = getPayments(state);
    const newPayments = R.omit(keys)(oldPayments);
    await dispatch({ type: actionTypes.SET_PAYMENTS, payload: newPayments });
  };
}

export function updatePayment({ key, amount }) {
  return async (
    dispatch,
    getState,
) => {
    const payments = getPayments(getState());
    const obj = { ...payments[key], amount };
    const newPayments = { ...payments, [key]: obj };
    await dispatch({ type: actionTypes.SET_PAYMENTS, payload: newPayments });
  };
}

export function deleteLastPayment(key) {
  return {
    type: actionTypes.DELETE_LAST_PAYMENT,
    payload: key,
  };
}

/** @deprecated Not used anymore - previously would clear all unpaid payments after a card payment fails */
export function deleteUnpaidPayments() {
  return async (dispatch, getState) => {
    //  TODO: Make this a single action to clean up redux logs
    getCardPayments(getState())
      .filter(p => !p.paid)
      .forEach(pmt => dispatch(deletePayment(pmt)));
  };
}

export function setLastPayments(payments) {
  return {
    type: actionTypes.SET_LAST_PAYMENTS,

    payload: payments.map((payment, index) => ({
      ...payment,
      key: `${payment.type.toLowerCase()}${index + 1}`,
    })),
  };
}

function tryToGetCheckNumber(checkPayment) {
  return async dispatch => {
    try {
      const checkNumber = await dispatch(
        openModalPage({
          isPopup: true,
          component: modalPages.CaptureCheckNumber,
          props: { checkPayment },
        }),
      );
      return checkNumber;
    } catch (error) {
      dispatch(deletePayment({ key: checkPayment.key }));
      dispatch(setPaymentSelected(''));
      throw error;
    }
  };
}

export async function askForCheckNumbers(dispatch, getState) {
  const checkBehaviour = getCheckBehavior(getState());
  if (checkBehaviour !== 'NEW') return;

  const checkPayments = Object.values(getPayments(getState())).filter(
    pmt => pmt.type === 'CHECK' && !pmt.attributes.refNo,
  );

  if (checkPayments.length === 0) return;

  await checkPayments.reduce(async (all, checkPayment) => {
    await all;

    const checkNumber = await dispatch(tryToGetCheckNumber(checkPayment));
    await dispatch(
      setPayment({ ...checkPayment, attributes: { refNo: checkNumber } }),
    );
  }, Promise.resolve());
}

export function setShowPaymentInput(
  showPaymentsInput,
  paymentType,
) {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_SHOW_PAYMENT_INPUT,
      payload: {
        showPaymentsInput,
        paymentType,
      },
    });
  };
}

export function setPaymentsState(payload) {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_STATE,
      payload,
    });
  };
}

export function updatePaymentsTotal(payload) {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_PAYMENTS_TOTAL,
      payload,
    });
  };
}

export function updateCurrency(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CURRENCY,
      payload,
    });
  };
}

export function setTransactionConfirmed(transactionConfirmed) {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_TRANSACTION_CONFIRMED,
      payload: transactionConfirmed,
    });
  };
}

export function setPaymentSalesDocument(salesDocument) {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_SALES_DOCUMENT,
      payload: salesDocument,
    });
  };
}
export function setPaymentEditValue(value) {
  return {
    type: actionTypes.SET_PAYMENT_EDIT_VALUE,
    payload: value,
  };
}

async function fulfillOrder(id) {
  return doClientRequest({
    request: 'saveSalesDocument',
    id,
    invoiceState: 'FULFILLED',
  });
}

export function confirmPayment() {
  return async (dispatch, getState) => {
    const log = baseLog.extend('confirmPayment');
    const warn = baseLog.extend('confirmPayment');
    warn.log = console.warn.bind(console);
    try {
      log('CONFIRM_PAYMENT for redux');
      dispatch({
        type: actionTypes.CONFIRM_PAYMENT,
      });
      log('Fetching state');
      const state = getState();
      const isReturn = getIsAReturn(state);
      let salesDocument = getSalesDocument(state);
      const currentSalesDocument = getCurrentSalesDocument(state);
      const customer = getCustomer(state);
      const {
        customerID,
        contactID,
      } = getCustomerAndContactFieldsForSaveSalesDocument(customer);
      const { pointOfSaleID, warehouseID } = getSelectedPos(state);
      const employeeID = getEmployeeID(state);
      const loggedInEmployeeID = getLoggedInEmployeeID(state);
      const appliedCoupons = getAppliedCoupons(state);
      const promotionCoupons = getAppliedPromotionCoupons(state);
      const total = getPaymentsTotal(state);
      const balance = getBalance(state);
      const change = getChange(state);
      const useAgeVerification = getUseAgeVerification(state);
      const layawayCancellationFeePaymentTypeId = getSetting(
        'layaway_cancellation_fee_payment_type',
      )(state);
      const layawayCancellationFeePaymentType = getNameForCustomPaymentTypeById(
        layawayCancellationFeePaymentTypeId,
      )(state);

      const updateDocumentDateWhenSavingPendingSales = getSetting(
        'update_document_date_when_saving_pending_sales',
      )(state);
      const updateDocumentCreatorWhenSavingPendingSales = getSetting(
        'update_document_creator_when_saving_pending_sales',
      )(state);

      const additionalCurrencies = getAdditionalCurrencies(state);
      const paymentsDict = getPayments(state);
      const defaultCurrencyCode = getDefaultCurrency(state);
      const payments = Object.values(paymentsDict);
      const hasUsedCurrency = payments.some(
        payment =>
          additionalCurrencies.includes(payment.currencyCode) &&
          payment.currencyCode !== defaultCurrencyCode,
      );
      let orderNeedsFulfillment = false;
      let orderID =
        currentSalesDocument?.type === 'ORDER'
          ? currentSalesDocument?.id
          : undefined;
      log('state collected', {
        isReturn,
        salesDocument,
        customerID,
        contactID,
        pointOfSaleID,
        warehouseID,
        employeeID,
        appliedCoupons,
        total,
        balance,
        additionalCurrencies,
        paymentsDict,
        payments,
        hasUsedCurrency,
      });

      log('sorting payments in-place');
      // prevents tip and store credit type payments to be processed earlier than legit monetary payments
      // order should be alphabetical ( CARD > CASH > CHECK > GIFTCARD > STORECREDIT > TIP )
      payments.sort((a, b) => {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        return 0;
      });

      log('Checking if payments are paid');
      const paymentsArePaid = payments.reduce((all, payment) => {
        if (payment.type === 'CARD') {
          return all && payment.paid;
        }
        return all;
      }, true);

      if (!paymentsArePaid) {
        log('They are not, aborting');
        throw new Error(i18next.t('alerts:payments.cardPaymentsUnpaid'));
      }

      log('Checking if partial payment is allowed');
      const isPartialPaymentAllowed = getIsPartialPaymentAllowed(state);

      if (!isPartialPaymentAllowed) {
        log('Checking if paid amount covers total');
        const changeOrBalance = getIsEligibleForCashRounding(state)
          ? getChange(state)
          : getBalance(state);
        if (Math.abs(total) && Math.sign(total) === -Math.sign(changeOrBalance)) {
          log('It does not, aborting');
          dispatch(
            addError(
              i18next.t('alerts:payments.paidAmountIsLessThanTotal', {
                paidAmount: round(getPaymentsSum(state)),
                total,
              }),
              {
                selfDismiss: true,
                dismissible: false,
              },
            ),
          );
          throw new Error('Paid amount is less than total');
        }
      }

      log('step n');
      salesDocument = R.mergeLeft(
        { amountPaidWithStoreCredit: 0 },
        salesDocument,
      );

      const alwaysOpenCashDrawer = !!getSetting(
        'touchpos_always_open_drawer_after_sale',
      )(state);
      const shouldPrintCashInOut = !!getSetting('cash_inout_open_drawer')(state);
      if (payments.length) {
        salesDocument = R.mergeLeft(
          { paymentType: payments[0].type },
          salesDocument,
        );
      }

      log('step n+1');
      const storeCreditPayments = [];
      const bonusRequests = [];
      let remainingStoreCredit = payments.find(p => p.type === 'STORECREDIT');
      if (remainingStoreCredit && remainingStoreCredit.amount < 0) {
        remainingStoreCredit = -remainingStoreCredit.amount;
      } else {
        remainingStoreCredit = 0;
      }

      log('step n+2');
      const splitPaymentTrigger = amount =>
        remainingStoreCredit < amount && remainingStoreCredit > 0 && amount > 0;
      const addToStorePayments = payment => {
        return storeCreditPayments.push({
          ...payment,
          // TODO: fix function defining is it is store credit or not
          addedToStoreCredit: 1,
        });
      };

      log('step n+3');
      /** If salesDocument is of type: 'PREPAYMENT' and the payment covers the outstanding amount, change the type to 'CASHINVOICE'
       * if salesDocument is new layaway it would have outstandingAmount, if its existing  one it would
       */
      if (salesDocument.type === 'PREPAYMENT') {
        const remainder = Number(salesDocument.total) - total;
        if (Math.abs(remainder) < 0.01) {
          salesDocument = R.mergeLeft(
            {
              type: 'CASHINVOICE',
              baseDocumentID: salesDocument.id,
              number: undefined,
              id: undefined,
            },
            salesDocument,
          );
        }
        // For layaway cancellation, convert the cancellation fee into a payment
        if (
          salesDocument.invoiceState === 'CANCELLED' &&
          salesDocument.cancellationFee
        ) {
          // Edit the payments array to include the extra payment
          payments.push({
            type: layawayCancellationFeePaymentType,
            amount: `-${Math.abs(salesDocument.cancellationFee).toFixed(2)}`,
          });
        }
      }

      if (salesDocument.type === 'INVWAYBILL') {
        if (salesDocument.baseDocumentID) {
          orderID = salesDocument.baseDocumentID;
          orderNeedsFulfillment = true;
        }
      }
      // If there's a document to be created linking to the order, then set the order to be fulfilled
      if (orderNeedsFulfillment && orderID) {
        bonusRequests.push({
          request: 'setSalesOrderAsFulfilled',
          orderID,
        });
      }
      log('step n+4');
      const isCashInvoice =
        !salesDocument.type || salesDocument.type === 'CASHINVOICE';
      const negativeTotal = Number(total) < 0;
      const allRowsHaveNegativeAmount = getHasOnlyNegativeRowAmounts(state);
      if (isCashInvoice && (negativeTotal || allRowsHaveNegativeAmount)) {
        salesDocument = R.mergeLeft(
          {
            type: 'CREDITINVOICE',
          },
          salesDocument,
        );
      }
      log('step n+5');
      const storeCredit = Number(
        payments.find(pmt => pmt.type === 'STORECREDIT')?.amount ?? 0,
      );

      log('step n+6');
      let paymentParams = payments
        .map(payment => {
          const amount = Number(payment.amount);
          const {
            type,
            typeID,
            vatrateID,
            giftCardBalance,
            added,
            cardHolder = '',
            giftCardID,
            serial,
            attributes,
            cardType,
            cardNumber,
            signature,
            currencyCode,
            giftCardTypeID,
            cardName,
            info,
          } = payment;

          const paymentToReturn = {
            documentID: 'CURRENT_INVOICE_ID',
            type,
            typeID,
            paymentServiceProvider: '',
            added,
            cardNumber: cardNumber ?? serial,
            cardName,
            cardType,
            info,
          };

          if (attributes) {
            Object.assign(
              paymentToReturn,
              new ErplyAttributes(attributes).asFlatArray,
            );
          }

          const hasRemainingStoreCredit =
            remainingStoreCredit >= amount && amount > 0;
          if (type !== 'STORECREDIT' && hasRemainingStoreCredit) {
            Object.assign(paymentToReturn, { addedToStoreCredit: 1 });
          }
          switch (type) {
            case 'CHECK':
              Object.assign(paymentToReturn, {
                sum: splitPaymentTrigger(amount) ? remainingStoreCredit : amount,
              });
              if (splitPaymentTrigger(amount)) {
                addToStorePayments(paymentToReturn);
                Object.assign(paymentToReturn, {
                  sum: amount - remainingStoreCredit,
                });
              }
              break;

            case 'STORECREDIT':
              if (Number(amount, 10) !== 0) {
                salesDocument = R.mergeLeft(
                  {
                    amountPaidWithStoreCredit: storeCredit,
                  },
                  salesDocument,
                );
              }
              return null;

            case 'GIFTCARD':
              Object.assign(paymentToReturn, {
                giftCardVatRateID: vatrateID || 0,
                sum: splitPaymentTrigger(amount) ? remainingStoreCredit : amount,
                giftCardID,
                giftCardTypeID,
              });
              if (cardHolder) Object.assign(paymentToReturn, { cardHolder });
              if (splitPaymentTrigger(amount)) {
                addToStorePayments(paymentToReturn);
                Object.assign(paymentToReturn, {
                  sum: amount - remainingStoreCredit,
                });
              }
              if (serial) {
                bonusRequests.push(
                  R.when(
                    R.always(amount > 0),
                    R.mergeDeepLeft({
                      redeemingCustomerID: customerID,
                      redemptionDateTime: timestampInSeconds(),
                      redemptionWarehouseID: warehouseID,
                      redemptionPointOfSaleID: pointOfSaleID,
                      redemptionInvoiceID: 'CURRENT_INVOICE_ID',
                      redemptionEmployeeID: employeeID,
                    }),
                  )({
                    giftCardID,
                    balance: round((giftCardBalance ?? 0) - amount),
                    code: serial,
                    typeID: giftCardTypeID,
                    added: timestampInSeconds(),
                    requestName: 'saveGiftCard',
                  }),
                );
              }
              break;

            case 'CHANGE':
              Object.assign(paymentToReturn, { type: 'CASH' });
            // fallthrough
            case 'CASH': {
              paymentToReturn.sum = amount;
              if (splitPaymentTrigger(amount)) {
                // TODO: What are split payments again
                // Validate that split payments work correctly together with CHANGE (cash back)
                addToStorePayments({
                  ...paymentToReturn,
                  sum: remainingStoreCredit,
                });
                paymentToReturn.sum -= remainingStoreCredit;
              }
              const newAmount = paymentToReturn.sum;

              const cashChange = Math.max(0, change);

              Object.assign(paymentToReturn, {
                cashPaid: round(newAmount, 2),
                sum: round(newAmount - cashChange, 2),
                cashChange,
              });
              // if there has been a currency used, do not include change
              // change is introduced as a separate payment, see "GOTO-change2" (ctrl-f)
              if (hasUsedCurrency) {
                Object.assign(paymentToReturn, {
                  sum: paymentToReturn.cashPaid,
                  cashChange: 0,
                });
              }
              break;
            }
            case 'CARD': {
              const mapPaymentFields = fields =>
                Object.fromEntries(fields.map(f => [f, payment[f]]));
              const cayanFields = [
                'aid',
                'applicationLabel',
                'cryptogramType',
                'cryptogram',
                'expirationDate',
              ];
              const paxFields = [
                'aid',
                'entryMethod',
                'applicationLabel',
                'transactionType',
              ];
              const givexFields = [
                'certificateBalance',
                'transactionType',
                'statusCode',
                'statusMessage',
                'expirationDate',
              ];
              const useGivexFields = getSetting('givex_payments')(getState());
              const usePaxFields = getIsModuleEnabled('pax_payments')(getState());
              const useCayanFields = getIsModuleEnabled('cayan_payments')(
                getState(),
              );
              const extraFields = {
                givex: {
                  paymentServiceProvider: 'givex',
                  ...mapPaymentFields(givexFields),
                },
                pax: {
                  paymentServiceProvider: 'pax',
                  ...mapPaymentFields(paxFields),
                },
                cayan: {
                  paymentServiceProvider: 'merchant_warehouse',
                  ...mapPaymentFields(cayanFields),
                },
                neither: {},
              }[
                useGivexFields && payment.cardType === 'GIVEX'
                  ? 'givex'
                  : usePaxFields
                  ? 'pax'
                  : useCayanFields
                  ? 'cayan'
                  : 'neither'
              ];

              Object.assign(paymentToReturn, {
                sum: splitPaymentTrigger(amount) ? remainingStoreCredit : amount,
                cardHolder,
                cardType,
                cardName: undefined,
                cardNumber,
                signature,
                signatureIV: undefined,
                aid: undefined,
                applicationLabel: undefined,
                pinStatement: undefined,
                cryptogramType: undefined,
                cryptogram: undefined,
                safNumber: undefined,
                transactionId: undefined,
                ...extraFields,
              });
              if (splitPaymentTrigger(amount)) {
                addToStorePayments(paymentToReturn);
                Object.assign(paymentToReturn, {
                  sum: amount - remainingStoreCredit,
                });
              }
              break;
            }
            case 'TIP':
              Object.assign(paymentToReturn, {
                sum: round(-Math.abs(Number(amount))),
              });
              break;

            default:
              Object.assign(paymentToReturn, {
                sum: amount,
              });
              break;
          }
          if (type !== 'STORECREDIT' && remainingStoreCredit > 0) {
            remainingStoreCredit -= amount;
            if (remainingStoreCredit < 0) remainingStoreCredit = 0;
          }
          return { ...paymentToReturn, currencyCode };
        })
        .filter(el => el && el.type !== 'PAID');

      log('step n+7');
      // label: "GOTO-change2"
      // Adds new payment object representing the change in default currency for payments with non-default currency
      // special case: payment and change were in different currencies & balance over 0 - POS should save the change provided
      // in default currency as a payment in order to provide sufficient information to BO team to print ZReport correctly.
      if (hasUsedCurrency && balance > 0) {
        paymentParams = paymentParams.concat({
          documentID: 'CURRENT_INVOICE_ID',
          type: 'CASH',
          paymentServiceProvider: '',
          sum: `-${round(balance, 2)}`,
          cashChange: `-${round(balance, 2)}`,
          currencyCode: defaultCurrencyCode,
        });
      }

      log('step n+8');
      if (getIsUsingManualStoreCredit(state)) {
        salesDocument = R.dissoc('amountPaidWithStoreCredit', salesDocument);
        salesDocument = R.mergeLeft(
          { paymentType: 'STORECREDIT' },
          salesDocument,
        );
      }

      log('step n+9');
      if (storeCredit < 0 && !isReturn && !getIsUsingManualStoreCredit(state)) {
        paymentParams = paymentParams.concat({
          documentID: 'CURRENT_INVOICE_ID',
          type: 'CASH',
          paymentServiceProvider: '',
          sum: round(-storeCredit, 2),
          cashChange: '0',
          cashPaid: round(-storeCredit, 2),
          currencyCode: defaultCurrencyCode,
          addedToStoreCredit: 1,
        });
      }

      if (salesDocument.invoiceState === 'PENDING') {
        const newProps = {};

        if (updateDocumentDateWhenSavingPendingSales) {
          newProps.date = dayjs().format('YYYY-MM-DD');
          newProps.time = dayjs().format('HH:mm:ss');
        }

        if (updateDocumentCreatorWhenSavingPendingSales) {
          newProps.employeeID = loggedInEmployeeID;
        }

        // If there's anything to update the document with, do so
        if (Object.keys(newProps).length) {
          salesDocument = R.mergeLeft(newProps, salesDocument);
        }
      }

      log('step n+10');
      const receiptPayments = payments.map(payment => ({
        ...payment,
        sum: payment.amount,
      }));
      log('receiptPayments', receiptPayments);

      log('Setting last receipt data');
      lastReceiptData.set({
        payments: receiptPayments,
      });

      // means that we are paying for an order
      if (
        salesDocument?.id &&
        salesDocument?.type === 'ORDER' &&
        salesDocument?.invoiceState !== 'CANCELLED' &&
        getHasPayButtonBeenClicked(getState())
      ) {
        salesDocument = R.mergeLeft(
          {
            id: undefined,
            number: undefined,
            invoiceNo: undefined,
            type: 'CASHINVOICE',
            baseDocumentID: salesDocument.id,
          },
          salesDocument,
        );

        if (!getSetting('copy_creator_to_linked_sales')(getState())) {
          salesDocument = {
            ...salesDocument,
            employeeID: getLoggedInEmployeeID(getState()),
          };
        }
      }
      log('closing current modalPage');
      dispatch(previousModalPage());
      if (useAgeVerification) dispatch(previousModalPage());
      log('Starting to save salesdoc');
      await dispatch(
        saveSalesDocument({
          salesDocument,
          payments: paymentParams,
          storeCreditPayments,
          bonusRequests,
          customerID,
          contactID,
          onSuccess: ({
            invoiceID,
            invoiceLink,
            invoiceNo,
            receiptLink,
            ...salesDocument
          }) => {
            let requests = [];
            if (appliedCoupons) {
              [...appliedCoupons, ...(promotionCoupons ?? [])]
                .filter(coupon => coupon.used)
                .forEach(coupon =>
                  requests.push(
                    doClientRequest({
                      uniqueIdentifier: coupon.uniqueIdentifier,
                      customerID,
                      invoiceID,
                      warehouseID,
                      pointOfSaleID,
                      employeeID,
                      timestamp: timestampInSeconds(),
                      request: 'redeemIssuedCoupon',
                    }),
                  ),
                );
            }

            const cashChange = Math.abs(
              Number(
                (
                  payments.find(pmt => pmt.cashChange) || {
                    amount: '0',
                  }
                ).amount,
              ),
            );
            dispatch(
              openModalPage({
                component: modalPages.PaymentConfirmation,
                modalClassName: 'confirmation',
                isPopup: true,
                props: {
                  invoiceID,
                  invoiceLink,
                  customer,
                  payments: receiptPayments,
                  invoiceNo:
                    salesDocument.type === 'CREDITINVOICE'
                      ? `${invoiceNo}K`
                      : invoiceNo,
                  receiptLink,
                  change,
                  type: salesDocument.type,
                  ...salesDocument,
                },
              }),
            );

            // CASH-like payments
            const paymentsContainsCash = payments.some(p =>
              ['CASH', 'CHANGE'].includes(p.type),
            );
            // Change due to overpayment
            const changeNeeded = change > 0;

            if (
              alwaysOpenCashDrawer ||
              (shouldPrintCashInOut && (paymentsContainsCash || changeNeeded))
            ) {
              dispatch(openCashDrawer());
            }

            const result = {
              invoiceID,
              invoiceLink,
              invoiceNo,
              receiptLink,
              ...salesDocument,
              __dev: `Warning: Not all of these fields are guaranteed by the spec. 
              Any extra fields are subject to deprecation without warning and should not be relied upon in the code
              That said, if you just want to log them to the console or display them to the user that's fine`,
            };
            if (UrlControl.actionCancelLayaway || UrlControl.actionReturnSale) {
              Promise.all(requests).then(() => {
                UrlControlActions.payment.resolve(result);
              });
            }
          },
        }),
      );
    } catch (e) {
      dispatch(
        addError(
          i18next.t('payment:alerts.failedToConfirmPayments', {
            error: e?.message ?? e,
          }),
          {
            selfDismiss: false,
            dismissible: true,
          },
        ),
      );
      console.error('Failed to confirm payment', e);
      // Rethrow so that processPayments does not proceed as if no error occurred
      throw new Error('Failed to confirm payment', { cause: e });
    }
  };
}

export function setPaymentButtonClicked(payload) {
  return {
    type: actionTypes.SET_PAY_BUTTON_CLICKED,
    payload,
  };
}

export function closePaymentsModal() {
  return async (dispatch, getState) => {
    const state = getState();
    const resetSalesDocumentOnClose = getResetSalesDocumentOnClose(state);
    if (resetSalesDocumentOnClose) {
      dispatch(resetCurrentSalesDocument());
    }
    // Fold this if you don't care about urlcontrol behaviour
    if (UrlControl.actionCancelLayaway || UrlControl.actionReturnSale) {
      UrlControlActions.payment.reject(new Error('Cancelled by user'));
    }
    dispatch(closeModalPage(modalPages.Payment));
    dispatch(setPaymentButtonClicked(false));
  };
}

export function processPayments() {
  return async (dispatch, getState) => {
    const state = getState();
    // Remove the "Payments were only partially approved when/if payments are processed anew while being not fully processed from last attempt"
    dispatch(dismissType('payment-partialApproval'));

    const { on } = getPluginLifecycleHook('onProcessPayments')(state);

    const allPayments = getAllPayments(state);
    const format = getCurrencyFormatter(getState());
    const shouldVoid = allPayments.some(pmt => pmt.shouldProcess && pmt.paid);

    // In order to avoid unpaid payments to be processed by the integration when voiding, we still need to filter the payments by paid and if payment needs to be processed
    const paymentsToProcess = R.pipe(
      R.ifElse(
        () => shouldVoid,
        R.filter(pmt => pmt.shouldProcess && pmt.paid),
        R.filter(pmt => !pmt.paid),
      ),
    )(allPayments);

    const withCurrency = p => Number(p.amount) * Number(p?.currencyRate ?? 1);

    const totalAmountBeforeProcessing = allPayments
      .map(withCurrency)
      .reduce(add, 0);

    const currentInt = getActivePaymentIntegration(state);
    const paymentsByIntegration = R.pipe(
      R.groupBy(
        pmt =>
          pmt.paymentIntegration ?? (pmt.type === 'CARD' ? currentInt : 'none'),
      ),
      Object.entries,
    )(paymentsToProcess);

    const paymentsByIntegrationList = await dispatch(
      on(null, paymentsByIntegration),
    );
    const round = num => Math.round(num * 100) / 100;

    return paymentsByIntegrationList
      .reduce(
        (prev, [integration, payments]) =>
          prev.then(() =>
            dispatch(processWithIntegration(integration, payments)),
          ),
        Promise.resolve(null),
      )
      .then(() => {
        const totalAmountPostProcessing = Object.values(getPayments(getState()))
          // at the time of the implementation
          // CARD type is processed by integrations, everything else is assumed processed
          .filter(p => p.paid || p.type !== 'CARD')
          .map(withCurrency)
          .reduce(add, 0);
        // In case of rounding errors, allow payment to be up to BUT NOT INCLUDING a penny short
        // 2.59 payment for 2.60 NOT OKAY
        // 2.59 payment for 2.599999 OKAY
        if (
          !(round(totalAmountPostProcessing) < round(totalAmountBeforeProcessing))
        ) {
          const currentCustomerID = getSelectedCustomerID(getState());
          // ---logic for boarding cards with cayan vault tokens---
          // current saved vault cards in customer longAttribute
          const savedCards = getSelectedCustomerAllCardTokenAttribute(
            getState(),
          );
          // payments we got from the integration
          const payments = getPayments(getState());

          // Check the received payments to see if they contain a cardToken
          // If they do, map the payments and return a new array of objects containing cardToken, cardNumber, mounted (false), integration
          const newCardPaymentsWithToken = Object.values(payments)
            .filter(payment => payment.cardToken)
            .map(payment => ({
              cardToken: payment.cardToken,
              cardNumber: payment.cardNumber,
              mounted: false,
              integration: currentInt,
            }));

          // check if we found out payments containing card tokens.
          // If found, we proceed. Otherwise, we continue skip and continue as a regular payment
          if (newCardPaymentsWithToken.length) {
            // Copy over existing data to a dict & Mark all saved cards as false, because we will board a new card
            const cardsDict = {};
            savedCards.forEach(card => {
              cardsDict[card.cardNumber] = { ...card, mounted: false };
            });

            // Add the new payments to the dict (& keep track of the last card)
            newCardPaymentsWithToken.forEach(payment => {
              cardsDict[payment.cardNumber] = payment;
            });
            const lastModifiedCardNbr = newCardPaymentsWithToken.slice(-1)[0]
              .cardNumber;

            // Change the last card to a mounted card
            cardsDict[lastModifiedCardNbr].mounted = true;

            // transform back dict to array so we can save it to the api (customer attribute)
            const updatedCards = Object.values(cardsDict);

            // save to customer long attribute
            customerAPI
              .saveCustomer({
                customerID: currentCustomerID,
                longAttributeName1: 'cardToken',
                longAttributeValue1: JSON.stringify(updatedCards),
              })
              .then(() =>
                dispatch(
                  addSuccess(i18next.t('payment:alerts.cardBoardedSuccess'), {
                    selfDismiss: true,
                    dismissible: false,
                  }),
                ),
              )
              .catch(error => {
                let errorMessage = i18next.t(
                  'payment:alerts.defaultBoardingError',
                );
                if (error instanceof Error) {
                  errorMessage = error.message;
                } else if (typeof error === 'string') {
                  errorMessage = error;
                }
                dispatch(
                  addError(
                    i18next.t('payment:alerts.cardBoardedError', {
                      error: errorMessage,
                    }),
                    {
                      selfDismiss: true,
                      dismissible: false,
                    },
                  ),
                );
              });
          }
          // -- end of cayan vault card boarding logic --

          return dispatch(confirmPayment()).then(() =>
            dispatch(closePaymentsModal()),
          );
        }

        // Calculate the unpaid amount from the totals
        const diff = round(
          round(totalAmountPostProcessing) - round(totalAmountBeforeProcessing),
        );
        if (!shouldVoid) {
          // Not self-dismissing to ensure the cashier has read the alert
          // Partial approval should be a pretty rare event so the extra click should not be a major issue
          dispatch(
            addWarning(
              i18next.t('payment:alerts.partialApproval', {
                balance: format(Math.abs(diff)),
              }),
              {
                selfDismiss: false,
                dismissible: true,
                errorType: 'payment-partialApproval',
              },
            ),
          );
        }
      })
      .catch(err => {
        const warn = baseLog.extend('processPayments');
        warn.log = console.warn.bind(console);
        warn('Payment has failed. Stay in Payments', err);
      })
      .finally(() => {
        dispatch(setWaitingForTerminal(false));
      });
  };
}

export function closePayments() {
  return async (dispatch, getState) => {
    const log = baseLog.extend('closePayments');
    const warn = baseLog.extend('closePayments');
    warn.log = console.warn.bind(console);
    log('@@@ CLOSING PAYMENT');
    const state = getState();
    dispatch(dismissType('payment-partialApproval'));

    const { before, on, after } = getPluginLifecycleHook('onClosePayments')(
      state,
    );
    const payments = getAllPayments(state).filter(p => p.paid);
    const currentInt = getActivePaymentIntegration(state);
    const paymentsByIntegration = R.pipe(
      R.groupBy(
        pmt =>
          pmt.paymentIntegration ?? (pmt.type === 'CARD' ? currentInt : 'none'),
      ),
    )(payments);

    await dispatch(before(paymentsByIntegration));

    dispatch(setManualStoreCredit(false));

    try {
      // No need to prompt if there are no 'paid' payments
      if (payments.length) {
        await new Promise((resolve, reject) =>
          dispatch(
            createConfirmation(resolve, reject, {
              title: i18next.t('alerts:payments.confirmVoid.title'),
              body: i18next.t('alerts:payments.confirmVoid.body'),
            }),
          ),
        );
      }
    } catch (e) {
      // User cancelled
      return;
    }

    await dispatch(on(paymentsByIntegration, null));
    // Go through the payments and execute their integrations
    const error = await Object.entries(paymentsByIntegration)
      .reduce(
        (pr, [integration, payments]) =>
          pr.then(() => dispatch(voidWithIntegration(integration, payments))),
        Promise.resolve(),
      )
      .then(
        () => null,
        err => err ?? new Error('Unknown error'),
      );

    await dispatch(after(paymentsByIntegration, error));

    if (error) {
      warn('Attempt to void payments threw error', error);
      dispatch(addWarning(i18next.t('alerts:payments.void_error')));
      return;
    }
    if (getAllPayments(getState()).some(pmt => pmt.paid)) {
      warn('Some "paid" payments remain, cannot exit of payment screen');
      dispatch(addWarning(i18next.t('alerts:payments.void_error')));
      return;
    }
    dispatch(closePaymentsModal());
  };
}

export function fillSelectedPayment() {
  return async (dispatch, getState) => {
    const key = getPaymentSelected(getState());
    const pmt = getPayments(getState())[key];
    if (!pmt) return; // Error here?
    await dispatch(setPayment({ ...pmt, key, amount: Infinity }));
    await dispatch(setPaymentSelected(''));
  };
}

export function addFullCardPayment() {
  return async (dispatch, getState) => {
    const state = getState();
    const toPay = -getBalance(state);
    const { code: currencyCode, rate } = getPaymentsCurrency(state);
    if (toPay === 0) {
      return dispatch(
        addWarning(i18next.t('alerts:payments.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
    // If total is 0, allow payments in either direction
    if (getPaymentsExceededTotal(state)) {
      return dispatch(
        addWarning(i18next.t('alerts:payments.amountExceeded'), {
          selfDismiss: 3000,
        }),
      );
    }
    await dispatch(
      setPayment({
        key: uuidv4(),
        type: 'CARD',
        caption: 'CARD',
        amount: round(toPay, 2),
        currencyCode,
        currencyRate: Number(rate),
      }),
    );
    return dispatch(setPaymentSelected(''));
  };
}

export function addFullCashPayment() {
  return async (dispatch, getState) => {
    const state = getState();
    const { code: currencyCode, rate } = getPaymentsCurrency(state);
    const total = getPaymentsTotal(state);
    const payments = Object.values(getPayments(state));
    const toPay = getCashAmountToPay(state);

    // Round that amount based on cash rounding setting
    const roundCash = getPosRoundCashFunction(state);
    let rounded = roundCash(toPay);

    // If total is below rounding (<2.5¢ for 5¢ rounding), and customer is paying by cash only
    // let them pay the minimum denomination anyway
    const hasNonCashPayments = !!payments.some(
      p => !['PAID', 'CASH'].includes(p.type),
    );
    if (roundCash(total) === 0 && !hasNonCashPayments) {
      rounded = Math.sign(toPay) * getPosRoundCash(state);
    }

    // No balance, payment not wanted
    if (Number(round(-getBalance(state), 2)) === 0) {
      return dispatch(
        addWarning(i18next.t('alerts:payments.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
    // If total is positive, then only positive payments are allowed
    // If total is negative, then only negative payments are allowed
    // If total is zero, then both negative and positive payments are allowed
    if (getCashPaymentsExceededTotal(state)) {
      return dispatch(
        addWarning(i18next.t('alerts:payments.amountExceeded'), {
          selfDismiss: 3000,
        }),
      );
    }

    await dispatch(
      setPayment({
        key: `${currencyCode}-cash`,
        type: 'CASH',
        caption: 'CASH',
        amount: rounded,
        currencyCode,
        currencyRate: Number(rate),
      }),
    );
    return dispatch(setPaymentSelected(''));
  };
}

export function setBoardCard(payload) {
  return {
    type: actionTypes.SET_BOARD_CARD,
    payload,
  };
}

export function setCardTokenInPayment(payload) {
  return {
    type: actionTypes.SET_CARD_TOKEN_IN_PAYMENT,
    payload,
  };
}

export function removeCardTokenInPayment(payload) {
  return {
    type: actionTypes.REMOVE_CARD_TOKEN_IN_PAYMENT,
    payload,
  };
}
