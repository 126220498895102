import { getSalesDocuments } from 'services/ErplyAPI/sales';

import { ATTRIBUTES } from './constants';

export async function checkIfDuplicateSAINumber(currentSAINumber: string) {
  const [salesDocument] = await getSalesDocuments({
    searchAttributeName: ATTRIBUTES.SAI_NUMBER,
    searchAttributeValue: currentSAINumber,
  });
  return salesDocument !== undefined;
}
