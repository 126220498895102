/* eslint-disable */
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getWarehouseById } from 'reducers/warehouses';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getCurrentPrinterIntegration } from 'reducers/configs/settings';
import { PrinterScript, PrinterScriptSalesReceipt } from './PrinterScript';

/**
 * A redux action that takes a salesDocument and returns a script
 * for the printer integration
 *
 * If you want to print the receipt, dispatch printReceipt() instead
 */
export const formatReceipt = (
  {
    company,
    warehouse,
    pointOfSale,
    notes,
    customerFullName,
    employeeFullName,
    salesDocumentNumber,
    billTable,
    translations,
  },
  { giftReceipt },
) => async (dispatch, getState) => {
  /* Receipt formatting starts here */
  const printer = getCurrentPrinterIntegration(getState());
  const s = new PrinterScriptSalesReceipt(printer);
  s.ln().ln();
  s.companyData(company, warehouse, pointOfSale);
  s.ln();
  s.receiptData(salesDocumentNumber);
  s.ln();
  s.print(`Customer: ${customerFullName}`).ln();
  s.ln();
  s.print(`Employee: ${employeeFullName}`).ln();
  s.hr();

  s.billTable(billTable, {
    giftReceipt,
    width: printer.id === '1504 0059' ? 42 : 48,
  });
  notes.split('\n').forEach(line => {
    s.print(line).ln();
  }); // sale notes
  s.ln()
    .ln()
    .ln();

  s.barcode(salesDocumentNumber);
  s.centerText('Thank you for the purchase!', 'Welcome back!');
  s.ln().ln();

  return s.toString();
};

// prettier-disable
/**
 * A redux action that takes some ZReport details and returns a script
 * for the printer integration
 *
 * If you want to print the Z Report, dispatch printZReport() instead
 */
export const formatZReport = ({ storeCredit, transfers, voids }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const employee = getEmployeeById(getLoggedInEmployeeID(state))(state);
  const POS = getSelectedPos(state);
  const warehouse = getWarehouseById(POS.warehouseID)(state);
  const currentDateTime = new Date()
    .toISOString()
    .replace('T', ' ')
    .replace('Z', '');

  const col1 = { width: 38 };
  const col2 = { width: 10, alignRight: true };
  const s = new PrinterScript(getCurrentPrinterIntegration(getState()));

  s.print(`Date: ${currentDateTime}`).ln();
  s.print(`Report printed by: ${employee.fullName}`).ln();
  // TODO: Start of period ????
  // TODO: End of period ????
  s.print(`Register: ${POS.name}`).ln();
  s.print(`Location: ${warehouse.name}`).ln();

  const [_, fallbackRows] = Object.values(transfers);

  for (const { rows } of Object.values(transfers)) {
    const defaultRows = rows === undefined ? fallbackRows.rows : rows;
    for (const {
      banked,
      cash = '0',
      cashExpected,
      cashTotal,
      closed,
      dayIncome,
      difference,
      initialAmount,
      left,
      opened,
      pointOfSaleID,
      totalCounted,
      totalTransactions,
      varianceReason,
    } of defaultRows) {
      s.hr();
      s.leftRight('Register:', { ...col1, bold: true, size: 1 }, POS.name, {
        ...col2,
        bold: true,
      }).commitLine({ underlineCurrentRow: true });
      s.td('Initial amount in till:', { ...col1, bold: true, size: 1 })
        .td(initialAmount, { ...col2, bold: true })
        .commitLine({ underlineCurrentRow: true });
      s.print(`Opened: `, { bold: true, size: 1 })
        .print(opened, {})
        .underlineCurrentRow()
        .ln();
      s.td('Day income:', col1)
        .td(dayIncome, col2)
        .commitLine();

      for (const { dateTime, employeeName, sum, comment } of cash) {
        s.td(`${dateTime} ${employeeName}`, col1)
          .td(sum, col2)
          .commitLine();
        if (comment) {
          s.print(comment).ln();
        }
      }

      s.td('Day income + all cash in / out:', col1)
        .td(cashTotal, col2)
        .commitLine();
      s.td('Expected amount in register', col1).commitLine();
      s.td('  at end of day:  ', col1)
        .td(cashExpected, col2)
        .commitLine({ underlineCurrentRow: true });
      s.td('Closed:', { ...col1, bold: true, size: 1 })
        .td(closed, col2)
        .commitLine({ underlineCurrentRow: true });
      s.td('Total counted in register:', col1)
        .td(totalCounted, col2)
        .commitLine();
      s.td('Deposited:', col1)
        .td(banked, col2)
        .commitLine();
      s.td('Left to till as change:', col1)
        .td(left, col2)
        .commitLine({ underlineCurrentRow: true });
      s.td('Over/short:', col1)
        .td(difference.toFixed(2), col2)
        .commitLine({ underlineCurrentRow: true });
      s.td('Transactions total:', col1)
        .td(totalTransactions, col2)
        .commitLine({ underlineCurrentRow: true });
    }
    s.ln().ln();
  }
  return s.toString();
};
