import React, { useMemo } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import classnames from 'classnames';

import InputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';

import './AddressForm.scss';
import { useTranslation } from 'react-i18next';
import { fieldGroups } from './formShape';

/**
 *  Address form card used in few form components
 */

const AddressForm = ({ address, addressTypes = [], onChange = null }) => {
  const addressTypesOptions = useMemo(
    () =>
      addressTypes.map(({ id, name }) => ({
        value: id,
        name,
      })),
    [addressTypes],
  );

  const { t } = useTranslation('addressForm');

  return (
    <div className="address-form">
      {addressTypes && (
        <Form.Group>
          <InputField
            size="lg"
            title={t('type')}
            value={address?.typeID ?? ''}
            name="addressTypeID"
            type="select"
            options={addressTypesOptions}
            data-testid="address"
            onChange={e => onChange('typeID', e.target.value)}
            className={classnames([styles.formInput, styles.longTitle])}
          />
        </Form.Group>
      )}
      <Form.Group>
        {fieldGroups.map(fieldGroup => (
          <InputGroup key={fieldGroup[0].name}>
            {fieldGroup.map(field => (
              <Form.Control
                key={field.name}
                value={address[field.key] ?? ''}
                placeholder={t(`placeholders.${field.key}`).toUpperCase()}
                style={field.style || {}}
                onChange={e => onChange([field.key], e.target.value)}
                name="address"
                type={field.type}
                data-testid={field.key}
              />
            ))}
          </InputGroup>
        ))}
      </Form.Group>
    </div>
  );
};

export default AddressForm;
