import { addError } from 'actions/Error';
import { PosPlugin } from 'plugins/plugin';
import { getClientCode } from 'reducers/Login';

import { getConfiguration } from '../../../configuration';

import { acknowledgeRegistrationToErply } from './api';

export const registerGeneratedCardNumber: Required<
  PosPlugin
>['onSaveCustomer']['after'] = (p, customerID) => async (
  dispatch,
  getState,
) => {
  if (p?.customerID) return;
  const storeAccountNumber = getClientCode(getState());
  const { isProduction } = getConfiguration(getState());
  try {
    await acknowledgeRegistrationToErply({
      isProduction,
      storeAccountNumber,
      customerID,
      customerCardNumber: Number(p.customerCardNumber),
    });
  } catch (error) {
    dispatch(addError('Failed to register customer card number'));
  }
};
