import { PaymentObj } from 'paymentIntegrations/types';

import { threeDecimalCurrencies, zeroDecimalCurrencies } from './constants';
import { InputData } from './types';

const getCurrencyScalingFactor = (currency: string) => {
  if (zeroDecimalCurrencies.includes(currency.toUpperCase())) {
    return 1;
  }
  if (threeDecimalCurrencies.includes(currency.toUpperCase())) {
    return 1e3;
  }
  return 1e2;
};

export const getSumToSend = (amount: string, currency: string) => {
  const amountToUse = amount.replace('-', '');
  const scaling = getCurrencyScalingFactor(currency);
  return Math.round(Number(amountToUse) * scaling);
};

export const convertToErplySum = (amount: number, currency: string) => {
  const scaling = getCurrencyScalingFactor(currency);
  return (Math.abs(amount) / scaling).toFixed(2);
};

export const generateInputData = (
  payment: PaymentObj & { original?: PaymentObj; currencyCode?: string },
  initialType?: 'REFUND' | 'VOID' | 'SALE',
): InputData => {
  function getTransactionType() {
    if (initialType) return initialType;
    const isRefund = Number(payment.amount) < 0 && !payment.paid;
    if (isRefund) return 'REFUND';
    return payment.paid && payment.shouldProcess ? 'VOID' : 'SALE';
  }

  const { stripePaymentId } = (payment?.original ?? payment)?.attributes ?? {};

  const transactionType = getTransactionType();
  switch (transactionType) {
    case 'SALE':
    default:
      return {
        transactionType,
        amount: payment.amount,
      };
    case 'REFUND':
      return {
        transactionType,
        amount: payment.amount,
        stripePaymentId,
        currency: payment.currency ?? payment.currencyCode,
      };
    case 'VOID':
      return {
        transactionType,
        stripePaymentId,
      };
  }
};
