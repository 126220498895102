/*
 * applies_to=<number>/auto/manual
 *  * <number>: Applies to entire shopping cart and other tax products which have a smaller number
 *  * auto: Applies only to products that have this tax explicitly listed (default)
 *  * manual: Cashier must manually select which products take this tax (allows taxing other tax products)
 *
 * is_tax_percentage: number
 *
 * force_taxed_by: true/false (default false)
 *  * If true, automatically enable tax product when this product is added to sale
 * taxed_by: number[]
 *  * Product IDs that should apply to this product if they are type=auto
 */
import React from 'react';
import * as R from 'ramda';
import { Table } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { getProductsUniversal } from 'actions/productsDB';
import { getProductsInShoppingCart, getTotal } from 'reducers/ShoppingCart';
import TableTotal from 'components/tableTotal/TableTotal';
import { getProductByID } from 'reducers/cachedItems/products';

import { addTaxProduct, getTaxProducts, reducer } from './redux/redux';
import { pluginID } from './constants';
import { getTaxProductsWithPrices } from './redux/computedState';
import { getAttrs } from './utils/getAttrs';
import { ProductRow } from './react/ProductRow';

export const TaxProductsPlugin: PosPlugin<any> = {
  id: pluginID,
  name: 'Pseudo-tax products',
  info:
    'Enable configuration of products which will act like additional taxes on top of the sale',
  keywords: ['products', 'tax', 'shopping', 'cart'],
  onAddProduct: {
    before: p => async (
      dispatch: ThunkDispatch<any, any, Action>,
      getState,
    ) => {
      const {
        products: [prod],
      } = await dispatch(getProductsUniversal({ productID: p.productID }));

      const { percentage, taxedBy, forceTaxedBy } = getAttrs(prod);
      const isEmptyOfRegularProducts =
        getProductsInShoppingCart(getState()).length === 0;
      if (percentage) {
        if (!isEmptyOfRegularProducts) dispatch(addTaxProduct(p.productID));
        throw new Error('Do not add tax product to cart');
      }
      if (forceTaxedBy) {
        taxedBy.forEach(id => dispatch(addProduct({ productID: id })));
      }
    },
  },
  reduxReducer: reducer,
  onAddReturnProducts: {
    on: (p, ap) => async (
      dispatch: ThunkDispatch<any, any, Action>,
      getState,
    ) => {
      // Allow only non-tax products through to core POS
      return R.evolve({
        // Data cached by action
        orders: R.reject(
          (p: any) =>
            !!getAttrs(getProductByID(p.productID)(getState())).percentage,
        ),
      })(ap);
    },
    after: (p, r) => async (
      dispatch: ThunkDispatch<any, any, Action>,
      getState,
    ) => {
      // Add the tax products to the plugin
      const adds = p.orders
        .filter(
          p => !!getAttrs(getProductByID(p.productID)(getState())).percentage,
        )
        .map(p => Number(p.productID))
        .map(addTaxProduct)
        .map(dispatch);

      return Promise.all(adds).then(() => {});
    },
  },

  UITableTotalRow: ({ children }) => {
    const taxProducts = useSelector(getTaxProductsWithPrices);
    const baseTotal = useSelector(getTotal);
    const taxTotal = taxProducts
      .map(tp => tp.taxPrice.net + tp.taxPrice.tax)
      .filter(Number.isFinite)
      .reduce(R.add, 0);
    return <TableTotal customTotalAmount={baseTotal + taxTotal} />;
  },

  onSaveSalesDocument: {
    on: (p, ap) => async (dispatch, getState) => {
      const taxProducts = getTaxProductsWithPrices(getState());

      const addTaxProductsToSSDRequest = taxProducts => request => {
        const availableIndex =
          Math.max(
            1,
            ...Object.keys(request)
              .filter(k => /productID\d+/.test(k))
              .map(k => k.replace(/productID/, ''))
              .map(Number),
          ) + 1;

        const out = { ...request };
        taxProducts.forEach((p, i) => {
          out[`productID${availableIndex + i}`] = p.productID;
          out[`amount${availableIndex + i}`] = 1;
          out[`price${availableIndex + i}`] = p.taxPrice.net;
        });
        return out;
      };

      return R.map(
        R.when(
          R.propEq('requestName', 'saveSalesDocument'),
          addTaxProductsToSSDRequest(taxProducts),
        ),
      )(ap);
    },
  },
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      const taxProducts = getTaxProductsWithPrices(getState());
      const taxTotal = taxProducts
        .map(tp => tp.taxPrice.net + tp.taxPrice.tax)
        .filter(Number.isFinite)
        .reduce(R.add, 0);

      return R.pipe(
        R.evolve({
          total: R.add(taxTotal),
        }),
      )(ap);
    },
  },
  UITableBill: ({ children }) => {
    const ids = useSelector(getTaxProducts);
    const prods = useSelector(getTaxProductsWithPrices);
    return (
      <>
        {children}
        <Table color="secondary" style={{ width: '100%' }}>
          <tbody>
            {prods.map((prod, i) => (
              <ProductRow key={prod.productID} product={prod} />
            ))}
          </tbody>
        </Table>
      </>
    );
  },
  ComponentHeader: ({ children }) => {
    return (
      <>
        {children}
        <div
          style={{
            display: 'none',
            position: 'fixed',
            inset: 'auto 0 0 auto',
            width: 200,
            height: 200,
            background: 'white',
            color: 'black',
            zIndex: 7000,
          }}
        />
      </>
    );
  },
};
