import { round } from 'utils';

export const getAdyenFromLocalStorage = () => {
  const adyenConfig = JSON.parse(sessionStorage.getItem('adyen-config'));
  if (adyenConfig !== null) {
    return adyenConfig;
  }
  return {};
};

export const saveAdyenToLocalStorage = config => {
  sessionStorage.setItem('adyen-config', JSON.stringify(config));
};

/**
 * @param {arrayOf(Object.shapeOf({X: string, Y: string}))} signature The object must contain coordenates from the signature
 * @returns Base64 string
 */
export const encodeSignatureToBase64 = signature => {
  // changes the values of the signature object from hexadecimal to decimal
  const arrayHexToDecimal = array => {
    const signatureArray = [];
    array.forEach(coordenates =>
      signatureArray.push(
        parseInt(coordenates.X, 16),
        parseInt(coordenates.Y, 16),
      ),
    );
    return signatureArray;
  };

  // packs data stream
  const uint16 = data => {
    let bytes = '';
    // eslint-disable-next-line no-bitwise
    bytes += String.fromCharCode(data & 0xff);
    // eslint-disable-next-line no-bitwise
    bytes += String.fromCharCode((data >> 8) & 0xff);
    return bytes;
  };

  const signatureArray = arrayHexToDecimal(signature);

  // packs data stream
  const packData = array => {
    const finalArray = [];
    array.forEach(number => finalArray.push(uint16(number)));
    return finalArray;
  };

  const base64String = window.btoa(packData(signatureArray).join(''));
  return base64String;
};

/**
 *  Listen for lost internet connection
 */
export const offlineListener = (setButtons, setMessage) => {
  window.addEventListener('offline', () => {
    setButtons([]);
    setMessage('No internet connection.');
  });
};

/**
 *  Listen for restored internet connection
 */
export const onlineListener = (setButtons, setMessage) => {
  window.addEventListener('online', () => {
    setMessage('Connected. Check payment status.');
    setButtons(['check-status']);
  });
};

/**
 * Maps a string.
 * @param {string} str String to be mapped
 */
export const parseQueryString = str =>
  Object.fromEntries(str.split('&').map(pair => pair.split('=')));

/**
 * Calculates the amount that will be paid to erply and to the shop based on the commission that erply will receive per payment.
 * @param {string || number} totalAmount
 * @param {arrayOf(Object)} erplyCommission
 */
export const calculateSplitsAmounts = (totalAmount, allSplits) => {
  if (!allSplits) {
    return false;
  }

  const finalConf = [...allSplits];

  const numAmount = Number(totalAmount);
  finalConf.forEach((splitConf, index) => {
    const finalAmount = (numAmount / 100) * Number(splitConf.percentage);
    finalConf[index].amount = round(finalAmount * 100, 0);
  });

  return finalConf;
};

/**
 * Calculates amounts for each split.
 * @param {arrayOf(Object)} splits Splits percentages to be implemented.
 * @param {number} totalAmount Total amount to be paid(all splits included).
 * @param {string} currencyCode Currency code.
 * @returns {string} String needed to apply splits to adyen market pay
 */
export const defineSplitString = (splits, cardPaymentData, currencyCode) => {
  let finalString = `split.api=1&split.nrOfItems=${
    splits.length
  }&split.totalAmount=${Number(cardPaymentData.amount) *
    100}&split.currencyCode=${currencyCode}`;

  splits.forEach((split, index) => {
    const currentIndex = index + 1;

    finalString += `&split.item${currentIndex}.amount=${Number(
      split.amount,
    )}&split.item${currentIndex}.type=${split.type}${
      split.type === 'MarketPlace'
        ? `&split.item${currentIndex}.account=${split.account}`
        : ''
    }${
      split.type === 'MarketPlace'
        ? `&split.item${currentIndex}.reference=${cardPaymentData.id.toString()}${currentIndex}`
        : ''
    }`;

    return true;
  });

  return finalString;
};

export let currentPayment = {};

export const setCurrentPayment = payload => (currentPayment = payload);
export const getCurrentPayment = () => currentPayment;
