import * as triposAPI from '../requests/triposRequests';

const waitForTerminalReady = async (lane, duration) => {
  const condition = { timedOut: false };

  const timeout = setTimeout(() => {
    condition.timedOut = true;
  }, duration);

  while (!condition.timedOut) {
    // eslint-disable-next-line no-await-in-loop
    const requestConnection = await triposAPI.requestCloudConnection({}, lane);
    if (
      // Successful request overall
      requestConnection.status === 200 &&
      // Endpoint responds that the connection is actually open and terminal can be used
      requestConnection.data?.status === 'Open'
    )
      break;

    // eslint-disable-next-line no-await-in-loop
    await new Promise(resolve => setTimeout(resolve, 750));
  }

  if (condition.timedOut) {
    throw new Error('Timeout while waiting for terminal ready');
  }

  clearTimeout(timeout);
};

export const handleErrors = ({
  err,
  displayError,
  updateMessage,
  enableButtons,
}) => {
  if (err.message === `Network Error`) {
    displayError(err.message);
    updateMessage(`Check connection with the terminal...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === `Too Many Requests`) {
    displayError(`Too Many Requests`);
    updateMessage(`Too Many Requests. \n \n Please wait and try again...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === `Transaction failed`) {
    displayError(`Transaction failed.`);
    updateMessage(`Transaction failed. \n \n Please try again...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === `Transaction declined`) {
    displayError(`Transaction declined.`);
    updateMessage(`Transaction declined. \n \n Please try again...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.response) {
    if (err.response.data.triPosError) {
      const { developerMessage: devMsg } = err.response.data.triPosError[0];
      displayError(`Transaction unsuccessful`);
      switch (devMsg) {
        case devMsg.includes(`The transaction has timed out`) && devMsg: {
          updateMessage(
            `The transaction has timed out. No request attempted. \n \n Please try again..`,
          );
          enableButtons(['retry-payment', 'return-back']);
          throw err;
        }
        case devMsg.includes(`Error getting signature`) && devMsg: {
          updateMessage(
            `Error getting signature. User may have pressed cancel. \n \n Please try again...`,
          );
          enableButtons(['retry-payment', 'return-back']);
          throw err;
        }
        case devMsg.includes(`Card is gift only card`) && devMsg: {
          updateMessage(
            `Card is gift only card but the configuration does not allow for gift transactions. \n \n Please try again...`,
          );
          enableButtons(['retry-payment', 'return-back']);
          throw err;
        }
        case devMsg.includes(`Card is debit only card`) && devMsg: {
          updateMessage(
            `Card is debit only card but the configuration does not allow for debit transactions. \n \n Please try again...`,
          );
          enableButtons(['retry-payment', 'return-back']);
          throw err;
        }
        case devMsg.includes(`Cancelled by cardholder`) && devMsg: {
          updateMessage(`Cancelled by cardholder. \n \n Please try again...`);
          enableButtons(['retry-payment', 'return-back']);
          throw err;
        }

        default:
          updateMessage(`Please try again...`);
          enableButtons(['retry-payment', 'return-back']);
          throw err;
      }
    }
    displayError(`Transaction unsuccessful.`);
    updateMessage(`Please try again...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === 'Timeout while waiting for terminal ready') {
    displayError('Failed to get status of the lane.');
    updateMessage('Could not get status of the lane or lane is closed.');
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  displayError(`Transaction unsuccessful.`);
  updateMessage(`Please try again...`);
  enableButtons(['retry-payment', 'return-back']);
  throw err;
};

export const withResponseErrors = ({ request, params }) =>
  new Promise((resolve, reject) => {
    const onResponse = {
      requestSale(response) {
        if (response.data.transactionStatus === 'Failed') {
          const error = { response, message: 'Transaction failed' };
          reject(error);
          return;
        }
        if (response.data.transactionStatus === 'Declined') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }
        resolve(response);
      },
      requestReversal(response) {
        if (response.data.transactionStatus === 'Cancelled') {
          const error = { response, message: 'Transaction cancelled' };
          reject(error);
          return;
        }
        if (response.data.transactionStatus === 'Failed') {
          const error = { response, message: 'Transaction failed' };
          reject(error);
          return;
        }
        if (response.data.transactionStatus === 'Declined') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }
        resolve(response);
      },
      requestReturn(response) {
        if (response.data.transactionStatus === 'Cancelled') {
          const error = { response, message: 'Transaction cancelled' };
          reject(error);
          return;
        }
        if (response.data.transactionStatus === 'Failed') {
          const error = { response, message: 'Transaction failed' };
          reject(error);
          return;
        }
        if (response.data.transactionStatus === 'Declined') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }

        resolve(response);
      },
      testConnection(response) {
        resolve(response);
      },
    };

    waitForTerminalReady(params.configuration.laneId, 12000)
      .then(() =>
        triposAPI[request]({ ...params }).then(
          response => {
            onResponse[request](response);
          },
          err => {
            throw err;
          },
        ),
      )
      .catch(err => {
        reject(err);
      });
  });
