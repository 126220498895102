import React from 'react';
import { Button, ButtonProps, Theme, withStyles } from '@material-ui/core';

import {
  isPaletteColor,
  generateButtonColorStyles,
} from 'utils/muiStyles/utils';
import { ThemeColor } from 'utils/muiStyles/types';

function generateThemeColorStyles(theme: Theme) {
  return (
    Object.keys(theme.palette)
      .filter(color => isPaletteColor(color, theme))
      // Primary and secondary styles already exist
      .filter(color => !['primary', 'secondary'].includes(color))
      .reduce((styles, colorName) => {
        return {
          ...styles,
          ...generateButtonColorStyles(colorName, theme),
        };
      }, {})
  );
}

export type MuiButtonProps = Omit<ButtonProps, 'color'> & {
  color: ThemeColor;
};

/**
 * Proxy for MUI Button that overrides "color" prop type
 */
function TypedButton({ color, ...rest }: MuiButtonProps) {
  return (
    <Button
      // @ts-ignore
      color={color}
      {...rest}
    />
  );
}

/**
 * Extension of MUI Button component with added support for all theme colors
 */
const MuiButton = withStyles(generateThemeColorStyles, { name: 'MuiButton' })(
  TypedButton,
);

export default MuiButton;
