import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useBreakpoints } from 'utils/hooks/UI';
import ListItem from 'components/ListItem';
import {
  getPayments,
  getBalance,
  getLastPayment,
  getPaymentSelected,
  getIsReturnPayment,
} from 'reducers/Payments';
import {
  deletePayment,
  setPaymentEditValue,
  setShowPaymentInput,
  fillSelectedPayment,
} from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { generateCustomPaymentKey } from 'utils/payments';
import { addWarning } from 'actions/Error';
import { getCustomPaymentTypes } from 'reducers/PaymentTypes';

const CustomPayment = ({ type, disabled = false }) => {
  const dispatch = useDispatch();
  const isMobileView = !useBreakpoints().mdplus;
  const isReturnPayment = useSelector(getIsReturnPayment);
  const payments = useSelector(getPayments);
  const balance = useSelector(getBalance);
  const { t } = useTranslation('payment');
  const { lastPayment, lastKey } = useSelector(getLastPayment);
  const { name } = useSelector(getCustomPaymentTypes).find(
    p => p.type === type,
  );
  const selectedPaymentKey = useSelector(getPaymentSelected);
  const selectedPayment = useSelector(getPayments)[selectedPaymentKey];

  const addCustomPayment = () => {
    if (selectedPayment?.type === type) {
      return dispatch(fillSelectedPayment());
    }
    dispatch(setPaymentEditValue(''));

    const fullPayment = isReturnPayment ? balance : -balance;
    if (fullPayment > 0) {
      const keys = Object.keys(payments).filter(key => key.includes(type));

      const emptyKey = keys.find(key => payments[key].amount === '0.00');

      const paymentKey = emptyKey || generateCustomPaymentKey(keys, type);

      (async () => {
        if (lastPayment.amount === '0.00')
          dispatch(deletePayment({ key: lastKey }));
        await dispatch(
          setPayment({
            amount: '0.00',
            key: paymentKey,
            caption: name,
            type,
            paymentIntegration: type === 'CARD' ? 'external' : undefined,
          }),
        );
        await dispatch(setPaymentSelected(paymentKey));
      })();
    } else {
      dispatch(
        addWarning(t('alerts.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
  };

  return isMobileView ? (
    <li className="payment-item card-payment" style={{ cursor: 'pointer' }}>
      <div
        tabIndex={0}
        className="button"
        role="button"
        key={type}
        onClick={() => dispatch(setShowPaymentInput(true, type))}
      >
        <span className="title">{name}</span>
      </div>
    </li>
  ) : (
    <ListItem
      key={type}
      disabled={disabled}
      variant="grid_yellow"
      action={addCustomPayment}
    >
      <span>{name}</span>
      {selectedPayment?.type === type && (
        <span className="tip">
          {new Intl.NumberFormat(undefined, { style: 'percent' }).format(1)}
        </span>
      )}
    </ListItem>
  );
};

export default CustomPayment;
