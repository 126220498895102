import { ListItem } from '@material-ui/core';
import React, { FC } from 'react';

import { Employee } from 'types/Employee';

type Props = {
  employee: Employee;
  choose: (employee: Employee) => void;
  selectedEmployee: Employee | null;
};

const EmployeeLine: FC<Props> = ({ employee, choose, selectedEmployee }) => {
  return (
    <ListItem
      selected={selectedEmployee?.id === employee.id}
      onClick={() => choose(employee)}
      divider
      button
    >
      {employee.fullName}
    </ListItem>
  );
};

export default EmployeeLine;
