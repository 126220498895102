import { openPluginModalPage } from 'actions/modalPage';
import { modals } from 'plugins/mm/constants';

import styles from './coupons.module.scss';
import { GridButtonFunction } from 'reducers/UI/gridButtons';

export const loadCouponsButton = {
  id: 'loadCoupons',
  actionType: 'action',
  action: openPluginModalPage(modals.LOAD_COUPONS)({
    isPopup: true,
    modalClassName: styles['load-coupons-modal'],
  }),
} as GridButtonFunction;

export { default as LoadCoupons } from './LoadCoupons';
