import debug from 'debug';
import i18next from 'i18next';

import { getClientCode, getSessionKey } from 'reducers/Login';
import { addError } from 'actions/Error';

import { getServiceInfo } from './api';

const baseLog = debug('metricsCollector');

export function getDeviceID() {
  return async (
    dispatch,
    getState,
  ): Promise<string | null> => {
    const log = baseLog.extend('getDeviceID');
    const clientCode = getClientCode(getState());
    const sessionKey = getSessionKey(getState());
    try {
      log('Fetching service info');
      const serviceInfo = await getServiceInfo(sessionKey, clientCode);
      log('Received service info', serviceInfo);
      const hostID = serviceInfo.records[0].HostID;
      if (!hostID) {
        log(
          'HostID is missing, the microservice version is too old',
          serviceInfo,
        );
        dispatch(
          addError(
            i18next.t('alerts:metricsCollector.missingHostIdFromResponse'),
          ),
        );
        return null;
      }
      log('Successfully fetched device ID', hostID.ID);
      return hostID.ID;
    } catch (error) {
      log('Failed to get device ID from metrics collector microservice', error);
      dispatch(
        addError(i18next.t('alerts:metricsCollector.failedToFetchDeviceId')),
      );
      return null;
    }
  };
}
