import i18next from 'i18next';

import {
  SET_MANUAL_CAMPAIGNS,
  SET_COUPON_CAMPAIGNS,
  SET_COUPON_RULES,
} from 'constants/CampaignsDB';
import {
  getManualPromotions,
  getCouponCampaigns,
  getCoupons,
  saveIssuedCoupon,
} from 'services/ErplyAPI/campaigns';
import { modalPages as mp } from 'constants/modalPage';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getApplicableCoupons } from 'reducers/CampaignsDB';
import { timestampInSeconds } from 'utils';
import { openModalPage } from 'actions/ModalPage/openModalPage';

import { addError, addWarning, dismissAll } from './Error';
import { createConfirmation } from './Confirmation';
import { prepareCouponData } from './integrations/printer/preparePrintoutData';
import { printCoupon } from './integrations/printer';

/** Fetch coupons/campaigns from Erply API and save them in redux */
export function fetchCampaignsAndCoupons() {
  return async (dispatch, getState) => {
    const warehouseID = getSelectedWarehouseID(getState());
    await Promise.all([
      getManualPromotions(warehouseID)
        .then(data =>
          dispatch({ type: SET_MANUAL_CAMPAIGNS, payload: { data } }),
        )
        .catch(() => {}), // TODO: Is this safe to ignore?
      getCouponCampaigns(warehouseID)
        .then(data =>
          dispatch({ type: SET_COUPON_CAMPAIGNS, payload: { data } }),
        )
        .catch(() => {}), // TODO: Is this safe to ignore?
      getCoupons()
        .then(data => dispatch({ type: SET_COUPON_RULES, payload: { data } }))
        .catch(() => {}), // TODO: Is this safe to ignore?
    ]);
  };
}

/** Fetch available coupons and then open the coupon view */
export function fetchAvailableCoupons() {
  return async dispatch => {
    dispatch(
      addWarning(i18next.t('alerts:loading.coupons'), { dismissible: false }),
    );
    try {
      const coupons = await getCoupons().then(records =>
        records.filter(r => r.printedAutomaticallyInPOS === 0),
      );
      dispatch(dismissAll());
      dispatch(openModalPage({ component: mp.coupons, props: { coupons } }));
    } catch (err) {
      dispatch(dismissAll());
      console.error('Unable to fetch available coupons', err);
      addError(i18next.t('alerts:genericError'), {
        selfDismiss: true,
        dismissible: false,
      });
    }
  };
}

export function saveApplicableCoupons({ invoiceID, invoiceNo, salesDocument }) {
  return async (dispatch, getState) => {
    const {
      warehouseID,
      pointOfSaleID,
      employeeID,
      customerID,
    } = salesDocument;

    const applicableCoupons = getApplicableCoupons(getState());

    const automaticallyPrintedCoupons = [];
    /**
     * Variable that shows if a sale was created as a pending sale via "save sale"
     * Checks by invoiceNo being 0, type CASHINVOICE and if doc not confirmed
     */
    const isNewPendingSale =
      Number(invoiceNo) === 0 &&
      salesDocument.type === 'CASHINVOICE' &&
      Number(salesDocument.confirmInvoice) === 0;
    // In case of pending sale being created via "Save sale" do not print coupons
    const couponsToPrint = isNewPendingSale
      ? []
      : (
          await Promise.all(
            applicableCoupons.map(async ({ couponID, print }) => {
              const issuedTimestamp = timestampInSeconds();
              try {
                const [{ uniqueIdentifier }] = await saveIssuedCoupon({
                  customerID,
                  couponID,
                  warehouseID,
                  pointOfSaleID,
                  employeeID,
                  timestamp: issuedTimestamp,
                  invoiceID,
                });

                if (print === 'AUTOMATICALLY') {
                  automaticallyPrintedCoupons.push({
                    uniqueIdentifier,
                    couponID,
                    print,
                  });
                }
                return { uniqueIdentifier, couponID, print };
              } catch (error) {
                if (error.errorCode === 1042) {
                  /**
                   * Sometimes presumed amount of customer's reward points is not enough to issue
                   * calculated amount of coupons (API call fails with error code 1042).
                   * In that case just skip those coupons since technically they did not satisfy
                   * requirements of the coupon rule and therefore should not be issued.
                   */
                  return null;
                }
                throw error;
              }
            }),
          )
        )?.filter(coupon => coupon && coupon?.print !== 'NEVER');

    let isConfirmed = true;

    const couponsWithPrompt =
      couponsToPrint.length - automaticallyPrintedCoupons.length;
    if (couponsWithPrompt > 0) {
      // Ask once to decide about all coupons that prompt for print
      isConfirmed = await new Promise((resolve, reject) => {
        dispatch(
          createConfirmation(resolve, reject, {
            title: i18next.t('alerts:coupons.title'),
            body: i18next.t('alerts:coupons.body', {
              coupons: couponsWithPrompt,
              automaticallyPrintedCoupons: automaticallyPrintedCoupons.length,
            }),
          }),
        );
      }).catch(() => false);
    }

    const failedCouponPrints = [];

    const handleCouponPrint = async ({
      uniqueIdentifier,
      couponID,
      couponName,
    }) => {
      try {
        const data = await dispatch(
          prepareCouponData({
            uniqueIdentifier,
            couponID: Number(couponID),
          }),
        );
        try {
          await dispatch(printCoupon(data));
        } catch (err) {
          failedCouponPrints.push(couponName);
          console.error(
            'Failed to print coupon after retrieving printout data.',
          );
        }
      } catch (err) {
        failedCouponPrints.push(couponName);
        console.error('Failed to prepare coupon printout data');
      }
    };

    if (isConfirmed) {
      couponsToPrint.forEach(handleCouponPrint);
    } else {
      automaticallyPrintedCoupons.forEach(handleCouponPrint);
    }

    if (failedCouponPrints.length) {
      await dispatch(
        addError(
          'Failed to print the following coupons:\n',
          failedCouponPrints.join(','),
        ),
      );
    }
  };
}
