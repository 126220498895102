import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { format } from 'date-fns';

import {
  getCurrencyCode,
  getCurrencySymbol,
  getShouldShowTaxRateInsteadOfName,
} from 'reducers/configs/settings';
import { getCompany, getLoggedInEmployeeID } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getSelectedWarehouseID, getWarehouseById } from 'reducers/warehouses';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getVatRates } from 'reducers/vatRatesDB';
import { lastInvoiceNumbers } from 'services/localDB';
import { getCurrency } from 'reducers/configs/currency';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getRounding } from 'reducers/Payments';
import { getProductsUniversal } from 'actions/productsDB';
import { add, round } from 'utils';
import { getLastPayments } from 'reducers/sales';
import { RootState } from 'reducers/index';

import { OfflinePrintParams, VatTotal } from './types';
import { mapRow, mapRowForActualDataSet, defaultTranslations } from './utils';

/**
 * This function will gather all information that is available in current state
 * and create an object that will be sent to receipt-printout-api MS
 */
function prepareOfflineData({ salesDocument }: Pick<OfflinePrintParams, 'salesDocument'>) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    const vatrates = getVatRates(getState());

    const { employeeID, rows, rounding, total } = salesDocument;
    // const total = rows.reduce((sum, row) => sum + row.rowTotal, 0) + rounding;
    const netTotal = rows.reduce((sum, row) => sum + row.rowNetTotal, 0);
    const vatTotal = total - netTotal;

    const company = getCompany(getState());
    const pointOfSale = getSelectedPos(getState());
    const warehouse = getWarehouseById(pointOfSale.warehouseID)(getState());
    const currentEmployeeID = getLoggedInEmployeeID(getState());
    const employee = getEmployeeById(employeeID ?? currentEmployeeID)(getState());
    const currencyCode = getCurrencyCode(getState());
    const currencySymbol = getCurrencySymbol(getState());
    const shouldShowTaxRateInsteadOfName = getShouldShowTaxRateInsteadOfName(
      getState(),
    );

    const {
      email,
      fullName,
      customerCardNumber,
      factoringContractNumber,
      address,
      address2,
      country,
      state,
      postalCode,
      city,
      bankAccountNumber,
      bankName,
      code,
      fax,
      mobile,
      phone,
      GLN,
      groupName,
      customerID,
      customerManagerName,
      notes,
      rewardPoints,
    } = getSelectedCustomer(getState());

    const { employeeName } = employee;


    const formatPrice = (price: string | number) =>
      String(price).replace(/[^0-9.',-]+/g, '');

    const vatTotals = await rows.reduce<Promise<Record<string, VatTotal>>>(
      async (vatRows, productRow) => {
        const {
          products: [{ vatrateID }],
        } = await dispatch(
          getProductsUniversal({ productID: productRow.productID }),
        );

        const vatRate = vatrates.find(vat => vat.id === String(vatrateID));

        return {
          ...(await vatRows),
          [vatrateID]: {
            tax: shouldShowTaxRateInsteadOfName ? vatRate?.rate : vatRate?.name,
            netSum: productRow.rowNetTotal.toFixed(2),
            vatSum: productRow.rowVATTotal.toFixed(2),
            rate: vatRate?.rate,
          },
        };
      },
      Promise.resolve({}),
    );

    const vatTotalsByRate = await rows.reduce<Promise<Record<string, VatTotal>>>(
    async (vatRows, productRow) => {
      const {
        products: [{ vatrateID }],
      } = await dispatch(
        getProductsUniversal({ productID: productRow.productID }),
      );

      const vatRate = vatrates.find(vat => vat.id === String(vatrateID));

      return {
        ...(await vatRows),
        [vatrateID]: vatRate?.rate,
      };
    },
    Promise.resolve({}),
  );

    const lastInvoiceNoLocal = await lastInvoiceNumbers.getItem({
      key: pointOfSale.pointOfSaleID,
    });

    const productIDs = rows.map(r => r.productID);
    const { productsDict } = await dispatch(getProductsUniversal({ productIDs }));

    const mergedProducts = rows.map(row => ({
      ...productsDict[row.productID],
      ...row,
    }));

    const documentRows = mergedProducts.map(product =>
      mapRowForActualDataSet(product, currencySymbol),
    );
    const matrixRows = mergedProducts
      .filter(({ type }) => type === 'MATRIX')
      .map(product => mapRowForActualDataSet(product, currencySymbol));

    const paidTotal = documentRows.map(row => Number(row.rowTotal)).reduce(add);

    const totalDiscountSum = rows.reduce((acc, { discount, rowTotal }) => {
      return acc + (rowTotal / 100) * discount;
    }, 0);

    const vatTotalsByRateForAD = await rows.reduce<Promise<VatTotal[]>>(
      async (vatRates, productRow, _index, allRows) => {
        const {
          products: [{ vatrateID }],
        } = await dispatch(
          getProductsUniversal({ productID: productRow.productID }),
        );

        const vatRate = vatrates.find(vat => vat.id === String(vatrateID));

        const netTotal = allRows
          .filter(row => row.vatRate === vatRate?.rate)
          .map(row => row.rowNetTotal)
          .reduce(add, 0);

        const vatTotal = allRows
          .filter(row => row.vatRate === vatRate?.rate)
          .map(row => row.rowVATTotal)
          .reduce(add, 0);

        const newVatRate = {
          rateID: Number(vatrateID),
          name: vatRate?.name,
          netTotal: netTotal.toString(),
          netTotalWithFormat: netTotal.toFixed(2),
          netTotalWithCurrency: `${netTotal.toFixed(2)} ${currencySymbol}`,
          total: vatTotal,
          totalWithFormat: vatTotal.toFixed(2),
          totalWithCurrency: `${vatTotal.toFixed(2)} ${currencySymbol}`,
          rate: `${vatRate?.rate} %`,
        };
        const included = (await vatRates).some(
          vatRate => vatRate.rateID === newVatRate.rateID,
        );
        if (!included) {
          (await vatRates).push(newVatRate);
        } else {
          await vatRates;
        }

        return vatRates;
      },
      Promise.resolve([]),
    );

    // getLastPayments selector is used here, because only latest document printing is supported in offline mode
    const payments = Object.values<Record<string, any>>(
      getLastPayments(getState()),
    ).map(({ added, currencyCode, sum, type, cashPaid }) => ({
      date: format(new Date(added * 1000), 'dd.mm.yyyy'),
      type,
      currencySymbol: getCurrency(currencyCode)(getState()).symbol,
      currency: currencyCode,
      sum,
      paid: cashPaid,
      sumWithCurrency: formatPrice(
        `${sum} ${getCurrency(currencyCode)(getState()).symbol}`,
      ),
      paidWithCurrency: formatPrice(
        `${sum} ${getCurrency(currencyCode)(getState()).symbol}`,
      ),
    }));

    const doc = {
      addressID: warehouse.addressID,
      baseDocuments: [],
      clientCardNumber: customerCardNumber,
      clientEmail: email,
      clientFactoringContractNumber: factoringContractNumber,
      clientID: salesDocument.clientID,
      clientName: fullName,
      currencyCode,
      currencyRate: '1.000000000000',
      date: format(new Date(), 'yyyy-MM-dd'),
      employeeID: Number(salesDocument.employeeID),
      employeeName,
      id: Number(salesDocument.id),
      internalNotes: salesDocument.internalNotes,
      invoiceLink: salesDocument.invoiceLink,
      netTotal,
      notes: salesDocument.notes,
      number: lastInvoiceNoLocal,
      paid: String(total),
      pointOfSaleID: pointOfSale.pointOfSaleID,
      pointOfSaleName: pointOfSale.name,
      pricelistID:
        warehouse.pricelistID ||
        warehouse.pricelistID2 ||
        warehouse.pricelistID3 ||
        warehouse.pricelistID4 ||
        warehouse.pricelistID5,
      receiptLink: salesDocument.receiptLink,
      rounding,
      rows: salesDocument.rows.map(row => mapRow(row, currencySymbol)),
      taxExemptCertificateNumber: '',
      time: format(new Date(), 'hh:mm:ss'),
      total,
      vatTotal,
      vatTotalsByRate,
      vatTotalsByTaxRate: Object.values(vatTotals),
      warehouseID: Number(getSelectedWarehouseID(getState())),
      warehouseName: warehouse.name,
    };

    const dataset = {
      companyAccountNumber: company.bankAccountNumber,
      companyAccountNumber2: company.bankAccountNumber2,
      companyAddressCountry: company.country,
      companyAddressFull: ' ',
      companyAddressLabelLine1: ' ',
      companyAddressPostalCode: '',
      companyAddressState: '',
      companyAddressStreetAndBuilding: '',
      companyAddressTownOrCity: '',
      companyBank: company.bankName,
      companyBank2: company.bankName2,
      companyEmail: company.email,
      companyFax: company.fax,
      companyIBAN: company.bankIBAN,
      companyIBAN2: company.bankIBAN2,
      companyName: company.name,
      companyNotes: '',
      companyPhone: company.phone,
      companyRegCode: company.code,
      companySWIFT: company.bankSWIFT,
      companySWIFT2: company.bankSWIFT2,
      companyTaxOffice: '',
      companyVatNumber: company.VAT,
      companyWeb: company.web,
      currency: currencyCode,
      currencyRate: '1,00',
      currencySymbol,
      currentDate: format(new Date(), 'yyyy-mm-dd'),
      currentDateTime: format(new Date(), 'yyyy-mm-dd hh:mm:ss'),
      currentTime: format(new Date(), 'hh:mm:ss'),
      customerAddressAddressLine2: address2,
      customerAddressCountry: country,
      customerAddressFull: address,
      customerAddressPostalCode: postalCode,
      customerAddressState: state,
      customerAddressTownOrCity: city,
      customerBankAccountNumber: bankAccountNumber,
      customerBankName: bankName,
      customerCode: code,
      customerContactEmail: email,
      customerContactFax: fax,
      customerContactMobile: mobile,
      customerContactName: fullName,
      customerContactPhone: phone,
      customerEmail: email,
      customerFax: fax,
      customerGLNCode: GLN,
      customerGroup: groupName,
      customerID,
      customerManager: customerManagerName,
      customerMobile: mobile,
      customerName: fullName,
      customerNotes: notes,
      customerPhone: phone,
      documentDate: format(new Date(), 'MM.dd.yyyy'),
      documentName: `${defaultTranslations.sale.documentNumber} ${lastInvoiceNoLocal}`,
      documentNumber: lastInvoiceNoLocal,
      documentRows,
      documentTime: format(new Date(), 'hh:mm:ss'),
      employeeEmail: employee.email,
      employeeFax: employee.fax,
      employeeID: Number(salesDocument.employeeID),
      employeeMobile: employee.mobile,
      employeeName: employee.employeeName,
      employeePhone: employee.phone,
      matrixRows,
      netTotal,
      netTotalWithCurrency: `${netTotal} ${currencySymbol}`,
      netTotalWithFormat: String(netTotal),
      newRewardPointsBalance: round(rewardPoints, 2),
      notes: salesDocument.notes,
      paidTotal: String(paidTotal),
      paidTotalWithCurrency: `${paidTotal} ${currencySymbol}`,
      payments,
      registerName: warehouse.name,
      rounding,
      roundingWithCurrency: `${rounding} ${currencySymbol}`,
      total,
      totalAmount: String(rows.map(({ amount }) => Number(amount)).reduce(add)),
      totalDiscountPercent: `${rows
        .map(({ discount }) => Number(discount))
        .reduce(add)} %`,
      totalDiscountSum: String(totalDiscountSum),
      totalDiscountSumWithCurrency: `${totalDiscountSum} ${currencySymbol}`,
      totalWithCurrency: `${total} ${currencySymbol}`,
      totalWithFormat: String(total),
      vatTotal,
      vatTotalWithCurrency: `${vatTotal} ${currencySymbol}`,
      vatTotalWithFormat: String(vatTotal),
      vatTotalsByRate: vatTotalsByRateForAD,
      warehouseName: warehouse.name,
      wayOfDelivery: '',
      webShopOrderNumber: '',
      zeroVatRateNotice: '',
    };

    return {
      documentActualReport: dataset,
      salesDocument: doc,
    };
  };
}

export default prepareOfflineData;
