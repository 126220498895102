import { Card } from './types';

export const removeCard = (cards: Card[], cardNbr: string) => {
  const updatedCards = cards.filter(card => card.cardNumber !== cardNbr);
  return updatedCards;
};

export const mountOrUnmountCard = (prevCards: Card[], cardNbr: string) =>
  prevCards.map(card => ({
    ...card,
    mounted: card.cardNumber === cardNbr && !card.mounted,
  }));
