import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useErrorState, { translateError } from 'utils/hooks/useErrorState';

import { Ctx } from '../../../../components/CtxInputs';
import RenderFormItem from '../../../../components/FormFieldCtx';

const tsysCanadaFields = {
  terminalID: {
    id: 'terminalID',
    type: 'text',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'terminalID' } },
  },
  xWebID: {
    id: 'xWebID',
    type: 'text',
    validate: val =>
      val?.length ? null : { message: 'isMissing', dict: { field: 'xWebID' } },
  },
  authKey: {
    id: 'authKey',
    type: 'password',
    validate: val =>
      val?.length ? null : { message: 'isMissing', dict: { field: 'authKey' } },
  },
  printReceipt: {
    id: 'printReceipt',
    type: 'select',
    options: [
      { name: 'Select an option', value: '' },
      { name: 'Merchant and Customer', value: '1' },
      { name: 'Merchant', value: '0' },
      { name: 'None', value: '2' },
    ],
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'printReceipt' } },
  },
};

const TSYSCanadaConfiguration = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.tsysCanada.${text}`, ...rest);

  const superContext = useContext(Ctx);
  const { configuration, setEnableUpdate } = superContext.values;

  const { errors, hasErrors } = useErrorState(configuration, tsysCanadaFields);

  useEffect(() => setEnableUpdate(!hasErrors), [hasErrors]);

  const onChange = (key, value) => {
    superContext.onChange('configuration', { ...configuration, [key]: value });
  };
  return (
    <div>
      <span>{t('title')}</span>
      <Ctx.Provider value={{ values: configuration, onChange }}>
        {Object.values(tsysCanadaFields).map(({ validate, ...rest }) => (
          <RenderFormItem
            key={rest.id}
            title={t(rest.id)}
            name={rest.id}
            error={translateError(errors[rest.id], t)}
            {...rest}
          />
        ))}
      </Ctx.Provider>
    </div>
  );
};

export default TSYSCanadaConfiguration;
