import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { Trans, useTranslation } from 'react-i18next';

import ErplyAlert from 'components/ErplyAlert';
import Confirmation from 'components/Confirmation';
import { CustomerTooltip } from 'containers/Forms/Customers';
import VisibleConsole from 'components/VisibleConsole';
import OnScreenKeyboard from 'components/Keyboards/OnScreenKeyboard';
import { SentryErrorBoundary } from 'containers/App/Sentry';
import * as themes from 'utils/muiTheme';
import { getActiveColorTheme } from 'reducers/UI/theme';

import './i18n';
import MainApp from './App';
import useBootstrapTheme from './hooks/useBootstrapTheme';
import useUserPreferredTheme from './hooks/useUserPreferredTheme';

import 'assets/App.scss';

const useAppLock = () => {
  const [hasLock, setHasLock] = useState(/** @type boolean|null */ null);
  useEffect(() => {
    let done;
    function holdLock() {
      if (done) return;
      setHasLock(true);
      return new Promise(resolve => {
        done = resolve;
      });
    }

    navigator.locks.request(
      'brazilpos',
      { mode: 'exclusive', ifAvailable: true },
      lock => {
        // Got lock
        if (lock !== null) return holdLock();

        // Did not get lock, report failure and get in line
        setHasLock(false);
        navigator.locks.request('brazilpos', { mode: 'exclusive' }, holdLock);
      },
    );
    return () => {
      setHasLock(false);
      if (done) {
        done();
      } else {
        done = true;
      }
    };
  }, []);

  return hasLock;
};

const App = () => {
  const colorTheme = useSelector(getActiveColorTheme);

  function getSelectedTheme() {
    if (colorTheme === 'highContrast') return themes.highContrastTheme;
    return colorTheme === 'dark' ? themes.darkTheme : themes.lightTheme;
  }

  useUserPreferredTheme();
  useBootstrapTheme(colorTheme);

  const hasLock = useAppLock();
  useTranslation('duplicateTabs');
  if (hasLock === null) return <div className="duplicateTabError" />;
  if (hasLock === false) {
    return (
      <div className="duplicateTabError">
        <div>
          <Trans
            ns="duplicateTabs"
            i18nKey="message"
            components={{
              title: <h4 />,
              p: <p />,
              a: (
                <a href="https://wiki.erply.com/article/1999-single-tab-solution-for-brazil-pos" />
              ),
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <SentryErrorBoundary>
      <ThemeProvider theme={getSelectedTheme()}>
        <MainApp />

        <ErplyAlert />
        <Confirmation />
        <CustomerTooltip />
        <OnScreenKeyboard />
        <VisibleConsole />
      </ThemeProvider>
    </SentryErrorBoundary>
  );
};

export default App;
