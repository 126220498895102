import i18next from 'i18next';

import {
  closeConfirmationByID,
  createConfirmation,
} from 'actions/Confirmation';

import { getCafaEntry } from '../../reducers/cafaConfigs';
import { INTEGRATION_TYPES } from '../../constants/CAFA';

import { MONERIS_CONST } from './types';

// todo translations

const askForEntryMode = () => async dispatch =>
  new Promise((resolve, reject) => {
    let id = '';
    setTimeout(() => {
      dispatch(closeConfirmationByID(id));
      reject();
    }, 7000);
    const { payload } = dispatch(
      createConfirmation(resolve, reject, {
        title: i18next.t(`Manual Entry Mode`),
        body: i18next.t(
          `Do you want to allow the usage of Manual Entry Mode for the following transactions?`,
        ),
      }),
    );
    // eslint-disable-next-line prefer-destructuring
    id = payload.id;
  })
    .then(() => true)
    .catch(() => false);

/**
 * Checks if Moneris Configuration has been configured to use Manual Entry Mode
 *
 * If the config has a origEntryMode configuration value
 * * always - set EntryMode to manual - {origEntryMode: 'M'}
 * * never - don't do anything - return {}
 * * ask - ask the user via popup for their input and return one of the above based on their selection
 */

export const getManualEntryModeParams = () => async (dispatch, getState) => {
  const { value: config } =
    getCafaEntry('moneris', INTEGRATION_TYPES.payment)(getState()) || {};
  const { KEY, OPTIONS } = MONERIS_CONST.CONFIG.ENTRY_MODE;

  const manualEntryModeParam = {
    allowed: { origEntryMode: 'M' },
    disallowed: {},
  };
  switch (config?.[KEY]) {
    case OPTIONS.ALWAYS:
      return Promise.resolve(manualEntryModeParam.allowed);
    case OPTIONS.ASK:
      return dispatch(askForEntryMode()).then(isAllowed =>
        isAllowed
          ? manualEntryModeParam.allowed
          : manualEntryModeParam.disallowed,
      );
    case OPTIONS.NEVER:
    default:
      return Promise.resolve(manualEntryModeParam.disallowed);
  }
};
