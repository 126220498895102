import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import React from 'react';

import { AccountAdminEmployee, Employee } from 'types/Employee';

interface Props
  extends Omit<
    AutocompleteProps<Employee | AccountAdminEmployee, false, false, false>,
    'renderInput'
  > {
  label: string;
}

const isRegularEmployee = (
  employee: Employee | AccountAdminEmployee,
): employee is Employee => !!(employee as Employee).fullName;

const EmployeeSelectField: React.FC<Props> = ({ label, ...props }) => {
  return (
    <Autocomplete
      getOptionLabel={option =>
        isRegularEmployee(option)
          ? option.fullName
          : `${option.firstName}, ${option.lastName}`
      }
      style={{ margin: '0.5rem 0' }}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          inputProps={{
            'data-testid': 'employee-select',
            ...params.inputProps,
          }}
          onKeyDown={e => {
            // Do not trigger form submission
            if (e.key === 'Enter') e.preventDefault();
          }}
          label={label}
          size="medium"
        />
      )}
    />
  );
};

export default EmployeeSelectField;
