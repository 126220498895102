import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';

import { getAllWarehouses } from 'reducers/warehouses';
import { Warehouse } from '../../types/Warehouse';

const getWarehousesById = createSelector(
  getAllWarehouses,
  warehouses =>
    new Map<number, Warehouse>(
      warehouses.map(wh => [Number(wh.warehouseID), wh]),
    ),
);

export const WarehouseAutocomplete = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: Partial<
    AutocompleteProps<number, Multiple, DisableClearable, FreeSolo>
  >,
) => {
  const { t: tCommon } = useTranslation('common');
  const warehouses = useSelector(getWarehousesById);
  const options = useMemo(() => {
    const options = Array.from(warehouses.keys());
    options.sort((a,b) => a - b);
    return options;
  }, [warehouses]);

  return (
    <Autocomplete
      renderInput={props => <TextField variant="outlined" {...props} />}
      options={options}
      getOptionLabel={id => warehouses.get(id)?.name ?? tCommon('loading')}
      filterOptions={createFilterOptions({
        stringify: id => {
          const wh = warehouses.get(id ?? NaN);
          return wh?.name ?? '';
        },
      })}
      {...props}
    />
  );
};
