import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { getShouldLock } from '../redux/selectors';

const UIProductGroups: Required<PosPlugin>['UIProductGroups'] = ({
  children,
}) => {
  const shouldLock = useSelector(getShouldLock);

  if (!shouldLock) return children;

  return R.pipe(
    R.over(
      R.lensPath(['props', 'items']),
      R.map(
        R.unless(
          R.propEq('type', 'special'),
          R.pipe(
            R.assoc('disabled', true),
            R.assoc('style', { disabled: 0.25, pointerEvents: 'none' }),
          ),
        ),
      ),
    ),
    R.over(
      R.lensPath(['props', 'specialBttnsEndIfFull']),
      R.map(
        R.pipe(
          R.assoc('disabled', true),
          R.assoc('style', { disabled: 0.25, pointerEvents: 'none' }),
        ),
      ),
    ),
  )(children);
};

export default UIProductGroups;
