/* eslint-disable no-restricted-globals,no-undef */

import getDB from 'services/DB/getDB';
import { REDUX_POSID, REDUX_SESSIONKEY } from 'constants/persistence';
import { SO } from 'services/DB/types';

const partnerKey = process.env.REACT_APP_PARTNER_KEY;

const proxy = process.env.REACT_APP_PROXY_URL
  ? `${process.env.REACT_APP_PROXY_URL}:${process.env.REACT_APP_PROXY_PORT}/`
  : '';

const urlEncode = (dict: Record<string, string>) =>
  Object.entries(dict)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

const ErplyRequest = async params => {
  const db = await getDB(params.clientCode);
  const sessionKey = await db.get(SO.SHARED.NAME, REDUX_SESSIONKEY);
  const posID = await db.get(SO.SHARED.NAME, REDUX_POSID);
  Object.entries({ ...params, sessionKey, partnerKey })
    .filter(([, value]) => value === undefined)
    // eslint-disable-next-line no-param-reassign
    .forEach(([key, _]) => delete params[key]);
  const body = { partnerKey, ...params, sessionKey, posID };
  return self
    .fetch(`${proxy}https://${body.clientCode}.erply.com/api/`, {
      method: 'POST',
      body: urlEncode(body),
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })
    .then(response => response.json())
    .then(data => data)
    .catch(e => console.error('Failed to execute ErplyAPI request', params, e));
};

export default ErplyRequest;
