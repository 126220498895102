export const REDUX_CLIENTCODE = 'REDUX-clientcode';
export const REDUX_SESSIONKEY = 'REDUX-sessionkey';
export const REDUX_USERNAME = 'REDUX-username';
export const REDUX_OFFLINELOGOUTPIN = 'REDUX-offlineLogoutPin';
export const REDUX_PIN = 'REDUX-pin';
export const REDUX_POSID = 'REDUX-posid';
export const REDUX_WAREHOUSEID = 'REDUX-warehouseid';
export const REDUX_WAREHOUSE = 'REDUX-warehouse';
export const REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK =
  'REDUX-fetch-only-products-in-stock';
export const REDUX_ALL_WAREHOUSES = 'REDUX-all-warehouses';
export const REDUX_CUSTOMER_REGISTRY_URL = 'REDUX-customer-reg-url';
export const REDUX_CUSTOMER_REGISTRY_TOKEN = 'REDUX-customer-reg-token';
export const REDUX_APP_NAME = 'pos-wizard';
export const REDUX_POS = 'REDUX-pos';
export const REDUX_USER = 'REDUX-user';
export const REDUX_EMPLOYEE = 'REDUX-employee';
export const REDUX_SETTINGS = 'REDUX-settings';
export const REDUX_JWT = 'REDUX-jwt';
export const REDUX_INSTALLER_CONFIG = 'REDUX-installer-config';
export const REDUX_SERVICE_ENDPOINTS = 'REDUX-service-endpoints';
export const REDUX_INSTALLER_MS_ENDPOINTS = 'REDUX-installer-ms-endpoints';
export const REDUX_SOFTLOGOUT = 'REDUX-softlogout';
export const VIEW_MODE = 'viewMode';
export const OPEN_DAY_INFO = 'OPEN_DAY_INFO';
