import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';

import { defaultCustomerCreationFieldsDisplay } from './constants';
import { Configuration, CustomerCreationField } from './types';

import { getFieldsToShowConfig } from '.';

type CustomerCreationFormProps = {
  currentFields: Configuration['fields'];
  save: (nc: Configuration['fields']) => void;
};

export const CustomerCreationForm: FC<CustomerCreationFormProps> = ({
  currentFields,
  save,
}) => {
  const { t } = useTranslation('customerDisplay');
  const existingConf = useSelector(getFieldsToShowConfig);

  const [customerForm, setCustomerForm] = useState(currentFields);

  const handleFormChange = (field: CustomerCreationField) =>
    setCustomerForm({
      ...customerForm,
      [field.name]: !customerForm?.[field.name] || false,
    });

  return (
    <>
      <h4>{t('titles.customerCreationForm')}</h4>
      {Object.entries(defaultCustomerCreationFieldsDisplay).map(
        ([key, value]) => {
          return (
            <InputField
              key={key}
              value={customerForm[key] ?? false}
              type="checkbox"
              onChange={() => handleFormChange(value)}
            >
              {t(`options.${key}`)}
            </InputField>
          );
        },
      )}
      <UIButton
        text="Save customer form"
        action={() => save(customerForm)}
        disabled={R.equals(customerForm, existingConf)}
      />
      <hr />
    </>
  );
};
