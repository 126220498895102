import React, { useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import {
  getIssuedCoupons,
  saveIssuedCoupon,
  verifyCoupon,
} from 'services/ErplyAPI/campaigns';
import { PosPlugin } from 'plugins/plugin';
import { addWarning, addError, addSuccess } from 'actions/Error';
import { useConfirmation } from 'components/Confirmation';
import { ErplyApiError } from 'services/ErplyAPI/core/apiErrors';

import { getCoupons } from '../rdx/reducers';
import { setCoupons } from '../rdx/actions';

import IssuedCoupons from './IssuedCoupons';

const UICoupons: PosPlugin['UICoupons'] = ({ children, id }) => {
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const coupons = useSelector(getCoupons);

  const isKeyBoardEvent = (
    event: React.KeyboardEvent | React.MouseEvent,
  ): event is React.KeyboardEvent =>
    (event as React.KeyboardEvent).key !== undefined;

  const findAndApplyCoupon = async (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (!id) return;

    if (isKeyBoardEvent(event) && event.key !== 'Enter') return;

    if (coupons.find(coup => coup.uniqueIdentifier === id)) {
      dispatch(addWarning('Coupon already added '));
      return;
    }

    try {
      const [verifiedCoupon] = await verifyCoupon(id);
      const [coupon] = await getIssuedCoupons({
        uniqueIdentifier: verifiedCoupon.uniqueIdentifier,
      });
      dispatch(setCoupons([...coupons, coupon]));
      dispatch(addSuccess(`Added coupon ${coupon.uniqueIdentifier}`));
    } catch (err) {
      if (err instanceof ErplyApiError) {
        const isExpiration = err.errorCode === 1045;

        if (!isExpiration) {
          dispatch(addError(err.message, { selfDismiss: true }));
          return;
        }

        // means that coupon is expired
        const hasConfirmed = await confirm({
          title: 'Coupon has expired',
          body: 'Do you want to activate this coupon?',
          confirmText: 'Activate',
        });

        if (!hasConfirmed) return;

        const [{ issuedCouponID }] = await getIssuedCoupons({
          uniqueIdentifier: id,
        });

        // update coupon expiry date for today, so it can be used
        await saveIssuedCoupon({
          issuedCouponID,
          expiryDate: format(new Date(), 'yyyy-MM-dd'),
        });

        const [verifiedCoupon] = await verifyCoupon(id);

        const [coupon] = await getIssuedCoupons({
          uniqueIdentifier: verifiedCoupon.uniqueIdentifier,
        });
        dispatch(setCoupons([...coupons, coupon]));
        dispatch(addSuccess(`Added coupon ${coupon.uniqueIdentifier}`));
      }
    }
  };

  return R.pipe(
    React.Children.toArray,
    R.map(R.assocPath(['props', 'title'], 'Check MF Voucher')),
    R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.map(
          R.ifElse(
            R.propEq('key', 'coupons_text_field'),
            R.pipe(
              R.assocPath(['props', 'onKeyDown'], findAndApplyCoupon),
              R.assocPath(
                ['props', 'InputProps', 'endAdornment', 'props', 'onClick'],
                findAndApplyCoupon,
              ),
            ),
            R.insert(1, <IssuedCoupons id={id} />),
          ),
        ),
      ),
    ),
  )(children);
};

export default UICoupons;
