import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import { getLastOrder, getSelectedOrder } from 'reducers/ShoppingCart';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';

import { ProductActions, ProductForm } from './components';

const ProductOrderView: FC<{ isMatrixProduct: boolean }> = ({
  isMatrixProduct = false,
}) => {
  const dispatch = useDispatch();

  const selectedOrder = useSelector(getSelectedOrder);
  const fallbackOrder = useSelector(getLastOrder);
  const order = selectedOrder || fallbackOrder;

  useEffect(() => {
    if (!order) {
      dispatch(closeModalPage('PRODUCT_VIEW'));
    }
  }, [order]);

  return (
    <>
      <ProductActions isMatrixProduct={isMatrixProduct} />
      <Modal.Body>{!!order && <ProductForm />}</Modal.Body>
    </>
  );
};

export default ProductOrderView;
