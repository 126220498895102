/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { Box, MenuItem, TextField, Typography } from '@material-ui/core';
import { useAsync } from 'react-use';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { addError, addWarning } from 'actions/Error';
import { syncLocalDatabaseSO } from 'actions/connectivity';
import {
  doClientRequest,
  requestCompleteResponse,
} from 'services/ErplyAPI/core/ErplyAPI';
import {
  getClientCode,
  getLoggedInEmployeeID,
  getUserRights,
} from 'reducers/Login';
import { SO } from 'services/DB/types';
import updateRecordsToLocalDB from 'services/DB/updateRecordsToLocalDB';
import { editEmployee } from 'actions/Login';
import { Employee } from 'types/Employee';
import { getUserGroups } from 'services/ErplyAPI/api';
import { addItemsToCache } from 'actions/cachedItems';
import { ErplyApiUser } from 'types/User';

type Props = {
  onClose: () => void;
  actionToPerform: 'add' | 'edit';
  children?: never;
  employeeData: Employee | null;
};

const updateLoggedInEmployee = (
  password: string,
  clientCode: string,
) => async dispatch => {
  try {
    const [user] = await doClientRequest<ErplyApiUser>({
      request: 'getSessionKeyUser',
    });

    dispatch(
      editEmployee({
        user,
        username: user.userName,
        clientCode,
        password,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      dispatch(addError(error.message));
    }
    console.error('Failed to load logged-in employee', error);
  }
};

const Employees: React.FC<Props> = ({
  actionToPerform,
  employeeData,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { value: userGroups = [] } = useAsync(async () => {
    try {
      const response = await getUserGroups();
      return response.map(group => ({
        name: group.name,
        value: group.userGroupID,
      }));
    } catch (error) {
      dispatch(
        addWarning(
          error instanceof Error
            ? error.message
            : 'There was an error please try again later...',
          {
            selfDismiss: true,
            dismissible: false,
          },
        ),
      );
      return [];
    }
  }, []);

  const [firstName, updateFirstName] = useState(employeeData?.firstName ?? '');
  const [lastName, updateLastName] = useState(employeeData?.lastName ?? '');
  const [email, updateEmail] = useState(employeeData?.email ?? '');
  const [selectedUserGroup, updateSelectedUserGroup] = useState(
    employeeData?.userGroupID ?? -1,
  );
  const [PIN, updatePIN] = useState('');
  const [password, updatePassword] = useState('');
  const { t } = useTranslation('editEmployee');

  const clientCode = useSelector(getClientCode);

  const rightsOverEmployees = useSelector(getUserRights).modules.employees;
  const loggedInEmployeeID = useSelector(getLoggedInEmployeeID);

  const isEditingWithoutRights =
    actionToPerform === 'edit' && rightsOverEmployees.edit === 0;

  useEffect(() => {
    if (
      actionToPerform === 'add' &&
      selectedUserGroup === -1 &&
      userGroups?.[0]?.value
    ) {
      updateSelectedUserGroup(userGroups[0].value);
    }
  }, [userGroups]);

  const handleSubmit = async () => {
    const params = R.when(
      () => actionToPerform === 'edit' && employeeData?.employeeID,
      R.assoc('employeeID', employeeData?.employeeID),
    )({
      request: 'saveEmployee',
      firstName,
      lastName,
      email,
    });

    const defineSaveUserParams = (newEmployeeID: string) => {
      const params = R.ifElse(
        () => actionToPerform === 'add',
        R.pipe(
          R.assoc('employeeID', newEmployeeID),
          R.assoc('userGroupID', selectedUserGroup),
          R.assoc('name', email),
        ),
        R.pipe(
          R.assoc('employeeID', employeeData?.employeeID),
          R.assoc('userGroupID', employeeData?.userGroupID),
          R.assoc('userID', employeeData?.userID),
        ),
      )({ request: 'saveUser' });

      if (password !== '' && PIN !== '') {
        return { ...params, password, pin: PIN };
      }
      if (PIN !== '' && password === '') {
        return { ...params, pin: PIN };
      }
      if (password !== '' && PIN === '') return { ...params, password };
      return params;
    };

    try {
      const response = await requestCompleteResponse<{ employeeID: string }>(
        params,
      );
      await requestCompleteResponse(
        defineSaveUserParams(response.records[0].employeeID),
      );
      if (employeeData?.employeeID === loggedInEmployeeID) {
        dispatch(syncLocalDatabaseSO(SO.EMPLOYEES.NAME));
        dispatch(updateLoggedInEmployee(password, clientCode));
      } else {
        updateRecordsToLocalDB(clientCode, SO.EMPLOYEES.NAME, {
          updateCache: newEmployee => {
            dispatch(addItemsToCache(SO.EMPLOYEES.NAME, newEmployee));
          },
        })
          .catch(e => console.error('Failed to update employee cache', e))
          .then(onClose);
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(addError(error.message));
      }
      console.error('Failed to save edits to employee', error);
    }
  };

  const invalidLastName = lastName.length < 1;
  const invalidEmail =
    email.length === 0 ||
    (email.length >= 1 &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      ));
  const invalidPin = 1 > PIN.length && 6 < PIN.length;
  const invalidPassword = 1 > password.length && 8 < password.length;

  const getEmailHelperText = () => {
    if (email.length === 0) return t('errorTexts.falseEmail');
    if (invalidEmail) return t('errorTexts.falseEmail');
    return '';
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1.5rem"
      >
        <Typography variant="h4">
          {t('title', { context: actionToPerform })}
        </Typography>
        <Box display="flex">
          <SaveButton
            title={t('saveButton')}
            color="secondary"
            action={handleSubmit}
            disabled={
              invalidEmail ||
              invalidLastName ||
              invalidPin ||
              invalidPassword ||
              isEditingWithoutRights
            }
          />
          <CloseButton action={onClose} />
        </Box>
      </Box>
      <Box
        display="flex"
        gridGap="1rem"
        flexDirection="column"
        padding="0 1.5rem"
      >
        <TextField
          label={t('fields.firstname')}
          value={firstName}
          variant="outlined"
          fullWidth
          onChange={event => updateFirstName(event.target.value)}
        />
        <TextField
          label={t('fields.lastname')}
          value={lastName}
          variant="outlined"
          fullWidth
          error={invalidLastName}
          helperText={invalidLastName && t('errorTexts.lastname')}
          onChange={event => updateLastName(event.target.value)}
        />
        <TextField
          label={t('fields.email')}
          value={email}
          fullWidth
          variant="outlined"
          onChange={event => updateEmail(event.target.value)}
          error={invalidEmail}
          helperText={getEmailHelperText()}
        />
        <TextField
          label={t('fields.group')}
          select
          fullWidth
          variant="outlined"
          value={selectedUserGroup}
          onChange={event => updateSelectedUserGroup(event.target.value)}
        >
          {userGroups.map(({ name, value }) => (
            <MenuItem key={name} value={value}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t('fields.pin')}
          type="password"
          fullWidth
          error={invalidPin}
          helperText={invalidPin && t('errorTexts.pin')}
          variant="outlined"
          value={PIN}
          onChange={event => updatePIN(event.target.value)}
        />
        <TextField
          label={t('fields.password')}
          type="password"
          error={invalidPassword}
          helperText={invalidPassword && t('errorTexts.password')}
          value={password}
          fullWidth
          variant="outlined"
          onChange={event => updatePassword(event.target.value)}
        />
      </Box>
    </>
  );
};

export default Employees;
