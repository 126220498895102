import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getOpenedOrderIndex,
  getProductInOrderByIndex,
  getVisibleProductDiscounts,
} from 'reducers/ShoppingCart';
import { useBreakpoints } from 'utils/hooks/UI';
import { removeProduct } from 'actions/ShoppingCart';
import { setSelectedOrder } from 'actions/ShoppingCart/setSelectedOrder';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as modals } from 'constants/modalPage';
import {
  getCurrencyFormatter,
  getSetting,
  getShowPricesWithQuantity,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import Icon from 'components/Icon';
import { round } from 'utils';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { PluginComponent } from 'plugins';
import { Order } from 'types/ShoppingCart';

import RowName from './components/RowName';
import Quantity from './components/RowQuantity';

import './tableBill.scss';
import { addWarning } from 'actions/Error';

type Props = {
  depthLevel?: number;
  isEven: boolean;
  product: Order;
  children?: never;
};

const TableBillRow: React.FC<Props> = ({ depthLevel = 0, isEven, product }) => {
  const { t } = useTranslation('shoppingCart');
  const { orderIndex } = product;
  const dispatch = useDispatch();
  const openedOrderIndex = useSelector(getOpenedOrderIndex);
  const isMobileView = !useBreakpoints().mdplus;
  const gridDisplay = useSelector(getGridIsOpened);
  const order = useSelector(getProductInOrderByIndex(orderIndex));

  const isWithTax = useSelector(getShowPricesWithTax);
  const isWithQty = useSelector(getShowPricesWithQuantity);

  const format = useSelector(getCurrencyFormatter);
  const appliedDiscounts = useSelector(
    getVisibleProductDiscounts(order.rowNumber),
  );

  const isFreeTextProduct = Number(product.productID) === 0;

  const orderPrice = useMemo(() => {
    const netPrice = isWithQty ? order.rowNetTotal : order.finalPrice;
    const vatPrice = isWithQty ? order.rowTotal : order.finalPriceWithVAT;
    const price = isWithTax ? vatPrice : netPrice;
    return <span data-testid="order-price">{format(price)}</span>;
  }, [order, isWithTax, isWithQty, format]);

  const discountPrice = useCallback(
    discount => {
      const price = discount.value / (isWithQty ? 1 : discount.affected);
      return (
        <span
          data-testid="discount-value"
          data-test-key={`product-discount-value-${orderIndex}`}
          key={`${discount.type}${discount.campaignId ?? discount.name}`}
          className="adjustment"
        >
          {format(price, { roundDown: true })}
        </span>
      );
    },
    [isWithQty, orderIndex, format],
  );

  const handleModal = useCallback(() => {
    if (isFreeTextProduct)
      return dispatch(addWarning(t('alerts.cannotEditFreeTextItem')));
    dispatch(setSelectedOrder(orderIndex));
    dispatch(
      openModalPage({
        component: modals.ProductOrderView,
        groupID: 'PRODUCT_VIEW',
        modalClassName: 'product-order-modal',
        replace: true,
      }),
    );
  }, [orderIndex, isFreeTextProduct]);

  const memoizedRow = useMemo(() => {
    return product?.children?.length
      ? product.children.map(ch => (
          <TableBillRow
            data-test-key={ch.code}
            data-testid="table-bill-row"
            key={ch.orderIndex}
            depthLevel={depthLevel + 1}
            isEven={isEven}
            product={ch}
          />
        ))
      : null;
  }, [product.children]);

  const shouldGroupProducts = useSelector(
    getSetting('touchpos_group_products_in_shopping_cart'),
  );

  const editAction = useCallback(
    e => {
      e.stopPropagation();
      dispatch(setSelectedOrder(orderIndex));
      dispatch(
        openModalPage({
          component: modals.ProductOrderEdit,
          groupID: 'PRODUCT_VIEW',
        }),
      );
      e.stopPropagation();
    },
    [orderIndex],
  );

  const discountAction = useCallback(
    e => {
      e.stopPropagation();
      handleModal();
    },
    [handleModal],
  );

  const deleteAction = useCallback(
    e => {
      dispatch(removeProduct(orderIndex));
      e.stopPropagation();
    },
    [orderIndex],
  );

  const selected = openedOrderIndex === orderIndex;
  return (
    <>
      <PluginComponent
        hookname="UICustomTableBillRow"
        props={{ order, depthLevel, isEven, shouldGroupProducts }}
      >
        <tr
          style={{
            cursor: 'pointer',
          }}
          className={classNames({
            active: selected,
            computed: order.computed,
            grouped: shouldGroupProducts,
            isEven: shouldGroupProducts && isEven,
          })}
          onClick={e => {
            e.stopPropagation();
            handleModal();
          }}
          data-testid="product-row"
          data-test-key={`product-bill-${order.orderIndex}`}
        >
          {/* Column 1 : Product name */}
          {/* TODO: promotion name length should expand to both first cells, not just on. mb wix that with css later. */}
          <td
            style={{ paddingLeft: depthLevel ? `${depthLevel * 16}px` : '4px' }}
            data-testid="product-name-cell"
            data-test-key={`product-name-cell-${order.orderIndex}`}
          >
            <RowName depthLevel={depthLevel} orderIndex={orderIndex} />
          </td>

          {/* Column 2 : Product code */}
          <td
            data-testid="product-code-cell"
            data-test-key={`product-code-cell-${order.orderIndex}`}
          >
            <span
              data-testid="product-code"
              data-test-key={`product-code-${order.orderIndex}`}
            >
              {order.code}
            </span>
          </td>

          {/* Column 3 : Product amount */}
          <PluginComponent
            data-testid="ui-product-amount-in-cart"
            hookname="UIProductAmountInShoppingCart"
            props={order}
          >
            <td
              data-testid="amount"
              data-test-key={`product-amount-${order.orderIndex}`}
              className="amount"
            >
              <Quantity orderIndex={orderIndex} />
            </td>
          </PluginComponent>

          {/* Column 4 : Final price of this product, calculated, singular */}
          <td>
            {orderPrice}
            {appliedDiscounts.length > 0
              ? appliedDiscounts.map(discountPrice)
              : null}
          </td>

          {/* Column 5 : Total discount percentage for this product in order, calculated */}
          {!isMobileView && !gridDisplay ? (
            <td
              data-testid="total-discount"
              data-test-key={`total-discount-${order.orderIndex}`}
            >
              {order.totalDiscount
                ? round(order.totalDiscount, 0)
                : round(0, 2)}
            </td>
          ) : null}

          {/* Column 6 : Total discount amount for this product in order, calculated */}
          {/* TODO: logic returns wrong value, fix that sometime soon */}
          {!isMobileView && !gridDisplay ? (
            <td
              data-testid="total-discount-amount"
              data-test-key={`total-discount-amount-${order.orderIndex}`}
            >
              {order.totalDiscount &&
                order.basePriceWithVAT &&
                format(
                  order.amount
                    ? ((order.basePriceWithVAT * order.totalDiscount) / 100) *
                        order.amount
                    : 0,
                )}
            </td>
          ) : null}

          {/* Column 7 : Tax total amount for this product in order, calculated */}
          {!isMobileView && !gridDisplay ? (
            <td
              data-testid="row-vat-total"
              data-test-key={`row-vat-total-${order.orderIndex}`}
            >
              {order.rowVATTotal ? round(order.rowVATTotal, 2) : round(0, 2)}
            </td>
          ) : null}

          {/* Column 8 : Discount shortcut */}
          {!isMobileView && !isFreeTextProduct && (
            <td className="icon" data-testid="discount-icon">
              {order.computed ? null : (
                <PluginComponent
                  hookname="UICartDiscountShortcut"
                  data-testid="ui-cart-discount-shortcut"
                  props={{ order, isMobileView }}
                >
                  <Icon name="erply_discount_color" action={discountAction} />
                </PluginComponent>
              )}
            </td>
          )}

          {/* Column 9 : Product edit shortcut */}
          {!isMobileView && !isFreeTextProduct && (
            <td className="icon" data-testid="edit-icon">
              <PluginComponent
                hookname="UICartProductEditShortcut"
                data-testid="ui-cart-product-edit-shortcut"
                props={{ order, isMobileView }}
              >
                {order.computed ? null : (
                  <Icon name="erply_edit_color" action={editAction} />
                )}
              </PluginComponent>
            </td>
          )}
          {/* Column 9 : Product delete shortcut */}
          <td className="icon" data-testid="delete-icon">
            <PluginComponent
              hookname="UIDeleteProductShortcut"
              data-testid="ui-delete-product-shortcut"
              props={{ order, isMobileView }}
            >
              {order.computed ? null : (
                <Icon
                  className={isMobileView ? 'ml-2 float-right' : ''}
                  name="erply_delete_color"
                  action={deleteAction}
                />
              )}
            </PluginComponent>
          </td>
        </tr>
      </PluginComponent>
      {!!product?.children?.length && memoizedRow}
    </>
  );
};

export default TableBillRow;
