import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';

import InputFieldBase from 'components/FieldTypes/InputField';
import { loginInput } from 'components/FieldTypes/skins/skins.module.scss';
import { useLocalStorage } from 'utils/hooks/statelike';
import { REDUX_PIN, REDUX_SESSIONKEY } from 'constants/persistence';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { attemptPinLogin } from 'actions/Login';
import { version } from 'external_data';
import { getIsOfflineLocked } from 'reducers/Login';
import { getConnectionHealth } from 'reducers/connectivity/connection';

import { styles } from '../util';

const InputField = props => (
  <InputFieldBase size="lg" className={loginInput} {...props} />
);

const PinLoginView = ({ onCancel, onSignup }) => {
  const [pin, setPin] = useLocalStorage(REDUX_PIN);

  const dispatch = useDispatch();
  const pinLogin = () => dispatch(attemptPinLogin(pin));

  const offlineLogin = useSelector(
    createSelector(
      getIsOfflineLocked,
      getConnectionHealth,
      (locked, health) => locked && !health,
    ),
  );

  useShortcut('Enter', pinLogin);

  // If sessionKey disappears (f.ex. was just detected to be expired and removed), kick back to login screen
  useEffect(() => {
    const i = setInterval(() => {
      if (!localStorage.getItem(REDUX_SESSIONKEY)) {
        onCancel();
      }
    }, 100);
    return () => clearInterval(i);
  }, []);

  const { t } = useTranslation('login');
  return (
    <Card style={styles.card}>
      <Card.Header style={styles.header}>
        {t('pinlogin.title')}
        <span style={{ flexGrow: 1 }} />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span
          onClick={onCancel}
          className="Clickable-icon icon_menu" // icon_grid-3x3 for login
          style={{
            fontSize: '40px',
            // transform: "scale(0.7, 0.7)" for login
          }}
        />
      </Card.Header>
      <Card.Body className="p-sm-4 p-0 pb-4">
        <InputField
          autoFocus={true}
          name="pin"
          onChange={e => setPin(e.target.value)}
          title={
            offlineLogin ? t('offlineLogin:login.fields.pin') : t('inputs.pin')
          }
          type="password"
          value={pin}
        />
        <Container>
          <Row className="dark-row">
            <Col sm={6} xs={12}>
              <Button
                onClick={pinLogin}
                style={{ flexGrow: 1, ...styles.button }}
                size="lg"
                variant="secondary"
              >
                {t('login.title')}
              </Button>
            </Col>
            <Col sm={6} xs={12}>
              <Button
                onClick={onSignup}
                style={{ flexGrow: 1, ...styles.button }}
                size="lg"
                variant="primary"
              >
                {t('signup.title')}
              </Button>
            </Col>
          </Row>
        </Container>
        <span style={styles.version}>{version}</span>
      </Card.Body>
    </Card>
  );
};

PinLoginView.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSignup: PropTypes.func.isRequired,
};

export default PinLoginView;
