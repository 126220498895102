import React from 'react';
import { useSelector } from 'react-redux';
import RandExp from 'randexp';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { addError } from 'actions/Error';
import InputField from 'components/FieldTypes/InputField';
import { getProductByID } from 'reducers/cachedItems/products';
import { add, ErplyAttributes } from 'utils';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { integer } from 'components/FieldTypes/formatters';
import { getSelectedWarehouse } from 'reducers/warehouses';
import Loader from 'components/Loader';
import { getGiftcardTypes } from 'reducers/giftcards';

import { printCreditSlip } from './actionPrintCreditSlip';
import { getIsAReturn } from 'reducers/sales';
import { getGiftCards } from 'services/ErplyAPI';

const pluginID = 'pnpCreditSlip' as const;

type Configuration = {
  creditSlipTypeID: number;
};

// region DEPENDENCY: Environmental and CA fees
const getPnpTaxesConfig = state => {
  return (
    getPluginConfiguration<{
      envFeeName: string;
      admissionTaxName: string;
      admissionFeeName: string;
      code5names: string;
    }>('environmental-&-CA-fees')(state) ?? {}
  );
};
const getIsPnpFakeTax = (productID: string) => state => {
  const productName = getProductByID(productID)(state).name;
  const { envFeeName, admissionTaxName, admissionFeeName } = getPnpTaxesConfig(
    state,
  );
  const names = [envFeeName, admissionTaxName, admissionFeeName];
  return names.map(s => s.toUpperCase()).includes(productName.toUpperCase());
};
const getIsEnvFee = (productID: string) => state => {
  const { name } = getProductByID(productID)(state);
  const { envFeeName } = getPnpTaxesConfig(state);
  if (envFeeName === undefined) return false;
  return name.toUpperCase() === envFeeName.toUpperCase();
};
const getAppliesForEnvFee = (productID: string) => state => {
  const { code5 } = getProductByID(productID)(state);
  const { code5names } = getPnpTaxesConfig(state);
  // Env fee itself does not apply to itself
  if (getIsEnvFee(productID)(state)) return false;
  // Otherwise, it needs to have a listed code5
  return (code5names?.split(',') ?? []).map(s => s.trim()).includes(code5);
};
// endregion

const getShouldReturnToCreditSlip = (id: string) => state => {
  const product = getProductByID(id)(state);
  const returnTender = new ErplyAttributes(product.attributes)?.get(
    'return_tender',
  );

  return returnTender?.toUpperCase() !== 'CASH';
};
const generateCreditSlipSerial = state => {
  const warehouseCode = getSelectedWarehouse(state).code;
  const prefix = `000${warehouseCode}`.slice(-3);
  const uuid = new RandExp(/[A-Z][0-9A-Z]{7}/).gen();
  return `${prefix}${uuid}`;
};

const fairlyRoundArray = (array: number[], round: (number) => number) => {
  let err = 0;
  return array.map(val => {
    const target = val + err;
    const value = round(target);
    err = target - value;
    return value;
  });
};

/**
 * Prepopulates payments with cash and credit slip
 * Prints out a custom receipt for the credit slip on saveSalesDocument
 */
const creditSlipPlugin: PosPlugin = {
  id: pluginID,
  name: '[PNP] Credit slip',
  keywords: ['pnp'],
  // language=Markdown
  info: `
On returns, automatically generates cash and credit slip tenders to cover the entire sale
Generates credit slip tenders by default, except if any of the products have a return_tender attribute !== 'GIFTCARD', in which case those products contribute to a cash tender intead
After payment, prints out a receipt for each credit slip that was on the return
`,
  getStatus: state => {
    const conf = getPluginConfiguration<Configuration>(pluginID)(state);
    if (!conf?.creditSlipTypeID) {
      return {
        type: 'error',
        message: 'Credit slip type not selected',
      };
    }
    return {
      type: 'valid',
      message: '',
    };
  },
  ComponentConfigurationByLevel: {
    Company: ({ current: { creditSlipTypeID: id } = {}, save }) => {
      const options = useSelector(getGiftcardTypes);
      return (
        <Loader
          show={options === null}
          loadingText="Loading available gift card types"
        >
          <br />
          <InputField
            type="select"
            options={options?.map(gc => ({ name: gc.names.EN, value: gc.id }))}
            size="lg"
            title="Gift card type"
            formatter={integer}
            onChange={e => save({ creditSlipTypeID: e.target.value })}
            value={id}
          />
        </Loader>
      );
    },
  },
  combineConfiguration: company => company,
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      if (p.props.creditSlipOverride) {
        return ap;
      }
      const conf = getPluginConfiguration<Configuration>(pluginID)(getState());
      if (!conf?.creditSlipTypeID) {
        dispatch(
          addError(
            `MISCONFIGURATION: Credit slip type ID not configured in plugin ${pluginID}`,
          ),
        );
        throw new Error('Credit slip ID not configured');
      }
      const state = getState();
      const cart = getProductsInShoppingCart(state);
      const isReturn = getIsAReturn(state);

      if (!isReturn) return ap;

      // Split into env fee, products it applies to, and others
      const cartEnvFees = cart.filter(p => getIsEnvFee(p.productID)(state));
      const cartEnvFeeAppliesTo = cart
        .filter(p => !cartEnvFees.includes(p))
        .filter(p => getAppliesForEnvFee(p.productID)(state));
      const cartOthers = cart
        .filter(p => !cartEnvFees.includes(p))
        .filter(p => !cartEnvFeeAppliesTo.includes(p));

      // Calculate the effective env fee rate
      const totalEnvFee = cartEnvFees.map(p => p.rowTotal).reduce(add, 0);
      const totalEnvFeeProducts = cartEnvFeeAppliesTo
        .map(p => p.rowTotal)
        .reduce(add, 0);
      const effectiveEnvFeeRate = totalEnvFee / totalEnvFeeProducts;

      // Group into totals by product ID
      const totalsInCart: {
        [productID: string]: { rowTotal: number; amount: number };
      } = {};

      cart.forEach(prod => {
        const { rowTotal = 0, amount = 0 } = totalsInCart[prod.productID] ?? {};
        totalsInCart[prod.productID] = {
          rowTotal: rowTotal + prod.rowTotal,
          amount: amount + prod.amount,
        };
      });

      /*
       * Env fee total needs to be distributed based on the products it applies to
       * So the following shopping cart:
       *         Net total | tax   | tender | isEnvFeeProduct
       *              0.75 | 0.00  | cash   | Env fee product
       *              5.00 | 0.50  | cash   | Env fee applicable
       *             10.00 | 1.00  | slip   | Env fee applicable
       *              1.00 | 0.10  | slip   | Not Env fee applicable
       *
       * would be interpreted as
       * envFeeRate = 0.75 / (5.00+10.00) = 5%
       * product.envFee = product.rowTotal * envFeeRate
       *         Net total  | tax   | Env fee | tender
       *               5.00 | 0.50  |    0.25 | cash
       *              10.00 | 1.00  |    0.50 | slip
       *               1.00 | 0.10  |    0.00 | slip
       *
       * And thus tenders would be generated as
       * Cash: cash totals (5.50) + env fee for cash products (0.25) = 5.75
       * Slip: slip totals (12.10) + env fee for slip products (0.50) = 12.60
       * */
      let giftcardSum = 0;
      let cashSum = 0;
      Object.entries(totalsInCart).forEach(
        ([productID, { rowTotal, amount }]) => {
          if (amount >= 0) return; // Based on original code
          if (getIsEnvFee(productID)(state)) return; // Skip fee itself
          let total = rowTotal;
          if (getAppliesForEnvFee(productID)(state)) {
            total += rowTotal * effectiveEnvFeeRate;
          }
          if (getShouldReturnToCreditSlip(productID)(state)) {
            giftcardSum += total;
          } else {
            cashSum += total;
          }
        },
      );

      let [roundedGiftcard, roundedCash] = fairlyRoundArray(
        [giftcardSum, cashSum],
        a => Math.round(a * 100) / 100,
      );

      if (Math.abs(roundedCash) <= 0.01) {
        roundedGiftcard += roundedCash;
        roundedCash -= roundedCash;
      }

      const retAP = {
        ...ap,
        payments: Object.fromEntries(
          [
            0.01 <= Math.abs(Number(roundedGiftcard)) && [
              'pnp-credit-slip',
              {
                type: 'GIFTCARD',
                amount: roundedGiftcard.toFixed(2),
                giftCardTypeID: conf.creditSlipTypeID,
                caption: 'Credit slip',
                serial: generateCreditSlipSerial(getState()),
                locked: true,
                currencyRate: ap.payments.cash?.currencyRate ?? 1,
              },
            ],
            0.01 <= Math.abs(Number(roundedCash)) && [
              'cash',
              {
                type: 'CASH',
                amount: roundedCash.toFixed(2),
                caption: 'Cash',
                locked: true,
                currencyRate: ap.payments.cash?.currencyRate ?? 1,
              },
            ],
          ].flatMap(a => (a ? [a] : [])),
        ),
      };

      return retAP;
    },
  },
  onSaveSalesDocument: {
    after: (p, { requests, responses, salesDocument }) => async (
      dispatch,
      getState,
    ) => {
      const ids = responses.requests
        .filter(req => req.status.requestName === 'saveGiftCard')
        .map(req => req.records[0].giftCardID);

      await Promise.all(
        ids.map(id =>
          getGiftCards({
            giftCardID: id,
          }).then(
            ([
              {
                code,
                balance,
                purchaseDateTime,
                redemptionDateTime,
                purchaseInvoiceID,
              },
            ]) =>
              dispatch(
                printCreditSlip({
                  invoiceID: purchaseInvoiceID,
                  code,
                  balance,
                  date: redemptionDateTime || purchaseDateTime,
                  redeem: !!redemptionDateTime,
                }),
              ),
          ),
        ),
      );
    },
  },
};
export default creditSlipPlugin;
