/* eslint-disable no-console */

import LocalDatabase from './core';

export default class LastReceiptData extends LocalDatabase {
  constructor() {
    super({ TABLE_NAME: 'lastReceiptData' });
  }

  async set({ payments = null, salesDoc = null }) {
    try {
      const { payments: oldPayments = {}, salesDoc: oldSalesDoc = {} } =
        this.localData[this.CLIENT_CODE]?.[this.storeKey] || {};

      const store = {
        payments: payments || oldPayments,
        salesDoc: salesDoc || oldSalesDoc,
      };
      await this.saveItem({ value: store });
    } catch (e) {
      console.error(
        'Failed to update last receipt data',
        { payments, salesDoc },
        e,
      );
    }
    return null;
  }

  async reset() {
    try {
      await this.saveItem({ value: {} });
    } catch (e) {
      console.error('Failed to reset last receipt data', e);
    }
    return null;
  }

  get() {
    try {
      return this.localData[this.CLIENT_CODE]?.[this.storeKey] || {};
    } catch (e) {
      console.error('Failed to read last receipt data', e);
    }
    return null;
  }
}
