import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useSize } from 'utils/hooks/UI';
import { useMeasurement } from 'components/List';
import { getShouldGridUpdate } from 'reducers/UI/gridDisplay';
import { toggleShouldGridUpdate } from 'actions/UI';
import useGridButtonSize from 'components/GridButton/useGridButtonSize';
import { GridButton } from 'components/GridButton';

export const useMeasureListHeight = () => {
  const { width, height } = useGridButtonSize();
  const { height: windowHeight } = useSize();
  const headerHeight = 48;
  const paddingTop = 15;
  const largeButtonHeight = 103;
  const heightAvailable =
    windowHeight - headerHeight - paddingTop - largeButtonHeight;

  const { needsToMeasure, Measure, height: totalLines } = useMeasurement(
    {
      targetHeight: heightAvailable,
      component: GridButton,
    },
    [width, height],
  );

  if (needsToMeasure) {
    return { needsToMeasure: true, Measure };
  }
  return { needsToMeasure: false, totalLines };
};

export const useRefreshGrid = (refresh: () => void) => {
  const dispatch = useDispatch();
  const shouldRefresh = useSelector(getShouldGridUpdate);
  useEffect(() => {
    if (shouldRefresh) {
      refresh();
      dispatch(toggleShouldGridUpdate(false));
    }
  }, [dispatch, refresh, shouldRefresh]);
};
