/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as R from 'ramda';

import {
  addMultiProducts,
  setDiscountReason,
  updateProductOrder,
} from 'actions/ShoppingCart';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { addError } from 'actions/Error';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getProductByID } from 'reducers/cachedItems/products';
import { REASONS } from 'constants/reasonCodesDB';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { attributes, components } from 'plugins/pnp/carSales/constants';
import { getRequirementsForProductAction } from 'plugins/pnp/carSales/selectors';
import { openPluginModalPage } from 'actions/modalPage';
import { ErplyAttributes } from 'utils';
import { createConfirmation } from 'actions/Confirmation';

import reduxReducer, {
  getExchangeState,
  SET_EXCHANGE_PROCESS_STEP,
  SET_ORIGINAL_SALES_DOCUMENT,
  SET_PRODUCTS_FOR_EXCHANGE,
} from './rdx';
import {
  ComponentConfiguration,
  UICartDiscountShortcut,
  UICartProductEditShortcut,
  UIProductReturnCloseButton,
  UICustomPayButton,
  UICustomYellowCTA,
  UIDeleteProductShortcut,
  UIDiscountButtons,
  UIExchangeModalTitle,
  UIProductAmountInProductOrderEdit,
  UIProductAmountInShoppingCart,
  UIQuantityActionsContainer,
  UIReturnOrderProduct,
  UISingleReturnRowCheckbox,
  UIReturnTableHeaders,
  UIReturnOrderProductSelectReason,
} from './components';
import processPayment from './rdx/actions';
import { ExchangeConfigurationType } from './components/ComponentConfiguration';

const ProcessExchanges: PosPlugin = {
  id: 'pnp-process-exchanges',
  name: '[PNP] - Process exchanges',
  keywords: ['pnp', 'exchange', 'return'],
  info:
    "This plugin is exclusive for PNP client and enables the exchange of products. A button will be enabled in the main layout of the POS with the text 'Exchange', the exchange process will start when clicking the button. The exchange process consists of two stages. On the first stage the workflow is the same as a normal return, the return reason 'Exhange' will be assigned to the returned products. After selecting the products to return and clicking the PAY button, a sales document will be created and the shopping cart will be populated with the same items in the same amount that were returned, this will be the beginning of the second stage. During this stage, if you press the PAY button, the sale will be performed and a sales document will be created. ",
  getStatus: state => {
    const currentConfig = getPluginConfiguration<ExchangeConfigurationType>(
      'pnp-process-exchanges',
    )(state);

    const hasExchangeReason = currentConfig?.selectedReturnReason;
    const discountReason = currentConfig?.selectedDiscountReason;
    const hasExchangePaymentType = currentConfig?.selectedPaymentType;

    if (hasExchangeReason === '') {
      return {
        type: 'error',
        message: 'Return reason not selected in exchange plugin configuration.',
      };
    }

    if (discountReason === '') {
      return {
        type: 'error',
        message:
          'Discount reason  not selected in exchange plugin configuration.',
      };
    }

    if (hasExchangePaymentType === '') {
      return {
        type: 'error',
        message:
          'Payment type for exchanges not selected in exchange plugin configuration.',
      };
    }

    return {
      type: 'valid',
      message: `Ready`,
    };
  },
  reduxReducer,
  ComponentConfiguration,
  onSaveSalesDocument: {
    on: (p, requests) => async (dispatch, getState) => {
      const state = getState();
      const productsInCart = getProductsInShoppingCart(state);
      const exchangeState = getExchangeState(state);
      const { exchangeStep } = exchangeState;

      if (exchangeStep === 1) {
        await dispatch({
          type: SET_PRODUCTS_FOR_EXCHANGE,
          payload: productsInCart,
        });
      }

      return requests;
    },
    after: (p, ep) => async (dispatch, getState) => {
      const state = getState();

      const currentConfig = getPluginConfiguration<ExchangeConfigurationType>(
        'pnp-process-exchanges',
      )(state);

      const discountReasons = getReasonCodes(REASONS.DISCOUNT)(state);
      const hasExchangeDiscountReason = discountReasons.find(
        reason =>
          reason.reasonID === Number(currentConfig?.selectedDiscountReason),
      );
      const exchangeState = getExchangeState(state);
      const { exchangeStep, itemsToExchange, originalSalesDoc } = exchangeState;

      const itemsToAddToShoppingCart: any = [];

      itemsToExchange.forEach((item: any) => {
        itemsToAddToShoppingCart.push({
          ...item,
          rowTotal: Math.abs(item.rowTotal),
          rowNetTotal: Math.abs(item.rowNetTotal),
          rowVATTotal: Math.abs(item.rowVATTotal),
          amount: Math.abs(item.amount),
          basePriceNet: Math.abs(item.basePriceNet),
          basePriceWithVAT: Math.abs(item.basePriceWithVAT),
        });
      });

      if (exchangeStep === 1) {
        await dispatch({ type: SET_EXCHANGE_PROCESS_STEP, payload: 2 });
        await dispatch(setCustomer({ data: originalSalesDoc.clientID }));
        await dispatch(addMultiProducts([...itemsToAddToShoppingCart]));

        const aProductHasDiscount = itemsToExchange.some(
          (product: any) => product.discount > 0,
        );

        if (aProductHasDiscount) {
          await dispatch(setDiscountReason({ ...hasExchangeDiscountReason }));
        }

        const promiseMap = (inputValues, mapper) => {
          let carVin;
          let carStockNo;
          let carYear;
          let carMake;
          let carModel;
          let carCylinder;
          let carCarSide;
          let carCustomer;

          const reducer = (accumulator, itemInCart) =>
            accumulator.then(async acc => {
              await dispatch(mapper(itemInCart.productID))
                .then(async result => {
                  if (
                    result.has('customer') ||
                    result.has('vin') ||
                    result.has('cylinder') ||
                    result.has('carSide')
                  ) {
                    const {
                      car,
                      cylinder,
                      carSide,
                      customer,
                    } = (await dispatch(
                      openPluginModalPage(components.requirementsPopup)({
                        isPopup: true,
                        props: {
                          current: itemInCart.attributes[attributes.cfjcData],
                          requirements: result,
                          isExchange: true,
                        },
                      }),
                    )) as any;

                    carVin = car.vin;
                    carStockNo = car.stock.stockNo;
                    carYear = car.year;
                    carMake = car.make;
                    carModel = car.model;
                    carCylinder = cylinder;
                    carCarSide = carSide;
                    carCustomer = customer;

                    let noChangesInOrigianlData = R.equals(
                      JSON.stringify(
                        [
                          carVin,
                          carStockNo,
                          carYear,
                          carMake,
                          carModel,
                          carCylinder,
                          carCarSide,
                          carCustomer,
                        ].map(a => a ?? ''),
                      ),
                      itemInCart.attributes[attributes.cfjcData],
                    );

                    while (noChangesInOrigianlData) {
                      // eslint-disable-next-line no-await-in-loop
                      await new Promise((resolve, reject) =>
                        dispatch(
                          createConfirmation(resolve, reject, {
                            title: 'Confirmation',
                            body:
                              'The car details are the same as on the item being return. Continue?',
                          }),
                        ),
                      )
                        .then(() => {
                          noChangesInOrigianlData = false;
                          return true;
                        })
                        .catch(async () => {
                          const secondAttempt = (await dispatch(
                            openPluginModalPage(components.requirementsPopup)({
                              isPopup: true,
                              props: {
                                current:
                                  itemInCart.attributes[attributes.cfjcData],
                                requirements: result,
                                isExchange: true,
                              },
                            }),
                          )) as any;

                          carVin = secondAttempt.car.vin;
                          carStockNo = secondAttempt.car.stock.stockNo;
                          carYear = secondAttempt.car.year;
                          carMake = secondAttempt.car.make;
                          carModel = secondAttempt.car.model;
                          carCylinder = secondAttempt.cylinder;
                          carCarSide = secondAttempt.carSide;
                          carCustomer = secondAttempt.customer;

                          noChangesInOrigianlData = R.equals(
                            JSON.stringify(
                              [
                                carVin,
                                carStockNo,
                                carYear,
                                carMake,
                                carModel,
                                carCylinder,
                                carCarSide,
                                carCustomer,
                              ].map(a => a ?? ''),
                            ),
                            itemInCart.attributes[attributes.cfjcData],
                          );

                          return false;
                        });
                    }

                    await dispatch(
                      updateProductOrder({
                        orderIndex: itemInCart.orderIndex,
                        attributes: {
                          ...new ErplyAttributes(itemInCart.attributes).asDict,
                          [attributes.cfjcData]: JSON.stringify(
                            [
                              car.vin,
                              car.stock.stockNo,
                              car.year,
                              car.make,
                              car.model,
                              cylinder,
                              carSide,
                              customer,
                            ].map(a => a ?? ''),
                          ),
                        },
                      }),
                    );
                  }

                  return acc.push(result) && acc;
                })
                .catch(error =>
                  console.error(
                    'ProcessExchanges plugin: Failed to recollect CFJC data',
                    error,
                  ),
                );
            });
          return inputValues.reduce(reducer, Promise.resolve([]));
        };

        await promiseMap(itemsToExchange, getRequirementsForProductAction);

        await dispatch(processPayment());
      }

      if (exchangeStep === 2) {
        dispatch({ type: SET_EXCHANGE_PROCESS_STEP, payload: 0 });
      }
    },
  },
  onAddProduct: {
    before: product => async (dispatch, getState) => {
      const state = getState();
      const currentProduct = getProductByID(product.productID)(state);

      const currentEnvConfig: any = getPluginConfiguration(
        'environmental-&-CA-fees',
      )(state);
      const exchangeState = getExchangeState(state);
      const { exchangeStep } = exchangeState;

      if (
        exchangeStep > 0 &&
        !currentProduct.name === currentEnvConfig.envFeeName
      ) {
        dispatch(addError('Unable to add new products during an exchange.'));
        throw new Error('Unable to add new products during an exchange.');
      }
    },
  },
  onAddReturnProducts: {
    on: (p, ap) => async (dispatch, getState) => {
      const state = getState();
      const currentConfig = getPluginConfiguration<ExchangeConfigurationType>(
        'pnp-process-exchanges',
      )(state);

      const { exchangeStep } = getExchangeState(state);
      let updatedOrders = ap.orders;

      if (exchangeStep > 0) {
        updatedOrders = ap.orders.map(({ vatrateID, ...o }) => ({
          ...o,
          vatrateID,
          returnReasonID: currentConfig?.selectedReturnReason,
        }));
      }

      return {
        ...ap,
        orders: updatedOrders,
      };
    },
  },
  onResetShoppingCart: {
    after: () => async (dispatch, getState) => {
      const state = getState();
      const exchangeState = getExchangeState(state);

      const { exchangeStep, itemsToExchange } = exchangeState;

      if (exchangeStep >= 1 && !itemsToExchange.length) {
        dispatch({ type: SET_EXCHANGE_PROCESS_STEP, payload: 0 });
      }

      if (exchangeStep === 2) {
        dispatch({ type: SET_ORIGINAL_SALES_DOCUMENT, payload: {} });
      }
    },
  },
  UIProductReturnCloseButton,
  UIProductAmountInShoppingCart,
  UICartDiscountShortcut,
  UICartProductEditShortcut,
  UIDeleteProductShortcut,
  UIDiscountButtons,
  UIQuantityActionsContainer,
  UICustomPayButton,
  UIReturnOrderProduct,
  UICustomYellowCTA,
  UIExchangeModalTitle,
  UIProductAmountInProductOrderEdit,
  UISingleReturnRowCheckbox,
  UIReturnTableHeaders,
  UIReturnOrderProductSelectReason,
};

export default ProcessExchanges;
