import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import propTypes from 'prop-types';

const Checkbox = ({
  size,
  value,
  onClick,
  skin,
  className,
  children,
  errorText,
  disabled = false,
  ...rest
}) => {
  return (
    <InputGroup
      size={size}
      style={{
        ...skin.InputGroup,
        flexWrap: 'nowrap',
        cursor: !disabled ? 'pointer' : 'not-allowed',
      }}
      className={className}
      onClick={!disabled ? onClick : undefined}
      {...rest}
    >
      <span
        style={{
          fontFamily: 'ElegantIcons',
          opacity: !disabled ? '1' : '.40',
          display: 'flex',
          alignItems: 'center',
          padding: '0 0.2ch',
          fontSize: fontSizes[size],
        }}
      >
        {value ? 'Z' : 'V'}
      </span>
      {/* prettier-ignore */}
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          lineHeight: '1em',
      
        }}
      >
        <div style={{ visibility: 'hidden', minHeight: '1em', display:errorText ? undefined : 'none'}}>{errorText}</div>
        <div style={{ opacity: !disabled ? '1' : '.40' }}>{children}</div>
        <FormControl.Feedback
          type="invalid"
          style={{ minHeight: '1em', display:errorText ? 'block' : 'none'}}
        >
          {errorText || ''}
        </FormControl.Feedback>
      </span>
    </InputGroup>
  );
};
Checkbox.propTypes = {
  value: propTypes.bool.isRequired,
  onClick: propTypes.func.isRequired,
};
export default Checkbox;

const fontSizes = {
  sm: 14,
  md: 20,
  lg: 28,
};
