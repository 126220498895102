import { Box, Button, TextField, TextFieldProps } from '@material-ui/core';
import React, { useState } from 'react';

import { PosPlugin } from 'plugins/plugin';
import ProductGroupAutocomplete from 'components/inputs/ProductGroupAutocomplete';

import { Configuration } from '../types';
import { defaultValues } from '../constants';

const FullWidthField: React.FC<TextFieldProps> = props => {
  return <TextField {...props} fullWidth variant="outlined" margin="normal" />;
};

const ComponentConfiguration: Required<
  PosPlugin<Configuration>
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const [state, setState] = useState(current ?? defaultValues);

  const onChange = (key: keyof typeof state) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    setState(prev => ({ ...prev, [key]: value }));
  };

  const setValue = (groupID: string) =>
    setState(prev => ({ ...prev, groupID }));

  return (
    <Box padding="1rem 0">
      <Button variant="contained" color="secondary" onClick={() => save(state)}>
        Save
      </Button>
      <ProductGroupAutocomplete value={state.groupID} setValue={setValue} />
      <FullWidthField
        label="Username"
        onChange={onChange('username')}
        value={state.username}
      />
      <FullWidthField
        label="Password"
        onChange={onChange('password')}
        value={state.password}
        type="password"
      />
      <FullWidthField
        label="Client secret"
        onChange={onChange('clientSecret')}
        value={state.clientSecret}
        type="password"
      />
    </Box>
  );
};

export default ComponentConfiguration;
