/**
 *
 * @param {Store} store
 */
import { getSelectedPos } from '../../reducers/PointsOfSale';
import { getWarehouseById } from '../../reducers/warehouses';
import { getUserLoggedIn } from '../../reducers/Login';

export default store => {
  let title = ``;

  const updateTitle = newTitle => {
    if (title !== newTitle) {
      title = newTitle;
      document.title = newTitle;
    }
  };

  store.subscribe(() => {
    const state = store.getState();
    const user = getUserLoggedIn(state);
    if (!user) {
      return updateTitle('ERPLY Login');
    }
    const pos = getSelectedPos(state);
    if (!pos) {
      return updateTitle('ERPLY Login');
    }
    const warehouse = getWarehouseById(pos.warehouseID)(state);
    if (!warehouse) {
      return updateTitle(`ERPLY Login`);
    }
    return updateTitle(
      `${warehouse.name} - ${pos.name} - ${user.employeeName}`,
    );
  });
};
