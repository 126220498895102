import React from 'react';

import { useBreakpoints } from 'utils/hooks/UI';
import { GreenButton } from 'components/ActionButtons';

import { MobileView } from './components';

const PaymentCTA = ({ width }) => {
  const isMobileView = !useBreakpoints().mdplus;

  return isMobileView ? <MobileView /> : <GreenButton width={width} />;
};

export default PaymentCTA;
