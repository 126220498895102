import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

import { saveSetting } from 'actions/configs';
import { getCurrencyFormatter } from 'reducers/configs/settings';
import { Section } from 'containers/Forms/Settings/components/Inputs';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import {
  processTransactionRequest,
  testConnection,
  closeBatch,
} from 'paymentIntegrations/chase/requests';
import InputField from 'components/FieldTypes/InputField';
import TestButtonBlock from 'components/UIElements/PaymentTestBlock';
import { sanitizePrice } from 'actions/CreateNew';
import useErrorState, { translateError } from 'utils/hooks/useErrorState';
import { TransactionType } from 'paymentIntegrations/chase/types';

import { Ctx } from '../../../../components/CtxInputs';
import RenderFormItem from '../../../../components/FormFieldCtx';

import { chaseFields } from './formShapes';

const inputClass = classNames([
  formStyles.formInput,
  formStyles.mediumTitle,
  'chase-form-input',
]);

const ChaseConfiguration = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.chase.${text}`, ...rest);

  const dispatch = useDispatch();

  const superContext = useContext(Ctx);
  const {
    configuration,
    setEnableUpdate,
    initialIntegrationState,
  } = superContext.values;
  const allSaved = R.equals(configuration, initialIntegrationState);
  const { deviceIP, devicePort } = configuration;
  const { errors, hasErrors } = useErrorState(configuration, chaseFields);

  useEffect(() => {
    setEnableUpdate(!hasErrors);
  }, [errors, hasErrors, setEnableUpdate]);

  const setDeviceIP = value =>
    superContext.onChange('configuration', {
      ...configuration,
      deviceIP: value,
    });
  const setDevicePort = value =>
    superContext.onChange('configuration', {
      ...configuration,
      devicePort: value,
    });

  const onChange = (key, value) => {
    superContext.onChange('configuration', { ...configuration, [key]: value });
  };

  const format = useSelector(getCurrencyFormatter);
  const formatCurrency = amount => format(Number(amount));

  const [testingAmount, setTestingAmount] = useState('0.01');
  const [testingRefNo, setTestingRefNo] = useState('');
  const [testingInvoiceNo, setTestingInvoiceNo] = useState('');
  const [testOutput, setTestOutput] = useState('');
  const [voidsList, setVoidsList] = useState([]);
  const [currentAction, setCurrentAction] = useState('');
  const [isOngoing, setIsOngoing] = useState(false);
  const [status, setStatus] = useState({});
  const setSuccess = key => setStatus({ ...status, [key]: 'Success' });
  const setFailure = key => setStatus({ ...status, [key]: 'Failure' });

  const pseudoInvoiceNumber = () => {
    const num = new Date();
    return JSON.stringify(num.getTime());
  };

  const testFields = [
    {
      title: 'Amount',
      value: testingAmount,
      action: e => setTestingAmount(sanitizePrice(e.target.value)),
      disabled: isOngoing === true,
    },
    {
      title: 'Reference #',
      value: testingRefNo,
      action: e => setTestingRefNo(e.target.value.replace(/[^\d]/g, '')),
      disabled: isOngoing === true,
    },
    {
      title: 'Invoice #',
      value: testingInvoiceNo,
      action: e => setTestingInvoiceNo(e.target.value),
      disabled: isOngoing === true,
    },
    {
      title: 'Output',
      value: testOutput,
      action: {},
      disabled: true,
    },
  ];

  const mainTestButtons = [
    {
      variant: 'secondary',
      title: `Test connection`,
      type: 'connection',
      info: 'Test the connection between the POS and the terminal',
      disabled: isOngoing,
      action: () => testConnection(),
    },
    {
      variant: 'secondary',
      title: `Pay ${formatCurrency(testingAmount)}`,
      type: 'sale',
      info: 'Attempt a sale',
      disabled: isOngoing || !Number(testingAmount) > 0,
      action: () =>
        processTransactionRequest({
          amount: testingAmount,
          transactionType: TransactionType.SALE,
        }),
    },
    {
      variant: 'secondary',
      title: `Return ${formatCurrency(testingAmount)}`,
      type: 'return',
      info: 'Attempt a refund',
      disabled: isOngoing || !Number(testingAmount) > 0,
      action: () =>
        processTransactionRequest({
          transactionType: TransactionType.REFUND,
          amount: testingAmount,
          invoiceNr: pseudoInvoiceNumber(),
        }),
    },
    {
      variant: 'secondary',
      title: `Void`,
      type: 'void',
      info: 'Attempt a transaction void',
      disabled: isOngoing || !testingRefNo,
      action: () =>
        processTransactionRequest({
          transactionType: TransactionType.VOID,
          referenceNumber: testingRefNo,
        }),
    },
    {
      variant: 'danger',
      title: `Close batch`,
      type: 'close',
      info: 'Close the current batch of payments in the terminal.',
      disabled: isOngoing,
      action: () => closeBatch(),
    },
  ];

  const handleTest = ({ type, action }) => {
    const out = msg => setTestOutput(`${type.toUpperCase()}: ${msg}`);
    setIsOngoing(true);
    setCurrentAction(type);
    out(`processing, please wait...`);
    action()
      .then(r => {
        const { records } = r.data;
        if (records && records.length > 0 && records[0].resultCode === '0') {
          // get conf
          if (type === 'conf-get' && records[0].configJSON) {
            const conf = JSON.parse(records[0].configJSON);
            setDeviceIP(conf.deviceIP);
            setDevicePort(conf.devicePort);
            saveConfSettings();
            out('configuration settings retrieved');
          }
          // set conf
          if (type === 'conf-set') {
            saveConfSettings();
            out('configuration saved');
          }
          // connection test
          if (type === 'connection' && records[0].resultCode === '0') {
            out('connection established');
          }
          // all transactions with a reference number
          if (
            type !== 'connection' &&
            type !== 'conf-set' &&
            type !== 'conf-get'
          ) {
            let failed = '';
            records.forEach(rec => {
              if (rec.referenceNumber) {
                const update = [...voidsList, { ...rec }];
                setVoidsList(update);
                out(`success`);
              } else {
                failed = rec.transactionStatus;
              }
            });
            if (failed !== '') {
              out(failed);
              setIsOngoing(false);
              setFailure(type);
              return;
            }
          }
        } else {
          out(r.message || r.errorMessage || records[0].transactionStatus || records[0].statusMessage);
          setIsOngoing(false);
          setFailure(type);
          return;
        }
        setIsOngoing(false);
        setSuccess(type);
      })
      .catch(err => {
        out(
          err.message ||
            err.errorMessage ||
            err.data.records[0].transactionStatus,
        );
        console.error('Chase test failed with error', err);
        setIsOngoing(false);
        setFailure(type);
      });
  };

  const handleVoid = v => {
    const { referenceNumber, approvedAmount, invoiceNumber } = v;
    setTestingAmount(approvedAmount);
    setTestingRefNo(referenceNumber);
    setTestingInvoiceNo(invoiceNumber);
  };

  const saveConfSettings = async () => {
    dispatch(
      saveSetting({
        parameterName: 'terminalIP-chase',
        parameterValue: deviceIP,
      }),
    );
    dispatch(
      saveSetting({
        parameterName: 'terminalPort-chase',
        parameterValue: devicePort,
      }),
    );
  };

  const returnButtons = (buttons = []) =>
    buttons.map(b => {
      let { variant } = b;
      const processing = isOngoing && b.type === currentAction;
      if (processing && b.type !== 'reversal') {
        variant = 'primary';
      } else if (b.disabled) {
        variant = 'light';
      }
      return (
        <TestButtonBlock
          key={b.type}
          clickHandler={() => handleTest({ action: b.action, type: b.type })}
          button={{ ...b, variant }}
          processing={processing}
          status={status[b.type]}
        />
      );
    });

  const returnFields = (fields = []) =>
    fields.map(f => {
      return (
        <InputField
          type={f.type ? f.type : 'text'}
          title={f.title}
          value={f.value}
          onChange={f.action}
          size="lg"
          className={inputClass}
          disabled={f.disabled}
        />
      );
    });

  const returnVoids = voidsList.map(v => {
    const voidHandler = () => handleVoid(v);
    let btnVariant = 'light';
    if (
      v.approvedAmount === testingAmount &&
      v.referenceNumber === testingRefNo
    ) {
      btnVariant = 'primary';
    }
    return (
      <Button className="mr-2 mb-2" variant={btnVariant} onClick={voidHandler}>
        {`${v.transactionType} | ${v.entryMode} | #${v.referenceNumber}`}
      </Button>
    );
  });

  return (
    <div>
      <Ctx.Provider value={{ values: configuration, onChange }}>
        {Object.values(chaseFields).map(({ validate, ...rest }) => (
          <RenderFormItem
            key={rest.id}
            title={t(rest.id)}
            name={rest.id}
            error={translateError(errors[rest.id], t)}
            {...rest}
          />
        ))}
      </Ctx.Provider>
      <h3>
        <b>Tests:</b>
      </h3>
      {!allSaved ? (
        <h4>Unsaved changes, tests unavailable</h4>
      ) : (
        <>
          {returnFields(testFields)}
          <Section>
            {returnButtons(mainTestButtons)}
            <div>
              {voidsList.length ? (
                <>
                  <h4>Current processed payments:</h4>
                  {returnVoids}
                </>
              ) : (
                <h4>No processed payments</h4>
              )}
            </div>
          </Section>
        </>
      )}
    </div>
  );
};

export default ChaseConfiguration;
