import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UIButton from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { getEmployees } from 'reducers/cachedItems/employees';
import { Employee } from 'types/Employee';
import { setCurrentEmployee } from 'actions/sales';
import { getSelectedWarehouseID } from 'reducers/warehouses';

import EmployeeLine from './components/Employee';
import { Box, Typography, TextField, List } from '@material-ui/core';

const EmployeeSelect: React.FC<{ resolve: (val: unknown) => void, onClose: () => void, children?: never, reject: (reason?: unknown) => void }> = ({ resolve, onClose, reject }) => {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModalPage());
    reject()
  }

  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );

  const warehouseID = useSelector(getSelectedWarehouseID);
  const [search, setSearch] = useState<string>('');
  const employees = useSelector(getEmployees);

  const choose = (employee: Employee) => {
    setSelectedEmployee(employee);
  }

  const submit = () => {
    resolve(selectedEmployee);
    dispatch(setCurrentEmployee(selectedEmployee));
    onClose();
  };

  const filteredEmployees = useMemo(
    () =>
      Object.values(employees).filter(
        ({ firstName, lastName, warehouses }) =>
          (firstName.toLowerCase().includes(search.toLowerCase()) ||
            lastName.toLowerCase().includes(search.toLowerCase())) &&
          warehouses.find(wh => Number(wh.id) === Number(warehouseID)),
      ),
    [search, employees, warehouseID],
  );

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">
          Choose Employee
        </Typography>
        <Box display="flex" alignItems="center">
          <UIButton
            disabled={!selectedEmployee}
            text="Choose"
            variant="POS"
            action={submit}
          />
          <CloseButton action={close} />
        </Box>
      </Box>
      <Box>
        <TextField
          value={search}
          onChange={e => setSearch(e.target.value)}
          label="Search"
          fullWidth
          variant="outlined"
          margin='normal'
        />
        <List>
          {filteredEmployees.map(employee => (
            <EmployeeLine
              key={employee.employeeID}
              selectedEmployee={selectedEmployee}
              choose={choose}
              employee={employee}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default EmployeeSelect;
