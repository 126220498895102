import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import Loader from 'components/Loader';

import useURL from './hooks/useURL';
import { EmailValidationProps } from './types';

const EmailValidation: React.FC<EmailValidationProps> = ({
  onClose,
  resolve,
  securityToken,
}) => {
  const { t } = useTranslation('createCustomer', { keyPrefix: 'titles' });
  const [iframeLoading, setIframeLoading] = useState(true);

  const { loading, url } = useURL();

  const ref = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (ref.current && !loading) {
      ref.current.submit();
    }
  }, [loading]);

  function close() {
    onClose();
    resolve();
  }

  return (
    <Box padding="1rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{t('updateEmail')}</Typography>
        <CloseButton action={close} />
      </Box>
      <Loader block loadingText="Loading..." show={loading || iframeLoading}>
        <form
          style={{ display: 'none' }}
          method="POST"
          action={url?.toString()}
          target="email-edit"
          ref={ref}
        >
          <input type="hidden" name="securityToken" value={securityToken} />
        </form>
        <iframe
          name="email-edit"
          onLoad={() => setIframeLoading(false)}
          width="100%"
          height="650px"
          title="Email edit"
          src=""
          frameBorder="0px"
        />
      </Loader>
    </Box>
  );
};

export default EmailValidation;
