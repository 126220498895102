import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { isInt, isFloat, isEmpty } from 'validator';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { getTotal } from 'reducers/ShoppingCart';
import { getCurrentSalesDocument } from 'reducers/sales';
import { openPaymentModal } from 'actions/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { addWarning } from 'actions/Error';
import { getPrepaymentPercentages } from 'actions/sales';
import InputField from 'components/FieldTypes/InputField';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import { round } from 'utils';
import {
  getDateFormatFromBO,
  getAPIDateFormatter,
  getSetting,
} from 'reducers/configs/settings';
import MuiDatePicker from 'components/MuiDatePicker';
import { float, positive } from 'components/FieldTypes/formatters';

import fields from './formFields';

import 'react-datepicker/dist/react-datepicker.css';
import './Layaway.sass';

const Layaway = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPrepaymentPercentages());
  }, [dispatch]);
  const total = useSelector(getTotal);
  const predeterminedPercentage = useSelector(
    getSetting('touchpos_default_layby_percent'),
  );
  const currentSaleDoc = useSelector(getCurrentSalesDocument);
  const formatDateForAPI = useSelector(getAPIDateFormatter);
  const format = useSelector(getDateFormatFromBO);
  const isExistingLayaway =
    currentSaleDoc.id && currentSaleDoc.type === 'PREPAYMENT';
  const date = new Date();
  const initExpiryDate = isExistingLayaway
    ? // if picked up layaway, add paymentDays to doc's date
      dayjs(currentSaleDoc.date).add(Number(currentSaleDoc.paymentDays), 'day')
    : null;
  const initPaid =
    currentSaleDoc.type === 'PREPAYMENT'
      ? currentSaleDoc.paid || '0.00'
      : '0.00';

  const initOutstandingAmount = round(total - Number(initPaid), 2);
  const predeterminedPayment = round(
    (round(initOutstandingAmount, 2) * (predeterminedPercentage ?? 0)) / 100,
    2,
  );
  const initState = {
    startDate: isExistingLayaway ? currentSaleDoc.date : date,
    expiryDate: initExpiryDate,
    advancePayment: predeterminedPayment || '0.00',
    prepaymentPercent: predeterminedPercentage || '',
    invoiceTotal: round(total, 2),
    paid: initPaid,
    outstandingAmount: initOutstandingAmount,
  };

  const [state, setState] = useState(initState);

  const expired = () => {
    return dayjs(state.expiryDate).diff(dayjs(date), 'days') < 0;
  };

  const close = () => {
    dispatch(closeModalPage());
  };
  const { t } = useTranslation('layaway');

  const onSave = () => {
    if (!(state.advancePayment > 0)) {
      dispatch(
        addWarning(t('alerts.missingPayment'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    } else if (state.expiryDate && !dayjs(state.expiryDate).isValid()) {
      dispatch(
        addWarning(t('alerts.invalidDate'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    } else if (state.expiryDate && expired() && !isExistingLayaway) {
      dispatch(
        addWarning(t('alerts.alreadyExpired'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    } else {
      dispatch(closeModalPage());
      dispatch(
        openPaymentModal({
          props: {
            total: Number(state.advancePayment) + Number(state.paid),
            salesDocument: {
              type: 'PREPAYMENT',
              reserveGoods: 1,
              advancePayment: Number(state.advancePayment) + Number(state.paid),
              reserveGoodsUntilDate: formatDateForAPI(state.expiryDate, format),
              paymentDays: dayjs(
                formatDateForAPI(state.expiryDate, format),
              ).diff(new Date().toISOString().split('T')[0], 'day'),
            },
          },
          showNotes: false,
        }),
      );
    }
  };

  const percentageFormatter = newVal => (newVal > 100 ? 100 : newVal);

  const handleChange = {
    prepaymentPercent: e => {
      const prepaymentPercent = e.target.value;
      setState({
        ...state,
        prepaymentPercent,
        // calculate the percent from the outstanding amount
        advancePayment: round(
          (state.outstandingAmount * prepaymentPercent) / 100,
          2,
        ),
      });
    },
    advancePayment: e => {
      const { value } = e.target;
      if (isFloat(value) || isInt(value) || isEmpty(value))
        setState({
          ...state,
          advancePayment:
            Number(value) <= Number(state.outstandingAmount)
              ? value
              : state.outstandingAmount,
          prepaymentPercent: round((value * 100) / state.outstandingAmount, 2),
        });
    },
    roundOnBlur: () =>
      setState({
        ...state,
        advancePayment: round(state.advancePayment, 2),
      }),
    startDate: e => {
      setState({ ...state, startDate: e });
    },
    expiryDate: e => {
      setState({ ...state, expiryDate: e });
    },
  };

  return (
    <div className="layaway-sales" data-testid="layaway-sales">
      <Modal.Header>
        <Modal.Title data-testid="layaway-sales-title">
          {t('save.title')}
        </Modal.Title>
        <div
          className="layaway-sales-header-bttn-wrapper"
          style={{
            marginLeft: 'auto',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <SaveButton data-testid="save-layaway-btn" action={() => onSave()} />
          <CloseButton action={() => close()} />
        </div>
      </Modal.Header>
      <Modal.Body className="body">
        <Form>
          {fields.map(field => {
            return (
              <Form.Group key={field.key} controlId={field.key}>
                {field.type === 'date' ? (
                  <MuiDatePicker
                    onChange={handleChange[field.key]}
                    disabledProp={field.disabled || isExistingLayaway}
                    data-testid="date-picker"
                    data-test-key={field.key}
                    disablePast={true}
                    style={{ height: '39px' }}
                    format={format}
                    value={state[field.key]}
                    label={t(`fields.${field.key}`)}
                  />
                ) : (
                  <InputField
                    size="lg"
                    title={t(`fields.${field.key}`)}
                    value={state[field.key] || ''}
                    data-testid="layaway-field"
                    data-test-key={`layaway-${field.key}`}
                    name={field.key}
                    type={field.type}
                    formatter={
                      field.key === 'prepaymentPercent'
                        ? float.and(positive).and(percentageFormatter)
                        : null
                    }
                    options={field.options}
                    onChange={field.disabled ? null : handleChange[field.key]}
                    autoComplete="off"
                    onBlur={() =>
                      field.key === 'advancePayment'
                        ? handleChange.roundOnBlur()
                        : null
                    }
                    readOnly={field.disabled}
                    className={classNames([styles.formInput, styles.longTitle])}
                  />
                )}
              </Form.Group>
            );
          })}
        </Form>
      </Modal.Body>
    </div>
  );
};

export default Layaway;
