import axios from 'axios';

import { REDUX_JWT } from 'constants/persistence';
import { getUrlForService } from 'actions/serviceEndpoints';

const client = axios.create();

function getJwt(): string | null {
  const jwt = localStorage.getItem(REDUX_JWT);
  if (jwt === null) return null;

  try {
    return JSON.parse(jwt);
  } catch (error) {
    return jwt;
  }
}

client.interceptors.request.use(async config => {
  return {
    ...config,
    baseURL: await getUrlForService('cdn'),
    headers: {
      ...config.headers,
      JWT: getJwt(),
    },
  };
});

export default client;
