import React, { useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import uuid from 'uuid/v4';
import * as R from 'ramda';

import { PosPlugin } from '../../plugin';
import { Question, QuestionConfig } from '../types';
import { getHighestOrderPropValue } from '../utils';

import QuestionsTable from './QuestionsTable';
import NewQuestionStepper from './NewQuestionStepper';

const generateQuestionTemplate = (): Question => ({
  id: uuid(),
  required: false,
  content: '',
  answers: {},
  multiOptions: false,
  order: 0,
  attributeName: '',
});

const ComponentConfigurationByLevel: Required<
  PosPlugin
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const [questions, setQuestions] = useState<QuestionConfig>(
    R.clone(current?.questions ?? {}),
  );

  const [onlyOnNewCustomer, setOnlyOnNewCustomer] = useState<boolean>(
    Boolean(current?.onlyOnNewCustomer),
  );

  const [question, setQuestion] = useState<Question>();

  const addNewQuestion = () => setQuestion(generateQuestionTemplate());

  const selectQuestion = (qid: Question['id']) => {
    if (questions[qid]) setQuestion(R.clone(questions[qid]));
  };

  const saveQuestion = () => {
    if (question?.id) {
      const questionCopy = R.clone(question);
      // if new question (order>= 0 - default order) set
      if (!questions[questionCopy.id]?.order) {
        questionCopy.order = getHighestOrderPropValue(questions) + 1;
      }
      setQuestions(R.assoc(questionCopy.id, questionCopy));
      setQuestion(undefined);
    }
  };

  const configHasChanged = useMemo(() => {
    return R.not(
      R.equals(current?.questions, questions) &&
        !!current?.onlyOnNewCustomer === onlyOnNewCustomer,
    );
  }, [questions, current, onlyOnNewCustomer]);

  const saveConfig = () => {
    save({ questions, onlyOnNewCustomer });
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '1em' }}>
      <Grid
        xs={12}
        item
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        {!question && (
          <Button
            onClick={addNewQuestion}
            variant="outlined"
            style={{ marginRight: 'auto' }}
          >
            Add Question
          </Button>
        )}
        <Button
          disabled={!configHasChanged}
          color="primary"
          variant="outlined"
          onClick={saveConfig}
        >
          Save
        </Button>
      </Grid>
      <Grid xs={12} item>
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyOnNewCustomer}
              onChange={() => setOnlyOnNewCustomer(!onlyOnNewCustomer)}
            />
          }
          label="Show Feedback form only on the creation of a New Customer"
        />
      </Grid>
      <Grid item xs={12}>
        {!!question && (
          <NewQuestionStepper
            question={question}
            updateQuestion={setQuestion}
            saveQuestion={saveQuestion}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <QuestionsTable
          questions={questions}
          setQuestions={setQuestions}
          selectQuestion={selectQuestion}
        />
      </Grid>
    </Grid>
  );
};

export default ComponentConfigurationByLevel;
