import React, { useMemo, useState } from 'react';
import * as R from 'ramda';
import * as rxjs from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Card,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useObservable } from 'react-use';

import { PosPlugin } from 'plugins/plugin';
import {
  CreatePaymentRequest,
  GetPaymentStatusResponse,
} from 'plugins/oneacre/api/types/schema';
import { addError } from 'actions/Error';
import { getPluginConfiguration } from 'reducers/Plugins';
import {
  getActivePaymentIntegration,
  getCafaEntry,
} from 'reducers/cafaConfigs';
import { saveActiveIntegration } from 'actions/integrations/CafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { getUserLoggedIn } from 'reducers/Login';

import * as API from './api/API';

import { pluginID } from './index';
import {
  PaymentIndeterminate
} from 'paymentIntegrations/cayan/requests/resultParsers';

export type Conf = {
  url: string;
  apiKey: string;
  accountNumber: number;
  walletId: string;
};
export const getMpesaConfiguration = state =>
  getPluginConfiguration<Conf>(pluginID)(state);

const StatusAndButtons = ({
  status,
  buttons,
}: {
  status: string;
  buttons: Record<any, () => void>;
}) => {
  return (
    <>
      <h2>Status: {status}</h2>

      <div>
        {Object.entries(buttons).map(([name, action]) => (
          <Button key={name} onClick={action}>
            {name}
          </Button>
        ))}
      </div>
    </>
  );
};

/** @deprecated Not in use for security reasons */
const Configuration: PosPlugin<Conf>['ComponentConfiguration'] = ({
  byLevel: {
    company: {
      current = { url: '', apiKey: '', accountNumber: -0, walletId: '' },
      save,
    },
  },
}) => {
  const dispatch = useDispatch();
  const integration = useSelector(getActivePaymentIntegration);
  const selectPmtIntegration = saveActiveIntegration({
    integrationType: INTEGRATION_TYPES.payment,
    value: 'mPesa',
  });
  const [config, setConfig] = useState<Conf>(current);
  return (
    <>
      <Button
        variant="contained"
        disabled={integration === 'mPesa'}
        onClick={() => dispatch(selectPmtIntegration)}
      >
        {integration === 'mPesa'
          ? 'mPesa selected as active payment integration'
          : 'Select mPesa as payment integration'}
      </Button>
      <hr />
      <TextField
        fullWidth
        label="Endpoint"
        onChange={e => setConfig(R.assoc('url', e.target.value))}
        value={config.url}
      />
      <TextField
        fullWidth
        label="API KEY"
        type="password"
        onChange={e => setConfig(R.assoc('apiKey', e.target.value))}
        value={config.apiKey}
      />
      <TextField
        fullWidth
        label="Account number"
        onChange={e =>
          setConfig(R.assoc('accountNumber', Number(e.target.value)))
        }
        value={config.accountNumber}
      />
      <Button disabled={R.equals(current, config)} onClick={() => save(config)}>
        SAVE
      </Button>

      <hr />

      <Test2 config={config} />
    </>
  );
};
/** @deprecated Not in use for security reasons */
const Test2 = ({ config }) => {
  const [amount, setAmount] = useState<number | undefined>();
  const status = useObservable(
    useMemo(
      () =>
        amount
          ? API.processPayment$(
              {
                remoteTransactionReference: 'System test',
                shop: {
                  id: '1',
                  name: 'Põhikassa',
                  attendantName: 'Mart Jõgi',
                  description: 'Test transaction from plugin configuration',
                },
                client: {
                  walletId: '0',
                  accountNumber: 1,
                  phoneNumber: '+37258049174',
                },
                transaction: {
                  amount,
                  date: '2020-15-1222:13:05.201',
                  currency: 'EUR',
                },
              },
              config,
            )
          : rxjs.EMPTY,
      [amount],
    ),
  );

  if (!amount)
    return (
      <Button onClick={() => setAmount(Math.random())}>
        Begin transaction
      </Button>
    );

  if (!status)
    return (
      <Card>
        <LinearProgress variant="indeterminate" />
        <h2>Processing payment of {amount.toFixed(2)}...</h2>
        <p>Status: Just started</p>
      </Card>
    );

  if (status instanceof PaymentIndeterminate)
    return (
      <Card>
        <LinearProgress variant="indeterminate" />
        <h2>Processing payment of {amount.toFixed(2)}...</h2>
        <p
          style={{ whiteSpace: 'pre' }}
          // eslint-disable-next-line react/no-children-prop
          children={JSON.stringify(status?.data ?? '')}
        />
      </Card>
    );
  return (
    <Card>
      <h2>
        Payment of {amount.toFixed(2)} {typeof status}
      </h2>
      <p
        style={{ whiteSpace: 'pre' }}
        // eslint-disable-next-line react/no-children-prop
        children={JSON.stringify(status?.data ?? '', undefined, 4)}
      />
      <Button onClick={() => setAmount(undefined)}>RESET</Button>
    </Card>
  );
};
/** @deprecated Not in use for security reasons */
const Test = ({ config }) => {
  const [request, setRequest] = useState<CreatePaymentRequest | null>(null);
  const [status, setStatus] = useState<GetPaymentStatusResponse | null>(null);
  const dispatch: ThunkDispatch<any, any, Action> = useDispatch();

  if (!request) {
    return (
      <StatusAndButtons
        status="No active payment"
        buttons={{
          'Start new payment': () => {
            const id = uuidv4();
            const request = {
              remoteTransactionReference: id,
              shop: {
                id: '1',
                name: 'Põhikassa',
                attendantName: 'Mart Jõgi',
                description: 'Test transaction from plugin configuration',
              },
              client: {
                walletId: '0',
                accountNumber: 1,
                phoneNumber: '+37258049174',
              },
              transaction: {
                amount: 0.01,
                date: '2020-15-1222:13:05.201',
                currency: 'EUR',
              },
            };
            API.createPayment(request, config)
              .then(() => setRequest(request))
              .catch(err => dispatch(addError(err.message)));
          },
        }}
      />
    );
  }

  if (!status) {
    return (
      <StatusAndButtons
        status="Processing payment (no status yet)..."
        buttons={{
          'Check status': () => {
            API.getPaymentStatus(request.remoteTransactionReference, config)
              .then(({ data }) => setStatus(data))
              .catch(err => dispatch(addError(err.message)));
          },
        }}
      />
    );
  }
  if (!['FULFILLED', 'FAILED'].includes(status?.status)) {
    return (
      <StatusAndButtons
        status={`Processing payment (status: ${status?.status})...`}
        buttons={{
          'Recheck status': () => {
            API.getPaymentStatus(request.remoteTransactionReference, config)
              .then(({ data }) => setStatus(data))
              .catch(err => dispatch(addError(err.message)));
          },
        }}
      />
    );
  }
  if (status?.status === 'FULFILLED')
    return (
      <StatusAndButtons
        status="Payment success"
        buttons={{
          'Confirm and reset': () => {
            setRequest(null);
            setStatus(null);
          },
        }}
      />
    );
  if (status?.status === 'FAILED')
    return (
      <StatusAndButtons
        status="Payment failed"
        buttons={{
          'Confirm and reset': () => {
            setRequest(null);
            setStatus(null);
          },
        }}
      />
    );

  return <span>Unknown state</span>;
};

const useConfigs = () => {
  const company = useSelector(
    getCafaEntry(pluginID, 'brazilPlugin', {
      level: 'Company',
      id: '',
    }),
  )?.value;
  const warehouse = useSelector(
    getCafaEntry(pluginID, 'brazilPlugin', {
      level: 'Warehouse',
      id: useSelector(getSelectedWarehouseID),
    }),
  )?.value;
  const pos = useSelector(
    getCafaEntry(pluginID, 'brazilPlugin', {
      level: 'Pos',
      id: useSelector(getSelectedPosID),
    }),
  )?.value;
  const user = useSelector(
    getCafaEntry(pluginID, 'brazilPlugin', {
      level: 'User',
      id: useSelector(getUserLoggedIn).userID,
    }),
  )?.value;
  return useMemo(() => ({ company, warehouse, pos, user }), [
    company,
    warehouse,
    pos,
    user,
  ]);
};

const levels = ['user', 'pos', 'warehouse', 'company'];
const ConfIndicator = ({ label, lens }) => {
  const existing = useConfigs();
  const effective = useSelector(getMpesaConfiguration);

  const { source, value } = useMemo(() => {
    const key = levels.find(k => R.view(lens, existing[k]));
    if (key) return { value: R.view(lens, existing[key]), source: key };
    return {
      value: R.view(lens, { value: effective }),
      source: 'built-in defaults',
    };
  }, [existing, effective]);

  return (
    <div>
      <b>{label}: </b>
      <Typography color="secondary" component="span">
        {JSON.stringify(value) ?? '-'}
      </Typography>{' '}
      from {source}
    </div>
  );
};

export const ReadonlyConfiguration: PosPlugin<
  Conf
>['ComponentConfiguration'] = () => {
  const existing = useConfigs();
  const actual = useSelector(getMpesaConfiguration);
  const dispatch = useDispatch();
  const integration = useSelector(getActivePaymentIntegration);
  const selectPmtIntegration = saveActiveIntegration({
    integrationType: INTEGRATION_TYPES.payment,
    value: 'mPesa',
  });
  return (
    <>
      <p>POS-side configuration of this plugin has been disabled</p>
      <Button
        variant="contained"
        disabled={integration === 'mPesa'}
        onClick={() => dispatch(selectPmtIntegration)}
      >
        {integration === 'mPesa'
          ? 'mPesa selected as active payment integration'
          : 'Select mPesa as payment integration'}
      </Button>
      <p>
        If you are an administrator, you can configure this plugin by manually
        adding the appropriate entry to CAFA (f.ex. using the Configuration
        Admin application)
      </p>
      <p>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        The configuration should be made in the "pos-wizard" application, with
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        "brazilPlugin" type and "{pluginID}" name
      </p>
      <h2>The configurations that the plugin is expecting are:</h2>
      company:
      <Typography color="primary" style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(
          {
            config: {
              accountNumber: 0,
              apiKey: '4BIDACCE552ACC5',
              url: 'https://some.url.com',
            },
          },
          undefined,
          4,
        )}
      </Typography>
      any:
      <Typography color="primary" style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(
          {
            config: {
              walletId: '00000000',
            },
            enabled: true,
          },
          undefined,
          4,
        )}
      </Typography>
      <h2>The currently active configurations are</h2>
      Company:
      <Typography color="secondary" style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(existing.company, undefined, 4)}
      </Typography>
      Warehouse:
      <Typography color="secondary" style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(existing.warehouse, undefined, 4)}
      </Typography>
      Pos:
      <Typography color="secondary" style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(existing.pos, undefined, 4)}
      </Typography>
      User:
      <Typography color="secondary" style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(existing.user, undefined, 4)}
      </Typography>
      <h2>Adding together into an effective config of</h2>
      <Typography color="primary" style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(actual, undefined, 4)}
      </Typography>
    </>
  );
};
