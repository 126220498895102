import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import {
  commitTempSettings,
  dropTempSettings,
  saveTempSetting,
} from 'actions/configs';
import { getSettings } from 'reducers/configs/settings';
import { getARTemplates } from 'services/SalesAPI';
import { ARTemplateResponse } from 'services/SalesAPI/types';

const documentTypes = [
  'INVOICE',
  'CASHINVOICE',
  'CREDITINVOICE',
  'PREPAYMENT',
  'ORDER',
  'INVWAYBILL',
  'WAYBILL',
  'OFFER',
  'RESERVATION',
  'GIFTRECEIPT',
] as const;

type DocType = typeof documentTypes[number];
type LowercasedReturnType = {
  INVOICE: 'actual_reports_template_for_invoice';
  CASHINVOICE: 'actual_reports_template_for_cashinvoice';
  CREDITINVOICE: 'actual_reports_template_for_creditinvoice';
  PREPAYMENT: 'actual_reports_template_for_prepayment';
  ORDER: 'actual_reports_template_for_order';
  INVWAYBILL: 'actual_reports_template_for_invwaybill';
  WAYBILL: 'actual_reports_template_for_waybill';
  OFFER: 'actual_reports_template_for_offer';
  RESERVATION: 'actual_reports_template_for_reservation';
  GIFTRECEIPT: 'actual_reports_template_for_giftreceipt';
};
const enableSettingKey = 'use_actual_reports_templates';
const getSettingKeyForDocType = (
  docType: DocType,
): LowercasedReturnType[DocType] => {
  return `actual_reports_template_for_${docType.toLowerCase()}` as LowercasedReturnType[DocType];
};

const useStyles = makeStyles(() => ({
  table: {
    '& td': {
      borderBottom: 'none',
      backgroundColor: 'transparent',
    },
  },
}));

const ReceiptTemplatesConfiguration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsReceiptTemplates');
  const { t: returnT } = useTranslation('return');
  const styles = useStyles();

  const settings = useSelector(getSettings);
  const [changed, setChanged] = useState<string[]>([]);

  const [templates, setTemplates] = useState<ARTemplateResponse[]>([]);

  useEffect(() => {
    getARTemplates().then(res => setTemplates(res));
  }, []);

  const onChange = e => {
    dispatch(saveTempSetting(e.target.name, e.target.value));
    setChanged(prev => [...prev, e.target.name]);
  };

  const onClose = () => {
    if (changed.length) dispatch(dropTempSettings(changed));
    dispatch(previousModalPage());
  };

  const onSave = () => {
    dispatch(commitTempSettings(changed));
    dispatch(previousModalPage());
  };

  return (
    <div className="erply-settings">
      <Modal.Header>
        <span>{t('title')}</span>
        <span style={{ flexGrow: 1 }} />
        <SaveButton action={onSave} disabled={!changed.length} variant="POS" />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormControlLabel
          label={t('inputLabels.enable')}
          control={
            <Checkbox
              name={enableSettingKey}
              checked={settings[enableSettingKey]}
              onChange={() =>
                onChange({
                  target: {
                    name: enableSettingKey,
                    value: !settings[enableSettingKey],
                  },
                })
              }
            />
          }
        />
        <Table size="small" className={styles.table}>
          <TableBody>
            {documentTypes.map(docType => (
              <TableRow key={docType}>
                <TableCell>
                  <Typography>
                    {t('inputLabels.template.docType', {
                      documentType: returnT('documentTypes.documentType', {
                        context: docType,
                      }),
                    })}
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    select
                    fullWidth
                    disabled={!settings[enableSettingKey]}
                    variant="outlined"
                    name={getSettingKeyForDocType(docType)}
                    value={settings[getSettingKeyForDocType(docType)] ?? 0}
                    onChange={onChange}
                  >
                    <MenuItem key={0} value={0}>
                      <em>{t('inputLabels.template.default')}</em>
                    </MenuItem>
                    {templates.map(template => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Modal.Body>
    </div>
  );
};

export default ReceiptTemplatesConfiguration;
