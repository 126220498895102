import { v4 as uuidv4 } from 'uuid';
import { createSelector } from 'reselect';

import { TYPE_LOGOUT } from 'constants/Login';
import { getHasUndismissedErrorsOfType } from 'reducers/Error';
import { RootState } from 'reducers';

import * as mp from '../constants/modalPage';

export type ModalPageState = {
  id: string;
  component: string;
  props: any;
  modalClassName: string;
  isPopup: boolean;
  groupID?: string;
  replace?: boolean;
};

const initState = {
  components: [] as ModalPageState[],
};

// TODO: Refactor actions and reducer so that all the props are passed via payload

function modalPage(
  state = initState,
  {
    id,
    type,
    component,
    props = {},
    modalClassName,
    isPopup,
    groupID,
    replace,
  }: ModalPageState & { type: string },
) {
  switch (type) {
    case TYPE_LOGOUT:
      return initState;
    case mp.OPEN_MODAL_PAGE:
      // eslint-disable-next-line no-case-declarations
      const newMP = {
        id: id || uuidv4(),
        component,
        props,
        modalClassName,
        isPopup,
        groupID,
      };
      // replace && groupID replaces all cp from the group with the payload component
      // replace only replaces the last component
      if (replace) {
        return {
          ...state,
          components: [
            ...(groupID
              ? state.components.filter(
                  c => !c.groupID || c.groupID !== groupID,
                )
              : state.components.slice(0, -1)),
            newMP,
          ],
        };
      }
      return {
        ...state,
        components: [...state.components, newMP],
      };
    case mp.PREVIOUS_MODAL_PAGE: {
      const lastPopup = state.components
        .filter(comp => comp.isPopup)
        .slice(-1)[0];
      const last = state.components.slice(-1)[0];
      const closeID = (lastPopup || last || {}).id;
      const updatedComponents = state.components.filter(
        ({ id }) => id !== closeID,
      );
      if (updatedComponents.length === state.components.length) return state;
      return { ...state, components: updatedComponents };
    }
    case mp.CLOSE_MODAL_PAGE: {
      if (!id && !groupID) return initState;
      const updatedComponents = id
        ? state.components.filter(({ id: pid }) => pid !== id)
        : state.components.filter(c => c.groupID !== groupID);
      if (updatedComponents.length === state.components.length) return state;
      return { ...state, components: updatedComponents };
    }
    default:
      return state;
  }
}

export default modalPage;

export function getComponents(state: RootState) {
  return state.modalPage.components;
}

export const getModalPages = createSelector(getComponents, components =>
  components.filter(comp => !comp.isPopup),
);
export const getModalPopups = createSelector(getComponents, components =>
  components.filter(comp => comp.isPopup),
);

// region Blocking scanning
const getScannerNonWhitelistComponent = createSelector(
  getComponents,
  components =>
    components
      .map(comp => comp.component)
      .find(comp => !mp.scannerComponentWhiteList.includes(comp)),
);
function getScannerBlacklistedAlert(state: RootState) {
  return mp.scannerErrorTypesBlackList.find(et =>
    getHasUndismissedErrorsOfType(et)(state),
  );
}

export const getMessageIfScanningShouldBeBlocked = createSelector(
  getScannerNonWhitelistComponent,
  getScannerBlacklistedAlert,
  (component, alert) => {
    if (component) return ['componentBlacklist', component];
    if (alert) return ['alertBlacklist', alert];
    return undefined;
  },
);
// endregion
// region Blocking shopping cart
const getShoppingcartBlacklistedComponent = createSelector(
  getComponents,
  components =>
    components
      .map(comp => comp.component)
      .find(comp => mp.shoppingcartComponentsBlacklist.includes(comp)),
);
function getShoppingcartBlacklistedAlert(state: RootState) {
  return mp.shoppingcartErrorTypesBlacklist.find(et =>
    getHasUndismissedErrorsOfType(et)(state),
  );
}

export const getMessageIfShoppingcartShouldBeBlocked = createSelector(
  getShoppingcartBlacklistedComponent,
  getShoppingcartBlacklistedAlert,
  (component, alert) => {
    if (component) return ['componentBlacklist', component];
    if (alert) return ['alertBlacklist', alert];
    return undefined;
  },
);
// endregion

export function getCurrentModalPage(state: RootState) {
  return getModalPages(state).slice(-1)[0] || null;
}

export function getCurrentModalPopup(state: RootState) {
  return getModalPopups(state).slice(-1)[0] || null;
}

export function hasOpenModalPage(state: RootState) {
  return getCurrentModalPage(state) !== null;
}
export function hasOpenModalPopup(state: RootState) {
  return getCurrentModalPopup(state) !== null;
}

export function getIsModalOpened(state: RootState) {
  return getComponents(state).length > 0;
}
