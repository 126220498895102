import * as R from 'ramda';

import { REDUX_CUSTOMER_REGISTRY_URL } from 'constants/persistence';
import * as customerRegistryAPI from 'services/CustomerRegistery';
import {
  doClientRequest,
  requestAllRecords,
} from 'services/ErplyAPI/core/ErplyAPI';

export const getCustomers = params => {
  const customerRegistery = !!localStorage.getItem(REDUX_CUSTOMER_REGISTRY_URL);
  if (customerRegistery) {
    const {
      customerID,
      searchNameIncrementally: searchTerm = '',
      customerIDs,
      getBalanceInfo,
      getBalanceWithoutPrepayments,
    } = params;

    if (searchTerm.length) {
      return customerRegistryAPI.customerSearch({ searchTerm });
    }

    if (customerID) {
      const customer = customerRegistryAPI.getMasterRecord({ id: customerID });
      if (getBalanceInfo) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return getCustomerBalance({
          customerIDs: customerIDs ?? customerID,
          getBalanceWithoutPrepayments,
        }).then(([{ availableCredit }]) =>
          customer.then(([c]) => [{ ...c, availableCredit }]),
        );
      }
      return customer;
    }
    if (customerIDs)
      return customerRegistryAPI.findByIdList({ list: customerIDs });

    return customerRegistryAPI.advancedSearch(params);
  }

  return doClientRequest({
    ...params,
    doNotCalculateRecordsTotal: 1,
    responseMode: 'detail',
    request: 'getCustomers',
  });
};

export const getDefaultCustomer = params =>
  doClientRequest({ ...params, request: 'getDefaultCustomer' });

export const saveCustomer = params => {
  const customerRegistery = !!localStorage.getItem(REDUX_CUSTOMER_REGISTRY_URL);
  if (customerRegistery) {
    if (params.customerID) return customerRegistryAPI.updateCustomer(params);
    return customerRegistryAPI.createCustomer(params);
  }
  const props = R.dissoc('newCustomer', params);
  return doClientRequest({ ...props, request: 'saveCustomer' });
};

export const saveAddress = params => {
  const customerRegistery = !!localStorage.getItem(REDUX_CUSTOMER_REGISTRY_URL);
  if (customerRegistery) {
    if (params.addressID) return customerRegistryAPI.updateAddress(params);
    return customerRegistryAPI.createAddress(params);
  }
  return doClientRequest({ ...params, request: 'saveAddress' });
};
export const deleteAddress = params => {
  const customerRegistery = !!localStorage.getItem(REDUX_CUSTOMER_REGISTRY_URL);
  if (customerRegistery) {
    return customerRegistryAPI.deleteAddress(params);
  }

  return doClientRequest({ ...params, request: 'deleteAddress' });
};

export const getAddressTypes = params =>
  doClientRequest({ ...params, request: 'getAddressTypes' });

export const getCustomerGroups = () =>
  doClientRequest({ request: 'getCustomerGroups' });

export const getCustomerRewardPoints = customerID =>
  doClientRequest({ customerID, request: 'getCustomerRewardPoints' });

export const getUsedRewardPointRecords = (params, from = 0, count = Infinity) =>
  requestAllRecords(
    {
      ...params,
      request: 'getUsedRewardPointRecords',
    },
    from,
    count,
  ).then(({ records }) => records);

export const logProcessingOfCustomerData = customerIDs =>
  doClientRequest({
    customerIDs,
    activityType: 'read',
    description: 'Read data from POS',
    fields: 'all',
    request: 'logProcessingOfCustomerData',
  });

/**
 *
 * @param customerIDs
 * @returns {Promise<[{
 *   customerID: string,
 *   actualBalance: string,
 *   creditLimit: number,
 *   availableCredit: string,
 *   creditAllowed: 0 | 1
 * }]>}
 */
export const getCustomerBalance = ({
  customerIDs,
  getBalanceWithoutPrepayment,
}) =>
  doClientRequest({
    request: 'getCustomerBalance',
    customerIDs,
    getBalanceWithoutPrepayment,
  });

/**
 * Get a list of person titles. Person titles are used as a prefix for polite client naming
 * @param lang {'eng' | 'spa' | 'ger' | 'swe' | 'fin' | 'rus' | 'est' | 'lat' | 'lit' | 'gre'}
 * @returns Promise<{id: number, name: string, order: number, added: number, lastModified: number}[]>
 */
export const getPersonTitles = ({ lang }) =>
  doClientRequest({
    request: 'getPersonTitles',
    lang,
  });

/**
 * Get a list of job titles. Job titles are used to specify employee's job area.
 * @param lang {'eng' | 'spa' | 'ger' | 'swe' | 'fin' | 'rus' | 'est' | 'lat' | 'lit' | 'gre'}
 * @returns Promise<{id: number, name: string, order: number, added: number, lastModified: number}[]>
 */
export const getJobTitles = ({ lang }) =>
  doClientRequest({
    request: 'getJobTitles',
    lang,
  });

