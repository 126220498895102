import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import ListItem from 'components/ListItem';
import { addWarning } from 'actions/Error';
import {
  deletePayment,
  setPaymentEditValue,
  setShowPaymentInput,
} from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import {
  getBalance,
  getIsReturnPayment,
  getLastPayment,
  getPayments,
  getPaymentsCurrency,
} from 'reducers/Payments';
import { useBreakpoints } from 'utils/hooks/UI';
import { getPaymentLimits } from 'reducers/PaymentLimits';

const AddCardPayment = ({ disabled = false }) => {
  const { t } = useTranslation('payment');
  const { t: t2 } = useTranslation('alerts');
  const dispatch = useDispatch();
  const isMobileView = !useBreakpoints().mdplus;
  const isReturnPayment = useSelector(getIsReturnPayment);
  const payments = useSelector(getPayments);
  const balance = useSelector(getBalance);
  const { lastPayment, lastKey } = useSelector(getLastPayment);
  const { code: currencyCode, rate } = useSelector(getPaymentsCurrency);
  const paidInGivenType = Object.values(payments)
    ?.filter(op => op.type === 'CARD')
    ?.reduce((a, b) => a + Number(b?.amount || 0), 0);
  const { amount: tenderLimit } = useSelector(getPaymentLimits).find(limit => {
    return limit.type === 'CARD' && limit.amount > 0;
  }) || { amount: Infinity };
  const remainingLimit = tenderLimit - paidInGivenType;

  const addCardPayment = () => {
    dispatch(setPaymentEditValue(''));

    const fullPayment = isReturnPayment ? balance : -balance;
    if (!remainingLimit) {
      dispatch(
        addWarning(
          t2('payments.tenderLimit', {
            tender: 'card',
          }),
        ),
      );
    } else if (fullPayment > 0) {
      const cardPaymentsKeys = Object.keys(payments).filter(key =>
        key.includes('card'),
      );

      const emptyCardPaymentKey = cardPaymentsKeys.find(
        key => payments[key].amount === '0.00',
      );

      const paymentKey = emptyCardPaymentKey || uuidv4();

      (async () => {
        if (lastPayment.amount === '0.00')
          dispatch(deletePayment({ key: lastKey }));
        await dispatch(
          setPayment({
            amount: '0.00',
            key: `${paymentKey}`,
            caption: 'CARD',
            type: 'CARD',
            currencyCode,
            currencyRate: Number(rate),
          }),
        );
        if (payments[paymentKey])
          await dispatch(setPaymentSelected(`${paymentKey}`));
      })();
    } else {
      dispatch(
        addWarning(t('alerts.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
  };

  return isMobileView ? (
    <li className="payment-item card-payment" style={{ cursor: 'pointer' }}>
      <div
        tabIndex={0}
        className="button"
        role="button"
        onClick={() => dispatch(setShowPaymentInput(true, 'CARD'))}
      >
        <span className="title">{t('buttons.enterCard')}</span>
      </div>
    </li>
  ) : (
    <ListItem
      data-testid="card-payment-button"
      disabled={disabled}
      variant="grid_dark"
      action={addCardPayment}
    >
      <span>{t('buttons.card')}</span>
    </ListItem>
  );
};

export default AddCardPayment;
