import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useMethods } from 'react-use';

import { useBreakpoints } from 'utils/hooks/UI';
import Loader from 'components/Loader';
import { Product } from 'types/Product';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { modalPages as mp } from 'constants/modalPage';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import {
  getCurrencyFormatter,
  getSetting,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { openProductViewForItem } from 'actions/productsDB';
import { PluginComponent } from 'plugins';
import { getCachedItemPerTypeByID } from 'reducers/cachedItems';

import { useSearchResultStyles } from '../index.styles';

export const ProductResultRow = React.memo(
  React.forwardRef<
    HTMLTableRowElement,
    {
      product: Product & { embeddedPrice?: number };
      selected?: boolean;
      onSelect: (product: Product & { embeddedPrice?: number }) => void;
    }
  >(({ product, selected, onSelect }, ref) => {
    const format = useSelector(getCurrencyFormatter);
    const dispatch = useDispatch();
    const isWithTax = useSelector(getShowPricesWithTax);
    const gridIsOpened = useSelector(getGridIsOpened);
    const isMobile = !useBreakpoints().mdplus;

    const cachedProductStock = useSelector(
      getCachedItemPerTypeByID('productStockLevels', product.productID),
    );
    const itemsFree = Number(cachedProductStock?.amountInStock ?? product.free);

    const price = format(
      // eslint-disable-next-line no-nested-ternary
      product.embeddedPrice
        ? isWithTax
          ? product.embeddedPrice * (1 + product.vatrate / 100)
          : product.embeddedPrice
        : (isWithTax ? product.priceListPriceWithVat : null) ||
            product.priceListPrice ||
            product.price ||
            0,
    );

    const openProductView = (e: MouseEvent) => {
      e.stopPropagation();
      dispatch(openProductViewForItem({ productID: product.productID }));
    };

    const shouldTruncateProductName = useSelector(
      getSetting('truncate_product_search_results_texts'),
    );

    const classes = useSearchResultStyles();

    return (
      <PluginComponent hookname="UIProductResultRow">
        <TableRow
          data-testid="search-result-product"
          data-test-key={product.productID}
          key={product.productID}
          onClick={() => onSelect(product)}
          className={classes.resultRow}
          selected={selected}
          ref={ref}
        >
          <TableCell component="th" scope="product">
            <Typography
              data-testid="search-result-name"
              data-test-key={product.productID}
              style={{
                maxWidth: gridIsOpened ? '200px' : '400px',
                overflowWrap: 'break-word',
                hyphens: 'manual',
              }}
              noWrap={shouldTruncateProductName}
            >
              {product.name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              data-testid="search-result-code"
              data-test-key={product.productID}
            >
              {product.code}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              data-testid="search-result-price"
              data-test-key={product.productID}
            >
              {price}
            </Typography>
          </TableCell>
          {!isMobile && !gridIsOpened && (
            <TableCell>
              <Typography
                data-testid="search-result-stock"
                data-test-key={product.productID}
              >
                {itemsFree === Number.MAX_VALUE ? 0 : itemsFree}
              </Typography>
            </TableCell>
          )}
          <TableCell align="right" onClick={openProductView}>
            <SearchIcon />
          </TableCell>
        </TableRow>
      </PluginComponent>
    );
  }),
);

const ProductResultTable: FC<{ products: Product[]; close: () => void }> = ({
  products,
  close,
}) => {
  const { t } = useTranslation('search');
  const dispatch = useDispatch();
  const gridIsOpened = useSelector(getGridIsOpened);
  const isMobile = !useBreakpoints().mdplus;
  const ref = useRef<Array<HTMLTableRowElement | null>>([]);

  const shouldOpenProductView = !useSelector(
    getSetting('touchpos_disable_product_row_auto_open'),
  );

  const select = useCallback(
    (product: Product & { embeddedPrice?: number }) => {
      dispatch(
        addProduct(
          {
            price: product.embeddedPrice || undefined,
            productID: product.productID,
            amount: product.amount,
            manualDiscount: 0,
            needsWeightPopup: !product.amount,
          },
          {
            nextView: shouldOpenProductView
              ? {
                  component: mp.ProductOrderView,
                  groupID: 'PRODUCT_VIEW',
                }
              : undefined,
          },
        ),
      );
      close();
    },
    [shouldOpenProductView, close, dispatch],
  );

  const [selected, keyHandlers] = useMethods(
    n => ({
      ArrowUp: () => (n - 1 + products.length) % products.length,
      ArrowDown: () => (n + 1 + products.length) % products.length,
      PageUp: () => (n - 10 + products.length) % products.length,
      PageDown: () => (n + 10 + products.length) % products.length,
      Enter: () => {
        select(products[n]);
        return n;
      },
    }),
    0,
  );
  const onKeyDown = useCallback(
    (e: { preventDefault: () => void; key: string }) => {
      if (keyHandlers[e.key]) {
        keyHandlers[e.key]();
        e.preventDefault();
      }
    },
    [keyHandlers],
  );

  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    ref.current[selected]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [selected]);

  return (
    <Table
      stickyHeader
      data-testid="product-search-results"
      aria-label="product results"
      tabIndex={0}
      onKeyDown={onKeyDown}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
    >
      <TableHead data-testid="product-results-headers">
        <TableRow>
          {(!isMobile && !gridIsOpened
            ? ['name', 'code', 'price', 'stock']
            : ['name', 'code', 'price']
          )
            .concat(' ')
            .map(header => (
              <PluginComponent key={header} hookname="UIProductResultHeaderCell">
                <TableCell key={header}>
                  <Typography
                    variant="h6"
                    data-testid={`search-result-header-${header}`}
                    data-test-key={`search-result-header-${header}`}
                  >
                    {header !== ' ' ? t(`products.headers.${header}`) : header}
                  </Typography>
                </TableCell>
              </PluginComponent>
            ))}
        </TableRow>
      </TableHead>
      <TableBody data-testid="product-results-body">
        {products.length > 0 ? (
          products.map((product, i) => (
            <ProductResultRow
              key={product.productID}
              product={product}
              selected={hasFocus && selected === i}
              onSelect={select}
              ref={el => {
                ref.current[i] = el;
              }}
            />
          ))
        ) : (
          <TableRow>
            <TableCell>
              <Typography>{t('noResultsFor')}</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const ProductResults: FC<{
  close: () => void;
  products: (Product & { embeddedPrice?: number })[];
  loading: boolean;
}> = ({ close, products, loading }) => {
  return (
    <Loader show={loading} block style={{ maxHeight: 'inherit' }}>
      <TableContainer component={Paper} style={{ maxHeight: 'inherit' }}>
        <PluginComponent
          hookname="UIProductResultTable"
          props={{ products, close }}
        >
          <ProductResultTable products={products} close={close} />
        </PluginComponent>
      </TableContainer>
    </Loader>
  );
};

export default ProductResults;
