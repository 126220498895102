import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { ReasonCode } from 'types/ReasonCodes';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';

import UIButton from '../../../../../components/UIElements/UIButton';

type SelectReturnReasonsProps = {
  globalReturnReason: string | undefined;
  applyCodeChange: () => void;
  changeRowsReturnReason: (string) => void;
  globalReasonRule: string;
  setGlobalReasonRule: (string) => void;
};

const SetBulkReturnReasons: FC<SelectReturnReasonsProps> = ({
  globalReturnReason = '',
  changeRowsReturnReason,
  globalReasonRule,
  setGlobalReasonRule,
  applyCodeChange,
}) => {
  const { t } = useTranslation('return');
  const returnCodeReasons: ReasonCode[] = useSelector(
    getReasonCodes(REASONS.RETURN),
  );

  if (returnCodeReasons.length <= 0) {
    return null;
  }

  return (
    <Table className="set-bulk-return-reason">
      <tbody>
        <tr>
          <td className="align-middle">{t('options.setReason')}</td>
          <td className="align-middle">
            <Form.Control
              as="select"
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setGlobalReasonRule(e.target.value)
              }
              value={globalReasonRule}
            >
              <option value="unassigned">{t('options.forUnassigned')}</option>
              <option value="all">{t('options.forAll')}</option>
            </Form.Control>
          </td>
          <td className="align-middle">
            <Form.Control
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                changeRowsReturnReason(e.target.value)
              }
              as="select"
              value={globalReturnReason}
            >
              <option value="">{t('options.default')}</option>
              {returnCodeReasons.map(reason => (
                <option key={reason.reasonID} value={reason.reasonID}>
                  {reason.name}
                </option>
              ))}
            </Form.Control>
          </td>
          <td className="align-middle text-right">
            <UIButton text={t('options.apply')} action={applyCodeChange} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default SetBulkReturnReasons;
