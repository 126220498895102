import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import * as R from 'ramda';

import { miniUuid } from 'utils';

import { Answer, Question } from '../types';
import {
  deleteMemberOfDictAndUpdateOrderProp,
  dictToArrayByOrder,
  getHighestOrderPropValue,
  swapDictMembersByOrder,
} from '../utils';

const AddQuestionContent: FC<{
  questionRequired: Question['required'];
  questionContent: Question['content'];
  questionMultiOptions: Question['multiOptions'];
  setQuestionRequired: (q: Question['required']) => void;
  setQuestionContent: (q: Question['content']) => void;
  setQuestionMultiOptions: (q: Question['multiOptions']) => void;
}> = ({
  questionRequired,
  questionContent,
  questionMultiOptions,
  setQuestionRequired,
  setQuestionMultiOptions,
  setQuestionContent,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={questionRequired}
              onChange={e => setQuestionRequired(e.target.checked)}
            />
          }
          label="Answer is required"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={questionMultiOptions}
              onChange={e => setQuestionMultiOptions(e.target.checked)}
            />
          }
          label="The question allows multiple answers"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Question"
          multiline
          value={questionContent}
          onChange={e => setQuestionContent(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

const generateNewAnswer = (): Answer => ({
  id: miniUuid(),
  content: '',
  needsInput: false,
  order: 0,
});

const AddAnswers: FC<{
  answers: Question['answers'];
  setAnswers: (a: Question['answers']) => void;
}> = ({ answers, setAnswers }) => {
  const [newAnswer, setNewAnswer] = useState(generateNewAnswer());

  // Updates the order in case the item was moved while being edited
  useEffect(() => {
    const existingAnswer = answers[newAnswer.id];
    if (existingAnswer && existingAnswer.order !== newAnswer.order) {
      setNewAnswer(R.assoc('order', existingAnswer.order));
    }
  }, [answers, newAnswer.id, newAnswer.order]);

  // we need to update the order before we save the answer
  const saveNewAnswer = () => {
    // cannot edit properties of state without using state setter, so we use copy of the state
    const newAnswerCopy = { ...newAnswer };
    // only update the order if this is a new item - only new items should have default order value 0
    if (!answers[newAnswerCopy.id]?.order) {
      newAnswerCopy.order = getHighestOrderPropValue(answers) + 1;
    }
    setAnswers(R.assoc([newAnswer.id], newAnswerCopy, answers));
    // reset answer state to the default value
    setNewAnswer(generateNewAnswer());
  };

  // sets the selected answer in state so it can be edited
  const selectAnswer = (aid: Answer['id']) => setNewAnswer(answers[aid]);

  const deleteAnswer = (aid: Answer['id']) => {
    // deleting an answer also requires updating the order value for the rest of the answers
    // to keep the order fields in sequence
    setAnswers(deleteMemberOfDictAndUpdateOrderProp(aid, answers));
  };

  // moving answer requires the swap of order between 2 items
  const moveAnswer = (aid: Answer['id'], direction: 'up' | 'down') =>
    setAnswers(swapDictMembersByOrder(aid, answers, direction));

  // answers are sorted by their order prop, to be presented to the user the way they would be in the Customer Creation form
  const orderedAnswers = useMemo(() => dictToArrayByOrder(answers), [answers]);

  return (
    <Grid container spacing={1}>
      {orderedAnswers.map(a => (
        <>
          <Grid item key={a.id} xs={8} style={{ alignSelf: 'center' }}>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <span style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {a.content}
              </span>
              {a.needsInput && (
                <span
                  style={{
                    fontStyle: 'italic',
                    marginLeft: 'auto',
                    paddingLeft: '0.5em',
                  }}
                >
                  Requires input
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ alignSelf: 'center', textAlign: 'right' }}>
            <IconButton
              disabled={a.order <= 1}
              onClick={() => moveAnswer(a.id, 'up')}
            >
              <ArrowUpward />
            </IconButton>
            <IconButton
              disabled={a.order >= Object.keys(answers).length}
              onClick={() => moveAnswer(a.id, 'down')}
            >
              <ArrowDownward />
            </IconButton>
            <IconButton onClick={() => selectAnswer(a.id)}>
              <EditOutlined />
            </IconButton>
            <IconButton onClick={() => deleteAnswer(a.id)}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Grid>
        </>
      ))}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={newAnswer.needsInput}
              onChange={e =>
                setNewAnswer(R.assoc('needsInput', e.target.checked))
              }
            />
          }
          label="The Answer requires user input"
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'stretch',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label="Answer"
          multiline
          value={newAnswer.content}
          onChange={e => setNewAnswer(R.assoc('content', e.target.value))}
        />
        <Button
          disabled={!newAnswer.content.length}
          variant="outlined"
          onClick={saveNewAnswer}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

const alphaNumericDashUnderscoreRgx = /^[a-zA-Z0-9-_]+$/;

const AddAttributeName: FC<{
  attributeName: Question['attributeName'];
  setAttributeName: (attrName: Question['attributeName']) => void;
}> = ({ attributeName, setAttributeName }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Attribute"
          value={attributeName}
          onChange={e => setAttributeName(e.target.value)}
          helperText="Attribute name can only contain the following symbols: A-Z, a-z, 0-9, dash and underscore."
          error={!attributeName.match(alphaNumericDashUnderscoreRgx)}
        />
      </Grid>
    </Grid>
  );
};

const steps = ['Add question', 'Add answers', 'Add attribute name'];

const NewQuestionStepper: FC<{
  question: Question;
  updateQuestion: Dispatch<SetStateAction<Question | undefined>>;
  saveQuestion: () => void;
}> = ({ question, updateQuestion, saveQuestion }) => {
  // region New Question actions
  const updateQuestionRequired = (required: Question['required']) =>
    updateQuestion(R.assoc('required', required));

  const updateQuestionContent = (content: Question['content']) =>
    updateQuestion(R.assoc('content', content));

  const updateQuestionMultiOptions = (content: Question['multiOptions']) =>
    updateQuestion(R.assoc('multiOptions', content));

  const updateQuestionAnswers = (answers: Question['answers']) =>
    updateQuestion(R.assoc('answers', answers));

  const updateAttributeName = (attributeName: Question['attributeName']) =>
    updateQuestion(R.assoc('attributeName', attributeName));
  // endregion

  // region Navigate through stepper and its views
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const views = {
    0: (
      <AddQuestionContent
        questionRequired={question.required}
        questionContent={question.content}
        questionMultiOptions={question.multiOptions}
        setQuestionRequired={updateQuestionRequired}
        setQuestionMultiOptions={updateQuestionMultiOptions}
        setQuestionContent={updateQuestionContent}
      />
    ),
    1: (
      <AddAnswers
        answers={question.answers}
        setAnswers={updateQuestionAnswers}
      />
    ),
    2: (
      <AddAttributeName
        attributeName={question.attributeName}
        setAttributeName={updateAttributeName}
      />
    ),
  };

  const allStepsCompleted =
    // there is a question content
    question.content.length > 0 &&
    // there is at least 1 answer with content
    Object.values(question.answers).some(a => a.content.length > 0) &&
    // there is attributeName to be used to save the question
    question.attributeName.length > 0 &&
    question.attributeName.match(alphaNumericDashUnderscoreRgx);
  // endregion

  return (
    <Paper style={{ width: '100%', padding: '1em' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(label => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
            All steps completed - the new feedback question is ready to be saved
          </Typography>
          <Box
            style={{ display: 'flex', flexDirection: 'row', paddingTop: '1em' }}
          >
            <Box style={{ flex: '1 1 auto' }} />
            <Button
              onClick={() => updateQuestion(undefined)}
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box style={{ padding: '1em' }}>{views[activeStep]}</Box>
          <Box
            style={{ display: 'flex', flexDirection: 'row', paddingTop: '1em' }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              style={{ marginRight: 1 }}
            >
              Back
            </Button>
            <Box style={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              <Button
                disabled={!allStepsCompleted}
                onClick={saveQuestion}
                variant="outlined"
              >
                Finish
              </Button>
            ) : (
              <Button variant="outlined" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </>
      )}
    </Paper>
  );
};

export default NewQuestionStepper;
