import { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useDelphiCustomerDisplayData } from 'containers/App/hooks/useCustomerDisplayData';
import { urlEncode } from 'utils';
import { getDelphiCustomerDisplayConfig } from 'reducers/cafaConfigs';
import { addProduct } from 'actions/ShoppingCart/addProduct';


/**
 * websocket connection with http fallback
 */
const useSendToCombo = (url, onReceive) => {
  const isWebsocketUrl = /^ws/.test(url);
  const [conn, setConn] = useState();
  useEffect(() => {
    if (!conn || conn.URL !== url) {
      if (conn) conn.close();
      if (!url) return;
      if (!isWebsocketUrl) return;
      const sock = new window.WebSocket(url);
      sock.onopen = () => {
        sock.URL = url;
        setConn(sock);
        sock.onmessage = e => onReceive(JSON.parse(e.data));
      };
    }
  }, [conn, url]);
  const sendMessage = useMemo(
    () => data => {
      if (!url) return;
      if (isWebsocketUrl) {
        if (conn) {
          conn.send(JSON.stringify(data));
        }
      } else {
        window.fetch(
          `${url}?${urlEncode({
            action: data.action,
            request: JSON.stringify(data.request),
          })}`,
          {
            method: 'POST',
          },
        );
      }
    },
    [url, conn, isWebsocketUrl],
  );
  return sendMessage;
};
const useCustomerDisplaySettingsFromCafa = () =>
  useSelector(getDelphiCustomerDisplayConfig);

const useDelphiCustomerDisplay = () => {
  const dispatch = useDispatch();
  const handleMessage = useCallback(
    (data) => {
      const { action, request } = data
      switch (action) {
        case 'addProduct':
          dispatch(
            addProduct({ productID: request.productID, price: request.price }),
          );
          break;
        default:
          break;
      }
    },
    [dispatch, addProduct],
  );
  const cdData = useDelphiCustomerDisplayData();
  const settings = useCustomerDisplaySettingsFromCafa();
  const sendToCombo = useSendToCombo(
    settings.microServiceUrl,
    handleMessage,
  );

  useEffect(() => {
    if (!sendToCombo) return;
    if (!settings) return;
    sendToCombo({
      action: 'conf',
      request: settings,
    });
  }, [sendToCombo, settings]);

  useEffect(() => {
    if (!sendToCombo) return;
    if (!cdData) return;
    sendToCombo({
      action: 'cdisplay',
      request: cdData,
    });
  }, [sendToCombo, cdData]);
};

export default useDelphiCustomerDisplay;
