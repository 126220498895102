import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField, TextFieldProps } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';
import { AddressTypes } from 'types/Address';
import { getAddressTypes } from 'reducers/customerSearch';
import { reactcursively } from 'utils/react';
import { CompanyRecord } from 'plugins/estonianBusinessRegistry/constants';

import { useCompaniesAutocomplete } from './useCompaniesAutocomplete';

function matches(value) {
  return R.pathEq(['props', 'data-test-key'], value);
}

interface Props {
  value: string;
  loading: boolean;
  onChange(data: any): void;
  companies: CompanyRecord[];
  chosenCompany: CompanyRecord | undefined;
  chooseCompany(company: CompanyRecord | null): Promise<any>;
  inputProps: TextFieldProps;
  error?: boolean;
  helperText?: string;
}

function CompanyNameAutocomplete({
  value,
  loading,
  onChange,
  companies,
  chosenCompany,
  chooseCompany,
  inputProps,
  ...rest
}: Props) {
  return (
    <Autocomplete
      freeSolo
      loading={loading}
      options={companies}
      value={
        chosenCompany ??
        ({
          name: value,
        } as CompanyRecord) /* Fallback needed so that field value is not reset when opening edit form */
      }
      onChange={(_e, newValue, reason) => {
        if (typeof newValue !== 'string') chooseCompany(newValue);
        if (reason === 'clear')
          onChange(prev => ({ ...prev, companyName: '' }));
      }}
      inputValue={value}
      onInputChange={(_e, newValue) =>
        onChange(prev => ({ ...prev, companyName: newValue }))
      }
      getOptionLabel={company => company.name}
      renderInput={params => (
        <TextField {...params} {...inputProps} {...rest} />
      )}
    />
  );
}

export const UICustomerFormBeta: Required<PosPlugin>['UICustomerFormBeta'] = ({
  customerType,
  customer,
  children,
  onChange,
}) => {
  const addressTypes: AddressTypes[] = useSelector(getAddressTypes);

  // TODO: Refactor
  //   Either move into the component itself if these two are always meant to be used together
  //   Or split into smaller hooks that make sense on their own, rather than catering to a single component
  //   see longer comment in https://gl.nimi24.com/pos-refacto/pos-refactoring/-/merge_requests/3143#note_71848
  const {
    companies,
    chooseCompany,
    chosenCompany,
    loading,
  } = useCompaniesAutocomplete(customer, onChange, addressTypes);

  const disableIfCompany = chosenCompany
    ? R.assocPath(['props', 'disabled'], true)
    : R.identity;

  if (customerType !== 'COMPANY') return children;

  return reactcursively(
    R.pipe(
      R.when(matches('companyName'), textField => {
        return (
          <CompanyNameAutocomplete
            value={textField.props.value}
            loading={loading}
            onChange={onChange}
            companies={companies}
            chosenCompany={chosenCompany}
            chooseCompany={chooseCompany}
            inputProps={textField.props}
          />
        );
      }),
      R.when(matches('code'), disableIfCompany),
      R.when(matches('vatNumber'), disableIfCompany),
    ),
  )(children);
};
