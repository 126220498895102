import * as R from 'ramda';

import { suretaxReducer } from 'plugins/suretax/redux';
import { suretaxLog } from 'plugins/suretax/meta';
import { getSuretaxApi } from 'plugins/suretax/Configuration';
import { PluginError } from 'plugins/pluginUtils';

const log = suretaxLog.extend('onSaveSalesDocument.on');

/**
 * Repeat the latest suretax request using ReturnFileCode 0 (actual finalized sale, not just a quote)
 *
 * Attach the resulting TransID to the ERPLY sales document
 */
export const onSaveSalesDocument = (p, ap) => async (dispatch, getState) => {
  const api = getSuretaxApi(getState());
  const saveSalesRequest = R.find(R.propEq('requestName', 'saveSalesDocument'))(
    ap,
  );
  if (
    ['INVWAYBILL', 'CASHINVOICE', 'INVOICE', 'CREDITINVOICE'].includes(
      saveSalesRequest.type,
    )
  ) {
    try {
      const currentRequest = suretaxReducer.getCurrentRequest(getState());
      log('Read current request', currentRequest);
      if (!currentRequest)
        throw new Error(
          'SaveSalesDocument action with no suretax request to save',
        );

      const request = R.assoc('ReturnFileCode', '0', currentRequest);
      log('Updated ReturnFileCode to 0', request);
      const res = await api.makeRequest(request);
      log('Sent request and it was successful', res);

      log('Updating documents...', ap);
      const modifiedRequests = R.map(
        R.when(
          R.propEq('requestName', 'saveSalesDocument'),
          R.assocPath(['attributes', 'SuretaxTransID'], res.TransId),
        ),
      )(ap);

      log('Done', modifiedRequests);
      return modifiedRequests;
    } catch (error) {
      throw new PluginError(
        error.message,
        'Failed to perform Suretax tax calculation and finalize the transaction',
      );
    }
  }
  return ap;
};
