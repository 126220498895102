import Button from 'react-bootstrap/Button';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PosPlugin } from 'plugins/plugin';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { useMeasurement } from 'components/List';

import { getExchangeState, getTagsSlice, hasEnvProduct } from '../rdx';
import processPayment from '../rdx/actions';

const UICustomPayButton: PosPlugin['UICustomPayButton'] = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('gridButtons');

  const exchangeState = useSelector(getExchangeState);
  const { exchangeStep } = exchangeState;
  const returnTags = useSelector(state => getTagsSlice()(state)) as any;
  const productsInCart = useSelector(getProductsInShoppingCart);
  const hasEnvProductInCart = useSelector(hasEnvProduct);

  const classNameFor = n =>
    classNames('btn-long', 'button-item', {
      'btn-double': n === 2,
      'btn-triple': n === 3,
    });

  const { needsToMeasure, Measure, width } = useMeasurement({
    targetHeight: 1000,
    component: useCallback(() => <button className={classNameFor(1)} />, []),
  });
  if (needsToMeasure) return Measure;

  // width was 9 and the POS was crashing, default value was 8, changed to 7(arrays index from 0)
  const [w1, w2, w3, w4] = [
    [0, 0, 0, 0],
    [0, 0, 0, 1],
    [0, 0, 1, 1],
    [0, 1, 1, 1],
    [1, 1, 1, 1],
    [1, 1, 1, 2],
    [1, 1, 2, 2],
    [1, 1, 2, 3],
  ][width && width < 8 ? width : 7];

  const defineTextToShow = () => {
    if (productsInCart.length === 1 && hasEnvProductInCart) {
      return 'gridButtons:actions.pay';
    }
    if (exchangeStep === 1) {
      return 'gridButtons:actions.payExchange';
    }
    if (exchangeStep === 2) {
      return 'gridButtons:actions.saleExchange';
    }
    return 'gridButtons:actions.pay';
  };

  /* Always enable button when it's not step 1 (return step). During step 1, Enabled only when there are products in the cart, and all of them except env fee have tags */
  const disableButton = () => {
    if (exchangeStep === 1) {
      const tagsAssigned = Object.keys(returnTags);

      if (tagsAssigned.length === 0) {
        return true;
      }

      if (hasEnvProductInCart) {
        if (productsInCart.length - 1 === tagsAssigned.length) {
          return false;
        }
      }

      if (productsInCart.length !== tagsAssigned.length) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      {exchangeStep >= 1 ? (
        <Button
          className={`btn-long button-item btn-grid_green btn-${
            ['hidden', '', 'double', 'triple'][w4]
          }`}
          style={{ backgroundColor: '#37AF59' }}
          onClick={() => dispatch(processPayment())}
          data-testid="complete-exchange-btn"
          disabled={disableButton()}
        >
          <span
            style={{
              fontSize: '20px',
              textTransform: 'uppercase',
              fontWeight: 700,
            }}
          >
            {t(defineTextToShow())}
          </span>
        </Button>
      ) : (
        children
      )}
    </>
  );
};

export default UICustomPayButton;
