import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getIsPaymentWithCashOnly } from 'reducers/Payments';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import ListItem from 'components/ListItem';
import { useBreakpoints } from 'utils/hooks/UI';
import { addFullCashPayment } from 'actions/Payments';
import { getUISetting } from 'reducers/configs/settings';

const AddFullCashPayment = ({ disabled = false }) => {
  const { t } = useTranslation('payment');
  const isMobileView = !useBreakpoints().mdplus;
  const dispatch = useDispatch();

  const isPaymentWithCashOnly = useSelector(getIsPaymentWithCashOnly);

  const show = useSelector(getUISetting('touchposPaymentButtonsHighlight'));

  const action = () => {
    if (!disabled) {
      dispatch(addFullCashPayment());
    }
  };
  useShortcut('F2', action, 30);
  return isMobileView ? (
    <li
      className="payment-item full-cash-payment"
      style={{ cursor: 'pointer' }}
    >
      <div tabIndex={0} className="button" role="button" onClick={action}>
        <span className="title">{t('buttons.exactCash')}</span>
      </div>
    </li>
  ) : (
    <ListItem
      disabled={disabled}
      // @ts-ignore
      variant="grid_light"
      style={{
        backgroundColor: isPaymentWithCashOnly && show ? '#0394fc' : '',
      }}
      action={action}
      data-testid="full-cash-payment-button"
    >
      <span className="tip">100%</span>
      <span className="exact-cash-icon" />
      <span data-testid="full-cash-payment-shortcut" className="func-key">
        F2
      </span>
    </ListItem>
  );
};

export default AddFullCashPayment;
