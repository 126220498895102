import {
  getProductsInShoppingCart,
  getShoppingCartForSalesDocument,
  getTotal,
} from 'reducers/ShoppingCart';
import { getProductByID } from 'reducers/cachedItems/products';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getUserLoggedIn } from 'reducers/Login';
import { ErplyAttributes } from 'utils';
import { Override, promptForOverrides } from 'plugins/pnp/security/redux/base';
import { getUserGroups } from 'services/ErplyAPI';
import { addProgress, dismissType } from 'actions/Error';
import { calculate } from 'actions/ShoppingCart/calculate';
import { getCurrentSalesDocument } from 'reducers/sales';
import { getCurrencyFormatter } from 'reducers/configs/settings';
import { getPluginConfiguration } from 'reducers/Plugins';

import { Configuration } from '../PluginConf';

import pnpSecurityPlugin, { pluginID } from '..';

const checkBitPerm = perm => group => {
  const v = Number(
    new ErplyAttributes(group.attributes).get(
      `${pnpSecurityPlugin.id}_permission-${perm}`,
    ),
  );
  return !!v;
};
const checkGroupPerm = user => perm => {
  const v = new ErplyAttributes(user.attributes).get(
    `${pnpSecurityPlugin.id}_permission-${perm}`,
  );
  return v;
};

export const securityCheckpoints = {
  onOpenPayment: () => async (dispatch, getState) => {
    // region get data from redux
    const state = getState();
    const conf = getPluginConfiguration<Configuration>(pluginID)(state);

    const prods = getProductsInShoppingCart(state);
    const customer = getSelectedCustomer(state);
    const customerIsVolumeBuyer =
      String(customer.groupID) === String(conf.volumeBuyerCustomerGroupID);
    // endregion

    // region get data from api (discount and manual promotion differences)
    dispatch(addProgress('Checking for volume buyer', 'pnpSecurity'));
    if (customerIsVolumeBuyer) {
      await dispatch(calculate({ customerID: undefined }));
    }
    /** Only for volume buyers, for all other customers this is equal to the regular state */
    const nonVolumeBuyerState = getState();
    dispatch(dismissType('pnpSecurity'));
    dispatch(addProgress('Checking for discounts', 'pnpSecurity'));
    await dispatch(
      calculate({ manualPromotionIDs: '', couponIdentifiers: '' }),
    );
    const noDiscountsState = getState();
    await dispatch(calculate());
    dispatch(dismissType('pnpSecurity'));
    // endregion

    // region Collect override requirements
    const overrides: Override[] = [];
    const reasonsCollected: Record<string, string> = {};

    /* Exchange or return */
    if (prods.some(p => p.amount < 0)) {
      overrides.push({
        key: 'return',
        POSText: 'Sale is exchange or return',
        BOText: 'Sale is exchange or return',
        isValidOverride: checkBitPerm('return'),
        context: 'salesDoc',
      });
    }
    /* Cashier must enter price */
    const manualPriceProds = prods.filter(
      p => getProductByID(p.productID)(state).cashierMustEnterPrice,
    );
    if (manualPriceProds.length) {
      overrides.push({
        key: 'cashierMustEnterPrice',
        POSText: `Sale contains "cashier must enter price" products (${manualPriceProds
          .map(p => p.name)
          .join(', ')})`,
        BOText: `Sale contains "cashier must enter price" products (${manualPriceProds
          .map(p => p.name)
          .join(', ')})`,
        isValidOverride: checkBitPerm('cashierMustEnterPrice'),
        context: 'salesDoc',
      });
    }
    /* Customer has applied pricelist */
    if (getTotal(state) !== getTotal(nonVolumeBuyerState)) {
      const customer = getSelectedCustomer(getState());
      overrides.push({
        key: 'customerPricelist',
        POSText: `${customer.firstName} ${customer.lastName} is a volume buyer`,
        BOText: `${customer.firstName} ${customer.lastName} is a volume buyer`,
        isValidOverride: checkBitPerm('customerPricelist'),
        context: 'salesDoc',
      });
    }
    /* Customer is tax exempt */
    const salesDoc = getCurrentSalesDocument(state);
    const salesDocTaxExempt = !!salesDoc.taxExemptCertificateNumber;
    if (customer.taxExempt) {
      overrides.push({
        key: 'customerTaxExempt',
        POSText: `Customer ${customer.fullName} is tax exempt`,
        BOText: `Customer ${customer.fullName} is tax exempt`,
        isValidOverride: checkBitPerm('customerTaxExempt'),
        context: 'salesDoc',
      });
    } else if (salesDocTaxExempt) {
      overrides.push({
        key: 'customerTaxExempt',
        POSText: `Tax is exempt on sale`,
        BOText: `Tax is exempt on sale`,
        isValidOverride: checkBitPerm('customerTaxExempt'),
        context: 'salesDoc',
      });
    }
    /* Exceeded discount limit */
    // Discount is taken from every source that is manually triggered by the cashier
    // Discount: yes
    // Manual promotions: yes
    // Manually added coupons: yes
    // Automatic promotions: no
    // Customer price list: no, but volume buyer will apply
    const prodsFirst = getShoppingCartForSalesDocument(state);
    const prodsSecond = getShoppingCartForSalesDocument(noDiscountsState);
    const diffs = prodsFirst
      .map((_, i) => [prodsFirst[i], prodsSecond[i]])
      .map(
        ([first, second]) =>
          /* Manual discount +  discount from manually applied promotions */
          Number(first.manualDiscount) + (first.discount - second.discount),
      );

    const saleDC = Math.max(...diffs.map(d => Number(d)));
    const saleOC = -Math.min(...diffs.map(d => Number(d)));
    // prettier-ignore
    const reasonText = `${saleOC < saleDC ? "Discount" : "Overcharge"} on sale of ${saleOC < saleDC ? saleDC : saleOC}% exceeds user limit`;
    overrides.push({
      key: 'maxDiscount',
      POSText: reasonText,
      BOText: reasonText,
      isValidOverride: group =>
        Number(checkGroupPerm(group)('maxDiscount')) > Math.max(saleDC, saleOC),
      context: 'salesDoc',
    });
    // endregion

    return dispatch(promptForOverrides(overrides));
  },
  onClickLockedTender: () => async (dispatch, getState) => {
    const user = getUserLoggedIn(getState());
    const [group] = await getUserGroups({ userGroupID: user.groupID });
    // @ts-ignore
    if (
      Number(
        new ErplyAttributes(group.attributes).get(
          `${pnpSecurityPlugin.id}_permission-unlockTenders`,
        ),
      )
    ) {
      return;
    }
    await dispatch(
      promptForOverrides([
        {
          isValidOverride: ({ attributes }) =>
            !!Number(
              new ErplyAttributes(attributes).get(
                `${pnpSecurityPlugin.id}_permission-unlockTenders`,
              ),
            ),
          BOText: 'Unlock tenders',
          POSText: 'Unlock tenders',
          key: 'unlockTenders',
          context: 'salesDoc',
        },
      ]),
    );
  },
  onCloseDay: () => async (dispatch, getState) => {
    await dispatch(
      promptForOverrides([
        {
          isValidOverride: checkBitPerm('closeDrawer'),
          BOText: 'Closing the drawer',
          POSText: 'Closing the drawer',
          key: 'closeDrawer',
          context: 'dayOpening',
        },
      ]),
    );
  },
  onCashInout: ({ sum, comment }) => async (dispatch, getState) => {
    const format = getCurrencyFormatter(getState());
    const formattedSum = format(Math.abs(sum));
    const formattedComment = comment?.length ? `(comment=${comment})` : '';
    await dispatch(
      promptForOverrides([
        {
          isValidOverride: checkBitPerm('cashInOut'),
          BOText: sum > 0 ? 'Cash in' : 'Cash out',
          POSText:
            sum > 0
              ? `Cash in (${formattedSum}) ${formattedComment}`
              : `Cash out (${formattedSum}) ${formattedComment}`,
          key: 'cashInOut',
          context: 'cashInOut',
        },
      ]),
    );
  },
};
