import React from 'react';
import { insert, pipe } from 'ramda';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import Icon from 'components/Icon';
import { getClientCode } from 'reducers/Login';
import { HeaderIconButton } from 'containers/Header/components/Icon';

const serviceApp: PosPlugin = {
  id: 'service-app-plugin',
  name: 'Service App',
  keywords: ['service app', 'service', 'app'],
  info: 'Will enable service app link in header',
  ComponentHeader: ({ children }) => {
    const clientCode = useSelector(getClientCode);
    return pipe(
      React.Children.toArray,
      insert(
        2,
        <li>
          <HeaderIconButton
            size="small"
            onClick={() =>
              window.open(
                `https://service-app.erply.com/?auth=cookie&clientCode=${clientCode}`,
                '_blank',
              )
            }
          >
            <Icon name="vehicle" />
          </HeaderIconButton>
        </li>,
      ),
    )(children);
  },
};

export default serviceApp;
