import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import { withResponseErrors } from 'paymentIntegrations/pcEftpos/errors';
import { sanitizePrice } from 'actions/CreateNew';
import TestButtonBlock from 'components/UIElements/PaymentTestBlock';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import useErrorState, { translateError } from 'utils/hooks/useErrorState';
import { addError } from 'actions/Error';
import InputField from 'components/FieldTypes/InputField';

import { Ctx } from '../../../../components/CtxInputs';
import RenderFormItem from '../../../../components/FormFieldCtx';

import TerminalActivation from './TerminalActivation';

const pceftposFields = {
  // eftposIP: {
  //   id: 'eftposIP',
  //   type: 'text',
  //   validate: val =>
  //     val?.length ? null : { message: 'isMissing', dict: { field: 'IP' } },
  // },
  // eftposPort: {
  //   id: 'eftposPort',
  //   type: 'text',
  //   validate: val =>
  //     val?.length ? null : { message: 'isMissing', dict: { field: 'Port' } },
  // },
  tcpipDevicePort: {
    id: 'tcpipDevicePort',
    type: 'text',
    validate: val => {
      if (val === '') {
        return { message: 'isMissing', dict: { field: 'tcpipDevicePort' } };
      }
      return null;
    },
    formatter: v => {
      if (typeof v === 'string') return v.replace(/^0+|\D/g, '');
      if (typeof v === 'number' && !Number.isNaN(v)) return v;
      return '';
    },
  },
};

const inputClass = classNames([formStyles.formInput, formStyles.longTitle]);

const useStyles = makeStyles({
  'pceftpos-container': {
    '& .margin-bottom': {
      marginBottom: '1.5rem',
    },
  },
  title: {
    margin: '1.5rem 0',
  },
});

const PCEftposConfigurations = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest): string =>
    rawT(`integrations.pcEftpos.${text}`, ...rest);

  const styles = useStyles();

  const superContext = useContext(Ctx);

  const {
    configuration,
    setEnableUpdate,
    initialIntegrationState,
  } = superContext.values;
  const { onChange } = superContext;
  const [enableWarning, setEnableWarning] = useState(false);
  const [testingAmount, setTestingAmount] = useState('0.01');
  const [isOngoing, setIsOngoing] = useState(false);
  const [lastTransactionId, setLastTransactionId] = useState<string | null>('');
  const [status, setStatus] = useState({});
  const [lastTransactionAmount, setLastTransactionAmount] = useState<
    string | null
  >('');
  const setSuccess = key => setStatus({ ...status, [key]: 'Success' });
  const setFailure = key => setStatus({ ...status, [key]: 'Failure' });
  const [currentAction, setCurrentAction] = useState('');
  const dipsatch = useDispatch();
  const { errors, hasErrors } = useErrorState(configuration, pceftposFields);
  useEffect(() => setEnableUpdate(!hasErrors), [hasErrors, setEnableUpdate]);

  const initialDevicePort = initialIntegrationState?.tcpipDevicePort;
  const configDevicePort = configuration?.tcpipDevicePort;

  // Track for changes in tcpipDevicePort and activate the Alert to the user
  useEffect(() => {
    // if user changed the values of tcpipDevicePort
    // and the new value is not empty string enable Alert
    const shouldAlert =
      initialDevicePort !== configDevicePort && !!configDevicePort;

    setEnableWarning(shouldAlert);
  }, [initialDevicePort, configDevicePort]);

  const handleChange = useCallback(
    (key, value) => {
      onChange?.('configuration', {
        ...configuration,
        [key]: value,
      });
    },
    [onChange, configuration],
  );

  // Convert tcipIpDevicePort to number
  useEffect(() => {
    // Don't convert in-progress values
    if (configuration.tcpipDevicePort === '') return;
    // Don't convert if already converted
    if (typeof configuration.tcpipDevicePort === 'number') return;

    handleChange('tcpipDevicePort', Number(configuration.tcpipDevicePort));
  }, [configuration.tcpipDevicePort, handleChange, hasErrors]);

  const testButtons = [
    {
      variant: 'secondary',
      title: t('testConnection'),
      type: 'connection',
      info: t('testConnectionHint'),
      disabled: isOngoing || !(Number(testingAmount) > 0),
      action: () =>
        withResponseErrors({
          request: 'requestTestConnection',
          params: { configuration },
        }),
    },
    {
      variant: 'danger',
      title: t('pay', { testingAmount }),
      type: 'sale',
      info: t('payHint'),
      disabled: isOngoing || !(Number(testingAmount) > 0),
      action: () =>
        withResponseErrors({
          request: 'requestSale',
          params: { configuration, amount: testingAmount },
        }),
    },
    {
      variant: 'secondary',
      title: t('reversePrevTransaction'),
      type: 'reverse',
      info: t('reversePrevTransactionHint'),
      disabled: isOngoing || !(lastTransactionId && lastTransactionAmount),
      action: () =>
        withResponseErrors({
          request: 'requestVoid',
          params: {
            amount: lastTransactionAmount,
            referenceNumber: lastTransactionId,
            configuration,
          },
        }),
    },
  ];
  const onClickHandler = ({ type, action }) => {
    setIsOngoing(true);
    setCurrentAction(type);
    action()
      .then(({ data }) => {
        if (type === 'sale') {
          setLastTransactionId(data.records[0].referenceNumber);
          setLastTransactionAmount(data.records[0].approvedAmount);
        }
        if (['return', 'reverse'].includes(type)) {
          setLastTransactionId(null);
          setLastTransactionAmount(null);
        }
        if (type === 'connection' && !Object.keys(data).length) {
          setIsOngoing(false);
          setFailure(type);
          return;
        }
        setIsOngoing(false);
        setSuccess(type);
      })
      .catch(err => {
        console.error('Error in PCEftpos payment integration', err);
        setIsOngoing(false);
        setFailure(type);
        dipsatch(addError(err.message));
      });
  };
  const returnTestButtons = testButtons.map(b => (
    <TestButtonBlock
      key={b.type}
      clickHandler={() => onClickHandler({ action: b.action, type: b.type })}
      button={b}
      processing={isOngoing && b.type === currentAction}
      status={status[b.type]}
    />
  ));

  return (
    <div className={styles['pceftpos-container']}>
      <h2 className={styles.title}>{t('title')}</h2>
      <Ctx.Provider
        value={{
          values: configuration,
          onChange: handleChange,
        }}
      >
        {Object.values(pceftposFields).map(({ ...rest }) => (
          <RenderFormItem
            key={rest.id}
            title={t(rest.id)}
            name={rest.id}
            error={translateError(errors[rest.id], t)}
            {...rest}
          />
        ))}
        {enableWarning && (
          <Alert variant="warning" className="margin-bottom">
            {t('tcpipDevicePortChangedWarning')}
          </Alert>
        )}
        <TerminalActivation />
        <div>
          <div className="margin-bottom">
            <span>
              {isOngoing
                ? t('testButtonStatus', {
                    currentAction: t(`actionTypes.${currentAction}`),
                  })
                : t('testButtonInfo')}
            </span>
          </div>
          <InputField
            type="text"
            title={t('testingAmount')}
            value={testingAmount}
            onChange={e => setTestingAmount(sanitizePrice(e.target.value))}
            size="lg"
            className={inputClass}
          />
          {returnTestButtons}
        </div>
      </Ctx.Provider>
    </div>
  );
};

export default PCEftposConfigurations;
