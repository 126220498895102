import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { getSetting } from 'reducers/configs/settings';
import InputField from 'components/FieldTypes/InputField';
import { getCurrentSalesDocument } from 'reducers/sales';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

const LabelledInput = ({ label, value, setter, focus }) => (
  <>
    <label>{label}</label>
    <InputField
      autoFocus={focus}
      onChange={e => setter(e.target.value)}
      value={value}
      size="lg"
    />
    <br />
  </>
);

const CustomNotes = ({ resolve }) => {
  const { notes: currentNotes = '' } = useSelector(getCurrentSalesDocument);
  const title1 = useSelector(getSetting('sale_extra_note1'));
  const title2 = useSelector(getSetting('sale_extra_note2'));
  const title3 = useSelector(getSetting('sale_extra_note3'));
  const required1 = useSelector(getSetting('sale_extra_note1_required'));
  const required2 = useSelector(getSetting('sale_extra_note2_required'));
  const required3 = useSelector(getSetting('sale_extra_note3_required'));

  const [note1, setnote1] = useState('');
  const [note2, setnote2] = useState('');
  const [note3, setnote3] = useState('');

  const requiredMissing =
    (required1 && !note1.length) ||
    (required2 && !note2.length) ||
    (required3 && !note3.length);

  const dispatch = useDispatch();
  const onDone = () => {
    if (requiredMissing) {
      return;
    }
    dispatch(previousModalPage());
    resolve(
      [
        currentNotes,
        note1 && `${title1}: ${note1}`,
        note2 && `${title2}: ${note2}`,
        note3 && `${title3}: ${note3}`,
      ]
        .filter(a => a)
        .join('\n'),
    );
  };
  if (!(title1 || title2 || title3)) {
    onDone();
  }
  useShortcut('Enter', requiredMissing ? null : onDone, 20);
  const { t } = useTranslation('saleNotes');
  return (
    <>
      <Modal.Header>
        <h3 style={{ fontWeight: 'bold' }}>{t('prePayment.title')}</h3>
        <span style={{ flexGrow: 1 }} />
        <UIButton
          shortCut='Enter'
          text={t('common:done')}
          disabled={requiredMissing}
          action={onDone}
          variant="POS"
        />
      </Modal.Header>
      <Modal.Body>
        {title1 && (
          <LabelledInput
            label={`${title1}${required1 ? '*' : ''}`}
            focus={true}
            value={note1}
            setter={setnote1}
          />
        )}
        {title2 && (
          <LabelledInput
            focus={!title1}
            label={`${title2}${required2 ? '*' : ''}`}
            value={note2}
            setter={setnote2}
          />
        )}
        {title3 && (
          <LabelledInput
            focus={!title1 && !title2}
            label={`${title3}${required3 ? '*' : ''}`}
            value={note3}
            setter={setnote3}
          />
        )}
      </Modal.Body>
    </>
  );
};

export default CustomNotes;
