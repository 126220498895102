import React, { useEffect } from 'react';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { getClientCode } from 'reducers/Login';
import { saveCustomer } from 'services/ErplyAPI';
import {
  getDefaultCustomerGroup,
  getSearchCustomerGroups,
} from 'reducers/customerSearch';

import {
  disableField,
  insertAfter,
  matches,
  notMatches,
  renameField,
} from '../utils';

export const UICustomerForm: PosPlugin['UICustomerForm'] = ({
  value,
  onChange,
  children,
}) => {
  const sampleField = React.Children.toArray(children).find((c: any) =>
    /firstName/.test(c?.key),
  );
  const customerGroups = useSelector(getSearchCustomerGroups);
  const defaultCustomerGroup = useSelector(getDefaultCustomerGroup);
  const defaultGroupID = (defaultCustomerGroup ?? customerGroups?.[0])
    ?.customerGroupID;

  // Since group field is locked, ensure it has a legal value
  useEffect(() => {
    if (!value.groupID) {
      onChange(R.assoc('groupID', defaultGroupID));
    }
  }, [value.groupID, defaultGroupID, onChange]);
  // Since birthday field is hidden, ensure it has a legal value
  useEffect(() => {
    const [y, m, d] = value.birthday?.split('-') ?? [];
    if (![y, m, d].every(Number)) {
      onChange(R.assoc('birthday', '1970-01-01'));
    }
  }, [onChange, value.birthday]);
  // Since euCustomerType field is hidden, ensure it has a legal value
  useEffect(() => {
    if (!value.euCustomerType) {
      onChange(R.assoc('euCustomerType', 'DOMESTIC'));
    }
  }, [onChange, value.euCustomerType]);
  // Since gender field is hidden, ensure it has a legal value
  useEffect(() => {
    if (!value.gender) {
      onChange(R.assoc('gender', 'UNIDENTIFIED'));
    }
  }, [onChange, value.gender]);
  // On creating a new customer, assign the customerCardNumber to be equal to DX, where X is a random 5-digit long number
  useEffect(() => {
    if (!value.customerID) {
      onChange(
        R.assoc(
          'customerCardNumber',
          `D${Math.floor(Math.random() * 90000) + 10000}`,
        ),
      );
    }
  }, [onChange, value.customerID]);
  // Form updates
  return R.pipe(
    R.map(
      R.pipe(
        // Disable fields groupID and customerCardNumber
        R.when(matches(/customerCardNumber/), disableField),
        R.when(matches(/groupID/), disableField),
        R.when(matches(/customerCardNumber/), renameField('Card number')),
      ),
    ),
    // Remove fields birthday, euCustomerType, gender and id code
    R.filter(notMatches(/birthday|euCustomerType|gender|code/)),
    // Add birth note field after the groupID field
    insertAfter(
      /customerCardNumber/,
      R.mergeDeepRight(sampleField ?? <div>Sample field not found</div>)({
        key: 'householdCode',
        props: {
          title: 'Birth note',
          'data-testid': 'householdCode',
          field: {
            key: 'householdCode',
            validate: undefined,
          },
        },
      }),
    ),
    // @ts-ignore
  )(React.Children.toArray(children));
};

export const onSaveCustomer: Required<
  Required<PosPlugin>['onSaveCustomer']
>['on'] = (p, ap) => async () => {
  return R.pipe(
    R.dissocPath(['attributes', 'wbuBirth']),
    R.assoc('householdCode', ap.attributes.wbuBirth),
  )(ap) as typeof ap;
};
export const updateCustomerCardNumber: Required<
  Required<PosPlugin>['onSaveCustomer']
>['after'] = (p, customerID) => async (
  dispatch: ThunkDispatch<any, any, Action>,
  getState,
) => {
  const clientCode = getClientCode(getState());
  const customerNr = customerID.toString().padStart(7, '0');
  await saveCustomer({
    customerID,
    customerCardNumber: `${clientCode}-${customerNr}`,
  });
};
