import { createSelector } from 'reselect';

import { TYPE_LOGOUT } from 'constants/Login';
import { Alert } from 'types/Alerts';

import {
  TYPE_ADDERROR,
  TYPE_DISMISS,
  TYPE_DISMISS_TYPE,
  TYPE_DISMISS_ALL,
  TYPE_SET_UNREAD,
  TYPE_READ_ALL,
} from '../constants/Error';

function notType(name) {
  return item => item.name !== name || name === undefined;
}

const sesStAl = sessionStorage.getItem('alerts');
// If there are alerts in session storage, use those as initState
const initialState: Alert[] = sesStAl ? JSON.parse(sesStAl) : [];

export default function reducer(state = initialState, { type, payload }) {
  let newState = Array.from(state);
  switch (type) {
    case TYPE_DISMISS:
      if (payload) {
        const i = newState.findIndex(er => er.id === payload);
        // If such index does exist, dismiss the error
        if (i !== -1) newState[i] = { ...newState[i], dismissed: true };
      } else {
        newState[newState.length - 1] = {
          ...newState[newState.length - 1],
          dismissed: true,
        };
      }
      break;
    case TYPE_ADDERROR: {
      const prevIdx = newState.length - 1;
      const prevError = newState[prevIdx];
      if (prevError) {
        const { selfDismiss, dismissed } = prevError;
        // If previous error should be dismissed at a later point in time,
        // manually mark it as dismissed and set to unread
        if (selfDismiss && !dismissed) {
          newState[prevIdx] = {
            ...prevError,
            dismissed: true,
            unread: true,
          };
        }
      }
      newState = [...newState, payload];
      sessionStorage.setItem('latestAlertId', payload.id);
      break;
    }
    case TYPE_DISMISS_TYPE:
      newState = newState.map(er =>
        notType(payload)(er) ? er : { ...er, dismissed: true },
      );
      break;
    case TYPE_DISMISS_ALL:
      newState = newState.map(err => ({ ...err, dismissed: true }));
      break;
    case TYPE_SET_UNREAD:
      newState = newState.map(err => {
        if (err.id === payload.id) {
          return { ...err, unread: payload.unread };
        }
        return err;
      });
      break;
    case TYPE_LOGOUT:
      newState = [];
      sessionStorage.setItem('latestAlertId', '0');
      break;
    case TYPE_READ_ALL:
      newState = newState.map(e => ({ ...e, unread: false }));
      break;
    default:
      break;
  }
  sessionStorage.setItem('alerts', JSON.stringify(newState.slice(-200)));
  return newState.slice(-200);
}

export function getCurrentErrors(state): Alert[] {
  return state.Error;
}
export const getUnreadErrors = createSelector(getCurrentErrors, errors =>
  errors.filter(err => err.unread),
);
export const getErrorsToShow = createSelector(getCurrentErrors, errors =>
  errors.filter(err => !err.dismissed),
);
export function getCurrentError(state) {
  return state.Error[0];
}
export function getHasUndismissedErrorsOfType(name) {
  return state => state.Error.some(err => err.name === name && !err.dismissed);
}
