import React from 'react';
import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';
import classNames from 'classnames';

import { useGridButtonStyles } from './GridButtonSizeProvider';

type GridButtonProps = ButtonBaseProps & {
  btnColor?: keyof Omit<
    Palette,
    | 'type'
    | 'common'
    | 'contrastThreshold'
    | 'tonalOffset'
    | 'grey'
    | 'divider'
    | 'action'
    | 'background'
    | 'text'
    | 'getContrastText'
    | 'augmentColor'
  >;
  customColor?: boolean;
};

function GridButton({
  btnColor,
  className,
  customColor = false,
  ...rest
}: GridButtonProps) {
  const { classes } = useGridButtonStyles();

  return (
    <ButtonBase
      disableRipple
      disableTouchRipple
      className={classNames(
        classes?.gridButton,
        classes?.[`gridButton-${btnColor}`],
        { [classes?.['gridButton-custom'] ?? '']: customColor },
        className,
      )}
      {...rest}
    />
  );
}

export default React.memo(GridButton);
