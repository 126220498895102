import React from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

import { getExchangeState } from '../rdx';

const UIProductAmountInProductOrderEdit: PosPlugin['UIProductAmountInProductOrderEdit'] = ({
  children,
}) => {
  const exchangeState = useSelector(getExchangeState);
  return <>{exchangeState.exchangeStep > 0 ? <></> : children}</>;
};

export default UIProductAmountInProductOrderEdit;
