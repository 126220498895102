import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import uuidv4 from 'uuid/v4';
import { Modal } from 'react-bootstrap';

import { addError } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getLastProductIndex } from 'reducers/ShoppingCart';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { getPluginConfiguration } from 'reducers/Plugins';
import { RootState } from 'reducers';

import { addViiProductData, getViiProducts } from '../redux/actions';
import { pluginID } from '../constants';
import { Configuration } from '../types';

const ViiGiftCardPurchaseOnReturnModal = ({
  closeModal,
  card,
  lockAmount,
  product: viiProduct,
}) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const config = useSelector(getPluginConfiguration<Configuration>(pluginID));
  const latestOrderIndex = useSelector(getLastProductIndex);
  const viiGiftCardProducts = useSelector(getViiProducts);

  const [processing, setProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState<string>(card.number ?? '');
  const [inputAmount, setInputAmount] = useState(card.amount ?? '');
  const withProcessing = (action: () => Promise<void>) => () => {
    setProcessing(true);
    if (processing) return;
    action()
      .catch(e => dispatch(addError(e.message)))
      .finally(() => setProcessing(false));
  };

  const handleSearch = async () => {
    // If plugin not configured yet this menu is somehow opened, throw error
    if (!config)
      throw new Error(
        'Vii plugin not configured. Please configure from plugins menu!',
      );
    if (!config.giftCardCode)
      throw new Error(
        'Vii plugin lacks gift card configuration. Please configure from plugins menu!',
      );
    // If the gift card product is not yet fetched, then throw error.
    if (!viiProduct) {
      throw new Error(
        `Gift card with configured code ${config.giftCardCode} not found!`,
      );
    }
    // Block duplicate card from being added
    if (
      Object.values(viiGiftCardProducts).some(
        vgcp => vgcp.CardNumber === cardNumber,
      )
    ) {
      throw new Error('This gift card has already been added to cart!');
    }
    // Block card addition if amount is not supported
    if (Number(inputAmount) < 20 || Number(inputAmount) > 999) {
      throw new Error('Amount must be between 20 and 999 (inclusive)');
    }
    closeModal();
    const idToUse = uuidv4();
    await dispatch(
      addProduct({
        productID: viiProduct.productID,
        price: inputAmount,
        // @ts-ignore
        amount: 1,
      }),
    );
    dispatch(
      addViiProductData({
        orderIndex: Number(latestOrderIndex ?? 0) + 1,
        vii: {
          CardNumber: cardNumber,
          Amount: inputAmount,
          ExternalReference: idToUse,
          viiTranId: idToUse,
        },
      }),
    );
    // Dispatch addProduct as well
  };
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {`Enter ${viiProduct?.name ?? 'Vii Gift Card'} number`}
        </Modal.Title>
        <Button
          disabled={processing || !cardNumber || !inputAmount}
          onClick={withProcessing(handleSearch)}
          variant="contained"
          color="secondary"
          style={{ position: 'relative' }}
        >
          {processing && (
            <LinearProgress
              variant="indeterminate"
              style={{ position: 'absolute', inset: 0 }}
            />
          )}
          Sell
        </Button>
        <CloseButton action={() => dispatch(previousModalPage())} />
      </Modal.Header>
      <Modal.Body>
        <Grid container>
          <TextField
            fullWidth
            variant="outlined"
            inputRef={inputRef}
            autoFocus={true}
            value={cardNumber}
            onChange={e => setCardNumber(e.target.value)}
            error={!cardNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '20%' }}>
                  CODE
                </InputAdornment>
              ),
            }}
            style={{
              marginBottom: '20px',
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            value={inputAmount}
            onChange={e => setInputAmount(e.target.value)}
            error={!inputAmount}
            disabled={lockAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '20%' }}>
                  SUM
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Modal.Body>
    </>
  );
};

export default ViiGiftCardPurchaseOnReturnModal;
