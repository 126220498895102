import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputField from 'components/FieldTypes/InputField';
import { PosPlugin } from 'plugins/plugin';
import { getCustomerGroups } from 'reducers/customerSearch';
import { CustomerGroup } from 'types/CustomerGroup';
import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

export type Configuration = {
  defaultCustomerGroup: CustomerGroup;
  defaultCompanyGroup: CustomerGroup;
};

export const ComponentConfiguration: PosPlugin<
  Configuration
>['ComponentConfiguration'] = ({ current, save }) => {
  const groups = useSelector(getCustomerGroups) as CustomerGroup[];

  const [chosenGroups, setChosenGroups] = useState({
    defaultCustomerGroup: current?.defaultCustomerGroup ?? groups[0],
    defaultCompanyGroup: current?.defaultCompanyGroup ?? groups[0],
  });

  const { defaultCompanyGroup, defaultCustomerGroup } = chosenGroups;

  const dispatch = useDispatch();

  const options = groups.map(group => group.name);

  const handleSave = () => {
    save({ defaultCustomerGroup, defaultCompanyGroup });
    dispatch(previousModalPage());
  };

  const onChange = e => {
    e.persist();
    setChosenGroups(prevState => ({
      ...prevState,
      [e.target.name]: groups.find(group => group.name === e.target.value),
    }));
  };

  return (
    <>
      <UIButton variant="POS" action={() => handleSave()} text="SAVE" />
      <InputField
        type="select"
        value={defaultCustomerGroup.name}
        className="mt-2"
        options={options}
        title="Person"
        name="defaultCustomerGroup"
        onChange={onChange}
      />
      <InputField
        type="select"
        value={defaultCompanyGroup.name}
        options={options}
        title="Company"
        name="defaultCompanyGroup"
        className="mt-2"
        onChange={onChange}
      />
    </>
  );
};
