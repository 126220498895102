import React, { FC, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Tab, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getProductGroupsTreeOrdered } from 'reducers/productGroupsDB';
import { getVatRates, getActiveVatRates } from 'reducers/vatRatesDB';
import { notUndefinedOrNull } from 'utils';
import { sanitizePrice } from 'actions/CreateNew';

import { ProductCreation } from './ProductCreation';
import { CustomerCreation } from './CustomerCreation';
import { CompanyCreation } from './CompanyCreation';

interface TabPanelProps {
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
};

const ScannerResultsCreation: FC<{
  code: string;
  canAddCustomer: boolean;
  canAddProduct: boolean;
}> = ({ code, canAddCustomer, canAddProduct }) => {
  const { t } = useTranslation('scanSearchResults');
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(canAddProduct ? 0 : 1);
  const productGroups = useSelector(getProductGroupsTreeOrdered);
  const vatRates = useSelector(getVatRates).sort(
    (a, b) => Number(a.id) - Number(b.id),
  );
  const fallbackActiveRate = vatRates?.[0] || {};
  const activeVatRates = useSelector(getActiveVatRates);
  const [customerData, setCustomerData] = useState({
    code,
    lastName: '',
    firstName: '',
  });
  const [companyData, setCompanyData] = useState({
    companyName: '',
    code,
  });
  const allRates = useMemo(
    () =>
      (activeVatRates.length ? activeVatRates : [fallbackActiveRate])
        .filter(notUndefinedOrNull)
        .map(item => ({
          name: item.name,
          value: item.id,
        })),
    [activeVatRates, notUndefinedOrNull, fallbackActiveRate],
  );

  const [productData, setProductData] = useState({
    code,
    name: '',
    groupID: productGroups?.[0]?.value || 0,
    netPrice: 0.0,
    priceWithVAT: 0.0,
    vatrateID:
      (activeVatRates.length ? activeVatRates[0].id : fallbackActiveRate.id) ??
      0,
  });

  const handleCustomerDataChange = (key, value) => {
    setCustomerData({ ...customerData, [key]: value });
  };
  const handleCompanyDataChange = (key, value) => {
    setCompanyData({ ...companyData, [key]: value });
  };
  const handleProductDataChange = (key, value) => {
    switch (key) {
      case 'netPrice': {
        const vatrate = vatRates.find(
          rate => rate.id === productData.vatrateID,
        ) || { rate: 0 };
        const priceWithVAT =
          Number(value) + (Number(value) * Number(vatrate.rate)) / 100;
        const newPrices = { netPrice: sanitizePrice(value), priceWithVAT };
        setProductData({
          ...productData,
          ...newPrices,
        });
        break;
      }
      case 'priceWithVAT': {
        const vatrate = vatRates.find(
          rate => rate.id === productData.vatrateID,
        ) || { rate: 0 };
        const netPrice = Number(value) / (1 + Number(vatrate.rate) / 100);
        const newPrices = { netPrice, priceWithVAT: sanitizePrice(value) };
        setProductData({
          ...productData,
          ...newPrices,
        });
        break;
      }
      case 'vatrateID': {
        const { netPrice } = productData;
        const vatrateID = value;
        const vatrate = vatRates.find(rate => rate.id === vatrateID) || {
          rate: 0,
        };
        const priceWithVAT =
          Number(netPrice) + (Number(netPrice) * Number(vatrate.rate)) / 100;
        const values = { vatrateID, priceWithVAT };
        setProductData({
          ...productData,
          ...values,
        });
        break;
      }
      default:
        setProductData({ ...productData, [key]: value });
        break;
    }
  };

  const handleChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setActiveTab(newValue);
  };

  const close = () => {
    dispatch(closeModalPage());
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t('scannerNoResults.header')}</Modal.Title>
        <CloseButton action={() => close()} />
      </Modal.Header>
      <Modal.Body>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="scanning results"
        >
          {canAddProduct && (
            <Tab
              value={0}
              style={{ outline: 'none' }}
              label={t('labels.product')}
              id="scanner-creation-product"
            />
          )}
          {canAddCustomer && (
            <Tab
              value={1}
              style={{ outline: 'none' }}
              label={t('labels.customer')}
              id="scanner-creation-customer"
            />
          )}
          {canAddCustomer && (
            <Tab
              value={2}
              style={{ outline: 'none' }}
              label={t('labels.company')}
              id="scanner-creation-company"
            />
          )}
        </Tabs>
        {canAddProduct && (
          <TabPanel value={activeTab} index={0}>
            <ProductCreation
              onChange={handleProductDataChange}
              productData={productData}
              productGroupsSelection={productGroups}
              vatRates={vatRates}
              vatRatesSelection={allRates}
            />
          </TabPanel>
        )}
        {canAddCustomer && (
          <TabPanel value={activeTab} index={1}>
            <CustomerCreation
              onChange={handleCustomerDataChange}
              customerData={customerData}
            />
          </TabPanel>
        )}
        {canAddCustomer && (
          <TabPanel value={activeTab} index={2}>
            <CompanyCreation
              onChange={handleCompanyDataChange}
              companyData={companyData}
            />
          </TabPanel>
        )}
      </Modal.Body>
    </>
  );
};

export default ScannerResultsCreation;
