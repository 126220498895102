import React, { useState } from 'react';
import { Box, Divider, Step, StepButton, Stepper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { withProgressAlert } from 'actions/actionUtils';
import { addError, addSuccess } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { PosPlugin } from 'plugins/plugin';

import { API } from '../API/API';

import { useSpecialOrderState } from './CreateSpecialOrderModal/useSpecialOrderState';
import { ConfirmTransferOrders } from './CreateSpecialOrderModal/ConfirmTransferOrders';
import { CreateTransferOrders } from './CreateSpecialOrderModal/CreateTransferOrders';

type SavedDocument = Parameters<
  Required<Required<PosPlugin>['onSaveSalesDocument']>['after']
>[1]['salesDocument']

const saveTransferOrders = withProgressAlert('Saving transfer order(s)')(
  (
    document: SavedDocument,
    transfers: Parameters<typeof API.saveInventoryTransfer>[0],
    links: { stableRowID: string; amount: number }[][],
  ) => async (dispatch, getState) => {
    // Save sales orders
    const documentIDs = await Promise.all<number>(
      transfers.map(params =>
        API.saveInventoryTransfer(params).then(res => res.inventoryTransferID),
      ),
    );
    // Then fetch them to get the stableRowIDs
    const documents = await API.getInventoryTransfers(documentIDs);
    // to save the links
    return API.saveLinks(
      documents.flatMap((doc, docIndex) =>
        doc.rows.map((row, rowIndex) => {
          const link = links[docIndex][rowIndex];
          return {
            linkedQuantity: Number(link.amount),
            salesDocID: Number(document.invoiceID),
            salesRowID: Number(link.stableRowID),
            transferDocID: Number(doc.inventoryTransferID),
            transferRowID: Number(row.stableRowID),
          };
        }),
      ),
    );
  },
);

/**
 * Custom modal page for creating a new special order
 *
 * Requires an existing document to be passed in. Will then prompt the user to
 * select source warehouses and fill out the quantity of each product to order from each warehouse.
 * To submit, user must assign quantities to cover all the exceeded stock, without exceeding the stock of any of the source warehouses
 *
 * Can not be closed, creating the stock transfers is mandatory (unless they refresh ofc)
 *
 */
export const CreateSpecialOrderModal = {
  id: 'specialOrders-createSpecialOrder',
  Component: ({ salesDocument }: { salesDocument: SavedDocument }) => {
    const dispatch: ThunkDispatch<any, any, Action> = useDispatch();
    const state = useSpecialOrderState(salesDocument.rows);
    const [step, setStep] = useState(0);

    return (
      <Box padding={2}>
        <h2>
          Create transfer order(s) for sales order {salesDocument.invoiceNo}
        </h2>
        <Divider />

        <Stepper activeStep={step} orientation="horizontal">
          <Step key={0}>
            <StepButton onClick={() => setStep(0)}>
              Select warehouses and products
            </StepButton>
          </Step>
          <Step key={1}>
            <StepButton onClick={() => setStep(1)} disabled={!!state.error}>
              Confirm transfer order(s)
            </StepButton>
          </Step>
        </Stepper>
        <Box margin={2} />
        {step === 0 && (
          <CreateTransferOrders
            specialOrderState={state}
            salesDocument={salesDocument}
            onConfirm={() => setStep(1)}
          />
        )}
        {step === 1 && (
          <ConfirmTransferOrders
            specialOrderState={state}
            salesDocument={salesDocument}
            onBack={() => setStep(0)}
            onConfirm={(salesDocument, transferOrders, links) =>
              dispatch(
                saveTransferOrders(salesDocument, transferOrders, links),
              ).then(
                () => {
                  dispatch(addSuccess('Transfer order(s) saved successfully'));
                  dispatch(previousModalPage());
                },
                e => {
                  console.error(e);
                  dispatch(addError(e.message));
                },
              )
            }
          />
        )}
      </Box>
    );
  },
};
