import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';

import { getEmployeeID } from 'reducers/Payments';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { PosPlugin } from 'plugins/plugin';

import { API } from '../../API/API';
import { WarehouseName } from '../../utils/WarehouseName';
import { ProductName } from '../../utils/ProductUtils';

import { useSpecialOrderState } from './useSpecialOrderState';

const flattenForApi = (objs: object[]): object => {
  const out = {};
  objs.forEach((obj, index) => {
    Object.entries(obj).forEach(([k, v]) => {
      out[`${k}${index}`] = v;
    });
  });
  return out;
};

type TransferLink = { stableRowID: string; amount: number };
type TransferDraft = Parameters<typeof API.saveInventoryTransfer>[0];
type SavedDocument = Parameters<
  Required<Required<PosPlugin>['onSaveSalesDocument']>['after']
>[1]['salesDocument'];
/**
 * The "confirm" page for creating special orders. Lists the orders that will be created (by source warehouse) in nice human-readable text
 * On confirm, creates the transfer orders and links and closes the modalpage
 */
export const ConfirmTransferOrders = ({
  salesDocument,
  specialOrderState: {
    getValue,
    warehouses: { value: warehouses },
  },
  onBack,
  onConfirm,
}: {
  salesDocument: SavedDocument;
  specialOrderState: ReturnType<typeof useSpecialOrderState>;
  onBack: () => void;
  onConfirm: (
    salesDocument: SavedDocument,
    transferOrders: TransferDraft[],
    links: TransferLink[][],
  ) => void;
}) => {
  const transferOrderBaseParams = {
    creatorID: useSelector(getEmployeeID),
    warehouseToID: useSelector(getSelectedWarehouseID),
    type: 'TRANSFER_ORDER',
    confirmed: 1,
  } as const;
  const links: { stableRowID: string; amount: number }[][] = [];
  const transferOrders: TransferDraft[] = [];

  warehouses.forEach(whID => {
    const rows = salesDocument.rows
      .map(({ stableRowID, productID }) => {
        const value = Number(getValue(whID, stableRowID));
        return { productID, amount: value, stableRowID };
      })
      .filter(row => row.amount > 0);

    if (rows.length === 0) return;

    transferOrders.push({
      ...transferOrderBaseParams,
      warehouseFromID: whID,
      ...flattenForApi(rows.map(R.omit(['stableRowID']))),
    });
    links.push(rows.map(R.omit(['productID'])));
  });

  return (
    <>
      <Box>
        {warehouses.map(wh => {
          const rows = salesDocument.rows
            .map(({ stableRowID, productID }) => {
              const value = Number(getValue(wh, stableRowID));
              return { productID, amount: value, stableRowID };
            })
            .filter(row => row.amount > 0);

          if (rows.length === 0)
            return (
              <Box key={wh}>
                <Typography>
                  No products selected to transfer from{' '}
                  <WarehouseName id={String(wh)} />
                </Typography>
              </Box>
            );
          return (
            <Box key={wh}>
              <Typography>
                Transfer from <WarehouseName id={String(wh)} />
              </Typography>
              <ul>
                {rows?.map(({ stableRowID, productID, amount }) => {
                  return (
                    <li key={stableRowID}>
                      {amount}x <ProductName id={productID} />
                    </li>
                  );
                })}
              </ul>
            </Box>
          );
        })}
      </Box>
      <Box margin={1} display="inline-block">
        <Button variant="outlined" color="secondary" onClick={onBack}>
          Go back and edit
        </Button>
      </Box>
      <Box margin={1} display="inline-block">
        <Button
          variant="contained"
          color="primary"
          onClick={() => onConfirm(salesDocument, transferOrders, links)}
        >
          Create transfer order(s)
        </Button>
      </Box>
    </>
  );
};
