import { ThemeOptions, createTheme } from '@material-ui/core';
import * as R from 'ramda';

import sharedCssColors from 'assets/themes/colors.scss';
import lightCssColors from 'assets/themes/default/colors.scss';
import darkCssColors from 'assets/themes/dark/colors.scss';

import {
  addThemeColors,
  baseThemeOptions,
  createAugmentColorsFunction,
} from './utils';

const augmentColors = createAugmentColorsFunction({
  palette: {
    contrastThreshold: 4.5,
  },
});

const sharedColors = augmentColors(sharedCssColors);
const lightColors = augmentColors(lightCssColors);
const darkColors = augmentColors(
  R.omit(['background', 'background-accent'], darkCssColors),
);

const themeOptions: ThemeOptions = {
  palette: { contrastThreshold: 4.5 },
  overrides: {
    MuiButtonBase: {
      root: {
        '&:focus': { outline: 'none !important' },
      },
    },
  },
};

const lightThemeOptions = R.pipe(
  R.mergeDeepRight(themeOptions),
  addThemeColors(lightColors),
  addThemeColors(sharedColors),
)(baseThemeOptions);

const darkThemeOptions = R.pipe(
  R.mergeDeepRight(themeOptions),
  R.assocPath(['palette', 'type'], 'dark'),
  addThemeColors(darkColors),
  addThemeColors(sharedColors),
)(baseThemeOptions);

export const lightTheme = createTheme(lightThemeOptions);
export const darkTheme = createTheme(darkThemeOptions);
