import { PosPlugin } from 'plugins/plugin';
import { getCustomers as fetchCustomers } from 'services/ErplyAPI/customers';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { createConfirmation } from 'actions/Confirmation';
import { modalPages as mp } from 'constants/modalPage';
import { saveCustomer } from 'actions/customerSearch';
import { Customer } from 'types/Customer';
import { REDUX_CUSTOMER_REGISTRY_URL } from 'constants/persistence';

import { Conf, globalFields, pluginID, pluginName } from './constants';
import Configuration from './Configuration';

const duplicateCustomerWarning: PosPlugin<Conf | {}, Conf, Conf, Conf, null> = {
  id: pluginID,
  name: pluginName,
  info: `Checks if there are customers existing with the fields provided in the customer creation form. Fields to check by should be configured in the plugin configuration. If duplicate is encountered, displays a confirmation with fields that are duplicated and offers to either save the customer anyway or go back to editing.`,

  getStatus: () => {
    return { type: 'valid', message: 'OK' };
  },
  ComponentConfigurationByLevel: {
    Company: Configuration,
  },
  combineConfiguration: c => {
    return {
      fields: c?.fields ?? { PERSON: {}, COMPANY: {} },
      hideButton: c?.hideButton,
    };
  },
  onSaveCustomer: {
    on: (p, ap) => async (dispatch, getState) => {
      if (p.continue) {
        return ap;
      }
      const { fields, searchInCombination, hideButton } =
        getPluginConfigurationAtLevel<Conf | undefined>(
          pluginID,
          'Company',
          '',
        )(getState()) ?? {};

      const enabledFields = Object.fromEntries(
        Object.entries(fields?.[p.customerType] ?? {}).filter(([, val]) => val),
      );
      const warnings: string[] = [];

      const dupeNames = Object.keys(enabledFields).map(key => {
        const field = globalFields[p.customerType][key];
        return `${field.label}`;
      });

      const responseNames = Object.keys(enabledFields).map(key => {
        const field = globalFields[p.customerType][key];
        return `${field.responseName}`;
      });

      const duplicates = () => {
        const isUsingCustomerRegistry = !!localStorage.getItem(
          REDUX_CUSTOMER_REGISTRY_URL,
        );
        const fieldsToCheckBy = Object.keys(enabledFields);
        const params = {};
        if (fieldsToCheckBy.length) {
          if (searchInCombination?.[p.customerType]) {
            fieldsToCheckBy.forEach(key => {
              const { name, apiParam, customerRegistryParam } = globalFields[
                p.customerType
              ][key];

              const usedParam = isUsingCustomerRegistry
                ? customerRegistryParam
                : apiParam;

              if (p[name]?.length > 0 && usedParam) {
                params[usedParam] = p[name];
              }
            });
            if (Object.keys(params).length) {
              return fetchCustomers(params).then(results => {
                return dupeNames.map((name, id) => ({
                  [name]: results
                    .filter(res => res.customerID !== Number(p.customerID))
                    .filter(res => res[responseNames[id]].length > 0),
                }));
              });
            }
            return [] as { [key: string]: Customer[] }[];
          }
          return Promise.all(
            fieldsToCheckBy.map(key => {
              const { name, apiParam, customerRegistryParam } = globalFields[
                p.customerType
              ][key];

              const usedParam = isUsingCustomerRegistry
                ? customerRegistryParam
                : apiParam;

              if (p[name]?.length > 0 && usedParam) {
                return fetchCustomers({
                  [`${usedParam}`]: p[name],
                });
              }

              return [];
            }),
          ).then((results: { [id: number]: Customer[] }) =>
            dupeNames.map((name, id) => ({
              // Since this is a Promise.all, requires to access results by ID of the promise
              [name]: Object.values(results[id])
                .filter(res => res.customerID !== Number(p.customerID))
                .filter(res => res[responseNames[id]].length > 0),
            })),
          );
        }
        return [] as { [key: string]: Customer[] }[];
      };
      const duplicateCustomers = await duplicates();

      const fieldHasDuplicates = duplicateCustomers.map(
        (object: { [key: string]: Customer[] }) => {
          const [[key, val]] = Object.entries(object);
          if (val.length > 0) {
            warnings.push(key);
            return true;
          }
          return false;
        },
      );

      const warningMessage = `Existing customers with fields ${warnings.join(
        ', ',
      )} found`;

      const showPopup = searchInCombination?.[p.customerType]
        ? fieldHasDuplicates.every(val => val)
        : fieldHasDuplicates.some(val => val);

      if (showPopup) {
        new Promise((resolve, reject) => {
          const rejectFunction = hideButton ? null : reject;
          return dispatch(
            createConfirmation(resolve, rejectFunction, {
              title: 'Duplicate customer',
              body: warningMessage,
              noReject: hideButton,
              confirmText: [
                {
                  name: hideButton ? 'Go back' : 'Save anyway',
                },
              ],
              cancelText: 'Back to editing',
            }),
          );
        })
          .then(
            () => {
              if (hideButton) {
                throw new Error('Cancel customer saving');
              }
              p.continue = true;
              dispatch(
                saveCustomer(p, () => {
                  dispatch(
                    openModalPage({
                      component: mp.customerView,
                      groupID: mp.customerView,
                      replace: true,
                    }),
                  );
                }),
              );
            },
            () => {},
          )
          .catch(() => {});
      } else {
        return ap;
      }
      throw new Error('Error');
    },
  },
};
export default duplicateCustomerWarning;
