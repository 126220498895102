export default [
  {
    key: 'prepaymentPercent',
    type: 'text',
    disabled: false,
  },
  {
    key: 'advancePayment',
    type: 'text',
    disabled: false,
  },
  {
    key: 'invoiceTotal',
    type: 'text',
    disabled: true,
  },
  {
    key: 'paid',
    type: 'text',
    disabled: true,
  },
];
