import React, { PropsWithChildren } from 'react';
import { ListGroupItem, Popover } from 'react-bootstrap';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { CompanyRecord } from '../../constants';

import { useCompaniesAutocomplete } from './useCompaniesAutocomplete';

const matches = regex => c => {
  const key = c?.props?.field?.key.replace(/\W/gi, '').replace('0', '');
  return regex.test(key);
};

const disablify = R.pipe(R.assocPath(['props', 'field', 'disabled'], true));

interface Props {
  companies: CompanyRecord[];
  chooseCompany(company: CompanyRecord): Promise<any>;
}

function WithAutocomplete({
  children,
  companies,
  chooseCompany,
}: PropsWithChildren<Props>) {
  return (
    <>
      {children}
      {companies?.length ? (
        <Popover id="popover-basic" style={{ marginTop: '120px' }}>
          <Popover.Title as="h3">Companies found</Popover.Title>
          <Popover.Content>
            {companies?.map(company => (
              <ListGroupItem
                key={company.company_id}
                onClick={() => chooseCompany(company)}
              >
                {company.name}
              </ListGroupItem>
            ))}
          </Popover.Content>
        </Popover>
      ) : null}
    </>
  );
}

export const UICustomerForm: Required<PosPlugin>['UICustomerForm'] = ({
  value,
  children,
  onChange,
  addressTypes,
}) => {
  const { companies, chooseCompany, chosenCompany } = useCompaniesAutocomplete(
    value,
    onChange,
    addressTypes ?? [],
  );

  const disableIfCompany = chosenCompany ? disablify : R.identity;

  return R.pipe(
    R.map(
      R.when(matches(/companyName$/), component => (
        <WithAutocomplete companies={companies} chooseCompany={chooseCompany}>
          {component}
        </WithAutocomplete>
      )),
    ),
    R.map(R.when(matches(/code$/), disableIfCompany)),
    R.map(R.when(matches(/vatNumber$/), disableIfCompany)),
    // @ts-ignore
  )(React.Children.toArray(children));
};
