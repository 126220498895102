import React, { useState, useEffect } from 'react';
import { Modal, Col, Container, Row, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import UIButton from 'components/UIElements/UIButton';
import { getSetting } from 'reducers/configs/settings';

import AgeLine from './AgeLine';
import { useTranslation } from 'react-i18next';

const AgeVerification = ({ processPayments, setPaymentSelected }) => {
  const [options, setOptions] = useState({
    days: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],
    years: [],
    months: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ],
  });
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [isCorrectDate, setIsCorrectDate] = useState(false);

  const birthDate = `${month}.${day}.${year}`;

  const userEnteredDate = isCorrectDate
    ? new Date(Number(year), Number(month), Number(day))
    : new Date();
  const dateRightNow = new Date();

  const userEnteredYear = userEnteredDate.getFullYear();
  const yearRightNow = dateRightNow.getFullYear();

  const isSkipDisabled = useSelector(getSetting('skip_age_verification'));

  if (isCorrectDate) {
    localStorage.setItem('age-verification', birthDate);
  }

  const hadBirthday = (month, day) => {
    const currentYear = new Date().getFullYear();
    const bdThisYear = new Date(currentYear, month - 1, day);
    return bdThisYear.getTime() < Date.now();
  };

  const diff = hadBirthday(month, day)
    ? yearRightNow - userEnteredYear
    : yearRightNow - userEnteredYear - 1 || 0;

  const currentYear = dateRightNow.getFullYear();
  const getDaysInMonth = (month, year) => {
    const days = new Array(31)
      .fill('')
      .map((_, i) => new Date(year, month - 1, i + 1))
      .filter(v => v.getMonth() === month - 1);
    if (days.length < 1) return options.days.length;
    return days.length;
  };

  const getNumbersInRange = (start, end) =>
    new Array(end - start + 1).fill(null).map((_, i) => i + start);

  useEffect(() => {
    setOptions({
      ...options,
      years: getNumbersInRange(currentYear - 100, currentYear),
      days: getNumbersInRange(1, getDaysInMonth(month, year)).map(day =>
        day.toString().length === 1 ? `0${day}` : day,
      ),
    });
    if (birthDate.length === 10) setIsCorrectDate(true);
  }, [month, year, day]);
  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(setPaymentSelected(''));
    dispatch(processPayments());
    setTimeout(() => {
      localStorage.removeItem('selectedReason');
      localStorage.removeItem('age-verification');
    }, 10000);
  };

  const onSkip = () => {
    localStorage.setItem('age-verification', 'skipped');
    dispatch(setPaymentSelected(''));
    dispatch(processPayments());
    setTimeout(() => {
      localStorage.removeItem('selectedReason');
      localStorage.removeItem('age-verification');
    }, 10000);
  };

  const { t } = useTranslation('settingsAgeVerification');

  const { years, days, months } = options;

  const onClose = () => dispatch(previousModalPage());

  return (
    <>
      <Modal.Header style={{ alignItems: 'center' }}>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('title')}
        </span>

        <div style={{ flex: 1 }} />

        {!isSkipDisabled && (
          <UIButton text={t('skip')} variant="POS" action={onSkip} />
        )}
        <UIButton
          disabled={!isCorrectDate}
          text={t('save')}
          variant="POS"
          action={onConfirm}
        />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="no-gutters p-3 justify-content-between">
            <Col md={3}>
              <Form.Control
                onChange={e => setMonth(e.target.value)}
                as="select"
              >
                <option selected disabled>
                  {t('month')}
                </option>
                {months.map(month => (
                  <option key={month}>{month}</option>
                ))}
              </Form.Control>
            </Col>
            <Col md={3}>
              <Form.Control onChange={e => setDay(e.target.value)} as="select">
                <option selected disabled>
                  {t('day')}
                </option>
                {days.map(day => (
                  <option key={day}>{day}</option>
                ))}
              </Form.Control>
            </Col>
            <Col md={3}>
              <Form.Control onChange={e => setYear(e.target.value)} as="select">
                <option selected disabled>
                  {t('year')}
                </option>
                {years.reverse().map(year => (
                  <option key={year}>{year}</option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className={`p-${isCorrectDate ? '5' : '3'}`}>
        {isCorrectDate ? (
          <AgeLine diff={diff} />
        ) : (
          <p style={{ textAlign: 'center', width: '100%' }}>
            {t('errorMessage')}
          </p>
        )}
      </Modal.Footer>
    </>
  );
};

export default AgeVerification;
