/* eslint-disable no-await-in-loop,no-restricted-syntax */
import { sleep } from 'utils';
import { CardPaymentHooks, PaymentObj } from 'paymentIntegrations/types';
import { withWaitingForTerminal } from 'paymentIntegrations';

import allowBypassPin from './allowBypassPin';
import makePayment from './makePayment';
import handleSuccess from './handleSuccess';
import handleFail from './handleFail';

const processPayments = (
  cardPayments: PaymentObj[],
  hooks: CardPaymentHooks,
) => async dispatch => {
  const allowPinBypass = await dispatch(allowBypassPin());
  let isOK = false;
  for (const payment of cardPayments) {
    isOK = await dispatch(
      withWaitingForTerminal(() => {
        return dispatch(makePayment({ payment, hooks, allowPinBypass }))
          .then(({ data: response }) =>
            dispatch(handleSuccess({ payment, response, hooks })),
          )
          .catch(error => dispatch(handleFail({ error, payment, hooks })))
          .finally(() => sleep(4));
      }),
    );
    if (!isOK) break;
  }
  return isOK;
};

export default processPayments;
