import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import List from 'components/List';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getExpandProductButtonConfig } from 'reducers/configs/settings';
import { getIsEditMode } from 'reducers/UI/productEditMode';
import { modalPages as mp } from 'constants/modalPage';
import { useKeyState } from 'utils/hooks/keyboard/useKeyState';
import './index.css';
import { openStockAndPriceForItem } from 'actions/productsDB';
import { isString } from 'utils';
import { PluginComponent } from 'plugins';
import { GridButton } from 'components/GridButton';

import { ProductInfo } from './components';

const useClickCountingAction = (
  isProduct = false,
  action = null,
  productID = null,
) => {
  const shouldExpandProductButton =
    useSelector(getExpandProductButtonConfig) === 'onClick';
  const [clickCount, setClickCount] = useState(0);
  const detectClick = useCallback(
    () => isProduct && setClickCount(cc => cc + 1),
    [isProduct],
  );

  const onClickOutOfButton = useCallback(
    e => {
      if (e.target.id === productID || e.target.parentNode?.id === productID) {
        action && action();
      }
      setClickCount(0);
    },
    [productID, action],
  );

  // instead of adding event listener to each product button,
  // only add it when the button has been clicked once
  useEffect(() => {
    if (shouldExpandProductButton && clickCount === 1) {
      window.addEventListener('click', onClickOutOfButton);
    }
    return () => {
      window.removeEventListener('click', onClickOutOfButton);
    };
  }, [productID, shouldExpandProductButton, clickCount, action]);

  return { detectClick, shouldExpandBtn: clickCount === 1 };
};

export const ProductItem = ({ item }) => {
  const dispatch = useDispatch();
  const ALT = useKeyState('Alt');

  const add = item => dispatch(addProduct({ productID: item.productID }));

  const addMatrix = (item) =>
    dispatch(
      openModalPage({
        component: mp.matrixVariations,
        groupID: mp.matrixVariations,
        replace: true,
        props: { selectedProduct: item },
      }),
    );
  const edit = () =>
    dispatch(
      openModalPage({
        component: mp.ProductEdit,
        props: { productID: item.productID },
      }),
    );

  const isEditMode = useSelector(getIsEditMode);
  const onClick = () => {
    document.activeElement.blur();
    if (item?.action) return item.action();
    if (!item?.productID) return null;
    if (isEditMode) return edit();
    if (ALT) return dispatch(openStockAndPriceForItem(item));
    if (item.productVariations) return addMatrix(item);
    return add(item);
  };
  const action = onClick;

  // The product button should:
  // "disabled" | undefined - behave as normal
  // "onHover" - expand on hover, onClickHandler works as usual
  // "onClick - expand on click, second click will trigger onClickHandler
  const expandProductButton = useSelector(getExpandProductButtonConfig);
  const { detectClick, shouldExpandBtn } = useClickCountingAction(
    Boolean(item?.productID),
    onClick,
    `product-button-${item.productID}`,
  );

  return (
    <PluginComponent
      hookname="UIProductButton"
      props={{
        item,
        action,
        ALT,
        isEditMode,
      }}
    >
      <GridButton
        className={classNames({
          'product-btn': true,
          'scale-on-hover':
            expandProductButton === 'onHover' && item?.productID,
          'scale-on-click':
            expandProductButton === 'onClick' &&
            item?.productID &&
            shouldExpandBtn,
          'edit-mode': isEditMode && item?.productID,
        })}
        id={`product-button-${item.productID}`}
        btnColor={item && item.type === 'special' ? 'grid_green' : 'grid_light'}
        disabled={item && item.disabled}
        data-testid="product-button"
        data-test-key={item.code}
        onClick={expandProductButton === 'onClick' ? detectClick : onClick}
        title={isString(item.name) ? item.name : undefined}
      >
        <ProductInfo product={item} />
      </GridButton>
    </PluginComponent>
  );
};

ProductItem.propTypes = {
  item: PropTypes.object,
};

const Products = ({
  products,
  minLines,
  maxLines,
  specialBttnsEndIfFull,
  specialBttnsStart,
  specialBttnsEnd,
  onSetNrLines,
  gridTypeOpened,
}) => (
  <PluginComponent
    hookname="UIProducts"
    props={{
      products,
      minLines,
      maxLines,
      specialBttnsEndIfFull,
      specialBttnsStart,
      specialBttnsEnd,
      onSetNrLines,
      gridTypeOpened,
    }}
  >
    <List
      specialBttnsEndIfFull={specialBttnsEndIfFull}
      minLines={minLines}
      maxLines={maxLines}
      items={products}
      itemComponent={<ProductItem />}
      specialBttnsStart={specialBttnsStart}
      specialBttnsEnd={specialBttnsEnd}
      onSetNrLines={onSetNrLines}
      gridTypeOpened={gridTypeOpened}
    />
  </PluginComponent>
);

Products.propTypes = {
  products: PropTypes.array.isRequired,
  startBttns: PropTypes.array,
  endBttns: PropTypes.array,
  totalLength: PropTypes.number,
};

export default Products;
