import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Button, TextField, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getCurrencyFormatter } from 'reducers/configs/settings';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

type Props = {
  onClose(): void;
  resolve(code: string): void;
  reject(reason: string): void;
  checkPayment: { amount: string };
};

/**
 *  Do not change UI of this component, because it was requested the way it is right now
 */
const CaptureCheckNumber: React.FC<Props> = ({
  resolve,
  onClose,
  reject,
  checkPayment,
}) => {
  const [checkNumber, setCheckNumber] = useState('');

  const { t } = useTranslation('payment', { keyPrefix: 'captureCheckNumber' });

  const format = useSelector(getCurrencyFormatter);

  const isValidCheckNumber = checkNumber.trim().length > 0;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  function onConfirm() {
    if (!isValidCheckNumber) return;
    resolve(checkNumber);
    onClose();
  }
  function onCancel() {
    reject('Cancelled capturing check number');
    onClose();
  }

  useShortcut('Enter', onConfirm, 30);
  useShortcut('Escape', onCancel, 30);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="0.5rem"
      padding="0.75rem"
    >
      <Typography variant="h5">
        {t('title', { amount: format(checkPayment.amount) })}
      </Typography>
      <Divider />
      <TextField
        value={checkNumber}
        onChange={e => setCheckNumber(e.target.value)}
        fullWidth
        label={t('inputLabel')}
        variant="outlined"
        inputRef={inputRef}
        error={!isValidCheckNumber}
        helperText={isValidCheckNumber ? '' : t('inputErrorText')}
      />
      <Box display="flex" gridGap="0.5rem">
        <Button variant="contained" onClick={onCancel} fullWidth>
          {t('cancelText')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!isValidCheckNumber}
          onClick={onConfirm}
          fullWidth
        >
          {t('confirmText')}
        </Button>
      </Box>
    </Box>
  );
};

export default CaptureCheckNumber;
