import { getPluginSelector } from 'reducers/Plugins';

import { ZipState } from './reducers';

export const getZipSlice = () =>
  getPluginSelector<ZipState>('capture-zip')(state => state);

export const getAllEnabledProductGroups = (state: ZipState) =>
  getZipSlice()(state).all;

export const getZipCode = (state: ZipState) => getZipSlice()(state).zip;

export const getIsZipAlreadyEntered = (state: ZipState) =>
  !!getZipSlice()(state).zip;
