import i18next from 'i18next';

import * as api from 'services/ErplyAPI/customers';
import { createConfirmation } from 'actions/Confirmation';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { addWarning } from 'actions/Error';

import fetchRegistryCodeValidation from './requests';

const searchCustomerInErply = async registryCode => {
  const erplyCustomer = await api.getCustomers({
    searchRegistryCode: registryCode,
  });

  return erplyCustomer[0];
};

const askToAddCustomer = async dispatch => {
  await i18next.loadNamespaces('fiscalIntegration');
  return new Promise((resolve, reject) => {
    dispatch(
      createConfirmation(resolve, reject, {
        title: i18next.t('fiscalIntegration:existingCustomer'),
        body: i18next.t('fiscalIntegration:selectCustomer'),
        confirmText: i18next.t('fiscalIntegration:accept'),
        cancelText: i18next.t('fiscalIntegration:refuse'),
      }),
    );
  });
};

export const validateRegistryCode = (
  registryCode: string,
  onChange,
  addressOnChange,
  addresses,
) => async dispatch => {
  const erplyCustomer = await searchCustomerInErply(registryCode);

  if (erplyCustomer) {
    const shouldAddCustomer = await dispatch(askToAddCustomer).then(
      () => true,
      () => false,
    );

    if (shouldAddCustomer) {
      dispatch(closeModalPage(modalPages.createCustomer));
      dispatch(setCustomer({ data: erplyCustomer.customerID }));
    }

    return null;
  }

  let validationResult = await fetchRegistryCodeValidation(registryCode);
  const {
    companyExists,
    vatNumber,
    city,
    postalCode,
    country,
    address,
  } = validationResult;
  // Update values in the form with the values received from external service
  const manualValuesUpdate = values => {
    return onChange(val => ({ ...val, ...values }));
  };

  if (!companyExists) {
    await i18next.loadNamespaces('fiscalIntegration');
    dispatch(
      addWarning(i18next.t('fiscalIntegration:companyNotFound'), {
        dismissible: false,
      }),
    );
    manualValuesUpdate({
      vatNumber: '',
      companyName: '',
    });
    return null;
  }

  if (!vatNumber || !address.trim()) {
    const missingData = await dispatch(
      openModalPage({
        component: modalPages.pluginModal,
        isPopup: true,
        props: {
          name: 'garminInputs',
          vatNumber,
          address,
        },
      }),
    );

    validationResult = { ...validationResult, ...missingData };
  }

  const updateAddress = () => {
    return addressOnChange(
      [{ ...addresses[0], city, postalCode, country, street: address }].concat(
        addresses.slice(1),
      ),
    );
  };

  updateAddress();
  manualValuesUpdate({
    vatNumber: validationResult.vatNumber,
    companyName: validationResult.companyName,
  });

  return null;
};
