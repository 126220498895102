import { getPluginSelector } from 'reducers/Plugins';

import { pluginID } from '../constants';

// action types
const SET_ALLOW_FOCUS = `${pluginID}:SET_ALLOW_FOCUS`;

// actions
export const setAllowFocus = (payload: boolean) => ({
  type: SET_ALLOW_FOCUS,
  payload,
});

type Action = {
  type: string;
  payload: boolean;
};

// selectors
export const getIsFocusAllowed = getPluginSelector(pluginID)((n) => n);

// reducer
export default (state = true, { type, payload }: Action) => {
  switch (type) {
    case SET_ALLOW_FOCUS:
      return payload;
    default:
      return state;
  }
};
