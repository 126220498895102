import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getClientCode, getSessionKey } from 'reducers/Login';
import {
  getCurrentPosLanguage,
  getPosDefaultLanguage,
} from 'reducers/configs/settings';
import { wrapperApiVersion, version } from 'external_data';
import { getSelectedPosID } from 'reducers/PointsOfSale';

const wrapperData: Record<string, any> = {};
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
window.wrapperData = wrapperData;

export const useWrapperContextVariables = () => {
  const newData = {
    clientCode: useSelector(getClientCode),
    sessionKey: useSelector(getSessionKey),
    receiptLang: useSelector(getPosDefaultLanguage)?.legacyIdentifier,
    posLang: useSelector(getCurrentPosLanguage),
    apiVersion: wrapperApiVersion,
    posID: useSelector(getSelectedPosID),
    softwareName: 'Erply Brazil POS',
    softwareVersion: version,
  };
  Object.entries(newData).forEach(([key, value]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      wrapperData[key] = value;
    }, [value]);
  });
};
