import dayjs from 'dayjs';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import i18next from 'i18next';

import { getPatchScriptReceiptTemplates } from 'reducers/cafaConfigs';
import { getClientCode, getSessionKey } from 'reducers/Login';
import { RootState } from 'reducers';
import { createConfirmation } from 'actions/Confirmation';
import { addWarning } from 'actions/Error';

import { getIsMSRunning } from '../utils';

import { CacheInfo } from './types';
import * as api from './api';

function getCacheInfo(): ThunkAction<
  Promise<CacheInfo | null>,
  RootState,
  unknown,
  AnyAction
> {
  return async (dispatch, getState) => {
    const sessionKey = getSessionKey(getState());
    const clientCode = getClientCode(getState());
    try {
      return await api.getCacheInfo(sessionKey, clientCode);
    } catch (error) {
      dispatch(
        addWarning(
          i18next.t(
            'alerts:integrations.receiptPrintoutApi.failedToFetchCacheInfo',
          ),
          { dismissible: true, selfDismiss: 10000 },
        ),
      );
      return null;
    }
  };
}

function clearCachedTemplate(): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> {
  return async (dispatch, getState) => {
    const sessionKey = getSessionKey(getState());
    const clientCode = getClientCode(getState());
    try {
      await api.clearCachedTemplate(sessionKey, clientCode);
    } catch (error) {
      await new Promise(resolve =>
        dispatch(
          createConfirmation(resolve, null, {
            title: i18next.t(
              'alerts:integrations.receiptPrintoutApi.failedToClearCache.title',
            ),
            body: i18next.t(
              'alerts:integrations.receiptPrintoutApi.failedToClearCache.body',
            ),
          }),
        ),
      );
    }
  };
}

export function clearOutdatedReceiptTemplateCache(): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> {
  return async (dispatch, getState) => {
    const isMsRunning = await dispatch(getIsMSRunning('receipt-printout-api'));
    if (!isMsRunning) return;

    const receiptTemplates = getPatchScriptReceiptTemplates(getState());

    const cacheFromMs = await dispatch(getCacheInfo());
    if (!cacheFromMs) return;

    const templateCache = cacheFromMs.cacheData?.templates ?? [];
    const hasOutdatedCache = templateCache.some(cache => {
      const matchingTemplate = receiptTemplates.find(
        template => template.id === cache.templateId,
      );
      if (!matchingTemplate) return false;

      const cacheDate = dayjs(cache.date);
      const templateChangedDate = dayjs(matchingTemplate.changed * 1000);

      return cacheDate.isBefore(templateChangedDate);
    });

    if (!hasOutdatedCache) return;

    await dispatch(clearCachedTemplate());
  };
}
