import * as R from 'ramda';
import { compare } from 'compare-versions';

/**
 * Checks if the URL is production or sandbox
 */
export const checkIsAllowedEnvironment = () =>
  ['epos', 'pos-sb', 'pos-dev'].includes(
    window.location.hostname.split('.')?.[0],
  );

/**
 * Returns the first parameter in the pathname - POS version
 */
export const getVersionParamFromURL = () =>
  window.location.pathname.split('/')?.[1];

/**
 * Returns the version query parameter from the URL
 */
export const getVersionQueryParamFromURL = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('version');
};

export const LAST_VC_REDIRECT_VERSION = 'last_version_control_redirect';

/**
 * Checks if the current version is already
 * @param version - version to redirect to
 */
export const checkIsSecondRedirectAttempt = version => {
  const lastVersionFromRedirect = sessionStorage.getItem(
    LAST_VC_REDIRECT_VERSION,
  );
  return lastVersionFromRedirect === version;
};

/**
 * Redirects POS to the version passed from parameters
 */
export const redirectPOSToVersion = (version: string) => {
  const posLocation = new URL(window.location.origin.toString());
  posLocation.searchParams.set('version', 'VERSION_REPLACE');
  window.location.replace(
    posLocation.toString().replace('VERSION_REPLACE', version),
  );
};

const ALL_VERSIONS_URL = 'https://app-ver.erply.com';

const versionsFromTxtToArray = R.pipe(
  R.replace(/(\s|\n)*/g, ''),
  R.split(','),
  R.filter(a => R.not(R.isEmpty(a))),
);

/**
 * Fetches all available versions for the current environment,
 * if the current environment is production or sandbox
 */
export const getPOSAvailableVersions = () => {
  const environment = window.location.hostname.split('.')?.[0];
  if (checkIsAllowedEnvironment()) {
    return window
      .fetch(`${ALL_VERSIONS_URL}/${environment}/all.txt`)
      .then(r => r.text())
      .then(versionsFromTxtToArray)
      .catch(err => {
        console.error('Failed to load available POS versions', err);
        return [];
      }) as Promise<string[]>;
  }
  return Promise.resolve([]);
};

/**
 * Filters out versions before the passed version.
 * @param semVer - semantic version
 * @param versions - array of semantic versions
 */
export const filterOutVersionBeforeSemVer = (
  semVer: string,
  versions: string[],
) => {
  const semanticVersionRegex = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/g;
  if (!semanticVersionRegex.test(semVer)) return versions;
  return versions.filter(v => compare(v, semVer, '>='));
};

/**
 * Returns environment based on the URL
 * - epos.erply.com - production
 * - pos-sb.erply.com - sandbox
 * - pos-dev.erply.com - develop
 * - everything else - ""
 */
export const getEnvironmentFromURL = ():
  | 'develop'
  | 'sandbox'
  | 'production'
  | '' => {
  const env = window.location.hostname.split('.')?.[0];
  return (
    {
      'pos-dev': 'develop',
      'pos-sb': 'sandbox',
      epos: 'production',
    }[env] ?? ''
  );
};
export const verifyTargetAvailable = target => {
  const posLocation = new URL(window.location.origin.toString());
  posLocation.pathname = target;
  return window.fetch(posLocation.toString(), { method: 'HEAD' }).then(res => {
    if (res.redirected) {
      if (!res.url.includes(target)) {
        // On redirect off, throw error
        throw new Error(`Target version ${target} redirects to ${res.url}`);
      } else {
        // On redirect that includes version (maybe just toggling a trailing slash or something), warn but proceed
        console.warn(
          `Target version ${target} redirected to ${res.url}, but that url still contains desired version`,
        );
      }
    }
  });
};
