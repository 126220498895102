import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';

import {
  commitTimestamp,
  semanticVersion as version,
  sha,
} from 'external_data';
import { modalPages as mp } from 'constants/modalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getUserRights, getUserLoggedIn } from 'reducers/Login';
import { ExternalPage } from 'containers/Forms/Settings/components/Inputs';

import { Page } from './components/Inputs';

import './Settings.scss';

const HeaderSettings = ({ closeModalPage }) => {
  const { t } = useTranslation('settings');

  const perms = useSelector(getUserRights);
  const settingsPerms = perms.modules.settings.view > 0;
  const employeesPerms = perms.modules.employees.edit > 0;

  const userInfo = useSelector(getUserLoggedIn);

  return (
    <div className="erply-settings" data-testid="erply-settings">
      <Modal.Header>
        <span style={{ marginRight: 'auto' }}>{t('title')}</span>
        <CloseButton
          data-testid="close"
          action={() => {
            closeModalPage();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <h6>{t('sections.general')}</h6>
        <ListGroup as="ul">
          {settingsPerms && (
            <Page
              data-testid="setting"
              data-test-key={t('CompanyAndLocation')}
              title={t('CompanyAndLocation')}
              value={mp.companySettings}
            />
          )}
          {employeesPerms && (
            <Page
              data-testid="setting"
              data-test-key={t('Employees')}
              title={t('Employees')}
              value={mp.employeesSettings}
            />
          )}
          {settingsPerms && (
            <>
              <Page
                data-testid="setting"
                data-test-key={t('Customers')}
                title={t('Customers')}
                value={mp.customersSettings}
              />
              <Page
                data-testid="setting"
                data-test-key={t('TaxRates')}
                title={t('TaxRates')}
                value={mp.taxRatesSettings}
              />
              <Page
                data-testid="setting"
                data-test-key={t('Configuration')}
                title={t('Configuration')}
                value={mp.configurationSettings}
              />
              <Page
                data-testid="setting"
                data-test-key={t('PaymentConf')}
                title={t('PaymentConf')}
                value={mp.paymentSettings}
              />
              <Page
                data-testid="setting"
                data-test-key={t('PrintingConf')}
                title={t('PrintingConf')}
                value={mp.printingSettings}
              />
              <Page
                data-testid="setting"
                data-test-key={t('ScanningConf')}
                title={t('ScanningConf')}
                value={mp.scanningSettings}
              />
              <Page
                data-testid="setting"
                data-test-key={t('Debug')}
                title={t('Debug')}
                value={mp.debugSettings}
              />
              <Page
                data-testid="setting"
                data-test-key={t('Plugins')}
                title={t('Plugins')}
                value={mp.pluginSettings}
              />
            </>
          )}
          <Page
            data-testid="setting"
            data-test-key={t('SetLanguage')}
            title={t('SetLanguage')}
            value={mp.languageSettings}
          />
        </ListGroup>

        <h6>{t('sections.deviceSpecific')}</h6>
        <ListGroup as="ul">
          <Page
            data-testid="setting"
            data-test-key={t('OnScreenKeyboard')}
            title={t('OnScreenKeyboard')}
            value={mp.onScreenKeyboard}
          />
          <Page
            data-testid="setting"
            data-test-key={t('Theme')}
            title={t('Theme')}
            value={mp.Theme}
          />
          {settingsPerms && (
            <Page
              data-testid="setting"
              data-test-key={t('AssociatePos')}
              title={t('AssociatePos')}
              value={mp.assocaitePOSSettings}
            />
          )}
          {settingsPerms && (
            <Page
              data-testid="setting"
              data-test-key={t('AssociatePosNew')}
              title={t('AssociatePosNew')}
              value={mp.associatePosSettingsNew}
            />
          )}
        </ListGroup>

        <h6>{t('sections.links')}</h6>
        <ExternalPage
          href={`${userInfo.loginUrl}?section=products`}
          post={{
            authKey: userInfo.sessionKey,
          }}
          title={t('BackOffice')}
          data-testid="setting"
          data-test-key={t('BackOffice')}
        />
        <ExternalPage
          href={
            {
              'https://epos.erply.com': 'https://posow.erply.com/',
              'https://pos-sb.erply.com': 'https://posow.erply.com/',
              'https://pos-dev.erply.com': 'https://posow.erply.com/',
            }[window.origin] ?? 'https://posow.erply.com'
          }
          title={t('POSOnboardingWizard')}
          data-testid="setting"
          data-test-key={t('POSOnboardingWizard')}
        />
        <div style={{ height: 12 }} />
        <div style={{ whiteSpace: 'pre-line' }}>
          {t('common:version', {
            context: 'long',
            version,
            sha,
            timestamp: new Date(commitTimestamp),
          })}
        </div>
      </Modal.Body>
    </div>
  );
};

const mapStateToProps = state => ({
  userInfo: state.Login,
});
const mapDispatchToProps = {
  closeModalPage,
  openModalPage,
};
export default connect(mapStateToProps, mapDispatchToProps)(HeaderSettings);
