import * as R from 'ramda';

import { returnTenders, salesTenders } from './constants';

function getDefaultTenderConfig(tenders) {
  return tenders.reduce(
    (config, { configName }) => ({
      ...config,
      [configName]: { allowed: true, limit: '' },
    }),
    {},
  );
}

const defaultSaleTenderConfig = getDefaultTenderConfig(salesTenders);
const defaultReturnTenderConfig = getDefaultTenderConfig(returnTenders);

const defaultConfig = {
  enabled: false,
  config: {
    allowOnlyOriginalTendersOnReturnWithReceipt: false,
    sale: defaultSaleTenderConfig,
    returnWithReceipt: defaultReturnTenderConfig,
    return: defaultReturnTenderConfig,
  },
};

export function withDefaultValue(config) {
  if (!config) return defaultConfig;
  return R.mergeDeepLeft(config ?? {}, defaultConfig);
}
