import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { round } from 'utils/index';

export const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  notes: {
    display: 'flex',
    padding: '.5em 0',
  },
  commentLabel: {
    width: '6ch',
    paddingRight: '1em',
  },
  comment: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 1 auto',
    borderBottom: '1px solid #000',
    paddingBottom: '1em',
  },
  amount: {
    display: 'flex',
    padding: '.5em 0',
    alignSelf: 'flex-end',
    width: '20ch',
    textAlign: 'right',
  },
  sumLabel: {
    fontWeight: 700,
    paddingRight: '1em',
  },
  sum: {
    fontWeight: 700,
    flex: '1 1 auto',
  },
};

const CashInOut = ({
  address = '',
  warehouseName = '',
  employeeName = '',
  companyName = '',
  comment = '',
  sum = '0.00',
  currencySymbol = '',
}) => {
  const { t } = useTranslation('cashInOut');
  const date = format(new Date(), 'yyyy.MM.dd HH:mm:ss');
  return (
    <div style={{ width: '34ch' }}>
      <h4 style={{ fontWeight: 700 }}>
        {t('receipt.title', {
          company: companyName,
          warehouse: warehouseName,
        })}
      </h4>
      <br />
      <p>{address}</p>
      <p>{date}</p>
      <p>{t('receipt.employee', { employee: employeeName })}</p>
      <br />
      <div style={style.container}>
        <div style={style.notes}>
          <div style={style.commentLabel}>{t('receipt.notes')}</div>
          <div style={style.comment}>
            <span style={{ wordBreak: 'break-word' }}>{comment}</span>
          </div>
        </div>
        <div style={style.amount}>
          <div style={style.sumLabel}>
            {t('receipt.sumLabel', {
              context: Number(sum) < 0 ? 'out' : 'in',
              currencySymbol,
            })}
            :
          </div>
          <div style={style.sum}>{round(Math.abs(Number(sum)), 2)}</div>
        </div>
      </div>
    </div>
  );
};

export default CashInOut;
