import React, { useState } from 'react';
import { useAsync } from 'react-use';
import * as R from 'ramda';

import { openManagerOverride } from 'containers/Forms/ManagerOverride/actions';
import { PosPlugin } from 'plugins/plugin';
import {
  getHasProductsInShoppingCart,
  getProductInOrderByIndex,
} from 'reducers/ShoppingCart';
import { getUserGroups } from 'services/ErplyAPI/api';
import Loader from 'components/Loader';
import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';

export const pluginID = 'security';
type PluginConf = {
  managerGroups: {
    [key: number]: boolean;
  };
};

const SecurityPlugin: PosPlugin<PluginConf> = {
  id: pluginID,
  name: 'Security plugin',
  keywords: ['security', 'manager override', 'manager', 'override', 'rights'],
  getStatus: state => {
    const conf =
      getPluginConfigurationAtLevel<PluginConf>(
        pluginID,
        'Company',
        '',
      )(state) || {};
    const { managerGroups = {} } = conf;
    const isValid = Object.values(managerGroups).some(val => val);
    return {
      type: isValid ? 'valid' : 'error',
      message: isValid
        ? 'Configure manager groups in plugin configuration'
        : 'At least 1 user group has to be configured as manager group',
    };
  },

  ComponentConfigurationByLevel: {
    Company: ({ current = { managerGroups: {} } as PluginConf, save }) => {
      const [groups, setGroups] = useState(current?.managerGroups ?? {});
      const { value: allGroups = [], loading } = useAsync(
        () => getUserGroups(),
        [getUserGroups],
      );

      const onGroupChange = e => {
        const { value, name } = e.target;
        setGroups({
          ...groups,
          [name]: value,
        });
      };
      const saveButtonDisabled =
        R.equals(groups, current?.managerGroups) ||
        Object.values(groups).every(val => !val);

      return (
        <div>
          <UIButton
            text="Save"
            action={() =>
              save({
                managerGroups: groups,
              })
            }
            disabled={saveButtonDisabled}
          />
          <h3>
            Here you can configure which user groups are considered managers
          </h3>

          <Loader show={loading} loadingText="Loading user groups...">
            {allGroups.map(group => {
              return (
                <InputField
                  type="checkbox"
                  name={group.userGroupID}
                  value={groups[group.userGroupID] || false}
                  onChange={onGroupChange}
                >
                  {group.name}
                </InputField>
              );
            })}
          </Loader>
        </div>
      );
    },
  },
  onUpdateOrderAmount: {
    // eslint-disable-next-line consistent-return
    before: ({ amount, orderIndex }) => async (dispatch, getState) => {
      const order = getProductInOrderByIndex(orderIndex)(getState());
      const currentAmount = Number(order.amount);
      const numberToSet = Number(amount);

      const isLoweringQty = numberToSet < currentAmount;
      const bothPositive = currentAmount > 0 && numberToSet > 0;
      const bothNegative = currentAmount < 0 && numberToSet < 0;

      const { managerGroups = {} } =
        getPluginConfigurationAtLevel<PluginConf>(
          pluginID,
          'Company',
          '',
        )(getState()) || {};
      // Check specifically if user is LOWERING the amount and not starting the return - starting the return is checked from the main thread aka core POS
      if (isLoweringQty && (bothNegative || bothPositive)) {
        return dispatch(
          openManagerOverride([
            {
              key: 'reducedAmount',
              text: 'Current user cannot lower amount of product in cart',
              isPermitted: async user => managerGroups[user.groupID],
            },
          ]),
        ) as any;
      }
    },
  },
  onRemoveOrder: {
    before: () => async (dispatch, getState) => {
      const { managerGroups = {} } =
        getPluginConfigurationAtLevel<PluginConf>(
          pluginID,
          'Company',
          '',
        )(getState()) || {};
      return dispatch(
        openManagerOverride([
          {
            key: 'rowRemoval',
            text: 'Current user cannot remove rows from shopping cart',
            isPermitted: async user => managerGroups[user.groupID],
          },
        ]),
      ) as any;
    },
  },
  onStartNewSale: {
    // eslint-disable-next-line consistent-return
    before: () => async (dispatch, getState) => {
      const { managerGroups = {} } =
        getPluginConfigurationAtLevel<PluginConf>(
          pluginID,
          'Company',
          '',
        )(getState()) || {};
      const hasShoppingCart = getHasProductsInShoppingCart(getState());
      if (hasShoppingCart) {
        return dispatch(
          openManagerOverride([
            {
              key: 'startNewSale',
              text: 'User cannot start new sale',
              isPermitted: async user => managerGroups[user.groupID],
            },
          ]),
        ) as any;
      }
    },
  },
};

export default SecurityPlugin;
