import React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getCheckBehavior } from 'reducers/configs/settings';
import {
  getPaymentSelected,
  getIsCheckAndGiftCardMenuSelected,
  getOriginalPayments,
  getPaymentsTotal,
} from 'reducers/Payments';

import GiftCard from '../GiftCard';
import KeyPad from '../KeyPad';
import NumberPad from '../NumberPad';
import OriginalPaymentList from '../OriginalPaymentList';
import PayCheck from '../PayCheck';
import PaymentList from '../PaymentList';

const PaymentContent = () => {
  const paymentSelected = useSelector(getPaymentSelected);
  const { isCheckMenuSelected, isGiftCardMenuSelected } = useSelector(
    getIsCheckAndGiftCardMenuSelected,
  );

  const checkBehaviour = useSelector(getCheckBehavior);

  const isReturn = useSelector(getPaymentsTotal) < 0;
  const hasOriginalPayments = 0 < useSelector(getOriginalPayments).length;
  const isReferencedReturn = isReturn && hasOriginalPayments;

  if (isGiftCardMenuSelected)
    return (
      <Col xs={12}>
        <GiftCard />
      </Col>
    );
  if (checkBehaviour === 'OLD' && isCheckMenuSelected) {
    return (
      <Col xs={12}>
        <PayCheck />
      </Col>
    );
  }
  if (isReferencedReturn)
    return (
      <>
        <Col xs={4}>{paymentSelected ? <NumberPad /> : <KeyPad />}</Col>
        <Col xs={4}>
          <PaymentList />
        </Col>
        <Col xs={4}>
          <OriginalPaymentList />
        </Col>
      </>
    );
  return (
    <>
      <Col xs={4}>{paymentSelected ? <NumberPad /> : <KeyPad />}</Col>
      <Col xs={8}>
        <PaymentList />
      </Col>
    </>
  );
};

export default PaymentContent;
