import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

type Props = {
  loadingText?: string;
  show: boolean;
  style?: React.CSSProperties;
  className?: string;
  withPadding?: boolean;
  block?: boolean;
  count?: number;
  variant?: 'spinner' | 'bars';
};

const Spinner = () => <span className={styles.spinner} />;

const Bars = ({ count = 5 }) => (
  <span className={styles.bars}>
    {Array(count)
      .fill(0)
      .map((_, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <div key={i} />
      ))}
  </span>
);

/**
 * A Loading overlay to wrap any component
 *
 * When show is true, shows the component with a loading overlay on top of it
 * <br/>When show is false, shows the component only
 */
const Loader: React.FC<Props> = ({
  children,
  loadingText = '',
  show = true,
  style = undefined,
  variant = 'spinner',
  withPadding = false,
  className = '',
  block = false,
  count = 5,
  ...props
}) => {
  return (
    <div
      className={classNames(styles.container, { [className]: !!className })}
      style={{ display: block ? 'block' : 'inline-block', maxWidth: '100%', ...style }}
      {...props}
    >
      {children}
      <div
        className={classNames(styles.overlay, {
          [styles.withPadding]: withPadding,
        })}
        style={show ? {} : { display: 'none' }}
      >
        {
          {
            spinner: <Spinner />,
            bars: <Bars count={count} />,
          }[variant]
        }
        <span style={{ fontSize: '20px', fontWeight: 600 }}>{loadingText}</span>
      </div>
    </div>
  );
};

export default Loader;
