import * as shoppingCartActionTypes from 'constants/ShoppingCart';
import { IssuedCoupon } from 'types/Coupon';

import { recalculateAfter } from './calculate';

export function applyCoupons(coupons: IssuedCoupon[]) {
  return recalculateAfter({
    type: shoppingCartActionTypes.APPLY_COUPONS,
    payload: coupons,
  });
}
