import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { getExchangeState } from '../rdx';

const UIReturnTableHeaders: PosPlugin['UIReturnTableHeaders'] = ({
  children,
}) => {
  const { exchangeStep } = useSelector(getExchangeState);

  if (exchangeStep === 0) return children;

  return R.map(
    R.over(
      R.lensPath(['props', 'children', 'props', 'children']),
      R.pipe(
        React.Children.toArray,
        // remove reason header, because on exchange we should not allow to choose reason manually
        R.reject(R.pathEq(['props', 'data-testid'], 'reason')),
        // disable toggle all rows checkbox
        R.map(
          R.when(
            R.pathEq(
              ['props', 'children', 'props', 'data-testid'],
              'toggle-rows-input',
            ),
            R.assocPath(['props', 'children', 'props', 'disabled'], true),
          ),
        ),
      ),
    ),
  )(React.Children.toArray(children));
};

export default UIReturnTableHeaders;
