export const BRONZE_GROUP_ID = 9;
export const SILVER_GROUP_ID = 10;
export const EMAIL_MEMBER_GROUP_ID = 36;
export const NON_EMAIL_MEMBER_GROUP_ID = 37;
export const DEFAULT_GROUP_ID = 8;

/**
 * 9 is Bronze
 * 10 is Silver
 */
export const VALID_GROUP_IDS = [BRONZE_GROUP_ID, SILVER_GROUP_ID];

export const SALTS = {
  development: 'qS85efRf^#Hlb9RPZR0&y#$',
  production: 'iKa&s0DnVsJ7zpJaFls^47',
};
