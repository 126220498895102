import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import CustomerSearch from 'containers/Col1/Search/Customers';

const MobileSearchCustomers = () => {
  const { t } = useTranslation('customerSearch');
  const dispatch = useDispatch();
  const close = useCallback(() => dispatch(closeModalPage()), []);
  return (
    <Container style={{ height: '90vh', overflow: 'auto' }} fluid>
      <Modal.Header
        style={{
          display: 'flex',
        }}
      >
        <Modal.Title style={{ paddingTop: '25px', fontWeight: 900 }}>
          {t('title')}
        </Modal.Title>
        <div style={{ marginLeft: 'auto' }}>
          <CloseButton
            style={{ marginTop: '-16px', marginLeft: '8px' }}
            action={close}
          />
        </div>
      </Modal.Header>
      <div className="d-flex flex-column w-100">
        <CustomerSearch active={true} closePopover={close} />
      </div>
    </Container>
  );
};

export default MobileSearchCustomers;
