import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import RenderFormItem from 'containers/Forms/Settings/components/FormFieldCtx';
import TestButtonBlock from 'components/UIElements/PaymentTestBlock';
import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import { withResponseErrors } from 'paymentIntegrations/pcEftpos/errors';
import InputField from 'components/FieldTypes/InputField';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';

const virtualPinPadFields = {
  username: {
    id: 'username',
    type: 'text',
  },
  password: {
    id: 'password',
    type: 'password',
  },
};

const inputClass = classNames([formStyles.formInput, formStyles.longTitle]);

const TerminalActivation: FC = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest): string =>
    rawT(`integrations.pcEftpos.${text}`, ...rest);

  const { values } = useContext(Ctx);

  const [pairCode, setPairCode] = useState('');
  const [status, setStatus] = useState<string | null>(null);
  const [activating, setActivating] = useState(false);

  const action = () =>
    withResponseErrors({
      request: 'requestTerminalStatus',
      params: {
        manageType: 'ActivateTerminal',
        data: {
          username: values.username,
          password: values.password,
          pairCode,
        },
      },
    });

  const clickHandler = async action => {
    try {
      setStatus(null);
      setActivating(true);
      await action();
      setActivating(false);
      setStatus('Success');
    } catch (error) {
      setActivating(false);
      setStatus('Failure');
    }
  };

  return (
    <div className="margin-bottom">
      <div className="margin-bottom">
        <span>{t('activateTerminal')}</span>
      </div>
      {Object.values(virtualPinPadFields).map(({ ...rest }) => (
        <RenderFormItem
          key={rest.id}
          title={t(rest.id)}
          name={rest.id}
          {...rest}
        />
      ))}
      <Form.Group>
        <InputField
          type="text"
          title={t('pairCode')}
          value={pairCode}
          onChange={e => setPairCode(e.target.value)}
          size="lg"
          className={inputClass}
        />
      </Form.Group>
      <TestButtonBlock
        clickHandler={() => clickHandler(action)}
        button={{
          variant: 'secondary',
          title: t('activateButton'),
          type: 'connection',
          info: t('activateButtonHint'),
          disabled: activating,
          action,
        }}
        processing={activating}
        status={status}
      />
    </div>
  );
};

export default TerminalActivation;
