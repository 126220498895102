/* eslint-disable @typescript-eslint/camelcase */
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { validateEmail } from 'containers/Login/validations';

import {
  Configuration,
  getConfiguration,
  getEmail,
  getTemplateId,
  Config,
} from './configuration';
import { reducer } from './redux';
import { pluginID } from './constants';
import { shouldSaveSaleDocToFiscal } from './utils';
import {
  resetState,
  saveFiscalDataToJsonApi,
  saveSaleToFiscalService,
} from './features/recordSaleFiscalData';
import documentation from './documentation.md';
import { WithCustomerPin } from './features/customerForm';

export const oneAcrePergamonFiscalPlugin: PosPlugin<
  Configuration,
  Configuration,
  any,
  any,
  any
> = {
  id: pluginID,
  name: 'Pergamon Fiscal plugin',
  infoMDUrl: documentation,
  combineConfiguration: c =>
    R.mergeDeepLeft(c)({
      apiEndpoint: '',
      invoice_pin: '',
      templateId: 0,
      email: '',
    }),
  getStatus: state => {
    const {
      invoice_pin = '',
      apiEndpoint = '',
      templateId = 0,
      email = '',
    } = getConfiguration(state);

    const missingFields: string[] = [];

    if (!invoice_pin.length) missingFields.push('invoice pin');
    if (!apiEndpoint.length) missingFields.push('endpoint address');
    if (!templateId) missingFields.push('template');
    if (!email) missingFields.push('e-mail');

    if (missingFields.length)
      return { type: 'error', message: `${missingFields.join(', ')} missing` };

    if (validateEmail(email).length)
      return { type: 'error', message: `Invalid e-mail` };

    return { type: 'valid', message: 'Ready' };
  },
  reduxReducer: (state, action) => {
    return reducer(state, action);
  },
  ComponentConfigurationByLevel: {
    Company: Config,
  },
  onSaveSalesDocument: {
    on: saveSaleToFiscalService,
  },
  onSaveSalesDocumentAttrToJsonApi: {
    on: saveFiscalDataToJsonApi,
  },
  onClosePayments: {
    on: resetState,
  },
  onSendInvoiceByEmail: {
    on: (p, ap) => async (dispatch, getState) => {
      const templateID = getTemplateId(getState());
      const email = getEmail(getState());
      return R.evolve({
        emailRequests: R.pipe(
          R.append(R.assoc('recipientEmail', email, ap.emailRequests[0])),
          R.when(
            () => shouldSaveSaleDocToFiscal(ap.fullSaleDoc),
            R.map(R.assoc('templateID', templateID)),
          ),
        ),
      })(ap);
    },
  },
  UICustomerForm: WithCustomerPin,
};
