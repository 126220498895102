import { PatchScript } from '@pos-refacto/patchscript-with-react';
import axios from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

import { CacheInfo, GetPatchScriptParams } from './types';

function getReceiptPrintoutApiUrl(): string {
  const endpoint =
    getMSEndpointFromLS('receiptPrintoutApi') ||
    'https://localhost.erply.com:5651/';
  return `${endpoint}api/v1`;
}

export async function getPatchScript(
  sessionKey: string,
  clientCode: string,
  params: GetPatchScriptParams,
): Promise<PatchScript> {
  const url = await getReceiptPrintoutApiUrl();
  const {
    data: { patchscript },
  } = await axios.post<{ patchscript: PatchScript }>(
    `${url}/getpatchscript`,
    params,
    {
      headers: {
        sessionKey,
        clientCode,
      },
    },
  );
  return patchscript;
}

export async function clearCachedTemplate(
  sessionKey: string,
  clientCode: string,
): Promise<void> {
  const url = await getReceiptPrintoutApiUrl();
  await axios.get(`${url}/clearcache`, {
    headers: {
      sessionKey,
      clientCode,
    },
  });
}

export async function getCacheInfo(
  sessionKey: string,
  clientCode: string,
): Promise<CacheInfo> {
  const url = await getReceiptPrintoutApiUrl();
  const { data } = await axios.get<CacheInfo>(`${url}/cacheinfo`, {
    headers: {
      sessionKey,
      clientCode,
    },
  });
  return data;
}
