import { Box, Typography, Button, Divider } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getDefaultCurrency } from 'reducers/configs/settings';
import CurrencyInput from 'components/inputs/CurrencyInput';

type Props = {
  children?: never;
  onClose: () => void;
  resolve: (value: unknown) => void;
};

const CurrencySelection: React.FC<Props> = ({ onClose, resolve }) => {
  const defaultCurrency = useSelector(getDefaultCurrency);
  const [selected, setSelected] = useState(defaultCurrency);
  const { t } = useTranslation('multiCurrency');
  return (
    <>
      <Box
        padding="1rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        data-testid="currency-selection"
      >
        <Typography style={{ flex: 1 }} variant="h5" data-testid="title">
          {t('chooseCurrency')}
        </Typography>
        <Box display="flex">
          <Button
            data-testid="save-btn"
            variant="contained"
            color="secondary"
            onClick={() => {
              resolve(selected);
              onClose();
            }}
          >
            {t('save')}
          </Button>
          <CloseButton
            action={() => {
              resolve(defaultCurrency);
              onClose();
            }}
          />
        </Box>
        <Divider />
      </Box>
      <CurrencyInput
        value={selected}
        onChange={e => setSelected(e.target.value)}
      />
    </>
  );
};

export default CurrencySelection;
