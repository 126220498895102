import {
  ENTER_RETURN_TAG,
  REMOVE_RETURN_TAG,
  REPLACE_RETURN_TAG,
  RESET_ALL_TAGS,
} from './types';

const initState = {};

export default (state = initState, { type, ...rest }): {} => {
  switch (type) {
    case ENTER_RETURN_TAG:
      return {
        ...state,
        [rest.payload.id]: [
          ...(state[rest.payload.id] ?? []),
          rest.payload.tag,
        ],
      };
    case REPLACE_RETURN_TAG:
      return {
        ...state,
        [rest.payload.id]: rest.payload.tags,
      };
    case REMOVE_RETURN_TAG:
      // eslint-disable-next-line no-case-declarations
      const newState = { ...state };
      delete newState[rest.payload.id];
      return newState;
    case RESET_ALL_TAGS:
      return initState;
    default:
      return state;
  }
};
