import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Collapse, Fade } from 'react-bootstrap';

import { TYPES } from 'constants/Error';
import { getErrorsToShow } from 'reducers/Error';
import { dismiss } from 'actions/Error';

import layers from '../assets/layers.module.css';
import { PluginComponent } from '../plugins';

const bootstrapTypes = {
  [TYPES.ERROR]: 'danger',
  [TYPES.WARNING]: 'warning',
  [TYPES.SUCCESS]: 'success',
};

const GlobalAlertComponent = React.memo(({ errors, fading, onClose }) => {
  const errorsToDisplay = errors.filter(e => !e.hide);
  const progressErrors = errorsToDisplay.filter(
    e => !e.selfDismiss && !e.dismissible,
  );
  const nonProgressErrors = errorsToDisplay.filter(
    e => !progressErrors.includes(e),
  );

  const hasProgressError =
    errors.filter(e => !e.selfDismiss && !e.dismissible).length > 0;
  const mainError = progressErrors[0] ?? nonProgressErrors[0];

  if (!errors.length) return null;
  return (
    <div
      className={`${layers.alert}`}
      style={{
        marginTop: 0,
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        pointerEvents: hasProgressError ? 'auto' : 'none',
        background: hasProgressError ? '#0004' : '#0000',
        bottom: 0,
      }}
      data-testid="alert-container"
    >
      {errorsToDisplay.length > 0 ? (
        <Alert
          style={{
            pointerEvents: 'auto',
            display: 'flex',
            justifyContent: 'center',
            padding: 8,
            margin: 4,
          }}
          key={mainError.id}
          data-testid="alert"
          data-test-key={mainError.text}
          variant={bootstrapTypes[mainError.type]}
          transition={Fade}
          show={fading.indexOf(mainError) < 0}
          onClose={() => onClose(mainError.id)}
          dismissible={mainError.dismissible}
        >
          <div style={{ textAlign: 'center' }}>
            {progressErrors.length ? (
              progressErrors.map(err => <div key={err.text}>{err.text}</div>)
            ) : (
              <span>{mainError.text}</span>
            )}
          </div>
        </Alert>
      ) : null}
      <div
        style={{
          margin: 4,
          padding: 0,
          opacity: 0.7,
          height: '1em',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
        }}
      >
        {nonProgressErrors
          .filter(e => e !== mainError)
          .map((err, i) => (
            <Alert
              style={{
                padding: 0,
                margin: 4,
                display: 'inline-block',
              }}
              data-testid="alert"
              data-test-key={err.text}
              variant={bootstrapTypes[err.type]}
              transition={Collapse}
              show={fading.indexOf(errors[0]) < 0 || i !== 0}
              dismissible={false}
              key={err.id}
            >
              <span>{String(err.text).substring(0, 24)}…</span>
            </Alert>
          ))}
      </div>
    </div>
  );
});

function ErplyAlert() {
  const currentErrors = useSelector(getErrorsToShow);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [fading, setFading] = useState([]);

  // Remove/fade older errors and show newer ones
  useEffect(() => {
    const newErrors = currentErrors.filter(e => errors.indexOf(e) < 0);
    const removedErrors = errors.filter(
      e => fading.indexOf(e) < 0 && currentErrors.indexOf(e) < 0,
    );
    if (newErrors.length) {
      setErrors(curr => curr.concat(newErrors));
      newErrors.forEach(err => {
        if (err?.selfDismiss) {
          setTimeout(() => {
            dispatch(dismiss(err.id));
          }, err?.selfDismiss);
        }
      });
    }
    if (removedErrors.length) {
      setFading(fade => fade.concat(removedErrors));
      setTimeout(() => {
        setErrors(curr => curr.filter(e => removedErrors.indexOf(e) < 0));
        setFading(fade => fade.filter(e => removedErrors.indexOf(e) < 0));
      }, 150);
    }
  }, [currentErrors]);

  const onClose = useCallback(alertID => dispatch(dismiss(alertID)), []);

  return (
    <PluginComponent
      hookname="UIErplyAlert"
      props={{ errors, fading, onClose }}
    >
      <GlobalAlertComponent errors={errors} fading={fading} onClose={onClose} />
    </PluginComponent>
  );
}

export default ErplyAlert;
