import React from 'react';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';
import './customerPurchasesContainer.css';
import i18next from 'i18next';

const CustomerPurchasesContainer = ({
  title,
  previousPurchases = [],
  addProduct,
}) => {
  const displayPreviousPurchases = prevPurchases => {
    const allPurchases = [];
    prevPurchases.map(purchase => (
      <div>
        {purchase.rows.map(item => {
          return allPurchases.push(
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <p
              key={`${item.productID}${Math.random()}`}
              className={
                item.itemName === 'Overdue charge'
                  ? 'isHidden'
                  : 'prev-purchase'
              }
              onClick={() => addProduct({ productID: item.productID })}
            >
              <span className="dark-text">{item.itemName}</span>
              <span className="dark-text">{item.code}</span>
            </p>,
          );
        })}
      </div>
    ));
    return allPurchases.slice(0, 5);
  };
  return (
    <Container className="customer-purchases">
      <h4 className="purchases-title dark_text">
        {title || i18next.t('customer:recentPurchases.title')}
      </h4>
      <Container className="purchases-details">
        {/* data */}
        {previousPurchases && previousPurchases.length ? (
          displayPreviousPurchases(previousPurchases)
        ) : (
          <p
            className="dark_text"
            style={{
              textAlign: 'center',
              marginTop: '18%',
            }}
          >
            {i18next.t('customer:recentPurchases.none')}
          </p>
        )}
      </Container>
    </Container>
  );
};

CustomerPurchasesContainer.propTypes = {
  /** Title to display */
  title: PropTypes.string,
  /** Previouse purchases information of current customer */
  previousPurchases: PropTypes.arrayOf(PropTypes.object),
  /** Adds a product to the shopping cart */
  addProduct: PropTypes.func,
};

export default CustomerPurchasesContainer;
