import * as API from 'services/ErplyAPI/api';
import {
  TYPE_SET_LOADING,
  TYPE_SET_POSLIST,
  UPDATE_CURRENT_POS,
} from 'constants/PointsOfSale';
import { getSelectedPos } from 'reducers/PointsOfSale';

export function setPoslist(pointsOfSale) {
  return {
    type: TYPE_SET_POSLIST,
    payload: pointsOfSale,
  };
}

export function setLoading(loading) {
  return {
    type: TYPE_SET_LOADING,
    payload: loading,
  };
}

export function updateCurrentPointOfSale() {
  return async (dispatch, getState) => {
    const { pointOfSaleID } = getSelectedPos(getState());
    try {
      const [pointOfSale] = await API.getPointsOfSale({ pointOfSaleID });
      dispatch({ type: UPDATE_CURRENT_POS, payload: pointOfSale });
      return pointOfSale;
    } catch (err) {
      console.error(`Failed to load current POS (id=${pointOfSaleID}`, err);
      return false;
    }
  };
}
