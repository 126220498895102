/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isEmpty, isLength } from 'validator';

import InputField from 'components/FieldTypes/InputField';
import DatePicker from 'components/DatePicker/DatePicker';
import { modalPages as mp } from 'constants/modalPage';
import { AddressForm } from 'containers/Forms/Customers';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import {
  getCustomerRegistryUrl,
  getSearchCustomerGroups,
} from 'reducers/customerSearch';
import { getAllHomeStores } from 'reducers/warehouses';
import { PluginComponent } from 'plugins';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getAddressTypes } from 'services/ErplyAPI';
import { getCanAddCustomers } from 'reducers/Login';
import { addWarning } from 'actions/Error';
import { getUISetting } from 'reducers/configs/settings';

import { form, mssgs } from './formShapes';

const inputClass = classNames([styles.formInput, styles.longTitle]);
const labelStyle = { fontWeight: 600, fontSize: '1em' };

const Input = ({
  title,
  value,
  onChange,
  field: { key, type, options, validate, disabled },
  label = null,
  children = [],
}) => (
  <Form.Group name={key}>
    {label ? <Form.Label style={labelStyle}>{label}</Form.Label> : null}
    <InputField
      size="lg"
      className={inputClass}
      onChange={onChange}
      title={title}
      disabled={disabled}
      value={value[key]}
      name={key}
      type={type}
      options={options}
      errorText={validate?.(value[key])}
      data-testid={key}
    >
      {children}
    </InputField>
  </Form.Group>
);

const BirthdayInput = ({ title, value, onChange }) => {
  const customerRegistry = !!useSelector(getCustomerRegistryUrl);
  const birthdayValidation = customerRegistry
    ? {
        date: value =>
          (!Number(value) || isEmpty(value, { ignore_whitespace: true })) &&
          mssgs.notEmpty,
        month: value =>
          (!Number(value) || isEmpty(value, { ignore_whitespace: true })) &&
          mssgs.notEmpty,
        year: value =>
          (!Number(value) ||
            isEmpty(value, { ignore_whitespace: true }) ||
            !isLength(value, { min: 4 })) &&
          mssgs.notEmpty,
      }
    : undefined;

  return (
    <DatePicker
      key="birthday"
      name="birthday"
      title={<span style={labelStyle}>{title}</span>}
      month
      date
      year
      validation={birthdayValidation}
      labels={{
        month: 'MM',
        date: 'DD',
        year: 'YYYY',
      }}
      size="lg"
      labelStyle={{
        background: 'transparent',
        borderRight: 'none',
      }}
      inputStyle={{
        fontSize: '14px',
        borderLeft: 0,
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export const MultiAddressInput = ({
  onChange,
  title,
  addressTypes,
  allowMultiple = true,
  customer,
}) => {
  const { t } = useTranslation('createCustomer');
  const dispatch = useDispatch();
  const { addresses = [] } = customer;

  return (
    <Form.Group style={{ marginTop: '1em' }} name="address">
      <Form.Label
        style={{
          display: 'flex',
          // width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <span data-testid="address-label" style={{ ...labelStyle }}>
          {title}
        </span>
        {allowMultiple && (
          <span
            className="link-span"
            onClick={e => {
              e.preventDefault();
              dispatch(
                openModalPage({
                  component: mp.extraAddress,
                  props: {
                    updateAddresses: newAddresses => onChange(newAddresses),
                    addressTypes,
                    customer,
                  },
                }),
              );
            }}
          >
            {t('address.other')}
          </span>
        )}
      </Form.Label>
      {/* TODO: fetch the address types and popuplate the options with them */}
      <AddressForm
        address={addresses[0] ?? {}}
        onChange={(k, v) =>
          onChange(
            [{ ...(addresses[0] ?? {}), [k]: v }].concat(addresses.slice(1)),
          )
        }
        addressTypes={addressTypes}
      />
    </Form.Group>
  );
};

const useAddressTypes = () => {
  const [addressTypes, setAddressTypes] = useState([]);
  useEffect(() => {
    /* get the address types, add them to state and if the customer doesnt have addressTypeID,
    add as default the first item in the addressTypes list */
    getAddressTypes({ lang: 'eng' }).then(addressTypes => {
      setAddressTypes(addressTypes);
    });
  }, []);
  return addressTypes;
};

const CustomerForm = ({ onChange, birthday, setBirthDay, customer }) => {
  const dispatch = useDispatch();
  const hasRightsToAddCustomer = useSelector(getCanAddCustomers) === 1;
  const hideHomeStore = useSelector(
    getUISetting('isCustomerHomeStoreDisabled'),
  );
  const customerAddition = !customer.customerID;
  const isTresspassing = !hasRightsToAddCustomer && customerAddition;
  const { t } = useTranslation('createCustomer');
  useEffect(() => {
    if (isTresspassing) {
      dispatch(previousModalPage()).then(
        dispatch(
          addWarning(t(`alerts.cannotAddCustomer`), {
            selfDismiss: 3000,
          }),
        ),
      );
    }
  }, [dispatch, isTresspassing, t]);

  const homeStores = useSelector(getAllHomeStores);
  const formattedHomeStores = homeStores.map(store => ({
    value: store.warehouseID,
    name: store.name,
  }));

  const addressTypes = useAddressTypes();
  useEffect(() => {
    // addressTypeID listed before p because this should only be set as the default
    // If there isn't any addressTypeID on the person already
    onChange(p =>
      p.addressTypeID
        ? p
        : { ...p, addressTypeID: addressTypes && addressTypes[0]?.id },
    );
  }, [addressTypes, onChange]);

  const customerGroups = useSelector(getSearchCustomerGroups);

  useEffect(() => {
    const firstAddressID = addressTypes?.[0]?.id;
    if (!customer.addressTypeID && firstAddressID)
      onChange(customer => ({ ...customer, addressTypeID: firstAddressID }));
  }, [addressTypes, customer.addressTypeID, onChange]);

  const customerRegistry = !!useSelector(getCustomerRegistryUrl);
  const handleChange = useCallback(
    event => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;

      if (name === 'address') {
        const { addressID, addressFieldKey } = event;

        const newAddresses = customer.addresses;
        const addressIndex = newAddresses.findIndex(
          a => a.addressID === addressID,
        );

        const newAddress = {
          ...customer.newAddress,
          ...newAddresses[addressIndex], // if index is negative this does nothing
          [addressFieldKey]: value,
        };
        if (addressIndex >= 0) {
          newAddresses[addressIndex] = newAddress;
        }
        return onChange({
          newAddress,
          ...customer,
          [name]: newAddresses,
        });
      }
      return onChange({
        ...customer,
        [name]: value,
      });
    },
    [customer, onChange],
  );

  const isCompany = customer.customerType === 'COMPANY';

  const groupNameOptions = useMemo(
    () =>
      customerGroups.map(el => ({
        name: el.name,
        value: el.customerGroupID,
      })),
    [customerGroups],
  );

  const formCommon = hideHomeStore
    ? form.common.filter(el => el.key !== 'homeStoreID')
    : form.common;

  formCommon.forEach((el, i) => {
    if (el.key === 'groupID') {
      formCommon[i].options = groupNameOptions;
    }
    if (el.key === 'homeStoreID' && formattedHomeStores.length) {
      formCommon[i].options = ['', ...formattedHomeStores];
    }
  });

  return (
    <Form style={{ width: '100%', overflowY: 'auto', paddingTop: '1em' }}>
      <PluginComponent
        hookname="UICustomerForm"
        props={{ value: customer, onChange, isCompany, addressTypes }}
      >
        {(isCompany ? form.company : form.person)
          .concat(formCommon)
          .map(field => (
            <Input
              field={field}
              key={field.key}
              data-testid={field.key}
              value={customer}
              onChange={handleChange}
              title={t(`fields.${field.key}`)}
            />
          ))}
        {customerRegistry && (
          <Input
            key="gender"
            field={form.other.gender}
            data-testid={form.other.gender.key}
            title={t(`fields.gender`)}
            value={customer}
            onChange={handleChange}
          />
        )}
        {customerRegistry && (
          <Input
            key="euCustomerType"
            field={form.other.euCustomerType}
            data-testid={form.other.euCustomerType.key}
            value={customer}
            onChange={handleChange}
            label={t('idCode.euCustomerType')}
          />
        )}
        {!isCompany && (
          <BirthdayInput
            key="birthday"
            labelStyle={labelStyle}
            title={t('fields.birthday')}
            value={birthday}
            onChange={setBirthDay}
          />
        )}
        <MultiAddressInput
          key="addresses"
          title={t('address.title')}
          addressTypes={addressTypes}
          onChange={useCallback(
            addresses => onChange({ ...customer, addresses }),
            [onChange, customer],
          )}
          allowMultiple={!!customer.id}
          customer={customer}
        />
        <Input
          key="code"
          label={isCompany ? t('registryCode.title') : t('idCode.title')}
          title={t('fields.code')}
          field={form.other.code}
          data-testid={form.other.code.key}
          value={customer}
          onChange={handleChange}
        />
        <Form.Group key="notes">
          <Form.Label style={{ ...labelStyle }}>{t('fields.notes')}</Form.Label>
          <FormControl
            as="textarea"
            rows="4"
            placeholder={t('fields.notes', { context: 'placeholder' })}
            name="notes"
            value={customer.notes}
            data-testid="notes"
            onChange={handleChange}
          />
        </Form.Group>
        <Input
          key="emailOptOut"
          field={form.other.emailOptOut}
          data-testid={form.other.emailOptOut}
          value={customer}
          onChange={handleChange}
        >
          {t('fields.emailOptOut')}
        </Input>
      </PluginComponent>
    </Form>
  );
};

CustomerForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  birthday: PropTypes.object.isRequired,
  setBirthDay: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
};

export default CustomerForm;
