import { Alert } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalState } from 'react-use';
import { useSelector } from 'react-redux';

import { getCurrentPosLanguage } from 'reducers/configs/settings';

const DISMISSED_PERMANENTLY = 'feb22-urgentalert-dismissed';

const useIsHidden = createGlobalState(
  !!localStorage.getItem(DISMISSED_PERMANENTLY),
);
export const useUnhideUrgentAlert = () => {
  const [, setHidden] = useIsHidden();
  return useCallback(() => {
    localStorage.removeItem(DISMISSED_PERMANENTLY);
    setHidden(false);
  }, []);
};

const messageTextDict = {
  en:
    'If you experience issues with our micro-services (payments, printing...), please, follow',
  fi:
    'Huomio! Jos havaitsette ongelmia mikropalveluidemme kanssa (kuitin tulostus, kassalipas), noudata',
  et:
    'Tähelepanu! Kui esineb tõrkeid mikroteenustega (tšeki printimine, kassasahtel, kaardimaksed), järgige palun juhiseid',
};
const linkTextDict = {
  en: 'this article',
  fi: 'tätä artikkelia',
  et: 'selles artiklis',
};

const linkAddress =
  'https://wiki.erply.com/et/article/1823-microservice-and-installer-app-issue-resolution-18th-feb-23';

export const UrgentAlert = ({ isRelevant }: { isRelevant: boolean }) => {
  const lang = useSelector(getCurrentPosLanguage);
  const [hidden, setHidden] = useIsHidden();
  const onDismiss = () => {
    localStorage.setItem(DISMISSED_PERMANENTLY, 'true');
    setHidden(true);
  };
  if (!isRelevant) return null;
  if (hidden) return null;
  const target = document.querySelector('[data-portal=urgent-alert-container]');
  if (!target) return null;

  const messageText = messageTextDict[lang] ?? messageTextDict.en;
  const linkText = linkTextDict[lang] ?? linkTextDict.en;

  return ReactDOM.createPortal(
    <Alert
      severity="warning"
      style={{ textAlign: 'center' }}
      action={
        <Button color="inherit" size="small" onClick={onDismiss}>
          Close
        </Button>
      }
    >
      {`${messageText} `}
      <a target="_blank" rel="noopener noreferrer" href={linkAddress}>
        {linkText}
      </a>
    </Alert>,
    target,
  );
};
