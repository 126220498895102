import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';

const singlePrompt = (
) => async (dispatch, getState) => {
    const state = getState();

    return new Promise((resolve, reject) =>
        dispatch(
            openModalPage({
                component: mp.pluginModal,
                props: {
                    name: 'GarminFiscalModal',
                    resolve,
                    reject,
                    closeModal: () => {
                        dispatch(previousModalPage());
                    },
                    onCancel: () => {
                        dispatch(previousModalPage());
                        resolve(false);
                    },
                },
                isPopup: true,
            }),
        ),
    );
};

export default singlePrompt;
