import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import ListItem from 'components/ListItem';
import { PosPlugin } from 'plugins/plugin';
import { getIsAReturn } from 'reducers/sales';
import { getBalance as getBalanceSelector } from 'reducers/Payments';
import { setPayment } from 'actions/Payments/setPayment';

import { getWbuConfiguration } from '../configuration';

const ButtonItem = ({ style = {}, ...props }) => (
  <ListItem
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontFamily: 'Proxima Nova',
      minWidth: 80,
      minHeight: 76,
      height: 'inherit',
      width: 'inherit',
      ...style,
    }}
    {...props}
  />
);

const UIKeyPad: PosPlugin['UIKeyPad'] = ({ children }) => {
  const dispatch = useDispatch();
  const balance = useSelector(getBalanceSelector);
  const { customPayments } = useSelector(getWbuConfiguration);
  const isAReturn = useSelector(getIsAReturn);
  if (isAReturn) return children;

  const addCustomPayment = payment => {
    dispatch(
      setPayment({
        key: uuidv4(),
        amount: balance,
        type: payment.name,
        cardType: payment.cardType,
        caption: payment.name,
      }),
    );
  };
  return R.pipe(
    R.insertAll(
      0,
      customPayments
        .filter(payment => payment.enabled)
        .map(payment => (
          <ButtonItem
            variant="grid_yellow"
            action={() => addCustomPayment(payment)}
            style={{ fontSize: '14px', wordBreak: 'break-word' }}
          >
            {payment.name}
          </ButtonItem>
        )),
    ),
  )(React.Children.toArray(children));
};

export default UIKeyPad;
