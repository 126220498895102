import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import * as R from 'ramda';
import { TOptions } from 'i18next';

import * as actionTypes from 'constants/returnProducts';
import {
  getCanProductBeReturned,
  isRowFullyReturned,
  isSaleFullyReturned,
} from 'utils';
import { JDoc, ReturnRow } from 'types/ReturnTable';
import { RootState } from 'reducers';

import { createOverrideSelector } from './Plugins';

const initialState = {
  salesDocs: [],
  returnDocument: null,
  docSelected: null,
  ordersSelected: {},
  returnedRows: [],
  loading: false,
  isGiftReturn: false,
};

function salesDocs(state = initialState.salesDocs, { type, payload }) {
  switch (type) {
    default:
      return state;
    case actionTypes.SET_SALES_DOCS:
      return payload;
    case actionTypes.RESET_SALES_DOCS:
    case actionTypes.RESET_TO_DEFAULT:
      return initialState.salesDocs;
  }
}

function docSelected(state = initialState.docSelected, { type, payload }) {
  switch (type) {
    default:
      return state;
    case actionTypes.SET_DOC_SELECTED:
      return payload;
    case actionTypes.RESET_DOC_SELECTED:
    case actionTypes.RESET_TO_DEFAULT:
      return initialState.docSelected;
  }
}

function ordersSelected(
  state = initialState.ordersSelected,
  { type, payload },
) {
  switch (type) {
    default:
      return state;
    case actionTypes.SET_ORDERS_SELECTED:
      if (R.equals(state, payload)) return state;
      return payload;
    case actionTypes.SET_ONE_ORDER_SELECTED:
      if (R.equals(state[payload.id], payload.order)) return state;
      return R.assoc(payload.id, payload.order, state);
    case actionTypes.RESET_TO_DEFAULT:
      return initialState.ordersSelected;
  }
}

function returnDocument(state = null, { type, payload }) {
  switch (type) {
    default:
      return state;
    case actionTypes.SET_RETURN_DOCUMENT:
      return payload;
    case actionTypes.RESET_RETURN_DOCUMENT:
    case actionTypes.RESET_TO_DEFAULT:
      return initialState.returnDocument;
  }
}

function returnedRows(state = initialState.returnedRows, { type, payload }) {
  switch (type) {
    default:
      return state;
    case actionTypes.SET_RETURNED_ROWS:
      return payload;
    case actionTypes.RESET_TO_DEFAULT:
      return initialState.returnedRows;
  }
}

function loading(state = false, { type, payload }) {
  if (type === actionTypes.SET_LOADING) {
    return payload;
  }
  return state;
}

function isGiftReturn(state = initialState.isGiftReturn, { type, payload }) {
  switch (type) {
    case actionTypes.SET_IS_GIFT_RETURN:
      return payload;
    case actionTypes.RESET_TO_DEFAULT:
      return initialState.isGiftReturn;
    default:
      return state;
  }
}

export default combineReducers({
  salesDocs,
  docSelected,
  ordersSelected,
  returnDocument,
  returnedRows,
  loading,
  isGiftReturn,
});

function getReturnProductSlice(state) {
  return state.returnProducts;
}

export const getSalesDocs = createSelector(
  getReturnProductSlice,
  state => state.salesDocs,
);

export const getReturnDocument = createSelector(getReturnProductSlice, state =>
  state.salesDocs.find(doc => doc.id === state.returnDocument),
);

export const getDocSelected = createSelector(
  getReturnProductSlice,
  state => state.docSelected,
);

const getOriginalOrders = createSelector(
  getReturnProductSlice,
  state => state.ordersSelected,
);
const getOrdersBase = createSelector(
  state => getReturnDocument(state),
  state => getOriginalOrders(state),
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  state => getReturnedRowIDsAndAmounts(state),
  (document, original, returned) =>
    R.pipe(
      R.map((docRow: ReturnRow) => {
        // For sales, made in pre-historic times, assign the rowID as stableRowID
        const row: ReturnRow & { stableRowID: string } = R.assoc(
          'stableRowID',
          docRow.stableRowID ?? docRow.rowID,
        )(docRow);
        const origs = returned.filter(
          orig => orig.stableRowID === row.stableRowID,
        );
        const order = original[row.stableRowID] ?? {};
        const remainingAmount =
          Number(row.amount) + origs.map(o => o.amount).reduce(R.add, 0);
        return R.mergeDeepLeft({
          ...order,
          originalAmount: Number(row.amount),
          amount:
            order.amount <= remainingAmount ? order.amount : remainingAmount,
          remainingAmount,
        })(row);
      }),

      // Move jdoc properties onto root level because POS wants it that way for some reason
      R.map(p => ({ ...p, ...p.jdoc?.BrazilPOS })),
    )(document?.rows ?? []) as (ReturnRow & JDoc['BrazilPOS'])[],
);

export const getOrders = createOverrideSelector('getOrders', getOrdersBase);

export const getReturnableOrdersBaseFn = createSelector(
  state => getOrders(state),
  state => getReturnedRowIDsAndAmounts(state),
  (rows, returnedRows) => products => {
    return rows.filter(row => {
      const product = products?.find(
        prod => String(row.productID) === String(prod.productID),
      );
      if (!product) return false;

      const canBeReturned = getCanProductBeReturned(product);
      const isFullyReturned = isRowFullyReturned(row, returnedRows);

      return canBeReturned && !isFullyReturned && row.remainingAmount > 0;
    });
  },
);

export const getReturnableOrdersFn = createOverrideSelector(
  'getReturnableOrdersFn',
  getReturnableOrdersBaseFn,
);

export const getOrdersSelected = createSelector(
  getOrders,
  orders =>
    Object.fromEntries(
      Object.entries(orders).filter(([k, v]: any) => v.selected),
    ) ?? {},
);

export function getModalTitleProps(state: RootState): TOptions | undefined {
  const returnDocument = getReturnDocument(state);
  const salesDocs = getSalesDocs(state);

  if (salesDocs.length > 1) {
    return { context: 'multipleMatches' };
  }
  if (returnDocument) {
    return {
      context: 'match',
      number: returnDocument.number,
      name: returnDocument.clientName.replace(',', ''),
    };
  }
  return undefined;
}

export function getSalesDocByID(documentID) {
  return createSelector(
    getReturnProductSlice,
    state =>
      state.salesDocs.find(document => document.id === documentID) || null,
  );
}

export const getHasItemsSelected = createSelector(
  getReturnProductSlice,
  state =>
    Object.values(state.ordersSelected).reduce((anySelected, order: any) => {
      return anySelected || order.selected;
    }, false),
);

export const getReturnedRowIDsAndAmounts = createSelector(
  getReturnProductSlice,
  (state): { productID: string; amount: number; stableRowID: string }[] =>
    state.returnedRows.map(({ productID, amount, stableRowID }) => ({
      productID,
      amount,
      stableRowID,
    })),
);

export const getIsLoading = createSelector(
  getReturnProductSlice,
  state => state.loading,
);

export const getReturnedDocWarehouseID = createSelector(
  getReturnProductSlice,
  state => state.salesDocs[0]?.warehouseID,
);

export const getInvoiceFullyReturned = createSelector(
  getReturnDocument,
  getReturnedRowIDsAndAmounts,
  (state = {}, returnedRows = []) => {
    switch (state.type) {
      case 'CREDITINVOICE':
      case 'INVWAYBILL':
      case 'CASHINVOICE': {
        return isSaleFullyReturned(state, returnedRows);
      }
      default:
        return false;
    }
  },
);

export function getIsGiftReturn(state: RootState) {
  return getReturnProductSlice(state).isGiftReturn;
}
