import { getPointsOfSale } from 'services/ErplyAPI';

import LocalDatabase from './core';

export default class LastInvoiceNumbers extends LocalDatabase {
  constructor() {
    super({ TABLE_NAME: 'lastInvoiceNumbers' });
  }

  async setInitialNumber(pointOfSaleID) {
    try {
      const response = await getPointsOfSale({ pointOfSaleID });
      const initialNumbers = { [pointOfSaleID]: response[0].lastInvoiceNo };
      const lastLocalInvoiceNumber =
        (await this.getItem({
          key: pointOfSaleID,
        })) ?? '';

      if (lastLocalInvoiceNumber < initialNumbers[pointOfSaleID]) {
        this.localData[this.CLIENT_CODE] = initialNumbers;
        await this.saveItem({
          key: pointOfSaleID,
          value: response[0].lastInvoiceNo,
        });
      }
    } catch (err) {
      console.error('Failed to load initial invoice number for POS ', pointOfSaleID, err);
    }
  }

  async increment(posID) {
    try {
      const value = `${Number(this.localData[this.CLIENT_CODE][posID]) + 1}`;
      await this.saveItem({
        key: posID,
        value,
      });
      return value;
    } catch (e) {
      console.error('Failed to increment invoice number', e);
    }
    return null;
  }
}
