export default ({
  addressID = undefined,
  ownerID = undefined,
  typeID = undefined,
  street = undefined,
  address2 = undefined,
  city = undefined,
  postalCode = undefined,
  state = undefined,
  country = undefined,
}) => {
  const data = {
    addressLine1: street,
    addressLine2: address2,
    addressTypeID: typeID,
    city,
    country,
    id: addressID,
    postalCode,
    state,
  };
  Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
  return data;
};
