export const initialState = {};

export const forms = {
  groupForm: [{ key: 'name', type: 'text' }],
  productForm: {
    list: [
      { key: 'name', type: 'text', required: true },
      { key: 'code', type: 'text' },
      { key: 'groupID', type: 'select', required: true },
      { key: 'vatrateID', type: 'select' },
      { key: 'netPrice', type: 'text', required: true },
      { key: 'priceWithVAT', type: 'text' },
      { key: 'unitID', type: 'select' },
    ],
    checkbox: { key: 'nonStockProduct', value: false },
  },
};
