import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import * as customerActionTypes from 'constants/customerSearch';
import * as loginActionTypes from 'constants/Login';
import * as persistenceTypes from 'constants/persistence';
import { ErplyLongAttributes } from 'utils';

import { createOverrideSelector } from './Plugins';

function defaultCustomer(state = {}, { type, data }) {
  switch (type) {
    case customerActionTypes.GET_DEFAULT_CUSTOMER.SUCCESS:
      return data;
    case loginActionTypes.TYPE_LOGOUT:
      return {};
    default:
      return state;
  }
}

function customer(state = null, { type, data, payload }) {
  switch (type) {
    case customerActionTypes.SET_CUSTOMER_SUCCESS:
    case customerActionTypes.SET_ONE_CUSTOMER_SUCCESS:
      return data;
    case customerActionTypes.GET_CUSTOMER_STORE_CREDIT_SUCCESS:
      return {
        ...state,
        availableCredit: payload,
      };
    case customerActionTypes.SAVE_CUSTOMER_CARD_TOKEN_INFO:
      return {
        ...state,
        longAttributes: new ErplyLongAttributes(state.longAttributes).set(
          'cardToken',
          JSON.stringify(data),
        ).asArray,
      };

    case loginActionTypes.TYPE_LOGOUT:
    case customerActionTypes.RESET_CUSTOMER:
      return null;
    default:
      return state;
  }
}

function customerGroups(state = [], { type, data }) {
  switch (type) {
    case customerActionTypes.GET_CUSTOMER_GROUPS_SUCCESS:
      return data;
    case loginActionTypes.TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}

function customerIssuedCoupons(state = [], { type, data, error }) {
  switch (type) {
    case customerActionTypes.SET_CUSTOMER_ISSUED_COUPONS.SUCCESS:
      return data;
    // If there was an error, then the state should not be changed to text, but rather should be set to default value aka empty array aka []
    case customerActionTypes.SET_CUSTOMER_ISSUED_COUPONS.ERROR:
    case customerActionTypes.RESET_CUSTOMER:
    case loginActionTypes.TYPE_LOGOUT:
    case loginActionTypes.TYPE_LOGOUT_SOFT:
      return [];
    default:
      return state;
  }
}

function customerRewardPoints(state = 0, { type, data, error }) {
  switch (type) {
    case customerActionTypes.GET_CUSTOMER_REWARD_POINTS_SUCCESS:
      return data;
    case customerActionTypes.GET_CUSTOMER_REWARD_POINTS_ERROR:
      return error;
    case customerActionTypes.RESET_CUSTOMER:
    case customerActionTypes.SET_CUSTOMER:
    case loginActionTypes.TYPE_LOGOUT:
      return 0;
    default:
      return state;
  }
}

function addressTypes(state = [], { type, data }) {
  switch (type) {
    case customerActionTypes.GET_ADDRESS_TYPES_SUCCESS:
      return data;
    case loginActionTypes.TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}

function personTitles(state = [], { type, data }) {
  switch (type) {
    case customerActionTypes.GET_PERSON_TITLES_SUCCESS:
      return data;
    case loginActionTypes.TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}

function jobTitles(state = [], { type, data }) {
  switch (type) {
    case customerActionTypes.GET_JOB_TITLES_SUCCESS:
      return data;
    case loginActionTypes.TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}

function loading(state = {}, { type, payload }) {
  switch (type) {
    case customerActionTypes.SET_CUSTOMER:
      return { ...state, customer: true };
    case customerActionTypes.SET_CUSTOMER_SUCCESS:
    case customerActionTypes.SET_CUSTOMER_ERROR:
      return { ...state, customer: false };
    case customerActionTypes.GET_CUSTOMER_REWARD_POINTS:
      return { ...state, points: true };
    case customerActionTypes.GET_CUSTOMER_REWARD_POINTS_SUCCESS:
    case customerActionTypes.GET_CUSTOMER_REWARD_POINTS_ERROR:
      return { ...state, points: false };

    case customerActionTypes.GET_CUSTOMER_STORE_CREDIT:
      return { ...state, credit: true };
    case customerActionTypes.GET_CUSTOMER_STORE_CREDIT_SUCCESS:
      return { ...state, credit: false };

    case loginActionTypes.TYPE_LOGOUT:
      return [];
    default:
      return state;
  }
}

export default combineReducers({
  defaultCustomer,
  customer,
  customerGroups,
  addressTypes,
  personTitles,
  jobTitles,
  customerIssuedCoupons,
  customerRewardPoints,
  loading,
});

function getSlice(state) {
  return state.customerSearch;
}

export function getIsLoadingCustomer(state) {
  return Object.values(state.customerSearch.loading).some(v => v);
}

/**
 * @param {import('reducers').RootState} state
 * @returns {import('types/Coupon').IssuedCoupon[]}
 */
export function getCustomerIssuedCoupons(state) {
  return getSlice(state).customerIssuedCoupons;
}

export function getSelectedCustomerID(state) {
  return (getSlice(state).customer || getSlice(state).defaultCustomer).id;
}

function getDefaultCustomerID(state) {
  return getSlice(state).defaultCustomer.id;
}

export function getDefaultCustomer(state) {
  return getSlice(state).defaultCustomer;
}

export function getIsDefaultCustomer(state) {
  if (!getSlice(state).customer) return true;

  return getDefaultCustomer(state).id === getSelectedCustomerID(state);
}

export function getSelectedCustomer(state) {
  if (getIsDefaultCustomer(state)) return getSlice(state).defaultCustomer;
  return getSlice(state).customer;
}

export function getSearchCustomerGroups(state) {
  return getSlice(state).customerGroups;
}

export function getCustomerRewardPointsSelector(state) {
  return Number(getSlice(state).customerRewardPoints) || 0;
}

export function getCustomerGroups(state) {
  return state.customerSearch.customerGroups;
}

const getDefaultCustomerGroupBase = createSelector(
  getDefaultCustomer,
  getCustomerGroups,
  ({ groupID }, groups) =>
    groups.find(g => String(g.customerGroupID) === String(groupID)),
);

export const getDefaultCustomerGroup = createOverrideSelector(
  'getDefaultCustomerGroup',
  getDefaultCustomerGroupBase,
);

export function getCustomerGroupByID(groupID) {
  return state =>
    getCustomerGroups(state).find(group => group.clientGroupID === groupID);
}

export const getSelectedCustomerGroupHierarchyByID = createSelector(
  getSelectedCustomer,
  getCustomerGroups,
  (customer, groups) => {
    function getGroupHierarchy(parentGroupID, hierarchy) {
      if (!parentGroupID) return hierarchy;
      const parentGroup = groups.find(
        group => group.customerGroupID === parentGroupID,
      );
      return getGroupHierarchy(parentGroup?.parentID, [
        parentGroupID,
        ...hierarchy,
      ]);
    }

    const hierarchy = [customer.groupID];
    const group = groups.find(
      group => group.customerGroupID === customer.groupID,
    );
    return getGroupHierarchy(group?.parentID, hierarchy);
  },
);

export function getCustomerRegistryUrl() {
  return localStorage.getItem(persistenceTypes.REDUX_CUSTOMER_REGISTRY_URL);
}

export function getCustomerRegistryToken() {
  return localStorage.getItem(persistenceTypes.REDUX_CUSTOMER_REGISTRY_TOKEN);
}

export const getAddressTypes = createSelector(
  getSlice,
  slice => slice.addressTypes,
);
export const getPersonTitles = createSelector(
  getSlice,
  slice => slice.personTitles,
);

export const getJobTitles = createSelector(getSlice, slice => slice.jobTitles);

export const getSelectedCustomerAddresses = createSelector(
  getSelectedCustomer,
  selectedCustomer => selectedCustomer?.addresses,
);

// selector for all card tokens, mounted or unmounted
export const getSelectedCustomerAllCardTokenAttribute = createSelector(
  getSelectedCustomer,
  selectedCustomer => {
    const attributes = new ErplyLongAttributes(
      selectedCustomer.longAttributes,
    ).get('cardToken');

    if (attributes) {
      const allCardtokensAttribute = JSON.parse(attributes);
      return allCardtokensAttribute;
    }
    return [];
  },
);

// selector for getting the mounted card only
export const getSelectedCustomerMountedCardTokenAttribute = createSelector(
  getSelectedCustomer,
  selectedCustomer => {
    const attributes = new ErplyLongAttributes(
      selectedCustomer.longAttributes,
    ).get('cardToken');

    if (!attributes) return null;

    const cardTokenAttributes = JSON.parse(attributes);
    const selectedCustomerAttribute = cardTokenAttributes.find(
      attr => attr.mounted,
    );
    return selectedCustomerAttribute;
  },
);

export function getIsNestedChildCustomerGroupFn(state) {
  function isChild(groupID, parentGroupID) {
    if (!Number(groupID)) return false;
    const group = getCustomerGroupByID(groupID)(state);
    if (group?.parentID === parentGroupID) return true;
    return isChild(group?.parentID, parentGroupID);
  }
  return isChild;
}
