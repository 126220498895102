import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { PluginComponent } from 'plugins';
import { getUISetting } from 'reducers/configs/settings';
import { useMeasurement } from 'components/List';
import CalculateAndPayButton from 'components/CalculateButton';
import './Actions.scss';
import {
  RedButton,
  YellowButtons,
  PopupButton,
} from 'components/ActionButtons';

import SaleOptions from '../SaleOptions';
import Functions from '../Functions';

import { ActionsProps } from './types';

const widthPresets = [
  [0, 0, 0, 0],
  [0, 0, 0, 1],
  [0, 0, 1, 1],
  [0, 1, 1, 1],
  [1, 1, 1, 1],
  [1, 1, 1, 2],
  [1, 1, 2, 2],
  [1, 1, 2, 3],
];

const linearPreset = [1, 1, 2, 2];

const Actions: React.FC<ActionsProps> = ({ isLinear }) => {
  const hideSwitchUser = useSelector(getUISetting('hideSwitchUser'));

  const { t } = useTranslation('gridButtons');

  const classNameFor = (n: number) =>
    classNames('btn-long', 'button-item', {
      'btn-double': n === 2,
      'btn-triple': n === 3,
    });

  const {
    needsToMeasure,
    Measure,
    width = widthPresets.length - 1,
  } = useMeasurement({
    targetHeight: 1000,
    component: useCallback(
      () => (
        <button aria-label="button" type="button" className={classNameFor(1)} />
      ),
      [],
    ),
  });
  if (needsToMeasure) return Measure;

  let [w1, w2, w3, w4] = widthPresets[width] ?? R.last(widthPresets);

  if (hideSwitchUser) {
    w2 += w1;
    w1 = 0;
  }

  if (isLinear) {
    [w1, w2, w3, w4] = linearPreset;
    if (hideSwitchUser) {
      w2 += w1;
      w1 = 0;
    }
  }

  return (
    <div
      style={
        isLinear
          ? {
              display: 'flex',
              flexFlow: 'row wrap',
              maxWidth: '206px',
            }
          : {}
      }
    >
      <YellowButtons width={w2} />
      <RedButton width={w3} />
      {/* Green button */}
      <CalculateAndPayButton width={w4} />
      {isLinear && (
        <Box display="flex" width="100%">
          <PopupButton title={t('saleOptions.title')}>
            <SaleOptions />
          </PopupButton>
          <PopupButton title={t('functions.title')}>
            <Functions />
          </PopupButton>
        </Box>
      )}
      <PluginComponent hookname="UIExtraActionButtons" />
    </div>
  );
};

export default Actions;
