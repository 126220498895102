import { getPluginConfiguration } from 'reducers/Plugins';

import meta from './meta';
import { createSelector } from 'reselect';

export type Configuration = {
  frequency: number;
};

export const getConfiguration = createSelector(
  getPluginConfiguration<Configuration>(meta.pluginID),
  conf => conf ?? { frequency: 1 },
);
