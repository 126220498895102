import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { getARTemplates } from 'services/SalesAPI';
import { RootState } from 'reducers';
import { getClientCode } from 'reducers/Login';
import { addSuccess } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import MuiButton from 'components/CustomButtons/MuiButton';

import { defaultVoucherTypeID } from '../constants';
import { Configuration } from '../types';
import { OrderNotificationConfigurationInput } from '../orderNotification/OrderNotificationConfigurationInput';

/**
 * Field should only be visible for franchise account (500).
 */
const clientCodesAllowedToUseIntegratedSolution = [500];

const ComponentConfiguration: Required<
  PosPlugin
>['ComponentConfigurationByLevel']['Company'] = ({
  current = {
    PaymentTypeID: defaultVoucherTypeID,
    UseIntegratedVouchers: false,
    businessID: '',
    clientID: '',
    clientSecret: '',
  },
  save,
}: {
  current: Configuration;
  save: (value: Configuration) => void;
}) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const existingPaymentTypes = useSelector(getAllPaymentTypes);

  const { value: receiptTemplates, loading } = useAsync(getARTemplates, []);
  const clientCode = useSelector(getClientCode);
  /**
   * Specific request from customer - if used by Franchise account (500), then this field needs to be enabled by default, but still configurable.
   *
   * If not among allowed CC, then this must always be disabled.
   */
  const defaultUseIntegratedVouchers = clientCodesAllowedToUseIntegratedSolution.includes(
    Number(clientCode),
  );

  const [configState, setConfigState] = useState(current);

  const useIntegratedVouchersValueWithFallback =
    configState?.UseIntegratedVouchers ?? defaultUseIntegratedVouchers;

  const handleChange = useCallback(
    e => {
      setConfigState({
        ...configState,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });
    },
    [configState],
  );

  useEffect(() => {
    // If non-Franchise account has setting enabled, disable it
    if (
      !clientCodesAllowedToUseIntegratedSolution.includes(Number(clientCode)) &&
      !!configState?.UseIntegratedVouchers
    ) {
      handleChange({
        target: {
          name: 'UseIntegratedVouchers',
          value: false,
        },
      });
    }
  }, [clientCode, configState, handleChange]);

  const handleSave = () => {
    save(configState);
    dispatch(addSuccess('Configuration saved'));
    dispatch(previousModalPage());
  };

  return (
    <>
      <MuiButton
        onClick={() => handleSave()}
        variant="contained"
        color="UIButton"
        disabled={
          configState?.UseIntegratedVouchers &&
          (!configState?.businessID?.length ||
            !configState?.clientID?.length ||
            !configState?.clientSecret?.length)
        }
        style={{ marginTop: '10px', marginBottom: '10px' }}
      >
        Save
      </MuiButton>

      <div>
        <Typography
          variant="h6"
          style={{ marginBottom: '1.5rem', marginTop: '1rem' }}
        >
          Pusher connection for notifications
        </Typography>
        <Box margin={2} padding={2}>
          <OrderNotificationConfigurationInput
            value={configState?.orderNotification}
            onChange={v =>
              setConfigState(cs => ({
                ...cs,
                orderNotification: v,
              }))
            }
          />
        </Box>
      </div>

      <div>
        <Typography
          variant="h6"
          style={{ marginBottom: '1.5rem', marginTop: '1rem' }}
        >
          Configure NSW Voucher
        </Typography>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="nsw-vouchers-config-select-label">
            Payment type to use as NSW Voucher
          </InputLabel>
          <Select
            label="Payment type to use as NSW Voucher"
            labelId="nsw-vouchers-config-select-label"
            id="nsw-vouchers-config-select"
            name="PaymentTypeID"
            value={configState?.PaymentTypeID ?? defaultVoucherTypeID}
            onChange={handleChange}
          >
            {existingPaymentTypes.map(ept => (
              <MenuItem value={ept.id}>{ept.name}</MenuItem>
            ))}
          </Select>
          {clientCodesAllowedToUseIntegratedSolution.includes(
            Number(clientCode),
          ) ? (
            <>
              <FormControlLabel
                label="Use integrated NSW Voucher solution"
                control={
                  <Checkbox
                    name="UseIntegratedVouchers"
                    checked={useIntegratedVouchersValueWithFallback}
                    onChange={() => {
                      handleChange({
                        // Checkbox does not have target in the event, thus need to generate it's own target
                        target: {
                          name: 'UseIntegratedVouchers',
                          value: !useIntegratedVouchersValueWithFallback,
                        },
                      });
                    }}
                  />
                }
              />
              <TextField
                name="businessID"
                fullWidth
                required={!!configState?.UseIntegratedVouchers}
                variant="outlined"
                label="Business ID"
                onChange={e => handleChange(e)}
                value={configState?.businessID ?? ''}
                error={
                  !!configState?.UseIntegratedVouchers &&
                  !configState?.businessID?.length
                }
                helperText={
                  !!configState?.UseIntegratedVouchers &&
                  !configState?.businessID?.length
                    ? 'Required field'
                    : ''
                }
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="clientID"
                fullWidth
                required={!!configState?.UseIntegratedVouchers}
                variant="outlined"
                label="Client ID"
                onChange={e => handleChange(e)}
                value={configState?.clientID ?? ''}
                error={
                  !!configState?.UseIntegratedVouchers &&
                  !configState?.clientID?.length
                }
                helperText={
                  !!configState?.UseIntegratedVouchers &&
                  !configState?.clientID?.length
                    ? 'Required field'
                    : ''
                }
                style={{ marginTop: '10px' }}
              />
              <TextField
                name="clientSecret"
                fullWidth
                required={!!configState?.UseIntegratedVouchers}
                variant="outlined"
                label="Client Secret"
                onChange={e => handleChange(e)}
                value={configState?.clientSecret ?? ''}
                error={
                  !!configState?.UseIntegratedVouchers &&
                  !configState?.clientSecret?.length
                }
                helperText={
                  !!configState?.UseIntegratedVouchers &&
                  !configState?.clientSecret?.length
                    ? 'Required field'
                    : ''
                }
                style={{ marginTop: '10px' }}
              />
            </>
          ) : null}
        </FormControl>
      </div>
      <div>
        <Typography
          variant="h6"
          style={{ marginBottom: '1.5rem', marginTop: '1rem' }}
        >
          Second receipt
        </Typography>
        <TextField
          select
          fullWidth
          label="Template for the second return receipt"
          variant="outlined"
          name="secondReceiptTemplate"
          value={configState?.secondReceiptTemplate ?? 0}
          onChange={handleChange}
        >
          <MenuItem key={0} value={0}>
            <em>Select receipt template</em>
          </MenuItem>
          {loading ? (
            <MenuItem key="loading" value={0} disabled>
              Loading templates...
            </MenuItem>
          ) : (
            receiptTemplates?.map(template => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))
          )}
        </TextField>
      </div>
      <div>
        <Typography
          variant="h6"
          style={{ marginBottom: '1.5rem', marginTop: '1rem' }}
        >
          Qantas Frequent Flyer
        </Typography>
        <TextField
          fullWidth
          multiline
          minRows={4}
          maxRows={6}
          label="Message to be printed on receipt"
          variant="outlined"
          name="messageOnReceipt"
          value={configState?.messageOnReceipt ?? ''}
          onChange={handleChange}
        />
        <FormControlLabel
          label="Prompt for card number when paying"
          control={
            <Checkbox
              name="promptForQffCardNumberOnPay"
              checked={configState?.promptForQffCardNumberOnPay ?? false}
              onChange={handleChange}
            />
          }
        />
      </div>
    </>
  );
};

export default ComponentConfiguration;
