import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import debug from 'debug';

import { getSelectedWarehouseID } from 'reducers/warehouses';
import { hardLogout } from 'actions/Login';
import { getDeviceID } from 'actions/integrations/metricsCollector/actions';
import { RootState } from 'reducers';
import { getHasRightToEditSettings } from 'reducers/Login';
import { getPosAssociationConfig } from 'reducers/configs/settings';
import { useConfirmation } from 'components/Confirmation';
import { getSelectedPosID } from 'reducers/PointsOfSale';

const baseLog = debug('useAssociatedPos');

const log = baseLog.extend('autoSelectPos');

function useAssociatedPos() {
  const { t } = useTranslation('settingsAssociatePos');
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const confirm = useConfirmation();

  const { enabled, associations, allowOnlyAssociatedDevices } = useSelector(
    getPosAssociationConfig,
  );
  const hasRightToEditPosAssociations = useSelector(getHasRightToEditSettings);
  const selectedPosID = useSelector(getSelectedPosID);
  const selectedWarehouseID = useSelector(getSelectedWarehouseID);

  const { value: deviceID, loading } = useAsync(() => dispatch(getDeviceID()), [
    dispatch,
  ]);

  const posID = useMemo(() => {
    if (loading) return null;
    if (!deviceID) return null;
    const hasAssociationConfig = !!Object.keys(associations).length;
    if (!hasAssociationConfig) return null;
    return associations[deviceID];
  }, [associations, deviceID, loading]);

  const warnAndKickOut = useCallback(() => {
    dispatch(hardLogout());
    if (deviceID) {
      confirm({
        title: t('confirmations.accessDenied.title'),
        body: t('confirmations.accessDenied.body'),
        noReject: true,
      });
    } else {
      confirm({
        title: t('confirmations.missingDeviceID.title'),
        body: t('confirmations.missingDeviceID.body'),
        noReject: true,
      });
    }
  }, [confirm, deviceID, dispatch, t]);

  const autoSelect = useCallback(
    (id, onSelect: (id: number | string) => void, isPos: boolean) => {
      const alreadySelected = isPos ? selectedPosID : selectedWarehouseID;
      if (alreadySelected) return;

      const type = isPos ? 'POS' : 'warehouse';
      log(`Checking if the device is associated with any ${type}`, id);
      if (id) {
        log('Selecting associated POS', id);
        onSelect(id);
        return;
      }
      if (posID) {
        log(`The user has no access rights for associated ${type}`, id);
        confirm({
          title: t('confirmations.noAccessRightsForAssociatedPos.title'),
          body: t('confirmations.noAccessRightsForAssociatedPos.body'),
          noReject: true,
        });
        if (!hasRightToEditPosAssociations) {
          log('The user has no configuration editing rights. Logging out');
          dispatch(hardLogout());
          return;
        }
      }
      if (hasRightToEditPosAssociations) {
        log('The user has configuration editing rights. Let user select POS');
        return;
      }
      if (allowOnlyAssociatedDevices) {
        log('Unknown devices are not allowed. Logging out');
        warnAndKickOut();
      }

      log('Unknown devices are allowed. Let user select POS');
    },
    [
      selectedPosID,
      selectedWarehouseID,
      posID,
      hasRightToEditPosAssociations,
      allowOnlyAssociatedDevices,
      confirm,
      t,
      dispatch,
      warnAndKickOut,
    ],
  );

  return {
    loading,
    enabled,
    posID,
    autoSelect,
  };
}

export default useAssociatedPos;
