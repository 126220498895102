import * as api from 'services/ErplyAPI/productUnits';
import * as c from 'constants/productUnitsDB';

export function fetchProductUnits() {
  return async dispatch => {
    const vatRates = await api.getProductUnits();
    dispatch({
      type: c.SET_PRODUCTUNITS,
      payload: vatRates,
    });
  };
}
