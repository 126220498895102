import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField, Typography, Button } from '@material-ui/core';

import CloseButton from 'components/CustomButtons/CloseButton';
import { updateCurrSaleDocTaxExNumber } from 'actions/sales';
import { getCurrentSalesDocument } from 'reducers/sales';
import { onTaxExempt } from 'actions/Plugins';
import { getTaxFreeVatRate } from 'reducers/vatRatesDB';
import { addError } from 'actions/Error';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { calculate } from 'actions/ShoppingCart/calculate';

type Props = {
  onClose: () => void;
};

const TaxExempt: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('taxExempt');
  const [id, setid] = useState<string | undefined>(
    useSelector(getCurrentSalesDocument).taxExemptCertificateNumber,
  );
  const dispatch = useDispatch();

  const vatrate = useSelector(getTaxFreeVatRate);

  const onDone = async (isPartialExempt = false) => {
    if (!id) {
      dispatch(addError(t('alerts.missingCertificateNumber')));
      return;
    }

    await dispatch(
      updateCurrSaleDocTaxExNumber({
        taxExemptCertificateNumber: id,
        isPartialExempt,
      }),
    );
    await dispatch(onTaxExempt({}));
    await dispatch(calculate());
    onClose();
  };

  const resetTaxExemptNumber = async () => {
    setid('');
    await dispatch(
      updateCurrSaleDocTaxExNumber({
        taxExemptCertificateNumber: '',
        isReseting: true,
      }),
    );
    await dispatch(onTaxExempt({}));
    await dispatch(calculate());
  };

  useEffect(() => {
    if (!vatrate) {
      onClose();
      dispatch(addError(t('alerts.missingZeroVatRate')));
    }
  }, [dispatch, onClose, t, vatrate]);

  useShortcut('Enter', () => onDone());

  return (
    <div data-testid="tax-exempt-modal">
      <Modal.Header>
        <Typography variant="h5">{t('title')}</Typography>
        <Button
          data-testid="confirm--partial-exempt-btn"
          onClick={() => onDone(true)}
          variant="contained"
          color="secondary"
          style={{ marginLeft: 'auto', marginRight: '5px' }}
        >
          {t('buttons.partial')}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          data-testid="confirm-btn"
          onClick={() => onDone()}
        >
          {t('buttons.full')}
        </Button>
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <TextField
          autoFocus
          variant="outlined"
          label={t('fields.cert')}
          value={id}
          fullWidth
          onChange={e => setid(e.target.value)}
          data-testid="cert-input"
        />
        <Button
          data-testid="reset-tax-excempt-number"
          onClick={resetTaxExemptNumber}
          variant="contained"
          color="secondary"
          style={{ marginTop: '5px' }}
        >
          {t('buttons.reset')}
        </Button>
      </Modal.Body>
    </div>
  );
};

export default TaxExempt;
