import i18next from 'i18next';

import swedbankRequest from './index';

const pingTerminal = async params => {
  const loadNamespace = i18next.loadNamespaces('settingsPayment');
  const t = key => loadNamespace.then(() => i18next.t(key));
  const failkey = 'settingsPayment:integrations.swedbank.testConnection.fail';
  const successKey = 'settingsPayment:integrations.swedbank.testConnection.ok';
  try {
    const ping = await swedbankRequest.get('testConnection');

    if (ping.data.records[0].transactionStatus === 'Operation succeeded') {
      if (params) {
        params.updateMessage(await t(successKey));
        params.enableButtons(['retry', 'terminal-connection', 'close']);
      }
      return await t(successKey);
    }

    return await t(failkey);
  } catch (error) {
    if (params) {
      params.updateMessage(await t(failkey));
      params.enableButtons(['terminal-connection', 'close']);
    }

    return t(failkey);
  }
};

export default pingTerminal;
