import { getCardPayments } from 'reducers/Payments';
import { deletePayment, markForProcessing } from 'actions/Payments';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { CardPaymentHooks } from 'paymentIntegrations/types';

import processPayments from './handlers/processPayments';

export const title = 'Junction Transaction';

export const initPayments = (params: CardPaymentHooks) => async dispatch => {
  const {
    enableButtons,
    resolvePayments,
    rejectPayments,
    cardPayments,
  } = params;

  enableButtons([]);

  const shouldVoid = cardPayments.every(p => p.paid && p.shouldProcess);

  dispatch(processPayments(params))
    .then(data => {
      if (data.errors) {
        enableButtons(['close']);
      } else if (shouldVoid) {
        rejectPayments();
      } else {
        resolvePayments();
      }
    })
    .catch(err =>
      console.error('Failed to process payments with Junction', err),
    );
};

export const voidPayments = () => async (dispatch, getState) => {
  getCardPayments(getState()).forEach(({ key, paid }) => {
    if (paid) {
      dispatch(markForProcessing({ key }));
    } else {
      dispatch(deletePayment({ key }));
    }
  });

  if (getCardPayments(getState())?.length) {
    await dispatch(openTerminalIntegration());
  }
};

const close = params => async (dispatch, getState) => params.rejectPayments();
export const functions = [
  {
    text: '',
    name: 'forceCancel',
    variant: 'plain', // HACK: Non existing variant has no bg color
  },
  {
    actionOnClick: close,
    text: 'Close',
    name: 'close',
    variant: 'warning',
  },
  {
    text: '',
    name: 'forceSuccess',
    variant: 'plain', // HACK: Non existing variant has no bg color
  },
];
