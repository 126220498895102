import debug from 'debug';
import qs from 'qs';
import { UrlControl } from './UrlControl';

const log = debug('UrlControl');
const warn = debug('UrlControl');
warn.log = console.warn.bind(console);

export const urlParams = qs.parse(window.location.search, {
  ignoreQueryPrefix: true, // ignore the leading ? from the query string
  /**
   * default 20
   * array indices larger than this are treated like object properties
   * We want to support longer arrays just in case someone has a large document they are making returns for
   */
  arrayLimit: 100,
  /**
   * Support dot notation `?foo.a=1,foo.b=2` for {foo: {a:'1', b:'2'}}
   */
  allowDots: true,
});

/*
 * If URL is encoded in a non-canonical format
 * Replace it with the canonical one to encourage/hint developers to use that instead
 */
const canonicalSearch = qs.stringify(urlParams, { encode: false });
if (`?${canonicalSearch}` !== window.location.search) {
  warn(
    'UrlEncode: Parameters passed in non canonical format. Received',
    window.location.search,
    'but expected',
    canonicalSearch,
  );
  const newUrl = new URL(window.location.href);
  newUrl.search = canonicalSearch;
  window.history?.replaceState(null, '', newUrl.href);
} else {
  log('URL parameters are already in the expected format', urlParams);
}
