import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  styled,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';

import CloseButton from 'components/CustomButtons/CloseButton';
import { EA_MIN_STOCK, END_POINTS } from 'plugins/taf/endlessAisle/constants';
import { getCountryInUse } from 'reducers/configs/settings';
import { openPluginModalPage } from 'actions/modalPage';
import { ThunkAction } from 'reducers';

const FatButton = styled(Button)({
  padding: '1.25rem',
  width: '100%',
});

const DividerWithMargin = styled(Divider)({
  margin: '1rem 0',
});

type EAOutOfStockResult = 'endless-aisle' | 'ignore';

function EAOutOfStockPopup({
  productName,
  productID,
  resolve,
  reject,
  onClose,
}: {
  productName: string;
  productID: number;
  resolve: (v: EAOutOfStockResult) => void;
  reject: (e: Error) => void;
  onClose: () => void;
}) {
  const country = useSelector(getCountryInUse);
  const makeEndpoint =
    country === 'AU' ? END_POINTS.TAF_SERVER_AU : END_POINTS.TAF_SERVER_NZ;
  const stock = useAsync(async () => {
    const { data } = await axios.get<{ qty: string }>(makeEndpoint(productID));
    return Math.max(0, Number(data.qty) - 3);
  }, [makeEndpoint, productID]);

  const hasEnoughStockQuantity =
    stock.value !== undefined && stock.value >= EA_MIN_STOCK;
  return (
    <Box padding="1.25rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Product out of stock</Typography>
        <CloseButton
          action={() => {
            onClose();
            reject(new Error('Closed from X button'));
          }}
        />
      </Box>
      <DividerWithMargin />
      <Box padding="1rem">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          textAlign="center"
        >
          <Typography variant="h5">
            No stock left for {productName} in store.
          </Typography>
          {stock.error ? (
            <Typography variant="h5" color="error">
              TAF global store stock:{' '}
              {stock.error?.message ??
                `Unable to get stock for product with id: ${productID}`}
            </Typography>
          ) : (
            <Typography variant="h5">
              TAF Global store stock:{' '}
              {stock.value ?? (
                <Skeleton width="1ch" style={{ display: 'inline-block' }} />
              )}
            </Typography>
          )}
        </Box>
      </Box>
      <DividerWithMargin />
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        gridGap="1rem"
      >
        <FatButton
          variant="contained"
          disabled={!hasEnoughStockQuantity}
          onClick={() => {
            onClose();
            resolve('endless-aisle');
          }}
        >
          {hasEnoughStockQuantity ? 'Endless Aisle' : 'EA not available'}
        </FatButton>
        <FatButton
          variant="contained"
          color="secondary"
          onClick={() => {
            onClose();
            resolve('ignore');
          }}
        >
          Add product
        </FatButton>
      </Box>
    </Box>
  );
}

type EAValidateAddressResult =
  | 'deliverToStore'
  | 'deliverToCustomer'
  | 'updateCustomer';

function EAValidateAddressPopup({
  customerAddress,
  companyAddress,
  resolve,
  reject,
  onClose,
}: {
  customerAddress: string;
  companyAddress: string;
  resolve: (v: EAValidateAddressResult) => void;
  reject: (e: Error) => void;
  onClose: () => void;
}) {
  const [checked, setChecked] = useState(false);

  return (
    <Box padding="1.25rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Validate address</Typography>
        <CloseButton
          action={() => {
            onClose();
            reject(new Error('Closed from x button'));
          }}
        />
      </Box>
      <DividerWithMargin />
      <Box display="flex" flexDirection="column" gridGap="1rem">
        <div>
          <Typography variant="h5">Customer Address:</Typography>
          <Typography variant="h6">{customerAddress}</Typography>
        </div>
        <div>
          <Typography variant="h5">Store Address:</Typography>
          <Typography variant="h6">{companyAddress}</Typography>
        </div>
      </Box>
      <Box padding="1rem">
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
              name="Delivery to store"
            />
          }
          label="Delivery to store"
        />
      </Box>
      <DividerWithMargin />
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        gridGap="1rem"
      >
        <FatButton
          variant="contained"
          onClick={() => {
            onClose();
            resolve('updateCustomer');
          }}
        >
          Update
        </FatButton>
        <FatButton
          variant="contained"
          color="secondary"
          onClick={() => {
            onClose();
            resolve(checked ? 'deliverToStore' : 'deliverToCustomer');
          }}
        >
          Confirm
        </FatButton>
      </Box>
    </Box>
  );
}

export const endlessAisleComponents = {
  'taf-ea-endlessAisle': EAOutOfStockPopup,
  'taf-ea-validateAddress': EAValidateAddressPopup,
};

export function endlessAisleProductPrompt(props: {
  productName: string;
  productID: number;
}): ThunkAction<EAOutOfStockResult> {
  return function action(dispatch) {
    return dispatch(
      openPluginModalPage('taf-ea-endlessAisle')({ props, isPopup: true }),
    );
  };
}

export function endlessAisleValidateAddressPrompt(props: {
  companyAddress: string;
  customerAddress: string;
}): ThunkAction<EAValidateAddressResult> {
  return function action(dispatch) {
    return dispatch(
      openPluginModalPage('taf-ea-validateAddress')({ props, isPopup: true }),
    );
  };
}
