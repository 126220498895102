import React, { useEffect } from 'react';
import * as R from 'ramda';

import {
  matches,
  requirify,
} from 'plugins/ideal/features/customerMultiOptIn/utils';

import { Customer, OnChange } from '../types';

interface Props {
  onChange: OnChange;
  value: Customer;
}

export const useRequireEmail = ({ value, onChange }: Props) => {
  const isEmailValid = !!value.email;
  useEffect(() => {
    onChange(R.assoc('invalid', !isEmailValid));
  }, [isEmailValid, onChange]);

  return R.map(R.when(matches(/email$/), requirify));
};
