import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'validator';
import {
  Box,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  styled,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { round } from 'utils';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { deletePayment } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { getCardPaymentsForIntegration } from 'reducers/Payments';
import CloseButton from 'components/CustomButtons/CloseButton';
import { useLocalStorage } from 'utils/hooks/statelike';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import {
  getExternalPayments,
  getIdForCustomPaymentType,
} from 'reducers/PaymentTypes';
import { voidPayments } from 'paymentIntegrations/external';
import { addWarning } from 'actions/Error';
import { getSetting } from 'reducers/configs/settings';
import MuiButton from 'components/CustomButtons/MuiButton';

const CardButton = styled(MuiButton)({
  height: '5em',
});

export const ExternalPayment = ({ resolvePayments, rejectPayments }) => {
  const [authCode, setAuthCode] = useState('');
  const { t } = useTranslation('payment');
  const dispatch = useDispatch();

  const paymentCards = useSelector(getExternalPayments);
  const cardPayments = useSelector(
    getCardPaymentsForIntegration('external'),
  ).filter(x => !x.paid || (x.paid && x.shouldProcess));
  const payment = cardPayments[0];
  const isReturn = payment?.paid && payment?.shouldProcess;
  const originalCardName = payment?.type === 'CARD' ? null : payment?.type;
  const [cardType, setCardType] = useState(originalCardName);
  const cardTypeId = useSelector(getIdForCustomPaymentType(cardType));
  const authCodeNeeded = useSelector(
    getSetting('touchpos_require_auth_code_on_payments'),
  );

  useEffect(() => {
    setCardType(originalCardName);
  }, [originalCardName]);

  useEffect(() => {
    if (isReturn) {
      dispatch(previousModalPage());
      dispatch(voidPayments()).then(resolvePayments, rejectPayments);
    }
  }, [dispatch, isReturn, rejectPayments, resolvePayments]);
  if (cardPayments.length === 0) {
    resolvePayments();
    return null;
  }
  const onSubmit = e => {
    e.preventDefault();
    if (isEmpty(authCode.trim()) && authCodeNeeded) {
      return dispatch(
        addWarning(t('externalPayment.alerts.authCodeRequired'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }
    if (payment) {
      if (isReturn) {
        dispatch(deletePayment({ key: payment.key }));
      } else {
        dispatch(
          // TODO: Get type from type name
          setPayment({
            ...payment,
            paid: true,
            typeID: cardTypeId,
            cardType: originalCardName ?? cardType,
            attributes: {
              authCode,
            },
          }),
        ).then(() => dispatch(setPaymentSelected('')));
      }
    }
    setAuthCode('');
    setCardType(null);
  };

  return (
    <Container px={2}>
      <Box
        pt={2}
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        <Typography variant="h5">
          {t('externalPayment.title', {
            amount: payment.amount ? round(payment.amount) : '',
            cardType: cardType ?? '',
          })}
        </Typography>
        <CloseButton action={() => rejectPayments()} />
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap="0.5rem" pt={3}>
        {paymentCards.map(card => (
          <CardButton
            key={card}
            color={cardType === card ? 'grid_light' : 'grid_dark'}
            variant="contained"
            style={{ textTransform: 'none' }}
            onClick={() => {
              setCardType(card);
            }}
          >
            {card}
          </CardButton>
        ))}
      </Box>
      <Box pt={1}>
        {!cardType ? (
          <Alert severity="warning">
            {t('externalPayment.alerts.methodRequired')}
          </Alert>
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
            color="primary"
            onChange={e => setAuthCode(e.target.value)}
            value={authCode}
            label={t('externalPayment.inputs.authCode')}
            aria-label="Default"
          />
        )}
      </Box>
      <Box pt={1} pb={1}>
        {cardType ? (
          <Grid container>
            <Grid item xs={12} md={5}>
              <MuiButton
                color="grid_red"
                fullWidth
                onClick={() => rejectPayments()}
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                }}
                variant="contained"
              >
                {t('externalPayment.buttons.failedTransaction')}
              </MuiButton>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="h4" align="center" color="textSecondary">
                {t('externalPayment.buttons.sale')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <MuiButton
                color="grid_green"
                fullWidth
                onClick={onSubmit}
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                }}
                variant="contained"
              >
                {t('externalPayment.buttons.succeededTransaction')}
              </MuiButton>
            </Grid>
          </Grid>
        ) : (
          <Box flex={1}>
            <Typography variant="h4" align="center" color="textSecondary">
              {t('externalPayment.buttons.sale')}
            </Typography>
          </Box>
        )}
      </Box>
      <ExternalPaymentActions />
    </Container>
  );
};

export const ExternalPaymentActions = () => {
  const [infoHidden, setInfoHidden] = useLocalStorage(
    'hideExternalIntegrationinfo',
  );

  return infoHidden ? null : (
    <Box pb={2}>
      <Trans i18nKey="payment:externalPayment.descriptionPayment">
        <Link
          href="https://help.erply.com/"
          target="_blank"
          rel="noopener"
          color="primary"
        />
        <Link onClick={() => setInfoHidden(true)} href="#" color="primary" />
      </Trans>
    </Box>
  );
};

export default ExternalPayment;
