import React, { FC } from 'react';

type Props = {
  header?: string;
};

const ExtraHeader: FC<Props> = ({ header }) => {
  return header ? (
    <th data-testid="pnp-order-net-total-header">{header}</th>
  ) : null;
};

export default ExtraHeader;
