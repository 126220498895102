import { v4 as uuidv4 } from 'uuid';

import { version } from 'external_data';
import { round } from 'utils';
import { REDUX_CLIENTCODE } from 'constants/persistence';

let refno = null;
let amount = null;
const sendMessage = msg => {
  const clientCode = localStorage.getItem(REDUX_CLIENTCODE);
  const messageToSend = {
    ...msg,
    payload: {
      ...(msg.payload ?? {}),
      clientCode,
      softwareName: 'Erply Brazil POS',
      softwareVersion: version,
    },
  };
  return window.wrapperMessageChannel.port1.postMessage(messageToSend);
};

/** Cancel the most recent payment */
export const cancelPayments = () => async () => {
  sendMessage({
    type: 'payment:cancel',
    requestID: uuidv4(),
    payload: {
      referenceNumber: refno, // The refno of the most recently sent startPayment
      amount,
      current: true,
      meta: {
        paymentIndex: 0,
        totalPayments: 1,
      },
    },
  });
};
/** Notify the wrapper of a finished sale (guarantee no void of payments before this point) */
export const finished = () => {
  sendMessage({
    type: 'payment:finished',
    requestID: uuidv4(),
    payload: {},
  });
};
/** Cancel/void a specific payment */
export const retractPayment = ({ amount, referenceNumber, index, total }) => {
  sendMessage({
    type: 'payment:cancel',
    requestID: uuidv4(),
    payload: {
      amount: String(amount),
      referenceNumber: String(referenceNumber),
      transactionType: 'VOID',
      current: false,
      meta: {
        paymentIndex: index,
        totalPayments: total,
      },
    },
  });
};

const sendRegularPayment = ({
  payment,
  employee = '',
  customer = '',
  currencyCode = '',
  transactionType = 'SALE',
  invoice,
  index,
  total,
}) => {
  refno = uuidv4();
  amount = payment.amount;
  sendMessage({
    type: 'payment:start',
    requestID: uuidv4(),
    payload: {
      transactionType,
      transactionId: payment.key,
      amount: round(payment.amount, 2),
      currency: currencyCode,
      tenderType: 'CREDIT', // Acceptable values: CREDIT, DEBIT
      employeeID: String(employee.employeeID),
      invoiceNumber: String(invoice.number), // generated by POS unique ref number
      customerID: String(customer.customerID),
      softwareName: 'Erply Brazil POS',
      softwareVersion: version,
      referenceNumber: refno,
      ecrRefNum: '1',
      sigSavePath: uuidv4(),
      supportedPartialPayments: true,
      meta: {
        paymentIndex: index,
        totalPayments: total,
      },
    },
  });
};

/** Capture a previously performed PREAUTH payment */
export const sendCapturePayment = ({
  payment,
  employee = '',
  customer = '',
  currencyCode = '',
  invoice,
  index,
  total,
}) => {
  refno = payment.referenceNumber || uuidv4();
  amount = payment.amount;
  sendMessage({
    type: 'payment:start',
    requestID: uuidv4(),
    payload: {
      transactionType: 'CAPTURE',
      transactionId: payment.key,
      amount: round(payment.amount, 2),
      currency: currencyCode,
      tenderType: 'CREDIT', // Acceptable values: CREDIT, DEBIT
      employeeID: String(employee.employeeID),
      invoiceNumber: String(invoice.number), // generated by POS unique ref number
      customerID: String(customer.customerID),
      softwareName: 'Erply Brazil POS',
      softwareVersion: version,
      referenceNumber: refno,
      ecrRefNum: '1',
      sigSavePath: uuidv4(),
      supportedPartialPayments: true,
      meta: {
        paymentIndex: index,
        totalPayments: total,
      },
    },
  });
};

const sendReturnPayment = ({
  payment,
  invoice,
  employee,
  customer,
  currencyCode,
  index,
  total,
}) => {
  const toOriginalFormat = p => {
    const payment = p.original ?? p;
    const getAttribute = name => {
      const attrs = (payment.original ?? payment).attributes ?? [];
      if (attrs.find) {
        return attrs.find(attr => attr?.attributeName === name)?.attributeValue;
      }
      if (attrs?.[name]) {
        return attrs?.[name];
      }
      return undefined;
    };

    return getAttribute('refNo')
      ? {
          cardType: String(payment.cardType),
          cardNumber: String(payment.cardNumber),
          cryptogram: String(payment.signature),
          referenceNumber: String(getAttribute('refNo')),
          transactionId: String(getAttribute('transId')),
          entryMode: String(getAttribute('entryMode')),
          amount: String(p.amount ?? payment.sum),
          dateTime: String(getAttribute('dateTime')),
        }
      : null;
  };

  sendMessage({
    type: 'payment:startReturn',
    requestID: uuidv4(),
    payload: {
      employeeID: String(employee.employeeID),
      customerID: String(customer.customerID),
      currencyCode,
      amount: round(Math.abs(Number(payment.amount)), 2),
      transactionType: 'REFUND',
      invoiceNumber: String(invoice.number),
      original: toOriginalFormat(payment.original ?? payment),
      meta: {
        paymentIndex: index,
        totalPayments: total,
      },
    },
  });
};

/**
 * Send a regular/return payment request
 * @param {'SALE'|'PREAUTH'} transactionType Set this to preauth if you need to perform a capture later
 * PREAUTH is not valid for returns!
 */
export const sendPayment = ({
  payment,
  employee,
  customer,
  currencyCode,
  transactionType,
  invoice,
  index,
  total,
}) =>
  Number(payment.amount) > 0
    ? sendRegularPayment({
        payment,
        employee,
        customer,
        currencyCode,
        transactionType,
        invoice,
        index,
        total,
      })
    : sendReturnPayment({
        payment,
        employee,
        customer,
        currencyCode,
        invoice,
        index,
        total,
      });
