/* eslint-disable no-plusplus */
import { createSelector } from 'reselect';

import { getProductGroupByID } from 'reducers/productGroupsDB';
import { ErplyAttributes } from 'utils';
import { getPluginConfiguration } from 'reducers/Plugins';

import { Configuration, pluginID } from './meta';
import { ProductGroup } from 'types/Product';

export const getWarrantyGroupId = createSelector(
  state => getPluginConfiguration<Configuration | undefined>(pluginID)(state),
  conf => conf?.warrantyProductGroupId ?? 967,
);

export const getMainWarehouseId = createSelector(
  state => getPluginConfiguration<Configuration | undefined>(pluginID)(state),
  conf => conf?.mainWarehouseId ?? 8,
);

const getProductGroupAncestry = (groupID: number) => state => {
  const ancestry: ProductGroup[] = [];
  let current = getProductGroupByID(groupID)(state);

  /** To prevent infinite loops from hanging the POS */
  let MAX_LOOP_COUNT = 100;
  // prettier-ignore
  while (0 <-- MAX_LOOP_COUNT) {
    if (!Number(current?.parentGroupID)) return ancestry;
    ancestry.push(current);
    current = getProductGroupByID(current.parentGroupID)(state);
  }
  console.error('Infinite walk in getProductGroupAncestry!!!');
  return ancestry;
};

export const getWarrantyRatesForGroup = groupID => (
  state,
): { basePrice: number; percentage: number } | undefined => {
  const ancestry = getProductGroupAncestry(groupID)(state);
  return ancestry
    .map(g => {
      const attrs = new ErplyAttributes(g.attributes);
      const basePrice = attrs.get('extraGuaranteeBasePrice');
      const percentage = attrs.get('extraGuaranteePricePercent');
      if (basePrice && percentage)
        return {
          basePrice: Number(basePrice),
          percentage: Number(percentage),
        };
    })
    .find(data => data);
};
