import { getPluginConfiguration, getPluginSelector } from 'reducers/Plugins';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';

import {
  SET_WAIVERS,
  IS_AN_ADMISSION,
  ADD_TO_SELECTED_WAIVER,
  DESELECT_WAIVER,
  RESET_SELECTED_WAIVERS,
} from './actionTypes';

export type WaiversState = {
  waivers: any[];
  isAnAdmission: boolean;
  selectedWaivers: WaiverObject[];
};

type WaiverObject = {
  waiver_id: string;
  images: string;
  names: string;
  waiverURL: string;
};

const waiverReducer = (
  state = {
    waivers: [],
    isAnAdmission: false,
    selectedWaivers: [],
  },
  action,
): WaiversState => {
  switch (action.type) {
    case SET_WAIVERS:
      return { ...state, waivers: [...action.payload] };
    case IS_AN_ADMISSION:
      return { ...state, isAnAdmission: action.payload };
    case ADD_TO_SELECTED_WAIVER:
      return {
        ...state,
        selectedWaivers: [...state.selectedWaivers, action.payload],
      };
    case DESELECT_WAIVER: {
      const updatedWaivers = state.selectedWaivers.filter(
        (waiver: WaiverObject) => waiver.waiver_id !== action.payload,
      );

      return {
        ...state,
        selectedWaivers: [...updatedWaivers],
      };
    }
    case RESET_SELECTED_WAIVERS:
      return {
        ...state,
        selectedWaivers: [],
      };
    default:
      return state;
  }
};

export const getWaiversReduxState = state =>
  getPluginSelector<WaiversState>('pnp-ewaiver')(n => n)(state);

/**
 * Searches and returns admission product from shopping cart, if found.
 */
export const hasAdmissionFee = (state): boolean | object => {
  const productsInCart: Record<string, any> = getProductsInShoppingCart(state);
  const currentConfig: any = getPluginConfiguration('pnp-ewaiver')(state) ?? {};
  const selectedCodes = currentConfig.admissionProductCode
    .split(',')
    .map(code => code.trim());

  if (productsInCart.length) {
    const admissionFeeProduct = productsInCart.find(product =>
      selectedCodes.includes(String(product.code)),
    );
    if (admissionFeeProduct) {
      return admissionFeeProduct;
    }
  }
  return false;
};

export default waiverReducer;
