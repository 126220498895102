import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { printZReport } from 'actions/integrations/printer';
import { prepareZReportBoHtml } from 'actions/integrations/printer/preparePrintoutData';

import Loader from '../Loader';

function insertHTML(element: HTMLIFrameElement | null, HTML: string) {
  if (element) {
    const doc = element.contentDocument;
    if (doc) {
      doc.open();
      doc.write(HTML);
      doc.close();
    }
  }
  return element;
}

export default ({ print = false, save = false }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

  const { error: err, value: d } = useAsync(
    () => dispatch(prepareZReportBoHtml),
    [],
  );

  const [needsPrint, setNeedsPrint] = useState(false);
  const [needsBrowserPrint, setNeedsBrowserPrint] = useState(false);

  useEffect(() => setNeedsPrint(print), [print]);
  useEffect(() => setNeedsBrowserPrint(save), [save]);

  if (err) {
    return <div>There was an error: {err.toString()}</div>;
  }

  if (!d) {
    return (
      <Loader
        show={true}
        variant="bars"
        style={{ display: 'block', height: '100%' }}
      />
    );
  }

  return (
    <iframe
      style={{
        backgroundColor: 'white',
        borderWidth: 0,
        width: '100%',
        height: '100%',
      }}
      title="A Z-Report"
      ref={el => {
        insertHTML(el, d);
        if (el && needsPrint) {
          setNeedsPrint(false);
          dispatch(printZReport(d));
        }
        if (el && needsBrowserPrint) {
          setNeedsBrowserPrint(false);
          dispatch(printZReport(d, true));
        }
      }}
    >
      Rendering...
    </iframe>
  );
};
