import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  getCurrencyFormatter,
  getShowPricesWithTax,
} from 'reducers/configs/settings';

type Props = {
  orderIndex: string;
  rowTotal: number | string;
  rowNetTotal: number | string;
};

const ExtraColumn: FC<Props> = ({ orderIndex, rowNetTotal, rowTotal }) => {
  const format = useSelector(getCurrencyFormatter);
  const isWithTax = useSelector(getShowPricesWithTax);
  return orderIndex ? (
    <td data-testid="pnp-order-net-total" data-test-key={orderIndex}>
      {format(isWithTax ? rowTotal : rowNetTotal)}
    </td>
  ) : null;
};

export default ExtraColumn;
