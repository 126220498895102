import React, { useCallback, useState } from 'react';
import { useBeforeUnload, useToggle } from 'react-use';
import * as R from 'ramda';
import Modal from 'react-bootstrap/Modal';
import {
  Typography,
  TextField,
  Box,
  Paper,
  Zoom,
  Grid,
  Button,
  InputAdornment,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  createConfirmation,
  closeConfirmationByID,
} from 'actions/Confirmation';
import {
  getActiveConfirmation,
  getHasActiveConfirmation,
} from 'reducers/Confirmation';
import layers from 'assets/layers.module.css';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getCausalStack } from 'utils';

import styles from './index.module.scss';
import { TimerBar } from './TimerBar';

const Confirmation = () => {
  const show = useSelector(getHasActiveConfirmation);
  const {
    id,
    resolve,
    reject,
    props: {
      title = '',
      body = '',
      confirmText = null,
      cancelText = null,
      inputs = [],
      enableShortcuts = true,
      details = null,
      error = null,
      alertBeforeUnload = false,
      automaticAction = undefined,
      automaticActionAfter = undefined,
    } = {},
  } = useSelector(getActiveConfirmation) || {};

  useBeforeUnload(alertBeforeUnload, ' ' /* non-empty string */);

  const confirmTexts = Array.isArray(confirmText)
    ? confirmText
    : [{ name: confirmText, value: '' }];

  const singleConfirm = confirmTexts.length === 1;
  const [data, setData] = useState({});
  const [showError, toggleError] = useToggle(false);
  const [showDetails, toggleDetails] = useToggle(false);
  const { t } = useTranslation('alerts');
  const { t: t2 } = useTranslation('common');

  const dispatch = useDispatch();

  const close = useCallback(() => dispatch(closeConfirmationByID(id)), [
    dispatch,
    id,
  ]);

  const onConfirm = useCallback(
    value => {
      close();
      resolve(value);
    },
    [close, resolve],
  );

  const onCancel = useCallback(() => {
    if (reject) {
      close();
      reject();
    }
    return false;
  }, [close, reject]);

  const defaultTitle = t('confirmation.defaultTitle');
  const defaultBody = t('confirmation.defaultBody');
  useShortcut(
    'Enter',
    show && singleConfirm && enableShortcuts ? onConfirm : null,
    120,
  );
  useShortcut('Escape', show && enableShortcuts ? onCancel : null, 120);

  const onHide = useCallback(() => ({}), []);
  const container = document.getElementById('modals');

  if (!confirmTexts.length) return null;

  return (
    <Modal
      container={container}
      show={show}
      dialogClassName={`confirmation ${layers.confirmation}`}
      backdropClassName={layers.confirmation}
      onHide={onHide}
      data-testid="confirmation-modal"
    >
      <Modal.Header data-testid="header" className={styles.header}>
        {title || defaultTitle}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          {/* if string */ body && body.slice ? (
            <span
              data-testid="body"
              style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
            >
              {body || defaultBody}
            </span>
          ) : (
            <span data-testid="body">{body || defaultBody}</span>
          )}
          <Box margin={1} display="flex" justifyContent="space-around">
            {error && (
              <Button onClick={toggleError}>
                {t('confirmation.buttons.error')}
              </Button>
            )}
            {details && (
              <Button onClick={toggleDetails}>
                {t('confirmation.buttons.details')}
              </Button>
            )}
          </Box>
          <Box display="flex" justifyContent="center">
            <Zoom
              in={showError || showDetails}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Paper>
                <Box margin={1} padding={1}>
                  {error &&
                    getCausalStack(error).map(e => (
                      <Typography>{e.stack}</Typography>
                    ))}
                  {details && (
                    <pre>{JSON.stringify(details, undefined, 2)}</pre>
                  )}
                </Box>
              </Paper>
            </Zoom>
          </Box>
        </div>
        <div className={styles.body}>
          {inputs.map(({ name, value, title, ...rest }) => (
            <TextField
              key={name}
              name={name}
              {...rest}
              value={value}
              onChange={e => setData(R.assoc(name, e.target.value))}
              fullWidth
              variant="outlined"
              margin="dense"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{t(title)}</InputAdornment>
                ),
              }}
            />
          ))}
        </div>
        <div className={styles.buttonsContainer}>
          <Grid container direction="row" justifyContent="center" spacing={4}>
            {reject && (
              <Grid item className={styles.rejectButtonsColumn}>
                <Button
                  fullWidth={!singleConfirm}
                  variant="contained"
                  disableElevation
                  className={styles.button}
                  onClick={() => onCancel()}
                  data-testid="cancel-btn"
                >
                  {enableShortcuts ? (
                    <span className={styles.buttonIcon}>esc</span>
                  ) : null}
                  <span className={styles.buttonText}>
                    {cancelText ?? t2('cancel')}
                  </span>
                </Button>
                {automaticAction === 'reject' && automaticActionAfter ? (
                  <TimerBar
                    increments={250}
                    onDone={onCancel}
                    time={automaticActionAfter}
                  />
                ) : null}
              </Grid>
            )}

            <Grid item className={styles.buttonsColumn}>
              {confirmTexts.map(({ name, value }, i) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow={singleConfirm ? 1 : undefined}
                  marginBottom={confirmTexts.length > 2 ? '8px' : undefined}
                >
                  <Button
                    fullWidth={singleConfirm && !reject}
                    color="secondary"
                    variant="contained"
                    disableElevation
                    key={name || `${t('ok')}`}
                    className={styles.button}
                    data-testid="confirm-btn"
                    data-test-key={name ?? 'default-confirm-btn'}
                    onClick={() =>
                      onConfirm({
                        submit: value,
                        data,
                      })
                    }
                  >
                    {singleConfirm && enableShortcuts ? (
                      <span className={styles.buttonIcon}>⏎</span>
                    ) : null}
                    <span
                      className={styles.buttonText}
                      style={{ fontWeight: 700 }}
                    >
                      {name ?? t2('ok')}
                    </span>
                  </Button>
                  {i === 0 &&
                  automaticAction === 'resolve' &&
                  automaticActionAfter ? (
                    <TimerBar
                      increments={250}
                      onDone={() =>
                        onConfirm({
                          submit: value,
                          data,
                        })
                      }
                      time={automaticActionAfter}
                    />
                  ) : null}
                </Box>
              ))}
            </Grid>
          </Grid>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Confirmation;

/**
 * @function ConfirmationFunction
 * Display a confirmation and resolve or reject when the user closes it
 * @param options
 * @param options.title The title of the confirmation dialog
 * @param options.body The body text of the confirmation dialog
 * @param options.noReject True if you don't want the user to have a 'cancel' option - guarantees that the promise will not reject
 * @return {Promise<void, void>}
 * @example
 * confirm({
 *     title: 'Are you sure you want to Foo the Bar',
 *     body: 'This action is permanent',
 *     enableShortcuts: Disables the enter and escape key shortcuts if it is set to false. Default value is true,
 *   .then(() => Foo(bar))
 *   .catch(() => {}))
 */

/**
 * Returns a function that can be called to display a confirmation popup
 * @returns {ConfirmationFunction}
 * @example
 * const MyComponent = () => {
 *   // Get the confirmation function
 *   const confirm = useConfirmation();
 *   // Use it as a promise to guard an action
 *   missileAlert = () => confirm({title: 'You better be damn sure'})
 *       .then(() => sendAlert())
 *       .catch(scoldUser)
 *   missileAlertTest = () => confirm({title: 'send TEST missile alert?'})
 *       .then(() => sendAlert({test: true})
 *       .catch(() => {}) // do nothing if cancelled
 *   // Clicking either button displays a confirmation first
 *   // and only does the action once the user accepts
 *   return (
 *     <div>
 *       <button onClick={missileAlert}>Send missile alert</button>
 *       <button onClick={missileAlertTest}>Send TEST missile alert</button>
 *     </div>
 *   )
 * }
 * @example
 * const MyComponent = () => {
 *   // The confirmation can also be informative, offering no way to cancel
 *   const confirm = useConfirmation();
 *   const missileAlert = () => sendAlert().then(confirm({noReject: true, body: 'The missile alert has been sent - you will be logged out'}))
 *   return <button onClick={missileAlert}>Send missile alert</button>
 * }
 */
export const useConfirmation = () => {
  const dispatch = useDispatch();

  return useCallback(
    ({ title, body, noReject = false, ...props }) =>
      new Promise((resolve, rej) => {
        const reject = noReject ? null : rej;
        dispatch(
          createConfirmation(resolve, reject, { title, body, ...props }),
        );
      }),
    [dispatch],
  );
};
