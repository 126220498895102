import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import * as API from 'services/ErplyAPI/api';
import { setLoading, setPoslist } from 'actions/PointsOfSale';
import { addError } from 'actions/Error';
import { RootState } from 'reducers';

export function loadPointsOfSale() {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    try {
      dispatch(setLoading(true));
      const pointsOfSale = await API.getPointsOfSale({});
      dispatch(setPoslist(pointsOfSale));
      return pointsOfSale;
    } catch (e) {
      dispatch(
        addError(
          e instanceof Error ? e.message : 'Failed to load points of sale',
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
    return null;
  };
}
