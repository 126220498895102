import i18next from 'i18next';

import {
  getAllowOnlyOriginalTendersOnReturnWithReceipt,
  getIsExchangeAllowed,
  getLimitsFor,
  getPosRoundCash,
  getPosRoundCashFunction,
  getSetting,
} from 'reducers/configs/settings';
import {
  getIsCalculatingShoppingCart,
  getProductsInShoppingCart,
  getTotal,
  getCartIsPendingCalculate,
  getShouldCalculateOffline,
} from 'reducers/ShoppingCart';
import {
  getCustomerRegistryUrl,
  getDefaultCustomer,
  getIsLoadingCustomer,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import {
  getIsCurrentSaleAReturn,
  getCurrentSalesDocReturnPayments,
  getCurrentSalesDocOriginalPayments,
  getCurrentSalesDocument,
  getIsAReturn,
  getReturnTotal,
  getReturnIsPartial,
  getIsCurrentSaleGiftReturn,
} from 'reducers/sales';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getLoggedInEmployeeID } from 'reducers/Login';
import {
  add,
  ErplyAttributes,
  miniUuid,
  round,
  timestampInSeconds,
  waitForCondition,
} from 'utils';
import { setPaymentButtonClicked, setPaymentsState } from 'actions/Payments';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import { addError, addWarning, dismissType } from 'actions/Error';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import * as c from 'constants/modalPage';
import { getProductByID } from 'reducers/cachedItems/products';
import { getPayments, getSalesDocuments } from 'services/ErplyAPI/sales';
import { getAllCurrencies } from 'reducers/configs/currency';
import { getPaymentsTotal } from 'reducers/Payments';

import { calculate } from './ShoppingCart/calculate';
import { withProgressAlert } from './actionUtils';
import { waitForCafaToLoad } from './integrations/CafaConfigs';
import { checkRightToMakeSale } from './Login';
import { openModalPage } from './ModalPage/openModalPage';

const typesThatShouldShowOriginalPayments = [
  'ORDER',
  'PREPAYMENT',
  'INVWAYBILL',
  'INVOICE',
];

export function openPluginModalPage(pluginComponentName) {
  return (props = {}) =>
    openModalPage({
      component: c.modalPages.pluginModal,
      ...props,
      props: { ...props.props, name: pluginComponentName },
    });
}

function openPaymentModalBase(
  { props = {}, showNotes = true, isReopeningAfterSaleFailedToSave = false } = {
    props: {},
    showNotes: true,
    isReopeningAfterSaleFailedToSave: false,
  },
) {
  return async (dispatch, getState, /** @type {{halt, resume}} */ progress) => {
    const {
      before: pluginBefore,
      on: pluginOn,
      after: pluginAfter,
    } = getPluginLifecycleHook('onOpenPaymentModal')(getState());

    try {
      await dispatch(
        pluginBefore({ props, showNotes, isReopeningAfterSaleFailedToSave }),
      );
    } catch (err) {
      dispatch(setPaymentButtonClicked(false));
      return;
    }

    try {
      // In case user does not have rights to make a sale, do not allow going to payments
      await dispatch(checkRightToMakeSale());
    } catch (err) {
      dispatch(setPaymentButtonClicked(false));
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const isBlockedExchange = await dispatch(checkIfBlockedExchange());
    if (isBlockedExchange) {
      dispatch(setPaymentButtonClicked(false));
      return;
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await dispatch(waitForCartCalculationToFinish());
      await dispatch(waitForCafaToLoad());
    } catch (error) {
      return; // Timed out
    }

    const state = getState();
    const returnTotal = getReturnTotal(state);
    const total = props.total ?? getTotal(state);
    const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);
    const isCurrentSaleGiftReturn = getIsCurrentSaleGiftReturn(state);
    const isAReturn = isCurrentSaleAReturn || total < 0;
    const shoppingCartTotal = isAReturn ? returnTotal : total;
    const currentSalesDocument =
      props.currentSalesDocument ?? getCurrentSalesDocument(state);
    const originalPayments =
      props.originalPayments ?? getCurrentSalesDocOriginalPayments(state);
    const selectedCustomer = props.customer || getSelectedCustomer(state);
    const selectedPos = getSelectedPos(state);
    const posRoundCash = getPosRoundCash(state);
    const title1 = getSetting('sale_extra_note1')(state);
    const title2 = getSetting('sale_extra_note2')(state);
    const title3 = getSetting('sale_extra_note3')(state);
    const employeeID = getLoggedInEmployeeID(state);
    const shouldShowActualTenders = typesThatShouldShowOriginalPayments.includes(
      currentSalesDocument.type,
    );
    const productsInCart = getProductsInShoppingCart(state);
    const hasPositiveAmountProductsInCart = productsInCart.some(
      product => product.amount > 0,
    );
    const paymentsTotal = getPaymentsTotal(state);
    const usingCustomerRegistry = Boolean(getCustomerRegistryUrl(state));

    const originalTenderOnly = getAllowOnlyOriginalTendersOnReturnWithReceipt(
      state,
    );
    const isDefaultCustomer =
      getDefaultCustomer(state).id === selectedCustomer.id;

    const customerHasDoNotSellAttribute = !!selectedCustomer.doNotSell;
    const customerIsSuspended = !!selectedCustomer.suspended;

    const blockSellingToCustomer = usingCustomerRegistry
      ? customerIsSuspended
      : customerHasDoNotSellAttribute;

    if (blockSellingToCustomer) {
      // ensure the payment.json is loaded
      await i18next
        .loadNamespaces('payment')
        .then(() =>
          dispatch(
            addWarning(i18next.t('payment:alerts.accountSuspendedSaleBlocked')),
          ),
        );
      return;
    }

    let cumulativeSumOfRoundings = 0;
    const returnedCashPaymentIDs =
      currentSalesDocument.returnedPayments
        ?.filter(p => p.type === 'CASH')
        .flatMap(({ ids }) => ids)
        .join(',') ?? [];

    if (posRoundCash && posRoundCash !== 0 && returnedCashPaymentIDs.length > 0) {
      cumulativeSumOfRoundings = await getPayments({
        paymentIDs: returnedCashPaymentIDs,
      })
        .then(previousReturnPayments =>
          getSalesDocuments({
            ids: previousReturnPayments.map(pmt => pmt.documentID).join(','),
          }),
        )
        .then(records => records.map(rec => rec.rounding).reduce(add, 0))
        // Add rounding of the initial sale
        .then(sum => sum + currentSalesDocument.rounding);
    }

    // if notes are enabled and notes are not empty then open modalPage with notes and update the saleDocument
    let notes;
    if (showNotes && (title1 || title2 || title3)) {
      dispatch(progress.halt);
      notes = await new Promise(resolve =>
        dispatch(
          openModalPage({
            component: c.modalPages.saleNotesCustom,
            isPopup: true,
            modalClassName: 'saleNotes',
            props: { resolve },
          }),
        ),
      );
      await dispatch(progress.resume);
    }
    const payments = {};
    const salesDocument = { notes };

    if (isAReturn) {
      const isFullReturn =
        !getReturnIsPartial(state) &&
        Number(-total) ===
          // Account for the possible rounding on the document
          Number(
            (
              Number(currentSalesDocument.total) - currentSalesDocument.rounding
            ).toFixed(2),
          );
      // Prefill return payments
      if (total < 0 && (isFullReturn || originalPayments.length === 1)) {
        /**
         * Payment amount should not exceed the total of the sale
         * <br/>
         * For now only used for CASH (can be applied for other payments if needed - e.g. CARD but need to check if all PI support Partial returns)
         */
        const getMaxAllowedPaymentAmount = pa => {
          const paymentAmount = Math.abs(pa);
          return Math.min(paymentAmount, -total);
        };
        let reducedPaymentsEntries = originalPayments
          .map((currentPayment, i) => {
            const { type, sum, cardNumber, attributes: attrs } = currentPayment;
            const attributes = new ErplyAttributes(attrs);
            const isTip = Boolean(attributes.get('cardIsTip') || type === 'TIP');
            if (isTip) return undefined;
            const parsedOriginal = {
              ...currentPayment,
              attributes: attributes.asDict,
            };
            const newKey = `orig.${currentPayment.paymentID}.${miniUuid()}`;
            const withOriginalPaymentID = {
              originalPaymentID: currentPayment.paymentID,
            };
            switch (type) {
              default:
                return undefined;
              case 'TIP':
                return [
                  `tip${i + 1}`,
                  {
                    original: parsedOriginal,
                    key: `tip${i + 1}`,
                    caption: 'TIP',
                    type: 'TIP',
                    amount: round(-Number(sum), 2),
                    currencyCode: currentPayment.currencyCode,
                    currencyRate: Number(currentPayment.currencyRate),
                    attributes: withOriginalPaymentID,
                    typeID: currentPayment.typeID,
                  },
                ];
              case 'CASH':
                return [
                  `${currentPayment.currencyCode}-cash`,
                  {
                    original: parsedOriginal,
                    key: `${currentPayment.currencyCode}-cash`,
                    caption: 'CASH',
                    type: 'CASH',
                    amount: getPosRoundCashFunction(getState())(
                      -getMaxAllowedPaymentAmount(sum),
                    ).toFixed(2),
                    currencyCode: currentPayment.currencyCode,
                    currencyRate: Number(currentPayment.currencyRate),
                    attributes: withOriginalPaymentID,
                    typeID: currentPayment.typeID,
                  },
                ];
              case 'ONLINE':
                return [
                  `online-${i + 1}`,
                  {
                    original: parsedOriginal,
                    key: `online-${i + 1}`,
                    caption: 'ONLINE',
                    amount: round(-getMaxAllowedPaymentAmount(sum), 2),
                    currencyCode: currentPayment.currencyCode,
                    currencyRate: Number(currentPayment.currencyRate),
                    attributes: withOriginalPaymentID,
                    typeID: currentPayment.typeID,
                    type: 'ONLINE',
                    cardType: currentPayment.cardType,
                    cardName: currentPayment.cardType,
                  },
                ];
              case 'CHECK':
                return [
                  `check${i + 1}`,
                  {
                    original: parsedOriginal,
                    paymentKey: `check${i + 1}`,
                    key: `check${i + 1}`,
                    caption: 'CHECK',
                    type: 'CHECK',
                    amount: round(-Number(sum), 2),
                    currencyCode: currentPayment.currencyCode,
                    currencyRate: Number(currentPayment.currencyRate),
                    attributes: withOriginalPaymentID,
                    typeID: currentPayment.typeID,
                  },
                ];
              case 'GIFTCARD':
                return [
                  newKey,
                  {
                    original: parsedOriginal,
                    key: newKey,
                    caption: 'STORE CREDIT',
                    type: 'STORECREDIT',
                    amount: round(-Number(sum), 2),
                    currencyCode: currentPayment.currencyCode,
                    currencyRate: Number(currentPayment.currencyRate),
                    attributes: withOriginalPaymentID,
                    typeID: currentPayment.typeID,
                  },
                ];
              case 'CARD':
                return [
                  newKey,
                  {
                    original: parsedOriginal,
                    key: newKey,
                    caption: currentPayment.caption ?? 'CARD',
                    cardNumber,
                    type: 'CARD',
                    amount: round(-getMaxAllowedPaymentAmount(sum), 2),
                    currencyCode: currentPayment.currencyCode,
                    currencyRate: Number(currentPayment.currencyRate),
                    paymentIntegration: currentPayment.paymentIntegration,
                    attributes: withOriginalPaymentID,
                    typeID: currentPayment.typeID,
                    cardType: currentPayment.cardType,
                    cardName: currentPayment.cardType,
                  },
                ];
              case 'TRANSFER':
                return [
                  newKey,
                  {
                    original: parsedOriginal,
                    key: newKey,
                    caption: 'TRANSFER',
                    type: 'TRANSFER',
                    amount: round(-Number(sum), 2),
                    currencyCode: currentPayment.currencyCode,
                    currencyRate: Number(currentPayment.currencyRate),
                    attributes: withOriginalPaymentID,
                    typeID: currentPayment.typeID,
                  },
                ];
            }
          })
          .filter(a => a !== undefined)
          .filter(
            ([key, pmt]) => !(isDefaultCustomer && pmt.type === 'STORECREDIT'),
          );
        // Deduplicate GIFTCARD → STORECREDIT
        const storeCreditPayments = reducedPaymentsEntries.filter(
          ([key, pmt]) => pmt.type === 'STORECREDIT',
        );
        if (storeCreditPayments.length) {
          reducedPaymentsEntries = reducedPaymentsEntries
            .filter(([, pmt]) => pmt.type !== 'STORECREDIT')
            .concat([
              [
                'storeCredit',
                {
                  ...storeCreditPayments[0][1],
                  sum: storeCreditPayments.reduce(
                    (prev, [, pmt]) => -pmt.sum,
                    add,
                  ),
                },
              ],
            ]);
        }

        /**
         * If payments exceed total (f.ex. because a negative payment like tip was removed)
         * then do not autogenerate payments
         *
         * Cannot simply reduce them because integrations might not support partial returns
         * as mentioned in comment of {@link getMaxAllowedPaymentAmount}
         */
        const autogeneratedPaymentsSum = reducedPaymentsEntries
          .map(([, { amount }]) => Number(amount))
          .reduce(add, 0);
        if (
          // Added to fix JS rounding errors
          Math.abs(autogeneratedPaymentsSum) -
            Math.abs(currentSalesDocument.rounding) -
            0.0005 >
          getMaxAllowedPaymentAmount(autogeneratedPaymentsSum).toFixed(2)
        ) {
          reducedPaymentsEntries = [];
        }

        if (isFullReturn) {
          Object.assign(payments, Object.fromEntries(reducedPaymentsEntries));
        } else if (reducedPaymentsEntries.length === 1) {
          const [paymentType, paymentEntry] = reducedPaymentsEntries[0];
          const roundCash = getPosRoundCashFunction(state);
          const roundedTotal = roundCash(total);
          const isCashPayment = paymentEntry.type === 'CASH';
          const paymentPrice = isCashPayment ? roundedTotal : total;

          const cashPaymentPrice =
            Math.abs(paymentPrice) < Math.abs(total) / 2
              ? -posRoundCash
              : paymentPrice;

          const amountToUse = isCashPayment
            ? cashPaymentPrice
            : round(-getMaxAllowedPaymentAmount(paymentEntry.amount));

          if (paymentPrice === 0) {
            delete payments[paymentType];
          } else {
            Object.assign(payments, {
              [paymentType]: {
                ...paymentEntry,
                amount: amountToUse,
              },
            });
          }
        }
      }

      // Link return to original
      Object.assign(salesDocument, {
        creditToDocumentID: currentSalesDocument.id,
        type: 'CREDITINVOICE',
        creditInvoiceType: 'RETURN',
        isCashInvoice:
          currentSalesDocument.type === 'INVWAYBILL' ||
          currentSalesDocument.type === 'CASHINVOICE'
            ? 1
            : 0,
      });
    }

    const isInvoiceOrInvoiceWaybill = ['INVWAYBILL', 'INVOICE'].includes(
      currentSalesDocument.type,
    );

    const isReturn = getIsAReturn(getState());

    if (
      shouldShowActualTenders &&
      originalPayments.length &&
      // Don't include original payments when making any kind of return
      // If we did, then it would be complicated to calculate the correct total
      //
      // For example, suppose the following sale
      //  | Coca cola x5  25.00
      //  |       TOTAL: 125.00
      //  |    PAYMENTS:  40.00 CASH
      // That is then partially returned
      //  |  Coca cola x-1 25.00
      // That and then partially returned again
      //  |  Coca cola x-2 25.00
      // In order for the numbers to match up with original payments included,
      // we would have to add up the sum of all the products *not* included in this or any previous return
      //  |        TOTAL: 50.00 (orig 125 - prev document 25 - current document 50)
      //  |     PAYMENTS: 40.00 CASH
      //
      // Instead, it's much easier to just not include the payments
      // Then the total can just be the current shopping cart total (or unrefunded payments, if smaller)
      total > 0 &&
      (!isReturn || !isInvoiceOrInvoiceWaybill) &&
      // Either the sales doc is not passed via props at all or if it is, then that it's not a cancellation
      (!props.salesDocument || props.salesDocument.invoiceState !== 'CANCELLED')
    ) {
      let reducedPaymentsEntries = originalPayments
        .map((currentPayment, i) => {
          const { type, sum, cardNumber, attributes: attrs } = currentPayment;
          const attributes = new ErplyAttributes(attrs);
          switch (type) {
            case 'TIP':
              return [
                `tip${i + 1}`,
                {
                  key: `tip${i + 1}`,
                  caption: 'TIP',
                  type,
                  amount: round(Number(sum), 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                  attributes,
                },
              ];
            case 'CASH':
              return [
                currentPayment.paymentID,
                {
                  key: currentPayment.paymentID,
                  caption: 'CASH',
                  type,
                  amount: round(sum, 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                },
              ];
            case 'ONLINE':
              return [
                `online-${i + 1}`,
                {
                  key: `online-${i + 1}`,
                  caption: 'ONLINE',
                  type,
                  amount: round(sum, 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                },
              ];
            case 'CHECK':
              return [
                `check${i + 1}`,
                {
                  paymentKey: `check${i + 1}`,
                  key: `check${i + 1}`,
                  caption: 'CHECK',
                  type,
                  amount: round(Number(sum), 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                },
              ];
            case 'GIFTCARD':
              return [
                currentPayment.paymentID,
                {
                  key: currentPayment.paymentID,
                  caption: 'STORE CREDIT',
                  // Change specifically to STORECREDIT
                  type: 'STORECREDIT',
                  amount: round(Number(sum), 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                },
              ];
            case 'CARD':
              return [
                currentPayment.paymentID,
                {
                  key: currentPayment.paymentID,
                  caption: currentPayment.caption ?? 'CARD',
                  cardNumber,
                  type,
                  amount: round(Number(sum), 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                  paymentIntegration: currentPayment.paymentIntegration,
                },
              ];
            case 'TRANSFER':
              return [
                currentPayment.paymentID,
                {
                  key: currentPayment.paymentID,
                  caption: 'TRANSFER',
                  type,
                  amount: round(Number(sum), 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                },
              ];
            default:
              return [
                currentPayment.paymentID,
                {
                  key: currentPayment.paymentID,
                  caption: currentPayment.type,
                  type,
                  amount: round(Number(sum), 2),
                  currencyCode: currentPayment.currencyCode,
                  currencyRate: Number(currentPayment.currencyRate),
                },
              ];
          }
        })
        .filter(a => a !== undefined)
        // Remove store credit payments if default customer
        .filter(
          ([_key, pmt]) => !(isDefaultCustomer && pmt.type === 'STORECREDIT'),
        )
        // Followup payment vs cancellation
        .map(([key, pmt]) => {
          if (
            props.salesDocument &&
            props.salesDocument.invoiceState === 'CANCELLED'
          ) {
            // Cancellation, create return payments
            return [key, { ...pmt, amount: -pmt.amount }];
          }
          // Followup to partial payment - set existing payments to type PAID so they count towards the total, can't be edited, and won't get saved
          return [key, { ...pmt, type: 'PAID' }];
        });
      // Deduplicate GIFTCARD → STORECREDIT
      const storeCreditPayments = reducedPaymentsEntries.filter(
        ([_key, pmt]) => pmt.type === 'STORECREDIT',
      );
      if (storeCreditPayments.length) {
        reducedPaymentsEntries = reducedPaymentsEntries
          .filter(([, pmt]) => pmt.type !== 'STORECREDIT')
          .concat([
            [
              'storeCredit',
              {
                ...storeCreditPayments[0][1],
                sum: storeCreditPayments.reduce((_prev, [, pmt]) => pmt.sum, add),
              },
            ],
          ]);
      }
      Object.assign(payments, Object.fromEntries(reducedPaymentsEntries));
    }

    const initSale = { ...salesDocument, ...props.salesDocument };

    const paymentLimits = (() => {
      if (isCurrentSaleAReturn) {
        if (isCurrentSaleGiftReturn) {
          return getLimitsFor('return')(state);
        }
        if (originalTenderOnly) {
          const currentTypes = originalPayments.map(payment => {
            switch (payment.type) {
              case 'CASH':
                return { type: 'CASH', amount: -1 };
              case 'CARD':
                return { type: 'CARD', amount: -1 };
              case 'GIFTCARD':
                return { type: 'STORECREDIT', amount: -1 };
              case 'STORECREDIT':
                return { type: 'CASH', amount: -1 };
              case 'CHECK':
                return { type: 'CHECK', amount: -1 };
              case 'TIP':
                return { type: 'TIP', amount: -1 };
              default:
                return { type: payment.type, amount: -1 };
            }
          });
          const allTypes = [
            { type: 'CASH', amount: -1 },
            { type: 'CARD', amount: -1 },
            { type: 'GIFTCARD', amount: -1 },
            { type: 'GIFTCARD', serial: true, amount: -1 },
            { type: 'STORECREDIT', amount: -1 },
            { type: 'CHECK', amount: -1 },
            { type: 'TIP', amount: -1 },
          ];
          return allTypes
            .map(payment => {
              const isPaymentOriginal = (() =>
                currentTypes.find(
                  originalPayment => payment.type === originalPayment.type,
                ))();
              if (!isPaymentOriginal) return payment;
              return false;
            })
            .filter(p => p);
        }

        if (hasPositiveAmountProductsInCart && paymentsTotal >= 0) {
          return getLimitsFor('sale')(state);
        }

        return getLimitsFor('returnWithReceipt')(state);
      }

      if (shoppingCartTotal < 0) {
        return getLimitsFor('return')(state);
      }

      const limits = getLimitsFor('sale')(state);
      const isPickedUpInvoice =
        initSale.type === 'INVOICE' &&
        Number(initSale.confirmed) === 1 &&
        initSale.paymentStatus === 'UNPAID';
      const isAccountSale = initSale.type === 'INVWAYBILL';

      // Do not allow paying with store credit when paying an invoice
      if (isPickedUpInvoice || isAccountSale) {
        return limits.map(limit =>
          limit.type === 'STORECREDIT'
            ? { type: 'STORECREDIT', amount: -1 }
            : limit,
        );
      }
      return limits;
    })();

    const initialStateData = {
      // add sumOfRoundings
      // shopSalesDoc,
      isCurrentSaleAReturn: getIsCurrentSaleAReturn(getState()),
      currentSalesDocument,
      salesDocument: initSale,
      payments: { ...payments, ...props.payments },
      originalPayments,
      returnPayments:
        props.returnPayments ?? getCurrentSalesDocReturnPayments(getState()),
      shoppingCartTotal,
      selectedCustomer,
      selectedPos,
      employeeID,
      posRoundCash,
      customer: null,
      total: props.total || total,
      paymentLimits,
      resetSalesDocumentOnClose: props.resetSalesDocumentOnClose || false,
      ignoreCurrent: props.ignoreCurrent ?? false,
      payButtonClicked: props.payButtonClicked ?? false,
      cumulativeSumOfRoundings,
      currencies: getAllCurrencies(getState()),
    };

    // If any products have return_tender, use that
    const products = getProductsInShoppingCart(getState());
    const tenders = {};
    products.forEach(order => {
      // Dealing with free-text line products. These items have an ID value of 0. In this case the product is not cached so it should be passed directly form the order.
      const product =
        Number(order.productID) === 0
          ? order
          : getProductByID(order.productID)(getState());
      if (product.amount < 0) {
        const key = new ErplyAttributes(product?.attributes).get('return_tender');
        if (!key) return;
        tenders[key] = (tenders[key] ?? 0) + order.rowTotal;
      }
    });
    const forcedTendersDoNotFitOriginalSale = Object.entries(tenders).some(
      ([key, amount]) =>
        Object.entries(initialStateData.payments)
          .filter(([k, p]) => p.type === key)
          .map(([k, p]) => p.amount)
          .reduce(add, 0) < amount,
    );
    // TODO: If forced return tender and only one such payment on original sale
    // somehow connect those as well
    if (forcedTendersDoNotFitOriginalSale) {
      initialStateData.payments = Object.fromEntries(
        Object.entries(tenders).map(([k, v]) => [
          `forced-${k}`,
          {
            amount: round(v, 2),
            locked: true,
            type: k.toUpperCase(),
            caption: k.toUpperCase(),
          },
        ]),
      );
    }

    const threw = Symbol('threw');
    const stateData = await dispatch(
      pluginOn(
        { props, showNotes, isReopeningAfterSaleFailedToSave },
        initialStateData,
      ),
    ).catch(() => threw);
    if (stateData === threw) {
      setPaymentButtonClicked(false);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const initialState = new InitialState(stateData);

    dispatch(setPaymentsState(initialState));

    dispatch(
      openModalPage({
        component: c.modalPages.Payment,
        modalClassName: 'payment-modal',
        isPopup: true,
        props,
        groupID: c.modalPages.Payment,
        replace: true,
      }),
    );

    dispatch(
      pluginAfter({ props, showNotes, isReopeningAfterSaleFailedToSave }, null),
    );
  };
}
export const openPaymentModal = withProgressAlert(
  'payment:alerts.paymentOpening',
  { errorType: 'openPaymentModal' },
)(openPaymentModalBase);

export function closeModalPageById(id) {
  return {
    type: c.CLOSE_MODAL_PAGE,
    id,
  };
}

export class InitialState {
  constructor({
    isCurrentSaleAReturn,
    currentSalesDocument,
    salesDocument,
    payments,
    originalPayments,
    returnPayments,
    shoppingCartTotal,
    customer,
    resetSalesDocumentOnClose,
    paymentLimits,
    selectedCustomer,
    selectedPos,
    total,
    employeeID,
    cumulativeSumOfRoundings,
    currencies,
    ignoreCurrent = false,
    payButtonClicked = false,
  }) {
    this.cumulativeSumOfRoundings = cumulativeSumOfRoundings;
    // this.returnedPayments = currentSalesDocument.returnedPayments;
    this.salesDocument = {
      ...currentSalesDocument,
      ...salesDocument,
      confirmed: 1,
    };
    this.currentSalesDocument = currentSalesDocument;

    this.total = Number(round(total || shoppingCartTotal, 2));
    this.payments = function calculateStartinPayments() {
      // Somehow payments were undefined after payment modal was opened and closed, but wasn't able to reproduce or understand why, thus the fallback
      const localPayments = JSON.parse(JSON.stringify(payments ?? {}));
      const tip = localPayments.tip ? Number(localPayments.tip.amount) : 0;
      const isReturnPayment =
        isCurrentSaleAReturn || tip + Number(this.total) < 0;
      const shouldShowActualTenders = typesThatShouldShowOriginalPayments.includes(
        this.salesDocument.type,
      );

      if (
        this.salesDocument.paid &&
        !isReturnPayment &&
        !shouldShowActualTenders
      ) {
        return {
          ...localPayments,
          paid: {
            added: timestampInSeconds(),
            amount: this.salesDocument.paid,
            caption: 'Paid',
            giftcard: undefined,
            id: `payment-${timestampInSeconds()}`,
            type: 'PAID',
            currencyRate:
              currencies.find(
                cur => cur.code === this.salesDocument.currencyCode,
              )?.rate ?? 1,
          },
        };
      }

      /**
       * For each payment limit, reduce over the payments of that type
       * counting the total amount used and removing anything that exceeds the limit
       * The first payment to exceed the limit will be reduced to hit the limit exactly, and subsequent payments will be deleted
       *
       * For example, payments [-2.00, -6.00, -5.00] will be reduced as follows
       * Limit | payments
       * -1   | [                   ]  // -1 means blocked entirely
       * 0    | [-2.00, -6.00, -5.00]  // 0 means no limit
       * 4    | [-2.00, -2.00,      ]  // Any other number is a limit to the combined total of all payments of that type
       */
      paymentLimits.forEach(limit => {
        const paymentsOfType = Object.entries(localPayments).filter(
          ([key, value]) =>
            value.type === limit.type &&
            // The presence or absence of a serial needs to also match (used only by gift card)
            // value.serial <-> limit.serial
            !!value.serial === !!limit.serial,
        );

        // 0/null represents no limit - no need to do anything
        if (!limit.amount) {
          return;
        }

        // -1 represents disallowed completely, remove all matching payments
        if (limit.amount === -1) {
          paymentsOfType.forEach(([key]) => delete localPayments[key]);
          return;
        }
        // Any other amount represents a limit, walk through payments apply the limit
        paymentsOfType.reduce(
          (remainingLimit, [key, payment]) => {
            const newAmount = isReturnPayment
              ? Math.max(payment.amount, remainingLimit)
              : Math.min(payment.amount, remainingLimit);

            if (newAmount === 0) {
              delete localPayments[key];
              return remainingLimit;
            }
            // eslint-disable-next-line no-param-reassign
            payment.amount = round(newAmount);
            return remainingLimit - newAmount;
          },
          isReturnPayment ? -Number(limit.amount) : Number(limit.amount),
        );
      });
      return localPayments;
    }.apply(this);
    this.originalPayments = originalPayments;
    this.returnPayments = returnPayments;
    this.paymentsSum = function calculateSumm() {
      return Object.values(this.payments)
        .map(payment => Number(payment.amount))
        .reduce((accumulated, num) => accumulated + num, 0);
    }.apply(this);

    this.balance = function calculateStartingBalance() {
      // if the current sale document is 'type: "ORDER" ' subtract the paid amount from the balance

      const tipKey = Object.keys(this.payments).filter(key =>
        key.includes('tip'),
      );
      const tipAmount = Number((this.payments[tipKey] || { amount: 0 }).amount);
      if (this.salesDocument.type === 'ORDER' && this.salesDocument.paid) {
        return -(this.total - this.paymentsSum + tipAmount * 2);
      }
      return -(this.total - this.paymentsSum + tipAmount * 2);
    }.apply(this);

    this.customer = customer || selectedCustomer;
    this.resetSalesDocumentOnClose = resetSalesDocumentOnClose;
    this.ignoreCurrent = ignoreCurrent;
    this.payButtonClicked = payButtonClicked;
    this.paymentLimits = paymentLimits || [];
    this.selectedPos = selectedPos;
    this.paymentEditIndex = 0;
    this.paymentDecimalEdit = false;
    this.paymentSelected = '';
    this.showPaymentsInput = false;
    this.employeeID = employeeID;
  }
}

/**
 * Checks if sale is an exchange based on shopping cart contents.
 * If sale is an exchange and exchanges are not allowed returns true,
 * otherwise returns false.
 */
export function checkIfBlockedExchange() {
  return async (dispatch, getState) => {
    // Deny entry with mixed quantities or if positive values on return
    const orders = getProductsInShoppingCart(getState());
    const isAllowedExchange = getIsExchangeAllowed(getState());
    const isReturn = getIsAReturn(getState());
    const hasPositive = orders.some(o => o.amount > 0);
    const hasNegative = orders.some(o => o.amount < 0);
    if (!isAllowedExchange) {
      if (hasPositive && hasNegative) {
        dispatch(
          addError(i18next.t('payment:alerts.exchangeNotAllowed'), {
            selfDismiss: 4000,
          }),
        );
        return true;
      }
      if (isReturn && hasPositive) {
        dispatch(
          addError(i18next.t('payment:alerts.exchangeNotAllowedOnReturn'), {
            selfDismiss: 4000,
          }),
        );
        return true;
      }
    }
    return false;
  };
}

export function waitForCartCalculationToFinish() {
  return async (
    dispatch,
    getState,
  ) => {
    const s = 1000;
    const testFn = () =>
      !(
        (getIsLoadingCustomer(getState()) ||
          getCartIsPendingCalculate(getState()) ||
          getIsCalculatingShoppingCart(getState())) &&
        getConnectionHealth(getState())
      );
    const callback = () =>
      dispatch(
        addWarning(i18next.t('alerts:loading.waitingForCartToCalculate'), {
          selfDismiss: false,
          errorType: 'payment-waiting',
        }),
      );

    try {
      await waitForCondition({
        testFn,
        timeout: 60 * s,
        stability: 0.75 * s,
        checkInterval: 0.1 * s,
        callbacks: {
          [1 * s]: callback,
        },
      });
    } catch (error) {
      dispatch(
        addError(i18next.t('alerts:loading.waitingForCartToCalculate_timedOut'), {
          selfDismiss: 5000,
        }),
      );
      throw error;
    } finally {
      dispatch(dismissType('payment-waiting'));
    }
    const calculationFailed = getShouldCalculateOffline(getState());
    const isOnline = getConnectionHealth(getState());
    if (isOnline && calculationFailed) {
      await dispatch(calculate());
      // Calculation still failed
      if (getShouldCalculateOffline(getState())) {
        dispatch(
          addError(i18next.t('alerts:loading.waitingForCartToCalculate_error'), {
            selfDismiss: 5000,
          }),
        );
        throw new Error('Cart calculation failed');
      }
    }
  };
}
