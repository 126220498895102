import React from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import * as R from 'ramda';

import { FormFieldConfiguration } from '../../../types';

import DisabledOnSelect from './DisabledOnSelect';
import useFieldConfiguration, { Props } from './useFieldConfiguration';

function FieldConfigurationDesktop({
  field,
  label,
  config = {} as FormFieldConfiguration,
  updateConfig,
}: Props) {
  const {
    editMode: [editMode, setEditMode],
    title: [title, { set: setTitle, reset: resetTitle, save: saveTitle }],
    defaultValue: {
      Input: DefaultValueInput,
      update: updateDefaultValue,
      isSupported: supportsDefaultValue,
      isDisableable: isAllowedToDisable,
    },
  } = useFieldConfiguration({
    field,
    label,
    config,
    updateConfig,
  });

  return (
    <TableRow key={field}>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {editMode ? (
            <TextField value={title} onChange={e => setTitle(e.target.value)} />
          ) : (
            title ?? label
          )}
          <div>
            {editMode ? (
              <IconButton onClick={saveTitle} title="Save">
                <SaveIcon color="secondary" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setEditMode(true)} title="Edit">
                <EditIcon color="primary" />
              </IconButton>
            )}
            {config.label && (
              <IconButton onClick={resetTitle} title="Reset">
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            )}
          </div>
        </Box>
      </TableCell>
      <TableCell>
        {supportsDefaultValue ? (
          <DefaultValueInput
            value={config.defaultValue}
            label={label}
            onChange={updateDefaultValue}
          />
        ) : null}
      </TableCell>
      <TableCell>
        <DisabledOnSelect
          value={config.disabledOn}
          disabled={!isAllowedToDisable}
          label={label}
          onChange={e =>
            updateConfig(field, R.assoc('disabledOn', e.target.value, config))
          }
        />
      </TableCell>
      <TableCell>
        <Checkbox
          disabled={!isAllowedToDisable}
          checked={config.enabled ?? false}
          onChange={() =>
            updateConfig(field, R.assoc('enabled', !config.enabled, config))
          }
        />
      </TableCell>
      <TableCell>
        <Checkbox
          disabled={!isAllowedToDisable}
          checked={config.required ?? false}
          onChange={() =>
            updateConfig(field, R.assoc('required', !config.required, config))
          }
        />
      </TableCell>
    </TableRow>
  );
}

export default React.memo(FieldConfigurationDesktop);
