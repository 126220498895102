import React from 'react';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { PosPlugin } from 'plugins/plugin';
import { CardPaymentHooks, FUNC_BUTTONS } from 'paymentIntegrations/types';
import { getExistingInReduxHookPayments } from 'reducers/Payments';
import { markForProcessing, deletePayment } from 'actions/Payments';
import { RootState } from 'reducers';

import { processPayments } from '../handlers/processPayments';

const closeModal = params => async () => {
  params.rejectPayments();
};

export const functions = [
  {
    actionOnClick: closeModal,
    name: 'close',
    text: 'Close',
    variant: 'danger',
  },
];

export const customPaymentIntegration: PosPlugin['customPaymentIntegration'] = {
  id: 'vii',
  // customComponent: CardPaymentUI,
  initPayments: (params: CardPaymentHooks) => async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
  ) => {
    const {
      cardPayments,
      enableButtons,
      rejectPayments,
      resolvePayments,
      updateMessage,
    } = params;
    const shouldVoid = cardPayments.every(cp => cp.paid && cp.shouldProcess);
    enableButtons([FUNC_BUTTONS.CANCEL]);
    const cardsToProcess = cardPayments.filter(p =>
      shouldVoid ? p.paid : !p.paid,
    );
    return dispatch(
      processPayments({ ...params, cardPayments: cardsToProcess }),
    ).then(({ errors }) => {
      if (errors?.length) {
        enableButtons([FUNC_BUTTONS.CLOSE]);
      } else if (shouldVoid) {
        rejectPayments();
      } else {
        updateMessage('Payment Successfull');
        resolvePayments();
      }
    });
  },
  voidPayments: params => async (dispatch, getState) => {
    const paymentsToVoid = getExistingInReduxHookPayments(params.cardPayments)(
      getState(),
    );
    paymentsToVoid.forEach(({ key, paid }) => {
      if (paid) {
        dispatch(markForProcessing({ key }));
      } else {
        dispatch(deletePayment({ key }));
      }
    });
    const cardPayments = getExistingInReduxHookPayments(params.cardPayments)(
      getState(),
    );
    return new Promise((resolve, reject) =>
      dispatch(
        openModalPage({
          component: modalPages.cardPaymentUI,
          isPopup: true,
          modalClassName: 'card-payment-ui',
          props: {
            integration: customPaymentIntegration,
            payments: cardPayments,
            resolvePayments: resolve,
            rejectPayments: reject,
            isVoid: true,
            functions,
          },
          groupID: modalPages.cardPaymentUI as any,
          replace: false,
        }),
      ),
    ).finally(() => dispatch(previousModalPage()));
  },
  functions,
};
