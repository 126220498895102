import { addError, addWarning } from 'actions/Error';

import { getCustomerDisplayState } from './reducer';
import {
  SET_FETCHED_INITIAL_SCREEN_DETAILS,
  SET_HAS_PERMISSION,
  SET_SCREENS,
  SET_SELECTED_SCREEN,
} from './actionTypes';

export const setHasPermission = value => ({
  type: SET_HAS_PERMISSION,
  payload: value,
});

export const setFetchedInitialScreenDetails = value => ({
  type: SET_FETCHED_INITIAL_SCREEN_DETAILS,
  payload: value,
});

export const setScreens = screens => ({
  type: SET_SCREENS,
  payload: screens,
});

export const setSelectedScreen = screen => ({
  type: SET_SELECTED_SCREEN,
  payload: screen,
});

const typedWindow = window as Window & {
  [key: string]: any;
  screen: Screen & { isExtended?: boolean };
};

export const getScreenDetails = () => async (dispatch, getState) => {
  const { hasPermission } = getCustomerDisplayState(getState());
  if (hasPermission !== null && !hasPermission) return null;
  try {
    if (!('getScreenDetails' in typedWindow)) {
      dispatch(setHasPermission(false));
      dispatch(
        addError('Multi-screen window open is not supported by your browser'),
      );
      return null;
    }

    const details = await typedWindow.getScreenDetails();
    if (hasPermission === null) dispatch(setHasPermission(true));
    dispatch(setScreens(details.screens));
    dispatch(setSelectedScreen(null));
    return details;
  } catch (error) {
    if (hasPermission === null) {
      dispatch(setHasPermission(false));
      dispatch(addError('Permission to detect multiple screens denied'));
      return null;
    }
    throw error;
  }
};

export const openWindowOnSelectedScreen = url => async (dispatch, getState) => {
  const { selectedScreen } = getCustomerDisplayState(getState());

  if (!selectedScreen) {
    dispatch(addWarning('Screen not selected. Opening on current screen.'));
    return window.open(url);
  }

  return window.open(
    url,
    undefined,
    `
      left=${selectedScreen.screen.left}
      top=${selectedScreen.screen.top}
      width=${selectedScreen.screen.width}
      height=${selectedScreen.screen.height}
    `,
  );
};
