/* eslint-disable @typescript-eslint/camelcase */
import axios, { AxiosResponse } from 'axios';

import { getServiceEndpoints } from 'services/ErplyAPI/getServiceEndpoints';
import { JsonAPISuccessResponse } from 'services/JsonAPI';
import { proxy } from 'services/shared';
import {
  REDUX_CLIENTCODE,
  REDUX_SERVICE_ENDPOINTS,
  REDUX_SESSIONKEY,
} from 'constants/persistence';

import { PrintoutExtrasWBURecord } from '../customReceipt/types';

type WBUJsonApiSuccessResponse = JsonAPISuccessResponse & {
  json_object: {
    printoutExtrasWBU: PrintoutExtrasWBURecord;
  };
};

const getJsonApiUrl = async () => {
  const { url } = JSON.parse(
    localStorage.getItem(REDUX_SERVICE_ENDPOINTS) || '{}',
  )?.json;
  if (url) return url;
  const [payload] = await getServiceEndpoints();
  return payload?.json?.url;
};

export const saveWBUDataToInvoiceInJsonApi = async (
  id: number,
  data: PrintoutExtrasWBURecord,
) => {
  const jsonApiUrl = await getJsonApiUrl();
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  return axios.put<WBUJsonApiSuccessResponse>(
    `${proxy}${jsonApiUrl}v1/json_object/invoice/${id}`,

    {
      id,
      json_object: {
        printoutExtrasWBU: data,
      },
    },
    {
      headers: { clientCode, sessionKey },
    },
  );
};

export const fetchWBUDataFromInvoiceFromJsonApi = async ({
  id,
}: {
  id: number;
}): Promise<AxiosResponse<WBUJsonApiSuccessResponse>> => {
  const jsonApiUrl = await getJsonApiUrl();
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  return axios.get<WBUJsonApiSuccessResponse>(
    `${proxy}${jsonApiUrl}v1/json_object/invoice/${id}`,
    {
      headers: { clientCode, sessionKey },
    },
  );
};
