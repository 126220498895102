import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { reactcursivelyMap } from 'utils/react';

/**
 * Removes product barcode column
 */
const UIReturnOrderProduct: PosPlugin['UIReturnOrderProduct'] = ({
  children,
}) => {
  return reactcursivelyMap(
    R.reject(R.pathEq(['props', 'data-testid'], 'product-row-barcode')),
  )(children);
};

export default UIReturnOrderProduct;
