import React from 'react';
import { Box, Card, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';

import { getHomeStoreById, getSelectedWarehouse } from 'reducers/warehouses';
import {
  getCurrencyFormatter,
  getCurrentPosLanguage,
} from 'reducers/configs/settings';

import { ErplyAttributes } from '../../../../utils';

import { Coupon } from './types';

const useStyles = makeStyles(theme => ({
  coupon: {
    borderColor: '#999',
    '&:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  title: {
    '& *': { display: 'inline' },
  },
  details: {
    display: 'flex',
    fontSize: '1.05rem',
    fontWeight: 600,
    '& div:first-child': {
      marginRight: '1rem',
      '& span:last-child': {
        color: theme.palette.error.main,
      },
    },
  },
  redeemedAlert: {
    marginTop: '1rem',
  },
}));

interface Props {
  coupon: Coupon;
}

function CouponCard({ coupon }: Props) {
  const styles = useStyles();
  const { t } = useTranslation('campaigns');
  const language = useSelector(getCurrentPosLanguage);
  dayjs.locale(language);
  const { timeZone, attributes } = useSelector(getSelectedWarehouse);
  const langCode = ['en', 'fr'].includes(language)
    ? language.toUpperCase()
    : 'EN';

  const priceTier = String(new ErplyAttributes(attributes).get('price_tier'));
  const getDescr = () => {
    switch (priceTier) {
      case '1':
        return coupon.saveDescrFR1;
      case '2':
        return coupon.saveDescrENT2;
      case '3':
        return coupon.saveDescrENT3;
      case '4':
        return coupon.saveDescrENT4;
      default:
        return `Error: Current warehouse has no attribute price_tier`;
    }
  };
  const getLocalizedCouponProp = (propertyName: keyof Coupon) => {
    const localizedPropName = propertyName.replace(/(EN)|(FR)/g, langCode);
    return coupon[localizedPropName];
  };

  const currencyFormatter = useSelector(getCurrencyFormatter);
  const redeemedHomeStore = useSelector(
    getHomeStoreById(coupon.redeemedWarehouseId),
  );
  const redeemedDateTime = coupon.isRedeemed
    ? // In dayjs time zone names are hardcoded in en-US locale
      // this causes EDT time zone to be displayed as EDT in French while expected name is UTC-4
      // thus dayjs is not used here, instead formatting logic is copied from the Berlin plugin
      new Date(coupon.redeemedTimestamp * 1000).toLocaleString(language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        // End date should format in local time zone (see footnote 1)
        timeZone,
      })
    : '';

  return (
    <Card variant="outlined" className={styles.coupon}>
      <Box padding="1rem">
        <div className={styles.title}>
          <Typography variant="h6">
            {getDescr()} {getLocalizedCouponProp('offerTitleEN')}
          </Typography>
          <Typography variant="body1">
            {' '}
            ({getLocalizedCouponProp('erplyTitleEN')})
          </Typography>
        </div>

        {coupon.endDate ? (
          <Typography
            variant="body2"
            color={coupon.expired ? 'error' : 'textSecondary'}
            paragraph
          >
            {coupon.expired
              ? t('loadCoupons.coupon.expired')
              : t('loadCoupons.coupon.expirationDate', {
                  // Per Berlin plugin, take provided time and subtract 03:00:01, display result
                  date: dayjs(coupon.endDate)
                    // Expiration should render exactly as in Berlin plugin (see footnote 1)
                    .subtract(3, 'hours')
                    .subtract(1, 'seconds')
                    .format('dddd, MMMM DD, YYYY'),
                })}
          </Typography>
        ) : null}
        <Typography variant="body1">
          {getLocalizedCouponProp('offerDescEN2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {getLocalizedCouponProp('offerDescEN3')}
        </Typography>
        <div className={styles.details}>
          {coupon.salePrice ? (
            <div>
              <span>{t('loadCoupons.coupon.salePrice')}</span>
              <span>{currencyFormatter(coupon.salePrice)}</span>
            </div>
          ) : null}
          <div>
            {t('loadCoupons.coupon.pluList')}
            {coupon.productList?.replace(/\|/g, ', ')}
          </div>
        </div>
        {coupon.isRedeemed ? (
          <Alert severity="error" className={styles.redeemedAlert}>
            {redeemedHomeStore
              ? t('loadCoupons.coupon.redeemed', {
                  homeStoreName: redeemedHomeStore?.name,
                  date: redeemedDateTime,
                })
              : t('loadCoupons.coupon.redeemedUnknownLocation', {
                  date: redeemedDateTime,
                })}
          </Alert>
        ) : null}
      </Box>
    </Card>
  );
}

/**
 * Footnote 1:
 * M&M have explicitly requested the different dates on this screen to be rendered in different time zones
 * - Per PBIB-6997, the expiration date should be rendered exactly as in the Berlin plugin which is UTC minus 3 hours and 1 second
 * - Per PBIB-7361, the coupon redemption date should be rendered in the timezone of the selected warehouse
 */

export default CouponCard;
