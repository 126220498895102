import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addError, addSuccess } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';

import { GivexCard } from '../types';
import { GivexCardInput } from '../components/GivexCardInput';
import { transferBalance } from '../API/givexAPI';
import {
  getGivexConfiguration,
  getValidateGivexCard,
} from '../configuration/Configuration';

/**
 * The transfer view allows the entire balance of one card to be transferred onto another
 *
 * This is commonly used to upgrade a customer from a non-pin card to a pin-enabled card
 */
const GivexTransfer = () => {
  const [fromCard, setFromCard] = useState<GivexCard>({ cardNo: '', pin: '' });
  const [toCard, setToCard] = useState<GivexCard>({ cardNo: '', pin: '' });
  const { displayName } = useSelector(getGivexConfiguration);

  const dispatch = useDispatch();
  const validateCard = useSelector(getValidateGivexCard);
  const givexValidationErrorMessageFrom = validateCard(
    fromCard,
    'transfer-from',
  );
  const givexValidationErrorMessageTo = validateCard(toCard, 'transfer-to');

  const handleClose = () => dispatch(previousModalPage());
  const transferAndClose = (from: GivexCard, to: GivexCard) => {
    if (givexValidationErrorMessageFrom) {
      dispatch(addError(givexValidationErrorMessageFrom));
      return;
    }
    if (givexValidationErrorMessageTo) {
      dispatch(addError(givexValidationErrorMessageTo));
      return;
    }
    transferBalance(from, to)
      .then(handleClose)
      .then(() => dispatch(addSuccess(`Balance transfer successful`)))
      .catch(e => dispatch(addError(e.message)));
  };

  return (
    <div data-testid="givex-giftcard-transfer">
      <Modal.Header>
        <Modal.Title>
          <h3 data-testid="title">
            <b>{displayName} transfer</b>
          </h3>
        </Modal.Title>
        <div className="d-flex align-content-center">
          <SaveButton
            action={() => transferAndClose(fromCard, toCard)}
            data-testid="save"
            title="Do transfer"
          />
          <CloseButton action={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <h2 className="my-2">Transfer balance from card</h2>
        <GivexCardInput
          value={fromCard}
          onChange={setFromCard}
          type="transfer-from"
        />
        <h2 className="my-2">To card</h2>
        <GivexCardInput
          value={toCard}
          onChange={setToCard}
          type="transfer-to"
        />
      </Modal.Body>
    </div>
  );
};

export default GivexTransfer;
