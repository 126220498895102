import React, { FC, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { LinearProgress } from '@material-ui/core';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-use';

import UIButton from 'components/UIElements/UIButton';
import { modalPages as mp } from 'constants/modalPage';
import { addProduct as addProductAction } from 'actions/ShoppingCart/addProduct';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { useAvailableMatrixDimensions } from 'containers/Forms/matrixVariations/useMatrixDimensions';
import useProducts from 'utils/hooks/useProducts';
import { notUndefinedOrNull } from 'utils';
import { Product } from 'types/Product';

import './matrixVariations.scss';
import { Dimension, ProductVariation } from './types';
import { getSelectedWarehouseID } from 'reducers/warehouses';

type Props = {
  selectedProduct: {
    productID: string;
  } & Partial<Product>;
};

const MatrixSelect: FC<Props> = ({ selectedProduct }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('product');
  const warehouseID = useSelector(getSelectedWarehouseID);

  const [
    selectedDimensions,
    { set: select, remove: deselect, get: getSelected },
  ] = useMap();

  const toggle = (dimID: number, dimValueID: number) => {
    if (getSelected(dimID) === dimValueID) {
      deselect(dimID);
    } else {
      select(dimID, dimValueID);
    }
  };

  const {
    products: [fetchedProduct],
  } = useProducts({
    productID: Number(selectedProduct.productID),
    getProductsFor: 'SALES',
    warehouseID,
  });
  const {
    dimensions: [dimension1, dimension2, dimension3],
    products: { products: variationProducts, loading: loadingFilters },
  } = useAvailableMatrixDimensions(
    (fetchedProduct ?? selectedProduct)?.variationList,
  );

  /**
   * Given any of the three dimensions,
   * returns a test that will only pass when the given product matches all the provided dimensions
   * and is also available for sale
   * <p>Omitted dimensions are not filtered
   * @example
   * const test1 = matchesDimensions(null,null,null) // always passes
   * const test2 = matchesDimensions(null,null,2) // passes for all products with dim3 = 2
   */
  const matchesDimensions = (dimensionID: number, dimensionValueID: number) => (
    variation: ProductVariation,
  ) => {
    const check = {
      ...selectedDimensions,
      [dimensionID]: dimensionValueID,
    };
    return (
      Object.entries(check).every(
        ([dimID, valueID]) =>
          variation.dimensions.find(dim => dim.dimensionID === Number(dimID))
            ?.dimensionValueID === valueID,
      ) &&
      variationProducts.some(
        p => String(p.productID) === String(variation.productID),
      )
    );
  };

  const openMatrixMultipleView = useCallback(() => {
    dispatch(previousModalPage());
    dispatch(
      openModalPage({
        component: mp.matrixVariationsMultiple,
        props: { productID: selectedProduct.productID },
      }),
    );
  }, [dispatch, selectedProduct]);

  /**
   * Auto-select the only option for dimensions that have single option
   */
  useEffect(() => {
    const setDefault = ({ dimensionID, values }) => {
      if (values.length === 1)
        if (!getSelected(dimensionID))
          select(dimensionID, values[0].dimensionValueID);
    };
    [dimension1, dimension2, dimension3].forEach(dim => {
      if (dim) setDefault(dim);
    });
  }, [
    dimension1,
    dimension2,
    dimension3,
    getSelected,
    select,
    selectedDimensions,
  ]);

  /**
   * Automatically adds variation product to Shopping Cart when
   * - all dimensions are loaded
   * - all available dimensions are selected
   */
  useEffect(() => {
    if (!loadingFilters) {
      const numberOfDimensions = [dimension1, dimension2, dimension3].filter(
        a => a,
      ).length;
      const numberOfSelectedDimensions = Object.keys(selectedDimensions).length;
      if (
        numberOfDimensions &&
        numberOfDimensions === numberOfSelectedDimensions
      ) {
        const selectedVariation = fetchedProduct?.variationList?.find(
          variation =>
            variation.dimensions.every(
              dim => getSelected(dim.dimensionID) === dim.dimensionValueID,
            ),
        );
        if (selectedVariation?.productID) {
          dispatch(
            addProductAction({
              productID: Number(selectedVariation.productID),
            }),
          );
          dispatch(previousModalPage());
        }
      }
    }
  }, [
    selectedDimensions,
    dimension1,
    dimension2,
    dimension3,
    loadingFilters,
    fetchedProduct,
    getSelected,
    dispatch,
  ]);

  return (
    <>
      <Modal.Header
        style={{ display: 'flex' }}
        data-testid="matrix-select-header"
      >
        <Modal.Title data-testid="title">
          {t('matrix.variationSelect.title')}
        </Modal.Title>
        <div style={{ flexGrow: 1 }} />
        <CloseButton action={() => dispatch(previousModalPage())} />
      </Modal.Header>
      <Modal.Body data-testid="matrix-select">
        {loadingFilters && <LinearProgress variant="indeterminate" />}
        {[dimension2, dimension1, dimension3]
          .filter(notUndefinedOrNull)
          .map((dim: Dimension) => (
            <Container
              data-testid="dimension"
              data-test-key={dim.dimensionID}
              key={dim.dimensionID}
            >
              <span data-testid="name">{dim.name}</span>
              {dim.values?.map(option => (
                <SquareButton
                  key={option.code}
                  text={option.value}
                  selected={
                    getSelected(dim.dimensionID) === option.dimensionValueID
                  }
                  onClick={() =>
                    toggle(dim.dimensionID, option.dimensionValueID)
                  }
                  disabled={
                    !selectedProduct?.variationList?.some(
                      matchesDimensions(
                        dim.dimensionID,
                        option.dimensionValueID,
                      ),
                    ) || loadingFilters
                  }
                />
              ))}
            </Container>
          ))}
        <div className="text-hr">{t('common:or')}</div>
        <Container>
          <UIButton
            data-testid="button-select-multiple"
            variant="POS"
            text={t('matrix.variationSelect.buttonMultiple')}
            action={openMatrixMultipleView}
          />
        </Container>
      </Modal.Body>
    </>
  );
};

const SquareButton = ({
  selected,
  text,
  disabled,
  onClick,
}: {
  selected: boolean;
  text: string;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <Button
      disabled={disabled}
      data-testid="dimension-button"
      className={selected ? 'selected' : undefined}
      data-test-key={text}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default MatrixSelect;
