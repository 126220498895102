import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PosPlugin } from 'plugins/plugin';
import ListItem from 'components/ListItem';
import { openPluginModalPage } from 'actions/modalPage';
import {
  getOriginalPayments,
  getPaymentIsDefaultCustomer,
  getPayments,
  getSalesDocument,
  getTotal,
  getPaymentsTotal,
  getHasPaymentsOfType,
} from 'reducers/Payments';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { addError, addWarning } from 'actions/Error';

import { getHasSchoolAssignedToPositiveProducts } from '../rdx/reducers';
import { defaultVoucherTypeID, pluginID } from '../constants';
import { Configuration } from '../types';
import { modals } from '../NSWVouchers/components';

import { baseLog } from '..';

const ButtonItem = ({ style = {}, ...props }) => (
  <ListItem
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontFamily: 'Proxima Nova',
      minWidth: 80,
      minHeight: 76,
      height: 'inherit',
      width: 'inherit',
      ...style,
    }}
    {...props}
  />
);

const UIKeyPad: PosPlugin['UIKeyPad'] = ({
  children,
  customer,
  code,
  balance,
}) => {
  const log = baseLog.extend('UIKeyPad');
  const payments = useSelector(getPayments);
  const dispatch = useDispatch();
  const isClubFitCustomer =
    (customer?.groupName ?? '').toUpperCase() === 'CLUBFIT';

  const { PaymentTypeID } = useSelector(
    getPluginConfigurationAtLevel<Configuration>(pluginID, 'Company', ''),
  ) ?? {
    PaymentTypeID: defaultVoucherTypeID,
  };
  const total = useSelector(getTotal);
  const currentSale = useSelector(getSalesDocument);
  const isCurrentSalesDocumentCustomerDefaultCustomer = useSelector(
    getPaymentIsDefaultCustomer,
  );
  const originalPayments = useSelector(getOriginalPayments);
  const hasSchoolAssignedToPositiveProducts = useSelector(
    getHasSchoolAssignedToPositiveProducts,
  );

  const isSaleAReturn = useSelector(getPaymentsTotal) < 0;
  const paidWithOnlinePayment = useSelector(getHasPaymentsOfType('ONLINE'));
  const isOnlineReturn = paidWithOnlinePayment && isSaleAReturn;

  const reduxPaymentTypes = useSelector(getAllPaymentTypes);

  const shouldShowNSWVoucherKeyPadButton = () => {
    log('Checking if NSW Voucher Key Pad should be shown. Data given: ', {
      currentSale,
      originalPayments,
      PaymentTypeID,
      hasSchoolAssignedToPositiveProducts,
      total,
    });
    if (currentSale.id && currentSale.type === 'CREDITINVOICE') {
      log('Current sale is referenced return');
      // If exchange sale more than original sale,
      if (total > 0) {
        log(
          'Payment due. Checking if has school assigned to positive quantity products',
        );
        // Customer can pay difference with any tender, including NSW voucher if exchanged for “school” use products.
        if (hasSchoolAssignedToPositiveProducts) {
          log('It does. NSW Voucher keypad visible');
          return true;
        }
        log('It does not. NSW Voucher keypad hidden');
        // Falls through to returning false
      } else {
        log('Sum must be returned to customer. Not showing NSW Voucher keypad');
        return false;
      }
      return false;
    }
    log('Not a referenced return. Showing NSW Voucher');
    return true;
  };

  const { t } = useTranslation('payment');
  const showVoucherModal = () => {
    if (!PaymentTypeID) {
      return dispatch(
        addError('Payment type not configured', {
          selfDismiss: 3000,
        }),
      );
    }
    const paymentType = reduxPaymentTypes.find(
      rpt => `${rpt.id}` === PaymentTypeID,
    );
    if (!paymentType) {
      return dispatch(
        addError('Configured payment type not found', {
          selfDismiss: 3000,
        }),
      );
    }
    // Adding a new NSW voucher payment to the list while balance is covered
    if (balance === 0 && !Object.keys(payments).includes('NSW')) {
      return dispatch(
        addWarning(t('alerts.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
    return dispatch(
      openPluginModalPage(modals.voucherInputModal)({
        isPopup: true,
        groupID: 'nsw-modal',
        props: {
          balance,
          customer,
          paymentType,
          currencyCode: code,
        },
      }),
    );
  };

  return R.pipe(
    React.Children.toArray,
    R.map(
      R.when(el => {
        const dataTestIdVal: string | undefined = R.path([
          'props',
          'children',
          'props',
          'data-testid',
        ])(el);
        return dataTestIdVal?.includes('payment-') && isOnlineReturn;
      }, R.assocPath(['props', 'children', 'props', 'disabled'], true)),
    ),
    R.prepend(
      <ListItem
        action={() =>
          dispatch(openPluginModalPage('PartnerModal')({ isPopup: true }))
        }
        style={{ minHeight: 76, width: 80 }}
        disabled={
          payments.PARTNER ||
          !isClubFitCustomer ||
          isCurrentSalesDocumentCustomerDefaultCustomer ||
          isOnlineReturn
        }
        key="partnerPayment"
        variant="grid_yellow"
      >
        Partner
      </ListItem>,
    ),
    R.when(
      shouldShowNSWVoucherKeyPadButton,
      R.prepend(
        <ButtonItem
          variant="grid_yellow"
          action={showVoucherModal}
          disabled={isOnlineReturn}
          key="nswVoucher"
        >
          Service NSW Voucher
        </ButtonItem>,
      ),
    ),
  )(children);
};

export default UIKeyPad;
