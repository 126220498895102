import React from 'react';

export const PostableLink = ({ post, href, children, style, ...rest }) => {
  const open = e => {
    e.preventDefault();
    const form = e.target.closest('form');
    form.target = '_blank';
    form.submit();
  };

  return (
    <form action={href} method={post ? 'post' : 'get'}>
      {post
        ? Object.entries(post).map(([k, v]) => (
            <input key={k} type="hidden" name={k} value={v} />
          ))
        : null}
      <span
        onClick={open}
        onAuxClick={open}
        style={{ cursor: 'pointer', ...style }}
        {...rest}
      >
        {children}
      </span>
    </form>
  );
};
