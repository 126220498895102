import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useSearchStyles = makeStyles<Theme, { showPopover: boolean }>({
  container: {
    display: 'flex',
  },
  item: {
    flex: '1 1 0',
    display: 'flex',
  },
  activeItem: {
    flex: '1 1 0',
    display: 'flex',
    position: 'relative',
  },
  passiveItem: {
    display: 'none',
  },
});

export const useSearchResultStyles = makeStyles({
  resultRow: {
    cursor: 'pointer',
  },
  name: { maxWidth: 128, overflow: 'hidden' },
  email: { maxWidth: 128, overflow: 'hidden' },
  cardNumber: { maxWidth: 128, overflow: 'hidden' },
  phone: { maxWidth: 128, overflow: 'hidden' },
  generic: { maxWidth: 128, overflow: 'hidden' },
});
