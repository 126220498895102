import { v4 as uuidv4 } from 'uuid';

import { GivexCard } from 'plugins/givexHeartland/types';

import { defaultGivexUrl } from '../configuration/Configuration';

import {
  GivexActivateOrIncrementResponse,
  GivexBalanceResponse,
  GivexBalanceTransferResponse,
  GivexCancelResponse,
  GivexCardRequestResponse,
  GivexErrorResponse,
  GivexHistoryResponse,
  GivexPaymentResponse,
} from './types';

export class GivexError extends Error {
  canRetry: boolean;

  response: GivexErrorResponse | null;

  constructor(
    message: string,
    canRetry: boolean,
    response?: GivexErrorResponse,
  ) {
    super(message);
    this.canRetry = canRetry;
    this.response = response ?? null;
  }
}

const errorForResponse = msg => {
  const getError = response => {
    const code = Number(response.statusCode);
    const errTxt = {
      2: 'The request to GIVEX has timed out',
      3: 'Connection to GIVEX could not be established',
      4: 'MS Config error: no such host',
      40: 'GIVEX card is not activated',
    };
    if (code) {
      switch (code) {
        case 2:
        case 3:
        case 4:
          return new GivexError(errTxt[code], true, response.data ?? response);
        case 1:
        default:
          return new GivexError(
            errTxt[code] ?? response.statusMessage,
            false,
            response.data ?? response,
          );
      }
    }
    return null;
  };

  const baseErr = getError(msg);
  if (baseErr) return baseErr;
  if (!msg.data)
    return new GivexError(
      'Invalid response from MS (statusCode 0 but no data)',
      false,
    );
  return getError(msg.data);
};

const stripUndefineds = obj =>
  Object.fromEntries(Object.entries(obj).filter(([, b]) => b !== undefined));

let givexURL = defaultGivexUrl;
export const updateGivexURL = (newURL: string) => {
  givexURL = newURL;
};
const makeRequest = <T>(req, data) =>
  new Promise<{ data: T; id: string }>((resolve, reject) => {
    const ws = new window.WebSocket(givexURL);

    ws.onopen = () =>
      ws.send(
        JSON.stringify({
          id: uuidv4(),
          function: req,
          data: stripUndefineds(data),
        }),
      );
    // eslint-disable-next-line no-multi-assign
    ws.onclose = ws.onerror = () =>
      reject(
        new Error(
          'No connection to Givex Microservice - Make sure that it is running',
        ),
      );

    ws.onmessage = rawMsg => {
      const msg = JSON.parse(rawMsg.data);
      const err = errorForResponse(msg);

      if (err) reject(err);
      else resolve(msg);
    };
  });

/**
 * Fetch balance and expiration information about the given card
 */
export const getBalance = (card: GivexCard) =>
  makeRequest<GivexBalanceResponse>('balance', {
    cardno: card.cardNo,
    securityCode: card.pin,
  }).then(msg =>
    msg.data
      ? {
          ...msg.data,
          certificateExpirationDate:
            msg.data.certificateExpirationDate === 'None'
              ? null
              : msg.data.certificateExpirationDate,
        }
      : msg.data,
  );

/**
 * Fetch edit history for the given card (activations, transactions and adjustments).
 *
 * NB: Cancelled charges are removed from history, rather than listing both charge and cancellation
 */
export const getHistory = (card: GivexCard) =>
  makeRequest<GivexHistoryResponse>('history', {
    cardno: card.cardNo,
    securityCode: card.pin,
    historyType: 'Certificate',
  }).then(msg => msg.data);

/**
 * Add money to a gift card, creating it if it does not already exist
 */
export const refillGiftcard = (card: GivexCard, sum: string | number) =>
  makeRequest<GivexActivateOrIncrementResponse>('activateOrIncrement', {
    cardno: card.cardNo,
    sum: Math.abs(Number(sum)).toFixed(2),
    securityCode: card.pin,
  }).then(msg => ({ ...msg.data, referenceNumber: msg.id }));

export const adjustGiftCard = (card: GivexCard, sum) =>
  makeRequest('adjustment', {
    cardno: card.cardNo,
    sum: String(sum),
    securityCode: card.pin,
  }).then(msg => msg.data);

/**
 * Charge (make a payment with) a gift card, subtracting balance from it
 */
export const chargeGiftcard = (card: GivexCard, sum: string | number) =>
  makeRequest<GivexPaymentResponse>('Payment', {
    cardno: card.cardNo,
    sum: Math.abs(Number(sum)).toFixed(2),
    securityCode: card.pin,
  }).then(msg => msg.data);

/**
 * Cancel a previous charge - this removes the charge from the card's history, unlike {@link refillGiftcard} which would be listed as a second transaction
 */
export const cancelGiftcardCharge = (card: GivexCard, sum: string | number) =>
  makeRequest<GivexCancelResponse>('Cancel', {
    cardno: card.cardNo,
    sum: Math.abs(Number(sum)).toFixed(2),
    securityCode: card.pin,
  }).then(msg => msg.data);

/**
 * Cancel a previous refill - Givex doesn't have a way to cancel refills, so this is just the chargeGiftcard method to achieve a similar end result
 */
export const reverseGiftcardRefill = (card, sum) => chargeGiftcard(card, sum);

/**
 * Transfer the entire balance of one card onto another
 *
 * Used when switching cards (for example to trade a pinless card for a pin card)
 */
export const transferBalance = (from: GivexCard, to: GivexCard) =>
  makeRequest<GivexBalanceTransferResponse>('balanceTransfer', {
    cardno: from.cardNo,
    cardnoTransferTo: to.cardNo,
    securityCode: from.pin,
  }).then(msg => msg.data);

/**
 * Makes the card inactive
 */
export const deactivateGiftcard = (card: GivexCard) =>
  makeRequest<GivexCardRequestResponse>('cardRequest', {
    cardno: card.cardNo,
    securityCode: card.pin,
    cardRequestActionType: 'Cancel',
  }).then(msg => msg.data);
