import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getSelectedOrder } from 'reducers/ShoppingCart';
import { modalPages } from 'constants/modalPage';
import { openProductViewForItem } from 'actions/productsDB';
import useProducts from 'utils/hooks/useProducts';
import { IconName } from 'components/Icon/types';

type ProductActionProps = {
  isMatrixProduct: boolean;
};

type ActionType = {
  name: IconName;
  title: string;
  hide?: boolean;
  action: () => void;
};

const ProductActions: FC<ProductActionProps> = ({
  isMatrixProduct = false,
}) => {
  const { t } = useTranslation('shoppingCart');
  const dispatch = useDispatch();

  const order = useSelector(getSelectedOrder);
  const props = useMemo(() => ({ productID: order?.productID }), [
    order?.productID,
  ]);
  const {
    products: [product],
  } = useProducts(props);
  const hasRelatedProducts =
    product?.relatedProducts?.length || product?.replacementProducts?.length;
  const actions: ActionType[] = [
    {
      name: 'icon_puzzle',
      title: t('actions.cross-sell'),
      action: () =>
        dispatch(
          openModalPage({
            component: modalPages.relatedProducts,
            props: {
              productID: product?.productID,
              parentRowID: order.orderIndex,
            },
            modalClassName: 'related-products-modal',
            groupID: 'RELATED_PRODUCTS',
          }),
        ),
      hide: !hasRelatedProducts,
    },
    {
      name: 'icon_house_alt',
      title: t('actions.search'),
      action: async () => {
        dispatch(openProductViewForItem(order));
      },
    },
    {
      name: 'icon_pencil-edit',
      title: t('actions.edit'),
      action: () => {
        dispatch(
          openModalPage({
            component: modalPages.ProductOrderEdit,
            props: { isMatrixProduct },
            groupID: 'PRODUCT_VIEW',
            modalClassName: 'product-edit-modal',
          }),
        );
      },
    },
  ];

  return (
    <Modal.Header
      style={{ padding: 14, fontSize: 24, justifyContent: 'flex-end' }}
    >
      {actions
        .filter(({ hide }) => !hide)
        .map(action => (
          <Icon
            style={{ paddingLeft: '12px', paddingRight: '12px' }}
            key={action.name}
            name={action.name}
            title={action.title}
            action={action.action}
            data-testid="action"
            data-test-key={action.title}
          />
        ))}
      <CloseButton
        action={() => {
          dispatch(closeModalPage());
        }}
      />
    </Modal.Header>
  );
};

export default ProductActions;
