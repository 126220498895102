import axios from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

export const defaultSwedbankUrl = 'https://localhost.erply.com:10104/api/v1/';

export const getSwedbankUrl = () => {
  const endpoint = getMSEndpointFromLS('swedbank');
  return endpoint ? `${endpoint}api/v1/` : defaultSwedbankUrl;
};

export default axios.create({
  baseURL: getSwedbankUrl(),
  timeout: 180000,
  headers: {
    Accept: '*',
    'Content-Type': 'application/json',
  },
});
