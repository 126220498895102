/* eslint-disable @typescript-eslint/camelcase */
import * as R from 'ramda';
import axios from 'axios';
import dayjs from 'dayjs';

import { getCustomers } from 'services/ErplyAPI';
import {
  getEndpoint,
  getInvoicePin,
} from 'plugins/oneAcrePergamonFiscal/configuration';
import { getProductsUniversal } from 'actions/productsDB';
import { round } from 'utils';
import {
  getRounding,
  getShoppingCartForSalesDocument,
  getTotal,
  getTotalNet,
  getTotalTax,
} from 'reducers/ShoppingCart';
import { getCurrentSalesDocument } from 'reducers/sales';

import { PergamonFiscalRequest, PergamonFiscalResponse } from '../../types';
import { authorization } from '../../constants';

import { compensateCartForRounding } from './utils';

const generatePayload = (saleDoc, customer, baseDoc) => async (
  dispatch,
  getState,
): Promise<PergamonFiscalRequest> => {
  const rows = getShoppingCartForSalesDocument(getState());
  const erplyToPergamonDate = R.pipe(R.split('-'), R.reverse, R.join('_'));
  const { productsDict } = await dispatch(
    getProductsUniversal({
      productIDs: rows.map(({ productID }) => productID),
    }),
  );

  const formatItem = ({ productID, amount, finalPriceWithVAT, rowTotal }) => {
    const hsCode = productsDict[productID]?.supplierCode ?? '';
    const itemName = productsDict[productID]?.name ?? '';
    const unitTotal = round(finalPriceWithVAT, 2);
    const total = round(rowTotal, 2);
    return `${hsCode} ${itemName} ${amount} ${unitTotal} ${total}`;
  };

  const { rows: adjustedRows, netTotal, vatTotal } = compensateCartForRounding(
    rows,
    getRounding(getState()),
    getTotalTax(getState()),
    getTotalNet(getState()),
  );

  const grandTotal = round(getTotal(getState()), 2);

  const isCreditInvoice = saleDoc.type === 'CREDITINVOICE';

  const payload = {
    invoice_date: erplyToPergamonDate(saleDoc.date),
    invoice_number: String(saleDoc.invoiceNo),
    invoice_pin: getInvoicePin(getState()),
    customer_pin: customer.attributes?.customer_pin ?? '',
    customer_exid: saleDoc.taxExemptCertificateNumber,
    grand_total: grandTotal ?? '',
    net_subtotal: netTotal ?? '',
    tax_total: vatTotal ?? '',
    net_discount_total: '0', // Not necessary because we bake the discounts into the items_list totals
    sel_currency: saleDoc.currencyCode,
    rel_doc_number:
      baseDoc?.jdoc?.['kenya-fiscal-brazil']?.cu_invoice_number ?? '',
    items_list: adjustedRows.map(formatItem),
  };
  return isCreditInvoice
    ? {
        ...payload,
        sel_device:
          baseDoc?.jdoc?.['kenya-fiscal-brazil']?.cu_serial_number ?? '',
      }
    : payload;
};

export const recordSaleInPergamonFiscal = (saleDoc, customerId) => async (
  dispatch,
  getState,
) => {
  const assertOne = res => {
    if (res.length > 1)
      throw new Error('Search by ID returned multiple records');
    if (res.length < 1) throw new Error('Search by ID returned no records');
    return res[0];
  };

  const customer = await getCustomers({ id: customerId }).then(assertOne);

  let baseDoc;
  if (saleDoc.type === 'CREDITINVOICE') {
    baseDoc = getCurrentSalesDocument(getState());
    if (!Object.keys(baseDoc).length) {
      throw new Error('Unreferenced returns are not supported');
    }
  }

  Object.assign(saleDoc, {
    date: dayjs().format('YYYY-MM-DD'),
    time: dayjs().format('HH:mm:ss'),
  });

  const data: PergamonFiscalRequest = await dispatch(
    generatePayload(saleDoc, customer, baseDoc),
  );

  const endpoint = getEndpoint(getState());
  const docType = {
    CREDITINVOICE: 'credit-note',
    CASHINVOICE: 'invoice',
  }[saleDoc.type];

  return axios
    .post<PergamonFiscalResponse>(`${endpoint}/api/sign?${docType}+1`, data, {
      headers: {
        Authorization: authorization,
      },
    })
    .then(response => ({ response: response.data, request: data }));
};
