import React, { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

import { ReasonCode } from 'types/ReasonCodes';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { setOneOrderSelected } from 'actions/returnProducts';
import { addWarning } from 'actions/Error';
import InputField from 'components/FieldTypes/InputField';
import { PluginComponent } from 'plugins';
import { ReturnRow } from 'types/ReturnTable';
import { REASONS } from 'constants/reasonCodesDB';
import { getProductCodeToShowOnReturnModal } from 'reducers/configs/settings';

import RowAmount from './RowAmount';

type OrderProductProps = {
  row: ReturnRow;
  globalReturnReason: string | undefined;
  isEven: boolean;
  depthLevel?: number;
  disabled?: boolean;
};

const OrderProduct: FC<OrderProductProps> = ({
  row,
  globalReturnReason,
  isEven,
  depthLevel = 1,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('return');
  const codeToShow = useSelector(getProductCodeToShowOnReturnModal);

  const isSelected = !!row?.selected;
  const returnReasonID = row?.returnReasonID;
  const returnReasons: ReasonCode[] = useSelector(
    getReasonCodes(REASONS.RETURN),
  );

  const reasonChange = (returnReasonID: string) => {
    dispatch(
      setOneOrderSelected(row.stableRowID, {
        ...row,
        returnReasonID,
      }),
    );
  };

  const toggleReturnRow = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (row.nonRefundable) {
        return dispatch(
          addWarning(
            t('alerts:nonRefundableProductError', {
              name: row.itemName,
            }),
          ),
        );
      }
      return dispatch(
        setOneOrderSelected(row.stableRowID, {
          ...row,
          selected: !row?.selected,
          // Deselect reason on deselect row
          returnReasonID: !row?.selected
            ? row?.returnReasonID ?? globalReturnReason
            : undefined,
          amount: row?.remainingAmount,
        }),
      );
    },
    [row, dispatch, globalReturnReason, t],
  );

  const returnReasonsOptions = useMemo(
    () =>
      returnReasons.map(reason => (
        <option
          data-testid="product-row-reason-option"
          data-test-key={reason.reasonID}
          key={reason.reasonID}
          value={reason.reasonID}
        >
          {reason.name}
        </option>
      )),
    [returnReasons],
  );

  const rowLocked = row.remainingAmount === 0 || disabled;
  const originalQuantity = row.originalAmount;

  useEffect(() => {
    // if a already returned row gets selected via selectAll, remove it
    if (row.remainingAmount === 0 && row?.selected) {
      dispatch(
        setOneOrderSelected(row.stableRowID, {
          ...row,
          selected: false,
          returnReasonID: undefined,
        }),
      );
    } else if (row.remainingAmount < row?.amount) {
      // If the selected amount exceeds the available amount, cap that
      dispatch(
        setOneOrderSelected(row.stableRowID, {
          ...row,
          amount: row.remainingAmount,
        }),
      );
    }
  }, [dispatch, row]);

  return (
    <PluginComponent
      hookname="UIReturnOrderProduct"
      props={{
        row,
        globalReturnReason,
        isSelected,
        isEven,
        depthLevel,
        rowLocked,
        returnReasonID,
        toggleReturnRow,
      }}
    >
      <>
        <tr
          data-testid="product-return-row"
          className={classNames('grouped', { rowLocked, isEven })}
        >
          <PluginComponent
            hookname="UISingleReturnRowCheckbox"
            props={{
              row,
              rowLocked,
              isSelected,
              globalReturnReason,
              toggleReturnRow,
            }}
          >
            <td className="align-middle">
              <InputField
                type="checkbox"
                disabled={row.nonRefundable}
                className={`${rowLocked && 'invisible'}`}
                value={isSelected}
                data-testid="product-row-toggle"
                onChange={toggleReturnRow}
              />
            </td>
          </PluginComponent>
          <td
            className="align-middle"
            data-testid="product-row-name"
            style={{
              paddingLeft: depthLevel ? `${depthLevel * 16}px` : '4px',
              fontWeight: depthLevel === 1 ? 700 : 'initial',
            }}
          >
            {row.itemName}
          </td>
          <td className="align-middle" data-testid="product-row-barcode">
            {row[codeToShow]?.split(',').join(', ')}
          </td>
          <td className="align-middle" data-testid="product-row-original-qty">
            {originalQuantity}
          </td>
          <td className="align-middle">
            <RowAmount row={row} />
          </td>
          {returnReasons.length > 0 ? (
            <PluginComponent
              hookname="UIReturnOrderProductSelectReason"
              props={{ reasonChange, row }}
            >
              <td className="align-middle">
                <Form.Control
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    reasonChange(e.target.value)
                  }
                  className={!isSelected ? 'isHidden' : ''}
                  as="select"
                  data-testid="product-row-return-reasons"
                  disabled={!isSelected}
                  value={row?.returnReasonID ?? 0}
                >
                  <option value={0}>{t('options.default')}</option>
                  {returnReasonsOptions}
                </Form.Control>
              </td>
            </PluginComponent>
          ) : null}
        </tr>
        {row.children?.length > 0
          ? row.children.map(ch => (
              <OrderProduct
                row={ch}
                isEven={isEven}
                depthLevel={depthLevel + 1}
                globalReturnReason={globalReturnReason}
              />
            ))
          : null}
      </>
    </PluginComponent>
  );
};

export default OrderProduct;
