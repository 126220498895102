import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import classNames from 'classnames';

import { PosPlugin } from 'plugins/plugin';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import InputField from 'components/FieldTypes/InputField';

import { setPurchaseOrderNumber } from '../rdx/actions';
import { getHipPocketState } from '../rdx/reducers';

/**
 * Add a 'purchase number' input field which live updates into the plugin's redux
 */
const UIAccountSalesForm: PosPlugin['UIAccountSalesForm'] = ({ children }) => {
  const dispatch = useDispatch();
  const { purchaseOrderNumber } = useSelector(getHipPocketState);

  return R.pipe(
    React.Children.toArray,
    R.modifyPath(
      [0, 'props', 'children'],
      R.pipe(
        R.append(
          <InputField
            size="lg"
            data-testid="input-field"
            data-test-key="purchaseOrder"
            title="Purchase order number"
            value={purchaseOrderNumber}
            name="purchaseOrder"
            type="text"
            onChange={e => dispatch(setPurchaseOrderNumber(e.target.value))}
            autoComplete="off"
            readOnly={false}
            className={classNames([styles.formInput, styles.longTitle])}
          />,
        ),
      ),
    ),
  )(children);
};

export default UIAccountSalesForm;
