import * as R from 'ramda';

import {
  InstallerInfo,
  MsCertInfo,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/types';
import {
  CHECK_MS_CERTS,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/CONFIGURATION';

// region types
type AutoUpdateState = 'pending' | 'in progress' | 'done-ok' | 'done-failed';
/** Installer response to /info request */
type InstallerInfoState = null | InstallerInfo;
/** Responses of various microservices to the /service/info request, containing SSL certificate information */
type CertInfoState = Record<string, MsCertInfo | Error>;
/** Combination of installer and cert info, the full status check performed before and after update */
type CheckState = {
  info: InstallerInfoState;
  certs: CertInfoState;
};
type LogState = any[][];
/** Debug object available to log/copy for later debugging */
type DebugState = {
  before: {
    info: InstallerInfoState;
    certs: CertInfoState;
  };
  after: {
    info: InstallerInfoState;
    certs: CertInfoState;
  };
  log: LogState;
};
// endregion
let store = {
  autoUpdateState: 'pending' as AutoUpdateState,
  checkResults: {
    before: null as null | CheckState,
    after: null as null | CheckState,
  },
  updateResults: null as null | Error | any,
  logs: [] as LogState[],
} as const;

const getset = <T>(lens: any) =>
  [
    () => R.view(lens, store) as T,
    (v: T) => {
      store = R.set(lens, v, store);
    },
  ] as const;

export const [getAutoUpdateState, setAutoUpdateState] = getset<
  typeof store.autoUpdateState
>(R.lensPath(['autoUpdateState']));
export const [getCheckResultsBefore, setCheckResultsBefore] = getset<
  typeof store.checkResults.before
>(R.lensPath(['checkResults', 'before']));
export const [getCheckResultsAfter, setCheckResultsAfter] = getset<
  typeof store.checkResults.after
>(R.lensPath(['checkResults', 'after']));
export const [getUpdateResults, setUpdateResults] = getset<
  typeof store.updateResults
>(R.lensPath(['updateResults']));
export const [getLogs, setLogs] = getset<typeof store.logs>(
  R.lensPath(['logs']),
);
// region selectors
type DebugInfo = {
  log: ReturnType<typeof getLogs>;
  before: ReturnType<typeof getCheckResultsBefore>;
  after: ReturnType<typeof getCheckResultsAfter>;
};

let getDebugInfoCache: DebugInfo;
/**
 * Summary, contains check results from both before and after, for sending logs for analysis
 */
export function getDebugInfo() {
  const log = getLogs();
  const before = getCheckResultsBefore();
  const after = getCheckResultsAfter();
  if (getDebugInfoCache) {
    if (getDebugInfoCache.log === log) {
      if (getDebugInfoCache.before === before) {
        if (getDebugInfoCache.after === after) {
          return getDebugInfoCache;
        }
      }
    }
  }
  getDebugInfoCache = {
    log,
    before,
    after,
  };
  return getDebugInfoCache;
}
/**
 * Returns a number from 0 to 1 to estimate the current autoinstall progress
 * NB: If the process cancels early, for example because no update is necessary, this value may remain <1
 * Check {@link autoUpdateStateAtom} to know if the process has finished and a progress bar should no longer be displayed
 */
export const getProgressTotal = () => {
  const getProgressFor = (cs: CheckState | null) => {
    const installer = cs?.info;
    const msData = cs?.certs;
    if (!installer) return 0;
    if (!msData) return 0;
    if (CHECK_MS_CERTS === false) return 1;
    const total = installer?.data.integrations.length ?? 0;
    const done = Object.values(msData).filter(a => a).length - 1;
    return (done + 1) / (total + 1);
  };
  const updateProgress = getUpdateResults() === null ? 0 : 1;
  const beforeProgress = getProgressFor(getCheckResultsBefore());
  const afterProgress = getProgressFor(getCheckResultsAfter());
  return 0.4 * beforeProgress + 0.2 * updateProgress + 0.4 * afterProgress;
};
// endregion

// region actions
export const [getBeforeInfo, setBeforeInfo] = getset<
  NonNullable<typeof store.checkResults.before>['info']
>(R.lensPath(['checkResults', 'before', 'info']));
export const [getAfterInfo, setAfterInfo] = getset<
  NonNullable<typeof store.checkResults.after>['info']
>(R.lensPath(['checkResults', 'after', 'info']));
export const [getBeforeCerts, setBeforeCerts] = getset<
  NonNullable<typeof store.checkResults.before>['certs']
>(R.lensPath(['checkResults', 'before', 'certs']));
export const [getAfterCerts, setAfterCerts] = getset<
  NonNullable<typeof store.checkResults.after>['certs']
>(R.lensPath(['checkResults', 'after', 'certs']));

export const setBeforeCert = (name: string, v: MsCertInfo | Error) =>
  setBeforeCerts({
    ...getBeforeCerts(),
    [name]: v,
  });
export const setAfterCert = (name: string, v: MsCertInfo | Error) =>
  setAfterCerts({
    ...getAfterCerts(),
    [name]: v,
  });

export const reset = () => {
  if (getAutoUpdateState() !== 'done-failed') {
    throw new Error('Can only retry out of failed state');
  }
  setAutoUpdateState('pending');
  setCheckResultsBefore({ info: null, certs: {} });
  setCheckResultsAfter({ info: null, certs: {} });
  setUpdateResults({});
};
// endregion

/**
 * Console-like object that logs to {@link logAtom} allowing the logs to be displayed on screen & included in the debug object
 */
export const Console = {
  /** Log a new line */
  log: (...params: any[]) => {
    setLogs([...getLogs(), params]);
  },
  /** Append to the current line */
  logPush: (...params: any[]) => {
    const prev = getLogs();
    setLogs([...prev.slice(0, -1), [...prev.slice(-1)[0], ...params]]);
  },
  /** Replace the current line (similar to \r in CLI environments) */
  logReplace: (...params: any[]) => {
    const prev = getLogs();
    setLogs([...prev.slice(0, -1), params]);
  },
};
