import {
  assoc,
  lensPath,
  map,
  mergeDeepLeft,
  mergeDeepRight,
  pipe,
  prop,
  set,
  sortBy,
  view,
} from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { FormGroup, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { InputAdornment, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { renameKeys } from 'ramda-adjunct';

import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';
import InputField from 'components/FieldTypes/InputField';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI2';
import {
  formInput,
  longTitle,
} from 'components/FieldTypes/skins/skins.module.scss';
import COUNTRIES from 'plugins/victorinoxCustomizations/data/countries.json';
import { requestCompleteResponse } from 'services/ErplyAPI/core/ErplyAPI';

import Configuration from './Config';

const GENDERS = [
  { value: 'M', name: 'M' },
  { value: 'F', name: 'F' },
  { value: 'DIVERS', name: 'DIVERS' },
];
const LANGUAGES = [
  { value: 'ger', name: 'German' },
  { value: 'eng', name: 'English' },
  { value: 'fre', name: 'French' },
  { value: 'ita', name: 'Italian' },
  { value: 'chi', name: 'Chinese' },
  // { value: 'jpn', name: 'Japanese' },
  // { value: 'spa', name: 'Spanish' },
];

/**
 * Create a custom hook that will fetch data from erply asyncronously
 * The hook will take a default value as input, and will return that until the records are all loaded
 *
 * @param requestName Name of the ErplyAPI request to fetch the data from
 * @param transformer A function to transform ErplyAPI records into the desired format
 * For us, the desired format is usually as name/value pairs for <select> inputs
 */
const createUseX = (requestName, transformer) => {
  const useX = defaultValue => {
    const [value, setValue] = useState<typeof defaultValue>([defaultValue]);
    useEffect(() => {
      requestCompleteResponse({
        request: requestName,
        recordsOnPage: 1,
      })
        .then(({ status: { recordsTotal } }) =>
          Promise.all(
            Array(Math.ceil(recordsTotal / 100))
              .fill(0)
              .map((z, i) => i + 1)
              .map(pageNo =>
                doClientRequest({
                  request: requestName,
                  recordsOnPage: 100,
                  pageNo,
                }),
              ),
          ),
        )
        .then(res => res.flatMap(a => a.records))
        .then(transformer)
        .then(res => setValue(res));
    }, []);
    return value;
  };
  return useX;
};

const useCountryOptions = createUseX(
  'getCountries',
  pipe(
    map(
      renameKeys({
        countryID: 'value',
        countryName: 'name',
        countryCode: 'code',
      }),
    ),
    // @ts-ignore
    sortBy(prop('name')),
  ),
);

const usePersonTitleOptions = createUseX(
  'getPersonTitles',
  map(renameKeys({ id: 'value', name: 'name' })),
);

const isValidAddress = v => v && Object.values(v).join('').length > 0;

const pluginID = 'customer: vx';
export type ConfType = {
  homeStores: { [id: string]: string };
};
const defaultConf: ConfType = {
  homeStores: {},
};
const getHomestoreOptions = state =>
  Object.entries(
    getPluginConfiguration<ConfType>(pluginID)(state).homeStores,
  ).map(([value, name]) => ({ name, value }));

const victorinoxCustomizationsPlugin: PosPlugin<ConfType> = {
  id: pluginID,
  name: 'vx customizations',
  keywords: ['customer', 'vx'],
  ComponentConfigurationByLevel: {
    Company: Configuration,
  },

  combineConfiguration: c => mergeDeepLeft(c ?? {}, defaultConf),

  UICustomerForm: ({ value: customer, onChange, children }) => {
    const titleOptions = usePersonTitleOptions({
      name: 'loading...',
      value: customer.personTitleID,
    });

    const isValid =
      {
        PERSON:
          !!customer.personTitleID &&
          !!customer.firstName &&
          !!customer.lastName,
        COMPANY: !!customer.companyName,
      }[customer.customerType] &&
      !!customer.attributes?.['vx-language'] &&
      !!customer.countryID &&
      !!customer.homeStoreID &&
      !!(!!customer.email || customer.addresses?.some(isValidAddress));

    useEffect(() => {
      if (!isValid !== customer.invalid) {
        onChange(assoc('invalid', !isValid, customer));
      }
    }, [customer, isValid]);

    // When country changes, clear phone input because the old number
    // is not valid with the new area code
    const [lastCountryID, setLastCountryID] = useState(customer.countryID);
    useEffect(() => {
      if (lastCountryID !== customer.countryID) {
        onChange(assoc('phone', '', customer));
        setLastCountryID(customer.countryID);
      }
    }, [customer.countryID, lastCountryID]);

    const newField = (
      title,
      path,
      { options, required } = {} as Partial<{
        options: any;
        required: boolean;
      }>,
    ) => {
      const valueLens = lensPath(path);
      return (
        <FormGroup>
          <InputField
            title={title}
            className={classNames(formInput, longTitle)}
            value={view(valueLens, customer) ?? ''}
            onChange={e =>
              onChange(
                set(
                  valueLens,
                  e.target.value === '' ? undefined : e.target.value,
                ),
              )
            }
            type={options ? 'select' : 'text'}
            size="lg"
            options={[{ value: '', name: '' }].concat(options)}
            errorText={
              !required || view(valueLens, customer)
                ? undefined
                : 'This field can not be blank!'
            }
          />
        </FormGroup>
      );
    };
    const existingField = (fieldName, { required, validate } = {} as any) => {
      const field = React.Children.toArray(children).find((c: any) =>
        fieldName.test(c.key),
      );
      if (!field) {
        return null;
      }
      if (required) {
        return mergeDeepRight(field as any, {
          props: {
            field: {
              validate: v => (v ? undefined : 'This field can not be blank'),
            },
          },
        });
      }
      if (validate) {
        return mergeDeepRight(field as any, {
          props: { field: { validate } },
        });
      }
      return field;
    };

    const countryOptions = useCountryOptions({
      name: 'loading...',
      value: customer.countryID,
      code: '',
    });

    const selectedCountry = countryOptions.find(
      opt => String(opt.value) === String(customer.countryID),
    );

    const areaCode = useMemo(() => {
      const code = selectedCountry?.code;
      if (!code) return '+???';
      const areaCode = COUNTRIES[code?.toUpperCase()];
      return `+${areaCode ?? '???'}`;
    }, [selectedCountry]);

    return (
      <Modal.Body>
        {customer.customerType === 'PERSON' ? (
          <>
            {newField('Title', ['personTitleID'], {
              options: titleOptions,
              required: true,
            })}
            {newField('Gender', ['attributes', 'vx-gender'], {
              options: GENDERS,
            })}
            {existingField(/\$firstName/, { required: true })}
            {existingField(/\$lastName/, { required: true })}
          </>
        ) : (
          existingField(/\$companyName/, { required: true })
        )}
        {newField('Language', ['attributes', 'vx-language'], {
          options: LANGUAGES,
          required: true,
        })}
        {existingField(/\$group/, { required: true })}
        {newField('Country', ['countryID'], {
          options: countryOptions,
          required: true,
        })}
        {existingField(/\$homeStoreID/, { required: true })}
        {existingField(/\$email/, {
          validate: v =>
            v || customer.addresses?.some(isValidAddress)
              ? undefined
              : 'Must specify either an address or an email',
        })}
        {existingField(/\$emailOptOut/)}

        <TextField
          margin="normal"
          variant="outlined"
          label="Phone"
          fullWidth
          placeholder="418181211"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{areaCode}</InputAdornment>
            ),
          }}
          value={customer.phone}
          onChange={e => onChange(assoc('phone', e.target.value, customer))}
        />

        {existingField(/\$mobile/)}
        {existingField(/\$birthday/)}
        {existingField(/\$addresses/)}
        {existingField(/\$notes/)}
      </Modal.Body>
    );
  },

  getTranslationOverrides: createSelector(
    state => null,
    () => ({
      addressForm: {
        placeholders: {
          state: 'State',
        },
      },
    }),
  ),
};
export default victorinoxCustomizationsPlugin;
