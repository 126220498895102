import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { getPayments } from 'services/ErplyAPI/sales';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { RootState } from 'reducers';
import { addWarning } from 'actions/Error';
import { PosPlugin, ActionLifecycleHooks } from 'plugins/plugin';
import { ErplyApiError } from 'services/ErplyAPI/core/apiErrors';

import { getCFRState } from './rdx';
import { CFRSalesDocument } from './types';
import getFranchiseSalesDocuments from './requests';

type FallbackDocuments = Required<
  PosPlugin
>['onFetchSalesDocsForReturn'] extends ActionLifecycleHooks<
  any,
  infer ActionParams,
  any
>
  ? ActionParams
  : never;

export async function getAreAllPaymentsOnlineByDocumentID(documentID: string) {
  const payments = await getPayments({
    documentID,
  });

  const documentHasPayments = payments.length > 0;

  const onlyOnlinePayments: boolean = payments.every(
    payment => payment.type === 'ONLINE',
  );

  return documentHasPayments && onlyOnlinePayments;
}

const handleFulfilledDocument = (
  followUpDocuments: SaleDocumentResponse['followUpDocuments'],
): ThunkAction<Promise<void>, RootState, unknown, Action> => async dispatch => {
  const invoiceNrs = followUpDocuments
    .map(document => document.number)
    .join(',');

  const message = invoiceNrs
    ? `Enter invoice(s) ${invoiceNrs} to return this order`
    : 'Order must be picked up to return';

  dispatch(addWarning(message, { selfDismiss: false, dismissible: true }));

  throw new Error(message);
};

export const checkIfValidReturn = ({
  invoiceState,
  followUpDocuments,
  id,
}: SaleDocumentResponse): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  Action
> => async (dispatch, getState) => {
  if (invoiceState === 'FULFILLED') {
    await dispatch(handleFulfilledDocument(followUpDocuments));
    return;
  }

  const areAllPaymentsOnline = await getAreAllPaymentsOnlineByDocumentID(
    String(id),
  );

  const { isOnlineReturn } = getCFRState(getState());

  if (!areAllPaymentsOnline && isOnlineReturn) {
    const message = 'Please use regular return for this document';
    dispatch(addWarning(message));
    throw new Error(message);
  }

  if (!isOnlineReturn && areAllPaymentsOnline) {
    const message = 'Please use online return for this document';
    dispatch(addWarning(message));
    throw new Error(message);
  }
};

export function hasOnlyOnlinePayments(document: CFRSalesDocument) {
  return (
    document.payments.length > 0 &&
    document.payments.every(payment => payment.type === 'ONLINE')
  );
}

export async function getSalesDocuments(documentNumber: string) {
  const results = await Promise.allSettled([
    getFranchiseSalesDocuments({
      nonReturnedItemsOnly: 0,
      types: 'INVWAYBILL,CASHINVOICE,EXPORTINVOICE,INVOICE',
      number: documentNumber,
    }),
    getFranchiseSalesDocuments({
      nonReturnedItemsOnly: 1,
      types: 'INVWAYBILL,CASHINVOICE,EXPORTINVOICE,INVOICE',
      number: documentNumber,
    }),
  ]);

  const isRejected = <T>(
    p: PromiseSettledResult<T>,
  ): p is PromiseRejectedResult => p.status === 'rejected';

  if (results.some(isRejected)) {
    throw new Error('getFranchiseSalesDocuments request failed');
  }

  const fulfilledResults = (results as unknown) as [
    PromiseFulfilledResult<CFRSalesDocument[]>,
    PromiseFulfilledResult<CFRSalesDocument[]>,
  ];

  return {
    franchiseDocumentsWithAllRows: fulfilledResults[0].value,
    franchiseDocumentsWithNonReturnedRows: fulfilledResults[1].value,
  };
}

export const checkFranchiseDocuments = (
  documentNumber: string,
  fallbackDocuments: FallbackDocuments,
): ThunkAction<
  Promise<FallbackDocuments | undefined>,
  RootState,
  unknown,
  Action
> => async dispatch => {
  try {
    const {
      franchiseDocumentsWithAllRows,
      franchiseDocumentsWithNonReturnedRows,
    } = await getSalesDocuments(documentNumber);

    if (franchiseDocumentsWithAllRows.length === 0) {
      return fallbackDocuments;
    }

    const onlineFranchiseDocuments = franchiseDocumentsWithAllRows.filter(
      hasOnlyOnlinePayments,
    );

    if (onlineFranchiseDocuments.length === 0) {
      if (
        franchiseDocumentsWithAllRows.length > 0 &&
        fallbackDocuments.payload.length > 0
      ) {
        const message = 'Please use regular return for this document';
        throw new Error(message);
      }
      return fallbackDocuments;
    }

    const allDocumentsFullyReturned = franchiseDocumentsWithNonReturnedRows.every(
      ({ rows }) => rows.length === 0,
    );

    // all documents have been returned case
    if (allDocumentsFullyReturned) {
      const message = 'All documents have been fully returned';
      throw new Error(message);
    }

    return {
      payload: onlineFranchiseDocuments.map(doc => {
        const documentWithNonReturnedRows = franchiseDocumentsWithNonReturnedRows.find(
          d => d.id === doc.id,
        );
        if (!documentWithNonReturnedRows) {
          return {
            ...doc,
            paid: doc.total,
          };
        }

        return {
          ...doc,
          paid: doc.total,
          // filter out rows that are already returned
          // and rows that include freight in item name
          rows: doc.rows.filter(row => {
            const returnedRow = documentWithNonReturnedRows.rows.find(
              r => r.stableRowID === row.stableRowID,
            );
            if (!returnedRow) {
              return row.itemName.toUpperCase().includes('FREIGHT');
            }
            return (
              Number(returnedRow.amount) - Number(row.amount) >= 0 ||
              row.itemName.toUpperCase().includes('FREIGHT')
            );
          }),
        };
      }),
      returnedRows: [],
    } as any;
  } catch (error) {
    const isErplyError = error instanceof ErplyApiError;
    if (isErplyError && error.errorCode === 1067) {
      return fallbackDocuments;
    }
    dispatch(
      addWarning(
        error instanceof Error
          ? error.message
          : 'Request to get documents failed',
      ),
    );
  }
};
