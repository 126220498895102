/* eslint-disable max-classes-per-file */
import { AxiosError } from 'axios';

import { CayanTransactionData } from './types';

export enum ErrorTypes {
  BADSTATUS = 'BADSTATUS',
  BUSY = 'BUSY',
  FAULT = 'FAULT',
  TIMEOUT = 'TIMEOUT',
  UNKNOWN = 'UNKNOWN',
  UNKNOWN_CANCEL = 'CANCEL_UNKNOWN',
}
export class PaymentSuccess {
  data: {
    // key: To be added manually by caller
    type: 'CARD';
    amount: string;
    cardHolder: CayanTransactionData['cardHolder'];
    cardType: CayanTransactionData['paymentType'];
    cardNumber: CayanTransactionData['cardNumber'];
    attributes: {
      refNo: string;
      authCode: string;
    };
    aid: CayanTransactionData['aid'];
    applicationLabel: CayanTransactionData['applicationLabel'];
    entrymethod: CayanTransactionData['entryMode'];
    transcationType: CayanTransactionData['transactionType'];
    pinStatement: CayanTransactionData['pinStatement'];
    cryptogramType: CayanTransactionData['cryptogramType'];
    cryptogram: CayanTransactionData['cryptogram'];
    expirationDate: CayanTransactionData['expirationDate'];
    paid: true;
    tipAmount: string;
    cardToken?: string;
    customerID?: number;
  };

  constructor(data) {
    this.data = data;
  }
}
export class PaymentIndeterminate {
  reason: ErrorTypes;

  message: string;

  refno?: string;

  constructor(reason: ErrorTypes, message: string, refno?: string) {
    this.reason = reason;
    this.message = message;
    this.refno = refno;
    console.info(this);
  }

  toString() {
    if (this.message === 'Invalid Transport Key') {
      this.message = 'Card not yet presented';
    }
    return `[${this.reason}]: ${this.message}`;
  }
}
export class PaymentFailure {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

const parseCancelResponse = (record: {
  statusMessage: string;
  resultStatus: string;
}) => {
  if (record.statusMessage === 'Cannot Cancel In Idle Screen') {
    return new PaymentFailure('Cannot cancel in idle screen');
  }
  if (record.resultStatus === 'Cancelled') {
    return new PaymentFailure('Cancelled');
  }
  return new PaymentIndeterminate(
    ErrorTypes.UNKNOWN_CANCEL,
    record.resultStatus,
  );
};

const parsePaymentObjectFromResponse = (record: CayanTransactionData) => {
  const sign = record.transactionType?.toUpperCase() === 'SALE' ? '' : '-';

  // Status sometimes given as DECLINED;2005;transaction type not allowed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transStatus, code, message] = record.transactionStatus.split(';');
  switch (transStatus) {
    case 'APPROVED':
      return new PaymentSuccess({
        // key: To be added manually by caller
        type: 'CARD',
        amount: `${sign}${record.approvedAmount}`,
        cardHolder: record.cardHolder,
        cardType: record.paymentType,
        cardNumber: record.cardNumber.slice(-4),
        attributes: {
          refNo: `${record.authCode}.${record.referenceNumber}`,
          authCode: record.authCode,
        },
        tipAmount: record.additionalData?.amountDetails?.userTipAmount,
        aid: record.aid,
        applicationLabel: record.applicationLabel,
        entrymethod: record.entryMode,
        transcationType: record.transactionType,
        pinStatement: record.pinStatement,
        cryptogramType: record.cryptogramType,
        cryptogram: record.cryptogram,
        expirationDate: record.expirationDate,
        paid: true,
        cardToken: record?.additionalData?.vaultToken,
      });
    case 'POSCancelled':
      return new PaymentFailure('Cancelled successfully');
    case 'UserCancelled':
      return new PaymentFailure('Cancelled by user');
    case 'DECLINED':
      return new PaymentFailure('Transaction declined');
    case 'DECLINED_DUPLICATE':
    case 'DECLINED,DUPLICATE':
      return new PaymentFailure('Transaction declined (Duplicate)');
    case 'FAULT':
      return new PaymentIndeterminate(ErrorTypes.FAULT, record.statusMessage);
    case undefined:
      return new PaymentIndeterminate(
        ErrorTypes.BADSTATUS,
        record.statusMessage,
      );
    default: {
      // if (
      //   record.statusMessage ===
      //   'Invalid Request:Terminal Busy; Invalid Transport Key while requesting details of transaction'
      // ) {
      //   return new PaymentIndeterminate(ErrorTypes.BUSY, record.statusMessage);
      // }
      return new PaymentIndeterminate(ErrorTypes.UNKNOWN, record.statusMessage);
    }
  }
};

const throwErrorFromAxiosError = (e: AxiosError): PaymentIndeterminate => {
  // If a network error happens, save the transport key if present and convert to an internal error code for easy switching later
  let refno: string | null = null;
  try {
    refno = JSON.parse(e.response?.data.details).hostReferenceNumber;
  } catch (e) {
    // pass
  }
  if (refno) {
    return new PaymentIndeterminate(ErrorTypes.TIMEOUT, e.message, refno);
  }
  return new PaymentIndeterminate(ErrorTypes.UNKNOWN, e.message);
};

export const doPaymentRequest = (promise: Promise<any>) =>
  promise.then(res => {
    if (res instanceof PaymentFailure) return res;
    if (res instanceof PaymentIndeterminate) return res;
    if (res instanceof PaymentSuccess) return res;
    return parsePaymentObjectFromResponse(res.data.records?.[0]);
  }, throwErrorFromAxiosError);

export const doCancelRequest = (promise: Promise<any>) =>
  promise.then(
    res => parseCancelResponse(res.data.records?.[0]),
    throwErrorFromAxiosError,
  );
