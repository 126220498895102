import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { RootState } from 'reducers';

import { Entity } from './types';

const singlePrompt = (
  componentName: 'TafCustomerSearchModal',
  props?: {
    type: 'schoolEntity' | 'medicalEntity';
    selected: Entity['entityID'];
  },
) => async dispatch => {
  return dispatch(
    openModalPage({
      component: mp.pluginModal,
      props: {
        name: componentName,
        props,
      },
      isPopup: true,
      modalClassName:
        componentName === 'TafCustomerSearchModal'
          ? 'customer-search-input'
          : undefined,
    }),
  );
};

export const couponsPrompt = () => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
) => {
  return dispatch(
    openModalPage({
      component: mp.pluginModal,
      props: {
        name: 'TafCouponsPoints',
        closeModal: () => {
          dispatch(previousModalPage());
        },
        onCancel: () => {
          dispatch(previousModalPage());
        },
      },
      isPopup: true,
    }),
  );
};

export const contactUpdateFormPrompt = customer => async dispatch => {
  return dispatch(
    openModalPage({
      component: mp.pluginModal,
      props: {
        name: 'TafContactUpdateForm',
        closeModal: () => {
          dispatch(previousModalPage());
        },
        customer,
      },
      isPopup: true,
    }),
  );
};

export default singlePrompt;

type PartnerInfo = {
  selectedCustomerName: string;
  poNumber: string;
  partnerName: string;
};

const partnerNotesRegex = /^(.*) \((.+)\) \| PO# (.+)\r?$/;

export function setPartnerInfoInNotes(notes = '', partnerInfo: PartnerInfo) {
  const partnerNote = `${partnerInfo.partnerName} (${partnerInfo.selectedCustomerName}) | PO# ${partnerInfo.poNumber}`;
  if (!notes) return partnerNote;

  const lines = notes.split('\n');
  const matchingLineIndex = lines.findIndex(line =>
    partnerNotesRegex.test(line),
  );
  if (matchingLineIndex !== -1) {
    lines[matchingLineIndex] = partnerNote;
    return lines.join('\n');
  }
  return [...lines, partnerNote].join('\n');
}

export function removePartnerInfoFromNotes(notes = ''): string {
  const lines = notes.split('\n');
  return lines.filter(line => !partnerNotesRegex.test(line)).join('\n');
}

export function getPartnerInfoFromNotes(notes = ''): PartnerInfo | null {
  const lines = notes.split('\n');
  const matchingLine = lines.find(line => partnerNotesRegex.test(line));
  const match = matchingLine?.match(partnerNotesRegex);
  if (!match) return null;
  const [, partnerName, selectedCustomerName, poNumber] = match;
  return { partnerName, selectedCustomerName, poNumber };
}

export function copyPartnerInfoToNotes(oldNotes = '', newNotes = ''): string {
  const existingInfo = getPartnerInfoFromNotes(oldNotes);
  if (!existingInfo) return newNotes;
  return setPartnerInfoInNotes(newNotes, existingInfo);
}
