/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { RecentSalesList } from 'containers/Forms/RecentSalesList';
import { PluginComponent } from 'plugins';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getSelectedWarehouseID } from 'reducers/warehouses';

import './RecentSales.sass';

const RecentSales = () => {
  const { t } = useTranslation('sale');
  const dispatch = useDispatch();
  // Value in field
  const [value, setValue] = useState(null);
  // Value used for search (delayed)
  const [submittedValue, setSubmittedValue] = useState(null);
  const activeCustomer = useSelector(getSelectedCustomer);
  const currentWarehouseID = useSelector(getSelectedWarehouseID);

  useEffect(() => {
    if (value?.replace(/-/g, '') !== null) {
      const i = setTimeout(() => {
        setSubmittedValue(value);
      }, 1000);
      return () => clearTimeout(i);
    }
    return false;
  }, [value]);

  const handleChange = event => {
    const { value } = event.target;
    setValue(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmittedValue(value);
  };

  const close = () => {
    dispatch(closeModalPage());
  };

  const queryProps = useMemo(
    () => ({
      number: submittedValue || undefined,
      morePages: true,
      showFilter: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      search_invoice_by_regular_and_custom_number: 1,
      // when getSalesDocument sent with this attribute, will return list of issuedCouponIDs
      presentCoupons: 1,
    }),
    [submittedValue],
  );

  return (
    <div className="recent-sales-data" data-testid="recent-sales">
      <Modal.Header
        style={{
          display: 'flex',
        }}
      >
        <Modal.Title data-testid="recent-sales-title">
          {t('recentSales.title')}
        </Modal.Title>
        <CloseButton action={close} />
      </Modal.Header>
      <div className="recent-sales-search">
        <Form onSubmit={handleSubmit}>
          <InputGroup size="lg">
            <FormControl
              aria-label="Sales number"
              placeholder={t('recentSales.fields.number', {
                context: 'placeholder',
              })}
              type="text"
              data-testid="search-number"
              value={value || ''}
              onChange={handleChange}
            />
            <InputGroup.Append>
              <InputGroup.Text
                className="search-button"
                // sale.number.replace(/-/g, '') is more of a temp solution
                // to pass down the pnp invoice number correctly
                onClick={() => setSubmittedValue(value?.replace(/-/g, ''))}
              >
                <i className="flip-horizontal  icon_search" />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      </div>
      <Modal.Body>
        <PluginComponent
          hookname="UIRecentSalesPreviousPurchasesTable"
          props={{
            warehouseNumber: currentWarehouseID,
            customer: activeCustomer,
            ...queryProps,
          }}
        >
          <RecentSalesList
            queryProps={{
              showCustomer: true,
              warehouseNumber: currentWarehouseID,
              ...queryProps,
            }}
          />
        </PluginComponent>
      </Modal.Body>
    </div>
  );
};

export default RecentSales;
