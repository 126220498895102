import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import {
  getCustomPaymentTypes,
  getEnabledCustomPaymentsSetting,
} from 'reducers/PaymentTypes';
import { getPaymentTypesAction } from 'actions/PaymentTypes';
import { saveSetting } from 'actions/configs';
import { addError, addWarning } from 'actions/Error';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { Integer } from 'types/APITypes';
import { RootState } from 'reducers';

import { generateCustomPaymentType } from '../utils';
import { PluginConfiguration } from '../types';

const ComponentConfiguration: Required<
  PosPlugin<PluginConfiguration>
>['ComponentConfigurationByLevel']['Company'] = ({ current = {}, save }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const [customPaymentTypeID, setCustomPaymentID] = useState(
    current?.customPaymentTypeID ?? '0',
  );

  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState({
    paymentType: false,
    domain: false,
  });
  const [newCustomPaymentName, setCustomPaymentName] = useState('');
  const [domain, setDomain] = useState(current?.domain);

  const enabledCustomPaymentTypes = useSelector<
    RootState,
    Record<string, 0 | 1>
  >(getEnabledCustomPaymentsSetting);

  function updateDomain(e) {
    setDomain(e.target.value);
    setDirty(R.assoc('domain'));
  }

  const customPaymentTypes = useSelector(getCustomPaymentTypes);

  function changeCustomPaymentType(e) {
    // if the user selects existing payment integration, reset the new custom payment generation progress
    if (Number(e.target.value)) {
      setCustomPaymentName('');
    }
    setCustomPaymentID(e.target.value);
  }

  function handleCustomPaymentNameChange(e) {
    const { value } = e.target;
    const name = generateCustomPaymentType(value);
    setCustomPaymentName(name);
    setDirty(R.assoc('paymentType'));
  }

  async function createNewCustomPaymentType() {
    setLoading(true);
    try {
      const paymentTypeID = await doClientRequest<{ paymentTypeID: Integer }>({
        request: 'savePaymentType',
        type: newCustomPaymentName,
        name: newCustomPaymentName,
        // eslint-disable-next-line @typescript-eslint/camelcase
        print_name: newCustomPaymentName,
      }).then(data => data?.[0]?.paymentTypeID);

      await dispatch(getPaymentTypesAction());
      setCustomPaymentID(paymentTypeID);
      setCustomPaymentName('');
    } catch (err) {
      dispatch(addError('Failed to create new payment type'));
    } finally {
      setLoading(false);
    }
  }

  function saveConfiguration() {
    if (!Number(customPaymentTypeID)) {
      dispatch(
        addWarning(`Missing required fields! Please, add Custom Payment Type.`),
      );
      return;
    }

    if (!domain) {
      dispatch(addWarning(`Missing required fields! Please, add Domain.`));
      return;
    }

    // if we had a previously selected custom payment integration for MoMo,
    // and we are about to save a new one,
    // remove the current one from 'enabled_custom_payments' - this would hide its custom payment button from Payment Modal
    const previousCustomPaymentType = customPaymentTypes.find(
      ({ id }) => id === current?.customPaymentTypeID,
    )?.type;
    if (
      Number(current?.customPaymentTypeID) !== Number(customPaymentTypeID) &&
      previousCustomPaymentType &&
      enabledCustomPaymentTypes[previousCustomPaymentType]
    ) {
      dispatch(
        saveSetting({
          parameterName: 'enabled_custom_payments',
          parameterValue: JSON.stringify(
            R.dissoc(previousCustomPaymentType)(enabledCustomPaymentTypes),
          ),
        }),
      );
    }

    // save the updated configuration
    save({
      customPaymentTypeID,
      domain,
    });
  }

  const validationErr = useMemo(() => {
    const err = {
      paymentType: '',
      domain: '',
    };
    // Domain validation
    if (!domain) err.domain = 'Domain cannot be empty!';
    // Custom Payment Name validation
    if (!newCustomPaymentName) err.paymentType = 'Payment Type cannot be empty';
    else if (
      customPaymentTypes.some(({ type }) => type === newCustomPaymentName)
    )
      err.paymentType = 'Payment Type already exists!';

    return err;
  }, [domain, newCustomPaymentName]);

  const disabledSaveButton =
    domain === current?.domain &&
    Number(customPaymentTypeID) === Number(current?.customPaymentTypeID);

  return (
    <Grid container spacing={2} style={{ marginTop: '1em' }}>
      <Grid xs={12} item container justifyContent="space-between">
        <Typography variant="h5">Configure Custom Payment Type</Typography>
        <Button
          color="primary"
          variant="outlined"
          onClick={saveConfiguration}
          disabled={disabledSaveButton}
        >
          Save
        </Button>
      </Grid>
      <Grid xs={12} item>
        <Typography>
          This plugin needs a custom payment type to be associated with the
          external Payment Processor.
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <Typography>
          You can either select an existing Custom Payment Type.
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <TextField
          fullWidth
          select
          disabled={loading}
          variant="outlined"
          value={customPaymentTypeID}
          onChange={changeCustomPaymentType}
        >
          <MenuItem value="0">Select Payment Type</MenuItem>
          {customPaymentTypes.map(cp => (
            <MenuItem key={cp.id} value={cp.id}>
              {cp.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid xs={12} item>
        <Typography>Or you could generate a new one.</Typography>
      </Grid>
      <Grid xs={10} item>
        <TextField
          fullWidth
          variant="outlined"
          label="New Custom Payment Name"
          disabled={loading || !!Number(customPaymentTypeID)}
          value={newCustomPaymentName}
          onChange={handleCustomPaymentNameChange}
          error={
            dirty.paymentType &&
            !Number(customPaymentTypeID) &&
            !!validationErr.paymentType
          }
          helperText={
            dirty.paymentType &&
            !Number(customPaymentTypeID) &&
            validationErr.paymentType
          }
        />
      </Grid>
      <Grid xs={2} item container>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          disabled={Boolean(
            loading || Number(customPaymentTypeID) || validationErr.paymentType,
          )}
          onClick={createNewCustomPaymentType}
        >
          Generate
        </Button>
      </Grid>
      <Grid xs={12} item>
        <TextField
          fullWidth
          variant="outlined"
          value={domain}
          onChange={updateDomain}
          label="External Integration Domain"
          error={dirty.domain && !!validationErr.domain}
          helperText={dirty.domain && validationErr.domain}
        />
      </Grid>
    </Grid>
  );
};

export default ComponentConfiguration;
