import { createSelector } from 'reselect';

import { RootState } from 'reducers';
import { getSetting, getUISetting } from 'reducers/configs/settings';
import { Theme } from 'types/Theme';
import { SET_COLOR_THEME } from 'constants/UI';

type State = { theme: Theme | null; persist: boolean };
type Action = { type: typeof SET_COLOR_THEME; payload: State };

function isValidTheme(theme): theme is Theme {
  return ['light', 'dark', 'highContrast'].includes(theme);
}

const COLOR_THEME = 'colorTheme';

const localStorageTheme = localStorage.getItem(COLOR_THEME);

const initialState: State = {
  theme: isValidTheme(localStorageTheme) ? localStorageTheme : null,
  persist: isValidTheme(localStorageTheme),
};
export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case SET_COLOR_THEME:
      if (payload.persist) {
        payload.theme
          ? localStorage.setItem(COLOR_THEME, payload.theme)
          : localStorage.removeItem(COLOR_THEME);
      }
      return payload;
    default:
      return state;
  }
};

export function getThemeSlice(state: RootState) {
  return state.UI.theme;
}

export function getDeviceColorTheme(state: RootState) {
  return state.UI.theme.theme;
}

export const getActiveColorTheme = createSelector(
  getThemeSlice,
  getUISetting('color_theme'),
  getSetting('touchpos_use_dark_theme'),
  ({ theme: deviceTheme }, themeSetting, legacyThemeSetting): Theme => {
    const legacyTheme = legacyThemeSetting ? 'dark' : 'light';
    return deviceTheme ?? themeSetting ?? legacyTheme;
  },
);

export function getIsThemeSet() {
  return !!localStorage.getItem(COLOR_THEME);
}
