import React, { KeyboardEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Customer } from 'types/Customer';
import { PosPlugin } from 'plugins/plugin';

import { getWbuConfiguration } from '../configuration';

const UICustomerSearch: PosPlugin['UICustomerSearch'] = ({
  searchChangeAllowed,
  setSearchChangeAllowed,
  isReady,
  cancel,
  addCustomer,
  doSearch,
  customerPromise,
  searchValue,
  children,
}) => {
  const { noSelectOnEnter } = useSelector(getWbuConfiguration);

  const selectIfSingleResult = useCallback(
    (customers: Customer[]) => {
      if (!noSelectOnEnter && customers.length === 1) {
        addCustomer(customers[0]);
        setSearchChangeAllowed(false);
      }
    },
    [addCustomer, setSearchChangeAllowed],
  );

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchChangeAllowed) {
      if (isReady() === false) {
        cancel();
        doSearch(searchValue).then(selectIfSingleResult);
      } else {
        customerPromise.then(selectIfSingleResult);
      }
    }
  };

  return React.Children.map(children, child =>
    React.isValidElement(child)
      ? React.cloneElement(child, { onKeyDown })
      : child,
  );
};

export default UICustomerSearch;
