import { useState } from 'react';

export const useCheckboxToggle = (key: string) => {
  const [value, setValue] = useState<string | boolean | null>(
    localStorage.getItem(key),
  );
  const toggle = () => {
    if (value) {
      setValue(false);
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, 'true');
      setValue(true);
    }
  };
  return [localStorage.getItem(key), toggle];
};
