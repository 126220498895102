import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  getCurrencySymbol,
  getSetting,
  getShouldShowCurrentDocumentTypeAboveCart,
  getUISetting,
} from 'reducers/configs/settings';
import { useBreakpoints } from 'utils/hooks/UI';
import { getLastOrder } from 'reducers/ShoppingCart';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import {
  getCurrentSalesDocument,
  getIsCurrentSaleAReturn,
} from 'reducers/sales';

import './tableBill.scss';

import TableBillRow from './TableRow';
import TableBillHeader from './TableBillHeader';

/**
 * Current document indicator
 */
const DocumentTypeInfo = () => {
  const document = useSelector(getCurrentSalesDocument);
  const isReturn = useSelector(getIsCurrentSaleAReturn);
  const showDocumentTypeAboveCart = useSelector(
    getShouldShowCurrentDocumentTypeAboveCart,
  );
  const { t } = useTranslation('billTable');

  return showDocumentTypeAboveCart ? (
    <div className="document-type">
      {t('headers.document', {
        type: document.type ?? 'CASHINVOICE',
        number: document.number ?? document.invoiceNumber,
        context: isReturn ? 'return' : undefined,
      })}
    </div>
  ) : null;
};

/** FIX THIS BY NOT PASSING THE PROPS BUT ACTUALLY OPTIMIZING/REIMPLEMENTING SHOPPINGCARTROWS TO HAVE COMPONENTS/CHILDREN */
const TableBill = ({ shoppingCartProducts }) => {
  const isMobileView = !useBreakpoints().mdplus;
  const gridDisplay = useSelector(getGridIsOpened);
  const currencySymbol = useSelector(getCurrencySymbol);
  const wideTable = !isMobileView && !gridDisplay;
  const widerShoppingCart = useSelector(getUISetting('isEvenColumns'));
  const focusAmountInputForCurrentItem = useSelector(
    getSetting('pos_focus_amount_for_last_item_in_shopping_cart'),
  );
  const reverse = !useSelector(getSetting('pos_cart_rows_to_bottom'));
  const lastOrder = useSelector(getLastOrder);
  const products = useMemo(
    () =>
      reverse
        ? Array.from(shoppingCartProducts).reverse()
        : shoppingCartProducts,
    [reverse, shoppingCartProducts],
  );
  return (
    <>
      <DocumentTypeInfo />
      <Table
        className={classNames('table-bill', {
          suppressed: wideTable,
          widerShoppingCart,
          mobile: isMobileView,
          grid: gridDisplay && !isMobileView && !widerShoppingCart,
        })}
      >
        <TableBillHeader
          isMobileView={isMobileView}
          currencySymbol={currencySymbol}
          wideTable={wideTable}
        />
        <tbody data-testid="product-table-body">
          {products.map(product => (
            <TableBillRow
              key={product.orderIndex}
              gridDisplay={gridDisplay}
              isEven={product.isEven}
              useAutoFocus={
                focusAmountInputForCurrentItem &&
                product.orderIndex === lastOrder.orderIndex &&
                product.amount === 1 &&
                !product.addedByScanner
              }
              product={product}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TableBill;
