import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { displayView } from 'constants/UI';
import { goUpOneProductGroup, setGridView, setProductGroup } from 'actions/UI';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as modals } from 'constants/modalPage';
import { getSelectedProductGroupSubgroups } from 'reducers/productGroupsDB';
import { getSelectedProductGroup } from 'reducers/UI/gridDisplay';
import { getUISetting } from 'reducers/configs/settings';
import { useMeasureListHeight } from 'containers/Col2/GridViews/util';
import { getCanAddProducts } from 'reducers/Login';

import ProductGroups from '../ProductGroups';

import {
  addNewBttn,
  goUpBttn,
  homeBttn,
  leftArrowBttn,
  rightArrowBttn,
} from './SpecialBttns';

const ProductGroupsView = () => {
  const [page, setPage] = useState(0);
  const [nrGroups, setNrGroups] = useState(null);

  const dispatch = useDispatch();

  const currentProductGroup = useSelector(getSelectedProductGroup);

  const productGroups = useSelector(getSelectedProductGroupSubgroups);

  const goToHomePage = useCallback(() => {
    dispatch(setGridView(displayView.main));
    dispatch(setProductGroup('0'));
  }, [setGridView, setProductGroup]);

  const createNewItem = useCallback(
    () =>
      dispatch(
        openModalPage({
          component: modals.createNew,
        }),
      ),
    [openModalPage],
  );

  const openPreviousPage = () => page > 0 && setPage(page - 1);

  const openNextPage = () => setPage(page + 1);
  const canAddProducts = useSelector(getCanAddProducts);
  const hideAddProductBtn = useSelector(getUISetting('hideAddProductOrGroup'));

  const startBttns = useMemo(
    () =>
      [
        homeBttn(goToHomePage),
        goUpBttn(
          () => dispatch(goUpOneProductGroup()),
          !Number(currentProductGroup.id) > 0,
        ),
        canAddProducts > 0 && !hideAddProductBtn
          ? addNewBttn(createNewItem)
          : null,
      ].filter(a => a),
    [canAddProducts, hideAddProductBtn, currentProductGroup.id],
  );

  const endBttns = useMemo(
    () =>
      nrGroups + 2 < productGroups.length
        ? [
            leftArrowBttn(openPreviousPage, page === 0),
            rightArrowBttn(
              openNextPage,
              (page + 1) * nrGroups >= productGroups.length,
            ),
          ]
        : [],
    [
      nrGroups + 2 < productGroups.length,
      nrGroups >= productGroups.length,
      page,
    ],
  );

  const groups = useMemo(() => productGroups.slice(page * nrGroups), [
    productGroups,
    page,
    nrGroups,
  ]);

  const onSetNrLines = useCallback((lines, groups) => setNrGroups(groups), []);

  const { needsToMeasure, Measure, totalLines } = useMeasureListHeight();
  if (needsToMeasure) return Measure;

  return (
    <ProductGroups
      productGroups={groups}
      specialBttnsStart={startBttns}
      specialBttnsEnd={endBttns}
      onSetNrLines={onSetNrLines}
      minLines={Math.max(7, totalLines)}
      maxLines={Math.max(7, totalLines)}
    />
  );
};

export default ProductGroupsView;
