import * as R from 'ramda';

import { RESET_SHOPPING_CART } from 'constants/ShoppingCart';
import { getPluginSelector } from 'reducers/Plugins';

import { pluginID } from '../constants';

const ACTION_TYPES = {
  ADD: 'plugin taxProducts: ADD',
  REMOVE: 'plugin taxProducts: REMOVE',
  CLEAR: 'plugin taxProducts: CLEAR',
};

type State = {
  taxProducts: number[];
};
const initialState: State = {
  taxProducts: [],
};

export const addTaxProduct = (id: number) => ({
  type: ACTION_TYPES.ADD,
  payload: id,
});
export const removeTaxProduct = (id: number) => ({
  type: ACTION_TYPES.REMOVE,
  payload: id,
});
export const clearTaxProducts = {
  type: ACTION_TYPES.CLEAR,
  payload: undefined,
};
export const getTaxProducts = getPluginSelector<State>(pluginID)(
  (s = initialState) => s?.taxProducts ?? [],
);
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SHOPPING_CART:
    case ACTION_TYPES.CLEAR:
      return initialState;

    case ACTION_TYPES.ADD:
      return R.evolve({
        taxProducts: R.unless(
          R.includes(action.payload),
          R.append(action.payload),
        ),
      })(state);

    case ACTION_TYPES.REMOVE:
      return R.evolve({
        taxProducts: R.without([action.payload]),
      })(state);

    default:
      return state;
  }
};
