import React from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

import { getExchangeState } from '../rdx';

const UIDiscountButtons: PosPlugin['UIDiscountButtons'] = ({ children }) => {
  const exchangeState = useSelector(getExchangeState);
  return <>{exchangeState.exchangeStep >= 1 ? <></> : children}</>;
};

export default UIDiscountButtons;
