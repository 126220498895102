import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getHasRightToEditSettings } from 'reducers/Login';

export default function ControlledAccordions() {
  const settingsPerms = useSelector(getHasRightToEditSettings);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const { t } = useTranslation('alerts');
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <Typography variant="h6" style={{ padding: '1em', textAlign: 'left' }}>
        Statuses explained
      </Typography>
      <Accordion
        elevation={0}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="online-mode-explained"
          id="online-mode-info"
        >
          <Typography>{t('connectionStatusInfo.online.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ textAlign: 'justify' }}>
            {t('connectionStatusInfo.online.info')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="offline-mode-explained"
          id="offline-mode-info"
        >
          <Typography>{t('connectionStatusInfo.offline.title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ textAlign: 'justify' }}>
            {t('connectionStatusInfo.offline.info')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {settingsPerms ? (
        <Accordion
          elevation={0}
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="forced-offline-mode-explained"
            id="forced-offline-mode-info"
          >
            <Typography>
              {t('connectionStatusInfo.forcedOffline.title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ textAlign: 'justify' }}>
              {t('connectionStatusInfo.forcedOffline.info')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  );
}
