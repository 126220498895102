/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';

import { addError } from '../../actions/Error';

import ComponentConfiguration, {
  getFiscalIntegrationForSelectedCountry,
} from './components/ComponentConfiguration';
import {
  EFSTA_ID,
  EFSTA_INFO,
  EFSTAConf,
  EFSTAConfCompany,
  EFSTAConfPos,
  EFSTAConfUser,
  EFSTAConfWarehouse,
} from './types';

/**
 * European Fiscal STandards Association plugin
 *
 * @see https://public.efsta.net/efr/DE/EFR_Guide_%5BDE%5D_2.1.6_en.pdf DE Guide
 * @see http://public.efsta.net/efr/DE/collection_business_cases.pdf Example requests
 * @see http://files.ctk.at/AFS/EFSTA%20%28RKS%29/EFR_Installation/EFR_Installation/Sonstiges/EFR_Reference.pdf Types
 */
const EFSTAFiscalIntegration: PosPlugin<
  EFSTAConf,
  EFSTAConfCompany,
  EFSTAConfWarehouse,
  EFSTAConfPos,
  EFSTAConfUser
> = {
  id: EFSTA_ID,
  name: 'EFSTA Fiscal Integration',
  keywords: ['EFSTA', 'fiscal', 'integration'],
  info: EFSTA_INFO,
  getStatus: state => {
    const fi = getFiscalIntegrationForSelectedCountry(state);
    return (
      fi?.getStatus?.(state) ?? {
        type: 'warning',
        message:
          'Selected country has no automatic config validation. POS cannot determine if configuration is valid',
      }
    );
  },
  UICustomTableBillRow: props => {
    const { UICustomTableBillRow: Comp } = useSelector(
      getFiscalIntegrationForSelectedCountry,
    ) as any;
    // eslint-disable-next-line react/destructuring-assignment
    return Comp ? <Comp {...props} /> : props.children;
  },
  ComponentConfiguration,
  combineConfiguration: (c, w, p, u) => ({
    country: c?.country ?? '-',
    endpoint: p?.endpoint,
  }),
  ComponentHeader: ({ children }) => {
    const { ComponentHeader: Comp } = useSelector(
      getFiscalIntegrationForSelectedCountry,
    ) as any;
    return (
      <>
        {children}
        {Comp && <Comp />}
      </>
    );
  },
  onOpenPaymentModal: {
    on: (p, ap) => async (
      dispatch: ThunkDispatch<any, any, Action>,
      getState,
    ) => {
      const { onOpenPaymentModal } = getFiscalIntegrationForSelectedCountry(
        getState(),
      );
      await dispatch(onOpenPaymentModal(p, ap)).catch(err => {
        dispatch(addError(err.message));
        throw err;
      });
      return ap;
    },
  },
  onSaveSalesDocument: {
    on: (props, requests) => async (
      dispatch: ThunkDispatch<any, any, Action>,
      getState,
    ) => {
      const { onSaveDocument } = getFiscalIntegrationForSelectedCountry(
        getState(),
      );
      return dispatch(onSaveDocument(requests)).catch(err => {
        dispatch(addError(err.message));
        throw new Error('EFSTA integration failed to register sales document', {cause: err});
      });
    },
  },
  onTryCashInOut: {
    on: (props, cashInOutPayload) => async (
      dispatch: ThunkDispatch<any, any, Action>,
      getState,
    ) => {
      const { onCashInOut } = getFiscalIntegrationForSelectedCountry(
        getState(),
      );
      await dispatch(onCashInOut(props.sum)).catch(err => {
        dispatch(addError(err.message));
        throw err;
      });
      return cashInOutPayload;
    },
  },
  onCloseDay: {
    on: (p, ap) => async (
      dispatch: ThunkDispatch<any, any, Action>,
      getState,
    ) => {
      const { onCloseDay } = getFiscalIntegrationForSelectedCountry(getState());
      await dispatch(onCloseDay()).catch(err => {
        dispatch(addError(err.message));
        throw err;
      });
      return ap;
    },
  },
};

export default EFSTAFiscalIntegration;

/*
  Legend:
  * CHECT_EFSTA - check if the current country from configs should send request to EFSTA

 */
