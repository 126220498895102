import { createSelector } from 'reselect';
import { format } from 'date-fns';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { addError } from 'actions/Error';
import {
  RItem,
  RTableRow,
} from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { getCompany } from 'reducers/Login';
import { getWarehouseById } from 'reducers/warehouses';
import {
  getCurrencyCode,
  getIsModuleEnabled,
  getSetting,
} from 'reducers/configs/settings';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { requestAllRecords } from 'services/ErplyAPI/core/ErplyAPI';
import { SaleDocumentResponse } from 'types/SalesDocument';
import {
  CayanOrPaxFields,
  GivexFields,
  PaxFields,
  Payment,
} from 'types/Payment';
import { AppliedPromotionRecordResponse } from 'types/Promotions';
import { Company } from 'types/Company';
import { getCustomers } from 'services/ErplyAPI';
import { getSalesDocuments, getPayments } from 'services/ErplyAPI/sales';
import { getCurrency } from 'reducers/configs/currency';
import { getPluginConfiguration } from 'reducers/Plugins';
import { add, round } from 'utils';
import { getVatRateByID } from 'reducers/vatRatesDB';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { CancelActionOverrideError } from 'plugins/pluginUtils';
import { getSaleDocID } from 'actions/integrations/printer/utils';

import { fetchWBUDataFromInvoiceFromJsonApi } from '../API/jsonAPI';
import { pluginID } from '../constants';

import { getTranslationFunction, TransKeys } from './translations';
import {
  getBBCouponsCount,
  getCustomerMembershipEndDate,
  getRewardPointsForSale,
} from './saveReceiptDataToJSON';
import { GivexIncrementJson, PrintoutExtrasWBURecord } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 *   [WBU] Get Employee name as it should be displayed on sale document receipt
 *
 *   Based on WBU specifi setting configured in BO - employee_identifier_on_receipt
 *   return the correct name format:
 *    - [undefined | null | ''] - Do not show the employee on the receipt
 *    - id - ID
 *    - first_name - First name
 *    - first_name_and_initial - First name, with last name initial
 *    - full_name - Full name
 *
 */
const getEmployeeForReceipt = id =>
  createSelector(
    state => getEmployeeById(id)(state),
    state => getSetting('employee_identifier_on_receipt')(state),
    (employee, setting) => {
      if (!employee) return '';
      switch (setting) {
        case 'first_name':
          return employee.firstName;
        case 'first_name_and_initial':
          return `${employee.firstName} ${(employee.lastName ?? '')?.slice(
            0,
            1,
          )}`;
        default:
          return '';
      }
    },
  );

/**
 * Gets records from JSON API for WBU specific table that adds values that do not exist on saleDopcument record
 * If JSON API record is not found, the function would attempt to retrieve whatever it can - currently rewardPoints from sale and BirdBuck (BB) coupons
 * @param salesDocumentID - the id of the sale document to be printed
 */
const getWbuJsonRecordsForSale = (
  salesDocumentID: number,
  clientID: number,
) => async (dispatch): Promise<PrintoutExtrasWBURecord> =>
  fetchWBUDataFromInvoiceFromJsonApi({
    id: salesDocumentID,
  })
    .then(async ({ data }) => {
      const extras = data?.json_object?.printoutExtrasWBU ?? {};

      if (!extras.earnedRewardPoints) {
        extras.earnedRewardPoints = await getRewardPointsForSale(
          salesDocumentID,
        );
        if (extras.earnedRewardPoints) {
          extras.customerGroup = 2;
        }
      }

      if (!extras.issuedCouponsCount) {
        extras.issuedCouponsCount = await getBBCouponsCount(salesDocumentID);
        if (extras.issuedCouponsCount) {
          extras.customerGroup = 2;
        }
      }

      if (!extras.dscExpirationDate) {
        extras.dscExpirationDate = await dispatch(
          getCustomerMembershipEndDate(clientID),
        );
      }

      return extras;
    })
    .catch(async err => {
      console.error('Failed to fetch WBU data from JSON API', err);
      return {
        earnedRewardPoints: await getRewardPointsForSale(salesDocumentID),
        issuedCouponsCount: await getBBCouponsCount(salesDocumentID),
        dscExpirationDate: await dispatch(
          getCustomerMembershipEndDate(clientID),
        ),
      } as PrintoutExtrasWBURecord;
    });

const wbuRound = (...args: Parameters<typeof round>) => round(...args) ?? '';

export const doWBUSalesReceipt = (
  salesDocumentID: SaleDocumentResponse['id'],
) => async (dispatch, getState) => {
  const state = getState();

  /**
   * This configuration does not have a form
   * It is intentionally configurable only by our Customer Support
   * so the customer does not damage the logo by accident
   */
  const config = getPluginConfiguration<any>(pluginID)(state);
  const logoBlob = config?.customPrinting?.logo ?? '';
  const taxComponentsEnabled = getIsModuleEnabled('subvatrates')(state);

  const thankYouFooterNote = getSetting('receipt_footer')(state);
  const visitUsLinkSetting = getSetting('invoiceExtraFooterLine')(state);

  const t = getTranslationFunction('en');

  const discountReasons = getReasonCodes(REASONS.DISCOUNT)(state);
  const discountReasonsDict = discountReasons.reduce(
    (acc, next) => ({ ...acc, [next.reasonID]: next }),
    {},
  );

  const [salesDocument] = await getSalesDocuments({
    id: salesDocumentID,
    salesDocumentID,
    getAddedTimestamp: 1,
    getTaxComponents: taxComponentsEnabled ? 1 : undefined,
  });

  // region Fetching data
  const [payments, promotionsApplied, printoutExtrasWBU] = await Promise.all([
    (getPayments({ documentID: salesDocumentID }) as unknown) as Promise<
      Payment[]
    >,

    requestAllRecords<AppliedPromotionRecordResponse>({
      request: 'getAppliedPromotionRecords',
      invoiceIDs: salesDocumentID,
    }).then(res => res.records),
    dispatch(getWbuJsonRecordsForSale(salesDocumentID, salesDocument.clientID)),
  ]);

  const [customer = {}] = await getCustomers({
    customerID: salesDocument.clientID,
  });

  // endregion

  const promotionByProductID = promotionsApplied.reduce(
    (prev, next) => ({
      ...prev,
      [next.stableRowID]: [...(prev[next.stableRowID] ?? []), next],
    }),
    {},
  );

  const {
    promotionsByPromotionID,
    priceListsByPriceListID,
    manualDiscountsByReasonID,
  }: Record<string, Record<string, AppliedPromotionRecordResponse[]>> =
    // Input is a mixed array
    R.pipe(
      // Group by type
      R.groupBy(item => {
        if (Number(item.promotionID)) return 'promotionsByPromotionID';
        if (Number(item.priceListID)) return 'priceListsByPriceListID';
        return 'manualDiscountsByReasonID';
      }),
      // Default to empty groups if no items
      R.mergeRight({
        promotionsByPromotionID: [],
        priceListsByPriceListID: [],
        manualDiscountsByReasonID: [],
      }),
      // Inside each type, group by relevant ID
      R.evolve({
        promotionsByPromotionID: R.groupBy(item => item.promotionID),
        priceListsByPriceListID: R.groupBy(item => item.priceListID),
        manualDiscountsByReasonID: R.groupBy(
          item => item.manualDiscountReasonID,
        ),
      }),
    )(promotionsApplied);

  const getPromotionTotalByType = (
    promotionsDict: Record<string, AppliedPromotionRecordResponse[]>,
  ) =>
    Object.values(promotionsDict).map(promotion =>
      promotion.reduce(
        (pr, next) => {
          return {
            ...pr,
            promotionName:
              (next.promotionName ||
                next.priceListName ||
                discountReasonsDict[next.manualDiscountReasonID]?.name) ??
              '',
            totalDiscount: pr.totalDiscount + Number(next.totalDiscount),
          };
        },
        { totalDiscount: 0, promotionName: '' },
      ),
    );

  const currencyCode = getCurrencyCode(state);
  const currency = getCurrency(currencyCode)(state);

  const employeeName = getEmployeeForReceipt(salesDocument.employeeID)(state);
  const { name: homeStore = '' } =
    getWarehouseById(customer.homeStoreID)(state) ?? {};
  const { warehouseID } = getSelectedPos(state);
  const { address, phone, code: warehouseCode, receiptFooterText } =
    getWarehouseById(warehouseID)(state) ?? {};
  const company: Company = getCompany(state);

  const visitUsLink = receiptFooterText?.length
    ? receiptFooterText
    : visitUsLinkSetting;

  const header = (): RItem[] => [
    {
      align: 'center',
      blob: logoBlob,
      type: 'image',
    },
    {
      align: 'center',
      type: 'text',
      pieces: [
        {
          text: company.companyName,
          meta: { bold: true },
        },
      ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [
        {
          text: address,
        },
      ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [
        {
          text: `${t('phone')}: `,
        },
        {
          text: phone,
        },
      ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: !employeeName
        ? []
        : [
            {
              text: `${t('cashier')}: `,
            },
            {
              text: employeeName,
            },
          ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [
        {
          text: `${t('customer')}: `,
        },
        {
          text:
            customer?.companyName ||
            `${customer.firstName ?? ''} ${customer.lastName ?? ''}`,
        },
      ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [
        {
          text: `${t('homeStore')}: `,
        },
        {
          text: homeStore,
        },
      ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [
        { text: `${t('receipt')}: ` },
        { text: salesDocument.number },
        { text: ' #' },
        { text: warehouseCode },
      ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [
        {
          text: dayjs
            .tz(
              `${salesDocument.date} ${salesDocument.time}`,
              getSetting('timezone')(state),
            )
            .tz(dayjs.tz.guess())
            .format('MM/DD/YYYY @ HH:mm:ss'),
        },
      ],
    },
    {
      align: 'center',
      type: 'text',
      pieces: [],
    },
  ];

  const shoppingCart = (): RItem[] => {
    const createReceiptItemsRows = (): RTableRow[] => {
      return salesDocument.rows
        ?.map((row): RTableRow[] => {
          const promotions = promotionByProductID[
            row.stableRowID
          ] as AppliedPromotionRecordResponse[];
          const priceBeforePromo = promotions?.[0]?.priceBeforePromotion
            ? Number(wbuRound(promotions?.[0]?.priceBeforePromotion, 2))
            : undefined;
          const promoAmount = promotions?.[0]?.totalAmount
            ? Number(promotions?.[0]?.totalAmount)
            : undefined;
          const productRow: RTableRow = {
            type: 'normal',
            cells: [
              {
                pieces: [{ text: row.code, meta: { bold: true } }],
              },
              {
                align: 'center',
                pieces: [{ text: row.itemName, meta: { bold: true } }],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: row.amount.toString(),
                    meta: { bold: true },
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(wbuRound(row.price)), //
                    meta: { bold: true },
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(
                      wbuRound(
                        promoAmount && priceBeforePromo
                          ? promoAmount * priceBeforePromo
                          : promotions?.[0]?.totalBeforePromotion ??
                              row.rowNetTotal,
                      ),
                    ),
                    meta: { bold: true },
                  },
                ],
              },
            ],
          };

          const promotionRows: RTableRow[] =
            promotions?.map(promotion => ({
              type: 'normal',
              cells: [
                {
                  pieces: [
                    {
                      text: `${t('discount')}: `,
                    },
                    {
                      text:
                        (promotion.promotionName ||
                          promotion.priceListName ||
                          discountReasonsDict[promotion.manualDiscountReasonID]
                            ?.name) ??
                        '',
                    },
                  ],
                },
                'colspan',
                null,
                null,
                {
                  align: 'right',
                  pieces: [
                    {
                      text: `${currency.format(
                        wbuRound(-Math.abs(Number(promotion.totalDiscount))),
                      )}`,
                    },
                  ],
                },
              ],
            })) ?? [];

          const totalPromotionSum: RTableRow = {
            type: 'sectionStart',
            cells: [
              null,
              null,
              null,
              null,
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(wbuRound(row.rowNetTotal)),
                  },
                ],
              },
            ],
          };
          return [productRow, ...promotionRows, totalPromotionSum].filter(
            a => a,
          );
        })
        .flat();
    };

    return [
      {
        align: 'stretch',
        type: 'table',
        columns: [
          {
            weight: 1,
            baseWidth: 0,
          },
          {
            weight: 3,
            baseWidth: 0,
          },
          {
            baseWidth: 5,
            weight: 0,
          },
          {
            baseWidth: 8,
            weight: 0,
          },
          {
            baseWidth: 8,
            weight: 0,
          },
        ],
        rows: [
          {
            type: 'header',
            cells: [
              {
                align: 'left',
                pieces: [
                  {
                    text: t('item'),
                    meta: { bold: true },
                  },
                ],
              },
              {
                align: 'center',
                pieces: [
                  {
                    text: t('description'),
                    meta: { bold: true },
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: t('qty'),
                    meta: { bold: true },
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: t('price'),
                    meta: { bold: true },
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: t('total'),
                    meta: { bold: true },
                  },
                ],
              },
            ],
          },
          ...createReceiptItemsRows(),
        ],
      },
    ];
  };

  const total = (): RItem[] => {
    const createReceiptTotalRows = (): RTableRow[] => {
      const subTotal = salesDocument.rows
        .map(r => {
          const promotions = promotionByProductID[r.stableRowID];
          const priceBeforePromo = promotions?.[0]?.priceBeforePromotion
            ? Number(wbuRound(promotions?.[0]?.priceBeforePromotion, 2))
            : undefined;
          const promoAmount = promotions?.[0]?.totalAmount
            ? Number(promotions?.[0]?.totalAmount)
            : undefined;
          return promoAmount && priceBeforePromo
            ? promoAmount * priceBeforePromo
            : promotions?.[0]?.totalBeforePromotion ?? r.rowNetTotal;
        })
        .reduce((pr, next) => pr + Number(next), 0);

      const promotionsTotalPerType = [
        ...getPromotionTotalByType(promotionsByPromotionID),
        ...getPromotionTotalByType(priceListsByPriceListID),
        ...getPromotionTotalByType(manualDiscountsByReasonID),
      ];

      const formatPrice = price => {
        return price.replace(/[^0-9.',-]+/g, '');
      };
      const vatTotals = salesDocument.vatTotalsByTaxRate.map(vtbr => {
        const reduxVatRate = getVatRateByID(vtbr.vatrateID)(state);
        return {
          percentage: wbuRound((vtbr.total / salesDocument.netTotal) * 100, 0),
          components: taxComponentsEnabled
            ? reduxVatRate?.components?.map(comp => ({
                ...comp,
                tax: comp.name,
                // have to manually calculate the components net
                vatSum: (
                  (vtbr.total / Number(formatPrice(reduxVatRate.rate))) *
                  comp.rate
                ).toFixed(2),
              }))
            : [],
          total: vtbr.total,
          netTotal: salesDocument.netTotalsByRate[vtbr.vatrateID],
          name: reduxVatRate?.name,
          rate: reduxVatRate?.rate,
        };
      });

      return [
        {
          type: 'normal',
          cells: [
            {
              align: 'right',
              pieces: [
                {
                  text: `${t('subtotal')}: `,
                  meta: {
                    bold: true,
                  },
                },
              ],
            },
            null,
            {
              align: 'right',
              pieces: [
                {
                  text: currency.format(wbuRound(subTotal)),
                  meta: {
                    bold: true,
                  },
                },
              ],
            },
          ],
        },
        ...promotionsTotalPerType.map(
          (promo): RTableRow => {
            return {
              type: 'normal',
              cells: [
                {
                  align: 'right',
                  pieces: [
                    {
                      text: `${promo.promotionName || t('discount')}: `,
                      meta: {
                        bold: true,
                      },
                    },
                  ],
                },
                null,
                {
                  align: 'right',
                  pieces: [
                    {
                      text: `${currency.format(
                        wbuRound(-Math.abs(promo.totalDiscount)),
                      )}`,
                      meta: {
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            };
          },
        ),
        {
          type: 'normal',
          cells: [
            {
              align: 'right',
              pieces: [
                {
                  text: `${t('subtotalAfterDiscount')}: `,
                  meta: {
                    bold: true,
                  },
                },
              ],
            },
            null,
            {
              align: 'right',
              pieces: [
                {
                  text: currency.format(wbuRound(salesDocument.netTotal)),
                  meta: {
                    bold: true,
                  },
                },
              ],
            },
          ],
        },
        ...vatTotals.flatMap(vatRate => [
          {
            type: 'normal',
            cells: [
              {
                align: 'right',
                pieces: [
                  {
                    text: `${t('net')} (${vatRate.name} ${vatRate.rate}%): `,
                    meta: {
                      bold: true,
                    },
                  },
                ],
              },
              null,
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(vatRate.netTotal),
                    meta: {
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          ...(vatRate.components?.length
            ? []
            : [
                {
                  type: 'normal',
                  cells: [
                    {
                      align: 'right',
                      pieces: [
                        {
                          text: `${t('tax')} (${vatRate.name} ${
                            vatRate.rate
                          }%): `,
                          meta: {
                            bold: true,
                          },
                        },
                      ],
                    },
                    null,
                    {
                      align: 'right',
                      pieces: [
                        {
                          text: currency.format(wbuRound(vatRate.total)),
                          meta: {
                            bold: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ]),
          ...(vatRate.components ?? []).map((comp): any => ({
            type: 'normal',
            cells: [
              {
                align: 'right',
                pieces: [
                  {
                    text: `${comp.name} ${comp.rate}%:`,
                  },
                ],
              },
              null,
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(wbuRound(comp.vatSum)),
                  },
                ],
              },
            ],
          })),
        ]),
      ];
    };

    const mergeStoreCreditPayments = payments => {
      const [storeCreditPayments, restPayments] = R.partition(
        R.prop('storeCredit'),
        payments,
      );

      if (!storeCreditPayments.length) return payments;

      const sum = storeCreditPayments
        .map(({ sum }) => Number(sum))
        .reduce(add, 0);
      return [{ sum, type: 'STORECREDIT' }, ...restPayments];
    };

    const getPaymentTypeForReceipt = payment => {
      const { type, cardType } = payment;
      switch (type) {
        case 'STORECREDIT':
          return 'STORE CREDIT';
        case 'GIFTCARD':
          if (cardType.length) return `${cardType} ${type}`;
        // fallthrough
        default:
          return type;
      }
    };

    const createTendersReceiptRows = (): RTableRow[] => {
      // map over the tenders and show the Tender type and tender total
      return mergeStoreCreditPayments(payments)
        .map((payment): RTableRow[] => {
          const paymentType = getPaymentTypeForReceipt(payment);
          const sum = payment.type !== 'CASH' ? payment.sum : payment.cashPaid;
          return [
            {
              type: 'normal',
              cells: [
                {
                  align: 'right',
                  pieces: [
                    {
                      text: `Paid (by ${paymentType}):`,
                    },
                  ],
                },
                {
                  align: 'right',
                  pieces: [
                    {
                      text: currency.format(wbuRound(sum)),
                    },
                  ],
                },
              ],
            },
          ];
        })
        .flat();
    };

    return [
      // Subtotal and other promotions
      {
        align: 'stretch',
        type: 'table',
        columns: [
          {
            weight: 1,
            baseWidth: 0,
          },
          {
            weight: 0,
            baseWidth: 2,
          },
          {
            baseWidth: 10,
            weight: 0,
          },
        ],
        rows: createReceiptTotalRows(),
      },
      // Sale Total
      {
        align: 'right',
        type: 'table',
        columns: [
          {
            weight: 1,
            baseWidth: 0,
          },
          {
            weight: 1,
            baseWidth: 0,
          },
          {
            weight: 1,
            baseWidth: 0,
          },
          {
            weight: 0,
            baseWidth: 8,
          },
          {
            baseWidth: 10,
            weight: 0,
          },
        ],
        rows: [
          {
            type: 'sectionStart',
            cells: [
              null,
              null,
              null,
              {
                align: 'right',
                pieces: [
                  {
                    text: `${t('total')}: `,
                    meta: { bold: true },
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(wbuRound(salesDocument.total)),
                    meta: { bold: true },
                  },
                ],
              },
            ],
          },
          {
            type: 'sectionStart',
            cells: [
              null,
              null,
              null,
              {
                align: 'right',
                pieces: [{ text: '' }],
              },
              {
                align: 'right',
                pieces: [{ text: '' }],
              },
            ],
          },
        ],
      },
      // Tenders
      {
        align: 'stretch',
        type: 'table',
        columns: [
          {
            weight: 1,
            baseWidth: 0,
          },
          {
            baseWidth: 22,
            weight: 0,
          },
        ],
        rows: [
          ...createTendersReceiptRows(),
          // Total Amounts of tenders
          {
            type: 'normal',
            cells: [
              {
                align: 'right',
                pieces: [
                  {
                    text: `${t('amtTendered')}:`,
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(
                      wbuRound(
                        payments.reduce((pr, { sum }) => pr + Number(sum), 0),
                      ),
                    ),
                  },
                ],
              },
            ],
          },
          // Change
          {
            type: 'normal',
            cells: [
              {
                align: 'right',
                pieces: [
                  {
                    text: `${t('change')}:`,
                  },
                ],
              },
              {
                align: 'right',
                pieces: [
                  {
                    text: currency.format(
                      wbuRound(
                        payments.find(({ type }) => type === 'CASH')
                          ?.cashChange ?? '0',
                      ),
                    ),
                  },
                ],
              },
            ],
          },
          // empty line
          {
            type: 'normal',
            cells: [{ pieces: [{ text: '' }] }],
          },
        ],
      },
    ];
  };

  const paymentReceipt = (): RItem[] => {
    const createPaymentDocRows = (payment: Payment): RTableRow[] => {
      type CardFieldKeys = keyof (Payment &
        CayanOrPaxFields &
        PaxFields &
        GivexFields);

      // Card payment info rows
      const cardPaymentRows: (CardFieldKeys | CardFieldKeys[])[] = [
        'transactionType',
        'sum',
        'aid',
        'cardType',
        'cardNumber',
        'cardHolder',
        'referenceNumber',
        'authorizationCode',
        'entryMethod',
      ];

      // Givex payment info rows
      const givexPaymentRows: (CardFieldKeys | CardFieldKeys[])[] = [
        'transactionType',
        'sum',
        ['cardType', 'type'],
        'cardNumber',
        'authorizationCode',
        'expirationDate',
        'certificateBalance',
      ];

      // WBU custom payment info rows
      const customPaymentRows: (CardFieldKeys | CardFieldKeys[])[] = [
        'sum',
        ['cardType', 'type'],
      ];

      let rows = customPaymentRows;
      if (payment.type === 'CARD') rows = cardPaymentRows;
      if (payment.cardType === 'GIVEX') rows = givexPaymentRows;

      const makePaymentInfoRow = (name = '', value = ''): RTableRow => ({
        type: 'normal',
        cells: [
          {
            align: 'right',
            pieces: [
              {
                text: `${name}:`,
              },
            ],
          },
          {
            align: 'right',
            pieces: [
              {
                text: value.toString(),
              },
            ],
          },
        ],
      });

      return rows
        .map(key => {
          const keys = [key].flat();
          return makePaymentInfoRow(
            t(keys[0] as TransKeys),
            keys.map(k => payment[k]).join(' '),
          );
        })
        .concat({
          type: 'normal',
          cells: [{ pieces: [{ text: '' }] }],
        });
    };

    return [
      {
        align: 'stretch',
        type: 'table',
        columns: [
          {
            weight: 1,
            baseWidth: 0,
          },
          {
            baseWidth: 22,
            weight: 0,
          },
        ],
        rows: [
          ...payments
            .filter(
              p => ['CARD', 'GIFTCARD'].includes(p.type) && p.cardType.length,
            )
            .map(createPaymentDocRows)
            .flat(),
        ],
      },
    ];
  };

  const youSavedSection = (): RItem[] => {
    const totalDiscounts = promotionsApplied
      .map(({ totalDiscount }) => totalDiscount)
      .reduce((pr, next) => pr + Number(next), 0);

    if (totalDiscounts <= 0) return [];
    return [
      {
        align: 'center',
        type: 'table',
        columns: [
          { weight: 1, baseWidth: 0 },
          { weight: 0, baseWidth: 24 },
          { weight: 1, baseWidth: 0 },
        ],
        rows: [
          {
            type: 'sectionStart',
            cells: [
              null,
              {
                align: 'center',

                pieces: [
                  {
                    text: t('youSaved', {
                      sum: currency.format(wbuRound(totalDiscounts)),
                    }),
                    meta: { size: 2, bold: true },
                  },
                ],
              },
              null,
            ],
          },
          {
            type: 'sectionStart',
            cells: [null, { pieces: [{ text: '' }] }, null],
          },
        ],
      },
    ];
  };

  const youEarnedBBSection = (): RItem[] => {
    const issuedCouponsCount = printoutExtrasWBU?.issuedCouponsCount;
    if (issuedCouponsCount === 0) return [];
    return [
      {
        align: 'center',
        type: 'text',
        pieces: [
          {
            text: t('youEarned', {
              totalBB: `${
                issuedCouponsCount === 1 ? 'a' : issuedCouponsCount ?? 'unknown'
              }`,
            }),
            meta: { size: 2 },
          },
        ],
      },
    ];
  };

  const DSCMemberSection = (): RItem[] => {
    // comparison with constant requested by ticket Reporter
    const isDCSCustomer = Number(printoutExtrasWBU?.customerGroup) === 2;
    if (!isDCSCustomer || !printoutExtrasWBU) return [];
    return [
      {
        align: 'center',
        type: 'text',
        pieces: [
          {
            text: t('youEarnedClubPoints', {
              points:
                typeof printoutExtrasWBU.earnedRewardPoints === 'number'
                  ? wbuRound(printoutExtrasWBU.earnedRewardPoints)
                  : 'unknown',
            }),
            meta: { size: 2 },
          },
        ],
      },
      {
        align: 'center',
        type: 'text',
        pieces: [
          {
            text: t('clubPointsTotal', {
              points:
                typeof printoutExtrasWBU.dscCurrentSavings === 'number'
                  ? wbuRound(printoutExtrasWBU.dscCurrentSavings)
                  : 'unknown',
            }),
            meta: { size: 2 },
          },
        ],
      },
      {
        align: 'center',
        type: 'text',
        pieces: [
          {
            text: `${t('clubPointsExpiration')}: `,
            meta: { size: 2 },
          },
          {
            text: printoutExtrasWBU?.dscExpirationDate
              ? format(
                  new Date(printoutExtrasWBU.dscExpirationDate),
                  'MM/dd/yyyy',
                )
              : 'unknown',
            meta: { size: 2 },
          },
        ],
      },
      {
        align: 'center',
        type: 'text',
        pieces: [
          {
            text: t('storePointsEligibilityNote'),
          },
        ],
      },
      // empty line
      {
        align: 'center',
        type: 'text',
        pieces: [{ text: '' }],
      },
    ];
  };

  // always
  const visitUsNote = (): RItem[] => [
    {
      align: 'center',
      type: 'text',
      pieces: [
        {
          text: thankYouFooterNote,
          meta: { bold: true },
        },
      ],
    },
    // empty line
    {
      align: 'center',
      type: 'text',
      pieces: [{ text: '', meta: { size: 2 } }],
    },
    {
      type: 'table',
      columns: [
        { weight: 1, baseWidth: 0 },
        { weight: 3, baseWidth: 0 },
        { weight: 1, baseWidth: 0 },
      ],
      rows: [
        {
          type: 'sectionStart',
          cells: [
            null,
            {
              align: 'center',
              pieces: [{ text: '' }],
            },
            null,
          ],
        },
        {
          type: 'normal',
          cells: [
            null,
            {
              align: 'center',
              pieces: [
                {
                  text: `${t('visitUsNote')}:`,
                  meta: { bold: true },
                },
              ],
            },
            null,
          ],
        },
        {
          type: 'normal',
          cells: [
            null,
            {
              align: 'center',
              pieces: [
                {
                  text: visitUsLink,
                },
              ],
            },
            null,
          ],
        },
        // empty line
        {
          type: 'normal',
          cells: [
            null,
            {
              align: 'center',
              pieces: [{ text: '' }],
            },
            null,
          ],
        },
        {
          type: 'sectionStart',
          cells: [
            null,
            {
              align: 'center',
              pieces: [{ text: '' }],
            },
            null,
          ],
        },
      ],
    },
    // empty line
    {
      align: 'center',
      type: 'text',
      pieces: [{ text: '', meta: { size: 2 } }],
    },
  ];

  const barcodeSection = (): RItem[] => [
    {
      align: 'center',
      type: 'barcode',
      format: 'Code39FullASCII',
      data: salesDocument.number,
    },
  ];

  const givexIncrementSection = (): RItem[] => {
    const givexRows: GivexIncrementJson[] = salesDocument.rows
      .filter(r => r.jdoc?.BrazilPOS?.givex)
      .map(r => r?.jdoc?.BrazilPOS?.givex);

    const createGivexIncrementRow = (
      givexRow: GivexIncrementJson,
    ): RTableRow[] => {
      const maskedGivexRowNumber = `****${givexRow.number}*`;
      return [
        {
          type: 'normal',
          cells: [
            {
              pieces: [
                {
                  // TODO: add translations `${t('incrementCardNumber')}` and test
                  text: 'Card number',
                  meta: { bold: true },
                },
                {
                  text: ': ',
                  meta: { bold: true },
                },
                {
                  text: maskedGivexRowNumber,
                },
              ],
            },
            'colspan',
          ],
        },
        {
          type: 'normal',
          cells: [
            {
              pieces: [
                {
                  // TODO: add translations `${t('incrementDate')}` and test
                  text: 'Date',
                  meta: { bold: true },
                },
                {
                  text: ': ',
                  meta: { bold: true },
                },
                {
                  text: givexRow.dateTime,
                },
              ],
            },
            'colspan',
          ],
        },
        {
          type: 'normal',
          cells: [
            {
              pieces: [
                {
                  // TODO: add translations `${t('incrementBalance')}` and test
                  text: 'Balance',
                  meta: { bold: true },
                },
                {
                  text: ': ',
                  meta: { bold: true },
                },
                {
                  text: givexRow.balance,
                },
              ],
            },
            'colspan',
          ],
        },
        {
          type: 'normal',
          cells: [
            {
              pieces: [
                {
                  // TODO: add translations `${t('incrementRefNo')}` and test
                  text: 'Reference number',
                  meta: { bold: true },
                },
                {
                  text: ': ',
                  meta: { bold: true },
                },
                {
                  text: givexRow.referenceNumber,
                },
              ],
            },
            'colspan',
          ],
        },
        {
          type: 'sectionStart',
          cells: [{ pieces: [] }],
        },
      ];
    };
    return [
      {
        align: 'left',
        type: 'table',
        columns: [
          {
            weight: 2,
            baseWidth: 2,
          },
          {
            weight: 2,
            baseWidth: 2,
          },
          {
            weight: 0,
            baseWidth: 20,
          },
        ],
        rows: givexRows.flatMap(createGivexIncrementRow),
      },
    ];
  };

  return [
    ...header(),
    ...shoppingCart(),
    ...total(),
    ...paymentReceipt(),
    ...youSavedSection(),
    ...youEarnedBBSection(),
    ...DSCMemberSection(),
    ...visitUsNote(),
    ...barcodeSection(),
    ...givexIncrementSection(),
  ];
};

export const onPrintWBUReceipt: Required<
  PosPlugin
>['onGenerateReceipt']['before'] = ({ salesDocument }) => async dispatch => {
  const salesDocumentID = getSaleDocID(salesDocument);
  if (salesDocumentID === undefined) {
    dispatch(addError('Failed to print last invoice - invoice ID is unknown'));
    throw new Error(
      'Cancel print, only do plugin print but plugin print also failed',
    );
  }

  const patchScript = (await dispatch(
    doWBUSalesReceipt(Number(salesDocumentID)),
  )) as RItem[];
  throw new CancelActionOverrideError('Cancel print, only do plugin print', {
    patchScript,
  });
};
