import { checkIsDayOpen } from 'actions/OpenCloseDay';
import { TYPE_SELECTPOS } from 'constants/PointsOfSale';
import {
  REDUX_POSID,
  REDUX_WAREHOUSE,
  REDUX_WAREHOUSEID,
} from 'constants/persistence';
import { getPointsOfSale } from 'reducers/PointsOfSale';
import { getAllWarehouses } from 'reducers/warehouses';

export function selectPos(id) {
  return async (dispatch, getState) => {
    if (id === null) {
      localStorage.removeItem(REDUX_POSID);
    } else {
      localStorage.setItem(REDUX_POSID, JSON.stringify(id));
    }
    const pos = getPointsOfSale(getState()).find(pos => pos.pointOfSaleID === id);
    if (pos) {
      localStorage.setItem(
        REDUX_WAREHOUSEID,
        JSON.stringify(`${pos.warehouseID}`),
      );
      const warehouseFound =
        getAllWarehouses(getState()).find(
          warehouse => warehouse.warehouseID === pos.warehouseID,
        ) || null;
      if (warehouseFound) {
        localStorage.setItem(REDUX_WAREHOUSE, JSON.stringify(warehouseFound));
      }
    }

    dispatch({ type: TYPE_SELECTPOS, payload: id });
    if (pos) {
      dispatch(checkIsDayOpen({ pointOfSaleID: id }));
    }
  };
}
