import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import * as R from 'ramda';

import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { saveItem, sanitizePrice } from 'actions/CreateNew';
import { getSelectedProductGroupID } from 'reducers/UI/gridDisplay';
import Loader from 'components/Loader';
import { getDefaultProductGroupID } from 'reducers/productGroupsDB';
import {
  getActiveVatRates,
  getVatRateByID,
  getVatRates,
  getVatRatesFetching,
} from 'reducers/vatRatesDB';
import { addWarning } from 'actions/Error';

import { GroupForm, ProductForm } from './forms';

import './CreateNewItem.scss';

const CreateNewItem = ({ type }) => {
  const dispatch = useDispatch();
  const selectedGroupID = useSelector(getSelectedProductGroupID);
  const vatRatesAreFetching = useSelector(getVatRatesFetching);
  const vatRates = useSelector(getVatRates).sort(
    (a, b) => Number(a.id) - Number(b.id),
  );
  const activeVatRates = useSelector(getActiveVatRates);
  const currentGroupID = Number(selectedGroupID);
  const { t } = useTranslation('createNew');

  const defaultProductGroupID = useSelector(getDefaultProductGroupID);

  useEffect(() => {
    if (!vatRatesAreFetching && !vatRates.length) {
      dispatch(addWarning(t('warning.noVatRates')));
      dispatch(previousModalPage());
    }
  }, [addWarning, dispatch, previousModalPage, vatRatesAreFetching]);

  const [state, setState] = useState({
    product: {
      name: '',
      code: '',
      groupID: currentGroupID || defaultProductGroupID || 1,
      vatrateID:
        (activeVatRates.length ? activeVatRates[0].id : vatRates?.[0]?.id) ?? 0,
      netPrice: 0.0,
      unitID: 0,
      nonStockProduct: 0,
    },
    group: {
      name: '',
      parentGroupID: currentGroupID,
    },
  });

  // update vat rate when the rates have loaded
  useEffect(() => {
    if (
      !vatRatesAreFetching &&
      (activeVatRates.length || vatRates.length) &&
      !state.product.vatrateID
    ) {
      const newVatRateID = activeVatRates?.[0]?.id ?? vatRates[0].id;
      setState(R.assocPath(['product', 'vatrateID'], newVatRateID));
    }
  }, [activeVatRates, vatRates, state.product.vatrateID, vatRatesAreFetching]);

  const vatRate = useSelector(getVatRateByID(state.product.vatrateID));

  const handleChange = (key, value) => {
    let newValue;
    switch (key) {
      case 'netPrice': {
        const priceWithVAT =
          Number(value) + (Number(value) * Number(vatRate.rate)) / 100;
        const newPrices = { netPrice: sanitizePrice(value), priceWithVAT };
        setState({
          ...state,
          [type]: {
            ...state[type],
            ...newPrices,
          },
        });
        break;
      }
      case 'priceWithVAT': {
        const netPrice = Number(value) / (1 + Number(vatRate.rate) / 100);
        const newPrices = { netPrice, priceWithVAT: sanitizePrice(value) };
        setState({
          ...state,
          [type]: {
            ...state[type],
            ...newPrices,
          },
        });
        break;
      }
      case 'vatrateID': {
        const { netPrice } = state[type];
        const vatrateID = value;
        const vatrate = vatRates.find(rate => rate.id === vatrateID);
        const priceWithVAT =
          Number(netPrice) + (Number(netPrice) * Number(vatrate.rate)) / 100;
        const values = { vatrateID, priceWithVAT };
        setState({
          ...state,
          [type]: {
            ...state[type],
            ...values,
          },
        });
        break;
      }
      case 'nonStockProduct':
        newValue = value ? 1 : 0;
        setState({
          ...state,
          [type]: {
            ...state[type],
            [key]: newValue,
          },
        });
        break;
      default:
        newValue = value;
        setState({
          ...state,
          [type]: {
            ...state[type],
            [key]: newValue,
          },
        });
        break;
    }
  };

  const [disabled, setDisabled] = useState(false);
  const handleSaveItem = () => {
    setDisabled(true);
    dispatch(saveItem({ params: state[type], itemType: type })).finally(() =>
      setDisabled(false),
    );
  };

  const header = {
    product: t('product.title'),
    group: t('group.title'),
  }[type];

  return (
    <div className="create-new-item">
      <Modal.Header>
        <Modal.Title className="modal-title-overflow mr-2">
          <h3>
            <b>{header}</b>
          </h3>
        </Modal.Title>
        <div className="create-new-item-action-buttons">
          <SaveButton
            data-testid="save-btn"
            action={handleSaveItem}
            disabled={disabled || vatRatesAreFetching}
            loading={vatRatesAreFetching}
          />
          <CloseButton action={() => dispatch(previousModalPage())} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Loader show={vatRatesAreFetching} block>
          {type === 'product' && (
            <ProductForm
              state={state[type]}
              handleSaveItem={handleSaveItem}
              type={type}
              onChange={handleChange}
            />
          )}
          {type === 'group' && (
            <GroupForm
              handleSaveItem={handleSaveItem}
              state={state[type]}
              type={type}
              onChange={handleChange}
            />
          )}
        </Loader>
      </Modal.Body>
    </div>
  );
};

export default CreateNewItem;
