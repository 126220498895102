import * as R from 'ramda';

export class ErplyLongAttributes extends Object {
  data: { [key: string]: any } = {};

  constructor(from?: any) {
    super({});
    if (!from) return;
    // eslint-disable-next-line consistent-return
    if (from.constructor === ErplyLongAttributes) return from;
    // Parse array
    if (from?.map) {
      from.forEach(({ attributeName: key, attributeValue: value }) => {
        this.data[key] = value;
      });
      return;
    }
    const matches = Object.keys(from)
      .map(k => k.match(/^longAttribute(Name|Value)(\d+)$/))
      .filter(i => i !== null);
    if (matches.length) {
      matches.forEach(k => {
        if (k) {
          const index = k[2];
          this.data[from[`longAttributeName${index}`]] =
            from[`longAttributeValue${index}`];
        }
      });
      return;
    }
    if (from.constructor === Object) {
      this.data = from;
    }
  }

  static fromFlatArray(from) {
    const obj = new ErplyLongAttributes({});
    const matches = Object.keys(from)
      .map(k => k.match(/^longAttribute(Name|Value)(\d+)$/))
      .filter(i => i !== null);
    matches.forEach(k => {
      if (k) {
        const index = k[2];
        obj.set(
          from[`longAttributeName${index}`],
          from[`longAttributeValue${index}`],
        );
      }
    });
    return obj;
  }

  static withoutFlatArray(obj) {
    return R.pickBy(
      (value, key) => !R.test(/^longAttribute(Name|Value)(\d+)$/)(String(key)),
    )(obj);
  }

  /** @param {ErplyLongAttributes} otherAttrs */
  merge(otherAttrs) {
    return new ErplyLongAttributes({
      ...this.asDict,
      ...otherAttrs.asDict,
    });
  }

  get(name) {
    return this.data[name];
  }

  set(name, value) {
    this.data[name] = value;
    return this;
  }

  remove(name) {
    delete this.data[name];
    return this;
  }

  /**
   * {
   *   foo: 'foovalue',
   *   bar: 2
   * }
   */
  get asDict() {
    return this.data;
  }

  /**
   * @example [
   *   {
   *     attributeName: 'foo',
   *     attributeValue: 'foovalue',
   *   }, {
   *     attributeName: 'bar',
   *     attributeValue: 2,
   *   }
   * ]
   */
  get asArray(): {
    attributeName: string;
    attributeValue: any;
  }[] {
    return R.pipe(
      R.toPairs,
      R.map(([k, v]) => ({
        attributeName: k,
        attributeValue: v,
      })),
    )(this.data) as any;
  }

  /**
   * @example {
   *   longAttributeName1: 'foo',
   *   longAttributeValue1: 'foovalue',
   *   longAttributeName2: 'bar',
   *   longAttributeValue2: 2,
   * }
   */
  get asFlatArray() {
    return Object.fromEntries(
      this.asArray.flatMap((obj, i) =>
        Object.entries(obj).map(([k, v]) => [
          `${k.replace(/attribute/, 'longAttribute')}${i + 1}`,
          v,
        ]),
      ),
    );
  }
}
