import uuid from 'uuid/v4';
import * as R from 'ramda';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { getClientCode } from 'reducers/Login';
import { sleep } from 'utils';

import { validateSuccess } from '../errors/validateSuccess';
import { parseError } from '../errors/parseError';
import { RootState } from '../../../reducers';
import { TerminalError } from '../errors/errors';

import makePayment from './makePayment';
import handleSuccess from './handleSuccess';
import { attemptRecovery } from './attemptRecovery';
import handlePrint from './handlePrint';

const processPayments = ({
  cardPayments,
  updateMessage,
  enableButtons,
}): ThunkAction<{ data: any[]; errors: boolean }, RootState, void, Action> => (
  dispatch,
  getState,
) => {
  const clientCode = getClientCode(getState());
  const shouldVoid = cardPayments.every(p => p.paid && p.shouldProcess);
  return cardPayments
    .filter(p => (shouldVoid ? p.paid : !p.paid))
    .map(R.assoc('clientCode', clientCode))
    .map(p => R.assoc('uuid', uuid())(p))
    .map(p => {
      if (!p?.attributes?.refNo) {
        // eslint-disable-next-line no-param-reassign
        return {
          ...p,
          attributes: { ...(p.attributes ?? {}), refNo: uuid() },
        };
      }
      return p;
    })
    .reduce((prev, payment) => {
      const {
        attributes: { refNo },
      } = payment;
      return prev.then(async ({ data, errors }) => {
        if (!errors) {
          // Perform initial request
          await dispatch(makePayment({ payment, updateMessage }))
            // Check response for any issues, convert them to thrown errors
            .then(validateSuccess, parseError)
            // If error, attempt recovery (incl. force buttons)
            .catch(err => {
              if (shouldVoid) {
                // No recovery options for void
                updateMessage(`Void failed due to:\n${err}`);
                throw err;
              }
              return dispatch(
                attemptRecovery(
                  refNo,
                  payment,
                  err,
                  updateMessage,
                  enableButtons,
                ),
              );
            })
            // Update POS
            .then(
              async success => {
                await dispatch(
                  handleSuccess({
                    payment,
                    response: success.data,
                    updateMessage,
                    shouldVoid,
                  }),
                );
                data.push(success.data.records[0]);
              },
              async error => {
                console.warn('Payment failed with error', error);
                if (error instanceof TerminalError) {
                  if (error.printFrom) {
                    dispatch(handlePrint({ response: error.printFrom }));
                  }
                }
                // eslint-disable-next-line no-param-reassign
                errors = true;
              },
            )
            .finally(() => {
              sleep(0.5);
            });
        }
        return { data, errors };
      });
    }, Promise.resolve({ data: [], errors: false }));
};

export default processPayments;
