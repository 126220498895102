import React from 'react';
import Modal from 'react-bootstrap/Modal';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';

export default ({ title, headerButtons = {}, onClose, children }) => {
  return (
    <>
      <Modal.Header>
        <div style={{ fontSize: 24, fontWeight: 'bold' }}>{title}</div>

        <span style={{ flexGrow: 1 }} />
        {Object.entries(headerButtons).map(([key, action]) => (
          <SaveButton action={action} title={key} />
        ))}
        {onClose && <CloseButton action={onClose} />}
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
    </>
  );
};
