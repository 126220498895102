import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  AccordionActions,
  Accordion,
  Button,
  styled,
} from '@material-ui/core';
import { ExpandMore, Save, EditOutlined, Edit } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from 'reducers';
import { Configuration, Reader } from 'paymentIntegrations/stripe/types';
import { updateReader } from 'paymentIntegrations/stripe/API';
import { addWarning, addSuccess, addError } from 'actions/Error';

const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
});

const ReaderElement = ({
  reader,
  configuration,
  changeReader,
}: {
  reader: Reader;
  configuration: Configuration;
  changeReader: (reader: Reader) => void;
}) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { t } = useTranslation('settingsPayment', {
    keyPrefix: 'integrations.stripe',
  });

  const selectReader = () => {
    changeReader(reader);
    dispatch(addWarning(t('saveToConfirm')));
  };

  const { reader: selectedReader } = configuration ?? {};

  const [label, setLabel] = useState(reader.label);
  const [editMode, setEditMode] = useState(false);

  const saveEdit = async () => {
    try {
      await dispatch(updateReader({ label, readerID: reader.id }));
      dispatch(addSuccess(t('nameChanged')));
    } catch (e) {
      if (e instanceof Error) {
        dispatch(addError(e.message));
      } else if (typeof e === 'string') {
        dispatch(addError(e));
      } else {
        dispatch(addError(t('errors.terminalRenamingError')));
      }
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">
          {t('titles.reader', {
            context: selectedReader?.id === reader.id ? 'selected' : '',
            label,
          })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <TextField
          fullWidth
          label={t('labels.readerLabel')}
          value={label}
          disabled={!editMode}
          onChange={e => setLabel(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ display: editMode ? 'block' : 'none' }}
                  disabled={label === reader.label}
                  onClick={() => saveEdit()}
                >
                  <Save />
                </IconButton>
                <IconButton
                  aria-label={t('labels.editLabel')}
                  onClick={() => setEditMode(val => !val)}
                  edge="end"
                >
                  {editMode ? <EditOutlined /> : <Edit />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FlexBox>
          <Title>{t('fields.version')}</Title>
          <Typography>{reader.device_sw_version}</Typography>
        </FlexBox>
        <FlexBox>
          <Title>{t('fields.type')}</Title>
          <Typography>{reader.device_type}</Typography>
        </FlexBox>
        <FlexBox>
          <Title>{t('fields.identifier')}</Title>
          <Typography>{reader.id}</Typography>
        </FlexBox>
        <FlexBox>
          <Title>{t('fields.ip')}</Title>
          <Typography>{reader.ip_address}</Typography>
        </FlexBox>
        <FlexBox>
          <Title>{t('fields.serialNumber')}</Title>
          <Typography>{reader.serial_number}</Typography>
        </FlexBox>
      </AccordionDetails>
      {selectedReader?.id !== reader.id && (
        <AccordionActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => selectReader()}
          >
            {t('selectReader')}
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

export default ReaderElement;
