import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ListItem from 'components/ListItem';
import { PosPlugin } from 'plugins/plugin';
import { addWarning } from 'actions/Error';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import {
  getGivexConfiguration,
  getHasGivexProductInCart,
  getIsAllowed,
} from 'plugins/givexHeartland/configuration/Configuration';
import { getBalance } from 'reducers/Payments';

const ButtonItem = ({ style = {}, ...props }) => (
  <ListItem
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontFamily: 'Proxima Nova',
      minWidth: 80,
      minHeight: 76,
      height: 'inherit',
      width: 'inherit',
      ...style,
    }}
    {...props}
  />
);
/** Adds a 'GIVEX' button to the payment keypad. Said button adds a CARD payment for givex to process */
const KeyPad: PosPlugin['UIKeyPad'] = ({ children }) => {
  const { t } = useTranslation('payment');

  const dispatch = useDispatch();

  const balance = useSelector(getBalance);
  const isAllowed = useSelector(getIsAllowed);
  const hasGivexProduct = useSelector(getHasGivexProductInCart);
  const { displayName } = useSelector(getGivexConfiguration);

  if (hasGivexProduct || !isAllowed) return children;

  const addGivexPayment = () => {
    if (balance !== 0) {
      dispatch(setPaymentSelected('giftcard-givex'));
    } else {
      dispatch(
        addWarning(t('alerts.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
  };
  return R.pipe(
    R.prepend(
      <ButtonItem variant="grid_yellow" action={addGivexPayment}>
        {displayName}
      </ButtonItem>,
    ),
  )(React.Children.toArray(children));
};

export default KeyPad;
