import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { setKeyboardEnabled } from 'actions/OnScreenKeyboard';
import { getKeyboardIsEnabled } from 'reducers/OnScreenKeyboard';
import InputField from 'components/FieldTypes/InputField';

const OnScreenKeyboardSettings = () => {
  const keyboardEnabled = useSelector(getKeyboardIsEnabled);
  const dispatch = useDispatch();
  const toggleKeyboard = () => dispatch(setKeyboardEnabled(!keyboardEnabled));
  const onClose = () => dispatch(previousModalPage());

  const { t } = useTranslation('settingsOnScreenKeyboard');

  return (
    <div>
      <Modal.Header
        style={{
          display: 'flex',
        }}
      >
        <Modal.Title style={{ fontSize: '24px', fontWeight: 700 }}>
          {t('title')}
        </Modal.Title>
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <CloseButton action={onClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <h2>{t('descriptionTitle')}</h2>
        <p>{t('description')}</p>
        <InputField
          type="checkbox"
          value={keyboardEnabled}
          onChange={toggleKeyboard}
          size="lg"
        >
          {t('checkbox')}
        </InputField>
      </Modal.Body>
    </div>
  );
};

export default OnScreenKeyboardSettings;
