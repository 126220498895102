import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Alert from '@material-ui/lab/Alert';
import * as R from 'ramda';
import { useToggle } from 'react-use';
import { Settings } from '@material-ui/icons';

import { getUseCreateCustomerBeta } from 'reducers/configs/settings';
import { saveTempSetting } from 'actions/configs';

import {
  CUSTOMER_TYPES,
  CustomerType,
  CustomerFormConfiguration as CustomerPOSConfig,
  FormFieldConfiguration as FormFieldConfig,
  FormFieldName,
} from '../types';

import FormFieldConfiguration from './FormFieldConfiguration';

interface Props {
  config: CustomerPOSConfig;
  updateConfig: Dispatch<SetStateAction<CustomerPOSConfig>>;
}

const CustomerFormConfiguration = ({ config, updateConfig }: Props) => {
  const dispatch = useDispatch();
  const [showConfig, toggleConfig] = useToggle(false);

  const isConfigurableCustomerFromEnabled = useSelector(
    getUseCreateCustomerBeta,
  );
  const toggleCustomerFormBeta = () => {
    dispatch(
      saveTempSetting(
        'pos_brazil_use_customer_creation_beta',
        !isConfigurableCustomerFromEnabled,
      ),
    );
  };

  const [customerType, setCustomerType] = useState<CustomerType>(
    CUSTOMER_TYPES.PERSON,
  );

  const updateConfigPerField = useCallback(
    (field: FormFieldName, config: FormFieldConfig) => {
      updateConfig(R.assocPath(['formFields', customerType, field], config));
    },
    [customerType, updateConfig],
  );

  const updateAllowedCustomerType = e => {
    updateConfig(R.assoc('allowedCustomerTypes', e.target.value));
  };

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid container>
        <Grid item>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isConfigurableCustomerFromEnabled}
                  onChange={toggleCustomerFormBeta}
                />
              }
              label="Enable Configurable Customer Form"
            />
          </FormControl>
        </Grid>
        <Grid item xs />
        <Grid item>
          <IconButton onClick={toggleConfig}>
            <Settings />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={showConfig}>
        <Grid item xs={12}>
          <h2>Configurable Customer Form</h2>
        </Grid>
        <Grid item xs={12}>
          <p>
            Configurable customer form allows you to specify which fields should
            be visible on the customer creation form and which of them should be
            required. You can also customize their names so would be more
            descriptive and prompting to you.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Alert color="warning">
            <WarningIcon /> Enabling Configurable Customer Form would break all
            Customer Creation plugins. Either use the legacy Customer Creation
            Form with plugins or the new Configurable Customer Form. For more
            information, please, contact our support team.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              POS user should be able to create:
            </FormLabel>
            <RadioGroup
              aria-label="allowed customer type"
              defaultValue=""
              value={config.allowedCustomerTypes}
              onChange={updateAllowedCustomerType}
            >
              <FormControlLabel
                value={CUSTOMER_TYPES.PERSON}
                control={<Radio />}
                label="Person"
              />
              <FormControlLabel
                value={CUSTOMER_TYPES.COMPANY}
                control={<Radio />}
                label="Company"
              />
              <FormControlLabel
                value=""
                control={<Radio />}
                label="PERSON and COMPANY"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <p>
            Select which fields to show when creating/editing customer at the
            POS
          </p>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Customer type"
            variant="outlined"
            fullWidth
            onChange={e => setCustomerType(e.target.value as CustomerType)}
            value={customerType}
          >
            <MenuItem value={CUSTOMER_TYPES.PERSON}>
              {CUSTOMER_TYPES.PERSON}
            </MenuItem>
            <MenuItem value={CUSTOMER_TYPES.COMPANY}>
              {CUSTOMER_TYPES.COMPANY}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormFieldConfiguration
            config={config}
            customerType={customerType}
            updateConfigPerField={updateConfigPerField}
          />
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default CustomerFormConfiguration;
