import * as R from 'ramda';
import * as Sentry from '@sentry/browser';

import { PosPlugin } from 'plugins/plugin';
import { ErplyAttributes } from 'utils';
import { addError } from 'actions/Error';

import { getSAINumber } from '../redux/selectors';
import { ATTRIBUTES } from '../constants';

const addSAINumberAttribute: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (props, requests) => async (
  dispatch,
  getState,
) => {
  const SAINumber = getSAINumber(getState());
  if (SAINumber === null) {
    const message = 'Can not save a sale without SAI number';
    dispatch(addError(message, { dismissible: false, selfDismiss: false }));
    const error = new Error(message);
    Sentry.captureException(error);
    throw error;
  }

  function addSAINumberToAttributes(request: typeof requests[number]) {
    const attributes = new ErplyAttributes(request.attributes);
    attributes.set(ATTRIBUTES.SAI_NUMBER, SAINumber);
    return { ...request, ...attributes.asFlatArray };
  }

  return R.map(
    R.when(
      R.propEq('requestName', 'saveSalesDocument'),
      addSAINumberToAttributes,
    ),
  )(requests);
};

export default addSAINumberAttribute;
