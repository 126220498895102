/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import { PluginComponent } from 'plugins';
import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import Loader from 'components/Loader';
import { getIsPickupInProgress } from 'reducers/sales';
import {
  getInvoiceFullyReturned,
  getIsLoading,
  getModalTitleProps,
  getOrdersSelected,
  getReturnDocument,
  getSalesDocs,
} from 'reducers/returnProducts';
import {
  addSelectedOrdersToShoppingCart,
  fetchSalesDocs,
  setLoading,
  setOrdersSelected,
} from 'actions/returnProducts';
import './ProductReturn.scss';
import { useAsyncFunctionNoParallelExecution } from 'utils/hooks/useSingleUseFunction';
import { getSetting } from 'reducers/configs/settings';

import { DocumentSelection, ReturnTable } from './components';
import { useOrderLayawayCancellation } from './hooks';

const ReturnProductContent = () => {
  const invoiceFullyReturned = useSelector(getInvoiceFullyReturned);
  const salesDocs = useSelector(getSalesDocs);
  const returnDocument = useSelector(getReturnDocument);

  useOrderLayawayCancellation();

  if (salesDocs.length > 1 && !returnDocument) {
    return <DocumentSelection />;
  }
  if (!returnDocument) return null;

  if (
    returnDocument.type === 'PREPAYMENT' ||
    returnDocument.type === 'ORDER' ||
    invoiceFullyReturned
  ) {
    return null;
  }

  return <ReturnTable />;
};

type ProductReturnProps = {
  saleNumberProp?: string;
};

const ProductReturn: FC<ProductReturnProps> = ({ saleNumberProp }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('return');

  const ordersSelected = useSelector(getOrdersSelected);
  const modalTitleProps = useSelector(getModalTitleProps);
  const modalTitle = t('title', modalTitleProps);

  const loading = useSelector(getIsLoading);
  const pickupInProgress = useSelector(getIsPickupInProgress);
  const returnDocument = useSelector(getReturnDocument);
  const documentTypes = useSelector(getSetting('allowed_types_on_return')).join(
    ',',
  );

  const [salesDocNumber, setSalesDocNumber] = useState(saleNumberProp ?? '');

  const [ordersSelectedInitVal, setOrdersSelectedInitVal] = useState<
    typeof ordersSelected | null
  >(null);
  useEffect(() => {
    if (!ordersSelectedInitVal && ordersSelected) {
      setOrdersSelectedInitVal(ordersSelected);
    }
  }, [ordersSelectedInitVal, ordersSelected]);

  const getSales = useCallback(
    async event => {
      if (event) {
        event.preventDefault();
      }
      dispatch(setLoading(true));
      salesDocNumber &&
        (await dispatch(
          fetchSalesDocs({
            number: salesDocNumber,
            search_invoice_by_regular_and_custom_number: 1,
            getReturnedPayments: 1,
            types: documentTypes,
          }),
        ));
      dispatch(setLoading(false));
    },
    [dispatch, salesDocNumber, documentTypes],
  );

  // Do a search whenever the number is changed
  useDebounce(() => getSales(null), 500, [getSales]);

  const [save, { isUsed: saving }] = useAsyncFunctionNoParallelExecution(() =>
    dispatch(addSelectedOrdersToShoppingCart()),
  );

  const close = () => {
    // update the ordersSelected state to initial value from when the component was opened
    dispatch(setOrdersSelected(ordersSelectedInitVal));
    dispatch(previousModalPage());
  };

  const onInvoiceNumberChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSalesDocNumber(
      event.target.value.replace(/k$/, 'K').replace(/([kK](?=.))/g, ''),
    );
  const loadingText = t('alerts.loading');

  const getSaveButtonText = () => {
    if (loading || pickupInProgress) return loadingText;
    return !returnDocument
      ? t('common:confirm')
      : t('common:save', { context: 'changes' });
  };

  const invalidAmounts = Object.values(ordersSelected).some(
    order =>
      Number(order.amount) > order.originalAmount || Number(order.amount) === 0,
  );

  return (
    <div className="product-return" data-testid="product-return">
      <Box
        display="flex"
        alignItems="center"
        padding="1rem"
        justifyContent="space-between"
      >
        <PluginComponent hookname="UIExchangeModalTitle">
          <Typography variant="h5" data-testid="return-title">
            {modalTitle}
          </Typography>
        </PluginComponent>
        <Box display="flex" alignItems="center">
          <SaveButton
            data-testid="save-return-btn"
            title={getSaveButtonText()}
            action={save}
            disabled={invalidAmounts || saving}
          />
          <PluginComponent
            hookname="UIProductReturnCloseButton"
            props={{ close }}
          >
            <CloseButton action={close} />
          </PluginComponent>
        </Box>
      </Box>
      <Divider />
      <Box padding="1rem">
        <form onSubmit={getSales}>
          <TextField
            variant="outlined"
            fullWidth
            onChange={onInvoiceNumberChange}
            onFocus={event => {
              event.preventDefault();
            }}
            value={salesDocNumber}
            label={t('fields.invoiceNumber', {
              context: 'placeholder',
            })}
            className="invoice-number"
            autoFocus
            InputProps={{
              inputProps: {
                'data-testid': 'invoice-number',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Divider
                    orientation="vertical"
                    style={{ width: 2, height: 35, marginRight: '1rem' }}
                  />
                  <SearchOutlinedIcon
                    onClick={getSales}
                    className="search-button"
                  />
                </InputAdornment>
              ),
            }}
          />
        </form>
        <Loader withPadding loadingText={loadingText} block show={loading}>
          <ReturnProductContent />
        </Loader>
      </Box>
    </div>
  );
};

export default ProductReturn;
