import { getCurrentSalesDocument, getIsAReturn } from 'reducers/sales';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getProductsUniversal } from 'actions/productsDB';
import { addError } from 'actions/Error';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getClientCode, getSessionKey } from 'reducers/Login';
import { calculateShoppingCart } from 'services/ErplyAPI';
import {
  removeProduct,
  updateOrderAmount,
  updateOrderPrice,
} from 'actions/ShoppingCart';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { Product } from 'types/Product';

import {
  ADMISSION_FEE_TAX_AMOUNT,
  EnvFeeState,
  RETURN_SAME_AMOUNT_OF_ITEMS_BOUGHT,
  SET_ADMISSION_TAX_PRODUCT,
  SET_ENV_FEE_AMOUNT,
  SET_ENV_FEE_RATE,
  SHOULD_ADD_ENV_FEE,
  STORE_ENV_PRODUCT,
} from '../types';
import { calculateAdmissionTaxAmount } from '../utils';

import {
  getConfiguration,
  getEnvFeeAmount,
  getEnvFeeRate,
  getEnvProductFromShoppingCart,
  getEnvState,
  shouldAddEnvProductToCartBasedOnCode5Names,
} from './selectors';

export const checkIfReturningAllProducts = () => (dispatch, getState) => {
  const state = getState();

  const currentSaleDoc = getCurrentSalesDocument(state);
  const productsInCart = getProductsInShoppingCart(state);

  const productsReducer = (accumulator, currentValue) => {
    return accumulator + Number(currentValue.amount);
  };

  if (currentSaleDoc && currentSaleDoc.rows) {
    const totalProductsOnSalesDocument = currentSaleDoc.rows.reduce(
      productsReducer,
      0,
    );
    let counter = 0;

    productsInCart.forEach(item => {
      const isReturned = currentSaleDoc.rows.some(
        product => product.itemName === item.name,
      );

      if (isReturned) {
        counter += 1;
      }
    });

    if (counter === totalProductsOnSalesDocument) {
      return dispatch({
        type: RETURN_SAME_AMOUNT_OF_ITEMS_BOUGHT,
        payload: true,
      });
    }
  }

  return dispatch({
    type: RETURN_SAME_AMOUNT_OF_ITEMS_BOUGHT,
    payload: false,
  });
};

/**
 * Looks for the environmental product in the plugin state and if it does not find it there, it will look for the product in the DB.
 * @returns {object | boolean} Returns the environmenal fee product or a false if it does not find it.
 */
export const getEnvironmentalFeeProduct = () => async (dispatch, getState) => {
  const state = getState();

  const envFeeState: EnvFeeState = getEnvState(state);
  const currentConfig = getConfiguration(state);

  const { envFeeProduct } = envFeeState;

  // look for the product in the plugin state and check that it matches to the name in the plugin config.
  if (
    envFeeProduct &&
    envFeeProduct.name.toUpperCase() === currentConfig.envFeeName.toUpperCase()
  ) {
    return envFeeProduct;
  }

  // Look for the product in the DB and store it in plugin config.
  const { products: envProductInDB } = ((await dispatch(
    getProductsUniversal({ name: currentConfig.envFeeName }),
  )) as unknown) as {
    products: Product[];
    productsDict: Record<string | number, Product>;
    total: number;
  };

  if (envProductInDB.length === 0) {
    dispatch(addError("Missing creation of product 'Environmental fee'"));
    return false;
  }

  dispatch({ type: STORE_ENV_PRODUCT, payload: envProductInDB[0] });
  return envProductInDB[0];
};

/**
 * Looks for the Admission TAX product in the plugin state and if it does not find it there, it will look for the product in the DB.
 * @returns {object | boolean} Returns the Admission TAX product or a false if it does not find it.
 */
export const getAdmissionTAXProduct = () => async (dispatch, getState) => {
  const state = getState();

  const envFeeState: EnvFeeState = getEnvState(state);
  const currentConfig = getConfiguration(state);

  // Find product in plugin state
  const { admissionFeeProduct } = envFeeState;

  if (admissionFeeProduct) {
    return admissionFeeProduct;
  }

  // If a product is not found in plugin state the look for the product in DB.
  const { products: admissionTaxProductInDB } = ((await dispatch(
    getProductsUniversal({ name: currentConfig.admissionTaxName }),
  )) as unknown) as {
    products: Product[];
    productsDict: Record<string | number, Product>;
    total: number;
  };

  if (admissionTaxProductInDB.length === 0) {
    dispatch(addError("Missing creation of product 'admission tax'"));
    return false;
  }

  dispatch({
    type: SET_ADMISSION_TAX_PRODUCT,
    payload: admissionTaxProductInDB[0],
  });

  return admissionTaxProductInDB[0];
};

/**
 * Calculates the price amount that the Admission TAX product should receive based on the price of the Admission FEE product and will store it in plugin state. If an amount above 0 has previously been stored  as the Admission TAX product price in the plugin state, it will return that amount.
 * @returns {number} Admission TAX product price.
 */
export const defineAdmissionTaxAmount = () => async (
  dispatch,
  getstate,
): Promise<number> => {
  const state = getstate();
  const { warehouseID, pointOfSaleID, vatrate } = getSelectedPos(state);
  const customerID = getSelectedCustomerID(state);
  const clientCode = getClientCode(state);
  const sessionKey = getSessionKey(state);

  const envFeeState: EnvFeeState = getEnvState(state);

  const { admissionFeeTaxAmount } = envFeeState;

  if (admissionFeeTaxAmount > 0) {
    return admissionFeeTaxAmount;
  }

  const admissionProducts: any = await dispatch(getAdmissionFeeProducts());

  if (!admissionProducts) {
    dispatch(addError('Error while calculating admission tax amount'));
    return 0;
  }

  const finalPrice = await calculateShoppingCart({
    warehouseID,
    pointOfSaleID,
    sessionKey,
    clientCode,
    customerID,
    productID1: admissionProducts[0].productID,
    amount1: 1,
  });

  const calculatedAdmissionTaxAmount = calculateAdmissionTaxAmount(
    finalPrice,
    vatrate,
  );

  dispatch({
    type: ADMISSION_FEE_TAX_AMOUNT,
    payload: calculatedAdmissionTaxAmount,
  });

  return calculatedAdmissionTaxAmount;
};

export const addEnvFeeProductToCart = () => async (dispatch, getState) => {
  const state = getState();

  const shouldAddEnvProductToCart = shouldAddEnvProductToCartBasedOnCode5Names(
    state,
  );
  const isReturn = getIsAReturn(state);
  const environmentalFeeProductInCart = getEnvProductFromShoppingCart(state);
  const rateToApply = getEnvFeeRate(state);

  if (typeof rateToApply !== 'number') {
    return dispatch(addError('Environmental fee rate is missing in BO.'));
  }

  dispatch({ type: SET_ENV_FEE_RATE, payload: rateToApply });

  let envFeeAmount = 0;

  if (rateToApply >= 0) {
    envFeeAmount = getEnvFeeAmount(rateToApply)(state);
  }

  if (shouldAddEnvProductToCart && !isReturn) {
    dispatch({
      type: SET_ENV_FEE_AMOUNT,
      payload: envFeeAmount,
    });
    dispatch({ type: SHOULD_ADD_ENV_FEE, payload: envFeeAmount > 0 });

    if (!environmentalFeeProductInCart) {
      const envProductInDB = await dispatch(getEnvironmentalFeeProduct());

      if (!envProductInDB) {
        dispatch({ type: SHOULD_ADD_ENV_FEE, payload: false });
        return dispatch(
          addError("Missing creation of product 'Environmental fee'"),
        );
      }

      // Environmental fee product needs to be added by passing the productID and price so that the addProduct action adds the current location tax rate to the environmental fee product.
      return dispatch(
        addProduct({
          productID: envProductInDB.productID,
          price: envFeeAmount,
        }),
      );
    }

    if (environmentalFeeProductInCart) {
      return dispatch(
        updateOrderPrice(
          envFeeAmount,
          envFeeAmount,
          environmentalFeeProductInCart.orderIndex,
        ),
      );
    }
  }

  if (!shouldAddEnvProductToCart && environmentalFeeProductInCart) {
    dispatch(removeProduct(environmentalFeeProductInCart.orderIndex));
  }

  /* Specific logic for unreferenced returns */
  if (isReturn && environmentalFeeProductInCart) {
    dispatch({
      type: SET_ENV_FEE_AMOUNT,
      payload: envFeeAmount,
    });
    dispatch({ type: SHOULD_ADD_ENV_FEE, payload: Math.abs(envFeeAmount) > 0 });
    await dispatch(
      updateOrderAmount(-1, environmentalFeeProductInCart.orderIndex),
    );
    await dispatch(
      updateOrderPrice(
        Math.abs(envFeeAmount),
        Math.abs(envFeeAmount),
        environmentalFeeProductInCart.orderIndex,
      ),
    );
  }
  /** End of unreferenced return specific logic */
};

export const resetEnvFeeState = () => dispatch => {
  dispatch({
    type: SET_ENV_FEE_AMOUNT,
    payload: 0,
  });
  dispatch({ type: SHOULD_ADD_ENV_FEE, payload: false });
};

/** Finds duplicated products in the shopping cart and removes them from the shopping cart.
 * @param {arrayOf(Object)} productsInCart Products in the shopping cart.
 * @param {string} productToSearch Product name that should be used as reference to find duplicated instances of the product.
 * @returns {void}
 */
export const removeDuplicatedAdmissionProductFromCart = () => async (
  dispatch,
  getState,
) => {
  const admissionTaxProductsInCart: any[] = [];
  const productsInCart = getProductsInShoppingCart(getState());
  const { admissionTaxName } = getConfiguration(getState());
  productsInCart.forEach(product => {
    if (
      product.name &&
      product.name.toUpperCase() === admissionTaxName.toUpperCase()
    ) {
      admissionTaxProductsInCart.push(product);
    }
  });

  if (admissionTaxProductsInCart.length > 1) {
    admissionTaxProductsInCart.shift();
    admissionTaxProductsInCart.forEach(product => {
      dispatch(removeProduct(product.orderIndex));
    });
  }
};

/**
 * Looks for the Admission FEE products in the DB.
 * @returns {array | boolean} Returns the Admission fee products or a false if it does not find any product.
 */
export const getAdmissionFeeProducts = () => async (dispatch, getState) => {
  const state = getState();
  const currentConfig = getConfiguration(state);

  const admissionFeeNameOptions = currentConfig.admissionFeeName
    ? currentConfig.admissionFeeName
        .split(',')
        .filter(value => value !== ' ')
        .map(name => name.trim())
    : [];

  const admissionFeeProducts = await Promise.all(
    admissionFeeNameOptions.map(option =>
      dispatch(getProductsUniversal({ searchNameIncrementally: option })),
    ),
  ).then(admissionProducts => {
    const finalProducts: any[] = [];
    admissionProducts.forEach(({ products }) => {
      products.forEach(product => {
        const duplicatedProduct = finalProducts.find(
          prod => prod.name === product.name,
        );

        if (!duplicatedProduct) {
          finalProducts.push(product);
        }
      });
    });

    return finalProducts;
  });

  if (admissionFeeProducts.length === 0) {
    dispatch(addError('Missing creation of admission fee products'));
    return false;
  }

  return admissionFeeProducts;
};
