import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getIsThemeSet } from 'reducers/UI/theme';
import { setColorTheme } from 'actions/UI';

const highContrastMediaQuery = window.matchMedia('(prefers-contrast: more)');
const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
const printMediaQuery = window.matchMedia('print');

function getPreferredTheme() {
  if (highContrastMediaQuery.matches) return 'highContrast';
  return darkMediaQuery.matches ? 'dark' : null;
}

function updateTheme(dispatch) {
  const isThemeSetExplicitly = getIsThemeSet();
  if (isThemeSetExplicitly) return;
  // Browser changes prefers-color-scheme media query to 'light' (or at least not 'dark') when media is print
  if (printMediaQuery.matches) return;
  const preferredTheme = getPreferredTheme();
  dispatch(setColorTheme(preferredTheme, false));
}

function useUserPreferredTheme() {
  const dispatch = useDispatch();

  // Switch theme when POS is opened
  useEffect(() => {
    dispatch(updateTheme);
  }, [dispatch]);

  // Switch theme when media query changes
  useEffect(() => {
    function listener() {
      dispatch(updateTheme);
    }
    highContrastMediaQuery.addEventListener('change', listener);
    darkMediaQuery.addEventListener('change', listener);

    return () => {
      highContrastMediaQuery.removeEventListener('change', listener);
      darkMediaQuery.removeEventListener('change', listener);
    };
  }, [dispatch]);
}

export default useUserPreferredTheme;
