/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import i18next from 'i18next';

import {
  getSelectedCustomer,
  getAddressTypes as getAddressTypesSelector,
  getSelectedCustomerAddresses,
} from 'reducers/customerSearch';
import { getSelectedPos } from 'reducers/PointsOfSale';
import * as api from 'services/ErplyAPI/customers';
import * as customerActionsTypes from 'constants/customerSearch';
import { ErplyAttributes } from 'utils';
import { addError } from 'actions/Error';
import { getCurrentPosLanguage, getSetting } from 'reducers/configs/settings';
import { getPluginLifecycleHook } from 'reducers/Plugins';

import { withProgressAlert } from './actionUtils';
import { getOneCustomer } from './CustomerSearch/getOneCustomer';
import { setCustomer } from './CustomerSearch/setCustomer';

export function deselectCustomer() {
  return {
    type: customerActionsTypes.RESET_CUSTOMER,
  };
}

export function getDefaultCustomer() {
  return async (dispatch, getState) => {
    dispatch({ type: customerActionsTypes.GET_DEFAULT_CUSTOMER.START });
    try {
      const { pointOfSaleID } = getSelectedPos(getState());
      const [data] = await api.getDefaultCustomer({ pointOfSaleID });
      if (data && data.id) {
        const result = await api.getCustomers({
          customerID: data.id,
          getAddresses: 1,
          getBalanceInfo: 1,
          getBalanceWithoutPrepayments: 1,
        });
        const [customer] = result;
        dispatch({
          type: customerActionsTypes.GET_DEFAULT_CUSTOMER.SUCCESS,
          data: customer,
        });
      } else {
        dispatch(addError('No default customer configured'));
        dispatch({
          type: customerActionsTypes.GET_DEFAULT_CUSTOMER.SUCCESS,
          data: {},
        });
      }
    } catch (err) {
      console.error('Unable to load default customer', err);
      dispatch({
        type: customerActionsTypes.GET_DEFAULT_CUSTOMER.ERROR,
        data: err,
      });
    }
  };
}
export function selectOneCustomer(props) {
  return async (dispatch, getState) => {
    const { customerID, searchCode = '' } = props;
    dispatch({ type: customerActionsTypes.SET_ONE_CUSTOMER });
    const { before, on, after } = getPluginLifecycleHook('onSelectOneCustomer')(
      getState(),
    );
    try {
      await dispatch(before(props));
      const { customer, points } = await getOneCustomer(customerID, searchCode);

      const allowInvoicesForContactPersons = getSetting(
        'allowCreateInvForContactPerson',
      )(getState());
      if (!!customer.companyID && !allowInvoicesForContactPersons) {
        dispatch(
          addError(
            i18next.t('alerts:customers.invoicesForContactPersonNotAllowed'),
          ),
        );
        return;
      }

      const payload = await dispatch(
        on(props, { ...customer, rewardPoints: points }),
      );
      dispatch({
        type: customerActionsTypes.SET_ONE_CUSTOMER_SUCCESS,
        data: payload,
      });
      await dispatch(after(props, payload));
    } catch (e) {
      console.error('Unable to select customer', props, e);
      dispatch({ type: customerActionsTypes.SET_ONE_CUSTOMER_ERROR, data: e });
    }
  };
}

export function getCustomerStoreCredit(customerID) {
  return async dispatch => {
    dispatch({
      type: customerActionsTypes.GET_CUSTOMER_STORE_CREDIT,
      payload: {},
    });
    try {
      const [{ availableCredit }] = await api.getCustomers({
        customerID,
        getBalanceInfo: 1,
      });
      dispatch({
        type: customerActionsTypes.GET_CUSTOMER_STORE_CREDIT_SUCCESS,
        payload: availableCredit,
      });
    } catch (e) {
      console.error('Unable to load store credit for customer', customerID, e);
      dispatch({
        type: customerActionsTypes.GET_CUSTOMER_STORE_CREDIT_SUCCESS,
        payload: 0,
      });
    }
  };
}

export function getCustomerGroups() {
  return async dispatch => {
    dispatch({ type: customerActionsTypes.GET_CUSTOMER_GROUPS });
    try {
      const data = await api.getCustomerGroups();
      dispatch({ type: customerActionsTypes.GET_CUSTOMER_GROUPS_SUCCESS, data });
      return data;
    } catch (err) {
      console.error('Unable to load customer groups', err);
      dispatch({
        type: customerActionsTypes.GET_CUSTOMER_GROUPS_ERROR,
        data: err,
      });
      return [];
    }
  };
}

export function fetchAddressTypes() {
  return async (dispatch, getState) => {
    dispatch({ type: customerActionsTypes.GET_ADDRESS_TYPES_START });
    try {
      const lang = getCurrentPosLanguage(getState());
      const data = await api.getAddressTypes({ lang });
      dispatch({ type: customerActionsTypes.GET_ADDRESS_TYPES_SUCCESS, data });
    } catch (err) {
      console.error('Unable to load address types', err);
      dispatch({
        type: customerActionsTypes.GET_ADDRESS_TYPES_ERROR,
        data: err,
      });
    }
  };
}

export function fetchPersonTitles() {
  return async (dispatch, getState) => {
    dispatch({ type: customerActionsTypes.GET_PERSON_TITLES_START });
    try {
      const lang = getCurrentPosLanguage(getState());
      const data = await api.getPersonTitles({ lang });
      dispatch({ type: customerActionsTypes.GET_PERSON_TITLES_SUCCESS, data });
    } catch (err) {
      console.error('Unable to load person titles (for customers)', err);
      dispatch({
        type: customerActionsTypes.GET_PERSON_TITLES_ERROR,
        data: err,
      });
    }
  };
}

export function fetchJobTitles() {
  return async (dispatch, getState) => {
    dispatch({ type: customerActionsTypes.GET_JOB_TITLES_START });
    try {
      const lang = getCurrentPosLanguage(getState());
      const data = await api.getJobTitles({ lang });
      dispatch({ type: customerActionsTypes.GET_JOB_TITLES_SUCCESS, data });
    } catch (err) {
      console.error('Unable to load job titles (for employees)', err);
      dispatch({
        type: customerActionsTypes.GET_JOB_TITLES_ERROR,
        data: err,
      });
    }
  };
}

export function createCustomer(props) {
  return async dispatch => {
    dispatch({ type: customerActionsTypes.SAVE_CUSTOMER });
    try {
      const response = await api.saveCustomer(props);
      dispatch({ type: customerActionsTypes.SAVE_CUSTOMER_SUCCESS });
      return response;
    } catch (err) {
      dispatch({ type: customerActionsTypes.SAVE_CUSTOMER_ERROR, data: err });
      throw err;
    }
  };
}

export function saveAddress(address) {
  return async dispatch => {
    dispatch({ type: customerActionsTypes.SAVE_ADDRESS });
    try {
      await api.saveAddress(address);
      dispatch({ type: customerActionsTypes.SAVE_ADDRESS_SUCCESS });
    } catch (err) {
      dispatch({ type: customerActionsTypes.SAVE_ADDRESS_SUCCESS, data: err });
      throw new Error('Unable to save address', { cause: err });
    }
  };
}

export function deleteAddress(addressID) {
  return async dispatch => {
    dispatch({ type: customerActionsTypes.DELETE_ADDRESS });
    try {
      await api.deleteAddress({ addressID });
      dispatch({
        type: customerActionsTypes.DELETE_ADDRESS_SUCCESS,
        data: addressID,
      });
    } catch (err) {
      dispatch({ type: customerActionsTypes.DELETE_ADDRESS_ERROR, data: err });
      throw new Error('Unable to delete address', { cause: err });
    }
  };
}

export function updateAddresses(saveAddresses, deleteAddresses) {
  return async (
    dispatch,
    getState,
  ) => {
    try {
      await Promise.all([
        ...saveAddresses.map(address => dispatch(saveAddress(address))),
        ...deleteAddresses.map(address =>
          dispatch(deleteAddress(address.addressID)),
        ),
      ]);
    } catch (err) {
      console.error('Error while bulk editing multiple addresses', err);
      dispatch(addError(i18next.t('alerts:errors.failedBulkAddressUpdate')));
    }
    const { customerID } = getSelectedCustomer(getState());
    await dispatch(selectOneCustomer({ customerID }));
    await dispatch(setCustomer({ data: getSelectedCustomer(getState()) }));
    return getSelectedCustomerAddresses(getState()) ?? [];
  };
}

function saveCustomerBase(props, callback) {
  return async (dispatch, getState) => {
    const state = getState();
    const { before, on, after } = getPluginLifecycleHook('onSaveCustomer')(state);

    try {
      dispatch(before(props));

      /* companyTypeID is present to avoid duplicates when editing existing
        customers (companies) with an already specified companyType
      */
      const {
        // newAddress,
        addressTypeID,
        companyTypeID,
        addresses,
        attributes,
        ...rest
      } = props;

      const abort = Symbol('abort');
      const data = await dispatch(
        on(props, { params: rest, attributes, addresses }),
      ).catch(() => abort);
      if (data === abort) return;

      const response = await dispatch(
        createCustomer({
          ...data.params,
          ...new ErplyAttributes(data.attributes).asFlatArray,
        }),
      );

      const { customerID } = response[0];

      if (data.addresses?.[0]) {
        const [{ id: defaultAddressType }] = getAddressTypesSelector(getState());
        await dispatch(
          saveAddress({
            ...addresses[0],
            ownerID: customerID,
            typeID: (addresses[0].typeID ?? addressTypeID) || defaultAddressType,
          }),
        );
      }
      await dispatch(after(props, customerID));
      await dispatch(selectOneCustomer({ customerID }));
      await dispatch(setCustomer({ data: getSelectedCustomer(getState()) }));
      if (callback) callback();
    } catch (err) {
      dispatch(addError(err.message));
      console.error('Error while saving customer', err);
    }
  };
}

export const saveCustomer = withProgressAlert('alerts:createCustomer.saving')(
  saveCustomerBase,
);
