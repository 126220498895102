import { batch } from 'react-redux';
import i18next from 'i18next';

import { addWarning, addError, dismissAll } from 'actions/Error';
import {
  checkIfBlockedExchange,
  waitForCartCalculationToFinish,
} from 'actions/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { resetShoppingCart } from 'actions/ShoppingCart';
import { saveSalesDocument } from 'actions/sales';
import { modalPages } from 'constants/modalPage';
import { getSelectedPos } from 'reducers/PointsOfSale';
import {
  getProductsInShoppingCart,
  getSelectedOffersSelector,
} from 'reducers/ShoppingCart';
import {
  getCurrencyCode,
  getIsModuleEnabled,
  getSetting,
} from 'reducers/configs/settings';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getLoggedInEmployeeID } from 'reducers/Login';
import * as api from 'services/ErplyAPI/sales';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getIsAReturn } from 'reducers/sales';

export function saveAsOffer() {
  return async (dispatch, getState) => {
    const state = getState();
    const shoppingCartItems = getProductsInShoppingCart(state);
    const currentEmployee = getLoggedInEmployeeID(state);

    if (!shoppingCartItems.length) {
      return dispatch(
        addWarning(i18next.t('alerts:shoppingCartEmpty'), {
          dismissible: false,
          selfDismiss: true,
        }),
      );
    }

    try {
      await dispatch(waitForCartCalculationToFinish());
    } catch (error) {
      return null; // Timed out
    }

    const isBlockedExchange = await dispatch(checkIfBlockedExchange());
    if (isBlockedExchange) return null;

    const hasPositive = shoppingCartItems.some(o => o.amount > 0);
    const hasNegative = shoppingCartItems.some(o => o.amount < 0);
    const isAReturn = getIsAReturn(getState());
    const isANegativeReturn = isAReturn && hasNegative && !hasPositive;
    // allows exchanges and show errors on negative returns
    if (isANegativeReturn) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.returnSaveError', {
            context: 'offer',
          }),
          {
            selfDismiss: true,
            dismissible: false,
          },
        ),
      );
    }

    const isMultiCurrencyEnabled = getIsModuleEnabled('pos_multicurrency')(
      getState(),
    );

    const currencyCode = isMultiCurrencyEnabled
      ? await dispatch(
          openModalPage({
            component: modalPages.currencySelection,
            isPopup: true,
          }),
        )
      : getCurrencyCode(state);

    const requestParams = {
      currencyCode,
      customerID: getSelectedCustomerID(state),
      warehouseID: getSelectedPos(state).warehouseID,
      pointOfSaleID: getSelectedPos(state).pointOfSaleID,
      confirmed: 1,
      type: 'OFFER',
      employeeID: currentEmployee,
      ...shoppingCartItems,
    };

    try {
      dispatch(
        addWarning(i18next.t('alerts:saving.offer'), { dismissible: false }),
      );
      await dispatch(saveSalesDocument({ salesDocument: requestParams }));
      batch(() => {
        dispatch(resetShoppingCart());
        dispatch(dismissAll());
      });
    } catch (error) {
      batch(() => {
        dispatch(dismissAll());
        dispatch(
          addError(i18next.t('alerts:saving.offer_error'), {
            selfDismiss: true,
          }),
        );
      });
    }

    return true;
  };
}

export function fetchOffers() {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(
      addWarning(i18next.t('alerts:loading.offer'), { dismissible: false }),
    );

    const onlyCurrentLoc = getSetting(
      'touchpos_offers_only_from_current_location',
    )(state);

    const currentWarehouseID = getSelectedWarehouseID(state);

    const requestParams = {
      warehouseID: onlyCurrentLoc ? currentWarehouseID : undefined,
      type: 'OFFER',
      confirmed: 1,
      getRowsForAllInvoices: 1,
      getUnfulfilledDocuments: 1,
      recordsOnPage: 100,
    };

    try {
      const offers = await api.getSalesDocuments(requestParams);
      batch(() => {
        dispatch(
          openModalPage({
            component: modalPages.offers,
            props: { fetchedOffers: offers },
          }),
        );
        dispatch(dismissAll());
      });
    } catch (err) {
      batch(() => {
        dispatch(dismissAll());
        dispatch(
          addError(i18next.t('alerts:loading.offer_error'), {
            selfDismiss: true,
          }),
        );
      });
    }
  };
}

export function deletePaidOffers() {
  return async (dispatch, getState) => {
    const state = getState();
    const selectedOffers = getSelectedOffersSelector(state);

    selectedOffers.forEach(offerID => {
      return api.deleteSalesDocument(offerID);
    });
  };
}
