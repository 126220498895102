import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';
import { setPayment } from 'actions/Payments/setPayment';
import { getPayments } from 'reducers/Payments';
import { getPluginConfiguration } from 'reducers/Plugins';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { RootState } from 'reducers';

import { ComponentConfiguration } from './components';

const constants = {
  id: 'gift-card-to-payment-types',
  name: 'Giftcard types to Payment types',
};

const giftCardToPaymentTypes: PosPlugin = {
  id: constants.id,
  name: constants.name,
  info:
    'This plugin is used to link giftcard types to payment types. When a giftcard type is used for payment, the type of the payment will be changed from GIFTCARD to the configured mapping payment type',
  keywords: ['pnp', 'giftcard', 'types', 'payment'],
  ComponentConfiguration,
  ComponentHeader: ({ children }) => {
    const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
    const configuration = useSelector(
      getPluginConfiguration<Record<string, number>>(constants.id),
    );
    const payments = useSelector(getPayments);
    useEffect(() => {
      const newPayments = Object.entries(payments)
        .filter(
          ([k, p]: any) =>
            configuration?.[p?.giftCardTypeID] &&
            String(p.typeID) !== String(configuration?.[p?.giftCardTypeID]),
        )
        .map(([k, p]: any) => {
          const newP = {
            ...p,
            key: k,
            typeID: configuration?.[p?.giftCardTypeID],
          };
          return newP;
        });
      if (newPayments.length) {
        dispatch(setPayment(newPayments[0])).then(() => {
          dispatch(setPaymentSelected(''));
        });
      }
    }, [configuration, dispatch, payments]);
    return children;
  },
  onSaveSalesDocument: {
    on: (p, requests) => async (dispatch, getState) => {
      const configuration = getPluginConfiguration<Record<string, number>>(
        constants.id,
      )(getState());
      if (!configuration) return requests;

      return R.map(
        R.when(
          (r: any) =>
            r.requestName === 'savePayment' &&
            !!configuration?.[r?.giftCardTypeID],
          R.dissoc('giftCardVatRateID'),
        ),
      )(requests);
    },
  },
};

export default giftCardToPaymentTypes;
