import { doClientRequest } from './core/ErplyAPI';

export const clockIn = params =>
  doClientRequest({ ...params, request: 'clockIn' });

export const clockOut = params =>
  doClientRequest({ ...params, request: 'clockOut' });

export const clockedInEmployees = params =>
  doClientRequest({ ...params, request: 'getClockedInEmployees' });

export const clockInOutRecords = params =>
  doClientRequest({ ...params, request: 'getClockIns' });
