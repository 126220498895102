import { createSelector } from 'reselect';

import * as c from 'constants/productUnitsDB';

import { getSetting } from './configs/settings';

export default (state = [], { type, payload }) => {
  switch (type) {
    case c.SET_PRODUCTUNITS:
      return payload;
    default:
      return state;
  }
};

export function getProductUnits(state) {
  return state.productUnitsDB;
}

// todo migrate to PIM API for manipulations with product units, request update on API level to determine what units should require unit prompt
export const getWeightedProductUnits = createSelector(
  getSetting('units_for_prompt'),
  // When loading to API, the Boolean filter is already applied - but it's not applied when set from the settings screen
  // bceause if it was, the user would not be able to type a trailing comma
  // In the future, we can implement a better input component that doesn't have this problem and then this selector will be pointless
  units => units.filter(Boolean),
);
