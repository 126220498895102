import React, { ReactNode } from 'react';

import { PosPlugin } from 'plugins/plugin';

const OriginalPayment: PosPlugin['UIOriginalPayment'] = ({
  payment,
  onClick,
  children,
}) => {
  return React.Children.map<ReactNode, ReactNode>(children, child => {
    if (!React.isValidElement(child)) return child;

    const disabled =
      payment.type === 'GIFTCARD' && payment.cardNumber?.length
        ? true
        : child.props.disabled;
    return React.cloneElement(child, {
      disabled,
      onClick: !disabled ? onClick : undefined,
      style: { cursor: disabled ? 'not-allowed' : 'pointer' },
    });
  });
};

export default OriginalPayment;
