type Function = {
  actionOnClick: () => (dispatch, getState) => Promise<any>;
  text: string;
  name: string;
  variant: string;
};

export const wrapperFunctionButtons = {
  functions: [] as Function[],
  set(newArr: Function[]) {
    this.functions.splice(0, Infinity);
    newArr.forEach(item => this.functions.push(item));
  },
  remove(buttons: Function[]) {
    this.set(this.functions.filter(b => buttons.includes(b)));
  },
  add(buttons: Function[]) {
    buttons.forEach(btn => {
      if (!this.functions.includes(btn)) {
        this.functions.push(btn);
      }
    });
  },
};
