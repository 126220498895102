import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, MenuItem } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';
import { addError, addSuccess } from 'actions/Error';
import { getGiftcardTypes } from 'reducers/giftcards';

import { Configuration } from '.';

const Config: Required<
  PosPlugin
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const dispatch = useDispatch();
  const giftCardTypes = useSelector(getGiftcardTypes);

  const [value, setValue] = useState<Configuration | undefined>(current);

  const saveConfig = () => {
    if (!value || !Object.keys(value).length) {
      dispatch(addError('Configuration is empty'));
      return;
    }
    save(value);
    dispatch(addSuccess('Configuration saved'));
  };

  return (
    <>
      <TextField
        select
        fullWidth
        margin="normal"
        variant="outlined"
        label="Gift card type"
        value={value?.giftCardTypeID ?? 0}
        onChange={e =>
          setValue(prev => ({
            ...prev,
            giftCardTypeID: Number(e.target.value),
          }))
        }
      >
        <MenuItem key={0} value={0}>
          <em>None</em>
        </MenuItem>
        {giftCardTypes.map(gcType => (
          <MenuItem key={gcType.id} value={gcType.id}>
            {gcType.nameEN}
          </MenuItem>
        ))}
      </TextField>
      <Button variant="contained" color="primary" onClick={saveConfig}>
        Save
      </Button>
    </>
  );
};

export default Config;
