import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { SaveButton } from 'components/UIElements/UIButton';
import { PrintingMSConf } from 'containers/Forms/Settings/views/Printing/GoMsSettings';
import { WrapperSettings } from 'containers/Forms/Settings/views/Printing/WrapperSettings';
import {
  getCafaEntry,
  getGeneralPrintingSettings,
  getIsLoadingCafa,
} from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import { addWarning, dismissType } from 'actions/Error';
import { PrintingBaseSettings } from 'containers/Forms/Settings/views/Printing/PrintingBaseSettings';
import { posSendMessage } from 'utils/hooks/useWrapper';
import Loader from 'components/Loader';

/**
 * @returns {({name: string, Settings: React.FC, types: any[], config: {enabled:boolean, config: any}})[]}
 */
const useIntegrations = () => {
  const ints = [
    {
      name: 'wrapper',
      Settings: WrapperSettings,
    },
    {
      name: 'goMS',
      Settings: PrintingMSConf,
    },
    {
      name: 'browser',
      Settings: null, // TODO: Select between BO and PatchScript
    },
  ];
  const values = ints.map(
    ({ name }) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSelector(getCafaEntry(name, INTEGRATION_TYPES.printer))?.value,
  );
  return useMemo(
    () =>
      ints.map((int, i) => ({
        ...int,
        config: values[i] ?? {},
      })),
    [ints, ...values],
  );
};

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsPrinter');
  const loadingCafa = useSelector(getIsLoadingCafa);

  const [changed, setChanged] = useState({});
  const integrations = useIntegrations();
  const onClose = () => {
    posSendMessage(
      'config:setPrintingConfig',
      integrations.find(int => int.name === 'wrapper').config,
    );
    dispatch(previousModalPage());
  };
  const currentBaseConfig = useSelector(getGeneralPrintingSettings);
  const [base, setBase] = useState(currentBaseConfig);

  const onSave = () => {
    const savingIntegrations = Object.entries(changed).map(([key, value]) =>
      dispatch(
        saveCafaConfig({
          integrationName: key,
          integrationType: INTEGRATION_TYPES.printer,
          config: value,
        }),
      ),
    );
    const savingGeneral =
      base === currentBaseConfig
        ? Promise.resolve()
        : dispatch(
            saveCafaConfig({
              integrationName: 'printing',
              config: base,
            }),
          );
    dispatch(
      addWarning('Saving printing configurations...', {
        errorType: 'saving_print_config',
        selfDismiss: false,
        dismissible: false,
      }),
    )
      .then(() => Promise.all(savingIntegrations.concat(savingGeneral)))
      .finally(() => dispatch(dismissType('saving_print_config')));
  };

  return (
    <>
      <Modal.Header>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('title')}
        </span>

        <span style={{ flexGrow: 1 }} />
        <SaveButton action={onSave} variant="POS" />
        <CloseButton action={onClose} />
      </Modal.Header>
      {/* prettier-ignore */}
      <Modal.Body>
        <h2>{t('general.title')}</h2>
        <PrintingBaseSettings value={base} onChange={setBase} />
        <h2>{t('integrations.title')}</h2>
        <Loader show={loadingCafa} block>
          {integrations.map((int) => {
            const { name, config, Settings } = int;
            const lens = R.lensPath([name, 'enabled']);
            const enabled = R.view(lens, changed) ?? config?.enabled;
            const toggle = R.set(lens, !enabled)

            return (
              <Accordion expanded={Settings ? undefined : false}>
                <AccordionSummary expandIcon={Settings && <ExpandMoreIcon />}>
                  <FormControlLabel
                    label={t(`integrations.${name}`)}
                    onClick={e => e.stopPropagation()}
                    onFocus={e => e.stopPropagation()}
                    control={<Checkbox
                      checked={enabled}
                      onChange={(e) => {
                        e.stopPropagation()
                        setChanged(curr => toggle(curr))
                      }}
                    />
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {Settings && <Settings
                    value={R.mergeDeepLeft(changed[name]?.config ?? {}, config.config ?? {})}
                    onChange={value =>
                      setChanged(curr => ({
                        ...curr,
                        [name]: { ...config, config: value },
                      }))
                    }
                  />}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Loader>
      </Modal.Body>
    </>
  );
};
