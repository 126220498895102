import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import { useBreakpoints, useSize } from 'utils/hooks/UI';
import UIButton from 'components/UIElements/UIButton';

const LoggingValueRow = ({ name, value, update }) => {
  const [state, setState] = useState(value);
  useEffect(() => {
    if (value !== state) {
      setState(value);
      update({ name, time: new Date(), oldVal: state, newVal: value });
    }
  }, [value]);
  return (
    <tr>
      <td>{name}</td>
      <td>{value}</td>
    </tr>
  );
};

const SizeDebug = () => {
  const [log, setLog] = useState<
    {
      oldVal: number;
      newVal: number;
      time: Date;
      name: string;
    }[]
  >([]);
  const addToLog = name => (newVal, oldVal) =>
    setLog(l => l.concat({ oldVal, newVal, time: new Date(), name }));
  const { height, width } = useSize();
  const {lg, mdplus, md, sm, xs} = useBreakpoints();
  const values = {
    useBreakpointLG: lg,
    useBreakpointMDPLUS: mdplus,
    useBreakpointMD: md,
    useBreakpointSM: sm,
    useBreakpointXS: xs,
    useSizeWidth: width,
    docClientWidth: document.documentElement.clientWidth,
    docOffsetWidth: document.documentElement.offsetWidth,
    docScrollWidth: document.documentElement.scrollWidth,
    winInnerWidth: window.innerWidth,
    winOuterWidth: window.outerWidth,
    scrWidth: window.screen.width,
    scrAvailWidth: window.screen.availWidth,
    bdyClientWidth: document.body.clientWidth,
    bdyOffsetWidth: document.body.offsetWidth,
    bdyScrollWidth: document.body.scrollWidth,
  };

  const [enabled, setEnabled] = useState(false);
  if (!enabled) {
    return <UIButton text={'Start debugging size detection'} action={() => setEnabled(true)} />
  }
  return (
    <>
      <Table>
        {Object.entries(values).map(([k, v]) => (
          <LoggingValueRow
            value={v}
            name={k}
            update={line => setLog(l => l.concat(line))}
          />
        ))}
      </Table>

      <ul>
        {log.map(l => (
          <li>
            [{l.time.getHours()}:{l.time.getMinutes()}:{l.time.getSeconds()}.{l.time.getMilliseconds()}]: {l.name} updated {l.oldVal}→{l.newVal}
          </li>
        ))}
      </ul>
    </>
  );
};
export default SizeDebug;
