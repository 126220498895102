import { getCafaEntry } from 'reducers/cafaConfigs';
import fetchSplitsConfigByID, { setMarketplaceSPS } from 'actions/splits';
import { INTEGRATION_TYPES } from 'constants/CAFA.ts';

export default () => async (dispatch, getState) => {
  const { value: adyenConfig } = getCafaEntry(
    'adyen',
    INTEGRATION_TYPES.payment,
  )(getState());

  const getSPSs = async (id, acc) => {
    const configs = await dispatch(fetchSplitsConfigByID(id));
    if (configs) {
      return dispatch(setMarketplaceSPS(configs[acc]));
    }
    return false;
  };

  if (adyenConfig) {
    const {
      enableMarketplace,
      marketplaceID,
      marketplaceAccount,
    } = adyenConfig;

    if (enableMarketplace === 1 && marketplaceID && marketplaceAccount) {
      getSPSs(marketplaceID, marketplaceAccount);
    }
  }
};
