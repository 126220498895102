import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { createSelector } from 'reselect';

import { SO } from 'services/DB/types';
import { Employee } from 'types/Employee';
import { trimComma } from 'utils';

import { getUserLoggedIn } from '../Login';
import {
  getEmployeeIdentifierOnReceipt,
  getHideEmployeesFromOtherLocation,
} from '../configs/settings';
import { getSelectedWarehouseID } from '../warehouses';

import { getCachedItemsPerType } from './index';

export function getEmployees(state): Record<number | string, Employee> {
  return getCachedItemsPerType(SO.EMPLOYEES.NAME)(state);
}

export const getEmployeesRespectingLocationOption = createSelector(
  state => getSelectedWarehouseID(state),
  state => getHideEmployeesFromOtherLocation(state),
  state => getEmployees(state),
  (warehouseID, shouldFilterEmployeesByWarehouse, allEmployeesDict) => {
    const employees = Object.values(allEmployeesDict);
    if (shouldFilterEmployeesByWarehouse) {
      return employees.filter(e =>
        e.warehouses.some(wh => String(wh.id) === String(warehouseID)),
      );
    }
    return employees;
  },
);

export function getEmployeeById(id) {
  return state => getEmployees(state)[id];
}

export const getCommissionable = createSelector(
  getEmployeesRespectingLocationOption,
  getUserLoggedIn,
  (employees, loggedInUser) =>
    Object.values(employees).filter(
      employee => employee.userGroupID === loggedInUser.groupID,
    ),
);

export const getOtherCommissionable = createSelector(
  getEmployeesRespectingLocationOption,
  getUserLoggedIn,
  (employees, loggedInUser) =>
    Object.values(employees).filter(
      employee => employee.userGroupID !== loggedInUser.groupID,
    ),
);

/**
 * Gives the employee identifier as it has been confiured in BO -> Settings -> Configuration -> "Employee identifier on receipt"
 * @param employeeID
 */
export function getEmployeeIdentifier(employeeID: string | number) {
  return (
    state,
  ): string => {
    const receiptNameOption = getEmployeeIdentifierOnReceipt(state);
    const employee = getEmployeeById(employeeID)(state);
    if (!employee) {
      console.error('Failed to find employee by id', employeeID);
      return '';
    }

    switch (receiptNameOption) {
      case 'first_name':
        return trimComma(`${employee.firstName}`);
      case 'id':
        return trimComma(`${employee.id}`);
      case 'first_name_and_initial':
        return trimComma(
          `${employee.firstName}, ${employee.lastName?.charAt(0)}`,
        );
      default: // if nothing selected on BO, default to fullName
      case 'full_name':
        return trimComma(`${employee.fullName}`);
    }
  };
}
