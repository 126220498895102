import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';

import { getCFRState } from '../rdx';

const UIExchangeModalTitle: PosPlugin['UIExchangeModalTitle'] = ({
  children,
}) => {
  const { isOnlineReturn } = useSelector(getCFRState);

  if (!isOnlineReturn) return children;

  return (
    <Box fontSize="1.75rem" fontWeight={600} data-testid="return-title">
      Online Return
    </Box>
  );
};

export default UIExchangeModalTitle;
