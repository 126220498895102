import { getPluginSelector } from 'reducers/Plugins';

import { State } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getTagsByProductId = id =>
  getPluginSelector<State>('pnp-tag-returned-products')(
    state => state[id] ?? [],
  );

export const getTagsSlice = () =>
  getPluginSelector<State>('pnp-tag-returned-products')(state => state);
