import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import NumberPad from 'components/Keyboards/NumberPad';
import UIButton from 'components/UIElements/UIButton';
import {
  float,
  integer,
  positive,
  toFixed,
} from 'components/FieldTypes/formatters';
import { getDayCountedByDrawer } from 'reducers/configs/settings';
import CloseButton from 'components/CustomButtons/CloseButton';
import QuestionMark from 'components/QuestionMark';
import { getCurrency } from 'reducers/configs/currency';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getUserLoggedIn } from 'reducers/Login';
import { PluginComponent } from 'plugins';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { useBreakpoints } from 'utils/hooks/UI';
import { getShowDeposit, getShowExpected } from 'reducers/OpenCloseDay';

import { largeInputAdornment, useOCDInputStyles } from '../components/OCDInput';
import ActiveCurrencySelect from '../components/ActiveCurrencySelect';

interface Props {
  bills: { value: number; mark: string; amount: string }[];
  onChangeBill: (banknote: string, value: string) => void;
  counted: string;
  countedComputed: string;
  updateCounted: (value: string) => void;
  register: string;
  registerComputed: string;
  updateRegister: (value: string | null) => void;
  deposit: string;
  depositComputed: string;
  updateDeposit: (value: string | null) => void;
  expectedAmount: string;
  onClickTenders: () => void;
  onClickSubmit: () => Promise<void | string>;
  pluginSubmit: () => Promise<void>;
  selectedCurrency: string;
  setSelectedCurrency: Dispatch<SetStateAction<string>>;
}

const CloseDayMain: FC<Props> = props => {
  const {
    bills,
    onChangeBill,
    counted,
    countedComputed,
    updateCounted,
    register,
    registerComputed,
    updateRegister,
    deposit,
    depositComputed,
    updateDeposit,
    expectedAmount,
    onClickTenders,
    onClickSubmit,
    pluginSubmit,
    selectedCurrency,
    setSelectedCurrency,
  } = props;
  const dispatch = useDispatch();
  const { t: rootT } = useTranslation('openCloseDay');
  const t = (text: string, options?: Record<string, number | string>) =>
    rootT(`closeDay.main.${text}`, options);
  const styles = useOCDInputStyles();
  const showExpected = useSelector(getShowExpected);

  const { format: formatCurrency, symbol: currencySymbol } = useSelector(
    getCurrency(selectedCurrency),
  );

  const drawerCountUsed = useSelector(getDayCountedByDrawer);

  const showDeposit = useSelector(getShowDeposit);
  const { name: posName }: { name: string } = useSelector(getSelectedPos);
  const { employeeID }: { employeeID: string } = useSelector(getUserLoggedIn);
  const { drawerID } = useSelector(getEmployeeById(employeeID)) ?? {};
  const drawerCountedDay = useSelector(getDayCountedByDrawer);
  const name = drawerCountedDay ? t('name', { name: drawerID }) : posName;

  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const onChangeRegister = (val: string) => {
    updateRegister(val);
    updateDeposit(null);
  };
  const onChangeDeposit = (val: string) => {
    updateDeposit(val);
    updateRegister(null);
  };

  const isXSMobile = !useBreakpoints().sm;

  const MiniView = () => (
    <Grid container spacing={2}>
      <Grid item container style={{ marginTop: '1rem', display: 'flex', justifyContent: "space-between" }}>
        <Grid item>
          <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
            <span data-testid="close-day-header">
              {drawerCountUsed ? t('drawerTitle') : t('registerTitle')}
            </span>
          </span>
          <QuestionMark position={{ left: 150, top: 20 }} code={35} color="red" />
        </Grid>
        <Grid item>
          <CloseButton action={() => dispatch(previousModalPage())} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ActiveCurrencySelect
          selected={selectedCurrency}
          setSelected={setSelectedCurrency}
        />
      </Grid>
      <Grid item xs={12}>
        <span
          data-testid="pos-name"
          style={{ minWidth: '100%', textAlign: 'center' }}
        >
          {name}
        </span>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex' }}>
        <UIButton
          style={{ flexGrow: 1 }}
          text={t('buttons.tenders')}
          variant="POS"
          data-testid="tenders-button"
          action={onClickTenders}
        />
        <UIButton
          style={{ flexGrow: 1 }}
          text={t('buttons.submit')}
          variant="POS"
          data-testid="submit-button"
          action={onClickSubmit}
        />
      </Grid>
    </Grid>
  );

  return (
    <PluginComponent
      props={{
        ...props,
        drawerCountedDay,
        currencySymbol,
        showDeposit,
        showExpected,
        pluginSubmit,
      }}
      hookname="UICloseDayMain"
    >
      <div data-testid="close-day-container">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="2px 1.25rem 8px 1.25rem"
        >
          {isXSMobile ? (
            <MiniView />
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
                paddingTop="1em"
              >
                <ActiveCurrencySelect
                  title={
                    drawerCountUsed ? t('drawerTitle') : t('registerTitle')
                  }
                  selected={selectedCurrency}
                  setSelected={setSelectedCurrency}
                />

                <span data-testid="pos-name">{name}</span>
              </Box>
              <QuestionMark
                position={{ left: 150, top: 20 }}
                code={35}
                color="red"
              />

              <Box flex={1} />

              <UIButton
                text={t('buttons.tenders')}
                variant="POS"
                data-testid="tenders-button"
                action={onClickTenders}
              />
              <UIButton
                text={t('buttons.submit')}
                variant="POS"
                data-testid="submit-button"
                action={onClickSubmit}
              />
              <CloseButton action={() => dispatch(previousModalPage())} />
            </>
          )}
        </Box>
        <Divider />
        <Box
          display="flex"
          flexDirection={isXSMobile ? 'column' : 'row'}
          padding="1.25rem"
        >
          <Box flex={1} marginRight="1.25rem">
            {!isXSMobile && (
              <Grid
                container
                direction="row"
                spacing={2}
                className={styles.billsContainer}
                data-testid="denominations"
              >
                {bills.map(({ mark, value, amount }, i) => (
                  <Grid item xs={6} key={value}>
                    <TextField
                      inputRef={i === 0 ? inputRef : null}
                      fullWidth
                      variant="outlined"
                      className={styles.input}
                      placeholder="0"
                      value={amount}
                      onChange={e => {
                        // @ts-ignore
                        const formatter = integer.and(positive);
                        onChangeBill(String(value), formatter(e.target.value));
                      }}
                      inputProps={{
                        'data-testid': 'bill',
                        'data-test-key': mark,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box>{mark.toString()}</Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            <TextField
              fullWidth
              variant="outlined"
              className={styles.largeInput}
              placeholder={countedComputed}
              value={counted}
              onChange={e => {
                // @ts-ignore
                const formatter = float.and(positive).and(toFixed(2));
                updateCounted(formatter(e.target.value));
              }}
              inputProps={{
                'data-testid': 'counted',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={largeInputAdornment(
                t('fields.counted'),
                currencySymbol,
              )}
            />
            <TextField
              fullWidth
              variant="outlined"
              className={styles.largeInput}
              placeholder={registerComputed}
              value={showDeposit ? register : counted}
              onChange={e => {
                // @ts-ignore
                const formatter = float.and(positive).and(toFixed(2));
                const formattedValue = formatter(e.target.value);
                if (showDeposit) {
                  onChangeRegister(formattedValue);
                } else {
                  updateCounted(formattedValue);
                }
              }}
              inputProps={{
                'data-testid': 'drawer-or-register',
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={largeInputAdornment(
                t(`fields.${drawerCountedDay ? 'drawer' : 'register'}`),
                currencySymbol,
              )}
            />
            {showDeposit && (
              <TextField
                fullWidth
                variant="outlined"
                className={styles.largeInput}
                placeholder={depositComputed}
                value={deposit}
                onChange={e => {
                  // @ts-ignore
                  const formatter = float.and(positive).and(toFixed(2));
                  onChangeDeposit(formatter(e.target.value));
                }}
                inputProps={{
                  'data-testid': 'deposit',
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={largeInputAdornment(
                  t('fields.deposit'),
                  currencySymbol,
                )}
              />
            )}
          </Box>
          <div>
            <NumberPad />
            {showExpected && (
              <Table style={{ fontWeight: 600, fontSize: '1.14em' }}>
                <tbody>
                  <tr>
                    <td data-testid="cash-expected-header">
                      {t('headers.cashExpected')}
                    </td>
                    <td data-testid="cash-expected-value">
                      {formatCurrency(expectedAmount)}
                    </td>
                  </tr>
                  <tr>
                    <td data-testid="cash-diff-header">
                      {t('headers.cashDifference')}
                    </td>
                    <td data-testid="cash-diff-value">
                      {formatCurrency(
                        Number(counted || countedComputed) -
                          Number(expectedAmount),
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </Box>
      </div>
    </PluginComponent>
  );
};

export default CloseDayMain;
