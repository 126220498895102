import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';

import { getEnabledPOSVersionControl } from 'reducers/configs/settings';
import { addWarning } from 'actions/Error';
import { getClientCode } from 'reducers/Login';
import { getConfiguration } from 'services/CAFA';
import * as types from 'constants/CAFA';

import {
  checkIsAllowedEnvironment,
  getEnvironmentFromURL,
  getVersionParamFromURL,
  verifyTargetAvailable,
} from '../utils';
import { getCAFAEndpoint } from 'reducers/configs/serviceEnpoints';

const url = {
  version: getVersionParamFromURL(),
  env: getEnvironmentFromURL(),
  isVersionControlEnv: checkIsAllowedEnvironment(),
};
/**
 * <h3>Custom hook that redirects POS to pre-configured CAFA version and returns a loader - boolean value</h3>
 *  <em> The hook's behaviour is toggled by pos_brazil_enabled_version_control</em>
 *
 *   - if there is no selected version and the current version is latest - ignores
 *   - if there is no selected version and the current version is not the latest - redirects to latest
 *   - if there is selected version, and it is not the current version - redirects to the selected version
 *
 */
const useVersionRedirect = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('posVersionControl');

  const clientCode = useSelector(getClientCode);
  const versioningEnabled = useSelector(getEnabledPOSVersionControl);
  const cafa = useAsync(
    () =>
      getConfiguration({
        level: 'Company',
        type: types.INTEGRATION_TYPES.global,
        name: types.INTEGRATIONS.global.posBrazilVersion,
      })
        .then(
          res =>
            res.data[0]?.value as
              | { develop: string; sandbox: string; production: string }
              | undefined,
        )
        .then(v => ({
          develop: 'latest',
          sandbox: 'latest',
          production: 'latest',
          ...v,
        })),
    [clientCode, getCAFAEndpoint()],
  );
  const [targetMissing, setTargetMissing] = useState<boolean | undefined>(
    undefined,
  );
  const [redirect, setRedirect] = useState<null|string>(null)
  // Empty string to be treated as 'latest' as well
  const targetVersion = cafa.value?.[url.env] || 'latest';
  const status = useMemo(() => {
    // Version control not enabled on this URL or not enabled in general
    if (!url.isVersionControlEnv) return 'inactive';
    if (!versioningEnabled) return 'inactive';

    // Loading CAFA
    if (cafa.loading) return 'loading';
    // CAFA failed to load
    if (cafa.error) return 'error';

    // We are on the correct version already
    if (targetVersion === url.version) return 'ok';
    // We already tried to redirect but still aren't on the correct version
    if (targetMissing) return 'looped';
    // We're on the wrong version and haven't redirected yet
    return 'redirect';
  }, [
    versioningEnabled,
    cafa.loading,
    cafa.error,
    targetVersion,
    targetMissing,
  ]);

  const [actionTaken, setActionTaken] = useState(false);
  useEffect(() => {
    if (actionTaken) return;
    switch (status) {
      default:
        console.error(
          `THIS SHOULD NEVER HAPPEN: Version control in unknown status ${status}`,
        );
        return;

      case 'inactive':
      case 'loading':
        return;

      case 'ok':
        return;

      case 'looped':
        setActionTaken(true);
        dispatch(
          addWarning(
            t('warnings.repetitiveRedirect', { version: targetVersion }),
            { selfDismiss: false, dismissible: true },
          ),
        );
        return;

      case 'error':
        setActionTaken(true);
        dispatch(
          addWarning(t('warnings.cafaError', { error: cafa.error?.message })),
        );
        return;

      case 'redirect':
        verifyTargetAvailable(targetVersion).then(
          () => {
            setActionTaken(true);
            setRedirect(targetVersion);
          },
          () => setTargetMissing(true),
        );
    }
  }, [status, targetVersion, actionTaken, cafa.error, dispatch, t]);

  return {
    status,
    redirect,
  };
};

export default useVersionRedirect;
