import React, { useEffect } from 'react';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { getConnectionHealth } from 'reducers/connectivity/connection';
import { PosPlugin } from 'plugins/plugin';
import { openPluginModalPage } from 'actions/modalPage';
import { combineLifecycleHooks } from 'plugins/pluginUtils';
import { addProduct } from 'actions/ShoppingCart/addProduct';
import { addError, addWarning } from 'actions/Error';
import { getProductInOrderByIndex } from 'reducers/ShoppingCart';

import { Conf } from './types';
import { pluginID } from './constants';
import { customPaymentIntegration } from './payment/CustomPaymentIntegration';
import { modalComponents, modals } from './modals';
import * as API from './API/givexAPI';
import {
  ComponentConfiguration,
  getGivexConfiguration,
  getGivexURL,
  getisGivexAdmin,
  getIsTransferRequired,
} from './configuration/Configuration';
import {
  addGivexRefillPaymentsFromCart,
  convertOriginalGiftcardPaymentsBackToGivex,
  postProcessPayments,
  removeOriginalGivexPaymentsIfGivexDisallowed,
  makeGivexRefillLastPayment,
  applyGivexCardBalance,
  setPaymentLimitsForCardDeactivation,
  deactivateGiftcard,
  resetReduxState,
  refillGiftCards,
} from './payment/overrides';
import KeyPad from './payment/Keypad';
import OriginalPayment from './payment/OriginalPayment';
import GiftCard from './payment/GiftCard';
import { getCardToDeactivate, reducer } from './rdx';
import { isGivexIncrementProduct } from './utils';

const GivexHeartland: PosPlugin<Conf> = {
  id: pluginID,
  name: 'Givex heartland',

  /* Configuraton */
  ComponentConfigurationByLevel: {
    Company: ComponentConfiguration,
  },
  combineConfiguration: c =>
    R.pipe(
      // Typescript definitions for mergeDeepLeft do not know that null / undefined is allowed here
      R.mergeDeepLeft(R.__, {
        givexUrl: 'wss://localhost.erply.com:5658',
        isPinRequired: false,
        defaultCardNumber: '',
        defaultPin: '',
        isTransferEnabled: false,
        adminId: '-1',
        isAllowedOnSale: true,
        isAllowedOnReturnWithoutReceipt: true,
        isAllowedOnReturnWithReceipt: true,
        displayName: 'Givex',
      } as Partial<Conf>),
      R.evolve({ displayName: value => value.trim() || 'Givex' }),
    )(c),
  getStatus: state => {
    const {
      giftcardProductIDs,
      giftcardRefundProductID,
    } = getGivexConfiguration(state);

    const errorMessages: string[] = [];
    if (!giftcardProductIDs.length)
      errorMessages.push('Missing products to use as Givex increment');
    if (!giftcardRefundProductID || Number(giftcardRefundProductID) < 0)
      errorMessages.push('Missing product to use as Givex deactivation refund');

    if (errorMessages.length)
      return { type: 'error', message: errorMessages.join('. ') };
    return { type: 'valid', message: 'Ready' };
  },

  onAddProduct: {
    before: product => async (dispatch, getState) => {
      const givexConf = getGivexConfiguration(getState());
      if (
        isGivexIncrementProduct(product.productID, givexConf) &&
        product.allowNegativePrice
      ) {
        dispatch(
          // @ts-ignore
          addProduct({
            ...product,
            allowNegativePrice: false,
          }),
        );
        throw new Error(
          `${givexConf.displayName} increment was added via product search. Replacing props via givex plugin`,
        );
      }
    },
    on: (params, ap) => async (dispatch, getState) => {
      const config = getGivexConfiguration(getState());
      if (isGivexIncrementProduct(ap.product.productID, config)) {
        return {
          ...ap,
          product: {
            ...ap.product,
            uuid: uuidv4(),
          },
        };
      }
      return ap;
    },
  },
  UIKeyPad: KeyPad,
  UIGiftCard: GiftCard,
  UIOriginalPayment: OriginalPayment,
  customPaymentIntegration,
  components: modalComponents,
  reduxReducer: reducer,
  customHooks: { getGivexCardToDeactivate: getCardToDeactivate },

  onOpenPaymentModal: combineLifecycleHooks(
    { on: addGivexRefillPaymentsFromCart },
    { on: removeOriginalGivexPaymentsIfGivexDisallowed },
    { on: setPaymentLimitsForCardDeactivation },
  ),
  onSetPayment: {
    on: applyGivexCardBalance,
  },
  onSetCurrentSalesDocPayments: {
    on: convertOriginalGiftcardPaymentsBackToGivex,
  },
  onSaveSalesDocument: combineLifecycleHooks(
    {
      on: postProcessPayments,
      after: deactivateGiftcard,
    },
    { on: refillGiftCards },
  ),
  onProcessPayments: { on: makeGivexRefillLastPayment },
  onClosePayments: { after: resetReduxState },

  ComponentHeader: ({ children }) => {
    const url = useSelector(getGivexURL);
    useEffect(() => {
      API.updateGivexURL(url);
    }, [url]);

    return children;
  },

  onUpdateOrderAmount: {
    on: (p, ap) => async (dispatch, getState) => {
      const state = getState();
      const conf = getGivexConfiguration(state);
      const order = getProductInOrderByIndex(p.orderIndex)(state);
      if (
        isGivexIncrementProduct(order.productID, conf) &&
        Math.abs(Number(ap.amount)) > 1
      ) {
        dispatch(
          addWarning(
            `Only one ${conf.displayName} Increment product can be sold per row`,
          ),
        );
        return {
          ...ap,
          amount: order.amount,
        };
      }
      return ap;
    },
  },

  selectorOverrides: {
    getIsReturnPayment: base =>
      createSelector(
        base,
        getCardToDeactivate,
        (baseValue, cardToDeactivate) => !!cardToDeactivate || baseValue,
      ),
    getFunctionButtons: base =>
      createSelector(
        base,
        getIsTransferRequired,
        getisGivexAdmin,
        getGivexConfiguration,
        getConnectionHealth,
        (baseButtons, isTransferEnabled, isAdmin, conf, isOnline) => [
          ...baseButtons,
          {
            id: uuidv4(),
            name: `${conf.displayName} balance`,
            actionType: 'action',
            disabled: !isOnline,
            action: openPluginModalPage(modals.giftCardBalance)({}),
          },
          {
            id: uuidv4(),
            name: `${conf.displayName} adjust`,
            actionType: 'action',
            disabled: !isOnline,
            hide: !isAdmin,
            action: openPluginModalPage(modals.giftCardAdjust)({}),
          },
          {
            id: uuidv4(),
            name: `${conf.displayName} transfer`,
            actionType: 'action',
            disabled: !isOnline,
            hide: !isTransferEnabled,
            action: openPluginModalPage(modals.giftCardTransfer)({}),
          },
          {
            id: uuidv4(),
            name: `${conf.displayName} increment`,
            actionType: 'action',
            disabled: !isOnline,
            action: !conf.giftcardProductIDs.length
              ? addError(
                  `${conf.displayName} increment product is not configured`,
                )
              : addProduct({
                  productID: conf.giftcardProductIDs[0],
                  allowNegativePrice: false,
                }),
          },
          {
            id: uuidv4(),
            name: `Deactivate ${conf.displayName}`,
            actionType: 'action',
            disabled: !isOnline,
            action: openPluginModalPage(modals.giftCardDeactivation)({}),
          },
        ],
      ),
  },
};

export default GivexHeartland;
