import { createSelector } from 'reselect';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getUserLoggedIn } from 'reducers/Login';

const perms = ['sale', 'return', 'return_receipt'].flatMap(trans =>
  ['serial', 'regular'].flatMap(
    gcType => `pos_allow_${trans}_giftcard_${gcType}`,
  ),
);
const asObj = Object.fromEntries(perms.map(p => [p, 0]));

/**
 * Compares the current employee's 'groupID' against the JSON encoded list in plugin_allow_giftcard_button_usergroups
 * If the groupID is listed, does nothing
 * If the groupID is missing, or the list is missing, or the groupID on the employee is missing, then locks the ability to use gift cards on any sale
 */
export const disableGiftcardBasedOnConfParam: Required<PosPlugin>['selectorOverrides']['getSettings'] = base => {
  return createSelector(
    base,
    state => getUserLoggedIn(state)?.groupID,
    (settings: any, groupID) => {
      try {
        const ids = JSON.stringify(
          settings.plugin_allow_giftcard_button_usergroups,
        );
        if (ids.includes(groupID ?? '*')) {
          return settings;
        }
        return R.mergeDeepLeft(asObj)(settings);
      } catch (e) {
        return R.mergeDeepLeft(asObj)(settings);
      }
    },
  );
};
