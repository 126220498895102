import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { Collapse, Typography, Badge } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { modalPages } from 'constants/modalPage';
import './Header.scss';
import { disableProductEditMode, enableProductEditMode } from 'actions/UI';
import { semanticVersion as version } from 'external_data';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { useBreakpoints } from 'utils/hooks/UI';
// @ts-ignore
import ErplyLogo from 'assets/img/erply-logo-white.svg';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getIsManualViewMode } from 'reducers/UI/manualViewMode';
import QuestionMark from 'components/QuestionMark';
import { getCanEditProducts, getHasRightToEditSettings } from 'reducers/Login';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getIsEditMode } from 'reducers/UI/productEditMode';
import { PluginComponent } from 'plugins';
import { HeaderInstaller } from 'containers/Header/components/Installer';
import { LogoutOnInactivity } from 'containers/Header/components/Inactivity/LogoutOnInactivity';
import { getEnabledPOSVersionControl } from 'reducers/configs/settings';
import { getUnreadErrors } from 'reducers/Error';
import { checkIsAllowedEnvironment } from 'containers/VersionControl/utils';

import BottomPopup from '../BottomPopup';
import { VersionControlContainer } from '../VersionControl';

import {
  BackOfficeRedirect,
  Feedback,
  HeaderCloudSync,
  HeaderConnectivity,
  HeaderLink,
  HeaderNotifications,
  HeaderUserMenu,
  ManualViewMode,
  ProductEditMode,
  Settings,
  ViewChanger,
} from './components';
import useUpdateSettingInLS from './useUpdateSettingInLS';

const Header = () => {
  const [isToggled, setIsToggled] = useState(false);
  const gridDisplayOpened = useSelector(getGridIsOpened);

  const { t } = useTranslation('header');

  const dispatch = useDispatch();
  const isManualViewMode = useSelector(getIsManualViewMode);
  const isEditMode = useSelector(getIsEditMode);
  const selectedPos = useSelector(getSelectedPos);
  const rightToEditProducts = useSelector(getCanEditProducts);
  const rightToEditSettings = useSelector(getHasRightToEditSettings);
  const versionControlEnabled = useSelector(getEnabledPOSVersionControl);
  const isWrapper = 'wrapperInfo' in window;
  const notifications = useSelector(getUnreadErrors);

  const title = t(`titles.notifications`, { count: notifications.length });

  useShortcut(
    'Enter',
    () => isEditMode && dispatch(disableProductEditMode()),
    30,
  );

  useUpdateSettingInLS('hideSignUpButton', 'hideSignUpButton');
  useUpdateSettingInLS('hideLoginAppButton', 'hideLoginAppButton');
  useUpdateSettingInLS('touchpos_app_background_url', 'loginBackgroundUrl');

  const bp = useBreakpoints();
  const openCompanySettings = useCallback(() => {
    dispatch(openModalPage({ component: modalPages.Settings }));
    dispatch(openModalPage({ component: modalPages.companySettings }));
  }, [dispatch]);

  useShortcut(
    'Enter',
    () => {
      if (isEditMode) {
        dispatch(disableProductEditMode());
      }
    },
    30,
  );

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const separator = (
    <li style={{ padding: 2 }}>
      <span
        style={{
          display: 'block',
          transform: 'scale(1,2)',
          color: 'gray',
          paddingBottom: 3,
          userSelect: 'none',
        }}
      >
        |
      </span>
    </li>
  );
  return (
    <Container fluid>
      {isEditMode ? (
        <Row className="align-items-center">
          <Col className="d-flex justify-content-between align-items-center">
            {bp.mdplus ? (
              <Image className="erply-header__logo" src={ErplyLogo} />
            ) : null}
            <div className="text--yellow font-weight-bold pr-3 pr-md-0 erply-header__announcement">
              {t('editModeDescription')}
            </div>
            <div
              className="btn font-weight-bold px-4 erply-header__edit-close"
              onClick={() =>
                isEditMode
                  ? dispatch(disableProductEditMode())
                  : dispatch(enableProductEditMode())
              }
            >
              {t('common:done')}
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="align-items-center" style={{ flexWrap: 'nowrap' }}>
          {bp.mdplus || !bp.sm ? (
            <Col xs="auto">
              <Image className="erply-header__logo" src={ErplyLogo} />
            </Col>
          ) : null}
          {bp.sm ? (
            <Col className="px-0">
              <HeaderLink
                data-testid="header-location"
                className="text--yellow font-weight-bold pl-3 pl-lg-0"
                href="#"
                content={`${selectedPos.warehouseName} - ${selectedPos.name} - v${version}`}
                onClick={openCompanySettings}
              />
            </Col>
          ) : null}

          <Col xs="auto" className="erply-header__list">
            <ul className="d-flex align-items-center pr-3">
              {!bp.sm && (
                <li>
                  <Typography onClick={handleToggle}>
                    {isToggled ? <ExpandLess /> : <ExpandMore />}
                  </Typography>
                </li>
              )}

              {bp.sm && (
                <>
                  <PluginComponent hookname="ComponentHeader" />
                  {separator}
                  <li>
                    <Badge
                      badgeContent={notifications.length}
                      title={title}
                      style={{ cursor: 'pointer' }}
                      overlap="circular"
                    >
                      <HeaderNotifications noAlerts={!notifications.length} />
                    </Badge>
                  </li>
                  {Boolean(versionControlEnabled) &&
                  checkIsAllowedEnvironment() ? (
                    <li>
                      <VersionControlContainer />
                    </li>
                  ) : null}
                  <li>
                    <Feedback />
                  </li>
                  <li>
                    <HeaderCloudSync />
                  </li>
                  {bp.mdplus && !isWrapper ? (
                    <li>
                      <HeaderInstaller />
                    </li>
                  ) : null}
                  <li>
                    <HeaderConnectivity />
                  </li>
                  {separator}
                  <li>
                    <ManualViewMode />
                  </li>
                  {rightToEditProducts &&
                  rightToEditSettings &&
                  gridDisplayOpened &&
                  bp.mdplus ? (
                    <li>
                      <ProductEditMode />
                    </li>
                  ) : null}
                  {separator}
                  <li>
                    <BackOfficeRedirect />
                  </li>
                  {bp.mdplus ? (
                    <li>
                      <ViewChanger />
                    </li>
                  ) : null}
                  <li>
                    <Settings />
                  </li>
                  {isManualViewMode && (
                    <li>
                      <QuestionMark position={{ top: 0 }} code={1209} />
                    </li>
                  )}
                </>
              )}
            </ul>
            {/* Hidden unless debug mode */}
            <LogoutOnInactivity />
            <HeaderUserMenu />
            {bp.mdplus ? null : <BottomPopup isSaveAs={false} />}
          </Col>
        </Row>
      )}
      {!bp.sm && (
        <Collapse in={isToggled}>
          <ul className="mobile-header">
            <li>
              <Badge
                badgeContent={notifications.length}
                title={title}
                style={{ cursor: 'pointer' }}
                overlap="circular"
              >
                <HeaderNotifications noAlerts={!notifications.length} />
              </Badge>
            </li>
            {Boolean(versionControlEnabled) && checkIsAllowedEnvironment() && (
              <li>
                <VersionControlContainer />
              </li>
            )}

            <li>
              <Feedback />
            </li>
            <li>
              <HeaderCloudSync />
            </li>
            {bp.mdplus && !isWrapper ? (
              <li>
                <HeaderInstaller />
              </li>
            ) : null}
            <li>
              <HeaderConnectivity />
            </li>
            <li>
              <ManualViewMode />
            </li>
            {rightToEditProducts &&
            rightToEditSettings &&
            gridDisplayOpened &&
            bp.mdplus ? (
              <li>
                <ProductEditMode />
              </li>
            ) : null}
            <li>
              <BackOfficeRedirect />
            </li>
            {bp.mdplus ? (
              <li>
                <ViewChanger />
              </li>
            ) : null}
            <li>
              <Settings />
            </li>
            {isManualViewMode && (
              <li>
                <QuestionMark position={{ top: 0 }} code={1209} />
              </li>
            )}
            <PluginComponent hookname="ComponentHeader" />
          </ul>
        </Collapse>
      )}
    </Container>
  );
};

export default Header;
