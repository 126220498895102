import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';

export const getEditedProductsInShoppingCart: Required<
  PosPlugin
>['selectorOverrides']['getProductsInShoppingCart'] = base => {
  return createSelector(
    base,
    getSetting('plugin_dsc_membership_product_id'),
    (products: any, membershipProductId) => {
      return products.map(prod => {
        if (prod.productID === Number(membershipProductId)) {
          if (!(prod.manualDiscount === 0 || prod.manualDiscount === 100)) {
            return {
              ...prod,
              manualDiscount: 0,
              discount: 0,
              manualDiscountReasonCodeID: 0,
            };
          }
        }
        return prod;
      });
    },
  );
};
