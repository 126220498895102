export type ValueOf<T> = T[keyof T];

export const NETS_CONST = {
  CAFA_CONFIG_NAME: 'netsCloud',
  TERMINAL: 'nets-terminal',
  ALL_TERMINALS: 'nets-all-terminals',
  TOKEN: `nets-token`,
  USERNAME: 'nets-username',
  ENV: 'nets-environment',
  ENV_TYPES: {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
  },
  TRANSACTION_TYPES: {
    PURCHASE: 'purchase',
    BONUS: 'bonus',
    RETURN_OF_GOODS: 'returnOfGoods',
  },
  ACTIONS: {
    CANCEL: 'cancelAction',
    DOWNLOAD_DATASET: 'downloadDataset',
    DOWNLOAD_SOFTWARE: 'downloadSoftware',
    RECONCILIATION: 'reconciliation',
    PRINT_LAST_TRANSACTION_RECEIPT: 'printLatestTransactionReceipt',
  },
  REQ_TIMEOUT: 2 * 60 * 1000,
  CONFIG: {
    OPTIONS: {
      NEVER: 'never',
      ALWAYS: 'always',
      ASK: 'ask',
    },
    PIN: 'bypassPin',
    PRINT: {
      MERCHANT: 'merchantCopy',
      CUSTOMER: 'customerCopy',
    },
  },
  TRANS_PATH: 'paymentIntegrations:nets',
} as const;

export type NetsConfig = {
  username: string;
  password: string;
  terminal?: string;
  environment?: string;
  [NETS_CONST.CONFIG.PIN]?: ValueOf<typeof NETS_CONST.CONFIG.OPTIONS>;
  [NETS_CONST.CONFIG.PRINT.CUSTOMER]?: ValueOf<
    typeof NETS_CONST.CONFIG.OPTIONS
  >;
  [NETS_CONST.CONFIG.PRINT.MERCHANT]?: ValueOf<
    typeof NETS_CONST.CONFIG.OPTIONS
  >;
};

export type LoginResponse = {
  token: string;
  username: string;
  terminals: string[];
};

export type TransactionSuccessResponse = {
  result: {
    // contains a unique transaction identifier
    transactionId: string; // "156023467841",
    // contains the PAN (primary account number) of the payment card used.
    // For non-loyalty cards within the PCI-DSS security requirements scope,
    // the card number is truncated/masked (6+4 digits).
    cardNumber: string; // "524999******2083",
    // a RFC3999 formatted timestamp of transaction
    timeStamp: string; // "2018-11-07T10:31:51.000Z",
    // a transaction receipt to be stored by merchant
    merchantReceipt: string; // "EUR 25.15 TAPAHT. OK MERCHANT RECEIPT",
    // a transaction receipt to be printed to the customer.
    // Note: the receipt text shall be printed without modifications.
    customerReceipt: string; // "EUR 25.15 TAPAHT. OK CUSTOMER COPY",
    // full LocalMode arguments as sent by the terminal
    // * Please refer to Baxi.net and Baxi.Agent application programmers guides for full details.
    localModeEventArgs: {
      Result: string; // "0",
      ResultData: string; // "D05524999******2083;20181107123151;0;1;156023467841;;;;;;;77773000;777730;Mastercard;00;___;A0000000041010;;;;;;;;;;;",
      Accumulator: string; // '32';
      IssuerID: string; // '5';
      TruncatedPAN: string; // '524999******2083';
      TimeStamp: string; // '20181107123151';
      CVM: string; // '0';
      SessionNumber: string; // '1';
      StanAuth: string; // '156023467841';
      SequenceNumber: string; // '';
      TotalAmount: string; // '0';
      RejectionSource: string; // '0';
      RejectionReason: string; //  '';
      TipAmount: string; //  '0';
      SurchargeAmount: string; //  '0';
      TerminalID: string; //  '77773000';
      SiteID: string; //  '777730';
      CardIssuerName: string; //  'Mastercard';
      ResponseCode: string; //  '00';
      TCC: string; //  '___';
      AID: string; //  'A0000000041010';
      TVR: string; //  '';
      TSI: string; //  '';
      ATC: string; //  '';
      AED: string; //  '';
      IAC: string; //  '';
      OrganisationNumber: string; //  '';
      BankAgent: string; //  '';
      EncryptedPAN: string; //  '';
      AccountType: string; //  '';
      OptionalData: string; //  '';
      XZCounter: string; //  '';
    };
  }[];
};

export type TransactionDeclinedResponse = {
  failure: {
    error: string; // "Terminal / rejected transaction",
    transactionId: string | null; //  null,
    cardNumber: string | null; // null,
    timeStamp: string | null; // null,
    merchantReceipt: string; // "STANDARD\r\nOstopaikka: 30522122\r\nPääte:77177314-771773\r\n2018-11-07 12:14\r\n\r\nDEBIT MASTERCARD\r\n************0963-1\r\nAID: A0000000041010\r\nTVR: 0840001000\r\nTSI:C800\r\nRef:  IB_\r\nErä: 555\r\n\r\nHYLÄTTY\r\nILMAANTUI VIRHE\r\nYRITÄUUDELLEEN\r\n\r\n\r\n\r\n*NP*",
    customerReceipt: string; // "",
    localModeEventArgs: {
      Result: string; // "2",
      ResultData: string; // "D!004513687******0963;20181107121417;0;555;;;;03;3:0;;;77177314;771773;DEBITMASTERCARD;;IB_;A0000000041010;0840001000;C800;;130901;0000000000;1234567890;STANDARD;;Inactive",
      Accumulator: string; // "48",
      IssuerID: string; // "4",
      TruncatedPAN: string; // "513687******0963",
      TimeStamp: string; // "20181107121417",
      CVM: string; // "0",
      SessionNumber: string; // "555",
      StanAuth: string; // "",
      SequenceNumber: string; // "",
      TotalAmount: string; // "0",
      RejectionSource: string; // "3",
      RejectionReason: string; // "3:0",
      TipAmount: string; // "0",
      SurchargeAmount: string; // "0",
      TerminalID: string; // "77177314",
      SiteID: string; // "771773",
      CardIssuerName: string; // "DEBIT MASTERCARD",
      ResponseCode: string; // "",
      TCC: string; // "IB_",
      AID: string; // "A0000000041010",
      TVR: string; // "0840001000",
      TSI: string; // "C800",
      ATC: string; // "",
      AED: string; // "130901",
      IAC: string; // "0000000000",
      OrganisationNumber: string; // "1234567890",
      BankAgent: string; // "STANDARD",
      EncryptedPAN: string; // "",
      AccountType: string; // "Inactive",
      OptionalData: string; // "",
      XZCounter: string; // ""
    };
  };
};

export type TransactionResponse =
  | TransactionSuccessResponse
  | TransactionDeclinedResponse;

export type GetTerminalsResponse = { terminals: { terminalId: string }[] };

export type GetTerminalInfoResponse = {
  terminal: {
    ituTestInfo: boolean; // true;
    ituStatusInfo: boolean; // true;
    siteID: string; // '840002';
    ituVersion: string; // '3226';
    ituModel: string; // 'Telium iPP350 (Pinpad – no printer)';
    ituSoftwareVersion: string; // '6215';
    ituSerialNumber: string; // '84361956';
    ituTimestampPowerOn1: string; // '2020-12-04T13:23:37.000Z';
    ituTimestampPowerOn2: string; // '2020-12-04T13:23:37.000Z';
    terminalConnected: boolean; // true;
    terminalConnectedTimestamp: string; // '2020-12-06T22:50:17.950Z';
    terminalDisconnectedTimestamp: string; // '2020-12-06T22:50:16.227Z';
    cardInserted: boolean; // false;
    readCardInfo: string | null; // null;
  };
};
