import React, { ChangeEvent } from 'react';
import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import { useSelector } from 'react-redux';

import {
  getAllPaymentTypes,
  getCustomPaymentTypes,
} from 'reducers/PaymentTypes';
import { PaymentType } from 'types/PaymentType';

type PaymentTypeInputProps = {
  onlyCustom?: boolean;
  value: PaymentType['id'];
  onChange: (e: ChangeEvent<PaymentType['id']>) => void;
} & TextFieldProps;

export const PaymentTypeInput = ({
  onlyCustom,
  ...textFieldProps
}: PaymentTypeInputProps) => {
  const paymentTypes = useSelector(
    onlyCustom ? getCustomPaymentTypes : getAllPaymentTypes,
  );

  return (
    <TextField select {...textFieldProps}>
      <MenuItem value={-1} disabled>
        Please select...
      </MenuItem>
      {paymentTypes.map(pt => (
        <MenuItem key={pt.id} value={pt.id}>
          {pt.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
