import { useDispatch, useSelector } from 'react-redux';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as rxjs from 'rxjs';
import * as rxop from 'rxjs/operators';
import debug from 'debug';
import { createSelector } from 'reselect';

import {
  getPosTimeoutSettings,
  getSavedSetting,
} from 'reducers/configs/settings';
import { getComponents } from 'reducers/modalPage';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import { getHasProductsInShoppingCart } from 'reducers/ShoppingCart';
import { modalPages } from 'constants/modalPage';
import { hardLogout, softLogout } from 'actions/Login';

import styles from './LogoutOnInactivity.module.scss';

/**
 * Based on configuration and current POS state, returns the current timings for
 * the pos to track inactivity with
 *
 * When disabled or when a blacklisted modal is open, returns Infinity
 * otherwise returns the configured timeout time
 */
const getCurrentInactivityTime = createSelector(
  // Settings
  getPosTimeoutSettings,
  getSavedSetting('pos_timeout'),
  // POS state
  getComponents,
  getIsDefaultCustomer,
  getHasProductsInShoppingCart,
  (
    timeoutConf: any,
    timeout,
    openedModalPages,
    hasDefaultCustomerSelected,
    hasItemsInCart,
  ) => {
    const mpIsOpen = c => openedModalPages.map(mp => mp.component).includes(c);
    const timerShouldBePaused = [
      true && mpIsOpen(modalPages.Settings),
      timeoutConf.openCloseDay && mpIsOpen(modalPages.OpenDay),
      timeoutConf.openCloseDay && mpIsOpen(modalPages.closeDay),
      timeoutConf.stockLevels && mpIsOpen(modalPages.stockAndPriceItem),
      timeoutConf.stockLevels && mpIsOpen(modalPages.stockAndPriceList),
      timeoutConf.zReport && mpIsOpen(modalPages.ZReport),
      timeoutConf.creatingCustomer && mpIsOpen(modalPages.createCustomer),
      timeoutConf.creatingCustomer && mpIsOpen(modalPages.extraAddress),
      timeoutConf.browsingSales && mpIsOpen(modalPages.recentSales),
      timeoutConf.browsingSales && mpIsOpen(modalPages.pendingSales),
      timeoutConf.browsingSales && mpIsOpen(modalPages.pickupOrders),
      timeoutConf.browsingSales && mpIsOpen(modalPages.layawayList),
      timeoutConf.browsingSales && mpIsOpen(modalPages.layawayActionSelection),
      timeoutConf.browsingSales && mpIsOpen(modalPages.offers),
      timeoutConf.browsingSales && mpIsOpen(modalPages.unpaidInvoices),
      timeoutConf.browsingSales && mpIsOpen(modalPages.ProductReturn),
      timeoutConf.customerSelected && !hasDefaultCustomerSelected,
      timeoutConf.productsInCart && hasItemsInCart,
    ].some(Boolean);
    // Infinity if paused
    if (timerShouldBePaused) return Infinity;
    // or conf empty
    if (!timeout) return Infinity;
    // or conf invalid
    if (!Number.isFinite(Number(timeout))) return Infinity;

    return Number(timeout);
  },
);

/**
 * Action to perform when the timeout is reached
 *
 * Either locks the POS or logs out, depending on configuration
 */
const doInactivityAction = async (dispatch, getState) => {
  switch (getPosTimeoutSettings(getState()).action) {
    case 'hardLogout':
      return dispatch(hardLogout());
    default:
    case 'softLogout':
      return dispatch(softLogout());
  }
};

/**
 * A debug indicator of the user's chosen color
 * Returns the jsx element as well as a function to call to trigger the indicator to flash
 *
 * (The flashing functionality is the reason this isn't a react component)
 */
const useDebugElement = (color: string) => {
  // Debug indicator
  const debugEl = useRef<HTMLSpanElement>(null);
  const flash = useCallback(() => {
    if (debugEl.current) {
      debugEl.current.classList.remove(styles.flash);
      // Read height to force reflow
      const h = debugEl.current.offsetHeight;
      debugEl.current.classList.add(styles.flash);
    }
  }, [debugEl]);

  const component = (
    <span
      key="indicator"
      title="Logout on inactivity status (debug only)"
      ref={debugEl}
      style={{ backgroundColor: color }}
      className={styles.flash}
    />
  );
  if (!debug.enabled('LogoutOnInactivity')) return [null, flash] as const;
  return [component, flash] as const;
};

/**
 * Returns an rxjs observable of all the events which should count as activity
 */
const getActivityObservable = createSelector(
  getPosTimeoutSettings,
  (settings: any) =>
    rxjs.merge(
      ...[
        rxjs.NEVER,
        settings.inputs && rxjs.fromEvent(document, 'click'),
        settings.inputs && rxjs.fromEvent(document, 'key'),
        settings.inputs && rxjs.fromEvent(document, 'keypress'),
        settings.inputs && rxjs.fromEvent(document, 'keydown'),
        settings.inputs && rxjs.fromEvent(document, 'keyup'),
        settings.mousemove && rxjs.fromEvent(document, 'mousemove'),
      ].filter(a => a),
    ),
);

/**
 * Component that tracks modalpages & inputs and triggers a logout if the user is inactive for long enough
 *
 * @returns A div with position:fixed to fade out the screen when the logout is about to trigger
 *
 * When debug mode is enabled (`localStorage.debug = '*'`), also renders an indicator
 * that shows if the timer is currently active
 * and flashes whenever it gets reset by user interaction
 */
export const LogoutOnInactivity = () => {
  const dispatch = useDispatch();
  /** Length of time to measure for inactivity - Infinity if blocked or disabled */
  const currentInactivityTime = useSelector(getCurrentInactivityTime);

  /** How long to animate the fade before executing the action */
  const fadeTime = 6;
  /** How long to wait until starting the face */
  const timeUntilFade = Math.max(10, currentInactivityTime) - fadeTime;
  /** Whether we are currently in the main phase or fading phase */
  const [fading, setFading] = useState(false);

  /* Debug indicator shows up according to localstorage.debug */
  const debugBackgroundColor = useMemo(() => {
    if (timeUntilFade === Infinity) return 'salmon'; // Timer inactive
    if (fading) return 'yellow'; // Timer almost over, fade is active
    return 'lime'; // Timer active with plenty of time
  }, [fading, timeUntilFade]);
  const [debugIndicator, flashDebugIndicator] = useDebugElement(
    debugBackgroundColor,
  );

  /* Timer implementation */
  const activity$ = useSelector(getActivityObservable);
  useEffect(() => {
    // When disabled, reset the timestamp and phase
    if (!Number.isFinite(timeUntilFade)) {
      localStorage.removeItem('lastTimestamp');
      setFading(false);
      return () => {};
    }

    // If there's a stored timestamp. reduce the time by the time since it was recorded
    const lastTimestamp = localStorage.getItem('lastTimestamp');
    const spentTime = lastTimestamp ? Date.now() - Number(lastTimestamp) : 0;
    const subscription = activity$
      // Throw an error when time elapses with no activity
      .pipe(rxop.timeout((fading ? fadeTime : timeUntilFade) * 1e3 - spentTime))
      // Throttle activity events to limit performance
      // because this triggers react setters, localstorage updates, and flashes
      .pipe(rxop.throttleTime(100))
      .subscribe({
        /** Stuff that happens when activity is detected */
        next: () => {
          if (fading) setFading(false);
          localStorage.setItem('lastTimestamp', String(Date.now()));
          flashDebugIndicator();
        },
        /** Stuff that happens when the time runs out */
        error: () => {
          if (fading) dispatch(doInactivityAction);
          else setFading(true);
        },
        /** This should never happen */
        complete: () =>
          console.error(
            'Activity stream closed, no longer tracking user activity - this should never happen',
          ),
      });
    return () => subscription.unsubscribe();
  }, [
    activity$,
    timeUntilFade,
    fadeTime,
    fading,
    flashDebugIndicator,
    dispatch,
  ]);

  return (
    <>
      {debugIndicator}
      <div
        id="fadeoutOverlay"
        style={{
          pointerEvents: 'none',
          position: 'fixed',
          backgroundColor: 'black',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 9999999,
          opacity: fading ? 0.8 : 0,
          transition: fading ? `opacity ${fadeTime}s` : '',
        }}
      />
    </>
  );
};
