import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getProductNoteButtonsConfigs } from 'reducers/configs/settings';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';

import NoteButtonsComponent from './NoteButtonsComponent';

const NoteButtons = ({ currentNote, onClick }) => {
  const dispatch = useDispatch();
  const { notes, previewCount } = useSelector(getProductNoteButtonsConfigs);
  const onClickEnhanced = note => {
    onClick(`${currentNote ? `${currentNote}\n` : ''}${note}`);
  };
  const onClickModalEnhanced = note => {
    dispatch(previousModalPage());
    onClickEnhanced(note);
  };
  const { t } = useTranslation('shoppingCart');
  return (
    <>
      <h3>{t('noteButtons.title')}</h3>
      <NoteButtonsComponent
        notes={notes}
        previewCount={previewCount}
        currentNote={currentNote}
        onClick={onClickEnhanced}
        onExpandClick={(e: any) => {
          e.preventDefault();
          dispatch(
            openModalPage({
              component: modalPages.productNoteButtons,
              props: {
                notes,
                currentNote,
                onClick: onClickModalEnhanced,
                previewCount: notes.length,
              },
            }),
          );
        }}
      />
    </>
  );
};

export default NoteButtons;
