import { useEffect } from 'react';

import {
  REDUX_CLIENTCODE,
  REDUX_USERNAME,
  REDUX_PIN,
  REDUX_POSID,
} from 'constants/persistence';

/**
 * Sometimes, very rarely, localstorage gets 'corrupted'
 * (credential fields, which should be valid json, get saved without quotes)
 * When this happens, the next time the POS launches it will crash trying to parse localStorage as JSON
 * giving a white screen in live and an error screen in dev
 *
 * This method will delete any such corrupted values.
 */
const sanitizeLocalStorage = () => {
  const jsonFields = [
    REDUX_CLIENTCODE,
    REDUX_USERNAME,
    REDUX_PIN,
    REDUX_POSID,
  ];
  jsonFields.forEach(key => {
    try {
      JSON.parse(localStorage.getItem(key));
    } catch (e) {
      localStorage.removeItem(key);
    }
  });
};
export default sanitizeLocalStorage;
