import { PatchScript } from '@pos-refacto/patchscript-with-react';

import { mmReceiptTranslations } from 'plugins/mm/features/receipt/pieces/translations/translations';

import { CurrencyUtil } from '../types';

type Table = Extract<PatchScript[number], { type: 'table' }>;
type TableRow = Table['rows'][number];
type Text = Extract<PatchScript[number], { type: 'text' }>;

export const text = (s: any, meta?: Text['pieces'][number]['meta']): Text => ({
  type: 'text',
  pieces: [{ text: String(s), meta }],
});
export const textR = (s: any, meta?: Text['pieces'][number]['meta']): Text => ({
  ...text(s, meta),
  align: 'right',
});
export const price = (
  CURR: CurrencyUtil,
  s: any,
  meta?: Text['pieces'][number]['meta'],
): Text => ({
  type: 'text',
  align: 'right',
  pieces: [{ text: CURR.stringify(CURR.parse(s)), meta }],
});

type ReceiptCardData = {
  transactionType: string;
  sum: string;
  typeTrans: string;
  cardType: string;
  cardNumber: string;
  expirationDate: string;
  referenceNumber: string;
  authorizationCode: string;
  certificateBalance: string;
};

export const receiptCardDetailsTable = (
  card: ReceiptCardData,
  CURR: CurrencyUtil,
  {
    cardDetails: t,
    productsTable: {
      totals: { types: typeTranslations },
    },
  } = mmReceiptTranslations.en,
): PatchScript[number] => {
  const output: PatchScript[number] = {
    type: 'table',
    columns: [
      { baseWidth: 0, weight: 1 },
      { baseWidth: 30, weight: 0 },
    ],
    rows: [{ type: 'normal', cells: [textR(' '), textR(' ')] }],
  };
  function labelValue(label: string, value: string): TableRow {
    return { type: 'normal', cells: [textR(label), textR(value)] };
  }
  let rawType: string;
  if (card.cardType === 'GIVEX') {
    rawType = 'GIVEX';
  } else if (/cash/i.test(card.cardType) && /card/i.test(card.cardType)) {
    rawType = 'CASHCARD';
  } else {
    rawType = card.typeTrans;
  }
  const type = typeTranslations[rawType] ?? rawType;
  const transactionType =
    t.transactionTypes[card.transactionType] ?? card.transactionType;
  const sum = CURR.stringifyWithSymbol(CURR.parse(card.sum));
  const balance = CURR.stringifyWithSymbol(CURR.parse(card.certificateBalance));
  switch (card.typeTrans) {
    case 'GIFT_CARD': {
      output.rows.push(
        labelValue(t.transactionType, transactionType),
        labelValue(t.sum, sum),
        labelValue(t.typeTrans, type),
        labelValue(t.cardNumber, card.cardNumber),
        labelValue(t.authorizationCode, card.authorizationCode),
        labelValue(t.certificateBalance, balance),
      );
      return output;
    }
    case 'GIVEX': {
      const cardNumber = `${'*'.repeat(15)}${card.cardNumber.trim().slice(-4)}`;
      output.rows.push(
        labelValue(t.transactionType, transactionType),
        labelValue(t.sum, sum),
        labelValue(t.authorizationCode, card.authorizationCode),
        labelValue(t.typeTrans, type),
        labelValue(t.cardNumber, cardNumber),
      );
      if (typeof card.certificateBalance === 'string') {
        output.rows.push(labelValue(t.certificateBalance, balance));
      }
      return output;
    }
    default:
      output.rows.push(
        labelValue(t.transactionType, transactionType),
        labelValue(t.sum, sum),
        labelValue(t.authorizationCode, card.authorizationCode),
        labelValue(t.typeTrans, type),
        labelValue(t.cardNumber, card.cardNumber),
      );
      return output;
  }
};
