/**
 * Creates array of numeric strings withing the passed range, zero padded to be at least 2 characters
 *
 */

export default (min = 0, max = 10000) => {
  const dates = [];
  let i = min;
  while (i <= max) dates.push(i++);
  return dates.map(d => (d < 10 ? `0${d}` : d.toString()));
};
