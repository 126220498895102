import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as R from 'ramda';

import { getIDealConfiguration } from 'plugins/ideal/Configuration';

import { Customer, OnChange } from '../types';

interface Props {
  onChange: OnChange;
  value: Customer;
  isCompany: boolean;
}

/** When creating new customer, auto-apply configured default groups */
export const useDefaultCustomerGroups = ({
  value,
  onChange,
  isCompany,
}: Props) => {
  const { defaultCustomerGroup, defaultCompanyGroup } = useSelector(
    getIDealConfiguration,
  );
  const defaultGroup = isCompany ? defaultCompanyGroup : defaultCustomerGroup;

  useEffect(() => {
    if (value.customerID) return;
    if (value.groupID) return;
    onChange(R.assoc('groupID', defaultGroup));
  }, [defaultGroup, onChange, value]);
};
