import React, { FC, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@material-ui/core';

import tableStyles from 'assets/styles/table.module.scss';
import UIButton from 'components/UIElements/UIButton';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { PluginComponent } from 'plugins';
import useProducts from 'utils/hooks/useProducts';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import './relatedProducts.scss';
import { getTotalCountInCart } from 'reducers/ShoppingCart';
import { getShowPricesWithTax } from 'reducers/configs/settings';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';

type RelatedProductsProps = {
  productID: number;
  parentRowID: number;
};

const ProductRow = ({ prod, id, onClick }) => {
  const countInCart = useSelector(getTotalCountInCart(id));
  const shouldShowPriceWithTax = useSelector(getShowPricesWithTax);
  const amountToShow = Number(prod.free || 0) - countInCart;
  return (
    <tr key={id} onClick={onClick} data-testid="product-row" data-test-key={id}>
      <td>{prod.name}</td>
      <td>{prod.code}</td>
      <td>
        {(shouldShowPriceWithTax ? prod.priceWithVat : prod.price)?.toFixed(2)}
      </td>
      <td>{amountToShow}</td>
    </tr>
  );
};

const RelatedProductsModal: FC<RelatedProductsProps> = ({
  productID,
  parentRowID,
}) => {
  const { t } = useTranslation('product');
  const warehouseID = Number(useSelector(getSelectedWarehouseID));
  const props = useMemo(() => ({ productID }), [productID]);
  const {
    products: [mainProduct],
    loading: loadingMain,
  } = useProducts(props);

  const { products: relatedProducts, loading: relatedLoading } = useProducts({
    productIDs: mainProduct?.relatedProducts?.map(Number) ?? [],
    getProductsFor: 'SALES',
    warehouseID,
  });

  const {
    products: replacementProducts,
    loading: replacementLoading,
  } = useProducts({
    productIDs: mainProduct?.replacementProducts?.map(Number) ?? [],
    getProductsFor: 'SALES',
    warehouseID,
  });

  const loading = loadingMain || relatedLoading || replacementLoading;

  const dispatch = useDispatch();
  const onClose = () => dispatch(previousModalPage());
  const closeAll = () => dispatch(closeModalPage('RELATED_PRODUCTS'));
  const tableStyle = [tableStyles.minimalTable, tableStyles.hover].join(' ');

  if (
    !loading &&
    !mainProduct?.relatedProducts?.length &&
    !mainProduct?.replacementProducts?.length
  ) {
    onClose();
  }
  return (
    <>
      <PluginComponent
        hookname="UIRelatedProducts"
        props={{
          productID,
          parentRowID,
          relatedProducts,
          replacementProducts,
          tableStyle,
        }}
      >
        <div data-testid="related-products">
          <Modal.Header>
            <span className="related-products-title">
              {t('relatedProducts.title', {
                context: relatedProducts.length ? undefined : 'substitutes',
              })}
            </span>

            <div className="ml-auto">
              <UIButton
                text={t('common:done')}
                variant="POS"
                action={onClose}
                data-testid="done-btn"
              />
              <UIButton
                text={t('common:closeAll')}
                variant="POS"
                action={closeAll}
                data-testid="close-btn"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            {loadingMain && <LinearProgress variant="indeterminate" />}
            {!relatedProducts.length ? null : (
              <Table
                data-testid="related-products-table"
                hover
                className={tableStyle}
              >
                <thead data-testid="table-headings">
                  <tr>
                    <th data-testid="name">
                      {t('relatedProducts.headers.name')}
                    </th>
                    <th data-testid="code">
                      {t('relatedProducts.headers.code')}
                    </th>
                    <th data-testid="price">
                      {t('relatedProducts.headers.price')}
                    </th>
                    <th data-testid="stock">
                      {t('relatedProducts.headers.stock')}
                    </th>
                  </tr>
                </thead>
                <tbody data-testid="body">
                  {relatedProducts
                    .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                    .map(relProd => (
                      <ProductRow
                        key={relProd.productID}
                        prod={relProd}
                        id={relProd.productID}
                        onClick={() =>
                          dispatch(
                            addProduct({
                              productID: relProd.productID,
                              parentRowID,
                            }),
                          )
                        }
                      />
                    ))}
                </tbody>
              </Table>
            )}
            {relatedProducts.length && replacementProducts.length ? (
              <>
                <hr />
                <h3 style={{ fontWeight: 'bold' }}>
                  {t('relatedProducts.title', {
                    context: 'substitutes',
                  })}
                </h3>
              </>
            ) : null}

            {!replacementProducts.length ? null : (
              <Table
                data-testid="replacement-products-table"
                hover
                className={tableStyle}
              >
                <thead data-testid="headings">
                  <tr>
                    <th data-testid="name">
                      {t('relatedProducts.headers.name')}
                    </th>
                    <th data-testid="code">
                      {t('relatedProducts.headers.code')}
                    </th>
                    <th data-testid="price">
                      {t('relatedProducts.headers.price')}
                    </th>
                    <th data-testid="stock">
                      {t('relatedProducts.headers.stock')}
                    </th>
                  </tr>
                </thead>
                <tbody data-testid="body">
                  {replacementProducts
                    .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                    .map(replProd => (
                      <ProductRow
                        key={replProd.productID}
                        prod={replProd}
                        id={replProd.productID}
                        onClick={() =>
                          dispatch(
                            addProduct({
                              productID: replProd.productID,
                            }),
                          )
                        }
                      />
                    ))}
                </tbody>
              </Table>
            )}
          </Modal.Body>
        </div>
      </PluginComponent>
    </>
  );
};

export default RelatedProductsModal;
