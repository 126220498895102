import { PosPlugin } from 'plugins/plugin';
import { getCurrentSalesDocument } from 'reducers/sales';

import { getCFRState } from '../rdx';

const setCurrentSalesDocPayments: Required<
  PosPlugin
>['onSetCurrentSalesDocPayments']['on'] = (p, ap) => async (
  dispatch,
  getState,
) => {
  const { isOnlineReturn } = getCFRState(getState());

  if (isOnlineReturn && ap.original.length === 0) {
    const currentSalesDocumentPayments =
      getCurrentSalesDocument(getState())?.payments || [];
    return { original: currentSalesDocumentPayments, returned: [] };
  }

  return ap;
};

export default setCurrentSalesDocPayments;
