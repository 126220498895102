import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { PosPlugin } from 'plugins/plugin';
import { getProductsInShoppingCart, getTotal } from 'reducers/ShoppingCart';
import { ShoppingCart } from 'types/ShoppingCart';
import { getCurrencyCode } from 'reducers/configs/settings';
import { getCurrencyByCode } from 'reducers/configs/currency';
import { RItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { CancelActionOverrideError } from 'plugins/pluginUtils';
import { RootState } from 'reducers';
import { SaveSalesDocumentResponse } from 'types/SalesDocument';

import UICustomPaymentList from './components/UICustomPaymentList';
import reduxReducer, { getCurrency, setCurrency } from './redux';
import { doEstonianBankPrintout } from './utils';

const pluginID = 'currency-converter';

const CurrencyConeverterPlugin: PosPlugin = {
  id: pluginID,
  name: 'Currency converter (Estonian Bank)',
  info:
    'This plugin enables currency conversion. Plugin is meant for Estonian Bank only and is in estonian langugage.',
  keywords: ['conversion', 'currency', 'currency rate'],
  getStatus: () => ({
    type: 'valid',
    message: '',
  }),
  reduxReducer,
  UICustomPaymentList,
  onSaveSalesDocument: {
    on: (p, requests) => async (_dispatch, getState) => {
      const hasSaleTypeSpecified = Boolean(p?.salesDocument?.type);
      if (hasSaleTypeSpecified) return requests;

      const products: ShoppingCart = getProductsInShoppingCart(getState());
      const isRegularGiftCardAndTaxFree = products.some(
        product => product.isRegularGiftCard && Number(product.vatRate) === 0,
      );

      const chosenCurrency = getCurrency(getState());

      const currencyCode = getCurrencyCode(getState());
      const defaultCurrency = getCurrencyByCode(currencyCode)(getState());

      const total = getTotal(getState());
      const totalInTargetCurrency =
        total * Number(chosenCurrency?.rate ?? defaultCurrency?.rate ?? 1);

      const convertValuesToNegative = (obj: object, keys: string[]) => {
        const initialObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          const cleanKey = key.replace(/[0-9]/g, '');
          if (keys.includes(cleanKey)) {
            initialObj[key] = -value;
          }
        });
        return initialObj;
      };

      if (isRegularGiftCardAndTaxFree) {
        return requests.map(req => {
          if (req.requestName === 'savePayment') {
            return {
              ...req,
              currencyCode: defaultCurrency?.code,
              sum: -totalInTargetCurrency,
              type: 'CASH',
            };
          }
          if (req.requestName === 'saveSalesDocument') {
            return {
              ...req,
              currencyCode: chosenCurrency?.code,
              type: 'CREDITINVOICE',
              ...convertValuesToNegative(req, ['rowTotal', 'amount']),
            };
          }
          return req;
        });
      }

      return requests;
    },
  },
  onOpenPaymentModal: {
    after: () => async dispatch => {
      dispatch(setCurrency(null));
    },
  },
  onGenerateReceipt: {
    before: ({ salesDocument }) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      const salesDocumentID =
        salesDocument.id ??
        (salesDocument as SaveSalesDocumentResponse).invoiceID;
      if (!salesDocumentID) throw new Error('No id specified');

      const patchScript = (await dispatch(
        doEstonianBankPrintout(Number(salesDocumentID)),
      )) as RItem[];
      if (patchScript) {
        throw new CancelActionOverrideError(
          'Cancel print, only do plugin print',
          { patchScript },
        );
      }
    },
  },
};

export default CurrencyConeverterPlugin;
