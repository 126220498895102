/**
 * Script commands for use with the printer integration
 *
 * The printer is unable to PRINT html, images, or anything like that
 * Hence we need to create our own receipt using these basic commands
 */
import { add, round } from 'utils';
import 'core-js/features/string/match-all';
import { notUndefinedOrNull } from 'utils/tsHelpers';

import { BOLD, LN, PRINT, SIZE, UNDERLINE } from './baseCommands';

/**
 * Metadata to pass to the PrinterScript constructor so it knows how to format its output
 */
export type PrinterMetadata = {
  /** ID of the printer as 4+4 hex digits separated by productColumn space */
  id: string;
  /** Human readable name of the printer */
  name: string;
  /** An invisible character that this printer supports for use with spacing/alignment */
  SP: string;
  charset: number;
};

type PrinterModeOverrides = {
  /** Override the BOLD mode */
  bold?: boolean;
  /** Override the UNDERLINE mode */
  underline?: boolean;
  /** Override the TEXTSIZE mode */
  size?: number;
};

/** Default behaviour is to wrap text to the next line */
type PrinterCellOverrides = PrinterModeOverrides & {
  /** The width of the cell */
  width: number;
  /** Whether to crop the text if if exceeds the cell's width */
  alignRight?: boolean;
  /** Whether to align the text/value to the right of the cell */
  crop?: boolean;
};

/**
 * Fluent builder for step-by-step construction of the receipt script
 * call toString() on this object to get the the full script (not b64 encoded)
 */
export class PrinterScript {
  commands: string[] = [];

  cells: (PrinterCellOverrides & { text: string })[] = [];

  printer: PrinterMetadata;

  constructor(printer: PrinterMetadata) {
    this.printer = printer;
  }

  /**
   * Whether the printer is currently in BOLD mode
   * printer modes remain in effect until explicitly toggled off
   * @returns {boolean} true if the printer is in BOLD mode
   */
  get isBold() {
    return (
      this.commands
        .map(c => /BOLD (\d)/.test(c))
        .filter(productColumn => productColumn)
        .map(match => match[1])
        .slice(-1)[0] === '1'
    );
  }

  /**
   * Whether the printer is currently in UNDERLINE mode
   * printer modes remain in effect until explicitly toggled off
   * @returns {boolean} true if the printer is in UNDERLINE mode
   */
  get isUnderline() {
    return (
      this.commands
        .map(c => /UNDERLINE (\d)/.test(c))
        .filter(productColumn => productColumn)
        .map(match => match[1])
        .slice(-1)[0] === '1'
    );
  }

  /**
   * Adds productColumn line break to the current script
   * @returns {PrinterScript}
   */
  ln() {
    this.commands.push('LF');
    return this;
  }

  /**
   * Prints some text to the current script
   * @param data
   * @param overrides
   * printer mode overrides to apply for this piece of text
   * <p>NB: Any overrides will be reset to the default value afterwards regardless of their previous val
   * <p>Overrides that are not passed in will retain whatever value they had
   */
  print(data: any, { bold, underline, size }: PrinterModeOverrides = {}): this {
    this.commands.push(
      ...[
        size !== undefined ? SIZE(size) : null,
        bold ? BOLD(1) : null,
        underline ? UNDERLINE(1) : null,
        /\S/g.test(data) ? PRINT(data) : null,
        underline ? UNDERLINE(0) : null,
        bold ? BOLD(0) : null,
        size !== undefined ? SIZE(0) : null,
      ].filter(notUndefinedOrNull),
    );
    return this;
  }

  /**
   * Add the given commands straight to the internal .commands field
   * A low level command used to wrap an arbitrary script
   * @param {string[]} cmds An array of commands
   * @returns {PrinterScript}
   */
  addCommands(cmds: string[]) {
    this.commands = [...this.commands, ...cmds];
    return this;
  }

  /**
   * Print two columns with the width distributed based on the lengths of the contents (twice as long - twice the space)
   */
  leftRight(
    left: any,
    leftStyle: PrinterCellOverrides,
    right: any,
    rightStyle: PrinterCellOverrides,
  ) {
    const ratio = `${left}`.length / `${left}${right}`.length;
    const width1 = Math.ceil(48 * ratio);
    const width2 = 48 - width1;
    const lStyle = { ...leftStyle, width: width1 };
    const rStyle = {
      ...rightStyle,
      width: width2,
    };
    this.td(left, lStyle).td(right, rStyle);
    return this;
  }

  /**
   * Switch the printer's BOLD mode on or off
   * @param {boolean} bold
   * @returns {PrinterScript}
   */
  bold(bold: boolean) {
    this.commands.push(BOLD(bold ? 1 : 0));
    return this;
  }

  /**
   * Switch the printer's UNDERLINE mode on or off
   * @param {boolean} underline
   * @returns {PrinterScript}
   */
  underline(underline: boolean) {
    this.commands.push(UNDERLINE(underline ? 1 : 0));
    return this;
  }

  /**
   * Fill the line with underline characters, optionally leaving some characters in the beginning empty
   *
   * There must not be any text on the current line or this will wrap around
   * @param n The number of characters at the start to leave empty
   * @returns {PrinterScript}
   */
  hr(n = 0) {
    this.commands.push(
      `PRINT ${this.printer.SP.repeat(n) + '_'.repeat(48 - n)}`,
    );
    this.commands.push(LN());
    return this;
  }

  /**
   * Overrides the UNDERLINE property for the entire row to be true
   * @returns {PrinterScript}
   */
  underlineCurrentRow() {
    const i = this.commands.lastIndexOf('LF');
    const beforeThisLine = this.commands.slice(0, i + 1);
    const thisLineWithoutUnderline = this.commands
      .slice(i + 1)
      .filter(c => !/UNDERLINE (\d)/.test(c));
    const printCmdRegex = /^PRINT (.+)/;
    const lineLength = thisLineWithoutUnderline
      .map(cmd => printCmdRegex.exec(cmd))
      .filter(notUndefinedOrNull)
      .map(match => match[1].length)
      .reduce(add, 0);
    const remainder = 48 - lineLength;
    this.commands = [
      ...beforeThisLine,
      UNDERLINE(1),
      ...thisLineWithoutUnderline,
      ...(remainder > 0 ? [`PRINT ${this.printer.SP.repeat(remainder)}`] : []),
      UNDERLINE(0),
    ];
    return this;
  }

  /**
   * Print productColumn table cell with the given properties
   * {@link commitLine} must be called later to print all the accumulated .td cells
   * @param {string|any} text The text/value to print in the cell
   * @param {PrinterModeOverrides} overrides
   * @returns {PrinterScript}
   */
  td(
    text,
    { width, alignRight, bold, underline, crop, size }: PrinterCellOverrides,
  ) {
    const newCell = {
      text: String(text),
      width,
      alignRight,
      bold,
      underline,
      crop,
      size,
    };
    if (crop) {
      if (newCell.text.length > width - 1) {
        newCell.text = `${newCell.text.slice(0, width - 2)}^`;
      }
    }
    this.cells = [...this.cells, newCell];
    return this;
  }

  /**
   * Must be called to finish off cells declared with {@link td}
   * @param underlineCurrentRow
   * @returns {PrinterScript}
   */
  commitLine({ underlineCurrentRow = false } = {}) {
    const processedCells = this.cells.map(c => ({
      ...c,
      lines: [...c.text.matchAll(new RegExp(`[^\n]{0,${c.width - 1}}`, 'g'))]
        .map(res => res[0])
        .filter(O => O),
    }));

    Array(Math.max(...processedCells.map(cell => cell.lines.length)))
      .fill(0)
      .forEach((_, i) => {
        processedCells.forEach(
          ({ lines, width, alignRight, bold, underline, size }) => {
            const subtext = lines[i] || '';
            this.print(
              alignRight
                ? subtext.padStart(width, this.printer.SP)
                : subtext.padEnd(width, this.printer.SP),
              { size, bold, underline },
            );
          },
        );
        this.commands.push(LN());
      });
    if (underlineCurrentRow) {
      this.commands.pop();
      this.underlineCurrentRow();
      this.commands.push(LN());
    }
    this.cells = [];
    return this;
  }

  /**
   * Prints the given values each in the center of its own line
   * @returns {PrinterScript}
   */
  centerText(...lines) {
    lines.forEach(line => {
      const { text, ...props } = line.split
        ? { text: line, width: line.length }
        : line;
      const width = text.length;
      const extraSpace = 48 - width;
      const space = Math.floor(extraSpace / 2);
      this.td('', { width: space })
        .td(text, { ...props, width: width + 1 })
        .commitLine();
    });
    return this;
  }

  /**
   * @returns {string} the plaintext script to open the cash drawer
   */
  toOpenCashDrawer() {
    return `SETPRINTER ${this.printer.id}\nOPENCASHDRAWER`;
  }

  barcode(code) {
    this.commands.push(`BARCODE 4 ${code}`);
  }

  /**
   * @returns {string} the plaintext script for the receipt so far
   */
  toString() {
    let newLine = true;
    return this.commands
      .reduce(
        (cmds, cmd) => {
          if (cmd === 'LF') newLine = true;
          // Consolidate print statements
          const printCmdRegex = /^PRINT (.*)/;
          const lastPrint = printCmdRegex.exec(cmds[cmds.length - 1]);
          const currentPrint = printCmdRegex.exec(cmd);
          if (newLine && currentPrint && cmd[7] === ' ') {
            currentPrint[1] = `\`${currentPrint[1].substring(1)}`;
            // eslint-disable-next-line no-param-reassign
            cmd = `PRINT ${currentPrint[1]}`;
          }
          if (currentPrint) {
            newLine = false;
          }
          if (lastPrint && currentPrint) {
            return [
              ...cmds.slice(0, -1),
              PRINT(`${lastPrint[1]}${currentPrint[1]}`),
            ];
          }
          return [...cmds, cmd];
        },
        [
          `SETPRINTER ${this.printer.id}`,
          'INIT',
          `CHARSET ${this.printer.charset}`,
        ], // 0519 0001 04b8 0e15
      )
      .concat(['FULLCUT'])
      .join('\n');
  }
}

/**
 * Prints productColumn grid of all possible printable characters
 * @returns {PrinterScript}
 */
export const printCharsetGrid = (script: PrinterScript): PrinterScript => {
  const { SP } = script.printer;
  script.print(`.${SP}${SP}0123${SP}4567${SP}89ab${SP}cdef`).ln();
  for (let i = 2; i < 16; i++) {
    script.print(`${i.toString(16)}: `);
    Array(16)
      .fill(0)
      .map((_, j) => j)
      // eslint-disable-next-line eqeqeq
      .map(j => `${String.fromCharCode(16 * i + j)}${j % 4 == 3 ? SP : ''}`)
      .forEach(str => script.print(str));
    script.print('|').ln();
  }

  return script;
};

export const printSampleScript = (script: PrinterScript) => {
  script.print('Normal line').ln();
  script.centerText('Some centered text');
  script.barcode('abarcode');
  script.commands.push('HT 10', 'PRINT col 10', 'HT 30', 'PRINT col 30', 'LF');
  script
    .print('Underlined row')
    .underlineCurrentRow()
    .ln();
  script
    .print('underlined', { underline: true })
    .print(' ')
    .print('bold', { bold: true })
    .ln();
  script.print('Large text', { size: 2 }).ln();
  script.print('Larger text', { size: 3 }).ln();
  script
    .print('small ')
    .print('large bold underlined', { size: 2, bold: true, underline: true })
    .ln();
  return script;
};

// prettier-ignore
export const printPrinterTest = (script:PrinterScript) => {
  script
    .print(`Printer operational`).ln()
    .print(`This printer is "${script.printer.name}"`)
    .td('ID', { width: 12, bold: true }).td(script.printer.id, { width: 32 })
    .commitLine()
    .ln().hr().hr()
    .print('CHARSET TEST', { size: 2, bold: true }).ln();
  printCharsetGrid(script).hr()
    .print('WIDTH TEST', { size: 2, bold: true }).ln()
    .print('10--,----|').ln()
    .print('20--,----|----,----|').ln()
    .print('30--,----|----,----|----,----|').ln()
    .print('40--,----|----,----|----,----|----,----|').ln()
    .print('50--,----|----,----|----,----|----,----|----,----|').ln()
    .print('60--,----|----,----|----,----|----,----|----,----|----,----|').ln()
    .print('70--,----|----,----|----,----|----,----|----,----|----,----|----,----|',).ln()
    .hr()
    .print('FEATURE TEST', { size: 2, bold: true }).ln()
  printSampleScript(script)
    .ln();
  return script;
};

/**
 * @extends PrinterScript
 * A PrinterScript with utility commands to print out various sections of productColumn sales receipt
 */
export class PrinterScriptSalesReceipt extends PrinterScript {
  /**
   * Print the company data
   * @example
   * > Dino corp OÜ
   * > Shop avenue 32, 12345 Tallinn, Eesti
   * > Phone: 12345678
   * > Reg code: 3323033230, VAT no.: 33239
   * >
   */
  companyData(company, warehouse, POS) {
    this.print(`${company.name} - ${POS.name}`, { size: 1 })
      .ln()
      .print(warehouse.address)
      .ln()
      .print(`Phone: ${POS.phone}`)
      .ln()
      .print(`Reg code: ${company.code}`)
      .print(', ')
      .print(`VAT no.: ${company.VAT}`)
      .ln();
  }

  /**
   * Print the receipt data
   * @example
   * > Receipt number: 14000030
   * > 9/23/2019, 11:51:01 AM
   * >
   */
  receiptData(receiptNumber) {
    this.print(`Receipt number: `, { underline: true })
      .print(receiptNumber, { underline: true, bold: true })
      .ln()
      .print(new Date().toLocaleString())
      .ln();
  }

  /**
   * Print the bill
   * @example
   * > Product                 Amount   Price    Total
   * > Coca-Cola                   1   175.00   175.00
   * > Friikartulid                2     1.50     3.00
   * > Friikartulid (Suur)         4     2.25     9.00
   * >
   * >               _________________________________
   * >                         Subtotal:        374.00
   * >               _________________________________
   * >                         Base price:      312.66
   * >                         VAT:              61.34
   * >               _________________________________
   * >                         TOTAL EUR:       374.00
   * >
   * >                         Paid (card):       2.00
   * >                         Paid (cash):      23.20
   * >
   */
  billTable(
    { billRows, subTotal, basePrice, VAT, total, payments, currencyCode },
    { giftReceipt, width = 48 },
  ) {
    const b = 8;
    const c = 12;
    const d = 8;
    const a = width - b - c - d;
    const productColumn = { width: a };
    const amountPriceColumn = { width: b, bold: true };
    const TotalColumn1 = { width: c, alignRight: true, bold: true };
    const TotalColumn2 = { width: d, alignRight: true, bold: true };
    const subTotalColumn = { width: b + c };

    this.td('Product', productColumn).td('Amount', amountPriceColumn);
    if (!giftReceipt) {
      this.td('Price', TotalColumn1).td('Total', TotalColumn2);
    }
    this.commitLine({ underlineCurrentRow: true });

    billRows.forEach(row => {
      this.td(row.itemName || row.product.name, productColumn);
      this.td(row.amount, amountPriceColumn);
      if (!giftReceipt) {
        const finalPrice = round(row.finalPriceWithVAT, 2);
        const discount = Number(row.discount);
        if (discount) {
          const discountPercentage = `-${round(discount, 2)}%`.padStart(4, ' ');
          this.td(`${finalPrice}\n${discountPercentage}`, TotalColumn1);
        } else {
          this.td(String(finalPrice), TotalColumn1);
        }
        this.td(round(row.rowTotal, 2), TotalColumn2);
      }
      this.commitLine();
    });

    this.ln();
    this.hr(10);
    if (giftReceipt) return;

    // prettier-ignore
    this.td('`',productColumn).td('Subtotal:', subTotalColumn).td(subTotal, TotalColumn2).commitLine();
    this.hr(10);
    // prettier-ignore
    this.td('`',productColumn).td('Base price:', subTotalColumn).td(basePrice, TotalColumn2).commitLine();
    // prettier-ignore
    this.td('`',productColumn).td('VAT:', subTotalColumn).td(VAT, TotalColumn2).commitLine();
    this.hr(10);
    this.td('`', productColumn)
      .td(`TOTAL ${currencyCode}:`, { ...subTotalColumn, size: 1 })
      .td(round(total, 2), { ...TotalColumn2, size: 1 })
      .commitLine();
    this.ln();

    payments.forEach(pmt => {
      this.td('`', productColumn)
        .td(`Paid (${pmt.type.toLowerCase()}):`, subTotalColumn)
        .td(`${round(pmt.sum, 2)}`, TotalColumn2)
        .commitLine();
    });
  }
}
