import axios from 'axios';

function getBaseUrl(isProduction) {
  return isProduction
    ? 'https://online-order-microservice.mmms.ca/api'
    : 'https://dev22-onlineorderms.mmms.ca/api';
}

interface StatusUpdate {
  invoiceId: number;
  status: 'PICKING' | 'PICKED' | 'DELIVERED' | 'CANCELLED';
  orderType: 'delivery' | 'pickup';
  clientCode: number;
  warehouseId: number;
  success: boolean;
}

export async function updateOrderStatus({
  payload,
  accessToken,
  isProduction,
}: {
  payload: StatusUpdate;
  accessToken: string;
  isProduction: boolean;
}): Promise<StatusUpdate> {
  const baseUrl = getBaseUrl(isProduction);
  return axios
    .post(`${baseUrl}/Erply/OrderStatusUpdate`, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    .then(({ data }) => data);
}
