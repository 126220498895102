import React, { useState } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import { addError } from 'actions/Error';
import CloseButton from 'components/CustomButtons/CloseButton';
import Loader from 'components/Loader';
import GoErpApp from 'components/GoErpApp/GoErpApp';
import { messageTypes } from 'plugins/mm/constants';
import { getGoErpTemplateBaseUrl } from 'reducers/configs/serviceEnpoints';

import './PirForm.module.scss';

interface Props {
  documentID: number;
  onClose(): void;
}

function PirForm({ documentID, onClose }: Props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const templateBaseUrl = useSelector(getGoErpTemplateBaseUrl);
  const appUrl = templateBaseUrl
    ? `${templateBaseUrl}/pir-form-page?documentID=${documentID}`
    : null;

  // There is no way to check if iframe has loaded successfully using iframe events
  // so instead just check directly if the resource iframe is using is accessible
  const { error: iframeContentError } = useAsync(
    () =>
      appUrl
        ? window.fetch(appUrl, { credentials: 'include' })
        : Promise.reject(new Error('GoErp app URL not found')),
    [appUrl],
  );

  async function messageHandler({ data: { type, payload } }: MessageEvent) {
    if (type === messageTypes.CLOSE_APP) {
      if (!payload.wasCompletedWithoutErrors) {
        dispatch(addError('An error occurred in the PIR form'));
      }
      onClose();
    }
  }

  return (
    <div data-testid="PirForm">
      <Box display="flex" alignItems="center" padding="1rem">
        <Typography variant="h5">PIR form</Typography>
        <Box flexGrow={1} />
        {iframeContentError ? <CloseButton action={onClose} /> : null}
      </Box>
      <Divider />
      <Box>
        <Loader show={loading} block>
          <GoErpApp
            title="PIR form"
            url={`/pir-form-page?documentID=${documentID}`}
            onStartLoading={() => setLoading(true)}
            onLoad={() => setLoading(false)}
            onMessage={messageHandler}
            iframeProps={{
              style: {
                width: '100%',
                height: 'calc(100vh - 250px)',
              },
            }}
          />
        </Loader>
      </Box>
    </div>
  );
}

export default PirForm;
