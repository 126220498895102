/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { urlEncode } from 'utils';
import { RootState } from 'reducers';

import { apiUrl } from './constants';
import { getSumToSend } from './utils';
import {
  CreateIntentPayload,
  CancellationBodyType,
  CreateReaderPayload,
  Reader,
  PaymentIntent,
  Refund,
  StripeLocation,
  AddressData,
} from './types';

import { getRequestHeaders } from '.';

export const fetchConnectionToken = async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
  return window
    .fetch(`${apiUrl}/terminal/connection_tokens`, {
      method: 'POST',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data.secret;
    });
};

export const unexpectedDisconnect = () => {
  // In this function, your app should notify the user that the reader disconnected.
  // You can also include a way to attempt to reconnect to a reader.
  console.log('Disconnected from reader');
};

export const createPaymentIntent = ({
  amount,
  currency,
  receipt_email,
}: CreateIntentPayload) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  const amountToSend = getSumToSend(amount, currency);
  const body = urlEncode({
    ...(receipt_email ? { receipt_email } : {}),
    amount: amountToSend,
    currency,
    'payment_method_types[0]': 'card_present',
  });
  return window
    .fetch(`${apiUrl}/payment_intents`, {
      method: 'POST',
      headers,
      body,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data as PaymentIntent;
    });
};

export const cancelPaymentIntent = ({
  payment_intent,
  reason,
}: {
  payment_intent: string;
  reason: CancellationBodyType['cancellation_reason'];
}) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  const body = urlEncode({
    cancellation_reason: reason,
  });
  return window
    .fetch(`${apiUrl}/payment_intents/${payment_intent}/cancel`, {
      method: 'POST',
      headers,
      body,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data as PaymentIntent;
    });
};

export const cancelReaderAction = (reader_id: string) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/terminal/readers/${reader_id}/cancel_action`, {
      method: 'POST',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data;
    });
};

export const createRefundIntent = ({
  payment_intent,
  amount,
}: {
  payment_intent: string;
  amount?: number;
}) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());

  const body = urlEncode(
    amount
      ? {
          payment_intent,
          amount,
        }
      : {
          payment_intent,
        },
  );

  return window
    .fetch(`${apiUrl}/refunds`, {
      method: 'POST',
      headers,
      body,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data as Refund;
    });
};

export const createReader = ({
  location,
  registration_code,
  label,
}: CreateReaderPayload) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  const body = urlEncode({
    location,
    registration_code,
    ...(label ? { label } : {}),
  });

  const reader = window
    .fetch(`${apiUrl}/terminal/readers`, {
      method: 'POST',
      headers,
      body,
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data as Reader;
    });

  return reader;
};

export const updateReader = ({
  label,
  readerID,
}: {
  label: string;
  readerID: string;
}) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const body = urlEncode({
    label,
  });

  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/terminal/readers/${readerID}`, {
      method: 'POST',
      headers,
      body,
    })
    .then(response => response.json())
    .then(resp => {
      if (resp.error) {
        throw new Error(resp.error.message);
      }
      return resp.data as Reader[];
    });
};

export const getAllOnlineReaders = ({
  location,
}: {
  location?: string;
}) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const params = urlEncode({
    ...(location ? { location } : {}),
    limit: 20,
    status: 'online',
  });
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/terminal/readers?${params}`, {
      method: 'GET',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(resp => {
      if (resp.error) {
        throw new Error(
          resp?.error?.message ?? 'Could not connect to Stripe API',
        );
      }
      return resp.data as Reader[];
    })
    .catch(e => {
      console.error('Failed to get online readers. Error: ', e.message ?? e);
      throw new Error(`Failed to get online readers. Error: ${e.message ?? e}`);
    });
};

export const getAllLocations = () => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/terminal/locations`, {
      method: 'GET',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(resp => {
      if (resp.error) {
        throw new Error(
          resp?.error?.message ?? 'Could not connect to Stripe API',
        );
      }
      return resp.data as StripeLocation[];
    })
    .catch(e => {
      const errMsg = `Failed to get locations. Error: ${e.message ?? e}`;
      console.error(errMsg);
      throw new Error(errMsg);
    });
};
// region Deprecated API calls. Deprecated due to not being used, but kept in case future developments require these
/**
 * @deprecated unused by POS
 */
const getReaderByID = ({ readerID }) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/terminal/readers/${readerID}`, {
      method: 'GET',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data as Reader;
    });
};

/**
 * @deprecated unused by POS
 */
const getPaymentIntentById = ({
  payment_intent,
}: {
  payment_intent: string;
}) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/payment_intents/${payment_intent}`, {
      method: 'GET',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data as PaymentIntent;
    });
};

/**
 * @deprecated unused by POS
 */
const getRefundById = ({ refund_id }: { refund_id: string }) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/refunds/${refund_id}`, {
      method: 'GET',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data as Refund;
    });
};

/**
 * @deprecated unused by POS
 */
const capturePaymentIntent = ({
  payment_intent,
}: {
  payment_intent: string;
}) => async (
  _dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/payment_intents/${payment_intent}/capture`, {
      method: 'POST',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data as Reader;
    });
};

/**
 * @deprecated Cannot cancel a refund - refund is automatic
 */
const cancelRefund = (refund_id: string) => async (
  _dispatch,
  getState: () => RootState,
) => {
  const headers = getRequestHeaders(getState());
  return window
    .fetch(`${apiUrl}/refunds/${refund_id}/cancel`, {
      method: 'POST',
      headers,
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data;
    });
};

/**
 * @deprecated Unused by POS, was used for testing
 */
const createLocation = (addressData: AddressData, accountID) => async (
  _dispatch,
  getState: () => RootState,
) => {
  const { line1, city, state, country, postalCode, displayName } = addressData;
  if (!country) {
    throw new Error('Failed to create Location in Stripe: Country is missing');
  }
  if (!displayName) {
    throw new Error(
      'Failed to create Location in Stripe: Display Name is missing',
    );
  }
  if (!accountID) {
    throw new Error(
      'Failed to create Location in Stripe: Account ID is missing',
    );
  }
  const headers = getRequestHeaders(getState());
  const body = urlEncode({
    line1,
    city,
    state,
    country,
    postal_code: postalCode,
    displayName,
    accountID,
  });
  const location = window
    .fetch(`${apiUrl}/terminal/locations`, {
      method: 'POST',
      headers,
      body,
    })
    .then(response => response.json());

  return location as Promise<Location>;
};
// endregion
