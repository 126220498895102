import { hasCookie, setCookieTemporary } from './cookie';
import { InstallerUpdateDialog } from './UI';
import { triggerWorkaroundInstallerUpdate } from './script';
import { TARGET_CERT_DATE } from './CONFIGURATION';

const isTooLate = TARGET_CERT_DATE.getTime() < new Date().getTime();
const workaround = {
  /**
   * True if workaround has already been run (or it's too late anyway and we should act as if it had)
   */
  hasAlreadyRun: hasCookie || isTooLate,
  /**
   * Whether the workaround script should be run and UI shown
   *
   * Initially true, but after running the workaround and pressing continue, a cookie gets set
   * so that on subsequent refreshes this is false instead
   *
   * Also false if it's already past the expiration date (because there's no point running the script anymore)
   */
  get shouldRun() {
    if (workaround.hasAlreadyRun) return false;
    if (isTooLate) return false;
    if ((window as any).wrapperInfo) return false;
    return true;
  },
  skip: setCookieTemporary,
  /**
   * Function to trigger the workaround
   */
  run: triggerWorkaroundInstallerUpdate,
  /**
   * UI to show the current state of the update to the user & when it's done, to set the cookie and allow them to continue
   */
  UI: InstallerUpdateDialog,
};
export default workaround;
