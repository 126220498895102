import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getSelectedOrder } from 'reducers/ShoppingCart';
import { setOrderEmployeeID } from 'actions/ShoppingCart';
import {
  getCommissionable,
  getOtherCommissionable,
} from 'reducers/cachedItems/employees';

import '../ProductForm/ProductForm.scss';

const ProductCommissionsForm: FC<{ onClose: () => void }> = ({ onClose }) => {
  const order = useSelector(getSelectedOrder);
  const recommendedCommissions = useSelector(getCommissionable);
  const nonRecommendedCommissions = useSelector(getOtherCommissionable);
  const dispatch = useDispatch();
  const setCommission = id => {
    dispatch(setOrderEmployeeID(id, order.orderIndex));
  };
  const { t } = useTranslation('shoppingCart');

  return (
    <>
      <Modal.Header>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('commission.title')}
        </span>

        <div style={{ flex: 1 }} />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="product-order-form">
          <div className="product-body">
            <div className="commission">
              <ToggleButtonGroup
                name="commission"
                type="radio"
                value={order.employeeID}
                onChange={setCommission}
              >
                {recommendedCommissions.map(
                  ({ employeeID, firstName, lastName }) => (
                    <ToggleButton
                      variant="light"
                      key={employeeID}
                      value={employeeID}
                      disabled={!employeeID}
                    >
                      {firstName} {lastName}
                    </ToggleButton>
                  ),
                )}
              </ToggleButtonGroup>
              <hr />
              <ToggleButtonGroup
                name="commission"
                type="radio"
                value={order.employeeID}
                onChange={setCommission}
              >
                {nonRecommendedCommissions.map(
                  ({ employeeID, firstName, lastName }) => (
                    <ToggleButton
                      variant="light"
                      key={employeeID}
                      value={employeeID}
                      disabled={!employeeID}
                    >
                      {firstName} {lastName}
                    </ToggleButton>
                  ),
                )}
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ProductCommissionsForm;
