import i18next from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { modalPages } from 'constants/modalPage';
import { getCardPaymentsForIntegration } from 'reducers/Payments';
import {
  markForProcessing,
  unmarkFromProcessing,
  deletePayment,
} from 'actions/Payments';
import { addSuccess } from 'actions/Error';
import { chainPromises } from 'utils';
import { createConfirmation } from 'actions/Confirmation';
import { getActivePaymentIntegration } from 'reducers/cafaConfigs';
import { RootState } from 'reducers';

export const customComponent = modalPages.integrationsExternal;

export const voidPayments = () => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const currentIntegration = getActivePaymentIntegration(getState());
  const cardPayments = getCardPaymentsForIntegration('external')(getState())
    .filter(
      payment =>
        payment.paymentIntegration ?? currentIntegration === 'external',
    )
    .filter(payment => payment.paid);

  cardPayments.forEach(payment =>
    dispatch(markForProcessing({ key: payment.key })),
  );

  if (cardPayments.length) {
    const creators = cardPayments.map(payment => () =>
      new Promise((resolve, reject) =>
        dispatch(
          createConfirmation(resolve, reject, {
            title: i18next.t('alerts:payments.confirmVoid.secondTitle'),
            body:
              i18next.t('alerts:payments.confirmVoid.secondBody') +
              payment.cardType,
          }),
        ),
      ).then(() => dispatch(deletePayment({ key: payment.key }))),
    );

    await chainPromises(...creators).finally(() => {
      getCardPaymentsForIntegration('external')(getState())
        .filter(payment => payment.paid)
        .forEach(payment =>
          dispatch(unmarkFromProcessing({ key: payment.key })),
        );
    });

    await dispatch(
      addSuccess('External payments voided successfully', {
        selfDismiss: true,
      }),
    );
  }
  return true;
};
