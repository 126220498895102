/* eslint-disable no-await-in-loop */
import { REDUX_WAREHOUSEID } from 'constants/persistence';

import { getTableMeta, SO, SOName } from './types';
import getDB from './getDB';

export const resetDB = async (clientCode, SO_NAME: SOName) => {
  const instance = await getDB(clientCode);
  const warehouseID = await instance.get(SO.SHARED.NAME, REDUX_WAREHOUSEID);
  const TABLE_META = getTableMeta(SO_NAME, Number(warehouseID));
  if (SO.PRODUCT_STOCK.NAME === SO_NAME) {
    let warehouseCursor = await instance
      .transaction(SO_NAME, 'readwrite')
      .store.index(SO.PRODUCT_STOCK.INDEXES.WAREHOUSE_ID.indexName)
      .openCursor(Number(warehouseID));
    while (warehouseCursor) {
      await warehouseCursor.delete();
      warehouseCursor = await warehouseCursor.continue();
    }
  } else {
    await instance.clear(SO_NAME);
  }
  await instance.delete(SO.META.NAME, TABLE_META.CURRENT_DELETE_PAGE);
  await instance.delete(SO.META.NAME, TABLE_META.LAST_SYNC_SERVER);
  await instance.delete(SO.META.NAME, TABLE_META.CURRENT_SYNC_PAGE);
  await instance.delete(SO.META.NAME, TABLE_META.CURRENT_SYNC_SERVER);
};

export default resetDB;
