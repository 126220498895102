import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InputField from 'components/FieldTypes/InputField';
import { integer } from 'components/FieldTypes/formatters';
import { ReturnRow } from 'types/ReturnTable';
import { setOneOrderSelected } from 'actions/returnProducts';

type Props = {
  row: ReturnRow;
};

const RowAmount: React.FC<Props> = ({ row }) => {
  const { t } = useTranslation('return', { keyPrefix: 'errors' });

  const dispatch = useDispatch();
  const amountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      setOneOrderSelected(row.stableRowID, {
        ...row,
        amount: e.target.value,
      }),
    );
  };

  const isSelected = !!row?.selected;

  const errorText = (() => {
    if (Number(row.amount) === 0) {
      return t('minAmount', { amount: Math.min(1, row.remainingAmount) });
    }
    if (Number(row.amount) > Number(row.remainingAmount)) {
      return t('maxAmount');
    }
    return '';
  })();

  return (
    <InputField
      className={!isSelected ? 'isHidden' : ''}
      disabled={!isSelected}
      onChange={amountChange}
      data-testid="product-row-qty"
      errorText={errorText}
      formatter={integer}
      type="number"
      value={String(row.amount ?? 0)}
    />
  );
};

export default RowAmount;
