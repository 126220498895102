import { Action, combineReducers, Reducer } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, ThunkAction as ReduxThunkAction } from 'redux-thunk';

import CampaignsDB from 'reducers/CampaignsDB';
import UI from 'reducers/UI';
import Login from 'reducers/Login';
import Error from 'reducers/Error';
import PointsOfSale from 'reducers/PointsOfSale';
import modalPage from 'reducers/modalPage';
import customerSearch from 'reducers/customerSearch';
import sales from 'reducers/sales';
import connectivity from 'reducers/connectivity';
import OpenCloseDay from 'reducers/OpenCloseDay';
import warehouses from 'reducers/warehouses';
import storeRegion from 'reducers/storeRegions';
import configs from 'reducers/configs';
import OnScreenKeyboard from 'reducers/OnScreenKeyboard';
import Confirmation from 'reducers/Confirmation';
import shoppingCart from 'reducers/ShoppingCart';
import clockInOut from 'reducers/clockInOut';
import reasonCodesDB from 'reducers/reasonCodesDB';
import productGroupsDB from 'reducers/productGroupsDB';
import vatRatesDB from 'reducers/vatRatesDB';
import productUnitsDB from 'reducers/productUnitsDB';
import giftcards from 'reducers/giftcards';
import Payments from 'reducers/Payments';
import Plugins from 'reducers/Plugins';
import PaymentTypes from 'reducers/PaymentTypes';
import assortmentProducts from 'reducers/assortmentProducts';
import cafaConfigs from 'reducers/cafaConfigs';
import returnProducts from 'reducers/returnProducts';
import cachedItems from 'reducers/cachedItems';
import { TYPE_AUTO_LOGIN, TYPE_LOGIN } from 'constants/Login';

function isLoginAction(
  a: Action,
): a is { type: string; payload: { clientCode: string } } {
  return a.type === TYPE_LOGIN || a.type === TYPE_AUTO_LOGIN;
}

/**
 * Wraps a reducer. The wrapped reducer will behave as normal, but will keep track of TYPE_LOGIN actions.
 * Every time a TYPE_LOGIN action happens, unless it's the same clientcode as last time the reducer will revert to its initial state
 *
 * For example
 * <pre>
 * @@INIT
 * CACHE_LOADED
 * LOGIN 100001 // REDUCER RESET TO INITIAL STATE
 * SELECTPOS
 * ADD PRODUCT
 * HARD LOGOUT
 * LOGIN 100001
 * HARD LOGOUT
 * LOGIN 100002 // REDUCER RESET TO INITIAL STATE
 * HARD LOGOUT
 * LOGIN 100001 // REDUCER RESET TO INITIAL STATE
 * </pre>
 * @param reducer
 */
function resetOnHardLogout<S, A extends Action<string>>(
  reducer: Reducer<S, A>
): Reducer<S, A> {
  /** The clientcode from the last-seen LOGIN action */
  let currentClientcode: string | null = null;

  return (state, action: A) => {
    if (isLoginAction(action)) {
      if (action.payload.clientCode !== currentClientcode) {
        currentClientcode = action.payload.clientCode;
        // Casting required, because some of our reducers claim to require a subtype of A
        // (for example only actions where the payload has a 'count' property
        // In practice, all reducers must be able to accept a type-only action
        // so this should be fine
        // Once the top-level reducers have been updated to all accept a regular 'Action'
        // (as they should), this cast will no longer be necessary
        return reducer(undefined, ({ type: '@@INIT' } as unknown) as A);
      }
    }
    return reducer(state, action);
  };
}

const rootReducer = combineReducers({
  configs: resetOnHardLogout(configs),
  UI, // Application state, doesn't need to reset on login
  Login, // If login resets on login then you can't log in
  Error, // Application state, doesn't need to reset on login
  cafaConfigs: resetOnHardLogout(cafaConfigs),
  PointsOfSale: resetOnHardLogout(PointsOfSale),
  modalPage: resetOnHardLogout(modalPage),
  customerSearch: resetOnHardLogout(customerSearch),
  sales: resetOnHardLogout(sales),
  connectivity, // Device state, irrespective of account
  OpenCloseDay: resetOnHardLogout(OpenCloseDay),
  warehouses: resetOnHardLogout(warehouses),
  storeRegion: resetOnHardLogout(storeRegion),
  OnScreenKeyboard: resetOnHardLogout(OnScreenKeyboard),
  Confirmation, // Application state, doesn't need to reset on login
  cachedItems: resetOnHardLogout(cachedItems),
  shoppingCart: resetOnHardLogout(shoppingCart),
  clockInOut: resetOnHardLogout(clockInOut),
  reasonCodesDB: resetOnHardLogout(reasonCodesDB),
  productGroupsDB: resetOnHardLogout(productGroupsDB),
  vatRatesDB: resetOnHardLogout(vatRatesDB),
  productUnitsDB: resetOnHardLogout(productUnitsDB),
  CampaignsDB: resetOnHardLogout(CampaignsDB),
  giftcards: resetOnHardLogout(giftcards),
  Payments: resetOnHardLogout(Payments),
  Plugins: resetOnHardLogout(Plugins),
  PaymentTypes: resetOnHardLogout(PaymentTypes),
  returnProducts: resetOnHardLogout(returnProducts),
  assortmentProducts: resetOnHardLogout(assortmentProducts),
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export type RootGetState = () => RootState;
export type ThunkAction<R = void> = ReduxThunkAction<
  Promise<R>,
  RootState,
  unknown,
  Action
>;
export type RootDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

export const useAppDispatch: () => RootDispatch = useDispatch;
