import React, { FC, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import { Alert } from '@material-ui/lab';

import useProducts from 'utils/hooks/useProducts';
import useProductImages from 'utils/hooks/useProductImages';

import ThumbnailCarousel from './ThumbnailCarousel';
import SingleImageView from './SingleImageView';

const NoImageFallback = () => {
  const { t } = useTranslation('shoppingCart');
  return (
    <div className="product-image">
      <div className="image-instructions">
        <span>{t('productForm.image', { context: 'placeholder' })}</span>
      </div>
    </div>
  );
};

type Props = {
  product: ReturnType<typeof useProducts>['products'][number];
};

const ProductImages: FC<Props> = ({ product }) => {
  const [currentItem, setCurrentItem] = useState(0);

  const { t } = useTranslation('shoppingCart');

  const { value: imageUrls, error } = useProductImages(product);

  const incrementItem = () =>
    setCurrentItem(prev => {
      return prev === (imageUrls?.length ?? 0) - 1 ? 0 : prev + 1;
    });

  const decrementItem = () =>
    setCurrentItem(prev => {
      return prev > 0 ? prev - 1 : (imageUrls?.length ?? 0) - 1;
    });

  const [showSingleImage, setShowSingleImage] = useState(false);

  const divEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (divEl.current) {
      divEl.current.focus();
    }
  }, [divEl]);

  if (error) {
    return <Alert severity="error">{t('productForm.error')}</Alert>;
  }

  if (!imageUrls || imageUrls.length === 0) return <NoImageFallback />;

  return (
    <div
      className="photos-carousel-wrapper"
      ref={divEl}
      onKeyDown={e => {
        switch (e.key) {
          case 'ArrowRight':
            incrementItem();
            break;
          case 'ArrowLeft':
            decrementItem();
            break;
          default:
          // nothing
        }
      }}
    >
      <Carousel
        activeIndex={currentItem}
        className="carousel-container"
        controls={true}
        interval={null}
        slide={false}
        indicators={false}
        onSelect={setCurrentItem}
      >
        {imageUrls.map(imageUrl => (
          <Carousel.Item key={imageUrl.xs}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              src={imageUrl.md}
              alt={imageUrl.md}
              data-testid="image"
              data-test-key={imageUrl.md}
              onClick={() => setShowSingleImage(true)}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <ThumbnailCarousel
        imageUrls={imageUrls.map(i => i.xs)}
        setCurrentItem={setCurrentItem}
      />
      <SingleImageView
        show={showSingleImage}
        productName={product.name}
        imageUrl={imageUrls?.[currentItem]?.md}
        close={() => setShowSingleImage(false)}
      />
    </div>
  );
};

export default ProductImages;
