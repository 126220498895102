/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch } from 'react-redux';

import { addProduct } from 'actions/ShoppingCart/addProduct';
import Reorderable from 'utils/HOCs/Reorderable';

import './DraggableTable.sass';
import DropdownSearch from 'components/DropdownSearch';

/**
 * @param {{key: string, name: string}[]} propCols
 * @param {Object<string, any>} data
 * @returns {*}
 * @constructor
 */
const DraggableTable = ({
  columns = [],
  data = [],
  order,
  setOrder,
  setSorting,
  creteria,
  ascending,
  setItems,
  ...props
}) => {
  const dispatch = useDispatch();
  // State for saving the already filtered products
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [filters, setFilters] = useState([]);
  const noDragOrder = order.filter(i =>
    columns[i] ? columns[i].noDrag : null,
  );
  const dragOrder = order.filter(i => (columns[i] ? !columns[i].noDrag : null));

  const fixedOrder = noDragOrder.concat(dragOrder);

  const newColumns = columns.filter((c, i) => order.indexOf(i) < 0);
  const columnsSorted = fixedOrder
    .map(index => columns[index])
    .filter(a => a)
    .concat(newColumns);

  const moveTo = (from, to) => {
    setOrder(() => {
      const original = columnsSorted.map(sorted => columns.indexOf(sorted));
      original.splice(to, 0, original.splice(from, 1)[0]);
      return original;
    });
  };
  return (
    <Table {...props}>
      <thead>
        <tr>
          <Reorderable moveTo={moveTo}>
            {columnsSorted.map((col, i) => {
              return (
                <HeaderCell
                  title={col.title}
                  active={creteria === col.key}
                  setSorting={() => setSorting(col.key)}
                  keyValue={col.key}
                  ascending={ascending}
                  stylingClass={
                    col.title.length > 7 ? 'longHeader' : 'normHeader'
                  }
                  data-index={i}
                  key={col.key}
                  setItems={setItems}
                  keyForSearch={col.key}
                  filteredProducts={filteredProducts}
                  setFilteredProducts={setFilteredProducts}
                  filters={filters}
                  setFilters={setFilters}
                />
              );
            })}
          </Reorderable>
        </tr>
      </thead>

      <TableBody columns={columnsSorted} data={data} dispatch={dispatch} />
    </Table>
  );
};

const HeaderCell = ({
  title,
  stylingClass,
  setSorting,
  setFiltering,
  active,
  ascending,
  keyValue,
  setItems,
  keyForSearch,
  filteredProducts,
  setFilteredProducts,
  filters,
  setFilters,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  return (
    <th
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className={stylingClass}
      {...rest}
    >
      <div className={`header-cell header-cell-${keyValue}`}>
        <div
          style={{
            fontWeight: active ? 700 : 400,
            display: 'flex',
            width:
              stylingClass === 'longHeader' || title === 'Code 3'
                ? '125px'
                : '100%',
            justifyContent: 'space-between',
          }}
          onClick={setSorting}
        >
          <div>{title}</div>
        </div>
        {active && (
          <span
            style={{
              marginLeft: 'auto',
              marginRight: '.5em',
            }}
          >
            <span className={ascending ? 'arrow_down' : 'arrow_up'} />
          </span>
        )}
        <DropdownSearch
          keyValue={keyValue}
          show={show}
          setItems={setItems}
          keyForSearch={keyForSearch}
          filteredProducts={filteredProducts}
          setFilteredProducts={setFilteredProducts}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
    </th>
  );
};

const TableBody = ({ columns = [], data = [], dispatch }) => (
  <tbody>
    {!data.length ? (
      <p style={{ textAlign: 'center', marginTop: '25xp' }}>
        No results were found
      </p>
    ) : (
      data.map(d => (
        <TableRow
          key={d.productID}
          columns={columns}
          state={d}
          dispatch={dispatch}
        />
      ))
    )}
  </tbody>
);

const TableRow = ({ columns = [], state = {}, dispatch }) => (
  <tr>
    {columns.map(({ key }, index) => {
      if (index === 0) {
        return (
          <td style={{ width: '40px' }} key="add-cta">
            <button
              className="client-details-button"
              type="button"
              onClick={() => dispatch(addProduct(state))}
              style={{ borderRadius: '2.5px' }}
            >
              Add
            </button>
          </td>
        );
      }
      return <td key={key}>{state[key]}</td>;
    })}
  </tr>
);

export default DraggableTable;
