import { useUpdate } from 'react-use';
import { useEffect } from 'react';

/**
 * Returns the current time relative to the current anchor.
 * Rerenders the component every 'resolution' ms relative to the anchor time
 *
 * For example if you mount this component at 12:09, with a target time of 13:15, and a resolution of 1 hour:
 * * The initial render would have an offset of -01:06
 * * It would rerender at 12:15 with an offset of -01:00
 * * It would rerender at 13:15 with an offset of 00:00
 * * It would rerender at 14:15 with an offset of 01:00
 * * ...etc.
 *
 * The returned offset will always be valid on every render - the resolution only affects when/how often
 * this hook will force a rerender. This can be used to ensure that visible timers update
 *
 * @see https://jsfiddle.net/23085e6k/5/
 */
export const useRelativeTime = ({
  anchorTime,
  resolution,
}: {
  /** Target time to measure offset from & which resolution is aligned to, in milliseconds */
  anchorTime: number | undefined;
  /** Time difference between two updates/rerenders, in milliseconds */
  resolution: number;
}) => {
  const update = useUpdate();
  useEffect(() => {
    if (!anchorTime) return () => {};

    const deltaTime = Date.now() - anchorTime;
    const fraction = deltaTime % resolution;
    const remaining = resolution - fraction;

    let interval;
    // Wait for next resolution alignment (resolution multiple offset from target)
    const timeout = setTimeout(() => {
      update();
      // Then start an interval to rerender the component at the start of every <minute/second/other unit of precision>
      interval = setInterval(update, resolution);
    }, remaining + 1);

    return () => {
      clearTimeout(timeout);
      if (interval) clearInterval(interval);
    };
  }, [anchorTime, resolution]);
  if (anchorTime === undefined) return undefined;
  return Date.now() - anchorTime;
};
