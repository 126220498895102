import { PosPlugin } from 'plugins/plugin';

import { getCFRState } from '../rdx';
import { checkFranchiseDocuments, checkIfValidReturn } from '../utils';

type ModifyReturnDocuments = Required<
  Required<PosPlugin>['onFetchSalesDocsForReturn']
>['on'];

function removeFreightRows(
  fetchedDocuments: Parameters<ModifyReturnDocuments>[1],
) {
  return {
    ...fetchedDocuments,
    payload: fetchedDocuments.payload.map(document => ({
      ...document,
      rows: document.rows.filter(
        row =>
          row.itemName?.toUpperCase() !== 'FREIGHT0000' &&
          row.code?.toUpperCase() !== 'FREIGHT0000',
      ),
    })),
  };
}

const modifyReturnDocuments: ModifyReturnDocuments = (
  params,
  fetchedDocuments,
) => async (dispatch, getState) => {
  const { canPerformCFRReturn, isOnlineReturn } = getCFRState(getState());

  const fetchedDocumentsWithoutOrders = {
    payload: fetchedDocuments.payload.filter(
      document => document.type !== 'ORDER',
    ),
    returnedRows: fetchedDocuments.returnedRows,
  };

  if (
    !canPerformCFRReturn ||
    !isOnlineReturn ||
    fetchedDocuments.payload.length > 0
  ) {
    await Promise.all(
      fetchedDocuments.payload.map(document =>
        dispatch(checkIfValidReturn(document)),
      ),
    );

    return removeFreightRows(fetchedDocumentsWithoutOrders);
  }

  const documents = await dispatch(
    checkFranchiseDocuments(params.number, fetchedDocumentsWithoutOrders),
  );
  if (documents) return removeFreightRows(documents);
  return {
    payload: [],
    returnedRows: [],
  };
};

export default modifyReturnDocuments;
