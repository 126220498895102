import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';
import {
  Box,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { Field } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';
import { RootState } from 'reducers';

import { resetCustomerIDTaf, setCustomerIDTaf } from '../rdx/actions';
import { getCustomerID } from '../rdx/reducers';

const CustomerIDField = ({
  customerID,
  customerType,
}: {
  customerID: number | string;
  customerType: string;
}) => {
  return (
    <Field
      name="customerID"
      wrapper={Grid}
      wrapperProps={{ item: true, xs: 6 }}
    >
      <TextField
        name="customerID"
        fullWidth
        disabled
        variant="outlined"
        label={customerType}
        value={customerID ?? ''}
      />
    </Field>
  );
};

const UICustomerFormBeta: PosPlugin['UICustomerFormBeta'] = ({
  children,
  customer,
  onChange,
}) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { t } = useTranslation('createCustomer');
  const selectedCustomer = useSelector(getSelectedCustomer);
  const customerIDTaf = useSelector(getCustomerID);

  const { customerID } = customer;
  useEffect(() => {
    if (!customerID && !customerIDTaf) {
      // create customer on component mount to populate ID field
      // use random first name and last name, since this customer will be either:
      // 1) deleted
      // 2) names will be modified, because its impossible to save via UI otherwise
      doClientRequest<{
        customerID: number;
        clientID: number;
        alreadyExists: boolean;
      }>({
        request: 'saveCustomer',
        firstName: 'test',
        lastName: 'test',
      }).then(([createdCustomerData]) => {
        dispatch(setCustomerIDTaf(createdCustomerData.customerID));
      });
    }
  }, [customerIDTaf, customerID, dispatch]);

  // if we edit an existing customer, his home store would be saved in homeStore field
  // ,but our core form would look for homeStoreID
  // , so we need to populate homeStoreID with homeStore value
  useEffect(() => {
    if (customer.homeStore && !customer.homeStoreID) {
      onChange(data => {
        return {
          ...data,
          homeStoreID: Number(customer.homeStore),
        };
      });
    }
  }, [customer.homeStore, customer.homeStoreID, onChange]);

  useUnmount(() => {
    if (customerIDTaf && selectedCustomer.id !== customerIDTaf) {
      doClientRequest({
        request: 'deleteCustomer',
        customerID: customerIDTaf,
      });
      dispatch(resetCustomerIDTaf());
    }
  });

  if (!customerIDTaf && customerID !== selectedCustomer.id) {
    return (
      <Box width="100%" textAlign="center">
        <LinearProgress />
        <Typography variant="h6">Loading next customer id..</Typography>
      </Box>
    );
  }

  // create a new array with child components and insert the customer ID field into the array
  const updatedChildren = R.pipe(
    R.insert(
      2,
      <CustomerIDField
        customerID={customerIDTaf}
        customerType={
          customer.customerType === 'PERSON'
            ? t('fields.customerID')
            : t('fields.companyID')
        }
      />,
    ),
  )(children);

  return updatedChildren;
};

export default UICustomerFormBeta;
