import React, { useState } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';

import Icon from 'components/Icon';
import { addError, addSuccess } from 'actions/Error';

import './configuration.scss';
import { WbuPosPlugin, Warehouse } from '.';

export interface CustomPayment {
  name: string;
  cardType: string;
  enabled: boolean;
  limit: string;
}

export const WarehouseConfiguration: Required<
  WbuPosPlugin
>['ComponentConfigurationByLevel']['Warehouse'] = ({ current, save }) => {
  const [config, setConfig] = useState<Warehouse>(
    current ?? {
      customPayments: [],
    },
  );
  const dispatch = useDispatch();

  const saveConfig = () => {
    if (!config) {
      dispatch(addError('Configuration is empty'));
      return;
    }
    save(config);
    dispatch(addSuccess('Configuration saved'));
  };

  const onChangeProp = (props: (string | number)[]) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { type, value, checked },
    } = e;
    const newVal = type === 'checkbox' ? checked : value;
    setConfig(R.assocPath(props, newVal));
  };

  const addPayment = () => {
    setConfig(
      R.evolve({
        customPayments: R.append({
          name: '',
          cardType: '',
          enabled: false,
          limit: '',
        }),
      }),
    );
  };

  const removePayment = (i: number) => {
    setConfig(R.evolve({ customPayments: R.remove(i, 1) }));
  };

  return (
    <div className="config-content">
      <div className="custom-payments">
        <div>
          <Typography variant="h6">Custom payments</Typography>
          <Button variant="contained" color="secondary" onClick={addPayment}>
            Add
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Enable</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Card type</TableCell>
              <TableCell>Limit</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {config?.customPayments.map((payment, i) => (
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={onChangeProp(['customPayments', i, 'enabled'])}
                    checked={payment.enabled}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={onChangeProp(['customPayments', i, 'name'])}
                    value={payment.name}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={onChangeProp(['customPayments', i, 'cardType'])}
                    value={payment.cardType}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={onChangeProp(['customPayments', i, 'limit'])}
                    value={payment.limit}
                    type="number"
                    disabled={!payment.enabled}
                    fullWidth
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <Icon
                    name="erply_delete_color"
                    action={() => removePayment(i)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Button
        variant="contained"
        color="primary"
        className="save-button"
        onClick={saveConfig}
      >
        Save
      </Button>
    </div>
  );
};
