import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { List, makeStyles } from '@material-ui/core';

import { removeCoupon } from 'actions/ShoppingCart';
import { applyCoupons } from 'actions/ShoppingCart/applyCoupons';
import { getAppliedCoupons } from 'reducers/ShoppingCart';

import { getCoupons } from '../rdx/reducers';
import { setCoupons } from '../rdx/actions';

import Coupon from './Coupon';

const useStyles = makeStyles({
  list: { minHeight: '108px', height: 'calc(100vh - 370px)', overflow: 'auto' },
  listItem: { paddingTop: '16px' },
  checkBoxContainer: {
    marginTop: '8px',
    marginRight: '16px',
  },
  checkBox: { margin: '-8px' },
});

const useApplyCoupons = (selectedCouponIds: string[]) => {
  const dispatch = useDispatch();

  const coupons = useSelector(getCoupons);
  const appliedCoupons = useSelector(getAppliedCoupons);

  useDebounce(
    () => {
      const couponsToApply = coupons.filter(
        ({ uniqueIdentifier: issuedId }) => {
          const isSelected = selectedCouponIds.includes(issuedId);
          const isAlreadyApplied = appliedCoupons.some(
            ({ uniqueIdentifier: appliedId }) => issuedId === appliedId,
          );
          return isSelected && !isAlreadyApplied;
        },
      );
      if (couponsToApply.length) dispatch(applyCoupons(couponsToApply));

      const couponsToRemove = appliedCoupons.filter(
        ({ uniqueIdentifier: appliedId }) => {
          const isSelected = selectedCouponIds.includes(appliedId);
          return !isSelected;
        },
      );
      couponsToRemove.map(({ uniqueIdentifier }) =>
        dispatch(removeCoupon(uniqueIdentifier)),
      );
    },
    500,
    [selectedCouponIds, coupons, appliedCoupons, dispatch],
  );
};

interface Props {
  id: string;
}

function IssuedCoupons({ id }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const coupons = useSelector(getCoupons);
  const appliedCoupons = useSelector(getAppliedCoupons);
  const [checkedCouponIds, setCheckedCouponIds] = useState<string[]>(
    appliedCoupons.map(c => c.uniqueIdentifier),
  );

  function toggleCoupon(id: string) {
    return setCheckedCouponIds(prevIds =>
      prevIds.includes(id)
        ? prevIds.filter(prevId => prevId !== id)
        : [...prevIds, id],
    );
  }

  function isChecked(id: string) {
    return checkedCouponIds.includes(id);
  }

  function isUsed(id: string) {
    return (
      appliedCoupons.find(coupon => coupon.uniqueIdentifier === id)?.used ??
      false
    );
  }

  function removeCoupon(id: string) {
    if (isChecked(id)) {
      toggleCoupon(id);
    }
    const couponsWithoutRemoved = coupons.filter(
      coupon => coupon.uniqueIdentifier !== id,
    );
    dispatch(setCoupons(couponsWithoutRemoved));
  }

  useApplyCoupons(checkedCouponIds);

  return (
    <List data-testid="coupons" disablePadding className={classes.list}>
      {coupons.map(coupon => (
        <Coupon
          key={coupon.uniqueIdentifier}
          coupon={coupon}
          checked={isChecked(coupon.uniqueIdentifier)}
          toggleCoupon={toggleCoupon}
          removeCoupon={removeCoupon}
          used={isUsed(coupon.uniqueIdentifier)}
          classes={classes}
        />
      ))}
    </List>
  );
}

export default IssuedCoupons;
