/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import {
  ISdkManagedPaymentIntent,
  Terminal,
  loadStripeTerminal,
} from '@stripe/terminal-js';
import * as R from 'ramda';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import i18next from 'i18next';

import { getErrorMessage, withWaitingForTerminal } from 'paymentIntegrations';
import { CardPaymentHooks, FUNC_BUTTONS } from 'paymentIntegrations/types';
import { getCardPaymentsForIntegration, getPayments } from 'reducers/Payments';
import { RootState } from 'reducers';
import { sleep } from 'utils';
import { unmarkFromProcessing } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { getSelectedCustomer } from 'reducers/customerSearch';

import {
  CreateIntentActionPayload,
  PaymentIntent,
  ProcessPaymentsOutput,
  ReduxStripePayment,
  Refund,
} from '../types';
import {
  cancelPaymentIntent,
  cancelReaderAction,
  createPaymentIntent,
  createRefundIntent,
  fetchConnectionToken,
  unexpectedDisconnect,
} from '../API';
import { convertToErplySum, generateInputData, getSumToSend } from '../utils';
import { baseLog } from '../constants';

import { getStripeConfiguration } from '..';

let terminal: Terminal | null;

const getTerminal = () => async dispatch => {
  if (terminal) return terminal;
  return loadStripeTerminal().then(StripeTerminal => {
    const result = StripeTerminal?.create({
      onFetchConnectionToken: () => dispatch(fetchConnectionToken),
      onUnexpectedReaderDisconnect: unexpectedDisconnect,
    });
    if (result) {
      terminal = result;
      return result;
    }
    return terminal;
  });
};

const handlePaymentCollection = ({ payment, id, client_secret }) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState,
) => {
  const log = baseLog.extend('handlePaymentCollection');
  const config = getStripeConfiguration(getState());
  const { reader: configuredReader } = config ?? {};
  // If payment intent was created, need to save the intent ID to the redux payment object (as attribute)
  await dispatch(
    setPayment({
      ...payment,
      attributes: {
        client_secret,
        stripePaymentId: id,
      },
    }),
  );
  const terminal = await dispatch(getTerminal());
  if (!terminal) {
    throw new Error('Failed to fetch Stripe terminal');
  }
  try {
    const connectedReader = await terminal.getConnectedReader();
    // If no readers are connected, try discovering readers and connect the first one
    if (!connectedReader) {
      const discoverReadersResponse = await terminal.discoverReaders();
      // If some mistake while discovering, log and throw error
      if ('error' in discoverReadersResponse) {
        console.error(
          'Failed to discover readers: ',
          discoverReadersResponse.error.message,
        );
        throw new Error(
          i18next.t(
            'payment:cardPayments.stripe.alerts.noReadersDiscoveredWithError',
            { error: discoverReadersResponse.error.message },
          ),
        );
      }
      // No readers were discovered - need to throw since otherwise payment cannot be made
      if (discoverReadersResponse.discoveredReaders.length === 0) {
        console.error('No available readers.');
        throw new Error(
          i18next.t('payment:cardPayments.stripe.alerts.noReadersAvailable'),
        );
      }

      // Search only the online readers
      let readerToConnect = discoverReadersResponse.discoveredReaders.find(
        dr => dr.status === 'online',
      );
      if (!readerToConnect) {
        throw new Error(
          i18next.t(
            'payment:cardPayments.stripe.alerts.noOnlineReadersDiscovered',
          ),
        );
      }
      // Check if reader to connect matches the configured reader
      if (configuredReader && readerToConnect.id !== configuredReader.id) {
        const matchingReader = discoverReadersResponse.discoveredReaders.find(
          dr => dr.id === configuredReader.id,
        );
        if (matchingReader) {
          // If the configured reader is discovered, but is 'offline', throw error
          if (matchingReader.status === 'offline') {
            throw new Error(
              i18next.t(
                'payment:cardPayments.stripe.alerts.configuredTerminalDiscoveredIsOffline',
              ),
            );
          }
          // Otherwise set it as reader to connect since it means the device is online
          readerToConnect = matchingReader;
        }
      }
      await terminal.connectReader(readerToConnect);
    }
  } catch (e) {
    console.error('There was an error connecting the reader: ', e);
    throw e;
  }

  log('About to collect payment method for: ', { client_secret });
  const collection = await terminal.collectPaymentMethod(client_secret);
  log('Collect payment sent. Collection:', collection);

  if ('paymentIntent' in collection) {
    log(
      'Payment intent is present in the collection. Intent: ',
      collection.paymentIntent,
    );

    const confirmation = await terminal.processPayment(
      collection.paymentIntent,
    );
    log('Processed the payment. Confirmation: ', confirmation);

    if ('error' in confirmation) {
      log(
        'Payment processing failed with the following Error: ',
        confirmation.error,
      );
      throw new Error(confirmation.error.message);
    }
    if (
      'paymentIntent' in confirmation &&
      confirmation.paymentIntent.status === 'succeeded'
    ) {
      return confirmation.paymentIntent;
    }
    throw new Error(
      i18next.t('payment:cardPayments.stripe.alerts.failedToConfirmIntent'),
    );
  } else {
    throw new Error(
      i18next.t('payment:cardPayments.stripe.alerts.failedToCollectPayment'),
    );
  }
};

export const collectPayment = ({
  payment,
  receipt_email,
}: CreateIntentActionPayload) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
) => {
  const log = baseLog.extend('collectPayment');
  log('Collecting payment of: ', { payment });
  const { amount, currency: cur, currencyCode: curC } = payment;
  const currency = cur ?? curC;
  const payload =
    receipt_email && receipt_email.length
      ? { amount, currency, receipt_email }
      : { amount, currency };
  // If payment intent was already created, there's no point in creating it again
  if (!payment.attributes?.stripePaymentId) {
    log('No existing intent. Creating new one: ', { payload });
    return dispatch(createPaymentIntent(payload)).then(
      async ({ client_secret, id }) => {
        if (!client_secret) {
          throw new Error(
            i18next.t(
              'payment:cardPayments.stripe.alerts.failedToCreatePayment',
            ),
          );
        }
        log('Intent created. Collecting payment...');
        const res = await dispatch(
          handlePaymentCollection({ payment, id, client_secret }),
        );
        log('Payment collected: ', res);
        return res;
      },
    );
  }
  log('There is an existing intent for this payment. Collecting payment...');
  const result = await dispatch(
    handlePaymentCollection({
      payment,
      id: payment.attributes.stripePaymentId,
      client_secret: payment.attributes.client_secret,
    }),
  );
  log('Payment collected: ', result);

  return result;
};

export const collectRefund = ({
  payment_intent,
}: {
  payment_intent: string;
}) => async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
  const refundResult = await dispatch(
    createRefundIntent({
      payment_intent,
    }),
  );
  return refundResult;
};

export const confirmIntent = ({
  payment_intent,
}: {
  payment_intent: ISdkManagedPaymentIntent;
}) => async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
  const terminal = await dispatch(getTerminal());
  if (!terminal) {
    throw new Error(
      i18next.t('payment:cardPayments.stripe.alerts.failedToFetchTerminal'),
    );
  }

  const result = await terminal.processPayment(payment_intent);
  if ('error' in result) {
    throw new Error(result.error.message);
  }
  const { paymentIntent } = result;
  return paymentIntent;
};

export const refundPayment = ({
  payment_intent,
  amount,
  currency,
}: {
  payment_intent: string;
  amount: string;
  currency: string;
}) => async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
  const amountToSend = getSumToSend(amount, currency);

  const refundResult = await dispatch(
    createRefundIntent({
      payment_intent,
      amount: amountToSend,
    }),
  );
  return refundResult;
};

export const processPayments = (params: CardPaymentHooks) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const log = baseLog.extend('processPayments');
  const emptyResponse: ProcessPaymentsOutput = [];

  const {
    enableButtons,
    updateMessage,
    beforeDocDelete,
    beforeDocSave,
  } = params;
  const cardPayments: ReduxStripePayment[] = getCardPaymentsForIntegration(
    'stripe',
  )(getState());

  const customer = getSelectedCustomer(getState());

  // Voiding should happen not only for all the paid payments, but the ones that have had a payment intent created for them
  const shouldVoid = cardPayments.every(
    cp =>
      cp.paid &&
      cp.shouldProcess &&
      (cp?.attributes?.stripePaymentId || cp?.attributes?.stripeRefundId),
  );

  return cardPayments
    .filter(p =>
      shouldVoid
        ? p?.attributes?.stripePaymentId || p?.attributes?.stripeRefundId
        : !p.paid,
    )
    .reduce((prevPayment, currentPayment) => {
      return prevPayment.then(async ({ data, errors }) => {
        if (!errors.length) {
          try {
            await dispatch(
              withWaitingForTerminal(async () => {
                const inputData = generateInputData(currentPayment);
                enableButtons([FUNC_BUTTONS.CANCEL]);
                const amt = Number(currentPayment.amount).toFixed(2);
                const context = inputData.transactionType.toLocaleLowerCase();
                updateMessage(
                  i18next.t(
                    'paymentIntegrations:transactionMessages.processing',
                    {
                      context,
                      amount: amt,
                    },
                  ),
                );

                let record: PaymentIntent | Refund | undefined;

                if (inputData.transactionType === 'REFUND') {
                  const refundLog = log.extend('refund');
                  // Refund without receipt
                  if (!inputData.stripePaymentId) {
                    refundLog(
                      'Unreferenced refund was attempted, but it is not supported.',
                    );
                    throw new Error(
                      i18next.t(
                        'payment:cardPayments.stripe.alerts.unreferencedReturnNotSupported',
                      ),
                    );
                  }
                  record = await dispatch(
                    refundPayment({
                      payment_intent: inputData.stripePaymentId,
                      amount: inputData.amount,
                      currency: inputData.currency,
                    }),
                  );
                } else if (inputData.transactionType === 'VOID') {
                  const voidlog = log.extend('void');
                  const { stripePaymentId, stripeRefundId } =
                    currentPayment.attributes ?? {};

                  // When trying to cancel/void a refund, throw an error before sending a request to API
                  if (stripeRefundId) {
                    throw new Error(
                      i18next.t(
                        'payment:cardPayments.stripe.alerts.refundCancellationUnavailable',
                      ),
                    );
                  } else if (!stripePaymentId) {
                    throw new Error(
                      i18next.t(
                        'payment:cardPayments.stripe.alerts.cannotVoidWithoutIntent',
                      ),
                    );
                  } else {
                    record = await dispatch(
                      collectRefund({
                        payment_intent: stripePaymentId,
                      }),
                    );
                  }
                  voidlog('Voided the payment: ', { record });
                } else {
                  const paymentlog = log.extend('payment');
                  record = await dispatch(
                    collectPayment({
                      payment: currentPayment,
                      receipt_email: customer.email,
                    }),
                  );
                  paymentlog(record);
                }

                if (record) {
                  const latestPaymentState = getPayments(getState())[
                    currentPayment.key
                  ];
                  if (inputData.transactionType === 'VOID') {
                    updateMessage('Void successful!');
                    // Sleep so that there's time for cashier to see the msg
                    await sleep(1);
                    beforeDocDelete(currentPayment.key);
                  } else if (inputData.transactionType === 'REFUND') {
                    updateMessage('Refund successful!');
                    // Sleep so that there's time for cashier to see the msg
                    await sleep(1);
                    await dispatch(
                      setPayment({
                        ...latestPaymentState,
                        paid: true,
                        stripeRefund: record,
                        attributes: {
                          stripeRefundId: record.id,
                        },
                      }),
                    );
                  } else {
                    updateMessage('Payment successful!');
                    // Sleep so that there's time for cashier to see the msg
                    await sleep(1);
                    // If the payment was successfully collected, mark it so that it can be confirmed later
                    log('Payment successful. Got the following data: ', record);
                    const charge = (record as PaymentIntent).charges.data[0];
                    const cardPresentData =
                      charge.payment_method_details?.card_present;
                    await beforeDocSave({
                      key: currentPayment.key,
                      paid: true,
                      type: 'CARD',
                      amount: convertToErplySum(
                        Number(charge.amount),
                        charge.currency,
                      ),
                      cardType: cardPresentData?.brand?.toUpperCase(),
                      cardNumber: cardPresentData?.last4,
                      cardHolder: cardPresentData?.cardholder_name,
                      attributes: {
                        refNo: charge.id,
                        cardNumber: cardPresentData?.last4,
                        receiptUrl: charge.receipt_url,
                      },
                    });
                  }
                } else {
                  updateMessage(
                    i18next.t(
                      'payment:cardPayments.stripe.alerts.failedToProcessPayment',
                    ),
                  );
                  console.error('Unknown failure');
                  errors.push('error');
                }
              }),
            );
          } catch (e) {
            const error = e as Error;
            updateMessage(
              getErrorMessage(
                error,
                i18next.t(
                  'payment:cardPayments.stripe.alerts.failedToProcessPayment',
                ),
              ),
            );
            enableButtons(['close', 'retry-payment']);
            errors.push(error.message);
            console.error('Failed to process payments in Stripe', error);
          } finally {
            await sleep(1);
          }
        }

        return { data, errors };
      });
    }, Promise.resolve({ data: emptyResponse, errors: [] as string[] }));
};

export const cancelCurrentReaderAction = () => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  try {
    const terminal = await dispatch(getTerminal());
    if (!terminal) {
      throw new Error(
        i18next.t('payment:cardPayments.stripe.alerts.cannotCancelNoTerminal'),
      );
    }
    const connectedReader = terminal.getConnectedReader();

    // Doesn't work with simulated
    if (connectedReader && connectedReader.id !== 'SIMULATOR') {
      await terminal.clearReaderDisplay();
      await dispatch(cancelReaderAction(connectedReader.id));
    }
  } catch (e) {
    // do nothing
  } finally {
    const payments: ReduxStripePayment[] = getCardPaymentsForIntegration(
      'stripe',
    )(getState());

    await Promise.all(
      payments.map(async p => {
        if (p.paid || !p.attributes.stripePaymentId) {
          dispatch(unmarkFromProcessing({ key: p.key }));
          return;
        }
        const cancelResponse = await dispatch(
          cancelPaymentIntent({
            payment_intent: p.attributes.stripePaymentId,
            reason: 'abandoned',
          }),
        );
        if (cancelResponse.status === 'canceled') {
          const newAttributes = R.omit(
            [
              'stripePaymentId',
              'stripeRefundId',
              'client_secret',
              'receiptUrl',
            ],
            p.attributes,
          );
          await dispatch(
            setPayment({
              ...p,
              attributes: newAttributes,
              ignoreCurrentAttributes: true,
              shouldProcess: false,
              paid: false,
            }),
          );
        }
      }),
    );
  }
};
