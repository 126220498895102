import { serviceInfo } from 'paymentIntegrations/adyen/requests/adyenRequests';

// the /service/info request exists in every microservice, and is guaranteed to succeed IIF the microservice has finished all of its bootup steps and is ready to serve requests
// source - @Andrey.S

/** If successful response not received for this long, then throw error */
const TIMEOUT = 10e3;
/** How often to retry the service/info request */
const CHECK_FREQUENCY = 0.5e3;
/**
 * Keep performing /service/info requests every {@link CHECK_FREQUENCY} until the request succeeds
 *
 * Throw an error if this doesn't happen within {@link TIMEOUT}
 */
const waitServiceAvailable = async ({ updateMessage }) => {
  updateMessage(
    'Waiting for terminal microservice to finish initializing and become accessible',
  );
  let intervalID;
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject(
          new Error('Timed out waiting for terminal to become accessible'),
        ),
      TIMEOUT,
    );
    intervalID = setInterval(
      () =>
        serviceInfo().then(resolve, () => {
          /* Ignore failures, let them retry */
        }),
      CHECK_FREQUENCY,
    );
  })
    .catch(() => {
      updateMessage(
        'Terminal microservice is not accessible. Please ensure it is installed and running',
      );
    })
    .finally(() => clearTimeout(intervalID));
};

export default waitServiceAvailable;
