import * as R from 'ramda';

import { TYPE_LOGOUT } from 'constants/Login';
import {
  SET_MANUAL_CAMPAIGNS,
  SET_COUPON_CAMPAIGNS,
  SET_COUPON_RULES,
} from 'constants/CampaignsDB';
import { arrayToObj } from 'utils';
import { getSelectedPos } from 'reducers/PointsOfSale';

const initialState = {
  manualCampaigns: {},
  couponCampaigns: {},
  couponRules: {},
  loading: 2,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MANUAL_CAMPAIGNS:
      return {
        ...state,
        manualCampaigns: {
          ...state.manualCampaigns,
          ...arrayToObj(payload.data, 'campaignID'),
        },
      };
    case SET_COUPON_CAMPAIGNS:
      return {
        ...state,
        couponCampaigns: {
          ...state.couponCampaigns,
          ...arrayToObj(payload.data, 'campaignID'),
        },
      };
    case SET_COUPON_RULES:
      return {
        ...state,
        couponRules: {
          ...state.couponRules,
          ...arrayToObj(payload.data, 'couponID'),
        },
      };
    case TYPE_LOGOUT:
    default:
      return state;
  }
};

export function getManualPromotions(state) {
  return Object.values(state.CampaignsDB.manualCampaigns);
}

export function getCoupons(state) {
  return Object.values(state.CampaignsDB.couponCampaigns);
}

export function getPromotionByID(id) {
  return state => {
    const { manualCampaigns, couponCampaigns, couponRules } = state.CampaignsDB;
    return manualCampaigns[id] || couponCampaigns[id] || couponRules[id];
  };
}

export function getCouponByID(id) {
  return state => state.CampaignsDB.couponRules[id];
}

export function getApplicableCoupons(state) {
  const computed = state.shoppingCart.computed?.printAutomaticCoupons;
  if (computed !== undefined) {
    return (
      computed
        .map(({ couponID }) => getCouponByID(couponID)(state))
        // Ensure we do not return array of undefined
        .filter(coupon => coupon)
    );
  }
  console.warn(
    'getApplicableCoupons called without fetch, falling back to manual algorithm',
  );
  const { couponRules } = state.CampaignsDB;
  const currentWarehouseID = getSelectedPos(state).warehouseID;
  const currentDate = new Date();

  const applicableRules = Object.values(couponRules).filter(
    ({
      printedAutomaticallyInPOS,
      issuedFromDate,
      issuedUntilDate,
      warehouseID,
    }) => {
      const endingDate =
        issuedUntilDate === '0000-00-00'
          ? new Date(8640000000000000)
          : new Date(issuedUntilDate);
      const startingDate =
        issuedFromDate === '0000-00-00'
          ? new Date(-8640000000000000)
          : new Date(issuedFromDate);

      return (
        printedAutomaticallyInPOS === 1 &&
        currentDate >= startingDate &&
        currentDate <= endingDate &&
        (Number(warehouseID) === 0 ||
          Number(currentWarehouseID) === Number(warehouseID))
      );
    },
  );
  return applicableRules.filter(a => a);
}

export function campaignDataNotFetched(state) {
  return !!R.equals(state.CampaignsDB, initialState);
}
