import { Box, Button, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedWarehouse } from 'reducers/warehouses';
import { ErplyAttributes } from 'utils';

type Props = {
  children?: never;
  resolve: (value: unknown) => void;
  onClose: () => void;
};

const BankAccountModal: React.FC<Props> = ({ resolve, onClose }) => {
  const location = useSelector(getSelectedWarehouse);
  const attributes = new ErplyAttributes(location?.attributes ?? {}).asArray.filter(attr => attr.attributeName.startsWith("ns_account_"))
  const [selectedItemName, setSelectedItemName] = useState('');

  return (
    <Box padding="1rem">
      <Typography variant="h5">Choose bank account</Typography>
      <Select
        value={selectedItemName}
        fullWidth
        variant="outlined"
        onChange={e => setSelectedItemName(e.target.value as string)}
        style={{ margin: '1rem 0' }}
      >
        {attributes.map(({ attributeName, attributeValue }) => (
          <MenuItem key={attributeName} value={attributeName}>
            {attributeValue}
          </MenuItem>
        ))}
      </Select>
      <Box display="flex" justifyContent="flex-end" gridGap="1rem">
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!selectedItemName.length}
          onClick={() => {
            if (selectedItemName.length) {
              resolve(
                attributes.find(
                  ({ attributeName }) => attributeName === selectedItemName,
                ),
              );
              onClose();
            }
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default BankAccountModal;
