import { createSelector } from 'reselect';

import { getPluginSelector } from 'reducers/Plugins';
import { RootState } from 'reducers';
import { getShoppingCartRows } from 'reducers/ShoppingCart';
import {
  RESET_CUSTOMER,
  SET_CUSTOMER_ISSUED_COUPONS,
  SET_CUSTOMER_SUCCESS,
} from 'constants/customerSearch';
import { RESET_SHOPPING_CART } from 'constants/ShoppingCart';

import { TafState } from '../types';

import {
  SELECTED_ENTITY_TYPE,
  SET_GLOBAL_ENTITY_BY_TYPE,
  SET_PRODUCTS_STATE,
  SET_CUSTOMER_LAST_USED_ENTITIES,
  DISPLAY_PREVIOUS_ENTITIES,
  SET_FIT_ID,
  SET_PAYMENT_TYPE_IDS,
  SET_VOUCHERS,
  RESET_VOUCHERS,
  RESET_STATE,
  SET_CUSTOMER_ID_TAF,
  RESET_CUSTOMER_ID_TAF,
  SET_PRINT_SECOND_RECEIPT,
  SET_COUPONS,
  SET_QFF_CARD_NUMBER,
} from './actionTypes';

export type entityObject = {
  entityType: string | undefined;
  entityID: string | number | undefined;
  entityName: string;
};

const initialState: TafState = {
  applyTafLogic: false,
  invoicePaymentTypeID: null,
  paymentTypeID: null,
  globalSchoolEntity: {
    entityType: '',
    entityID: undefined,
    entityName: '',
  },
  globalMedicalEntity: {
    entityType: '',
    entityID: undefined,
    entityName: '',
  },
  selectedEntityType: '',
  isEditMode: false,
  productsState: {},
  editState: {
    newEntityType: '',
    newEntityID: undefined,
    newEntityName: '',
  },
  customerLastUsedEntities: {
    previousAssociations: [],
    previousProffesionals: [],
  },
  displayPreviousEntities: false,
  fitID: '',
  vouchers: [],
  customerID: '',
  printSecondReceipt: false,
  coupons: [],
  qffCardNumber: null,
};
const tafReducer = (state: TafState = initialState, action): TafState => {
  switch (action.type) {
    case SET_PAYMENT_TYPE_IDS:
      return { ...state, ...action.payload };
    case SET_GLOBAL_ENTITY_BY_TYPE: {
      const entityData = action.payload;

      if (entityData.entityType === 'association') {
        return { ...state, globalSchoolEntity: entityData };
      }

      if (entityData.entityType === 'proffesional') {
        return { ...state, globalMedicalEntity: entityData };
      }

      return state;
    }

    case SELECTED_ENTITY_TYPE:
      return { ...state, selectedEntityType: action.payload };
    case SET_PRODUCTS_STATE: {
      return {
        ...state,
        productsState: action.payload,
      };
    }
    case SET_CUSTOMER_LAST_USED_ENTITIES: {
      return {
        ...state,
        customerLastUsedEntities: action.payload,
      };
    }
    case DISPLAY_PREVIOUS_ENTITIES: {
      return {
        ...state,
        displayPreviousEntities: action.payload,
      };
    }
    case SET_FIT_ID:
      return { ...state, fitID: action.payload };
    case SET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
      };
    case RESET_VOUCHERS:
      return {
        ...state,
        vouchers: [],
      };
    case RESET_STATE:
      return { ...initialState, paymentTypeID: state.paymentTypeID };
    case SET_CUSTOMER_ID_TAF:
      return {
        ...state,
        customerID: action.payload,
      };
    case RESET_CUSTOMER_ID_TAF:
      return {
        ...state,
        customerID: '',
      };
    case SET_PRINT_SECOND_RECEIPT:
      return {
        ...state,
        printSecondReceipt: action.payload,
      };
    case SET_COUPONS:
      return {
        ...state,
        coupons: action.payload,
      };
    case SET_CUSTOMER_ISSUED_COUPONS.SUCCESS:
      return {
        ...state,
        coupons: action.data,
      };
    case SET_CUSTOMER_ISSUED_COUPONS.ERROR:
    case RESET_SHOPPING_CART:
    case SET_CUSTOMER_SUCCESS:
    case RESET_CUSTOMER:
      return {
        ...state,
        coupons: [],
      };
    case SET_QFF_CARD_NUMBER:
      return {
        ...state,
        qffCardNumber: action.payload,
      };
    default:
      return state;
  }
};

export const getTafReduxState = (state: RootState) =>
  getPluginSelector<TafState>('taf-plugin')(n => n)(state);

export const getSchoolEntitySelected = (state: RootState) => {
  const tafState = getTafReduxState(state);
  const schoolID = tafState.globalSchoolEntity.entityID;
  return schoolID;
};

export const getMedicalEntitySelected = (state: RootState) => {
  const tafState = getTafReduxState(state);
  const medicalID = tafState.globalMedicalEntity.entityID;
  return medicalID;
};

export const getHasSchoolAssignedToPositiveProducts = (state: RootState) => {
  const { productsState } = getTafReduxState(state);
  const cartRows = getShoppingCartRows(state);
  const positiveStableRowIDs = cartRows
    .filter(cr => cr?.user?.amount > 0)
    .map(cr => cr.user.orderIndex);
  return positiveStableRowIDs.some(psrid => {
    const schoolID = productsState[psrid]?.schoolEntity?.entityID;
    return schoolID !== undefined && schoolID !== '';
  });
};

export const getCustomerID = (state: RootState) => {
  const { customerID } = getTafReduxState(state);
  return customerID || '';
};

export const getPrintSecondReceipt = createSelector(
  getTafReduxState,
  state => state?.printSecondReceipt,
);

export const getCoupons = createSelector(
  getTafReduxState,
  state => state?.coupons,
);

export const getQffCardNumber = createSelector(
  getTafReduxState,
  state => state?.qffCardNumber,
);

export default tafReducer;
