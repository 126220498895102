/* eslint-disable no-await-in-loop,no-console */
import i18next from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { modalPages } from 'constants/modalPage';
import { healthCheck } from 'services/ErplyAPI/connection';
import * as ErplyAPI from 'services/ErplyAPI';
import { pendingRequests } from 'services/localDB';
import {
  getConnectionHealth,
  getConnectionStatus,
  getIsForcedOfflineMode,
} from 'reducers/connectivity/connection';
import { getClientCode, getSessionKey, getUsername } from 'reducers/Login';
import {
  SYNC_PENDING_REQUEST,
  TYPE_REMOVE_FROM_CLOUD,
  TYPE_SET_CLOUD_STATUS,
  TYPE_SET_CONNECTION_HEALTH,
  TYPE_SET_CONNECTION_STATUS,
  TYPE_SET_FORCE_OFFLINE,
} from 'constants/connectivity';
import {
  getFetchOnlyProductsThatAreInStock,
  getSetting,
  getSkipSyncForSO,
} from 'reducers/configs/settings';
import { createConfirmation } from 'actions/Confirmation';
import { getDB, resetDB, saveToSharedSO, syncDB } from 'services/DB';
import {
  getTableMeta,
  SO,
  syncableSO,
  SyncableSOName,
} from 'services/DB/types';
import { stopSync } from 'services/DB/syncDB';
import { getAllWarehouses, getSelectedWarehouseID } from 'reducers/warehouses';
import { sleep } from 'utils';
import {
  REDUX_ALL_WAREHOUSES,
  REDUX_CLIENTCODE,
  REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK,
  REDUX_POSID,
  REDUX_SESSIONKEY,
  REDUX_USERNAME,
  REDUX_WAREHOUSEID,
} from 'constants/persistence';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { RootState } from 'reducers';
import { getComponents } from 'reducers/modalPage';

import {
  addItemsToCache,
  setItemsInCache,
  syncCachedItems,
} from './cachedItems';
import { calculate } from './ShoppingCart/calculate';

export function setCloudStatus(
  name: string,
  statusUpdate: {
    done?: number;
    progress?: number;
    todo?: number;
    start?: number;
    next?: number;
    syncing?: boolean;
  },
) {
  return {
    type: TYPE_SET_CLOUD_STATUS,

    payload: {
      name,
      statusUpdate,
    },
  };
}
// TODO: should use the new DB -> move the pending requests to BrazilPOS DB, index them by id and allow offline querying
export function syncPendingRequest() {
  return async dispatch => {
    dispatch({ type: SYNC_PENDING_REQUEST.START });
    try {
      const allRequests: any[] = Object.entries(
        (await pendingRequests?.getItem()) || {},
      );
      dispatch(
        setCloudStatus('pendingRequests', {
          done: 0,
          progress: 0,
          syncing: true,
          todo: allRequests.length,
        }),
      );
      if (allRequests.length > 0)
        // eslint-disable-next-line no-restricted-syntax
        for (const [
          index,
          [key, { request, params }],
        ] of allRequests.entries()) {
          await dispatch(
            setCloudStatus('pendingRequests', {
              done: index,
              progress: 1,
              todo: allRequests.length - index - 1,
            }),
          );
          await ErplyAPI[request](params);
          await pendingRequests.remove(key);
          await dispatch(
            setCloudStatus('pendingRequests', {
              done: index + 1,
              progress: 0,
              todo: allRequests.length - index - 1,
            }),
          );
        }
      dispatch({ type: SYNC_PENDING_REQUEST.SUCCESS });

      setTimeout(() => {
        dispatch(
          setCloudStatus('pendingRequests', {
            done: 0,
            progress: 0,
            syncing: false,
            todo: 0,
          }),
        );
      }, 1000);
    } catch (err) {
      dispatch({ type: SYNC_PENDING_REQUEST.FAILURE });
      console.error('Unable to sync pending request(s)', err);
    }
  };
}

export function setConnectionStatus(connectionStatus) {
  return {
    type: TYPE_SET_CONNECTION_STATUS,
    payload: connectionStatus,
  };
}

export function setForcedOfflineStatus(forceOffline: boolean) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    dispatch({
      type: TYPE_SET_FORCE_OFFLINE,
      payload: forceOffline,
    });
    // Calculate if went out of forced offline and network connection is OK
    if (!forceOffline && getConnectionHealth(getState())) {
      dispatch(calculate());
    }
  };
}

export function syncLocalDatabaseSO(SO_NAME: SyncableSOName) {
  return async (dispatch, getState) => {
    const state = getState();
    const clientCode = getClientCode(state);

    if (getSkipSyncForSO(SO_NAME)(getState())) {
      await resetDB(clientCode, SO_NAME);
      dispatch({ type: TYPE_REMOVE_FROM_CLOUD, payload: SO_NAME });
      return console.warn(
        `Syncing for ${SO_NAME} has been disabled from Settings/LocalStorage`,
      );
    }
    dispatch(
      setCloudStatus(SO_NAME, {
        done: 0,
        progress: 0,
        todo: 0,
        syncing: true,
      }),
    );

    const warehouseID = getSelectedWarehouseID(state);
    let latestCacheReset;
    const db = await getDB(clientCode);
    const TABLE_META = getTableMeta(SO_NAME, warehouseID);
    const latestSync = await db.get(SO.META.NAME, TABLE_META.LAST_SYNC_SERVER);

    switch (SO_NAME) {
      case SO.PRODUCTS.NAME: {
        latestCacheReset =
          Number(getSetting('reset-localProductsDB')(state)) || 0;
        break;
      }
      // NB! DO NOT SYNC CUSTOMER DB  - vulnerability issues
      case SO.PRICE_LIST.NAME: {
        latestCacheReset =
          Number(getSetting('reset-localPricelistsDB')(state)) || 0;
        break;
      }
      case SO.EMPLOYEES.NAME: {
        latestCacheReset =
          Number(getSetting('reset-localEmployeesDB')(state)) || 0;
        break;
      }
      default:
        break;
    }

    if (latestCacheReset > latestSync) {
      await i18next.loadNamespaces('localDBs');
      await resetDB(clientCode, SO_NAME);
      dispatch(
        createConfirmation(() => {}, null, {
          title: 'Confirmation',
          body: i18next.t('localDBs:confirmation.clearedCache', {
            databases: SO_NAME,
          }),
        }),
      );
    }

    const shouldUpdateCache = SO.PRODUCTS.NAME !== SO_NAME;

    return syncDB(clientCode, SO_NAME, {
      syncStartCallback: (t, s, time) =>
        dispatch(
          setCloudStatus(SO_NAME, {
            done: 0,
            progress: s,
            todo: t,
            start: new Date().getTime(),
            next: new Date().getTime() + time * 1000,
            syncing: true,
          }),
        ),
      syncUpdateCallback: (total, current, size = 0, time) =>
        dispatch(
          setCloudStatus(SO_NAME, {
            done: current,
            progress: size,
            todo: total - current - size,
            start: new Date().getTime(),
            next: new Date().getTime() + time * 1000,
            syncing: true,
          }),
        ),
      updateCache: items => {
        if (shouldUpdateCache) {
          dispatch(addItemsToCache(SO_NAME, items));
        }
      },
      setCache: async items => {
        await dispatch(setItemsInCache(SO_NAME, items));
      },
    })
      .then(total =>
        dispatch(
          setCloudStatus(SO_NAME, {
            done: total,
            progress: 0,
            todo: 0,
            syncing: false,
          }),
        ),
      )
      .catch(err =>
        console.error('Unable to sync with database of', SO_NAME, err),
      );
  };
}

/**
 * This action initiates the synchronisation process of indexedDB with items from ErplyAPI
 */
export function syncLocalDB() {
  return async (dispatch, getState) => {
    /**
     * We need to make sure that the indexedDB is operational during POS Version changes
     *   Users can change their POS version
     *   Each POS version has certain indexedDB version that it uses
     *   If the user downgrades POS Version and the previous POS version had higher indexedDB version than the current POS version
     *   indexedDB will throw an error on its upgrade function - indexedDB can only upgrade to higher versions
     *   To handle this:
     *   * `getDB` will try to open the DB
     *   * if the DB already exists and is with smaller or the same version - no issues
     *   * if the DB already exists and has smaller version, the getDB will catch the error and delete the DB
     *   * once the DB is deleted new DB with the new indexedDB version will be generated
     */
    const clientCode = getClientCode(getState());
    await getDB(clientCode).catch(err =>
      console.error('Failed to open indexedDB prior DB sync', err),
    );

    /**
     * Syncing happens via WebWorkers
     * In order to make sure that the Worker has all the informaiton it needs to operate on its own
     * We need to sync all vital inputs for it, such as clientCode, sessionKey, username, posID etc.
     */
    const username = getUsername(getState());
    const sessionKey = getSessionKey(getState());
    const posID = getSelectedPosID(getState());
    const warehouseID = getSelectedWarehouseID(getState());
    const warehouses = getAllWarehouses(getState());
    const fetchOnlyItemsInStock = getFetchOnlyProductsThatAreInStock(
      getState(),
    );

    await saveToSharedSO(clientCode, [
      { key: REDUX_CLIENTCODE, value: clientCode },
      { key: REDUX_USERNAME, value: username },
      { key: REDUX_SESSIONKEY, value: sessionKey },
      { key: REDUX_POSID, value: Number(posID) },
      { key: REDUX_WAREHOUSEID, value: Number(warehouseID) },
      { key: REDUX_ALL_WAREHOUSES, value: warehouses },
      { key: REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK, value: fetchOnlyItemsInStock },
    ]).catch(err => {
      console.error('Unable to sync - unable to write to shared SO', err);
    });

    /**
     * Now that we have checked that DB is ok and we have all the metadata needed,
     * we can proceed with actual sycning of the syncable items that POS needs for offline mode
     */
    Promise.all(
      Object.values(syncableSO).map(({ NAME }) =>
        dispatch(syncLocalDatabaseSO(NAME)),
      ),
    )
      .catch(e => console.error('Unable to sync with ErplyAPI databases', e))
      .finally(() => dispatch(syncCachedItems()));
  };
}

export function abortSyncLocalDB() {
  return async () => {
    Object.values(syncableSO).map(({ NAME }) => stopSync(NAME));
  };
}

export function setConnectionHealth(connectionHealth) {
  return {
    type: TYPE_SET_CONNECTION_HEALTH,
    payload: connectionHealth,
  };
}

export function checkHealthStatus(i = 0) {
  return async (dispatch, getState) => {
    let status = 'error';
    const clientCode = getClientCode(getState());
    if (!clientCode) return;
    try {
      const timerOn = new Date().getTime();
      const health = await healthCheck();
      const timerOff = new Date().getTime();
      const ping = timerOff - timerOn;
      if (health.status === 200) {
        // If going from OFFLINE to ONLINE
        if (!getConnectionHealth(getState())) {
          dispatch(setConnectionHealth(true));
          const isPaymentModalOpened = getComponents(getState())
            .map(comp => comp.component)
            .includes(modalPages.Payment);
          // If payment window is not open and user is not in forced offline - re-calculate
          if (!isPaymentModalOpened && !getIsForcedOfflineMode(getState())) {
            dispatch(calculate());
          }
        }
        switch (true) {
          case ping < 250:
            status = 'Excellent';
            break;
          case ping >= 250 && ping < 400:
            status = 'Good';
            break;
          case ping >= 400 && ping < 750:
            status = 'Average';
            break;
          case ping >= 750 && ping < 1500:
            status = 'Poor';
            break;
          case ping >= 1500:
            status = 'Poorer';
            break;
          default:
            status = 'error';
            break;
        }
      }
    } catch (e) {
      // Connection not available
      if (i < 5) {
        await sleep(0.5);
        await dispatch(checkHealthStatus(i + 1));
        return;
      }
      if (getConnectionHealth(getState())) {
        dispatch(setConnectionHealth(false));
        // Do not calculate to keep the previously calculated promotions/prices from Online if user went offline as long as there's no edits to cart
      }
      status = 'error';
    }
    if (getConnectionStatus(getState()) !== status) {
      dispatch(setConnectionStatus(status));
    }
  };
}
