import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import InputField from 'components/FieldTypes/InputField';
import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

type Props = {
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
};

const CloseDayNotes: FC<Props> = ({ resolve, reject }) => {
  const [notes, setNotes] = useState('');

  const dispatch = useDispatch();

  const { t } = useTranslation('openCloseDay');

  const handleClose = () => {
    dispatch(previousModalPage());
    reject();
  };

  const handleSubmit = () => {
    dispatch(previousModalPage());
    resolve(notes);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <h3>
            <b>{t('alerts.exceedingLimit')}</b>
          </h3>
        </Modal.Title>
        <div
          className="d-flex align-content-center"
          style={{ marginLeft: 'auto' }}
        >
          <SaveButton disabled={!notes.trim().length} action={handleSubmit} />
          <CloseButton action={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <InputField
          size="lg"
          value={notes}
          placeholder={t('closeDay.main.fields.exceedingReason')}
          autoFocus
          onChange={e => setNotes(e.target.value)}
        />
      </Modal.Body>
    </>
  );
};

export default CloseDayNotes;
