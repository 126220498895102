import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { modalPages } from 'constants/modalPage';
import useProducts from 'utils/hooks/useProducts';
import { getWarrantyGroupId } from 'plugins/onoff/warrantyPlugin/selectors';
import { useAutocloseModalPage } from 'plugins/onoff/warrantyPlugin/hooks';
import { preventWarrantyEdits } from 'plugins/onoff/warrantyPlugin/preventWarrantyEdits';

import { baseLog, Configuration, pluginID } from './meta';
import { CompanyConfiguration } from './configuration';
import { promptToAddRelatedWarranty } from './actions';
import infoMDUrl from './info.md';

/**
 * OnOff warranty plugin
 *
 * @see {@link info}
 * @see Alternate implementation where the products in the cart are virtual is available in branch PBIB-3765-alt
 */
export const OnOffWarrantyPlugin: PosPlugin<
  Configuration,
  Configuration,
  null,
  null,
  null
> = {
  id: pluginID,
  name: 'O3F Warranty plugin',
  infoMDUrl,
  keywords: ['products', 'tax', 'shopping', 'cart', 'customer-specific'],

  ComponentConfigurationByLevel: {
    Company: CompanyConfiguration,
  },
  combineConfiguration: c =>
    c ?? { warrantyProductGroupId: -1, mainWarehouseId: -1 },
  /**
   * Force disable row merging behaviour
   *
   * We can't easily determine in onAddProduct whether it is going to merge or not,
   * and it would be weird if we prompted for warranties when it is about to merge
   * By disabling merging, every call to onAddProduct is going to make a new row, and therefore it makes sense to prompt
   */
  selectorOverrides: {
    getSettings: base =>
      createSelector(base, R.assoc('touchpos_always_item_in_new_row', true)),
  },

  onAddProduct: {
    /**
     * If product has related warranties, show prompt for one
     */
    after: p => async (dispatch, getState) => {
      try {
        await dispatch(promptToAddRelatedWarranty(p.productID));
      } catch (e) {
        const log = baseLog.extend('onAddProduct.after');
        log(e.message);
      }
    },
  },

  /** Close Related products view if any of the related products are warranty products */
  UIRelatedProducts: ({ children, productID, relatedProducts }) => {
    const {
      products: [product],
    } = useProducts({ productID });
    const warrantyGroupId = useSelector(getWarrantyGroupId);
    const hasWarrantyProducts = relatedProducts.some(
      p => Number(p.groupID) === warrantyGroupId,
    );
    const isWarrantyProduct = Number(product?.groupID) === warrantyGroupId;

    const shouldClose = hasWarrantyProducts || isWarrantyProduct;
    return useAutocloseModalPage(
      modalPages.relatedProducts,
      children,
      shouldClose,
    );
  },

  ...preventWarrantyEdits,
};
