import { Trans } from 'react-i18next';
import React from 'react';
import i18next from 'i18next';

import { createConfirmation } from 'actions/Confirmation';

import { addError, dismissType } from '../../actions/Error';

/**
 * Async actions are automatically attached a .catch handler
 * which displays ErplyAlert errors and logs errors to console
 */
export const erplyAlertOnError = (type, func) => {
  const action = async (dispatch, getState) => {
    try {
      dispatch(dismissType(type));
      return await func(dispatch, getState);
    } catch (e) {
      /**
       * 1219 stands for password expiry - needs it's own modal page
       */
      if (e.errorCode === 1219) {
        await i18next.loadNamespaces('validation');
        return new Promise(() =>
          dispatch(
            createConfirmation(
              () => {
                // do nothing
              },
              null,
              {
                title: i18next.t('validation:ErplyAPI.error.1219.title'),
                body: (
                  <p align="center">
                    <Trans i18nKey="validation:ErplyAPI.error.1219.body">
                      <a
                        href="https://login.erply.com/"
                        target="_blank"
                      />
                    </Trans>
                  </p>
                ),
              },
            ),
          ),
        );
      }
      console.error('Displayed banner error: ', e);
      dispatch(
        addError(e && e.message, {
          selfDismiss: 3000,
          errorType: type,
        }),
      );
      return e;
    }
  };
  action.rawAction = func;
  return action;
}
