import * as R from 'ramda';
import { Children } from 'react';

import { PosPlugin } from 'plugins/plugin';

const UIReturnTableHeaders: PosPlugin['UIReturnTableHeaders'] = ({
  children,
}) => {
  return R.map(
    R.over(
      R.lensPath(['props', 'children', 'props', 'children']),
      R.pipe(
        Children.toArray,
        R.reject(R.pathEq(['props', 'data-testid'], 'barcode')),
        R.map(
          R.when(
            R.pathEq(['props', 'data-testid'], 'reason'),
            R.assocPath(['props', 'style'], { width: '6%' }),
          ),
        ),
      ),
    ),
  )(Children.toArray(children));
};
export default UIReturnTableHeaders;
