/* eslint-disable import/prefer-default-export */

/* NOTE: Last 3 fields should have a width 100% combined together
 * but for some reason the InputGroup layput breaks
 * and the last field falls down to a new line
 * with the current proportion the ratio is as needed therefore I am leaving it as it is
 */
export const fieldGroups = [
  [
    {
      key: 'street',
      type: 'text',
      placeholder: 'ADDRESS LINE 1',
      style: { width: '100%' },
    },
  ],[
    {
      key: 'address2',
      type: 'text',
      placeholder: 'ADDRESS LINE 2',
      style: { width: '70%' },
    },
    {
      key: 'postalCode',
      type: 'text',
      placeholder: 'ZIP',
      style: { width: '30%' },
    },
  ], [
    {
      key: 'city',
      type: 'text',
      placeholder: 'CITY',
      style: { width: '30%' },
    },
    {
      key: 'state',
      type: 'text',
      placeholder: 'STATE',
      style: { width: '10%' },
    },
    {
      key: 'country',
      type: 'text',
      placeholder: 'COUNTRY',
      style: { width: '20%' },
    },
  ]
];
