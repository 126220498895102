import React from 'react';
import * as R from 'ramda';

import { Customer } from 'types/Customer';
import { getCustomerSearchResultsConfiguration } from 'reducers/configs/settings';

import { Namerow, CardNrrow, GenericRow, AddressComponent } from './Components';

export async function mapContactsToCompanies(
  customerArray: Customer[],
  getContactCompanies: (contactCompanyIds: string[]) => Promise<Customer[]>,
): Promise<Customer[]> {
  const { 0: customers, ...contacts } = R.groupBy(
    R.prop('companyID'),
    customerArray,
  );
  if (!contacts || R.isEmpty(contacts)) {
    return customerArray;
  }
  const contactCompanyIds = Object.keys(contacts);
  const contactCompanies = await getContactCompanies(contactCompanyIds);

  const mappedCustomers = (customers ?? [])
    .filter(
      customer => !contactCompanies.some(company => company.id === customer.id),
    )
    .concat(
      contactCompanies.map(company => ({
        ...company,
        contactPersons: contacts[company.id],
      })),
    );

  if (contactCompanyIds.length === contactCompanies.length)
    return mappedCustomers;

  const missingCompanyIds = R.difference(
    contactCompanyIds,
    contactCompanies.map(c => String(c.id)),
  ).reduce(
    (acc: Customer[], id: string) => [...acc, ...contacts[id]],
    mappedCustomers,
  );

  return missingCompanyIds;
}

export function getCustomerName(customer: Customer) {
  return customer.companyName || `${customer.firstName} ${customer.lastName}`;
}

export const sortByCompanyOrFullName = R.sortBy(
  R.compose(R.toLower, getCustomerName),
);

export function getRowsToDisplay(
  customerResultsConfig: ReturnType<
    typeof getCustomerSearchResultsConfiguration
  >,
  isRetailView: boolean,
) {
  return Object.keys(customerResultsConfig)
    .filter(header => {
      const isEnabled = customerResultsConfig[header] === 1;
      const isRetailHeader = header.includes('retail');
      const shouldDisplay = isRetailView ? isRetailHeader : !isRetailHeader;
      return isEnabled && shouldDisplay;
    })
    .map(header => header.replace('retail_', ''));
}

export function defineComponentToDisplay({
  customer,
  location,
  companyName,
  rowsToDisplay,
}: {
  customer: Customer;
  location?: string;
  companyName?: string;
  rowsToDisplay: ReturnType<typeof getRowsToDisplay>;
}) {
  return rowsToDisplay.map(item => {
    switch (item) {
      case 'name':
        return (
          <Namerow
            key={`${item}-${customer.customerID}`}
            customer={customer}
            companyName={companyName}
            location={location}
          />
        );
      case 'email':
      case 'phone':
      case 'customerID':
      case 'mobile':
      case 'postalCode':
        return (
          <GenericRow
            key={`${item}-${customer.customerID}`}
            customer={customer}
            fieldName={item}
          />
        );
      case 'cardNr':
        return (
          <CardNrrow
            key={`${item}-${customer.customerID}`}
            customer={customer}
          />
        );
      case 'address':
        return <AddressComponent customer={customer} />;
      default:
        return null;
    }
  });
}
