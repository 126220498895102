import { useState, useEffect, useCallback } from 'react';

import { CLASS_KEYBOARD } from '../../constants/OnScreenKeyboard';

const isWithinKeyboard = el => {
  return (
    el &&
    ((el.classList && el.classList.contains(CLASS_KEYBOARD)) ||
      isWithinKeyboard(el.parentNode))
  );
};

// eslint-disable-next-line import/prefer-default-export
export const useActiveElement = () => {
  const [element, setElement] = useState(document.activeElement);

  const onFocusOutListener = useCallback(
    e => {
      const relatedTarget =
        e.relatedTarget || e.explicitOriginalTarget || document.activeElement;

      if (isWithinKeyboard(relatedTarget)) {
        e.target.focus();
      } else if (relatedTarget !== element) {
        setElement(relatedTarget);
      }
    },
    [element],
  );
  const onFocusListener = useCallback(() => {
    if (document.activeElement !== element) {
      setElement(document.activeElement);
    }
  }, [element]);

  useEffect(() => {
    document.addEventListener('focusin', onFocusListener);
    document.addEventListener('focusout', onFocusOutListener);
    return () => {
      document.removeEventListener('focusin', onFocusListener);
      document.removeEventListener('focusout', onFocusOutListener);
    };
  }, [onFocusListener, onFocusOutListener]);

  return element;
};
