import { batch } from 'react-redux';
import i18next from 'i18next';

import * as API from 'services/ErplyAPI/api';
import { getClientCode, getHasRightToViewZReport } from 'reducers/Login';
import {
  CLOSE_DAY,
  LOADING,
  OPEN_DAY,
  SET_POSDAYTOTALS,
} from 'constants/OpenCloseDay';
import { getSelectedPos } from 'reducers/PointsOfSale';
import {
  getCurrentDay,
  getCurrentDayByCurrencyCode,
  getCurrentDays,
  getNotesRequired,
} from 'reducers/OpenCloseDay';
import {
  getAllowOfflineLogin,
  getDayCountedByDrawer,
  getDefaultCurrency,
  getEnabledCurrencies,
  getIsModuleEnabled,
  getSyncBatchToDayOpenings,
} from 'reducers/configs/settings';
import { getEmployeeID } from 'reducers/Payments';
import i18n from 'containers/App/i18n';
import { requestWithOfflineFallback } from 'services/ErplyAPI/core/ErplyAPI';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import { OPEN_DAY_INFO } from 'constants/persistence';

import { getPluginLifecycleHook } from '../reducers/Plugins';
import { modalPages as mp } from '../constants/modalPage';

import { addError, addWarning, dismissType } from './Error';
import { closeBatch } from './integrations/terminal';
import { previousModalPage } from './ModalPage/previousModalPage';
import { openModalPage } from './ModalPage/openModalPage';
import { hardLogout } from './Login';

const startLoading = { type: LOADING, payload: true };
const stopLoading = { type: LOADING, payload: false };
const t = i18n.getFixedT(null, 'openCloseDay');
function getOpenDayInfoFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem(OPEN_DAY_INFO)) || [];
  } catch (e) {
    console.error('Failed to get open day from local storage', e);
    return [];
  }
}

function getIndexOfSelectedOpenDayFromLocalStorage(
  { pointOfSaleID, clientCode, currencyCode },
  isMultiCurrencyEnabled,
) {
  const localStorageData = getOpenDayInfoFromLocalStorage();

  const isMatchingCommonConditions = item =>
    item.pointOfSaleID === pointOfSaleID && item.clientCode === clientCode;

  return localStorageData.findIndex(item =>
    isMultiCurrencyEnabled
      ? isMatchingCommonConditions(item) && item.currencyCode === currencyCode
      : isMatchingCommonConditions(item),
  );
}

export function updateOpenDayInfoInLocalStorage(
  info,
  isMultiCurrencyEnabled,
) {
  const infoMissing =
    getIndexOfSelectedOpenDayFromLocalStorage(info, isMultiCurrencyEnabled) < 0;

  if (infoMissing) {
    const currentInfo = getOpenDayInfoFromLocalStorage();
    currentInfo.push(info);
    localStorage.setItem(OPEN_DAY_INFO, JSON.stringify(currentInfo));
  }
}

export function removeOpenDayInfoInLocalStorage(
  pointOfSaleID,
  clientCode,
  currencyCode,
  isMultiCurrencyEnabled,
) {
  const currentInfo = getOpenDayInfoFromLocalStorage();
  const indexOfCurrentOpenDay = getIndexOfSelectedOpenDayFromLocalStorage(
    { pointOfSaleID, clientCode, currencyCode },
    isMultiCurrencyEnabled,
  );

  if (indexOfCurrentOpenDay === -1) return;

  currentInfo.splice(indexOfCurrentOpenDay, 1);

  if (currentInfo.length === 0) {
    localStorage.removeItem(OPEN_DAY_INFO);
    return;
  }
  localStorage.setItem(OPEN_DAY_INFO, JSON.stringify(currentInfo));
}

function getSelectedDaysInLocalStorage(
  id,
  clientCode,
  isMultiCurrencyEnabled,
  currencies,
) {
  const currentInfo = getOpenDayInfoFromLocalStorage();
  if (isMultiCurrencyEnabled) {
    const selectedPos = currencies.map(currency =>
      currentInfo.filter(
        item =>
          item.pointOfSaleID === id &&
          item.clientCode === clientCode &&
          item.currencyCode === currency,
      ),
    );
    return selectedPos.flat();
  }
  return currentInfo.filter(
    item => item.pointOfSaleID === id && item.clientCode === clientCode,
  );
}

function openDayAction(
  pointOfSaleID,
  openedUnixTime,
  totalsForCurrencies,
  registerTotalsForCurrencies,
) {
  return async (dispatch, getState) => {
    const selectedPos = getSelectedPos(getState());
    const isMultiCurrencyEnabled = getIsModuleEnabled('pos_multicurrency')(
      getState(),
    );
    const defaultCurrency = getDefaultCurrency(getState());
    const currencies = getEnabledCurrencies(getState());

    let response = [];
    if (!isMultiCurrencyEnabled) {
      const requestParams = {
        pointOfSaleID: pointOfSaleID || selectedPos.pointOfSaleID,
        openedUnixTime,
        openedSum:
          registerTotalsForCurrencies[defaultCurrency] ||
          totalsForCurrencies[defaultCurrency] ||
          0,
        clientCode: getClientCode(getState()),
      };
      response = await dispatch(
        requestWithOfflineFallback({
          request: 'openDayV2',
          params: requestParams,
        }),
      );

      updateOpenDayInfoInLocalStorage(requestParams, false);
    } else {
      response = await Promise.all(
        currencies.map(currencyCode => {
          const requestParams = {
            pointOfSaleID: pointOfSaleID || selectedPos.pointOfSaleID,
            openedUnixTime,
            openedSum:
              registerTotalsForCurrencies[currencyCode] ||
              totalsForCurrencies[currencyCode] ||
              0,
            clientCode: getClientCode(getState()),
            currencyCode,
          };
          updateOpenDayInfoInLocalStorage(requestParams, true);
          return dispatch(
            requestWithOfflineFallback({
              request: 'openDayV2',
              params: requestParams,
            }),
          );
        }),
      )
        .then(data => data.flat())
        .catch(e => {
          console.error('Failed to open days for all currencies', currencies, e);
          throw e;
        });
    }

    return response;
  };
}

export function openDay({
  pointOfSaleID = undefined,
  openedUnixTime = new Date().getTime() / 1000,
  totalsForCurrencies,
  registerTotalsForCurrencies,
}) {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading);
      const openDayResponse = await dispatch(
        openDayAction(
          pointOfSaleID,
          openedUnixTime,
          totalsForCurrencies,
          registerTotalsForCurrencies,
        ),
      );

      batch(() => {
        dispatch({ type: OPEN_DAY, payload: openDayResponse });
      });
    } catch (error) {
      dispatch(addError(t('alerts.failedToOpenDay')));
      console.error(error);
      throw new Error(t('alerts.failedToOpenDay'), {
        cause: error,
      });
    } finally {
      dispatch(stopLoading);
    }
  };
}

export function checkIsDayOpen({ pointOfSaleID }) {
  return async (dispatch, getState) => {
    dispatch(startLoading);
    try {
      if (!pointOfSaleID) {
        return; // NO POS SELECTED;
      }
      const isMultiCurrencyEnabled = getIsModuleEnabled('pos_multicurrency')(
        getState(),
      );
      const employeeID = getEmployeeID(getState());
      const clientCode = getClientCode(getState());
      const isConnectionAvailable = getConnectionHealth(getState());

      let foundDaysInLocalStorage = [];
      let response = [];
      if (!isMultiCurrencyEnabled) {
        foundDaysInLocalStorage = getSelectedDaysInLocalStorage(
          pointOfSaleID,
          clientCode,
          false,
        );
        response = await API.checkIsDayOpen({
          pointOfSaleID,
          employeeID,
        }).catch(e => {
          console.error(
            'Failed to load open days for',
            { employeeID, pointOfSaleID },
            e,
          );
          return [];
        });
      } else {
        // only check for additional currencies if the default currency day is opened
        const currencies = getEnabledCurrencies(getState());
        foundDaysInLocalStorage = getSelectedDaysInLocalStorage(
          pointOfSaleID,
          clientCode,
          true,
          currencies,
        );

        response = await Promise.all(
          currencies.map(currencyCode =>
            API.checkIsDayOpen({
              pointOfSaleID,
              currencyCode,
              employeeID,
            }),
          ),
        )
          .then(data => data.flat())
          .catch(e => {
            console.error(
              'Failed to load open days for',
              { employeeID, pointOfSaleID },
              e,
            );
            return [];
          });
      }

      let payload = [];

      if (response.length || isConnectionAvailable) {
        payload = response;
      } else {
        payload = foundDaysInLocalStorage;
      }
      dispatch({
        type: OPEN_DAY,
        payload,
      });
    } catch (e) {
      dispatch({
        type: OPEN_DAY,
        payload: getAllowOfflineLogin(getState()) ? [{}] : null,
      });
    } finally {
      dispatch(stopLoading);
    }
  };
}

async function tryRefetchCurrentDay(
  selectedPointOfSaleID,
  employeeID,
  currencyCode,
) {
  try {
    const response = await API.checkIsDayOpen({
      pointOfSaleID: selectedPointOfSaleID,
      employeeID,
      ...(currencyCode && { currencyCode }),
    });
    return response;
  } catch (error) {
    console.warn(
      'Failed to load open days:',
      { employeeID, selectedPointOfSaleID },
      error,
    );
    return null;
  }
}

async function getDays(dispatch, getState) {
  const days = getCurrentDays(getState()) || [];
  const currencies = getEnabledCurrencies(getState());
  const { pointOfSaleID } = getSelectedPos(getState());
  const isMultiCurrencyEnabled = getIsModuleEnabled('pos_multicurrency')(
    getState(),
  );
  const employeeID = getEmployeeID(getState());

  if (days.length > 0) return days;

  if (isMultiCurrencyEnabled) {
    const fetchCurrentDayForCurrency = currency => {
      return tryRefetchCurrentDay(pointOfSaleID, employeeID, currency) ?? [];
    };

    const results = await Promise.all(
      currencies.map(fetchCurrentDayForCurrency),
    );
    return results.flat();
  }

  const result =
    (await tryRefetchCurrentDay(pointOfSaleID, employeeID, null)) ?? [];
  if (result.length > 0) {
    days.push(result?.[0]);
    return days;
  }
  return [];
}

export function getPointOfSaleDayTotals() {
  return async (dispatch, getState) => {
    dispatch(startLoading);
    const { pointOfSaleID } = getSelectedPos(getState());
    let days = getCurrentDays(getState()) || [];
    const defaultCurrency = getDefaultCurrency(getState());
    const isMultiCurrencyEnabled = getIsModuleEnabled('pos_multicurrency')(
      getState(),
    );
    const useDrawer = getDayCountedByDrawer(getState());
    if (days.length === 0) {
      days = await dispatch(getDays);
    }

    const data = await Promise.all(
      days.map(async ({ openedUnixTime, currencyCode, dayID }) => {
        const amounts = await API.getPointOfSaleDayTotals({
          ...(useDrawer ? { dayID } : { pointOfSaleID }),
          ...(isMultiCurrencyEnabled ? { currencyCode } : {}),
          openedUnixTime,
        });
        return [currencyCode ?? defaultCurrency, amounts];
      }),
    )
      .then(d => Object.fromEntries(d ?? []))
      .catch(e => {
        console.error('Failed to load POS day totals', e);
        return [];
      });
    batch(() => {
      dispatch({ type: SET_POSDAYTOTALS, payload: data });
      dispatch(stopLoading);
    });
    return data;
  };
}

export function closeDay(props) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onCloseDay')(
      getState(),
    );
    try {
      const {
        pointOfSaleID,
        openedUnixTime,
        closedUnixTime = new Date().getTime() / 1000,
        closedSum,
        bankedSum,
        notes,
        currencyCode,
        ...rest
      } = props;

      await dispatch(before(props));
      const selectedPos = getSelectedPos(getState());
      const clientCode = getClientCode(getState());
      const employeeID = getEmployeeID(getState());
      const currencies = getEnabledCurrencies(getState());
      const selectedPointOfSaleID = pointOfSaleID || selectedPos.pointOfSaleID;
      const isMultiCurrencyEnabled = getIsModuleEnabled('pos_multicurrency')(
        getState(),
      );
      const useDrawer = getDayCountedByDrawer(getState());
      let currentDay = isMultiCurrencyEnabled
        ? getCurrentDayByCurrencyCode(
            currencyCode ?? getDefaultCurrency(getState()),
          )(getState())
        : getCurrentDay(getState());

      if (!currentDay) {
        /* In case the currentDay is not stored in redux (most probably due to offline day opening),
        send a request to get the current day and use it to close the day. */
        const response =
          (await tryRefetchCurrentDay(
            selectedPointOfSaleID,
            employeeID,
            currencyCode,
          )) ?? [];
        if (response.length === 0) {
          console.error('currentDay is missing even after refetching');
          return;
        }
        [currentDay] = response;
      }

      dispatch(startLoading);

      const closeDayPayload = await dispatch(
        on(props, {
          pointOfSaleID: selectedPointOfSaleID,
          ...(useDrawer && currentDay.dayID
            ? { dayID: currentDay.dayID }
            : {
                openedUnixTime: openedUnixTime || currentDay.openedUnixTime,
              }),
          currencyCode: isMultiCurrencyEnabled ? currencyCode : undefined,
          closedUnixTime,
          closedSum,
          bankedSum,
          notes,
          ...rest,
        }),
      );
      const [closedDay] = await API.closeDay(closeDayPayload);
      isMultiCurrencyEnabled
        ? currencies.forEach(currency =>
            removeOpenDayInfoInLocalStorage(
              pointOfSaleID || selectedPos.pointOfSaleID,
              clientCode,
              currency,
              true,
            ),
          )
        : removeOpenDayInfoInLocalStorage(
            pointOfSaleID || selectedPos.pointOfSaleID,
            clientCode,
            null,
            false,
          );
      batch(() => {
        dispatch({ type: CLOSE_DAY, payload: closedDay });
        dispatch(stopLoading);
      });
      await dispatch(after(props, closedDay));
    } catch (err) {
      console.error('Failed to close day', err);
    }
  };
}

function prepareCloseDay({ closedSum, bankedSum, currencyCode, ...rest }) {
  return closeDay({ closedSum, bankedSum, currencyCode, ...rest });
}

export function closeAllDays(daysToClose) {
  return async (dispatch, getState) => {
    try {
      const notesRequired = getNotesRequired(getState());
      const rightToViewZReport = getHasRightToViewZReport(getState());
      if (notesRequired && !daysToClose[0]?.notes) {
        dispatch(
          addWarning(i18next.t('openCloseDay:alerts.notesRequired'), {
            selfDismiss: true,
            errorType: 'EOD',
          }),
        );
        return false;
      }

      if (getSyncBatchToDayOpenings(getState())) {
        dispatch(dismissType('EOD'));
        dispatch(startLoading);
        await dispatch(closeBatch())
          .then(r => {
            if (!r) throw new Error('closeBatch did not return a truthy value');
            return r;
          })
          .catch(e => {
            dispatch(
              addError(i18next.t('openCloseDay:alerts.terminalCloseBatchFail'), {
                selfDismiss: true,
                errorType: 'EOD',
              }),
            );
            throw e;
          })
          .finally(() => dispatch(stopLoading));
      }
      await Promise.all(daysToClose.map(r => dispatch(prepareCloseDay(r))))
        .then(() => dispatch(previousModalPage()))
        .then(() => {
          if (!rightToViewZReport) {
            return dispatch(hardLogout());
          }
          return dispatch(
            openModalPage({
              component: mp.ZReport,
              props: { logoutAfterClose: true },
            }),
          );
        });
    } catch (err) {
      console.error('Failed to close all days', err);
    }
    return true;
  };
}
