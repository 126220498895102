import { useEffect } from 'react';

import { PosPlugin } from 'plugins/plugin';

const WithAllAsReasonRule: PosPlugin['UISetBulkReturnReasons'] = ({
  children,
  globalReasonRule,
  setGlobalReasonRule,
}) => {
  useEffect(() => {
    if (globalReasonRule !== 'all') {
      setGlobalReasonRule('all');
    }
  }, [globalReasonRule, setGlobalReasonRule]);

  return children;
};

export default WithAllAsReasonRule;
