import RandExp from 'randexp';
import * as R from 'ramda';

import 'error-cause/auto';
import { REDUX_SESSIONKEY } from 'constants/persistence';
import { Product } from 'types/Product';
import { PriceList } from 'types/PriceList';

import { posSendMessage } from './hooks/useWrapper';
// Todo: Update usages to import from the new file directly, and remove this export
export { ErplyAttributes } from './ErplyAttributes';
export * from './tsHelpers';

export const round = (value, decimals = 2) => {
  /*
   * For some reason, it is more precise to do rounding on scaled-up numbers
   * Example input `(49.565).toFixed(2) === 49.56`
   * but `round(49.565,2) === 49.57`
   * This difference is not rounding half up/down/even, or anything like that, but is just random due to floating point errors
   */
  const scalingFactor = 10 ** decimals;

  let v = parseFloat(value);
  v *= scalingFactor;
  v = Math.round(Math.abs(v)) * Math.sign(v); // round half-away from zero (default is round half-up)
  v /= scalingFactor;
  return Number.isNaN(v) ? undefined : v.toFixed(decimals);
};

/**
 * Rounding function that currency formatter uses
 *
 * Adds 0.000001 to ensure negative rounding errors don't turn a half-round into a round-down.
 * Rounds to 2 decimal places.
 */
export const roundCurrency = (value: number, roundDown = false): number => {
  return parseFloat(
    (
      Math.sign(value) *
      (Math.abs(value) + (roundDown && value > 0 ? -1 : 1) * 1e-6)
    ) /* en-US = 23,456,789.123456123 */
      .toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .replace(/,/g, ''),
  );
};

export const isEmpty = object =>
  Object.entries(object).length === 0 && object.constructor === Object;

export const isFunction = obj =>
  !!(obj && obj.constructor && obj.call && obj.apply);

export const isString = item => !!(item && item.substring);

export const timestampInSeconds = () => Math.floor(Date.now() / 1000);

/**
 * Given an array of objects, creates a new dict that indexes all the object by the given keyProp
 * @example
 * const myData = [
 *    {id: 1, name:'foo'},
 *    {id: 2, name:'bar'}
 * ]
 * console.log(arrayToObj(myData));
 * // yields {1: {id:1, name:'foo'},  2: {id:2, name:'bar'}}
 */
export const arrayToObj = (arr, keyProp) => {
  return Object.assign({}, ...arr.map(item => ({ [item[keyProp]]: item })));
};

export const strToDate = (YMDString, fallback): Date => {
  const [y, m, d] = YMDString.split(/\D/g).map(Number);
  if (y + m + d === 0) return strToDate(fallback, null);
  const date = new Date();
  date.setFullYear(y);
  date.setMonth(m - 1);
  date.setDate(d);
  return date;
};
export const dateCompare = (...datesInOrder) => {
  const isSorted = !!datesInOrder.reduce(
    (prev, next) =>
      prev && next.getTime() - prev.getTime() > -1000 * 60 * 60 * 24 && next,
    new Date('0001-01-01'),
  );
  return isSorted;
};

export const hasBirthday = date => {
  const today = new Date();
  const birthday = new Date(date);
  return (
    today.getMonth() === birthday.getMonth() &&
    today.getDate() === birthday.getDate()
  );
};

export const chainPromises = (...promiseCreators) => {
  return promiseCreators.reduce(
    (prevPromise, nextCreator) => prevPromise.then(() => nextCreator()),
    Promise.resolve(),
  );
};

export const timeout = (promise, timeout) =>
  new Promise((res, rej) => {
    promise.then(res).catch(rej);
    setTimeout(rej, timeout);
  });

export const urlEncode = dict =>
  Object.entries(dict)
    .map(
      ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v as string)}`,
    )
    .join('&');

export const getOS = ():
  | 'Mac'
  | 'iOS'
  | 'Windows'
  | 'Android'
  | 'Linux'
  | null => {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'Mac';
  }
  if (iosPlatforms.indexOf(platform) !== -1) {
    return 'iOS';
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'Windows';
  }
  if (/Android/.test(userAgent)) {
    return 'Android';
  }
  if (/Linux/.test(platform)) {
    return 'Linux';
  }
  return null;
};

/**
 * Converts a collection of space-separated words into PascalCase
 * In the case of a single word, gives it a leading uppercase character
 * @example
 * pascalCase('foobar') // 'Foobar'
 * pascalCase('FOObar') // 'Foobar'
 * pascalCase('FOO bar BAZ') // 'FooBarBaz'
 * @returns {string}
 */
export const pascalCase = str =>
  str
    .split(' ')
    .map(w => `${w[0].toUpperCase()}${w.substr(1).toLowerCase()}`)
    .join('');

/** Add two numbers (for use as [1,2,3].reduce(sum,0) */
export const add = (a, b) => a + b;
/** @deprecated For debug use only */
export const withMeasureTime = (name, func) => (...params) => {
  const w: any = window;
  w.time = w.time || {};
  w.time[name] = w.time[name] || [];
  const t1 = Date.now();
  const result = func(...params);
  if (result.then) {
    result.then(() => {
      const t2 = Date.now();
      w.time[name].push(t2 - t1);
      // Debug function, should not be used in production therefore OK to console
      // eslint-disable-next-line no-console
      console.log(
        `Function ${name} has run ${
          w.time[name].length
        } times for a total runtime of ${w.time[name].reduce(
          (a, b) => a + b,
          0,
        )}`,
      );
    });
  } else {
    const t2 = Date.now();
    w.time[name].push(t2 - t1);
    // Debug function, should not be used in production therefore OK to console
    // eslint-disable-next-line no-console
    console.log(
      `Function ${name} has run ${
        w.time[name].length
      } times for a total runtime of ${w.time[name].reduce(
        (a, b) => a + b,
        0,
      )}`,
    );
  }
  return result;
};

/** A promise that will resolve in the specified number of seconds */
export const sleep = seconds =>
  new Promise(resolve => setTimeout(resolve, Number(seconds) * 1000));

export const snakeToCamel = str =>
  str.replace(/([-_]\w)/g, g => g[1].toUpperCase());

export const miniUuid = (length = 6) => {
  return new RandExp(new RegExp(`[0-9A-Z]{${length}}`)).gen();
};

/**
 * Capitalizes the first letter of a given string
 * @param lower
 * @returns {string}
 */
export const capitalizeFirstLetter = lower => {
  if (typeof lower !== 'string' || !lower.length) return '';
  return lower.charAt(0).toUpperCase() + lower.substring(1);
};

export const isTruey = value => {
  // Update as necessary
  return value && value !== 'false' && value !== '0';
};

export const DefaultDict = (constructor, data = {}) => {
  return new Proxy(data, {
    get: (target, p, receiver) => {
      if (!Object.hasOwnProperty.call(target, p)) {
        // eslint-disable-next-line no-param-reassign
        target[p] = constructor();
      }
      return target[p];
    },
  });
};

export const waitForCondition = async ({
  testFn,
  timeout,
  checkInterval = 0.1,
  stability,
  callbacks = {},
}: {
  testFn: () => any;
  timeout: number;
  /**
   * How frequently to rerun the test, this should be significantly smaller than any of the other numbers
   */
  checkInterval: number;
  /**
   * For how long the test needs to remain passing before we resolve
   * If not specified then even a momentary success will resolve
   */
  stability?: number;
  /** Callbacks to run after specific durations
   * @example
   * const callbacks = {
   *   [1000]: () => addWarning('Loading...'),
   *   [10000]: () => addWarning('This is taking a while'),
   *   [30000]: enableAbortButton
   * }
   */
  callbacks: { [after: number]: () => void };
}) => {
  const startAt = new Date().getTime();
  const remainingCallbacks = { ...callbacks };
  let passedAt: number | null = null;

  while (true) {
    const passed = testFn();
    const now = Date.now();
    const elapsed = now - startAt;
    if (timeout < elapsed) throw new Error('Timeout');
    Object.entries(remainingCallbacks).forEach(([key, callback]) => {
      if (Number(key) < elapsed) {
        delete remainingCallbacks[key];
        callback();
      }
    });
    if (passed) {
      if (!stability) return;
      if (!passedAt) passedAt = new Date().getTime();
      const passDuration = now - passedAt;
      if (stability < passDuration) return;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(checkInterval / 1000);
  }
};

export const getCanProductBeReturned = (product?: Product) => {
  const isRefundable = product?.nonRefundable !== 1;
  const hasPrice = !Number.isNaN(product?.price);
  const isNonStock = product?.nonStockProduct;
  const isSerialGiftCard = !!product?.isGiftCard;
  return isRefundable && (hasPrice || isNonStock) && !isSerialGiftCard;
};

/**
 * @param {object} row - Row to check for being fully returned
 * @param {object[]} returnedRows - Already returned rows (getReturnedRowIDsAndAmounts) to search within for the provided row
 * @return {boolean} Returns if the row has already been fully returned or not
 */
export const isRowFullyReturned = (row, returnedRows) => {
  const originalAmount = Number(row.originalAmount ?? row.amount);
  if (originalAmount < 0) return true;
  const returnedAmount = returnedRows.find(
    returnedRow => Number(returnedRow.stableRowID) === Number(row.stableRowID),
  )?.amount;
  return originalAmount === -returnedAmount;
};

/**
 * @param {object} sale - Sale object to check for being fully returned
 * @param {object[]} returnedRows - Already returned rows (getReturnedRowIDsAndAmounts) to search within for the provided row
 * @return {boolean} Returns if all of the sale rows have already been fully returned
 */
export const isSaleFullyReturned = (sale, returnedRows) => {
  const saleFullyReturned = sale.rows.every(row =>
    isRowFullyReturned(row, returnedRows),
  );

  return saleFullyReturned;
};

/**
 * @param {Number} id The productID to get images from
 * @returns { {
 *    id: string,
 *    name: string,
 *    xs: string,
 *    sm: string,
 *    md: string,
 *    lg: string
 * }[]}
 */

export const getProductImages = (product?: Product) => {
  // in case product somehow still is passed as undefined, return empty array
  if (!product) return [];
  return (product.images || []).map(img => ({
    id: img.pictureID,
    name: img.name,
    xs: img.thumbURL,
    sm: img.smallURL,
    md: img.largeURL,
    lg: img.fullURL,
  }));
};

export const updateSessionKey = async (clientCode, sessionKey) => {
  posSendMessage('auth:login')();
  localStorage.setItem(REDUX_SESSIONKEY, JSON.stringify(sessionKey));
};

/**
 * Returns a copy of the second object, containing only properties also present in the first
 */
export const intersect = R.useWith(R.pick, [R.keys, R.identity]);
/** True if the keys common to both objects share the same values - keys only present in one object are ignored */
export const intersectEquals = R.curry((a = {}, b = {}) => {
  return R.equals(intersect(a, b), intersect(b, a));
});

type ErrWithCause = Error & { cause?: ErrWithCause };
/**
 * Given an Error, will return a list containing the error itself and its recursive causes
 * @param The initial (highest) error
 * @returns all the errors as a list (passed error first, deepest error last)
 * @example
 *   const a = new Error('Network error')
 *   const b = new Error('Failed to load data', { cause: a });
 *   const c = new Error('Component could not render', { cause: b })
 *
 *   const stack = getCausalStack(c); // [c,b,a]
 */
export const getCausalStack = (err: Error) => {
  const stack: Error[] = [];
  let e: ErrWithCause | undefined = err as ErrWithCause;
  while (e) {
    stack.push(e);
    e = e.cause;
  }
  return stack;
};

/**
 * A method that removes trailing and leading commas off of a specified value
 * @param text
 */
export const trimComma = text => text?.replace(/,\s*$|^\s*,/g, '').trim() ?? '';

export function mockAsyncFn<P extends any[], R>(
  fn: (...params: P) => Promise<R>,
): (...params: P) => Promise<R>;
export function mockAsyncFn<P extends any[], R>(
  name: string,
  fn2: (...params: P) => Promise<R>,
): (...params: P) => Promise<R>;
export function mockAsyncFn<P extends any[], R>(
  fnOrName: string | ((...params: P) => Promise<R>),
  fn2?: (...params: P) => Promise<R>,
) {
  // @ts-ignore
  const name = fnOrName.name ?? fnOrName;
  return (...params: P) =>
    new Promise<R>((resolve, reject) => {
      // @ts-ignore
      console.info(`Mock function call: ${name}`, params, {
        resolve,
        reject,
      });
      (window as any).resolve = resolve;
      (window as any).reject = reject;
    });
}
export function mockSyncFn<P extends any[], R>(
  fn: (...params: P) => R,
): (...params: P) => R;
export function mockSyncFn<P extends any[], R>(
  name: string,
  fn: (...params: P) => R,
): (...params: P) => R;
export function mockSyncFn<P extends any[], R>(
  fnOrName: string | ((...params: P) => R),
  fn2?: (...params: P) => R,
) {
  // @ts-ignore
  const name = fnOrName.name ?? fnOrName;
  return (...params: P) =>
    JSON.parse(
      window.prompt(
        // @ts-ignore
        `${name}(${params
          .map(p => JSON.stringify(p))
          .join(',')})? (write response as valid json)`,
      )!,
    );
}

export const isOfflineError = error => {
  const offlineErrors = [
    'failed to fetch',
    'invalid or missing client code',
    'the internet connection appears to be offline',
    'could not connect, please check your internet connection',
    'networkerror when attempting to fetch resource',
    'error: forced offline mode is enabled',
  ];
  return offlineErrors.some(e =>
    error
      .toString()
      .toLowerCase()
      .includes(e),
  );
};

export async function withPerformanceMeasure(name, action) {
  window.performance.mark(`${name}_start`);
  const result = await action();
  window.performance.mark(`${name}_end`);
  window.performance.measure(name, `${name}_start`, `${name}_end`);
  return result;
}

interface UpdateNoteOptions {
  replace?: string | RegExp;
}

export function updateNotes(
  newNotes: string,
  originalNotes?: string,
  options?: UpdateNoteOptions,
) {
  if (!originalNotes) return newNotes;

  if (typeof options?.replace === 'string') {
    if (originalNotes.includes(options.replace)) {
      return originalNotes.replace(options.replace, newNotes);
    }
  } else if (options?.replace instanceof RegExp) {
    if (options.replace.test(originalNotes)) {
      return originalNotes.replace(
        options.replace,
        /**
         * Replacer is used avoid unexpected notes in case partner customer name contains
         * special syntax that replace function expects when it is used with regex (e.g. "$1")
         */
        () => newNotes,
      );
    }
  }

  return `${originalNotes}\n${newNotes}`;
}

export function getReason<T>(p: PromiseSettledResult<T>) {
  if (p.status === 'rejected') return p.reason;
  return null;
}

export function isPriceListActive(priceList: PriceList) {
  const unexpired = dateCompare(
    strToDate(priceList.startDate, '0001-01-01'),
    new Date(),
    strToDate(priceList.endDate, '9999-99-99'),
  );
  const active = !!Number(priceList.active);
  return unexpired && active;
}

export { ErplyLongAttributes } from 'utils/ErplyLongAttributes';
