/* eslint-disable no-console */
import { AxiosError } from 'axios';
import i18next from 'i18next';

import {
  CardPaymentHooks,
  FUNC_BUTTONS,
  PaymentObj,
} from 'paymentIntegrations/types';
import { getCafaEntry2 } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { sleep } from 'utils';

import { NETS_CONST, NetsConfig, TransactionDeclinedResponse } from '../types';
import nets from '../requests';

import printNets from './printing';

export default ({
  error,
  payment,
  hooks,
}: {
  error: AxiosError | TransactionDeclinedResponse;
  payment: PaymentObj;
  hooks: CardPaymentHooks;
}) => async (dispatch, getState) => {
  if ('response' in error) {
    // timed out request
    if (error.code === 'ECONNABORTED') {
      const config = getCafaEntry2<Required<NetsConfig>>(
        NETS_CONST.CAFA_CONFIG_NAME,
        INTEGRATION_TYPES.payment,
      )(getState());

      hooks.updateMessage(
        i18next.t(`${NETS_CONST.TRANS_PATH}.paymentUI.paymentResultTimeout`),
      );
      await nets
        .voidTransaction({
          config,
          params: {
            amount: Number(payment.amount),
            terminalId: config.terminal,
          },
        })
        .then(() => {
          hooks.updateMessage(
            i18next.t(`${NETS_CONST.TRANS_PATH}.paymentUI.voidSuccess`),
          );
        })
        .catch(e => console.error('Failed to void transaction in NETS cloud', e));
    } else if (typeof error?.response?.data?.failure === 'string') {
      hooks.updateMessage(
        i18next.t(
          `${NETS_CONST.TRANS_PATH}.paymentUI.transactionFailWithInfo`,
          { info: error.response.data.failure },
        ),
      );
    } else if (error?.response?.data?.failure?.error) {
      hooks.updateMessage(
        i18next.t(
          `${NETS_CONST.TRANS_PATH}.paymentUI.transactionFailWithInfo`,
          { info: error.response.data.failure.error },
        ),
      );
      await dispatch(
        printNets(null, error?.response?.data?.failure?.customerReceipt),
      );
    } else {
      hooks.updateMessage(
        i18next.t(`${NETS_CONST.TRANS_PATH}.paymentUI.transactionFail`),
      );
    }
  }
  // Await prior to enabling the buttons after failure - if "retry" tapped quick enough, user would get "TERMINAL BUSY"
  await sleep(2);

  hooks.enableButtons([FUNC_BUTTONS.RETURN_BACK, FUNC_BUTTONS.RETRY]);

  return false;
};
