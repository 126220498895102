import * as R from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useProducts from 'utils/hooks/useProducts';
import { getSelectedWarehouseID } from 'reducers/warehouses';

/**
 * Dedicated state for the "Create special order" modal
 *
 * This would be ideally split up into single-responbility hooks, but i found no clean way to do that,
 * the amount of linked state was getting messy
 *
 * This state represents:
 * 1) The selected warehouses to create Inventory Transfer Orders from
 * 2) The amounts of each product to order from each warehouse (by stableRowID)
 *
 * Additionally, it includes the following computed states
 * 1) Error states
 * 2) All available warehouses to select from
 * 3) Util function for getting the available stock of a given product (by stableRowID) at a given warehouse
 */
interface CreateSpecialOrderState {
  /** Get the current input value for a specific row-warehouse combo */
  getValue: (warehouseID: number, stableRowID: number) => string;
  /** Set the current input value for a specific row-warehouse combo */
  setValue: (warehouseID: number, stableRowID: number, value: string) => void;
  /** Get the amount of stock at the given warehouse for the given product */
  getStock: (warehouseID: number, stableRowID: number) => number | undefined;
  /** The current total amounts ordered for each stableRowID */
  totals: {
    [stableRowID: string]: number;
  };
  /**
   * Warehouses state
   */
  warehouses: {
    /** The options to choose from */
    value: number[];
    /** Shortcut to deselect all warehouses */
    clear: () => void;
    /** Shortcut to select a warehouse */
    add: (warehouseID: number) => void;
    /** Shortcut to deselect a warehouse */
    remove: (warehouseID: number) => void;
  };
  /**
   * Errors
   * Null if everything is good, otherwise indexed by row and then column
   * NB: There's also a special column named "total" for row-level errors
   * @example
   * {
   *   "503001: { // the first row
   *     "total": "Need 1 more", // Row-level error
   *     "3": "Stock exceeded", // Error for warehouse 3 on this row
   *   }
   * }
   */
  error: null | {
    [stableRowID: string]: {
      [warehouseID: string]: string;
    };
  };
};

/**
 * Util hook for managing the input state of the [Create special orders modal]{@link import('CreateSpecialOrderModal').CreateSpecialOrderModal}
 * Receives the list of rows that must be fulfilled, and returns {@link CreateSpecialOrderState}
 */
export const useSpecialOrderState = (
  rows: { productID: string|number; stableRowID: string|number; amount: string|number }[],
): CreateSpecialOrderState => {
  const [state, setState] = useState<{
    [warehouseID: string]: { [rowID: string]: string };
  }>({});
  const currentWarehouse = useSelector(getSelectedWarehouseID);
  // With localFirst, if products are already cached without stock information, that cache will be served instead!
  const { productsDict } = useProducts(
    {
      productIDs: rows.map(row => Number(row.productID)),
      getStockInfo: 1,
      getFields: 'productID,name,warehouses',
    },
    // Workaround for bug in useProducts - it sometimes serves cached data instead, even if the cached data doesn't have stock levels
    { localFirst: false, addToCachedItems: false, withMeta: false },
  );

  const strWarehouses = R.pipe(
    R.keys,
    R.map(Number),
    R.sortBy(R.identity),
    JSON.stringify,
  )(state);
  const getValue = useCallback(
    (warehouseID, stableRowID) => state[warehouseID]?.[stableRowID] ?? '',
    [state],
  );
  const setValue = useCallback(
    (warehouseID, stableRowID, value) => {
      setState(R.assocPath([warehouseID, stableRowID], value));
    },
    [setState],
  );
  const getStock = useCallback(
    (warehouseID, productID) =>
      productsDict[productID]?.warehouses?.[warehouseID]?.free,
    [productsDict],
  );
  const totals = useMemo(
    () =>
      Object.fromEntries(
        rows.map(row => {
          const inStock = getStock(currentWarehouse, row.productID) ?? 0;
          const target = Math.min(Number(row.amount), -inStock ?? 0);
          return [row.stableRowID, Math.max(0, target)];
        }),
      ),
    [rows, getStock, currentWarehouse],
  );

  return {
    getValue,
    setValue,
    getStock,
    warehouses: {
      clear: useCallback(() => setState({}), [setState]),
      add: useCallback(id => setState(R.assoc(id, {})), [setState]),
      remove: useCallback(id => setState(R.dissoc(id)), [setState]),
      value: useMemo(() => JSON.parse(strWarehouses), [strWarehouses]),
    },
    totals,
    error: useMemo(() => {
      const error = Object.fromEntries(
        rows.map(row => [
          row.stableRowID,
          {} as { [warehouseID: string]: string },
        ]),
      );
      const runningTotals = { ...totals };
      Object.entries(state).forEach(([warehouseID, values]) =>
        Object.entries(values).forEach(([stableRowID, amount]) => {
          const row = rows.find(row => row.stableRowID === Number(stableRowID));
          if (!Number(amount)) return;
          runningTotals[stableRowID] -= Number(amount);

          if (row === undefined) return;
          const stockFree = getStock(warehouseID, row.productID);
          if (stockFree === undefined) return;
          if (stockFree < Number(amount)) {
            error[stableRowID][warehouseID] = 'Out of stock';
          }
        }),
      );
      Object.entries(runningTotals).forEach(([stableRowID, remaining]) => {
        if (remaining < 0) {
          error[stableRowID].total = `${-remaining} too many`;
        } else if (0 < remaining) {
          error[stableRowID].total = `order ${remaining} more`;
        }
      });
      return R.pipe(
        // Filter out rows that are ok
        R.filter(R.complement(R.isEmpty)),
        // If all OK, return null
        R.when(R.isEmpty, R.always(null)),
      )(error);
    }, [getStock, rows, state, totals]),
  };
};
