/**
 * Next are specific to the provider or their device configuration
 *
 * apiHost {string} -  Host name or IP address for junction@pos api (in production it is localhost)
 *
 * apiPort {number} - Specifies port for junction@pos api (in most cases 20001)
 *
 * posId {string} - This id should be provided by Junction@Pos provider (customers should obtain it during registration)
 *
 * storeId {string} - This id should be provided by Junction@Pos provider (customers should obtain it during registration)
 *
 * chain {string} - This parameter is used when there is multiple lanes depends from 1 service, providing service to all lanes in a chain environment, across the stores in the chain. (At this point leave it as default, as we do not support multiple terminals yet)
 *
 *
 *Those are for the service itself:
 *
 * SSLEnable {boolean} - Use secured connection (https) (only for MS) Note: if ssl is on, requests to the junction@pos api will also use ssl connection
 *
 * Port {number} - Specifies port for this micro-service
 *
 *
 * Those are optional, for service logging:
 *
 * logLevel {string} - Minimum logging level. Possible values from highest to lowest: ERROR, WARN, INFO, DEBUG, TRACE. (Default: INFO)
 *
 * logTarget {string} - The output target for logging. Possible values: FILE, STDOUT (Default: STDOUT)
 *
 * logFormat {string} - The format of logs, possible values are: TEXT, JSON (Default: JSON).
 * @constructor
 */

export const junctionFields = {
  provider: {
    apiHost: {
      id: 'apiHost',
      type: 'text',
      validate: () => null,
    },
    apiPort: {
      id: 'apiPort',
      type: 'text',
      validate: () => null,
    },
    posId: {
      id: 'posId',
      type: 'text',
      validate: () => null,
    },
    storeId: {
      id: 'storeId',
      type: 'text',
      validate: () => null,
    },
    // TODO: add when multi printer is available
    // chan: {
    //   id: 'chan',
    //   type: 'text',
    // },
  },
  // TODO: add to Wizard
  logging: {
    logLevel: {
      id: 'logLevel',
      type: 'select',
      options: ['ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE'],
      validate: () => null,
    },
    logTarget: {
      id: 'logTarget',
      type: 'select',
      options: ['STDOUT', 'FILE'],
      validate: () => null,
    },
    logFormat: {
      id: 'logFormat',
      type: 'select',
      options: ['JSON', 'TEXT'],
      validate: () => null,
    },
  },
  printing: {
    print: {
      id: 'print',
      type: 'select',
      options: ['ask', 'automatic'],
      validate: () => null,
    },
  },
};

export const defaultOptions = {
  logLevel: 'TRACE',
  logTarget: 'STDOUT',
  logFormat: 'JSON',
  print: 'ask',
};
