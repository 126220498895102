import axios from 'axios';

import { TransactionResponse } from 'paymentIntegrations/netsCloud/types';
import { getMSEndpointFromLS } from 'reducers/installer';

import {
  CloseBatchResponse,
  PaymentResponse,
  StatusResponse,
  TestConnectionResponse,
  TransactionData,
  PaymentData,
} from './types';

const instance = axios.create({
  baseURL: `${getMSEndpointFromLS('pax') ||
    'https://localhost.erply.com:10101'}api/v1/`,
  timeout: 200e3,
  headers: {
    Accept: '*',
    'Content-Type': 'application/json',
  },
});

export const makePayment = async (payment: PaymentData) => {
  return instance
    .post<PaymentResponse>('/payment', payment)
    .then(res => res.data);
};

export const closeBatch = async () => {
  return instance.get<CloseBatchResponse>('/closeBatch').then(res => res.data);
};

export const testConnection = async () => {
  return instance
    .get<TestConnectionResponse>('testConnection')
    .then(res => res.data);
};
export const getTransactionStatus = async (data: TransactionData) => {
  return instance
    .post<TransactionResponse>('payment', data)
    .then(res => res.data);
};

export const manageRequest = async (manageType = 'Status') => {
  return instance
    .post<StatusResponse>('manage', { manageType })
    .then(res => res.data);
};
