import React, {
  ChangeEvent,
  FormEvent,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { getGiftCard } from 'services/ErplyAPI/campaigns';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { addError, addWarning } from 'actions/Error';
import {
  getCurrencyFormatter,
  getDateFormatter,
} from 'reducers/configs/settings';
import { GiftCard } from 'types/GiftCard';
import './GiftCardBalance.sass';

const useStyles = makeStyles(() => ({
  'gift-card-balance-info': {
    marginTop: '15px',
    '& p': {
      width: '100%',
      fontSize: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      '& span:last-child': {
        fontWeight: 700,
      },
    },
  },
}));

const PURCHASED = 'headers.purchased';
const BALANCE = 'headers.balance';
const EXPIRY = 'headers.expiration';

const GiftCardBalance = () => {
  const { t } = useTranslation('giftcard');
  const styles = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [giftCard, setGiftCard] = useState({
    [PURCHASED]: '',
    [BALANCE]: '',
    [EXPIRY]: '',
  });

  const formatDate = useSelector(getDateFormatter);
  const formatCurrency = useSelector(getCurrencyFormatter);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const getGiftCardBalance = async (code: string) => {
    if (!code.length) {
      dispatch(
        addWarning(t('alerts.missingSerial'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    } else {
      try {
        const [giftCard]: GiftCard[] = await getGiftCard({
          code,
        });
        if (giftCard) {
          const { balance, purchaseDateTime, expirationDate } = giftCard;
          setGiftCard({
            [PURCHASED]: purchaseDateTime
              ? formatDate(purchaseDateTime * 1000)
              : '',
            [BALANCE]: formatCurrency(balance ?? 0),
            [EXPIRY]: expirationDate ? formatDate(expirationDate) : '',
          });
        } else {
          dispatch(
            addError(t('alerts.serialNotFound', { serial: value }), {
              selfDismiss: true,
              dismissible: false,
            }),
          );
        }
      } catch (err) {
        dispatch(
          addError(t('alerts.genericError'), {
            selfDismiss: true,
            dismissible: false,
          }),
        );
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value.replace(/(\s*)/g, ''));

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      getGiftCardBalance(value);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getGiftCardBalance(value);
  };

  return (
    <div data-testid="gift-card-balance">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1.25rem"
        paddingY="0.75rem"
      >
        <Box paddingLeft="5px">
          <Typography variant="h5">{t('balance.title')}</Typography>
        </Box>
        <div>
          <CloseButton
            style={{ transform: 'scale(0.85)' }}
            action={() => dispatch(previousModalPage())}
          />
        </div>
      </Box>
      <Divider />
      <Box padding="1.25rem">
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            aria-label="Gift card serial number"
            placeholder={t('fields.serial_placeholder')}
            type="text"
            inputRef={inputRef}
            value={value}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            data-testid="name-or-code-input"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className="search-button"
                  onClick={() => getGiftCardBalance(value)}
                  data-testid="search-button"
                >
                  <i className="flip-horizontal  icon_search" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </form>
        <div className={styles['gift-card-balance-info']}>
          {Object.keys(giftCard).map(el => (
            <p data-testid="giftcard-info" data-test-key={el} key={el}>
              <span data-testid="title" data-test-key={t(el)}>
                {t(el)}:
              </span>
              <span data-testid="value" data-test-key={giftCard[el]}>
                {giftCard[el]}
              </span>
            </p>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default GiftCardBalance;
