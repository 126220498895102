import { useEffect, useState } from 'react';

export const useResettingState = <T>(initialState: T, timeout: number) => {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    if (state) {
      const t = setTimeout(() => {
        setState(initialState);
      }, timeout);
      return () => clearTimeout(t);
    }
  }, [state]);

  return [state, setState] as const;
};
