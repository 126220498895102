import {
  getCustomers,
  getPayments,
  getSalesDocuments,
} from 'services/ErplyAPI';
import { addError } from 'actions/Error';
import { openPaymentModal } from 'actions/modalPage';
import { ErplyAttributes } from 'utils';
import { printReceipt } from 'actions/integrations/printer';

import { baseLog } from '../../constants';

const payForOrderLog = baseLog.extend('payForOrder');
const printOrderReceiptLog = baseLog.extend('printOrderReceipt');

export const payForOrder = (orderId: number) => async dispatch => {
  const log = payForOrderLog;

  if (!orderId) {
    dispatch(addError('Cannot find order. Order id is missing.'));
    return;
  }

  try {
    log('Fetching sale document', orderId);
    const sales = await getSalesDocuments({ id: orderId });

    if (!sales.length || sales.length > 1) {
      dispatch(addError('Cannot find order.'));
      return;
    }

    const order = sales[0];
    log('Retrieved order:', order);

    log('Fetching payments and customer');
    const [payments, customers] = await Promise.all([
      getPayments({
        documentID: orderId,
      }),
      getCustomers({
        customerID: order.clientID,
      }),
    ]);
    log('Retrieved data:', { payments, customers });

    log('Confirming order and updating its attributes');
    const attributes = new ErplyAttributes(order.attributes ?? []);
    attributes
      .set('pickupStatus', 'DELIVERED')
      .set('pickupProcessed', 1)
      .set('ConfirmInvoice', 1);

    const updatedOrder = {
      ...order,
      confirmed: 1,
      attributes: attributes.asArray,
    };

    log('Opening payment modal', { updatedOrder });
    await dispatch(
      openPaymentModal({
        props: {
          ignoreCurrent: true,
          total: updatedOrder.total,
          salesDocument: {
            ...updatedOrder,
            // Empty rows causes no row info to be saved (doc already exists, so it's fine)
            modifiedRows: [],
          },
          currentSalesDocument: updatedOrder,
          originalPayments: payments,
          id: orderId,
          type: updatedOrder.type,
          customer: customers[0],
        },
      }),
    );
  } catch (error) {
    console.error(error);
    dispatch(addError('Failed to confirm order'));
  }
};

export const printOrderReceipt = (orderId: number) => async dispatch => {
  const log = printOrderReceiptLog;

  try {
    log('Fetching order', orderId);
    const sales = await getSalesDocuments({ id: orderId });

    if (!sales.length || sales.length > 1) {
      dispatch(addError('Cannot find order.'));
      return;
    }

    const order = sales[0];
    log('Retrieved order:', order);

    log('Fetching payments');
    const payments = await getPayments({
      documentID: orderId,
    });
    log('Retrieved payments:', payments);
    log('Printing receipt');
    await dispatch(printReceipt({ ...order, payments }));
  } catch (error) {
    console.error(error);
    dispatch(addError('Failed to print order receipt'));
  }
};
