/* eslint-disable @typescript-eslint/camelcase */
import axios, { AxiosResponse } from 'axios';
import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getClientCode, getSessionKey, getUserLoggedIn } from 'reducers/Login';
import { getSetting } from 'reducers/configs/settings';
import {
  CustomerMembership,
  CustomerMasterRecord,
  CustomerPurchaseDate,
  CustomerStats,
  RecentSale,
} from 'plugins/wbu/API/types';
import { proxy } from 'services/shared';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getServiceEndpoints } from 'reducers/configs/serviceEnpoints';
import { RootState } from 'reducers';

const getSalesBaseUrl = state =>
  getSetting('plugin_reorder_worksheet_api_endpoint')(state);

const getDscUrl = state =>
  getSetting('plugin_dsc_loyalty_membership_base_url')(state);

const getShouldUseDSCAPI = state =>
  !!getSetting('use_dsc_membership_service')(state);

const getMembershipURL = createSelector(
  getShouldUseDSCAPI,
  getServiceEndpoints,
  getDscUrl,
  (shouldUseOldAPI, servicePoints, oldAPIUrl) => {
    return String(
      (shouldUseOldAPI ? oldAPIUrl : servicePoints?.memberships?.url) ??
        'https://api-lms-us.erply.com/',
    );
  },
);

const getCustomerUrl = createSelector(
  getUserLoggedIn,
  ({ customerRegistryURLs: [url] }) => url?.url,
);

const getJWT = createSelector(
  getUserLoggedIn,
  ({ customerRegistryURLs: [url] }) => url?.token,
);

const getKeys = createSelector(
  getSalesBaseUrl,
  getDscUrl,
  getCustomerUrl,
  getClientCode,
  getSessionKey,
  getJWT,
  getMembershipURL,
  (
    salesBaseUrl,
    dscUrl,
    customerUrl,
    clientCode,
    sessionKey,
    jwt,
    membershipURL,
  ) => ({
    jwt,
    dscUrl,
    customerUrl,
    clientCode,
    sessionKey,
    salesBaseUrl,
    membershipURL,
  }),
);

const getHeaders = createSelector(
  getKeys,
  getShouldUseDSCAPI,
  ({ jwt, clientCode, sessionKey }, shouldUseOldAPI) => {
    const commonHeaders = {
      'Content-Type': 'application/json',
      'Customer-Registry-JWT': jwt,
    };
    const lmsHeaders = {
      clientCode,
      sessionKey,
    };
    const dscHeaders = {
      'Erply-Api-Client-Code': clientCode,
      'Erply-Api-Session-Key': sessionKey,
    };
    const membershipHeaders = {
      ...commonHeaders,
      ...(shouldUseOldAPI ? dscHeaders : lmsHeaders),
    };
    const baseHeaders = {
      ...commonHeaders,
      'Erply-Api-Client-Code': clientCode,
      'Erply-Api-Session-Key': sessionKey,
    };
    return {
      baseHeaders,
      membershipHeaders,
    };
  },
);

/**
 * @see https://wbu-sh-sb.erply.com/v1/documentation#/
 */
const WBUAPI = {
  getMembershipData: customerId => async (
    _dispatch,
    getState: () => RootState,
  ) => {
    const state = getState();
    const { membershipURL } = getKeys(state);
    const { membershipHeaders: headers } = getHeaders(getState());
    const url = `${membershipURL}memberships?customerId=${customerId}&page=1&recordsPerPage=25`;
    return axios
      .get(url, {
        headers,
      })
      .then(res => res.data);
  },

  // https://wbudcs-sb.erply.com/v1/customers/{customer_id}/memberships
  // DSC member since
  getCustomerMemberships: customerID => async (
    _dispatch,
    getState: () => RootState,
  ): Promise<CustomerMembership> => {
    const { membershipURL } = getKeys(getState());
    const useOldAPI = getShouldUseDSCAPI(getState());
    const url = `${
      !useOldAPI ? proxy : ''
    }${membershipURL}customers/${customerID}/memberships`;
    const { membershipHeaders: headers } = getHeaders(getState());

    return axios.get(url, { headers }).then(res => res.data);
  },

  // https://wbudcs-sb.erply.com/v1/membership-purchases
  // DSC membership first purchase date and member since date
  getMembershipPurchases: ({ customerId, createdAfter }) => async (
    _dispatch,
    getState: () => RootState,
  ): Promise<{
    pagination: {
      recordsTotal: number;
      recordsInResponse: number;
      recordsPerPage: number;
      currentPage: number;
      totalPages: number;
    };
    data: CustomerPurchaseDate[];
  }> => {
    const { membershipURL } = getKeys(getState());
    const { membershipHeaders: headers } = getHeaders(getState());

    return axios
      .get(`${membershipURL}membership-purchases`, {
        params: { customerId, createdAfter, recordsPerPage: 500 },
        headers,
      })
      .then(res => res.data);
  },

  // https://wbudcs-sb.erply.com/v1/membership-purchases
  // Update DSC membership date
  updateCustomerMembershipDate: ({
    customerId,
    salesDocumentId,
    amount,
  }) => async (dispatch, getState: () => RootState): Promise<unknown> => {
    const { membershipURL } = getKeys(getState());
    const { membershipHeaders: headers } = getHeaders(getState());
    const warehouseId = getSelectedWarehouseID(getState());
    const useOldAPI = getShouldUseDSCAPI(getState());

    return axios.post(
      `${membershipURL}membership-purchases`,
      {
        warehouseId: Number(warehouseId),
        customerId,
        salesDocumentId,
        ...(useOldAPI
          ? {
              amount,
            }
          : {
              duration: amount,
              durationUnit: 'year',
            }),
      },
      {
        headers,
      },
    );
  },

  // https://wbudcs-sb.erply.com/v1/membership-purchases/return
  // https://api-lms-sb.erply.com/documentation/index.html#/membership-purchases/post_v2_membership_purchases_return
  // Return DSC membership purchase
  returnMembershipPurchase: ({
    validationOnly,
    customerId,
    amount,
    salesDocumentId,
    relatedDocumentId,
  }) => async (
    _dispatch,
    getState: () => RootState,
  ): Promise<AxiosResponse<unknown>> => {
    const { membershipURL } = getKeys(getState());
    if (!membershipURL) {
      throw new Error('DSC membership URL is not defined');
    }
    const { membershipHeaders: headers } = getHeaders(getState());
    const warehouseId = getSelectedWarehouseID(getState());
    const useOldAPI = getShouldUseDSCAPI(getState());
    const endpoint = useOldAPI
      ? 'membership-purchases'
      : 'v2/membership-purchases';

    return axios
      .post(
        `${membershipURL}${endpoint}/return`,
        {
          validationOnly,
          warehouseId: Number(warehouseId),
          customerId,
          salesDocumentId,
          [useOldAPI ? 'amount' : 'duration']: amount,
          relatedDocumentId,
        },
        {
          headers,
        },
      )
      .then(res => res.data)
      .catch(err => {
        const errMsg = err?.response?.data?.message;
        if (errMsg) {
          throw new Error(errMsg);
        }
        throw new Error(err.message);
      });
  },

  // https://wbu-sh-sb.erply.com/v1/reports/customer-stats
  // Total visits, total sales, DSC savings, life savings, last visited
  // Gets customer purchase statistics through a POST request
  // The body is the customer's ID and a date (currently just null)
  getCustomerStats: (
    /** A mapping of customer IDs to activation dates */
    body: { [customerID: string]: string | null },
  ) => async (
    dispatch,
    getState,
  ): Promise<{
    status?: {
      responseStatus: 'ok' | string;
      recordsTotal: number;
      generationTime: number;
    };
    data: CustomerStats[];
  }> => {
    const { salesBaseUrl } = getKeys(getState());
    const { baseHeaders: headers } = getHeaders(getState());

    return axios
      .post(`${salesBaseUrl}/v1/reports/customer-stats`, JSON.stringify(body), {
        headers,
      })
      .then(res => res.data)
      .catch(() => {
        return {
          data: [
            {
              customer_id: 0,
              'max(cdm.last_transaction_id)': 0,
              'max(cdm.last_transaction_number)': 0,
              last_visit: undefined,
              first_visit: undefined,
              total_visits: 0,
              total_sales: 0,
              total_savings: 0,
              total_dsc_savings: 0,
              dsc_current_savings: 0,
              potential_savings: 0,
            },
          ],
        };
      });
  },

  // https://wbu-cr-sb.erply.com/V1/Customer/getMasterRecord
  // Get regular customer data for WBU
  getCustomerMasterRecord: customerID => async (
    dispatch,
    getState,
  ): Promise<{
    api: {
      cache: {
        cached: boolean;
        ttl: any;
        expires: any;
      };
      generationTime: string;
      path: string;
      time: number;
      uniqueId: string;
    };
    error: {
      message: 'OK' | string;
      code: any | null;
    };
    result: CustomerMasterRecord;
  }> => {
    const keys = getKeys(getState());
    return axios
      .get(`${keys.customerUrl}V1/Customer/getMasterRecord`, {
        params: {
          'parameters[id]': customerID,
          'api[jwt]': keys.jwt,
        },
      })
      .then(res => res.data);
  },

  updateCustomerMasterRecord: (
    data: Partial<CustomerMasterRecord> & { id: number },
  ) => async (dispatch, getState) => {
    const { result: current } = await dispatch(
      WBUAPI.getCustomerMasterRecord(data.id),
    );
    const { baseHeaders: headers } = getHeaders(getState());
    const keys = getKeys(getState());
    return axios.post<void>(
      `${keys.customerUrl}V1/Customer/update`,
      {
        'api[jwt]': keys.jwt,
        parameters: R.mergeDeepLeft(data, current),
      },
      { headers },
    );
  },

  getRecentSales: ({
    customerID,
    count = 15,
    offset = 0,
    ...rest
  }: {
    customerID: string | number;
    count?: number;
    offset?: number;
  }) => async (
    dispatch,
    getState,
  ): Promise<{
    status: {
      generationTime: number;
      recordsTotal: number;
      responseStatus: 'ok' | string;
    };
    data: Record<string, RecentSale>;
  }> => {
    const { salesBaseUrl } = getKeys(getState());
    const { baseHeaders: headers } = getHeaders(getState());
    const data = new FormData();
    Object.entries({ ...rest, count, customerID, offset }).forEach(
      ([key, value]: [string, any]) => {
        data.set(key, value);
      },
    );
    return axios
      .post(`${salesBaseUrl}/v1/reports/customer-recent-sales`, data, {
        headers,
      })
      .then(res => res.data);
  },
  createPreMembership: (customerId: string | number) => async (
    dispatch,
    getState: () => RootState,
  ) => {
    const { membershipURL } = getKeys(getState());
    const { membershipHeaders: headers } = getHeaders(getState());
    const useOldAPI = getShouldUseDSCAPI(getState());
    const urlString = useOldAPI
      ? 'pre-membership'
      : `pre-memberships?customerId=${customerId}&duration=30`;

    return axios
      .post(
        `${proxy}${membershipURL}${urlString}`,
        useOldAPI ? { customerId } : {},
        { headers },
      )
      .then(res => res.data);
  },
  removePreMembership: (customerId: string | number) => async (
    dispatch,
    getState: () => RootState,
  ) => {
    const { membershipURL } = getKeys(getState());
    const { membershipHeaders: headers } = getHeaders(getState());
    const useOldAPI = getShouldUseDSCAPI(getState());
    const urlParams = useOldAPI
      ? 'pre-membership'
      : `pre-memberships?customerId=${customerId}`;

    return axios.delete(`${proxy}${membershipURL}${urlParams}`, {
      headers,
      data: useOldAPI
        ? {
            customerId,
          }
        : {},
    });
  },
  renewMembership: (customerId: number, endDate: any) => async (
    dispatch,
    getState: () => RootState,
  ) => {
    const shouldUseOldAPI = getShouldUseDSCAPI(getState());
    const { membershipHeaders: headers } = getHeaders(getState());
    const { membershipURL } = getKeys(getState());
    const keyToUse = shouldUseOldAPI ? 'endDateTime' : 'endDate';
    return axios
      .patch(
        `${membershipURL}memberships?customerId=${customerId}&endDate=${endDate}T23:59:59`,
        {
          customerId,
          [keyToUse]: `${endDate}T23:59:59`,
        },
        { headers },
      )
      .then(res => res.data);
  },
};

export default WBUAPI;

// prettier-ignore
const sDocFields: (keyof RecentSale)[] = ["id", "sales_document_id", "sales_document_number", "type", "date_time", "currency_rate", "currency_code", "warehouse_id", "customer_id", "added", "last_modified", "last_synchronized", "client_code", "deleted", "date_modified"]
// prettier-ignore
const rowFields: (keyof RecentSale)[] = ["row_id", "product_id", "product_name", "code", "code2", "code4", "code8", "product_top_group_id", "product_top_group_name", "product_group_id", "product_group_name", "product_category_name", "priority_group_name", "warehouse_value", "price", "discount", "final_net_price", "final_price_with_vat", "amount", "row_net_total", "row_vat", "row_total_with_vat", "sales_profit", "return_reason_name", "potential_savings", "was_member"]

export type groupedSale = Pick<RecentSale, typeof sDocFields[number]> & {
  rows: Pick<RecentSale, typeof rowFields[number]>[];
};
export const groupSalesHistory = R.pipe(
  // Sort by row id
  R.sort<RecentSale>(R.ascend(R.prop('row_id'))),
  // Group by document_id
  R.groupBy(R.prop('sales_document_number')),
  // Merge into one sales doc with multiple rows
  R.map(
    sdoc =>
      (R.assoc(
        'rows',
        R.map(R.pick<RecentSale, keyof RecentSale>(rowFields))(sdoc),
        R.pick(sDocFields)(sdoc[0]),
      ) as any) as groupedSale,
  ),
  // @ts-ignore Ungroup
  R.values,
  // Sort sales documents
  R.sort<groupedSale>(R.descend(R.prop('date_time'))),
);
