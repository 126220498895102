import axios from 'axios';

import { addError } from 'actions/Error';

import { CompanyRecordByCode, Response } from './constants';

const generateUrl = companyName =>
  `https://ariregister.rik.ee/est/api/autocomplete?q=${companyName}`;

const url = 'https://ep.intralplugins.com/ARAutocomplete/proxy.php';

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
};

export const api = {
  getCompanyByName: (companyName: string) => async dispatch => {
    try {
      const { data } = await axios.get<Response>(generateUrl(companyName));
      return data;
    } catch (error) {
      dispatch(
        addError(`Failed to search companies by name: ${error.message}`, {
          selfDismiss: 5000,
        }),
      );
      console.error('Failed to fetch company by name', companyName, error);
    }
  },
  getCompanyByCode: (code: number) => async dispatch => {
    try {
      const params = new URLSearchParams({
        code: String(code),
      });
      const { data } = await axios.post<CompanyRecordByCode>(url, params, {
        headers,
      });
      return data;
    } catch (error) {
      dispatch(addError(`Failed to search company by code: ${error.message}`), {
        selfDismiss: 5000,
      });
      console.error('Failed to fetch company by code', code, error);
    }
  },
};
