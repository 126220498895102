import React, { useState, useCallback, useMemo, ChangeEvent, FC } from 'react';
import { Box, Grid, InputAdornment, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { sendInvoiceByEmail } from 'actions/sales';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import Icon from 'components/Icon';
import CloseButton from 'components/CustomButtons/CloseButton';
import { validateEmail } from 'containers/Login/validations';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import ConfirmOptionButton from '../Payment/components/Buttons/ConfirmOption';
import { useStyles } from '../Payment/views/Confirmation';

interface Props {
  customerEmail: string;
  invoiceID: string;
}

const EmailModal: FC<Props> = ({ customerEmail, invoiceID }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [enteredAddress, setEnteredAddress] = useState(customerEmail || '');

  const isInvalidMail = useMemo(() => !!validateEmail(enteredAddress).length, [
    enteredAddress,
  ]);

  const closePopUp = useCallback(() => {
    dispatch(previousModalPage());
  }, [dispatch]);

  useShortcut('Escape', closePopUp, 100);

  return (
    <div data-testid="confirmation" className={styles.confirmation}>
      <Box padding="1.5rem" borderBottom="1px solid #dee2e6">
        <h4 data-testid="header">Email document</h4>
        <span style={{ flexGrow: 1 }} />
        <CloseButton action={closePopUp} className="close-button" />
      </Box>
      <Box padding="1rem" display="flex" flexDirection="column" gridGap="1rem">
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter email"
              data-testid="email"
              type="text"
              value={enteredAddress}
              onChange={e => setEnteredAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <ConfirmOptionButton
              action={() =>
                dispatch(sendInvoiceByEmail({ invoiceID, enteredAddress }))
              }
              title="Email"
              data-testid="email-button"
              disabled={isInvalidMail}
              icon={<Icon name="icon_mail_alt" />}
              color="grid_dark"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EmailModal;
