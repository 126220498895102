import axios from 'axios';

import { generateToken } from './utils';

const CUSTOMER_BUCKET_ID = 3;

const getBaseUrl = isProduction =>
  isProduction
    ? 'https://registration.mmms.ca/api/registration/'
    : 'https://dev-registration.mmms.ca/api/registration/';

export const generateCustomerCardNumber = async ({
  isProduction,
  storeAccountNumber,
}: {
  isProduction: boolean;
  storeAccountNumber: string;
}): Promise<string> => {
  const token = await generateToken(storeAccountNumber, isProduction);
  return axios
    .get(`${getBaseUrl(isProduction)}GetNewCustomerNumber`, {
      params: {
        token,
        clientAppID: storeAccountNumber,
        customerBucketID: CUSTOMER_BUCKET_ID,
      },
    })
    .then(({ data }) => data);
};

export const acknowledgeRegistrationToErply = async ({
  isProduction,
  storeAccountNumber,
  customerID,
  customerCardNumber,
}: {
  isProduction: boolean;
  storeAccountNumber: string;
  customerID: number;
  customerCardNumber: number;
}) => {
  const token = await generateToken(storeAccountNumber, isProduction);
  return axios
    .post(`${getBaseUrl(isProduction)}PostCustomerRegistrationAck`, {
      Token: token,
      ClientAppID: storeAccountNumber,
      CustomerBucketID: CUSTOMER_BUCKET_ID,
      POSCustomerID: customerID,
      RetrievedCustomerID: customerCardNumber,
    })
    .then(({ data }) => {
      if (data !== 'OK') {
        throw new Error(data);
      }
    });
};
