import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export const useBehaviourSubject = data => {
  const [subject$] = useState(new BehaviorSubject(data));
  useEffect(() => {
    return () => subject$.complete();
  }, []);

  useEffect(() => {
    subject$.next(data);
  }, [data]);
  return subject$;
};
