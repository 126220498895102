import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getCafaEntry } from 'reducers/cafaConfigs';
import { formatReceipt } from 'Systems/PrinterScript/exports';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { getPayments } from 'reducers/Payments';
import { RootState } from 'reducers/index';

import { BrowserActions, MSActions, WrapperActions } from './actions';
import prepareOfflineData from './prepareOfflineData';
import { OfflineSalesDocument, Options } from './types';
import { getLocalPatchScript, getPatchScript } from './utils';

export function offlinePrint(
  salesDocument: OfflineSalesDocument,
  options: Options,
) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    const { documentActualReport, salesDocument: doc } = await dispatch(
      prepareOfflineData({ salesDocument }),
    );
    const patchScript = await dispatch(
      getPatchScript(
        {
          documentActualReport,
          salesDocument: doc,
          printoutType: 'PATCH_SCRIPT',
        },
        options,
      ),
    );

    const payments = getPayments(getState());

    const { on, after } = getPluginLifecycleHook('onPrintReceipt')(getState());

    const useWrapper =
      getCafaEntry<'printer', 'wrapper', { enabled: boolean }>(
        'wrapper',
        'printer',
      )(getState())?.value.enabled ?? false;

    const useMS =
      getCafaEntry<'printer', 'goMS', { enabled: boolean }>(
        'goMS',
        'printer',
      )(getState())?.value.enabled ?? false;

    const useBrowser =
      getCafaEntry<'printer', 'browser', { enabled: boolean }>(
        'browser',
        'printer',
      )(getState())?.value.enabled ?? false;

    const funcs = [
      useWrapper && WrapperActions.printPatchscript(patchScript),
      useMS &&
        MSActions.print(
          localStorage.getItem('MS.print.legacy')
            ? await dispatch(
                // @ts-ignore
                formatReceipt(patchScript, { giftReceipt: options.giftReceipt }),
              )
            : JSON.stringify(patchScript),
        ),
      useBrowser && BrowserActions.printPatchScript(patchScript),
    ].filter(a => a);

    await dispatch(
      on({ payments, salesDocument, options }, { patchScript }),
    ).catch(e => {
      throw e;
    });

    await Promise.all(funcs.map(dispatch));

    dispatch(after({ payments, salesDocument, options }, {})).catch(e => {
      throw e;
    });
  };
}

export function offlineBrowserPrint(
  salesDocument: OfflineSalesDocument,
  options: Options,
) {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    const patchScript = await dispatch(
      getLocalPatchScript(salesDocument, options),
    );
    dispatch(BrowserActions.printPatchScript(patchScript));
  };
}
