import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  InstallerStatus,
  installerStatusResponses$,
} from '@pos-refacto/installer-rxjs';
import { take } from 'rxjs/operators';

import { getClientCode, getSessionKey } from 'reducers/Login';
import { Product } from 'types/Product';
import { getCafaEntry, getGeneralPrintingSettings } from 'reducers/cafaConfigs';
import { INTEGRATIONS, INTEGRATION_TYPES } from 'constants/CAFA';
import { getTemplateForType } from 'reducers/patchScript';
import { composeReceiptWithSchema } from 'reducers/patchScript/composeReceiptWithSchema';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import {
  getSalesDocumentActualReportsDataset,
  getSalesDocuments,
} from 'services/ErplyAPI/sales';
import { getIsModuleEnabled } from 'reducers/configs/settings';
import { RootState } from 'reducers/index';
import { SaveSalesDocumentResponse } from 'types/SalesDocument';
import { addError } from 'actions/Error';

import { getPatchScript as getPatchScriptFromReceiptPrintoutApi } from './receiptPrintoutApi/api';
import { GetPatchScriptParams } from './receiptPrintoutApi/types';
import { OfflineSalesDocument, Options, Row, SalesDocument } from './types';
import { renderPatchscript, wrapPngData } from './actions';
import { prepareReceiptData } from './preparePrintoutData';
import prepareOfflineData from './prepareOfflineData';

export function getSaleDocID(salesDocument: SalesDocument) {
  return salesDocument.id ?? (salesDocument as SaveSalesDocumentResponse).invoiceID;
}

export function mapRow(
  product: Partial<Row & Product>,
  currencySymbol: string,
) {
  return {
    productID: String(product.productID),
    amount: String(product?.amount),
    amountAvailable: product?.free,
    amountInStock: product?.totalInStock,
    amountReserved: 0,
    brand: product?.brandName,
    category: product?.categoryName,
    code: product?.code,
    code2: product?.code2,
    code3: product?.code3,
    description: product?.description,
    discount: String(product?.discount),
    finalNetPrice: Number(product?.finalPrice),
    finalNetPriceWithCurrency: `${product.finalPrice} ${currencySymbol}`,
    finalPriceWithVAT: Number(product?.finalPriceWithVAT),
    finalPriceWithVATWithCurrency: `${product.finalPriceWithVAT} ${currencySymbol}`,
    grossWeight: String(product?.grossWeight),
    grossWeightWithUnits: `${product?.grossWeight} ${product?.unitName}`,
    imageURL1: product?.images?.[0],
    locationInWarehouse: product?.locationInWarehouse,
    longDescription: product?.longdesc,
    manufacturer: product?.manufacturerName,
    netWeight: String(product?.netWeight),
    netWeightWithUnits: `${product?.netWeight} ${product?.unitName}`,
    no: Number(product?.unitID),
    originalNetPrice: String(product?.basePriceNet),
    originalNetPriceWithCurrency: `${product.basePriceNet} ${currencySymbol}`,
    originalPriceWithVAT: Number(product?.basePriceWithVAT),
    originalPriceWithVATWithCurrency: `${product.basePriceWithVAT} ${currencySymbol}`,
    packages: product?.packages,
    productGrossWeight: String(product?.grossWeight),
    productGrossWeightWithUnit: `${product?.grossWeight} ${product?.unitName}`,
    productGroup: product?.groupName,
    productHeight: String(product?.height),
    productHeightWithUnit: `${product?.height} ${product?.unitName}`,
    productLength: String(product?.length),
    productLengthWithUnit: `${product?.length} ${product?.unitName}`,
    productName: product?.name,
    productNetWeight: String(product?.netWeight),
    productNetWeightWithUnit: `${product?.netWeight} ${product?.unitName}`,
    productVolume: String(product?.volume),
    productVolumeWithUnit: `${product?.volume} ${product?.unitName}`,
    productWidth: String(product?.width),
    productWidthWithUnit: `${product?.width} ${product?.unitName}`,
    realPercentageDiscount: String(product?.discount),
    rowNetTotal: Number(product?.rowNetTotal),
    rowNetTotalWithCurrency: `${product.rowNetTotal} ${currencySymbol}`,
    rowTotal: Number(product?.rowTotal),
    rowTotalWithCurrency: `${product.rowTotal} ${currencySymbol}`,
    rowVAT: Number(product?.rowVATTotal),
    rowVATWithCurrency: `${product.rowVATTotal} ${currencySymbol}`,
    supplier: product?.supplierName,
    supplierCode: String(product?.supplierCode),
    title: product?.name,
    unit: product?.unitName,
    usualDeliveryTime: String(product?.deliveryTime),
    vatRate: `${product?.vatRate} %`,
  };
}

export function mapRowForActualDataSet(
  product: Partial<Row & Product>,
  currencySymbol: string,
) {
  return {
    productID: String(product.productID),
    amount: String(product?.amount),
    amountAvailable: product?.free,
    amountInStock: product?.totalInStock,
    amountReserved: 0,
    brand: product?.brandName,
    category: product?.categoryName,
    code: product?.code,
    code2: product?.code2,
    code3: product?.code3,
    description: product?.description,
    discount: String(product?.discount),
    finalNetPrice: String(product?.finalPrice),
    finalNetPriceWithCurrency: `${product.finalPrice} ${currencySymbol}`,
    finalPriceWithVAT: String(product?.finalPriceWithVAT),
    finalPriceWithVATWithCurrency: `${product.finalPriceWithVAT} ${currencySymbol}`,
    grossWeight: String(product?.grossWeight),
    grossWeightWithUnits: `${product?.grossWeight} ${product?.unitName}`,
    imageURL1: product?.images?.[0],
    locationInWarehouse: product?.locationInWarehouse,
    longDescription: product?.longdesc,
    manufacturer: product?.manufacturerName,
    netWeight: String(product?.netWeight),
    netWeightWithUnits: `${product?.netWeight} ${product?.unitName}`,
    no: Number(product?.unitID),
    originalNetPrice: String(product?.basePriceNet),
    originalNetPriceWithCurrency: `${product.basePriceNet} ${currencySymbol}`,
    originalPriceWithVAT: Number(product?.basePriceWithVAT),
    originalPriceWithVATWithCurrency: `${product.basePriceWithVAT} ${currencySymbol}`,
    packages: product?.packages,
    productGrossWeight: String(product?.grossWeight),
    productGrossWeightWithUnit: `${product?.grossWeight} ${product?.unitName}`,
    productGroup: product?.groupName,
    productHeight: String(product?.height),
    productHeightWithUnit: `${product?.height} ${product?.unitName}`,
    productLength: String(product?.length),
    productLengthWithUnit: `${product?.length} ${product?.unitName}`,
    productName: product?.name,
    productNetWeight: String(product?.netWeight),
    productNetWeightWithUnit: `${product?.netWeight} ${product?.unitName}`,
    productVolume: String(product?.volume),
    productVolumeWithUnit: `${product?.volume} ${product?.unitName}`,
    productWidth: String(product?.width),
    productWidthWithUnit: `${product?.width} ${product?.unitName}`,
    realPercentageDiscount: String(product?.discount),
    rowNetTotal: String(product?.rowNetTotal),
    rowNetTotalWithCurrency: `${product.rowNetTotal} ${currencySymbol}`,
    rowTotal: String(product?.rowTotal),
    rowTotalWithCurrency: `${product.rowTotal} ${currencySymbol}`,
    rowVAT: String(product?.rowVATTotal),
    rowVATWithCurrency: `${product.rowVATTotal} ${currencySymbol}`,
    supplier: product?.supplierName,
    supplierCode: String(product?.supplierCode),
    title: product?.name,
    unit: product?.unitName,
    usualDeliveryTime: String(product?.deliveryTime),
    vatRate: `${product?.vatRate} %`,
  };
}

export const defaultTranslations = {
  company: {
    regNumber: 'Registry code',
    vat: 'VAT',
    phone: 'Phone',
    address: 'Address',
  },
  sale: {
    name: 'Name',
    client: 'Customer',
    saler: 'Salesperson',
    receiptNumber: 'Receipt number',
    documentNumber: 'Document number',
    invoiceBalance: 'Invoice balance',
    reprintNumber: 'Print attempt',
  },
  product: {
    description: 'Description',
    quantity: 'Qty',
    price: 'Price',
    sum: 'Sum',
    paid: 'Paid',
    discount: 'Discount',
    returnReason: 'Return reason',
    VAT: 'VAT',
    change: 'Change',
    originalPrice: 'Original price',
  },
  totals: {
    neto: 'Net total',
    total: 'Total',
    subtotal: 'Subtotal',
  },
  defaults: {
    footer: 'Thank you for the purchase!\nWelcome back!',
    kitchen: 'Kitchen',
    bar: 'Bar',
    notesTitle: 'Notes',
    totalDiscountDefaultValue: 'Total Discount',
    baseDocument: 'Base documents',
    storeCreditAdded: "Added to customer's store account",
    signature: 'Signature',
  },
  payments: {
    cardNumber: 'Card #',
    cardHolder: 'Cardholder name',
    referenceNumber: 'Ref. #',
    authorizationCode: 'Auth. code',
    cardType: 'Card type',
    paid: 'Amount paid',
  },
  storeCredit: {
    currentBalance: "Customer's current store credit balance",
    storeCreditEarned: 'Credits earned with purchase',
  },
  rewardPoints: {
    previousRewardPointsBalance: 'Previous reward points balance',
    rewardPointsEarned: 'Points earned with purchase',
    newRewardPointsBalance: 'New reward points balance',
  },
};

export function getIntegrationType(options: Options) {
  if (options?.giftReceipt) {
    return 'giftReceipt';
  }
  if (options?.order) {
    return 'order';
  }
  if (options?.offer) {
    return 'quote';
  }
  if (options?.kitchen) {
    return 'kitchen';
  }
  if (options?.bar) {
    return 'bar';
  }
  return 'salesReceipt';
}

export function getPatchScript(params: GetPatchScriptParams, options: Options) {
  return async (
    _dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    const { id: templateId } =
      getCafaEntry(
        getIntegrationType(options),
        INTEGRATION_TYPES.patchScriptTemplate,
      )(getState()) ?? {};

    if (!templateId) {
      throw new Error('Unable to find patchscript template id');
    }

    const patchscript = await getPatchScriptFromReceiptPrintoutApi(
      getSessionKey(getState()),
      getClientCode(getState()),
      { ...params, templateId },
    );

    return patchscript;
  };
}

export function getLocalPatchScript(
  salesDocument: SalesDocument,
  options: Options,
) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    const integrationType = getIntegrationType(options);

    const data = await dispatch(
      prepareReceiptData({ salesDocument, type: integrationType }),
    );

    const patchScriptTemplate = getTemplateForType(
      INTEGRATIONS[INTEGRATION_TYPES.patchScriptTemplate][integrationType],
    )(getState());

    const {
      forcePatchscriptRender: { enabled, pixelWidth, scaleMultiplier },
    } = getGeneralPrintingSettings(getState());

    const patchScript = await (enabled
      ? data =>
          renderPatchscript(data, pixelWidth, scaleMultiplier).then(wrapPngData)
      : data => Promise.resolve(data))(
      composeReceiptWithSchema(patchScriptTemplate, data),
    );

    return patchScript;
  };
}

export function getIsMSRunning(
  integrationName: string,
): ThunkAction<
  Promise<boolean>,
  RootState,
  unknown,
  AnyAction
> {
  return async dispatch => {
    try {
      const res = await installerStatusResponses$
        .pipe<InstallerStatus | Error>(take(1))
        .toPromise();
      if (res instanceof Error) {
        // Installer not running (f.ex. wrappers, but also unintegrated desktops)
        return false;
      }
      return (
        res?.data.integrations.find(int => int.name === integrationName)
          ?.status === 'StatusRunning'
      );
    } catch (error) {
      dispatch(
        addError(
          error instanceof Error
            ? error.message
            : 'Can not determine if MS is running',
        ),
      );
      return false;
    }
  };
}

export function getFinalPatchScript(
  salesDocument: SalesDocument,
  options: Options,
) {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    try {
      const isReceiptPrintoutAPIRunning = await dispatch(
        getIsMSRunning('receipt-printout-api'),
      );
      if (!isReceiptPrintoutAPIRunning) {
        // will proceed with flow that was before receipt printout api was introduced
        throw new Error('Receipt printout API MS not running');
      }

      const patchScript = await dispatch(
        getPatchScript(
          {
            salesDocumentId: Number(getSaleDocID(salesDocument)),
            printoutType: 'PATCH_SCRIPT',
          },
          options,
        ),
      );
      return patchScript;
    } catch (error) {
      const patchScript = await dispatch(
        getLocalPatchScript(salesDocument, options),
      );
      return patchScript;
    }
  };
}

export function getOfflinePatchScript(
  salesDocument: OfflineSalesDocument,
  options: Options = {},
) {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    try {
      const { documentActualReport, salesDocument: doc } = await dispatch(
        // @ts-ignore - fix type later
        prepareOfflineData({ salesDocument }),
      );
      const patchScript = await dispatch(
        getPatchScript(
          {
            documentActualReport,
            salesDocument: doc,
            printoutType: 'PATCH_SCRIPT',
          },
          options,
        ),
      );
      return patchScript;
    } catch (error) {
      const patchScript = await dispatch(
        getLocalPatchScript(salesDocument, options),
      );
      return patchScript;
    }
  };
}

export function getDocumentARDataset(salesDocument: SalesDocument) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    const isOnline = getConnectionHealth(getState());
    const taxComponentsEnabled = getIsModuleEnabled('subvatrates')(getState());

    const getParams = async () => {
      // means that doc was made offline, thats why theres no invoiceLink
      if (!salesDocument.invoiceLink) {
        const [doc] = await getSalesDocuments({
          number: (salesDocument as OfflineSalesDocument).number,
        });
        return {
          salesDocumentID: doc.id,
        };
      }
      return {
        salesDocumentID: getSaleDocID(salesDocument),
      };
    };

    if (isOnline) {
      const params = await getParams();
      const [doc] = await getSalesDocumentActualReportsDataset({
        ...params,
        getTaxComponents: taxComponentsEnabled ? 1 : undefined,
      });
      return doc;
    }
    const { documentActualReport } = await dispatch(
      prepareOfflineData({
        salesDocument: salesDocument as OfflineSalesDocument,
      }),
    );
    return documentActualReport;
  };
}
