import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Trans, useTranslation } from 'react-i18next';
import * as MUI from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import RandExp from 'randexp';
import { useMediatedState } from 'react-use';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import {
  getUseAgeVerification,
  getPosRoundCashFunction,
} from 'reducers/configs/settings';
import { SaveButton } from 'components/UIElements/UIButton';
import {
  float,
  integer,
  numberArray,
  percentage,
  positive,
} from 'components/FieldTypes/formatters';
import { commitTempSettings, dropTempSettings } from 'actions/configs';
import {
  Page,
  Section as BaseSection,
  collapseAllSections,
} from 'containers/Forms/Settings/components/Inputs';
import { miniUuid, snakeToCamel } from 'utils';
import { getClientCode } from 'reducers/Login';
import { useConfirmation } from 'components/Confirmation';
import resetDB from 'services/DB/resetDatabase';
import { SO } from 'services/DB/types';
import { modalPages } from 'constants/modalPage';
import { PaymentTypeInput } from 'components/inputs/paymentTypeInput';
import { PluginComponent } from 'plugins';

import * as CtxInputs from '../../components/CtxInputs';
import { useSettings } from '../../hooks/useSettings';

import './Configuration.scss';

const Check = ({ title, name, ...rest }) => {
  const { t } = useTranslation('settingsConfiguration');
  return (
    <CtxInputs.Check
      data-testid={name}
      {...rest}
      name={name}
      title={title || t(snakeToCamel(name))}
    />
  );
};

const LongText = ({ title, name, ...rest }) => {
  const { t } = useTranslation('settingsConfiguration');
  return (
    <CtxInputs.LongText
      data-testid={name}
      {...rest}
      name={name}
      title={title || t(snakeToCamel(name))}
    />
  );
};

const NumberInp = ({ name, title, ...rest }) => {
  const {
    values: { [name]: value },
    onChange,
  } = useContext(CtxInputs.Ctx);

  const [v, setV] = useState(String(value));
  const isValid = Number.isFinite(Number(v));
  useEffect(() => {
    setV(String(value));
  }, [value]);

  const { t } = useTranslation('settingsConfiguration');
  return (
    <CtxInputs.Text
      data-testid={name}
      {...rest}
      title={title || t(snakeToCamel(name))}
      name={name}
      value={v}
      invalid={!isValid}
      onChange={(k, v) => {
        setV(v);
        const newValue = Number(v);
        if (Number.isFinite(newValue) && newValue !== value) {
          onChange(name, newValue);
        }
      }}
    />
  );
};
const Text = ({ name, title, ...rest }) => {
  const { t } = useTranslation('settingsConfiguration');
  return (
    <CtxInputs.Text
      data-testid={name}
      {...rest}
      title={title || t(snakeToCamel(name))}
      name={name}
    />
  );
};

const Select = ({ name, title, ...rest }) => {
  const { t } = useTranslation('settingsConfiguration');
  return (
    <CtxInputs.Select
      data-testid={name}
      {...rest}
      name={name}
      title={title || t(snakeToCamel(name))}
    />
  );
};

const Section = ({ name, title, ...rest }) => {
  const { t } = useTranslation('settingsConfiguration');
  return <BaseSection data-testid={name} {...rest} title={title || t(name)} />;
};

export default () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsConfiguration');
  const { t: t2 } = useTranslation('common');
  const clientCode = useSelector(getClientCode);
  const useAgeVerification = useSelector(getUseAgeVerification);

  const {
    values,
    onChange,
    changed,
    getError,
    setError,
    removeError,
    allValid,
  } = useSettings();

  const onClose = () => {
    dispatch(dropTempSettings(Object.keys(changed)));
    dispatch(previousModalPage());
  };

  const onSave = () => {
    dispatch(commitTempSettings(Object.keys(changed))).then(onClose);
  };

  const confirm = useConfirmation();

  useEffect(() => {
    if (changed.touchpos_out_of_stock_warning === 'block') {
      confirm({
        title: t('alerts.outOfStockBlock.title'),
        body: t('alerts.outOfStockBlock.body'),
      }).catch(() => {
        onChange('touchpos_out_of_stock_warning', 'warning');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed.touchpos_out_of_stock_warning, confirm, t]);

  return (
    <div className="erply-settings__configuration">
      <Modal.Header>
        <span>{t('title')}</span>

        <span style={{ flexGrow: 1 }} />
        <SaveButton
          action={onSave}
          variant="POS"
          disabled={!Object.keys(changed).length || !allValid}
        />
        <CloseButton action={onClose} />
      </Modal.Header>
      {/* prettier-ignore */}
      <Modal.Body>
        <CtxInputs.Ctx.Provider
            value={useMemo(
              () => ({ values, onChange, getError, setError, removeError }),
              [values, onChange, getError, setError, removeError],
            )}
          >
          <MUI.Grid container>
            <MUI.Grid item xs/>
            <MUI.Button variant="text" onClick={collapseAllSections}>
              {t('settings:buttons.collapseAllSections')}
            </MUI.Button>
          </MUI.Grid>
          <Section title={t("section.userInterface")}>
            <Page
              title={t("buttons.userInterface")}
              value={modalPages.SettingsUserInterface}
            />
            {useAgeVerification && (
              <Page title={t('section.ageVerification')} value={modalPages.ageVerificationSettings} />
            )}
          </Section>

          <Section name="section.general">
            <Check name="touchpos_show_total_basket_quantity" />
            <Check name="pos_brazil_enabled_version_control" />
            <Check name="touchpos_disable_product_image_display" />
            <Check name="touchpos_disable_product_row_auto_open" />
            <Check name="touchpos_disable_related_products_popup" />
            <Check name="touchpos_disable_zero_price_auto_open" />
            <Check name="touchpos_disable_unused_coupons_popup" />
            {/* Commented out the following button cause the feature is currently not supported */}
            {/* <Check name="touchpos_filter_products_button" /> */}
            <Check name="detailed_pending_sales" />
            <Check name="detailed_layaways" />
            <Check name="touchpos_warehouse_select" />
            <Check name="touchpos_show_all_registers" />
            <Check name="brazil_disable_create_company" />
            <Check name="touchpos_display_code_during_scanning" />
            <Check name="touchpos_hide_employees_from_other_location" />
            <Check name="hide_offline_inventory_for_stock_lookup" />
            <div>
              <NumberInp
                name='pos_timeout'
                formatter={useCallback(
                  integer.and(positive), [integer, positive])}
              />
              <MUI.Collapse in={Boolean(values.pos_timeout)} appear={false}>
                <TimeoutOnInactivityConfiguration
                  value={values.pos_timeout_config}
                  setValue={v => onChange('pos_timeout_config', v)}
                />
              </MUI.Collapse>
            </div>
            <ProductGroupOrderBy
              orderByTitle={t("touchposOrderProductsByInProductGroup")}
              orderByOptions={useMemo(() => {
                const core = 'touchpos_order_products_by_in_product_group';
                return [
                  {
                    value: 'name',
                    name: t(snakeToCamel(`${core}_name`)),
                  },
                  {
                    value: 'code',
                    name: t(snakeToCamel(`${core}_code`)),
                  },
                  {
                    value: 'productID',
                    name: t(snakeToCamel(`${core}_productID`)),
                  },
                  {
                    value: 'price',
                    name: t(snakeToCamel(`${core}_price`)),
                  },
                  {
                    value: 'changed',
                    name: t(snakeToCamel(`${core}_changed`)),
                  },
                  {
                    value: 'added',
                    name: t(snakeToCamel(`${core}_added`)),
                  },
                ];
              }, [t])}
              orderDirectionTitle={t("touchposOrderProductsDirectionInProductGroup")}
              options={useMemo(() => ([
                {
                  value: 'asc',
                  name: t2('asc')
                },
                {
                  value: 'desc',
                  name: t2('desc')
                }
              ]), [t2])}
            />
            <Select
              name="pos_expand_product_button"
              title={t(snakeToCamel("pos_expand_product_button"))}
              options={useMemo(() => {
                const key = snakeToCamel("pos_expand_product_button");
                const values = ["disabled", "onHover", "onClick"];
                return values.map(opt => ({
                  name: t(key, { context: opt }),
                  value: opt
                }));
              }, [t])}
            />
             <Select
              name="pos_images_source"
              title={t(snakeToCamel("pos_images_source"))}
              options={useMemo(() => {
                const key = snakeToCamel("pos_images_source");
                const values = ["erply", "cdn", "both"];
                return values.map(opt => ({
                  name: t(key, { context: opt }),
                  value: opt
                }));
              }, [t])}
            />
            <Text
              name="touchpos_custom_discount_percentages"
              placeholder="10,25,50,100"
              formatter={numberArray}
            />
            <SerialNumberLength values={values}
              propName="serial_number_length"
              serialNameTitle="serial_name_title"
              comparisonOperator="serial_number_operator"
              options={[
                {
                  value: 'minLength',
                  name: t('comparison_minLength'),
                },
                {
                  value: 'equals',
                  name: t('comparison_equals'),
                },
                {
                  value: 'maxLength',
                  name: t('comparison_maxLength'),
                },
              ]}
            />
          </Section>

          <Section name="section.shoppingCart">
            <Check name="touchpos_always_item_in_new_row" />
            <Select
              disabled={values.touchpos_always_item_in_new_row}
              options={useMemo(() => ["consecutive", "any"]
                  .map(value => ({
                    value, name: t(
                      snakeToCamel("touchpos_group_items_in_row_by"),
                      { context: value }
                    )
                  }))
                , [t])}
              name="touchpos_group_items_in_row_by"
            />
            <Check name="touchpos_group_products_in_shopping_cart" />
            <Check name="touchpos_remove_grouped_products_in_shopping_cart" />
            <Check name="pos_focus_amount_for_last_item_in_shopping_cart" />
            <Check name="pos_brazil_allow_scanning_products_from_order_qty_field" />
            <Check name="touchpos_no_container_fee_on_return" />
            <Check name="pos_hide_price_list_from_discounts" />
            <Check name="pos_show_prices_in_cart_with_quantity" />
            <Check name="pos_cart_rows_to_bottom" />
            <Check name="pos_brazil_do_not_calculate_shopping_cart_on_change" />
            <Check name="pos_brazil_show_current_document_type_above_cart" />
            <Check name="allow_fractional_product_quantities" />
            <Check name="append_gift_card_number_to_product_name" />
            <Page
              title={t("buttons.quickNotes")}
              value={modalPages.QuickNotes}
            />
          </Section>

            <Section name="section.productSearch">
              <Check name="search_product_by_all_codes" />
              {Number(values.search_product_by_all_codes)
                ? <MUI.FormControl>
                  <Check name="touchpos_search_product_code_from_middle" disabled value={0} />
                  <MUI.FormHelperText>{t('touchposSearchProductCodeFromMiddle', { context: 'unsupported' })}</MUI.FormHelperText>
                </MUI.FormControl>
                : <Check name="touchpos_search_product_code_from_middle" />
              }
              <Select name="touchpos_sort_products_by" options={useMemo(() => {
                  const core = "touchpos_sort_products_by";
                  return [
                    {
                      value: 'name',
                      name: t(snakeToCamel(`${core}_name`)),
                    },
                    {
                      value: 'code',
                      name: t(snakeToCamel(`${core}_code`)),
                    },
                    {
                      value: 'productID',
                      name: t(snakeToCamel(`${core}_productID`)),
                    },
                    {
                      value: 'price',
                      name: t(snakeToCamel(`${core}_price`)),
                    },
                    {
                      value: 'changed',
                      name: t(snakeToCamel(`${core}_changed`)),
                    },
                    {
                      value: 'added',
                      name: t(snakeToCamel(`${core}_added`)),
                    },
                  ];
                }, [t])
              }/>
              <Check name="single_product_result_adds_to_shopping_cart" />
              <Check name="truncate_product_search_results_texts"/>
            </Section>

          <Section name="section.sale">
            <Check name="touchpos_autoidle_after_sale" />
            <Check name="touchpos_disable_selling_to_default_customer" />
            <Check name="update_document_creator_when_saving_pending_sales" />
            <Check name="copy_creator_to_linked_sales" />
            <Check name="update_document_date_when_saving_pending_sales" />
            <Check name="allow_exchange_products_on_return" />
            <Check name="brazil_allow_overcharge" />
            <Check name="allow_price_editing_for_exchanges_and_returns" />
            <Check name="touchpos_autoclose_line_after_discount_selected" />
            <Check name="touchpos_enable_edit_name_in_order" />
            <Check name="pos_allow_selling_only_from_pricelist1" />
            <Check name="touchpos_use_age_verification" />
            <Check name="touchpos_non_discountable_products" />
            <Check name="touchpos_sell_only_matrix_in_stock" />
            <Check name="touchpos_disable_product_added_notification" />
            <Check name="touchpos_choose_employee_manually" />
            <Check name="touchpos_allow_editing_existing_layaways" />
            <Check name="touchpos_close_to_minimum_stock_confirmation" />
            <NumberInp name="touchpos_default_layby_percent"
                  formatter={useCallback(integer.and(positive).and(percentage), [integer, positive, percentage])} />
            <LayawayCancellationConfiguration />
            <Select
              options={useMemo(() => ["", "warning", "confirmation", "block"]
                  .map(value => ({
                    value, name: t(
                      snakeToCamel("touchpos_out_of_stock_warning"),
                      { context: value || "none" }
                    )
                  }))
                , [t])}
              name="touchpos_out_of_stock_warning"
            />

            <Table>
              <thead>
              <tr>
                <th>{t("saleNotes.title")}</th>
                <th>{t("saleNotes.required")}</th>
              </tr>
              </thead>
              <tbody>
              {["1", "2", "3"].map(n => (
                <tr key={n}>
                  <td>
                    <Text
                      name={`sale_extra_note${n}`}
                      title=" "
                      onChange={(key, value) => {
                        !value && onChange(`sale_extra_note${n}_required`, 0)
                        onChange(key, value)
                      }} />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Check
                      size="lg"
                      name={`sale_extra_note${n}_required`}
                      disabled={!(values[`sale_extra_note${n}`] || values[`sale_extra_note${n}_required`])}
                    > </Check>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
            <Check name="pos_enable_special_handling_of_gift_returns" />
          </Section>

          <Section name="section.document">
            <Check name="touchpos_orders_only_from_current_location" />
            <Check name="touchpos_laybys_only_from_current_location" />
            <Check name="touchpos_offers_only_from_current_location" />
            <Check name="touchpos_unfinished_sales_only_from_current_location" />
            <Check name="touchpos_recent_sales_only_from_current_location" />
            <Check name="touchpos_invoices_only_from_current_location" />
            <ReturnDocumentsConfiguration />
          </Section>

          <Section name="section.receipt">
            <Page
              title={t("buttons.receiptTemplates")}
              value={modalPages.ReceiptTemplates}
            />
            <Check name="touchpos_close_after_print" />
            <Check name="hide_negative_discounts" />
            <Check name="show_notes_on_main_receipt" />
            <Check name="show_tax_rate_instead_of_name" />
            <Check name="touchpos_show_print_attempt_number" />
            <Check name="touchpos_show_product_code_on_receipt" />
            <Check name="touchpos_show_change_on_receipt" />
            <Check name="touchpos_show_discount_total" />
            <Check name="touchpos_show_company_reg_vat_number" />
            <Check name="show_change_on_confirmation" />
            <Check name="show_invoice_no_on_confirmation" />
            <Check name="show_original_price_on_main_receipt" />
            <Check name="touchpos_show_card_data_on_receipt" />
            <Check name="show_customer_store_credit_balance_on_receipt" />
            <SerialNumberConfiguration />
            <Text name="touchpos_set_printing_language_priority" />
            <LongText name="touchpos_default_send_receipt_to_email_addresses" />

          </Section>

          <Section name="section.payment">
            <Check name="touchpos_require_card_type_on_external_payment" />
            <Check name="touchpos_require_auth_code_on_payments" />
            <MUI.FormControl>
              <Check name="touchpos_enable_check_extra_fields" disabled={Number(values.capture_check_numbers) === 1} />
              <MUI.FormHelperText>{t('check.helperText')}</MUI.FormHelperText>
            </MUI.FormControl>
            <Check name="capture_check_numbers"/>
            <Check name="pos_brazil_show_expected_change_in_payment_modal" />
            <Rounding values={values} onChange={onChange} propName="pos_round_cash_to_nearest_x" roundingPropName="pos_round_algo"/>
            <Select name="invoice_rounding" options={
              ["-1", "0", "10", "100"].map(value => ({ value, name: t("invoiceRounding", { context: value }) }))
            } />
            <ExternalCardTypesConfiguration values={values}/>
          </Section>

          <Section name="section.giftcard">
            <Check name="pos_disable_generate_number_button" />
            <Check name="pos_use_serial_gift_card_payment_amount_input" />
            <GiftcardSerialPattern values={values} onChange={onChange} />
          </Section>

            <Section name="section.units">
              <Text
                name="units_for_prompt"
                placeholder="kg,g,lb"
                value={values.units_for_prompt.join(',')}
                onChange={(k,v) => onChange(k, v.split(","))}
              />
            </Section>

            <Section name="section.printing">
              <Check name="touchpos_print_kitchen_bar_receipts" />
              <Check name="touchpos_autoclose_checkout_final_confirmaton_popup" />
              {!Number(values.touchpos_autoclose_checkout_final_confirmaton_popup)
                ? <MUI.FormControl>
                  <Check name="touchpos_disable_default_print_the_receipt" disabled value={true} />
                  <MUI.FormHelperText>{t('touchposDisableDefaultPrintTheReceipt', { context: 'unsupported' })}</MUI.FormHelperText>
                </MUI.FormControl>
                : <Check name="touchpos_disable_default_print_the_receipt" />
              }
              <Check name="touchpos_print_cash_in_out_receipt" />
              <Check name="touchpos_print_layaway_two_times" />
            </Section>

          <Section name="section.cashdrawer">
            <Check name="cash_inout_open_drawer" />
            <Check name="touchpos_always_open_drawer_after_sale" />
          </Section>

          <Section name="section.startofday">
            <Check name="showConfirmationOnOpenDay" />
          </Section>

          <Section name="section.endofday">
            <Check name="touchpos_eod_reason_required" />
            {!Number(values.touchpos_eod_reason_required ?? 0)
              ? <MUI.FormControl fullWidth>
                <Text
                  name="touchpos_eod_allowed_difference"
                  formatter={useCallback(float.and(positive), [float, positive])}
                  disabled
                />
                <MUI.FormHelperText>{t('touchposEodAllowedDifference_noEffect')}</MUI.FormHelperText>
              </MUI.FormControl>
              : <Text
                name="touchpos_eod_allowed_difference"
                formatter={useCallback(float.and(positive), [float, positive])}
              />
            }
            <Check name="touchpos_eod_notes_required" />
            <Check name="touchpos_check_only_cash" />
            <Check name="touchpos_eod_disable_show_expected" />
            <Check name="touchpos_eod_disable_count_all_payment_types" />
            <Check name="touchpos_get_short_report" />
            <Check name="touchpos_get_report_only_last_shift" />
            <Check name="touchpos_disable_deposit_on_eod" />
            <Check name="day_startend_open_drawer" />
            <Check name="touchpos_remove_pending_sales_before_close_day" />
            <Check name="closing_day_notes_mandatory" />
            <Check name="touchpos_eod_fill_counted" />
          </Section>

          <Section name="section.localDatabaseSupport">
            <MUI.FormControl>
              <Check name="touchpos_allow_offline_mode" />
              <MUI.FormHelperText>{t("touchposAllowOfflineMode", { context: "sellingToDefaultCustomer" })}</MUI.FormHelperText>
            </MUI.FormControl>
            <Check name="brazil_enable_save_state" />
            <Check name="brazil_use_local_product_db" />
            <Check name="touchpos_fetch_only_products_in_stock" />
            <div>
              <Trans i18nKey="settingsConfiguration:localDatabases.description"><p /></Trans>
              <Button
                variant="outline-danger"
                onClick={useCallback(() => resetDB(clientCode, SO.PRODUCTS.NAME), [clientCode])}>
                {t("localDatabases.buttonResetProductsDB")}
              </Button>
            </div>
          </Section>

          <Section name="section.customization">
            <LongText
              placeholder="http://www.example.com/somepath/plugin.js"
              name="touchpos_javascript_plugin_path"
            />
            <Check name="touchpos_javascript_plugin_mandatory" />
            <LongText
              placeholder={t("placeholders.customCSS")}
              name="touchpos_custom_css"
            />
          </Section>
        </CtxInputs.Ctx.Provider>
      </Modal.Body>
    </div>
  );
};

const roundingOptions = [false, true].flatMap(whole =>
  Object.entries({
    inf: 'away-from-zero',
    '+inf': 'up',
    '-inf': 'down',
    '0': 'towards-zero',
    even: 'towards-even',
    odd: 'towards-odd',
  }).map(([target, name]) => ({
    value: `${whole},${target}`,
    transKey: `posRoundCashToNearest.algorithms.${whole ? '' : 'half-'}${name}`,
  })),
);

const Rounding = ({ values, onChange }) => {
  const prop = {
    count: 'pos_round_cash_to_nearest_x',
    algorithm: 'pos_round_algo',
  };
  /** The "example" value to test the rounding functionality with */
  const [testValue, setTestValue] = useMediatedState(
    (newValue, setState) => setState(prev => float(newValue, prev)),
    '12.34',
  );
  /** Local (string) state of the input field - whenever the input is valid, it is synced up to tempSettings */
  const [count, setCount] = useMediatedState((newValue, setState) => {
    setState(prev => {
      const v = integer.and(positive)(newValue.replace(/\D/, ''), prev);
      if (Number.isFinite(Number(v))) {
        onChange(prop.count, Number(v));
      }
      return v;
    });
  }, String(values[prop.count]));
  const countIsValid = Number.isFinite(Number(count)) && Number(count) > 1;
  const { t } = useTranslation('settingsConfiguration');
  const roundCash = useSelector(getPosRoundCashFunction);
  // Keep enabled when value is empty
  // otherwise if the user selects the field and backspaces to delete the previous value
  // they will be kicked out from entering the new one
  //
  const enabled = count === '' || 0 < Number(values[prop.count]);

  const [open, setOpen] = useState(false);
  return (
    <MUI.Box>
      {/* Master checkbox & expand button */}
      <MUI.Grid container>
        <MUI.Grid item>
          <Check
            name={prop.count}
            title={t('posRoundCashToNearest.title')}
            value={0 < Number(values[prop.count])}
            onChange={(k, v) => onChange(prop.count, v ? 5 : 0)}
          />
        </MUI.Grid>
        <MUI.Grid item xs />
        <MUI.Grid item>
          <MUI.IconButton onClick={() => setOpen(a => !a)}>
            <Settings />
          </MUI.IconButton>
        </MUI.Grid>
      </MUI.Grid>

      <MUI.Collapse in={open}>
        <MUI.Grid container spacing={2}>
          {/* Rounding amount and algo selection */}
          <MUI.Grid item xs={6}>
            <MUI.TextField
              fullWidth
              disabled={!enabled}
              label={t('posRoundCashToNearest.fields.count')}
              value={count}
              onChange={e => setCount(e.target.value)}
              error={!countIsValid}
              helperText={
                values[prop.count] === 1
                  ? t('posRoundCashToNearest.validation.countIs1')
                  : ''
              }
              variant="outlined"
            />
          </MUI.Grid>
          <MUI.Grid item xs={6}>
            <MUI.TextField
              fullWidth
              disabled={!enabled}
              label={t('posRoundCashToNearest.fields.algorithm')}
              select
              value={values[prop.algorithm]}
              onChange={e => onChange(prop.algorithm, e.target.value)}
              variant="outlined"
            >
              {roundingOptions.map(({ value, transKey }) => (
                <MUI.MenuItem key={value} value={value}>
                  {t(transKey)}
                </MUI.MenuItem>
              ))}
            </MUI.TextField>
          </MUI.Grid>

          <MUI.Grid item xs={12}>
            <h4>{t('posRoundCashToNearest.example.title')}</h4>
          </MUI.Grid>
          {/* Try-it-yourself calculator / example */}
          <MUI.Grid item xs={6}>
            <MUI.TextField
              fullWidth
              disabled={!enabled}
              label={t('posRoundCashToNearest.example.fields.price')}
              value={testValue}
              onChange={e => setTestValue(e.target.value)}
              variant="outlined"
            />
          </MUI.Grid>
          <MUI.Grid item xs={6}>
            <MUI.TextField
              fullWidth
              label={t('posRoundCashToNearest.example.fields.result')}
              value={roundCash(Number(testValue)).toFixed(2)}
              disabled
              variant="outlined"
            />
          </MUI.Grid>
        </MUI.Grid>
        <p>{t('posRoundCashToNearest.example.subtitle')}</p>
      </MUI.Collapse>
    </MUI.Box>
  );
};
const ReturnDocumentsConfiguration = () => {
  const { t } = useTranslation('settingsConfiguration', {
    keyPrefix: 'returnDocumentTypeConfigList',
  });
  const { t: t2 } = useTranslation('return', {
    keyPrefix: 'documentTypes',
  });
  const DOCUMENT_TYPE_OPTIONS = [
    'CASHINVOICE',
    'INVWAYBILL',
    'PREPAYMENT',
    'ORDER',
    'INVOICE',
  ];
  const { values, onChange } = useContext(CtxInputs.Ctx);
  const configuredValues = R.uniq([
    'CASHINVOICE',
    ...(values.allowed_types_on_return || []),
  ]);

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const handleChange = e => {
    const { name, checked: newVal } = e.target;

    const newConfValues = [...configuredValues];
    if (newVal) {
      newConfValues.push(name);
    } else {
      newConfValues.splice(newConfValues.indexOf(name), 1);
    }
    onChange('allowed_types_on_return', newConfValues);
  };

  const [open, setOpen] = useState(false);
  return (
    <MUI.Box>
      <MUI.Grid container alignItems="center">
        <MUI.Grid item xs>
          <MUI.Typography type="h4">{t('title')}</MUI.Typography>
        </MUI.Grid>
        <MUI.Grid item>
          <MUI.IconButton onClick={() => setOpen(a => !a)}>
            <Settings />
          </MUI.IconButton>
        </MUI.Grid>
      </MUI.Grid>
      <MUI.Collapse in={open} key="return_document_type_config_list">
        {DOCUMENT_TYPE_OPTIONS.map(opt => (
          <MUI.FormControlLabel
            label={t2('documentType', { context: opt })}
            key={`label-${opt}`}
            control={
              <MUI.Checkbox
                // should be configured to always be enabled
                disabled={opt === 'CASHINVOICE'}
                name={opt}
                key={`checkbox-${opt}`}
                checked={configuredValues.includes(opt)}
                onChange={handleChange}
              />
            }
          />
        ))}
      </MUI.Collapse>
    </MUI.Box>
  );
};

const cancellationFeeTypeSelection = ['total', 'paid', 'unpaid'];

const LayawayCancellationConfiguration = () => {
  const { t } = useTranslation('settingsConfiguration', {
    keyPrefix: 'layawayCancellationFee',
  });
  const { values, onChange } = useContext(CtxInputs.Ctx);

  const feeType = values.layaway_cancellation_fee_type;
  const feePrefillPercentage = values.layaway_cancellation_fee_percentage;
  const feePaymentType = values.layaway_cancellation_fee_payment_type;

  const keysObj = {
    percentage: 'layaway_cancellation_fee_percentage',
    type: 'layaway_cancellation_fee_type',
    pmtType: 'layaway_cancellation_fee_payment_type',
  };

  return (
    <PluginComponent hookname="UILayawayCancellationConfig">
      <MUI.Box>
        <MUI.Typography variant="h5">{t('title')}</MUI.Typography>
        <MUI.Box display="flex" flexWrap="wrap" alignItems="center">
          {t('prefillFirst')}
          <MUI.TextField
            type="number"
            size="small"
            key="layaway-cancellation-config-percentage"
            variant="outlined"
            style={{ width: '7rem', margin: '0 8px' }}
            value={feePrefillPercentage}
            onChange={e => onChange(keysObj.percentage, Number(e.target.value))}
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
                'data-testid': 'layaway-cancellation-config-percentage',
              },
              endAdornment: (
                <MUI.InputAdornment position="end">%</MUI.InputAdornment>
              ),
            }}
          />
          {t('prefillSecond')}
          <MUI.TextField
            select
            key="layaway-cancellation-config-type"
            size="small"
            variant="outlined"
            style={{ margin: '0 8px' }}
            value={feeType}
            onChange={e => onChange(keysObj.type, e.target.value)}
            InputProps={{
              inputProps: {
                'data-testid': 'layaway-cancellation-config-type',
              },
            }}
          >
            {cancellationFeeTypeSelection.map(type => (
              <MUI.MenuItem value={type}>{t(type)}</MUI.MenuItem>
            ))}
          </MUI.TextField>
          {t('prefillThird')}
          <MUI.Box marginLeft="8px">
            <PaymentTypeInput
              size="small"
              key="layaway-cancellation-config-payment-type"
              variant="outlined"
              value={feePaymentType ?? '-1'}
              onChange={e => onChange(keysObj.pmtType, e.target.value)}
              onlyCustom
              InputProps={{
                inputProps: {
                  'data-testid': 'layaway-cancellation-config-payment-type',
                },
              }}
            />
          </MUI.Box>
        </MUI.Box>
      </MUI.Box>
    </PluginComponent>
  );
};

const TEXT_AROUND_SN_FIELD = 'text_around_serial_number';
const DEFAULT_TEXT_AROUND_SN_FIELD = '(,)';

const SerialNumberConfiguration = () => {
  const { t } = useTranslation('settingsConfiguration');

  const { values, onChange, setError, removeError, getError } = useContext(
    CtxInputs.Ctx,
  );
  const errorText = getError(TEXT_AROUND_SN_FIELD);

  const textAroundSerialNumber = String(values[TEXT_AROUND_SN_FIELD] ?? '');
  const [first = '', second = ''] = textAroundSerialNumber.split(',');

  const handleChange = (fieldName, newValue) => {
    onChange(fieldName, newValue);

    // Empty string or 2 symbols separated by a comma
    const regex = /^(.{1},.{1})?$/;
    if (regex.test(newValue)) {
      removeError(TEXT_AROUND_SN_FIELD);
    } else {
      setError(TEXT_AROUND_SN_FIELD, t('textAroundSerialNumberError'));
    }
  };

  return (
    <>
      <Text
        name={TEXT_AROUND_SN_FIELD}
        onChange={handleChange}
        placeholder={t('textAroundSerialNumberPlaceholder')}
        errorText={errorText}
      />
      {errorText ? (
        <MUI.Typography color="error" style={{ marginTop: '0.6rem' }}>
          {t('textAroundSerialNumberInvalid', {
            defaultValue: DEFAULT_TEXT_AROUND_SN_FIELD,
          })}
        </MUI.Typography>
      ) : null}
      <MUI.Typography style={{ fontWeight: 'bold' }}>
        {t('textAroundSerialNumberError', { first, second })}
      </MUI.Typography>
    </>
  );
};

const ExternalCardTypesConfiguration = ({ values = {} }) => {
  const conf = values.pos_external_extra_card_payment_types ?? '';
  const hasInvalidConf = conf
    .toLowerCase()
    .split(',')
    .some(el => el.trim() === 'card');
  const { t } = useTranslation('settingsConfiguration');
  return (
    <>
      <Text name="pos_external_extra_card_payment_types" />
      {hasInvalidConf ? (
        <MUI.Typography color="error" style={{ fontWeight: 'bold' }}>
          {/* TODO: change to use { context: 'note' } once key is translated.
          Not done via context since at the moment of adding this note, it is missing translations and in case non-dev would fallback to main key's (posExternalExtraCardPaymentTypes) translation */}
          {t('posExternalExtraCardPaymentTypesNote')}
        </MUI.Typography>
      ) : null}
    </>
  );
};

const ProductGroupOrderBy = ({
  orderByTitle,
  orderByOptions,
  orderDirectionTitle,
  options,
}) => {
  return (
    <MUI.Grid container style={{ flexWrap: 'nowrap', alignItems: 'flex-end' }}>
      <MUI.Grid item xs={7}>
        <Select
          name="touchpos_order_products_by_in_product_group"
          title={orderByTitle}
          options={orderByOptions}
        />
      </MUI.Grid>
      <MUI.Grid item xs={5}>
        <Select
          name="touchpos_order_products_direction_in_product_group"
          title={orderDirectionTitle}
          options={options}
        />
      </MUI.Grid>
    </MUI.Grid>
  );
};

const SerialNumberLength = ({
  values,
  propName,
  comparisonOperator,
  serialNameTitle,
  options,
}) => {
  const { onChange: ctxChange } = useContext(CtxInputs.Ctx);
  // When toggling the Serial Number prompt, we need to save a numeric value to the `length` field, thus the Number conversion
  // Other fields use context in themselves, thus they don't receive separate onChanges
  const onSerialNumberToggle = (key, value) => {
    ctxChange(key, Number(value));
  };
  return (
    <MUI.Grid container style={{ flexWrap: 'nowrap', alignItems: 'flex-end' }}>
      <MUI.Grid item>
        <Check
          name={propName}
          title=" "
          value={values[propName]}
          onChange={onSerialNumberToggle}
        />
      </MUI.Grid>

      <MUI.Grid item style={{ flexGrow: 1 }}>
        <Text name={serialNameTitle} />
      </MUI.Grid>

      <MUI.Grid item style={{ flexGrow: 1 }}>
        <NumberInp name={propName} formatter={integer.and(positive)} />
      </MUI.Grid>

      <MUI.Grid item>
        <Select
          name={comparisonOperator}
          disabled={!Number(values[propName])}
          options={options}
        />
      </MUI.Grid>
    </MUI.Grid>
  );
};

const TimeoutOnInactivityConfiguration = ({ value, setValue }) => {
  const { t } = useTranslation('settingsConfiguration');

  const CheckFor = useCallback(
    ({ name, trans }) => {
      const checked = !!R.prop(name, value);
      return (
        <MUI.FormControlLabel
          label={t(`posTimeoutSettings.${trans}`, { context: name })}
          control={
            <MUI.Checkbox
              checked={checked}
              onChange={e => setValue(R.assoc(name, e.target.checked, value))}
            />
          }
        />
      );
    },
    [value, setValue, t],
  );

  return (
    <MUI.Box marginLeft={2}>
      <h3>{t('posTimeoutSettings.resetOnTitle')}:</h3>
      <MUI.FormGroup row>
        <CheckFor trans="resetOn" name="inputs" />
        <CheckFor trans="resetOn" name="mousemove" />
      </MUI.FormGroup>
      <h3>{t('posTimeoutSettings.resetWhileTitle')}:</h3>
      <MUI.FormGroup row>
        <MUI.FormControlLabel
          label={t('posTimeoutSettings.resetWhile', { context: 'settings' })}
          control={<MUI.Checkbox checked={true} disabled />}
        />
        <CheckFor trans="resetWhile" name="openCloseDay" />
        <CheckFor trans="resetWhile" name="stockLevels" />
        <CheckFor trans="resetWhile" name="zReport" />
        <CheckFor trans="resetWhile" name="browsingSales" />
        <CheckFor trans="resetWhile" name="customerSelected" />
        <CheckFor trans="resetWhile" name="creatingCustomer" />
        <CheckFor trans="resetWhile" name="productsInCart" />
      </MUI.FormGroup>
      <h3>{t('posTimeoutSettings.action')}</h3>
      <MUI.RadioGroup
        value={value.action ?? 'softLogout'}
        onChange={e => setValue(R.assoc('action', e.target.value, value))}
      >
        <MUI.FormControlLabel
          value="softLogout"
          control={<MUI.Radio />}
          label={t('posTimeoutSettings.action', { context: 'softLogout' })}
        />
        <MUI.FormControlLabel
          value="hardLogout"
          control={<MUI.Radio />}
          label={t('posTimeoutSettings.action', { context: 'hardLogout' })}
        />
      </MUI.RadioGroup>
    </MUI.Box>
  );
};

const GiftcardSerialPattern = ({ values }) => {
  const value = values.pos_serial_giftcard_pattern;
  const { t } = useTranslation('settingsConfiguration');
  const examples = useMemo(() => {
    try {
      if (!value) return null;
      const rand = new RandExp(
        `${value}` ??
          /^[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/,
      );
      return Array(10)
        .fill(0)
        .map(() => rand.gen()?.substring(0, 49));
    } catch (e) {
      return null;
    }
  }, [value]);
  return (
    <>
      <Text
        name="pos_serial_giftcard_pattern"
        placeholder={t('posSerialGiftcardPattern', {
          context: 'placeholder',
        })}
      />
      <MUI.FormControl>
        <MUI.FormHelperText style={{ whiteSpace: 'pre-line' }}>
          {t('posSerialGiftcardPattern', {
            context: 'regex',
          })}
        </MUI.FormHelperText>
      </MUI.FormControl>
      {examples && (
        <div>
          {t('posSerialGiftcardPattern', { context: 'examples' })}
          <ul>
            {examples.map(ex => {
              const key = `pos_serial_giftcard_pattern_example${miniUuid()}`;
              return <li key={key}>{ex}</li>;
            })}
          </ul>
        </div>
      )}
    </>
  );
};
