import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';

import UIButton from 'components/UIElements/UIButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

const ExpiredTIN = ({ resolve, expirationTime, isValidFormat }) => {
  const dispatch = useDispatch();
  const onOK = () => {
    resolve();
    dispatch(previousModalPage());
  };

  useShortcut('Enter', onOK, 100);
  useShortcut('Escape', onOK, 100);
  const warningText = isValidFormat
    ? `This customer’s tax exemption status expired on ${expirationTime}.`
    : `Invalid expiration date format.`;

  return (
    <div data-testid="pnp-tax-exemption">
      <Modal.Header>
        <h3 data-testid="title" className="m-auto">
          Tax Exemption Status Has Expired
        </h3>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col data-testid="text" className="text-center m-2">
            {warningText}
          </Col>
        </Row>
        <Row>
          <Col className="text-center d-flex m-2 justify-content-center">
            <UIButton
              style={{
                flexGrow: 1,
                maxWidth: '160px',
                height: '70px',
                fontSize: '24px',
                fontWeight: 500,
              }}
              data-testid="confirm"
              action={onOK}
              variant="success"
              text="OK"
            />
          </Col>
        </Row>
      </Modal.Body>
    </div>
  );
};

export default ExpiredTIN;
