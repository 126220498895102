/* eslint-disable max-classes-per-file */
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { ActionLifecycleHooks } from 'plugins/plugin';
import { notUndefinedOrNull } from 'utils';

export const combineLifecycleHooks = <P, AP, O>(
  ...hooks: ActionLifecycleHooks<P, AP, O>[]
): ActionLifecycleHooks<P, AP, O> => ({
  before: p => async (dispatch: ThunkDispatch<any, any, Action>) =>
    hooks
      .map(h => h.before)
      .filter(notUndefinedOrNull)
      .reduce(
        (prev, curr) => prev.then(() => dispatch(curr(p))),
        Promise.resolve(),
      ),
  on: (p, ap) => async (dispatch: ThunkDispatch<any, any, Action>) =>
    hooks
      .map(h => h.on)
      .filter(notUndefinedOrNull)
      .reduce(
        (prev, curr) => prev.then(ap => dispatch(curr(p, ap))),
        Promise.resolve(ap),
      ),
  after: (p, o) => async (dispatch: ThunkDispatch<any, any, Action>) =>
    hooks
      .map(h => h.after)
      .filter(notUndefinedOrNull)
      .reduce(
        (prev, curr) => prev.then(() => dispatch(curr(p, o))),
        Promise.resolve(),
      ),
});

/**
 * Error that includes a message intended to be displayed to the user.
 *
 * Currently the only use case is to show an error message on the modal when a sale document fails to save
 */
export class PluginError extends Error {
  messageForUI?: string;

  constructor(message: string, messageForUI?: string) {
    super(message);
    this.messageForUI = messageForUI;
  }
}

/**
 * Made to override actions from plugin hook.
 * Plugin hook runs own logic instead of action, then throws this error with return data
 * The error is then caught by the action and data is returned from action.
 *
 * NB! This is not the best solution and is currently used in four places
 * If overriding actions becomes a common need consider implementing a better solutions,
 * see {@link https://gl.nimi24.com/pos-refacto/pos-refactoring/-/merge_requests/2297#note_49383 Mart's proposed solutions}
 */
export class CancelActionOverrideError extends Error {
  data: unknown;

  constructor(message: string, data: unknown) {
    super(message);
    this.data = data;
  }
}
