import React from 'react';
import { useTranslation } from 'react-i18next';

import { WrapperForm } from 'components/WrapperForm';

export const WrapperSettings = ({ value, onChange }) => {
  const { t } = useTranslation('settingsPrinter');
  return (
    <div>
      <h3>{t('wrapper.title')}</h3>
      {/* <TestReceiptButton /> */}
      <WrapperForm
        value={value}
        onChange={(k, v) => onChange({ ...value, [k]: v })}
        getMessage="config:getPrintingFields"
        setMessage="config:setPrintingFields"
        updateMessage="config:setPrintingConfig"
      />
    </div>
  );
};
