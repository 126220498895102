import { getErrorMessage } from 'paymentIntegrations';

import swedbankRequest from './index';

const swedbankVoid = async (
  cardPayment,
  { currencyCode },
  { updateMessage },
  numberOfPayments,
  index,
  clientCode,
) => {
  updateMessage(
    `Cancelling payment for ${cardPayment.amount}...
    Transaction ${index + 1} / ${numberOfPayments}`,
  );

  const RefNo = cardPayment.original
    ? cardPayment.original.attributes.refNo
    : cardPayment.attributes.refNo;
  const point = RefNo.indexOf('.');
  const comma = RefNo.indexOf(',');
  const refNo = RefNo.slice(point + 1, comma);

  const data = {
    requestID: cardPayment.uuid,
    transactionType: 'VOID',
    amount: Math.abs(cardPayment.amount).toString(),
    currency: cardPayment.currencyCode || currencyCode,
    referenceNumber: refNo,
    clientCode,
  };

  try {
    const voidTransaction = await swedbankRequest.post('payment', data);

    return voidTransaction;
  } catch (error) {
    updateMessage(
      getErrorMessage(
        error,
        'There was an error while performing the cancellation. Please try again',
      ),
    );
  }
  return false;
};

export default swedbankVoid;
