import { IssuedCoupon, VerifiedCoupon } from 'types/Coupon';
import { GiftCard } from 'types/GiftCard';

import { doClientRequest } from './core/ErplyAPI';
import {
  GetCouponsParams,
  GetCouponsResponse,
  GetIssuedCouponsParams,
} from './core/types';

const getCampaigns = overrides =>
  doClientRequest({
    request: 'getCampaigns',
    activeToday: 1,
    recordsOnPage: 100,
    ...overrides,
  });

export const getManualPromotions = warehouseID =>
  getCampaigns({ type: 'manual', warehouseID });

export const getCouponCampaigns = warehouseID =>
  getCampaigns({ type: 'coupon', warehouseID });

export const verifyCoupon = id =>
  doClientRequest<VerifiedCoupon>({
    request: 'verifyIssuedCoupon',
    uniqueIdentifier: id,
  });

export const getCoupons = (params: GetCouponsParams) =>
  doClientRequest<GetCouponsResponse>({
    request: 'getCoupons',
    ...params,
  });

export const saveIssuedCoupon = params =>
  doClientRequest({
    request: 'saveIssuedCoupon',
    ...params,
  });

export const getIssuedCoupons = (params: GetIssuedCouponsParams) =>
  doClientRequest<IssuedCoupon>({
    request: 'getIssuedCoupons',
    ...params,
  });

export const getGiftCard = params =>
  doClientRequest<GiftCard>({
    request: 'getGiftCards',
    ...params,
  });
