import { clientCode, password, username } from 'constants/manuals';
import { urlEncode } from 'utils';
import { proxy } from 'services/shared';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchProducts = async () => {
  const sessionKey = await window
    .fetch(`${proxy}https://${clientCode}.erply.com/api/`, {
      method: 'POST',
      body: urlEncode({
        request: 'verifyUser',
        username,
        clientCode,
        password,
        sessionLength: 86400
      }),
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })
    .then(response => response.json())
    .then(response => {
      if (response.status.errorCode) {
        throw new Error(response.status.message);
      } else {
        // eslint-disable-next-line prefer-destructuring
        return response.records[0].sessionKey;
      }
    });

  return window
    .fetch(`${proxy}https://${clientCode}.erply.com/api/`, {
      method: 'POST',
      body: urlEncode({
        request: 'getProducts',
        clientCode,
        sessionKey,
        recordsOnPage: 1000,
      }),
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    })
    .then(response => response.json())
    .then(response => {
      if (response.status.errorCode) {
        throw new Error(response.status.message);
      } else {
        return response.records;
      }
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error('Failed to load manual pages', e);
      return [];
    });
};

export default fetchProducts;
