import axios from 'axios';

import { PaymentObj } from 'paymentIntegrations/types';

import { IntegrationData, TenderType } from '../types';

const tsysCanadaVoid = async (
  cardPayment: PaymentObj,
  { terminalID, xWebID, authKey }: IntegrationData,
  returnType: TenderType,
): Promise<string> => {
  const payloadString = `<REQUEST><TRANSACTIONTYPE>${
    returnType === 'CREDIT' ? 'CREDITVOID' : 'DEBITVOID'
  }</TRANSACTIONTYPE><TRANSACTIONID>${
    cardPayment.attributes?.refNo
  }</TRANSACTIONID><XWEBID>${xWebID}</XWEBID><XWEBTERMINALID>${terminalID}</XWEBTERMINALID><XWEBAUTHKEY>${authKey}</XWEBAUTHKEY></REQUEST>`;

  const baseURL =
    'https://localsystem.paygateway.com:21113/RcmService.svc/Initialize?callback=jsonpResponse&xl2Parameters=';
  const payment = await axios.get(`${baseURL}${payloadString}`, {
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  });

  if (payment && payment.data) {
    return payment.data;
  }

  throw new Error('TSYS void response contains no void data');
};

export default tsysCanadaVoid;
