import { getTransferRowLinks, saveTransferRowLinks } from 'services/SalesAPI';
import {
  getInventoryTransfers,
  getSalesDocuments,
  saveInventoryTransfer,
} from 'services/ErplyAPI';

/**
 * APIs relevant for the Nohble customizations plugin
 */
export const API = {
  /**
   * Mark the inventory transfer with the given ID as confirmed
   * @see https://learn-api.erply.com/requests/saveinventorytransfer
   */
  confirmInventoryTransfer: (inventoryTransferID: number) =>
    saveInventoryTransfer({ inventoryTransferID, confirmed: 1 }),

  /**
   * Fetch all unconfirmed inventory transfers TO the given warehouse
   * @see https://learn-api.erply.com/requests/getinventorytransfers
   */
  getUnconfirmedInventoryTransfers: (warehouseToID: number) =>
    getInventoryTransfers({ warehouseToID, confirmed: 0, type: 'TRANSFER' }),

  /**
   * Fetch specific inventory transfers based on a list of IDs
   * @see https://learn-api.erply.com/requests/getinventorytransfers
   */
  getInventoryTransfers: (inventoryTransferIDs: number[]) =>
    getInventoryTransfers({ inventoryTransferIDs }),

  /**
   * Save an inventory transfer
   * @see https://learn-api.erply.com/requests/saveinventorytransfer
   */
  saveInventoryTransfer: (
    params: Parameters<typeof saveInventoryTransfer>[0],
  ) => saveInventoryTransfer(params).then(res => res[0]),

  /**
   * Fetch multiple sales documents based on a list of IDs.
   * @see https://learn-api.erply.com/requests/getsalesdocuments
   */
  getDocumentNumbersAndRows: (
    documentIDs: number[],
  ): Promise<
    {
      id: number | string;
      number: string;
      rows: {
        amount: number;
        productID: number;
        stableRowID: string | number;
      }[];
    }[]
  > =>
    getSalesDocuments({
      getFields: 'id,number,rows',
      getRowsForAllInvoices: 1,
      ids: documentIDs.join(','),
    }),

  /**
   * Given the ID of an Inventory Transfer document, get all of the links from that document
   * A link is a relation between rows of different documents.
   * A link on a transfer connects a row on the transfer to the row on a sale that the transfer is meant to compensate
   * @see https://api-sales-eu10.erply.com/documentation/index.html#/Sales%20Document%20Row%20Links/get_v1_sales_documents_rows_transfer_row_links
   */
  getLinks: getTransferRowLinks,

  /**
   * Create new inventory transfer links
   * A link is a relation between rows of different documents.
   * A link on a transfer connects a row on the transfer to the row on a sale that the transfer is meant to compensate
   * @see https://api-sales-eu10.erply.com/documentation/index.html#/Sales%20Document%20Row%20Links/get_v1_sales_documents_rows_transfer_row_links
   */
  saveLinks: (links: Parameters<typeof saveTransferRowLinks>[0]['items']) =>
    saveTransferRowLinks({ items: links }),
};
