/* eslint-disable consistent-return,@typescript-eslint/camelcase */
/* eslint-disable camelcase */

import { createSelector } from 'reselect';
import * as R from 'ramda';
import dayjs from 'dayjs';

import { TYPE_LOGOUT } from 'constants/Login';
import { REDUX_SETTINGS } from 'constants/persistence';
import {
  getActiveAllowedTendersConfig,
  getCafaEntry,
} from 'reducers/cafaConfigs';
import { getClientCode, getUserLoggedIn, getUserRights } from 'reducers/Login';
import { ErplyAttributes } from 'utils/index';
import * as c from 'constants/configs';
import { getProductGroupByID } from 'reducers/productGroupsDB';
import { createOverrideSelector } from 'reducers/Plugins';
import { SO } from 'services/DB/types';
import * as types from 'constants/CAFA';
import { getConnectionHealth } from 'reducers/connectivity/connection';

import { getProductByID } from '../cachedItems/products';
import { applyRounding } from '../../utils/rounding/rounding';
import { getTotal } from '../Payments';

import { getCurrency, getCurrencyDenominations } from './currency';
import { defaultSettings, Settings } from './defaultSettings';

export const settingsState = {
  ...defaultSettings, // default settings
  touchpos_out_of_stock_warning: '',
  pos_languages: [
    {
      name: 'Arabic',
      nativeName: 'العَرَبِيَّة',
      shortDisplayName: 'العَرَبِيَّة',
      legacyIdentifier: 'lit',
      isoCode: 'ar',
      code: 'ar',
      countries: ['AE', 'SA'],
      isOverride: true,
    },
    {
      name: 'Czech',
      nativeName: 'Čeština',
      shortDisplayName: 'CS',
      legacyIdentifier: 'est',
      isoCode: 'cs',
      code: 'cs',
      countries: ['CZ'],
      isOverride: true,
    },
    {
      name: 'Danish',
      nativeName: 'Dansk',
      shortDisplayName: 'DA',
      legacyIdentifier: 'spa',
      isoCode: 'da',
      code: 'da',
      countries: ['DK', 'FO'],
      isOverride: true,
    },
    {
      name: 'Dutch',
      nativeName: 'Nederlands',
      shortDisplayName: 'NL',
      legacyIdentifier: 'gre',
      isoCode: 'nl',
      code: 'nl',
      countries: ['NL', 'BE'],
      isOverride: true,
    },
    {
      name: 'English',
      nativeName: 'English',
      shortDisplayName: 'ENG',
      legacyIdentifier: 'eng',
      isoCode: 'en',
      countries: [],
      isOverride: false,
    },
    {
      name: 'English (Australia)',
      nativeName: 'English',
      shortDisplayName: 'ENG',
      legacyIdentifier: 'eng',
      isoCode: 'en',
      code: 'en-AU',
      countries: ['AU'],
      isOverride: false,
    },
    {
      name: 'Estonian',
      nativeName: 'Eesti keel',
      shortDisplayName: 'EST',
      legacyIdentifier: 'est',
      isoCode: 'et',
      countries: [],
      isOverride: false,
    },
    {
      name: 'French',
      nativeName: 'Français',
      shortDisplayName: 'FRE',
      legacyIdentifier: 'fre',
      isoCode: 'fr',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Faroese',
      nativeName: 'Føroyskt',
      shortDisplayName: 'FO',
      legacyIdentifier: 'lit',
      isoCode: 'fo',
      countries: ['DK', 'FO'],
      isOverride: true,
    },
    {
      name: 'Finnish',
      nativeName: 'Suomen kieli',
      shortDisplayName: 'FIN',
      legacyIdentifier: 'fin',
      isoCode: 'fi',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Spanish',
      nativeName: 'Español',
      shortDisplayName: 'SPA',
      legacyIdentifier: 'spa',
      isoCode: 'es',
      countries: [],
      isOverride: false,
    },
    {
      name: 'German',
      nativeName: 'Deutsch',
      shortDisplayName: 'GER',
      legacyIdentifier: 'ger',
      isoCode: 'de',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Russian',
      nativeName: 'Русский язык',
      shortDisplayName: 'RUS',
      legacyIdentifier: 'rus',
      isoCode: 'ru',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Swedish',
      nativeName: 'Svenska',
      shortDisplayName: 'SWE',
      legacyIdentifier: 'swe',
      isoCode: 'sv',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Latvian',
      nativeName: 'Latviešu',
      shortDisplayName: 'LAT',
      legacyIdentifier: 'lat',
      isoCode: 'lv',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Lithuanian',
      nativeName: 'Lietuvių kalba',
      shortDisplayName: 'LIT',
      legacyIdentifier: 'lit',
      isoCode: 'lt',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Greek',
      nativeName: 'Ελληνικά',
      shortDisplayName: 'GRE',
      legacyIdentifier: 'gre',
      isoCode: 'el',
      countries: [],
      isOverride: false,
    },
    {
      name: 'Italian',
      nativeName: 'Italiano',
      shortDisplayName: 'IT',
      legacyIdentifier: 'lat',
      isoCode: 'it',
      countries: ['IT'],
      isOverride: true,
    },
    {
      name: 'Polish',
      nativeName: 'Polski',
      shortDisplayName: 'PL',
      legacyIdentifier: 'gre',
      isoCode: 'pl',
      countries: ['PL'],
      isOverride: true,
    },
    {
      name: 'Thai',
      nativeName: 'ภาษาไทย',
      shortDisplayName: 'TH',
      legacyIdentifier: 'fin',
      isoCode: 'th',
      countries: ['TH'],
      isOverride: true,
    },
    {
      name: 'Turkish',
      nativeName: 'Türkçe',
      shortDisplayName: 'TR',
      legacyIdentifier: 'lit',
      isoCode: 'tr',
      countries: ['TR'],
      isOverride: true,
    },
    {
      name: 'Vietnamese',
      nativeName: 'Tiếng Việt',
      shortDisplayName: 'VN',
      legacyIdentifier: 'lat',
      isoCode: 'vi',
      countries: ['VN'],
      isOverride: true,
    },
    {
      name: 'Norwegian',
      nativeName: 'Norsk',
      shortDisplayName: 'NO',
      legacyIdentifier: 'gre',
      isoCode: 'no',
      countries: ['NO', 'SE', 'FI', 'DK', 'FO', 'EE', 'IS'],
      isOverride: true,
    },
  ],
};

export function settings(state = settingsState, { type, data }) {
  switch (type) {
    case c.SAVE_SETTING_LOCALSTORAGE:
      localStorageCanary += 1;
      if (data.value === undefined) {
        localStorage.removeItem(data.key);
      } else {
        localStorage.setItem(data.key, data.value);
      }
      return state;
    case c.SET_SETTINGS_SUCCESS:
      return { ...state, ...data };
    case c.SAVE_SETTING_SUCCESS:
      return { ...state, ...data };
    case TYPE_LOGOUT:
      return settingsState;
    default:
      return state;
  }
}

export function objects(state = {}, { type, data }) {
  switch (type) {
    case c.SET_OBJECTS.SUCCESS:
      return { ...state, ...data };

    default:
      return state;
  }
}

export function tempSettings(state = {}, { type, payload }) {
  switch (type) {
    case c.TEMP_SETTINGS_SET:
      return { ...state, ...payload };
    case c.TEMP_SETTINGS_DROP:
      return R.omit(payload, state);
    default:
      return state;
  }
}

export function getCurrencyId(state) {
  return state.configs.currency.default?.currencyID;
}

export function getCurrencyCode(state) {
  return state.configs.currency.default?.code ?? '';
}

export function getPosCurrencyUnits(
  state,
): null | { name?: string; value: number }[] {
  const units = getSetting('pos_currency_units')(state)
    ?.split(',')
    .map(e => e?.trim())
    .filter(a => a.length)
    .map(pair => {
      const [a, b] = pair.split('=');
      if (b) return { name: a, value: Number(b) };
      return { value: Number(pair) };
    });
  return units.length ? units : null;
}

/**
 * @callback innerSelector
 * @param {import('../../reducers').RootState} state
 * @returns {import('../../containers/Forms/OpenCloseDay/OCDTypes').Denominations}
 *
 * @param {string} code
 * @returns {innerSelector}
 */
export function getDenominations(code) {
  return state => {
    return getCurrencyDenominations(code)(state);
  };
}

export const getDiscountPercentages = createSelector(
  state => getSetting('touchpos_custom_discount_percentages')(state),
  conf => {
    const customPercentages = `${conf}`
      .split(',')
      .map(a => Number(a))
      .filter(a => a);
    const defaultPercentages = [0, 5, 10, 15, 20, 25, 50];
    const percentages =
      customPercentages.length > 0 ? customPercentages : defaultPercentages;
    return R.uniq([0, ...percentages]);
  },
);

/**
 * Convert a 2 character language code into a 3 character one.
 */
function lang2to3(iso2: string): string {
  return {
    en: 'eng',
    et: 'est',
    ru: 'rus',
    fr: 'fre',
    es: 'spa',
    de: 'ger',
    fi: 'fin',
    cs: 'ces',
    it: 'ita',
    tr: 'tur',
  }[iso2];
}
export function getFallbackLanguagesFor(type) {
  return state => {
    const backOfficeLang = getPosDefaultLanguage(state)[
      type === 'receipt' ? 'isoCode' : 'legacyIdentifier'
    ];
    // If you add languages for receipts, don't forget to update supportedLangs with language
    // you're adding
    const supportedLangs = [
      'en',
      'et',
      'ru',
      'fr',
      'es',
      'de',
      'fi',
      'cs',
      'it',
      'tr',
    ];
    const dataFromConf = getSetting('touchpos_set_printing_language_priority')(
      state,
    )?.trim();
    const splitDataFromConf = dataFromConf
      ? dataFromConf
          ?.split(',')
          ?.map(el => el.trim())
          .concat(supportedLangs)
      : supportedLangs;
    return [backOfficeLang, ...splitDataFromConf]
      .filter(value => supportedLangs.includes(value))
      .map(type !== 'receipt' ? lang2to3 : (a: string) => a);
  };
}

let localStorageCanary = 1;
const getSettingsInLocalstorage = createSelector(
  () => localStorageCanary,
  state => getClientCode(state),
  (_, clientCode) =>
    Object.assign(
      {},
      ...Object.keys(localStorage)
        .map(key => key.match(/^CONFIGURATION: (\d+) - (.+)/))
        .filter(match => match)
        .filter(([, cc]) => cc === clientCode)
        .map(([whole, , setting]) => ({
          [`CONFIGURATION: ${setting}`]: JSON.parse(
            localStorage.getItem(whole)!,
          ),
        })),
    ),
);

function getStoredSettings(state) {
  try {
    return JSON.parse(localStorage.getItem(REDUX_SETTINGS)!);
  } catch (e) {
    return null;
  }
}

const getModules = createSelector(
  state => getSettings(state),
  settings => settings?.additionalModules,
);

export function getIsModuleEnabled(name) {
  return createSelector(
    getModules,
    modules => modules.find(m => m.name === name)?.enabled === 1,
  );
}

export const getSavedSettings = createSelector(
  state => getSettingsInLocalstorage(state),
  (state: any) => state.configs?.settings,
  state => getStoredSettings(state),
  (localStorageSettings, settings, storedSettings): Settings => {
    if (Object.values(settings || {}).length > 2) {
      localStorage.setItem(
        REDUX_SETTINGS,
        JSON.stringify({
          ...localStorageSettings,
          ...settings,
        }),
      );
    } else if (storedSettings) {
      return storedSettings;
    }

    return {
      ...localStorageSettings,
      ...settings,
    };
  },
);
const getSettingsBase = createSelector(
  () => {
    try {
      return (
        JSON.parse(sessionStorage.getItem('debug: settingsOverride')!) ?? {}
      );
    } catch (e) {
      return {};
    }
  },
  state => getSavedSettings(state),
  state => getTempSettings(state),
  (ovr, saved, temp): Settings =>
    R.reduce(R.mergeWith((a, b) => a ?? b))({})([ovr, temp, saved]),
);
export const getSettings = createOverrideSelector(
  'getSettings',
  getSettingsBase,
);

export function getSetting<K extends keyof Settings>(name: K) {
  return (state): Settings[K] => {
    const settings = getSettings(state);
    const key = Object.keys(settings).find(
      key => key.toLowerCase() === name.toLowerCase(),
    );
    const value = getSettings(state)[key!];
    return value;
  };
}

export function getSavedSetting<K extends keyof Settings>(settingName: K) {
  return createSelector(getSavedSettings, (settings): Settings[K] => {
    const key = Object.keys({ ...settings }).find(
      key => key.toLowerCase() === settingName.toLowerCase(),
    );
    return settings[key!];
  });
}

function getSettingIsUndefined(name) {
  return state => getSetting(name)(state) ?? undefined;
}
/**
 * True if it is allowed to have positive quantities on return documents, false otherwise
 * @default 1
 */
export const getIsExchangeAllowed = getSetting(
  'allow_exchange_products_on_return',
);

export const getPriceEditingAllowedForExchangesAndReturns = getSetting(
  'allow_price_editing_for_exchanges_and_returns',
);

export function getWarehouseSelect(state) {
  return getSetting('touchpos_warehouse_select')(state);
}

export function getCheckBehavior(state) {
  if (Number(getSetting('capture_check_numbers')(state)) === 1) return 'NEW';
  if (Number(getSetting('touchpos_enable_check_extra_fields')(state)) === 1)
    return 'OLD';
  return 'NOT_CONFIGURED';
}

export function getAllRegisters(state) {
  return getSetting('touchpos_show_all_registers')(state);
}

export function getDisableProductAddedNotification(state) {
  return getSetting('touchpos_disable_product_added_notification')(state);
}

export const getSortProductsBy = getSetting('touchpos_sort_products_by');

export function getIsNonDiscountableProductsEnabled(state) {
  return getSetting('touchpos_non_discountable_products')(state);
}

export function getIsCustomPaymentEnabled(state) {
  return getSetting('touchpos_enable_custom_payments')(state);
}
export function getShouldShowTaxRateInsteadOfName(state) {
  return getSetting('show_tax_rate_instead_of_name')(state);
}
export function getOrderProductsByInProductGroup(state) {
  return getSetting('touchpos_order_products_by_in_product_group')(state);
}
export function getOrderProductsDirectionInProductGroup(state) {
  return getSetting('touchpos_order_products_direction_in_product_group')(
    state,
  );
}

export const getPosRoundCash = createSelector(
  state => getSetting('pos_round_cash_to_nearest_x')(state),
  newSetting => newSetting / 100,
);

export const getPosRoundCashFunction = createSelector(
  state => getPosRoundCash(state),
  state => getSetting('pos_round_algo')(state),
  state => getTotal(state),
  (roundingAmount, algoConf, total) => (value, options) => {
    if (roundingAmount === 0) return value;
    let [whole, target] = ['false', 'inf'];
    try {
      [, whole, target] = algoConf.match(/^(.+?),(.+?)$/);
    } catch (e) {
      console.warn(
        'Failed to parse rounding algorithm, falling back to default',
        e,
      );
    }
    return (
      applyRounding(Number(value) / roundingAmount, {
        wholeRounding: options?.wholeRounding ?? whole === 'true',
        type: options?.type ?? 'payment',
        algorithm: options?.algorithm ?? target,
        direction: options?.direction ?? total < 0 ? 'refund' : 'sale',
      }) * roundingAmount
    );
  },
);

/**
 * Return configured serial number length. 0 if turned off, otherwise string value
 * @param {*} state
 * @returns { string | 0 }
 */
export function getSerialNumberLength(state) {
  return getSetting('serial_number_length')(state);
}

export const getSerialNumberOperator = getSetting('serial_number_operator');

export const getSerialNumberTitle = getSetting('serial_name_title');

export function getUISettings(state) {
  return getSetting('touchpos_ui_configuration')(state);
}

export const getCustomerSearchResultsConfiguration = getSetting(
  'customer_search_results_configuration',
);

export function getUISetting(name) {
  return state => getUISettings(state)[name];
}

/**
 * @returns {'code' | 'barcode'}
 */
export const getProductCodeToShowOnReturnModal = createSelector(
  getSetting('productCodeToShowOnReturnModal'),
  conf => conf,
);
export function getPosLanguages(state) {
  return getSetting('pos_languages')(state);
}
export function getPosDefaultLanguage(state) {
  return getSetting('pos_default_language')(state);
}
export function getBackOfficeDefaultLanguage(state) {
  return getSetting('default_language')(state);
}
export function getBackOfficeFooter(state) {
  return getSetting('receipt_footer')(state);
}
export function getEmployeeIdentifierOnReceipt(state) {
  return getSetting('employee_identifier_on_receipt')(state);
}
export function getBOCodeOnReceipt(state) {
  return getSetting('code_on_receipt')(state);
}
export function getBOtotalDiscountLabel(state) {
  return getSetting('total_discount_label')(state);
}

export function getCountryInUse(state) {
  return getSetting('country')(state);
}

/**
 * @returns {string} symbol
 */
export function getCurrencySymbol(state) {
  return getCurrency(getCurrencyCode(state))(state).symbol;
}

export function getCurrencyFormatter(state) {
  return getCurrency(getCurrencyCode(state))(state).format;
}

export function getCurrencyFormatterForCurrency(currency) {
  return createSelector(getCurrency(currency), currency => currency.format);
}

/**
 - JSON - appending an item to a stringified list
 const value = JSON.parse(str)
 const newStr = JSON.stringify(value.concat(newItem))
 - JSON - setting a property on a stringified object
 const value = JSON.parse(str)
 value.enabled = false;
 const newStr = JSON.stringify(value)
 - CURR - multiplying a stringified sum by a rate
 const value = CURR.parse(str)
 const newStr = CURR.stringify(value * rate)
 */
export const getCurrencyFormatterNoSymbol = createSelector(
  (state: any) => getCurrency(getCurrencyCode(state))(state),
  currency => ({
    stringify: currency.formatNoSymbol,
    parse: currency.unformatNoSymbol,
  }),
);

export function getCurrencyWholeFormatter(state) {
  return getCurrency(getCurrencyCode(state))(state).wholeFormat;
}
export function getCurrencyCentsFormatter(state) {
  return getCurrency(getCurrencyCode(state))(state).centsFormat;
}

export function getPosActiveLanguage(state) {
  const {
    use_default_language: useDefault,
    ['CONFIGURATION: lang' as keyof Settings]: currLang,
    pos_default_language: posLang,
  } = getSettings(state);

  return useDefault ? posLang?.isoCode : currLang;
}

export function getBackOfficeInvoiceAnouncement(state) {
  return getFallbackLanguagesFor('announcement')(state)
    .map(lang => getSetting(`invoice_announcement_${lang}` as any)(state))
    .find(a => a);
}

export function getTempSettings(state) {
  return state.configs.tempSettings;
}

function getLimitSettingFor(docType, pmtType) {
  return createSelector(
    getSetting(`pos_allow_${docType}_${pmtType}` as any),
    getSetting(`pos_allow_${docType}_${pmtType}_limit` as any),
    (allowed: boolean, limit: string) => ({ allowed, limit }),
  );
}

function getLimitSettingsFor(docType: string) {
  return createSelector(
    getLimitSettingFor(docType, 'cash'),
    getLimitSettingFor(docType, 'card'),
    getLimitSettingFor(docType, 'giftcard_regular'),
    getLimitSettingFor(docType, 'giftcard_serial'),
    getLimitSettingFor(docType, 'storecredit'),
    getLimitSettingFor(docType, 'check'),
    getLimitSettingFor(docType, 'tip'),
    (
      cash,
      card,
      giftcard_regular,
      giftcard_serial,
      storecredit,
      check,
      tip,
    ) => ({
      cash,
      card,
      giftcard_regular,
      giftcard_serial,
      storecredit,
      check,
      tip,
    }),
  );
}

export const getAllLimitSettings = createSelector(
  getLimitSettingsFor('sale'),
  getLimitSettingsFor('return_receipt'),
  getLimitSettingsFor('return'),
  getSetting('pos_allow_return_receipt_original_tender_only'),
  (
    sale,
    returnWithReceipt,
    returnWithoutReceipt,
    allowOnlyOriginalTendersOnReturnWithReceipt,
  ) => ({
    enabled: true,
    config: {
      allowOnlyOriginalTendersOnReturnWithReceipt,
      sale,
      returnWithReceipt,
      return: returnWithoutReceipt,
    },
  }),
);

export const getAllowOnlyOriginalTendersOnReturnWithReceipt = createSelector(
  state => getActiveAllowedTendersConfig(state),
  tenderConfig =>
    tenderConfig.config.allowOnlyOriginalTendersOnReturnWithReceipt,
);

function getLimitFor(docType, pmtType) {
  return createSelector(
    state => getActiveAllowedTendersConfig(state),
    tenderConfig => {
      // Since it is not possible to return tips whatsoever when making a referenced or unreferenced return, allow value should be set to -1
      if (pmtType === 'tip') {
        if (docType === 'return' || docType === 'returnWithReceipt') {
          return -1;
        }
      }
      const { allowed, limit } = tenderConfig.config[docType][pmtType] ?? {
        allowed: false,
        limit: '',
      };
      if (!allowed) return -1;
      if (!limit) return null;
      if (limit === '') return null;
      if (Number.isNaN(limit)) return null;
      if (Number(limit) <= 0) return null;
      return Number(limit);
    },
  );
}
function getLimitForGiftcard(docType) {
  return createSelector(
    getLimitFor(docType, 'giftcard_regular'),
    getSetting('pos_use_giftcards_with_serial_numbers'),
    getSettingIsUndefined(`pos_allow_${docType}_giftcard_regular`),
    (limit, defaultOff, unconfigured) => {
      if (Number(defaultOff) && unconfigured) return -1;
      return limit;
    },
  );
}

export function getLimitsFor(docType) {
  return createSelector(
    getLimitFor(docType, 'cash'),
    getLimitFor(docType, 'card'),
    getLimitForGiftcard(docType),
    getLimitFor(docType, 'giftcard_serial'),
    getLimitFor(docType, 'storecredit'),
    getLimitFor(docType, 'check'),
    getLimitFor(docType, 'tip'),
    getLimitFor(docType, 'transfer'),
    (
      cash,
      card,
      giftcard,
      giftcardSerial,
      storeCredit,
      check,
      tip,
      transfer,
    ) => [
      { type: 'CASH', amount: cash },
      { type: 'CARD', amount: card },
      { type: 'GIFTCARD', amount: giftcard },
      { type: 'GIFTCARD', serial: true, amount: giftcardSerial },
      { type: 'STORECREDIT', amount: storeCredit },
      { type: 'CHECK', amount: check },
      { type: 'TIP', amount: tip },
      { type: 'TRANSFER', amount: transfer },
    ],
  );
}

export function getShowPricesWithTax(state) {
  return getSetting('locale_uses_price_with_tax')(state);
}

export function getShowPricesWithQuantity(state) {
  return getSetting('pos_show_prices_in_cart_with_quantity')(state);
}

export function getUseLocalProductDB(state) {
  return getSetting('brazil_use_local_product_db')(state);
}

export const getDisabledDatabaseSO = createSelector(
  state => getUseLocalProductDB(state),
  useLocalProductsDB => [
    SO.CUSTOMERS.NAME,
    /*
    Add new conditions here if more SO should be synced conditionally
   */
    ...(!useLocalProductsDB
      ? [SO.PRODUCTS.NAME, SO.PRODUCT_STOCK.NAME, SO.PRICE_LIST.NAME]
      : []),
  ],
);

export function getSkipSyncForSO(SO_NAME) {
  return state => getDisabledDatabaseSO(state).includes(SO_NAME);
}

export function getFetchOnlyProductsThatAreInStock(state) {
  return getSavedSetting('touchpos_fetch_only_products_in_stock')(state);
}

export function getUseAgeVerification(state) {
  return getSetting('touchpos_use_age_verification')(state);
}

/**
 * @returns {{id: string, name: string, SP:string, charset:number}[]}
 */
export function getSupportedPrinterIntegrations(state) {
  return [
    { id: '0519 0001', name: 'Star', SP: '\x7F', charset: 32 },
    { id: '04b8 0e15', name: 'Epson', SP: '\xA0', charset: 16 },
    { id: '1504 0059', name: 'Bixolon', SP: '\xA0', charset: 16 },
  ];
}

/**
 * @returns {{id: string, name: string, SP:string, charset:number}}
 */
export function getCurrentPrinterIntegration(state) {
  const integrations = getSupportedPrinterIntegrations(state);
  const selected = getSetting('currentPrinterIntegration')(state);
  return integrations.find(int => int.name === selected) || integrations[0];
}

export const getCurrentPosLanguage = createSelector(
  state => getTempSettings(state)?.['CONFIGURATION: lang'],
  state =>
    getCafaEntry(
      types.INTEGRATIONS.posConfigurations.language,
      types.INTEGRATION_TYPES.posConfigurations,
      { level: types.CAFA_LEVELS.User, id: getUserLoggedIn(state)?.userID },
    )(state),
  state => getSetting('CONFIGURATION: lang' as any)(state),
  state => getSetting('pos_default_language')(state),
  (fromTempSettings, fromCAFAConfig, fromLS, defaultLangObj) =>
    fromTempSettings ??
    fromCAFAConfig?.value ??
    (fromLS?.length ? fromLS : undefined) ??
    defaultLangObj.isoCode,
);

export const getCurrentPosLanguageOverride = createSelector(
  state => getTempSettings(state)['CONFIGURATION: lang'],
  state =>
    getCafaEntry(
      types.INTEGRATIONS.posConfigurations.language,
      types.INTEGRATION_TYPES.posConfigurations,
      { level: types.CAFA_LEVELS.User, id: getUserLoggedIn(state)?.userID },
    )(state),
  state => getSetting('CONFIGURATION: lang' as any)(state),
  (fromTempSettings, fromCAFAConfig, fromLS) =>
    fromTempSettings ?? fromCAFAConfig?.value ?? fromLS,
);

export const getWrapperConfig = createSelector(
  getCafaEntry('wrapper', types.INTEGRATION_TYPES.payment),
  entry => entry?.value,
);

export const getUseCayanCapture = createSelector(
  getSetting('use_cayan_capture'),
  /* Temporarily disabled as per https://erply.atlassian.net/browse/PBIB-1750?focusedCommentId=187006 */
  setting => false,
  // setting => setting === 1,
);

export const getAdditionalCurrencies = createSelector(
  state => getSetting('touchpos_sale_additional_currencies')(state),
  currencies => {
    const codes = currencies
      ?.split(',')
      .map(c => c.trim())
      .filter(c => c.match('^[A-Z]{3}$'));
    return codes ?? [];
  },
);

export function getDefaultCurrency(state) {
  return (
    getSetting('default_currency')(state) ??
    state.configs.currency?.default?.code
  );
}

export const getEnabledCurrencies = createSelector(
  getIsModuleEnabled('pos_multicurrency'),
  getDefaultCurrency,
  getAdditionalCurrencies,
  (useMultiCurrency, defaultCurrency, additional) =>
    useMultiCurrency
      ? Array.from(new Set([defaultCurrency, ...additional]))
      : [defaultCurrency],
);

export function getShouldShowPrintAttemptCount(state) {
  return !!Number(getSetting('touchpos_show_print_attempt_number')(state));
}

export function getShouldShowCardDataOnReceipt(state) {
  return !!Number(getSetting('touchpos_show_card_data_on_receipt')(state));
}

export function getShouldShowCustomerStoreCreditBalanceOnReceipt(state) {
  return !!Number(
    getSetting('show_customer_store_credit_balance_on_receipt')(state),
  );
}

export function getShouldChooseEmployeeManually(state) {
  return !!Number(getSetting('touchpos_choose_employee_manually')(state));
}

export function getShouldShowProductCode(state) {
  return getSetting('touchpos_show_product_code_on_receipt')(state);
}

export function getShouldShowCashChangeOnReceipt(state) {
  return getSetting('touchpos_show_change_on_receipt')(state);
}

export function getShowTotalDiscountOnReceipt(state) {
  return getSetting('touchpos_show_discount_total')(state);
}

/**
 * @deprecated
 */
export function getShowCompanyRegVatNumberOnReceipt(state) {
  return getSetting('touchpos_show_company_reg_vat_number')(state);
}

export function getShouldHideNegativeDiscountsOnReceipt(state) {
  return getSetting('hide_negative_discounts')(state);
}

export function getAllowEditingExistingLayaways(state) {
  return getSetting('touchpos_allow_editing_existing_layaways')(state);
}

export const getDiscountLimit = createSelector(
  state => getUserRights(state),
  rights => {
    const { rightMakeDiscountInPOS } = rights;
    // If user cannot assign discounts, set the limit to 0, else check maxDiscount
    if (rightMakeDiscountInPOS === 0) return 0;
    const md = rights.maxDiscount;
    if (md < 0) return 0;
    if (md === 0) return Number.POSITIVE_INFINITY;
    return md;
  },
);

// Previously known as getIsCashBackDisabled, now Cash Back renamed to Cash Withdrawal/Out
export function getIsCashWithdrawalDisabled(state) {
  return (
    // usage of 'disableCashBack' intentional!
    getSetting('disableCashBack')(state)
  );
}

/**
 * @returns {Selector<string[]>}
 */
export function getCustomPromptsForProduct(id) {
  return state => {
    const product = getProductByID(id)(state) ?? {};
    const group = getProductGroupByID(product.groupID)(state);
    const productAttrs =
      new ErplyAttributes(product.attributes).get('custom_prompts') ?? '';
    const groupAttrs =
      new ErplyAttributes(group.attributes).get('custom_prompts') ?? '';

    const asArray = `${productAttrs},${groupAttrs}`.split(',').filter(a => a);
    return Array.from(new Set(asArray)).sort((a, b) => a.localeCompare(b));
  };
}

export const getDayCountedByDrawer = createSelector(
  getSetting('pos_shifts_counted'),
  setting => setting === 'BY_DRAWER',
);

export const getTransactionsOnlyFromLastShift = getSetting(
  'touchpos_get_report_only_last_shift',
);

const dateFormatMapping = {
  'd/m/Y': 'DD/MM/YYYY',
  'd.m.Y': 'DD.MM.YYYY',
  'd.m.Y.': 'DD.MM.YYYY.',
  'd-m-Y': 'DD-MM-YYYY',
  'm/d/Y': 'MM/DD/YYYY',
  'Y-m-d': 'YYYY-MM-DD',
  'Y/m/d': 'YYYY/MM/DD',
  'Y.m.d.': 'YYYY.MM.DD.',
  'Y.m.d': 'YYYY.MM.DD',
};
export const getDateFormatFromBO = createSelector(
  state => getSetting('dateformat')(state),
  boFormat =>
    (dateFormatMapping[boFormat] ?? 'DD-MM-YYYY')
      .toLowerCase()
      .replace('mm', 'MM'),
);
export const getDateFormatFromBOKeysOrdered = createSelector(
  state => getSetting('dateformat')(state),
  boFormat =>
    (boFormat.toLowerCase() || 'dmy')
      .replace(/[^a-z']/g, '')
      .split('')
      .map(el => {
        if (el === 'd') {
          return { key: 'date' };
        }
        if (el === 'm') {
          return { key: 'month' };
        }
        return { key: 'year' };
      }),
);

export function getAPIDateFormatter() {
  return (date, format) =>
    date ? String(dayjs(date, format).format('YYYY-MM-DD')) : '';
}

export const getDateFormatter = createSelector(
  state => getSetting('dateformat')(state),
  boFormat => date =>
    date
      ? dayjs(
          date,
          dateFormatMapping[boFormat]?.toLowerCase()?.replace('mm', 'MM'),
        ).format(dateFormatMapping[boFormat] ?? 'DD-MM-YYYY')
      : date,
);

export function getAllowOfflineLogin(state) {
  return !!Number(getSetting('touchpos_allow_offline_login')(state));
}

export function getAllowOfflineMode(state) {
  return getSetting('touchpos_allow_offline_mode')(state);
}

export function getBarcodeType(state) {
  return getSetting('barcode_type')(state) ?? '';
}
export function getBarcodeWeightPrefix(state) {
  return getSetting('barcode_weight_prefix')(state) ?? '';
}
export function getBarcodePricePrefix(state) {
  return getSetting('barcode_price_prefix')(state) ?? '';
}
export function getBarcodeCodeLength(state) {
  return Number(getSetting('barcode_code_length')(state));
}
export function getBarcodeMeasurementLength(state) {
  return Number(getSetting('barcode_measurement_length')(state));
}
export function getBarcodeWeightDecimals(state) {
  return Number(getSetting('barcode_weight_decimals')(state));
}

/**
 * Whether the payment integration's batch should be synced to day openings
 * (batch closed when day closed, batch opened when day opened)
 *
 * @default true
 */
export const getSyncBatchToDayOpenings = createSelector(
  getSetting('sync_terminal_batch_to_day_openings'),
  setting => Number(setting ?? '1'),
);

/**
 * Should Product button expand to show longer product names and under what circumstances
 * @type {OutputSelector<unknown, unknown, (res: unknown) => unknown>}
 */
export function getExpandProductButtonConfig(state) {
  return getSetting('pos_expand_product_button')(state);
}

export function getPrefillHomeStoreOnCustomerCreation(state) {
  return !!getSetting('touchpos_prefill_home_store_during_customer_creation')(
    state,
  );
}

/**
 * Should customers be filtered in Customer search input component
 * @returns {"disabled" | "byHomeStore" | "bySignupStore"}
 */
export function getCustomerSearchFilterOption(state) {
  return getSetting('pos_customer_search_filter')(state);
}

export const getProductNoteButtonsConfigs = createSelector(
  state => getSetting('pos_product_quick_notes')(state),
  state => getSetting('pos_product_quick_notes_preview_count')(state),
  (notes, previewCount) => {
    return { notes, previewCount };
  },
);

export function getUseCreateCustomerBeta(state) {
  return getSetting('pos_brazil_use_customer_creation_beta')(state);
}

export function getProductFilterModuleEnabled(state) {
  return false;
  // Temporary disable the feature cause it is not currently supported
  // return  getSetting('touchpos_filter_products_button', false)(state);
}

export const getSkipCalculateShoppingCartOnChange = getSetting(
  'pos_brazil_do_not_calculate_shopping_cart_on_change',
);

export const getAllowScanningFromOrderQtyInput = getSetting(
  'pos_brazil_allow_scanning_products_from_order_qty_field',
);

export function getShouldShowCurrentDocumentTypeAboveCart(state) {
  return !!getSetting('pos_brazil_show_current_document_type_above_cart')(
    state,
  );
}
/**
 * Check if POS Version Control is enabled
 * <br/>
 * <em>Initially the implementation is optional and can be toggled with setting. This setting would perhaps be deprecated in the future.</em>
 */
export function getEnabledPOSVersionControl(state) {
  return getSavedSetting('pos_brazil_enabled_version_control')(state);
}

export function getTextAroundSerialNumber(state) {
  const defaultValue = '(,)';
  const value = String(getSetting('text_around_serial_number')(state));
  const regex = /^(.{1},.{1})?$/;
  return regex.test(value) ? value : defaultValue;
}

export const getSerialNumberFormatterFn = createSelector(
  getTextAroundSerialNumber,
  wrapperAsString => {
    const [firstSymbol, lastSymbol] = wrapperAsString.split(',');
    return serialNumber =>
      wrapperAsString
        ? `${firstSymbol}${serialNumber}${lastSymbol}`
        : serialNumber;
  },
);

/** @deprecated Functionally exact duplicate of {@link getSavedPostimeoutSettings}> */
export function getPosTimeoutSettings(state) {
  return R.tryCatch(
    JSON.parse,
    R.always(defaultSettings.pos_timeout_config),
  )(getSavedSetting('pos_timeout_config')(state));
}

export const getSavedPosTimeoutSettings = createSelector(
  getSavedSetting('pos_timeout_config'),
  R.tryCatch(
    JSON.parse,
    R.always(defaultSettings.pos_timeout_config),
  ) as () => any,
);

export function getGiftcardSerialConfig(state) {
  return getSetting('pos_serial_giftcard_pattern')(state);
}

export const getGiftcardSerialRegex = createSelector(
  getGiftcardSerialConfig,
  pattern => {
    if (!pattern) {
      // Source: https://datatracker.ietf.org/doc/html/rfc4122#page-14
      // v4 UUIDs are mostly random, except...
      // 1) the two most significant bits (bits 6 and 7) of clock_seq_hi_and_reserved are zero and one, respectively
      //    Translation: The first character of c_s_h_a_r must be 8, 9, a, or b
      // 2) the four most significant bits (bits 12 through 15) of time_hi_and_version to the 4-bit version number
      //    Translation: The first character of t_h_a_v must be 4
      //
      //                time-low  - xxxxxxxx
      //                time-mid  - xxxx
      //   time-high-and-version  - 4xxx
      //  clock-seq-and-reserved
      //         & clock-seq-low  - Hxxx  (H = 8|9|a|b)
      //                    node  - xxxxxxxxxxxx
      //
      //       time-low   time-mid    t-h-a-v   c-s-a-r & c-s-l     node
      //      |        | |        | |         | |              | |         |
      // return /^[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/;
      return /.+/;
    }
    try {
      return new RegExp(`^${pattern}$`);
    } catch {
      return /.+/;
    }
  },
);

export const getCurrentPosLanguageLegacy = createSelector(
  getPosLanguages,
  getCurrentPosLanguage,
  getSetting('default_language'),
  (langs, currentLang, defaultLang) => {
    const exactLang = langs.find(
      l => currentLang === l?.isoCode || currentLang === l.code,
    );
    return (
      (exactLang ?? langs.find(l => currentLang.split('-')[0] === l?.isoCode))
        ?.legacyIdentifier ?? defaultLang
    );
  },
);

/**
 * Indicates if the logged-in user should see the employees form all locations
 * or only from the current location
 */
export const getHideEmployeesFromOtherLocation = getSetting(
  'touchpos_hide_employees_from_other_location',
);

export function getAllowFractionalProductQuantities(state) {
  return getSetting('allow_fractional_product_quantities')(state);
}

export function getShoppingCartRowMergingCriteria(state) {
  return getSetting('touchpos_group_items_in_row_by')(state);
}

export function getUseReceiptTemplates(state) {
  return getSetting('use_actual_reports_templates')(state);
}

export function getReceiptTemplateIdByDocType(docType = '') {
  return state =>
    getSetting(`actual_reports_template_for_${docType.toLowerCase()}` as any)(
      state,
    ) ?? 0;
}

export const getPosAssociationConfig = createSelector(
  getSavedSetting('pos_associations'),
  associationConfig => associationConfig ?? defaultSettings.pos_associations,
);

export function getIsSpecialHandlingOfGiftReturnsEnabled(state) {
  return getSetting('pos_enable_special_handling_of_gift_returns')(state);
}

export const getIsSellingToDefaultCustomerBlocked = createSelector(
  getSetting('touchpos_disable_selling_to_default_customer'),
  getAllowOfflineMode,
  getConnectionHealth,
  (disableSellingToDefaultCustomer, allowOffline, isOnline) => {
    if (allowOffline && !isOnline) {
      return false;
    }
    return disableSellingToDefaultCustomer;
  },
);

/**
 * Function that can be used to format ordinary digits like 12, 561563, etc to the account's `numberformat`
 */
export const getNumberFormatFunction = createSelector(
  state => getSetting('numberformat')(state),
  ([decimalSep, thousandsSep]) => {
    const format = (num: number) => {
      /* de-DE = 23.456.789,123456123 */
      return num.toLocaleString('de-DE').replace(
        /[,.]/g,
        match =>
          ({
            '.': thousandsSep,
            ',': decimalSep,
          }[match]),
      );
    };
    return format;
  },
);
