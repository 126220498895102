import React, { useState } from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';

import { Configuration } from './Configuration';

const validateFrequency = frequency => {
  if (frequency < 1) return 'Frequency must be at least 1';
  if (frequency % 1) return 'Frequency must be an integer'; // must it?
  return '';
};
export const ComponentConfiguration: PosPlugin<
  Configuration
>['ComponentConfiguration'] = ({ current, save }) => {
  const currentFreq = current?.frequency ?? 1;
  const [freq, setFreq] = useState(String(currentFreq));

  const error = validateFrequency(Number(freq));
  return (
    <TextField
      label="Update frequency (minutes)"
      type="number"
      variant="outlined"
      value={freq}
      onChange={e => setFreq(e.target.value)}
      error={!!error}
      helperText={error}
      InputProps={{
        inputProps: { min: 1, step: 1 },
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="contained"
              disabled={Number(freq) === current?.frequency || !!error}
              onClick={() => save({ frequency: Number(freq) })}
            >
              Save
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};
