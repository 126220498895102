import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, styled } from '@material-ui/core';

import TableStats from 'components/tableStats/TableStats';
import UserBadge from 'components/userBadge/UserBadge';
import UIButton from 'components/UIElements/UIButton';
import CustomerPurchasesContainer from 'components/customerPurchases/CustomerPurchasesContainer';
import {
  getProductFilterModuleEnabled,
  getUISetting,
} from 'reducers/configs/settings';
import { useBreakpoints } from 'utils/hooks/UI';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp, modalPages } from 'constants/modalPage';
import { getGridView, getGridIsOpened } from 'reducers/UI/gridDisplay';
import { PluginComponent } from 'plugins';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import {
  getCustomerRewardPointsSelector,
  getIsDefaultCustomer,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getPreviousPurchases, getTotalVisitsToStore } from 'reducers/sales';

import './visibleCustomerInformation.css';

export const BadgeComponent = ({
  totalVisitsToStore,
  previousPurchases,
  customerRewardPoints,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('customerInformation');
  const currentCustomer = useSelector(getSelectedCustomer);

  const displayCustomerView = useCallback(
    () =>
      currentCustomer.id
        ? dispatch(
            openModalPage({
              component: mp.customerView,
              groupID: mp.customerView,
              replace: true,
            }),
          )
        : null,
    [currentCustomer.id],
  );

  const gridDisplay = useSelector(getGridView);
  const largeView = useBreakpoints().mdplus;
  const addProductHandler = useCallback(prod => dispatch(addProduct(prod)), []);

  // Check if Filter Products button should be rendered instead of Purchases
  const filterProductsEnabled = useSelector(getProductFilterModuleEnabled);
  const openFilterProductsView = useCallback(() => {
    dispatch(
      openModalPage({
        component: modalPages.productsFilter,
        groupID: modalPages.productsFilter,
        replace: true,
      }),
    );
  }, []);

  const titles = ['storeCredit', 'rewardPoints', 'visits'];

  const dataHeaders = useMemo(() => titles.map(name => t(name).toUpperCase()), [
    titles,
  ]);

  const data = useMemo(
    () => [
      currentCustomer.availableCredit,
      customerRewardPoints,
      totalVisitsToStore,
    ],
    [currentCustomer.availableCredit, customerRewardPoints, totalVisitsToStore],
  );

  return (
    <PluginComponent
      hookname="UICustomerBadgeCustomRegion"
      props={{
        totalVisitsToStore,
        previousPurchases,
        customerRewardPoints,
        currentCustomer,
      }}
    >
      <Grid item container>
        {largeView && (
          <PluginComponent
            hookname="UITableStats"
            props={{
              dataHeaders,
              data,
            }}
          >
            <TableStats
              dataHeaders={dataHeaders}
              data={data}
              isCustomer={true}
            />
          </PluginComponent>
        )}
      </Grid>
      {largeView && (
        <Grid
          item
          container
          spacing={1}
          data-testid="user-badge-action-buttons"
        >
          <Grid item xs={6}>
            <UIButton
              text={t('details')}
              block={true}
              className="client-details-button"
              action={displayCustomerView}
            />
          </Grid>
          <Grid item xs={6}>
            {filterProductsEnabled ? (
              <UIButton
                // todo filter button should be translated
                text="FILTER"
                block={true}
                action={openFilterProductsView}
              />
            ) : (
              <UIButton
                text={t('purchases')}
                block={true}
                action={displayCustomerView}
              />
            )}
          </Grid>
        </Grid>
      )}
      {!gridDisplay && largeView ? (
        <Grid item>
          <CustomerPurchasesContainer
            previousPurchases={previousPurchases}
            addProduct={addProductHandler}
          />
        </Grid>
      ) : null}
    </PluginComponent>
  );
};

const Container = styled('div')(({ isMobileView, isLargeView }) => ({
  marginTop: !isMobileView && '24px',
  marginRight: isMobileView && '15px',
  wordBreak: 'break-word',
  ...(isLargeView
    ? {
        maxWidth: '1140px',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100%',
      }
    : {}),
}));

/** Component that displays general information about a client, like personal data, sales, rewards, etc. */
const VisibleCustomerInformation = () => {
  const hideVCI = useSelector(getUISetting('hideCustomerInfo'));
  const currentCustomer = useSelector(getSelectedCustomer);
  const isDefaultCustomer = useSelector(getIsDefaultCustomer);
  const customerRewardPoints = useSelector(getCustomerRewardPointsSelector);
  const totalVisitsToStore = useSelector(getTotalVisitsToStore);
  const previousPurchases = useSelector(getPreviousPurchases);
  const gridDisplay = useSelector(getGridIsOpened);

  const { mdplus: largeView, lg: largestView } = useBreakpoints();

  const currentCustomerName = useMemo(() => {
    if (Object.keys(currentCustomer ?? {}).length === 0) return '';
    return !currentCustomer.companyName || currentCustomer.companyName === ''
      ? `${currentCustomer.firstName} ${currentCustomer.lastName}`
      : currentCustomer.companyName;
  }, [currentCustomer]);

  if (hideVCI) return null;
  return (
    <PluginComponent
      hookname="UIVisibleCustomerInformation"
      props={{
        currentCustomerName,
        totalVisitsToStore,
        previousPurchases,
        customerRewardPoints,
        currentCustomer,
        isDefaultCustomer,
        largeView,
        gridDisplay,
      }}
    >
      <Container
        data-testid="customer-information-container"
        isMobileView={!largeView}
        isLargeView={largestView}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} data-testid="user-column">
            <UserBadge
              customerName={currentCustomerName}
              totalVisitsToStore={totalVisitsToStore}
              customerRewardPoints={customerRewardPoints}
              isDefaultCustomer={isDefaultCustomer}
              customerCardNumber={currentCustomer.customerCardNumber}
              customerGroup={currentCustomer.groupName}
              isCustomer={true}
            />

            <BadgeComponent
              totalVisitsToStore={totalVisitsToStore}
              previousPurchases={previousPurchases}
              customerRewardPoints={customerRewardPoints}
              isCustomer={true}
            />
          </Grid>
        </Grid>
      </Container>
    </PluginComponent>
  );
};

export default VisibleCustomerInformation;
