import React from 'react';

import Icon from 'components/Icon';

const style = {
  wrapper: {
    display: 'flex',
    height: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {
    fontSize: '1.85em',
  },
};

export const extendBttn = (action, code = null, groupID = null) => ({
  name: (
    <span style={style.wrapper}>
      <span style={style.inner}>...</span>
    </span>
  ),
  id: 'extendBttn',
  code,
  groupID,
  type: 'special',
  action,
});

export const homeBttn = action => ({
  id: 'special-go-to-main-view',
  name: (
    <span style={style.wrapper}>
      <span className="icon_house" style={style.inner} />
    </span>
  ),
  type: 'special',
  action,
});

export const goUpBttn = (action, disabled) => ({
  id: 'special-go-up',
  name: (
    <span style={style.wrapper}>
      <span className="arrow_carrot-up_alt2" style={style.inner} />
    </span>
  ),
  type: 'special',
  disabled,
  action,
});

export const addNewBttn = action => ({
  id: 'special-add-new',
  name: (
    <span style={style.wrapper}>
      <Icon name="erply_plus_circle" style={{ fontSize: '32px' }} />
    </span>
  ),
  type: 'special',
  action,
});

export const leftArrowBttn = (action, disabled) => ({
  id: 'special-prev-product',
  name: (
    <span style={style.wrapper}>
      <span className="arrow_carrot-left_alt2" style={style.inner} />
    </span>
  ),
  type: 'special',
  disabled,
  action,
});

export const rightArrowBttn = (action, disabled) => ({
  id: 'special-next-product',
  name: (
    <span style={style.wrapper}>
      <span className="arrow_carrot-right_alt2" style={style.inner} />
    </span>
  ),
  type: 'special',
  disabled,
  action,
});
