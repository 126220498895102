import { createSelector } from 'reselect';

import {
  getBOCodeOnReceipt,
  getSetting,
  getShouldHideNegativeDiscountsOnReceipt,
  getShouldShowCardDataOnReceipt,
  getShouldShowCashChangeOnReceipt,
  getShouldShowCustomerStoreCreditBalanceOnReceipt,
  getShouldShowPrintAttemptCount,
  getShouldShowProductCode,
  getShowPricesWithTax,
  getShowTotalDiscountOnReceipt,
} from 'reducers/configs/settings';
import { TItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';

function conditionalShowItems<T>(show: boolean) {
  return (...values: T[]): T[] => {
    if (show) return values;
    return [];
  };
}
function conditionalShowSingleItem<T>(show: boolean, value: T): [T] | [] {
  if (show) return [value];
  return [];
}
function createDefaultTemplateSelector(showPrices) {
  return createSelector(
    getShowPricesWithTax,
    getShouldShowProductCode,
    getShouldShowCashChangeOnReceipt,
    getShowTotalDiscountOnReceipt,
    getShouldShowPrintAttemptCount,
    getShouldShowCardDataOnReceipt,
    getShouldShowCustomerStoreCreditBalanceOnReceipt,
    getShouldHideNegativeDiscountsOnReceipt,
    getBOCodeOnReceipt,
    getSetting('touchpos_show_company_reg_vat_number'),
    (
      isWithTax,
      showProductCode,
      showChange,
      showDiscountTotal,
      shouldShowReprintAmount,
      showCardInfo,
      showStoreCreditBalance,
      shouldHideNegativeDiscounts,
      BOReceiptCode,
      showCompanyRegVatNumberInHeader,
    ): TItem[] => {
      // prettier-ignore
      const schema: TItem[] = [
        { type: 'image', align: 'center', blob: ['logo'] },
        {
          type: 'text',
          pieces: [{
            text: ['company', 'name'],
            meta: { bold: true },
          }],
        },
        {
          type: 'text',
          pieces: [
              { 
                meta: {
                  bold: false, underline: false
                },
                text: ['locationOrCompanyData', 'receiptInfoAddress']
              }
          ],
        },
        ...conditionalShowSingleItem<TItem>(showCompanyRegVatNumberInHeader, {
          type: 'text',
          pieces: [
            { text: ['translations', 'company', 'regNumber'] },
            { text: ': ' },
            { text: ['locationOrCompanyData', 'receiptInfoCode'] },
            { text: '; ' },
            { text: ['translations', 'company', 'vat'] },
            { text: ': ' },
            { text: ['company', 'VAT'] },
          ],
        }),
        {
          type: 'text',
          pieces: [
            { text: ['translations', 'company', 'phone'] },
            { text: ': ' },
            { text: ['locationOrCompanyData', 'receiptInfoPhone'] },
          ],
        },
        {
          type: 'text',
          pieces: [
            { text: ['warehouse', 'name'] },
            { text: ' - ' },
            { text: ['pointOfSale', 'name'] },
          ],
        },
        {
          type: 'text',
          pieces: [
            { text: ['translations', 'sale', 'saler'] },
            { text: ': ' },
            { text: ['employeeIdentifier'] },
          ],
        },
        { type: 'text', pieces: [] },
        {
          type: 'text',
          pieces: [
            { text: ['documentName'], meta: { underline: true } },
          ],
        },
        ...conditionalShowSingleItem<TItem>(shouldShowReprintAmount, {
          type: 'text',
          pieces: [
            { text: ['translations', 'sale', 'reprintNumber'] },
            { text: ': ' },
            { text: ['currentPrintNumber'] },
          ],
        }),
        {
          type: 'text',
          pieces: [
            { text: ['date'] },
            { text: ', ' },
            { text: ['time'] },
          ],
        },
        { type: 'text', pieces: [{ text: ' ' }] },
        {
          type: 'text',
          pieces: [
            { text: ['translations', 'sale', 'client'] },
            { text: ': ' },
            { text: ['customerFullName'] },
          ],
        },
        ...conditionalShowSingleItem<TItem>(showStoreCreditBalance, {
          'type': 'text',
          'pieces': [
            { 'text': ['translations', 'storeCredit', 'currentBalance'] },
            { 'text': ': ' },
            { 'text': ['customer', 'customerCurrentBalanceWithCurrency'] }
          ],
        }),
        { type: 'text', pieces: [{ text: '' }] },
        {
          type: 'table',
          columns: [
            { baseWidth: 0, weight: 3 },
            { baseWidth: 0, weight: 1 },
            ...conditionalShowItems<any>(showPrices)(
              { baseWidth: 6, weight: 0 },
              { baseWidth: 8, weight: 0 },
            ),
          ],
          rows: [
            {
              type: 'header',
              cells: [
                { pieces: [{ text: ['translations', 'product', 'description'] }] },
                {
                  align: 'center',
                  pieces: [{ text: ['translations', 'product', 'quantity'] }],
                },
                ...conditionalShowItems(showPrices)(
                  {
                    align: 'right',
                    pieces: [{ text: ['translations', 'product', 'price'] }],
                  },
                  {
                    align: 'right',
                    pieces: [{ text: ['translations', 'product', 'sum'] }],
                  },
                ),
              ],
            },
            {
              repeat: ['billTable', 'billRows'],
              sub: [
                {
                  type: 'normal',
                  cells: [
                    {
                      pieces: [
                        { text: ['product', 'outer', 'product', 'nameWithTranslation'] },
                        ...conditionalShowItems<any>(showProductCode && BOReceiptCode)(
                          { text: ' (' },
                          { text: ['product', 'productCodeForReceipt'] },
                          { text: ')' },
                        ),
                      ],
                    },
                    {
                      align: 'center',
                      pieces: [
                        { text: ['amount'] },
                        { text: ['product', 'unitForReceipt'] },
                      ],
                    },
                    ...conditionalShowItems<any>(showPrices)(
                      {
                        align: 'right',
                        pieces: [{ text: [isWithTax ? 'finalPriceWithVAT' : 'finalNetPrice'] }],
                      },
                      {
                        align: 'right',
                        pieces: [{ text: [isWithTax ? 'rowTotal' : 'rowNetTotal'] }],
                      },
                    ),
                  ],
                },
                {
                  type: 'normal',
                  cells: [
                    {
                      pieces: [{
                        text: ['product', 'outer', 'product', 'notes'],
                        meta: { size: -1 },
                      }],
                    },
                    ...conditionalShowItems<any>(showPrices)(
                      {
                        align: 'center',
                        pieces: [{
                          text: ['originalPrice'],
                          meta: { size: -1 },
                        }],
                      }, {
                        align: 'right',
                        pieces: [{
                          text: ['discount'],
                          meta: { size: -1 },
                        }],
                      },
                    ),
                  ],
                },
              ],
            },
            {
              type: 'normal',
              cells: [{ pieces: [] }],
            },
            {
              type: 'sectionStart',
              cells: [
                { pieces: [] },
                { pieces: [] },
                { pieces: [] },
                { pieces: [] },
              ],
            },
            ...conditionalShowItems<any>(showPrices)(
              {
                type: 'normal',
                cells: [
                  {
                    align: 'right',
                    pieces: [
                      {
                        text: ['company', 'outer', 'translations', 'totals', 'subtotal'],
                        meta: { bold: true },
                      },
                      { text: ' ' },
                      {
                        text: ['company', 'outer', 'currencySymbol'],
                        meta: { bold: true },
                      },
                    ],
                  },
                  'colspan',
                  'colspan',
                  {
                    align: 'right',
                    pieces: [
                      {
                        text: ['company', 'outer', 'billTable', 'subTotal'],
                        meta: { bold: true },
                      },
                    ],
                  },
                ],
              },
              {
                type: 'normal',
                cells: [
                  'colspan',
                  { pieces: [] },
                  { pieces: [] },
                  { pieces: [] },
                ],
              },
              {
                type: 'sectionStart',
                cells: [
                  'colspan',
                  { pieces: [] },
                  { pieces: [] },
                  { pieces: [] },
                ],
              },
              {
                repeat: ['billTable', 'vatRateRows'],
                sub: [
                  {
                    type: 'normal',
                    cells: [
                      {
                        align: 'right',
                        pieces: [
                          ['outer', 'translations', 'totals', 'neto'],
                          ' ',
                          ['outer', 'currencySymbol'],
                        ].map(text => ({ text, meta: { bold: true } })),
                      },
                      'colspan',
                      'colspan',
                      {
                        align: 'right',
                        pieces: [
                          {
                            text: ['netSum'],
                            meta: { bold: true },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'normal',
                    cells: [
                      {
                        align: 'right',
                        pieces: [
                          ['outer', 'translations', 'product', 'VAT'],
                          ' (',
                          ['tax'],
                          ') ',
                          ['outer', 'currencySymbol'],
                        ].map(text => ({ text, meta: { bold: true } })),
                      },
                      'colspan',
                      'colspan',
                      {
                        align: 'right',
                        pieces: [{ meta: { bold: true }, text: ['vatSum'] }],
                      },
                    ],
                  },
                  {
                    repeat: ['components'],
                    sub: [
                      {
                        type: 'normal',
                        cells: [
                          null,
                          {
                            align: 'right',
                            pieces: [
                              { text: ['tax'], meta: { size: -1 } },
                              { text: ' ' },
                              { text: ['outer', 'currencySymbol'] },
                            ],
                          },
                          'colspan',
                          {
                            align: 'right',
                            pieces: [{ text: ['vatSum'], meta: { size: -1 } }],
                          },
                        ],
                      },
                    ],
                  },
                  { type: 'normal', cells: [{ pieces: [] }] },
                ],
              },
              {
                type: 'sectionStart',
                cells: [
                  null,
                  {
                    align: 'right',
                    pieces: [
                      {
                        text: ['company', 'outer', 'translations', 'totals', 'total'],
                        meta: { bold: true, size: 2 },
                      },
                      { text: ' ' },
                      {
                        text: ['currencySymbol'],
                        meta: { bold: true, size: 2 },
                      },
                    ],
                  },
                  'colspan',
                  {
                    align: 'right',
                    pieces: [{
                      text: ['billTable', 'total'],
                      meta: { bold: true, size: 2 },
                    }],
                  },
                ],
              },
              {
                type: 'normal',
                cells:
                  conditionalShowItems<any>(showDiscountTotal)(
                    ...(shouldHideNegativeDiscounts ? [
                      {
                        if: ['billTable', 'totalDiscountSumWithVatIsNegative'],
                        else: [null,
                          {
                            align: 'right',
                            pieces: [
                              {
                                text: ['totalDiscountLabel'],
                                meta: { bold: true },
                              },
                              { text: ' ' },
                              {
                                text: ['company', 'outer', 'currencySymbol'],
                                meta: { bold: true },
                              },
                            ],
                          },
                          'colspan',
                          {
                            align: 'right',
                            pieces: [
                              {
                                text: ['billTable', isWithTax ? 'totalDiscountSumWithVat' : 'totalDiscountSum'],
                                meta: { bold: true },
                              },
                            ],
                          },],
                      }
                    ] : [
                      null,
                      {
                        align: 'right',
                        pieces: [
                          {
                            text: ['totalDiscountLabel'],
                            meta: { bold: true },
                          },
                          { text: ' ' },
                          {
                            text: ['company', 'outer', 'currencySymbol'],
                            meta: { bold: true },
                          },
                        ],
                      },
                      'colspan',
                      {
                        align: 'right',
                        pieces: [
                          {
                            text: ['billTable', isWithTax ? 'totalDiscountSumWithVat' : 'totalDiscountSum'],
                            meta: { bold: true },
                          },
                        ],
                      },
                    ])
                  ),
              },
              {
                repeat: ['billTable', 'payments'],
                sub: [
                  {
                    type: 'normal',
                    cells: [
                      {
                        align: 'right',
                        pieces: [
                          { text: ['outer', 'translations', 'product', 'paid'] },
                          { text: ' (' },
                          { text: ['typeTrans'] },
                          { text: ') ' },
                          { text: ['outer', 'currencySymbol'] },
                        ],
                      },
                      'colspan',
                      'colspan',
                      {
                        align: 'right',
                        pieces: [{ text: ['paid'] }],
                      },
                    ],
                  },
                ],
              },
              {
                type: 'normal',
                cells: conditionalShowItems<any>(showChange)(
                  null,
                  {
                    align: 'right',
                    pieces: [
                      { text: ['translations', 'product', 'change'] },
                      { text: ' ' },
                      { text: ['company', 'outer', 'currencySymbol'] },
                    ],
                  },
                  'colspan',
                  {
                    align: 'right',
                    pieces: [{ text: ['billTable', 'cashChange'] }],
                  },
                ),
              },
              {
                repeat: ['billTable', 'cardPayments'],
                sub: conditionalShowItems<any>(showCardInfo)(
                  {
                    type: 'normal',
                    cells: [
                      {
                        pieces: [
                          {
                            text: ['outer', 'translations', 'payments', 'cardNumber'],
                            meta: { bold: true },
                          },
                          {
                            text: ': ',
                            meta: { bold: true },
                          },
                          {
                            text: ['cardNumber'],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'normal',
                    cells: [
                      {
                        pieces: [
                          {
                            text: ['outer', 'translations', 'payments', 'cardHolder'],
                            meta: { bold: true },
                          },
                          { text: ': ', meta: { bold: true } },
                          { text: ['cardHolder'] },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'normal',
                    cells: [
                      {
                        pieces: [
                          {
                            text: ['outer', 'translations', 'payments', 'referenceNumber'],
                            meta: { bold: true },
                          },
                          { text: ': ', meta: { bold: true } },
                          { text: ['referenceNumber'] },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'normal',
                    cells: [
                      {
                        pieces: [
                          {
                            text: ['outer', 'translations', 'payments', 'authorizationCode'],
                            meta: { bold: true },
                          },
                          { text: ': ', meta: { bold: true } },
                          { text: ['authorizationCode'] },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'normal',
                    cells: [
                      {
                        pieces: [
                          {
                            text: ['outer', 'translations', 'payments', 'cardType'],
                            meta: { bold: true },
                          },
                          { text: ': ', meta: { bold: true } },
                          { text: ['cardType'] },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'normal',
                    cells: [
                      {
                        pieces: [
                          {
                            text: ['outer', 'translations', 'payments', 'paid'],
                            meta: { bold: true },
                          },
                          { text: ': ', meta: { bold: true } },
                          { text: ['paidWithCurrency'] },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'sectionStart',
                    cells: [{ pieces: [] }],
                  },
                ),
              },
            )
          ],
        },
        { type: 'text', pieces: [{ text: ['notes'] }] },
        { type: 'text', pieces: [{ text: ['baseDocuments'] }] },
        { type: 'text', pieces: [] },
        { type: 'text', pieces: [{ text: ['returnSignature'] }] },
        {
          type: 'barcode',
          align: 'center',
          format: 'Code39FullASCII',
          data: ['salesDocumentNumber'],
        },
        { type: 'text', pieces: [] },
        { type: 'text', align: 'center', pieces: [{ text: ['footer'] }] },
        { type: 'text', pieces: [] },
      ];
      return schema;
    },
  );
}

export const getDefaultReceiptTemplateFor = {
  salesReceipt: createDefaultTemplateSelector(true),
  giftReceipt: createDefaultTemplateSelector(false),
};
