import React from 'react';
import { Button } from 'react-bootstrap';
import './index.scss';

import Icon from 'components/Icon';
import BottomPopup from 'containers/BottomPopup';

import { Props } from './types';

const IconButton: React.FC<Props> = ({
  children,
  text,
  shortCut,
  className,
  icons = [],
  action,
  useSearchSvg = false,
  showOtherFunctions = false,
  showSaveAsFunctions = false,
  ...props
}) => {
  if (showOtherFunctions) {
    return (
      <Button
        onClick={() => action?.()}
        className={`iconbutton ${className ?? ''}`}
        {...props}
      >
        {shortCut &&
          (icons?.length === 0 ? (
            <span className="text-center">{shortCut}</span>
          ) : (
            <span className="shortcut-style">{shortCut}</span>
          ))}
        <BottomPopup isSaveAs={false} />
      </Button>
    );
  }
  if (showSaveAsFunctions) {
    return (
      <Button
        onClick={() => action?.()}
        className={`iconbutton ${className ?? ''}`}
        {...props}
      >
        {shortCut &&
          (icons?.length === 0 ? (
            <span className="text-center">{shortCut}</span>
          ) : (
            <span className="shortcut-style">{shortCut}</span>
          ))}
        <BottomPopup isSaveAs={true} />
      </Button>
    );
  }
  return (
    <Button
      onClick={() => action?.()}
      className={`iconbutton ${className ?? ''}`}
      {...props}
    >
      {useSearchSvg && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 32.24800109863281 32.24800109863281"
          xmlns="http://www.w3.org/2000/svg"
          fill="#cccccc"
          className="flip-horizontal"
        >
          <g>
            <path d="M 19,0C 11.82,0, 6,5.82, 6,13c0,3.090, 1.084,5.926, 2.884,8.158l-8.592,8.592c-0.54,0.54-0.54,1.418,0,1.958 c 0.54,0.54, 1.418,0.54, 1.958,0l 8.592-8.592C 13.074,24.916, 15.91,26, 19,26c 7.18,0, 13-5.82, 13-13S 26.18,0, 19,0z M 19,24 C 12.934,24, 8,19.066, 8,13S 12.934,2, 19,2S 30,6.934, 30,13S 25.066,24, 19,24z" />
          </g>
        </svg>
      )}
      {shortCut &&
        (icons.length === 0 ? (
          <span className="text-center">{shortCut}</span>
        ) : (
          <span className="shortcut-style">{shortCut}</span>
        ))}
      {(icons.length > 0 || text) && (
        <div
          className={`${
            icons?.length === 1 && !text ? 'd-block' : 'd-flex'
          } h-100 ${
            shortCut && shortCut.length > 0
              ? 'align-items-end'
              : 'align-items-center'
          } ${
            icons.length < 3 ? 'justify-content-end' : 'justify-content-around'
          } ${icons.length === 1 && !text && 'justify-content-center'}`}
        >
          <span className="maintext">{text}</span>

          {icons.map((icon, index) => (
            <span key={icon}>
              <Icon
                className={`medium-size ${icons.length === 2 ? 'mr-1' : ''}`}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                name={icon}
              />
            </span>
          ))}
        </div>
      )}
      {children}
    </Button>
  );
};

export default IconButton;
