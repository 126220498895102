import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import {
  Box,
  Button,
  FormControlLabel,
  Input,
  MenuItem,
  Select,
  Switch,
  Table,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getAllCafaEntries, getCafaEntry } from 'reducers/cafaConfigs';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import UIButton from 'components/UIElements/UIButton';

import { soundProviders } from './SoundProviders';
import { RootState } from 'reducers';

const events = [
  'scan/success/product',
  'scan/success/customer',
  'scan/failure/multiple',
  'scan/failure/none',
];

export const SoundEventConfiguration = () => {
  const { t } = useTranslation('audio');
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const savedConfig = useSelector(getSoundConfig);
  const [value, setValue] = useState(savedConfig);
  useEffect(() => setValue(savedConfig), [savedConfig]);
  const changed = !R.equals(savedConfig, value);
  const dispatch = useDispatch();
  const save = () => {
    Object.entries(value).forEach(([k, v]) => {
      if (!R.equals(v, savedConfig[k])) {
        dispatch(
          saveCafaConfig({
            integrationType: 'soundBindings',
            integrationName: k,
            config: v,
            level: { level: 'Company', id: '' },
          }),
        );
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const enabled = useSelector(getIsSoundEnabled);
  const toggle = () =>
    dispatch(
      saveCafaConfig({
        integrationType: '',
        integrationName: 'soundEnabled',
        config: { enabled: !enabled },
        level: { level: 'Company', id: '' },
      }),
    );
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1} component="h3">
          {t('config.title')}
        </Box>
        <UIButton text={t('common:save')} action={save} disabled={!changed} />
      </Box>

      <FormControlLabel
        control={<Switch checked={enabled} onClick={toggle} />}
        label={t('config.globalToggle')}
        labelPlacement="end"
      />

      <Table>
        {events.map(event => {
          const bindingConfig = value?.[event];
          const { provider: providerID, config } = bindingConfig ?? {};
          const provider = soundProviders?.[providerID];

          return (
            <tr key={event}>
              <th>
                {t('config.event', { context: event })}
                <Button
                  disabled={!provider}
                  onClick={() => provider?.playSound(config)}
                >
                  {t('config.playSound')}
                </Button>
              </th>
              <td>
                <Select
                  value={providerID ?? '-'}
                  onChange={e =>
                    setValue(R.assocPath([event, 'provider'], e.target.value))
                  }
                >
                  <MenuItem value="-">-</MenuItem>
                  {Object.entries(soundProviders).map(([k, p]) => (
                    <MenuItem value={k} key={k}>
                      {t(`config.providers.${k}.name`)}
                    </MenuItem>
                  ))}
                </Select>
                {(() => {
                  const Input = provider?.SoundInput;
                  if (Input) {
                    return (
                      <Input
                        value={config}
                        setValue={v => {
                          setValue(R.assocPath([event, 'config'], v));
                          provider?.onConfigUpdated?.(v);
                        }}
                      />
                    );
                  }
                  return null;
                })()}
              </td>
            </tr>
          );
        })}
      </Table>
    </>
  );
};

const getIsSoundEnabled = createSelector(
  getCafaEntry<any, any, { enabled: boolean }>('soundEnabled'),
  entry => !!entry?.value.enabled,
);

const getSoundBindings = createSelector(
  (state: RootState) => getAllCafaEntries(state),
  entries => entries.filter(e => e.type === 'soundBindings'),
);

export const getSoundConfig = createSelector(
  getIsSoundEnabled,
  getSoundBindings,
  (enabled, bindings) => {
    return R.fromPairs(bindings.map(bind => [bind.name, bind.value])) as Record<string, {config:any, provider:string}>;
  },
);
const getSoundBindingForEvent = (event: string) => state => {
  const enabled = getIsSoundEnabled(state);
  if (!enabled) return null;

  const { value = {} } =
    getCafaEntry<any, any, { type: string; config: any }>(
      event,
      'soundBindings',
    )(state) ?? {};

  if (!('provider' in value)) return null;
  return value as { provider: string; config: any };
};

export const playSoundEvent = (
  event: typeof events[number],
): ThunkAction<any, any, any, Action> => async (dispatch, getState) => {
  const binding = getSoundBindingForEvent(event)(getState());
  if (!binding) return;

  const { provider, config } = binding;
  soundProviders[provider]?.playSound(config);
};

export const useSounds = () => {
  const conf = useSelector(getSoundConfig);
  const enabled = useSelector(getIsSoundEnabled);

  useEffect(() => {
    Object.entries(conf).forEach(([event, config]) => {
      if (config.provider in soundProviders) {
        // Only preload existing configs
        if (config.config) {
          soundProviders[config.provider].onConfigUpdated?.(config.config);
        }
      }
    });
  }, [enabled, conf]);
};
