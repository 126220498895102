import React, { useState } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import { addError, addSuccess } from 'actions/Error';
import { integer, positive } from 'components/FieldTypes/formatters';

import './configuration.scss';
import { Company, WbuPosPlugin } from '.';

export const CompanyConfiguration: Required<
  WbuPosPlugin
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const [config, setConfig] = useState<Company | undefined>(current);
  const dispatch = useDispatch();

  const saveConfig = () => {
    if (!config) {
      dispatch(addError('Configuration is empty'));
      return;
    }
    save(config);
    dispatch(addSuccess('Configuration saved'));
  };

  const onChangeProp = (formatter = R.identity) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { name, type, value, checked },
    } = event;
    const parsedValue = type === 'checkbox' ? checked : value;
    setConfig(R.assoc(name, formatter(parsedValue)));
  };

  return (
    <div className="config-content">
      <div>
        <Typography variant="h6">Customer search</Typography>
        <FormControlLabel
          control={
            <Checkbox
              name="noSelectOnEnter"
              value={config?.noSelectOnEnter}
              checked={config?.noSelectOnEnter}
              onChange={onChangeProp()}
            />
          }
          label="Do not select customer when Enter is pressed if only one result was found"
        />
      </div>
      <div>
        <Typography variant="h6" className="title">
          DSC membership
        </Typography>
        <TextField
          name="plugin_dsc_membership_return_days"
          value={config?.plugin_dsc_membership_return_days}
          onChange={onChangeProp(R.pipe(integer, positive, Number))}
          variant="outlined"
          label="Return period"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">days</InputAdornment>,
          }}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        className="save-button"
        onClick={saveConfig}
      >
        Save
      </Button>
    </div>
  );
};
