import { isInt } from 'validator';

import { NETS_CONST } from 'paymentIntegrations/netsCloud/types';

export const isTerminal = terminalId =>
  terminalId && terminalId.length && isInt(terminalId.toString());

type FormShapeItem = {
  id: string;
  type: string;
  options?: (string | Record<'name' | 'value', string | number>)[];
  validate?: (
    val: string,
  ) => null | { message: string; dict?: Record<string, string> };
};

type FormShape = Record<string, FormShapeItem>;

export const netsFields: FormShape = {
  environment: {
    id: 'environment',
    type: 'select',
    options: Object.values(NETS_CONST.ENV_TYPES),
  },
  username: {
    id: 'username',
    type: 'text',
    validate: (val: string) =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'username' } },
  },
  password: {
    id: 'password',
    type: 'password',
    validate: val =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'password' } },
  },
  terminal: {
    id: 'terminal',
    type: 'select',
    options: [{ name: 'selectTerminal', value: '' }],
    validate: val =>
      // eslint-disable-next-line no-nested-ternary
      val?.length
        ? isTerminal(val)
          ? null
          : { message: 'invalidTerminal' }
        : { message: 'isMissing', dict: { field: 'terminal' } },
  },
};

const askAlwaysNeverOptions: Record<'name' | 'value', string>[] = Object.values(
  NETS_CONST.CONFIG.OPTIONS,
).map(v => ({
  name: v,
  value: v,
}));

export const customConfiguration: FormShape = {
  [NETS_CONST.CONFIG.PIN]: {
    id: NETS_CONST.CONFIG.PIN,
    type: 'select',
    options: askAlwaysNeverOptions,
  },
  [NETS_CONST.CONFIG.PRINT.MERCHANT]: {
    id: NETS_CONST.CONFIG.PRINT.MERCHANT,
    type: 'select',
    options: askAlwaysNeverOptions,
  },
  [NETS_CONST.CONFIG.PRINT.CUSTOMER]: {
    id: NETS_CONST.CONFIG.PRINT.CUSTOMER,
    type: 'select',
    options: askAlwaysNeverOptions,
  },
};
