import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Table } from 'react-bootstrap';

import UIButton from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import InputField from 'components/FieldTypes/InputField';
import Loader from 'components/Loader';
import {
  getIsDefaultCustomer,
  getSelectedCustomer,
} from 'reducers/customerSearch';

import { CFJCVehicle, createCFJCVehicle, getVehicles } from '../services/CFCJ';

import styles from './CfjcPopup.module.scss';

const fields: Record<
  string,
  {
    formatter: (newVal: string, oldVal: string) => string;
    validator: (val: string) => boolean;
    finished: (val: string) => boolean;
  }
> = {
  vin: {
    formatter: (newVal, oldVal) => {
      return newVal
        .toUpperCase()
        .replace(/[^\dABCDEFGHJKLMNPRSTUVWXYZ]/, '')
        .slice(0, 17);
    },
    validator: v => {
      if (v.length !== 17) return false;
      const parseDigit = n =>
        (('123456789' + 'ABCDEFGH_' + 'JKLMN_P_R' + '_STUVWXYZ').indexOf(n) %
          9) +
        1;

      const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
      const checksum =
        v
          .split('')
          .map(parseDigit)
          .map((v, i) => weights[i] * v)
          .reduce((a, b) => a + b, 0) % 11;
      const checksumChar = checksum === 10 ? 'X' : `${checksum}`;
      return checksumChar === v[8];
    },
    finished: v => 17 <= v.length,
  },
  stockNo: {
    formatter: (newVal, oldVal) => {
      const n = newVal.replace(/[^-A-Z\d]/, '');
      if (n.length > oldVal.length) {
        if (n.length === 2) return `${n}-`;
        if (n.length === 6) return `${n}-`;
      }
      return n;
    },
    validator: val => {
      const [a, b, c] = val.split('-');
      return /^\d\d$/.test(a) && /^[A-Z]\d\d$/.test(b) && /^\d+$/.test(c);
    },
    finished: val => 8 <= val.length,
  },
  year: {
    formatter: (newVal, oldVal) => {
      return newVal.replace(/\D/, '').slice(0, 4);
    },
    validator: a => a.length === 4 && Number(a) <= new Date().getFullYear(),
    finished: a => a.length === 4,
  },
  make: {
    formatter: (newVal, oldVal) => {
      return newVal.slice(0, 30);
    },
    validator: a => 1 <= a.length && a.length <= 30,
    finished: a => 1 <= a.length,
  },
  model: {
    formatter: (newVal, oldVal) => {
      return newVal.slice(0, 30);
    },
    validator: a => 1 <= a.length && a.length <= 30,
    finished: a => 1 <= a.length,
  },
};

export const CarsTableSelect = ({ cars, onSelect, loading }) => {
  return (
    <div data-testid="pnp-cars-table-select">
      <Loader show={loading} loadingText="Waiting for API response" block>
        <Table size="sm">
          <thead>
            <tr>
              <th>Select</th>
              <th>vin</th>
              <th>
                year <br />
                make <br />
                model <br />
              </th>
              <th>
                stockNo <br />
                stockDate <br />
                stockId <br />
              </th>
            </tr>
          </thead>
          <tbody>
            {((r: any): r is Error => r.message)(cars) ? (
              <tr style={{ color: 'red' }}>
                <td colSpan={10}>{cars.message}</td>
              </tr>
            ) : (
              cars.map(car => (
                <tr data-testid="car-row" data-test-key={car.vin}>
                  <td>
                    <UIButton
                      text={
                        // eslint-disable-next-line no-nested-ternary
                        !car.vin
                          ? 'Missing VIN'
                          : !car.stock.stockNo
                            ? 'Missing stock number'
                            : 'Select'
                      }
                      disabled={!car.vin || !car.stock.stockNo}
                      action={() => onSelect(car)}
                      data-testid="select-btn"
                    />
                  </td>
                  <td data-testid="vin">{car.vin}</td>
                  <td>
                    <span data-testid="year">{car.year}</span>
                    <br />
                    <span data-testid="make">{car.make}</span>
                    <br />
                    <span data-testid="model">{car.model}</span>
                    <br />
                  </td>
                  <td>
                    <span data-testid="stock-no">{car.stock.stockNo}</span>
                    <br />
                    <span data-testid="stock-date">{car.stock.stockDate}</span>
                    <br />
                    <span data-testid="stock-id">{car.stock.stockId}</span>
                    <br />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Loader>
    </div>
  );
};

const CarInput = ({
  value,
  lock,
  onChange = () => { },
  onSelectNew,
}: {
  value: CFJCVehicle;
  lock: CFJCVehicle;
  onChange?: (newValue: CFJCVehicle | ((CFJCVehicle) => CFJCVehicle)) => void;
  onSelectNew?: () => void;
}) => {
  const onChangeOf = (key: keyof CFJCVehicle) => e => {
    onChange({ ...value, [key]: e.target.value });
  };
  const onChangeOfStock = (key: keyof CFJCVehicle['stock']) => e => {
    onChangeOf('stock')({ ...value.stock, [key]: e.target.value });
  };
  return (
    <div data-testid="car-input">
      <div style={{ display: 'flex' }}>
        <InputField
          style={{ flexGrow: 2 }}
          title="vin"
          // formatter={fields.vin.formatter}
          isValid={fields.vin.validator(value.vin)}
          isInvalid={
            fields.vin.finished(value.vin) && !fields.vin.validator(value.vin)
          }
          onChange={onChangeOf('vin')}
          value={value.vin}
          disabled={true}
          data-testid="vin"
        />
        <InputField
          style={{ flexGrow: 1 }}
          title="stockNo"
          // formatter={fields.stockNo.formatter}
          isValid={fields.stockNo.validator(value.stock.stockNo)}
          isInvalid={
            fields.stockNo.finished(value.stock.stockNo) &&
            !fields.stockNo.validator(value.stock.stockNo)
          }
          onChange={onChangeOfStock('stockNo')}
          value={value.stock.stockNo}
          disabled={true}
          data-testid="stock-no"
        />
      </div>
      <InputField
        style={{ flexBasis: 0 }}
        title="year"
        // formatter={fields.year.formatter}
        isValid={fields.year.validator(value.year)}
        isInvalid={
          fields.year.finished(value.year) && !fields.year.validator(value.year)
        }
        onChange={onChangeOf('year')}
        value={value.year}
        disabled={lock.year}
        data-testid="year"
      />
      <InputField
        style={{ flexBasis: 0 }}
        title="make"
        // formatter={fields.make.formatter}
        isValid={fields.make.validator(value.make)}
        isInvalid={
          fields.make.finished(value.make) && !fields.make.validator(value.make)
        }
        onChange={onChangeOf('make')}
        value={value.make}
        disabled={lock.make}
        data-testid="make"
      />
      <InputField
        style={{ flexBasis: 0 }}
        title="model"
        // formatter={fields.model.formatter}
        isValid={fields.model.validator(value.model)}
        isInvalid={
          fields.model.finished(value.model) &&
          !fields.model.validator(value.model)
        }
        onChange={onChangeOf('model')}
        value={value.model}
        disabled={lock.model}
        data-testid="model"
      />
      {onSelectNew ? (
        <UIButton
          data-testid="return-btn"
          text="Return to search"
          action={onSelectNew}
        />
      ) : null}
    </div>
  );
};

const CfjcVehicleSearch = ({
  vehicle,
  setVehicle,
  current,
}: {
  vehicle: CFJCVehicle;
  setVehicle: (a: React.SetStateAction<CFJCVehicle>) => void;
  /** The current vehicle - to autoselect the vehicle on edit */
  current?: CFJCVehicle;
}) => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<{
    search: string | null;
    results: CFJCVehicle[] | Error;
  }>({ search: null, results: new Error('Perform a search') });

  const dispatch = useDispatch();
  const doSearch = () => {
    if (loading) return;
    setLoading(true);
    ((dispatch(getVehicles(search)) as any) as ReturnType<
      ReturnType<typeof getVehicles>
    >)
      .then(results => setResults(res => ({ ...res, results })))
      .catch(err => setResults(res => ({ ...res, results: err })))
      .finally(() => setLoading(false));
  };

  const [selectedEntry, setSelectedEntry] = useState<CFJCVehicle | undefined>(
    undefined,
  );
  useEffect(() => {
    if (selectedEntry) {
      setVehicle(selectedEntry);
    }
  }, [selectedEntry]);

  // If have existing data, autoselect the same vehicle entry
  useEffect(() => {
    if (current) {
      setLoading(true);
      ((dispatch(getVehicles(current.vin)) as any) as ReturnType<
        ReturnType<typeof getVehicles>
      >)
        .then(results => {
          setResults(res => ({ ...res, results }));
          setSelectedEntry(results.find(res => res.vin === current.vin));
        })
        .catch(err => setResults(res => ({ ...res, results: err })))
        .finally(() => setLoading(false));
    }
  }, [current]);

  const [notes, setNotes] = useState('');
  if (selectedEntry) {
    return (
      <CarInput
        value={vehicle}
        lock={selectedEntry || createCFJCVehicle()}
        onChange={setVehicle}
        onSelectNew={() => setSelectedEntry(undefined)}
      />
    );
  }
  return (
    <>
      <InputField
        disabled={loading}
        value={search}
        onChange={e => setSearch(e.target.value)}
        title="Search for vehicles"
        appendTitle={<UIButton text="Search" action={doSearch} />}
        onSubmit={doSearch}
        autoFocus
        data-testid="search-input"
      />
      <CarsTableSelect
        cars={results?.results}
        loading={loading}
        onSelect={c => {
          setSelectedEntry(c);
        }}
      />
    </>
  );
};

const CarSideInput = ({ value, onChange, valid }) => {
  const options = [
    'Passenger side front',
    'Passenger side back',
    'Driver side front',
    'Driver side back',
  ];
  return (
    <InputField
      title="Car side"
      onChange={onChange}
      value={value}
      type="select"
      valid={valid}
      options={[''].concat(options)}
      isValid={options.includes(value)}
      data-testid="car-side-input"
    />
  );
};

const CylinderInput = ({ value, onChange, valid }) => {
  const options = [3, 4, 5, 6, 8, 10, 12].map(String);
  return (
    <InputField
      onChange={onChange}
      value={value}
      title="Cylinders"
      type="select"
      valid={valid}
      options={[''].concat(options)}
      isValid={options.includes(value)}
      data-testid="cylinder-input"
    />
  );
};

const CustomerInput = ({ value, onChange, disabled = false, valid }) => {
  return (
    <InputField
      onChange={onChange}
      value={value}
      disabled={disabled}
      valid={valid}
      title="First and last name"
      data-testid="first-and-last-name"
    />
  );
};
const Checkmark = ({ show }) => {
  return show ? (
    <span style={{ color: 'lime', fontWeight: 'bold' }}>✔️</span>
  ) : null;
};
export const CfjcRequirements = ({
  requirements,
  isExchange = false,
  /** The current {@link attributes.cfjcData} attribute - to repopulate the data on edit */
  current,
  resolve = ({ car, cylinder, carSide, customer }) => { },
  reject = a => { },
}) => {
  const defaultCustomer = useSelector(getIsDefaultCustomer);
  const selectedCustomer = useSelector(getSelectedCustomer);
  const customerName =
    selectedCustomer.customerType === 'PERSON'
      ? `${selectedCustomer.firstName} ${selectedCustomer.lastName}`
      : selectedCustomer.fullName;
  const customerOverride = defaultCustomer ? undefined : customerName;

  const requireVin = requirements.has('vin');
  const requireCylinder = requirements.has('cylinder');
  const requireCarSide = requirements.has('carSide');
  const requireCustomer = requirements.has('customer');

  const {
    currentVehicle,
    currentCylinder,
    currentCarSide,
    currentCustomer,
  } = useMemo(() => {
    try {
      const data = JSON.parse(current as string);
      return {
        currentVehicle: createCFJCVehicle(data),
        currentCylinder: data[5],
        currentCarSide: data[6],
        currentCustomer: data[7],
      };
    } catch (e) {
      return {};
    }
  }, [current]);
  const [vehicle, setVehicle] = useState<CFJCVehicle>(
    currentVehicle ?? createCFJCVehicle(),
  );
  const [cylinder, setCylinder] = useState(currentCylinder ?? '');
  const [carSide, setCarSide] = useState(currentCarSide ?? '');
  const [customer, setCustomer] = useState(
    customerOverride ?? currentCustomer ?? '',
  );
  const startRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const validVehicle = [
    vehicle.vin,
    vehicle.stock.stockNo,
    vehicle.year,
    vehicle.make,
    vehicle.model,
  ].every(item => item && 0 < item.length);
  // fields.vin.validator(vehicle.vin) &&
  // (fields.stockNo.validator(vehicle.stock.stockNo) ||
  //   vehicle.stock.stockNo === '-') &&
  // fields.year.validator(vehicle.year) &&
  // fields.make.validator(vehicle.make) &&
  // fields.model.validator(vehicle.model);

  const validCustomer = customer.length > 0;
  const validCylinder = cylinder?.length > 0;
  const validCarSide = carSide?.length > 0;
  const requirementsFulfilled =
    !(requireCustomer && !validCustomer) &&
    !(requireVin && !validVehicle) &&
    !(requireCylinder && !validCylinder) &&
    !(requireCarSide && !validCarSide);

  return (
    <div data-testid="cfjc-modal" ref={startRef} className={styles.modal}>
      <Modal.Title
        className="modal-header"
        style={{ display: 'flex', position: 'static' }}
      >
        <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
          Input car details
        </span>
        <span style={{ flexGrow: 1 }} />
        <UIButton
          text="SAVE"
          disabled={!requirementsFulfilled}
          action={() => {
            resolve({ car: vehicle, cylinder, carSide, customer });
            dispatch(previousModalPage());
          }}
          data-testid="save-btn"
        />
        <CloseButton
          action={() => {
            reject(new Error('User cancelled'));
            dispatch(previousModalPage());
          }}
        />
      </Modal.Title>
      {isExchange ? <p style={{ margin: "15px 0 0 15px" }}>Please select card details for the new BUILDER CAR SALES</p> : null}
      <Modal.Body className={styles.body}>
        <style>{`.modal-dialog { max-width: 1400px; margin: 2em; }`}</style>

        {requireVin ? (
          <div className={styles.search}>
            <CfjcVehicleSearch
              vehicle={vehicle}
              setVehicle={v => {
                setVehicle(v);
                startRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
              current={currentVehicle}
            />
          </div>
        ) : null}

        {requireCustomer ? (
          <div className={styles.customer}>
            <CustomerInput
              value={customer}
              onChange={e => setCustomer(e.target.value)}
              valid={validCustomer}
            />
          </div>
        ) : null}
        {requireCylinder ? (
          <div className={styles.cylinder}>
            <CylinderInput
              value={cylinder}
              onChange={e => setCylinder(e.target.value)}
              valid={validCylinder}
            />
          </div>
        ) : null}
        {requireCarSide ? (
          <div className={styles.carSide}>
            <CarSideInput
              value={carSide}
              onChange={e => setCarSide(e.target.value)}
              valid={validCarSide}
            />
          </div>
        ) : null}
      </Modal.Body>
    </div>
  );
};
