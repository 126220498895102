import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  Typography,
  DialogTitle,
  Box,
  styled,
} from '@material-ui/core';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import {
  applySaleDiscount,
  setDiscountReason,
  updateOrderDiscount,
  clearGlobalDiscountReason,
  clearDiscountReason,
} from 'actions/ShoppingCart';
import {
  getDiscountReasonByOrderIndex,
  getGlobalDiscountReason,
} from 'reducers/ShoppingCart';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { Order } from 'types/ShoppingCart';
import { ReasonCode } from 'types/ReasonCodes';

interface Props {
  reasonCodes: ReasonCode[];
  amount?: number;
  percentage?: number;
  order?: Order;
  value?: number;
  resolve: (reason?: ReasonCode) => void;
  onClose: () => void;
}

const ScrollableList = styled(List)({
  height: '50vh',
  overflowY: 'scroll',
});

const StyledListItem = styled(ListItem)({
  '&:hover': {
    background: 'lightblue',
  },
});

const DiscountPopup: React.FC<Props> = ({
  resolve,
  reasonCodes,
  amount,
  percentage,
  order,
  value,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('discountPopup');
  const prevSelectedReason = useSelector<unknown, ReasonCode | undefined>(
    getDiscountReasonByOrderIndex(order?.orderIndex),
  );

  const globalReason = useSelector<unknown, ReasonCode | undefined>(
    getGlobalDiscountReason,
  );

  const formattedGlobalReason =
    Number(order?.totalDiscount) > 0 ? globalReason : null;

  const [selectedReasonID, setSelectedReasonID] = useState(
    prevSelectedReason?.reasonID ?? formattedGlobalReason?.reasonID ?? null,
  );

  const setReason = async (reasonID: number | null) => {
    const reason = reasonCodes.find(r => r.reasonID === reasonID);
    if (Number(value) === 0 && order) {
      dispatch(clearDiscountReason(order.orderIndex));
    }
    if (globalReason && !order && !value) {
      dispatch(clearGlobalDiscountReason());
    }

    if (amount || percentage) {
      await dispatch(applySaleDiscount({ amount, percentage }));
    }

    dispatch(setDiscountReason(reason, order?.orderIndex));

    if (order && value) {
      await dispatch(updateOrderDiscount(Number(value), order?.orderIndex));
    }

    resolve(reason);
    dispatch(closeModalPage());
  };

  useShortcut(
    'Enter',
    () => setReason(selectedReasonID || reasonCodes?.[0].reasonID),
    35,
  );

  useEffect(() => {
    if (reasonCodes.length === 1) {
      setReason(reasonCodes[0].reasonID);
    }
  }, [reasonCodes]);

  return (
    <div data-testid="discount-reason-codes-modal">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h5">{t('title')}</Typography>
        </DialogTitle>
        <Box display="flex" marginRight="10px">
          <SaveButton
            action={() => setReason(selectedReasonID)}
            disabled={!selectedReasonID}
            data-testid="save-btn"
          />
          <CloseButton data-testid="close-btn" action={onClose} />
        </Box>
      </Box>
      <ScrollableList>
        {useMemo(
          () =>
            reasonCodes.map(({ name, reasonID }) => (
              <StyledListItem
                button
                className="dark_list_item"
                onClick={() => setSelectedReasonID(reasonID)}
                key={reasonID}
                data-testid="reason-code"
                data-test-key={reasonID}
              >
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="h6">{name}</Typography>
                  {selectedReasonID === reasonID ? (
                    <span
                      style={{ color: '#333' }}
                      className="icon_check fa-2x dark_icon"
                    />
                  ) : null}
                </Box>
              </StyledListItem>
            )),
          [reasonCodes, selectedReasonID],
        )}
      </ScrollableList>
    </div>
  );
};

export default DiscountPopup;
