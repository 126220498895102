type WindowFetchOverrideType = (
  WindowFetchType,
) => (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;

const overrideDict: Record<string, WindowFetchOverrideType> = {};

export const addWindowFetchOverride = (
  name: string,
  override: WindowFetchOverrideType,
) => {
  overrideDict[name] = override;
};

export const removeWindowFetchOverride = (name: string) => {
  delete overrideDict[name];
};

const orig = window.fetch.bind(window);
window.fetch = (...params) =>
  Object.values(overrideDict).reduce((acc, next) => next(acc), orig)(...params);
