import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getPaymentTypes } from 'services/ErplyAPI/payment';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { Customer } from 'types/Customer';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { RootState } from 'reducers';
import { IssuedCoupon } from 'types/Coupon';

import { Voucher } from '../NSWVouchers/types';

import {
  SET_GLOBAL_ENTITY_BY_TYPE,
  SET_PRODUCTS_STATE,
  SET_CUSTOMER_LAST_USED_ENTITIES,
  RESET_STATE,
  DISPLAY_PREVIOUS_ENTITIES,
  SET_FIT_ID,
  SET_PAYMENT_TYPE_IDS,
  RESET_VOUCHERS,
  SET_VOUCHERS,
  SET_CUSTOMER_ID_TAF,
  RESET_CUSTOMER_ID_TAF,
  SET_PRINT_SECOND_RECEIPT,
  SET_COUPONS,
  SET_QFF_CARD_NUMBER,
} from './actionTypes';
import {
  entityObject,
  getMedicalEntitySelected,
  getSchoolEntitySelected,
  getTafReduxState,
} from './reducers';

export const setPaymentTypeIDs = (payload: {
  paymentTypeID: string | null;
  invoicePaymentTypeID: string | null;
}) => ({
  type: SET_PAYMENT_TYPE_IDS,
  payload,
});

export const setCustomerIDTaf = (customerID: number) => {
  return {
    type: SET_CUSTOMER_ID_TAF,
    payload: customerID,
  };
};

export const resetCustomerIDTaf = () => {
  return {
    type: RESET_CUSTOMER_ID_TAF,
  };
};

const setGlobalEntity = (entity: entityObject) => async (
  dispatch,
  getState,
) => {
  const key =
    entity.entityType === 'association' ? 'schoolEntity' : 'medicalEntity';

  // Load shopping cart & product state
  const state = getState();
  const indexes = getProductsInShoppingCart(state).map(o => o.orderIndex);
  const { productsState } = getTafReduxState(state);
  const updatedProducts = { ...productsState };

  // Assign new default entity to all products which do not already have an assignment in that slot
  indexes.forEach(indexValue => {
    if (!updatedProducts[indexValue]?.[key]?.entityID) {
      updatedProducts[indexValue] = {
        ...updatedProducts[indexValue],
        [key]: entity,
      };
    }
  });
  // Update product state & global entity
  await dispatch({ type: SET_GLOBAL_ENTITY_BY_TYPE, payload: entity });
  return dispatch({ type: SET_PRODUCTS_STATE, payload: updatedProducts });
};

const checkIfProductsHaveEntities = () => (dispatch, getState) => {
  const state = getState();
  const { productsState } = getTafReduxState(state);

  const hasMedicalsSelected = Object.values(productsState).some(
    value => value.medicalEntity?.entityID,
  );
  const hasSchoolsSelected = Object.values(productsState).some(
    value => value.schoolEntity?.entityID,
  );

  if (!hasMedicalsSelected) {
    dispatch(
      setGlobalEntity({
        entityType: 'proffesional',
        entityID: undefined,
        entityName: '',
      }),
    );
  }

  if (!hasSchoolsSelected) {
    dispatch(
      setGlobalEntity({
        entityType: 'association',
        entityID: undefined,
        entityName: '',
      }),
    );
  }
};

export const updateProductsState = (
  {
    indexes,
  }: {
    indexes: number[];
  } = { indexes: [] },
) => (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const state = getState();
  const {
    productsState,
    globalSchoolEntity,
    globalMedicalEntity,
  } = getTafReduxState(state);

  const schoolSelected = getSchoolEntitySelected(state);
  const medicalSelected = getMedicalEntitySelected(state);

  const updatedProducts = { ...productsState };

  if (schoolSelected || medicalSelected) {
    indexes.forEach(indexValue => {
      const currentProductEntities = updatedProducts[indexValue];

      if (currentProductEntities) {
        const schoolToAssign = currentProductEntities?.schoolEntity?.entityID
          ? currentProductEntities.schoolEntity
          : globalSchoolEntity;
        const medicalToAssign = currentProductEntities?.medicalEntity?.entityID
          ? currentProductEntities.medicalEntity
          : globalMedicalEntity;

        currentProductEntities.schoolEntity = schoolToAssign;
        currentProductEntities.medicalEntity = medicalToAssign;
        return;
      }

      updatedProducts[indexValue] = {
        schoolEntity: globalSchoolEntity,
        medicalEntity: globalMedicalEntity,
      };
    });

    dispatch({
      type: SET_PRODUCTS_STATE,
      payload: updatedProducts,
    });
  }
};

export const resetEntityInProduct = (
  orderIndex: number,
  entityType: string,
) => (dispatch, getState) => {
  const { productsState } = getTafReduxState(getState());

  productsState[orderIndex][
    entityType === 'association' ? 'schoolEntity' : 'medicalEntity'
  ] = {
    entityID: undefined,
    entityName: '',
    entityType: '',
  };

  dispatch(checkIfProductsHaveEntities());

  dispatch({
    type: SET_PRODUCTS_STATE,
    payload: productsState,
  });
};

export const removeProductInTafState = (orderIndex: number) => (
  dispatch,
  getState,
) => {
  const { productsState } = getTafReduxState(getState());
  delete productsState[orderIndex];
  dispatch(checkIfProductsHaveEntities());

  dispatch({
    type: SET_PRODUCTS_STATE,
    payload: productsState,
  });
};

export const setCustomerLastUsedEntities = (previousEntities: {
  previousAssociations: Customer[];
  previousProffesionals: Customer[];
}) => {
  return {
    type: SET_CUSTOMER_LAST_USED_ENTITIES,
    payload: previousEntities,
  };
};

export const toggleDisplayPreviousEntities = (displayEntities: boolean) => {
  return {
    type: DISPLAY_PREVIOUS_ENTITIES,
    payload: displayEntities,
  };
};

export const resetTafState = () => ({
  type: RESET_STATE,
  payload: null,
});

export const setFitIDInRedux = (id: string) => {
  return {
    type: SET_FIT_ID,
    payload: id,
  };
};

/**
 * Set the given customer as a default medical association.
 * Also assigns this to any products already in the cart that don't have a medical yet
 */
export const setGlobalMedical = (customer: Customer) =>
  setGlobalEntity({
    entityType: 'proffesional',
    entityID: customer.id,
    entityName: customer.fullName ?? customer.companyName,
  });
/**
 *
 * Set the given customer as a default school association.
 * Also assigns this to any products already in the cart that don't have a school yet
 */
export const setGlobalSchool = (customer: Customer) =>
  setGlobalEntity({
    entityType: 'association',
    entityID: customer.id,
    entityName: customer.fullName ?? customer.companyName,
  });

/**
 * Updates the assignment for the given product (by orderIndex)
 */
export const setEntityForOrderIndex = (orderIndex: string, entity) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { productsState } = getTafReduxState(state);

  const typeName =
    entity.entityType === 'association' ? 'schoolEntity' : 'medicalEntity';

  return dispatch({
    type: SET_PRODUCTS_STATE,
    payload: {
      ...productsState,
      [orderIndex]: {
        ...productsState[orderIndex],
        [typeName]: entity,
      },
    },
  });
};

export const setPaymentTypeIDsToRedux = () => async dispatch => {
  const paymentTypes = await getPaymentTypes({});
  const invoicePaymentTypes = await doClientRequest<{
    type: string;
    [key: string]: unknown;
    id: string;
  }>({
    request: 'getInvoicePaymentTypes',
  });
  const isPartnerType = (record: { type: string; [key: string]: unknown }) =>
    record.type.toUpperCase() === 'PARTNER';
  const invoicePaymentTypeID = invoicePaymentTypes.find(isPartnerType)?.id;
  const paymentTypeID = paymentTypes.find(isPartnerType)?.id;

  if (invoicePaymentTypeID && paymentTypeID) {
    dispatch(
      setPaymentTypeIDs({
        invoicePaymentTypeID,
        paymentTypeID: String(paymentTypeID),
      }),
    );
  }
};

export const resetVouchers = () => ({
  type: RESET_VOUCHERS,
});

export const setVouchers = (vouchers: Voucher[]) => ({
  type: SET_VOUCHERS,
  payload: vouchers,
});

export const setPrintSecondReceipt = (step: boolean) => ({
  type: SET_PRINT_SECOND_RECEIPT,
  payload: step,
});

export const setCoupons = (coupons: IssuedCoupon[]) => ({
  type: SET_COUPONS,
  payload: coupons,
});

export const setQffCardNumber = (cardNumber: string | null) => ({
  type: SET_QFF_CARD_NUMBER,
  payload: cardNumber,
});
