import i18next from 'i18next';

export const validateEmail = (email: string): string => {
  const emailRegex = /^[^<>()[\]\\.,;:\s@"]+[\w-.,]+@([\w-]+\.)+[\w-]{2,}$/;
  // Email is empty
  if (email.length === 0) {
    return i18next.t('validation:generic.required');
  }
  // Email missing @
  if (!email.includes('@'))
    return i18next.t('validation:login.emailRequiresAt');
  // Email has spaces
  if (email.includes(' ')) return i18next.t('validation:login.noSpacesInEmail');

  // Split email by @
  const [preAt, postAt, postSecondAt] = email.split('@');
  // There's no symbols prior to @
  if (!preAt) {
    return i18next.t('validation:login.emailFirstHalfMissing');
  }
  // Has two @ within the string
  if (postSecondAt) {
    return i18next.t('validation:login.emailAllowOnlyOneAt');
  }
  // Has no symbols after @
  if (!postAt) {
    return i18next.t('validation:login.emailSecondHalfMissing');
  }
  // Split the after @ part of the email by .
  const [postAtPreDot, postAtPostDot] = postAt.split('.');
  // There must be some text prior to the dot
  if (!postAtPreDot) {
    return i18next.t('validation:login.emailSecondHalfNoSymbolsPriorDot');
  }
  // No symbols specified after dot
  if (!postAtPostDot) {
    return i18next.t('validation:login.emailSecondHalfNeedsDot');
  }
  // After dot part must have at least 2 alphanumeric symbols
  if (!/[\w-]{2,}/.test(postAtPostDot.toLowerCase())) {
    return i18next.t(
      'validation:login.emailSecondHalfLessThanTwoSymbolsAfterDot',
    );
  }
  // Finally check the email by regex to validate for presence of any weird symbols
  if (!emailRegex.test(email.toLowerCase())) {
    return i18next.t('validation:login.invalidEmail');
  }
  return '';
};

export const validateWebsite = (website: string): string => {
  const websiteRegex = /(?:([A-Za-z]+):)?(\/{0,3})[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-{}]*[\w@?^=%&amp;/~+#-{}])??/;
  if (website.length > 0 && !websiteRegex.exec(website)) {
    return i18next.t('validation:login.invalidWebsite');
  }
  return '';
};

const validateText = (text: string): string => {
  if (text.length === 0) {
    return i18next.t('validation:generic.required');
  }
  if (text.length > 100) {
    return i18next.t('validation:generic.maxLength', { max: 100 });
  }
  return '';
};

export const validateFirstName = validateText;
export const validateLastName = validateText;
export const validateCompany = validateText;
export const validatePhone = validateText;

export const validatePassword = (pass: string): string => {
  if (pass.length === 0) {
    return i18next.t('validation:generic.required');
  }
  if (pass.length < 8) {
    return i18next.t('validation:generic.minLength', { min: 8 });
  }
  if (pass.length > 100) {
    return i18next.t('validation:generic.maxLength', { max: 100 });
  }
  return '';
};

export const validateSelect = (val: string): string => {
  // eslint-disable-next-line eqeqeq
  if (val?.replace(/[ -]/g, '').length === 0 || val == '-1') {
    return i18next.t('validation:generic.required');
  }
  return '';
};
