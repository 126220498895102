import React from 'react';
import { useTranslation } from 'react-i18next';

const contactsToDisplay: {
  regions: string[];
  phone?: string;
  mail: string;
}[] = [
  {
    regions: ['us', 'canada'],
    phone: '+1(855) 463-7759',
    mail: 'support@erply.com',
  },
  {
    regions: ['australia', 'newZealand'],
    phone: '+612 6145 0462',
    mail: 'supportaus@erply.com',
  },
  {
    regions: ['europe'],
    phone: '+372 628 0020',
    mail: 'abi@erply.com',
  },
  {
    regions: ['finland', 'sweden', 'denmark'],
    phone: '+358 974 790 284',
    mail: 'tuki@erply.com',
  },
  {
    regions: ['asia', 'africa', 'middleEast'],
    mail: 'support@erply.com',
  },
  {
    regions: ['southAmerica'],
    mail: 'support@erply.com',
  },
];

const ContactDetails = ({ berlinPOSVersion }) => {
  const { t } = useTranslation('contacts');

  return (
    <div className="contacts">
      {contactsToDisplay.map(({ mail, phone, regions }) => (
        <div key={mail} className="state">
          <h6>{regions.map(reg => t(`regions.${reg}`)).join(' & ')}</h6>
          {phone && <a href={`tel:${phone.replace(/[^\d+]/g, '')}`}>{phone}</a>}
          <a
            href={`mailto:${mail}?subject=BerlinPOS${berlinPOSVersion}feedback/question`}
          >
            {mail}
          </a>
        </div>
      ))}
    </div>
  );
};

export default React.memo(ContactDetails);
