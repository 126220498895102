import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Box } from '@material-ui/core';

import { hardLogout } from 'actions/Login';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { fetchClockedInEmployees } from 'actions/clockInOut';
import chpass from 'containers/Forms/ChangePassword/index.module.scss';
import { semanticVersion as version } from 'external_data';
import { getUserLoggedIn } from 'reducers/Login';
import { useSessionExpiration } from 'containers/Header/components/UserMenu/SessionExpiration/SessionExpiration';
import { PluginComponent } from 'plugins';
import Icon from 'components/Icon';
import { useBreakpoints } from 'utils/hooks/UI';

import HeaderLink from '../Link';

import ShortcutsModal from './Shortcuts';
import ContactsModal from './Contacts';

import './UserMenu.scss';
import { getSetting } from 'reducers/configs/settings';

const popperConfig = {
  /* GPU acceleration is achieved by using translate3D,
     which causes text to be rendered unsharply at zoom levels other than 100% */
  modifiers: { computeStyle: { gpuAcceleration: false } },
};
const fullHeight = { height: '100%' };

const UserMenuLink = ({ href, title, bold = false, icon }) => (
  <Dropdown.Item href={href} target="_blank" rel="noopener noreferrer">
    {bold ? <strong>{title}</strong> : <span>{title}</span>}
    {href && <sup style={{ fontFamily: 'FontAwesome' }}>{' \uf08e'}</sup>}
    <Icon
      className="erply-header__icon erply-header__icon--dropdown"
      name={`icon_${icon}`}
    />
  </Dropdown.Item>
);
const UserMenuAction = ({ action, title, bold = false, icon }) => {
  const dispatch = useDispatch();
  return (
    <Dropdown.Item
      onClick={() => dispatch(action)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {bold ? <strong>{title}</strong> : <span>{title}</span>}
      <Icon
        className="erply-header__icon erply-header__icon--dropdown"
        name={`icon_${icon}`}
      />
    </Dropdown.Item>
  );
};

const UserMenuModal = ({ title, modalTitle, bold, icon, children }) => {
  const [show, setShow] = useState(false);

  return (
    <Dropdown.Item as="button" onClick={() => setShow(c => !c)}>
      {bold ? <strong>{title}</strong> : <span>{title}</span>}
      <div onClick={e => e.stopPropagation()}>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          container={document.getElementById('modals')}
          className="erply-header__modal"
        >
          <Modal.Header closeButton>{modalTitle}</Modal.Header>
          <Modal.Body>{children}</Modal.Body>
        </Modal>
      </div>
      <Icon
        className="erply-header__icon erply-header__icon--dropdown"
        name={`icon_${icon}`}
      />
    </Dropdown.Item>
  );
};

const UserMenu = () => {
  const accountLinks = useSelector(getSetting('account_links'));

  const userInfo = useSelector(getUserLoggedIn);

  const { t } = useTranslation('header');

  const [shortExpiration, longExpiration] = useSessionExpiration();

  const isMobileView = !useBreakpoints().sm;

  const getUserDisplayName = user => {
    if (isMobileView) {
      const nameSplit = user.split(' ');
      return `${nameSplit[0].charAt(0)} ${nameSplit[
        nameSplit.length - 1
      ].charAt(0)}`;
    }
    return userInfo.employeeName;
  };

  return (
    <Dropdown
      className="erply-header__dropdown erply-header__dropdown--user"
      alignRight
      data-testid="header-user-menu"
    >
      <Dropdown.Toggle as="div">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={fullHeight}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="end"
            flexDirection="column"
          >
            <HeaderLink
              className="text--yellow font-weight-bold"
              href="#"
              id="signed-in-employee"
              content={getUserDisplayName(userInfo.employeeName)}
            />
            {shortExpiration}
          </Box>
          <div className="d-flex flex-column pl-2">
            <i className="arrow-triangle arrow_triangle-up" />
            <i className="arrow-triangle arrow_triangle-down" />
          </div>
        </Box>
      </Dropdown.Toggle>

      <Dropdown.Menu popperConfig={popperConfig}>
        <PluginComponent hookname="UIUserMenuDropDownMenu">
          <Dropdown.Item
            as="div"
            className="app_version"
            data-testid="app-version"
            key="app-version"
          >
            ERPLY
            <br />
            Brazil v{version}
          </Dropdown.Item>

          {longExpiration}

          {/* What's new */}
          <UserMenuLink
            title={t('menu.new')}
            icon="comment_alt"
            bold
            href="https://wiki.erply.com/category/208"
            data-testid="news"
            key="news"
          />

          {/* Erply manuals */}
          <UserMenuLink
            title={t('menu.manuals')}
            icon="book"
            href="https://wiki.erply.com/"
            data-testid="manuals"
            key="manuals"
          />

          {/* ERPLY Terms of Service */}
          <UserMenuLink
            title={t('menu.TOS')}
            href={accountLinks.terms_of_service}
            data-testid="tos"
            key="tos"
          />

          {/* Keyboard shortcuts */}
          <UserMenuModal
            title={t('menu.shortcuts')}
            modalTitle="Keyboard shortcuts"
            data-testid="shortcuts"
            key="shortcuts"
          >
            <ShortcutsModal />
          </UserMenuModal>

          {/* Contact us */}
          <UserMenuModal
            title={t('menu.contact')}
            icon="contacts_alt"
            modalTitle={t('menu.contact')}
            data-testid="contact"
            key="contact"
          >
            <ContactsModal berlinPOSVersion={userInfo.berlinPOSVersion} />
          </UserMenuModal>

          {/* Change password */}
          <UserMenuAction
            action={openModalPage({
              component: mp.ChangePassword,
              isPopup: true,
              modalClassName: chpass.changePasswordDialog,
            })}
            title={t('menu.changePassword')}
            icon="key_alt"
            data-testid="password"
            key="password"
          />

          {/* Change Pin */}
          <UserMenuAction
            action={openModalPage({
              component: mp.ChangePin,
              isPopup: true,
              modalClassName: chpass.changePasswordDialog,
            })}
            title={t('menu.changePin')}
            icon="calculator_alt"
            data-testid="pin"
            key="pin"
          />

          {/* Clock in/Out */}
          <UserMenuAction
            action={fetchClockedInEmployees()}
            title={t('menu.clockInOut')}
            icon="clock_alt"
            data-testid="clockinout"
            key="clockinout"
          />

          {/* Log out */}
          <UserMenuAction
            action={hardLogout()}
            title={t('menu.logout')}
            bold
            data-testid="logout"
            key="logout"
          />
        </PluginComponent>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default React.memo(UserMenu);
