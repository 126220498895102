import React from 'react';

import { Sales } from 'containers/Forms/RecentSalesList';

import PurchasesList from './PurchasesList';

type Props = {
  done: boolean;
  loading: string;
  name: boolean;
  onMore: () => void;
  sales: Sales;
  showFilter: boolean;
  title: string;
};

const PreviousPurchases: React.FC<Props> = ({
  title,
  sales,
  loading,
  done,
  onMore,
  name,
}) => {
  return (
    <PurchasesList
      title={title}
      showName={name}
      sales={sales}
      loadMore={!done ? onMore : undefined}
      loadingText={loading}
    />
  );
};

export default PreviousPurchases;
