import i18next from 'i18next';

import { PosPlugin } from 'plugins/plugin';
import { createConfirmation } from 'actions/Confirmation';
import { saveAssignment } from 'services/ErplyAPI';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getIsModuleEnabled } from 'reducers/configs/settings';

import reduxReducer, {
  getAssignmentState,
  SET_PRODUCTS_FOR_ASSIGNMENT,
} from './rdx';

const orderAssignment: PosPlugin = {
  id: 'order-assignment',
  name: 'Order assignment',
  keywords: ['order', 'assignment', 'lancaster'],
  info:
    'After an order is created, it will be assigned to an assignment. Module "Assignments - Appliances" or "Assignments - Cars" needs to be enabled in Erply back office.',
  getStatus: state => {
    const appliancesModule = 'appliances';
    const carsModule = 'cars';
    const hasAppliancesModule = getIsModuleEnabled(appliancesModule)(state);
    const hasCarsModule = getIsModuleEnabled(carsModule)(state);

    if (hasAppliancesModule || hasCarsModule) {
      return { type: 'valid', message: 'Ready' };
    }

    return {
      type: 'error',
      message: `Module "Assignments - Appliances" or "Assignments - Cars" needs to be enabled in Erply back office.`,
    };
  },
  reduxReducer,
  onSaveSalesDocument: {
    on: (p, requests) => async (dispatch, getState) => {
      const state = getState();
      const productsInCart = getProductsInShoppingCart(state);

      await dispatch({
        type: SET_PRODUCTS_FOR_ASSIGNMENT,
        payload: productsInCart,
      });

      return requests;
    },
    after: (props, { requests }) => async (dispatch, getState) => {
      const state = getState();
      const warehouseID = getSelectedWarehouseID(state);
      const employeeID = getLoggedInEmployeeID(state);
      const { customerID } = getSelectedCustomer(getState());
      const assignmentState = getAssignmentState(state);
      const { itemsForAssignment } = assignmentState;

      const saveRequest = requests.filter(
        req => req.requestName === 'saveSalesDocument',
      );
      const isOrder = saveRequest[0]?.type === 'ORDER';

      if (isOrder) {
        const askToCreateAssignment = async dispatch => {
          await i18next.loadNamespaces('orderAssignment');
          return new Promise((resolve, reject) => {
            dispatch(
              createConfirmation(resolve, reject, {
                title: i18next.t('orderAssignment:orderAssignment'),
                body: i18next.t('orderAssignment:askForAssigment'),
                confirmText: i18next.t('orderAssignment:accept'),
                cancelText: i18next.t('orderAssignment:refuse'),
              }),
            );
          })
            .then(async () => {
              const params = {
                warehouseID,
                bookerEmployeeID: employeeID,
                customerID,
              };

              itemsForAssignment.forEach((r, i) => {
                Object.assign(params, {
                  // Service ID ?
                  [`itemName${i}`]: r.name,
                  [`productID${i}`]: Number(r.productID),
                  [`amount${i}`]: r.amount,
                  [`price${i}`]: r.basePrice,
                  [`discount${i}`]: r.totalDiscount,
                  [`firstEmployeeID${i}`]: r.employeeID,
                });
              });
              const orderAssignment = await saveAssignment(params);
              return orderAssignment;
            })
            .catch(() => null);
        };

        const orderAssignment = await dispatch(askToCreateAssignment);

        if (orderAssignment?.length && orderAssignment?.[0]?.assignmentID) {
          const showAssignmentConfirmation = async dispatch => {
            await i18next.loadNamespaces('orderAssignment');
            return new Promise((resolve, reject) => {
              dispatch(
                createConfirmation(resolve, reject, {
                  title: i18next.t('orderAssignment:success'),
                  body:
                    orderAssignment.length && orderAssignment[0].assignmentID
                      ? i18next.t('orderAssignment:successfulAssignment', {
                          assignmentID: orderAssignment[0].assignmentID,
                        })
                      : i18next.t('orderAssignment:unsuccessfulAssignment'),
                  confirmText: i18next.t('orderAssignment:ok'),
                }),
              );
            });
          };

          await dispatch(showAssignmentConfirmation);
        }
      }
    },
  },
};

export default orderAssignment;
