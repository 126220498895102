import i18next from 'i18next';

import {
  createConfirmation,
  closeConfirmationByID,
} from 'actions/Confirmation';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { getCafaEntry2 } from 'reducers/cafaConfigs';

import { NETS_CONST, NetsConfig } from '../types';

// Nets functionality allowing the user to use signature instead of PIN
const confirmBypassPin = () => async dispatch =>
  new Promise((resolve, reject) => {
    let id = '';
    setTimeout(() => {
      dispatch(closeConfirmationByID(id));
      reject();
    }, 5000);
    const { payload } = dispatch(
      createConfirmation(resolve, reject, {
        title: i18next.t(`${NETS_CONST.TRANS_PATH}.allowBypassPin.title`),
        body: i18next.t(`${NETS_CONST.TRANS_PATH}.allowBypassPin.body`),
      }),
    );
    // eslint-disable-next-line prefer-destructuring
    id = payload.id;
  })
    .then(() => true)
    .catch(() => false);

const allowBypassPin = () => async (dispatch, getState) => {
  const config = getCafaEntry2<Required<NetsConfig>>(
    NETS_CONST.CAFA_CONFIG_NAME,
    INTEGRATION_TYPES.payment,
  )(getState());

  switch (config[NETS_CONST.CONFIG.PIN]) {
    case NETS_CONST.CONFIG.OPTIONS.ALWAYS:
      return true;
    case NETS_CONST.CONFIG.OPTIONS.ASK:
      return dispatch(confirmBypassPin());
    case NETS_CONST.CONFIG.OPTIONS.NEVER:
    default:
      return false;
  }
};

export default allowBypassPin;
