import { useState } from 'react';
import * as R from 'ramda';

import {
  DisabledOn,
  FIELDS_WITH_DEFAULT_VALUES,
  FormFieldConfiguration,
  FormFieldName,
} from '../../../types';
import { getIsAllowedToBeDisabled, getRequiredApiFields } from '../../../utils';

import {
  CheckField,
  CustomerTypeField,
  GroupField,
  HomeStoreField,
  CountryField,
} from './DefaultValueInputs';

const defaultValueInputs = {
  euCustomerType: CustomerTypeField,
  groupID: GroupField,
  homeStoreID: HomeStoreField,
  country: CountryField,
};

export interface Props {
  field: FormFieldName;
  label: string;
  config: FormFieldConfiguration;
  updateConfig(field: FormFieldName, config: FormFieldConfiguration): void;
}

function useFieldConfiguration({ field, label, config, updateConfig }: Props) {
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(config.label ?? label);

  const resetTitle = () => {
    updateConfig(field, R.dissoc('label', config));
    setTitle(label);
  };

  const saveTitle = () => {
    if (title !== label) {
      updateConfig(field, R.assoc('label', title, config));
    } else {
      resetTitle();
    }
    setEditMode(false);
  };

  const requiredApiFields = getRequiredApiFields();
  const isRequiredByApi = requiredApiFields.includes(field);
  const isAllowedToDisable = getIsAllowedToBeDisabled(
    field,
    config.defaultValue,
  );
  const supportsDefaultValue = FIELDS_WITH_DEFAULT_VALUES.includes(field);

  function updateDefaultValue(val: string | number) {
    const updatedConfig: Partial<FormFieldConfiguration> = {
      // omit field entirely instead of saving empty string to CAFA
      defaultValue: val || undefined,
    };

    if (isRequiredByApi && !val) {
      updatedConfig.enabled = true;
      updatedConfig.required = true;
      updatedConfig.disabledOn = DisabledOn.NEVER;
    }

    updateConfig(field, R.mergeLeft(updatedConfig, config));
  }

  return {
    editMode: [editMode, setEditMode] as const,
    title: [
      title,
      { set: setTitle, reset: resetTitle, save: saveTitle },
    ] as const,
    defaultValue: {
      Input: defaultValueInputs[field] ?? CheckField,
      update: updateDefaultValue,
      isSupported: supportsDefaultValue,
      isDisableable: isAllowedToDisable,
    },
  };
}

export default useFieldConfiguration;
