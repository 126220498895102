/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import InputField from 'components/FieldTypes/InputField';
import { PosPlugin } from 'plugins/plugin';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import {
  getPluginSelector,
  getPluginConfiguration,
  getEnabledPlugins,
} from 'reducers/Plugins';
import { addSuccess, addError } from 'actions/Error';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getLastSalesDocument } from 'reducers/sales';
import { getSalesDocuments } from 'services/ErplyAPI';
import { getPermanentPdfUrl } from 'services/SalesAPI';
import { pluginID } from 'plugins/oneAcrePergamonFiscal/constants';
import { getTemplateId } from 'plugins/oneAcrePergamonFiscal/configuration';
import { shouldSaveSaleDocToFiscal } from 'plugins/oneAcrePergamonFiscal/utils';
import { round } from 'utils';

import {
  SEND_SMS,
  SET_PHONE_NUMBER_FOR_SMS,
  SET_SALE_ITEMS,
} from './actionTypes';
import { defineTestParams, formatDate, getRowsData } from './utils';

const telerivetErplyServerURL = 'https://telerivet.erply.com/';

const MyInput = ({ ...props }) => (
  <InputField
    size="lg"
    className={classNames([styles.formInput, styles.mediumTitle])}
    value={props?.value}
    onChange={props?.onChange}
    {...props}
  />
);

const getTelerivetSMSState = state =>
  getPluginSelector<{
    sendSMS: boolean;
    phoneNumberForSMS: string;
    saleItems: [];
  }>('sms-telerivet')(n => n)(state);

const sendRequestToTelerivet = (url, { apiKey, params }) => {
  return new Promise((resolve, reject) => {
    const xhr = new window.XMLHttpRequest();
    xhr.open('POST', url, true);

    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = () => {
      if (xhr.status === 200) resolve(xhr.response);
    };
    xhr.onerror = e => {
      console.error(
        'Error was encountered while connecting to the server. Please contact Erply support.',
        e,
      );
      reject(e);
    };

    xhr.send(JSON.stringify({ apiKey, params }));
  });
};

const getReceiptUrl = saleDocId => async (dispatch, getState) => {
  const state = getState();

  let receiptTemplateId = 0;

  const isPergamonFiscalPluginEnabled = !!getEnabledPlugins(state).find(
    plugin => plugin.plugin?.id === pluginID,
  );

  try {
    const [fullSaleDoc] = await getSalesDocuments({
      invoiceID: saleDocId,
    });
    if (
      isPergamonFiscalPluginEnabled &&
      shouldSaveSaleDocToFiscal(fullSaleDoc)
    ) {
      receiptTemplateId = getTemplateId(state);
    }

    const receiptUrl = await getPermanentPdfUrl(
      saleDocId,
      receiptTemplateId,
    ).then(res => res.url);

    return receiptUrl;
  } catch (error) {
    console.error('Failed to retrieve invoice PDF link', error);
    await dispatch(
      addError('There was an error while retrieving invoice PDF link'),
      {
        dismissible: true,
        selfDismiss: false,
      },
    );
    return '';
  }
};

const SMSTelerivet: PosPlugin = {
  id: 'sms-telerivet',
  name: 'Telerivet SMS plugin',
  keywords: ['sms', 'telerivet'],
  info:
    'This plugin enables sms service from Telerivet. You can send invoices via sms to clients after a sale.',
  reduxReducer: (state = { sendSMS: true }, { type, payload }) => {
    switch (type) {
      case SEND_SMS:
        return {
          ...state,
          sendSMS: payload,
        };
      case SET_PHONE_NUMBER_FOR_SMS:
        return {
          ...state,
          phoneNumberForSMS: payload,
        };
      case SET_SALE_ITEMS:
        return {
          ...state,
          saleItems: [...payload],
        };
      default:
        return state;
    }
  },
  getStatus: state => {
    const currentConfig: any =
      getPluginConfiguration('sms-telerivet')(state) ?? {};
    const { apiKey, projectID, serviceID } = currentConfig;

    if (!apiKey) {
      return {
        type: 'error',
        message: `Missing api key`,
      };
    }

    if (!projectID) {
      return {
        type: 'error',
        message: `Missing project ID`,
      };
    }

    if (!serviceID) {
      return {
        type: 'error',
        message: `Missing service ID`,
      };
    }

    return {
      type: 'valid',
      message: `Ready`,
    };
  },
  ComponentConfiguration: ({ current = {}, save }) => {
    const dispatch = useDispatch();
    const [apiKey, setApiKey] = useState(current.apiKey);
    const [projectID, setProjectID] = useState(current.projectID);
    const [serviceID, setServiceID] = useState(current.serviceID);
    const [phoneNumber, setPhoneNumber] = useState(current.phoneNumber);
    const [email, setEmail] = useState(current.email);
    const [validPhoneNumber, toggleValidPhoneNumber] = useState(false);

    useEffect(() => {
      toggleValidPhoneNumber(new RegExp('^[^/a-zA-Z]+$').test(phoneNumber));
    }, [phoneNumber]);

    return (
      <div>
        <p>API KEY</p>
        <input value={apiKey} onChange={e => setApiKey(e.target.value)} />
        <p style={{ marginTop: '10px' }}>PROJECT ID</p>
        <input value={projectID} onChange={e => setProjectID(e.target.value)} />
        <p style={{ marginTop: '10px' }}>SERVICE ID</p>
        <input value={serviceID} onChange={e => setServiceID(e.target.value)} />
        <div style={{ width: '100%', height: '25px' }} />
        <button
          type="button"
          onClick={() =>
            save({
              apiKey,
              projectID,
              serviceID,
            })
          }
        >
          Save configuration
        </button>
        <hr />
        <p style={{ marginBottom: '25px' }}>
          Enter phone number (required) to test an endpoint. Include email if
          needed
        </p>
        <p>Phone number</p>
        <input
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
        <p style={{ marginTop: '10px' }}>Email</p>
        <input value={email} onChange={e => setEmail(e.target.value)} />

        <div style={{ width: '100%', height: '25px' }} />

        {!validPhoneNumber ? (
          <p style={{ color: 'red' }}>Invalid phone number format</p>
        ) : null}
        {!apiKey ? <p style={{ color: 'red' }}>Missing API key</p> : null}
        {!projectID ? <p style={{ color: 'red' }}>Missing project ID</p> : null}
        {!serviceID ? <p style={{ color: 'red' }}>Missing service ID</p> : null}

        <button
          type="button"
          onClick={async () => {
            try {
              const response: any = await sendRequestToTelerivet(
                telerivetErplyServerURL,
                {
                  apiKey,
                  params: defineTestParams({
                    phone: phoneNumber,
                    email,
                    projectID,
                    serviceID,
                  }),
                },
              );

              if (response.indexOf('{') < 0) {
                throw new Error('Error sending SMS');
              }

              dispatch(addSuccess(`SMS sent to phone number ${phoneNumber}`));
            } catch (error) {
              dispatch(addError('There was an error while sending the SMS'));
            }
          }}
          disabled={
            !phoneNumber ||
            !projectID ||
            projectID === '' ||
            !serviceID ||
            serviceID === '' ||
            !validPhoneNumber
          }
        >
          Test endpoint
        </button>

        {/** Add feedback for why the button is disabled */}
      </div>
    );
  },
  UIConfirmationCheckbox: () => {
    const { t } = useTranslation('payment');

    const smsState = useSelector(getTelerivetSMSState);
    const { sendSMS } = smsState;

    return (
      <MyInput type="checkbox" value={sendSMS}>
        <span style={{ fontWeight: 'bold' }}>
          {t('confirmView.inputs.sendSMS')}
        </span>
      </MyInput>
    );
  },
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      const state = getState();

      const productsInCart = getProductsInShoppingCart(state);
      dispatch({ type: SET_SALE_ITEMS, payload: productsInCart });

      return {
        ...ap,
      };
    },
  },
  UIConfirmationInputField: ({ customer }) => {
    const { t } = useTranslation('payment');
    const dispatch = useDispatch();

    const { mobile, phone } = customer ?? {};
    const phoneNumberToDisplay = useMemo((): string => {
      if (mobile && mobile !== ' ') {
        return mobile;
      }

      if (phone && phone !== ' ') {
        return phone;
      }

      return '';
    }, [mobile, phone]);

    useEffect(() => {
      dispatch({
        type: SET_PHONE_NUMBER_FOR_SMS,
        payload: phoneNumberToDisplay,
      });
    }, [dispatch, phoneNumberToDisplay]);

    const [phoneNumber, setPhone] = useState(phoneNumberToDisplay);
    const [validPhoneNumber, toggleValidPhoneNumber] = useState(false);

    const smsState = useSelector(getTelerivetSMSState);
    const { sendSMS } = smsState;

    useEffect(() => {
      toggleValidPhoneNumber(new RegExp('^[^/a-zA-Z]+$').test(phoneNumber));
    }, [phoneNumber]);

    return (
      <>
        {sendSMS ? (
          <>
            <span>{t('confirmView.inputs.phone')}</span>
            <MyInput
              onChange={e => {
                dispatch({
                  type: SET_PHONE_NUMBER_FOR_SMS,
                  payload: e.target.value,
                });
                setPhone(e.target.value);
              }}
              type="text"
              style={{ fontSize: 18 }}
              value={phoneNumber}
            />
            {!validPhoneNumber ? (
              <span style={{ color: 'red' }}>
                Invalid or missing phone number
              </span>
            ) : null}
          </>
        ) : null}
      </>
    );
  },
  afterSuccessfulPaymentConfirmation: () => async (
    dispatch: ThunkDispatch<unknown, unknown, Action>,
    getState,
  ) => {
    const state = getState();

    const doc = getLastSalesDocument(state);
    const smsState = getTelerivetSMSState(state);
    const { sendSMS, phoneNumberForSMS, saleItems } = smsState;

    const currentConfig: any =
      getPluginConfiguration('sms-telerivet')(state) ?? {};
    const { apiKey, projectID, serviceID } = currentConfig;

    if (!doc.salesDoc.invoiceID) {
      dispatch(
        addError(
          'Cannot send SMS: No last document, or last document invoice ID is unknown',
        ),
      );
      return;
    }
    const [fullSalesDoc] = await getSalesDocuments({
      id: doc.salesDoc.invoiceID,
    });
    const actualPaidAmount = R.pipe(
      R.map(R.prop('amount')),
      R.reduce(R.add, 0),
    )(doc.payments);

    if (sendSMS) {
      try {
        // const receiptUrl = await dispatch(
        //   getReceiptUrl(doc.salesDoc.invoiceID),
        // );

        const response: any = await sendRequestToTelerivet(
          telerivetErplyServerURL,
          {
            apiKey,
            params: {
              project_id: projectID,
              service_id: serviceID,
              to_number: phoneNumberForSMS,
              start_time_offset: 0,
              message_type: 'service',
              vars: {
                // Temporary, since OAF is figuring out url shortening
                receiptUrl:
                  fullSalesDoc.jdoc?.['kenya-fiscal-brazil']?.verify_url ?? '',
                receipt: doc.salesDoc.invoiceNo,
                total: round(doc.salesDoc.total),
                amount: round(actualPaidAmount),
                phone: phoneNumberForSMS,
                date: formatDate(),
                ...getRowsData(saleItems),
              },
            },
          },
        );

        if (response.indexOf('{') < 0) {
          throw new Error('Error sending SMS');
        }

        dispatch(addSuccess(`SMS sent to phone number ${phoneNumberForSMS}`));
      } catch (error) {
        dispatch(addError('There was an error while sending the SMS'));
      }

      dispatch({ type: SET_PHONE_NUMBER_FOR_SMS, payload: '' });
    }
  },
};

export default SMSTelerivet;
