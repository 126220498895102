/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { getCustomers, getCustomerRewardPoints } from 'services/ErplyAPI';
import { getSalesDocuments } from 'services/ErplyAPI/sales';
import { IssuedCoupon } from 'types/Coupon';
import { getPayments } from 'reducers/Payments';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { notUndefinedOrNull } from 'utils';
import * as jsonApi from 'services/JsonAPI';
import { addError } from 'actions/Error';
import { RootState } from 'reducers';

import { saveWBUDataToInvoiceInJsonApi } from '../API/jsonAPI';
import { getWbuExtraData, updateCustomer } from '../redux';
import WBUAPI from '../API/API';

import { GivexIncrementJson, PrintoutExtrasWBURecord } from './types';

export const getBBCouponsCount = (
  invoiceID,
): Promise<PrintoutExtrasWBURecord['issuedCouponsCount']> =>
  doClientRequest<IssuedCoupon>({
    request: 'getIssuedCoupons',
    issuedInvoiceIDs: invoiceID,
  })
    .then(
      coupons =>
        // comparison with constant 'BB' code requested by ticket Reporter
        // API only allows searching by customerID and timestamps, but in the same range there might be more than 1 sales generates,
        // so after we fetch all the earned points sales for that time frame, we need to find the correct doc by invoiceID
        coupons.filter(c => c.couponCode === 'BB').length,
    )
    .catch(err => {
      console.error('Failed getting issued coupons', err);
      // if we cannot fund any records better admit it instead of falsy assume that the amount is 0 - avoids confusion for the customers
      return undefined;
    });

export const getCustomerMembershipEndDate = (clientID: number) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
) => {
  const data = await dispatch(WBUAPI.getCustomerMemberships(clientID));
  return data.endDateTime;
};

export const getRewardPointsForSale = (
  invoiceID,
): Promise<PrintoutExtrasWBURecord['earnedRewardPoints']> =>
  doClientRequest<{ earnedPoints: number }>({
    request: 'getEarnedRewardPointRecords',
    invoiceID,
  })
    .then(data => data?.[0]?.earnedPoints ?? 0)
    .catch(err => {
      console.error('Failed getting earned reward points', err);
      // if we cannot fund any records better admit it instead of falsy assume that the amount is 0 - avoids confusion for the customers
      return undefined;
    });

export const saveReceiptDataToJSON: Required<
  PosPlugin
>['onSaveSalesDocumentAttrToJsonApi']['on'] = ({ savedSaleDocument }) => async (
  dispatch,
  getState,
) => {
  const salesDocument = await getSalesDocuments({
    id: savedSaleDocument.invoiceID,
    getAddedTimestamp: 1,
  }).then(data => data?.[0]);

  const [birdBuckCouponsTotal, earnedPoints, customer] = await Promise.all([
    getBBCouponsCount(savedSaleDocument.invoiceID),
    getRewardPointsForSale(savedSaleDocument.invoiceID),
    getCustomers({ customerID: salesDocument.clientID })
      .then(data => data?.[0])
      .catch(err => {
        console.error('Failed to load customer', salesDocument.clientID, err);
        return {};
      }),
    dispatch(updateCustomer(String(salesDocument.clientID))),
  ]);

  const { endDateTime = '' } = getWbuExtraData(getState()) ?? {};

  const [{ points: rewardPoints = 0 }] = await getCustomerRewardPoints(
    customer.id,
  );

  const wbuData: PrintoutExtrasWBURecord = {
    dscExpirationDate: endDateTime ?? '',
    dscCurrentSavings: Number(rewardPoints),
    customerGroup: customer.groupID ?? 0,
    earnedRewardPoints: earnedPoints,
    issuedCouponsCount: birdBuckCouponsTotal,
  };

  await saveWBUDataToInvoiceInJsonApi(
    Number(savedSaleDocument.invoiceID),
    wbuData,
  );

  return null;
};

export const saveRowDataToJSON: Required<
  PosPlugin
>['onSaveSalesDocumentAttrToJsonApi']['after'] = ({
  savedSaleDocument,
}) => async (dispatch, getState) => {
  const payments = getPayments(getState());
  const cart = getProductsInShoppingCart(getState());

  const salesDocument = await getSalesDocuments({
    id: savedSaleDocument.invoiceID,
    getAddedTimestamp: 1,
  }).then(data => data?.[0]);

  const { rows } = salesDocument;

  const givexIncrementPayments: (GivexIncrementJson & {
    rowNumber: number;
  })[] = Object.values(payments)
    .filter(
      (value: any) =>
        value.cardType === 'GIVEX' &&
        value.transactionType === 'Increment' &&
        value.GIVEX,
    )
    .map((value: any) => ({
      number: value.GIVEX.card.cardNo.slice(-10, -1),
      balance: value.certificateBalance,
      referenceNumber: value.referenceNumber.slice(-6),
      dateTime: value.dateTime,
      rowNumber: cart.find(c => c.uuid === value.key).rowNumber,
    }));

  const recordsToSavePerRow = givexIncrementPayments
    .map(({ rowNumber, ...payload }) => {
      const id = rows[rowNumber - 1]?.stableRowID;
      if (!id) return undefined;
      return {
        id,
        json_object: {
          BrazilPOS: {
            givex: payload,
          },
        },
      };
    })
    .filter(notUndefinedOrNull);

  if (recordsToSavePerRow.length) {
    await Promise.all(
      recordsToSavePerRow.map(jsonApi.saveRecordsToInvoiceRow),
    ).catch(e => {
      dispatch(addError('Failed to store GIVEX info in JSON API'));
      console.error(
        'Failed to store GIVEX info in JSON API\n',
        recordsToSavePerRow,
        e,
      );
    });
  }
};
