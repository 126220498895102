import { DBSchema } from 'idb';
import dayjs from 'dayjs';

import { Product } from 'types/Product';
import { Employee } from 'types/Employee';
import { PriceList } from 'types/PriceList';

export const DB_NAME = 'Brazil-POS';
export const VERSION = 2;
export const LAST_SYNC_SERVER = 'lastSync';
export const CURRENT_SYNC_SERVER = 'currentSync';
export const CURRENT_SYNC_PAGE = 'currentSyncPage';
export const CURRENT_DELETE_PAGE = 'currentDeletePage';
export const READY = 'ready';

type Products = {
  value: Product;
  key: string | number;
  indexes: {
    groupID: number;
    name: string;
    code: string;
    price: number;
    added: number;
    productID: number;
    changed: number;
    groupAndCode: number;
    groupAndName: string;
  };
};

type ProductStockLevels = {
  value: Product;
  key: string | number;
  indexes: {
    warehouseID: number;
    name: string;
    code: string;
  };
};

type Employees = {
  value: Employee;
  key: string;
  indexes: {
    userID: number;
  };
};

type PriceLists = {
  value: PriceList;
  key: string;
  indexes: {
    product: number;
    productGroup: number;
  };
};

type Shared = {
  value: any;
  key: any;
};

export const SO = {
  SHARED: {
    NAME: 'shared',
    ID_PROP: '',
    KEY_PATH: {},
    INDEXES: {},
    REQUEST_NAME: '',
    EXTRA_PARAMS: {},
  },
  META: {
    NAME: 'meta',
    ID_PROP: '',
    KEY_PATH: {},
    INDEXES: {},
    REQUEST_NAME: '',
    EXTRA_PARAMS: {},
  },
  PRODUCTS: {
    NAME: 'products',
    ID_PROP: 'productID',
    KEY_PATH: { keyPath: 'productID' },
    INDEXES: {
      NAME: {
        indexName: 'name',
        keyPath: 'nameArray',
        options: { unique: false, multiEntry: true },
      },
      CODE: {
        indexName: 'code',
        keyPath: 'codes',
        options: { unique: false, multiEntry: true },
      },
      GROUP_ID: {
        indexName: 'groupID',
        keyPath: 'groupID',
        options: { unique: false },
      },
      PRICE: {
        indexName: 'price',
        keyPath: ['groupID', 'price'],
        options: { unique: false },
      } as const,
      ADDED: {
        indexName: 'added',
        keyPath: ['groupID', 'added'],
        options: { unique: false },
      },
      PRODUCT_ID: {
        indexName: 'productID',
        keyPath: ['groupID', 'productID'],
        options: { unique: false },
      },
      CHANGED: {
        indexName: 'changed',
        keyPath: ['groupID', 'lastModified'],
        options: { unique: false },
      },
      GROUP_AND_NAME: {
        indexName: 'groupAndName',
        keyPath: ['groupID', 'capitalisedName'],
        options: { unique: false },
      },
      GROUP_AND_CODE: {
        indexName: 'groupAndCode',
        keyPath: ['groupID', 'code'],
        options: { unique: false },
      },
    } as const,
    EXTRA_PARAMS: {
      /* NOT FETCHED getFIFOCost */
      /* NOT FETCHED getStockInfo */
      // ↑ See 'getProductStock' to implement 'localProductStockDB'
      includeMatrixVariations: 1,
      type: 'PRODUCT,BUNDLE,MATRIX,ASSEMBLY',
      /* getPriceListPrices */
      // getPriceListPrices: 1, // DO NOT SET THOSE IF PRODUCT SHOULD BE USED OVER FEW LOCATIONS
      /* getWarehouseSpecificVAT */
      // getWarehouseSpecificVAT: 1, // DO NOT SET THOSE IF PRODUCT SHOULD BE USED OVER FEW LOCATIONS
      /* getRecipes */
      getRecipes: 1,
      /* getPackageInfo */
      getPackageInfo: 1,
      /* getReplacementProducts */
      getReplacementProducts: 1,
      /* getRelatedProducts */
      getRelatedProducts: 1,
      /* getParameters */
      getParameters: 1,
      /* getPackagingMaterials */
      getPackagingMaterials: 1,
      /* getRelatedFiles */
      getRelatedFiles: 1,
      /* getContainerInfo */
      getContainerInfo: 1,
      /* getMatrixVariations */
      getMatrixVariations: 1,
      getAllLanguages: 1,
      getPriceCalculationSteps: 1,
      recordsOnPage: 1000,
    },
    REQUEST_NAME: 'getProducts',
  },
  PRODUCT_STOCK: {
    NAME: 'productStockLevels',
    ID_PROP: 'productID',
    KEY_PATH: { keyPath: ['productID', 'warehouseID'] },
    INDEXES: {
      WAREHOUSE_ID: {
        indexName: 'warehouseID',
        keyPath: 'warehouseID',
        options: { unique: false },
      },
    } as const,
    EXTRA_PARAMS: { getAmountReserved: 1, getReorderPoints: 1 },
    REQUEST_NAME: 'getProductStock',
  },
  PRICE_LIST: {
    NAME: 'priceLists',
    ID_PROP: 'pricelistID',
    KEY_PATH: { keyPath: 'pricelistID' },
    INDEXES: {},
    EXTRA_PARAMS: {
      recordsOnPage: 100,
      active: 1,
      endDateFrom: dayjs().format('YYYY-MM-DD'),
    },
    REQUEST_NAME: 'getPriceLists',
  },
  EMPLOYEES: {
    NAME: 'employees',
    ID_PROP: 'employeeID',
    KEY_PATH: { keyPath: 'employeeID' },
    INDEXES: {},
    EXTRA_PARAMS: { recordsOnPage: 100 },
    REQUEST_NAME: 'getEmployees',
  },
  CUSTOMERS: {
    NAME: 'customers',
    ID_PROP: 'customerID',
    KEY_PATH: { keyPath: 'customerID' },
    INDEXES: {},
    EXTRA_PARAMS: {},
    REQUEST_NAME: 'getCustomers',
  },
  ASSORTMENTS: {
    NAME: 'assortments',
    ID_PROP: 'productID',
    KEY_PATH: { keyPath: ['productID', 'assortmentID'] },
    INDEXES: {
      ASSORTMENT_ID: {
        indexName: 'assortmentID',
        keyPath: 'assortmentID',
        options: { unique: false },
      },
    },
    EXTRA_PARAMS: { recordsOnPage: 1000 },
    REQUEST_NAME: 'getAssortmentProducts',
  },
} as const;

export type ValueOf<T> = T[keyof T];

export type SOName = ValueOf<typeof SO>['NAME'];

const { SHARED, META: _, CUSTOMERS, ...REST } = { ...SO };

export const syncableSO = REST;

export type SyncableSOName = ValueOf<typeof syncableSO>['NAME'];

export interface MyDB extends DBSchema, Record<SOName, any> {
  shared: Shared;
  products: Products;
  productStockLevels: ProductStockLevels;
  employees: Employees;
  priceLists: PriceLists;
  customers: any;
}

export const getTableMeta = (SO_NAME: SOName, currentSyncID: number) => {
  const CURRENT_SYNC_ID =
    SO.PRODUCT_STOCK.NAME === SO_NAME && currentSyncID
      ? `-${currentSyncID}`
      : '';
  return {
    CURRENT_SYNC_PAGE: `${SO_NAME}-${CURRENT_SYNC_PAGE}${CURRENT_SYNC_ID}`,
    CURRENT_DELETE_PAGE: `${SO_NAME}-${CURRENT_DELETE_PAGE}${CURRENT_SYNC_ID}`,
    CURRENT_SYNC_SERVER: `${SO_NAME}-${CURRENT_SYNC_SERVER}${CURRENT_SYNC_ID}`,
    LAST_SYNC_SERVER: `${SO_NAME}-${LAST_SYNC_SERVER}${CURRENT_SYNC_ID}`,
    READY: `${SO_NAME}-${READY}${CURRENT_SYNC_ID}`,
  };
};

export const getSObyName = SO_NAME =>
  Object.values(SO).find(v => v.NAME === SO_NAME) as ValueOf<typeof SO>;
