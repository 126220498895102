/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { getAllWarehouses, getSelectedWarehouse } from 'reducers/warehouses';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';

type Configuration = {
    selectedLocationsList: string[];
};

interface SaveParams {
    newSelectedLocationsList: string[];
}

const defaultConfiguration: Configuration = {
    selectedLocationsList: []
};

const pluginId = 'hide-payment-currency';

const getShouldCurrencyBeHidden = (state: any): boolean => {
    const { selectedLocationsList }: any = getPluginConfiguration(pluginId)(state);
    const currentWarehouse = getSelectedWarehouse(state);

    return selectedLocationsList.includes(currentWarehouse.name)
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipsContainer: {
            minHeight: '50px',
            paddingLeft: 0,
            marginTop: '20px',
        },
        chip: {
            marginRight: '15px',
            marginBottom: '15px',
        },
        information: {
            marginLeft: '10px',
        },
        errorMessage: {
            marginLeft: '10px',
            color: 'red',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 175,
            backgroundColor: '#fff',
        },
        checkBoxFormControl: {
            margin: theme.spacing(2),
            display: 'flex',
        },
        checkboxesContainer: {
            display: 'block',
        },
        saveButton: {
            backgroundColor: '#f2b71e',
            fontWeight: 400,
            '&:hover': {
                backgroundColor: '#dda30d',
                borderColor: '#d19a0c',
            },
        },
    }),
);

const hidePaymentCurrency: PosPlugin = {
    id: pluginId,
    name: 'Hide payment currency',
    keywords: ['payment', 'currency', "hide"],
    info:
        'This plugin hides the currency value in the payment screen. You can enable this functionality by location.',
    getStatus: () => {
        return {
            type: 'valid',
            message: `Ready`,
        };
    },
    combineConfiguration: company => ({
        ...defaultConfiguration,
        ...company,
    }),
    ComponentConfiguration: ({
        byLevel: {
            company: { current = {}, save },
        },
    }) => {
        const classes = useStyles();

        const allLocations = useSelector(getAllWarehouses)

        const [selectedLocations, setLocationToList] = useState<string[]>(
            current.selectedLocationsList ?? [],
        );

        const doSave = (params: SaveParams): void => {
            const { newSelectedLocationsList } = params;

            save({
                selectedLocationsList: newSelectedLocationsList || selectedLocations,
            });
        };

        const handleLocationDelete = (
            groupNumber: string,
            selectedLocations: string[],
        ): void => {
            const indexOfGroup = selectedLocations.indexOf(groupNumber);

            if (indexOfGroup < 0) {
                return;
            }
            const updatedLocations = [...selectedLocations];
            updatedLocations.splice(indexOfGroup, 1);
            setLocationToList([...updatedLocations]);
            doSave({ newSelectedLocationsList: [...updatedLocations] });
        };

        const handleLocationChange = (
            event: React.ChangeEvent<{ value: any }>,
        ) => {
            const newSelectedLocationsList = [...selectedLocations, event.target.value];
            setLocationToList(newSelectedLocationsList);
            doSave({
                newSelectedLocationsList,
            });
        };

        return (
            <div>
                <h4>Locations where the payment currency will be hidden:</h4>
                <Container className={classes.chipsContainer}>
                    {selectedLocations.length > 0 ? (
                        selectedLocations.map(locationName => (
                            <Chip
                                className={classes.chip}
                                variant="outlined"
                                key={locationName}
                                onDelete={() => handleLocationDelete(locationName, selectedLocations)}
                                label={locationName}
                            />
                        ))
                    ) : (
                        <p className={classes.errorMessage}>No locations selected</p>
                    )}
                </Container>

                <p className={classes.information}>Add another location</p>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="product-group-select">Select location</InputLabel>
                    <Select
                        labelId="product-group-select"
                        id="product-group-select"
                        value=""
                        onChange={handleLocationChange}
                        label="product-group"
                    >
                        {allLocations
                            .filter(location => !selectedLocations.includes(location.name))
                            .map(({ name }) => (
                                <MenuItem value={name} key={name}>
                                    {name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </div>
        );
    },
    UIPaymentCurrency: ({ children }) => {
        const shouldHideCurrency: boolean = useSelector(getShouldCurrencyBeHidden);

        return shouldHideCurrency ? <></> : children
    },
    UIOriginalPaymentCurrency: ({ children }) => {
        const shouldHideCurrency: boolean = useSelector(getShouldCurrencyBeHidden)

        return shouldHideCurrency ? <></> : children
    },
};

export default hidePaymentCurrency;
