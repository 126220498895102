import { createSelector } from 'reselect';

import { SO } from 'services/DB/types';

import {
  getBarcodeCodeLength,
  getBarcodeMeasurementLength,
  getBarcodePricePrefix,
  getBarcodeType,
  getBarcodeWeightDecimals,
  getBarcodeWeightPrefix,
  getSetting,
} from '../configs/settings';

import { getCachedItemPerTypeByID, getCachedItemsPerType } from '.';

export function getProductByID(id) {
  return state =>
    getCachedItemPerTypeByID(SO.PRODUCTS.NAME, id?.toString())(state);
}

const defaultFallbackLangs = ['eng', 'est', 'rus', 'fre', 'spa', 'ger', 'ita'];

/**
 * Selector to get the translated name for a product.
 *
 * */

export function getTranslatedNameOfProductByProductID(id, language) {
  return state => {
    const dataFromConf = getSetting('touchpos_set_printing_language_priority')(
      state,
    );
    const splitDataFromConf = dataFromConf
      ? dataFromConf
          ?.split(',')
          ?.map(el => el.trim())
          ?.filter(el => el.length > 0)
          ?.concat(defaultFallbackLangs)
      : defaultFallbackLangs;
    const formatedNameTranslations = splitDataFromConf.map(
      lang => `name${lang.toString().toUpperCase()}`,
    );
    return (
      getProductByID(id)(state)[language] ||
      getProductByID(id)(state)[
        formatedNameTranslations.find(value => {
          const result = getProductByID(id)(state)[value];
          if (result && result !== '') return result;
          return undefined;
        })!
      ]
    );
  };
}

/**
 * Checks only among cached items
 * For true check among all products use indexedDB
 * @param id
 * @returns {function(*=): boolean}
 */
export function getCacheHasProductWithID(id) {
  return state =>
    !!getCachedItemPerTypeByID(SO.PRODUCTS.NAME, id)(state)?.productID;
}

export function getStockOfIDInCurrentWarehouse(id) {
  return state => getProductByID(id)(state).totalInStock;
}

export function getProductUnitname(id) {
  return state => {
    const prod = getProductByID(id)(state) || {};
    return prod.unitName;
  };
}

export function getRelatedProductIDs(id) {
  return state => getProductByID(id)(state)?.relatedProducts ?? [];
}

export function getRelatedProducts(id) {
  return state =>
    getRelatedProductIDs(id)(state)
      .filter(id => getCacheHasProductWithID(id)(state))
      .map(id => ({
        ...getProductByID(id)(state),
        stock: getStockOfIDInCurrentWarehouse(id)(state),
      }));
}

export function getReplacementProductIDs(id) {
  return state => getProductByID(id)(state)?.replacementProducts || [];
}

export function getReplacementProducts(id) {
  return state =>
    getReplacementProductIDs(id)(state)
      .filter(id => getCacheHasProductWithID(id)(state))
      .map(id => ({
        ...getProductByID(id)(state),
        stock: getStockOfIDInCurrentWarehouse(id)(state),
      }));
}

export function getHasRelatedOrReplacementProducts(id) {
  return state =>
    getReplacementProductIDs(id)(state).length > 0 ||
    getRelatedProductIDs(id)(state).length > 0;
}

export function getProductComponents(id) {
  return state => {
    const product = getProductByID(id)(state);
    if (!product?.productComponents?.length) {
      return [];
    }
    return product.productComponents
      .map(({ componentID, amount }) => ({
        componentID,
        ...getProductByID(componentID)(state),
        amount,
      }))
      .sort(({ name: a = '' }, { name: b = '' }) => a.localeCompare(b));
  };
}

/**
 * @return {function (state) : {
 *   productID: number,
 *   code: string,
 *   code2: string,
 *   name: string,
 *   dimensions: {
 *     name: string,
 *     dimensionID: number,
 *
 *     value: string,
 *     dimensionValueID: number,
 *     code: string,
 *     order: number,
 *   }[]
 * }[]}
 */
export function getVariationsForId(id) {
  return state => {
    const cachedProducts = getCachedItemsPerType(SO.PRODUCTS.NAME)(state);
    const variations = cachedProducts[id]?.variationList ?? [];

    const existing = variations.filter(
      ({ productID }) => cachedProducts[productID],
    );
    const active = existing.filter(
      id =>
        !new Set(['ARCHIVED', 'NOT_FOR_SALE']).has(
          cachedProducts[id.productID].status,
        ),
    );
    return active.sort((a, b) => b.order - a.order);
  };
}

/**
 * @return {function(state) : {[string]: {
 *   name: string,
 *   dimensionID: string,
 *   values: {
 *     order: string,
 *     dimensionValueID: string,
 *     value: string
 *     code: string,
 *   }[]
 * }}}
 */
export function getDimensionsForId(id) {
  return state => {
    const byIndex = getVariationsForId(id)(state)
      .flatMap(v => v.dimensions.map((v, i) => ({ ...v, dimensionIndex: i })))
      .reduce(
        (
          list,
          {
            name,
            dimensionID,
            value,
            dimensionValueID,
            code,
            order,
            dimensionIndex,
          },
        ) => ({
          ...list,
          [dimensionIndex]: {
            name,
            dimensionID,
            values: [
              ...(list[dimensionIndex] ? list[dimensionIndex].values : []),
              {
                order,
                dimensionValueID,
                value,
                code,
              },
            ],
          },
        }),
        {},
      );
    const unique = [byIndex[0], byIndex[1], byIndex[2]]
      .filter(a => a)
      .map(item => {
        const valueIDs = [
          ...new Set(item.values.map(value => value.dimensionValueID)),
        ];
        return {
          ...item,
          values: valueIDs.map(id =>
            item.values.find(val => val.dimensionValueID === id),
          ),
        };
      });

    return unique;
  };
}

export function getMatrixVariationsWithStock(parentProductID) {
  return state => {
    const productVariations = getVariationsForId(parentProductID)(state);
    return productVariations.map(variation => {
      const amount = Number(
        getCachedItemPerTypeByID(SO.PRODUCTS.NAME, variation.productID)(state)
          ?.free ?? 0,
      );
      return {
        ...variation,
        inStock: amount > 0,
      };
    });
  };
}

export function getShouldSellOnlyMatrixProductsInStock(state) {
  return getSetting('touchpos_sell_only_matrix_in_stock')(state);
}

// Selector to find a product by slicing up the given code into product code, weight, etc
export const getBarcodeDataExtractorFn = createSelector(
  state => getBarcodeType(state),
  state => getBarcodeWeightPrefix(state),
  state => getBarcodeCodeLength(state),
  state => getBarcodeMeasurementLength(state),
  state => getBarcodeWeightDecimals(state),
  state => getBarcodePricePrefix(state),
  (
    type,
    weightPrefix,
    barcodeLength,
    measurementLength,
    weightPrecision,
    pricePrefix,
  ) => {
    // if config in BO not specified, return empty object
    const isInvalidPriceConfig =
      barcodeLength === 0 ||
      measurementLength === 0 ||
      pricePrefix.length === 0;
    const isInvalidWeightConfig =
      weightPrefix.length === 0 || barcodeLength === 0;

    const weightedBarcodeRegex = new RegExp(
      `^(${weightPrefix}[0-9]{${barcodeLength}}[0-9]{${measurementLength}}[0-9])$`,
    );

    const pricedBarcodeRegex = new RegExp(
      `^(${pricePrefix}[0-9]{${barcodeLength}}[0-9]{${measurementLength}}[0-9])$`,
    );
    // detect that the text is a weighted barcode

    return text => {
      if (
        type === 'weight' &&
        weightedBarcodeRegex.test(text) &&
        !isInvalidWeightConfig
      ) {
        // if so, return an object with weight (aka amount) and product code
        return {
          prodCode: text.slice(
            weightPrefix.length,
            weightPrefix.length + barcodeLength,
          ),
          weight:
            Number(
              text.slice(
                weightPrefix.length + barcodeLength,
                weightPrefix.length + barcodeLength + measurementLength,
              ),
            ) /
            10 ** weightPrecision,
          type,
        };
      }

      // region Price extracting logic
      if (
        type === 'price' &&
        pricedBarcodeRegex.test(text) &&
        !isInvalidPriceConfig
      ) {
        // if so, return an object price and product code
        return {
          prodCode: text.slice(
            pricePrefix.length,
            pricePrefix.length + barcodeLength,
          ),
          embeddedPrice: Number(
            text.slice(
              pricePrefix.length + barcodeLength,
              pricePrefix.length + barcodeLength + measurementLength,
            ) / 100,
          ),
          type,
        };
      }
      // endregion

      return {};
    };
  },
);
