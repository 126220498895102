/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sort, map, uniq, pipe } from 'ramda';
import { isObject } from 'ramda-adjunct';

import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import InputField from 'components/FieldTypes/InputField';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import {
  saveTempSetting,
  dropTempSettings,
  commitTempSettings,
} from 'actions/configs';
import {
  getSettings,
  getCurrentPosLanguageOverride,
} from 'reducers/configs/settings';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import { CAFA_LEVELS, INTEGRATION_TYPES, INTEGRATIONS } from 'constants/CAFA';
import { getUserLoggedIn } from 'reducers/Login';
import './Language.scss';
import { missingLanguages } from 'utils/languages';

import styles from './Language.module.scss';

const Language = () => {
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const { userID } = useSelector(getUserLoggedIn);
  const { t, i18n } = useTranslation('settingsLanguage');
  const { pos_languages, pos_default_language } = settings;

  const [languageCodes, setLanguageCodes] = useState([]);
  const fullLanguageList = useMemo(
    () => [...pos_languages, ...missingLanguages],
    [pos_languages],
  );
  useEffect(() => {
    setLanguageCodes(
      pipe(
        sort(({ name: a }, { name: b }) => a.localeCompare(b)),
        map(lang => lang.code || lang.isoCode),
        // sort out duplicate languages so that we don't have a choice of two French for example
        uniq(),
      )(fullLanguageList),
    );
  }, [fullLanguageList]);
  const languageSelected = useSelector(getCurrentPosLanguageOverride);

  const [viewedLangs, setViewedLangs] = useState(new Set());

  const viewLang = lang => {
    if (!viewedLangs.has(lang)) {
      setViewedLangs(current => new Set([...current.values(), lang]));
    }
  };
  window.i18n = i18n;
  useEffect(() => {
    languageCodes.forEach(lang =>
      i18n.reloadResources(lang, 'settingsLanguage').then(() => {
        viewLang(lang);
      }),
    );
  }, [languageCodes]);
  // Change language by setting temp settings
  const setLanguage = lang => {
    dispatch(saveTempSetting('use_default_language', !lang));
    dispatch(saveTempSetting('CONFIGURATION: lang', lang));
  };

  const saveSettings = () => {
    dispatch(
      commitTempSettings(['use_default_language', 'CONFIGURATION: lang']),
    );
    dispatch(
      saveCafaConfig({
        integrationName: INTEGRATIONS.posConfigurations.language,
        integrationType: INTEGRATION_TYPES.posConfigurations,
        level: { id: userID, level: CAFA_LEVELS.User },
        config: languageSelected,
      }),
    );
  };

  // Clear temp settings on exit
  useEffect(
    () => () =>
      dispatch(
        dropTempSettings(['CONFIGURATION: lang', 'use_default_language']),
      ),
    [],
  );
  const getStatusForLang = lang => {
    if (!lang) return 'none';
    const def = viewedLangs.has(lang) ? 'none' : 'unknown';
    const langStatus = t('languageStatus', def, {
      lng: isObject(lang) ? lang.isoCode : lang,
      lngs: [isObject(lang) ? lang.isoCode : lang],
      fallbackLng: isObject(lang) ? lang.isoCode : lang,
    });
    return langStatus;
  };

  return (
    <div className="erply-settings__language">
      <Modal.Header>
        <Modal.Title>{t('title')}</Modal.Title>
        <SaveButton
          action={() => {
            saveSettings();
            dispatch(previousModalPage());
          }}
        />
        <CloseButton action={() => dispatch(previousModalPage())} />
      </Modal.Header>
      <Modal.Body>
        <Table>
          <tbody>
            <tr
              onClick={() => setLanguage('')}
              className={styles[getStatusForLang(pos_default_language)]}
            >
              <td>
                <InputField type="checkbox" size="lg" value={!languageSelected}>
                  {t('default')}
                </InputField>
              </td>
            </tr>
            {languageCodes.map(lang => {
              const langStatus = getStatusForLang(lang);
              return (
                <tr
                  className={styles[langStatus]}
                  key={lang}
                  onClick={() => setLanguage(lang)}
                >
                  <td>
                    <InputField
                      type="checkbox"
                      size="lg"
                      disabled={langStatus === 'none'}
                      value={lang === languageSelected}
                    >
                      {t(`lang.${lang.toLowerCase()}`)} (
                      {t(`status_${langStatus}`)})
                    </InputField>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </div>
  );
};

export default Language;
