import React, { FocusEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getSetting } from 'reducers/configs/settings';

import styles from './SearchComponent.module.scss';

const useStyles = makeStyles<Theme>({
  search: {
    width: '100%',
    borderRadius: '4px',
  },
});

type SearchComponentProps = {
  placeholder?: string;
  searchValue: string;
  setSearchValue: (str: string) => void;
  active: boolean;
  focusKey?: string;
  close: () => void;
  testId: string;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (event?: FocusEvent<HTMLInputElement>) => void;
};

const SearchComponent = React.forwardRef<
  HTMLInputElement | null,
  SearchComponentProps
>(
  (
    {
      placeholder = '',
      searchValue,
      setSearchValue,
      active,
      focusKey,
      close,
      testId,
      onKeyDown,
      onFocus,
    },
    outerRef,
  ) => {
    const { t } = useTranslation('search');
    const isDarkTheme = useSelector(getSetting('touchpos_use_dark_theme'));
    const classes = useStyles({ isDarkTheme });

    const defaultRef = useRef<HTMLInputElement | null>(null);
    const ref = outerRef ?? defaultRef;

    useEffect(() => {
      if (active && ref && 'current' in ref && ref.current) {
        ref.current.focus();
      }
    }, [active, ref]);

    useShortcut('Escape', close, 0);
    return (
      <FormControl className={classes.search} variant="outlined">
        <OutlinedInput
          placeholder={t(`${placeholder}.title`)}
          value={searchValue}
          data-testid={testId}
          onKeyDown={onKeyDown}
          onFocus={() => onFocus?.()}
          onChange={e => setSearchValue(e.target.value)}
          id="customer-search-input"
          inputRef={ref}
          className="pl-3"
          endAdornment={
            active && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="close popover"
                  onClick={e => {
                    e.stopPropagation();
                    close();
                  }}
                >
                  x
                </IconButton>
              </InputAdornment>
            )
          }
        />
        {!active && focusKey && (
          <span
            className={styles.focusKey}
            onClick={() => {
              if (ref && 'current' in ref) {
                ref.current?.focus();
              }
            }}
          >
            {focusKey}
          </span>
        )}
      </FormControl>
    );
  },
);

export default SearchComponent;
