import * as R from 'ramda';

import { SaleDocumentResponse } from 'types/SalesDocument';
import {
  requestAllRecords,
  requestCompleteResponse,
} from 'services/ErplyAPI/core/ErplyAPI';
import {
  setNewOnlineOrderIds,
  setOnlineOrderAmount,
  getPluginState,
  markOnlineOrderIdsAsViewed,
} from 'plugins/mm/rdx';

export type OnlineOrders = Pick<
  SaleDocumentResponse,
  'id' | 'total' | 'webShopOrderNumbers'
>;

const EMPTY_RETURN_VALUE = { newOrders: [], amountOfNewOrders: 0 };

export const fetchOnlineOrderData = () => async (
  dispatch,
  getState,
): Promise<{ newOrders: OnlineOrders[]; amountOfNewOrders: number }> => {
  const commonProps = {
    request: 'getSalesDocuments',
    type: 'INVWAYBILL',
    searchAttributeName1: 'pickupProcessed',
    searchAttributeValue1: '0',
    recordsOnPage: 1,
    getFields: 'id',
  };

  try {
    const [
      newOrdersResponse,
      notProcessedOrdersAmount,
      cancelledOrdersAmount,
    ] = await Promise.all([
      requestAllRecords<OnlineOrders>({
        ...commonProps,
        searchAttributeName2: 'pickupStatus',
        searchAttributeValue2: 'NEW',
        getFields: 'id,total,webShopOrderNumbers',
      }),
      requestCompleteResponse(commonProps).then(
        response => response.status.recordsTotal,
      ),
      requestCompleteResponse({
        ...commonProps,
        searchAttributeName2: 'pickupStatus',
        searchAttributeValue2: 'CANCELLED',
      }).then(response => response.status.recordsTotal),
    ]);

    const amountOfOrdersToProcess =
      notProcessedOrdersAmount - cancelledOrdersAmount;
    dispatch(setOnlineOrderAmount(amountOfOrdersToProcess));

    if (!newOrdersResponse.status.recordsTotal) return EMPTY_RETURN_VALUE;

    const { newOnlineOrderIds: cachedNewOnlineOrderIds } = getPluginState(
      getState(),
    );
    const newOrderIds = newOrdersResponse.records.map(order => order.id);
    dispatch(setNewOnlineOrderIds(newOrderIds));

    const newestOrderIds = R.difference(newOrderIds, cachedNewOnlineOrderIds);

    if (!newestOrderIds.length) return EMPTY_RETURN_VALUE;

    return {
      newOrders: newOrdersResponse.records
        .filter(order => newestOrderIds.includes(order.id))
        .slice(0, 10),
      amountOfNewOrders: newestOrderIds.length,
    };
  } catch (error) {
    console.error(error);
    return EMPTY_RETURN_VALUE;
  }
};
