import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getNestedProductsInShoppingCart,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import { getSetting } from 'reducers/configs/settings';
import { PluginComponent } from 'plugins';
import TableBill from 'components/tableBill/TableBill';
import { DebugDisplayVariables } from 'components/Dev/Debug';

import './billContainer.css';
import { useBreakpoints } from 'utils/hooks/UI';
import { useTrackResize } from 'utils/hooks/useTrackResize';

const BillContainer = () => {
  const shoppingCartProducts = useSelector(getProductsInShoppingCart);
  const groupedProducts = useSelector(getNestedProductsInShoppingCart);
  const shouldGroupProducts = useSelector(
    getSetting('touchpos_group_products_in_shopping_cart'),
  );

  const height = useTrackResize(() => window.innerHeight);
  const factor = height / 110;

  const products = shouldGroupProducts ? groupedProducts : shoppingCartProducts;
  const isMobile = !useBreakpoints().mdplus;

  const style = useMemo(
    () =>
      isMobile
        ? {
            height: height - 70 > 700 ? 35 * factor : 20 * factor,
          }
        : {},
    [isMobile, height, factor],
  );

  return (
    <div className="bill-container" style={style}>
      <PluginComponent hookname="UITableBill" props={{ products }}>
        <TableBill shoppingCartProducts={products} />
      </PluginComponent>
      <DebugDisplayVariables
        {...{
          shoppingCartProducts,
          groupedProducts,
          shouldGroupProducts,
        }}
      />
    </div>
  );
};

export default BillContainer;
