import { styled, Theme } from '@material-ui/core';

import MuiButton from 'components/CustomButtons/MuiButton';

function getButtonWidth(width: number | string) {
  if (typeof width === 'string') return width;

  if (width === 2) return '198px';
  if (width === 3) return '301px';
  return '95px';
}

const StyledButton = styled(MuiButton)<
  Theme,
  {
    width: number | string;
    height?: number;
  }
>(({ width, height }) => {
  return {
    margin: '0px 4px',
    marginBottom: '8px',
    height: `${height ?? 95}px`,
    width: getButtonWidth(width),
    boxShadow: 'none',
    fontWeight: 400,
    padding: '2px 4px',
  };
});

export default StyledButton;
