import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getIsPaymentWithCardOnly } from 'reducers/Payments';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import ListItem from 'components/ListItem';
import { useBreakpoints } from 'utils/hooks/UI';
import { addFullCardPayment } from 'actions/Payments';
import { getUISetting } from 'reducers/configs/settings';

const AddFullCardPayment = ({ disabled = false }) => {
  const { t } = useTranslation('payment');
  const isMobileView = !useBreakpoints().mdplus;
  const dispatch = useDispatch();

  const isPaymentWithCardOnly = useSelector(getIsPaymentWithCardOnly);

  const show = useSelector(getUISetting('touchposPaymentButtonsHighlight'));

  const action = () => {
    if (!disabled) {
      dispatch(addFullCardPayment());
    }
  };
  useShortcut('F3', action, 20);
  return (
    <>
      {isMobileView ? (
        <li
          className="payment-item full-card-payment"
          style={{ cursor: 'pointer' }}
        >
          <div tabIndex={0} className="button" role="button" onClick={action}>
            <span className="title">{t('buttons.exactCard')}</span>
          </div>
        </li>
      ) : (
        <ListItem
          disabled={disabled}
          variant="grid_light"
          action={action}
          style={{
            backgroundColor:
              isPaymentWithCardOnly && show === 1 ? '#0394fc' : '',
          }}
          data-testid="full-card-payment-button"
        >
          <span className="tip">100%</span>
          <span className="icon_creditcard" />
          <span data-testid="full-card-payment-shortcut" className="func-key">
            F3
          </span>
        </ListItem>
      )}
    </>
  );
};

export default AddFullCardPayment;
