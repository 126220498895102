import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import { getProductsUniversal } from 'actions/productsDB';
import {
  Dimension,
  ProductVariation,
} from 'containers/Forms/matrixVariations/types';
import { useAppDispatch } from 'reducers';
import { getShouldSellOnlyMatrixProductsInStock } from 'reducers/cachedItems/products';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getSetting } from 'reducers/configs/settings';

const dimensionProps = ['dimensionID', 'dimensionIndex', 'name', 'itemIndex'];
const dimensionsFromVariations = (
  variations: ProductVariation[] = [],
): Dimension[] =>
  R.pipe(
    // Take dimensions from the products
    R.map(R.prop('dimensions')),
    R.flatten,
    R.addIndex(R.map)((obj, itemIndex) => ({ ...obj, itemIndex })),
    // Group by dimension
    R.groupBy(R.prop('dimensionID')),
    R.values,
    // Extract dimension props from first object, leave option-specific ones in values
    R.map(arr => ({
      ...R.pick(dimensionProps)(arr[0]),
      values: R.pipe(
        R.map(R.omit(dimensionProps)),
        R.uniq,
        R.sort(R.ascend(R.prop('order'))),
      )(arr),
    })),
    // Sort by index
    R.sort(R.descend(R.prop('itemIndex'))),
  )(variations);

export const useMatrixDimensions = (variations: ProductVariation[]) =>
  useMemo(() => dimensionsFromVariations(variations), [variations]);

export const useAvailableMatrixDimensions = (
  variations: ProductVariation[] = [],
) => {
  const dispatch = useAppDispatch();
  const warehouseID = useSelector(getSelectedWarehouseID);
  const getItemsFromFirstPriceListOnly = useSelector(
    getSetting('pos_allow_selling_only_from_pricelist1'),
  );
  const shouldSellMatrixInStockOnly = useSelector(
    getShouldSellOnlyMatrixProductsInStock,
  );
  const {
    value: variationsFiltered,
    loading: loadingFilters,
  } = useAsync(async () => {
    if (variations.length === 0) return new Promise<never>(() => undefined);
    if (getItemsFromFirstPriceListOnly || shouldSellMatrixInStockOnly) {
      const { productsDict } = await dispatch(
        getProductsUniversal({
          productIDs: variations.map(v => Number(v.productID)),
          getFields: 'productID',
          getItemsFromFirstPriceListOnly: getItemsFromFirstPriceListOnly
            ? 1
            : 0,
          getOnlyItemsInStock: shouldSellMatrixInStockOnly ? 1 : 0,
          getProductsFor: 'SALES',
          warehouseID,
        }),
      );
      return variations.filter(v => v.productID in productsDict);
    }
    return variations;
  }, [variations, getItemsFromFirstPriceListOnly, shouldSellMatrixInStockOnly]);

  const dimensions = useMatrixDimensions(variationsFiltered ?? variations);
  return {
    dimensions,
    products: {
      products: variationsFiltered ?? variations,
      loading: loadingFilters,
    },
  };
};
