import i18next from 'i18next';
import { createSelector } from 'reselect';

import { getSelectedWarehouse } from 'reducers/warehouses';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getCompany, getLoggedInEmployeeID } from 'reducers/Login';

import { getCurrentPosLanguageLegacy, getSetting } from './settings';

function isPresent(str) {
  return str && str.length > 0;
}

const getEmailSenderAddress = createSelector(
  getSetting('send_emails_from_company_address'),
  getSelectedWarehouse,
  getLoggedInEmployeeID,
  getCompany,
  state => state,
  (sendFromCompanyAddress, warehouse, loggedInEmployeeId, company, state) => {
    if (Number(sendFromCompanyAddress)) {
      const locationEmail = warehouse?.email;
      if (locationEmail && locationEmail.length > 0) return locationEmail;
    } else {
      const employeeEmail = getEmployeeById(loggedInEmployeeId)(state)?.email;
      if (employeeEmail && employeeEmail.length > 0) return employeeEmail;
    }
    return company.email;
  },
);

function getEmailSubject(docType: string, docNo: string) {
  return createSelector(
    getSetting('mail_subject_contains_company_name'),
    getCompany,
    (includeCompanyName, company) => {
      const subject = i18next.t('billTable:headers.document', {
        type: docType ?? 'CASHINVOICE',
        number: docNo,
      });
      if (Number(includeCompanyName) && isPresent(company.companyName)) {
        return `${company.companyName} - ${subject}`;
      }
      return subject;
    },
  );
}

function getEmailBody(docTypeId: number) {
  return createSelector(
    getCurrentPosLanguageLegacy,
    getSetting('default_language'),
    state => state,
    (lang, defaultLang, state) => {
      const template = getSetting(`mailtemplate_inv${docTypeId}_${lang}` as any)(
        state,
      );
      if (isPresent(template)) return template;

      const fallbackTemplate = getSetting(`mailtemplate_default_${lang}` as any)(
        state,
      );
      if (isPresent(fallbackTemplate)) return fallbackTemplate;

      const defaultLangTemplate = getSetting(
        `mailtemplate_inv${docTypeId}_${defaultLang}` as any,
      )(state);
      if (isPresent(defaultLangTemplate)) return defaultLangTemplate;

      const defaultLangFallbackTemplate = getSetting(
        `mailtemplate_default_${defaultLang}` as any,
      )(state);
      if (isPresent(defaultLangFallbackTemplate))
        return defaultLangFallbackTemplate;

      const lastFallbackTemplate = getSetting('invoice_mail_template')(state);
      if (isPresent(lastFallbackTemplate)) return lastFallbackTemplate;

      // value cannot be empty string
      return '-';
    },
  );
}

/**
 * Since there is no simple way for retrieving all default values for email invoices
 * and endpoint does not have fallback values, data is collected based on this:
 * https://erply.atlassian.net/wiki/spaces/BOSPEC/pages/1924923485/Emailing+Invoices+in+Back+Office+Defaults
 */
export function getDefaultEmailInvoiceParams(docTypeId: number, docType: string, docNo: string) {
  return createSelector(
    getCurrentPosLanguageLegacy,
    getEmailSenderAddress,
    getEmailSubject(docType, docNo),
    getEmailBody(docTypeId),
    getCompany,
    (emailLanguage, senderEmail, emailSubject, emailBody, company) => ({
      emailLanguage,
      senderEmail,
      senderName: company.companyName,
      emailSubject,
      emailBody,
    }),
  );
}
