import { getClientCode } from 'reducers/Login';
import { REDUX_POSID, REDUX_CLIENTCODE } from 'constants/persistence';

// const proxy = `http://10.12.2.107:8080/`;

const get = key => JSON.parse(localStorage.getItem(key));
// eslint-disable-next-line import/prefer-default-export
export const healthCheck = async () => {
  const cc = get(REDUX_CLIENTCODE);
  const posID = get(REDUX_POSID);
  const formData = [];
  formData.push(`clientcode=${cc}`);
  formData.push(`posID=${posID}`);
  const response = await window.fetch(`https://${cc}.erply.com/api/health`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: formData,
  });

  const data = await response;
  return data;
};
