import { Skeleton } from '@material-ui/lab';
import React from 'react';

import useProducts from 'utils/hooks/useProducts';
import { SkeletonWord } from './SkeletonWord';

/** Util component to take a product ID and render its name (or a skeleton while loading) */
export const ProductName = ({ id }: { id: number }) => {
  const {
    products: [product],
  } = useProducts({
    productIDs: id ? [id] : [],
  });
  return product ? <>{product?.name}</> : <SkeletonWord />;
};
/**
 * Util component for rendering the amounts and names of products on a document.
 *
 * If no rows provided, renders a skeleton
 *
 * If products not loaded, renders skeletons for their names
 */
export const ProductList = ({
  rows,
}: {
  rows?: { amount: number; stableRowID: number; productID: number }[];
}) => {
  const { productsDict } = useProducts({
    productIDs: rows?.map(r => r.productID) ?? [],
  });
  if (!rows) return <Skeleton variant="rect" height="3ch" width="100%" />;
  return (
    <>
      {rows.map(row => (
        <div key={row.stableRowID}>
          {row.amount}x {productsDict[row.productID]?.name ?? <SkeletonWord />}
        </div>
      ))}
    </>
  );
};
