/* eslint-disable no-param-reassign */

import React from 'react';
import { batch } from 'react-redux';

import * as c from 'constants/UI';
import {
  getGridIsOpened,
  getSelectedProductGroup,
  getSelectedProductGroupID,
  getSelectedProductPage,
} from 'reducers/UI/gridDisplay';
import { getCurrentModalPage } from 'reducers/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { sleep } from 'utils';
import { Theme } from 'types/Theme';

import { cleanUpProductCache } from './productsDB';

export function openGridView() {
  return {
    type: c.OPEN_GRID_VIEW,
  };
}

export function closeGridView() {
  return {
    type: c.CLOSE_GRID_VIEW,
  };
}

export function setGridView(
  payload: typeof c.displayView[keyof typeof c.displayView],
) {
  return {
    type: c.SET_GRID_VIEW,
    payload,
  };
}

export function setProductPage(payload: number) {
  return async dispatch => {
    await dispatch({
      type: c.SET_PRODUCT_PAGE,
      payload,
    });
    dispatch(cleanUpProductCache());
  };
}

export function setProductGroup(payload: string) {
  return async dispatch => {
    batch(() => {
      dispatch({
        type: c.SET_PRODUCT_GROUP,
        payload,
      });
      dispatch(setProductPage(0));
    });
  };
}

export function openNextPage() {
  return (dispatch, getState) => {
    const currentPage = getSelectedProductPage(getState());
    dispatch(setProductPage(currentPage + 1));
  };
}

export function openPreviousPage() {
  return (dispatch, getState) => {
    const currentPage = getSelectedProductPage(getState());
    dispatch(setProductPage(Math.max(0, currentPage - 1)));
  };
}

export function toggleManualViewMode(payload: boolean) {
  return {
    type: c.TOGGLE_MANUAL_VIEW_MODE,
    payload,
  };
}

export function toggleManualEditMode() {
  return {
    type: c.TOGGLE_MANUAL_EDIT_MODE,
  };
}

export function enableProductEditMode() {
  return {
    type: c.ENABLE_PRODUCT_EDIT_MODE,
  };
}

export function disableProductEditMode() {
  return {
    type: c.DISABLE_PRODUCT_EDIT_MODE,
  };
}

export function toggleShouldGridUpdate(payload: boolean) {
  return {
    type: c.SET_GRID_SHOULD_UPDATE,
    payload,
  };
}

export function setColorTheme(theme: Theme | null, persist = true) {
  return {
    type: c.SET_COLOR_THEME,
    payload: { theme, persist },
  };
}

let lastTooltipID = 0;

export function addTooltip({
  content = <></>,
  selfDismiss = undefined,
}: {
  content: JSX.Element;
  selfDismiss?: number;
}) {
  return async dispatch => {
    // dispatch(hideTooltip());
    lastTooltipID += 1;
    const id = lastTooltipID;
    dispatch({
      type: c.ADD_TOOLTIP,
      payload: { id, content },
    });
    if (selfDismiss !== undefined) {
      await sleep(selfDismiss / 1000);
      dispatch(hideTooltip(id));
    }
  };
}

export function hideTooltip(id: number) {
  return async (dispatch, getState) => {
    await dispatch({
      type: c.HIDE_TOOLTIP,
      payload: { id },
    });
    const tooltipsShown = getState().UI.customerTooltip.tooltips.filter(
      ({ show }) => show,
    ).length;
    if (tooltipsShown === 0) dispatch(resetTooltips());
  };
}

export function resetTooltips() {
  return {
    type: c.RESET_TOOLTIPS,
  };
}

export function goUpOneProductGroup() {
  return async (dispatch, getState) => {
    const previousProductGroup = getSelectedProductGroup(getState())
      .parentGroupID;
    return dispatch(setProductGroup(previousProductGroup));
  };
}

export function closeGridWithConditions() {
  return (dispatch, getState) => {
    const state = getState();
    const currentModal = getCurrentModalPage(state);
    const gridOnly = ['CreateNew', 'CreateNew/Item'];
    if (currentModal && gridOnly.indexOf(currentModal.component) >= 0) {
      dispatch(closeModalPage());
    }
    return dispatch(closeGridView());
  };
}

export function setShoppingCartTooltip(content: JSX.Element | null) {
  return {
    type: c.SET_SHOPPING_CART_TOOLTIP,
    payload: content,
  };
}

export function setShouldUpdateGrid(productGroupID: number) {
  return (dispatch, getState) => {
    const gridIsOpen = getGridIsOpened(getState());
    const openedProductGroupID = getSelectedProductGroupID(getState());
    if (gridIsOpen && Number(openedProductGroupID) === productGroupID) {
      dispatch(toggleShouldGridUpdate(true));
    }
  };
}
