import { AnyAction, combineReducers } from 'redux';
import { createSelector, Selector } from 'reselect';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';

import { TYPE_LOGOUT } from 'constants/Login';
import { ACTIVATE_PLUGIN } from 'constants/Plugins';
import plugins from 'plugins';
import {
  ActionLifecycleHooks,
  HookOfType,
  PluginStatus,
  PosPlugin,
  SelectorOverride,
} from 'plugins/plugin';
import { CAFA_ENTRY, INTEGRATION_TYPES } from 'constants/CAFA';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getUserLoggedIn } from 'reducers/Login';
import { notUndefinedOrNull } from 'utils/tsHelpers';
import { RootState } from 'reducers';

import { getAllCafaEntries, getUnobstructedCafaEntries } from './cafaConfigs';

const initialValue = [];

function hasReducer(
  p: PosPlugin,
): p is PosPlugin & Pick<Required<PosPlugin>, 'reduxReducer'> {
  return 'reduxReducer' in p;
}

/**
 * From the list of plugins, composes a reducer that has a slice for each plugin based on the id
 * {
 *   somePluginId: 0,
 *   someOtherPluginId: 'No reducer',
 *   complexPluginReducer: {
 *     counter: 1,
 *     loading: true,
 *   }
 * }
 */
function reducers() {
  return combineReducers(
    Object.fromEntries(
      (plugins || []).filter(hasReducer).map(p => [p.id, p.reduxReducer]),
    ),
  );
}
export default (state = {}, { type, payload, ...rest }: AnyAction) => {
  if (type === ACTIVATE_PLUGIN) {
    plugins[payload.id] = payload;
  }
  switch (type) {
    case TYPE_LOGOUT:
      return {};
    default:
      return reducers()(state, { type, payload, ...rest });
  }
};

function getPluginStates(state) {
  return state.Plugins;
}
function getPluginState(pluginID: string) {
  return state => getPluginStates(state)[pluginID];
}

/**
 * Binds a plugin selector to select from the correct slice in the POS store
 * Must be passed a pluginID, then the selector
 */
export function getPluginSelector<Slice>(pluginID: string) {
  return <T>(selector: (state: Slice) => T) => (
    state,
  ): T => selector(getPluginState(pluginID)(state));
}

/**
 * Returns a list of enabled plugins ids
 */
export const getEnabledPlugins = createSelector(
  state => getUnobstructedCafaEntries(state),
  (cafaInts): { plugin?: PosPlugin; config: any }[] => {
    const ints = cafaInts
      .filter(int => int.type === INTEGRATION_TYPES.brazilPlugin)
      .filter(int => int.value.enabled)
      .map(int => ({
        plugin: plugins.find(p => p.id === int.name),
        config: int.value,
      }))
      .filter(int => int.plugin);
    return ints;
  },
);

/**
 * Returns all the available plugins (enabled or not)
 * @returns {PosPlugin[]}
 */
export function getAvailablePlugins(): PosPlugin[] {
  return [...plugins];
}

/**
 * All the plugins combined so that all of their properties because arrays of properties instead
 * @example
 * {
 *   id: ['pnpSecurity', 'carSales'],
 *   getStatus: [(state) => {...}, (state) => {...}],
 *   UIHeaderComponent: [() => {...}],
 * }
 */

const getEnabledPluginsCombined = createSelector<any, any, any>(
  getEnabledPlugins, // [{plugin, config}, {plugin,config}]
  (R.pipe as any)(
    R.map(({ plugin }): PosPlugin | undefined => plugin), // [{id:'a', getFoo: 1}, {id: 'b', getFoo: 12, getBar: 2}]
    R.filter(notUndefinedOrNull),
    R.map(R.map(prop => [prop])), // [{id:['a'], getFoo: [1]}, {id: ['b'], getFoo: [12], getBar: [2]}]
    R.reduce(R.mergeWith(R.concat))({}), // {id:['a', 'b'], getFoo: [1, 12], getBar: [2]}
  ),
);

/**
 * Given the name of a hook (any property of {@link PosPlugin}), returns all the values from all the plugins that define this hook
 * @param name The name of the pluginhook
 * @returns {(state) => T[]}
 */
export function getPluginHooksByName<T>(name) {
  return (state): NonNullable<T>[] =>
    getEnabledPluginsCombined(state)[name] ?? initialValue;
}

export function getPluginComponentsByName(name) {
  return state =>
    getEnabledPlugins(state)
      .map(({ plugin }) => plugin?.components?.[name])
      .filter(f => f);
}

export function getPluginSelectorValuesByName<T>(name) {
  return (state): T[] => {
    const pluginHooksByName = getPluginHooksByName<Selector<any, T>>(name)(state);
    if (!pluginHooksByName.length) return initialValue;
    return pluginHooksByName.map(sel => sel(state));
  };
}
export function getPluginConfigurationAtLevel<T>(
  name: string,
  level: CAFA_ENTRY['level'],
  levelId: CAFA_ENTRY['level_id'],
) {
  return (state): T =>
    getAllCafaEntries(state).find(
      int =>
        int.type === INTEGRATION_TYPES.brazilPlugin &&
        int.name === name &&
        int.level === level &&
        !(
          level !== 'Company' &&
          levelId &&
          String(levelId) !== String(int.level_id)
        ),
    )?.value?.config;
}

export function getPluginConfiguration<T>(name) {
  return (state): T => {
    const pos = getSelectedPos(state);
    const user = getUserLoggedIn(state);
    const combineFunction = getAvailablePlugins().find(p => p.id === name)
      ?.combineConfiguration;
    if (combineFunction) {
      return combineFunction(
        getPluginConfigurationAtLevel(name, 'Company', '')(state),
        getPluginConfigurationAtLevel(
          name,
          'Warehouse',
          pos?.warehouseID,
        )(state),
        getPluginConfigurationAtLevel(name, 'Pos', pos?.pointOfSaleID)(state),
        getPluginConfigurationAtLevel(name, 'User', user?.userID)(state),
      );
    }
    return getUnobstructedCafaEntries(state).find(
      int => int.type === INTEGRATION_TYPES.brazilPlugin && int.name === name,
    )?.value.config;
  };
}

/**
 * Get lifecycle actions that can be added to existing thunk actions to enable plugins to react to and interrupt them
 * @example
 * const addProductToShoppingCart = ({productID, amount}) => async (dispatch, getState) => {
 *   const state = getState();
 *   const {before, on ,after} = getPluginLifecycleHook('onAddProductToShoppingCart')(state);
 *
 *   // First we run the before hook
 *   dispatch(before({productID, amount}));
 *
 *   // Then we run any core pre-processing of the POS
 *   const actualAmount = Math.min(capacity, amount);
 *   if(!productHasStock(productID, actualAmount)(state)) {
 *     dispatch(showOutOfStockError(productID));
 *     return;
 *   }
 *
 *   try {
 *     // Then we run the on hook
 *     const params = await dispatch(on({productID, amount}, {productID, amount:actualAmount, id})) // Plugins can throw an error here to abort
 *
 *     // Then we dispatch to redux
 *     dispatch({
 *       type: ADD_PRODUCT,
 *       payload: params
 *     })
 *
 *     // Finally we notify the after hook
 *     const id = getLatestShoppingCartProductID(getState());
 *     await dispatch(after({productID, amount}, {id}))
 *   } catch (e) {
 *     // Cancelled by plugin, do nothing
 *   }
 * }
 */
type LifecycleHookname = Exclude<
  HookOfType<ActionLifecycleHooks<any, any, any>>,
  'getStatus' | 'reduxActions' | 'customHooks' | 'components' | undefined
>;

type Params<T extends LifecycleHookname> = Required<
  PosPlugin
>[T] extends ActionLifecycleHooks<infer Params, infer ActionParams, infer X>
  ? [Params, ActionParams, X]
  : never;

export function getPluginLifecycleHook<T extends LifecycleHookname>(hookname: T) {
  return (
    state: RootState,
  ): Required<ActionLifecycleHooks<Params<T>[0], Params<T>[1], Params<T>[2]>> => {
    type P = Params<T>;
    const hooks = getPluginHooksByName<ActionLifecycleHooks<P[0], P[1], P[2]>>(
      hookname,
    )(state);
    // typescript doesn't understand that .filter(a=>a) removes undefined
    // But it does understand if written with flatMap like this
    const befores = hooks.flatMap(hook => hook.before ?? []);
    const ons = hooks.flatMap(hook => hook.on ?? []);
    const afters = hooks.flatMap(hook => hook.after ?? []);

    return {
      /**
       * Hook run immediately after the user input (should be placed at the beginning of the POS thunk action
       * @param {I} params Input parmeters to the POS thunk action
       */
      before: (params: P[0]) => async (dispatch): Promise<void> => {
        await Promise.all(befores.map(bef => dispatch(bef(params)))).catch(e => {
          console.debug(`Lifecycle hook ${hookname}.before had an error`, e);
          throw new Error(
            `Plugin lifecycle hook ${hookname}.before had an error`,
            { cause: e },
          );
        });
      },
      /**
       * Hook run after the POS thunk action has finished preprocessing/validation and is about to dispatch to the reducer
       * This hook has the ability to cancel the action by throwing an error
       * @param {I} params Parameters that were passed to the thunk action
       * @param {AI} actionParams Parameters that are going to be passed to the reducer
       * @returns {(dispatch) => Promise<I>} A thunk action
       * If this action throws an error, then the POS thunk action is cancelled
       * Otherwise the returned params from this action are used instead of actionParams
       * If you don't want to change anything, return actionParams
       */
      on: (params: P[0], actionParams: P[1]) => async (
        dispatch,
      ): Promise<P[1]> => {
        return ons
          .reduce(
            (prev, on) =>
              prev.then(
                val => (dispatch(on(params, val)) as unknown) as Promise<P[1]>,
              ),
            Promise.resolve(actionParams),
          )
          .catch(e => {
            console.debug(`Lifecycle hook ${hookname}.on had an error`, e);
            throw new Error(`Plugin lifecycle hook ${hookname}.on had an error`, {
              cause: e,
            });
          });
      },
      /**
       * Hook run after the action has dispatched to the reducer
       * @param {I} params The original parameters to the POS thunk action
       * @param {O} extra Any relevant extra data generated by the POS thunk action or the reducer
       */
      after: (params: P[0], extra: P[2]) => async (dispatch): Promise<void> => {
        await Promise.all(afters.map(aft => dispatch(aft(params, extra)))).catch(
          e => {
            console.debug(`Lifecycle hook ${hookname}.after had an error`, e);
            throw new Error(
              `Plugin lifecycle hook ${hookname}.after had an error`,
              { cause: e },
            );
          },
        );
      },
    };
  };
}

/**
 * Similar to getPluginLifecycleHook, this selector finds all component specific hooks for given modal page and returns
 * before on and after hooks to be dispatched in ModalPage component
 * @param component
 */
export function getOnOpenModalPageHooks<I = any, AI = any, O = any>(
  component: string,
) {
  return (state): ActionLifecycleHooks<I, AI, O> => {
    const hooks: any =
      getPluginHooksByName('onOpenModalPage')(state)
        .map((c: any) => c[component])
        .filter(notUndefinedOrNull) ?? [];

    const befores = hooks.map(hook => hook.before).filter(notUndefinedOrNull);
    const ons = hooks.map(hook => hook.on).filter(notUndefinedOrNull);
    const afters = hooks.map(hook => hook.after).filter(notUndefinedOrNull);

    return {
      before: (params: I) => async (dispatch): Promise<void> => {
        await Promise.all(befores.map(bef => dispatch(bef(params)))).catch(e => {
          console.error(
            `Lifecycle hook onOpenModalPage.${component}.before had an error`,
            e,
          );
          throw e;
        });
      },
      on: (params, actionParams) => async (dispatch): Promise<AI> => {
        return ons
          .reduce(
            (prev, on) => prev.then(val => dispatch(on(params, val))),
            Promise.resolve(actionParams),
          )
          .catch(e => {
            console.error(
              `Lifecycle hook onOpenModalPage.${component}.on had an error`,
              e,
            );
            throw e;
          });
      },
      after: (params: I, extra: O) => async (dispatch): Promise<void> => {
        await Promise.all(afters.map(aft => dispatch(aft(params, extra)))).catch(
          e => {
            console.error(
              `Lifecycle hook onOpenModalPage.${component}.after had an error`,
              e,
            );
            throw e;
          },
        );
      },
    };
  };
}

function getPluginStatusSelectors() {
  return (plugins ?? []).map(plugin => state => {
    const p = plugin;
    try {
      const status = p.getStatus?.(state);
      // If getStatus not defined on plugin, assume plugin valid
      if (!status) return { type: 'valid', message: 'Ready' } as PluginStatus;
      return status;
    } catch (e) {
      // If getStatus fails, report to sentry and override it so we only get one report per session
      const original = p.getStatus as Selector<any, any>;
      p.getStatus = state => {
        try {
          return original.apply(p, [state]);
        } catch (e) {
          console.error('Plugin getStatus threw an error', { plugin: p, e });
          return {
            type: 'error',
            message:
              'Could not determine plugin status, this likely indicates a fatal error that would prevent the plugin from working correctly',
          };
        }
      };

      Sentry.captureException(new Error(`${p?.id}: getStatus error`));
      return p.getStatus?.(state);
    }
  });
}

export function getPluginStatus(id: string) {
  return getPluginStatusSelectors()[plugins.findIndex(p => p.id === id)] ??
  ((): PluginStatus => ({ type: 'error', message: `Plugin ${id} not found` }));
}

// DO NOT EXPORT, this is a selector factory
const getPluginLanguageOverridesSelector = createSelector(
  getEnabledPlugins,
  plugins => {
    const selectors = plugins
      .map(en => en.plugin?.getTranslationOverrides)
      .filter(a => a);
    return createSelector(
      () => null,
      ...selectors,
      // @ts-ignore
      (...languages) =>
        // mergeDeepWith does a deep merge (combines deep properties of both objects)
        // reduce does so recursively over all the plugin language overrides
        // the with function skips null and undefined values
        //
        // without with: mergeDeep({a: undefined, b:2}, {a:1, b:null}) === {a:undefined, b:2}
        // with with: mergeDeepW..({a: undefined, b:2}, {a:1, b:null}) === {a:1, b:2}
        R.reduce(R.mergeDeepWith((a, b) => a ?? b))({})(languages),
    );
  },
);
/**
 * The sum of all active plugins' translationOverride values - shouldn't be necessary to use this anywhere except the useEffect in App.js
 */
export const getPluginLanguageOverride = createSelector(
  getPluginLanguageOverridesSelector,
  state => state,
  (selector, state) => selector(state),
);

type SelectorOverrides<TState, TReturn> = Record<
  string,
  SelectorOverride<Selector<TState, TReturn>>
>;

export function createOverrideSelector<TState, TReturn>(
  name: string,
  baseSelector: Selector<TState, TReturn>,
) {
  const overriddenSelector = createSelector(
    state =>
      getPluginHooksByName<SelectorOverrides<TState, TReturn>>(
        'selectorOverrides',
      )(state)
        .map(overrides => overrides[name])
        .filter(a => a),
    selectors => selectors.reduce((prev, next) => next(prev), baseSelector),
  );
  return createSelector(
    overriddenSelector,
    (state: TState) => state,
    (sel, state) => sel(state),
  );
}
