import { Button } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';

import { getShouldShowIssueGiftCardSection } from './selectors';

export const GiftCard: PosPlugin['UIGiftCard'] = ({ children }) => {
  const dispatch = useDispatch();

  const showButton = useSelector(getShouldShowIssueGiftCardSection);

  const openGiftCardSerialPopup = () => {
    dispatch(
      openModalPage({
        component: modalPages.giftCardSerial,
        groupID: modalPages.giftCardSerial,
        isPopup: true,
      }),
    );
  };

  if (!showButton) return children;

  return R.map(
    R.when(
      R.pathEq(['props', 'data-testid'], 'giftcard-form'),
      R.over(
        R.lensPath(['props', 'children']),
        R.always(
          <div style={{ marginBottom: '1rem' }}>
            <div>Issue new serial gift card</div>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={openGiftCardSerialPopup}
            >
              Enter serial number
            </Button>
          </div>,
        ),
      ),
    ),
  )(React.Children.toArray(children));
};
