export default {
  en: {
    promotionsText: 'This is the default text for promotions',
    tableProductColText: 'Product',
    tableCodeColText: 'Code',
    tableQuantityColText: 'QTY',
    tableDiscountColText: 'Disc',
    tablePriceColText: 'Price',
    summaryDiscountText: 'Discount',
    summaryTaxText: 'Tax',
    summaryNetText: 'Net',
    summaryTotalText: 'Total',
    donationTitle: 'Would you like to give a donation?',
    donationSubtitle: 'Please select the amount you wish to donate:',
    donationInputPlaceholder: 'Insert your donation amount here',
    donationButtonOkText: 'Ok',
    donationConfirmationTitle: 'Please confirm your donation amount:',
    donationAmountReplacementIdentifier: '_amt_',
    donationConfimationSubtitle:
      'You are about to give a donation in the amount of _amt_',
    donationButtonConfirmText: 'Confirm',
    donationButtonCancelText: 'Cancel',
    donationThankingText: 'Thank you for your donation!',
    tipTitle: 'Would you like to give a tip?',
    tipSubtitle: 'Please select the percentage you wish to tip:',
    tipInputPlaceholder: 'Insert your tip percentage here',
    tipButtonOkText: 'Ok',
    tipConfirmationTitle: 'Please confirm your tip percentage:',
    tipAmountReplacementIdentifier: '_amt_',
    tipConfimationSubtitle:
      'You are about to give a _amt_ percent tip of the total amount',
    tipButtonConfirmText: 'Confirm',
    tipButtonCancelText: 'Cancel',
    tipThankingText: 'Thank you for your tip!',
    emailLookupTitle: 'Lookup customer by email',
    emailLookupSubtitle: 'Please enter your email to receive the receipt',
    emailLookupInputPlaceholder: 'Insert your email here',
    emailLookupButtonOkText: 'Ok',
    phoneLookupTitle: 'Lookup customer by phone number',
    phoneLookupSubtitle: 'Please enter the number to check in',
    phoneLookupInputPlaceholder: 'Insert your phone number here',
    phoneLookupButtonOkText: 'Ok',
    registerCustomerTitle: 'Register as a new customer',
    registerCustomerConfirmationTitle: 'Confirm registration',
    registerCustomerConfirmationSubtitle:
      'Please confirm your registration to loyalty campaign',
    registerCustomerButtonConfirmText: 'Confirm',
    registerCustomerButtonCancelText: 'Cancel',
    registerCustomerSuccessfulText: 'You have been registered successfully',
    registerCustomerFailedText: 'You could not be registered',
    couponTitle: 'Enter coupon code to add',
    couponSubtitle: 'Please enter coupon code to add:',
    couponInputPlaceholder: 'Insert your coupon code here',
    couponConfirmationTitle: 'Please confirm your coupon code',
    couponButtonOkText: 'Ok',
    couponCodeReplacementIdentifier: '_coupon_',
    couponConfirmationSubtitle: 'You entered _coupon_ as the coupon code',
    couponButtonConfirmText: 'Confirm',
    couponButtonCancelText: 'Cancel',
    couponCodeAppliedTitle: 'Coupon code successfully applied',
    couponCodeDiscountIdentifier: '_couponDiscount_',
    couponCodeAppliedSubtitle: 'You got a discount of _couponDiscount_',
    couponCodeFailedTitle: 'Could not apply coupon code',
    couponCodeFailedSubtitle: 'Please ask at the desk if you wish to retry',
    upsellTitle: 'Best offers today',
    upsellPriceText: 'Offer Price',
    upsellAddText: 'Add',
    upsellConfirmationTitle: 'Confirm purchase',
    upsellConfirmationSubtitle: 'Please confirm the addition of the products',
    upsellConfirmationButtonConfirmText: 'Confirm',
    upsellConfirmationButtonCancelText: 'Cancel',
    upsellPurchaseSuccessTitle: 'Purchase successful!',
    upsellPurchaseSuccessSubtitle: 'Thank you!',
    upsellPurchaseFailedTitle: 'Purchase failed',
    thankYouForShoppingText: 'Thank you for shopping with us!',
    qrCodeDataText: '',
    qrTitle: 'Code not sent',
    qrSubtitle: 'Please ask to send the correct code',
    rateServiceTitle: 'Please rate our service',
    rateServiceSubtitle:
      'We take into account your opinion and look forward to your comments and suggestions',
    rateServiceCompletedTitle: 'Thank you for rating!',
    rateServiceCompletedSubtitle: 'We try our best to meet your expectations',
    rateServiceInputPlaceholder: 'Your comment',
    customerCheckInTitle: 'Check in or do something',
    customerCheckInSubtitle: 'Choose an option required',
    customerCheckInAddCustomer: 'Register',
    customerCheckInLookUpCustomer: 'Find',
    customerCheckInCoupon: 'Coupon',
    registerCustomerForm: {
      label: {
        firstName: 'Enter your first name',
        lastName: 'Enter your last name',
        email: 'Enter your email',
        zip: 'Enter your zip code',
        code: 'Enter your ID code',
        customerCardNumber: 'Enter your loyalty card number',
        phone: 'Enter your phone number',
        mobile: 'Enter your mobile number',
        emailOptOut: 'Subscribe to email list',
        privacy: 'I agree to terms and conditions',
        termsAndConditions: 'I agree to terms and conditions',
        birthday: 'Enter your birthday',
      },
      placeholder: {
        firstName: 'Insert your first name here',
        lastName: 'Insert your last name here',
        email: 'Insert your email here',
        zip: 'Insert your zip code here',
        code: 'Insert your ID code here',
        customerCardNumber: 'Insert loyalty card number here',
        phone: 'Insert phone number here',
        mobile: 'Enter your mobile number here',
        emailOptOut: '',
        birthday: 'Insert your birthday here',
      },
      requiredMessage: {
        firstName: 'First name is required',
        lastName: 'Last name is required',
        email: 'Email is required',
        zip: 'Zip is required',
        code: 'ID code required',
        customerCardNumber: 'Loyalty card number required',
        phone: 'Phone number required',
        mobile: 'Mobile number required',
        emailOptOut: '',
        birthday: 'Birthday is required',
      },
      message: {
        termsAndConditions:
          'This is a text shown in modal for terms and conditions. \\n\\n1. Test \\n\\n2. Test',
      },
      link: {
        privacy: 'https://www.erply.com',
      },
    },
  },
  et: {
    promotionsText: 'Vaikimisi kampaaniatekst',
    tableProductColText: 'Toode',
    tableCodeColText: 'Kood',
    tableQuantityColText: 'Kogus',
    tableDiscountColText: 'Allah.',
    tablePriceColText: 'Hind',
    summaryDiscountText: 'Allah.',
    summaryTaxText: 'KMaks',
    summaryNetText: 'Neto',
    summaryTotalText: 'Kokku',
    donationTitle: 'Kas soovite teha annetust?',
    donationSubtitle: 'Palun valige annetuse summa:',
    donationInputPlaceholder: 'Sisestage annetuse summa siia',
    donationButtonOkText: 'Ok',
    donationConfirmationTitle: 'Kinnitage annetuse summa:',
    donationAmountReplacementIdentifier: '_amt_',
    donationConfimationSubtitle: 'Olete sooritamas annetust summas _amt_',
    donationButtonConfirmText: 'Kinnita',
    donationButtonCancelText: 'Katkesta',
    donationThankingText: 'Täname annetamast!',
    tipTitle: 'kas soovite anda jootraha?',
    tipSubtitle: 'Palun valige jootraha summa:',
    tipInputPlaceholder: 'Sisestage jootraha protsent siia',
    tipButtonOkText: 'Ok',
    tipConfirmationTitle: 'Kinnitage jootraha protsent:',
    tipAmountReplacementIdentifier: '_amt_',
    tipConfimationSubtitle:
      'Olete lisamas _amt_ protsenti kogusummast jootrahana',
    tipButtonConfirmText: 'Kinnita',
    tipButtonCancelText: 'Katkesta',
    tipThankingText: 'Täname jootraha eest!',
    emailLookupTitle: 'Otsi klienti emaili järgi',
    emailLookupSubtitle: 'Sisestage email millele soovite saada tšekki',
    emailLookupInputPlaceholder: 'Sisestage email siia',
    emailLookupButtonOkText: 'Ok',
    phoneLookupTitle: 'Otsi klienti telefoninumbri järgi',
    phoneLookupSubtitle: 'Palun sisestage telefoninumber',
    phoneLookupInputPlaceholder: 'Sisestage telefoninumber siia',
    phoneLookupButtonOkText: 'Ok',
    registerCustomerTitle: 'Registreeri uus klient',
    registerCustomerConfirmationTitle: 'Kinnita registreerimine',
    registerCustomerConfirmationSubtitle: 'Palun kinnitage oma registratsioon',
    registerCustomerButtonConfirmText: 'Kinnita',
    registerCustomerButtonCancelText: 'Katkesta',
    registerCustomerSuccessfulText: 'Olete edukalt registreeritud',
    registerCustomerFailedText: 'Teie registreerimine ebaõnnestus',
    couponTitle: 'Sisesta kupongikood',
    couponSubtitle: 'Sisestage kupongikood:',
    couponInputPlaceholder: 'Sisesta kupongikood siia',
    couponConfirmationTitle: 'Kinnitage kupongikood',
    couponButtonOkText: 'Ok',
    couponCodeReplacementIdentifier: '_coupon_',
    couponConfirmationSubtitle: 'Sisestasite kupongikoodiks _coupon_',
    couponButtonConfirmText: 'Kinnita',
    couponButtonCancelText: 'Katkesta',
    couponCodeAppliedTitle: 'Kupongikood edukalt rakendatud',
    couponCodeDiscountIdentifier: '_couponDiscount_',
    couponCodeAppliedSubtitle:
      'Te saite kupongilt allahindluse _couponDiscount_',
    couponCodeFailedTitle: 'Kupongi lisamine ebaõnnestus',
    couponCodeFailedSubtitle:
      'Palun teavitage teenindajat kui soovite uuesti proovida',
    upsellTitle: 'Päeva parimad pakkumised',
    upsellPriceText: 'Erihind',
    upsellAddText: 'Lisa',
    upsellConfirmationTitle: 'Kinnita tellimus',
    upsellConfirmationSubtitle: 'Palun kinnitage toote lisamine',
    upsellConfirmationButtonConfirmText: 'Kinnita',
    upsellConfirmationButtonCancelText: 'Katkesta',
    upsellPurchaseSuccessTitle: 'Tellimus õnnestus!',
    upsellPurchaseSuccessSubtitle: 'Täname!',
    upsellPurchaseFailedTitle: 'Tellimus ebaõnnestus',
    thankYouForShoppingText: 'Külastage meid jälle!',
    qrCodeDataText: '',
    qrTitle: 'Koodi ei saadetud',
    qrSubtitle: 'Palun küsige õiget QR koodi',
    rateServiceTitle: 'Palun hinnake meie teenindust',
    rateServiceSubtitle:
      'Teie tagasisidet kasutatakse teeninduse parandamiseks',
    rateServiceCompletedTitle: 'Täname tagasiside eest!',
    rateServiceCompletedSubtitle: '',
    rateServiceInputPlaceholder: 'Kommentaar',
    customerCheckInTitle: 'Tere tulemast',
    customerCheckInSubtitle: 'Valige tegevus',
    customerCheckInAddCustomer: 'Registreeri kliendiks',
    customerCheckInLookUpCustomer: 'Leia registreeritud klient',
    customerCheckInCoupon: 'Kasuta kupongi',
    registerCustomerForm: {
      label: {
        firstName: 'Sisestage oma eesnimi',
        lastName: 'Sisestage oma perekonnanimi',
        email: 'Sisestage oma e-posti aadress',
        zip: 'Sisestage oma postiindeks',
        code: 'Sisestage oma isikukood',
        customerCardNumber: 'Sisestage oma kliendikaardi number',
        phone: 'Sisestage oma telefoni number',
        mobile: 'Sisestage oma mobiilinumber',
        birthday: 'Sisestage oma sünnipäev',
        emailOptOut: 'Kas soovite e-posti teel pakkumisi',
      },
      placeholder: {
        firstName: 'Sisestage oma eesnimi',
        lastName: 'Sisestage oma perekonnanimi',
        email: 'Sisestage oma e-posti aadress',
        zip: 'Sisestage oma postiindeks',
        code: 'Sisestage oma isikukood',
        customerCardNumber: 'Sisestage oma kliendikaardi number',
        phone: 'Sisestage oma telefoni number',
        mobile: 'Sisestage oma mobiilinumber',
        birthday: 'Sisestage oma sünnipäev',
        emailOptOut: '',
      },
      requiredMessage: {
        firstName: 'Eesnimi on kohustuslik',
        lastName: 'Perekonnanimi on kohustuslik',
        email: 'E-post on kohustuslik',
        zip: 'Postiindeks on kohustuslik',
        code: 'Isikukood on kohustuslik',
        customerCardNumber: 'Kliendikaardi number on kohustuslik',
        phone: 'Telefoni number on kohustuslik',
        mobile: 'Mobiili number on kohustuslik',
        birthday: 'Sünnipäev on kohustuslik',
        emailOptOut: '',
      },
    },
  },
};
