import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { openModalPage } from 'actions/ModalPage/openModalPage';

import { modalPages } from 'constants/modalPage';

import styles from './listGroup.module.css';

/**
 * The [Add product] button (line?) visible at the bottom of the bill in mobile view
 */
const ListGroup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('billTable');
  return (
    <div className={styles['list-group']}>
      <a
        href="#"
        className="addProductBtn"
        onClick={() =>
          dispatch(openModalPage({ component: modalPages.searchProducts }))
        }
      >
        <p>{t('functions.addProduct')}</p>
        <div>
          <svg
            className="flip-horizontal"
            width="20"
            height="20"
            viewBox="0 0 32.24800109863281 32.24800109863281"
            fill="#cccccc"
          >
            <g>
              <path d="M 19,0C 11.82,0, 6,5.82, 6,13c0,3.090, 1.084,5.926, 2.884,8.158l-8.592,8.592c-0.54,0.54-0.54,1.418,0,1.958 c 0.54,0.54, 1.418,0.54, 1.958,0l 8.592-8.592C 13.074,24.916, 15.91,26, 19,26c 7.18,0, 13-5.82, 13-13S 26.18,0, 19,0z M 19,24 C 12.934,24, 8,19.066, 8,13S 12.934,2, 19,2S 30,6.934, 30,13S 25.066,24, 19,24z" />
            </g>
          </svg>
        </div>
      </a>
    </div>
  );
};

export default ListGroup;
