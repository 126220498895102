import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import {
  getCurrencyFormatter,
  getDateFormatter,
  getUISetting,
} from 'reducers/configs/settings';
import Icon from 'components/Icon';
import { modalPages } from 'constants/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getPayments } from 'services/ErplyAPI/sales';
import { getIssuedCoupons } from 'services/ErplyAPI/campaigns';
import { printCoupon, printReceipt } from 'actions/integrations/printer';
import { getIsMSRunning } from 'actions/integrations/printer/utils';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { addError, addWarning, dismissType } from 'actions/Error';
import { prepareCouponData } from 'actions/integrations/printer/preparePrintoutData';
import { RootState } from 'reducers';

const useStyles = makeStyles({
  toggleRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '100%',

      '&:first-child': {
        /** @see {@link https://www.joren.co/flex-grow-9999-hack/} */
        flexGrow: 999,
        minWidth: '300px',
      },
      '&:nth-child(2)': {
        flexGrow: 1,
        minWidth: '238px',
      },
    },
  },
});

type Props = {
  sale: SaleDocumentResponse;
  showName: boolean;
  isSaleFullyReturned: boolean;
};

/**
 * Display sale related details per row: date, time, number, client, sum as well as print, email and start return buttons.
 * ShowName determines if customer name should be shown on the row. It should be passed as false if customer specific sales are opened.
 */

const ToggleRow: React.FC<Props> = ({
  sale,
  showName,
  isSaleFullyReturned,
}) => {
  const { t } = useTranslation('customer');
  const styles = useStyles();
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const dateFormatter = useSelector(getDateFormatter);
  const formatCurrency = useSelector(getCurrencyFormatter);
  const shouldHideRecentSales = useSelector(getUISetting('hideRecentSales'));
  const returnShouldBeHidden =
    isSaleFullyReturned ||
    // Exact exchanges (total: 0.00) don't get payments assigned (since there's nothing to pay for), thus are marked as "UNPAID"
    (sale.paymentStatus === 'UNPAID' && sale.type !== 'CREDITINVOICE');

  const print = async ({ giftReceipt = false } = {}) => {
    const isReceiptPrintoutAPIRunning = await dispatch(
      getIsMSRunning('receipt-printout-api'),
    );
    // do not fetch payments if receipt-printout-api MS is running
    // receipt-printout-api MS only needs sales document id for printing
    const payments = !isReceiptPrintoutAPIRunning
      ? await getPayments({
          documentID: sale.id,
        })
      : [];
    // @ts-ignore
    dispatch(printReceipt({ payments, ...sale }, { giftReceipt }));
  };

  const printSaleRelatedCoupons = async () => {
    dispatch(
      addWarning(t('recentPurchases.coupons.fetching'), {
        errorType: 'recent-sales-coupon-fetching',
      }),
    );

    // If opened from recent sales, showName is true
    const issuedCouponsForSale = await getIssuedCoupons({
      issuedInvoiceIDs: String(sale.id),
    })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch issued coupons for sale', sale, err);
        dispatch(addError(t('recentPurchases.coupons.error')));
      })
      .finally(() => dispatch(dismissType('recent-sales-coupon-fetching')));

    if (!issuedCouponsForSale || !issuedCouponsForSale.length) {
      return dispatch(
        addWarning(t('recentPurchases.coupons.none'), {
          selfDismiss: 4000,
          dismissible: true,
        }),
      );
    }

    return issuedCouponsForSale.forEach(
      async ({ uniqueIdentifier, couponID }) => {
        dispatch(
          addWarning(t('recentPurchases.coupons.preparing'), {
            errorType: 'recent-sales-coupon-preparing',
          }),
        );
        const data = await dispatch(
          prepareCouponData({
            uniqueIdentifier,
            couponID: Number(couponID),
          }),
        );
        dispatch(dismissType('recent-sales-coupon-preparing'));
        dispatch(
          addWarning(t('recentPurchases.coupons.printing'), {
            errorType: 'recent-sales-coupon-printing',
          }),
        );
        await dispatch(printCoupon(data));
        dispatch(dismissType('recent-sales-coupon-printing'));
      },
    );
  };

  const sendEmail = () => {
    dispatch(
      openModalPage({
        component: modalPages.emailModal,
        isPopup: true,
        props: {
          customerEmail: sale.clientEmail,
          invoiceID: sale.id,
        },
      }),
    );
  };

  return (
    <div className={styles.toggleRow}>
      <div>
        <div>
          <div>
            <strong
              data-testid="sale-number"
              data-test-key={`sale-number-${sale.number}`}
            >
              {sale.number}
            </strong>
            {showName && (
              <strong
                data-testid="client-name"
                data-test-key={`${sale.clientName}-${sale.number}`}
                className="ml-4"
              >
                {sale.clientName}
              </strong>
            )}
          </div>
          <div>
            <span
              data-testid="sale-date"
              data-test-key={`sale-date-${sale.number}`}
              className="mr-2"
            >
              {dateFormatter(sale.date)}
            </span>
            <span
              data-testid="sale-time"
              data-test-key={`sale-time-${sale.number}`}
              className="mr-2"
            >
              {sale.time}
            </span>
          </div>
        </div>
        <div>
          <strong
            data-testid="sale-total"
            data-test-key={`sale-total-${sale.number}`}
          >
            {formatCurrency(sale.total)}
          </strong>
        </div>
      </div>
      <div>
        <Box
          display="flex"
          alignItems="start"
          marginX="0.5rem"
          gridGap="0.5rem"
          width="calc(4 * 24px + 3 * 0.5rem)"
          onClick={e => e.stopPropagation()}
        >
          <Icon
            title={t('customerView.buttons.printReceipt')}
            invert
            data-testid="print-receipt"
            data-test-key={`print-receipt-${sale.number}`}
            name="icon_printer"
            action={() => print()}
            style={{
              display: !shouldHideRecentSales ? 'block' : 'none',
            }}
          />
          <Icon
            title={t('customerView.buttons.printGiftReceipt')}
            invert
            name="icon_gift_alt"
            data-testid="print-gift-receipt"
            data-test-key={`print-gift-receipt-${sale.number}`}
            action={() => print({ giftReceipt: true })}
          />
          <Icon
            title={t('customerView.buttons.coupon')}
            invert
            name="icon_tags_alt"
            data-testid="print-coupon"
            data-test-key={`print-coupon-${sale.number}`}
            action={printSaleRelatedCoupons}
            style={{
              display: sale.issuedCouponIDs?.length ? 'block' : 'none',
            }}
          />
          <Icon
            title={t('customerView.buttons.emailReceipt')}
            invert
            name="icon_mail_alt"
            data-testid="email-receipt"
            data-test-key={`email-receipt-${sale.number}`}
            action={sendEmail}
            style={{
              display: sale.clientEmail ? 'block' : 'none',
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          data-testid="start-return"
          data-test-key={`start-return-${sale.number}`}
          style={{
            visibility: returnShouldBeHidden ? 'hidden' : 'visible',
          }}
          onClick={e => {
            e.stopPropagation();
            dispatch(
              openModalPage({
                props: {
                  saleNumberProp: sale.number,
                },
                component: modalPages.ProductReturn,
                modalClassName: 'product-return-modal',
              }),
            );
          }}
        >
          <span>{t('customerView.buttons.startReturn')}</span>
        </Button>
      </div>
    </div>
  );
};

export default ToggleRow;
