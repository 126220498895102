import { getPluginSelector } from 'reducers/Plugins';

import { meta } from '../meta';

// types
type State = {
  canPerformCFRReturn: boolean | null;
  isOnlineReturn: boolean;
};

// action types
const CAN_PERFORM_CFR_RETURN = 'PLUGIN:CFR:CAN_PERFORM_CFR_RETURN';
const IS_ONLINE_RETURN = 'PLUGIN:CFR:IS_ONLINE_RETURN';

// actions
export function setCanPerformCFRReturn(payload: boolean) {
  return {
    type: CAN_PERFORM_CFR_RETURN,
    payload,
  };
}

export function setIsOnlineReturn(payload: boolean) {
  return {
    type: IS_ONLINE_RETURN,
    payload,
  };
}

// reducers

const initState: State = {
  canPerformCFRReturn: null,
  isOnlineReturn: false,
};

const CFRReducer = (
  state = initState,
  { type, payload }: { type: string; payload: boolean },
) => {
  switch (type) {
    case CAN_PERFORM_CFR_RETURN:
      return { ...state, canPerformCFRReturn: payload };
    case IS_ONLINE_RETURN:
      return { ...state, isOnlineReturn: payload };
    default:
      return state;
  }
};

export const getCFRState = state =>
  getPluginSelector<State>(meta.id)(n => n)(state);

export default CFRReducer;
