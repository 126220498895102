import { useToggle } from 'react-use';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import UIButton from 'components/UIElements/UIButton';
import { UniquePrefixWordlist } from 'utils/diceware';
import useProducts from 'utils/hooks/useProducts';
import InputField from 'components/FieldTypes/InputField';

import { DonationProductSearch } from './DonationProductSearch';
import { VisualsConfig } from './VisualsConfig';
import { PromotionsConfig } from './PromotionsConfig';
import { QRCodeConfig } from './QRCodeConfig';
import { Configuration } from './types';
import { CustomerCreationForm } from './CustomerCreationForm';
import { WorkflowConfig } from './WorkflowConfig';
import { FontConfig } from './FontConfig';

import {
  getChannelName,
  getDonationProductId,
  getShouldShowOpenCustomerDisplayButton,
} from '.';

export const ComponentConfiguration: Required<
  PosPlugin<Partial<Configuration>>
>['ComponentConfigurationByLevel']['Pos'] = ({ current, save }) => {
  const { t } = useTranslation('customerDisplay');

  const donationID = useSelector(getDonationProductId);
  const shouldShowOpenCustomerDisplayButton = useSelector(
    getShouldShowOpenCustomerDisplayButton,
  );

  const [donationProductId, setDonationProductId] = useState(donationID);
  const {
    products: [donationProduct],
  } = useProducts({ productID: donationProductId });

  const [saving, setSaving] = useToggle(false);

  const saveWorkflowConfig = newWorkflowConfig => {
    setSaving(true);
    save({
      ...current,
      ...newWorkflowConfig,
    });
  };

  useEffect(() => setSaving(false), [current, setSaving]);

  const openExternalCustomerDisplay = async () => {
    window.open('https://customer-display.erply.com/#/');
  };

  function saveConfig<TKey extends keyof Configuration>(
    key: TKey,
    value: Partial<Configuration[TKey]>,
  ) {
    setSaving(true);
    save({
      ...current,
      [key]: value,
    });
  }

  if (!current)
    return (
      <div>
        {t('titles.noPassphrase')} <br />
        <UIButton
          text={t('buttons.generatePassphrase')}
          action={() =>
            saveConfig(
              'passCode2',
              UniquePrefixWordlist.generateCode(2).join(''),
            )
          }
          disabled={saving}
        />
      </div>
    );
  return (
    <>
      <div>
        <h4>{t('titles.passPhrase')}</h4>
        <UIButton
          text={t('buttons.generatePassphrase')}
          action={() =>
            saveConfig(
              'passCode2',
              UniquePrefixWordlist.generateCode(2).join(''),
            )
          }
          disabled={saving}
        />
        <br />
        <PassPhrase />
      </div>
      <InputField
        value={shouldShowOpenCustomerDisplayButton}
        name="shouldShowOpenCustomerDisplayButton"
        type="checkbox"
        onChange={() =>
          saveConfig(
            'shouldShowOpenCustomerDisplayButton',
            !shouldShowOpenCustomerDisplayButton,
          )
        }
      >
        {t(`titles.shouldShowOpenCustomerDisplayButton`)}
      </InputField>
      <div>
        <h4>{t('titles.externalDisplay')}</h4>
        <UIButton
          text={t('buttons.openExternalCustomerDisplay')}
          action={openExternalCustomerDisplay}
          style={{ marginBottom: '10px' }}
        />
        <div style={{ marginLeft: '5px' }}>
          <h3>{t('titles.externalDisplayConfig')}</h3>
          <WorkflowConfig
            config={R.pick(
              [
                'rowDiscountAsPercentage',
                'afterSaleAction',
                'openCustomerFormOnCreate',
              ],
              current,
            )}
            save={saveWorkflowConfig}
          />
          <CustomerCreationForm
            currentFields={current?.fields ?? {}}
            save={newCustomerFormConf =>
              saveConfig('fields', newCustomerFormConf)
            }
          />
          <VisualsConfig
            fieldTranslations={current?.fieldTranslations ?? {}}
            saveCustomerFormTranslations={newFieldTranslations =>
              saveConfig('fieldTranslations', newFieldTranslations)
            }
            config={current?.config ?? {}}
            saveLocales={newDisplayLocales =>
              saveConfig('config', {
                ...(current?.config ?? {}),
                localizationConstants: newDisplayLocales,
              })
            }
            saveSettings={newDisplayConfigs =>
              saveConfig('config', {
                ...(current?.config ?? {}),
                settings: newDisplayConfigs,
              })
            }
            resetConfig={() => saveConfig('config', {})}
          />
          <PromotionsConfig
            currentPromos={current?.promotions ?? []}
            savePromos={editedPromos => saveConfig('promotions', editedPromos)}
            promotionsType="regular"
          />
          <PromotionsConfig
            currentPromos={current?.smallPromotions ?? []}
            savePromos={editedPromos =>
              saveConfig('smallPromotions', editedPromos)
            }
            promotionsType="small"
          />
          <FontConfig
            config={current?.config?.fontConfig}
            save={fontConfig =>
              saveConfig('config', {
                ...(current?.config ?? {}),
                fontConfig,
              })
            }
          />
          <QRCodeConfig
            config={current?.qrConfig ?? {}}
            saveQRConfig={qrConfig => saveConfig('qrConfig', qrConfig)}
          />
          <div style={{ marginTop: '10px' }}>
            <b>
              {donationProductId === 0
                ? t('titles.noDonationProduct')
                : t('titles.donationProduct', {
                    name: donationProduct?.name,
                  })}
            </b>
            <br />
            <DonationProductSearch
              handleDonationProductSelection={(donationId: number) => {
                setDonationProductId(donationId);
                saveConfig('donationProductId', donationId);
              }}
            />
          </div>
          <div>
            <h4>{t('titles.upsellConfig')}</h4>
            <InputField
              type="checkbox"
              name="showUpsellProductsWithStocksOnly"
              value={current?.showUpsellProductsWithStocksOnly ?? false}
              onChange={e =>
                save({
                  ...current,
                  showUpsellProductsWithStocksOnly: e.target.value,
                })
              }
            >
              {t('upsellForm.showUpsellProductsWithStocksOnly')}
            </InputField>
          </div>
        </div>
      </div>
    </>
  );
};

const PassPhrase = () => {
  const name = useSelector(getChannelName);
  const { t } = useTranslation('customerDisplay');

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('titles.name')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{name}</td>
        </tr>
      </tbody>
    </Table>
  );
};
