import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { setPayment } from 'actions/Payments/setPayment';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { modalPages } from 'constants/modalPage';
import { Payment } from 'types/Payment';
import { RootState } from 'reducers';

import { getIntegration } from './getIntegration';

export function processWithIntegration(
  integration: string,
  payments: Payment[],
) {
  return async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    // 'none' special-cased so that it would not open and close a modal page
    // Ideally in the future we would support this in general, by having integrations open their own modalpage or by having some prop they use to signify they don't need one
    // integration.initPaymentSolo(cardPayments) or something
    if (integration === 'none') {
      // If there's a CARD payment with integration 'none', need to actually mark it as 'paid'
      const actualCardPayments = payments.filter(p => p.type === 'CARD');
      return actualCardPayments.length
        ? actualCardPayments.reduce(
            (prev, pmt) =>
              prev.then(() =>
                dispatch(
                  setPayment({
                    ...pmt,
                    paid: true,
                    forceAmount: true,
                  }),
                ),
              ),
            Promise.resolve(null),
          )
        : Promise.resolve(null);
    }
    return dispatch(getIntegration(integration)).then(data =>
      new Promise((resolve, reject) =>
        dispatch(
          openModalPage({
            component: data.customComponent || modalPages.cardPaymentUI,
            isPopup: true,
            modalClassName: 'card-payment-ui',
            props: {
              integration: data,
              payments,
              resolvePayments: resolve,
              rejectPayments: reject,
            },
            groupID: modalPages.cardPaymentUI,
            replace: false,
          }),
        ),
      ).finally(() =>
        dispatch(
          previousModalPage({
            component: data.customComponent || modalPages.cardPaymentUI,
          }),
        ),
      ),
    );
  };
}
