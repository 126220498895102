import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

import { getWbuConfiguration } from '../configuration';

const UIOriginalPayment: PosPlugin['UIOriginalPayment'] = ({
  payment,
  onClick,
  children,
}) => {
  const { customPayments } = useSelector(getWbuConfiguration);
  const isWbuCustomPayment = customPayments.some(
    cp => payment.cardType === cp.cardType,
  );

  return React.Children.map<ReactNode, ReactNode>(children, child => {
    if (!React.isValidElement(child)) return child;

    const disabled = isWbuCustomPayment || child.props.disabled;
    return React.cloneElement(child, {
      disabled,
      onClick: !disabled ? onClick : undefined,
      style: { cursor: disabled ? 'not-allowed' : 'pointer' },
    });
  });
};

export default UIOriginalPayment;
