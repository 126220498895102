/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from 'reselect';

import { CLOSE_DAY, OPEN_DAY, SET_POSDAYTOTALS } from 'constants/OpenCloseDay';
import { TYPE_LOGIN, TYPE_LOGOUT } from 'constants/Login';
import { PosDayTotal } from 'containers/Forms/OpenCloseDay/OCDTypes';

import { getSetting } from './configs/settings';
import { getUserLoggedIn } from './Login';
import { getEmployeeById } from './cachedItems/employees';

interface CurrentDays {
  dayID: number;
  pointOfSaleID: number;
  openedUnixTime: number;
  shiftType: string;
  currencyCode: string;
}

interface OpenCloseDayState {
  pos_day_totals: Record<string, PosDayTotal[]>;
  current_days: CurrentDays[] | null;
  loading: boolean;
  has_closed_day: boolean | undefined;
}

const initialState: OpenCloseDayState = {
  pos_day_totals: {},
  current_days: null,
  loading: false,
  /**
   * immediately after closing the day, and until the user logs in again, this will contain the details about the closed day
   * Used to avoid showing the open-day dialog after closing so the user can read the z-report in peace
   */
  has_closed_day: undefined,
};

export default (state = initialState, { type, payload }): OpenCloseDayState => {
  switch (type) {
    case TYPE_LOGIN:
      return { ...state, has_closed_day: false };
    case OPEN_DAY:
      return {
        ...state,
        current_days: payload,
      };
    case CLOSE_DAY:
      return {
        ...state,
        current_days:
          state.current_days?.filter(
            cd => String(cd.dayID) !== String(payload.dayID),
          ) ?? null,
        has_closed_day: payload,
      };
    case SET_POSDAYTOTALS:
      return { ...state, pos_day_totals: payload };
    case TYPE_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

function getOpenCloseDaySlice(state): OpenCloseDayState {
  return state.OpenCloseDay;
}

export const getCurrentDays = createSelector(
  state => getOpenCloseDaySlice(state),
  slice => slice.current_days,
);

export const getPosDayTotals = createSelector(
  state => getOpenCloseDaySlice(state),
  slice => slice.pos_day_totals,
);

export const getExpectedTenders = createSelector(
  state => getPosDayTotals(state),
  (totals = {}) => {
    const payload = {} as { [currency: string]: { [tender: string]: number } };
    Object.keys(totals).forEach(curr => {
      payload[curr] = Object.fromEntries(
        totals[curr]
          ?.filter(d => d.paymentType !== 'TIP')
          ?.map(d => [
            [
              `${d.paymentType}${
                ['CARD', 'GIFTCARD'].includes(d.paymentType)
                  ? `-${d.cardType}`
                  : ''
              }`,
            ],
            d.expectedAmount,
          ]) ?? [],
      );
    });
    return payload;
  },
);

export function getExpectedTendersByCurrency(currency = '') {
  return createSelector(getExpectedTenders, (tenders = {}) => tenders[currency]);
}

export const getHasDayOpen = createSelector(
  state => getCurrentDays(state),
  current_days => current_days && current_days.length > 0,
);

export function getCurrentDayByCurrencyCode(currencyCode) {
  return createSelector(getCurrentDays, days =>
    days ? days.find(d => d.currencyCode === currencyCode) : null,
  );
}

export function getCurrentDay(state) {
  return state.OpenCloseDay.current_days?.[0];
}

export function getIsLoading(state) {
  return state.OpenCloseDay.current_days === null;
}

export const getShowExpected = createSelector(
  getSetting('touchpos_eod_disable_show_expected'),
  disableShowExpected => !disableShowExpected,
);

export const getShowDeposit = createSelector(
  getSetting('touchpos_disable_deposit_on_eod'),
  disableDepositSetting => !disableDepositSetting,
);

export const getCloseDayNotesMandatory = createSelector(
  getSetting('closing_day_notes_mandatory'),
  setting => !!setting,
);

export const getAllowedDifference = createSelector(
  getSetting('touchpos_eod_allowed_difference'),
  allowedDifferenceSetting =>
    allowedDifferenceSetting.length !== 0
      ? Number(allowedDifferenceSetting)
      : Number.MAX_VALUE,
);

export const getCheckOnlyCashOnEOD = getSetting('touchpos_check_only_cash');

export const getNotesRequired = getSetting('touchpos_eod_notes_required');

// if drawer cash count used, keep loading till we fetch employee data and see if there is a drawer assigned
export const getIsOpenDayReady = createSelector(
  state => state,
  getIsLoading,
  state => getSetting('pos_shifts_counted')(state) === 'BY_DRAWER',
  getUserLoggedIn,
  (state, dayIsLoading, drawerUsed, loggedInUser) => {
    const { id } = getEmployeeById(loggedInUser?.employeeID)(state) ?? {};
    return drawerUsed ? !id || dayIsLoading : dayIsLoading;
  },
);

export function getHasJustClosedDay(state) {
  return !!state.OpenCloseDay.has_closed_day;
}

export function getCurrentClosedDay(state) {
  return state.OpenCloseDay.has_closed_day;
}

export const getDayForZreport = createSelector(
  state => getHasJustClosedDay(state),
  state => getCurrentDay(state),
  state => getCurrentClosedDay(state),
  (justClosed, ifCurrent, ifClosed) => (justClosed ? ifClosed : ifCurrent),
);
