import React, { useState } from 'react';
import { Box, makeStyles, Popover, Typography } from '@material-ui/core';

import Icon from 'components/Icon';

const useStyles = makeStyles({
  icon: {
    marginLeft: '3px !important',
    fontWeight: 600,
    fontSize: 'inherit',
    cursor: 'pointer !important',
  },
  popover: {
    width: '300px',
  },
});

function RenewalTooltip() {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const setAnchorElement = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(prev => (prev ? null : event.currentTarget));
  };

  return (
    <>
      <Icon
        name="icon_info_alt"
        className={styles.icon}
        aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        // @ts-ignore
        onClick={setAnchorElement}
      />
      <Popover
        classes={{ paper: styles.popover }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <Box padding="1rem">
          <Typography>
            Membership end date can be set only after the start of the last
            active membership purchase.
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default RenewalTooltip;
