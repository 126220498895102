import { Box, Typography, Divider, Button } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import Loader from 'components/Loader';
import { addError } from 'actions/Error';

import { RetryProps } from '../types';

const MembershipActivationRetry: FC<RetryProps> = ({
  error: propError,
  handleMembership,
  resolve,
}) => {
  const dispatch = useDispatch();

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(propError);

  const retry = async () => {
    try {
      setIsSaving(true);
      await handleMembership();
      setIsSaving(false);
      dispatch(previousModalPage());
      resolve('Success');
    } catch (e) {
      setIsSaving(false);
      const errMsg = e.message ?? e ?? 'Could not access membership service';
      dispatch(addError(errMsg));
      setError(errMsg);
    }
  };

  return (
    <Loader show={isSaving} loadingText="Retrying...">
      <Box padding="1rem">
        <Box display="flex" alignItems="center" marginBottom="0.75rem">
          <Box>
            <Typography variant="h5">Updating membership failed</Typography>
          </Box>
        </Box>
        <Divider style={{ marginBottom: '1em' }} />
        <Box>
          <Typography>
            Updating membership failed due to following reason:
            <br />
            {error}
          </Typography>
        </Box>
        <Box marginTop="28px">
          <Typography>
            Warning: clicking Continue Anyway, will concluded the sale, but the
            membership might not be correctly created for the customer and needs
            to be added later.
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap="0.5rem"
          marginTop="28px"
        >
          <Button
            onClick={() => retry()}
            fullWidth
            variant="contained"
            color="primary"
            style={{ textTransform: 'uppercase' }}
          >
            Retry
          </Button>
          <Button
            onClick={() => {
              dispatch(previousModalPage());
              resolve('User chose to continue');
            }}
            style={{ textTransform: 'uppercase' }}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Continue anyway
          </Button>
        </Box>
      </Box>
    </Loader>
  );
};

export default MembershipActivationRetry;
