import React from 'react';
import { useSelector } from 'react-redux';

import { Order } from 'types/ShoppingCart';

import { getOrderAmount } from '../utils';

const DisplayedRowAmount = React.memo<Pick<Order, 'orderIndex'>>(
  ({ orderIndex }) => {
    const amount = useSelector(getOrderAmount(orderIndex));
    return <div className="amount-gift-card-serial">{amount}</div>;
  },
);

export default DisplayedRowAmount;
