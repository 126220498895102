import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { setZipCode } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { addError } from 'actions/Error';
import layers from 'assets/layers.module.css';

type Props = {
  resolve: (res?: any) => void;
  reject: (err?: any) => void;
};

const EnterZipModal: FC<Props> = ({ reject, resolve }) => {
  const [error, setError] = useState('');
  const [zip, setZip] = useState('');
  const dispatch = useDispatch();

  const validateZipCode = (elementValue: string) => {
    const zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
    return zipCodePattern.test(elementValue);
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!validateZipCode(e.currentTarget.value)) {
      setError('ZIP code must be either 5 digits long, or ZIP +4 format.');
    } else {
      setError('');
    }
    setZip(e.currentTarget.value);
  };

  const onCancel = () => {
    dispatch(previousModalPage());
    return reject();
  };
  const onSave = () => {
    if (error || zip.length === 0) {
      dispatch(
        addError('ZIP code must be either 5 digits long, or ZIP +4 format.'),
      );
      return;
    }
    dispatch(setZipCode(zip));
    dispatch(previousModalPage());
    return resolve(zip);
  };

  useShortcut('Escape', onCancel, 100);
  useShortcut('Enter', onSave, 100);

  return (
    <Modal
      data-testid="customer-zip"
      show={true}
      backdropClassName={layers.confirmation}
    >
      <Modal.Header>
        <h3 className="font-weight-bold">Customer ZIP required</h3>
      </Modal.Header>
      <Modal.Body>
        <InputField
          autoFocus
          data-testid="value"
          errorText={error || ''}
          onChange={handleChange}
          value={zip}
          isInvalid={!!error}
        />

        <div className="text-right mt-3">
          <UIButton
            data-testid="cancel"
            className="btn-cancel mr-1"
            action={onCancel}
            text="Cancel"
          />
          <UIButton
            data-testid="done"
            className="save-button-view"
            action={onSave}
            text="Done"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EnterZipModal;
