/* eslint-disable @typescript-eslint/camelcase */
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { PosPlugin } from 'plugins/plugin';
import { updateCurrSaleDocTaxExNumber } from 'actions/sales';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { ErplyAttributes } from 'utils';
import { calculate } from 'actions/ShoppingCart/calculate';
import { getCurrentSalesDocument } from 'reducers/sales';
import { getSelectedCustomer } from 'reducers/customerSearch';

import constants from './constants';
import * as components from './components';

/**
 * This plugin provides functionality for customers tax exemption
 * If the customer has TIN added to the customer card and the expiration date has not passed yet,
 * when a customer is selected, the TIN will be added to the tax exempt number for the current sale document
 */

const prefix = 'PLUGIN:TAX_EXEMPT:';

dayjs.extend(isSameOrBefore);

export const ADD_PRODUCT_VATRATE_ID = `${prefix}ADD_PRODUCT_VATRATE_ID`;
export const REMOVE_PRODUCT_VATRATE_ID = `${prefix}REMOVE_PRODUCT_VATRATE_ID`;
export const RESET_STATE = `${prefix}RESET_STATE`;

const taxExempt: PosPlugin = {
  id: constants.id,
  name: constants.name,
  info:
    'Tax Identification Number(TIN) is added via BO. Clients with active TIN are exempted from taxation. If TIN has expired the user is informed via Popup.',
  keywords: ['pnp', 'tax exempt', 'TIN', 'tax free'],
  getStatus: () => ({ type: 'valid', message: 'Ready for testing' }),
  reduxReducer: (state = {}, { type, payload }) => {
    switch (type) {
      default:
        return state;
      case ADD_PRODUCT_VATRATE_ID:
        return { ...state, [payload.orderIndex]: payload.vatrateID };
      case RESET_STATE:
        return {};
    }
  },
  onSetCustomer: {
    before: () => async (dispatch, getState) => {
      const currentCustomer = getSelectedCustomer(getState());
      const attributes = new ErplyAttributes(currentCustomer.attributes).asDict;
      const salesDocument = getCurrentSalesDocument(getState());
      // reset tax exempt number if it was auto-populated from previously selected customer
      if (
        salesDocument.taxExemptCertificateNumber &&
        salesDocument.taxExemptCertificateNumber ===
          attributes.tax_exempt_number
      ) {
        await dispatch(
          updateCurrSaleDocTaxExNumber({ taxExemptCertificateNumber: '' }),
        );
        dispatch(calculate());
      }
    },
    after: (props, customerData) => async dispatch => {
      const {
        tax_exempt_number,
        tax_exempt_expiration_date,
        custom_tax_exempt_enabled, // Full tax exemption enabled
        partial_tax_exemption, // Partial tax exemption enabled
      } = new ErplyAttributes(customerData.attributes).asDict;
      if (
        tax_exempt_number &&
        (Number(custom_tax_exempt_enabled) || Number(partial_tax_exemption))
      ) {
        const date = dayjs(
          new Date(tax_exempt_expiration_date),
          'MM/DD/YYYY',
          true,
        );
        const dateIsActive = dayjs().isSameOrBefore(date, 'date');

        if (!tax_exempt_expiration_date || dateIsActive) {
          await dispatch(
            updateCurrSaleDocTaxExNumber({
              taxExemptCertificateNumber: tax_exempt_number,
              isPartialExempt: !!Number(partial_tax_exemption),
            }),
          );
          dispatch(calculate());
        } else {
          await dispatch(
            openModalPage({
              component: modalPages.pluginModal,
              props: {
                name: constants.components.ExpiredTIN,
                expirationTime: tax_exempt_expiration_date,
                isValidFormat: date.isValid(),
              },
              isPopup: true,
            }),
          );
        }
      }
    },
  },
  components,
};

export default taxExempt;
