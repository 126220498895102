import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ErplyLogo from '../assets/img/erply-logo-white.svg';

import ThemeSwitch from './ThemeSwitch';

const Header = () => {
  const [dropdownVisible, toggleDropdownVisible] = useState(false);
  const { t } = useTranslation('header');

  return (
    <>
      <nav style={style.appHeader} role="navigation">
        <div style={{ ...style.verticalCenter, ...style.headerItem }}>
          <img
            src={ErplyLogo}
            style={style.headerLogo}
            id="navbar-logo"
            className="app-brand hidden-mobile"
            alt="Logo"
          />
        </div>

        <div style={style.space} />

        <ThemeSwitch />

        <a
          className="d-none d-sm-block Disable-link-decoration"
          style={style.headerItem}
          href={`tel:${t('phone').replace(/\s/g, '')}`}
        >
          {t('phone')}
        </a>
        <a
          className="d-none d-sm-block Disable-link-decoration"
          style={style.headerItem}
          href="https://reg.erply.com/password_reminder/"
        >
          {t('forgotPassword')}
        </a>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          className="d-block d-sm-none Clickable-icon"
          style={{ ...style.headerItem, ...style.headerToggle }}
          onClick={() => toggleDropdownVisible(!dropdownVisible)}
        >
          {[0, 1, 2].map(i => (
            <i key={i} className="icon_stop" style={{ fontSize: '12px' }} />
          ))}
        </div>
      </nav>
      <div
        className="d-sm-none erply-header"
        style={{
          ...style.dropdown,
          display: dropdownVisible ? 'block' : 'none',
        }}
      >
        <a
          className="Disable-link-decoration"
          style={style.headerItem}
          href={`mailto:${t('email')}`}
        >
          ${t('email')}
        </a>
        <br />
        <a
          className="Disable-link-decoration"
          style={style.headerItem}
          href={`tel:${t('phone').replace(/\s/g, '')}`}
        >
          ${t('phone')}
        </a>
        <br />
        <a
          className="Disable-link-decoration"
          style={style.headerItem}
          href="/"
        >
          ${t('forgotPassword')}
        </a>
      </div>
    </>
  );
};

export default Header;

const headerStyles = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: 'white',
};
const style = {
  appHeader: {
    ...headerStyles,
    display: 'flex',
    alignItems: 'stretch',
    border: '0',
    height: '48px',
    minHeight: '48px',
    padding: '0 1.5em',
  },
  headerToggle: {
    cursor: 'pointer',
  },
  space: {
    flexGrow: 1,
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLogo: {
    height: '21px',
  },
  headerItem: {
    padding: '0 10px',
    lineHeight: '48px',
    textAlign: 'center',
  },
  dropdown: {
    ...headerStyles,
    position: 'fixed',
    right: 0,
    top: 48,
    zIndex: 1,
    borderRadius: '0 0 4px 4px',
  },
};
