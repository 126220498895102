import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getSelectedWarehouse } from 'reducers/warehouses';
import { getProductByID } from 'reducers/cachedItems/products';
import { getPluginConfiguration } from 'reducers/Plugins';
import { pluginId } from 'plugins/pnp/carSales/constants';
import { Configuration } from 'plugins/pnp/carSales/index';
import { getProductsUniversal } from 'actions/productsDB';

export const getRequirementsForProduct = productID => (state): Set<string> => {
  const { state: warehouseState } = getSelectedWarehouse(state);
  const { requirementsByStoreGroup: conf } = getPluginConfiguration<
    Configuration
  >(pluginId)(state);
  const product = getProductByID(productID)(state);
  const { code } = product;
  return new Set(
    Object.entries(conf[warehouseState]?.[code] ?? {})
      .filter(([k, v]) => v)
      .map(([k]) => k),
  );
  // const attrs = new ErplyAttributes(product.attributes).asArray;
  // const requirements = attrs
  //   .filter(attr => Number(attr.attributeValue) === 1)
  //   .map(attr => attr.attributeName.match(/pnp-requirement-(.+?)-(.+)/))
  //   .filter(<T>(a: T): a is NonNullable<T> => !!a)
  //   .filter(match => match[1] === warehouseState)
  //   .map(match => match[2]);
  // return new Set(requirements);
};

export const getRequirementsForProductAction = productID => async (
  dispatch: ThunkDispatch<any, any, Action>,
  getState,
): Promise<Set<string>> => {
  const state = getState();
  const { state: warehouseState } = getSelectedWarehouse(state);
  const { requirementsByStoreGroup: conf } = getPluginConfiguration<
    Configuration
  >(pluginId)(state);

  const {
    products: [result],
  } = await dispatch(getProductsUniversal({ productID: Number(productID) }));

  const { code } = result;

  return new Set(
    Object.entries(conf[warehouseState]?.[code] ?? {})
      .filter(([k, v]) => v)
      .map(([k]) => k),
  );
};
