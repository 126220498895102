import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';

import { addError, addSuccess } from 'actions/Error';
import { withProgressAlert } from 'actions/actionUtils';
import { openPaymentModal } from 'actions/modalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getDefaultCurrency } from 'reducers/configs/settings';
import { ThunkAction } from 'reducers';
import { getProductsUniversal } from 'actions/productsDB';

import { GivexCard } from '../types';
import { GivexCardInput } from '../components/GivexCardInput';
import { getBalance } from '../API/givexAPI';
import { setCardToDeactivate } from '../rdx';
import {
  getGivexConfiguration,
  getValidateGivexCard,
} from '../configuration/Configuration';
import { attemptToDeactivate } from '../payment/overrides';
import { addCardNumberToProductName } from '../utils';

const fetchCardInfo = withProgressAlert('Fetching gift card info')(
  (card: GivexCard) => async dispatch => {
    try {
      const response = await getBalance(card);
      return {
        balance: response.certificateBalance,
        expires: response.certificateExpirationDate,
      };
    } catch (error) {
      dispatch(
        addError(
          `There was an error while checking the gift card balance: ${error.message}`,
        ),
      );
      return null;
    }
  },
);

const deactivate = (card: GivexCard): ThunkAction => async (
  dispatch,
  getState,
) => {
  const { displayName, giftcardRefundProductID } = getGivexConfiguration(
    getState(),
  );

  if (!giftcardRefundProductID || Number(giftcardRefundProductID) < 0) {
    dispatch(
      addError(
        'Cannot deactivate. Product to use as deactivation refund is not configured.',
        { selfDismiss: 6000 },
      ),
    );
    return;
  }

  const cardInfo = await dispatch(fetchCardInfo(card));
  if (!cardInfo) return;
  dispatch(setCardToDeactivate({ ...card, balance: cardInfo.balance }));

  if (!Number(cardInfo.balance)) {
    const didDeactivate = await dispatch(attemptToDeactivate(card));
    if (didDeactivate) {
      dispatch(addSuccess('Successfully deactivated the gift card'));
    }
    dispatch(setCardToDeactivate(null));
    return;
  }

  const currencyCode = getDefaultCurrency(getState());
  const { productsDict } = await dispatch(
    getProductsUniversal(
      { productID: Number(giftcardRefundProductID) },
      { withMeta: false },
    ),
  );
  const refundProduct = productsDict[giftcardRefundProductID];

  dispatch(
    openPaymentModal({
      props: {
        ignoreCurrent: true,
        total: 0,
        currentSalesDocument: {
          type: 'CREDITINVOICE',
          // The product is needed so that refunds can be easily viewed in reports
          modifiedRows: [
            {
              productID: giftcardRefundProductID,
              amount: -1,
              price: cardInfo.balance,
              itemName: addCardNumberToProductName(
                refundProduct.name,
                card.cardNo,
              ),
            },
          ],
        },
        payments: {
          'givex-deactivation': {
            key: 'givex-deactivation',
            locked: true,
            amount: cardInfo.balance,
            type: 'META',
            paymentIntegration: 'givex',
            caption: `${displayName} deactivation`,
            cardNumber: card.cardNo,
            GIVEX: {
              card,
            },
            currencyCode,
            currencyRate: 1,
          },
        },
      },
    }),
  );
};

const GivexDeactivation = () => {
  const dispatch = useDispatch();
  const { displayName } = useSelector(getGivexConfiguration);
  const validate = useSelector(getValidateGivexCard);

  const [card, setCard] = useState<GivexCard>({
    cardNo: '',
    pin: '',
  });
  const validationErrorText = validate(card, 'deactivate');

  const handleClose = () => dispatch(previousModalPage());

  return (
    <div data-testid="givex-deactivation">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1.25rem"
        paddingY="0.75rem"
        borderBottom="1px solid #dee2e6"
      >
        <h3 data-testid="title">
          <b>Deactivate {displayName}</b>
        </h3>
        <div className="d-flex align-content-center">
          <CloseButton action={handleClose} />
        </div>
      </Box>
      <Box padding="1.25rem">
        <GivexCardInput value={card} onChange={setCard} type="deactivate" />
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={!!validationErrorText}
            onClick={() => dispatch(deactivate(card))}
          >
            Start deactivation
          </Button>
          <Typography color="error">{validationErrorText}</Typography>
        </div>
      </Box>
    </div>
  );
};

export default GivexDeactivation;
