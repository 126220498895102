import React from 'react';
import { Button, TextField, Typography } from '@material-ui/core';

import { WarehouseAutocomplete } from 'components/inputs/WarehouseAutocomplete';
import { PosPlugin } from 'plugins/plugin';

import { useSpecialOrderState } from './useSpecialOrderState';
import { TransferOrderSpreadsheet } from './TransferSpreadsheetInput';

type SavedDocument = Parameters<
  Required<Required<PosPlugin>['onSaveSalesDocument']>['after']
>[1]['salesDocument']

export const CreateTransferOrders = ({
  specialOrderState: state,
  salesDocument,
  onConfirm,
}: {
  specialOrderState: ReturnType<typeof useSpecialOrderState>;
  salesDocument: SavedDocument;
  onConfirm: () => void;
}) => {
  return (
    <>
      <WarehouseAutocomplete
        renderInput={props => (
          <TextField
            variant="outlined"
            label="Select warehouses to order products from"
            {...props}
          />
        )}
        multiple
        value={state.warehouses.value}
        onChange={(event, newValue, action, payload) => {
          switch (action) {
            case 'select-option':
              state.warehouses.add(payload!.option);
              break;
            case 'remove-option':
              state.warehouses.remove(payload!.option);
              break;
            case 'clear':
              state.warehouses.clear();
              break;
            default:
              break;
          }
        }}
      />
      {state.warehouses.value.length === 0 ? (
        <Typography>Please select at least one warehouse</Typography>
      ) : (
        <TransferOrderSpreadsheet
          salesDocument={salesDocument}
          specialOrderState={state}
        />
      )}
      <Button
        disabled={!!state.error}
        variant="contained"
        color="primary"
        onClick={onConfirm}
      >
        OK
      </Button>
    </>
  );
};
