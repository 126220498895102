/* eslint-disable @typescript-eslint/camelcase */
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';

import { round, add, ErplyAttributes, trimComma } from 'utils';
import { getIssuedCoupons, getCoupons, getGiftCard } from 'services/ErplyAPI';
import { getEmployeeByIDAsync } from 'utils/hooks/useEmployeeHook';
import { getClientCode, getCompany, getSessionKey } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getSelectedWarehouse, getWarehouseById } from 'reducers/warehouses';
import {
  getBackOfficeFooter,
  getBackOfficeInvoiceAnouncement,
  getBOCodeOnReceipt,
  getBOtotalDiscountLabel,
  getCountryInUse,
  getCurrencyCode,
  getCurrencyFormatterForCurrency,
  getCurrencyFormatterNoSymbol,
  getCurrencyId,
  getDateFormatFromBO,
  getDayCountedByDrawer,
  getDefaultCurrency,
  getEmployeeIdentifierOnReceipt,
  getEnabledCurrencies,
  getFallbackLanguagesFor,
  getIsModuleEnabled,
  getSetting,
  getShouldHideNegativeDiscountsOnReceipt,
  getShouldShowTaxRateInsteadOfName,
  getShowPricesWithTax,
  getTransactionsOnlyFromLastShift,
} from 'reducers/configs/settings';
import { getProductByID } from 'reducers/cachedItems/products';
import * as API from 'services/ErplyAPI/api';
import { ZReportsApi } from 'services/ReportsAPI';
import {
  getAllCurrencies,
  getCurrency,
  getCurrencyByCode,
} from 'reducers/configs/currency';
import {
  getCurrentClosedDay,
  getCurrentDay,
  getDayForZreport,
} from 'reducers/OpenCloseDay';
import { getEndpointForService } from 'reducers/configs/serviceEnpoints';
import { proxy } from 'services/shared';
import { addError, addWarning } from 'actions/Error';
import { getCurrency as fetchCurrencies } from 'actions/configs';
import { getVatRateByID } from 'reducers/vatRatesDB';
import { getOneCustomer } from 'actions/CustomerSearch/getOneCustomer';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { getProductsUniversal } from 'actions/productsDB';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { getEmployeeID } from 'reducers/Payments';
import { getEmployeeIdentifierAsync } from 'actions/EmployeesDB';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { Currency } from 'types/Currencies';
import { IssuedCoupon } from 'types/Coupon';

import { RootState } from '../../../reducers/index';

import { defaultTranslations, getDocumentARDataset } from './utils';

/**
 * Tue Mar 23 9999 07:27:15 GMT+0200 (Eastern European Standard Time)
 * Unix timestamp equivalent of Infinity, kept within the range of 4-digit years for compatibility with systems that might not support dates past that
 * NB: Seconds, not milliseconds, multiply by 1000 for use in javascript
 */
const DISTANT_FUTURE_UNIX = 253377782835;

export async function getReceiptLogoData(dispatch, getState) {
  const url = getSetting('receiptLogoURL')(getState());
  const img = new window.Image();

  // @ts-ignore
  return new Promise<string>((res, rej) => {
    img.setAttribute('crossOrigin', 'anonymous');
    img.onerror = rej;
    img.onload = function() {
      const canvas = document.createElement('canvas');
      // @ts-ignore
      canvas.width = this.width;
      // @ts-ignore
      canvas.height = this.height;
      const ctx = canvas.getContext('2d');
      // @ts-ignore
      ctx.drawImage(this, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      const data = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
      res(data);
    };
    img.src = url;
    setTimeout(
      () => rej(new Error('timeout trying to fetch receipt logo')),
      5000,
    );
  });
}

function prepareRedeemedCouponData(saleDocumentID) {
  return async (_dispatch, getState) => {
    let redeemedCoupons: IssuedCoupon[] = [];
    try {
      redeemedCoupons = await getIssuedCoupons({
        redeemedInvoiceIDs: String(saleDocumentID),
      });
    } catch (error) {
      console.error(error);
    }

    const dateFormatFromBO = getDateFormatFromBO(getState());

    const formatExpiryDate = (couponExpiryDate: string) => {
      const expiryDate = new Date(couponExpiryDate);
      if (expiryDate.toString() === 'Invalid Date') {
        return '';
      }
      return dayjs(expiryDate).format(dateFormatFromBO.toUpperCase());
    };

    return redeemedCoupons.map(coupon => {
      return {
        uniqueIdentifier: coupon.uniqueIdentifier,
        couponCode: coupon.couponCode,
        campaignName: coupon.campaignName,
        expiryDate: formatExpiryDate(coupon.expiryDate),
      };
    });
  };
}

/**
 * A redux action that takes a salesDocument and returns an object with all the necessary data for the receipt
 *
 * @returns
 * ```
 * {
 *   company,
 *   warehouse,
 *   pointOfSale,
 *   salesDocumentNumber,
 *   customerFullName,
 *   employeeFullName,
 *   billTable: {
 *     billRows [],
 *     total,
 *     subTotal,
 *     VAT,
 *     basePrice,
 *     currencyCode,
 *     payments,
 *     translations
 * }
 * ```
 */
export function prepareReceiptData({
  salesDocument,
  type,
}: {
  salesDocument: { id?: string | number; [other: string]: any };
  type?: string | undefined;
}) {
  return async (dispatch, getState) => {
    const state = getState();
    const country = getCountryInUse(state);

    const existingDialects = {
      en: ['AU', 'CA', 'US'],
    };

    const languages = getFallbackLanguagesFor('receipt')(state).flatMap(
      lang => {
        if (existingDialects[lang]?.includes(country)) {
          return [`${lang}-${country}`, lang];
        }
        return [lang];
      },
    );

    const taxComponentsEnabled = getIsModuleEnabled('subvatrates')(state);
    const isWithTax = getShowPricesWithTax(state);

    // Start fetching logo immediately (in parallel), we will use it later
    const asyncLogo = dispatch(getReceiptLogoData).catch(() => '');
    const translationsSeparate = await Promise.all(
      languages.map(lang => {
        return window
          .fetch(
            `${process.env.PUBLIC_URL}/printingLocales/${lang}/printing.json`,
          )
          .then(response => response.json())
          .catch(() => {
            if (lang === 'en') {
              return defaultTranslations;
            }
            return {};
          });
      }),
    );
    const translations = R.reduce(R.mergeDeepLeft, {}, translationsSeparate);

    const docHasTaxComponents = salesDocument?.vatTotalsByTaxRate?.some(
      r => r.components,
    );

    // Ensure we have product data
    salesDocument.id = salesDocument?.id || salesDocument?.invoiceID;
    // Save the salesDoc ID to fetch stuff if needed
    const initialID = salesDocument.id;
    // Fetch salesDocument if necessary
    if (
      !salesDocument.clientID ||
      !salesDocument.rows ||
      // check if provided salesDoc is actualReportsDataset. Ordinary salesdoc doesn't have matrixRows
      !salesDocument.matrixRows ||
      /*
        if the received salesDocument was fetched without getTaxComponents: 1 but taxcomponent module is enabled, we refetch the doc
      */
      (taxComponentsEnabled && !docHasTaxComponents)
    ) {
      if (!salesDocument.id) {
        throw new Error(
          'Cannot PRINT receipt for sales document with no rows and no ID',
        );
      }
      // [salesDocument] = await getSalesDocuments(requestParams);
      // @ts-ignore
      salesDocument = await dispatch(getDocumentARDataset(salesDocument));
    }

    const redeemedCoupons = await dispatch(
      prepareRedeemedCouponData(initialID),
    );

    const hasNumericAttr = (item, attrName) => {
      return Number(new ErplyAttributes(item).get(attrName)) === 1;
    };
    // uses documentRows for kitchen/bar printing
    const rowsForKitchenReceipt = salesDocument?.documentRows?.filter(
      r =>
        hasNumericAttr(r, 'attribute kitchen_item') ||
        hasNumericAttr(r, 'attribute_kitchen_item'),
    );

    // uses documentRows for kitchen/bar printing
    const rowsForBarReceipt = salesDocument?.documentRows?.filter(
      r =>
        hasNumericAttr(r, 'attribute bar_item') ||
        hasNumericAttr(r, 'attribute_bar_item'),
    );

    const determinePrintingRows = () => {
      if (type === 'kitchen') return rowsForKitchenReceipt;
      if (type === 'bar') return rowsForBarReceipt;
      return salesDocument?.documentRows;
    };

    const {
      totalDiscountSumWithVAT,
      totalDiscountSum,
      documentName,
      rounding,
      total,
      totalWithFormat,
      netTotal,
      netTotalWithFormat,
      documentNumber,
      customerName,
      customerCode,
      customerPhone,
      customerEmail,
      customerMobile,
      customerFax,
      customerVatNumber,
      customerManager,
      customerLoyaltyCardCode,
      customerGLNCode,
      customerBankName,
      customerBankAccountNumber,
      customerGroup,
      customerTaxOffice,
      customerAddressStreetAndBuilding,
      customerAddressAddressLine2,
      customerAddressTownOrCity,
      customerAddressPostalCode,
      customerAddressCountry,
      customerAddressState,
      customerAddressFull,
      customerNotes,
      customerContactName,
      customerContactEmail,
      customerContactPhone,
      customerContactMobile,
      customerContactFax,
      receiptLink,
      documentTime,
      documentDate,
      vatTotalsByRate = [],
      vatTotal,
      employeeID,
      baseDocuments,
      printCount,
      previousRewardPointsBalance,
      rewardPointsEarned,
      newRewardPointsBalance,
      // 'currency' is returned from API
      currency,
      // 'currencyCode' does not exist on salesDoc
      // currencyCode
      currencySymbol,
      attribute_added_storecredit,
      customerBalanceChange,
      customerCurrentBalance,
      customerCurrentBalanceWithCurrency,
      documentRowsWithComponents,
      invoiceBalanceWithCurrency,
      invoiceBalance,
    } = salesDocument;
    let {
      // since salesDocument ALWAYS ends up being ARdataset, we get the payments from the doc instead of passing payments down from who knows where
      payments = [],
    } = salesDocument;

    const showNotesOnMainReceipt = getSetting('show_notes_on_main_receipt')(
      state,
    );
    const showOriginalPriceOnMainReceipt = getSetting(
      'show_original_price_on_main_receipt',
    )(state);
    const company = getCompany(state);
    const reduxPaymentTypes = getAllPaymentTypes(getState());
    const pointOfSale = getSelectedPos(state);
    const warehouse = getWarehouseById(pointOfSale.warehouseID)(state);
    const employee = await dispatch(
      getEmployeeByIDAsync(employeeID.toString()),
    );
    const currencyCode = getCurrencyCode(state);
    const BOfooter = getBackOfficeFooter(state);
    const receiptNameOption = getEmployeeIdentifierOnReceipt(state);
    const announcementMessage = getBackOfficeInvoiceAnouncement(state);
    const BOreceiptCode = getBOCodeOnReceipt(state);
    const totalDiscountLabel =
      getBOtotalDiscountLabel(state) ||
      translations.defaults.totalDiscountDefaultValue;
    const CURR = getCurrencyFormatterNoSymbol(state);
    const transform = R.curry((fn, value) =>
      CURR.stringify(fn(CURR.parse(value))),
    );
    const totalDiscountSumWithVatIsNegative =
      CURR.parse(totalDiscountSumWithVAT ?? 0) < 0;

    const shouldShowTaxRateInsteadOfName = getShouldShowTaxRateInsteadOfName(
      state,
    );
    // add currencySymbol and translations to payments so that we can edit the
    // receipt schema properly
    const downspreadProps = (obj: any, props = {}) => {
      if (!obj) return obj;
      if (obj.constructor === Object) {
        const primitiveSubProps = Object.fromEntries(
          Object.entries(obj as { [a: string]: any }).filter(
            ([k, v]) => !v?.map,
          ),
        );
        const newProps = { ...props, ...primitiveSubProps };
        const outerProps = props;
        const hereProps = Object.fromEntries(
          Object.entries(obj).map(([k, v]) => [
            k,
            downspreadProps(v, newProps),
          ]),
        );
        return {
          outer: outerProps,
          ...hereProps,
        };
      }
      if (obj.map) {
        return obj.map(i => downspreadProps(i, props));
      }
      return obj;
    };

    const formatPrice = price => {
      return price.replace(/[^0-9.',-]+/g, '');
    };
    const currencyFormatter = getCurrencyFormatterNoSymbol(state);

    const subTotal = formatPrice(
      isWithTax ? totalWithFormat : netTotalWithFormat,
    );
    const basePrice = currencyFormatter.stringify(Number(netTotal)); // is same as netTotal (why u lie?)
    const VAT = Number(total - netTotal).toFixed(2);
    const vatTotals = vatTotalsByRate.map(vtbr => ({
      ...vtbr,
      tax: shouldShowTaxRateInsteadOfName ? vtbr.rate : vtbr.name,
      netSum: formatPrice(vtbr.netTotalWithFormat),
      vatSum: formatPrice(vtbr.totalWithFormat),
      components: taxComponentsEnabled
        ? getVatRateByID(vtbr.rateID)(state)?.components?.map(comp => ({
            ...comp,
            tax: shouldShowTaxRateInsteadOfName ? `${comp.rate} %` : comp.name,
            // have to manually calculate the components net
            vatSum: currencyFormatter.stringify(
              (
                (vtbr.total / Number(formatPrice(vtbr.rate))) *
                comp.rate
              ).toFixed(2),
            ),
          }))
        : [],
    }));

    const normalizer = number => Math.round(number * 100) / 100;

    const vatPercentage = normalizer(
      Number((vatTotal / netTotal) * 100),
    ).toString();

    const generateCalculationString = (payment, rate) => {
      const trueRate = CURR.stringify(CURR.parse(rate));
      if (!payment.paidWithCurrency || !payment.currency) return '';
      if (currencyCode === payment.currency) return '';
      const stringToReturn = ` (${payment.paidWithCurrency} * ${trueRate})`;
      return stringToReturn;
    };

    if (attribute_added_storecredit && Number(attribute_added_storecredit)) {
      const extraPayment = {
        authorizationCode: '',
        cardHolder: '',
        cardNumber: '',
        cardType: '',
        change: '0,00',
        changeWithCurrency: `0,00 ${currency}`,
        // API format + Schemas use 'currency', thus left unchanged
        currency,
        date: documentDate,
        dateTime: `${documentDate} ${documentTime}`,
        info: '',
        paid: attribute_added_storecredit,
        paidWithCurrency: `${attribute_added_storecredit} ${currency}`,
        payerName: customerName,
        referenceNumber: '',
        sum: attribute_added_storecredit,
        sumWithCurrency: `${attribute_added_storecredit} ${currency}`,
        time: documentTime,
        type: translations?.defaults?.storeCreditAdded,
      };
      payments.push(extraPayment);
    }

    payments = payments?.map(payment => {
      const currency = getCurrencyByCode(
        payment.currencyCode || payment.currency,
      )(getState());
      const currencyRate = currency?.rate ?? 1;
      const applyCurrencyRateToString = transform(n => n * currencyRate);
      const isCard =
        reduxPaymentTypes?.find(p => p.name === payment.type)?.type === 'CARD';

      return {
        ...payment,
        typeTrans: payment.cardType ? payment.cardType.trim() : payment.type,
        currencySymbol: getCurrency(payment.currencyCode || payment.currency)(
          getState(),
        ).symbol,
        currencyRate,
        sum: formatPrice(payment.sumWithCurrency),
        paid: applyCurrencyRateToString(payment.paid),
        multiCurrencyCalculationFormula: generateCalculationString(
          payment,
          currencyRate,
        ),
        isCard,
      };
    });

    // manually go thru all the payments and see whatever payment has a change, otherwise show 0
    const cashChange =
      payments?.find(p => p?.change !== '0.00' && p?.change !== '0,00')
        ?.change || currencyFormatter.stringify(0);

    const formatSalesDocNotes = () => {
      const notesTrans = translations?.defaults?.notesTitle;
      // if for some reason the notesTitle is untranslated, return it as empty string
      const header = notesTrans ? notesTrans.concat(':\n') : '';
      let notes = salesDocument?.notes ?? '';
      if (notes.length > 0) {
        notes = header.concat(notes);
      }
      return notes;
    };

    const getNotesFromRow = row => {
      const showNotes =
        type === 'bar' || type === 'kitchen' || showNotesOnMainReceipt;
      const notesExist = !!row.jdoc?.BrazilPOS?.notes?.length;
      if (showNotes && notesExist) return `(${row.jdoc.BrazilPOS.notes})`;
      return '';
    };

    const getUnitForReceipt = row => {
      if (row.unit) {
        // add a space so that if row has no unit the amount won't have an extra space
        return ` ${row.unit}`;
      }
      return '';
    };

    const getOriginalPriceForRow = row => {
      const text = translations.product.originalPrice;

      const price = formatPrice(
        isWithTax ? row.originalPriceWithVAT.toFixed(2) : row.originalNetPrice,
      );

      const hasDiscount = row.realDiscountBasePrice !== row.originalNetPrice;

      // if setting is toggled and original price is different to the actual price
      if (showOriginalPriceOnMainReceipt && hasDiscount) {
        return `${text}: `.concat(price);
      }
      return '';
    };

    const employeeIdentifier = await dispatch(
      getEmployeeIdentifierAsync(String(employee?.id)),
    );
    const rows = determinePrintingRows();

    const hideNegativeDiscountsSetting = getShouldHideNegativeDiscountsOnReceipt(
      state,
    );
    const shouldHideNegativeDiscounts = discount => {
      if (!discount || Number(discount) === 0) return true;
      if (hideNegativeDiscountsSetting) {
        return discount <= 0;
      }
      return discount === 0;
    };

    const receiptInfoName = warehouse.name || company.name || '';
    const receiptInfoCode = warehouse.code || company.code || '';
    const receiptInfoPhone =
      warehouse.phone || company.phone || company.mobile || '';
    const receiptInfoFax = warehouse.fax || company.fax || '';
    const receiptInfoEmail = warehouse.email || company.email || '';
    const receiptInfoWebsite = warehouse.website || company.web || '';
    const receiptInfoBankAccountNumber =
      warehouse.bankAccountNumber || company.bankAccountNumber || '';
    const receiptInfoBankName = warehouse.bankName || company.bankName || '';
    const receiptInfoAddress = warehouse.address || company.address || '';
    const receiptInfoCountry = warehouse.country || company.country || '';
    const receiptInfoStreet = warehouse.street || company.street || '';
    const receiptInfoCity = warehouse.city || company.city || '';
    const receiptInfoState = warehouse.state || company.state || '';
    const receiptInfoAddress2 = warehouse.address2 || company.address2 || '';
    const receiptInfoZipCode = warehouse.ZIPcode || company.ZIPcode || '';

    const defaultCustomerID =
      pointOfSale.defaultCustomerID || company.defaultClientID;
    const customerID = salesDocument.clientID ?? salesDocument.customerID;
    const isDefaultCustomer = Number(defaultCustomerID) === Number(customerID);

    const customer = {
      customerID,
      customerName,
      customerCode,
      customerPhone,
      customerEmail,
      customerMobile,
      customerFax,
      customerVatNumber,
      customerManager,
      customerLoyaltyCardCode,
      customerGLNCode,
      customerBankName,
      customerBankAccountNumber,
      customerGroup,
      customerTaxOffice,
      customerAddressStreetAndBuilding,
      customerAddressAddressLine2,
      customerAddressTownOrCity,
      customerAddressPostalCode,
      customerAddressCountry,
      customerAddressState,
      customerAddressFull: customerAddressFull
        ?.replace(/<br>/g, ', ')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&#039;/g, "'")
        .replace(/&quot;/g, '"'),
      customerNotes,
      customerContactName,
      customerContactEmail,
      customerContactPhone,
      customerContactMobile,
      customerContactFax,
      customerBalanceChange,
      customerCurrentBalance,
      customerCurrentBalanceWithCurrency,
      isDefaultCustomer,
    };

    const isReturn = total < 0;

    // return signature consists of
    const returnSignature = isReturn
      ? `${translations.defaults.signature}: ${'.'.repeat(40)}`
      : '';

    const formattedBaseDocuments = baseDocuments
      ? `${translations?.defaults?.baseDocument}:\n${baseDocuments}`
      : '';
    const cardPayments =
      payments?.filter(
        p =>
          p.cardType ||
          p.authorizationCode ||
          p.cardHolder ||
          p.cardNumber ||
          p.referenceNumber ||
          p.isCard,
      ) || [];

    const stringToNumber = stringNumber => {
      const number = Number(
        String(stringNumber)
          .replace(',', '.')
          .replace(/\s/, ''),
      );
      if (Number.isNaN(number)) return 0;
      return number;
    };
    const totalItemsSold =
      salesDocument?.documentRows?.reduce((a, row) => {
        return a + stringToNumber(row.amount);
      }, 0) ?? 0;

    const footer = BOfooter || translations?.defaults?.footer;
    const receiptData = {
      locationOrCompanyData: {
        receiptInfoName,
        receiptInfoCode,
        receiptInfoPhone,
        receiptInfoFax,
        receiptInfoEmail,
        receiptInfoWebsite,
        receiptInfoBankAccountNumber,
        receiptInfoBankName,
        receiptInfoAddress,
        receiptInfoCountry,
        receiptInfoStreet,
        receiptInfoCity,
        receiptInfoState,
        receiptInfoAddress2,
        receiptInfoZipCode,
      },
      receiptLink,
      company,
      warehouse,
      pointOfSale: {
        ...pointOfSale,
        lastCouponNo: !pointOfSale.lastCouponNo ? '' : pointOfSale.lastCouponNo,
        lastInvoiceNo: !pointOfSale.lastInvoiceNo
          ? ''
          : pointOfSale.lastInvoiceNo,
      },
      time: documentTime, // hh:mm:ss format, might be changed in future on api side
      date: documentDate, // this one is in localized format (from API docs)
      notes: formatSalesDocNotes(),
      currencySymbol,
      attributes: R.pipe(
        R.pickBy((v, k) => R.test(/attribute_(.+)/)(String(k))),
        RA.renameKeysWith(k => k.replace(/^attribute_/, '')),
      )(salesDocument),
      salesDocumentNumber: documentNumber,
      customerFullName: trimComma(customerName),
      employeeIdentifier: trimComma(employeeIdentifier),
      employeeFullName: trimComma(employee?.fullName),
      billTable: {
        billRows: rows?.map(row => ({
          ...row,
          itemName: row.title || row.productName,
          product: {
            nameWithTranslation: row.title || row.productName,
            productCodeForReceipt: BOreceiptCode ? row[BOreceiptCode] : '',
            ...getProductByID(row.productID)(state),
            unitForReceipt: getUnitForReceipt(row),
            notes: getNotesFromRow(row),
          },
          originalPrice: getOriginalPriceForRow(row),
          originalPriceWithVAT: currencyFormatter.stringify(
            row.originalPriceWithVAT,
          ),
          originalNetPrice: formatPrice(row.originalNetPrice),
          finalNetPrice: formatPrice(row.finalNetPriceWithCurrency),
          rowTotal: formatPrice(row.rowTotalWithCurrency),
          rowNetTotal: formatPrice(row.rowNetTotalWithCurrency),
          discount: shouldHideNegativeDiscounts(row.discount)
            ? ''
            : `-${row.discount}`,
        })),
        totalItemsSold,
        vatRateRows: vatTotals?.map(dataRow => ({
          ...dataRow,
          percentage: dataRow.rate,
        })),
        total: totalWithFormat,
        subTotal,
        totalDiscountSumWithVat: totalDiscountSumWithVAT,
        totalDiscountSumWithVatIsNegative,
        totalDiscountSum,
        VAT,
        basePrice,
        currencyCode,
        payments,
        cardPayments,
        vatPercentage,
        cashChange,
        netTotal: netTotalWithFormat,
        rounding,
        customer,
      },
      customer,
      translations,
      documentName,
      currentPrintNumber: Number(printCount),
      logo: await asyncLogo,
      BOfooter,
      totalDiscountLabel,
      footer,
      baseDocuments: formattedBaseDocuments,
      isReturn,
      returnSignature,
      announcementMessage,
      rewardPointsInfo: {
        previousRewardPointsBalance: round(previousRewardPointsBalance, 2),
        rewardPointsEarned: round(rewardPointsEarned, 2),
        newRewardPointsBalance: round(newRewardPointsBalance, 2),
      },
      invoiceBalanceWithCurrency,
      invoiceBalance,
      redeemedCoupons,
    };
    return downspreadProps(receiptData);
  };
}

function cell(...texts) {
  return {
    pieces: texts.map(text => ({ text: String(text) })),
  };
}
function boldCell(...texts) {
  return {
    pieces: texts.map(text => ({ text: String(text), meta: { bold: true } })),
  };
}
function rCell(...texts) {
  return {
    align: 'right',
    pieces: texts.map(text => ({ text: String(text) })),
  };
}
function rBoldCell(...texts) {
  return {
    align: 'right',
    pieces: texts.map(text => ({ text: String(text), meta: { bold: true } })),
  };
}
function formatDate(unix, dateFormat) {
  return unix
    ? dayjs(unix * 1000 ?? 0).format(`${dateFormat.toUpperCase()} hh:mm a`)
    : '---';
}

export function buildZReportPatchscript({ recursive = true } = {}) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    const state = getState();
    const sessionKey = getSessionKey(state);
    const clientCode = getClientCode(state);
    const currencyId = getCurrencyId(state);
    const format = getDateFormatFromBO(state);
    if (currencyId === undefined) {
      if (recursive) {
        dispatch(
          addWarning('Could not access currency, refetching currency again'),
        );
        await dispatch(fetchCurrencies());
        return dispatch(buildZReportPatchscript({ recursive: false }));
      }
      dispatch(addError('Failed to load currencies, cannot print zReport'));
    }

    const useDrawer = getDayCountedByDrawer(state);
    const getOnlyLastShift = getTransactionsOnlyFromLastShift(state);

    const isMultiCurrencyEnabled = getIsModuleEnabled('pos_multicurrency')(
      getState(),
    );
    const defaultCurrencyCode = getDefaultCurrency(state);
    const defaultCurrency = getCurrencyByCode(defaultCurrencyCode)(state);

    const enabledCurrencies = getEnabledCurrencies(state);

    const currencies: Currency[] = isMultiCurrencyEnabled
      ? getAllCurrencies(state).filter(currency =>
          enabledCurrencies.includes(currency.code),
        )
      : [defaultCurrency].filter((c): c is Currency => !!c);
    const currencyCodes = currencies.map(currency => currency.code);

    const {
      openedUnixTime,
      dayID,
      closedUnixTime = DISTANT_FUTURE_UNIX,
    } = getDayForZreport(state);

    const warehouse = getSelectedWarehouse(state);
    const pos = getSelectedPos(state);
    const posIDs = `${pos.pointOfSaleID}`;
    const whIDs = `${warehouse.warehouseID}`;

    const api = new ZReportsApi(
      undefined,
      `${proxy}${getEndpointForService('reports').url.replace(/\/+$/, '')}`,
    );

    const posDaysForAllCurrencies = await Promise.all(
      currencies.map(currency =>
        api.v1POSDayGet(
          Number(currency.currencyID),
          openedUnixTime,
          closedUnixTime,
          (useDrawer || getOnlyLastShift) && !isMultiCurrencyEnabled
            ? dayID
            : undefined,
          useDrawer || getOnlyLastShift ? undefined : posIDs,
          whIDs,
          undefined,
          undefined,
          undefined,
          {
            headers: {
              sessionKey,
              clientCode,
            },
          },
        ),
      ),
    );
    const totalsByTypeForAllCurrencies = await Promise.all(
      currencies.map(currency =>
        api.v1POSDayTransactionTotalByTypeGet(
          Number(currency.currencyID),
          openedUnixTime,
          closedUnixTime,
          (useDrawer || getOnlyLastShift) && !isMultiCurrencyEnabled
            ? dayID
            : undefined,
          useDrawer || getOnlyLastShift ? undefined : posIDs,
          whIDs,
          undefined,
          undefined,
          {
            headers: { sessionKey, clientCode },
          },
        ),
      ),
    );
    const cashFlowsForAllCurrencies = await Promise.all(
      currencies.map(currency =>
        api.v1POSDayTransactionCashflowGet(
          Number(currency.currencyID),
          openedUnixTime,
          closedUnixTime,
          (useDrawer || getOnlyLastShift) && !isMultiCurrencyEnabled
            ? dayID
            : undefined,
          useDrawer || getOnlyLastShift ? undefined : posIDs,
          whIDs,
          undefined,
          undefined,
          {
            headers: { clientCode, sessionKey },
          },
        ),
      ),
    );
    const posDays = posDaysForAllCurrencies.map(day => day?.data?.[0]);
    const posTotalsByType = totalsByTypeForAllCurrencies.map(
      total => total?.data?.[0],
    );
    const cashflows = cashFlowsForAllCurrencies.map(cashFlow => cashFlow.data);

    if (!posDays.length)
      return [
        {
          type: 'text',
          pieces: [{ text: 'There was an error loading Z report data' }],
        },
      ];

    const ifClosed = (currencyCode, val, def = '---') => {
      const posDay = posDays.find(day => day?.currency?.code === currencyCode);
      return posDay?.closedAtUnix ? val : def;
    };

    const getTransTotal = (currencyCode: string) => {
      const totalsByType = posTotalsByType.find(
        total => total?.currency?.code === currencyCode,
      );
      return (
        totalsByType?.transactionTotals
          ?.filter(t => t.paymentType?.type === 'CASH')
          .map(t => t.income)
          .reduce(add, 0) ?? 0
      );
    };

    const combinedResult = currencyCodes.flatMap((currencyCode, idx) => {
      const posDay = isMultiCurrencyEnabled
        ? posDays?.find(day => day?.currency?.code === currencyCode)
        : posDays[0];
      const transTotal = getTransTotal(currencyCode);
      const totalsByType = isMultiCurrencyEnabled
        ? posTotalsByType?.find(total => total?.currency?.code === currencyCode)
        : posTotalsByType[0];

      const cashInOutTotal =
        cashflows?.[idx]?.map(f => f.amount).reduce(add, 0) ?? 0;

      const formatCurrency = (amount?: string | number) => {
        if (!amount) return '0.00';
        const formatter = getCurrencyFormatterForCurrency(currencyCode)(
          getState(),
        );
        return formatter(amount);
      };

      return [
        {
          type: 'table',
          columns: [
            { weight: 7, baseWidth: 0 },
            { weight: 3, baseWidth: 0 },
          ],
          rows: [
            {
              type: 'header',
              cells: [
                boldCell(useDrawer ? 'Drawer:' : 'Register:'),
                rBoldCell(
                  useDrawer
                    ? posDay?.drawerEmployees?.[0]?.name
                    : posDay?.pointOfSale?.name,
                ),
              ],
            },
            {
              type: 'normal',
              cells: [
                cell(`Initial amount in ${useDrawer ? 'drawer' : 'register'}:`),
                rCell(formatCurrency(posDay?.initialAmount)),
              ],
            },
            {
              type: 'header',
              cells: [
                boldCell(
                  'Opened: ',
                  posDay?.openedBy?.name,
                  ' ',
                  formatDate(posDay?.openedAtUnix, format),
                ),
                'colspan',
              ],
            },
            {
              type: 'normal',
              cells: [cell('Day income:'), rCell(formatCurrency(transTotal))],
            },
            ...(cashflows?.[idx]?.flatMap(f => [
              {
                type: 'normal',
                cells: [
                  cell(
                    formatDate(f.dateTimeUnix, format),
                    ' ',
                    f.by?.name,
                    ' ',
                  ),
                  rCell(formatCurrency(f.amount)),
                ],
              },
              {
                type: 'normal',
                cells: [cell(f.comment), null],
              },
            ]) ?? []),
            {
              type: 'normal',
              cells: [
                cell('Day income + all cash in & out:'),
                rCell(formatCurrency(transTotal + cashInOutTotal)),
              ],
            },
            {
              type: 'normal',
              cells: [
                cell(
                  `Expected amount in ${
                    useDrawer ? 'drawer' : 'register'
                  } at end of day`,
                ),
                rCell(
                  formatCurrency(
                    posDay?.initialAmount + transTotal + cashInOutTotal,
                  ),
                ),
              ],
            },
            ...[
              {
                type: 'header',
                cells: [
                  boldCell(
                    'Closed: ',
                    ifClosed(currencyCode, posDay?.closedBy?.name),
                    ' ',
                    formatDate(posDay?.closedAtUnix, format),
                  ),
                  'colspan',
                ],
              },
              {
                type: 'normal',
                cells: [
                  cell(`Total counted in ${useDrawer ? 'drawer' : 'register'}`),
                  rCell(
                    ifClosed(
                      currencyCode,
                      formatCurrency(
                        (posDay?.closedDeposited ?? 0) +
                          (posDay?.closedLeftAsChange ?? 0),
                      ),
                    ),
                  ),
                ],
              },
              {
                type: 'normal',
                cells: [
                  cell('Deposited:'),
                  rCell(
                    ifClosed(
                      currencyCode,
                      formatCurrency(posDay?.closedDeposited),
                    ),
                  ),
                ],
              },
              {
                type: 'normal',
                cells: [
                  cell(
                    `Left ${
                      useDrawer ? 'in drawer' : 'to register'
                    } as change:`,
                  ),
                  rCell(
                    ifClosed(
                      currencyCode,
                      formatCurrency(posDay?.closedLeftAsChange),
                    ),
                  ),
                ],
              },
              {
                type: 'header',
                cells: [
                  boldCell('Over/short:'),
                  rBoldCell(
                    ifClosed(
                      currencyCode,
                      formatCurrency(
                        totalsByType?.transactionTotals
                          ?.map(t => t.overShortAmount)
                          .reduce(add, 0),
                      ),
                    ),
                  ),
                ],
              },
              {
                type: 'header',
                cells: [
                  boldCell('Transactions total:'),
                  rBoldCell(posDay?.totalTransactions),
                ],
              },
              ...(totalsByType?.transactionTotals?.flatMap(t => [
                {
                  type: 'header',
                  cells: [
                    boldCell(
                      t.paymentType?.type === 'CARD'
                        ? `CARD - ${t.paymentType?.cardType}`
                        : t.paymentType?.type,
                    ),
                    'colspan',
                  ],
                },
                {
                  type: 'normal',
                  cells: [cell('Day income:'), rCell(t.income)],
                },
                ...ifClosed(
                  currencyCode,
                  [
                    {
                      type: 'normal',
                      cells: [cell('Counted:'), rCell(t.counted)],
                    },
                    {
                      type: 'normal',
                      cells: [
                        boldCell('Over/short:'),
                        rBoldCell(t.overShortAmount),
                      ],
                    },
                    {
                      type: 'normal',
                      cells: [
                        cell('Variance reason:'),
                        rCell(t.varianceReasonID),
                      ],
                    },
                  ],
                  [] as any,
                ),
              ]) ?? []),
            ],
          ],
        },
        { type: 'text', pieces: [{ text: '' }] },
        { type: 'text', pieces: [{ text: '' }] },
      ];
    });
    return combinedResult;
  };
}

export function prepareZReportData(params) {
  return async (dispatch, getState) => {
    const useDrawer = getDayCountedByDrawer(getState());
    const { dayID } = getCurrentClosedDay(getState()) || {};
    const fallbackDayID = getCurrentDay(getState())?.dayID.toString();

    const getOnlyLastShift = getTransactionsOnlyFromLastShift(getState());

    const id = dayID || fallbackDayID;
    const { pointOfSaleID, warehouseID } = getSelectedPos(getState());
    return API.getZReport({
      ...(useDrawer || getOnlyLastShift
        ? { dayID: id }
        : { pointOfSaleID, warehouseID }),
      ...params,
      format: undefined,
    });
  };
}

export async function prepareZReportBoHtml(dispatch, getState) {
  const state = getState();

  const allCurrencies = getEnabledCurrencies(state);
  const getOnlyLastShift = getTransactionsOnlyFromLastShift(state);
  const noPaymentTypes = getSetting(
    'touchpos_eod_disable_count_all_payment_types',
  )(state);
  const getShortReport = getSetting('touchpos_get_short_report')(state);

  const useDrawer = getDayCountedByDrawer(state);
  const currentClosedDay = getCurrentClosedDay(state);
  const currentDay = getCurrentDay(state);
  const currentPos = getSelectedPos(state);

  const { dayID = undefined } = currentClosedDay ?? {};
  const fallbackDayID = currentDay?.dayID.toString();
  const { pointOfSaleID, warehouseID } = currentPos;
  const id = dayID || fallbackDayID;

  if (useDrawer && !id) {
    const err = new Error('Error retrieving dayID');
    Sentry.captureException(err, {
      contexts: {
        useDrawer,
        currentClosedDay,
        currentDay,
        currentPos,
      },
    });
    throw err;
  }
  const promises = allCurrencies.map(currency =>
    API.getZReport({
      ...(useDrawer
        ? {
            dayID: id,
            currencyCode: currency,
          }
        : {
            pointOfSaleID,
            warehouseID,
            currencyCode: currency,
          }),
      getShortReport: getShortReport ? 1 : 0,
      getOnlyLastShift: getOnlyLastShift ? 1 : 0,
    }),
  );
  return Promise.all(promises)
    .then(res => res.map(data => data.htm))
    .then(values => values.reduce((acc, cur) => acc.concat(cur), ''))
    .then(
      noPaymentTypes ? prepareZReportBoHtml._removePaymentTypes : R.identity,
    );
}
prepareZReportBoHtml._removePaymentTypes = html => {
  const detach = n => n.parentElement.removeChild(n);

  const temp = document.createElement('div');
  temp.innerHTML = html;
  // Remove payment types
  const tables = [...temp.querySelectorAll('table')];
  const sessionTables = tables.filter(
    table =>
      table
        ?.querySelectorAll('tr')[0]
        ?.querySelectorAll('td, th')[0]
        // @ts-ignore
        ?.innerText.trim() !== '',
  );
  sessionTables.forEach(table => {
    const rows = [...table.querySelectorAll('tr')];
    const transactionsTotalRow = rows.filter(
      r => r.querySelectorAll('strong').length,
    )[4];
    rows.slice(rows.indexOf(transactionsTotalRow) + 1).forEach(detach);
  });
  return temp.innerHTML;
};

export function prepareCouponData({ uniqueIdentifier, couponID }) {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, Action>,
    getState: () => unknown,
  ) => {
    const state = getState();
    const [coupon] = await getCoupons({ couponID });
    const [issuedCoupon] = await getIssuedCoupons({ uniqueIdentifier });
    const dateFormatFromBO = getDateFormatFromBO(getState());

    const formatExpiryDate = () => {
      const expiryDate = new Date(issuedCoupon.expiryDate);
      if (expiryDate.toString() === 'Invalid Date') {
        return '';
      }
      return dayjs(expiryDate).format(dateFormatFromBO.toUpperCase());
    };

    const data = {
      ...issuedCoupon,
      ...coupon,
      expiryDate: formatExpiryDate(),
    };

    const employee = await dispatch(
      getEmployeeByIDAsync(data.issuedEmployeeID),
    );
    const warehouse = getWarehouseById(data.issuedWarehouseID)(state);
    const company = getCompany(state);
    const customer = await getOneCustomer(data.issuedCustomerID)
      .then(res => res.customer)
      .catch(() => undefined); // if coupon has no customer, it will throw an error
    const employeeIdentifier = await dispatch(
      getEmployeeIdentifierAsync(data.issuedEmployeeID),
    );

    return {
      issuedTimestamp: dayjs(new Date(data.issuedTimestamp * 1000)).format(
        `${dateFormatFromBO.toUpperCase()} HH:mm:ss`,
      ),
      employee,
      employeeIdentifier,
      warehouse,
      company,
      customer,
      coupon: data,
      uniqueIdentifier,
    };
  };
}

export function prepareGiftCardData(productID: number, giftCardCode: string) {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, Action>,
    getState: () => unknown,
  ) => {
    const [giftCard] = await getGiftCard({
      code: giftCardCode,
    });
    const {
      products: [product],
    } = await dispatch(getProductsUniversal({ productID }));
    const { customer } = await getOneCustomer(
      String(giftCard.redeemingCustomerID),
    );
    const company = getCompany(getState());
    const pointOfSale = getSelectedPos(getState());

    const employee = getEmployeeById(getEmployeeID(getState()))(getState());

    const warehouse = getWarehouseById(pointOfSale.warehouseID)(getState());

    const lang = getCafaEntry<string, string, string>(
      'language',
      'posConfigurations',
    )(getState())?.value;

    const printTranslations = await window
      .fetch(`${process.env.PUBLIC_URL}/printingLocales/${lang}/printing.json`)
      .then(response => response.json())
      .catch(() => ({}));

    const footer =
      getBackOfficeFooter(getState()) || printTranslations?.defaults?.footer;

    const receiptInfoName = warehouse.name || company.name || '';
    const receiptInfoCode = warehouse.code || company.code || '';
    const receiptInfoPhone =
      warehouse.phone || company.phone || company.mobile || '';
    const receiptInfoFax = warehouse.fax || company.fax || '';
    const receiptInfoEmail = warehouse.email || company.email || '';
    const receiptInfoWebsite = warehouse.website || company.web || '';
    const receiptInfoBankAccountNumber =
      warehouse.bankAccountNumber || company.bankAccountNumber || '';
    const receiptInfoBankName = warehouse.bankName || company.bankName || '';
    const receiptInfoAddress = warehouse.address || company.address || '';
    const receiptInfoCountry = warehouse.country || company.country || '';

    const locationOrCompanyData = {
      receiptInfoName,
      receiptInfoCode,
      receiptInfoPhone,
      receiptInfoFax,
      receiptInfoEmail,
      receiptInfoWebsite,
      receiptInfoBankAccountNumber,
      receiptInfoBankName,
      receiptInfoAddress,
      receiptInfoCountry,
    };

    return {
      giftCard,
      customer,
      product,
      company,
      locationOrCompanyData,
      pointOfSale,
      employee,
      footer,
    };
  };
}
