import { createSelector } from 'reselect';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';

export const getReasonCodesDb: Required<
  PosPlugin
>['selectorOverrides']['getReasonCodesDb'] = base =>
  createSelector(
    base,
    getSetting('hidden_reason_codes'),
    (baseReasonCodes, reasonCodeIdsToHide) => {
      if (!baseReasonCodes.RETURN) return baseReasonCodes;
      const filteredReturnReasons = R.pipe(
        R.toPairs,
        R.map(([lang, reasons]) => [
          lang,
          reasons.filter(
            reason => !reasonCodeIdsToHide.includes(String(reason.reasonID)),
          ),
        ]),
        R.fromPairs,
      )(baseReasonCodes.RETURN);
      return {
        ...baseReasonCodes,
        RETURN: filteredReturnReasons,
      };
    },
  );
