/* eslint-disable no-console */
import axios, { AxiosPromise } from 'axios';

import {
  GetTerminalInfoResponse,
  GetTerminalsResponse,
  LoginResponse,
  NETS_CONST,
  NetsConfig,
  TransactionResponse,
  ValueOf,
} from '../types';

const baseUrls = {
  development: 'https://connectcloud-test.aws.nets.eu:443/v1/',
  production: 'https://api1.cloudconnect.nets.eu/v1/',
};

const getConfigsFromSStorage = () => ({
  token: sessionStorage.getItem(NETS_CONST.TOKEN),
  terminalID: sessionStorage.getItem(NETS_CONST.TERMINAL),
  environment:
    sessionStorage.getItem(NETS_CONST.ENV) === NETS_CONST.ENV_TYPES.DEVELOPMENT
      ? NETS_CONST.ENV_TYPES.DEVELOPMENT
      : NETS_CONST.ENV_TYPES.PRODUCTION,
});

const getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `bearer ${getConfigsFromSStorage().token}`,
});

const login = ({
  username,
  password,
}: NetsConfig): AxiosPromise<LoginResponse> => {
  const { environment } = getConfigsFromSStorage();
  // DEV NOTE - if login doesn't succeed on dev, switch to production, try logging in, fail to login, switch back to dev, login, this time should be successful
  return axios.post(`${baseUrls[environment]}login`, { username, password });
};

export const netsAuth = async (config: NetsConfig) => {
  const { username, password, terminal } = config;
  if (username && password) {
    await login({ username, password })
      .then(({ data }) => {
        const { token } = data;
        if (token) sessionStorage.setItem(NETS_CONST.TOKEN, token);
        if (terminal) sessionStorage.setItem(NETS_CONST.TERMINAL, terminal);
      })
      .catch(e => console.warn('Failed to authenticate with NETS', e));
  }
};

/**
 * Function that checks if the authorization token has expired (statusCode === 401)
 * and sends request for new token. Once the token has been received the request is resent
 * with the same parameters
 *
 * @param {T} request
 * @returns {T}
 * @template T
 */
export const withAuthCheck = <T extends (...args: any) => Promise<any>>(
  request: T,
) => async ({
  config,
  params,
}: {
  config: NetsConfig;
  params?: Parameters<T>[0];
}): Promise<ReturnType<T>> => {
  try {
    return request(params);
  } catch (err) {
    if (err?.response?.status !== 401) {
      throw err;
    }
  }

  await netsAuth(config);
  return request(params);
};

const getTerminals = (): AxiosPromise<GetTerminalsResponse> => {
  const { environment } = getConfigsFromSStorage();
  return axios.get(`${baseUrls[environment]}terminal`, {
    headers: getHeaders(),
  });
};

const getTerminalInfo = ({
  terminalId,
}: {
  terminalId: string;
}): AxiosPromise<GetTerminalInfoResponse> => {
  const { terminalID, environment } = getConfigsFromSStorage();
  return axios.get(
    `${baseUrls[environment]}terminal/${terminalId || terminalID}`,
    {
      headers: getHeaders(),
    },
  );
};

const createTransaction = ({
  terminalId,
  amount,
  allowPinBypass,
}: {
  terminalId: string;
  amount: number;
  allowPinBypass: boolean;
}): AxiosPromise<TransactionResponse> => {
  const { terminalID, environment } = getConfigsFromSStorage();

  return axios.post(
    `${baseUrls[environment]}terminal/${terminalId || terminalID}/transaction`,
    {
      transactionType: NETS_CONST.TRANSACTION_TYPES.PURCHASE,
      amount: Math.round(amount * 100),
      allowPinBypass,
    },
    {
      headers: getHeaders(),
      timeout: NETS_CONST.REQ_TIMEOUT,
    },
  );
};

const voidTransaction = ({
  terminalId,
  amount,
  transactionId,
}: {
  terminalId: string;
  amount: number;
  transactionId?: string;
}): AxiosPromise<TransactionResponse> => {
  const { terminalID, environment } = getConfigsFromSStorage();
  return axios.delete(
    `${baseUrls[environment]}terminal/${terminalId ||
      terminalID}/transaction/${transactionId ?? ''}`,
    {
      headers: getHeaders(),
      timeout: NETS_CONST.REQ_TIMEOUT,
      data: { amount: Math.round(amount * 100) },
    },
  );
};

const returnTransaction = ({
  terminalId,
  amount,
}): AxiosPromise<TransactionResponse> => {
  const { terminalID, environment } = getConfigsFromSStorage();
  return axios.post(
    `${baseUrls[environment]}terminal/${terminalId || terminalID}/transaction`,
    {
      transactionType: NETS_CONST.TRANSACTION_TYPES.RETURN_OF_GOODS,
      amount: Math.round(amount * 100),
    },
    {
      headers: getHeaders(),
      timeout: NETS_CONST.REQ_TIMEOUT,
    },
  );
};

const adminAction = (action: ValueOf<typeof NETS_CONST.ACTIONS>) => ({
  terminalId,
}: {
  terminalId: string;
}) => {
  const { terminalID, environment } = getConfigsFromSStorage();
  return axios.post(
    `${baseUrls[environment]}terminal/${terminalId ||
      terminalID}/administration`,
    { action },
    {
      headers: getHeaders(),
    },
  );
};

const cancelOngoing = adminAction(NETS_CONST.ACTIONS.CANCEL);

const downloadDataset = adminAction(NETS_CONST.ACTIONS.DOWNLOAD_DATASET);

const downloadSoftware = adminAction(NETS_CONST.ACTIONS.DOWNLOAD_SOFTWARE);

const reconciliation = adminAction(NETS_CONST.ACTIONS.RECONCILIATION);

const printLatestTransactionReceipt = adminAction(
  NETS_CONST.ACTIONS.PRINT_LAST_TRANSACTION_RECEIPT,
);

const getTransaction = ({ terminalId }: { terminalId: string }) => {
  const { terminalID, environment } = getConfigsFromSStorage();
  return axios.get(
    `${baseUrls[environment]}terminal/${terminalId || terminalID}/transaction`,
    {
      headers: getHeaders(),
    },
  );
};

export default {
  login,
  getTerminals: withAuthCheck(getTerminals),
  getTerminalInfo: withAuthCheck(getTerminalInfo),
  createTransaction: withAuthCheck(createTransaction),
  voidTransaction: withAuthCheck(voidTransaction),
  returnTransaction: withAuthCheck(returnTransaction),
  cancelOngoing: withAuthCheck(cancelOngoing),
  downloadDataset: withAuthCheck(downloadDataset),
  downloadSoftware: withAuthCheck(downloadSoftware),
  reconciliation: withAuthCheck(reconciliation),
  printLatestTransactionReceipt: withAuthCheck(printLatestTransactionReceipt),
  getTransaction: withAuthCheck(getTransaction),
};
