import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';

import { getCustomerIssuedCoupons } from 'reducers/customerSearch';
import { getIsAReturn } from 'reducers/sales';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';

export const AddCouponsQuickAction = () => {
  const COUPON_DISPLAY_LIMIT = 6;
  const { t } = useTranslation('customer');
  const { t: tAlerts } = useTranslation('alerts');
  const coupons = useSelector(getCustomerIssuedCoupons);
  const isAReturn = useSelector(getIsAReturn);

  const couponsListText = coupons
    .map(coupon => coupon.uniqueIdentifier)
    .slice(0, COUPON_DISPLAY_LIMIT)
    .join(',\n');

  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      openModalPage({
        component: mp.saleCoupons,
        props: { apply: coupons },
      }),
    );
  };
  if (isAReturn) return null;
  if (coupons.length === 0) return null;
  return (
    <Box margin={2} data-testid="add-customer-coupons">
      <Typography color="primary">
        {tAlerts('customerTooltips.activeCoupons', {
          context: coupons.length < COUPON_DISPLAY_LIMIT ? 'few' : 'many',
          coupons: couponsListText,
          extra: Math.max(0, coupons.length - COUPON_DISPLAY_LIMIT),
        })}
      </Typography>
      <Button color="primary" onClick={onClick} variant="contained">
        {t('customerView.buttons.addCoupons')}
      </Button>
    </Box>
  );
};
