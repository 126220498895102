import { createSelector } from 'reselect';

import { getPluginConfiguration, getPluginSelector } from 'reducers/Plugins';
import { PluginStatus } from 'plugins/plugin';
import { getSelectedWarehouse, getWarehouseById } from 'reducers/warehouses';
import { ErplyAttributes } from 'utils';
import { ShoppingCartItem } from 'types/ShoppingCart';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getProductByID } from 'reducers/cachedItems/products';
import { getReturnedDocWarehouseID } from 'reducers/returnProducts';
import {
  getCustomerGroupByID,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getCurrentSalesDocument, getIsAReturn } from 'reducers/sales';

import { EnvFeeState } from '../types';
import {
  defineEnvFeeRate,
  defineNumberOfWaiverProductsInCart,
  defineRegularProductsInCartLength,
  getPNPEnvFeeRateObjects,
  roundUp,
} from '../utils';

export const getConfiguration = state => ({
  ...{
    admissionFeeName: '',
    admissionTaxName: '',
    CAFeeEnabled: false,
    envFeeEnabled: false,
    envFeeName: '',
    code5names: '',
    productsThatWaiveAdmisisonFee: '',
    envFeeProduct: null,
  },
  ...getPluginConfiguration<{
    admissionFeeName: string;
    admissionTaxName: string;
    CAFeeEnabled: boolean;
    envFeeEnabled: boolean;
    envFeeName: string;
    code5names: string;
    productsThatWaiveAdmisisonFee: string;
    envFeeProduct: null | Record<string, any>;
    admissionFeeProduct: null | Record<string, any>;
  }>('environmental-&-CA-fees')(state),
});

type ExchangeProcessState = {
  exchangeStep: number;
  itemsToExchange: any[];
  originalSalesDoc: {
    [key: string]: any;
  };
};

export const getEnvAndCAFeePluginStatus = (state): PluginStatus => {
  const currentWarehouse = getSelectedWarehouse(state);
  const currentConfig: any = getConfiguration(state);
  const {
    admissionFeeName,
    admissionTaxName,
    CAFeeEnabled,
    envFeeEnabled,
    envFeeName,
    code5names,
  } = currentConfig;

  const warehouseEnvFeeRateObject = new ErplyAttributes(
    currentWarehouse.attributes,
  )?.get('pnp_env_fee_rate_warehouse');

  if (!envFeeEnabled && !CAFeeEnabled) {
    return {
      type: 'error',
      message:
        'Missing configuration for Environmental or CA admission fee plugin.',
    };
  }

  if (envFeeEnabled) {
    if (
      !warehouseEnvFeeRateObject ||
      warehouseEnvFeeRateObject.attributeValue === ''
    ) {
      return {
        type: 'error',
        message: 'Missing environmental fee rate in warehouse level in BO.',
      };
    }

    if (!envFeeName) {
      return {
        type: 'error',
        message:
          'Missing name of environmental fee product in plugin configuration.',
      };
    }

    if (!code5names || code5names === '') {
      return {
        type: 'error',
        message:
          'Missing Code 5 name(s) in environmental fee plugin configuration',
      };
    }
  }

  if (CAFeeEnabled) {
    if (
      !admissionFeeName ||
      (admissionFeeName === '' && !admissionTaxName) ||
      admissionTaxName === ''
    ) {
      return {
        type: 'error',
        message: 'Missing CA admission fee plugin configuration',
      };
    }

    if (!admissionFeeName || admissionFeeName === '') {
      return {
        type: 'error',
        message:
          'Missing CA admission fee name, add it in plugin configuration',
      };
    }

    if (!admissionTaxName || admissionTaxName === '') {
      return {
        type: 'error',
        message:
          'Missing CA admission tax name, add it in plugin configuration',
      };
    }
  }

  return {
    type: 'valid',
    message: 'Ready',
  };
};

export const getEnvState = state =>
  getPluginSelector<EnvFeeState>('environmental-&-CA-fees')(n => n)(state);

export const getExchangeState = state =>
  getPluginSelector<ExchangeProcessState>('pnp-process-exchanges')(n => n)(
    state,
  );

/**
 * Searches and returns admission product from shopping cart, if found.
 */
export const getAdmissionFeeFromCart = (
  state,
): ShoppingCartItem | undefined => {
  const productsInCart: Record<string, any> = getProductsInShoppingCart(state);
  const admissionFeeName = getConfiguration(
    state,
  ).admissionFeeName.toUpperCase();

  return productsInCart.find(product =>
    admissionFeeName.includes(product.name.trim().toUpperCase()),
  );
};

/**
 * Searches and returns admission tax product from given list of orders or shopping cart
 */
export const getAdmissionTaxFromCart = (
  state,
  orders?,
): (ShoppingCartItem & { price?: number | string }) | undefined => {
  const products: ShoppingCartItem[] & { price?: number | string } =
    orders || getProductsInShoppingCart(state);
  const admissionTaxName = getConfiguration(
    state,
  ).admissionTaxName.toUpperCase();
  return products.find(product =>
    admissionTaxName.includes(product.name?.trim().toUpperCase()),
  );
};

/**
 * Calculates the final price of the environmental fee product
 * @param {number} feePercentage Percentage to be applied to the calculation of the product env amount
 */
export const getEnvFeeAmount = (feePercentage: number) => state => {
  const productsInCart: ShoppingCartItem[] = getProductsInShoppingCart(state);
  const config = getConfiguration(state);

  const saleTotal = productsInCart.reduce((accumulator, currentValue) => {
    const currentProduct = getProductByID(currentValue.productID)(state);

    // Should NOT add the product to the total if:
    const shouldNotAddProduct =
      // code5names does NOT include product.code5
      !config.code5names
        ?.toUpperCase()
        ?.includes(currentProduct.code5?.toUpperCase()) ||
      // OR CAFee enabled and and the product is CAFee
      (config.CAFeeEnabled &&
        currentProduct.name.toUpperCase() ===
          config.admissionTaxName.toUpperCase()) ||
      // AND environmentFee enabled and the product is EnvFee
      (config.envFeeEnabled &&
        currentProduct.name.toUpperCase() === config.envFeeName.toUpperCase());

    return shouldNotAddProduct
      ? accumulator
      : accumulator +
          roundUp(
            Number(currentValue.finalPrice) * Number(currentValue.amount),
          );
  }, 0);

  return Number(((saleTotal / 100) * feePercentage).toFixed(2));
};

export const getEnvProductFromShoppingCart = createSelector(
  state => getProductsInShoppingCart(state),
  state => getConfiguration(state),
  (products, { envFeeName }) =>
    products.find(
      product => product?.name?.toUpperCase() === envFeeName?.toUpperCase(),
    ),
);

export const calculateNetTotal = (state): number => {
  const productsInCart = getProductsInShoppingCart(state);
  const config = getConfiguration(state);

  return productsInCart.reduce((accumulator, currentValue) => {
    const currentProduct = getProductByID(currentValue.productID)(state);

    // Do not add env fee product or admission tax product to net total calculation
    const shouldNotAddProduct =
      (config.CAFeeEnabled &&
        currentProduct?.name?.toUpperCase() ===
          config.admissionTaxName?.toUpperCase()) ||
      (config.envFeeEnabled &&
        currentProduct?.name?.toUpperCase() ===
          config.envFeeName?.toUpperCase());

    return shouldNotAddProduct
      ? accumulator
      : accumulator + roundUp(currentValue.finalPrice * currentValue.amount);
  }, 0);
};

export const shouldAddEnvProductToCartBasedOnCode5Names = (
  state: any,
): boolean => {
  const currentConfig = getConfiguration(state);
  const productsInCart = getProductsInShoppingCart(state);

  const { envFeeEnabled, code5names } = currentConfig;

  if (
    productsInCart.length > 0 &&
    envFeeEnabled &&
    code5names &&
    code5names !== ''
  ) {
    const addedProducts: any = [];

    productsInCart.forEach(orderProduct => {
      const fullProduct = getProductByID(orderProduct.productID)(state);
      addedProducts.push(fullProduct);
    });

    const code5Name = currentConfig.code5names.split(',');
    return addedProducts.some(product =>
      code5Name.some(
        code =>
          code?.toUpperCase()?.trim() === product.code5?.toUpperCase()?.trim(),
      ),
    );
  }

  return false;
};

export const getEnvFeeRate = state => {
  const originalWarehouseID = getReturnedDocWarehouseID(state);
  const originalWarehouse = getWarehouseById(originalWarehouseID)(state);
  const currentWarehouse = getSelectedWarehouse(state);
  const currentCustomer = getSelectedCustomer(state);
  const customerGroup = getCustomerGroupByID(currentCustomer.groupID)(state);
  const exchangeState = getExchangeState(state);
  const currentSaleDoc = getCurrentSalesDocument(state);
  const isReturn = getIsAReturn(state);
  const warehouse = isReturn
    ? originalWarehouse ?? currentWarehouse
    : currentWarehouse;
  const { originalSalesDoc, exchangeStep } = exchangeState;

  const {
    customerRateObject,
    customerGroupRateObject,
    warehouseEnvFeeRateObject,
  } = getPNPEnvFeeRateObjects(warehouse, customerGroup, currentCustomer);

  const originalEnvFeeRate =
    exchangeStep === 2
      ? new ErplyAttributes(originalSalesDoc.attributes)?.get(
          'original-env-fee-rate',
        )
      : new ErplyAttributes(currentSaleDoc.attributes)?.get(
          'original-env-fee-rate',
        );

  return originalEnvFeeRate
    ? Number(originalEnvFeeRate)
    : defineEnvFeeRate(
        customerRateObject,
        customerGroupRateObject,
        warehouseEnvFeeRateObject,
      );
};

/** Evaluates if the admission tax should be waived for the curren sale. It will only be waived if all the products in the shopping cart have been configured to waive the admisison tax (productsThatWaiveAdmisisonFee).
 * @returns {boolean} Value determining if the admission tax should be exempted.
 */
export const defineIfAdmissionTaxShouldBeWaived = (state: any): boolean => {
  const currentConfig = getConfiguration(state);
  const productsInCart = getProductsInShoppingCart(state);

  const {
    productsThatWaiveAdmisisonFee,
    admissionTaxName,
    envFeeName,
  } = currentConfig;

  const productsWaivingCAFee: string[] =
    productsThatWaiveAdmisisonFee && productsThatWaiveAdmisisonFee !== ''
      ? productsThatWaiveAdmisisonFee.split(',').map(code => code.trim())
      : [];

  const numberOfWaiverProductsInCart = defineNumberOfWaiverProductsInCart(
    productsInCart,
    productsWaivingCAFee,
  );

  const filteredCartLength = defineRegularProductsInCartLength(
    productsInCart,
    admissionTaxName,
    envFeeName,
  );

  return filteredCartLength === numberOfWaiverProductsInCart;
};
