/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';

import { getServiceEndpoints } from 'services/ErplyAPI/getServiceEndpoints';
import { proxy } from 'services/shared';
import {
  REDUX_CLIENTCODE,
  REDUX_SERVICE_ENDPOINTS,
  REDUX_SESSIONKEY,
} from 'constants/persistence';

import {
  PergamonFiscalJsonApiSuccessResponse,
  PergamonFiscalRecord,
} from '../../types';

const getJsonApiUrl = async () => {
  const { url } = JSON.parse(
    localStorage.getItem(REDUX_SERVICE_ENDPOINTS) || '{}',
  )?.json;
  if (url) return url;
  const [payload] = await getServiceEndpoints();
  return payload?.json?.url;
};

export const saveFiscalDataToInvoiceInJsonApi = async (
  id: number,
  data: PergamonFiscalRecord,
) => {
  const jsonApiUrl = await getJsonApiUrl();
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  return axios.put<PergamonFiscalJsonApiSuccessResponse>(
    `${proxy}${jsonApiUrl}v1/json_object/invoice/${id}`,

    {
      id,
      json_object: {
        'kenya-fiscal-brazil': data,
      },
    },
    {
      headers: { clientCode, sessionKey },
    },
  );
};
