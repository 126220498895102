import axios from 'axios';

import {
  REDUX_CLIENTCODE,
  REDUX_SERVICE_ENDPOINTS,
  REDUX_SESSIONKEY,
} from 'constants/persistence';
import { getServiceEndpoints } from 'services/ErplyAPI/getServiceEndpoints';
import { proxy } from 'services/shared';

import {
  ClockedInEmployeesResponse,
  RequestProps,
  UserRightsResponse,
  WarehouseResponse,
} from './types';

const getUrl = async (): Promise<string> => {
  const lsUrl = JSON.parse(
    localStorage.getItem(REDUX_SERVICE_ENDPOINTS) || '{}',
  )?.['account-admin'];
  if (lsUrl?.url) return lsUrl.url;
  const [payload] = await getServiceEndpoints();
  return payload?.['account-admin']?.url;
};

const doRequest = async <D, R>({
  endpoint,
  method,
  data,
  params,
}: RequestProps<D>): Promise<R> => {
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');

  const url = await getUrl();
  return axios({
    url: `${proxy}${url}${endpoint}`,
    method,
    data,
    params,
    headers: { clientCode, sessionKey },
  }).then(res => res.data);
};

export const getClockedInEmployees = async () => {
  return doRequest<never, ClockedInEmployeesResponse>({
    method: 'GET',
    endpoint: 'v1/employee-clock-in',
  }).then(res => res.data.employees);
};

export const getUserRights = async () => {
  return doRequest<never, UserRightsResponse>({
    method: 'GET',
    endpoint: 'v1/user/rights',
  }).then(res => res.data);
};

export const fetchWarehouses = async () => {
  return doRequest<never, WarehouseResponse>({
    method: 'GET',
    endpoint: 'v1/warehouse',
  }).then(res => res.data);
};
