import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isInt, isFloat, isEmpty } from 'validator';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { round } from 'utils';

import './CashInOut.sass';
import { tryCashInOut } from 'actions/cashInOut';
import { fetchReasonCodes } from 'actions/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';

const CashInOut = () => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');
  // const [operation, setOperation] = useState('out');
  const [note, setNote] = useState('');

  useEffect(() => {
    dispatch([
      fetchReasonCodes({ purpose: REASONS.CASH_IN }),
      fetchReasonCodes({ purpose: REASONS.CASH_OUT }),
    ]);
  }, []);

  const handleChange = e => {
    const { value } = e.target;
    if (isInt(value) || isEmpty(value) || isFloat(value)) setAmount(value);
  };

  const roundOnBlur = () => (amount === '' ? '' : setAmount(round(amount, 2)));

  const handleSubmit = e => {
    e.preventDefault();
    cashTransaction(-Number(amount), note);
  };

  const { t } = useTranslation('cashInOut');

  const cashTransaction = async (sum, comment) => {
    dispatch(tryCashInOut(sum, comment))
      .then(() => dispatch(previousModalPage()))
      .catch(e => {});
  };

  return (
    <div data-testid="cash-in-out" className="cash-in-out">
      <Modal.Header>
        <Modal.Title>{t('title')}</Modal.Title>
        <div className="cash-in-out-action-buttons">
          <SaveButton
            style={{ marginRight: '5px' }}
            title={t('buttons.cashin')}
            action={() => cashTransaction(Number(amount), note)}
            data-testid="cash-in-btn"
          />
          <SaveButton
            title={t('buttons.cashout')}
            action={() => cashTransaction(-Number(amount), note)}
            data-testid="cash-out-btn"
          />
          <CloseButton action={() => dispatch(previousModalPage())} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              size="lg"
              value={amount}
              placeholder={t('inputs.amount')}
              as="input"
              type="text"
              onChange={handleChange}
              onBlur={roundOnBlur}
              data-testid="amount"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows="13"
              value={note}
              onChange={e => setNote(e.target.value)}
              placeholder={t('inputs.notes')}
              data-testid="notes"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </div>
  );
};

export default CashInOut;
