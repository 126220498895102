import { repeat } from 'ramda';

import { nestItems } from 'utils';

const updateNestedName = (el, nestedLevel = 0) => {
  let result: Record<'name' | 'value', string>[] = [];
  if (!el) return result;

  result.push({
    name: nestedLevel
      ? `${repeat('—', nestedLevel).join('')}  ${el.name}`
      : el.name,
    value: el.clientGroupID,
  });

  if (el.children.length) {
    result = result.concat(
      ...el.children.map(ch => updateNestedName(ch, nestedLevel + 1)),
    );
  }
  return result;
};

export const getGroupOptionsWithNameHierarchy = (els = []) => {
  if (!els.length) return [];

  return nestItems(els, {
    idIndicator: 'customerGroupID',
    parentIndicator: 'parentID',
  })
    .map(el => updateNestedName(el))
    .flat();
};

const maxDate = new Date();
const minDate = new Date('1900-01-01');

export function getBirthdayError(currentBday: unknown) {
  if (
    currentBday instanceof Date ||
    typeof currentBday === 'number' ||
    typeof currentBday === 'string'
  ) {
    const currentTime = new Date(currentBday).getTime();

    return (
      Number.isNaN(currentTime) ||
      currentTime > maxDate.getTime() ||
      currentTime < minDate.getTime()
    );
  }
  return false;
}
