import axios from 'axios';

import { PaymentObj } from 'paymentIntegrations/types';

import { IntegrationData, TenderType } from '../types';

const tsysCanadaReturn = async (
  cardPayment: PaymentObj,
  { terminalID, xWebID, authKey }: IntegrationData,
  returnType: TenderType,
): Promise<string> => {
  const payloadString = `<REQUEST><TRANSACTIONTYPE>${
    returnType === 'CREDIT' ? 'CREDITRETURN' : 'DEBITRETURN'
  }</TRANSACTIONTYPE><AMOUNT>${Math.abs(
    Number(cardPayment.amount),
  )}</AMOUNT><XWEBID>${xWebID}</XWEBID><XWEBTERMINALID>${terminalID}</XWEBTERMINALID><XWEBAUTHKEY>${authKey}</XWEBAUTHKEY></REQUEST>`;

  const baseURL =
    'https://localsystem.paygateway.com:21113/RcmService.svc/Initialize?callback=jsonpResponse&xl2Parameters=';
  const payment = await axios.get(`${baseURL}${payloadString}`, {
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  });

  if (payment && payment.data) {
    return payment.data;
  }

  throw new Error('TSYS refund response contains no refund data');
};

export default tsysCanadaReturn;
