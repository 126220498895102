/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Rx from 'rxjs';
import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  concat,
  concatMap,
  delay,
  finalize,
  map,
  mapTo,
  repeat,
  repeatWhen,
  switchMap,
  take,
  takeLast,
  takeWhile,
  tap,
  toArray,
  retryWhen,
  distinctUntilChanged,
} from 'rxjs/operators';
import * as R from 'ramda';

import {
  getAllPayments,
  getCardPaymentsForIntegration,
} from 'reducers/Payments';
import { deletePayment, deletePayments } from 'actions/Payments';
import { addError, addSuccess, addWarning, dismissType } from 'actions/Error';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { getClientCode, getLoggedInEmployeeID } from 'reducers/Login';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { sleep } from 'utils';
import { withWaitingForTerminal } from 'paymentIntegrations';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { CardPaymentHooks } from 'paymentIntegrations/types';

import {
  PaymentFailure,
  PaymentIndeterminate,
  PaymentSuccess,
} from './requests/resultParsers';
import { CAYAN } from './requests/cayanTransactions';

// Configuration options
const RETRY_COUNT = 5;
const RETRY_TIMEOUT = 5000;
const MESSAGE_TIME = 3500;

// region buttons
const $btnCheckStatus = new Rx.Subject();
const $btnCancel = new Rx.Subject();
const $btnForceCancel = new Rx.Subject();
const $btnKeyed = new Rx.Subject();

export const functions = [
  {
    actionOnClick: hooks => () => $btnKeyed.next(),
    name: 'keyed',
    text: 'Keyed entry',
    variant: 'success',
  },
  {
    actionOnClick: hooks => () => $btnCheckStatus.next(),
    name: 'checkStatus',
    text: 'Check status',
    variant: 'warning',
  },
  {
    actionOnClick: hooks => () => $btnCancel.next(),
    name: 'cancel',
    text: 'Cancel',
    variant: 'danger',
  },
  {
    actionOnClick: hooks => () => $btnForceCancel.next(),
    name: 'forceCancel',
    text: 'Force cancel',
    variant: 'danger',
  },
] as const;

const enabledButtons: string[] = [];
const onButton = ($btnObservable, buttonName) => (hooks: CardPaymentHooks) =>
  Rx.defer(() => {
    enabledButtons.push(buttonName);
    hooks.enableButtons(Array.from(new Set(enabledButtons)));

    return $btnObservable.pipe(
      finalize(() => {
        enabledButtons.splice(enabledButtons.indexOf(buttonName), 1);
        hooks.enableButtons(Array.from(new Set(enabledButtons)));
      }),
    );
  });
/** Subscribe to this to enable button and receive click events as emissions */
const $evtCheckStatus = onButton($btnCheckStatus, 'checkStatus');
/** Subscribe to this to enable button and receive click events as emissions */
const $evtCancel = onButton($btnCancel, 'cancel');
/** Subscribe to this to enable button and receive click events as emissions */
const $evtForceCancel = onButton($btnForceCancel, 'forceCancel');
/** Subscribe to this to enable button and receive click events as emissions */
const $evtKeyed = onButton($btnKeyed, 'keyed');

/**
 * Returns an observable.
 * While the observable is subscribed to, clicking on the button will subscribe to actionObs$.
 * While actionObs$ is running, the button is not enabled (no parallel run)
 */
const useButton = <T,>(buttonObs$, actionObs$: Rx.Observable<T>) => (
  hooks: CardPaymentHooks,
) =>
  buttonObs$(hooks).pipe(
    // Unsub from source after first click (disables the button)
    take(1),
    // Perform the action
    switchMap(() => actionObs$),
    // When action done, restart (this also reenables the button)
    retryWhen(delay(1000)), // errors
    repeatWhen(delay(1000)), // successes
  ) as Rx.Observable<T>;
// endregion

// region utils
/**
 * While the observable is subscribed to, enable keyed entry and cancel buttons
 * to make their respective API requests
 */
const enableKeyedEntryAndCancel = (hooks: CardPaymentHooks) => <T,>(
  source$: Rx.Observable<T>,
): Rx.Observable<T> =>
  Rx.using(
    () =>
      Rx.merge(
        useButton($evtCancel, Rx.defer(CAYAN.cancel))(hooks),
        useButton($evtKeyed, Rx.defer(CAYAN.enableKeyedEntry))(hooks),
      ).subscribe(),
    () => source$,
  );

/**
 * Same as {@link concat}, except the next observable will be created with the latest refno captured from the current one
 *
 * If there is no refno by the time the input completes, the output will also complete
 *
 * @see https://rxjs.dev/api/index/function/concat
 */
const concatWithRefno = <T,>(
  nextObsCreator: (refNo: string) => Rx.Observable<T>,
) => (source: Rx.Observable<T>) => {
  let refno: string;
  return source.pipe(
    tap(v => {
      if (v instanceof PaymentIndeterminate) {
        if (v.refno) {
          refno = v.refno;
        }
      }
    }),
    concat(
      Rx.defer(() =>
        refno ? nextObsCreator(refno) : (Rx.empty() as Rx.Observable<T>),
      ),
    ),
  );
};

/**
 * Same as {@link tap}, except also passes the index of the item
 *
 * @see https://rxjs.dev/api/operators/tap
 */
const tapIndex = <T,>(consumer: (v: T, i: number) => void) =>
  map<T, T>((v, i) => {
    consumer(v, i);
    return v;
  });
// endregion

/**
 * Initial function called when CardPaymentUI is rendered
 * @param hooks
 */
export const initPayments = (hooks: CardPaymentHooks) => async (dispatch, getState) => {
  const {
    isCurrentSaleAReturn,
    cardPayments,
    updateMessage,
    setTitle,
    resolvePayments,
    rejectPayments,
  } = hooks;
  const state = getState();
  const { value: cayanConfig } =
    getCafaEntry('cayan', INTEGRATION_TYPES.payment)(state) ?? {};
  const loggedInEmployeeID = getLoggedInEmployeeID(state);
  const currentCustomer = getSelectedCustomerID(state);
  const clientCode = getClientCode(state);

  const cayanData = {
    ...cayanConfig,
    loggedInEmployeeID,
    currentCustomer,
    clientCode,
  };

  const paymentsToProcess = cardPayments
    .filter(p => !p.paid)
    .map(pmt => R.assoc('uuid', uuidv4())(pmt));

  /**
   * Keep title in sync with terminal status
   */
  const statusToTitle$ = Rx.defer(() =>
    CAYAN.status().then(({ records: [{ deviceState }] }) => deviceState),
  ).pipe(
    repeatWhen(delay(2000)),
    retryWhen(delay(2000)),
    distinctUntilChanged(),
    tap(state => {
      updateMessage(state);
    }),
  );

  /**
   * When subscribed, keep automatically running checkStatus
   * Emits results
   */
  const automaticRetries$ = (payment, refno: string) => {
    return Rx.defer(() =>
      sleep(RETRY_TIMEOUT / 1000).then(() =>
        CAYAN.checkStatus(payment, cayanData, refno),
      ),
    ).pipe(repeat());
  };

  /**
   * When subscribed, enable checkStatus, cancel, force cancel buttons
   *
   * ## checkStatus:
   *     - Checks status
   *     - Emits checkstatus result
   *
   * ## cancel:
   *     - Checks status
   *     - if indeterminate then tries to cancel
   *     - Emits last result
   *
   * ## force cancel:
   *     - Checks status
   *     - if indeterminate then tries to cancel
   *     - if indeterminate then will emit successful cancel anyway
   *     - otherwise emits last result
   */
  const manualRetries$ = (payment, refno: string) => {
    return Rx.merge(
      $evtCheckStatus(hooks).pipe(mapTo('checkStatus' as const)),
      $evtCancel(hooks).pipe(mapTo('cancel' as const)),
      $evtForceCancel(hooks).pipe(mapTo('forceCancel' as const)),
    ).pipe(
      take(1),
      concatMap(btn => {
        return Rx.defer(async () => {
          const steps = {
            checkStatus: 1,
            cancel: 2,
            forceCancel: 3,
          }[btn];
          hooks.setTitle(`Checking status (step 1/${steps})`);
          await sleep(MESSAGE_TIME / 1000);
          const res = await CAYAN.checkStatus(payment, cayanData, refno);
          if (!(res instanceof PaymentIndeterminate)) return res;
          if (btn === 'checkStatus') {
            hooks.setTitle('Failed to check status');
            return res;
          }
          hooks.setTitle(`Attempting to cancel (step 2/${steps})`);
          await sleep(MESSAGE_TIME / 1000);
          const res2 = await CAYAN.cancel();
          if (!(res2 instanceof PaymentIndeterminate)) return res2;
          if (btn === 'cancel') {
            hooks.setTitle('Failed to cancel');
            return res2;
          }
          hooks.setTitle(`No connection - forcing cancel (step 3/3)`);
          await sleep(MESSAGE_TIME / 1000);
          console.error(
            'Force cancel performed, can not confirm whether payment actually cancelled/failed',
          );
          return new PaymentFailure('Force cancelled');
        });
      }),
      repeat(),
    );
  };

  /**
   * Process a single payment
   * Emits only the final status of the payment, either success or failure, then completes
   *
   * First will make the payment request
   * Then makes {@link RETRY_COUNT} automatic retries
   * Then enables manual retry buttons while still secretly running automatic retries in the background
   *
   * At any stage, if a definite result (success or failure) is reached then
   * the observable will emit the result and complete.
   */
  const processPayment$ = (payment, i) => {
    const [t, amt] = [paymentsToProcess.length, payment.amount];
    hooks.setTitle(`Performing payment (${i + 1}/${t}) for ${amt}`);

    // Make initial payment request
    return Rx.from(
      dispatch(
        withWaitingForTerminal(() => {
          return payment.amount > 0
            ? CAYAN.startPayment(payment, cayanData)
            : CAYAN.startReturn(payment, cayanData);
        }),
      ),
    ).pipe(
      // @ts-ignore
      enableKeyedEntryAndCancel(hooks),

      // Then do 5 automatic retries with messages
      concatWithRefno(refno => {
        hooks.setTitle('Making 5 automatic retries (1/5)');
        return automaticRetries$(payment, refno).pipe(
          take(RETRY_COUNT),
          tapIndex((res, i) =>
            hooks.setTitle(`Making 5 automatic retries (${i + 2}/5)`),
          ),
        );
      }),

      // Then keep doing retries secretly forever, and unlock manual retries
      concatWithRefno(refno => {
        hooks.setTitle(
          'Automatic retries inconclusive, please check the terminal',
        );
        return Rx.merge(
          manualRetries$(payment, refno),
          automaticRetries$(payment, refno),
        );
      }),

      // Only run this pipeline until we have a definite result
      takeWhile(v => v instanceof PaymentIndeterminate, true),
      // Only emit the definite result
      takeLast(1),
    );
  };

  /**
   * Attempt to process all the payments (or until the first cancel/fail),
   * then emit an array of all payments that were successful
   */
  const getPayments$ = Rx.from<any>(paymentsToProcess).pipe(
    concatMap((payment: any, paymentIndex) => {
      return processPayment$(payment, paymentIndex).pipe(
        tap(async res => {
          if (res instanceof PaymentSuccess) {
            const tipAmount = Number(res.data.tipAmount);
            const convertedResData = tipAmount
              ? {
                  ...res.data,
                  amount: (Number(res.data.amount) - tipAmount).toFixed(2),
                }
              : res.data;

            await hooks.beforeDocSave(
              R.assoc('key', payment.key, convertedResData),
            );
            if (tipAmount) {
              await hooks.beforeDocSave({
                key: uuidv4(),
                paid: true,
                type: 'TIP',
                caption: 'TIP (adjustment)',
                // Stringify on purpose since -String = -Number
                amount: `-${res.data.tipAmount}`,
                cardType: res.data.cardType,
                attributes: {
                  refNo: res.data.attributes.refNo,
                },
              });
              await hooks.beforeDocSave({
                key: uuidv4(),
                paid: true,
                type: 'CARD',
                caption: 'TIP (payment)',
                amount: res.data.tipAmount,
                cardType: res.data.cardType,
                cardHolder: res.data.cardHolder,
                cardNumber: res.data.cardNumber,
                attributes: {
                  cardIsTip: true,
                  authCode: res.data.attributes.authCode,
                  paymentType: res.data.cardType,
                  refNo: res.data.attributes.refNo,
                  cardNumber: res.data.cardNumber,
                },
              });
            }
          }
        }),
      );
    }),
    // (Only results and failures here) If a payment is failed/cancelled, unsubscribe (do not process the remaining ones)
    takeWhile(v => v instanceof PaymentSuccess, true),
    // Run status calls in parallel and update title
    source$ =>
      Rx.using(
        () => statusToTitle$.subscribe(),
        () => source$,
      ),
    toArray(),
  );

  // Ensure MS/terminal accessible
  if (!(await CAYAN.ping())) {
    hooks.setTitle('Could not connect to the terminal!');
    await sleep(MESSAGE_TIME / 1000);
    rejectPayments();
    return;
  }

  // Perform payments until done or until one fails
  const paymentResults = await getPayments$.toPromise();

  // Notify the user of the result
  const error = paymentResults.find(
    (result): result is PaymentFailure => result instanceof PaymentFailure,
  );
  const isIncomplete = paymentResults.length !== paymentsToProcess.length;

  if (error) {
    hooks.setTitle('Payment cancelled / failed');
    hooks.updateMessage(error.message);
    await sleep(MESSAGE_TIME / 1000);
    rejectPayments();
  } else if (isIncomplete) {
    hooks.setTitle('Payment cancelled / failed');
    hooks.updateMessage('Unknown error');
    await sleep(MESSAGE_TIME / 1000);
    rejectPayments();
  } else {
    hooks.setTitle('Payment success');
    await sleep(MESSAGE_TIME / 1000);
    resolvePayments();
  }
};

export const closeBatch = () => async () => CAYAN.closeBatch();

/**
 * Void current successful payments in case the user wants to abort the current purchase
 */
export const voidPayments = () => async (dispatch, getState) => {
  const cardPaymentsForIntegration = getCardPaymentsForIntegration('cayan')(
    getState(),
  );
  const payments = getAllPayments(getState());
  const cardPayments = cardPaymentsForIntegration
    .filter(p => p.paid)
    .filter(p => !p.attributes?.cardIsTip)
    .map(p => R.assoc('uuid', uuidv4(), p));
  const clientCode = getClientCode(getState());
  const state = getState();
  const { value: cayanConfig } =
    getCafaEntry('cayan', INTEGRATION_TYPES.payment)(state) ?? {};
  const loggedInEmployeeID = getLoggedInEmployeeID(state);
  const currentCustomer = getSelectedCustomerID(state);

  const cayanData = {
    ...cayanConfig,
    loggedInEmployeeID,
    currentCustomer,
    clientCode,
  };

  const setProgress = text => {
    dispatch([
      dismissType('cayan'),
      addWarning(text, {
        dismissible: false,
        selfDismiss: false,
        errorType: 'cayan',
      }),
    ]);
  };

  const getTipPaymentKeysForGivenRefNo = (refNo?: string): string[] => {
    if (!refNo) return [];
    const isTipPayment = card =>
      card.attributes?.cardIsTip || card.type === 'TIP';
    return payments
      .filter(p => isTipPayment(p) && p.attributes?.refNo === refNo)
      .map(p => p.key);
  };

  return Rx.from<any>(cardPayments)
    .pipe(
      concatMap((payment: any, paymentIndex) => {
        const hasTip = payment.tipAmount && Number(payment.tipAmount);
        // Check if the payment has had a tip and if so, add it's amount to the payment's amount
        if (hasTip) {
          const newAmount = (
            Number(payment.amount) + Number(payment.tipAmount)
          ).toFixed(2);
          Object.assign(payment, { amount: newAmount });
        }
        return Rx.defer(() => {
          setProgress(
            `Voiding ${payment.amount} (${paymentIndex + 1}/${
              cardPayments.length
            })`,
          );
          return dispatch(
            withWaitingForTerminal(() => CAYAN.startVoid(payment, cayanData)),
          );
        }).pipe(
          tap((data: any) => {
            if (data instanceof PaymentSuccess) {
              dispatch(deletePayment({ key: payment.key }));
              if (hasTip) {
                const tipPaymentKeys = getTipPaymentKeysForGivenRefNo(
                  payment.attributes?.refNo,
                );

                if (tipPaymentKeys.length) {
                  dispatch(deletePayments({ keys: tipPaymentKeys }));
                }
              }
            }
          }),
        );
      }),
      toArray(),
    )
    .toPromise()
    .then(() => {
      dispatch(
        addSuccess(
          i18next.t('paymentIntegrations:transactionMessages.voidSuccessful'),
          {
            selfDismiss: true,
          },
        ),
      );
      dispatch(dismissType('cayan'));
    });
};

export const integrationSetup = newValues => async dispatch =>
  CAYAN.ping().then(success =>
    dispatch(
      success
        ? addWarning('Cayan integration basic setup is ready.', {
            dismissible: false,
            selfDismiss: MESSAGE_TIME,
          })
        : addError('Cayan integration needs to be configured.', {
            dismissible: false,
            selfDismiss: MESSAGE_TIME,
          }),
    ),
  );
