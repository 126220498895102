export enum ProviderTypeCode {
  /** Default value for non-telecom services or if Provider Type is unknown */
  Default = '99',

  /* Communications */
  COMM_ILEC = '00',
  COMM_IXC = '01',
  COMM_CLEC = '02',
  COMM_VOIP = '03',
  COMM_ISP = '04',
  COMM_WIRELESS = '05',

  /* Utilities */
  /** Utilities - Regulated distributor */
  UTIL_REG_DIST = '50',
  /** Utilities - Regulated distributor / supplier */
  UTIL_REG_DIST_SUP = '52',
  /** Utilities - Non-regulated reseller */
  UTIL_RESELL = '53',
  /** Utilities - Non-regulated supplier with a regulated distributor */
  UTIL_REG_DIST_UNREG_SUP = '54',
  /** Utilities - Custom */
  UTIL_CUSTOM = '55',
  /** Utilities - Regulated pipeline company */
  UTIL_REG_PIPE = '56',
  /** Utilities - Renewable energy */
  UTIL_RENEW = '57',
  /** Retail */
  RETAIL = '70',
}
