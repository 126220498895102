import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { getSelectedPos } from 'reducers/PointsOfSale';
import { getWarehouseRights } from 'reducers/Login';
import { REDUX_WAREHOUSE, REDUX_WAREHOUSEID } from 'constants/persistence';
import * as warehousesActionTypes from 'constants/warehouses';
import { TYPE_LOGOUT, TYPE_LOGOUT_SOFT } from 'constants/Login';

function warehouses(state = {}, action) {
  switch (action.type) {
    case warehousesActionTypes.FETCH_SUCCESS:
      return Object.fromEntries(
        action.warehouses.map(wh => [wh.warehouseID, wh]),
      );
    case TYPE_LOGOUT:
      return {};
    default:
      return state;
  }
}

function homeStores(state = [], action) {
  switch (action.type) {
    case warehousesActionTypes.FETCH_HOMESTORES_SUCCESS:
      return action.payload;
    case TYPE_LOGOUT:
    case TYPE_LOGOUT_SOFT:
      return [];
    default:
      return state;
  }
}

function fetching(state = false, { type }) {
  switch (type) {
    case warehousesActionTypes.FETCH_STARTED:
      return true;
    case warehousesActionTypes.FETCH_SUCCESS:
    case warehousesActionTypes.FETCH_FAILURE:
      return false;
    default:
      return state;
  }
}

function error(state = null, { type, error }) {
  switch (type) {
    case warehousesActionTypes.FETCH_FAILURE:
      return error;
    case warehousesActionTypes.FETCH_SUCCESS:
      return null;
    default:
      return state;
  }
}

export default combineReducers({ warehouses, homeStores, fetching, error });

export const getAllWarehouses = createSelector(
  state => state.warehouses.warehouses,
  warehouses => Object.values(warehouses),
);

export function findWarehouseById(state = [], id) {
  return state[id] || null;
}

export function getWarehouseById(id) {
  return state => findWarehouseById(state.warehouses.warehouses, id);
}

export const getAllowedWarehouses = createSelector(
  state => getAllWarehouses(state),
  state => getWarehouseRights(state),
  (allWarehouses, warehouseRights) => {
    const locations = [];
    warehouseRights.forEach(warehouse => {
      if (warehouse.right === '1') {
        const warehouseObj = allWarehouses.find(
          wh => Number(wh.warehouseID) === Number(warehouse.warehouseID),
        );
        warehouseObj &&
          warehouseObj?.isOfflineInventory === 0 &&
          locations.push(warehouseObj);
      }
    });
    return locations;
  },
);

const getStoredWarehouse = createSelector(
  () => localStorage.getItem(REDUX_WAREHOUSE),
  json => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  },
);

function getSlice(state) {
  return state.warehouses;
}

export function getSelectedWarehouseID(state) {
  return JSON.parse(localStorage.getItem(REDUX_WAREHOUSEID));
}

export const getSelectedWarehouse = createSelector(
  state => getStoredWarehouse(state),
  state => getSelectedWarehouseID(state),
  state => getAllWarehouses(state),
  (stored, id, allWarehouses) => {
    const found =
      allWarehouses.find(
        warehouse => String(warehouse.warehouseID) === String(id),
      ) || null;
    if (found) {
      localStorage.setItem(REDUX_WAREHOUSE, JSON.stringify(found));
      return found;
    }
    if (String(stored?.warehouseID) === String(id)) return stored;
    return null;
  },
);

export function getLoggedInWarehouse(state) {
  return getWarehouseById(getSelectedPos(state)?.warehouseID)(state);
}

export function getAllHomeStores(state) {
  return getSlice(state).homeStores;
}

/**
 * @param warehouseID {number}
 * @return {(state: any) => import('types/Warehouse.ts').Warehouse}
 */
export function getHomeStoreById(id) {
  return state =>
    getSlice(state).homeStores.find(store => store.warehouseID === Number(id));
}
