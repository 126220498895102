import React from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  getUISetting,
  getSetting,
  getUseCreateCustomerBeta,
} from 'reducers/configs/settings';
import './CustomerTable.css';
import { Customer } from 'types/Customer';
import { REDUX_CUSTOMER_REGISTRY_URL } from 'constants/persistence';

import CustomerTableBeta from './CustomerTableBeta';
import { useCustomerFieldMapping } from './useCustomerFieldMapping';

const personFieldsOnly = ['birthday'];

const getCustomerCardFields = ({
  customerType,
  shouldDisplayID = false,
}: {
  customerType: 'PERSON' | 'COMPANY';
  shouldDisplayID: boolean;
}) => {
  const fields = localStorage.getItem(REDUX_CUSTOMER_REGISTRY_URL)
    ? [
        { key: 'customerCardNumber', name: 'Card number' },
        { key: 'groupName', name: 'Group' },
        { key: 'birthday', name: 'Birthday' },
        { key: 'email', name: 'E-mail' },
        { key: 'phone', name: 'Phone' },
        { key: 'mobile', name: 'Mobile' },
        { key: 'addresses', name: 'Address' },
        { key: 'rewardPoints', name: 'Reward points' },
        { key: 'code', name: 'Code' },
        { key: 'vatNumber', name: 'VAT number' },
        { key: 'homeStoreID', name: 'Home store' },
      ]
    : [
        { key: 'customerCardNumber', name: 'Card number' },
        { key: 'groupName', name: 'Group' },
        { key: 'birthday', name: 'Birthday' },
        { key: 'email', name: 'E-mail' },
        { key: 'phone', name: 'Phone' },
        { key: 'mobile', name: 'Mobile' },
        { key: 'addresses', name: 'Address' },
        { key: 'rewardPoints', name: 'Reward points' },
        { key: 'availableCredit', name: 'Store credit' },
        { key: 'code', name: 'Code' },
        { key: 'vatNumber', name: 'VAT number' },
        { key: 'homeStoreID', name: 'Home store' },
        { key: 'creditLimit', name: 'Credit limit' },
      ];
  const filteredFields = fields.filter(({ key }) =>
    customerType === 'COMPANY' ? !personFieldsOnly.includes(key) : key,
  );

  if (shouldDisplayID) {
    filteredFields.unshift({ key: 'id', name: 'ID' });
  }

  const leftRangeToSlice = shouldDisplayID ? [0, 7] : [0, 6];
  const rightRangeToSlice = shouldDisplayID ? [7, 14] : [6, 13];

  return {
    leftFields: filteredFields.slice(...leftRangeToSlice),
    rightFields: filteredFields.slice(...rightRangeToSlice),
  };
};

const CustomerTable: React.FC<{
  customer: Customer;
}> = ({ customer }) => {
  const { customerType } = customer;
  const shouldDisplayID = useSelector(
    getSetting('touchpos_display_id_in_customer_details'),
  );

  const { leftFields, rightFields } = getCustomerCardFields({
    customerType,
    shouldDisplayID,
  });

  const hideHomeStore = useSelector(
    getUISetting('isCustomerHomeStoreDisabled'),
  );

  const { t } = useTranslation('customer');

  const getData = useCustomerFieldMapping(customer);

  return (
    <Row
      className="customer-tables-container"
      data-testid="customer-table-container"
    >
      <Col sm={12} md={12} lg={6}>
        <Table borderless size="sm">
          <tbody>
            {leftFields.map(({ key, name }) => (
              <tr data-testid={name} key={name}>
                <td>
                  <strong data-testid={key}>
                    {t(`customerView.fields.${key}`)}
                  </strong>
                </td>
                <td
                  data-testid={customer[key]}
                  style={{ textAlign: 'right', wordBreak: 'break-all' }}
                >
                  {getData(key)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <Col sm={12} md={12} lg={6}>
        <Table borderless size="sm">
          <tbody>
            {rightFields.map(({ key, name }) => {
              if (key === 'vatNumber' && customer.customerType !== 'COMPANY')
                return null;
              if (key === 'homeStoreID' && hideHomeStore) return null;
              return (
                <tr data-testid={name} key={name}>
                  <td>
                    <strong data-testid={key}>
                      {t(`customerView.fields.${key}`)}
                    </strong>
                  </td>
                  <td
                    data-testid={customer[key]}
                    style={{ textAlign: 'right', wordBreak: 'break-all' }}
                  >
                    {getData(key)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default props => {
  const shouldUseCreateCustomerBeta = useSelector(getUseCreateCustomerBeta);
  if (shouldUseCreateCustomerBeta) return <CustomerTableBeta {...props} />;
  return <CustomerTable {...props} />;
};
