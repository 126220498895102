import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { displayView } from 'constants/UI';
import {
  openNextPage,
  openPreviousPage,
  setGridView,
  setProductGroup,
} from 'actions/UI';
import { useMeasureListHeight } from 'containers/Col2/GridViews/util';
import { getQuickSelectProductIDs } from 'reducers/PointsOfSale';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import useProducts from 'utils/hooks/useProducts';
import {
  getFetchOnlyProductsThatAreInStock,
  getSetting,
  getUseLocalProductDB,
} from 'reducers/configs/settings';
import { getSelectedProductPage } from 'reducers/UI/gridDisplay';
import { getSelectedCustomerID } from 'reducers/customerSearch';

import Products from '../Products';

import { homeBttn, leftArrowBttn, rightArrowBttn } from './SpecialBttns';

const QuickSelectView = () => {
  const dispatch = useDispatch();
  const page = useSelector(getSelectedProductPage);
  const [nrItems, setNrItems] = useState(null);

  const quickSelectProductIDs = useSelector(getQuickSelectProductIDs);
  const getItemsFromFirstPriceListOnly = useSelector(
    getSetting('pos_allow_selling_only_from_pricelist1'),
  );
  const isUsingLocalProductsDb = useSelector(getUseLocalProductDB);
  const warehouseID = useSelector(getSelectedWarehouseID);
  const fetchOnlyProductsInStock = useSelector(
    getFetchOnlyProductsThatAreInStock,
  );
  const clientID = useSelector(getSelectedCustomerID);

  const { products } = useProducts(
    {
      pageNo: page,
      recordsOnPage: nrItems,
      getProductsFor: 'SALES',
      warehouseID,
      getWarehouseSpecificVAT: 1,
      quickPosProducts: 1,
      getItemsFromFirstPriceListOnly,
      getOnlyItemsInStock:
        isUsingLocalProductsDb && fetchOnlyProductsInStock ? 1 : 0,
      getPriceListPrices: 1,
      clientID,
    },
    isUsingLocalProductsDb ? { addToCachedItems: true, localFirst: true } : {},
  );

  const goToHomePage = useCallback(() => {
    dispatch(setGridView(displayView.main));
    dispatch(setProductGroup('0'));
  }, [dispatch]);

  const startBttns = useMemo(() => [homeBttn(goToHomePage)], [goToHomePage]);
  const endBttns = useMemo(
    () =>
      nrItems + 2 < quickSelectProductIDs.length
        ? [
            leftArrowBttn(() => dispatch(openPreviousPage()), page === 0),
            rightArrowBttn(
              () => dispatch(openNextPage()),
              (page + 1) * nrItems >= quickSelectProductIDs.length,
            ),
          ]
        : [],
    [nrItems, quickSelectProductIDs.length, page, dispatch],
  );

  const onSetNrLines = useCallback((lines, items) => setNrItems(items), []);

  const { needsToMeasure, Measure, totalLines } = useMeasureListHeight();
  const lines = Math.max(7, totalLines);
  if (needsToMeasure) return Measure;

  return (
    <Products
      products={products}
      specialBttnsStart={startBttns}
      specialBttnsEnd={endBttns}
      onSetNrLines={onSetNrLines}
      minLines={lines}
      maxLines={lines}
    />
  );
};

export default QuickSelectView;
