import React from 'react';

import { PosPlugin } from 'plugins/plugin';
import { ErplyAttributes } from 'utils';
import { getSelectedWarehouse } from 'reducers/warehouses';
import { getProductGroups } from 'reducers/productGroupsDB';
import {
  setCaptureZIPProductGroups,
  addZip,
  removeZipCode,
} from './redux/actions';
import { ProductGroup } from './redux/types';
import reduxReducer from './redux/reducers';
import EnterZipModal from './components/EnterZipModal';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import {
  getAllEnabledProductGroups,
  getZipCode,
  getIsZipAlreadyEntered,
} from './redux/selectors';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getProductByID } from 'reducers/cachedItems/products';

const captureCustomerZIP: PosPlugin = {
  id: 'capture-zip',
  name: 'PNP - Capture customer ZIP',
  getStatus: state => {
    const warehouse = getSelectedWarehouse(state);
    const warehouseAttrs = new ErplyAttributes(warehouse.attributes);
    const zipCodeLocationCollectEnabled =
      warehouseAttrs.get('zip-code-location-collect') === '1';

    const productGroups: ProductGroup[] = getProductGroups(state).reduce(
      (acc, cur) => {
        const attr =
          new ErplyAttributes(cur.attributes).get(
            'zip-product-group-capture',
          ) === '1';
        if (attr) {
          acc.push(cur);
        }
        return acc;
      },
      [],
    );

    const zipProductGroupCaptureEnabled = productGroups.length >= 1;

    if (!zipCodeLocationCollectEnabled) {
      return {
        type: 'warning',
        message:
          '"Does this location collect ZIP codes?" checkbox must be checked on location card in BO.',
      };
    }
    if (!zipProductGroupCaptureEnabled) {
      return {
        type: 'warning',
        message:
          '"Is ZIP capture required for this group?" must be checked in product group in BO',
      };
    }

    return {
      type: 'valid',
      message: 'Ready to use',
    };
  },
  onMount: () => async (dispatch, getState) => {
    const state = getState();
    const warehouse = getSelectedWarehouse(state);
    const warehouseAttrs = new ErplyAttributes(warehouse.attributes);
    const zipCodeLocationCollectEnabled =
      warehouseAttrs.get('zip-code-location-collect') === '1';
    if (zipCodeLocationCollectEnabled) {
      const prodGroups = getProductGroups(state);

      const productGroups: ProductGroup[] = prodGroups.reduce((acc, cur) => {
        const attr =
          new ErplyAttributes(cur.attributes).get(
            'zip-product-group-capture',
          ) === '1';
        if (attr) {
          acc.push(cur);
        }
        return acc;
      }, []);
      if (productGroups) {
        dispatch(setCaptureZIPProductGroups(productGroups));
      }
    }
  },
  reduxReducer,
  info:
    'When enabled, will ask customer for ZIP code and add that data to the receipt as an attribute field. In order to this take affect, location must have "Does this location collect ZIP codes?" checkbox checked in BO and product in shopping cart must be inside product group which has "Is ZIP capture required for this group?" checkbox checked.',
  keywords: ['pnp', 'zip', 'shoppingcart', 'customer'],
  combineConfiguration: (...args) => {
    return args[0];
  },
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      const state = getState();
      const warehouse = getSelectedWarehouse(state);
      const warehouseAttrs = new ErplyAttributes(warehouse.attributes);
      const zipCodeLocationCollectEnabled =
        warehouseAttrs.get('zip-code-location-collect') === '1';

      if (zipCodeLocationCollectEnabled) {
        const isZipAlreadyEntered = getIsZipAlreadyEntered(state);
        const cart = getProductsInShoppingCart(state);

        const { admissionTaxName, envFeeName }: any =
          getPluginConfiguration('environmental-&-CA-fees')(state) ?? {};

        const enabledProductGroupIDs = getAllEnabledProductGroups(state).map(
          group => group.productGroupID,
        );

        const cartHasProductFromEnabledGroup = cart
          .map(({ productID }) => {
            const { groupID, name } = getProductByID(productID)(state);
            if (
              enabledProductGroupIDs.includes(groupID) &&
              name !== admissionTaxName &&
              name !== envFeeName
            ) {
              return true;
            }
            return false;
          })
          .some(val => val === true);

        if (cartHasProductFromEnabledGroup && !isZipAlreadyEntered) {
          await addZip({ dispatch });
        }
      }

      return {
        ...ap,
      };
    },
  },
  onSaveSalesDocument: {
    on: (p, requests) => async (dispatch, getState) => {
      const state = getState();
      const zip = getZipCode(state);

      const mapped = requests.map(r => {
        if (r.requestName === 'saveSalesDocument' && !!zip) {
          const attr = {
            attributeName595: 'zip-code',
            attributeType595: 'text',
            attributeValue595: zip,
          };
          return {
            ...r,
            ...attr,
          };
        }
        return r;
      });
      return mapped;
    },
    after: (p, ep) => async (dispatch, getState) => {
      dispatch(removeZipCode());
    },
  },
  components: {
    EnterZipModal,
  },
};

export default captureCustomerZIP;
