export const languageCodeToLegacyIdMapping = {
  en: 'eng',
  'en-ZA': 'eng',
  'en-AU': 'eng',
  'en-GB': 'eng',
  et: 'est',
  lv: 'lat',
  ru: 'rus',
  fi: 'fin',
  el: 'gre',
  es: 'spa',
  de: 'ger',
  sv: 'swe',
  lt: 'lit',
  da: 'spa',
  nl: 'gre',
  pl: 'gre',
  fr: 'lat',
  zh_Hans: 'lit',
  zh_Hant: 'lat',
  it: 'lat',
  tr: 'lit',
  th: 'fin',
  fo: 'lit',
  vi: 'lat',
  ar: 'lit',
  cs: 'est',
  no: 'gre',
  he: 'est',
  bg: 'fin',
} as const;

export const languageLegacyIdToCodeMapping = {
  eng: 'en',
  est: 'et',
  lat: 'lv',
  rus: 'ru',
  fin: 'fi',
  gre: 'el',
  spa: 'es',
  ger: 'de',
  swe: 'sv',
  lit: 'lt',
} as const;

/**
 * Array of languages that POS uses, but which are not returned by the getActiveLanguages request.
 * Update this array in case any other language is missing
 */
export const missingLanguages = [
  {
    code: 'en-ZA',
    countries: ['ZA'],
    isOverride: false,
    isoCode: 'en',
    legacyIdentifier: 'eng',
    name: 'English (South Africa)',
    nativeName: 'English',
    shortDisplayName: 'ENG',
  },
  {
    code: 'en-GB',
    countries: [],
    isOverride: false,
    isoCode: 'en-gb',
    legacyIdentifier: 'eng',
    name: 'English (GB)',
    nativeName: 'English',
    shortDisplayName: 'ENG',
  },
];

/** Get supported language from dict by passing a key */
export function getLegacyLanguageId(languageCode: string) {
  return languageCodeToLegacyIdMapping[languageCode];
}

function getCorrespondingLegacyLanguageCode(languageCode: string) {
  const legacyId = languageCodeToLegacyIdMapping[languageCode];
  return languageLegacyIdToCodeMapping[legacyId];
}

/**
 * Given an object with name fields in different languages (e.g. key is "nameEN")
 * returns an object with language codes as keys and names as values
 * @example
 * const names = getNamesByLanguage({ id: 1,  nameEN: 'engValue', nameET: 'estValue' })
 * // names is { EN: 'engValue', ET: 'estValue' }
 */
export function getNamesByLanguage(
  entity: Record<string, any>,
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(entity)
      .map(([k, v]) => [k.match(/name([A-Z]+)/)?.[1], v])
      .filter(
        (entry): entry is [string, string] =>
          entry[0] !== undefined && typeof entry[1] === 'string',
      ),
  );
}

/**
 * Given an object with language codes as keys and names as values, desired language and default language
 * return a name that matches the provided language.
 *
 * Matching priority:
 * 1. language param
 * 2. legacy language that corresponds to language param
 * 3. default language param
 * 4. English
 */
export function getNameInLanguage(
  names: Record<string, string>,
  language: string,
  defaultLanguage?: string,
) {
  return (
    names[language.toUpperCase()] ||
    names[getCorrespondingLegacyLanguageCode(language).toUpperCase()] ||
    (defaultLanguage ? getNameInLanguage(names, defaultLanguage) : names.EN)
  );
}
