import React, { useCallback, useEffect, useMemo } from 'react';
import debug from 'debug';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getEmployeePOSByWarehouseIDS } from 'reducers/PointsOfSale';
import { getSetting } from 'reducers/configs/settings';
import { selectWarehouse } from 'actions/warehouses';
import {
  getAllowedWarehouses,
  getSelectedWarehouse,
} from 'reducers/warehouses';
import Loader from 'components/Loader';
import { getIsLoading } from 'reducers/OpenCloseDay';
import { UrlControl } from 'services/UrlControl/UrlControl';
import useAssociatedPos from 'utils/hooks/useAssociatedPos';

const baseLog = debug('WarehouseSelect');

const useAutoSelectWarehouse = (onSelect, allPoints, allowedWarehouses) => {
  const associatedPosId = useSelector(
    getSetting('CONFIGURATION: associated-pos'),
  );
  const associatedPos = allPoints.find(
    pos => String(pos.pointOfSaleID) === String(associatedPosId),
  );
  const selectedWarehouse = useSelector(getSelectedWarehouse);

  const associatedPosNew = useAssociatedPos();

  const ucas = UrlControl.autoSelectPos;

  const whIdFrom = {
    urlControl: {
      pos: allPoints.find(
        pos => Number(pos.pointOfSaleID) === ucas?.pointOfSaleID,
      )?.warehouseID,
      wh: ucas?.warehouseID,
    },
    associatedPosNew: allPoints.find(
      pos => Number(pos.pointOfSaleID) === associatedPosNew.posID,
    )?.warehouseID,
    associatedPos: allowedWarehouses.find(
      wh => String(wh.warehouseID) === String(associatedPos?.warehouseID),
    )?.warehouseID,
    alreadySelected: allowedWarehouses.find(wh => wh === selectedWarehouse)
      ?.warehouseID,
  };

  const autoSelectWarehouseId = useMemo(() => {
    const log = baseLog.extend('autoSelectWarehouseID');
    if (whIdFrom.urlControl.pos) {
      log('Using warehouse of UrlControl POS', whIdFrom.urlControl.pos);
      return () => onSelect(whIdFrom.urlControl.pos);
    }
    if (whIdFrom.urlControl.wh) {
      log('Using UrlControl warehouse id', whIdFrom.urlControl.wh);
      return () => onSelect(whIdFrom.urlControl.wh);
    }
    if (associatedPosNew.enabled) {
      log('Using new POS association setting', whIdFrom.associatedPosNew);
      if (associatedPosNew.loading) return null;
      return () =>
        associatedPosNew.autoSelect(whIdFrom.associatedPosNew, onSelect, false);
    }
    if (whIdFrom.associatedPos) {
      log('Using warehouse of associated pos', whIdFrom.associatedPos);
      return () => onSelect(whIdFrom.associatedPos);
    }
    if (whIdFrom.alreadySelected) {
      log('Using already-selected warehouse', whIdFrom.alreadySelected);
      return () => onSelect(whIdFrom.alreadySelected);
    }
    log('No valid autoselect targets found');
    return undefined;
  }, [
    whIdFrom.urlControl.pos,
    whIdFrom.urlControl.wh,
    whIdFrom.associatedPos,
    whIdFrom.alreadySelected,
    whIdFrom.associatedPosNew,
    associatedPosNew,
    onSelect,
  ]);

  useEffect(() => {
    if (autoSelectWarehouseId) autoSelectWarehouseId();
  }, [autoSelectWarehouseId]);
};

const styles = {
  card: {
    width: '358px',
    maxWidth: '100%',
    borderRadius: '8px',
    border: 0,
  },
  header: {
    fontWeight: 700,
    fontSize: '24px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    height: '66px',
    padding: '15px 15px 15px 22px',
  },
  body: {
    maxHeight: '70vh',
    overflowY: 'scroll',
    padding: '16px 0px 0px',
    marginBottom: '20px',
  },
  close: {
    fontSize: '42px',
  },
  pos: {
    minHeight: '67px',
    padding: '16px 22px',
    textAlign: 'left',
    color: '#555',
    border: 0,
    borderRadius: 0,
  },
};

export default function WarehouseSelect({
  onCancel,
  toggleClockInOut,
  updateView,
}) {
  const dispatch = useDispatch();
  const onSelect = useCallback(id => dispatch(selectWarehouse(id)), [dispatch]);
  const { t } = useTranslation('login');

  const selectedWarehouse = useSelector(getSelectedWarehouse);
  const isLoading = useSelector(getIsLoading);
  const loadingDayOpen = isLoading && !!selectedWarehouse;

  const allowedWarehouses = useSelector(getAllowedWarehouses);
  const pointsByWarehouseIDs = useSelector(getEmployeePOSByWarehouseIDS);

  const allPoints = useMemo(
    () => Object.values(pointsByWarehouseIDs).flatMap(a => a),
    [pointsByWarehouseIDs],
  );
  useAutoSelectWarehouse(onSelect, allPoints, allowedWarehouses);

  return (
    <Loader
      show={loadingDayOpen}
      loadingText={t('loading.login', { context: 'checkingIfDayOpen' })}
    >
      <Card style={styles.card}>
        <Card.Header style={styles.header}>
          {t('selectWarehouse.title')}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <span
            className="icon_close Clickable-icon"
            style={styles.close}
            onClick={() => {
              onCancel();
              toggleClockInOut(false);
              updateView('login');
            }}
          />
        </Card.Header>
        <Card.Body style={styles.body}>
          <ListGroup style={{ border: 0 }}>
            {allowedWarehouses.map(warehouse => {
              const disabled = !pointsByWarehouseIDs[warehouse.warehouseID]
                ?.length;
              return (
                <ListGroup.Item
                  key={warehouse.warehouseID}
                  onClick={() => onSelect(warehouse.warehouseID)}
                  style={{
                    ...styles.pos,
                    ...(disabled ? { backgroundColor: '#ccc' } : {}),
                  }}
                  data-testid="warehouse-item"
                  data-test-key={warehouse.warehouseID}
                  className="Clickable-div"
                  disabled={disabled}
                >
                  <span
                    data-testid="warehouse-name"
                    style={{ fontSize: '24px' }}
                  >
                    {warehouse.name}
                  </span>
                  <br />
                  {disabled ? (
                    <span data-testid="warehouse-empty">
                      {t('selectWarehouse.empty')}
                    </span>
                  ) : (
                    warehouse.city && (
                      <span data-testid="warehouse-city">{warehouse.city}</span>
                    )
                  )}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card.Body>
      </Card>
    </Loader>
  );
}

WarehouseSelect.propTypes = {
  onCancel: PropTypes.func.isRequired,
  toggleClockInOut: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
};
