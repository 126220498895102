import React from 'react';
import PropTypes from 'prop-types';

const Records = ({ currentPage, itemsPerPage, totalRecords }) => {
  const visibleRecords = {
    from: (currentPage - 1) * itemsPerPage + 1,
    to:
      currentPage * itemsPerPage > totalRecords
        ? totalRecords
        : currentPage * itemsPerPage,
  };
  return (
    <div className="records">
      <span>{`Records ${visibleRecords.from} - ${visibleRecords.to} of ${totalRecords}`}</span>
    </div>
  );
};

Records.propTypes = {
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  itemsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  totalRecords: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default Records;
