import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import * as R from 'ramda';

import { Printer } from 'types/PrintingMS';
import { getPrintingMSSettings } from 'reducers/cafaConfigs';
import { getPrinterList } from 'services/InstallerApp/printingMS';

const usePrintingMSSettings = () => {
  const goMSSettings = useSelector(getPrintingMSSettings, R.equals);
  const { value: printer, loading } = useAsync<
    Printer | undefined
  >(async () => {
    const printers = goMSSettings
      ? await getPrinterList(goMSSettings.value.PrinterGetFullList)
      : [];
    return printers.find(
      (printer): printer is Printer =>
        printer.Name === goMSSettings?.value?.PrinterName,
    );
  }, [goMSSettings]);

  return { goMSSettings, printer, loading };
};

export default usePrintingMSSettings;
