import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import * as R from 'ramda';

import { Payment } from 'types/Payment';
import { modalPages } from 'constants/modalPage';
import { getComponents } from 'reducers/modalPage';
import {
  getCurrentSalesDocOriginalPayments,
  getCurrentSalesDocReturnPayments,
  getCurrentSalesDocument,
  getIsCurrentSaleAReturn,
  getIsUnreferencedReturn,
} from 'reducers/sales';
import { add } from 'utils';

import { getPayments, getCurrentSalesDocPaymentsCombo } from 'reducers/Payments';
import { PaymentObj } from 'paymentIntegrations/types';
import { getTotal } from 'reducers/ShoppingCart';

export const getWasSaleCompletedMoreThan12MonthsAgo = createSelector(
  getCurrentSalesDocument,
  currentSalesDocument =>
    dayjs()
      .subtract(12, 'month')
      .isAfter(dayjs(currentSalesDocument.date)),
);

const getWasOriginalSalePaidBySerialGiftCard = createSelector(
  getCurrentSalesDocOriginalPayments,
  originalPayments =>
    originalPayments.some(
      pmt => pmt.type === 'GIFTCARD' && pmt.cardNumber?.length,
    ),
);

export const getCanIssueNewGiftCardAsReturn = createSelector(
  getWasOriginalSalePaidBySerialGiftCard,
  getWasSaleCompletedMoreThan12MonthsAgo,
  (originallyPaidBySerialGiftCard, saleWasCompletedMoreThan12MonthsAgo) =>
    originallyPaidBySerialGiftCard || saleWasCompletedMoreThan12MonthsAgo,
);

export const getShouldShowIssueGiftCardSection = createSelector(
  getIsCurrentSaleAReturn,
  getIsUnreferencedReturn,
  getComponents,
  getCanIssueNewGiftCardAsReturn,
  (
    isReferencedReturn,
    isUnreferencedReturn,
    components,
    canIssueNewGiftCardAsReturn,
  ) => {
    const paymentModalOpen = components
      .map(comp => comp.component)
      .includes(modalPages.Payment);
    return (
      paymentModalOpen &&
      ((isReferencedReturn && canIssueNewGiftCardAsReturn) ||
        isUnreferencedReturn)
    );
  },
);

type EnhancedPayment = Payment & { spent: number; remaining: number };

export const getRemainingAmount = createSelector(
  getCurrentSalesDocPaymentsCombo,
  getCurrentSalesDocOriginalPayments,
  getCurrentSalesDocReturnPayments,
  getPayments,
  getWasSaleCompletedMoreThan12MonthsAgo,
  (
    enhancedOriginalPayments: EnhancedPayment[],
    originalPayments: Payment[],
    returnPayments: Payment[],
    currentPayments: PaymentObj[],
    saleWasCompletedMoreThan12MonthsAgo,
  ) => {
    if (saleWasCompletedMoreThan12MonthsAgo)
      return enhancedOriginalPayments.map(pmt => pmt.remaining).reduce(add, 0);

    const isSerialGiftCardPayment = pmt =>
      pmt.type === 'GIFTCARD' && (pmt.cardNumber?.length || pmt.serial?.length);
    return originalPayments
      .filter(isSerialGiftCardPayment)
      .map((pmt: Payment) => {
        const spentPreviouslyForThisPayment: number = returnPayments
          .filter(isSerialGiftCardPayment)
          .map(p => -Number(p.sum))
          .reduce(add, 0);
        const spentCurrentlyForThisPayment: number = Object.values(
          currentPayments,
        )
          .filter(isSerialGiftCardPayment)
          .map(p => -Number(p.amount))
          .reduce(add, 0);

        const spent =
          spentPreviouslyForThisPayment + spentCurrentlyForThisPayment;

        return Math.min(Number(pmt.sum) - spent, Number(pmt.sum));
      })
      .reduce(add, 0);
  },
);

export const getReturnPaymentLimits = createSelector(
  getCurrentSalesDocOriginalPayments,
  getCurrentSalesDocReturnPayments,
  (originalPayments: Payment[], returnPayments: Payment[]) =>
    R.pipe(
      R.groupBy(pmt =>
        pmt.cardNumber?.length ? `${pmt.type}-serial` : pmt.type,
      ),
      R.toPairs,
      R.map(([key, value]) =>
        R.reduce(
          (prev, current) =>
            R.modify('amount', R.add(Number(current.sum)), prev),

          {
            type: key.replace('-serial', ''),
            amount: 0,
            ...(key.includes('serial') ? { serial: true } : {}),
          },
          value,
        ),
      ),
    )([...originalPayments, ...returnPayments]),
);
