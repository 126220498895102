import {
  ProductGroup,
  SET_ZIP_CAPTURE_PRODUCT_GROUPS,
  SET_ZIP_CODE,
  REMOVE_ZIP_CODE,
  ZipActionTypes,
} from './types';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';

export const setCaptureZIPProductGroups = (
  payload: ProductGroup[],
): ZipActionTypes => ({
  type: SET_ZIP_CAPTURE_PRODUCT_GROUPS,
  payload,
});

export const setZipCode = (payload: string): ZipActionTypes => ({
  type: SET_ZIP_CODE,
  payload,
});

type Props = {
  dispatch: any;
};

export const addZip = ({ dispatch }: Props): Promise<string> =>
  new Promise((resolve, reject) =>
    dispatch(
      openModalPage({
        component: mp.pluginModal,
        props: {
          name: 'EnterZipModal',
          resolve,
          reject,
          dispatch,
        },
        isPopup: true,
      }),
    ),
  );
export const removeZipCode = (): ZipActionTypes => ({
  type: REMOVE_ZIP_CODE,
});
