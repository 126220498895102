import { createSelector } from 'reselect';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { customPaymentIntegration } from 'plugins/oneacre/CustomPaymentIntegration';
import { getActivePaymentIntegration } from 'reducers/cafaConfigs';

import { Conf, ReadonlyConfiguration } from './Configuration';

export const pluginID = 'oneAcreMPesa';

const oneAcreMPesa: PosPlugin<Conf> = {
  id: pluginID,
  name: 'mPesa payment integration (OneAcre)',
  // language=Markdown
  info: `## Warnings
This plugin is customer-specific. If you are not the intended user, enable this plugin at your own risk, as its behaviour may change at any time without warning.

## Usage
### Setup
* Enable the plugin
* Use Configuration Admin (or another CAFA tool of your choice) to create the necessary configurations. See the "Configure plugin" menu for a hint of what the configuration should look like
* Under "Configure plugin", press the button to select mPesa as the payment integration
### Usage
Simply add regular CARD payments to a sale, if mPesa is selected as the payment integration then they will be processed by this plugin

## Implementation
The plugin is implemented as a custom payment integration, with a button in the config to select it.

When processing payments, the plugin will prompt the user with a list of payments. Each payment requires the cashier to enter a valid phone number. Once the cashier enters a valid phone number and presses send, processing of that payment will begin.

The processing consists of
1) Generation of a reference number (done by the POS).
2) Sending the initial request to generate the transaction.
3) Periodic followup requests to query for the status of the transaction.

Because dismissed payments will report as pending forever and there is no 'cancel' request in the API, we allow the cashier to manually cancel a payment. - A warning is displayed to inform them of the consequences and to make sure they double check first, but otherwise no validation is performed

If a transaction fails / is cancelled, it may be retried in the same UI. If it succeeds it remains locked.  
When all transactions succeed, the UI will close and payment processing will finish
When no transactions are pending (all succeeded / failed / cancelled / not started), the user is able to cancel back to the payment screen

⚠️ Refunds and voids are not supported, this is a known limitation. The user will use 'external' integration or some other tender to perform returns`,
  getStatus: createSelector(
    state => getActivePaymentIntegration(state),
    integration => {
      if (integration === 'mPesa') {
        return { type: 'valid', message: 'Ready to use' };
      }
      return {
        type: 'error',
        message: 'mPesa not selected as payment integration',
      };
    },
  ),
  keywords: ['mobile', 'payment', 'mpesa', 'one', 'acre', 'oneacre'],
  ComponentConfiguration: ReadonlyConfiguration,
  combineConfiguration: (c, w, p, u) => ({
    url: c?.url ?? '',
    apiKey: c?.apiKey ?? '',
    accountNumber: c?.accountNumber ?? 0,
    walletId: [u, p, w, c].map(conf => conf?.walletId).find(id => id) ?? '',
  }),
  customPaymentIntegration,
};
export default oneAcreMPesa;
