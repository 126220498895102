import { styled } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

/**
 * A fixed width skeleton component used to "load" short words or phrases, for example product names
 */
export const SkeletonWord = styled(Skeleton)({
  width: '12ch',
  display: 'inline-block',
});
