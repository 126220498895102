import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

/**
 * StatusTag
 *
 * The component shows the current status of the processed operation
 *
 * @example
 * ```
 * <StatusTag processing={true} status={false} />
 * ```
 *
 * @returns
 * ```
 * <Spinner animation="border" size="sm" />
 * ```
 * or
 * ```
 * Successs
 * ```
 * or
 * ```
 * Failure
 * ```
 *
 */

const StatusTag = ({ processing, status }) => {
  const [active, setActive] = useState(false);
  const [timer, setTimer] = useState(null);
  useEffect(() => {
    setTimer(setTimeout(() => setActive(false), 2000));
    setActive(true);
    return () => clearTimeout(timer);
  }, [status, processing]);
  let message = '';
  message = status && (
    <Badge
      variant={status === 'Success' ? 'success' : 'danger'}
      className="d-flex justify-content-center align-items-center"
    >
      {status}
    </Badge>
  );
  if (processing) {
    return (
      <span
        className="d-flex justify-content-center align-items-center"
        style={{ minWidth: '5ch' }}
      >
        <Spinner animation="border" size="sm" />
      </span>
    );
  }
  if (active) {
    return (
      <span
        className="d-flex justify-content-center align-items-center"
        style={{ minWidth: '5ch' }}
      >
        {message}
      </span>
    );
  }
  return null;
};

StatusTag.propTypes = {
  /** Boolean value showing if the request being tested is in progress */
  processing: PropTypes.bool.isRequired,
  /** Final status of the processed request - can only be 'Success or 'Failure' */
  status: PropTypes.oneOf(['Success', 'Failure']),
};

/**
 * testButton
 *
 * The button renders UI for test request,
 * shows brief request info on hover if provided
 *
 * @example
 * ```
 * <TestButton button={{
 * variant: 'success',
 *  title: `Download software`,
 *  type: 'software',
 *  info: 'Downlaod latest software for the terminal',
 *  disabled: isOngoing || !Number(testingAmount) > 0,
 *  action: () => dispatch(downloadSoftware()),
 * }}
 * clickHandler={() => async () => {}} />
 * ```
 *
 * @returns
 * ```
 * <Button
 *  key={button.type}
 *   className="my-2"
 *   variant={button.variant}
 *   disabled={button.disabled || !button.action}
 *   onClick={clickHandler}
 * >
 *   {button.title}
 * </Button>
 * ```
 *
 */
const TestButton = ({ button, clickHandler }) => (
  <OverlayTrigger
    overlay={<Tooltip id={`tooltip-${button.type}`}>{button.info}</Tooltip>}
  >
    <span className="d-inline-block">
      <Button
        key={button.type}
        className="my-2"
        variant={button.variant}
        disabled={button.disabled || !button.action}
        onClick={clickHandler}
      >
        {button.title}
      </Button>
    </span>
  </OverlayTrigger>
);

TestButton.ptopTypes = {
  button: PropTypes.shape({
    /** bootstrap variant to be passed to the Button component */
    variant: PropTypes.string.isRequired,
    /** Title of the request button */
    title: PropTypes.string.isRequired,
    /** Type/Key of the button the button - MUST be unique */
    type: PropTypes.string.isRequired,
    /** Brief description of the request - woud be shown when the user hovers over the button */
    info: PropTypes.string,
    /** Boolean value to determine if the request button shoul dbe disabled  */
    disabled: PropTypes.bool.isRequired,
    /** Action to be dispatched on Button click -
     * must be redux action or a function that returns async function */
    action: PropTypes.func,
  }).isRequired,
  /** Action to be fired when the user clicks on the Request Button */
  clickHandler: PropTypes.func.isRequired,
};

/**
 *  Test payment integration block with status UI
 */

const TestButtonBlock = ({ button, clickHandler, processing, status }) => (
  <div className="d-flex justify-content-between">
    <TestButton button={button} clickHandler={clickHandler} />
    <StatusTag processing={processing} status={status} />
  </div>
);

TestButtonBlock.propTypes = {
  /** Boolean value showing if the request being tested is in progress */
  processing: PropTypes.bool.isRequired,
  /** Final status of the processed request - can only be 'Success or 'Failure' */
  status: PropTypes.oneOf(['Success', 'Failure']),
  /** Button object */
  button: PropTypes.shape({
    /** bootstrap variant to be passed to the Button component */
    variant: PropTypes.string.isRequired,
    /** Title of the request button */
    title: PropTypes.string.isRequired,
    /** Type/Key of the button the button - MUST be unique */
    type: PropTypes.string.isRequired,
    /** Brief description of the request - woud be shown when the user hovers over the button */
    info: PropTypes.string,
    /** Boolean value to determine if the request button shoul dbe disabled  */
    disabled: PropTypes.bool.isRequired,
    /** Action to be dispatched on Button click -
     * must be redux action or a function that returns async function */
    action: PropTypes.func,
  }).isRequired,
  /** Action to be fired when the user clicks on the Request Button */
  clickHandler: PropTypes.func.isRequired,
};

export default TestButtonBlock;
