import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useDebounce } from 'react-use';

import { Order } from 'types/ShoppingCart';
import { removeProduct, updateOrderAmount } from 'actions/ShoppingCart';
import { getAllowFractionalProductQuantities } from 'reducers/configs/settings';
import { RootState } from 'reducers';

import {
  getAutoFocusInputValue,
  getDisableOrderQty,
  getOrderAmount,
  sanitizeValue,
} from '../utils';

const SimpleRowQuantity = React.memo<Pick<Order, 'orderIndex'>>(
  ({ orderIndex }) => {
    const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
    const orderAmount = useSelector(getOrderAmount(orderIndex));
    const autoFocus = useSelector(getAutoFocusInputValue(orderIndex));
    const disabled = useSelector(getDisableOrderQty(orderIndex));
    const [tempAmount, setTempAmount] = useState<typeof orderAmount | null>(
      null,
    );
    const isFractionalQuantityAllowed = useSelector(
      getAllowFractionalProductQuantities,
    );

    const ref = useRef<HTMLInputElement>(null);

    // Avoids dispatching parallel calculate actions while user is typing
    const [isUpdatingOrderAmount] = useDebounce(
      async () => {
        if (
          !([null, '', '-'] as Array<typeof tempAmount>).includes(tempAmount)
        ) {
          await dispatch(updateOrderAmount(tempAmount, orderIndex));
          setTempAmount(prev => (prev !== tempAmount ? prev : null));
        }
      },
      700,
      [tempAmount],
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const qty = sanitizeValue(e.target.value);
      const isAcceptableValue = qty === '-' || !Number.isNaN(Number(qty));
      const respectsFractionalQtySetting =
        isFractionalQuantityAllowed ||
        (!isFractionalQuantityAllowed && !qty.includes('.'));

      if (
        isAcceptableValue &&
        respectsFractionalQtySetting &&
        qty !== tempAmount
      ) {
        setTempAmount(qty);
      }
    };

    const handleBlur = async () => {
      if (tempAmount === '-') {
        dispatch(updateOrderAmount(-1, orderIndex));
        return;
      }
      if (tempAmount === '') {
        setTempAmount(orderAmount);
      } else if (tempAmount !== null && !isUpdatingOrderAmount()) {
        await dispatch(updateOrderAmount(tempAmount, orderIndex));
        setTempAmount(null);
      }

      if (Number(orderAmount) === 0) {
        dispatch(removeProduct(orderIndex));
      }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        ref.current?.blur();
      }
    };

    return (
      <input
        type="text"
        ref={ref}
        id="autoFocusInput"
        value={tempAmount !== null ? tempAmount : orderAmount}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onClick={e => e.stopPropagation()}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        autoComplete="off"
        onFocus={autoFocus ? e => e.target.select() : undefined}
      />
    );
  },
);

export default SimpleRowQuantity;
