/* eslint-disable @typescript-eslint/camelcase */
import axios, { AxiosResponse } from 'axios';
import * as R from 'ramda';

import { proxy } from 'services/shared';
import {
  REDUX_CLIENTCODE,
  REDUX_SERVICE_ENDPOINTS,
  REDUX_SESSIONKEY,
} from 'constants/persistence';
import { getServiceEndpoints } from 'services/ErplyAPI/getServiceEndpoints';

export type InvoiceRowRecord = {
  parentRowID: number;
  returnTags: string[] | [];
  notes: string | '';
};

export type CreditInvoiceRecord = {
  creditedStableRowID: number;
};
export type JsonAPISuccessResponse = {
  id: number;
  json_object: {
    BrazilPOS: InvoiceRowRecord;
    CreditInvoice: CreditInvoiceRecord;
  };
};

export type JsonAPIErrorResponse = {
  error: string;
};

const getJsonApiUrl = async () => {
  const { url } = JSON.parse(
    localStorage.getItem(REDUX_SERVICE_ENDPOINTS) || '{}',
  )?.json;
  if (url) return url;
  const [payload] = await getServiceEndpoints();
  return payload?.json?.url;
};

/**
 * Get record from Json Api based on recordID - stableRowID in sales document
 * @param recordID
 */
export const getRecordsForInvoiceRow = async ({
  id,
}: {
  id: number;
}): Promise<AxiosResponse<JsonAPISuccessResponse>> => {
  const jsonApiUrl = await getJsonApiUrl();
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  return axios.get<JsonAPISuccessResponse>(
    `${proxy}${jsonApiUrl}v1/json_object/inv_rows/${id}`,
    {
      headers: { clientCode, sessionKey },
    },
  );
};
/**
 * Save record to Json Api
 * 1. Tries to fetch record
 * 2. If the record exists, modifies according to the payload
 * 3. If the payload is matching the returned record, ignores the save
 * 4. If record does not exist, saves new record
 */
export const saveRecordsToInvoiceRow = async (
  recordToSave: R.DeepPartial<JsonAPISuccessResponse> & { id: number },
) => {
  const emptyPayload: JsonAPISuccessResponse = {
    id: recordToSave.id,
    json_object: {
      CreditInvoice: {
        creditedStableRowID: 0,
      },
      BrazilPOS: {
        parentRowID: 0,
        returnTags: [],
        notes: '',
        serialNumber: '',
      },
    },
  } as JsonAPISuccessResponse;
  const response =
    (await getRecordsForInvoiceRow({
      id: recordToSave.id,
    })
      .then(res => res.data)
      .catch(() => ({} as JsonAPISuccessResponse))) ?? emptyPayload;

  // Ignoring null / undefined, merge deep in the following priorities:
  // 1. Record to save
  // 2. Response
  // 3. Defaults
  // recordToSave may always be filled with undefineds
  const merged = R.reduce(
    R.mergeDeepWith((a, b) => b ?? a),
    emptyPayload,
    [response, recordToSave],
  ) as JsonAPISuccessResponse;

  const isUnmodified = R.equals(merged, response);
  if (isUnmodified) return undefined;

  const jsonApiUrl = await getJsonApiUrl();
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  return axios.put<JsonAPISuccessResponse>(
    `${proxy}${jsonApiUrl}v1/json_object/inv_rows/${merged.id}`,
    merged,
    {
      headers: { clientCode, sessionKey },
    },
  );
};

export const getMultiRecordsForInvoiceRows = (recordIDs: number[]) => {
  const requests = recordIDs.map(r => getRecordsForInvoiceRow({ id: r }));
  return Promise.all(requests)
    .then(data => data.map(({ data }) => data))
};
