import { terminalDiagnosis } from 'paymentIntegrations/adyen/requests/adyenRequests';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';

const checkTerminalDiagnosis = async params => {
  const { updateMessage, enableButtons, displayWarning } = params;

  try {
    updateMessage('Checking connection...');
    const response = await terminalDiagnosis();

    // On successful diagnosis will always return resultCode: "0"
    if (response.resultCode === '0') {
      enableButtons([
        FUNC_BUTTONS.TERMINAL_DIAGNOSIS,
        FUNC_BUTTONS.CHECK_STATUS,
        FUNC_BUTTONS.RETRY,
        FUNC_BUTTONS.CLOSE,
      ]);
      return updateMessage(`Communications with terminal are OK.`);
    }

    enableButtons([FUNC_BUTTONS.TERMINAL_DIAGNOSIS, FUNC_BUTTONS.CHECK_STATUS]);
    console.warn('Adyen: Connection not OK', response);
    return updateMessage(
      `Terminal connection is not OK!\n${response.statusMessage}`,
    );
  } catch (error) {
    console.error('Adyen: Terminal diagnosis failed', error);

    const errorMessage = error?.response?.data?.errorMessage;
    if (errorMessage) {
      if (errorMessage.includes('busy')) {
        updateMessage('Terminal is Busy');
        displayWarning('Terminal is Busy');
        enableButtons([FUNC_BUTTONS.CHECK_STATUS, FUNC_BUTTONS.CANCEL]);
        return false;
      }
      updateMessage(error.errorMessage);
      displayWarning(error.errorMessage);
      enableButtons([FUNC_BUTTONS.CHECK_STATUS, FUNC_BUTTONS.CLOSE]);
      return false;
    }

    enableButtons([FUNC_BUTTONS.CHECK_STATUS, FUNC_BUTTONS.CLOSE]);
    updateMessage(error.message);
    displayWarning(error.message);
    return false;
  }
};

export default checkTerminalDiagnosis;
