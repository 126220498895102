export const SET_PAYMENTS = 'PAYMENT: SET_PAYMENTS';
export const SET_PAYMENT = 'PAYMENT: SET_PAYMENT';
export const SET_PAYMENT_SELECTED = 'PAYMENT: SET_PAYMENT_SELECTED';
export const SET_STATE = 'PAYMENT: SET_STATE';
export const UPDATE_PAYMENTS_TOTAL = 'PAYMENT: UPDATE_PAYMENTS_TOTAL';
export const UPDATE_CURRENCY = 'PAYMENT: UPDATE_CURRENCY';
export const SET_SHOW_PAYMENT_INPUT = 'PAYMENT: SET_SHOW_PAYMENT_INPUT';
export const SET_CHARGE_CONFIRMED = 'PAYMENT: SET_CHARGED_CONFIRMED';
export const SET_TRANSACTION_CONFIRMED = 'PAYMENT: SET_TRANSACTION_CONFIRMED';
export const SET_SALES_DOCUMENT = 'PAYMENT: SET_SALES_DOCUMENT';
export const SET_PAYMENT_EDIT_VALUE = 'PAYMENT: SET_PAYMENT_EDIT_VALUE';
export const RESET_PAYMENTS = 'PAYMENT: RESET_PAYMENTS';
export const CONFIRM_PAYMENT = 'PAYMENT: CONFIRM_PAYMENT';
export const MARK_FOR_PROCESSING = 'PAYMENT: MARK_FOR_PROCESSING';
export const UNMARK_FROM_PROCESSING = 'PAYMENT: UNMARK_FROM_PROCESSING';
export const GET_PAYMENT_TYPES = 'GET_PAYMENT_TYPES';
export const SET_LAST_PAYMENTS = 'SALES:SET_LAST_PAYMENTS';
export const DELETE_LAST_PAYMENT = 'SALES:DELETE_LAST_PAYMENT_BY_KEY';
export const SET_MANUAL_STORE_CREDIT = 'PAYMENT: SET_MANUAL_STORE_CREDIT';
export const SET_BOARD_CARD = 'PAYMENT: SET_BOARD_CARD';

export const SET_WAITING_FOR_TERMINAL = 'PAYMENT: SET_WAITING_FOR_TERMINAL';
export const SET_PAY_BUTTON_CLICKED = 'PAYMENTS: SET_PAY_BUTTON_CLICKED';
export const SET_CARD_TOKEN_IN_PAYMENT = 'PAYMENTS: SET_CARD_TOKEN_IN_PAYMENT';
export const REMOVE_CARD_TOKEN_IN_PAYMENT =
  'PAYMENTS: REMOVE_CARD_TOKEN_IN_PAYMENT';
export const PAYMENT_TYPES = {
  CASH: 'CASH',
  CARD: 'CARD',
  CREDIT: 'CREDIT',
  GIFTCARD: 'GIFTCARD',
  CHECK: 'CHECK',
  TRANSFER: 'TRANSFER',
  TIP: 'TIP',
};
