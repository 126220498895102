import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';

/**
 * MM doesn't care about permissions.
 * They want to ensure that users who explicitly have the permission to "manually apply promotions and coupons by entering a coupon code" removed
 * to still be able to manually apply promotions regardless
 */
export const getSaleOptionButtons: Required<
  PosPlugin
>['selectorOverrides']['getSaleOptionButtons'] = base =>
  createSelector(base, btns =>
    btns.map(btn => {
      switch (btn.id) {
        case 'promotions':
          return {
            ...btn,
            action: () => openModalPage({ component: mp.salePromotions }),
          };

        default:
          return btn;
      }
    }),
  );
