import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useBreakpoints } from 'utils/hooks/UI';
import { getShowPaymentInput } from 'reducers/Payments';
import { closePayments } from 'actions/Payments';
import { useAsyncFunctionNoParallelExecution } from 'utils/hooks/useSingleUseFunction';

import { PaymentDesktop, PaymentMobile, PaymentInput } from './views';
import { useEvent } from 'react-use';

const Payment = () => {
  const dispatch = useDispatch();
  const isMobileView = !useBreakpoints().mdplus;
  const { showPaymentsInput } = useSelector(getShowPaymentInput);

  const [closePaymentHandler] = useAsyncFunctionNoParallelExecution(() =>
    dispatch(closePayments()),
  );

  useEvent('beforeunload', e => {
    e.preventDefault();
    e.returnValue = '';
  });

  if (isMobileView) {
    if (showPaymentsInput) return <PaymentInput />;
    return <PaymentMobile voidHandler={closePaymentHandler} />;
  }
  return <PaymentDesktop voidHandler={closePaymentHandler} />;
};
export default Payment;
