import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import {
  getCardPaymentsForIntegration,
  getIsWaitingForTerminal,
} from 'reducers/Payments';
import { getSetting } from 'reducers/configs/settings';
import {
  deletePayment,
  markForProcessing,
  unmarkFromProcessing,
} from 'actions/Payments';
import { INTEGRATION_TYPES } from 'constants/CAFA.ts';
import { getTotal } from 'reducers/ShoppingCart';
import { getReturnIsPartial } from 'reducers/sales';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { getClientCode } from 'reducers/Login';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';
import { getErrorMessage, withWaitingForTerminal } from 'paymentIntegrations';

import swedbankVoid from './requests/void';
import swedbankRefund from './requests/return';
import swedbankPayment from './requests/payment';
import actLoop from './requests/act';
import closeDay from './requests/reconciliation';
import handlePaymentSuccess from './requestHandlers/paymentHandler';
import {
  handleSuccessVoid,
  handleSuccessRefund,
} from './requestHandlers/voidHandler';
import swedBankRequest from './requests';

export const title = 'Swedbank Terminal Interface';

/**
 * If a payment has been not been sent for reconciliation then a void must be performed to cancel the payment.
 * @param {arrayOf(Object)} cardPayments The returns to be proccessed
 * @param {Object} hooks Methods to manipulate the CardPaymentUI component
 * @param {Object} swedbankData Swedbank specific data.
 * @param {Boolean} voidAll Determines wheather confirmed payments should be voided.
 */
const processVoidPayments = (
  cardPayments,
  hooks,
  swedbankData,
  /** True for voids (payments not saved), false for returns (payments already saved) */
  voidAll = false,
) => async (dispatch, getState) => {
  const { enableButtons, updateMessage } = hooks;
  const clientCode = getClientCode(getState());
  const errors = { shouldReturn: false };
  const completedPayments = await cardPayments
    .filter(p => (voidAll ? p.shouldProcess && p.paid : !p.paid))
    .map(p => R.assoc('uuid', uuidv4())(p))
    .reduce(
      (pr, payment, index) =>
        pr.then(async acc => {
          if (!errors.errors) {
            enableButtons([]);

            return dispatch(
              withWaitingForTerminal(async () => {
                try {
                  const response = await swedbankVoid(
                    payment,
                    swedbankData,
                    {
                      enableButtons,
                      updateMessage,
                    },
                    cardPayments.length,
                    index,
                    clientCode,
                  );
                  const successfulVoid = await dispatch(
                    voidAll
                      ? handleSuccessVoid(response, payment, hooks)
                      : handleSuccessRefund(response, payment, hooks),
                  );
                  if (successfulVoid) {
                    return [...acc, payment.key];
                  }

                  // If void failed due to transaction missing AND we are in refund - try to refund it
                  if (
                    !voidAll &&
                    response.data.records[0]?.rawResponse?.ReturnCode === '32781'
                  ) {
                    const refundResponse = await dispatch(swedbankRefund(
                      payment,
                      swedbankData,
                      {
                        enableButtons,
                        updateMessage,
                      },
                      cardPayments.length,
                      index,
                      clientCode,
                    ))
                    const successfulRefund = await dispatch(handleSuccessRefund(refundResponse, payment, hooks));
                    if (successfulRefund) {
                      return [...acc, payment.key];
                    }
                    
                  }
                  errors.errors = true;
                  enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
                  return acc;
                } catch (error) {
                  errors.errors = true;
                  enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
                  return acc;
                }
              }),
            );
          }
          return acc;
        }),
      Promise.resolve([]),
    );
  return { errors, completedPayments };
};

/**
 * If a payment has been sent for reconciliation then a return must be performed to refund the payment.
 * @param {arrayOf(Object)} cardPayments The returns to be proccessed
 * @param {CardPaymentHooks} hooks Methods to manipulate the CardPaymentUI component
 * @param {Object} swedbankData Swedbank specific data.
 */
const processReturns = (cardPayments, hooks, swedbankData) => async (
  dispatch,
  getState,
) => {
  const clientCode = getClientCode(getState());
  let errors;
  const completedPayments = await cardPayments
    .filter(p => !p.paid)
    .map(p => R.assoc('uuid', uuidv4())(p))
    .reduce(
      (pr, payment, index) =>
        pr.then(async acc => {
          if (!errors) {
            hooks.enableButtons([]);

            return dispatch(
              withWaitingForTerminal(async () => {
                try {
                  const response = await dispatch(
                    swedbankRefund(
                      payment,
                      swedbankData,
                      hooks,
                      cardPayments.length,
                      index,
                      clientCode,
                    ),
                  );
                  const successfulRefund = await dispatch(
                    handleSuccessRefund(response, payment, hooks),
                  );
                  if (successfulRefund) {
                    return [...acc, payment.key];
                  }
                  errors = true;
                  hooks.enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
                  return acc;
                } catch (err) {
                  errors = true;
                  hooks.enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
                  return acc;
                }
              }),
            );
          }
          return acc;
        }),

      Promise.resolve([]),
    );
  return { errors, completedPayments };
};

const processPayments = (
  { cardPayments, enableButtons, updateMessage, beforeDocSave, rejectPayments },
  swedbankData,
) => async (dispatch, getState) => {
  const clientCode = getClientCode(getState());
  let errors;
  const completedPayments = await cardPayments
    .filter(p => !p.paid)
    .map(p => R.assoc('uuid', uuidv4())(p))
    .reduce(
      (pr, payment, idx, filteredPmnts) =>
        pr.then(acc => {
          if (!errors) {
            enableButtons([]);
            return dispatch(
              withWaitingForTerminal(async () => {
                try {
                  const res = await swedbankPayment(
                    payment,
                    swedbankData,
                    {
                      updateMessage,
                      enableButtons,
                    },
                    filteredPmnts.length,
                    idx,
                    clientCode,
                  );
                  const successfulPayment = await dispatch(
                    handlePaymentSuccess(res, payment, {
                      updateMessage,
                      beforeDocSave,
                      enableButtons,
                      swedbankData,
                      rejectPayments,
                    }),
                  );
                  if (successfulPayment) {
                    return [...acc, successfulPayment];
                  }
                  errors = true;
                  enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
                  return acc;
                } catch (err) {
                  errors = true;
                  enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
                  return acc;
                }
              }),
            );
          }
          return acc;
        }),
      Promise.resolve([]),
    );
  return { errors, completedPayments };
};

/** Error thrown when a void payment could not be voided but POS should reattempt it as a refund */
const SHOULD_RETURN = Symbol('Should return');
export const initPayments = params => async (dispatch, getState) => {
  const {
    cardPayments,
    enableButtons,
    resolvePayments,
    updateMessage,
    currencyCode,
  } = params;
  const state = getState();
  const totalToPay = getTotal(state);
  const { value: swedbankConfig } = getCafaEntry(
    'swedbank',
    INTEGRATION_TYPES.payment,
  )(state);
  const swedbankData = { ...swedbankConfig, currencyCode };
  enableButtons([]);
  const shouldVoid = cardPayments.every(p => p.shouldProcess && p.paid);
  const isPartialReturn = getReturnIsPartial(state);

  if (shouldVoid) {
    actLoop.start(updateMessage);
    return dispatch(
      processVoidPayments(cardPayments, params, swedbankData, true),
    )
      .finally(actLoop.stop)
      .then(data => {
        if (data.errors.errors) {
          if (data.errors.shouldReturn === true) {
            return 0;
          }
          enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
          return false;
        }
        updateMessage('Cancellation Successful');
        return setTimeout(() => resolvePayments(), 1000);
      })
      .catch(err => {
        updateMessage(
          getErrorMessage(err, 'Error while proccessing swedbank payments'),
        );
        console.error('Error while proccessing swedbank payments', err);
      });
  }

  if (totalToPay < 0) {
    let voidedPayment = SHOULD_RETURN;

    if (!isPartialReturn) {
      actLoop.start(updateMessage);
      voidedPayment = await dispatch(
        processVoidPayments(cardPayments, params, swedbankData),
      )
        .finally(actLoop.stop)
        .then(data => {
          if (data.errors.errors) {
            if (data.errors.shouldReturn) {
              throw SHOULD_RETURN;
            }
            enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
            return false;
          }
          updateMessage('Cancellation Successful');
          return setTimeout(() => resolvePayments(), 1000);
        })
        .catch(err => {
          updateMessage(
            getErrorMessage(err, 'Error while proccessing swedbank payments'),
          );
          console.error('Error while proccessing swedbank payments', err);
        });
    }

    if (voidedPayment === SHOULD_RETURN) {
      actLoop.start(updateMessage);
      return dispatch(processReturns(cardPayments, params, swedbankData))
        .finally(actLoop.stop)
        .then(data => {
          if (data.errors) {
            enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
          } else {
            updateMessage('Refund Successful');
            setTimeout(() => resolvePayments(), 1000);
          }
        })
        .catch(err => {
          updateMessage(
            getErrorMessage(err, 'Error while proccessing swedbank payments'),
          );
          console.error('Error while proccessing swedbank payments', err);
        });
    }
    return false;
  }

  actLoop.start(updateMessage);
  return dispatch(processPayments(params, swedbankData))
    .finally(actLoop.stop)
    .then(data => {
      if (data.errors) {
        enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);
      } else {
        updateMessage('Payment Successful');
        setTimeout(() => resolvePayments(), 1000);
      }
    })
    .catch(err => {
      updateMessage(
        getErrorMessage(err, 'Error while proccessing swedbank payments'),
      );
      console.error('Error while proccessing swedbank payments', err);
    });
};

const cancelCurrentPayments = ({
  cardPayments,
  enableButtons,
  rejectPayments,
  updateMessage,
}) => async (dispatch, getState) => {
  const isWaitingForTerminal = getIsWaitingForTerminal(getState());
  try {
    await Promise.all(
      cardPayments.map(p => dispatch(unmarkFromProcessing({ key: p.key }))),
    );
    const {
      data: {
        records: [record],
      },
    } = await swedBankRequest.post('manage', {
      manageType: 'cancelTransaction',
    });
    switch (record.resultCode) {
      case '0':
        enableButtons([]);
        rejectPayments();
        break;
      default:
        if (!isWaitingForTerminal) {
          rejectPayments();
          return;
        }
        updateMessage(
          record.statusMessage ??
            'Cancelling from POS failed. Please cancel from terminal',
        );
    }
  } catch (error) {
    updateMessage(
      getErrorMessage(error, 'Something went wrong on payment cancelling.'),
    );
  } finally {
    actLoop.stop();
  }
};

export const cancelPayments = params => async dispatch =>
  dispatch(cancelCurrentPayments(params)).catch(console.error);

/**
 * Void current successful payments in case the user wants to abort the current purchase
 */
export const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('swedbank')(getState());
  cardPayments.forEach(({ key, paid }) =>
    paid
      ? dispatch(markForProcessing({ key }))
      : dispatch(deletePayment({ key })),
  );

  const finalPayments = getCardPaymentsForIntegration('swedbank')(getState());
  if (finalPayments.length) {
    await dispatch(openTerminalIntegration());
  }
};

export const retryPayments = params => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('swedbank')(getState());
  if (cardPayments.every(c => c.paid)) {
    Promise.all(
      cardPayments.map(c => dispatch(markForProcessing({ key: c.key }))),
    );
  }
  const finalPayments = getCardPaymentsForIntegration('swedbank')(getState());
  dispatch(initPayments({ ...params, cardPayments: finalPayments }));
};

/**
 * Close day a.k.a. 'closeBatch', 'reconciliation'
 *
 * Action must return true in case of a success and {false} in case of a fail
 */
export const closeBatch = () => async (dispatch, getState) => {
  const swedbankPort = getSetting('terminalPort-swedbank')(getState());
  const swedbankIP = getSetting('terminalIP-swedbank')(getState());
  const swedbankData = { swedbankIP, swedbankPort };
  try {
    await closeDay(swedbankData);
    return true;
  } catch (err) {
    console.error('Swedbank:closeBatch:err\n', err);
    return false;
  }
};

export const functions = [
  {
    actionOnClick: retryPayments,
    name: FUNC_BUTTONS.RETRY,
    text: 'Retry Payment',
    variant: 'warning',
  },
  {
    actionOnClick: cancelPayments,
    name: FUNC_BUTTONS.CLOSE,
    text: 'Close',
    variant: 'danger',
  },
];
