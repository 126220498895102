import { PatchScript } from '@pos-refacto/patchscript-with-react';

import { mmReceiptTranslations } from 'plugins/mm/features/receipt/pieces/translations/translations';

export const receiptFooter = (
  { footer: f } = mmReceiptTranslations.en,
  invoiceNumber: string,
  footer: string,
): PatchScript => [
  {
    type: 'barcode',
    format: 'Code39FullASCII',
    data: invoiceNumber,
    align: 'center',
  },
  ...f.map(text => ({
    type: 'text' as const,
    align: 'center' as const,
    pieces: [{ text }],
  })),
  {
    type: 'text',
    align: 'center',
    pieces: [{ text: footer }]
  }
];
