import React, { KeyboardEvent, useContext, useEffect, useMemo } from 'react';
import * as R from 'ramda';

type KeyboardKey = KeyboardEvent['key'];
type Handler = {
  key: KeyboardKey;
  handler?: ((e: KeyboardEvent) => any) | null;
  priority: number;
  upHandler?: ((e: KeyboardEvent) => any) | null;
};
const listeners: Handler[] = [];

const preventDefaultUnlessException = e => {
  // When in an input field, do not prevent regular input field shortcuts
  if (
    [
      'Enter',
      'Home',
      'End',
      'PageUp',
      'PageDown',
      'Backspace',
      'Delete',
      'Tab',
    ].includes(e.key)
  ) {
    if (['INPUT', 'TEXTAREA'].includes(e.target?.nodeName)) {
      return; // Do not prevent regular input field shortcuts
    }
  }
  e.preventDefault();
};
const sharedListener = e => {
  const keyRegistered = listeners
    .sort((a, b) => b.priority - a.priority)
    .reduce(
      (a, b) => a || (b.key === e.key && (e.repeat || b.handler?.(e) || true)),
      false,
    );

  if (keyRegistered) preventDefaultUnlessException(e);
};
const sharedListenerUp = e => {
  const keyRegistered = listeners
    .filter(({ upHandler }) => upHandler)
    .sort((a, b) => b.priority - a.priority)
    .reduce(
      (a, b) => a || (b.key === e.key && (b.upHandler?.(e) || true)),
      false,
    );
  if (keyRegistered) preventDefaultUnlessException(e);
};

document.body.addEventListener('keydown', sharedListener);
document.body.addEventListener('keyup', sharedListenerUp);

const shortcutScopeCtx = React.createContext({
  surpress: false,
  defaultPriority: 10,
});
export const ShortcutScope: React.FC<React.ComponentProps<
  typeof shortcutScopeCtx.Provider
>['value']> = ({ children, ...props }) => {
  const defaults = useContext(shortcutScopeCtx);
  return (
    <shortcutScopeCtx.Provider value={R.mergeDeepLeft(props, defaults)}>
      {children}
    </shortcutScopeCtx.Provider>
  );
};

/**
 * Binds a given function to a shortcut key - triggerable globally throughout the app (you don't have to force focus into your component)
 * If multiple handlers are bound to a key, only the one with the highest priority gets run
 *
 * NOTE: For keys held down, this will only trigger the event once
 *
 * @param key
 * @param {function(event): void} handler handler to run when the key is pressed
 * @param {number} priority Priority of the shortcut, higher is higher, default is 10
 * @param {function(event): void} upHandler handler to run when the key is released
 */
export function useShortcut(
  key: KeyboardEvent['key'],
  handler?: ((e: KeyboardEvent<Element>) => any) | null,
  priority?: number,
  upHandler?: (e: KeyboardEvent<Element>) => any,
) {
  const { surpress, defaultPriority } = useContext(shortcutScopeCtx);
  const listener = useMemo(
    () => ({
      key,
      handler,
      priority: priority ?? defaultPriority,
      upHandler,
    }),
    [key, handler, priority ?? defaultPriority, upHandler],
  );
  useEffect(() => {
    if (listener.key && listener.handler && !surpress) {
      listeners.unshift(listener);
      return () => {
        listeners.splice(listeners.indexOf(listener), 1);
      };
    }
    return () => {};
  }, [listener, surpress]);
}
