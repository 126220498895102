import { createSelector, Selector } from 'reselect';

import {
  getProductsInShoppingCart,
  getTotal,
  getTotalNet,
} from 'reducers/ShoppingCart';
import { toCayan, CayanTotals, CayanItem } from 'plugins/cayanCustomerDisplay/api';
import { getPluginConfiguration } from 'reducers/Plugins';
import { add } from 'utils';
import { getMSEndpointFromLS } from 'reducers/installer';

// region External dependencies
const getPnpTaxesConfig = (state) => {
  return (
    getPluginConfiguration<{
      envFeeName: string;
      admissionTaxName: string;
      admissionFeeName: string;
    }>('environmental-&-CA-fees')(state) ?? {}
  );
};
// endregion

const entity = 'cayan-integration';

const getCayanEndpoint: Selector<
  any,
  string|null
> =
  () => getMSEndpointFromLS('cayan');

export const ensureCayanInstalledAndRunning = () => async (
  dispatch,
  getState,
) => {
  // TODO: Ensure cayan is running
};

export const getCayanEndpointURL = getCayanEndpoint;

export const getProductsForDisplay = createSelector(
  state => getProductsInShoppingCart(state),
  state => getPnpTaxesConfig(state),
  (products, conf): CayanItem[] => {
    const { envFeeName, admissionTaxName, admissionFeeName } = conf;
    const isTaxProduct = product =>
      [envFeeName, ...(admissionFeeName?.split(',') ?? [])].includes(
        product.name,
      );
    const isTax = product => [admissionTaxName].includes(product.name);

    const regularProducts = products.filter(p => !isTaxProduct(p) && !isTax(p));
    const taxlikeProducts = products.filter(p => isTaxProduct(p));
    return [...regularProducts, ...taxlikeProducts].map(toCayan);
  },
);

export const getTotals = createSelector(
  state => getProductsInShoppingCart(state),
  state => getPnpTaxesConfig(state),
  state => Number(getTotal(state)),
  state => Number(getTotalNet(state)),

  (products, pnpTaxConf, total, net): CayanTotals => {
    const { admissionTaxName } = pnpTaxConf;
    const isTax = product => admissionTaxName === product.name;
    const productTaxTotal = products
      .filter(p => isTax(p))
      .map(p => p.rowTotal)
      .reduce(add, 0);
    return {
      OrderTotal: total.toFixed(2),
      OrderTax: (total - net + productTaxTotal).toFixed(2),
    };
  },
);
