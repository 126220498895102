import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';
import { addError } from 'actions/Error';
import Icon from 'components/Icon';

export type PluginConf = {
  translations: ReturnType<Required<PosPlugin>['getTranslationOverrides']>;
};
export const ConfComponent: PosPlugin<
  PluginConf
>['ComponentConfigurationByLevel'] = {
  Company: ({ current = { translations: {} } as PluginConf, save }) => {
    const [data, setData] = useState(current?.translations ?? {});

    const dispatch = useDispatch();
    const addFile = (name, dataUrl) => {
      const match = `${dataUrl}`.match(/data:(.+?);(.+?),(.+)/);
      if (!match) return;
      const [, type, , data] = match;
      if (type !== 'application/json') {
        dispatch(addError('Uploaded translation file must be .json'));
        return;
      }
      try {
        const parsedData = JSON.parse(
          // This correctly decodes UTF-8
          decodeURIComponent(escape(window.atob(data))),
        );
        setData(c => ({
          ...c,
          [name.replace(/.json/, '')]: parsedData,
        }));
      } catch (e) {
        dispatch(addError('Error parsing file contents as JSON'));
        console.error('Error parsing file contents as JSON', e);
      }
    };
    return (
      <div>
        <h3>Upload files here</h3>
        <InputField
          type="file"
          multiple
          value={null}
          onChange={e => addFile(e.target.name, e.target.value)}
        />
        <UIButton
          text="Save"
          disabled={data === current?.translations}
          action={() => save({ translations: data })}
        />
        <h3>Current translations</h3>
        {Object.entries(data).map(([filename, data]) => (
          <div>
            <Icon
              name="icon_trash"
              action={() => setData(({ [filename]: _, ...c }) => c)}
              style={{ color: 'salmon' }}
            />
            <h3>{filename}</h3>
            <ul>{JSON.stringify(data)}</ul>
          </div>
        ))}
      </div>
    );
  },
};
