/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import { createSelector } from 'reselect';
import * as R from 'ramda';

import * as actionTypes from 'constants/Payments';
import * as loginTypes from 'constants/Login';
import { add, round } from 'utils';
import {
  getDefaultCurrency,
  getPosRoundCash,
  getPosRoundCashFunction,
} from 'reducers/configs/settings';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getActivePaymentIntegration } from 'reducers/cafaConfigs';
import { getDefaultCustomer } from 'reducers/customerSearch';

import { getCurrencyByCode } from './configs/currency';
import { createOverrideSelector } from './Plugins';
import { getPaymentLimits } from './PaymentLimits';

const initialState = {
  isCurrentSaleAReturn: false,
  cumulativeSumOfRoundings: 0,
  currencies: [],
  currentSalesDocument: {},
  employeeID: 0,
  originalPayments: [],
  returnPayments: [],
  selectedCustomer: {},
  selectedPos: {},
  shoppingCartTotal: 0,
  type: '',
  salesDocument: {},
  total: null,
  payments: {},
  customer: {},
  resetSalesDocumentOnClose: null,
  ignoreCurrent: false,
  payButtonClicked: false,
  paymentLimits: [],
  paymentEditValue: '',
  paymentSelected: null,
  showPaymentsInput: {},
  currency: {},
  usingManualStoreCredit: false,
  waitingForTerminal: false,
};

export default (localState = initialState, { type, payload }) => {
  const deselectPaymentIfPaid = R.when(
    // If currently selected payment is paid
    ls => ls.payments[ls.paymentSelected]?.paid,
    // Then deselect it
    R.assoc('paymentSelected', ''),
  );
  const removeSelectedIfUnpaidAndZero = state => {
    const paymentSelected = state.payments[state.paymentSelected];
    if (!paymentSelected) return state;
    if (paymentSelected.paid) return state;
    if (Number(paymentSelected.amount)) return state;
    return R.dissocPath(['payments', state.paymentSelected], state);
  };
  const selectPayment = key => state => {
    if (state.paymentSelected === key) return state;
    return R.pipe(
      removeSelectedIfUnpaidAndZero,
      R.assoc('paymentEditValue', ''),
      R.assoc('paymentSelected', key),
    )(state);
  };
  const set = field =>
    R.pipe(R.assoc(field, payload), deselectPaymentIfPaid)(localState);

  switch (type) {
    case actionTypes.RESET_PAYMENTS:
    case loginTypes.TYPE_LOGOUT:
      return initialState;
    case actionTypes.SET_STATE:
      return payload;
    case actionTypes.SET_WAITING_FOR_TERMINAL:
      return { ...localState, waitingForTerminal: payload };
    case actionTypes.SET_PAYMENT_SELECTED:
      return selectPayment(payload)(localState);

    case actionTypes.MARK_FOR_PROCESSING:
      return R.assocPath(
        ['payments', payload, 'shouldProcess'],
        true,
      )(localState);
    case actionTypes.UNMARK_FROM_PROCESSING:
      return R.assocPath(
        ['payments', payload, 'shouldProcess'],
        false,
      )(localState);
    case actionTypes.SET_MANUAL_STORE_CREDIT:
      return set('usingManualStoreCredit');
    case actionTypes.SET_PAYMENT:
      return R.assocPath(['payments', payload.key], payload, localState);
    case actionTypes.SET_PAYMENTS:
      return set('payments');
    case actionTypes.SET_SHOW_PAYMENT_INPUT:
      return set('showPaymentsInput');
    case actionTypes.SET_SALES_DOCUMENT:
      return set('salesDocument');
    case actionTypes.SET_PAY_BUTTON_CLICKED:
      return set('payButtonClicked');
    case actionTypes.SET_PAYMENT_EDIT_VALUE:
      return set('paymentEditValue');
    case actionTypes.UPDATE_PAYMENTS_TOTAL:
      return set('total');
    case actionTypes.UPDATE_CURRENCY:
      return {
        ...localState,
        currency: payload,
      };
    case actionTypes.SET_BOARD_CARD:
      return R.assocPath(
        ['payments', payload.key, 'boardCard'],
        payload.flag,
        localState,
      );
    case actionTypes.SET_CARD_TOKEN_IN_PAYMENT:
      return R.assocPath(
        ['payments', payload.key, 'cardToken'],
        payload.cardToken,
        localState,
      );
    case actionTypes.REMOVE_CARD_TOKEN_IN_PAYMENT:
      return R.dissocPath(['payments', payload.key, 'cardToken'], localState);
    default:
      return localState;
  }
};

/*
 * UI Behaviour
 */
export function getIsCurrentSaleAReturn(state) {
  return state.Payments.isCurrentSaleAReturn;
}
export function getTotal(state) {
  return state.Payments.total;
}
export function getShowCashInput(state) {
  return state.Payments.showCashInput;
}
export function getOriginalPayments(state) {
  return state.Payments.originalPayments;
}
export function getReturnPayments(state) {
  return state.Payments.returnPayments;
}
export function getTransactionConfirmed(state) {
  return state.Payments.transactionConfirmed;
}
export function getChargeConfirmed(state) {
  return state.Payments.chargeConfirmed;
}
export function getPaymentEditIndex(state) {
  return state.Payments.paymentEditIndex;
}
export function getPaymentDecimalEdit(state) {
  return state.Payments.paymentDecimalEdit;
}
export function getResetSalesDocumentOnClose(state) {
  return state.Payments.resetSalesDocumentOnClose;
}
/**
 * When cancelling documents, we have to be able to do so without interactions with current cart/selected customer
 * @param {import('reducers').RootState} state
 * @returns
 */
export function getIgnoreCurrentOngoingSale(state) {
  return state.Payments.ignoreCurrent;
}
export function getHasPayButtonBeenClicked(state) {
  return state.Payments.payButtonClicked;
}
export function getPaymentEditValue(state) {
  return state.Payments.paymentEditValue;
}

/*
 * Payments
 */
export function getPayments(state) {
  return state.Payments?.payments || {};
}
export function getShowPaymentInput(state) {
  return state.Payments.showPaymentsInput;
}
export const getAllPayments = createSelector(getPayments, pmts =>
  Object.entries(pmts).map(([key, v]) => ({ ...v, key })),
);
export function getIsWaitingForTerminal(state) {
  return state.Payments.waitingForTerminal;
}
export const getCardPayments = createSelector(getAllPayments, payments =>
  payments.filter(payment => payment.type === 'CARD'),
);
export const getCardPaymentKeys = createSelector(
  getCardPayments,
  cardPayments => cardPayments.map(card => card.key),
);
export const getUnpaidCardPayments = createSelector(getCardPayments, pmts =>
  pmts.filter(p => !p.paid),
);
export const getPaidCardPayments = createSelector(getCardPayments, pmts =>
  pmts.filter(p => p.paid),
);
export function getExistingInReduxHookPayments(hookPayments) {
  return state => {
    const reduxCardPayments = getCardPayments(state);
    return (
      hookPayments
        .map(({ key }) => {
          return reduxCardPayments.find(p => p.key === key);
        })
        // filter out cards that were not found and resulted in undefined
        .filter(card => card)
    );
  };
}
export function getCardPaymentsForIntegration(integration) {
  return state =>
    Object.entries(getPayments(state))
      .map(([key, v]) => ({ ...v, key }))
      .filter(payment => {
        const currentInt =
          !payment.paymentIntegration &&
          payment.type === 'CARD' &&
          integration === getActivePaymentIntegration(state);
        const forcedInt = payment.paymentIntegration === integration;
        return currentInt || forcedInt;
      });
}

export function getIsUsingManualStoreCredit(state) {
  return state.Payments.usingManualStoreCredit;
}

export function getPaymentSelected(state) {
  return state.Payments.paymentSelected;
}

export const getIsCheckAndGiftCardMenuSelected = createSelector(
  getPaymentSelected,
  paymentSelected => ({
    isCheckMenuSelected: /check(-\d+)?/.test(paymentSelected),
    isGiftCardMenuSelected: /giftcard(-\d+)?/.test(paymentSelected),
  }),
);

export function getLastPayment(state) {
  const paymentsKeys = Object.keys(state.Payments.payments);
  const keysCount = paymentsKeys.length;
  const lastPayment =
    keysCount > 0 ? state.Payments.payments[paymentsKeys[keysCount - 1]] : {};
  return { lastPayment, lastKey: paymentsKeys[keysCount - 1] };
}

export const getIsPaymentWithCashOnly = createSelector(
  state => Object.values(getPayments(state)),
  payments => payments.length && payments.every(({ type }) => type === 'CASH'),
);

export const getIsPaymentWithCardOnly = createSelector(
  state => Object.values(getPayments(state)),
  payments => payments.length && payments.every(({ type }) => type === 'CARD'),
);

/*
 * Sale
 */
export function getSalesDocument(state) {
  return state.Payments.salesDocument;
}
export function getCurrentSalesDocument(state) {
  return state.Payments.currentSalesDocument;
}
export function getSalesDocumentReturnedPayments(state) {
  return getSalesDocument(state).returnedPayments;
}
export const getSalesDocumentAdvancePayment = createSelector(
  state => getSalesDocument(state),
  saleDocument => saleDocument?.advancePayment,
);
export function getUnroundedTotal(state) {
  return Number(state.Payments.total);
}
export const getRoundedTotal = createSelector(
  state => getUnroundedTotal(state),
  state => getPosRoundCashFunction(state),
  (unroundedTotal, round) => round(unroundedTotal),
);

export const getPaymentsTotal = createSelector(
  state => getUnroundedTotal(state),
  state => state.Payments.currency,
  (total, currency) => {
    return total / Number(currency?.rate ?? 1);
  },
);

export const getIsEligibleForCashRounding = createSelector(
  getPayments,
  getOriginalPayments,
  (payments, originalPayments) => {
    const paymentsArray = Object.values(payments);
    if (!paymentsArray.length) return false;

    const paidPaymentIds = paymentsArray
      .filter(payment => payment.type === 'PAID')
      .map(payment => String(payment.key).replace('CASH-', ''));
    const wasPaidWithCashBefore = originalPayments
      .filter(payment => payment.type === 'CASH')
      .some(originalPayment =>
        paidPaymentIds.includes(String(originalPayment.paymentID)),
      );

    return (
      wasPaidWithCashBefore ||
      paymentsArray.some(payment => payment.type === 'CASH')
    );
  },
);

export function getPaymentsCurrency(state) {
  const defaultCurrency = getDefaultCurrency(state);
  return (
    state.Payments.currency || getCurrencyByCode(defaultCurrency)(state) || {}
  );
}

export const getTip = createSelector(
  state => getPaymentsCurrency(state),
  state => Object.values(state.Payments.payments),
  ({ code, rate }, payments) => {
    // On picked up payments, the `type` is "PAID" while on new ones it's "TIP"
    return payments
      .filter(p => p.type === 'TIP' || p.caption === 'TIP')
      .map(pmt => {
        if (code !== pmt.currency) {
          const multiplier = Number(
            round((pmt.currencyRate ?? 1) / Number(rate ?? 1)),
          );

          return Math.abs(pmt.amount) * multiplier;
        }

        return Math.abs(pmt.amount);
      })
      .reduce(add, 0);
  },
);

export const getIsReturnPayment = createOverrideSelector(
  'getIsReturnPayment',
  createSelector(
    state => getTip(state),
    state => getPaymentsTotal(state),
    (tip, total) => tip + total < 0,
  ),
);

export const getPaymentsSum = createSelector(
  state => getPaymentsCurrency(state),
  state => Object.values(state.Payments.payments),
  ({ code, rate }, payments) => {
    return payments
      .map(pmt => {
        if (/* pmt.currencyRate && */ code !== pmt.currency) {
          const multiplier = Number(
            round((pmt.currencyRate ?? 1) / Number(rate ?? 1)),
          );

          return Number(pmt.amount) * multiplier;
        }

        return Number(pmt.amount);
      })
      .reduce(add, 0);
  },
);

export const getBalance = createSelector(
  state => getPaymentsSum(state),
  state => getPaymentsTotal(state),
  (sum, total) => {
    const diff = Number(sum - round(total, 2)) || 0;
    return Math.abs(diff) < 10 ** -4 ? 0 : diff;
  },
);

function isCashPayment(p) {
  return ['PAID', 'CASH'].includes(p.type);
}

/**
 * The change that would be given back to the customer if this was a full payment
 * NB: This *will* return negative amounts in case of underpayment, but
 * negative change should not *actually* be given out!
 * Instead, a negative change signifies that the customer has underpaid by a
 * larger amount than can be explained by cash rounding
 */
export const getChange = createSelector(
  state => getPosRoundCashFunction(state),
  state => getPosRoundCash(state),
  getBalance,
  getTotal,
  state => getPayments(state),
  (roundCash, roundCashConfig, balance, total, payments) => {
    const paymentsArray = Object.values(payments);
    const isPaidWithOnlyCash = paymentsArray.every(p => isCashPayment(p));
    const change = -roundCash(-balance, { type: 'change' });
    const totalLessThanHalfRounding = total < roundCashConfig / 2;

    if (
      Math.abs(change) > Math.abs(balance) &&
      isPaidWithOnlyCash &&
      totalLessThanHalfRounding
    ) {
      return change === 0
        ? Math.abs(change)
        : change - Math.sign(total) * roundCashConfig;
    }
    // Avoid negative 0. Formatting functions that use toLocaleString will preserve
    // the minus sign and "-0.00" is not something we want to show to the user
    return change === 0 ? Math.abs(change) : change;
  },
);

export const getRounding = createSelector(
  state => getChange(state),
  state => getBalance(state),
  (change, balance) => {
    return balance - change;
  },
);

export function getCustomer(state) {
  return state.Payments.customer;
}

/**
 * True if the current customer (either explicitly passed to openPaymentModal or passively selected in the cart)
 * is the default customer
 *
 * TODO: Better name?
 *       Includes 'Payment' to emphasize that it's a payment selector, and would not be appropriate
 *       to use outside of the payment context
 */
export const getPaymentIsDefaultCustomer = createSelector(
  getCustomer,
  getDefaultCustomer,
  (customer, defaultCustomer) =>
    Number(customer.id) === Number(defaultCustomer.id),
);

export const getCashPayment = createSelector(
  state => getPayments(state),
  state => getPaymentsCurrency(state),
  (payments, { code }) => payments[`${code}-cash`],
);
/*
 * External
 */
export function getEmployeeID(state) {
  return getLoggedInEmployeeID(state);
}

/*
 * Buttons
 */
function getIsPaymentDisabledBase(state) {
  return (type, serial) => {
    const paymentLimit = getPaymentLimits(state).find(
      limit =>
        limit.type === type && [undefined, !!limit.serial].includes(serial),
    );
    return paymentLimit && paymentLimit.amount < 0;
  };
}

export const getIsPaymentDisabled = createOverrideSelector(
  'getIsPaymentDisabled',
  getIsPaymentDisabledBase,
);

export function getIsSerialGiftcardDisabled(state) {
  const paymentLimit = getPaymentLimits(state).find(
    limit => limit.serial && limit.type === 'GIFTCARD',
  );

  return paymentLimit && paymentLimit.amount < 0;
}

export function getIsGiftcardDisabled(state) {
  const paymentLimit = getPaymentLimits(state).find(
    limit => !limit.serial && limit.type === 'GIFTCARD',
  );
  return paymentLimit && paymentLimit.amount < 0;
}

/*
 * DEBUG
 */
export function getPaymentsState(state) {
  return state;
}

/**
 * Generate unique payment key based on given type
 *
 * @param {string} - card payment type
 */

export function getNewPaymentKeyForType(type) {
  return createSelector(getPayments, state => {
    const usedNumbers = Object.keys(state)
      .filter(k => k.includes(type))
      .map(p => p.replace(type, ''))
      .map(Number)
      // eslint-disable-next-line no-restricted-globals
      .filter(n => !isNaN(n));
    const maxNumber = Math.max(0, ...usedNumbers);
    return `${type}${maxNumber + 1}`;
  });
}

export const getCurrentSalesDocPaymentsCombo = createSelector(
  state => getOriginalPayments(state),
  state => getReturnPayments(state),
  state => getPayments(state),
  (originalPayments, returnPayments, currentPayments) =>
    originalPayments.map(pmt => {
      // Handling for old payments that were saved before originalPaymentID attribute was introduced
      if (returnPayments.every(p => !Number(p.attributes?.originalPaymentID))) {
        const spentPreviouslyForThisCard = returnPayments
          .filter(p => p.cardNumber === pmt.cardNumber && p.type === pmt.type)
          .map(p => -Number(p.sum))
          .reduce(add, 0);
        const spentCurrentlyForThisCard = Object.values(currentPayments)
          .filter(p => p?.original?.paymentID === pmt.paymentID)
          .map(p => -Number(p.amount))
          .reduce(add, 0);

        const spent = Math.max(
          0,
          spentPreviouslyForThisCard + spentCurrentlyForThisCard,
        );
        return {
          ...pmt,
          spent,
          remaining: Math.min(pmt.sum - spent, Number(pmt.sum)),
        };
      }

      const spentPreviouslyForThisPayment = returnPayments
        .filter(p => p.attributes?.originalPaymentID === pmt.paymentID)
        .map(p => -Number(p.sum))
        .reduce(add, 0);
      const spentCurrentlyForThisPayment = Object.values(currentPayments)
        .filter(p => p.original?.paymentID === pmt.paymentID)
        .map(p => -Number(p.amount))
        .reduce(add, 0);

      const spent =
        spentPreviouslyForThisPayment + spentCurrentlyForThisPayment;

      return {
        ...pmt,
        spent,
        remaining: Math.min(pmt.sum - spent, Number(pmt.sum)),
      };
    }),
);
/** @deprecated unused */
export function getUnreturnedPaymentsTotal(state) {
  const pmts = getCurrentSalesDocPaymentsCombo(state);
  return pmts.map(p => p.remaining).reduce(add, 0);
}

const getPaymentsExceededTotalBase = createSelector(
  getPaymentsTotal,
  getBalance,
  (total, balance) => Math.sign(-balance) === -Math.sign(total),
);

export const getPaymentsExceededTotal = createOverrideSelector(
  'getPaymentsExceededTotal',
  getPaymentsExceededTotalBase,
);

export const getCashAmountToPay = createSelector(
  getPaymentsTotal,
  getPaymentsCurrency,
  getPayments,
  (total, { code: currency, rate }, paymentDict) => {
    const payments = Object.values(paymentDict);
    // Figure out the amount to pay, based on the total and sum of all other payments
    // (ignoring the current CASH payment because it will be overwritten)
    const otherPaymentsSum =
      payments
        .filter(
          p =>
            p.currency !== currency ||
            (p.type === 'CASH' && p.original?.paymentID) ||
            // Check for change helps the receipt not to get overpaid if Change is converted to Cash
            (p.type !== 'CASH' && p.type !== 'CHANGE'),
        )
        .map(p => Number(p.amount) * (p.currencyRate ?? 1))
        .reduce(R.add, 0) / (rate ?? 1);
    return total - otherPaymentsSum;
  },
);

const getCashPaymentsExceededTotalBase = createSelector(
  getPaymentsTotal,
  getCashAmountToPay,
  (total, toPay) => Math.sign(toPay) === -Math.sign(total),
);

export const getCashPaymentsExceededTotal = createOverrideSelector(
  'getCashPaymentsExceededTotal',
  getCashPaymentsExceededTotalBase,
);

export function getHasPaymentsOfType(paymentType) {
  return state => {
    const payments = Object.values(getPayments(state));
    if (payments.length === 0) return false;
    return payments.some(payment => payment.type === paymentType);
  };
}

/**
 * Generate a dictionary of paid amounts per type for original payments in a format {[payment.type]: sum}
 */
export const getOriginalPaymentTotals = createSelector(
  state => getOriginalPayments(state),
  originalPayments =>
    originalPayments.reduce((items, { type, sum }) => {
      if (items[type]) {
        return {
          ...items,
          [type]: Number(items[type]) + Number(sum),
        };
      }
      return {
        ...items,
        [type]: Number(sum),
      };
    }, {}),
);

export const getIsPartialPaymentAllowed = createSelector(
  getSalesDocument,
  getHasPayButtonBeenClicked,
  getSalesDocumentAdvancePayment,
  (salesDocument, payButtonClicked, outstandingAmount) => {
    // Orders, waybills and invwaybills can save with any payment amount (incl none)
    return (
      (['ORDER', 'WAYBILL'].includes(salesDocument.type) &&
        !payButtonClicked) ||
      (salesDocument.type === 'INVWAYBILL' &&
        (!outstandingAmount || Number(outstandingAmount) === 0)) ||
      salesDocument.type === 'INVOICE'
    );
  },
);
