import { RootState } from 'reducers';

import * as c from '../../constants/UI';

function enabled(
  state = false,
  { type, payload }: { type: string; payload: boolean }
) {
  switch (type) {
    case c.TOGGLE_MANUAL_VIEW_MODE:
      if (payload !== undefined) {
        return payload;
      }
      return !state;
    default:
      return state;
  }
}

export default enabled;

export function getIsManualViewMode(state: RootState) {
  return state.UI.manualViewMode;
}
