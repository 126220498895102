import React from 'react';
import i18next from 'i18next';
import { createSelector } from 'reselect';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { addError, addWarning } from 'actions/Error';
import {
  openCashDrawer,
  printGiftReceipt,
  printReceipt,
} from 'actions/integrations/printer';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { fetchOffers, saveAsOffer } from 'actions/offers';
import {
  fetchLayawaySalesAndOpenModal,
  fetchOrderSalesAndOpenModal,
  fetchPendingSalesAndOpenModal,
  fetchRecentSalesAndOpenModal,
  fetchUnpaidInvoicesAndOpenmodal,
  saveAsAccountSale,
  saveAsLayaway,
  saveAsOrder,
  saveAsWaybill,
  saveSale,
  startNewSale,
} from 'actions/sales';
import {
  getDayCountedByDrawer,
  getUISettings,
} from 'reducers/configs/settings';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import {
  getHasRightToApplyPromotions,
  getHasRightToCashInOut,
  getHasRightToOpenAndCloseDay,
  getHasRightToViewPromotions,
  getHasRightToViewZReport,
} from 'reducers/Login';
import {
  createOverrideSelector,
  getPluginSelectorValuesByName,
} from 'reducers/Plugins';
import { getCurrentSalesDocument, getLastSalesDocument } from 'reducers/sales';
import {
  getAppliedCouponCount,
  getAppliedPromotionCount,
  getHasAppliedDiscount,
  getHasCurrentSaleVatRate,
  getShoppingCartNeedsCalculation,
} from 'reducers/ShoppingCart';
import { isEmpty } from 'utils';
import { modalPages as mp } from 'constants/modalPage';
import { getCustomerRegistryUrl } from 'reducers/customerSearch';
import Icon from 'components/Icon';
import { fetchAvailableCoupons } from 'actions/CampaignsDB';
import { fetchClockedInEmployees } from 'actions/clockInOut';
import { RootState } from 'reducers';

export interface GridButtonSaleOption {
  trans?: string;
  id: string;
  name?: string;
  hotkey?: string;
  badge?: string | null;
  key?: string;
  actionType?: 'action' | 'page' | 'popup';
  code?: number;
  hide?: boolean;
  disabled?: boolean;
  icon?: string;
  action?: () => ThunkAction<unknown, RootState, never, Action>;
  view?: (props: Partial<Omit<GridButtonSaleOption, 'view'>>) => JSX.Element;
  modalProps?: Record<string, unknown>;
}
export interface GridButtonFunction
  extends Omit<GridButtonSaleOption, 'action'> {
  action?: ThunkAction<unknown, RootState, never, Action>;
}

const getSaleOptionButtonsBase = createSelector(
  getHasAppliedDiscount,
  getUISettings,
  getLastSalesDocument,
  getAppliedCouponCount,
  getAppliedPromotionCount,
  getHasCurrentSaleVatRate,
  getCurrentSalesDocument,
  getHasRightToApplyPromotions,
  getHasRightToViewPromotions,
  getShoppingCartNeedsCalculation,
  getConnectionHealth,
  getPluginSelectorValuesByName<{
    functions: GridButtonFunction[];
    saleOptions: GridButtonSaleOption[];
  }>('getCustomButtons'),
  (
    hasDiscount,
    uiSettings,
    { salesDoc: lastInvoice, payments: receiptPayments },
    couponCount,
    promotionCount,
    hasShipping,
    currentSalesDocument,
    hasRightToApplyPromotions,
    hasRightToViewPromotions,
    shoppingCartNeedsCalculation,
    isOnline,
    customButtons,
  ) => {
    const isOffline = !isOnline;
    const {
      notes,
      internalNotes,
      taxExemptCertificateNumber: taxExemptID,
    } = currentSalesDocument;
    const hasSaleNotes = notes || internalNotes;
    const hasTaxExempt = !!taxExemptID;

    return [
      {
        hotkey: 'F4',
        hide: uiSettings.hideNewSale,
        code: 24,
        action: () => async dispatch => dispatch(startNewSale()),
        id: 'newSale',
      },
      {
        hide: uiSettings.hideSaveSale,
        code: 27,
        action: () => async dispatch => dispatch(saveSale()),
        id: 'saveSale',
      },
      {
        hide: uiSettings.hideSaveOrder,
        code: 29,
        action: () => async dispatch => dispatch(saveAsOrder()),
        id: 'saveAsOrder',
      },
      {
        hide: uiSettings.hideSaveLayaway,
        disabled: isOffline,
        code: 28,
        action: () => async dispatch => dispatch(saveAsLayaway()),
        id: 'saveAsLayaway',
      },
      {
        hide: uiSettings.hideWaybills,
        disabled: isOffline,
        action: () => async dispatch => dispatch(saveAsWaybill()),
        id: 'saveAsWaybill',
      },
      {
        badge: hasDiscount ? 'SET' : null,
        hide: uiSettings.hideDiscount,
        disabled: isOffline,
        code: 23,
        action: () => async dispatch => {
          dispatch(openModalPage({ component: mp.SaleDiscount }));
        },
        icon: 'icon_percent',
        id: 'discount',
      },
      {
        code: 30,
        hide: uiSettings.hideTaxExcempt,
        badge: hasTaxExempt ? 'SET' : null,
        action: () => async dispatch => {
          dispatch(openModalPage({ component: mp.taxExempt }));
        },
        id: 'taxExempt',
      },
      {
        code: 26,
        icon: 'icon_bag_alt',
        badge: hasSaleNotes ? 'SET' : undefined,
        id: 'notes',
        hide: uiSettings.hideNotes,
        action: () => async dispatch => {
          dispatch(
            openModalPage({
              component: mp.saleNotes,
              modalClassName: 'saleNotes',
            }),
          );
        },
      },
      {
        code: 22,
        icon: 'erply_printer',
        id: 'lastReceipt',
        hide: uiSettings.hideLastReceiptPrint,
        disabled: isOffline,
        action: () => async dispatch => {
          if (!lastInvoice.receiptLink && isEmpty(receiptPayments)) {
            return dispatch(
              addWarning(i18next.t('gridButtons:alerts.noLastSale'), {
                selfDismiss: true,
              }),
            );
          }
          return dispatch(
            printReceipt({
              payments: receiptPayments,
              ...lastInvoice,
            }),
          );
        },
      },
      {
        code: 19,
        icon: 'icon_drawer_alt',
        id: 'cashDrawer',
        hide: uiSettings.hideOpenCashDrawer,
        action: () => async dispatch => dispatch(openCashDrawer()),
      },
      {
        code: 25,
        badge: promotionCount || null,
        hide: uiSettings.hidePromotions,
        id: 'promotions',
        disabled: isOffline,
        action: () => async dispatch => {
          if (!hasRightToApplyPromotions || !hasRightToViewPromotions) {
            return dispatch(
              addWarning(i18next.t('alerts:noApplyPromotionsRights'), {
                dismissible: false,
                selfDismiss: true,
              }),
            );
          }
          dispatch(openModalPage({ component: mp.salePromotions }));
        },
      },
      {
        badge: couponCount || null,
        code: 30,
        id: 'saleCommission',
        hide: uiSettings.hideSaleCommission || uiSettings.hideProductCommission,
        action: () => async dispatch => {
          dispatch(openModalPage({ component: mp.SaleCommission }));
        },
      },
      {
        badge: couponCount || null,
        code: 20,
        hide: uiSettings.hideCoupons,
        disabled: isOffline,
        action: () => async dispatch => {
          if (!hasRightToApplyPromotions) {
            return dispatch(
              addWarning(i18next.t('alerts:noApplyPromotionsRightsCoupons'), {
                dismissible: false,
                selfDismiss: true,
              }),
            );
          }
          dispatch(openModalPage({ component: mp.saleCoupons }));
        },
        id: 'coupons',
      },
      {
        id: 'giftReceipt',
        icon: 'icon_gift_alt',
        code: 21,
        hide: uiSettings.hidePrintGiftcardReceipt,
        action: () => async dispatch => {
          if (!lastInvoice.receiptLink && isEmpty(receiptPayments)) {
            return dispatch(
              addWarning(i18next.t('gridButtons:alerts.noLastSale'), {
                dismissible: false,
                selfDismiss: true,
              }),
            );
          }
          return dispatch(
            printGiftReceipt({
              payments: receiptPayments,
              ...lastInvoice,
            }),
          );
        },
      },
      {
        code: 18,
        id: 'addShipping',
        hide: uiSettings.hideAddShipping,
        disabled: isOffline,
        badge: hasShipping && 'SET',
        action: () => async dispatch => {
          dispatch(openModalPage({ component: mp.shipping }));
        },
      },
      {
        id: 'saveAsOffer',
        hide: uiSettings.hideSaveAsOffer,
        disabled: isOffline,
        action: () => async dispatch => {
          if (shoppingCartNeedsCalculation) {
            return dispatch(
              addWarning(
                i18next.t('gridButtons:alerts.calculateShoppingCart'),
                {
                  selfDismiss: true,
                  dismissible: true,
                },
              ),
            );
          }
          return dispatch(saveAsOffer());
        },
      },
      {
        hide: uiSettings.hideStockTransfer,
        id: 'stockTransfer',
        disabled: isOffline,
        action: () => async dispatch => {
          dispatch(openModalPage({ component: mp.stockTransfer }));
        },
      },
      {
        // works almost the same as layaway
        hide: uiSettings.hideAccountSales,
        id: 'accountSales',
        disabled: isOffline,
        action: () => async dispatch => {
          dispatch(saveAsAccountSale());
        },
      },
      ...customButtons.flatMap(a => a.saleOptions ?? []),
    ].filter(a => !a.hide) as GridButtonSaleOption[];
  },
);

export const getSaleOptionButtons = createOverrideSelector(
  'getSaleOptionButtons',
  getSaleOptionButtonsBase,
);

const getFunctionButtonsBase = createSelector(
  getUISettings,
  getHasRightToOpenAndCloseDay,
  getConnectionHealth,
  getCustomerRegistryUrl,
  getDayCountedByDrawer,
  getPluginSelectorValuesByName<{
    functions: GridButtonSaleOption[];
    saleOptions: GridButtonFunction[];
  }>('getCustomButtons'),
  getHasRightToCashInOut,
  getHasRightToViewZReport,
  (
    uiSettings,
    hasRightsToCloseDay,
    isOnline,
    hasCustomerRegistryUrl,
    dayCountedByDrawer,
    customButtons,
    hasRightToCashInOut,
    hasRightToViewZReport,
  ) => {
    const disabled = !isOnline;
    const {
      hideAddCustomer,
      hideRecentSales,
      hidePendingSales,
      hideOrders,
      hideLayaways,
      hideCloseDay,
      hideXReport,
      hideCashInOut,
      hidePriceLookup,
      hideClockInOut,
      hidePrintCoupons,
      hideGiftCardBalance,
      hideOffers,
      hideUnpaidInvoices,
    } = uiSettings;
    const closeDayTransKey = dayCountedByDrawer ? 'closeDrawer' : 'closeDay';

    return [
      {
        id: 'addCustomer',
        key: 'addCustomer',
        actionType: 'page',
        hide: hideAddCustomer,
        disabled,
        view: () => (
          <>
            <i className="no-margin icon_profile" style={{ fontSize: '2em' }} />
            <span
              className="icon_plus"
              style={{
                position: 'absolute',
                right: '4px',
                top: '2px',
                fontSize: '2em',
              }}
            />
          </>
        ),
        modalProps: {
          component: mp.createCustomer,
          props: { newCustomer: true },
          groupID: mp.createCustomer,
          replace: true,
        },
      },
      {
        id: 'completedSales',
        actionType: 'action',
        code: 41,
        hide: hideRecentSales,
        disabled,
        action: dispatch => {
          dispatch(fetchRecentSalesAndOpenModal());
        },
      },
      {
        id: 'pendingSales',
        code: 38,
        actionType: 'action',
        hide: hidePendingSales,
        disabled,
        action: dispatch => dispatch(fetchPendingSalesAndOpenModal()),
      },
      {
        id: 'advancedCustomerSearch',
        actionType: 'page',
        icon: 'icon_search',
        hide: !hasCustomerRegistryUrl,
        disabled,
        modalProps: {
          component: mp.advancedCustomerSearch,
          groupID: mp.advancedCustomerSearch,
          replace: true,
        },
      },
      {
        id: 'orderSales',
        actionType: 'action',
        hide: hideOrders,
        disabled,
        code: 39,
        action: dispatch => dispatch(fetchOrderSalesAndOpenModal()),
      },
      {
        id: 'layawaySales',
        actionType: 'action',
        code: 37,
        hide: hideLayaways,
        disabled,
        action: dispatch => {
          (document.activeElement as HTMLElement | null)?.blur();
          dispatch(fetchLayawaySalesAndOpenModal());
        },
      },
      {
        id: closeDayTransKey,
        actionType: 'action',
        hide: hideCloseDay,
        disabled,
        code: 36,
        action: dispatch => {
          if (!hasRightsToCloseDay) {
            dispatch(addError(i18next.t('alerts:noCloseDayRights')));
          } else {
            dispatch(
              openModalPage({
                component: mp.closeDay,
                isPopup: true,
                modalClassName: 'openCloseDay',
              }),
            );
          }
        },
      },
      {
        id: 'zReport',
        icon: 'icon_documents_alt',
        code: 43,
        hide: hideXReport,
        disabled,
        actionType: 'action',
        action: dispatch => {
          if (!hasRightToViewZReport) {
            dispatch(addError(i18next.t('alerts:noZReportViewRights')));
          } else {
            dispatch(
              openModalPage({
                component: mp.ZReport,
              }),
            );
          }
        },
      },
      {
        id: 'cashInOut',
        actionType: 'action',
        code: 32,
        hide: hideCashInOut && hasRightToCashInOut,
        disabled,
        action: dispatch => {
          if (!hasRightToCashInOut) {
            dispatch(addError(i18next.t('alerts:noCashInOutRights')));
          } else {
            dispatch(
              openModalPage({
                component: mp.cashInOut,
              }),
            );
          }
        },
      },
      {
        id: 'stockAndPrice',
        actionType: 'action',
        icon: 'icon_cart_alt',
        code: 1303,
        hide: hidePriceLookup,
        disabled,
        badge: (
          <Icon
            name="icon_search"
            style={{
              fontWeight: 'bold',
              margin: 0,
              display: 'inline-block' /* Required for transform */,
              transform: 'scaleX(-1)',
            }}
          />
        ),
        action: dispatch =>
          dispatch(
            openModalPage({
              component: mp.stockAndPriceList,
            }),
          ),
      },
      {
        id: 'clockInOut',
        actionType: 'action',
        code: 34,
        icon: 'icon_clock_alt',
        hide: hideClockInOut,
        disabled,
        action: dispatch => dispatch(fetchClockedInEmployees()),
      },
      {
        id: 'giftcard',
        actionType: 'action',
        code: `giftcard-${'giftcard'.length}`,
        hide: hideGiftCardBalance,
        disabled,
        action: dispatch => {
          (document.activeElement as HTMLElement | null)?.blur();
          dispatch(
            openModalPage({ component: mp.giftCardBalance, isPopup: true }),
          );
        },
      },
      {
        icon: 'icon_printer',
        id: 'coupons',
        code: 20,
        actionType: 'action',
        hide: hidePrintCoupons,
        disabled,
        action: dispatch => dispatch(fetchAvailableCoupons()),
      },
      {
        id: 'offers',
        code: `offers-${'offers'.length}`,
        actionType: 'action',
        hide: hideOffers,
        disabled,
        action: dispatch => dispatch(fetchOffers()),
      },
      {
        id: 'payAnInvoice',
        code: 'payAnInvoice-12',
        actionType: 'action',
        hide: hideUnpaidInvoices,
        disabled,
        action: dispatch => {
          (document.activeElement as HTMLElement | null)?.blur();
          dispatch(fetchUnpaidInvoicesAndOpenmodal());
        },
      },
      ...customButtons.flatMap(a => a.functions ?? []),
    ].filter(({ hide }) => !hide) as GridButtonFunction[];
  },
);

export const getFunctionButtons = createOverrideSelector(
  'getFunctionButtons',
  getFunctionButtonsBase,
);
