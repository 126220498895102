import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { modalPages as mp } from 'constants/modalPage';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import IconButton from 'components/IconButton';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { PluginComponent } from 'plugins';
import { useBreakpoints } from 'utils/hooks/UI';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import UIButton from 'components/UIElements/UIButton';

import './userBadge.scss';

export interface UserBadgeProps {
  /** Name of the customer - displayed in bold in the top row */
  customerName: string;
  /** The card number for this customer - displayed just below the customer name */
  customerCardNumber: string;
  /** The group of the customer - displayed below the customerCardNumber */
  customerGroup: string;
  /** Determines the default icon that will be displayed in the user badge */
  isCustomer: boolean;
  /** Determines the icon and functionality for user badge */
  isDefaultCustomer: boolean;
  /** Shows the amount of customer reward points on user badge */
  customerRewardPoints: number;
  /** Shows customer's total visits to store number */
  totalVisitsToStore: number;
  /** Shows the latest clock in time if any for a customer */
  loggedInEmployeeLastClockInTime?: string | null;
  /** Check if fetching employeee stats failed */
  isEmployeeError: boolean;
  /** Retry function to fetch employee data and other info */
  onRetryEmployeeData: () => void;
  /** Check if employee stats are being fetched */
  isLoadingEmployee: boolean;
}

/** Component that displays an icon an specific data next to it */
const UserBadge: React.FC<UserBadgeProps> = ({
  customerName,
  customerCardNumber,
  customerGroup,
  isCustomer = false,
  isDefaultCustomer,
  customerRewardPoints = 0,
  totalVisitsToStore = 0,
  loggedInEmployeeLastClockInTime,
  isEmployeeError,
  onRetryEmployeeData,
  isLoadingEmployee,
}) => {
  const dispatch = useDispatch();

  const searchCustomer = useCallback(
    () => dispatch(openModalPage({ component: mp.searchCustomers })),
    [dispatch],
  );

  const prefix = !isCustomer ? 'employee' : 'customer';

  const customer = useSelector(getSelectedCustomer);
  const fields = [
    { key: 'email', value: 'email' },
    { key: 'customerCardNumber', value: 'card' },
    { key: 'phone', value: 'phone' },
  ];
  const credits = [
    { key: 'store', value: 'availableCredit' },
    { key: 'reward', value: customerRewardPoints },
    { key: 'visits', value: totalVisitsToStore },
  ];

  const count = useMemo(
    () =>
      fields.filter(
        field =>
          customer[field.key] !== undefined && customer[field.key].length > 1,
      ).length,
    [customer, fields],
  );

  const mobileView = !useBreakpoints().mdplus;
  const connectionHealth = useSelector(getConnectionHealth);

  const showUserPage = () => {
    if (!isCustomer || !connectionHealth) return;
    const view = isDefaultCustomer ? mp.createCustomer : mp.customerView;
    dispatch(
      openModalPage({
        component: view,
        // @ts-ignore
        groupID: view,
        replace: true,
        props: {
          newCustomer: isDefaultCustomer,
        },
      }),
    );
  };

  useTranslation('customer'); // ensure customer namespace also loaded
  const { t } = useTranslation('employee');

  return (
    <PluginComponent
      hookname="UIUserBadge"
      props={{
        customerName,
        customerCardNumber,
        customerGroup,
        isCustomer,
        isDefaultCustomer,
        customerRewardPoints,
        totalVisitsToStore,
        loggedInEmployeeLastClockInTime,
        isEmployeeError,
        isLoadingEmployee,
        onRetryEmployeeData,
      }}
    >
      <Grid
        container
        className={`${mobileView ? 'p-0' : 'user-badge'}`}
        style={{
          cursor: isCustomer ? 'pointer' : 'initial',
          padding: '0 15px',
        }}
      >
        <Grid
          item
          xs={12}
          container
          className="user-badge-icon-wrapper"
          onClick={mobileView ? searchCustomer : showUserPage}
          data-testid={`${prefix}-badge-container`}
        >
          {mobileView && (
            <Grid item style={{ flexGrow: 1, paddingRight: '15px' }}>
              <IconButton
                variant={undefined}
                shortCut={t('customer:customers').toUpperCase()}
                action={searchCustomer}
                block
                className="searchCustomerBtn"
                useSearchSvg
              />
            </Grid>
          )}

          <Grid
            item
            xs="auto"
            id="user-icon"
            className="user-icon"
            onClick={showUserPage}
          >
            {isCustomer ? (
              <>
                <div className="user-badge-icon">
                  <span
                    className={`user-badge-icon-span ${
                      isDefaultCustomer ? 'icon_plus_alt' : 'icon_profile'
                    }`}
                    data-testid={`${prefix}-badge-icon-span`}
                    style={{
                      fontSize: mobileView ? '50px' : undefined,
                      cursor: !connectionHealth ? 'not-allowed' : '',
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="user-badge-icon">
                <span className="user-badge-icon-span icon_profile" />
              </div>
            )}
          </Grid>

          {!mobileView && (
            <Grid
              item
              className="user-badge-data-wrapper"
              data-testid={`"display-${prefix}-view"`}
            >
              <Grid
                item
                container
                className="user-badge-name"
                data-testid={`${prefix}-name`}
              >
                {customerName || t('customer:noSelectedCustomer')}
              </Grid>
              {!isCustomer && loggedInEmployeeLastClockInTime ? (
                <Grid item container>
                  <span
                    data-testid="employee-clock-in-time"
                    className="employee-clock-in-time"
                  >
                    {t('clockInTime', {
                      time: loggedInEmployeeLastClockInTime,
                    })}
                  </span>
                </Grid>
              ) : null}
              {!isDefaultCustomer && isCustomer && (
                <>
                  <Grid item container>
                    <Grid item data-testid={`${prefix}-card-number`}>
                      {customerCardNumber}
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid
                      item
                      style={{
                        fontWeight: 700,
                        marginTop: '2px',
                        fontSize: '16px',
                      }}
                      data-testid={`${prefix}-group`}
                    >
                      {customerGroup}
                    </Grid>
                  </Grid>
                </>
              )}
              {isEmployeeError && (
                <UIButton
                  text={t('retry')}
                  block={false}
                  className="client-details-button"
                  action={onRetryEmployeeData}
                  disabled={isLoadingEmployee}
                />
              )}
            </Grid>
          )}
        </Grid>
        {mobileView && (
          <>
            <Grid item container style={{ marginBottom: '8px' }}>
              <Grid item className="user-badge-data-wrapper pl-0">
                <Grid item container className="user-badge-name">
                  {customerName || t('customer:noSelectedCustomer')}
                </Grid>
                {!isCustomer && loggedInEmployeeLastClockInTime ? (
                  <Grid item container>
                    <span className="employee-clock-in-time">
                      {t('clockInTime', {
                        time: loggedInEmployeeLastClockInTime,
                      })}
                    </span>
                  </Grid>
                ) : null}
                {!isDefaultCustomer && isCustomer && (
                  <>
                    <Grid item container>
                      <Grid item>{customerCardNumber}</Grid>
                    </Grid>
                    <Grid item container>
                      <Grid
                        item
                        style={{
                          fontWeight: 700,
                          marginTop: '2px',
                          fontSize: '16px',
                        }}
                      >
                        {customerGroup}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              container
              className="justify-content-between"
              spacing={1}
              // Reverse direction makes items on the left wrap first
              direction="row-reverse"
              wrap="wrap-reverse"
            >
              {count > 0 && (
                <Grid
                  item
                  xs={5}
                  className="pl-0"
                  style={{
                    flexGrow: 5,
                    order: 3, // Compensation for reverse direction
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {fields.map(field =>
                    customer[field.key] ? (
                      <div
                        key={field.key}
                        className="d-flex justify-content-between"
                      >
                        <strong>{field.value.toUpperCase()}</strong>
                        <p>
                          {customer[field.key].slice(
                            0,
                            customer[field.key].indexOf('@'),
                          )}
                        </p>
                      </div>
                    ) : null,
                  )}
                </Grid>
              )}
              <Grid
                item
                xs={3}
                className="pl-0"
                style={{
                  flexGrow: 3,
                  order: 2, // Compensation for reverse direction
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {credits.map(credit =>
                  customer[credit.value] || credit ? (
                    <div
                      key={credit.key}
                      className="d-flex justify-content-between"
                    >
                      <strong>
                        {t(`customer:card.${credit.key}`).toUpperCase()}
                      </strong>
                      <p className="text-left">
                        {customer[credit.value] !== undefined
                          ? customer[credit.value]
                          : credit.value}
                      </p>
                    </div>
                  ) : null,
                )}
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  flexGrow: 3,
                  order: 1, // Compensation for reverse direction
                }}
              >
                <IconButton
                  block
                  style={{ height: '42px' }}
                  action={showUserPage}
                  icons={['erply_edit_2']}
                  variant="warning"
                  disabled={isDefaultCustomer}
                />
                <IconButton
                  block
                  style={{ height: '42px' }}
                  action={searchCustomer}
                  icons={['erply_eye']}
                  variant="light"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </PluginComponent>
  );
};

export default UserBadge;
