import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { removeProduct, updateOrderAmount } from 'actions/ShoppingCart';
import { getSelectedWarehouse } from 'reducers/warehouses';

import { IS_AN_ADMISSION } from './rdx/actionTypes';

const singlePrompt = (
  params: {
    admissionProduct?: any;
    productsInCartLength?: number;
    isSecondAttempt?: boolean;
    displaySelectedWaivers?: boolean;
    invoiceNumber?: string;
    apiKey?: string;
  } = {
      isSecondAttempt: false,
    },
) => async (dispatch, getState) => {
  const state = getState();

  const currentWarehouse = getSelectedWarehouse(state).code;

  return new Promise((resolve, reject) =>
    dispatch(
      openModalPage({
        component: mp.pluginModal,
        props: {
          name: 'EWaiverModal',
          resolve,
          reject,
          currentWarehouse,
          invoiceNumber: params.invoiceNumber,
          apiKey: params.apiKey,
          isSecondAttempt: params.isSecondAttempt,
          displaySelectedWaivers: params.displaySelectedWaivers,
          closeModal: () => {
            dispatch(previousModalPage());
          },
          onCancel: () => {
            if (params.admissionProduct) {
              const { orderIndex, amount } = params.admissionProduct;

              if (amount === 1) {
                dispatch(removeProduct(params.admissionProduct.orderIndex));
              }

              if (amount > 1) {
                dispatch(updateOrderAmount(Number(amount) - 1, orderIndex));
              }
            }

            if (params.productsInCartLength === 1) {
              dispatch({ type: IS_AN_ADMISSION, payload: false });
            }

            dispatch(previousModalPage());
          },
        },
        isPopup: true,
      }),
    ),
  );
};

export const buildWaiverAttributes = (waivers: Record<string, any>[]) => {

  const waiverIdsToStore = {};

  const selectedWaiversIDs = waivers.map(waiver => waiver.waiver_id)

  selectedWaiversIDs.forEach((waiverID, index) => {
    let currentIndex: number = index + 1;

    if (currentIndex > 2) {
      currentIndex = index + 2;
    }

    waiverIdsToStore[`attributeName${currentIndex}`] = `waiverID-${currentIndex > 3 ? currentIndex - 1 : currentIndex
      }`;
    waiverIdsToStore[`attributeType${currentIndex}`] = 'text';
    waiverIdsToStore[`attributeValue${currentIndex}`] = waiverID;
  });

  return waiverIdsToStore;
}

export default singlePrompt;
