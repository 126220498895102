import { messageTypes } from 'plugins/mm/constants';

import { baseLog } from '../../constants';

import { payForOrder, printOrderReceipt } from './actions';

interface PayForOrderMessage {
  type: typeof messageTypes.PAY_FOR_WEBSHOP_ORDER;
  payload: {
    invoiceId: number;
  };
}

interface PrintReceiptMessage {
  type: typeof messageTypes.PRINT_RECEIPT;
  payload: {
    invoiceId: number;
  };
}

const log = baseLog.extend('handleMessage');

export const handleMessage = (messageEvent: MessageEvent) => async dispatch => {
  const message = messageEvent.data;
  log('Received message: ', message);
  if (!message) return;

  switch (message.type) {
    case messageTypes.PAY_FOR_WEBSHOP_ORDER: {
      const typedMessage = message as PayForOrderMessage;
      await dispatch(payForOrder(typedMessage.payload.invoiceId));
      break;
    }
    case messageTypes.PRINT_RECEIPT: {
      const typedMessage = message as PrintReceiptMessage;
      await dispatch(printOrderReceipt(typedMessage.payload.invoiceId));
      break;
    }
    default:
      break;
  }
};
