import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getModalPopups } from 'reducers/modalPage';
import UIButton from 'components/UIElements/UIButton';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import SearchComponent from 'containers/Col1/Search/components/SearchComponent';

import reduxReducer, { getIsFocusAllowed, setAllowFocus } from './redux';
import { pluginID } from './constants';

type Configuration = {
  showAutomaticResults?: boolean;
};

const allowAutoFocus = () => async dispatch => {
  dispatch(setAllowFocus(true));
};

const customerSearchAutoFocus: PosPlugin<Configuration> = {
  id: pluginID,
  name: 'Customer search autofocus',
  info:
    'This plugin will autofocus customer search on initial load and after new sale',
  keywords: ['customer search', 'customer', 'search', 'autofocus'],
  // @ts-ignore
  reduxReducer,
  ComponentConfiguration: ({ current, save }) => {
    const [showAutomaticResults, toggleAutoclick] = useState(
      current?.showAutomaticResults ?? true,
    );

    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              checked={showAutomaticResults}
              onChange={() => toggleAutoclick(prev => !prev)}
              color="primary"
            />
          }
          label="Display customer results table automatically"
        />
        <UIButton
          variant="POS"
          type="button"
          text="Save"
          action={() =>
            save({
              showAutomaticResults,
            })
          }
        />
      </>
    );
  },
  UICustomerSearch: ({ children, ...props }) => {
    const shoppingCart = useSelector(getProductsInShoppingCart);
    const ref = useRef<HTMLInputElement | null>(null);
    const isCartEmpty = shoppingCart.length === 0;
    const focusAllowed = useSelector(getIsFocusAllowed);

    const modals = useSelector(getModalPopups);
    const isConfirmationOpened = modals.some(
      ({ component }) => component === 'PaymentConfirmation',
    );

    const { activeElement } = document;
    const inputNotFocused = activeElement?.tagName !== 'INPUT';

    const conditionsFulfilled =
      isCartEmpty && inputNotFocused && !isConfirmationOpened;

    const dispatch = useDispatch();

    useEffect(() => {
      if (conditionsFulfilled && focusAllowed) {
        ref?.current?.focus();
        ref?.current?.click();
        dispatch(setAllowFocus(false));
      }
    }, [ref, conditionsFulfilled, focusAllowed, dispatch]);

    return (
      <SearchComponent
        {...props}
        ref={ref}
        testId="customer-search-input"
        placeholder="customers"
      />
    );
  },
  UICustomerResults: ({ searchValue, children }) => {
    const { showAutomaticResults = true } = useSelector(
      getPluginConfiguration<Configuration>('customer-search-autofocus'),
    ) ?? {};

    return showAutomaticResults || searchValue.length ? children : null;
  },
  onSaveSalesDocument: {
    after: allowAutoFocus,
  },
  onResetShoppingCart: {
    after: allowAutoFocus,
  },
  onRemoveOrder: {
    after: () => async (dispatch, getState) => {
      const shoppingCart = getProductsInShoppingCart(getState());
      const isDefaultCustomer = getIsDefaultCustomer(getState());
      if (shoppingCart.length === 0 && isDefaultCustomer) {
        dispatch(setAllowFocus(true));
      }
    },
  },
};

export default customerSearchAutoFocus;
