import axios from 'axios';

import { Printer } from 'types/PrintingMS';
import { getMSEndpointFromLS } from 'reducers/installer';

const defaultBaseUrl = `https://localhost.erply.com:5000`;

function getBaseUrl(): string {
  const baseUrl = getMSEndpointFromLS('goMS');
  return baseUrl || defaultBaseUrl;
}

export async function getPrinterList(full?: boolean): Promise<Printer[]> {
  const baseUrl = getBaseUrl();
  try {
    const response = await axios.get(`${baseUrl}/getprinterlist`, {
      params: { full },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}
