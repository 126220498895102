import { Box, styled, TextField, Typography } from '@material-ui/core';
import React from 'react';

import { ProductName } from '../../utils/ProductUtils';
import { WarehouseName } from '../../utils/WarehouseName';

const Grid2DTable = styled(Box)({
  display: 'grid',
  alignItems: 'stretch',
  justifyItems: 'stretch',
});
const QtyInput = ({
  stock,
  value,
  error,
  onChange,
}: {
  stock: number | null;
  value: string;
  error: string | undefined;
  onChange: (v: string) => void;
}) => {
  return (
    <TextField
      value={value}
      onChange={e =>
        onChange(e.target.value.replace(/,/, '.').replace(/[^\d.]/, ''))
      }
      variant="outlined"
      fullWidth
      InputProps={{
        endAdornment: stock !== null && <Typography>/{stock}</Typography>,
      }}
      error={!!error}
      helperText={error ?? ' '}
    />
  );
};
/**
 * A full table-sized input for managing the special order state
 * Has the selected warehouses as columns, and the required products as rows,
 * and each table cell is an input to determine how many of that product
 * to order from that warehouse.
 *
 * Each cell shows the available stock at that warehouse, and an error if it is exceeded
 * Each product header (left) will show the quantity necessary, and an error if it is not met
 */
export const TransferOrderSpreadsheet = ({
  salesDocument,
  specialOrderState: {
    warehouses: { value: warehouses },
    getValue,
    setValue,
    getStock,
    error,
    totals,
  },
}) => {
  return (
    <Grid2DTable margin={2} gridGap={8}>
      {/* Warehouse headers along the top edge */}
      {warehouses.map((warehouseID, whIndex) => (
        <Box
          gridColumn={whIndex + 2}
          gridRow={1}
          key={`warehouse ${warehouseID}`}
          alignSelf="end"
          justifySelf="center"
        >
          <WarehouseName id={warehouseID} />
        </Box>
      ))}

      {/* Product headers along the left edge */}
      {salesDocument.rows.map(
        ({ stableRowID, productID, amount }, rowIndex) => (
          <Box
            gridColumn={1}
            gridRow={rowIndex + 2}
            key={`row ${stableRowID}}`}
            alignSelf="center"
            justifySelf="end"
            textAlign="end"
          >
            {totals[stableRowID]}/{amount}x{' '}
            <ProductName id={Number(productID)} />
            <Typography color="error" style={{ minHeight: '1lh' }}>
              {error?.[stableRowID]?.total ?? ''}
            </Typography>
          </Box>
        ),
      )}

      {/* Values in the middle */}
      {salesDocument.rows.flatMap(({ stableRowID, productID }, rowIndex) =>
        warehouses.map((warehouseID, whIndex) => (
          <Box
            gridColumn={whIndex + 2}
            gridRow={rowIndex + 2}
            key={`input for row ${stableRowID} for warehouse ${warehouseID}`}
          >
            <QtyInput
              stock={getStock(warehouseID, productID)}
              error={error?.[stableRowID]?.[warehouseID]}
              value={getValue(warehouseID, stableRowID)}
              onChange={v => setValue(warehouseID, stableRowID, v)}
            />
          </Box>
        )),
      )}
    </Grid2DTable>
  );
};
