import { createSelector } from 'reselect';

import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getPluginConfiguration } from 'reducers/Plugins';
import { ShoppingCart } from 'types/ShoppingCart';

import {
  Configuration,
  ReduxViiPayment,
  ReduxViiProduct,
  ViiGiftCard,
} from '../types';
import { pluginID } from '../constants';

import {
  ADD_VII_GIFT_CARD_PRODUCT_TO_CACHE,
  REMOVE_VII_GIFT_CARD_PRODUCT_FROM_CACHE,
  ENTER_PAYMENT_DATA,
  ENTER_PRODUCT_DATA,
  REMOVE_VII_PAYMENT,
  REMOVE_VII_PRODUCT,
  RESET_PLUGIN_STATE,
} from './types';

export interface ViiState {
  products: {
    [id: number]: ReduxViiProduct;
  };
  payments: {
    [id: string]: ReduxViiPayment;
  };
  viiProduct: null | ViiGiftCard;
}

export const initState: ViiState = {
  payments: {},
  products: {},
  viiProduct: null,
};

export default (state = initState, { type, ...rest }): {} => {
  switch (type) {
    case ENTER_PAYMENT_DATA:
      return {
        ...state,
        payments: {
          ...state.payments,
          [rest.payload.id]: {
            ...state.payments[rest.payload.id],
            ...rest.payload.body,
          },
        },
      };
    case REMOVE_VII_PAYMENT:
      // eslint-disable-next-line no-case-declarations
      const newPaymentState = state;
      delete newPaymentState.payments[rest.payload.id];
      return newPaymentState;
    case ENTER_PRODUCT_DATA:
      return {
        ...state,
        products: {
          ...state.products,
          [rest.payload.id]: {
            ...state.products[rest.payload.id],
            ...rest.payload.body,
          },
        },
      };
    case RESET_PLUGIN_STATE:
      return {
        ...initState,
        // Keep the ViiProduct in state
        viiProduct: state.viiProduct,
      };
    case ADD_VII_GIFT_CARD_PRODUCT_TO_CACHE:
      return {
        ...state,
        viiProduct: rest.payload,
      };
    case REMOVE_VII_GIFT_CARD_PRODUCT_FROM_CACHE:
      return {
        ...state,
        viiProduct: null,
      };
    case REMOVE_VII_PRODUCT:
      // eslint-disable-next-line no-case-declarations
      const newProductState = state;
      delete newProductState.products[rest.payload.id];
      return newProductState;
    default:
      return state;
  }
};

export const getHasViiProductInCart = createSelector(
  state => getProductsInShoppingCart(state),
  state => getPluginConfiguration<Configuration>(pluginID)(state),
  (cart: ShoppingCart, config) => {
    if (!config) return false;
    return cart.some(p => p.code === config.giftCardCode);
  },
);
