type Boolish = boolean | 1 | 0;
/*
 * Basic printer commands
 */
export const INIT = () => 'INIT';
export const CUT = () => 'CUT 2';
export const BOLD = (isBold: Boolish = true) => `BOLD ${isBold ? 1 : 0}`;
export const UNDERLINE = (isUnderlined: Boolish = true) =>
  `UNDERLINE ${isUnderlined ? 1 : 0} 1`;
export const ALIGNRIGHT = (isAlignRight: Boolish = true) =>
  `ALIGN ${isAlignRight ? 'right' : 'left'}`;
export const PRINT = text => `PRINT ${`${text}`.replace(/\n/g, '')}`;
export const LF = () => 'LF';
export const HT = () => 'HT';
export const HTPOSITION = (productColumn, b) =>
  `HTPOSITION ${productColumn} ${b}`;
export const SIZE = n => `CHARSIZE ${n}`;
/*
 * Utility combinations
 */
export const LN = LF;
