import React, { useEffect, useState, useCallback } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import { getCustomers } from 'services/ErplyAPI';
import { Customer } from 'types/Customer';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  tableContainer: {
    maxHeight: '340px',
  },
});

const ContactsTable: React.FC<{ customerID: number }> = ({ customerID }) => {
  const classes = useStyles();
  const { t } = useTranslation('customer');

  const [contacts, setContacts] = useState<
    (Customer & { contactPersonID: number })[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchContacts = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getCustomers({
        customerID,
        getContactPersons: 1,
      });
      const contactPersons = R.sortBy(
        R.prop('lastName'),
        response[0]?.contactPersons ?? [],
      );
      setContacts(contactPersons);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [customerID]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const getMessage = () => {
    if (loading) return <CircularProgress />;
    if (error) return t('customerView.contacts.error');
    return t('customerView.contacts.none');
  };

  return !contacts.length ? (
    <Typography align="center" className={classes.container}>
      {getMessage()}
    </Typography>
  ) : (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader aria-label="contacts-table">
        <TableHead>
          <TableRow>
            <TableCell>
              {t('customerView.contacts.headings.lastName')}
            </TableCell>
            <TableCell>
              {t('customerView.contacts.headings.firstName')}
            </TableCell>
            <TableCell>{t('customerView.contacts.headings.phone')}</TableCell>
            <TableCell>{t('customerView.contacts.headings.email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map(contact => (
            <TableRow key={contact.contactPersonID}>
              <TableCell>{contact.lastName}</TableCell>
              <TableCell>{contact.firstName}</TableCell>
              <TableCell>{contact.phone}</TableCell>
              <TableCell>{contact.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContactsTable;
