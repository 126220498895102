import * as R from 'ramda';
import { createSelector } from 'reselect';

import { combineLifecycleHooks } from 'plugins/pluginUtils';
import { PosPlugin } from 'plugins/plugin';
import { generateCustomReceipt } from 'plugins/mm/features/receipt';

import documentation from './documentation.md';
import { modals, pluginID, ALLOWED_DOCUMENT_TYPES } from './constants';
import { getCustomTranslations } from './translations';
import { Config, Configuration, getConfiguration } from './configuration';
import reducer from './rdx';
import {
  clearPreviousCustomerId,
  savePreviousCustomerIdToLocalStorage,
} from './features/previousCustomer';
import {
  AddressSearch,
  WithAddressSearchButton,
} from './features/customerForm/addressSearch';
import { registerGeneratedCardNumber } from './features/customerForm/customerCardNumber';
import {
  assocSeniorStatus,
  addSeniorTagToCustomer,
} from './features/customerForm/seniorStatus';
import UICustomerFormBeta from './features/customerForm/UICustomerFormBeta';
import { FilteredMenu } from './features/userMenu';
import { getFunctionButtons } from './features/functionButtons';
import { getCustomerTableFields } from './features/customerTableFields';
import { LoadCoupons } from './features/coupons';
import {
  assignCardTypesToGiftCardPayments,
  enforceVoucherOwnership,
} from './features/giftCards';
import {
  authenticateOrderNotifications,
  OrderNotifications,
  WithOrderNotificationsIcon,
  deleteLinkedLayaway,
  reopenOrderNotifications,
  hydrateOnlineOrderState,
  updateOnlineOrderStatus,
} from './features/orderNotifications';
import { setReturnPaymentForGivexCardDeactivation } from './features/givex';
import { openPirFormIfReturn, PirForm } from './features/pir';
import { getSaleOptionButtons } from './features/unlockPromotionsWithoutPermissions/getSaleOptions';
import {
  EmailValidation,
  changeCustomerGroupToMatchEmail,
} from './features/customerForm/email';
import {
  generateSaveInventoryWriteOffRequests,
  resetInventoryWriteOffRequests,
  sendInventoryWriteOffRequests,
} from './features/inventoryWriteOff';
import {
  WithoutCustomerColumn,
  WithOnlyAllAllowed,
  WithoutReasonColumn,
  WithoutPerItemTab,
} from './features/productReturn';
import {
  forgetStoredGivexRegillPrintouts,
  rememberRefillPayments,
  printStoredGivexRefillPrintouts,
} from './features/receipt/generateGivexRefillPrintout';
import { getReasonCodesDb } from './features/hideReturnReasons';
import {
  WithDisabledDebitPayments,
  addCashPaymentAsDebitReturn,
  clearCachedDebitReturnPaymentAmount,
  removePrefilledDebitReturnPayments,
} from './features/payments';
import { applyDefaultSuspendedValue } from './features/customerForm/suspendedField';
import { useDefaultNumberingForDeliveredOrder } from './features/orderNotifications/overrides';
import { applyDefaultLanguage } from './features/customerForm/languageField';

const mmPlugin: PosPlugin<Config> = {
  id: pluginID,
  name: 'M&M customizations',
  keywords: ['mm', 'm&m'],
  infoMDUrl: documentation,
  getStatus: state => {
    const config = getConfiguration(state);
    const errorMessages: string[] = [];

    if (!config?.couponServiceTokenSalt)
      errorMessages.push('Coupon service token salt is missing');

    const missingAuthenticationServiceFields = [
      !config?.authenticationService?.username && 'username',
      !config?.authenticationService?.password && 'password',
    ].filter(Boolean);
    if (missingAuthenticationServiceFields.length)
      errorMessages.push(
        `Authentication service ${missingAuthenticationServiceFields.join(
          ' and ',
        )} ${
          missingAuthenticationServiceFields.length > 1 ? 'are' : 'is'
        } missing`,
      );

    if (errorMessages.length)
      return { type: 'error', message: errorMessages.join('. ') };
    return { type: 'valid', message: 'Ready' };
  },
  ComponentConfigurationByLevel: {
    Company: Configuration,
  },
  combineConfiguration: c =>
    R.mergeDeepLeft(c)({
      isProduction: false,
      couponServiceTokenSalt: '',
      emailServiceToken: '',
      authenticationService: {
        username: '',
        password: '',
      },
    }),

  reduxReducer: reducer,

  onMount: () => async dispatch => {
    dispatch(authenticateOrderNotifications());
    dispatch(hydrateOnlineOrderState());
  },
  onOpenPaymentModal: combineLifecycleHooks(
    {
      before: forgetStoredGivexRegillPrintouts,
      on: setReturnPaymentForGivexCardDeactivation,
    },
    {
      before: clearCachedDebitReturnPaymentAmount,
      on: removePrefilledDebitReturnPayments,
      after: addCashPaymentAsDebitReturn,
    },
  ),
  onSaveSalesDocument: combineLifecycleHooks(
    {
      on: assignCardTypesToGiftCardPayments,
      after: savePreviousCustomerIdToLocalStorage,
    },
    {
      on: generateSaveInventoryWriteOffRequests,
      after: sendInventoryWriteOffRequests,
    },
    { on: rememberRefillPayments },
    { on: useDefaultNumberingForDeliveredOrder },
    { after: openPirFormIfReturn },
    { after: reopenOrderNotifications },
    { after: updateOnlineOrderStatus },
    { after: deleteLinkedLayaway },
  ),
  onPrintReceipt: {
    after: printStoredGivexRefillPrintouts,
  },
  onClosePayments: {
    after: resetInventoryWriteOffRequests,
  },
  onCloseDay: {
    after: clearPreviousCustomerId,
  },
  selectorOverrides: {
    getFunctionButtons,
    getSaleOptionButtons,
    getCustomerTableFields,
    getReasonCodesDb,
    getSettings: base =>
      createSelector(
        base,
        R.assoc('allowed_types_on_return', ALLOWED_DOCUMENT_TYPES),
      ),
  },

  UIAddressFormBeta: WithAddressSearchButton,

  UIUserMenuDropDownMenu: FilteredMenu,

  UISalesDocumentSelection: WithoutCustomerColumn,

  // region Product return modal
  UISetBulkReturnReasons: WithOnlyAllAllowed,
  UIReturnTableHeaders: WithoutReasonColumn,
  UIReturnOrderProductSelectReason: () => null,
  UIProductReturnReasonReturnTypesBody: WithoutPerItemTab,
  // endregion

  components: {
    [modals.ADDRESS_SEARCH]: AddressSearch,
    [modals.LOAD_COUPONS]: LoadCoupons,
    [modals.ORDER_NOTIFICATIONS]: OrderNotifications,
    [modals.PIR_FORM]: PirForm,
    [modals.EMAIL_VALIDATION]: EmailValidation,
  },

  getTranslationOverrides: getCustomTranslations,
  onSaveCustomer: combineLifecycleHooks(
    {
      after: registerGeneratedCardNumber,
    },
    { after: addSeniorTagToCustomer },
    { on: applyDefaultSuspendedValue },
    { on: applyDefaultLanguage },
  ),
  onSetCustomer: combineLifecycleHooks(
    {
      on: assocSeniorStatus,
    },
    { after: changeCustomerGroupToMatchEmail },
  ),
  onAddSerialGiftCardPayment: {
    on: enforceVoucherOwnership,
  },
  onGenerateReceipt: {
    before: generateCustomReceipt,
  },
  UICustomerFormBeta,
  ComponentHeader: WithOrderNotificationsIcon,
  UIOriginalPayment: WithDisabledDebitPayments,
};

export default mmPlugin;
