export const SET_ZIP_CAPTURE_PRODUCT_GROUPS =
  'PLUGIN: SET_ZIP_CAPTURE_PRODUCT_GROUP';
export const SET_ZIP_CODE = 'PLUGIN:SET_ZIP_CODE';
export const REMOVE_ZIP_CODE = 'PLUGIN:REMOVE_ZIP_CODE';

export interface Attribute {
  attirbuteName: string;
  attributeType: string;
  attributeValue: string;
}
export interface ProductGroup {
  added: number;
  attributes: Attribute[];
  id: string;
  lastModified: number;
  name: string;
  nonDiscountable: number;
  parentGroupID: string;
  positionNo: number;
  productGroupID: number;
  showInWebshop: string;
  subGroups: string[];
}

export interface SetZipCaptureProductGroupsAction {
  type: typeof SET_ZIP_CAPTURE_PRODUCT_GROUPS;
  payload?: ProductGroup[];
}

interface SetZip {
  type: typeof SET_ZIP_CODE;
  payload: string;
}

interface RemoveZip {
  type: typeof REMOVE_ZIP_CODE;
  payload?: any;
}

export type ZipActionTypes =
  | SetZipCaptureProductGroupsAction
  | SetZip
  | RemoveZip;
