import { createSelector } from 'reselect';
import * as R from 'ramda';
import { v4 } from 'uuid';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { openPluginModalPage } from 'actions/modalPage';
import { getTaxFreeVatRate } from 'reducers/vatRatesDB';

import { RootState } from '../../reducers/index';

import { TaxFreeModal, TaxReturnModal, UITableTotalRow } from './components';
import ComponentConfiguration from './Configuration';
import documentation from './documentation.md';
import reduxReducer, { getTaxReturnDocument } from './redux';
import { pluginID, Configuration } from './constants';

const taxFreeForm: PosPlugin<Configuration> = {
  id: pluginID,
  name: 'Tax Free Form',
  ComponentConfiguration,
  keywords: ['min', 'tax', 'tax free'],
  infoMDUrl: documentation,
  reduxReducer,
  getStatus: state => {
    const conf = getPluginConfiguration<Configuration>(pluginID)(state);
    if (!conf?.minPurchaseAmount) {
      return {
        message: 'Please add min. purchase amount',
        type: 'error',
      };
    }
    return {
      message: 'Ready',
      type: 'valid',
    };
  },
  selectorOverrides: {
    getFunctionButtons: base =>
      createSelector(
        base,
        state => getPluginConfiguration<Configuration>(pluginID)(state),
        (baseButtons, conf) =>
          !conf.minPurchaseAmount
            ? baseButtons
            : [
                ...baseButtons,
                {
                  id: v4(),
                  name: 'Tax Free',
                  actionType: 'action',
                  action: openPluginModalPage('TaxFreeModal')({}),
                },
              ],
      ),
    getSaleOptionButtons: base =>
      createSelector(
        base,
        state => getPluginConfiguration<Configuration>(pluginID)(state),
        (baseButtons, conf) =>
          !conf.minPurchaseAmount
            ? baseButtons
            : [
                ...baseButtons,
                {
                  id: v4(),
                  name: 'Tax Return',
                  action: () => (
                    dispatch: ThunkDispatch<RootState, unknown, Action>,
                  ) => {
                    dispatch(openPluginModalPage('TaxReturnModal')({}));
                  },
                },
              ],
      ),
  },
  components: {
    TaxReturnModal,
    TaxFreeModal,
  },
  UITableTotalRow,
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      const taxReturnDocument = getTaxReturnDocument(getState());
      return {
        ...ap,
        total: taxReturnDocument ? -taxReturnDocument.vatTotal : ap.total,
      };
    },
  },
  onSaveSalesDocument: {
    on: (p, ap) => async (dispatch, getState) => {
      const taxReturnDocument = getTaxReturnDocument(getState());
      if (!taxReturnDocument) return ap;
      const taxFreeVatRate = getTaxFreeVatRate(getState());
      const addTaxFreeVatRateToRows = R.mapObjIndexed((value, key) =>
        key.match(/vatrateID\d+/) ? taxFreeVatRate?.id : value,
      );

      return R.map(
        R.when(
          R.propEq('requestName', 'saveSalesDocument'),
          R.pipe(
            R.omit(['creditInvoiceType', 'type', 'creditToDocumentID']),
            R.assoc('id', taxReturnDocument.id),
            addTaxFreeVatRateToRows,
          ),
        ),
      )(ap);
    },
  },
};

export default taxFreeForm;
