import { useEffect, useState } from "react";

export const useTrackResize = getter => {
    const [value, setValue] = useState(0);
  
    useEffect(() => {
      const handler = () => setValue(getter());
      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
    }, []);
    return value;
  };