import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { getDefaultCustomer } from 'reducers/customerSearch';
import { getCanEditCustomers } from 'reducers/Login';
import { getSetting } from 'reducers/configs/settings';
import { getIsCardBoardingEnabled } from 'reducers/cafaConfigs';

const Icons = ({ customer }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('customer');
  const isCardBoardingEnabled = useSelector(getIsCardBoardingEnabled);

  const storeCreditShouldBeHidden = useSelector(
    getSetting('pos_hide_add_credit_store_from_customer_view'),
  );

  const defaultCustomer = useSelector(getDefaultCustomer);
  const isDefaultCustomer = customer.id === defaultCustomer.id;
  const editCustomerRights = useSelector(getCanEditCustomers);
  const icons = [
    {
      name: 'icon_currency',
      title: t('customerView.buttons.addCredit'),
      id: 'add-credit',
      hidden: storeCreditShouldBeHidden,
      action: () => {
        dispatch(
          openModalPage({
            component: mp.storeCredit,
            props: { customer },
          }),
        );
      },
    },
    {
      name: 'icon_pencil-edit',
      title: t('customerView.buttons.editProfile'),
      id: 'edit-customer',
      hidden: Number(editCustomerRights) === 0,
      action: () => {
        dispatch(
          openModalPage({
            component: mp.createCustomer,
            props: { customer },
          }),
        );
      },
    },
  ];

  const displayIcons = () =>
    icons.map(icon => {
      const style = icon.hidden
        ? { display: 'none' }
        : { paddingLeft: '12px', paddingRight: '12px' };
      return (
        <Icon
          style={style}
          key={icon.name}
          name={icon.name}
          title={icon.title}
          action={icon.action}
          data-testid={icon.id}
        />
      );
    });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {isCardBoardingEnabled && (
        <Icon
          style={{ paddingLeft: '12px', paddingRight: '12px' }}
          key="icon_creditcard"
          name="icon_creditcard"
          title={t('mountCardsModal.mountCardsIcon')}
          action={() => {
            dispatch(
              openModalPage({
                // @ts-ignore
                isPopup: false,
                component: mp.MountUnmountCard,
                props: {
                  name: 'MountUnmountCard',
                  props: customer,
                },
              }),
            );
          }}
          data-testid="mount-cards"
        />
      )}
      {!isDefaultCustomer ? displayIcons() : null}
      <CloseButton action={() => dispatch(closeModalPage())} />
    </div>
  );
};

export default Icons;
