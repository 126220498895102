import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';

import { PosPlugin } from 'plugins/plugin';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import InputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';

type Title = {
  id: number;
  name: string;
  order: number;
  added: number;
  lastModified: number;
};

const inputClass = classNames([styles.formInput, styles.longTitle]);

const tsteCustomizations: PosPlugin = {
  id: 'tste-customizations',
  name: 'TSTE - Customizations',
  info: 'This plugin adds "Title" filed to the Customer creation form',
  keywords: ['TSTE', 'form', 'customer'],
  getStatus: () => ({ type: 'valid', message: 'Ready' }),
  UICustomerForm: ({ value, children, onChange }) => {
    const [titleOptions, setTitleOptions] = useState<Title[]>([]);
    useEffect(() => {
      doClientRequest<Title>({
        request: 'getPersonTitles',
      }).then(setTitleOptions);
    }, []);

    const handleChange = e => {
      const { value, name } = e.target;
      onChange(c => ({ ...c, [name]: value }));
    };

    return (
      <>
        {value.customerType === 'PERSON' && (
          <Form.Group>
            <InputField
              size="lg"
              className={inputClass}
              onChange={handleChange}
              title="Title"
              value={value.personTitleID}
              name="personTitleID"
              type="select"
              options={[{ value: '', name: 'Select option' }].concat(
                titleOptions.map(({ id, name }) => ({
                  value: id.toString(),
                  name,
                })),
              )}
              data-testid="personTitleID"
            />
          </Form.Group>
        )}
        {children}
      </>
    );
  },
};

export default tsteCustomizations;
