import { getProductsUniversal } from 'actions/productsDB';
import {
  getChannelName,
  getCustomerDisplayPluginConfiguration,
} from 'plugins/customerDisplay';
import { PosPlugin } from 'plugins/plugin';
import { getTotalCountInCart } from 'reducers/ShoppingCart';
import {
  getCurrencyFormatter,
  getSetting,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { publish } from 'services/CentrifugoWS';
import { Product } from 'types/Product';

function getImageFormat(url: string) {
  const match = url.match(/\.([a-z]+)$/);
  return match?.[1]?.toUpperCase() ?? '';
}

function formatProduct(
  product: Product,
  getPrice: (product: Product) => string,
) {
  const productImage = product.images?.find(
    image => !image.external, // CDN images cause CORS error on customer display
  );
  const media =
    !!productImage && !!productImage?.largeURL
      ? {
          type: 'IMAGE',
          src: productImage.largeURL as string,
          format: getImageFormat(productImage.largeURL),
        }
      : { type: 'TEXT', format: 'TEXT' };

  return {
    id: String(product.productID),
    name: product.name,
    description: product.description,
    price: getPrice(product),
    ...media,
  };
}

export const sendUpsellProductsToDisplay: Required<
  PosPlugin
>['onAddProduct']['on'] = (_p, ap) => async (dispatch, getState) => {
  const shouldDisplayRelatedProducts = !getSetting(
    'touchpos_disable_related_products_popup',
  )(getState());

  if (
    !shouldDisplayRelatedProducts ||
    !ap.selectedProduct.relatedProducts?.length
  ) {
    return ap;
  }

  const config = getCustomerDisplayPluginConfiguration(getState());
  const channelName = getChannelName(getState());
  const isWithTax = getShowPricesWithTax(getState());
  const formatCurrency = getCurrencyFormatter(getState());

  const relatedProductsIDs = ap.selectedProduct.relatedProducts.map(Number);
  const { products: relatedProducts } = await dispatch(
    getProductsUniversal({
      productIDs: relatedProductsIDs,
      getLocalStockInfo: 1
    }),
  );

  function getProductPrice(product: Product) {
    return formatCurrency(
      isWithTax ? product.priceListPriceWithVat : product.priceListPrice,
    );
  }

  function checkIfShouldShowProduct(product: Product) {
    if (!config.showUpsellProductsWithStocksOnly) return true;
    if (product.nonStockProduct) return true;
    const amountInCart = getTotalCountInCart(product.productID)(getState());
    return Number(product.free ?? 0) - amountInCart > 0;
  }

  const upsellProducts = relatedProducts
    .filter(checkIfShouldShowProduct)
    .map(product => formatProduct(product, getProductPrice));

  if (!upsellProducts.length) return ap;

  publish({
    channel: `customer_display:${channelName}`,
    message: {
      action: 'SET_UPSELL_PRODUCTS',
      products: upsellProducts,
    },
  });

  return ap;
};
