export const USDL_CONST = {
  ID: 'USDL_age_verification',
  NAME: 'USDL Age Verification',
  KEYWORDS: ['age', 'verification', 'usdl'],
  INFO:
    'Age verification functionality using United States Driving License to verify the age of the customer and controls the sale of pre configured products.',
};

export type USDLProductConfig = {
  minAge: number;
  maxAmount: number;
};

export interface USDLPluginConfiguration {
  products: Record<number, USDLProductConfig>;
  productGroups: Record<number, USDLProductConfig>;
}

// Not an actual type, used as a symbol so JSDoc can link to this explanation
/**
 * ## CardType
 * What types of records contain this field, can be either DL (Driver's license), ZV (jurisdiction-specific data), or Both
 *
 * ## Length
 * The length and valid characters for this field, consists of three parts: <type><length><charset>
 *   * <type>: F for fixed length fields, V for variable length fields
 *   * <length>: The length as a number
 *   * <charset>: The set of allowed characters for this field: A:[A-Z], N=[0-9], S=special characters,
 *
 * @example
 * Length: V6ANS - Variable-length field of length 6, that supports all three character types (alphanumberic and special)
 * Length: F8N - Fixed-length field of 8 numbers
 */
type HowToReadUsdlMetadata = never;

export interface USDLBarcodeData {
  /** @deprecated Field not found in documentation but referenced in code, maybe a typo? */
  DCT: string;
  /**
   * Jurisdiction-specific vehicle class
   *
   * Jurisdiction-specific vehicle class / group code, designating the type of vehicle the cardholder has privilege to drive.
   *
   * @CardType DL
   * @Length V6ANS
   *
   * @see {HowToReadUsdlMetadata}
   */
  DCA: string;
  /**
   * Jurisdiction-specific restriction codes
   *
   * Jurisdiction-specific codes that represent restrictions to driving privileges (such as airbrakes, automatic transmission, daylight only, etc.)
   *
   * @CardType DL
   * @Length V12ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCB: string;
  /**
   * Jurisdiction-specific endorsement codes
   *
   * Jurisdiction-specific codes that represent additional privileges granted to the cardholder beyond the vehicle class
   * (such as transportation of passengers, hazardous materials, operation of motorcycles, etc.)
   *
   * @CardType DL
   * @Length V5ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCD: string;
  /**
   * Document expiration date
   *
   * Date on which the driving and identification privileges granted by the document are no longer valid
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType Both
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DBA: string;
  /**
   * Customer family name
   *
   * Family name of the cardholder (Family name is sometimes also called "last name" or "surname").
   * Collect full name for record, print as many characters as possible on portait side of DL/ID
   *
   * @CardType Both
   * @Length V40ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCS: string;
  /**
   * Customer first name
   *
   * First name of the cardholder
   *
   * @CardType Both
   * @Length V40ANS
   * @see {HowToReadUsdlMetadata}
   */
  DAC: string;
  /**
   * Customer middle name(s)
   *
   * Middle name(s) of the cardholder. In the case of multiple middle names they shall be separated by a comma ","
   *
   * @CardType Both
   * @Length V40ANS
   * @see {HowToReadUsdlMetadata}
   */
  DAD: string;
  /**
   * Document issue date
   *
   * Date on which the document was issued.
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType Both
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DBD: string;
  /**
   * Date of birth
   *
   * Date on which the cardholder was born.
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType Both
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DBB: string;
  /**
   * Physical description - Sex
   *
   * Gender of the cardholder. 1 = male, 2 = female, 9 = not specified.
   *
   * @CardType Both
   * @Length F1N
   * @see {HowToReadUsdlMetadata}
   */
  DBC: '1' | '2' | '9';
  /**
   * Physical description - Eye color
   *
   * Color of cardholder's eyes. (ANSI D-20 codes)
   *
   * @CardType Both
   * @Length F3A
   * @see {HowToReadUsdlMetadata}
   */
  DAY:
    | 'BLK'
    | 'BLU'
    | 'BRO'
    | 'DIC'
    | 'GRY'
    | 'GRN'
    | 'HAZ'
    | 'MAR'
    | 'PNK'
    | 'UNK';
  /**
   * Physical description - Height
   *
   * Height of cardholder
   *
   * Inches (in): number of inches followed by " in"
   * @example 6'1'' = "073 in"
   * Centimeres (cm): number of centimiters folowed by " cm"
   * @example 181 centimeters="181 CM"
   *
   * @CardType Both
   * @Length F6ANS
   * @see {HowToReadUsdlMetadata}
   *
   * @deprecated When splitting by spaces, the unit from this field will be lost.
   *   Check the parsing logic before using this field, remove this deprecation notice if the parsing is fixed to maintain the unit
   */
  DAU: string;
  /**
   * Address - Street 1
   *
   * Street portion of the cardholder address.
   *
   * @CardType Both
   * @Length V35ANS
   * @see {HowToReadUsdlMetadata}
   */
  DAG: string;
  /**
   * Address - City
   *
   * City portion of the cardholder address.
   *
   * @CardType Both
   * @Length V20ANS
   * @see {HowToReadUsdlMetadata}
   */
  DAI: string;
  /**
   * Address - Jurisdiction code
   *
   * State portion of the cardholder address.
   *
   * @CardType Both
   * @Length F2A
   * @see {HowToReadUsdlMetadata}
   */
  DAJ: string;
  /**
   * Address - Postal code
   *
   * Postal code of the cardholder address in the U.S. and Canada.
   * If the trailing portion of the postal ode in the U.S. is not known, zeros will be used to fill the trailing set of numbers up to nine(9) digits
   *
   * @CardType Both
   * @Length F11ANS
   * @see {HowToReadUsdlMetadata}
   */
  DAK: string;
  /**
   * Customer ID number
   *
   * The number assigned or calculated by the issuing authority
   *
   * @CardType Both
   * @Length V25ANS
   * @see {HowToReadUsdlMetadata}
   */
  DAQ: string;
  /**
   * Document discriminator
   *
   * Number must uniquely identify a particular document issued to that customer from others that may have been issued in the past.
   * This number may serve multiple purposes of document discrimination, audit information number, and/or inventory control.
   *
   * @CardType Both
   * @Length V25ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCF: string;
  /**
   * Country identification
   *
   * Country in which DL/ID is issued.
   *
   * @CardType Both
   * @Lendth F3A
   * @see {HowToReadUsdlMetadata}
   */
  DCG: 'USA' | 'CAN';
  /**
   * Family name truncation
   * T - Truncated, N - Not truncated, U - Unknown whether truncated
   *
   * @CardType Both
   * @Length F1A
   * @See {HowToReadUsdlMetadata}
   */
  DDE: 'T' | 'N' | 'U';
  /**
   * First name truncation
   * T - Truncated, N - Not truncated, U - Unknown whether truncated
   *
   * @CardType Both
   * @Length F1A
   * @See {HowToReadUsdlMetadata}
   */
  DDF: 'T' | 'N' | 'U';
  /**
   * Middle name truncation
   * T - Truncated, N - Not truncated, U - Unknown whether truncated
   *
   * @CardType Both
   * @Length F1A
   * @See {HowToReadUsdlMetadata}
   */
  DDG: 'T' | 'N' | 'U';

  // Optional data elements
  /**
   * Address - Street 2
   *
   * Second line of street portion of the cardholder address
   * @CardType Both
   * @Length V35ANS
   * @see {HowToReadUsdlMetadata}
   */
  DAH?: string;
  /**
   * Hair color
   *
   * Bald, black, blonde, brown, gray, red/auburn, sandy, white, unknown.
   * If the issuing jurisdiction wishes to abbreviate colors, the three-character codes provided in AAMVA D20 must be used
   */
  DAZ?: // prettier-ignore
    | "bald" | "black" | "blonde" | "brown" | "gray" | "red/auburn" | "sandy" | "white" | "unknown"
    | "BAL"  | "BLK"   | "BLN"    | "BRO"   | "GRY"  | "RED"        | "SDY"   | "WHI"   | "UNK";
  /**
   * Place of birth
   *
   * Country and municipality and/or state/province
   *
   * @CardType Both
   * @Length V33A
   * @see {HowToReadUsdlMetadata}
   */
  DCI?: string;
  /**
   * Audit information
   *
   * A string of letters and/or numbers that identifies when, where, and by whom a driver license/ID card was made.
   * If audit information is not used on the card or the MRT, it must be included in the driver record.
   *
   * @CardType Both
   * @Length V25ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCJ?: string;
  /**
   * Inventory control number
   *
   * A string of letters and/or numbers that is affixed to the raw materials (card stock, laminate, etc.)
   * used in producing driver licenses and ID cards. (HDS recommended field)
   *
   * @CardType Both
   * @Length V25ANS
   */
  DCK?: string;
  /**
   * Alias / AKA Family Name
   *
   * Other family name by which cardholder is known
   *
   * @CardType Both
   * @Length V10ANS
   * @see {HowToReadUsdlMetadata}
   */
  DBN?: string;
  /**
   * Alias / AKA Given Name
   *
   * Other given name by which cardholder is known
   *
   * @CardType Both
   * @Length V15ANS
   * @see {HowToReadUsdlMetadata}
   */
  DBG?: string;
  /**
   * Alias / AKA Suffix name
   *
   * Other suffix by which cardholder is known
   *
   * @CardType Both
   * @Length V5ANS
   * @see {HowToReadUsdlMetadata}
   */
  DBS?: string;
  /**
   * Name suffix
   *
   * Name suffix
   * (if jurisdiction participates in systems requiring name suffix (PDPS, CDLIS, etc.),
   * the suffix must be collected and displayed on the DL/ID and in the MRT).
   * Collect full name for record, print as many characters as possible on portrait side of DL/ID
   * * JR (Junior)
   * * SR (Senior)
   * * 1ST or I (First)
   * * 2ND or II (Second)
   * * 3RD or III (Third)
   * * 4TH or IV (Fourth)
   * * 5TH or V (Fifth)
   * * 6TH or VI (Sixth)
   * * 7TH or VII (Seventh)
   * * 8TH or VII (Eighth)
   * * 9TH or IX (Ninth)
   *
   * @CardType Both
   * @Length V5ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCU?: string;
  /**
   * Physical description - Weight range
   *
   * Indicates the approximate weight range of the cardholder.
   * 0 = up to 31 kg (up to 70 lbs)
   * 1 = 32 – 45 kg (71 – 100 lbs)
   * 2 = 46 - 59 kg (101 – 130 lbs)
   * 3 = 60 - 70 kg (131 – 160 lbs)
   * 4 = 71 - 86 kg (161 – 190 lbs)
   * 5 = 87 - 100 kg (191 – 220 lbs)
   * 6 = 101 - 113 kg (221 – 250 lbs)
   * 7 = 114 - 127 kg (251 – 280 lbs)
   * 8 = 128 – 145 kg (281 – 320 lbs)
   * 9 = 146+ kg (321+ lbs)
   *
   * @CardType Both
   * @Length F1N
   * @see {HowToReadUsdlMetadata
   */
  DCE?: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  /**
   * Race / Ethnicity
   *
   * Codes for race or ethnicity of the cardholder, as defined in AAMVA D20
   *
   * @CardType Both
   * @Length V3A
   * @see {HowToReadUsdlMetadata}
   */
  DCL?: 'AI' | 'AP' | 'BK' | 'H' | 'O' | 'U' | 'W';
  /**
   * Standard vehicle classification
   *
   * Standard vehicle classification code(s) for cardholder.
   * This data element is a placeholder for future efforts to standardize vehicle classifications.
   *
   * @deprecated
   * @CardType DL
   * @Length F4AN
   * @see {HowToReadUsdlMetadata}
   */
  DCM?: string;
  /**
   * Standard endorsement code
   *
   * Standard endorsement code(s) for cardholder.
   * See codes in D20. This data element is a placeholder for future efforts to standardize endorsement codes
   *
   * @deprecated
   * @CardType DL
   * @Length F5AN
   * @see {HowToReadUsdlMetadata}
   */
  DCN?: string;
  /**
   * Standard restriction code
   *
   * Standard restriction code(s) for cardholder.
   * See codes in D20. This data element is a placeholder for future efforts to standardize restriction codes
   *
   * @deprecated
   * @CardType DL
   * @Length F12AN
   * @see {HowToReadUsdlMetadata}
   */
  DCO?: string;
  /**
   * Jurisdiction-specific vehicle classification description
   *
   * Text that explains the jurisdiction-specific code(s) for classifications of vehicles cardholder is authorized to drive.
   *
   * @CardType DL
   * @Length V50ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCP?: string;
  /**
   * Jurisdiction-specific endorsement code description
   *
   * Text that explains the jurisdiction-specific code(s) that indicates additional driving privileges granted to the cardholder beyond the vehicle class
   *
   * @CardType DL
   * @Length V50ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCQ?: string;
  /**
   * Jurisdiction-specific restriction code description
   *
   * Text describing the jurisdiction-specific restriction code(s) that curtail driving privileges.
   *
   * @CardType DL
   * @Length V50ANS
   * @see {HowToReadUsdlMetadata}
   */
  DCR?: string;
  /**
   * Compliance type
   *
   * DHS required field that indicates compliance:
   * @example
   * "F" // compliant
   * "N" // non-compliant
   *
   * @CardType Both
   * @Length F1A
   * @see {HowToReadUsdlMetadata}
   */
  DDA?: 'F' | 'N';
  /**
   * Card revision date
   *
   * DHS required field that indicates date of the most recent **version** change or modification to the visible format of the DL/ID.
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType Both
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DDB?: string;
  /**
   * HAZMAT endorsement expiration date
   *
   * Date on which the hazardous material endorsement granted by the document is no longer valid.
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType DL
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DDC?: string;
  /**
   * Limited duration document indicator
   *
   * DHS required field that indicates that the cardholder has temporary lawful status = "1"
   *
   * @CardType Both
   * @Length F1N
   * @see {HowToReadUsdlMetadata}
   */
  DDD?: string;
  /**
   * Weight (pounds)
   *
   * Cardholder weight in pounds
   * @example
   * "185" // 185 lb
   *
   * @CardType Both
   * @Length F3N
   * @see {HowToReadUsdlMetadata}
   */
  DAW?: string;
  /**
   * Weight (kilograms)
   *
   * Cardholder weight in kilograms
   * @example
   * "084" // 84 kg
   *
   * @CardType Both
   * @Length F3N
   * @see {HowToReadUsdlMetadata}
   */
  DAX?: string;
  /**
   * Under 18 until
   *
   * Date on which the cardholder turns 18 years old.
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType Both
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DDH?: string;
  /**
   * Under 19 until
   *
   * Date on which the cardholder turns 19 years old
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType Both
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DDI?: string;
  /**
   * Under 21 until
   *
   * Date on which the cardholder turns 21 years old
   * (MMDDCCYY for U.S., CCYYMMDD for Canada)
   *
   * @CardType Both
   * @Length F8N
   * @see {HowToReadUsdlMetadata}
   */
  DDJ?: string;
  /**
   * Organ donor indicator
   *
   * Field that indicates that the cardholder is an organ donor = "1"
   *
   * @CardType Both
   * @Length F1N
   * @see {HowToReadUsdlMetadata}
   */
  DDK?: string;
  /**
   * Veteran indicator
   *
   * Field that indicates that the cardholder is a veteran = "1"
   *
   * @CardType Both
   * @Length F1N
   * @see {HowToReadUsdlMetadata}
   */
  DDL?: string;
}

// prettier-ignore
export interface USDLMappedData {
  /** @see {USDLBarcodeData.DCA} */ jurisdictionVehicleClass: USDLBarcodeData['DCA'],
  /** @see {USDLBarcodeData.DCB} */ jurisdictionRestrictionCodes: USDLBarcodeData['DCB'],
  /** @see {USDLBarcodeData.DCD} */ jurisdictionEndorsementCodes: USDLBarcodeData['DCD'],
  /** @see {USDLBarcodeData.DBA} */ dateOfExpiry: USDLBarcodeData['DBA'],
  /** @see {USDLBarcodeData.DCS} */ lastName: USDLBarcodeData['DCS'],
  // Where is DCT? I can't find it in the documentation
  /** @see {USDLBarcodeData.DCT} */ firstNameAndMiddleName: USDLBarcodeData['DCT'],
  /** @see {USDLBarcodeData.DAC} */ firstName: USDLBarcodeData['DAC'],
  /** @see {USDLBarcodeData.DAD} */ middleName: USDLBarcodeData['DAD'],
  /** @see {USDLBarcodeData.DBD} */ dateOfIssue: USDLBarcodeData['DBD'],
  /** @see {USDLBarcodeData.DBB} */ dateOfBirth: USDLBarcodeData['DBB'],
  /** @see {USDLBarcodeData.DBC} */ sex: USDLBarcodeData['DBC'],
  /** @see {USDLBarcodeData.DAY} */ eyeColor: USDLBarcodeData['DAY'],
  /** @see {USDLBarcodeData.DAU} */ height: USDLBarcodeData['DAU'],
  /** @see {USDLBarcodeData.DAG} */ addressStreet: USDLBarcodeData['DAG'],
  /** @see {USDLBarcodeData.DAI} */ addressCity: USDLBarcodeData['DAI'],
  /** @see {USDLBarcodeData.DAJ} */ addressState: USDLBarcodeData['DAJ'],
  /** @see {USDLBarcodeData.DAK} */ addressPostalCode: USDLBarcodeData['DAK'],
  /** @see {USDLBarcodeData.DAQ} */ documentNumber: USDLBarcodeData['DAQ'],
  /** @see {USDLBarcodeData.DCF} */ documentDiscriminator: USDLBarcodeData['DCF'],
  /** @see {USDLBarcodeData.DCG} */ issuer: USDLBarcodeData['DCG'],
  /** @see {USDLBarcodeData.DDE} */ lastNameTruncated: USDLBarcodeData['DDE'],
  /** @see {USDLBarcodeData.DDF} */ firstNameTruncated: USDLBarcodeData['DDF'],
  /** @see {USDLBarcodeData.DDG} */ middleNameTruncated: USDLBarcodeData['DDG'],
  // optional
  /** @see {USDLBarcodeData.DAZ} */ hairColor: USDLBarcodeData['DAZ'],
  /** @see {USDLBarcodeData.DAH} */ addressStreet2: USDLBarcodeData['DAH'],
  /** @see {USDLBarcodeData.DCI} */ placeOfBirth: USDLBarcodeData['DCI'],
  /** @see {USDLBarcodeData.DCJ} */ auditInformation: USDLBarcodeData['DCJ'],
  /** @see {USDLBarcodeData.DCK} */ inventoryControlNumber: USDLBarcodeData['DCK'],
  /** @see {USDLBarcodeData.DBN} */ otherLastName: USDLBarcodeData['DBN'],
  /** @see {USDLBarcodeData.DBG} */ otherFirstName: USDLBarcodeData['DBG'],
  /** @see {USDLBarcodeData.DBS} */ otherSuffixName: USDLBarcodeData['DBS'],
  /** @see {USDLBarcodeData.DCU} */ nameSuffix: USDLBarcodeData['DCU'],
  /** @see {USDLBarcodeData.DCE} */ weightRange: USDLBarcodeData['DCE'],
  /** @see {USDLBarcodeData.DCL} */ race: USDLBarcodeData['DCL'],
  /** @see {USDLBarcodeData.DCM} */ standardVehicleClassification: USDLBarcodeData['DCM'],
  /** @see {USDLBarcodeData.DCN} */ standardEndorsementCode: USDLBarcodeData['DCN'],
  /** @see {USDLBarcodeData.DCO} */ standardRestrictionCode: USDLBarcodeData['DCO'],
  /** @see {USDLBarcodeData.DCP} */ jurisdictionVehicleClassificationDescription: USDLBarcodeData['DCP'],
  /** @see {USDLBarcodeData.DCQ} */ jurisdictionEndorsementCodeDescription: USDLBarcodeData['DCQ'],
  /** @see {USDLBarcodeData.DCR} */ jurisdictionRestrictionCodeDescription: USDLBarcodeData['DCR'],
  /** @see {USDLBarcodeData.DDA} */ complianceType: USDLBarcodeData['DDA'],
  /** @see {USDLBarcodeData.DDB} */ dateCardRevised: USDLBarcodeData['DDB'],
  /** @see {USDLBarcodeData.DDC} */ dateOfExpiryHazmatEndorsement: USDLBarcodeData['DDC'],
  /** @see {USDLBarcodeData.DDD} */ limitedDurationDocumentIndicator: USDLBarcodeData['DDD'],
  /** @see {USDLBarcodeData.DAW} */ weightLb: USDLBarcodeData['DAW'],
  /** @see {USDLBarcodeData.DAX} */ weightKg: USDLBarcodeData['DAX'],
  /** @see {USDLBarcodeData.DDH} */ dateAge18: USDLBarcodeData['DDH'],
  /** @see {USDLBarcodeData.DDI} */ dateAge19: USDLBarcodeData['DDI'],
  /** @see {USDLBarcodeData.DDJ} */ dateAge21: USDLBarcodeData['DDJ'],
  /** @see {USDLBarcodeData.DDK} */ organDonor: USDLBarcodeData['DDK'],
  /** @see {USDLBarcodeData.DDL} */ veteran: USDLBarcodeData['DDL'],
}
