import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import SmartTable from 'components/Tables';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { useLocalStorage } from 'utils/hooks/statelike';

import options from './filterOptions';
import './ProductsFilter.sass';

const { Header, Body, Title } = Modal;

// TODO: REFACTOR:
// - use indexedDB
// - pass products down to table - currently table has hooks to get products - dummy components should not hooks directly to redux state
// - use indexedDB cursors and indexes to query the products

const ProductsFilter = () => {
  const dispatch = useDispatch();
  // replace with products from indexedDB
  const allProducts = [];

  // replace with custom hook useProducts
  const [products, setProducts] = useState(allProducts);
  // const [itemsToDisplay, setItems] = useState([]);
  const [order, setOrder] = useLocalStorage('products-filter: columnOrder', []);
  const [columns, setColumns] = useLocalStorage('products-filter: columns', [
    'add',
    'name',
    'code',
    'price',
  ]);

  const toggleColumn = name =>
    setColumns(columns =>
      columns.some(col => col === name)
        ? columns.filter(col => col !== name)
        : [...columns, name],
    );

  const close = () => {
    dispatch(closeModalPage());
  };

  return (
    <>
      <Header>
        <Title>
          Products Filter - <em>Currently not available</em>
        </Title>
        <CloseButton action={close} />
      </Header>
      <Body>
        <SmartTable
          // responsive
          columns={columns}
          toggleColumn={toggleColumn}
          order={order}
          setOrder={setOrder}
          fields={options}
          data={products}
          options={options}
          withOptions
          // variant="dark"
          // defaultfields={}
          draggable
          pagination
          setItems={setProducts}
        />
      </Body>
    </>
  );
};

export default ProductsFilter;
