import {
  getLastProduct,
  getProductInOrderByIndex,
} from 'reducers/ShoppingCart';
import { addWarning } from 'actions/Error';

const noEditSerialQty = {
  onUpdateOrderAmount: {
    on: (p, ap) => async (dispatch, getState) => {
      const state = getState();
      const order = getProductInOrderByIndex(p.orderIndex)(state);
      if (order.serialNumber?.length) {
        dispatch(
          addWarning('Cannot edit product amount when it has a serial number'),
        );
        return {
          ...ap,
          amount: order.amount,
        };
      }
      return ap;
    },
  },
  onAddProduct: {
    on: (p, ap) => async (dispatch, getState) => {
      const lastProduct = getLastProduct(getState());
      if (ap.product.serialNumber?.length || lastProduct.serialNumber?.length)
        return { ...ap, options: { ...ap.options, shouldMerge: false } };
      return ap;
    },
  },
};

export default noEditSerialQty;
