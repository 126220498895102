import { createSelector } from 'reselect';

import {
  TYPE_SELECTPOS,
  TYPE_SET_LOADING,
  TYPE_SET_POSLIST,
  UPDATE_CURRENT_POS,
} from 'constants/PointsOfSale';
import {
  REDUX_POSID,
  REDUX_WAREHOUSEID,
  REDUX_POS,
} from 'constants/persistence';

import loadingReducer from './util/loadingReducer';
import {
  getUserLoggedIn,
  getUserRights,
  getIsLoading as getIsLoadingLogin,
} from './Login';

const initialState = {
  loading: false,
  pointsOfSale: [],
  selected: false,
};

function comparePointsOfSale(a, b) {
  if (b.name > a.name) {
    return -1;
  }
  if (b.name < a.name) {
    return 1;
  }
  return a.pointOfSaleID - b.pointOfSaleID;
}

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case TYPE_SET_POSLIST:
      return { ...state, pointsOfSale: payload.sort(comparePointsOfSale) };
    case TYPE_SELECTPOS:
      return { ...state, selected: payload };
    case TYPE_SET_LOADING:
      return { ...state, loading: loadingReducer.reduce(state, payload) };
    case UPDATE_CURRENT_POS:
      return {
        ...state,
        pointsOfSale: state.pointsOfSale.map(el =>
          el.pointOfSaleID === payload.pointOfSaleID ? payload : el,
        ),
      };
    default:
      return state;
  }
}

export default reducer;

export function getPointsOfSale(state) {
  return state.PointsOfSale.pointsOfSale;
}

export function getSelectedPosID(state) {
  return JSON.parse(localStorage.getItem(REDUX_POSID));
}

export const getEmployeePOSByWarehouseIDS = createSelector(
  state => getPointsOfSale(state),
  points => {
    const warehouseIDS = [...new Set(points.map(p => p.warehouseID))];
    return Object.assign(
      {},
      ...warehouseIDS.map(wh => ({
        [wh]: points.filter(point => point.warehouseID === wh),
      })),
    );
  },
);

export function getEmployeePOSByWarehouseID(ids) {
  return state => {
    const pointsByWarehouseIDS = getEmployeePOSByWarehouseIDS(state);
    return ids.flatMap(id => pointsByWarehouseIDS[id] || []);
  };
}

const getStoredPos = createSelector(
  () => localStorage.getItem(REDUX_POS),
  json => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  },
);

export const getSelectedPos = createSelector(
  state => getStoredPos(state),
  state => getSelectedPosID(state),
  state => state.PointsOfSale.pointsOfSale,
  (stored, id, pointsOfSale) => {
    const found = pointsOfSale.find(pos => pos.pointOfSaleID === id) || null;
    if (found) {
      localStorage.setItem(REDUX_POS, JSON.stringify(found));
      return found;
    }
    if (stored && stored.pointOfSaleID === id) return stored;
    return null;
  },
);

export function getIsLoading(state) {
  return loadingReducer.getIsLoading(state.PointsOfSale.loading);
}

export function getPointsOfSaleWithRights(state) {
  const pointsOfSale = getPointsOfSale(state);
  const rights = getUserRights(state);
  if (!getUserLoggedIn(state) || !rights?.warehouses) {
    return [];
  }
  return pointsOfSale.filter(
    pos =>
      (
        Object.values(rights.warehouses).find(
          w => w.warehouseID === pos.warehouseID,
        ) || {}
      ).right === '1',
  );
}
export function getPosLoggedIn(state) {
  return (
    getSelectedPos(state) &&
    getUserLoggedIn(state) &&
    !getIsLoadingLogin(state) &&
    !getIsLoading(state)
  );
}
export const getQuickSelectProductIDs = createSelector(
  state => getSelectedPos(state).quickButtons,
  quickButtonIDs => quickButtonIDs.map(r => r.productID),
);
