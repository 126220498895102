import { PrinterScript } from 'Systems/PrinterScript/exports';
import { getCurrentPrinterIntegration } from 'reducers/configs/settings';
import { tryPrintRawData } from 'actions/integrations/printer';
import { createConfirmation } from 'actions/Confirmation';

const print = string => async (dispatch, getState) => {
  const selectedPrinter = getCurrentPrinterIntegration(getState());
  const printer = new PrinterScript(selectedPrinter);
  const lines = string.split(/\n/);
  lines.forEach(line => printer.print(line).ln());
  dispatch(tryPrintRawData(printer.toString()));
};

export default clientR => async dispatch => {
  await new Promise((resolve, reject) =>
    dispatch(
      createConfirmation(resolve, reject, {
        title: 'Print Client Receipt',
        body: 'Print receipt copy for the client?',
      }),
    ),
  )
    .then(() => dispatch(print(clientR)))
    .catch(() => {});
};
