import { createSelector } from 'reselect';

import { ErplyAttributes, round, roundCurrency } from 'utils';

import { getSelectedPos } from '../../../reducers/PointsOfSale';

import { TendersByCurrency } from './OCDTypes';

export const spreadTenders = (dict: TendersByCurrency[string]) => {
  const newDict: Record<string, number | string> = {};
  Object.keys(dict).forEach(key => {
    const { reason, counted } = dict[key];
    const trailingDash = /-$/;
    // CARD- is the expected way to send the CARD payment info if it was paid with integration 'none'
    const newKey = key === 'CARD-' ? key : key.replace(trailingDash, '');
    newDict[`varianceReasonID-${newKey}`] = reason !== -1 ? reason : '';
    newDict[`counted-${newKey}`] = Number(counted) || 0;
  });
  return newDict;
};

export const getDifference = (expected: number, counted: string) =>
  // counted is rounded using roundCurrency thus to avoid issues with difference
  // use the same rounding algorithm for expected
  Math.abs(Number(counted) - roundCurrency(expected));

export const isWithinAllowedDifference = (
  expected: number,
  counted: string,
  allowedDifference: number,
) => {
  const difference = getDifference(expected, counted);
  // round to avoid binary floating point math issues
  return Number(round(difference)) <= allowedDifference;
};

export const asFloat = (value: string | null | undefined) =>
  value ? Number(value) || 0 : 0;

export const getDefaultCountedInRegister = createSelector(
  getSelectedPos,
  pos => {
    const attrs = new ErplyAttributes(pos?.attributes);
    // `default_float_amount` supported for backwards compatibility with old Berlin plugin
    // but `default_cash_in_register` is the new official attribute name,
    // and the one that should be communicated out to customers that want to
    // use this feature
    const defaultCashCounted = attrs.get('default_cash_in_register');
    // For compatibility with old Berlin plugin - not to be used
    const legacy = attrs.get('default_float_amount');
    return String(defaultCashCounted ?? legacy ?? '');
  },
);
