import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setShowPaymentInput } from 'actions/Payments';
import { getShowPaymentInput } from 'reducers/Payments';

const AddTip = () => {
  const dispatch = useDispatch();
  const { showPaymentsInput } = useSelector(getShowPaymentInput);
  const { t } = useTranslation('payment');

  return (
    <li className="payment-item cash-payment" style={{ cursor: 'pointer' }}>
      <div
        tabIndex={0}
        className="button"
        role="button"
        onClick={() => dispatch(setShowPaymentInput(!showPaymentsInput, 'TIP'))}
      >
        <span className="title">{t('buttons.addTip')}</span>
      </div>
    </li>
  );
};

export default AddTip;
