/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios';

import { round, sleep } from 'utils';
import { getMSEndpointFromLS } from 'reducers/installer';

import {
  PaymentProps,
  RefundProps,
  VoidProps,
  VerifoneV2Response,
  VerifoneV2TransactionRecord,
} from '../types';
import { baseLog } from '../constants';

const defaultVerifoneUrl = 'https://localhost.erply.com:7878/api/v2/';

function getIsTransactionResponse(
  input: AxiosResponse<VerifoneV2Response>['data']['records'][number],
): input is VerifoneV2TransactionRecord {
  return 'additionalData' in input;
}

function formatResponseToV1Format(response: AxiosResponse<VerifoneV2Response>) {
  const log = baseLog.extend('v2responseToV1');
  log('Formatting given response:', response);
  const record = response?.data?.records?.[0];
  log('Got the following record: ', record);
  if (record) {
    if (getIsTransactionResponse(record)) {
      const formattedResponse = {
        data: {
          // assign the record explicitly as data since this is how it is returned in v1
          ...record,
          // extract the receipt data from additionalData into data itself for direct access (same as v1)
          customerReceipt: record.additionalData?.CustomerReceipt,
          merchantReceipt: record.additionalData?.MerchantReceipt,
        },
      };
      log('Generated the following response:', formattedResponse);
      return formattedResponse;
    }
    if ('transactionStatus' in record) {
      return {
        data: {
          ...record,
          statusMessage: record.transactionStatus,
        },
      };
    }
    return { data: record };
  }
  log('Could not get a record, returning response as is');
  return response;
}

function getVerifoneUrl() {
  const endpoint = getMSEndpointFromLS('verifone');
  return endpoint ? `${endpoint}api/v2/` : defaultVerifoneUrl;
}

const verifoneRequest = axios.create({
  baseURL: getVerifoneUrl(),
  headers: {
    Accept: 'application/json',
  },
});

export async function testConnection() {
  return verifoneRequest
    .get<VerifoneV2Response>('testConnection')
    .then(response => formatResponseToV1Format(response))
    .catch(e => {
      console.error('Failed to test connection to verifone', e);
      throw e;
    });
}

export async function requestSale(params: PaymentProps) {
  const log = baseLog.extend('requestSale');
  const amount = round(params.amount);
  const payload = {
    transactionType: 'SALE',
    amount,
  };

  try {
    log(
      'Making payment request to V2 endpoint with following payload: ',
      payload,
    );

    const response = await verifoneRequest.post<VerifoneV2Response>(
      'payment',
      payload,
    );

    log('Got the following response from the v2 request: ', response);

    return formatResponseToV1Format(response);
  } catch (e) {
    console.error('Failed to process payment with verifone', e);
    throw e;
  } finally {
    await sleep(0.2);
  }
}

export async function requestReturn(params: RefundProps) {
  const log = baseLog.extend('requestReturn');
  const amount = round(params.amount);
  const payload = {
    amount,
    transactionType: 'REFUND',
  };

  try {
    log(
      'Making refund request to V2 endpoint with following payload: ',
      payload,
    );

    const response = await verifoneRequest.post<VerifoneV2Response>(
      'payment',
      payload,
    );

    log('Got the following response from the v2 request: ', response);

    return formatResponseToV1Format(response);
  } catch (e) {
    console.error('Failed to process refund with verifone', e);
    throw e;
  } finally {
    await sleep(0.2);
  }
}

export async function requestVoid(params: VoidProps) {
  const log = baseLog.extend('requestVoid');
  const amount = round(params.amount);
  const referenceNumber = params.referenceNumber.replace(/^0+/, '');

  const payload = {
    transactionType: 'VOID',
    amount,
    referenceNumber,
    origDateTime: params.timestamp,
  };

  try {
    log(
      'Making a void request to V2 endpoint with following payload: ',
      payload,
    );

    const response = await verifoneRequest.post<VerifoneV2Response>(
      'payment',
      payload,
    );

    log('Got the following response from the v2 request: ', response);

    return formatResponseToV1Format(response);
  } catch (e) {
    console.error('Failed to process VOID with verifone', e);
    throw e;
  } finally {
    await sleep(0.2);
  }
}

export async function requestStart() {
  return verifoneRequest.get<VerifoneV2Response>('start').catch(e => {
    console.error('Failed to process start with verifone', e);
    throw e;
  });
}

export async function cancelRequest() {
  return verifoneRequest
    .post<VerifoneV2Response>('manage', { manageType: 'cancelTransaction' })
    .then(resp => {
      const formattedResponse = formatResponseToV1Format(resp);
      if ('resultCode' in formattedResponse.data) {
        if (formattedResponse.data.resultCode === '0') {
          return formattedResponse;
        }
        let errMessage =
          formattedResponse.data.statusMessage ??
          'Failed to cancel Verifone payment';
        if (
          formattedResponse.data.statusMessage?.includes(
            'transaction cannot be canceled',
          )
        ) {
          errMessage =
            'At this point the transaction cannot be canceled. Please finish the payment';
        }
        throw new Error(errMessage);
      }
      return formattedResponse;
    })
    .catch(e => {
      console.error('Failed to process abort with verifone', e);
      throw e;
    });
}
