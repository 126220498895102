import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';
import { addError } from 'actions/Error';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';

import { SET_EXCHANGE_PROCESS_STEP } from '../rdx';

const UICustomYellowCTA: PosPlugin['UICustomYellowCTA'] = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('gridButtons');

  const currentConfig: any =
    useSelector(state =>
      getPluginConfiguration('environmental-&-CA-fees')(state),
    ) ?? {};

  const classNameFor = n =>
    classNames('btn-long', 'button-item', {
      'btn-double': n === 2,
      'btn-triple': n === 3,
    });

  const btnTextContainerStyle = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
  } as const;

  const btnTextStyle = {
    fontSize: '20px',
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'inline-flex',
    alignItems: 'center',
  } as const;

  const processReturn = () => {
    const hasExchangePaymentType = currentConfig.selectedPaymentType !== '';
    const hasExchangeReason = currentConfig.selectedReturnReason !== '';
    const hasExchangeDiscountReason =
      currentConfig.selectedDiscountReason !== '';

    if (!hasExchangePaymentType) {
      return dispatch(
        addError(
          'Payment type for exchanges not selected in exchange plugin configuration.',
        ),
      );
    }

    if (!hasExchangeReason) {
      return dispatch(
        addError(
          'Return reason not selected in exchange plugin configuration.',
        ),
      );
    }

    if (!hasExchangeDiscountReason) {
      return dispatch(
        addError(
          'Discount reason  not selected in exchange plugin configuration.',
        ),
      );
    }

    dispatch({ type: SET_EXCHANGE_PROCESS_STEP, payload: 1 });
    return dispatch(
      openModalPage({
        component: modalPages.ProductReturn,
        modalClassName: 'product-return-modal',
      }),
    );
  };

  return (
    <Button
      className={classNames(classNameFor(2), 'btn-grid_yellow')}
      variant="warning"
      onClick={processReturn}
      data-testid="exchange-btn"
    >
      <span style={btnTextContainerStyle}>
        <span style={btnTextStyle}>{t('actions.exchange')}</span>
      </span>
    </Button>
  );
};

export default UICustomYellowCTA;
