import i18n from 'i18next';

export const OPEN_MODAL_PAGE = 'OPEN_MODAL_PAGE';
export const CLOSE_MODAL_PAGE = 'CLOSE_MODAL_PAGE';
export const PREVIOUS_MODAL_PAGE = 'PREVIOUS_MODAL_PAGE';

// prettier-ignore
const modalPagesSpec = {
  advancedCustomerSearch: { auto: true, trans: "advancedCustomerSearch", path: "Customers/views/AdvancedCustomerSearch" },
  advancedSearchResults: { auto: true, trans: "advancedCustomerSearch", path: "Customers/views/AdvancedSearchResults" },
  ageVerification: { auto: true, trans: "settingsAgeVerification", path: "AgeVerification" },
  ageVerificationSettings: { auto: false, trans: 'settingsAgeVerification', path: 'Settings/views/AgeVerification' },
  AllowedTenders: { auto: false, trans: "", path: "Settings/views/Payment/views/AllowedTenders" },
  assocaitePOSSettings: { auto: false, trans: "settingAssociatePos", path: "Settings/views/AssociatePOS" },
  associatePosSettingsNew: { auto: false, trans: "settingAssociatePos", path: "Settings/views/AssociatePosNew" },
  cardPaymentUI: { auto: true, trans: "payment", path: "cardPaymentUI" },
  cashierPrice: { auto: true, trans: "", path: "CashierPrice" },
  cashInOut: { auto: false, trans: "cashInOut", path: "CashInOut" },
  ChangePassword: { auto: false, trans: "changePassword", path: "ChangePassword" },
  ChangePin: { auto: false, trans: "changePin", path: "ChangePin" },
  clockIn: { auto: false, trans: "clockInOut", path: "clockIn" },
  clockOut: { auto: false, trans: "clockInOut", path: "clockOut" },
  closeDay: { auto: false, trans: "openCloseDay", path: "OpenCloseDay/CloseDay" },
  closeDayNotes: { auto: false, trans: 'openCloseDay', path: 'CloseDayNotes' },
  currencySelection: { auto: false, trans: "", path: "CurrencySelection" },
  companySettings: { auto: false, trans: "settingsCompany", path: "Settings/views/Company" },
  configurationSettings: { auto: false, trans: "settingsConfiguration", path: "Settings/views/Configuration" },
  coupons: { auto: false, trans: "printCoupons", path: "Coupons" },
  customPrompts: { auto: true, trans: "CustomPrompts", path: "CustomPrompts/CustomPrompts" },
  serialNumberPrompt: { auto: true, trans: "SerialNumberPrompt", path: "CustomPrompts/SerialNumberPrompt" },
  createCustomer: { auto: true, trans: "createCustomer", path: "Customers/views/CreateCustomer" },
  createNew: { auto: false, trans: "createNew", path: "CreateNew" },
  createNewItem: { auto: false, trans: "createNew", path: "CreateNew/Item" },
  customerView: { auto: true, trans: "customer", path: "Customers/views/CustomerView" },
  debugSettings: { auto: false, trans: "settingsDebug", path: "Settings/views/Debug" },
  pluginSettings: { auto: false, trans: "settingsPlugin", path: "Settings/views/Plugins" },
  discountPopup: { auto: true, trans: "discountPopup", path: "DiscountPopup" },
  editEmployee: { auto: false, trans: "employee", path: "Settings/views/Employees/EditEmployee" },
  employeesSettings: { auto: false, trans: "settingsEmployees", path: "Settings/views/Employees" },
  externalDeviceSettings: { auto: false, trans: "", path: "Settings/views/ExternalDevice" },
  extraAddress: { auto: false, trans: "addressForm", path: "Customers/views/ExtraAddress" },
  giftCardBalance: { auto: false, trans: "giftcard", path: "GiftCardBalance" },
  giftCardSerial: { auto: false, trans: "giftcard", path: "GiftCardSerial" },
  GridButtonSize: {auto: false, trans: 'gridButtonSize', path: 'Settings/views/Configuration/GridButtonSize'},
  Theme: { auto: false, trans: "", path: "Settings/views/Theme" },
  integrationsExternal: { auto: false, trans: "", path: "IntegrationUIComponents/External" },
  languageSettings: { auto: false, trans: "settingsLanguage", path: "Settings/views/Language" },
  layaway: { auto: false, trans: "layaway", path: "Layaway" },
  layawayList: { auto: false, trans: "layaway", path: "LayawayList" },
  layawayActionSelection: { auto: false, trans: 'layaway', path: 'LayawayList/ActionSelection.tsx' },
  cancellationFeeModal: { auto: false, trans: 'cancellationFee', path: 'LayawayList/CancellationFeeModal.tsx'},
  Manuals: { auto: false, trans: "", path: "Manuals" },
  matrixVariations: { auto: true, trans: "", path: "matrixVariations" },
  matrixVariationsMultiple: { auto: true, trans: "", path: "MatrixVariationsMultiple" },
  managerOverride: { auto: true, trans: "ManagerOverride", path: "ManagerOverride/ManagerOverride.tsx" },
  MSPrintPreview: { auto: true, trans: "", path: "MSPrintPreview" },
  offers: { auto: false, trans: "offer", path: "offers" },
  onScreenKeyboard: { auto: false, trans: "", path: "Settings/views/OnScreenKeyboard" },
  OpenDay: { auto: false, trans: "openCloseDay", path: "OpenCloseDay/OpenDay" },
  Payment: { auto: true, trans: "payment", path: "Payment" },
  PaymentConfirmation: { auto: true, trans: "payment", path: "Payment/views/Confirmation" },
  paymentSettings: { auto: false, trans: "settingsPayment", path: "Settings/views/Payment" },
  pendingSales: { auto: false, trans: "sale", path: "PendingSales" },
  pickupOrders: { auto: false, trans: "order", path: "PickupOrders" },
  orderActionSelection: { auto: false, trans: 'order', path: 'PickupOrders/ActionSelection.tsx'},
  printingSettings: { auto: false, trans: "settingsPrinter", path: "Settings/views/Printing" },
  scanningSettings: { auto: false, trans: "settingsScanner", path: "Settings/views/Scanning" },
  ProductEdit: { auto: true, trans: "product", path: "ProductEdit" },
  ProductGroupEdit: { auto: true, trans: "product", path: "ProductGroupEdit" },
  ProductGroupsEditMode: { auto: true, trans: "", path: "Settings/views/ProductGroupsEditMode" },
  ProductOrderEdit: { auto: true, trans: "product", path: "ProductOrderEdit" },
  ProductOrderView: { auto: true, trans: "product", path: "ProductOrderView" },
  ProductReturn: { auto: false, trans: "return", path: "ProductReturn" },
  ProductReturnReasons: { auto: false, trans: "", path: "ProductReturnReasons" },
  productsFilter: { auto: true, trans: "product", path: "ProductsFilter" },
  ProductView: { auto: true, trans: "", path: "ProductView" },
  ProductWeight: { auto: true, trans: "", path: "ProductWeight" },
  ReceiptPreview: { auto: false, trans: "", path: "ReceiptPreview" },
  recentSales: { auto: false, trans: "sale", path: "RecentSales" },
  relatedProducts: { auto: false, trans: "product", path: "ProductOrderView/components/RelatedProducts" },
  productNoteButtons: { auto: false, trans: "product", path: 'ProductOrderView/components/Notes/NoteButtonsModal' },
  SaleCommission: { auto: false, trans: "sale", path: "SaleCommission" },
  saleCoupons: { auto: false, trans: "", path: "Campaigns/Coupons" },
  SaleDiscount: { auto: true, trans: "discount", path: "SaleDiscount" },
  saleNotes: { auto: true, trans: "saleNotes", path: "SaleNotes" },
  saleNotesCustom: { auto: true, trans: "", path: "SaleNotes/Custom" },
  salePromotions: { auto: false, trans: "campaigns", path: "Campaigns/Promotions" },
  searchCustomers: { auto: false, trans: "customer", path: "SearchCustomers" },
  searchProducts: { auto: true, trans: "product", path: "SearchProducts" },
  Settings: { auto: true, trans: "settings", path: "Settings" },
  shipping: { auto: false, trans: "shipping", path: "Shipping" },
  stockAndPriceItem: { auto: true, trans: "product", path: "StockAndPriceItem" },
  stockAndPriceList: { auto: true, trans: "product", path: "StockAndPriceList" },
  stockTransfer: { auto: false, trans: "stockTransfer", path: "StockTransfer" },
  storeCredit: { auto: false, trans: "", path: "StoreCredit" },
  taxExempt: { auto: true, trans: "taxExempt", path: "TaxExempt" },
  taxRatesSettings: { auto: false, trans: "settingsTaxRates", path: "Settings/views/TaxRates" },
  customersSettings: { auto: false, trans: "settingsTaxRates", path: "Settings/views/Customers" },
  taxRatesSettingsEdit: { auto: false, trans: "", path: "Settings/views/TaxRates/Edit" },
  tipAfterPayment: { auto: false, trans: "", path: "TipAfterPayment" },
  unpaidInvoices: { auto: false, trans: "unpaidInvoice", path: "unpaidInvoices" },
  ZReport: { auto: false, trans: "zReport", path: "ZReport" },
  pluginModal: { auto: false, trans: "", path: "PluginModal" },
  accountSales: { auto: false, trans: "accountSales", path: "AccountSales" },
  emailModal: { auto: false, trans: "", path: "EmailModal" },
  reasonPopup: { auto: false, trans: "", path: "ReasonPopup" },
  employeeSelect: { auto: false, trans: "", path: "EmployeeSelect" },
  ScannerMultiResults: { auto: false, trans: "scannerSelection", path: "ScannerMultiResults" },
  ScannerNoResults: { auto: false, trans: "", path: "ScannerNoResults" },
  ScannerResultsCreation: { auto: false, trans: "", path: "ScannerResultsCreation" },
  PackageAmountPopup: { auto: false, trans: '', path: "PackageAmountPopup" },
  ProductCommissions: { auto: false, trans: '', path: 'ProductOrderView/components/ProductCommissions' },
  SettingsUserInterface: { auto: false, trans: '', path: 'Settings/views/Configuration/UserInterface' },
  CustomerSearchResults: {auto: false, trans: '', path: 'Settings/views/CustomerSearchResults'},
  SettingsCustomerDisplay: { auto: false, trans: '', path: 'Settings/views/IntegrationCustomerDisplay' },
  ConfigurePlugin: { auto: false, trans: '', path: "ConfigurePlugin/ConfigurePlugin" },
  PaymentGateways: { auto: false, trans: "", path: "Settings/views/Payment/views/PaymentGateways" },
  CustomPayments: { auto: false, trans: '', path: 'Settings/views/CustomPayment' },
  QuickNotes: { auto: false, trans: '', path: 'Settings/views/Configuration/QuickNotes' },
  ReceiptTemplates: { auto: false, trans: '', path: 'Settings/views/Configuration/ReceiptTemplates' },
  PromotionAmountPrompt: { auto: false, trans: '', path: 'Campaigns/PromotionAmountPrompt' },
  ExtDevCardReader: { auto: false, trans: '', path: 'Settings/views/ExternalDevice/views/CardReader/' },
  PasswordInput: { auto: true, trans: '', path: 'PasswordInput'},
  ExtDevAdvanced: { auto: false, trans: '', path: 'Settings/views/ExternalDevice/views/Advanced/' },
  CaptureCheckNumber: { auto: true, trans: '', path: 'CaptureCheckNumber' },
  MountUnmountCard: { auto: false, trans: "", path: "MountUnmountCard" },
};

export const autoImportLazyComponents = (forceAll: boolean): void => {
  Object.values(modalPagesSpec).forEach(({ auto, path, trans }) => {
    if (forceAll || auto) {
      if (trans) {
        i18n.loadNamespaces(trans);
      }
    }
  });
  i18n.loadNamespaces('shoppingCart');
  i18n.loadNamespaces('offlineLogin');
};

export const modalPages = Object.fromEntries(
  Object.entries(modalPagesSpec).map(([k, v]) => [k, k]),
) as { [key in keyof typeof modalPagesSpec]: string };

export const scannerErrorTypesBlackList = ['openPaymentModal'];
export const scannerComponentWhiteList = [
  modalPages.relatedProducts,
  modalPages.matrixVariations,
  modalPages.matrixVariationsMultiple,
  modalPages.saleCoupons,
];
export const shoppingcartComponentsBlacklist = [
  modalPages.Payment,
  modalPages.PaymentConfirmation,
];
export const shoppingcartErrorTypesBlacklist = scannerErrorTypesBlackList;

export const idCardReaderWhiteList = [
  modalPages.relatedProducts,
  modalPages.matrixVariations,
  modalPages.matrixVariationsMultiple,
];
