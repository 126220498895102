import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import UIButton from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { useDispatch, useSelector } from 'react-redux';
import { addWarning, addSuccess } from 'actions/Error';
import { sendCapturePayment } from 'paymentIntegrations/wrapper/messages';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { deleteLastPayment } from 'actions/Payments';

import Loader from 'components/Loader';
import { getLastPayments } from 'reducers/sales';
import { useTranslation } from 'react-i18next';
import { savePayments } from 'actions/sales';

const TipAfterPayment = ({ loading, salesDoc }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('payment');
  const [tip, setTip] = useState('');
  const employeeID = useSelector(getLoggedInEmployeeID);
  const cardPayments = useSelector(getLastPayments);

  const onChange = e => setTip(e.target.value);
  if (cardPayments.length === 0) {
    dispatch(previousModalPage());
    return null;
  }
  const capturePayment = async () => {
    const invoice = {
      ...salesDoc,
      number: salesDoc.invoiceNo,
    };
    const employee = {
      employeeID,
    };
    const todoPayment = [
      ...cardPayments.map(p => ({
        ...p,
        amount: Number(p.sum),
      })),
    ];

    if (todoPayment.length > 0) {
      const tipPayment = {
        ...todoPayment[0],
        giftCardVatRateID: undefined,
        paymentID: undefined,
        amount: Number(tip),
        type: 'TIP',
        key: 'tip',
      };

      const payments =
        Number(tip) > 0
          ? [
              { ...todoPayment[0], giftCardVatRateID: undefined },
              { ...tipPayment },
            ]
          : [{ ...todoPayment[0], giftCardVatRateID: undefined }];

      try {
        dispatch(
          savePayments({
            documentID: todoPayment[0].documentID,
            payments,
            storeCreditPayments: [],
          }),
        );
      } catch (error) {
        console.warn('Failed to save payment', error);
      }

      sendCapturePayment({
        payment: {
          ...todoPayment[0],
          amount: Number(todoPayment[0].amount) + Number(tip),
        },
        invoice: { ...invoice },
        employee: { ...employee },
      });

      dispatch(deleteLastPayment(todoPayment[0].key));
      setTip('');
      return;
    }
  };
  const onSubmit = () =>
    tip.trim().length === 0
      ? dispatch(addWarning(t('tipAfterPayments.messages.noValue')))
      : capturePayment();
  const { invoiceNo } = salesDoc;
  const { cardType, cardNumber, sum } = cardPayments[0] || {};
  return (
    <>
      <Loader show={loading}>
        <Modal.Header>
          <Modal.Title>{`${t(
            'tipAfterPayments.title',
          )} ${invoiceNo}`}</Modal.Title>
          <div className="cash-in-out-action-buttons">
            <CloseButton action={() => dispatch(previousModalPage())} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {t('tipAfterPayments.body.cardType')} {cardType}
          </h5>
          <h5>
            {t('tipAfterPayments.body.cardNumber')} {cardNumber}
          </h5>
          <h5>
            {t('tipAfterPayments.body.sum')} {sum}
          </h5>
          <Form>
            <Form.Control
              value={tip}
              onChange={onChange}
              placeholder={t('tipAfterPayments.body.enterTip')}
            />
            <UIButton
              className="mt-3"
              text={t('tipAfterPayments.actions.capture')}
              action={onSubmit}
            />
          </Form>
        </Modal.Body>
      </Loader>
    </>
  );
};

export default TipAfterPayment;
