import i18next from 'i18next';

export class ErplyApiError extends Error {
  constructor(status) {
    const camelToSentence = camel =>
      camel
        .replace(/([A-Z])/g, match => ` ${match.toLowerCase()}`)
        .replace(/^./, match => match.toUpperCase())
        .trim();
    super(
      // i18next-extract-mark-context-next-line [1000,1001,1002,1003,1005,1006,1007,1008,1009,1010,1011,1012,1013,1014,1015,1016,1017,1018,1019,1020,1021,1022,1023,1024,1025,1026,1027,1028,1029,1040,1041,1042,1043,1044,1045,1046,1047,1048,1049,1050,1051,1052,1053,1054,1055,1056,1057,1058,1059,1060,1061,1062,1063,1064,1065,1066,1067,1068,1071,1072,1073,1074,1075,1076,1077,1078,1079,1080,1081,1082,1083,1084,1090,1091,1092,1100,1101,1102,1103,1110,1111,1112,1113,1114,1115,1116,1117,1118,1119,1120,1121,1122,1123,1124,1126,1127,1128,1129,1130,1131,1132,1133,1134,1136,1137,1138,1139,1140,1141,1142,1143,1144,1145,1146,1147,1148,1149,1150,1151,1152,1153,1154,1155,1156,1157,1158,1159,1160,1161]
      i18next.t(
        [
          `validation:ErplyAPI.error.${status.errorCode}`,
          'validation:ErplyAPI.error.unknown',
        ],
        {
          ...status,
          errorField: i18next.t(`login:inputs.${status.errorField}`, {
            defaultValue: camelToSentence(status.errorField ?? ''),
          }),
        },
      ),
    );
    this.errorCode = status.errorCode;
    this.errorField = status.errorField;
  }
}
