import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { getCustomerGroups } from 'reducers/customerSearch';
import InputField from 'components/FieldTypes/InputField';
import Loader from 'components/Loader';

export type Configuration = {
  volumeBuyerCustomerGroupID: string | null;
};
export const ComponentConfiguration: Required<
  PosPlugin<Configuration>
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const groups = useSelector(getCustomerGroups);

  return (
    <Loader
      show={groups === null}
      loadingText="Loading available customer groups"
    >
      <br />
      <InputField
        type="select"
        options={[{ name: 'None selected...', value: '-1' }].concat(
          groups?.map(gr => ({ name: gr.name, value: gr.customerGroupID })),
        )}
        size="lg"
        title="Volume buyer customer group"
        onChange={e => save({ volumeBuyerCustomerGroupID: e.target.value })}
        value={current?.volumeBuyerCustomerGroupID}
      />
    </Loader>
  );
};
