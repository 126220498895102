import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as types from 'constants/ShoppingCart';
import * as typesC from 'constants/customerSearch';
import { getPluginSelector } from 'reducers/Plugins';
import { setCurrentSalesDocument } from 'actions/sales';
import { getCurrentSalesDocument } from 'reducers/sales';
import { ErplyAttributes } from 'utils';
import { selectOneCustomer } from 'actions/customerSearch';
import { getSelectedCustomer } from 'reducers/customerSearch';

import * as meta from './meta';
import { SuretaxApi } from './api/SuretaxApi';

type RequestRequest = Parameters<SuretaxApi['makeRequest']>[0];
type RequestResponse = ReturnType<SuretaxApi['makeRequest']> extends Promise<
  infer R
>
  ? R
  : never;

const actionTypes = {
  set: 'Plugin: Set suretax results',
};

type ReduxType = {
  /** The calculated taxes for the current state of the cart */
  active?: {
    request: RequestRequest;
    response: RequestResponse;
  };
};
const initialState: ReduxType = {};
export const suretaxReducer = (state: ReduxType = initialState, action) => {
  switch (action.type) {
    case types.CALCULATE_SUCCESS:
    case types.RESET_SHOPPING_CART:
    case types.CALCULATE:
    case types.ADD_PRODUCT_SUCCESS:
    case types.ADD_MULTI_PRODUCTS_SUCCESS:
    case types.REMOVE_PRODUCT:
    case types.DECREASE_ORDER_AMOUNT:
    case types.INCREASE_ORDER_AMOUNT:
    case typesC.SET_CUSTOMER_SUCCESS:
    case typesC.SET_ONE_CUSTOMER_SUCCESS:
    case types.UPDATE_ORDER_AMOUNT:
    case types.UPDATE_ORDER_DISCOUNT:
    case types.UPDATE_ORDER_PRICE:
    case types.UPDATE_ORDER_OTHER:
    case types.UPDATE_ORDER_NAME:
    case types.UPDATE_NO_PRODUCT_CONTAINER:
    case types.SET_EMPLOYEE:
    case types.RESET_DISCOUNT:
    case types.APPLY_DISCOUNT:
    case types.UPDATE_PRODUCT_NOTES:
      // On shopping cart update, whatever active state we had becomes invalid
      return initialState;

    case actionTypes.set:
      return { active: action.payload };

    default:
      return state;
  }
};

suretaxReducer.setCurrent = (
  request: RequestRequest,
  response: RequestResponse,
) => ({
  type: actionTypes.set,
  payload: { request, response },
});

suretaxReducer.getCurrent = getPluginSelector<ReduxType>(meta.pluginID)(
  a => a.active?.response,
);
suretaxReducer.getCurrentRequest = getPluginSelector<ReduxType>(meta.pluginID)(
  a => a.active?.request,
);
suretaxReducer.setShippingZip = (zip: string) => async (dispatch, getState) => {
  const doc = getCurrentSalesDocument(getState());
  await dispatch(
    setCurrentSalesDocument({
      ...doc,
      attributes: new ErplyAttributes(doc?.attributes).set('SuretaxZip', zip),
    }),
  );
  dispatch(selectOneCustomer(getSelectedCustomer(getState()).customerID));
};
suretaxReducer.getShippingZip = createSelector(getCurrentSalesDocument, sdoc =>
  new ErplyAttributes(sdoc.attributes).get('SuretaxZip'),
);
