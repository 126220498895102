import axios, { AxiosResponse } from 'axios';

import {
  REDUX_CLIENTCODE,
  REDUX_SERVICE_ENDPOINTS,
  REDUX_SESSIONKEY,
} from 'constants/persistence';
import { getServiceEndpoints } from 'services/ErplyAPI/getServiceEndpoints';
import { proxy } from 'services/shared';

import {
  ARTemplateResponse,
  TransferRowLink,
  RequestProps,
  SendByEmailRequest,
  SendByEmailResponse,
  TransferRowLinkDraft,
} from './types';

const getUrl = async (): Promise<string> => {
  const { url } = JSON.parse(
    localStorage.getItem(REDUX_SERVICE_ENDPOINTS) || '{}',
  )?.['sales'];
  if (url) return url;
  const [payload] = await getServiceEndpoints();
  return payload?.sales?.url;
};

const doRequest = async <R>({
  method,
  endpoint,
  data,
  params,
}: RequestProps) => {
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  const url = await getUrl();
  const response: AxiosResponse<R> = await axios({
    url: `${proxy}${url}${endpoint}`,
    method,
    data,
    params,
    headers: { clientCode, sessionKey },
  });
  return response.data;
};

export const sendSalesDocumentByEmail = async (data: SendByEmailRequest) =>
  doRequest<SendByEmailResponse>({
    endpoint: 'v1/sales/documents/by-email',
    method: 'post',
    data: { requests: data },
  });

export const getARTemplates = async () =>
  doRequest<ARTemplateResponse[]>({
    endpoint: 'v1/actual-reports/templates',
    method: 'get',
  });

export const getPermanentPdfUrl = async (
  saleDocID: number,
  templateID: number,
) =>
  doRequest<{ url: string }>({
    endpoint: `v1/sales/${saleDocID}/permanent-pdf-url`,
    method: 'get',
    params: { templateID },
  });

/**
 * Read links between sales document rows and inventory transfer document rows
 *
 * One sales document row can reference multiple inventory transfer document rows, and vice versa.
 * @see https://api-sales-eu10.erply.com/documentation/index.html#/Sales%20Document%20Row%20Links/get_v1_sales_documents_rows_transfer_row_links
 */
export const getTransferRowLinks = async (
  params: Partial<{
    salesDocumentIDs: number[];
    transferDocumentIDs: number[];
    orderBy:
      | 'id'
      | 'salesRowID'
      | 'salesDocID'
      | 'transferRowID'
      | 'transferDocID'
      | 'linkedQuantity'
      | 'transferTypeID'
      | 'transferTypeName'
      | 'transferStatusID'
      | 'transferStatusName'
      | 'transferNumber'
      | 'transferDate'
      | 'transferSourceWarehouseID'
      | 'transferSourceWarehouseName'
      | 'salesDocTypeID'
      | 'salesDocTypeName'
      | 'salesDocStatusID'
      | 'salesDocStatusName'
      | 'added'
      | 'changed';
    orderByDirection: 'ASC' | 'DESC';
    skip: number;
    take: number;
  }>,
) =>
  doRequest<TransferRowLink[]>({
    endpoint: 'v1/sales/documents/rows/transfer-row-links',
    method: 'get',
    params,
  });

/**
 * Create links between sales document rows and transfer document rows
 * @see https://api-sales-eu10.erply.com/documentation/index.html#/Sales%20Document%20Row%20Links/post_v1_sales_documents_rows_transfer_row_links
 */
export const saveTransferRowLinks = async (data: {
  items: TransferRowLinkDraft[];
}) =>
  doRequest<{ ids: number[] }>({
    endpoint: 'v1/sales/documents/rows/transfer-row-links',
    method: 'post',
    data,
  });
