import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import UIButton from 'components/UIElements/UIButton';
import { Product } from 'types/Product';
import {
  getCurrencyFormatter,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { addProduct } from 'actions/ShoppingCart/addProduct';


const ProductsResults: FC<{ products: Product[] }> = ({ products }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('scannerSelection');

  const formatCurrency = useSelector(getCurrencyFormatter);
  const shouldUsePriceWithVAT = useSelector(getShowPricesWithTax);

  const selectProduct = product => {
    dispatch(
      addProduct({
        productID: product.productID,
        needsWeightPopup: !product.amount,
        amount: product?.amount || 1,
      }),
    );
    dispatch(closeModalPage());
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={{ textAlign: 'center' }}>
            <TableCell
              style={{
                width: '250px',
                paddingLeft: '1em',
              }}
            >
              <span>{t('products.headers.name')}</span>
            </TableCell>
            <TableCell style={{ width: '200px' }}>
              <span>{t('products.headers.price')}</span>
            </TableCell>
            <TableCell style={{ width: '500px' }}>
              <span>{t('products.headers.group')}</span>
            </TableCell>
            <TableCell style={{ width: '200px' }}>
              <span>{t('products.headers.category')}</span>
            </TableCell>
            <TableCell style={{ width: '100px' }}>
              <span>{t('select')}</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(p => (
            <TableRow
              style={{ textAlign: 'center' }}
              key={p.productID}
              data-testid="scanningDuplicateProduct"
              data-test-key={p.productID}
            >
              <TableCell>
                <span>{p.name}</span>
              </TableCell>
              <TableCell>
                <span>
                  {formatCurrency(
                    shouldUsePriceWithVAT
                      ? p.priceListPriceWithVat ?? p.priceWithVat
                      : p.priceListPrice ?? p.price,
                  )}
                </span>
              </TableCell>
              <TableCell>
                <span>{p.groupName}</span>
              </TableCell>
              <TableCell>
                <span>{p.categoryName}</span>
              </TableCell>
              <TableCell>
                <UIButton action={() => selectProduct(p)} text={t('select')} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsResults;
