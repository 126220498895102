/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from 'reselect';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import i18next from 'i18next';
import * as R from 'ramda';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

import { getSetting } from 'reducers/configs/settings';
import {
  getProductInOrderByIndex,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import WBUAPI from 'plugins/wbu/API/API';
import * as salesApi from 'services/ErplyAPI/sales';
import { addError, addWarning, dismissType } from 'actions/Error';
import { PosPlugin } from 'plugins/plugin';
import {
  getCustomerGroups,
  getDefaultCustomer,
  getIsDefaultCustomer,
  getSelectedCustomer,
  getSelectedCustomerID,
} from 'reducers/customerSearch';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import { createConfirmation } from 'actions/Confirmation';
import { removeProduct } from 'actions/ShoppingCart';
import { calculate } from 'actions/ShoppingCart/calculate';
import { waitForCondition } from 'utils';
import { getIsAReturn, getIsCurrentSaleAReturn } from 'reducers/sales';
import { selectOneCustomer } from 'actions/customerSearch';
import { RootState } from 'reducers';
import { openPluginModalPage } from 'actions/modalPage';

import {
  updateCustomer,
  getPreviousCustomer,
  getIsWbuCustomerLoading,
  getWbuExtraData,
  setPreviousCustomer,
} from '../redux';
import { getWbuConfiguration } from '../configuration';
import { wbuLog } from '../constants';

import MembershipActivationRetry from './modalPages/membershipActivationRetry';
import { HandleMembershipProps } from './types';

dayjs.extend(utc);
dayjs.extend(timezone);

const hasPreMembershipProductInCart = createSelector(
  getSetting('plugin_dsc_membership_product_id'),
  getProductsInShoppingCart,
  (membershipProductID, cart) =>
    cart.some(
      ({ productID }) => Number(productID) === Number(membershipProductID),
    ),
);

export const tryActivateOrReturnMembership: Required<
  PosPlugin
>['onSaveSalesDocumentAttrToJsonApi']['before'] = ({
  savedSaleDocument,
  progress,
}) => async (dispatch, getState) => {
  const customerID = getSelectedCustomer(getState())?.id;
  const membershipProductID = getSetting('plugin_dsc_membership_product_id')(
    getState(),
  );
  const [salesDocument] = await salesApi.getSalesDocuments({
    id: savedSaleDocument.invoiceID,
  });

  const productIDs = new Set(savedSaleDocument.rows.map(row => row.productID));
  const DSCrows = savedSaleDocument.rows.filter(
    row => Number(row.productID) === Number(membershipProductID),
  );
  const amount = Object.values(DSCrows).reduce(
    (a, { amount }) => Number(a) + Number(amount),
    0,
  );

  const isDSCMembershipSold = productIDs.has(Number(membershipProductID));

  const handleMembership = (args: HandleMembershipProps) => async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ) => {
    const { amount, customerID, savedSaleDocument, salesDocument } = args;
    // return membership
    if (getIsCurrentSaleAReturn(getState())) {
      await dispatch(
        WBUAPI.returnMembershipPurchase({
          validationOnly: 0,
          customerId: customerID,
          amount: -amount,
          salesDocumentId: savedSaleDocument.invoiceID,
          relatedDocumentId: salesDocument.baseDocuments[0].id,
        }),
      );
      return;
    }

    // activate membership
    if (
      salesDocument.invoiceState !== 'PENDING' &&
      Number(salesDocument.confirmed) !== 0
    ) {
      await dispatch(
        WBUAPI.updateCustomerMembershipDate({
          customerId: customerID,
          amount,
          salesDocumentId: savedSaleDocument.invoiceID,
        }),
      );
    }
  };

  if (isDSCMembershipSold) {
    const props = { amount, customerID, savedSaleDocument, salesDocument };
    try {
      await dispatch(handleMembership(props));
    } catch (error) {
      const errMsg =
        error?.message ?? error ?? 'Could not access membership service';
      await dispatch(progress.halt);
      dispatch(addError('User membership did not update: ', errMsg));
      await dispatch(
        openPluginModalPage('MembershipActivationRetry')({
          isPopup: true,
          props: {
            handleMembership: () => dispatch(handleMembership(props)),
            error: errMsg,
          },
        }),
      );
      dispatch(progress.resume);
    }
  }
};

/**
 * Checks if DSC memberships can be returned,
 * if not removes them from array of products to add to shopping cart
 */
export const filterMembershipsThatCanBeReturned: Required<
  PosPlugin
>['onAddReturnProducts']['on'] = (p, ap) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const doFilter = async () => {
    const state = getState();
    const membershipProductID = getSetting('plugin_dsc_membership_product_id')(
      state,
    );
    const checkIfMembershipProduct = product =>
      Number(product.productID) === Number(membershipProductID);
    const withoutMembershipProducts = R.evolve({
      orders: R.reject(checkIfMembershipProduct),
    });

    const membershipProductsToAdd = ap.orders.filter(checkIfMembershipProduct);
    if (!membershipProductsToAdd.length) return ap;

    const documentDate = dayjs.tz(
      `${ap.returnDocument.date}T${ap.returnDocument.time}`,
      getSetting('timezone')(state),
    );

    const { plugin_dsc_membership_return_days } = getWbuConfiguration(state);
    const lastAllowedToReturnDate = dayjs().subtract(
      plugin_dsc_membership_return_days,
      'day',
    );
    if (documentDate.isBefore(lastAllowedToReturnDate)) {
      dispatch(
        addWarning(
          `Allowed DSC Membership returning in ${plugin_dsc_membership_return_days} days has been passed- can not return the Membership`,
          { selfDismiss: false },
        ),
      );
      return withoutMembershipProducts(ap);
    }

    const amountToReturn = R.pipe(
      R.map(R.prop('amount')),
      R.sum,
    )(membershipProductsToAdd);

    try {
      await dispatch(
        WBUAPI.returnMembershipPurchase({
          validationOnly: 1,
          customerId: ap.returnDocument.clientID,
          amount: -amountToReturn,
          salesDocumentId: 1, // constant id since only trying to validate
          relatedDocumentId: ap.returnDocument.id,
        }),
      );

      return ap;
    } catch (error) {
      dispatch(addWarning((error as Error).message, { selfDismiss: false }));
      return withoutMembershipProducts(ap);
    }
  };
  const filteredAp = await doFilter();

  if (!filteredAp.orders.length) {
    throw new Error('Cancelled from plugin');
  }
  return filteredAp;
};

export const checkRightsForMembership: Required<
  PosPlugin
>['onAddProduct']['before'] = product => async (dispatch, getState) => {
  const membershipProductID = getSetting('plugin_dsc_membership_product_id')(
    getState(),
  );
  const isDefaultCustomer = getIsDefaultCustomer(getState());
  const isReturn = getIsAReturn(getState());

  const productIsMembership =
    Number(product.productID) === Number(membershipProductID);

  const membershipProductCannotBeAdded =
    isDefaultCustomer && productIsMembership;

  const membershipProductAddedToCreditInvoice = isReturn && productIsMembership;

  if (membershipProductCannotBeAdded) {
    dispatch(
      createConfirmation(
        () => {
          // do nothing
        },
        null,
        {
          title: 'Membership addition failed',
          body: 'DSC membership cannot be sold to default customer',
        },
      ),
    );
    throw new Error('Default user cannot purchase membership product');
  } else if (membershipProductAddedToCreditInvoice) {
    dispatch(
      createConfirmation(
        () => {
          // do nothing
        },
        null,
        {
          title: 'Membership addition failed',
          body: 'DSC membership cannot be sold during returns',
        },
      ),
    );
    throw new Error('DSC membership cannot be sold during returns');
  }
};
export const checkMembershipBeingReturned: Required<
  PosPlugin
>['onUpdateOrderAmount']['before'] = ({ amount, orderIndex }) => async (
  dispatch,
  getState,
) => {
  const order = getProductInOrderByIndex(orderIndex)(getState());
  const membershipProductID = getSetting('plugin_dsc_membership_product_id')(
    getState(),
  );
  if (Number(membershipProductID) === order.productID && Number(amount) < 0) {
    await dispatch(
      createConfirmation(
        () => {
          // do nothing
        },
        null,
        {
          title: 'Membership refund failed',
          body: 'DSC membership cannot be refunded',
        },
      ),
    );
    throw new Error('DSC membership cannot be sold during returns');
  }
};

const resetPremembershipLog = wbuLog.extend('resetPremembership');
/**
 * Removes premembership from
 * - previous customer if membership product is in cart
 * - selected customer if they have stale premembership due to unexpected POS closing
 *
 * Should run when customer is changed. Currently known actions that set customer:
 * - setCustomer
 * - selectOneCustomer
 * - startNewSale
 * - saveSalesDocument
 */
export const resetPremembership = (recalculateCart = false) => async (
  dispatch,
  getState,
) => {
  const log = resetPremembershipLog;

  const selectedCustomer = getSelectedCustomer(getState());
  const previousCustomer = getPreviousCustomer(getState());
  const hasMembershipProduct = hasPreMembershipProductInCart(getState());

  log('Checking if should remove pre-membership from previous customer', {
    previousCustomer,
    selectedCustomer,
    hasMembershipProduct,
  });
  if (
    previousCustomer.id &&
    previousCustomer.hasPremembership &&
    (selectedCustomer.id !== previousCustomer.id || !hasMembershipProduct)
  ) {
    log('Removing pre-membership from previous customer', previousCustomer.id);
    dispatch(WBUAPI.removePreMembership(previousCustomer.id));
  }

  log('Checking if selected customer is default');
  const { id: defaultCustomerID } = getDefaultCustomer(getState());
  if (!selectedCustomer.id || selectedCustomer.id === defaultCustomerID) {
    log('Is default customer');
    dispatch(setPreviousCustomer({ id: undefined, hasPremembership: false }));
    return;
  }

  const customerGroups = getCustomerGroups(getState());
  const membershipGroupID = customerGroups.find(
    ({ name }) => name.toLowerCase() === 'dsc membership',
  ).customerGroupID;
  const wbuExtraData = getWbuExtraData(getState());

  const hasPremembership =
    wbuExtraData?.status === 'non member' &&
    selectedCustomer.groupID === membershipGroupID;

  log('Checking if selected customer has stale pre-membership', {
    hasPremembership,
  });
  // Remove premembership that customer has due to unexpected POS closing
  if (hasPremembership && !hasMembershipProduct) {
    log(
      'Removing stale pre-membership from selected customer',
      selectedCustomer.id,
    );
    // If for any reason this does not run DSC service will reset premembership after 10 minutes
    await dispatch(WBUAPI.removePreMembership(selectedCustomer.id));
    dispatch(selectOneCustomer({ customerID: selectedCustomer.id }));
    dispatch(
      setPreviousCustomer({ id: selectedCustomer.id, hasPremembership: false }),
    );
  } else {
    log('Updating previous customer state');
    dispatch(
      setPreviousCustomer({
        id: selectedCustomer.id,
        hasPremembership,
      }),
    );
  }

  if (recalculateCart) {
    log('Recalculating cart');
    dispatch(calculate());
  }
};

export const removeMembershipFromDefaultCustomer: Required<
  PosPlugin
>['onSetCustomer']['after'] = () => async (dispatch, getState) => {
  const membershipProductID = getSetting('plugin_dsc_membership_product_id')(
    getState(),
  );
  const isDefaultCustomer = getIsDefaultCustomer(getState());
  const productsIncart = getProductsInShoppingCart(getState());
  const membershipProductFromCart = productsIncart.find(
    ({ productID }) => Number(productID) === Number(membershipProductID),
  );
  if (isDefaultCustomer && membershipProductFromCart) {
    dispatch(addWarning('Removing DSC membership product'));
    dispatch(removeProduct(membershipProductFromCart.orderIndex));
  }
};

export const waitForCustomerToSet: Required<
  PosPlugin
>['onCalculate']['before'] = () => async (dispatch, getState) => {
  const isOnline = getConnectionHealth(getState());
  const isDefaultCustomerSelected = getIsDefaultCustomer(getState());

  // do not wait for customer to be set if default customer is selected or POS is in offline mode
  if (isDefaultCustomerSelected || !isOnline) return;

  const s = 1000;
  try {
    await waitForCondition({
      testFn: () =>
        !getIsWbuCustomerLoading(getState()) && getConnectionHealth(getState()),
      timeout: 60 * s,
      checkInterval: 0.1 * s,
      callbacks: {
        [1 * s]: () =>
          dispatch(
            addWarning(i18next.t('alerts:loading.customer'), {
              selfDismiss: false,
              errorType: 'customer-waiting',
            }),
          ),
      },
    });
  } catch (error) {
    dispatch(
      addWarning(i18next.t('alerts:loading.customer_error'), {
        selfDismiss: true,
      }),
    );
  } finally {
    dispatch(dismissType('customer-waiting'));
  }
};

export const determineMembershipDiscount: Required<
  PosPlugin
>['onCalculate']['on'] = (_, rows) => async (_, getState) => {
  const membershipProductId = getSetting('plugin_dsc_membership_product_id')(
    getState(),
  );
  const isMembershipProd = row => row.productID === Number(membershipProductId);
  return rows.map(row => {
    if (isMembershipProd(row)) {
      if (!(row.manualDiscount === 0 || row.manualDiscount === 100)) {
        return {
          ...row,
          manualDiscount: 0,
          discount: 0,
          manualDiscountReasonCodeID: 0,
        };
      }
      return row;
    }
    return row;
  });
};

export const resetCustomerData: Required<
  PosPlugin
>['onSaveCustomer']['after'] = (_, customerID) => async (dispatch, _) => {
  dispatch(updateCustomer(String(customerID)));
};

export const setPremembership: Required<PosPlugin>['onCalculate']['on'] = (
  p,
  ap,
) => async (dispatch, getState) => {
  // Will be present because waitForCustomerToSet is called earlier
  const wbuExtraData = getWbuExtraData(getState());
  const hasMembershipProduct = hasPreMembershipProductInCart(getState());
  const { id, hasPremembership } = getPreviousCustomer(getState());
  const customerID = getSelectedCustomerID(getState());

  if (wbuExtraData?.status === 'member' || wbuExtraData?.status === 'active') {
    return ap; // Customer is already a member
  }

  if (!hasPremembership && hasMembershipProduct) {
    await dispatch(WBUAPI.createPreMembership(customerID));
    dispatch(
      setPreviousCustomer({ id, hasPremembership: hasMembershipProduct }),
    );
  } else if (hasPremembership && !hasMembershipProduct) {
    await dispatch(WBUAPI.removePreMembership(customerID));
    dispatch(
      setPreviousCustomer({ id, hasPremembership: hasMembershipProduct }),
    );
  }
  return ap;
};

export const components = { MembershipActivationRetry };
