import SAIModal from './components/SAIModal';

export const pluginID = 'capture-sai-number';

export const pluginName = 'Capture SAI number';

export const meta = {
  id: pluginID,
  name: pluginName,
  keywords: ['SAI number', 'reference number', 'reference'],
};

export const ALLOWED_SALE_OPTION_IDS = [
  'cashDrawer',
  'lastReceipt',
  'giftReceipt',
  'stockTransfer',
];

export const modals = {
  SAINumberModal: `${pluginID}_SAI_NUMBER_MODAL`,
};

export const components = {
  [modals.SAINumberModal]: SAIModal,
};

export const ATTRIBUTES = {
  SAI_NUMBER: 'saiNumber',
};

export enum Status {
  FAILED_TO_VALIDATE = 'FAILED_TO_VALIDATE',
  LOADING = 'LOADING',
  DUPLICATE = 'DUPLICATE',
  NOT_VALIDATED = 'NOT_VALIDATED',
  VALID = 'VALID',
}
