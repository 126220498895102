import { CardPaymentHooks, PaymentObj } from 'paymentIntegrations/types';

import { ApiResponse } from '../types';

import { parseJSONPTerminalResponse, printReceipts } from './utils';

type Response = ApiResponse & { falsePositive?: boolean };

const handleVoidTerminalResponse = (
  response: string,
  cardPayment: PaymentObj,
  { beforeDocDelete }: CardPaymentHooks,
) => async (dispatch): Promise<Response | null> => {
  if (response) {
    const terminalResponse: Response = parseJSONPTerminalResponse(response);

    if (
      terminalResponse.RcmResponse?.RESPONSE?.HOSTRESPONSEDESCRIPTION ===
      'Approval'
    ) {
      const { RECEIPTTEXT } = terminalResponse.RcmResponse.RESPONSE;

      await beforeDocDelete(cardPayment.key);

      dispatch(printReceipts(RECEIPTTEXT));

      return terminalResponse;
    }

    terminalResponse.falsePositive = true;
    return terminalResponse;
  }
  return null;
};

export default handleVoidTerminalResponse;
