import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import * as c from 'constants/modalPage';

function getModalClassName(component: string): string {
  switch (component) {
    case c.modalPages.pendingSales:
    case c.modalPages.recentSales:
    case c.modalPages.pickupOrders:
    case c.modalPages.layawayList:
    case c.modalPages.unpaidInvoices:
    case c.modalPages.offers:
    case c.modalPages.stockAndPriceList:
    case c.modalPages.customerView:
    case c.modalPages.pluginSettings:
    case c.modalPages.ConfigurePlugin:
    case c.modalPages.ZReport:
      return 'wide-modal';
    default:
      return 'default-modal';
  }
}

export function openModalPage<T = any>({
  component,
  props = {},
  modalClassName,
  isPopup = false,
  groupID = undefined,
  replace = false,
  id = undefined,
}: {
  component: string;
  props?: Record<string, any>;
  modalClassName?: string;
  isPopup?: boolean;
  groupID?: string;
  replace?: boolean;
  id?: any;
}): ThunkAction<Promise<T>, unknown, unknown, Action> {
  return async dispatch =>
    new Promise((res, rej) =>
      dispatch({
        id,
        type: c.OPEN_MODAL_PAGE,
        component,
        props: {
          resolve: res,
          reject: rej,
          ...props,
        },
        modalClassName: modalClassName ?? getModalClassName(component),
        isPopup,
        groupID,
        replace,
      }),
    );
}
