import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSProperties } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import ReactDOM from 'react-dom';

import { addWarning } from 'actions/Error';
import { getPOSVersionsDictFromCAFA } from 'reducers/cafaConfigs';
import {
  getEnabledPOSVersionControl,
  getSetting,
} from 'reducers/configs/settings';
import Loader from 'components/Loader';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import * as types from 'constants/CAFA';
import { getUserRights } from 'reducers/Login';
import workaround from 'containers/VersionControl/WorkaroundInstallerUpdate';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import {
  FORCED_ROLLOUT_CUTOFF_DATE,
  ROLLOUT_CONF_PARAM_NAME,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/CONFIGURATION';

import {
  checkIsAllowedEnvironment,
  getEnvironmentFromURL,
  redirectPOSToVersion,
} from './utils';
import VersionControlModal from './components/VersionControlModal';
import useVersionRedirect from './hooks/useVersionRedirect';

type VersionControlContainerProps = {
  style?: CSSProperties;
};

export const VersionControlContainer: FC<VersionControlContainerProps> = ({
  style,
}) => {
  const dispatch: ThunkDispatch<any, any, Action> = useDispatch();
  const { t } = useTranslation('posVersionControl');

  const perms = useSelector(getUserRights);
  const hasVersionPermissions = Boolean(perms.rightChangePOSVersion);
  const posVersioningIsEnabled = useSelector(getEnabledPOSVersionControl);
  const versionsDict = useSelector(getPOSVersionsDictFromCAFA);
  const environment = useMemo(() => getEnvironmentFromURL(), []);
  const currentVersion = useMemo(() => versionsDict[environment], [
    versionsDict,
    environment,
  ]);

  const onNoAccessHandler = () => {
    dispatch(addWarning(t('warnings.noAccess')));
  };

  const saveVersion = (value: string) => {
    if (!environment) {
      dispatch(addWarning(t('warnings.invalidEnvForVersion')));
    }
    return dispatch(
      saveCafaConfig({
        level: { level: types.CAFA_LEVELS.Company, id: '' },
        integrationName: types.INTEGRATIONS.global.posBrazilVersion,
        integrationType: types.INTEGRATION_TYPES.global,
        config: R.assoc(environment, value, versionsDict),
      }),
    );
  };

  if (!checkIsAllowedEnvironment() || !posVersioningIsEnabled) return null;

  return (
    <VersionControlModal
      style={style}
      hasAccess={hasVersionPermissions}
      onNoAccess={onNoAccessHandler}
      currentVersion={currentVersion}
      saveVersion={saveVersion}
    />
  );
};

const POSVersionRedirect: FC = ({ children }) => {
  const { status, redirect } = useVersionRedirect();
  // Note: When setting types are merged in, this needs to be added
  //  For the time being, it's a regular boolean setting that must default to false
  const accountIsInRollout = useSelector(getSetting(ROLLOUT_CONF_PARAM_NAME));
  const mustRolloutAll = FORCED_ROLLOUT_CUTOFF_DATE < new Date();
  const posID = useSelector(getSelectedPosID);

  let shouldRun = false;
  if (workaround.shouldRun) {
    if (accountIsInRollout || mustRolloutAll) {
      shouldRun = true;
    }
  }

  const onDone = useCallback(() => {
    if (redirect) {
      redirectPOSToVersion(redirect);
    } else {
      window.location.reload();
    }
  }, [redirect]);

  useEffect(() => {
    switch (status) {
      case 'redirect':
      case 'ok':
      case 'inactive':
        if (shouldRun) {
          // Run workaround, then redirect or refresh
          const root = document.getElementById('root');
          ReactDOM.unmountComponentAtNode(root);
          ReactDOM.render(<workaround.UI onDone={onDone} onRetry={() => workaround.run(posID, true)} />, root);
          workaround.run(posID);
        } else if (!workaround.hasAlreadyRun) {
          // Skipping workaround, set cookie and refresh to get correct version
          workaround.skip();
          onDone();
        } else if (redirect) {
          // Workaround already run but VC wants to redirect
          onDone();
        } else {
          // Workaround already run and VC doesn't need to redirect either, stay
        }
        break;
      default:
      // Wait for VC to finish calculating
    }
  }, [redirect, status, posID, shouldRun, onDone]);

  return (
    <>
      {children}
      {status === 'loading' && (
        <Loader show={true} style={{ position: 'fixed', inset: 0, padding: 0 }}>
          <div />
        </Loader>
      )}
    </>
  );
};

export default POSVersionRedirect;
