import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { renameKeysWith } from 'ramda-adjunct';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import InputField from 'components/FieldTypes/InputField';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { addError } from 'actions/Error';
import { resetCurrentSalesDocument } from 'actions/sales';
import { resetShoppingCart } from 'actions/ShoppingCart';

import { ReasonCode } from '../constants';

const InventoryWriteOff = ({ cart, writeOffReasonCodes }) => {
  const selectOptions = useMemo(() => writeOffReasonCodes.map(rc => rc.name), [
    writeOffReasonCodes,
  ]);
  const [selectedReason, setSelectedReason] = useState<ReasonCode>(
    writeOffReasonCodes[0],
  );
  const [comments, setComments] = useState('');
  const warehouseID = useSelector(getSelectedWarehouseID);

  const mappedCodes = useMemo(
    () =>
      writeOffReasonCodes.reduce((acc, cur) => {
        const { name } = cur;
        return { ...acc, [name]: cur };
      }, {}),
    [writeOffReasonCodes],
  );

  const productParams = Object.fromEntries(
    cart
      .map((row, i) => renameKeysWith(k => `${k}${i + 1}`)(row))
      .flatMap(Object.entries),
  );

  const dispatch = useDispatch();

  const writeOff = async () => {
    try {
      await doClientRequest({
        request: 'saveInventoryWriteOff',
        warehouseID,
        reasonID: selectedReason.reasonID,
        comments,
        ...productParams,
      });
      dispatch(closeModalPage());
      dispatch(resetCurrentSalesDocument());
      dispatch(resetShoppingCart());
    } catch (error) {
      dispatch(addError(error.message));
    }
  };

  return (
    <>
      <Modal.Header>
        <h2>Inventory Write-Off</h2>
        <CloseButton action={() => dispatch(previousModalPage())} />
      </Modal.Header>
      <Modal.Body>
        <InputField
          title="Reason"
          value={selectedReason.name}
          type="select"
          options={selectOptions}
          onChange={e => setSelectedReason(mappedCodes[e.target.value])}
        />
        <InputField
          value={comments}
          className="mt-2"
          type="textarea"
          title="Comments"
          onChange={e => setComments(e.target.value)}
        />
        <Button
          size="lg"
          block
          className="mt-2"
          variant="danger"
          onClick={() => writeOff()}
        >
          Confirm
        </Button>
      </Modal.Body>
    </>
  );
};

export default InventoryWriteOff;
