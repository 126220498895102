import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { doPnpSalesReceipt } from 'plugins/pnp/customReceipts/actions';
import { ComponentConfiguration } from 'plugins/pnp/customReceipts/Configuration';
import { addError } from 'actions/Error';
import { getPluginConfiguration, getPluginSelector } from 'reducers/Plugins';
import { getProductsUniversal } from 'actions/productsDB';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { Product } from 'types/Product';
import { RItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { CancelActionOverrideError } from 'plugins/pluginUtils';
import { getSaleDocID } from 'actions/integrations/printer/utils';

export type Configuration = {
  customLogo: string;
  customStampLogo: string;
  footerTexts: { [groupID: string]: string };
  preFooterText: string;
  headerText: string;
  gcTypes: (number | string)[];
  noPrintProductGroups: number[];
};
const defaultConf: Configuration = {
  customLogo: '',
  customStampLogo: '',
  footerTexts: {},
  preFooterText: '',
  headerText: '',
  gcTypes: [],
  noPrintProductGroups: [],
};
export const pluginID = 'pnp-customPrinting';
const getNoPrintProductGroups = state =>
  getPluginConfiguration<Configuration>(pluginID)(state)?.noPrintProductGroups;

const pnpPrinting: PosPlugin<Configuration> = {
  id: pluginID,
  name: '[PNP] Custom printing',
  keywords: ['pnp', 'printing', 'receipt'],
  // language=Markdown
  info: `Overrides the default printing functionality with a layout specific to PNP`,
  getStatus: () => ({
    type: 'valid',
    message: '',
  }),
  combineConfiguration: (company, warehouse, pos, user) => ({
    ...defaultConf,
    ...company,
    ...warehouse,
    ...pos,
    ...user,
  }),
  ComponentConfiguration,
  onGenerateReceipt: {
    before: ({ salesDocument }) => async dispatch => {
      const salesDocumentID = getSaleDocID(salesDocument);
      if (salesDocumentID === undefined) {
        dispatch(
          addError('Failed to print last invoice - invoice ID is unknown'),
        );
        throw new Error(
          'Cancel print, only do plugin print but plugin print also failed',
        );
      }
      const patchScript = (await dispatch(
        doPnpSalesReceipt(Number(salesDocumentID)),
      )) as RItem[];
      throw new CancelActionOverrideError(
        'Cancel print, only do plugin print',
        { patchScript },
      );
    },
  },
  /**
   * Customer badge component removed as per
   * @see https://erply.atlassian.net/browse/PBIB-779 (no default controls)
   * @see https://erply.atlassian.net/browse/PBIB-778 (no debug controls)
   * */
  UICustomerBadgeCustomRegion: () => null,
  /**
   * Disallow bulk setting of return reason
   * @see https://erply.atlassian.net/browse/PBIB-670
   */
  UISetBulkReturnReasons: () => null,

  // region Prevent receipt print with certain products
  reduxReducer: (state = false, action) => {
    if (action.type === pluginID) {
      return action.payload;
    }
    return state;
  },
  onSaveSalesDocument: {
    before: () => async (dispatch, getState) => {
      const shoppingCart = getProductsInShoppingCart(getState());
      const productIDs = new Set<number>(
        shoppingCart.map(row => Number(row.productID)),
      );
      const { products } = ((await dispatch(
        getProductsUniversal({ productIDs: Array.from(productIDs) }),
      )) as unknown) as {
        products: Product[];
        productsDict: Record<string | number, Product>;
        total: number;
      };
      const groupIDs = new Set(products.map(p => p.groupID));
      const hasTheProduct = getNoPrintProductGroups(getState()).some(groupID =>
        groupIDs.has(groupID),
      );

      await dispatch({ type: pluginID, payload: hasTheProduct });
    },
  },
  selectorOverrides: {
    getSettings: base =>
      createSelector(
        base,
        state => getPluginSelector(pluginID)(state => state)(state),
        (settings, hasTheProduct) => {
          if (!hasTheProduct) return settings;
          return {
            ...settings,
            // eslint-disable-next-line @typescript-eslint/camelcase
            touchpos_disable_default_print_the_receipt: true,
          };
        },
      ),
  },
  // endregion
};
export default pnpPrinting;
