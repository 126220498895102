import React, { useEffect, useState } from 'react';
import { Form, Modal, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { addWarning } from 'actions/Error';
import { setReturnReason } from 'actions/ShoppingCart';
import { getProductByID } from 'reducers/cachedItems/products';
import { PluginComponent } from 'plugins';

import * as styles from './ProductReturnReasons.css';
import { Props, ReturnTab } from './types';

function ProductReturnReasons({ reasons, order, resolve, reject }: Props) {
  const { t } = useTranslation('return');
  const originalProduct = useSelector(getProductByID(order.productID));
  const dispatch = useDispatch();
  const {
    nonRefundable: origProdNonRefund,
    name: origProdName,
  } = originalProduct;
  const { amount: orderAmount, orderIndex } = order;
  const [firstReason] = reasons;
  const reasonsLength = reasons.length;

  useEffect(() => {
    if (!!origProdNonRefund && Number(orderAmount) < 0) {
      dispatch(
        addWarning(
          i18next.t('alerts:nonRefundableProductError', {
            name: origProdName,
          }),
        ),
      );
      reject(new Error(t('alerts.userCancelledReasonSelection')));
      dispatch(previousModalPage());
    }
  }, [t, dispatch, orderAmount, origProdName, origProdNonRefund, reject]);

  const [activeTab, setActiveTab] = useState<ReturnTab>('perItem');
  const [selectedReason, setSelectedReason] = useState('');

  const reasonNames = reasons.map(reason => reason.name);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReason(e.target.value);
  };

  useEffect(() => {
    if (reasonsLength === 1) {
      Promise.all([
        dispatch(setReturnReason(firstReason, orderIndex)),
        dispatch(previousModalPage()),
      ]).then(() => resolve(firstReason));
    }
  }, [dispatch, firstReason, orderIndex, reasonsLength, resolve]);
  return (
    <div
      data-testid="product-return-reasons"
      className={styles['product-return-reasons']}
    >
      <Modal.Header>
        <Modal.Title data-testid="title">
          {t('title')} - {order.name}
        </Modal.Title>
        <div className="d-flex justify-content-end align-items-center">
          <SaveButton
            data-testid="save"
            title={t('common:save', { context: 'changes' })}
            disabled={!selectedReason}
            action={async () => {
              const reason = reasons.find(
                reason => reason.name === selectedReason,
              );

              if (activeTab === 'perSale') {
                Promise.all([
                  dispatch(setReturnReason(reason)),
                  dispatch(previousModalPage()),
                ]).then(() => resolve(reason));
                return;
              }

              Promise.all([
                dispatch(setReturnReason(reason, order.orderIndex)),
                dispatch(previousModalPage()),
              ]).then(() => resolve(reason));
            }}
          />
          <CloseButton
            data-testid="close"
            action={() => {
              dispatch(previousModalPage());
              reject(new Error('User cancelled return reason selection'));
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <PluginComponent
          hookname="UIProductReturnReasonReturnTypesBody"
          props={{ activeTab, setActiveTab }}
        >
          <Tabs
            id="controlled-tab-return-types"
            activeKey={activeTab}
            onSelect={k => setActiveTab(k as ReturnTab)}
          >
            <Tab eventKey="perItem" title={t('returnType.perItem')}>
              <Form.Control
                data-testid="per-item"
                className="mt-3"
                onChange={onChange}
                as="select"
              >
                <option selected disabled>
                  {t('options.default')}
                </option>

                {reasonNames.map(name => (
                  <option data-testid="option" data-test-key={name} key={name}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Tab>
            <Tab eventKey="perSale" title={t('returnType.perSale')}>
              <Form.Control
                data-testid="per-sale"
                className="mt-3"
                onChange={onChange}
                as="select"
              >
                <option selected disabled>
                  {t('options.default')}
                </option>
                {reasonNames.map(name => (
                  <option data-test-id="option" data-test-key={name} key={name}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Tab>
          </Tabs>
        </PluginComponent>
      </Modal.Body>
    </div>
  );
}
export default ProductReturnReasons;
