import { PosPlugin } from 'plugins/plugin';

import { hooks } from './hooks';

const defaultEvent = {
  before: params => async () => {},
  on: (params, actionParams) => async () => actionParams,
  after: (params, results) => async () => {},
};

export const TSPOS: any = {
  Model: {} as any,
  Events: {
    onSaveSalesDocument: defaultEvent,
    onStartNewSale: defaultEvent,
  } as const,
};
// @ts-ignore
window.TSPOS = TSPOS;

const pluginId = 'traditional-plugin';
export const traditionalPlugin: PosPlugin = {
  id: pluginId,
  name: 'Traditional (legacy-like) plugin',
  ComponentHeader: ({ children }) => {
    hooks.forEach(h => h());
    return children;
  },
  /* TSPOS.EventManager.addEventListener('after_document_save' */
  onSaveSalesDocument: TSPOS.Events.onSaveSalesDocument,

  /* TSPOS.EventManager.addEventListener('document_init' */
  // @ts-ignore
  onStartNewSale: TSPOS.Events.onStartNewSale,
};
