import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';

import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI2';
import {
  getCustomers,
  getPayments,
  getSalesDocuments,
} from 'services/ErplyAPI';
import { getSelectedPos } from 'reducers/PointsOfSale';
import {
  RItem,
  RTable,
  RTableRow,
  RText,
  RTextGroup,
  RTextLine,
  RTextMetadata,
} from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { getPluginConfiguration } from 'reducers/Plugins';
import { add, ErplyAttributes } from 'utils';
import { deepMap, notUndefinedOrNull, unique } from 'utils/tsHelpers';
import { getSetting } from 'reducers/configs/settings';
import { getSelectedWarehouse } from 'reducers/warehouses';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { getGiftcardTypes } from 'reducers/giftcards';
import { getProductsUniversal } from 'actions/productsDB';
import { Product } from 'types/Product';
import { Payment } from 'types/Payment';

import { Configuration, pluginID } from '.';

type ActualReportsRecord = {
  printCount: 0;
  totalDiscountSum: '9.00' | string;
  documentRows: {
    no: 2;
    title: 'Board Game (Test Product)' | string;
    productGroup: 'Games (Example Group)' | string;
    productName: 'Board Game (Test Product)' | string;
    amount: '1' | string;
    unit: '' | string;
    code: string;
    finalNetPrice: '7.50' | string;
    finalPriceWithVAT: '9.00' | string;
    finalNetPriceWithCurrency: '$7.50' | string;
    finalPriceWithVATWithCurrency: '$9.00' | string;
    rowNetTotal: '7.50' | string;
    rowNetTotalWithCurrency: '$7.50' | string;
    rowVAT: '1.50' | string;
    rowVATWithCurrency: '$1.50' | string;
    rowTotal: '9.00' | string;
    rowTotalWithCurrency: '$9.00' | string;
    discount: '50%' | string;
    vatRate: '20 %' | string;
    originalNetPrice: '15.00' | string;
    originalPriceWithVAT: 18;
    originalNetPriceWithCurrency: '$15.00' | string;
    originalPriceWithVATWithCurrency: '$18.00' | string;
    jdoc: any;
    realPercentageDiscount: '' | string;
    realDiscountBasePrice: '7.50' | string;
    realDiscountBasePriceWithCurrency: '$7.50' | string;
  }[];
};
type PaymentRecord = {
  paymentID: string;
  documentID: string;
  type: string;
  typeID: string | number;
  date: string;
  sum: string;
  cashPaid: string;
  cashChange: string;
  cardNumber?: string;
  giftCardID?: string;
  attributes: Record<string, string>;
};
type VatrateRecord = {
  id: string;
  name: string;
  components?: {
    componentID: string;
    type: 'STATE' | 'COUNTY' | 'CITY' | 'OTHER';
    name: string;
    rate: string;
  }[];
};
type RewardPointsRecord = {
  transactionID: string;
  customerID: string;
  customerCardNumber: string;
  invoiceID: string;
  invoiceNo: string;
  earnedPoints: number;
  remainingPoints: number;
  createdUnixTime: number;
  expiryUnixTime: number;
  pointOfSaleID: string;
  pointOfSaleName: string;
  employeeID: string;
  employeeName: string;
};
type SalesDocument = {
  id: string;
  type: string;
  number: string;
  warehouseID: string;
  pointOfSaleID: string;
  currencyCode: string;
  pricelistID: string;
  date: string;
  time: string;
  clientID: string;
  clientName: string;
  clientEmail: string;
  clientCardNumber: string;
  /** Custom address */
  addressID: string;
  /** Custom address */
  address: string;
  employeeID: string;
  employeeName: string;
  notes: string;
  netTotal: string;
  vatTotal: string;
  rounding: string;
  total: string;
  paid: string;
  taxExemptCertificateNumber: string;
  referenceNumber: string;
  customReferenceNumber: string;
  attributes: { attributeName; attributeType; attributeValue }[];
  longAttributes: { attributeName; attributeValue }[];
  vatTotalsByTaxRate: {
    vatrateID: number;
    total: string;
  }[];
  rows: {
    rowID: string;
    stableRowID: string;
    productID: string;
    serviceID: string;
    itemName: string;
    code: string;
    vatrateID: string;
    amount: string;
    price: string;
    discount: string;
    finalNetPrice: string;
    finalPriceWithVAT: string;
    rowNetTotal: string;
    rowVAT: string;
    rowTotal: string;
    deliveryDate: string;
    returnReasonID: string;
    employeeID: string;
    campaignIDs: string;
    containerID: string;
    containerAmount: string;
    originalPriceIsZero: 0 | 1;
    packageID: string;
    sourceWaybillID: string;
    jdoc: any;
  }[];
};

const textToTaggedPieces = ({
  text,
  meta = {},
}: {
  text: string;
  meta?: RTextMetadata;
}): RText[] => {
  const triggers = {
    '<b>': { bold: true },
    '</b>': { bold: false },
    '<u>': { underline: true },
    '</u>': { underline: false },
    '<h1>': { size: 3 },
    '</h1>': { size: 1 },
    '<h2>': { size: 2 },
    '</h2>': { size: 1 },
    '<p>': { size: -1 },
    '<p2>': { size: -2 },
    '<p3>': { size: -3 },
    '</p>': { size: 1 },
    '</p2>': { size: 1 },
    '</p3>': { size: 1 },
  };
  const matchingKey = Object.keys(triggers).find(k => `${text}`.includes(k));
  if (!matchingKey) return [{ text, meta }]; // Out of triggers, return
  const [before, ...afters] = text.split(matchingKey);
  return [
    { text: before, meta },
    ...textToTaggedPieces({
      text: afters.join(matchingKey),
      meta: { ...meta, ...triggers[matchingKey] },
    }),
  ];
};
const onlyLast = (char, target) => {
  const parts = target?.split(char);
  if (!parts) return target;
  if (parts.length === 1) return target;
  return parts.slice(0, -1).join('') + char + parts.slice(-1)[0];
};
const parseUSNumber = a =>
  Number(onlyLast('.', a?.replace?.(/[^\d-.]/, '')) ?? a);
const formatUS = a =>
  Number(a).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const getReceiptImage = state =>
  getPluginConfiguration<Configuration>(pluginID)(state)?.customLogo;
const getReceiptStampImage = state =>
  getPluginConfiguration<Configuration>(pluginID)(state)?.customStampLogo;
const getPreFooterText = state =>
  getPluginConfiguration<Configuration>(pluginID)(state)?.preFooterText;
const getCustomHeaderText = state =>
  getPluginConfiguration<Configuration>(pluginID)(state)?.headerText;

async function getRewardPointsRecord(customer, salesDocumentID: number) {
  const rewardPointsRecord: null | RewardPointsRecord = null;
  const {
    status: { recordsTotal },
  } = await doClientRequest({
    request: 'getEarnedRewardPointRecords',
    recordsOnPage: 1,
    customerID: customer.customerID,
  });
  const pages = Math.ceil(recordsTotal / 500);
  for (let i = pages; 0 < i; i--) {
    const { records } = await doClientRequest({
      request: 'getEarnedRewardPointRecords',
      recordsOnPage: 500,
      customerID: customer.customerID,
      pageNo: i,
    });
    const rewardPointsRecord = records.find(
      rec => String(rec.invoiceID) === String(salesDocumentID),
    );
    if (rewardPointsRecord) return rewardPointsRecord;
    if (records.length === 0) {
      return null;
    }
  }
  return rewardPointsRecord;
}

const getTaxComponents = (data: ActualReportsRecord) =>
  Object.keys(data)
    .map(k => k.match(/^vatComponent_(.+?)_(.+?)_name$/))
    .filter(a => a)
    .filter(([, , type]) => type === 'OTHER')
    .map(([, name, type]) => ({
      name: data[`vatComponent_${name}_${type}_name`],
      value: data[`vatComponent_${name}_${type}_amountOfTax`],
    })) as { name: string; value: number }[];

// prettier-ignore
export const doPnpSalesReceipt = (
  salesDocumentID: number,
  /** @deprecated Wrong format */
  prefetchedPayments?: Partial<Payment>[]
): ThunkAction<any, any, any, any> => async (dispatch, getState) => {
  /*
    Redux state stuff
  */
  const pos: any = getSelectedPos(getState());
  const warehouse: any = getSelectedWarehouse(getState());
  const conf = getPluginConfiguration<Configuration>(pluginID)(getState()) ?? {};
  const footerTextConfig = getSetting("receipt_footer")(getState());
  const discountLabel = getSetting("total_discount_label")(getState());
  // prettier-ignore
  const taxProductNames = [
    getPluginConfiguration<{ admissionTaxName: string }>("environmental-&-CA-fees")(getState())?.admissionTaxName?.toUpperCase(),
    // Adm fee should be rendered as a product
    // getPluginConfiguration<{ admissionFeeName: string }>('environmental-&-CA-fees')(getState())?.admissionFeeName?.toUpperCase(),
    getPluginConfiguration<{ envFeeName: string }>("environmental-&-CA-fees")(getState())?.envFeeName?.toUpperCase()
  ];

  /*
    Requests for data
  */
  const [
    salesDocument,
    arData,
    payments,
  ] = await Promise.all([
    getSalesDocuments({
      id: salesDocumentID
    }).then(a => a[0]) as Promise<SalesDocument>,

    doClientRequest({
      request: "getSalesDocumentActualReportsDataset",
      salesDocumentID
    }).then(a => a.records[0]) as Promise<ActualReportsRecord>,

    getPayments({ documentID: salesDocumentID }) as unknown as Promise<PaymentRecord[]>,

  ] as const);
  const { products } = ((await dispatch(
    getProductsUniversal({ productIDs: salesDocument?.rows.map(r => Number(r.productID)) }),
  )) as unknown) as {
    products: Product[];
    productsDict: Record<string | number, Product>;
    total: number;
  };
  const [customer] = await getCustomers({ id: salesDocument.clientID });
  const [
    rewardPointsRecord,
    customerRewardPoints
  ] = await Promise.all([
    getRewardPointsRecord(customer, salesDocumentID),
    doClientRequest({
      request: "getCustomerRewardPoints",
      customerID: customer.customerID
    }).then(({ records: [{ points }] }) => points)
  ]);
  doClientRequest({
    request: 'registerReceiptPrint',
    invoiceID: salesDocumentID,
    pointOfSaleID: pos.pointOfSaleID,
  })

  /*
    post-API redux
   */
  const employee = getEmployeeById(salesDocument.employeeID)(getState());
  /*
    Computed data
  */
  const taxComponents = getTaxComponents(arData);
  const isTaxProduct = (p: ActualReportsRecord["documentRows"][number]) => taxProductNames.some(n => n === p.productName.toUpperCase());
  const totalDiscount = salesDocument.rows.map(
    ({ finalPriceWithVAT, discount }) =>
      parseUSNumber(finalPriceWithVAT) * (parseUSNumber(discount) / 100)
  ).reduce(add, 0);

  /*
    Util functions
  */
  const nestingDepths: number[] = [];
  salesDocument.rows.forEach((row, i, rows) => {
    // eslint-disable-next-line no-param-reassign
    let nesting = 0;
    let next: any = row;
    while (next) {
      nesting += 1;
      // eslint-disable-next-line no-loop-func
      next = rows.find(r => String(r.stableRowID) === String(next.jdoc?.BrazilPOS?.parentRowID));
    }
    nestingDepths.push(nesting);
  });
  const maxNesting = Math.max(...nestingDepths) + 2;
  const nestingCells = (count: number) => (cell: RTextGroup | "colspan" | null): (RTextGroup | "colspan" | null)[] => {
    return Array(count).fill({ pieces: [{ text: " " }] })
      .concat(cell)
      .concat(Array(maxNesting - count).fill("colspan"));
  };


  const firstLineSectionStart = <T>(l: T, i): T => {
    if (i === 0) {
      return {
        ...l,
        type: "sectionStart"
      };
    }
    return l;
  };

  /* Composing the receipt */
  const copySection: RItem[] = arData.printCount ? [
    ({
      type: "text",
      align: "center",
      pieces: [{ text: `**REPRINT ${arData.printCount}** ` }, { text: dayjs().format("M/DD/YYYY h:mma") }]
    })
  ] : [];

  const customHeaderText: RItem[] = getCustomHeaderText(getState())?.split(/\n/).map(text => (
    { type: "text", align: "center", pieces: textToTaggedPieces({ text }) }
  )) ?? [];
  const headerSection: RItem[] = [
    { type: "text", align: "center", pieces: [{ text: warehouse.name }] } as RTextLine,
    { type: "text", align: "center", pieces: [{ text: warehouse.address }] } as RTextLine,
    { type: "text", align: "center", pieces: [{ text: warehouse.phone }] } as RTextLine
  ];

  const headerSection2: RItem[] = [
    {
      type: "table" as const,
      columns: [
        { baseWidth: 7, weight: 0 },
        { baseWidth: 0, weight: 3 },
        { baseWidth: 4, weight: 0 },
        { baseWidth: 0, weight: 2 }
      ],
      rows: [
        {
          cells: [
            { pieces: [{ text: "Ticket:" }] },
            { pieces: [{ text: salesDocument.number }] },
            { pieces: [{ text: "Usr" }] },
            { pieces: [{ text: `${employee.firstName} ${employee.lastName.slice(0, 1)}.` }] }
          ]
        },
        {
          cells: [
            { pieces: [{ text: "Date" }] },
            { pieces: [{ text: dayjs(`${salesDocument.date} ${salesDocument.time}`).format("M/DD/YYYY h:mma") }] },
            "colspan",
            "colspan"
            // {pieces: [{text: 'Sta:'}]},
            // {pieces: [{text: salesDocument.pointOfSaleID}]}
          ]
        }
      ]
    }
  ];

  const creditSlipRemainingBalances: { balance: number, code: string, typeID: number }[] = await Promise.all(
    payments?.filter(p => conf.gcTypes.includes(p.typeID))
      .filter(p => p.cardNumber || new ErplyAttributes(p.attributes).get("card_number"))
      .map(p =>
        [p.sum, p.giftCardID || new ErplyAttributes(p.attributes).get("giftCardID")]
      )
      .filter(([, b]) => b)
      .map(([, id]) =>
        doClientRequest({ request: "getGiftCards", giftCardID: id }).then(a => a.records)
      )
  ).then(a => a.flatMap(a => a));
  const creditSlipSection: RTableRow[] =
    creditSlipRemainingBalances?.map((pmt): RTableRow => (
      {
        type: "normal",
        cells: [
          {
            align: "left",
            pieces: [{ text: `${getGiftcardTypes(getState()).find(t => t.id === pmt.typeID)?.nameEN} remaining balance:` }]
          },
          "colspan",
          "colspan",
          { align: "left", pieces: [{ text: parseUSNumber(pmt.balance).toFixed(2) }] }
        ]
      }
    )) ?? [];

  const paymentsSection = [{
    type: "normal",
    cells: [
      { align: "left", pieces: [{ text: "Tender:" }] }
    ]
  } as RTableRow].concat(payments.flatMap((p: PaymentRecord) => {
    const data = { type: p.type, sum: formatUS(parseUSNumber(p.sum)), cardNumber: p.cardNumber, change: undefined };
    if (conf.gcTypes.includes(p.typeID)) {
      Object.assign(data, {
        type: getAllPaymentTypes(getState()).find(pt => pt.id === p.typeID)?.print_name,
        cardNumber: p.cardNumber ? `${p.cardNumber.slice(0, 3)}${p.cardNumber.slice(3).replace(/\S/g, "*")}` : p.cardNumber
      });
    }
    if (p.type === "CASH") {
      if (parseUSNumber(p.cashChange) !== 0) {
        Object.assign(data, {
          sum: `${formatUS(parseUSNumber(p.cashPaid))}`,
          change: `${(formatUS(-parseUSNumber(p.cashChange)))}`
        });
      }
    }
    return [
      {
        type: "normal",
        cells: [
          { align: "left", pieces: [{ text: `${data.type} ` }] },
          "colspan",
          { align: "right", pieces: [{ text: data.sum }] },
          "colspan"
        ]
      }, data.cardNumber ? {
        type: "normal",
        cells: [
          { align: "left", pieces: [{ text: data.cardNumber }] },
          "colspan",
          "colspan",
          "colspan"
        ]
      } : undefined,
      data.change ? {
        type: "normal",
        cells: [
          { align: "left", pieces: [{ text: `CHANGE:` }] },
          "colspan",
          { align: "right", pieces: [{ text: data.change }] },
          "colspan"
        ]
      } : undefined
    ].filter(notUndefinedOrNull) as RTableRow[];
  })).concat([{
    type: "normal",
    cells: [
      { align: "left", pieces: [{ text: "Sale amt recvd" }] },
      "colspan",
      { align: "right", pieces: [{ text: formatUS(payments.map(r => parseUSNumber(r.sum)).reduce(add, 0)) }] },
      null
    ]
  }]);
  const logo: RItem[] = getReceiptImage(getState())
    ? [
      {
        type: "image",
        align: "center",
        blob: getReceiptImage(getState())
      }
    ]
    : [];

  // prettier-ignore
  const productRows = (r: ActualReportsRecord["documentRows"][number]): RTableRow[] => {
    const codeRows = [
      {
        type: "normal",
        cells: [
          { align: "left", pieces: [{ text: r.code }] },
          { align: "right", pieces: [{ text: `${parseUSNumber(r.amount)}` }] },
          { align: "right", pieces: [{ text: parseUSNumber(r.finalNetPrice).toFixed(2) }] },
          { align: "right", pieces: [{ text: (parseUSNumber(r.finalNetPrice) * parseUSNumber(r.amount)).toFixed(2) }] }
        ]
      }
    ] as RTableRow[];
    const originalNetPrice = parseUSNumber(salesDocument.rows[r.no - 1].price);
    const finalNetPrice = parseUSNumber(r.finalNetPrice);
    const savedAmount = (originalNetPrice - finalNetPrice) * parseUSNumber(r.amount);
    const titleRows = [
      ...(r.discount ? [{
        type: "normal",
        cells: [
          savedAmount
            ? { align: "right", pieces: [{ text: `AMOUNT SAVED: ${(savedAmount).toFixed(2)}` }] }
            : "colspan",
          "colspan",
          "colspan",
          { align: "left", pieces: [] }
        ]
      }] : []),
      {
        type: "normal",
        cells: [
          { align: "left", pieces: [{ text: r.title }] },
          "colspan",
          "colspan",
          "colspan"
        ]
      }
    ] as RTableRow[];
    const metadataRows = [
      ...(
        r?.jdoc?.BrazilPOS?.returnTags?.length ? [{
          type: "normal", cells: [
            {
              align: "left",
              pieces: [
                { text: "" },
                { text: "Return tag: ", meta: { size: -1 } },
                { text: unique(r?.jdoc?.BrazilPOS?.returnTags).join(", "), meta: { size: -1 } }
              ]
            },
            "colspan",
            "colspan",
            "colspan"
          ]
        }] as RTableRow[] : []
      ),
      ...(
        [
          new ErplyAttributes(salesDocument.attributes).get(`row-attribute-${salesDocument.rows[r.no - 1].stableRowID}-pnp_cfjc`)
        ]
          .map(a => {
            try {
              return JSON.parse(a);
            } catch (e) {
              return undefined;
            }
          })
          .filter(a => a)
          .flatMap(([vin, stockNo, year, make, model, cylinder, carSide, customer]) => [
            {
              type: "normal",
              cells: [
                { align: "left", pieces: [{ text: "" }, { meta: { size: -1 }, text: `${customer}    ${stockNo}` }] },
                "colspan",
                "colspan",
                "colspan"
              ]
            }, {
              type: "normal",
              cells: [
                {
                  align: "left",
                  pieces: [{ text: "" }, { meta: { size: -1 }, text: `${vin}    ${year}    ${make}    ${model}` }]
                },
                "colspan",
                "colspan",
                "colspan"
              ]
            },
            (cylinder || carSide) ? {
              type: "normal",
              cells: [
                {
                  align: "left", pieces: [{ text: "" },
                    cylinder && { meta: { size: -1 }, text: `${cylinder} cylinders` },
                    cylinder && carSide && { meta: { size: -1 }, text: `   ` },
                    carSide && { meta: { size: -1 }, text: `${carSide}` }
                  ].filter(a => a)
                },
                "colspan",
                "colspan",
                "colspan"
              ]
            } : null
          ])
          .filter(a => a) as RTableRow[]
      )
    ] as RTableRow[];

    const indents = {
      own: nestingDepths[r.no - 1] - 1,
      next: salesDocument.rows[r.no]?.stableRowID
        ? nestingDepths[r.no] - 1
        : Infinity
    };
    const rows = [
      ...codeRows,
      ...titleRows,
      ...metadataRows
    ] as RTableRow[];

    const indentAs = (rows: RTableRow[], count: number) => rows.forEach(row => {
      // eslint-disable-next-line no-param-reassign
      row.cells = nestingCells(count)(row.cells[0]).concat(row.cells.slice(1));
    });

    indentAs(codeRows, indents.own);
    indentAs(titleRows, indents.own + 2);
    indentAs(metadataRows, indents.own + 2);

    return rows;
  };


  const rewardPointSection: RItem[] = rewardPointsRecord
    ? [{
      type: "table",
      columns: [{ baseWidth: 0, weight: 1 }, { baseWidth: 0, weight: 1 }],
      rows: [
        {
          type: "normal",
          cells: [
            { align: "left", pieces: [{ text: "Loyalty PTS Ernd: " }] },
            { align: "right", pieces: [{ text: String((rewardPointsRecord as RewardPointsRecord).earnedPoints) }] }
          ]
        },
        {
          type: "normal",
          cells: [
            { align: "left", pieces: [{ text: "Loyalty PTS Bal: " }] },
            { align: "right", pieces: [{ text: String(customerRewardPoints) }] }
          ]
        }
      ]
    }] : [];
  const taxproductTaxRows = arData.documentRows.filter(isTaxProduct)
    .sort((a, b) => taxProductNames.indexOf(a.productName.toUpperCase()) - taxProductNames.indexOf(b.productName.toUpperCase()))
    .filter(row => parseUSNumber(row.finalPriceWithVAT))
    .map(row => ({
      type: "normal",
      cells: [
        { align: "left", pieces: [{ text: row.title }] },
        "colspan",
        "colspan",
        { align: "right", pieces: [{ text: formatUS(parseUSNumber(row.finalNetPrice) * parseUSNumber(row.amount)) }] }
      ]
    }));
  const totalRow = {
    type: "normal",
    cells: [
      {
        align: "left",
        pieces: [{ text: "Total: ", meta: { bold: true } }]
      },
      "colspan",
      {
        align: "right",
        pieces: [{ text: formatUS(salesDocument.total) }]
      },
      "colspan"
    ]
  };
  const taxComponentTaxRows = taxComponents.map(({ name, value }) => {
    return {
      type: "normal", cells: [
        { align: "left", pieces: [{ text: name }, { text: ":" }] },
        "colspan",
        { align: "right", pieces: [{ text: formatUS(value) }] },
        "colspan"
      ]
    };
  });
  const rawTaxRows = [{
    type: "normal", cells: [
      { align: "left", pieces: [{ text: "Tax:" }] },
      "colspan",
      { align: "right", pieces: [{ text: formatUS(salesDocument.vatTotal) }] },
      "colspan"
    ]
  }];
  const taxRows = taxComponentTaxRows.length ? taxComponentTaxRows : rawTaxRows;
  const subtotalRow = {
    type: "normal",
    cells: [
      {
        align: "left",
        pieces: [{ text: "Item Subtotal: ", meta: { bold: true } }]
      },
      "colspan",
      {
        align: "right",
        pieces: [{
          text:
            formatUS(
              arData.documentRows
                .filter(p => !isTaxProduct(p))
                .map(p => p.rowNetTotal).map(parseUSNumber)
                .reduce(add, 0)
            )
        }]
      },
      "colspan"
    ]
  };
  const youSaved = totalDiscount ? [
    {
      type: "text",
      pieces: [
        { text: `${discountLabel}: ` },
        { text: formatUS(arData.totalDiscountSum) }
      ]
    }
  ] as RItem[] : [];
  const productsTable: RTable[] = [{
    type: "table",
    columns: [
      ...Array(maxNesting)
        .fill({ baseWidth: 1, weight: 0 }),
      { baseWidth: 0, weight: 1 },
      { baseWidth: 4, weight: 0 },
      { baseWidth: 10, weight: 0 },
      { baseWidth: 10, weight: 0 }
    ],
    rows: [
      {
        type: "header",
        cells: [
          ...nestingCells(0)(
            { align: "left", pieces: [{ text: "Item #" }] }
          ),
          { align: "right", pieces: [{ text: "Qty" }] },
          { align: "right", pieces: [{ text: "Price" }] },
          { align: "right", pieces: [{ text: "Total" }] }
        ]
      },
      {
        type: "header",
        cells: [
          ...nestingCells(2)(
            { align: "left", pieces: [{ text: "Description" }] }
          ),
          "colspan",
          "colspan",
          "colspan"
        ]
      },
      ...arData.documentRows.filter(p => !isTaxProduct(p)).flatMap(productRows),
      ...[
        ...([
          subtotalRow,
          ...taxproductTaxRows,
          ...taxRows,
          { type: "invisible", cells: [{ pieces: [{ text: "" }] }] },
          totalRow
        ]).map(firstLineSectionStart),
        { type: "invisible", cells: [{ pieces: [{ text: "" }] }] },
        ...paymentsSection,
        { type: "invisible", cells: [{ pieces: [{ text: "" }] }] },
        ...creditSlipSection.map(firstLineSectionStart)
      ]
        .map(((row: RTableRow) => row?.cells?.length ? ({
          ...row,
          cells: nestingCells(0)(
            row.cells[0]
          ).concat(row.cells.slice(1))
        }) : (row)) as (any) => RTableRow)
    ]
  }];
  const footerTexts = new Set(
    products
      .map(p => conf?.footerTexts?.[p.groupID])
      .filter(a => a)
  );
  const salesDocNotes = salesDocument.notes
    ? ([
      {
        type: "text",
        align: "center",
        pieces: [{ text: "CSA notes:", meta: { underline: true } }]
      },
      {
        type: "text",
        align: "center",
        pieces: [{ text: salesDocument.notes }]
      }
    ] as RItem[])
    : [];
  const productSpecificFooterTexts = Array.from(footerTexts).flatMap(
    t =>
      ([
        {
          type: "text",
          align: "center",
          pieces: textToTaggedPieces({ text: t })
        }
      ] as RItem[]).filter(a => a)
  );
  const footerTextFromConfParam = footerTextConfig ? [
    ...footerTextConfig.split(/\n/).map(text => ({
      type: "text",
      align: "center",
      pieces: textToTaggedPieces({ text })
    }))
  ] as RItem[] : [];
  const stampArea = [
    ...Array(2).fill({ type: "text", pieces: [{ text: "" }] }),
    {
      type: "image",
      align: "center",
      blob: getReceiptStampImage(getState())
    },
    ...Array(2).fill({ type: "text", pieces: [{ text: "" }] })
  ] as RItem[];
  const qrCode = [{
    type: "qrcode",
    data: salesDocument.number,
    align: "center"
  }] as RItem[];
  const br: RTextLine = { type: "text", pieces: [{ text: "" }] };
  const nrItemsPurchased: RItem[] = [{
    type: "text",
    pieces: [{ text: `Number of items ${salesDocument.type === 'ORDER' ? 'ordered' : 'purchased'}: ` }, { text: `${arData.documentRows.filter(p => !isTaxProduct(p)).map(p => parseUSNumber(p.amount)).reduce(add, 0)}` }]
  }];
  const preFooterMessage: RItem[] =
    getPreFooterText(getState()).split(/\n/).map(text => ({
      type: "text", align: "center", pieces: textToTaggedPieces({ text })
    }));
  const customerName: RItem[] = [{
    type: "text", align: "center", pieces: [{
      text:
        (customer.customerType === "COMPANY"
            ? customer.companyName
            : `${customer.firstName} ${customer.lastName}`
        ).toUpperCase()
    }]
  }];
  const orderConfirmationTitle: RItem[] = salesDocument.type === 'ORDER' ? [
    {type: 'text', pieces: [{text: 'ORDER CONFIRMATION', meta:{size: 2}}], align: 'center'}
  ] : [];
  const orderBalance: RItem[] = salesDocument.type === 'ORDER' ? [
    {type: 'text', pieces: [{text: 'Balance due on the order: ', meta: {size: 2}}, {text: formatUS(Number(salesDocument.total) - Number(salesDocument.paid))}]}
  ] : []

  const spaced = (...items: RItem[][]) => {
    if (items.every(i => i.length === 0)) return []
    return items.flatMap(i => i).concat([br])
  }
  const printout: RItem[] = [
    ...spaced(
      logo,
      headerSection,
      customHeaderText,
      spaced(copySection),
      orderConfirmationTitle,
      headerSection2, // is table, has space after

      productsTable, // is table, has space after

      orderBalance,
      nrItemsPurchased,
      youSaved,
    ),
    ...spaced(customerName),
    ...spaced(
      rewardPointSection,
    ),
    ...spaced(
      salesDocNotes,
    ),
    ...spaced(
      preFooterMessage,
      qrCode,
      stampArea,
    ),
    ...spaced(
      productSpecificFooterTexts,
    ),
    ...spaced(
      footerTextFromConfParam,
    )
  ];

  const smallFontPrintout = deepMap((value) => {
    if (!value) return value;
    if (value.text === undefined) return value;
    const size = {
      3: 2,
      2: 1,
      1: -1,
      [-1]: -2,
      [-2]: -3,
      [-3]: -3
    }[value.meta?.size ?? 1] ?? -1;
    return {
      ...value,
      meta: {
        ...value.meta,
        size
      }
    };
  }, printout);
  return smallFontPrintout;
};
