import { Theme, ThemeOptions, createTheme } from '@material-ui/core';
import * as R from 'ramda';

export const createAugmentColorsFunction = (options: ThemeOptions) => (
  colors: Record<string, string>,
): Theme['palette'] => {
  const theme = createTheme(options);
  return R.mapObjIndexed((color, name) => {
    if (name === 'background') {
      return {
        paper: color,
        default: colors['background-accent'] ?? theme.palette.background.paper,
      };
    }
    if (name === 'background-accent') {
      return null;
    }
    if (name === 'divider') {
      return color;
    }
    return theme.palette.augmentColor({ main: color });
  }, colors);
};

export const addThemeColors = (
  themeColors: Theme['palette'],
): ((theme: ThemeOptions) => ThemeOptions) =>
  R.evolve({
    palette: R.mergeDeepRight(R.omit(['background-accent'], themeColors)),
  });

export const baseThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['foobar_m3', 'Proxima Nova', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
    caption: { fontSize: '0.875rem' },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        // Invisible border added so that buttons are outlined when CSS forced-colors media feature is enabled
        border: '1px solid rgba(0,0,0,0)',
      },
    },
    // Resize input labels for vision deficiency
    //   1: Resize the <legend> which cuts the outline to reserve space for the label
    MuiOutlinedInput: {
      root: {
        '& legend': {
          fontSize: '0.9em !important',
        },
      },
    },
    //   2: Shrink the actual label less when it's shrinking into the outline
    MuiInputLabel: {
      // Different input variants translate label differently and since it's not possible to only override scale this has to be done
      root: {
        '&$shrink': {
          transform: 'translate(0, 1.5px) scale(0.9)',
        },
      },
      outlined: {
        '&$shrink': {
          transform: 'translate(14px, -6px) scale(0.9)',
        },
      },
      filled: {
        '&$shrink': {
          transform: 'translate(12px, 10px) scale(0.9)',
        },
      },
    },
    // ------------------------------------------
  },
  props: {
    MuiBadge: {
      overlap: 'rectangular',
    },
    MuiButton: {
      disableElevation: true,
    },
  },
};
