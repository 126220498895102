import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { RootState } from 'reducers';

import { checkIfDuplicateSAINumber } from '../utils';
import { Status } from '../constants';
import { getScannedQuery } from '../redux/selectors';
import { setScannedQuery } from '../redux/actions';

export default function useSAINumber(initialSAINumber: string | undefined) {
  const [status, setStatus] = useState<Status | null>(null);
  const [SAINumber, setSAINumber] = useState(initialSAINumber);
  const scannedQuery = useSelector(getScannedQuery);
  const ref = useRef<HTMLInputElement | null>(null);
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  useEffect(() => {
    if (scannedQuery !== null) {
      // focus field, otherwise it looks weird
      ref.current?.focus();
      setSAINumber(scannedQuery);
    }
  }, [scannedQuery]);

  async function validateForDuplicates(currentSAINumber: string) {
    if (currentSAINumber === initialSAINumber) return true;

    try {
      setStatus(Status.LOADING);
      const isDuplicateSAINumber = await checkIfDuplicateSAINumber(
        currentSAINumber,
      );
      setStatus(isDuplicateSAINumber ? Status.DUPLICATE : Status.VALID);
      return !isDuplicateSAINumber;
    } catch (error) {
      setStatus(Status.FAILED_TO_VALIDATE);
      return false;
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (status !== Status.NOT_VALIDATED) setStatus(Status.NOT_VALIDATED);
    if (scannedQuery !== null) dispatch(setScannedQuery(null));
    setSAINumber(event.target.value);
  }

  const isValidSAINUmber =
    SAINumber !== undefined && SAINumber.trim().length > 0;

  function getErrorMessage() {
    if (!isValidSAINUmber) return 'SAI number can not be empty';
    if (status === Status.DUPLICATE) {
      return 'This SAI # is already used on another sale. Please enter a different #';
    }
    if (status === Status.FAILED_TO_VALIDATE) {
      return 'Failed to verify SAI number. Try again later.';
    }
    return null;
  }

  const errorMessage = getErrorMessage();

  return {
    validateForDuplicates,
    loading: status === Status.LOADING,
    isDuplicate: status === Status.DUPLICATE,
    SAINumber,
    isValidSAINUmber,
    onChange,
    errorMessage,
    ref,
  };
}
