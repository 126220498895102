import { savePluginConfig } from 'actions/Plugins';
import { PosPlugin } from 'plugins/plugin';

import { pluginID } from './constants';
import { UICustomerForm, UICustomerFormBeta } from './features/customerForm';

const estonianBusinessRegistry: PosPlugin = {
  id: pluginID,
  name: 'Estonian Business Registry Autocomplete',
  info:
    'This plugin adds data from Estonian Business Registry when creating a customer in POS.',
  keywords: ['Estonian Business Registry', 'form', 'customer'],
  getStatus: () => {
    return { type: 'valid', message: 'Ready' };
  },
  onMount: () => async dispatch => {
    // Wipe config because we no longer want to store credentials
    dispatch(
      savePluginConfig(
        estonianBusinessRegistry,
        {},
        {
          level: 'Company',
          id: '',
        },
      ),
    );
  },
  UICustomerForm,
  UICustomerFormBeta,
};

export default estonianBusinessRegistry;
