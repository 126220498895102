import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { useBreakpoints } from 'utils/hooks/UI';
import {
  getComponents,
  hasOpenModalPage,
  hasOpenModalPopup,
} from 'reducers/modalPage';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { ShortcutScope } from 'utils/hooks/keyboard/useShortcut';
import * as Forms from 'containers/Forms/Forms';

import './ModalPage.css';
import { getOnOpenModalPageHooks } from 'reducers/Plugins';

const ModalPageWrapper = ({
  isPopup,
  show = true,
  children,
  modalClassName,
  componentPath,
}) => {
  const dispatch = useDispatch();
  const { before, on, after } = useSelector(
    getOnOpenModalPageHooks(componentPath),
  );

  useEffect(() => {
    dispatch(before());
    return () => dispatch(after());
  }, []);

  const isLarge = useBreakpoints().mdplus;
  return !isPopup ? (
    <div
      className={
        isLarge ? 'modal-page-container' : 'mobile-modal-page-container'
      }
      style={{ display: show ? '' : 'none' }}
    >
      {children}
    </div>
  ) : (
    <Modal
      container={document.getElementById('modals')}
      show={true}
      onHide={previousModalPage}
      dialogClassName={modalClassName}
      animation={false}
    >
      {children}
    </Modal>
  );
};

ModalPageWrapper.propTypes = {
  isPopup: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
  modalClassName: PropTypes.string,
};

const LazyLoadedComponent = React.memo(({ component, props }) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(previousModalPage());
  const [Component, hotReloadComponent] = useState(() => lazyLoad(component));

  if (!component.component.apply) {
    const path = `../Forms/${component.component}`;
    if (module.hot) {
      module.hot.accept(path, () => {
        hotReloadComponent(() => lazyLoad(path));
      });
    }
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component onClose={onClose} {...props} />
    </Suspense>
  );
});

const lazyLoad = component => {
  // Also accept actual components
  if (component.component.apply) {
    return component.component;
  }
  return Forms[component.component];
};

const ModalPage = () => {
  const showPage = useSelector(hasOpenModalPage);
  const gridOpened = useSelector(getGridIsOpened);
  const showPopup = useSelector(hasOpenModalPopup);
  const components = useSelector(getComponents);
  const modalPages = components.filter(comp => !comp.isPopup);
  const modalPopups = components.filter(comp => comp.isPopup);

  return (
    <>
      {modalPopups.map((popup, i) => {
        return (
          <ModalPageWrapper
            key={popup.id}
            componentPath={popup.component}
            show={showPopup && i === modalPopups.length - 1}
            isPopup={true}
            modalClassName={popup.modalClassName}
          >
            <ShortcutScope
              surpress={i !== modalPopups.length - 1}
              defaultPriority={30}
            >
              <LazyLoadedComponent props={popup.props} component={popup} />
            </ShortcutScope>
          </ModalPageWrapper>
        );
      })}

      {modalPages
        .filter(() => showPage && (gridOpened || !showPopup))
        .map((page, i) => {
          return (
            <ModalPageWrapper
              key={page.id}
              componentPath={page.component}
              show={i === modalPages.length - 1}
              isPopup={!gridOpened}
              modalClassName={`${page.modalClassName} page ${i}`}
            >
              <ShortcutScope defaultPriority={20}>
                <LazyLoadedComponent props={page.props} component={page} />
              </ShortcutScope>
            </ModalPageWrapper>
          );
        })}
    </>
  );
};

export default ModalPage;
