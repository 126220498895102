import React from 'react';
import { useTranslation } from 'react-i18next';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import { IconButton, Menu, MenuItem, Theme, styled } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { getDeviceColorTheme } from 'reducers/UI/theme';
import { setColorTheme } from 'actions/UI';

const ThemeButton = styled(IconButton)<Theme>(({ theme }) => ({
  color: theme.palette.common.white,
}));

function ThemeSwitch() {
  const { t } = useTranslation(['header', 'settingsTheme']);

  const dispatch = useDispatch();
  const theme = useSelector(getDeviceColorTheme);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function openDropdown(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function closeDropdown() {
    setAnchorEl(null);
  }

  function setTheme(value) {
    dispatch(setColorTheme(value));
    closeDropdown();
  }

  return (
    <div style={{ padding: '5px' }}>
      <ThemeButton
        size="small"
        onClick={anchorEl ? closeDropdown : openDropdown}
        title={t('toggleTheme')}
      >
        <Brightness6Icon fontSize="large" />
      </ThemeButton>
      <Menu
        id="theme-dropdown"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeDropdown}
        anchorEl={anchorEl}
        style={{ transform: 'translate(-70px, 30px)' }}
      >
        {['light', 'dark', 'highContrast'].map(value => (
          <MenuItem
            key={value}
            value={value}
            selected={value === theme}
            onClick={() => setTheme(value)}
          >
            {t(`options.${value}`, { ns: 'settingsTheme' })}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default ThemeSwitch;
