import React, { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Box, Divider, Grid, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { RootState } from 'reducers';
import { Address as FormAddress } from 'types/Address';
import { addError } from 'actions/Error';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import { Address } from '../types';
import { findAddresses, retrieveAddress } from '../api';

import AddressAutocomplete from './AddressAutocomplete';

const countries = [
  { name: 'Canada', value: 'CA' },
  { name: 'United States of America', value: 'US' },
];

const AddressSearch = ({ onChange, onClose }) => {
  const { t } = useTranslation('addressForm');
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const [country, setCountry] = useState(countries[0].value);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<Address[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);

  const save = useCallback(async () => {
    if (!selectedAddress) return;
    setSaving(true);
    try {
      const fullAddress = await dispatch(retrieveAddress(selectedAddress.Id));
      const parsedAddress: Partial<FormAddress> = {
        address: fullAddress.Line1,
        address2: fullAddress.Line2,
        city: fullAddress.City,
        country: fullAddress.CountryName,
        postalCode: fullAddress.PostalCode,
        state: fullAddress.ProvinceName,
        street: fullAddress.Line1,
      };
      Object.entries(parsedAddress).forEach(([key, value]) => {
        onChange(key, value);
      });
      onClose();
    } catch (error) {
      dispatch(addError(t('lookUpAddress.alerts.failedRetrieve')));
    } finally {
      setSaving(false);
    }
  }, [t, dispatch, onChange, onClose, selectedAddress]);

  const search = useCallback(
    async (country: string, searchValue: string, lastId?: string) => {
      if (!searchValue.length) return;
      setLoading(true);
      setSearchResults([]);
      try {
        const addresses = await dispatch(
          findAddresses(country, searchValue, lastId),
        );
        setSearchResults(addresses);
      } catch (error) {
        dispatch(addError(t('lookUpAddress.alerts.failedFind')));
      } finally {
        setLoading(false);
      }
    },
    [t, dispatch],
  );

  useDebounce(() => search(country, searchValue), 400, [
    search,
    country,
    searchValue,
  ]);
  useShortcut('Enter', save, 40);

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1.25rem"
        paddingY="0.75rem"
      >
        <Box fontSize="24px" fontWeight={700}>
          {t('lookUpAddress.title')}
        </Box>

        <Box display="flex">
          <SaveButton
            disableElevation
            title={t('lookUpAddress.saveButton')}
            action={save}
            loading={saving}
            disabled={!selectedAddress || saving}
          />
          <CloseButton action={onClose} />
        </Box>
      </Box>
      <Divider />
      <Box padding="1.25rem">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              label={t('lookUpAddress.fields.country')}
              value={country}
              onChange={e => setCountry(e.target.value)}
              fullWidth
              variant="outlined"
            >
              {countries.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <AddressAutocomplete
              label={t('lookUpAddress.fields.address')}
              loadingText={t('lookUpAddress.extra.loading')}
              loading={loading}
              options={searchResults}
              noOptionsText={t('lookUpAddress.extra.noResults')}
              value={selectedAddress}
              inputValue={searchValue}
              onInputChange={e => setSearchValue(e.target.value)}
              onOptionSelect={address => {
                if (address?.Next === 'Find') {
                  search(country, searchValue, address.Id);
                  return false;
                }
                setSelectedAddress(address);
                if (!address) return false;
                setSearchValue(`${address.Text} ${address.Description}`);
                return true;
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AddressSearch;
