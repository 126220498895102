/* eslint-disable no-nested-ternary */

import * as R from 'ramda';

import { ReturnRow } from 'types/ReturnTable';

export function notUndefinedOrNull<T>(v: T | undefined | null): v is T {
  return v !== null && v !== undefined;
}

/**
 * Generates an array of numbers based on the given params
 *
 * @param a if its the only parameter will return array from 0 to a including
 * @param b if passed a will be the beginning and b will be the end of the array
 */
export const range = (a: number, b?: number): number[] => {
  const start = b === undefined ? 0 : a;
  const end = b === undefined ? a : b;
  const isAsc = start < end;
  const arrLength = Math.abs(start - end) + 1 ?? 0;
  return new Array(arrLength)
    .fill(0)
    .map((_, index) => (isAsc ? start + index : start - index));
};

export const unique = <T>(arr: T[]): T[] => Array.from(new Set(arr));

type NestItemsProps = {
  items: { [key: string]: any }[];
  options: {
    parentIndicator: string;
    idIndicator: string;
  };
};

type NestItemsType = (NestItemsProps) => NestItemsProps['items'];

/**
 * Takes shopping cart products and nests them based on their parentIndicator
 * idIndicator - placeholder for the name of the id of the item
 * parentIndicator - placeholder for the name of the parent field of the item
 *
 */
export const nestItems = (
  items: ReturnRow[],
  {
    idIndicator,
    parentIndicator,
  }: { idIndicator: string; parentIndicator: string },
): ReturnRow[] => {
  const remaining: Record<string, any> = {};

  items.forEach(p => {
    remaining[p[idIndicator]] = p;
  });

  const getProductRecursively = (products, parentID) => {
    const matching: Record<string, any>[] = [];
    const rest: Record<string, any>[] = [];

    products.forEach(pr => {
      if (String(pr[parentIndicator]) === String(parentID)) matching.push(pr);
      else rest.push(pr);
    });

    matching.forEach(p => delete remaining[p[idIndicator]]);
    return matching.map(p => ({
      ...p,
      children: getProductRecursively(rest, p[idIndicator]),
    }));
  };

  let nestedProducts = getProductRecursively(items, 0);
  while (Object.values(remaining)?.length) {
    const left = Object.values(remaining);
    nestedProducts = nestedProducts.concat(
      getProductRecursively(left, left[0][parentIndicator]),
    );
  }
  // isEven prop helps to color the background in shopping cart by group
  return nestedProducts.map((p, i) => ({ ...p, isEven: i % 2 === 0 }));
};

export const nestGroupedProducts = (products, options): ReturnRow[] =>
  nestItems(
    products.map(p => ({
      ...p,
      parentRowID: p.parentRowID ?? p.jdoc?.BrazilPOS?.parentRowID ?? 0,
    })),
    options,
  );

export const deepMap = R.curry((mapper: (any) => any, object: any): any =>
  (R.is(Array, object) ? (R.map as any) : R.mapObjIndexed)(
    (val, key, obj) =>
      R.or(R.is(Array, val), R.is(Object, val))
        ? deepMap(mapper, mapper(val))
        : mapper(val),
    object,
  ),
);

export type ValueOf<T> = T[keyof T];
export type KeysOfType<Obj, KeyType> = ValueOf<
  {
    [K in keyof Obj]: Obj[K] extends KeyType ? K : never;
  }
>;

/**
 * Polyfill/backport/implementation of the typescript 4.9 satisfies operator
 *
 * Call with a value and desired type, and this function will report a type error if the value does not match the type
 * However, unlike annotations which override type, this will keep the inferred type unchanged
 *
 * @example Regular variable assignment does not check types
 * type Status = {status: number}
 * const ok = {
 *   foo: {status:1},
 *   bar: [status:2}
 * ]
 * const not_ok = {
 *   foo: {status:1},
 *   bar: [status:2}
 *   baz: 3 // ← No type error
 * ]
 * @example Type annotations discard specific type information
 * type Status = {status: number}
 * const ok: Record<string,Status> = {
 *   foo: {status:1},
 *   bar: [status:2}
 * ]
 * const not_ok: Record<string, Status> = {
 *   foo: {status:1},
 *   bar: [status:2}
 *   baz: 3 // ← Type error as expected
 * ]
 * ok. // ← No autocomplete for keys
 * ok.nonexist.status // ← No type error
 * @example satisfies does both
 * type Status = {status: number}
 * const ok = satisfies<Record<string, Status>>()({
 *   foo: {status:1},
 *   bar: {status:2},
 * })
 * const not_ok = satisfies<Record<string, Status>>()({
 *   foo: {status:1},
 *   bar: {status:2},
 *   baz: 3 // ← Type error as expected
 * })
 * ok. // ← autocomplete for 'foo' and 'bar'
 * ok.nonexist.status // ← Type error, property 'nonexist' does not exist
 */
export const satisfies = <T>() => <U extends T>(u: U) => u;
