import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect } from 'react';

import { PosPlugin } from 'plugins/plugin';
import { getSessionKey, getUserLoggedIn } from 'reducers/Login';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';
import Icon from 'components/Icon';
import { HeaderIconButton } from 'containers/Header/components/Icon';

import { setPaymentTypeIDsToRedux } from '../rdx/actions';
import { pluginID } from '../constants';
import { Configuration } from '../types';
import { LocalStorageNSW } from '../NSWVouchers/types';
import { generateTokenAndSetToLS } from '../NSWVouchers/utils';
import OrderNotificationHeaderComponent from '../orderNotification/OrderNotificationHeaderComponent';

const WINDOW_FEATURES = 'popup,width=900,height=600';
const getToday = () => {
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  return `${day}/${month}/${year}`;
};
// 100000000375
const params = {
  productivityReport: () => {
    const params = new URLSearchParams();
    const today = getToday();
    params.set('lang', 'eng');
    params.set('print', 'report_productivity_report_new');
    params.set('date1', today);
    params.set('date2', today);
    params.set('date', today);
    params.set('balance_date', today);
    params.set('display_bundles', 'components');
    params.set('currency_id', '4');
    params.set('gift_card_sales', 'exclude');
    params.set('accounts_receivable_listed', 'by_receiver');
    return params;
  },
  salesReport: () => {
    const params = new URLSearchParams();
    const today = getToday();
    params.set('lang', 'eng');
    params.set('print', 'report_sales');
    params.set('turnover', 'sales');
    params.set('cnt', 'invoice');
    params.set('date1', today);
    params.set('date2', today);
    params.set('date', today);
    params.set('balance_date', today);
    params.set('orgper_idDat_sales', '100000000375');
    params.set('display_bundles', 'components');
    params.set('currency_id', '4');
    params.set('gift_card_sales', 'exclude');
    params.set('accounts_receivable_listed', 'by_receiver');
    return params;
  },
};
const ProductivityReport = () => {
  const user = useSelector(getUserLoggedIn);
  const warehouseID = useSelector(getSelectedWarehouseID);
  const sessionKey = useSelector(getSessionKey);

  const getUrl = useCallback(() => {
    if (!user?.loginUrl) return null;
    const urlParams = params.productivityReport();
    urlParams.set('warehouse_id', warehouseID); // warehouse_id=33000000022&
    urlParams.set('authKey', sessionKey); // authKey=8f248eb3002144166fa4a7efc663184934f2dac4129b
    return `${user.loginUrl}popup.php?${urlParams.toString()}`;
  }, [user, warehouseID, sessionKey]);

  const onClick = useCallback(() => {
    const url = getUrl();
    if (!url) return;
    window.open(url, '_blank', WINDOW_FEATURES);
  }, [getUrl]);

  return (
    <li>
      <HeaderIconButton
        size="small"
        disabled={!user?.loginUrl}
        onClick={onClick}
      >
        <Icon name="icon_documents_alt" />
      </HeaderIconButton>
    </li>
  );
};
const SalesReport = () => {
  const user = useSelector(getUserLoggedIn);
  const warehouseID = useSelector(getSelectedWarehouseID);
  const sessionKey = useSelector(getSessionKey);

  const getUrl = useCallback(() => {
    if (!user?.loginUrl) return null;
    const urlParams = params.salesReport();
    urlParams.set('warehouse_id', warehouseID);
    urlParams.set('authKey', sessionKey);
    return `${user.loginUrl}popup.php?${urlParams.toString()}`;
  }, [user, warehouseID, sessionKey]);

  const onClick = useCallback(() => {
    const url = getUrl();
    if (!url) return;
    window.open(url, '_blank', WINDOW_FEATURES);
  }, [getUrl]);

  return (
    <li>
      <HeaderIconButton
        size="small"
        disabled={!user?.loginUrl}
        onClick={onClick}
      >
        <Icon name="icon_datareport" />
      </HeaderIconButton>
    </li>
  );
};

const TafComponentHeader: PosPlugin['ComponentHeader'] = ({ children }) => {
  const configs = useSelector(
    getPluginConfigurationAtLevel<Configuration>(pluginID, 'Company', ''),
  );
  // Some functionality
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPaymentTypeIDsToRedux());
  }, [dispatch]);

  useEffect(() => {
    const lsi = localStorage.getItem('NSW'); // LocalStorageInfo
    const plsi: LocalStorageNSW = JSON.parse(lsi || '{}'); // ParsedLocalStorageInfo
    const date = new Date();
    if (
      configs?.UseIntegratedVouchers &&
      // No NSW info in LS
      (!lsi ||
        // NSW missing access token
        !plsi.access_token ||
        // NSW Token expired
        plsi.created + plsi.expires_in < date.getTime())
    ) {
      generateTokenAndSetToLS(configs);
    }
  }, [configs]);

  // And append header icons for printing
  return [
    children,
    <ProductivityReport />,
    <SalesReport />,
    <OrderNotificationHeaderComponent />,
  ];
};

export default TafComponentHeader;
