import { UPDATE_REASON_CODES, REASONS } from 'constants/reasonCodesDB';
import { TYPE_LOGOUT } from 'constants/Login';
import { RootState } from 'reducers';
import { ReasonCode } from 'types/ReasonCodes';
import { getLegacyLanguageId } from 'utils/languages';

import {
  getCurrentPosLanguage,
  getCurrentPosLanguageOverride,
  getSetting,
} from './configs/settings';
import { createOverrideSelector } from './Plugins';

type ReasonCodesState = Record<
  keyof typeof REASONS,
  Record<string, ReasonCode[]>
>;

export default (
  state = {} as ReasonCodesState,
  { type, payload }: { type: string; payload: ReasonCodesState },
) => {
  switch (type) {
    case TYPE_LOGOUT:
      return {} as ReasonCodesState;
    case UPDATE_REASON_CODES:
      return { ...state, ...payload };
    default:
      return state;
  }
};

function getReasonCodesDbBase(state: RootState) {
  return state.reasonCodesDB;
}

export const getReasonCodesDb = createOverrideSelector(
  'getReasonCodesDb',
  getReasonCodesDbBase,
);

export function getHasReasonCodes(purpose: keyof typeof REASONS) {
  return (state: RootState) => getReasonCodesDb(state)[purpose] !== undefined;
}

export function getReasonCodes(purpose: keyof typeof REASONS) {
  return (state: RootState) => {
    const activePosLanguage =
      getCurrentPosLanguageOverride(state) || getCurrentPosLanguage(state);
    const defaultLanguage = getSetting('pos_default_language')(state);
    const lang =
      getLegacyLanguageId(activePosLanguage) ??
      defaultLanguage.legacyIdentifier;
    return getReasonCodesDb(state)[purpose]?.[lang] || [];
  };
}
