import React, { FC, useEffect, useState } from 'react';
import {
  Checkbox,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import defaultTranslations from 'plugins/customerDisplay/constants/defaultTranslations';
import { getCurrentPosLanguage } from 'reducers/configs/settings';

import { Configuration } from './types';

import { getLocaleFieldsFromConfig } from './index';

export const CustomerDisplayLocaleField: FC<TextFieldProps & {
  name: string;
  setValue: (v: string | undefined) => void;
  value: string | undefined;
}> = props => {
  const {
    name,
    disabled,
    value: propsValue,
    setValue: setPropsValue,
    InputProps,
  } = props;

  const {
    [name as keyof Configuration['config']['localizationConstants']]: fieldFromCombinedLevelsConfig = '',
  } = useSelector(getLocaleFieldsFromConfig) ?? {};

  const [value, setValue] = useState(propsValue);
  useEffect(() => {
    if (propsValue !== undefined && value !== propsValue) setValue(propsValue);
  }, [propsValue, value]);

  const languageCode = useSelector(getCurrentPosLanguage);
  const elements = name?.split('.');
  const defaultValue =
    defaultTranslations[languageCode]?.[name] ??
    R.path(elements, defaultTranslations[languageCode] || {}) ??
    defaultTranslations.en?.[name] ??
    R.path(elements, defaultTranslations.en || {});

  const usingFallbackValueText =
    propsValue === undefined && fieldFromCombinedLevelsConfig !== undefined
      ? 'Using value from another configuration level'
      : `Using default from language ${languageCode}`;

  return (
    <TextField
      {...props}
      value={propsValue ?? defaultValue}
      helperText={propsValue === undefined ? usingFallbackValueText : ' '}
      disabled={disabled || propsValue === undefined}
      onChange={e => setPropsValue(e.target.value)}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <Checkbox
              checked={propsValue !== undefined}
              disabled={disabled}
              onChange={e =>
                setPropsValue(e.target.checked ? value ?? '' : undefined)
              }
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
