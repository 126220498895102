import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { usePosOnMessage, usePosSendMessage } from 'utils/hooks/useWrapper';
import { getWrapperConfig } from 'reducers/configs/settings';
import { getCafaEntry } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';

const useWrapperConfig = (active: boolean) => {
  const wrapper = {
    setConfig: usePosSendMessage('config:setConfig'),
    setPrinterConfig: usePosSendMessage('config:setPrintingConfig'),
  };
  const wrapperSettings = useSelector(getWrapperConfig);
  const wrapperPrintingSettings = useSelector(
    getCafaEntry<any,any,{ enabled: boolean; config: any }>(
      'wrapper',
      INTEGRATION_TYPES.printer,
    ),
  )?.value?.config;
  useEffect(() => {
    if (active) {
      wrapper.setConfig(wrapperSettings);
      wrapper.setPrinterConfig(wrapperPrintingSettings);
    }
  }, [wrapperSettings, wrapper.setConfig, active, wrapperPrintingSettings]);

  usePosOnMessage('config:getConfig', () => wrapper.setConfig(wrapperSettings));
  usePosOnMessage('config:getPrintingConfig', () =>
    wrapper.setPrinterConfig(wrapperPrintingSettings),
  );
};

export default useWrapperConfig;
