import React, { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  InputAdornment,
  TableContainer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useTranslation } from 'react-i18next';

import { round } from 'utils';
import { Product } from 'types/Product';
import { modalPages as mp } from 'constants/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { addError, addWarning, dismissAll } from 'actions/Error';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getSetting, getShowPricesWithTax } from 'reducers/configs/settings';
import { getProductsUniversal } from 'actions/productsDB';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { PluginComponent } from 'plugins';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '750px',
    marginTop: '1rem',
  },
  table: {
    '& th, & td': { padding: '0.75rem' },
    '& th': {
      fontWeight: 700,
    },
  },
});

const totalProductsInStock = (warehouses: Product['warehouses'] = {}) =>
  Object.values(warehouses).reduce(
    (total, wh) => total + Number(wh.totalInStock),
    0,
  );

const totalProductsInStore = (
  warehouses: Product['warehouses'] = {},
  warehouseID: string,
) => Number(warehouses[warehouseID]?.totalInStock || 0);

const TableBodyRow: React.FC<{
  row: Product;
  action: () => void;
  warehouseID: string;
  withTax: boolean;
  [key: string]: any;
}> = ({ row, action, warehouseID, withTax, ...rest }) => (
  <PluginComponent hookname="UIStockAndPriceListTableRow">
    <TableRow
      key={row.productID}
      onClick={action}
      style={{ cursor: 'pointer' }}
      {...rest}
    >
      <TableCell data-testid="code">{row.code}</TableCell>
      <TableCell data-testid="name">{row.name}</TableCell>
      <TableCell data-testid="total-in-store">
        {totalProductsInStore(row.warehouses, warehouseID)}
      </TableCell>
      <TableCell data-testid="total-in-stock">
        {totalProductsInStock(row.warehouses)}
      </TableCell>
      <TableCell data-testid="price">
        {round(withTax ? row.priceListPriceWithVat : row.priceListPrice, 2) ||
          ''}
      </TableCell>
    </TableRow>
  </PluginComponent>
);

const StockAndPrice: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('product');
  const dispatch: ThunkDispatch<any, unknown, Action> = useDispatch();
  const { pointOfSaleID: posID, warehouseID } = useSelector(getSelectedPos);
  const { clientID } = useSelector(getSelectedCustomerID) || {};
  const shouldUsePriceWithVAT = useSelector(getShowPricesWithTax);
  const getItemsFromFirstPriceListOnly = useSelector(
    getSetting('pos_allow_selling_only_from_pricelist1'),
  )
    ? 1
    : 0;

  const [products, setProducts] = useState<Product[]>([]);
  const [value, setValue] = useState('');

  const searchSale = useCallback(
    async searchVal => {
      if (searchVal.trim()) {
        dispatch(
          addWarning(t('stockAndPrice.alerts.loading', { search: searchVal })),
        );
        try {
          // gets products with their stock availability
          dispatch(
            getProductsUniversal(
              {
                getStockInfo: 1,
                active: 1,
                recordsOnPage: 100,
                getReplacementProducts: 1,
                getRelatedProducts: 1,
                orderBy: 'name',
                orderByDir: 'asc',
                getPriceListPrices: 1,
                getContainerInfo: 1,
                getAllLanguages: 1,
                getRecipes: 1,
                type: 'PRODUCT,MATRIX,BUNDLE,ASSEMBLY',
                getPriceCalculationSteps: 1,
                clientID,
                posID,
                searchNameIncrementally: searchVal.trim(),
                searchCodeFromMiddle: 1,
                getItemsFromFirstPriceListOnly,
              },
              // do not take meta (price and stock), they come from API
              { localFirst: false, withMeta: false },
            ),
          )
            .then(({ productsDict }) => {
              // get prices for the products
              return doClientRequest<{
                productID: number;
                specialPrice: number;
                specialPriceWithVAT: number;
              }>({
                request: 'getProductPrices',
                productIDs: Object.keys(productsDict).join(),
                warehouseID,
              }).then(productPrices =>
                // update each product with the price list price
                productPrices.map(pp => ({
                  ...productsDict[pp.productID],
                  priceListPrice: pp.specialPrice,
                  priceListPriceWithVat: pp.specialPriceWithVAT,
                })),
              );
            })
            .then(products => {
              // save the products to state
              setProducts(products);
              dispatch(dismissAll());
            });
        } catch (err) {
          dispatch(dismissAll());
          dispatch(
            addError(t('validation:genericError'), { selfDismiss: true }),
          );
          console.error('Failed to search for sales', err);
        }
      } else {
        dispatch(
          addWarning(t('stockAndPrice.alerts.searchRequired'), {
            selfDismiss: true,
            dismissible: true,
          }),
        );
      }
    },
    [clientID, posID],
  );

  const handleChange = e => {
    const { value } = e.target;
    setValue(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    searchSale(value);
  };
  const onKeyDown = e => {
    if (e.key === 'Enter') {
      searchSale(value);
    }
  };

  const close = () => {
    dispatch(dismissAll());
    dispatch(closeModalPage());
  };

  const selectSale = productItem => {
    dispatch(
      openModalPage({
        component: mp.stockAndPriceItem,
        props: {
          productItem,
        },
      }),
    );
  };

  return (
    <div data-testid="stock-and-price">
      <Modal.Header>
        <Modal.Title>
          <Box fontSize={24} fontWeight={700}>
            {t('stockAndPrice.title')}
          </Box>
        </Modal.Title>
        <div className="stock-and-price-action-buttons">
          <CloseButton action={close} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="product-search">
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              aria-label="Product name or code"
              placeholder={t('stockAndPrice.fields.search', {
                context: 'placeholder',
              })}
              type="text"
              onKeyDown={onKeyDown}
              value={value}
              onChange={handleChange}
              data-testid="name-or-code-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className="search-button"
                    onClick={() => searchSale(value)}
                    data-testid="search-btn"
                  >
                    <i className="flip-horizontal  icon_search" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </form>
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            className={classes.table}
            data-testid="results-table"
          >
            <TableHead data-testid="headings">
              <PluginComponent hookname="UIStockAndPriceListTableHeaderRow">
                <TableRow>
                  <TableCell data-testid="code">
                    {t('stockAndPrice.headers.code')}
                  </TableCell>
                  <TableCell data-testid="product">
                    {t('stockAndPrice.headers.product')}
                  </TableCell>
                  <TableCell data-testid="store-qty">
                    {t('stockAndPrice.headers.storeQty')}
                  </TableCell>
                  <TableCell data-testid="qty">
                    {t('stockAndPrice.headers.qty')}
                  </TableCell>
                  <TableCell data-testid="total">
                    {t('stockAndPrice.headers.total')}
                  </TableCell>
                </TableRow>
              </PluginComponent>
            </TableHead>
            <TableBody>
              {!!products.length &&
                products.map((r: any) => (
                  <TableBodyRow
                    key={r.productID}
                    row={r}
                    warehouseID={warehouseID}
                    action={() => selectSale(r)}
                    withTax={shouldUsePriceWithVAT}
                    data-testid="product-row"
                    data-test-key={r.productID}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal.Body>
    </div>
  );
};

export default StockAndPrice;
