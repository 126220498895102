import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import { getSetting } from 'reducers/configs/settings';
import useProducts from 'utils/hooks/useProducts';
import { getImages } from 'services/CDN';
import { getProductImages } from 'utils';
import { getClientCode } from 'reducers/Login';
import { addSizeToUrl, getImageUrl } from 'services/CDN/utils';

type Product = ReturnType<typeof useProducts>['products'][number];
type Params = {
  enabled?: boolean;
};

function getErplyImages(product: Product) {
  return getProductImages(product).map(image => ({
    xs: image.xs,
    sm: image.md,
    md: image.md,
    lg: image.lg,
  }));
}

async function getCdnImages(
  productId: Product['productID'],
  clientCode: string,
) {
  const images = await getImages({ productId, isDeleted: false });
  const imageUrl = await getImageUrl(clientCode);

  return images.map(image => {
    const baseUrl = `${imageUrl}/${image.key}`;
    return {
      xs: addSizeToUrl(baseUrl, 100),
      sm: addSizeToUrl(baseUrl, 200),
      md: addSizeToUrl(baseUrl, 600),
      lg: addSizeToUrl(baseUrl, 800),
    };
  });
}

/**
 * Returns a list of URLs where the given product's images are hosted
 * When loading from Erply API, this returns a successful asyncstate immediately
 * When loading from CDN api or both, an async fetch is performed
 *
 * When disabled via options.loading=false, a non-loading, non-error state is returned
 */
export default function useProductImages(
  product: Product,
  { enabled = true }: Params = {},
) {
  const imageSource = useSelector(getSetting('pos_images_source'));
  const clientCode = useSelector(getClientCode);

  const productId = product?.productID;

  return useAsync(async () => {
    if (!productId) throw new Error('Product id missing');
    if (!enabled) return null;

    switch (imageSource) {
      case 'cdn': {
        return getCdnImages(productId, clientCode);
      }
      case 'both': {
        const cdnImages = await getCdnImages(productId, clientCode);
        const erplyImages = getErplyImages(product);
        return [...cdnImages, ...erplyImages];
      }
      default:
      case 'erply': {
        return getErplyImages(product);
      }
    }
  }, [imageSource, productId, clientCode, enabled]);
}
