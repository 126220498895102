/* eslint-disable no-console */
import { v4 as uuidv4 } from 'uuid';

import {
  getCardPayments,
  getCardPaymentsForIntegration,
} from 'reducers/Payments';
import {
  deletePayment,
  markForProcessing,
  unmarkFromProcessing,
} from 'actions/Payments';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import { getClientCode } from 'reducers/Login';
import { getErrorMessage, withWaitingForTerminal } from 'paymentIntegrations';

import printVerifone from './printing';
import { getVerifoneApi } from './requests';
import * as oldAPI from './requests/verifoneRequestsV1';

export const title = 'Verifone Terminal Interface';

export const initPayments = ({
  enableButtons,
  isCurrentSaleAReturn,
  resolvePayments,
  updateMessage,
  beforeDocSave,
  beforeDocDelete,
  formatCurrency,
}) => async (dispatch, getState) => {
  const clientCode = getClientCode(getState());
  const printReciept = (...props) => dispatch(printVerifone(...props));

  enableButtons(['console', 'stop']);

  const processPayments = async () => {
    // Is `verifone` the correct integration?
    const cardPayments = getCardPaymentsForIntegration('verifone')(getState());
    const shouldVoid = cardPayments.every(cp => cp.paid && cp.shouldProcess);
    const verifoneApi = await getVerifoneApi();

    let error = false;

    // conditional requests
    const makeRequest = payment => {
      if (error) {
        throw new Error(error);
      }
      if (payment.paid && payment.shouldProcess === true) {
        updateMessage(
          `Voiding transaction #${payment.attributes.refNo}: ${formatCurrency(
            payment.amount,
          )}`,
        );
        const amount = String(payment.amount).replace(/-/g, '');
        return verifoneApi.requestVoid({
          amount,
          referenceNumber: payment.attributes.refNo,
          timestamp: payment.attributes.dateTime,
          clientCode,
          requestID: uuidv4(),
        });
      }
      if (!payment.paid) {
        if (isCurrentSaleAReturn || Number(payment.amount) < 0) {
          const amount = String(payment.amount).replace(/-/g, '');
          updateMessage(`Card refund: ${formatCurrency(amount)}...`);
          return verifoneApi.requestReturn({
            amount,
            clientCode,
            requestID: uuidv4(),
          });
        }
        const payload = { ...payment, clientCode, requestID: uuidv4() };

        updateMessage(`Card payment: ${formatCurrency(payment.amount)}...`);
        return verifoneApi.requestSale(payload);
      }
      return Promise.resolve();
    };

    // response processing
    const processPayment = (p, r) => {
      const genericMsg = `${r.transactionType} #${
        p.attributes ? p.attributes.refNo : r.referenceNumber
      }: ${formatCurrency(r.approvedAmount)} - success!`;

      if (r.transactionType === 'REVERSAL') {
        updateMessage(genericMsg);
        beforeDocDelete(p.key);
      } else if (
        r.referenceNumber &&
        r.approvedAmount &&
        Number(r.approvedAmount) !== 0
      ) {
        updateMessage(genericMsg);
        if (!p.paid) {
          beforeDocSave({
            key: p.key,
            type: 'CARD',
            amount:
              r.transactionType === 'REFUND'
                ? -Number(r.approvedAmount)
                : Number(r.approvedAmount),
            cardType: r.cardHolder,
            // As per savePayment documentation {@link https://learn-api.erply.com/requests/savepayment}, we should be saving only the last 4 digits of the card number
            cardNumber: r.cardNumber.trim().slice(-4),
            paid: true,
            attributes: {
              authCode: r.authCode,
              refNo: r.referenceNumber,
              CreditOrDebit: r.cardHolder,
              expirationDate: '', // is this needed/legal?
              dateTime: r.dateTime,
              isNFC: r.entryMode === 'NFC',
            },
          });
        } else if (p.paid && p.shouldProcess === true) {
          beforeDocDelete(p.key);
        }
      } else {
        error = `Something went wrong...`;
        updateMessage(`Something went wrong...`);
        console.error('Failed to process payment with verifone', r);
      }
    };

    // actually process all the payments
    await cardPayments
      .filter(p => (shouldVoid ? p.paid : !p.paid))
      .reduce(
        (pr, p) =>
          pr.then(acc =>
            dispatch(
              withWaitingForTerminal(async () => {
                try {
                  const res = await makeRequest(p);

                  if (!res || !res.data) {
                    throw new Error(
                      'Verifone transaction failed: No response from MS',
                    );
                  }

                  if (res.data) {
                    // v1 successful responses don't have resultCode, thus fallback is safe
                    if (!Number(res.data.resultCode ?? 0)) {
                      processPayment(p, res.data);
                    } else {
                      let msg = res.data.statusMessage;
                      // Check if the error came from trying to void a refund
                      if (
                        res.config?.url === 'reversal' &&
                        res.data.statusMessage === 'Reversal/Refund not allowed'
                      ) {
                        msg = msg.concat(
                          '\n\nNB! Cannot cancel a refunded payment. Please finalize the return and start a new sale to take payment from customer',
                        );
                      }
                      if (!msg?.length) {
                        if (res.data.transactionStatus === 'Canceled') {
                          msg = 'Verifone transaction has been cancelled';
                        } else {
                          msg = 'Verifone transaction failed';
                        }
                      }
                      throw new Error(msg);
                    }
                    if (res.data.customerReceipt) {
                      printReciept(res.data.customerReceipt);
                    }
                  } else if (res.message) {
                    throw new Error(res.message);
                  }
                  return [...acc, res];
                } catch (err) {
                  const message = getErrorMessage(
                    err,
                    err?.message ?? 'Verifone transaction failed',
                  );
                  error = message;
                  updateMessage(message);
                  return [...acc, err];
                }
              }),
            ),
          ),
        Promise.resolve([]),
      );

    if (error) {
      enableButtons(['console', 'retry', 'back']);
    } else {
      resolvePayments();
    }
  };
  await processPayments();
};

// void'em'all
export const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPaymentsForIntegration('verifone')(getState());
  cardPayments.forEach(({ key, paid }) =>
    paid
      ? dispatch(markForProcessing({ key }))
      : dispatch(deletePayment({ key })),
  );

  const paymentsToProcess = getCardPaymentsForIntegration('verifone')(
    getState(),
  );
  if (paymentsToProcess.length) {
    await dispatch(openTerminalIntegration());
  }
};

// debug
const consolePayments = params => async () => {
  // Used in button explicitly for logging to console, therefore ok
  // eslint-disable-next-line no-console
  console.log('verifone payments', params.cardPayments);
};

/**
 * stop/abort
 * @param {import('paymentIntegrations/types').CardPaymentHooks} params
 */
const stopProcess = params => async () => {
  // Need to ping the MS to find out if would need to show alert later
  const usev2 = await oldAPI.getUseV2();
  // Pass the received variable to avoid sending extra info request
  const verifoneApi = await getVerifoneApi(usev2);

  try {
    const cancelResponse = await verifoneApi.cancelRequest();
    // For V2 don't update any messages - it sends 'cancelTransaction' request, which cancels the payment out and we need to show the response from the payment request
    if (!usev2) {
      params.updateMessage(
        cancelResponse?.data?.statusMessage ??
          'Transaction was successfully cancelled',
      );
    }
  } catch (e) {
    params.updateMessage(e.message);
    throw e;
  }
};

/**
 * Go back to payments list
 * @param {import('paymentIntegrations/types').CardPaymentHooks} params
 */
const returnBack = params => async (dispatch, getState) => {
  const cardPayments = getCardPayments(getState()).filter(
    payment => payment.paid && payment.shouldProcess === true,
  );
  cardPayments.forEach(p => dispatch(unmarkFromProcessing({ key: p.key })));
  params.rejectPayments();
};

/**
 * X button
 * @param {import('paymentIntegrations/types').CardPaymentHooks} params
 */
export const cancelPayments = params => async dispatch => {
  await dispatch(stopProcess(params));
  await dispatch(returnBack(params));
};

// buttons
export const functions = [
  {
    actionOnClick: consolePayments,
    text: 'Console payments',
    name: 'console',
    variant: 'light',
  },
  {
    actionOnClick: initPayments,
    text: 'Retry',
    name: 'retry',
    vaeriant: 'secondary',
  },
  {
    actionOnClick: stopProcess,
    text: 'Stop transaction',
    name: 'stop',
    variant: 'danger',
  },
  {
    actionOnClick: returnBack,
    text: 'Return back',
    name: 'back',
    variant: 'warning',
  },
];
