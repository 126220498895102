/* eslint-disable import/prefer-default-export */
import { batch } from 'react-redux';
import i18next from 'i18next';

import {
  saveCompanyInfo,
  saveWarehouseInfo,
  saveAddressInfo,
} from 'services/ErplyAPI/settings';
import { modalPages } from 'constants/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';

import { getWarehouses } from './warehouses';
import { getCompanyInfo } from './Login';
import { addWarning, addError } from './Error';
import { loadPointsOfSale } from './PointsOfSale/loadPointsOfSale';

export function saveCompanyAndLocation(
  company,
  warehouse,
  address
) {
  return async dispatch => {
    try {
      if (!company.name.replace(/\s/g, '')) {
        dispatch(
          addWarning(i18next.t('validation:company.nameRequired'), {
            selfdDismiss: 1500,
          }),
        );
      } else {
        await saveCompanyInfo(company);
        await saveAddressInfo(address);
        await saveWarehouseInfo(warehouse);
        batch(() => {
          dispatch(getCompanyInfo());
          dispatch(loadPointsOfSale());
          dispatch(getWarehouses());
          dispatch(openModalPage({ component: modalPages.Settings }));
        });
      }
    } catch (e) {
      dispatch(addError(String(e), { selfDismiss: 2500 }));
    }
  };
}
