import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { createSelector } from 'reselect';
import { Box } from '@material-ui/core';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { getAllWarehouses } from 'reducers/warehouses';
import Loader from 'components/Loader';
import { getProducts } from 'services/ErplyAPI';
import {
  getCurrencyFormatter,
  getSetting,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { PluginComponent } from 'plugins';

const WarehouseStockLevel = ({ warehouse, productInWarehouse }) => {
  const stock = productInWarehouse?.[warehouse.warehouseID];
  const asNumber = a => (a !== undefined ? Number(a) : '?');
  return (
    <tr data-testid="stock-row" key={`stockIn${warehouse.warehouseID}`}>
      <td data-testid="warehouse-name">{warehouse.name}</td>
      <td data-testid="total-stock">{asNumber(stock?.totalInStock)}</td>
      <td data-testid="reserver">{asNumber(stock?.reserved)}</td>
      <td data-testid="free">{asNumber(stock?.free)}</td>
    </tr>
  );
};

/**
 * Filters out offline inventory warehouses based on setting 'hide_offline_inventory_for_stock_lookup'
 */
const getWarehousesForProductStockLevel = createSelector(
  getAllWarehouses,
  getSetting('hide_offline_inventory_for_stock_lookup'),
  (whs, shouldHideOfflineWh) =>
    shouldHideOfflineWh
      ? whs.filter(wh => Number(wh?.isOfflineInventory) === 0)
      : whs,
);

const ProductInformation = ({ productItem }) => {
  const { t } = useTranslation('product');
  const format = useSelector(getCurrencyFormatter);

  const { value: override, error, loading } = useAsync(async () => {
    if (!productItem?.productID) return null;
    const response = await getProducts({
      productID: productItem.productID,
      from: 0,
      count: 1,
      getStockInfo: 1,
    });
    return response.records[0];
  }, [productItem.productID]);

  const shouldUsePriceWithVAT = useSelector(getShowPricesWithTax);

  const warehouses = useSelector(getWarehousesForProductStockLevel);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(previousModalPage());
  };

  return (
    <Box data-testid="product-info">
      <Box
        sx={{
          margin: '20px 25px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h4>{t('productView.title')}</h4>
        <Box display="flex">
          <SaveButton
            title={t('common:add', { context: 'new' })}
            action={() =>
              dispatch(
                addProduct({
                  productID: productItem.productID,
                  amount: 1,
                  manualDiscount: 0,
                }),
              )
            }
          />
          <CloseButton action={() => close()} />
        </Box>
        
      </Box>
      <Table className="mt-2">
        <tbody>
          <tr>
            <td>{t('relatedProducts.headers.name')}</td>
            <td data-testid="name">{productItem?.name}</td>
          </tr>
          <tr>
            <td>{t('relatedProducts.headers.code')}</td>
            <td data-testid="code">{productItem?.code}</td>
          </tr>
          {productItem?.code2 && (
            <tr>
              <td>{t('relatedProducts.headers.ean')}</td>
              <td data-testid="code2">{productItem?.code2}</td>
            </tr>
          )}
          <tr>
            <td>{t('relatedProducts.headers.price')}</td>
            <td data-testid="price">
              {format(
                shouldUsePriceWithVAT
                  ? productItem?.priceWithVat
                  : productItem?.price,
              )}
            </td>
          </tr>
          <tr>
            <td>{t('relatedProducts.headers.priceListPrice')}</td>
            <td data-testid="price-list-price">
              {format(
                shouldUsePriceWithVAT
                  ? productItem?.priceListPriceWithVat
                  : productItem?.priceListPrice,
              )}
            </td>
          </tr>
          {productItem?.groupName && (
            <tr>
              <td>{t('relatedProducts.headers.group')}</td>
              <td data-testid="group-name">{productItem?.groupName}</td>
            </tr>
          )}
          {productItem?.categoryName && (
            <tr>
              <td>{t('relatedProducts.headers.category')}</td>
              <td data-testid="category">{productItem?.categoryName}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <PluginComponent hookname="UIStockDataTable">
        <Box
          sx={{
            margin: '20px 25px',
          }}
        >
          <h4>{t('relatedProducts.headers.stockInfo.title')}</h4>
        </Box>
        {!error ? (
          <Loader show={loading} block>
            <Table>
              <thead>
                <tr>
                  <th>{t('relatedProducts.headers.stockInfo.warehouse')}</th>
                  <th>{t('relatedProducts.headers.stockInfo.total')}</th>
                  <th>{t('relatedProducts.headers.stockInfo.reserved')}</th>
                  <th>{t('relatedProducts.headers.stockInfo.free')}</th>
                </tr>
              </thead>
              <tbody>
                {warehouses.map(warehouse => (
                  <WarehouseStockLevel
                    key={warehouse.warehouseID}
                    warehouse={warehouse}
                    productInWarehouse={override?.warehouses}
                  />
                ))}
              </tbody>
            </Table>
          </Loader>
        ) : (
          <h5>{t('relatedProducts.stockInfo.failedToFetch')}</h5>
        )}
      </PluginComponent>
    </Box>
  );
};

export default ProductInformation;
