import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { getProductsUniversal } from 'actions/productsDB';
import { PosPlugin } from 'plugins/plugin';
import { createConfirmation } from 'actions/Confirmation';
import { prepareGiftCardData } from 'actions/integrations/printer/preparePrintoutData';
import { printGiftCard } from 'actions/integrations/printer';

import * as documentation from './documentation.md';

/**
 * If the id is changed plugin configuration in CAFA will no longer be associated
 * with this plugin. Customers then will have to re-enable the plugin or might
 * not get confused and think that plugin stopped working.
 * To avoid this inconvenience plugin id was left unchanged after plugin was renamed.
 */
const pluginID = 'retailCare';

const giftCardPrinting: PosPlugin = {
  id: pluginID,
  name: 'Gift card printing',
  keywords: ['retail', 'care', 'retail care', 'gift cards', 'print'],
  infoMDUrl: documentation,
  onSaveSalesDocument: {
    after: (_props, { salesDocument, requests }) => async (
      dispatch: ThunkDispatch<unknown, unknown, Action>,
    ) => {
      const codes = requests
        .filter(({ requestName }) => requestName === 'saveGiftCard')
        .map(({ code }) => code)
        .reverse();

      const productIDs = salesDocument.rows.map(row => row.productID);
      const { products } = await dispatch(getProductsUniversal({ productIDs }));
      const giftCards = products.filter(({ isGiftCard, isRegularGiftCard }) =>
        Boolean(isGiftCard || isRegularGiftCard),
      );

      giftCards.forEach(async ({ name, productID }, index) => {
        const hasConfirmed = await new Promise<boolean>((resolve, reject) => {
          return dispatch(
            createConfirmation(resolve, reject, {
              title: 'Giftcard print',
              body: `Do you want to print ${name} gift card?`,
            }),
          );
        });
        if (hasConfirmed) {
          const data = await dispatch(
            prepareGiftCardData(productID, codes[index]),
          );
          dispatch(printGiftCard(data));
        }
      });
    },
  },
};

export default giftCardPrinting;
