import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getConfiguration } from '../rdx';

type UIReturnOrderProductProps = {
  row: any;
  isSelected: any;
  toggleReturnRow: any;
};

const UIReturnOrderProduct: FC<UIReturnOrderProductProps> = ({
  row,
  isSelected,
  toggleReturnRow,
  children,
}) => {
  const { envFeeName, admissionTaxName } = useSelector(getConfiguration);

  const isEnvFee = row.itemName === envFeeName;
  const isAdmTax = row.itemName === admissionTaxName;
  const dummyAction = {
    stopPropagation: () => undefined,
  } as React.SyntheticEvent;

  useEffect(() => {
    // Env fee never selected
    if (isEnvFee) {
      if (isSelected) toggleReturnRow(dummyAction);
    }
    // Adm tax never selected
    if (isAdmTax) {
      if (isSelected) toggleReturnRow(dummyAction);
    }
  }, [isEnvFee, isAdmTax, isSelected, toggleReturnRow]);

  if (isAdmTax) return null;
  if (isEnvFee) return null;
  return <>{children}</>;
};

export default UIReturnOrderProduct;
