export enum TaxExemptionRecordIndicator {
  /**
   * No exemptions
   * @property Types []
   * @property TaxAuthorityLevels []
   */
  NONE = '00',
  /**
   * Federal excise tax exempt
   * @property Types [00]
   * @property TaxAuthorityLevels [0(Federal)]
   */
  FEDEX = '01',
  /**
   * State taxes exempt
   * @property Types [...allState]
   * @property TaxAuthorityLevels [2(County), 3(City), 4(Local), 5(Reporting agency)]
   */
  STATE = '02',
  /**
   * Federal excise tax and State taxes exempt
   * @property Types [00, ...allState]
   * @property TaxAuthorityLevels [1(State)]
   */
  FEDEX_STATE = '03',
  /**
   * Local taxes exempt
   * @note All tax types are exempted for the "local" tax authority levels
   * @property Types [...all]
   * @property TaxAuthorityLevels [2(County), 3(City), 4(Local), 5(Reporting agency)]
   */
  LOCAL = '04',
  /**
   * Federal excise tax and local taxes exempt
   * @property Types [00, ...allLocal]
   * @property TaxAuthorityLevels [2(County), 3(City), 4(Local)]
   */
  FEDEX_LOCAL = '05',
  /**
   * State and local taxes exempt
   * @property Types [...all]
   * @property TaxAuthorityLevels [2(County), 3(City), 4(Local)]
   */
  STATE_LOCAL = '06',
  /**
   * Federal excise tax and state and local taxes exempt
   * @property Types [00, ...allState, ...allLocal]
   * @property TaxAuthorityLevels [1(State), 2(County), 3(City), 4(Local), 5(Reporting agency)]
   */
  FEDEX_STATE_LOCAL = '07',
  /**
   * All federal level taxes exempt
   * @note All tax types are exempted for the "Federal" tax authority (i.e. FET, FUSF, FTRS)
   * @property Types [00, 35, 60]
   * @property TaxAuthorityLevels [0(Federal)]
   */
  ALLFED = '09',
  /**
   * All unit based taxes exempt
   * @note This exemption type excludes all tax types at all tax authority levels that are listed as a unit based fee.
   * @property Types see notes
   * @property TaxAuthorityLevels see notes
   */
  UNITALL = '10',
  /**
   * Unit based E911 exempt
   * @note Exempts state and local unit based E911 fees.
   * @property Types [06, 33]
   * @property TaxAuthorityLevels [..anyLevel]
   */
  UNITE911 = '11',
  /**
   * Only apply unit based fees
   * @note This exemption type excludes all tax types at all tax authority levels that are not listed as a unit based fee.
   * @property Types see notes
   * @property TaxAuthorityLevels see notes
   */
  UNITFEES = '12',
  /**
   * Local right of way fees (ROW) exempt
   * @property Types [24]
   * @property TaxAuthorityLevels [2(County), 3(City), 4(Local)]
   */
  ROW = '13',
  /**
   * All state and local E911s exempt
   * @note Exempts state and local E911 fees - both unit based and percentage of revenue.
   * @property Types [06, 33]
   * @property TaxAuthorityLevels [...anyLevel]
   */
  STATE_LOCAL_UNITE911 = '14',
  /**
   * Only apply federal universal service fund (USF)
   * @note All taxes except Federal USF (tax type 35) are exempt
   * @property Types see note
   * @property TaxAuthorityLevels see note
   */
  ONLYUSF = '19',
  /**
   * All PUC fees exempt
   * @property Types [08]
   * @property TaxAuthorityLevels [...anyLevel]
   */
  PUC = '20',
  /**
   * All state and local gross receipts tax (GRT) exempt
   * @property Types [28, 32]
   * @property TaxAuthorityLevels [...anyLevel]
   */
  STATE_LOCALGRT = '21',
  /**
   * All state universal service fund (USF) fees exempt
   * @property Types [26]
   * @property TaxAuthorityLevels [...anyLevel]
   */
  STATEUSF = '22',
  /**
   * State Gross Receipts Tax and Universal Service Fund Exempt
   * @note
   * @property Types [26,28,32]
   * @property TaxAuthorityLevels [Any Level]
   */
  STATEGROSS_USF = '23',
  /**
   * Federal Universal Service Fund (USF) Exempt
   * @note
   * @property Types [35]
   * @property TaxAuthorityLevels [0(Federal)]
   */
  FEDUSF = '24',
  /**
   * Federal Excise, Federal Universal Service Fund (USF) and State Sales Tax Exempt
   * @note
   * @property Types [00,01,35]
   * @property TaxAuthorityLevels [Any Level]
   */
  FEDEX_FEDUSF_STATE = '25',
  /**
   * State Excise Tax Exempt (includes FL CST)
   * @note
   * @property Types [27]
   * @property TaxAuthorityLevels [1(State)]
   */
  STATEEX = '26',
  /**
   * Federal Excise, Federal USF and Local Sales Tax Exempt
   * @note
   * @property Types [00,02,03,04,05]
   * @property TaxAuthorityLevels [350(Federal),2(County),3(City),4(Local),5(Reporting Agency)]
   */
  FEDEX_FEDUSF_LOCAL = '27',
  /**
   * Federal USF and State Local Sales Tax Exempt
   * @note
   * @property Types [01,02,03,04,05,35]
   * @property TaxAuthorityLevels [Any Level]
   */
  FEDUSF_STATE = '28',
  /**
   * Federal USF and Local Sales Tax Exempt
   * @note
   * @property Types [02,03,04,05,35]
   * @property TaxAuthorityLevels [0(Federal),2(County),3(City),4(Local),5(Reporting Agency)]
   */
  FEDUSF_LOCAL = '29',
  /**
   * State Excise and Local Telecommunications Tax Exempt
   * @note  This is primarily used for exempting the Florida State and Local Telecommunications Services Tax.
   * @property Types [27,37]
   * @property TaxAuthorityLevels [1(State),2(County),3(City),4(Local),5(Reporting Agency)]
   */
  STATEEX_LOCALTELCOM = '30',
  /**
   * State Level Sales Tax Exempt
   * @note
   * @property Types [01]
   * @property TaxAuthorityLevels [1(State)]
   */
  STATELEVEL = '31',
  /**
   * State and Local Sales Tax Exempt
   * @note
   * @property Types [01,02,03,04,05]
   * @property TaxAuthorityLevels [Any Level]
   */
  STATE_LOCALSALES = '32',
  /**
   * Federal Excise, Federal USF, State and Local Sales Tax Exempt
   * @note
   * @property Types [00,01,02,03,04,05,35]
   * @property TaxAuthorityLevels [0(Federal),1(State),2(County),3(City),4(Local),5(Reporting Agency)]
   */
  FEDEX_FEDUSF_STATE_LOCALSALES = '33',
  /**
   * Federal TRS (Federal Regulatory Assessment Fee) Exempt
   * @note
   * @property Types [60]
   * @property TaxAuthorityLevels [0(Federal),]
   */
  FEDTRS = '35',
  /**
   * Value Added Tax (VAT)
   * @note  This for any international VAT transaction (not including Canada as they have separate tax types).
   * @property Types [51]
   * @property TaxAuthorityLevels [Any level]
   */
  VAT = '36',
  /**
   * Canadian Provincial Service Taxes (PST) Exempt
   * @note
   * @property Types [42]
   * @property TaxAuthorityLevels [Any Level]
   */
  PST = '37',
  /**
   * Canadian Harmonized Service Taxes (HST) Exempt
   * @note
   * @property Types [41]
   * @property TaxAuthorityLevels [Any Level]
   */
  HST = '38',
  /**
   * Canadian Goods and Services Taxes (GST) Exempt
   * @note
   * @property Types [40]
   * @property TaxAuthorityLevels [Any Level]
   */
  GST = '39',
  /**
   * Local Utility Users Tax Exempt
   * @note
   * @property Types [16]
   * @property TaxAuthorityLevels [Any Level]
   */
  LOCUTILUSER = '41',
  /**
   * State Excise Tax Exempt and Local Surcharge Exempt
   * @note  This is primarily used for exempting the NY State Excise and local MTA Surcharge on Excise tax.
   * @property Types [17,27]
   * @property TaxAuthorityLevels [1(State),4(Local)]
   */
  STATEEX_LOCALSURCHARGE = '42',
  /**
   * Universal Lifeline Telephone Service Surcharge (ULTS) exempt
   * @note
   * @property Types [22]
   * @property TaxAuthorityLevels [Any Level]
   */
  ULTS = '43',
  /**
   * City Level Taxes Exempt
   * @note
   * @property Types [All]
   * @property TaxAuthorityLevels [3(City)]
   */
  CITYLEVEL = '44',
  /**
   * State Franchise Fee
   * @note
   * @property Types [23]
   * @property TaxAuthorityLevels [1(State)]
   */
  STATEFRANCHISE = '45',
  /**
   * Local Franchise Fee
   * @note
   * @property Types [36]
   * @property TaxAuthorityLevels [2(County),3(City),4(Local),5(Reporting Agency)]
   */
  LOCALFRANCHISE = '46',
  /**
   * County Level Taxes Exempt
   * @note
   * @property Types [All]
   * @property TaxAuthorityLevels [2(County)]
   */
  COUNTYLEVEL = '47',
  /**
   * Los Angeles Communications Users Tax Exempt
   * @note  Only for Geocode starting with "US06037", which is all localities in Los Angeles that apply the CUT.
   * @property Types [61]
   * @property TaxAuthorityLevels [See Note]
   */
  LACOMMUSERS = '61',
  /**
   * Non-unit taxes exempt except for E911
   * @note  Used to disable all revenue based taxes with the exception of E911. In addition to unit based fees, only state-level, revenue based E911 would apply.
   * @property Types [Many]
   * @property TaxAuthorityLevels [Any Level]
   */
  NONUNIT_WITHOUT_E911 = '62',
  /**
   * All Taxes Exempt - Apply no tax or fees
   * TA  Tax Adjustment  All  All  All tax types for all levels are exempted.
   * @note  All tax types for all levels are exempted.
   * @property Types [All]
   * @property TaxAuthorityLevels [All]
   */
  ALL = '99',
}
