import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';
import { getCurrentSalesDocument } from 'reducers/sales';
import { getDefaultCurrency } from 'reducers/configs/settings';

import { getTaxReturnDocument, getTaxTotals } from '../redux';

const Flexbox: React.FC = ({ children }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    {children}
  </Box>
);

const UITableTotalRow: PosPlugin['UITableTotalRow'] = ({ children }) => {
  const taxReturnDocument = useSelector(getTaxReturnDocument);
  const document = useSelector(getCurrentSalesDocument);
  const currencyCode = useSelector(getDefaultCurrency);
  const taxTotals = useSelector(getTaxTotals);

  return taxTotals && taxReturnDocument ? (
    <Box padding="1.5rem">
      {Object.entries(taxTotals).map(([percentage, total]) => (
        <Flexbox key={percentage}>
          <Typography variant="h4">{percentage}%</Typography>
          <Typography variant="h4">
            {total.toFixed(2)} {currencyCode}
          </Typography>
        </Flexbox>
      ))}
      <Flexbox>
        <Typography variant="h4">Total</Typography>
        <Typography variant="h4">
          {document.vatTotal} {currencyCode}
        </Typography>
      </Flexbox>
    </Box>
  ) : (
    children
  );
};

export default UITableTotalRow;
