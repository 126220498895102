import React, { useEffect } from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { format } from 'date-fns';

import { RootState } from 'reducers';
import { getPayments } from 'reducers/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { PosPlugin } from 'plugins/plugin';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { addSuccess } from 'actions/Error';
import { PaymentObj } from 'paymentIntegrations/types';
import { sleep } from 'utils';

import {
  CancelChargeResponse,
  ChargeResponse,
  GivexCard,
  RefillResponse,
} from '../types';
import {
  GivexProcessor,
  GivexProcessorChargePayment,
  GivexProcessorRefillPayment,
} from '../components/GivexProcessor';
import { getGivexConfiguration } from '../configuration/Configuration';

const waitForUniqueSecond = (() => {
  let promise = Promise.resolve(undefined as any);
  return () => {
    const p = promise;
    promise = promise.then(() => sleep(1));
    return p;
  };
})();
/** Fields to delete when a payment is voided */
const pmtFields = [
  'cardNumber',
  'paymentServiceProvider',
  'certificateBalance',
  'transactionType',
  'statusCode',
  'statusMessage',
  'expirationDate',
  'cardType',
  'GIVEX',
];
/** Payment data to save on successful payment */
const toPmt = (card: GivexCard, response: ChargeResponse | RefillResponse) => ({
  cardNumber: card.cardNo
    .trim()
    .slice(0, -4)
    .replace(/\S/g, '*')
    .concat(card.cardNo.trim().slice(-4)),
  paymentServiceProvider: 'givex',
  certificateBalance: response.certificateBalance,
  transactionType: response.transactionType,
  statusCode: response.statusCode,
  statusMessage: response.statusMessage,
  expirationDate: response.certificateExpirationDate,
  cardType: 'GIVEX',
  dateTime: format(new Date(), 'MM/dd/yyyy @ HH:mm:ss'),
  ...((response as any).referenceNumber
    ? {
        referenceNumber: (response as any).referenceNumber,
      }
    : {}),
  GIVEX: {
    card,
  },
});

/**
 * UI when givex is processing card payments.
 * Displays all givex payments and allows the user to enter card details and pay/unpay each of them
 * When all payments are paid, resolved automatically
 * When all payments are unpaid, user needs to cancel manually
 */
const CardPaymentUI = ({
  payments,
  resolvePayments,
  rejectPayments,
  isVoid = false,
}) => {
  const pmts = useSelector(getPayments);
  const { displayName } = useSelector(getGivexConfiguration);
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  /* Resolve automatically when all givex payments are paid */
  const allProcessed = payments.every(
    isVoid ? p => !pmts[p.key].paid : p => pmts[p.key].paid,
  );
  useEffect(() => {
    if (allProcessed) {
      resolvePayments();
      dispatch(
        addSuccess(
          isVoid
            ? `${displayName} payment(s) voided`
            : `${displayName} payment(s) succeeded`,
        ),
      );
    }
  }, [allProcessed, dispatch, displayName, isVoid, resolvePayments]);

  const isChargeResponse = (
    res: ChargeResponse | RefillResponse,
  ): res is ChargeResponse => !!(res as ChargeResponse).sum;

  const handlePayment = (
    payment: Partial<PaymentObj>,
    card: GivexCard,
    res?: ChargeResponse | RefillResponse,
  ) => {
    // @ts-ignore
    const amount = isChargeResponse(res ?? {}) ? res.sum : payment.amount;
    // Workaround: Ensure no previous payments have been saved with the current 'added' value
    return waitForUniqueSecond()
      .then(() =>
        dispatch(
          setPayment({
            key: payment.key,
            paid: true,
            amount:
              Math.sign(Number(payment.amount)) * Math.abs(Number(amount)),
            forceAmount: true, // core POS wants to reset payment to be positive
            ...(res
              ? toPmt(card, res)
              : {
                  GIVEX: {
                    card,
                  },
                }),
          }),
        ),
      )
      .finally(() => waitForUniqueSecond());
    // ^ Reserve a second *again* aftwards,
    // because setPayment is asynchronous and it's possible that the second we 'waited' for isn't the one that got marked on the payment
  };

  const handleCancel = (
    payment: Partial<PaymentObj>,
    cancelResponse?: CancelChargeResponse,
  ) =>
    dispatch(
      setPayment({
        key: payment.key,
        amount: Number(cancelResponse?.sum) || payment.amount,
        paid: false,
        forceAmount: true, // core POS wants to reset payment to be positive
        ...R.fromPairs(pmtFields.map(f => [f, undefined])),
      }),
    );

  return (
    <GivexProcessor close={rejectPayments}>
      {payments.map(payment => {
        const isCharge = payment.amount > 0;

        return isCharge ? (
          <GivexProcessorChargePayment
            paid={pmts[payment.key].paid ? pmts[payment.key].GIVEX.card : false}
            initialCard={pmts[payment.key].GIVEX?.card}
            amount={Math.abs(payment.amount)}
            onPaid={(card, _, chargeRes) =>
              handlePayment(payment, card, chargeRes)
            }
            onCancelled={cancelResponse =>
              handleCancel(payment, cancelResponse)
            }
          />
        ) : (
          <GivexProcessorRefillPayment
            paid={
              pmts[payment.key].paid ? pmts[payment.key].GIVEX?.card : false
            }
            amount={Math.abs(payment.amount)}
            onPaid={(card, refillRes) =>
              handlePayment(payment, card, refillRes)
            }
            onCancelled={cancelResponse =>
              handleCancel(payment, cancelResponse)
            }
            initialCard={pmts[payment.key].GIVEX?.card}
            paymentKey={payment.key}
          />
        );
      })}
    </GivexProcessor>
  );
};
export const customPaymentIntegration: PosPlugin['customPaymentIntegration'] = {
  id: 'givex',
  customComponent: CardPaymentUI,
  voidPayments: ({ cardPayments }) => async dispatch =>
    new Promise((resolve, reject) =>
      dispatch(
        openModalPage({
          // TODO Need to check what the provided type is and update openModalPage's input type
          // @ts-ignore
          component: CardPaymentUI,
          isPopup: true,
          modalClassName: 'card-payment-ui',
          props: {
            integration: customPaymentIntegration,
            payments: cardPayments,
            resolvePayments: resolve,
            rejectPayments: reject,
            isVoid: true,
          },
          groupID: modalPages.cardPaymentUI,
          replace: false,
        }),
      ),
    ).finally(() => dispatch(previousModalPage())),
};
