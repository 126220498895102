import { getPluginConfiguration } from 'reducers/Plugins';

import { pluginID } from '../constants';

export type Configuration = {
  giftCardTypeID: number;
};

export const getConfiguration = getPluginConfiguration<Configuration>(pluginID);

export { default as Config } from './Configuration';
