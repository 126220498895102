import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import { Customer } from 'types/Customer';
import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { addWarning } from 'actions/Error';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import CloseButton from 'components/CustomButtons/CloseButton';

const DuplicateCustomersSelectionForm: FC<{ customers: Customer[] }> = ({
  customers,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('scannerSelection');

  const selectCustomer = customer => {
    dispatch(
      addWarning(
        t('customerSelected', {
          fullName: customer.fullName,
        }),
        {
          dismissible: true,
          selfDismiss: true,
        },
      ),
    );
    dispatch(setCustomer({ data: customer }));
    dispatch(closeModalPage());
  };

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">
            Multiple matching customers found
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <CloseButton action={() => dispatch(previousModalPage())} />
        </Box>
      </Box>
      <Divider style={{ marginBottom: '1em' }} />
      <Box>
        <TableRow style={{ textAlign: 'left' }}>
          <TableCell style={{ width: '500px' }}>
            <span>{t('customers.headers.fullName')}</span>
          </TableCell>
          <TableCell style={{ width: '500px' }}>
            <span>{t('customers.headers.groupName')}</span>
          </TableCell>
          <TableCell style={{ width: '300px' }}>
            <span>{t('customers.headers.birthday')}</span>
          </TableCell>
          <TableCell style={{ width: '100px' }}>
            <span>{t('select')}</span>
          </TableCell>
        </TableRow>
      </Box>
      <Box>
        {customers.map(customer => (
          <TableRow
            style={{ textAlign: 'start' }}
            key={customer.customerID}
            data-testid="scanningDuplicateProduct"
            data-test-key={customer.customerID}
          >
            <TableCell style={{ width: '500px' }}>
              <span>{customer.fullName}</span>
            </TableCell>
            <TableCell style={{ width: '500px' }}>
              <span>{customer.groupName}</span>
            </TableCell>
            <TableCell style={{ width: '300px' }}>
              <span>{customer.birthday}</span>
            </TableCell>
            <TableCell style={{ width: '100px' }}>
              <UIButton
                action={() => selectCustomer(customer)}
                text={t('select')}
              />
            </TableCell>
          </TableRow>
        ))}
      </Box>
    </Box>
  );
};

export default DuplicateCustomersSelectionForm;
