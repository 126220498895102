import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { getCFRState } from '../rdx';

const UICustomReturnCTA: PosPlugin['UICustomReturnCTA'] = ({ children }) => {
  const { isOnlineReturn } = useSelector(getCFRState);

  if (!isOnlineReturn) return children;

  return R.pipe(
    React.Children.toArray,
    R.map(R.over(R.lensPath(['props', 'disabled']), R.T)),
  )(children);
};

export default UICustomReturnCTA;
