import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';

import { NoteButtonsComponent } from './NoteButtonsComponent';

const NoteButtonsModal = ({
  onClose,
  notes,
  currentNote,
  onClick,
  previewCount,
}) => {
  const { t } = useTranslation('shoppingCart');
  return (
    <>
      <Modal.Header>
        <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
          {t('noteButtons.title')}
        </span>

        <div style={{ flex: 1 }} />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <NoteButtonsComponent
          notes={notes}
          previewCount={previewCount}
          currentNote={currentNote}
          onClick={onClick}
        />
      </Modal.Body>
    </>
  );
};

export default NoteButtonsModal;
