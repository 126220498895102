import debug from 'debug';

import { UrlControl } from 'services/UrlControl/UrlControl';
import * as c from 'constants/persistence';

const baseLog = debug('UrlControl').extend('behaviour');

if (UrlControl.debug) {
  debug.enable(UrlControl.debug);
  baseLog('Set debug enabled namespaces to', UrlControl.debug);
}

if (UrlControl.autoSelectPos !== undefined) {
  if (
    'pointOfSaleID' in UrlControl.autoSelectPos ||
    'warehouseID' in UrlControl.autoSelectPos
  ) {
    localStorage.removeItem(c.REDUX_WAREHOUSEID);
    localStorage.removeItem(c.REDUX_WAREHOUSE);
    localStorage.removeItem(c.REDUX_POSID);
    localStorage.removeItem(c.REDUX_POS);
    baseLog('Deleted previously selected warehouse/pos from localstorage');
  }
}
