/* eslint-disable camelcase */
/* eslint-disable import/no-named-as-default-member */
import { batch } from 'react-redux';
import i18next from 'i18next';
import { createSelector } from 'reselect';
import * as R from 'ramda';

import * as API from 'services/ErplyAPI/api';
import * as AccountAPI from 'services/AccountAdmin';
import { proxy } from 'services/shared';
import { resetDB } from 'services/DB';
import { ErplyApiError } from 'services/ErplyAPI/core/apiErrors';
import {
  GET_COMAPNY_INFO,
  TYPE_EDIT_EMPLOYEE_DATA,
  TYPE_LOGIN,
  TYPE_AUTO_LOGIN,
  TYPE_LOGIN_OFFLINE,
  TYPE_LOGOUT,
  TYPE_LOGOUT_ERROR,
  TYPE_LOGOUT_OFFLINE,
  TYPE_LOGOUT_SOFT,
  TYPE_LOGOUT_SOFT_START,
  TYPE_LOGOUT_START,
  TYPE_SETLOADING,
  TYPE_SETUSERRIGHTS,
  TYPE_SET_USER_SALES_DATA,
} from 'constants/Login';
import {
  REDUX_CLIENTCODE,
  REDUX_CUSTOMER_REGISTRY_TOKEN,
  REDUX_CUSTOMER_REGISTRY_URL,
  REDUX_OFFLINELOGOUTPIN,
  REDUX_PIN,
  REDUX_POS,
  REDUX_POSID,
  REDUX_SERVICE_ENDPOINTS,
  REDUX_SESSIONKEY,
  REDUX_SETTINGS,
  REDUX_USER,
  REDUX_USERNAME,
  REDUX_WAREHOUSE,
  REDUX_WAREHOUSEID,
  REDUX_JWT,
} from 'constants/persistence';
import {
  getClientCode,
  getHasRightToMakeASale,
  getSessionKey,
  getUserLoggedIn,
  getUserRightsAreFetched,
  getUsername,
} from 'reducers/Login';
import { getWarehouses, getHomeStores } from 'actions/warehouses';
import { setSettings } from 'actions/configs';
import { erplyAlertOnError } from 'store/middleware/erplyAlertIntegration';
import { addError, addWarning } from 'actions/Error';
import { getSetting } from 'reducers/configs/settings';
import { SO } from 'services/DB/types';
import { urlEncode } from 'utils';
import { getEndpointForService } from 'reducers/configs/serviceEnpoints';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import {
  getConnectionHealth,
  getIsForcedOfflineMode,
} from 'reducers/connectivity/connection';
import { createConfirmation } from 'actions/Confirmation';
import { ErplyApiUser } from 'types/User';
import {
  GetUserRightsParams,
  GetUserRightsResponse,
  ModuleKey,
} from 'services/ErplyAPI/core/types';
import { UserRight } from 'services/AccountAdmin/types';
import { posSendMessage } from 'utils/hooks/useWrapper';
import { modalPages } from 'constants/modalPage';
import { getModalPopups } from 'reducers/modalPage';
import { RootDispatch, RootGetState } from 'reducers';

import { openModalPage } from './ModalPage/openModalPage';
import { selectPos } from './PointsOfSale/selectPos';
import { loadPointsOfSale } from './PointsOfSale/loadPointsOfSale';

export function setLogin({
  user,
  username,
  clientCode,
  pin,
}: {
  user: ErplyApiUser;
  username: string;
  clientCode: string;
  pin?: string;
}) {
  return {
    type: TYPE_LOGIN,

    payload: {
      user,
      username,
      clientCode,
      pin,
    },
  };
}

export function setAutoLogin(payload: {
  user: ErplyApiUser;
  username: string;
  clientCode: string;
  pin?: string;
  password?: string;
}) {
  return {
    type: TYPE_AUTO_LOGIN,
    payload,
  };
}

export function editEmployee({
  user,
  username,
  clientCode,
  password,
}: {
  user: ErplyApiUser;
  username: string;
  clientCode: string;
  password?: string;
}) {
  return {
    type: TYPE_EDIT_EMPLOYEE_DATA,

    payload: {
      user,
      username,
      clientCode,
      password,
    },
  };
}

export function setUserSalesData(payload) {
  return {
    type: TYPE_SET_USER_SALES_DATA,
    payload,
  };
}

function setLoading(v) {
  return {
    type: TYPE_SETLOADING,
    payload: v,
  };
}
function setUserRights(v) {
  return {
    type: TYPE_SETUSERRIGHTS,
    payload: v,
  };
}
function moduleWithFallback(
  mod: UserRight,
  fallbackModule: GetUserRightsResponse['modules'][ModuleKey],
) {
  const { edit, add, view, delete: del, sectionName, ...rest } = mod;
  return {
    edit: edit ?? fallbackModule.edit,
    add: add ?? fallbackModule.add,
    view: view ?? fallbackModule.view,
    delete: del ?? fallbackModule.delete,
    ...rest,
  };
}

/**
 * Fetch the user rights from Erply API and set them to Redux
 */
export function fetchUserRightsFromAPIsAndSetToState(
  params: GetUserRightsParams = {},
) {
  return async dispatch => {
    const [userRights] = await API.getUserRights(params);
    const userRightModules = await AccountAPI.getUserRights()
      .then(res =>
        res.reduce((obj, module) => {
          return {
            ...obj,
            // temporary workaround; fixes issue where AccountAdmin API and Erply API return different user permissions for POS user
            [module.sectionName]: moduleWithFallback(
              module,
              userRights.modules[module.sectionName],
            ),
          };
        }, {}),
      )
      .catch(err => {
        console.log('Failed to fetch Account Admin user rights: ', err);
        return userRights.modules;
      });
    const finalUserRights = {
      ...userRights,
      modules: R.mergeDeepRight(userRights.modules, userRightModules),
    };
    dispatch(setUserRights(finalUserRights));
    return finalUserRights;
  };
}

/**
 * Fetch the user rights from Erply API and display a prompt on failure
 */
export function getUserRights(params: GetUserRightsParams = {}) {
  return async dispatch => {
    try {
      return dispatch(fetchUserRightsFromAPIsAndSetToState(params));
    } catch (e) {
      return dispatch(
        createConfirmation(
          () => {
            // Calls the same action recursively until it succeeds
            return dispatch(getUserRights(params));
          },
          null,
          {
            title: i18next.t('alerts:errors.failedToFetchUserRights', {
              context: 'title',
            }),
            body: i18next.t('alerts:errors.failedToFetchUserRights', {
              context: 'body',
            }),
          },
        ),
      );
    }
  };
}

/**
 * Attempt to log in with the given cc/uname/pass combination
 * If successful, save the returned login/session credentials in redux and load user rights, configs, points of sale, etc.
 *
 * Resolves only when all the fetches are complete
 */
export function attemptLogin(
  clientCode: string,
  username: string,
  password: string,
) {
  return erplyAlertOnError('login', async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const results = await API.loginWithPass({
        clientCode,
        username,
        password,
      });
      localStorage.setItem(REDUX_CLIENTCODE, JSON.stringify(clientCode));

      if (results[0].customerRegistryURLs.length) {
        localStorage.setItem(
          REDUX_CUSTOMER_REGISTRY_URL,
          results[0].customerRegistryURLs[0].url,
        );
        localStorage.setItem(
          REDUX_CUSTOMER_REGISTRY_TOKEN,
          results[0].customerRegistryURLs[0].token.toString(),
        );
      } else {
        localStorage.removeItem(REDUX_CUSTOMER_REGISTRY_URL);
        localStorage.removeItem(REDUX_CUSTOMER_REGISTRY_TOKEN);
      }
      posSendMessage('auth:login')();

      localStorage.setItem(REDUX_JWT, results[0].token);

      dispatch(setLogin({ user: results[0], username, clientCode }));
      await dispatch([
        getUserRights(),
        setSettings(),
        // setObjects(), // TODO: This hangs for some accounts maybe
        loadPointsOfSale(),
        getWarehouses(),
        getHomeStores(),
      ]);
      return results[0];
    } catch (e) {
      if (e instanceof ErplyApiError) {
        switch (e.errorCode) {
          case 1051:
            e.message = i18next.t('validation:ErplyAPI.error.credentials');
            throw e;
          default:
          // Do nothing
        }
      }
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  });
}

export function attemptCookieLogin(
  clientCode: string,
  sessionKey?: string,
) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const params = {
        request: 'getSessionKeyUser',
        clientCode,
        partnerKey: process.env.REACT_APP_PARTNER_KEY,
        sessionKey,
      };
      if (!sessionKey) delete params.sessionKey;

      const response = await window
        .fetch(`https://${clientCode}.erply.com/api/`, {
          credentials: 'include', // same-origin won't work in localhost
          // maybe won't work in production either
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          body: urlEncode(params),
        })
        .then(res => res.json())
        .then(res => res.records[0]);
      localStorage.setItem(REDUX_CLIENTCODE, JSON.stringify(clientCode));
      localStorage.setItem(REDUX_SESSIONKEY, JSON.stringify(response.sessionKey));
      localStorage.setItem(REDUX_USER, JSON.stringify(response));
      localStorage.setItem(REDUX_USERNAME, JSON.stringify(response.userName));

      if (response.customerRegistryURLs.length) {
        localStorage.setItem(
          REDUX_CUSTOMER_REGISTRY_URL,
          response.customerRegistryURLs[0].url,
        );
        localStorage.setItem(
          REDUX_CUSTOMER_REGISTRY_TOKEN,
          response.customerRegistryURLs[0].token,
        );
      } else {
        localStorage.removeItem(REDUX_CUSTOMER_REGISTRY_URL);
        localStorage.removeItem(REDUX_CUSTOMER_REGISTRY_TOKEN);
      }

      posSendMessage('auth:login')();

      dispatch(
        setLogin({
          user: response,
          username: response.username,
          clientCode,
        }),
      );
      await dispatch([
        getUserRights(),
        setSettings(),
        // setObjects(), // TODO: This hangs for some accounts maybe
        loadPointsOfSale(),
        getWarehouses(),
        getHomeStores(),
      ]);
    } catch (e) {
      if (e instanceof ErplyApiError) {
        switch (e.errorCode) {
          case 1051:
            e.message = i18next.t('validation:ErplyAPI.error.credentials');
            throw e;
          default:
          // Do nothing
        }
      }
      throw e;
    } finally {
      dispatch(setLoading(false));
    }
  };
}

/**
 * Fetch company info and save it in redux
 * Resolves when done fetching
 */
export function getCompanyInfo() {
  return async dispatch => {
    dispatch({ type: GET_COMAPNY_INFO.START });
    try {
      const [company] = await API.getCompanyInfo();
      dispatch({ type: GET_COMAPNY_INFO.SUCCESS, payload: company });
    } catch (err) {
      console.error('Failed to load company info', err);
    }
  };
}

const getShowOfflinePrompts = createSelector(
  state => getConnectionHealth(state),
  state => getSetting('touchpos_allow_offline_login')(state),
  (health, allowOffline) => allowOffline && !health,
);
const translate = i18next.getFixedT(null, 'offlineLogin');
async function showOfflineLogoutPrompt(dispatch) {
  return new Promise((resolve, reject) => {
    dispatch(
      createConfirmation(resolve, reject, {
        title: translate('onLogout.title'),
        body: `${translate('onLogout.description')}\n\n${translate(
          'onLogout.question',
        )}`,
        confirmText: translate('onLogout.buttons.ok'),
        cancelText: translate('onLogout.buttons.cancel'),
      }),
    );
  });
}
async function showOfflineLockPrompt(dispatch) {
  return new Promise((resolve, reject) => {
    dispatch(
      createConfirmation(resolve, reject, {
        title: translate('onLock.title'),
        body: translate('onLock.description'),
        inputs: [
          {
            name: 'offlineLoginPin',
            type: 'password',
            title: translate('onLock.fields.pin'),
          },
        ],
      }),
    );
  });
}

export function askForPassword({
  clientCode,
  username,
}: {
  clientCode?: string;
  username?: string;
}) {
  return async (dispatch, getState) => {
    const openedModalPages = getModalPopups(getState());
    const alreadyOpen = openedModalPages.some(
      omp => omp.component === modalPages.PasswordInput,
    );
    // do not open the session expiry if it's aleady open
    if (alreadyOpen) {
      return;
    }

    dispatch(
      openModalPage({
        // @ts-ignore
        groupID: 'sessionExpiryPasswordInput',
        component: modalPages.PasswordInput,
        isPopup: true,
        props: { clientCode, username },
      }),
    );
  };
}
/**
 * Log out and erase all session data from SSO
 * This is the only secure form of logout
 */
export function hardLogout() {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onHardLogout')(
      getState(),
    );
    try {
      if (getShowOfflinePrompts(getState())) {
        const userConfirmed = await dispatch(showOfflineLogoutPrompt).then(
          () => true,
          () => false,
        );
        if (!userConfirmed) return;
      }
      await dispatch(before({}));
      dispatch({ type: TYPE_LOGOUT_START });
      // Disable sessionkey
      const clientCode = getClientCode(getState());
      const endpoint = getEndpointForService('auth')?.url;
      if (endpoint) {
        const sk = getSessionKey(getState());
        await window.fetch(`${proxy}${endpoint}v1/session`, {
          method: 'DELETE',
          headers: { clientCode, sessionKey: sk },
        });
      }
      // await sso.endSession();
      localStorage.removeItem(REDUX_POSID);
      localStorage.removeItem(REDUX_POS);
      localStorage.removeItem(REDUX_USER);
      localStorage.removeItem(REDUX_WAREHOUSEID);
      localStorage.removeItem(REDUX_WAREHOUSE);
      // localStorage.removeItem(REDUX_CLIENTCODE); // Note: PBIB-1922 maintain clientcode on logout
      localStorage.removeItem(REDUX_USERNAME);
      localStorage.removeItem(REDUX_SETTINGS);
      localStorage.removeItem(REDUX_SESSIONKEY);
      // Deletes any kind of cached passwords, which came from previous version
      localStorage.removeItem('REDUX-password');
      localStorage.removeItem(REDUX_PIN);
      localStorage.removeItem(REDUX_OFFLINELOGOUTPIN);
      localStorage.removeItem('lastTimestamp');
      localStorage.removeItem(REDUX_SERVICE_ENDPOINTS);
      posSendMessage('auth:hard-logout')();
      if (clientCode) {
        await resetDB(clientCode, SO.SHARED.NAME);
      }
      await dispatch(on({}, {}));
      // alert('login.js:220');
      // console.error('window.location.href = `${LOGIN_URL}login/erply?redirectUrl=${window.origin}&disableAutoLogin=1`;')
      // window.location.href = `${LOGIN_URL}login/erply?redirectUrl=${window.origin}&disableAutoLogin=1`;
      dispatch({ type: TYPE_LOGOUT });
      dispatch(selectPos(null));
      await dispatch(after({}, null));
    } catch (e) {
      const err = e as Error;
      dispatch(addError(err.message));
      dispatch({ type: TYPE_LOGOUT_ERROR });
    }
  };
}

/**
 * Pretend to log out, but keep the sessionKey in SSO
 */
export function softLogout({
  resetPOS = true,
  path = '/erply',
}: { resetPOS?: boolean; path?: string } = {}) {
  return async (
  dispatch,
  getState,
) => {
  const { before, on, after } = getPluginLifecycleHook('onSoftLogout')(
    getState(),
  );
  if (getShowOfflinePrompts(getState())) {
    return dispatch(showOfflineLockPrompt).then(
      res =>
        dispatch({
          type: TYPE_LOGOUT_OFFLINE,
          payload: { pin: res.data.offlineLoginPin ?? '' },
        }),
      // User cancelled locking of the POS
      e => undefined,
    );
  }
  if (!getConnectionHealth(getState())) {
    const isForcedOfflineMode = getIsForcedOfflineMode(getState());
    return dispatch(
      addError(
        i18next.t('offlineLogin:cannotLockOffline', {
          context: isForcedOfflineMode ? 'forceOffline' : '',
        }),
        { selfDismiss: 6000, dismissible: true },
      ),
    );
  }
  await dispatch(before({ resetPOS, path }));
  dispatch({ type: TYPE_LOGOUT_SOFT_START });
  await dispatch(on({ resetPOS, path }, {}));
  localStorage.removeItem('lastTimestamp');
  posSendMessage('auth:soft-logout')();
  dispatch({ type: TYPE_LOGOUT_SOFT });
  return dispatch(after({ resetPOS, path }, {}));
};
}


/**
 * Attempts to log in the user with a pin code
 * This only works if the user has already logged in and is only softly logged out
 * @param pin the pin code to attempt login with
 */
export function attemptPinLogin(pin) {
  return erplyAlertOnError('login', async (dispatch, getState) => {
    const clientCode = getClientCode(getState());
    const sessionKey = getSessionKey(getState());
    const username = getUsername(getState());
    try {
      dispatch({ type: TYPE_LOGIN_OFFLINE, payload: { pin } });
      const [user] = await API.loginWithPin({
        clientCode,
        sessionKey,
        cardCode: pin,
      });
      posSendMessage('auth:login')();
      if (user.userID === getUserLoggedIn(getState()).userID) {
        dispatch(setLogin({ user, pin, clientCode, username }));
        return dispatch([
          getUserRights(),
          setSettings(),
          // setObjects(), // TODO: This hangs for some accounts maybe
          loadPointsOfSale(),
          getWarehouses(),
          getHomeStores(),
        ]);
      }
      return dispatch(attemptCookieLogin(clientCode, user.sessionKey));
    } catch (e) {
      if (e instanceof ErplyApiError) {
        switch (e.errorCode) {
          case 1010:
            await i18next.loadNamespaces('validation');
            e.message = i18next.t('validation:ErplyAPI.error.pin.missing');
            break;
          case 1051:
            await i18next.loadNamespaces('validation');
            e.message = i18next.t('validation:ErplyAPI.error.pin.invalid');
            break;
          default:
          // Do nothing
        }
      }
      throw e;
    }
  });
}

/** Attempt to log in to the demo user */
export function attemptDemoLogin(country, email) {
  return erplyAlertOnError('login', async dispatch => {
    dispatch(setLoading(true));
    try {
      const data = await API.createDemoUser({ country, email });
      const { clientCode, username, password } = data[0];
      await dispatch(attemptLogin(clientCode, username, password));
      await dispatch([getUserRights(), loadPointsOfSale()]);
    } finally {
      dispatch(setLoading(false));
    }
  });
}

/** Attempt to register and log in with a new user */
export function attemptSignup(
  countryCode,
  email,
  firstName,
  lastName,
  companyName,
  phone,
  website,
  password,
  nrStores,
) {
  return erplyAlertOnError('login', async dispatch => {
    try {
      dispatch(setLoading(true));
      const data = await API.signUp({
        countryCode,
        email,
        firstName,
        lastName,
        companyName,
        phone,
        website,
        password,
        nrStores,
      });
      const { clientCode, username, password: passwd } = data[0];
      batch(async () => {
        await dispatch(attemptLogin(clientCode, username, passwd));
        return dispatch([loadPointsOfSale(), getWarehouses(), getHomeStores()]);
      });
    } finally {
      dispatch(setLoading(false));
    }
  });
}

export function checkRightToMakeSale() {
  return async (dispatch: RootDispatch, getState: RootGetState) => {
    // If rights are not present in RDX
    if (!getUserRightsAreFetched(getState())) {
      // Re-fetch them
      try {
        await dispatch(fetchUserRightsFromAPIsAndSetToState());
      } catch (e) {
        dispatch(
          addError(
            i18next.t('alerts:errors.failedToFetchUserRights', {
              context: 'title',
            }),
          ),
        );
        throw new Error(
          i18next.t('alerts:errors.failedToFetchUserRights', {
            context: 'title',
          }),
        );
      }
    }
    // If user rights failed to re-fetch, throw error
    if (!getUserRightsAreFetched(getState())) {
      dispatch(
        addError(
          i18next.t('alerts:errors.failedToFetchUserRights', {
            context: 'title',
          }),
        ),
      );
      throw new Error(
        i18next.t('alerts:errors.failedToFetchUserRights', {
          context: 'title',
        }),
      );
    }
    // Rights were indeed fetched, but the right to make a sale is missing
    if (!getHasRightToMakeASale(getState())) {
      dispatch(addWarning(i18next.t('alerts:noSaleRights')));
      throw new Error(i18next.t('alerts:noSaleRights'));
    }
  };
}

export function getRightsForUserID(userID?: number) {
  return API.getUserRights({ userID }).then(records => records[0]);
}

export function fetchSalesData(dispatch) {
  return API.getEmployeesStats().then(data =>
    dispatch(setUserSalesData(data?.[0])),
  );
}
