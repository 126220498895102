import React from 'react';

/* eslint-disable import/prefer-default-export */
import * as printing from 'utils/print';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getUserLoggedIn, getCompany } from 'reducers/Login';
import { getWarehouseById } from 'reducers/warehouses';
import { getCurrencySymbol } from 'reducers/configs/settings';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { CashInOut } from 'utils/print/templates';

function basicPrintInfo(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const { warehouseID } = getSelectedPos(state);
    const {
      name: warehouseName,
      address: warehouseAddress,
      phone: warehousePhone,
    } = getWarehouseById(warehouseID)(state);
    const { employeeName } = getUserLoggedIn(state);
    const {
      name: companyName,
      phone: companyPhone,
      address: companyAddress,
    } = getCompany(state);
    return {
      ...payload,
      employeeName,
      companyName,
      warehouseName,
      address: warehouseAddress || companyAddress || '',
      phone: warehousePhone || companyPhone || '',
    };
  };
}

export function printCashInOut(payload) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onCashInOutPrint')(
      getState(),
    );
    await dispatch(before(payload));

    const currencySymbol = getCurrencySymbol(getState());
    const data = await dispatch(basicPrintInfo({ ...payload, currencySymbol }));
    const CashInOutComponent = <CashInOut {...(data ?? {})} />;

    const { Template } = await dispatch(
      on(payload, { data, Template: CashInOutComponent }),
    );
    printing.printFromTemplate({
      Template,
      title: window.document.title,
    });
    await dispatch(after(payload, undefined));
  };
}
