/* eslint-disable @typescript-eslint/camelcase */
import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { Configuration, Reader } from 'paymentIntegrations/stripe/types';
import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';

import Readers from './Readers';

const StripeSettings = () => {
  const { t } = useTranslation('settingsPayment', {
    keyPrefix: 'integrations.stripe',
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const superContext = useContext(Ctx);
  const { configuration = {}, setEnableUpdate } = superContext.values as {
    configuration: Configuration;
    setEnableUpdate: (value: boolean) => unknown;
  };
  type Conf = NonNullable<Configuration>;

  const onChange = <K extends keyof Conf>(key: K, value: Conf[K]) => {
    superContext.onChange('configuration', {
      ...configuration,
      [key]: key === 'secret' ? String(value).trim() : value,
    });
  };

  const changeReader = (reader: Reader) => {
    onChange('reader', reader);
  };

  const secretEmpty = !configuration.secret || configuration.secret.length < 1;

  useEffect(() => {
    setEnableUpdate(!secretEmpty);
  }, [secretEmpty, setEnableUpdate]);

  return (
    <Ctx.Provider value={{ values: configuration, onChange }}>
      <Typography variant="h5">{t('disclaimer')}</Typography>
      <TextField
        onChange={e => onChange('secret', e.target.value)}
        fullWidth
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        data-testid="stripe-secret-input"
        label={t('secretKey')}
        id="secret"
        value={configuration.secret ?? ''}
        error={secretEmpty}
        helperText={
          secretEmpty ? t('errors.fieldEmpty', { field: 'secretKey' }) : null
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t('passwordVisibility')}
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{ marginTop: '30px' }}
      />
      <Readers configuration={configuration} changeReader={changeReader} />
    </Ctx.Provider>
  );
};

export default StripeSettings;
