import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hasOpenModalPage } from 'reducers/modalPage';
import { PluginComponent } from 'plugins';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';

import HeaderIcon from '../Icon';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const modalPageShown = useSelector(hasOpenModalPage);
  const dispatch = useDispatch();

  const { t } = useTranslation('header');

  const onClick = useCallback(() => {
    if (modalPageShown) dispatch(closeModalPage(modalPages.Settings));
    dispatch(openModalPage({ component: modalPages.Settings }));
  }, [modalPageShown]);

  return (
    <PluginComponent hookname="ComponentHeaderSettings">
      <HeaderIcon
        icon="settings"
        title={t('titles.settings')}
        data-testid="header-settings"
        onClick={onClick}
      />
    </PluginComponent>
  );
};

export default Settings;
