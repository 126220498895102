import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as rxjs from 'rxjs';
import * as rxop from 'rxjs/operators';
import {
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { Check, Report, Send } from '@material-ui/icons';
import { useObservable, useMap, useToggle } from 'react-use';
import validator from 'validator';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

import { getPayments } from 'reducers/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { PosPlugin } from 'plugins/plugin';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { addError, addSuccess } from 'actions/Error';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getSelectedPos, getSelectedPosID } from 'reducers/PointsOfSale';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getUserLoggedIn } from 'reducers/Login';
import { getCurrencyCode } from 'reducers/configs/settings';
import { useConfirmation } from 'components/Confirmation';
import { PaymentSuccess } from 'paymentIntegrations/cayan/requests/resultParsers';

import {
  MpesaPaymentFailed,
  MpesaPaymentStatus,
  MpesaPaymentSuccess,
  processPayment$,
} from './api/API';
import { CreatePaymentRequest } from './api/types/schema';
import { getMpesaConfiguration } from './Configuration';

const VALIDATE_PHONE_NR = phoneNr =>
  validator.isMobilePhone(phoneNr, ['en-KE'], { strictMode: true })
    ? ''
    : 'Invalid phone number';

const getPaymentForAmountAndPhoneNr = (
  amount: number,
  phoneNr: string,
  currency: string,
) => state => {
  const { accountNumber, walletId } = getMpesaConfiguration(state);
  const employeeFromLS = getUserLoggedIn(state);
  // getUserLoggedIn(state).employeeID is same as getLoggedInEmployeeID(state)
  const employee = getEmployeeById(employeeFromLS?.employeeID)(state);
  const pmt: CreatePaymentRequest = {
    remoteTransactionReference: uuidv4(),
    client: {
      phoneNumber: phoneNr,
      accountNumber,
      walletId,
    },
    shop: {
      id: getSelectedPosID(state),
      name: getSelectedPos(state).name,
      description: getSelectedPos(state).description ?? '',
      attendantName: (employee ?? employeeFromLS)?.employeeName ?? '',
    },
    transaction: {
      amount,
      date: new Date().toISOString().replace(/[TZ]/g, ''),
      currency,
    },
  };
  return pmt;
};

export const MPesaSinglePayment = ({
  paid,
  amount,
  currency,
  processing,
  setProcessing,
  onPaid,
}: {
  paid: boolean;
  amount: number;
  currency: string;
  processing: string | undefined;
  setProcessing: (processing: string | undefined) => void;
  onPaid: (phoneNr: string, amount: number, authCode: string) => void;
}) => {
  const config = useSelector(getMpesaConfiguration);
  const [phoneNr, setPhoneNr] = useState('');
  const normalizedPhoneNr = `+254 ${phoneNr}`.replace(/\s/, '');
  const pmt = useSelector(
    getPaymentForAmountAndPhoneNr(amount, normalizedPhoneNr, currency),
  );
  const refNo = pmt.remoteTransactionReference;
  const status$ = useMemo(() => {
    if (!processing) return rxjs.EMPTY;
    if (processing === 'aborted')
      return rxjs.of(
        new MpesaPaymentFailed(
          {
            dateTime: dayjs().format('YYYY-MM-DDThh.mm:ss.sss'),
            eventId: '',
            message: 'Transaction aborted by cashier',
            note: 'Transaction aborted by cashier',
            status: 'ABORTED',
          },
          refNo,
        ),
      );
    return processPayment$(pmt, config).pipe(
      rxop.tap(
        status => {
          if (status instanceof MpesaPaymentSuccess)
            onPaid(
              pmt.client.phoneNumber,
              pmt.transaction.amount,
              status.data.mpesaCode ?? 'not provided',
            );
        },
        () => setProcessing(undefined),
        () => setProcessing(undefined),
      ),
      // Clear previous payment's final status when starting a new attempt
      rxop.startWith(undefined),
    );
  }, [processing]);
  const status = useObservable(status$);
  const phoneNrValidationError = VALIDATE_PHONE_NR(normalizedPhoneNr);
  const success = status instanceof PaymentSuccess;
  const isProcessingAndNotAborted = !!processing && processing !== 'aborted';
  const disabled = success || paid || isProcessingAndNotAborted;

  const confirm = useConfirmation();
  const abort = () =>
    confirm({
      title: 'Abort the transaction?',
      body: `Please note the payment request has already been sent to the customer, 
please ask them to dismiss this request before cancelling and starting a new payment request`,
    }).then(
      () => setProcessing('aborted'),
      () => {
        // do nothing
      },
    );
  return (
    <Box margin={2}>
      <LinearProgress
        variant="indeterminate"
        style={{
          visibility: isProcessingAndNotAborted ? undefined : 'hidden',
        }}
      />
      <TextField
        value={phoneNr}
        onChange={e => setPhoneNr(e.target.value)}
        disabled={disabled}
        error={0 < phoneNrValidationError.length}
        helperText={phoneNrValidationError}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              Payment of
              <Typography
                color="secondary"
                component="span"
                style={{ margin: '1ch' }}
              >
                {Number(amount).toFixed(2)}
              </Typography>
              from phone number: (+254)
            </InputAdornment>
          ),
          endAdornment: success ? (
            <span style={{ color: 'lime' }}>
              <Check />
            </span>
          ) : (
            <InputAdornment position="end">
              {isProcessingAndNotAborted ? (
                <IconButton
                  onClick={abort}
                  disabled={!isProcessingAndNotAborted}
                  key="stop"
                >
                  <Report />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setProcessing(pmt.remoteTransactionReference)}
                  disabled={disabled || !!phoneNrValidationError}
                  key="start"
                >
                  <Send />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      <p>
        {isProcessingAndNotAborted && <Refno refNo={`${processing}`} />}
        <div
          style={{ whiteSpace: 'pre' }}
          // eslint-disable-next-line react/no-children-prop
          children={
            // prettier-ignore
            // eslint-disable-next-line no-nested-ternary
            status
              ? <Status data={status.data}/>
              : (processing && processing !== 'aborted' ? 'Creating transaction...' : null)
          }
        />
      </p>
    </Box>
  );
};
const Refno = ({ refNo }: { refNo: string }) => {
  const [show, toggle] = useToggle(false);

  if (show)
    return <div onClick={toggle}>TransactionReferenceNumber: {refNo}</div>;
  return <div onClick={toggle}>refno: {refNo.slice(0, 8)}</div>;
};
const Status = ({ data }: { data: MpesaPaymentStatus['data'] }) => {
  const [show, toggle] = useToggle(false);

  if (show)
    return <div onClick={toggle}>{JSON.stringify(data, undefined, 4)}</div>;
  return <div onClick={toggle}>{data.note}</div>;
};

const CardPaymentUI = ({
  payments,
  resolvePayments,
  rejectPayments,
  isVoid = false,
}) => {
  const [processingMap, processing] = useMap({} as Record<string, string>);
  const pmts = useSelector(getPayments);
  const defaultCurrency = useSelector(getCurrencyCode);
  const dispatch = useDispatch();

  const cancel = () => {
    rejectPayments();
  };

  const allPaid = payments.every(p => pmts[p.key].paid);
  useEffect(() => {
    if (allPaid && !isVoid) {
      resolvePayments();
      dispatch(addSuccess('mPesa payments fulfilled'));
    }
  }, [allPaid, isVoid]);

  useEffect(() => {
    if (isVoid) {
      cancel();
      dispatch(addError('mPesa does not support void/refund'));
    }
  }, [isVoid]);

  const noneProcessing =
    Object.entries(processingMap).filter(([_, v]) => v !== 'aborted').length ===
    0;

  return (
    <>
      <div
        style={{
          visibility: noneProcessing ? undefined : 'hidden',
          pointerEvents: noneProcessing ? undefined : 'none',
        }}
      >
        <CloseButton action={cancel} style={{ float: 'right' }} />
      </div>

      {payments.map(payment => {
        return (
          <MPesaSinglePayment
            paid={pmts[payment.key].paid}
            amount={Math.abs(payment.amount)}
            currency={pmts[payment.key].currencyCode ?? defaultCurrency}
            setProcessing={refno => {
              return refno
                ? processing.set(payment.key, refno)
                : processing.remove(payment.key);
            }}
            processing={processingMap[payment.key]}
            onPaid={(phoneNr, amount, authCode) =>
              dispatch(
                setPayment({
                  key: payment.key,
                  amount,
                  cardNumber: phoneNr,
                  paid: true,
                  attributes: { authCode },
                }),
              )
            }
          />
        );
      })}
    </>
  );
};
export const customPaymentIntegration: PosPlugin['customPaymentIntegration'] = {
  id: 'mPesa',
  customComponent: CardPaymentUI,
  voidPayments: ({ cardPayments }) => async dispatch =>
    new Promise((resolve, reject) =>
      dispatch(
        openModalPage({
          // TODO: Fix later
          // @ts-ignore
          component: CardPaymentUI,
          isPopup: true,
          modalClassName: 'card-payment-ui',
          props: {
            integration: customPaymentIntegration,
            payments: cardPayments,
            resolvePayments: resolve,
            rejectPayments: reject,
            isVoid: true,
          },
          // @ts-ignore
          groupID: modalPages.cardPaymentUI,
          replace: false,
        }),
      ),
    ).finally(() => dispatch(previousModalPage())),
};
