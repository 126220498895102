import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalPages as modals } from 'constants/modalPage';
import { setGridView } from 'actions/UI';
import { displayView } from 'constants/UI';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import {
  getFetchOnlyProductsThatAreInStock,
  getSetting,
  getUISetting,
  getUseLocalProductDB,
} from 'reducers/configs/settings';
import { getSelectedProductGroupSubgroups } from 'reducers/productGroupsDB';
import { useMeasureListHeight } from 'containers/Col2/GridViews/util';
import { getCanAddProducts } from 'reducers/Login';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import useProducts from 'utils/hooks/useProducts';
import { getSelectedCustomerID } from 'reducers/customerSearch';

import ProductGroups from '../ProductGroups';
import Products from '../Products';
import Functions from '../Functions';
import SaleOptions from '../SaleOptions';

import { addNewBttn, extendBttn, homeBttn } from './SpecialBttns';

const MainView = () => {
  const dispatch = useDispatch();
  const warehouseID = useSelector(getSelectedWarehouseID);
  const getItemsFromFirstPriceListOnly = useSelector(
    getSetting('pos_allow_selling_only_from_pricelist1'),
  );

  const setGridViewToProducts = useCallback(
    () => dispatch(setGridView(displayView.productGroups)),
    [dispatch],
  );

  const setGridViewToQuickSelect = useCallback(
    () => dispatch(setGridView(displayView.quickSelect)),
    [dispatch],
  );

  const createNewItem = useCallback(
    () =>
      dispatch(
        openModalPage({
          component: modals.createNew,
        }),
      ),
    [dispatch],
  );
  const canAddProducts = useSelector(getCanAddProducts);
  const hideAddProductBtn = useSelector(getUISetting('hideAddProductOrGroup'));

  const startBttns = useMemo(
    () =>
      [
        canAddProducts > 0 && !hideAddProductBtn && addNewBttn(createNewItem),
      ].filter(a => a),
    [canAddProducts, createNewItem, hideAddProductBtn],
  );

  const productGroups = useSelector(getSelectedProductGroupSubgroups);
  const isUsingLocalProductsDb = useSelector(getUseLocalProductDB);
  const fetchOnlyProductsInStock = useSelector(
    getFetchOnlyProductsThatAreInStock,
  );
  const clientID = useSelector(getSelectedCustomerID);

  const { products } = useProducts(
    {
      getProductsFor: 'SALES',
      warehouseID,
      getItemsFromFirstPriceListOnly,
      getWarehouseSpecificVAT: 1,
      quickPosProducts: 1,
      getOnlyItemsInStock:
        isUsingLocalProductsDb && fetchOnlyProductsInStock ? 1 : 0,
      getPriceListPrices: 1,
      clientID,
    },
    isUsingLocalProductsDb ? { addToCachedItems: true, localFirst: true } : {},
  );

  const [functionLines, setFunctionLines] = useState(2);
  const [saleOptLines, setSaleOptLines] = useState(2);
  const [view, setView] = useState(null);
  const goHomeBttns = useMemo(() => [homeBttn(() => setView(null))], [setView]);
  const productGroupsExtendBttns = useMemo(
    () => [extendBttn(setGridViewToProducts)],
    [setGridViewToProducts],
  );
  const quickSelectExtendBttns = useMemo(
    () => [extendBttn(setGridViewToQuickSelect)],
    [setGridViewToQuickSelect],
  );
  const functionsExtendBttns = useMemo(
    () => [extendBttn(() => setView('functions'), 1221, 17)],
    [],
  );
  const saleOptionsExtendBttns = useMemo(
    () => [extendBttn(() => setView('options'), 1208, 23)],
    [],
  );
  const onSetNrFunctionLines = l => setFunctionLines(l);
  const onSetNrSaleOptionsLines = l => setSaleOptLines(l);

  const { needsToMeasure, Measure, totalLines } = useMeasureListHeight();
  if (needsToMeasure) return Measure;
  const remainingSpace =
    Math.max(7, totalLines) - saleOptLines - functionLines - 1;

  switch (view) {
    case 'functions':
      return (
        <Functions
          minLines={totalLines}
          maxLines={totalLines}
          specialBttnsStart={goHomeBttns}
        />
      );
    case 'options':
      return (
        <SaleOptions
          minLines={totalLines}
          maxLines={totalLines}
          specialBttnsStart={goHomeBttns}
        />
      );
    default:
      return (
        <div>
          <ProductGroups
            specialBttnsStart={startBttns}
            specialBttnsEndIfFull={productGroupsExtendBttns}
            productGroups={productGroups}
            minLines={1}
            maxLines={1}
          />
          <Products
            specialBttnsEndIfFull={quickSelectExtendBttns}
            products={products}
            minLines={remainingSpace}
            maxLines={remainingSpace}
          />
          <Functions
            maxLines={2}
            onSetNrLines={onSetNrFunctionLines}
            specialBttnsEndIfFull={functionsExtendBttns}
          />
          <SaleOptions
            maxLines={2}
            onSetNrLines={onSetNrSaleOptionsLines}
            specialBttnsEndIfFull={saleOptionsExtendBttns}
          />
        </div>
      );
  }
};

export default MainView;
