import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { reactcursively } from 'utils/react';

const dataTestId = 'client-name';
const headerDataTestId = 'client-name-header';

function matches(value) {
  return R.pathEq(['props', 'data-testid'], value);
}

const WithoutCustomerColumn: PosPlugin['UISalesDocumentSelection'] = ({
  children,
}) => {
  return reactcursively(
    R.pipe(
      R.when(matches(dataTestId), () => {
        return null;
      }),
      R.when(matches(headerDataTestId), () => {
        return null;
      }),
    ),
  )(children);
};

export default WithoutCustomerColumn;
