import { PosPlugin } from 'plugins/plugin';
import { employeeSelectOnProductGroup } from 'plugins/ideal/features/employeeSelectOnProductGroup';

import {
  ComponentConfiguration,
  getIDealConfiguration,
  pluginID,
} from './Configuration';
import noEditSerialQty from './features/noEditSerialQty';
import { UICustomerForm, UICustomerFormBeta } from './features/customerForm';

const iDealPlugin: PosPlugin = {
  id: pluginID,
  name: 'iDeal customizations',
  keywords: ['serial number', 'serial', 'number', 'iDeal'],
  getStatus: state => {
    const conf = getIDealConfiguration(state) ?? {};
    if (
      !Object.keys(conf.defaultCompanyGroup ?? {}).length ||
      !Object.keys(conf.defaultCustomerGroup ?? {}).length
    ) {
      return {
        message: 'No default customer groups configured',
        type: 'error',
      };
    }
    return {
      type: 'valid',
      message: 'Ready',
    };
  },
  ComponentConfiguration,
  onUpdateOrderAmount: noEditSerialQty.onUpdateOrderAmount,
  onAddProduct: noEditSerialQty.onAddProduct,
  UICustomerForm,
  UICustomerFormBeta,
  selectorOverrides: {
    getSettings: employeeSelectOnProductGroup.selectorOverrides.getSettings,
  },
};

export default iDealPlugin;
