import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';

import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getClientCode, getUserLoggedIn } from 'reducers/Login';
import { getCurrentPosLanguage } from 'reducers/configs/settings';
import { getConfiguration } from 'plugins/mm/configuration';

import { generateToken, getURL } from '../utils';

export default function useURL() {
  const customerId = useSelector(getSelectedCustomerID);
  const clientCode = useSelector(getClientCode);
  const user = useSelector(getUserLoggedIn);
  const lang = useSelector(getCurrentPosLanguage);
  const { isProduction } = useSelector(getConfiguration);
  const environment = isProduction ? 'production' : 'development';

  const { loading, value } = useAsync(async () => {
    const token = await generateToken(customerId, environment);
    return getURL({
      t: token,
      clientCode,
      customerId,
      lang,
      userId: user.userID,
      userName: user.userName,
    });
  }, [clientCode, user, customerId]);

  return { url: value, loading };
}
