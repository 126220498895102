import React from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { PosPlugin } from 'plugins/plugin';
import { getModalTitleProps } from 'reducers/returnProducts';

import { getExchangeState } from '../rdx';

const UIExchangeModalTitle: PosPlugin['UIExchangeModalTitle'] = () => {
  const { t } = useTranslation('return');
  const exchangeState = useSelector(getExchangeState);
  const modalTitleProps = useSelector(getModalTitleProps);
  const modalTitle = t('title', modalTitleProps);
  const exchangeTitle =
    exchangeState.exchangeStep === 1
      ? modalTitle.replace('Return', 'Exchange')
      : modalTitle;

  return <Modal.Title>{exchangeTitle}</Modal.Title>;
};

export default UIExchangeModalTitle;
