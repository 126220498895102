import * as R from 'ramda';
import debug from 'debug';
import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { getBalance, getPayments, getSalesDocument } from 'reducers/Payments';
import { round } from 'utils';

import { UILayawayCancellationConfig } from './components';
import {
  combineConfiguration,
  Conf,
  Configuration,
  getCancellationFeePaymentType,
  getCancellationFeePaymentTypeId,
  getCancellationFeePercentage,
  getCancellationFeeType,
} from './configuration';
import { cancellationFeePaymentInfo, pluginId, pluginName } from './constants';

const log = debug(pluginId);

const getIsLayawayCancellation = createSelector(
  getSalesDocument,
  salesDoc =>
    salesDoc.type === 'PREPAYMENT' && salesDoc.invoiceState === 'CANCELLED',
);

const getCustomerOwesCancellationFee = createSelector(
  getIsLayawayCancellation,
  getSalesDocument,
  getPayments,
  getCancellationFeePaymentType,
  (
    isLaywayCancellation,
    salesDoc,
    payments: Record<
      string,
      { amount: string | number; type: string; locked?: boolean }
    >,
    paymentType,
  ) => {
    if (!isLaywayCancellation) return false;
    const cancellationFee = Object.values(payments).find(
      pmt => pmt.type === paymentType?.name && pmt.locked,
    );
    if (!cancellationFee) return false;
    return Number(salesDoc.advancePayment) < -Number(cancellationFee.amount);
  },
);

export const CancellationFeePlugin: PosPlugin<Conf> = {
  // region meta
  id: pluginId,
  name: pluginName,
  keywords: ['prepayment', 'layaway', 'fee', 'charge', 'cancel', 'payment'],
  info: `Adds an extra payment to layaway cancellation, representing a fee amount that will not be refunded to the customer\n\nPlugin is now a built-in feature. During Layaway cancellation, user can enter the cancellation fee, which will later be saved as 'CANCELFEE' payment on the document`,
  // endregion

  // region configuration
  combineConfiguration,
  getStatus: createSelector(
    getCancellationFeePaymentType,
    getCancellationFeePercentage,
    getCancellationFeeType,
    (pt, per, type) => {
      if (!pt)
        return { type: 'warning', message: 'Payment type not configured' };
      if (per <= 0)
        return { type: 'warning', message: 'Fee percentage not configured' };
      if (!type) return { type: 'warning', message: 'Fee type not configured' };
      return { type: 'valid', message: 'Configuration OK' };
    },
  ),
  ComponentConfigurationByLevel: {
    Company: Configuration,
  },
  // endregion

  // region implementation
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      const ignore = (...args) => {
        log(...args, '🛑');
        return ap;
      };

      log('', 'Checking if this is a layaway cancellation...', { p, ap });

      if (ap.salesDocument.type !== 'PREPAYMENT')
        return ignore('1) Is it a layaway? no!');
      log('', '  1) Is it a layaway? yes');

      if (ap.salesDocument.invoiceState !== 'CANCELLED')
        return ignore('', '  2) Is it being set to status CANCELLED? no!');
      log('', '  2) Is it being set to status CANCELLED? yes');

      log('', 'Checking configuration...');
      const state = getState();
      const paymentType = getCancellationFeePaymentType(state);
      const percentage = getCancellationFeePercentage(state);
      const type = getCancellationFeeType(state);
      log('', '  1) Percentage: ', percentage);
      log('', '  2) type: ', type);
      log('', '  3) Payment Type: ', paymentType);

      if (!paymentType) return ignore('Payment type not configured');

      log('', '  Found payment type', paymentType);
      const { paid, total } = ap.currentSalesDocument;

      const withCharge = source =>
        R.pipe(
          R.evolve({
            payments: R.append({
              type: paymentType.type,
              typeID: paymentType.id,
              caption: paymentType.name,
              amount: -Math.round(source * percentage) / 100,
              locked: true,
              info: cancellationFeePaymentInfo,
            }),
          }),
          R.assoc('total', -paid),
        )(ap);

      const unpaid = total - paid;
      switch (type) {
        case 'unpaid':
          log('', 'Appending fee based on unpaid amount️', unpaid);
          return withCharge(unpaid);
        case 'paid':
          log('', 'Appending fee based on paid amount', paid);
          return withCharge(paid);
        case 'total':
          log('', 'Appending fee based on document total️', total);
          return withCharge(total);
        default:
          return ap;
      }
    },
  },
  onSetPayment: {
    on: (p, ap) => async (dispatch, getState) => {
      const customerOwesCancellationFee = getCustomerOwesCancellationFee(
        getState(),
      );
      const payments: Record<string, { type: string }> = getPayments(
        getState(),
      );
      const hasChange = Object.values(payments).some(
        pmt => pmt.type === 'CHANGE',
      );
      log('', 'Checking if should add positive payment...', {
        customerOwesCancellationFee,
        hasChange,
      });
      if (customerOwesCancellationFee && !hasChange) {
        log('', 'Should add positive payment amount? yes');
        return R.evolve({ amount: amount => round(Math.abs(amount)) })(ap);
      }
      log('', 'Should add positive payment amount? no');
      return ap;
    },
  },
  onSaveSalesDocument: {
    on: (p, requests) => async (dispatch, getState) => {
      // Since core POS adds the fee as well, it is required to remove the plugin's payment since otherwise it would create duplicates
      return requests.filter(
        r =>
          // Allow all requests other than savePayment
          r.requestName !== 'savePayment' ||
          // If is savePayment, remove the payment added by the plugin
          r.info !== cancellationFeePaymentInfo,
      );
    },
  },
  // Selectors overridden to allow full cash/card payments to be added and also change layway cancellation settings
  selectorOverrides: {
    // Override the layaway fee type, payment type and percentage configs with ones from plugin
    getSettings: base =>
      createSelector(
        base,
        state => getCancellationFeePaymentTypeId(state),
        state => getCancellationFeePercentage(state),
        state => getCancellationFeeType(state),
        (settings, pluginPaymentType, pluginFeePercentage, pluginFeeType) =>
          R.pipe(
            R.assoc('layaway_cancellation_fee_payment_type', pluginPaymentType),
            R.assoc('layaway_cancellation_fee_percentage', pluginFeePercentage),
            R.assoc('layaway_cancellation_fee_type', pluginFeeType),
          )(settings),
      ),
    getPaymentsExceededTotal: base =>
      createSelector(
        base,
        getCustomerOwesCancellationFee,
        getBalance,
        (exceeded, customerOwesCancellationFee, balance) => {
          return customerOwesCancellationFee && balance < 0 ? false : exceeded;
        },
      ),
    getCashPaymentsExceededTotal: base =>
      createSelector(
        base,
        getCustomerOwesCancellationFee,
        getBalance,
        (exceeded, customerOwesCancellationFee, balance) =>
          customerOwesCancellationFee && balance < 0 ? false : exceeded,
      ),
  },
  // endregion
  // Region disable the layaway cancellation fee configuration in core so that only the plugin's config applies
  UILayawayCancellationConfig,
};
