import React from 'react';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';
import { getSalesDocument } from 'reducers/Payments';

import { defaultVoucherTypeID, pluginID } from '../constants';
import { Configuration } from '../types';

const UIOriginalPayment: Required<PosPlugin>['UIOriginalPayment'] = ({
  payment,
  children,
  onClick,
  disabled,
}) => {
  const { PaymentTypeID } = useSelector(
    getPluginConfigurationAtLevel<Configuration>(pluginID, 'Company', ''),
  ) ?? {
    PaymentTypeID: defaultVoucherTypeID,
  };

  const currentSalesDoc = useSelector(getSalesDocument);
  const wasPaidByNSWVouchers =
    currentSalesDoc?.attributes?.nsw_vouchers?.length > 0;

  const getIsDisabled = () => {
    if (wasPaidByNSWVouchers && payment.typeID === PaymentTypeID) {
      return true;
    }
    if (payment.type === 'PARTNER') {
      return true;
    }
    return disabled;
  };

  return React.Children.map<React.ReactNode, React.ReactNode>(children, child =>
    React.isValidElement(child)
      ? React.cloneElement(child, {
          // @ts-ignore
          disabled: getIsDisabled(),
          onClick: !getIsDisabled() ? onClick : undefined,
          style: { cursor: getIsDisabled() ? 'not-allowed' : 'pointer' },
        })
      : child,
  );
};
export default UIOriginalPayment;
