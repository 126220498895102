/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import * as MUI from '@material-ui/core';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { SaveButton } from 'components/UIElements/UIButton';
import {
  getSettings,
  getIsCustomPaymentEnabled,
} from 'reducers/configs/settings';
import Loader from 'components/Loader';
import { addError } from 'actions/Error';
import CloseButton from 'components/CustomButtons/CloseButton';
import { saveSetting } from 'actions/configs';
import {
  Page,
  Section,
  collapseAllSections,
} from 'containers/Forms/Settings/components/Inputs';
import { Ctx, Check } from 'containers/Forms/Settings/components/CtxInputs';
import { modalPages } from 'constants/modalPage';

import styles from './Payment.module.scss';

const Payment = () => {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const isCustomPaymentEnabled = useSelector(getIsCustomPaymentEnabled);

  const { t } = useTranslation('settingsPayment');
  const onClose = () => dispatch(previousModalPage());

  const [changed, setChanged] = useState({});
  const current = useSelector(getSettings);

  const onSave = async () => {
    setSaving(true);
    try {
      Promise.all(
        Object.entries(changed).map(([key, value]) =>
          dispatch(
            saveSetting({
              parameterName: key,
              parameterValue: value,
            }),
          ),
        ),
      ).then(onClose);
    } catch (e) {
      console.error('Failed to revert settings in aborted form', changed, e);
      dispatch(addError(e.message, { selfDismiss: true }));
    } finally {
      setSaving(false);
    }
  };

  // Menu that allows you to setup custom payments
  const customPayments = isCustomPaymentEnabled && (
    <Page title={t('customPayments.title')} value={modalPages.CustomPayments} />
  );

  const onChange = useCallback(
    (key, value) => {
      if (
        !key.includes('limit') &&
        value === 0 &&
        !key.includes('pos_allow_return_receipt_original_tender_only')
      ) {
        setChanged(state => ({ ...state, [`${key}_limit`]: '' }));
      }
      if (current[key] === value) {
        setChanged(state => {
          const newState = { ...state };
          delete newState[key];
          return newState;
        });
      } else {
        setChanged(state => ({ ...state, [key]: value }));
      }
      return undefined;
    },
    [current],
  );

  const values = {
    ...current,
    ...changed,
  };

  return (
    <div
      className={styles['erply-settings__payment']}
      style={{ overflowY: 'auto' }}
      data-testid="settings-payment-configuration-modal"
    >
      <Loader
        show={saving}
        loadingText="Saving..."
        style={{ display: 'block', height: '100%', maxHeight: '100%' }}
      >
        <Modal.Header>
          <span>{t('title')}</span>
          <span style={{ flexGrow: '1' }} />
          <SaveButton
            data-testid="save-settings-payment-configuration-btn"
            variant="POS"
            action={onSave}
          />

          <CloseButton action={onClose} />
        </Modal.Header>
        <Modal.Body>
          <Ctx.Provider value={{ values, onChange }} values={values}>
            <MUI.Grid container>
              <MUI.Grid item xs />
              <MUI.Button variant="text" onClick={collapseAllSections}>
                {t('settings:buttons.collapseAllSections')}
              </MUI.Button>
            </MUI.Grid>
            <Section title={t('general.title')}>
              <Page
                title={t('general.setupGateways')}
                value={modalPages.PaymentGateways}
              />
              <Page
                title={t('allowedTenders.title')}
                value={modalPages.AllowedTenders}
              />
              {/* Temporarily disabled as per https://erply.atlassian.net/browse/PBIB-1750?focusedCommentId=187006 */}
              {/* <Check */}
              {/*   name="use_cayan_capture" */}
              {/*   title={t('general.capturePayments')} */}
              {/* /> */}
              <Check
                // Is used to enable/disable Cash Out/Withdrawal functionality (previously named Cash Back)
                name="disableCashBack"
                title={t('general.disableCashBack')}
              />
            </Section>
            <Section title={t('customPayments.title')}>
              <Check
                name="touchpos_enable_custom_payments"
                title={t('customPayments.enable')}
              />
              {/* Menu that allows you to setup custom payments */}
              {customPayments}
            </Section>
          </Ctx.Provider>
        </Modal.Body>
      </Loader>
    </div>
  );
};

export default Payment;
