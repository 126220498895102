import React, { FC } from 'react';
import { Dispatch } from 'redux';
import Modal from 'react-bootstrap/Modal';

import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

type CustomerNotesProps = {
  notes: string;
  fullName: string;
  dispatch: Dispatch<any>;
};

const CustomerNotesPopup: FC<CustomerNotesProps> = ({
  notes,
  fullName,
  dispatch,
}) => {
  const onClose: () => void = () => dispatch(previousModalPage());

  return (
    <div data-testid="pnp-customer-notes">
      <Modal.Header>
        <h3 data-testid="name" style={{ fontWeight: 'bold' }}>
          {fullName}
        </h3>
        <span style={{ flexGrow: 1 }} />
        <UIButton
          data-testid="close"
          text="Close"
          action={onClose}
          variant="POS"
        />
      </Modal.Header>
      <Modal.Body data-testid="notes" style={{ overflow: 'auto' }}>
        {notes}
      </Modal.Body>
    </div>
  );
};

export default CustomerNotesPopup;
