import { ErplyAttributes } from 'utils';
import * as R from 'ramda';

export const getAttrs = product => {
  const attrs = new ErplyAttributes(product.attributes);
  return {
    percentage: attrs.get('taxProducts_percentage') ?? (0 as number),
    applyTo:
      attrs.get('taxProducts_apply-to') ??
      ('auto' as number | 'auto' | 'manual'),
    taxedBy: R.tryCatch(
      JSON.parse,
      R.always([]),
    )(attrs.get('taxProducts_taxed-by')) as number[],
    forceTaxedBy: !!attrs.get('taxproducts_force-taxed-by'),
  };
};
