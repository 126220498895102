import Button from 'react-bootstrap/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PosPlugin } from 'plugins/plugin';
import { getIsCalculatingShoppingCart } from 'reducers/ShoppingCart';
import { useMeasurement } from 'components/List';
import { calculate } from 'actions/ShoppingCart/calculate';
import { addWarning } from 'actions/Error';

import { getEnvState } from '../rdx';

const UICustomPayButton: PosPlugin['UICustomPayButton'] = ({ children }) => {
  const { t } = useTranslation('gridButtons');

  const [POSWasRecentlyCalculating, setPOSWasRecentlyCalculating] = useState(
    false,
  );

  const dispatch = useDispatch();
  const POSIsCalculating = useSelector(getIsCalculatingShoppingCart);
  const envFeeState = useSelector(getEnvState);

  useEffect(() => {
    const t = setTimeout(
      () => {
        setPOSWasRecentlyCalculating(POSIsCalculating);
      },
      POSIsCalculating ? 0 : 4500,
    );
    return () => clearTimeout(t);
  }, [POSIsCalculating]);

  const classNameFor = n =>
    classNames('btn-long', 'button-item', {
      'btn-double': n === 2,
      'btn-triple': n === 3,
    });

  const { needsToMeasure, Measure, width } = useMeasurement({
    targetHeight: 1000,
    component: useCallback(() => <button className={classNameFor(1)} />, []),
  });
  if (needsToMeasure) return Measure;

  // width was 9 and the POS was crashing, default value was 8, changed to 7(arrays index from 0)
  const [w1, w2, w3, w4] = [
    [0, 0, 0, 0],
    [0, 0, 0, 1],
    [0, 0, 1, 1],
    [0, 1, 1, 1],
    [1, 1, 1, 1],
    [1, 1, 1, 2],
    [1, 1, 2, 2],
    [1, 1, 2, 3],
  ][width && width < 8 ? width : 7];

  const showCalculating =
    envFeeState.calculatingEnvOrAdmTaxFees ||
    POSIsCalculating ||
    POSWasRecentlyCalculating;

  return (
    <>
      {showCalculating ? (
        <Button
          className={`btn-long button-item btn-grid_green btn-${
            ['hidden', '', 'double', 'triple'][w4]
          }`}
          style={{ backgroundColor: '#37AF59' }}
          onClick={() => {
            if (!showCalculating) {
              batch(() => {
                dispatch(calculate());
                dispatch(
                  addWarning('Recalculating shopping cart', {
                    dismissible: false,
                    selfDismiss: true,
                  }),
                );
              });
            }
          }}
          data-testid="calculating-mock-btn"
          disabled={showCalculating}
        >
          <span
            style={{
              fontSize: '20px',
              textTransform: 'uppercase',
              fontWeight: 700,
            }}
          >
            {showCalculating
              ? t('gridButtons:actions.calculating').replace(
                  '...',
                  POSIsCalculating ? '...' : '..',
                )
              : 'PAY'}
          </span>
        </Button>
      ) : (
        children
      )}
    </>
  );
};

export default UICustomPayButton;
