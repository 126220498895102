import React from 'react';
import * as R from 'ramda';
import { Grid } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';

import { useMultiOptIn } from '../customerMultiOptIn/customerMultiOptIn';
import { insertAfterBeta } from '../customerMultiOptIn/utils';
import { useDefaultCustomerGroups } from '../defaultCustomerGroups';

export const UICustomerFormBeta: Required<PosPlugin>['UICustomerFormBeta'] = ({
  customerType,
  customer,
  onChange,
  children,
}) => {
  const isCompany = customerType === 'COMPANY';
  useDefaultCustomerGroups({ value: customer, onChange, isCompany });
  const { optOutCheckboxes } = useMultiOptIn({
    onChange,
    value: customer,
    isCompany,
  });

  return R.pipe(
    React.Children.toArray,
    insertAfterBeta(
      'emailOptOut',
      <Grid item xs={12}>
        {optOutCheckboxes}
      </Grid>,
    ),
  )(children);
};
