import React from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';

import { PluginComponent } from 'plugins';
import { modalPages as mp } from 'constants/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getSetting } from 'reducers/configs/settings';

import './tableStats.scss';

type DataType = (string | number | { value: string; fullValue: string })[];

/**
 * Display statistics in a small table
 */
const TableStats = ({
  dataHeaders,
  data,
  title = null,
  isCustomer = false,
}: {
  /** The headers for this table */
  dataHeaders: (string | number)[];
  /** The values for this table - should have the same shape as dataHeaders */
  data: DataType;
  /** The title of this table */
  title?: string | null;
  /** Determines the default icon that will be displayed in the user badge */
  isCustomer: boolean;
}) => {
  const isDark = useSelector(getSetting('touchpos_use_dark_theme'));
  const prefix = isCustomer ? 'customer' : 'employee';

  const displayTableHeaders = dataHeaders.map((header, index) => (
    // eslint-disable-next-line prefer-template
    <th
      key={`header${index + 1}`}
      style={{
        width: `${100 / dataHeaders.length}%`,
      }}
    >
      {header}
    </th>
  ));

  const displayTableStats = data.map((stat, index) => {
    let value = stat;
    let fullValue = stat;
    if (typeof stat === 'object') {
      ({ value, fullValue } = stat);
    }
    return (
      <td
        data-testid={`${prefix}-stats`}
        data-test-key={`${prefix}-${fullValue}-${index}`}
        key={`stat${index + 1}`}
        title={`${fullValue}`}
      >
        {value}
      </td>
    );
  });
  const dispatch = useDispatch();
  const showCustomer = () =>
    dispatch(openModalPage({ component: mp.customerView }));
  return (
    <PluginComponent hookname="UITableStats">
      <p className="statsTableTitle">{title}</p>
      <Table
        variant={isDark ? 'dark' : ''}
        size="sm"
        className="tableStats"
        onClick={
          isCustomer
            ? showCustomer
            : () => {
                // do nothing
              }
        }
      >
        <thead>
          <tr>{displayTableHeaders}</tr>
        </thead>
        <tbody>
          <tr>{displayTableStats}</tr>
        </tbody>
      </Table>
    </PluginComponent>
  );
};

export default TableStats;
