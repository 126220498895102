import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { suretaxLog } from 'plugins/suretax/meta';
import { updateProductOrder } from 'actions/ShoppingCart';
import { calculate } from 'actions/ShoppingCart/calculate';
import { selectOneCustomer } from 'actions/customerSearch';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { addError } from 'actions/Error';

import { fetchSuretaxInfo } from './fetchSuretaxInfo';
import { getRequiredTaxrateUpdates } from './getRequiredTaxrateUpdates';

/**
 * Send the shopping cart to suretax for calculation and apply the resulting taxes to the shopping cart
 *
 * In case of failure, invalidates the shopping cart again
 *
 * 1. Get info from suretax
 * 2. Collect matching taxes in erply, creating new ones if necessary
 * 3. Find products in shopping cart that need taxes changed
 * 4. Change those taxes
 * 5. Retrigger onCalculate to calculate the cart with the newly applied tax rates
 */
export const afterCalculate = (p, ap) => async (
  dispatch: ThunkDispatch<any, any, Action>,
  getState,
) => {
  const log = suretaxLog.extend('updateSuretaxFromOnCalculate');
  try {
    log('Getting Suretax data for current shopping cart', { p, ap });
    const response = await dispatch(fetchSuretaxInfo(ap));

    log('Got data', response);
    log('Comparing to cart, collecting vat rates that need to be applied');
    const updates = await dispatch(getRequiredTaxrateUpdates(response));

    if (!updates.length)
      return log('No updates necessary, shopping cart all OK');

    // Prevent infinite recursion in case taxes fail to apply correctly
    if (p.suretaxRerun)
      throw new Error(
        'Already updated shopping cart but taxes are still wrong. This should not happen',
      );

    log('Got updates', updates, ', updating cart');
    await Promise.all(updates.map(upd => dispatch(updateProductOrder(upd))));
    log('Retriggering calculate');
    return dispatch(calculate({ forceCalculate: true, suretaxRerun: true })); // Pray that this code is correct, else this is an infinite loop
  } catch (e) {
    log.log = console.error.bind(console);
    log(
      'Error in response, invalidating shopping cart by "changing" the customer',
      e,
    );
    dispatch(selectOneCustomer(getSelectedCustomer(getState()).customerID));
    dispatch(
      addError(
        `${e.data.HeaderMessage}\n${e.data.ItemMessages.map(
          m => m.Message,
        ).join('\n')}`,
      ),
    );
  }
};
