import {
  SLOWDOWN_FACTOR,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/CONFIGURATION';
import {
  InstallerCertInfo,
  InstallerInfo,
  MsCertInfo,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/types';

const uxSlowdown = <T,>(res: T) => {
  return new Promise<T>(resolve =>
    setTimeout(() => resolve(res), SLOWDOWN_FACTOR * Math.random()),
  );
};

export const API = {
  getInstallerInfo: () =>
    window
      .fetch(`https://localhost.erply.com:${7777}/api/v1/info`)
      .then(res => res.json() as Promise<InstallerInfo>)
      .then(uxSlowdown),

  getInstallerCertInfo: () =>
    window
      .fetch(`https://localhost.erply.com:${7777}/api/v1/service/info`)
      .then(res => res.json())
      .then(
        (info: InstallerCertInfo): MsCertInfo => ({
          utcDateTime: info.dateTime,
          recordsCount: 1,
          records:
            'serviceInfo' in info
              ? [info.serviceInfo]
              : [
                  {
                    Name: '',
                    GoVersion: '',
                    certs: JSON.parse(info.message) as MsCertInfo['records'][number]['certs'],
                    OS: '',
                    ReleaseDate: '',
                    Version: '',
                  },
                ],
        }),
      )
      .then(uxSlowdown),

  getCertInfo: (port: number) =>
    window
      .fetch(`https://localhost.erply.com:${port}/api/v1/service/info`)
      .then(res => res.json() as Promise<MsCertInfo>)
      .then()
      .then(uxSlowdown),

  updateInstaller: () =>
    window
      .fetch(`https://localhost.erply.com:${7777}/api/v1/update`)
      .then(async res => {
        const json = await res.json();
        if (!res.ok) throw new Error(json.message);
        return json;
      })
      .then(uxSlowdown),
};
