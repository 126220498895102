import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import ListItem from 'components/ListItem';
import { deletePayment, setPaymentEditValue } from 'actions/Payments';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { getPaymentSelected } from 'reducers/Payments';

const ClosePayment = ({ voidHandler }) => {
  const dispatch = useDispatch();

  const paymentSelected = useSelector(getPaymentSelected);

  const escapeHandler = async () => {
    if (paymentSelected !== '') {
      dispatch(setPaymentSelected(''));
      dispatch(deletePayment({ key: paymentSelected }));
      dispatch(setPaymentEditValue(''));
    } else {
      return voidHandler();
    }
  };

  useShortcut('Escape', escapeHandler, 20);

  const cancelPayment = () => voidHandler();

  return (
    <ListItem
      data-testid="cancel-payment-button"
      variant="grid_red"
      action={cancelPayment}
    >
      <span className="icon_close" />
    </ListItem>
  );
};

export default ClosePayment;
