export const MONERIS_CONST = {
  CONFIG: {
    ENTRY_MODE: {
      KEY: 'origEntryMode',
      OPTIONS: {
        NEVER: 'never',
        ALWAYS: 'always',
        ASK: 'ask',
      },
    },
    RECEIPT: {
      KEY: 'Receipt',
      OPTIONS: {
        NONE: 'none',
        MERCHANT: 'merchant',
        CARDHOLDER: 'cardholder',
        MERCHANTCARDHOLDER: 'merchant, cardholder',
      },
    },
  },
};

/**
 * Types that you are seeing here are described based on what MS team requested us to send
 */
export type PurchaseRequest = {
  transactionType: 'SALE';
  amount: string;
  invoiceNumber: string;
};

export type VoidRequest = {
  transactionType: 'VOID';
  amount: string;
  referenceNumber?: string;
};

export type RefundRequest = {
  transactionType: 'REFUND';
  amount: string;
  referenceNumber?: string;
};

export type TransactionRequest = PurchaseRequest | VoidRequest | RefundRequest;

export type CancelTransaction = {
  manageType: 'cancelTransaction';
};

export type StatusTransaction = {
  manageType: 'Status';
};

export type ActivateTerminal = {
  manageType: 'ActivateTerminal';
  data: string;
};

export type Type = 'SALE' | 'VOID' | 'REFUND' | null;

export type Config = {
  DeviceTimeout?: number;
  MerchantID?: string;
  Receipt?: 'merchant, cardholder' | 'none' | 'merchant' | 'cardholder';
  TerminalID?: string;
  TerminalIPAddress?: string;
  TerminalPort?: string;
  version?: string;
};
