import { deletePayment } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import * as paymentIntegrations from 'paymentIntegrations/paymentIntegrations';
import { getPluginHooksByName } from 'reducers/Plugins';
import { addError } from 'actions/Error';

function defaultVoidPayment({ cardPayments }) {
  return async dispatch =>
    Promise.all(
      cardPayments
        .filter(pmt => pmt.type === 'CARD')
        .reduce(
          (prev, pmt) =>
            prev.then(() => dispatch(deletePayment({ key: pmt.key }))),
          Promise.resolve(null),
        ),
    );
}
export function defaultInitPayment({
  cardPayments,
  resolvePayments,
}) {
  return async dispatch =>
    Promise.all(
      cardPayments
        .filter(pmt => pmt.type === 'CARD')
        .reduce(
          (prev, pmt) =>
            prev.then(() =>
              dispatch(
                setPayment({
                  ...pmt,
                  paid: true,
                  forceAmount: true,
                }),
              ),
            ),
          Promise.resolve(null),
        ),
    ).then(resolvePayments);
}

export function getIntegration(integration: string) {
  return async (
    dispatch,
    getState,
  ) => {
    const defaultInt = {
      initPayments: defaultInitPayment,
      voidPayments: defaultVoidPayment,
    };
    const builtin = paymentIntegrations[integration];
    const plugin = getPluginHooksByName<{ id: string }>(
      'customPaymentIntegration',
    )(getState()).find(int => int.id === integration);

    if (integration === 'none' || integration === '')
      return Promise.resolve(defaultInt);
    if (!integration) return Promise.resolve(defaultInt);
    if (builtin) return builtin;
    if (plugin) return plugin;
    dispatch(
      addError(
        `Failed to load integration ${integration}, please reselect a payment integration from settings→payment`,
      ),
    );
    throw new Error(`Failed to load integration ${integration}`);
  };
}
