/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';

import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { saveCustomer } from 'actions/customerSearch';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { modalPages as mp } from 'constants/modalPage';
import {
  getCustomerRegistryUrl,
  getDefaultCustomerGroup,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getSetting } from 'reducers/configs/settings';
import { getDefaultHomeStoreID } from 'reducers/cafaConfigs';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { Customer } from 'types/Customer';
import { PluginComponent } from 'plugins';

import { companyInitState, personInitState } from './formShapes';
import CustomerForm from './CustomerForm';

import './CreateCustomer.sass';

const CUSTOMER_TYPES = {
  person: 'PERSON',
  company: 'COMPANY',
} as const;

const CreateCustomer = ({
  newCustomer,
  newCustomerData = { addresses: [] },
  customer: inputCustomer,
}) => {
  const selectedCustomer = useSelector(getSelectedCustomer);
  const customer = inputCustomer || selectedCustomer;

  const dispatch = useDispatch();
  // prettier-ignore
  const customerRegistry = useSelector(getCustomerRegistryUrl);
  const allowCreateNewCompany = !useSelector(
    getSetting('brazil_disable_create_company'),
  );

  const warehouseID = useSelector(getSelectedWarehouseID);

  const [activeTab, setActiveTab] = useState<
    typeof CUSTOMER_TYPES[keyof typeof CUSTOMER_TYPES]
  >(
    customer.id && !newCustomer ? customer.customerType : CUSTOMER_TYPES.person,
  );
  const defaultPersonHomeStoreID = useSelector(
    getDefaultHomeStoreID(CUSTOMER_TYPES.person),
  );
  const defaultCompanyHomeStoreID = useSelector(
    getDefaultHomeStoreID(CUSTOMER_TYPES.company),
  );
  const defaultGroupID = useSelector(getDefaultCustomerGroup)?.customerGroupID;

  const [PERSON, setPERSON] = useState({
    // new customer default data
    ...personInitState,
    // homeStoreID for a new Person customer
    homeStoreID: defaultPersonHomeStoreID,
    groupID: defaultGroupID,
    // if new customer - data for new customer
    // if existing customer - data from existing customer (overrides homeStoreID)
    ...(newCustomer ? newCustomerData : customer),
  });
  const [COMPANY, setCOMPANY] = useState({
    ...companyInitState,
    // homeStoreID for a new Company customer
    homeStoreID: defaultCompanyHomeStoreID,
    ...(newCustomer ? {} : { ...customer, companyTypeID: undefined }),
  });
  const CUSTOMER = {
    [CUSTOMER_TYPES.person]: PERSON,
    [CUSTOMER_TYPES.company]: COMPANY,
  }[activeTab];

  useEffect(() => {
    const newCustomer = {
      addressTypeID: customer.addressTypeID,
      addresses: customer.addresses || newCustomerData?.addresses || [],
      birthday: '00-00-0000',
      newAddress: {},
    };
    const newCompany = {
      addressTypeID: customer.addressTypeID,
      addresses: customer.addresses || [],
      newAddress: {},
    };
    if (customerRegistry) {
      Object.assign(newCustomer, { gender: customer.gender });
      Object.assign(newCustomer, { euCustomerType: customer.euCustomerType });
      Object.assign(newCompany, { gender: customer.gender });
      Object.assign(newCompany, { euCustomerType: customer.euCustomerType });
    }
    setPERSON(p => ({ ...p, newCustomer }));
    setCOMPANY(c => ({ ...c, newCompany }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const checkForRequiredFields = useCallback(() => {
    const currentState = {
      [CUSTOMER_TYPES.person]: PERSON,
      [CUSTOMER_TYPES.company]: COMPANY,
    }[activeTab];
    if (currentState && activeTab === CUSTOMER_TYPES.company) {
      return !currentState.companyName.trim().length || currentState.invalid;
    }
    if (currentState && activeTab === CUSTOMER_TYPES.person) {
      if (customerRegistry) {
        const [year, month, date] = currentState.birthday.split('-');
        return (
          !Number(date) ||
          !Number(month) ||
          !Number(year) ||
          !date.trim().length ||
          !month.trim().length ||
          !year.trim().length ||
          !currentState?.firstName?.trim().length ||
          !currentState?.lastName?.trim().length
        );
      }
      return (
        !currentState?.firstName?.trim().length ||
        !currentState?.lastName?.trim().length ||
        currentState.invalid
      );
    }
    return true;
  }, [activeTab, customerRegistry, PERSON, COMPANY]);

  const setCustomer = useCallback(
    data =>
      activeTab === CUSTOMER_TYPES.person ? setPERSON(data) : setCOMPANY(data),
    [activeTab, setPERSON, setCOMPANY],
  );

  const setBirthDay = useCallback(
    ({ year, month, date }) =>
      setPERSON(p => ({ ...p, birthday: `${year}-${month}-${date}` })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [PERSON.birthday],
  );

  const [year, month, date] = PERSON.birthday.split('-').map(value => {
    if (value === '00' || value === '0000') return ' ';
    if (Number(value) > 0 && value.length === 1) return `0${value}`;
    return value;
  });

  const birthday = { date, month, year };

  const onSave = useCallback(() => {
    const newCustomerPayload = CUSTOMER as Customer;
    // only Person type customers should have a birthday
    if (activeTab === CUSTOMER_TYPES.person) {
      const [year = '', month = '', date = ''] = CUSTOMER.birthday.split('-');
      // allows saving bday without the birth year - existing in Berlin, used by many businesses
      newCustomerPayload.birthday = `${year.trim().length ? year : '9999'}-${
        month.trim().length ? month : '00'
      }-${date.trim().length ? date : '00'}`;
    }
    newCustomerPayload.emailOptOut = Number(CUSTOMER.emailOptOut) as 0 | 1;
    newCustomerPayload.signUpStoreID = newCustomer
      ? Number(warehouseID)
      : CUSTOMER.signUpStoreID;
    dispatch(
      saveCustomer(newCustomerPayload, () => {
        dispatch(closeModalPage());
        dispatch(
          openModalPage({
            component: mp.customerView,
            groupID: mp.customerView,
            replace: true,
          }),
        );
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, saveCustomer, openModalPage, CUSTOMER]);

  const closeCreateCustomer = useCallback(() => {
    if (CUSTOMER.id) {
      dispatch(closeModalPage());
      dispatch(
        openModalPage({
          component: mp.customerView,
          groupID: mp.customerView,
          replace: true,
        }),
      );
    } else {
      dispatch(closeModalPage());
    }
  }, [dispatch, CUSTOMER.id]);

  const customerState = CUSTOMER;
  const customerTypes = useMemo(
    () =>
      customerState.id
        ? [customerState.customerType]
        : Object.values(CUSTOMER_TYPES),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerState.id],
  );

  const { t } = useTranslation('createCustomer');
  return (
    <div
      className="create-customer-page"
      data-testid="create-customer-container"
    >
      <Modal.Header>
        <Modal.Title
          data-testid="title"
          style={{ fontSize: '24px', fontWeight: 700 }}
        >
          {t('title', { context: customerState.id ? 'edit' : 'create' })}
        </Modal.Title>
        <div className="create-customer-page-action-buttons">
          <PluginComponent
            hookname="UICustomerFormSaveButton"
            props={{
              disabled: checkForRequiredFields(),
              customer: customerState,
              onSave,
            }}
          >
            <SaveButton
              data-testid="save-button"
              action={onSave}
              disabled={checkForRequiredFields()}
            />
          </PluginComponent>
          <CloseButton action={closeCreateCustomer} />
        </div>
      </Modal.Header>
      <Modal.Body>
        {allowCreateNewCompany && (
          <Nav variant="pills" fill>
            {customerTypes.map(el => (
              <Nav.Item key={el}>
                <Nav.Link
                  className={el === activeTab ? 'nav-link active' : 'nav-link'}
                  onClick={() => setActiveTab(el)}
                  eventKey={el}
                  data-testid={el}
                >
                  {t(`type.${el.toLowerCase()}`)}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        )}
        <CustomerForm
          onChange={setCustomer}
          setBirthDay={setBirthDay}
          birthday={birthday}
          customer={customerState}
        />
      </Modal.Body>
    </div>
  );
};

CreateCustomer.propTypes = {
  /** If true, displays a new customer form regardless of whether the POS has a customer already selected */
  newCustomer: PropTypes.bool,
  customer: PropTypes.object.isRequired,
};
export default CreateCustomer;
