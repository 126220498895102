import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import RandExp from 'randexp';

import CloseButton from 'components/CustomButtons/CloseButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import {
  getGiftcardSerialConfig,
  getGiftcardSerialRegex,
  getSetting,
} from 'reducers/configs/settings';
import { addGiftCardToCartIfSerialUnused } from 'actions/giftcards';
import { PluginComponent } from 'plugins';

import { Props } from './types';

const GiftCardSerial: React.FC<Props> = ({ productID, onClose }) => {
  const [giftCardSerial, setGiftCardSerial] = useState('');
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  const shouldHideGenerateButton = useSelector(
    getSetting('pos_disable_generate_number_button'),
  );

  const { t } = useTranslation('giftcard');

  const handleSubmit = () =>
    dispatch(addGiftCardToCartIfSerialUnused({ productID, giftCardSerial }));

  const serialNumberRegexConfig = useSelector(getGiftcardSerialConfig);
  const pattern = useSelector(getGiftcardSerialRegex);
  const isValid = pattern.test(giftCardSerial);
  const generate = () => {
    setGiftCardSerial(
      serialNumberRegexConfig
        ? new RandExp(pattern).gen()?.substring(0, 49)
        : v4(),
    );
  };

  useShortcut('Enter', handleSubmit);
  useShortcut('Escape', onClose);
  let helperText = '';
  if (!isValid) {
    if (!serialNumberRegexConfig) {
      helperText = t('errors.empty');
    } else {
      helperText = t('errors.regexMismatch', {
        serial: giftCardSerial,
        pattern: serialNumberRegexConfig,
      });
    }
  } else {
    helperText = '';
  }

  return (
    <PluginComponent
      hookname="UICustomGiftCardSerial"
      props={{
        handleSubmit,
        giftCardSerial,
        generate,
        helperText,
        isValid,
        onClose,
        shouldHideGenerateButton,
        setGiftCardSerial,
        productID,
      }}
    >
      <Box padding="1rem">
        <Box display="flex" alignItems="center" marginBottom="0.75rem">
          <Box>
            <Typography variant="h5">{t('serial.title')}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" flex={1}>
            <Button
              onClick={handleSubmit}
              disabled={!giftCardSerial || !isValid}
              variant="contained"
              color="secondary"
            >
              {t('buttons.save')}
            </Button>
            <CloseButton
              style={{ transform: 'scale(0.85)' }}
              action={onClose}
            />
          </Box>
        </Box>
        <TextField
          label={t('fields.serial', { context: 'placeholder' })}
          value={giftCardSerial}
          inputRef={inputRef}
          autoFocus
          error={!isValid}
          helperText={helperText}
          style={{ marginBottom: '0.75rem' }}
          onChange={e =>
            setGiftCardSerial(e.target.value.replace(/(\s*)/g, ''))
          }
          variant="outlined"
          fullWidth
        />
        {!shouldHideGenerateButton && (
          <Button
            variant="contained"
            onClick={generate}
            fullWidth
            color="secondary"
          >
            {t('buttons.generateNumber')}
          </Button>
        )}
      </Box>
    </PluginComponent>
  );
};

export default GiftCardSerial;
