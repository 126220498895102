import { useSelector } from 'react-redux';
import React from 'react';

import { getWarehouseById } from 'reducers/warehouses';
import { SkeletonWord } from 'plugins/nohble/SpecialOrders/utils/SkeletonWord';

/** Util component for rendering the name of a warehouse given a warehouseID. Renders a skeleton while loading */
export const WarehouseName = ({ id }) => {
  const warehouse = useSelector(getWarehouseById(id));
  return warehouse?.name ?? <SkeletonWord />;
};
