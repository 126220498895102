import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

type KeySpec = {
  title: string;
  primaryKey: string | string[];
  secondaryKey?: string | string[];
};
type ShortcutSpec = { window: string; keys: KeySpec[] };
const ShortcutsModal = () => {
  const { t } = useTranslation('shortcuts');
  const shortcutsToDisplay = useMemo<ShortcutSpec[]>(
    () => [
      {
        window: t('main.title'),
        keys: [
          {
            title: t('main.openPayment'),
            primaryKey: 'F2',
          },
          {
            title: t('main.newSale'),
            primaryKey: 'F4',
          },
          {
            title: t('main.searchProducts'),
            primaryKey: 'F9',
          },
          {
            title: t('main.searchCustomers'),
            primaryKey: 'F10',
          },
        ],
      },
      {
        window: t('payment.title'),
        keys: [
          {
            title: t('payment.exactCard'),
            primaryKey: 'F2',
          },
          {
            title: t('payment.enterCard'),
            primaryKey: 'F3',
          },
          {
            title: t('payment.enterCash'),
            primaryKey: 'F4',
          },
          {
            title: t('payment.confirmPayments'),
            primaryKey: 'F8',
            secondaryKey: 'Enter',
          },
          {
            title: t('payment.clearAmount'),
            primaryKey: 'Del',
            secondaryKey: 'Backspace',
          },
        ],
      },
      {
        window: t('general.title'),
        keys: [
          {
            title: t('general.closeCancel'),
            primaryKey: 'Esc',
          },
          {
            title: t('general.confirm'),
            primaryKey: 'Enter',
          },
        ],
      },
      {
        window: t('settings.title'),
        keys: [
          {
            title: t('settings.collapseAll'),
            primaryKey: ['Ctrl', 'Shift', 'NumPad -'],
          },
          {
            title: t('settings.expandAll'),
            primaryKey: ['Ctrl', 'Shift', 'NumPad +'],
          },
        ],
      },
    ],
    [t],
  );

  const shortcuts = useMemo(
    () =>
      shortcutsToDisplay.map(s => (
        <div className="window" key={s.window}>
          <h5>{s.window}</h5>
          <ul>
            {s.keys.map(sk => (
              <li key={sk.title}>
                <div>
                  <span>{sk.title}</span>
                </div>
                <div>
                  <ShortcutText shortcut={sk.primaryKey} />
                  {sk.secondaryKey ? (
                    <>
                      <span>or</span>
                      <ShortcutText shortcut={sk.secondaryKey} />
                    </>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )),
    [shortcutsToDisplay],
  );

  return <div className="shortcuts">{shortcuts}</div>;
};

const ShortcutText = ({ shortcut }: { shortcut: string | string[] }) => {
  if (typeof shortcut === 'string') return <kbd>{shortcut}</kbd>;
  if (Array.isArray(shortcut))
    return R.pipe(
      R.map(key => <kbd key={key}>{key}</kbd>),
      R.intersperse(' + '),
    )(shortcut);
  throw new Error(
    `Illegal params passed to shortcutText - expected string or string array, but received ${JSON.stringify(
      shortcut,
    )}`,
  );
};
export default ShortcutsModal;
