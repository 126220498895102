import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { PosPlugin } from 'plugins/plugin';
import { REASONS } from 'constants/reasonCodesDB';

import { getAllPaymentTypes } from '../../../../reducers/PaymentTypes';

export type ExchangeConfigurationType = {
  selectedReturnReason?: string;
  selectedDiscountReason?: string;
  selectedPaymentType?: string;
};

const ComponentConfiguration: PosPlugin<
  ExchangeConfigurationType
>['ComponentConfiguration'] = ({ current = {}, save }) => {
  const [selectedReturnReason, setReturnReason] = useState(
    current.selectedReturnReason || '',
  );
  const [selectedDiscountReason, setDiscountReason] = useState(
    current.selectedDiscountReason || '',
  );
  const [selectedPaymentType, setPaymentType] = useState(
    current.selectedPaymentType || '',
  );

  const types = useSelector(getAllPaymentTypes);
  const returnReasons = useSelector(getReasonCodes(REASONS.RETURN));
  const discountReasons = useSelector(getReasonCodes(REASONS.DISCOUNT));

  const mapReasonsToOptions = (reasons: any, purpose: string) => {
    const options: any = [{ value: '', name: 'Select an option' }];

    reasons.forEach(reason => {
      if (reason.purpose === purpose.toUpperCase()) {
        options.push({
          value: reason.reasonID.toString(),
          name: reason.name,
        });
      }

      if (purpose.toUpperCase() === 'PAYMENT') {
        options.push({
          value: reason.id,
          name: reason.name,
        });
      }
    });

    return options;
  };

  return (
    <div>
      <p>
        In order to use the Exchange button function, you must select the
        payment type and reason codes that will be used to record exchange
        transactions (a return and a new sale for each exchange).
      </p>
      <p>
        Note: the payments for the return and the new sale will cancel each
        other out but should be recorded for accounting purposes.
      </p>
      <Form.Group>
        <Form.Label>Payment type for exchanges</Form.Label>
        <InputField
          type="select"
          onChange={e => setPaymentType(e.target.value)}
          options={mapReasonsToOptions(types, 'PAYMENT')}
          value={selectedPaymentType}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Return reason code for exchanges</Form.Label>
        <InputField
          type="select"
          onChange={e => setReturnReason(e.target.value)}
          options={mapReasonsToOptions(returnReasons, 'RETURN')}
          value={selectedReturnReason}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Discount reason code for exchanges</Form.Label>
        <InputField
          type="select"
          onChange={e => setDiscountReason(e.target.value)}
          options={mapReasonsToOptions(discountReasons, 'DISCOUNT')}
          value={selectedDiscountReason}
        />
      </Form.Group>
      <UIButton
        variant="POS"
        type="button"
        text="Save"
        action={() =>
          save({
            selectedDiscountReason,
            selectedPaymentType,
            selectedReturnReason,
          })
        }
      />
    </div>
  );
};

export default ComponentConfiguration;
