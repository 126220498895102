import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-use';

import WBUAPI from 'plugins/wbu/API/API';
import * as api from 'services/ErplyAPI/customers';
import { RootState } from 'reducers';

export const useCustomerExtraData = (customerID: string) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  return useAsync(
    () =>
      Promise.all([
        dispatch(WBUAPI.getCustomerStats({ [customerID]: null })).then(
          ({ data: [data] }) => data,
        ),
        dispatch(WBUAPI.getCustomerMemberships(customerID)).then(data => ({
          ...data,
          dscExpiration: data.endDateTime
            ? new Date(data.endDateTime)
            : undefined,
        })),
      ]).then(([a, b]) => ({ ...a, ...b })),
    [customerID],
  );
};
export const useCustomerRewardPoints = (customerID: string) => {
  return useAsync<number>(
    () =>
      api.getCustomerRewardPoints(customerID).then(([{ points }]) => points),
    [customerID],
  );
};
