import React from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

import styles from 'components/FieldTypes/skins/skins.module.scss';

import { Check, Select, Text } from './CtxInputs';

const ErrorBlock = ({ error = '' }) => {
  return (
    <Form.Control.Feedback
      type="invalid"
      style={{
        display: error ? 'block' : 'none',
      }}
    >
      {error}
    </Form.Control.Feedback>
  );
};

const RenderFormItem = props => {
  const { type, title, id, error, ...rest } = props;
  switch (type) {
    default:
      return null;
    case 'text':
    case 'password':
      return (
        <Form.Group>
          <Text
            title={title}
            name={id}
            type={type}
            prependTitle
            className={classNames([styles.formInput, styles.longTitle])}
            {...rest}
          />
          <ErrorBlock error={error} />
        </Form.Group>
      );
    case 'select':
      return (
        <Form.Group>
          <Select
            title={title}
            name={id}
            prependTitle
            className={classNames([
              styles.formInput,
              styles.mediumTitle,
              'current-integration-input',
            ])}
            {...rest}
          />
          <ErrorBlock error={error} />
        </Form.Group>
      );
    case 'checkbox':
      return (
        <Form.Group>
          <Check title={title} name={id} {...rest} />
          <ErrorBlock error={error} />
        </Form.Group>
      );
  }
};

export default RenderFormItem;
