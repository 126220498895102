import React from 'react';
import { useSelector } from 'react-redux';

import SaleInfoRows from 'containers/Forms/Customers/views/CustomerView/sales/SaleInfoRows';

import { getConfiguration } from '../rdx';

const UIRecentSalesInfoRow = (props: any) => {
  const currentConfig = useSelector(getConfiguration);
  const { rows } = props;

  const productsToDisplay = rows
    .filter(
      product =>
        product.itemName.toUpperCase() !==
        currentConfig?.envFeeName?.toUpperCase(),
    )
    .filter(
      product =>
        product.itemName.toUpperCase() !==
        currentConfig?.admissionTaxName?.toUpperCase(),
    );

  return <SaleInfoRows saleRows={productsToDisplay} />;
};

export default UIRecentSalesInfoRow;
