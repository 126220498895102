import { PatchScript } from '@pos-refacto/patchscript-with-react';

import { Payment } from 'types/Payment';
import { AppliedPromotionRecordResponse } from 'types/Promotions';
import { Employee } from 'types/Employee';
import { ReasonCode } from 'types/ReasonCodes';
import { PaymentType } from 'types/PaymentType';

import { CoreReceiptData, CurrencyUtil, OfflineReceiptData } from './types';
import { mmReceiptTranslations } from './pieces/translations/translations';
import { receiptLogo } from './pieces/1logo';
import { receiptOfflineBanner } from './pieces/2offlineBanner';
import { receiptHeaderDetails } from './pieces/3headerDetails';
import {
  receiptProductsTable,
  receiptProductsTableGiftReceipt,
} from './pieces/5productsTable';
import { receiptFooter } from './pieces/9footer';
import { receiptCardDetailsTable } from './pieces/6cardDetails';
import { assignPaymentTypeProps } from './utils';

type MMReceiptData = {
  company: {
    name: string;
    address: string;
  };
  warehouse: {
    fax: string;
    phone: string;
    /** Not available in offline dataset */
    code?: string;
  };
  employee: {
    name: string;
  };
  customer: {
    cardNumber: string;
  };
  register: {
    name: string;
  };
  receipt: {
    number: string;
  };
  misc: {
    currentTime: string;
  };
  totals: {
    total: number;
    vat: {
      byRate: {
        name?: string;
        rate?: string;
        total: string;
      }[];
    };
    net: number;
    discount: string;
    rounding: number;
  };
  payments: {
    type: string;
    typeTrans?: string;
    sum: string;
    cardNumber: string;
    cardType: string;
    typeNameRaw: 'GIVEX' | 'VOUCHER' | 'CASHCARD' | string;
    isCard: boolean;
  }[];
  rows: {
    productName?: string;
    amount: string;
    discount: number;
    rowNetTotal: string;
    finalNetPrice: string;
    originalNetPrice: string;
    discounts?: {
      unit: number;
      total: number;
      name: string;
      qty: number;
    }[];
  }[];
  cards: {
    sum: string;
    transactionType: string;
    typeTrans: string;
    cardNumber: string;
    cardType: string;
    expirationDate: string;
    referenceNumber: string;
    authorizationCode: string;
    certificateBalance: string;
  }[];
  footer: string;
  offline: boolean;
};

/**
 * Given M&M receipt data, and translations of the desired language, assembles them into a patchscript printout
 *
 * To generate M&M receipt data, pass online data (`prepareReceiptData`) into {@link fromOnlineData},
 * or offline data (`prepareOfflineData` + payments) into {@link fromOfflineData}
 *
 * @example
 * const patchscript = generateReceipt
 *   fromOfflineData(await dispatch(prepareOfflineData(...)), p.payments),
 *   mmReceiptTranslations.fr
 * )
 */
export function generateReceipt(
  data: MMReceiptData,
  t = mmReceiptTranslations.en,
  options: Partial<{ giftReceipt: boolean }> = {},
  CURR: CurrencyUtil,
): PatchScript {
  const out: PatchScript = [];
  out.push(receiptLogo);
  if (data.offline) out.push(receiptOfflineBanner(t));
  out.push(...receiptHeaderDetails(data, t));

  if (options.giftReceipt) {
    out.push(receiptProductsTableGiftReceipt(data, t, CURR));
  } else {
    out.push(...receiptProductsTable(data, t, CURR));
    out.push(...data.cards.map(card => receiptCardDetailsTable(card, CURR, t)));
  }
  out.push(...receiptFooter(t, data.receipt.number, data.footer));
  return out;
}

export function fromOnlineData(
  coreData: CoreReceiptData,
  promotions: AppliedPromotionRecordResponse[][],
  specialPriceLists: { memberRewards: number[]; flyerPrices: number[] },
  CURR: CurrencyUtil,
  employee: Employee | undefined,
  footer: string,
  discountReasons: ReasonCode[],
  paymentTypes: PaymentType[],
): MMReceiptData {
  const { memberRewards, flyerPrices } = specialPriceLists;
  return {
    offline: false,
    company: {
      name: coreData.company.name,
      address: coreData.company.address,
    },
    warehouse: {
      fax: coreData.warehouse.fax,
      phone: coreData.warehouse.phone,
      code: coreData.warehouse.code,
    },
    employee: {
      name: employee?.employeeName ?? '',
    },
    customer: {
      cardNumber: coreData.customer.customerLoyaltyCardCode,
    },
    register: {
      name: coreData.pointOfSale.name,
    },
    receipt: {
      number: coreData.salesDocumentNumber,
    },
    misc: {
      // Format: (BO date format) hh:mm:ss
      currentTime: `${coreData.date} ${coreData.time} `,
    },
    totals: {
      total: CURR.parse(coreData.billTable.total),
      vat: {
        byRate: coreData.billTable.vatRateRows
          .flatMap(({ components }) => components)
          .filter(({ rate }) => 0 < Number(rate))
          .map(comp => ({
            ...comp,
            total: comp.vatSum,
          })),
      },
      net: coreData.billTable.netTotal,
      discount: coreData.billTable.totalDiscountSum,
      rounding: coreData.billTable.rounding,
    },
    payments: coreData.billTable.payments.map(payment =>
      assignPaymentTypeProps(payment, paymentTypes),
    ),
    rows: coreData.billTable.billRows.map((row, i) => ({
      ...row,
      discounts: (function combineDiscountInformation(
        promotions,
        appliedDiscounts,
      ): MMReceiptData['rows'][number]['discounts'] {
        const mapped: MMReceiptData['rows'][number]['discounts'] = promotions.map(
          (p, j) => {
            function getDiscountName() {
              if (memberRewards.includes(Number(p.priceListID))) {
                return 'rewardsMember';
              }
              if (flyerPrices.includes(Number(p.priceListID))) {
                return 'flyerPrice';
              }
              if (p.promotionName) {
                return p.promotionName;
              }
              if (p.priceListName) {
                return p.priceListName;
              }
              if (appliedDiscounts[j]?.discountName) {
                return appliedDiscounts[j]?.discountName;
              }
              if (Number(p.manualDiscountReasonID)) {
                const matchingDiscount = discountReasons.find(
                  r => Number(r.reasonID) === Number(p.manualDiscountReasonID),
                );
                if (matchingDiscount) {
                  return matchingDiscount.name;
                }
              }
              return '';
            }

            return {
              total: CURR.parse(p.totalDiscount),
              unit: CURR.parse(p.unitDiscount),
              name: getDiscountName(),
              qty: CURR.parse(p.discountedAmount),
            };
          },
        );
        /*
         * There is one exception, however\:
         *   if promotion "Get these items for a fixed total $x" applies,
         *   manual discount gets overwritten by the fixed price, and is re-applied after the promotion.
         *   Therefore, manual discount may occur twice on one invoice line —
         *   to one part of the line quantity, the manual discount is applied before all promotions,
         *   and to another part of the line quantity it is applied after a specific promotion.
         * Source: https://learn-api.erply.com/requests/getappliedpromotionrecords
         */
        // Edge case cannot happen without at least 3 promotions - two manual discounts, and the fixed price between them
        if (promotions.length < 3) return mapped;
        // Otherwise check if first and last promotions are manual
        const startsWithManualPromotion =
          CURR.parse(promotions[0].manualDiscountPercentage) > 0;
        const endsWithManualPromotion =
          0 <
          CURR.parse(
            promotions[promotions.length - 1].manualDiscountPercentage,
          );
        // and there is some fixed price promotion between them
        const containsFixedPricePriceList = promotions.some(
          p => p.priceListDiscountType === 'PRICE',
        );
        if (
          startsWithManualPromotion &&
          containsFixedPricePriceList &&
          endsWithManualPromotion
        ) {
          return mapped.slice(1);
        }
        return mapped;
      })(promotions[i], row.appliedDiscounts),
    })),
    cards: coreData.billTable.payments
      .filter(pmt => pmt.isCard || pmt.cardType === 'GIVEX')
      .map(card => ({
        ...card,
        transactionType:
          0 < CURR.parse(coreData.billTable.total) ? 'SALE' : 'REFUND',
      })),
    footer,
  };
}
export function fromOfflineData(
  data: OfflineReceiptData,
  payments: Payment[],
  CURR: CurrencyUtil,
  employee: Employee | undefined,
  footer: string,
  paymentTypes: PaymentType[],
): MMReceiptData {
  return {
    offline: true,
    company: {
      name: data.documentActualReport.companyName,
      address: data.documentActualReport.companyAddressFull,
    },
    warehouse: {
      fax: data.documentActualReport.companyFax,
      phone: data.documentActualReport.companyPhone,
    },
    employee: {
      name: employee?.employeeName ?? '',
    },
    customer: {
      cardNumber: data.salesDocument.clientCardNumber,
    },
    register: {
      name: data.documentActualReport.registerName,
    },
    receipt: {
      number: data.documentActualReport.documentNumber,
    },
    misc: {
      // Format: yyyy-mm-dd hh:mm:ss
      currentTime: data.documentActualReport.currentDateTime,
    },
    totals: {
      total: data.documentActualReport.total,
      vat: {
        byRate: data.documentActualReport.vatTotalsByRate.map(rate => ({
          ...rate,
          total: String(rate.total),
        })),
      },
      net: data.documentActualReport.netTotal,
      discount: data.documentActualReport.totalDiscountSum,
      rounding: data.salesDocument.rounding,
    },
    payments: payments.map(payment =>
      assignPaymentTypeProps(payment, paymentTypes),
    ),
    rows: data.documentActualReport.documentRows.map((r, i) => ({
      ...r,
      discount: CURR.parse(r.discount),
    })),
    cards: [],
    footer,
  };
}
