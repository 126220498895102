import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Box,
  Divider,
  DialogContent,
  withStyles,
} from '@material-ui/core';
import * as R from 'ramda';

import CloseButton from 'components/CustomButtons/CloseButton';

import StyledButton from './StyledButton';

const StyledDialog = withStyles({
  paper: {
    fontFamily: 'foobar_m3',
    width: '500px',
  },
})(Dialog);

const StyledDialogContent = withStyles({
  root: {
    width: '100%',
    maxHeight: '600px',
    '&>div': {
      justifyContent: 'center',
    },
  },
})(DialogContent);

type Props = {
  title: string;
  height?: number;
  children: React.ReactElement;
};

const PopupButton: React.FC<Props> = ({ title, height = 65, children }) => {
  const [open, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <StyledButton
        onClick={() => setOpen(true)}
        height={height}
        variant="contained"
        color="grid_light"
        width="100%"
      >
        {title}
      </StyledButton>
      <StyledDialog open={open} onClose={onClose}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="0.25rem"
        >
          <DialogTitle>{title}</DialogTitle>
          <CloseButton style={{ marginRight: '0.5rem' }} action={onClose} />
        </Box>
        <Divider />
        <StyledDialogContent>
          {R.assocPath(['props', 'beforeSelect'], onClose, children)}
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
};

export default PopupButton;
