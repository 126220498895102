import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { ErplyAttributes } from 'utils';
import { calculateFairSplits } from 'plugins/hipPocket/payForInvoices/fairSplit';

import { UIAccountSalesForm, UIUnpaidInvoices } from './components';
import {
  clearSelectedSales,
  setSelectedSales,
  setPurchaseOrderNumber,
} from './rdx/actions';
import hpReducer, { getHipPocketState } from './rdx/reducers';
import { splitPayment } from './payForInvoices/splitPaymentsBetweenInvoices';

const pluginID = 'hip-pocket-plugin';

const HipPocketPlugin: PosPlugin = {
  id: pluginID,
  name: 'HipPocket Customizations',
  keywords: ['hippocket', 'customizations'],
  getStatus: () => {
    return {
      type: 'valid',
      message: `Ready`,
    };
  },
  reduxReducer: hpReducer,
  UIAccountSalesForm,
  UIUnpaidInvoices,
  /** If there's a purchaseOrderNumber recorded in redux, save it to the document's attributes */
  onSaveSalesDocument: {
    on: (p, requests) => async (dispatch, getState) => {
      const { purchaseOrderNumber, selectedSales } = getHipPocketState(
        getState(),
      );

      const setAttr = (attr, value) => request => ({
        ...ErplyAttributes.withoutFlatArray(request),
        ...ErplyAttributes.fromFlatArray(request).set(attr, value).asFlatArray,
      });

      /**
       * If a purchaseOrderNumber has been recorded in redux, apply that to the saveSalesDocument request(s)
       */
      function recordPurchaseOrderNumber(requests) {
        if (!purchaseOrderNumber.length) return requests;
        return requests.map(request => {
          if (request.requestName !== 'saveSalesDocument') return request;
          return setAttr('purchaseOrder', purchaseOrderNumber);
        });
      }

      /**
       * If target documents have been recorded in redux, split the payment evenly between those documents
       */
      function splitPaymentAcrossTargetDocuments(requests) {
        if (!Object.keys(selectedSales).length) return requests;

        // Update remaining to-pay amount as you go
        let remainingToPay = { ...selectedSales };
        const newRequests = requests.flatMap(request => {
          if (request.requestName === 'saveSalesDocument') {
            return R.pick(['requestName', 'id'], request);
          }
          if (request.requestName === 'savePayment') {
            const [pmts, newRemaining] = splitPayment(request, remainingToPay);
            remainingToPay = newRemaining;
            return pmts;
          }
          return [request];
        });
        // sanity check
        if (Object.values(remainingToPay).some(v => Math.abs(v) > 0.005)) {
          throw new Error('Failed to evenly cover document totals');
        }
        dispatch(setSelectedSales(remainingToPay));
        return newRequests;
      }

      return R.pipe(
        recordPurchaseOrderNumber,
        splitPaymentAcrossTargetDocuments,
      )(requests);
    },
    after: () => async dispatch => {
      dispatch(setPurchaseOrderNumber(''));
    },
  },
  onClosePayments: {
    after: (p, o) => async dispatch => {
      dispatch(clearSelectedSales);
    },
  },
  onResetShoppingCart: {
    after: () => async dispatch => {
      dispatch(setPurchaseOrderNumber(''));
    },
  },
};

export default HipPocketPlugin;
