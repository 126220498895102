import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { addProduct } from 'actions/ShoppingCart/addProduct';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { getAllWarehouses } from 'reducers/warehouses';

import ProductView from '../ProductView';

const StockAndPriceItem = ({ productItem }) => {
  const { t } = useTranslation('product');
  const dispatch = useDispatch();
  const warehouses = useSelector(getAllWarehouses);
  const addProductAction = options => {
    dispatch(addProduct(options));
    dispatch(closeModalPage());
  };
  return (
    <ProductView
      title={t('stockAndPrice.title')}
      productItem={productItem}
      warehouses={warehouses}
      addProduct={addProductAction}
      previousModalPage={() => dispatch(previousModalPage())}
    />
  );
};

export default StockAndPriceItem;
