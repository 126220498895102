// eslint-disable-next-line import/prefer-default-export

import { Customer } from 'types/Customer';

export const generateCustomPaymentKey = (
  keys: string[],
  type: string,
): string => {
  if (keys.length) {
    const lastKey = keys[keys.length - 1];
    return type.concat(`${Number(lastKey.replace(type, '')) + 1}`);
  }
  return `${type}1`;
};

export const getCustomerAndContactFieldsForSaveSalesDocument = (
  customer: Customer,
) => {
  const { customerID, companyID } = customer;
  if (companyID) {
    return { customerID: companyID, contactID: customerID };
  }
  return { customerID };
};
