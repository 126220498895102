import { TextField, TextFieldProps } from '@material-ui/core';
import {
  AutocompleteProps,
  Autocomplete as MuiAutocomplete,
} from '@material-ui/lab';
import React from 'react';

function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  inputProps,
  ...rest
}: Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
> & {
  inputProps: TextFieldProps & {
    'data-testid'?: string;
    'data-test-key'?: string;
  };
}) {
  return (
    <MuiAutocomplete
      {...rest}
      renderInput={params => <TextField {...params} {...inputProps} />}
    />
  );
}

export default Autocomplete;
