import React, { useEffect, useCallback, useMemo } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debug from 'debug';

import {
  getSelectedPos,
  getEmployeePOSByWarehouseIDS,
} from 'reducers/PointsOfSale';
import { selectPos } from 'actions/PointsOfSale/selectPos';
import { getAllRegisters, getSetting } from 'reducers/configs/settings';
import Loader from 'components/Loader';
import { getIsLoading } from 'reducers/OpenCloseDay';
import {
  getSelectedWarehouse,
  getAllowedWarehouses,
} from 'reducers/warehouses';
import { UrlControl } from 'services/UrlControl/UrlControl';
import { getVatRatesFetching } from 'reducers/vatRatesDB';
import useAssociatedPos from 'utils/hooks/useAssociatedPos';

const baseLog = debug('PosSelect');

const log = baseLog.extend('useAutoSelectPos');

const useAutoSelectPos = (onSelect, allOptions, allowedOptions) => {
  const selectedPOS = useSelector(getSelectedPos);
  const associatedPosId = useSelector(
    getSetting('CONFIGURATION: associated-pos'),
  );

  const associatedPosNew = useAssociatedPos();

  const posFrom = {
    urlControl: allOptions.find(
      pos =>
        Number(pos.pointOfSaleID) === UrlControl.autoSelectPos?.pointOfSaleID,
    )?.pointOfSaleID,
    associatedPosNew: allowedOptions.find(
      pos => String(pos.pointOfSaleID) === String(associatedPosNew.posID),
    )?.pointOfSaleID,
    associatedPos: allowedOptions.find(
      pos => String(pos.pointOfSaleID) === String(associatedPosId),
    )?.pointOfSaleID,
    alreadySelected: allowedOptions.find(
      pos => String(pos.pointOfSaleID) === String(selectedPOS?.pointOfSaleID),
    )?.pointOfSaleID,
  };

  const autoSelect = useMemo(() => {
    /* UrlControl POS ID */
    if (posFrom.urlControl) {
      log('Using POS from UrlControl', posFrom.urlControl);
      return () => onSelect(posFrom.urlControl);
    }
    if (associatedPosNew.enabled) {
      log('Using new POS association setting', posFrom.associatedPosNew);
      if (associatedPosNew.loading) return null;
      return () =>
        associatedPosNew.autoSelect(posFrom.associatedPosNew, onSelect, true);
    }
    if (posFrom.associatedPos) {
      log('Using POS from associated POS setting', posFrom.associatedPos);
      return () => onSelect(posFrom.associatedPos);
    }
    if (posFrom.alreadySelected) {
      log('Using POS that is already selected', posFrom.alreadySelected);
      return () => onSelect(posFrom.alreadySelected);
    }
    log('No valid autoselect targets found', { allOptions, allowedOptions });
    return null;
  }, [
    posFrom.urlControl,
    posFrom.associatedPos,
    posFrom.alreadySelected,
    posFrom.associatedPosNew,
    associatedPosNew,
    allOptions,
    allowedOptions,
    onSelect,
  ]);

  useEffect(() => {
    if (autoSelect) {
      autoSelect();
    }
  }, [autoSelect]);
};

export default function PosSelect({ onCancel, toggleClockInOut, updateView }) {
  const dispatch = useDispatch();
  const onSelect = useCallback(id => dispatch(selectPos(id)), [dispatch]);

  const showAllRegisters = useSelector(getAllRegisters);
  const { t } = useTranslation('login');

  const selectedPOS = useSelector(getSelectedPos);

  const loadingDayOpen = useSelector(getIsLoading) && !!selectedPOS;

  const allowedWarehouses = useSelector(getAllowedWarehouses);
  const selectedWarehouse = useSelector(getSelectedWarehouse);
  const allowedWarehouseIDS = allowedWarehouses.map(wh => wh.warehouseID);
  const pointsByWarehouseIDS = useSelector(getEmployeePOSByWarehouseIDS);
  const pointsOfSale = useMemo(() => {
    if (selectedWarehouse) {
      return pointsByWarehouseIDS[selectedWarehouse.warehouseID] ?? [];
    }
    return allowedWarehouseIDS.flatMap(id => pointsByWarehouseIDS[id] || []);
  }, [selectedWarehouse, pointsByWarehouseIDS, allowedWarehouseIDS]);

  const filteredPointsOfSale = pointsOfSale?.filter(pos =>
    showAllRegisters ? true : !['ECOM'].includes(pos?.type),
  );

  useAutoSelectPos(onSelect, pointsOfSale, filteredPointsOfSale);

  const vatRatesFetching = useSelector(getVatRatesFetching);

  return (
    <Loader
      show={loadingDayOpen || (selectedPOS && vatRatesFetching)}
      loadingText={t('loading.login', { context: 'checkingIfDayOpen' })}
    >
      <Card data-testid="pos-select-container" style={styles.card}>
        <Card.Header style={styles.header}>
          <span data-testid="title">{t('selectPos.title')}</span>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <span
            className="icon_close Clickable-icon"
            data-testid="close-icon"
            style={styles.close}
            onClick={() => {
              onCancel();
              toggleClockInOut(false);
              updateView('login');
            }}
          />
        </Card.Header>
        <Card.Body data-testid="body" style={styles.body}>
          <ListGroup style={{ border: 0 }}>
            {filteredPointsOfSale.map(pos => (
              <ListGroup.Item
                key={pos.pointOfSaleID}
                data-testid="pos-item"
                data-test-key={pos.pointOfSaleID}
                onClick={() => onSelect(pos.pointOfSaleID)}
                style={styles.pos}
                className="Clickable-div"
              >
                <span
                  style={{ fontSize: '24px' }}
                  data-testid="warehouse-name"
                  data-test-key={pos.warehouseName}
                >
                  {pos.warehouseName}
                </span>
                <br />
                <span data-testid="pos-name" data-test-key={pos.name}>
                  {pos.name}
                </span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </Loader>
  );
}

PosSelect.propTypes = {
  onCancel: PropTypes.func.isRequired,
  toggleClockInOut: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
};

const styles = {
  card: {
    width: '358px',
    maxWidth: '100%',
    borderRadius: '8px',
    border: 0,
  },
  header: {
    fontWeight: 700,
    fontSize: '24px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    height: '66px',
    padding: '15px 15px 15px 22px',
  },
  body: {
    maxHeight: '70vh',
    overflowY: 'scroll',
    padding: '16px 0px 0px',
    marginBottom: '20px',
  },
  close: {
    fontSize: '42px',
  },
  pos: {
    minHeight: '67px',
    padding: '16px 22px',
    textAlign: 'left',
    color: '#555',
    border: 0,
    borderRadius: 0,
  },
};
