import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useBreakpoints } from 'utils/hooks/UI';
import { getGridIsOpened } from 'reducers/UI/gridDisplay';

import {
  CustomerType,
  FormFieldConfiguration as FormFieldConfig,
  FORM_FIELDS,
  CustomerFormConfiguration,
  FormFieldName,
} from '../../types';

import {
  FieldConfigurationDesktop,
  FieldConfigurationMobile,
} from './FieldConfiguration';

const useStyles = makeStyles({
  table: {
    '& table': {
      tableLayout: 'fixed',
    },
    '& td, & th': {
      padding: '10px',
      '&:first-child': {
        minWidth: '210px',
        width: '40%',
      },
      '&:nth-child(2)': {
        minWidth: '170px',
        width: '27%',
      },
      '&:nth-child(3)': {
        minWidth: '170px',
        width: '23%',
      },
      '&:nth-child(4), &:nth-child(5)': {
        width: '70px',
      },
    },
    '& td': {
      padding: '0 10px',
    },
  },
});

interface Props {
  config: CustomerFormConfiguration;
  customerType: CustomerType;
  updateConfigPerField(field: FormFieldName, config: FormFieldConfig): void;
}

function FormFieldConfigurationDesktop({
  config,
  customerType,
  updateConfigPerField,
}: Props) {
  const styles = useStyles();
  const { t } = useTranslation('createCustomer');

  return (
    <TableContainer className={styles.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow style={{ fontWeight: 'bold' }}>
            <TableCell>Field Name</TableCell>
            <TableCell>Default value</TableCell>
            <TableCell>Disabled on</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell>Required</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {((FORM_FIELDS[customerType] as unknown) as string[]).map(field => {
            const fieldConfig = config?.formFields?.[customerType]?.[field];
            return (
              <FieldConfigurationDesktop
                key={field}
                field={(field as unknown) as FormFieldName}
                label={t(`fields.${field}`)}
                config={fieldConfig}
                updateConfig={updateConfigPerField}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function FormFieldConfigurationMobile({
  config,
  customerType,
  updateConfigPerField,
}: Props) {
  const { t } = useTranslation('createCustomer');

  return (
    <Grid container>
      {((FORM_FIELDS[customerType] as unknown) as string[]).map(field => {
        const fieldConfig = config?.formFields?.[customerType]?.[field];
        return (
          <FieldConfigurationMobile
            key={field}
            field={(field as unknown) as FormFieldName}
            label={t(`fields.${field}`)}
            config={fieldConfig}
            updateConfig={updateConfigPerField}
          />
        );
      })}
    </Grid>
  );
}

function FormFieldConfiguration({
  config,
  customerType,
  updateConfigPerField,
}: Props) {
  const isMobileView = !useBreakpoints().sm;
  const isGridDisplay = useSelector(getGridIsOpened);

  const Component =
    isMobileView || !isGridDisplay
      ? FormFieldConfigurationMobile
      : FormFieldConfigurationDesktop;

  return (
    <Component
      config={config}
      customerType={customerType}
      updateConfigPerField={updateConfigPerField}
    />
  );
}

export default FormFieldConfiguration;
