type FieldValue = {
  name: string;
  label: string;
  responseName: string;
  apiParam: string;
  customerRegistryParam?: string;
};

export const pluginID = 'newCustomerDuplicateWarnings';
export const pluginName = 'Customer creation duplicate warnings';

type Person = Record<
  | 'email'
  | 'phone'
  | 'mobile'
  | 'firstName'
  | 'lastName'
  | 'customerCardNumber'
  | 'registryCode',
  FieldValue
>;
export type CustomerType = 'PERSON' | 'COMPANY';

type State = Record<CustomerType, Record<string, FieldValue>>;

export const globalFields: State = {
  PERSON: {
    email: {
      name: 'email',
      label: 'Email',
      responseName: 'email',
      apiParam: 'searchName',
      customerRegistryParam: 'email',
    },
    phone: {
      name: 'phone',
      label: 'Phone',
      responseName: 'phone',
      apiParam: 'searchName',
      customerRegistryParam: 'phone',
    },
    mobile: {
      name: 'mobile',
      label: 'Mobile',
      responseName: 'mobile',
      apiParam: 'searchName',
      customerRegistryParam: 'mobilePhone',
    },
    firstName: {
      name: 'firstName',
      label: 'First name',
      responseName: 'firstName',
      apiParam: 'searchPersonFirstName',
      customerRegistryParam: 'firstName',
    },
    lastName: {
      name: 'lastName',
      label: 'Last name',
      responseName: 'lastName',
      apiParam: 'searchPersonLastName',
      customerRegistryParam: 'lastName',
    },
    customerCardNumber: {
      name: 'customerCardNumber',
      label: 'Customer card number',
      responseName: 'customerCardNumber',
      apiParam: 'searchCode',
    },
    registryCode: {
      name: 'code',
      label: 'Code',
      responseName: 'code',
      apiParam: 'searchRegistryCode',
    },
  },
  COMPANY: {
    companyName: {
      name: 'companyName',
      label: 'Company name',
      responseName: 'companyName',
      apiParam: 'searchName',
    },
    vatNumber: {
      name: 'vatNumber',
      label: 'VAT number',
      responseName: 'vatNumber',
      apiParam: 'searchVATNo',
    },
    customerCardNumber: {
      name: 'customerCardNumber',
      label: 'Customer card number',
      responseName: 'customerCardNumber',
      apiParam: 'searchCode',
    },
    email: {
      name: 'email',
      label: 'Email',
      responseName: 'email',
      apiParam: 'searchName',
      customerRegistryParam: 'email',
    },
    phone: {
      name: 'phone',
      label: 'Phone',
      responseName: 'phone',
      apiParam: 'searchName',
      customerRegistryParam: 'phone',
    },
    mobile: {
      name: 'mobile',
      label: 'Mobile',
      responseName: 'mobile',
      apiParam: 'searchMobile',
    },
  },
};

export type Conf = {
  fields?: Record<CustomerType, Record<string, boolean>>;
  searchInCombination?: Record<CustomerType, boolean>;
  hideButton?: boolean;
};
