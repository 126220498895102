import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Badge from 'react-bootstrap/Badge';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Trans, useTranslation } from 'react-i18next';

import {
  getCloudSync,
  getCloudSyncTotalPercentageGetter,
  getRequestsToSync,
} from 'reducers/connectivity/cloudSync';
import Icon from 'components/Icon';
import { getClientCode } from 'reducers/Login';
import { setCloudStatus, syncLocalDatabaseSO } from 'actions/connectivity';
import { resetDB } from 'services/DB';
import { round } from 'utils';
import { stopSync } from 'services/DB/syncDB';

import HeaderIcon from '../Icon';

import styles from './CloudSync.module.scss';

const PendingRequests = ({ time, todo, syncing, progress, done }) => {
  const { t } = useTranslation('header');
  return (
    <Dropdown.Item
      as="div"
      className="text-center"
      data-testid="pending-requests"
    >
      <strong>{t('sync.requests.title')}</strong>

      {(!todo && !syncing && (
        <>
          <div>{t('sync.requests.done1')}</div>
          <div>
            {t('sync.requests.done2', {
              time: time.toLocaleTimeString(),
            })}
          </div>
        </>
      )) ||
        null}

      {(todo && !syncing && (
        <>
          <div>
            <Trans i18nKey="header:sync.requests.waiting" tOptions={{ todo }}>
              <strong />
            </Trans>
          </div>
        </>
      )) ||
        null}
      {((todo || progress) && syncing && (
        <>
          <div>
            <Trans
              i18nKey="header:sync.requests.processing"
              tOptions={{ progress, todo }}
            >
              <strong />
            </Trans>
          </div>
        </>
      )) ||
        null}
      {(!todo && !progress && syncing && (
        <>
          <div>{t('sync.requests.done1')}</div>
        </>
      )) ||
        null}
      {(syncing && (
        <ProgressBar
          max={todo + progress + done}
          data-testid="progressbar"
          data-test-todo={todo}
          data-test-progress={progress}
          data-test-done={done}
        >
          <ProgressBar
            variant="success"
            now={done}
            max={todo + progress + done}
          />
        </ProgressBar>
      )) ||
        null}
    </Dropdown.Item>
  );
};

const InterpolatingProgressBar = ({
  done,
  step,
  startTime,
  endTime,
  total,
  ...props
}) => {
  const [time, setTime] = useState(new Date().getTime());
  const deltaTime = time - startTime;
  const totalTime = endTime - startTime;
  const fracTime = Math.min(1, deltaTime / totalTime);
  useEffect(() => {
    const i = setInterval(() => setTime(new Date().getTime()), 500);
    return () => clearInterval(i);
  }, []);
  return (
    <>
      <ProgressBar
        max={total}
        data-testid="progressbar"
        data-test-todo={total - step - done}
        data-test-progress={step}
        data-test-done={done}
      >
        <ProgressBar variant="success" now={done} max={total} />
        <ProgressBar
          striped
          animated
          variant="warning"
          now={step * fracTime}
          max={total}
          {...props}
        />
      </ProgressBar>
    </>
  );
};
const CloudSync = ({
  name,
  done,
  progress,
  todo,
  start,
  next,
  time,
  syncing,
}) => {
  const { t } = useTranslation('header');
  const clientCode = useSelector(getClientCode);
  const dispatch = useDispatch();

  return (
    <Dropdown.Item
      as="div"
      className="text-center"
      key={name}
      data-testid="cloudsync-item"
      data-test-key={name}
    >
      <Icon
        name="icon_refresh"
        data-testid="icon-refresh"
        data-test-key={`icon-refresh-${name}`}
        action={async e => {
          e.stopPropagation();
          await dispatch(
            setCloudStatus(name, { done: 0, progress: 0, todo: 0 }),
          );
          await stopSync(name);
          dispatch(syncLocalDatabaseSO(name));
        }}
        title={t('sync.database.refreshTitle')}
      />
      <strong data-testid="db-name" data-test-key={name}>
        {name}
      </strong>
      <Icon
        name="icon_trash"
        data-testid="icon-trash"
        data-test-key={`icon-trash-${name}`}
        action={async e => {
          e.stopPropagation();
          await dispatch(
            setCloudStatus(name, {
              done: 0,
              progress: 0,
              todo: 0,
              syncing: true,
            }),
          );

          await stopSync(name);
          await resetDB(clientCode, name);
          dispatch(syncLocalDatabaseSO(name));
        }}
        title={t('sync.database.trashTitle')}
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {syncing ? (
        todo || progress || done ? (
          <div data-testid="syncing-block" data-test-key={name}>
            <Trans
              i18nKey="header:sync.database.processing"
              tOptions={{ done, progress, todo }}
            >
              <strong />
            </Trans>

            <InterpolatingProgressBar
              done={done}
              step={progress}
              startTime={start}
              endTime={start + 8000}
              total={done + progress + todo}
              data-testid="progress-bar"
              data-test-key={`progress-bar-${name}`}
            />
          </div>
        ) : (
          <div>
            <strong
              data-testid="waiting-sync"
              data-test-key={`waiting-sync-${name}`}
            >
              {t('sync.database.waiting')}
            </strong>
          </div>
        )
      ) : done ? (
        <>
          <div
            data-testid="synced-count"
            data-test-key={`synced-count-${name}`}
          >
            {t('sync.database.done1', { done })}
          </div>
          <div
            data-testid="done-syncing"
            data-test-key={`done-syncing-${name}`}
          >
            {t('sync.database.done2', {
              time: time.toLocaleTimeString(),
            })}
          </div>
        </>
      ) : (
        <div>
          <strong data-testid="empty-sync" data-test-key={`empty-sync-${name}`}>
            {t('sync.database.empty')}
          </strong>
        </div>
      )}
    </Dropdown.Item>
  );
};

const HeaderCloudSync = () => {
  const getPercentage = useSelector(getCloudSyncTotalPercentageGetter);
  const [percentage, setPercentage] = useState(getPercentage());
  useEffect(() => {
    const i = setInterval(() => setPercentage(getPercentage()), 500);
    return () => clearInterval(i);
  }, [getPercentage]);
  const cloudSync = useSelector(getCloudSync);
  const requestsToSync = useSelector(getRequestsToSync);

  const offline = percentage <= 0;
  const partial = percentage > 0 && percentage < 1;
  const online = percentage >= 1;

  const { t } = useTranslation('header');
  return (
    <Dropdown
      className="erply-header__dropdown erply-header__dropdown--sync"
      data-testid="header-cloud-sync"
      alignRight
    >
      <Dropdown.Toggle as="div">
        <HeaderIcon
          className={classNames(styles.syncIcon, {
            [styles.offline]: offline,
            [styles.updating]: partial,
            [styles.online]: online,
          })}
          icon="cloud"
          title={
            (offline && t('sync.title', { context: 'fail' })) ||
            (partial && t('sync.title', { context: 'pending' })) ||
            (online && t('sync.title', { context: 'done' }))
          }
        />
        {!partial && requestsToSync > 0 && (
          <Badge
            style={{
              position: 'absolute',
              bottom: -5,
              fontSize: 10,
              left: -10,
              right: -10,
            }}
            variant="warning"
            data-testid="cloudsync-notification-request-count"
          >
            {requestsToSync}
          </Badge>
        )}
        {partial && (
          <Badge
            style={{
              position: 'absolute',
              bottom: -5,
              fontSize: 10,
              left: -10,
              right: -10,
              zIndex: 50000,
            }}
            variant="warning"
            data-testid="cloudsync-notification-sync-percentage"
          >
            {round(percentage * 100, 2)}%
          </Badge>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{
          /* GPU acceleration is achieved by using translate3D,
          which causes text to be rendered unsharply at zoom levels other than 100% */
          modifiers: {
            offset: {
              enabled: true,
              offset: '1px',
            },
            computeStyle: {
              gpuAcceleration: false,
            },
          },
        }}
        data-testid="cloudsync-menu"
      >
        {cloudSync.map(
          ({ name, todo, done, progress, start, next, time, syncing }) => {
            if (name === 'pendingRequests') {
              return (
                <PendingRequests
                  key={name}
                  todo={todo}
                  syncing={syncing}
                  time={time}
                  progress={progress}
                  done={done}
                />
              );
            }
            return (
              <CloudSync
                key={name}
                name={name}
                done={done}
                progress={progress}
                todo={todo}
                start={start}
                next={next}
                time={time}
                syncing={syncing}
              />
            );
          },
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderCloudSync;
