/* eslint-disable import/prefer-default-export */
import { doClientRequest } from './core/ErplyAPI';

export const saveCompanyInfo = company =>
  doClientRequest({
    ...company,
    request: 'editCompanyInfo',
  });

export const saveWarehouseInfo = warehouse =>
  doClientRequest({
    ...warehouse,
    request: 'saveWarehouse',
  });

export const saveAddressInfo = address =>
  doClientRequest({
    ...address,
    request: 'saveAddress',
  });
