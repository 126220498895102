import * as R from 'ramda';

import { SO, SOName, SyncableSOName } from 'services/DB/types';
import * as types from 'constants/cachedItems';
import { getDB } from 'services/DB';
import { getClientCode } from 'reducers/Login';
import { getSelectedWarehouse } from 'reducers/warehouses';
import { getCachedItemsPerTypeByIDs } from 'reducers/cachedItems';

export function addItemsToCache(
  itemType: Exclude<SOName, typeof SO.META.NAME | typeof SO.SHARED.NAME>,
  items: Record<string | number, any>,
) {
  return {
    type: types.ADD_TO_CACHE,

    payload: {
      itemType,
      items,
    },
  };
}

export function addChangedItemsToCache(
  itemType: Exclude<SOName, typeof SO.META.NAME | typeof SO.SHARED.NAME>,
  items: Record<string | number, any>,
) {
  return (dispatch, getState) => {
    const cachedItems = getCachedItemsPerTypeByIDs(
      itemType,
      Object.keys(items),
    )(getState());
    const changedItems = Object.entries(items).filter(
      ([id, item]) => !R.equals(cachedItems[id], item),
    );

    if (changedItems.length) {
      dispatch(addItemsToCache(itemType, Object.fromEntries(changedItems)));
    }
  };
}

export function removeItemsFromCache(
  itemType: SOName,
  ids: (string | number)[],
) {
  return {
    type: types.REMOVE_FROM_CACHE,

    payload: {
      itemType,
      ids,
    },
  };
}

export function resetCache(itemType: SOName) {
  return {
    type: types.RESET_CACHE,

    payload: {
      itemType,
    },
  };
}

export function setItemsInCache(
  itemType: SOName,
  items: Record<string | number, any>,
) {
  return {
    type: types.SET_CACHE,

    payload: {
      itemType,
      items,
    },
  };
}

export function syncCachedItems() {
  return async (dispatch, getState) => {
    try {
      const clientCode = getClientCode(getState());
      const warehouse = getSelectedWarehouse(getState());
      const warehouseID = Number(warehouse?.warehouseID ?? 0);
      const assortmentID = Number(warehouse?.assortmentID ?? 0);
      const db = await getDB(clientCode);

      const promises = Object.values(SO)
        .filter(i => i.REQUEST_NAME && i.NAME !== SO.PRODUCTS.NAME)
        .map(CURRENT_SO => {
          let _promise;
          switch (CURRENT_SO.NAME) {
            case SO.PRODUCT_STOCK.NAME:
              _promise = db
                .transaction(CURRENT_SO.NAME)
                .store.index(CURRENT_SO.INDEXES.WAREHOUSE_ID.indexName)
                .getAll(warehouseID);
              break;
            case SO.ASSORTMENTS.NAME:
              _promise = db
                .transaction(CURRENT_SO.NAME)
                .store.index(CURRENT_SO.INDEXES.ASSORTMENT_ID.indexName)
                .getAll(assortmentID);
              break;
            default:
              _promise = db.transaction(CURRENT_SO.NAME).store.getAll();
              break;
          }
          return _promise.then(data =>
            dispatch(
              addItemsToCache(
                CURRENT_SO.NAME as SyncableSOName,
                Object.fromEntries(data.map(d => [d[CURRENT_SO.ID_PROP], d])),
              ),
            ),
          );
        });

      await Promise.all(promises);
    } catch (e) {
      console.error('Failed to update local cache from IDB', e);
    }
  };
}
