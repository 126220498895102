import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import ListItem from 'components/ListItem';
import { useBreakpoints } from 'utils/hooks/UI';
import {
  deletePayment,
  setPaymentEditValue,
  setShowPaymentInput,
} from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import {
  getBalance,
  getLastPayment,
  getPaymentsCurrency,
  getShowPaymentInput,
} from 'reducers/Payments';
import { addWarning } from 'actions/Error';

const AddCashPayment = ({ disabled = false }) => {
  const { t } = useTranslation('payment');
  const balance = useSelector(getBalance);
  const isMobileView = !useBreakpoints().mdplus;
  const dispatch = useDispatch();
  const { lastPayment, lastKey } = useSelector(getLastPayment);
  const { showPaymentsInput } = useSelector(getShowPaymentInput);
  const { code: currencyCode, rate } = useSelector(getPaymentsCurrency);

  const addCashPayment = () => {
    if (balance === 0.0) {
      return dispatch(
        addWarning(t('alerts.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
    if (disabled) {
      return;
    }
    (async () => {
      await dispatch(setPaymentEditValue(''));

      if (lastPayment.amount === '0.00')
        dispatch(deletePayment({ key: lastKey }));

      await dispatch(
        setPayment({
          amount: '0.00',
          key: `${currencyCode}-cash`,
          caption: t('tenders.cash'),
          type: 'CASH',
          currencyCode,
          currencyRate: Number(rate),
        }),
      );
    })();
  };
  useShortcut('F4', addCashPayment, 20);
  return isMobileView ? (
    <li className="payment-item cash-payment" style={{ cursor: 'pointer' }}>
      <div
        tabIndex={0}
        className="button"
        role="button"
        onClick={() =>
          dispatch(setShowPaymentInput(!showPaymentsInput, 'CASH'))
        }
      >
        <span className="title">{t('buttons.enterCash')}</span>
      </div>
    </li>
  ) : (
    <ListItem
      data-testid="cash-payment-button"
      disabled={disabled}
      variant="grid_dark"
      action={addCashPayment}
    >
      <span>{t('buttons.cash')}</span>

      <span className="func-key">F4</span>
    </ListItem>
  );
};

export default AddCashPayment;
