import i18next from 'i18next';

import { addError } from 'actions/Error';
import * as api from 'services/ErplyAPI/productGroups';

import * as c from '../constants/productGroupsDB';
import { getProductGroupByID } from '../reducers/productGroupsDB';

function flatten(objects) {
  return Object.assign(
    {},
    ...objects.map(object => ({
      [object.id]: {
        ...object,
        subGroups: object.subGroups.map(sg => sg.id),
      },
      ...flatten(object.subGroups),
    })),
  );
}

export function productGroupsArray(flatData) {
  return Object.entries(flatData).map(e => e[1]);
}

export function fetchProductGroups() {
  return async dispatch => {
    dispatch({ type: c.GET_PRODUCT_GROUPS });
    try {
      const data = await api.getProductGroups({ getAttributes: 1 });
      dispatch({ type: c.GET_PRODUCT_GROUPS_SUCCESS, payload: flatten(data) });
    } catch (err) {
      console.error('Failed to load product groups', err);
      dispatch({ type: c.GET_PRODUCT_GROUPS_ERROR });
    }
  };
}

/**
 * @param {{
 *   productGroupID: string,
 *   name: string,
 *   nameEST: string,
 *   nameENG: string,
 *   nameLAT: string,
 *   nameRUS: string,
 *   nameFIN: string,
 *   parentGroupID: string,
 *   showInWebshop: number,
 * }} params
 */
export function saveProductGroup(params) {
  return async (dispatch, getState) => {
    if (!params.id && params.name !== undefined) {
      if (params.name === '') {
        dispatch(
          addError(i18next.t('validation:productGroup.nameRequired'), {
            dismissible: true,
            errorType: 'addProductGroup',
            selfDismiss: true,
          }),
        );
        return true;
      }
      if (params.name.length > 255) {
        dispatch(
          addError(
            i18next.t('validation:productGroup.groupNameMaxLength', { max: 255 }),
            {
              dismissible: true,
              errorType: 'addProductGroup',
              selfDismiss: true,
            },
          ),
        );
        return true;
      }
    }
    dispatch({ type: c.SAVE_PRODUCT_GROUPS });
    try {
      const [data] = await api.saveProductGroup(params);
      const [newGroup] = await api.getProductGroups({
        productGroupID: data.productGroupID,
      });
      const parentGroup = {
        ...getProductGroupByID(newGroup.parentGroupID)(getState()),
      };
      newGroup.subGroups = getProductGroupByID(newGroup.id)(getState()).subGroups;
      parentGroup.subGroups = [
        ...new Set([...parentGroup.subGroups, newGroup.id]),
      ];
      dispatch({ type: c.SAVE_PRODUCT_GROUPS_SUCCESS, payload: newGroup });
      dispatch({ type: c.SAVE_PRODUCT_GROUPS_SUCCESS, payload: parentGroup });
      return false;
    } catch (err) {
      console.error('Failed to save and reload product group', err);
      dispatch({ type: c.SAVE_PRODUCT_GROUPS_ERROR });
      return true;
    }
  };
}
