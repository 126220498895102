import client from './client';
import { GetImagesParams, GetImagesResponse } from './types';

export async function getImages(params: GetImagesParams) {
  try {
    const {
      data: { images },
    } = await client.get<GetImagesResponse>('/images', { params });
    return images;
  } catch (error) {
    throw new Error('Failed to get images from CDN API', { cause: error });
  }
}
