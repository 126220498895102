import { TextFieldProps, TextField, MenuItem } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

import { getEnabledCurrencies } from 'reducers/configs/settings';

const CurrencyInput: React.FC<TextFieldProps> = props => {
  const currencies = useSelector(getEnabledCurrencies);

  return (
    <TextField
      variant="outlined"
      data-testid="currency-input"
      select
      fullWidth
      style={{ padding: '0.75rem 1rem' }}
      {...props}
    >
      {currencies.map(currency => (
        <MenuItem
          data-testid="currency-input-item"
          data-test-key={currency}
          key={currency}
          value={currency}
        >
          {currency}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CurrencyInput;
