import React, { useState } from 'react';
// @ts-ignore
import * as R from 'ramda';
import {
  Box,
  IconButton,
  Slide,
  Table,
  TableCell,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

// @ts-ignore
import { ReactComponent as SuretaxIcon } from '../assets/Wolters_Kluwer_Logo.svg';
import { suretaxReducer } from '../redux';
import * as meta from '../meta';

const Square = ({ color }) => (
  <Box style={{ backgroundColor: color, margin: 2, width: 4, height: 4 }} />
);

/**
 * Show status in header, click to toggle debug info panel
 */
export const ComponentHeader: PosPlugin<
  meta.Configuration
>['ComponentHeader'] = ({ children }) => {
  const [show, setShow] = useState(false);

  const active = useSelector(suretaxReducer.getCurrent);

  return (
    <>
      {children}
      <li>
        <Box display="flex" flexDirection="column" alignItems="center">
          <IconButton onClick={() => setShow(show => !show)} size="small">
            <SuretaxIcon
              style={{ width: 24, height: 24 }}
              viewBox="20 20 200 200"
            />
          </IconButton>
          <Box display="flex" justifyContent="center">
            <Square color={active ? 'lime' : 'gray'} />
          </Box>
        </Box>
      </li>
      <div
        style={{
          zIndex: 1,
          pointerEvents: 'none',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Slide direction="left" in={show} mountOnEnter unmountOnExit>
          <div
            style={{
              pointerEvents: 'auto',
              padding: 8,
              width: 600,
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'black 1px 1px 6px 0px',
            }}
          >
            <Status />
          </div>
        </Slide>
      </div>
    </>
  );
};

const Status = () => {
  const suretaxData = useSelector(suretaxReducer.getCurrent);

  if (!suretaxData)
    return (
      <Typography color="error">
        Latest version of the shopping cart has not been sent to SureTax yet.
        Please press the Calculate button
      </Typography>
    );

  return (
    <Table size="small">
      <tr>
        <TableCell>TransID</TableCell>
        <TableCell>
          <div>Main: {suretaxData.TransId}</div>
          <div>Master: {suretaxData.MasterTransId}</div>
        </TableCell>
      </tr>
      <tr>
        <TableCell>Success</TableCell>
        <TableCell>{suretaxData.Successful}</TableCell>
      </tr>
      <tr>
        <TableCell>HeaderMessage</TableCell>
        <TableCell>{suretaxData.HeaderMessage}</TableCell>
      </tr>

      {suretaxData.GroupList.map(group => (
        <tr>
          <TableCell>Line: {group.LineNumber}</TableCell>
          <TableCell>
            {group.TaxList.map(tax => {
              return (
                <div style={{ fontSize: 8, margin: 4 }}>
                  <div>
                    <b>{tax.TaxTypeDesc}</b>
                  </div>
                  <div>
                    <b>Tax rate:</b> {tax.TaxRate * 100}%
                  </div>
                  <div>
                    <b>Tax amount:</b> {tax.TaxAmount}
                  </div>
                  <div>
                    <b>Percent taxable:</b> {tax.PercentTaxable}
                  </div>
                  <div>
                    <b>Amount taxable:</b> {tax.RevenueBase}
                  </div>
                  <div>
                    <b>Amount total:</b> {tax.Revenue}
                  </div>
                </div>
              );
            })}
          </TableCell>
          <TableCell>
            <div>
              Total tax amount:{' '}
              {group.TaxList.map(t => t.TaxAmount).reduce(R.add, 0)}
            </div>
            <div>
              Total tax rate:{' '}
              {group.TaxList.map(t =>
                Number(t.TaxAmount) === 0 ? 0 : t.TaxRate * 100,
              ).reduce(R.add, 0)}
              %
            </div>
          </TableCell>
        </tr>
      ))}
    </Table>
  );
};
