import { PaymentLimit } from '../types/PaymentLimit';

import { createOverrideSelector } from './Plugins';

function getPaymentLimitsBase(state) {
  return state.Payments.paymentLimits as PaymentLimit[];
}

export const getPaymentLimits = createOverrideSelector(
  'getPaymentLimits',
  getPaymentLimitsBase,
);
