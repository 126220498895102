import { RootState } from 'reducers';

import { TYPE_CLOSE, TYPE_SHOW } from '../constants/Confirmation';

import { getSetting } from './configs/settings';

type Err = Error & { cause?: Err };

interface Props {
  body?: string;
  title?: string;
  error: (Err & { cause?: Err }) | null;
  enableShortcuts: boolean;
  confirmText: string | { name: string; value: string }[];
  cancelText?: string;
  inputs: {
    name: string;
    value: unknown;
    title: string;
    type?: string;
    label?: string;
  }[];
  details: any;
  alertBeforeUnload?: boolean;
  automaticAction?: 'resolve' | 'reject';
  automaticActionAfter?: number;
}

interface Confirmation {
  id: string;
  resolve: (value: unknown) => void;
  reject?: (reason?: unknown) => void;
  props: Props;
}

export default (
  state = [] as Confirmation[],
  { type, payload }: { type: string; payload: Confirmation },
) => {
  switch (type) {
    case TYPE_SHOW:
      return [payload, ...state];
    case TYPE_CLOSE:
      return payload.id
        ? state.filter(conf => conf.id !== payload.id)
        : state.slice(1);
    default:
      return state;
  }
};

export function getHasActiveConfirmation(state) {
  return state.Confirmation.length > 0;
}
export function getActiveConfirmation(state: RootState) {
  return state.Confirmation[0];
}

export function getCloseConfirmationAfterPrint(state: RootState) {
  return getSetting('touchpos_close_after_print')(state);
}
