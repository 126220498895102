import * as R from 'ramda';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

/** matches(regex)(element) - Returns true if the given element matches the regex */
export const matches = regex => c => {
  const key = c?.key?.replace(/\W/gi, '').replace('0', '');
  return regex.test(key);
};

export const notMatches = regex => R.complement(matches(regex));

/** Returns a disabled version of the passed-in field */
export const disableField = R.assocPath(['props', 'field', 'disabled'], true);

/** Changes the title of the matching field with what was provided */
export const renameField = value => R.assocPath(['props', 'title'], value);

/**
 * append(regex, element)(children)
 * After the first match of regex, insert the given element into the children list
 */
export const insertAfter = R.curry((regex, item, list) => {
  const index = R.findIndex(matches(regex), list);
  return R.insert(index, item, list);
});

export const generateExpirationValues = (expires?: Date) => {
  const now = dayjs();
  const expiration = expires ? dayjs(expires) : undefined;
  const expired = dayjs().isAfter(expiration ?? dayjs('1970-01-01T00:00:00'));
  const expiresSoon =
    !expired &&
    dayjs()
      .add(1, 'month')
      .isAfter(expiration ?? dayjs('1970-01-01T00:00:00'));
  const untilExpiration = expiration
    ? Math.abs(expiration.diff(now, 'd'))
    : undefined;

  return { expiration, expired, expiresSoon, untilExpiration };
};
