import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getSerialNumberLength,
  getSerialNumberOperator,
  getSerialNumberTitle,
} from 'reducers/configs/settings';

const useCustomPromptsFormValidation = (invalidByDefault: string[] = []) => {
  const { t } = useTranslation('validation');

  const serialNumberLength = useSelector(getSerialNumberLength);
  const comparisonOperator = useSelector(getSerialNumberOperator);
  const serialNumberTitle = useSelector(getSerialNumberTitle);

  const [invalidPrompts, setInvalidPrompts] = useState<string[]>(
    invalidByDefault,
  );

  const validateSerialNumberLength = (
    serialNumber: string,
    operatorValue: string,
    serialNumberLength: string,
  ): boolean => {
    const parsedLength = Number(serialNumberLength);
    const serialLength = serialNumber.length;
    // Check for at least 1 symbol when conf is off
    if (!parsedLength) return serialLength > 0;

    switch (operatorValue) {
      case 'minLength':
        return serialLength >= parsedLength;
      case 'equals':
        return serialLength === parsedLength;
      case 'maxLength':
        return serialLength <= parsedLength;
      default:
        return false;
    }
  };

  const defineErrorText = (
    operatorValue: string,
    serialNumberLength: string,
    promptValue: string,
    isSerialNumber: boolean,
  ): string => {
    if (promptValue.length && new RegExp(/[:,]/g).test(promptValue)) {
      // To avoid issues with parsing custom prompts from product name
      return t('customPrompts.forbiddenSymbols', { symbols: ':,' });
    }

    if (!isSerialNumber) return '';

    // Check for at least 1 symbol when conf is off
    if (!Number(serialNumberLength) && !promptValue.length)
      return t('customPrompts.minLength', { min: 1 });

    switch (operatorValue) {
      case 'minLength':
        return t('customPrompts.minLength', { min: serialNumberLength });
      case 'equals':
        return t('customPrompts.equals', { amount: serialNumberLength });
      case 'maxLength':
        return t('customPrompts.maxLength', { max: serialNumberLength });
      default:
        return t('customPrompts.minLength', { min: 1 });
    }
  };

  return {
    invalidPrompts,
    validate: (p, v) => {
      let validSerial = true;
      if (p === String(serialNumberTitle).trim()) {
        validSerial = validateSerialNumberLength(
          v,
          comparisonOperator,
          String(serialNumberLength),
        );
      }
      const forbiddenSymbols = new RegExp(/[:,]/g);
      if (validSerial && !forbiddenSymbols.test(v)) {
        setInvalidPrompts(prev => prev.filter(prompt => prompt !== p));
      } else {
        setInvalidPrompts(prev => [...prev, p]);
      }
    },
    getErrorText: (promptName, promptValue) =>
      invalidPrompts.includes(promptName)
        ? defineErrorText(
            comparisonOperator,
            String(serialNumberLength),
            promptValue,
            promptName === String(serialNumberTitle).trim(),
          )
        : '',
  };
};

export default useCustomPromptsFormValidation;
