import React from 'react';
import { useDispatch } from 'react-redux';

import { GridButton } from 'components/GridButton';
import { GridButtonSaleOption } from 'reducers/UI/gridButtons';

import logo from './qantas_logo.png';
import { openQffModal } from './utils';

type Props = Parameters<Required<GridButtonSaleOption>['view']>[0];

function QffGridButton({ action }: Props) {
  const dispatch = useDispatch();

  function handleClick() {
    if (action) return dispatch(action);
    return dispatch(openQffModal());
  }

  return (
    <GridButton
      customColor
      style={{ backgroundColor: '#fff' }}
      onClick={handleClick}
    >
      <img
        src={logo}
        alt="Qantas Frequent Flyer"
        style={{
          display: 'block',
          maxHeight: 'calc(100% - 0.5rem)',
          width: 'auto',
          height: 'auto',
        }}
      />
    </GridButton>
  );
}

export default QffGridButton;
