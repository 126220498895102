import React, { FC, useState } from 'react';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  InputAdornment,
  TextField,
  Divider,
} from '@material-ui/core';

import { fetchLayawaySales } from 'actions/sales';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { dismissAll } from 'actions/Error';
import {
  getDateFormatter,
  getCurrencyFormatterNoSymbol,
  getSetting,
} from 'reducers/configs/settings';
import { modalPages } from 'constants/modalPage';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { RootState } from 'reducers';

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 'calc(100vh - 210px)',
    marginTop: '1rem',
  },
  table: {
    '& th, & td': { padding: '0.75rem' },
    '& th': {
      fontWeight: 700,
    },
  },
  searchButton: {
    cursor: 'pointer',
  },
});

interface Props {
  sales: SaleDocumentResponse[];
}

const LayawayList: FC<Props> = ({ sales }) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const [layaways, setLayaways] = useState(sales);
  const [value, setValue] = useState<string | null>(null);

  const styles = useStyles();

  const detailedLayaways = useSelector(state =>
    getSetting('detailed_layaways')(state),
  );

  const searchSale = async (searchVal: string) => {
    const sales: SaleDocumentResponse[] = await dispatch(
      fetchLayawaySales({ numberOrCustomer: searchVal.trim() }),
    );

    setLayaways(sales);
  };

  useDebounce(
    () => {
      if (value !== null) {
        searchSale(value);
      }
    },
    1000,
    [value],
  );

  const handleChange = e => {
    const { value } = e.target;
    setValue(value);
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      searchSale(value ?? '');
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    searchSale(value ?? '');
  };

  const close = () => {
    dispatch(dismissAll());
    dispatch(closeModalPage());
  };

  const selectSale = sale => {
    dispatch(
      openModalPage({
        component: modalPages.layawayActionSelection,
        isPopup: true,
        props: {
          sale,
        },
      }),
    );
  };

  const formatDate = useSelector(getDateFormatter);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);

  const { t } = useTranslation('layaway');
  const generateNotesForLayaway = layaway => {
    const globalNote = layaway.notes.trim();
    const internalNote = layaway.internalNotes.trim();
    if (globalNote) {
      if (internalNote) {
        return `${globalNote}\n${internalNote}`;
      }
      return globalNote;
    }
    if (internalNote) {
      return internalNote;
    }
    return '';
  };

  return (
    <div className="sales-data" data-testid="layaway-container">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1.25rem"
        paddingY="0.75rem"
      >
        <Box fontSize={24} fontWeight={700}>
          {t('load.title')}
        </Box>
        <div>
          <CloseButton action={close} />
        </div>
      </Box>
      <Divider />
      <Box padding="1.25rem">
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            aria-label="Sales number"
            placeholder={t('fields.customerOrSalesNumber', {
              context: 'placeholder',
            })}
            type="text"
            value={value || ''}
            onKeyDown={onKeyDown}
            onChange={handleChange}
            inputProps={{
              'data-testid': 'layaway-search-input',
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  data-testid="layaway-search-button"
                  className={styles.searchButton}
                  onClick={() => searchSale(value ?? '')}
                >
                  <i className="flip-horizontal  icon_search" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </form>
        <TableContainer className={styles.tableContainer}>
          <Table stickyHeader className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('headers.number')}</TableCell>
                <TableCell>{t('headers.date')}</TableCell>
                <TableCell>{t('headers.customer')}</TableCell>
                {detailedLayaways ? (
                  <TableCell>{t('headers.notes')}</TableCell>
                ) : null}
                <TableCell>{t('headers.email')}</TableCell>
                <TableCell>{t('headers.paid')}</TableCell>
                <TableCell>{t('headers.total')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {layaways.length ? (
                layaways.map(r => (
                  <TableRow
                    key={r.id}
                    onClick={() => selectSale(r)}
                    style={{ cursor: 'pointer' }}
                    data-testid="layaway-row"
                    data-test-key={r.number}
                  >
                    <TableCell data-testid="number">{r.number}</TableCell>
                    <TableCell data-testid="date">
                      {formatDate(r.date)}
                    </TableCell>
                    <TableCell data-testid="client">{r.clientName}</TableCell>
                    {detailedLayaways ? (
                      <TableCell data-testid="notes">
                        {generateNotesForLayaway(r)}
                      </TableCell>
                    ) : null}
                    <TableCell data-testid="email">
                      {r.clientEmail || ''}
                    </TableCell>
                    <TableCell data-testid="paid">
                      {CURR.stringify(r.paid)}
                    </TableCell>
                    <TableCell data-testid="total">
                      {CURR.stringify(r.total)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell data-testid="no-layaways" colSpan={6}>
                    {t('load.noLayaways')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default LayawayList;
