import { PosPlugin } from 'plugins/plugin';

import meta from './meta';
import info from './info.md';
import { ComponentConfiguration } from './ComponentConfiguration';
import { useMetricsCollector } from './useMetricsCollector';

export const MetricsCollectorPlugin: PosPlugin = {
  id: meta.pluginID,
  name: 'metrics-collector beta',
  infoMDUrl: info,
  ComponentHeader: ({ children }) => {
    useMetricsCollector();
    return children;
  },
  ComponentConfiguration,
};
