import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import BarcodeReader from 'react-barcode-reader';

import LoginHeader from 'components/Header';
import Main from 'containers/Main';
import POS from 'containers/POS';
import Header from 'containers/Header';
import Login from 'containers/Login';
import { hardLogout } from 'actions/Login';
import { getCurrentPosLanguage, getSetting } from 'reducers/configs/settings';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { usePosOnMessage, usePosSendMessage } from 'utils/hooks/useWrapper';
import { addError, addSuccess, addWarning, dismissType } from 'actions/Error';
import { getHasDayOpen, getIsLoading } from 'reducers/OpenCloseDay';
import sanitizeLocalStorage from 'containers/App/hooks/useSanitizeLocalStorage';
import useDelphiCustomerDisplay from 'containers/App/hooks/useDelphiCustomerDisplay';
import { usePreventTrespassing } from 'containers/App/hooks/usePreventTrespassing';
import { useOnBeforeReloadCleanUp } from 'containers/App/hooks/useOnBeforeReloadCleanUp';
import { useWrapperContextVariables } from 'containers/App/hooks/useWrapperContextVariables';
import useWrapperConfig from 'containers/App/hooks/useWrapperConfig';
import { getPluginLanguageOverride } from 'reducers/Plugins';
import { handleScanning } from 'actions/scanner';
import { useSounds } from 'services/Audio/Sound';
import { useTrackConnectionStatus } from 'containers/App/hooks/useTrackConnectionStatus';
import { getScanningAlgorithm } from 'reducers/cafaConfigs';
import {
  getHasRightToOpenAndCloseDay,
  getUserRightsAreFetched,
} from 'reducers/Login';
import { getPosLoggedIn } from 'reducers/PointsOfSale';
import { renderPatchscript } from 'actions/integrations/printer/actions';
import { getActiveColorTheme } from 'reducers/UI/theme';

import POSVersionRedirect from '../VersionControl';

import i18n from './i18n';
import {
  useGlancetronCustomerDisplay,
  useIdCardReader,
  useMagellanScanner,
} from './hooks/useExternalDevices';
import { useIsLoggedIn } from './hooks/useIsLoggedIn';
import { getSelectedTheme } from './hooks/useBootstrapTheme';

// region mockPasswordExpired shortcut - wanted for at least another month as of 06MAY20 (@liis.kalmet)
const listener = e => {
  if (e.ctrlKey && e.altKey && e.key === 'p') {
    if (localStorage.getItem('mockPasswordExpired')) {
      // eslint-disable-next-line no-undef
      alert('Debug: Disabled, next login will be normal');
      localStorage.removeItem('mockPasswordExpired');
    } else {
      // eslint-disable-next-line no-undef
      alert(
        'Debug: Next login will be saved with .passwordExpired = 1 for testing',
      );
      localStorage.setItem('mockPasswordExpired', 1);
    }
  }
};
document.addEventListener('keydown', listener);
// endregion
sanitizeLocalStorage();
const VerifiedApp = () => {
  useDelphiCustomerDisplay();
  useWrapperContextVariables();
  useSounds();
  useTrackConnectionStatus();
  useOnBeforeReloadCleanUp();

  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const posLoggedIn = useSelector(getPosLoggedIn);
  const notLoading = !useSelector(getIsLoading);
  const isPosLoggedIn = posLoggedIn && notLoading;

  const { minScanLength, avgTimeByChar, timeBeforeScanTest } = useSelector(
    getScanningAlgorithm,
  );

  const hasDayOpen = useSelector(getHasDayOpen);
  /** If the user is in a POS to which they don't have rights, boot them out */
  usePreventTrespassing();

  /* Global wrapper support */
  usePosOnMessage('general:dismissError', ({ id }) => {
    dispatch(dismissType(`wrapper-${id}`));
  });
  usePosOnMessage(
    'general:showError',
    ({
      type = 'error',
      message,
      id,
      dismissible = true,
      selfDismiss = false,
    }) => {
      const actionCreator = {
        error: addError,
        warning: addWarning,
        success: addSuccess,
      }[type];

      if (!actionCreator) {
        console.error(
          'general:showError type must be one of "error","warning","success" (or omitted, which means error by default)',
        );
        return;
      }
      if (!id && !selfDismiss && !dismissible) {
        console.error(
          'general:showError must either be self-dismissing (selfDismiss !== false), user dismissable (dismissable === true), or specify an ID so it can be dismissed by the wrapper',
        );
        return;
      }
      dispatch(
        actionCreator(message, {
          errorType: `wrapper-${id}`,
          selfDismiss,
          dismissible,
        }),
      );
    },
  );
  useWrapperConfig(isLoggedIn);
  /* renderPatchscript command for wrappers to trade in patchscript for png */
  const renderPatchscriptDone = usePosSendMessage('renderPatchscript:done');
  usePosOnMessage('renderPatchscript', ({ patchScript }) => {
    const pixelWidth = window.wrapperInfo?.printReceiptWidth;
    const receiptScale = window.wrapperInfo?.printReceiptScale;
    renderPatchscript(patchScript, pixelWidth, receiptScale).then(blob =>
      renderPatchscriptDone({ pngData: blob }),
    );
  });
  usePosOnMessage('scan:doScan', ({ code }) => dispatch(handleScanning(code)));

  // useConfigHandler();
  // useWrapperSimulator();
  // Ensure these namespaces are always loaded
  const selectedLanguage = useSelector(getCurrentPosLanguage);
  useEffect(() => {
    // Dev language is english language but *only* edited by devs
    // Having 'dev' and 'en' separate ensures that even if translators work on an older version and submit that, it won't overwrite newly added translations in the dev language
    i18n.options.fallbackLng = [selectedLanguage, 'en', 'dev'];
    i18n.changeLanguage('plugin');
    document.documentElement.lang = selectedLanguage;
  }, [selectedLanguage]);

  useTranslation('common');
  useTranslation('validation');
  useTranslation('return');
  const { t: alertsT } = useTranslation('alerts');

  // Plugin i18n override
  const pluginLanguageOverrides = useSelector(getPluginLanguageOverride);
  useEffect(() => {
    window.i18n = i18n;
    Object.keys(i18n.getDataByLanguage('plugin') ?? []).forEach(ns => {
      i18n.removeResourceBundle('plugin', ns);
    });
    Object.entries(pluginLanguageOverrides).forEach(([ns, data]) => {
      i18n.addResourceBundle('plugin', ns, data);
    });
    i18n.changeLanguage('plugin');
  }, [pluginLanguageOverrides]);

  const customCss = useSelector(getSetting('touchpos_custom_css'));

  const hasRightsToOpenDay = useSelector(getHasRightToOpenAndCloseDay);
  const userRightsFetched = useSelector(getUserRightsAreFetched)

  const shouldRenderPos =
    isLoggedIn &&
    isPosLoggedIn &&
    (hasDayOpen || (!hasDayOpen && hasRightsToOpenDay)) && userRightsFetched;

  const header = useMemo(
    () => (shouldRenderPos ? <Header /> : <LoginHeader />),
    [shouldRenderPos],
  );

  const main = useMemo(() => {
    return shouldRenderPos ? (
      <POSVersionRedirect>
        <POS />
      </POSVersionRedirect>
    ) : (
      <Login />
    );
  }, [shouldRenderPos]);

  const colorTheme = useSelector(getActiveColorTheme);
  const theme = getSelectedTheme(colorTheme);
  document.body.setAttribute('data-theme', theme);
  /* Created an element to hold all modal pages */
  if (document.querySelectorAll('body #modals').length === 0) {
    const divEl = document.createElement('DIV');
    divEl.setAttribute('id', 'modals');
    document.body.appendChild(divEl);
  }

  useEffect(() => {
    if (isPosLoggedIn && !hasDayOpen) {
      if (hasRightsToOpenDay) {
        dispatch(
          openModalPage({
            component: mp.OpenDay,
            isPopup: true,
            modalClassName: 'openCloseDay',
          }),
        );
      } else if (userRightsFetched) {
        dispatch(hardLogout());
        dispatch(addError(alertsT('noOpenDayRights')));
      } else {
        // Still fetching user rights, wait for it
      }
    }
    // intentional since we don't want OpenDay modal to open right after day is closed
  }, [isPosLoggedIn, userRightsFetched]);

  const zoom = localStorage.getItem('Debug: zoom') ?? '1';
  useEffect(() => {
    const el = document.documentElement;
    el.style.position = 'fixed';
    el.style.top = 0;
    el.style.bottom = 0;
    el.style.left = 0;
    el.style.right = 0;
    el.style.overscrollBehaviour = 'contain';
    // eslint-disable-next-line no-undef
    window.dispatchEvent(new Event('resize'));
  }, [zoom]);

  useMagellanScanner();
  useGlancetronCustomerDisplay();
  useIdCardReader();

  return (
    <div
      className={classNames('App')}
      data-theme={theme}
      style={{
        position: 'absolute',
        transform: `scale(${zoom})`,
        transformOrigin: 'top left',
        width: `${100 / zoom}%`,
        height: `${100 / zoom}%`,
      }}
    >
      <style>{customCss}</style>
      <Main header={header} main={main} />
      <BarcodeReader
        avgTimeByChar={avgTimeByChar ?? 100}
        minLength={minScanLength ?? 2}
        timeBeforeScanTest={timeBeforeScanTest ?? 100}
        onScan={code => dispatch(handleScanning(code))}
        onError={console.error}
      />
    </div>
  );
};

export default VerifiedApp;
