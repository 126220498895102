import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, Table } from '@material-ui/core';
import uuidv4 from 'uuid/v4';

import { addError, addWarning } from 'actions/Error';
import { setPayment } from 'actions/Payments/setPayment';
import {
  getBalance as getBalanceSelector,
  getPayments,
} from 'reducers/Payments';
import { PaymentObj } from 'paymentIntegrations/types';

import { GivexCard } from '../types';
import { getBalance } from '../API/givexAPI';
import { GivexCardInput } from '../components/GivexCardInput';
import {
  getGivexConfiguration,
  getValidateGivexCard,
} from '../configuration/Configuration';

const GiftCard = ({ typeID, children }) => {
  const { t } = useTranslation('payment');

  const dispatch = useDispatch();
  const [cardInput, setCardInput] = useState<GivexCard>({
    cardNo: '',
    pin: '',
  });
  const givexValidationErrorMessage = useSelector(getValidateGivexCard)(
    cardInput,
    'payment-search'
  );

  const { displayName } = useSelector(getGivexConfiguration);
  const balance = useSelector(getBalanceSelector);
  const payments: Record<
    string,
    PaymentObj & { GIVEX?: { card: GivexCard } }
  > = useSelector(getPayments);

  const [processing, setProcessing] = useState(false);
  const withProcessing = action => () => {
    if (givexValidationErrorMessage) {
      dispatch(addError(givexValidationErrorMessage));
      return;
    }
    setProcessing(true);
    if (processing) return;
    action()
      .catch(e => dispatch(addError(e.message)))
      .finally(() => setProcessing(false));
  };

  const handleSearch = async () => {
    const existsInPayments = Object.values(payments).filter(
      payment => payment.GIVEX?.card?.cardNo === cardInput.cardNo,
    ).length;
    if (existsInPayments) {
      dispatch(
        addWarning(
          t('alerts.giftcardSerialAlreadyAdded', {
            serial: cardInput.cardNo,
          }),
          {
            dismissible: false,
            errorType: 'giftCard',
            selfDismiss: 2000,
          },
        ),
      );
      return;
    }
    const card = await getBalance(cardInput);
    dispatch(
      setPayment({
        key: uuidv4(),
        amount: -balance,
        type: 'CARD',
        paymentIntegration: 'givex',
        caption: displayName.toUpperCase(),
        cardNumber: cardInput.cardNo,
        GIVEX: {
          card: { ...cardInput, balance: card.certificateBalance },
        },
      }),
    );
  };

  if (typeID !== 'givex') {
    return children;
  }

  return (
    <Table>
      <tbody>
        <tr data-testid="payment">
          <td data-testid="refill" />
          <td data-testid="status" style={{ display: 'flex', flex: 'column' }}>
            <GivexCardInput
              value={cardInput}
              onChange={setCardInput}
              disabled={processing}
              type="payment-search"
            />
          </td>
          <td data-testid="charge">
            <Button
              disabled={processing}
              onClick={withProcessing(handleSearch)}
              variant="contained"
              color="primary"
              style={{ position: 'relative' }}
            >
              {processing && (
                <LinearProgress
                  variant="indeterminate"
                  style={{ position: 'absolute', inset: 0 }}
                />
              )}
              Search
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default GiftCard;
