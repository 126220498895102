import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 75,
  },
});

interface SliderProps {
  min: number;
  max: number;
  step: number;
}

type InputSliderProps = SliderProps & {
  title: string;
  value: number | string;
  onChange(newValue: number): void;
};

function InputSlider({
  title,
  min,
  max,
  step,
  value,
  onChange,
}: InputSliderProps) {
  const classes = useStyles();

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    onChange(typeof newValue === 'number' ? newValue : newValue[0]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const limitedValue = Math.max(
        min,
        Math.min(max, Number(event.target.value)),
      );
      onChange(limitedValue);
    }
  };

  return (
    <div className={classes.root}>
      <Typography id="input-slider" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof value === 'string' ? parseFloat(value) : value}
            onChange={handleSliderChange}
            min={min}
            max={max}
            step={step}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.input}
            variant="outlined"
            value={value}
            onChange={handleInputChange}
            inputProps={{
              step,
              min,
              max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default InputSlider;
