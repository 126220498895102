import { doClientRequest } from './core/ErplyAPI';

export const sendPurchaseDocumentByMail = ({ documentID, email }) =>
  doClientRequest({
    request: 'sendByEmail',
    type: 'PURCHASEDOCUMENT',
    id: documentID,
    email,
  });

export const sendSalesDocumentByMail = ({ invoiceID, email }) =>
  doClientRequest({
    request: 'sendByEmail',
    type: 'SALESDOCUMENT',
    id: invoiceID,
    email,
  });
