import { ErplyAttributes } from 'utils';

import { makePayment } from '../api2';

import { generateInputData, TransactionType } from './processPayments';

export const handleFail = ({
  record,
  updateMessage,
  payment,
}) => async dispatch => {
  switch (record.resultCode) {
    case '3':
      {
        const inputData = await dispatch(
          generateInputData(payment, TransactionType.Void),
        );
        const attributes = new ErplyAttributes(payment);
        const refNo = attributes.get('refNo');
        updateMessage(
          `Transaction time  out exceeded. ${
            refNo ? 'Voiding last transaction.' : ''
          }`,
        );
        if (refNo) {
          const {
            records: [result],
          } = await makePayment({ ...inputData, transactionType: 'Void' });

          switch (result.resultCode) {
            case '0':
              updateMessage('Voidéd successfully');
              break;
            default:
              updateMessage(
                `Void failed: ${result.transactionStatus} ${result.statusMessage}`,
              );
          }
        }
      }
      break;
    default:
      updateMessage(record.transactionStatus);
      break;
  }
};
