import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';

import { CFRSalesDocument, CFRSalesDocumentParams } from '../types';

const getFranchiseSalesDocuments = async (params: CFRSalesDocumentParams) =>
  doClientRequest<CFRSalesDocument>({
    ...params,
    request: 'getFranchiseSalesDocuments',
  });

export default getFranchiseSalesDocuments;
