import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';

import { getProductGroups } from 'reducers/productGroupsDB';

const ProductGroupAutocomplete: React.FC<{
  value: string;
  setValue: (groupID: string) => void;
}> = ({ value, setValue, ...rest }) => {
  const [inputValue, setInputValue] = useState('');

  const productGroups = useSelector(getProductGroups);
  const options = productGroups.map(group => String(group.productGroupID));

  return (
    <Autocomplete
      inputValue={inputValue}
      options={options}
      value={value ?? options[0]}
      getOptionLabel={option =>
        productGroups.find(group => group.id === option)?.name ?? ''
      }
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(reason === 'clear' ? '' : newInputValue);
        if (reason === 'clear') setValue('');
      }}
      onChange={(event, newValue) => {
        if (newValue) setValue(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="Choose group"
          variant="outlined"
        />
      )}
      {...rest}
    />
  );
};

export default ProductGroupAutocomplete;
