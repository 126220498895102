import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { Field } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';
import { openPluginModalPage } from 'actions/modalPage';
import { modals } from 'plugins/mm/constants';
import { RootState } from 'reducers';
import { getCustomers, saveCustomer } from 'services/ErplyAPI';
import { addWarning, addError } from 'actions/Error';
import {
  getCustomerRegistryToken,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { SET_CUSTOMER_SUCCESS } from 'constants/customerSearch';

import { VALID_GROUP_IDS, SILVER_GROUP_ID, BRONZE_GROUP_ID } from './constants';
import { EmailFieldProps, EndAdornmentProps } from './types';
import styles from './EmailValidation.module.scss';

const EndAdornment: React.FC<EndAdornmentProps> = ({
  customerID,
  onChange,
}) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const securityToken = getCustomerRegistryToken();

  const selectedCustomer = useSelector(getSelectedCustomer);

  const { t } = useTranslation('createCustomer');

  async function openEmailModal() {
    if (securityToken === null) {
      dispatch(
        addWarning(
          'Access token for MM email service not loaded yet, please wait.',
        ),
      );
      return;
    }
    await dispatch(
      openPluginModalPage(modals.EMAIL_VALIDATION)({
        isPopup: true,
        modalClassName: styles['email-validation-modal'],
        props: { securityToken },
      }),
    );
  }

  async function handleClick() {
    await dispatch(openEmailModal).catch(() => undefined);

    try {
      if (customerID === undefined) {
        throw new Error('Can not get a customer from API without id');
      }

      const [customer] = await getCustomers({ customerID }).catch(err => {
        throw new Error('Failed to get a customer from API', {
          cause: err,
        });
      });

      const hasEmail = customer.email.length > 0;
      const groupID = hasEmail ? SILVER_GROUP_ID : BRONZE_GROUP_ID;

      await saveCustomer({ customerID, groupID }).catch(err => {
        throw new Error('Failed to save a customer to API', { cause: err });
      });

      dispatch({
        type: SET_CUSTOMER_SUCCESS,
        data: { ...selectedCustomer, email: customer.email, groupID },
      });

      onChange(prev => ({
        ...prev,
        groupID: String(groupID),
        email: customer.email,
      }));
    } catch (error) {
      dispatch(
        addError(
          error instanceof Error
            ? error.message
            : "Failed to change customer's email",
        ),
      );
    }
  }

  if (!customerID) return null;

  return (
    <InputAdornment position="end">
      <Button
        disabled={securityToken === null}
        onClick={handleClick}
        size="large"
        variant="contained"
      >
        {t('buttons.changeEmail')}
      </Button>
    </InputAdornment>
  );
};

const EmailField: React.FC<EmailFieldProps> = ({ customer, onChange }) => {
  const { t } = useTranslation('createCustomer');

  const isBronzeOrSilverGroup = VALID_GROUP_IDS.includes(
    Number(customer.groupID),
  );

  return (
    <Field name="email" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
      <TextField
        name="email"
        fullWidth
        variant="outlined"
        label={t('fields.email')}
        value={customer.email ?? ''}
        onChange={e =>
          onChange(prev => ({
            ...prev,
            email: e.target.value,
          }))
        }
        InputProps={{
          endAdornment: isBronzeOrSilverGroup ? (
            <EndAdornment
              customerID={customer.customerID}
              onChange={onChange}
            />
          ) : null,
          disabled: true,
        }}
      />
    </Field>
  );
};

export default EmailField;
