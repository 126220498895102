/* eslint-disable no-console */
import Centrifuge from 'centrifuge';
import { encode } from 'jwt-simple';
import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuid4 } from 'uuid';

import { posConnectedCentrifugoMessage } from 'plugins/customerDisplay/constants';

export type CentrifugoSubscriber = {
  client: string;
  latency: number;
  transport: string;
};
// Centrifuge Library documentation https://www.npmjs.com/package/centrifuge

// const info$ = new Rx.ReplaySubject(1);
const userID = uuid4();
// initiate the centrifuge object earlier
const centrifugeResult = new Centrifuge(
  'wss://centrifugo-sb.erply.com/connection/websocket',
);
const centrifugeConnection = (() => {
  const hmacSecret = 'f27cfb90ca697895bb6372ad05040d97f81b2995';
  const tokenPayload = { sub: userID };
  const token = encode(tokenPayload, hmacSecret);
  centrifugeResult.setToken(token);
  centrifugeResult.connect();
  // info$.subscribe({
  //   next: i => {
  //     TODO: Update connection with new info
  //   },
  // });
  return centrifugeResult;
})();

centrifugeResult.on('connect', () => {
  centrifugeResult.send(posConnectedCentrifugoMessage);
});

centrifugeResult.on('disconnect', data => {
  if (data.reconnect) {
    const hmacSecret = 'f27cfb90ca697895bb6372ad05040d97f81b2995';
    const tokenPayload = { sub: userID };
    const token = encode(tokenPayload, hmacSecret);
    centrifugeResult.setToken(token);
    centrifugeResult.connect();
  }
});

export const useCentrifugoClient = ({
  channel,
  listener,
}: {
  channel: string;
  listener: (msg: any) => void;
}) => {
  const centrifugoListener = useRef(listener);
  const listenerFunction = useCallback(msg => {
    return centrifugoListener.current(msg);
  }, []);
  useEffect(() => {
    centrifugoListener.current = listener;
  }, [listener]);

  useEffect(() => {
    const sub = centrifugeConnection.subscribe(channel, listenerFunction);
    return () => sub.unsubscribe();
  }, [channel, centrifugoListener]);

  /**
   * Method to post a message to Centrifugo channel
   * @param msg Message, which you want to send to centrifugo.
   * Example: {
   * action: "START_NEW"
   * }
   * @return Sends out a message with 'channel' and 'client' attached to the message so that Centrifugo recognizes it
   */
  const postMessage = useCallback(
    msg => {
      const actualMsg = {
        data: JSON.stringify(msg),
        channel,
        client: userID,
      };
      centrifugeConnection.publish(channel, actualMsg);
    },
    [channel],
  );
  return { postMessage };
};
/**
 * @param channel centrifugo channel name in format of customer_display:12345
 * @param listener An event listener, that will be interacting with the incoming data
 * Centrifugo client for sending messages in a certain format to customer display endpoint
 */
export const useCentrifugoClientKrisVersion = ({
  channel,
  listener = msg => {},
}) => {
  const { postMessage } = useCentrifugoClient({
    channel,
    listener,
  });
  return {
    postMessage: msg => {
      // console.log({ msg, stringified: JSON.stringify(msg) });
      postMessage(msg);
    },
  };
};
/**
 * @param channel centrifugo channel name in format of customer_display:12345
 * @param message message to post to centrifugo channel
 * posts a JSON stringified message (along with client and channel) to centrifugo channel
 * use this if you cannot call `useCentrifugoClient`
 */
export const publish = ({
  channel,
  message,
}: {
  channel: string;
  message: any;
}) => {
  const actualMsg = {
    data: JSON.stringify(message),
    channel,
    client: userID,
  };
  return centrifugeConnection.publish(channel, actualMsg);
};
