export default {
  plugin: {
    id: 'pnp-related-items',
    name: 'PnP - Related products',
  },
  components: {
    bulkEditPrice: 'BulkEditPrice',
  },
  levelsOptions: ['company', 'warehouse', 'pos'],
};

export type RelatedProdPlugConfig = {
  [key: string]: {
    defaultKitItemPrice: string;
    customPrices: {
      [key: string]: string | number;
    };
  };
};
