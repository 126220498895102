import { PosPlugin } from 'plugins/plugin';
import {
  getCustomerGroupByID,
  getIsNestedChildCustomerGroupFn,
} from 'reducers/customerSearch';
import { saveCustomer } from 'actions/customerSearch';
import { addProgress, addSuccess, dismissType } from 'actions/Error';
import i18n from 'containers/App/i18n';

import {
  BRONZE_GROUP_ID,
  DEFAULT_GROUP_ID,
  EMAIL_MEMBER_GROUP_ID,
  NON_EMAIL_MEMBER_GROUP_ID,
  SILVER_GROUP_ID,
} from './constants';

const updateCustomerGroup = (customer, groupID: number) => async (
  dispatch,
  getState,
) => {
  const newGroup = getCustomerGroupByID(groupID)(getState());
  if (!newGroup) return;

  await dispatch(
    addProgress(
      i18n.t('alerts:updatingCustomerGroup'),
      'update-customer-group',
    ),
  );
  await dispatch(
    saveCustomer({ ...customer, groupID }, async () => {
      await dispatch(dismissType('update-customer-group'));
      await dispatch(
        addSuccess(
          i18n.t('alerts:customerGroupUpdated', { groupName: newGroup.name }),
        ),
      );
    }),
  );
};

/**
 * Changes customer group based on e-mail according to following rules:
 *
 * If selected customer has an email and is NOT in Email Member group or its subgroup then change group to Silver
 *
 * If selected customer does NOT have an email and is NOT in Non-Email Member group or its subgroup or is in Email Member group or its subgroup then change group to Bronze
 *
 * M&M customer group hierarchy:
 * - Non-member
 * - — Non-email member
 * - —— Bronze
 * - —— Email member
 * - ——— Silver
 */
export const changeCustomerGroupToMatchEmail: Required<
  PosPlugin
>['onSetCustomer']['after'] = (p, o) => async (dispatch, getState) => {
  const customerGroupID = Number(o.groupID);
  if (customerGroupID === DEFAULT_GROUP_ID) return;

  const isNestedChild = getIsNestedChildCustomerGroupFn(getState());
  const isNonEmailMemberSubGroup = isNestedChild(
    customerGroupID,
    NON_EMAIL_MEMBER_GROUP_ID,
  );
  const isEmailMemberSubGroup = isNestedChild(
    customerGroupID,
    EMAIL_MEMBER_GROUP_ID,
  );
  const currentGroupRequiresEmail =
    isEmailMemberSubGroup || customerGroupID === EMAIL_MEMBER_GROUP_ID;
  const currentGroupRequiresEmptyEmail =
    (isNonEmailMemberSubGroup && !currentGroupRequiresEmail) ||
    customerGroupID === NON_EMAIL_MEMBER_GROUP_ID;

  if (o.email?.length) {
    if (customerGroupID && currentGroupRequiresEmail) return;
    await dispatch(updateCustomerGroup(o, SILVER_GROUP_ID));
  } else {
    if (customerGroupID && currentGroupRequiresEmptyEmail) return;
    await dispatch(updateCustomerGroup(o, BRONZE_GROUP_ID));
  }
};
