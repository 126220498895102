import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getSelectedWarehouseID } from 'reducers/warehouses';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { SkeletonWord } from 'plugins/nohble/SpecialOrders/utils/SkeletonWord';
import { withProgressAlert } from 'actions/actionUtils';
import { addError, addSuccess } from 'actions/Error';

import { WarehouseName } from '../utils/WarehouseName';
import { API } from '../API/API';
import { ProductList } from '../utils/ProductUtils';

import {
  useInboundUnconfirmedInventoryTransfers,
  useLinksFromTransfers,
} from './PickupSpecialOrderModal/useInboundUnconfirmedInventoryTransfers';
import { useDocuments } from './PickupSpecialOrderModal/useDocuments';

const acceptTransfer = withProgressAlert('Accepting inventory transfer')(
  (transferID: number) => async (dispatch, getState) => {
    return API.confirmInventoryTransfer(transferID);
  },
);

/**
 * A custom modal page for listing and accepting unconfirmed Inventory Transfers
 */
export const PickupSpecialOrderModal = {
  id: 'specialOrders-pickupSpecialOrder',
  Component: () => {
    const transfers = useInboundUnconfirmedInventoryTransfers();
    const links = useLinksFromTransfers(transfers);
    const documentIDs = useMemo(
      () =>
        links.value
          ? Array.from(new Set(links.value?.map(link => link.salesDocID)))
          : undefined,
      [links.value],
    );
    const documents = useDocuments(documentIDs);
    const dispatch = useDispatch();
    const close = () => dispatch(previousModalPage());
    const warehouseID = useSelector(getSelectedWarehouseID);

    const isLoading = documents.loading || links.loading;
    const transfersFiltered = useMemo(() => {
      return transfers.value?.map(transfer => {
        const isLinked = links.value?.some(link => {
          const isToTransfer =
            link.transferDocID === transfer.inventoryTransferID;
          const fromDocumentExists = documents.value?.some(
            doc => Number(doc.id) === link.salesDocID,
          );
          return isToTransfer && fromDocumentExists;
        });
        return { ...transfer, isLinked };
      });
    }, [documents.value, links.value, transfers]);
    return (
      <>
        <Box display="flex" alignItems="start" margin={2}>
          <h2>Pickup special orders</h2>
          <Box flexGrow={1} />
          <CloseButton action={close} />
        </Box>
        <Divider />

        <Box margin={2}>
          {!isLoading && transfersFiltered?.every(t => !t.isLinked) && (
            <h2>
              There are no pending inventory transfers to{' '}
              <WarehouseName id={warehouseID} />
            </h2>
          )}
          {transfersFiltered?.map(transfer => {
            if (transfer.isLinked) {
              return (
                <TransferCard
                  key={transfer.inventoryTransferID}
                  transfer={transfer}
                  links={links.value}
                  documents={documents.value}
                />
              );
            }
            if (isLoading) {
              return (
                <Box margin={2} key={transfer.inventoryTransferID}>
                  <Skeleton variant="rect" height="3em" width="100%" />
                </Box>
              );
            }
            return null;
          })}
        </Box>
      </>
    );
  },
};

const TransferCard = ({
  transfer,
  links,
  documents,
}: {
  transfer: Exclude<
    ReturnType<typeof useInboundUnconfirmedInventoryTransfers>['value'],
    undefined
  >[number];
  links: ReturnType<typeof useLinksFromTransfers>['value'];
  documents: ReturnType<typeof useDocuments>['value'];
}) => {
  const dispatch: ThunkDispatch<any, any, Action> = useDispatch();
  const [expand, setExpand] = useState(true);
  const documentID = links?.find(
    link => link.transferDocID === transfer.inventoryTransferID,
  )?.salesDocID;
  const document = documents?.find(doc => doc.id === documentID);
  const accept = () =>
    dispatch(acceptTransfer(transfer.inventoryTransferID)).then(
      () => {
        dispatch(addSuccess('Stock transfer confirmed'));
        dispatch(previousModalPage());
      },
      e => dispatch(addError(e.message)),
    );
  return (
    <Box margin={1}>
      <Accordion expanded={expand}>
        <AccordionSummary
          onClick={() => setExpand(a => !a)}
          expandIcon={<ExpandMoreIcon />}
        >
          <h3>
            Transfer {transfer.inventoryTransferNo} for Sales order{' '}
            {document?.number ?? <SkeletonWord width="8ch" />} from{' '}
            <WarehouseName id={transfer.warehouseFromID} />
          </h3>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <Box>
            <h3>Products included</h3>
            <ProductList rows={transfer.rows} />

            <Button variant="contained" color="primary" onClick={accept}>
              Accept transfer
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
