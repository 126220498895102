import { useSessionStorage } from 'react-use';
import { Card, Table } from 'react-bootstrap';
import React, { useState } from 'react';

import UIButton from 'components/UIElements/UIButton';

const safeStringify = (object: any) => {
  try {
    return JSON.stringify(object);
  } catch (e) {
    try {
      return String(object);
    } catch (e) {
      return '[ERROR]';
    }
  }
};
const shortSafeStringify = (object: any) => {
  const longText = safeStringify(object);
  const shortText = longText.slice(0, 400);
  if (longText.length > 400) {
    return `${shortText}...`;
  }
  return longText;
};
/**
 * Visible only when localStorage 'debug' is set (Settings→debug→user interface→enable debug views)
 * Will render a collapsed card, which when expanded contains a table of all passed props
 * The table header can be clicked to log all the vars
 * Or the individual values can be clicked directly to log them separately
 *
 * @example
 * // count, payments, and bar are local variables that we want to keep track of
 * <DebugDisplayVariables count={count} payments={payments} foo={bar}>
 *   // Additional button at the end of the table to reset the state for testing
 *   <UIButton text="Reset" action={() => setState(initialValue)} />
 * </DebugDisplayVariables>
 */
export const DebugDisplayVariables = ({ children = undefined, ...rest }) => {
  const [debug, setDebug] = useSessionStorage('debug', false);
  const [open, setOpen] = useState(false);

  return debug ? (
    <Card
      className="border-debug"
      style={open ? { background: '#EB04' } : { display: 'inline-block' }}
    >
      <Card.Header className="bg-debug" onClick={() => setOpen(v => !v)}>
        {open ? 'Debug info' : 'Click to show debug info'}
      </Card.Header>
      <Card.Body
        style={open ? { background: 'transparent' } : { display: 'none' }}
      >
        <Table
          style={{
            display: 0 < Object.keys(rest).length ? 'table' : 'undefined',
            fontSize: 10,
          }}
          // Debug-only component, therefore okay to use console
          // eslint-disable-next-line no-console
          onClick={() => console.log(rest)}
          size="sm"
        >
          <tr>
            <th colSpan={2} style={{ textAlign: 'center', fontSize: '1.2em' }}>
              Passed data
              <span
                style={{color: 'lightblue'}}
                onClick={() => {
                  const data = JSON.stringify(rest)
                  window.navigator.clipboard.writeText(data)
                  window.alert(`Copied data:\n${data}`)
                }}
              >
                (click to copy)
              </span>
            </th>
          </tr>
          {Object.entries(rest).map(([k, v]) => (
            <tr>
              <th>{k}</th>
              <td
                style={{ overflowWrap: 'anywhere' }}
                onClick={e => {
                  e.stopPropagation();
                  // Debug-only component, therefore okay to use console
                  // eslint-disable-next-line no-console
                  console.log(v);
                }}
              >
                {shortSafeStringify(v)}
              </td>
            </tr>
          ))}
        </Table>
        {children}
      </Card.Body>
    </Card>
  ) : null;
};
