import React from 'react';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { CurrencySelect, Conversion } from '.';

const UICustomPaymentList: PosPlugin['UICustomPaymentList'] = ({ children }) =>
  R.pipe(
    React.Children.toArray,
    R.insert(1, <CurrencySelect />),
    R.insert(2, <Conversion />),
  )(children);

export default UICustomPaymentList;
