import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'ramda';
import { useDebounce } from 'react-use';

import { addError, addWarning } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import {
  getAge,
  getLegalName,
  getTimeTillExpiry,
  parseUsdlData,
  validateUsdlData,
} from '../utils';
import { USDLMappedData } from '../types';

const CustomerInfoCard = ({
  data,
  deny,
  approve,
  loading,
}: {
  data: USDLMappedData | null;
  deny: () => void;
  approve: () => void;
  loading: boolean;
}) => {
  if (loading)
    return (
      <Box
        style={{
          display: 'flex',
          flex: '1 1 0',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (!data) return null;
  return (
    <>
      <Grid item xs={12}>
        <Typography
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <span>Legal Name:</span>
          <span>{getLegalName(data)}</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <span>Age:</span>
          <span>{`${getAge(data)}`}</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <span>ID Expiration:</span>
          <span>{getTimeTillExpiry(data)}</span>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Button
          style={{ flex: '1 1 0', marginRight: '0.5em' }}
          variant="outlined"
          color="primary"
          onClick={deny}
        >
          Deny
        </Button>
        <Button
          style={{ flex: '1 1 0', marginLeft: '0.5em' }}
          variant="outlined"
          color="secondary"
          onClick={approve}
        >
          Approve
        </Button>
      </Grid>
    </>
  );
};

const SCAN_FOCUS_STATES = {
  awaitingScan: {
    color: 'green',
    message: 'Please scan customers driving licence',
  },
  lostFocus: {
    color: 'red',
    message:
      'Focus lost. Please, press REFOCUS button to refocus before you proceed with the scan',
  },
  receivingData: {
    color: 'lightblue',
    message:
      'Receiving data from scanner. Do not type nor click anywhere until the process is finished.',
  },
  verifyData: { color: 'green', message: 'Verify driving license data' },
};

const USDLAgeVerificationModal = ({ resolve, reject, minAge = 21 }) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLTextAreaElement>(null);

  const refocusInput = () => {
    ref?.current?.focus?.();
  };

  const [value, setValue] = useState('');
  const [data, setData] = useState<USDLMappedData | null>(null);

  const close = () => {
    dispatch(previousModalPage());
  };

  useShortcut('F2', refocusInput, 100);
  useShortcut('F8', refocusInput, 100);
  useShortcut('F9', refocusInput, 100);

  useDebounce(
    () => {
      if (!value) return;

      try {
        const newData = parseUsdlData(value);
        try {
          validateUsdlData(newData, minAge);
          setValue('');
          setData(newData);
        } catch (e) {
          // Error during validation - not worth retrying, the result will be the same
          dispatch(addError(e.message));
          close();
          reject();
        }
      } catch (e) {
        // Error during parsing, might be a scanning error, worth retrying
        dispatch(
          addWarning(
            `Scan failed. Please try scanning one more time (${e.message})`,
          ),
        );
        setValue('');
        refocusInput();
      }
    },
    1000,
    [value],
  );

  const scanState = (() => {
    if (isEmpty(data)) {
      if (document.activeElement?.id !== ref?.current?.id) {
        return SCAN_FOCUS_STATES.lostFocus;
      }
      if (!value.length) {
        return SCAN_FOCUS_STATES.awaitingScan;
      }
      return SCAN_FOCUS_STATES.receivingData;
    }
    return SCAN_FOCUS_STATES.verifyData;
  })();

  return (
    <Paper
      onClick={refocusInput}
      style={{
        padding: '2em',
        minHeight: 300,
        display: 'flex',
      }}
    >
      <Grid container spacing={2} style={{ display: 'flex', flex: '1 1 0' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h4">USDL Age Verification</Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography style={{ color: scanState.color, fontSize: '1.5rem' }}>
            {scanState.message}
          </Typography>
        </Grid>
        <CustomerInfoCard
          data={data}
          loading={!!value}
          deny={() => {
            reject();
            close();
          }}
          approve={() => {
            if (data) {
              resolve(getAge(data));
              close();
            }
          }}
        />
        {scanState === SCAN_FOCUS_STATES.lostFocus && (
          <Grid item xs={12} style={{ marginTop: 'auto' }}>
            <Button variant="outlined" fullWidth onClick={refocusInput}>
              Refocus
            </Button>
          </Grid>
        )}
        <Grid item xs={12} style={{ marginTop: 'auto' }}>
          <Button variant="outlined" fullWidth onClick={close}>
            Cancel
          </Button>
        </Grid>
        <textarea
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          id="usdl-hidden-input"
          ref={ref}
          value={value}
          style={{
            width: 0,
            height: 0,
            padding: 0,
            margin: 0,
            position: 'fixed',
            top: -20,
          }}
          onChange={e => setValue(e.target.value)}
        />
      </Grid>
    </Paper>
  );
};

export default USDLAgeVerificationModal;
