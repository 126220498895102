import axios from 'axios';

const baseURL = 'https://app.waiverelectronic.com/api/openapi/waivers';

export const requestAllWaivers = (params: {
  startDate: number;
  endDate: number;
  location: string;
  apiKey: string;
}): Promise<any[]> => {
  const { startDate, endDate, location, apiKey } = params;
  return axios({
    baseURL,
    url: '/getListofUnassignedWaivers',
    method: 'post',
    headers: {
      Authorization: apiKey,
    },
    data: {
      startDate,
      endDate,
      staffId: location,
    },
  })
    .then(response => {
      if (response?.data?.success?.length) {
        return response.data.success;
      }

      return [];
    })
    .catch(error => {
      if (error.response) {
        throw new Error(error.response.statusText);
      }
    });
};

export const setReceiptToWaiver = (params: {
  invoiceNumber: string;
  waiver: Record<string, any>;
  apiKey: string;
}): Promise<{ success: boolean; waiver: Record<string, any> }> => {
  const { invoiceNumber, waiver, apiKey } = params;
  return axios({
    baseURL,
    url: '/attachInvoiceNumber',
    method: 'post',
    headers: {
      Authorization: apiKey,
    },
    data: {
      invoiceNumber,
      waiverId: waiver.waiver_id,
    },
  })
    .then(response => {
      if (response?.data?.success === 'success') {
        return { success: true, waiver };
      }

      return { success: false, waiver };
    })
    .catch(error => {
      if (error.response) {
        throw new Error(error.response.statusText);
      }

      return { success: false, waiver };
    });
};
