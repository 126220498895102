export const pluginId = 'pnpCarSales';
export const attributes = {
  requireCfjc: `pnp_require_cfjc`,
  requireCylinder: `pnp_require_cylinder_count`,
  requireDoorSide: `pnp_require_car_side`,
  cfjcData: `pnp_cfjc`,
} as const;
export const components = {
  requirementsPopup: `${pluginId} → cfjc_requirements`,
  cylinderPicker: `${pluginId} → pnp_cylinder_picker`,
  carSidePicker: `${pluginId} → pnp_door_side_picker`,
} as const;
