import React, { useMemo } from 'react';
import { useAsync } from 'react-use';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

const companyOrFullName = R.ifElse(
  R.propEq('customerType', 'COMPANY'),
  R.prop('companyName'),
  R.compose(R.join(' '), R.props(['firstName', 'lastName'])),
);

const isMatchingSearchValue = searchValue =>
  R.pipe(companyOrFullName, R.eqBy(R.toLower, searchValue));

const UICustomerResults: PosPlugin['UICustomerResults'] = ({
  customerPromise,
  searchValue = '',
  children,
}) => {
  const { value: customers = [] } = useAsync(() => customerPromise, [
    customerPromise,
  ]);

  const sortedCustomers = useMemo(() => {
    const exactMatches = customers.filter(isMatchingSearchValue(searchValue));

    if (!exactMatches.length) return customers;
    return R.pipe(
      R.reject(isMatchingSearchValue(searchValue)),
      R.concat(exactMatches),
    )(customers);
  }, [customers, searchValue]);

  return R.pipe(
    React.Children.toArray,
    R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(
          React.Children.toArray,
          R.map(child =>
            React.cloneElement(child, {
              customers: sortedCustomers,
            }),
          ),
        ),
      ),
    ),
  )(children);
};

export default UICustomerResults;
