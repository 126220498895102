import { addMultiProducts, resetShoppingCart } from 'actions/ShoppingCart';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { saveSalesDocument, setCurrentSalesDocument } from 'actions/sales';
import { modalPages } from 'constants/modalPage';
import { getCustomer } from 'reducers/Payments';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { getTaxFreeVatRate } from 'reducers/vatRatesDB';
import { getSalesDocuments } from 'services/ErplyAPI/sales';
import { addError, addSuccess, addWarning, dismissType } from 'actions/Error';
import { setReturnDocument } from 'actions/returnProducts';
import { createConfirmation } from 'actions/Confirmation';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';

import {
  STORE_PRODUCTS_IN_CART,
  SELECTED_CUSTOMER,
  ONGOING_SALE,
} from './actionTypes';

export const programPickUp = (
  customer,
  {
    invoice_id: invoiceId,
    add_products_for_delivery: products,
  }: {
    invoice_id: string;
    add_products_for_delivery: any[];
  },
) => async (dispatch, getState) => {
  if (!Array.isArray(products)) return;
  await dispatch(setCustomer({ data: customer }));

  const state = getState();
  const customerID = getSelectedCustomerID(state);
  const productsInCart = getProductsInShoppingCart(state);
  const shouldStoreProductsInCart = productsInCart.length > 0;

  if (shouldStoreProductsInCart) {
    const shouldContinuePickup = await new Promise((resolve, reject) =>
      dispatch(
        createConfirmation(resolve, reject, {
          title: ' Pickup process started',
          body:
            'The ongoing sale will be resumed after the pickup process has finished',
        }),
      ),
    )
      .then(() => true)
      .catch(() => false);
    if (!shouldContinuePickup) {
      return;
    }

    dispatch({ type: ONGOING_SALE, payload: true });
    dispatch({ type: SELECTED_CUSTOMER, payload: customerID });
    await dispatch({
      type: STORE_PRODUCTS_IN_CART,
      payload: productsInCart,
    });
    await dispatch(resetShoppingCart());
  }

  await dispatch(
    addMultiProducts(
      products.map(p => ({
        name: p.name,
        productID: p.productID,
        amount: p.quantity,
        vatrateID: getTaxFreeVatRate(state)?.id,
      })),
    ),
  );
  await dispatch(previousModalPage());
  await dispatch(
    saveSalesDocument({
      salesDocument: {
        type: 'WAYBILL',
        customerID: customer.customerID,
        confirmed: 1,
        attributes: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          base_invoice_id: invoiceId,
        },
      },
      onSuccess: ({
        invoiceID,
        invoiceLink,
        invoiceNo,
        receiptLink,
        ...salesDocument
      }) => {
        dispatch(
          openModalPage({
            component: modalPages.PaymentConfirmation,
            modalClassName: 'confirmation',
            isPopup: true,
            props: {
              invoiceID,
              invoiceLink,
              customer: getCustomer(state),
              payments: [],
              invoiceNo,
              receiptLink,
              balance: 0,
              type: salesDocument.type,
              ...salesDocument,
            },
          }),
        );
      },
    }),
  );
};

/**
 * Return program from customer card
 * @desc
 * @return
 */
export const returnProgram = ({
  invoice_id: invoiceID,
  number,
  ...rest
}: {
  invoice_id: string;
  number: string;
  [any: string]: any;
}) => async (dispatch, getState) => {
  const progress = async text => {
    await dispatch(dismissType('lawnReturn'));
    await dispatch(
      addWarning(text, {
        selfDismiss: false,
        errorType: 'lawnReturn',
      }),
    );
  };
  const done = async text => {
    await dispatch(dismissType('lawnReturn'));
    await dispatch(addSuccess(text));
  };
  const fail = async text => {
    await dispatch(dismissType('lawnReturn'));
    await dispatch(addError(text));
  };

  try {
    await progress('Preparing return: Fetching invoice');
    const [salesDocument] = await getSalesDocuments({
      id: invoiceID,
    });
    if (!salesDocument) {
      await fail('Invoice to return not found');
      return;
    }
    await progress('Preparing return: Loading data into POS');
    await Promise.all([
      dispatch(setCustomer({ data: salesDocument.clientID })),
      dispatch(
        addMultiProducts(
          salesDocument.rows.map(p => ({
            ...p,
            name: p.itemName,
            amount: -p.amount,
          })),
        ),
      ),
      dispatch(setReturnDocument(salesDocument)),
      dispatch(
        setCurrentSalesDocument({
          attributes: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            program_return: invoiceID,
          },
        }),
      ),
    ]);
    await done('Ready to return');
  } catch (e) {
    await fail('Failed to prepare return');
    console.error('Failed to prepare return', e);
  }
};
