import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import Icon from 'components/Icon';
import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';

import { defaultPromotion, promoOptions, promoTypes } from './constants';
import { Configuration, Promotion } from './types';

import { getPromotionsArray, getSmallPromotionsArray } from '.';

type PromotionsConfigParams = {
  currentPromos: Configuration['promotions'];
  savePromos: (np: Configuration['promotions']) => void;
  promotionsType: 'small' | 'regular';
};

type PromoFieldType = 'type' | 'format' | 'duration' | 'src';

type PromoDict = Record<Promotion['id'], Promotion>;

const arrToDictById = (arr: Configuration['promotions'] = []) =>
  Object.fromEntries(arr.map(v => [v.id, v]));

export const PromotionsConfig: FC<PromotionsConfigParams> = ({
  currentPromos,
  savePromos,
  promotionsType,
}) => {
  const { t } = useTranslation('customerDisplay', {
    keyPrefix: 'promotionsForm',
  });
  const existingPromotions = useSelector(
    promotionsType === 'regular' ? getPromotionsArray : getSmallPromotionsArray,
  );
  const existingPromotionsDict = useMemo(
    () => arrToDictById(existingPromotions),
    [existingPromotions],
  );
  const [editedPromos, setEditedPromos] = useState<PromoDict>(
    arrToDictById(currentPromos),
  );

  const addRow = () => {
    const newPromoObj = R.assoc('id', uuidv4(), defaultPromotion);
    setEditedPromos(R.assoc(newPromoObj.id, newPromoObj));
  };

  const removePromo = (id: string) => setEditedPromos(R.dissoc(id));

  const handleChange = (
    newVal: string,
    fieldType: PromoFieldType,
    id: string,
  ) => {
    let newObj = R.assoc(
      fieldType,
      fieldType === 'duration' ? Number(newVal) : newVal,
      editedPromos[id],
    );
    if (fieldType === 'type') {
      newObj = R.assoc('format', promoOptions[newVal][0], newObj);
    }
    setEditedPromos(R.assoc(id, newObj));
  };

  const tempPromotions: PromoDict = R.mergeRight(
    arrToDictById([...existingPromotions, ...currentPromos]),
    editedPromos,
  );

  return (
    <>
      <h4>
        {t('title', { context: promotionsType === 'regular' ? '' : 'small' })}
      </h4>
      <Table>
        <thead>
          <tr>
            <th>{t('type')}</th>
            <th>{t('format')}</th>
            <th>{t('source')}</th>
            <th>{t('duration')}</th>
            <th>{t('delete')}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(tempPromotions).map(promo => (
            <tr key={`promotionRow${promo?.id}`}>
              <td>
                <InputField
                  type="select"
                  disabled={!editedPromos[promo.id]}
                  onChange={e => handleChange(e.target.value, 'type', promo.id)}
                  options={promoTypes}
                  size="md"
                  value={promo.type}
                />
              </td>
              <td>
                <InputField
                  type="select"
                  disabled={!editedPromos[promo.id]}
                  onChange={e =>
                    handleChange(e.target.value, 'format', promo.id)
                  }
                  options={promoOptions[promo.type]}
                  size="md"
                  value={promo.format}
                />
              </td>
              <td>
                <InputField
                  type="text"
                  disabled={!editedPromos[promo.id]}
                  onChange={e => handleChange(e.target.value, 'src', promo.id)}
                  size="md"
                  value={promo.src}
                />
              </td>
              <td>
                <InputField
                  type="text"
                  disabled={!editedPromos[promo.id]}
                  onChange={e =>
                    handleChange(e.target.value, 'duration', promo.id)
                  }
                  size="md"
                  value={promo.duration}
                />
              </td>
              <td>
                <Icon
                  name="icon_trash_alt"
                  disabled={!editedPromos[promo.id]}
                  action={() => removePromo(promo.id)}
                  title={t('delete')}
                  style={{
                    color: 'red',
                    fontSize: '20px',
                    alignSelf: 'flex-end',
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <UIButton action={() => addRow()} text={t('addNew')} />
      <UIButton
        action={() => savePromos(Object.values(editedPromos))}
        disabled={R.equals(editedPromos, existingPromotionsDict)}
        text={t('savePromos')}
      />
      <hr />
    </>
  );
};
