import React from 'react';
import { useSelector } from 'react-redux';

import {
  getProductDiscounts,
  getProductInOrderByIndex,
} from 'reducers/ShoppingCart';
import { Order } from 'types/ShoppingCart';

const AppliedDiscountsForQuantityColumn = React.memo<Pick<Order, 'orderIndex'>>(
  ({ orderIndex }) => {
    const order = useSelector(getProductInOrderByIndex(orderIndex)) as Order;
    const appliedDiscounts = useSelector(getProductDiscounts(order.rowNumber));
    return appliedDiscounts.length > 0
      ? appliedDiscounts.map(discount => (
          <span
            key={order.orderIndex}
            data-testid="discount-affected"
            data-test-key={`product-discount-${order.orderIndex}`}
            className="adjustment"
          >
            x {discount.affected}
          </span>
        ))
      : null;
  },
);

export default AppliedDiscountsForQuantityColumn;
