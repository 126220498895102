import { dismissType } from 'actions/Error';
import {
  getReceiptTemplateIdByDocType,
  getUseReceiptTemplates,
} from 'reducers/configs/settings';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import {
  getCafaEntry,
  getGeneralPrintingSettings,
  getPrintingMSSettings,
} from 'reducers/cafaConfigs';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { getSalesDocuments } from 'services/ErplyAPI/sales';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { getPrinterList } from 'services/InstallerApp/printingMS';
import { CancelActionOverrideError } from 'plugins/pluginUtils';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { getCausalStack } from 'utils';

import {
  GenerateReceiptParams,
  GenerateReceiptReturn,
  SalesDocument,
} from './types';
import { renderARHtmlForPrintMS } from './actions';
import { getIntegrationType, getFinalPatchScript } from './utils';

function getPatchScriptImageFromHtml(html: string) {
  return async (
    dispatch,
    getState,
  ) => {
    const {
      forcePatchscriptRender: { enabled, pixelWidth, scaleMultiplier },
    } = getGeneralPrintingSettings(getState());

    // Render using configured width and scale
    if (enabled) {
      const blob = await renderARHtmlForPrintMS(
        html,
        pixelWidth,
        scaleMultiplier,
      );
      return [{ type: 'image', blob }];
    }
    // Render and try to fill full printer paper width
    const goMSSettings = getPrintingMSSettings(getState());
    const printers = goMSSettings
      ? await getPrinterList(goMSSettings.value.PrinterGetFullList)
      : [];

    const selectedPrinter = printers.find(
      printer => printer.Name === goMSSettings?.value?.PrinterName,
    );
    const printerWidth =
      selectedPrinter?.[`PixelPerLine${goMSSettings?.value?.PrinterPaperSize}`];

    const blob = await renderARHtmlForPrintMS(html, printerWidth);
    return [{ type: 'image', blob }];
  };
}

async function getFullSalesDoc(salesDoc) {
  function getParams() {
    // there will be no id available when sale was made in offline mode
    if (salesDoc.id)
      return {
        id: salesDoc.id,
      };
    if (salesDoc.invoiceID)
      return {
        id: salesDoc.invoiceID,
      };
    return {
      number: salesDoc.invoiceNo,
    };
  }

  const docs = await getSalesDocuments(getParams());
  return docs?.[0] ?? {};
}

/**
 * Sales document object passed to `printReceipt` can be of different types
 * and depending on the particular type we may or may not need to fetch
 * the sale from API to get missing props. This function fetches the sale
 * only when necessary and caches the response from the API.
 */
export function getCachingSalePropAccessFn(salesDoc: SalesDocument) {
  let cachedSalesDoc: SaleDocumentResponse | null = null;

  return async function getPropOrFetchIfMissing(
    propName: keyof SaleDocumentResponse,
  ): Promise<SaleDocumentResponse[typeof propName]> {
    if (salesDoc[propName]) return salesDoc[propName];
    if (cachedSalesDoc?.[propName]) return cachedSalesDoc[propName];
    const fullSalesDoc = await getFullSalesDoc(salesDoc);
    cachedSalesDoc = fullSalesDoc;
    return cachedSalesDoc[propName];
  };
}

/**
 * Given a sales document, gathers up the necessary data for it and prints out
 * a sales receipt.
 * May make new network requests to fetch additional data about the document
 */
export function generateReceipt({
  salesDocument,
  options = {},
}: GenerateReceiptParams) {
  return async (
  dispatch,
  getState,
): Promise<GenerateReceiptReturn> => {
  const { before, on, after } = getPluginLifecycleHook(
    // @ts-ignore
    'onGenerateReceipt',
  )(getState());
  const integrationType = getIntegrationType(options);
  const useReceiptTemplates = getUseReceiptTemplates(getState());

  try {
    await dispatch(before({ salesDocument, options }));

    const getSaleProp = getCachingSalePropAccessFn(salesDocument);

    const saleType = await getSaleProp('type');
    const receiptTemplateId = getReceiptTemplateIdByDocType(
      options.giftReceipt ? 'GIFTRECEIPT' : saleType,
    )(getState());

    const hasActualReportsTemplate = useReceiptTemplates && receiptTemplateId;

    const saleId = await getSaleProp('id');
    const html = hasActualReportsTemplate
      ? await doClientRequest({
          request: 'getSalesDocumentActualReportsHTML',
          documentID: saleId,
          templateID: receiptTemplateId,
        }).then(records => (records as { html: string }[])[0]?.html)
      : undefined;

    if (
      saleType === 'INVWAYBILL' ||
      integrationType === 'order' ||
      integrationType === 'quote'
    ) {
      if (hasActualReportsTemplate)
        return { html, onlyBrowserPrint: true, hasActualReportsTemplate };
      const saleInvoiceLink = await getSaleProp('invoiceLink');
      return {
        url: saleInvoiceLink,
        onlyBrowserPrint: true,
        hasActualReportsTemplate,
      };
    }

    const url = salesDocument.receiptLink;

    const type = INTEGRATION_TYPES.printer;

    const useWrapper =
      getCafaEntry<typeof type, 'wrapper', { enabled: boolean }>(
        'wrapper',
        type,
      )(getState())?.value.enabled ?? false;
    const useMS =
      getCafaEntry<typeof type, 'goMS', { enabled: boolean }>(
        'goMS',
        type,
      )(getState())?.value.enabled ?? false;

    const useHtmlToGeneratePatchScript =
      (useMS || useWrapper) && hasActualReportsTemplate;
    const patchScript = await (useHtmlToGeneratePatchScript
      ? dispatch(getPatchScriptImageFromHtml(html ?? ''))
      : dispatch(getFinalPatchScript(salesDocument, options)));

    const receipt = await dispatch(
      on(
        { salesDocument, options },
        { html, patchScript, url, hasActualReportsTemplate },
      ),
    );

    await dispatch(after({ salesDocument, options }, null));
    return receipt;
  } catch (error) {
    const override = getCausalStack(error).find(
      e => e instanceof CancelActionOverrideError,
    ) as CancelActionOverrideError | undefined;
    if (override) return override.data as GenerateReceiptReturn; // Return receipt generated by plugin hook
    throw error;
  }
};
}
