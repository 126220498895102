import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';

import { CustomerFormFeedbackConfig } from '../types';
import { getQuestionsValidityMap } from '../utils';

const UICustomerFormSaveButton: PosPlugin['UICustomerFormSaveButton'] = ({
  children,
  disabled,
  customer,
}) => {
  const config = useSelector(
    getPluginConfiguration<CustomerFormFeedbackConfig>(
      'customer-creation-form-feedback-section',
    ),
  );

  if (config?.onlyOnNewCustomer && customer?.customerID) return children;

  const checkIfAllQuestionsHaveValidAnswers = () => {
    const validityMap = getQuestionsValidityMap(
      config?.questions,
      customer.attributes,
    );
    return Object.values(validityMap).every(Boolean);
  };

  return React.Children.map<ReactNode, ReactNode>(children, child =>
    React.isValidElement(child)
      ? React.cloneElement(child, {
          disabled: disabled || !checkIfAllQuestionsHaveValidAnswers(),
        })
      : child,
  );
};

export default UICustomerFormSaveButton;
