import { FETCH_ADYEN_SPLITS_BY_ID } from 'constants/CAFA';
import SET_MARKETPLACE_SPS from 'constants/marketplace';
import { fetchSplitsConfigByIDFromCAFA } from 'services/CAFA';

import { addError } from './Error';

function fetchSplitsConfigByID(splitsCafaID) {
  return async dispatch => {
    dispatch({ type: FETCH_ADYEN_SPLITS_BY_ID.START });

    try {
      const splitConfig = await fetchSplitsConfigByIDFromCAFA(splitsCafaID);
      if (splitConfig && splitConfig.id === splitsCafaID) {
        dispatch({ type: FETCH_ADYEN_SPLITS_BY_ID.SUCCESS });
        const keys = Object.keys(splitConfig.value);
        return splitConfig.value[keys[0]];
      }
      throw new Error(
        'Unable to load or parse configs for adyen marketplace accounts',
      );
    } catch (error) {
      console.error('Unable to fetch splits', error);

      if (error.message === 'Request failed with status code 500') {
        dispatch(addError('No adyen marketplace data found for this ID'));
      }

      return false;
    }
  };
}

export function setMarketplaceSPS(config) {
  return {
    type: SET_MARKETPLACE_SPS.SUCCESS,
    payload: config,
  };
}

export default fetchSplitsConfigByID;
