import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { getPluginConfiguration, getPluginSelector } from 'reducers/Plugins';
import { getClientCode, getSessionKey } from 'reducers/Login';
import { programPickUp, returnProgram } from 'plugins/zamzow/eventHandlers';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getProductByID } from 'reducers/cachedItems/products';
import { addError, addWarning } from 'actions/Error';
import { getTaxFreeVatRate } from 'reducers/vatRatesDB';
import { getIsAReturn } from 'reducers/sales';
import { ErplyAttributes } from 'utils';
import { addMultiProducts, removeProduct } from 'actions/ShoppingCart';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';

import {
  STORE_PRODUCTS_IN_CART,
  SELECTED_CUSTOMER,
  ONGOING_SALE,
} from './actionTypes';

const links = {
  staging: 'https://lawn-programs-in-pos-staging.herokuapp.com',
  live: 'https://lawn-programs-in-pos.herokuapp.com',
};

type Configuration = {
  staging: boolean;
};

const getIsLawnProduct = id => state =>
  getProductByID(id)(state).type === 'BUNDLE';
const getIsLawnSale = state =>
  getProductsInShoppingCart(state).some(p =>
    getIsLawnProduct(p.productID)(state),
  );

const getZamzowLawnState = state =>
  getPluginSelector<{
    productsInCart: [];
    customerID: any;
    ongoingSale: boolean;
  }>('zamzow')(n => n)(state);

export const zamzowLawncarePlugin: PosPlugin<
  Configuration,
  Configuration,
  {},
  {},
  {}
> = {
  id: 'zamzow',
  name: 'Zamzow lawncare program',

  getStatus: state => {
    const { id } = getTaxFreeVatRate(state) ?? {};
    if (!id)
      return { type: 'error', message: 'Account has no 0% vat rates to use' };
    return { type: 'valid', message: '' };
  },
  combineConfiguration: company =>
    company ?? {
      staging: true,
    },
  ComponentConfigurationByLevel: {
    Company: ({ current, save }) => {
      const [staging, setStaging] = useState(current?.staging || false);
      return (
        <>
          <InputField
            type="checkbox"
            onChange={e => setStaging(e.target.value)}
            value={staging}
          >
            Use staging server
          </InputField>
          <InputField
            type="checkbox"
            onChange={e => setStaging(!e.target.value)}
            value={!staging}
          >
            Use production server
          </InputField>
          <UIButton text="Save" action={() => save({ staging })} />
        </>
      );
    },
  },
  reduxReducer: (
    state = {
      productsInCart: [],
      ongoingSale: false,
      customerID: null,
    },
    action,
  ) => {
    switch (action.type) {
      case ONGOING_SALE:
        return {
          ...state,
          ongoingSale: action.payload,
        };
      case STORE_PRODUCTS_IN_CART:
        return {
          ...state,
          productsInCart: action.payload,
        };
      case SELECTED_CUSTOMER:
        return {
          ...state,
          customerID: action.payload,
        };
      default:
        return state;
    }
  },
  UICustomerViewPreviousPurchasesTable: ({ children, customer }) => {
    const { staging = true } =
      useSelector(getPluginConfiguration<Configuration>('zamzow')) ?? {};
    const clientCode = useSelector(getClientCode);
    const sessionKey = useSelector(getSessionKey);
    const link = staging ? links.staging : links.live;
    const dispatch = useDispatch();

    useEffect(() => {
      const handler = (e: MessageEvent) => {
        switch (e.data.methodName) {
          case 'programPickUp':
            dispatch(programPickUp(customer, e.data.methodData));
            break;
          case 'returnProgram':
            dispatch(returnProgram(e.data.methodData));
            break;
          default:
            break;
        }
      };
      window.addEventListener('message', handler);
      return () => window.removeEventListener('message', handler);
    }, [customer, dispatch]);

    return (
      <>
        <iframe
          src={`${link}/?cID=${customer.customerID}&account_code=${clientCode}&session_KEY=${sessionKey}`}
          style={{ width: '100%', height: '600px', backgroundColor: 'white' }}
        />
        {children}
      </>
    );
  },
  onSetCustomer: {
    before: p => async (dispatch, getState) => {
      const isLawnSale = getIsLawnSale(getState());
      const isDefaultCustomer = getIsDefaultCustomer(getState());
      if (isLawnSale && isDefaultCustomer) {
        const items = getProductsInShoppingCart(getState());
        await dispatch(
          items
            .filter(item => getIsLawnProduct(item.productID)(getState()))
            .map(item =>
              removeProduct(item.orderIndex, { showWarning: false }),
            ),
        );
        dispatch(
          addWarning('Cannot sell Lawn Care programs to default customer'),
        );
      }
    },
  },
  /** Preventing edit of bundle sale or adding bundle product to non-empty cart */
  onAddProduct: {
    on: (p, { product, options, ...rest }) => async (dispatch, getState) => {
      const isLawnProduct = getIsLawnProduct(p.productID)(getState());
      const isDefaultCustomer = getIsDefaultCustomer(getState());

      if (isLawnProduct && isDefaultCustomer) {
        dispatch(
          addWarning('Cannot sell Lawn Care programs to default customer'),
        );
        throw new Error('Cannot sell Lawn Care programs to default customer');
      }

      const state = getState();
      const existing = getProductsInShoppingCart(state);
      if (
        getIsLawnSale(state) ||
        (getIsLawnProduct(p.productID)(state) && existing.length)
      ) {
        dispatch(addWarning('Bundles can only be sold separately'));
        throw new Error('Bundles can only be sold separately');
      }
      return { product, options, ...rest };
    },
  },

  /** Set program_status=open if relevant bundle sold */
  onSaveSalesDocument: {
    on: (p, ap) => async (dispatch, getState) => {
      const lawn = getIsLawnSale(getState());
      const isReturn = getIsAReturn(getState());
      if (lawn && !isReturn) {
        return ap.map(r =>
          r.requestName !== 'saveSalesDocument'
            ? r
            : {
                ...r,
                type: 'INVOICE',
                ...ErplyAttributes.fromFlatArray(r).set(
                  'program_status',
                  'open',
                ).asFlatArray,
              },
        );
      }
      if (lawn && isReturn) {
        const attrs = ap.find(req => req.requestName === 'saveSalesDocument')
          ?.attributes;
        const originalID = new ErplyAttributes(attrs).get('program_return');
        if (!attrs || !originalID) {
          dispatch(
            addError(
              'Could not find orignal invoice ID to update returned status',
            ),
          );
          console.error(
            'Could not find orignal invoice ID to update returned status',
            { ap, attrs, originalID },
          );
        }
        return ap.concat([
          {
            requestName: 'saveSalesDocument',
            id: originalID,
            ...new ErplyAttributes({
              program_status: 'returned',
            }).asFlatArray,
          },
        ]);
      }
      return ap;
    },
    after: (p, ep) => async (dispatch, getState) => {
      const { productsInCart, customerID, ongoingSale } = getZamzowLawnState(
        getState(),
      );

      if (productsInCart.length > 0 && ongoingSale) {
        dispatch({ type: ONGOING_SALE, payload: false });
        dispatch(addMultiProducts(productsInCart));
        dispatch(
          setCustomer({
            data: customerID,
          }),
        );
      }
    },
  },
};
