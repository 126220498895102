import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { assoc, assocPath, dissoc, equals } from 'ramda';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Alert from '@material-ui/lab/Alert';

import useProducts from 'utils/hooks/useProducts';
import SaveButton from 'components/CustomButtons/SaveButton';
import { PosPlugin } from 'plugins/plugin';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import {
  getProductGroupsDBSlice,
  getProductGroupsTreeOrdered,
} from 'reducers/productGroupsDB';

import { USDLProductConfig } from '../types';

const defaultProdConfig = { minAge: 18, maxAmount: 0 };

const useStyles = makeStyles({
  show: {
    position: 'absolute',
    top: 50,
    left: 0,
    maxHeight: '30vh',
    margin: '0 4px',
    borderRadius: '4px',
    zIndex: 100,
    overflowY: 'auto',
    flexDirection: 'column',
    border: '1px solid black',
  },
  hide: {
    display: 'none',
  },
});

const SearchProduct = ({ selectProduct }) => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  const [opened, setOpened] = useState(false);

  const { products } = useProducts(
    { searchNameIncrementally: searchValue, recordsOnPage: 20 },
    undefined,
    1000,
  );

  const handleSelect = (productID, groupID) => {
    selectProduct(productID, groupID);
    setSearchValue('');
    setOpened(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <TextField
        label="Search for Product"
        value={searchValue}
        onFocus={() => setOpened(true)}
        onChange={e => setSearchValue(e.target.value)}
        style={{ position: 'relative' }}
      />
      <Paper className={opened ? classes.show : classes.hide}>
        {products.map(({ name, productID, groupID }) => (
          <MenuItem
            key={productID}
            onClick={() => handleSelect(productID, groupID)}
          >
            <Typography key={productID}>{name}</Typography>
          </MenuItem>
        ))}
      </Paper>
    </div>
  );
};

const SearchProductGroup = ({ selectProductGroup }) => {
  const handleSelect = e => {
    const { value } = e.target;
    selectProductGroup(value);
  };

  const productGroups = useSelector(getProductGroupsTreeOrdered);
  return (
    <TextField
      select
      style={{ minWidth: '25ch' }}
      value={0}
      onSelect={handleSelect}
    >
      <MenuItem value={0} disabled>
        Select Product Group
      </MenuItem>
      {productGroups.map((option: any) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => selectProductGroup(option.value)}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

const EditableTableCell: FC<{
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isOverridden?: boolean;
  min?: number;
  max?: number;
}> = ({ value, onChange, min, max, isOverridden = false }) => {
  const [editMode, setEditMode] = useState(false);
  const enableEditMode = () => {
    if (!editMode) setEditMode(true);
  };

  const overrideValue = min ?? max;
  return (
    <TableCell onClick={enableEditMode} align="right">
      {editMode ? (
        <TextField
          autoFocus
          onBlur={() => setEditMode(false)}
          onKeyDown={e =>
            (e.key === 'Enter' || e.key === 'Escape') && setEditMode(false)
          }
          type="number"
          inputProps={{
            min: min ?? 0,
            max: max ?? Infinity,
            style: { textAlign: 'right', width: '3em' },
          }}
          value={value}
          onChange={onChange}
        />
      ) : (
        <Typography style={{ padding: '6px 0 7px' }}>
          <span
            style={
              isOverridden
                ? {
                    textDecoration: 'line-through',
                    textDecorationColor: 'red',
                    marginRight: '0.25em',
                  }
                : {}
            }
          >
            {value}
          </span>
          {isOverridden && <span>{overrideValue}</span>}
        </Typography>
      )}
    </TableCell>
  );
};

const ProductConfigurationTable = ({
  state,
  setter,
  productGroupConfiguration,
}) => {
  const { productsDict } = useProducts({
    productIDs: Object.keys(state).map(Number),
  });

  const getMinRequiredAge = useCallback(
    (productID, groupID) => productGroupConfiguration[groupID]?.minAge,
    [productsDict, productGroupConfiguration],
  );

  const getMaxAllowedAmount = useCallback(
    (productID, groupID) => productGroupConfiguration[groupID]?.maxAmount,
    [productsDict, productGroupConfiguration],
  );

  const addProduct = (productID: number, groupID: number) => {
    setter(
      assoc(productID, {
        minAge: getMinRequiredAge(productID, groupID) ?? 18,
        maxAmount: getMaxAllowedAmount(productID, groupID) ?? 0,
      }),
    );
  };

  const updateProductConfig = (productID, propName, value) => {
    setter(assocPath([productID, propName], value));
  };

  const removeRecord = (productID: number) => {
    setter(dissoc(productID));
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <SearchProduct selectProduct={addProduct} />
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Product code</TableCell>
              <TableCell align="right">Max Amount</TableCell>
              <TableCell align="right">Min Age</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(state).map(prodID => {
              const minAge = getMinRequiredAge(
                prodID,
                productsDict[prodID]?.groupID,
              );
              const maxAmount = getMaxAllowedAmount(
                prodID,
                productsDict[prodID]?.groupID,
              );
              return (
                <TableRow key={prodID}>
                  <TableCell>{productsDict[prodID]?.name}</TableCell>
                  <TableCell>{productsDict[prodID]?.code}</TableCell>
                  <EditableTableCell
                    value={state[prodID].maxAmount}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      updateProductConfig(
                        prodID,
                        'maxAmount',
                        // do not allow bigger maxAmount than the one from groupConfig
                        Math.min(Number(e.target.value), maxAmount),
                      )
                    }
                    isOverridden={maxAmount < state[prodID].maxAmount}
                    max={maxAmount}
                  />
                  <EditableTableCell
                    value={state[prodID].minAge}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      updateProductConfig(
                        prodID,
                        'minAge',
                        // do not allow smaller minAge than the one from groupConfig
                        Math.max(Number(e.target.value), minAge),
                      )
                    }
                    isOverridden={minAge > state[prodID].minAge}
                    min={minAge}
                  />
                  <TableCell align="right">
                    <Button onClick={() => removeRecord(Number(prodID))}>
                      <DeleteOutlinedIcon color="error" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};

const ProductGroupConfigurationTable = ({ state, setter }) => {
  const productGroupDict = useSelector(getProductGroupsDBSlice);
  const addProductGroup = (productGroupID: number) => {
    setter(assoc(productGroupID, defaultProdConfig));
  };

  const updateProductGroupConfig = (productID, propName, value) => {
    setter(assocPath([productID, propName], value));
  };

  const removeRecord = (productID: number) => {
    setter(dissoc(productID));
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <SearchProductGroup selectProductGroup={addProductGroup} />
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Group Name</TableCell>
              <TableCell align="right">Max Amount</TableCell>
              <TableCell align="right">Min Age</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(state).map(prodGroupID => (
              <TableRow key={prodGroupID}>
                <TableCell>{productGroupDict[prodGroupID]?.name}</TableCell>
                <EditableTableCell
                  value={state[prodGroupID].maxAmount}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    updateProductGroupConfig(
                      prodGroupID,
                      'maxAmount',
                      Number(e.target.value),
                    )
                  }
                />
                <EditableTableCell
                  value={state[prodGroupID].minAge}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    updateProductGroupConfig(
                      prodGroupID,
                      'minAge',
                      Number(e.target.value),
                    )
                  }
                />
                <TableCell align="right">
                  <Button onClick={() => removeRecord(Number(prodGroupID))}>
                    <DeleteOutlinedIcon color="error" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};

const ComponentConfiguration: PosPlugin['ComponentConfiguration'] = ({
  current = {},
  byLevel: {
    company: { save },
  },
}) => {
  const dispatch = useDispatch();

  const [prodGroupConfigs, setProdGroupConfigs] = useState<
    Record<number, USDLProductConfig>
  >(current.productGroups ?? {});

  const [prodConfigs, setProdConfigs] = useState<
    Record<number, USDLProductConfig>
  >(current.products ?? {});

  const saveConfig = () => {
    save({ products: prodConfigs, productGroups: prodGroupConfigs });
    dispatch(previousModalPage());
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <SaveButton
          action={saveConfig}
          disabled={
            equals(current.products, prodConfigs) &&
            equals(current.productGroups, prodGroupConfigs)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          Configure Products and Product Groups for Age Verification
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          To add age verification for given product or product group, add them
          from the search to the Configuration tables. Each product/product
          group would get default minimum required age 18 and default maximum
          sellable amount 0. POS only prevents the sell of amounts that are
          greater than 0. If amount is 0 (default), POS would not control the
          product amount in the shopping cart. To update the age or the max
          amount per sale, click on the table cell representing the value and
          change the value in it.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="warning">
          If a product and its product group both have been configured the
          plugin would take the lowest max amount and the highest min age.
        </Alert>
      </Grid>
      <ProductConfigurationTable
        state={prodConfigs}
        setter={setProdConfigs}
        productGroupConfiguration={prodGroupConfigs}
      />
      <ProductGroupConfigurationTable
        state={prodGroupConfigs}
        setter={setProdGroupConfigs}
      />
    </Grid>
  );
};

export default ComponentConfiguration;
