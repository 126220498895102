import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, MenuItem, Typography } from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { getThemeSlice } from 'reducers/UI/theme';
import { setColorTheme } from 'actions/UI';
import CloseButton from 'components/CustomButtons/CloseButton';
import { RootState } from 'reducers';
import ThemeDropdown from 'components/ThemeDropdown';

interface Props {
  onClose(): void;
}

export const UNSET_VALUE = 'unset';

function Theme({ onClose }: Props) {
  const { t } = useTranslation('settingsTheme');
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const { theme, persist } = useSelector(getThemeSlice);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value === UNSET_VALUE) return dispatch(setColorTheme(null));
    // @ts-ignore
    return dispatch(setColorTheme(value));
  }

  return (
    <div data-testid="high-contrast-theme">
      <Box display="flex" alignItems="center" padding="1rem">
        <Typography variant="h5">{t('title')}</Typography>
        <Box flexGrow={1} />
        <CloseButton action={onClose} />
      </Box>
      <Divider />
      <Box padding="1rem">
        <ThemeDropdown
          value={persist ? theme ?? UNSET_VALUE : UNSET_VALUE}
          onChange={handleChange}
          helperText={t('appliedLocally')}
          extraOptions={
            <MenuItem key={UNSET_VALUE} value={UNSET_VALUE}>
              {t('options.unset')}
            </MenuItem>
          }
        />
      </Box>
    </div>
  );
}

export default Theme;
