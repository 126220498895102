import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import ProductSearch from 'containers/Col1/Search/Products';

const MobileSearchProducts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('productSearch');

  const close = () => dispatch(previousModalPage());
  return (
    <Container style={{ height: '90vh', overflow: 'auto' }} fluid>
      <Modal.Header
        style={{
          display: 'flex',
        }}
      >
        <Modal.Title style={{ paddingTop: '25px', fontWeight: 900 }}>
          {t('title')}
        </Modal.Title>
        <div style={{ marginLeft: 'auto' }}>
          <CloseButton
            style={{ marginTop: '-16px', marginLeft: '8px' }}
            action={() => close()}
          />
        </div>
      </Modal.Header>
      <div className="w-100">
        <ProductSearch active={true} closePopover={close} />
      </div>
    </Container>
  );
};

export default MobileSearchProducts;
