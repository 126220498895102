import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AxiosError } from 'axios';

import { setWaitingForTerminal } from 'actions/Payments';
import { RootState } from 'reducers';

export function withWaitingForTerminal<T>(
  func: () => Promise<T>,
): ThunkAction<Promise<T>, RootState, unknown, AnyAction> {
  return async dispatch => {
    try {
      dispatch(setWaitingForTerminal(true));
      const response = await func();
      return response;
    } catch (err) {
      console.error('withWaitingForTerminal function failed due to: ', err);
      throw err;
    } finally {
      dispatch(setWaitingForTerminal(false));
    }
  };
}

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).response !== undefined;
}

export function getErrorMessage(
  error: unknown,
  defaultMessage: string,
): string {
  if (isAxiosError(error)) {
    const axiosError =
      error.response?.data?.errorMessage ?? error.response?.data?.message;
    return axiosError ?? defaultMessage;
  }
  if (error instanceof Error) {
    return error.message ?? defaultMessage;
  }
  return defaultMessage;
}
