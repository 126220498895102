import axios, { AxiosResponse } from 'axios';

import {
  Coupon,
  GetCouponsParams,
  GetCouponsRequestParams,
  GetCouponsResponse,
} from './types';

const getBaseUrl = isProduction =>
  isProduction
    ? 'https://couponservices.mmms.ca/activation/pos/'
    : 'https://dev-couponservices.mmms.ca/activation/pos/';

export const getCoupons = async ({
  token,
  isProduction,
  customerId,
  clientCode,
  langCode,
}: GetCouponsParams) => {
  const baseUrl = getBaseUrl(isProduction);
  return axios
    .get<GetCouponsRequestParams, AxiosResponse<GetCouponsResponse>>(baseUrl, {
      params: {
        token,
        customerId,
        langCode,
        posaccount: clientCode,
      },
    })
    .then(({ data }) => data.coupons);
};
