import * as R from 'ramda';

/**
 * Given the base price of a product, and any one discount property:
 * * price: The desired price of the product (2.99 = "only 2.99$")
 * * discount: The desired discount percentage (0.2 = "20% off")
 * * discountValue: The desired discount amount (10 = "10$ off")
 * Will calculate the remaining three properties and return all three
 *
 * NB: Discount percentage is to be given as a fraction, if you have a percentage divide it by 100 first
 * @example
 * const {discount, discountValue} = calculateDiscountForms({basePrice: 20, price: 2});
 * // returns {price: 2, discount: .9, discountValue: 18}
 */
export const calculateDiscountForms = ({
  basePrice: A,
  price,
  discount,
  discountValue,
}: {
  basePrice: number;
  price: number;
  discount: number;
  discountValue: number;
}): {
  price: number;
  discount: number;
  discountValue: number;
} => {
  // prevents discounts above 100%
  const B = Math.max(0, price);
  const C = Math.min(1, discount);
  const D = Math.min(A, discountValue);

  if (A === undefined)
    throw new Error(
      `calculateDiscountForms expects a valid basePrice, but received ${A}`,
    );
  if (!Number.isNaN(D))
    return {
      price: A - D,
      discount: D / A,
      discountValue: D,
    };
  if (!Number.isNaN(C)) {
    return {
      price: A * (1 - C),
      discount: C,
      discountValue: A * C,
    };
  }
  if (!Number.isNaN(B)) {
    return {
      price: B,
      discount: 1 - B / A,
      discountValue: A - B,
    };
  }
  throw new Error(
    'calculateDiscountForms expects at least one of "price", "discountPercentage", or "discountAmount" to be passed',
  );
};

export const formatNumber = R.pipe(
  // Comma key inputs periods
  R.replace(/[,]/g, '.'),
  // Illegal characters are ignored
  R.replace(/[^0-9-.]/g, ''),
  // Only one decimal separator, cut off the rest
  R.split('.'),
  R.take(2),
  R.join('.'),
  // Join and move "-" signs to the beginning
  // prettier-ignore
  R.when(
    R.test(/-/),
    R.pipe(
      R.replace(/-/g, ''),
      R.replace(/^/, '-')
    )
  ),
  // Add leading 0 if missing
  // @ts-ignore
  R.replace(/^\./, '0.'),
) as (num: string) => string;
