import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { mergeDeepRight } from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import UIButton from 'components/UIElements/UIButton';
import { getPointsOfSale } from 'reducers/PointsOfSale';

import { ConfType } from '.';

type CompanyConfiguration = Required<
  PosPlugin<ConfType>
>['ComponentConfigurationByLevel']['Company'];

const Configuration: CompanyConfiguration = ({
  current = { homeStores: {} },
  save,
}) => {
  const known = Object.fromEntries(
    useSelector(getPointsOfSale).map(pos => [pos.pointOfSaleID, pos.name]),
  );

  const loaded = Object.keys(known).filter(
    k => current.homeStores[k] === known[k],
  );
  const outOfDate = Object.keys(known).filter(
    k => current.homeStores[k] && current.homeStores[k] !== known[k],
  );
  const missing = Object.keys(known).filter(k => !current.homeStores[k]);
  const unknown = Object.keys(current.homeStores ?? {}).filter(k => !known[k]);
  const load = () =>
    save(
      mergeDeepRight(current, {
        homeStores: known,
      }),
    );
  const reset = () => save({ ...current, homeStores: {} });

  const anythingToLoad = outOfDate.length + missing.length;

  return (
    <Modal.Body>
      <UIButton
        action={anythingToLoad ? load : () => {}}
        disabled={!anythingToLoad}
        text={
          anythingToLoad
            ? 'Load homestore options from current user'
            : 'All stores known to current user already loaded'
        }
      />
      <UIButton action={reset} variant="danger" text="Reset homestores" />
      <ul>
        <h3>Homestore options</h3>
        {unknown.map(k => (
          <li style={{ color: 'gray' }}>
            {current.homeStores[k]} (not visible to current user)
          </li>
        ))}
        {missing.map(k => (
          <li style={{ color: 'salmon' }}>{known[k]} (not loaded)</li>
        ))}
        {outOfDate.map(k => (
          <li style={{ color: 'orange' }}>
            {current.homeStores[k]} (Name mismatch, current name is {known[k]})
          </li>
        ))}
        {loaded.map(k => (
          <li>{known[k]} (loaded)</li>
        ))}
      </ul>
    </Modal.Body>
  );
};

export default Configuration;
