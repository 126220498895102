/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import * as R from 'ramda'

import {
  TYPE_ADDERROR,
  TYPE_DISMISS,
  TYPE_DISMISS_ALL,
  TYPE_DISMISS_TYPE,
  TYPES,
  TYPE_SET_UNREAD,
  TYPE_READ_ALL,
  TYPE_RESET,
} from 'constants/Error';
import { getHasUndismissedErrorsOfType } from 'reducers/Error';
import { getPluginLifecycleHook } from 'reducers/Plugins';

const SentryCategory = 'ErplyAlert';

const sesStId = sessionStorage.getItem('latestAlertId');
const alerts = sessionStorage.getItem('alerts') ?? '[]';
function parseAlerts() {
  try {
    const parsedAlerts = JSON.parse(alerts);
    return Array.isArray(parsedAlerts) ? parsedAlerts : [];
  } catch {
    return [];
  }
}
const parsedAlerts = parseAlerts();
const maxAlert = parsedAlerts.length ? R.maxBy(R.prop('id'), parsedAlerts) : 0;
const sessId = sesStId ? Number(sesStId) : 0;

let lastId = Math.max(maxAlert, sessId);

/**
 * A promise to ensure that adding/removing errors runs in order, since they have been turned into async actions now
 */
let errorQueue = Promise.resolve();

function runWithQueue(action) {
  return function thunk(dispatch, getState) {
    errorQueue = errorQueue
      // ignore previous errors
      .catch(() => undefined)
      // Run current action
      .then(() => dispatch(action));
    return errorQueue;
  };
}

export function dismiss(id) {
  return runWithQueue({ type: TYPE_DISMISS, payload: id });
}
export function dismissType(type) {
  return runWithQueue(async (dispatch, getState) => {
    if (getHasUndismissedErrorsOfType(type)(getState())) {
      dispatch({ type: TYPE_DISMISS_TYPE, payload: type });
    }
  });
}
export function dismissAll() {
  return runWithQueue({ type: TYPE_DISMISS_ALL });
}
export function reset() {
  return runWithQueue({ type: TYPE_RESET });
}

export function setUnreadNotification(id, unread) {
  return {
    type: TYPE_SET_UNREAD,
    payload: { id, unread },
  };
}
export function readAllNotifications() {
  return {
    type: TYPE_READ_ALL,
  };
}

function add(
  TYPE,
  text,
  { dismissible, selfDismiss: selfDismissParam, errorType: name }: Options,
) {
  return async (dispatch, getState) => {
    const state = getState();

    const selfDismiss = selfDismissParam === true ? 2500 : selfDismissParam;
    try {
      let params = {
        id: 0,
        text,
        dismissible,
        selfDismiss,
        name,
        type: TYPE,
        dismissed: false,
        timestamp: Date.now(),
        unread: false,
      };
      // @ts-ignore
      const { before, on, after } = getPluginLifecycleHook('onAddAlert')(state);

      await dispatch(before(params));

      Sentry.addBreadcrumb({
        category: SentryCategory,
        level:
          ({
            [TYPES.SUCCESS]: 'info',
            [TYPES.WARNING]: 'warning',
            [TYPES.ERROR]: 'error',
          }[TYPE] as Sentry.Severity) ?? 'log',
        message: text,
        data: {
          type: 'success',
        },
      });

      lastId += 1;
      params.id = lastId;
      params = await dispatch(on(params, params));
      dispatch({
        type: TYPE_ADDERROR,
        payload: params,
      });
      dispatch(after(params, null));
      return lastId;
    } catch (e) {
      // Unexpected error or error from plugin hook
      console.error('Error displaying POS message', { e });
    }
    return lastId;
  };
}

type Options = {
  /** @default true */
  dismissible: boolean;
  /** @default true */
  selfDismiss: number | boolean;
  errorType?: string;
};

function Options(opt?: Partial<Options>): Options {
  return {
    dismissible: true,
    selfDismiss: true,
    ...opt,
  };
}

export function addSuccess(text: string, options?: Partial<Options>) {
  return runWithQueue(add(TYPES.SUCCESS, text, Options(options)));
}
export function addWarning(text: string, options?: Partial<Options>) {
  return runWithQueue(add(TYPES.WARNING, text, Options(options)));
}
export function addError(text: string, options?: Partial<Options>) {
  return runWithQueue(add(TYPES.ERROR, text, Options(options)));
}
export function addProgress(text: string, errorType: string) {
  return runWithQueue(
    add(TYPES.WARNING, text, {
      dismissible: false,
      selfDismiss: false,
      errorType,
    }),
  );
}
