import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';

import { ALLOWED_SALE_OPTION_IDS } from '../constants';
import { getSAINumber } from '../redux/selectors';

function getShouldDisable(key: string) {
  return !ALLOWED_SALE_OPTION_IDS.includes(key);
}

const getSaleOptionButtons: Required<
  PosPlugin
>['selectorOverrides']['getSaleOptionButtons'] = base => {
  return createSelector(base, getSAINumber, (saleOptions, SAINumber) => {
    if (SAINumber !== null) return saleOptions;

    return saleOptions.map(saleOption => {
      const shouldDisable = getShouldDisable(saleOption.id);

      if (!shouldDisable) return saleOption;
      return {
        ...saleOption,
        disabled: true,
        style: { opacity: 0.25, pointerEvents: 'none' },
      };
    });
  });
};

export default getSaleOptionButtons;
