import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './GiftCard.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import {
  Box,
  InputAdornment,
  Button as MuiButton,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  getCurrencyFormatter,
  getPosDefaultLanguage,
  getSetting,
} from 'reducers/configs/settings';
import InputField from 'components/FieldTypes/InputField';
import { getRegularGiftcards, getGiftcardTypes } from 'reducers/giftcards';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { setPayment } from 'actions/Payments/setPayment';
import {
  getBalance,
  getIsGiftcardDisabled,
  getIsSerialGiftcardDisabled,
  getNewPaymentKeyForType,
  getPaymentSelected,
} from 'reducers/Payments';
import { PluginComponent } from 'plugins';
import { addError, addWarning, dismissType } from 'actions/Error';
import {
  addSerialGiftCardPayment,
  fetchSerialGiftCard,
} from 'actions/giftcards';
import { useAppDispatch } from 'reducers';
import { useAsyncFunctionNoParallelExecution } from 'utils/hooks/useSingleUseFunction';

const sanitizeAmount = raw => {
  let clean = raw
    .replace(/[,]/g, '.')
    .replace(/[^0-9.]/g, '')
    .replace(/[.](?=.*[.])/g, '');
  if (clean.match(/^[.]/g)) clean = clean.replace(/^[.]/g, '0.');
  if (clean.match(/^[0]+[1-9]/g)) clean = clean.replace(/^[0]/g, '');
  return clean;
};

const GiftCard = () => {
  const { t } = useTranslation('payment');
  const regularGiftCards = useSelector(getRegularGiftcards);

  const [state, setState] = useState({
    serialCardNumber: '',
    regularCardAmount: '',
    regularGiftCards,
    selectedCard: '0',
    regularCardsLoaded: true,
    giftCardNotes: '',
  });
  const [selectedSerialGiftCard, setSelectedSerialGiftCard] = useState(null);
  const [
    selectedSerialGiftCardAmount,
    setSelectedSerialGiftCardAmount,
  ] = useState('');

  const dispatch = useAppDispatch();

  const useSerialGiftCardPaymentAmountInput = useSelector(
    getSetting('pos_use_serial_gift_card_payment_amount_input'),
  );
  const paymentKey = useSelector(getNewPaymentKeyForType('giftCard'));
  const balance = useSelector(getBalance);
  const isGiftcardDisabled = useSelector(getIsGiftcardDisabled);
  const isSerialGiftcardDisabled = useSelector(getIsSerialGiftcardDisabled);
  const gcTypes = useSelector(getGiftcardTypes);
  const typeID = useSelector(getPaymentSelected).match(/giftcard-(.+)/)?.[1];
  const giftCard = gcTypes.find(t => String(t.id) === String(typeID));
  const type = giftCard?.name;

  // Serial field shown if either:
  //    1) Opened from button of specific type
  // or 2) There are no specific type buttons
  const showSerialField =
    !isSerialGiftcardDisabled && (type || gcTypes.length === 0);

  // Regular field shown if:
  //    1) Opened from regular button (not a specific type button)
  const showRegularField = !isGiftcardDisabled && !type;

  const [
    searchSerialGiftCard,
    { isUsed: isSearchingForSerialGiftCard },
  ] = useAsyncFunctionNoParallelExecution(async () => {
    dispatch(dismissType('giftCard'));
    const serialGiftCard = await dispatch(
      fetchSerialGiftCard(state.serialCardNumber?.trim()),
    );
    if (!serialGiftCard) return;
    if (typeID) {
      if (!serialGiftCard.typeID) {
        dispatch(addWarning(t('alerts.serialGiftCardTypeMissing')), {
          dismissible: true,
          errorType: 'giftCard',
          selfDismiss: 8000,
        });
      } else if (String(serialGiftCard.typeID) !== String(typeID)) {
        const foundGiftCardType = gcTypes.find(
          t => String(t.id) === String(serialGiftCard.typeID),
        );
        dispatch(
          addError(
            t('alerts.serialGiftCardTypeIsWrong', {
              giftCardType: foundGiftCardType?.name,
            }),
            { dismissible: true, errorType: 'giftCard', selfDismiss: 20000 },
          ),
        );
        return;
      }
    }

    const paymentAmount = String(
      Math.min(serialGiftCard.balance, Math.abs(balance)),
    );
    if (useSerialGiftCardPaymentAmountInput) {
      setSelectedSerialGiftCard(serialGiftCard);
      setSelectedSerialGiftCardAmount(paymentAmount);
    } else {
      await dispatch(addSerialGiftCardPayment(serialGiftCard, paymentAmount));
    }
  });

  const [
    addSerialGiftCard,
    { isUsed: isAddingSerialGiftCard },
  ] = useAsyncFunctionNoParallelExecution(async () => {
    if (!selectedSerialGiftCard) return;
    await dispatch(
      addSerialGiftCardPayment(
        selectedSerialGiftCard,
        selectedSerialGiftCardAmount,
      ),
    );
  });

  const handleChange = e => {
    let stateValue = e.target.value;
    switch (e.target.name) {
      case 'regularCardSelect':
        setState({ ...state, selectedCard: stateValue });
        break;
      case 'regularCardAmount':
        stateValue = sanitizeAmount(stateValue);
        setState({ ...state, [e.target.name]: stateValue });
        break;
      default:
        setState({ ...state, [e.target.name]: stateValue });
    }
  };

  const setRegularGiftCard = () => {
    let paymentAmount = Number(state.regularCardAmount);
    dispatch(dismissType('giftCard'));
    if (isGiftcardDisabled) {
      dispatch(
        addWarning(t('alerts.regularGiftcardDisabled'), {
          dismissible: false,
          errorType: 'giftCard',
          selfDismiss: 2000,
        }),
      );
      return false;
    }
    if (!paymentAmount) {
      dispatch(
        addWarning(t('alerts.giftcardAmountRequired'), {
          dismissible: false,
          errorType: 'giftCard',
          selfDismiss: 2000,
        }),
      );
      return false;
    }
    if (!state.regularGiftCards[state.selectedCard]) {
      dispatch(
        addWarning(t('alerts.giftCardRequired'), {
          dismissible: false,
          errorType: 'giftCard',
          selfDismiss: 2000,
        }),
      );
      return false;
    }
    if (paymentAmount + balance > 0) {
      paymentAmount = Math.abs(Math.min(paymentAmount, balance));
    }
    const payment = {
      amount: paymentAmount,
      key: paymentKey,
      caption: t('tenders.regularGiftCard'),
      type: 'GIFTCARD',
      vatrateID: state.regularGiftCards[state.selectedCard].vatRateID,
      cardHolder: state.giftCardNotes || null,
    };
    return dispatch(setPayment(payment)).then(() =>
      dispatch(setPaymentSelected('')),
    );
  };

  // If at the moment of gift card menu opening redux still does not have gift cards, but gets populated with them later, populate the state of the component
  useEffect(() => {
    if (!state.regularGiftCards && regularGiftCards) {
      setState(prevState => ({
        ...prevState,
        regularGiftCards,
      }));
    }
  }, [regularGiftCards, state.regularGiftCards]);

  const goBack = () => {
    dispatch(setPaymentSelected(''));
  };

  const isDisabled =
    isGiftcardDisabled ||
    !state.regularGiftCards?.[state.selectedCard] ||
    !Number(state.regularCardAmount);

  const paymentAmountErrorText = useMemo(() => {
    const paymentAmountExceedsCardBalance =
      Number(selectedSerialGiftCardAmount) >
      Number(selectedSerialGiftCard?.balance);
    const paymentAmountExceedsBalance =
      Number(selectedSerialGiftCardAmount) > Math.abs(Number(balance));

    const errorT = key =>
      t(`serialGiftcard.fields.paymentAmount.errors.${key}`);

    if (!selectedSerialGiftCardAmount.trim()) {
      return errorT('empty');
    }
    if (Number(selectedSerialGiftCardAmount) < 0) {
      return errorT('negative');
    }
    if (paymentAmountExceedsCardBalance) {
      return errorT('exceedsGiftCardBalance');
    }
    if (paymentAmountExceedsBalance) {
      return errorT('exceedsBalance');
    }
    return '';
  }, [balance, selectedSerialGiftCard, selectedSerialGiftCardAmount, t]);

  const formatCurrency = useSelector(getCurrencyFormatter);
  return (
    <PluginComponent hookname="UIGiftCard" props={{ typeID }}>
      <Form data-testid="giftcard-form" className="form-giftCard">
        <Form.Group style={{ display: showSerialField ? undefined : 'none' }}>
          <span>
            {t('serialGiftcard.title', {
              context: type ? 'type' : undefined,
              type,
            })}
          </span>
          <form
            onSubmit={e => {
              e.stopPropagation();
              e.preventDefault();
              searchSerialGiftCard();
            }}
          >
            <InputGroup size="lg">
              <Form.Control
                autoFocus
                disabled={isSerialGiftcardDisabled}
                value={state.serialCardNumber}
                data-testid="serial-number"
                name="serialCardNumber"
                placeholder={
                  type ??
                  t('serialGiftcard.fields.code', {
                    context: 'placeholder',
                  })
                }
                onChange={handleChange}
              />

              <InputGroup.Append data-testid="search-giftcard">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    isSerialGiftcardDisabled || isSearchingForSerialGiftCard
                  }
                  className={`flip-horizontal ${
                    isSerialGiftcardDisabled || isSearchingForSerialGiftCard
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <span className="icon_search" />
                </Button>
              </InputGroup.Append>

              <InputGroup.Append data-testid="go-back" disabled>
                <InputGroup.Text disabled onClick={goBack}>
                  <span className="icon_close" />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </form>
          {selectedSerialGiftCard ? (
            <Box>
              <Typography paragraph>
                {t('serialGiftcard.info', {
                  balance: selectedSerialGiftCard.balance,
                })}
              </Typography>
              <form
                onSubmit={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  addSerialGiftCard();
                }}
              >
                <TextField
                  type="number"
                  fullWidth
                  variant="outlined"
                  label={t('serialGiftcard.amount')}
                  value={selectedSerialGiftCardAmount}
                  onChange={e =>
                    setSelectedSerialGiftCardAmount(e.target.value)
                  }
                  error={!!paymentAmountErrorText}
                  helperText={paymentAmountErrorText}
                  inputProps={{ step: '0.01' }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MuiButton
                          type="submit"
                          variant="contained"
                          color="secondary"
                          disableElevation
                          style={{
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            marginRight: '-14px',
                            zIndex: 2,
                          }}
                          disabled={
                            !!paymentAmountErrorText || isAddingSerialGiftCard
                          }
                        >
                          {t('serialGiftcard.addAmount')}
                        </MuiButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Box>
          ) : null}
        </Form.Group>
        <Form.Group style={{ display: showRegularField ? undefined : 'none' }}>
          <div className="d-flex justify-content-between">
            <span>{t('regularGiftcard.title')}</span>
            <span data-testid="balance">
              {t('regularGiftcard.balance', {
                balance: formatCurrency(balance),
              })}
            </span>
          </div>

          <div className="cards-regular">
            <div>
              <InputField
                disabled={isGiftcardDisabled}
                data-testid="select"
                size="lg"
                name="regularCardSelect"
                type="select"
                options={state.regularGiftCards}
                onChange={handleChange}
                value={state.selectedCard}
              />
            </div>
            <div>
              <InputGroup size="lg">
                <Form.Control
                  disabled={isGiftcardDisabled}
                  value={state.regularCardAmount}
                  data-testid="amount"
                  name="regularCardAmount"
                  placeholder={t('regularGiftcard.fields.amount', {
                    context: 'placeholder',
                  })}
                  onChange={handleChange}
                />
                <InputGroup.Append>
                  <Button
                    disabled={isDisabled}
                    variant="primary"
                    className={isDisabled ? 'disabled' : undefined}
                    onClick={setRegularGiftCard}
                    data-testid="check"
                  >
                    <span className="icon_check" />
                  </Button>
                </InputGroup.Append>
                <InputGroup.Append data-testid="close">
                  <InputGroup.Text onClick={goBack}>
                    <span className="icon_close" />
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </div>

          <InputGroup size="lg">
            <Form.Control
              as="textarea"
              data-testid="notes"
              disabled={isGiftcardDisabled}
              value={state.giftCardNotes}
              rows="3"
              name="giftCardNotes"
              placeholder={t('regularGiftcard.fields.notes', {
                context: 'placeholder',
              })}
              onChange={handleChange}
            />
          </InputGroup>
        </Form.Group>
      </Form>
    </PluginComponent>
  );
};

export default GiftCard;
