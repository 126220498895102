/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { addError } from 'actions/Error';
import { CardPaymentHooks, FUNC_BUTTONS } from 'paymentIntegrations/types';
import { sleep } from 'utils';
import { deletePayment } from 'actions/Payments';
import { RootState } from 'reducers';

import { load, redemption, undo } from '../API/viiAPI';
import {
  addViiPaymentData,
  getViiPayments,
  removeViiPaymentDataById,
} from '../redux/actions';
import { noResponse } from '../constants';

export const processPayments = (params: CardPaymentHooks) => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const { cardPayments, enableButtons, updateMessage, beforeDocSave } = params;
  const reduxViiPayments = getViiPayments(getState());
  const shouldVoid = cardPayments.every(cp => cp.paid && cp.shouldProcess);
  return cardPayments
    .filter(p => (shouldVoid ? p.paid : !p.paid))
    .reduce((prevPayment, currentPayment) => {
      return prevPayment.then(async ({ data, errors }) => {
        if (!errors?.length) {
          try {
            const transType =
              Number(currentPayment.amount) < 0 && !currentPayment.paid
                ? 'REFUND'
                : currentPayment.paid && currentPayment.shouldProcess
                ? 'VOID'
                : 'SALE';
            const { key } = currentPayment;

            const card = reduxViiPayments[currentPayment.key];
            // SALE section
            if (transType === 'SALE' && card.PIN) {
              await dispatch(
                redemption({
                  ...card,
                  PIN: card.PIN,
                  Amount: Number(currentPayment.amount).toFixed(2),
                }),
              ).then(async res => {
                if (res && Number(res?.ResponseCode[0]) === 0) {
                  data.push(res);
                  const additionalProps = {};
                  if (res.AuthCode?.[0]) {
                    Object.assign(additionalProps, {
                      AuthCode: res.AuthCode[0],
                    });
                  }
                  await beforeDocSave({
                    ...currentPayment,
                    paid: true,
                    type: 'CARD',
                    cardNumber: res.CardNumber[0].slice(-4),
                    cardType: 'ViiGiftCards',
                    attributes: {
                      refNo: res.ExternalReference[0],
                    },
                  });
                  dispatch(
                    addViiPaymentData(currentPayment.key, {
                      CardNumber: res.CardNumber[0],
                      Amount: res.Amount[0],
                      ExternalReference: res.ExternalReference[0],
                      viiReceiptNumber: res.viiReceiptNumber[0],
                      IssuanceDate: res.IssuanceDate[0],
                      ExpiryDate: res.ExpiryDate[0],
                      SettlementDate: res.SettlementDate[0],
                      viiUserName: res.viiUserName[0],
                      viiTranId: res.viiTranId[0],
                      ...additionalProps, // PRE-AUTH CODE
                    }),
                  );
                } else {
                  updateMessage(res?.ResponseMessage[0] ?? noResponse);
                  dispatch(addError(res?.ResponseMessage[0] ?? noResponse));
                  errors.push(res?.ResponseMessage[0] ?? noResponse);
                  enableButtons([FUNC_BUTTONS.CLOSE]);
                }
              });
            }
            // VOID section
            else if (transType === 'VOID') {
              await dispatch(
                undo({
                  ...card,
                  viiTranIdToUndo: card.viiTranId,
                }),
              ).then(async res => {
                if (res && Number(res.ResponseCode?.[0]) === 0) {
                  data.push(res);
                  dispatch(deletePayment({ key }));
                  // When voiding, there is no need to store info to vii redux, but rather should remove the entry
                  dispatch(removeViiPaymentDataById(currentPayment.key));
                } else {
                  updateMessage(res?.ResponseMessage[0] ?? noResponse);
                  dispatch(addError(res?.ResponseMessage[0] ?? noResponse));
                  errors.push(res?.ResponseMessage[0] ?? noResponse);
                  enableButtons([FUNC_BUTTONS.CLOSE]);
                }
              });
            }
            // REFUND section
            else {
              await dispatch(
                load({
                  ...card,
                  Amount: Math.abs(Number(currentPayment.amount)).toFixed(2),
                }),
              ).then(async res => {
                if (res && Number(res.ResponseCode?.[0]) === 0) {
                  data.push(res);
                  const additionalProps = {};
                  if (res.AuthCode?.[0]) {
                    Object.assign(additionalProps, {
                      AuthCode: res.AuthCode[0],
                    });
                  }
                  await beforeDocSave({
                    ...currentPayment,
                    paid: true,
                    type: 'CARD',
                    cardNumber: res.CardNumber[0].slice(-4),
                    cardType: 'ViiGiftCards',
                    attributes: {
                      refNo: res.ExternalReference[0],
                    },
                  });
                  dispatch(
                    addViiPaymentData(currentPayment.key, {
                      CardNumber: res.CardNumber[0],
                      Amount: res.Amount[0],
                      ExternalReference: res.ExternalReference[0],
                      viiReceiptNumber: res.viiReceiptNumber[0],
                      IssuanceDate: res.IssuanceDate[0],
                      ExpiryDate: res.ExpiryDate[0],
                      SettlementDate: res.SettlementDate[0],
                      viiUserName: res.viiUserName[0],
                      viiTranId: res.viiTranId[0],
                      ...additionalProps, // AUTH CODE
                    }),
                  );
                } else {
                  updateMessage(res?.ResponseMessage[0] ?? noResponse);
                  dispatch(addError(res?.ResponseMessage[0] ?? noResponse));
                  errors.push(res?.ResponseMessage[0] ?? noResponse);
                  enableButtons([FUNC_BUTTONS.CLOSE]);
                }
              });
            }
          } catch (error) {
            updateMessage('Payment processing failed');
            console.error(error);
          } finally {
            await sleep(1);
          }
        }

        return { data, errors };
      });
    }, Promise.resolve({ data: [], errors: [] }));
};
