import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { getUISetting } from 'reducers/configs/settings';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import { useSearchStyles } from './index.styles';
import CustomersSearch from './Customers';
import ProductsSearch from './Products';

const customerContainerID = 'customer-search-container';
const productContainerID = 'product-search-container';

type ContainerID = typeof customerContainerID | typeof productContainerID;

const productSearchShortcut = 'F9';
const customerSearchShortcut = 'F10';

const Search: FC = () => {
  const [activeInputType, setActiveInputType] = useState<ContainerID | null>(
    null,
  );

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setActiveInputType(event.currentTarget.id as ContainerID);
  }, []);

  const handleClose = useCallback(() => setActiveInputType(null), []);

  const hideCustomerSearch = useSelector(getUISetting('hideCustomerSearch'));

  const isOffline = !useSelector(getConnectionHealth);

  const classes = useSearchStyles({ showPopover: Boolean(activeInputType) });

  const getCurrentStyle = useCallback(
    (type: ContainerID) => {
      if (type === customerContainerID && (hideCustomerSearch || isOffline))
        return classes.passiveItem;
      if (!activeInputType) return classes.item;
      if (activeInputType === type) return classes.activeItem;
      return classes.passiveItem;
    },
    [customerContainerID, hideCustomerSearch, isOffline, activeInputType],
  );

  const isProductsActive = activeInputType === productContainerID;
  const isCustomersActive = activeInputType === customerContainerID;

  const productRef = useRef<HTMLInputElement | null>(null);
  const customerRef = useRef<HTMLInputElement | null>(null);
  useShortcut(productSearchShortcut, () => {
    setActiveInputType(productContainerID);
    productRef.current?.focus();
  });
  useShortcut(customerSearchShortcut, () => {
    setActiveInputType(customerContainerID);
    customerRef.current?.focus();
  });

  const customerContainerStyle = useMemo(
    () => getCurrentStyle(customerContainerID),
    [customerContainerID, getCurrentStyle],
  );
  const productContainerStyle = useMemo(
    () => getCurrentStyle(productContainerID),
    [productContainerID, getCurrentStyle],
  );

  return (
    <>
      <Grid
        container
        id="super-container"
        className={classes.container}
        spacing={2}
      >
        <Grid
          item
          id={customerContainerID}
          data-testid="customer-search-container"
          className={customerContainerStyle}
          onClick={handleClick}
        >
          <CustomersSearch
            ref={customerRef}
            closePopover={handleClose}
            active={isCustomersActive}
            focusKey={customerSearchShortcut}
          />
        </Grid>
        <Grid
          item
          id={productContainerID}
          className={productContainerStyle}
          onClick={handleClick}
        >
          <ProductsSearch
            ref={productRef}
            closePopover={handleClose}
            active={isProductsActive}
            focusKey={productSearchShortcut}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Search;
