import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { getModalPages } from 'reducers/modalPage';
import {
  getAccount,
  getClientCode,
  getCompany,
  getUserLoggedIn,
  getUsername,
} from 'reducers/Login';

const RATE_LIMIT = 5000;
const rateLimit = {};
const isRateLimited = error => {
  if (rateLimit[error]) return true;

  rateLimit[error] = true;
  setTimeout(() => delete rateLimit[error], RATE_LIMIT);
  return false;
};

const BLACKLIST = [/TypeError: Failed to fetch/];

if (
  process.env.NODE_ENV === 'production' ||
  sessionStorage.getItem('enable-sentry') ||
  sessionStorage.getItem('debug-sentry')
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    maxBreadcrumbs: 300,
    attachStacktrace: true,
    integrations: ints =>
      ints.filter(int => !['GlobalHandlers', 'TryCatch'].includes(int.name)),
    beforeBreadcrumb: (breadcrumb, hint?) => {
      // region Include request data in breadcrumb
      if (breadcrumb.category === 'fetch') {
        // Because fetch is async, skip this breadcrumb and (re-)add it when it has been read with .json()
        const response = hint?.response as Response;
        const original = response.json;
        response.json = (...params: Parameters<typeof original>) =>
          original.apply(response, params).then(res => {
            Sentry.addBreadcrumb({
              ...breadcrumb,
              category: 'fetchParsed',
              data: {
                ...breadcrumb.data,
                response: res,
              },
            });
            return res;
          });
        return null;
      }
      if (breadcrumb.category === 'xhr') {
        // XHR is easier, just parse the already included response
        const { response } = hint?.xhr as XMLHttpRequest;
        try {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data = {
            ...breadcrumb.data,
            response: JSON.parse(response),
          };
        } catch (e) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data = {
            ...breadcrumb.data,
            response,
          };
        }
      }
      // endregion
      return breadcrumb;
    },
    beforeSend: async (event, hint?) => {
      if (sessionStorage.getItem('debug-sentry')) {
        // Debug-only log, therefore okay to use console
        // eslint-disable-next-line no-console
        console.log('Did not send event', event);
        return null;
      }
      // @ts-ignore
      const errMsg = hint?.originalException?.message;
      if (BLACKLIST.some(t => t.test(errMsg))) return null;
      if (isRateLimited(errMsg)) {
        console.warn(
          'Rate limiting sentry report for error:',
          hint?.originalException,
        );
        return null;
      }
      return event;
    },
  });
}

const Recovery = t => ({ error, componentStack, resetError }) => (
  <div
    style={{
      display: 'flex',
      position: 'fixed',
      flexDirection: 'column',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
    }}
  >
    <h3 style={{ color: 'initial' }}>{t('errorBoundary.fallback.title')}</h3>
  </div>
);

const useSentryUser = () => {
  const clientCode = useSelector(getClientCode);
  const userName = useSelector(getUsername);
  const company = useSelector(getCompany);
  const user = useSelector(getUserLoggedIn);
  Sentry.setUser({
    id: `${clientCode} ${user?.userID}`,
    username: `${clientCode} (${userName})`,
    email: company?.email,
  });
};
const useSentryContext = (name, selector) => {
  const value: any = useSelector(selector);
  useEffect(() => Sentry.setContext(name, value), [name, value]);
};
const useSentryExtraData = () => {
  useSentryContext('account', getAccount);
  useSentryContext('modalPages', getModalPages);
  // useSentryContext('databases', getCachedProductsLength);
  useSentryContext('wrapper', () => window.wrapperInfo);
  useSentryUser();
};

export const SentryErrorBoundary = ({ children }) => {
  const { t } = useTranslation('Sentry');

  const title = t('errorBoundary.dialog.title');
  const subtitle = t('errorBoundary.dialog.subtitle');
  const subtitle2 = t('errorBoundary.dialog.subtitle2');

  const dialogOptions = useMemo(
    () => ({
      title,
      subtitle,
      subtitle2,
    }),
    [title, subtitle, subtitle2],
  );

  const fallback = useMemo(() => Recovery(t), [t]);

  useSentryExtraData();
  return (
    <SentryReact.ErrorBoundary
      showDialog={true}
      dialogOptions={dialogOptions}
      fallback={fallback}
    >
      {children}
    </SentryReact.ErrorBoundary>
  );
};
