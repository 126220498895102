import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { applyPromotion } from 'actions/ShoppingCart';
import InputField from 'components/FieldTypes/InputField';
import { integer, positive } from 'components/FieldTypes/formatters';

export default ({ promotion }) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');
  const onClose = () => dispatch(previousModalPage());
  const save = () => {
    try {
      const amt = parseInt(amount, 10);
      dispatch(applyPromotion({ ...promotion, amount: amt }));
      dispatch(previousModalPage());
    } catch (err) {
      // Ignore errors and do nothing
    }
  };
  const invalidAmount = amount === '';
  return (
    <>
      <Modal.Header>
        <div style={{ fontSize: 24, fontWeight: 'bold' }}>
          Enter campaign amount
        </div>

        <span style={{ flexGrow: 1 }} />
        <SaveButton action={save} title="Save" disabled={invalidAmount} />
        <CloseButton action={onClose} />
      </Modal.Header>

      <Modal.Body>
        <p>Campaign: {promotion.name}</p>
        <InputField
          value={amount}
          size="lg"
          onChange={e => setAmount(e.target.value)}
          formatter={integer.and(positive)}
          placeholder="APPLIED X"
          errorText={invalidAmount && `This field can't be blank`}
        />
      </Modal.Body>
    </>
  );
};
