import { Shift4Api } from '@pos-refacto/shift4-integration';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getCafaEntry } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { semanticVersion } from 'external_data/index';
import { getCustomer, getEmployeeID } from 'reducers/Payments';
import { sleep } from 'utils';
import { printMerchantReceipt } from 'paymentIntegrations/shift4/printMerchantReceipt';
import { getProductsInShoppingCart, getTotalTax } from 'reducers/ShoppingCart';
import { getMSEndpointFromLS } from 'reducers/installer';
import { getSelectedWarehouse } from 'reducers/warehouses';
import { getSelectedPos } from 'reducers/PointsOfSale';

dayjs.extend(utc);
dayjs.extend(timezone);

const getConf = createSelector(
  getCafaEntry('shift4', INTEGRATION_TYPES.payment),
  (e): undefined | {
    endpoint: string;
    terminalId: string;
    accessToken: string;
    timezone: string;
    printMerchantReceipt: boolean;
  } => e?.value,
);

const getEndpoint = createSelector(
  getConf,
  () => getMSEndpointFromLS('shift4'),
  (conf, proxy) => `${proxy}?redirect=${conf?.endpoint}`,
);

const getTerminalId = createSelector(getConf, conf => conf?.terminalId);
const getAccessToken = createSelector(getConf, conf => conf?.accessToken);
const getTimezone = createSelector(getConf, conf => conf?.timezone);
const getPrintMerchantReceiptEnabled = createSelector(
  getConf,
  conf => conf?.printMerchantReceipt,
);

const getApi = createSelector(
  getEndpoint,
  getAccessToken,
  (endpoint, accessToken) => {
    if (!accessToken) return null;
    if (!endpoint) return null;
    return new Shift4Api(
      endpoint,
      { version: semanticVersion, name: 'Brazil POS' },
      'Erply',
      accessToken,
    );
  },
);

export const doShift4Transaction = (
  amount: number,
  setMessage: (msg: string) => void,
  getOption: () => Promise<'cancel' | 'checkStatus'>,
  refund: boolean,
) => async (dispatch, getState) => {
  const state = getState();
  const api = getApi(state);
  if (!api) throw new Error('Shift4 not configured');
  const enableMerchantReceiptPrint = getPrintMerchantReceiptEnabled(state);
  const pos = getSelectedPos(state);
  const s4InvoiceNumber = await api.generateInvoiceNumber(
    String(pos.pointOfSaleID),
    false,
  );
  const customer = getCustomer(state);
  const shoppingCart = getProductsInShoppingCart(state);
  const tax = getTotalTax(state);
  const warehouse = getSelectedWarehouse(state);
  const timezone = getTimezone(state);
  const transactionRequest: Parameters<
    typeof api.saleRefund & typeof api.salePurchase
  >[0] = {
    dateTime: dayjs()
      .tz(timezone ?? dayjs.tz.guess())
      .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    amount: {
      total: Number(amount),
      tax: Number(tax),
    },
    clerk: { numericId: Number(getEmployeeID(state)) },
    device: { terminalId: getTerminalId(state) ?? '' },
    apiOptions: ['ALLOWPARTIALAUTH'],
    transaction: {
      invoice: String(s4InvoiceNumber),
      purchaseCard: {
        customerReference: customer.customerID || '-',
        destinationPostalCode: warehouse.ZIPcode || '-',
        productDescriptors: shoppingCart
          .map(i => `${i.amount}x ${i.name}`)
          .map(i => (i.length <= 40 ? i : `${i.slice(0, 39)}…`)),
      },
    },
    card: {
      present: 'Y',
    },
  };
  if (refund) {
    // purchaseCard not wanted
    delete transactionRequest.transaction.purchaseCard;
    // Partial auth not supported
    transactionRequest.apiOptions = [];
    transactionRequest.amount.total = -transactionRequest.amount.total;
    transactionRequest.amount.tax = -transactionRequest.amount.tax;
  }
  const res = await (refund
    ? api.saleRefund(transactionRequest)
    : api.salePurchase(transactionRequest)
  )
    .catch(async e => {
      setMessage(e.message);
      await sleep(5);
      while (true) {
        setMessage('Checking status...');
        try {
          const res = await api?.invoiceInformation(s4InvoiceNumber);
          if (res.data.result[0].amount) return res;
          throw e;
        } catch (_) {
          setMessage(`${e.message}\nPlease check the terminal.`);
          // User confirms failure
          if ((await getOption()) === 'cancel') throw e;
          // User wants to try again
          setMessage('Please wait...');
          await sleep(5);
        }
      }
    })
    .then(res => {
      switch (res.data?.result?.[0]?.transaction?.responseCode) {
        case 'D':
          throw new Error('Transaction DECLINED');
        case 'X':
          throw new Error('Card is expired');
        case 'e':
        case 'f':
        case 'R':
          console.error('Shift4 transaction error', res.data);
          throw new Error('Shift4 transaction error');
        case 'A':
        case 'C':
        case 'P':
          return res;
        default:
          throw new Error('Shift4 transaction state is unknown');
        // pass
      }
    });
  const {
    data: {
      result: [pmt],
    },
  } = res;
  if (enableMerchantReceiptPrint && pmt.receipt?.length) {
    dispatch(printMerchantReceipt(pmt.receipt));
  }
  return {
    amount: refund ? -pmt.amount.total : pmt.amount.total,
    cardType: pmt.card.type,
    cardHolder: pmt.customer.firstName,
    cardNumber: pmt.card.number,
    attributes: {
      authCode: (pmt as any)?.transaction?.authorizationCode,
      universalToken: (pmt as any)?.universalToken?.value,
      transactionToken: (pmt as any)?.card?.token?.value,
      paymentType: 'DEBIT',
      refNo: s4InvoiceNumber,
      cardNumber: pmt.card.number,
    },
  };
};

export const doShift4Refund = (
  amount: number,
  setMessage: (msg: string) => void,
  getOption: () => Promise<'cancel' | 'checkStatus'>,
) => doShift4Transaction(amount, setMessage, getOption, true);

export const doShift4Payment = (
  amount: number,
  setMessage: (msg: string) => void,
  getOption: () => Promise<'cancel' | 'checkStatus'>,
) => doShift4Transaction(amount, setMessage, getOption, false);

export const doShift4Void = (invoiceNumber: string) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const api = getApi(state);
  if (!api) throw new Error('Shift4 not configured');
  const enableMerchantReceiptPrint = getPrintMerchantReceiptEnabled(state);
  try {
    const pmt = await api.void(invoiceNumber);
    if (enableMerchantReceiptPrint && pmt.data?.result?.[0]?.receipt?.length) {
      dispatch(printMerchantReceipt(pmt.data?.result?.[0]?.receipt));
    }
    return null;
  } catch (e) {
    return api.invoiceInformation(invoiceNumber).then(
      () => {
        throw new Error('Failed to void');
      },
      () => {
        console.warn(
          `Cannot print merchant receipt for void because the initial void request failed with error: ${e}`,
        );
      },
    );
  }
};
