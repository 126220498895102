import axios from 'axios';

export const createFiscalReceipt = (
  payload: Record<string, any> = {},
  port: string | number,
): Promise<Record<string, any>> => {
  return axios({
    baseURL: `https://localhost.erply.com:${port || 10121}/api/v1/fiscal`,
    url: '/receipt',
    method: 'post',
    data: payload,
  })
    .then(response => {
      return { success: true, response: response.data.records };
    })
    .catch(error => {
      if (error.response) {
        return { success: false, message: error.response.data.errorMessage };
      }

      return {
        success: false,
        message: 'Error al momento de crear la factura fiscal',
      };
    });
};
