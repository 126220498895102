import { getProductByID } from 'reducers/cachedItems/products';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { addError, addSuccess } from 'actions/Error';
import { modalPages as mp } from 'constants/modalPage';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getProductInOrderByIndex } from 'reducers/ShoppingCart';
import {
  removeProduct,
  updateOrderAmount,
  updateProductContainerOverride,
} from 'actions/ShoppingCart';

import {
  ENTER_RETURN_TAG,
  REMOVE_RETURN_TAG,
  REPLACE_RETURN_TAG,
  RESET_ALL_TAGS,
} from './types';
import { getTagsByProductId } from './selectors';

export const addReturnTag = (id: number | string, tag: string) => ({
  type: ENTER_RETURN_TAG,
  payload: {
    id,
    tag,
  },
});

export const removeTagsByProductId = (id: string | number) => ({
  type: REMOVE_RETURN_TAG,
  payload: {
    id,
  },
});
export const replaceTagsByProductId = (
  id: string | number,
  tags: string[],
) => ({
  type: REPLACE_RETURN_TAG,
  payload: {
    id,
    tags,
  },
});

export const removeAllTags = () => ({
  type: RESET_ALL_TAGS,
});

type getTagProps = {
  dispatch: any;
  productName: string;
  current: number;
  total: number;
  tag?: string;
};

/**
 * Opens a popup for the return tag to be entered
 */
export const getTag = ({
  productName,
  total,
  current,
  dispatch,
}: getTagProps): Promise<string> =>
  new Promise((resolve, reject) => {
    dispatch(
      openModalPage({
        component: mp.pluginModal,
        props: {
          name: 'EnterTagModal',
          resolve,
          reject,
          productName,
          current,
          total,
        },
        isPopup: true,
      }),
    );
  });

type updateTagsProps = {
  product: any;
  tags: string[] | [];
  dispatch: any;
};

/**
 * Opens a popup for removing extra tags, when the product amount has been decreased
 */
export const updateTags = ({
  product,
  tags,
  dispatch,
}: updateTagsProps): Promise<string[]> =>
  new Promise((resolve, reject) => {
    dispatch(
      openModalPage({
        component: mp.pluginModal,
        props: {
          name: 'RemoveTagModal',
          resolve,
          reject,
          dispatch,
          productName: product.name,
          productAmount: product.amount * -1,
          tags,
        },
        isPopup: true,
      }),
    );
  });

/**
 * The function iterates over the array of products and gets a tags for each of them
 */
export const getTagsForMultipleProducts: (
  products: Record<string, any>[],
  onCancel: (number) => void,
) => any = (products, onCancel) => async (dispatch, getState) => {
  const { envFeeName }: any =
    getPluginConfiguration('environmental-&-CA-fees')(getState()) ?? {};
  // eslint-disable-next-line no-restricted-syntax
  for (const product of products) {
    // region Don't ask for tag for Environmental Fee
    if (envFeeName === product.name) {
      return;
    }
    // endregion

    const productCard = getProductByID(product.productID)(getState());
    if (product.amount < 0) {
      if (product.returnTags?.length) {
        dispatch(
          replaceTagsByProductId(product.orderIndex, product.returnTags),
        );
      } else {
        try {
          for (let i = 0; i < Number(product?.amount) * -1; i++) {
            // eslint-disable-next-line no-await-in-loop
            await getTag({
              productName: productCard.name,
              total: Number(product?.amount) * -1,
              current: i + 1,
              dispatch,
            })
              .then((tag: string) => {
                dispatch(addReturnTag(product.orderIndex, tag));
                dispatch(previousModalPage());
                dispatch(addSuccess('Return tag was added successfully.'));
              })
              .catch(() => {
                dispatch(previousModalPage());
                throw new Error(
                  `Tag input cancelled for product${
                    product.id
                  }, tag number ${i + 1}`,
                );
              });
          }
        } catch (e) {
          onCancel(product.orderIndex);
        }
      }
    }
  }
};

export const updateOrderReturnTags = ({
  orderIndex,
  amount,
}: {
  orderIndex: number | string;
  amount: number | string;
}) => async (dispatch, getState) => {
  const state = getState();
  const product = getProductInOrderByIndex(orderIndex)(state);
  if (!product) return;
  const updatedProductTags = getTagsByProductId(product.orderIndex)(state);
  const numberOfTags = updatedProductTags?.length ?? 0;

  // prevent automatically adding container to returned products
  if (Number(amount) < 0) {
    dispatch(updateProductContainerOverride(orderIndex, false));
  }

  const removeProductOnCancel: (
    orderIndex: number | string,
  ) => void = orderIndex => {
    dispatch(removeProduct(orderIndex, { showWarning: false }));
    dispatch(
      addError(
        'Adding return tag failed. Product was removed from shopping cart.',
      ),
    );
  };

  const updateProductAmountOnCancel: (
    orderIndex: number | string,
  ) => void = orderIndex => {
    const updatedNumberOfTags = getTagsByProductId(orderIndex)(getState());
    if (updatedNumberOfTags?.length) {
      dispatch(updateOrderAmount(updatedNumberOfTags.length * -1, orderIndex));
      dispatch(
        addError(
          `Operation failed. Product amount was restored to ${updatedNumberOfTags.length *
            -1}.`,
        ),
      );
    } else {
      removeProductOnCancel(orderIndex);
    }
  };

  if (numberOfTags) {
    if (Number(amount) >= 0) {
      dispatch(removeTagsByProductId(orderIndex));
    } else if (Number(amount) < 0) {
      const realAmount = Number(amount) * -1;
      if (realAmount > numberOfTags) {
        dispatch(
          getTagsForMultipleProducts(
            [{ ...product, orderIndex, amount: Number(amount) + numberOfTags }],
            updateProductAmountOnCancel,
          ),
        );
      }
      if (realAmount < numberOfTags) {
        updateTags({ product, tags: updatedProductTags, dispatch })
          .then(tags => {
            dispatch(replaceTagsByProductId(product.orderIndex, tags));
            dispatch(addSuccess('Return tags were updated successfully'));
          })
          .catch(() => updateProductAmountOnCancel(product.orderIndex));
      }
    }
  } else {
    await dispatch(
      getTagsForMultipleProducts(
        [{ ...product, orderIndex, amount }],
        removeProductOnCancel,
      ),
    );
  }
};
