import React, { ChangeEvent, FC } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Field } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';
import { getSetting } from 'reducers/configs/settings';

interface Props {
  customer: { language: string };
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const LanguageField: FC<Props> = ({ customer, handleChange }) => {
  const { t } = useTranslation('createCustomer');
  const defaultLanguage = useSelector(getSetting('pos_default_language'));

  const defaultValue = ['en', 'fr'].includes(defaultLanguage.isoCode)
    ? defaultLanguage.isoCode
    : 'en';

  return (
    <Field name="language" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
      <TextField
        select
        name="language"
        variant="outlined"
        label={t('fields.language')}
        fullWidth
        value={customer.language ?? defaultValue}
        onChange={handleChange}
      >
        <MenuItem value="en">{t('languages.english')}</MenuItem>
        <MenuItem value="fr">{t('languages.french')}</MenuItem>
      </TextField>
    </Field>
  );
};

export default LanguageField;
