import { Card, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import InputFieldBase from 'components/FieldTypes/InputField';
import { loginInput } from 'components/FieldTypes/skins/skins.module.scss';
import { hardLogout, softLogout } from 'actions/Login';
import { changePassword } from 'services/ErplyAPI';

import { styles } from '../util';

const InputField = props => (
  <InputFieldBase size="lg" className={loginInput} {...props} />
);

const getErrorForPassword = password => {
  if (password.length === 0) {
    return i18next.t('validation:generic.required');
  }
  if (password.length < 8) {
    return i18next.t('validation:generic.minLength', { min: 8 });
  }
  return false;
};
const PasswordExpired = () => {
  const [old, setOld] = useState('');
  const [new1, setNew1] = useState('');
  const [new2, setNew2] = useState('');
  const [errorOverride, setErrorOverride] = useState(false);
  const { t } = useTranslation('changePassword');
  const error1 = getErrorForPassword(old);
  const error2 = getErrorForPassword(new1);
  const error3 =
    getErrorForPassword(new2) ||
    (new1 !== new2 && t('validation:changePassword.noMatch'));

  const dispatch = useDispatch();
  const submit = () =>
    changePassword({ oldPassword: old, newPassword: new1 })
      .then(() => dispatch(hardLogout()))
      .then(() => localStorage.removeItem('mockPasswordExpired'))
      .catch(e => setErrorOverride(e.message));

  useShortcut('Enter', submit);
  useEffect(() => setErrorOverride(false), [old, new1, new2]);
  return (
    <Card style={styles.card}>
      <Card.Header style={styles.header}>
        <div style={{ margin: 8 }}>{t('title', { context: 'expired' })}</div>
      </Card.Header>
      <Card.Body className="p-sm-4 p-0 pb-4">
        <InputField
          type="password"
          title={t('inputs.current')}
          onChange={e => setOld(e.target.value)}
          errorText={error1}
        />

        <InputField
          type="password"
          title={t('inputs.new1')}
          errorText={error2}
          onChange={e => setNew1(e.target.value)}
        />
        <InputField
          type="password"
          title={t('inputs.new2')}
          errorText={error3}
          onChange={e => setNew2(e.target.value)}
        />
        {errorOverride ? (
          <div style={{ color: 'red', minHeight: '1em' }}>{errorOverride}</div>
        ) : null}
        <Button
          style={{ flexGrow: 1, ...styles.button }}
          size="lg"
          variant="primary"
          onClick={submit}
          disabled={error1 || error2 || error3 || errorOverride}
        >
          {t('btnChangePassword')}
        </Button>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="https://reg.erply.com/password_reminder/">
            {t('forgotPassword')}
          </a>
        </div>
        <Button
          style={{ flexGrow: 1, ...styles.button }}
          size="lg"
          variant="secondary"
          onClick={() => dispatch(softLogout())}
        >
          {t('btnLogout')}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default PasswordExpired;
