/* eslint-disable @typescript-eslint/camelcase */
export default {
  addressForm: {
    placeholders: {
      postalCode_Canada: 'Code postal',
      state_Canada: 'Province',
    },
    lookUpAddress: {
      title: "Chercher l'addresse",
      saveButton: 'OK',
      fields: {
        country: 'Pays',
        address: 'Adresse',
      },
      extra: {
        loading: 'Chargement',
        noResults: 'Aucun résultat',
      },
      alerts: {
        failedFind: "Échec de la récupération et de la définition de l'adresse",
        failedRetrieve: 'Impossible de trouver les adresses',
      },
    },
    errors: {
      country: 'Le pays doit être Canada ou les États-Unis',
    },
  },
  createCustomer: {
    titles: {
      updateEmail: 'Mettre à jour le courriel',
    },
    countries: {
      select: 'Choisissez un pays',
      usa: 'Etats-Unis',
      canada: 'Canada',
    },
    errorText: {
      loyaltyCardNumber: {
        invalid: 'Ce champ doit être un numéro valide à 7, 8 ou 9 chiffres',
        checking: 'Vérification...',
        alreadyExists: 'Ce numéro de carte client existe déjà',
        generate_progress: 'Génération...',
        generate: 'Générer',
      },
      birthday: {
        empty: "La date n'est pas valide",
      },
      name: {
        requiredField: 'Ce champ ne peut pas être vide',
      },
      phone: {
        invalidLength: 'Le numéro de téléphone doit comporter 10 caractères',
        invalidFormat:
          'Le numéro de téléphone ne doit contenir que des chiffres et aucun espace',
      },
      mobile: {
        invalidLength: 'Le téléphone mobile doit comporter 10 caractères',
        invalidFormat:
          'Le mobile ne doit contenir que des chiffres et aucun espace',
      },
      failedToSave: "Le client n'a pu être sauvegardé",
      failedToUpdate: "Le client n'a pu être sauvegardé",
      requiredField: 'Ce champ ne peut pas être vide',
    },
    fields: {
      language: 'Langue',
      senior: 'Aîné',
      doNotSell: 'Suspendu',
      salesBlocked: 'Vente uniquement au comptant',
    },
    languages: {
      english: 'Anglais',
      french: 'Français',
    },
    buttons: {
      changeEmail: 'Changer le courriel',
    },
  },
  gridButtons: {
    alerts: {
      giftCardOnPendingSaleNotAllowed:
        "Les bons d'achats ne peuvent pas être enregistrés en cas de vente en attente",
    },
    functions: {
      previousCustomer: 'Client précédent',
      loadCoupons: 'Charger des coupons',
      giftcard: 'Vérifier le solde du bon',
    },
  },
  campaigns: {
    loadCoupons: {
      buttonLabel: 'Charger des coupons',
      title: 'Charger des coupons',
      messages: {
        foundCoupons:
          'Les offres suivantes ont été chargées pour le numéro de client <strong>{{customerCardNumber}}</strong> ({{customerName}})',
        noCoupons:
          "Il n'y a aucune offre pour le numéro de client  <strong>{{customerCardNumber}}</strong> ({{customerName}})",
      },
      failed: {
        title: 'Échec du chargement des coupons',
        body:
          "Le point de vente n'a pas réussi à obtenir des coupons pour le client ({{customerName}}). Réessayez plus tard.",
        reason: 'Raison : {{reason}}',
      },
      coupon: {
        expired: 'Expiré',
        expirationDate: 'Expire le {{date}}',
        salePrice: 'Prix de Vente : ',
        pluList: 'Liste de produits : ',
        redeemed: 'Échangeé dans le magasin {{homeStoreName}} le {{date}}',
        redeemedUnknownLocation: 'Échangeé à un endroit inconnu le {{date}}',
      },
    },
  },
  giftcard: {
    balance: {
      title: "Vérifier le solde du bon d'achat",
    },
    serial: {
      title: "Entrez le numéro de numéro du bon d'achat",
    },
    fields: {
      serial_placeholder: "Numéro de série du bon d'achat/carte de paiement",
    },
    alerts: {
      fetchingSerial: "Recherche du bon d'achat {{serial}}...",
      serialAlreadyAdded:
        "Un bon d'achat avec le numéro de série {{serial}} est déjà ajouté",
      serialAlreadyUsed: "Le bon d'achat {{serial}} est déjà utilisé",
      missingSerial: "Ajouter le numéro de série du bon d'achat",
      serialNotFound: "Le bon d'achat {{serial}} n'a pas été trouvé",
    },
    errors: {
      regexMismatch:
        'Le numéro fourni ({{serial}}) ne correspond pas au modèle configuré : {{pattern}}',
      empty: 'Le numéro de série du bon est vide',
    },
  },
  modalpage: {
    modalpageName_giftCardBalance: "Page du solde du bon d'achat",
    modalpageName_giftCardSerial:
      "Fenêtre contextuelle du numéro de bon d'achat",
  },
  payment: {
    buttons: {
      giftcard_combined: "<0></0><1>Cadeau certifié / Bon d'achat</1>",
      giftcard: '<0></0><1>Cadeau certifié</1>',
      giftCard: "Chèque cadeau / Bon d'achat",
    },
    tenders: {
      regularGiftCard: 'Cadeau certifié',
      serialGiftCard: "Bon d'achat / Carte de paiement",
    },
    alerts: {
      serialGiftcardDisabled: "Bon d'achat désactivé !",
      giftcardAmountRequired: 'Entrez le montant du cadeau certifié !',
      regularGiftcardDisabled: 'Cadeau certifié désactivé !',
      giftcardSerialRequired:
        "Entrez le code du bon d'achat / carte de paiement !",
      fetchingSerialGiftcard:
        "Recherche du Bon d'achat/carte de paiement # {{serial}}...",
      giftcardSerialAlreadyAdded:
        "Le bon d'achat/carte de paiement # {{serial}} est déjà ajouté !",
      giftcardSerialNotFound:
        "Erreur! Aucun bon d'achat/carte de paiement # {{serial}} trouvé !",
      giftcardExpired:
        "Erreur! Le bon d'achat / la carte de paiement est expiré !",
      giftcardInsufficientBalance:
        "Erreur! Le solde du bon d'achat/de la carte de paiement # {{serial}} est de {{balance}} !",
      giftcardMinimumSpend:
        "Le bon d'achat/carte de paiement # {{serial}} ne peut être utilisé que si le total du panier est supérieur à {{minimumSpend}} !",
      giftcardUnnecessary:
        "Pas besoin d'utiliser un bon d'achat/carte de paiement !",
      removeGiftcard_title: "Supprimer le bon d'achat/la carte de paiement",
      giftcardWrongType: 'Ajout du bouton {{toType}} à partir de {{fromType}}',
      removeGiftcardSerial_body:
        'Confirmer la suppression du bon/carte de paiement # <0>{{serial}}</0>',
      removeGiftcardRegular_body:
        'Confirmez le retrait du cadeau certifié avec le montant : <0>{{amount}}</0>',
      giftCardRequired: 'Un cadeau certifié est obligatoire',
      failedToFetchGiftCard:
        "Échec de la récupération du bon d'achat/de la carte de paiement # {{serial}}",
      notVoucherOwner:
        "Impossible d'utiliser le bon d'achat. Le bon d'achat n'appartient pas au client sélectionné.",
    },
    serialGiftcard: {
      title: "Bon d'achat / Carte de paiement",
      title_type: '{{type}}',
      info:
        "Solde du bon d'achat choisi : {{balance}}. Veuillez saisir le montant que vous souhaitez utiliser dans le champ ci-dessous",
      fields: {
        code_placeholder: "Code du bon d'achat",
        paymentAmount: {
          errors: {
            exceedsGiftCardBalance:
              "Ne peut pas être supérieur au solde du bon d'achat",
          },
        },
      },
    },
    regularGiftcard: {
      title: 'Cadeau certifié',
      fields: {
        amount_placeholder: 'Montant du cadeau certifié',
      },
    },
  },
  settingsConfiguration: {
    section: {
      giftcard: "Bon d'achat",
    },
    posUseGiftcardsWithSerialNumbers: "Autoriser les bons d'achat",
    posDisableGenerateNumberButton:
      "Désactiver le bouton Générer un numéro lors de la vente d'un bon d'achat",
    posUseSerialGiftCardPaymentAmountInput:
      "Utiliser la saisie du montant du paiement par bon d'achat",
    posSerialGiftcardPattern:
      "Modèle de numéro de série du bon d'achat (Regular Expression)",
    posSerialGiftcardPattern_regex:
      "Un bon d'achat portant le numéro « POS-vs4A-acWjAb-6b6X » peut être généré aléatoirement avec le modèle suivant : POS-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{6} -[a-zA-Z0-9]{4}\\nLes caractères individuels sont toujours les mêmes, dans ce cas les premières lettres 'POS' et tous les tirets ('-'). Le [a-zA-Z0-9] permet la génération de n'importe quel caractère spécifié à l'intérieur des parenthèses, dans ce cas les caractères minuscules et majuscules (a-z et A-Z) et les nombres (0-9) peuvent être générés de manière aléatoire. Le nombre suivant entre accolades spécifie le nombre de caractères pour ce groupe.\\nCeci n'est qu'un exemple simple, les modèles REGEX peuvent être beaucoup plus avancés et des informations supplémentaires peuvent être trouvées sur l'Internet.",
  },
  settingsPayment: {
    sale: {
      tenders: {
        giftcardSerial: "Bon d'achat / Carte de paiement",
        giftcardRegular: 'Cadeau certifié',
      },
    },
  },
  settingsUserInterface: {
    functions: {
      hideGiftCardBalance: "Vérifier le solde du bon d'achat",
    },
  },
  openCloseDay: {
    closeDay: {
      tenders: {
        tenders: {
          'GIFTCARD-GIFT_CERTIFICATE': 'Cadeau certifié',
          'GIFTCARD-CASH_CARD': 'Carte de paiement',
          'GIFTCARD-VOUCHER': "Bon d'achat",
        },
      },
    },
  },
  orderNotifications: {
    title: 'Commandes',
    newOrders: 'Nouvelles commandes ({{count}})',
    moreNewOrders_one: 'et {{count}} de plus',
    moreNewOrders_other: 'et {{count}} autres',
  },
};
