import * as R from 'ramda';

import { round } from 'utils';

interface MinimalRow {
  productID: number;
  amount: number;
  finalPriceWithVAT: number;
  rowTotal: number;
  vatRate: string;
}

export const compensateCartForRounding = <T extends MinimalRow>(
  rows: T[],
  rounding: number,
  vatTotal: number,
  netTotal: number,
): { rows: typeof rows; netTotal: string; vatTotal: string } => {
  if (rounding === 0)
    return {
      rows,
      netTotal: String(netTotal),
      vatTotal: String(vatTotal),
    };

  const cartRows = R.map(R.clone)(rows); // Avoid mutating state

  const rowToAdjust =
    vatTotal === 0 ? cartRows[0] : cartRows.find(row => row.vatRate !== '0');

  if (!rowToAdjust)
    return {
      rows,
      netTotal: String(netTotal),
      vatTotal: String(vatTotal),
    };

  const vatPercentage = 1 + Number(rowToAdjust.vatRate) / 100;
  const netRounding = rounding / vatPercentage;

  const amountSign = Math.sign(rowToAdjust.amount);

  const newFinalPriceWithVAT = Number(
    round(rowToAdjust.finalPriceWithVAT + rounding * amountSign),
  );

  if (Math.abs(rowToAdjust.amount) === 1) {
    rowToAdjust.finalPriceWithVAT = newFinalPriceWithVAT;
    rowToAdjust.rowTotal = newFinalPriceWithVAT * amountSign;
  } else if (
    Math.abs(rowToAdjust.amount) > 0 &&
    Math.abs(rowToAdjust.amount) < 1
  ) {
    const newFinalPriceWithVAT = Number(
      round(rowToAdjust.finalPriceWithVAT + rounding / rowToAdjust.amount),
    );

    rowToAdjust.finalPriceWithVAT = newFinalPriceWithVAT;
    rowToAdjust.rowTotal = newFinalPriceWithVAT * rowToAdjust.amount;
  } else {
    rowToAdjust.amount -= amountSign;
    rowToAdjust.rowTotal = rowToAdjust.finalPriceWithVAT * rowToAdjust.amount;
    cartRows.unshift({
      productID: rowToAdjust.productID,
      amount: amountSign,
      finalPriceWithVAT: newFinalPriceWithVAT,
      rowTotal: newFinalPriceWithVAT * amountSign,
    });
  }

  return {
    rows: cartRows,
    netTotal: round(netTotal + netRounding) ?? '',
    vatTotal: round(vatTotal + (rounding - netRounding)) ?? '',
  };
};
