import React, { ComponentProps } from 'react';
import Button from 'react-bootstrap/Button';

type Props = Omit<ComponentProps<typeof Button>, 'variant'> & {
  action?: (event: React.MouseEvent) => void;
  classes?: string;
  style?: React.CSSProperties;
  variant?: string;
}

/**
 * A square button mainly for use in the List component
 *
 * Wraps its children in a bootstrap button
 */
const ListItem: React.FC<Props> = ({
  children,
  action,
  classes = '',
  ...bttnProps
}) => {
  return (
    // @ts-ignore
    <Button
      className={`button-item ${classes}`}
      {...bttnProps}
      onClick={event => action && action(event)}
    >
      {children}
    </Button>
  );
};

export default ListItem;
