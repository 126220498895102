import React from 'react';

import InputField from 'components/FieldTypes/InputField';

interface Props {
  prompts: string[];
  value: Record<string, string>;
  onChange(p: string, v: string): void;
  getErrorText(p: string, v: string): string;
  validate(p: string, v: string): void;
  autoFocus?: boolean;
}

const CustomPromptsForm: React.FC<Props> = ({
  prompts,
  value,
  onChange,
  getErrorText,
  validate,
  autoFocus = false,
}) => {
  return (
    <>
      {prompts.map((p, i) => (
        <InputField
          key={p}
          data-testid="prompt"
          data-test-key={p}
          title={p}
          errorText={getErrorText(p, value[p])}
          onChange={e => {
            const v = e.target.value;
            onChange(p, v);
            validate(p, v);
          }}
          autoFocus={autoFocus && i === 0}
          value={value[p]}
        />
      ))}
    </>
  );
};

export default CustomPromptsForm;
