import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import {
  getGeneralPrintingSettings,
  getIsLoadingCafa,
} from 'reducers/cafaConfigs';
import {
  printRulerAndStick,
  printTextScales,
} from 'actions/integrations/printer';
import usePrintingMSSettings from 'utils/hooks/usePrintingMSSettings';
import { RootState } from 'reducers';

type T = ReturnType<typeof getGeneralPrintingSettings>;

const subState = <T,>(lens, value, onChange): [T, (v: T) => void] => {
  const currentValue = (R.view(lens, value) as unknown) as T;
  return [currentValue, newValue => onChange(R.set(lens, newValue, value))];
};
export const PrintingBaseSettings = ({
  value,
  onChange,
}: {
  value: T;
  onChange: (v: T) => void;
}) => {
  return (
    <>
      <div>
        <ForcePatchScriptRenderInput value={value} setValue={onChange} />
      </div>
      <div>
        <UseBackofficeZReport value={value} setValue={onChange} />
      </div>
    </>
  );
};

const ForcePatchScriptRenderInput = ({ value, setValue }) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const baseLens = R.lensProp('forcePatchscriptRender');
  const [state, setState] = subState(baseLens, value, setValue);
  const [loading, setLoading] = useState<boolean | [number, number]>(false);
  const [enabled, setEnabled] = subState<boolean>(
    R.lensProp('enabled'),
    state,
    setState,
  );
  const [width, setWidth] = subState<number>(
    R.lensProp('pixelWidth'),
    state,
    setState,
  );
  const [scale, setScale] = subState<number>(
    R.lensProp('scaleMultiplier'),
    state,
    setState,
  );
  const { t } = useTranslation('settingsPrinter');

  const loadingCafa = useSelector(getIsLoadingCafa);
  const {
    goMSSettings,
    printer,
    loading: loadingGoMSSettings,
  } = usePrintingMSSettings();

  const printerSettingsMessage = useMemo(() => {
    if (!printer || goMSSettings?.value?.PrinterName === '')
      return t(`general.fields.pixelWidth.recommendationError`);

    const printerSetting =
      printer[`PixelPerLine${goMSSettings?.value?.PrinterPaperSize}`];
    if (!printerSetting)
      return t(`general.fields.pixelWidth.recommendationError`);

    return t(`general.fields.pixelWidth.recommendation`, {
      pixelWidth: printerSetting,
    });
  }, [goMSSettings, printer, t]);

  return (
    <>
      <FormControlLabel
        label={t(`general.fields.forcePatchscriptRender`)}
        control={
          <Checkbox
            checked={enabled ?? false}
            onChange={() => setEnabled(!enabled)}
          />
        }
      />
      <div style={{ marginLeft: '3em', display: enabled ? undefined : 'none' }}>
        {loading ? (
          <LinearProgress
            value={(100 * loading[0]) / loading[1]}
            valueBuffer={(100 * (loading[0] + 1)) / loading[1]}
            variant={
              Array.isArray(loading) && loading.length === 2
                ? 'buffer'
                : 'indeterminate'
            }
          />
        ) : null}
        <Button
          color="primary"
          disabled={!!loading}
          onClick={() => {
            setLoading(true);
            dispatch(printRulerAndStick()).finally(() => setLoading(false));
          }}
        >
          {t('general.fields.inputs.ruler.title')}
        </Button>
        <FormControl>
          <Input
            type="number"
            value={Object.is(width, -0) ? '' : width}
            onChange={e => {
              const v = e.target.value === '' ? -0 : Number(e.target.value);
              setWidth(v);
            }}
            startAdornment={
              <InputAdornment position="start">
                {t(`general.fields.inputs.ruler.width`)}
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {t(`general.fields.inputs.px`)}
              </InputAdornment>
            }
          />
          <FormHelperText>
            {loadingGoMSSettings || loadingCafa ? (
              <>
                {t(`general.fields.pixelWidth.recommendationLoading`)}{' '}
                <CircularProgress color="inherit" size={13} thickness={5} />
              </>
            ) : (
              printerSettingsMessage
            )}
          </FormHelperText>
        </FormControl>
        <br />
        <Button
          color="primary"
          disabled={!!loading}
          onClick={() => {
            setLoading(true);
            dispatch(
              printTextScales(width, (d, t) => setLoading([d, t])),
            ).finally(() => setLoading(false));
          }}
        >
          {t('general.fields.inputs.scale.title')}
        </Button>
        <Input
          type="number"
          value={Object.is(scale, -0) ? '' : scale * 100}
          onChange={e => {
            const v = e.target.value === '' ? -0 : Number(e.target.value);
            setScale(v / 100);
          }}
          startAdornment={
            <InputAdornment position="start">
              {t('general.fields.inputs.scale.scale')}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              {t('general.fields.inputs.percentage')}
            </InputAdornment>
          }
        />
        <br />
      </div>
    </>
  );
};

const UseBackofficeZReport = ({ value, setValue }) => {
  const lens = R.lensPath(['zReport', 'useBoReceipt']);
  const enabled = R.view(lens, value) as boolean;
  const { t } = useTranslation('settingsPrinter');

  return (
    <FormControlLabel
      label={t(`general.fields.useBoZReport`)}
      control={
        <Checkbox
          checked={enabled ?? false}
          onChange={() => setValue(R.set(lens, !enabled))}
        />
      }
    />
  );
};
