import { curry } from 'ramda';

export const translations = {
  en: {
    phone: 'Phone',
    cashier: 'Cashier',
    customer: 'Customer',
    homeStore: 'HomeStore',
    receipt: 'Receipt',
    item: 'Item',
    description: 'Description',
    qty: 'Qty',
    price: 'Price',
    total: 'Total',
    net: 'Net',
    discount: 'Discount',
    subtotal: 'Subtotal',
    subtotalAfterDiscount: 'Subtotal (after discount)',
    tax: 'Tax',
    paid: 'Paid',
    amtTendered: 'Amt Tendered',
    change: 'Change Due',
    transactionType: 'Transaction Type',
    sum: 'Amount Paid',
    aid: 'AID',
    cardType: 'Card Type',
    type: 'Card Type',
    cardNumber: 'Card #',
    cardHolder: 'CardHolder name',
    referenceNumber: 'Ref #',
    authorizationCode: 'Approval code',
    entryMethod: 'Entry Method',
    expirationDate: 'Expiration Date',
    certificateBalance: 'Balance',
    youSaved: 'YOU SAVED {{sum}}',
    youEarned: 'You have earned {{totalBB}} $10 DSC Bird Buck',
    youEarnedClubPoints: 'You have earned {{points}} Club Points today',
    clubPointsTotal: 'Your new Club Points total is {{points}}!',
    clubPointsExpiration: 'Club Expiration',
    storePointsEligibilityNote:
      '*DSC Membership benefits only eligible at Home Store(s)',
    visitUsNote: 'Visit us and order online at',
    verificationCode: 'Verification Code',
    incrementDate: 'Date',
    incrementCardNumber: 'Card number',
    incrementBalance: 'Balance',
    incrementRefNo: 'Reference number',
  },
};

type Lang = keyof typeof translations;

export type TransKeys = keyof typeof translations[keyof typeof translations];

export const getTranslationFunction = curry(
  (lang: Lang) => (key: TransKeys, meta?: Record<string, string>) => {
    const line = translations[lang][key];
    if (!line) return key;
    if (!meta) return line;
    return line.replace(/{{(.*?)}}/g, (_, key) => meta[key]);
  },
);
