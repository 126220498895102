import * as R from 'ramda';
import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { getCurrentSalesDocument } from 'reducers/sales';

import { ComponentConfiguration } from './components';
import {
  DISABLE_EXTERNAL_PAYMENT,
  ENABLE_EXTERNAL_PAYMENT,
  PLUGIN_ID,
  PluginConfiguration,
} from './types';
import reduxReducer, {
  getExternalPaymentProviderDomain,
  getHasExternalPaymentsForProcessing,
  getSelectedCustomPaymentType,
} from './reducer';

const defaultPluginConfiguration: PluginConfiguration = {
  customPaymentTypeID: '',
  domain: '',
};

/**
 * This plugin is a semi-integrated Payment Processor for Mobile Money Payments (MoMo)
 *
 * <br/>
 * The plugin uses a custom payment type that the user has to generate ( either in BO or in the plugin Configuration Component)
 * and save to the plugin's Configuration
 *
 * When the user wants to process a sale with MoMo Payment, they need to:
 * * prepare the Shopping Cart as usual
 * * Save as Order
 * * (Optional) Select the tenders they would like to use aside from MoMo
 * * Add MoMo payment - the amount does not relly matter but at this point we treat the Custom Payment as a regular Tender for the user's convenience
 * * Press the green check button to Process the tenders and finalise the sale document (Order)
 *
 * The plugin will intercept the saveSalesDocument bulk request and:
 * * remove the MoMo Payment - it will not be saved to API
 * * open the MoMo pre-configured endpoint (domain is configured in the Plugin Configuration) in a new tab - orderNumber and employeeID are passed as query parameters, where the Mobile Payment can be finalised
 *
 * When a MoMo Payment redirection happens:
 * * MoMo takes the orderNumber from the query parameters of the redirect URL
 * * it finds the sale document based on the id and requests a payment from the user in the amount of the unpaid amount from the sale document
 * * Note! MoMo only considers the unpaid amount, it does not care about the existing Payments linked to the sale document
 */
const externalPaymentMoMo: PosPlugin<PluginConfiguration> = {
  id: PLUGIN_ID,
  name: 'Mobile money Payment Integration',
  keywords: ['mobile', 'money', 'momo', 'payment'],
  // eslint-disable-next-line global-require
  infoMDUrl: require('./documentation.md'),
  getStatus: state => {
    if (!getSelectedCustomPaymentType(state))
      return {
        type: 'error',
        message:
          'The plugin is not configured or the configured payment type no longer exists. Please, add a Custom Payment type to be used.',
      };

    if (!getExternalPaymentProviderDomain(state)?.trim())
      return {
        type: 'error',
        message:
          'The plugin is not configured. Please, add a domain for the External Payment processor.',
      };
    return { type: 'valid', message: 'Ready' };
  },
  ComponentConfigurationByLevel: {
    Company: ComponentConfiguration,
  },
  reduxReducer,
  selectorOverrides: {
    getSettings: base =>
      createSelector(
        base,
        getCurrentSalesDocument,
        getSelectedCustomPaymentType,
        (settings, currentSaleDocument, customPaymentType) => {
          if (!customPaymentType) return settings;

          const shouldShow = currentSaleDocument.type !== 'ORDER' ? 0 : 1;

          return R.over(
            R.lensProp('enabled_custom_payments'),
            R.pipe(
              R.tryCatch(JSON.parse, R.always({})),
              R.assoc(customPaymentType, shouldShow),
              JSON.stringify,
            ),
          )(settings);
        },
      ),
  },
  combineConfiguration: (company, warehouse, pos) =>
    R.reduce(
      R.mergeDeepRight,
      defaultPluginConfiguration,
      [company, warehouse, pos].filter(Boolean),
    ),
  onSaveSalesDocument: {
    on: (p, ap) => async (dispatch, getState) => {
      const currentSaleDoc = getCurrentSalesDocument(getState());
      const saleDocument = ap.find(r => r.requestName === 'saveSalesDocument');

      // only intercept ORDER sales (when confirming an order into invoice, need to check the current rdx document)
      if (saleDocument?.type !== 'ORDER' && currentSaleDoc?.type !== 'ORDER')
        return ap;

      const selectedCustomPaymentType = getSelectedCustomPaymentType(
        getState(),
      );
      // remove the payment request for the external payment integration
      // once the payment is processed the integration will save it itself
      const filteredRequests = ap.filter(
        request =>
          !(
            request.requestName === 'savePayment' &&
            request.type === selectedCustomPaymentType
          ),
      );

      // if a payment request for external integration was present
      // store that in the plugin redux state so the webhook for the payment integration will be triggered
      if (filteredRequests?.length !== ap?.length) {
        dispatch({ type: ENABLE_EXTERNAL_PAYMENT });
      }

      return filteredRequests;
    },
    after: (p, o) => async (dispatch, getState) => {
      const shouldRedirect = getHasExternalPaymentsForProcessing(getState());
      if (!shouldRedirect) return;
      // state - domain, orderNumber, employeeID
      const domain = getExternalPaymentProviderDomain(getState());
      const employeeID = o.requests.find(
        r => r.requestName === 'saveSalesDocument',
      )?.employeeID;

      const orderNumber = o.salesDocument.invoiceNo;
      // open the web hook for payment processing in a new tab
      // address - https://<<host-domain>>/erply-prompt-momo-payment/?orderNo=<<order-number>>&teller=<<logged -in-user>>
      window.open(
        `https://${domain}/erply-prompt-momo-payment/?orderNo=${orderNumber}&teller=${employeeID}`,
      );
      dispatch({ type: DISABLE_EXTERNAL_PAYMENT });
    },
  },
};

export default externalPaymentMoMo;
