import { useDispatch } from 'react-redux';
import React, { useState } from 'react';

import { PosPlugin } from 'plugins/plugin';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { getPluginConfiguration } from 'reducers/Plugins';
import { print } from 'utils/print';
import { addError } from 'actions/Error';
import { SaveSalesDocumentResponse } from 'types/SalesDocument';
import { getSaleDocID } from 'actions/integrations/printer/utils';

const pluginID = 'actualReports';
type Configuration = {
  templateID: number;
};
const actualReportsPlugin: PosPlugin<
  Configuration,
  {},
  Configuration,
  {},
  {}
> = {
  id: pluginID,
  name: 'Actual reports: custom receipt printout',
  info:
    'Replace sales receipt printouts with an actual reports template (browser-print only)',
  ComponentConfiguration: ({ current, save }) => {
    const dispatch = useDispatch();
    const close = () => dispatch(previousModalPage());
    const [id, setId] = useState(current?.templateID ?? '');

    return (
      <>
        <InputField
          onChange={e => setId(e.target.value)}
          value={id}
          formatter={n => n.replace(/\D/g, '')}
          title="Actual reports template ID"
        />
        <UIButton
          action={() => {
            save({ templateID: id });
            close();
          }}
          text="Save"
        />
      </>
    );
  },
  getStatus: state => {
    const conf = getPluginConfiguration<Configuration>(pluginID)(state);
    if (conf?.templateID) return { type: 'valid', message: '' };
    return { type: 'error', message: 'Template ID not configured' };
  },
  onPrintReceipt: {
    before: p => async (dispatch, getState) => {
      const conf = getPluginConfiguration<Configuration>(pluginID)(getState());
      await doClientRequest<{ html: string }>({
        request: 'getSalesDocumentActualReportsHTML',
        // @ts-ignore - fix later
        documentID: getSaleDocID(p.salesDocument),
        templateID: conf.templateID,
      })
        .then(records => records[0].html)
        .then(
          data => {
            print({ fullHtml: data });
            throw new Error('Cancel regular receipt for AR receipt');
          },
          e => {
            dispatch(
              addError(
                'Failed to print actual report - perhaps the template ID is incorrect? Falling back to standard printout',
              ),
            );
            console.error('Failed to print actual report', e);
            // Catch errors and continue with regular receipt
          },
        );
    },
  },
};

export default actualReportsPlugin;
