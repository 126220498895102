import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const pagesPerViewOptions = [5, 10, 15, 20, 25];

/**
 * Coponent for selecting items per page
 *
 */

const SelectRecordsPerPage = ({
  itemsPerPage,
  setItemsPerPage,
  style = {},
}) => {
  return (
    <Dropdown onSelect={setItemsPerPage} alignRight={true} style={{ ...style }}>
      <Dropdown.Toggle>{itemsPerPage}</Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: '1em' }}>
        {pagesPerViewOptions.map(p => (
          <Dropdown.Item
            key={p}
            style={{ width: '2em', textAlign: 'right' }}
            eventKey={p}
          >
            {p}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

SelectRecordsPerPage.propTypes = {
  /** current items per page value */
  itemsPerPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** items per page setter function */
  setItemsPerPage: PropTypes.func.isRequired,
  /** javascript style object to be passed to Dropdown */
  style: PropTypes.object,
};

export default SelectRecordsPerPage;
