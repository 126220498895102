import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getGridIsOpened } from 'reducers/UI/gridDisplay';
import { getUISetting } from 'reducers/configs/settings';
import { useBreakpoints } from 'utils/hooks/UI';
import CustomerDisplaySimulator from 'containers/CustomerDisplaySimulator/CustomerDisplaySimulator';
import ModalPage from 'containers/ModalPage/ModalPage';
import './POS.scss';
import { autoImportLazyComponents } from 'constants/modalPage';
import Col1 from 'containers/Col1/Col1';
import Col2 from 'containers/Col2';
import { Grid } from '@material-ui/core';
import { isSoftLoggedOut } from 'reducers/Login';
import { LockscreenOverlay } from 'containers/POS/Lockscreen';

const POS = () => {
  const largeView = useBreakpoints().mdplus;
  const isQuickView = useSelector(getGridIsOpened);
  const isFullScreen = useSelector(getUISetting('isFullScreen'));
  const isEvenColumns = useSelector(getUISetting('isEvenColumns')) && largeView;
  const isLocked = useSelector(isSoftLoggedOut);

  useEffect(() => autoImportLazyComponents(true), []);

  if (!largeView) {
    return (
      <Grid
        container
        className={classNames('pos-container', {
          quickView: isQuickView,
          fullScreen: isFullScreen,
        })}
      >
        {isLocked && <LockscreenOverlay />}
        <Grid
          item
          xs={12}
          id="pos-col-1"
          className={classNames('pos-col-1', {
            even: isEvenColumns,
          })}
        >
          <Col1 />
          <ModalPage />
        </Grid>
        {localStorage.getItem('Debug: mockCustomerDisplay') && (
          <CustomerDisplaySimulator />
        )}
      </Grid>
    );
  }

  return (
    <Grid
      container
      className={classNames('pos-container', {
        quickView: isQuickView,
        fullScreen: isFullScreen,
      })}
    >
      {isLocked && <LockscreenOverlay />}
      <Grid
        item
        xs="auto"
        id="pos-col-1"
        className={classNames('pos-col-1', 'narrow', {
          even: isEvenColumns,
        })}
      >
        <Col1 />
      </Grid>
      <Grid item className="pos-col-2">
        <div>
          <Col2 />
        </div>
        <div>
          <ModalPage />
        </div>
      </Grid>
      {localStorage.getItem('Debug: mockCustomerDisplay') && (
        <CustomerDisplaySimulator />
      )}
    </Grid>
  );
};
export default POS;
