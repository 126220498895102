import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';

const ScannerNoResults = ({ code }) => {
  const dispatch = useDispatch();
  const close = () => dispatch(previousModalPage());
  const { t } = useTranslation('scanSearchResults');

  return (
    <>
      <Modal.Header>
        <h3 style={{ fontWeight: 'bold' }}>{t('scannerNoResults.header')}</h3>
        <CloseButton action={close} />
      </Modal.Header>
      <Modal.Body>
        <div style={{ whiteSpace: 'pre-line' }}>
          <span>{t('scannerNoResults.body', { code })}</span>
        </div>
      </Modal.Body>
    </>
  );
};

export default ScannerNoResults;
