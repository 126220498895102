import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';

const AddGiftCard = () => {
  const { t } = useTranslation('payment');
  const dispatch = useDispatch();
  return (
    <li className="payment-item cash-payment" style={{ cursor: 'pointer' }}>
      <div
        tabIndex={0}
        className="button"
        role="button"
        onClick={() => dispatch(setPaymentSelected('giftcard'))}
      >
        <span className="title">{t('buttons.giftCard')}</span>
      </div>
    </li>
  );
};

export default AddGiftCard;
