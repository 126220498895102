import { createSelector } from 'reselect';
import { Action } from 'redux';

import * as actionTypes from 'constants/Payments';
import { PaymentType } from 'types/PaymentType';

import { getSetting } from './configs/settings';

// TODO: finish transition to typescript:
// * add types to reducers
// * add types to selectors

const initialState: PaymentType[] = [];

function paymentTypes(
  state: PaymentType[] = initialState,
  { type, payload }: Action & { payload: PaymentType[] },
): PaymentType[] {
  switch (type) {
    case actionTypes.GET_PAYMENT_TYPES:
      return payload;
    default:
      return state;
  }
}

export default paymentTypes;

const baseTypes = [
  'TRANSFER',
  'CASH',
  'CREDIT',
  'GIFTCARD',
  'TIP',
  'CHECK',
  'CARD',
];

export function getAllPaymentTypes(state): PaymentType[] {
  return state.PaymentTypes;
}
export const getCustomPaymentTypes = createSelector(
  state => getAllPaymentTypes(state),
  paymentTypes =>
    paymentTypes.filter(paymentType => !baseTypes.includes(paymentType.type)),
);
export function getIdForCustomPaymentType(type) {
  return state => getCustomPaymentTypes(state).find(pt => pt.type === type)?.id;
}

export function getNameForCustomPaymentTypeById(id: string) {
  return state => {
    if (Number(id) < 0) {
      return 'CANCELFEE';
    }
    return (
      getCustomPaymentTypes(state).find(pt => pt.id === id)?.type ?? 'CANCELFEE'
    );
  };
}
export const getEnabledCustomPaymentsSetting = getSetting(
  'enabled_custom_payments',
);
const getActiveCustomPayments = createSelector(
  state => getCustomPaymentTypes(state),
  state => getEnabledCustomPaymentsSetting(state),
  (customPts, enabledSettings) =>
    customPts.filter(pt => enabledSettings[pt.type]),
);

export const getExternalPayments = createSelector(
  state => getSetting('pos_external_extra_card_payment_types')(state),
  (listString: string) =>
    listString
      .split(',')
      .map(a => a.trim())
      .filter(a => a.length),
);

const getCustomCardTypesSetting = createSelector(
  state => getSetting('pos_custom_extra_card_payment_types')(state),
  listString => listString.split(','),
);
export const getPaymentCards = createSelector(
  state => getCustomPaymentTypes(state),
  state => getCustomCardTypesSetting(state),
  (customPts, enabledSettings) => {
    return customPts.filter(pt => 0 <= enabledSettings.indexOf(pt.name));
  },
);

export function getActiveCustomPaymentNames(state) {
  return getActiveCustomPayments(state).map(a => a.name);
}
export function getActiveCustomPaymentTypes(state) {
  return getActiveCustomPayments(state).map(a => a.type);
}

export function getTypes(state) {
  return getSetting('enabled_custom_payments')(state);
}
