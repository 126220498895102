import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getCustomerDisplayState } from '../rdx/reducer';
import {
  getScreenDetails,
  setFetchedInitialScreenDetails,
} from '../rdx/actions';

const useScreenDetector = () => {
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  const { fetchedInitialScreenDetails } = useSelector(getCustomerDisplayState);

  useEffect(() => {
    if (!fetchedInitialScreenDetails) {
      dispatch(setFetchedInitialScreenDetails(true));
      dispatch(getScreenDetails()).then(screenDetails => {
        screenDetails?.addEventListener('screenschange', () =>
          dispatch(getScreenDetails()),
        );
      });
    }
  }, [fetchedInitialScreenDetails]);
};

export default useScreenDetector;
