import React from 'react';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import { useMultiOptIn } from '../customerMultiOptIn/customerMultiOptIn';
import { insertAfter, matches } from '../customerMultiOptIn/utils';
import { useDefaultCustomerGroups } from '../defaultCustomerGroups';
import { useRequireEmail } from '../requireEmail';

export const UICustomerForm: Required<PosPlugin>['UICustomerForm'] = ({
  value,
  onChange,
  isCompany,
  children,
}) => {
  useDefaultCustomerGroups({ value, onChange, isCompany });
  const makeEmailRequired = useRequireEmail({ value, onChange });
  const { optOutCheckboxes, removeDefaultEmailOptOut } = useMultiOptIn({
    onChange,
    value,
    isCompany,
  });

  return R.pipe(
    React.Children.toArray,
    insertAfter(/emailOptOut/, optOutCheckboxes),
    removeDefaultEmailOptOut,
    makeEmailRequired,
    R.reject(matches(/customerCardNumber/)),
  )(children);
};
