import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { displayView } from 'constants/UI';
import { modalPages as mp } from 'constants/modalPage';
import List from 'components/List';
import { setGridView, setProductGroup } from 'actions/UI';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getIsEditMode } from 'reducers/UI/productEditMode';
import { isString } from 'utils';
import { getGridView } from 'reducers/UI/gridDisplay';

import { PluginComponent } from 'plugins';
import { GridButton } from 'components/GridButton';

const ProductGroupItem = ({ item }) => {
  const isEditMode = useSelector(getIsEditMode);
  const gridView = useSelector(getGridView);
  const dispatch = useDispatch();
  const onProductGroupEdit = () =>
    dispatch(
      openModalPage({
        component: mp.ProductGroupEdit,
        props: { productGroupID: item.productGroupID },
      }),
    );

  const onProductGroupClick = () => {
    if (gridView !== 'main') dispatch(setGridView(displayView.main));
    dispatch(setProductGroup(item.id));
  };

  const handleClick = () => {
    if (item?.action) return item.action();
    if (item.productGroupID)
      return isEditMode ? onProductGroupEdit() : onProductGroupClick();
  };

  const testKey = typeof item.name === 'string' ? item.name : item.id;

  return (
    <GridButton
      className={classNames({
        'edit-mode': isEditMode && item.productGroupID,
      })}
      btnColor={item.type === 'special' ? 'grid_green' : 'grid_dark'}
      onClick={handleClick}
      disabled={item.disabled}
      title={isString(item.name) ? item.name : undefined}
      data-testid="product-group-button"
      data-test-key={testKey}
      style={{
        background: item.attributes?.PosGroupColor,
        ...item.style,
      }}
    >
      <span data-testid="product-group-button-name">{item.name}</span>
    </GridButton>
  );
};

ProductGroupItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
  }),
};

const ProductGroups = ({
  productGroups,
  specialBttnsStart,
  specialBttnsEndIfFull,
  minLines,
  maxLines,
  specialBttnsEnd,
  onSetNrLines,
  gridTypeOpened,
}) => {
  const itemComponent = useMemo(() => <ProductGroupItem />, []);

  return (
    <PluginComponent
      hookname="UIProductGroups"
      props={{
        productGroups,
        specialBttnsStart,
        specialBttnsEndIfFull,
        minLines,
        maxLines,
        specialBttnsEnd,
        onSetNrLines,
        gridTypeOpened,
      }}
    >
      <List
        items={productGroups}
        specialBttnsStart={specialBttnsStart}
        minLines={minLines}
        maxLines={maxLines}
        onSetNrLines={onSetNrLines}
        specialBttnsEnd={specialBttnsEnd}
        specialBttnsEndIfFull={specialBttnsEndIfFull}
        itemComponent={itemComponent}
        gridTypeOpened={gridTypeOpened}
      />
    </PluginComponent>
  );
};

ProductGroups.propTypes = {
  productGroups: PropTypes.array.isRequired,
  startBttns: PropTypes.array,
  endBttns: PropTypes.array,
  totalLength: PropTypes.number,
};

export default React.memo(ProductGroups);
