/* eslint-disable no-param-reassign */

import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import * as api from 'paymentIntegrations/pax/api2';
import RenderFormItem from 'containers/Forms/Settings/components/FormFieldCtx';
import { paxFields } from 'paymentIntegrations/pax/paxFields';
import useErrorState, { translateError } from 'utils/hooks/useErrorState';

import { Ctx } from '../../../../components/CtxInputs';

const Pax = () => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.pax.${text}`, ...rest);
  const superContext = useContext(Ctx);
  const {
    configuration,
    setEnableUpdate,
    initialIntegrationState,
  } = superContext.values;
  const allSaved = R.equals(configuration, initialIntegrationState);
  const { errors, hasErrors } = useErrorState(configuration, paxFields);
  const [conn, setConn] = useState('');

  useEffect(() => setEnableUpdate(!hasErrors), [hasErrors]);

  // Update configuration fields
  const onChange = (key, value) => {
    switch (key) {
      case 'apiPort':
        value = Number(value.replace(/\D/g, ''));
        break;
      default:
        value = String(value);
        break;
    }
    superContext.onChange('configuration', {
      ...configuration,
      [key]: value,
    });
  };

  return (
    <div>
      <Ctx.Provider
        value={{
          values: configuration,
          onChange,
        }}
      >
        <h2>PAX-Heartland Integration Form</h2>
        <section className="my-4">
          <h5>Provider settings</h5>
          {Object.values(paxFields).map(field => (
            <RenderFormItem
              key={field.id}
              title={t(field.id)}
              name={field.id}
              error={translateError(errors[field.id], t)}
              {...field}
            />
          ))}
          <Button
            disabled={!allSaved}
            onClick={() =>
              api
                .testConnection()
                .then(() => setConn('Connection successful'))
                .catch(err => {
                  setConn('Error in PAX connection, see console log');
                  console.error('Error in PAX connection', err);
                })
            }
          >
            {allSaved
              ? 'Test connection'
              : 'Unsaved changes, save first to Test Connection'}
          </Button>
        </section>
        {conn}
      </Ctx.Provider>
    </div>
  );
};

export default Pax;
