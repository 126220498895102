/* eslint-disable @typescript-eslint/no-use-before-define */
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import * as R from 'ramda';

import {
  getCardPayments,
  getCustomer,
  getSalesDocument,
} from 'reducers/Payments';
import { deletePayment, markForProcessing } from 'actions/Payments';
import { addWarning, dismissType, addError } from 'actions/Error';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getLoggedInEmployeeID, getClientCode } from 'reducers/Login';
import { getCurrentSalesDocument } from 'reducers/sales';
import { lastInvoiceNumbers } from 'services/localDB';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { posAddOnMessage, posSendMessage } from 'utils/hooks/useWrapper';
import { getCurrencyCode, getUseCayanCapture } from 'reducers/configs/settings';
import { openTerminalIntegration } from 'actions/integrations/terminal';
import {
  sendPayment,
  retractPayment,
  finished,
  cancelPayments,
} from 'paymentIntegrations/wrapper/messages';
import { wrapperFunctionButtons } from 'paymentIntegrations/wrapper/functions';

/**
 * Main method that gets called by cardPaymentUI to handle ALL card payments
 */

const initRegularPayments = hooks => async (dispatch, getState) => {
  const {
    updateMessage,
    enableButtons,
    cardPayments: allCardPayments,
    resolvePayments,
    rejectPayments,
    processSaleAsReturn,
    beforeDocSave,
  } = hooks;
  const clientCode = getClientCode(getState());
  const useCayanCapture = getUseCayanCapture(getState());
  const transactionType = useCayanCapture ? 'PREAUTH' : 'SALE';
  enableButtons(['cancel']);
  const currencyCode = getCurrencyCode(getState());
  const cardPayments = allCardPayments.filter(
    pmt => processSaleAsReturn || !pmt.paid,
  );
  if (cardPayments.length === 0) {
    resolvePayments();
    return null;
  }

  updateMessage('Connecting to wrapper');

  const todo = [...cardPayments.map((p, i) => ({ ...p, referenceNumber: i }))];
  let done = [];

  const customer = getCustomer(getState());
  const employee = getEmployeeById(getLoggedInEmployeeID(getState()))(
    getState(),
  );
  const nextInvoiceNumber =
    Number(
      await lastInvoiceNumbers.getItem({
        key: getSelectedPosID(getState()),
      }),
    ) + 1;
  const invoice = {
    ...getCurrentSalesDocument(getState()),
    ...getSalesDocument(getState()),
    number: nextInvoiceNumber,
  };
  const paymentCancelled = payload => {
    if (payload && payload.referenceNumber) {
      done = done.filter(p => p !== payload.referenceNumber);
    }
    unbindCallbacks.forEach(cb => cb());
    rejectPayments();
  };
  const sendBtnPressed = posSendMessage('payment:pressButton');
  const unbindCallbacks = [
    posAddOnMessage('payment:setMessage', ({ message }) => {
      updateMessage(message);
    }),
    posAddOnMessage('payment:setButtons', ({ buttons }) => {
      wrapperFunctionButtons.set(
        buttons.map(btn => ({
          ...btn,
          actionOnClick: () => async (dispatch, getState) =>
            sendBtnPressed(btn.name),
        })),
      );
      enableButtons(buttons.filter(btn => btn.enabled).map(btn => btn.name));
    }),
    posAddOnMessage('payment:done', async payload => {
      const {
        approvedAmount,
        authCode,
        cardNumber,
        entryMode,
        referenceNumber,
        dateTime,
        signature,
        cardType,
        paymentType,
        additionalData,
      } = R.pipe(
        R.evolve({
          transactionType: R.toUpper,
        }),
        R.when(
          R.propEq('transactionType', 'REFUND'),
          R.evolve({
            approvedAmount: a => -Number(a),
          }),
        ),
      )(payload);
      const tipAmount = additionalData?.amountDetails?.userTipAmount;
      await beforeDocSave({
        key: todo[0].key,
        type: 'CARD',
        paid: true,
        amount: Number(tipAmount)
          ? (Number(approvedAmount) - Number(tipAmount)).toFixed(2)
          : String(approvedAmount),
        cardType: paymentType || cardType,
        cardNumber,
        signature,
        attributes: {
          /** refNo as per documentation */
          refNo: referenceNumber,
          /** authCode as per documentation */
          authCode,
          entryMode,
          dateTime,
        },
      });
      if (Number(tipAmount)) {
        await beforeDocSave({
          key: todo[0].key.concat('2'),
          type: 'TIP',
          amount: -tipAmount,
          cardType,
          attributes: {
            refNo: referenceNumber,
          },
        });
        await beforeDocSave({
          key: todo[0].key.concat('1'),
          paid: true,
          type: 'CARD',
          amount: tipAmount,
          cardType,
          cardNumber,
          attributes: {
            cardIsTip: true,
            authCode,
            paymentType: cardType,
            refNo: referenceNumber,
            cardNumber,
          },
        });
      }
      done.push(referenceNumber);
      todo.shift();
      if (todo.length > 0) {
        sendPayment({
          clientCode,
          requestID: uuidv4(),
          payment: todo[0],
          employee,
          transactionType,
          customer,
          invoice,
          currencyCode,
          index: cardPayments.length - todo.length,
          total: cardPayments.length,
        });
        return;
      }
      finished();
      unbindCallbacks.forEach(cb => cb());
      resolvePayments();
    }),
    posAddOnMessage('payment:failed', paymentCancelled),
    posAddOnMessage('payment:cancelled', paymentCancelled),
  ];

  sendPayment({
    clientCode,
    requestID: uuidv4(),
    payment: todo[0],
    invoice,
    customer,
    transactionType,
    employee,
    currencyCode,
    index: 0,
    total: cardPayments.length,
  });
};

const initVoidPayments = ({
  enableButtons,
  resolvePayments,
  rejectPayments,
  beforeDocDelete,
  cardPayments,
}) => async (dispatch, getState) => {
  enableButtons([]);
  let todo = cardPayments.filter(p => p.paid).reverse();

  const total = todo.length;
  if (todo.length) {
    retractPayment({
      referenceNumber: todo[0].attributes.refNo,
      amount: todo[0].amount,
      index: 0,
      total,
    });
  } else {
    return rejectPayments();
  }

  dispatch(
    addWarning('Voiding payments...', {
      selfDismiss: false,
      dismissible: false,
      errorType: 'WrapperIntegration/cancel',
    }),
  );
  return new Promise((done, failed) => {
    const unbindCallbacks = [
      posAddOnMessage('payment:failed', () => {
        dispatch(dismissType('WrapperIntegration/cancel'));
        dispatch(addError('Voiding payments has failed'));
        unbindCallbacks.forEach(cb => cb());
        failed();
      }),
      posAddOnMessage('payment:cancelled', payload => {
        const {
          referenceNumber: newRefNo,
          originalReferenceNumber: refNo,
        } = payload;
        const payment = todo.find(
          p => p.attributes.refNo === refNo || p.attributes.refNo === newRefNo,
        );
        if (payment?.key) beforeDocDelete(payment.key);
        else
          console.error(
            'Error deleting payment - payment-to-be-deleted could not be found',
            {
              deleted: payment,
              haystack: todo,
            },
          );
        todo = todo.filter(pmt => pmt !== payment);
        if (todo.length) {
          retractPayment({
            referenceNumber: todo[0].attributes.refNo,
            amount: todo[0].amount,
            index: total - todo.length,
            total,
          });
        } else {
          dispatch(dismissType('WrapperIntegration/cancel'));
          unbindCallbacks.forEach(cb => cb());
          done();
        }
      }),
    ];
  }).then(
    () => rejectPayments(),
    () => rejectPayments(),
  );
};

const reportSoftlockWithCancel = ({ resolvePayments }) => async (
  dispatch,
  getState,
) => {
  const issueID = Sentry.captureMessage('Wrapper override used to cancel');
  posSendMessage('debug:reportSentry')({ issueID });
  resolvePayments();
};
const reportSoftlockWithSuccess = ({
  cardPayments,
  beforeDocDelete,
  beforeDocSave,
  resolvePayments,
}) => async (dispatch, getState) => {
  const issueID = Sentry.captureMessage(
    'Wrapper override used to force payment success',
  );
  posSendMessage('debug:reportSentry')({ issueID });
  const shouldVoid = cardPayments.some(
    payment => payment.shouldProcess === true,
  );
  const todo = getCardPayments(getState())
    .filter(p => (shouldVoid ? p.paid : !p.paid))
    .reverse();
  todo.forEach(t => {
    if (shouldVoid) {
      beforeDocDelete(t.key);
    } else {
      beforeDocSave({
        key: t.key,
        type: 'CARD',
        paid: true,
        amount: String(t.amount),
        cardType: undefined,
        cardNumber: undefined,
        signature: undefined,
        attributes: {
          /** refNo as per documentation */
          refNo: undefined,
          /** authCode as per documentation */
          authCode: undefined,
          entryMode: undefined,
          dateTime: undefined,
        },
      });
    }
  });
  resolvePayments();
};
const cancelButtonAction = hooks => async (dispatch, getState) => {
  dispatch(cancelPayments(hooks));

  const clientCode = getClientCode(getState());
  if (String(clientCode) === '467402') {
    const i = setTimeout(() => {
      wrapperFunctionButtons.add(debugButtons);
      hooks.enableButtons(debugButtons.map(btn => btn.name));
      remove();
    }, 5 * 1000);
    const handler = () => {
      clearTimeout(i);
      remove();
    };
    const remove = posAddOnMessage('payment:cancelled', handler);
  }
};

const debugButtons = [
  {
    actionOnClick: reportSoftlockWithCancel,
    text: 'force cancel',
    name: 'debug-cancel',
    variant: 'debug',
  },
  {
    actionOnClick: reportSoftlockWithSuccess,
    text: 'force success',
    name: 'debug-success',
    variant: 'debug',
  },
];
const defaultButtons = [
  {
    actionOnClick: cancelButtonAction,
    text: 'Cancel',
    name: 'cancel',
    variant: 'danger',
  },
];

const initPayments = hooks => async dispatch => {
  wrapperFunctionButtons.set(defaultButtons);
  const shouldVoid = hooks.cardPayments.some(
    payment => payment.shouldProcess === true,
  );
  if (shouldVoid) dispatch(initVoidPayments(hooks));
  else dispatch(initRegularPayments(hooks));
};
const voidPayments = () => async (dispatch, getState) => {
  const cardPayments = getCardPayments(getState());
  if (cardPayments.length) {
    cardPayments.forEach(({ key, paid }) =>
      paid
        ? dispatch(markForProcessing({ key }))
        : dispatch(deletePayment({ key })),
    );
  }
  const finalPayments = getCardPayments(getState());
  if (finalPayments.length) {
    await dispatch(openTerminalIntegration());
  }
  return true;
};

const setup = () => {
  // TODO: Move messageChannel setup to here
};
const { functions } = wrapperFunctionButtons;
export { initPayments, functions, voidPayments, setup };
