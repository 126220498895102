import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equals } from 'ramda';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useAsync } from 'react-use';

import { Product } from 'types/Product';
import {
  getProductsUniversal,
  GetProductsUniversalOptions,
  ProductRequestParams,
  ProductsUniversalReturnType,
} from 'actions/productsDB';
import { RootState } from 'reducers';

import {
  getActivePriceListIDsAsString,
} from './useReapplyPriceLists';

type UseProductsOptions = GetProductsUniversalOptions & { enabled: boolean };
const defaultOptions: UseProductsOptions = {
  localFirst: true,
  withMeta: true,
  addToCachedItems: false,
  enabled: true,
};

const useProducts = (
  props: ProductRequestParams,
  options?: GetProductsUniversalOptions,
  debounceTimeout = 300,
): {
  products: Product[];
  total: number;
  loading: boolean;
  productsDict: Record<number, Product>;
  refresh(): void;
} => {
  const newOptions = { ...defaultOptions, ...options };
  const activePricelists = useSelector(getActivePriceListIDsAsString);
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const [isDebounced, setIsDebounced] = useState(false);
  const [memoProps, setMemoProps] = useState<null | typeof props>(null);
  const [memoOptions, setMemoOptions] = useState<null | typeof newOptions>(
    null,
  );
  const [memoPricelists, setMemoPricelists] = useState(activePricelists);
  const [productsPromise, setProductsPromise] = useState<
    Promise<ProductsUniversalReturnType>
  >(new Promise(() => undefined));
  const {
    value: { products = [], total = 0, productsDict = {} } = {},
    loading,
  } = useAsync(() => productsPromise, [productsPromise]);

  useEffect(() => {
    if (isDebounced) return;
    if (equals(props, memoProps))
      if (equals(newOptions, memoOptions))
        if (equals(memoPricelists, activePricelists)) return;
    if (!newOptions.enabled) return;

    setProductsPromise(
      dispatch(
        getProductsUniversal(props, {
          ...newOptions,
          reapplyPriceLists:
            newOptions.reapplyPriceLists || activePricelists.length > 0,
        }),
      ),
    );
    setMemoProps(props);
    setMemoOptions(newOptions);
    setMemoPricelists(activePricelists);
    setIsDebounced(true);
    setTimeout(() => setIsDebounced(false), debounceTimeout);
  }, [
    props,
    memoProps,
    newOptions,
    memoOptions,
    isDebounced,
    dispatch,
    debounceTimeout,
    activePricelists,
    memoPricelists,
  ]);

  return {
    products,
    productsDict,
    total,
    loading,
    // Trick the hook into thinking the options have changed and thus a refetch is necessary
    refresh: () => setMemoOptions(null),
  };
};

export default useProducts;
