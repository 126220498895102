import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { reactcursively } from 'utils/react';

import {
  getGivexConfiguration,
  getIsAllowed,
} from '../configuration/Configuration';

const OriginalPayment: PosPlugin['UIOriginalPayment'] = ({
  payment,
  onClick,
  children,
}) => {
  const isAllowed = useSelector(getIsAllowed);
  const isGivexPayment = payment.paymentIntegration === 'givex';
  const { displayName } = useSelector(getGivexConfiguration);

  return reactcursively(
    R.pipe(
      R.when(
        R.pathEq(['props', 'data-testid'], 'payment-item-container'),
        R.modify('props', props => {
          const disabled = isGivexPayment ? !isAllowed : props.disabled;
          return {
            ...props,
            disabled,
            onClick: !disabled ? onClick : undefined,
            style: { cursor: disabled ? 'not-allowed' : 'pointer' },
          };
        }),
      ),
      R.when(
        R.both(
          R.pathEq(['props', 'data-testid'], 'payment-type'),
          () => payment.cardType === 'GIVEX',
        ),
        R.assocPath(['props', 'children'], displayName),
      ),
    ),
  )(children);
};

export default OriginalPayment;
