const INIT_STATE = null;
const INIT_ACTION_TYPE = '@@INIT';

function reduceStart(state) {
  return state + 1;
}
function reduceFinish(state) {
  return state - 1;
}
function reduce(state, set) {
  return set ? reduceStart(state) : reduceFinish(state);
}
function createReducer(
  onTypes: string[],
  offTypes: string[],
  resetOnLoad = false,
) {
  return (state = INIT_STATE, { type }) => {
    if (resetOnLoad && type === INIT_ACTION_TYPE) return INIT_STATE;
    if (onTypes.includes(type)) return reduceStart(state);
    if (offTypes.includes(type)) return reduceFinish(state);
    return state;
  };
}
function getIsLoading(state = 0) {
  return state > 0;
}
function getIsInitState(state) {
  return Object.is(INIT_STATE, state);
}

export default {
  reduce,
  reduceStart,
  reduceFinish,
  createReducer,
  getIsLoading,
  getIsInitState,
  initState: INIT_STATE,
};
