import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { proxy } from 'services/shared';

const DefaultComponent = () => <div>Document</div>;

/**
 * @param {NodeListOf<HTMLImageElement>} images
 * @returns {Promise<unknown[]>}
 */
async function waitForImagesToLoad(images) {
  const filtered = Array.prototype.map.call(images, item => {
    if (item.src === '') return Promise.resolve();
    const img = new window.Image();
    img.src = item.src;
    return new Promise(resolve => {
      img.addEventListener('load', resolve);
      img.addEventListener('error', resolve);
      if (img.complete) resolve();
    });
  });
  return Promise.all(filtered);
}

export const print = async ({
  title = 'Print Document',
  html = 'Document',
  fullHtml = '',
  url = '',
} = {}) => {
  try {
    if (url && !url.includes('undefined')) {
      // eslint-disable-next-line no-param-reassign
      fullHtml = await window.fetch(proxy + url).then(res => res.text());
    }

    const printPreview = window.document.getElementById('printPreview');
    // Make printPreview visible before printing so that the iframe renders and browser loads fonts for the iframe
    // If fonts are not preloaded then no text will be printed on AR template receipts
    printPreview.style.setProperty('display', 'block');

    const iframe = window.document.createElement('iframe');
    iframe.srcdoc =
      fullHtml ||
      `<html><head><title>${title}</title>
        </head><body>${html}</body></html>`;

    printPreview.insertBefore(iframe, printPreview.firstChild);

    const imagesFromIframe = iframe.contentWindow.document.querySelectorAll(
      'img',
    );
    await waitForImagesToLoad(imagesFromIframe);
    await new Promise(res => setTimeout(res, 300));

    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    printPreview.style.removeProperty('display');
    // Remove the iframe because it might be included in browser printout of patchscript
    printPreview.removeChild(iframe);
  } catch (err) {
    console.error(
      'Failed to print receipt',
      { html, fullHtml, url, title },
      err,
    );
    return false;
  }
  return true;
};

export const printFromTemplate = async ({
  Template = DefaultComponent,
  title = 'Print Document',
}) => {
  const html = renderToStaticMarkup(Template);

  print({
    html,
    title,
  });
};

export default ({
  url,
  html = renderToStaticMarkup(<DefaultComponent />),
  title = 'Print Document',
  children,
}) => {
  return <span onClick={() => print({ url, title, html })}>{children}</span>;
};
