/* eslint-disable @typescript-eslint/camelcase */

export const defineTestParams = (params: {
  phone: string;
  email?: string;
  projectID: string;
  serviceID: string;
}) => {
  const { phone, email, projectID, serviceID } = params;
  const testParams: any = {
    project_id: projectID,
    service_id: serviceID,
    to_number: phone,
    start_time_offset: 0,
    message_type: 'service',
    vars: {
      phone,
      receipt: 'receipt_placeholder',
      amount: 'amount_placeholder',
      date: '2020.07.21',
      name1: 'Test product',
      netRowPrice1: '6.66',
      netUnitPrice1: '6.66',
      quantity1: '1',
      rowPrice1: '7.99',
      unitPrice1: '7.99',
    },
  };

  if (email && email !== '') {
    testParams.vars.email = email;
  }

  return testParams;
};

export const getRowsData = rows => {
  return rows.reduce(
    (
      target,
      { name, amount, basePrice, basePriceWithVAT, rowNetTotal, rowTotal },
      index,
    ) => {
      return {
        ...target,
        [`name${index}`]: name,
        [`quantity${index}`]: `${amount}`,
        [`netUnitPrice${index}`]: Number(basePrice).toFixed(2),
        [`unitPrice${index}`]: Number(basePriceWithVAT).toFixed(2),
        [`netRowPrice${index}`]: Number(rowNetTotal).toFixed(2),
        [`rowPrice${index}`]: Number(rowTotal).toFixed(2),
      };
    },
    {},
  );
};

export const formatDate = () => {
  const d = new Date();
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};
