import { PosPlugin } from 'plugins/plugin';

import { meta, components } from './constants';
import { reducer } from './redux';
import {
  UIProductGroups,
  UIProducts,
  UICol1,
  UICol2,
  UITableBill,
  UICustomPayButton,
} from './components';
import {
  resetState,
  addSAINumberAttribute,
  setSAINumberToSavedDocument,
  setSAINumberToReturnDocument,
  preventScanIfNoSAINumber,
  preventAddProductIfNoSAINumber,
} from './pluginHooks';
import { getSaleOptionButtons } from './selectorOverrides';

const saiNumberCapture: PosPlugin = {
  ...meta,
  // casting needed, because reduxReducer type is not valid
  reduxReducer: reducer as PosPlugin['reduxReducer'],
  UIProductGroups,
  UIProducts,
  UICol1,
  UICol2,
  UITableBill,
  UICustomPayButton,
  components,
  onStartNewSale: {
    after: resetState,
  },
  onRemoveOrder: {
    after: resetState,
  },
  onAddProduct: {
    before: preventAddProductIfNoSAINumber,
  },
  onSaveSalesDocument: {
    on: addSAINumberAttribute,
    after: resetState,
  },
  onPickupPendingSale: {
    on: setSAINumberToSavedDocument,
  },
  onPickupOrder: {
    on: setSAINumberToSavedDocument,
  },
  onPickupLayaway: {
    on: setSAINumberToSavedDocument,
  },
  onPickupOffer: {
    on: setSAINumberToSavedDocument,
  },
  onPickupInvoice: {
    on: setSAINumberToSavedDocument,
  },
  onAddReturnProducts: {
    on: setSAINumberToReturnDocument,
  },
  onScan: {
    before: preventScanIfNoSAINumber,
  },
  selectorOverrides: {
    getSaleOptionButtons,
  },
};

export default saiNumberCapture;
