import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, makeStyles } from '@material-ui/core';
import * as R from 'ramda';

import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { Address } from 'types/Address';
import { Customer } from 'types/Customer';

import AddressForm from './AddressForm';
import { getSelectedCustomerID } from 'reducers/customerSearch';

const useStyles = makeStyles({
  labelContainer: {
    height: '68px',
    '& div:has(> #address-label)': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

const MultiAddressInput: FC<{
  onChange: (func: (prevAddresses: Address[]) => Address[]) => void;
  customer: Customer;
}> = ({ onChange, customer }) => {
  const { t } = useTranslation('createCustomer');
  const styles = useStyles();
  const dispatch = useDispatch();
  const selectedCustomerID = useSelector(getSelectedCustomerID);
  const { addresses = [{}] as Address[] } = customer;
  return (
    <Grid item container spacing={2}>
      <Grid
        item
        container
        justify="space-between"
        alignContent="center"
        className={styles.labelContainer}
      >
        <Grid item>
          <strong id="address-label" data-testid="address-label">
            {t('address.title')}
          </strong>
        </Grid>

        {customer.id === selectedCustomerID && (
          <Grid item>
            <Button
              variant="contained"
              disableElevation
              onClick={() =>
                dispatch(
                  openModalPage({
                    component: mp.extraAddress,
                    props: {
                      updateAddresses: onChange,
                      customer,
                    },
                  }),
                )
              }
            >
              {t('address.other')}
            </Button>
          </Grid>
        )}
      </Grid>
      <AddressForm
        address={addresses[0]}
        onChange={(k, v) =>
          onChange(prev =>
            prev?.length ? R.adjust(0, R.assoc(k, v), prev) : [{ [k]: v }],
          )
        }
      />
    </Grid>
  );
};

export default MultiAddressInput;
