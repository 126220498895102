export const styles = {
  container: {
    marginTop: '70px',
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    borderRadius: '8px',
    width: '464px',
    maxWidth: '100%',
  },
  header: {
    fontWeight: 700,
    fontSize: '24px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    height: '62px',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    margin: '8px 8px',
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '14px',
    padding: '12px',
    marginTop: 8,
    width: '100%',
  },
  version: {
    position: 'absolute',
    right: '0.5ch',
    bottom: 0,
    fontSize: '13px',
    color: '#6f6f6f',
  },
  close: {
    fontSize: '42px',
  },
  remark: {
    fontWeight: 700,
    display: 'flex',
    flexGrow: 1,
    margin: '16px',
  },
};
