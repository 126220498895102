import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePosOnMessage, usePosSendMessage } from 'utils/hooks/useWrapper';
import Loader from 'components/Loader';
import UIButton from 'components/UIElements/UIButton';
import { Select, Text } from 'containers/Forms/Settings/components/CtxInputs';

export const useWrapperFields = (setMessage, getMessage) => {
  const [fields, setFields] = useState([
    { type: 'default', title: 'wrapper.notDetected', disabled: true },
  ]);
  usePosOnMessage(
    setMessage,
    useCallback(
      ({ fields }) => {
        setFields(fields);
      },
      [setFields],
    ),
  );
  const requestFields = usePosSendMessage(getMessage);
  useEffect(requestFields, []);
  return fields;
};

export const WrapperForm = ({
  value,
  onChange: outerOnChange,
  setValue: outerSetValue,
  setMessage,
  getMessage,
  updateMessage,
}) => {
  const { t } = useTranslation('settingsPrinter');
  const update = usePosSendMessage(updateMessage);
  const onChange = (k, v) => {
    outerOnChange(k, v);
  };
  useEffect(() => {
    update(value);
  }, [value]);
  const fields = useWrapperFields(setMessage, getMessage);
  usePosOnMessage(updateMessage, outerSetValue);

  const defaultValues = useMemo(
    () =>
      Object.fromEntries(
        fields
          .map(f => [f.name, f.defaultValue])
          .filter(([, def]) => def !== undefined),
      ),
    [fields],
  );
  const values = useMemo(
    () => ({
      ...defaultValues,
      ...value,
    }),
    [defaultValues, value],
  );
  const fieldsWithKeys = useMemo(
    () => fields.map(f => ({ ...f, key: Math.random() })),
    [fields],
  );

  return fieldsWithKeys.map(({ type, name, title, key, ...rest }) => {
    switch (type) {
      case 'paragraph':
        return <p key={key}>{title}</p>;
      case 'header':
        return <h3 key={key}>{title}</h3>;
      case 'default':
        return <h3 key={key}>{t(title)}</h3>;
      case 'loader':
        return (
          <Loader
            style={{ height: '100px' }}
            show={true}
            block
            loadingText={title}
            key={key}
          />
        );
      case 'button':
        return (
          <UIButton
            key={key}
            text={title}
            action={() =>
              window.wrapperMessageChannel.port1.postMessage({
                type: 'config:pressButton',
                payload: name,
              })
            }
            {...rest}
          />
        );
      case 'select':
        return (
          <Select
            key={key}
            title={title}
            type={type}
            name={name}
            value={values[name]}
            onChange={onChange}
            {...rest}
            options={rest.options}
          />
        );
      default:
        return (
          <Text
            key={key}
            title={title}
            type={type}
            name={name}
            onChange={onChange}
            value={values[name]}
            {...rest}
          />
        );
    }
  });
};
