import { PosPlugin } from 'plugins/plugin';
import { getCurrentSalesDocument } from 'reducers/sales';
import { addWarning } from 'actions/Error';

import { EA_SALE } from '../constants';

export const beforeUpdateOrderAmount: Required<
  PosPlugin
>['onUpdateOrderAmount']['before'] = p => async (dispatch, getState) => {
  const { type } = getCurrentSalesDocument(getState());

  if (p.amount > 1 && type === EA_SALE) {
    const errorMessage =
      'Endless aisle sale can have only 1 product with 1 quantity';
    dispatch(addWarning(errorMessage));
    throw new Error(errorMessage);
  }
};
