/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Box, Popover, TableCell, Typography, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useSearchResultStyles } from 'containers/Col1/Search/index.styles';
import CloseButton from 'components/CustomButtons/CloseButton';
import { Customer } from 'types/Customer';

import { getCustomerName } from './util';

const StyledText = styled(Typography)({
  'text-wrap': 'nowrap',
  'text-overflow': 'ellipsis',
  'max-width': '20ch',
  overflow: 'hidden',
  'max-height': '6em',
});

export const Namerow: React.FC<{
  customer: Customer;
  companyName?: string;
  location?: React.ReactNode;
}> = ({ customer, companyName, location }) => {
  const classes = useSearchResultStyles();
  const { t } = useTranslation('search');

  return (
    <TableCell
      component="th"
      scope="product"
      className={classes.name}
      key={customer.customerID}
    >
      <Typography
        data-testid="customer-name"
        data-test-key={customer.customerID}
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: customer.companyID ? '1em' : 0,
        }}
      >
        <span>{getCustomerName(customer)}</span>
        {customer.companyID ? (
          <span>
            <em>{companyName ?? t('customers.companyNotFound')}</em>
          </span>
        ) : null}
        {location ? (
          <span>
            <em>{location}</em>
          </span>
        ) : null}
      </Typography>
    </TableCell>
  );
};

export const CardNrrow = ({ customer }: { customer: Customer }) => {
  const classes = useSearchResultStyles();

  return (
    <TableCell className={classes.cardNumber}>
      <Typography
        data-testid="customer-cardnumber"
        data-test-key={customer.customerID}
      >
        {customer.customerCardNumber}
      </Typography>
    </TableCell>
  );
};

export const GenericRow = ({
  customer,
  fieldName,
}: {
  customer: Customer;
  fieldName: keyof Customer;
}) => {
  const classes = useSearchResultStyles();
  return (
    <TableCell className={classes.generic}>
      <Typography
        data-testid={`customer-${fieldName}`}
        data-test-key={customer.customerID}
      >
        {customer[fieldName] ?? ''}
      </Typography>
    </TableCell>
  );
};

export const AddressComponent = ({ customer }: { customer: Customer }) => {
  const addresses = customer.addresses ?? [];
  const classes = useSearchResultStyles();

  const { t } = useTranslation('search', { keyPrefix: 'customers' });

  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (addresses.length === 0) {
    return <TableCell className={classes.generic} />;
  }

  const hasLongEnoughAddresses = addresses.some(
    ({ address }) => address.length > 28,
  );

  return (
    <TableCell>
      {addresses.slice(0, 3).map(({ address, addressID }, index) => (
        <StyledText key={String(addressID)}>
          {index + 1}) {address}
        </StyledText>
      ))}
      {hasLongEnoughAddresses || addresses.length > 3 ? (
        <>
          <a href="#" onClick={handleClick}>
            {t('addresses.showFullAddresses')}
          </a>
          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
              style: { padding: '1rem' },
            }}
          >
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6">{t('addresses.all')}</Typography>
                <CloseButton
                  style={{ scale: 0.85 }}
                  action={e => {
                    e.stopPropagation();
                    setAnchorEl(null);
                  }}
                />
              </Box>
              {addresses.map(({ address, addressID }, index) => (
                <Typography variant="body1" key={String(addressID)}>
                  {index + 1}) {address}
                </Typography>
              ))}
            </Box>
          </Popover>
        </>
      ) : null}
    </TableCell>
  );
};
