import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton, withStyles } from '@material-ui/core';

import Icon from 'components/Icon';

import './HeaderIcon.scss';

export const HeaderIconButton = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
  },
}))(IconButton);

/**

 Icon that goes into the POS-header, specific to the Header layout.
 Limited to icon classes having "`icon_`" prefix. Recommended use as a child of a list item.
 Inherits the current parent color, changes to `#999` (grey) on hover.

 Rendered as an icon `<i>` DOM element.
 Optionally can set to be enclosed within an anchor tag `<a>` with corresponding attributes.

 */

const HeaderIcon = ({
  isLink = false,
  className,
  href,
  post = undefined,
  icon,
  onClick,
  style,
  target = '_blank',
  ...props
}) => {
  if (isLink) {
    const open = e => {
      e.preventDefault();
      const form = e.target.closest('form');
      form.target = target;
      form.submit();
    };
    return (
      <form action={href} method={post ? 'post' : 'get'}>
        {post
          ? Object.entries(post).map(([k, v]) => (
              <input type="hidden" name={k} key={k} value={v} {...props} />
            ))
          : null}
        <HeaderIconButton
          size="small"
          component="a"
          href={href}
          onClick={open}
          onAuxClick={open}
          target={target}
        >
          <Icon
            className={classNames(className, 'erply-header__icon')}
            name={`icon_${icon}`}
            action={() => undefined}
            style={{ ...style, color: 'white' }}
            {...props}
          />
        </HeaderIconButton>
      </form>
    );
  }
  return (
    <HeaderIconButton size="small" onClick={onClick}>
      <Icon
        className={classNames(className, 'erply-header__icon')}
        name={`icon_${icon}`}
        style={{ ...style }}
        {...props}
      />
    </HeaderIconButton>
  );
};

HeaderIcon.propTypes = {
  /** The type of icon you want it to be. Sets a mandatory 'icon_' prefix that is continued with the remainder of the icon class (e.g. 'tools') */
  icon: propTypes.string,
  /** Sets the title attribute to show tooltips  when hovering over with a mouse */
  title: propTypes.string,
  /** Any functions you want to invoke upon clicking */
  onClick: propTypes.func,
  /** Pass any other elements within the component */
  children: propTypes.object,
  /** Set to true if you want this to be wrapped in an anchor tag (used for linking to urls) */
  isLink: propTypes.bool,
  /** ONLY isLink===true ! Whatever internal/external url it may be */
  href: propTypes.string,
  /** ONLY isLink===true ! Recommended for external urls (pass '_blank' to open in a new tab) */
  target: propTypes.string,
  /** ONLY isLink===true ! Anchor relation. */
  rel: propTypes.string,
  /** Additional classes that you would want to pass to the icon. Is passed to the anchor if isLink is set to true */
  className: propTypes.string,
};

export default HeaderIcon;
