import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getProductGroupsTreeOrdered } from 'reducers/productGroupsDB';
import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';

const HideProducts: PosPlugin = {
  id: 'pnp-hide-products',
  name: '[PNP] - Hide products',
  keywords: ['pnp', 'hide', 'products'],
  info:
    'Items belonging to the product groups selected in this configuration menu will not be displayed in the search results but they will be able to be selected if the product code is input in the search field and then the Enter key is pressed.',
  ComponentConfiguration: ({ current = { selectedGroups: [] }, save }) => {
    const [selectedGroups, setSelectedGroups] = useState<number[]>([
      ...current.selectedGroups,
    ]);

    const allGroups: { name; value }[] = useSelector(
      getProductGroupsTreeOrdered,
    );

    return (
      <div>
        <h4>Configure [PNP] hide groups from product search results</h4>
        <p>Product groups to hide from product search results at the POS:</p>
        {allGroups.map(group => (
          <InputField
            type="checkbox"
            value={selectedGroups.includes(group.value)}
            onChange={() => {
              if (selectedGroups.includes(group.value)) {
                const updatedGroups = selectedGroups.filter(
                  g => g !== group.value,
                );
                return setSelectedGroups([...updatedGroups]);
              }

              return setSelectedGroups([...selectedGroups, group.value]);
            }}
          >
            {group.name}
          </InputField>
        ))}
        <UIButton
          variant="POS"
          type="button"
          text="Save"
          action={() => save({ selectedGroups })}
        />
      </div>
    );
  },
  UIProductResultTable: ({ products, children }) => {
    const config = useSelector(
      getPluginConfiguration<any>('pnp-hide-products'),
    );
    const filteredProducts = useMemo(
      () =>
        products.filter(
          product => !config?.selectedGroups?.includes(product.groupID),
        ),
      [products],
    );
    const child = (Array.isArray(children) ? children[0] : children) || null;
    return (
      child &&
      children &&
      // @ts-ignore
      React.cloneElement(child, { products: filteredProducts })
    );
  },
};

export default HideProducts;
