export type PaymentObj = {
  key: string;
  amount: string;
  typeID: string;
  vatrateID: string;
  giftCardBalance: string;
  added: string;
  cardHolder: string;
  giftCardID: string;
  giftCardTypeID?: number | null;
  serial: string;
  cardType?: string;
  cardNumber?: string;
  signature?: string;
  // Currency since this is what MS expects
  currency?: string;
  currencyCode: string;
  shouldProcess?: boolean;
  paid?: boolean;
  aid?: string;
  applicationLabel?: string;
  pinStatement?: string;
  cryptogramType?: string;
  cryptogram?: string;
  transactionId?: string;
  type: string;
  attributes?: {
    refNo?: string;
    authCode?: string;
    [other: string]: any;
  };
};

export type CardPaymentHooks = {
  displayWarning: (arg: string) => void;
  displayError: (arg: string) => void;
  displaySuccess: (arg: string) => void;
  updateMessage: (arg: string) => void;
  enabledButtons: string[];
  enableButtons: (arg: string[]) => void;
  resolvePayments: () => void;
  rejectPayments: () => void;
  setTitle: (arg: string) => void;
  cardPayments: any[];
  isCurrentSaleAReturn: boolean;
  isPartialReturn: boolean;
  beforeDocSave: (arg: any) => Promise<void>;
  beforeDocDelete: (paymentKey: string) => Promise<void>;
  formatCurrency: (arg: number) => void;
  currencyCode: string;
};

export const FUNC_BUTTONS = {
  RETRY: 'retry',
  VOID: 'void',
  CANCEL: 'cancel',
  REPRINT: 'reprint',
  RETURN_BACK: 'return-back',
  CHECK_STATUS: 'check-status',
  TERMINAL_DIAGNOSIS: 'terminal-diagnosis',
  CLOSE: 'close',
};
