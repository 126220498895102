export const salesTenders = [
  { transKey: 'cash', configName: 'cash' },
  { transKey: 'card', configName: 'card' },
  { transKey: 'giftcardSerial', configName: 'giftcard_serial' },
  { transKey: 'giftcardRegular', configName: 'giftcard_regular' },
  { transKey: 'storecredit', configName: 'storecredit' },
  { transKey: 'check', configName: 'check' },
  { transKey: 'tip', configName: 'tip' },
];

export const returnTenders = [
  { transKey: 'cash', configName: 'cash' },
  { transKey: 'card', configName: 'card' },
  { transKey: 'giftcardSerial', configName: 'giftcard_serial' },
  { transKey: 'giftcardRegular', configName: 'giftcard_regular' },
  { transKey: 'storecredit', configName: 'storecredit' },
  { transKey: 'check', configName: 'check' },
  // tip is excluded
];
