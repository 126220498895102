/* eslint-disable no-unused-expressions */
import React, { useRef, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import classNames from 'classnames';

const NormalInput = ({
  title,
  prependTitle,
  appendTitle,
  size,
  isValid,
  isInvalid,
  disabled,
  value,
  onChange,
  type,
  skin,
  onFocus,
  onBlur,
  className,
  errorText,
  ...rest
}) => {
  const input = useRef();
  const [hasFocus, setFocus] = useState(false);
  return (
    <div
      className={classNames(className, {
        focused: hasFocus,
        valid: isValid,
        invalid: isInvalid,
      })}
    >
      <InputGroup
        size={size}
        style={skin.InputGroup}
        onClick={() => input.current && input.current.focus()}
      >
        {title ? (
          <InputGroup.Prepend style={skin.Prepend}>
            <InputGroup.Text style={skin.PrependText}>{title}</InputGroup.Text>
          </InputGroup.Prepend>
        ) : null}
        {prependTitle ? (
          <InputGroup.Prepend style={skin.Prepend}>
            {prependTitle}
          </InputGroup.Prepend>
        ) : null}
        <FormControl
          ref={input}
          style={{
            height: 'auto',
            ...skin.Input,
            cursor: disabled ? 'not-allowed' : null,
          }}
          {...{
            ...rest,
            type,
            isValid,
            isInvalid,
            value,
            onChange,
            disabled,
            onFocus: () => {
              onFocus && onFocus();
              setFocus(true);
            },
            onBlur: () => {
              onBlur && onBlur();
              setFocus(false);
            },
          }}
        />
        {appendTitle ? (
          <InputGroup.Append style={skin.Append}>
            {appendTitle.substr ? (
              <InputGroup.Text style={skin.AppendText}>
                {appendTitle}
              </InputGroup.Text>
            ) : (
              appendTitle
            )}
          </InputGroup.Append>
        ) : null}
      </InputGroup>
      {errorText?.length > 0 ? (
        <FormControl.Feedback
          type="invalid"
          style={{ minHeight: '1em', display: 'block', marginBottom: '-1em' }}
        >
          {errorText}
        </FormControl.Feedback>
      ) : null}
    </div>
  );
};
export default NormalInput;
