import React, { FC, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import SaveButton from 'components/CustomButtons/SaveButton';
import InputField from 'components/FieldTypes/InputField';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getProductsUniversal, saveProduct } from 'actions/productsDB';
import { addSuccess, addWarning } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { getVatRatesFetching } from 'reducers/vatRatesDB';
import { VatRate } from 'types/VatRate';
import { Product } from 'types/Product';

export const ProductCreation: FC<{
  productGroupsSelection: {
    name: string;
    value: string;
  }[];
  onChange: (name: string, value: Product[keyof Product]) => void;
  productData: {
    code: string;
    name: string;
    groupID: string | number;
    netPrice: number;
    priceWithVAT: number;
    vatrateID: string;
  };
  vatRates: VatRate[];
  vatRatesSelection: {
    name: string;
    value: string;
  }[];
}> = ({
  productData,
  productGroupsSelection,
  onChange,
  vatRates,
  vatRatesSelection = [],
}) => {
  const { t } = useTranslation('scanSearchResults');
  const dispatch = useDispatch() as ThunkDispatch<any, any, Action>;

  const handleChange = e => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const vatRatesAreFetching = useSelector(getVatRatesFetching);

  useEffect(() => {
    if (!vatRatesAreFetching && !vatRates.length) {
      dispatch(addWarning(t('warning.noVatRates')));
      dispatch(previousModalPage());
    }
  }, [addWarning, dispatch, vatRatesAreFetching]);

  const handleSubmit = async () => {
    const hadError = await dispatch(saveProduct(productData));
    if (!hadError) {
      dispatch(addSuccess(t('warnings.productCreated')));
      const { products: prods } = await dispatch(
        getProductsUniversal({
          name: productData.name,
          code: productData.code,
          active: 1,
        }),
      );
      dispatch(previousModalPage());
      dispatch(addProduct({ productID: prods[0].productID }));
    }
  };

  useShortcut('Enter', () => handleSubmit(), 30);

  return (
    <>
      <Form onSubmit={() => handleSubmit()}>
        <Form.Group>
          <InputField
            isRequired={true}
            size="lg"
            title={t('inputs.product.name')}
            type="text"
            name="name"
            onChange={e => handleChange(e)}
            value={productData.name}
          />
        </Form.Group>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.product.code')}
            name="code"
            type="text"
            onChange={e => handleChange(e)}
            value={productData.code}
          />
        </Form.Group>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.product.group')}
            name="groupID"
            type="select"
            options={productGroupsSelection}
            value={productData.groupID}
            onChange={e => handleChange(e)}
          />
        </Form.Group>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.product.vatRate')}
            name="vatrateID"
            type="select"
            options={vatRatesSelection}
            value={productData.vatrateID}
            onChange={e => handleChange(e)}
          />
        </Form.Group>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.product.netPrice')}
            name="netPrice"
            type="text"
            value={productData.netPrice}
            onChange={e => handleChange(e)}
          />
        </Form.Group>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.product.priceWithVAT')}
            name="priceWithVAT"
            type="text"
            value={productData.priceWithVAT}
            onChange={e => handleChange(e)}
          />
        </Form.Group>
        <SaveButton action={handleSubmit} title={t('buttons.save')} disabled={vatRatesAreFetching} loading={vatRatesAreFetching}/>
      </Form>
    </>
  );
};
