import React from 'react';
import * as R from 'ramda';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PosPlugin } from 'plugins/plugin';
import { modals } from 'plugins/mm/constants';
import { openPluginModalPage } from 'actions/modalPage';
import { reactcursively } from 'utils/react';
import MuiButton from 'components/CustomButtons/MuiButton';

import CountrySelect from './CountrySelect';
import { countries } from './constants';

type Children = ReturnType<typeof React.Children.toArray>;

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.down('sm')]: {
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  [theme.breakpoints.up('sm')]: {
    buttonContainer: {
      position: 'relative',
      width: '100%',
      height: 0,
    },
    button: {
      position: 'absolute',
      top: '-52px',
      left: '40%',
    },
  },
}));

const setFieldLabel = (fieldName: string, label: string) =>
  R.when(
    R.both(
      R.hasPath(['props', 'label']),
      R.pathEq(['props', 'name'], fieldName),
    ),
    R.set(R.lensPath(['props', 'label']), label),
  );

/**
 * Address search button should appear both in Customer form and in Other addresses.
 * Subtitle + extra buttons are not included in the AddressFormBeta, so to avoid
 * adding multiple new plugin components, address search button is inserted into
 * AddressFormBeta and positioned between subtitle and extra buttons using css.
 */
const WithAddressSearchButton: Required<PosPlugin>['UIAddressFormBeta'] = ({
  address,
  onChange,
  children,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('addressForm');

  const addAddressSearchButton = R.prepend(
    <div className={styles.buttonContainer}>
      <MuiButton
        color="UIButton"
        variant="contained"
        disableElevation
        className={styles.button}
        onClick={() =>
          dispatch(
            openPluginModalPage(modals.ADDRESS_SEARCH)({
              isPopup: true,
              props: { onChange },
            }),
          )
        }
      >
        {t('lookUpAddress.title')}
      </MuiButton>
    </div>,
  );

  const setCustomTranslations = reactcursively(
    R.pipe(
      setFieldLabel(
        'postalCode',
        t('placeholders.postalCode', { context: 'Canada' }),
      ),
      setFieldLabel('state', t('placeholders.state', { context: 'Canada' })),
    ),
  );

  const disableAddressFields: (children: Children) => Children = R.map(
    R.over(
      R.lensPath(['props', 'children']),
      R.map(
        R.when(item => {
          const path = R.path(['props', 'name'], item);
          return !['country', 'addressTypeID'].includes(path);
        }, R.assocPath(['props', 'children', 'props', 'disabled'], true)),
      ),
    ),
  );

  const replaceCountryInput: (children: Children) => Children = R.map(
    R.over(
      R.lensPath(['props', 'children']),
      R.map(
        R.when(
          R.pathEq(['props', 'name'], 'country'),
          R.always(
            <CountrySelect
              country={
                address?.country === 'United States'
                  ? countries.USA
                  : address?.country
              }
              handleChange={onChange}
            />,
          ),
        ),
      ),
    ),
  );

  const withCustomTranslations: (children: Children) => Children = R.when(
    () => address?.country === 'Canada',
    setCustomTranslations,
  );

  return R.pipe(
    React.Children.toArray,
    disableAddressFields,
    withCustomTranslations,
    addAddressSearchButton,
    replaceCountryInput,
  )(children);
};

export default WithAddressSearchButton;
