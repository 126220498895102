import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import InputField from 'components/FieldTypes/InputField';
import { loginInput } from 'components/FieldTypes/skins/skins.module.scss';
import { getClientCode } from 'reducers/Login';
import { loginWithPass, loginWithPin } from 'services/ErplyAPI/api';
import { addError, addWarning } from 'actions/Error';
import Loader from 'components/Loader';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import Icon from 'components/Icon';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { User } from 'types/User';
import { Reason } from 'containers/Forms/ManagerOverride/types';

const ManagerOverride = ({
  reasons,
  resolve,
  reject,
}: {
  reasons: Reason[];
  resolve: (overrides: { [key: string]: User }) => void;
  reject: (e) => void;
}) => {
  // Reason
  const [overridden, setOverridden] = useState<{ [key: string]: User }>({});
  const remainingReasons = useMemo(
    () => reasons.filter(r => !overridden[r.key]),
    [reasons, overridden],
  );

  // Form
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [pin, setPin] = useState('');
  const [usePin, setUsePin] = useState(false);
  const [loading, setLoading] = useState(false);

  // Misc
  const clientCode = useSelector(getClientCode);
  const { t } = useTranslation('managerOverride');
  const dispatch = useDispatch();

  const submit = () => {
    if (loading) return;
    setLoading(true);
    const login = usePin
      ? loginWithPin({ cardCode: pin, clientCode })
      : loginWithPass({
          clientCode,
          username,
          password,
        });
    login
      .then(([user]) =>
        Promise.all(
          remainingReasons.map(({ key, text, isPermitted }) =>
            isPermitted(user).then(isPermitted => {
              if (isPermitted) {
                setOverridden(c => ({ ...c, [key]: user }));
              } else if (text) {
                // If attempted user lacks the right, notify the cashier
                dispatch(addWarning(text));
              }
            }),
          ),
        ),
      )
      .catch(err => {
        dispatch(addError(err.message));
      })
      .finally(() => setLoading(false));
  };
  const cancel = () => {
    dispatch(previousModalPage({ component: mp.managerOverride }));
    reject(new Error('User cancelled override'));
  };

  // Autoresolve when all reasons covered
  useEffect(() => {
    if (remainingReasons.length === 0) {
      dispatch(previousModalPage({ component: mp.managerOverride }));
      resolve(overridden);
    }
  }, [remainingReasons.length]);

  useShortcut('Enter', submit, 30);
  return (
    <Loader show={loading}>
      <Modal.Header>
        <Modal.Title>{t('title')}</Modal.Title>
        <CloseButton action={cancel} />
      </Modal.Header>

      <Modal.Body>
        <div />
        {reasons.map(({ key, text }) => (
          <div key={key}>
            {text}
            <Icon
              name={overridden[key] ? 'icon_check' : 'icon_cross'}
              style={{
                color: overridden[key] ? 'lime' : 'gray',
                opacity: overridden[key] ? 1 : 0.5,
              }}
            />
            {overridden[key]?.employeeName}
          </div>
        ))}

        <div style={{ display: 'flex' }}>
          <span style={{ flexGrow: 1 }} />
          <Icon
            action={() => setUsePin(c => !c)}
            name={usePin ? 'icon_grid-3x3' : 'icon_menu'}
          />
        </div>
        {usePin ? (
          <InputField
            autoFocus
            title={t('inputs.pin')}
            className={loginInput}
            value={pin}
            onChange={e => setPin(e.target.value)}
            type="password"
          />
        ) : (
          <>
            <InputField
              autoFocus
              title={t('inputs.username')}
              className={loginInput}
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <InputField
              title={t('inputs.password')}
              className={loginInput}
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
            />
          </>
        )}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={submit}
            disabled={loading}
            style={{ flexGrow: 1 }}
            size="lg"
            variant="primary"
          >
            {t('buttons.submit', { count: remainingReasons.length })}
          </Button>
        </div>
      </Modal.Body>
    </Loader>
  );
};
export default ManagerOverride;
