/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button } from '@material-ui/core';

import { round } from 'utils';
import Loader from 'components/Loader';
import WBUAPI, { groupedSale, groupSalesHistory } from 'plugins/wbu/API/API';
import { getShowPricesWithTax } from 'reducers/configs/settings';
import { RootState } from 'reducers';
import { addError } from 'actions/Error';

import styles from '../index.module.scss';

type Props = {
  customerID: string | number;
};

const getCustomerSalesHistory = ({
  customerID,
  offset,
  count,
}: {
  customerID: number;
  offset: number;
  count?: number;
}) => async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
  if (customerID === undefined) throw new Error('No customer passed in');

  const { data } = await dispatch(
    WBUAPI.getRecentSales({ customerID, offset, count }),
  ).catch(err => {
    throw new Error(`${err?.message ?? 'Failed to fetch recent sales'}`);
  });
  return Object.entries(data)
    .map(([, value]) => value)
    .flatMap(a => a);
};

const Sales: FC<Props> = ({ customerID }) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const count = 15;
  const [showMore, setShowMore] = useState(true);
  const [sales, setSales] = useState<groupedSale[]>([]);
  const [loading, setLoading] = useState(false);
  const loadNext = useCallback(async () => {
    setLoading(true);
    dispatch(
      getCustomerSalesHistory({
        customerID: Number(customerID),
        offset: sales.length,
      }),
    )
      .then(newSales => {
        const groupedNewSales = groupSalesHistory(newSales ?? []);
        setSales(sales => [...sales, ...groupedNewSales]);
        if (groupedNewSales.length < count) {
          setShowMore(false);
        } else {
          setShowMore(true);
        }
      })
      .catch(err => {
        dispatch(addError(err?.message ?? 'Failed to get sales'));
      })
      .finally(() => setLoading(false));
  }, [sales]);

  // Load initial amount of sales
  useEffect(() => {
    loadNext();
  }, []);

  const priceWithTax = useSelector(getShowPricesWithTax);

  return (
    <Loader block withPadding loadingText="Fetching sales" show={loading}>
      <Table responsive className={styles.salesHistory} size="sm">
        <thead>
          <tr>
            <th colSpan={2}>Date</th>
            <th colSpan={2}>Number</th>
          </tr>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Qty</th>
            <th>Sum</th>
          </tr>
        </thead>

        <tbody>
          {sales.map(({ date_time, sales_document_number, rows }) => (
            <>
              <tr className={styles.header}>
                <th colSpan={2}>{sales_document_number}</th>
                <th colSpan={2}>{date_time}</th>
              </tr>
              {rows.map(
                ({
                  row_id,
                  code,
                  product_name,
                  amount,
                  row_total_with_vat,
                  row_net_total,
                }) => (
                  <tr key={row_id}>
                    <td>{code}</td>
                    <td>{product_name}</td>
                    <td>{round(amount)}</td>
                    <td>
                      {round(priceWithTax ? row_total_with_vat : row_net_total)}
                    </td>
                  </tr>
                ),
              )}
            </>
          ))}
        </tbody>
        {showMore ? (
          <Button
            style={{ marginTop: '10px' }}
            variant="contained"
            color="secondary"
            onClick={loadNext}
          >
            Load more
          </Button>
        ) : null}
      </Table>
    </Loader>
  );
};

export default Sales;
