import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Selector, useDispatch, useSelector } from 'react-redux';
import { createSelector, OutputParametricSelector } from 'reselect';
import * as R from 'ramda';
import { renameKeys } from 'ramda-adjunct';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { Badge } from '@material-ui/core';
import CropFree from '@material-ui/icons/CropFree';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import dayjs from 'dayjs';

import { addError, addSuccess, addWarning, dismissType } from 'actions/Error';
import { applyCoupon } from 'actions/ShoppingCart';
import { addProduct } from 'actions/ShoppingCart/addProduct';
import Icon from 'components/Icon';
import { PosPlugin } from 'plugins/plugin';
import {
  getCurrencyFormatter,
  getSetting,
  getShowPricesWithQuantity,
  getShowPricesWithTax,
} from 'reducers/configs/settings';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getPluginHooksByName } from 'reducers/Plugins';
import { getLastSale } from 'reducers/sales';
import {
  getAppliedCoupons,
  getProductsInShoppingCart,
  getShoppingCartDiscountsDict,
  getTotal,
  getTotalNet,
  getTotalTax,
  getVisibleTotalDiscount,
} from 'reducers/ShoppingCart';
import { saveCustomer } from 'actions/customerSearch';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { publish, useCentrifugoClientKrisVersion } from 'services/CentrifugoWS';
import { getCustomers } from 'services/ErplyAPI';
import { verifyCoupon } from 'services/ErplyAPI/campaigns';
import * as api from 'services/ErplyAPI/mail';
import { isEmpty, notUndefinedOrNull, sleep } from 'utils';
import useProducts from 'utils/hooks/useProducts';
import { openPluginModalPage } from 'actions/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { HeaderIconButton } from 'containers/Header/components/Icon';

import {
  couponDeclinedMessage,
  customerCreationRejectionMessage,
  customerEmailSearchMessage,
  customerPhoneSearchMessage,
  enterCouponMessage,
  finishedSaleCentrifugoMessage,
  getDonationMessage,
  hideMiscContentMessage,
  posConnectedCentrifugoMessage,
  startNewSaleCentrifugoMessage,
} from './constants';
import { openWindowOnSelectedScreen } from './rdx/actions';
import ScreenSelectMenu from './screenSelect/ScreenSelectMenu';
import useScreenDetector from './screenSelect/useScreenDetector';
import { Configuration, CustomerDisplayState, QrCode } from './types';
import { handleSelectedUpsellProducts } from './features/upsellProducts';

import {
  getChannelName,
  getCustomerCreationMessage,
  getCustomQRCodeMessage,
  getDiscountAsPercentage,
  getDonationProductId,
  getOpenCustomerFormOnCreate,
  getQRCodeConfig,
  getSetConfigCentrifugoMessage,
  getSetPromotionsMessage,
  getSetSmallPromotionsMessage,
  getShouldShowOpenCustomerDisplayButton,
} from '.';

const getDefaultCustomerDisplayData = createSelector(
  state => getShowPricesWithTax(state),
  state => getProductsInShoppingCart(state),
  state => getVisibleTotalDiscount(state),
  state => getTotalTax(state),
  state => getTotalNet(state),
  state => getTotal(state),
  state => getCurrencyFormatter(state),
  state => getSelectedCustomer(state),
  state => getShoppingCartDiscountsDict(state),
  state => getSetting('pos_cart_rows_to_bottom')(state),
  (
    isWithTax,
    shoppingCart,
    totalDiscount,
    totalVat,
    totalNet,
    total,
    formatCurrency,
    activeCustomer,
    shoppingCartDiscounts,
    rowsToBottom,
  ) => {
    const cart = rowsToBottom
      ? shoppingCart
      : Array.from(shoppingCart).reverse();
    return {
      products: cart.map(o => ({
        id: o.orderIndex,
        name: o.name,
        code: o.code,
        qty: o.amount,
        basePrice: isWithTax
          ? formatCurrency(Number(o.priceListPriceWithVAT))
          : formatCurrency(Number(o.priceListPrice)),
        disc: (shoppingCartDiscounts[o.rowNumber] || [])
          ?.map(a => `${a.affected}x -${a.percentage}%`)
          .join('\n'),
        final: isWithTax
          ? formatCurrency(Number(o.rowTotal))
          : formatCurrency(Number(o.rowNetTotal)),
      })),
      surcharges: [
        { name: 'Tax', id: 'tax', amount: formatCurrency(totalVat) },
      ],
      discount: formatCurrency(totalDiscount, { roundDown: true }),
      net: formatCurrency(totalNet),
      surcharge: formatCurrency(totalVat),
      total: formatCurrency(total),
      customer: {
        fullName: activeCustomer?.fullName?.replace(/,/g, ''),
        firstName: activeCustomer?.firstName,
        lastName: activeCustomer?.lastName,
        email: activeCustomer?.email,
        birthday: activeCustomer?.birthday,
      },
    };
  },
);

const getCustomerDisplayData = createSelector(
  state =>
    getPluginHooksByName<Record<string, any>>('customHooks')(state)
      .map(h => h.customerDisplayGetData)
      .filter(a => a) as OutputParametricSelector<
      any,
      CustomerDisplayState,
      CustomerDisplayState,
      any
    >[],
  state => state,
  (hooks, rootState) => {
    const result = ([getDefaultCustomerDisplayData, ...hooks] as any[]).reduce(
      (prev, selector) => selector(rootState, prev),
      undefined,
    ) as CustomerDisplayState;
    return result;
  },
);

const CustomerDisplayConnection = ({
  channelName,
}: {
  channelName: string;
}) => {
  const channel = `customer_display:${channelName}`;
  const { postMessage } = useCentrifugoClientKrisVersion({ channel });
  const deps: any[] = [];
  const useDepSelector = <A,>(sel: Selector<any, A>): A => {
    const val = useSelector<any, A>(sel);
    deps.push(val);
    return val;
  };
  deps.push(channel);

  const data = useDepSelector(getCustomerDisplayData);

  const [memoized, setMemoized] = useState(data);

  useEffect(() => {
    if (!R.equals(memoized, data)) setMemoized(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if (channel) {
      postMessage(posConnectedCentrifugoMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    if (channel) {
      postMessage({
        action: 'SET_PRODUCTS_CD',
        data: {
          ...memoized,
        },
        timestamp: Date.now(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoized, channel]);
  return null;
};

export const ComponentHeader: PosPlugin<Configuration>['ComponentHeader'] = ({
  children,
}) => {
  const { t } = useTranslation('customerDisplay');
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  const channelName = useSelector(getChannelName);
  const channel = `customer_display:${channelName}`;
  const format = useSelector(getCurrencyFormatter);
  const isWithTax = useSelector(getShowPricesWithTax);
  const isWithQty = useSelector(getShowPricesWithQuantity);
  const reverse = !useSelector(getSetting('pos_cart_rows_to_bottom'));
  const productsInCart = useSelector(getProductsInShoppingCart);
  const cart = useMemo(
    () => (reverse ? Array.from(productsInCart).reverse() : productsInCart),
    [reverse, productsInCart],
  );

  const employeeID = useSelector(getLoggedInEmployeeID);
  const employee = useSelector(getEmployeeById(employeeID));

  const visibleTotalDiscount = useSelector(getVisibleTotalDiscount);
  const total = useSelector(getTotal);
  const net = useSelector(getTotalNet);
  const tax = useSelector(getTotalTax);

  const combo = useMemo(
    () => ({
      cart,
      summary: {
        disc: format(visibleTotalDiscount, { roundDown: true }),
        total: format(total),
        net: format(net),
        tax: format(tax),
      },
    }),
    [cart, format, visibleTotalDiscount, total, net, tax],
  );

  const [memoized, setMemoized] = useState(combo);
  const [registerCustomerData, setRegisterCustomerData] = useState({});
  const [customerEmailInput, setCustomerEmailInput] = useState('');
  const [customerPhoneInput, setCustomerPhoneInput] = useState('');
  const [couponInput, setCouponInput] = useState('');
  const [donationInput, setDonationInput] = useState(0);
  const [emailInputAction, setEmailInputAction] = useState('');
  const [latestMessage, setLatestMessage] = useState<any>({
    data: null,
    type: '',
  });
  const [needsStartNew, setNeedsStartNew] = useState(true);

  const discountAsPercentage = useSelector(getDiscountAsPercentage);
  const openCustomerFormOnCreate = useSelector(getOpenCustomerFormOnCreate);
  const { invoiceID } = useSelector(getLastSale);

  const customerCreationMessage = useSelector(getCustomerCreationMessage);
  const setConfigMessage = useSelector(getSetConfigCentrifugoMessage);
  const promoMsg = useSelector(getSetPromotionsMessage);
  const smallPromoMsg = useSelector(getSetSmallPromotionsMessage);
  const qrCodeConfig = useSelector(getQRCodeConfig);
  const qrCodeMessage = useSelector(getCustomQRCodeMessage);
  const shouldShowOpenCustomerDisplayButton = useSelector(
    getShouldShowOpenCustomerDisplayButton,
  );

  const coupons = useSelector(getAppliedCoupons);
  const couponDeniedMessage = reason => {
    return {
      action: 'COUPON_CODE_FAILED',
      reason,
      showAgain: false,
    };
  };
  const couponAppliedMessage = {
    action: 'COUPON_CODE_APPLIED',
    discount: '',
    summary: memoized.summary,
    showAgain: false,
  };
  const donationProductId = useSelector(getDonationProductId);
  const {
    products: [donationProduct],
  } = useProducts({ productID: donationProductId });

  const saveCustomerThruButton = async info => {
    const attributes: {
      privacy?: boolean;
      termsAndConditions?: boolean;
      privacyDateTime?: string;
      termsAndConditionsDateTime?: string;
    } = {};
    const { privacy, termsAndConditions, birthday, ...props } = info;

    const date = dayjs().format('DD.MM.YYYY');
    const time = dayjs().format('HH:mm:ss');
    const name = `${employee?.firstName ?? ''} ${employee?.lastName ?? ''}`;
    const agreedWhenByWho = `${date} ${time} ${name}`;

    if (privacy) {
      attributes.privacy = info.privacy;
      attributes.privacyDateTime = agreedWhenByWho;
    }
    if (termsAndConditions) {
      attributes.termsAndConditions = info.termsAndConditions;
      attributes.termsAndConditionsDateTime = agreedWhenByWho;
    }
    const newCustomerData = R.pipe(
      R.assoc('addresses', [{ postalCode: info.zip }]),
      R.when(
        () => notUndefinedOrNull(birthday),
        R.assoc('birthday', dayjs(birthday).format('YYYY-MM-DD')),
      ),
      // assign the 'privacy' and 'termsAndConditions' as attributes
      R.when(
        () => Object.keys(attributes).length > 0,
        R.assoc('attributes', attributes),
      ),
    )(props);
    if (openCustomerFormOnCreate) {
      dispatch(
        openModalPage({
          component: mp.createCustomer,
          props: {
            newCustomer: true,
            newCustomerData,
          },
        }),
      );
      return;
    }

    let customerSaved = false;
    await dispatch(
      saveCustomer(newCustomerData, () => {
        customerSaved = true;
      }),
    );
    if (!customerSaved) {
      publish({
        channel: `customer_display:${channelName}`,
        message: customerCreationRejectionMessage,
      });
    }
  };

  const parseDonationToPOS = amount => {
    dispatch(
      addProduct({
        productID: donationProductId,
        price: amount,
        amount: 1,
      }),
    );
    publish({
      channel,
      message: {
        action: 'ADDED_DONATION',
        onlyDonationData: true,
        product: {
          ...donationProduct,
          price: amount,
        },
        summary: memoized.summary,
      },
    });
  };

  const searchCustomerThruButton = async ({ info, field }) => {
    const customers = await getCustomers({
      searchFromMiddle: 1,
      request: 'getCustomers',
      searchNameIncrementally: info,
      doNotCalculateRecordsTotal: 1,
    });
    if (customers.length === 1) {
      const customer = customers[0];
      dispatch(
        addSuccess(
          t('customerSearch.selected', {
            lastName: customer?.lastName,
            firstName: customer?.firstName,
          }),
          {
            dismissible: true,
            selfDismiss: true,
          },
        ),
      );
      dispatch(setCustomer({ data: customer }));
    } else if (customers.length > 0) {
      dispatch(
        openPluginModalPage('DuplicateCustomersSelectionForm')({
          isPopup: true,
          props: {
            customers,
          },
        }),
      );
    } else {
      dispatch(
        addWarning(t('customerSearch.noResults'), {
          dismissible: true,
          selfDismiss: true,
        }),
      );
    }
  };

  const sendEmailToCustomer = async ({ email }) => {
    try {
      dispatch(
        addWarning(i18next.t('payment:confirmView.alerts.sendingEmail'), {
          errorType: 'Receipt email',
          dismissible: false,
          selfDismiss: false,
        }),
      );
      await api.sendSalesDocumentByMail({
        invoiceID,
        email,
      });
      dispatch(dismissType('Receipt email'));
      dispatch(
        addSuccess(
          i18next.t('payment:confirmView.alerts.sendingEmail', {
            context: 'done',
          }),
          {
            errorType: 'Receipt email',
            selfDismiss: true,
          },
        ),
      );
      // after email sent,
      publish({
        channel,
        message: finishedSaleCentrifugoMessage,
      });
      setNeedsStartNew(true);
    } catch (err) {
      dispatch(dismissType('Receipt email'));
      dispatch(
        addWarning(err.message, {
          dismissible: false,
          selfDismiss: true,
          errorType: 'Receipt email',
        }),
      );
    }
  };

  const searchAddCouponThruButton = id => {
    if (coupons.find(coup => coup.uniqueIdentifier === id)) {
      dispatch(
        addWarning(t('alerts:promotions.couponAlreadyApplied'), {
          selfDismiss: true,
        }),
      );
      publish({
        channel,
        message: couponDeniedMessage(
          t('alerts:promotions.couponAlreadyApplied'),
        ),
      });
      return;
    }
    verifyCoupon(id)
      .then(res => res[0])
      .then(coupon => {
        dispatch(applyCoupon(coupon));
      })
      .then(() => {
        dispatch(
          addSuccess(t('alerts:promotions.couponSuccessfullyApplied'), {
            selfDismiss: true,
          }),
        );
        publish({
          channel,
          message: couponAppliedMessage,
        });
      })
      .catch(err => {
        dispatch(addError(err.message, { selfDismiss: true }));
        publish({
          channel,
          message: couponDeniedMessage(err.message),
        });
      });
  };

  const actions = {
    REGISTER_CUSTOMER_DATA: {
      accept: () => {
        saveCustomerThruButton(registerCustomerData);
        setRegisterCustomerData({});
        setLatestMessage({ data: null, type: '' });
      },
      decline: () => {
        publish({
          channel,
          message: customerCreationRejectionMessage,
        });
        setRegisterCustomerData({});
        setLatestMessage({ data: null, type: '' });
      },
    },
    CUSTOMER_EMAIL_INPUT: {
      accept: () => {
        searchCustomerThruButton({
          info: customerEmailInput,
          field: 'email',
        });
        setCustomerEmailInput('');
        setLatestMessage({ data: null, type: '' });
      },
    },
    CUSTOMER_PHONE_INPUT: {
      accept: () => {
        searchCustomerThruButton({
          info: customerPhoneInput,
          field: 'phone',
        });
        setCustomerPhoneInput('');
        setLatestMessage({ data: null, type: '' });
      },
    },
    ENTERED_COUPON_CODE: {
      accept: () => {
        searchAddCouponThruButton(couponInput);
        setCouponInput('');
        setLatestMessage({ data: null, type: '' });
      },
      decline: () => {
        publish({
          channel,
          message: couponDeclinedMessage,
        });
        setCouponInput('');
        setLatestMessage({ data: null, type: '' });
      },
    },
    ADD_DONATION: {
      accept: () => {
        parseDonationToPOS(donationInput);
        setDonationInput(0);
        setLatestMessage({ data: null, type: '' });
      },
    },
  };

  const shouldShowDeclineButton = type => {
    return !(
      type === 'CUSTOMER_PHONE_INPUT' ||
      type === 'CUSTOMER_EMAIL_INPUT' ||
      type === 'ADD_DONATION'
    );
  };

  const discountAmountByTax = isWithTax
    ? 'totalDiscountAmountWithVAT'
    : 'totalDiscountAmount';

  useEffect(() => {
    if (!R.equals(memoized, combo)) {
      setMemoized(combo);
    }
  }, [combo, memoized]);

  const setProductMessage = useMemo(() => {
    const convertPriceForDisplay = cartRow => {
      const basePrice = isWithTax
        ? cartRow.finalPriceWithVAT
        : cartRow.finalPrice;
      const multiplier = isWithQty ? cartRow.qty : 1;
      const price = basePrice * multiplier;
      return price;
    };
    return {
      action: 'SET_PRODUCTS',
      products: memoized.cart.map(
        R.pipe(
          renameKeys({
            amount: 'qty',
          }),
          (prod: any) =>
            R.assoc(
              'disc',
              // eslint-disable-next-line no-nested-ternary
              discountAsPercentage
                ? Number(prod?.totalDiscount) > 0
                  ? `${Number(prod?.totalDiscount)?.toFixed(2)}%`
                  : ''
                : prod?.[discountAmountByTax] > 0
                ? format(
                    isWithQty
                      ? prod?.[discountAmountByTax] * Number(prod?.qty)
                      : prod?.[discountAmountByTax],
                  )
                : '',
            )(prod),
          (prod: any) =>
            R.assoc('price', format(convertPriceForDisplay(prod) ?? 0))(prod),
        ),
      ),
      summary: memoized.summary,
    };
  }, [
    memoized.cart,
    memoized.summary,
    isWithTax,
    isWithQty,
    discountAsPercentage,
    discountAmountByTax,
    format,
  ]);

  const [qrCodeState, setQrCodeState] = useState<QrCode | null>(null);
  const hasCart = setProductMessage.products.length !== 0;

  const newSaleWhileAfterSaleQrIsShown = qrCodeState?.isAfterSaleQR && hasCart;

  useEffect(() => {
    if (!qrCodeState) return () => undefined;
    if (newSaleWhileAfterSaleQrIsShown) {
      setQrCodeState(null);
      publish({
        channel,
        message: hideMiscContentMessage,
      });
      return () => undefined;
    }
    if (!newSaleWhileAfterSaleQrIsShown) {
      const i = setTimeout(() => {
        setQrCodeState(null);
        publish({
          channel,
          message: hideMiscContentMessage,
        });
        if (qrCodeState.isAfterSaleQR) {
          publish({
            channel,
            message: finishedSaleCentrifugoMessage,
          });
        }
      }, qrCodeConfig.qrTimeout * 1000);
      return () => clearTimeout(i);
    }
    return () => undefined;
  }, [
    channel,
    newSaleWhileAfterSaleQrIsShown,
    qrCodeConfig.qrTimeout,
    qrCodeState,
  ]);

  const { postMessage } = useCentrifugoClientKrisVersion({
    channel,
    listener: msg => {
      const customerDisplayInput = JSON.parse(msg.data.data);
      const { action } = customerDisplayInput;
      if (action === 'GET_CONFIG') {
        publish({ channel, message: setConfigMessage });
        publish({ channel, message: promoMsg });
        setNeedsStartNew(true);
      }
      if (action === 'GET_CUSTOMER_EMAIL') {
        setEmailInputAction('search');
      }
      if (action === 'COMPLETE_PURCHASE') {
        setEmailInputAction('sale');
        setNeedsStartNew(true);
      }
      if (action === 'REGISTER_CUSTOMER_DATA') {
        const customerData = {
          ...customerDisplayInput.customer,
          customerType: 'PERSON',
        };
        setRegisterCustomerData(customerData);
        setLatestMessage({ data: customerData, type: action });
      }
      if (action === 'CUSTOMER_EMAIL_INPUT') {
        const { email } = customerDisplayInput;
        if (emailInputAction === 'sale') {
          sendEmailToCustomer({ email });
        } else {
          setCustomerEmailInput(email);
          setLatestMessage({ data: email, type: action });
        }
      }
      if (action === 'CUSTOMER_PHONE_INPUT') {
        const { phoneNumber } = customerDisplayInput;
        setCustomerPhoneInput(phoneNumber);
        setLatestMessage({ data: phoneNumber, type: action });
      }
      if (action === 'ENTERED_COUPON_CODE') {
        const { couponCode } = customerDisplayInput;
        setCouponInput(couponCode);
        setLatestMessage({ data: couponCode, type: action });
      }
      if (action === 'PURCHASE_INACTIVITY_TIMEOUT' || action === 'FINISHED') {
        setNeedsStartNew(true);
      }
      if (action === 'ADD_DONATION') {
        const { donation } = customerDisplayInput;
        const { amount } = donation;
        setDonationInput(amount);
        setLatestMessage({ data: amount, type: action });
      }
      if (action === 'SHOW_QR') {
        setQrCodeState(customerDisplayInput);
      }
      if (action === 'PURCHASE_UPSELL_PRODUCTS') {
        dispatch(
          handleSelectedUpsellProducts(customerDisplayInput.products ?? []),
        );
      }
    },
  });

  const searchCustomerByEmail = useCallback(() => {
    if (needsStartNew) {
      postMessage(startNewSaleCentrifugoMessage);
      setNeedsStartNew(false);
    }
    postMessage(customerEmailSearchMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsStartNew]);

  const searchCustomerByPhone = useCallback(() => {
    if (needsStartNew) {
      postMessage(startNewSaleCentrifugoMessage);
      setNeedsStartNew(false);
    }
    postMessage(customerPhoneSearchMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsStartNew]);

  const registerCustomer = useCallback(() => {
    if (needsStartNew) {
      postMessage(startNewSaleCentrifugoMessage);
      setNeedsStartNew(false);
    }
    postMessage(customerCreationMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerCreationMessage, needsStartNew]);

  const enterCoupon = useCallback(() => {
    if (needsStartNew) {
      postMessage(startNewSaleCentrifugoMessage);
      setNeedsStartNew(false);
    }
    postMessage(enterCouponMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsStartNew]);

  const promptDonation = useCallback(() => {
    if (needsStartNew) {
      postMessage(startNewSaleCentrifugoMessage);
      setNeedsStartNew(false);
    }
    postMessage(getDonationMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsStartNew]);

  const promptQRcode = useCallback(() => {
    if (needsStartNew) {
      postMessage(startNewSaleCentrifugoMessage);
      setNeedsStartNew(false);
    }
    postMessage(qrCodeMessage);
    setQrCodeState(qrCodeMessage);
    sleep(qrCodeConfig.qrTimeout).then(() => {
      postMessage(hideMiscContentMessage);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsStartNew, qrCodeMessage, qrCodeConfig.qrTimeout]);

  useEffect(() => {
    const handleNewSale = () => {
      Promise.resolve(postMessage(startNewSaleCentrifugoMessage)).then(() => {
        setNeedsStartNew(false);
      });
    };
    if (needsStartNew && setProductMessage.products.length !== 0) {
      handleNewSale();
    } else {
      postMessage(setProductMessage);
      postMessage(smallPromoMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProductMessage, needsStartNew, smallPromoMsg]);

  const notificationCount = [
    !!customerEmailInput.length,
    !!customerPhoneInput.length,
    !!couponInput.length,
    !isEmpty(registerCustomerData),
    !!(donationInput > 0),
  ].filter(a => a).length;

  useScreenDetector();

  const openCustomerDisplay = async () => {
    const { origin, pathname } = window.location;
    const relativePath = origin.concat(pathname).replace('index.html', '');
    const w = await dispatch(
      openWindowOnSelectedScreen(`${relativePath}customerDisplay/index.html`),
    );
    await sleep(1);
    w?.postMessage(
      { payload: { channelName }, type: 'setConfig' },
      `${relativePath}customerDisplay/index.html`,
    );
  };

  const openExternalCustomerDisplay = async () => {
    await dispatch(
      openWindowOnSelectedScreen('https://customer-display.erply.com/#/'),
    );
  };

  return (
    <>
      {channelName ? (
        <CustomerDisplayConnection channelName={channelName} />
      ) : null}
      <Dropdown
        data-testid="header-notifications"
        className="erply-header__notifications"
        alignRight
        as="li"
      >
        <Dropdown.Toggle id="TestingDropdown">
          <Badge badgeContent={notificationCount} color="primary">
            <HeaderIconButton size="small">
              <Icon
                name="icon_desktop"
                style={{ color: channelName ? 'lightgreen' : 'salmon' }}
                title={
                  channelName
                    ? 'Customer Display plugin: OK, Acting as sender'
                    : 'Customer Display plugin: Channel not configured'
                }
              />
            </HeaderIconButton>
          </Badge>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {shouldShowOpenCustomerDisplayButton && (
            <>
              <Dropdown.Item className="text-center">
                <Button onClick={channelName ? openCustomerDisplay : undefined}>
                  <span>{t('headerIcon.openDisplayApp')}</span>
                </Button>
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item className="text-center">
            <Button
              onClick={channelName ? openExternalCustomerDisplay : undefined}
            >
              <span>{t('headerIcon.openExtDisplayApp')}</span>
            </Button>
          </Dropdown.Item>
          <Dropdown.Item as="div" className="text-center">
            <Button
              onClick={
                isEmpty(registerCustomerData)
                  ? registerCustomer
                  : () => actions.REGISTER_CUSTOMER_DATA.accept()
              }
              title={t(
                isEmpty(registerCustomerData)
                  ? 'headerIcon.sendCustomerForm'
                  : 'headerIcon.registerCustomerWithInfo',
              )}
              variant={isEmpty(registerCustomerData) ? 'primary' : 'success'}
              className="icon_profile"
              style={{ width: '150px', height: '65px', fontSize: '34px' }}
            >
              <span className="icon_plus" style={{ fontSize: '34px' }} />
            </Button>
          </Dropdown.Item>
          <Dropdown.Item as="div" className="text-center">
            <Button
              onClick={
                customerEmailInput.length
                  ? () => actions.CUSTOMER_EMAIL_INPUT.accept()
                  : searchCustomerByEmail
              }
              title={t(
                customerEmailInput.length
                  ? 'headerIcon.searchCustomerByEmail'
                  : 'headerIcon.sendSearchCustomerByEmail',
              )}
              variant={customerEmailInput.length ? 'success' : 'primary'}
              className="icon_profile"
              style={{ width: '150px', height: '65px', fontSize: '34px' }}
            >
              <span className="icon_mail" style={{ fontSize: '26px' }} />
              <span className="icon_search" style={{ fontSize: '17px' }} />
            </Button>
          </Dropdown.Item>
          <Dropdown.Item as="div" className="text-center">
            <Button
              onClick={
                customerPhoneInput.length
                  ? () => actions.CUSTOMER_PHONE_INPUT.accept()
                  : searchCustomerByPhone
              }
              title={t(
                customerPhoneInput.length
                  ? 'headerIcon.searchCustomerByPhone'
                  : 'headerIcon.sendSearchCustomerByPhone',
              )}
              variant={customerPhoneInput.length ? 'success' : 'primary'}
              className="icon_profile"
              style={{ width: '150px', height: '65px', fontSize: '34px' }}
            >
              <span className="icon_phone" style={{ fontSize: '26px' }} />
              <span className="icon_search" style={{ fontSize: '17px' }} />
            </Button>
          </Dropdown.Item>
          <Dropdown.Item as="div" className="text-center">
            <Button
              onClick={
                couponInput.length
                  ? () => actions.ENTERED_COUPON_CODE.accept()
                  : enterCoupon
              }
              title={t(
                couponInput.length
                  ? 'headerIcon.acceptCoupon'
                  : 'headerIcon.sendCouponField',
              )}
              variant={couponInput.length ? 'success' : 'primary'}
              className="icon_document"
              style={{ width: '150px', height: '65px', fontSize: '34px' }}
            />
          </Dropdown.Item>
          <Dropdown.Item as="div" className="text-center">
            <Button
              onClick={
                donationInput === 0
                  ? promptDonation
                  : () => actions.ADD_DONATION.accept()
              }
              title={t(
                donationInput === 0
                  ? 'headerIcon.promptDonation'
                  : 'headerIcon.addDonation',
              )}
              variant={donationInput === 0 ? 'primary' : 'success'}
              className="icon_gift"
              style={{ width: '150px', height: '65px', fontSize: '34px' }}
              disabled={donationProductId === 0}
            />
          </Dropdown.Item>
          <Dropdown.Item as="div" className="text-center">
            <Button
              onClick={promptQRcode}
              title={t('headerIcon.promptQR')}
              variant="primary"
              style={{ width: '150px', height: '65px' }}
            >
              <CropFree style={{ width: '120px', height: '50px' }} />
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <li>
        <ScreenSelectMenu />
      </li>
      {latestMessage.data ? (
        <li>
          <ButtonGroup className="mr-2" style={{ marginLeft: '1.5em' }}>
            <Button
              variant="success"
              onClick={() => actions[latestMessage.type].accept()}
              title={t(`latestMessageTitles.${latestMessage.type}`, {
                data:
                  latestMessage.type === 'REGISTER_CUSTOMER_DATA'
                    ? JSON.stringify(latestMessage?.data)
                    : latestMessage.data,
              })}
            >
              Accept
            </Button>
            {shouldShowDeclineButton(latestMessage.type) ? (
              <Button
                variant="danger"
                onClick={() => actions[latestMessage.type].decline()}
              >
                Decline
              </Button>
            ) : null}
          </ButtonGroup>
        </li>
      ) : null}

      {children}
    </>
  );
};
