import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import ReactDOM from 'react-dom';
import React from 'react';
import * as R from 'ramda';

import { PrinterScript } from 'Systems/PrinterScript/exports';
import {
  getCurrentPrinterIntegration,
  getSupportedPrinterIntegrations,
} from 'reducers/configs/settings';
import {
  PrinterMetadata,
  printPrinterTest,
} from 'Systems/PrinterScript/core/PrinterScript';
import { sleep } from 'utils';
import { PatchscriptRender } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/printer/sampleReactPrinter';
import { print } from 'utils/print';
import {
  PatchScript,
  RItem,
} from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';

import {
  sendOpenCashDrawerToWrapper,
  sendPatchscriptToWrapper,
  sendReceiptDataToWrapper,
  sendZReportDataToWrapper,
} from './wrapper';

const { WebSocket } = window;

let browserQueue = Promise.resolve('');
export function renderElement(
  element: JSX.Element,
  width: number,
  scale: number,
) {
  browserQueue = Promise.all([browserQueue, import('dom-to-image')]).then(
    async ([, { default: domToImage }]) => {
      const div = document.getElementById('printPreview');
      ReactDOM.render(
        <div
          style={{
            display: 'inline-block',
            width: width / scale,
            position: 'fixed',
            zIndex: -1000,
            top: 0,
            left: 0,
          }}
        >
          {element}
        </div>,
        div,
      );

      await sleep(0.3);
      div?.style.setProperty('display', 'block');
      const el = div?.children[0];
      const blob = (
        await domToImage.toPng(el, {
          width: (el?.clientWidth ?? 1) * scale,
          height: (el?.clientHeight ?? 1) * scale,
          style: {
            transform: `scale(${scale})`,
            'transform-origin': 'top left',
          },
        })
      ).split(',')[1];
      div?.style.removeProperty('display');
      return blob;
    },
  );
  return browserQueue;
}

/**
 * Renders Actual Reports receipt html to DOM (scaled to the size of given width) and converts it to an image.
 * If receipt consists of multiple pages combines them all into one long page
 * @returns image blob
 */
export function renderARHtmlForPrintMS(
  html: string,
  width: number,
  scaleMultiplier = null as number | null,
): Promise<string> {
  browserQueue = Promise.all([browserQueue, import('dom-to-image')]).then(
    async ([, { default: domToImage }]) => {
      const scale = scaleMultiplier ?? 1;
      const div = document.getElementById('printPreview');
      if (!div) return '';
      ReactDOM.render(
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: html }}
          style={{
            display: 'inline-block',
            position: 'fixed',
            zIndex: -1000,
            width: width / scale,
            top: 0,
            left: 0,
          }}
        />,
        div,
      );

      await sleep(0.3);
      div.style.setProperty('display', 'block');
      const pages = div.querySelectorAll<HTMLDivElement>('[data-page]');
      let [elementToPrint] = pages;

      // Combine multiple pages into one long page
      if (pages.length > 1) {
        const pageContainer = document.createElement('div');
        div.firstChild?.insertBefore(pageContainer, pages[0]);
        pageContainer.style.width = pages[0].style.width;

        pages.forEach((page, idx) => {
          pageContainer.appendChild(page);

          const height =
            idx < pages.length - 1
              ? page.clientHeight
              : // Last page height is calculated based on its content height + hardcoded bottom padding
                // Otherwise last page might be mostly empty (and I care about the trees)
                R.pipe(
                  Array.from,
                  R.map(
                    child => parseFloat(child.style.top) + child.offsetHeight,
                  ),
                  R.sort(R.descend(R.identity)),
                  R.head,
                  R.add(65),
                )(page.children);

          pageContainer.style.height = `${pageContainer.clientHeight +
            height}px`;
        });
        elementToPrint = pageContainer;
      }

      const autoScale = !scaleMultiplier
        ? width / elementToPrint.clientWidth
        : scale;

      const blob = (
        await domToImage.toPng(elementToPrint, {
          width,
          height: elementToPrint.clientHeight * autoScale,
          style: {
            transform: `scale(${autoScale})`,
            'transform-origin': 'top left',
          },
        })
      ).split(',')[1];

      div.style.removeProperty('display');
      ReactDOM.unmountComponentAtNode(div);

      return blob;
    },
  );
  return browserQueue;
}

function testPrinterType(printer: PrinterMetadata) {
  return async () => {
    const script = printPrinterTest(new PrinterScript(printer)).toString();
    await new Promise((resolve, reject) => {
      const ws = new WebSocket(`wss://localhost.erply.com:5000`);
      ws.onerror = () => reject(new Error('Printing failed with error'));
      ws.onclose = () => reject(new Error('Printing failed with error'));
      ws.onopen = () => {
        ws.send(script);
        resolve(script);
      };
    });
  };
}

export const MSActions = {
  openCashDrawer: () => async (
    dispatch: ThunkDispatch<unknown, unknown, Action>,
    getState: () => unknown,
  ) => {
    const useLegacy = localStorage.getItem('MS.print.legacy');
    const metaData = getCurrentPrinterIntegration(getState());

    if (useLegacy)
      return dispatch(
        MSActions.print(new PrinterScript(metaData).toOpenCashDrawer()),
      );
    return axios.get('https://localhost.erply.com:5000/opencashdrawer');
  },
  print: (script: string) => async () => {
    return new Promise((resolve, reject) => {
      const ws = new WebSocket(`wss://localhost.erply.com:5000`);
      ws.onerror = () => reject(new Error('Printing failed with error'));
      ws.onclose = () => reject(new Error('Printing failed with error'));
      ws.onopen = () => {
        ws.send(script);
        resolve(script);
      };
    });
  },
  testAll: () => async (
    dispatch: ThunkDispatch<unknown, unknown, Action>,
    getState: () => unknown,
  ) => {
    Object.values(getSupportedPrinterIntegrations(getState())).reduce(
      async (prevPromise, int) => {
        await prevPromise;
        await sleep(1);
        dispatch(testPrinterType(int));
      },
      Promise.resolve(),
    );
  },
};

export const WrapperActions = {
  openCashDrawer: sendOpenCashDrawerToWrapper,
  printReceipt: ({ data, link }, options = {}) =>
    sendReceiptDataToWrapper({ receiptLink: link, data }, options),
  printZReport: ({ data, link, html }) =>
    sendZReportDataToWrapper({ data, link, html }),
  printCoupon: () => {
    throw new Error('Wrapper: coupon printing not implemented yet.');
  },
  printPatchscript: (data: PatchScript) => sendPatchscriptToWrapper(data),
};

export const BrowserActions = {
  print: ({
    url,
    html,
    fullHtml,
  }: {
    url?: string;
    html?: string;
    fullHtml?: string;
  }) => async () => {
    // TODO: This could also be changed to do inline printing instead of a popup
    // And then it would also need to use the queue
    return print({ url, html, fullHtml });
  },
  printPatchScript: (patchScript: RItem[]) => async () => {
    browserQueue = browserQueue.then(
      () =>
        new Promise(resolve => {
          ReactDOM.render(
            <PatchscriptRender receiptData={patchScript} />,
            document.getElementById('printPreview'),
          );
          setTimeout(() => {
            window.print();
            resolve(patchScript.toString());
          }, 300);
        }),
    );
    return browserQueue;
  },
};

export function renderPatchscript(
  patchScript: RItem[],
  width: number,
  scale: number,
) {
  const element = <PatchscriptRender receiptData={patchScript} />;
  return renderElement(element, width, scale);
}

export function wrapPngData(data: string) {
  return [{ type: 'image', blob: data }] as RItem[];
}
