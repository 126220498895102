import * as R from 'ramda';
import { compare } from 'compare-versions';
import dayjs from 'dayjs';

import {
  CAFA_FOR_ERROR,
  CAFA_FOR_SUCCESS,
  CHECK_MS_CERTS,
  TARGET_CERT_DATE,
  TARGET_INSTALLER_VERSION,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/CONFIGURATION';
import {
  Console,
  getAutoUpdateState,
  getCheckResultsAfter,
  getCheckResultsBefore,
  getDebugInfo,
  reset,
  setAfterCert,
  setAfterInfo,
  setAutoUpdateState,
  setBeforeCert,
  setBeforeInfo,
  setUpdateResults,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/state';
import { API } from 'containers/VersionControl/WorkaroundInstallerUpdate/API';
import { MsCertInfo } from 'containers/VersionControl/WorkaroundInstallerUpdate/types';
import { hasCookie } from 'containers/VersionControl/WorkaroundInstallerUpdate/cookie';
import { updateConfiguration } from 'services/CAFA';

const certValid = (certInfo: MsCertInfo) => {
  return certInfo.records.every(record =>
    record.certs.every(cert => {
      const certExpiration = new Date(cert.NotAfter).getTime();
      const targetExpiration = TARGET_CERT_DATE.getTime();
      if (certExpiration <= targetExpiration) {
        return false;
      }
      return true;
    }),
  );
};

// prettier-ignore
const INTEGRATION_DEFAULT_PORTS = {
  "adyen-integration":            "10108", // Adyen ms
  "chile-fiscal-service":         "10124", // Fiscal printing MS
  "bsoft-fiscal":                 "10120", // bsoft-fiscal (Garmin Chile)
  "cayan-integration":            "10103", // cayan-integration
  "chase-ingenico-ict250-driver": "10100", // Chase
  "redeban-integration":          "10106", // redeban-integration
  "sonet-payment-ms":             "10112", // Sonet (Czech)
  "pos-junction-integration":     "10107", // Junction (Africa)
  "pcard-payment-ms":             "10113", // PCARD
  "efsta-fiscal-proxy":           "10123", // efsta-fiscal-proxy
  "extdev-microservice":          "5658",  // Extdev Microservice
  "givex-direct-driver":          "5658",  // Givex Direct Driver
  "linkpos-integration":          "10110", // LinkPOS (Thai)
  "local-proxy-ms":               "10902", // local-proxy-ms
  "logger-ms":                    "10900", // logger-ms
  "metrics-collector-ms":         "10901", // metrics-collector-ms
  "moneris-integration":          "10109", // Moneris (Canada)
  "pc-eftpos-integration":        "10105", // PC-EFTPOS
  "printing-microservice":        "5000",  // Printing-microservice
  "receipt-printout-api":         "5651",  // Receipt Printout API
  "scale-fairbanks":              "10111", // scale-fairbanks
  "swedbank-ingenico":            "10104", // swedbank-ingenico
  "installer2":                   "7777",  // Installer / Wizard
}

async function retryGetInstallerInfo({ times = 5, delay = 5 } = {}) {
  Console.log('Waiting for installer to come back online:');
  let error: Error = new Error('No attempts made');
  for (let i = 0; i < times; i++) {
    // Wait for delay
    Console.log(`  Attempt ${i + 1}/${times}[${' '.repeat(delay)}] `);
    for (let s = 0; s < delay; s++) {
      const bar = '█'.repeat(s+1).padEnd(delay);
      // eslint-disable-next-line no-await-in-loop
      await new Promise(res => setTimeout(res, 1e3));
      Console.logReplace(`  Attempt ${i + 1}/${times}[${bar}] `);
    }
    try {
      // eslint-disable-next-line no-await-in-loop
      const info = await API.getInstallerInfo();
      Console.logPush('✅️ Installer is back up');
      return info;
    } catch (e) {
      Console.logPush('⌛ Not yet up')
      error = e;
    }
  }
  throw error;
}
async function runAllChecks(
  setInfo: typeof setBeforeInfo,
  setCert: typeof setBeforeCert,
  autoRetry = false,
) {
  let error = false;
  let outdated = false;

  Console.log('Fetching installer info...');
  const installerInfo = autoRetry
    ? await retryGetInstallerInfo({ times: 6, delay: 5 })
    : await API.getInstallerInfo();
  Console.log('Installer is: ');
  setInfo(installerInfo);
  const installerOutdated = compare(
    installerInfo.data.version,
    TARGET_INSTALLER_VERSION,
    '<',
  );
  if (installerOutdated) {
    Console.logPush('⚠️ OUTDATED');
  } else {
    Console.logPush('✅️ UP TO DATE');
  }

  if (CHECK_MS_CERTS) {
    Console.log('Fetching cert information for installer:');
    const installerCertInfo = await API.getInstallerCertInfo();
    setCert('installer2', installerCertInfo);
    try {
      const installerCertOutdated = !certValid(installerCertInfo);
      if (installerCertOutdated) {
        outdated = true;
        Console.logPush('⚠️ OUTDATED');
      } else {
        Console.logPush('✅️ UP TO DATE');
      }
    } catch (e) {
      error = true;
      Console.logPush('⚠️ Unknown', e);
      setCert('installer2', e as Error);
    }

    for (let i = 0; i < installerInfo.data.integrations.length; i++) {
      const int = installerInfo.data.integrations[i];
      Console.log(`... for ${int.name}`);
      try {
        // eslint-disable-next-line no-await-in-loop
        const msCert = await API.getCertInfo(
          int.port ?? INTEGRATION_DEFAULT_PORTS[int.name],
        );
        setCert(int.name, msCert);
        const msCertOutdated = !certValid(msCert);
        if (msCertOutdated) {
          outdated = true;
          Console.logPush('⚠️ OUTDATED');
        } else {
          Console.logPush('✅️ UP TO DATE');
        }
      } catch (e) {
        error = true;
        Console.logPush('⚠️ Unknown', e);
        setCert(int.name, e as Error);
      }
    }
  }

  if (installerOutdated) return false;
  switch (CHECK_MS_CERTS) {
    default:
    case false:
    case 'log-only':
      return true;
    case 'loose':
      return !outdated;
    case 'strict':
      return !outdated && !error;
  }
}

async function doUpdate() {
  // Already ran validation on this device
  if (hasCookie)
    console.warn(
      'Cookie already set - this should never happen, might be a caching issue',
    );
  // Is a wrapper, installer is irrelevant
  if ((window as any).wrapperInfo) return true;

  try {
    const okBefore = await runAllChecks(setBeforeInfo, setBeforeCert);
    if (okBefore) return true;

    Console.log('Issues detected, running installer update:');
    await API.updateInstaller().then(
      res => {
        setUpdateResults(res);
        Console.logPush('OK');
      },
      e => {
        setUpdateResults(e);
        Console.logPush('🛑', e);
      },
    );

    Console.log('Checking if issues are now resolved');
    const okAfter = await runAllChecks(setAfterInfo, setAfterCert, true);
    if (!okAfter) {
      Console.log(
        '❌️ issues not resolved, please check the troubleshooting article',
      );
      console.error({
        dataBefore: getCheckResultsBefore(),
        dataAfter: getCheckResultsAfter(),
      });
      return false;
    }
    Console.logPush('✅️ YES');
    return true;
  } catch (e) {
    Console.log('Unexpected error', e);
    throw e;
  }
}

export const triggerWorkaroundInstallerUpdate = (
  posID: string,
  retry = false,
) => {
  if (retry) {
    try {
      reset();
      Console.log('== Retry triggered by user ==');
    } catch (e) {
      Console.log(
        '== ⚠️ Retry attempted by user, but should not be possible right now ==',
      );
      return;
    }
  }

  if (getAutoUpdateState() !== 'pending') return;

  setAutoUpdateState('in progress');
  const start = dayjs();

  if (!('toJSON' in Error.prototype)) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Error.prototype, 'toJSON', {
      value(this: Error) {
        return Object.fromEntries(
          Object.getOwnPropertyNames(this)
            .filter(k => k !== 'stack')
            .map(k => [k, this[k]]),
        );
      },
    });
  }

  doUpdate()
    // Upload logs to cafa if configuration says to
    .catch(() => false)
    .then(async success => {
      const spec = success ? CAFA_FOR_SUCCESS : CAFA_FOR_ERROR;
      if (!spec) return success;
      // Upload to cafa
      Console.log('Uploading error logs to CAFA');
      await updateConfiguration(
        R.mergeDeepLeft(spec, {
          level_id: posID,
          value: { update: getDebugInfo() },
        }),
      ).then(
        () => Console.logPush('✅️ Done'),
        err => Console.logPush(`❌️ ${err.message}`),
      );
      return success;
    })

    // Set final state to show to the user
    .catch(() => false)
    .then(success => setAutoUpdateState(success ? 'done-ok' : 'done-failed'))

    // Log completion time
    .finally(() => {
      const end = dayjs();
      const ms = end.diff(start);
      Console.log(`Completed in ${ms}ms`);
    });
};
