import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import { SHOULD_SAVE_TAX_INVOICE, SET_DOCUMENT_TYPE, SET_CUSTOMER_DATA } from '../rdx/actionTypes';

import GarminForm from './GarminForm';

const GarminModal = ({
    onCancel,
    closeModal,
    resolve
}: {
    resolve: (outcome: boolean) => boolean;
    onCancel: () => void;
    closeModal: () => void;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('fiscalIntegration');

    useShortcut('Escape', onCancel, 100);

    const resolveModal = useCallback(
        () => {
            resolve(true);
            closeModal();
        }, [resolve, closeModal]);

    return (
        <>
            <style>{`[data-testid='alert-container'] {z-index:100 !important}`}</style>
            <Modal.Header>
                <Modal.Title>Select receipt type</Modal.Title>
                <CloseButton action={() => {
                    dispatch({ type: SHOULD_SAVE_TAX_INVOICE, payload: false });
                    onCancel();
                }} />
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey="0">
                    <Accordion.Toggle as={Card.Header} eventKey="0" >
                        <Button variant="primary" size="lg" block onClick={() => {
                            dispatch({ type: SHOULD_SAVE_TAX_INVOICE, payload: true });
                            dispatch({ type: SET_DOCUMENT_TYPE, payload: "Receipt" });
                            dispatch({
                                type: SET_CUSTOMER_DATA, payload: {
                                    rutReceiver: "66666666-6",
                                    recipientName: "Cliente generico",
                                    recipientDraft: "Empresa generica",
                                    recipientBillingAddress: "Dirección generica",
                                    recipientBillingCommune: "Santiago de chile",
                                    recipientBillingCity: "Santiago de chile",
                                }
                            });
                            resolveModal();
                        }}>
                            {t("regularInvoice")}
                        </Button>
                    </Accordion.Toggle>

                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => {
                        dispatch({ type: SHOULD_SAVE_TAX_INVOICE, payload: true })
                        dispatch({ type: SET_DOCUMENT_TYPE, payload: "Invoice" });
                    }}>
                        <Button variant="primary" size="lg" block  >
                            {t("taxInvoice")}
                        </Button>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <GarminForm resolveModal={resolveModal} />
                    </Accordion.Collapse>
                </Accordion>
            </Modal.Body>
        </>
    );
};

export default GarminModal;
