import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

import {
  getTagsByProductId,
  replaceTagsByProductId,
  updateTags,
} from '../redux';

import './UICustomTableBillRow.scss';

const ReturnTagRow = ({ order, isEven, shouldGroupProducts, depthLevel }) => {
  const dispatch = useDispatch();
  const returnTags: Array<string> =
    useSelector(state => getTagsByProductId(order.orderIndex)(state)) || [];

  const editTag = async e => {
    // open popup to edit tags
    await updateTags({ product: order, dispatch, tags: returnTags })
      .then(tags => dispatch(replaceTagsByProductId(order.orderIndex, tags)))
      .catch(e => console.error('Failed to update tags', e));
  };

  return (
    <tr
      style={{ cursor: 'pointer' }}
      className={classNames({ isEven, grouped: shouldGroupProducts })}
      onClick={editTag}
      data-testid="return-tag-row"
    >
      <td colSpan={11}>
        <span
          style={{
            paddingLeft: `${(depthLevel + 1) * 16}px`,
            display: 'flex',
            justifyContent: 'left',
          }}
        >
          RETURN TAG #:{' '}
          <span style={{ marginLeft: 'auto' }}>
            {returnTags.map((tag, i): ReactElement | null => {
              if (i < 3)
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <span
                    data-testid="tag"
                    data-test-key={tag}
                    key={i}
                    className="px-2"
                  >{`${tag}${i < returnTags.length - 1 ? ',' : ''} `}</span>
                );
              // eslint-disable-next-line react/no-array-index-key
              if (i === 3) return <span key={i}>...</span>;
              return null;
            })}
          </span>
        </span>
      </td>
    </tr>
  );
};

const UICustomTableBillRow: PosPlugin['UICustomTableBillRow'] = ({
  children,
  order,
  depthLevel,
  isEven,
  shouldGroupProducts,
}) => {
  const returnTags: Array<string> =
    useSelector(state => getTagsByProductId(order.orderIndex)(state)) || [];

  return R.pipe(
    React.Children.toArray,
    R.when(
      () => returnTags.length > 0,
      R.insert(
        1,
        <ReturnTagRow
          order={order}
          isEven={isEven}
          depthLevel={depthLevel}
          shouldGroupProducts={shouldGroupProducts}
        />,
      ),
    ),
  )(children);
};

export default UICustomTableBillRow;
