import React, { FC, useState } from 'react';
import { Alert, Button, Card, ListGroup, Modal } from 'react-bootstrap';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import UIButton from 'components/UIElements/UIButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { addWarning } from 'actions/Error';
import { miniUuid } from 'utils';
import InputField from '../../../../components/FieldTypes/InputField';

type Props = {
  resolve: any;
  reject: any;
  dispatch: any;
  productName: string;
  tags: string[];
  productAmount: number;
};

const RemoveTagModal: FC<Props> = ({
  resolve,
  reject,
  dispatch,
  productName,
  tags: propTags,
  productAmount,
}) => {
  const initState = {};
  // eslint-disable-next-line no-return-assign
  propTags.forEach(t => (initState[miniUuid()] = t));
  const [tags, setTags] = useState<Record<string, any>>(initState);
  const [editedTagID, setEditedTagID] = useState('');
  const [tempTag, setTempTag] = useState('');
  const [error, setError] = useState('');

  const currentNumberOfTags = Object.values(tags).length;

  const setToEdit = id => {
    setTempTag(tags[id]);
    setEditedTagID(id);
  };

  const handleTagChange = e => {
    const val = e.target.value.replace(/\D/g, '').slice(0, 8);
    if (val.length < 6) {
      setError('Return tags must be between 6 and 8 numeric characters.');
    } else {
      setError('');
    }
    setTempTag(val);
  };

  const saveEditedTag = () => {
    if (tempTag.length < 6) {
      dispatch(addWarning('Can not save. Invalid tag.'));
    } else {
      setTags({ ...tags, [editedTagID]: tempTag });
      setTempTag('');
      setEditedTagID('');
    }
  };

  const removeTag = id => {
    if (Object.values(tags).length === productAmount) {
      dispatch(addWarning('Cannot remove more tags.'));
    } else {
      const newTags = { ...tags };
      delete newTags[id];
      setTags(newTags);
    }
  };

  const cancelAllChanges = () => {
    dispatch(previousModalPage());
    reject();
  };

  const saveChanges = () => {
    resolve(Object.values(tags));
    dispatch(previousModalPage());
  };

  useShortcut('Escape', cancelAllChanges, 100);
  useShortcut('Enter', saveChanges, 100);

  return (
    <div data-testid="pnp-remove-tag-modal">
      <Modal.Header>
        <h3 className="font-weight-bold">Edit Return Tags</h3>
      </Modal.Header>
      <Modal.Body>
        <p data-testid="product-name">{`Product name: ${productName}`}</p>
        {currentNumberOfTags > productAmount && (
          <Alert data-testid="alert" variant="warning">
            {`The return product amount is ${productAmount} but the entered return tags are ${currentNumberOfTags}${
              currentNumberOfTags - productAmount !== 0
                ? `. Please remove the extra ${currentNumberOfTags -
                    productAmount} tag(s).`
                : ''
            }`}
          </Alert>
        )}
        {!!error.length && <Alert variant="danger">{error}</Alert>}
        <Card>
          <Card.Body>
            <ListGroup>
              {Object.entries(tags).map(([id, tag]) => (
                <ListGroup.Item
                  className="d-flex flex-fill justify-content-between align-items-center"
                  key={id}
                  data-testid="tag-item"
                  data-test-key={tag}
                >
                  {editedTagID === id ? (
                    <InputField
                      autoFocus
                      name={id}
                      onChange={handleTagChange}
                      value={tempTag}
                      data-testid="editable-tag"
                    />
                  ) : (
                    <span data-testid="tag" data-test-key={tag}>
                      {tag}
                    </span>
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                    {editedTagID === id && tempTag !== tag && (
                      <Button
                        variant="info"
                        onClick={e => {
                          e.stopPropagation();
                          setEditedTagID('');
                          setTempTag('');
                        }}
                        data-testid="restore-btn"
                      >
                        Restore
                      </Button>
                    )}
                    <Button
                      variant={editedTagID === id ? 'success' : 'info'}
                      className="ml-1"
                      disabled={!!editedTagID && tempTag.length < 6}
                      onClick={e => {
                        e.stopPropagation();
                        if (editedTagID === id) {
                          saveEditedTag();
                        } else {
                          setToEdit(id);
                        }
                      }}
                      data-testid="save-or-edit-btn"
                    >
                      {editedTagID === id ? 'Save' : 'Edit'}
                    </Button>
                    <Button
                      variant="danger"
                      className="ml-1"
                      disabled={currentNumberOfTags <= productAmount}
                      onClick={e => {
                        e.stopPropagation();
                        removeTag(id);
                      }}
                      data-testid="remove-btn"
                    >
                      Remove
                    </Button>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>

        <div className="text-right mt-3">
          <UIButton
            className="btn-cancel mr-1"
            action={cancelAllChanges}
            text="Cancel"
            data-testid="cancel-btn"
          />
          <UIButton
            className="save-button-view"
            action={saveChanges}
            disabled={!!editedTagID}
            text="Save"
            data-testid="save-btn"
          />
        </div>
      </Modal.Body>
    </div>
  );
};

export default RemoveTagModal;
