import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import { useDebugValue, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { getSessionKey } from 'reducers/Login';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI2';
import {
  getProductsInShoppingCart,
  getShoppingCartForSalesDocument,
} from 'reducers/ShoppingCart';
import { hardLogout } from 'actions/Login';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import { getPayments } from 'reducers/Payments';

export const DEBUG_SESSION_EXPIRATION = sessionStorage.getItem('pnpSecurity: debug session expiration');

/**
 * Uses the passed in function to schedule rerenders
 * The next rerender is scheduled to be in the number of milliseconds returned from getNextRenderTime
 * When deps change, the currently scheduled rerender is cancelled and a new one is scheduled instead
 *
 * @example
 * // Rerender every 1 second
 * useScheduledRender(() => 1000, [])
 *
 * // Rerender on every wall-clock minute
 * useScheduledRender(() => {
 *   const now = Date.now();
 *   const MS_PER_MINUTE = 1000 * 60;
 *   const minute = MS_PER_MINUTE - Math.ceil(now % MS_PER_MINUTE)
 * }, [])
 *
 * // Rerender every 1 second while foo is true,
 * useScheduledRender(() => foo ? 1000 : 9999999, [foo])
 */
const useScheduledRender = (getNextRenderTime, deps) => {
  const [nextRenderTime, setNextRenderTime] = useState(Date.now() + 1000);
  const reschedule = () => setNextRenderTime(Date.now() + getNextRenderTime());

  useEffect(reschedule, deps);

  useEffect(() => {
    const remaining = nextRenderTime - Date.now();
    if (remaining < Number.MAX_SAFE_INTEGER){
      const t = setTimeout(reschedule, Math.max(0, remaining + 2));
      return () => clearTimeout(t);
    }
    return () => {}
  }, [nextRenderTime]);
};

export const getHasNoCustomerOrCart = createSelector(
  state => 0 < getProductsInShoppingCart(state).length,
  state => getIsDefaultCustomer(state),
  (hasProds, isDefaultCustomer) => !hasProds && isDefaultCustomer,
);
export const getHasNoPayments = createSelector(
  state => getPayments(state),
  pmts => R.isEmpty(pmts),
);

/**
 * @param {number} warningBuffer Buffer time in ms, before expiration, when warning should be shown
 * @param {number} logoutBuffer Buffer time in ms, before expiration, when user should be automatically logged out as soon as possible
 */
export const usePreExpirationLogoutTimer = (
  warningBuffer: number,
  logoutBuffer: number,
) => {
  const sessionKey = useSelector(getSessionKey);

  const { value: expiresAt = 0, loading, error } = useAsync(
    () =>
      doClientRequest({ request: 'getSessionKeyInfo', sessionKey }).then(
        res => {
          if (DEBUG_SESSION_EXPIRATION) {
            // Simulate 90 second expiration on sessionKey
            const t = res.records[0]?.creationUnixTime;
            const future = Number(t) * 1000 + 1000 * 90;
            return future;
          }
          return Number(res.records[0]?.expireUnixTime) * 1000;
        },
      ),
    [sessionKey],
  );

  const now = Date.now();
  const remainingTime = expiresAt ? expiresAt - now : Infinity;

  useScheduledRender(() => {
    const remainingTime = expiresAt - Date.now();
    if (expiresAt === 0) return Infinity;
    if (remainingTime > warningBuffer) return remainingTime - warningBuffer;
    return (((remainingTime % 1000) + 1000) % 1000) + 5;
  }, [expiresAt, warningBuffer, logoutBuffer]);

  const timeUntilWarning = remainingTime - warningBuffer;
  const timeUntilLogout = remainingTime - logoutBuffer;

  const retVal = {
    loading,
    error,
    warningTime:
      !(loading || error) && timeUntilWarning < 0 ? timeUntilLogout : false,
    expirationTime:
      !(loading || error) && timeUntilLogout < 0 ? remainingTime : false,
  } as {
    loading: boolean;
    error: false | Error;
    warningTime: false | number;
    expirationTime: false | number;
  };
  useDebugValue(retVal);
  return retVal;
};
