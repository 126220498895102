import React, { FC, useState } from 'react';
import { Box, Divider, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { isInt, isFloat, isEmpty } from 'validator';
import * as R from 'ramda';
import i18next from 'i18next';

import { SaleDocumentResponse } from 'types/SalesDocument';
import CloseButton from 'components/CustomButtons/CloseButton';
import { openPaymentModal } from 'actions/modalPage';
import SaveButton from 'components/CustomButtons/SaveButton';
import {
  getCurrencyFormatterNoSymbol,
  getDateFormatter,
} from 'reducers/configs/settings';
import * as salesAPI from 'services/ErplyAPI/sales';
import { getHasRightToReturn } from 'reducers/Login';
import { addError } from 'actions/Error';
import { Customer } from 'types/Customer';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

const Item = ({ children }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop="10px"
    >
      {children}
    </Box>
  );
};

const CancellationFeeModal: FC<{
  sale: SaleDocumentResponse;
  customer: Customer;
}> = ({ sale, customer }) => {
  const { t } = useTranslation('layaway');
  const { t: commonT } = useTranslation('common');
  const dispatch = useDispatch();
  const formatDate = useSelector(getDateFormatter);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);
  const hasRightToReturn = useSelector(getHasRightToReturn);

  const outstandingAmount = sale.total - CURR.parse(sale.paid);
  const expiryDate = dayjs(sale.date).add(Number(sale.paymentDays), 'day');

  const [fee, setFee] = useState('');

  const onChange = e => {
    const { name, value } = e.target;
    // Do not allow negative amounts - Berlin treats negative as 0
    let valueToSet = value.replace(/-/g, '');
    if (name === 'cancellationFee') {
      if (isFloat(valueToSet) || isInt(valueToSet) || isEmpty(valueToSet)) {
        if (Number(valueToSet) > sale.total) {
          valueToSet = sale.total.toString();
        }
        setFee(valueToSet);
      }
    }
  };

  const toReturn = CURR.parse(sale.paid) - Number(fee);

  const initCancellation = async () => {
    if (!hasRightToReturn) {
      dispatch(addError(i18next.t('alerts:noRefReturnRights')));
    } else {
      dispatch(
        openPaymentModal({
          props: {
            isReturn: true,
            ignoreCurrent: true,
            salesDocument: {
              clientID: sale.clientID,
              modifiedRows: [],
              total: -sale.total,
              type: 'PREPAYMENT',
              invoiceState: 'CANCELLED',
              advancePayment: sale.advancePayment,
              id: sale.id,
              cancellationFee: Number(fee),
            },
            originalPayments: await salesAPI.getPayments({
              documentID: sale.id,
            }),
            currentSalesDocument: sale,
            customer,
            total: -toReturn,
          },
        }),
      );
      dispatch(closeModalPage());
    }
  };

  return (
    <Box padding="1.25rem" data-testid="layaway-cancellation-fee">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5" data-testid="title" data-test-key={sale.id}>
            {t('headers.layawayReturn', { number: sale.number })}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <SaveButton
            action={initCancellation}
            variant="contained"
            color="secondary"
            style={{ alignItems: 'flex-end', position: 'relative' }}
            data-testid="save-button"
            data-test-key={sale.id}
          >
            {commonT('save_changes').toUpperCase()}
          </SaveButton>
          <CloseButton
            action={() => dispatch(previousModalPage())}
            data-testid="close-button"
            data-test-key={sale.id}
          />
        </Box>
      </Box>
      <Divider style={{ marginBottom: '10px' }} />
      <Item>
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="h6"
          data-testid="customer-title"
          data-test-key={sale.id}
        >
          {t('headers.customer')}
        </Typography>
        <Typography
          variant="h6"
          data-testid="customer-value"
          data-test-key={sale.id}
          style={{ width: '50%' }}
        >
          {sale.clientName}
        </Typography>
      </Item>
      <Item>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          data-testid="startDate-title"
          data-test-key={sale.id}
        >
          {t('fields.startDate')}
        </Typography>
        <Typography
          variant="h6"
          data-testid="startDate-value"
          data-test-key={sale.id}
          style={{ width: '50%' }}
        >
          {formatDate(sale.date)}
        </Typography>
      </Item>
      <Item>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          data-testid="expiryDate-title"
          data-test-key={sale.id}
        >
          {t('fields.expiryDate')}
        </Typography>
        <Typography
          variant="h6"
          data-testid="expiryDate-value"
          data-test-key={sale.id}
          style={{ width: '50%' }}
        >
          {formatDate(expiryDate)}
        </Typography>
      </Item>
      <Item>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          data-testid="invoiceTotal-title"
          data-test-key={sale.id}
        >
          {t('fields.invoiceTotal')}
        </Typography>
        <Typography
          variant="h6"
          data-testid="invoiceTotal-value"
          data-test-key={sale.id}
          style={{ width: '50%' }}
        >
          {CURR.stringify(sale.total)}
        </Typography>
      </Item>
      <Item>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          data-testid="outstandingAmount-title"
          data-test-key={sale.id}
        >
          {t('fields.outstandingAmount')}
        </Typography>
        <Typography
          variant="h6"
          data-testid="outstandingAmount-value"
          data-test-key={sale.id}
          style={{ width: '50%' }}
        >
          {CURR.stringify(outstandingAmount)}
        </Typography>
      </Item>
      <Item>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          data-testid="paid-title"
          data-test-key={sale.id}
        >
          {t('fields.paid')}
        </Typography>
        <Typography
          variant="h6"
          data-testid="paid-value"
          data-test-key={sale.id}
          style={{ width: '50%' }}
        >
          {CURR.stringify(sale.paid)}
        </Typography>
      </Item>
      <Item>
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold' }}
          data-testid="cancellationFee-title"
          data-test-key={sale.id}
        >
          {t('fields.cancellationFee')}
        </Typography>
        <TextField
          name="cancellationFee"
          variant="outlined"
          inputProps={{
            inputmode: 'decimal',
          }}
          size="small"
          value={fee}
          onChange={onChange}
          data-testid="cancellationFee-value"
          data-test-key={sale.id}
          style={{ width: '50%' }}
        />
      </Item>
      <Item>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {t('fields.toReturn')}
        </Typography>
        <Typography variant="h6" style={{ width: '50%' }}>
          {CURR.stringify(Number(sale.paid) - CURR.parse(fee))}
        </Typography>
      </Item>
    </Box>
  );
};

export default CancellationFeeModal;
