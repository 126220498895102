import React, { CSSProperties, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsManualViewMode } from 'reducers/UI/manualViewMode';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import Icon from 'components/Icon';

type Props = {
  code?: string | number;
  color?: string;
  position?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  style?: CSSProperties;
  className?: string;
  index?: number;
};

const QuestionMark: FC<Props> = ({
  code,
  color,
  position = {},
  style = {},
  className = '',
}) => {
  const isManualMode = useSelector(getIsManualViewMode);
  const dispatch = useDispatch();

  if (!isManualMode) return null;

  const { top, bottom, left, right } = position;

  const openManualsModal = e => {
    e.stopPropagation();
    dispatch(
      openModalPage({
        component: mp.Manuals,
        isPopup: true,
        modalClassName: 'manual-modal',
        props: {
          code,
        },
      }),
    );
  };

  return (
    <span
      className={className}
      title="Instructions"
      onClick={openManualsModal}
      style={{
        cursor: 'pointer',
        backgroundColor: color,
        top,
        bottom,
        left,
        right,
        position: 'absolute',
        // padding: '3px 8px',
        borderRadius: '50%',
        color: '#fff',
        ...style,
      }}
    >
      <Icon name="icon_info_alt" />
    </span>
  );
};

export default QuestionMark;
