import { InstallerStatus, installerStatus$ } from '@pos-refacto/installer-rxjs';

import { PosPlugin } from 'plugins/plugin';
import { getProductsInShoppingCart, getTotal } from 'reducers/ShoppingCart';
import { addError, addSuccess } from 'actions/Error';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import {
  getSelectedWarehouseID,
  getSelectedWarehouse,
} from 'reducers/warehouses';
import { ErplyAttributes } from 'utils';
import { getIsCurrentSaleAReturn } from 'reducers/sales';
import { getReturnDocument } from 'reducers/returnProducts';
import { PluginError } from 'plugins/pluginUtils';

import GarminFiscalModal from './components/GarminFiscalModal';
import fiscalIntegrationReducer, {
  getFiscalIntegrationState,
} from './rdx/reducer';
import {
  RESET_FISCAL_STATE,
  SHOULD_SAVE_TAX_INVOICE,
  SET_DOCUMENT_TYPE,
  SET_CUSTOMER_DATA,
} from './rdx/actionTypes';
import singlePrompt from './utils';
import { createFiscalReceipt } from './requests/index';

const FiscalIntegration: PosPlugin = {
  id: 'fiscal-integration',
  name: 'Garmin Fiscal Integration',
  keywords: [
    'Garmin Fiscal Integration',
    'Garmin',
    'fiscal',
    'integration',
    'chile',
  ],
  info: 'Garmin Fiscal Integration',
  components: {
    GarminFiscalModal,
  },
  reduxReducer: fiscalIntegrationReducer,
  getStatus: () => {
    // const { msIsInstalled, status } = getMSDataByEntity('bsoft-fiscal')(state);
    return {
      type: 'warning',
      message: 'Cannot automatically determine fiscal integration status',
    };
  },
  onOpenPaymentModal: {
    on: (p, ap) => async (dispatch, getState) => {
      if (p.isReopeningAfterSaleFailedToSave) return ap;

      const state = getState();
      const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);
      const originalDocument = getReturnDocument(state);
      const storedAttributes: any = isCurrentSaleAReturn
        ? new ErplyAttributes(originalDocument.attributes)
        : null;

      if (isCurrentSaleAReturn) {
        dispatch({ type: SHOULD_SAVE_TAX_INVOICE, payload: true });
        dispatch({ type: SET_DOCUMENT_TYPE, payload: 'Return' });
        dispatch({
          type: SET_CUSTOMER_DATA,
          payload: {
            rutReceiver: storedAttributes.get('taxID'),
            recipientName: storedAttributes.get('recipientName'),
            recipientDraft: storedAttributes.get('recipientDraft'),
            recipientBillingAddress: storedAttributes.get(
              'recipientBillingAddress',
            ),
            recipientBillingCommune: storedAttributes.get(
              'recipientBillingCommune',
            ),
            recipientBillingCity: storedAttributes.get('recipientBillingCity'),
          },
        });
      } else {
        await dispatch(singlePrompt());
      }

      return ap;
    },
  },
  onSaveSalesDocument: {
    on: (p, ep: any) => async (dispatch, getState) => {
      const state = getState();
      const posId = getSelectedPosID(state);
      const currentWarehouse = getSelectedWarehouse(state);
      const warehouseId = getSelectedWarehouseID(state);
      const productsInCart = getProductsInShoppingCart(state);
      const total = getTotal(state);
      const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);
      const originalDocument = getReturnDocument(state);
      const fiscalState = getFiscalIntegrationState(state);
      const { shouldSaveTaxInvoice, customerData, documentType } = fiscalState;
      let data: InstallerStatus | undefined;
      installerStatus$.subscribe(event => {
        data = event;
      });
      const { status, name } =
        data?.data.integrations.find(int => int.name === 'bsoft-fiscal') ?? {};

      if (status !== 'StatusRunning') {
        const errorMessage = `Sale can not be recorded to fiscal service due to ${name ||
          'bsoft-fiscal'} microservice not running. Please start microservice and try again`;
        throw new PluginError(errorMessage, errorMessage);
      }

      const bsoftConf = {} as any;
      const storedAttributes = isCurrentSaleAReturn
        ? new ErplyAttributes(originalDocument.attributes)
        : null;

      const warehouseAttributes = new ErplyAttributes(
        currentWarehouse.attributes,
      );
      const bsoftWarehouseID = warehouseAttributes.get('bsoft_code');
      const bsoftLocal = warehouseAttributes.get('bsoft_local');

      let attributesToStore;

      if (shouldSaveTaxInvoice) {
        const payments = ep.filter(
          request => request.requestName === 'savePayment',
        );
        const saveRequest = ep.filter(
          request => request.requestName === 'saveSalesDocument',
        );

        if (!saveRequest[0].invoiceNo) {
          dispatch(
            addError(
              'Error: El número de folio de Erply no ha sido creado. Favor de reiniciar la venta.',
            ),
          );

          throw new Error('No invoice number created.');
        }

        const receiptData = {
          receiptNumber: String(saveRequest[0].invoiceNo),
          documentType,
          total: Math.abs(total).toFixed(),
          employeeId: saveRequest[0].employeeID,
          posId: bsoftLocal ? String(bsoftLocal) : String(posId),
          warehouseId: bsoftWarehouseID
            ? String(bsoftWarehouseID)
            : warehouseId,
          payments: [
            ...payments.map(payment => {
              return { ...payment, paymentType: 'Cash' };
            }),
          ],
          receiptItems: [
            ...productsInCart.map(product => ({
              code: product.code,
              quantity: String(Math.abs(product.amount)),
              unitPrice:
                documentType === 'Receipt'
                  ? product.finalPriceWithVAT.toFixed()
                  : product.basePrice?.toFixed(),
              totalAmount: Math.abs(product.rowTotal).toFixed(),
              discountAmount: product.totalDiscountAmountWithVAT
                ? product.totalDiscountAmountWithVAT.toFixed()
                : '0',
            })),
          ],
          additionalData: { ...customerData },
          corrections:
            isCurrentSaleAReturn && storedAttributes
              ? [
                  {
                    origDocumentType: storedAttributes.get('documentType'),
                    origDocDateTime: new Date(
                      `${originalDocument.date} ${originalDocument.time}`,
                    ).toISOString(),
                    correctionType: 'Cancel',
                    referenceNumber: storedAttributes.get(
                      'fiscalReceiptNumber',
                    ),
                  },
                ]
              : [],
        };

        const fiscalReceipt = await createFiscalReceipt(
          receiptData,
          Number(bsoftConf.Port),
        );

        if (fiscalReceipt.success) {
          const fiscalReceiptNumber = fiscalReceipt.response[0].referenceNumber;

          if (fiscalReceiptNumber === '0') {
            // false positive
            dispatch(
              addError(
                `La factura de impuestos no se guardó correctamente, razón: ${fiscalReceipt.response[0].statusMessage}`,
                {
                  selfDismiss: true,
                },
              ),
            );
            throw new Error('La factura fiscal no se guardó correctamente');
          }

          // Successful creation of invoice
          const {
            recipientName,
            recipientDraft,
            recipientBillingAddress,
            recipientBillingCommune,
            recipientBillingCity,
            rutReceiver,
          } = customerData;
          attributesToStore = new ErplyAttributes({
            fiscalReceiptNumber,
            documentType,
            taxID: rutReceiver,
            recipientName,
            recipientDraft,
            recipientBillingAddress,
            recipientBillingCommune,
            recipientBillingCity,
          }).asFlatArray;
          dispatch(
            addSuccess(
              `Factura de impuestos guardada con número de referencia: ${fiscalReceiptNumber}`,
              {
                selfDismiss: true,
                dismissible: false,
              },
            ),
          );

          dispatch({ type: RESET_FISCAL_STATE });
        } else {
          dispatch(
            addError(
              'La venta ha sido cancelada, razón: La factura fiscal no se guardó correctamente',
              {
                selfDismiss: true,
              },
            ),
          );
          console.error('FiscalError:', fiscalReceipt.message);
          throw new Error('La factura fiscal no se guardó correctamente');
        }
      }

      return ep.map(request => {
        if (request.requestName === 'saveSalesDocument') {
          return { ...request, ...attributesToStore };
        }
        return request;
      });
    },
  },
};

export default FiscalIntegration;
