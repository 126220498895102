import React from 'react';
import { Skeleton } from '@material-ui/lab';
import * as R from 'ramda';
import { useAsync } from 'react-use';

import { PosPlugin } from 'plugins/plugin';
import { getPayments } from 'services/ErplyAPI/sales';
import { reactcursivelyMap } from 'utils/react';

const UIOrderActionSelection: PosPlugin['UIOrderActionSelection'] = ({
  children,
  sale,
}) => {
  const { value: payments = [], loading } = useAsync(
    () => getPayments({ documentID: sale.id }),
    [sale.id],
  );

  const isOnlineOrder =
    payments.length > 0 && payments.every(payment => payment.type === 'ONLINE');

  function shouldRemoveOption(item) {
    const path = R.path(['props', 'data-testid'])(item);
    return path === 'order-action-selection-cancel-button' && isOnlineOrder;
  }

  function insertItem(item) {
    if (!loading) return item;

    return (
      <Skeleton variant="rect" width="100%" height="100%">
        {item}
      </Skeleton>
    );
  }

  return reactcursivelyMap(
    R.map(
      R.when(
        R.pathEq(['props', 'data-testid'], 'action-selection'),
        R.over(
          R.lensPath(['props', 'children']),
          R.pipe(R.reject(shouldRemoveOption), R.map(insertItem)),
        ),
      ),
    ),
  )(children);
};

export default UIOrderActionSelection;
