import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getAddressTypes, getSelectedCustomer } from 'reducers/customerSearch';
import { AddressTypes } from 'types/Address';
import { Customer } from 'types/Customer';
import { CustomerFormConfiguration } from 'containers/Forms/Settings/views/Customers/types';

import CompanyForm from './CompanyForm';
import PersonForm from './PersonForm';
import { ConfigurableForm } from './components';
import { Person, Company } from './formShapes';

type Props = {
  onChange:
    | React.Dispatch<React.SetStateAction<Person>>
    | React.Dispatch<React.SetStateAction<Company>>;
  customer: Person | Company;
  setFieldIsValid(
    name: keyof Customer | 'senior' | 'suspended' | 'language',
    isValid: boolean,
  ): void;
  configuration: CustomerFormConfiguration;
};

const CustomerForm: React.FC<Props> = ({
  onChange,
  customer,
  setFieldIsValid,
  configuration,
}) => {
  const { customerType } = customer ?? {};

  const selectedCustomer = useSelector(getSelectedCustomer);
  const isEditingCustomer = customer.id === selectedCustomer.id;

  // Should only be used with events,
  // if u want to update a specific key use onChange instead
  const handleChange = useCallback(
    event => {
      const { target } = event;
      const value =
        target.type === 'checkbox' ? Number(target.checked ?? 0) : target.value;
      const { name } = target;

      if (name === 'address') {
        const { addressID, addressFieldKey } = event;

        const newAddresses = customer.addresses ?? [];
        const addressIndex = newAddresses.findIndex(
          a => a.addressID === addressID,
        );

        const newAddress = {
          ...customer.newAddress,
          ...newAddresses[addressIndex], // if index is negative this does nothing
          [addressFieldKey]: value,
        };
        if (addressIndex >= 0) {
          newAddresses[addressIndex] = newAddress;
        }
        return onChange(prev => ({
          newAddress,
          ...prev,
          [name]: newAddresses,
        }));
      }
      if (name === 'suspended') {
        return onChange(prev => ({
          ...prev,
          [name]: !!value,
        }));
      }
      return onChange(prev => ({
        ...prev,
        [name]: value,
      }));
    },
    [customer, onChange],
  );

  const addressTypes: AddressTypes[] = useSelector(getAddressTypes);
  const defaultAddressTypeID = addressTypes?.[0]?.id;
  const missingAddressType = !customer.addressTypeID;

  useEffect(() => {
    if (missingAddressType && defaultAddressTypeID) {
      onChange(prev => ({
        ...prev,
        addressTypeID: defaultAddressTypeID,
      }));
    }
  }, [defaultAddressTypeID, missingAddressType, onChange]);

  const config = configuration.formFields?.[customer.customerType];

  return (
    <ConfigurableForm
      config={config}
      setFieldIsValid={setFieldIsValid}
      isEditingCustomer={isEditingCustomer}
    >
      {customerType === 'COMPANY' ? (
        <CompanyForm
          customer={customer}
          handleChange={handleChange}
          onChange={onChange}
        />
      ) : (
        <PersonForm
          customer={customer}
          handleChange={handleChange}
          onChange={onChange}
        />
      )}
    </ConfigurableForm>
  );
};

export default CustomerForm;
