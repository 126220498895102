import { createSelector } from 'reselect';
import { v1 as uuidv1 } from 'uuid';

import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';
import { addError } from 'actions/Error';
import { SET_SALES_DOCS } from 'constants/returnProducts';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';

import { getCFRState, setIsOnlineReturn } from './rdx';

export const getFunctionButtons: Required<
  PosPlugin
>['selectorOverrides']['getFunctionButtons'] = base =>
  createSelector(
    base,
    getSetting('db_idFormat'),
    getCFRState,
    (baseButtons, DBFormat, { canPerformCFRReturn }) => [
      ...baseButtons,
      {
        id: uuidv1(),
        name: 'Online Return',
        actionType: 'action',
        action: dispatch => {
          if (DBFormat !== '%012d' && canPerformCFRReturn) {
            dispatch(
              addError(
                'Unable to make cross franchise return - wrong dbFormat',
                {
                  selfDismiss: true,
                  dismissible: false,
                },
              ),
            );
            return;
          }

          if (canPerformCFRReturn) dispatch(setIsOnlineReturn(true));

          dispatch(setIsOnlineReturn(true));
          dispatch({ type: SET_SALES_DOCS, payload: [] });

          dispatch(
            openModalPage({
              component: modalPages.ProductReturn,
              modalClassName: 'product-return-modal',
            }),
          );
        },
      },
    ],
  );

export const getIsPaymentDisabled: Required<
  PosPlugin
>['selectorOverrides']['getIsPaymentDisabled'] = base =>
  createSelector(base, getCFRState, (base, { isOnlineReturn }) => {
    if (isOnlineReturn) {
      return () => true;
    }

    return base;
  });

export const getSaleOptionButtons: Required<
  PosPlugin
>['selectorOverrides']['getSaleOptionButtons'] = base =>
  createSelector(base, getCFRState, (baseButtons, { isOnlineReturn }) => {
    if (!isOnlineReturn) return baseButtons;

    return baseButtons.map(button => {
      if (button.id === 'newSale') {
        return button;
      }

      return {
        ...button,
        disabled: true,
      };
    });
  });
