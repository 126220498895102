import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import { getLastOrder, getSelectedOrder } from 'reducers/ShoppingCart';
import { ErplyAttributes } from 'utils';
import { addSuccess } from 'actions/Error';
import { updateProductNotes } from 'actions/ShoppingCart';
import InputField from 'components/FieldTypes/InputField';

import NoteButtons from './NoteButtons';
import useProducts from 'utils/hooks/useProducts';

export const Notes = () => {
  const dispatch = useDispatch();
  let order = useSelector(getSelectedOrder);
  const lastOrder = useSelector(getLastOrder);
  if (!order) {
    order = lastOrder;
  }
  const {
    products: [product]
  } = useProducts({productID: order.productID})

  const preNotes = new ErplyAttributes(product?.attributes).get('pre_notes');
  const [notesField, setNotesField] = useState(
    !order.notes
      ? preNotes?.replace(/<br ?\/?>/g, '\n')
      : order.notes?.replace(/<br ?\/?>/g, '\n'),
  );

  const rowsCount = notesField?.split('\n').filter(chunk => chunk).length ?? 1;

  const saveNotesForSpecificProduct = (notes, orderIndex) => {
    dispatch(
      addSuccess('Notes are saved', {
        selfDismiss: 1500,
        dismissible: false,
      }),
    );
    return dispatch(updateProductNotes(notes, orderIndex));
  };

  return (
    <>
      <div className="notes">
        <h4>Add notes</h4>
        <InputField
          size="lg"
          type="textarea"
          style={{
            minHeight: `${rowsCount * 32}px`,
          }}
          value={notesField}
          onChange={e => setNotesField(e.target.value)}
          onSubmit={e => setNotesField(`${e.target.value}\n`)}
          appendTitle={
            <Button
              onClick={() =>
                saveNotesForSpecificProduct(notesField, order.orderIndex)
              }
            >
              Save
            </Button>
          }
          data-testid="notes"
        />
      </div>

      <NoteButtons currentNote={notesField} onClick={setNotesField} />
    </>
  );
};
