import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardContentProps,
  LinearProgress,
  Paper,
  Theme,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { getSelectedCustomerID } from 'reducers/customerSearch';

interface HeightProps {
  minHeight: number;
  maxHeight: number;
  expanded: boolean;
}

type Props = Omit<HeightProps, 'expanded'> & {
  loading: boolean;
  onClick: () => void;
};

const useStyles = makeStyles<Theme, HeightProps>({
  positionOverride: {
    position: 'static',
    height: props => `${props.minHeight}px`,
    // Makes the WBU Custom badge fit more info and enables to increase the font size
    marginLeft: '-5px',
    marginRight: '-5px',
  },
  popoverContainer: {
    position: 'relative',
    height: props => `${props.minHeight}px`,
    zIndex: 30,
  },
  popover: {
    display: 'flex',
    position: 'absolute',
    overflowY: 'auto',
    maxHeight: props =>
      `${props.expanded ? props.maxHeight : props.minHeight}px`,
    top: 0,
    bottom: props => `-${props.expanded ? props.maxHeight : props.minHeight}px`,
    left: 0,
    right: 0,
  },
  expandButtonContainer: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
});

const ExpandableCardContent = styled(CardContent)<
  Theme,
  CardContentProps & HeightProps & { expanded?: boolean }
>(({ minHeight, maxHeight, expanded = false }) => ({
  padding: '0 !important',
  ...(expanded
    ? { maxHeight: `${maxHeight}px` }
    : { height: `${minHeight}px` }),
  overflowX: 'hidden',
  overflowY: expanded ? 'auto' : 'hidden',
  flex: '1 1 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const ExpandableCustomerBadgeRegion: FC<PropsWithChildren<Props>> = ({
  loading,
  minHeight,
  maxHeight,
  onClick,
  children,
}) => {
  /* Expand/hide logic */
  const selectedCustomerId = useSelector(getSelectedCustomerID);

  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  const styles = useStyles({ minHeight, maxHeight, expanded });

  useEffect(() => {
    setExpanded(false);
  }, [selectedCustomerId]);

  const ExpandIcon = expanded ? ExpandLess : ExpandMore;

  return (
    <div className={styles.positionOverride}>
      <div className={styles.popoverContainer}>
        <Paper className={styles.popover}>
          <Card
            variant="outlined"
            style={{
              maxHeight: `${maxHeight}px`,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {loading && <LinearProgress />}
            <ExpandableCardContent
              ref={ref}
              expanded={expanded}
              minHeight={minHeight}
              maxHeight={maxHeight}
              onClick={onClick}
            >
              <div style={{ marginBottom: '2em', padding: '0.5em' }}>
                {children}
              </div>
              <Paper className={styles.expandButtonContainer}>
                <Button
                  fullWidth
                  onClick={e => {
                    e.stopPropagation();
                    ref?.current?.scrollTo(0, 0);
                    setExpanded(!expanded);
                  }}
                  title={expanded ? 'Collapse' : 'Expand'}
                >
                  <ExpandIcon />
                </Button>
              </Paper>
            </ExpandableCardContent>
          </Card>
        </Paper>
      </div>
    </div>
  );
};

export default ExpandableCustomerBadgeRegion;
