import { getSelectedWarehouseID } from 'reducers/warehouses';
import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';
import {
  getInventoryWriteOffRequests,
  setInventoryWriteOffRequests,
} from 'plugins/mm/rdx';
import { addError } from 'actions/Error';
import { SaveInventoryWriteOffParams } from 'types/InventoryWriteOff';
import { saveInventoryWriteOff } from 'services/ErplyAPI/inventoryWriteOffs';
import i18next from 'containers/App/i18n';
import {
  spreadCart,
  unspreadCart,
} from 'utils/apiTransformations/saveSalesDocumentCart';

export const generateSaveInventoryWriteOffRequests: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (_p, ap) => async (dispatch, getState) => {
  const saleRequest = ap.find(
    request => request.requestName === 'saveSalesDocument',
  );
  if (!saleRequest) return ap;

  const shouldSendInventoryWriteOffRequest =
    saleRequest.type === 'CREDITINVOICE' &&
    ['RETURN', 'VOID'].includes(saleRequest.creditInvoiceType) &&
    saleRequest.invoiceNo;

  if (!shouldSendInventoryWriteOffRequest) return ap;

  const automaticallyTransferredReasonCodeIds = getSetting(
    'automatically_transfered_reason_code_ids',
  )(getState());
  if (!automaticallyTransferredReasonCodeIds.length) return ap;

  const rows = unspreadCart(saleRequest).cart.filter(
    row => `productID` in row && `amount` in row && `returnReasonID` in row,
  );

  const reasonCodeTransferWarehouseId = getSetting(
    'reason_code_transfer_warehouse_id',
  )(getState());
  const selectedWarehouseID = getSelectedWarehouseID(getState());
  const baseRequest = {
    warehouseID:
      Number(reasonCodeTransferWarehouseId) || Number(selectedWarehouseID),
    comments: `${saleRequest.invoiceNo}K`,
  };

  const inventoryWriteOffRequests = automaticallyTransferredReasonCodeIds
    .filter(reasonId =>
      rows.some(row => String(row.returnReasonID) === reasonId),
    )
    .map(reasonId => {
      const rowsWithMatchingReasonId = rows
        .filter(row => String(row.returnReasonID) === reasonId)
        .map(({ productID, amount }) => ({
          productID,
          amount: Math.abs(Number(amount)),
        }));
      const productInfo = spreadCart({
        cart: rowsWithMatchingReasonId,
      });
      return {
        ...baseRequest,
        ...productInfo,
        reasonID: Number(reasonId),
      };
    });

  dispatch(setInventoryWriteOffRequests(inventoryWriteOffRequests));

  return ap;
};

export const sendInventoryWriteOffRequests: Required<
  PosPlugin
>['onSaveSalesDocument']['after'] = () => async (dispatch, getState) => {
  const requests = getInventoryWriteOffRequests(getState());
  if (!requests.length) return;

  try {
    await Promise.all(requests.map(saveInventoryWriteOff));
  } catch (error) {
    console.error(error);
    dispatch(addError(i18next.t('alerts:failedToSaveInventoryWriteOffs')));
  } finally {
    dispatch(setInventoryWriteOffRequests([]));
  }
};

export const resetInventoryWriteOffRequests: Required<
  PosPlugin
>['onClosePayments']['after'] = () => async dispatch => {
  dispatch(setInventoryWriteOffRequests([]));
};
