import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

const GarminInputs = ({ resolve, reject, vatNumber, address }) => {
  const { t } = useTranslation('customPrompts');

  const dispatch = useDispatch();

  const [vatNumberValue, setVatNumber] = useState(vatNumber);
  const [addressOne, setaddress] = useState(address);

  const confirm = () => {
    dispatch(previousModalPage());
    resolve({ vatNumber: vatNumberValue, address: addressOne });
  };

  useShortcut('Enter', confirm, 20);

  const cancel = () => {
    dispatch(previousModalPage());
    reject();
  };

  return (
    <div data-testid="custom-prompt">
      <Modal.Header>
        <Modal.Title data-testid="title">Company data</Modal.Title>
        <span style={{ flexGrow: 1 }} />
        <UIButton
          data-testid="save"
          variant="POS"
          text={t('buttons.confirm')}
          action={confirm}
        />

        <CloseButton
          data-testid="cancel"
          action={cancel}
          disabled={!vatNumberValue || !addressOne}
        />
      </Modal.Header>
      <Modal.Body>
        {!vatNumber ? (
          <InputField
            key="garmin-vatNumber"
            data-testid="garmin-vatNumber"
            data-test-key="garmin-vatNumber"
            title="VAT Number"
            onChange={e => {
              setVatNumber(e.target.value);
            }}
            autoFocus={true}
            value={vatNumberValue}
          />
        ) : null}
        {!address.trim() ? (
          <InputField
            key="garmin-address"
            data-testid="garmin-address"
            data-test-key="garmin-address"
            title="Address"
            onChange={e => {
              setaddress(e.target.value);
            }}
            autoFocus={false}
            value={addressOne}
          />
        ) : null}
      </Modal.Body>
    </div>
  );
};

export default GarminInputs;
