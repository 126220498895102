import { addSuccess } from 'actions/Error';

import { printReceipts } from '../utils/printReceipts';

const handlePaymentSuccess = (
  response,
  cardPayment,
  { updateMessage, beforeDocSave, rejectPayments },
) => async dispatch => {
  if (response && response.data.records.length) {
    const result = response.data.records[0];
    const receipts = result.additionalData;

    // resultCode 0 means successful payment
    if (Number(result.resultCode) === 0) {
      await beforeDocSave({
        key: cardPayment.key,
        type: 'CARD',
        amount: result.approvedAmount,
        cardType: result.paymentType || result.cardType,
        cardNumber: result.cardNumber.slice(-4),
        attributes: {
          refNo: `${result.entryMode}:${result.authCode}.${
            result.referenceNumber
          },${result.dateTime.replace(' ', '&')}`,
        },
        paid: true,
      });

      if (receipts) {
        dispatch(printReceipts(receipts));
      }
      return result;
    }
    // means cancellation
    if (Number(result.resultCode) === 2) {
      rejectPayments();
      dispatch(addSuccess('Payment cancellation was successful'));
    }
    updateMessage(`Unsuccessful payment - ${result.statusMessage}`);
    return false;
  }
  return updateMessage(
    `Unsucessful payment.\n  Response not provided - ${JSON.stringify(
      response,
    )}`,
  );
};

export default handlePaymentSuccess;
