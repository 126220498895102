/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';

import { proxy } from 'services/shared';
import {
  REDUX_SERVICE_ENDPOINTS,
  REDUX_CLIENTCODE,
  REDUX_SESSIONKEY,
} from 'constants/persistence';
import { getServiceEndpoints } from 'services/ErplyAPI/getServiceEndpoints';

const getJsonApiUrl = async () => {
  const { url } = JSON.parse(
    localStorage.getItem(REDUX_SERVICE_ENDPOINTS) || '{}',
  )?.json;
  if (url) return url;
  const [payload] = await getServiceEndpoints();
  return payload?.json?.url;
};

export const saveViiPaymentsToJson = async (id, data) => {
  const jsonApiUrl = await getJsonApiUrl();
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  return axios.put(
    `${proxy}${jsonApiUrl}v1/json_object/payment/${id}`,

    {
      id,
      json_object: {
        'vii-brazil': data,
      },
    },
    {
      headers: { clientCode, sessionKey },
    },
  );
};

export const saveViiProductsToJson = async (id, data) => {
  const jsonApiUrl = await getJsonApiUrl();
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) || '');
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '');
  console.log({ id, data });
  return axios.put(
    `${proxy}${jsonApiUrl}v1/json_object/inv_rows/${id}`,

    {
      id,
      json_object: {
        'vii-brazil': data,
      },
    },
    {
      headers: { clientCode, sessionKey },
    },
  );
};
