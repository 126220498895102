import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "@material-ui/core";

import { getPayments } from "reducers/Payments";
import { setPayment } from "actions/Payments/setPayment";
import { securityCheckpoints } from "plugins/pnp/security/redux/overrides";
import { ErplyAttributes } from "utils";
import Icon from "components/Icon";
import { getUserLoggedIn } from "reducers/Login";
import { getUserGroups } from "services/ErplyAPI";
import { getCurrentModalPage } from "reducers/modalPage";
import { previousModalPage } from "actions/ModalPage/previousModalPage";
import { addWarning } from "actions/Error";
import { ComponentConfiguration, Configuration } from "plugins/pnp/security/PluginConf";
import { hardLogout } from "actions/Login";
import {
  DEBUG_SESSION_EXPIRATION,
  getHasNoCustomerOrCart,
  getHasNoPayments,
  usePreExpirationLogoutTimer
} from "plugins/pnp/security/preExpirationLogout/preExpirationLogout";
import { useConfirmation } from "components/Confirmation";

import { PosPlugin } from "../../plugin";

import { SecurityPopup } from "./securityPopup";
import {
  getAllOverridesString,
  getHasOverrides,
  getOverrideAttributes,
  promptForOverrides,
  reducer,
  resetOverrides,
  unsetOverrides
} from "./redux/base";

/**
 * When active is true, prevents any settings modalpages from opening and closes any that had been opened previously
 */
const useDisallowSettings = active => {
  const mps = useSelector(getCurrentModalPage);
  const dispatch = useDispatch();

  useEffect(() => {
    const needsEvac =
      active && mps?.component && /^Settings/gi.test(mps?.component);
    if (needsEvac) {
      dispatch(
        addWarning("You don't have rights to view this section", {
          selfDismiss: 3000,
        }),
      );
      dispatch(previousModalPage());
    }
  }, [mps?.component, active]);
};

// @ts-ignore
export const pluginID = 'pnp-security';
const PnpSecurityPlugin: PosPlugin<Configuration> = {
  id: pluginID,
  name: '[PNP] Security plugin',
  keywords: ['pnp'],
  ComponentHeader: ({ children }) => {
    const timer = a => {
      const d = new Date('2021-01-01T00:00:00Z');
      d.setSeconds(a / 1000);
      return d
        .toISOString()
        .split('T')[1]
        .split('.')[0];
    };

    const dispatch = useDispatch();

    const _1h = 1000 * 60 * 60;
    const _1s = 1000;
    const expiration = usePreExpirationLogoutTimer(
      DEBUG_SESSION_EXPIRATION ? 60 * _1s : 2 * _1h,
      DEBUG_SESSION_EXPIRATION ? 30 * _1s : _1h
    );

    const safeToLogout = useSelector(getHasNoCustomerOrCart);
    const almostSafeToLogout = useSelector(getHasNoPayments);
    const autoLogout =
      !expiration.loading &&
      !expiration.error &&
      ((expiration.warningTime && safeToLogout) ||
        (expiration.expirationTime && almostSafeToLogout));
    const confirm = useConfirmation();
    useEffect(() => {
      if (autoLogout) {
        confirm({
          title: 'Session is about to expire',
          body:
            'Your session is about to expire, you will have to log in again',
          noReject: true,
        }).then(() => dispatch(hardLogout()));
      }
    }, [autoLogout]);

    const component = (() => {
      if (expiration.error) {
        return (
          <Icon
            name="icon_lock"
            style={{ color: 'red' }}
            title="Manager override plugin enabled: Failed to load session expiration data"
          />
        );
      }
      if (expiration.loading) {
        return (
          <Icon
            name="icon_lock"
            style={{ color: 'orange' }}
            title="Manager override plugin enabled: Loading session data..."
          />
        );
      }
      if (0 < expiration.warningTime) {
        return (
          <span title="Manager override plugin enabled">
            <Badge color="primary" badgeContent={timer(expiration.warningTime)}>
              <Icon name="icon_lock" />
            </Badge>
          </span>
        );
      }
      if (0 < expiration.expirationTime) {
        return (
          <span title="Manager override plugin enabled">
            <Badge
              color="secondary"
              badgeContent={timer(expiration.expirationTime)}
            >
              <Icon name="icon_lock" />
            </Badge>
          </span>
        );
      }
      return <Icon name="icon_lock" title="Manager override plugin enabled" />;
    })();

    return (
      <>
        {children}
        {component}
      </>
    );
  },
  ComponentConfigurationByLevel: {
    Company: ComponentConfiguration,
  },
  combineConfiguration: c => c ?? { volumeBuyerCustomerGroupID: null },
  ComponentHeaderSettings: ({ children }) => {
    const [visible, setVisible] = useState(true);
    const user = useSelector(getUserLoggedIn);
    // DEV note: if you want to access settings, comment code from here --
    useEffect(() => {
      getUserGroups({ userGroupID: user.groupID })
        .then(([group]) => {
          return !!Number(
            new ErplyAttributes(group.attributes).get(
              `${PnpSecurityPlugin.id}_permission-accessSettings`,
            ),
          );
        })
        .then(hasPermission => {
          setVisible(hasPermission);
        });
    }, [user]);

    useDisallowSettings(!visible);
    // -- To here

    if (visible) return children;
    return <div style={{ visibility: 'hidden' }}>{children}</div>;
  },
  getStatus: () => ({
    type: 'valid',
    message: 'Configure permissions in BO',
  }),
  // language=Markdown
  info: `
Adds additional rights to user groups that must be configured in the backoffice.
When a right is missing, the user will be prompted to authorize with a different account
`,
  reduxReducer: reducer,
  onOpenPaymentModal: {
    on: (p, ap) => async dispatch => {
      dispatch(resetOverrides('salesDoc'));
      await dispatch(securityCheckpoints.onOpenPayment());
      return ap;
    },
  },
  onTryCashInOut: {
    on: (p, ap) => async dispatch => {
      dispatch(resetOverrides('cashInOut'));
      await dispatch(securityCheckpoints.onCashInout(p));
      return ap;
    },
  },
  components: {
    pnpSecurityPopup: SecurityPopup,
  },
  reduxActions: {
    showManagerOverride: promptForOverrides,
    clearManagerOverrideData: unsetOverrides,
  },
  /**
   * When clicking on locked tender - prompt for override to unlock all
   */
  onPaymentClick: {
    before: ({ paymentKey, payment }) => async (dispatch, getState) => {
      if (payment.locked) {
        await dispatch(securityCheckpoints.onClickLockedTender());
        const payments: Record<string, Record<string, any>> = getPayments(
          getState(),
        );
        await Promise.all(
          Object.entries(payments).map(([key, payment]) =>
            dispatch(setPayment({ key, ...payment, locked: false })),
          ),
        );
      }
    },
  },
  onSaveSalesDocument: {
    on: (p, requests) => async (dispatch, getState) => {
      if (!getHasOverrides('salesDoc')(getState())) {
        return requests;
      }
      const mapped = requests.map(r => {
        if (r.requestName === 'saveSalesDocument') {
          const overrides = getAllOverridesString('salesDoc')(getState());
          const existingAttributes = ErplyAttributes.fromFlatArray(r);
          const ours = getOverrideAttributes('salesDoc')(getState());
          const newAttributes = existingAttributes.merge(ours);

          return {
            ...r,
            internalNotes: `${r.internalNotes ??
              ''}\n=== The following manager overrides were applied to this sale ===\n${overrides}`,
            ...newAttributes.asFlatArray,
          };
        }
        return r;
      });
      return mapped;
    },
  },
  // @ts-ignore
  onCloseDay: {
    before: () => async dispatch => {
      // Reset any previous overrides from cancelled closeDay attempts
      dispatch(resetOverrides('dayOpening'));
      // Prompt for fresh close day overrides
      // Cancelling will throw an error and abort the opening of closeDay
      await dispatch(securityCheckpoints.onCloseDay());
    },
    on: (p, requests) => async (dispatch, getState) => {
      // Skip if no overrides were necessary
      if (!getHasOverrides('dayOpening')(getState())) {
        return requests;
      }

      // Else add human-readable descriptions to the notes and machine-readable data as attributes
      const mapped = requests.map(r => {
        const overrides = getAllOverridesString('dayOpening')(getState());
        return {
          ...r,
          notes: `=== The following manager overrides were applied to this drawer closing ===\n${overrides}`,
          ...getOverrideAttributes('dayOpening')(getState()).asFlatArray,
        };
      });
      return mapped;
    },
  },
  onSoftLogout: {
    before: p => async (dispatch, getState) => {
      dispatch(hardLogout());
      throw new Error('[PNP Security plugin]: All logouts are hard');
    },
  },
};
export default PnpSecurityPlugin;
