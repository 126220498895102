import i18next from 'i18next';

import { getGiftCards } from 'services/ErplyAPI/payment';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getSelectedPos } from 'reducers/PointsOfSale';
import * as api from 'services/ErplyAPI/products';
import { GIFTCARDS_UPDATE, GIFTCARDTYPES_UPDATE } from 'constants/giftcards';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { modalPages } from 'constants/modalPage';
import { CartItem } from 'containers/Forms/GiftCardSerial/types';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import {
  getCurrencyFormatter,
  getPosDefaultLanguage,
  getSetting,
} from 'reducers/configs/settings';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import {
  getBalance,
  getIsCurrentSaleAReturn,
  getIsSerialGiftcardDisabled,
  getNewPaymentKeyForType,
  getPaymentSelected,
  getPayments,
  getPaymentsCurrency,
  getTotal,
} from 'reducers/Payments';
import { getGiftcardTypes } from 'reducers/giftcards';
import { GetGiftCardResponse } from 'services/ErplyAPI/core/types';

import { addError, addWarning, dismissType } from './Error';
import { addProduct } from './ShoppingCart/addProduct';
import { setPaymentSelected } from './Payments/setPaymentSelected';
import { setPayment } from './Payments/setPayment';

export function fetchRegularGiftCards() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { warehouseID } = getSelectedPos(state);
      const params = {
        regularGiftCards: 1,
        warehouseID,
      };
      // TODO: need to find out if we need to fetch ALL the existing gift cards (provide from and count to the request)
      // Also research if it would make sense to add "active: 1" flag to get only active gift card products
      const { records } = await api.getProducts(params);
      const payload: { name: string; vatRateID: any; productID: any }[] = [];
      records.forEach(card => {
        payload.push({
          name: card.name,
          vatRateID: card.vatrateID,
          productID: card.productID,
        });
      });
      payload.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      // region What is this? doesn't look like it's used by anything
      let counter = 0;
      payload.forEach(c => {
        Object.assign(c, { value: counter });
        counter += 1;
      });
      // endregion
      await dispatch({
        type: GIFTCARDS_UPDATE,
        payload,
      });
      return payload;
    } catch (e) {
      console.error('Failed to load regular gift cards', e);
    }
  };
}

export function fetchGiftcardTypes() {
  return async (dispatch, getState) => {
    try {
      const types = await doClientRequest({
        request: 'getGiftCardTypes',
      });
      dispatch({
        type: GIFTCARDTYPES_UPDATE,
        payload: types,
      });
    } catch (err) {
      console.error('Failed to load gift card types', err);
    }
  };
}

export function addGiftCardToCartIfSerialUnused({
  productID,
  giftCardSerial,
}: {
  productID: number;
  giftCardSerial: string;
}) {
  return async (dispatch, getState) => {
    const cart: CartItem[] = getProductsInShoppingCart(getState());
    const shouldOpenProductView = !getSetting(
      'touchpos_disable_product_row_auto_open',
    )(getState());

    const { before, on, after } = getPluginLifecycleHook(
      'onAddSerialGiftCardProduct',
    )(getState());

    await dispatch(before({ productID, giftCardSerial }));

    dispatch(
      addWarning(
        i18next.t('giftcard:alerts.fetchingSerial', { serial: giftCardSerial }),
        {
          dismissible: true,
          errorType: 'giftCardSerialFetching',
          selfDismiss: 30000,
        },
      ),
    );
    const exists = cart.some(c => c.giftCardSerial === giftCardSerial);
    if (exists) {
      dispatch(dismissType('giftCardSerialFetching'));
      dispatch(
        addWarning(
          i18next.t('giftcard:alerts.serialAlreadyAdded', {
            serial: giftCardSerial,
          }),
          {
            dismissible: true,
            errorType: 'giftCardSerialAlreadyAdded',
            selfDismiss: 3000,
          },
        ),
      );
      return;
    }
    try {
      const result = await getGiftCards({ code: giftCardSerial });
      if (result.length) {
        dispatch(dismissType('giftCardSerialFetching'));
        dispatch(
          addError(
            i18next.t('giftcard:alerts.serialAlreadyUsed', {
              serial: giftCardSerial,
            }),
            {
              dismissible: true,
              errorType: 'giftCardSerialAlreadyUsed',
              selfDismiss: 3000,
            },
          ),
        );
        return;
      }
    } catch (e) {
      const error = e as Error;
      dispatch(addError(error.message));
    } finally {
      dispatch(dismissType('giftCardSerialFetching'));
    }

    try {
      const giftCardProduct = await dispatch(
        on({ productID, giftCardSerial }, { productID, giftCardSerial }),
      );

      dispatch(addProduct(giftCardProduct));
      dispatch(previousModalPage());

      if (shouldOpenProductView) {
        dispatch(
          openModalPage({
            component: modalPages.ProductOrderView,
          }),
        );
      }
      await dispatch(after({ productID, giftCardSerial }, null));
    } catch (err) {
      console.error(
        'Failed to add giftcard product to cart automatically',
        err,
      );
    }
  };
}

export function fetchSerialGiftCard(code: string) {
  return async (dispatch, getState): Promise<GetGiftCardResponse | null> => {
    const isSerialGiftcardDisabled = getIsSerialGiftcardDisabled(getState());

    if (isSerialGiftcardDisabled) {
      dispatch(
        addWarning(i18next.t('payment:alerts.serialGiftcardDisabled'), {
          dismissible: false,
          errorType: 'giftCard',
          selfDismiss: 2000,
        }),
      );
      return null;
    }
    // check for empty serial field
    if (!code) {
      dispatch(
        addWarning(i18next.t('payment:alerts.giftcardSerialRequired'), {
          dismissible: false,
          errorType: 'giftCard',
          selfDismiss: 2000,
        }),
      );
      return null;
    }

    const payments: Record<string, any> = getPayments(getState());

    // check for card being used in the payment already
    const existsInPayments = Object.values(payments).filter(
      payment => payment.serial === code,
    ).length;
    if (existsInPayments) {
      dispatch(
        addWarning(
          i18next.t('payment:alerts.giftcardSerialAlreadyAdded', {
            serial: code,
          }),
          {
            dismissible: false,
            errorType: 'giftCard',
            selfDismiss: 2000,
          },
        ),
      );
      return null;
    }

    // fetch the requested card
    dispatch(
      addWarning(
        i18next.t('payment:alerts.fetchingSerialGiftcard', { serial: code }),
        {
          dismissible: false,
          errorType: 'giftCard',
          selfDismiss: 30000,
        },
      ),
    );
    try {
      // Check for card existence
      const [card] = await getGiftCards({ code });
      dispatch(dismissType('giftCard'));
      if (!card) {
        dispatch(
          addError(
            i18next.t('payment:alerts.giftcardSerialNotFound', {
              serial: code,
            }),
            {
              dismissible: true,
              errorType: 'giftCard',
              selfDismiss: 3000,
            },
          ),
        );
        return null;
      }

      // check the expiry date
      const expiryDate = new Date(card.expirationDate);
      const today = new Date();
      if (expiryDate.setDate(expiryDate.getDate() + 1) < today.getTime()) {
        dispatch(
          addError(i18next.t('payment:alerts.giftcardExpired'), {
            dismissible: true,
            errorType: 'giftCard',
            selfDismiss: 3000,
          }),
        );
        return null;
      }

      // check if it's used up
      if (card.balance <= 0) {
        dispatch(
          addError(
            i18next.t('payment:alerts.giftcardInsufficientBalance', {
              serial: code,
              balance: card.balance,
            }),
            {
              dismissible: true,
              errorType: 'giftCard',
              selfDismiss: 3000,
            },
          ),
        );
        return null;
      }

      const total = getTotal(getState());
      const isCurrentSaleAReturn = getIsCurrentSaleAReturn(getState());
      const isReturn = isCurrentSaleAReturn || total < 0;

      const format = getCurrencyFormatter(getState());

      if (!isReturn && card.minimumSpend > total) {
        dispatch(
          addError(
            i18next.t('payment:alerts.giftcardMinimumSpend', {
              serial: code,
              minimumSpend: format(card.minimumSpend),
            }),
            {
              dismissible: true,
              errorType: 'giftcardMinimumSpend',
              selfDismiss: 3000,
            },
          ),
        );
        return null;
      }
      return card;
    } catch (e) {
      dispatch(
        addError(
          i18next.t('payment:alerts.failedToFetchGiftCard', {
            serial: code,
          }),
          {
            dismissible: true,
            selfDismiss: 3000,
          },
        ),
      );
      console.error('Failed to fetch gift card. Reason: ', e);
      return null;
    }
  };
}

export function addSerialGiftCardPayment(
  card: GetGiftCardResponse,
  paymentAmount: string,
) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook(
      'onAddSerialGiftCardPayment',
    )(getState());

    try {
      await dispatch(before({ card, paymentAmount }));

      const { code: currency, rate: currencyRate } = getPaymentsCurrency(
        getState(),
      );
      const paymentKey = getNewPaymentKeyForType('giftCard')(getState());
      const balance = getBalance(getState());
      const gcTypes = getGiftcardTypes(getState());
      const selectedPayment = getPaymentSelected(getState());
      const typeID =
        selectedPayment === 'giftcard'
          ? ''
          : selectedPayment.replace(/giftcard-/, '');
      const giftCard = gcTypes.find(t => String(t.id) === String(typeID));
      const type = giftCard?.name;

      const giftCardSumBiggerThanNeeded =
        Number(paymentAmount) > Math.abs(balance);
      const finalAmount = giftCardSumBiggerThanNeeded
        ? balance
        : Number(paymentAmount);

      const payment = {
        amount: String(finalAmount),
        key: paymentKey,
        caption: type ?? i18next.t('payment:tenders.serialGiftCard'),
        type: 'GIFTCARD',
        serial: card.code,
        giftCardID: String(card.giftCardID),
        giftCardTypeID: card.typeID,
        giftCardBalance: String(card.balance),
        vatrateID: String(card.vatrateID),
        currency,
        currencyCode: currency,
        currencyRate,
      };
      const updatedPayment = await dispatch(
        on({ card, paymentAmount }, payment),
      );
      await dispatch(setPayment(updatedPayment));
      await dispatch(setPaymentSelected(''));
      await dispatch(after({ card, paymentAmount }, null));
    } catch (error) {
      console.error(error);
    }
  };
}
