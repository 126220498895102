import React, { useState } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import { GridButton } from 'components/GridButton';
import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getGridButtonSizeConfig } from 'reducers/cafaConfigs';
import { saveCafaConfig } from 'actions/integrations/CafaConfigs';
import { CAFA_LEVELS, INTEGRATIONS, INTEGRATION_TYPES } from 'constants/CAFA';
import { getSelectedWarehouseID } from 'reducers/warehouses';

import InputSlider from './RangeSlider';

function GridButtonSize({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsGridButtons');

  const { buttonSize: savedButtonSize, fontSize: savedFontSize } = useSelector(
    getGridButtonSizeConfig,
  );
  const warehouseID = useSelector(getSelectedWarehouseID);

  const [buttonSize, setButtonSize] = useState(savedButtonSize);
  const [fontSize, setFontSize] = useState(savedFontSize);

  const save = async () => {
    await dispatch(
      saveCafaConfig({
        integrationName: INTEGRATIONS.posConfigurations.gridButton,
        integrationType: INTEGRATION_TYPES.posConfigurations,
        level: { level: CAFA_LEVELS.Warehouse, id: warehouseID },
        config: { buttonSize, fontSize },
      }),
    );
    onClose();
  };

  return (
    <div data-testid="gift-card-balance">
      <Box display="flex" alignItems="center" padding="1rem">
        <Box paddingLeft="5px" fontSize="24px" fontWeight={700}>
          {t('title')}
        </Box>
        <Box flexGrow={1} />
        <SaveButton
          action={save}
          disabled={
            savedButtonSize === buttonSize && savedFontSize === fontSize
          }
          variant="POS"
        />
        <CloseButton action={onClose} />
      </Box>
      <Divider />
      <Box padding="1rem">
        <Alert severity="info" style={{ marginBottom: '1rem' }}>
          {t('configLevelInfo')}
        </Alert>
        <Grid container spacing={4}>
          <Grid item>
            <InputSlider
              title={t('inputs.buttonSize')}
              value={buttonSize}
              onChange={setButtonSize}
              min={1}
              max={1.5}
              step={0.05}
            />
            <InputSlider
              title={t('inputs.fontSize')}
              value={fontSize}
              onChange={setFontSize}
              min={14}
              max={20}
              step={1}
            />
          </Grid>
          <Grid item>
            <Box marginBottom="1rem">{t('preview')}:</Box>
            <GridButton
              btnColor="grid_light"
              style={{
                width: 95 * buttonSize,
                height: 65 * buttonSize,
                fontSize,
              }}
            >
              Lorem ipsum dolor sit
            </GridButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default GridButtonSize;
