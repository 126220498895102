import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { RootState } from 'reducers';

import { printReceipts } from '..';

export const handleSuccess = ({
  payment,
  response,
  inputData,
  beforeDocDelete,
  beforeDocSave,
}) => async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
  if (inputData.transactionType === 'VOID') {
    await beforeDocDelete(payment.key);
  } else {
    const amount =
      inputData.transactionType === 'REFUND'
        ? `-${Number(response.approvedAmount).toFixed(2)}`
        : Number(response.approvedAmount).toFixed(2);
    await beforeDocSave({
      ...payment,
      transactionId: response.referenceNumber,
      paymentType: response.paymentType,
      amount,
      attributes: {
        refNo: response.referenceNumber,
        authCode: response.authCode,
        hostReferenceNumber: response.hostReferenceNumber,
      },
      ...response,
      paid: true,
    });
    if (response.additionalData) {
      dispatch(printReceipts(response.additionalData));
    }
  }
};
