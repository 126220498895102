import axios from 'axios';

import { EconomicSubject } from './types';

// Documentation: https://ares.gov.cz/swagger-ui/
const baseURL = 'https://ares.gov.cz/ekonomicke-subjekty-v-be/rest';
// Test VATs: 27074358, 01739808

function getAddress(address?: EconomicSubject['sidlo']) {
  const houseNumber = address?.cisloOrientacni
    ? `${address?.cisloDomovni}/${address?.cisloOrientacni}`
    : address?.cisloDomovni;
  return `${address?.nazevUlice} ${houseNumber}`;
}

async function fetchRegistryCodeValidation(registryCode: string) {
  const response = await axios.get<EconomicSubject>(
    `${baseURL}/ekonomicke-subjekty/${registryCode}`,
  );

  const neededData = {
    companyName: response.data.obchodniJmeno,
    vatNumber: response.data.dic,
    city: response.data.sidlo?.nazevObce,
    postalCode: String(response.data.sidlo?.psc ?? ''),
    country: response.data.sidlo?.nazevStatu,
    code: response.data.ico || response.data.icoId,
  };

  return {
    ...neededData,
    address: getAddress(response.data.sidlo),
    companyExists: !Object.values(neededData).every(item => item === undefined),
  };
}

export default fetchRegistryCodeValidation;
