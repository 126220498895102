import React, { useState } from 'react';
import { Button, TextField, Box } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';

import { Configuration } from './constants';

const ComponentConfiguration: PosPlugin<
  Configuration
>['ComponentConfiguration'] = ({ current, save }) => {
  const [minPurchaseAmount, setMinPurchaseAmount] = useState(
    current?.minPurchaseAmount ?? '',
  );

  return (
    <Box gridGap="0.5rem" alignItems="center">
      <TextField
        value={minPurchaseAmount}
        onChange={e => setMinPurchaseAmount(e.target.value)}
        variant="outlined"
        placeholder="Min. purchase amount"
      />

      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          save({
            minPurchaseAmount,
          })
        }
      >
        Save
      </Button>
    </Box>
  );
};

export default ComponentConfiguration;
