import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { Box } from '@material-ui/core';

import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';

import {
  dictToArrayByOrder,
  extractAnswerToState,
  getQuestionsValidityMap,
  setCustomerQAnswers,
} from '../utils';
import { CustomerFormFeedbackConfig } from '../types';

import FeedbackInputField from './FeedbackInputField';

const UiCustomerForm: PosPlugin['UICustomerForm'] = ({
  children,
  value,
  onChange,
}) => {
  const config = useSelector(
    getPluginConfiguration<CustomerFormFeedbackConfig>(
      'customer-creation-form-feedback-section',
    ),
  );

  const validityState = useMemo(
    () => getQuestionsValidityMap(config?.questions, value.attributes),
    [config, value.attributes],
  );

  if (config?.onlyOnNewCustomer && value?.customerID) return children;

  return R.pipe(
    React.Children.toArray,
    R.append(
      <div>
        {dictToArrayByOrder(config?.questions ?? {}).map(q => {
          return (
            <FeedbackInputField
              question={q}
              hasValidAnswer={validityState[q.id]}
              feedbackState={extractAnswerToState(q, value.attributes)}
              updateFeedback={(q, a) =>
                onChange(setCustomerQAnswers(value, q, a))
              }
            />
          );
        })}
      </div>,
    ),
  )(children);
};

export default UiCustomerForm;
