import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, TextField } from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';

import { setFitIDInRedux } from '../rdx/actions';
import { getTafReduxState } from '../rdx/reducers';

import TafCustomerDetails from './components';

const useStyles = makeStyles(theme => ({
  tafCustomerDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
      paddingRight: 0,
    },
  },
}));

const UIVisibleCustomerInformation: PosPlugin['UIVisibleCustomerInformation'] = ({
  gridDisplay,
  children,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { fitID } = useSelector(getTafReduxState);

  return R.pipe(
    React.Children.toArray,
    R.modifyPath(
      [0, 'props', 'children'],
      R.pipe(
        React.Children.toArray,
        R.modifyPath(
          [0, 'props', 'children'],
          R.pipe(
            React.Children.toArray,
            R.when(
              () => !gridDisplay,
              R.pipe(
                R.modifyPath(
                  [0, 'props'],
                  R.mergeLeft({ xs: 12, sm: 12, md: 6 }),
                ),
                R.append(
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className={styles.tafCustomerDetailsContainer}
                  >
                    <TafCustomerDetails />
                  </Grid>,
                ),
              ),
            ),
            R.modifyPath(
              [0, 'props', 'children'],
              R.pipe(
                R.insert(
                  1,
                  <TextField
                    name="fitID"
                    variant="outlined"
                    label="Fit ID"
                    fullWidth
                    value={fitID}
                    onChange={e => dispatch(setFitIDInRedux(e.target.value))}
                  />,
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  )(children);
};

export default UIVisibleCustomerInformation;
