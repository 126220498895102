import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import i18next from 'i18next';

import * as api from 'services/ErplyAPI/clockInOut';
import { lastClockInTime } from 'actions/clockInOut';
import { getHomeStores, getWarehouses } from 'actions/warehouses';
import {
  fetchAddressTypes,
  getCustomerGroups,
  getDefaultCustomer,
  fetchJobTitles,
  fetchPersonTitles,
} from 'actions/customerSearch';
import { getSelectedPos, getSelectedPosID } from 'reducers/PointsOfSale';
import {
  getHasRightToOpenAndCloseDay,
  getClientCode,
  getUserLoggedIn,
} from 'reducers/Login';
import {
  lastInvoiceNumbers,
  lastReceiptData,
  pendingRequests,
} from 'services/localDB';
import { checkIsDayOpen } from 'actions/OpenCloseDay';
import { fetchVatRates } from 'actions/vatRatesDB';
import { fetchProductUnits } from 'actions/productUnitsDB';
import { fetchProductGroups } from 'actions/productGroupsDB';
import {
  abortSyncLocalDB,
  syncLocalDatabaseSO,
  syncLocalDB,
  syncPendingRequest,
} from 'actions/connectivity';
import { getAllWarehouses, getLoggedInWarehouse } from 'reducers/warehouses';
import { getCompanyInfo, softLogout } from 'actions/Login';
import { getCurrency } from 'actions/configs';
import { getAssortmentProducts } from 'actions/assortmentProducts';
import { fetchAllRelatedCafaConfigs } from 'actions/integrations/CafaConfigs';
import { integrationFunctions as getIntegrationFunctions } from 'actions/integrations/terminal';
import { getPaymentTypesAction } from 'actions/PaymentTypes';
import { fetchServiceEndpoints } from 'actions/serviceEndpoints';
import { fetchReasonCodes } from 'actions/reasonCodesDB';
import { fetchGiftcardTypes, fetchRegularGiftCards } from 'actions/giftcards';
import { pluginCleanupOnUnmount, pluginSetupOnMount } from 'actions/Plugins';
import { getStoreRegionAssociatedWithSelectedWarehouse } from 'actions/storeRegions';
import {
  getDisabledDatabaseSO,
  getFetchOnlyProductsThatAreInStock,
} from 'reducers/configs/settings';
import { addError, dismissType, addWarning } from 'actions/Error';
import { REASONS } from 'constants/reasonCodesDB';
import './Main.css';
import { getHasDayOpen } from 'reducers/OpenCloseDay';
import { UrlControl } from 'services/UrlControl/UrlControl';
import { useIsLoggedIn } from 'containers/App/hooks/useIsLoggedIn';
import { SO } from 'services/DB/types';
import { stopSync } from 'services/DB/syncDB';
import { getDB, resetDB, saveToSharedSO } from 'services/DB';
import { REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK } from 'constants/persistence';
import { UrlControlActions } from 'services/UrlControl/actions';
import { GridButtonSizeProvider } from 'components/GridButton';
import { clearOutdatedReceiptTemplateCache } from 'actions/integrations/printer/receiptPrintoutApi/actions';
import { selectPos } from 'actions/PointsOfSale/selectPos';
import { redirectToLoginApp } from 'services/ErplyAPI/redirectToLoginApp';

const useUpdateLocalProducts = () => {
  const fetchOnlyProductsInStock = useSelector(
    getFetchOnlyProductsThatAreInStock,
  );
  const clientCode = useSelector(getClientCode);
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();

  const checkIfNeedsUpdating = useCallback(async () => {
    if (!isLoggedIn) {
      return false;
    }
    const db = await getDB(clientCode);
    const fetchOnlyProductsInStockFromShared = await db.get(
      SO.SHARED.NAME,
      REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK,
    );

    return fetchOnlyProductsInStock !== fetchOnlyProductsInStockFromShared;
  }, [clientCode, fetchOnlyProductsInStock, isLoggedIn]);

  const resyncProducts = useCallback(async () => {
    const needsUpdating = await checkIfNeedsUpdating();
    if (!needsUpdating) return;

    const name = SO.PRODUCTS.NAME;
    await saveToSharedSO(clientCode, [
      {
        key: REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK,
        value: fetchOnlyProductsInStock,
      },
    ]);

    await stopSync(name);
    await resetDB(clientCode, name);
    dispatch(syncLocalDatabaseSO(name));
  }, [checkIfNeedsUpdating, clientCode, fetchOnlyProductsInStock, dispatch]);

  useEffect(() => {
    resyncProducts();
  }, [resyncProducts]);
};

const onLogin = () => async (dispatch, getState) => {
  if (UrlControl.actionReturnSale) {
    i18next.loadNamespaces('urlControl').then(() => {
      dispatch(
        addWarning(i18next.t('urlControl:actionProgress.return'), {
          errorType: 'UrlControl',
          dismissible: false,
          selfDismiss: false,
        }),
      );
    });
  }
  if (UrlControl.actionCancelLayaway) {
    i18next.loadNamespaces('urlControl').then(() => {
      dispatch(
        addWarning(i18next.t('urlControl:actionProgress.cancelLayaway'), {
          errorType: 'UrlControl',
          dismissible: false,
          selfDismiss: false,
        }),
      );
    });
  }

  const serviceEndpoints = dispatch(fetchServiceEndpoints());
  // Fetch stuff
  dispatch([
    getDefaultCustomer(),
    getCompanyInfo(),
    getCurrency(),
    fetchAddressTypes(),
    fetchPersonTitles(),
    fetchJobTitles(),
    getCustomerGroups(),
    fetchProductGroups(),
    checkIsDayOpen({ pointOfSaleID: getSelectedPosID(getState()) }),
    fetchVatRates(), // Loading archived vat rates causes unnecessary delay of CAFA
    fetchProductUnits(),
    syncPendingRequest(),
    getHomeStores(),
  ]);

  api
    .clockedInEmployees({
      warehouseIDs: getSelectedPos(getState())?.warehouseID,
    })
    .then(response => {
      const employeeIsClockedIn = response.find(
        employee =>
          employee.employeeID ===
          parseInt(getUserLoggedIn(getState())?.employeeID, 10),
      );
      if (employeeIsClockedIn) {
        dispatch(lastClockInTime());
      }
    })
    .catch(e =>
      console.error(
        'Failed to fetch clocked-in employees (not enough permissions?)\n',
        e,
      ),
    );
  dispatch(fetchRegularGiftCards());
  dispatch(fetchGiftcardTypes());

  const clientCode = getClientCode(getState());
  await dispatch(getWarehouses());

  const warehouse = getLoggedInWarehouse(getState());
  if (!warehouse) {
    dispatch(addError(i18next.t('alerts:errors.missingWarehouse')));
    console.error(
      'Stopping login early because data not present - this can happen if logging in/out very quickly',
    );
    return dispatch(selectPos(null));
  }
  const { warehouseID, assortmentID } = warehouse;
  await dispatch(getStoreRegionAssociatedWithSelectedWarehouse());
  dispatch(getAssortmentProducts(warehouseID, assortmentID));

  // TODO: Add following localForage dbs to BrazilPOS IDB
  // TODO: remove option in settings for supporting offline customer support
  // TODO: !!! correction, only customers fetched during search can be stored locally but we should not sync all customers!!!
  pendingRequests.initiate({ CLIENT_CODE: clientCode });
  lastInvoiceNumbers.initiate({ CLIENT_CODE: clientCode });
  lastReceiptData.initiate({ CLIENT_CODE: clientCode }).then(() => {
    lastReceiptData.reset();
  });
  lastInvoiceNumbers.setInitialNumber(getSelectedPosID(getState()));

  dispatch(fetchReasonCodes({ purpose: REASONS.DISCOUNT })).catch(err =>
    console.warn('Failed to load discount reason codes', err),
  );

  dispatch(fetchReasonCodes({ purpose: REASONS.RETURN })).catch(err =>
    console.warn('Failed to load return reason codes', err),
  );

  dispatch(fetchReasonCodes({ purpose: REASONS.WRITEOFF })).catch(err =>
    console.warn('Failed to load inventory write-off reason codes', err),
  );

  // Cafa fetch configs related to this level + selected integrations config
  //   Fetching cafa requires service endpoints to be loaded first
  const cafa = serviceEndpoints.then(() =>
    dispatch(fetchAllRelatedCafaConfigs()),
  );

  cafa.then(async () => {
    // Cafa loaded, check for template updates and notify MS
    dispatch(clearOutdatedReceiptTemplateCache());
    // initial computations for enabled plugins
    dispatch(pluginSetupOnMount());
    // Initial Setup for payment integration
    await dispatch(getPaymentTypesAction());
    dispatch(getIntegrationFunctions()).then(data => {
      if (data?.integrationSetup) dispatch(data.integrationSetup());
    });
  });

  // UrlControl automations
  dispatch(dismissType('UrlControl'));
  dispatch(UrlControlActions.initRelevantPayment);
};

const isPnPDomain =
  /\/brazil-pos\/PnP-UAT/.test(window.location.href) ||
  window.origin === 'https://eposp.erply.com';

const onLogout = () => async dispatch => {
  // Cleanup for payment integration
  dispatch(getIntegrationFunctions()).then(data => {
    if (data?.integrationCleanup) data.integrationCleanup();
  });

  // clean up for enabled plugins
  dispatch(pluginCleanupOnUnmount());

  localStorage.removeItem('customers');

  // TODO: GET RID OF THIS PNP-SPECIFIC HACK
  if (isPnPDomain) redirectToLoginApp();
};

const Main = ({ header, main }) => {
  const dispatch = useDispatch();
  const disabledDatabaseSO = useSelector(getDisabledDatabaseSO);
  const hasDayOpen = useSelector(getHasDayOpen);
  const hasRightsToOpenDay = useSelector(getHasRightToOpenAndCloseDay);
  const isLoggedIn = useIsLoggedIn();

  const warehouses = useSelector(getAllWarehouses);
  const warehousesFetched = warehouses.length > 0;

  const shouldRenderPos =
    isLoggedIn && (hasDayOpen || (!hasDayOpen && hasRightsToOpenDay));

  useEffect(() => {
    if (shouldRenderPos) {
      dispatch(onLogin());
      return () => {
        dispatch(onLogout());
      };
    }
    return () => {}; // for consistent-return eslint rule
  }, [dispatch, shouldRenderPos]);

  useDeepCompareEffect(() => {
    if (isLoggedIn && warehousesFetched) {
      dispatch(syncLocalDB());
      return () => {
        dispatch(abortSyncLocalDB());
      };
    }
  }, [disabledDatabaseSO, isLoggedIn, warehousesFetched]);

  useUpdateLocalProducts();

  return (
    <div className="erply-container">
      <div className="erply-header" data-testid="erply-header">
        {header}
      </div>
      {shouldRenderPos && <div data-portal="urgent-alert-container" />}
      <div className="erply-main">
        <GridButtonSizeProvider>{main}</GridButtonSizeProvider>
      </div>
    </div>
  );
};

export default React.memo(Main);
