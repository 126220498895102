import { Theme, alpha, capitalize } from '@material-ui/core';

import { ThemeColor } from './types';

export function isPaletteColor(
  color: string,
  theme: Theme,
): color is ThemeColor {
  return (
    typeof theme.palette[color] === 'object' && 'main' in theme.palette[color]
  );
}

/**
 * Generates MUI Button styles based on the provided color and theme (respects theme palette options).
 * Styles are generated for all 3 button variant and are taken from MUI v4 source code
 * @see https://github.com/mui/material-ui/blob/v4.x/packages/material-ui/src/Button/Button.js
 *
 * Resulting styles can be injected as "classes" prop into Button component.
 * This way MUI Button will use appropriate class when provided with "color" prop
 * @example
 * function createStyles(theme) {
 *   return {
 *     ...generateButtonColorStyles('grid_dark', theme),
 *     ...generateButtonColorStyles({ name: 'red', value: '#f00' }, theme),
 *   }
 * }
 *
 * const CustomButton = withStyles(createStyles, { name: 'MuiButton' })(Button);
 * ...
 * return <CustomButton variant="contained" color="red">Text</CustomButton>
 *
 * @param color - can be either theme palette color or an object that contains color name and color value (in any format recognized by css)
 */
export function generateButtonColorStyles(
  color: string | { name: string; value: string },
  theme: Theme,
): Record<string, Record<string, unknown>> {
  if (typeof color === 'string' && !isPaletteColor(color, theme)) {
    throw new Error('Provided color is not a theme color');
  }

  const colorValue =
    typeof color === 'string'
      ? theme.palette[color]
      : theme.palette.augmentColor({ main: color.value });

  if (!colorValue) throw new Error('Invalid color');

  const capitalizedColorName = capitalize(
    typeof color === 'string' ? color : color.name,
  );

  return {
    [`text${capitalizedColorName}`]: {
      color: colorValue.main,
      '&:hover': {
        backgroundColor: alpha(
          colorValue.main,
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },

    [`outlined${capitalizedColorName}`]: {
      color: colorValue.main,
      border: `1px solid ${alpha(colorValue.main, 0.5)}`,
      '&:hover': {
        border: `1px solid ${colorValue.main}`,
        backgroundColor: alpha(
          colorValue.main,
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },

    [`contained${capitalizedColorName}`]: {
      color: colorValue.contrastText,
      backgroundColor: colorValue.main,
      '&:hover': {
        backgroundColor: colorValue.dark,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: colorValue.main,
        },
      },
    },
  };
}
