import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSetting } from 'reducers/configs/settings';
import { getHasProductsInShoppingCart } from 'reducers/ShoppingCart';
import ListGroup from 'components/listGroup/ListGroup';
import { useBreakpoints } from 'utils/hooks/UI';
import CalculateAndPayButton from 'components/CalculateButton';
import { useTrackResize } from 'utils/hooks/useTrackResize';
import { PluginComponent } from 'plugins';

import TableTotalContainer from './tableTotalContainer/TableTotalContainer';
import VisibleCustomerInformation from './visibleCustomerInformation/VisibleCustomerInformation';
import EmployeeStats from './employeeStats/EmployeeStats';
import BillContainer from './billContainer/BillContainer';
import Search from './Search';

import './col1.css';

const MobileView = () => {
  const width = useTrackResize(() => window.innerWidth);
  return (
    <>
      <VisibleCustomerInformation />
      <BillContainer />
      <ListGroup />
      <TableTotalContainer />
      <CalculateAndPayButton width={width} />
    </>
  );
};

const DesktopView = () => {
  const hideEmployeeStats = useSelector(
    getSetting('touchpos_hide_employee_stats'),
  );
  const anyProductsSelected = useSelector(getHasProductsInShoppingCart);

  const view = useMemo(
    () =>
      anyProductsSelected || hideEmployeeStats ? (
        <>
          <BillContainer />
          <TableTotalContainer />
        </>
      ) : (
        <EmployeeStats />
      ),
    [anyProductsSelected, hideEmployeeStats],
  );

  return (
    <>
      <Search />
      <VisibleCustomerInformation />
      {view}
    </>
  );
};

const Col1 = () => {
  const isMobileView = !useBreakpoints().mdplus;

  const view = useMemo(
    () => (isMobileView ? <MobileView /> : <DesktopView />),
    [isMobileView],
  );

  const innerHeight = useTrackResize(() => window.innerHeight);
  return (
    <PluginComponent hookname="UICol1">
      <div
        className="col1-wrapper"
        style={{
          overflowY: isMobileView ? 'scroll' : 'initial',
          maxHeight: innerHeight - 65,
        }}
      >
        {view}
      </div>
    </PluginComponent>
  );
};
export default Col1;
