import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { openPluginModalPage } from 'actions/modalPage';
import {
  PickupSpecialOrderModal,
} from 'plugins/nohble/SpecialOrders/PickupSpecialOrder/PickupSpecialOrderModal';

import { insertAfter } from '../utils/fn';

/**
 * Appends the "Special orders" button after the existing "orders" button
 */
export const addPickupOrderButton: Required<
  PosPlugin
>['selectorOverrides']['getFunctionButtons'] = base =>
  createSelector(base, buttons =>
    insertAfter(
      btn => btn.id === 'orderSales',
      {
        id: 'N5-SpecialOrderPickup',
        name: 'Special orders',
        actionType: 'action',
        action: openPluginModalPage(PickupSpecialOrderModal.id)(),
      },
      buttons,
    ),
  );
