import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Box, Card, LinearProgress, TextField } from '@material-ui/core';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { addError } from 'actions/Error';

import { GivexCard, GivexInputType } from '../types';
import {
  getGivexConfiguration,
  getValidateGivexCard,
} from '../configuration/Configuration';
import * as API from '../API/givexAPI';

import styles from './GivexCardInput.module.scss';

type GivexCardInputProps = {
  value: GivexCard;
  onChange: (newValue: GivexCard) => void;
  type: GivexInputType;
  disabled?: boolean;
};
/**
 * Fancy input field that looks like a credit card, but offers the same functionality:
 *
 * One input for the card number, and another input for the pin
 *
 * Bonus: Unlike the standard input, this input also has a balance indicator that the user can click to check the card's balance
 */
const GivexCardInputFancy = ({
  value,
  onChange,
  type,
  disabled = false,
}: GivexCardInputProps) => {
  const dispatch = useDispatch();
  const onChangeProp = prop => e =>
    onChange({ ...value, [prop]: e.target.value });

  const { defaultCardNumber, defaultPin, isPinRequiredPerType } = useSelector(
    getGivexConfiguration,
  );
  const usePin = isPinRequiredPerType?.[type];

  const givexValidationErrorMessage = useSelector(getValidateGivexCard)(
    value,
    type,
  );
  const givexBalanceValidationErrorMessage = useSelector(getValidateGivexCard)(
    value,
    'balance',
  );

  // Cannot check balance if input has no pin field, but balance field requires pin
  const canCheckBalance =
    isPinRequiredPerType.balance !== true ||
    isPinRequiredPerType[type] !== false;

  // Set value to default on component mount (empty dependencies array intended)
  useEffect(() => {
    if (!value.cardNo && !value.pin)
      onChange({ cardNo: defaultCardNumber, pin: defaultPin });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  const [balance, setBalance] = useState<string | null>(null);
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);
  useEffect(() => setBalance(null), [value]);
  const checkBalance = () => {
    if (givexBalanceValidationErrorMessage) {
      dispatch(addError(givexBalanceValidationErrorMessage));
      return;
    }
    setLoadingBalance(true);
    API.getBalance(value)
      .then(
        res => setBalance(res.certificateBalance),
        err => dispatch(addError(err.message)),
      )
      .finally(() => setLoadingBalance(false));
  };
  return (
    <Card className={styles.card} variant="outlined">
      {canCheckBalance && (
        <div
          className={classNames({
            [styles.balance]: true,
            [styles.unknown]: !balance,
          })}
        >
          {loadingBalance && (
            <div>
              Balance: ??.?? <LinearProgress variant="indeterminate" />
            </div>
          )}
          {!loadingBalance && balance && (
            <a onClick={checkBalance}>Balance: ${balance}</a>
          )}
          {!loadingBalance && !balance && <a onClick={checkBalance}>Balance</a>}
        </div>
      )}
      <TextField
        autoFocus
        inputRef={inputRef}
        disabled={disabled}
        className={styles.number}
        InputProps={{
          className: styles.numberinput,
        }}
        label="Card number"
        value={value.cardNo}
        onChange={onChangeProp('cardNo')}
      />
      {usePin === false ? null : (
        <TextField
          disabled={disabled}
          label="Pin"
          className={styles.pin}
          size="small"
          placeholder="Pin"
          name="pin"
          value={value.pin}
          variant="outlined"
          onChange={onChangeProp('pin')}
        />
      )}
    </Card>
  );
};

/**
 * Basic/compact input - shows card number and pin inputs side by side as regular text fields
 */
const GivexCardInputSimple = ({
  value,
  onChange,
  type,
  disabled = false,
}: GivexCardInputProps) => {
  const onChangeProp = (prop: keyof GivexCard) => e =>
    onChange(R.assoc(prop, e.target.value, value));

  const { isPinRequiredPerType } = useSelector(getGivexConfiguration);
  const usePin = isPinRequiredPerType?.[type];

  const hidePinField = usePin === false;
  const { defaultCardNumber, defaultPin } = useSelector(getGivexConfiguration);

  // Set value to default on component mount (empty dependencies array intended)
  useEffect(() => {
    if (!value.cardNo && !value.pin)
      onChange({ cardNo: defaultCardNumber, pin: defaultPin });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      <TextField
        disabled={disabled}
        autoFocus
        inputRef={inputRef}
        variant="outlined"
        fullWidth
        placeholder="Card number"
        value={value.cardNo}
        name="cardNo"
        onChange={onChangeProp('cardNo')}
      />
      {hidePinField ? null : (
        <TextField
          disabled={disabled}
          style={{ flex: '0 0 8ch' }}
          placeholder="Pin"
          variant="outlined"
          fullWidth
          value={value.pin}
          name="pin"
          onChange={onChangeProp('pin')}
        />
      )}
    </Box>
  );
};

// If fancy input is not wanted, can switch back to simple by editing this line
export const GivexCardInput = (props: GivexCardInputProps) => {
  const { useFancyInput } = useSelector(getGivexConfiguration);
  if (useFancyInput) return <GivexCardInputFancy {...props} />;
  return <GivexCardInputSimple {...props} />;
};
