import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Grid, InputAdornment } from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v1 as uuidv1 } from 'uuid';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { addError } from 'actions/Error';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import SaveButton from 'components/CustomButtons/SaveButton';
import { RootState } from 'reducers';

import { getTransactionHistory } from '../API/viiAPI';
import { GetTransactionHistoryResponse } from '../types';
import { noResponse } from '../constants';
import { getViiGiftCardProduct } from '../redux';

const ViiHistory = () => {
  const [cardNumber, setCardNumber] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const [response, setResponse] = useState<
    GetTransactionHistoryResponse['TransactionHistory'][0]['Transaction'] | null
  >(null);
  const [error, setError] = useState<string | null>(null);

  const viiProduct = useSelector(getViiGiftCardProduct);

  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const handleClose = () => dispatch(previousModalPage());

  const handleSearch = async () => {
    dispatch(
      getTransactionHistory({
        NewestFirst: 1,
        CardNumber: cardNumber,
        viiTranId: uuidv1(),
        PIN: pin,
      }),
    )
      .then(res => {
        if (res && Number(res?.ResponseCode?.[0]) === 0) {
          if (
            res.TransactionHistory &&
            Object.values(res.TransactionHistory?.[0])
          ) {
            setResponse((res.TransactionHistory?.[0]).Transaction);
            setError(null);
          } else {
            setError('This card has no transaction history');
            setResponse(null);
          }
        } else {
          setError(res?.ResponseMessage[0] ?? noResponse);
          setResponse(null);
        }
        return res;
      })
      .catch(err => {
        dispatch(addError(err.message));
        setError(err.message);
        setResponse(null);
      });
  };

  useShortcut('Enter', handleSearch);
  const inputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  return (
    <div data-testid="vii-history">
      <Modal.Header>
        <Modal.Title>
          <h3 data-testid="title">
            <b>{`Check ${viiProduct?.name ?? 'Vii gift card'} history`}</b>
          </h3>
        </Modal.Title>
        <div className="d-flex align-content-center">
          <SaveButton action={handleSearch} title="search" />
          <CloseButton action={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Grid container>
          <TextField
            fullWidth
            inputRef={inputRef}
            variant="outlined"
            autoFocus={true}
            value={cardNumber}
            onChange={e => setCardNumber(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '20%' }}>
                  NUMBER
                </InputAdornment>
              ),
            }}
            style={{
              marginBottom: '20px',
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            value={pin}
            type="password"
            onChange={e => setPin(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '20%' }}>
                  PIN
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {response && (
          <>
            <Grid container>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  Receipt No.
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  Trans. Type
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  {/* prettier-ignore */}
                  Amount
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  {/* prettier-ignore */}
                  Balance
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  {/* prettier-ignore */}
                  Date
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bold' }}>
                  Store Name
                </Typography>
              </Grid>
            </Grid>
            {response.map(resp => (
              <>
                <Grid container>
                  <Grid item xs={2}>
                    <Typography>{resp.ReceiptNumber[0]}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{resp.TransactionType[0]}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{resp.Amount[0]}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{resp.Balance[0]}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{resp.Date[0]}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{resp.StoreName[0]}</Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </>
        )}
        {error && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          </Grid>
        )}
      </Modal.Body>
    </div>
  );
};

export default ViiHistory;
