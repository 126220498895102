import * as R from 'ramda';

import { getPluginSelector } from 'reducers/Plugins';

const ADD_FREE_GIFT_CARD = 'ADD_FREE_GIFT_CARD';

const REMOVE_FREE_GIFT_CARD = 'REMOVE_FREE_GIFT_CARD';

const RESET_STATE = 'RESET_STATE';

export const addFreeGiftCard = (
  key: number,
  { code, percentage }: { code: string; percentage: string },
) => ({
  type: ADD_FREE_GIFT_CARD,
  payload: { key, code, percentage },
});

export const removeFreeGiftCard = (key: string | number) => ({
  type: REMOVE_FREE_GIFT_CARD,
  payload: key,
});

export const resetState = () => ({ type: RESET_STATE });

const initialState: Record<string, { code: string; percentage: string }> = {};

const reduxReducer = (
  state: typeof initialState = {},
  action: { type: string; payload?: any },
) => {
  switch (action.type) {
    case ADD_FREE_GIFT_CARD:
      return {
        ...state,
        [action.payload?.key]: {
          code: action.payload.code,
          percentage: action.payload.percentage,
        },
      };
    case REMOVE_FREE_GIFT_CARD:
      return R.dissoc(action.payload)(state);
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export const getFreeGiftCards = getPluginSelector<typeof initialState>(
  'lancaster-plugin',
)(n => n);

export default reduxReducer;
