import React from 'react';

import { PosPlugin } from 'plugins/plugin';
import { createConfirmation } from 'actions/Confirmation';
import { getPayments } from 'services/ErplyAPI/sales';

const confirmIdentity: Required<
  PosPlugin
>['onPickupOrder']['before'] = sale => async dispatch => {
  if (!sale.paid) return;

  const payments = await getPayments({ documentID: sale.id });
  const hasOnlinePayments = payments.some(pmt =>
    pmt.type.toLowerCase().includes('online'),
  );
  if (!hasOnlinePayments) return;

  await new Promise((resolve, reject) =>
    dispatch(
      createConfirmation(resolve, reject, {
        title: 'Online Order Confirmation',
        body: (
          <div style={{ textAlign: 'center' }}>
            Have you verified customer&#39;s identity?
            <br /> <b>A valid photo ID like a driver&#39;s license</b>
          </div>
        ),
        confirmText: 'Yes',
        cancelText: 'No',
      }),
    ),
  );
};

export default confirmIdentity;
