/* eslint-disable eqeqeq */

import React, {
  CSSProperties,
  ComponentProps,
  FC,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import * as R from 'ramda';
import debug from 'debug';

import ListItem from 'components/ListItem';
import { setPaymentEditValue, updateCurrency } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import {
  getPaymentsCurrency,
  getPayments,
  getPaymentSelected,
  getPaymentEditValue,
} from 'reducers/Payments';
import { getDefaultCurrency } from 'reducers/configs/settings';
import { getCurrencyByCode } from 'reducers/configs/currency';
import { range } from 'utils/tsHelpers';

interface ButtonItemProps extends ComponentProps<typeof ListItem> {
  style?: CSSProperties;
}

const ButtonItem: FC<ButtonItemProps> = ({ style, ...props }) => (
  <ListItem
    style={{
      fontSize: 24,
      fontFamily: 'Proxima Nova',
      minWidth: 80,
      minHeight: 76,
      height: 'inherit',
      width: 'inherit',
      ...style,
    }}
    {...props}
  />
);

const area = n => ({
  gridArea: n,
  alignSelf: 'stretch',
  justifySelf: 'stretch',
});

const parseToValue = stringAmount => {
  const v = Number(stringAmount);
  if (v === 0) return '';
  return v.toFixed(2);
};
const NumberPad: FC = () => {
  const dispatch = useDispatch();
  const selectedPayment = useSelector(getPayments)[
    useSelector(getPaymentSelected)
  ];
  const { code: selectedCurrency } = useSelector(getPaymentsCurrency);
  const defaultCurrencyCode = useSelector(getDefaultCurrency);
  const defaultCurrency = useSelector(getCurrencyByCode(defaultCurrencyCode));
  const value = useSelector(getPaymentEditValue);
  const setValue = value => {
    dispatch(
      setPayment({
        ...selectedPayment,
        amount: R.unless(Number.isFinite, R.always(0), Number(value)).toFixed(
          2,
        ),
      }),
    );
    dispatch(setPaymentEditValue(value));
  };
  // prettier-ignore
  const keyBinds = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ".", ",", "Backspace", "Enter", "Delete"];

  const processKey = useCallback(
    async key => {
      switch (key) {
        case 'Backspace':
          return setValue(value.slice(0, value.length - 1));
        case 'Delete':
          return setValue('');
        case 'Enter':
          await dispatch(updateCurrency(defaultCurrency));
          await dispatch(setPaymentSelected(''));
          return undefined;
        case ',':
        case '.':
          // When entering a new decimal point, assume previous ones were accidental
          return setValue(`${value.replaceAll('.', '')}.`);
        default:
          if (/^[0-9]$/.test(key)) {
            const amount = `${value}${key}`;
            return setValue(amount);
          }
        // Ignoring unknown key
      }
    },
    [defaultCurrency, dispatch, setValue, value],
  );

  useEffect(() => {
    const onKeyDown = ({ key, target }) => {
      if (target?.tagName === 'INPUT') return;
      if (keyBinds.includes(key)) {
        processKey(key);
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [keyBinds, processKey]);

  const onClickButton = useCallback(
    ({ target: { value } }) => {
      processKey(value);
    },
    [processKey],
  );
  return (
    <div
      style={{
        display: 'inline-grid',
        gridTemplateAreas: `'input input input' 'n1  n2  n3 '  ' n4  n5  n6 ' ' n7  n8  n9 ' ' dec  n0  ok '`,
      }}
      data-testid="payment-numberpad"
    >
      {debug.enabled('payment:amount') && (
        <div
          style={{
            ...area('input'),
            ...(debug.enabled
              ? undefined
              : {
                  height: 0,
                  visibility: 'hidden',
                }),
          }}
        >
          <TextField
            fullWidth
            label="Amount"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </div>
      )}
      <ButtonItem
        value="."
        variant="grid_dark"
        style={{ ...area('dec'), fontSize: 24 }}
        action={onClickButton}
        data-testid="decimal-button"
        data-test-key="."
      >
        .
      </ButtonItem>
      <ButtonItem
        variant="grid_green"
        style={{ ...area('ok'), fontSize: 24, fontWeight: 700 }}
        value="Enter"
        action={onClickButton}
        data-testid="enter-button"
        data-test-key="OK"
      >
        OK
      </ButtonItem>

      {range(9).map(n => (
        <ButtonItem
          key={n}
          variant="grid_light"
          data-test-key={n}
          data-testid="numpad-button"
          value={n}
          style={area(`n${n}`)}
          action={onClickButton}
        >
          {n}
        </ButtonItem>
      ))}
    </div>
  );
};

export default NumberPad;
