import React from 'react';
import * as R from 'ramda';

type ReactChild = ReturnType<typeof React.Children.toArray>[number];
/**
 * Returns a function that recursively modifies the given react tree by applying the given transformation function to all elements
 *
 * Useful for targetting specific elements by their props without relying on a specific component structure
 *
 * @see {@link reactcursivelyMap} To modify lists of children instead of individual children
 * @example
 * const removePrice = reactcursively(node =>
 *   node?.props?.["data-testid"] === "price"
 *     ? null
 *     : node
 * )
 *
 * const outlineAllElements = reactcursively(R.assocPath(
 *   ['props','style','outline'],
 *   '1px solid red'
 * ))
 *
 * const replaceVAT = vat => reactcursively(R.when
 *   R.pathEq(['props', 'data-testid'], 'tax'),
 *   R.assoc(['props', 'children'], vat)
 * )
 *
 * const appendProduct = newProd => reactcursively(
 *   // modify all children lists
 *   R.modifyPath(['props','children'], R.pipe(
 *     React.Children.toArray,
 *     R.when(
 *       // if they contain products
 *       R.some(R.pathEq(['props','className'], 'product')),
 *       // append one more product
 *       R.append(<TableBillRow item={newProd} className="product"/>)
 *     )
 *   )
 * )
 */
export const reactcursively = (fn: (c: ReactChild) => ReactChild) => (
  children: React.ReactNode,
): ReactChild[] =>
  React.Children.toArray(children).map(
    R.pipe(fn, R.modifyPath(['props', 'children'], reactcursively(fn))),
  );

/**
 * Returns a function that recursively modifies the given react tree by applying the given transformation function to all lists of children
 *
 * @see {@link reactcursively} To modify individual children
 * @example
 *
 * const sortByProp = reactcursivelyMap(
 *    R.sortBy(R.pathOr(['props','sort'], 0))
 * )
 *
 * const max5ChildrenPerNode = reactcursivelyMap(children => children.slice(0,5))
 *
 * const outlineAllElements = reactcursively(R.assocPath(
 *   ['props','style','outline'],
 *   '1px solid red'
 * ))
 *
 * const replaceVAT = vat => reactcursively(R.when
 *   R.pathEq(['props', 'data-testid'], 'tax'),
 *   R.assoc(['props', 'children'], vat)
 * )
 *
 * const appendProduct = newProd => reactcursively(
 *   // modify all children lists
 *   R.modifyPath(['props','children'], R.pipe(
 *     React.Children.toArray,
 *     R.when(
 *       // if they contain products
 *       R.some(R.pathEq(['props','className'], 'product')),
 *       // append one more product
 *       R.append(<TableBillRow item={newProd} className="product"/>)
 *     )
 *   )
 * )
 */
export const reactcursivelyMap = (fn: (c: ReactChild[]) => ReactChild[]) => (
  children: React.ReactNode,
): ReactChild[] =>
  R.pipe(
    React.Children.toArray,
    fn,
    R.map(R.modifyPath(['props', 'children'], reactcursivelyMap(fn))),
  )(children);
