/* eslint-disable @typescript-eslint/camelcase */
import { Configuration } from '../types';

import { generateNSWSessionToken } from './API';
import { LocalStorageNSW } from './types';

export const generateTokenAndSetToLS = (configs: Configuration = {}) =>
  generateNSWSessionToken({
    client_id: configs?.clientID,
    client_secret: configs?.clientSecret,
  })
    .then(res => {
      // Errors, coming from the API
      if (!res)
        throw new Error('Failed to generate session token: no response');
      if (!res.access_token)
        throw new Error(
          'Failed to generate session token: token missing from response',
        );

      const currentDate = new Date();
      const NSWInfoToSet: LocalStorageNSW = {
        access_token: res.access_token,
        created: currentDate.getTime(),
        expires_in: res.expires_in,
        token_type: res.token_type,
      };
      localStorage.setItem('NSW', JSON.stringify(NSWInfoToSet));
    })
    .catch(err => {
      console.error(err);
    });
