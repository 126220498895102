import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { Selector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { GivexCard } from 'plugins/givexHeartland/types';
import { getDefaultCurrency } from 'reducers/configs/settings';
import { getPluginHooksByName } from 'reducers/Plugins';
import { RootState } from 'reducers';

const getGivexCardToDeactivate = (state: RootState) => {
  const customHooks = getPluginHooksByName<Record<string, any>>('customHooks')(
    state,
  );
  const hook = customHooks.find(ch => !!ch?.getGivexCardToDeactivate) as
    | {
        getGivexCardToDeactivate: Selector<
          RootState,
          GivexCard & {
            balance: string;
          }
        >;
      }
    | undefined;

  if (!hook) return null;

  return hook.getGivexCardToDeactivate(state);
};

export const setReturnPaymentForGivexCardDeactivation: Required<
  PosPlugin
>['onOpenPaymentModal']['on'] = (p, ap) => async (dispatch, getState) => {
  const givexCardToDeactivate = getGivexCardToDeactivate(getState());
  if (!givexCardToDeactivate) return ap;

  const currencyCode = getDefaultCurrency(getState());
  const paymentType =
    Number(givexCardToDeactivate.balance) <= 5 ? 'CASH' : 'CARD';
  const newPayment = {
    key: uuidv4(),
    locked: true,
    amount: `-${givexCardToDeactivate.balance}`,
    type: paymentType,
    caption: paymentType,
    currencyCode,
    currencyRate: 1,
  };

  return R.evolve({ payments: R.assoc(newPayment.key, newPayment) })(ap);
};

