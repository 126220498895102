import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { style as defaultStyle } from 'utils/print/templates/CashInOut';
import { round } from 'utils';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';
import { PosPlugin } from 'plugins/plugin';

const style = {
  ...defaultStyle,
  reason: {
    display: 'flex',
    padding: '.5em 0',
  },
  reasonLabel: {
    width: '6ch',
    paddingRight: '1em',
  },
  reasonName: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 1 auto',
  },
} as Record<string, React.CSSProperties>;

interface Props {
  address?: string;
  warehouseName?: string;
  employeeName?: string;
  companyName?: string;
  comment?: string;
  sum?: string | number;
  currencySymbol?: string;
  reason?: string;
}

const CashInOutPrint: React.FC<Props> = ({
  address = '',
  warehouseName = '',
  employeeName = '',
  companyName = '',
  comment = '',
  sum = '0.00',
  currencySymbol = '',
  reason = '',
}) => {
  const { t } = useTranslation('cashInOut');
  const date = format(new Date(), 'MM/dd/yyyy HH:mm:ss');
  return (
    <div style={{ width: '34ch' }}>
      <h4 style={{ fontWeight: 700 }}>
        {t('receipt.title', {
          company: companyName,
          warehouse: warehouseName,
        })}
      </h4>
      <br />
      <p>{address}</p>
      <p>{date}</p>
      <p>{t('receipt.employee', { employee: employeeName })}</p>
      <br />
      <div style={style.container}>
        <div style={style.notes}>
          <div style={style.commentLabel}>{t('receipt.notes')}</div>
          <div style={style.comment}>
            <span style={{ wordBreak: 'break-word' }}>{comment}</span>
          </div>
        </div>
        <div style={style.reason}>
          <div style={style.reasonLabel}>Reason: </div>
          <div style={style.reasonName}>
            <span>{reason}</span>
          </div>
        </div>
        <div style={style.amount}>
          <div style={style.sumLabel}>
            {t('receipt.sumLabel', {
              context: Number(sum) < 0 ? 'out' : 'in',
              currencySymbol,
            })}
            :
          </div>
          <div style={style.sum}>{round(Math.abs(Number(sum)), 2)}</div>
        </div>
      </div>
    </div>
  );
};

const getCashInOutPrint: Required<PosPlugin>['onCashInOutPrint']['on'] = (
  p,
  { data },
) => async (dispatch, getState) => {
  const reasonCodes = getReasonCodes(
    data.type === 'in' ? REASONS.CASH_IN : REASONS.CASH_OUT,
  )(getState());
  const reasonCodeName = reasonCodes.find(r => r.reasonID === data.reasonID)
    ?.name;
  return {
    data,
    Template: <CashInOutPrint {...{ ...data, reason: reasonCodeName }} />,
  };
};

export default getCashInOutPrint;
