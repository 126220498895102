import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { getErrorMessage, withWaitingForTerminal } from 'paymentIntegrations';
import {
  CardPaymentHooks,
  FUNC_BUTTONS,
  PaymentObj,
} from 'paymentIntegrations/types';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { RootState } from 'reducers';
import {
  getCardPaymentsForIntegration,
  getEmployeeID,
} from 'reducers/Payments';
import { version } from 'external_data';

import {
  RefundTransaction,
  SaleTransaction,
  TransactionType,
  VoidTransaction,
} from '../types';

import handleTransactionResponse from './handleTransactionResponse';
import processTransaction from './processTransaction';

const processPayments = (
  hooks: CardPaymentHooks,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => async (
  dispatch,
  getState,
) => {
  const { updateMessage, enableButtons } = hooks;
  const cardPayments: PaymentObj[] = getCardPaymentsForIntegration('chase')(
    getState(),
  );
  const shouldVoid = cardPayments.some(p => p.paid && p.shouldProcess);

  const customerID = String(getSelectedCustomerID(getState()));
  // Chase only accepts maximum of 6 digits. So, we will send the last 6 digits of the id.
  const employeeID = String(getEmployeeID(getState())).slice(-6);

  const paymentsToProcess = cardPayments.filter(payment =>
    shouldVoid ? payment.paid : !payment.paid,
  );

  await paymentsToProcess.reduce(async (all, payment) => {
    await all;
    return dispatch(
      withWaitingForTerminal(async () => {
        enableButtons([FUNC_BUTTONS.CANCEL]);
        try {
          // voiding
          if (payment.paid && payment.shouldProcess) {
            // we can not void payment without ref no
            if (!payment.attributes?.refNo) {
              throw new Error(
                'Can not void payment without reference number!',
                { cause: payment },
              );
            }

            updateMessage(
              `Voiding payment of ${payment.amount} with refNo #${payment.attributes.refNo}`,
            );
            const record = await processTransaction<VoidTransaction>({
              referenceNumber: payment.attributes.refNo,
              transactionType: TransactionType.VOID,
              customerID,
              employeeID,
            });

            await handleTransactionResponse({ hooks, payment, record });
            return all;
          }

          // returning
          if (Number(payment.amount) < 0) {
            updateMessage(`Processing return of ${payment.amount}...`);
            const record = await processTransaction<RefundTransaction>({
              amount: payment.amount.replace('-', ''),
              customerID,
              employeeID,
              transactionType: TransactionType.REFUND,
            });

            await handleTransactionResponse({ hooks, payment, record });
            return all;
          }

          // regular payment
          updateMessage(`Processing a payment of ${payment.amount}...`);
          const record = await processTransaction<SaleTransaction>({
            amount: payment.amount,
            customerID,
            employeeID,
            softwareName: 'POS Brazil',
            softwareVersion: version,
            transactionType: TransactionType.SALE,
          });

          await handleTransactionResponse({ hooks, payment, record });
          return all;
        } catch (error) {
          const errorMessage = getErrorMessage(
            error,
            `Failed to process ${payment.type} payment with amount of ${payment.amount}`,
          );

          updateMessage(errorMessage);
          enableButtons([FUNC_BUTTONS.RETRY, FUNC_BUTTONS.CLOSE]);

          throw new Error(errorMessage, { cause: error });
        }
      }),
    );
  }, Promise.resolve());
};

export default processPayments;
