import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

import { useBreakpoints } from 'utils/hooks/UI';
import { getShoppingCartNeedsCalculation } from 'reducers/ShoppingCart';
import { calculate } from 'actions/ShoppingCart/calculate';
import { addProgress, dismissType } from 'actions/Error';

import PaymentCTA from '../PaymentCTA';
import IconButton from '../IconButton';

const CALCULATE_PROGRESS_TYPE = 'CALCULATING';

const CalculateAndPayButton = ({ width }) => {
  const dispatch = useDispatch();
  const isMobileView = !useBreakpoints().mdplus;
  const needsCalculation = useSelector(getShoppingCartNeedsCalculation);

  const { t } = useTranslation('gridButtons');

  const calculateShoppingCart = () => {
    dispatch(addProgress('Calculating Shopping Cart', CALCULATE_PROGRESS_TYPE));
    dispatch(calculate({ forceCalculate: true }))
      // @ts-ignore
      .finally(() => dispatch(dismissType(CALCULATE_PROGRESS_TYPE)));
  };

  if (!needsCalculation) return <PaymentCTA width={width} />;

  return (
    <>
      {isMobileView ? (
        <Row noGutters>
          <Col className="mr-2">
            <IconButton
              text={t('actions.calculate')}
              style={{ height: '60px' }}
              block={true}
              variant="success"
              action={calculateShoppingCart}
            />
          </Col>
        </Row>
      ) : (
        <Button
          className={`btn-long button-item btn-grid_green btn-${
            ['hidden', '', 'double', 'triple'][width]
          }`}
          // @ts-ignore
          variant="grid_green"
          onClick={calculateShoppingCart}
          data-testid="payment-button"
        >
          <span
            style={{
              fontSize: '20px',
              textTransform: 'uppercase',
              fontWeight: 700,
            }}
          >
            {t('actions.calculate')}
          </span>
        </Button>
      )}
    </>
  );
};

export default CalculateAndPayButton;
