import { useSelector } from 'react-redux';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { useAsync } from 'react-use';
import { API } from 'plugins/nohble/SpecialOrders/API/API';

export function useInboundUnconfirmedInventoryTransfers() {
  const warehouseID = useSelector(getSelectedWarehouseID);
  return useAsync(() => API.getUnconfirmedInventoryTransfers(warehouseID), [
    warehouseID,
  ]);
}

export const useLinksFromTransfers = (
  transfers: ReturnType<typeof useInboundUnconfirmedInventoryTransfers>,
) => {
  return useAsync(() => {
    if (transfers.error) return Promise.reject<never>(transfers.error);
    if (transfers.loading) return new Promise<never>(() => undefined);

    return API.getLinks({
      transferDocumentIDs: transfers.value?.map(
        transfer => transfer.inventoryTransferID,
      ),
    });
  }, [transfers]);
};
