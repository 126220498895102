/* eslint-disable no-param-reassign,no-console */
import i18next from 'i18next';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import dayjs from 'dayjs';

import { getSetting, getUseLocalProductDB } from 'reducers/configs/settings';
import * as types from 'constants/productsDB';
import * as api from 'services/ErplyAPI/products';
import { addError, addWarning, dismissType } from 'actions/Error';
import { syncLocalDatabaseSO } from 'actions/connectivity';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { getClientCode } from 'reducers/Login';
import { SO } from 'services/DB/types';
import { getItemsFromSO, saveItemsToSO } from 'services/DB';
import { notUndefinedOrNull } from 'utils/tsHelpers';
import { getActivePricelistIDsForPOS } from 'reducers/cachedItems/priceLists';
import { searchProductsDB } from 'services/DB/productSearch';
import { isPriceListActive } from 'utils';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import {
  doClientRequest,
  requestAllRecords,
  requestCompleteResponse,
} from 'services/ErplyAPI/core/ErplyAPI';
import {
  getProductGroupVatRateInSelectedWarehouse,
  getSelectedPosVatRate,
} from 'reducers/vatRatesDB';
import { getSyncIsReadyForType } from 'services/DB/getItemsFromSO';
import {
  getCachedItemsPerType,
  getCachedItemsPerTypeByIDs,
  getCachedItemsPerTypeIsReady,
} from 'reducers/cachedItems';
import { Product } from 'types/Product';
import { PriceList } from 'types/PriceList';
import updateRecordsToLocalDB from 'services/DB/updateRecordsToLocalDB';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import { RootDispatch, RootGetState, RootState } from 'reducers';
import { REMOVE_FROM_CACHE, RESET_CACHE } from 'constants/cachedItems';
import { getShoppingCartProductIDs } from 'reducers/ShoppingCart';
import { getCheckProductDisplayedInGridView } from 'reducers/UI/gridDisplay';

import { setShouldUpdateGrid } from './UI';
import { addChangedItemsToCache } from './cachedItems';

export function getActivePriceListsByIDsAsync(priceListIDs: string[]) {
  return async (dispatch, getState) => {
    const clientCode = getClientCode(getState());
    const warehouseID = getSelectedWarehouseID(getState());
    let priceLists: PriceList[] = [];

    if (getCachedItemsPerTypeIsReady(SO.PRICE_LIST.NAME)(getState())) {
      // If the DB is ready, then we have all (relevant) pricelists cached
      // There may be some ids missing, but they can't possibly be active
      // so there is no need to check and fall back to the API
      priceLists = Object.values(
        getCachedItemsPerTypeByIDs<PriceList>(
          SO.PRICE_LIST.NAME,
          priceListIDs,
        )(getState()),
      );
    } else {
      try {
        const { records } = await requestAllRecords<PriceList>({
          request: 'getPriceLists',
          priceListIDs: priceListIDs?.join(','),
          active: 1,
          endDateFrom: dayjs().format('YYYY-MM-DD'),
          warehouseID,
          recordsOnPage: 100,
        });
        if (records.length) {
          await saveItemsToSO(clientCode, SO.PRICE_LIST.NAME, records, {
            warehouseID,
          }).catch(e =>
            console.error('Failed to save new price lists to cache', e),
          );
          priceLists = records;
        }
      } catch (err) {
        console.error('Failed to load active price lists', priceListIDs, err);
        priceLists = await getItemsFromSO<PriceList>(
          clientCode,
          SO.PRICE_LIST.NAME,
          priceListIDs,
        );
      }
    }

    return priceLists
      .filter(isPriceListActive)
      .flatMap(pl => pl.pricelistRules)
      .filter(notUndefinedOrNull);
  };
}

/**
 * Checks the price lists of the products and returns a new list of the products
 * with additional properties attached.
 * Specifically, it adds the following properties.
 * * priceWithVat,
 * * priceListPrice: product.price,
 * * priceListPriceWithVat: priceWithVat,
 *
 * In PBIB-5366, added the ability to show vat in related products.
 *  Another approach was to move this logic to the UI component handling it.
 * However, due to some bugs the change was kept in this file.
 *
 * @todo Most probably, changes like this should be moved in the future.
 */
function getProductPriceListsAsync(
  products: Product[],
  options: GetProductsUniversalOptions = { reapplyPriceLists: false },
) {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, Action>,
    getState,
  ): Promise<Product[]> => {
    const productIDs = products.map(product => String(product.productID));
    const productsFromRedux = getCachedItemsPerTypeByIDs<Product>(
      SO.PRODUCTS.NAME,
      productIDs,
    )(getState());
    // if products that we are searching for are already in redux cache, return them, since they already have pricelist applied
    if (!options.reapplyPriceLists) {
      const cachedProductIds = Object.keys(productsFromRedux);
      if (productIDs.length === cachedProductIds.length) {
        // Preserve order
        return productIDs.map(id => productsFromRedux[id]);
      }
    }

    const activePriceListIDs = getActivePricelistIDsForPOS(getState());
    const priceListIDs = [
      ...new Set<string>(activePriceListIDs.map(id => String(id))),
    ];

    const priceListRules = await dispatch(
      getActivePriceListsByIDsAsync(priceListIDs),
    );

    const productAndServiceRules = Object.fromEntries(
      priceListRules
        .filter(r => r.type === 'PRODUCT' || r.type === 'SERVICE')
        .map(r => [r.id, r.price]),
    );
    const groupRules = Object.fromEntries(
      priceListRules
        .filter(r => r.type === 'PRODGROUP')
        .map(r => [r.id, r.discountPercent]),
    );

    const vatRateObject = getSelectedPosVatRate(getState());
    const vatRate = vatRateObject?.rate ? Number(vatRateObject.rate) : null;
    return products.map(product => {
      if (!options.reapplyPriceLists) {
        const productFromReduxCache = productsFromRedux[product.productID];
        if (productFromReduxCache) return productFromReduxCache;
      }

      const productGroupVatRate = getProductGroupVatRateInSelectedWarehouse(
        product.groupID,
      )(getState())?.rate;
      const vat = Number(productGroupVatRate ?? vatRate ?? product.vatrate);

      const priceListPrice = productAndServiceRules[product.productID];
      const groupDiscount = groupRules[product.productID];

      const isProductTaxFree = product.taxFree === 1;
      const priceWithVat = isProductTaxFree
        ? product.price
        : (product.price * (100 + vat)) / 100;

      if (priceListPrice) {
        return {
          ...product,
          priceWithVat,
          priceListPrice: Number(priceListPrice),
          priceListPriceWithVat: isProductTaxFree
            ? Number(priceListPrice)
            : (Number(priceListPrice) * (100 + vat)) / 100,
        };
      }

      if (groupDiscount) {
        return {
          ...product,
          priceWithVat,
          priceListPrice: (product.price * (100 - Number(groupDiscount))) / 100,
          priceListPriceWithVat:
            (priceWithVat * (100 - Number(groupDiscount))) / 100,
        };
      }
      // if no active price list, override old price list fetched from product sync
      return {
        ...product,
        priceWithVat,
        priceListPrice: product.price,
        priceListPriceWithVat: priceWithVat,
      };
    });
  };
}

function getProductStockLevelAsync(products: Product[]) {
  return async (dispatch, getState): Promise<Product[]> => {
    if (!products.length) return [];
    const warehouseID = Number(getSelectedWarehouseID());
    const clientCode = getClientCode(null);
    const productIDs = products.map(pr => pr.productID);

    let stockLevelsDict;
    if (getCachedItemsPerTypeIsReady(SO.PRODUCT_STOCK.NAME)(getState())) {
      stockLevelsDict = getCachedItemsPerType(SO.PRODUCT_STOCK.NAME)(
        getState(),
      );
    } else {
      let stockLevelArray;
      try {
        stockLevelArray = await doClientRequest({
          request: 'getProductStock',
          productIDs: productIDs.join(),
          getAmountReserved: 1,
          warehouseID,
        });
      } catch (err) {
        console.error('Failed to load product stock for', productIDs, err);
        stockLevelArray = await getItemsFromSO(
          clientCode,
          SO.PRODUCT_STOCK.NAME,
          productIDs,
          { warehouseID },
        );
      }

      stockLevelsDict = Object.fromEntries(
        stockLevelArray.map(p => [p.productID, p]),
      );
    }

    return products.map(product => {
      const { amountInStock = 0, amountReserved = 0, reorderPoint = 0 } =
        stockLevelsDict[product.productID] ?? {};
      return {
        ...product,
        totalInStock: Number(amountInStock),
        reserved: Number(amountReserved),
        free: Number(amountInStock) - Number(amountReserved),
        reorderPoint: Number(reorderPoint),
      };
    });
  };
}

type StockLevelRecord = {
  productID: number;
  amountInStock: string;
  amountReserved: string;
  warehouseID: number;
  reorderPoint: number;
};

/**
 * Action to update stock level after every sale
 *
 */
export function updateStockLevels(saleRows: any[]) {
  return async (dispatch, getState) => {
    const warehouseID = Number(getSelectedWarehouseID(getState()));
    const clientCode = getClientCode(getState());

    await updateRecordsToLocalDB(clientCode, SO.PRODUCT_STOCK.NAME, {}).catch(
      async err => {
        console.error(
          'Syncing stock level after sale failed. Proceeding with manual update.',
          err,
        );
        if (saleRows.length) {
          const stockLvls = await getItemsFromSO<StockLevelRecord>(
            clientCode,
            SO.PRODUCT_STOCK.NAME,
            saleRows.map(r => Number(r.productID)),
            { warehouseID },
          );
          await saveItemsToSO(clientCode, SO.PRODUCT_STOCK.NAME, [
            ...stockLvls.map((lvl, index) => ({
              ...(lvl ?? {
                productID: Number(saleRows[index].productID),
                amountReserved: '0',
                warehouseID,
              }),
              reorderPoint: Number(lvl.reorderPoint ?? 0),
              amountInStock:
                Number(lvl.amountInStock ?? 0) - Number(saleRows[index].amount),
            })),
          ]);
        }
      },
    );
  };
}

type CustomRequestParams = {
  /**
   * When passed, fetch store info just for the current warehouse
   * Creates fields 'free', 'totalInStock', and 'reserved'
   *
   * If fetched from API, uses 'getStockInfo' param and copies over the relevant info
   */
  getLocalStockInfo: 1 | 0;
};
export type ProductRequestParams = Partial<
  CustomRequestParams & {
    posID: number;
    clientID: number;
    productID: number;
    productIDs: number[];
    type: string;
    groupID: number;
    name: string;
    code: string;
    code2: string;
    code3: string;
    supplierCode: string;
    code5: string;
    code6: string;
    code7: string;
    code8: string;
    searchNameIncrementally: string;
    fullTextSearchPhrase: string;
    pageNo: number;
    recordsOnPage: number;
    orderBy:
      | 'name'
      | 'code'
      | 'productID'
      | 'price'
      | 'parentProductID'
      | 'changed'
      | 'added';
    orderByDir: 'asc' | 'desc';
    getProductsFor: 'SALES' | 'ORDERING';
    warehouseID: number;
    getFields?: string;
  } & Record<
      | 'includeMatrixVariations'
      | 'active'
      | 'getPriceListPrices'
      | 'getWarehouseSpecificVAT'
      | 'getRecipes'
      | 'getPackageInfo'
      | 'getReplacementProducts'
      | 'getRelatedProducts'
      | 'getParameters'
      | 'getPackagingMaterials'
      | 'getRelatedFiles'
      | 'getContainerInfo'
      | 'getMatrixVariations'
      | 'getAllLanguages'
      | 'getPriceCalculationSteps'
      | 'nonRefundableProduct'
      | 'searchCodeFromMiddle'
      | 'getItemsFromFirstPriceListOnly'
      | 'getOnlyItemsInStock'
      | 'regularGiftCards'
      | 'getStockInfo',
      1 | 0
    >
>;

export type ProductsAndTotalReturnType = {
  products: Product[];
  total: number;
  sources: Set<'api' | 'idb' | 'mem'>;
};

function convertPropsToAPIParameters(params): ProductRequestParams {
  return R.pipe(
    R.filter(notUndefinedOrNull),
    R.map(prop => {
      if (typeof prop === 'boolean') return Number(prop);
      return prop;
    }),
    // Custom param 'getLocalStockInfo' needs stock info - fetch it with getStockInfo
    R.when(R.prop('getLocalStockInfo'), R.assoc('getStockInfo', 1)),
    R.dissoc('getLocalStockInfo'),
  )({
    ...R.when(
      () => !(params.productID || params.productIDs || params.getProductsFor),
      R.assoc('active', 1),
    )(SO.PRODUCTS.EXTRA_PARAMS),
    recordsOnPage: 100,
    ...(typeof params === 'string'
      ? {
          searchNameIncrementally: params,
        }
      : params),
  });
}
function getProductsByIDs(
  params: ProductRequestParams,
  options: GetProductsUniversalOptions,
): (dispatch, getState) => Promise<ProductsAndTotalReturnType> {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, Action>,
    getState,
  ): Promise<ProductsAndTotalReturnType> => {
    const out: ProductsAndTotalReturnType = {
      products: [],
      get total() {
        return this.products.length;
      },
      sources: new Set(),
    };
    try {
      const { localFirst = true } = options;
      const parameters = convertPropsToAPIParameters(params);

      let ids = [
        ...new Set(
          [...(parameters.productIDs ?? []), parameters.productID].filter(
            notUndefinedOrNull,
          ),
        ),
      ]
        .map(n => Number(n))
        // Dealing with free-tex line products. These items have an ID value of 0. They must be filtered out if not they will cause the api to return all products in the database.
        .filter(id => id);

      if (!ids.length) {
        return out;
      }

      if (localFirst) {
        const productsMatchedInMemory = getCachedItemsPerTypeByIDs<
          Product,
          number
        >(
          SO.PRODUCTS.NAME,
          ids,
        )(getState());

        const idsInMemory = Object.keys(productsMatchedInMemory);

        if (Object.values(productsMatchedInMemory).length) {
          ids = ids.filter(id => !idsInMemory.includes(id.toString()));
          out.products.push(...Object.values(productsMatchedInMemory));
          out.sources.add('mem');
        }

        if (!ids.length) {
          return out;
        }

        const { products: iDBProducts } = await dispatch(
          searchProductsDB({
            ...parameters,
            productIDs: ids,
          }),
        );

        const idsInIDBProducts = iDBProducts.map(({ productID }) => productID);

        if (iDBProducts.length) {
          ids = ids.filter(id => !idsInIDBProducts.includes(id));
          out.products.push(...iDBProducts);
          out.sources.add('idb');
        }

        if (!ids.length) {
          return out;
        }
      }

      const { records = [] } = await requestCompleteResponse<Product>({
        request: 'getProducts',
        ...parameters,
        productIDs: ids.join(),
      }).catch(err => {
        console.error('Failed to load products by ids', ids, parameters, err);
        return { records: [] };
      });

      if (records.length) {
        out.products.push(...records);
        out.sources.add('api');
      }

      return out;
    } catch (err) {
      console.error('Failed to load products by IDs', params, options, err);
      return out;
    }
  };
}

function getProductByProperties(
  params: ProductRequestParams | string,
  options: GetProductsUniversalOptions = {},
) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, Action>,
    getState: () => RootState,
  ): Promise<ProductsAndTotalReturnType> => {
    const { localFirst = false } = options;
    const clientCode = getClientCode(getState());
    const parameters = convertPropsToAPIParameters(params);
    const productsReady = await getSyncIsReadyForType(
      clientCode,
      SO.PRODUCTS.NAME,
    );
    const assortmentsReady = await getSyncIsReadyForType(
      clientCode,
      SO.ASSORTMENTS.NAME,
    );
    if (
      getUseLocalProductDB(getState()) &&
      localFirst &&
      productsReady &&
      assortmentsReady
    ) {
      const { products, total } = await dispatch(searchProductsDB(parameters));
      return { products, total, sources: new Set(['idb']) };
    }
    try {
      const response = await requestCompleteResponse<Product>({
        request: 'getProducts',
        ...parameters,
      });
      if (response) {
        return {
          products: response.records,
          total: response.status.recordsTotal,
          sources: new Set(['api']),
        };
      }
      return { products: [], total: 0, sources: new Set() };
    } catch (err) {
      console.error('Failed to load products by params', params, options, err);
      const { products, total } = await dispatch(searchProductsDB(parameters));
      return { products, total, sources: new Set(['idb']) };
    }
  };
}

// 'sources' is an implementation detail for now
// If callers start needing this we can expose it and start supporting it
// But in the meantime, keeping it private allows us to refactor it easily
export type ProductsUniversalReturnType = Omit<
  ProductsAndTotalReturnType,
  'sources'
> & {
  productsDict: Record<number, Product>;
};

export type GetProductsUniversalOptions = {
  withMeta?: boolean;
  addToCachedItems?: boolean;
  localFirst?: boolean;
  reapplyPriceLists?: boolean;
};

function assertType<T>(v): asserts v is T {
  // Assume v is T
}

export function getProductsUniversal(
  { ...params }: ProductRequestParams,
  options: GetProductsUniversalOptions = { withMeta: true },
) {
  return async function action(
    dispatch,
    getState,
  ): Promise<{
    products: Product[];
    productsDict: Record<string, Product>;
    total: number;
  }> {
    assertType<RootDispatch>(dispatch);
    assertType<RootGetState>(getState);
    // Legacy support - saved items to always contain this data
    if (options.addToCachedItems) {
      params.getPriceListPrices = 1;
      params.getLocalStockInfo = 1;
    }
    // API param "getProductsFor" requires warehouseID
    if (params.getProductsFor) {
      if (!params.warehouseID) {
        params.warehouseID = getSelectedWarehouseID(getState());
      }
    }
    try {
      let products: Product[];
      let total: number;
      let sources: Set<'api' | 'idb' | 'mem' | 'none'>;
      if (params.productID || params.productIDs) {
        ({ products, total, sources } = await dispatch(
          getProductsByIDs(params, options),
        ));
      } else {
        ({ products, total, sources } = await dispatch(
          getProductByProperties(params, options),
        ));
      }

      // Apply custom properties from local parameters
      if (params.getLocalStockInfo) {
        products.forEach(p => {
          if (p.warehouses && p.free === undefined) {
            const warehouseID = getSelectedWarehouseID(getState());
            p.free = p.warehouses[warehouseID].free;
            p.totalInStock = p.warehouses[warehouseID].totalInStock;
            p.reserved = p.warehouses[warehouseID].reserved;
          }
        });
      }

      // Check all data is presence, fetch separately if required
      let cache = products;
      if (params.getPriceListPrices) {
        // IDB stores without pricelist info
        // memory stores "as is", but there's no way to check
        if (sources.has('idb') || sources.has('mem')) {
          cache = await dispatch(getProductPriceListsAsync(cache, options));
        }
      }

      if (params.getLocalStockInfo) {
        if (cache.some(p => p.free === undefined)) {
          // TODO: Optimization: Only fetch stock levels for products that don't have it
          cache = await dispatch(getProductStockLevelAsync(cache));
        }
      }
      if (options.addToCachedItems) {
        dispatch(
          addChangedItemsToCache(
            SO.PRODUCTS.NAME,
            Object.fromEntries(
              cache.map(pr => [
                pr[SO.PRODUCTS.ID_PROP],
                {
                  ...pr,
                  ...(typeof params !== 'string' &&
                  !Number.isNaN(Number(params.pageNo))
                    ? { page: params.pageNo }
                    : {}),
                },
              ]),
            ),
          ),
        );
      }
      if (options.withMeta !== false) products = cache;
      return {
        products,
        productsDict: Object.fromEntries(products.map(p => [p.productID, p])),
        total,
      };
    } catch (e) {
      console.error('Failed to load products', params, options, e);
      return {
        products: [],
        productsDict: {},
        total: 0,
      };
    }
  };
}

export function setLoading(val) {
  return {
    type: types.LOADING,
    payload: val,
  };
}

/**
 * Returns true if there was an error in the given params
 */
export function saveProduct(params) {
  return async (dispatch, getState) => {
    try {
      const productCodeUnique = getSetting('product_code_unique')(getState());
      dispatch(dismissType('addProduct')); // Dismiss previous errors

      if (params.name === '') {
        dispatch(
          addError(i18next.t('validation:product.nameRequired'), {
            dismissible: true,
            errorType: 'addProduct',
            selfDismiss: 2500,
          }),
        );
        return true;
      }

      if (params.name && params.name.length > 255) {
        dispatch(
          addError(
            i18next.t('validation:product.nameMaxLength', { max: 255 }),
            {
              dismissible: true,
              errorType: 'addProduct',
              selfDismiss: 2500,
            },
          ),
        );
        return true;
      }

      if (params.code && params.code.length > 50) {
        dispatch(
          addError(i18next.t('validation:product.codeMaxLength', { max: 50 }), {
            dismissible: true,
            errorType: 'addProduct',
            selfDismiss: 2500,
          }),
        );
        return true;
      }

      // Ensure code uniqueness
      if (params.code && productCodeUnique) {
        const { products: prodsWithSuchCode } = await dispatch(
          getProductsUniversal(
            { code: params.code, active: 1 },
            {
              withMeta: false,
              addToCachedItems: false,
            },
          ),
        );

        const codeAlreadyExists = prodsWithSuchCode.length > 0;

        if (codeAlreadyExists) {
          dispatch(
            addError(i18next.t('validation:product.codeUnique'), {
              dismissible: true,
              errorType: 'addProduct',
              selfDismiss: 2500,
            }),
          );
          return true;
        }
      }

      await api.saveProduct(params);
      await dispatch(syncLocalDatabaseSO(SO.PRODUCTS.NAME));

      // Update UI flag so that grid knows to update displayed product data
      const productGroupID =
        params.groupID ??
        (await dispatch(
          getProductsUniversal(
            { productID: params.productID },
            { localFirst: true },
          ),
        ).then(({ products: [product] }) => product?.groupID));
      if (productGroupID) {
        await dispatch(setShouldUpdateGrid(productGroupID));
      }

      return false;
    } catch (err) {
      console.error('Failed to save product', err);
    }
  };
}

export function openProductViewForItem({ productID }) {
  return async dispatch => {
    const {
      products: [product],
    } = await dispatch(getProductsUniversal({ productID }));
    dispatch(
      openModalPage({
        component: mp.ProductView,
        props: {
          productItem: product,
        },
      }),
    );
  };
}

export function openStockAndPriceForItem({ productID }) {
  return async (dispatch, getState) => {
    const {
      products: [product],
    } = await dispatch(
      getProductsUniversal(
        { productID },
        { localFirst: false, withMeta: true },
      ),
    );
    if (!getConnectionHealth(getState())) {
      dispatch(addWarning(i18next.t('alerts:errors.noAccessDuringOffline')));
    } else {
      dispatch(
        openModalPage({
          component: mp.stockAndPriceItem,
          props: {
            productItem: product,
          },
        }),
      );
    }
  };
}

export function resetProductCache() {
  return async (dispatch, getState) => {
    const isUsingLocalProductDb = getUseLocalProductDB(getState());
    if (!isUsingLocalProductDb) {
      dispatch({
        type: RESET_CACHE,
        payload: {
          itemType: SO.PRODUCTS.NAME,
        },
      });
      return;
    }

    const checkProductDisplayedInGridView = getCheckProductDisplayedInGridView(
      getState(),
    );
    const productCache = getCachedItemsPerType(SO.PRODUCTS.NAME)(getState());

    const idsToRemove = R.pipe(
      Object.values,
      R.reject(checkProductDisplayedInGridView),
      R.pluck('productID'),
    )(productCache);

    dispatch({
      type: REMOVE_FROM_CACHE,
      payload: {
        itemType: SO.PRODUCTS.NAME,
        ids: idsToRemove,
      },
    });
  };
}

/**
 * If product DB is in use removes all cached products except the ones in cart and ones visible on grid view
 */
export function cleanUpProductCache() {
  return async (dispatch, getState) => {
    const isUsingLocalProductDb = getUseLocalProductDB(getState());
    if (!isUsingLocalProductDb) return;

    const checkProductDisplayedInGridView = getCheckProductDisplayedInGridView(
      getState(),
    );
    const shoppingCartProductIDs = getShoppingCartProductIDs(getState());
    const productCache = getCachedItemsPerType(SO.PRODUCTS.NAME)(getState());

    const idsToRemove = R.pipe(
      Object.values,
      R.filter(
        product =>
          !shoppingCartProductIDs.includes(product.productID) &&
          !checkProductDisplayedInGridView(product),
      ),
      R.pluck('productID'),
    )(productCache);

    dispatch({
      type: REMOVE_FROM_CACHE,
      payload: {
        itemType: SO.PRODUCTS.NAME,
        ids: idsToRemove,
      },
    });
  };
}
