import { processTransactionRequest } from '../requests';
import { RefundTransaction, SaleTransaction, VoidTransaction } from '../types';

export default async function processTransaction<
  T extends SaleTransaction | RefundTransaction | VoidTransaction
>(transaction: T) {
  const response = await processTransactionRequest<T>(transaction);
  const [record] = response.data.records;
  return record;
}
