/**
 * Insert the given value into the list after the first matching element
 *
 * If no match, then a copy of the input list is returned
 * @example
 * const isEven = a => a%2 === 0
 * const data = [1,3,4,5,6,7,8]
 * const insetAfterEven = insertAfter(isEven)
 *
 * insertAfterEven(10, data); // [1, 3, 4, 10, 5, 6, 7, 8]
 * //                                   ^  ^^
 *
 * @example
 * insertAfter(a => a > 2, 'stop', [1,2,3,4,5,6]) // [1,2,3,'stop',4,5,6]
 * //                                   ^
 * @example
 * insertAfter(a => a === 4)(400)([1,2,3,4,5,6]) // [1,2,3,4,400,5,6]
 * @example
 * insertAfter(isOrderButton, specialOrderButton, buttons)
 * @example
 * insertAfter(isEven,100,[1,3,5]); // [1,3,5,100]
 * insertAfter(isEven, 100, []); // [100]
 * @see insertBefore
 */
export const insertAfter = <T>(
  where: (item: T) => boolean,
  what: T,
  into: readonly T[],
): T[] => {
  const index = into.findIndex(where);
  const out = [...into];
  if (index >= 0) {
    out.splice(index + 1, 0, what);
  } else {
    out.push(what);
  }
  return out;
};

/**
 * Clone the matching value in the list, inserting the clone after the original
 *
 * If no match found, then a copy of the input list is returned
 * @example
 * const isEven = a => a%2 === 0
 * const double = a => a * 2
 * const data = [1,3,4,5,6,7,8]
 * const cloneAfterEven = cloneAfter(isEven)
 *
 * cloneAfterEven(double, data); // [1, 3, 4, 8, 5, 6, 7, 8]
 * //                                      ^  ^
 * @example
 * cloneAfter(a => a === 4)(a => a * 100)([1,2,3,4,5,6]) // [1,2,3,4,400,5,6]
 * @example
 * cloneAfter(isOrderButton, convertToSpecialOrderButton, buttons)
 * @example
 * cloneAfter(isEven, double, [1,3,5,7]) // [1,3,5,7]
 * cloneAfter(isEven, double, []) // []
 * @see cloneBefore
 */
export const cloneAfter = <T>(
  where: (item: T) => boolean,
  what: (v: T) => T,
  into: readonly T[],
) => {
  const index = into.findIndex(where);
  const out = [...into];
  if (index >= 0) {
    out.splice(index + 1, 0, what(into[index]));
  }
  return out;
};
