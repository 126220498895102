import { colors, Typography } from '@material-ui/core';
import React from 'react';

import { generateExpirationValues } from '../utils';

export const Expiration = ({
  expires,
  customStyle,
}: {
  expires: Date | undefined;
  customStyle?: React.CSSProperties;
}) => {
  const {
    expiration,
    expired,
    expiresSoon,
    untilExpiration,
  } = generateExpirationValues(expires);

  const getTextToShow = () => {
    if (!expiration) return 'Non member';
    if (expired) return `Expired ${expiration.format('MM/DD/YY')}`;
    if (!untilExpiration) return expiration.format('MM/DD/YY');

    return expiration.format('MM/DD/YY').concat(` (${untilExpiration} days)`);
  };

  return (
    <Typography
      component="span"
      style={
        customStyle ?? {
          color:
            (expiration && expired && colors.red[500]) ||
            (expiresSoon && colors.orange[500]) ||
            (expiration && colors.green[500]) ||
            colors.red[500],
        }
      }
    >
      {getTextToShow()}
    </Typography>
  );
};
