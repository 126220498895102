import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { clockOut } from 'services/ErplyAPI/clockInOut';
import { version } from 'external_data';
import { hardLogout } from 'actions/Login';
import { useAppDispatch } from 'reducers';

import { styles } from '../util';

const ClockInOutInterface = ({
  updateView,
  toggleClockInOut,
  clockInOutState,
  warningMessage = null,
  onSignin,
  resetClockInDisplay = null,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('clockInOut');
  const [messageCase, updateMessageCase] = useState(0);
  const displayMessage = caseNumber => {
    switch (caseNumber) {
      case 0:
        return (
          <Trans i18nKey="clockInOut:clockOutConfirm">
            <strong />
          </Trans>
        );
      case 1:
        return (
          <Trans
            i18nKey="clockInOut:clockInSuccess"
            tOptions={{ warehouse: clockInOutState.warehouseName }}
          >
            <strong style={{ marginLeft: '5px' }} />
          </Trans>
        );
      case 2:
        return <strong>{t('clockOutSuccess')}</strong>;
      default:
        return 0;
    }
  };
  useEffect(() => {
    if (!clockInOutState.isClockedIn) {
      updateMessageCase(1);
    }
  }, []);
  return (
    <Card style={styles.card}>
      <Card.Header style={styles.header}>
        {t('title')}
        <span
          onClick={() => {
            updateView('login');
            toggleClockInOut(false);
          }}
          className="Clickable-icon icon_menu" // icon_grid-3x3 for login
          style={{
            fontSize: '40px',
            // transform: "scale(0.7, 0.7)" for login
          }}
        />
      </Card.Header>
      <Card.Body className="p-sm-4 pb-4">
        <Container style={{ textAlign: 'center', marginBottom: '15px' }}>
          {displayMessage(messageCase)}
        </Container>
        <Container>
          <Row>
            <Col
              sm={6}
              xs={12}
              style={{ display: messageCase === 2 ? 'none' : 'initial' }}
            >
              <Button
                onClick={() => {
                  toggleClockInOut(false);
                  updateView('login');
                  dispatch(hardLogout());
                }}
                style={{ flexGrow: 1, ...styles.button }}
                size="lg"
                variant="secondary"
              >
                {t('common:cancel')}
              </Button>
            </Col>
            <Col sm={messageCase === 2 ? 12 : 6} xs={12}>
              <Button
                onClick={async () => {
                  if (messageCase === 1) {
                    toggleClockInOut(false);
                    return onSignin(
                      clockInOutState.clientCode,
                      clockInOutState.username,
                      clockInOutState.password,
                    );
                  }

                  if (messageCase === 2) {
                    updateView('login');
                    toggleClockInOut(false);
                    return dispatch(hardLogout());
                  }

                  try {
                    await clockOut({
                      employeeID: clockInOutState.employeeID,
                      warehouseID: clockInOutState.warehouseID,
                      InUnixTime: clockInOutState.clockInUnixTime,
                      OutUnixTime: Math.round(new Date().getTime() / 1000),
                    });
                    resetClockInDisplay();
                    updateMessageCase(2);
                  } catch (e) {
                    console.error('Failed to clock out', e);
                    warningMessage(t('alerts:genericError'));
                  }
                  return true;
                }}
                style={{ flexGrow: 1, ...styles.button }}
                size="lg"
                variant="primary"
              >
                {t('common:ok')}
              </Button>
            </Col>
          </Row>
        </Container>
        <span style={styles.version}>{version}</span>
      </Card.Body>
    </Card>
  );
};

ClockInOutInterface.propTypes = {
  updateView: PropTypes.func.isRequired,
  toggleClockInOut: PropTypes.func.isRequired,
  clockInOutState: PropTypes.object.isRequired,
  warningMessage: PropTypes.func,
};

export default ClockInOutInterface;
