/* eslint-disable */
import React, { ChangeEvent, useState } from 'react';

import { PosPlugin } from 'plugins/plugin';
import { useDispatch, useSelector } from 'react-redux';
import { getGiftcardTypes, GiftCardType } from 'reducers/giftcards';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { PaymentType } from 'types/PaymentType';
import { Form, Table } from 'react-bootstrap';
import UIButton from 'components/UIElements/UIButton';
import { createPaymentType, getPaymentTypesAction } from 'actions/PaymentTypes';
import { addSuccess, addWarning, dismissType } from '../../../../actions/Error';
import { useTranslation } from 'react-i18next';
import { TYPES } from '../../../../constants/Error';

const ComponentConfiguration: PosPlugin['ComponentConfiguration'] = ({
  current,
  save,
}) => {
  const { t } = useTranslation('alerts');
  const dispatch = useDispatch();
  const giftcardTypes: GiftCardType[] = useSelector(getGiftcardTypes);
  const paymentTypes: PaymentType[] = useSelector(getAllPaymentTypes);
  const [loading, setLoading] = useState(false);
  const [conf, setConf] = useState<Record<string, string>>(current ?? {});

  const giftCardNameToPaymentCode = name => {
    return name
      .replace(/[\W_]+/g, '')
      .toUpperCase()
      .slice(0, 10);
  };

  const createMatchingType = async giftCardType => {
    setLoading(true);
    await dispatch(
      createPaymentType({
        type: giftCardNameToPaymentCode(giftCardType),
        name: giftCardType.toUpperCase(),
        print_name: giftCardType.toUpperCase(),
      }),
    );
    setLoading(false);
    dispatch(getPaymentTypesAction());
  };

  const handlePTChange = (e, giftCardID) => {
    const { value: paymentTypeID } = e.target;

    if (paymentTypeID)
      setConf(cf => ({ ...cf, [giftCardID]: Number(paymentTypeID) }));
  };

  const saveConfiguration = async () => {
    dispatch(addWarning(t('general.saving')));
    await save(conf);
    dispatch(dismissType(TYPES.WARNING));
    dispatch(addSuccess(t('general.success')));
  };

  const checkGTHasPT = giftcardType =>
    paymentTypes.filter(
      pt => pt.type === giftCardNameToPaymentCode(giftcardType),
    )?.length;

  return (
    <div>
      <p>
        In order to see each giftcard type as a separate tender during Drawer
        closing, each giftcard type needs to be linked to a payment type.
      </p>
      <Table>
        <thead>
          <tr>
            <th>Gift card type</th>
            <th>Payment type</th>
            <th>Matching payment type exists</th>
          </tr>
        </thead>
        <tbody>
          {giftcardTypes.map(gt => (
            <tr>
              <td className="align-middle">{gt.nameEN}</td>
              <td className="align-middle">
                {checkGTHasPT(gt.nameEN) ? (
                  'Type already exists'
                ) : (
                  <UIButton
                    disabled={loading}
                    variant="POS"
                    action={() => createMatchingType(gt.nameEN)}
                    text="Generate"
                  />
                )}
              </td>
              <td className="align-middle">
                <Form.Control
                  as="select"
                  value={conf[gt.id]}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    handlePTChange(e, gt.id)
                  }
                >
                  <option value="">Select payment type</option>
                  {paymentTypes.map(pt => {
                    return (
                      <option value={pt.id}>{`${pt.name} / ${pt.type}`}</option>
                    );
                  })}
                </Form.Control>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <UIButton text="Save" variant="POS" action={saveConfiguration} />
      </div>
    </div>
  );
};

export default ComponentConfiguration;
