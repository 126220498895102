import { version } from 'external_data';
import { getErrorMessage } from 'paymentIntegrations';

import swedbankRequest from './index';

const swedbankPayment = async (
  cardPayment,
  { currencyCode },
  { updateMessage },
  numberOfPayments,
  index,
  clientCode,
) => {
  updateMessage(
    `Performing payment ${index + 1} for ${cardPayment.amount}...
    Transaction ${index + 1} / ${numberOfPayments}`,
  );
  const data = {
    requestID: cardPayment.uuid,
    transactionType: 'SALE',
    amount: cardPayment.amount,
    invoiceNr: `${cardPayment.added}`,
    currency: currencyCode,
    softwareName: 'Erply Brazil POS',
    softwareVersion: version,
    clientCode,
  };

  try {
    const payment = await swedbankRequest.post('payment', data);

    return payment;
  } catch (error) {
    updateMessage(
      getErrorMessage(
        error,
        'There was an error while performing the refund. Please try again',
      ),
    );
  }
  return true;
};

export default swedbankPayment;
