import {
  ChaseTransactionResponse,
  RefundTransaction,
  SaleTransaction,
  VoidTransaction,
} from '../types';

import instance from './instance';

export default async function processTransactionRequest<
  T extends RefundTransaction | SaleTransaction | VoidTransaction
>(transaction: T) {
  try {
    const response = await instance.post<ChaseTransactionResponse>(
      'payment',
      transaction,
    );
    return response;
  } catch (error) {
    throw new Error(
      `Chase failed to process ${transaction.transactionType} transaction`,
      {
        cause: error,
      },
    );
  }
}
