import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  List,
  ListItem,
  styled,
  Typography,
} from '@material-ui/core';

import Loader from 'components/Loader';
import CloseButton from 'components/CustomButtons/CloseButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { cancelOrderAction, pickupOrder } from 'actions/sales';
import { getHasRightToReturn } from 'reducers/Login';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { PluginComponent } from 'plugins';

const StyledListItem = styled(ListItem)({
  borderRadius: '6px',
});

const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
});

const ActionSelection: FC<{
  sale: SaleDocumentResponse;
  onClose: () => void;
}> = ({ sale, onClose }) => {
  const { t } = useTranslation('order');
  const hasRightToReturn = useSelector(getHasRightToReturn);
  const dispatch = useDispatch();

  const cancelOrder = () => {
    dispatch(closeModalPage());
    dispatch(cancelOrderAction(sale));
  };

  /* Order continuation - populates cart, selects customer, allows to either add new products or edit current then either save as order or pay (previous behavior) */
  const pickUp = async () => {
    try {
      await dispatch(pickupOrder(sale));
      dispatch(closeModalPage());
    } catch {
      // ignore error
    }
  };

  return (
    <PluginComponent
      hookname="UIOrderActionSelection"
      props={{ sale, pickUp, cancelOrder, onClose }}
    >
      <Box padding="1rem" data-testid="pickup-orders-action-selection-modal">
        <Box
          display="flex"
          alignItems="center"
          marginBottom="0.75rem"
          data-testid="action-selection-header"
        >
          <Box data-testid="order-action-selection-title">
            <Typography variant="h5">{t('actionSelector.title')}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" flex={1}>
            <CloseButton
              style={{ transform: 'scale(0.85)' }}
              action={onClose}
              key={`order-${sale.id}-action-selection-close-button`}
              data-testid="order-action-selection-close-button"
            />
          </Box>
        </Box>
        <Divider style={{ marginBottom: '1em' }} />
        <StyledList data-testid="action-selection">
          <StyledListItem
            className="dark_list_item"
            button
            onClick={pickUp}
            key={`order-${sale.id}-action-selection-pickup-button`}
            data-testid="order-action-selection-pickup-button"
          >
            <Typography variant="h6">{t('actionSelector.pickup')}</Typography>
          </StyledListItem>
          <StyledListItem
            className="dark_list_item"
            button
            onClick={cancelOrder}
            disabled={!hasRightToReturn}
            key={`order-${sale.id}-action-selection-cancel-button`}
            data-testid="order-action-selection-cancel-button"
          >
            <Typography variant="h6">{t('actionSelector.cancel')}</Typography>
          </StyledListItem>
        </StyledList>
      </Box>
    </PluginComponent>
  );
};

export default ActionSelection;
