import { v1 as uuidv1 } from 'uuid';

import { TYPE_CLOSE, TYPE_SHOW } from '../constants/Confirmation';

/**
 * Create a popup that will call either resolve or reject when the user presses OK/CANCEL on it
 * if reject is not passed in then there will be no CANCEL button
 * @param resolve Callback when the user presses OK
 * @param reject Callback when the user presses CANCEL - omit to only have an OK button
 * @param props Props to pass to the confirmation
 */
// eslint-disable-next-line import/prefer-default-export
export function createConfirmation(resolve, reject, props) {
  const id = uuidv1();
  return {
    type: TYPE_SHOW,
    payload: {
      resolve,
      reject,
      id,
      props: { ...props },
    },
  };
}

export function closeConfirmationByID(id) {
  return {
    type: TYPE_CLOSE,
    payload: { id },
  };
}

// TODO: Possibly offer another action to clear all confirmations?
