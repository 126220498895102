/* eslint-disable no-console */
import i18next from 'i18next';

import { createConfirmation } from 'actions/Confirmation';
import { getCafaEntry2 } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { printPatchscript } from 'actions/integrations/printer';
import { PatchScript } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';

import { NETS_CONST, NetsConfig, ValueOf } from '../types';

export const print = string => async dispatch => {
  const patchScript: PatchScript = string
    .split('\n')
    .map(text => ({ type: 'text', pieces: [{ text }] }));
  dispatch(printPatchscript(patchScript));
};

const confirmCopyPrint = toWhom => async dispatch =>
  new Promise((resolve, reject) =>
    dispatch(
      createConfirmation(resolve, reject, {
        title: i18next.t(`${NETS_CONST.TRANS_PATH}.printReceipt.title`, {
          toWhom,
        }),
        body: i18next.t(`${NETS_CONST.TRANS_PATH}.printReceipt.body`, {
          toWhom,
        }),
      }),
    ),
  )
    .then(() => true, () => false);

const shouldPrint = (
  copyType: ValueOf<typeof NETS_CONST.CONFIG.PRINT>,
) => async (dispatch, getState) => {
  const config = getCafaEntry2<Required<NetsConfig>>(
    NETS_CONST.CAFA_CONFIG_NAME,
    INTEGRATION_TYPES.payment,
  )(getState());
  switch (config[copyType]) {
    case NETS_CONST.CONFIG.OPTIONS.ALWAYS:
      return true;
    case NETS_CONST.CONFIG.OPTIONS.ASK:
      return dispatch(
        confirmCopyPrint(
          copyType === NETS_CONST.CONFIG.PRINT.MERCHANT
            ? i18next.t(`${NETS_CONST.TRANS_PATH}.printReceipt.merchant`)
            : i18next.t(`${NETS_CONST.TRANS_PATH}.printReceipt.customer`),
        ),
      );
    case NETS_CONST.CONFIG.OPTIONS.NEVER:
    default:
      return false;
  }
};

export default (
  merchantR: string | null,
  clientR?: string,
) => async dispatch => {
  if (
    merchantR &&
    (await dispatch(shouldPrint(NETS_CONST.CONFIG.PRINT.MERCHANT)))
  ) {
    await dispatch(print(merchantR));
  }

  if (
    clientR &&
    (await dispatch(shouldPrint(NETS_CONST.CONFIG.PRINT.CUSTOMER)))
  ) {
    await dispatch(print(clientR));
  }
};
