import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import { from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import i18next from 'i18next';

import {
  getCustomerSearchFilterOption,
  getSetting,
} from 'reducers/configs/settings';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getBarcodeDataExtractorFn } from 'reducers/cachedItems/products';
import { getScanningAlgorithm } from 'reducers/cafaConfigs';
import { getCustomers } from 'services/ErplyAPI';
import { Product as APIProductType } from 'types/Product';
import { Customer } from 'types/Customer';
import { playSoundEvent } from 'services/Audio/Sound';
import { modalPages } from 'constants/modalPage';
import { notUndefinedOrNull } from 'utils';
import { getCanAddCustomers, getCanAddProducts } from 'reducers/Login';
import { getCurrentErrors } from 'reducers/Error';
import { getMessageIfScanningShouldBeBlocked } from 'reducers/modalPage';
import { RootState } from 'reducers';
import { getPluginLifecycleHook } from 'reducers/Plugins';

import { addError, addWarning, dismissType } from './Error';
import { getProductsUniversal, ProductRequestParams } from './productsDB';
import { addProduct } from './ShoppingCart/addProduct';
import { setCustomer } from './CustomerSearch/setCustomer';
import { openModalPage } from './ModalPage/openModalPage';

type Product = APIProductType & { embeddedPrice?: number };

export type ScannerSearchResults = {
  products: Product[];
  customers: Customer[];
};

type ScannerResultHandlers = {
  exactMatch: (results: ScannerSearchResults) => Promise<void>;
  multipleMatches: (results: ScannerSearchResults) => Promise<void>;
};

type CustomerMiniStep = {
  request: 'customers';
  by: 'searchNameIncrementally' | 'searchRegistryCode' | 'searchCode';
};

type ProductMiniStep = {
  request: 'products';
  by:
    | 'fullTextSearchPhrase'
    | 'code'
    | 'code2'
    | 'code3'
    | 'supplierCode'
    | 'code5'
    | 'code6'
    | 'code7'
    | 'code8'
    | 'name';
};

export type ScanningAlgorithmMiniStep = CustomerMiniStep | ProductMiniStep;
export type ScanningNoSearchResultsHandler =
  | 'notification'
  | 'popup'
  | 'creation';
export type ScanningAlgorithmStep = ScanningAlgorithmMiniStep[];
export type ScanningAlgorithm = ScanningAlgorithmStep[];
type ScanningSearchFunction<T extends Product[] | Customer[], E extends any> = (
  extraParams: E,
) => ThunkAction<Promise<T>, RootState, E, Action>;

const getSearchProductBaseParams = createSelector(
  state =>
    getSetting('touchpos_search_product_code_from_middle')(state) ? 1 : 0,
  state =>
    getSetting('pos_allow_selling_only_from_pricelist1')(state) ? 1 : 0,
  state => getSelectedWarehouseID(state),
  (searchCodeFromMiddle, getItemsFromFirstPriceListOnly, warehouseID) =>
    ({
      searchCodeFromMiddle,
      getItemsFromFirstPriceListOnly,
      getProductsFor: 'SALES',
      warehouseID,
      getPriceListPrices: 1,
    } as ProductRequestParams),
);

type ReturnType<T extends Product[] | Customer[], E extends any> = ThunkAction<
  Promise<T>,
  RootState,
  E,
  Action
>;

const getSearchCustomerBaseParams = createSelector(
  state => getCustomerSearchFilterOption(state),
  state => getSelectedWarehouseID(state),
  (queryFilterOption, warehouseID) => {
    const filteringOption: Record<string, number | string> = {};
    if (queryFilterOption === 'byHomeStore') {
      filteringOption.homeStoreID = warehouseID;
    } else if (queryFilterOption === 'bySignupStore') {
      filteringOption.signUpStoreID = warehouseID;
    }
    return {
      recordsOnPage: 100,
      getAddresses: 1,
      getBalanceInfo: 1,
      getBalanceWithoutPrepayments: 1,
      searchFromMiddle: 1,
      responseMode: 'detail',
      ...filteringOption,
    };
  },
);

function productSearchFn(
  extraParams: ProductRequestParams,
): ReturnType<Product[], ProductRequestParams> {
  return async (dispatch, getState) => {
    return dispatch(
      getProductsUniversal(
        {
          ...getSearchProductBaseParams(getState()),
          ...extraParams,
        },
        {
          localFirst: false,
        },
      ),
    ).then(({ products }) => products);
  };
}

function customerSearchFn(
  extraParams: Record<string, string | number>,
): ReturnType<Customer[], Record<string, string | number>> {
  return async (dispatch, getState) => {
    return getCustomers({
      ...getSearchCustomerBaseParams(getState()),
      ...extraParams,
    });
  };
}

const searchFnDict: Record<
  ScanningAlgorithmMiniStep['request'],
  ScanningSearchFunction<
    Product[] | Customer[],
    Record<string, string | number> & ProductRequestParams
  >
> = {
  products: productSearchFn,
  customers: customerSearchFn,
};

class InterruptScanningCycleError extends Error {
  constructor() {
    super();
    this.name = 'InterruptScannerCycleError';
    this.message = 'Scanner search has found results';
  }
}

function displayScanningCode(code: string) {
  return async (dispatch, getState) => {
    const displayScannedCodeDuringScanning = getSetting(
      'touchpos_display_code_during_scanning',
    )(getState());
    if (displayScannedCodeDuringScanning) {
      dispatch(
        addWarning(`Searching code ${code}`, {
          dismissible: false,
          selfDismiss: false,
          errorType: 'scannedCodeSearch',
        }),
      );
    }
  };
}
function parseEmbeddedBarcodeInfo(
  products: Product[],
  query: string,
): ThunkAction<Promise<Product[]>, RootState, unknown, Action> {
  return async (
  dispatch,
  getState,
) => {
    const { weight, embeddedPrice } = getBarcodeDataExtractorFn(getState())(
      query,
    );

    return products.map(p => {
      if (weight) {
        return {
          ...p,
          amount: weight,
        };
      }
      if (embeddedPrice) {
        return {
          ...p,
          embeddedPrice,
          amount: 1,
        };
      }
      return p;
    });
  };
}

export function handleScanning(
  query: string,
): ThunkAction<
  Promise<void>,
  RootState,
  Record<string, string | number>,
  Action
> {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onScan')(getState());

    const threw = Symbol('threw');
    const beforeResult = await dispatch(before(query)).catch(() => threw);
    if (beforeResult === threw) return;

    if (!query) return;

    // Ensure translations loaded
    if (!i18next.hasLoadedNamespace('scanSearchResults'))
      await new Promise(res =>
        i18next.loadNamespaces('scanSearchResults', res),
      );

    /**
     * Check various abort conditions and display an appropriate error message if any of them are true.
     *
     * @return true if any of the error conditions are present, false otherwise
     */
    const alertIfBlocked = () => {
      const err = getMessageIfScanningShouldBeBlocked(getState());
      if (err) {
        const [key, data] = err;
        dispatch(
          addError(
            i18next.t(`scanSearchResults:warnings.${key}`, {
              context: data,
              scannedText: query,
            }),
            {
              dismissible: true,
              selfDismiss: false,
              errorType: 'scanningOnBlacklistedModal',
            },
          ),
        );
        return true;
      }
      return false;
    };

    if (alertIfBlocked()) return;

    const hasRightsToAddCustomer = getCanAddCustomers(getState()) === 1;
    const hasRightsToAddProduct = getCanAddProducts(getState()) === 1;
    const currentErrors = getCurrentErrors(getState());

    const defaultScanningBehaviour: ScanningAlgorithm = [
      [{ request: 'products', by: 'code2' }],
      [{ request: 'products', by: 'code' }],
      [{ request: 'products', by: 'name' }],
    ];

    // eslint-disable-next-line prefer-const
    let { steps, noResultsHandler } = getScanningAlgorithm(getState());
    if (!steps?.length) steps = defaultScanningBehaviour;
    const { prodCode = query } = getBarcodeDataExtractorFn(getState())(query);

    dispatch(displayScanningCode(prodCode));

    const resultsHandlers: ScannerResultHandlers = {
      exactMatch: async results => {
        if (results.products?.length) {
          const [product] = await dispatch(
            parseEmbeddedBarcodeInfo(results.products, query),
          );
          if (
            currentErrors.some(
              err => err?.text === i18next.t('payment:alerts.paymentOpening'),
            )
          ) {
            throw new InterruptScanningCycleError();
          }
          dispatch(playSoundEvent('scan/success/product'));
          dispatch(
            addProduct({
              productID: product.productID,
              price: product.embeddedPrice || undefined,
              needsWeightPopup: !product.amount,
              amount: product?.amount,
              addedByScanner: true,
            }),
          );
        }

        if (results.customers?.length) {
          const customer = results.customers[0];
          dispatch(playSoundEvent('scan/success/customer'));
          dispatch(
            addWarning(`Customer ${customer.fullName} found`, {
              dismissible: true,
              selfDismiss: true,
            }),
          );
          if (
            currentErrors.some(
              err => err?.text === i18next.t('payment:alerts.paymentOpening'),
            )
          ) {
            throw new InterruptScanningCycleError();
          }
          dispatch(setCustomer({ data: customer }));
        }
        throw new InterruptScanningCycleError();
      },
      multipleMatches: async results => {
        if (
          currentErrors.some(
            err => err?.text === i18next.t('payment:alerts.paymentOpening'),
          )
        ) {
          throw new InterruptScanningCycleError();
        }
        let products: Product[] = [];
        if (results?.products?.length) {
          products = await dispatch(
            parseEmbeddedBarcodeInfo(results.products, query),
          );
        }
        if (
          currentErrors.some(
            err => err?.text === i18next.t('payment:alerts.paymentOpening'),
          )
        ) {
          throw new InterruptScanningCycleError();
        }
        dispatch(playSoundEvent('scan/failure/multiple'));
        dispatch(
          openModalPage({
            component: modalPages.ScannerMultiResults,
            isPopup: true,
            props: { results: { ...results, products } },
          }),
        );

        throw new InterruptScanningCycleError();
      },
    };

    const observable = await dispatch(
      on(
        query,
        from(steps).pipe(
          concatMap(async step => {
            if (alertIfBlocked()) {
              throw new InterruptScanningCycleError();
            }

            await Promise.all(
              step.map(miniStep =>
                dispatch(
                  searchFnDict[miniStep.request]({ [miniStep.by]: prodCode }),
                ),
              ),
            )
              .then(results =>
                step.reduce<ScannerSearchResults>(
                  (acc, miniStep, i) => {
                    return {
                      ...acc,
                      [miniStep.request]: [
                        ...acc[miniStep.request],
                        ...results[i],
                      ],
                    };
                  },
                  { products: [], customers: [] },
                ),
              )
              .then(async results => {
                const totalResultsCount = Object.values(results)
                  .flat()
                  .filter(notUndefinedOrNull).length;

                if (totalResultsCount === 0) return;
                if (totalResultsCount === 1) {
                  await resultsHandlers.exactMatch(results);
                }

                if (totalResultsCount > 1) {
                  await resultsHandlers.multipleMatches(results);
                }
              });
          }),
        ),
      ),
    );

    observable.subscribe({
      error: err => {
        if (!(err instanceof InterruptScanningCycleError))
          console.error('Scanning is blocked', err);
      },
      complete: () => {
        // if not configured from debug, no sound will be played
        dispatch(playSoundEvent('scan/failure/none'));
        switch (noResultsHandler) {
          case 'popup':
            // not createConfirmation because it needs to NOT have a onEnter handler that closes the modal
            dispatch(
              openModalPage({
                component: modalPages.ScannerNoResults,
                isPopup: true,
                props: { code: prodCode },
              }),
            );
            break;
          case 'creation':
            if (hasRightsToAddCustomer || hasRightsToAddProduct)
              dispatch(
                openModalPage({
                  component: modalPages.ScannerResultsCreation,
                  isPopup: true,
                  props: {
                    code: prodCode,
                    canAddCustomer: hasRightsToAddCustomer,
                    canAddProduct: hasRightsToAddProduct,
                  },
                }),
              );
            break;
          default:
            // by default, dispatch the warning
            dispatch(
              addWarning(`No matching result found for query: ${prodCode}`, {
                dismissible: true,
                selfDismiss: 3000,
              }),
            );
            break;
        }
      },
    });

    await dispatch(after(query, observable));

    dispatch(dismissType('scannedCodeSearch'));
  };
}
