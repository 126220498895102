/**
 * @param {string} [birthday]
 */
function mapBirthday(birthday) {
  if (!birthday) return undefined;

  const [birthdayYear, birthdayMonth, birthdayDay] = birthday.split('-');
  return {
    birthdayYear,
    birthdayMonth,
    birthdayDay,
  };
}

export default ({
  customerID = undefined,
  companyName = undefined,
  companyName2 = undefined,
  companyTypeID = undefined,
  firstName = undefined,
  lastName = undefined,
  fullName = undefined,
  personTitleID = undefined,
  eInvoiceEmail = undefined,
  emailEnabled = undefined,
  mailEnabled = undefined,
  operatorIdentifier = undefined,
  gender = undefined,
  groupID = undefined,
  code = undefined,
  vatNumber = undefined,
  email = undefined,
  phone = undefined,
  mobile = undefined,
  fax = undefined,
  skype = undefined,
  imageContent = undefined,
  imageMimeType = undefined,
  website = undefined,
  bankName = undefined,
  bankAccountNumber = undefined,
  bankIBAN = undefined,
  bankSWIFT = undefined,
  birthday = undefined,
  integrationCode = undefined,
  jobTitleID = undefined,
  companyID = undefined,
  customerManagerID = undefined,
  invoiePayerID = undefined,
  paymentDays = undefined,
  penaltyPerDay = undefined,
  credit = undefined,
  salesBlocked = undefined,
  referenceNumber = undefined,
  customerCardNumber = undefined,
  GLN = undefined,
  EDI = undefined,
  priceListID = undefined,
  priceListID2 = undefined,
  priceListID3 = undefined,
  euCustomerType = undefined,
  outsideEU = undefined,
  businessAreaID = undefined,
  countryID = undefined,
  taxOfficeID = undefined,
  notes = undefined,
  username = undefined,
  password = undefined,
  flagStatus = undefined,
  colorStatus = undefined,
  taxExempt = undefined,
  paysViaFactoring = undefined,
  twitterID = undefined,
  facebookName = undefined,
  creditCardLastNumbers = undefined,
  deliveryTypeID = undefined,
  signUpStoreID = undefined,
  homeStoreID = undefined,
  rewardPointsDisabled = undefined,
  customerBalanceDisabled = undefined,
  posCouponsDisabled = undefined,
  emailOptOut = undefined,
  shipGoodsWithWaybills = undefined,
  householdCode = undefined,
  suspended = undefined,
  language = undefined,
}) => {
  const data = {
    ...mapBirthday(birthday),
    bankAccountNumber,
    bankIBAN,
    bankName,
    bankSWIFT,
    businessAreaID,
    companyName: companyName || companyName2,
    countryID,
    creditLimit: credit,
    customerBalanceDisabled: toBoolean(customerBalanceDisabled),
    customerCardNumber,
    customerManagerID,
    deliveryTypeID,
    email,
    emailOptOut,
    euCustomerType,
    firstName,
    gender: !gender ? undefined : toUpperCase(gender),
    groupID,
    homeStoreID,
    id: customerID,
    integrationCode,
    jobTitleID,
    language,
    lastName,
    mobilePhone: mobile,
    notes,
    paymentDays,
    paysViaFactoring: toBoolean(paysViaFactoring),
    penaltyPerDay,
    phone,
    posCouponsDisabled: toBoolean(posCouponsDisabled),
    referenceNumber,
    registryCode: code,
    rewardPointsDisabled: toBoolean(rewardPointsDisabled),
    salesForCashOnly:
      salesBlocked === undefined ? undefined : Number(salesBlocked),
    shipGoodsWithWaybills: toBoolean(shipGoodsWithWaybills),
    signUpStoreID,
    vatNumber,
    householdCode,
    suspended: suspended === undefined ? undefined : Number(suspended),
  };
  Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);

  return data;
};
const toUpperCase = value => {
  if (value !== undefined) return value.toUpperCase();
  return undefined;
};
const toBoolean = value => {
  if (value === true) return true;
  if (value === false) return false;
  return undefined;
};

/* Unmapped fields:
{
  birthdayDay,
  birdthdayMonth,
  birdthdayYear,
  emailConsentState,
  maritalStatus,
  integrationID
  householdCode
  payerRecordID
  peopleInHousehold,
  primaryStoreID,
  saveTransactions,
  secondaryStoreID,
} */
