import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const FilterBlock = ({ applyFilter, filter }) => {
  const filterOptions = [
    {
      key: 'contains',
      name: 'Contains',
    },
    {
      key: 'not-contains',
      name: 'Not Contains',
    },
    {
      key: 'starts',
      name: 'Starts with',
    },
    {
      key: 'ends',
      name: 'Ends with',
    },
    {
      key: 'equals',
      name: 'Equals',
    },
    {
      key: 'not-equals',
      name: 'Not equal',
    },
  ];

  const [value, setValue] = useState('');
  const [filterOption, setFilterOption] = useState(filterOptions[0].key);

  const selectFilterCondition = eventKey => {
    setFilterOption(eventKey);
  };
  return (
    <div>
      <InputGroup style={{ minWidth: '30ch' }} size="sm">
        <DropdownButton
          as={InputGroup.Prepend}
          variant="outline-secondary"
          title={filterOption}
          onSelect={selectFilterCondition}
        >
          {filterOptions.map(p => (
            <Dropdown.Item
              key={p.name}
              active={p.key === filterOption}
              eventKey={p.key}
            >
              {p.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        <FormControl
          value={value}
          onChange={e => {
            setValue(e.target.value);
            applyFilter({
              value: e.target.value.trim(),
              filterCondition: filterOption,
            });
          }}
        />
        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={() => filter()}>
            Search
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
};

FilterBlock.propTypes = {
  applyFilter: PropTypes.func,
  reduceResults: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  setItems: PropTypes.func,
};

export default FilterBlock;
