/* eslint-disable @typescript-eslint/camelcase */
import React, { createContext, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import useGridButtonSize, { GridButtonSize } from './useGridButtonSize';

const Context = createContext({
  classes: null as ClassNameMap | null,
});

const useStyles = makeStyles<Theme, GridButtonSize>(theme => ({
  gridButton: {
    borderRadius: theme.shape.borderRadius,
    height: props => props.height,
    width: props => props.width,
    fontSize: props => props.fontSize,
    fontWeight: 400,
    lineHeight: '1.23',
    color: '#232323',
    margin: `0 4px 8px 4px`,
    padding: '2px 4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    position: 'relative',
  },

  'gridButton-custom': {
    '&:hover': {
      filter: 'saturate(1.25) brightness(0.85) !important',
    },
    '&focus, &:active': {
      filter: 'saturate(1.25) !important',
    },
    '&:disabled': {
      color: theme.palette.action.disabled,
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },

  ...Object.keys(theme.palette)
    .filter(
      prop =>
        typeof theme.palette[prop] === 'object' &&
        'main' in theme.palette[prop],
    )
    .reduce((styles, colorName) => {
      const color = theme.palette[colorName];
      // eslint-disable-next-line no-param-reassign
      styles[`gridButton-${colorName}`] = {
        color: theme.palette.getContrastText(color.main),
        backgroundColor: color.main,
        '&:hover': {
          color: theme.palette.getContrastText(color.dark),
          backgroundColor: color.dark,
        },
        '&:focus, &:active': {
          color: theme.palette.getContrastText(color.light),
          backgroundColor: color.light,
        },
        '&:disabled': {
          color: theme.palette.action.disabled,
          backgroundColor: theme.palette.action.disabledBackground,
        },
      };
      return styles;
    }, {}),
}));

export function useGridButtonStyles() {
  return useContext(Context);
}

export function GridButtonSizeProvider({ children }) {
  const gridButtonProps = useGridButtonSize();
  const gridButtonClasses = useStyles(gridButtonProps);
  return (
    <Context.Provider value={{ classes: gridButtonClasses }}>
      {children}
    </Context.Provider>
  );
}
