import { PosPlugin } from 'plugins/plugin';
import { ErplyAttributes } from 'utils';
import { openPluginModalPage } from 'actions/modalPage';
import { withContainingProgress } from 'actions/actionUtils';

import { modals, ATTRIBUTES } from '../constants';
import { setSAINumber } from '../redux/actions';

const setSAINumberToSavedDocument: NonNullable<Required<
  PosPlugin
>['onPickupPendingSale']['on']> = withContainingProgress(
  sale => async (dispatch, getState, progress) => {
    dispatch(progress.halt);

    const attributes = new ErplyAttributes(sale.attributes);
    const SAINumber = attributes.get(ATTRIBUTES.SAI_NUMBER);

    const updatedSAINumber = await dispatch(
      openPluginModalPage(modals.SAINumberModal)({
        isPopup: true,
        props: {
          initialSAINumber: SAINumber,
        },
      }),
    );

    dispatch(progress.resume);
    dispatch(setSAINumber(updatedSAINumber));

    attributes.set(ATTRIBUTES.SAI_NUMBER, updatedSAINumber);
    return { ...sale, ...attributes.asFlatArray };
  },
);

export default setSAINumberToSavedDocument;
