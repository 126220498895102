/* eslint-disable no-param-reassign */
import React from 'react';
import i18next from 'i18next';

import {
  PrinterScript,
  formatReceipt,
  formatZReport,
} from 'Systems/PrinterScript/exports';
import { addWarning, dismissType, addError } from 'actions/Error';
import {
  getCurrentPrinterIntegration,
  getSetting,
  getUseReceiptTemplates,
} from 'reducers/configs/settings';
import {
  prepareZReportData,
  buildZReportPatchscript,
} from 'actions/integrations/printer/preparePrintoutData';
import { getTemplateForType } from 'reducers/patchScript';
import { INTEGRATIONS, INTEGRATION_TYPES } from 'constants/CAFA';
import {
  getCafaEntry,
  getGeneralPrintingSettings,
  getIsLoadingCafa,
} from 'reducers/cafaConfigs';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import {
  composeReceiptWithSchema,
} from 'reducers/patchScript/composeReceiptWithSchema';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import { getSelectedPosID } from 'reducers/PointsOfSale';

import {
  MSActions,
  WrapperActions,
  BrowserActions,
  renderElement,
  renderPatchscript,
  wrapPngData,
} from './actions';
import t from './testPrints.module.scss';
import { offlinePrint, offlineBrowserPrint } from './offlinePrint';
import { generateReceipt } from './generateReceipt';
import { Options, SalesDocument } from './types';
import { sleep } from 'utils';

/**
 * @param {SalesDocument & {payments: any}} salesDocument
 * @param {Options} options
 * Given a sales document, gathers up the necessary data for it and prints out
 * a sales receipt.
 * May make new network requests to fetch additional data about the document
 */


/** Workaround until PBIB-5483 */
function waitForCafaLoaded() {
  return async function(dispatch, getState) {
    let loading = getIsLoadingCafa(getState());
    while (loading) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(1);
      loading = getIsLoadingCafa(getState());
    }
  };
}

export function printReceipt({ payments, ...salesDocument }, options = {}) {
  return async (dispatch, getState) => {
  const { before, on, after } = getPluginLifecycleHook('onPrintReceipt')(
    getState(),
  );
  await dispatch(waitForCafaLoaded())
  const pointOfSaleID = getSelectedPosID(getState());
  const useReceiptTemplates = getUseReceiptTemplates(getState());
  const isOnline = getConnectionHealth(getState());

  const pluginCancel = new Error('Cancelled by plugin');
  const convertToPluginError = e => {
    console.error(e);
    throw pluginCancel;
  };
  try {
    await dispatch(before({ payments, salesDocument, options })).catch(
      convertToPluginError,
    );

    dispatch(
      addWarning(i18next.t('alerts:printing.loadingDocumentData'), {
        selfDismiss: false,
        dismissible: false,
        errorType: 'receipt_print',
      }),
    );

    const {
      patchScript,
      html,
      url,
      onlyBrowserPrint,
      hasActualReportsTemplate,
    } = await dispatch(generateReceipt({ salesDocument, options }));

    if (onlyBrowserPrint) {
      if (useReceiptTemplates)
        return dispatch(BrowserActions.print({ fullHtml: html })).finally(() =>
          dispatch(dismissType('receipt_print')),
        );
      return dispatch(BrowserActions.print({ url })).finally(() =>
        dispatch(dismissType('receipt_print')),
      );
    }

    await doClientRequest({
      request: 'registerReceiptPrint',
      invoiceID: salesDocument.id ?? salesDocument.invoiceID,
      pointOfSaleID,
    }).catch(err =>
      dispatch(
        addError(
          err instanceof Error
            ? err.message
            : 'Failed to register receipt print',
        ),
      ),
    );

    const type = INTEGRATION_TYPES.printer;

    const useWrapper =
      getCafaEntry('wrapper', type)(getState())?.value.enabled ?? false;
    const useMS =
      getCafaEntry('goMS', type)(getState())?.value.enabled ?? false;
    const useBrowser =
      getCafaEntry('browser', type)(getState())?.value.enabled ?? false;

    const funcs = [
      useWrapper &&
        WrapperActions.printReceipt({ patchScript, link: url }, options),
      useWrapper && WrapperActions.printPatchscript(patchScript),
      useMS &&
        MSActions.print(
          localStorage.getItem('MS.print.legacy')
            ? await dispatch(formatReceipt(patchScript, options))
            : JSON.stringify(patchScript),
        ),
      useBrowser &&
        (hasActualReportsTemplate
          ? BrowserActions.print({ fullHtml: html })
          : BrowserActions.printPatchScript(patchScript)),
    ].filter(a => a);

    await dispatch(
      on(
        { payments, salesDocument, options },
        { data: patchScript, patchScript },
      ),
    ).catch(convertToPluginError);

    Promise.all(funcs.map(dispatch)).catch(e =>
      console.warn('Failed to print with error', e),
    );

    dispatch(after({ payments, salesDocument, options })).catch(
      convertToPluginError,
    );
    dispatch(dismissType('receipt_print'));
  } catch (e) {
    dispatch(dismissType('receipt_print'));
    if (e === pluginCancel) return undefined
    if (!isOnline) {
      try {
        await dispatch(offlinePrint(salesDocument, options));
      } catch (error) {
        if (error === pluginCancel) return undefined
        await dispatch(offlineBrowserPrint(salesDocument, options));
      }
    } else {
      console.error('Printing failed with reason', e);
      dispatch(addError('Printing failed, see console for details'));
    }
  } finally {
    dispatch(dismissType('receipt_print'));
  }
};
}


export function printGiftReceipt(salesDocument) {
  return printReceipt(salesDocument, { giftReceipt: true });
}
export function printKitchenReceipt(salesDocument) {
  return printReceipt(salesDocument, { kitchen: true });
}
export function printBarReceipt(salesDocument) {
  return printReceipt(salesDocument, { bar: true });
}
/**
 * Given some params for getZReport, will print out the respective Z-Report
 */
export function printZReport(html, onlyBrowserPrint = false) {
  return async (
    dispatch,
    getState,
  ) => {
    const data = await dispatch(
      prepareZReportData({
        getShortReport: getSetting('touchpos_get_short_report')(getState()),
      }),
    );
    await dispatch(waitForCafaLoaded())
    const {
      zReport: { useBoReceipt },
      forcePatchscriptRender: { enabled, pixelWidth, scaleMultiplier },
    } = getGeneralPrintingSettings(getState());

    const type = INTEGRATION_TYPES.printer;
    const useWrapper =
      getCafaEntry('wrapper', type)(getState())?.value.enabled ?? false;
    const useMS = getCafaEntry('goMS', type)(getState())?.value.enabled ?? false;
    const useBrowser =
      getCafaEntry('browser', type)(getState())?.value.enabled ?? false;

    if (useBoReceipt) {
      if (onlyBrowserPrint) return dispatch(BrowserActions.print({ html }));

      const blob =
        useWrapper || useMS
          ? await renderElement(
              <div
                style={{ color: 'black', background: 'white' }}
                dangerouslySetInnerHTML={{ __html: html }}
              />,
              pixelWidth,
              scaleMultiplier,
            )
          : undefined;
      [
        useWrapper && WrapperActions.printPatchscript([{ type: 'image', blob }]),
        useMS && MSActions.print(JSON.stringify([{ type: 'image', blob }])),
        useBrowser && BrowserActions.print({ html }),
      ]
        .filter(a => a)
        .forEach(dispatch);
      return undefined;
    }

    dispatch(
      addWarning(i18next.t('alerts:printing.printingZReport'), {
        dismissable: false,
        selfDismiss: false,
        errorType: 'zReport',
      }),
    );

    const patchScript = await dispatch(buildZReportPatchscript()).then(
      enabled
        ? data =>
            renderPatchscript(data, pixelWidth, scaleMultiplier).then(wrapPngData)
        : data => Promise.resolve(data),
    );

    const funcs = (onlyBrowserPrint
      ? [BrowserActions.printPatchScript(patchScript)]
      : [
          useWrapper && WrapperActions.printZReport({ html, data }),
          useWrapper && WrapperActions.printPatchscript(patchScript),
          useMS &&
            MSActions.print(
              localStorage.getItem('MS.print.legacy')
                ? await dispatch(formatZReport(data))
                : JSON.stringify(patchScript),
            ),
          useBrowser && BrowserActions.printPatchScript(patchScript),
        ]
    ).filter(a => a);

    if (!funcs.length) {
      dispatch(dismissType('zReport'));
      dispatch(
        addWarning(i18next.t('alerts:printing.noPrintingOption'), {
          selfDismiss: true,
        }),
      );
    }

    Promise.all(funcs.map(dispatch))
      .finally(() => dispatch(dismissType('zReport')))
      .catch(e => dispatch(addError(e.message), { selfDismiss: true }));
  };
}

export function openCashDrawer() {
  return async (dispatch, getState) => {
    const type = INTEGRATION_TYPES.printer;
    await dispatch(waitForCafaLoaded())
    const useWrapper =
      getCafaEntry('wrapper', type)(getState())?.value.enabled ?? false;
    const useMS = getCafaEntry('goMS', type)(getState())?.value.enabled ?? false;

    const funcs = [
      useWrapper && WrapperActions.openCashDrawer(),
      useMS && MSActions.openCashDrawer(),
    ].filter(a => a);

    try {
      if (funcs.length === 0) {
        throw new Error(i18next.t('alerts:printing.canNotOpenDrawer'));
      }
      await dispatch(
        addWarning(i18next.t('alerts:printing.openingCashDrawer'), {
          dismissable: false,
          selfDismiss: false,
          errorType: 'cashDrawer',
        }),
      );
      await Promise.all(funcs.map(dispatch));
    } catch (error) {
      const errorMessage =
        error?.response?.data?.Error ??
        error.message ??
        'Could not open cash drawer';

      dispatch(
        addError(errorMessage, {
          selfDismiss: 5000,
        }),
      );
    } finally {
      dispatch(dismissType('cashDrawer'));
    }
  };
}

export function printCoupon(data) {
  return async (dispatch, getState) => {
    await dispatch(waitForCafaLoaded())
    const patchScriptTemplate = getTemplateForType(
      INTEGRATIONS[INTEGRATION_TYPES.patchScriptTemplate].coupon,
    )(getState());
    const type = INTEGRATION_TYPES.printer;
    const useWrapper =
      getCafaEntry('wrapper', type)(getState())?.value.enabled ?? false;
    const useMS = getCafaEntry('goMS', type)(getState())?.value.enabled ?? false;
    const useBrowser =
      getCafaEntry('browser', type)(getState())?.value.enabled ?? false;

    const {
      forcePatchscriptRender: { enabled, pixelWidth, scaleMultiplier },
    } = getGeneralPrintingSettings(getState());
    const patchScriptData = await (enabled
      ? data =>
          renderPatchscript(data, pixelWidth, scaleMultiplier).then(wrapPngData)
      : data => Promise.resolve(data))(
      composeReceiptWithSchema(patchScriptTemplate, data),
    );
    // prettier-ignore
    const funcs = [
          useWrapper && console.warn('wrapper receiptData/html for coupons not implemented'),
          useWrapper && WrapperActions.printPatchscript(patchScriptData),
          useMS && MSActions.print(
              (localStorage.getItem('MS.print.legacy'))
                  ? new PrinterScript(getCurrentPrinterIntegration(getState()))
                      .print('TODO: Implement coupon format for microservice')
                      .ln()
                  : JSON.stringify(patchScriptData),
          ),
          useBrowser && BrowserActions.printPatchScript(patchScriptData),
      ].filter(a => a);

    return dispatch(funcs)
      .finally(() => dispatch(dismissType('cashDrawer')))
      .catch(err => dispatch(addError(err.message), { selfDismiss: true }));
  };
}

export function printGiftCard(data) {
  return async (dispatch, getState) => {
    await dispatch(waitForCafaLoaded())
    const patchScriptTemplate = getTemplateForType(
      INTEGRATIONS[INTEGRATION_TYPES.patchScriptTemplate].giftCard,
    )(getState());
    const type = INTEGRATION_TYPES.printer;
    const useWrapper =
      getCafaEntry('wrapper', type)(getState())?.value.enabled ?? false;
    const useMS = getCafaEntry('goMS', type)(getState())?.value.enabled ?? false;
    const useBrowser =
      getCafaEntry('browser', type)(getState())?.value.enabled ?? false;

    const {
      forcePatchscriptRender: { enabled, pixelWidth, scaleMultiplier },
    } = getGeneralPrintingSettings(getState());
    const patchScriptData = await (enabled
      ? data =>
          renderPatchscript(data, pixelWidth, scaleMultiplier).then(wrapPngData)
      : data => Promise.resolve(data))(
      composeReceiptWithSchema(patchScriptTemplate, data),
    );
    // prettier-ignore
    const funcs = [
          useWrapper && console.warn('wrapper receiptData/html for gift card not implemented'),
          useWrapper && WrapperActions.printPatchscript(patchScriptData),
          useMS && MSActions.print(
              (localStorage.getItem('MS.print.legacy'))
                  ? new PrinterScript(getCurrentPrinterIntegration(getState()))
                      .print('TODO: Implement gift card format for microservice')
                      .ln()
                  : JSON.stringify(patchScriptData),
          ),
          useBrowser && BrowserActions.printPatchScript(patchScriptData),
      ].filter(a => a);

    return dispatch(funcs)
      .finally(() => dispatch(dismissType('cashDrawer')))
      .catch(err => dispatch(addError(err.message), { selfDismiss: true }));
  };
}

export const testPrinter = MSActions.testAll;

// DEPRECATED tryPrintRawData
// Internal backwards compatibility
export const tryPrintRawData = MSActions.print;
/**
 * @param {RItem[]} patchScript
 * @param {boolean=} preview
 */
export function printPatchscript(patchScript, preview = false) {
  return async (
    dispatch,
    getState,
  ) => {
    await dispatch(waitForCafaLoaded())
    const type = INTEGRATION_TYPES.printer;
    const useWrapper =
      getCafaEntry('wrapper', type)(getState())?.value.enabled ?? false;
    const useMS = getCafaEntry('goMS', type)(getState())?.value.enabled ?? false;
    const useBrowser =
      getCafaEntry('browser', type)(getState())?.value.enabled ?? false;

    const {
      forcePatchscriptRender: { enabled, pixelWidth, scaleMultiplier },
    } = getGeneralPrintingSettings(getState());
    const renderedPatchScript = enabled
      ? await renderPatchscript(patchScript, pixelWidth, scaleMultiplier).then(
          wrapPngData,
        )
      : patchScript;

    if (preview) {
      return renderedPatchScript;
    }

    const funcs = [
      useWrapper && WrapperActions.printPatchscript(renderedPatchScript),
      useMS &&
        !localStorage.getItem('MS.print.legacy') &&
        MSActions.print(JSON.stringify(renderedPatchScript)),
      useBrowser && BrowserActions.printPatchScript(renderedPatchScript),
    ].filter(a => a);
    return dispatch(funcs).catch(err => {
      dispatch(
        addError('Error printing straight patchscript, see console for details'),
        { selfDismiss: true },
      );
      console.error('Error printing straight patchscript', err);
    });
  };
}

function Ruler() {
  return (
    <div style={{ backgroundColor: 'white', color: 'black' }}>
      Use this ruler to measure the line on the other receipt
      <br />
      <div>Big lines: 100px</div>
      <div>Medium lines: 25px</div>
      <div>Small lines: 5px</div>
      <div className={t.ruler}>
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  );
}
export function printRulerAndStick() {
  return async (dispatch, getState) => {
    return Promise.all(
      [
        renderElement(<div className={t.top} />, 10000, 1),
        renderElement(<Ruler />, 100, 1),
      ].map(p =>
        p
          .then(blob => [{ type: 'image', blob }])
          .then(ps =>
            Promise.all([
              dispatch(MSActions.print(JSON.stringify(ps))),
              dispatch(WrapperActions.printPatchscript(ps)),
              dispatch(BrowserActions.printPatchScript(ps)),
            ]),
          )
          .catch(e => {
            console.error('Failed to print ruler due to error:', e);
          }),
      ),
    );
  };
}
export function printTextScales(width, updateProgress = (done, total) => {}) {
  return async (dispatch, getState) => {
    const scales = [25, 50, 75, 100, 125, 150, 200, 300, 400];
    const patchScript = [];
    for (let i = 0; i < scales.length; i++) {
      updateProgress(i, scales.length);
      const scale = scales[i];
      // eslint-disable-next-line no-await-in-loop
      const blob = await renderElement(
        <span style={{ background: 'white', color: 'black' }}>
          Text scale {scale}%
        </span>,
        width,
        scale / 100,
      );
      patchScript.push({ type: 'image', blob });
    }

    return Promise.all([
      dispatch(MSActions.print(JSON.stringify(patchScript))),
      dispatch(WrapperActions.printPatchscript(patchScript)),
      dispatch(BrowserActions.printPatchScript(patchScript)),
    ]).catch(e => {
      console.error('Failed to print textscales due to error:', e);
    });
  };
}
