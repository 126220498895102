import React, { SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import UIButton from 'components/UIElements/UIButton';
import {
  getEnabledCurrencies,
  getIsModuleEnabled,
} from 'reducers/configs/settings';

type Props = {
  title?: string;
  selected: string;
  setSelected: React.Dispatch<SetStateAction<string>>;
};

const ActiveCurrencySelect: React.FC<Props> = ({
  title,
  selected,
  setSelected,
}) => {
  const currencies = useSelector(getEnabledCurrencies);
  const isMultiCurrencyEnabled = useSelector(
    getIsModuleEnabled('pos_multicurrency'),
  );
  return (
    <span style={{ fontWeight: 700, fontSize: '1.75em' }}>
      {title && <span data-testid="close-day-header">{title}</span>}
      {isMultiCurrencyEnabled &&
        currencies.map(currency => (
          <UIButton
            key={currency}
            variant={currency === selected ? 'POS' : 'outline-POS'}
            data-testid="currency-btn"
            data-test-key={currency}
            text={currency}
            action={() => setSelected(currency)}
          />
        ))}
    </span>
  );
};
export default ActiveCurrencySelect;
