import { Middleware } from 'redux';

import { RootDispatch, RootState } from 'reducers';
import {
  RESET_CUSTOMER,
  SET_CUSTOMER_SUCCESS,
  SET_ONE_CUSTOMER_SUCCESS,
} from 'constants/customerSearch';
import {
  getDefaultCustomer,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getStoreRegionCustomerGroupPriceListIds } from 'reducers/storeRegions';
import { Product } from 'types/Product';
import { getCachedItemsPerType } from 'reducers/cachedItems';
import { SO } from 'services/DB/types';
import { getProductsUniversal } from 'actions/productsDB';
import { calculate } from 'actions/ShoppingCart/calculate';

const listeningTo = [
  SET_CUSTOMER_SUCCESS,
  SET_ONE_CUSTOMER_SUCCESS,
  RESET_CUSTOMER,
];

export const reapplyPriceListsToCachedProducts: Middleware<
  unknown,
  RootState,
  RootDispatch
> = ({ dispatch, getState }) => next => async action => {
  if (!listeningTo.includes(action.type)) return next(action);

  const previousCustomer = getSelectedCustomer(getState());

  const result = next(action);

  const customerGroupPriceLists = getStoreRegionCustomerGroupPriceListIds(
    getState(),
  );
  if (!Object.keys(customerGroupPriceLists)) return result;

  const newCustomer =
    action.type === RESET_CUSTOMER
      ? getDefaultCustomer(getState())
      : action.data;

  if (previousCustomer.id === newCustomer.id) return result;

  const cachedProductDict: Record<string, Product> = getCachedItemsPerType(
    SO.PRODUCTS.NAME,
  )(getState());
  const cachedProducts = Object.values(cachedProductDict);
  if (!cachedProducts.length) return result;

  const cachedProductIds = cachedProducts.map(product =>
    Number(product.productID),
  );

  if (!cachedProductIds.length) return result;

  dispatch(
    getProductsUniversal(
      { productIDs: cachedProductIds },
      { addToCachedItems: true, reapplyPriceLists: true },
    ),
  ).then(() => dispatch(calculate()));

  return result;
};
