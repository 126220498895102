import { Modal } from 'react-bootstrap';
import {
  Box,
  Button,
  LinearProgress,
  Table,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Delete, Send } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import CloseButton from 'components/CustomButtons/CloseButton';
import { addError } from 'actions/Error';
import { getCurrencyFormatter } from 'reducers/configs/settings';
import { getPayments } from 'reducers/Payments';

import { GivexCard } from '../types';
import * as API from '../API/givexAPI';
import {
  getGivexConfiguration,
  getValidateGivexCard,
} from '../configuration/Configuration';
import { checkCardCanBeRefilled } from '../utils';
import {
  addCardToRefill,
  getCardNeedsToBeRefilled,
  removeCardToRefill,
} from '../rdx';

import { GivexCardInput } from './GivexCardInput';

type PromiseResultOf<ApiMethod extends (...any) => Promise<any>> = ReturnType<
  ApiMethod
> extends Promise<infer Type>
  ? Type
  : never;

/**
 * Modal popup that handles processing of givex card payments and refills
 *
 * The desired payments and refills should be provided as children using
 * {@link GivexProcessorChargePayment} and {@link GivexProcessorRefillPayment} components
 */
export const GivexProcessor = ({ close, children }) => {
  const { displayName } = useSelector(getGivexConfiguration);
  return (
    <div data-testid="givex-payment-modal">
      <Modal.Header>
        <Modal.Title>{displayName} payment integration</Modal.Title>
        <div className="d-flex align-content-center">
          <CloseButton action={close} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <Typography variant="subtitle1">
            Please add the card number(s)
          </Typography>
        </Box>

        <Table>
          <tbody>{children}</tbody>
        </Table>
      </Modal.Body>
    </div>
  );
};

/**
 * Component that handles processing and reverting of a single charge payment. Should be placed inside {@link GivexProcessor}
 *
 * Only handles the givex processing, reflecting those changes outside should be done by the caller
 *
 * @param paid Whether to render the payment as being processed
 * @param amount The amount of payment to process
 * @param onPaid Callback when the user has clicked to process the payment and the payment succeeds.
 * @param onCancelled Callback when the user has clicked to revert the payment and that succeeds.
 */
export const GivexProcessorChargePayment = ({
  paid,
  amount,
  onPaid,
  onCancelled,
  initialCard,
}: {
  paid: GivexCard | false;
  amount: number;
  onPaid: (
    card: GivexCard,
    balanceResponse: PromiseResultOf<typeof API.getBalance>,
    chargeResponse: PromiseResultOf<typeof API.chargeGiftcard>,
  ) => Promise<void>;
  onCancelled: (
    cancelResponse: PromiseResultOf<typeof API.cancelGiftcardCharge>,
  ) => void;
  initialCard: GivexCard;
}) => {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [card, setCard] = useState<GivexCard>(initialCard);
  const formatCurrency = useSelector(getCurrencyFormatter);
  const givexValidationErrorMessage = useSelector(getValidateGivexCard)(
    card,
    'payment-process-charge',
  );

  const withProcessing = action => () => {
    if (givexValidationErrorMessage) {
      dispatch(addError(givexValidationErrorMessage));
      return;
    }
    setProcessing(true);
    if (processing) return;
    action()
      .catch(e => dispatch(addError(e.message)))
      .finally(() => setProcessing(false));
  };

  return (
    <tr data-testid="payment">
      <td data-testid="refill" />
      <td data-testid="status" style={{ display: 'flex', flex: 'column' }}>
        <GivexCardInput
          value={paid || card}
          onChange={setCard}
          disabled
          type="payment-process-charge"
        />
      </td>
      <td data-testid="charge">
        <Button
          disabled={processing}
          onClick={withProcessing(() =>
            paid
              ? API.cancelGiftcardCharge(paid, amount).then(onCancelled)
              : API.getBalance(card).then(balanceResponse => {
                  const amt = Math.min(
                    Number(balanceResponse.certificateBalance),
                    amount,
                  );
                  if (amt >= amount) {
                    return API.chargeGiftcard(card, amt).then(chargeResponse =>
                      onPaid(card, balanceResponse, chargeResponse),
                    );
                  }
                  dispatch(addError('Not enough balance'));
                }),
          )}
          variant={paid ? undefined : 'contained'}
          color={paid ? undefined : 'primary'}
          startIcon={paid ? <Delete /> : undefined}
          endIcon={paid ? undefined : <Send />}
          style={{ position: 'relative' }}
        >
          {processing && (
            <LinearProgress
              variant="indeterminate"
              style={{ position: 'absolute', inset: 0 }}
            />
          )}
          {paid ? 'Revert ' : ''} Charge {formatCurrency(amount)}
        </Button>
      </td>
    </tr>
  );
};

/**
 * Component that handles processing and reverting of a single refill payment. Should be placed inside {@link GivexProcessor}
 *
 * Only handles the givex processing, reflecting those changes outside should be done by the caller
 *
 * @param paid Whether to render the payment as being processed
 * @param amount The amount of payment to process (should be negative)
 * @param onPaid Callback when the user has clicked to process the refill and it succeeds.
 * @param onCancelled Callback when the user has clicked to revert the refill and that succeeds.
 */
export const GivexProcessorRefillPayment = ({
  paid,
  amount,
  onPaid,
  onCancelled,
  initialCard,
  paymentKey,
}: {
  paid: GivexCard | false;
  amount: number;
  onPaid: (
    card: GivexCard,
    refillResponse?: PromiseResultOf<typeof API.refillGiftcard>,
  ) => void;
  onCancelled: (
    response?: PromiseResultOf<typeof API.reverseGiftcardRefill>,
  ) => void;
  initialCard?: GivexCard;
  paymentKey: string;
}) => {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [card, setCard] = useState<GivexCard>(
    initialCard ?? { cardNo: '', pin: '' },
  );
  const formatCurrency = useSelector(getCurrencyFormatter);
  const givexValidationErrorMessage = useSelector(getValidateGivexCard)(
    card,
    'payment-process-refill',
  );
  const withProcessing = action => () => {
    if (givexValidationErrorMessage) {
      dispatch(addError(givexValidationErrorMessage));
      return;
    }
    setProcessing(true);
    if (processing) return;
    action()
      .catch(e => dispatch(addError(e.message)))
      .finally(() => setProcessing(false));
  };

  const payment = useSelector(getPayments)[paymentKey];
  const cardNeedsToBeRefilled = useSelector(
    getCardNeedsToBeRefilled(paymentKey),
  );

  function handleRefund() {
    return paid
      ? API.reverseGiftcardRefill(paid, amount).then(onCancelled)
      : API.refillGiftcard(card, amount).then(refillResponse =>
          onPaid(card, refillResponse),
        );
  }

  async function handleActivationOrIncrement() {
    if (paid) {
      dispatch(removeCardToRefill(paymentKey));
      onCancelled();
      return;
    }
    const cardCanBeRefilled = await checkCardCanBeRefilled(card);
    if (!cardCanBeRefilled) {
      dispatch(addError('Card cannot be refilled'));
      return;
    }
    dispatch(addCardToRefill(paymentKey));
    await onPaid(card);
  }

  return (
    <tr data-testid="payment">
      <td data-testid="refill">
        <Button
          disabled={processing}
          onClick={withProcessing(
            payment.type === 'META' && !(paid && !cardNeedsToBeRefilled)
              ? handleActivationOrIncrement
              : handleRefund,
          )}
          variant={paid ? undefined : 'contained'}
          color={paid ? undefined : 'primary'}
          startIcon={paid ? <Delete /> : undefined}
          endIcon={paid ? undefined : <Send />}
          style={{ position: 'relative' }}
        >
          {processing && (
            <LinearProgress
              variant="indeterminate"
              style={{ position: 'absolute', inset: 0 }}
            />
          )}
          {paid ? 'Revert ' : ''} Refill by {formatCurrency(amount)}
        </Button>
      </td>
      <td data-testid="status" style={{ display: 'flex', flex: 'column' }}>
        <GivexCardInput
          value={paid || card}
          onChange={setCard}
          disabled={processing || !!paid || !!initialCard}
          type="payment-process-refill"
        />
      </td>
      <td data-testid="charge" />
    </tr>
  );
};
