import React, { useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, TextField, Typography } from '@material-ui/core';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import Alert from 'components/Alert';

import { setQffCardNumber } from '../rdx/actions';
import { getQffCardNumber } from '../rdx/reducers';

import logo from './qff_logo.png';

function getErrorText(cardNumber: string) {
  if (!cardNumber.startsWith('208181')) return 'Must start with "208181"';
  if (cardNumber.length !== 16) return 'Must be 16 characters long';
  return '';
}

function QffCardModal({ onClose }) {
  const dispatch = useDispatch();
  const currentCardNumber = useSelector(getQffCardNumber);

  const [cardNumber, setCardNumber] = useState(currentCardNumber ?? '');

  const errorText = getErrorText(cardNumber);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (/\D/.test(event.target.value)) return;
    setCardNumber(event.target.value);
  }

  function handleSave() {
    if (errorText) return;
    dispatch(setQffCardNumber(cardNumber));
    onClose();
  }

  useShortcut('Enter', handleSave, 30);
  useShortcut('Escape', onClose, 30);

  return (
    <div data-testid="CardNumberInputModal">
      <Box display="flex" alignItems="center" padding="1rem">
        <img
          src={logo}
          alt="Qantas Frequent Flyer"
          style={{
            display: 'block',
            maxHeight: '50px',
            width: 'auto',
            height: 'auto',
            margin: '-1rem 0',
          }}
        />
        <Typography variant="h5">Qantas Frequent Flyer</Typography>
      </Box>
      <Divider />
      <Box padding="1rem">
        <div>
          <TextField
            fullWidth
            margin="normal"
            autoFocus
            variant="outlined"
            label="Scan or enter the customer's Qantas Frequent Flyer card number"
            type="text"
            value={cardNumber}
            onChange={handleChange}
            error={!!errorText}
            helperText={errorText}
            inputProps={{
              inputmode: 'numeric',
            }}
          />
        </div>
        <Alert
          severity="error"
          variant="filled"
          icon={false}
          style={{ margin: '1rem 0', fontWeight: 700 }}
        >
          NOTE: Incomplete Layby and Orders, and Click and Collect will not earn
          points
        </Alert>
        <Box
          marginTop="1rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            color="secondary"
            variant="contained"
            disableElevation
            style={{ flexGrow: 1, marginRight: '1rem' }}
            disabled={!!errorText}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            style={{ flexGrow: 1 }}
            onClick={onClose}
          >
            Skip
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default QffCardModal;
