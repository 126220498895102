import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';

import { getExchangeState, SET_EXCHANGE_PROCESS_STEP } from '../rdx';

const UIProductReturnCloseButton: PosPlugin['UIProductReturnCloseButton'] = ({
  children,
}) => {
  const dispatch = useDispatch();
  const exchangeState = useSelector(getExchangeState);

  const resetExchangeSteps = () => {
    return dispatch({ type: SET_EXCHANGE_PROCESS_STEP, payload: 0 });
  };

  const [ch] = React.Children.toArray(children);

  if (exchangeState.exchangeStep <= 0) return <>{children}</>;

  return React.cloneElement(ch as React.ReactElement<any>, {
    action: () => {
      // @ts-ignore
      ch?.props?.action && ch.props.action();
      dispatch(resetExchangeSteps());
    },
  });
};

export default UIProductReturnCloseButton;
