import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Alert } from '@material-ui/lab';
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import * as R from 'ramda';

import { addError } from 'actions/Error';
import { saveSetting } from 'actions/configs';
import { getDeviceID } from 'actions/integrations/metricsCollector/actions';
import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { useConfirmation } from 'components/Confirmation';
import { RegisterInput } from 'components/inputs/RegisterInput';
import { RootState } from 'reducers';
import { getPointsOfSale } from 'reducers/PointsOfSale';
import { getPosAssociationConfig } from 'reducers/configs/settings';

interface Props {
  onClose(): void;
}

function AssociatePos({ onClose }: Props) {
  const { t } = useTranslation(['settingsAssociatePos', 'common']);
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const confirm = useConfirmation();

  const config = useSelector(getPosAssociationConfig);
  const pointsOfSale = useSelector(getPointsOfSale);
  const [updatedConfig, setUpdatedConfig] = useState(config);

  const { value: deviceID, loading } = useAsync(() => dispatch(getDeviceID()), [
    dispatch,
  ]);

  const shouldDisableSave =
    loading || !deviceID || R.equals(updatedConfig, config);

  async function save() {
    if (shouldDisableSave) return;

    try {
      await dispatch(
        saveSetting({
          parameterName: 'pos_associations',
          parameterValue: updatedConfig,
        }),
      );
      onClose();
    } catch {
      dispatch(addError(t('alerts.failedToSave')));
    }
  }

  const updatePosAssociation = useCallback(
    (posID: number | null) => {
      if (!deviceID) return;
      if (!posID) {
        if (!updatedConfig.associations[deviceID]) return;
        setUpdatedConfig(R.dissocPath(['associations', deviceID]));
        return;
      }

      const selectedPosAlreadyAssociated = Object.values(
        updatedConfig.associations,
      ).includes(posID);

      if (selectedPosAlreadyAssociated) {
        confirm({
          title: t('confirmations.duplicateAssociation.title'),
          body: t('confirmations.duplicateAssociation.body'),
          confirmText: t('yes', { ns: 'common' }),
          noReject: true,
        });
      }
      setUpdatedConfig(R.assocPath(['associations', deviceID], posID));
    },
    [confirm, deviceID, t, updatedConfig],
  );

  const currentConfigurationStatus = useMemo(() => {
    if (!deviceID) {
      if (!Object.keys(config.associations).length) {
        return t('notAssociated');
      }
      return t('cannotCheckAssociation');
    }
    const associatedPos = deviceID
      ? pointsOfSale.find(
          pos => pos.pointOfSaleID === config.associations[deviceID],
        )
      : null;
    if (associatedPos) {
      return t('associatedWith', { pointOfSale: associatedPos.name });
    }
    return t('notAssociated');
  }, [config.associations, deviceID, pointsOfSale, t]);

  return (
    <div data-testid="associate-pos-new">
      <Box display="flex" alignItems="center" padding="1rem">
        <Typography variant="h5">{t('title')}</Typography>
        <Box flexGrow={1} />
        <SaveButton action={save} disabled={shouldDisableSave} />
        <CloseButton action={onClose} />
      </Box>
      <Divider />
      <Box padding="1rem">
        <FormGroup>
          <FormControlLabel
            label={t('fields.enabled')}
            control={
              <Switch
                color="secondary"
                checked={updatedConfig.enabled}
                onChange={e =>
                  setUpdatedConfig(R.assoc('enabled', e.target.checked))
                }
              />
            }
          />
          <FormControlLabel
            label={t('fields.allowOnlyAssociatedDevices')}
            control={
              <Switch
                color="secondary"
                checked={updatedConfig.allowOnlyAssociatedDevices}
                onChange={e =>
                  setUpdatedConfig(
                    R.assoc('allowOnlyAssociatedDevices', e.target.checked),
                  )
                }
              />
            }
          />
        </FormGroup>
        {!deviceID && !loading ? (
          <Box marginBottom="2rem">
            <Alert severity="error">{t('deviceID.missing')}</Alert>
          </Box>
        ) : null}
        <Box
          marginBottom="2rem"
          display="flex"
          alignItems="center"
          gridGap="1rem"
        >
          {loading ? (
            <>
              <CircularProgress size="30px" />
              <Typography>{t('deviceID.loading')}</Typography>
            </>
          ) : (
            <Typography>{currentConfigurationStatus}</Typography>
          )}
        </Box>
        <RegisterInput
          disabled={!updatedConfig.enabled || loading || !deviceID}
          value={deviceID ? updatedConfig.associations[deviceID] : null}
          onChange={(_e, newValue) => updatePosAssociation(newValue)}
          renderInput={props => (
            <TextField
              variant="outlined"
              label={t('fields.registerInput')}
              {...props}
            />
          )}
        />
      </Box>
    </div>
  );
}

export default AssociatePos;
