import { RootState } from 'reducers';

import * as c from '../../constants/UI';

function enabled(state = false, { type }) {
  switch (type) {
    case c.ENABLE_PRODUCT_EDIT_MODE:
      return true;
    case c.DISABLE_PRODUCT_EDIT_MODE:
      return false;
    default:
      return state;
  }
}

export default enabled;

export function getIsEditMode(state: RootState) {
  return state.UI.productEditMode;
}
