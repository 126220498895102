import { AxiosResponse } from 'axios';

import { JunctionAPIResponse, JunctionPaymentObjectType } from '../types';

import { MSError, TerminalError } from './errors';

export const validateSuccess = (
  response: AxiosResponse<JunctionAPIResponse>,
) => {
  const [paymentObj]: JunctionPaymentObjectType[] =
    response?.data?.records ?? [];

  if (!paymentObj) throw new Error('No data found in response');

  // Validate resultcode from MS
  switch (Number(paymentObj.resultCode ?? NaN)) {
    case 0:
      break; // Success

    case 101: // UnknownError
    case 102: // JSONParsingError
    case 105: // FunctionNotImplemented
    case 107: // FileIOError
    case 112: // CSVParseError
    case 115: // XMLParseError
    case 117: // TransactionError
    case 118: // TimeoutError
    case 120: // DeviceBufferIOError
      throw new MSError(paymentObj.statusMessage);

    case 104: // ServiceBusyError
    case 106: // UnsupportedEndpointError
    case 111: // TypeMismatchError
    case 113: // DeviceConnectionError
    case 114: // InvalidTransactionParamsError
    case 119: // TransactionInProgressError
    case 108: // ConfigurationError
      // TODO: Recovery might be unnecessary for these codes
      // return new PaymentFailed(new Error(paymentObj.statusMessage))
      throw new MSError(paymentObj.statusMessage);

    default:
      throw new MSError(paymentObj.statusMessage);
  }

  // Validate hostCode from terminal
  // 00 - Approved on void?, 0 - Approved, 10 - Partial approval
  if (![0, 10].includes(Number(paymentObj?.hostCode ?? NaN)))
    throw new TerminalError(
      paymentObj.hostResponse ??
        paymentObj.statusMessage ??
        `Invalid hostCode ${paymentObj?.hostCode}`,
    );

  // Passthrough unchanged if valid
  return response;
};
