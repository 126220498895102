import i18next from 'i18next';

export const getValidationMessage = (
  isValid: boolean,
  isChecking: boolean,
  alreadyExists: boolean,
) => {
  if (!isValid)
    return i18next.t('createCustomer:errorText.loyaltyCardNumber.invalid');
  if (isChecking)
    return i18next.t('createCustomer:errorText.loyaltyCardNumber.checking');
  if (alreadyExists)
    return i18next.t(
      'createCustomer:errorText.loyaltyCardNumber.alreadyExists',
    );
  return null;
};

const luhnCheck = (number: string) => {
  const digits = number.split('').map(Number);
  const parity = (digits.length - 1) % 2;
  let sum = 0;
  for (let i = digits.length - 1; i >= 0; --i) {
    if (i % 2 === parity) {
      sum += digits[i];
    } else {
      sum += digits[i] === 9 ? 9 : (digits[i] * 2) % 9;
    }
  }
  return sum % 10 === 0;
};

/**
 * Validates customer loyalty card number. Validation rules:
 * - integer
 * - 7-9 digits long
 * - if its length is 9 then it should be in range of 200000000-399999999
 * - if its length is 7 or 8 then number is validated using
 * {@link https://en.wikipedia.org/wiki/Luhn_algorithm Luhn algorithm }
 */
export const validate = (cardNumber: string) => {
  if (/\D/.test(cardNumber)) return false;

  switch (cardNumber.length) {
    case 7:
    case 8:
      return luhnCheck(cardNumber);
    case 9:
      return 2e8 <= Number(cardNumber) && Number(cardNumber) < 4e8;
    default:
      return false;
  }
};

/**
 * Generates a SHA256 hash of the following string: “salt|ClientAppID|salt”
 * where salt is a constant string (different for prod/dev)
 */
export const generateToken = async (
  storeAccountNumber: string,
  isProduction: boolean,
): Promise<string> => {
  const salt = isProduction ? 'f9s%S$2j4!734&^34' : '*@&SK(D0@!(#111!(';
  const data = `${salt}|${storeAccountNumber}|${salt}`;
  const dataAsBuffer = new TextEncoder().encode(data);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', dataAsBuffer);
  return new Uint8Array(hashBuffer)
    .reduce((a, b) => a + b.toString(16).padStart(2, '0'), '')
    .toUpperCase();
};
