import { useAsync } from 'react-use';
import { API } from 'plugins/nohble/SpecialOrders/API/API';

export const useDocuments = (documentIDs?: number[]) => {
  return useAsync(() => {
    if (!documentIDs) return new Promise<never>(() => undefined);
    if (!documentIDs?.length)
      return Promise.resolve([]) as ReturnType<
        typeof API.getDocumentNumbersAndRows
      >;
    return API.getDocumentNumbersAndRows(documentIDs);
  }, [documentIDs]);
};
