import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

import { getProductByID } from 'reducers/cachedItems/products';
import { notUndefinedOrNull } from 'utils/tsHelpers';
import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';

import { formatPrice } from '../utils';
import constants from '../constants';

const formatNumber = value => {
  if (!value) return '';
  if (Number(value)) {
    return Number(value).toFixed(2);
  }
  return '';
};

const KittedItemConfiguration = ({
  product,
  goBack,
  inheritedConfiguration = {},
  configuration = {},
  save,
}) => {
  const { relatedProducts: relatedProductIDs = [], productID } = product;
  const dispatch = useDispatch();
  const reduxState = useSelector(state => state);
  const [config, setConfig] = useState(
    configuration[productID]?.customPrices ?? {},
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [defaultPrice, setDefaultPrice] = useState(
    configuration[productID]?.defaultKitItemPrice ?? '',
  );

  const finalDefaultPrice =
    defaultPrice || inheritedConfiguration[productID]?.defaultKitItemPrice;

  const relatedProducts: { [key: string]: any }[] = useMemo(
    () =>
      (relatedProductIDs ?? [])
        .map(pid => getProductByID(pid)(reduxState))
        .filter(notUndefinedOrNull)
        .filter(p => !p.code.startsWith('EW')),
    [product.productID],
  );

  const changeProductPrice = e => {
    const val = formatPrice(e.target.value);
    const id = e.target.name;
    if (val === '') {
      const newConfig = { ...config };
      delete newConfig[id];
      setConfig(newConfig);
    } else {
      setConfig({ ...config, [id]: val });
    }
  };

  const savePrices = async () => {
    await save({
      ...configuration,
      [productID]: { defaultKitItemPrice: defaultPrice, customPrices: config },
    });
    goBack();
  };

  const toggle = e => {
    const { name } = e.target;
    if (selectedRows.includes(name)) {
      setSelectedRows(selectedRows.filter(id => id !== name));
    } else {
      setSelectedRows([...selectedRows, name]);
    }
  };

  const toggleAll = () => {
    const payload = relatedProductIDs.every(id => selectedRows.includes(id))
      ? []
      : relatedProductIDs;
    setSelectedRows(payload);
  };

  const setBulkPrice = async () => {
    const price = await dispatch(
      openModalPage({
        component: modalPages.pluginModal,
        isPopup: true,
        props: { name: constants.components.bulkEditPrice },
      }),
    );

    const newConfig = { ...config };
    selectedRows.forEach(id => {
      newConfig[id] = price;
    });
    setConfig(newConfig);
  };

  const resetToDefPrice = () => {
    const newConfig = { ...config };
    selectedRows.forEach(id => {
      delete newConfig[id];
    });
    setConfig(newConfig);
  };

  const resetToRegularPrice = () => {
    const newConfig = { ...config };
    selectedRows.forEach(id => {
      newConfig[id] =
        relatedProducts.find(pr => Number(pr.productID) === Number(id))
          ?.priceListPrice ?? newConfig[id];
    });
    setConfig(newConfig);
  };

  return (
    <div data-testid="pnp-kitted-item-conf">
      <h3 className="d-flex">
        Related products{' '}
        <div className="ml-auto">
          <UIButton
            className="mt-1"
            variant="POS"
            text="Save"
            action={savePrices}
            data-testid="save-btn"
          />
          <UIButton
            data-testid="back-btn"
            variant="POS"
            text="Go back"
            action={goBack}
          />
        </div>
      </h3>
      {relatedProducts.length ? (
        <>
          <InputField
            title="Default kitted discount price"
            style={{ textAlign: 'right' }}
            onChange={e => setDefaultPrice(formatPrice(e.target.value))}
            placeholder={formatNumber(
              inheritedConfiguration[productID]?.defaultKitItemPrice,
            )}
            value={defaultPrice}
            data-testid="default-kit-item-price"
          />
          <div className="mt-2">
            <UIButton
              disabled={!selectedRows.length}
              text="Set bulk price"
              variant="success"
              action={setBulkPrice}
              data-testid="set-price-btn"
            />
            <UIButton
              disabled={!selectedRows.length}
              variant="warning"
              className="ml-1"
              text="Reset to default price"
              action={resetToDefPrice}
              data-testid="reset-to-default-btn"
            />
            <UIButton
              disabled={!selectedRows.length}
              variant="danger"
              className="ml-1"
              text="Reset to regular price"
              action={resetToRegularPrice}
              data-testid="reset-to-regular-btn"
            />
          </div>
          <Table className="mt-2">
            <thead>
              <tr>
                <th className="align-middle">
                  <InputField
                    onChange={toggleAll}
                    type="checkbox"
                    value={relatedProductIDs.every(id =>
                      selectedRows.includes(id),
                    )}
                    data-testid="checkbox"
                  />
                </th>
                <th className="align-middle">Name</th>
                <th className="align-middle">Code</th>
                <th className="align-middle text-right">Default price (net)</th>
                <th className="align-middle text-right">Price (net)</th>
                <th className="align-middle text-right">Final price (net)</th>
              </tr>
            </thead>
            <tbody>
              {relatedProducts
                .sort(({ name: a }, { name: b }) => a.localeCompare(b))
                .map(pr => (
                  <tr
                    key={pr.productID}
                    data-testid="product"
                    data-test-key={pr.productID}
                  >
                    <td className="align-middle">
                      <InputField
                        name={pr.productID.toString()}
                        type="checkbox"
                        onChange={toggle}
                        value={selectedRows.includes(pr.productID.toString())}
                        data-testid="selected"
                        data-test-key={pr.productID}
                      />
                    </td>
                    <td
                      data-testid="name"
                      data-test-key={pr.productID}
                      className="align-middle"
                    >
                      {pr.name}
                    </td>
                    <td
                      data-testid="code"
                      data-test-key={pr.productID}
                      className="align-middle"
                    >
                      {pr.code}
                    </td>
                    <td
                      data-testid="default-price"
                      data-test-key={pr.productID}
                      className="align-middle text-right"
                    >
                      {formatNumber(finalDefaultPrice)}
                    </td>
                    <td>
                      <InputField
                        style={{ width: '50px', textAlign: 'right' }}
                        placeholder={formatNumber(
                          inheritedConfiguration[productID]?.customPrices?.[
                            pr.productID
                          ],
                        )}
                        data-testid="change-price"
                        name={pr.productID}
                        onChange={changeProductPrice}
                        value={config?.[pr.productID]}
                      />
                    </td>
                    <td className="align-middle text-right" data-testid="price">
                      {formatNumber(config?.[pr.productID]) ||
                        formatNumber(
                          inheritedConfiguration[productID]?.customPrices?.[
                            pr.productID
                          ],
                        ) ||
                        formatNumber(finalDefaultPrice)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : (
        'No kit options for this product'
      )}
    </div>
  );
};

export default KittedItemConfiguration;
