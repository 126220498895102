/* eslint-disable no-console */
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Divider,
} from '@material-ui/core';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from '@material-ui/styles';

import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import * as API from 'services/ErplyAPI/api';
import { hardLogout, setLogin } from 'actions/Login';
import { getClientCode, getUserLoggedIn, getUsername } from 'reducers/Login';
import Loader from 'components/Loader';
import { REDUX_SESSIONKEY } from 'constants/persistence';
import { addError, addSuccess } from 'actions/Error';
import { createConfirmation } from 'actions/Confirmation';
import { styles } from 'containers/Login/util';
import { getShouldCalculateOffline } from 'reducers/ShoppingCart';
import * as shoppingCartActionTypes from 'constants/ShoppingCart';

const PasswordInput = ({
  username,
  clientCode,
}: {
  username?: string;
  clientCode?: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('sessionExpiration');
  const { t: t2 } = useTranslation('login');
  const { t: t3 } = useTranslation('header');
  const { t: t4 } = useTranslation('common');

  const stateUsername = useSelector(getUsername);
  const stateClientCode = useSelector(getClientCode);
  const stateUser = useSelector(getUserLoggedIn);
  const shouldCalculateOffline = useSelector(getShouldCalculateOffline);
  const calculateOffline = shouldCalculate => ({
    type: shoppingCartActionTypes.SET_CALCULATE_OFFLINE,
    payload: shouldCalculate,
  });

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const logoutWithConfirmation = () =>
    dispatch(
      createConfirmation(
        () => dispatch(hardLogout()),
        () => {
          // do nothing
        },
        {
          title: t('passwordPrompt.confirmationTitle'),
          body: t('passwordPrompt.confirmationBody'),
          cancelText: t4('back'),
          confirmText: t3('menu.logout'),
        },
      ),
    );

  const reverifyWithPass = async () => {
    setLoading(true);
    const usrName = username ?? stateUsername ?? stateUser?.userName;
    const cc = clientCode ?? stateClientCode;
    try {
      const results = await API.loginWithPass({
        clientCode: cc,
        username: usrName,
        password,
      });
      const response = results[0];
      if (response) {
        localStorage.setItem(
          REDUX_SESSIONKEY,
          JSON.stringify(response.sessionKey),
        );
        dispatch(
          setLogin({
            user: response,
            username: response.userName,
            clientCode: cc,
          }),
        );
        dispatch(addSuccess(t('passwordPrompt.sessionExtended')));
        dispatch(closeModalPage('sessionExpiryPasswordInput'));
        if (shouldCalculateOffline) {
          dispatch(calculateOffline(false));
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(addError(e.message));
      } else if (typeof e === 'string') {
        dispatch(addError(e));
      } else {
        dispatch(addError(t2('errors.loginFailed')));
      }
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const currentRef = inputRef.current;
    if (currentRef) {
      currentRef.focus();
    }
  }, []);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      reverifyWithPass();
    }
  };

  return (
    <Loader show={loading} loadingText={t2('loading.preclockin')}>
      <Box padding="1rem">
        <Box display="flex" alignItems="center">
          <Box marginBottom="15px">
            <Typography variant="h5">{t('passwordPrompt.title')}</Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          margin="0.75rem 15px 15px 15px"
        >
          <Typography>{t('passwordPrompt.note')}</Typography>
          <TextField
            fullWidth
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            inputRef={inputRef}
            autoFocus
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t2('inputs.password')}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t('passwordPrompt.toggleVisibility')}
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridGap="0.5rem"
          >
            <Button
              onClick={() => reverifyWithPass()}
              fullWidth
              variant="contained"
              color="secondary"
              style={styles.button as CSSProperties}
            >
              {t('passwordPrompt.verify')}
            </Button>
            <Button
              onClick={() => logoutWithConfirmation()}
              fullWidth
              variant="contained"
              color="primary"
              style={styles.button as CSSProperties}
            >
              {t3('menu.logout')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Loader>
  );
};

export default PasswordInput;
