const TARGET_LENGTH = 15;
const DEFAULT_PREFIX = '111';

/**
 * {clientcode}[n]{id}, padded with zeroes in the [n] position until it's length 15
 * isFranchiseAccount determines whether prefix is client code or default prefix
 * @example
 * generateOriginalInvoiceAttribute('123',    '456789', true) // 123000000456789
 * generateOriginalInvoiceAttribute('123','4567891234', true) // 123004567891234
 * generateOriginalInvoiceAttribute('123456','7891234567', false) // 111007891234567
 */

export function generateOriginalInvoiceAttribute(
  clientCode: string,
  saleId: number | string,
  isFranchiseAccount: boolean,
) {
  const stringifiedSaleId = String(saleId);
  // The only way an  original invoice id can have 15 characters length
  // is if the prefix has been appended previously
  if (stringifiedSaleId.length === TARGET_LENGTH) return stringifiedSaleId;
  const prefix = isFranchiseAccount ? clientCode : DEFAULT_PREFIX;
  // 0s should be added between clientCode and sale id
  // to maintain the same original-invoice-id length (15) for integration with Magento
  const padLength =
    // For the id's that have a prefix of 111, never set any zeroes in between
    prefix === DEFAULT_PREFIX
      ? 0
      : TARGET_LENGTH - prefix.length - stringifiedSaleId.length;
  // padLength should never be negative, but if it does happen somehow this prevents an error from being thrown
  const amountOfZeroes = Math.max(0, padLength);
  return `${prefix}${'0'.repeat(amountOfZeroes)}${saleId}`;
}
