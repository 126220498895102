import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import InputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import SaveButton from 'components/CustomButtons/SaveButton';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { sanitizePrice } from 'actions/CreateNew';
import { round } from 'utils';
import { getShowPricesWithTax } from 'reducers/configs/settings';

const CashierPrice = ({
  product,
  productID,
  giftCardSerial = '',
  allowNegativePrice = true,
}) => {
  const dispatch = useDispatch();
  const isWithTax = useSelector(getShowPricesWithTax);
  const [price, setPrice] = useState(
    `${round(isWithTax ? product.priceWithVat : product.price)}`,
  );

  const calculateNoVat = (value, vat) => {
    return (value * 100) / (vat + 100);
  };

  const handleSubmit = async e => {
    // For some reason, the product button will receive a 'click' event out of our enter key if we don't do this
    e.preventDefault();
    e.stopPropagation();
    if (price && price !== '' && Number(price) !== 0) {
      const priceWithoutVAT = isWithTax
        ? calculateNoVat(price, product.vatrate)
        : price;

      dispatch(previousModalPage());
      return dispatch(
        addProduct({
          productID,
          giftCardSerial,
          priceSet: true,
          price: priceWithoutVAT,
        }),
      );
    }
    return () => {
      // do nothing
    };
  };

  const handleChange = e => {
    const sanitizedPrice = sanitizePrice(e.target.value);
    const finalPrice = allowNegativePrice
      ? sanitizedPrice
      : sanitizedPrice.replace('-', '');

    setPrice(finalPrice);
  };

  const handleClose = () => {
    return dispatch(previousModalPage());
  };

  useShortcut('Enter', handleSubmit);
  useShortcut('Escape', handleClose);
  const { t } = useTranslation();
  return (
    <div className="cashier-price" data-testid="cashier-price">
      <Modal.Header>
        <Modal.Title>
          <h3 data-testid="product-name">
            <b>{`${product.name} ${product.code}`}</b>
          </h3>
        </Modal.Title>
        <div
          className="d-flex align-content-center"
          styles={{ marginLeft: 'auto' }}
        >
          <SaveButton
            className="assign-serial-button"
            action={handleSubmit}
            disabled={!price || price === '' || Number(price) === 0}
            data-testid="save"
          />
          <CloseButton
            style={{ transform: 'scale(0.85)' }}
            action={handleClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <InputField
              size="lg"
              title={t('common:glossary.price')}
              value={price}
              name="cashier-price"
              type="text"
              autoFocus
              onChange={handleChange}
              className={classnames(styles.formInput, styles.mediumTitle)}
              data-testid="price-field"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </div>
  );
};

export default CashierPrice;
