import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getCurrentPosLanguage } from 'reducers/configs/settings';
import { PosPlugin } from 'plugins/plugin';

import enTranslations from './en';
import frTranslations from './fr';

export const getCustomTranslations: Required<
  PosPlugin
>['getTranslationOverrides'] = createSelector(
  () => null,
  state => getCurrentPosLanguage(state),
  (_, language) => {
    switch (language) {
      case 'fr':
        return R.mergeDeepRight(enTranslations, frTranslations);
      case 'en':
      default:
        return enTranslations;
    }
  },
);
