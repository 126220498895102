import { v4 as uuidv4 } from 'uuid';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { RootState } from 'reducers';
import { GridButtonSaleOption } from 'reducers/UI/gridButtons';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';
import { ErplyAttributes } from 'utils';
import { openPluginModalPage } from 'actions/modalPage';

import { getQffCardNumber } from '../rdx/reducers';
import { pluginID } from '../constants';
import { Configuration } from '../types';

import QffGridButton from './QffGridButton';
import { getShouldSkipQffModal, openQffModal } from './utils';

export const qffCardButton: GridButtonSaleOption = {
  id: uuidv4(),
  name: 'Add QFF card',
  actionType: 'action',
  action: () => async (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
    dispatch(openQffModal());
  },
  view: QffGridButton,
};

export const askForQffCardNumber: Required<
  PosPlugin
>['onOpenPaymentModal']['after'] = () => async (dispatch, getState) => {
  const config = getPluginConfigurationAtLevel<Configuration>(
    pluginID,
    'Company',
    '',
  )(getState());
  if (!config?.promptForQffCardNumberOnPay) return;

  const cardNumber = getQffCardNumber(getState());
  if (cardNumber) return;

  const shouldSkipQffModal = getShouldSkipQffModal(getState());
  if (shouldSkipQffModal) return;

  await dispatch(openPluginModalPage('QffCardModal')({ isPopup: true }));
};

export const saveQffData: Required<PosPlugin>['onSaveSalesDocument']['on'] = (
  _p,
  ap,
) => async (_dispatch, getState) => {
  const cardNumber = getQffCardNumber(getState());
  if (!cardNumber) return ap;

  const config = getPluginConfigurationAtLevel<Configuration>(
    pluginID,
    'Company',
    '',
  )(getState());
  const message = config?.messageOnReceipt ?? '';

  return ap.map(request => {
    if (request.requestName !== 'saveSalesDocument') return request;
    if (['ORDER', 'PREPAYMENT'].includes(request.type)) return request;

    const updatedRequest = {
      ...request,
      notes: request.notes?.length ? `${request.notes}\n${message}` : message,
    };

    const attributes = ErplyAttributes.fromFlatArray(updatedRequest);
    attributes.set('has_qff_card_no', 'true');
    attributes.set('qff_card_no', cardNumber);

    return {
      ...ErplyAttributes.withoutFlatArray(updatedRequest),
      ...attributes.asFlatArray,
    };
  });
};
