import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';

const pluginId = 'alternative-product-button-text';

const alternativeProductButtonText: PosPlugin = {
  id: pluginId,
  name: 'Alternative product button text',
  keywords: ['alternative', 'product', 'button', 'text'],
  info:
    'This plugin displays the product cards with the following data: {Product code} {Product name}.',
  getStatus: () => {
    return {
      type: 'valid',
      message: `Ready`,
    };
  },
  UIProductButton: ({ children, item }) => {
    if (!item.productID) return children;

    return R.modifyPath(
      ['props', 'children', 'props', 'product'],
      R.pipe(
        R.assoc('name', `${item.code} \n ${item.name}`),
        R.dissoc('priceListPriceWithVat'),
        R.dissoc('priceWithVat'),
        R.dissoc('priceListPrice'),
        R.dissoc('price'),
      ),
    )(children);
  },
};

export default alternativeProductButtonText;
