import React, { useState } from 'react';
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getAllowedWarehouses } from 'reducers/warehouses';
import { clockIn } from 'services/ErplyAPI/clockInOut';
import { version } from 'external_data';

import { styles } from '../util';

const displayOptions = locations => {
  return locations.map(location => (
    <option value={location.warehouseID} key={location.warehouseID}>
      {location.name}
    </option>
  ));
};

const LocationSelection = ({
  updateView,
  toggleClockInOut,
  clockInOutState,
  updateClockInOutState,
  warningMessage = null,
}) => {
  const allowedWarehouses = useSelector(getAllowedWarehouses);
  const [selectedLocation, updateLocation] = useState({
    warehouseID: allowedWarehouses[0]?.warehouseID || 0,
    warehouseName: allowedWarehouses[0]?.name || '',
  });
  const { t } = useTranslation('clockInOut');

  return (
    <Card style={styles.card}>
      <Card.Header style={styles.header}>
        Clock in/out
        <span
          onClick={() => {
            updateView('login');
            toggleClockInOut(false);
          }}
          className="Clickable-icon icon_menu" // icon_grid-3x3 for login
          style={{
            fontSize: '40px',
            // transform: "scale(0.7, 0.7)" for login
          }}
        />
      </Card.Header>
      <Card.Body className="p-sm-4 pb-4">
        <Container>
          <Row>
            <Col>
              <InputGroup size="lg">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="select-location"
                    style={{ display: 'none' }}
                  >
                    {t('selectLocation')}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  value={selectedLocation.warehouseID}
                  onChange={event => {
                    const warehouses = allowedWarehouses;
                    const selectedWarehouse = warehouses.filter(
                      location => event.target.value === location.warehouseID,
                    );
                    updateLocation({
                      warehouseName: selectedWarehouse[0].name || '',
                      warehouseID: event.target.value,
                    });
                  }}
                >
                  {allowedWarehouses.length
                    ? displayOptions(allowedWarehouses)
                    : null}
                </FormControl>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <Button
                onClick={async () => {
                  try {
                    await clockIn({
                      employeeID: parseInt(clockInOutState.employeeID, 10),
                      warehouseID: selectedLocation.warehouseID,
                      InUnixTime: Math.round(new Date().getTime() / 1000),
                    });
                    updateClockInOutState({
                      ...clockInOutState,
                      ...selectedLocation,
                    });
                    updateView('clockInOutInterface');
                    // update clockinStat here
                  } catch (e) {
                    console.error('Failed to clock in',e);
                    warningMessage(t('alerts:genericError'));
                  }
                }}
                style={{ ...styles.button, marginBottom: '10px' }}
                size="lg"
                variant="secondary"
              >
                {t('clockInButton')}
              </Button>
            </Col>
          </Row>
        </Container>
        <span style={styles.version}>{version}</span>
      </Card.Body>
    </Card>
  );
};

LocationSelection.propTypes = {
  updateView: PropTypes.func.isRequired,
  toggleClockInOut: PropTypes.func.isRequired,
  clockInOutState: PropTypes.object.isRequired,
  updateClockInOutState: PropTypes.func.isRequired,
  warningMessage: PropTypes.func,
};

export default LocationSelection;
