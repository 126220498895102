import { refundRequest } from 'paymentIntegrations/adyen/requests/adyenRequests';
import { FUNC_BUTTONS } from 'paymentIntegrations/types';

import { creationOfAdyenPatchscript } from '../printing';

const fullAdyenReferencedReturn = async (payment, hooks) => {
  const {
    updateMessage,
    enableButtons,
    beforeDocSave,
    beforeDocDelete,
    displayWarning,
    adyenConfig,
    employeeID,
    printReceipt,
  } = hooks;
  updateMessage('Refunding purchase...');

  try {
    const returnResponse = await refundRequest(
      payment,
      adyenConfig.currencyCode,
      employeeID,
    );

    const {
      transactionStatus,
      referenceNumber,
      approvedAmount,
      statusMessage,
      additionalData,
      authCode,
      cardType,
      cardNumber,
      cardHolder,
    } = returnResponse;
    console.debug('adyen return response', returnResponse);

    if (transactionStatus === 'Success') {
      updateMessage(`Success. Refund has been successfully performed.`);
      if (payment.paid && payment.shouldProcess) {
        await beforeDocDelete(payment.key);
      } else {
        await beforeDocSave({
          key: payment.key,
          type: 'CARD',
          amount: `-${approvedAmount}`,
          cardType,
          cardNumber,
          cardHolder,
          attributes: {
            refNo: referenceNumber,
            authCode,
          },
          paid: true,
        });
      }

      if (adyenConfig.usePOSGeneralPrinter) {
        const parsedAdditionalData = JSON.parse(additionalData);
        const { receipts } = parsedAdditionalData;

        const receiptsPatchscript = creationOfAdyenPatchscript(receipts);
        printReceipt(receiptsPatchscript);
      }

      return true;
    }

    enableButtons([FUNC_BUTTONS.CLOSE]);
    updateMessage(`Unsuccessful return: ${statusMessage}.`);
    return false;
  } catch (error) {
    enableButtons([FUNC_BUTTONS.CHECK_STATUS]);
    updateMessage(error.message);
    displayWarning(error.message);
    return false;
  }
};

export default fullAdyenReferencedReturn;
