import dayjs from 'dayjs';

import { getPluginConfiguration } from 'reducers/Plugins';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getUserLoggedIn } from 'reducers/Login';
import { RItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { getCustomers, getSalesDocuments } from 'services/ErplyAPI';
import { printPatchscript } from 'actions/integrations/printer';
import { getSelectedWarehouse } from 'reducers/warehouses';
import { getEmployeeById } from 'reducers/cachedItems/employees';

export const printCreditSlip = ({
  invoiceID,
  code,
  balance,
  date,
  redeem = false,
}) => async (dispatch, getState) => {
  const [salesDocument] = await getSalesDocuments({
    id: invoiceID,
    recordsOnPage: 1,
  });
  const conf = getPluginConfiguration('pnp-customPrinting')(getState());
  const wh = getSelectedWarehouse(getState());
  const pos = getSelectedPos(getState());
  const user = getUserLoggedIn(getState());
  const employee = getEmployeeById(user.employeeID)(getState());
  const logoData = (conf as any)?.customLogo;

  const [customer] = await getCustomers({
    customerID: salesDocument.clientID,
  });
  const nowString = dayjs(new Date(date * 1000)).format('M/DD/YYYY h:mm A');

  const blankLine: RItem = { type: 'text', pieces: [{ text: ' ' }] };
  const logo: RItem[] = [
    ...((logoData
      ? [{ type: 'image', align: 'center', blob: logoData }]
      : []) as RItem[]),
  ];
  // prettier-ignore
  const title: RItem[] = [
    { type: 'text', align: 'center', pieces: [{ text: 'CREDIT SLIP', meta: { size: 2 } }] },
    { type: 'text', align: 'center', pieces: [{ text: wh.name }] },
    { type: 'text', align: 'center', pieces: [{ text: wh.address }] },
    { type: 'text', align: 'center', pieces: [{ text: wh.phone }] },
  ];
  // prettier-ignore
  const headerMetaSection: RItem[] = [
    {
      type: 'text',
      pieces: [{ text: 'Ticket # ', meta: { bold: true } }, { text: salesDocument.number ?? salesDocument.invoiceNo }],
    },
    { type: 'text', pieces: [{ text: 'User: ', meta: { bold: true } }, { text: `${employee.firstName} ${employee.lastName.slice(0, 1)}.` }] },
    { type: 'text', pieces: [{ text: nowString }] },
    { type: 'text', pieces: [{ text: 'Station: ', meta: { bold: true } }, { text: pos.pointOfSaleID }] },
  ];
  // prettier-ignore
  const midSection: RItem[] = [
    {
      type: 'table',
      columns: [{ baseWidth: 0, weight: 1 }, { baseWidth: 8, weight: 0 }],
      rows: [
        {
          type: 'normal',
          cells: [
            {
              pieces: [...(redeem ? [{ text: 'NEW ', meta: { bold: true } }] : []), {
                text: 'CREDIT SLIP BALANCE: ',
                meta: { bold: true },
              }, { text: balance.toFixed(2) }],
            },
          ],
        },
      ],
    },
    { type: 'text', pieces: [{ text: 'Store credit #: ', meta: { bold: true } }, { text: `${code}` }] },
  ];
  // prettier-ignore
  const qrCode: RItem[] = [
    { type: 'qrcode', align: 'center', data: code },
  ];
  // prettier-ignore
  const customerSection: RItem[] = [
    { type: 'text', align: 'center', pieces: [{ text: customer.fullName }] },
  ];
  // prettier-ignore
  await dispatch(
    printPatchscript([
      ...logo,
      ...title,
      ...headerMetaSection,
      blankLine,
      ...midSection,
      ...qrCode,
      ...customerSection,
    ]),
  );
};
