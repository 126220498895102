import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import InputField from 'components/FieldTypes/InputField';
import Icon from 'components/Icon';
import { hardLogout } from 'actions/Login';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import * as api from 'services/ErplyAPI/api';
import styles from 'components/FieldTypes/skins/skins.module.scss';
import { addWarning } from 'actions/Error';
import { sleep } from 'utils';

const getErrorForPassword = password => {
  if (password.length === 0) {
    return i18next.t('validation:generic.required');
  }
  if (password.length < 8) {
    return i18next.t('validation:generic.minLength', { min: 8 });
  }
  return false;
};

export default () => {
  const [current, setCurrent] = useState('');
  const [new1, setNew1] = useState('');
  const [new2, setNew2] = useState('');
  const [errorOverride, setErrorOverride] = useState(false);
  const [successOverride, setSuccessOverride] = useState(false);

  const { t } = useTranslation('changePassword');
  const error1 = getErrorForPassword(current);
  const error2 = getErrorForPassword(new1);
  const error3 =
    getErrorForPassword(new2) ||
    (new1 !== new2 && t('validation:changePassword.noMatch'));

  const dispatch = useDispatch();
  const doLogout = useCallback(() => dispatch(hardLogout()), [dispatch]);
  const closePopup = useCallback(() => dispatch(previousModalPage()), [
    dispatch,
  ]);

  useEffect(() => {
    setErrorOverride(false);
  }, [current, new1, new2]);

  const trySave = async () => {
    if (error1 || error2 || error3) {
      return;
    }
    try {
      await api.changePassword({ oldPassword: current, newPassword: new1 });
      await setSuccessOverride('You will be logged out in 2 seconds');
      await sleep(2);
      closePopup();
      doLogout();
      dispatch(addWarning('Password was changed. Please login again'), {
        selfDismiss: 2000,
      });
    } catch (e) {
      setErrorOverride(e.message);
    }
  };

  const anyError = error1 || error2 || error3 || errorOverride;
  return (
    <>
      <Modal.Header>
        <Modal.Title style={{ fontSize: '24px', fontWeight: 700 }}>
          {t('title')}
        </Modal.Title>
        <span style={{ flexGrow: 1 }} />
        <SaveButton action={trySave} disabled={anyError} />
        <CloseButton action={closePopup} />
      </Modal.Header>
      <Modal.Body>
        <form action="javascript:void(0);">
          <InputField
            type="password"
            autocomplete="password"
            size="lg"
            className={styles.changePassInput}
            title={t('inputs.current')}
            value={current}
            onChange={e => setCurrent(e.target.value)}
            errorText={error1}
          />
          <InputField
            type="password"
            autocomplete="new-password"
            size="lg"
            className={styles.changePassInput}
            title={t('inputs.new1')}
            value={new1}
            onChange={e => setNew1(e.target.value)}
            errorText={error2}
          />
          <InputField
            type="password"
            autocomplete="new-password"
            size="lg"
            className={styles.changePassInput}
            title={t('inputs.new2')}
            value={new2}
            onChange={e => setNew2(e.target.value)}
            errorText={error3}
          />
          {errorOverride ? (
            <div style={{ color: 'red', minHeight: '1em' }}>{errorOverride}</div>
          ) : null}
          {successOverride ? (
            <div style={{ color: 'green', minHeight: '1em' }}>
              {successOverride}
            </div>
          ) : null}

          <br />
          <p>
            <Icon name="icon_info" style={{ fontSize: 12 }} />
            {t('logoutNote')}
          </p>
        </form>
      </Modal.Body>
    </>
  );
};
