import {
  ADMISSION_FEE_NET_PRICE,
  ADMISSION_FEE_TAX_AMOUNT,
  CALCULATING_ENV_OR_ADM_TAX_FEES,
  HAS_REMOVED_ITEM,
  PRICE_HAS_BEEN_UPDATED,
  RETURN_SAME_AMOUNT_OF_ITEMS_BOUGHT,
  SET_ADMISSION_TAX_PRODUCT,
  SET_ENV_FEE_AMOUNT,
  SET_ENV_FEE_RATE,
  SET_INITIAL_ENV_FEE,
  SHOULD_ADD_ADMISSION_TAX,
  SHOULD_ADD_ENV_FEE,
  STORE_ENV_PRODUCT,
} from '../types';

const initState = {
  shouldAddEnvFee: false,
  envFeeAmount: 0,
  returnAllBoughtItems: true,
  hasRemovedItem: false,
  priceHasBeenUpdated: false,
  shouldAddAdmissionTax: false,
  admissionFeeNetPrice: null,
  admissionFeeTaxAmount: 0,
  initialEnvFeeAmount: 0,
  envFeeRate: 0,
  envFeeProduct: null,
  admissionFeeProduct: null,
  calculatingEnvOrAdmTaxFees: false,
};

const envAndCAFeeReducer = (
  state = initState,
  { type, payload }: { type: string; payload: any },
): object => {
  switch (type) {
    case RETURN_SAME_AMOUNT_OF_ITEMS_BOUGHT:
      return { ...state, returnAllBoughtItems: payload };
    case SHOULD_ADD_ENV_FEE:
      return { ...state, shouldAddEnvFee: payload };
    case SET_ENV_FEE_AMOUNT:
      return { ...state, envFeeAmount: payload ?? 0 };
    case SET_INITIAL_ENV_FEE:
      return { ...state, initialEnvFeeAmount: payload };
    case STORE_ENV_PRODUCT:
      return { ...state, envFeeProduct: payload };
    case HAS_REMOVED_ITEM:
      return { ...state, hasRemovedItem: payload };
    case PRICE_HAS_BEEN_UPDATED:
      return { ...state, priceHasBeenUpdated: payload };
    case SHOULD_ADD_ADMISSION_TAX:
      return { ...state, shouldAddAdmissionTax: payload };
    case SET_ADMISSION_TAX_PRODUCT:
      return { ...state, admissionFeeProduct: payload };
    case ADMISSION_FEE_NET_PRICE:
      return { ...state, admissionFeeNetPrice: payload };
    case SET_ENV_FEE_RATE:
      return { ...state, envFeeRate: payload };
    case ADMISSION_FEE_TAX_AMOUNT:
      return { ...state, admissionFeeTaxAmount: payload };
    case CALCULATING_ENV_OR_ADM_TAX_FEES:
      return { ...state, calculatingEnvOrAdmTaxFees: payload };
    default:
      return state;
  }
};

export default envAndCAFeeReducer;
