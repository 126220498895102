import React, { useState } from 'react';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import { useDebounce, useMountedState } from 'react-use';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getSalesDocuments } from 'services/ErplyAPI/sales';
import { SaleDocumentResponse } from 'types/SalesDocument';
import Loader from 'components/Loader';
import { addReturnProducts } from 'actions/ShoppingCart/addReturnProducts';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';

import { setTaxReturnDocument } from '../redux';

import Header from './Header';

const Document: React.FC<{
  salesDoc: SaleDocumentResponse;
}> = ({ salesDoc }) => {
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  return (
    <Box
      display="flex"
      padding="0.5rem 1.25rem"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography>{salesDoc.number}</Typography>
      <Button
        variant="contained"
        color="secondary"
        disabled={salesDoc.vatTotal === 0}
        onClick={async () => {
          await dispatch(
            addReturnProducts({
              returnDocument: {
                ...salesDoc,
                rows: salesDoc.rows,
              },
              orders: salesDoc.rows,
            }),
          );
          dispatch(setTaxReturnDocument(salesDoc));
          dispatch(closeModalPage());
        }}
      >
        Return tax
      </Button>
    </Box>
  );
};

const Documents: React.FC = () => {
  const [pageNo, setPageNo] = useState(1);

  const [salesDocuments, setSalesDocuments] = useState<SaleDocumentResponse[]>(
    [],
  );
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const isMounted = useMountedState();

  useDebounce(
    async () => {
      if (isMounted()) {
        setLoading(true);
        const documents = await getSalesDocuments({
          pageNo,
          recordsOnPage: 20,
          confirmed: 1,
          getRowsForAllInvoices: 1,
          types:
            'INVWAYBILL, CASHINVOICE, WAYBILL, PREPAYMENT, OFFER, EXPORTINVOICE, RESERVATION, ORDER, INVOICE',
          getReturnedPayments: 1,
          ...(searchValue ? { number: searchValue } : {}),
        });
        setSalesDocuments(documents);
        setLoading(false);
      }
    },
    500,
    [searchValue, pageNo, isMounted],
  );

  return (
    <Loader show={loading} loadingText="Fetching documents..." block>
      <Box padding="0.5rem">
        <TextField
          fullWidth
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          variant="outlined"
          label="Search by number"
        />

        {salesDocuments?.map(salesDoc => (
          <Document key={salesDoc.id.toString()} salesDoc={salesDoc} />
        ))}
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => setPageNo(prev => prev + 1)}
        >
          Load more
        </Button>
      </Box>
    </Loader>
  );
};

const TaxReturnModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box>
      <Header title="Tax Return" onClose={onClose} />
      <Documents />
    </Box>
  );
};

export default TaxReturnModal;
