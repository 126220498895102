import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, MenuItem, TextField } from '@material-ui/core';

import { getAddressTypes } from 'reducers/customerSearch';
import { Address } from 'types/Address';
import { PluginComponent } from 'plugins';

import { Field } from '../../views/CreateCustomerBeta/components';

const AddressForm: FC<{
  address: Address;
  onChange: (key: string, value: string) => void;
}> = ({ address, onChange = () => null }) => {
  const { t } = useTranslation('addressForm');
  const addressTypes = useSelector(getAddressTypes);
  return (
    <PluginComponent hookname="UIAddressFormBeta" props={{ address, onChange }}>
      <Grid item container spacing={2}>
        <Field
          name="addressTypeID"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            select
            label={t('type')}
            value={address?.typeID ?? ''}
            name="addressTypeID"
            data-testid="address"
            onChange={e => onChange('typeID', e.target.value)}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="">{t('selectOption')}</MenuItem>
            {addressTypes.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Field>
        <Field
          name="street"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            name="street"
            data-testid="street"
            label={t('placeholders.street')}
            variant="outlined"
            fullWidth
            value={address?.street ?? ''}
            onChange={e => onChange('street', e.target.value)}
          />
        </Field>
        <Field
          name="address2"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            name="street"
            data-testid="address2"
            label={t('placeholders.address2')}
            variant="outlined"
            fullWidth
            value={address?.address2 ?? ''}
            onChange={e => onChange('address2', e.target.value)}
          />
        </Field>
        <Field
          name="postalCode"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            name="postalCode"
            data-testid="postalCode"
            label={t('placeholders.postalCode')}
            variant="outlined"
            fullWidth
            value={address?.postalCode ?? ''}
            onChange={e => onChange('postalCode', e.target.value)}
          />
        </Field>
        <Field name="city" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            name="city"
            data-testid="city"
            label={t('placeholders.city')}
            variant="outlined"
            fullWidth
            value={address?.city ?? ''}
            onChange={e => onChange('city', e.target.value)}
          />
        </Field>
        <Field name="state" wrapper={Grid} wrapperProps={{ item: true, xs: 6 }}>
          <TextField
            name="state"
            data-testid="state"
            label={t('placeholders.state')}
            variant="outlined"
            fullWidth
            value={address?.state ?? ''}
            onChange={e => onChange('state', e.target.value)}
          />
        </Field>
        <Field
          name="country"
          wrapper={Grid}
          wrapperProps={{ item: true, xs: 6 }}
        >
          <TextField
            name="country"
            data-testid="country"
            label={t('placeholders.country')}
            variant="outlined"
            fullWidth
            value={address?.country ?? ''}
            onChange={e => onChange('country', e.target.value)}
          />
        </Field>
      </Grid>
    </PluginComponent>
  );
};

export default AddressForm;
