import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import {
  ConfComponent,
  PluginConf,
} from 'plugins/customTranslations/configuration';
import { getPluginConfiguration } from 'reducers/Plugins';

const pluginID = 'customTranslations';
const customTranslationsPlugin: PosPlugin<PluginConf> = {
  id: pluginID,
  name: 'Custom translations',
  keywords: ['core', 'translations', 'languages', 'texts', 'UI', 'interface'],
  ComponentConfigurationByLevel: ConfComponent,
  combineConfiguration: c => ({
    translations: c?.translations ?? {},
  }),
  getTranslationOverrides: createSelector(
    state => getPluginConfiguration<PluginConf>(pluginID)(state),
    conf => {
      const trans = conf?.translations ?? {}
      return trans;
    },
  ),
};

export default customTranslationsPlugin;
