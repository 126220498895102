import { PatchScript } from '@pos-refacto/patchscript-with-react';

import { RItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { mmReceiptTranslations } from 'plugins/mm/features/receipt/pieces/translations/translations';
import { AppliedPromotionRecordResponse } from 'types/Promotions';
import { PaymentType } from 'types/PaymentType';

import { CurrencyUtil } from '../types';

type Table = Extract<PatchScript[number], { type: 'table' }>;
type TableRow = Table['rows'][number];
type Text = Extract<PatchScript[number], { type: 'text' }>;

const text = (s: any, meta?: Text['pieces'][number]['meta']): Text => ({
  type: 'text',
  pieces: [{ text: String(s), meta }],
});
const textR = (s: any, meta?: Text['pieces'][number]['meta']): Text => ({
  ...text(s, meta),
  align: 'right',
});
const price = (CURR: CurrencyUtil, s: any, meta?: Text['pieces'][number]['meta']): Text => ({
  type: 'text',
  align: 'right',
  pieces: [{ text: CURR.stringify(CURR.parse(s)), meta }],
});

type ReceiptProductsTableData = {
  payments: {
    type: string;
    typeTrans?: string;
    sum: string;
    cardNumber: string;
    cardType: string;
    typeNameRaw: 'GIVEX' | 'VOUCHER' | 'CASHCARD' | string;
    isCard: boolean;
  }[];
  totals: {
    total: number;
    net: number;
    discount: string;
    vat: {
      byRate: {
        name?: string;
        rate?: string;
        total: string;
      }[];
    };
    rounding: number;
  };
  rows: {
    discount: number;
    rowNetTotal: string;
    finalNetPrice: string;
    originalNetPrice: string;
    productName?: string;
    code?: string;
    amount: string;
    discounts?: {
      /** Unit discount (Sign matches product, positive normally, negative on returns) */
      unit: number;
      /** Total discount (Sign matches product, positive normally, negative on returns */
      total: number;
      /** Nr of items the discount applies to (Sign matches qty, positive normally, negative on returns */
      qty: number;
      name: string;
    }[];
  }[];
  promotions?: AppliedPromotionRecordResponse[][];
};

/**
 * <pre>
 * D͟͞e͟͞s͟͞c͟͞r͟͞i͟͞p͟͞t͟͞i͟͞o͟͞n͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞Q͟͞u͟͞a͟͞n͟͞t͟͞i͟͞t͟͞y͟͞ ͟͞ ͟͞ ͟͞P͟͞r͟͞i͟͞c͟͞e͟͞ ͟͞ ͟͞T͟͞o͟͞t͟͞a͟͞l͟͞
 *  ͟ ͟ ͟ ͟     Coca cola
 *  Regular price: 50.00         1x    2.00   2.00
 *  ͟ ͟ ͟ ͟     Fries
 *  Regular price: 1.20          2x    1.00   2.00
 * Q͟͞u͟͞a͟͞n͟͞t͟͞i͟͞t͟͞y͟͞:͟͞ ͟͞2͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞S͟͞u͟͞b͟͞t͟͞o͟͞t͟͞a͟͞l͟͞:͟͞ ͟͞ ͟͞4͟͞0͟͞.͟͞0͟͞0͟͞
 *                                 HST 13%:   0.39
 *                                Rounding:  -0.02
 *  ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞T͟͞o͟͞t͟͞a͟͞l͟͞ ͟͞$͟͞:͟͞ ͟͞ ͟͞4͟͞0͟͞.͟͞3͟͞7͟͞
 *                              Discount $:  48.20
 *                             Paid (CASH):  40.37
 */
export const receiptProductsTable = (data: ReceiptProductsTableData, translations = mmReceiptTranslations.en, CURR: CurrencyUtil): PatchScript => {
  const t = {
    h: translations.productsTable.headers,
    d: translations.productsTable.discount,
    t: translations.productsTable.totals,
  };
  // eslint-disable-next-line
  /* eslint prettier/prettier: ["warn", { printWidth: 150 }] */
  const totalProducts = data.rows.map(r => CURR.parse(r.amount)).reduce((a, b) => a + b, 0);

  const productsTable: RItem = {
    type: 'table',
    columns: [
      { baseWidth: 10, weight: 0 },
      { baseWidth: 0, weight: 1 },
      { baseWidth: 8, weight: 0 },
      { baseWidth: 7, weight: 0 },
      { baseWidth: 7, weight: 0 },
    ],
    rows: [
      // header
      {
        type: 'header',
        cells: [text(t.h.description), 'colspan', textR(t.h.quantity), textR(t.h.price), textR(t.h.total)],
      },
      // Products
      ...data.rows.flatMap((r, i): TableRow[] => {
        const rows: TableRow[] = [];
        rows.push({
          type: 'normal',
          cells: [text(data.rows[i].code ?? '____', { bold: true }), text(r.productName), 'colspan', 'colspan', 'colspan'],
        });
        rows.push({
          type: 'normal',
          cells: [
            text(` ${t.d.regularPrice}: ${CURR.stringify(CURR.parse(r.originalNetPrice))}`),
            'colspan',
            textR(`${r.amount}x`),
            price(CURR, r.finalNetPrice),
            price(CURR, r.rowNetTotal),
          ],
        });
        if (r.discounts) {
          r.discounts.forEach(d => {
            const discountName = translations[d.name] ?? d.name;
            rows.push({
              type: 'normal',
              cells: [text(`  ${t.d.discount}: ${discountName}`, { size: -1 }), 'colspan', 'colspan', 'colspan', 'colspan'],
            });
            rows.push({
              type: 'normal',
              cells: [null, null, textR(`${Number(d.qty)}x`, { size: -1 }), price(CURR, -d.unit, { size: -1 }), price(CURR, -d.total, { size: -1 })],
            });
          });
        } else if (r.discount) {
          rows.push({
            type: 'normal',
            cells: [
              text(` ${t.d.discount}: -${r.discount}%`, { size: -1 }),
              'colspan',
              null,
              null,
              price(CURR, CURR.parse(r.finalNetPrice) - CURR.parse(r.originalNetPrice), { size: -1 }),
            ],
          });
        }
        return rows;
      }),
    ],
  };
  const totalsTable: RItem = {
    type: 'table',
    columns: [
      { baseWidth: 0, weight: 1 },
      { baseWidth: 17, weight: 0 },
      { baseWidth: 7, weight: 0 },
    ],
    rows: [
      // Quantity & subtotals & total & discount
      {
        type: 'sectionStart',
        cells: [
          text(`${t.t.quantity}: ${totalProducts}`),
          {
            align: 'right',
            pieces: [{ text: `${t.t.subtotal} ${CURR.symbol}:` }],
          },
          price(CURR, data.totals.net),
        ],
      },
      ...data.totals.vat.byRate.map(
        (v, i): TableRow => ({
          type: i === 0 ? 'sectionStart' : 'normal',
          cells: [
            {
              align: 'right',
              pieces: [{ text: `${v.name} ${v.rate}%:` }],
            },
            'colspan',
            textR(v.total),
          ],
        }),
      ),
      // Type does not get refined in a regular ternary, and "as const" is *too* refined
      ...Array<TableRow>(Number(data.totals.rounding !== 0)).fill({
        type: 'normal',
        cells: [{ align: 'right' as const, pieces: [{ text: `${t.t.rounding} ${CURR.symbol}:` }] }, 'colspan', price(CURR, data.totals.rounding)],
      }),
      {
        type: 'sectionStart',
        cells: [null, { align: 'right', ...text(`${t.t.total} ${CURR.symbol}:`, { bold: true }) }, price(CURR, data.totals.total, { bold: true })],
      },
      {
        type: 'sectionStart',
        cells: [
          null,
          { align: 'right', ...text(`${t.t.discount} ${CURR.symbol}:`, { bold: true }) },
          price(CURR, data.totals.discount, { bold: true }),
        ],
      },
      ...data.payments.map(
        // Paid: (CARD 1234)
        (pmt): TableRow => {
          const typeName = t.t.types[pmt.typeNameRaw] ?? pmt.typeNameRaw;
          const showNumber = !pmt.isCard && pmt.cardType !== 'GIVEX';
          const label = (function getLabel() {
            if (showNumber && pmt.cardNumber) {
              return `${t.t.paid} (${typeName} ${pmt.cardNumber.replace(/[* ]/g, '')})`;
            }
            return `${t.t.paid} (${typeName})`;
          })();

          return {
            type: 'normal',
            cells: [{ align: 'right', ...text(`${label} ${CURR.symbol}:`) }, 'colspan', price(CURR, pmt.sum)],
          };
        },
      ),
    ],
  };
  return [productsTable, totalsTable];
};

/**
 * <pre>
 * D͟͞e͟͞s͟͞c͟͞r͟͞i͟͞p͟͞t͟͞i͟͞o͟͞n͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞Q͟͞u͟͞a͟͞n͟͞t͟͞i͟͞t͟͞y͟͞
 *  ͟ ͟ ͟ ͟     Coca cola            1x
 *  ͟ ͟ ͟ ͟     Fries                2x
 * Q͟͞u͟͞a͟͞n͟͞t͟͞i͟͞t͟͞y͟͞:͟͞ ͟͞2͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞ ͟͞S
 */
export const receiptProductsTableGiftReceipt = (
  data: ReceiptProductsTableData,
  { productsTable: translations } = mmReceiptTranslations.en,
  CURR: CurrencyUtil,
): PatchScript[number] => {
  const t = {
    h: translations.headers,
    t: translations.totals,
  };
  // eslint-disable-next-line
  /* eslint prettier/prettier: ["warn", { printWidth: 150 }] */
  const totalProducts = data.rows.reduce((acc, next) => acc + CURR.parse(next.amount), 0);

  return {
    type: 'table',
    columns: [
      { baseWidth: 10, weight: 1 },
      { baseWidth: 0, weight: 1 },
      { baseWidth: 8, weight: 0 },
    ],
    rows: [
      // header
      {
        type: 'header',
        cells: [text(t.h.description), 'colspan', textR(t.h.quantity)],
      },
      // Products
      ...data.rows.flatMap((r, i): TableRow[] => [
        {
          type: 'normal',
          cells: [text(data.rows[i].code ?? '____', { bold: true }), text(r.productName), textR(`${r.amount}x`)],
        },
      ]),
      // Quantity & subtotals & total & discount
      {
        type: 'sectionStart',
        cells: [text(`${t.t.quantity}: ${totalProducts}`), 'colspan', 'colspan'],
      },
    ],
  };
};
