import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ButtonGroup,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';

import { getCurrencyFormatter, getSetting } from 'reducers/configs/settings';
import { dismissType } from 'actions/Error';
import { validateEmail } from 'containers/Login/validations';
import { printReceipt } from 'actions/integrations/printer';
import { PluginComponent } from 'plugins';
import { afterSuccessfulPaymentConfirmation } from 'actions/Plugins';
import CloseButton from 'components/CustomButtons/CloseButton';
import Icon from 'components/Icon';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { sendInvoiceByEmail, startNewSale } from 'actions/sales';
import { getCloseConfirmationAfterPrint } from 'reducers/Confirmation';
import { softLogout } from 'actions/Login';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { Customer } from 'types/Customer';
import { SaveSalesDocumentResponse } from 'types/SalesDocument';
import { PaymentObj } from 'paymentIntegrations/types';
import { useAppDispatch } from 'reducers';

import ConfirmOptionButton from '../../components/Buttons/ConfirmOption';

export const useStyles = makeStyles({
  confirmation: {
    '& h5': {
      margin: 0,
    },
    '& .close-button': {
      fontSize: '35px',
      height: '20px',
      position: 'absolute',
      right: '5%',
      top: '5px',
      cursor: 'pointer',
    },
    '& [data-testid="email"]': {
      height: ' 100%',
      '& .MuiInputBase-root': {
        height: ' 100%',
      },
    },
  },
});

type Props = SaveSalesDocumentResponse & {
  payments?: PaymentObj[];
  customer?: Customer;
  invoiceID: string;
  invoiceNo: string;
  change?: number;
  type?: string;
  customNumber: string;
  resolve: () => void;
  reject: (value: unknown) => void;
  onClose: () => void;
};

const PaymentConfirmation: FC<Props> = ({
  payments,
  customer,
  invoiceID,
  invoiceNo,
  change = 0,
  type = '',
  customNumber,
  ...salesDocument
}) => {
  const { t } = useTranslation('payment');
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const autoClose = useSelector(
    getSetting('touchpos_autoclose_checkout_final_confirmaton_popup'),
  );
  const defaultAddresses = useSelector(
    getSetting('touchpos_default_send_receipt_to_email_addresses'),
  ).split('\n');
  const showInvoiceNumber = useSelector(
    getSetting('show_invoice_no_on_confirmation'),
  );
  const printLayawayReceiptTwoTimes = useSelector(
    getSetting('touchpos_print_layaway_two_times'),
  );
  const showChange = useSelector(getSetting('show_change_on_confirmation'));
  const logoutAfterSale = useSelector(
    getSetting('touchpos_autoidle_after_sale'),
  );
  const autoPrint = !useSelector(
    getSetting('touchpos_disable_default_print_the_receipt'),
  );
  const shouldCloseAfterPrint = useSelector(getCloseConfirmationAfterPrint);

  const customerEmail = customer?.email ?? '';
  const sendAddresses = autoClose
    ? []
    : [...defaultAddresses, ...[customerEmail]].filter(a => a.length);
  const [enteredAddress, setEnteredAddress] = useState(sendAddresses[0] ?? '');
  const format = useSelector(getCurrencyFormatter);

  const emailHelperText = useMemo(() => validateEmail(enteredAddress), [
    enteredAddress,
  ]);
  const isInvalidMail = !!emailHelperText.length;
  const emailIsEmpty = !enteredAddress.length;

  useEffect(() => {
    if (enteredAddress) {
      if (enteredAddress.includes(' ')) {
        setEnteredAddress(enteredAddress.replace(/\s/g, ''));
      }
    }
  }, [enteredAddress]);

  const logout = useCallback(async () => {
    await dispatch(softLogout());
  }, [dispatch]);

  const onPrint = useCallback(async () => {
    await dispatch(
      printReceipt({
        id: invoiceID,
        payments,
        invoiceID,
        invoiceNo,
        customNumber,
        ...salesDocument,
      }),
    )
      .then(() => {
        if (type === 'PREPAYMENT' && printLayawayReceiptTwoTimes) {
          dispatch(
            printReceipt({
              id: invoiceID,
              payments,
              invoiceID,
              invoiceNo,
              customNumber,
              ...salesDocument,
            }),
          );
        }
        if (shouldCloseAfterPrint) {
          dispatch(previousModalPage());
          if (logoutAfterSale) {
            dispatch(softLogout());
          }
        }
        dispatch(afterSuccessfulPaymentConfirmation());
      })
      .finally(() => {
        // TODO: SonarQube false alarm
        //   "Promise returned in function argument where a void return was expected."
        //   But returning a promise in finally is legal
        //   Source: according to https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/finally
        return (Promise.all([
          dispatch(dismissType('receipt_print')),
          dispatch(dismissType('Receipt email')),
        ]) as any) as void;
        // Should check if both these alert types are actually opened
        // and move their closing to the same level of abstraction
      });
  }, [
    dispatch,
    invoiceID,
    payments,
    invoiceNo,
    customNumber,
    salesDocument,
    type,
    printLayawayReceiptTwoTimes,
    shouldCloseAfterPrint,
    logoutAfterSale,
  ]);

  const startNewSaleAndClose = useCallback(() => {
    if (logoutAfterSale) return;
    dispatch(previousModalPage());
    dispatch(startNewSale());
  }, [dispatch, logoutAfterSale]);

  const wasAutoClosed = useRef(false);

  useEffect(() => {
    if (autoClose && !wasAutoClosed.current) {
      wasAutoClosed.current = true;
      (autoPrint ? onPrint() : Promise.resolve())
        .then(() => dispatch(previousModalPage()))
        .then(() => {
          if (logoutAfterSale) {
            logout();
          }
        });
    }
  }, [autoClose, autoPrint, dispatch, logout, logoutAfterSale, onPrint]);

  const finishSale = useCallback(() => {
    dispatch(previousModalPage());
    if (logoutAfterSale) logout();
  }, [dispatch, logoutAfterSale, logout]);

  const openReceiptPreview = () => {
    dispatch(
      openModalPage({
        component: mp.ReceiptPreview,
        props: { salesDocument: { invoiceID, invoiceNo, ...salesDocument } },
        isPopup: true,
        modalClassName: 'fit-content receipt-preview',
      }),
    );
  };

  useShortcut('F4', startNewSaleAndClose, 100);
  useShortcut('Escape', finishSale, 100);
  useShortcut('Enter', onPrint, 100);

  return (
    <div data-testid="confirmation" className={styles.confirmation}>
      <Box padding="1.5rem">
        <Box display="flex" flexDirection="column">
          <h4 data-testid="header">{t('confirmView.header')}</h4>
          <div>
            {showInvoiceNumber && (
              <h5 data-testid="invoice-nr">
                {t('confirmView.title', {
                  context: type?.toLowerCase(),
                  number: customNumber || invoiceNo || '',
                })}
              </h5>
            )}
          </div>
          <div>
            {showChange && (
              <h5 data-testid="invoice-change">
                {t('confirmView.change', { amount: format(change) })}
              </h5>
            )}
          </div>
          <Box flexGrow={1} />
          <CloseButton action={finishSale} className="close-button" />
        </Box>
      </Box>
      <Divider />
      <Box padding="1rem" display="flex" flexDirection="column" gridGap="1rem">
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('confirmView.inputs.email')}
              data-testid="email"
              type="text"
              value={enteredAddress}
              onChange={e => setEnteredAddress(e.target.value)}
              helperText={!emailIsEmpty ? emailHelperText : ''}
              error={!emailIsEmpty && isInvalidMail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="flip-horizontal icon_search" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <ButtonGroup fullWidth variant="contained" disableElevation>
              <ConfirmOptionButton
                action={openReceiptPreview}
                title={t('confirmView.buttons.previewReceipt')}
                data-testid="preview-button"
                color="grid_dark"
                icon={<Icon name="icon_search-2" />}
              />
              <ConfirmOptionButton
                action={() =>
                  dispatch(
                    sendInvoiceByEmail({
                      invoiceID,
                      enteredAddress,
                    }),
                  )
                }
                title={t('confirmView.buttons.emailReceipt')}
                data-testid="email-button"
                disabled={isInvalidMail}
                color="grid_dark"
                icon={<Icon name="icon_mail_alt" />}
              />
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <ConfirmOptionButton
              action={startNewSaleAndClose}
              disabled={logoutAfterSale}
              title={t('confirmView.buttons.newSale')}
              data-testid="new-sale-button"
              color="grid_light"
              shortcut="F4"
            />
          </Grid>
          <Grid item xs={3}>
            <ConfirmOptionButton
              title={t('confirmView.buttons.close')}
              color="grid_light"
              action={finishSale}
              data-testid="escape-button"
              shortcut="Esc"
            />
          </Grid>
          <Grid item xs={6}>
            <ConfirmOptionButton
              color="grid_green"
              action={onPrint}
              title={t('confirmView.buttons.printReceipt')}
              data-testid="print-button"
              shortcut="Enter"
            />
          </Grid>
        </Grid>
        <PluginComponent hookname="UIConfirmationCheckbox" />
        <PluginComponent
          hookname="UIConfirmationInputField"
          props={{ customer }}
        />
      </Box>
    </div>
  );
};

export default PaymentConfirmation;
