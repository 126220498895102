export default response => {
  const customers = response?.data?.result?.results ?? [];
  return mapCustomers(customers);
};

const mapCustomers = customers => {
  return customers.map(customer => mapCustomer(customer));
};

const mapCustomer = ({
  addresses,
  companyName,
  customerCardNumber,
  email,
  firstName,
  id,
  lastName,
  mobilePhone,
  phone,
  registryCode,
  homeStoreID,
  companyID,
}) => ({
  id,
  addresses,
  customerCardNumber,
  customerID: id,
  phone,
  customerType: firstName || lastName ? 'PERSON' : 'COMPANY',
  mobile: mobilePhone,
  firstName,
  companyName,
  lastName,
  code: registryCode,
  fullName: firstName || lastName ? `${firstName}, ${lastName}` : companyName,
  email,
  homeStoreID,
  companyID: companyID || 0,
});
