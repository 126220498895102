import { createSelector } from 'reselect';
import * as R from 'ramda';

import { RootState } from 'reducers';
import { SET_STORE_REGION } from 'constants/storeRegions';
import {
  PriceList,
  StoreRegion as StoreRegionResponse,
} from 'types/StoreRegion';
import { isPriceListActive } from 'utils';

import { getSelectedCustomerGroupHierarchyByID } from './customerSearch';

/**
 * Some accounts may have hundreds of price lists associated with store regions
 * To avoid keeping unused data in memory only save ids of active price lists
 * If more details are needed price lists can be taken from idb
 */
function getActivePriceListIds(priceLists?: PriceList[]): number[] {
  if (!priceLists?.length) return [];
  return R.pipe(
    R.filter(isPriceListActive),
    R.sortBy(R.prop('positionNumber')),
    R.pluck('priceListID'),
  )(priceLists);
}

type StoreRegion = Omit<
  StoreRegionResponse,
  'priceLists' | 'customerGroupPriceLists'
> & {
  priceLists: number[];
  customerGroupPriceLists: Record<string, number[]>;
};

type State = StoreRegion | null;

const initialState: State = null;

function storeRegion(state: State = initialState, action): State {
  switch (action.type) {
    case SET_STORE_REGION: {
      const storeRegion: StoreRegionResponse = action.payload;

      const customerGroupPriceLists = Object.fromEntries(
        storeRegion.customerGroupPriceLists
          ?.map(
            ({ customerGroupID, priceLists }) =>
              [customerGroupID, getActivePriceListIds(priceLists)] as const,
          )
          .filter(([, priceLists]) => !!priceLists.length) ?? [],
      );

      return {
        ...storeRegion,
        priceLists: getActivePriceListIds(storeRegion.priceLists),
        customerGroupPriceLists,
      };
    }
    default:
      return state;
  }
}

export default storeRegion;

export function getStoreRegion(state: RootState) {
  return state.storeRegion;
}

export const getStoreRegionPriceListIds = createSelector(
  getStoreRegion,
  storeRegion => storeRegion?.priceLists ?? [],
);

export const getStoreRegionCustomerGroupPriceListIds = createSelector(
  getStoreRegion,
  storeRegion => storeRegion?.customerGroupPriceLists ?? {},
);

/**
 * @see https://wiki.erply.com/article/1575-1-price-list-algorithm
 */
export const getSelectedCustomerGroupPriceListIds = createSelector(
  getStoreRegionCustomerGroupPriceListIds,
  getSelectedCustomerGroupHierarchyByID,
  (priceListsByGroup, customerGroupHierarchy) => {
    return customerGroupHierarchy
      .flatMap(groupID => priceListsByGroup[groupID])
      .filter(Boolean);
  },
);
