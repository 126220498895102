// internal
import { createSelector } from 'reselect';

import { getPluginSelector } from 'reducers/Plugins';
import WBUAPI from 'plugins/wbu/API/API';
import { getSelectedCustomer } from 'reducers/customerSearch';

import { pluginID } from '../constants';

type Customer = {
  customerID: string;
  dscExpiration?: Date;
  potential_savings?: string;
  lastPurchaseStartTime?: string;
  total_visits: string;
  total_sales: string;
  birth: string;
  homeStoreID: number;
  last_visit?: Date;
  dsc_current_savings: string;
  endDateTime: string;
  status: 'member' | 'non member' | 'active';
  startDateTime?: string;
};

const initState = {
  customer: undefined as Customer | undefined,
  loading: false,
  previousCustomer: {
    id: undefined as number | undefined,
    hasPremembership: false,
  },
};
type State = typeof initState;

// actions
const LOADING_CUSTOMER = `plugin ${pluginID}: loadingCustomer`;
const SET_CUSTOMER = `plugin ${pluginID}: setCustomer`;
const SET_PREVIOUS_CUSTOMER = `plugin ${pluginID}: setPreviousCustomer`;

export const setCustomer = (customer: Customer) => ({
  type: SET_CUSTOMER,
  payload: customer,
});

export const setPreviousCustomer = (
  previousCustomer: State['previousCustomer'],
) => ({
  type: SET_PREVIOUS_CUSTOMER,
  payload: previousCustomer,
});

// reducer
export const reducer = (
  state: State = initState,
  { type, payload }: { type: string; [any: string]: any },
): State => {
  switch (type) {
    case LOADING_CUSTOMER:
      return { ...state, customer: undefined, loading: true };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: payload,
        loading: false,
      };
    case SET_PREVIOUS_CUSTOMER:
      return {
        ...state,
        previousCustomer: payload,
      };
    default:
      return state;
  }
};

// selectors
/** Return extra data about the current customer that is stored in redux */
export const getWbuExtraData = createSelector(
  state =>
    getPluginSelector<State>(pluginID)(
      (state: any) => state.customer as Customer | undefined,
    )(state),
  state => getSelectedCustomer(state),
  (extraData, customer) =>
    String(customer?.customerID) === String(extraData?.customerID)
      ? extraData
      : undefined,
);

export const getIsWbuCustomerLoading = createSelector(
  state =>
    getPluginSelector<State>(pluginID)((state: State) => state.loading)(state),
  loading => loading,
);

export const getPreviousCustomer = createSelector(
  state =>
    getPluginSelector<State>(pluginID)(
      (state: State) => state.previousCustomer,
    )(state),
  previousCustomer => previousCustomer,
);

// thunk action
/**
 * Given a customerID, fetch extra data about the customer and store it in redux
 */
export const updateCustomer = (customerID: string) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: LOADING_CUSTOMER });
  const data = await Promise.all([
    dispatch(WBUAPI.getCustomerStats({ [customerID]: null })).then(
      ({ data: [data] }) => data,
    ),
    dispatch(WBUAPI.getCustomerMemberships(customerID)).then(data => ({
      ...data,
      dscExpiration: data.endDateTime ? new Date(data.endDateTime) : undefined,
    })),
  ])
    .then(([a, b]) => ({
      ...a,
      ...b,
    }))
    .catch(e => {
      console.error('Failed to load customer stats and memberships', e);
      return {} as any;
    });
  dispatch(setCustomer({ ...data, customerID }));
};
