import debug from 'debug';

export const pluginID = 'O3F taxProducts';
export const baseLog = debug('plugins').extend(pluginID);
export type Configuration = {
  warrantyProductGroupId: number;
  mainWarehouseId: number;
};

export const TEXTS = {
  confirmation: (name, price) => `Kas lisan ${name} ${price}€`,
  confirmationYes: 'Jah',
  confirmationNo: 'Ei',
  error: {
    attributesNotFound:
      'Lisagarantii baasmäära ja hinnaprotsendiga tootegruppi ei leitud',
  },
};
