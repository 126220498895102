/**
 * @fileoverview
 *
 * Error classes to use when parsing the request - Allows to distinguish detected errors (f.ex. resultcode wrong) from programming errors (tried to read property of undefined)
 * Also allows distinguishing errors from MS or terminal, although at the moment we don't do anything with this information yet
 */
import { JunctionAPIResponse } from '../types';

export class MSError extends Error {}

export class TerminalError extends Error {
  constructor(
    message: string,
    /** The response to print a receipt from, if a failure receipt needs to be printed */
    public printFrom?: JunctionAPIResponse,
  ) {
    super(message);
  }
}
