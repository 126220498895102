import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import UIButton from 'components/UIElements/UIButton';
import { Check, Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import InputField from 'components/FieldTypes/InputField';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { PosPlugin } from 'plugins/plugin';
import {
  CompanyConfiguration as CC,
  Configuration as C,
} from 'plugins/pnp/carSales/index';
import { getAllWarehouses } from 'reducers/warehouses';
import { unique } from 'utils/tsHelpers';
import { addSuccess, addWarning } from 'actions/Error';

const inRange = (min: number, value: number, max: number) =>
  Math.min(max, Math.max(min, value));

const columns = [
  ['vin', 'VIN number'],
  ['cylinder', 'Cylinder count'],
  ['carSide', 'Side of car'],
  ['customer', 'Customer name'],
];
const columnsDict = Object.fromEntries(columns);

export const CompanyConfiguration: NonNullable<
  PosPlugin<C, CC>['ComponentConfigurationByLevel']
>['Company'] = ({ current, save }) => {
  const [url, setUrl] = useState(current?.url ?? '');
  const [token, setToken] = useState(current?.token ?? '');

  const [requirementsByState, setRequirementsByState] = useState(
    current?.requirementsByStoreGroup ?? {},
  );

  const states = unique(
    useSelector(getAllWarehouses)
      .flatMap(wh => wh.state)
      .filter(gn => gn !== ''),
  );
  const [state, selectState] = useState(states[0]);

  const dispatch = useDispatch();

  const importConfig = (text?: string) => {
    if (!text) return;
    const lines = text.split('\n').map(line => line.split(/[,\t]/));
    const validLines = lines
      .filter(line => line.length > 2)
      .filter(line => /^[YN]$/.test(line[2]?.trim().toUpperCase()));
    const invalidLines = lines.filter(l => !validLines.includes(l));

    const data = {};
    lines.forEach(line => {
      const [state, item, ...rest] = line;
      data[state] = data[state] ?? {};
      data[state][item] = Object.fromEntries(
        columns
          .map(([k], i) => [k, rest[i]?.trim().toUpperCase() === 'Y'])
          .filter(([k, v]) => v),
      );
    });
    dispatch(
      addSuccess(
        `Imported ${validLines.length}/${lines.length} rows from spreadsheet`,
      ),
    );
    if (invalidLines.length) {
      console.warn(`Invalid lines (${invalidLines.length}):`, invalidLines);
      dispatch(
        addWarning(
          `Logged ${invalidLines.length} invalid lines to console (F12)`,
        ),
      );
    }
    setRequirementsByState(data);
  };

  const fileInput = useRef<HTMLInputElement | null>(null);
  return (
    <div>
      <UIButton
        variant="POS"
        action={() => {
          save({ url, token, requirementsByStoreGroup: requirementsByState });
          dispatch(previousModalPage());
        }}
        text="SAVE"
      />
      <h3>API configuration</h3>
      <InputField
        onChange={e => setUrl(e.target.value)}
        value={url}
        title="CFJC API URL"
      />
      <InputField
        onChange={e => setToken(e.target.value)}
        value={token}
        title="CFJC API token"
      />
      <hr />
      <h3>Requirements configuration</h3>
      <h4>Import</h4>
      Import configuration from spreadsheet (.csv or .tsv):
      <input
        type="file"
        ref={fileInput}
        onChange={e => {
          if (!fileInput.current) return;
          const file = fileInput.current.files?.[0];
          if (!file) return;
          const reader = new FileReader();
          reader.onload = e => {
            if (e.target?.result !== undefined) {
              importConfig(e.target?.result as string);
            }
          };
          fileInput.current.value = '';
          reader.readAsText(file, 'UTF-8');
        }}
      />
      <br />
      <textarea
        style={{ width: '100%' }}
        value=""
        onChange={e => {
          importConfig(e.target.value);
        }}
        placeholder="Or paste the data in here"
      />
      <h4>Review</h4>
      <Validate reqs={requirementsByState} />
      <InputField
        title="State"
        type="select"
        options={states}
        onChange={e => selectState(e.target.value)}
        value={state}
      />
      {states.length === 0 ? (
        'No store groups configured on this account'
      ) : (
        <WarehouseConfiguration
          current={requirementsByState?.[state]}
          save={e => setRequirementsByState(c => ({ ...c, [state]: e }))}
        />
      )}
    </div>
  );
};

export const WarehouseConfiguration = ({
  current,
  save,
}: {
  current: any;
  save: (any) => void;
}) => {
  const [codes, setCodes] = useState('');
  const productRequirements = current ?? {};
  const setProdRequirements = k => {
    save({ ...current, productRequirements: k });
  };
  const onChangeProdReqs = (value, k, v) => {
    setProdRequirements({
      ...productRequirements,
      [value]: {
        ...productRequirements[value],
        [k]: v,
      },
    });
  };

  const [pPage, setPPage] = useState(1);
  const [newName, setNewName] = useState('');

  useEffect(() => {
    if (productRequirements[newName] || newName === '') {
      const expectedIndex = Object.keys(productRequirements).findIndex(
        p => p === newName,
      );
      if (expectedIndex < 0) return;
      const expectedPage = Math.floor(expectedIndex / 20) + 1;
      if (pPage !== expectedPage) {
        setPPage(expectedPage);
      }
    }
  }, [newName, productRequirements]);

  const lastPage = Math.floor(Object.keys(productRequirements).length / 20) + 1;
  // prettier-ignore
  return (
    <div>
      <Table size="sm">
        <tbody>
        <tr>
          <th>Product Code</th>
          <th>Require VIN</th>
          <th>Require Cylinder count</th>
          <th>Require Car side</th>
          <th>Require Customer</th>
        </tr>
        {/* <UIButton text="Add" action={addColumn}/> */}
        {Object.entries(productRequirements)?.slice((pPage - 1) * 20, (pPage) * 20).map(([code, value]) => (
          <tr className={code === newName ? "table-info" : ""} key={code}>
            <Ctx.Provider
              value={{
                values: productRequirements[code] ?? {},
                onChange: (k, v) => onChangeProdReqs(code, k, v)
              }}
            >
              {/* <td> */}
              {/*  <UIButton */}
              {/*    text="remove" */}
              {/*    variant="warning" */}
              {/*    action={() => { */}
              {/*      const {[code]:_, ...rest} = productRequirements */}
              {/*      setProdRequirements(rest) */}
              {/*    }} */}
              {/*  /> */}
              {/* </td> */}
              <td>{code}</td>
              {columns.map(([key]) => <td><Check disabled={true} name={key} size="md" /></td>)}
            </Ctx.Provider>
          </tr>
        ))}
        <tr />
        </tbody>

      </Table>
      <div style={{ width: "20ch", display: Object.entries(productRequirements)?.length > 20 ? "block" : "none" }}>
        <InputField
          title="Page"
          // prependTitle={<UIButton text="-" variant="info" disabled={pPage === 1} action={() => setPPage(c => Math.max(1,c-1))}/>}
          // appendTitle={[`out of ${lastPage}`, <UIButton text="+" variant="info" disabled={pPage >= lastPage} action={() => setPPage(c => c+1)}/>]}
          appendTitle={`out of ${lastPage}`}
          size="sm"
          value={pPage}
          type="number"
          style={{ width: "20ch" }}
          min={1}
          max={lastPage}
          onChange={e => setPPage(inRange(1, e.target.value, lastPage))} />
      </div>
      {/* <InputField */}
      {/*  title="Product code:" */}
      {/*  value={newName} */}
      {/*  onChange={e => setNewName(e.target.value)} */}
      {/*  appendTitle={<UIButton */}
      {/*    variant={productRequirements[newName] ? 'warning' : 'success'} */}
      {/*    text={productRequirements[newName] ? 'Already in list' : 'Add'} */}
      {/*    disabled={productRequirements[newName] || newName === ''} */}
      {/*    action={() => { */}
      {/*      setNewName(''); */}
      {/*      setPPage(lastPage); */}
      {/*      setProdRequirements({ ...productRequirements, [newName]: {} }); */}
      {/*    }} */}
      {/*  />} */}
      {/* /> */}

      {/* <UIButton text="Append from clipboard" action={() => { */}
      {/*  navigator.clipboard.readText() */}
      {/*    .then(text => Object.fromEntries( */}
      {/*      text.split("\n") */}
      {/*        .map(line => { */}
      {/*          const [code, ...rest] = line.split(/[,\t]/) */}
      {/*          const [vin, cylinder, carSide, customer] = rest.map(Number).map(Boolean) */}
      {/*          return [code, {vin, cylinder, carSide, customer}] */}
      {/*        }) */}
      {/*        .filter(([code]) => code) */}
      {/*    )).then(dict => setProdRequirements(({...productRequirements, ...dict}))); */}
      {/**/}
      {/* }}/> */}
      <br />
      <br />
    </div>
  );
};

const Validate = ({ reqs }) => {
  const states = unique(
    useSelector(getAllWarehouses)
      .flatMap(wh => wh.state)
      .filter(gn => gn !== '')
      .map(gn => gn.toUpperCase()),
  );
  const confStates = Object.keys(reqs);
  const invalidStates = confStates.filter(
    sg => !states.includes(sg.toUpperCase()),
  );

  return (
    <div>
      {invalidStates.length === 0 ? (
        <span style={{ color: 'green' }}>All states are valid</span>
      ) : (
        <>
          <span style={{ color: 'red' }}>
            {invalidStates.length} / {confStates.length} states in conf not
            found
          </span>
          <span style={{ color: 'red' }}>
            {invalidStates.length > 10
              ? ' The first 10 missing states are:'
              : null}
            <ul>
              {invalidStates.slice(0, 10).map(t => (
                <li>{t}</li>
              ))}
            </ul>
          </span>
        </>
      )}
    </div>
  );
};

export const PosConfiguration = WarehouseConfiguration;
