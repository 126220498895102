import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { ErplyAttributes } from 'utils';
import { getProductByID } from 'reducers/cachedItems/products';

export const LotteryProductPlugin: PosPlugin = {
  id: 'lotteryProduct',
  name: 'Lottery payout product',
  info:
    'Products marked with attribute `is_lottery_product`=1 will get added to the cart with quantity -1 by default',
  onAddProduct: {
    on: (p, ap) => async (dispatch, getState) => {
      const attributes = new ErplyAttributes(
        getProductByID(ap.product.productID)(getState()).attributes,
      );
      const lotteryProductAttribute = attributes.get('is_lottery_product');

      if (Number(lotteryProductAttribute) !== 1) return ap;
      return R.assocPath(['product', 'amount'], -1, ap);
    },
  },
};
