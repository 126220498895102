import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Tab, Tabs } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ScannerSearchResults } from 'actions/scanner';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';

import ProductsResults from './ProductsResults';
import CustomersResults from './CustomersResults';

interface TabPanelProps {
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
};

const ScannerMultiResults: FC<{ results: ScannerSearchResults }> = ({
  results,
}) => {
  const { t } = useTranslation('scannerSelection');
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(results.products.length ? 0 : 1);

  const handleChange = (e: React.ChangeEvent<{}>, newValue: any) => {
    setActiveTab(newValue);
  };

  const close = () => {
    dispatch(closeModalPage());
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t('title')}</Modal.Title>
        <CloseButton action={() => close()} />
      </Modal.Header>
      <Modal.Body>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="scanning results"
        >
          <Tab
            style={{ outline: 'none' }}
            label={t('products.label')}
            disabled={!results.products?.length}
            id="scanned-products-results"
          />
          <Tab
            style={{ outline: 'none' }}
            label={t('customers.label')}
            disabled={!results.customers?.length}
            id="scanned-customers-results"
          />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <ProductsResults products={results.products} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <CustomersResults customers={results.customers} />
        </TabPanel>
      </Modal.Body>
    </>
  );
};

export default ScannerMultiResults;
