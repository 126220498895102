import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import Icon from 'components/Icon';
import { resetShoppingCart } from 'actions/ShoppingCart';
import ListItem from 'components/ListItem';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getProductsUniversal } from 'actions/productsDB';
import { Product } from 'types/Product';

import { addSuccess } from '../../../actions/Error';

import singlePrompt, { buildWaiverAttributes } from './utils';
import EWaiverModal from './components/EwaiverModal';
import waiverReducer, {
  getWaiversReduxState,
  hasAdmissionFee,
} from './rdx/reducers';
import { IS_AN_ADMISSION, RESET_SELECTED_WAIVERS } from './rdx/actionTypes';
import {
  assignReceiptToWaiver,
  validateSelectedWaiverAndFinalizeSale,
} from './rdx/actions';

const EWaiver: PosPlugin = {
  id: 'pnp-ewaiver',
  name: '[PNP] - eWaiver',
  keywords: ['pnp', 'e', 'e-', 'e-waiver', 'waiver', 'e waiver'],
  info: 'eWaiver plugin',
  getStatus: state => {
    const currentConfig: any =
      getPluginConfiguration('pnp-ewaiver')(state) ?? {};

    const { admissionProductCode, apiKey } = currentConfig;

    if (!admissionProductCode || admissionProductCode === '') {
      return {
        type: 'error',
        message: 'Missing admissions product code',
      };
    }

    if (!apiKey || apiKey === '') {
      return {
        type: 'error',
        message: 'Missing api key',
      };
    }

    return {
      type: 'valid',
      message: 'Ready',
    };
  },
  ComponentConfiguration: ({ byLevel: { company } }) => {
    const dispatch = useDispatch();
    const [admissionProductCode, setAdmissionProductCode] = useState(
      company.current?.admissionProductCode || '',
    );
    const [apiKey, setApiKey] = useState(company.current?.apiKey || '');

    const handleSave = () => {
      company.save({
        admissionProductCode,
        apiKey,
      });
      dispatch(addSuccess('Saved'));
    };

    return (
      <>
        <Form.Group>
          <Form.Label>Write the code of the admission product</Form.Label>
          <InputField
            type="text"
            value={admissionProductCode}
            onChange={event => setAdmissionProductCode(event.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Write the api key</Form.Label>
          <InputField
            type="password"
            value={apiKey}
            onChange={event => setApiKey(event.target.value)}
          />
        </Form.Group>

        <UIButton variant="POS" type="button" text="Save" action={handleSave} />
      </>
    );
  },
  combineConfiguration: (c = {}, w = {}, p = {}, u) => {
    return {
      admissionProductCode: '',
      apiKey: '',
      ...c,
    };
  },
  reduxReducer: waiverReducer,
  components: {
    EWaiverModal,
  },
  UIQuantityActionsContainer: ({ children }) => {
    const waiversState = useSelector(getWaiversReduxState);
    return <>{waiversState.isAnAdmission ? <></> : children}</>;
  },
  UIProductAmountInProductOrderEdit: ({ children }) => {
    const waiversState = useSelector(getWaiversReduxState);
    return <>{waiversState.isAnAdmission ? <></> : children}</>;
  },
  UIProductAmountInShoppingCart: (props: any) => {
    const { amount, children } = props;
    const { isAnAdmission } = useSelector(getWaiversReduxState);

    return (
      <>{isAnAdmission ? <td className="amount">{amount}</td> : children}</>
    );
  },
  UIDeleteProductShortcut: ({ children, order, isMobileView }) => {
    const dispatch = useDispatch();
    const { isAnAdmission } = useSelector(getWaiversReduxState);

    return (
      <>
        {isAnAdmission ? (
          <td className="icon">
            {order?.computed ? null : (
              <Icon
                className={isMobileView ? 'ml-2 float-right' : ''}
                name="erply_delete_color"
                action={e => {
                  dispatch(resetShoppingCart());
                  e.stopPropagation();
                }}
              />
            )}
          </td>
        ) : (
          children
        )}
      </>
    );
  },
  onAddProduct: {
    after: (params, { product }) => async (dispatch, getState) => {
      const state = getState();

      const currentConfig: any =
        getPluginConfiguration('pnp-ewaiver')(state) ?? {};
      const {
        products: [currentProduct],
      } = ((await dispatch(
        getProductsUniversal({ productID: product.productID }),
      )) as unknown) as {
        products: Product[];
        productsDict: Record<string | number, Product>;
        total: number;
      };
      const selectedCodes =
        currentConfig.admissionProductCode
          ?.split(',')
          ?.map(code => code.trim()) || [];

      const admissionProductInCart = hasAdmissionFee(state);
      const productsInCart = getProductsInShoppingCart(getState());

      if (admissionProductInCart) {
        dispatch({ type: IS_AN_ADMISSION, payload: true });
      } else {
        dispatch({ type: IS_AN_ADMISSION, payload: false });
      }

      if (
        selectedCodes.length &&
        selectedCodes.includes(currentProduct?.code)
      ) {
        dispatch(
          singlePrompt({
            admissionProduct: admissionProductInCart,
            productsInCartLength: productsInCart.length,
          }),
        );
      }
    },
  },
  onSaveSalesDocument: {
    on: (p: any, ep: any) => async (dispatch, getState) => {
      const state = getState();
      const { isAnAdmission } = getWaiversReduxState(state);
      const currentConfig: any =
        getPluginConfiguration('pnp-ewaiver')(state) ?? {};
      let attributesToStore;

      if (isAnAdmission) {
        const saveRequest = ep.filter(
          request => request.requestName === 'saveSalesDocument',
        );

        const invoiceNumber =
          saveRequest[0].invoiceNo || saveRequest[0].customNumber;

        // will assign current invoice to the waivers API, if the waiver has been taken already, this will mean that the assignment was unssuccesful
        const assignmentResults: any = await dispatch(
          assignReceiptToWaiver(invoiceNumber),
        );

        const successfullAssignments = assignmentResults.filter(
          result => result.success,
        );
        const initialSuccessfulAssignments = successfullAssignments.map(
          result => result.waiver,
        );

        const unsuccesfulAssignments = assignmentResults.filter(
          result => result.success === false,
        );

        if (unsuccesfulAssignments.length > 0) {
          const secondAttempt = async () => {
            return dispatch(
              singlePrompt({
                isSecondAttempt: true,
                invoiceNumber,
                apiKey: currentConfig.apiKey,
              }),
            );
          };

          const promiseMap = (inputValues, mapper) => {
            const reducer = accumulator =>
              accumulator.then(acc =>
                mapper().then(result => {
                  return acc.push(result) && acc;
                }),
              );

            return inputValues.reduce(reducer, Promise.resolve([]));
          };

          await promiseMap(unsuccesfulAssignments, secondAttempt).then(
            waiversAssignedOnSecondAttempt => {
              if (waiversAssignedOnSecondAttempt.length > 0) {
                attributesToStore = buildWaiverAttributes([
                  ...initialSuccessfulAssignments,
                  ...waiversAssignedOnSecondAttempt,
                ]);
              } else {
                attributesToStore = buildWaiverAttributes([
                  ...initialSuccessfulAssignments,
                ]);
              }
            },
          );
        } else {
          attributesToStore = buildWaiverAttributes(
            initialSuccessfulAssignments,
          );
        }
      }

      return ep.map(request => {
        if (request.requestName === 'saveSalesDocument') {
          return { ...request, ...attributesToStore };
        }
        return request;
      });
    },
  },
  onResetShoppingCart: {
    after: () => async dispatch => {
      dispatch({ type: RESET_SELECTED_WAIVERS });
      dispatch({ type: IS_AN_ADMISSION, payload: false });
    },
  },
  UIMobilePaymentsModalPayButton: ({ children, disabled, confirmHandler }) => {
    const { t } = useTranslation('payment');
    const dispatch = useDispatch();
    const [manualDisable, toggleManualDisable] = useState(false);
    const { isAnAdmission } = useSelector(getWaiversReduxState);

    const payAdmission = async () => {
      toggleManualDisable(true);
      await dispatch(validateSelectedWaiverAndFinalizeSale(confirmHandler));
      toggleManualDisable(false);
    };

    return (
      <>
        {isAnAdmission ? (
          <UIButton
            disabled={manualDisable || disabled}
            text={t('buttons.confirmPayment')}
            block={true}
            action={payAdmission}
            className="confirm-payment"
          />
        ) : (
          children
        )}
      </>
    );
  },
  UIPaymentsModalPayButton: ({ children, disabled, confirmHandler }) => {
    const dispatch = useDispatch();
    const [manualDisable, toggleManualDisable] = useState(false);
    const { isAnAdmission } = useSelector(getWaiversReduxState);

    const payAdmission = async () => {
      toggleManualDisable(true);
      await dispatch(validateSelectedWaiverAndFinalizeSale(confirmHandler));
      toggleManualDisable(false);
    };

    return (
      <>
        {isAnAdmission ? (
          <ListItem
            disabled={manualDisable || disabled}
            variant="grid_green"
            action={payAdmission}
            data-testid="confirm-payment-button"
          >
            <span className="icon_check" />
            <span data-testid="confirm-payment-shortcut" className="func-key">
              F8
            </span>
          </ListItem>
        ) : (
          children
        )}
      </>
    );
  },
};

export default EWaiver;
