import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isNil, isEmpty } from 'ramda';

import { getUISetting } from 'reducers/configs/settings';

const useUpdateSettingInLS = (cafaKey, lsKey) => {
  const cafaSetting = useSelector(getUISetting(cafaKey));
  const lsSettingExists = !isNil(localStorage.getItem(lsKey));
  useEffect(() => {
    if (!isNil(cafaSetting) && !isEmpty(cafaSetting)) {
      localStorage.setItem(lsKey, String(cafaSetting));
    } else if (lsSettingExists && isNil(cafaSetting)) {
      localStorage.removeItem(lsKey);
    }
  }, [cafaSetting, lsSettingExists]);
};

export default useUpdateSettingInLS;
