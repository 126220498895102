import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button } from 'react-bootstrap';

import './Options.sass';
/**
 * List of togglable options to be selected among
 *
 */

const Options = ({
  options = [],
  selectedOptions = [],
  title = 'Options',
  onChange,
}) => {
  const [opened, setOpened] = useState(false);
  const save = () => {
    setOpened(false);
  };

  const optionsStyle = {
    maxHeight: '400px',
    display: 'flex',
    flexWrap: 'wrap',
  };
  return (
    <div className="options">
      <div className="options-buttons">
        <Button onClick={() => setOpened(!opened)}>
          {opened ? 'Close' : title}
        </Button>
        {opened && (
          <Button variant="warning" onClick={save}>
            Hide
          </Button>
        )}
      </div>
      <Collapse in={opened}>
        <div
          className="options-content"
          style={opened ? optionsStyle : { display: 'none' }}
        >
          {options.map(({ key, title, notToggable }) => (
            <div key={key} style={{ width: '50%' }}>
              <label style={{ width: '100%' }}>
                <input
                  type="checkbox"
                  onChange={e => (notToggable ? null : onChange(e.target.name))}
                  checked={selectedOptions.includes(key)}
                  name={key}
                />
                {` ${title}`}
              </label>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

Options.propTypes = {
  /** array of all the keys of selected options */
  selectedOptions: PropTypes.array,
  /** handler to be executed when the save button is clicked */
  onSave: PropTypes.func,
  /** Title for the Options block */
  title: PropTypes.string,
  /** event handler called when an checkbox is checked/unchecked */
  onChange: PropTypes.func.isRequired,
  /** array of all the options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** unique key of the option */
      key: PropTypes.string.isRequired,
      /** title / name of the option */
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Options;
