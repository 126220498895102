import { EFSTARequestTransaction } from '../requestType';

/**
 * Mod: (Modifier) Discount or rebate
 *
 * Adv: (Advance payment)
 *
 * Vou: (Voucher)
 *
 * Tip: (Tipping money)
 *
 * Dep: (Deposit ⚠)
 *
 * OpI: (Open item)
 * @see https://public.efsta.net/efr/DE/collection_business_cases.pdf#page=4
 */
export enum DEPosType {
  /** Discount or rebate */
  Mod = 'Mod',
  /** Advance payment */
  Adv = 'Adv',
  /** Voucher */
  Vou = 'Vou',
  /** Tipping money */
  Tip = 'Tip',
  /** Deposit ⚠ */
  Dep = 'Dep',
  /** Open item */
  OpI = 'OpI',
}

/**
 * <table>
 *   <tr><th>0</th><td>cash</td></tr>
 *   <tr><th> 1</th><td>non-cash (only if the POS does not know any further differentiation)</td></tr>
 *   <tr><th> 2</th><td>none</td></tr>
 *   <tr><th> 3</th><td>debit card</td></tr>
 *   <tr><th> 4</th><td>credit card</td></tr>
 *   <tr><th> 5</th><td>electronic service provider</td></tr>
 *   <tr><th> 6</th><td>prepaid card</td></tr>
 *   <tr><th> 7</th><td>voucher</td></tr>
 *   <tr><th> 8</th><td>Open balance ( claim)</td></tr>
 * </table>
 * @see https://public.efsta.net/efr/DE/collection_business_cases.pdf#page=6
 */
export enum DEPayGroup {
  /** cash */
  CASH = '0',
  /** non-cash (only if the POS does not know any further differentiation) */
  NONCASH = '1',
  /** none */
  NONE = '2',
  /** debit card */
  DEBIT = '3',
  /** credit card */
  CREDIT = '4',
  /** electronic service provider */
  ESP = '5',
  /** prepaid card */
  CARD_PREPAID = '6',
  /** voucher */
  VOUCHER = '7',
  /** Open balance ( claim) */
  OPEN_BALANCE = '8',
}

type WithTransactionID = {
  ESR?: {
    /** Transaction ID */
    TID: string;
  };
};
type Tra = EFSTARequestTransaction['Tra'] & {
  ESR?: {
    PosA?: {
      PTY?: DEPosType;
      Cat?: string;
      CatN?: string
    }[];
    PayA?: {
      PayG: DEPayGroup;
      CC?: string;
      FAmt?: string;
    }[];
    /**
     * Can be used to sign training transactions
     * or other non fiscal transactions.
     * @see https://public.efsta.net/efr/DE/collection_business_cases.pdf#page=5
     */
    NFS?:
      | 'Z'
      | 'DIFF'
      | 'PAY'
      | 'TRANSFER'
      | 'INI'
      | 'CAPITAL'
      | 'WAGE'
      | 'ORDER'
      | 'INVOICE'
      | 'AVTransfer'
      | 'ABORT';
    /** @summary Customer */
    Ctm: {
      /**
       * @summary Customer
       * @example "983248"
       */
      CN: string,
      /**
       * @summary Category
       * @example "Mitarbeiter"
       */
      Cat: string,
      /**
       * @summary Name
       * @example "Max Mustermann"
       */
      Nam: string
      /** @summary Name 2 */
      Nam2: string
      /**
       * @summary Address
       * @example "Musterstraße 10"
       */
      Adr: string,
      /** @summary Address 2 */
      Adr2: string,
      /**
       * @summary Postal Code
       * @example "12345"
       */
      Zip: string,
      /**
       * @summary City
       * @example "Musterstadt"
       */
      City: string,
      /**
       * @summary Country
       * @example "DE"
       */
      Ctry: string,
      /**
       * @summary VAT Number
       * @example "DE123456789"
       */
      TaxId: string,
    }
  },
};
export type DEStartRequestType = { TraS: Tra };
export type DEUpdateRequestType = {
  TraV: Tra & WithTransactionID;
};
export type DEFinishRequestType = {
  Tra: Tra & WithTransactionID;
};
