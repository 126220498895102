import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import { PosPlugin } from 'plugins/plugin';

import { getCFRState } from '../rdx';

const UIReturnTableExtraData: PosPlugin['UIReturnTableExtraData'] = ({
  returnDocument,
}) => {
  const { canPerformCFRReturn } = useSelector(getCFRState);

  const { t } = useTranslation('return');

  if (!canPerformCFRReturn) return null;

  const showLocation = !!returnDocument.warehouseName;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('headers.number')}</TableCell>
          <TableCell>{t('headers.date')}</TableCell>
          {showLocation && <TableCell>{t('headers.location')}</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell data-testid="document-number">
            {returnDocument.number}
          </TableCell>
          <TableCell data-testid="document-date">
            {returnDocument.date}
          </TableCell>
          {showLocation && (
            <TableCell data-testid="document-location">
              {returnDocument.warehouseName}
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default UIReturnTableExtraData;
