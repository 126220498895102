import Axios, { AxiosInstance } from 'axios';
import * as R from 'ramda';

import { posAddOnMessage } from 'utils/hooks/useWrapper';

let endpoint;
export const updateEndpoint = url => {
  endpoint = url ? `${url}api/v1/manage` : null;
};

const ax: AxiosInstance = Axios.create();
/** RequestID to send with wrapper messages */
let lastRequestID = 0;

const moveQuantityToDescription = (i: CayanItem) =>
  R.evolve({
    Description: d => `x${i.Quantity} ${d}`,
    // The TSYS LID will automatically multiple amount with quantity,
    // so if we are reducing the quantity
    // then we need to correspondingly raise the amount
    Quantity: () => '1',
    // prettier-ignore
    Amount: R.pipe(
      Number,
      R.multiply(Number(i.Quantity)),
      a => a.toFixed(2),
    )
  })(i);

const sanitizeCayanItem: (i: CayanItem) => CayanItem = R.pipe(
  // Restrict amount to 3 characters (if over, move to description)
  R.when(i => i.Quantity.length > 3, moveQuantityToDescription),
  // Slice off end of description if too long
  R.evolve<CayanItem>({
    Description: R.slice(0, 35),
  }),
);

const makeWrapperRequest = (type, payload) => {
  // Can replace with lastRequestID += 1?
  const requestID = `cayanCustomerDisplay:${lastRequestID++}`;
  const { port1 } = window.wrapperMessageChannel;
  port1.postMessage({
    type,
    request: requestID,
    payload,
  });
  return new Promise((resolve, reject) => {
    const listener = ({ data: msg }) => {
      if (msg.requestID === requestID) {
        if (msg.payload.resultStatus === 'Success') {
          resolve(msg);
        } else {
          reject(msg);
        }
        port1.removeEventListener('message', listener);
      }
    };
    port1.addEventListener('message', listener);
  });
};

const makeRequest = <Response>(data: any) => {
  if (!window.wrapperInfo) {
    return ax.post<{
      utcDateTime: string;
      recordsCount: number;
      records: Response[];
    }>(endpoint, {
      manageType: 'customerDisplay',
      data: JSON.stringify(data),
    });
  }
  return makeWrapperRequest('payment:customerDisplayIn', JSON.stringify(data));
};

export const startTransaction = (Order: string) =>
  makeRequest({
    Action: 'StartOrder',
    Order,
  });

/** Clear customer display with the implication that the sale did not happen */
export const cancelTransaction = (Order: string) => {
  if (!window.wrapperInfo) {
    return ax
      .post<{
        utcDateTime: string;
        recordsCount: number;
        records: Response[];
      }>(endpoint, { manageType: 'cancelTransaction' })
      .then(() => {});
  }

  return makeWrapperRequest('payment:cancel', { current: true });
};

/** Clear customer display with the implication that the sale happened externally to the device */
export const endTransaction = (Order: string) =>
  makeRequest({
    Action: 'EndOrder',
    Order,
    ExternalPaymentType: 'Cash',
  });

type ErplyItem = {
  orderIndex: any;
  productID: string;
  code2: string;
  amount: string;
  name: string;
  finalPrice: string;
  finalPriceWithVAT: string;
  rowTotal: string;
  rowVATTotal: string;
};
export type CayanItem = {
  ItemID: string;
  Type: string;
  TypeValue: string;
  UPC: string;
  Quantity: string;
  Description: string;
  Amount: string;
  TaxAmount: string;
  Category: string;
  DisplayOverride?: string;
};
export type CayanTotals = {
  OrderTotal: string;
  OrderTax: string;
};
export const toCayan = (item: ErplyItem): CayanItem => ({
  ItemID: String(item.orderIndex),
  Type: 'Sku',
  TypeValue: String(item.productID),
  UPC: item.code2,
  Quantity: String(item.amount),
  Description: item.name ?? '',
  Amount: Number(item.finalPrice).toFixed(2),
  TaxAmount: (Number(item.finalPriceWithVAT) - Number(item.finalPrice)).toFixed(
    2,
  ),
  Category: 'None',
});

export const addItem = (Order: string, item: CayanItem, totals: CayanTotals) =>
  makeRequest<{ resultStatus: 'Success'; additionalData: { ItemID: string } }>({
    Action: 'AddItem',
    Order,
    ...sanitizeCayanItem(item),
    ...totals,
  });

export const updateItem = (
  Order: string,
  itemID: string,
  item: CayanItem,
  totals: CayanTotals,
) =>
  makeRequest<{ resultStatus: 'Success'; additionalData: { ItemID: string } }>({
    Action: 'UpdateItem',
    Order,
    TargetItemID: itemID,
    // @ts-ignore
    ...R.pipe(sanitizeCayanItem, R.dissoc('ItemID'))(item),
    ...totals,
  });

export const deleteItem = (
  Order: string,
  itemID: string,
  totals: CayanTotals,
) =>
  makeRequest<{ resultStatus: 'Success'; additionalData: { ItemID: string } }>({
    Action: 'DeleteItem',
    Order,
    TargetItemID: itemID,
    ...totals,
  });

export const deleteAll = (Order: string) =>
  makeRequest<{ resultStatus: 'Success' }>({
    Action: 'DeleteAllItems',
    Order,
    RetainPaymentData: 'false',
    OrderTotal: '0.00',
    OrderTax: '0.00',
  });

export const updateTotal = (Order: string, totals: CayanTotals) =>
  makeRequest<{ resultStatus: 'Success'; additionalData: { ItemID: string } }>({
    Action: 'UpdateTotal',
    Order,
    ...totals,
  });
