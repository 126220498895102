import * as c from 'constants/integrations';

const initState = {
  currentIntegration: '',
  status: '',
};

export default (state = initState, { type, payload }) => {
  switch (type) {
    case c.SET_INTEGRATION_TYPE:
      return { ...state, currentIntegration: payload };
    case c.SET_INTEGRATION_STATUS:
      return { ...state, status: payload };
    default:
      return state;
  }
};

export function getIntegrationType(state) {
  return state.configs.integrations.currentIntegration;
}
export function getIntegrationStatus(state) {
  return state.configs.integrations.status;
}
