export default [
  {
    key: 'prepaymentPercent',
    type: 'text',
    disabled: false,
  },
  {
    key: 'advancePayment',
    type: 'text',
    disabled: false,
  },
  {
    key: 'startDate',
    type: 'date',
    disabled: true,
  },
  {
    key: 'expiryDate',
    type: 'date',
    disabled: false,
  },
  {
    key: 'invoiceTotal',
    type: 'text',
    disabled: true,
  },
  {
    key: 'outstandingAmount',
    type: 'text',
    disabled: true,
  },
  {
    key: 'paid',
    type: 'text',
    disabled: true,
  },
];
