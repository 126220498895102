/* eslint-disable no-console */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';

import { addError } from 'actions/Error';
import { useConfirmation } from 'components/Confirmation';
import nets from 'paymentIntegrations/netsCloud/requests';
import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import Loader from 'components/Loader';
import {
  deleteCAFAConfig,
  saveActiveIntegration,
} from 'actions/integrations/CafaConfigs';
import useErrorState, { translateError } from 'utils/hooks/useErrorState';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { NETS_CONST } from 'paymentIntegrations/netsCloud/types';

import RenderFormItem from '../../../../components/FormFieldCtx';

import { customConfiguration, netsFields } from './formShapes';

const NetsIntegrationForm = () => {
  const dispatch = useDispatch();
  const confirmReset = useConfirmation();
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.netsCloud.${text}`, ...rest);
  const superContext = useContext(Ctx);
  const { integration, configuration, setEnableUpdate } = superContext.values;
  const { username, password, terminal, environment } = configuration;

  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [terminalIds, setTerminalIds] = useState<string[]>([]);

  const { errors, hasErrors } = useErrorState(configuration, netsFields);

  useEffect(() => {
    if (environment) {
      sessionStorage.setItem(NETS_CONST.ENV, environment);
    }
  }, [environment]);

  useEffect(() => {
    if (terminal && !terminalIds.includes(terminal)) {
      setTerminalIds([...terminalIds, terminal]);
    }
  }, [configuration]);

  const onChange = (key, value) => {
    if (key === netsFields.environment.id) {
      sessionStorage.setItem(NETS_CONST.ENV, value);
    }
    superContext.onChange('configuration', { ...configuration, [key]: value });
  };

  const editAuthCredentials = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem(NETS_CONST.TOKEN);
  };

  useEffect(() => {
    setEnableUpdate(!hasErrors);
  }, [hasErrors]);

  useEffect(() => {
    if (isLoggedIn) {
      nets
        .getTerminals({ config: { username, password } })
        // @ts-ignore
        .then(({ data }) => {
          if (data?.terminals?.length) {
            setTerminalIds(data.terminals.map(t => t.terminalId));
            setIsLoggedIn(true);
          } else {
            dispatch(
              addError('No terminals registered for the current account.', {
                dismissible: false,
                selfDismiss: true,
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            addError('Fetching terminals for the current account failed.', {
              dismissible: false,
              selfDismiss: true,
            }),
          );
        });
    }
  }, [isLoggedIn]);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const { data } = await nets.login({ username, password });
      const { terminals, token } = data;
      sessionStorage.setItem('nets-token', token);
      setIsLoggedIn(true);
      setTerminalIds(terminals);
      setLoading(false);
    } catch (err) {
      dispatch(
        addError(
          'Login failed. Make sure you have the current username and password',
          {
            dismissible: false,
            selfDismiss: true,
          },
        ),
      );
      setLoading(false);
    }
  };

  const resetIntegration = async () => {
    const title = 'Reset NETS Terminal Integration';
    const body =
      'If you proceed, the integration would be reset and you would have to reenter your username and password again, and select a terminal for this Point of Sale.  ';
    confirmReset({ title, body })
      .then(async () => {
        editAuthCredentials();
        await dispatch(
          deleteCAFAConfig({
            integrationName: integration,
            integrationType: INTEGRATION_TYPES.payment,
          }),
        );
        await dispatch(
          saveActiveIntegration({
            integrationType: INTEGRATION_TYPES.payment,
            value: '',
          }),
        );
        superContext.onChange('configuration', {});
        superContext.onChange('integration', '');
      })
      .catch(e => console.error('Failed to reset NETS integration config', e));
  };

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    terminal: { validate, ...terminalField },
    environment: environmentField,
    ...authFields
  } = netsFields;

  const terminalOptions = useMemo(() => {
    return [
      ...terminalField.options?.map(o =>
        typeof o === 'string'
          ? { name: t(o), value: o }
          : { ...o, name: t(o.name) },
      ),
      ...terminalIds.map(o => ({ name: o, value: Number(o) })),
    ];
  }, [terminalIds]);

  const askAlwaysNeverTranslatedOptions = useMemo(
    () =>
      Object.values(NETS_CONST.CONFIG.OPTIONS).map(v => ({
        name: t(`askAlwaysNeverOptions.${v}`, v),
        value: v,
      })),
    [t, NETS_CONST.CONFIG.OPTIONS],
  );

  return (
    <Ctx.Provider
      value={{
        values: configuration,
        onChange,
      }}
    >
      <h2>{`${t('title')}:`}</h2>
      <div>
        <RenderFormItem
          title={t(environmentField.id)}
          name={environmentField.id}
          error={translateError(errors[environmentField.id], t)}
          {...environmentField}
        />
        <Loader show={loading} withPadding={true} style={{ width: '100%' }}>
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
          {Object.values(authFields).map(({ validate, ...rest }) => {
            return (
              <RenderFormItem
                key={rest.id}
                title={t(rest.id)}
                name={rest.id}
                readOnly={isLoggedIn}
                error={translateError(errors[rest.id], t)}
                {...rest}
              />
            );
          })}
        </Loader>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            className="btn btn-POS"
            onClick={() => (isLoggedIn ? editAuthCredentials() : handleLogin())}
          >
            {t(isLoggedIn ? 'edit' : 'login')}
          </Button>
          {isLoggedIn && (
            // @ts-ignore
            <Badge size="lg" variant="success">
              {t('loggedIn')}
            </Badge>
          )}
        </div>
      </div>
      <div className="mt-3">
        <RenderFormItem
          key={terminalField.id}
          title={t(terminalField.id)}
          name={terminalField.id}
          error={translateError(errors[terminalField.id], t)}
          disabled={!isLoggedIn || !terminalIds.length}
          {...terminalField}
          options={terminalOptions}
        />
      </div>
      <div>
        {Object.values(customConfiguration).map(field => (
          <RenderFormItem
            key={field.id}
            title={t(field.id)}
            name={field.id}
            error={translateError(errors[field.id], t)}
            {...field}
            options={askAlwaysNeverTranslatedOptions}
          />
        ))}
      </div>
      <div className="d-flex justify-content-end mt-2">
        <span className="link-span text-danger" onClick={resetIntegration}>
          {t('resetIntegration')}
        </span>
      </div>
    </Ctx.Provider>
  );
};

export default NetsIntegrationForm;
