import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { saveCustomer } from 'actions/customerSearch';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import SaveButton from 'components/CustomButtons/SaveButton';
import InputField from 'components/FieldTypes/InputField';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { addSuccess } from 'actions/Error';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getPrefillHomeStoreOnCustomerCreation } from 'reducers/configs/settings';
import { Customer } from 'types/Customer';

export const CustomerCreation: FC<{
  onChange: (name: string, value: Customer[keyof Customer]) => void;
  customerData: Partial<Customer>;
}> = ({ customerData, onChange }) => {
  const { t } = useTranslation('scanSearchResults');
  const dispatch = useDispatch();
  const whID = useSelector(getSelectedWarehouseID);
  const prefillHomeStore = useSelector(getPrefillHomeStoreOnCustomerCreation);

  const handleChange = e => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const handleSubmit = async () => {
    await dispatch(
      saveCustomer(
        {
          ...customerData,
          signUpStoreID: whID,
          homeStoreID: prefillHomeStore ? whID : undefined,
        },
        () => {
          dispatch(addSuccess(t('warnings.customerCreated')));
          return dispatch(closeModalPage());
        },
      ),
    );
  };

  useShortcut('Enter', () => handleSubmit(), 30);

  return (
    <>
      <Form onSubmit={() => handleSubmit()}>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.customer.firstName')}
            type="text"
            name="firstName"
            onChange={e => handleChange(e)}
            value={customerData.firstName}
          />
        </Form.Group>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.customer.lastName')}
            name="lastName"
            type="text"
            value={customerData.lastName}
            onChange={e => handleChange(e)}
          />
        </Form.Group>
        <Form.Group>
          <InputField
            size="lg"
            title={t('inputs.customer.code')}
            name="code"
            type="text"
            onChange={e => handleChange(e)}
            value={customerData.code}
          />
        </Form.Group>
        <SaveButton action={handleSubmit} title={t('buttons.save')} />
      </Form>
    </>
  );
};
