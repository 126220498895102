/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { addWarning } from 'actions/Error';
import { resetLastClockIn } from 'actions/clockInOut';
import {
  attemptDemoLogin,
  attemptLogin,
  attemptPinLogin,
  attemptSignup,
  hardLogout,
} from 'actions/Login';
import {
  getIsLoading as getIsLoadingLogin,
  getIsPasswordExpired,
  getSessionKey,
  getUserLoggedIn,
} from 'reducers/Login';
import { selectPos } from 'actions/PointsOfSale/selectPos';
import {
  getIsLoading as getIsLoadingPOS,
  getSelectedPos,
} from 'reducers/PointsOfSale';
import Loader from 'components/Loader';
import { deselectWarehouse } from 'actions/warehouses';
import { getWarehouseSelect } from 'reducers/configs/settings';
import { getSelectedWarehouse } from 'reducers/warehouses';
import WarehouseSelect from 'containers/Login/WarehouseSelect';
import PasswordExpired from 'containers/Login/PasswordExpired';

import LoginView from './Login';
import PinLogin from './PinLogin';
import PosSelect from './PosSelect';
import Signup1 from './Signup1';
import Signup2 from './Signup2';
import countries from './countries.json';
import LocationSelection from './clockInOut/LocationSelection';
import ClockInOutInterface from './clockInOut/ClockInOutInterface';
import { styles } from './util';

const WithLoginBG = ({ children }) => {
  const url = localStorage.getItem('loginBackgroundUrl');
  return (
    <div
      style={{
        width: '100%',
        background: url
          ? `url(${url}) no-repeat center center fixed`
          : 'linear-gradient(#5aacc8, #f7cc56)',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          padding: '52px 10px 0px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </div>
  );
};

const Login = () => {
  const initialView = useSelector(getSelectedPos) ? 'pin' : 'login';
  const [view, updateView] = useState(initialView);
  const [errorField, updateErrorField] = useState('');
  const [email, updateEmail] = useState('');
  const [country, updateCountry] = useState(countries[0].value);
  const [isClokingInOut, toggleClockInOut] = useState(false);
  const selectedWarehouse = useSelector(getSelectedWarehouse);
  const warehouseSelectOption = useSelector(getWarehouseSelect);
  const [clockInOutState, updateClockInOutState] = useState({});
  const dispatch = useDispatch();

  const hasSessionKey = !!useSelector(getSessionKey);
  const isLoggedIn = useSelector(getUserLoggedIn);
  const isLoadingLogin = useSelector(getIsLoadingLogin);
  const isLoadingPOS = useSelector(getIsLoadingPOS);
  const isLoading = isLoadingLogin || isLoadingPOS;
  const passwordExpired = useSelector(getIsPasswordExpired);

  const setErrorFieldIfError = e => {
    if (e.errorField) {
      updateErrorField(e.errorField);
    }
  };

  const doLogin = (cc, uname, pass) => dispatch(attemptLogin(cc, uname, pass));

  const doPinLogin = pin => dispatch(attemptPinLogin(pin));

  const doPosSelect = pos => dispatch(selectPos(pos));

  const doDemoLogin = (country, email) =>
    dispatch(attemptDemoLogin(country, email));

  const doSignup = ({
    country,
    email,
    firstName,
    lastName,
    companyName,
    phone,
    website,
    password,
    nrStores,
  }) =>
    dispatch(
      attemptSignup(
        country,
        email,
        firstName,
        lastName,
        companyName,
        phone,
        website,
        password,
        nrStores,
      ),
    );

  const doWarehouseDeselect = () => dispatch(deselectWarehouse());
  const warningMessage = message =>
    dispatch(
      addWarning(message, {
        selfDismiss: true,
        dismissible: false,
      }),
    );
  const doLogout = () => dispatch(hardLogout());
  const resetClockInDisplay = () => dispatch(resetLastClockIn());

  const forView = (name, ...checks) =>
    name === view && checks.reduce((a, b) => a && b, true)
      ? {}
      : { display: 'none' };

  const { t, ready } = useTranslation([
    'login',
    'gridButtons',
    'billTable',
    'search',
    'customerInformation',
  ]);

  if (isLoggedIn && !isLoading && !isClokingInOut) {
    if (!selectedWarehouse && warehouseSelectOption) {
      return (
        <WithLoginBG>
          <WarehouseSelect
            updateView={() => {}}
            toggleClockInOut={() => {}}
            onCancel={() => doLogout()}
          />
        </WithLoginBG>
      );
    }
    return (
      <WithLoginBG>
        <PosSelect
          onSelect={doPosSelect}
          onCancel={() =>
            warehouseSelectOption ? doWarehouseDeselect() : doLogout()
          }
          updateView={() => {}}
          toggleClockInOut={() => {}}
        />
      </WithLoginBG>
    );
  }
  if (passwordExpired) {
    return (
      <WithLoginBG>
        <PasswordExpired />
      </WithLoginBG>
    );
  }
  return (
    <WithLoginBG>
      {
        {
          login: (
            <Loader show={isLoading || !ready} loadingText={t('loading.login')}>
              <LoginView
                style={
                  view === 'login' ? forView('login', !isLoggedIn) : null
                }
                onSignin={(cc, uname, pass) => doLogin(cc, uname, pass)}
                onSignup={() => updateView('signup1')}
                onPinSelect={() => updateView('pin')}
                pinLoginEnabled={hasSessionKey}
                isClokingInOut={isClokingInOut}
                toggleClockInOut={toggleClockInOut}
                warningMessage={warningMessage}
                updateView={updateView}
                updateClockInOutState={updateClockInOutState}
              />
            </Loader>
          ),
          pin: (
            <Loader show={isLoading || !ready} loadingText={t('loading.login')}>
              {isLoading || !ready ? (
                <Card
                  style={{
                    ...styles.card,
                    minWidth: '300px',
                    minHeight: '150px',
                  }}
                />
              ) : (
                <PinLogin
                  style={view === 'pin' ? forView('pin', !isLoggedIn) : null}
                  onPinLogin={pin => doPinLogin(pin)}
                  onCancel={() => updateView('login')}
                  onSignup={() => updateView('signup1')}
                />
              )}
            </Loader>
          ),
          signup1: (
            <Loader show={isLoading} loadingText={t('loading.demo')}>
              <Signup1
                style={view === 'signup1' ? forView('signup1') : null}
                countries={countries}
                email={email}
                country={country}
                onCancel={() => updateView('login')}
                onOpenDemo={(country, email) =>
                  doDemoLogin(country, email).catch(e =>
                    setErrorFieldIfError(e),
                  )
                }
                onOpenFullAccountForm={() => updateView('signup2')}
                errorField={errorField}
                updateEmail={updateEmail}
                updateCountry={updateCountry}
              />
            </Loader>
          ),
          signup2: (
            <Loader show={isLoading} loadingText={t('loading.signup')}>
              <Signup2
                style={view === 'signup2' ? forView('signup2') : null}
                email={email}
                country={country}
                countries={countries}
                onCancel={() => updateView('signup1')}
                // eslint-disable-next-line react/destructuring-assignment
                onCreateAccount={data =>
                  doSignup(data).catch(e => setErrorFieldIfError(e))
                }
                errorField={errorField}
                updateEmail={updateEmail}
                updateCountry={updateCountry}
                updateErrorField={updateErrorField}
              />
            </Loader>
          ),
          locationSelection: (
            <Loader show={isLoading} loadingText={t('loading.preclockin')}>
              <LocationSelection
                updateView={updateView}
                toggleClockInOut={toggleClockInOut}
                clockInOutState={clockInOutState}
                updateClockInOutState={updateClockInOutState}
                warningMessage={warningMessage}
              />
            </Loader>
          ),
          clockInOutInterface: (
            <Loader show={isLoading} loadingText={t('loading.clockin')}>
              <ClockInOutInterface
                updateView={updateView}
                toggleClockInOut={toggleClockInOut}
                clockInOutState={clockInOutState}
                updateClockInOutState={updateClockInOutState}
                warningMessage={warningMessage}
                onSignin={(cc, uname, pass) => doLogin(cc, uname, pass)}
                resetClockInDisplay={resetClockInDisplay}
              />
            </Loader>
          ),
        }[view]
      }
    </WithLoginBG>
  );
};

export default Login;
