import { createSelector } from 'reselect';

import {
  RESET_LAST_CLOCK_IN,
  SET_LAST_CLOCK_IN,
  START_LOADING,
  STOP_LOADING,
} from '../constants/employees';

const initialState = {
  loading: false,
  loggedInEmployeeLastClockInTime: [] as Array<any>,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case START_LOADING:
      return { ...state, loading: true };
    case STOP_LOADING:
      return { ...state, loading: false };
    case SET_LAST_CLOCK_IN:
      return { ...state, loggedInEmployeeLastClockInTime: [...payload] };
    case RESET_LAST_CLOCK_IN:
      return { ...state, loggedInEmployeeLastClockInTime: [...payload] };
    default:
      return state;
  }
};

export function getIsLoading(state) {
  return state.clockInOut.loading;
}

export const getLoggedInEmployeeLastClockInTime = createSelector(
  (state: any) => state.clockInOut.loggedInEmployeeLastClockInTime,
  lastTimes => {
    if (lastTimes.length) {
      const time = lastTimes[0].clockInTime;
      return time.slice(0, time.length - 3);
    }
    return null;
  },
);
