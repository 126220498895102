import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';

import Loader from 'components/Loader';
import { isSaleFullyReturned } from 'utils';
import { Sales } from 'containers/Forms/RecentSalesList';

import Panel from './Panel';

type Props = {
  sales: Sales;
  title: string;
  showName: boolean;
  loadMore?: () => void;
  loadingText: string;
};

const PurchasesList: React.FC<Props> = ({
  loadingText,
  title,
  showName,
  sales,
  loadMore,
}) => {
  const { t } = useTranslation('customer');
  return (
    <Loader
      withPadding
      show={Boolean(loadingText)}
      loadingText={loadingText}
      block
      data-testid="previous-purchases"
    >
      <Typography data-testid="title">{title}</Typography>
      {useMemo(
        () =>
          sales.docs.map(s => (
            <Panel
              key={s.id}
              sale={s}
              isSaleFullyReturned={isSaleFullyReturned(s, sales.returnedRows)}
              showName={showName}
            />
          )),
        [sales.docs],
      )}
      {sales.docs.length !== 0 && loadMore && (
        <Button variant="contained" color="secondary" onClick={loadMore}>
          {t('customerView.purchases.loadMore')}
        </Button>
      )}
    </Loader>
  );
};

export default PurchasesList;
