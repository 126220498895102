import { createConfirmation } from 'actions/Confirmation';
import { getProductByID } from 'reducers/cachedItems/products';
import { getPluginConfiguration } from 'reducers/Plugins';
import { PosPlugin } from 'plugins/plugin';
import { getCountryInUse } from 'reducers/configs/settings';
import { updateCurrentSaleDocument } from 'actions/sales';
import { getHasProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getCurrentSalesDocument } from 'reducers/sales';
import { addSuccess } from 'actions/Error';
import { ErplyAttributes, updateNotes } from 'utils';
import { endlessAisleProductPrompt } from 'plugins/taf/endlessAisle/components/EAPopup';
import { ThunkAction } from 'reducers';

import { DEFAULT_NOTES, EA_SALE, pluginID } from '../constants';
import { Configuration } from '../types';

const markAsEndlessAisle: ThunkAction = async function markAsEndlessAisle(
  dispatch,
  getState,
) {
  const { attributes, notes, internalNotes } = getCurrentSalesDocument(
    getState(),
  );
  const newAttributes = new ErplyAttributes(attributes);
  newAttributes.set('endless_aisle', '1');
  dispatch(
    updateCurrentSaleDocument({
      notes: updateNotes(DEFAULT_NOTES, notes),
      internalNotes: updateNotes(DEFAULT_NOTES, internalNotes),
      type: EA_SALE,
      attributes: newAttributes.asFlatArray, // sus: Why would we be storing attributes internally in flat array format? This should probably just be dict
    }),
  );
};
export const onAddProduct: Required<PosPlugin>['onAddProduct']['on'] = (
  p,
  ap,
) => async (dispatch, getState) => {
  if (getHasProductsInShoppingCart(getState())) return ap;

  const { groupID } = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const product = getProductByID(p.productID)(getState());

  if (product.totalInStock > 0) return ap;
  if (Number(groupID) !== product.groupID) return ap;

  const country = getCountryInUse(getState());

  if (!['AU', 'NZ'].includes(country)) return ap;

  const choice = await dispatch(
    endlessAisleProductPrompt({
      productName: product.name,
      productID: product.productID,
    }),
  );
  if (choice === 'endless-aisle') {
    await dispatch(markAsEndlessAisle);
    dispatch(
      addSuccess(
        'Endless Aisle transaction started. Product has been added to sale.',
      ),
    );
  }
  return ap;
};

export const beforeAddProduct: Required<
  PosPlugin
>['onAddProduct']['before'] = () => async (dispatch, getState) => {
  const { type } = getCurrentSalesDocument(getState());
  if (type === EA_SALE) {
    await new Promise(resolve =>
      dispatch(
        createConfirmation(resolve, null, {
          title: 'Unable to add product',
          body: 'Endless Aisle Sales can only have 1 product',
        }),
      ),
    );
    throw new Error('Endless Aisle Sales can only have 1 product');
  }
};
