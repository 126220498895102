import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as modals } from 'constants/modalPage';
import { getHasRelatedOrReplacementProducts } from 'reducers/cachedItems/products';

const UIProductFormBody = ({ children, orderIndex, productID }) => {
  const dispatch = useDispatch();
  const hasRelatedProducts = useSelector(
    getHasRelatedOrReplacementProducts(productID),
  );
  const [first, ...rest] = React.Children.toArray(children);
  const openRelatedProducts = () => {
    dispatch(
      openModalPage({
        component: modals.relatedProducts,
        props: {
          productID,
          parentRowID: orderIndex,
        },
        modalClassName: 'related-products-modal',
        groupID: 'RELATED_PRODUCTS',
      }),
    );
  };
  return (
    <>
      {first}
      {hasRelatedProducts && (
        <div>
          <Button data-testid="open-kit-options" onClick={openRelatedProducts}>
            Reopen Kit Options
          </Button>
        </div>
      )}
      {rest}
    </>
  );
};

export default UIProductFormBody;
