import * as pceftposAPI from '../requests/pceftposRequests';

export const handleErrors = ({
  err,
  displayError,
  updateMessage,
  enableButtons,
}) => {
  if (err.message === `Pinpad offline`) {
    displayError(`Transaction declined. Pinpad is offline.`);
    updateMessage(
      `Transaction declined. Pinpad is offline. \n \n Please try again...`,
    );
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === `Network Error`) {
    displayError(err.message);
    updateMessage(`Check connection with the terminal...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === `Too Many Requests`) {
    displayError(`Too Many Requests`);
    updateMessage(`Too Many Requests. \n \n Please wait and try again...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === `Transaction failed`) {
    displayError(`Transaction failed.`);
    updateMessage(`Transaction failed. \n \n Please try again...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }
  if (err.message === `Transaction declined`) {
    displayError(`Transaction declined.`);
    updateMessage(`Transaction declined. \n \n Please try again...`);
    enableButtons(['retry-payment', 'return-back']);
    throw err;
  }

  displayError(`Transaction unsuccessful.`);
  updateMessage(`Please try again...`);
  enableButtons(['retry-payment', 'return-back']);
  throw err;
};

export const withResponseErrors = ({ request, params }) =>
  new Promise((resolve, reject) => {
    const onResponse = {
      requestSale(response) {
        if (response.data.records[0].statusMessage === '[PF] PINpad Offline') {
          const error = { response, message: 'Pinpad offline' };
          reject(error);
          return;
        }

        if (response.data.records[0].transactionStatus === 'DECLINED') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }

        if (response.data.records[0].transactionStatus !== 'APPROVED') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }

        resolve(response);
      },
      requestTestConnection(response) {
        if (response.data.records[0].transactionStatus !== 'Success') {
          const error = { response, message: 'Failure' };
          reject(error);
          return;
        }
        resolve(response);
      },
      requestVoid(response) {
        if (response.data.records[0].statusMessage === '[PF] PINpad Offline') {
          const error = { response, message: 'Pinpad offline' };
          reject(error);
          return;
        }
        if (response.data.records[0].transactionStatus === 'DECLINED') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }

        if (response.data.records[0].transactionStatus !== 'APPROVED') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }

        resolve(response);
      },
      requestRefund(response) {
        if (response.data.records[0].statusMessage === '[PF] PINpad Offline') {
          const error = { response, message: 'Pinpad offline' };
          reject(error);
          return;
        }
        if (response.data.records[0].transactionStatus === 'DECLINED') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }

        if (response.data.records[0].transactionStatus !== 'APPROVED') {
          const error = { response, message: 'Transaction declined' };
          reject(error);
          return;
        }

        resolve(response);
      },
    };
    pceftposAPI[request]({ ...params }).then(
      response => {
        onResponse[request](response);
      },
      err => {
        reject(err);
      },
    );
  });
