import { PatchScript } from '@pos-refacto/patchscript-with-react';

import { mmReceiptTranslations } from 'plugins/mm/features/receipt/pieces/translations/translations';

type Table = Extract<PatchScript[number], { type: 'table' }>;
type Row = Table['rows'][number];
type Cell = Row['cells'][number];

const make = ({ offline: { stripe, message } } = mmReceiptTranslations.en) => {
  const warningStripe: Cell = {
    pieces: [{ text: stripe.repeat(100), meta: { overflow: 'hidden' } }],
    align: 'center',
  };
  const warningText: Cell = {
    align: 'center',
    pieces: [
      {
        meta: { size: 2, bold: true },
        text: message,
      },
    ],
  };
  const blank: Cell = {
    pieces: [{ text: String(''), meta: undefined }],
  };

  return { warningStripe, warningText, blank };
};
/**
 * w͞a͞r͞n͞i͞n͞g͞-͞w͞a͞r͞n͞i͞n͞g͞-͞w͞a͞r͞n͞i͞n͞g͞-͞w͞a͞r͞n͞i͞n͞g͞-͞w͞a͞r͞n͞i͞n͞g͞-͞w͞a͞r͞n͞i͞n͞g͞-͞w͞a͞r͞n͞i͞n͞g͞-͞w͞a͞r͞
 *
 * <b>This receipt was printed during offline mode. Information on the receipt may be inaccurate and may not reflect the final state of the sale</b>
 *
 * w͟a͟r͟n͟i͟n͟g͟-͟w͟a͟r͟n͟i͟n͟g͟-͟w͟a͟r͟n͟i͟n͟g͟-͟w͟a͟r͟n͟i͟n͟g͟-͟w͟a͟r͟n͟i͟n͟g͟-͟w͟a͟r͟n͟i͟n͟g͟-͟w͟a͟r͟n͟i͟n͟g͟-͟w͟a͟r͟
 *
 */
export const receiptOfflineBanner = (
  t = mmReceiptTranslations.en,
): PatchScript[number] => {
  const { warningStripe, warningText, blank } = make(t);
  return {
    type: 'table',
    columns: [
      { baseWidth: 0, weight: 1 },
      { baseWidth: 0, weight: 3 },
      { baseWidth: 0, weight: 1 },
    ],
    rows: [
      { type: 'invisible', cells: [blank, blank, blank] },
      { type: 'header', cells: [null, warningStripe, null] },
      { type: 'normal', cells: [null, warningText, null] },
      { type: 'header', cells: [null, warningStripe, null] },
      { type: 'invisible', cells: [blank, blank, blank] },
    ],
  };
};
