import { Reason } from 'containers/Forms/ManagerOverride/types';
import { getUserLoggedIn } from 'reducers/Login';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';

export const openManagerOverride = (reasons: Reason[]) => async (
  dispatch,
  getState,
): Promise<void> => {
  const user = getUserLoggedIn(getState());
  const neededReasons = await Promise.all(
    reasons.map(reason =>
      reason.isPermitted(user).then(perm => (perm ? undefined : reason)),
    ),
  ).then(res => res.filter(reason => reason));
  if (neededReasons.length === 0) return;
  await dispatch(
    openModalPage({
      component: mp.managerOverride,
      isPopup: true,
      props: { reasons: neededReasons },
    }),
  );
};
