import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { getConnectionHealth } from 'reducers/connectivity/connection';

import { previousCustomerButton } from '../previousCustomer';
import { loadCouponsButton } from '../coupons';

export const getFunctionButtons: Required<
  PosPlugin
>['selectorOverrides']['getFunctionButtons'] = base =>
  createSelector(
    base,
    state => getConnectionHealth(state),
    (baseButtons, isOnline) => [
      { ...previousCustomerButton, disabled: !isOnline },
      ...baseButtons,
      { ...loadCouponsButton, disabled: !isOnline },
    ],
  );
