import {
  REDUX_CLIENTCODE,
  REDUX_SESSIONKEY,
  REDUX_POSID,
} from 'constants/persistence';
import { proxy, partnerKey } from 'services/shared';
import { pendingRequests } from 'services/localDB';
import * as ErplyAPI from 'services/ErplyAPI';
import { redirectToLoginApp } from 'services/ErplyAPI/redirectToLoginApp';
import { ErplyAttributes, isOfflineError, ErplyLongAttributes } from 'utils';
import i18n from 'containers/App/i18n';
import { getSetting } from 'reducers/configs/settings';

import { ErplyApiError } from './apiErrors';
import { doClientRequest as doBulkedClientRequest } from './ErplyAPI2';
import { ErplyRequestParams, ErplyResponse } from './types';

const t = i18n.getFixedT(null, 'openCloseDay');

const throwIfErplyError = <T>(response: ErplyResponse<T>) => {
  // Server errors and misconstructed requests
  if (response.status.errorCode) {
    throw new ErplyApiError(response.status);
  }

  return response;
};

const parseErplyResponse = async response => {
  if (!response.ok) {
    switch (response.status) {
      case 404:
        throw new ErplyApiError({ errorCode: 'network' });
      default:
        throw new ErplyApiError({ errorCode: 404 });
    }
  }
  const data = await response.json();
  await throwIfErplyError(data);
  return data;
};

const urlEncode = (dict: Record<string, any>) =>
  Object.entries(dict)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

// Returns the full response object
export async function requestCompleteResponse<T>(
  params: ErplyRequestParams,
): Promise<ErplyResponse<T>> {
  Object.entries(params)
    .filter(([, value]) => value === undefined)
    // eslint-disable-next-line no-param-reassign
    .forEach(([key]) => delete params[key]);
  const isVerifyUserRequest = params.request === 'verifyUser';
  const clientCode =
    params.clientCode ??
    JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) ?? '');

  if (isVerifyUserRequest) {
    return window
      .fetch(`${proxy}https://${clientCode}.erply.com/api/`, {
        method: 'POST',
        body: urlEncode({ partnerKey, clientCode, ...params }),
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      })
      .then(parseErplyResponse)
      .catch(err => {
        switch (err.errorCode) {
          /* Account has been configured to use login app only */
          case 1212:
            return redirectToLoginApp();
          default:
        }
        throw err;
      });
  }

  // For session requests, make an attempt but handle some error cases automatically
  return doBulkedClientRequest(params)
    .then(throwIfErplyError)
    .catch(err => {
      switch (err.errorCode) {
        /* GDPR log not available outside EU */
        case 1149:
          // pretend it succeeded
          return [];

        /* Any other error, let propagate out */
        default:
          throw err;
      }
    });
}

/**
 * Perform an API request and return the records from it
 * @param {Object} params Parameters to send to the Erply API
 * @throws {Error} If the API returns an error code or there is a network error
 * NB: Make sure to include the 'request' parameter as well
 */
export const doGenericRequest = async <T>(
  params: ErplyRequestParams,
): Promise<ErplyResponse<T>['records']> => {
  Object.entries(params)
    .filter(([, value]) => value === undefined)
    // eslint-disable-next-line no-param-reassign
    .forEach(([key]) => delete params[key]);
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY) || '""');
  const response = await window.fetch(`${proxy}https://www.erply.net/api/`, {
    method: 'POST',
    body: urlEncode({ partnerKey, sessionKey, ...params }),
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  });

  const data = await parseErplyResponse(response);
  return data.records;
};

export async function doClientRequest<T>(
  params: ErplyRequestParams,
): Promise<ErplyResponse<T>['records']> {
  const response = await requestCompleteResponse<T>(params);
  return response.records;
}

export const requestWithOfflineFallback = <T extends keyof typeof ErplyAPI, R>(
  {
    request,
    params,
  }: { request: T; params: Parameters<typeof ErplyAPI[T]>[0] },
  defaultFallbackValue?: R,
) => async (dispatch, getState): Promise<R> => {
  const response = await ErplyAPI[request](params).catch(err => {
    const allowOffline = getSetting('touchpos_allow_offline_mode')(getState());

    if (!isOfflineError(err)) {
      throw new Error(t('alerts.notAnOfflineError'), {
        cause: err,
      });
    }

    if (!allowOffline) {
      throw new Error(t('alerts.offlineNotAllowed'), { cause: err });
    }

    pendingRequests.add({ request, params });
    return (defaultFallbackValue ?? []) as R;
  });
  return response;
};

export async function doBulkRequest(params) {
  const noSessionRequest = ['verifyUser'].indexOf(params.request) >= 0;
  const clientCode = JSON.parse(localStorage.getItem(REDUX_CLIENTCODE) ?? '{}');
  const posID = JSON.parse(localStorage.getItem(REDUX_POSID) ?? 'null');
  const body = {
    partnerKey,
    clientCode,
    posID,
    requests: JSON.stringify(
      params.map(req => ({
        ...req,
        attributes: undefined,
        longAttributes: undefined,
        ...new ErplyLongAttributes(req.longAttributes).asFlatArray,
        ...new ErplyAttributes(req.attributes).asFlatArray,
      })),
    ),
  };
  if (!noSessionRequest) {
    // @ts-ignore
    body.sessionKey = JSON.parse(
      localStorage.getItem(REDUX_SESSIONKEY) ?? '{}',
    );
  }
  const response = await window.fetch(
    `${proxy}https://${body.clientCode}.erply.com/api/`,
    {
      method: 'POST',
      body: urlEncode(body),
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    },
  );
  const data = await parseErplyResponse(response);
  return data;
}

const lastReverify = {
  timestamp: 0,
  promise: Promise.reject(new Error('This should never happen')),
};
// JS engine will complain if there is a rejecting promise that no one has attached a .catch to
lastReverify.promise.catch(() => {});

/**
 * Fetches multiple pages until all records or a desired amount of records is acquired
 * If manual pagination is present, does only one request
 * @param params Request parameters
 * @param from Product index to fetch from
 * @param count How many products to fetch after the index (at most)
 */
export const requestAllRecords = async <T>(
  params: ErplyRequestParams,
  from = 0,
  count = Infinity,
) => {
  // Manual pagination, or pagination not supported
  if (params.pageNo !== undefined || params.searchNameIncrementally) {
    return requestCompleteResponse<T>({ ...params });
  }
  const recordsOnPage = Math.min(count, 100);
  /* Index of first page we need to fetch */
  const min = Math.floor(from / recordsOnPage);
  const { status, records: allRecords } = await requestCompleteResponse<T>({
    ...params,
    recordsOnPage,
    pageNo: min + 1,
  });

  /* Maximum number of pages to fetch based on count */
  const pagesLimit = Math.ceil(count / recordsOnPage);
  /* Maximum number of pages to fetch based on recordsTotal */
  const lastPage = min + Math.ceil(status.recordsTotal / recordsOnPage);
  /* Number of pages we're actually fetching */
  const pages = Math.min(pagesLimit, lastPage);

  await Promise.all(
    /* We already have the first one */
    Array(Math.max(pages - 1, 0))
      .fill(0)
      .map((_, index) =>
        requestCompleteResponse<T>({
          ...params,
          recordsOnPage,
          pageNo: min + index + 1 + 1,
        }).then(res => res.records),
      ),
  ).then(records =>
    /* Add all the records to the allRecords from the first request */
    // @ts-ignore
    records.flatMap(a => a).forEach(record => allRecords.push(record)),
  );
  return { status, records: allRecords };
};
