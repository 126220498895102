import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { getProductByID } from 'reducers/cachedItems/products';
import { PosPlugin } from 'plugins/plugin';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { ShoppingCart } from 'types/ShoppingCart';
import { getCurrentSalesDocument } from 'reducers/sales';

import { UICustomGiftCardSerial } from './components';
import reduxReducer, {
  addFreeGiftCard,
  getFreeGiftCards,
  removeFreeGiftCard,
  resetState,
} from './redux';
import documentation from './documentation.md';

export const pluginID = 'lancaster-plugin';
const lancasterPlugin: PosPlugin = {
  id: pluginID,
  name: 'Lancaster plugin',
  keywords: ['lancaster', 'gift', 'card'],
  infoMDUrl: documentation,
  getStatus: () => ({
    message: 'ok',
    type: 'valid',
  }),
  onRemoveOrder: {
    on: (p, ap) => async (
      dispatch: ThunkDispatch<unknown, unknown, Action>,
    ) => {
      dispatch(removeFreeGiftCard(p.orderIndex));
      return ap;
    },
  },
  onResetShoppingCart: {
    after: () => async dispatch => {
      dispatch(resetState());
    },
  },
  onAddMultiProducts: {
    on: (p, ap) => async (dispatch, getState) => {
      const discountedGiftCardOrders = p.filter(order => {
        // free line text items will have id as "0"
        if (order.productID === '0') return false;
        const product = getProductByID(order.productID)(getState());
        return product.isGiftCard && Number(order.discount) > 0;
      });

      if (!discountedGiftCardOrders.length) return ap;

      discountedGiftCardOrders.forEach(order => {
        dispatch(
          addFreeGiftCard(Number(order.orderIndex), {
            code: '',
            percentage: order.discount,
          }),
        );
      });

      return ap;
    },
  },
  onCalculate: {
    on: (p, ap) => async (dispatch, getState) => {
      const freeGiftCards = getFreeGiftCards(getState());
      const { id } = getCurrentSalesDocument(getState());
      return R.map(
        R.when(
          item => freeGiftCards[item.orderIndex],
          item =>
            R.pipe(
              R.assoc('forceDiscount', true),
              R.when(
                R.always(!id),
                R.assoc(
                  'manualDiscount',
                  freeGiftCards[item.orderIndex].percentage,
                ),
              ),
              R.assoc('discount', freeGiftCards[item.orderIndex].percentage),
            )(item),
        ),
      )(ap);
    },
  },
  onSaveSalesDocument: {
    on: (p, ap) => async (dispatch, getState) => {
      const productsInCart: ShoppingCart = getProductsInShoppingCart(
        getState(),
      );

      const freeGiftCardsAsArray = Object.values(getFreeGiftCards(getState()));
      return R.map(
        R.when(
          val =>
            R.both(R.propEq('requestName', 'saveGiftCard'), () => {
              const codeValue = R.prop('code')(val);
              return R.includes(
                codeValue,
                freeGiftCardsAsArray.map(gf => gf.code),
              );
            })(val),
          item => {
            const i = productsInCart.find(p => p.giftCardSerial === item.code);
            return R.pipe(
              R.assoc('balance', i?.basePriceWithVAT),
              R.assoc('value', i?.basePriceWithVAT),
            )(item);
          },
        ),
      )(ap);
    },
  },
  UICustomGiftCardSerial,
  reduxReducer,
};

export default lancasterPlugin;
