import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import * as R from 'ramda';

import { REASONS } from 'constants/reasonCodesDB';
import {
  getAllowedDifference,
  getCheckOnlyCashOnEOD,
  getCloseDayNotesMandatory,
  getExpectedTenders,
  getIsLoading,
  getPosDayTotals,
  getShowDeposit,
  getShowExpected,
} from 'reducers/OpenCloseDay';
import { closeAllDays, getPointOfSaleDayTotals } from 'actions/OpenCloseDay';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import Loader from 'components/Loader';
import {
  getDefaultCurrency,
  getDenominations,
  getEnabledCurrencies,
  getIsModuleEnabled,
  getSetting,
} from 'reducers/configs/settings';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { fetchReasonCodes } from 'actions/reasonCodesDB';
import { useConfirmation } from 'components/Confirmation';
import { addError, addWarning } from 'actions/Error';
import { deletePendingSales, getPendingSales } from 'actions/sales';
import { openCashDrawer } from 'actions/integrations/printer';
import { PluginComponent } from 'plugins';
import { round, roundCurrency } from 'utils';
import {
  getHasRightToDeleteUnconfirmedSale,
  getLoggedInEmployeeID,
} from 'reducers/Login';
import { modalPages } from 'constants/modalPage';
import { ErplyApiError } from 'services/ErplyAPI/core/apiErrors';

import Confirmation from './views/Confirmation';
import CloseDayMain from './views/CloseDayMain';
import Tenders from './views/Tenders';
import { CurrencyState, TendersByCurrency } from './OCDTypes';
import {
  asFloat,
  getDefaultCountedInRegister,
  isWithinAllowedDifference,
  spreadTenders,
} from './utils';

const CloseDayComponent = () => {
  // region Dispatch and such
  const { t } = useTranslation('openCloseDay');
  const dispatch: ThunkDispatch<unknown, unknown, Action> = useDispatch();
  const confirm = useConfirmation();
  // endregion

  // region Currency selectors
  const defaultCurrency: string = useSelector(getDefaultCurrency);
  const isMultiCurrencyEnabled = useSelector(
    getIsModuleEnabled('pos_multicurrency'),
  );
  const allCurrencies = useSelector(getEnabledCurrencies);
  // endregion

  // region OpenCashDrawer
  const shouldOpenCashDrawer = !!useSelector(
    getSetting('day_startend_open_drawer'),
  );
  const showExpected = useSelector(getShowExpected);

  useEffect(() => {
    if (shouldOpenCashDrawer) dispatch(openCashDrawer());
  }, [dispatch, shouldOpenCashDrawer]);
  // endregion

  // region Delete pending sales
  // Delete all pending sales if configured
  const shouldDelPendingSales = useSelector(
    getSetting('touchpos_remove_pending_sales_before_close_day'),
  );
  const userID = useSelector(getLoggedInEmployeeID);
  const permsToDeletePendingSales = useSelector(
    getHasRightToDeleteUnconfirmedSale,
  );
  useEffect(() => {
    if (!shouldDelPendingSales) return;
    dispatch(getPendingSales())
      .then(sales => {
        let filteredSales = sales;
        // can only delete own sales
        if (permsToDeletePendingSales === 1) {
          filteredSales = sales.filter(
            sale => Number(sale.employeeID) === Number(userID),
          );
        }
        if (filteredSales.length) {
          return confirm({
            body: t('alerts.closeDayWithPendingSales', { context: 'body' }),
          }).then(() => {
            // if Confirm is pressed, but user has no rights to delete sales, don't delete
            if (permsToDeletePendingSales === 0) {
              dispatch(addWarning(t('alerts.noRightsToDelete')));
              // if has permissions to delete either own or all sales - delete the sales
            } else {
              dispatch(deletePendingSales(filteredSales)).catch(err => {
                if (err instanceof ErplyApiError) {
                  // Should technically never happen, unless permsToDeletePendingSales = 0 check fails
                  if (Number(err.errorCode) === 1063) {
                    dispatch(addError(t('alerts.noRightsToDelete')));
                  } else {
                    dispatch(addError(err.message));
                  }
                } else {
                  dispatch(addError(t('alerts.failedToDelete')));
                }
              });
            }
          });
        }
        return null;
      })
      .catch(() => dispatch(previousModalPage()));
  }, [
    confirm,
    dispatch,
    shouldDelPendingSales,
    permsToDeletePendingSales,
    userID,
    t,
  ]);
  // endregion

  // region Other selectors

  const loading = useSelector(getIsLoading);

  const closeDayVarianceReasons = useSelector(
    getReasonCodes(REASONS.EOD_VARIANCE),
  );

  const checkOnlyCash = useSelector(getCheckOnlyCashOnEOD);

  const closingDayNotesMandatory = useSelector(getCloseDayNotesMandatory);
  const fillCounted = useSelector(getSetting('touchpos_eod_fill_counted'));
  const allowedDifference = useSelector(getAllowedDifference);
  const reasonRequired = useSelector(
    getSetting('touchpos_eod_reason_required'),
  );

  // endregion

  /*
   * State
   */
  const [view, setView] = useState('main');

  const [notes, setNotes] = useState('');

  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);
  const denominations = useSelector(getDenominations(selectedCurrency));

  // region Expected amounts
  const totals = useSelector(getPosDayTotals);
  const expectedTenders = useSelector(getExpectedTenders);
  const expectedCashAmount = useMemo(
    () =>
      roundCurrency(expectedTenders[selectedCurrency]?.CASH || 0).toString(),
    [selectedCurrency, expectedTenders],
  );
  // endregion

  // region Banknotes count
  const [billAmountState, setBillAmounts] = useState<{
    [currency: string]: { [value: string]: string };
  }>({
    [selectedCurrency]: {
      ...Object.fromEntries(denominations.map(d => [[d.value], ''])),
    },
  });
  const billAmounts = billAmountState[selectedCurrency] ?? {};
  // adds missing denominations to billAmounts when currency changes
  useEffect(() => {
    if (!billAmountState[selectedCurrency] && denominations) {
      const newDenominations = Object.fromEntries(
        denominations.map(d => [[d.value], '']),
      );
      setBillAmounts(state => ({
        ...state,
        [selectedCurrency]: newDenominations,
      }));
    }
  }, [denominations]);
  /**
   * Update Bill note amount handler
   * @param banknote {string} - bill note denomination instance {$100, $50, $10, etc...}
   * @param value {string} - counted bank notes amount (numeric val) {3|5|10}
   */
  const updateBillAmount = (banknote: string, value: string) => {
    setBillAmounts(state => ({
      ...state,
      [selectedCurrency]: { ...state[selectedCurrency], [banknote]: value },
    }));
  };
  // endregion

  // region Counted input field value
  const [countedState, setCounted] = useState<CurrencyState>({});
  // update countedState on currency change
  useEffect(() => {
    if (!countedState[selectedCurrency]) {
      setCounted(state => ({ ...state, [selectedCurrency]: null }));
    }
  }, [selectedCurrency]);
  /**
   * Update Counted amount handler
   * @param value {string} - counted amount (numeric val){3|5|10}
   */
  const updateCounted = (value: string) => {
    setCounted(state => ({ ...state, [selectedCurrency]: value }));
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setCurrencyState(state => ({
      ...state,
      [selectedCurrency]: {
        ...state[selectedCurrency],
        CASH: { ...state[selectedCurrency].CASH, counted: value },
      },
    }));
  };
  // endregion

  // region Register Input field
  const [registerState, setRegister] = useState<CurrencyState>({
    [defaultCurrency]: useSelector(getDefaultCountedInRegister),
  });
  // add new selected currency in registerState
  useEffect(() => {
    if (!registerState[selectedCurrency]) {
      setRegister(state => ({ ...state, [selectedCurrency]: null }));
    }
  }, [selectedCurrency]);
  /**
   * Update register value per currency
   * @param value {string|null} - register amount (numeric val)
   */
  const updateRegister = (value: string | null) =>
    setRegister(state => ({ ...state, [selectedCurrency]: value }));
  // endregion

  // region Deposit
  const [depositState, setDeposit] = useState<CurrencyState>({});
  useEffect(() => {
    if (!depositState[selectedCurrency]) {
      setDeposit(state => ({ ...state, [selectedCurrency]: null }));
    }
  }, [selectedCurrency]);
  /**
   * Update deposit amount handler
   * @param value {string|null} - deposit amount (numeric val) {2|3|22}
   */
  const updateDeposit = (value: string | null) => {
    setDeposit(state => ({ ...state, [selectedCurrency]: value }));
  };
  // endregion

  // region Tenders
  const [tenderState, setCurrencyState] = useState<TendersByCurrency>({});
  const tenders = tenderState[selectedCurrency] ?? {};

  const showDeposit = useSelector(getShowDeposit);

  useEffect(() => {
    setCurrencyState(state => ({
      ...state,
      [selectedCurrency]: Object.fromEntries(
        Object.entries(expectedTenders[selectedCurrency] ?? {}).map(
          ([paymentType, expected]) => {
            const tenderObjectInTotals = totals[selectedCurrency].find(tender =>
              [
                tender.paymentType,
                `${tender.paymentType}-${tender.cardType}`,
              ].includes(paymentType),
            );
            const shouldBlockCounted =
              tenderObjectInTotals?.paymentType === 'CASH' || !fillCounted;

            const counted = Number(
              isMultiCurrencyEnabled && paymentType === 'CASH'
                ? tenders?.CASH?.counted ?? String(expected)
                : String(tenderObjectInTotals?.expectedAmount),
            );
            return [
              paymentType,
              {
                reason: state[selectedCurrency]?.[paymentType]?.reason ?? -1,
                counted: shouldBlockCounted
                  ? ''
                  : roundCurrency(counted).toString(),
                expected,
              },
            ];
          },
        ),
      ),
    }));
  }, [expectedTenders, fillCounted, isMultiCurrencyEnabled, selectedCurrency]);

  const getCounted = val => {
    if (val) return val.counted;
    return 0;
  };
  /**
   *  Update reason for mismatch between expected and counted amount per tender
   * @param type
   * @param newVal
   */
  const updateTender = (
    type: string,
    newVal: { counted: string; reason: number },
  ) => {
    const counted = getCounted(newVal);

    if (type === 'CASH') {
      updateCounted(counted);
    }
    setCurrencyState(state => ({
      ...state,
      [selectedCurrency]: {
        ...state[selectedCurrency],
        [type]: { ...state[selectedCurrency][type], ...newVal },
      },
    }));
  };
  // endregion

  /*
   * Computed
   */

  /**
   * Used in the function that generates the bulk close day request to count totals based on currency
   */
  const totalPerCurrency = useMemo(() => {
    const dict = {};
    allCurrencies.forEach(curr => {
      const total = Object.entries(billAmountState[curr] ?? {})
        .map(([value, amount]) => Number(value) * Number(amount) || 0)
        .reduce((a, b) => a + b, 0);
      dict[curr] = total;
    });
    return dict;
  }, [allCurrencies, billAmountState]);

  const total = useMemo(
    () =>
      Object.entries(billAmounts)
        .map(([value, amount]) => Number(value) * Number(amount) || 0)
        .reduce((a, b) => a + b, 0),
    [billAmounts],
  );

  /**
   * Given a partial counted/register/deposit triplet, and the current total,
   * calculate any missing fields and return the complete triplet
   */
  const calculateCntRegDep = useCallback(
    (counted, register, deposit, total) => {
      const values = {
        counted,
        register,
        deposit,
      };
      if (!showDeposit) Object.assign(values, { deposit: '0.00' });
      if (!values.counted || Number(values.counted) === 0) {
        Object.assign(values, { counted: round(total, 2) });
      }
      if (!values.register && (values.counted || values.deposit)) {
        Object.assign(values, {
          register: round(asFloat(values.counted) - asFloat(values.deposit), 2),
        });
      }
      if (!values.deposit) {
        Object.assign(values, {
          deposit: round(asFloat(values.counted) - asFloat(values.register), 2),
        });
      }

      return values;
    },
    [showDeposit],
  );

  const { counted, register, deposit } = calculateCntRegDep(
    countedState[selectedCurrency],
    registerState[selectedCurrency],
    depositState[selectedCurrency],
    total,
  );

  const missingReasons = [
    // check if there should be CASH reason
    ...(isWithinAllowedDifference(
      Number(expectedCashAmount),
      counted,
      allowedDifference,
    ) || tenders.CASH?.reason >= 0
      ? []
      : ['CASH']),
    // check if there should be others reasons and if they are present
    ...[
      ...Object.keys(tenders),
      ...Object.keys(expectedTenders[selectedCurrency] ?? {}),
    ]
      .filter(a => {
        return a !== 'CASH';
      })
      .filter(type => {
        const expected = expectedTenders[selectedCurrency]?.[type] ?? 0;
        const { counted = '0' } = tenders[type] ?? {};
        if (isWithinAllowedDifference(expected, counted, allowedDifference)) {
          return false;
        }
        return !tenders[type] || tenders[type].reason < 0;
      }),
  ].filter(type => (checkOnlyCash ? type === 'CASH' : type));

  /* Actions */

  // region Close All Days
  const closeDays = useCallback(() => {
    return dispatch(
      closeAllDays(
        allCurrencies.map(currencyCode => {
          // get register, deposit and counted sums for particular currency
          const { deposit, register = '0.00', counted } = calculateCntRegDep(
            countedState[currencyCode],
            registerState[currencyCode],
            depositState[currencyCode],
            totalPerCurrency[currencyCode],
          );
          const tendersWithCountedFallback = spreadTenders(
            R.unless(
              R.path(['CASH', 'counted']),
              R.assocPath(['CASH', 'counted'], counted),
            )(tenderState[currencyCode] ?? {}),
          );

          return {
            closedSum: register,
            bankedSum: deposit,
            ...tendersWithCountedFallback,
            notes,
            currencyCode,
          };
        }),
      ),
    );
  }, [
    dispatch,
    allCurrencies,
    calculateCntRegDep,
    countedState,
    registerState,
    depositState,
    tenderState,
    notes,
    totalPerCurrency,
  ]);

  // endregion

  // check if cash amounts are covered in every currency
  const allCashAmountsCovered =
    Object.entries(expectedTenders).filter(([currencyCode, expAmounts]) =>
      Object.entries(expAmounts ?? {}).find(([tender, expected]) => {
        if (!expected || tender !== 'CASH' || !reasonRequired) return false;
        const countedCash = tenderState[currencyCode]?.CASH?.counted ?? 0;
        return !(expected - Number(countedCash) <= allowedDifference);
      }),
    ).length === 0;

  // region OnSubmit
  const onSubmit = async (): Promise<void | string> => {
    if (reasonRequired && missingReasons.length) {
      dispatch(
        addWarning(t('alerts.reasonsRequired'), {
          selfDismiss: true,
        }),
      );
      setView('tenders');
      return Promise.resolve();
    }

    // check for all currencies
    const allAmountsCovered =
      Object.entries(expectedTenders).filter(([currencyCode, expAmounts]) => {
        // that all tenders
        return Object.entries(expAmounts ?? {}).every(([tender, expected]) => {
          if (!expected || !reasonRequired) return false;
          const countedTender =
            tenderState[currencyCode]?.[tender]?.counted ?? 0;
          const reason = tenderState[currencyCode]?.[tender]?.reason ?? -1;
          // If no reason for difference is set, check that the difference is among the allowed difference
          if (reason < 0) {
            return !(expected - Number(countedTender) <= allowedDifference);
          }
          // Reason for difference is set, so can filter the tender out
          return false;
        });
      }).length === 0;

    if (closingDayNotesMandatory && !allCashAmountsCovered) {
      return new Promise<string>((resolve, reject) => {
        dispatch(
          openModalPage({
            component: modalPages.closeDayNotes,
            isPopup: true,
            props: { resolve, reject },
          }),
        );
      }).then(notes => {
        setNotes(notes);
        setView('confirm');
      });
    }
    if (allAmountsCovered || !showExpected) {
      setView('confirm');
    } else {
      dispatch(
        addWarning(t('alerts.dayCannotBeClosed'), {
          selfDismiss: true,
        }),
      );
    }
    return Promise.resolve();
  };

  const customOnSubmit = async () => {
    if (
      reasonRequired &&
      missingReasons.filter(reason => reason !== 'CASH').length
    ) {
      dispatch(
        addWarning(t('alerts.reasonsRequired'), {
          selfDismiss: true,
        }),
      );
      setView('tenders');
      return;
    }

    // check for all currencies
    const allAmountsCovered =
      Object.entries(expectedTenders).filter(([currencyCode, expAmounts]) => {
        return Object.entries(expAmounts ?? {}).every(([tender, expected]) => {
          if (!expected) return false;
          if (tender === 'CASH') return false;
          const countedTender =
            tenderState[currencyCode]?.[tender]?.counted ?? 0;
          // fulfill (expected - existing < allowed difference)
          return !(expected - Number(countedTender) <= allowedDifference);
        });
      }).length === 0;
    if (allAmountsCovered || !showExpected) {
      setView('confirm');
    } else {
      dispatch(
        addWarning(t('alerts.dayCannotBeClosed'), {
          selfDismiss: true,
        }),
      );
    }
  };
  // endregion

  // region Get totals per currency on load
  useEffect(() => {
    dispatch(getPointOfSaleDayTotals());
  }, [dispatch]);
  // endregion

  // region Fetch EODReasonCodes
  useEffect(() => {
    dispatch(fetchReasonCodes({ lazy: true, purpose: REASONS.EOD_VARIANCE }));
  }, [dispatch]);
  // endregion

  return (
    <Loader show={loading}>
      {
        {
          main: (
            <CloseDayMain
              /* Actions */
              onClickTenders={() => {
                if (!countedState[selectedCurrency] && total !== 0) {
                  updateCounted(round(total, 2) ?? '');
                }
                setView('tenders');
              }}
              /* Data */
              bills={denominations.map(denom => ({
                ...denom,
                amount: billAmounts[denom.value],
              }))}
              onChangeBill={updateBillAmount}
              /* Counted amount */
              counted={countedState[selectedCurrency] ?? ''}
              countedComputed={counted}
              updateCounted={updateCounted}
              /* Register */
              register={registerState[selectedCurrency] ?? ''}
              registerComputed={register ?? '0.00'}
              updateRegister={updateRegister}
              /* Deposit */
              deposit={depositState[selectedCurrency] ?? ''}
              depositComputed={deposit}
              updateDeposit={updateDeposit}
              /* Expected amount and selectedCurrency */
              expectedAmount={expectedCashAmount}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              /* Submit */
              onClickSubmit={onSubmit}
              pluginSubmit={customOnSubmit}
            />
          ),
          tenders: (
            <PluginComponent
              hookname="PluginTenders"
              props={{
                allowedDifference,
                onCancel: () => setView('main'),
                onDone: () => {
                  // Added checks for showExpected in order to allow user to continue with any entered amounts
                  // TODO: to be refactored when more info about allowedDifference, requiredReason and reason codes is provided
                  if (reasonRequired && showExpected) {
                    if (
                      missingReasons.filter(reason => reason !== 'CASH').length
                    ) {
                      return dispatch(addError(t('alerts.invalidAmount')));
                    }
                    if (missingReasons.length) {
                      return dispatch(
                        addWarning(t('alerts.reasonsRequired'), {
                          selfDismiss: true,
                        }),
                      );
                    }
                  } else {
                    setView('main');
                  }
                  return true;
                },
                tenders,
                onChange: (type, newVal) => {
                  const counted = getCounted(newVal);
                  if (type === 'CASH') {
                    updateCounted(counted);
                  }
                  setCurrencyState(state => ({
                    ...state,
                    [selectedCurrency]: {
                      ...state[selectedCurrency],
                      [type]: { ...state[selectedCurrency][type], ...newVal },
                    },
                  }));
                },
                reasons: closeDayVarianceReasons,
                missingReasons: reasonRequired ? missingReasons : [],
                expected: expectedTenders[selectedCurrency],
              }}
            >
              <Tenders
                onCancel={() => setView('main')}
                onDone={() => {
                  // Previous condition was removed by Ruslan.
                  // As long as "Reason Required" is unchecked, allow moving forward even with difference
                  if (reasonRequired && missingReasons.length && showExpected) {
                    dispatch(
                      addWarning(t('alerts.reasonsRequired'), {
                        selfDismiss: true,
                      }),
                    );
                  } else {
                    setView('main');
                  }
                }}
                tenders={tenders}
                onChange={updateTender}
                reasons={closeDayVarianceReasons}
                missingReasons={reasonRequired ? missingReasons : []}
                expected={expectedTenders[selectedCurrency]}
                selectedCurrency={selectedCurrency}
              />
            </PluginComponent>
          ),

          confirm: (
            <Confirmation
              counted={asFloat(counted)}
              tenders={Object.entries(tenders)
                .filter(([key, value]) => key !== 'CASH')
                .map(([key, value]) => asFloat(value.counted))
                .reduce((a, b) => a + b, 0)}
              notes={notes}
              onChangeNotes={e => setNotes(e.target.value)}
              onCancel={() => setView('main')}
              onConfirm={() => closeDays()}
              closingDayNotesMandatory={closingDayNotesMandatory}
              allCashAmountsCovered={allCashAmountsCovered}
            />
          ),
        }[view]
      }
    </Loader>
  );
};

export default CloseDayComponent;
