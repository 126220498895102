import { PatchScript } from '@pos-refacto/patchscript-with-react';
import dayjs from 'dayjs';

import { PosPlugin } from 'plugins/plugin';
import { getCustomer, getPayments } from 'reducers/Payments';
import {
  getCurrencyFormatter,
  getCurrencyFormatterNoSymbol,
  getFallbackLanguagesFor,
} from 'reducers/configs/settings';
import * as GivexAPI from 'plugins/givexHeartland/API/givexAPI';
import { printPatchscript } from 'actions/integrations/printer';
import { getCompany, getLoggedInEmployeeID } from 'reducers/Login';
import { getEmployeeById } from 'reducers/cachedItems/employees';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getLoggedInWarehouse } from 'reducers/warehouses';

import { metaPaymentsPrintout } from './pieces/metaPaymentsPrintout';
import { receiptHeaderDetails } from './pieces/3headerDetails';
import { receiptOfflineBanner } from './pieces/2offlineBanner';
import { receiptLogo } from './pieces/1logo';
import { mmReceiptTranslations } from './pieces/translations/translations';

type GivexCard = {
  cardNo: string;
  pin?: string;
};

type MMReceiptData = {
  company: {
    name: string;
    address: string;
  };
  warehouse: {
    fax: string;
    phone: string;
    /** Not available in offline dataset */
    code?: string;
  };
  employee: {
    name: string;
  };
  customer: {
    cardNumber: string;
  };
  register: {
    name: string;
  };
  receipt: {
    number: string;
  };
  misc: {
    currentTime: string;
  };
  offline: boolean;
};

/**
 * Storage for gift card printouts, so that they can be generated on sale but then printed out later
 * Stored as functions so that the gift card balance can be loaded in lazily
 */
let Memory: null | (() => Promise<PatchScript>)[] = null;

function generateRefillPrintout(
  payment: { cardNo: string; amount: number; balance: number; caption: string },
  data: MMReceiptData,
  t = mmReceiptTranslations.en,
  CURR: ReturnType<typeof getCurrencyFormatterNoSymbol>,
): PatchScript {
  const out: PatchScript = [];
  out.push(receiptLogo);
  if (data.offline) out.push(receiptOfflineBanner(t));
  out.push(...receiptHeaderDetails(data, t));
  out.push(
    ...metaPaymentsPrintout(
      {
        name: payment.caption,
        balance: payment.balance,
        cardNumber: payment.cardNo,
        sum: -payment.amount,
        date: dayjs().format('YYYY-MM-DD'),
        time: dayjs().format('hh:mm:ss'),
      },
      CURR,
    ),
  );

  return out;
}

export const rememberRefillPayments: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, ap) => async (dispatch, getState) => {
  const payments: {
    GIVEX: { card: GivexCard };
    type: string;
    amount: number;
    caption: string;
  }[] = Object.values(getPayments(getState()));
  const refills = payments.filter(
    payment => payment.type === 'META' && payment.GIVEX,
  );

  const printouts = refills.map(({ amount, caption, GIVEX: { card } }) => {
    const state = getState();
    const CURR = {
      stringify: getCurrencyFormatter(state),
      parse: () => {
        throw new Error('Parse not needed');
      },
    };
    const customer = getCustomer(state);
    const mmReceiptData = {
      company: getCompany(state),
      customer: {
        cardNumber: customer.customerCardNumber,
      },
      misc: {
        currentTime: dayjs().format('YYYY-MM-DD hh:mm:ss'),
      },
      offline: false,
      employee: {
        name: getEmployeeById(getLoggedInEmployeeID(state))(state).employeeName,
      },
      receipt: {
        number: String(
          ap.find(req => req.requestName === 'saveSalesDocument')?.invoiceNo ??
            '-',
        ),
      },
      register: getSelectedPos(state),
      warehouse: getLoggedInWarehouse(state),
    } as Parameters<typeof generateRefillPrintout>[1];
    const translations = getFallbackLanguagesFor('receipt')(getState())
      .map(l => mmReceiptTranslations[l])
      .find(a => a);

    return async function() {
      const { certificateBalance } = await GivexAPI.getBalance(card);
      const refillPayment = {
        amount,
        cardNo: card.cardNo,
        balance: Number(certificateBalance),
        caption,
      };
      const printout = generateRefillPrintout(
        refillPayment,
        mmReceiptData,
        translations,
        CURR,
      );
      return printout;
    };
  });
  Memory = printouts;

  return ap;
};

export function printStoredGivexRefillPrintouts() {
  return async function(dispatch, getState) {
    Memory?.forEach(async getPrintout => {
      dispatch(printPatchscript(await getPrintout()));
    });
    Memory = null;
  };
}
export function forgetStoredGivexRegillPrintouts() {
  return async function(dispatch, getState) {
    Memory = null;
  };
}
