import { Typography } from '@material-ui/core';
import React from 'react';

import { PosPlugin } from 'plugins/plugin';

/**
 * Add a warning text if the current customer has no NationalID
 */
export const UICustomerBadgeCustomRegion: PosPlugin['UICustomerBadgeCustomRegion'] = ({
  currentCustomer,
  children,
}) => {
  const missingCode = !currentCustomer.code;
  return (
    <>
      {missingCode && (
        <Typography color="error">
          No national ID set on customer. Default taxes will apply
        </Typography>
      )}

      {children}
    </>
  );
};
