import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as R from 'ramda';

import { openPluginModalPage } from 'actions/modalPage';
import { PosPlugin } from 'plugins/plugin';

import { BankAccountModal } from './components';
import documentation from './documentation.md';
import { RootState } from 'reducers';

const pluginID = 'netsuite-plugin';

const netsuitePlugin: PosPlugin = {
  id: pluginID,
  name: 'NetSuite: Select account during sale',
  keywords: ['net', 'suite', 'netsuite', 'bank', 'account', 'bank account'],
  infoMDUrl: documentation,
  onSaveSalesDocument: {
    on: ({ progress }, ap) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      await dispatch(progress.halt);

      const { attributeName, attributeValue, attributeType } = await dispatch(
        openPluginModalPage('BankAccountModal')({
          isPopup: true,
        }),
      );

      await dispatch(progress.resume);

      return R.map(
        R.when(
          R.propEq('requestName', 'saveSalesDocument'),
          R.pipe(
            R.assoc('attributeName111', attributeName),
            R.assoc('attributeValue111', attributeValue),
            R.assoc('attributeType111', attributeType),
          ),
        ),
      )(ap);
    },
  },
  components: {
    BankAccountModal,
  },
};

export default netsuitePlugin;
