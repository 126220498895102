import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import InputField from 'components/FieldTypes/InputField';
import Loader from 'components/Loader';
import { saveProductGroup } from 'actions/productGroupsDB';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { getProductGroupByID } from 'reducers/productGroupsDB';

import styles from './InputStyle.module.scss';

const ProductGroupEdit = ({ productGroupID }) => {
  const { t } = useTranslation('productGroup');
  const dispatch = useDispatch();

  const group = useSelector(getProductGroupByID(productGroupID)) || {};
  const groupColor = group.attributes?.PosGroupColor

  const [isSaving, setIsSaving] = useState(false);
  const [override, setOverride] = useState(group.name);
  const [color, setColor] = useState(groupColor);

  const hasChanges = override !== group.name || color !== groupColor;


  const close = useCallback(() => {
    dispatch(previousModalPage());
  }, [dispatch, previousModalPage]);

  const save = useCallback(async () => {
    if (hasChanges) {
      setIsSaving(true);
      await dispatch(
        saveProductGroup({
          productGroupID: group.productGroupID,
          name: override,
          attributeName1: 'PosGroupColor',
          attributeType1: 'text',
          attributeValue1: color,
        }),
      );
      setIsSaving(false);
    }
    close();
  }, [group, override, color, dispatch, close, setIsSaving, saveProductGroup]);

  return (
    <Loader
      show={isSaving}
      loadingText={t('loading')}
      style={{ display: 'block' }}
    >
      <div className={classNames(['product-edit', styles.productGroupEdit])}>
        <Modal.Header>
          <span style={{ fontSize: '1.75em', fontWeight: 700 }}>
            {t('edit.title')}
          </span>

          <span style={{ flexGrow: 1 }} />
          <SaveButton action={save} disabled={!hasChanges} />
          <CloseButton action={close} />
        </Modal.Header>

        <Modal.Body>
          <div className={styles.inputContainer}>
            <InputField
              size="lg"
              title={t('inputs.name')}
              value={override}
              onChange={e => setOverride(e.target.value)}
            />
            <InputField
              title={t('edit.backgroundColor')}
              type="text"
              value={color}
              onChange={e => setColor(e.target.value)}
            />
            <div
              style={{
                background: color,
                height: 30,
                margin: 12,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input
                type="color"
                value={color}
                onChange={e => setColor(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
      </div>
    </Loader>
  );
};

export default ProductGroupEdit;
// repeating-linear-gradient(-70deg, #ff08, #ff08 6px, #0008 7px, #0008 12px, #ff08 13px)
