import * as R from 'ramda';
import jwtDecode from 'jwt-decode';

import { PosPlugin } from 'plugins/plugin';
import {
  getClientCode,
  getHasRightToDeleteSale,
  getSessionKey,
} from 'reducers/Login';
import { closeModalPageById, openPluginModalPage } from 'actions/modalPage';
import { modals } from 'plugins/mm/constants';
import { getCurrentModalPage } from 'reducers/modalPage';
import { ErplyAttributes } from 'utils';
import { createConfirmation } from 'actions/Confirmation';
import { deleteSalesDocument } from 'services/ErplyAPI';
import { ErplyApiError } from 'services/ErplyAPI/core/apiErrors';
import { addError } from 'actions/Error';
import {
  ONLINE_ORDER_IDS,
  VIEWED_ONLINE_ORDER_IDS,
  getPluginState,
  setAccessToken,
  setNewOnlineOrderIds,
  setViewedOnlineOrderIds,
} from 'plugins/mm/rdx';
import { getConfiguration } from 'plugins/mm/configuration';
import { getSelectedWarehouseID } from 'reducers/warehouses';

import { baseLog } from './constants';
import * as authenticationApi from './authenticationApi';
import * as onlineOrderApi from './onlineOrderApi';

const log = baseLog.extend('authenticateOrderNotifications');

export const authenticateOrderNotifications: Required<
  PosPlugin
>['onMount'] = () => async (_dispatch, getState) => {
  try {
    log('Getting user credentials from local storage');
    const clientCode = getClientCode(getState());
    const sessionKey = getSessionKey(getState());
    log('Retrieved credentials', {
      clientCode,
      sessionKey,
    });
    if (!clientCode?.length || !sessionKey?.length) return;

    log('Checking if cookie already exists');
    const cookieExists = document.cookie.includes(
      `apisession_${clientCode}=${sessionKey}`,
    );
    if (cookieExists) {
      log('It does');
      return;
    }

    const cookie = `apisession_${clientCode}=${sessionKey}; Path=/; Domain=erply.com; Max-Age=43200;`;
    document.cookie = cookie;
    log('Cookie added successfully', cookie);
  } catch (error) {
    console.error(error);
  }
};

export const hydrateOnlineOrderState: Required<
  PosPlugin
>['onMount'] = () => async dispatch => {
  const newOnlineOrderIds =
    localStorage
      .getItem(ONLINE_ORDER_IDS)
      ?.split(',')
      .map(Number) ?? [];

  const viewedOnlineOrderIds =
    localStorage
      .getItem(VIEWED_ONLINE_ORDER_IDS)
      ?.split(',')
      .map(Number) ?? [];

  dispatch(setNewOnlineOrderIds(newOnlineOrderIds));
  dispatch(setViewedOnlineOrderIds(viewedOnlineOrderIds));
};

export const reopenOrderNotifications: Required<
  PosPlugin
>['onSaveSalesDocument']['after'] = p => async (dispatch, getState) => {
  if (p.salesDocument?.type === 'CREDITINVOICE') return;
  const openedModalPage = getCurrentModalPage(getState());
  if (openedModalPage?.props?.name !== modals.ORDER_NOTIFICATIONS) return;
  const attributes = new ErplyAttributes(p.salesDocument?.attributes ?? [])
    .asDict;

  const savedDocIsOnlineOrder =
    attributes.pickupStatus ||
    attributes.pickupProcessed ||
    attributes.ConfirmInvoice;
  if (!savedDocIsOnlineOrder) return;

  dispatch(closeModalPageById(openedModalPage.id));
  dispatch(
    openPluginModalPage(modals.ORDER_NOTIFICATIONS)({
      modalClassName: openedModalPage.modalClassName,
    }),
  );
};

function getIsDeliveredOrder(req: { requestName: string; [key: string]: any }) {
  if (req.requestName !== 'saveSalesDocument') return false;
  const attributes = ErplyAttributes.fromFlatArray(req);

  return (
    attributes.get('pickupStatus') === 'DELIVERED' &&
    attributes.get('pickupProcessed') === 1
  );
}
export const useDefaultNumberingForDeliveredOrder: Required<
  PosPlugin
>['onSaveSalesDocument']['on'] = (p, requests) => async (
  dispatch,
  getState,
) => {
  const useDefaultNumber = R.dissoc('invoiceNo');
  return R.map(R.when(getIsDeliveredOrder, useDefaultNumber))(requests);
};

const noRightsToDeleteConfirmation = createConfirmation(() => true, null, {
  title: 'Cannot delete the linked layaway',
  body: 'User has no rights to delete the layaway related to the online order',
});

export const deleteLinkedLayaway: Required<
  PosPlugin
>['onSaveSalesDocument']['after'] = p => async (dispatch, getState) => {
  if (p.salesDocument?.type === 'CREDITINVOICE') return;
  const attributes = new ErplyAttributes(p.salesDocument?.attributes ?? [])
    .asDict;
  if (!Number(attributes.layByDocumentID)) return;

  const hasRightToDelete = getHasRightToDeleteSale(getState());
  if (!hasRightToDelete) {
    await dispatch(noRightsToDeleteConfirmation);
    return;
  }

  try {
    await deleteSalesDocument(attributes.layByDocumentID);
  } catch (error) {
    if (error instanceof ErplyApiError) {
      switch (Number(error.errorCode)) {
        case 1063:
          await dispatch(noRightsToDeleteConfirmation);
          return;
        case 1011:
          console.warn(
            'Layaway with given id does not exist. Most likely already be deleted.',
          );
          return;
        default:
          break;
      }
    }
    await dispatch(addError('Failed to delete the linked layaway'));
  }
};

const getAccessToken = () => async (dispatch, getState) => {
  const log = baseLog.extend('getAccessToken');

  const { accessToken } = getPluginState(getState());
  log('Cached access token: ', accessToken);
  const {
    isProduction,
    authenticationService: { username, password },
  } = getConfiguration(getState());

  if (accessToken) {
    let decodedToken: {
      exp: number;
      username: string;
      [key: string]: number | string;
    } | null;
    try {
      decodedToken = jwtDecode(accessToken);
    } catch (error) {
      decodedToken = null;
    }
    if (decodedToken) {
      const willExpireSoon =
        new Date().getTime() + 60e3 >= decodedToken.exp * 1e3;
      const needNewToken = willExpireSoon || decodedToken.username !== username;
      if (!needNewToken) {
        log('Returning cached access token');
        return accessToken;
      }
    }
  }

  try {
    log('Requesting new access token');
    const newAccessToken = await authenticationApi.getAccessToken({
      isProduction,
      username,
      password,
    });
    log('Received new access token successfully');

    dispatch(setAccessToken(accessToken));
    return newAccessToken;
  } catch (error) {
    log('Failed to get new access token');

    throw new Error('Unable to access authentication service', {
      cause: error,
    });
  }
};

export const updateOnlineOrderStatus: Required<
  PosPlugin
>['onSaveSalesDocument']['after'] = (p, o) => async (dispatch, getState) => {
  const log = baseLog.extend('updateOnlineOrderStatus');
  const attributes = new ErplyAttributes(p.salesDocument?.attributes ?? [])
    .asDict;
  const savedDocIsOnlineOrder =
    attributes.pickupStatus ||
    attributes.pickupProcessed ||
    attributes.ConfirmInvoice;
  log(
    'Checking if sale document is confirmed online order',
    savedDocIsOnlineOrder,
  );
  if (!savedDocIsOnlineOrder) return;

  try {
    log('Requesting access token');
    const accessToken = await dispatch(getAccessToken());
    log('Access token received', accessToken);

    const { isProduction } = getConfiguration(getState());
    const clientCode = getClientCode(getState());
    const warehouseId = getSelectedWarehouseID(getState());

    log('Sending request to online order service');
    await onlineOrderApi.updateOrderStatus({
      isProduction,
      accessToken,
      payload: {
        invoiceId: o.salesDocument.invoiceID,
        status: 'DELIVERED',
        orderType: 'delivery',
        clientCode,
        warehouseId,
        success: true,
      },
    });
    log('Updated online order status successfully');
  } catch (error) {
    log('Failed to update online order status');
    console.error('Failed to update online order status', error);
  }
};
