import { getLoggedInWarehouse } from 'reducers/warehouses';
import { addError, addWarning, dismissType } from 'actions/Error';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { getSalesDocuments, saveSalesDocument } from 'services/ErplyAPI';
import { withProgressAlert } from 'actions/actionUtils';

import { PosPlugin } from '../../plugin';

const pluginID = 'location-name-to-receipt-number';

// class PnpInvoiceNumber {
//   version = '1';
//
//   warehouseCode: number;
//
//   posID: number;
//
//   sequence: number;
//
//   constructor(warehouseCode, posID, sequence = 1) {
//     this.warehouseCode = warehouseCode;
//     this.posID = posID;
//     this.sequence = sequence;
//   }
//
//   toString() {
//     return [
//       this.version,
//       String(this.warehouseCode).padStart(3, '0'),
//       String(this.posID).padStart(3, '0'),
//       String(this.sequence).padStart(8, '0'),
//     ].join('-');
//   }
//
// }
/** This method is run when there is no InvoiceNumber stored in cache.
 * It calculates the latest InvoiceNumber with the warehouseCode by doing a binary search
 * through recentSales and finding the latest one that fits the description of
 *
 *   hardcoded: 1
 *   warehouseCode: 555
 *   posID: 001
 *   order of the invoice: 00000002
 *
 *   Resulting invoice number: 1-555-001-00000002
 *
 * Returns order of the latest existing invoice for the warehouse
 *
 * @param code - warehouse code
 * @param posID - Point of sale ID
 *
 * @retuns the order number of latest existing invoice (8 digits)
 * */
const fetchInvoiceNumberForWarehouse = withProgressAlert(
  'Generating next invoice number...',
)((code: string, posID: string) => async (dispatch): Promise<number> => {
  // start and end is the part of the invoice, which is not PosID or WarehouseCode
  // which means that it's basically the order of the receipt
  const startingIdx = Number(`1`);
  let start = Number(`00000000`);
  let end = Number(`99999999`);
  // binary search - try each receipt number and find the latest one
  while (start < end) {
    const mid = Math.ceil((end + start) / 2);
    // eslint-disable-next-line no-await-in-loop
    const [ordinarySales, returnSales] = await Promise.all([
      // Looking thru the existing sales to see if a SALE with this number already exists
      getSalesDocuments({
        number: `${startingIdx}-${code}-${posID}-${`00000000${mid}`.slice(-8)}`,
      }),
      // Looking thru the existing sales to see if a RETURN with this number already exists
      getSalesDocuments({
        number: `${startingIdx}-${code}-${posID}-${`00000000${mid}`.slice(-8)}K`,
      }),
    ]);
    const receiptNumber = ordinarySales[0];
    const returnNumber = returnSales[0];
    // If either a sale or a return with this number exists, search sales with a larger number
    if (receiptNumber || returnNumber) {
      start = mid;
    } else {
      // Sale doesn't exist so search for sale with a smaller number
      end = mid - 1;
    }
  }
  return start;
});

/**
 *
 * Method for getting the latest invoice number from either fetching it from the localstorage and if none
   if there's none there, then we call our binary search method that will find the
   latest invoice number from our database (BO)
 * @param code - warehouse code
 * @param posID - Point of sale ID
 *
 * @returns custom invoice number in a format of 1(hardcoded)-warehouseCode(3 digits)-posID(3 digits)-saleOrder(8 digits)
 * (example 1-111-001-00000005)
 *
 */
const getInvoiceNumberForWarehouse = (code: string, posID: string) => async (
  dispatch,
): Promise<string> => {
  const startingIdx = Number(`1`);
  let lastNo =
    localStorage.getItem(`${pluginID}: lastInvoiceNo ${code}${posID}`) ??
    (await dispatch(fetchInvoiceNumberForWarehouse(code, posID)));
  const nextNumber = Number(lastNo) + 1;
  const [nextReceipt, nextReturn] = await Promise.all([
    getSalesDocuments({
      number: `${startingIdx}-${code}-${posID}-${`00000000${nextNumber}`.slice(-8)}`,
    }),
    getSalesDocuments({
      number: `${startingIdx}-${code}-${posID}-${`00000000${nextNumber}`.slice(-8)}K`,
    }),
  ]);
  // check if an invoice or credit invoice (return) with the number that it's about to get already exists
  if (nextReceipt[0] || nextReturn[0]) {
    lastNo = await dispatch(fetchInvoiceNumberForWarehouse(code, posID));
  }
  // update the invoiceNumber to have both the original number + the WH code
  const incrementedLastNo = `00000000${Number(lastNo) + 1}`.slice(-8);
  const invoiceNumber = `${startingIdx}-${code}-${posID}-${incrementedLastNo}`;
  return invoiceNumber;
};
/**
 * Plugin is used for customizing receipt number.
 * It adds warehouse code (which must be specified to a location through Back Office configs) to start of the receipt number.
 * As well as adds current POS id to the receipt number.
 * Example: 1-111-001-00000004 (1 - hardcoded, 111 - warehouse code, 001 - posID, rest - invoice order)
 * @method getInvoiceNumberForWarehouse gets the latest invoice number from localstorage (if saved previously) or from BO
 * @method fetchInvoiceNumberForWarehouse gets the order of latest sale made from current location and pos using binary search
 * @method
 */
const locationCodeToReceiptNumber: PosPlugin = {
  id: pluginID,
  name: 'PnP - Add location code to the receipt number',
  // language=Markdown
  info: `Plugin modifies the invoice number to contain warehouse code.\n       
  When you make a payment, the invoice number gets saved in the following schema:\n     
  1 - hardcoded number in the beginning,\n     
  3 digits - warehouse code\n     
  3 digits - POS/register ID\n     
  8 digits - order of sale\n     
  Plugin requires you to specify a warehouse code for selected location in the BackOffice.\n     
  Code should be 1)numeric 2)be 1-3 digits long.\n     
  If you do not have the code specified or it is in wrong format you will not be able to make a sale`,
  keywords: ['invoice', 'pnp', 'location', 'warehouse', 'receipt', 'code'],
  getStatus: state => {
    const wc = getLoggedInWarehouse(state).code.trim();
    // in case this gets refactored or something - isNaN is the correct way to check for NaN in here
    // there were 2 attempts at making it use Number.isNaN and it didn't work (javascript)
    if (isNaN(wc)) {
      return {
        type: 'warning',
        message: `You cannot make sales. Reason: Your warehouse code (${wc}) isn't numeric`,
      };
    }
    // in case this gets refactored or something - isNaN is the correct way to check for NaN in here
    // there were 2 attempts at making it use Number.isNaN and it didn't work (javascript)
    if (!isNaN(wc)) {
      if (wc.length > 3) {
        return {
          type: 'warning',
          message: `You cannot make sales. Reason: Warehouse code (${wc}) too long. Limit is 3 digits.`,
          /* ? `Warehouse code: ${wc}`
            : `Warehouse code not specified. You cannot make sales without it.`, */
        };
      }
      if (wc.length === 0) {
        return {
          type: 'warning',
          message: `You cannot make sales. Reason: Warehouse code not specified.`,
        };
      }
    }
    return {
      type: 'valid',
      message: `Warehouse code: ${wc}`,
    };
  },
  onSaveSalesDocument: {
    on: (parameter: any, requests) => async (dispatch, getState) => {
      const tempState = getState();
      // get warehouse id and convert the code into a 4 digit number so that it
      // fits plugin requirement
      const originalWarehouseCode = getLoggedInWarehouse(tempState).code.trim();
      const warehouseCode = `000${originalWarehouseCode}`.slice(-3);
      const initialPosID = getSelectedPosID();
      const posID = `000${initialPosID}`.slice(-3);
      const mapped = requests.map(async request => {
        if (request.requestName === 'saveSalesDocument') {
          // in case this gets refactored or something - isNaN is the correct way to check for NaN in here
          // there were 2 attempts at making it use Number.isNaN and it didn't work (javascript)
          if (isNaN(originalWarehouseCode)) {
            dispatch(
              addError('Warehouse code is not a number', {
                selfDismiss: true,
                dismissible: false,
              }),
            );
            throw new Error('Warehouse code must me numeric');
          }
          if (warehouseCode === '' || warehouseCode === '000') {
            dispatch(
              addError('Cannot use numbering scheme without warehouse code', {
                selfDismiss: true,
                dismissible: false,
              }),
            );
            throw new Error(
              'Cannot use numbering scheme without warehouse code',
            );
          }
          if (originalWarehouseCode.length > 3) {
            dispatch(
              addError('Warehouse code is too long (max 3 digits)', {
                selfDismiss: true,
                dismissible: false,
              }),
            );
            throw new Error('Warehouse code is too long (max 3 digits)');
          }
          // When using "save sale" you can't assign a custom invoiceNo nor customNumber to the sale (to pick it up afterwards and get the custom number)
          if (Number(request.confirmInvoice) === 0) {
            const modifiedRequest = { ...request, invoiceNo: undefined };
            return modifiedRequest;
          }
          if (
            // check if document is picked up order/layaway and assing the same number that was already used
            request.id &&
            (request.type === 'ORDER' || request.type === 'PREPAYMENT')
          ) {
            return request;
          }
          const invoiceNumberForWarehouse =
            // if request has a "number" (order/invoice/etc) then take it instead of generating new number
            request.number ??
            String(
              await dispatch(
                getInvoiceNumberForWarehouse(warehouseCode, posID),
              ),
            );
          const isReturn = request?.type === 'CREDITINVOICE';
          const modifiedRequest = {
            ...request,
            customNumber: isReturn
              ? `${invoiceNumberForWarehouse}K`
              : invoiceNumberForWarehouse,
            invoiceNo: undefined,
          };
          return modifiedRequest;
        }
        return request;
      });
      return Promise.all(mapped);
    },
    after: (params: any, ep) => async (dispatch, getState) => {
      const number = ep.salesDocument.customNumber;
      /* store the latest invoice number WITHOUT the warehouseCode into localStorage
         so that we don't need to fetch it from our BO (which takes around 5-10 seconds)
      */
      // here we check that the receipt number has the plugin specific schema
      const re = /(\d{1})-(\d{3})-(\d{3})-(\d{8})/
      if (!re.test(number)) return;

      const [,fixed, code, posID, sequence] = number.match(re);

      // update the localstorage variable only if the newly created number is larger than the one stored in LS
      const lsKey = `${pluginID}: lastInvoiceNo ${code}${posID}`;
      if (Number(localStorage.getItem(lsKey) ?? '0') < Number(sequence)) {
        localStorage.setItem(lsKey, sequence);
      }
    },
  },
};

export default locationCodeToReceiptNumber;
