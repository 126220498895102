import React from 'react';
import styles from './ageline.module.scss';
import Age from './Age';

const AgeLine = ({ diff }) => {
  return (
    <div className={styles['timeline']} style={{ float: 'left' }}>
      <div className={styles['timeline-items']}>
        {[18, 19, 21, 50].map((age, index) => (
          <Age index={index} diff={diff} key={age} age={age} />
        ))}
      </div>
    </div>
  );
};

export default AgeLine;
