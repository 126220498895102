import { AxiosError } from 'axios';

import { JunctionAPIResponse } from '../types';

import { MSError, TerminalError } from './errors';

export const parseError = (
  error: AxiosError | JunctionAPIResponse | Error | {},
): Promise<never> => {
  // Handle response timeout
  if ('response' in error && Number(error?.response?.status) === 504) {
    throw new TerminalError('Response timed out (504)');
  }

  // Handle terminal declined error
  if ('utcDateTime' in error) {
    const [paymentObj] = error.records;
    throw new TerminalError(
      paymentObj.hostResponse ?? 'Transaction failed',
      error,
    );
  }
  // handle application error
  if (error && 'code' in error) {
    if (error.code === '404')
      throw new MSError(
        'Unable to reach microservice - make sure it is installed.',
      );
    throw new MSError('Request failed');
  }

  console.warn('Unable to parse further information from error', error);
  throw error;
};
