import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import {
  ADD_TOOLTIP,
  HIDE_TOOLTIP,
  RESET_TOOLTIPS,
  SET_SHOPPING_CART_TOOLTIP,
} from 'constants/UI';
import { TYPE_LOGOUT, TYPE_LOGOUT_SOFT } from 'constants/Login';
import { RootState } from 'reducers';

type Tooltip = {
  id: number;
  content: JSX.Element;
  show?: boolean;
};

function tooltips(
  state = [] as Tooltip[],
  { type, payload }: { type: string; payload: Tooltip },
) {
  switch (type) {
    case ADD_TOOLTIP:
      return [
        ...state.map(tooltip => ({ ...tooltip, show: false })),
        { content: payload.content, id: payload.id, show: true },
      ];
    case HIDE_TOOLTIP: {
      const newState = [...state];
      const tooltipIndex = state.findIndex(({ id }) => id === payload.id);
      if (newState[tooltipIndex]) newState[tooltipIndex].show = false;
      return newState;
    }
    case RESET_TOOLTIPS: {
      return [];
    }
    default:
      return state;
  }
}
function shoppingCartTooltip(
  state: JSX.Element | null = null,
  {
    type,
    payload,
  }:
    | { type: typeof HIDE_TOOLTIP; payload: { id: number|'SHOPPING_CART_TOOLTIP' } }
    | { type: typeof RESET_TOOLTIPS; payload: any }
    | { type: typeof SET_SHOPPING_CART_TOOLTIP; payload: JSX.Element }
) {
  switch (type) {
    case HIDE_TOOLTIP:
      if (payload.id !== 'SHOPPING_CART_TOOLTIP') return state;
      return null;
    case TYPE_LOGOUT:
    case TYPE_LOGOUT_SOFT:
    case RESET_TOOLTIPS:
      return null;
    case SET_SHOPPING_CART_TOOLTIP:
      return payload;
    default:
      return state;
  }
}

const customerTooltip = combineReducers({
  tooltips,
  shoppingCartTooltip,
});
export default customerTooltip;

export const getShoppingCartTooltip = createSelector(
  (state: RootState) => state.UI.customerTooltip.shoppingCartTooltip,
  tooltip =>
    tooltip
      ? {
          content: tooltip,
          id: 'SHOPPING_CART_TOOLTIP',
          show: true,
        }
      : null,
);

export const getTooltips = createSelector(
  (state: RootState) => state.UI.customerTooltip.tooltips,
  getShoppingCartTooltip,
  (tooltips, scTooltip) => [...tooltips, scTooltip].filter(a => a),
);
