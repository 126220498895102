import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getPluginSelector } from 'reducers/Plugins';
import { RootState } from 'reducers';
import { getPayments } from 'reducers/Payments';

import { pluginID } from '../constants';
import { GivexCard } from '../types';

interface State {
  cardToDeactivate: (GivexCard & { balance: string }) | null;
  cardPaymentsToRefill: string[];
}

// actions
const types = {
  SET_CARD_TO_DEACTIVATE: `plugin ${pluginID}: SET_CARD_TO_DEACTIVATE`,
  ADD_CARD_TO_REFILL: `plugin ${pluginID}: ADD_CARD_TO_REFILL`,
  REMOVE_CARD_TO_REFILL: `plugin ${pluginID}: REMOVE_CARD_TO_REFILL`,
  RESET_STATE: `plugin ${pluginID}: RESET_STATE`,
} as const;

export function setCardToDeactivate(cardInfo: State['cardToDeactivate']) {
  return {
    type: types.SET_CARD_TO_DEACTIVATE,
    payload: cardInfo,
  };
}

export function addCardToRefill(
  paymentKey: State['cardPaymentsToRefill'][number],
) {
  return {
    type: types.ADD_CARD_TO_REFILL,
    payload: paymentKey,
  };
}

export function removeCardToRefill(
  paymentKey: State['cardPaymentsToRefill'][number],
) {
  return {
    type: types.REMOVE_CARD_TO_REFILL,
    payload: paymentKey,
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

type Action =
  | ReturnType<typeof setCardToDeactivate>
  | ReturnType<typeof addCardToRefill>
  | ReturnType<typeof removeCardToRefill>
  | ReturnType<typeof resetState>;

// reducer
const initialState: State = {
  cardToDeactivate: null,
  cardPaymentsToRefill: [],
};

export function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case types.SET_CARD_TO_DEACTIVATE:
      return {
        ...state,
        cardToDeactivate: (action as ReturnType<typeof setCardToDeactivate>)
          .payload,
      };
    case types.ADD_CARD_TO_REFILL: {
      const paymentKey = (action as ReturnType<typeof addCardToRefill>).payload;
      return {
        ...state,
        cardPaymentsToRefill: [...state.cardPaymentsToRefill, paymentKey],
      };
    }
    case types.REMOVE_CARD_TO_REFILL: {
      const paymentKey = (action as ReturnType<typeof removeCardToRefill>)
        .payload;
      return {
        ...state,
        cardPaymentsToRefill: state.cardPaymentsToRefill.filter(
          key => key !== paymentKey,
        ),
      };
    }
    case types.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

// selectors
function getPluginState(state: RootState) {
  return getPluginSelector<State>(pluginID)(state => state)(state);
}

export function getCardToDeactivate(state: RootState) {
  return getPluginState(state).cardToDeactivate;
}

export const getCardsToRefill = createSelector(
  state => getPluginState(state).cardPaymentsToRefill,
  getPayments,
  (paymentKeys, payments) =>
    R.pipe(
      R.pick(paymentKeys),
      Object.values,
      R.map(payment => ({
        key: payment.key,
        amount: Math.abs(Number(payment.amount)),
        ...(payment.GIVEX?.card ?? {}),
      })),
      R.reject(R.isNil),
    )(payments),
);

export function getCardNeedsToBeRefilled(
  paymentKey: State['cardPaymentsToRefill'][number],
) {
  return (state: RootState) => {
    const paymentKeys = getPluginState(state).cardPaymentsToRefill;
    return paymentKeys.includes(paymentKey);
  };
}
