import { handleWithAction } from '../utils/handleWithAction';

export const handleSuccessRefund = handleWithAction((result, pmt, hooks) =>
  hooks.beforeDocSave({
    key: pmt.key,
    type: 'CARD',
    amount: `-${result.approvedAmount}`,
    attributes: {
      refNo: `${result.authCode}.${result.referenceNumber}`,
    },
    paid: true,
  }),
);
export const handleSuccessVoid = handleWithAction((_, pmt, hooks) =>
  hooks.beforeDocDelete(pmt.key),
);
