import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import { suretaxReducer } from 'plugins/suretax/redux';
import { PosPlugin } from 'plugins/plugin';

const modifyByProp = (prop, value, modify) => children =>
  React.Children.map(
    children,
    R.pipe(
      R.when(R.pathEq(['props', prop], value), modify),
      R.modifyPath(['props', 'children'], modifyByProp(prop, value, modify)),
    ),
  );

/**
 * Modify the price column displayed in the TableBill
 * If suretax not calculated, show '-'
 * If suretax calculated but vatrate is not matching, show error message
 * If suretax calculated and vat rate matches, don't modify (show as normal)
 */
export const UICustomTableBillRow: Required<
  PosPlugin
>['UICustomTableBillRow'] = ({ children, order }) => {
  const taxes = useSelector(suretaxReducer.getCurrent);

  const setPrice = (price: string | JSX.Element) =>
    modifyByProp(
      'data-testid',
      'order-price',
      R.assocPath(['props', 'children'], price),
    )(children);

  const expectedTaxAmount = Number(
    taxes?.GroupList.find(
      gr => String(gr.LineNumber) === String(order.rowNumber),
    )
      ?.TaxList.map(tax => Number(tax.TaxAmount))
      .reduce(R.add, 0),
  );
  const needsUpdate =
    (order.rowNetTotal + expectedTaxAmount).toFixed(2) !==
    order.rowTotal.toFixed(2);

  const idealVatrate = 100 * (expectedTaxAmount / order.rowNetTotal);

  const actual = Number(order.vatRate);
  if (!needsUpdate) return children;

  if (Number.isNaN(expectedTaxAmount)) return setPrice('-');
  return setPrice(
    <Typography color="error">
      Needs update from {actual}% to {idealVatrate}% to get vat{' '}
      {expectedTaxAmount} instead of {order.rowVATTotal.toFixed(2)}
    </Typography>,
  );
};
