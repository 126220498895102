import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import {
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { addError, addSuccess } from 'actions/Error';

import { pluginID } from './constants';

export interface Config {
  isProduction: boolean;
  couponServiceTokenSalt: string;
  authenticationService: {
    username: string;
    password: string;
  };
}

export const getConfiguration = getPluginConfiguration<Config>(pluginID);

export const Configuration: Required<
  PosPlugin<Config>
>['ComponentConfigurationByLevel']['Company'] = ({ save, current }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(current);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function saveConfig() {
    if (!value || !Object.keys(value).length) {
      dispatch(addError('Configuration is empty'));
      return;
    }
    save(value);
    dispatch(addSuccess('Configuration saved'));
  }

  return (
    <>
      <Box margin="1rem 0">
        <Typography variant="h6">Services</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={value?.isProduction ?? false}
              onChange={e =>
                setValue(R.assoc('isProduction', e.target.checked))
              }
            />
          }
          label="Use production environment for M&M services"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Coupon service token salt"
          value={value?.couponServiceTokenSalt ?? ''}
          onChange={e =>
            setValue(R.assoc('couponServiceTokenSalt', e.target.value))
          }
        />

        <Typography variant="h6" style={{ marginTop: '1rem' }}>
          Authentication service
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Username"
          value={value?.authenticationService?.username ?? ''}
          onChange={e =>
            setValue(
              R.assocPath(
                ['authenticationService', 'username'],
                e.target.value,
              ),
            )
          }
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Password"
          type={isPasswordVisible ? 'text' : 'password'}
          value={value?.authenticationService?.password ?? ''}
          onChange={e =>
            setValue(
              R.assocPath(
                ['authenticationService', 'password'],
                e.target.value,
              ),
            )
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsPasswordVisible(prev => !prev)}>
                  {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={saveConfig}>
        Save
      </Button>
    </>
  );
};
