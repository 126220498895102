import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { PosPlugin } from 'plugins/plugin';
import { REDUX_CUSTOMER_REGISTRY_URL } from 'constants/persistence';

import { Conf, CustomerType, globalFields } from './constants';

type Props = {
  searchInCombination: boolean;
  updateSearchInCombination: (newValue: boolean) => void;
  fields: typeof globalFields[CustomerType];
  savedFields: Record<string, boolean>;
  onChange: (key: string, value: boolean) => void;
  customerTypeSelected: CustomerType;
  hideButton: boolean;
  toggleButton: (p: boolean) => void;
  isSupportedByCustomerRegistryApi: (key: string, tab: string) => boolean;
  selectedUnsupportedFields: string[];
};

type UnsupportedCheckedFieldsType = {
  [type: string]: string[];
};

const Config: React.FC<Props> = ({
  searchInCombination,
  updateSearchInCombination,
  fields,
  savedFields,
  onChange,
  customerTypeSelected,
  hideButton,
  toggleButton,
  isSupportedByCustomerRegistryApi,
  selectedUnsupportedFields,
}) => {
  return (
    <>
      <Box padding="0.75rem">
        <Typography variant="h5">
          You can configure if you want to check if there is a{' '}
          {customerTypeSelected.toLowerCase()} that has all the below fields
          duplicated or if any of the below fields are duplicated
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={searchInCombination}
              key="combineFields"
              onChange={() => {
                updateSearchInCombination(!searchInCombination);
              }}
            />
          }
          label="Check if customer has ALL selected fields duplicated"
        />
      </Box>
      <Box padding="0.75rem">
        <Typography variant="h5">
          Below you can configure which fields you want to check the{' '}
          {customerTypeSelected.toLowerCase()} by
        </Typography>
        {Object.entries(fields).map(([k, v]) => {
          const enabled = savedFields?.[k] ?? false;
          const isUnsupportedSelected =
            !isSupportedByCustomerRegistryApi(k, customerTypeSelected) &&
            enabled &&
            selectedUnsupportedFields.length > 0;
          return (
            <FormControlLabel
              key={k}
              control={
                <Checkbox
                  checked={enabled}
                  onChange={(e, newValue) => onChange(k, newValue)}
                />
              }
              label={
                <Typography
                  color={isUnsupportedSelected ? 'error' : 'textPrimary'}
                >
                  {v.label}
                </Typography>
              }
            />
          );
        })}
      </Box>
      <Box padding="0.75rem">
        <Typography variant="h5">
          Hide button allowing to save a customer if it duplicated?
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideButton}
              key="showMessage"
              onChange={() => {
                toggleButton(!hideButton);
              }}
            />
          }
          label="Hide button"
        />
      </Box>
    </>
  );
};

const Configuration: Required<
  PosPlugin<Conf>
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const dispatch = useDispatch();
  const defaultFields = {
    PERSON: {},
    COMPANY: {},
  };

  const isUsingCustomerRegistry = !!localStorage.getItem(
    REDUX_CUSTOMER_REGISTRY_URL,
  );

  const defaultSearchInCombination = { PERSON: false, COMPANY: false };
  const [savedFields, setSavedFields] = useState(
    current?.fields ?? defaultFields,
  );

  const [hideButton, toggleButton] = useState<boolean>(
    current?.hideButton || false,
  );

  const [activeTab, setActiveTab] = useState<CustomerType>('PERSON');

  const [searchInCombination, setSearchInCombination] = useState(
    current?.searchInCombination ?? defaultSearchInCombination,
  );
  const isSupportedByCustomerRegistryApi = (key: string, tab: string) =>
    !!globalFields[tab][key].customerRegistryParam;

  // check for the unsupported checked fields so we can make them red when we first open the page or check fields
  const selectedUnsupportedFields = useMemo(() => {
    const unsupportedCheckedFields: UnsupportedCheckedFieldsType = {
      PERSON: [],
      COMPANY: [],
    };
    if (!isUsingCustomerRegistry) {
      return unsupportedCheckedFields;
    }
    Object.keys(savedFields).forEach(type => {
      Object.keys(savedFields[type]).forEach(key => {
        if (
          savedFields[type][key] &&
          !isSupportedByCustomerRegistryApi(key, type)
        ) {
          unsupportedCheckedFields[type].push(key);
        }
      });
    });
    return unsupportedCheckedFields;
  }, [isUsingCustomerRegistry, savedFields]);

  const saveConf = () => {
    save({ fields: savedFields, searchInCombination });
    dispatch(previousModalPage());
  };

  const updateSearchInCombination = (newValue: boolean) =>
    setSearchInCombination(prev => ({ ...prev, [activeTab]: newValue }));

  const onChange = (key: string, value: boolean) => {
    setSavedFields(prev => {
      return {
        ...prev,
        [activeTab]: {
          ...prev[activeTab],
          [key]: value,
        },
      };
    });
  };

  const resetConfig = () => {
    save({
      fields: defaultFields,
      searchInCombination: defaultSearchInCombination,
    });
    setSavedFields(defaultFields);
    setSearchInCombination(defaultSearchInCombination);
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
      >
        <Tab label="PERSON" value="PERSON" />
        <Tab label="COMPANY" value="COMPANY" />
      </Tabs>
      <Config
        searchInCombination={searchInCombination[activeTab]}
        fields={globalFields[activeTab]}
        updateSearchInCombination={updateSearchInCombination}
        savedFields={savedFields[activeTab] ?? {}}
        onChange={onChange}
        customerTypeSelected={activeTab}
        toggleButton={toggleButton}
        hideButton={hideButton}
        isSupportedByCustomerRegistryApi={isSupportedByCustomerRegistryApi}
        selectedUnsupportedFields={selectedUnsupportedFields[activeTab]}
      />

      <Box display="flex" gridGap="1rem">
        {!savedFields.COMPANY || !savedFields.PERSON ? (
          <Button
            variant="contained"
            color="primary"
            disabled={R.equals(current?.fields, savedFields)}
            fullWidth
            onClick={resetConfig}
          >
            Reset all fields
          </Button>
        ) : null}

        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={saveConf}
        >
          Save
        </Button>
      </Box>
      <Box textAlign="center">
        {selectedUnsupportedFields[activeTab].length > 0 && (
          <Typography variant="body1" color="error">
            The red fields are not supported by the customer registry API and
            and as a result, duplication in these fields will not trigger a
            warning.
          </Typography>
        )}
      </Box>
    </>
  );
};
export default Configuration;
