import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import InputField from 'components/FieldTypes/InputField';
import { insertAfter } from 'plugins/oneAcrePergamonFiscal/utils';
import styles from 'components/FieldTypes/skins/skins.module.scss';

export const WithCustomerPin: Required<PosPlugin>['UICustomerForm'] = ({
  value,
  onChange,
  children,
}) => {
  const customerPinLens = R.lensPath(['attributes', 'customer_pin']);
  return R.pipe(
    React.Children.toArray,
    insertAfter(
      /customerCardNumber/,
      <Form.Group>
        <InputField
          key="customerPin"
          type="text"
          size="lg"
          title="Buyer KRA PIN"
          className={classNames([styles.formInput, styles.longTitle])}
          onChange={e => onChange(R.set(customerPinLens, e.target.value))}
          value={R.view(customerPinLens, value) ?? ''}
        />
      </Form.Group>,
    ),
  )(children);
};
