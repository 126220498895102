import React from 'react';

import { sort } from 'utils/sort';
import Options from 'components/Options';
import Pagination from 'components/Pagination';

import CustomTable from './CustomTable';
import DraggableTable from './DraggableTable';

class SmartTable extends React.Component {
  static defaultProps = {
    defaultfields: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      itemsPerPage: 5,
      currentPage: 1,
      creteria: '',
      ascending: true,
    };
  }

  setItemsPerPage = itemsPerPage =>
    this.setState({ itemsPerPage, currentPage: 1 });

  setPage = currentPage => this.setState({ currentPage });

  setSorting = name => {
    const { creteria, ascending } = this.state;
    if (creteria === name) {
      if (!ascending) {
        this.setState({ creteria: '', ascending: true });
      } else {
        this.setState({ ascending: false });
      }
    } else {
      this.setState({ creteria: name, ascending: true });
    }
  };

  render() {
    const {
      data,
      fields,
      withOptions,
      draggable,
      pagination,
      setItems,
      columns,
      toggleColumn,
      order,
      setOrder,
      products,
      options,
      ...restProps
    } = this.props;
    const { itemsPerPage, currentPage, creteria, ascending } = this.state;

    const dataPerPage = sort(data, creteria, ascending)
      .filter(a => a)
      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const table = draggable ? (
      <DraggableTable
        columns={columns.map(key => options[key])}
        data={dataPerPage}
        order={order}
        setOrder={setOrder}
        setSorting={this.setSorting}
        creteria={creteria}
        ascending={ascending}
        setItems={setItems}
        {...restProps}
      />
    ) : (
      <CustomTable
        columns={columns}
        data={dataPerPage}
        setSorting={this.setSorting}
        creteria={creteria}
        ascending={ascending}
        setItems={setItems}
        {...restProps}
      />
    );
    return (
      <div className="App">
        {withOptions && (
          <Options
            options={Object.keys(fields).map(f => fields[f])}
            selectedOptions={columns}
            onChange={toggleColumn}
          />
        )}
        <div style={{ overflow: 'auto' }}>
          {pagination ? (
            <Pagination
              setItemsPerPage={this.setItemsPerPage}
              pagination={pagination}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalRecords={data.length}
              setPage={this.setPage}
            >
              {table}
            </Pagination>
          ) : (
            table
          )}
        </div>
      </div>
    );
  }
}

export default SmartTable;
