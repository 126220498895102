import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Paper,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';

import SaveButton from 'components/CustomButtons/SaveButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { modalPages as mp } from 'constants/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getUserRights } from 'reducers/Login';
import { getEmployeesRespectingLocationOption } from 'reducers/cachedItems/employees';

const Employees: React.FC<{ children?: never; onClose: () => void }> = ({
  onClose,
}) => {
  const { t } = useTranslation('settingsEmployees');
  const allEmployees = useSelector(getEmployeesRespectingLocationOption);
  const rightsOverEmployees = useSelector(getUserRights).modules.employees;

  const dispatch = useDispatch();

  const employees = useMemo(
    () =>
      allEmployees
        .sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
          const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .map(employee => (
          <TableRow
            key={employee.id}
            className="company-employee"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              dispatch(
                openModalPage({
                  component: mp.editEmployee,
                  props: { actionToPerform: 'edit', employeeData: employee },
                }),
              )
            }
          >
            <TableCell>{`${employee.firstName} ${employee.lastName}`}</TableCell>
            <TableCell>{employee.email}</TableCell>
            <TableCell>{employee.phone}</TableCell>
          </TableRow>
        )),
    [allEmployees],
  );

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ margin: '1.5rem' }}
      >
        <Typography variant="h5">{t('title')}</Typography>
        <Box display="flex">
          <SaveButton
            title={t('common:add_new')}
            action={() =>
              dispatch(
                openModalPage({
                  component: mp.editEmployee,
                  props: { actionToPerform: 'add' },
                }),
              )
            }
            disabled={rightsOverEmployees.add === 0}
          />
          <CloseButton action={onClose} />
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ padding: '1,5rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('table.name')}</TableCell>
              <TableCell>{t('table.email')}</TableCell>
              <TableCell>{t('table.phone')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{employees}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Employees;
