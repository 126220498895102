import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getTooltips } from 'reducers/UI/customerTooltip';
import { hideTooltip } from 'actions/UI';
import { useBreakpoints } from 'utils/hooks/UI';

const CustomerTooltip = () => {
  const dispatch = useDispatch();
  const tooltips = useSelector(getTooltips);
  const isMobileView = !useBreakpoints().mdplus;

  const memoizedTooltips = useMemo(
    () =>
      tooltips.map(({ show, content, id }) => {
        return (
          <Overlay
            target={document.querySelector('#user-icon')}
            key={id}
            show={show}
            onClick={e => e.stopPropagation()}
            placement={isMobileView ? 'left' : 'right'}
            popperConfig={{
              modifiers: { computeStyle: { gpuAcceleration: false } },
            }}
          >
            <Popover
              className="customer-tooltip"
              style={{
                fontSize: '14px',
                boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
                zIndex: '1035',
              }}
            >
              <div style={{ margin: '0 20px 0 0', padding: '8px' }}>
                <CloseButton
                  action={() => dispatch(hideTooltip(id))}
                  style={{
                    margin: 0,
                    fontSize: '35px',
                    lineHeight: 0.5,
                    position: 'absolute',
                    right: '5px',
                  }}
                />
                {content}
              </div>
            </Popover>
          </Overlay>
        );
      }),
    [dispatch, isMobileView, tooltips],
  );

  return <>{memoizedTooltips}</>;
};

export default CustomerTooltip;
