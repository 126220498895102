import { addError } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { PosPlugin } from 'plugins/plugin';
import { getPluginSelector } from 'reducers/Plugins';
import { TYPE_LOGOUT } from 'constants/Login';
import { ErplyAttributes } from 'utils';
import { getUserGroups } from 'services/ErplyAPI/api';
import { getUserLoggedIn } from 'reducers/Login';
import layers from 'assets/layers.module.css';

import PnpSecurityPlugin from '..';

type State = Record<
  Override['context'],
  Record<Override['key'], { text: string; employeeID: string }>
>;

type ActionUnset = {
  type: 'plugin-pnpSecurity UNSET_OVERRIDES';
  payload: {
    reasons: string[];
  };
};
type ActionSet = {
  type: 'plugin-pnpSecurity SET_OVERRIDES';
  payload: {
    overrides: {
      reason: string;
      text: string;
      employeeID: string;
      context: Override['context'];
    }[];
  };
};
type ActionClear = {
  type: 'plugin-pnpSecurity CLEAR_OVERRIDES';
  payload: {
    context?: Override['context'];
  };
};

const typeSafeEntries = <T extends Record<any, any>>(
  obj: T,
): T extends Record<infer K, infer V> ? [K, V][] : [string, any] => {
  return Object.entries(obj) as any;
};

export const reducer: PosPlugin['reduxReducer'] = (
  state: State = {
    salesDoc: {},
    dayOpening: {},
    cashInOut: {},
  },
  action: any,
): State => {
  switch (action.type) {
    case 'plugin-pnpSecurity CLEAR_OVERRIDES': {
      if ((action as ActionClear).payload.context) {
        return Object.fromEntries(
          typeSafeEntries(state).map(([context, value]) => [
            context,
            context === (action as ActionClear).payload.context ? {} : value,
          ]),
        ) as State;
      }
    }
    // fallthrough
    case TYPE_LOGOUT:
      return {
        salesDoc: {},
        dayOpening: {},
        cashInOut: {},
      };
    case 'plugin-pnpSecurity UNSET_OVERRIDES': {
      return {
        salesDoc: Object.fromEntries(
          typeSafeEntries(state.salesDoc).filter(([reason]) =>
            (action as ActionUnset).payload.reasons.every(r => reason !== r),
          ),
        ),
        dayOpening: Object.fromEntries(
          typeSafeEntries(state.dayOpening).filter(([reason]) =>
            (action as ActionUnset).payload.reasons.every(r => reason !== r),
          ),
        ),
        cashInOut: Object.fromEntries(
          typeSafeEntries(state.cashInOut).filter(([reason]) =>
            (action as ActionUnset).payload.reasons.every(r => reason !== r),
          ),
        ),
      };
    }
    case 'plugin-pnpSecurity SET_OVERRIDES': {
      const setAction = action as ActionSet;
      return Object.fromEntries(
        Object.entries(state).map(([context, overrides]) => [
          context,
          {
            ...overrides,
            ...Object.fromEntries(
              setAction.payload.overrides
                .filter(reason => reason.context === context)
                .map(reason => [
                  reason.reason,
                  { text: reason.text, employeeID: reason.employeeID },
                ]),
            ),
          },
        ]),
      ) as State;
    }
    default:
      return state;
  }
};

/* ***********
 *  ACTIONS
 * ********** */

export const setOverrides = (
  overrides: ActionSet['payload']['overrides'],
): ActionSet => ({
  type: 'plugin-pnpSecurity SET_OVERRIDES',
  payload: {
    overrides,
  },
});
export const unsetOverrides = (
  reasons: ActionUnset['payload']['reasons'],
): ActionUnset => ({
  type: 'plugin-pnpSecurity UNSET_OVERRIDES',
  payload: {
    reasons,
  },
});
export const resetOverrides = (context: Override['context']): ActionClear => ({
  type: 'plugin-pnpSecurity CLEAR_OVERRIDES',
  payload: {
    context,
  },
});

/* ***********
 *  Selectors
 * ********** */

const getState = (state): State =>
  getPluginSelector<State>(PnpSecurityPlugin.id)((a: State) => a)(state);
const getStateForContext = (context: Override['context']) => state =>
  getState(state)[context];

export const getAllOverridesString = (context: Override['context']) => state =>
  Object.values(getStateForContext(context)(state))
    .map(r => r.text)
    .join('\n');

export const getOverrideAttributes = (
  context: Override['context'],
) => state => {
  const overrides = getStateForContext(context)(state);
  return new ErplyAttributes().set(
    'pnp-security-overrides',
    JSON.stringify(
      Object.fromEntries(
        Object.entries(overrides).map(([k, v]) => [k, v.employeeID]),
      ),
    ),
  );
};
export const getHasOverrides = (context: Override['context']) => state =>
  Object.values(getStateForContext(context)(state)).filter(a => a).length !== 0;

const singlePrompt = (messages: string[]) => async (dispatch, getState) => {
  return new Promise((resolve, reject) =>
    dispatch(
      openModalPage({
        component: mp.pluginModal,
        modalClassName: layers.confirmation,
        props: {
          name: 'pnpSecurityPopup',
          resolve,
          reject,
          onLogin: user => {
            dispatch(previousModalPage());
            resolve(user);
          },
          messages,
          onCancel: () => {
            dispatch(addError('User cancelled action'));
            dispatch(previousModalPage());
            reject(new Error('User cancelled action'));
          },
        },
        isPopup: true,
      }),
    ),
  );
};

export type Override = {
  key: string;
  POSText: string;
  BOText: string;
  isValidOverride: (group: { groupID: string; attributes: any }) => boolean;
  context: 'salesDoc' | 'dayOpening' | 'cashInOut';
};

export const promptForOverrides = (reasons: Override[]) => async (
  dispatch,
  getState,
) => {
  const user = getUserLoggedIn(getState());
  const [group] = await getUserGroups({ userGroupID: user.groupID });

  let remainingReasons = reasons.filter(r => !r.isValidOverride(group));
  const overrides: Record<
    string,
    {
      reason: string;
      text: string;
      employeeID: string;
      context?: Override['context'];
    }
  > = {};
  while (true) {
    // If everything has been overridden, save it to redux and return
    if (!Object.keys(remainingReasons).length) {
      dispatch(
        setOverrides(
          Object.values(overrides).map(v => ({ context: 'salesDoc', ...v })),
        ),
      );
      return;
    }
    // Else prompt for a user
    // eslint-disable-next-line no-await-in-loop
    const user = await dispatch(
      singlePrompt(remainingReasons.map(r => r.POSText)),
    );
    // And then use them to override everything they can
    // eslint-disable-next-line no-await-in-loop
    const [group] = await getUserGroups({ userGroupID: user.groupID });

    remainingReasons = remainingReasons.filter(reason => {
      if (reason.isValidOverride(group)) {
        overrides[reason.key] = {
          reason: reason.key,
          text: `${reason.BOText}: overridden by ${user.employeeName}`,
          employeeID: user.employeeID,
          context: reason.context,
        };
        return false;
      }
      return true;
    });
  }
};
