import { DeepPartial } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { urlEncode, sleep } from 'utils';
import { proxy } from 'services/shared';
import { getPluginConfiguration } from 'reducers/Plugins';
import { pluginId } from 'plugins/pnp/carSales/constants';

import { Configuration, defaultConfiguration } from '..';

export interface CFJCVehicle {
  vin: string;
  make: string;
  model: string;
  year: string;
  stock: {
    stockId: number;
    /** @example "42-W05-172" */
    stockNo: string;
    /** @example "2020-05-13T10:19:35.02" */
    stockDate: string;
  };
}
export const createCFJCVehicle = ([
  vin = '',
  stockNo = '',
  year = '',
  make = '',
  model = '',
] = []): CFJCVehicle => ({
  vin,
  make,
  model,
  year,
  stock: {
    stockId: NaN,
    stockNo,
    stockDate: '',
  },
});

export const getVehicles = (
  criteria: string,
): ThunkAction<Promise<CFJCVehicle[]>, any, any, any> => async (
  dispatch,
  getState,
) => {
  if (!criteria || !criteria.length)
    return Promise.reject(new Error('Criteria must be specified'));

  const { url, token } =
    getPluginConfiguration<Configuration>(pluginId)(getState()) ??
    defaultConfiguration;
  return window
    .fetch(
      `${proxy}${url}?${urlEncode({
        token,
        criteria,
      })}`,
    )
    .then(res => res.json());
};
