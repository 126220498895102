import { compare } from 'compare-versions';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
  API,
  installerStatus$,
  InstallerStatus,
} from '@pos-refacto/installer-rxjs';
import { useObservable } from 'react-use';

import { getCafaEntry } from 'reducers/cafaConfigs';
import { integrationMapper } from 'constants/installer';

// prettier-ignore
const MINIMUM_SUPPORTED_MS_VERSIONS = {
  "adyen-integration":                 "v1.0.22", // Adyen ms
  "bsoft-fiscal":                      "v0.1.8",  // bsoft-fiscal (Garmin Chile)
  "cayan-integration":                 "v1.5.15", // cayan-integration
  "chase-ingenico-ict250-driver":      "v0.2.3",  // Chase
  "chile-fiscal-service":              "v0.0.1",  // Fiscal printing MS
  "efsta-fiscal-proxy":                "v0.0.8",  // efsta-fiscal-proxy
  "extdev-microservice":               "v0.1.20", // Extdev Microservice
  "givex-direct-driver":               "v1.0.9",  // Givex Direct Driver
  "linkpos-integration":               "v1.0.5",  // LinkPOS (Thai)
  "local-proxy-ms":                    "v0.0.5",  // local-proxy-ms
  "logger-ms":                         "v0.0.4",  // logger-ms
  "metrics-collector-ms":              "v0.0.1",  // metrics-collector-ms
  "moneris-integration":               "v1.0.14", // Moneris (Canada)
  "pc-eftpos-integration":             "v0.1.19", // PC-EFTPOS
  "pcard-payment-ms":                  "v0.0.5",  // PCARD
  "pos-junction-integration":          "v0.1.13", // Junction (Africa)
  "printing-microservice":             "v2.1.45", // Printing-microservice
  "receipt-printout-api":              "v0.0.5",  // Receipt Printout API
  "redeban-integration":               "v1.0.8",  // redeban-integration
  "scale-fairbanks":                   "v0.0.4",  // scale-fairbanks
  "sonet-payment-ms":                  "v0.0.14", // Sonet (Czech)
  "swedbank-ingenico":                 "v1.5.0",  // swedbank-ingenico
  "vantiv-tripos-golang-microservice": "v1.0.19", // TriPos
  "verifone-fin":                      "v1.0.5",  // Verifone FIN
  "geocom-ms":                         undefined,
  "pax-poslink-integration":           undefined,
  "sweda34f-scale":                    undefined,
  "installer2":                        "v2.1.0",     // Installer / Wizard
}

// prettier-ignore
const INTEGRATION_DEFAULT_PORTS = {
  "adyen-integration":            "10108", // Adyen ms
  "chile-fiscal-service":         "10124", // Fiscal printing MS
  "bsoft-fiscal":                 "10120", // bsoft-fiscal (Garmin Chile)
  "cayan-integration":            "10103", // cayan-integration
  "chase-ingenico-ict250-driver": "10100", // Chase
  "redeban-integration":          "10106", // redeban-integration
  "sonet-payment-ms":             "10112", // Sonet (Czech)
  "pos-junction-integration":     "10107", // Junction (Africa)
  "pcard-payment-ms":             "10113", // PCARD
  "efsta-fiscal-proxy":           "10123", // efsta-fiscal-proxy
  "extdev-microservice":          "5658",  // Extdev Microservice
  "givex-direct-driver":          "5658",  // Givex Direct Driver
  "linkpos-integration":          "10110", // LinkPOS (Thai)
  "local-proxy-ms":               "10902", // local-proxy-ms
  "logger-ms":                    "10900", // logger-ms
  "metrics-collector-ms":         "10901", // metrics-collector-ms
  "moneris-integration":          "10109", // Moneris (Canada)
  "pc-eftpos-integration":        "10105", // PC-EFTPOS
  "printing-microservice":        "5000",  // Printing-microservice
  "receipt-printout-api":         "5651",  // Receipt Printout API
  "scale-fairbanks":              "10111", // scale-fairbanks
  "swedbank-ingenico":            "10104", // swedbank-ingenico
  "installer2":                   "7777",  // Installer / Wizard
}

type Int = typeof integrationMapper[keyof typeof integrationMapper];
type IntWithEntity = Exclude<Int, { entity: '' }>;
const MSData: IntWithEntity[] = Object.values(integrationMapper).filter(
  (int: Int): int is IntWithEntity => !!int.entity,
);

const useAccessible = (
  entity: string,
  check = false,
  installerStatus: InstallerStatus,
) => {
  const [accessible, setAccessible] = useState<undefined | boolean>(undefined);
  const defaultPort = INTEGRATION_DEFAULT_PORTS[entity];
  const installedVersion = installerStatus?.data?.integrations?.find(
    int => int.name === entity,
  )?.version;
  useEffect(() => {
    setAccessible(undefined);
  }, [installedVersion]);
  useEffect(() => {
    if (accessible === false) {
      const i = setTimeout(() => setAccessible(undefined), 1e3);
      return () => clearTimeout(i);
    }
    return () => {};
  }, [accessible]);
  useEffect(() => {
    if (accessible === undefined && check && defaultPort) {
      (installedVersion
        ? API.getIntegrationConfigValue(entity, installedVersion)
            .then(res => JSON.parse(res.data.config))
            .then(conf => conf.Port)
        : Promise.reject()
      )
        .catch(error => {
          console.warn(error);
          return defaultPort;
        })
        .then(port =>
          window.fetch(
            `https://localhost.erply.com:${port}/api/v1/service/info`,
          ),
        )

        .then(res => res.json())
        .then(res => {
          if (res.message) return JSON.parse(res.message)[0];
          return res.records[0].certs[0];
        })
        .then(cert => cert.NotAfter)
        .then(notAfter => new Date(notAfter).getTime() > Date.now())
        .catch(() => false)
        .then(setAccessible);
    }
  }, [accessible, check, defaultPort]);
  return accessible;
};
const useLatest = (entity: string, fetch = false) => {
  const [latest, setLatest] = useState<undefined | string>(undefined);
  useEffect(() => {
    if (!latest && fetch) {
      API.getIntegrationVersion(entity)
        .then(res => res.data.builds.slice(-1)[0])
        .then(setLatest);
    }
  }, [fetch, latest]);
  return latest;
};

export const useMSAccessible = (
  installerStatus: InstallerStatus,
  expected: Record<string, unknown>,
): {
  [entity: string]: boolean | undefined;
} => {
  const u = useAccessible;
  const accessible = Object.fromEntries(
    Object.keys(INTEGRATION_DEFAULT_PORTS).map(entity => {
      const isRunning =
        installerStatus?.data?.integrations.find(int => int.name === entity)
          ?.status === 'StatusRunning';
      const isRequired = !!expected[entity];
      if (entity === 'local-proxy-ms') return [entity, true];
      return [
        entity,
        u(entity, isRunning && isRequired, installerStatus),
      ] as const;
    }),
  );
  return useMemo(() => accessible, [JSON.stringify(accessible)]);
};

export const useMSRequirements = (
  installerStatus: InstallerStatus | Error | undefined,
): {
  [entity: string]: { version: string | undefined; config: any };
} => {
  const u = useSelector;
  const links = MSData.map(
    ({ id, type, entity }) =>
      [entity, u(getCafaEntry(id, type))?.value] as const,
  );

  const needEntities = Object.fromEntries(
    links
      .filter(([, conf]) => conf)
      .map(([name, { version, ...config }]) => [
        name,
        { version: version ?? 'latest', config },
      ]),
  );

  const u2 = useLatest;
  const linksWithLatestsBaked = Object.fromEntries(
    links.map(([entity, conf]) => [
      entity,
      u2(
        entity,
        needEntities[entity]?.version === 'latest' ||
          !needEntities[entity]?.version,
      ),
    ]),
  );

  const needEntitiesWithLatestsBaked = Object.fromEntries(
    Object.entries(needEntities).map(([name, { version, config }]) => {
      const latest = linksWithLatestsBaked[name];
      if (version === 'latest') return [name, { version: latest, config }];
      const minimum = MINIMUM_SUPPORTED_MS_VERSIONS[name];
      if (!minimum) return [name, { version, config }];
      // Case: Target version is configured, use it or the minimum
      if (version) {
        const tgt = version.replace(/v/, '');
        const min = minimum.replace(/v/, '');
        return [
          name,
          { version: compare(tgt, min, '<') ? minimum : version, config },
        ];
      }

      if (installerStatus instanceof Error) return [name, { version, config }];
      const currentVersion = installerStatus?.data.integrations.find(
        int => int.name === name,
      )?.version;
      // Case: No target version but MS is installed, keep version or bump to minimum
      if (currentVersion) {
        const tgt = currentVersion.replace(/v/, '');
        const min = minimum.replace(/v/, '');
        return [
          name,
          {
            version: compare(tgt, min, '<') ? minimum : currentVersion,
            config,
          },
        ];
      }

      // Case: MS not installed and no target version, go to latest
      return [name, { version: latest, config }];
    }),
  );
  return useMemo(() => needEntitiesWithLatestsBaked, [
    needEntitiesWithLatestsBaked,
  ]);
};

export const useOtherMSRequirements = (
  installerStatus: InstallerStatus | Error | undefined,
): {
  [entity: string]: { version: string | undefined; config: any };
} => {
  const required = useMSRequirements(installerStatus);

  const requiredKeys = Object.keys(required ?? {});
  const links = MSData.map(({ entity }) => {
    const installerObject = (installerStatus as InstallerStatus)?.data?.integrations.find(
      int => int.name === entity,
    );
    const ignore =
      // This MS's info request always fails, causing infinite requests, thus ignored
      entity === 'transaction-chain' ||
      // Filter out the required microservices so that we don't update them again
      requiredKeys.includes(entity) ||
      // We should not auto-install optional services, that are not installed currently
      !installerObject;

    return [
      entity,
      {
        ignore,
        version: installerObject?.version,
      },
    ] as const;
  });

  const needEntities = Object.fromEntries(
    links.map(([name, { version, ...config }]) => [
      name,
      { version: version ?? 'latest', config },
    ]),
  );

  const u2 = useLatest;
  const linksWithLatestsBaked = Object.fromEntries(
    links.map(([entity, conf]) => [
      entity,
      u2(
        entity,
        needEntities[entity]?.version === 'latest' ||
          !needEntities[entity]?.version,
      ),
    ]),
  );

  const needEntitiesWithLatestsBaked = Object.fromEntries(
    Object.entries(needEntities)
      // Filter out entities that are not installed
      .filter(([_name, { config }]) => !config.ignore)
      .map(([name, { version, config }]) => {
        const latest = linksWithLatestsBaked[name];
        if (version === 'latest') return [name, { version: latest, config }];
        const minimum = MINIMUM_SUPPORTED_MS_VERSIONS[name];
        if (!minimum) return [name, { version, config }];
        // Case: Target version is configured, use it or the minimum
        if (version) {
          const tgt = version.replace(/v/, '');
          const min = minimum.replace(/v/, '');
          return [
            name,
            { version: compare(tgt, min, '<') ? minimum : version, config },
          ];
        }

        if (installerStatus instanceof Error)
          return [name, { version, config }];
        const currentVersion = installerStatus?.data.integrations.find(
          int => int.name === name,
        )?.version;
        // Case: No target version but MS is installed, keep version or bump to minimum
        if (currentVersion) {
          const tgt = currentVersion.replace(/v/, '');
          const min = minimum.replace(/v/, '');
          return [
            name,
            {
              version: compare(tgt, min, '<') ? minimum : currentVersion,
              config,
            },
          ];
        }

        return [name, { version: latest, config }];
      }),
  );
  return useMemo(() => needEntitiesWithLatestsBaked, [
    needEntitiesWithLatestsBaked,
  ]);
};
