import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Box, Button } from '@material-ui/core';

import { getCurrentSalesDocument } from 'reducers/sales';
import { setCurrentSalesDocument } from 'actions/sales';
import { reactcursively } from 'utils/react';
import { PosPlugin } from 'plugins/plugin';

/**
 * Button that displays a checkbox
 *
 * The checkbox is checked if the sales document notes contains a line 'Self Fit'
 * Clicking on the button toggles the line (removes it if present, or adds it as the last line)
 */
const SelfFitButton = () => {
  const salesDoc = useSelector(getCurrentSalesDocument);
  const checked = salesDoc?.notes?.split('\n').includes('Self Fit') ?? false;
  const dispatch = useDispatch();
  const toggle = () =>
    dispatch(
      setCurrentSalesDocument({
        ...salesDoc,
        notes: R.pipe(
          R.defaultTo(''),
          R.split('\n'),
          R.ifElse(
            R.includes('Self Fit'),
            R.without(['Self Fit']),
            R.append('Self Fit'),
          ),
          R.join('\n'),
        )(salesDoc.notes),
      }),
    );
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      onClick={toggle}
      disableElevation
      style={{ height: '38px' }}
    >
      Self Fit
      <Box
        component="span"
        fontSize={24}
        marginLeft={1}
        lineHeight="1em"
        marginTop="-5px"
      >
        {checked < 0.5 ? '☐' : '☑'}
      </Box>
    </Button>
  );
};

const UICustomerBadgeCustomRegion: PosPlugin['UICustomerBadgeCustomRegion'] = ({
  children,
}) => {
  return reactcursively(
    R.pipe(
      R.when(
        // Replace the second button with the self fit button
        R.pathEq(['props', 'data-testid'], 'user-badge-action-buttons'),
        R.modifyPath(
          ['props', 'children'],
          R.pipe(
            React.Children.toArray,
            R.assocPath([1, 'props', 'children'], <SelfFitButton />),
          ),
        ),
      ),
    ),
  )(children);
};

export default UICustomerBadgeCustomRegion;
