import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal, Table } from 'react-bootstrap';

import { AddButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { addProduct } from 'actions/ShoppingCart/addProduct';

import { add } from 'utils';
import InputField from 'components/FieldTypes/InputField';
import { useAvailableMatrixDimensions } from 'containers/Forms/matrixVariations/useMatrixDimensions';
import useProducts from 'utils/hooks/useProducts';

const AmountSelection = ({ productID, amount, setAmount, name, ...rest }) => {
  if (!productID) {
    return (
      <InputField
        size="lg"
        value={0}
        onChange={() => {}}
        disabled={true}
        style={{ opacity: 0.4 }}
        {...rest}
      />
    );
  }

  return (
    <>
      <InputField
        size="lg"
        value={amount}
        onChange={e => setAmount(e.target.value)}
        {...rest}
      />
      <div>{name}</div>
    </>
  );
};

const MatrixVariationsMultiple = ({ productID }) => {
  const { t } = useTranslation('product');
  // Selected variations dimensions

  const props = useMemo(() => ({ productID: Number(productID) }), [productID]);
  const {
    products: [mainProduct],
  } = useProducts(props);
  const {
    dimensions,
    products: { products: variationProducts },
  } = useAvailableMatrixDimensions(mainProduct?.variationList);
  const sorted = [...dimensions].sort(
    (a, b) => a.values.length - b.values.length,
  );
  const longAxis = sorted.pop();
  const shortAxis = sorted.length ? sorted.shift() : null;
  const mediumAxis = sorted.length ? sorted.shift() : null;

  // Create state for storing selected dimensions
  const [amounts, setAmounts] = useState({});
  const [medium = mediumAxis?.values[0], setMedium] = useState(
    mediumAxis?.values[0],
  );

  const getVariationForDims = dict => {
    const variation = mainProduct?.variationList?.find(v =>
      v.dimensions.every(
        ({ dimensionID, dimensionValueID }) =>
          dict[dimensionID] === dimensionValueID,
      ),
    );
    return variation;
  };

  const title = t('matrix.variationSelect.title');

  const dispatch = useDispatch();
  const close = () => dispatch(previousModalPage());
  const confirm = () => {
    Object.entries(amounts)
      .filter(([, amount]) => Number(amount))
      .forEach(([id, amount]) =>
        dispatch(
          addProduct({
            productID: id,
            amount: Number(amount),
          }),
        ),
      );
    close();
  };
  return (
    <div data-testid="multiple-matrix-add">
      <Modal.Header
        style={{
          display: 'flex',
        }}
      >
        <Modal.Title style={{ fontSize: '24px', fontWeight: 700 }}>
          {title}
        </Modal.Title>
        <div style={{ flexGrow: 1 }} />
        <AddButton
          className="add-button-matrix"
          disabled={
            Object.values(amounts)
              .map(Number)
              .reduce(add, 0) === 0
          }
          data-testid="add-button"
          action={confirm}
        />
        <CloseButton action={close} />
      </Modal.Header>
      <Modal.Body>
        {mediumAxis ? (
          <InputField
            title={mediumAxis.name}
            data-testid="dimension-input"
            data-test-key={mediumAxis.name}
            onChange={e => setMedium(mediumAxis.values[e.target.value])}
            type="select"
            options={mediumAxis.values.map((val, i) => ({
              name: val.value,
              value: i,
            }))}
          />
        ) : null}
        <Table>
          <thead>
            <tr>
              <th />
              <th style={{ fontWeight: 'normal' }}>{shortAxis?.name}</th>
              {Array(shortAxis?.values.length ?? 1)
                .fill(0)
                .map((z, i) => (
                  <th key={i} />
                ))}
            </tr>
            <tr>
              <th style={{ fontWeight: 'normal' }}>{longAxis?.name}</th>
              {(shortAxis?.values ?? [{ dimensionID: false, value: null }]).map(
                short => (
                  <th key={short.dimensionID}>{short.value}</th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {longAxis?.values.map(long => (
              <tr>
                <th>{long.value}</th>
                {((shortAxis && shortAxis.values) || [null]).map(short => (
                  <td>
                    {(() => {
                      // prettier-ignore
                      const variation = getVariationForDims(Object.assign({}, ...[
                          (shortAxis && {[shortAxis.dimensionID]: short?.dimensionValueID}),
                          (longAxis && {[longAxis.dimensionID]: long?.dimensionValueID}),
                          (mediumAxis && {[mediumAxis.dimensionID]: medium?.dimensionValueID})
                        ].filter(n => n)));
                      const id = variation && variation.productID;
                      return (
                        <AmountSelection
                          productID={id}
                          amount={amounts[id]}
                          data-testid="amount-selection"
                          data-test-key={variation?.code}
                          setAmount={n => {
                            setAmounts(amts => ({ ...amts, [id]: n }));
                          }}
                          name={variation?.name}
                        />
                      );
                    })()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </div>
  );
};
export default MatrixVariationsMultiple;
