import { Box, Typography } from '@material-ui/core';
import React from 'react';

import CloseButton from 'components/CustomButtons/CloseButton';

const Header: React.FC<{ title: string; onClose: () => void }> = ({
  title,
  onClose,
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    padding="0.5rem"
  >
    <Typography variant="h4">{title}</Typography>
    <CloseButton action={onClose} />
  </Box>
);

export default Header;
