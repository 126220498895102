import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrencyFormatter } from 'reducers/configs/settings';

import { getLastChange } from './redux';

export const headerComponent = (({ children }) => {
  const lastBalance = useSelector(getLastChange);
  const format = useSelector(getCurrencyFormatter);
  return (
    <>
      <li
        title={`On the last sale the amount of change was ${format(
          lastBalance,
        )}`}
      >
        {format(lastBalance)}
      </li>
      {children}
    </>
  );
}) as React.FC;
