import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import { getHasRightToEditSettings } from 'reducers/Login';

const allowedElementKeys = ['logout', 'clockinout', 'app-version'];

const shouldBeHidden = el => {
  const shouldBeHidden =
    !el?.props?.['data-testid'] ||
    !allowedElementKeys.includes(el.props['data-testid']);
  return shouldBeHidden;
};

export const FilteredMenu: PosPlugin['UIUserMenuDropDownMenu'] = ({
  children,
}) => {
  const canViewSettings = useSelector(getHasRightToEditSettings);
  return R.pipe(
    React.Children.toArray,
    R.when(
      () => !canViewSettings,
      R.filter(el => !shouldBeHidden(el)),
    ),
  )(children);
};
