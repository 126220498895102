import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tabs, Tab } from 'react-bootstrap';

import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { savePluginConfig } from 'actions/Plugins';
import {
  getPluginConfiguration,
  getPluginConfigurationAtLevel,
} from 'reducers/Plugins';
import { getPosLoggedIn, getSelectedPos } from 'reducers/PointsOfSale';
import { getUserLoggedIn } from 'reducers/Login';

const ConfigurePlugin = ({ plugin }) => {
  const dispatch = useDispatch();
  const { pointOfSaleID, warehouseID } = useSelector(getSelectedPos);
  const { userID } = useSelector(getUserLoggedIn);
  const byLevel = plugin.ComponentConfigurationByLevel;
  const Configure = plugin.ComponentConfiguration;
  const configuration = useSelector(getPluginConfiguration(plugin.id));
  const companyConf = useSelector(
    getPluginConfigurationAtLevel(plugin.id, 'Company'),
  );
  const warehouseConf = useSelector(
    getPluginConfigurationAtLevel(plugin.id, 'Warehouse', warehouseID),
  );
  const posConf = useSelector(
    getPluginConfigurationAtLevel(plugin.id, 'Pos', pointOfSaleID),
  );
  const userConf = useSelector(
    getPluginConfigurationAtLevel(plugin.id, 'User', userID),
  );

  const saveLvlFn = (level, id) => newConf => {
    return dispatch(
      savePluginConfig(plugin, newConf, {
        level,
        id,
      }),
    );
  };

  const byLevelProps = {
    company: {
      current: companyConf,
      save: saveLvlFn('Company', undefined),
    },
    warehouse: {
      current: warehouseConf,
      save: saveLvlFn('Warehouse', warehouseID),
    },
    pos: {
      current: posConf,
      save: saveLvlFn('Pos', pointOfSaleID),
    },
    user: {
      current: userConf,
      save: saveLvlFn('User', userID),
    },
  };
  return (
    <>
      <Modal.Header
        className="header"
        style={{ fontSize: '1.75em', fontWeight: 'bold' }}
      >
        Configure {plugin?.name}
        <CloseButton action={() => dispatch(previousModalPage())} />
      </Modal.Header>
      <Modal.Body>
        {byLevel ? (
          <Tabs defaultActiveKey={Object.keys(byLevel)[0]}>
            {byLevel.Company ? (
              <Tab eventKey="Company" title="Company">
                <byLevel.Company
                  {...byLevelProps.company}
                  active={configuration}
                />
              </Tab>
            ) : null}
            {byLevel.Warehouse ? (
              <Tab eventKey="Warehouse" title="Warehouse">
                <byLevel.Warehouse
                  {...byLevelProps.warehouse}
                  active={configuration}
                />
              </Tab>
            ) : null}
            {byLevel.Pos ? (
              <Tab eventKey="Pos" title="Pos">
                <byLevel.Pos {...byLevelProps.pos} active={configuration} />
              </Tab>
            ) : null}
            {byLevel.User ? (
              <Tab eventKey="User" title="User">
                <byLevel.User {...byLevelProps.user} active={configuration} />
              </Tab>
            ) : null}
          </Tabs>
        ) : (
          <Configure
            current={configuration}
            save={newConf => dispatch(savePluginConfig(plugin, newConf))}
            byLevel={byLevelProps}
          />
        )}
      </Modal.Body>
    </>
  );
};

export default ConfigurePlugin;
