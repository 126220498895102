import * as R from 'ramda';

const validate = val => {
  return val ? undefined : 'This field can not be blank';
};
export const matches = regex => c => {
  const key = c?.key?.replace(/\W/gi, '').replace('0', '');
  return regex.test(key);
};
export const requirify = child => {
  return R.pipe(R.assocPath(['props', 'field', 'validate'], validate))(child);
};
export const insertAfter = R.curry((regex, item, list) => {
  const index = R.findIndex(matches(regex), list) + 1;
  return R.insert(index, item, list);
});

export const insertAfterBeta = R.curry((name, item, list) => {
  const index = R.findIndex(R.pathEq(['props', 'name'], name), list) + 1;
  return R.insert(index, item, list);
});
