import React, { useEffect, Children } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as RA from 'ramda-adjunct';

import {
  getSelectedCustomerID,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { combineLifecycleHooks } from 'plugins/pluginUtils';

import { UICustomerBadgeCustomRegion } from './customerBadge/UICustomerBadgeCustomRegion';
import { components, UICustomerViewTable } from './salesHistory';
import {
  getIsWbuCustomerLoading,
  getWbuExtraData,
  reducer,
  updateCustomer,
} from './redux';
import {
  UICustomerForm,
  updateCustomerCardNumber,
} from './customerForm/UICustomerForm';
import { disableGiftcardBasedOnConfParam } from './giftcardPerms/giftcardPerms';
import {
  tryActivateOrReturnMembership,
  checkRightsForMembership,
  removeMembershipFromDefaultCustomer,
  checkMembershipBeingReturned,
  waitForCustomerToSet,
  determineMembershipDiscount,
  filterMembershipsThatCanBeReturned,
  resetCustomerData,
  resetPremembership,
  setPremembership,
  components as membershipComponents,
} from './preMembership/preMembership';
import { onPrintWBUReceipt } from './customReceipt';
import {
  saveReceiptDataToJSON,
  saveRowDataToJSON,
} from './customReceipt/saveReceiptDataToJSON';
import { pluginID } from './constants';
import { SalesResults } from './salesResults/SalesResults';
import UICustomerSearch from './customerSearch/UICustomerSearch';
import getCashInOutPrint from './cashInOutPrint/cashInOutPrint';
import UIAdvancedSearchTableColumns from './customerSearch/UIAdvancedSearchTableColumns';
import UIOriginalPayment from './payment/UIOriginalPayment';
import UIKeyPad from './payment/UIKeypad';
import {
  convertCustomPaymentsToGiftCard,
  convertOriginalGiftCardPaymentsBackToCustom,
  disableGiftcardTenderIfGiftcardProductsInCart,
  insertCustomPaymentLimits,
} from './payment/overrides';
import { matches } from './utils';
import {
  CompanyConfiguration,
  WarehouseConfiguration,
  WbuPosPlugin,
} from './configuration';
import UIClockIn from './clockIn';
import {
  cancelAction,
  openSuspendedCustomerConfirmation,
} from './suspendedCustomer';
import UICustomerResults from './customerSearch/UICustomerResults';
import { UICustomReturnDiscountButtons } from './discountButtons/UIDiscountButtons';
import { getEditedProductsInShoppingCart } from './shoppingCart';

const WBUPlugin: WbuPosPlugin = {
  id: pluginID,
  name: 'WBU customizations',

  // Gift card restrictions
  selectorOverrides: {
    getSettings: disableGiftcardBasedOnConfParam,
    getProductsInShoppingCart: getEditedProductsInShoppingCart,
  },

  // Keep extra data in redux about the current customer
  reduxReducer: (state, action) => {
    return reducer(state, action);
  },

  ComponentHeader: ({ children }) => {
    const dispatch = useDispatch();
    const id = useSelector(getSelectedCustomerID);
    const isWBUCustomerLoading = useSelector(getIsWbuCustomerLoading);
    const extraData = useSelector(getWbuExtraData);
    const extraDataAlreadyLoaded = extraData?.customerID === id;
    useEffect(() => {
      if (!extraDataAlreadyLoaded && id && !isWBUCustomerLoading) {
        dispatch(updateCustomer(id));
      }
    }, [dispatch, extraDataAlreadyLoaded, id, isWBUCustomerLoading]);

    return children;
  },

  // Configuration
  ComponentConfigurationByLevel: {
    Company: CompanyConfiguration,
    Warehouse: WarehouseConfiguration,
  },

  combineConfiguration: (
    company = {
      noSelectOnEnter: false,
      plugin_dsc_membership_return_days: 30,
    },
    warehouse = {
      customPayments: [],
    },
    pos,
    user,
  ) => {
    return R.mergeAll([company, warehouse, pos, user]);
  },

  onSaveSalesDocumentAttrToJsonApi: {
    before: tryActivateOrReturnMembership,
    on: saveReceiptDataToJSON,
    after: saveRowDataToJSON,
  },

  onAddReturnProducts: {
    on: filterMembershipsThatCanBeReturned,
  },

  // Pre-membership product rules
  onAddProduct: combineLifecycleHooks(
    {
      before: cancelAction,
    },
    {
      before: checkRightsForMembership,
    },
  ),
  onUpdateOrderAmount: {
    before: checkMembershipBeingReturned,
  },
  onCalculate: combineLifecycleHooks(
    {
      before: waitForCustomerToSet,
      on: determineMembershipDiscount,
    },
    { on: setPremembership },
  ),
  onSelectOneCustomer: {
    after: () => async dispatch => dispatch(resetPremembership(true)),
  },
  onSetCustomer: combineLifecycleHooks(
    {
      after: () => async dispatch => dispatch(resetPremembership(true)),
    },
    { after: removeMembershipFromDefaultCustomer },
    {
      after: openSuspendedCustomerConfirmation,
    },
  ),
  onStartNewSale: {
    after: () => async dispatch => dispatch(resetPremembership()),
  },
  onGenerateReceipt: {
    before: ({ salesDocument, options }) => async (
      dispatch: ThunkDispatch<unknown, unknown, Action>,
    ) => {
      if (onPrintWBUReceipt && !options?.giftReceipt) {
        await dispatch(onPrintWBUReceipt({ salesDocument, options }));
      }
    },
  },
  // Custom form, view, badge
  onSaveCustomer: combineLifecycleHooks(
    {
      after: updateCustomerCardNumber,
    },
    { after: resetCustomerData },
  ),
  onCloseDay: {
    on: (p, ap) => async () => {
      return RA.renameKeysWith(
        R.when(
          R.match(/varianceReasonID-GIFTCARD-|counted-GIFTCARD-/),
          R.replace('GIFTCARD-', 'GIFTCARD'),
        ),
      )(ap);
    },
  },
  UICustomerForm,
  UICustomerBadgeCustomRegion,
  UICustomerViewTable,
  UIRecentSalesPreviousPurchasesTable: SalesResults,
  UIProductFormBody: ({ children }: { children: React.ReactNode }) => {
    return R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(Children.toArray, R.reject(matches(/notes/))),
      ),
    )(Children.toArray(children));
  },
  UICustomBirthdayInput: () => null,
  beforeShowCustomerDetails: customer => async (dispatch, getState) => {
    await dispatch(
      setCustomer({
        data: customer.id,
        recalculateShoppingCart: true,
        displayCouponsInfo: true,
        fetchRewardPoints: true,
      }),
    );
    dispatch(
      openModalPage({
        component: mp.customerView,
        groupID: mp.customerView,
        replace: true,
        props: { customer: getSelectedCustomer(getState()) },
      }),
    );
    throw new Error('Cancelled from plugin');
  },
  UICustomReturnDiscountButtons,
  UICustomerSearch,
  UICustomerResults,
  UIAdvancedSearchTableColumns,
  onCashInOutPrint: {
    on: getCashInOutPrint,
  },
  // Custom payments
  UIKeyPad,
  UIOriginalPayment,
  UIClockIn,
  onOpenPaymentModal: combineLifecycleHooks(
    { before: cancelAction, on: insertCustomPaymentLimits },
    { on: disableGiftcardTenderIfGiftcardProductsInCart },
  ),
  onSaveSalesDocument: {
    on: convertCustomPaymentsToGiftCard,
    after: () => async dispatch => dispatch(resetPremembership()),
  },
  onSetCurrentSalesDocPayments: {
    on: convertOriginalGiftCardPaymentsBackToCustom,
  },

  components: { ...components, ...membershipComponents },
};

export default WBUPlugin;
