import { useEffect } from 'react';

import { Theme } from 'types/Theme';

// eslint-disable-next-line import/no-webpack-loader-syntax
import defaultTheme from '!css-loader!sass-loader!../../../assets/themes/default/bootstrapTheme.scss';
// eslint-disable-next-line import/no-webpack-loader-syntax
import darkTheme from '!css-loader!sass-loader!../../../assets/themes/dark/bootstrapTheme.scss';
// eslint-disable-next-line import/no-webpack-loader-syntax
import highContrastTheme from '!css-loader!sass-loader!../../../assets/themes/highContrast/bootstrapTheme.scss';

const themes = {
  normal: defaultTheme,
  dark: darkTheme,
  highContrast: highContrastTheme,
};

export function getSelectedTheme(theme: Theme): keyof typeof themes {
  if (theme === 'highContrast') return 'highContrast';
  return theme === 'dark' ? 'dark' : 'normal';
}

/**
 * Hacky workaround to make dynamic color scheme for bootstrap. Bootstrap compiles to a css file,
 * so there is no way to conditionally override theme color variables (at least I have not found one).
 * Instead dynamically render css for the bootstrap with preferred overridden color scheme.
 */
function useBootstrapTheme(theme: Theme) {
  useEffect(() => {
    const selectedTheme = getSelectedTheme(theme);
    // Get existing bootstrap theme style tags
    const existingThemeStyles = document.querySelectorAll(
      'style[data-type="bs-theme"]',
    );

    // If exist then enable only selected one
    if (existingThemeStyles.length) {
      existingThemeStyles.forEach(themeStyle => {
        const themeName = themeStyle.getAttribute('data-theme');
        // Enable/disable unselected styles.
        // Media query is used because style tag does not support disabled attribute
        if (selectedTheme !== themeName) {
          themeStyle.setAttribute('media', 'max-width: 1px');
        } else {
          themeStyle.removeAttribute('media');
        }
      });
      return;
    }

    // If do not exist then create and add to DOM
    const firstStyleElement = document.querySelector('style');
    Object.keys(themes).forEach(themeName => {
      const themeStyle = document.createElement('style');
      themeStyle.setAttribute('data-type', 'bs-theme');
      themeStyle.setAttribute('data-theme', themeName);
      if (selectedTheme !== themeName) {
        themeStyle.setAttribute('media', 'max-width: 1px');
      }
      themeStyle.textContent = themes[themeName].toString();
      firstStyleElement?.insertAdjacentElement('afterend', themeStyle);
    });
  }, [theme]);
}

export default useBootstrapTheme;
