import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import React, { useDebugValue, useEffect, useMemo } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import { useAsync, useInterval, useUpdate } from 'react-use';
import { Trans, useTranslation } from 'react-i18next';

import { askForPassword } from 'actions/Login';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import {
  getCustomerRegistryToken,
  getCustomerRegistryUrl,
  getIsDefaultCustomer,
} from 'reducers/customerSearch';
import { getPayments } from 'reducers/Payments';
import { getClientCode, getSessionKey, getUsername } from 'reducers/Login';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI2';
import { getSetting } from 'reducers/configs/settings';
import { getConnectionHealth } from 'reducers/connectivity/connection';
import { ErplyApiUser } from 'types/User';

const TIME = {
  /** One hour */
  h: 3600e3 as const,
  /** One minute */
  m: 60e3 as const,
  /** One second */
  s: 1e3 as const,
};
/**
 * @deprecated New password prompt when session is close to expiry always takes 1h
 */
type SessionExpirationConfigOld = {
  /** This many seconds before expiration, the POS should begin showing the session timer */
  timer: number;
  /** This many seconds before expiration, the POS should auto-logout if there is no active sale (customer selected / products in cart) */
  customerOrCart: number;
  /** This many seconds before expiration, the POS should auto-logout as soon as there aren't payments */
  paymentsOnly: number;
};

type SessionExpirationConfig = number;
/**
 * @deprecated in favor of single, universal timeout of 1h
 */
const getConfigOld = createSelector(
  () => null,
  (n): SessionExpirationConfigOld => ({
    timer: TIME.h * 3,
    customerOrCart: TIME.h * 2,
    paymentsOnly: TIME.h * 1,
  }),
);

const getConfig = createSelector(
  () => null,
  (n): SessionExpirationConfig => TIME.h * 1,
);

/**
 * Given a sessionKey, checks its expiration and return true if the expiration is sufficiently far in the future
 * that no timer would be displayed yet
 */
export const checkSessionNotExpiring = sessionKey => async (
  dispatch,
  getState,
) => {
  const timer = getConfig(getState());
  return (
    doClientRequest({ request: 'getSessionKeyInfo', sessionKey })
      // Expiration timestamp
      .then(res => Number(res.records[0]?.expireUnixTime) * 1000)
      // Time until expiration
      .then(time => time - Date.now())
      // Remaining time is greater
      .then(delta => delta > timer)
      // Any error, presume sessionkey is expired
      .catch(() => false)
  );
};

export const getSessionUser = async (
  sessionKey: string,
): Promise<ErplyApiUser | null> => {
  return doClientRequest({ request: 'getSessionKeyUser', sessionKey })
    .then(response => response.records[0])
    .catch(() => null);
};

const getHasNoCustomerOrCart = createSelector(
  state => 0 < getProductsInShoppingCart(state).length,
  state => getIsDefaultCustomer(state),
  (hasProds, isDefaultCustomer) => !hasProds && isDefaultCustomer,
);
const getHasNoPayments = createSelector(
  state => getPayments(state),
  pmts => R.isEmpty(pmts),
);

const getCustomerRegistryExpiration = () => {
  const customerRegistryUrl = getCustomerRegistryUrl();
  const customerRegistryToken = getCustomerRegistryToken();
  if (!customerRegistryUrl?.length || !customerRegistryToken?.length) {
    return -Infinity;
  }
  const decoded = jwtDecode(customerRegistryToken);
  return (decoded?.t_isst + decoded?.t_ttl) * 1000 || -Infinity;
};

/**
 * @param {number} buffer Buffer time in ms, how long before expiration we actually care about the timer
 * This will enable the component to only rerender every second if expiration is closer than that time
 */
const usePreExpirationLogoutTimer = (buffer: number) => {
  // region read session key info
  const sessionKey = useSelector(getSessionKey);
  const { value: sessionExpiresAt = 0, loading, error } = useAsync(
    () =>
      doClientRequest({ request: 'getSessionKeyInfo', sessionKey }).then(
        res => {
          return Number(res.records[0]?.expireUnixTime) * 1000;
        },
      ),
    [sessionKey],
  );
  // endregion

  // region read customer registry info
  // TODO: Use a different error message for expiring / non-existing customer registry token?
  const shouldHaveCustomerRegistry = useSelector(
    getSetting('use_standalone_customer_registry'),
  )
    ? 1
    : 0;
  const customerRegistryExpiresAt = shouldHaveCustomerRegistry
    ? getCustomerRegistryExpiration()
    : Infinity;
  // endregion

  const now = Date.now();
  const expiresAt = Math.min(sessionExpiresAt, customerRegistryExpiresAt);
  const remainingTime = expiresAt ? expiresAt - now : Infinity;

  // region Rerender component every second once we are in warning distance
  const msUntilBufferStart =
    expiresAt === 0 ? null : Math.max(remainingTime - buffer, 0);
  const msTillNextSecond = 1001 - (Date.now() % 1000);
  useInterval(useUpdate(), msUntilBufferStart || msTillNextSecond);
  // endregion

  const retVal = {
    loading,
    error,
    value: remainingTime,
  } as {
    loading: boolean;
    error: false | Error;
    value: number;
  };
  useDebugValue(retVal);
  return retVal;
};

const timer = a => {
  if (!Number.isFinite(a)) return '';
  return `${a < 0 ? '-' : ''}${
    new Date(Math.abs(a) + 500)
      .toISOString()
      .split('T')[1]
      .split('.')[0]
  }`;
};

export const useSessionExpiration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('sessionExpiration');
  const clientCode = useSelector(getClientCode);
  const username = useSelector(getUsername);
  const isOnline = useSelector(getConnectionHealth);

  const config = useSelector(getConfig);
  const expiration = usePreExpirationLogoutTimer(config);

  const sessionExpiring = useMemo(() => {
    if (expiration.loading) return false;
    if (expiration.error) return false;
    return expiration.value < config;
  }, [expiration.loading, expiration.error, expiration.value, config]);

  const timerTotal = expiration.value;
  const [phase, timerNext] = useMemo(() => {
    if (expiration.loading) return ['loading', Infinity];
    if (expiration.error) return ['error', Infinity];
    // Session expired
    if (expiration.value < 0) return ['expired', Infinity];
    // Session close to expiry
    if (expiration.value < config) return ['timer', expiration.value];
    return ['none', ''];
  }, [expiration, config]);

  const shortStatus = useMemo(() => {
    return (
      <Trans
        t={t}
        i18nKey="shortWarning"
        tOptions={{ context: phase }}
        defaults=" "
        values={{
          timerNext: timer(timerNext),
          timerTotal: timer(timerTotal),
        }}
        components={{
          secondary: <Typography color="secondary" />,
          error: <Typography color="error" />,
        }}
      />
    );
  }, [phase, timerNext, timerTotal, t]);

  const longStatus = useMemo(() => {
    return (
      <Trans
        i18nKey="longWarning"
        tOptions={{ context: phase }}
        defaults=" "
        t={t}
        values={{
          timerNext: timer(timerNext),
          timerTotal: timer(timerTotal),
        }}
        components={{
          secondary: <Typography color="secondary" />,
          error: <Typography color="error" />,
          Loading: <LinearProgress variant="indeterminate" />,
        }}
      />
    );
  }, [phase, t, timerNext, timerTotal]);

  useEffect(() => {
    if (sessionExpiring && isOnline) {
      dispatch(askForPassword({ clientCode, username }));
    }
  }, [sessionExpiring, clientCode, dispatch, username, isOnline]);

  return [shortStatus, longStatus];
};
