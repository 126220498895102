import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const RemoveIcon = ({ isNew, remove, willBeRemoved, undoRemoveAddress }) => {
  const { t } = useTranslation('addressForm');
  if (isNew) {
    return <Button onClick={remove}>{t('common:cancel')}</Button>;
  }
  if (willBeRemoved) {
    return (
      <div>
        <span>
          <strong>{t('extra.toBeDeleted')}</strong>
        </span>
        <Button style={{ marginLeft: '0.25em' }} onClick={undoRemoveAddress}>
          {t('extra.linkUnDelete')}
        </Button>
      </div>
    );
  }
  return (
    <IconButton onClick={remove}>
      <DeleteOutlinedIcon />
    </IconButton>
  );
};

RemoveIcon.propTypes = {
  isNew: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  willBeRemoved: PropTypes.bool.isRequired,
  undoRemoveAddress: PropTypes.func.isRequired,
};

export default RemoveIcon;
