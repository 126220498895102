import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

import { useBreakpoints } from 'utils/hooks/UI';

const useStyles = makeStyles({
  popoverContainer: {
    position: 'absolute',
    top: 60,
    left: 0,
    minWidth: '100%',
    maxHeight: 'calc(100vh - 200px)',
    margin: '0 4px',
    borderRadius: '4px',
    zIndex: 100,
    flexDirection: 'column',
    border: '1px solid black',
  },
});

const PopOverContainer: FC<{ show: boolean }> = ({ show, children }) => {
  const isMobile = useBreakpoints().mdplus;
  const classes = useStyles();
  if (!show) return null;
  return (
    <Paper
      className={isMobile ? classes.popoverContainer : ''}
      onClick={e => e.stopPropagation()}
    >
      {children}
    </Paper>
  );
};

export default PopOverContainer;
