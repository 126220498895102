import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { PosPlugin } from 'plugins/plugin';
import { openPluginModalPage } from 'actions/modalPage';
import { CfjcRequirements } from 'plugins/pnp/carSales/components';
import { ErplyAttributes } from 'utils';
import {
  attributes,
  components,
  pluginId,
} from 'plugins/pnp/carSales/constants';
import { getProductByID } from 'reducers/cachedItems/products';
import { getRequirementsForProduct } from 'plugins/pnp/carSales/selectors';
import { CompanyConfiguration as CompanyConfigurationComponent } from 'plugins/pnp/carSales/components/configuration';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getSelectedOrder } from 'reducers/ShoppingCart';
import { updateProductOrder } from 'actions/ShoppingCart';
import { RootState } from 'reducers';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';

export const defaultConfiguration = {
  url: '',
  token: '',
  requirementsByStoreGroup: {},
};

export type CompanyConfiguration = {
  url: string;
  token: string;
  requirementsByStoreGroup: {
    [storeGroup: string]: {
      [productID: string]: {
        [req: string]: boolean;
      };
    };
  };
};
export type WarehouseConfiguration = {};
export type PosConfiguration = {};
export type UserConfiguration = {};
export type Configuration = CompanyConfiguration &
  WarehouseConfiguration &
  PosConfiguration &
  UserConfiguration;

const pnpCarSalesPlugin: PosPlugin<
  Configuration,
  CompanyConfiguration,
  WarehouseConfiguration,
  PosConfiguration,
  UserConfiguration
> = {
  id: pluginId,
  name: '[pnp] Car sales',
  ComponentConfigurationByLevel: {
    Company: CompanyConfigurationComponent,
    // Warehouse: WarehouseConfiguration,
    // Pos: PosConfiguration,
  },
  keywords: ['pnp', 'cfjc'],
  // language=Markdown
  info: `Allow certain products in certain store groups to trigger additional prompts for information - such as vin number, car side, cylinder count - or to require a customer to be added to the sale beforehand`,
  combineConfiguration: (c, wh, pos, u) => {
    return c ?? defaultConfiguration;
  },
  getStatus: state => {
    const conf = getPluginConfiguration<Configuration>(pluginId)(state);
    const { url, token } = conf;
    if (url.trim().length < 1 && token.trim().length < 1) {
      return {
        type: 'error',
        message: 'Missing configuration: url and token not specified',
      };
    }
    if (url.trim().length < 1) {
      return {
        type: 'error',
        message: 'Missing configuration: url not specified',
      };
    }
    if (token.trim().length < 1) {
      return {
        type: 'error',
        message: 'Missing configuration: token not specified',
      };
    }
    return {
      type: 'valid',
      message: '',
    };
  },
  components: {
    [components.requirementsPopup]: CfjcRequirements,
  },
  onAddProduct: {
    on: (p, { product: ap, options, ...rest }) => async (
      dispatch,
      getState,
    ) => {
      const product = getProductByID(p.productID)(getState());
      const reqs = getRequirementsForProduct(product.productID)(getState());

      if (
        reqs.has('customer') ||
        reqs.has('vin') ||
        reqs.has('cylinder') ||
        reqs.has('carSide')
      ) {
        const { car, cylinder, carSide, customer } = (await dispatch(
          openPluginModalPage(components.requirementsPopup)({
            isPopup: true,
            props: {
              requirements: reqs,
            },
          }),
        )) as any;
        return {
          ...rest,
          options,
          product: {
            ...ap,
            attributes: {
              ...new ErplyAttributes(ap.attributes).asDict,
              [attributes.cfjcData]: JSON.stringify(
                [
                  car.vin,
                  car.stock.stockNo,
                  car.year,
                  car.make,
                  car.model,
                  cylinder,
                  carSide,
                  customer,
                ].map(a => a ?? ''),
              ),
            },
          },
        };
      }
      return { ...rest, options, product: ap };
    },
  },
  UIProductFormBody: ({ children }) => {
    const order = useSelector(getSelectedOrder) || {};
    const reqs = useSelector(
      useMemo(() => getRequirementsForProduct(order.productID), [
        order.productID,
      ]),
    );
    const dispatch: ThunkDispatch<RootState, any, Action> = useDispatch();

    const hasReqs =
      reqs.has('customer') ||
      reqs.has('vin') ||
      reqs.has('cylinder') ||
      reqs.has('carSide');

    useEffect(() => {
      if (hasReqs && Number(order.amount) > 0) {
        dispatch(
          openPluginModalPage(components.requirementsPopup)({
            isPopup: true,
            props: {
              requirements: reqs,
              current: order.attributes[attributes.cfjcData],
            },
          }),
        )
          .then(({ car, cylinder, carSide, customer }) =>
            JSON.stringify(
              [
                car.vin,
                car.stock.stockNo,
                car.year,
                car.make,
                car.model,
                cylinder,
                carSide,
                customer,
              ].map(a => a ?? ''),
            ),
          )
          .then(newAttrs => {
            dispatch(
              updateProductOrder(
                R.pipe(
                  // If already has attrs, ensure they are in dict format
                  R.evolve({
                    attributes: attrs => new ErplyAttributes(attrs).asDict,
                  }),
                  // Assoc new attribute
                  R.assocPath(['attributes', attributes.cfjcData], newAttrs),
                )(order),
              ),
            );
          })
          .catch(() => undefined)
          .finally(() => {
            dispatch(previousModalPage());
          });
      }
    }, [hasReqs]);
    return children;
  },
};
export default pnpCarSalesPlugin;
