export const redirectToLoginApp = () => {
  const posLocation = new URL(window.location.pathname);
  const posParams = new URLSearchParams(posLocation.search);
  posParams.set('auth', 'cookie');
  posParams.delete('clientCode');
  posLocation.search = `?${posParams.toString()}&clientCode={CLIENTCODE}`;
  const target = posLocation.href;

  const login = new URL('https://login.erply.com');
  const loginParams = new URLSearchParams();
  loginParams.set('target', target);
  if (window.wrapperInfo) {
    // check if the POS is rendered from a Wrapper App
    loginParams.set('hideSignUp', 'true');
  }
  loginParams.set('isLoginAgain', 'true');
  login.search = `?${loginParams.toString()}`;
  window.location.href = login.href;
};
