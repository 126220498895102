export const EFSTA_ID = 'EFSTA_Fiscal_integration';
export const EFSTA_INFO =
  'POS Fiscal integration for EFSTA. EFTSA serves as middleware to fulfil country specific fiscal requirements for POS systems using a unified interface';

export type EFSTAConf = {
  country: string;
  endpoint: string | undefined;
};
export interface EFSTAConfCompany {
  country: string;
}
export type EFSTAConfWarehouse = {};
export interface EFSTAConfPos {
  endpoint: string;
}
export type EFSTAConfUser = {};
