import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  goUpOneProductGroup,
  openNextPage,
  openPreviousPage,
  setGridView,
  setProductGroup,
  setProductPage,
} from 'actions/UI';
import { displayView } from 'constants/UI';
import { modalPages as modals } from 'constants/modalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import {
  getSelectedProductGroupID,
  getSelectedProductPage,
} from 'reducers/UI/gridDisplay';
import {
  getSelectedProductGroup,
  getSelectedProductGroupSubgroups,
} from 'reducers/productGroupsDB';
import { getUISetting } from 'reducers/configs/settings';
import {
  useMeasureListHeight,
  useRefreshGrid,
} from 'containers/Col2/GridViews/util';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getCanAddProducts } from 'reducers/Login';
import useProductsPerGroup from 'utils/hooks/useProductsPerGroup';

import ProductGroups from '../ProductGroups';
import Products from '../Products';

import {
  addNewBttn,
  extendBttn,
  goUpBttn,
  homeBttn,
  leftArrowBttn,
  rightArrowBttn,
} from './SpecialBttns';

const MixedView = () => {
  const dispatch = useDispatch();
  const page = useSelector(getSelectedProductPage);
  const [nrProds, setNrProds] = useState(
    Number(localStorage.getItem('productCountOnPage')) ?? null,
  );
  const groupID = useSelector(getSelectedProductGroupID);
  const productGroups = useSelector(getSelectedProductGroupSubgroups);
  const currentProductGroup = useSelector(getSelectedProductGroup);

  const params = useMemo(
    () => ({
      groupID: Number(groupID),
      recordsOnPage: Number(nrProds ?? 0),
      pageNo: Number(page) + 1,
    }),
    [groupID, page, nrProds],
  );
  const { products, total, refresh } = useProductsPerGroup(params);

  useRefreshGrid(refresh);

  const goHomeProdGroup = useCallback(() => {
    dispatch(setProductGroup('0'));
  }, [dispatch]);

  useShortcut('Escape', goHomeProdGroup, 30);

  const goToCreateNew = useCallback(
    () =>
      dispatch(
        openModalPage({
          component: modals.createNew,
        }),
      ),
    [dispatch],
  );

  const canAddProducts = useSelector(getCanAddProducts);
  const hideAddProductBtn = useSelector(getUISetting('hideAddProductOrGroup'));

  const startBttns = useMemo(
    () =>
      [
        homeBttn(goHomeProdGroup),
        goUpBttn(() => {
          dispatch(goUpOneProductGroup());
          if (Number(currentProductGroup?.parentGroupID) === 0) {
            dispatch(setGridView(displayView.productGroups));
          }
        }),
        canAddProducts > 0 && !hideAddProductBtn
          ? addNewBttn(goToCreateNew)
          : null,
      ].filter(a => a),
    [
      goHomeProdGroup,
      canAddProducts,
      hideAddProductBtn,
      goToCreateNew,
      dispatch,
      currentProductGroup,
    ],
  );

  // actual product buttons excluding the special buttons shown in the grid

  // Reset pagination when changing group
  useEffect(() => {
    dispatch(setProductPage(0));
  }, [dispatch]);

  const setNumberProducts = useCallback((lines, items) => setNrProds(items), [
    setNrProds,
  ]);

  const productsPagination = useMemo(
    () => [
      leftArrowBttn(() => dispatch(openPreviousPage()), page === 0),
      rightArrowBttn(
        () => dispatch(openNextPage()),
        (page + 1) * nrProds >= total,
      ),
    ],
    [dispatch, nrProds, page, total],
  );
  const specialBttnsEndIfFull = useMemo(
    () =>
      extendBttn(() => {
        dispatch(setGridView(displayView.productGroups));
      }),
    [dispatch],
  );

  const { needsToMeasure, Measure, totalLines = 0 } = useMeasureListHeight();
  if (needsToMeasure) return Measure;
  const productLines = Math.max(7, totalLines) - 2;

  return (
    <>
      <ProductGroups
        productGroups={productGroups}
        specialBttnsStart={startBttns}
        specialBttnsEnd={undefined}
        specialBttnsEndIfFull={specialBttnsEndIfFull}
        onSetNrLines={undefined}
        minLines={2}
        maxLines={2}
        gridTypeOpened={undefined}
      />
      <Products
        products={products}
        specialBttnsStart={undefined}
        specialBttnsEnd={productsPagination}
        specialBttnsEndIfFull={undefined}
        onSetNrLines={setNumberProducts}
        minLines={productLines}
        maxLines={productLines}
        gridTypeOpened="mixedview"
      />
    </>
  );
};

export default MixedView;
