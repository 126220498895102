import { PosPlugin } from 'plugins/plugin';
import { getSelectedWarehouse } from 'reducers/warehouses';

import data from './states.json';
import CustomAddressFormBeta from './CustomAddressFormBeta';

const ID = 'customer-form-state-autocomplete-plugin';

const customerFormStateAutocompletePlugin: PosPlugin = {
  id: ID,
  name: 'NA Customer Form - Country and State Autocomplete (Canada/USA)',
  keywords: ['north', 'america', 'form', 'customer', 'state', 'country'],
  getStatus: state => {
    const { country } = getSelectedWarehouse(state);

    if (!['USA', 'CAN'].includes(country))
      return {
        type: 'error',
        message:
          'This plugin is only available in the United States and Canada.',
      };

    // if the current location country field is not matching the ISO standard from the data json,
    // warn the user that customer Country autofill for country will fail
    if (!data.Country.some(({ ISO }) => ISO === country))
      return {
        type: 'warning',
        message:
          'Warehouse Country value has to be in ISO format (USA/CAN). New Customer Country auto-population is not going to work until the Warehouse Country field has been updated.',
      };
    return { type: 'valid', message: 'Ready' };
  },
  info: `This plugin overrides the Country and State fields of the Customer Form in POS. 
    It will replace the free input fields with dropdowns. 
    The plugin only allows Canada and USA for options for Country field, 
    and all the Canadian and USA states for State field options.`,
  UIAddressFormBeta: CustomAddressFormBeta,
};

export default customerFormStateAutocompletePlugin;
