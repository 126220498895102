/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import { getConnectionHealth } from 'reducers/connectivity/connection';
import { useBreakpoints } from 'utils/hooks/UI';
import Icon from 'components/Icon';
import { HeaderIconButton } from 'containers/Header/components/Icon';

import MainView from './MainView';
import SaveAsView from './SaveAsView';
import QuickPrintView from './QuickPrintView';
import OpenSaleView from './OpenSaleView';
import CurrentSaleView from './CurrentSaleView';
import InfoView from './InfoView';
import MiscView from './MiscView';

import './BottomPopup.css';

const BottomPopup = ({ isSaveAs }) => {
  const [group, setGroup] = useState('main');
  // idea -> set modal.body type and depending on it, group buttons
  const [open, setOpen] = useState(false);
  const isOffline = !useSelector(getConnectionHealth);
  const disabledInOffline = isOffline
    ? { pointerEvents: 'none', opacity: '0.7' }
    : {};

  const isMobileView = !useBreakpoints().mdplus;

  if (isSaveAs && open) {
    return (
      <Modal className="bottom" show={open} animation="true">
        <Modal.Body className="bottom">
          <SaveAsView
            isSaveAs
            goBack={() => setGroup('main')}
            disabledInOffline={disabledInOffline}
            close={() => {
              setOpen(false);
            }}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <li
        className="erply-header__icon--menu erply-header__icon--linked"
        style={{ justifyContent: isMobileView && 'center', marginLeft: 0 }}
        onClick={() => setOpen(true)}
      >
        {isMobileView ? (
          <div style={{ paddingLeft: '4px', display: 'flex' }}>
            <HeaderIconButton size="small" style={{ borderRadius: '100vh' }}>
              <Icon className="erply-header__icon" name="icon_stop" />
              <Icon className="erply-header__icon" name="icon_stop" />
              <Icon className="erply-header__icon" name="icon_stop" />
            </HeaderIconButton>
          </div>
        ) : (
          <div>
            <HeaderIconButton size="small" style={{ borderRadius: '100vh' }}>
              <Icon className="erply-header__icon" name="icon_stop" />
              <Icon className="erply-header__icon" name="icon_stop" />
              <Icon className="erply-header__icon" name="icon_stop" />
            </HeaderIconButton>
          </div>
        )}
      </li>
      <Modal className="bottom" show={open} animation="true">
        <Modal.Body className="bottom">
          {group === 'main' && (
            <MainView
              setOpen={() => setOpen(false)}
              showPage={page => setGroup(page)}
            />
          )}
          {group === 'saveAs' && (
            <SaveAsView
              isSaveAs={false}
              goBack={() => setGroup('main')}
              disabledInOffline={disabledInOffline}
              close={() => {
                setGroup('main');
                setOpen(false);
              }}
            />
          )}
          {group === 'openSale' && !isOffline && (
            <OpenSaleView
              goBack={() => setGroup('main')}
              close={() => {
                setGroup('main');
                setOpen(false);
              }}
            />
          )}
          {group === 'quickPrint' && (
            <QuickPrintView
              goBack={() => setGroup('main')}
              disabledInOffline={disabledInOffline}
              close={() => {
                setGroup('main');
                setOpen(false);
              }}
            />
          )}
          {group === 'currentSale' && (
            <CurrentSaleView
              disabledInOffline={disabledInOffline}
              goBack={() => setGroup('main')}
              close={() => {
                setGroup('main');
                setOpen(false);
              }}
            />
          )}
          {group === 'info' && !isOffline && (
            <InfoView
              disabledInOffline={disabledInOffline}
              goBack={() => setGroup('main')}
              close={() => {
                setGroup('main');
                setOpen(false);
              }}
            />
          )}
          {group === 'misc' && (
            <MiscView
              disabledInOffline={disabledInOffline}
              goBack={() => setGroup('main')}
              close={() => {
                setGroup('main');
                setOpen(false);
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BottomPopup;
