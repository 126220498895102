import { SHOW, HIDE, ENABLE, DISABLE } from '../constants/OnScreenKeyboard';

const initialState = {
  s: false,
  e: JSON.parse(localStorage.getItem('onScreenKeyboard-enabled')) || false,
};

export default ({ s, e } = initialState, { type }) => {
  switch (type) {
    case SHOW:
      return { s: true, e };
    case HIDE:
      return { s: false, e };
    case ENABLE:
      localStorage.setItem('onScreenKeyboard-enabled', 'true');
      return { s, e: true };
    case DISABLE:
      localStorage.removeItem('onScreenKeyboard-enabled');
      return { s, e: false };
    default:
      return { s, e };
  }
};

export function getKeyboardIsShow(state) {
  return state.OnScreenKeyboard.s;
}
export function getKeyboardIsEnabled(state) {
  return state.OnScreenKeyboard.e;
}
