import React from 'react';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, TableRowProps } from '@material-ui/core';

import {
  getDateFormatter,
  getCurrencyFormatterNoSymbol,
} from 'reducers/configs/settings';
import { useAppDispatch } from 'reducers';
import { printReceipt } from 'actions/integrations/printer';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import Icon from 'components/Icon';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { pickupOffer } from 'actions/sales';

type Props = {
  offer: SaleDocumentResponse;
};

const OfferRow: React.FC<Props & TableRowProps> = ({ offer, ...rest }) => {
  const dateFormatter = useSelector(getDateFormatter);
  const dispatch = useAppDispatch();

  const CURR = useSelector(getCurrencyFormatterNoSymbol);

  function print(doc: typeof offer) {
    // @ts-ignore
    dispatch(printReceipt(doc, { offer: true }));
  }

  function sendEmail(offer) {
    dispatch(
      openModalPage({
        component: mp.emailModal,
        isPopup: true,
        props: {
          customerEmail: offer.clientEmail,
          invoiceID: offer.id,
        },
      }),
    );
  }

  return (
    <TableRow
      onClick={() => dispatch(pickupOffer(offer))}
      className="offer-row"
      style={{ cursor: 'pointer' }}
      {...rest}
    >
      <TableCell data-testid="offer-number">{offer.number}</TableCell>
      <TableCell data-testid="offer-date">
        {dateFormatter(offer.date)}
      </TableCell>
      <TableCell data-testid="offer-client-name">{offer.clientName}</TableCell>
      <TableCell data-testid="offer-client-email">
        {offer.clientEmail}
      </TableCell>
      <TableCell data-testid="paid">
        {CURR.stringify(offer.paid ? offer.paid : 0)}
      </TableCell>
      <TableCell data-testid="total">{CURR.stringify(offer.total)}</TableCell>
      <TableCell>
        <Icon
          data-testid="print-offer"
          invert
          name="icon_printer"
          action={e => {
            print(offer);
            e.stopPropagation();
          }}
        />
      </TableCell>
      <TableCell>
        <Icon
          invert
          data-testid="mail-offer"
          name="icon_mail_alt"
          action={e => {
            sendEmail(offer);
            e.stopPropagation();
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default OfferRow;
