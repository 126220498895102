/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import { version as v } from '../../package.json';

import sha from './git_sha';

export const version = `${v}_${sha}`;
export const semanticVersion = v;
export { default as sha } from './git_sha';
export { default as log } from './git_log';
export { default as oneline } from './git_oneline';
export { default as commitTimestamp } from './git_commiter_timestamp';
export const wrapperApiVersion = '2.11.0';
