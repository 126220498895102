import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionActions,
  Button,
  Tabs,
  Tab,
  Box,
  Popover,
  IconButton,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Markunread, Drafts } from '@material-ui/icons';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { readAllNotifications, setUnreadNotification } from 'actions/Error';
import { getCurrentErrors } from 'reducers/Error';
import { createConfirmation } from 'actions/Confirmation';
import MuiButton from 'components/CustomButtons/MuiButton';

import './HeaderNotifications.scss';
import { HeaderIconButton } from '../Icon';

type NotificationType = 'alert';
interface Notification {
  id: number;
  content: string;
  timestamp: number;
  unread: boolean;
  type: NotificationType;
}

interface TabPanelProps {
  type: 'all' | 'unread';
  index: number;
  value: number;
  setUnread: (id: number, unread: boolean, type: string) => void;
  notifications: Notification[];
  handleClose: () => void;
}

interface HeaderComponentProps {
  type: 'all' | 'unread';
  showReadAll: boolean;
  handleClose: () => void;
}

interface MarkAllAsReadButtonProps {
  handleClose: () => void;
}

function MarkAllAsReadButton(props: MarkAllAsReadButtonProps) {
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();

  const { handleClose } = props;

  const markAllAsRead = useCallback(async () => {
    handleClose();
    await new Promise((resolve, reject) =>
      dispatch(
        createConfirmation(resolve, reject, {
          title: t('confirmations.readAll.title'),
          body: t('confirmations.readAll.body'),
        }),
      ),
    ).then(
      // Read all notifications
      async () => {
        dispatch(readAllNotifications());
      },
      () => {
        // Do nothing if cancelled
      },
    );
  }, [dispatch, handleClose, t]);
  return (
    <Button
      variant="contained"
      color="secondary"
      fullWidth
      type="button"
      title={t('titles.markAsReadAll')}
      onClick={e => {
        e.stopPropagation();
        markAllAsRead();
      }}
      endIcon={<Drafts />}
    >
      {t('buttons.markAsReadAll')}
    </Button>
  );
}

function HeaderComponent(props: HeaderComponentProps) {
  const { t } = useTranslation('notifications');
  const { showReadAll, type, handleClose } = props;
  if (type === 'unread') {
    if (showReadAll) {
      return <MarkAllAsReadButton handleClose={handleClose} />;
    }
    return (
      <Typography
        style={{
          marginTop: '10px',
          color: 'text.secondary',
          fontWeight: 'bold',
          justifyContent: 'center',
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        {t('titles.caughtUp')}
      </Typography>
    );
  }
  return null;
}

function TabPanel(props: TabPanelProps) {
  const {
    value,
    index,
    setUnread,
    notifications: propNotifications,
    type,
    handleClose,
  } = props;

  const notifications =
    type === 'all'
      ? propNotifications
      : propNotifications.filter(pn => pn.unread);

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ margin: '0.5rem', overflowY: 'scroll', maxHeight: '400px' }}
    >
      {value === index && (
        <>
          <HeaderComponent
            type={type}
            showReadAll={!!notifications.length}
            handleClose={handleClose}
          />
          {notifications.map(notification => (
            <NotificationComponent
              key={notification.id}
              notification={notification}
              setUnread={() =>
                setUnread(
                  notification.id,
                  !notification.unread,
                  notification.type,
                )
              }
            />
          ))}
        </>
      )}
    </Box>
  );
}

const Accordion = withStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: theme.shape.borderRadius,
    maxWidth: '500px',
  },
}))(MuiAccordion);

const AccordionSummary = withStyles({
  content: {
    width: '100%',
    overflow: 'hidden',
  },
})(MuiAccordionSummary);

function NotificationComponent({
  notification,
  setUnread,
}: {
  notification: Notification;
  setUnread: (id: number, unread: boolean) => void;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation('notifications');
  const ref = useRef<HTMLDivElement | null>(null);

  const fullText = notification.content;
  const time = dayjs(notification.timestamp).format('hh:mm:ss');

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded && ref?.current) {
      ref?.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [expanded]);

  return (
    <Accordion
      data-testid="notification-accordion"
      data-test-key={`notification-accordion-${notification.id}`}
      expanded={expanded}
      onChange={(_e, ex) => {
        setExpanded(ex);
      }}
      ref={ref}
    >
      <AccordionSummary
        data-testid="summary"
        expandIcon={<ExpandMoreIcon />}
        style={{
          opacity: notification.unread ? '1' : `.75`,
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography
              data-testid="time"
              style={{
                color: 'text.secondary',
                fontWeight: 'bold',
              }}
            >
              {time}
            </Typography>
            <Typography
              data-testid="summary-title"
              style={{
                flexShrink: 0,
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {fullText}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ justifyContent: 'end' }}>
            <IconButton
              data-testid="summary-mark-as"
              data-test-key={`${notification.id}-${
                notification.unread ? 'read' : 'unread'
              }`}
              title={t('titles.markAs', {
                context: notification.unread ? 'read' : 'unread',
              })}
              onClick={e => {
                e.stopPropagation();
                dispatch(setUnread(notification.id, !notification.unread));
              }}
            >
              {notification.unread ? <Drafts /> : <Markunread />}
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        data-testid="details"
        style={{ opacity: notification.unread ? '1' : `.75` }}
      >
        <Typography
          data-testid="full-text"
          variant="subtitle1"
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            hyphens: 'auto',
            overflow: 'hidden',
          }}
        >
          {fullText}
        </Typography>
      </AccordionDetails>
      <AccordionActions data-testid="actions">
        <MuiButton
          data-testid="mark-as"
          data-test-key={`${notification.id}-${
            notification.unread ? 'read' : 'unread'
          }`}
          title={t('titles.markAs', {
            context: notification.unread ? 'read' : 'unread',
          })}
          endIcon={notification.unread ? <Drafts /> : <Markunread />}
          onClick={e => {
            e.stopPropagation();
            dispatch(setUnread(notification.id, !notification.unread));
          }}
          variant="contained"
          color={notification.unread ? 'POS' : 'grid_green'}
        >
          {t('buttons.markAs', {
            context: notification.unread ? 'read' : 'unread',
          })}
        </MuiButton>
      </AccordionActions>
    </Accordion>
  );
}

const unreadHandlers = {
  alert: (id, unread) => setUnreadNotification(id, unread),
  // New handlers go here
};

/**
 * Hook that would be getting the correct state
 *
 * Takes data to transform into notification and then return the required types
 *
 * This is just setup for future to simplify the notification centre implementation
 * @returns array of [Notification[], dismissHandler], where Notification[] is the list of notifications and dismissHandler is the function that dismisses the notification
 */
const useNotifications = (): [
  Notification[],
  (id: number, unread: boolean, type: string) => void,
] => {
  // region State fetching
  const dispatch = useDispatch();
  const errors = useSelector(getCurrentErrors);
  // endregion

  // region picking needed info
  // pretend we edit those
  const notifications: Notification[] = errors.map(err => ({
    ...err,
    content: err.text,
    type: 'alert',
  }));
  // endregion

  // region handlers
  const setUnread = (id: number, unread: boolean, type: string) => {
    return dispatch(unreadHandlers[type](id, unread));
  };
  // endregion

  return [notifications, setUnread];
};

function HeaderNotifications({ noAlerts = true }: { noAlerts: boolean }) {
  const [activeTab, setActiveTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const [notifications, setUnread] = useNotifications();

  const style = {
    margin: 'dense',
    color: 'white',
    opacity: noAlerts ? '0.8' : '1',
    fontSize: '24px',
  };

  return (
    <>
      <li>
        <HeaderIconButton size="small" onClick={handleClick}>
          <NotificationsNoneIcon style={style} />
        </HeaderIconButton>
      </li>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-testid="header-notifications"
      >
        <Box
          style={{
            maxWidth: '600px',
            justifyContent: 'center',
            border: '3px solid #232323',
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            style={{ justifyContent: 'center', marginTop: '10px' }}
          >
            <Tab icon={<Markunread />} label="UNREAD" />
            <Tab icon={<Drafts />} label="ALL" />
          </Tabs>
          <TabPanel
            type="unread"
            notifications={notifications}
            setUnread={setUnread}
            value={activeTab}
            index={0}
            handleClose={handleClose}
          />
          <TabPanel
            type="all"
            notifications={notifications}
            setUnread={setUnread}
            value={activeTab}
            index={1}
            handleClose={handleClose}
          />
        </Box>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
