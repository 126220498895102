export enum TaxSitusRule {
  /** 01 - Two-out-of-three test using NPA-NXX */
  TWO_OF_THREE_NPA_NXX = '01',
  /** 02 - Billed to number */
  BILLED_TO_NUMBER = '02',
  /** 03 - Origination number */
  ORIGINATION_NUMBER = '03',
  /** 04 - Zip code */
  ZIP = '04',
  /** 05 - Zip code + 4 */
  ZIP_PLUS_FOUR = '05',
  /** 07 - Point to point zip codes (private line transactions) */
  P2P_ZIP = '07',
  /** 09 - Two-out-of-three test using Zip+4 as tax situs jurisdiction */
  TWO_OF_THREE_ZIP = '09',
  /**
   * 11 - Used when Zipcode/Pluf4 field is assigned as the Billing location
   * and P2PZipcode/P2PPlus4 assigned as service location
   */
  T_11 = '11',
  /** 14 - Use zip code field for international country code (VAT ccalculations) */
  ZIP_I11L = '14',
  /** 17 - Point to point zip codes (private line transactions) with both A and Z endpoints calculated */
  P2P_BIDIRECTIONAL = '17',
  /** 22 - Use all addresses / Zip+4 to determine proper situs location */
  ALL = '22',
  /** 23 - Use only ship to address / Zip+4 */
  SHIP_TO_ADDR = '23',
  /** 24 - Use only Ship From Address / Zip+4 */
  SHIP_FROM_ADDRESS = '24',
  /** 25 - Use only Order Approval Address / Zip+4 */
  ORDER_APPROVAL_ADDRESS = '25',
  /** 26 - Use only Order Placement Address / Zip+4 */
  ORDER_PLACEMENT_ADDRESS = '26',
  /** 27 - Use only ZIP+4 from the Billing Address */
  BILLING_ADDRESS = '27',
}
