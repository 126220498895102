import React, { useContext, useEffect } from 'react';
import * as R from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { CAYAN } from 'paymentIntegrations/cayan/requests/cayanTransactions';
import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import useErrorState, { translateError } from 'utils/hooks/useErrorState';
import { getIsModuleEnabled } from 'reducers/configs/settings';

import TestButton from '../testButton';
import RenderFormItem from '../../../../components/FormFieldCtx';

import { cayanFields } from './formShapes';

const signupUrl = 'http://merchantwarehouse.com/';
const setupUrl =
  'http://support.erply.com/setting-up-cayan-merchant-warehouse-payments/';

const ExtraModule: React.FC<{
  module: string;
  fields: string[];
  children?: never;
}> = ({ module, fields }) => {
  return (
    <Box>
      <Box display="flex" alignItems="center">
        {fields.length > 0 ? (
          <CheckIcon fontSize="inherit" />
        ) : (
          <CloseIcon fontSize="inherit" />
        )}
        <Typography variant="h6">{module}</Typography>
      </Box>
      <Typography>
        {fields.map(k => (
          <div>{k}</div>
        ))}
      </Typography>
    </Box>
  );
};

const ExtraModules: React.FC<{ children?: never }> = () => {
  const modulesEnabled = {
    pax: useSelector(getIsModuleEnabled('pax_payments')),
    cayan: useSelector(getIsModuleEnabled('cayan_payments')),
  };
  const provider = Object.keys(modulesEnabled).find(key => modulesEnabled[key]);

  return (
    <Box>
      <Typography variant="h5">Extra data modules</Typography>
      <Alert style={{ margin: '0.5rem 0' }} severity="info">
        This section lists to the user which payment fields are able to be
        stored in BO and which are not - this depends on the enabled modules.
      </Alert>
      <Box display="flex" justifyContent="space-between">
        <ExtraModule
          fields={
            provider === 'pax'
              ? [
                  'paymentServiceProvider: "pax"',
                  'aid',
                  'entryMethod',
                  'applicationLabel',
                  'transactionType',
                ]
              : []
          }
          module="PAX payment data"
        />
        <ExtraModule
          fields={
            provider === 'cayan'
              ? [
                  'paymentServiceProvider: "merchant_warehouse"',
                  'aid',
                  'applicationLabel',
                  'cryptogramType',
                  'cryptogram',
                  'expirationDate',
                ]
              : []
          }
          module="Cayan / Merchant Warehouse payment data"
        />
      </Box>
    </Box>
  );
};

const CayanConfiguration: React.FC<{ children?: never }> = () => {
  const superContext = useContext(Ctx);
  const {
    configuration,
    setEnableUpdate,
    initialIntegrationState,
  } = superContext.values;
  const { t } = useTranslation('settingsPayment', {
    keyPrefix: 'integrations.cayan',
  });

  const { errors, hasErrors } = useErrorState(configuration, cayanFields);

  useEffect(() => {
    setEnableUpdate(!hasErrors);
  }, [errors, hasErrors, setEnableUpdate]);

  // Update configuration fields
  const onChange = (key: string, value) => {
    if (key === 'secureDeviceConnection') {
      superContext.onChange('configuration', {
        ...configuration,
        [key]: !!value,
        devicePort: value ? '8443' : '8080',
      });
    } else {
      superContext.onChange('configuration', {
        ...configuration,
        [key]: value,
      });
    }
  };

  const allSaved = R.equals(initialIntegrationState, configuration);

  return (
    <>
      <Ctx.Provider value={{ values: configuration, onChange }}>
        <Typography>{t('title')}</Typography>
        <Trans
          i18nKey="settingsPayment:integrations.cayan.signup1"
          values={{ url: signupUrl }}
        >
          <a href={signupUrl} target="_blank" rel="noopener noreferrer">
            replaced by i18n
          </a>
        </Trans>
        <Trans
          i18nKey="settingsPayment:integrations.cayan.signup2"
          values={{ url: setupUrl }}
        >
          <a href={setupUrl} target="_blank" rel="noopener noreferrer">
            replaced by i18n
          </a>
        </Trans>
        {Object.values(cayanFields).map(({ validate, ...rest }) => (
          <RenderFormItem
            key={rest.id}
            title={t(rest.id)}
            error={translateError(errors[rest.id], t)}
            {...rest}
          />
        ))}
        <ExtraModules />
        <TestButton
          disabled={!allSaved}
          testAction={() =>
            CAYAN.ping().then(res =>
              res ? t('connectionTest.ok') : t('connectionTest.fail'),
            )
          }
          text={t('connectionTest.button')}
          helperText={
            allSaved ? '' : t('connectionTest.button', { context: 'needsSave' })
          }
        />
      </Ctx.Provider>
    </>
  );
};

export default CayanConfiguration;
