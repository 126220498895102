import React, { useState } from 'react';
import { FormControl, InputGroup, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { addError, addSuccess } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import UIButton from 'components/UIElements/UIButton';

import { GivexCardInput } from '../components/GivexCardInput';
import { GivexCard } from '../types';
import { adjustGiftCard } from '../API/givexAPI';
import {
  getGivexConfiguration,
  getValidateGivexCard,
} from '../configuration/Configuration';

/** The adjust view allows the user to increment or decrement the balance on a givex card */
const GivexAdjust = () => {
  const [amount, setAmount] = useState('');
  const [card, setCard] = useState<GivexCard>({
    cardNo: '',
    pin: '',
  });
  const { displayName } = useSelector(getGivexConfiguration);
  const givexValidationErrorMessage = useSelector(getValidateGivexCard)(
    card,
    'adjust',
  );
  const dispatch = useDispatch();
  const handleClose = () => dispatch(previousModalPage());

  const handleAdjustGiftCard = amount => {
    if (givexValidationErrorMessage) {
      dispatch(addError(givexValidationErrorMessage));
      return;
    }

    adjustGiftCard(card, amount)
      .then(handleClose)
      .then(() => dispatch(addSuccess(`${displayName} adjustment successful!`)))
      .catch(error => dispatch(addError(error.message)));
  };

  return (
    <div data-testid="givex-giftcard-adjustment">
      <Modal.Header>
        <Modal.Title>
          <h3 data-testid="title">
            <b>{displayName} adjustment</b>
          </h3>
        </Modal.Title>
        <div className="d-flex align-content-center">
          <CloseButton action={handleClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <GivexCardInput value={card} onChange={setCard} type="adjust" />
        <InputGroup className="my-2">
          <FormControl
            placeholder="Amount"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
        </InputGroup>

        <div className="d-flex justify-content-between">
          <UIButton action={() => handleAdjustGiftCard(amount)} text="Add" />
          <UIButton
            action={() => handleAdjustGiftCard(-amount)}
            text="Subtract"
          />
        </div>
      </Modal.Body>
    </div>
  );
};

export default GivexAdjust;
