import { formatDuration, intervalToDuration, isAfter } from 'date-fns';

import { notUndefinedOrNull } from '../../utils';

import { USDLBarcodeData, USDLMappedData, USDLProductConfig } from './types';

/** Fields containing a first name - one of these must be present to consider the scan valid */
const FIRST_NAME_FIELDS = [
  'firstNameAndMiddleName',
  'firstName',
  'firstNameTruncated',
  'otherFirstName',
];
/** Fields containing a last name - one of these must be present to consider the scan valid */
const LAST_NAME_FIELDS = ['lastName', 'lastNameTruncated', 'otherLastName'];
/** Mapping of prefix codes in the raw data to field names in the parsed {@link UsdlData} object */
const usdlFieldDict: Record<keyof USDLBarcodeData, keyof USDLMappedData> = {
  DCT: 'firstNameAndMiddleName',
  DCA: 'jurisdictionVehicleClass',
  DCB: 'jurisdictionRestrictionCodes',
  DCD: 'jurisdictionEndorsementCodes',
  DBA: 'dateOfExpiry',
  DCS: 'lastName',
  DAC: 'firstName',
  DAD: 'middleName',
  DBD: 'dateOfIssue',
  DBB: 'dateOfBirth',
  DBC: 'sex',
  DAY: 'eyeColor',
  DAU: 'height',
  DAG: 'addressStreet',
  DAI: 'addressCity',
  DAJ: 'addressState',
  DAK: 'addressPostalCode',
  DAQ: 'documentNumber',
  DCF: 'documentDiscriminator',
  DCG: 'issuer',
  DDE: 'lastNameTruncated',
  DDF: 'firstNameTruncated',
  DDG: 'middleNameTruncated',
  // optional
  DAZ: 'hairColor',
  DAH: 'addressStreet2',
  DCI: 'placeOfBirth',
  DCJ: 'auditInformation',
  DCK: 'inventoryControlNumber',
  DBN: 'otherLastName',
  DBG: 'otherFirstName',
  DBS: 'otherSuffixName',
  DCU: 'nameSuffix', // e.g. jr, sr
  DCE: 'weightRange',
  DCL: 'race',
  DCM: 'standardVehicleClassification',
  DCN: 'standardEndorsementCode',
  DCO: 'standardRestrictionCode',
  DCP: 'jurisdictionVehicleClassificationDescription',
  DCQ: 'jurisdictionEndorsementCodeDescription',
  DCR: 'jurisdictionRestrictionCodeDescription',
  DDA: 'complianceType',
  DDB: 'dateCardRevised',
  DDC: 'dateOfExpiryHazmatEndorsement',
  DDD: 'limitedDurationDocumentIndicator',
  DAW: 'weightLb',
  DAX: 'weightKg',
  DDH: 'dateAge18',
  DDI: 'dateAge19',
  DDJ: 'dateAge21',
  DDK: 'organDonor',
  DDL: 'veteran',
};

/**
 * Internal method - Finds instances of USDL code words and adds line breaks in front of them
 * essentially separating each single field into its own line for easier parsing
 */
const addLineBreaksToCode = (codeProp: string): string => {
  return Object.keys(usdlFieldDict).reduce((code, key) => {
    const keyIndex = code.indexOf(key);
    if (keyIndex === -1) return code;
    return `${code.slice(0, keyIndex)}\n${code.slice(keyIndex)}`;
  }, codeProp);
};

/**
 * Extracts information from USDL
 * Based on DL/ID Standards - https://www.aamva.org/DL-ID-Card-Design-Standard/
 */
const extractInfo = (code: string) => {
  const pairs = code
    .split(/\n/)
    .map(row => {
      const c = row.substring(0, 3);
      if (usdlFieldDict[c]) {
        return [usdlFieldDict[c], row.substring(3)] as [string, string];
      }
      return undefined;
    })
    .filter(notUndefinedOrNull);

  return Object.fromEntries(pairs);
};


/**
 * Given a raw scanned string, parse the data and ensure required properties are present
 *
 * A failure in this method may indicate a faulty scan and the user should retry
 */
export const parseUsdlData = (rawData: string): USDLMappedData => {
  if (!rawData) throw new Error(`Cannot parse USDL: no data was given (${JSON.stringify(rawData)})`)
  // TODO: Implement parsing according to USDL standards (f.ex. use the separators/terminators given in the code, rather than simply looking for header matches)
  // Parse data from raw string
  const value = extractInfo(addLineBreaksToCode(rawData)) ?? {};

  // Check required fields present
  if (!value.dateOfBirth)
    throw new Error('Missing required data: Date of birth');
  if (!value.dateOfExpiry)
    throw new Error('Missing required data: expiration date');
  // TODO: Do we require both names or can we just require that *some* name exists (i.e. getLegalName returns a non-empty string)
  if (!FIRST_NAME_FIELDS.some(prop => value[prop]))
    throw new Error('Missing required data: First name');
  if (!LAST_NAME_FIELDS.some(prop => value[prop]))
    throw new Error('Missing required data: Last name');

  return value as any as USDLMappedData;
};

/**
 * Given parsed USDL data, extract the age of the person as a number
 */
export const getAge = (data: USDLMappedData): number => {
  const dateString = data.dateOfBirth;
  if (!dateString) return 0;
  const today = new Date();
  const month = Number(dateString.slice(0, 2)) - 1;
  const date = Number(dateString.slice(2, 4));
  const year = Number(dateString.slice(-4));
  const age = today.getFullYear() - year;
  const m = today.getMonth() - month;

  if (m < 0 || (m === 0 && today.getDate() < date)) {
    return age - 1;
  }
  return age;
};
/**
 * Given parsed USDL data, extract the expiration date and return a string representing the 'time until expiry'
 */
export const getTimeTillExpiry = (data: USDLMappedData): string => {
  const dateString = data.dateOfExpiry;
  if (!dateString) return '';
  const year = Number(dateString.slice(-4));
  const monthIndex = Number(dateString.slice(0, 2)) - 1;
  const duration = intervalToDuration({
    start: new Date(year, monthIndex),
    end: new Date(),
  });

  return formatDuration(duration, {
    format: ['years', 'months', 'days'],
    delimiter: ', ',
  });
};

/**
 * Check that the license is valid and the owner is of the required age
 *
 * A failure in this method should reject the license, there is no point to retry
 */
export const validateUsdlData = (
  value: USDLMappedData,
  minAge: number,
): true => {
  // Check expiration
  // TODO: use the DCG field to determine if the document is US or CA and parse the date appropriately
  //  Consider implementing the whole parsing logic by the spec
  //  Ticket: https://erply.atlassian.net/browse/PBIB-5939
  const now = new Date();
  const year = Number(value.dateOfExpiry.slice(-4));
  const monthIndex = Number(value.dateOfExpiry.slice(0, 2)) - 1;
  const expiration = new Date(year, monthIndex);
  if (isAfter(now, expiration)) throw new Error('License has expired');

  // Check age
  if (getAge(value) < minAge)
    throw new Error('Client is under the required age for this product');

  return true;
};

/**
 * Extract the full legal name from USDL data, as a string
 */
export const getLegalName = (value: USDLMappedData): string => {
  const name = {
    firstName: undefined as undefined | string,
    middleName: undefined as undefined | string,
    lastName: undefined as undefined | string,
    suffix: undefined as undefined | string,
  };
  // First name
  if (name.firstName === undefined && value.firstName) {
    name.firstName = value.firstName;
  }
  if (name.firstName === undefined && value.firstNameAndMiddleName) {
    name.firstName = value.firstNameAndMiddleName;
    name.middleName = ''; // Already have middle name, don't need to fetch this separately
  }
  if (name.firstName === undefined && value.otherFirstName) {
    name.firstName = value.otherFirstName;
  }
  if (name.firstName === undefined && value.firstNameTruncated) {
    name.firstName = value.firstNameTruncated;
  }

  // Middle name
  if (name.middleName === undefined && value.middleName) {
    name.middleName = value.middleName;
  }
  if (name.middleName === undefined && value.middleNameTruncated) {
    name.middleName = value.middleNameTruncated;
  }

  // Last name
  if (name.lastName === undefined && value.lastName) {
    name.lastName = value.lastName;
  }
  if (name.lastName === undefined && value.otherLastName) {
    name.lastName = value.otherLastName;
  }
  if (name.lastName === undefined && value.lastNameTruncated) {
    name.lastName = value.lastNameTruncated;
  }

  // Suffix
  if (name.suffix === undefined && value.nameSuffix) {
    name.suffix = value.nameSuffix;
  }
  return (
    [name.firstName, name.middleName, name.lastName, name.suffix]
      // skip undefined OR EMPTY fields, so we don't add duplicate spaces
      .filter(a => !!a)
      .join(' ')
  );
};

/**
 * Combines USDL product and product group configurations
 *
 * Returns USDLProductConfig applying the highest minAge and lowest positive maxAmount
 */
export const combineConfiguration = (
  productConfiguration: USDLProductConfig | undefined,
  productGroupConfiguration: USDLProductConfig | undefined,
): USDLProductConfig => ({
  maxAmount:
    productConfiguration?.maxAmount && productGroupConfiguration?.maxAmount
      ? Math.min(
          productConfiguration.maxAmount,
          productGroupConfiguration.maxAmount,
        )
      : (productConfiguration?.maxAmount ||
          productGroupConfiguration?.maxAmount) ??
        0,
  minAge: Math.max(
    productConfiguration?.minAge ?? 0,
    productGroupConfiguration?.minAge ?? 0,
  ),
});
