import axios from 'axios';

import { getEftposUrl } from './index';

export const requestTestConnection = ({ configuration, ...params }) => {
  return axios
    .get(`${getEftposUrl()}testConnection`)
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestTerminalStatus = ({ ...params }) => {
  return axios
    .post(`${getEftposUrl()}manage`, {
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestSale = ({ configuration, ...params }) => {
  return axios
    .post(`${getEftposUrl()}payment`, {
      transactionType: 'SALE',
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestRefund = ({ configuration, ...params }) => {
  return axios
    .post(`${getEftposUrl()}payment`, {
      transactionType: 'REFUND',
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestVoid = ({ configuration, ...params }) => {
  return axios
    .post(`${getEftposUrl()}payment`, {
      transactionType: 'VOID',
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};
