import { useEffect, useState } from 'react';

type KeyboardKey = KeyboardEvent['key'];

const keyStates: Record<KeyboardKey, boolean | undefined> = {};
const listeners2: ((KeyboardKey, boolean) => void)[] = [];
document.body.addEventListener('keydown', ({ key }) => {
  listeners2.forEach(l => l(key, true));
  keyStates[key] = true;
});
document.body.addEventListener('keyup', ({ key }) => {
  listeners2.forEach(l => l(key, false));
  keyStates[key] = false;
});

export const useKeyState = key => {
  const [state, setState] = useState(keyStates[key] || false);
  useEffect(() => {
    const listener = (k, state) => {
      if (key === k) {
        setState(state);
      }
    };
    listeners2.push(listener);
    return () => {
      listeners2.splice(listeners2.indexOf(listener), 1);
    };
  }, [key]);

  return state;
};
