/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import CKEditor from 'ckeditor4-react';
import { Button, Row, Col } from 'react-bootstrap';

const TextEditor = ({ content, save }) => {
  const [textValue, setTextValue] = useState('');
  useEffect(() => {
    if (content) {
      setTextValue(content);
    } else {
      setTextValue('');
    }
  }, [content]);

  const configuration = {
    allowedContent: true,
    extraAllowedContent: '*(*)',
    height: '500px',
    width: '100%',
  };

  const onEditorChange = evt => {
    setTextValue(evt.editor.getData());
  };

  return (
    <>
      <Row className="h-100 w-100">
        <Col className="w-100 h-100" xs={12}>
          <Button
            className="mb-3"
            onClick={() => save(textValue)}
            variant="success"
          >
            Save
          </Button>
          <CKEditor
            data={textValue}
            onChange={onEditorChange}
            config={configuration}
          />
        </Col>
      </Row>
    </>
  );
};

export default TextEditor;
