import React from 'react';

import './CloseButton.css';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  action?: (e: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent) => void;
  disabled?: boolean;
};
/**
 * Close Button is a close Icon width tweaked styles, that fix the issue with its size and position
 * In case there is no action passed to the commponent it would not change appearence on hover and will have opacity: 0.7
 */
const CloseButton: React.FC<Props> = ({ style = {}, action, ...props }) => {
  // Lowest priority shortcut - if escape does nothing then trigger this instead
  // If esc is already used by another component, this will not take effect
  useShortcut('Escape', action, 0);
  return (
    <span
      style={{ ...style }}
      className={`custom-close-btn ${action ? 'hoverable' : ''}`}
      onClick={e => action && action(e)}
      data-testid="custom-close-button"
      {...props}
    >
      ×
    </span>
  );
};

export default CloseButton;
