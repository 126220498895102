import { isEmail, isEmpty } from 'validator';

export const mssgs = {
  notEmpty: 'This field can not be blank',
  notEmail: 'This field must be a valid email address',
};

type Person = {
  firstName: string;
  lastName: string;
  customerCardNumber: string;
  groupID: string;
  homeStoreID: number;
  birthday: string;
  email: string;
  phone: string;
  mobile: string;
  street: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  code: string;
  notes: string;
  emailOptOut: number;
  customerType: string;
  addressTypeID: string;
};
export const personInitState: Person = {
  firstName: '',
  lastName: '',
  customerCardNumber: '',
  groupID: '',
  homeStoreID: 0,
  birthday: '0000-00-00',
  email: '',
  phone: '',
  mobile: '',
  street: '',
  address2: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  code: '',
  notes: '',
  emailOptOut: 0,
  customerType: 'PERSON',
  addressTypeID: '',
};

type Company = {
  companyName: string;
  vatNumber: string;
  customerCardNumber: string;
  groupName: string;
  homeStoreID: number;
  email: string;
  phone: string;
  mobile: string;
  street: string;
  address2: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  code: string;
  notes: string;
  emailOptOut: number;
  customerType: string;
  addressTypeID: string;
};
export const companyInitState: Company = {
  companyName: '',
  vatNumber: '',
  customerCardNumber: '',
  groupName: '',
  homeStoreID: 0,
  email: '',
  phone: '',
  mobile: '',
  street: '',
  address2: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  code: '',
  notes: '',
  emailOptOut: 0,
  customerType: 'COMPANY',
  addressTypeID: '',
};

type FormShape<Data, Key extends keyof Data> = {
  name: keyof Data;
  type: string;
  key: string;
  disabled?: boolean;
  value: (data: Data) => Data[Key];
  errorText: (data: Data) => string | undefined;
  hide: boolean;
};

export const form = {
  person: [
    {
      title: 'First Name',
      key: 'firstName',
      type: 'text',
      required: true,
      validate: value =>
        isEmpty(value, { ignore_whitespace: true }) && mssgs.notEmpty,
    },
    {
      title: 'Last Name',
      key: 'lastName',
      type: 'text',
      required: true,
      validate: value =>
        isEmpty(value, { ignore_whitespace: true }) && mssgs.notEmpty,
    },
  ],
  common: [
    {
      title: 'Loyalty card number',
      key: 'customerCardNumber',
      type: 'text',
    },
    {
      title: 'Group',
      key: 'groupID',
      type: 'select',
      options: [],
    },
    {
      title: 'Home Store',
      key: 'homeStoreID',
      type: 'select',
      options: [],
    },
    {
      title: 'Email',
      key: 'email',
      type: 'email',
      validate: value =>
        !isEmail(value) &&
        !isEmpty(value, { ignore_whitespace: true }) &&
        mssgs.notEmail,
    },
    {
      title: 'Phone',
      key: 'phone',
      type: 'text',
    },
    {
      title: 'Mobile',
      key: 'mobile',
      type: 'text',
    },
  ],
  company: [
    {
      title: 'Name',
      key: 'companyName',
      type: 'text',
      required: true,
      validate: value =>
        isEmpty(value, { ignore_whitespace: true }) && mssgs.notEmpty,
    },
    {
      title: 'VAT NUMBER',
      key: 'vatNumber',
      type: 'text',
    },
  ],
  address: [
    {
      key: 'street',
      type: 'text',
      style: { width: '100%' },
    },
    {
      key: 'address2',
      type: 'text',
      style: { width: '70%' },
    },
    {
      key: 'postalCode',
      type: 'text',
      style: { width: '30%' },
    },
    {
      key: 'city',
      type: 'text',
      style: { width: '30%' },
    },
    {
      key: 'state',
      type: 'text',
      style: { width: '30%' },
    },
    {
      key: 'country',
      type: 'text',
      style: { width: '30%' },
    },
  ],
  other: {
    gender: {
      type: 'select',
      key: 'gender',
      options: [
        { name: '', value: '' },
        { name: 'FEMALE', value: 'FEMALE' },
        { name: 'MALE', value: 'MALE' },
      ],
      validate: v => !!v ? undefined : mssgs.notEmpty,
    },
    euCustomerType: {
      type: 'select',
      key: 'euCustomerType',
      options: [
        { name: '', value: '' },
        { name: 'DOMESTIC', value: 'DOMESTIC' },
        { name: 'EU', value: 'EU' },
        { name: 'OUTSIDE_EU', value: 'OUTSIDE_EU' },
      ],
      validate: v => !!v ? undefined : mssgs.notEmpty,
    },
    code: {
      type: 'text',
      key: 'code',
    },
    emailOptOut: {
      type: 'checkbox',
      key: 'emailOptOut',
    },
  },
};
