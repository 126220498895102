import * as R from 'ramda';
import { createSelector } from 'reselect';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import debug from 'debug';

import { Customer } from 'types/Customer';
import { PosPlugin } from 'plugins/plugin';
import { getCustomers } from 'services/ErplyAPI';
import { getSalesDocuments } from 'services/ErplyAPI/sales';
import {
  getLastProductIndex,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import { add, ErplyAttributes, round } from 'utils';
import { addProgress, addWarning, dismissType } from 'actions/Error';
import {
  getCurrentSalesDocReturnPayments,
  getCurrentSalesDocument,
  getIsCurrentSaleAReturn,
} from 'reducers/sales';
import { RootState } from 'reducers';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { getPluginConfigurationAtLevel } from 'reducers/Plugins';
import { PaymentType } from 'types/PaymentType';
import {
  getOriginalPayments,
  getOriginalPaymentTotals,
  getPayments,
  getPaymentsCurrency,
  getSalesDocument,
  getTotal,
  getCurrentSalesDocument as getCurrentPaymentSalesDocument,
} from 'reducers/Payments';
import { printReceipt } from 'actions/integrations/printer';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { OfflineSalesDocument } from 'actions/integrations/printer/types';
import { getSaleDocID } from 'actions/integrations/printer/utils';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { combineLifecycleHooks } from 'plugins/pluginUtils';

import { sendInvoiceByEmail } from '../../../actions/sales';

import {
  TafCouponsPoints,
  TafCustomerSearchModal,
  TafContactUpdateForm,
} from './components/components';
import {
  UIVisibleCustomerInformation,
  UICustomTableBillRow,
  UIExtraActionButtons,
  UICoupons,
  UICustomerBadgeCustomRegion,
  UITableStats,
  UIKeyPad,
  PartnerModal,
  UIOriginalPayment,
  UIUserBadge,
  UICustomerFormBeta,
  ComponentConfiguration,
  UIReturnTableHeaders,
  UIReturnOrderProduct,
  TafComponentHeader,
} from './components';
import tafReducer, {
  getMedicalEntitySelected,
  getPrintSecondReceipt,
  getSchoolEntitySelected,
  getTafReduxState,
} from './rdx/reducers';
import {
  removeProductInTafState,
  updateProductsState,
  setCustomerLastUsedEntities,
  resetTafState,
  setPrintSecondReceipt,
} from './rdx/actions';
import documentation from './documentation.md';
import { SET_PRODUCTS_STATE } from './rdx/actionTypes';
import { Configuration } from './types';
import {
  ASSOCIATION_ENTITY_TYPE,
  defaultVoucherTypeID,
  pluginID,
  PROFFESIONAL_ENTITY_TYPE,
} from './constants';
import { components as NSWVoucherComponents } from './NSWVouchers/components';
import QffCardModal from './QFF/QffCardModal';
import {
  setPartnerInfoInNotes,
  contactUpdateFormPrompt,
  removePartnerInfoFromNotes,
  copyPartnerInfoToNotes,
} from './utils';
import {
  askForQffCardNumber,
  qffCardButton,
  saveQffData,
} from './QFF/overrides';

export const baseLog = debug('plugins').extend(pluginID);

const TafPlugin: PosPlugin = {
  id: pluginID,
  name: 'TAF Configuration',
  keywords: ['taf', 'NSW', 'vouchers', 'configuration'],
  infoMDUrl: documentation,
  getStatus: createSelector(
    state =>
      getPluginConfigurationAtLevel<Configuration>(
        pluginID,
        'Company',
        '',
      )(state),
    state => getAllPaymentTypes(state),
    (currentConfig, allPaymentTypes) => {
      const { PaymentTypeID, secondReceiptTemplate } = currentConfig ?? {};

      const getPaymentTypeErrorMessage = () => {
        if (!PaymentTypeID) {
          const defaultPayment = allPaymentTypes.find(
            (pt: PaymentType) => pt.id === defaultVoucherTypeID,
          );
          if (defaultPayment) {
            return `Payment type to use is not configured. Using ID 27 (${defaultPayment.name}).`;
          }
        }
        if (
          allPaymentTypes.some((pt: PaymentType) => pt.id === PaymentTypeID)
        ) {
          return '';
        }
        return 'Payment type to use for NSW Vouchers is not configured.';
      };
      const getReceiptTemplateErrorMessage = () => {
        if (!secondReceiptTemplate)
          return 'Receipt template is not configured.';
        return '';
      };

      const paymentTypeErrorMessage = getPaymentTypeErrorMessage();
      const receiptTemplateErrorMessage = getReceiptTemplateErrorMessage();

      if (!paymentTypeErrorMessage && !receiptTemplateErrorMessage) {
        return {
          type: 'valid',
          message: `Ready`,
        };
      }

      return {
        type: 'warning',
        message: `${paymentTypeErrorMessage}\n${receiptTemplateErrorMessage}`,
      };
    },
  ),
  ComponentConfigurationByLevel: {
    Company: ComponentConfiguration,
  },
  components: {
    TafCustomerSearchModal,
    TafCouponsPoints,
    TafContactUpdateForm,
    PartnerModal,
    QffCardModal,
    ...NSWVoucherComponents,
  },
  ComponentHeader: TafComponentHeader,
  onDoCustomerSearch: {
    on: (p, ap) => async () => {
      const { searchValue } = p;

      const isID = /^\d+$/.test(String(searchValue));

      if (isID) {
        const customerPromiseByID: Promise<Customer[]> = getCustomers({
          customerID: searchValue,
        });

        if (ap.promises) {
          ap.promises.push(customerPromiseByID);
        }
      }

      return {
        ...ap,
        promises: [
          Promise.all(ap.promises)
            .then(R.unnest)
            .then(R.uniqBy(R.prop('id'))),
        ],
      };
    },
  },
  reduxReducer: tafReducer,
  UIVisibleCustomerInformation,
  UIUserBadge,
  UICustomTableBillRow,
  UIExtraActionButtons,
  UICoupons,
  UICustomerBadgeCustomRegion,
  UITableStats,
  UIOriginalPayment,
  onResetShoppingCart: {
    after: () => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      dispatch(resetTafState());
    },
  },
  onStartNewSale: {
    after: () => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      dispatch(resetTafState());
    },
  },
  getTranslationOverrides: createSelector(
    () => null,
    () => ({
      gridButtons: {
        saleOptions: {
          coupons: 'Check MF Voucher',
          saveAsLayaway: 'Save As Layby Payment',
        },
        functions: {
          layawaySales: 'Laybys',
          pendingSales: 'Fit Pad Sales',
          coupons: 'Check MF Voucher',
        },
      },
      sale: {
        pendingSales: {
          title: 'Fit Pad Sales',
        },
      },
      customer: {
        customerView: {
          fields: {
            rewardPoints: 'MyFit points',
          },
        },
      },
      return: {
        alerts: {
          prepaymentNotSupported: {
            title: 'Layby cancellation confirmation',
            body: 'Do you wish to cancel the layby?',
          },
        },
      },
      payment: {
        confirmView: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          title_prepayment: 'Layby nr. {{number}}',
        },
      },
      layaway: {
        save: {
          title: 'Layby payment',
        },
        headers: {
          layawayReturn: 'Layby return - {{number}}',
        },
      },
    }),
  ),
  onAddProduct: {
    after: () => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const productIndex = getLastProductIndex(getState());
      const schoolSelected = getSchoolEntitySelected(getState());
      const medicalSelected = getMedicalEntitySelected(getState());

      if (medicalSelected || schoolSelected) {
        dispatch(
          updateProductsState({
            indexes: [productIndex],
          }),
        );
      }
    },
  },
  onAddMultiProducts: {
    after: orders => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const state = getState();
      const { rows } = getCurrentSalesDocument(state);
      const updatedProducts = {};
      const ids: Set<number> = new Set();
      const customersDict = {};

      const addedOrderIndexes = orders.map(order => String(order.orderIndex));

      /* 
        Original products state is populated by using stableRowIDs as keys,
        but product state is later accessed by using order indexes as keys.
        These 2 are not guaranteed to match and if one row ends up with an order index equal to 
        a stableRowID of another row then wrong entities will be used for that row.
        To avoid such issue only populate original entities of selected rows.
       */
      const selectedRows = rows.filter(row =>
        addedOrderIndexes.includes(String(row.stableRowID)),
      );

      selectedRows.forEach(row => {
        const { associationID, professionalID } = row;
        if (associationID) {
          ids.add(associationID);
        }
        if (professionalID) {
          ids.add(professionalID);
        }
      });

      try {
        const chunkSize = 100;

        const promises = R.pipe(
          R.splitEvery(chunkSize),
          R.map(chunk =>
            getCustomers({
              customerIDs: chunk.join(','),
              recordsOnPage: chunkSize,
            }),
          ),
        )([...ids]);

        const customers: Customer[] = await Promise.all(promises)
          .then(customers => customers.flat())
          .catch(() => {
            dispatch(
              addWarning(
                'There was an error while retrieving previously selected entities',
                {
                  dismissible: false,
                },
              ),
            );
            return [] as Customer[];
          });

        customers.forEach(customer => {
          customersDict[customer.id] = customer;
        });

        selectedRows.forEach(row => {
          const { associationID, professionalID, stableRowID } = row;

          if (associationID) {
            const associationCustomerData = customersDict[associationID];
            updatedProducts[stableRowID] = {
              ...updatedProducts[stableRowID],
              schoolEntity: {
                entityType: ASSOCIATION_ENTITY_TYPE,
                entityID: associationID,
                entityName:
                  associationCustomerData.companyName ||
                  `${associationCustomerData.firstName} ${associationCustomerData.lastName}`,
              },
            };
          }

          if (professionalID) {
            const professionalCustomerData = customersDict[professionalID];
            updatedProducts[stableRowID] = {
              ...updatedProducts[stableRowID],
              medicalEntity: {
                entityType: PROFFESIONAL_ENTITY_TYPE,
                entityID: professionalID,
                entityName:
                  professionalCustomerData.companyName ||
                  `${professionalCustomerData.firstName} ${professionalCustomerData.lastName}`,
              },
            };
          }
        });

        dispatch({
          type: SET_PRODUCTS_STATE,
          payload: updatedProducts,
        });
      } catch (error) {
        dispatch(
          addWarning(
            'There was an error while retrieving previously selected entities',
            {
              dismissible: false,
            },
          ),
        );
      }
    },
  },
  onRemoveOrder: {
    after: ({ orderIndex }) => async dispatch => {
      dispatch(removeProductInTafState(Number(orderIndex)));
    },
  },
  onSaveSalesDocument: combineLifecycleHooks(
    {
      on: saveQffData,
    },
    {
      on: (_p, requests) => async (
        _dispatch: ThunkDispatch<RootState, unknown, Action>,
        getState: () => RootState,
      ) => {
        const state = getState();
        const { productsState, fitID, vouchers } = getTafReduxState(state);
        const shoppingCart = getProductsInShoppingCart(state);
        const payments = getPayments(state);
        const selectedCustomer = getSelectedCustomer(state);
        const partnerPayment = payments.PARTNER;

        const saleDoc = requests.find(
          request => request.requestName === 'saveSalesDocument',
        );

        Object.entries(productsState).forEach(([orderIndex, product]) => {
          const schoolID = product.schoolEntity?.entityID;
          const medicalID = product.medicalEntity?.entityID;
          const index =
            shoppingCart.findIndex(
              scr => scr.orderIndex.toString() === orderIndex,
            ) + 1;
          if (schoolID && saleDoc) {
            saleDoc[`associateID${index}`] = schoolID;
          }

          if (medicalID && saleDoc) {
            saleDoc[`professionalID${index}`] = medicalID;
          }
        });

        return requests.map(request => {
          if (request.requestName !== 'saveSalesDocument') return request;

          const attrs = ErplyAttributes.fromFlatArray(request);
          const saleDocWithoutAttrs = ErplyAttributes.withoutFlatArray(request);
          const { notes, internalNotes } = saleDocWithoutAttrs;
          if (fitID.length) {
            attrs.set('fit_id', fitID);
          }

          if (partnerPayment) {
            /**
             * If manually added partner payment or prefilled and partner company fetched
             * then apply notes and attributes based on the payment object
             */
            if (partnerPayment.customerID && partnerPayment.company) {
              attrs.set('po_number', partnerPayment.customerID);
              attrs.set('partner_id', String(partnerPayment.company?.id));
              const partnerInfo = {
                selectedCustomerName: selectedCustomer.fullName,
                poNumber: partnerPayment.customerID,
                partnerName: partnerPayment.company?.fullName ?? '',
              };
              saleDocWithoutAttrs.notes = setPartnerInfoInNotes(
                notes,
                partnerInfo,
              );
              saleDocWithoutAttrs.internalNotes = setPartnerInfoInNotes(
                internalNotes,
                partnerInfo,
              );
              /**
               * If prefilled payment and failed to fetch partner company
               * then apply notes and attributes from the original sale document
               */
            } else {
              const originalDocument = getCurrentPaymentSalesDocument(
                getState(),
              );
              const originalAttributes = new ErplyAttributes(
                originalDocument.attributes,
              );
              attrs.set('po_number', originalAttributes.get('po_number'));
              attrs.set('partner_id', originalAttributes.get('partner_id'));
              saleDocWithoutAttrs.notes = copyPartnerInfoToNotes(
                originalDocument.notes,
                notes,
              );
              saleDocWithoutAttrs.internalNotes = copyPartnerInfoToNotes(
                originalDocument.internalNotes,
                internalNotes,
              );
            }
            /**
             * If partner payment is missing then removed notes and attributes
             * related to partner payment in case any were inherited from the
             * original document
             */
          } else {
            attrs.remove('po_number');
            attrs.remove('partner_id');
            saleDocWithoutAttrs.notes = removePartnerInfoFromNotes(notes);
            saleDocWithoutAttrs.internalNotes = removePartnerInfoFromNotes(
              internalNotes,
            );
          }
          if (vouchers.length) {
            const voucherAttributeValue = vouchers.map(v => v.code).join(',');
            attrs.set('nsw_vouchers', voucherAttributeValue);
          }
          return R.mergeDeepRight(saleDocWithoutAttrs, attrs.asFlatArray);
        });
      },
      after: (p, ap) => async (
        dispatch: ThunkDispatch<RootState, unknown, Action>,
        getState,
      ) => {
        try {
          const { paymentTypeID } = getTafReduxState(getState());
          const { invoiceID } = ap.salesDocument;

          ap.requests
            .filter(r => r.requestName === 'savePayment')
            .filter(r => String(r.typeID) === String(paymentTypeID))
            .map(r => r.info)
            .forEach(email => {
              dispatch(
                sendInvoiceByEmail({ invoiceID, enteredAddress: email }),
              );
            });
        } catch (e) {
          console.error('Unable to send emails to partner customer');
        }
        dispatch(resetTafState());
      },
    },
  ),
  onSetCustomer: {
    before: p => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const state = getState();
      const { isEditMode, applyTafLogic } = getTafReduxState(state);
      const currentSaleIsReturn = getIsCurrentSaleAReturn(state);
      const { clientID } = getCurrentSalesDocument(state);

      if (
        currentSaleIsReturn &&
        clientID !== p.data &&
        !isEditMode &&
        !applyTafLogic
      ) {
        dispatch(
          addWarning('Cannot change customer while making a return', {
            dismissible: true,
            selfDismiss: true,
          }),
        );
        throw new Error('Cannot change customer while making a return');
      }
    },
    on: (_p, ap) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      const customer = ap.payload;
      const { email, mobile, homeStore } = customer;

      if (!email || !mobile) {
        dispatch(contactUpdateFormPrompt(customer));
      }

      return R.pipe(
        R.when(
          () => !!homeStore,
          R.set(R.lensPath(['payload', 'homeStoreID']), homeStore),
        ),
        R.assocPath(['props', 'displayCouponsInfo'], false),
      )(ap);
    },
    after: (_props, customerData) => async (
      dispatch: ThunkDispatch<RootState, unknown, Action>,
    ) => {
      const documents = await getSalesDocuments({
        clientID: customerData.id,
        getRowsForAllInvoices: 1,
      });

      const currentCustomerWithEntities = await getCustomers({
        customerID: customerData.id,
        getAssociationsAndProfessionals: 1,
      });
      const {
        defaultAssociationID,
        defaultProfessionalID,
      } = currentCustomerWithEntities[0];

      const allRows = documents.flatMap(doc => doc.rows);
      const assocIds = allRows
        .map(row => row.associationID)
        .filter(v => v !== undefined && v !== 0);

      if (defaultAssociationID && defaultAssociationID !== 0) {
        assocIds.unshift(defaultAssociationID);
      }

      const uniqueAssocIds = [...new Set(assocIds)].slice(0, 4);

      const proffIds = allRows
        .map(row => row.professionalID)
        .filter(v => v !== undefined && v !== 0);

      if (defaultProfessionalID && defaultProfessionalID !== 0) {
        proffIds.unshift(defaultProfessionalID);
      }

      const uniqueProffIds = [...new Set(proffIds)].slice(0, 4);

      try {
        const [prevAssocObjects, prevProffObjects]: [
          Customer[],
          Customer[],
        ] = await Promise.all([
          getCustomers({
            customerIDs: uniqueAssocIds.join(','),
          }),
          getCustomers({
            customerIDs: uniqueProffIds.join(','),
          }),
        ]);

        dispatch(
          setCustomerLastUsedEntities({
            previousAssociations: prevAssocObjects,
            previousProffesionals: prevProffObjects,
          }),
        );
      } catch (error) {
        dispatch(
          addWarning(
            'There was an error while retrieving previously selected entities',
            {
              dismissible: false,
            },
          ),
        );
      }
    },
  },
  onOpenPaymentModal: {
    on: (_p, ap) => async (
      _dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const log = baseLog.extend('onOpenPaymentModal.on');
      let retAP = ap;
      const { paymentTypeID, vouchers } = getTafReduxState(getState());
      const {
        PaymentTypeID: NSWVoucherPaymentTypeID,
      } = getPluginConfigurationAtLevel<Configuration>(
        pluginID,
        'Company',
        '',
      )(getState()) ?? {
        PaymentTypeID: defaultVoucherTypeID,
      };
      const reduxPaymentTypes = getAllPaymentTypes(getState());

      const payments = ap.originalPayments || [];
      const returnedPayments = getCurrentSalesDocReturnPayments(getState());
      const { code } = getPaymentsCurrency(getState());

      const partnerPayment = payments.find(
        pmt => Number(pmt.typeID) === Number(paymentTypeID),
      );

      if (
        partnerPayment &&
        (ap.isCurrentSaleAReturn ||
          (['PREPAYMENT', 'ORDER'].includes(ap.salesDocument.type) &&
            ap.salesDocument.invoiceState === 'CANCELLED'))
      ) {
        log(
          'Partner payment was found and sale is a return. Adding to payment list',
        );

        const returnedPartnerAmount = returnedPayments
          .filter(pmt => pmt.type === 'PARTNER')
          .map(pmt => Number(pmt.sum) * -1)
          .reduce(add, 0);

        // do not add payment if initial partner payment amount already returned
        if (round(returnedPartnerAmount) === round(partnerPayment.sum)) {
          return retAP;
        }

        // If layaway is being cancelled with a fee, that exceeds the paid amount, do not pre-add partner payment
        const amount =
          ap.total >= 0
            ? 0
            : Math.min(Math.abs(ap.total), Number(partnerPayment.sum));

        // Do not add partner payment if total is 0 or partner payment's amount is 0
        if (amount !== 0 && amount - returnedPartnerAmount !== 0) {
          const attributes = new ErplyAttributes(
            ap.currentSalesDocument.attributes,
          );
          const customerID = attributes.get('po_number');
          const companyID = attributes.get('partner_id');
          let company;
          if (customerID) {
            [company] = await getCustomers({ id: companyID }).catch(() => []);
          }

          retAP = R.evolve({
            payments: R.assoc('PARTNER', {
              typeID: partnerPayment.typeID,
              caption: partnerPayment.type,
              amount: (amount - returnedPartnerAmount) * -1,
              customerID,
              company,
            }),
          })(retAP);
        }
      }
      if (vouchers.length) {
        log(
          'Sale has vouchers attached to it. Checking if configured voucher payment type is among available payment types',
        );
        const paymentType = reduxPaymentTypes.find(
          rpt => rpt.id === NSWVoucherPaymentTypeID,
        );
        if (paymentType) {
          log(
            'Voucher payment type does exist among payments. Adding voucher payment to payments list',
          );
          const sum = vouchers.length * 50;
          retAP = R.evolve({
            payments: R.assoc('NSW', {
              typeID: paymentType.id,
              type: paymentType.type,
              amount: sum > ap.total ? ap.total : sum,
              caption: paymentType.name,
              currencyRate: 1,
              currencyCode: code,
            }),
          })(retAP);
        } else {
          log(
            'Voucher payment type not detected. No NSW voucher payment added',
          );
        }
      }
      return retAP;
    },
    after: askForQffCardNumber,
  },
  selectorOverrides: {
    getSettings: base =>
      createSelector(
        base,
        state => getPrintSecondReceipt(state as RootState),
        state =>
          getPluginConfigurationAtLevel<Configuration>(
            pluginID,
            'Company',
            '',
          )(state),
        (settings, printSecondReceipt, pluginConfig) =>
          R.pipe(
            R.assoc('pos_brazil_show_current_document_type_above_cart', true),
            R.when(
              R.always(printSecondReceipt),
              R.pipe(
                R.assoc('use_actual_reports_templates', true),
                R.assoc(
                  'actual_reports_template_for_creditinvoice',
                  pluginConfig?.secondReceiptTemplate ?? 0,
                ),
              ),
            ),
          )(settings),
      ),
    getPaymentLimits: base =>
      createSelector(
        base,
        state =>
          getPluginConfigurationAtLevel<Configuration>(
            pluginID,
            'Company',
            '',
          )(state),
        state => getTotal(state),
        state => getSalesDocument(state),
        state => getOriginalPayments(state),
        state => getOriginalPaymentTotals(state),
        state => getAllPaymentTypes(state),
        (
          baseLimits,
          conf,
          total,
          currentSalesDoc,
          originalPayments,
          originalPaymentTotals,
          types,
        ) => {
          const log = baseLog.extend('selectorOverrides.getPaymentLimits');
          let limitsToReturn = baseLimits;
          const confTypeId = conf?.PaymentTypeID ?? defaultVoucherTypeID;

          if (!confTypeId || !originalPayments.length) {
            log(
              'Either Voucher payment type not configured or no original payments present. Returning limits as is',
            );
            return baseLimits;
          }

          const configuredType = types.find(t => t.id === confTypeId)?.name;

          if (!configuredType) {
            log(
              'Configured type not among currently available payment types. Returning limits as is',
            );
            return baseLimits;
          }

          const originalPaymentTypes: string[] = originalPayments.map(
            op => op.type,
          );

          if (currentSalesDoc.id && currentSalesDoc.type === 'CREDITINVOICE') {
            log('Current sale is a referenced refund/exchange');
            if (originalPayments.some(op => op.typeID === confTypeId)) {
              log(
                'Sale was originally paid by NSW voucher. Limits potentially need edits',
              );
              if (total < 0) {
                log(
                  'Exchange sale less than original aka money needs to be returned to customer. Allowing to return only paid by other tenders + STORECREDIT',
                );
                limitsToReturn = limitsToReturn.map(limit => {
                  log('Checking limits for payment type of: ', limit.type);
                  if (limit.type === 'STORECREDIT') {
                    log('Can return to STORECREDIT any amount.');
                    return { type: 'STORECREDIT', amount: Infinity };
                  }
                  if (limit.type === configuredType) {
                    log(
                      'Disallow returning since it is configured to act as NSW voucher',
                    );
                    return { ...limit, amount: -1 };
                  }
                  if (originalPaymentTypes.find(opt => opt === limit.type)) {
                    log(
                      'This payment type was used when originally paying for document. Limiting to original paid sum of: ',
                      originalPaymentTotals[limit.type],
                    );
                    return {
                      ...limit,
                      amount: originalPaymentTotals[limit.type],
                    };
                  }
                  log(
                    'Using this payment type not allowed since it was not used when originally paying for document.',
                  );
                  return {
                    ...limit,
                    amount: -1,
                  };
                });
                // For some reason, cash is missing from limits all in all. Since client wants to return CASH up to what/if was originally paid by it, need to manually add it to limit array
                if (
                  !limitsToReturn.some(({ type }) => type === 'CASH') &&
                  originalPaymentTypes.some(opt => opt === 'CASH') &&
                  originalPaymentTotals.CASH
                ) {
                  log(
                    `CASH type was missing from payment limits. Adding it manually and limiting it to originally paid by CASH sum of ${originalPaymentTotals.CASH}`,
                  );
                  limitsToReturn.push({
                    type: 'CASH',
                    amount: originalPaymentTotals.CASH,
                  });
                }
              } else {
                log('Exchange sale more than original. Limits unchanged');
              }
            } else {
              log(
                'Sale was not originally paid by NSW Voucher. User configured limits are applied',
              );
            }
          } else {
            log(
              'Current sale is not a referenced refund/exchange. Limits unchanged',
            );
          }
          return limitsToReturn;
        },
      ),
    getSaleOptionButtons: base =>
      createSelector(base, baseButtons => [...baseButtons, qffCardButton]),
  },
  onSaveCustomer: {
    on: (p, ap) => async (
      _dispatch: ThunkDispatch<RootState, unknown, Action>,
      getState: () => RootState,
    ) => {
      const { customerID } = getTafReduxState(getState());

      const updatedCustomer = R.clone(ap);

      if (ap.params.homeStoreID) {
        if (!ap.params.homeStore) {
          // TAF exclusively needs "homestore" parameter to be able to create a new customer, do not use homeStoreID
          updatedCustomer.params.homeStore = String(ap.params.homeStoreID);
        }

        delete updatedCustomer.params?.homeStoreID;
      }

      if (!ap?.params.customerID && customerID) {
        updatedCustomer.params.customerID = Number(customerID);
      }

      return updatedCustomer;
    },
  },
  onPrintReceipt: {
    after: (p, o) => async (
      dispatch: ThunkDispatch<unknown, unknown, Action>,
      getState,
    ) => {
      const { payments, salesDocument, options } = p;
      const printSecondReceipt = getPrintSecondReceipt(getState());

      const getFullSaleDoc = async () => {
        // @ts-ignore - fix later
        const id = getSaleDocID(salesDocument);
        // there will be no id available when sale was made in offline mode
        const params = id
          ? { id }
          : { number: (salesDocument as OfflineSalesDocument).number };

        const docs = await getSalesDocuments(params);
        const fullSaleDoc = docs?.[0] ?? {};

        return fullSaleDoc;
      };

      const fullSaleDoc = (salesDocument as SaleDocumentResponse).type
        ? (salesDocument as SaleDocumentResponse)
        : await getFullSaleDoc();

      if (fullSaleDoc.type !== 'CREDITINVOICE' || printSecondReceipt) return;
      dispatch(
        addProgress('Printing the second receipt', 'printing-second-receipt'),
      );
      dispatch(setPrintSecondReceipt(true));
      try {
        await dispatch(
          printReceipt(
            {
              payments,
              ...{
                ...fullSaleDoc,
                id: String(fullSaleDoc.id),
              },
              // temporarily convert to any
              ...(salesDocument as any),
            },
            options,
          ),
        );
      } finally {
        dispatch(setPrintSecondReceipt(false));
        dispatch(dismissType('printing-second-receipt'));
      }
    },
  },
  UICustomerFormBeta,
  UIKeyPad,
  UISetBulkReturnReasons: () => null,
  UIReturnTableHeaders,
  UIReturnOrderProduct,
};

export default TafPlugin;
