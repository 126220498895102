import { printReceipts } from './printReceipts';

export const handleWithAction = action => (
  response,
  cardPayment,
  hooks,
) => async dispatch => {
  if (response && response.data.records.length) {
    const result = response.data.records[0];
    const receipts = result.additionalData;

    if (
      result.transactionStatus === 'COMPLETED_REV' ||
      result.transactionStatus === 'COMPLETED'
    ) {
      action(result, cardPayment, hooks);
      dispatch(printReceipts(receipts));
      return result;
    }

    if (
      (result.resultCode === '1' && result.statusMessage.includes('327')) ||
      (result.resultCode === '1' && result.statusMessage.includes('0'))
    ) {
      hooks.updateMessage(result.statusMessage);
      return 0;
    }

    if (result.transactionStatus === '') {
      hooks.updateMessage(
        `Unsuccessful cancellation - Cancelled. \n Status: ${result.statusMessage}`,
      );
      hooks.enableButtons(['retry', 'terminal-connection', 'close']);
      return false;
    }

    hooks.updateMessage(`Unsuccessful cancellation - ${result.statusMessage}`);
    hooks.enableButtons(['retry', 'terminal-connection', 'close']);
    return false;
  }

  hooks.updateMessage(
    `Unsuccessful cancellation.\n  Response is missing - ${JSON.stringify(
      response,
    )}`,
  );
  return hooks.enableButtons(['retry', 'terminal-connection', 'close']);
};
