import * as c from 'constants/integrations';
import { processPayments } from 'actions/Payments';
import { getActivePaymentIntegration } from 'reducers/cafaConfigs';
import { getPluginHooksByName } from 'reducers/Plugins';
import * as paymentIntegrations from 'paymentIntegrations/paymentIntegrations';

import { defaultInitPayment } from '../Terminal/getIntegration';

/** @todo Document */
// TODO: NOTE that processPayment does not receive props but openTerminalIntegration does, this might cause issues with some integrations
export function openTerminalIntegration(...params) {
  return processPayments(...params);
}

export function setIntegrationType(integration) {
  return async dispatch => {
    dispatch({
      type: c.SET_INTEGRATION_TYPE,
      payload: integration,
    });
  };
}

/** @todo Document */
export function setIntegrationStatus(status) {
  return async dispatch => {
    dispatch({
      type: c.SET_INTEGRATION_STATUS,
      payload: status,
    });
  };
}

function defaultCloseBatch() {
  return async () => true;
}

function missingAction(action, integration) {
  return () => async () => {
    console.warn(`Missing function '${action}' in integration '${integration}'`);
  };
}

function doWithIntegration(action, defaultFunc) {
  return (...params) => async (dispatch, getState) => {
    const currentIntegration = getActivePaymentIntegration(getState());
    const fallback = defaultFunc || missingAction(action, currentIntegration);

    const builtin = paymentIntegrations[currentIntegration];
    const plugin = getPluginHooksByName('customPaymentIntegration')(
      getState(),
    ).find(int => int.id === currentIntegration);
    if (builtin?.[action]) return dispatch(builtin[action](...params));
    if (plugin?.[action]) return dispatch(plugin[action](...params));

    console.warn(
      `Integration '${currentIntegration}' has missing or broken method '${action}'\n falling back to default behaviour\n`,
    );
    return dispatch(fallback(...params));
  };
}

export function integrationFunctions() {
  return async (dispatch, getState) => {
    const currentIntegration = getActivePaymentIntegration(getState());
    const builtin = paymentIntegrations[currentIntegration];
    const plugin = getPluginHooksByName('customPaymentIntegration')(
      getState(),
    ).find(int => int.id === currentIntegration);
    if (builtin) return builtin;
    if (plugin) return plugin;
    console.error(`Integration ${currentIntegration} does not exist`);
    return null;
  };
}

export const integrationSetup = doWithIntegration('integrationSetup');
export const integrationCleanup = doWithIntegration('integrationCleanup');
export const cancelPayments = doWithIntegration('cancelPayments');
export const voidPayments = doWithIntegration('voidPayments');
export const initPayments = doWithIntegration(
  'initPayments',
  defaultInitPayment,
);
export const closeBatch = doWithIntegration('closeBatch', defaultCloseBatch);
