import uuidv4 from 'uuid/v4';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import i18next from 'i18next';

import { addWarning } from 'actions/Error';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { PosPlugin } from 'plugins/plugin';
import { getCurrentSalesDocument } from 'reducers/sales';
import { timestampInSeconds } from 'utils';
import { getAllPayments, getBalance } from 'reducers/Payments';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getLoggedInEmployeeID } from 'reducers/Login';

import { GiftCard } from './UIGiftCard';
import documentation from './documentation.md';
import { Config, Configuration, getConfiguration } from './configuration';
import { pluginID } from './constants';
import { getShouldShowIssueGiftCardSection } from './selectors';
import OriginalPayment from './UIOriginalPayment';

const giftCardRefundPlugin: PosPlugin<Configuration> = {
  id: pluginID,
  name: 'Issue new gift card on return',
  infoMDUrl: documentation,
  keywords: ['gift', 'card', 'refund', 'return'],

  ComponentConfigurationByLevel: {
    Company: Config,
  },
  combineConfiguration: c =>
    R.mergeDeepLeft(c)({
      giftCardTypeID: 0,
    }),
  // Adds UI to issue new gift card as return payment
  UIGiftCard: GiftCard,
  // Disabled original serial gift card payment onClick functionality
  UIOriginalPayment: OriginalPayment,
  // Adds payment to redux
  onAddSerialGiftCardProduct: {
    on: (p, ap) => async (
      dispatch: ThunkDispatch<unknown, unknown, Action>,
      getState,
    ) => {
      const { giftCardTypeID } = getConfiguration(getState());
      const shouldMakeChanges = getShouldShowIssueGiftCardSection(getState());
      if (!shouldMakeChanges) return ap;

      const currentPayments = getAllPayments(getState());
      const serialAlreadyInUse = !!currentPayments.find(
        pmt => pmt.serial === p.giftCardSerial,
      );
      if (serialAlreadyInUse) {
        dispatch(
          addWarning(
            i18next.t('giftcard:alerts.serialAlreadyAdded', {
              serial: p.giftCardSerial,
            }),
            {
              dismissible: true,
              errorType: 'giftCardSerialAlreadyAdded',
              selfDismiss: 3000,
            },
          ),
        );
        throw new Error('Serial already in use');
      }

      const onDone = () => {
        dispatch(setPaymentSelected(''));
        dispatch(previousModalPage());
      };

      const balance = getBalance(getState());

      await dispatch(
        setPayment({
          key: uuidv4(),
          type: 'GIFTCARD',
          caption: 'Gift card refund',
          amount: -balance,
          serial: p.giftCardSerial,
          // Needed to later identify request in onSaveSalesDocument.
          // This is the best solution I found that does not require plugin redux
          giftCardID: -1,
          giftCardTypeID,
        }),
      ).then(onDone, onDone);
      throw new Error('No need to add gift card to cart');
    },
  },
  // Modifies requests to removed giftCardID and properly save gift card
  onSaveSalesDocument: {
    on: (p, ap) => async (_, getState) => {
      const currentSaleDoc = getCurrentSalesDocument(getState());
      const { pointOfSaleID, warehouseID } = getSelectedPos(getState());
      const customerID = getSelectedCustomerID(getState());
      const employeeID =
        currentSaleDoc?.employee?.id ?? getLoggedInEmployeeID(getState());
      return R.map(
        R.when(
          R.propEq('giftCardID', -1),
          R.pipe(
            R.dissoc('giftCardID'),
            R.when(
              R.propEq('requestName', 'saveGiftCard'),
              R.pipe(
                request => R.assoc('value', request.balance, request),
                R.mergeLeft({
                  purchasingCustomerID: customerID,
                  purchaseDateTime: timestampInSeconds(),
                  purchaseWarehouseID: warehouseID,
                  purchasePointOfSaleID: pointOfSaleID,
                  purchaseEmployeeID: employeeID,
                  purchaseInvoiceID: 'CURRENT_INVOICE_ID',
                  added: timestampInSeconds(),
                }),
              ),
            ),
          ),
        ),
      )(ap);
    },
  },
};

export default giftCardRefundPlugin;
