import { PatchScript } from '@pos-refacto/patchscript-with-react';

import { missing } from './utils';
import { mmReceiptTranslations } from './translations/translations';

type HeaderData = {
  company: {
    name: string;
    address: string;
  };
  warehouse: {
    fax: string;
    phone: string;
    code?: string;
  };
  register: {
    name: string;
  };
  customer: {
    cardNumber: string;
  };
  receipt: {
    number: string;
  };
  employee: {
    name: string;
  };
  misc: {
    currentTime: string;
  };
};

/**
 * <b>M&M Food Market Milton - Store 018 018 - Milton Register 1</b>
 * <pre>
 * 400-420 Main st. E, Units 104 & 105, Milton, ON L9T1P9
 * Fax: 9058783465
 * Phone: 9058781848
 * Store N°: 018
 * Receipt N°: 34048416
 * Customer card number: 0123456798
 * Salesperson: Jyrgen E.
 */
export const receiptHeaderDetails = (
  {
    company,
    warehouse,
    register,
    employee,
    receipt,
    customer,
    misc,
  }: HeaderData,
  { header: t } = mmReceiptTranslations.en,
): PatchScript => [
  // Company details
  {
    type: 'text',
    pieces: [
      { text: `${company.name} - ${register.name}`, meta: { bold: true } },
    ],
  },
  { type: 'text', pieces: [{ text: `${company.address}` }] },
  { type: 'text', pieces: [{ text: `${t.fax}: ${warehouse.fax}` }] },
  { type: 'text', pieces: [{ text: `${t.phone}: ${warehouse.phone}` }] },
  {
    type: 'text',
    pieces: [
      { text: `${t.storeNumber}: ` },
      warehouse.code ? { text: warehouse.code } : missing(3),
    ],
  },
  // Receipt details
  {
    type: 'table',
    columns: [
      { baseWidth: 0, weight: 1 },
      { baseWidth: 0, weight: 1 },
    ],
    rows: [
      {
        type: 'invisible',
        cells: [
          {
            pieces: [{ text: `${t.receiptNumber}: ${receipt.number}` }],
          },
          {
            align: 'right',
            pieces: [{ text: misc.currentTime }],
          },
        ],
      },
    ],
  },
  {
    type: 'text',
    pieces: [{ text: `${t.customerCardNumber}: ${customer.cardNumber}` }],
  },

  { type: 'text', pieces: [{ text: `${t.salesperson}: ${employee.name}` }] },
];
