import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import InputField from 'components/FieldTypes/InputField';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import UIButton from 'components/UIElements/UIButton';
import { addWarning } from 'actions/Error';
import { formatPrice } from '../utils';

const BulkEditPrice = ({ resolve, reject }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState('');

  const handleChange = e => {
    setState(formatPrice(e.target.value));
  };

  const onSave = () => {
    if (!state.length) {
      dispatch(addWarning('Price can not be empty'));
      return undefined;
    }
    resolve(state);
    dispatch(previousModalPage());
    return undefined;
  };

  const onCancel = () => {
    reject();
    dispatch(previousModalPage());
  };

  useShortcut('Enter', onSave, 100);
  useShortcut('Escape', onCancel, 100);

  return (
    <>
      <Modal.Header>Bulk price edit</Modal.Header>
      <Modal.Body>
        <InputField
          title="Enter price"
          autoFocus
          onChange={handleChange}
          value={state}
        />
        <div className="text-right">
          <UIButton text="Cancel" variant="danger" action={onCancel} />
          <UIButton
            className="ml-1"
            text="Save"
            variant="success"
            action={onSave}
          />
        </div>
      </Modal.Body>
    </>
  );
};

export default BulkEditPrice;
