/* eslint-disable no-await-in-loop,no-console */

import {
  REDUX_ALL_WAREHOUSES,
  REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK,
  REDUX_POSID,
  REDUX_WAREHOUSEID,
} from 'constants/persistence';

import ErplyRequest from './ErplyRequest';
import { getSObyName, getTableMeta, SO, SyncableSOName } from './types';
import getDB from './getDB';
import saveItemsToSO from './saveItemsToSO';
import removeItemsFromSO from './removeItemsFromSO';
import getItemsFromSO from './getItemsFromSO';

export const sleep = seconds =>
  new Promise(resolve => setTimeout(resolve, seconds * 1000));

const BULK_SIZE = 1;
const TIMING = 0;

const applyCorrectParamsForSO = async (
  clientCode: string,
  SO_NAME: SyncableSOName,
  params = {},
) => {
  const SELECTED_SO = getSObyName(SO_NAME);
  const db = await getDB(clientCode);
  const warehouseID = await db.get(SO.SHARED.NAME, REDUX_WAREHOUSEID);
  const posID = await db.get(SO.SHARED.NAME, REDUX_POSID);
  const TABLE_META = getTableMeta(SO_NAME, warehouseID);
  const changedSince = await db.get(SO.META.NAME, TABLE_META.LAST_SYNC_SERVER);
  const fetchOnlyProductsInStock = await db.get(
    SO.SHARED.NAME,
    REDUX_FETCH_ONLY_PRODUCTS_IN_STOCK,
  );
  const { assortmentID } =
    ((await db.get(SO.SHARED.NAME, REDUX_ALL_WAREHOUSES)) ?? []).find(
      wh => String(wh.warehouseID) === String(warehouseID),
    ) ?? {};

  const defaultParams = {
    clientCode,
    request: SELECTED_SO.REQUEST_NAME,
    ...SELECTED_SO.EXTRA_PARAMS,
    ...(SELECTED_SO.NAME === 'products'
      ? { getOnlyItemsInStock: fetchOnlyProductsInStock ? 1 : 0 }
      : {}),
    ...params,
  };

  const paramsWithPosIdAndWhId = {
    ...defaultParams,
    ...(SO.EMPLOYEES.NAME === SO_NAME ? {} : { warehouseID, posID }),
  };

  switch (SO_NAME) {
    case SO.ASSORTMENTS.NAME:
      return { ...paramsWithPosIdAndWhId, assortmentID };
    case SO.PRODUCTS.NAME: {
      const activeParameter = changedSince ? {} : { active: 1 };
      return {
        ...paramsWithPosIdAndWhId,
        ...activeParameter,
        orderBy: 'productID',
        orderByDir: 'asc',
      };
    }
    default:
      return paramsWithPosIdAndWhId;
  }
};

const filterOutAndRemoveArchivedProducts = async (clientCode, products) => {
  const archivedIDs: any[] = [];
  const activeProducts: any[] = [];
  products.forEach(product => {
    if (product.active === 0) {
      archivedIDs.push(product[SO.PRODUCTS.ID_PROP]);
    } else {
      activeProducts.push(product);
    }
  });
  await removeItemsFromSO(clientCode, SO.PRODUCTS.NAME, archivedIDs);
  return activeProducts;
};

const updateRecordsToLocalDB = async (
  clientCode,
  SO_NAME,
  options,
  stopHandler = { current: false },
) => {
  const {
    syncStartCallback = () => null,
    syncUpdateCallback = () => null,
    setCache = () => null,
  } = options;

  const SELECTED_SO = getSObyName(SO_NAME);
  if (!SELECTED_SO) return 0;
  const db = await getDB(clientCode);
  const warehouseID = await db.get(SO.SHARED.NAME, REDUX_WAREHOUSEID);
  const { assortmentID } =
    (await db.get(SO.SHARED.NAME, REDUX_ALL_WAREHOUSES))?.find(
      wh => wh.warehouseID === String(warehouseID),
    ) ?? {};
  const TABLE_META = getTableMeta(SO_NAME, warehouseID);
  const changedSince = await db.get(SO.META.NAME, TABLE_META.LAST_SYNC_SERVER);

  const request = await applyCorrectParamsForSO(clientCode, SO_NAME, {
    changedSince,
  });
  const isFaultyAssortmentProductRequest =
    request.request === 'getAssortmentProducts' && !request?.['assortmentID'];
  let {
    records: initialRecords,
    // eslint-disable-next-line prefer-const
    status: { recordsTotal, requestUnixTime, responseStatus },
  } = isFaultyAssortmentProductRequest
    ? {
        records: [],
        status: {
          recordsTotal: 0,
          requestUnixTime: Date.now(),
          responseStatus: 'ok',
        },
      }
    : (await ErplyRequest(request)) ?? {};

  if (responseStatus === 'error') return 0;

  const currentSyncPage = await db.get(
    SO.META.NAME,
    TABLE_META.CURRENT_SYNC_PAGE,
  );

  if (stopHandler.current) throw new Error('Stopped');
  if (!currentSyncPage) {
    await db.put(SO.META.NAME, requestUnixTime, TABLE_META.CURRENT_SYNC_SERVER);
    await db.put(SO.META.NAME, 1, TABLE_META.CURRENT_SYNC_PAGE);
  }

  const recordsOnPage =
    (SELECTED_SO.EXTRA_PARAMS as { recordsOnPage: number | null })
      .recordsOnPage ?? 100;
  const recordsPerBulk = recordsOnPage * BULK_SIZE;
  const s = 12;
  const p = 50;
  const expectedTime = (Math.min(recordsOnPage, recordsTotal) * s) / p;
  if (stopHandler.current) throw new Error('Stopped');
  syncStartCallback(recordsTotal, recordsPerBulk, expectedTime);

  const totalRequests = Math.ceil(recordsTotal / recordsPerBulk);
  const firstPage = await db.get(SO.META.NAME, TABLE_META.CURRENT_SYNC_PAGE);
  const current = recordsPerBulk * (firstPage - 1);
  const size = Math.min(recordsTotal - current, recordsPerBulk);

  if (stopHandler.current) throw new Error('Stopped');
  await syncUpdateCallback(recordsTotal, current, size, expectedTime);

  for (
    let pageNumber = firstPage;
    pageNumber < totalRequests + 1;
    pageNumber++
  ) {
    if (recordsTotal === initialRecords.length) {
      if (SO_NAME === SO.PRODUCTS.NAME)
        initialRecords = await filterOutAndRemoveArchivedProducts(
          clientCode,
          initialRecords,
        );
      if (stopHandler.current) throw new Error('Stopped');
      await saveItemsToSO(clientCode, SO_NAME, initialRecords, {
        warehouseID,
        assortmentID,
      });
      if (stopHandler.current) throw new Error('Stopped');
      await db.put(SO.META.NAME, firstPage, TABLE_META.CURRENT_SYNC_PAGE);
      break;
    }

    let records = await Promise.all(
      Array(Math.min(BULK_SIZE, Math.ceil(size / BULK_SIZE)))
        .fill(pageNumber * BULK_SIZE)
        .map((nr, i) => nr + i)
        .map((pageNo, i) =>
          sleep(0.5 * i).then(async () =>
            ErplyRequest(
              await applyCorrectParamsForSO(clientCode, SO_NAME, {
                recordsOnPage,
                changedSince,
                pageNo,
              }),
            ),
          ),
        ),
    ).then(data =>
      data?.flatMap(res => res?.records).reduce((a, b) => a.concat(b), []),
    );
    if (stopHandler.current) throw new Error('Stopped');
    await saveItemsToSO(clientCode, SO_NAME, records, {
      warehouseID,
      assortmentID,
    });

    if (SO_NAME === SO.PRODUCTS.NAME)
      records = await filterOutAndRemoveArchivedProducts(
        clientCode,
        initialRecords,
      );

    if (stopHandler.current) throw new Error('Stopped');
    await saveItemsToSO(clientCode, SO_NAME, records, {
      warehouseID,
      assortmentID,
    });

    if (stopHandler.current) throw new Error('Stopped');
    await db.put(SO.META.NAME, pageNumber, TABLE_META.CURRENT_SYNC_PAGE);

    const recordsFetched = pageNumber * recordsPerBulk + records.length;
    const recordsRemaining = recordsTotal - recordsFetched;
    const nextOperationSize = Math.min(recordsRemaining, recordsPerBulk);
    if (stopHandler.current) throw new Error('Stopped');
    await syncUpdateCallback(recordsTotal, recordsFetched, 0);
    await sleep(TIMING / 1000);

    await syncUpdateCallback(
      recordsTotal,
      recordsFetched,
      nextOperationSize,
      (nextOperationSize * s) / p,
    );
  }

  // we do not want to store all products that exist in redux
  if (SO_NAME !== SO.PRODUCTS.NAME) {
    const itemsToAddToReduxCache = await getItemsFromSO(clientCode, SO_NAME);

    await setCache(
      Object.fromEntries(
        itemsToAddToReduxCache.map(r => [r[SELECTED_SO.ID_PROP], r]),
      ),
    );
  }

  const currentSyncServer = await db.get(
    SO.META.NAME,
    TABLE_META.CURRENT_SYNC_SERVER,
  );

  if (stopHandler.current) throw new Error('Stopped');
  await db.put(SO.META.NAME, currentSyncServer, TABLE_META.LAST_SYNC_SERVER);
  if (stopHandler.current) throw new Error('Stopped');
  await db.delete(SO.META.NAME, TABLE_META.CURRENT_SYNC_PAGE);
  if (stopHandler.current) throw new Error('Stopped');
  await db.delete(SO.META.NAME, TABLE_META.CURRENT_SYNC_SERVER);
  if (stopHandler.current) throw new Error('Stopped');
  await db.put(SO.META.NAME, true, TABLE_META.READY);
  return db.count(SO_NAME);
};

export default function(...args: Parameters<typeof updateRecordsToLocalDB>) {
  const [clientCode, SO_NAME] = args;
  return (navigator as any).locks.request(
    `updateRecordsToLocalDB-${clientCode}-${SO_NAME}`,
    () => updateRecordsToLocalDB(...args),
  );
};
