import { batch } from 'react-redux';
import i18next from 'i18next';

import { checkForLimitAmountPerSale } from 'actions/Sales/checkForLimitAmountPerSale';
import * as shoppingCartActionTypes from 'constants/ShoppingCart';
import { modalPages as modals } from 'constants/modalPage';
import {
  getSetting,
  getAllowEditingExistingLayaways,
  getSerialNumberTitle,
  getSerialNumberFormatterFn,
  getCustomPromptsForProduct,
  getShoppingCartRowMergingCriteria,
  getDisableProductAddedNotification,
} from 'reducers/configs/settings';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import {
  getLastProductIndex,
  getProductsInShoppingCart,
  getTotalCountInCart,
  checkAvailability,
  checkStockFarFromMinimum,
} from 'reducers/ShoppingCart';
import { getIsDefaultCustomer } from 'reducers/customerSearch';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getCurrentSalesDocument } from 'reducers/sales';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { getWeightedProductUnits } from 'reducers/productUnitsDB';
import { getHasPayButtonBeenClicked } from 'reducers/Payments';
import { getProductsUniversal } from 'actions/productsDB';
import { addError, addWarning, dismissType, addSuccess } from 'actions/Error';
import { getMessageIfShoppingcartShouldBeBlocked } from 'reducers/modalPage';
import { ErplyAttributes } from 'utils/ErplyAttributes';
import { createConfirmation } from 'actions/Confirmation';
import { setSelectedOrder } from 'actions/ShoppingCart/setSelectedOrder';

import { calculate } from './calculate';

/**
 * Is meant to be used with debouncedCalculate. Subsequent calls to wrapped action will
 * debounce the calculation.
 *
 * Mark the wrapped action as being one that potentially modifies the cart's calculations.
 * When an action wrapped with this is triggered, all pending cart calculations are paused until the action finishes.
 * (For calculations to be paused wrapped action should use debouncedCalculate)
 * This prevents useless calculations from being run on the cart if we know that some data is about to change anyway.
 * Also useful if action is expected to be called multiple times in quick succession and multiple cart calculations
 * running in parallel with multiple actions might impact performance.
 *
 * If the action finishes without triggering calculate (f.ex. it fails some precondition), then calculate will be retriggered automatically
 *
 * @example
 * const doActionBase = withDeferCalculations(
 *   ({ foo }) => dispatch => {
 *     if (foo > 5) return; // Wrapper should trigger calculation because we did not
 *     dispatch(updateShoppingCart(...args));
 *     dispatch(debouncedCalculate()); // Wrapper should not trigger a second duplicate calculation
 *   }
 * );
 * const doAction = withDeferCalculations(doActionBase);
 *
 * // Usage
 * dispatch(doAction({ foo: 1 }))
 *
 * TODO
 * Can potentially be improved. Current implementation will fail to block redundant request
 * if the wrapped action has multiple calculate calls and they are spaced out
 * @see https://gl.nimi24.com/pos-refacto/pos-refactoring/-/merge_requests/2814#note_66463
 */
const calculations: {
  index: number;
  timer: number | null;
} = {
  index: 0,
  timer: null,
};
function debouncedCalculate(timeout = 200) {
  return async dispatch => {
    if (calculations.timer) window.clearTimeout(calculations.timer);
    calculations.index += 1;
    if (calculations.index < 2) {
      dispatch(calculate());
      return;
    }
    calculations.timer = window.setTimeout(() => {
      dispatch(calculate());
      calculations.index = 0;
    }, timeout);
  };
}

function withDeferCalculations(action) {
  return (...args) => async dispatch => {
    if (!calculations.timer) {
      dispatch(action(...args));
      return;
    }

    const before = calculations.index;
    clearTimeout(calculations.timer);
    await dispatch(action(...args));
    const after = calculations.index;

    // If the action did not end up triggering calculate, do so now
    if (before === after) {
      dispatch(debouncedCalculate());
    }
  };
}

export function addProductFailure(error) {
  return {
    type: shoppingCartActionTypes.ADD_PRODUCT_FAILURE,
    payload: error,
  };
}

export function addProductSuccess(product, options = {}) {
  return {
    type: shoppingCartActionTypes.ADD_PRODUCT_SUCCESS,
    payload: {
      product,
      options,
    },
  };
}

/**
 * Debounce and batch products to be added to cart. Is meant to reduce rerenders.
 */

let timer;
let productQueue: any[] = [];
function debouncedAddProductsSuccess(params, timeout = 200) {
  return async (dispatch, getState) => {
    productQueue.push(params);
    let resolve;
    const promise = new Promise(res => {
      resolve = res;
    });
    if (timer) clearTimeout(timer);
    timer = setTimeout(async () => {
      batch(() => {
        dispatch(
          productQueue.map(({ product, options }) =>
            addProductSuccess(product, options),
          ),
        ).then(() =>
          dispatch(setSelectedOrder(getLastProductIndex(getState()))),
        );
      });
      if (!getDisableProductAddedNotification(getState())) {
        dispatch(
          addSuccess(
            i18next.t('alerts:productAddedToDocument', {
              count: productQueue.length,
            }),
            {
              selfDismiss: 1500,
              dismissible: false,
              errorType: 'productAddedToCart',
            },
          ),
        );
      }
      productQueue = [];
      resolve();
    }, timeout);
    return promise;
  };
}

// TODO Especify the values and business logic from the params (documentation)
export function addProductBase(
  {
    productID,
    amount = undefined,
    price = undefined,
    priceSet = false,
    giftCardSerial = '',
    manualDiscount = 0,
    needsWeightPopup = true,
    addContainerOverride = false,
    parentRowID = 0,
    notes = undefined,
    vatrateID = undefined,
    addedByScanner = false,
    allowNegativePrice = true,
  }: {
    productID: number;
    amount?: number;
    price?: number;
    priceSet: boolean;
    giftCardSerial: string;
    manualDiscount: number;
    needsWeightPopup: boolean;
    addContainerOverride: boolean;
    parentRowID: number;
    notes?: string;
    vatrateID?: number;
    addedByScanner?: boolean;
    allowNegativePrice?: boolean;
  },
  {
    nextView,
    // TODO: USE THESE PROPS AS INIT VALUES PERHAPS?
    shouldMerge,
    openPOE,
  }: {
    nextView?: {
      component: string;
      groupID: string;
    };
    shouldMerge?: boolean;
    openPOE?: boolean;
  } = {},
) {
  // prettier-ignore
  const params = {
      productID,
      amount,
      price,
      priceSet,
      giftCardSerial,
      manualDiscount,
      needsWeightPopup,
      addContainerOverride,
      parentRowID,
      notes,
      vatrateID,
      allowNegativePrice,
    };

  return async (dispatch, getState) => {
    const alertIfBlocked = () => {
      const err = getMessageIfShoppingcartShouldBeBlocked(getState());
      if (!err) return false;
      const [key, data] = err;
      dispatch(
        addError(
          i18next.t(`shoppingCart:alerts.blocked.${key}`, {
            context: data,
          }),
          {
            dismissible: true,
            selfDismiss: false,
            errorType: 'productAddedToCart',
          },
        ),
      );
      return true;
    };
    if (alertIfBlocked()) return;

    const state = getState();

    // do not allow user to add more products after clicking pay
    if (getHasPayButtonBeenClicked(state)) return;

    try {
      let {
        products: [selectedProduct],
      } = await dispatch(
        getProductsUniversal(
          { productID, getLocalStockInfo: 1 },
          { addToCachedItems: true, withMeta: true, localFirst: true },
        ),
      );
      if (alertIfBlocked()) return;

      if (selectedProduct.productComponents?.length) {
        await dispatch(
          getProductsUniversal(
            {
              productIDs: selectedProduct.productComponents.map(
                pc => pc.componentID,
              ),
            },
            { addToCachedItems: true, withMeta: true, localFirst: true },
          ),
        );
        if (alertIfBlocked()) return;
      }

      const { before, on, after } = getPluginLifecycleHook('onAddProduct')(
        getState(),
      );
      try {
        await dispatch(before(params));
      } catch (e) {
        return;
      }
      if (alertIfBlocked()) return;
      const weightedUnits = getWeightedProductUnits(getState());

      const shouldAskForWeight = weightedUnits.includes(
        selectedProduct.unitName,
      );

      const employeeID = getLoggedInEmployeeID(getState());
      let product: {
        productID: number;
        amount: number;
        manualDiscount: number;
        employeeID: any;
        price?: number;
        parentRowID: number;
        notes?: string;
        vatrateID?: number;
        addedByScanner: boolean;
        serialNumber?: string;
        name?: string;
        attributes?: {
          [key: string]: any;
        };
      } = {
        productID,
        amount: shouldAskForWeight && needsWeightPopup ? 0 : amount ?? 1,
        manualDiscount,
        employeeID,
        price,
        parentRowID,
        notes,
        vatrateID,
        addedByScanner,
      };

      const attrs = new ErplyAttributes(selectedProduct.attributes);
      const disallowSellingToDefaultCustomer = attrs.get(
        'disallow_selling_to_default_customer',
      );
      const isDefaultCustomer = getIsDefaultCustomer(state);
      const canEditProductsInLayways = getAllowEditingExistingLayaways(state);
      const currentSalesDocument = getCurrentSalesDocument(state);
      const shoppingCartItems = getProductsInShoppingCart(state);
      const serialNumberTitle = getSerialNumberTitle(state);
      const { type, invoiceState, id } = currentSalesDocument;
      const totalInCart = getTotalCountInCart(productID)(state);
      const isLayaway = type === 'PREPAYMENT' && invoiceState === 'READY';
      const isInvoice = type === 'INVOICE' && invoiceState === 'READY';
      const isPickedUpOrder =
        type === 'ORDER' && invoiceState === 'READY' && id;

      if (!canEditProductsInLayways && isLayaway) {
        dispatch(addWarning(i18next.t('alerts:layawayCartEditLocked')), {
          errorType: 'productAddedToCart',
        });
        return;
      }

      if (isInvoice) {
        dispatch(addWarning(i18next.t('alerts:invoiceCartEditLocked')));
        return;
      }

      if (isPickedUpOrder) {
        dispatch(
          addWarning(
            i18next.t('alerts:orderCartEditLocked', { context: 'addition' }),
          ),
        );
        return;
      }

      if (disallowSellingToDefaultCustomer && isDefaultCustomer) {
        dispatch(addWarning(i18next.t('alerts:noCustomerSelected')), {
          errorType: 'productAddedToCart',
        });
        return;
      }

      // Non refundable products should not be added to shopping cart with negative amount
      if (selectedProduct.nonRefundable && amount && amount < 0) {
        dispatch(
          addWarning(
            i18next.t('alerts:nonRefundableProductError', {
              name: selectedProduct.name,
            }),
          ),
        );
        return;
      }

      // Check for the limit amount per sale
      const shouldAddProduct = await dispatch(
        checkForLimitAmountPerSale(selectedProduct, shoppingCartItems),
      );
      if (alertIfBlocked()) return;

      if (!shouldAddProduct) {
        throw new Error('Product not added to cart due to limit per sale');
      }

      // For serial numbered gift cards, display serial number adding field
      if (selectedProduct.isGiftCard && !selectedProduct.isRegularGiftCard) {
        if (giftCardSerial || giftCardSerial !== '') {
          Object.assign(selectedProduct, { giftCardSerial });
          if (
            getSetting('append_gift_card_number_to_product_name')(getState())
          ) {
            Object.assign(product, {
              itemName: `${selectedProduct.name} ${giftCardSerial
                .split('')
                .map((char, i, arr) => (i > arr.length - 5 ? char : '*'))
                .join('')}`,
            });
          }
        } else {
          dispatch(
            openModalPage({
              component: modals.giftCardSerial,
              groupID: modals.giftCardSerial,
              replace: true,
              props: { productID },
              isPopup: true,
            }),
          );
          return;
        }
      }

      // For matrix master products, do not add but display menu instead
      if (selectedProduct.variationList?.length) {
        dispatch(
          openModalPage({
            component: modals.matrixVariations,
            groupID: modals.matrixVariations,
            replace: true,
            props: { selectedProduct },
          }),
        );
        return;
      }

      // For cashier must enter price products, display price input
      if (selectedProduct.cashierMustEnterPrice && !priceSet) {
        dispatch(
          openModalPage({
            component: modals.cashierPrice,
            groupID: modals.cashierPrice,
            replace: true,
            props: {
              productID,
              giftCardSerial,
              allowNegativePrice,
              product: selectedProduct,
            },
            isPopup: true,
          }),
        );
        return;
      }

      let serialNumberData = '';
      if (selectedProduct.hasSerialNumbers) {
        serialNumberData = await dispatch(
          openModalPage({
            isPopup: true,
            component: modals.serialNumberPrompt,
            props: {
              productName: selectedProduct.name,
            },
          }),
        );
        if (alertIfBlocked()) return;
        if (serialNumberData.trim().length) {
          product.serialNumber = serialNumberData;
          serialNumberData = `${serialNumberTitle}: ${serialNumberData}`;
        }
      }

      const serialNumberFormatter = getSerialNumberFormatterFn(getState());

      // For products with custom serial(s), display serials input
      const customPrompts = getCustomPromptsForProduct(productID)(getState());
      if (customPrompts.length) {
        const customData = await dispatch(
          openModalPage({
            isPopup: true,
            component: modals.customPrompts,
            props: {
              productID,
              prompts: customPrompts,
              productName: selectedProduct.name,
            },
          }),
        );
        if (alertIfBlocked()) return;

        product.name = `${selectedProduct.name} ${serialNumberFormatter(
          `${
            serialNumberData.length ? serialNumberData.concat(', ') : ''
          }${Object.entries(customData)
            .map(([k, v]) => `${k}: ${v}`)
            .join(', ')}`,
        )} `;
      }
      if (!customPrompts.length && serialNumberData.length) {
        product.name = `${selectedProduct.name} ${serialNumberFormatter(
          serialNumberData,
        )}`;
      }

      const warningType = getSetting('touchpos_out_of_stock_warning')(
        getState(),
      );

      const getPassedStockCheck = async () => {
        const translate: typeof i18next.t = (subKey, ...rest) =>
          i18next.t(`shoppingCart:alerts.productOutOfStock.${subKey}`, ...rest);

        const transData = {
          /** Currect actual stock (including products already in the cart but not sold yet) */
          stock: selectedProduct.free,
          /** The amount of this product that would be in the shopping cart if we continue */
          inCartAfter: totalInCart + (amount || 1),
          /** The name of the product which stock is checked */
          productName: selectedProduct.name,
        };
        if (!checkAvailability(productID, amount)(getState())) {
          // if out of stock - display warning
          switch (warningType) {
            case 'block':
              dispatch(addError(translate('block', transData)), {
                errorType: 'productAddedToCart',
              });
              return false;
            case 'confirmation':
              await new Promise((resolve, reject) =>
                dispatch(
                  createConfirmation(resolve, reject, {
                    title: translate('confirmation.title', transData),
                    body: translate('confirmation.body', transData),
                  }),
                ),
              );
              if (alertIfBlocked()) return false;
              return true;
            case 'warning':
              dispatch(dismissType('productAddedToCart'));
              dispatch(
                addWarning(translate('warning', transData), {
                  selfDismiss: 3000,
                  errorType: 'productAddedToCart',
                }),
              );
              return true;
            default:
              // off
              return true;
          }
        }
        return true;
      };

      // Return early if configured to block out of stock product or if confirmation was closed
      if (['block', 'confirmation'].includes(warningType)) {
        const stockOk = await getPassedStockCheck();
        if (!stockOk) {
          return;
        }
      }

      const farFromMinimum = checkStockFarFromMinimum(
        productID,
        amount,
      )(getState());
      if (!farFromMinimum) {
        dispatch(
          createConfirmation(
            () => {
              // do nothing
            },
            null,
            {
              title: i18next.t(
                `shoppingCart: alerts.productCloseToOutOfStock.title`,
              ),
              body: i18next.t(
                `shoppingCart: alerts.productCloseToOutOfStock.body`,
              ),
            },
          ),
        );
      }
      // if product needs custom price, display price input
      const shouldDisplayPriceInput = !getSetting(
        'touchpos_disable_zero_price_auto_open',
      )(getState());

      let options = {} as {
        shouldMerge: boolean;
        openPOE: boolean;
      };

      // Add the product to the cart
      Object.assign(options, {
        shouldMerge:
          !getSetting('touchpos_always_item_in_new_row')(getState()) &&
          !selectedProduct.cashierMustEnterPrice &&
          !giftCardSerial &&
          amount === undefined &&
          price === undefined,
        openPOE:
          shouldDisplayPriceInput &&
          selectedProduct.price === 0 &&
          price === undefined &&
          !selectedProduct.cashierMustEnterPrice,
      });

      const mergeCriteria = getShoppingCartRowMergingCriteria(getState());

      if (giftCardSerial || giftCardSerial !== '') {
        Object.assign(product, { giftCardSerial });
      }
      if (selectedProduct.isRegularGiftCard) {
        const { isRegularGiftCard } = selectedProduct;
        Object.assign(product, { isRegularGiftCard });
      }
      if (selectedProduct.attributes) {
        Object.assign(product, { attributes: product.attributes });
      }

      try {
        // PLUGIN - onProductAdd
        ({ product, options, selectedProduct } = await dispatch(
          on(params, { product, options, selectedProduct }),
        ).catch(() => Symbol.for('pluginerror')));
        if (alertIfBlocked()) return;

        await dispatch(
          debouncedAddProductsSuccess(
            {
              product,
              options: {
                merge: options.shouldMerge,
                mergeCriteria,
              },
            },
            0,
          ),
        );

        // By checking it specifically for warning only here, we ensure the "Product out of stock" message is displayed after the "product added to cart" message
        if (warningType === 'warning') {
          await getPassedStockCheck();
        }

        dispatch(debouncedCalculate());
      } catch (e) {
        console.error('Failed to merge product with previous row', e);
        dispatch(addProductFailure(e));
        return;
      }

      // Open the next view if one is specified
      if (nextView) {
        dispatch(openModalPage(nextView));
      }

      // Display related products popup if any exist
      const shouldDisplayRelatedProducts = !getSetting(
        'touchpos_disable_related_products_popup',
      )(getState());

      if (
        shouldDisplayRelatedProducts &&
        (selectedProduct.relatedProducts?.length ||
          selectedProduct.replacementProducts?.length)
      ) {
        dispatch(
          openModalPage({
            component: modals.relatedProducts,
            props: {
              productID,
              parentRowID: getLastProductIndex(getState()),
            },
            modalClassName: 'related-products-modal',
            groupID: 'RELATED_PRODUCTS',
          }),
        );
      }

      if (options.openPOE) {
        batch(async () => {
          await dispatch(
            openModalPage({
              component: modals.ProductOrderEdit,
              props: { isMatrixProduct: true },
              groupID: 'PRODUCT_VIEW',
              modalClassName: 'product-edit-modal',
            }),
          );
        });
      }
      const packages = selectedProduct.productPackages?.filter(
        p => p.packageAmount,
      );
      // Prompt user with amount selection if product has packages
      if (packages.length && selectedProduct.soldInPackages) {
        const packageAmounts = packages.map(pkg => pkg.packageAmount);
        dispatch(
          openModalPage({
            isPopup: true,
            component: modals.PackageAmountPopup,
            props: {
              amounts: packageAmounts,
              orderIndex: getLastProductIndex(getState()),
            },
          }),
        );
      }
      // Asks for product weight input
      if (shouldAskForWeight && needsWeightPopup) {
        dispatch(
          openModalPage({
            component: modals.ProductWeight,
            props: {
              orderIndex: getLastProductIndex(getState()),
              unit: selectedProduct.unitName,
            },
            modalClassName: 'product-weight-modal',
            isPopup: true,
          }),
        );
      }

      // Display warning if bundle
      if (selectedProduct.type === 'BUNDLE') {
        dispatch(
          addWarning('Bundle product selected', {
            selfDismiss: 3000,
            errorType: 'productAddedToCart',
          }),
        );
      }

      // PLUGIN - afterAddProduct
      dispatch(after(params, { product, selectedProduct }));
    } catch (e) {
      console.error('Failed to add product to cart', e);
    }
  };
}

export const addProduct = withDeferCalculations(addProductBase);
