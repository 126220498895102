import { DeepPartial } from 'ramda';

import { PosPlugin } from 'plugins/plugin';
import {
  getLastProductIndex,
  getProductsInShoppingCart,
} from 'reducers/ShoppingCart';
import { addError } from 'actions/Error';
import {
  JsonAPISuccessResponse,
  saveRecordsToInvoiceRow,
} from 'services/JsonAPI';
import { notUndefinedOrNull } from 'utils/tsHelpers';

import reduxReducer, {
  getTagsSlice,
  removeAllTags,
  removeTagsByProductId,
  updateOrderReturnTags,
} from './redux';
import {
  EnterTagModal,
  RemoveTagModal,
  UICustomTableBillRow,
} from './components';

const pnpTagOnReturn: PosPlugin = {
  id: 'pnp-tag-returned-products',
  name: 'PnP - Tag returned products',
  info: '# Header',
  // eslint-disable-next-line global-require
  infoMDUrl: require('./Info.md'),
  keywords: ['return', 'pnp', 'tag'],
  reduxReducer,
  getStatus: () => ({ type: 'valid', message: '' }),
  onAddProduct: {
    after: (params, { product }) => async (dispatch, getState) => {
      const orderIndex = getLastProductIndex(getState());

      await dispatch(updateOrderReturnTags({ ...product, orderIndex }));
    },
  },
  onAddReturnProducts: {
    after: (p, ap) => async dispatch => {
      ap.orders.reduce(
        (pr, order) => pr.then(() => dispatch(updateOrderReturnTags(order))),
        Promise.resolve(),
      );
    },
  },
  onUpdateOrderAmount: {
    after: (p, ap) => updateOrderReturnTags(ap),
  },
  onRemoveOrder: {
    after: ({ orderIndex }) => async dispatch => {
      dispatch(removeTagsByProductId(orderIndex));
    },
  },
  onResetShoppingCart: {
    after: () => async dispatch => {
      dispatch(removeAllTags());
    },
  },
  onSaveSalesDocumentAttrToJsonApi: {
    after: ({ savedSaleDocument }) => async (dispatch, getState) => {
      try {
        const tagsSlice = getTagsSlice()(getState());
        const rows = savedSaleDocument.rows.slice(0);
        const shoppingCartProducts = getProductsInShoppingCart(getState());

        const requestPayloads = Object.entries(tagsSlice)
          .map(([key, value]) => {
            const productIndex = shoppingCartProducts.findIndex(
              order => String(order.orderIndex) === String(key),
            );
            const recordID = rows[productIndex]?.stableRowID;
            if (!recordID) return undefined;
            const ret = {
              id: Number(recordID),
              // eslint-disable-next-line @typescript-eslint/camelcase
              json_object: {
                BrazilPOS: {
                  returnTags: value,
                },
              } as DeepPartial<JsonAPISuccessResponse['json_object']>,
            };
            return ret;
          })
          .filter(notUndefinedOrNull);

        await Promise.all(requestPayloads.map(saveRecordsToInvoiceRow)).catch(
          e => {
            dispatch(addError('Failed to store return tag(s)'));
          },
        );
      } catch (e) {
        console.error('Failed to store return tags', e);
      }
    },
  },
  components: {
    EnterTagModal,
    RemoveTagModal,
  },
  UICustomTableBillRow,
};

export default pnpTagOnReturn;
