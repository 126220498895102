import { round } from 'utils';
import { addWarning } from 'actions/Error';
import { getErrorMessage } from 'paymentIntegrations';
import { version } from 'external_data';

import swedbankRequest from './index';

const swedbankRefund = (
  cardPayment,
  // eslint-disable-next-line no-empty-pattern
  {
    /* swedbankIP, swedbankPort */
  },
  { updateMessage, rejectPayments },
  numberOfPayments,
  index,
  clientCode,
) => async dispatch => {
  updateMessage(
    `Refunding payment for ${cardPayment.amount}...
    Transaction ${index + 1} / ${numberOfPayments}`,
  );

  try {
    // Swedbank doesn't support unreferenced returns, thus the error
    if (!cardPayment.original) {
      rejectPayments();
      const message =
        'Unreferenced returns with Swedbank are not supported. Click on original card payment to refund to card';
      dispatch(addWarning(message));
      throw new Error(message);
    }

    const { referenceNumber } = cardPayment.original;
    const twoDots = referenceNumber.indexOf(':');
    const point = referenceNumber.indexOf('.');
    const comma = referenceNumber.indexOf(',');
    const entryMode = referenceNumber.slice(0, twoDots);
    const refNo = referenceNumber.slice(point + 1, comma);
    const originalDate = referenceNumber
      .slice(comma + 1, referenceNumber.length)
      .replace('&', ' ');
    const origAmount = round(parseFloat(cardPayment.original.sum), 2);
    const amount = round(Math.abs(cardPayment.amount), 2);

    const data = {
      requestID: cardPayment.uuid,
      transactionType: 'REFUND',
      origEntryMode: entryMode,
      amount: amount.toString(),
      origAmount: origAmount.toString(),
      currency: cardPayment.original.currencyCode,
      origDateTime: originalDate,
      referenceNumber: refNo,
      softwareName: 'Erply Brazil POS',
      softwareVersion: version,
      clientCode
    }
    const returnTransaction = await swedbankRequest.post('payment', data);

    return returnTransaction;
  } catch (error) {
    updateMessage(
      getErrorMessage(
        error,
        'There was an error while performing the refund. Please try again',
      ),
    );
  }
  return true;
};

export default swedbankRefund;
