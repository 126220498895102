import { batch } from 'react-redux';
import i18next from 'i18next';
import * as R from 'ramda';
import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import uuid from 'uuid/v1';

import * as saleApi from 'services/ErplyAPI/sales';
import { Progress } from 'actions/actionUtils';
import { getHasUndismissedErrorsOfType } from 'reducers/Error';
import { modalPages as mp } from 'constants/modalPage';
import * as c from 'constants/sales';
import {
  getCurrentSalesDocument,
  getIsAReturn,
  getIsPickupInProgress,
  getIsCurrentSaleAReturn,
  getIsSavedSale,
} from 'reducers/sales';
import {
  lastInvoiceNumbers,
  lastReceiptData,
  pendingRequests,
} from 'services/localDB';
import {
  getCurrencyCode,
  getReceiptTemplateIdByDocType,
  getSetting,
  getUseAgeVerification,
  getUseReceiptTemplates,
  getCurrencyFormatterNoSymbol,
} from 'reducers/configs/settings';
import { getDefaultEmailInvoiceParams } from 'reducers/configs/emailInvoice';
import {
  getIsDefaultCustomer,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getSelectedPos } from 'reducers/PointsOfSale';
import {
  ErplyAttributes,
  timestampInSeconds,
  getCausalStack,
  add,
  round,
  isOfflineError,
} from 'utils';
import { notUndefinedOrNull } from 'utils/tsHelpers';
import { getLoggedInEmployeeID, getHasRightToReturn } from 'reducers/Login';
import { getManualPromotions, getPromotionByID } from 'reducers/CampaignsDB';
import {
  getAllPromotions,
  getAllPromotionsRewardPointCost,
  getCartHasSerializedGiftCardProduct,
  getHasBarItems,
  getHasKitchenItems,
  getHasProductsInShoppingCart,
  getIsProductNonDiscountable,
  getNumberOfProductsInCart,
  getProductsInShoppingCart,
  getPromoRulesProperties,
  getRowVatRateID,
  getShoppingCartForSalesDocument,
  getShoppingCartNeedsCalculation,
  getShoppingCartRows,
  getTotal,
  getTotalNet,
} from 'reducers/ShoppingCart';
import { deselectCustomer } from 'actions/customerSearch';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import {
  addMultiProducts,
  applyPromotion,
  clearDiscountReasons,
  clearReturnReasons,
  resetShoppingCart,
  updateProductOrder,
} from 'actions/ShoppingCart';
import {
  addError,
  addSuccess,
  addWarning,
  dismiss,
  dismissAll,
  dismissType,
} from 'actions/Error';
import {
  openPaymentModal,
  checkIfBlockedExchange,
  waitForCartCalculationToFinish,
} from 'actions/modalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { createConfirmation } from 'actions/Confirmation';
import { updateCurrentPointOfSale } from 'actions/PointsOfSale';
import { doClientRequest } from 'services/ErplyAPI/core/ErplyAPI';
import * as jsonApi from 'services/JsonAPI';
import {
  printBarReceipt,
  printKitchenReceipt,
} from 'actions/integrations/printer';
import { getProductByID } from 'reducers/cachedItems/products';
import {
  getIgnoreCurrentOngoingSale,
  getIsUsingManualStoreCredit,
  getPayments,
  getRounding,
  getSalesDocument,
  getTotal as getPaymentsTotal,
} from 'reducers/Payments';
import { getSelectedPosVatRate, getVatRateByID } from 'reducers/vatRatesDB';
import {
  getUsedRewardPointRecords,
  getGiftCards,
  getProducts,
  getCustomers,
  batchSaveRequests,
} from 'services/ErplyAPI';
import { sendSalesDocumentByEmail } from 'services/SalesAPI';
import { spreadCart } from 'utils/apiTransformations/saveSalesDocumentCart';

import { getPluginLifecycleHook } from '../reducers/Plugins';
import { SO } from '../services/DB/types';

import { resetProductReturn } from './returnProducts';
import { withProgressAlert } from './actionUtils';
import { getProductsUniversal, updateStockLevels } from './productsDB';
import { syncLocalDatabaseSO } from './connectivity';
import { checkRightToMakeSale, setUserSalesData } from './Login';
import { resetPayments } from './Payments/resetPayments';
import { saveApplicableCoupons } from './CampaignsDB';

export function getSalesDocs() {
  return {
    type: c.GET_SALES_DOCUMENTS,
  };
}

export function getSalesDocumentsSuccess(payload) {
  return {
    type: c.GET_SALES_DOCUMENTS_SUCCESS,
    payload,
  };
}

export function getSalesDocumentsFullResponseSuccess(payload) {
  return {
    type: c.GET_SALES_DOCUMENTS_FULL_RESPONSE_SUCCESS,
    payload,
  };
}

export function getSalesDocumentsError(payload) {
  return {
    type: c.GET_SALES_DOCUMENTS_ERROR,
    payload,
  };
}

export function getSalesDocuments(params) {
  return async dispatch => {
    dispatch(getSalesDocs());
    try {
      const payload = await saleApi.getSalesDocuments(params);
      dispatch(getSalesDocumentsSuccess(payload));
    } catch (err) {
      console.error('Failed to load sales document', params, err);
      dispatch(getSalesDocumentsError(err));
    }
  };
}

export function getSalesDocumentsCompleteResponse(params) {
  return async dispatch => {
    dispatch(getSalesDocs());
    try {
      const payload = await saleApi.getSalesDocumentsCompleteResponse(params);
      dispatch(getSalesDocumentsFullResponseSuccess(payload));
    } catch (err) {
      if (err.toString() === 'TypeError: Failed to fetch') {
        dispatch(getSalesDocumentsFullResponseSuccess([]));
        return;
      }
      console.error('Failed to load sales document', params, err);
      dispatch(getSalesDocumentsError(err));
    }
  };
}

export function setCurrentEmployee(employee) {
  return {
    type: c.SET_SALE_EMPLOYEE,
    payload: employee,
  };
}

export function setCurrentSalesDocument(param) {
  return {
    type: c.SET_CURRENT_SALE_DOC,
    payload: param,
  };
}

export function updateCurrentSaleDocument(payload) {
  return {
    type: c.UPDATE_CURRENT_SALE_DOC,
    payload,
  };
}

/**
 * @param {Object} params
 * @param {string} params.taxExemptCertificateNumber
 * @param {boolean} [params.isReseting]
 * @param {boolean} [params.isPartialExempt]
 */
export function updateCurrSaleDocTaxExNumber({
  taxExemptCertificateNumber,
  isReseting = false,
  isPartialExempt = false,
}) {
  return async (dispatch, getState) => {
    // store vat rate before updating taxExemptCertificateNumber because after that the new vatrateID will override the existing one
    // and if the user removes the taxExemptCertificateNumber calculateShoppingCart will still return tax exempted prices

    const salesDoc = getCurrentSalesDocument(getState());
    if (
      isPartialExempt === salesDoc.isPartialExempt &&
      taxExemptCertificateNumber === (salesDoc.taxExemptCertificateNumber ?? '')
    )
      return;

    const rows = getShoppingCartRows(getState());
    await Promise.all(
      rows.map(({ user: { orderIndex, vatrateID, amount } }) =>
        dispatch(
          updateProductOrder({
            orderIndex,
            amount,
            vatrateID: isReseting ? undefined : vatrateID,
          }),
        ),
      ),
    );
    dispatch(
      updateCurrentSaleDocument({
        taxExemptCertificateNumber,
        isPartialExempt: !isReseting ? isPartialExempt : false,
      }),
    );
  };
}

export function resetCurrentSalesDocument() {
  return async dispatch => {
    dispatch({ type: c.RESET_CURRENT_SALES_DOC });
    dispatch({ type: c.RESET_CURRENT_SALE_AS_RETURN });
    dispatch({ type: c.RESET_CURRENT_SALES_DOC_PAYMENTS });
  };
}

export function setIsCurrentSaleAReturn(payload) {
  return {
    type: c.SET_CURRENT_SALE_AS_RETURN,
    payload,
  };
}

export function resetIsCurrentSaleAReturn() {
  return {
    type: c.RESET_CURRENT_SALE_AS_RETURN,
  };
}

export function setIsCurrentSaleGiftReturn(payload) {
  return {
    type: c.SET_CURRENT_SALE_AS_GIFT_RETURN,
    payload,
  };
}

export function resetCurrentSalesDocPayments() {
  return {
    type: c.RESET_CURRENT_SALES_DOC_PAYMENTS,
  };
}

export function setLastSalesDoc(payload) {
  return {
    type: c.SET_LAST_SALES_DOC,
    payload,
  };
}
export function resetLastSaleDoc() {
  return async dispatch => {
    dispatch({
      type: c.RESET_LAST_SALES_DOC,
    });
    lastReceiptData.reset();
  };
}

export function setPickupInProgress(payload) {
  return {
    type: c.SET_PICKUP_IN_PROGRESS,
    payload,
  };
}

// new sales actions

export function getSales(params) {
  return async dispatch => {
    dispatch({ type: c.GET_SALES_DOCUMENTS });
    try {
      const {
        records,
        status: { recordsTotal, recordsInResponse },
      } = await saleApi.getSalesDocumentsCompleteResponse(params);

      const byCustomerID = {};
      const all = {};
      records.forEach(r => {
        all[r.id] = r;
        byCustomerID[r.customerID] = byCustomerID[r.customerID]
          ? [...new Set([...byCustomerID[r.customerID], r.id])]
          : Array.of(r.id);
      });

      const payload = {
        recordsTotal,
        recordsInResponse,
        all,
        byCustomerID,
      };
      dispatch({ type: c.GET_SALES_DOCUMENTS_SUCCESS, payload });
    } catch (err) {
      console.error('Failed to load sales', params, err);
      dispatch({ type: c.GET_SALES_DOCUMENTS_ERROR, payload: err });
    }
  };
}

export function getPrepaymentPercentages() {
  return async dispatch => {
    dispatch({ type: c.GET_PREPAYMENT_PERCENTAGES.START });
    try {
      const payload = await saleApi.getPrepaymentPercentages();
      dispatch({ type: c.GET_PREPAYMENT_PERCENTAGES.SUCCESS, payload });
    } catch (err) {
      console.error('Failed to load percentage options for prepayment', err);
      dispatch(
        addError(i18next.t('alerts:loading.prePaymentPercentages_error'), {
          selfDismiss: true,
        }),
      );
      dispatch({ type: c.GET_PREPAYMENT_PERCENTAGES.SUCCESS, payload: err });
    }
  };
}

export function setCurrentSalesDocPayments({ documentID, paymentIDs = null }) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook(
      'onSetCurrentSalesDocPayments',
    )(getState());
    try {
      await dispatch(before({ documentID, paymentIDs }));
      dispatch({ type: c.SET_CURRENT_SALES_DOC_PAYMENTS.START });
      const originalPayments = saleApi
        .getPayments({ documentID })
        .catch(() => []);
      const returnPayments = paymentIDs
        ? saleApi.getPayments({ paymentIDs: paymentIDs.join(',') })
        : Promise.resolve([]);

      const giftcardSerials = originalPayments
        .then(R.map(R.path(['attributes', 'giftCardID'])))
        .then(R.filter(id => id))
        .then(R.map(giftCardID => getGiftCards({ giftCardID })))
        .then(prs => Promise.all(prs))
        .then(R.unnest); // Responses come in as arrays, this unwraps the individual responses

      const originalWithBalance = Promise.all([
        originalPayments,
        giftcardSerials,
      ]).then(([pmts, gcards]) =>
        pmts.map(pmt => {
          if (pmt.type !== 'GIFTCARD') return pmt;
          if (!pmt.attributes?.giftCardID) return pmt;
          return R.mergeDeepLeft({
            giftCardBalance: gcards.find(
              gc => String(gc.giftCardID) === String(pmt.attributes.giftCardID),
            ).balance,
          })(pmt);
        }),
      );

      const { original, returned } = await dispatch(
        on(
          { documentID, paymentIDs },
          {
            original: await originalWithBalance,
            returned: await returnPayments,
          },
        ),
      );
      // Needs to await until payments are set in redux. Otherwise, if a return is opened quick enough (via automation), the payment list is shown empty
      await dispatch({
        type: c.SET_CURRENT_SALES_DOC_PAYMENTS.SUCCESS,
        payload: { original, returned },
      });
      dispatch(after({ documentID, paymentIDs }, null));
    } catch (err) {
      console.error(
        'Failed to load/set previous payment information for return',
        err,
      );
      dispatch({ type: c.SET_CURRENT_SALES_DOC_PAYMENTS.ERROR, payload: err });
    }
  };
}

/**
 * Stores extra fields for salesDocuments to JSON API (NB! - requires pre existing schema)
 * @param savedSaleDocument - response from saveSalesDocument request
 * @returns {function(...[*]=)}
 */
// TODO: combine parentRowID and notes payloads to save requests to JSON API
// SAVEDPAYMENTS IS A TEMP SOLUTION TO BE REFACTORED LATER, ADDED LIKE THIS DUE TO URGENCY
export function saveSalesDocumentAttrToJsonApi({
  savedSaleDocument,
  shoppingCart,
  savedPayments /* TEMP SOLUTION, REFACTOR LATER */,
  progress,
}) {
  return async (dispatch, getState) => {
    try {
      const {
        before: beforePlugin,
        on: onPlugin,
        after: afterPlugin,
      } = getPluginLifecycleHook('onSaveSalesDocumentAttrToJsonApi')(
        getState(),
      );

      await dispatch(beforePlugin({ savedSaleDocument, progress }));

      try {
        await dispatch(onPlugin({ savedSaleDocument }, null));
      } catch (e) {
        return;
      }

      const shouldStoreParentID = getSetting(
        'touchpos_group_products_in_shopping_cart',
      )(getState());
      const shoppingCartProducts = shoppingCart;
      const { rows } = savedSaleDocument;

      /** @type JsonAPI.JsonAPISuccessResponse[] */
      const recordsToSave = shoppingCartProducts
        .map((order, i) => {
          const id = rows[order.rowNumber - 1]?.stableRowID;
          // const id = rows[i]?.stableRowID;
          if (!id) return undefined;
          /** Index of parent row */
          const j = shoppingCartProducts.findIndex(
            parent => parent.orderIndex === order.parentRowID,
          );
          const parentRowID =
            0 <= j && shouldStoreParentID ? Number(rows[j].stableRowID) : 0;

          /* If these are equal, then we are saving the same document as was the original, and it doesn't count as a return */
          const creditedStableRowID =
            order.stableRowID && id && Number(id) !== Number(order.stableRowID)
              ? Number(order.stableRowID)
              : 0;
          const { notes, serialNumber } = order;

          if (!(notes || parentRowID || creditedStableRowID || serialNumber))
            return undefined;
          /** @type JsonAPI.JsonAPISuccessResponse */
          const ret = {
            id,
            json_object: {
              CreditInvoice: {
                creditedStableRowID,
              },
              BrazilPOS: {
                notes,
                parentRowID,
                serialNumber,
              },
            },
          };
          return ret;
        })
        .filter(notUndefinedOrNull);
      await Promise.all(
        recordsToSave.map(jsonApi.saveRecordsToInvoiceRow),
      ).catch(e => {
        dispatch(addError('Failed to store in JSON API'));
        console.error('Failed to store in JSON API\n', recordsToSave, e);
      });
      // SAVED PAYMENTS ARE USED ONLY HERE, REFACTOR LATER
      await dispatch(afterPlugin({ savedSaleDocument, savedPayments }, null));
    } catch (e) {
      console.error(
        'Error calculating JSON data for document',
        { savedSaleDocument, shoppingCart },
        e,
      );
    }
  };
}

function printKitchenAndBarReceipts(lastSaleDocument) {
  return async (dispatch, getState) => {
    const state = getState();
    const shoppingCartHasBarItem = getHasBarItems(state);
    const shoppingCartHasKitchenItem = getHasKitchenItems(state);
    const mostRecentDoc = (
      await saleApi.getSalesDocuments({ id: lastSaleDocument.invoiceID })
    )[0];
    const savedSalesDoc = getCurrentSalesDocument(state);
    const mostRecentDocRows = mostRecentDoc?.rows || [];
    const savedSalesDocRows = savedSalesDoc?.rows || [];

    await dispatch(
      getProductsUniversal(
        {
          productIDs: [...mostRecentDocRows, ...savedSalesDocRows].map(r =>
            Number(r.productID),
          ),
        },
        { addToCachedItems: true },
      ),
    );
    const hasAnAttribute = (item, attrName) =>
      Number(new ErplyAttributes(item?.attributes).get(attrName)) === 1;
    const kitchenProductsInSavedSales = savedSalesDocRows.filter(item => {
      return hasAnAttribute(
        getProductByID(item.productID)(state),
        'kitchen_item',
      );
    });
    const kitchenProductsInMostRecentDoc = mostRecentDocRows.filter(item => {
      return hasAnAttribute(
        getProductByID(item.productID)(state),
        'kitchen_item',
      );
    });
    const barProductsInSavedSales = savedSalesDocRows.filter(item => {
      return hasAnAttribute(getProductByID(item.productID)(state), 'bar_item');
    });
    const barProductsInMostRecentDoc = mostRecentDocRows.filter(item => {
      return hasAnAttribute(getProductByID(item.productID)(state), 'bar_item');
    });
    /*
    determine if anything has changed in the sale
    kitchen/bar should worry only about:
    amount, name, notes, addition/removal of product, nothing else matters
  */
    const kitchenItemsChanged = () => {
      const shortenedMostRecentKitchenItems = kitchenProductsInMostRecentDoc.map(
        item => {
          const container = {};
          container.stableRowID = item.stableRowID;
          container.name = item.itemName;
          container.amount = item.amount;
          container.notes = item?.jdoc?.BrazilPOS.notes;
          return container;
        },
      );
      const shortenedSavedSalesKitchenItems = kitchenProductsInSavedSales.map(
        item => {
          const container = {};
          container.stableRowID = item.stableRowID;
          container.name = item.itemName;
          container.amount = item.amount;
          container.notes = item?.jdoc?.BrazilPOS.notes;
          return container;
        },
      );
      return (
        JSON.stringify(shortenedMostRecentKitchenItems) !==
        JSON.stringify(shortenedSavedSalesKitchenItems)
      );
    };
    const barItemsChanged = () => {
      const shortenedMostRecentBarItems = barProductsInMostRecentDoc.map(
        item => {
          const container = {};
          container.stableRowID = item.stableRowID;
          container.name = item.itemName;
          container.amount = item.amount;
          container.notes = item?.jdoc?.BrazilPOS.notes;
          return container;
        },
      );
      const shortenedSavedSalesBarItems = barProductsInSavedSales.map(item => {
        const container = {};
        container.stableRowID = item.stableRowID;
        container.name = item.itemName;
        container.amount = item.amount;
        container.notes = item?.jdoc?.BrazilPOS.notes;
        return container;
      });
      return (
        JSON.stringify(shortenedMostRecentBarItems) !==
        JSON.stringify(shortenedSavedSalesBarItems)
      );
    };
    if (shoppingCartHasKitchenItem) {
      if (kitchenItemsChanged()) {
        dispatch(printKitchenReceipt(mostRecentDoc));
      }
    }
    if (shoppingCartHasBarItem) {
      if (barItemsChanged()) {
        dispatch(printBarReceipt(mostRecentDoc));
      }
    }
  };
}
function promptToRetryOrReturnToPaymentModal(props, err) {
  return async (dispatch, getState) => {
    const isSavingPendingSale =
      !props.bonusRequests.length &&
      !props.payments.length &&
      !props.storeCreditPayments.length &&
      !Object.keys(props.salesDocument).length;

    const processedPaymentsCount = [
      ...props.storeCreditPayments,
      ...props.payments,
    ].length;

    const reason = err.cause?.messageForUI ?? err.cause?.cause?.messageForUI;

    await new Promise(resolve =>
      dispatch(
        createConfirmation(
          ({ submit: action }) => {
            if (action === 'retry') {
              dispatch(saveSalesDocument(props));
            } else if (action === 'cancelSale' && !isSavingPendingSale) {
              dispatch(
                openPaymentModal({
                  isReopeningAfterSaleFailedToSave: true,
                  props: { payments: getPayments(getState()) },
                }),
              );
            }
            resolve();
          },
          undefined,
          {
            title: i18next.t('alerts:payments.saving.title'),
            body: i18next.t('alerts:payments.saving.body', {
              reason,
              payments: processedPaymentsCount,
              context: reason ? 'withReason' : '',
            }),
            error: err,
            confirmText: [
              {
                name: i18next.t('alerts:payments.saving.cancelSale'),
                value: 'cancelSale',
              },
              {
                name: i18next.t('alerts:payments.saving.retry'),
                value: 'retry',
              },
            ],
            alertBeforeUnload: !!processedPaymentsCount,
          },
        ),
      ),
    );
  };
}

function saveSalesDocumentBase(
  /** @type {salesDocument?: any, payments?: any[], bonusRequests?: any[], onSuccess?: any} props */
  props = {
    salesDocument: {},
    payments: [],
    bonusRequests: [],
    storeCreditPayments: [],
  },
) {
  return async (dispatch, getState, /** @type {Progress} */ progress) => {
    try {
      await dispatch(checkRightToMakeSale());
      const state = getState();
      // temporaryUUID makes sure that repetitive requests would not cause duplicated documents
      const temporaryUUID = props?.temporaryUUID ?? uuid();
      // Plugins
      const {
        before: beforePlugin,
        on: onPlugin,
        after: afterPlugin,
      } = getPluginLifecycleHook('onSaveSalesDocument')(state);

      await dispatch(beforePlugin({ ...props, progress }));
      const ignoreCurrentSalesDocument = getIgnoreCurrentOngoingSale(state);

      const useAgeVerification = getUseAgeVerification(state);
      const onSuccess = props.onSuccess || (() => {});
      const currentSaleDoc = getCurrentSalesDocument(state);
      const { pointOfSaleID, warehouseID } = getSelectedPos(getState());

      const isPreviouslyCreatedInvoice =
        (props?.salesDocument?.type === 'INVOICE' ||
          props?.salesDocument?.type === 'INVWAYBILL') &&
        props?.salesDocument?.id;
      const salesDocument = isPreviouslyCreatedInvoice
        ? props.salesDocument
        : {
            ...currentSaleDoc,
            ...props.salesDocument,
            notes: props.salesDocument.notes || currentSaleDoc.notes,
          };

      const payments = props.payments || [];
      const bonusRequests = props.bonusRequests || [];
      dispatch({ type: c.SAVE_SALES_DOCUMENT.START });
      // if we are saving pending sale document, show warning that the doc is being saved
      const requests = [];
      const selectedCustomer = getSelectedCustomer(state);
      const { id: customerID } = selectedCustomer;
      const allowOffline = getSetting('touchpos_allow_offline_mode')(state);
      const loggedInEmployeeID = getLoggedInEmployeeID(state);
      const employeeID = currentSaleDoc?.employee?.id ?? loggedInEmployeeID;
      const updateDocumentDateWhenSavingPendingSales = getSetting(
        'update_document_date_when_saving_pending_sales',
      )(state);
      const updateDocumentCreatorWhenSavingPendingSales = getSetting(
        'update_document_creator_when_saving_pending_sales',
      )(state);
      const shoppingCart =
        salesDocument.modifiedRows ?? getProductsInShoppingCart(state);
      const currencyCode = getCurrencyCode(state);
      const allPromotions = getAllPromotions(state);
      // Why is this unused? Accidentally or intentionally removed feature and this is leftover?
      const { taxExemptCertificateNumber: taxExNr } = getCurrentSalesDocument(
        state,
      );
      const rulesProperties =
        salesDocument.type !== 'ORDER' ? getPromoRulesProperties(state) : {};
      const storeCred =
        Number(
          Object.values(getPayments(state)).find(
            pmt => pmt.type === 'STORECREDIT',
          )?.amount ?? 0,
        ) ?? 0;
      // Remove attributes from previous documents if making a return or a baseDocumentID exists (for orders, layawyas, etc).
      const attributes = new ErplyAttributes(
        salesDocument.type === 'CREDITINVOICE' || salesDocument.baseDocumentID
          ? undefined
          : salesDocument.attributes,
      );
      if (attributes.get('source-uuid')) attributes.remove('source-uuid');
      const salesDocumentTemplate = {
        temporaryUUID,
        currencyCode: salesDocument.currencyCode || currencyCode,
        customerID: props?.customerID || salesDocument.customerID || customerID,
        warehouseID,
        pointOfSaleID,
        confirmInvoice: Number(salesDocument.confirmed) || 0, // indicates if the saveDocument is to be completed or added to pending
        amountPaidWithStoreCredit: storeCred,
        type: salesDocument.type || 'CASHINVOICE',
        paymentType: salesDocument.paymentType,
        employeeID: salesDocument.employeeID || employeeID,
        attributes,
        notes: salesDocument.notes,
        internalNotes: salesDocument.internalNotes,
        baseDocumentID: salesDocument.baseDocumentID,
        taxExemptCertificateNumber: salesDocument.taxExemptCertificateNumber,
        requestName: 'saveSalesDocument',
        baseDocumentIDs: salesDocument.baseDocumentIDs || undefined,
        rounding: getRounding(state),
        invoiceState: salesDocument.invoiceState,
        workOrderID: salesDocument.workOrderID,
        ...rulesProperties,
      };

      if (props?.contactID) {
        Object.assign(salesDocumentTemplate, {
          contactID: props.contactID,
        });
      }

      if (selectedCustomer.paymentDays) {
        Object.assign(salesDocumentTemplate, {
          paymentDays: selectedCustomer.paymentDays,
        });
      }
      if (Number(salesDocument.confirmed) && getIsSavedSale(state)) {
        Object.assign(salesDocumentTemplate, {
          invoiceState: 'READY',
        });
      }

      if (storeCred === 0)
        delete salesDocumentTemplate.amountPaidWithStoreCredit;
      // "save sale" creates a CASHINVOICE sale that has the invoiceState set to "PENDING"
      const isPendingSale =
        currentSaleDoc.type === 'CASHINVOICE' &&
        salesDocument.invoiceState === 'PENDING' &&
        salesDocument.id;
      // handle Return Documents
      if (salesDocument.type === 'CREDITINVOICE') {
        if (isPendingSale) {
          Object.assign(salesDocumentTemplate, {
            id: salesDocument.id,
            isCashInvoice: 1,
            creditInvoiceType: salesDocument.creditInvoiceType,
          });
        } else {
          Object.assign(salesDocumentTemplate, {
            creditToDocumentID: salesDocument.creditToDocumentID,
            // per PBIB-5077: KR's comment: “In fact, it would be necessary for Brazil POS to always include the parameters "isCashInvoice=1" when making a credit invoice. This would signal to Erply that this credit note should be calculated in the same way as cash receipts. (Somehow it is necessary to distinguish between a credit made from a cash receipt in the POS and a credit made from an invoice-waybill.)”
            isCashInvoice: 1,
            creditInvoiceType: salesDocument.creditInvoiceType,
          });
          delete salesDocumentTemplate.invoiceState;
        }
      }

      if (useAgeVerification) {
        Object.assign(salesDocumentTemplate, {
          attributeName100: 'age-verification',
          attributeValue100: localStorage.getItem('age-verification'),
        });
      }
      if (salesDocument.invoiceState === 'PENDING') {
        if (updateDocumentDateWhenSavingPendingSales) {
          Object.assign(salesDocumentTemplate, {
            date: dayjs().format('YYYY-MM-DD'),
            time: dayjs().format('HH:mm:ss'),
          });
        }
        if (updateDocumentCreatorWhenSavingPendingSales) {
          Object.assign(salesDocumentTemplate, {
            employeeID: loggedInEmployeeID,
          });
        }
      }
      // Generate invoice numbers
      if (
        Number(salesDocument.confirmed) &&
        ['INVWAYBILL', 'CASHINVOICE', 'CREDITINVOICE'].includes(
          salesDocumentTemplate.type,
        )
      ) {
        const pos = await dispatch(updateCurrentPointOfSale());
        let { lastInvoiceNo } = pos;
        if (lastInvoiceNo) {
          const lastInvoiceNoLocal = await lastInvoiceNumbers
            .getItem({
              key: pointOfSaleID,
            })
            .then(Number)
            .then(R.when(Number.isNaN, R.always(null)));
          // check if due to race conditions local lastInvoiceNo is not higher than the one coming from API
          if (Number(lastInvoiceNoLocal) <= Number(lastInvoiceNo)) {
            await lastInvoiceNumbers.saveItem({
              key: pointOfSaleID,
              value: `${Number(lastInvoiceNo) + 1}`,
            });
          } else {
            lastInvoiceNo = lastInvoiceNoLocal;
          }
        } else {
          Sentry.addBreadcrumb(pos);
          Sentry.captureException(
            new Error('[SANITY] No .lastInvoiceNo present on POS'),
          );
        }
        if (lastInvoiceNo) {
          Object.assign(salesDocumentTemplate, {
            invoiceNo: Number(lastInvoiceNo) + 1,
          });
        }
      }
      // Set original warehouse id if exists
      if (
        Number(salesDocument.confirmed) &&
        'INVWAYBILL' === salesDocumentTemplate.type
      ) {
        // warehouseID has to match original if sales doc is confirmed and causes inventory change
        Object.assign(salesDocumentTemplate, {
          warehouseID: salesDocument.warehouseID || warehouseID,
        });
      }
      // handle Layaways
      if (salesDocument.type === 'PREPAYMENT')
        Object.assign(salesDocumentTemplate, {
          reserveGoods:
            salesDocument.invoiceState === 'CANCELLED'
              ? 0
              : salesDocument.reserveGoods,
          advancePayment: salesDocument.advancePayment,
          reserveGoodsUntilDate: salesDocument.reserveGoodsUntilDate,
          ...(typeof salesDocument.paymentDays === 'number' &&
          !Number.isNaN(salesDocument.paymentDays)
            ? { paymentDays: salesDocument.paymentDays }
            : {}),
        });
      if (salesDocument.id && salesDocument.type !== 'CREDITINVOICE') {
        Object.assign(salesDocumentTemplate, {
          id: salesDocument.id,
        });
      }

      Object.assign(
        salesDocumentTemplate,
        spreadCart({
          cart:
            salesDocument.modifiedRows ??
            getShoppingCartForSalesDocument(getState()),
        }),
      );
      if (!ignoreCurrentSalesDocument) {
        Object.assign(
          salesDocumentTemplate,
          getPromoRulesProperties(getState()),
        );
      }

      (salesDocument.modifiedRows ?? shoppingCart)
        .filter(r => r.giftCardSerial)
        .forEach(r => {
          bonusRequests.push({
            value: r.finalPriceWithVAT,
            balance: r.finalPriceWithVAT,
            code: r.giftCardSerial,
            purchasingCustomerID: customerID,
            purchaseDateTime: timestampInSeconds(),
            purchaseWarehouseID: warehouseID,
            purchasePointOfSaleID: pointOfSaleID,
            purchaseEmployeeID: r.employeeID,
            purchaseInvoiceID: 'CURRENT_INVOICE_ID',
            added: timestampInSeconds(),
            vatrateID: r.vatrateID,
            requestName: 'saveGiftCard',
          });
        });

      const giftcardPeriodOfValidity = R.pipe(
        Number,
        R.when(Number.isNaN, () => 0),
      )(getSetting('giftcard_period_of_validity', '0')(getState()));
      if (giftcardPeriodOfValidity) {
        const now = new Date();
        now.setDate(now.getDate() + giftcardPeriodOfValidity);
        const expiration = now.toISOString().split('T')[0];
        bonusRequests
          .filter(req => req.requestName === 'saveGiftCard')
          .forEach(req => {
            // Only add/update period of validity when the gift card is bought, not redeemed
            if (req.purchaseDateTime) {
              req.expirationDate = expiration;
            }
          });
      }

      Object.assign(salesDocumentTemplate, {
        requestID: requests.length,
      });

      const getEmployeeStatsTemplate = {
        requestName: 'getEmployeeStats',
        warehouseID,
        pointOfSaleID,
      };

      const { type, id, invoiceState } = getSalesDocument(getState());
      const total = getPaymentsTotal(getState());

      const isReturn = Number(total) < 0;
      const isReturnToStoreCredit = storeCred < 0;
      const isOrderOrLayaway = ['ORDER', 'PREPAYMENT'].includes(type);
      const isExistingDocument = !!id;
      const isCancellation = invoiceState === 'CANCELLED';

      // Normally store credit payments are not saved at all, instead the other payments go directly into customer credit.
      // This does not happen for layaway cancellations because the layaway already has a total.
      // Thus, to cancel successfully, we need to save explicit payments
      const shouldSaveStoreCreditPayments =
        isReturn &&
        isReturnToStoreCredit &&
        isOrderOrLayaway &&
        isExistingDocument &&
        isCancellation;

      requests.push(salesDocumentTemplate);
      requests.push(getEmployeeStatsTemplate);
      if (Number(salesDocumentTemplate.confirmInvoice) === 1) {
        [...(props.storeCreditPayments ?? []), ...payments].forEach(p =>
          requests.push(
            R.pipe(
              R.assoc('requestName', 'savePayment'),
              R.assoc('requestID', requests.length),
              R.when(p => p.typeID, R.dissoc('type')),
              R.when(
                p => p.addedToStoreCredit && shouldSaveStoreCreditPayments,
                R.assoc('type', 'CREDIT'),
              ),
              // PBIB-4533 If card type is unknown, still send *some* value for cardType
              //   to prevent counted/expected mismatch on z report
              //   See ticket for detailed explanation
              R.when(
                p => p.type === 'CARD' && !p.cardType,
                R.assoc('cardType', 'unknown'),
              ),
            )(p),
          ),
        );

        if (shouldSaveStoreCreditPayments) {
          const storeCreditPaymentForBalance = {
            documentID: 'CURRENT_INVOICE_ID',
            customerID:
              props?.customerID || salesDocument.customerID || customerID,
            type: 'CREDIT',
            paymentServiceProvider: '',
            sum: storeCred,
            currencyCode: salesDocument.currencyCode || currencyCode,
            requestName: 'savePayment',
          };

          requests.push(storeCreditPaymentForBalance);
        }

        bonusRequests.forEach(b =>
          requests.push({
            ...b,
            requestID: requests.length,
          }),
        );
        const rewardPointCost = getAllPromotionsRewardPointCost(getState());
        if (rewardPointCost) {
          requests.push({
            requestName: 'subtractCustomerRewardPoints',
            points: rewardPointCost,
            invoiceID: 'CURRENT_INVOICE_ID',
            customerID: getSelectedCustomer(getState()).customerID,
            // TODO: campaignID
          });
        }
        if (
          salesDocument.creditInvoiceType === 'RETURN' &&
          salesDocument.usedRewardPoints
        ) {
          const returnTotal = shoppingCart
            .filter(row =>
              salesDocument.rows.some(r => r.stableRowID === row.stableRowID),
            )
            .map(row => Number(round(row.rowTotal, 2)))
            .reduce(add, 0);
          const pointsToAdd = Math.round(
            salesDocument.usedRewardPoints *
              (-returnTotal / round(salesDocument.total, 2)),
          );
          requests.push({
            requestName: 'addCustomerRewardPoints',
            points: pointsToAdd,
            invoiceID: 'CURRENT_INVOICE_ID',
            customerID,
          });
        }
        if (
          Object.keys(allPromotions).length &&
          salesDocumentTemplate.type !== 'ORDER'
        ) {
          requests.push({
            ...allPromotions,
            invoiceID: 'CURRENT_INVOICE_ID',
            requestName: 'addPromotionCountsToInvoice',
            requestID: requests.length,
          });
        }

        /**
         * This is just an M&M specific integration point
         * (returns error 1006 on other accounts which is fine and can be ignored)
         * and is not related to addCustomerRewardPoints nor subtractCustomerRewardPoints
         */
        requests.push({
          invoiceID: 'CURRENT_INVOICE_ID',
          requestName: 'calculateRewardPoints',
          requestID: requests.length,
        });
      }

      // Update customer store credit if applicable
      // const storeCredit = salesDocumentTemplate.amountPaidWithStoreCredit;
      // if (storeCredit) {
      //   salesDocumentTemplate.amountPaidWithStoreCredit = Math.abs(storeCredit);
      // }
      // Any existing row-level attributes on the original document should be copied into the rows themselves and removed
      // This prevents attributes on the new document from referring to rows of the old
      // NOTE: This copying would be more logical to do when the document is loaded into cart, In that case checking for existing values is not necessary
      /** @type ErplyAttributes */
      new ErplyAttributes(salesDocument.attributes).asArray.forEach(
        ({ attributeName, attributeValue }) => {
          const match = attributeName.match(/^row-attribute-(.+?)-(.+)$/);
          if (match) {
            const [, rowID, attrName] = match;
            // Remove it from the document
            salesDocumentTemplate.attributes.remove(attributeName);
            // If an order is not found, that could be because that specific row was not chosen to be returned
            const order = shoppingCart.find(
              order => String(order.stableRowID) === rowID,
            );
            if (order) {
              // Order might not have an .attributes yet, initialize it
              order.attributes = order.attributes ?? {};
              // Only override if it doesn't have a value yet
              // (if it has been assigned a new value we should ignore the value from the old sales document)
              order.attributes[attrName] =
                order.attributes[attrName] ?? attributeValue;
            }
          }
        },
      );
      Object.assign(
        salesDocumentTemplate,
        salesDocumentTemplate.attributes.asFlatArray,
      );
      delete salesDocumentTemplate.attributes;
      if (getIsUsingManualStoreCredit(state)) {
        delete salesDocumentTemplate.amountPaidWithStoreCredit;
      }
      try {
        const modifiedRequests = await dispatch(
          onPlugin({ ...props, progress }, requests),
        );
        const payload = await batchSaveRequests(modifiedRequests);
        // At this point, if the action succeeded, both the sale document and payments are already saved to the BO
        const userSalesData = payload.requests.find(
          request => request.status.requestName === 'getEmployeeStats',
        )?.records?.[0];
        if (userSalesData) dispatch(setUserSalesData(userSalesData));

        const lastSaleDocument =
          payload.requests.find(
            request => request.status.requestName === 'saveSalesDocument',
          )?.records?.[0] ?? {};

        const lastSalePayments = payload.requests
          .filter(request => request.status.requestName === 'savePayment')
          .map(data => {
            return {
              ...data.status,
              ...data.records[0],
              ...(modifiedRequests.find(
                req => req.requestID === data.status.requestID,
              ) || {}),
            };
          });

        // Save row-level attributes
        const allAttrs = new ErplyAttributes({});
        if (lastSaleDocument.rows.length) {
          shoppingCart.forEach((order, i) => {
            const rowAttrs = new ErplyAttributes(order.attributes);
            rowAttrs.asArray.forEach(attr => {
              allAttrs.set(
                `row-attribute-${lastSaleDocument.rows[i].stableRowID}-${attr.attributeName}`,
                attr.attributeValue,
              );
            });
          });
        }
        dispatch(updateStockLevels(lastSaleDocument.rows ?? []));
        if (allAttrs.asArray.length > 0) {
          // Sale has already been saved. If this fails, retry would retry saving the entire sale + payments
          // TODO - we should still show why it failed and offer the user too re-save the attributes
          try {
            await saleApi.saveSalesDocument({
              id: lastSaleDocument.invoiceID,
              ...allAttrs.asFlatArray,
              temporaryUUID,
            });
          } catch (e) {
            dispatch(addError('Saving sale attributes failed.'));
            console.error(
              'Failed to save sale attributes. Sale: ',
              lastSaleDocument,
              '. Attributes: ',
              allAttrs.asFlatArray,
              e?.message,
            );
          }
        }

        await dispatch(
          saveApplicableCoupons({
            invoiceID: lastSaleDocument.invoiceID,
            invoiceNo: lastSaleDocument.invoiceNo,
            salesDocument: salesDocumentTemplate,
          }),
        );

        /**
         * save extra properties to JSON API
         * Not wrapped in try/catch since the function itself already has it.
         */
        await dispatch(
          saveSalesDocumentAttrToJsonApi({
            savedSaleDocument: lastSaleDocument,
            shoppingCart,
            savedPayments: lastSalePayments,
            progress,
          }),
        );
        /**
         * Print kitchen/bar receipts in case setting enabled and certain products are sold.
         */
        if (getSetting('touchpos_print_kitchen_bar_receipts')(state)) {
          // Kitchen and Bar receipt printing seems to be error prone, thus wrapped in try catch
          try {
            await dispatch(printKitchenAndBarReceipts(lastSaleDocument));
          } catch {
            await dispatch(
              addError('Failed to print kitchen and/or bar receipt(s)'),
            );
            console.error('Failed to print kitchen and/or bar receipt(s)');
          }
        }
        batch(() => {
          dispatch(resetPayments());
          if (!ignoreCurrentSalesDocument) {
            dispatch({ type: c.SAVE_SALES_DOCUMENT.SUCCESS, payload });
            dispatch(resetProductReturn());
            dispatch(deselectCustomer());
            dispatch(resetCurrentSalesDocument());
          }
          dispatch(setLastSalesDoc(lastSaleDocument));
        });
        if (!ignoreCurrentSalesDocument) {
          await dispatch(resetShoppingCart());
        }
        lastReceiptData.set({
          salesDoc: {
            ...lastSaleDocument,
            employeeID,
            /**
             * If we have row data in the response, augment it with local shopping cart data
             * Otherwise (offline mode?) just use the local shopping cart data as before
             *
             * The extra data from the response can be helpful for printing
             * for example stableRowID is necessary to match up applied promotions with their respective rows
             */
            rows: (function combineCartInfo() {
              if (!lastSaleDocument?.rows) return shoppingCart;
              return lastSaleDocument.rows.map((row, i) => ({
                ...shoppingCart[i],
                ...row,
              }));
            })(),
          },
        });
        onSuccess({ type: salesDocument.type, ...lastSaleDocument });
        dispatch(clearDiscountReasons());
        dispatch(clearReturnReasons());
        // Plugin afterSaveSaleDocument
        await dispatch(
          afterPlugin(props, {
            requests: modifiedRequests,
            responses: payload,
            salesDocument: lastSaleDocument,
          }),
        );
      } catch (err) {
        if (err.errorCode === 1206) return;
        if (!isOfflineError(err)) {
          // Let outer catch block handle the error
          throw new Error(
            i18next.t('alerts:errors.notAnOfflineError', {
              message: err.message,
            }),
            {
              cause: err,
            },
          );
        }

        if (!allowOffline) {
          throw new Error(i18next.t('alerts:errors.offlineNotAllowed'));
        }
        const offlineRequests = [];
        const offlineBonusRequests = props.bonusRequests || [];
        const getInvoiceNo = async () => {
          if (salesDocument.type !== 'ORDER') {
            const invoiceNo = await lastInvoiceNumbers.increment(pointOfSaleID);
            return invoiceNo;
          }
        };
        const invoiceNo = await getInvoiceNo();
        const offlineDocumentTemplate = {
          temporaryUUID,
          currencyCode: salesDocument.currencyCode || currencyCode,
          customerID: salesDocument.customerID || customerID,
          warehouseID: salesDocument.warehouseID || warehouseID,
          pointOfSaleID: salesDocument.pointOfSaleID || pointOfSaleID,
          confirmInvoice: salesDocument.confirmed || 0, // indicates if the saveDocument is to be completed or added to pending
          type: salesDocument.type || 'CASHINVOICE',
          invoiceNo: salesDocument.type === 'ORDER' ? undefined : invoiceNo,
          paymentType: salesDocument.paymentType,
          employeeID: salesDocument.employeeID || employeeID,
          ...new ErplyAttributes(salesDocument.attributes).asFlatArray,
          notes: salesDocument.notes,
          internalNotes: salesDocument.internalNotes,
          requestName: 'saveSalesDocument',
          added: timestampInSeconds(),
          rounding: getRounding(state),
        };
        Object.assign(
          offlineDocumentTemplate,
          spreadCart({
            cart: (
              salesDocument.modifiedRows ??
              getShoppingCartForSalesDocument(getState())
            ).map(row => ({
              ...row,
              vatrateID:
                getRowVatRateID(row)(getState()) ??
                getSelectedPosVatRate(getState())?.id,
            })),
          }),
        );
        Object.assign(offlineDocumentTemplate, {
          requestID: offlineRequests.length,
        });
        offlineRequests.push(offlineDocumentTemplate);
        if (Number(offlineDocumentTemplate.confirmInvoice) === 1) {
          payments.forEach(p =>
            offlineRequests.push({
              ...p,
              requestName: 'savePayment',
              requestID: offlineRequests.length,
              cardType:
                p.type === 'CARD' && !p.cardType ? 'unknown' : p.cardType,
            }),
          );
          offlineBonusRequests.forEach(b =>
            offlineRequests.push({
              ...b,
              requestID: offlineRequests.length,
            }),
          );
        }

        if (
          Object.keys(allPromotions).length > 0 &&
          offlineDocumentTemplate.type !== 'ORDER'
        ) {
          offlineRequests.push({
            ...allPromotions,
            invoiceID: 'CURRENT_INVOICE_ID',
            requestName: 'addPromotionCountsToInvoice',
            requestID: offlineRequests.length,
          });
        }

        dispatch(updateStockLevels(shoppingCart));
        pendingRequests.add({
          request: 'batchSaveRequests',
          params: offlineRequests,
        });
        batch(() => {
          dispatch(dismiss());
          dispatch(resetPayments());
          dispatch(resetShoppingCart());
          dispatch(deselectCustomer());
          dispatch(resetCurrentSalesDocument());
          dispatch(setLastSalesDoc(offlineDocumentTemplate));
        });
        const documentResponseTemplate = {
          currencyCode: salesDocument.currencyCode || currencyCode,
          clientID: salesDocument.customerID || customerID,
          warehouseID: salesDocument.warehouseID || warehouseID,
          pointOfSaleID: salesDocument.pointOfSaleID || pointOfSaleID,
          confirmed: salesDocument.confirmed || 0, // indicates if the saveDocument is to be completed or added to pending
          type: salesDocument.type || 'CASHINVOICE',
          invoiceNo: salesDocument.type === 'ORDER' ? undefined : invoiceNo,
          invoiceID: salesDocument.type === 'ORDER' ? undefined : invoiceNo,
          number: salesDocument.type === 'ORDER' ? undefined : invoiceNo,
          paymentType: salesDocument.paymentType,
          employeeID: salesDocument.employeeID || employeeID,
          ...new ErplyAttributes(salesDocument.attributes).asFlatArray,
          notes: salesDocument.notes,
          internalNotes: salesDocument.internalNotes,
          netTotal: getTotalNet(getState()),
          rounding: getRounding(state),
          total: getTotal(getState()) + getRounding(state),
          rows: shoppingCart,
        };
        lastReceiptData.set({ salesDoc: documentResponseTemplate });
        onSuccess({
          ...documentResponseTemplate,
        });
      }
    } catch (err) {
      batch(() => {
        dispatch({ type: c.SAVE_SALES_DOCUMENT.ERROR, payload: err });
        dispatch(dismiss());
      });
      // TODO: Polyfill error causal chain for chrome - maybe with `modern-errors` library?
      console.groupCollapsed('Error during saveSalesDocument', err);
      getCausalStack(err).forEach(err => console.error(err));
      console.groupEnd();
      Sentry.captureException(err);

      await dispatch(promptToRetryOrReturnToPaymentModal(props, err));
    }
  };
}

export const saveSalesDocument = withProgressAlert(
  'alerts:payments.savingDocument',
  {
    errorType: 'savingDocument',
  },
)(saveSalesDocumentBase);

export function savePayments({ documentID, payments, storeCreditPayments }) {
  return async dispatch => {
    const requests = [...payments, ...storeCreditPayments].map(pmnt => ({
      ...pmnt,
      ...new ErplyAttributes(pmnt).asFlatArray,
      sum: pmnt.amount,
      documentID,
      request: 'savePayment',
    }));

    const results = await Promise.all(
      requests.map(req => doClientRequest(req)),
    );
    dispatch(addSuccess(i18next.t('alerts:payments.success')));
    return results;
  };
}

export function startNewSale(params = { askForConfirmation: true }) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onStartNewSale')(
      getState(),
    );
    const isPaymentModalOpening = getHasUndismissedErrorsOfType(
      'openPaymentModal',
    )(getState());

    if (isPaymentModalOpening) return;

    try {
      await dispatch(before(params));
      const state = getState();

      const pickupInProgress = getIsPickupInProgress(state);
      if (pickupInProgress) {
        await dispatch(addWarning(i18next.t('alerts:pickupInProgress')));
        return;
      }

      if (getNumberOfProductsInCart(state) > 0 && params.askForConfirmation) {
        await new Promise((resolve, reject) =>
          dispatch(
            createConfirmation(resolve, reject, {
              title: i18next.t('alerts:payments.void.confirm.title'),
              body: i18next.t('alerts:payments.void.confirm.body'),
            }),
          ),
        );
      }
      await dispatch(on(params, null));
      await dispatch(resetShoppingCart());
      batch(() => {
        dispatch(clearDiscountReasons());
        dispatch(clearReturnReasons());
        dispatch(deselectCustomer());
        dispatch(resetCurrentSalesDocument());
        dispatch(resetProductReturn());
      });
      await dispatch(after(params, null));
    } catch (e) {
      // User cancelled
    }
  };
}

export function getPendingSales() {
  return async (dispatch, getState) => {
    const { pointOfSaleID: posID } = getSelectedPos(getState());
    const sales = await saleApi.getSalesDocuments({
      type: 'CASHINVOICE',
      recordsOnPage: 100,
      confirmed: 0,
      posID,
    });
    return sales;
  };
}

export function deletePendingSales(sales) {
  return () => Promise.all(sales.map(s => saleApi.deleteSalesDocument(s.id)));
}

// Previous and pending sales action to be dispatched form grid layout on Col2

function fetchingSalesData(query, salesType) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      addWarning(i18next.t(`alerts:loading.${salesType}Sales`), {
        dismissible: false,
        selfDismiss: false,
        errorType: 'fetching_sales',
      }),
    );
    try {
      const { pointOfSaleID: posID } = getSelectedPos(state);
      const currentLocationOnly = getSetting(
        {
          recent: 'touchpos_recent_sales_only_from_current_location',
          pending: 'touchpos_unfinished_sales_only_from_current_location',
          order: 'touchpos_orders_only_from_current_location',
          layaway: 'touchpos_laybys_only_from_current_location',
          invoice: 'touchpos_invoices_only_from_current_location',
          offer: 'touchpos_offers_only_from_current_location',
        }[salesType],
      )(state);

      const queryParams = {
        recordsOnPage: 100,
        confirmed: 0,
        getRowsForAllInvoices: 1,
        getCustomerInformation: 1,
        posID,
        ...(currentLocationOnly
          ? {
              warehouseID: getSelectedPos(state).warehouseID,
              // pointOfSaleID: getSelectedPos(state).pointOfSaleID,
            }
          : {}),
        ...query,
      };

      const sales = await saleApi.getSalesDocuments(queryParams);
      batch(() => {
        dispatch(dismissType('fetching_sales'));
        if (!sales.length) {
          dispatch(
            addWarning(
              i18next.t(`alerts:loading.${salesType}Sales`, {
                context: 'none',
              }),
              {
                selfDismiss: true,
              },
            ),
          );
        }
      });
      // TODO: maybe move this to the API request
      //  TODO: NB! (IF moving it there, do not forget to refactor actions/returnProducts.tsx fetchSalesDocs)
      // flattens the nested JSON API records in the productCard
      return sales.map(sale => ({
        ...sale,
        rows: sale.rows.map(row => ({
          ...row,
          ...row.jdoc?.BrazilPOS,
          orderIndex: row.stableRowID,
          // NB! Handles cases where sales Document has different currency than the current location
          // converts totals to default currency
          finalNetPrice: row.finalNetPrice * sale.currencyRate,
          finalPriceWithVAT: row.finalPriceWithVAT * sale.currencyRate,
          price: row.price * sale.currencyRate,
          rowNetTotal: row.rowNetTotal * sale.currencyRate,
          rowTotal: row.rowTotal * sale.currencyRate,
          rowVAT: row.rowVAT * sale.currencyRate,
        })),
        // NB! Handles cases where sales Document has different currency than the current location
        // converts totals to default currency
        netTotal: sale.netTotal * sale.currencyRate,
        netTotalsByTaxRate: sale.netTotalsByTaxRate.map(x => ({
          ...x,
          total: x.total * sale.currencyRate,
        })),
        total: sale.total * sale.currencyRate,
        vatTotal: sale.vatTotal * sale.currencyRate,
        vatTotalsByTaxRate: sale.vatTotalsByTaxRate.map(x => ({
          ...x,
          total: x.total * sale.currencyRate,
        })),
        paid: sale.paid ? String(sale.paid * sale.currencyRate) : sale.paid,
        currencyCode: getCurrencyCode(getState()),
        currencyRate: '0',
      }));
    } catch (err) {
      console.error('Failed to load sales of type', salesType, err);
      dispatch(
        addError(
          i18next.t(`alerts:loading.${salesType}Sales`, { context: 'error' }),
          {
            selfDismiss: true,
          },
        ),
      );
      return [];
    } finally {
      dispatch(dismissType('fetching_sales'));
    }
  };
}

function openModalPageWithSales(sales, salesType) {
  return async dispatch => {
    const components = {
      pending: mp.pendingSales,
      recent: mp.recentSales,
      order: mp.pickupOrders,
      layaway: mp.layawayList,
      invoice: mp.unpaidInvoices,
    };
    dispatch(
      openModalPage({
        component: components[salesType],
        props: { sales },
      }),
    );
  };
}

export function fetchRecentSales(queryProps = {}) {
  return fetchingSalesData({ confirmed: 1, ...queryProps }, 'recent');
}

export function fetchRecentSalesAndOpenModal() {
  return async dispatch => {
    dispatch(openModalPageWithSales([], 'recent'));
  };
}

export function fetchPendingSales(queryProps = {}) {
  return fetchingSalesData({ type: 'CASHINVOICE', ...queryProps }, 'pending');
}

export function fetchUnpaidInvoices(queryProps = {}) {
  return fetchingSalesData(
    {
      types: 'INVOICE,INVWAYBILL',
      confirmed: 1,
      unpaidItemsOnly: 1,
      getReturnedPayments: 1,
      ...queryProps,
    },
    'invoice',
  );
}

export function fetchUnpaidInvoicesAndOpenmodal() {
  return async dispatch => {
    const sales = await dispatch(fetchUnpaidInvoices());
    if (sales.length) {
      dispatch(openModalPageWithSales(sales, 'invoice'));
    }
  };
}

export function fetchPendingSalesAndOpenModal() {
  return async dispatch => {
    const sales = await dispatch(fetchPendingSales());
    if (sales.length) {
      dispatch(openModalPageWithSales(sales, 'pending'));
    }
  };
}

export function fetchOrderSales(queryProps = {}) {
  return fetchingSalesData(
    {
      confirmed: 1,
      type: 'ORDER',
      invoiceState: 'READY',
      // getUnfulfilledDocuments: 1 - does not return partially fulfilled (which are still unfulfilled) documents, thus removed
      ...queryProps,
    },
    'order',
  );
}

export function fetchOrderSalesAndOpenModal() {
  return async dispatch => {
    const sales = await dispatch(fetchOrderSales());
    if (sales.length) {
      dispatch(openModalPageWithSales(sales, 'order'));
    }
  };
}

export function fetchLayawaySales(queryProps) {
  return fetchingSalesData(
    {
      confirmed: 1,
      type: 'PREPAYMENT',
      getUnfulfilledDocuments: 1,
      getReturnedPayments: 1,
      invoiceState: 'NOT_CANCELLED',
      ...queryProps,
    },
    'layaway',
  );
}

export function fetchLayawaySalesAndOpenModal() {
  return async dispatch => {
    const sales = await dispatch(fetchLayawaySales());
    if (sales.length) {
      dispatch(openModalPageWithSales(sales, 'layaway'));
    }
  };
}

export function setUsedRewardPoints(returnDocument) {
  return async (dispatch, getState) => {
    const didUseRewardPoints = returnDocument.rows
      .reduce((all, row) => R.union(all, row.campaignIDs?.split(',')), [])
      .some(
        campaignID =>
          getPromotionByID(campaignID)(getState())?.formula ===
          'PAY_WITH_LOYALTY_POINTS',
      );
    if (didUseRewardPoints) {
      try {
        const date = new Date(returnDocument.date).getTime() / 1000;
        const daySeconds = 24 * 60 * 60;
        const rewardPointRecords = await getUsedRewardPointRecords({
          customerID: returnDocument.clientID,
          createdUnixTimeFrom: date - daySeconds,
          createdUnixTimeTo: date + daySeconds,
        });

        const relatedRecord = rewardPointRecords?.find(
          record => record.invoiceID === returnDocument.id,
        );
        if (!relatedRecord) {
          const error = new Error(
            'Invoice has PAY_WITH_LOYALTY_POINTS promotion on it, but no rewardPointRecord for the invoice could be found!',
            { returnDocument, rewardPointRecords },
          );
          Sentry.captureException(error);
          throw error;
        }

        dispatch({
          type: c.SET_USED_REWARD_POINTS,
          payload: relatedRecord.usedPoints,
        });
      } catch (error) {
        dispatch(
          addWarning('Unable to find amount of reward points to return', {
            selfDismiss: false,
          }),
        );
      }
    }
  };
}

export function saveSale() {
  return async (dispatch, getState) => {
    const shoppingCartNeedsCalculation = getShoppingCartNeedsCalculation(
      getState(),
    );
    /**
     * Only referenced returns have the `isCurrentSaleAReturn` flag inside `sales` state set to true.
     */
    const isReferencedReturn = getIsCurrentSaleAReturn(getState());

    if (shoppingCartNeedsCalculation) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.calculateShoppingCart'), {
          selfDismiss: true,
          dismissible: true,
        }),
      );
    }
    const shoppingCartIsEmpty = !getHasProductsInShoppingCart(getState());
    if (shoppingCartIsEmpty) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.receiptEmptyError'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }

    if (isReferencedReturn) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.returnSaveError', {
            context: 'pending',
          }),
          {
            selfDismiss: true,
            dismissible: false,
          },
        ),
      );
    }

    const { id, type } = getCurrentSalesDocument(getState());
    if (!!id && type === 'ORDER') {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.savingOrdersNotAllowed'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }

    const isBlockedExchange = await dispatch(checkIfBlockedExchange());
    if (isBlockedExchange) return null;

    try {
      await dispatch(waitForCartCalculationToFinish());
    } catch (error) {
      return null; // Timed out
    }

    const cartHasSerializedGiftCard = getCartHasSerializedGiftCardProduct(
      getState(),
    );
    if (cartHasSerializedGiftCard) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.giftCardOnPendingSaleNotAllowed'),
          {
            selfDismiss: true,
            dismissible: false,
          },
        ),
      );
    }
    dispatch(saveSalesDocument());
  };
}

export function saveAsOrder() {
  return async (dispatch, getState) => {
    const shoppingCartNeedsCalculation = getShoppingCartNeedsCalculation(
      getState(),
    );
    if (shoppingCartNeedsCalculation) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.calculateShoppingCart'), {
          selfDismiss: true,
          dismissible: true,
        }),
      );
    }
    const shoppingCartIsEmpty = !getHasProductsInShoppingCart(getState());
    if (shoppingCartIsEmpty) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.receiptEmptyError'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }

    const isBlockedExchange = await dispatch(checkIfBlockedExchange());
    if (isBlockedExchange) return null;

    try {
      await dispatch(waitForCartCalculationToFinish());
    } catch (error) {
      return null; // Timed out
    }

    const isAReturn = getIsAReturn(getState());
    if (isAReturn) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.returnSaveError', {
            context: 'order',
          }),
          {
            selfDismiss: true,
            dismissible: false,
          },
        ),
      );
    }
    const currentSalesDocument = getCurrentSalesDocument(getState());
    const { id, type } = currentSalesDocument;
    if (!id || (id && ['PREPAYMENT', 'CASHINVOICE'].includes(type)))
      dispatch(
        setCurrentSalesDocument({
          ...currentSalesDocument,
          type: 'ORDER',
          confirmed: 1,
        }),
      );
    return dispatch(openPaymentModal({ showNotes: false }));
  };
}

export function saveAsWaybill() {
  return async (dispatch, getState) => {
    const isDefaultCustomer = getIsDefaultCustomer(getState());
    if (isDefaultCustomer) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.defaultCustomerError', {
            context: 'waybill',
          }),
          {
            dismissible: false,
            selfDismiss: 3500,
          },
        ),
      );
    }
    const shoppingCartIsEmpty = !getHasProductsInShoppingCart(getState());
    if (shoppingCartIsEmpty) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.receiptEmptyError'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }

    const isBlockedExchange = await dispatch(checkIfBlockedExchange());
    if (isBlockedExchange) return null;

    try {
      await dispatch(waitForCartCalculationToFinish());
    } catch (error) {
      return null; // Timed out
    }

    const isAReturn = getIsAReturn(getState());
    if (isAReturn) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.returnSaveError', {
            context: 'waybill',
          }),
          {
            selfDismiss: true,
            dismissible: false,
          },
        ),
      );
    }
    const currentSalesDocument = getCurrentSalesDocument(getState());

    return dispatch(
      openPaymentModal({
        showNotes: false,
        props: {
          salesDocument: {
            ...currentSalesDocument,
            type: 'WAYBILL',
            confirmed: 1,
          },
        },
      }),
    );
  };
}

export function saveAsLayaway() {
  return async (dispatch, getState) => {
    const shoppingCartIsEmpty = !getHasProductsInShoppingCart(getState());
    if (shoppingCartIsEmpty) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.receiptEmptyError'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }
    const isDefaultCustomer = getIsDefaultCustomer(getState());
    if (isDefaultCustomer) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.defaultCustomerError', {
            context: 'layaway',
          }),
          {
            dismissible: false,
            selfDismiss: 3500,
          },
        ),
      );
    }

    const isBlockedExchange = await dispatch(checkIfBlockedExchange());
    if (isBlockedExchange) return null;

    try {
      await dispatch(waitForCartCalculationToFinish());
    } catch (error) {
      return null; // Timed out
    }

    const shoppingCartNeedsCalculation = getShoppingCartNeedsCalculation(
      getState(),
    );
    if (shoppingCartNeedsCalculation) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.calculateShoppingCart'), {
          selfDismiss: true,
          dismissible: true,
        }),
      );
    }
    const isAReturn = getIsAReturn(getState());
    if (isAReturn) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.returnSaveError', {
            context: 'layaway',
          }),
          {
            selfDismiss: true,
            dismissible: false,
          },
        ),
      );
    }
    const currentSalesDocument = getCurrentSalesDocument(getState());
    const { id, type } = currentSalesDocument;
    if (!id || (id && type === 'ORDER'))
      dispatch(
        setCurrentSalesDocument({
          ...currentSalesDocument,
          type: 'PREPAYMENT',
          confirmed: 1,
        }),
      );
    return dispatch(openModalPage({ component: mp.layaway }));
  };
}

export function saveAsAccountSale() {
  return async (dispatch, getState) => {
    const shoppingCartIsEmpty = !getHasProductsInShoppingCart(getState());
    const isDefaultCustomer = getIsDefaultCustomer(getState());
    const currentSalesDocument = getCurrentSalesDocument(getState());
    const customer = getSelectedCustomer(getState());
    const { id, type, total: saleTotal, paid } = currentSalesDocument;

    if (shoppingCartIsEmpty) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.receiptEmptyError'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }
    if (isDefaultCustomer) {
      return dispatch(
        addWarning(
          i18next.t('gridButtons:alerts.defaultCustomerError', {
            context: 'accountSales',
          }),
          {
            dismissible: false,
            selfDismiss: 3500,
          },
        ),
      );
    }

    const isBlockedExchange = await dispatch(checkIfBlockedExchange());
    if (isBlockedExchange) return null;

    try {
      await dispatch(waitForCartCalculationToFinish());
    } catch (error) {
      return null; // Timed out
    }

    const total = getTotal(getState());
    const outstandingAmount = saleTotal - paid || total;
    if (outstandingAmount > customer.availableCredit) {
      return dispatch(
        addWarning(i18next.t('gridButtons:alerts.creditLimitOver'), {
          dismissible: false,
          selfDismiss: 3500,
        }),
      );
    }
    if (!id || (id && type === 'ORDER'))
      dispatch(
        setCurrentSalesDocument({
          ...currentSalesDocument,
          type: 'INVWAYBILL',
          confirmed: 1,
          ...(id ? { baseDocumentID: id } : {}),
        }),
      );
    return dispatch(openModalPage({ component: mp.accountSales }));
  };
}

/**
 * @param { import("../types/SalesDocument").SaleDocumentResponse} sale
 */
function pickupOrderBase(sale) {
  return async (dispatch, getState) => {
    // @ts-ignore
    const { before, on, after } = getPluginLifecycleHook('onPickupOrder')(
      getState(),
    );

    await dispatch(before(sale));

    const order = await dispatch(on(sale, sale));

    const followUpDocumentIDs = sale.followUpDocuments.map(d => d.id);

    const followUpDocuments = followUpDocumentIDs.length
      ? await saleApi.getSalesDocuments({
          ids: followUpDocumentIDs.join(','),
          getRowsForAllInvoices: 1,
        })
      : [];

    // Combine all the rows from the followUp documents into a single flattened array
    const followUpRows = followUpDocuments.flatMap(fud => fud.rows);

    const rowsToSet = order.rows
      .map(row => {
        const initialAmount = Number(row.amount);

        const returnedAmount = followUpRows
          .filter(
            fur =>
              fur.jdoc?.CreditInvoice?.creditedStableRowID ===
              Number(row.stableRowID),
          )
          .map(fur => Number(fur.amount))
          .reduce(R.add, 0);

        // Calculate how much of the Order's row's qty can still be picked up/fulfilled
        const remainingAmount = initialAmount - returnedAmount;

        return { ...row, amount: String(remainingAmount) };
      })
      .filter(row => Number(row.amount) > 0);

    // If there are some rows that can be added and there were already some rows followed up,
    // explain to user why not all order rows were added
    if (rowsToSet.length && followUpRows.length) {
      i18next
        .loadNamespaces('order')
        .then(() =>
          dispatch(addWarning(i18next.t('order:load.alerts.partialPickup'))),
        );
    }

    await dispatch(resetShoppingCart());
    await dispatch(setCustomer({ data: order.clientID }));
    dispatch(
      setCurrentSalesDocument(R.assoc('fulfillableRows', rowsToSet)(order)),
    );
    dispatch(
      setCurrentSalesDocPayments({
        documentID: sale.id,
      }),
    );
    // NB! Make sure that cart is recalculated AFTER sale document and customer are set.
    // Certain document and customer fields might impact calculation and omitting them will result in
    // incorrectly calculated cart, for example, twice applied promotions.
    dispatch(addMultiProducts(rowsToSet));

    await dispatch(after(sale, null));
  };
}

export const pickupOrder = withProgressAlert('order:alerts.pickingUpOrder')(
  pickupOrderBase,
);

/**
 * @param { import("../types/SalesDocument").SaleDocumentResponse} sale
 */
function pickupPendingSaleBase(sale) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onPickupPendingSale')(
      getState(),
    );
    await dispatch(before(sale));

    const shouldGetPromotions = sale.rows.some(
      ({ discount, stableRowID }) => Number(discount) > 0 && stableRowID,
    );
    const promos = !shouldGetPromotions
      ? []
      : await doClientRequest({
          request: 'getAppliedPromotionRecords',
          invoiceIDs: sale.id,
        }).catch(err => {
          console.error(
            'Failed to fetch applied promotions for sale',
            sale,
            err,
          );
          return [];
        });

    const products = await getProducts({
      productIDs: sale.rows.map(row => row.productID).join(','),
    })
      .then(({ records }) => records)
      .catch(err => {
        console.error('Failed to pick up pending sale', sale, err);
        return [];
      });

    const getProductsToAdd = () =>
      sale.rows.map(product => {
        const isNonDiscountable = getIsProductNonDiscountable(product)(
          getState(),
        );
        const getDiscountFromPromo = () => {
          // promo associated with row
          const promo = (promos ?? []).find(
            promo =>
              Number(promo.stableRowID) === Number(product.stableRowID) &&
              Number(promo.manualDiscountPercentage),
          );

          if (!promo) return undefined;

          // if manual discount on product, use it instead of promo manual discount
          const { manualDiscountPercentage } = promo;

          return manualDiscountPercentage;
        };
        const newProduct = { ...product };
        if (!newProduct.originalPriceIsZero) {
          delete newProduct.price;
          delete newProduct.finalNetPrice;
          delete newProduct.finalPriceWithVAT;
        }
        if (!isNonDiscountable) {
          const manualDiscountToApply = getDiscountFromPromo();
          if (manualDiscountToApply)
            newProduct.manualDiscount = manualDiscountToApply;
          delete newProduct.discount;
        }

        // Check if VAT rate is implicitly set and if it is remove VAT rate ID
        const productObj = products.find(
          p => String(p.productID) === String(newProduct.productID),
        );
        const productVatRate = getVatRateByID(productObj?.vatrateID)(
          getState(),
        );
        const selectedPosVatRate = getSelectedPosVatRate(getState());
        const selectedCustomer = getSelectedCustomer(getState());

        const defaultVatRate = selectedPosVatRate || productVatRate;
        const isDefaultVatRate = newProduct.vatrateID === defaultVatRate?.id;
        const isImplicitVatRate =
          isDefaultVatRate || selectedCustomer.taxExempt;
        if (isImplicitVatRate) {
          delete newProduct.vatrateID;
        }
        return newProduct;
      });

    const threw = Symbol('threw');
    const modifiedSale = await dispatch(on(sale, sale)).catch(() => threw);
    if (modifiedSale === threw) return;

    dispatch(resetShoppingCart());

    dispatch(setCurrentSalesDocument(modifiedSale));
    await dispatch(setCustomer({ data: modifiedSale.clientID }));
    // NB! Make sure that cart is recalculated AFTER sale document and customer are set.
    // Certain document and customer fields might impact calculation and omitting them will result in
    // incorrectly calculated cart, for example, twice applied promotions.
    await dispatch(addMultiProducts(getProductsToAdd()));

    // Apply manual promotions if they are active and were applied when sale was saved
    const activeManualPromotions = getManualPromotions(getState());

    promos.forEach(promo => {
      const activeManualPromotion = activeManualPromotions.find(
        mp => Number(mp.campaignID) === Number(promo.promotionID),
      );
      if (activeManualPromotion) {
        dispatch(applyPromotion({ ...activeManualPromotion, amount: 1 }));
      }
    });

    await dispatch(after(sale, modifiedSale));
  };
}

export const pickupPendingSale = withProgressAlert(
  'sale:pendingSales.alerts.loadingPickup',
  {
    errorType: 'saved-sale-pickup',
  },
)(pickupPendingSaleBase);

/**
 * @param { import("../types/SalesDocument").SaleDocumentResponse} sale
 */
function cancelLayawayBase(sale) {
  return async (dispatch, getState) => {
    try {
      const CURR = getCurrencyFormatterNoSymbol(getState());
      const layawayCancellationFeePercentage = getSetting(
        'layaway_cancellation_fee_percentage',
      )(getState());
      const feeType = getSetting('layaway_cancellation_fee_type')(getState());
      const outstandingAmount = sale.total - CURR.parse(sale.paid);
      const hasRightToReturn = getHasRightToReturn(getState());

      if (!hasRightToReturn) {
        dispatch(addError(i18next.t('alerts:noRefReturnRights')));
        return;
      }
      const [customer] = await getCustomers({
        customerID: sale.clientID,
        getBalanceInfo: 1,
        getBalanceWithoutPrepayments: 1,
      });
      // If a percentage is set, calculate the fee and skip to payment screen
      if (Number(layawayCancellationFeePercentage ?? 0) > 0) {
        const multiplier = Number(layawayCancellationFeePercentage) / 100;
        const prefillNumericValue = () => {
          switch (feeType) {
            case 'paid': {
              return CURR.parse(sale.paid) * multiplier;
            }
            case 'unpaid': {
              return outstandingAmount * multiplier;
            }
            case 'total':
            default: {
              return sale.total * multiplier;
            }
          }
        };

        // Calculate the fee based on settings
        const fee = prefillNumericValue().toFixed(2);
        const toReturn = CURR.parse(sale.paid) - Number(fee);

        // Close the action selection first
        dispatch(closeModalPage());

        // Proceed to payment screen
        dispatch(
          openPaymentModal({
            props: {
              isReturn: true,
              ignoreCurrent: true,
              salesDocument: {
                clientID: sale.clientID,
                modifiedRows: [],
                total: -sale.total,
                type: 'PREPAYMENT',
                invoiceState: 'CANCELLED',
                advancePayment: sale.advancePayment,
                id: sale.id,
                cancellationFee: Number(fee),
              },
              originalPayments: await saleApi.getPayments({
                documentID: sale.id,
              }),
              currentSalesDocument: sale,
              customer,
              total: -toReturn,
            },
          }),
        );
      }
      // If percentage not configured (or set to 0), open the fee modal where the fee would be entered
      else {
        // Close the action selection first
        dispatch(closeModalPage());
        // Proceed to fee modal
        dispatch(
          openModalPage({
            component: mp.cancellationFeeModal,
            isPopup: true,
            props: {
              sale,
              // Pass customer to cancellation fee so that it doesn't need to re-fetch it
              customer,
            },
          }),
        );
      }
    } catch (error) {
      dispatch(addError(i18next.t('alerts:genericError')));
    }
  };
}

export const cancelLayaway = withProgressAlert('layaway:alerts.cancelLayaway')(
  cancelLayawayBase,
);

/**
 * @param { import("../types/SalesDocument").SaleDocumentResponse} sale
 */
function payFullyForLayawayBase(sale) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onPickupLayaway')(
      getState(),
    );

    try {
      await dispatch(before(sale));

      const threw = Symbol('threw');

      const modifiedSale = await dispatch(on(sale, sale)).catch(() => threw);
      if (modifiedSale === threw) return;

      await dispatch(resetShoppingCart());
      // TODO: Also pass products and customer explicitly
      //  products: .rows2
      //  customer: .clientID
      await dispatch(setCustomer({ data: modifiedSale.clientID }));
      await dispatch(setCurrentSalesDocument(modifiedSale));
      await dispatch(
        setCurrentSalesDocPayments({
          documentID: modifiedSale.id,
        }),
      );
      // NB! Make sure that cart is recalculated AFTER sale document and customer are set.
      // Certain document and customer fields might impact calculation and omitting them will result in
      // incorrectly calculated cart, for example, twice applied promotions.
      await dispatch(addMultiProducts(modifiedSale.rows));

      dispatch(closeModalPage());

      dispatch(
        openPaymentModal({
          props: {
            salesDocument: modifiedSale,
            resetSalesDocumentOnClose: false,
          },
        }),
      );

      await dispatch(after(sale, modifiedSale));
    } catch (error) {
      dispatch(addError(i18next.t('alerts:genericError')));
    }
  };
}

export const payFullyForLayaway = withProgressAlert(
  'layaway:alerts.loadingFullLayaway',
)(payFullyForLayawayBase);
/**
 * @param { import("../types/SalesDocument").SaleDocumentResponse} sale
 */
function payPartiallyForLayawayBase(sale) {
  return async (dispatch, getState) => {
    try {
      const { before, on, after } = getPluginLifecycleHook('onPickupLayaway')(
        getState(),
      );

      await dispatch(before(sale));

      const threw = Symbol('threw');

      const modifiedSale = await dispatch(on(sale, sale)).catch(() => threw);
      if (modifiedSale === threw) return;

      await dispatch(resetShoppingCart());
      // TODO: Also pass products and customer explicitly
      //  products: .rows2
      //  customer: .clientID
      await dispatch(
        setCustomer({
          data: modifiedSale.clientID,
          recalculateShoppingCart: false,
        }),
      );
      await dispatch(setCurrentSalesDocument(modifiedSale));
      await dispatch(
        setCurrentSalesDocPayments({
          documentID: modifiedSale.id,
        }),
      );
      // NB! Make sure that cart is recalculated AFTER sale document and customer are set.
      // Certain document and customer fields might impact calculation and omitting them will result in
      // incorrectly calculated cart, for example, twice applied promotions.
      await dispatch(addMultiProducts(modifiedSale.rows));

      dispatch(closeModalPage());

      dispatch(openModalPage({ component: mp.layaway, isPopup: true }));

      await dispatch(after(sale, modifiedSale));
    } catch (error) {
      dispatch(addError(i18next.t('alerts:genericError')));
    }
  };
}

export const payPartiallyForLayaway = withProgressAlert(
  'layaway:alerts.loadingPartialLayaway',
)(payPartiallyForLayawayBase);

/**
 * @param { import("../types/SalesDocument").SaleDocumentResponse} offer
 */
function pickupOfferBase(offer) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onPickupOffer')(
      getState(),
    );

    await dispatch(before(offer));
    const { clientID, rows, id } = offer;

    const currentSale = getCurrentSalesDocument(getState());
    const shoppingCartEmpty = !getHasProductsInShoppingCart(getState());

    const currentSaleToArray = currentSale =>
      currentSale ? currentSale.split(',').filter(s => s !== '') : [];

    const finalSale = {
      ...currentSale,
      baseDocumentIDs: currentSaleToArray(currentSale.baseDocumentIDs)
        .concat([id])
        .join(','),
    };

    if (!rows.length) return;

    const threw = Symbol('threw');
    const modifiedOffer = await dispatch(on(offer, finalSale)).catch(
      () => threw,
    );
    if (modifiedOffer === threw) return;

    const setOfferData = async () => {
      dispatch(setCurrentSalesDocument(modifiedOffer));
      await dispatch(setCustomer({ data: clientID }));
      // NB! Make sure that cart is recalculated AFTER sale document and customer are set.
      // Certain document and customer fields might impact calculation and omitting them will result in
      // incorrectly calculated cart, for example, twice applied promotions.
      dispatch(addMultiProducts(rows));
      dispatch(closeModalPage());
    };

    if (shoppingCartEmpty) {
      await setOfferData();
      await dispatch(after(offer, modifiedOffer));
      return;
    }

    try {
      await new Promise((resolve, reject) =>
        dispatch(
          createConfirmation(resolve, reject, {
            title: i18next.t('offer:confirmation.title'),
            body: i18next.t('offer:confirmation.body'),
          }),
        ),
      );
      // If OK is pressed, add the products to the shopping cart.
      await setOfferData();
    } catch (error) {
      // If Cancel is pressed, just return back to the offers screen
    }

    await dispatch(after(offer, modifiedOffer));
  };
}

export const pickupOffer = withProgressAlert('offer:pickup.loading')(
  pickupOfferBase,
);

/**
 * @param { import("../types/SalesDocument").SaleDocumentResponse} invoice
 */
function pickupInvoiceBase(invoice) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onPickupInvoice')(
      getState(),
    );

    await dispatch(before(invoice));

    const threw = Symbol('threw');
    const propSale = await dispatch(
      on(invoice, { ...invoice, modifiedRows: [] }),
    ).catch(() => threw);

    if (propSale === threw) return;

    const [customers, originalPayments] = await Promise.all([
      getCustomers({
        customerID: invoice.clientID,
        getBalanceInfo: 1,
        getBalanceWithoutPrepayments: 1,
      }),
      saleApi.getPayments({ documentID: invoice.id }),
    ]);

    await dispatch(previousModalPage());

    await dispatch(
      openPaymentModal({
        props: {
          ignoreCurrent: true,
          salesDocument: {
            ...propSale,
            modifiedRows: [],
          },
          total: propSale.total,
          customer: customers[0],
          originalPayments,
          currentSalesDocument: propSale,
        },
      }),
    );

    await dispatch(after(invoice, propSale));
  };
}

export const pickupInvoice = withProgressAlert('unpaidInvoice:pickup.loading')(
  pickupInvoiceBase,
);

export function sendInvoiceByEmail({ invoiceID, enteredAddress }) {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook(
      'onSendInvoiceByEmail',
    )(getState());

    await dispatch(before({ invoiceID, enteredAddress }));
    try {
      dispatch(
        addWarning(i18next.t('payment:confirmView.alerts.sendingEmail'), {
          errorType: 'Receipt email',
          dismissible: false,
          selfDismiss: false,
        }),
      );
      const [fullSaleDoc] = await saleApi.getSalesDocuments({
        invoiceID,
      });
      const defaultEmailInvoiceParams = getDefaultEmailInvoiceParams(
        fullSaleDoc.typeID,
        fullSaleDoc.type,
        fullSaleDoc.invoiceNo,
      )(getState());

      const useReceiptTemplates = getUseReceiptTemplates(getState());
      const templateID = getReceiptTemplateIdByDocType(fullSaleDoc.type)(
        getState(),
      );

      const initialEmailRequests = [
        {
          ...defaultEmailInvoiceParams,
          templateID: useReceiptTemplates ? Number(templateID) : 0,
          salesDocID: Number(invoiceID),
          recipientEmail: enteredAddress,
        },
      ];
      const { emailRequests } = await dispatch(
        on(
          { invoiceID, enteredAddress },
          { emailRequests: initialEmailRequests, fullSaleDoc },
        ),
      );
      const response = await sendSalesDocumentByEmail(emailRequests);
      if (response[0].error) throw new Error(response[0].error);
      dispatch(dismissType('Receipt email'));
      dispatch(
        addSuccess(
          i18next.t('payment:confirmView.alerts.sendingEmail', {
            context: 'done',
          }),
          {
            errorType: 'Receipt email',
            selfDismiss: true,
          },
        ),
      );
      await dispatch(after({ invoiceID, enteredAddress }));
    } catch (err) {
      console.warn('Failed to send invoice by email', err);
      dispatch(dismissType('Receipt email'));
      dispatch(
        addError(
          i18next.t('payment:confirmView.alerts.sendingEmail', {
            context: 'failed',
          }),
          {
            dismissible: false,
            selfDismiss: true,
            errorType: 'Receipt email',
          },
        ),
      );
    }
  };
}

/* Order cancellation - throws to payment screen instantly */
export function cancelOrderAction(sale) {
  return async dispatch => {
    dispatch(dismissAll());

    const customerPaid =
      Number(sale.paid) +
      (sale.returnedPayments?.map(p => Number(p.sum)).reduce(add, 0) ?? 0);

    const [customer] = await getCustomers({
      customerID: sale.clientID,
      getBalanceInfo: 1,
      getBalanceWithoutPrepayments: 1,
    });
    return dispatch(
      openPaymentModal({
        props: {
          isReturn: true,
          ignoreCurrent: true,
          payButtonClicked: true,
          salesDocument: {
            clientID: sale.clientID,
            modifiedRows: [],
            total: -sale.total,
            type: 'ORDER',
            invoiceState: 'CANCELLED',
            id: sale.id,
          },
          currentSalesDocument: sale,
          customer,
          total: -customerPaid,
          originalPayments: await saleApi.getPayments({ documentID: sale.id }),
        },
      }),
    );
  };
}
