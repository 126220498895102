import axios from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

type Timeout = number;

type PaymentData = {
  transactionType: 'SALE' | 'VOID' | 'REFUND';
  amount: string;
  requestID: string;
  clientCode: string;
  employeeID: string;
  invoiceNumber?: string;
  referenceNumber?: string;
};

const getDeviceTimeout = async () => {
  const endpoint =
    getMSEndpointFromLS('moneris') || 'https://localhost.erply.com:10109';

  try {
    const timeout = await axios.get(`${endpoint}api/v1/config`).then(res => {
      const result = res?.data?.records?.[0];
      if (!result) return 80e3;
      const parsedJSON = JSON.parse(result.configJSON ?? '{}');
      const { DeviceTimeout: terminalTimeout } = parsedJSON;

      // If the timeout is not defined or is 0, fallback to 60 + 20
      return ((terminalTimeout || 60) + 20) * 1000;
    });
    return timeout;
  } catch {
    // If get config request failed, return 60 + 20 seconds timeout (60 is default/fallback in MS + 20 buffer)
    return 80e3;
  }
};

const getAxiosInstance = (timeout: Timeout) => {
  const endpoint =
    getMSEndpointFromLS('moneris') || 'https://localhost.erply.com:10109/';
  const instance = axios.create({
    baseURL: `${endpoint}api/v1/`,
    timeout,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return instance;
};

const pingTerminal = async () => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout)
    .get('testConnection')
    .then(async response => {
      return (
        response.data.records[0].transactionStatus === 'Success' &&
        response.data.records[0].resultCode === '0'
      );
    });
};

const cancelPayment = async () => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout).post('manage', {
    manageType: 'cancelTransaction',
  });
};

const status = async () => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout)
    .post('manage', { manageType: 'Status' })
    .then(res => res.data);
};

const activateTerminal = async credentials => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout).post('manage', {
    manageType: 'ActivateTerminal',
    data: JSON.stringify(credentials),
  });
};

const closeDay = async () => {
  const timeout = await getDeviceTimeout();
  try {
    const reconcilation = await getAxiosInstance(timeout).get('closeBatch');
    if (reconcilation && reconcilation.data.records.length) {
      const result = reconcilation.data.records[0];
      if (
        result.transactionStatus === 'Operation succeeded' ||
        result.transactionStatus === 'Approved'
      ) {
        return true;
      }
      throw new Error('Error while reconciling payments.');
    }
  } catch (error) {
    console.error('Failed to close batch for Moneris', error);
    return false;
  }
  return true;
};

const doPayment = async (cardPayment: PaymentData) => {
  const timeout = await getDeviceTimeout();
  return getAxiosInstance(timeout)
    .post('payment', cardPayment)
    .then(res => res.data);
};
export const MONERIS = {
  ping: () => pingTerminal().then(result => result),
  startPayment: payment => doPayment({ transactionType: 'SALE', ...payment }),
  voidPayment: payment => doPayment({ transactionType: 'VOID', ...payment }),
  startRefund: payment => doPayment({ transactionType: 'REFUND', ...payment }),
  cancel: () => cancelPayment(),
  status: () => status(),
  activateTerminal: credentials => activateTerminal(credentials),
  closeDay: () => closeDay().then(a => a),
};
