import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
} from '@material-ui/core';

import CloseButton from 'components/CustomButtons/CloseButton';
import { fetchUnpaidInvoices, pickupInvoice } from 'actions/sales';
import {
  getDateFormatter,
  getCurrencyFormatterNoSymbol,
} from 'reducers/configs/settings';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { useAppDispatch } from 'reducers';

import { Search, ScrollableTableContainer } from './components';
import { PluginComponent } from 'plugins';

export default ({
  sales: initialSales,
  onClose,
}: {
  sales: SaleDocumentResponse[];
  onClose(): void;
}) => {
  const { t } = useTranslation('unpaidInvoice');
  const dispatch = useAppDispatch();
  const [sales, setSales] = useState<typeof initialSales>(initialSales);

  const formatDate = useSelector(getDateFormatter);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);

  const limitToSearch = useCallback(
    numberOrCustomer => {
      if (numberOrCustomer !== null)
        dispatch(fetchUnpaidInvoices({ numberOrCustomer })).then(setSales);
    },

    [dispatch],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="0.75rem"
      padding="1.25rem"
      overflow="hidden"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{t('title')}</Typography>
        <CloseButton action={onClose} />
      </Box>
      <Divider />
      <Search onSubmit={limitToSearch} />
      <ScrollableTableContainer>
      <PluginComponent
        hookname="UIUnpaidInvoices"
        props={{ sales }}
        >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('headers.number')}</TableCell>
              <TableCell>{t('headers.date')}</TableCell>
              <TableCell>{t('headers.customer')}</TableCell>
              <TableCell>{t('headers.email')}</TableCell>
              <TableCell>{t('headers.paid')}</TableCell>
              <TableCell>{t('headers.sum')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sales.map(sale => (
              <TableRow
                onClick={() => dispatch(pickupInvoice(sale))}
                key={sale.id}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{sale.number}</TableCell>
                <TableCell>{formatDate(sale.date)}</TableCell>
                <TableCell>{sale.clientName}</TableCell>
                <TableCell>{sale.clientEmail}</TableCell>
                <TableCell>{CURR.stringify(sale.paid)}</TableCell>
                <TableCell>{CURR.stringify(sale.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </PluginComponent>
      </ScrollableTableContainer>
    </Box>
  );
};
