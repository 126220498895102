import { assoc } from 'ramda';

import {
  getCustomerGroups,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getClientCode } from 'reducers/Login';
import { updateProductContainerOverride } from 'actions/ShoppingCart';
import { getProductsUniversal } from 'actions/productsDB';
import { PosPlugin } from 'plugins/plugin';
import { getItemsFromSO } from 'services/DB';
import { SO } from 'services/DB/types';
import { Customer } from 'types/Customer';
import { Product } from 'types/Product';

interface Rule {
  price: string;
  id: number;
  ruleID: number;
  type: string;
}

interface PriceList {
  active: string;
  added: number;
  addedByUserName: string;
  endDate: string;
  lastModified: number;
  lastModifiedByUserName: string;
  name: string;
  pricelistID: number;
  startDate: number;
  pricelistRules: Rule[];
}

const getCustomerPriceListIDs = (
  customer: Customer,
  customerGroups,
): number[] => {
  if (!customer || !customerGroups.length) return [];
  return [
    Number(customer.priceListID),
    Number(customer.priceListID2),
    Number(customer.priceListID3),
    customerGroups.find(
      group => Number(group.customerGroupID) === Number(customer.groupID),
    )?.pricelistID,
    customerGroups.find(
      group => Number(group.customerGroupID) === Number(customer.groupID),
    )?.pricelistID2,
    customerGroups.find(
      group => Number(group.customerGroupID) === Number(customer.groupID),
    )?.pricelistID3,
    customerGroups.find(
      group => Number(group.customerGroupID) === Number(customer.groupID),
    )?.pricelistID4,
    customerGroups.find(
      group => Number(group.customerGroupID) === Number(customer.groupID),
    )?.pricelistID5,
  ].filter(id => id !== 0);
};

const getCustomerPriceListRules = (
  priceLists: PriceList[],
  customerPricelistIDs: number[],
): Rule[] => {
  if (!customerPricelistIDs.length) return [];
  return priceLists
    .filter(priceList => customerPricelistIDs.includes(priceList.pricelistID))
    .filter(pricelist =>
      pricelist?.pricelistRules?.filter(rule => rule.type === 'PRODUCT'),
    )
    .flatMap(pricelist => pricelist?.pricelistRules)
    .sort((a, b) => (Number(a.price) > Number(b.price) ? -1 : 1));
};

const checkZeroPrice = (
  prod: Product,
  customerPricelistRules: Rule[],
): boolean =>
  !!customerPricelistRules.find(
    rule =>
      Number(rule.id) === Number(prod.containerID) && Number(rule.price) === 0,
  );

const removeCoreChangeWhenCustomerHasPricelist: PosPlugin = {
  id: 'exempt-customer-from-core-charge',
  name: '[PNP] - Exempt customer from core charge',
  info: 'Exempts PnP customers with special price lists from core charge.',
  keywords: ['pnp', 'core-charge', 'customers', 'exempt'],
  onAddProduct: {
    on: (props, { product, options, ...rest }) => async (dispatch, getState) => {
      const state = getState();
      const clientCode = getClientCode(state);
      const customerGroups = getCustomerGroups(state);
      const customer = getSelectedCustomer(state);

      const {
        products: [productCard],
      } = (await dispatch(
        getProductsUniversal(
          { productID: product.productID },
          { withMeta: true },
        ),
      )) as any;

      const customerPricelistIDs = getCustomerPriceListIDs(
        customer,
        customerGroups,
      );

      const priceLists: PriceList[] = await getItemsFromSO(
        clientCode,
        SO.PRICE_LIST.NAME,
        customerPricelistIDs,
      );

      const customerPricelistRules = getCustomerPriceListRules(
        priceLists,
        customerPricelistIDs,
      );
      if (checkZeroPrice(productCard, customerPricelistRules)) {
        return {
          ...rest,
          product: assoc('addContainerOverride', false)(product),
          options,
        };
      }
      return { ...rest, product, options };
    },
  },
  onSetCustomer: {
    after: () => async (dispatch, getState) => {
      const state = getState();
      const clientCode = getClientCode(state);
      const customerGroups = getCustomerGroups(state);
      const customer = getSelectedCustomer(state);
      const shoppingCartProducts = getProductsInShoppingCart(state);

      const { products: productCards } = (await dispatch(
        getProductsUniversal({
          productIDs: shoppingCartProducts.map(p => Number(p.productID)),
        }),
      )) as any;

      const productCardsDict = Object.fromEntries(
        productCards.map(p => [p.productID, p]),
      );

      const customerPricelistIDs = getCustomerPriceListIDs(
        customer,
        customerGroups,
      );

      const priceLists: PriceList[] = await getItemsFromSO(
        clientCode,
        SO.PRICE_LIST.NAME,
        customerPricelistIDs,
      );

      const customerPricelistRules = getCustomerPriceListRules(
        priceLists,
        customerPricelistIDs,
      );

      shoppingCartProducts.forEach(product => {
        if (product?.productID) {
          if (
            checkZeroPrice(
              productCardsDict[product.productID],
              customerPricelistRules,
            )
          ) {
            dispatch(updateProductContainerOverride(product.orderIndex, false));
          } else {
            dispatch(
              updateProductContainerOverride(product.orderIndex, undefined),
            );
          }
        }
      });
    },
  },
};

export default removeCoreChangeWhenCustomerHasPricelist;
