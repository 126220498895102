import { ThunkDispatch } from 'redux-thunk';

import { getHasProductsInShoppingCart } from 'reducers/ShoppingCart';
import { RootState } from 'reducers';

import { setSAINumber, setScannedQuery } from '../redux/actions';
import { Action } from '../redux/types';

const resetState = () => async (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
  getState: () => RootState,
) => {
  const hasProductsInCart = getHasProductsInShoppingCart(getState());

  if (hasProductsInCart) return;

  dispatch(setSAINumber(null));
  dispatch(setScannedQuery(null));
};

export default resetState;
