import { getActivePricelistIDsForPOS } from 'reducers/cachedItems/priceLists';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getPluginConfiguration } from 'reducers/Plugins';
import constants from 'plugins/pnp/relatedItems/constants';
import { getCachedItemsPerTypeByIDs } from 'reducers/cachedItems';
import { SO } from 'services/DB/types';
import { PriceList } from 'types/PriceList';

import { RelatedProdPlugConfig } from './constants';

export const formatPrice = value =>
  value
    .replace(/[^0-9.]/g, '')
    .split('.')
    .slice(0, 2)
    .map((e, i) => (i === 1 ? e.slice(0, 2) : e))
    .join('.');

export const mergeLevel: (
  mergeFrom: RelatedProdPlugConfig,
  mergeTo: any,
) => any = (mergeFrom = {}, mergeTo = {}) => {
  const result = { ...mergeTo };
  Object.entries(mergeFrom).forEach(([key, value]: [string, any]) => {
    result[key] = mergeTo[key] ?? {};
    if (value?.defaultKitItemPrice) {
      result[key].defaultKitItemPrice = value.defaultKitItemPrice;
    }
    Object.entries(value?.customPrices ?? {}).forEach(
      ([sKey, sValue]: [string, any]) => {
        result[key].customPrices = {
          ...(mergeTo[key]?.customPrices ?? {}),
          [sKey]: sValue,
        };
      },
    );
  });
  return result;
};

/**
 * Get the pricelistprice of the given product for the currently selected customer
 * For volume buyers, this will return the customer price if this product has a volume buyer discount
 * For other products or customers, this should return undefined
 * (assumption: Customer price lists only used for volume buyers)
 */
export const getCustomerPricelistPrice = productID => (
  state,
): number | undefined => {
  const customer = getSelectedCustomer(state);
  const customerPriceLists = getCachedItemsPerTypeByIDs<PriceList>(
    SO.PRICE_LIST.NAME,
    [customer.priceListID, customer.priceListID2, customer.priceListID3],
  )(state);
  const customerPriceList = Object.values(customerPriceLists)?.[0];
  const activePricelistIDs = getActivePricelistIDsForPOS(state);
  const priceListIDs = new Set<number>(
    activePricelistIDs.map(id => Number(id)),
  );

  const locationPricelist = Object.values(
    getCachedItemsPerTypeByIDs<PriceList, number>(
      SO.PRICE_LIST.NAME,
      Array.from(priceListIDs),
    )(state),
  )?.[0];

  const customerPriceListPrice = customerPriceList?.pricelistRules.find(
    rule => Number(rule.id) === Number(productID),
  )?.price;

  const locationPriceListPrice = locationPricelist?.pricelistRules.find(
    rule => Number(rule.id) === Number(productID),
  )?.price;

  return Number(customerPriceListPrice ?? locationPriceListPrice);
};

/**
 * Get the configured kitted price for the given combination of parent and child product
 * If configured, returns the price, else returns undefined
 */
export const getProductKittedItemPrice = (
  parentProductID: string,
  childProductID: string,
) => (state): number | undefined => {
  const configuration = getPluginConfiguration<{ [key: string]: any }>(
    constants.plugin.id,
  )(state)[parentProductID];

  const productKitPrice = configuration?.customPrices?.[childProductID];
  const defaultKitPrice = configuration?.defaultKitItemPrice;
  // Productkitprice does not apply if negative
  return 0 <= productKitPrice ? productKitPrice : defaultKitPrice;
};
