/* eslint-disable */
import React, { useState, useEffect, useMemo, FC, useCallback } from 'react';

import './index.css';
import { useSize } from 'utils/hooks/UI';
import { sleep } from 'utils';
import { getUISetting } from '../../reducers/configs/settings';
import { useSelector } from 'react-redux';

/**
 * @callback MeasuredCallback
 * Called when <MeasureSizes> has finished measuring
 * @param {Object} measurements
 * @param {number} measurements.width Total width available to this component
 * @param {number} measurements.oneWidth The width taken up by one ItemComponent and the padding on one side of it
 * @param {number} measurements.oneHeight The width taken up by one ItemComponent and the padding on one side of it
 */

/** Renders the given ItemComponent in a few different layouts simultaneously
 * to measure its size and padding
 * When measurements have been made, calls the setMeasured function with the results
 * @callback {() => {}} params.setMeasured foobar
 * */
const MeasureSizes = ({ ItemComponent, setMeasured }) => {
  const [totalWidth, setTotalWidth] = useState<number | null>(null);
  const [oneWidth, setSingleWidth] = useState<number | null>(null);
  const [oneHeight, setSingleHeight] = useState<number | null>(null);

  useEffect(() => {
    if (totalWidth && oneWidth && oneHeight) {
      setMeasured({
        width: totalWidth - 1,
        oneWidth,
        oneHeight,
      });
    }
  }, [totalWidth, oneWidth, oneHeight]);

  const measure = what => func => async el => {
    if (!el) return null;

    const value = parseFloat(window.getComputedStyle(el)[what]);
    while (value === 0) {
      await sleep(0);
    }
    func(value + 1);
  };
  const measureWidth = measure('width');
  const measureHeight = measure('height');
  return (
    <div ref={measureWidth(setTotalWidth)}>
      <div
        style={{ display: 'inline-flex', flexDirection: 'column' }}
        ref={el => {
          measureHeight(setSingleHeight)(el);
          measureWidth(setSingleWidth)(el);
        }}
      >
        <ItemComponent />
      </div>
    </div>
  );
};

type Measurement = {
  width: number;
  oneWidth: number;
  oneHeight: number;
};

export const useMeasurement = (
  { targetHeight, component },
  dependencies: unknown[] = [],
) => {
  const [measurements, setMeasurements] = useState<Measurement | null>(null);

  const { width: w } = useSize(200);
  const isFullScreen = useSelector(getUISetting('isFullScreen'));
  useEffect(() => {
    setMeasurements(null);
  }, [component, w, targetHeight, isFullScreen, ...dependencies]);

  const memoizedComponent = React.memo(component);

  if (!measurements) {
    return {
      needsToMeasure: true,
      Measure: (
        <MeasureSizes
          ItemComponent={memoizedComponent}
          setMeasured={setMeasurements}
        />
      ),
    };
  }

  const { width, oneWidth, oneHeight } = measurements;

  return {
    needsToMeasure: false,
    Measure: (
      <MeasureSizes ItemComponent={component} setMeasured={setMeasurements} />
    ),
    height: Math.floor(targetHeight / Math.ceil(oneHeight)),
    width: Math.floor(width / Math.ceil(oneWidth)),
  };
};

/**
 * @callback ListCallback
 * @param {number} lines The number of lines this List component has grown to
 * @param {number} items The number of items this List component can fit
 */

/**
 * List component used to create a grid view
 * @param {Object} props
 * @param {any[]} props.items Array of objects to display in the list
 * @param {any} props.itemComponent Component to use to display the passed in items
 * items will be passed as 'item' props to the component
 * component must be passed in rendered (<Foo/>), not as a component (Foo) or (() => <Foo/>)
 * @param {any[]} props.specialBttnsStart Array of objects to display at the very start
 * @param {any[]} props.specialBttnsEnd Array of objects to display at the end, regardless of how many items are actually passed
 * @param {any[]} props.specialBttnsEndIfFull An array of objects to display at the end instead of specialBttnsEnd,
 * only if there are enough items that they would not all fit in the list
 * @param {number} props.minLines Minimum number of lines to display
 * @param {number} props.maxLines Maximum number of lines to display - extra items will be omitted
 * @param {ListCallback} props.onSetNrLines A callback called to inform the parent how many lines this List is actually using and how many items it has space for
 * */

type ListProps = {
  specialBttnsStart?: any[];
  specialBttnsEnd?: any[];
  specialBttnsEndIfFull?: any[];
  items: any[];
  minLines?: number;
  maxLines?: number;
  onSetNrLines?: (finalRows: number, finalItemsCount: number) => void;
  itemComponent;
  gridTypeOpened?: string;
  measured: Partial<Measurement>;
  beforeSelect?: () => void;
};

const List: FC<ListProps> = ({
  specialBttnsStart = [],
  specialBttnsEnd = [],
  specialBttnsEndIfFull = [],
  items,
  minLines: minLines_unsanitized = 1,
  maxLines: maxLines_unsanitized = Infinity,
  onSetNrLines = () => {},
  itemComponent,
  gridTypeOpened,
  measured,
  beforeSelect,
}) => {
  const minLines = Math.max(0, minLines_unsanitized);
  const maxLines = Math.max(0, maxLines_unsanitized);
  // Input sanitisation
  const castToArray = item => (item.length === undefined ? [item] : item);
  specialBttnsStart = castToArray(specialBttnsStart);
  specialBttnsEnd = castToArray(specialBttnsEnd);
  specialBttnsEndIfFull = castToArray(specialBttnsEndIfFull);
  items = castToArray(items);

  const { width = 0, oneWidth = 0 } = measured;
  // Only update the key prefix when items changes
  const keyPrefix = useMemo(() => Math.random(), [items]);

  const nrSpec = specialBttnsStart.length + specialBttnsEnd.length;
  const nrSpecFull =
    specialBttnsStart.length +
    (specialBttnsEndIfFull.length || specialBttnsEnd.length);
  const nrItems = items.length;
  const nrTotal = nrItems + nrSpec;
  const perLine = Math.floor(width / oneWidth);

  const linesRequired = Math.ceil(nrTotal / perLine);
  const finalRows = Math.max(Math.min(linesRequired, maxLines), minLines);
  const itemsMissing = Math.max(finalRows * perLine - nrTotal);

  items = [
    ...items,
    ...Array(Math.max(0, itemsMissing)).fill({ disabled: true }),
  ];
  // ↑ Slice in case itemsMissing is negative (more items than space)

  useEffect(() => {
    const productCountOnPage = finalRows * perLine - nrSpecFull;
    if (gridTypeOpened === 'mixedview') {
      localStorage.setItem('productCountOnPage', productCountOnPage.toString());
    }
    onSetNrLines(finalRows, productCountOnPage);
  }, [finalRows, finalRows * perLine - nrSpecFull]);

  // Add conditional end buttons if not enough space
  if (itemsMissing < 0) {
    const overflow = -itemsMissing;
    if (specialBttnsEndIfFull.length > 0) {
      const newSpaceFromSwitch =
        specialBttnsEnd.length - specialBttnsEndIfFull.length;
      items = items.slice(0, items.length - overflow + newSpaceFromSwitch);
      specialBttnsEnd = specialBttnsEndIfFull;
    } else {
      items = items.slice(0, items.length - overflow);
    }
  }

  const buttons = useMemo(
    () => [...specialBttnsStart, ...items, ...specialBttnsEnd],
    [specialBttnsStart, items, specialBttnsEnd],
  );

  const components = useMemo(
    () =>
      buttons.map((item, j) =>
        React.cloneElement(itemComponent, {
          item,
          key: `${keyPrefix} ${j}`,
        }),
      ),
    [buttons, itemComponent],
  );
  /**
   * Using keyPrefix in the keys ensures that as long as the input items have
   * not changed, react knows not to rerender any children
   * However, as soon as the items change, all elements are invalidated
   * thus preventing cases where deletion in the middle of a list causes issues
   */
  return (
    <>
      {Array(finalRows)
        .fill(0)
        .map((_, i) => (
          <div
            style={{ display: 'flex', flexWrap: 'wrap' }}
            key={`${keyPrefix} ${i}`}
            onClick={beforeSelect}
          >
            {components.slice(i * perLine, (i + 1) * perLine)}
          </div>
        ))}
    </>
  );
};

const SelfMeasuringList: FC<Omit<ListProps, 'measured'>> = props => {
  const [measured, setMeasured] = useState<Partial<Measurement>>({});
  const isFullScreen = useSelector(getUISetting('isFullScreen'));
  const { width: windowWidth, height: windowHeight } = useSize(200);

  const componentClone = useCallback(
    () => React.cloneElement(props.itemComponent, { item: {} }),
    [props.itemComponent],
  );

  const totalItemsLength =
    props.items.length +
    (props.specialBttnsStart?.length ?? 0) +
    (props?.specialBttnsEnd?.length ?? 0);

  useEffect(() => {
    if (props.items.length > 0) {
      const cleared = { ...measured };
      delete cleared.width;
      setMeasured(cleared);
    }
  }, [totalItemsLength, windowWidth, isFullScreen]);

  if (!measured.width) {
    return (
      <MeasureSizes ItemComponent={componentClone} setMeasured={setMeasured} />
    );
  }
  return <List measured={measured} {...props} />;
};

// TODO: Make List easier to use by having memo check deep equality (rather than referential)
// and by accepting a component function instead of a rendered component
export default React.memo(SelfMeasuringList);
