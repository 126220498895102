/* eslint-disable no-lone-blocks */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable eqeqeq */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { createConfirmation } from 'actions/Confirmation';
import {
  getSetting,
  getCurrencyFormatterNoSymbol,
  getCheckBehavior,
} from 'reducers/configs/settings';
import {
  deletePayment,
  setPaymentEditValue,
  updateCurrency,
  setBoardCard,
  removeCardTokenInPayment,
  setCardTokenInPayment,
} from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { getAllCurrencies } from 'reducers/configs/currency';
import {
  getPayments,
  getPaymentsTotal,
  getPaymentSelected,
  getBalance,
  getTip,
  getChange,
  getOriginalPayments,
} from 'reducers/Payments';
import {
  getActivePaymentIntegration,
  getIsCardBoardingEnabled,
} from 'reducers/cafaConfigs';
import {
  getIsDefaultCustomer,
  getSelectedCustomerMountedCardTokenAttribute,
} from 'reducers/customerSearch';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { PluginComponent } from 'plugins';
import { addError } from 'actions/Error';

import styles from './PaymentList.module.scss';

const PaymentList = () => {
  const dispatch = useDispatch();
  const tip = useSelector(getTip);
  const total = useSelector(getPaymentsTotal);
  const currencies = useSelector(getAllCurrencies);

  const CURR = useSelector(getCurrencyFormatterNoSymbol);
  const balance = useSelector(getBalance);
  const change = useSelector(getChange);
  const payments = useSelector(getPayments);
  const shouldShowExpectedChange = useSelector(
    getSetting('pos_brazil_show_expected_change_in_payment_modal'),
  );

  const checkBehaviour = useSelector(getCheckBehavior);
  const isReturn = total < 0;
  const hasOriginalPayments = 0 < useSelector(getOriginalPayments).length;
  const isReferencedReturn = total < 0 && hasOriginalPayments;

  const paymentSelected = useSelector(getPaymentSelected);
  const { t } = useTranslation('payment');
  const cardTypeOnExternal = useSelector(state =>
    getSetting('touchpos_require_card_type_on_external_payment')(state),
  );
  const posIntegration = useSelector(getActivePaymentIntegration);
  const allowFallbackToExternal = useSelector(state =>
    getSetting('allow_fallback_to_external_integration')(state),
  );
  const allowManualIntegration =
    allowFallbackToExternal ||
    (posIntegration === 'pcEftpos' && cardTypeOnExternal);

  const hideCardNumber = (cardNumber = '') =>
    `${cardNumber
      .substring(0, cardNumber.length - 4)
      .replace(/\S/g, '*')}${cardNumber.substring(cardNumber.length - 4)}`;

  const { before, on, after } = useSelector(
    getPluginLifecycleHook('onPaymentClick'),
  );

  const isCardBoardingEnabled = useSelector(getIsCardBoardingEnabled);
  // get the mounted card cardToken and mounted status
  const { cardToken = null, mounted = false } =
    useSelector(getSelectedCustomerMountedCardTokenAttribute) ?? {};
  // vault btn is enabled when vault option is enabled and there is a boarded and mounted card
  const isPayWithBoardedCardBtnEnabled = isCardBoardingEnabled && mounted;
  const isDefaultCustomer = useSelector(getIsDefaultCustomer);

  const handleIntegrationSwitch = async (paymentKey, payment) => {
    const selectedIntegration =
      payment.paymentIntegration === 'external' ? posIntegration : 'external';

    const paymentInfo = {
      ...payment,
      key: paymentKey,
      paymentIntegration: selectedIntegration,
    };
    if (selectedIntegration == 'external') {
      paymentInfo.boardCard = false;
      paymentInfo.cardToken = undefined;
    }

    await dispatch(setPayment(paymentInfo));
    dispatch(setPaymentSelected(''));
  };

  const handlePaymentItemClick = async (paymentKey, payment) => {
    try {
      await dispatch(before({ paymentKey, payment }));
    } catch (e) {
      return;
    }

    const isPaid = payment.type === 'PAID' || payment.paid;
    const isLocked = payment.type === 'LOCKED' || payment.locked;
    const selected = payments[paymentKey] || {};
    if (isPaid || isLocked) {
      return;
    }

    try {
      await dispatch(on({ paymentKey, payment }));
    } catch (e) {
      return;
    }
    // Giftcard
    if (payment.type === 'GIFTCARD') {
      await new Promise((resolve, reject) => {
        dispatch(
          createConfirmation(resolve, reject, {
            title: t('alerts.removeGiftcard', { context: 'title' }),
            body: (
              <span className="text-center h5">
                <Trans
                  i18nKey={`payment:alerts.removeGiftcard${
                    payment.serial ? 'Serial' : 'Regular'
                  }`}
                  tOptions={{
                    context: 'body',
                    serial: payment.serial,
                    amount: payment.amount,
                  }}
                >
                  <b />
                </Trans>
              </span>
            ),
          }),
        );
      })
        .then(() => {
          (async () => {
            dispatch(deletePayment({ key: paymentKey }));
            await dispatch(setPaymentEditValue(''));
          })();
        })
        .catch(error =>
          console.error('Failed to remove gift card payment', error),
        );
      // This part is used for deletion
    } else if (payment.type === 'CHECK') {
      await new Promise((resolve, reject) => {
        dispatch(
          createConfirmation(resolve, reject, {
            title: t('alerts.removePayCheck', { context: 'title' }),
            body: (
              <span className="text-center h5">
                <Trans
                  i18nKey="payment:alerts.removePayCheck"
                  tOptions={{
                    context: 'body',
                    amount: payment.amount,
                  }}
                >
                  <b />
                </Trans>
              </span>
            ),
          }),
        );
      }).then(() => {
        (async () => {
          dispatch(deletePayment({ key: paymentKey }));
          await dispatch(setPaymentEditValue(''));
        })();
      });
    } else {
      (async () => {
        if (selected.amount == '0.00')
          dispatch(deletePayment({ key: paymentKey }));

        const currency = currencies.find(
          cur => String(cur.code) === String(payment.currencyCode),
        );
        if (currency) {
          dispatch(updateCurrency(currency));
        }
        await dispatch(setPaymentSelected(paymentKey));
        await dispatch(
          setPayment({
            ...payment,
            amount: '0.00',
            key: paymentKey,
          }),
        );

        await dispatch(setPaymentEditValue(''));
      })();
    }
    try {
      await dispatch(after({ paymentKey, payment }));
    } catch (e) {
      // Plugin errors don't get logged
    }
  };

  return (
    <PluginComponent hookname="UICustomPaymentList">
      <div data-testid="payment-list" className={styles['payment-list']}>
        <div data-testid="payment-total" className="payment-total">
          <span
            data-testid="payment-total-label"
            className="payment-total-label"
          >
            {t('bill.total', {
              context: Math.abs(tip) > 0 ? 'tip' : undefined,
            })}
          </span>
          <span
            data-testid="payment-total-value"
            className="payment-total-value pull-right"
          >
            {CURR.stringify(total + tip)}
          </span>
        </div>
        <div className="payments" data-testid="payments">
          <ul className="payment-list-group">
            {payments
              ? Object.keys(payments).map(paymentKey => {
                  // const paymentKey = Object.keys(payments);
                  const payment = payments[paymentKey];
                  const isPaid = payment.type === 'PAID' || payment.paid;
                  const isLocked = payment.type === 'LOCKED' || payment.locked;
                  const shouldShowCheckNumber =
                    payment.type === 'CHECK' &&
                    payment.attributes.refNo !== undefined &&
                    checkBehaviour === 'NEW';

                  const isOtherVaultConditionsEnabled =
                    !isReturn &&
                    payment.caption === 'CARD' &&
                    payment.paymentIntegration !== 'external' &&
                    !isPaid &&
                    !isLocked;
                  return (
                    <li
                      key={paymentKey}
                      className="payment-item"
                      data-testid="payment-item"
                      data-test-key={paymentKey}
                      data-test-paid={payment.paid}
                      data-test-locked={payment.locked}
                      data-test-type={payment.type}
                      data-test-serial={!!payment.serial} // Requested to be boolean by Daniel
                    >
                      <div
                        style={{
                          cursor: isPaid || isLocked ? 'auto' : 'pointer',
                        }}
                        tabIndex={0}
                        className={classNames('button', {
                          active: paymentKey === paymentSelected,
                        })}
                        data-testid="payment-item-container"
                        onClick={() =>
                          handlePaymentItemClick(paymentKey, payment)
                        }
                        role="button"
                      >
                        <div className="type" data-testid="payment-type">
                          <span data-testid="payment-caption">
                            {payment.caption}
                          </span>
                          <PluginComponent hookname="UIPaymentCurrency">
                            <span className="small">
                              {payment.currencyCode}
                            </span>
                          </PluginComponent>
                          <span data-testid="payment-card-number">
                            {hideCardNumber(payment.cardNumber)}
                          </span>
                          {isLocked ? (
                            <span
                              data-testid="payment-is-locked"
                              className="locked-indicator"
                            >
                              {t('bill.locked')}
                            </span>
                          ) : null}
                          {isPaid ? (
                            <span
                              data-testid="payment-is-paid"
                              className="paid-indicator"
                            >
                              {t('bill.paid')}
                            </span>
                          ) : null}
                          {payment.serial ? (
                            <span data-testid="payment-is serial">
                              {payment.serial}
                            </span>
                          ) : null}
                          {shouldShowCheckNumber ? (
                            <span
                              className="text-muted small"
                              data-testid="check-number"
                            >
                              {t('bill.checkNumber', {
                                checkNumber: payment.attributes.refNo,
                              })}
                            </span>
                          ) : null}
                        </div>

                        <span
                          className={classNames('newButton', {
                            'same-row': !isReferencedReturn,
                          })}
                          data-testid="payment-row-btns"
                        >
                          {allowManualIntegration ? (
                            <>
                              {payment.caption === 'CARD' &&
                                !isPaid &&
                                !isLocked && (
                                  <button
                                    className={classNames('btn', {
                                      'btn-warning':
                                        payment.paymentIntegration ===
                                        'external',
                                      'btn-primary':
                                        payment.paymentIntegration !==
                                        'external',
                                    })}
                                    onClick={e => {
                                      e.stopPropagation();
                                      handleIntegrationSwitch(
                                        paymentKey,
                                        payment,
                                      );
                                    }}
                                  >
                                    {payment.paymentIntegration ??
                                      posIntegration}
                                  </button>
                                )}
                            </>
                          ) : (
                            <></>
                          )}
                          {isCardBoardingEnabled &&
                            isOtherVaultConditionsEnabled && (
                              <button
                                className={classNames('btn boardButton', {
                                  'btn-warning': payment?.boardCard,
                                  'btn-primary': !payment?.boardCard,
                                })}
                                data-testid="board-card-btn"
                                onClick={async e => {
                                  e.stopPropagation();
                                  if (isDefaultCustomer) {
                                    dispatch(
                                      addError(
                                        t(
                                          'alerts.boardCardWithDefaultCustomerError',
                                        ),
                                        {
                                          dismissible: true,
                                          selfDismiss: 2000,
                                        },
                                      ),
                                    );
                                    return;
                                  }
                                  const updatedStatus = !payment.boardCard;
                                  dispatch(
                                    setBoardCard({
                                      flag: updatedStatus,
                                      key: paymentKey,
                                    }),
                                  );
                                  if (payment.cardToken) {
                                    dispatch(
                                      removeCardTokenInPayment({
                                        key: paymentKey,
                                      }),
                                    );
                                  }
                                }}
                              >
                                {t('tenders.boardCard')}
                              </button>
                            )}

                          {isPayWithBoardedCardBtnEnabled &&
                            isOtherVaultConditionsEnabled && (
                              <button
                                className={classNames('btn boardButton', {
                                  'btn-warning': payment?.cardToken,
                                  'btn-primary': !payment?.cardToken,
                                })}
                                data-testid="use-token-btn"
                                onClick={async e => {
                                  e.stopPropagation();
                                  if (payment?.cardToken) {
                                    dispatch(
                                      removeCardTokenInPayment({
                                        key: paymentKey,
                                      }),
                                    );
                                  } else {
                                    dispatch(
                                      setCardTokenInPayment({
                                        cardToken,
                                        key: paymentKey,
                                      }),
                                    );
                                    if (payment.boardCard) {
                                      dispatch(
                                        setBoardCard({
                                          flag: false,
                                          key: paymentKey,
                                        }),
                                      );
                                    }
                                  }
                                }}
                              >
                                {t('tenders.useToken')}
                              </button>
                            )}
                        </span>

                        <span data-testid="payment-amount" className="amount">
                          {' '}
                          {CURR.stringify(payment.amount)}
                        </span>
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
        {shouldShowExpectedChange && (
          <div className="payment-balance" data-testid="payment-change">
            <span
              data-testid="payment-change-label"
              className="payment-change-label"
            >
              {t('bill.balance', { context: 'over' })}
            </span>
            <span
              data-testid="payment-change-value"
              className="payment-change-value pull-right"
            >
              {CURR.stringify(change)}
            </span>
          </div>
        )}
        <div className="payment-balance" data-testid="payment-balance">
          <span
            data-testid="payment-balance-label"
            className="payment-balance-label"
          >
            {t('bill.balance')}
          </span>
          <span
            data-testid="payment-balance-value"
            className="payment-balance-value pull-right"
          >
            {CURR.stringify(balance)}
          </span>
        </div>
      </div>
    </PluginComponent>
  );
};
export default PaymentList;
