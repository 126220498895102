import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import * as R from 'ramda';
import { Card, Table, Button, Tooltip } from '@material-ui/core';
import { useToggle } from 'react-use';
import { Dvr } from '@material-ui/icons';

import {
  getProductsForDisplay,
  getTotals,
} from 'plugins/cayanCustomerDisplay/actions';
import * as API from 'plugins/cayanCustomerDisplay/api';
import { useCayanMicroservice } from 'plugins/cayanCustomerDisplay/ComponentHeader/useCayanMicroservice';
import { useResettingState } from 'plugins/cayanCustomerDisplay/ComponentHeader/useStateWithTimeout';
import { getLastSalesDocument } from 'reducers/sales';
import { PosPlugin } from 'plugins/plugin';
import { HeaderIconButton } from 'containers/Header/components/Icon';

const UNKNOWN = Symbol('UNKNOWN');

export const ComponentHeader: Required<PosPlugin>['ComponentHeader'] = ({
  children,
}) => {
  // Actual state of the POS
  const actualProducts = useSelector(getProductsForDisplay);
  const actualTotals = useSelector(getTotals);
  const lastInvoiceID = useSelector(getLastSalesDocument).salesDoc.invoiceID;

  // State reported to the terminal
  const [reportedOrder, setReportedOrder] = useState<
    string | null | typeof UNKNOWN
  >(UNKNOWN);
  const [reportedProducts, setReportedProducts] = useState<API.CayanItem[]>([]);
  const [reportedTotals, setReportedTotals] = useState({
    OrderTotal: '0.00',
    OrderTax: '0.00',
  });
  const [reportedInvoiceID, setReportedInvoiceID] = useState<string>(
    lastInvoiceID,
  );

  // Internal state
  const isRunning = useCayanMicroservice();
  const [error, setError] = useResettingState('', 2000);
  const [processing, setProcessing] = useState<string | boolean>(false);

  const resetReported = useCallback(() => {
    setReportedOrder(null);
    setReportedProducts([]);
    setReportedTotals({ OrderTotal: '0.00', OrderTax: '0.00' });
  }, []);

  useEffect(() => setReportedOrder(UNKNOWN), [isRunning]);
  // If states do not match, then send a single correction to the terminal
  useEffect(() => {
    /**
     * Closing the transaction will also clear the cart and any other state,
     * so we can start from scratch without worry of desync
     * The API call will only return an error if the transaction is already closed.
     */
    const recoverFromError = () =>
      API.endTransaction('0')
        .catch(() => undefined)
        .then(resetReported);

    // Only run one API command at a time, and wait between errors
    if (processing) return;
    if (error) return;
    if (!isRunning) return;

    // Get the system into a known state
    if (reportedOrder === UNKNOWN) {
      setProcessing('endTransaction');
      recoverFromError().finally(() => setProcessing(''));
      return;
    }

    // Start the order first if not started yet
    const needsOrderOpened = actualProducts.length > 0 && !reportedOrder;
    if (needsOrderOpened) {
      setProcessing('startTransaction');
      API.startTransaction('0')
        .then(
          () => setReportedOrder('0'),
          e => setError(e),
        )
        .finally(() => setProcessing(false));
      return;
    }

    // No order reported and didn't need to be opened, nothing to do
    if (!reportedOrder) return;

    // Order is reported, but needs to be closed
    if (actualProducts.length === 0 && reportedOrder) {
      const successfulEnd = lastInvoiceID !== reportedInvoiceID;
      const i = setTimeout(
        () => {
          setProcessing(successfulEnd ? 'EndTransaction' : 'CancelTransaction');
          (successfulEnd ? API.endTransaction : API.cancelTransaction)('0')
            .catch(() => {
              // Ignore errors, errors happen if trans already closed
              // f.ex. because a payment was successful
            })
            .then(() => {
              resetReported();
              setReportedInvoiceID(lastInvoiceID);
            })
            .finally(() => {
              setProcessing(false);
            });
        },
        successfulEnd ? 0 : 1000,
      );
      // eslint-disable-next-line consistent-return
      return () => {
        clearTimeout(i);
      };
    }

    const needsProductRemoved = reportedProducts.find(
      (item, i) => actualProducts[i]?.ItemID !== item.ItemID,
    );
    if (needsProductRemoved) {
      setProcessing('deleteItem');
      API.deleteItem(reportedOrder, needsProductRemoved.ItemID, actualTotals)
        .then(() => {
          setReportedProducts(R.without([needsProductRemoved]));
          setReportedTotals(actualTotals);
        }, recoverFromError)
        .finally(() => setProcessing(false));
      return;
    }
    const needsProductAdded = actualProducts.find(
      (order, i) => reportedProducts[i]?.ItemID !== order.ItemID,
    );
    if (needsProductAdded) {
      setProcessing('addItem');
      API.addItem(reportedOrder, needsProductAdded, actualTotals)
        .then(() => {
          setReportedProducts(R.append(needsProductAdded));
          setReportedTotals(actualTotals);
        }, recoverFromError)
        .finally(() => setProcessing(false));
      return;
    }
    const needsProductUpdated = actualProducts.find(
      (order, i) =>
        reportedProducts[i].ItemID === order.ItemID &&
        !R.equals(reportedProducts[i], order),
    ) as any;
    const updateReplaces =
      reportedProducts[actualProducts.indexOf(needsProductUpdated)];
    if (needsProductUpdated) {
      setProcessing('updateItem');
      API.updateItem(
        reportedOrder,
        updateReplaces.ItemID,
        needsProductUpdated,
        actualTotals,
      )
        .then(
          () =>
            setReportedProducts(
              R.update(
                actualProducts.indexOf(needsProductUpdated),
                needsProductUpdated,
              ),
            ),
          recoverFromError,
        )
        .finally(() => setProcessing(false));
      return;
    }

    const totalsNeedUpdating = !R.equals(reportedTotals, actualTotals);
    if (totalsNeedUpdating) {
      setProcessing('updateTotal');
      API.updateTotal(reportedOrder, actualTotals)
        .then(() => setReportedTotals(actualTotals), recoverFromError)
        .finally(() => setProcessing(false));
    }
  }, [
    actualProducts,
    actualTotals,
    lastInvoiceID,
    reportedOrder,
    reportedProducts,
    reportedTotals,
    reportedInvoiceID,
    processing,
    error,
    isRunning,
    resetReported,
    setError,
  ]);

  const [debug, toggleDebug] = useToggle(false);
  return (
    <>
      <Tooltip
        PopperProps={{
          style: {
            whiteSpace: 'pre-line',
          },
        }}
        title={`TSYS/Genius/Cayan customer display\n${
          isRunning
            ? Object.entries({
                order: reportedOrder,
                cart: reportedProducts
                  .map(p => `${p.Quantity}x ${p.Description} (${p.Amount})`)
                  .join('\n'),
                processing,
                error,
              })
                .map(([k, v]) => `===${k}===\n${String(v)}`)
                .join('\n')
            : 'Microservice not running, please wait...'
        }`}
      >
        <li>
          <HeaderIconButton size="small" onClick={toggleDebug}>
            <Dvr
              style={{
                fontSize: '24px',
                color: useMemo(() => {
                  if (!isRunning) return 'gray';
                  if (processing) return 'orange';
                  if (error) return 'salmon';
                  return 'lightgreen';
                }, [isRunning, processing, error]),
              }}
            />
          </HeaderIconButton>
        </li>
      </Tooltip>
      {children}
      <div
        style={{ position: 'relative', display: debug ? undefined : 'none' }}
      >
        <div
          style={{
            position: 'fixed',
            top: 40,
            right: 0,
            display: 'flex',
            flexDirection: 'row',
            opacity: 0.8,
            pointerEvents: 'none',
            zIndex: 9999,
          }}
        >
          <InfoCard
            title="Reported"
            order={reportedOrder}
            products={reportedProducts}
            totals={reportedTotals}
            expectedProducts={reportedProducts}
            expectedTotals={reportedTotals}
            expectedOrder={reportedOrder}
          />
          <InfoCard
            title="Actual"
            order={actualProducts.length ? '0' : null}
            products={actualProducts}
            totals={actualTotals}
            expectedProducts={reportedProducts}
            expectedTotals={reportedTotals}
            expectedOrder={reportedOrder}
          />
        </div>
      </div>
    </>
  );
};

const InfoCard = ({
  title,
  order,
  products,
  totals,
  expectedProducts: eProducts,
  expectedTotals: eTotals,
  expectedOrder: eOrder,
}: {
  title: string;
  order: typeof UNKNOWN | null | string;
  products: ReturnType<typeof getProductsForDisplay>;
  totals: ReturnType<typeof getTotals>;
  expectedProducts: ReturnType<typeof getProductsForDisplay>;
  expectedTotals: ReturnType<typeof getTotals>;
  expectedOrder: typeof UNKNOWN | null | string;
}) => {
  const colCmp = (a, b) => (R.equals(a, b) ? undefined : { color: 'red' });

  if (order === UNKNOWN) {
    return (
      <Card raised style={{ margin: 8 }}>
        <h2>{title}</h2>
        <span style={{ color: 'red' }}>ORDER STATUS UNKNOWN</span>
      </Card>
    );
  }
  if (order === null) {
    return (
      <Card raised style={{ margin: 8 }}>
        <h2>{title}</h2>
        <div style={colCmp(order, eOrder)}>No order</div>
      </Card>
    );
  }
  return (
    <Card raised style={{ margin: 8 }}>
      <h2>{title}</h2>
      <div style={colCmp(order, eOrder)}>Order: {order}</div>
      <div style={colCmp(totals.OrderTotal, eTotals.OrderTotal)}>
        Total: {totals.OrderTotal}
      </div>
      <div style={colCmp(totals.OrderTax, eTotals.OrderTax)}>
        Tax: {totals.OrderTax}
      </div>
      <Table>
        <thead style={{ fontSize: 8 }}>
          <th>ItemID</th>
          <th>Description</th>
          <th>Amount</th>
          <th>Quantity</th>
        </thead>
        <tbody>
          {products.map((p, i) => (
            <tr style={colCmp(p, eProducts[i])}>
              <td>{p.ItemID}</td>
              <td>{p.Description}</td>
              <td>{p.Amount}</td>
              <td>{p.Quantity}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        style={{ pointerEvents: 'all' }}
        // Button explicitly for logging to console, therefore OK
        // eslint-disable-next-line no-console
        onClick={() => console.log({ title, order, products, totals })}
      >
        Log to console
      </Button>
    </Card>
  );
};
