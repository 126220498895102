import { createSelector } from 'reselect';

import { TYPE_LOGOUT } from 'constants/Login';
import { RESET_SHOPPING_CART } from 'constants/ShoppingCart';
import { getPluginSelector } from 'reducers/Plugins';
import { getVatRates } from 'reducers/vatRatesDB';
import { SaleDocumentResponse } from 'types/SalesDocument';

import { pluginID } from '../constants';

const SET_TAX_RETURN = `${pluginID}: SET_TAX_RETURN`;

export const setTaxReturnDocument = (payload: SaleDocumentResponse | null) => ({
  type: SET_TAX_RETURN,
  payload,
});

export const getTaxReturnDocument = state =>
  getPluginSelector<SaleDocumentResponse | null>(pluginID)(n => n)(state);

export const getTaxTotals = createSelector(
  getTaxReturnDocument,
  getVatRates,
  (taxDoc, vatRates) => {
    if (taxDoc) {
      return taxDoc.vatTotalsByTaxRate.reduce((acc, cur) => {
        const vatRateFromRedux = vatRates.find(
          v => Number(v.id) === cur.vatrateID,
        );
        if (vatRateFromRedux) {
          return {
            ...acc,
            [vatRateFromRedux.rate]: cur.total,
          };
        }
        return acc;
      }, {} as Record<string, number>);
    }
    return null;
  },
);

export default (state = null, action) => {
  switch (action.type) {
    case SET_TAX_RETURN:
      return action.payload;
    case RESET_SHOPPING_CART:
    case TYPE_LOGOUT:
      return null;
    default:
      return state;
  }
};
