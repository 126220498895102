import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import CloseButton from 'components/CustomButtons/CloseButton';
import InputField from 'components/FieldTypes/InputField';
import { loginInput } from 'components/FieldTypes/skins/skins.module.scss';
import { getClientCode } from 'reducers/Login';
import { loginWithPass } from 'services/ErplyAPI/api';
import { addError } from 'actions/Error';
import Loader from 'components/Loader';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import layers from 'assets/layers.module.css';

export const SecurityPopup = ({
  onLogin,
  onCancel,
  messages = [],
  title = 'Security override required',
  body = `You do not have enough permissions to perform this action!
            Please call a manager to authorize`,
}: {
  onLogin: (u) => void;
  onCancel: () => void;
  messages: string[];
  title?: string;
  body?: string;
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const clientCode = useSelector(getClientCode);

  const dispatch = useDispatch();
  const approveOverride = () => {
    if (loading) return;
    setLoading(true);
    loginWithPass({
      clientCode,
      username,
      password,
    })
      .then(([user]) => {
        onLogin(user);
      })
      .catch(err => {
        dispatch(addError(err.message));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const css =
      '.modal-backdrop { background-color : rgba(105,105,105, .3) !important; }';
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    head.appendChild(style);
    style.appendChild(document.createTextNode(css));

    return () => {
      head.removeChild(style);
    };
  }, []);

  useShortcut('Enter', approveOverride, 30);
  return (
    <Modal
      show={true}
      backdropClassName={layers.confirmation}
      data-testid="manager-override"
    >
      <Loader show={loading}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <CloseButton action={onCancel} />
        </Modal.Header>
        <Modal.Body>
          <div />
          <div style={{ whiteSpace: 'pre-line' }}>{messages.join('\n')}</div>
          <InputField
            autoFocus
            title="username"
            className={loginInput}
            data-testid="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <InputField
            title="password"
            className={loginInput}
            value={password}
            data-testid="password"
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={approveOverride}
              disabled={loading}
              style={{ flexGrow: 1 }}
              size="lg"
              variant="primary"
              data-testid="approve-btn"
            >
              Approve override
            </Button>
          </div>
        </Modal.Body>
      </Loader>
    </Modal>
  );
};
export default SecurityPopup;
