import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getProductInOrderByIndex } from 'reducers/ShoppingCart';
import { getAllowScanningFromOrderQtyInput } from 'reducers/configs/settings';
import { Order } from 'types/ShoppingCart';

import DisplayedRowAmount from './components/DisplayedRowAmount';
import ScanningRowQuantity from './components/ScanningRowQuantity';
import SimpleRowQuantity from './components/SimpleRowQuantity';
import AppliedDiscountsForQuantityColumn from './components/AppliedDiscountsForQuantityColumn';

const RowQuantity = React.memo<Pick<Order, 'orderIndex'>>(({ orderIndex }) => {
  const shouldUseQtyWithScan = useSelector(getAllowScanningFromOrderQtyInput);
  const order = useSelector(getProductInOrderByIndex(orderIndex));

  const QtyComponent = useMemo(() => {
    if (order.giftCardSerial || order.computed) {
      return DisplayedRowAmount;
    }
    if (shouldUseQtyWithScan) {
      return ScanningRowQuantity;
    }
    return SimpleRowQuantity;
  }, [shouldUseQtyWithScan, order.giftCardSerial, order.computed]);

  return (
    <>
      <QtyComponent orderIndex={orderIndex} />
      <AppliedDiscountsForQuantityColumn orderIndex={orderIndex} />
    </>
  );
});

export default RowQuantity;
