import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, Box, Typography, styled } from '@material-ui/core';

import * as api from 'services/ErplyAPI/clockInOut';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getEmployeesRespectingLocationOption } from 'reducers/cachedItems/employees';
import { getClientCode, getLoggedInEmployeeID } from 'reducers/Login';
import { addWarning, dismissType } from 'actions/Error';
import { fetchClockedInEmployees, lastClockInTime } from 'actions/clockInOut';
import { loginWithPass, loginWithPin } from 'services/ErplyAPI/api';
import CloseButton from 'components/CustomButtons/CloseButton';
import { fetchSalesData } from 'actions/Login';
import { AccountAdminEmployee, Employee } from 'types/Employee';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { PluginComponent } from 'plugins';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import Loader from 'components/Loader';

import ClockedInEmployees from './ClockedInEmployees';
import EmployeeSelectField from './SelectField';

export const BoldText = styled(Typography)({
  fontWeight: 'bold',
});

type Props = {
  clockedInEmployees: Employee[];
};

const EmployeeLoader: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const companyEmployees = useSelector(getEmployeesRespectingLocationOption);
  const hasOptions = companyEmployees.length;

  const { t } = useTranslation('alerts');
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasTimedOut(true);
    }, 60e3);
    if (hasOptions && !hasTimedOut) {
      clearTimeout(timeout);
      setLoading(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [hasOptions, hasTimedOut]);

  useEffect(() => {
    if (hasTimedOut) {
      dispatch(addWarning(t('errors.failedToLoadEmployees')));
      setLoading(false);
    }
  }, [hasTimedOut, dispatch, t]);

  return (
    <Loader block show={loading} loadingText={t('general.loading')}>
      {children}
    </Loader>
  );
};

const ClockIn: React.FC<Props> = ({
  clockedInEmployees: initialClockedInEmployees = [],
}) => {
  const { t } = useTranslation(['clockInOut', 'validation']);

  const companyEmployees = useSelector(getEmployeesRespectingLocationOption);

  const clockedInEmployees = useMemo(
    () =>
      initialClockedInEmployees.map(initialEmployee => ({
        ...initialEmployee,
        username:
          companyEmployees.find(
            companyEmployee =>
              Number(companyEmployee.employeeID) ===
              Number(initialEmployee.employeeID),
          )?.username ?? '',
      })),
    [initialClockedInEmployees, companyEmployees],
  );

  const loggedInEmployeeID = useSelector(getLoggedInEmployeeID);
  const { posID } = useSelector(getSelectedPos);
  const selectedWarehouse = useSelector(getSelectedPos).warehouseID;
  const clientCode = useSelector(getClientCode);

  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModalPage());

  const options = useMemo(
    () =>
      companyEmployees
        .filter(
          ce =>
            !clockedInEmployees.some(
              cie => Number(cie.employeeID) === Number(ce.employeeID),
            ),
        )
        .sort((a, b) => {
          const nameA = a.fullName.toUpperCase();
          const nameB = b.fullName.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }),
    [companyEmployees, clockedInEmployees],
  );

  const [employeeToLogin, setEmployeeToLogin] = useState<Employee | null>(
    options[0] ?? null,
  );

  const [password, setPassword] = useState('');

  const clockInRequest = async (employee: Employee) => {
    if (!employee.employeeID) return;
    const clockedInResponse = await api.clockIn({
      employeeID: employee.employeeID,
      pointOfSaleID: posID,
      warehouseID: selectedWarehouse,
      InUnixTime: Math.round(new Date().getTime() / 1000),
    });
    if (clockedInResponse.warningAlreadyClockedIn) {
      dispatch(
        addWarning(t('alerts.alreadyClockedIn'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
      return;
    }
    if (employee?.employeeID === loggedInEmployeeID) {
      dispatch(lastClockInTime());
    }

    Promise.all([
      dispatch(fetchClockedInEmployees()),
      dispatch(fetchSalesData),
    ]);
  };

  const handleSubmit = async () => {
    try {
      dispatch(
        addWarning(t('alerts.verifyingPass'), {
          selfDismiss: false,
          dismissible: false,
          errorType: 'clockin-verifying',
        }),
      );
      if (!employeeToLogin) {
        dispatch(addWarning(t('alerts.noEmployeeChosen')));
        return;
      }
      const [user] = await loginWithPass({
        username: employeeToLogin.username,
        password,
        clientCode,
      }).catch(() =>
        loginWithPin({
          clientCode,
          cardCode: password,
        }),
      );
      if (String(employeeToLogin.employeeID) !== String(user.employeeID)) {
        throw new Error('Credentials do not match employee');
      }
      await clockInRequest(employeeToLogin);
    } catch (err) {
      dispatch(
        addWarning(t('ErplyAPI.error.credentials', { ns: 'validation' }), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    } finally {
      dispatch(dismissType('clockin-verifying'));
    }
  };

  const onChangeEmployee = (
    employee: Employee | AccountAdminEmployee | null,
  ) => {
    const option = options.find(option => option.id === Number(employee?.id));
    if (option) setEmployeeToLogin(option);
  };

  return (
    <PluginComponent
      hookname="UIClockIn"
      props={{
        employeeToLogin,
        onChangeEmployee,
      }}
    >
      <EmployeeLoader>
        <Box
          data-testid="clock-in-modal"
          padding="1rem"
          component="form"
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingBottom="1rem"
          >
            <BoldText variant="h5">{t('title')}</BoldText>
            <Box display="flex">
              <Button
                type="submit"
                disabled={!options.length}
                variant="contained"
                color="secondary"
                data-testid="save-btn"
              >
                {t('buttons.clockin')}
              </Button>
              <CloseButton action={onClose} />
            </Box>
          </Box>
          <Box>
            <EmployeeSelectField
              label={t('fields.employee')}
              onChange={(e, chosenEmployee) => {
                e.preventDefault();
                onChangeEmployee(chosenEmployee);
              }}
              value={employeeToLogin}
              options={options}
              data-testid="input"
              data-test-key="employee"
            />

            <TextField
              label={t('fields.password')}
              name="password"
              type="password"
              fullWidth
              variant="outlined"
              data-testid="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <ClockedInEmployees employees={clockedInEmployees} />
          </Box>
        </Box>
      </EmployeeLoader>
    </PluginComponent>
  );
};

export default ClockIn;
