import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { dismissAll } from 'actions/Error';

export const useOnBeforeReloadCleanUp = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const onUnload = () => {
      /* Add synchronous actions below this line */
      dispatch(dismissAll());
    };
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
};
