import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';

import { pluginID } from '../constants';

import { CustomPayment } from './WarehouseConfiguration';

export type Company = {
  noSelectOnEnter: boolean;
  plugin_dsc_membership_return_days: number;
};
export type Warehouse = { customPayments: CustomPayment[] };
export type Config = Company & Warehouse;

export type WbuPosPlugin = PosPlugin<
  Config,
  Company,
  Warehouse,
  Record<string, never>,
  Record<string, never>
>;

export const getWbuConfiguration = state =>
  getPluginConfiguration<Config>(pluginID)(state);

export { WarehouseConfiguration } from './WarehouseConfiguration';
export { CompanyConfiguration } from './CompanyConfiguration';
