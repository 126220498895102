import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import uuid4 from 'uuid/v4';

import {
  getProductDiscounts,
  getProductInOrderByIndex,
} from 'reducers/ShoppingCart';
import Icon from 'components/Icon';

type RowNameProps = { depthLevel: number; orderIndex: string };

const RowName: FC<RowNameProps> = ({ depthLevel, orderIndex }) => {
  const order = useSelector(getProductInOrderByIndex(orderIndex));
  const appliedDiscounts = useSelector(getProductDiscounts(order.rowNumber));

  return (
    <>
      {order.computed && (
        <Icon
          data-testid="icon-lock"
          name="icon_lock"
          style={{ marginRight: 8 }}
        />
      )}
      <span
        style={{ fontWeight: depthLevel === 0 ? 'bold' : 'initial' }}
        data-testid="product-name-span"
        data-test-key={`product-name-${orderIndex}`}
      >
        {order.name}
      </span>
      {appliedDiscounts.length > 0
        ? appliedDiscounts.map(discount => (
            <span
              data-testid="discount"
              data-test-key={`product-discount-${orderIndex}`}
              key={uuid4()}
              className="adjustment"
            >
              {discount.name}
            </span>
          ))
        : null}
    </>
  );
};

export default React.memo(RowName);
