const options = {
  add: {
    key: 'add',
    title: 'Add',
    isDefault: true,
    noDrag: true,
    notToggable: true,
  },
  brandName: {
    key: 'brandName',
    title: 'Brand',
  },
  name: {
    key: 'name',
    title: 'Name',
    isDefault: true,
  },
  code: {
    key: 'code',
    title: 'Code',
    isDefault: true,
  },
  code2: {
    key: 'code2',
    title: 'EAN Code',
  },
  supplierCode: {
    key: 'supplierCode',
    title: 'Supplier Code',
  },
  nameENG: {
    key: 'nameENG',
    title: 'Name ENG',
  },
  nameRUS: {
    key: 'nameRUS',
    title: 'Name Rus',
  },
  price: {
    key: 'price',
    title: 'Net Price',
    isDefault: true,
  },
  priorityGroupID: {
    key: 'priorityGroupID',
    title: 'Priority Group',
  },
  status: {
    key: 'status',
    title: 'Status',
  },
  type: {
    key: 'type',
    title: 'Type',
  },
  categoryName: {
    key: 'categoryName',
    title: 'Category',
  },
  code3: {
    key: 'code3',
    title: 'Code 3',
  },
  nameEST: {
    key: 'nameEST',
    title: 'Name EST',
  },
  priceWithVat: {
    key: 'priceWithVat',
    title: 'Net + VAT',
  },
  groupName: {
    key: 'groupName',
    title: 'Product Group',
  },
  displayedInWebshop: {
    key: 'displayedInWebshop',
    title: 'Show in webshop',
  },
  supplierName: {
    key: 'supplierName',
    title: 'Supplier',
  },
  deliveryTime: {
    key: 'deliveryTime',
    title: 'Usual delivery time',
  },
};

export default options;
