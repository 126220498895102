import React from 'react';
import { useTranslation } from 'react-i18next';

import { RedButton, GreenButton, PopupButton } from 'components/ActionButtons';
import SaleOptions from 'containers/Col2/SaleOptions';
import Functions from 'containers/Col2/Functions';

import Flexbox from './Flexbox';

const MobileView: React.FC = () => {
  const { t } = useTranslation('gridButtons');

  return (
    <>
      <Flexbox>
        <PopupButton height={50} title={t('saleOptions.title')}>
          <SaleOptions />
        </PopupButton>
        <PopupButton height={50} title={t('functions.title')}>
          <Functions />
        </PopupButton>
      </Flexbox>
      <Flexbox>
        <RedButton height={65} width="100%" />
        <GreenButton height={65} width="100%" />
      </Flexbox>
    </>
  );
};

export default MobileView;
