import { v4 as uuidv4 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { addWarning, dismissType } from 'actions/Error';
import i18next from 'containers/App/i18n';

export type Progress = {
  halt: ThunkAction<any, any, any, any>;
  resume: ThunkAction<any, any, any, any>;
};

const APPLY_WITH_PROGRESS = Symbol('applyWithProgress');
/**
 * Convert a regular thunk action into one that gets a progress alert automatically
 *
 * The action will receive a third parameter that it can use to halt and resume the progress to allow the user to interact with prompts
 * @example
 * const addProduct = withProgressAlert('alerts.addingProduct')(
 *   ({productID}) => async (dispatch, getState, progress) => {
 *
 *     let price = getPriceOfProductById(productID)(getState())
 *     if (price === 0) {
 *       dispatch(progress.halt);
 *       price = await dispatch(promptForPrice)
 *       dispatch(progress.resume);
 *     }
 *
 *     dispatch({type: 'add_product', payload: {productID, price}});
 *   }
 * )
 */
export function withProgressAlert(
  transKey,
  extraParams: Parameters<typeof addWarning>[1] = { selfDismiss: false },
) {
  return <Params extends any[], Return extends Promise<any>>(
    action: (...params: Params) => ThunkAction<Return, any, Progress, Action>,
  ): ((...params: Params) => ThunkAction<Return, any, unknown, Action>) => {
    /** Combine two progresses so they halt and resume as one - nested progressactions should receive progresses that are chained with all their calling parents */
    const chainedWith = (
      thisProgress: Progress,
      progress: Progress,
    ): Progress => ({
      halt: async dispatch => {
        dispatch(progress.halt);
        dispatch(thisProgress.halt);
      },
      resume: async dispatch => {
        dispatch(progress.resume);
        dispatch(thisProgress.resume);
      },
    });

    /**
     * Wrap dispatch so that if it's called with another progress-supporting function
     * then the current progress is chained into that one
     */
    const customDispatch = (dispatch, getState, thisProgress) => {
      const cd = action => {
        if (action[APPLY_WITH_PROGRESS])
          return dispatch(action[APPLY_WITH_PROGRESS](thisProgress));
        if (typeof action === 'function') {
          return action(cd, getState);
        }
        return dispatch(action);
      };
      return cd;
    };

    return (...params: Params): any => {
      // Create new progress
      const id = extraParams.errorType ?? uuidv4();
      const thisProgress: Progress = {
        resume: addWarning(i18next.t(transKey), {
          dismissible: false,
          selfDismiss: extraParams.selfDismiss,
          errorType: id,
        }),
        halt: dismissType(id),
      };

      const actionForProgress = (progress: Progress) => async (
        dispatch,
        getState,
      ) => {
        await dispatch(thisProgress.resume);
        return action(...params)(
          customDispatch(dispatch, getState, thisProgress),
          getState,
          progress,
        ).finally(() => {
          // If the parent action finishes earlier, prevent child actions from reopening the progress
          thisProgress.resume = () => {
            // empty action
          };
          dispatch(thisProgress.halt);
        });
      };

      const progressAction = actionForProgress(thisProgress);

      progressAction[APPLY_WITH_PROGRESS] = (progress: Progress) =>
        actionForProgress(chainedWith(thisProgress, progress));

      return progressAction;
    };
  };
}

export function withContainingProgress<
  Params extends any[],
  Return extends any,
  D extends any,
  G extends any
>(
  action: (
    ...params: Params
  ) => (dispatch: D, getState: G, progress: Progress) => Promise<Return>,
): ((...params: Params) => (dispatch: D, getState: G) => Promise<Return>) {
  // eslint-disable-next-line no-param-reassign
  const actWithParams = (...params: Params) => {
    const act = action(...params);
    act[APPLY_WITH_PROGRESS] = (progress: Progress) => (dispatch, getState) =>
      act(dispatch, getState, progress);
    return act;
  };
  return actWithParams as any;
}
