import axios from 'axios';

import { saveWarehouse } from 'services/ErplyAPI/warehouses';
import { getMSEndpointFromLS } from 'reducers/installer';

const defaultTriPosEndpoint = 'https://localhost.erply.com:9000/';

const getTriPosUrl = () =>
  getMSEndpointFromLS('triPOS') || defaultTriPosEndpoint;

// TODO: Refactor triPOS requests

// export const testConnection = () => {
//   return triposRequest
//     .get('/api/v1/testConnection')
//     .then(
//       response => {
//         return response.statusText;
//       },
//       err => {
//         throw err;
//       },
//     )
//     .catch(e => {
//       throw e;
//     });
// };

export const requestSale = params => {
  return axios
    .post(`${getTriPosUrl()}api/v1/payment`, {
      transactionType: '1',
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestCancel = params => {
  return axios
    .post(`${getTriPosUrl()}api/v1/cancel`, { transactionType: '1', ...params })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestReversal = params => {
  return axios
    .post(`${getTriPosUrl()}api/v1/reversal`, {
      transactionType: '1',
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestReturn = params => {
  return axios
    .post(`${getTriPosUrl()}api/v1/refund`, {
      transactionType: '1',
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestGetLanes = params => {
  return axios
    .get(`${getTriPosUrl()}cloudapi/v1/lanes`, { ...params })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestPostLane = params => {
  return axios
    .post(`${getTriPosUrl()}cloudapi/v1/lanes`, { ...params })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    )
    .catch(e => {
      throw e;
    });
};

export const requestCloudConnection = (params, laneId) => {
  return axios
    .get(`${getTriPosUrl()}cloudapi/v1/lanes/${laneId}/connectionstatus`, {
      ...params,
    })
    .then(
      r => {
        return r;
      },
      err => {
        throw err;
      },
    );
};

export const saveTriposConfig = async ({ config, warehouseID, posID }) => {
  try {
    await saveWarehouse({
      warehouseID,
      attributeName0: `tripos-posid-${posID}`,
      attributeType0: 'text',
      attributeValue0: JSON.stringify(config),
    });
  } catch (err) {
    console.error('Failed to save TriPOS configuration', err);
  }
};
