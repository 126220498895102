export const TYPE_SET_CONNECTION_HEALTH = 'CONNECTION:SET_HEALTH';
export const TYPE_SET_CONNECTION_STATUS = 'CONNECTION:SET_STATUS';
export const TYPE_SET_CLOUD_STATUS = 'CLOUD:SET_STATUS';
export const TYPE_REMOVE_FROM_CLOUD = 'CLOUD:REMOVE_CLOUD';
export const TYPE_SET_FORCE_OFFLINE = 'TYPE_SET_FORCE_OFFLINE';
export const SYNC_PENDING_REQUEST = {
  START: 'OFFLINE:DO_PENDING_REQUEST_START',
  FAILURE: 'OFFLINE:DO_PENDING_REQUEST_FAILURE',
  SUCCESS: 'OFFLINE:DO_PENDING_REQUEST_SUCCESS',
};
