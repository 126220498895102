import axios from 'axios';

function getBaseUrl(isProduction) {
  return isProduction
    ? 'https://secureauthenticationservice.mmms.ca/api'
    : 'https://dev-authenticationservice.mmms.ca/api';
}

interface TokenResponse {
  accessToken: string;
  refreshToken: string;
  expirationInSeconds: number;
  tokenType: string;
}

export async function getAccessToken({
  username,
  password,
  isProduction,
}: {
  username: string;
  password: string;
  isProduction: boolean;
}): Promise<string> {
  const baseUrl = getBaseUrl(isProduction);
  return axios
    .post<TokenResponse>(`${baseUrl}/auth/token`, { username, password })
    .then(({ data }) => data.accessToken);
}
