/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useMemo } from 'react';
import * as rxjs from 'rxjs';
import * as rxop from 'rxjs/operators';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import axios from 'axios';
import { AccountBalance } from '@material-ui/icons';

import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { getPayments } from 'reducers/Payments';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { getCurrentSalesDocument } from 'reducers/sales';
import { getVatRateByID, getVatRates } from 'reducers/vatRatesDB';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { createConfirmation } from 'actions/Confirmation';
import { ErplyAttributes } from 'utils';
import { getEFSTAEndpoint } from 'plugins/EFSTAFiscalIntegration/components/ComponentConfiguration';

import { FiscalIntegration } from '../../FiscalIntegration';

import styles from './styles.module.scss';
import { DEPayGroup, DEPosType, DEStartRequestType } from './DERequestType';

const ATTRIBUTES = {
  code: 'EFSTA-DE-CODE',
  d0: 'EFSTA-DE-D0',
};
type Pos = Required<
  Required<Required<DEStartRequestType>['TraS']>['ESR']
>['PosA'][number];
type Pay = Required<
  Required<Required<DEStartRequestType>['TraS']>['ESR']
>['PayA'][number];

const efr = axios.create();
efr.interceptors.response.use(
  f => f,
  err => {
    try {
      const warning = err.data.TraC.Result.Warning;
      if (warning) throw new Error(warning);
    } catch (e) {
      throw err;
    }
  },
);

const EFSTADETaxRates = [
  { rate: 19, name: 'Normal', group: 'A' },
  { rate: 7, name: 'Reduced', group: 'B' },
  { rate: 10.7, name: 'Average tax rate (§24(1)Nr.3 UStG)', group: 'C' },
  { rate: 5.5, name: 'Average tax rate (§24(1)Nr.1 UStG)', group: 'D' },
  { rate: 0, name: 'Not taxable', group: 'E' },
  { rate: 0, name: 'VAT-exempt', group: 'F' },
  { rate: 0, name: 'Tax rate can not be determined', group: 'G' },
  { rate: 16, name: 'Corona Normal', group: 'H' },
  { rate: 5, name: 'Corona Ermässigt', group: 'I' },
];

const getCurrentTransaction = (state): DEStartRequestType['TraS'] => {
  const shoppingCart = getProductsInShoppingCart(state);
  const payments = getPayments(state);
  const posID = getSelectedPosID(state);
  const salesDoc = getCurrentSalesDocument(state);
  const customer = getSelectedCustomer(state);
  const vatRates = getVatRates(state);

  return {
    ESR: {
      TL: posID.pointOfSaleID,
      TN: salesDoc.number ?? salesDoc.invoiceNo ?? 'new',
      test: '1',
      PosA: shoppingCart.map(
        (item): Pos => ({
          _: 'Pos',
          ...(item.computed ? { PTY: DEPosType.Dep } : undefined),
          Dsc: item.name,
          Pri: item.finalPriceWithVAT,
          Qty: item.amount,
          ID: item.productID,
          QtyU: item.unit,
          Cat: item.groupName,
          TaxG: vatRates.find(rate => rate.id === item.vatRateID)?.code ?? '?',
        }),
      ),
      PayA: Object.values(payments).map(
        (pmt: any): Pay => ({
          _: 'Pay',
          PayG: pmt.type === 'CASH' ? DEPayGroup.CASH : DEPayGroup.NONCASH,
          Amt: (Number(pmt.amount) * (pmt.currencyRate ?? 1)).toFixed(2),
          UID: pmt.referenceNumber,
          Dsc: pmt.type,
          CC: pmt.currencyCode ?? pmt.currency,
          FAmt: pmt.amount,
        }),
      ),
      Ctm: {
        CN: customer.id,
        Nam: customer.fullName ?? customer.companyName,
        Nam2: '',
        Cat: customer.group,
        Adr: customer.address,
        Adr2: customer.address2,
        Zip: customer.postalCode,
        City: customer.city,
        Ctry: customer.country,
        TaxId: customer.vatNumber,
      },
    },
  };
};

export const DEFiscalIntegration: FiscalIntegration = {
  getStatus: () => ({
    type: 'valid',
    message: 'Selected: DE',
  }),
  onCashInOut: (sum: string) => async (dispatch, getState) => {
    efr.defaults.baseURL = getEFSTAEndpoint(getState());
    return rxjs
      .from([
        {
          TraS: { ESR: { TL: getSelectedPosID(getState()) } },
        },
      ])
      .pipe(
        rxop.concatMap(msg => efr.post('/register', msg)),

        rxop.map(({ data: { TraSC: { Fis: { TID } } } }) => ({
          Tra: {
            ESR: {
              TL: getSelectedPosID(getState()),
              TID,
              NFS: 'PAY',
              PosA: [
                {
                  _: 'Pos',
                  Dsc: Number(sum) < 0 ? 'DKT:TenderPickup' : 'DKT:TenderLoan',
                  amt: sum,
                },
              ],
              PayA: [{ _: 'Pay', Dsc: 'Cash', PayG: '0', Amt: sum }],
            },
          },
        })),

        rxop.concatMap(msg => efr.post('/register', msg)),
      )
      .toPromise();
  },
  onCloseDay: () => async (dispatch, getState) => {
    efr.defaults.baseURL = getEFSTAEndpoint(getState());
    return efr.post('/register', {
      Tra: { ESR: { TL: getSelectedPosID(getState()), NFS: 'Z' } },
    });
  },
  onSaveDocument: (requests: any[]) => async (dispatch, getState) => {
    efr.defaults.baseURL = getEFSTAEndpoint(getState());
    const base = getCurrentTransaction(getState());

    const reqsWithPromises = R.map(
      R.when(
        R.propEq('requestName', 'saveSalesDocument'),
        async (sdoc: any) => {
          const attrs = ErplyAttributes.fromFlatArray(sdoc);
          const sdocNoAttrs = ErplyAttributes.withoutFlatArray(sdoc);
          const d0 = attrs.get(ATTRIBUTES.d0);
          const data = R.pipe(
            R.when(R.always(d0), R.assocPath(['TraS', 'ESR', 'D0'], d0)),
          )({
            TraS: base,
          });
          const res = await efr.post('/register', data);
          const isWipDocument = !['CASHINVOICE', 'CREDITINVOICE'].includes(
            sdoc.type,
          );
          const data2 = R.pipe(
            RA.renameKeys({
              TraS: 'Tra',
            }),
            R.assocPath(['Tra', 'ESR', 'TID'], res.data.TraSC.Fis.TID),
            R.when(
              R.always(isWipDocument),
              R.assocPath(['Tra', 'ESR', 'NFS'], 'ORDER'),
            ),
          )(data);
          const res2 = await efr.post('/register', data2);
          attrs.set(ATTRIBUTES.code, res2.data.TraC.Fis.Code);
          attrs.set(ATTRIBUTES.d0, d0 ?? res2.data.TraC.ESR.D);
          const resultingRequest = R.mergeDeepRight(
            sdocNoAttrs,
            attrs.asFlatArray,
          );
          return resultingRequest;
        },
      ),
    )(requests);
    return Promise.all(reqsWithPromises).catch(err => {
      throw new Error(
        'EFSTA DE failed to save salesDocument. Ensure the microservice is running and EFSTA is configured',
        { cause: err },
      );
    });
  },
  onOpenPaymentModal: (p, ap) => async (dispatch, getState) => {
    // const salesDocumentType = ap.salesDocument.type ??
    //   ap.currentSalesDocument.type ??
    //   'CASHINVOICE';
    // const allowedTypes = ['CASHINVOICE', 'CREDITINVOICE'];
    // if (!allowedTypes.includes(salesDocumentType))
    //   throw new Error(`document type ${salesDocumentType} not supported`);
    efr.defaults.baseURL = getEFSTAEndpoint(getState());
    const products = getProductsInShoppingCart(getState());
    const badTaxProducts = products.filter(p => {
      const vatRate = getVatRates(getState()).find(
        v => String(v.id) === String(p.vatrateID),
      );
      if (!vatRate) {
        console.warn('Vatrate not found', p, vatRate, getVatRates(getState()));
        return false;
      }
      return !'ABCDEFGHI'.split('').includes(vatRate.code);
    });
    if (badTaxProducts.length === 0) return ap;
    dispatch(
      createConfirmation(() => {}, null, {
        title: 'Invalid vat rates in cart',
        body: `Some products in the cart have vat rates which have not been correctly configured. (the ones marked in red)

For a vat rate to be usable, it needs to be assigned an EFSTA code A-I ("Code used in accounting software" in back-office).
For an overview of the vat rates on your account and how they are configured, see the table in the EFSTA plugin configuration.

To resolve the issue, either:
1) Remove the invalid products from the cart and replace them with different ones
2) Fix the vat rate in the back office and refresh the POS
`,
      }),
    );
    throw new Error('Some products in cart have incompatible vat rates.');
  },
  ComponentConfiguration: () => {
    const vatRates = useSelector(getVatRates);

    const byRate = R.groupBy((v: any) => Number(v.rate).toFixed(2))(vatRates);

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>Vat rates expected by DE fiscal</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>EFR expected vat rate</TableCell>
              <TableCell colSpan={2}>Erply vat rate</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>class</TableCell>
              <TableCell>description</TableCell>
              <TableCell>rate</TableCell>
              <TableCell>rate</TableCell>
              <TableCell>name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {EFSTADETaxRates.map(({ rate, name, group }) => {
              const matches = vatRates.filter(rate => rate.code === group);
              const firstRateIsIncorrect =
                Number(matches[0]?.rate ?? rate) !== rate;
              return (
                <>
                  <TableRow>
                    <TableCell>{group}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{rate}</TableCell>
                    <TableCell>
                      <Typography
                        color={firstRateIsIncorrect ? 'error' : undefined}
                      >
                        {matches[0]?.rate ?? ''}
                      </Typography>
                    </TableCell>
                    <TableCell>{matches[0]?.name ?? ''}</TableCell>
                  </TableRow>
                  {matches.slice(1).map((m, i) => (
                    <TableRow key={m.id}>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Typography
                          color={Number(m.rate) === rate ? undefined : 'error'}
                        >
                          {m.rate}
                        </Typography>
                      </TableCell>
                      <TableCell>{m.name}</TableCell>
                    </TableRow>
                  ))}
                </>
              );
            })}
            {vatRates
              .filter(r => !EFSTADETaxRates.map(r => r.group).includes(r.code))
              .map((rate, i) => (
                <TableRow>
                  <TableCell>{i === 0 && '-'}</TableCell>
                  <TableCell>
                    <Typography color="error">
                      {i === 0 && 'Not corresponding to any EFSTA code'}
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    <Typography color="error">{rate.rate}</Typography>
                  </TableCell>
                  <TableCell>{rate.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </>
    );
  },
  ComponentHeader: () => {
    return (
      <li>
        <div style={{ padding: '3px' }}>
          <AccountBalance style={{ fontSize: '24px' }} />
        </div>
      </li>
    );
  },
  UICustomTableBillRow: ({ order, children }) => {
    const loading = !order.vatrateID;
    const productID = order.vatrateID ?? '-0';
    const vatrate = useSelector(getVatRateByID(productID));
    const valid = 'ABCDEFGHI'.includes(vatrate?.code || 'nonexist');

    const color = useMemo(() => {
      if (loading) return styles.loadingTableRow;
      if (!valid) return styles.invalidTableRow;
    }, [valid, loading]);

    const referencedChildren = (R.adjust as any)(
      0,
      R.evolve({
        props: { className: c => `${c} ${color ?? ''} ${styles.tableRow}` },
      }),
      React.Children.toArray(children),
    );
    return referencedChildren;
  },
};
