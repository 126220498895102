import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  colors,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  styled,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useEvent } from 'react-use';

import {
  getAutoUpdateState,
  getDebugInfo,
  getLogs,
  getProgressTotal,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/state';
import {
  setCookiePermanent,
  setCookieTemporary,
} from 'containers/VersionControl/WorkaroundInstallerUpdate/cookie';

const errorTheme = createTheme({
  palette: {
    primary: colors.red,
  },
});
const successTheme = createTheme({
  palette: {
    primary: colors.green,
  },
});
function useValue<T>(getter: () => T): T {
  const [latest, setLatest] = useState(getter());

  useEffect(() => {
    const i = setInterval(() => {
      const v = getter();
      if (v !== latest) {
        setLatest(v);
      }
    }, 0.1e3);
    return () => clearInterval(i);
  }, [getter, latest]);
  return latest;
}

const TerminalBox = styled(Box)({
  background: 'black',
  color: 'white',
  overflow: 'scroll',
  height: '20em',
  whiteSpace: 'pre',
  fontFamily: 'monospace',
});
const LogViewTerminal = () => {
  const log = useValue(getLogs);

  const [showDetails, setShowDetails] = useState(false);

  return (
    <Box mt={2}>
      <Typography onClick={() => setShowDetails(a => !a)}>
        {showDetails ? 'Hide details' : 'Show details'}
      </Typography>
      <Collapse in={showDetails}>
        <TerminalBox>
          {log.map((items, i) => (
            <div
              key={i}
              style={{
                margin: 0,
                padding: 0,
                overflowAnchor: 'none',
              }}
            >
              {items
                .map(item => {
                  if (item instanceof Error)
                    return <span style={{ color: 'red' }}>{item.message}</span>;
                  if (typeof item === 'object') return JSON.stringify(item);
                  return String(item);
                })
                .map((el, i) => (
                  <span key={i} style={{ marginRight: '1ch' }}>
                    {el}
                  </span>
                ))}
            </div>
          ))}
          <Box
            style={{
              height: 1,
              overflowAnchor: 'auto',
            }}
          />
        </TerminalBox>
      </Collapse>
    </Box>
  );
};

function Actions({
  onDone,
  onRetry,
}: {
  onDone: () => void;
  onRetry: () => void;
}) {
  const state = useValue(getAutoUpdateState);
  const debugInfo = useValue(getDebugInfo);

  switch (state) {
    case 'done-ok':
      return (
        <ThemeProvider theme={successTheme}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              setCookiePermanent();
              onDone();
            }}
          >
            Continue to application
          </Button>
        </ThemeProvider>
      );
    case 'done-failed':
      return (
        <ThemeProvider theme={successTheme}>
          <Box margin={2}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(debugInfo));
              }}
            >
              Click to copy debug information
            </Button>
          </Box>
          <Box margin={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={onRetry}
            >
              Retry automatic update
            </Button>
          </Box>
          <Box margin={2}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => {
                setCookieTemporary();
                onDone();
              }}
            >
              Remind me later, continue to application
            </Button>
          </Box>
        </ThemeProvider>
      );
    default:
      return null;
  }
}

function AutoUpdateProgressBar(): JSX.Element {
  const progress = useValue(getProgressTotal);
  const state = useValue(getAutoUpdateState);

  switch (state) {
    case 'pending':
      return <LinearProgress />;
    case 'in progress':
      return <LinearProgress value={100 * progress} variant="determinate" />;
    case 'done-ok':
      return (
        <ThemeProvider theme={successTheme}>
          <LinearProgress value={100} color="primary" variant="determinate" />
          <Typography>Auto-update finished successfully.</Typography>
        </ThemeProvider>
      );
    case 'done-failed':
      return (
        <ThemeProvider theme={errorTheme}>
          <LinearProgress value={100} variant="determinate" />
          <Typography color="primary">
            Auto-update was unsuccessful, please refer to the{' '}
            <a
              href="https://wiki.erply.com/et/article/2010-erply-will-update-the-certificate-once-a-year"
              target="_blank"
              rel="noreferrer noopener"
            >
              troubleshooting article
            </a>{' '}
            for manual solutions. It is possible that the update was successful
            but requires a restart to take effect.
          </Typography>
        </ThemeProvider>
      );
    default: {
      // This assignment would be invalid if there were any unhandled states
      // State only becomes assignable to 'never' if this code is unreachable
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = state;
      return <LinearProgress />; // Out of an abundance of caution, will render some UI anyway
    }
  }
}

function usePreventInterruptions() {
  const state = useValue(getAutoUpdateState);

  useEvent('beforeunload', e => {
    if (state !== 'in progress') return undefined;

    e.preventDefault();
    e.returnValue =
      'Please do not close the page, otherwise installer may end up in a broken state';
    return 'Please do not close the page, otherwise installer may end up in a broken state';
  });
}

export const InstallerUpdateDialog = ({
  onDone,
  onRetry,
}: {
  onDone: () => void;
  onRetry: () => void;
}) => {
  usePreventInterruptions();

  return (
    <div
      style={{
        position: 'absolute',
        inset: 0,
        background: 'linear-gradient(#5aacc8, #f7cc56)',
        backgroundSize: 'cover',
      }}
    >
      <Dialog open>
        <DialogTitle>Updating certificates</DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              To ensure continued functionality, we need to run a quick update
              of the installer.
            </Typography>
            <Typography>
              To read more about this update, see{' '}
              <a
                href="https://wiki.erply.com/et/article/2010-erply-will-update-the-certificate-once-a-year"
                target="_blank"
                rel="noreferrer noopener"
              >
                the wiki article
              </a>
            </Typography>
          </Box>

          <AutoUpdateProgressBar />

          <LogViewTerminal />

          <Actions onDone={onDone} onRetry={onRetry} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
