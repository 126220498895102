import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { useDebounce } from 'react-use';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit(value: string | null): void;
};

const Search: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation('unpaidInvoice');
  const [value, setValue] = useState<string | null>(null);

  useDebounce(
    () => {
      if (value !== null) onSubmit(value);
    },
    500,
    [onSubmit, value],
  );

  return (
    <TextField
      fullWidth
      variant="outlined"
      aria-label="Invoice number"
      placeholder={t('fields.invoiceNumber', { context: 'placeholder' })}
      value={value ?? ''}
      onChange={e => setValue(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onSubmit(value);
        }
      }}
      InputProps={{
        endAdornment: (
          <i
            onClick={() => onSubmit(value)}
            className="flip-horizontal icon_search"
          />
        ),
      }}
    />
  );
};

export default Search;
