export const displayView = {
  main: 'main',
  productGroups: 'productGroups',
  quickSelect: 'quickSelect',
} as const;
export const breakPoints = {
  xs: { min: 0, max: 767 },
  sm: { min: 768, max: 991 },
  md: { min: 992, max: 1199 },
  lg: { min: 1200, max: 100000 },
};
export const OPEN_GRID_VIEW = 'OPEN_GRID_VIEW';
export const CLOSE_GRID_VIEW = 'CLOSE_GRID_VIEW';
export const SET_GRID_VIEW = 'SET_GRID_VIEW';
export const SET_PRODUCT_GROUP = 'SET_PRODUCT_GROUP';
export const SET_PRODUCT_PAGE = 'SET_PRODUCT_PAGE';

// Toggle product editing
export const ENABLE_PRODUCT_EDIT_MODE = 'ENABLE_PRODUCT_EDIT_MODE';
export const DISABLE_PRODUCT_EDIT_MODE = 'DISABLE_PRODUCT_EDIT_MODE';

// Toggle Manual view mode
export const TOGGLE_MANUAL_VIEW_MODE = 'TOGGLE_MANUAL_VIEW_MODE';

// Toggle Manual Edit settings
export const TOGGLE_MANUAL_EDIT_MODE = 'TOGGLE_MANUAL_EDIT_MODE';

// Toggle customer tooltip
export const ADD_TOOLTIP = 'ADD_TOOLTIP';
export const SET_SHOPPING_CART_TOOLTIP = 'SET_SHOPPING_CART_TOOLTIP';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';
export const RESET_TOOLTIPS = 'RESET_TOOLTIPS';
export const APP_LOADER_SHOW = 'APP_LOADER_SHOW';
export const APP_LOADER_HIDE = 'APP_LOADER_HIDE';

// Signal to grid to update
export const SET_GRID_SHOULD_UPDATE = 'SET_GRID_SHOULD_UPDATE';

export const SET_COLOR_THEME = 'SET_COLOR_THEME';
