import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { getProductsInShoppingCart, getTotal } from 'reducers/ShoppingCart';
import { add, isEmpty } from 'utils';
import * as c from 'constants/sales';
import * as c2 from 'constants/customerSearch';
import { lastReceiptData } from 'services/localDB';
import { getPayments } from 'reducers/Payments';
import { TYPE_LOGOUT, TYPE_LOGOUT_SOFT } from 'constants/Login';

const initState = {};

initState.all = [];

function all(state = initState.all, { type, payload }) {
  switch (type) {
    case c.GET_SALES_DOCUMENTS_SUCCESS:
      return payload;
    default:
      return state;
  }
}

/**
 * State only used for recordsTotal - todo: simplify or merge into something - made selectors
 */
initState.fullResponse = { status: { recordsTotal: 0 } };

function fullResponse(state = initState.fullResponse, { type, payload }) {
  switch (type) {
    case c.GET_SALES_DOCUMENTS_FULL_RESPONSE_SUCCESS:
      return payload;
    case c2.RESET_CUSTOMER:
    case TYPE_LOGOUT:
      return initState.fullResponse;
    default:
      return state;
  }
}

initState.currentSale = {};

function currentSale(state = {}, { type, payload }) {
  switch (type) {
    case c.SET_CURRENT_SALE_DOC:
      return payload;
    case c.UPDATE_CURRENT_SALE_DOC:
      return { ...state, ...payload };
    case c.SET_SALE_EMPLOYEE:
      return { ...state, employee: payload };
    case c.SAVE_SALES_DOCUMENT.SUCCESS:
    case c.RESET_CURRENT_SALES_DOC:
    case TYPE_LOGOUT:
      return initState.currentSale;
    case c.SET_USED_REWARD_POINTS:
      return {
        ...state,
        usedRewardPoints: payload,
      };
    default:
      return state;
  }
}

initState.paymentSale = {};

function paymentSale(state = {}, { type, payload }) {
  switch (type) {
    case c.SET_PAYMENT_SALE_DOC:
      return payload;
    case c.UPDATE_PAYMENT_SALE_DOC:
      return { ...state, ...payload };
    case c.RESET_PAYMENT_SALES_DOC:
    case TYPE_LOGOUT:
      return initState.paymentSale;
    default:
      return state;
  }
}

initState.lastSale = {};
function lastSale(state = {}, { type, payload }) {
  switch (type) {
    case c.SET_LAST_SALES_DOC:
      return payload;
    case c.RESET_LAST_SALES_DOC:
    case TYPE_LOGOUT:
      return initState.lastSale;
    default:
      return state;
  }
}

function lastPayments(state = {}, { type, payload }) {
  switch (type) {
    case c.SET_LAST_PAYMENTS:
      return payload;
    case c.DELETE_LAST_PAYMENT_BY_KEY:
      return state.filter(payment => payment.key !== payload);
    default:
      return state;
  }
}

initState.fetching = false;

function fetching(state = initState.fetching, { type }) {
  switch (type) {
    case c.GET_SALES_DOCUMENTS:
      return true;
    case c.GET_SALES_DOCUMENTS_SUCCESS:
    case c.GET_SALES_DOCUMENTS_FULL_RESPONSE_SUCCESS:
    case c.GET_SALES_DOCUMENTS_ERROR:
      return initState.fetching;
    default:
      return state;
  }
}

initState.isCurrentSaleAReturn = false;
function isCurrentSaleAReturn(
  state = initState.isCurrentSaleAReturn,
  { type, payload },
) {
  switch (type) {
    case c.SET_CURRENT_SALE_AS_RETURN:
      return payload;
    case c.SET_CURRENT_SALE_DOC:
    case c.RESET_CURRENT_SALE_AS_RETURN:
    case TYPE_LOGOUT:
      return initState.isCurrentSaleAReturn;
    default:
      return state;
  }
}

initState.isCurrentSaleGiftReturn = false;
function isCurrentSaleGiftReturn(
  state = initState.isCurrentSaleGiftReturn,
  { type, payload },
) {
  switch (type) {
    case c.SET_CURRENT_SALE_AS_GIFT_RETURN:
      return payload;
    case c.SET_CURRENT_SALE_DOC:
    case c.RESET_CURRENT_SALES_DOC:
    case TYPE_LOGOUT:
      return initState.isCurrentSaleGiftReturn;
    default:
      return state;
  }
}

initState.currentSalesDocPayments = { original: [], returned: [] };
function currentSalesDocPayments(
  state = initState.currentSalesDocPayments,
  { type, payload },
) {
  switch (type) {
    case c.SET_CURRENT_SALES_DOC_PAYMENTS.SUCCESS:
      return payload;
    case c.RESET_CURRENT_SALES_DOC_PAYMENTS:
    case TYPE_LOGOUT:
    case TYPE_LOGOUT_SOFT:
      return initState.currentSalesDocPayments;
    default:
      return state;
  }
}

function prepaymentPercentages(state = [], { type, payload }) {
  switch (type) {
    case c.GET_PREPAYMENT_PERCENTAGES.SUCCESS:
      return payload;
    default:
      return state;
  }
}

initState.pickupInProgress = false;

function pickupInProgress(
  state = initState.pickupInProgress,
  { type, payload },
) {
  switch (type) {
    case c.SET_PICKUP_IN_PROGRESS:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  currentSale,
  fullResponse,
  fetching,
  pickupInProgress,
  lastSale,
  isCurrentSaleAReturn,
  isCurrentSaleGiftReturn,
  prepaymentPercentages,
  currentSalesDocPayments,
  paymentSale,
  lastPayments,
});

function getSalesSlice(state) {
  return state.sales;
}

export const getTotalVisitsToStore = createSelector(
  getSalesSlice,
  sales => sales.fullResponse.status?.recordsTotal ?? 0,
);

export const getPreviousPurchases = createSelector(
  getSalesSlice,
  sales => sales.fullResponse.records,
);

export function getCurrentSalesDocument(state) {
  return state.sales.currentSale || {};
}

export function getLastPayments(state) {
  return state.sales.lastPayments || [];
}

export function getHasCurrentSalesDocument(state) {
  return !isEmpty(state.sales.currentSale);
}

export function getIsCurrentSaleAReturn(state) {
  return state.sales.isCurrentSaleAReturn;
}

export function getIsCurrentSaleGiftReturn(state) {
  return state.sales.isCurrentSaleGiftReturn;
}

export const getIsSavedSale = createSelector(
  state => getCurrentSalesDocument(state),
  currentDoc =>
    currentDoc?.type === 'CASHINVOICE' &&
    Number(currentDoc.confirmed) === 0 &&
    currentDoc.paymentStatus === 'UNPAID',
);

/**
 * Returns a dictionary of original and returned payments as arrays
 * @param {*} state
 * @returns {{ original: import('types/Payment').Payment[], returned: import('types/Payment').Payment[] }}
 */
export function getCurrentSalesDocPayments(state) {
  return state.sales.currentSalesDocPayments;
}

export const getCurrentSalesDocOriginalPayments = createSelector(
  state => getCurrentSalesDocPayments(state),
  curPayments => curPayments.original,
);

export const getCurrentSalesDocReturnPayments = createSelector(
  state => getCurrentSalesDocPayments(state),
  curPayments => curPayments.returned,
);

export const getCurrentSalesDocPaymentsCombo = createSelector(
  state => getCurrentSalesDocOriginalPayments(state),
  state => getCurrentSalesDocReturnPayments(state),
  state => getPayments(state),
  (originalPayments, returnPayments, currentPayments) =>
    originalPayments.map(pmt => {
      // Handling for old payments that were saved before originalPaymentID attribute was introduced
      if (returnPayments.every(p => !Number(p.attributes?.originalPaymentID))) {
        const spentPreviouslyForThisCard = returnPayments
          .filter(p => p.cardNumber === pmt.cardNumber && p.type === pmt.type)
          .map(p => -Number(p.sum))
          .reduce(add, 0);
        const spentCurrentlyForThisCard = Object.values(currentPayments)
          .filter(p => p?.original?.paymentID === pmt.paymentID)
          .map(p => -Number(p.amount))
          .reduce(add, 0);

        const spent = Math.max(
          0,
          spentPreviouslyForThisCard + spentCurrentlyForThisCard,
        );
        return {
          ...pmt,
          spent,
          remaining: Math.min(pmt.sum - spent, Number(pmt.sum)),
        };
      }

      const spentPreviouslyForThisPayment = returnPayments
        .filter(p => p.attributes?.originalPaymentID === pmt.paymentID)
        .map(p => -Number(p.sum))
        .reduce(add, 0);
      const spentCurrentlyForThisPayment = Object.values(currentPayments)
        .filter(p => p.original?.paymentID === pmt.paymentID)
        .map(p => -Number(p.amount))
        .reduce(add, 0);

      const spent =
        spentPreviouslyForThisPayment + spentCurrentlyForThisPayment;

      return {
        ...pmt,
        spent,
        remaining: Math.min(pmt.sum - spent, Number(pmt.sum)),
      };
    }),
);
export function getUnreturnedPaymentsTotal(state) {
  const pmts = getCurrentSalesDocPaymentsCombo(state);
  return pmts.map(p => p.remaining).reduce(add, 0);
}

export function getIsAReturn(state) {
  return getIsCurrentSaleAReturn(state) || getTotal(state) < 0;
}

export function getIsUnreferencedReturn(state) {
  return !getIsCurrentSaleAReturn(state) && getTotal(state) < 0;
}

export function getReturnTotal(state) {
  return state.shoppingCart.computed.total ?? 0;
}

export function getReturnVATTotal(state) {
  return state.shoppingCart.computed.vatTotal ?? 0;
}

export function getReturnNetTotal(state) {
  return state.shoppingCart.computed.netTotal ?? 0;
}

export const getLastSalesDocument = createSelector(
  () => lastReceiptData.get(),
  lastReceiptData => {
    if (!lastReceiptData) return { salesDoc: {}, payments: {} };
    if (isEmpty(lastReceiptData)) return { salesDoc: {}, payments: {} };

    return lastReceiptData;
  },
);

export function getLastSale(state) {
  return state.sales.lastSale ?? {};
}

export function getReturnIsPartial(state) {
  const productsInCart = getProductsInShoppingCart(state);
  const currentSalesDocument = getCurrentSalesDocument(state);
  const isReturn = getIsAReturn(state);

  const isFullReturn =
    !currentSalesDocument?.previousReturnsExist &&
    currentSalesDocument?.rows?.length === productsInCart.length &&
    currentSalesDocument?.rows?.reduce((result, currentProduct, index) => {
      const isProductInCart = (() => {
        if (productsInCart[index]) {
          const cartAmount = Number(productsInCart[index].amount);
          const invoiceAmount = -Number(currentProduct.amount);
          return cartAmount === invoiceAmount;
        }
        return false;
      })();
      return result && isProductInCart;
    }, true);

  return isReturn && !isFullReturn;
}

export function getIsPickupInProgress(state) {
  return state.sales.pickupInProgress;
}
