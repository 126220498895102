import * as R from 'ramda';

import { ErplyApi } from 'plugins/suretax/api/ErplyApi';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { suretaxLog } from 'plugins/suretax/meta';
import { RequestResponse } from 'plugins/suretax/api/types/Request';
import { chainPromises } from 'utils';

const log = suretaxLog.extend('getRequiredTaxrateUpdates');

/**
 * Compare the current suretax information against the shopping cart.
 * Return a list of updates in the form {orderIndex, vatrateID}
 *
 * Generates new vat rates in ErplyAPI if it can't find an existing one with the required rates
 */
export const getRequiredTaxrateUpdates = (response: RequestResponse) => async (
  dispatch,
  getState,
) => {
  const cart = getProductsInShoppingCart(getState());
  const taxes = cart.map(inCart => {
    const taxGroup = response.GroupList.find(
      gr => String(inCart.rowNumber) === gr.LineNumber,
    );
    // Suretax will not return a taxGroup if the product is tax-free
    // Therefore default to 0 tax
    const totalTax =
      taxGroup?.TaxList.map(tax => tax.TaxAmount).reduce(R.add, 0) ?? 0;
    const totalNet = inCart.rowNetTotal;

    return {
      orderIndex: inCart.orderIndex,
      vatRate: (100 * totalTax) / totalNet,
      net: totalNet,
      taxWanted: totalTax,
      taxCurrent: inCart.rowVATTotal,
    };
  });

  log('Calculated required taxes from response', { taxes }, { response, cart });

  log('Mapping through orders');
  const updates: { orderIndex: number; vatrateID: number }[] = [];
  const subLog = log.extend('chainPromises');
  await chainPromises(
    ...taxes.map(tax => async () => {
      const { net, vatRate, taxCurrent, taxWanted, orderIndex } = tax;
      // We don't need the exact perfect tax rate, as long as the resulting price is correct
      // Matching vat rates would cause every unique price to generate a new rate
      // Even if an existing rate would round to the same total anyway
      const needsUpdate = taxCurrent.toFixed(2) !== taxWanted.toFixed(2);
      if (!needsUpdate) return;
      subLog('Order needs to update, trying to find a tax rate', tax);
      let vatrateID = await ErplyApi.findTaxRate(net, taxWanted, 2);
      if (!vatrateID) {
        subLog('None found, creating a new one');
        vatrateID = await ErplyApi.createTaxRate(vatRate);
      }
      subLog('Will set to vatrate', vatrateID);
      updates.push({ orderIndex, vatrateID });
    }),
  );
  log('Found the following required updates', updates);
  return updates;
};
