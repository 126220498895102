export enum UnitTypeCodes {
  /** Default value for non-telecom services. */
  DEFAULT = '00',
  /** Access line */
  COMMUNICATIONS = '00',

  /** Utilities - kWh */
  kWh = '03',
  /** Utilities - THERM */
  THERM = '04',
  /** Utilities - Meter */
  METER = '06',
  /** Utilities - BCF (1 Billion Cubic Feet) */
  BCF = '07',
  /** Utilities - dTh (dekatherm */
  dTh = '08',
  /** Utilities - mWh (1000 kWh) */
  mWh = '09',
  /** Utilities - kW (Kilowatts) */
  kW = '10',
  /** Utilities - mW (Megawatts) */
  mW = '11',
  /** Utilities - CCF (100 Cubic Feet) */
  CCF = '12',
  /** Utilities - MCF (1000 Cubic Feet) */
  MCF = '14',
  /** Utilities - Canadian THERMS */
  THERM_CA = '18',
  /** Utilities - Per Liter */
  LITER = '21',
  /** Utilities - Per Gallon */
  GALLON = '22',
}
