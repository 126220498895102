import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './BottomPopup.css';
import { getLastSalesDocument } from 'reducers/sales';
import { isEmpty } from 'utils';
import { addWarning, addError } from 'actions/Error';
import * as PrinterIntegration from 'actions/integrations/printer';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages as mp } from 'constants/modalPage';
import { getHasRightToViewZReport } from 'reducers/Login';

const QuickPrintView = ({ goBack, disabledInOffline, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['gridButtons', 'alerts']);
  const { salesDoc: lastInvoice, payments: receiptPayments } = useSelector(
    getLastSalesDocument,
  );

  const rightToViewZReport = useSelector(getHasRightToViewZReport);

  const reprintLastReceipt = () => {
    if (!lastInvoice.receiptLink && isEmpty(receiptPayments)) {
      return dispatch(
        addWarning(t('alerts.noLastSale'), { selfDismiss: true }),
      );
    }
    return dispatch(
      PrinterIntegration.printReceipt({
        payments: receiptPayments,
        ...lastInvoice,
      }),
    );
  };

  const printGiftReceipt = () => {
    if (!lastInvoice.receiptLink && isEmpty(receiptPayments)) {
      return dispatch(
        addWarning(t('alerts.noLastSale'), {
          dismissible: false,
          selfDismiss: true,
        }),
      );
    }
    return dispatch(
      PrinterIntegration.printGiftReceipt({
        payments: receiptPayments,
        ...lastInvoice,
      }),
    );
  };

  const printZreport = () => {
    if (!rightToViewZReport) {
      dispatch(addError(t('noZReportViewRights', { ns: 'alerts' })));
    } else {
      dispatch(
        openModalPage({
          component: mp.ZReport,
        }),
      );
      close();
    }
  };

  return (
    <>
      <span
        className="mobile-menu-action"
        style={disabledInOffline}
        onClick={reprintLastReceipt}
      >
        {t('saleOptions.lastReceipt')}
      </span>
      <span
        className="mobile-menu-action"
        onClick={printGiftReceipt}
        style={{ marginTop: '14px' }}
      >
        {t('saleOptions.printGiftReceipt')}
      </span>
      <span
        className="mobile-menu-action btn-save"
        onClick={printZreport}
        style={{ marginTop: '14px' }}
      >
        {t('functions.zReport')}
      </span>
      <span
        className="btn-cancel mobile-menu-action"
        onClick={() => goBack()}
        style={{ marginTop: '28px' }}
      >
        {t('common:back')}
      </span>
    </>
  );
};

export default QuickPrintView;
