export const SET_INTEGRATION_TYPE = 'INTEGRATIONS: SET_TYPE';
export const SET_INTEGRATION_STATUS = 'INTEGRATIONS: SET_STATUS';
export const INSTALL_INTEGRATIONS = 'INTEGRATIONS: INSTALL';
export const FETCH_LOCAL_INTEGRATIONS = {
  START: 'FETCH_LOCAL_INTEGRATIONS_START',
  SUCCESS: 'FETCH_LOCAL_INTEGRATIONS_SUCCESS',
  ERROR: 'FETCH_LOCAL_INTEGRATIONS_ERROR',
};

export const FETCH_LATEST_VERSION = {
  START: 'FETCH_LATEST_VERSION_START',
  SUCCESS: 'FETCH_LATEST_VERSION_SUCCESS',
  ERROR: 'FETCH_LATEST_VERSION_ERROR',
};
