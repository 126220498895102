import * as R from 'ramda';

export class ErplyAttributes extends Object {
  data: { [key: string]: any } = {};

  constructor(from?: any) {
    super({});
    if (!from) return;
    // eslint-disable-next-line consistent-return
    if (from.constructor === ErplyAttributes) return from;
    // Parse array format
    if (from?.map) {
      from.forEach(
        ({
          attributeName: key,
          attributeType: type,
          attributeValue: value,
        }) => {
          this.data[key] = ErplyAttributes.fromTypeValue(type, value);
        },
      );
      return;
    }
    // parse flatArray format
    const matches = Object.keys(from)
      .map(k => k.match(/^attribute(Name|Value|Type)(\d+)$/))
      .filter(i => i !== null);
    if (matches.length) {
      matches.forEach(k => {
        if (k) {
          const index = k[2];
          this.data[
            from[`attributeName${index}`]
          ] = ErplyAttributes.fromTypeValue(
            from[`attributeType${index}`],
            from[`attributeValue${index}`],
          );
        }
      });
      return;
    }

    if (from.constructor === Object) {
      this.data = from;
    }
  }

  private static fromTypeValue(type, value) {
    if (type === 'int') return Number(value);
    if (type === 'double') return Number(value);
    return value;
  }

  static fromFlatArray(from) {
    const obj = new ErplyAttributes({});
    const matches = Object.keys(from)
      .map(k => k.match(/^attribute(Name|Value|Type)(\d+)$/))
      .filter(i => i !== null);
    matches.forEach(k => {
      if (k) {
        const index = k[2];
        obj.set(
          from[`attributeName${index}`],
          ErplyAttributes.fromTypeValue(
            from[`attributeType${index}`],
            from[`attributeValue${index}`],
          ),
        );
      }
    });
    return obj;
  }

  static withoutFlatArray(obj) {
    return R.pickBy(
      (value, key) => !R.test(/^attribute(Name|Value|Type)(\d+)$/)(String(key)),
    )(obj);
  }

  /** @param {ErplyAttributes} otherAttrs */
  merge(otherAttrs) {
    return new ErplyAttributes({
      ...this.asDict,
      ...otherAttrs.asDict,
    });
  }

  get(name) {
    return this.data[name];
  }

  set(name, value) {
    this.data[name] = value;
    return this;
  }

  remove(name) {
    delete this.data[name];
    return this;
  }

  /**
   * {
   *   foo: 'foovalue',
   *   bar: 2
   * }
   */
  get asDict() {
    return this.data;
  }

  /**
   * @example [
   *   {
   *     attributeName: 'foo',
   *     attributeType: 'text',
   *     attributeValue: 'foovalue',
   *   }, {
   *     attributeName: 'bar',
   *     attributeType: 'int',
   *     attributeValue: 2,
   *   }
   * ]
   */
  get asArray(): {
    attributeName: string;
    attributeType: string;
    attributeValue: any;
  }[] {
    return R.pipe(
      R.toPairs,
      R.map(([k, v]) => {
        if (typeof v === 'boolean') return [k, 'int', v ? 1 : 0];
        if (typeof v === 'number')
          if (v % 1 === 0) return [k, 'int', v];
          else return [k, 'double', v];
        return [k, 'text', v];
      }),
      R.map(([k, t, v]) => ({
        attributeName: k,
        attributeType: t,
        attributeValue: v,
      })),
    )(this.data) as any;
  }

  /**
   * @example {
   *   attributeName1: 'foo',
   *   attributeType1: 'text',
   *   attributeValue1: 'foovalue',
   *   attributeName2: 'bar',
   *   attributeType2: 'int',
   *   attributeValue2: 2,
   * }
   */
  get asFlatArray() {
    return Object.fromEntries(
      this.asArray.flatMap((obj, i) =>
        Object.entries(obj).map(([k, v]) => [`${k}${i + 1}`, v]),
      ),
    );
  }
}

