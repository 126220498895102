import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getClientCode, getSessionKey } from 'reducers/Login';
import { getSelectedPosID } from 'reducers/PointsOfSale';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getMSEndpointFromLS } from 'reducers/installer';

const baseUrls = {
  get logger() {
    return getMSEndpointFromLS('logger-ms') ?? 'https://localhost.erply.com:10900/'
  },
  get installer() {
    return 'https://localhost.erply.com:7777/'
  }
}
const endpoints = [
  /** logger-ms */
  data =>
    window.fetch(`${baseUrls.logger}registerDevice`, {
      method: 'GET',
      headers: data,
    }),
  /** installer */
  data =>
    window.fetch(`${baseUrls.installer}api/v1/registerDevice`, {
      method: 'POST',
      headers: data,
    }),
];

/** Register this hardware device as belonging to a certain clientcode+warehouse+POS combo */
export const useRegisterDevice = () => {
  const clientCode = useSelector(getClientCode);
  const sessionKey = useSelector(getSessionKey);
  const posId = useSelector(getSelectedPosID);
  const warehouseId = useSelector(getSelectedWarehouseID);

  useEffect(() => {
    // NOSONAR async used not for the return value, but for readability of code
    endpoints.forEach(async doRequest => {
      try {
        const headers = {
          Clientcode: clientCode,
          Sessionkey: sessionKey,
          WarehouseId: warehouseId,
          Posid: posId,
        };
        await doRequest(headers);
      } catch (err) {
        console.warn(
          'Unable to register device, the service might not be running',
          err,
        );
      }
    });
  }, [clientCode, sessionKey, posId, warehouseId]);
};
