/* eslint-disable no-console */
import i18next from 'i18next';

import { getCardPayments } from 'reducers/Payments';
import { getCafaEntry2 } from 'reducers/cafaConfigs';
import { INTEGRATION_TYPES } from 'constants/CAFA';
import { addError, addSuccess, addWarning } from 'actions/Error';
import {
  deletePayment,
  markForProcessing,
  unmarkFromProcessing,
} from 'actions/Payments';
import { openTerminalIntegration } from 'actions/integrations/terminal';

import { CardPaymentHooks, FUNC_BUTTONS } from '../types';

import nets from './requests';
import printNets, { print } from './handlers/printing';
import { NETS_CONST, NetsConfig } from './types';
import initialSetup from './handlers/initSetup';
import processPayments from './handlers/processPayments';

export const title = 'NETS Terminal Interface';

export const initPayments = (hooks: CardPaymentHooks) => async (
  dispatch,
  getState,
) => {
  const { enableButtons, resolvePayments, rejectPayments } = hooks;
  const cardPayments = getCardPayments(getState());
  enableButtons([FUNC_BUTTONS.CANCEL]);
  const shouldVoid = cardPayments.every(p => p.paid && p.shouldProcess);
  const filteredCardPayments = cardPayments.filter(p =>
    shouldVoid ? p.paid : !p.paid,
  );
  dispatch(processPayments(filteredCardPayments, hooks))
    .then(wasOK => {
      if (!wasOK) {
        enableButtons([
          FUNC_BUTTONS.RETRY,
          FUNC_BUTTONS.RETURN_BACK,
          FUNC_BUTTONS.REPRINT,
        ]);
      } else if (shouldVoid) {
        rejectPayments();
        dispatch(
          addSuccess(
            i18next.t('paymentIntegrations:transactionMessages.voidSuccessful'),
            {
              selfDismiss: true,
            },
          ),
        );
      } else {
        resolvePayments();
      }
    })
    .catch(e => console.error('Failed to process payments with NETS cloud', e));
};

const printLastReceipt = () => async (dispatch, getState) => {
  try {
    const config = getCafaEntry2<Required<NetsConfig>>(
      NETS_CONST.CAFA_CONFIG_NAME,
      INTEGRATION_TYPES.payment,
    )(getState());
    if (
      config[NETS_CONST.CONFIG.PRINT.CUSTOMER] ===
        NETS_CONST.CONFIG.OPTIONS.NEVER &&
      config[NETS_CONST.CONFIG.PRINT.MERCHANT] ===
        NETS_CONST.CONFIG.OPTIONS.NEVER
    ) {
      return dispatch(
        addWarning(
          i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.disabledPrinting`),
          {
            dismissible: false,
            selfDismiss: true,
          },
        ),
      );
    }
    const { data } = await nets.printLatestTransactionReceipt({
      config,
      params: { terminalId: config.terminal },
    });
    if (data.result) {
      await dispatch(print(data.result.printText.Text));
    } else {
      dispatch(
        addError(
          i18next.t(
            `${NETS_CONST.TRANS_PATH}.alerts.failedReprintNoTransaction`,
          ),
          {
            dismissible: false,
            selfDismiss: true,
          },
        ),
      );
    }
  } catch (err) {
    dispatch(
      addError(i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.failedReprint`), {
        dismissible: false,
        selfDismiss: true,
      }),
    );
  }
  return true;
};

const cancelCurrentPayment = ({
  enableButtons,
  cardPayments,
}: CardPaymentHooks) => async (dispatch, getState) => {
  enableButtons([]);
  const config = getCafaEntry2<Required<NetsConfig>>(
    NETS_CONST.CAFA_CONFIG_NAME,
    INTEGRATION_TYPES.payment,
  )(getState());
  await Promise.all(
    cardPayments.map(p => dispatch(unmarkFromProcessing({ key: p.key }))),
  );
  return nets.cancelOngoing({
    config,
    params: { terminalId: config.terminal },
  });
};

export const cancelPayments = (params: CardPaymentHooks) => async dispatch => {
  try {
    await dispatch(cancelCurrentPayment(params));
  } catch (error) {
    console.error('Failed to cancel payments with NETS cloud', error);
  } finally {
    params.rejectPayments();
  }
};

export const functions = [
  {
    actionOnClick: initPayments,
    text: 'retry',
    name: FUNC_BUTTONS.RETRY,
    variant: 'success',
  },
  {
    actionOnClick: printLastReceipt,
    text: 'Print last receipt',
    name: FUNC_BUTTONS.REPRINT,
    variant: 'success',
  },
  {
    actionOnClick: cancelCurrentPayment,
    text: 'Cancel',
    name: FUNC_BUTTONS.CANCEL,
    variant: 'danger',
  },
  {
    actionOnClick: cancelPayments,
    text: 'Return back',
    name: FUNC_BUTTONS.RETURN_BACK,
    variant: 'light',
  },
];

export const voidPayments = () => async (dispatch, getState) => {
  getCardPayments(getState()).forEach(({ key, paid }) => {
    if (paid) {
      dispatch(markForProcessing({ key }));
    } else {
      dispatch(deletePayment({ key }));
    }
  });

  if (getCardPayments(getState())?.length) {
    await dispatch(openTerminalIntegration());
  }
};

export const integrationSetup = () => async dispatch => {
  const warn = warning =>
    dispatch(
      addWarning(warning, {
        dismissible: false,
        selfDismiss: 3500,
      }),
    );
  dispatch(initialSetup(warn));
};

export const integrationCleanup = () => async () => {
  sessionStorage.removeItem(NETS_CONST.TOKEN);
  sessionStorage.removeItem(NETS_CONST.TERMINAL);
  sessionStorage.removeItem(NETS_CONST.ALL_TERMINALS);
  sessionStorage.removeItem(NETS_CONST.ENV);
};

export const closeBatch = () => async (dispatch, getState) => {
  try {
    const config = getCafaEntry2<Required<NetsConfig>>(
      NETS_CONST.CAFA_CONFIG_NAME,
      INTEGRATION_TYPES.payment,
    )(getState());
    const { data } = await nets.reconciliation({
      config,
      params: { terminalId: config.terminal },
    });
    if (data.result.reconciliation.printText)
      dispatch(print(data.result.reconciliation.printText.Text));

    return true;
  } catch (err) {
    console.error('Failed to close batch in NETS cloud', err);
    return false;
  }
};

export const downloadDataset = ({ terminalId }) => async (
  dispatch,
  getState,
) => {
  const config = getCafaEntry2<Required<NetsConfig>>(
    NETS_CONST.CAFA_CONFIG_NAME,
    INTEGRATION_TYPES.payment,
  )(getState());
  const { data } = await nets.downloadDataset({
    config,
    params: { terminalId },
  });
  if (data.result.printText) dispatch(printNets(data.result.printText.Text));
};

export const downloadSoftware = () => async (dispatch, getState) => {
  const config = getCafaEntry2<Required<NetsConfig>>(
    NETS_CONST.CAFA_CONFIG_NAME,
    INTEGRATION_TYPES.payment,
  )(getState());
  return nets
    .downloadSoftware({ config, params: { terminalId: config.terminal } })
    .then(() =>
      dispatch(
        addSuccess(
          i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.softwareUpdateSuccess`),
          {
            dismissible: false,
            selfDismiss: true,
          },
        ),
      ),
    )
    .catch(err => {
      dispatch(
        addWarning(
          i18next.t(`${NETS_CONST.TRANS_PATH}.alerts.softwareUpdateFail`),
          {
            dismissible: false,
            selfDismiss: true,
          },
        ),
      );
      throw err;
    });
};
