import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import { PluginComponent } from 'plugins';
import { SaleDocumentResponse } from 'types/SalesDocument';

import ToggleRow from './ToggleRow';
import SaleInfoRows from './SaleInfoRows';

import './index.scss';

type Props = {
  sale: SaleDocumentResponse;
  showName: boolean;
  isSaleFullyReturned: boolean;
};

const Panel: React.FC<Props> = ({ sale, showName, isSaleFullyReturned }) => {
  const [active, setActive] = useState('');

  const idAsString = String(sale.id);

  return (
    <Accordion
      data-testid="previous-purchase-row"
      data-test-key={sale.number}
      activeKey={active}
    >
      <Card className="card-style">
        <Accordion.Toggle
          as={Card.Header}
          onClick={() => setActive(active === idAsString ? '' : idAsString)}
          eventKey={idAsString}
        >
          <ToggleRow
            sale={sale}
            showName={showName}
            isSaleFullyReturned={isSaleFullyReturned}
          />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={idAsString}>
          <Card.Body className="no-padding">
            <PluginComponent hookname="UIRecentSalesInfoRow" props={sale}>
              <SaleInfoRows saleNumber={sale.number} saleRows={sale.rows} />
            </PluginComponent>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default Panel;
