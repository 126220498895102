import React from 'react';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import InputField from 'components/FieldTypes/InputField';
import styles from 'components/FieldTypes/skins/skins.module.scss';

import { form } from './formShapes';

const CompanyForm = ({ state, onChange }) => {
  const handleChange = e => {
    const { target } = e;
    onChange(target.name, target.value);
  };
  const { t } = useTranslation('settingsCompany');
  return (
    <Form style={{ width: '100%', overflowY: 'auto', paddingTop: '1em' }}>
      {form.company.map(field => (
        <Form.Group key={field.key} controlId="companyInfoFields">
          <InputField
            size="lg"
            name={field.key}
            title={
              /* i18next-extract-mark-context-next-line ["name","code","VAT","cc"] */
              t(`form.${field.key}`)
            }
            type={field.type}
            className={classNames([styles.formInput, styles.mediumTitle])}
            value={state[field.key] || ''}
            onChange={handleChange}
            disabled={field.disabled}
          />
        </Form.Group>
      ))}
      <h3 className="my-4" style={{ fontWeight: 700 }}>
        {t('location')}
      </h3>
      {form.location.map(field => (
        <Form.Group key={field.key} controlId="locationInfoFields">
          <InputField
            size="lg"
            title={
              /* i18next-extract-mark-context-next-line ["posName","warehouseName","phone"] */
              t(`form.${field.key}`)
            }
            name={field.key}
            type={field.type}
            className={classNames([styles.formInput, styles.mediumTitle])}
            value={state[field.key]}
            onChange={handleChange}
            disabled={field.disabled}
          />
        </Form.Group>
      ))}
      <span style={{ fontWeight: 700 }}>{t('address')}</span>
      {form.address.map(field => (
        <Form.Group key={field.key} controlId="locationAddressInfoFields">
          <InputField
            size="lg"
            title={
              /* i18next-extract-mark-context-next-line ["street","address2","zip","city","state","country"] */
              t(`form.${field.key}`).toUpperCase()
            }
            name={field.key}
            type={field.type}
            className={styles.formInput}
            value={state[field.key]}
            onChange={handleChange}
          />
        </Form.Group>
      ))}
    </Form>
  );
};

CompanyForm.propTypes = {};

export default CompanyForm;
