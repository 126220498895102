import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import { PosPlugin } from 'plugins/plugin';

import { getSAINumber } from '../redux/selectors';

const BreakingText = styled(Typography)({
  wordBreak: 'break-all',
});

const UITableBill: Required<PosPlugin>['UITableBill'] = ({ children }) => {
  const SAINumber = useSelector(getSAINumber);

  if (SAINumber === null) return children;

  return [
    <BreakingText variant="h6">SAI number: {SAINumber}</BreakingText>,
    children,
  ];
};

export default UITableBill;
