import i18next from 'i18next';

import { SET_STORE_REGION } from 'constants/storeRegions';
import * as api from 'services/ErplyAPI/storeRegions';
import { getLoggedInWarehouse } from 'reducers/warehouses';
import { Warehouse } from 'types/Warehouse';

import { addError } from './Error';

export function getStoreRegionAssociatedWithSelectedWarehouse() {
  return async (dispatch, getState) => {
    try {
      const selectedWarehouse: Warehouse = getLoggedInWarehouse(getState());

      if (!selectedWarehouse)
        throw new Error(i18next.t('alerts:errors.earlyStoreRegionFetch'));
      if (!selectedWarehouse.storeRegionID) return;

      const storeRegions = await api.getStoreRegions({
        getPriceLists: 1,
        getCustomerGroupPriceLists: 1,
      });
      const selectedStoreRegion = storeRegions.find(
        storeRegion =>
          Number(storeRegion.storeRegionID) ===
          Number(selectedWarehouse.storeRegionID),
      );
      dispatch({ type: SET_STORE_REGION, payload: selectedStoreRegion });
    } catch (e) {
      dispatch(
        addError(
          `${i18next.t('alerts:errors.failedToFetchStoreRegion')} ${(e as Error)
            .message || ''}`,
        ),
      );
    }
  };
}
