import { PosPlugin } from 'plugins/plugin';

import { nohblePluginMeta } from './meta';
import { addSaveOrderButton } from './SpecialOrders/SaveAsSpecialOrder/GridButton';
import { onSaveOrder } from './SpecialOrders/SaveAsSpecialOrder/onSaveOrder';
import { addPickupOrderButton } from './SpecialOrders/PickupSpecialOrder/GridButton';
import { CreateSpecialOrderModal } from './SpecialOrders/SaveAsSpecialOrder/CreateSpecialOrderModal';
import { PickupSpecialOrderModal } from './SpecialOrders/PickupSpecialOrder/PickupSpecialOrderModal';
import description from './description.md';

export const NohblePlugin: PosPlugin = {
  id: nohblePluginMeta.id,
  name: '[N5] customizations',
  keywords: ['nohble', 'special', 'orders', 'stock', 'inventory', 'transfers'],
  infoMDUrl: description,


  components: {
    [CreateSpecialOrderModal.id]: CreateSpecialOrderModal.Component,
    [PickupSpecialOrderModal.id]: PickupSpecialOrderModal.Component,
  },
  selectorOverrides: {
    getSaleOptionButtons: addSaveOrderButton,
    getFunctionButtons: addPickupOrderButton,
  },
  onSaveSalesDocument: {
    after: onSaveOrder.after,
  },
};
