import { getPluginSelector, getPluginConfiguration } from 'reducers/Plugins';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';

import { ExchangeProcessState } from './reducers';

export const getExchangeState = state =>
  getPluginSelector<ExchangeProcessState>('pnp-process-exchanges')(n => n)(
    state,
  );

export const getTagsSlice = () =>
  getPluginSelector('pnp-tag-returned-products')(state => state);

const getConfiguration = state =>
  getPluginConfiguration<{
    admissionFeeName: string;
    admissionTaxName: string;
    CAFeeEnabled: boolean;
    envFeeEnabled: boolean;
    envFeeName: string;
    code5names: string;
    productsThatWaiveAdmisisonFee: string;
    envFeeProduct: null | Record<string, any>;
    admissionFeeProduct: null | Record<string, any>;
  }>('environmental-&-CA-fees')(state) ?? {
    admissionFeeName: '',
    admissionTaxName: '',
    CAFeeEnabled: false,
    envFeeEnabled: false,
    envFeeName: '',
    code5names: '',
    productsThatWaiveAdmisisonFee: '',
    envFeeProduct: null,
  };

export const hasEnvProduct = (state): boolean => {
  const envFeeConfig = getConfiguration(state);
  const { envFeeName } = envFeeConfig;
  const productsInCart = getProductsInShoppingCart(state);

  if (productsInCart.length) {
    const envFeeProduct = productsInCart.find(
      product => product?.name?.toUpperCase() === envFeeName?.toUpperCase(),
    );
    if (envFeeProduct) {
      return true;
    }
  }
  return false;
};
