import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import FileInput from 'components/FieldTypes/types/file';

import CheckboxInput from './types/checkbox';
import NormalInput from './types/normal';
import SelectInput from './types/select';

InputField.propTypes = {
  /** The title to display on the left side of the input field */
  title: propTypes.string,
  /* Some text or React element to display on the right side of the input field */
  appendTitle: propTypes.any,
  /** The size of field to display */
  size: propTypes.oneOf(['sm', 'md', 'lg']),
  /** Whether to display the input in a success state */
  isValid: propTypes.bool,
  /** Whether to display the input in an error state */
  isInvalid: propTypes.bool,
  /** A function to automatically format/filter input
   * @param oldVal
   * @param newVal
   * @returns The new value to change the field to
   */
  formatter: propTypes.func,
  /** The type of input to display (text, email, password, select, checkbox, etc.) */
  type: propTypes.string,
  /** The value of the input */
  value: propTypes.any.isRequired,
  /** The onChange callback of the input (always returns an Event object with a .target.value) */
  onChange: propTypes.func.isRequired,
  /** (valid for checkbox only) The element to display next to the checkbox */
  children: propTypes.node,
  /** A skin function to modify the look of this element (see ../InputTypes/skins) */
  skin: propTypes.func,
  /** (valid for select only) The element to use as the select icon */
  selectIcon: propTypes.node,
  /** Callback to be executed with the event.target.value as unique parameter */
  customCallback: propTypes.func,
};
export default function InputField({
  title = "",
  size = 'md',
  isValid = false,
  isInvalid = false,
  type = 'text',
  value,
  formatter = (newVal, oldVal) => newVal,
  onChange = (e) => {},
  children = undefined,
  isRequired = false,
  skin = () => ({}),
  selectIcon = undefined,
  numeric = false,
  customCallback = undefined,
  /** For file inputs, this preprocesses e.target.value */
  parseDataUrl = undefined,
  ...rest
}) {
  if (rest.onSubmit) {
    const originalOnKeyDown = rest.onKeyDown;
    rest.onKeyDown = e => {
      if (e.key === 'Enter') {
        rest.onSubmit(e);
      } else {
        originalOnKeyDown && originalOnKeyDown(e);
      }
    };
  }

  if (rest.errorText) {
    isInvalid = true;
  }
  const titleNode = isRequired ? (
    <span title="Field is required">
      {title}
      <sup style={{ fontWeight: 700 }}>*</sup>
    </span>
  ) : (
    title
  );

  useEffect(() => {
    if (formatter) {
      if (value !== formatter(value)) {
        if (formatter(value) !== formatter(formatter(value))) {
          console.error('Infinitely recursive formatter denied');
          return;
        }
        onChange({ target: { name: rest.name, value: formatter(value) } });
      }
    }
  }, [value, formatter]);

  switch (type) {
    case 'file':
      return (
        <FileInput
          {...{
            size,
            title: titleNode,
            type,
            value,
            onChange: e => {
              if (onChange) {
                onChange(e);
              }
            },
            isValid,
            isInvalid,
            // eslint-disable-next-line prefer-rest-params
            skin: skin(...arguments),
            ...rest,
          }}
        />
      );
    case 'checkbox':
      return (
        <CheckboxInput
          {...{
            size,
            value: numeric ? (value ? 1 : 0) : value,
            onClick: e => {
              e.target.name = rest.name;
              // eslint-disable-next-line no-nested-ternary
              e.target.value = numeric ? (!value ? 1 : 0) : !value;
              if (onChange) {
                onChange(e);
              }
              if (customCallback) {
                customCallback(e.target.value);
              }
            },
            children,
            // eslint-disable-next-line prefer-rest-params
            skin: skin(...arguments),
            ...rest,
          }}
        />
      );
    case 'select':
      // implement a onChange to detect if value is not within options
      return (
        <SelectInput
          {...{
            size,
            title: titleNode,
            value,
            onChange,
            isValid,
            isInvalid,
            // eslint-disable-next-line prefer-rest-params
            skin: skin(...arguments),
            selectIcon,
            ...rest,
          }}
        />
      );

    default:
      return (
        <NormalInput
          {...{
            size,
            title: titleNode,
            type,
            as: {
              textarea: 'textarea',
            }[type],
            value,
            onChange: e => {
              if (formatter) {
                e.target.value = formatter(e.target.value, value);
              }
              if (onChange) {
                onChange(e);
              }
            },
            isValid,
            isInvalid,
            // eslint-disable-next-line prefer-rest-params
            skin: skin(...arguments),
            ...rest,
          }}
        />
      );
  }
}
