import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';

import { PosPlugin } from 'plugins/plugin';
import SaveButton from 'components/CustomButtons/SaveButton';
import InputField from 'components/FieldTypes/InputField';
import {
  getCurrencyFormatterNoSymbol,
  getDateFormatter,
} from 'reducers/configs/settings';

import { multipleInvoicePayments } from '../rdx/actions';
import { openPaymentModal } from "actions/modalPage";

/**
 * Replace the unpaid invoices screen with one that supports selecting multiple sales
 * Once selected, all sales are passed into {@link multipleInvoicePayments}
 */
const UIUnpaidInvoices: PosPlugin['UIUnpaidInvoices'] = ({
  sales,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('unpaidInvoice');

  const formatDate = useSelector(getDateFormatter);
  const CURR = useSelector(getCurrencyFormatterNoSymbol);

  const [selectedSales, setSelectedSales] = useState<Record<string, any>[]>([]);

  return (
    <>
      <div>
        <SaveButton
          action={() =>
            dispatch(multipleInvoicePayments(selectedSales))
          }
          disabled={selectedSales.length ? false : true}
          title="PAY"
          style={{ width: '250px', margin: 'auto' }}
        />
      </div>
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th>{t('headers.number')}</th>
            <th>{t('headers.date')}</th>
            <th>{t('headers.customer')}</th>
            <th>{t('headers.email')}</th>
            <th>{t('headers.paid')}</th>
            <th>{t('headers.sum')}</th>
          </tr>
        </thead>
        <tbody>
          {sales.map(sale => (
            <tr key={sale.id} style={{ cursor: 'pointer' }}>
              <td>
                <InputField
                  type="checkbox"
                  value={selectedSales.some(
                    selectedSale => selectedSale.id === sale.id,
                  )}
                  data-testid="product-row-toggle"
                  disabled={selectedSales.some(selected => selected.clientID !== sale.clientID)}
                  onChange={() => {
                    if (
                      selectedSales.some(
                        selectedSale => selectedSale.id === sale.id,
                      )
                    ) {
                      setSelectedSales(
                        selectedSales.filter(ss => ss.id !== sale.id),
                      );
                    } else {
                      setSelectedSales([...selectedSales, sale]);
                    }
                  }}
                />
              </td>
              <td>{sale.number}</td>
              <td>{formatDate(sale.date)}</td>
              <td>{sale.clientName}</td>
              <td>{sale.clientEmail}</td>
              <td>{CURR.stringify(sale.paid)}</td>
              <td>{CURR.stringify(sale.total)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default UIUnpaidInvoices;
