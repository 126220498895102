import * as api from 'services/ErplyAPI/customers';

export async function getOneCustomer(customerID, searchCode = '') {
  if (!customerID && searchCode.length === 0)
    throw new Error('Customer ID or searchCode is missing');

  const [data] = await api.getCustomers({
    customerID,
    getAddresses: 1,
    getBalanceInfo: 1,
    getBalanceWithoutPrepayments: 1,
    searchCode,
  });
  api.logProcessingOfCustomerData(`${customerID}`);
  const [{ points }] = await api.getCustomerRewardPoints(data.customerID);
  return { customer: data, points };
}
