import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';

import ComponentConfiguration from './components/ComponentConfiguration';

export const PLUGIN_ID = 'hide_global_alerts';

export const DESCRIPTION =
  'This plugin overrides ErplyAlert component and the hides from the user all the messages added to the plugin configuration.';

type PluginConf = {
  alertBlackList: string[];
};

const hideGlobalAlerts: PosPlugin = {
  id: PLUGIN_ID,
  name: 'Hide alert messages',
  info: DESCRIPTION,
  keywords: ['alert', 'override', 'message', 'notification', 'hide'],
  getStatus: () => ({ type: 'valid', message: 'Ready' }),
  ComponentConfiguration,
  onAddAlert: {
    before: p => async (_dispatch, getState) => {
      const configuration: {
        alertBlackList: string[];
      } = getPluginConfiguration<PluginConf>(PLUGIN_ID)(getState());
      const { alertBlackList = [] } = configuration ?? {};

      if (alertBlackList.some(abl => abl.includes(p.text))) {
        throw new Error('Dispatching blacklisted error');
      }
    },
  },
};

export default hideGlobalAlerts;
