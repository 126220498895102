import { RootState } from 'reducers';

import * as c from '../../constants/UI';

function enabled(state = false, { type }: { type: string }) {
  switch (type) {
    case c.TOGGLE_MANUAL_EDIT_MODE:
      return !state;
    default:
      return state;
  }
}

export default enabled;

export function getIsManualEditMode(state: RootState) {
  return state.UI.manualEditMode;
}
