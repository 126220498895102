import { createSelector } from 'reselect';

import { SO } from 'services/DB/types';
import * as types from 'constants/cachedItems';
import { TYPE_LOGOUT } from 'constants/Login';
import { TYPE_SET_CLOUD_STATUS } from 'constants/connectivity';
import { Product } from 'types/Product';

const initState = {
  [SO.PRODUCTS.NAME]: {
    ready: false,
    records: {},
  },
  [SO.PRODUCT_STOCK.NAME]: {
    ready: false,
    records: {},
  },
  [SO.PRICE_LIST.NAME]: {
    ready: false,
    records: {},
  },
  [SO.EMPLOYEES.NAME]: {
    ready: false,
    records: {},
  },
  [SO.CUSTOMERS.NAME]: {
    ready: false,
    records: {},
  },
  [SO.ASSORTMENTS.NAME]: {
    ready: false,
    records: {},
  },
};

export function cachedItems(state = initState, { type, payload }) {
  switch (type) {
    case types.ADD_TO_CACHE:
      return {
        ...state,
        [payload.itemType]: {
          ...state[payload.itemType],
          records: { ...state[payload.itemType].records, ...payload.items },
        },
      };
    case types.SET_CACHE:
      return {
        ...state,
        [payload.itemType]: {
          ready: true,
          records: payload.items,
        },
      };
    case TYPE_SET_CLOUD_STATUS:
      if (!state[payload.name]) return state;
      return {
        ...state,
        [payload.name]: {
          ...state[payload.name],
          ready: !payload.statusUpdate.syncing,
        },
      };
    case types.REMOVE_FROM_CACHE:
      // eslint-disable-next-line no-case-declarations
      const newState = { ...state[payload.itemType].records };
      payload.ids.forEach(id => {
        delete newState[id];
      });
      return {
        ...state,
        [payload.itemType]: { ...state[payload.itemType], records: newState },
      };
    case types.RESET_CACHE: {
      if (payload.itemType !== SO.PRODUCTS.NAME)
        return { ...state, [payload.itemType]: { ready: false, records: {} } };

      const currentItems = Object.entries(
        state[payload.itemType]?.records ?? {},
      );
      const remainingProducts = currentItems.filter(
        ([productID, product]) =>
          (payload.excludeProductIDs ?? []).includes(Number(productID)) ||
          payload.excludeProductGroupIDs ===
            Number((product as Product).groupID),
      );
      return {
        ...state,
        [payload.itemType]: {
          ready: false,
          records: Object.fromEntries(remainingProducts),
        },
      };
    }
    case TYPE_LOGOUT:
      return initState;
    default:
      return state;
  }
}

export default cachedItems;

export function getCachedItems(state) {
  return state.cachedItems;
}

export function getCachedItemsPerType(type: keyof typeof initState) {
  return createSelector(getCachedItems, slice => slice[type]?.records);
}

export const getCachedProductsLength = createSelector(
  getCachedItemsPerType(SO.PRODUCTS.NAME),
  items => Object.keys(items).length,
);

export function getCachedItemsPerTypeIsReady(type: keyof typeof initState) {
  return createSelector(getCachedItems, slice => slice[type]?.ready);
}

export function getCachedItemPerTypeByID(
  type: keyof typeof initState,
  id: string,
) {
  return state => getCachedItemsPerType(type)(state)[id];
}

export function getCachedItemsPerTypeByIDs<
  V extends any,
  K extends string | number | symbol = string
>(type: keyof typeof initState, ids: K[]) {
  return (state): Record<K, V> => {
    if (!ids.length) return {} as Record<K, V>;
    const cachedItems = getCachedItemsPerType(type)(state);
    const result = {} as Record<K, V>;
    ids.forEach(id => {
      if (cachedItems[id]) {
        result[id] = cachedItems[id];
      }
    });
    return result;
  };
}
