import { doClientRequest, requestCompleteResponse } from './core/ErplyAPI';

export const saveVatRate = ({
  code,
  name,
  state,
  city,
  county,
  stateTaxRate,
  cityTaxRate,
  countyTaxRate,
  rate,
  ZIPCode,
  ...rest
}) =>
  doClientRequest({
    request: 'saveVatRate',
    vatRateID: rest.id,
    code,
    name,
    state,
    city,
    county,
    stateTaxRate,
    cityTaxRate,
    countyTaxRate,
    rate,
    ZIPCode,
    ...rest,
  }).then(a => a[0]);

export const getVatRates = ({ recordsOnPage = 1000, pageNo, ...rest }) =>
  requestCompleteResponse({
    request: 'getVatRates',
    recordsOnPage,
    pageNo,
    ...rest,
  });
