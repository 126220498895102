import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import { updateProductOrder } from 'actions/ShoppingCart';
import {
  getCustomPromptsForProduct,
  getSerialNumberTitle,
  getTextAroundSerialNumber,
} from 'reducers/configs/settings';
import CustomPromptsForm from 'containers/Forms/CustomPrompts/CustomPromptsForm';
import useCustomPromptsFormValidation from 'containers/Forms/CustomPrompts/useCustomPromptsFormValidation';
import { Product } from 'types/Product';
import { ShoppingCartItem } from 'types/ShoppingCart';

const SerialNumberEditForm = ({
  product,
  order,
}: {
  product: Product;
  order: ShoppingCartItem & { serialNumber?: string | number };
}) => {
  const { t } = useTranslation('shoppingCart');

  const dispatch = useDispatch();

  const [firstSymbol, secondSymbol] = useSelector<unknown, string>(
    getTextAroundSerialNumber,
  ).split(',');

  const customPrompts = useSelector(
    getCustomPromptsForProduct(order?.productID),
  ) as string[];

  const {
    invalidPrompts,
    validate,
    getErrorText,
  } = useCustomPromptsFormValidation();

  const customPromptsMap = useMemo(() => {
    const prompts = String(order.name).replace(product.name, '');
    if (!prompts) return {};
    return prompts
      .trim()
      .replace(
        firstSymbol && secondSymbol
          ? new RegExp(`[${firstSymbol}${secondSymbol}]`, 'g')
          : /[()]/g,
        '',
      )
      .split(', ')
      .slice(product.hasSerialNumbers ? 1 : 0)
      .reduce((all, prev) => {
        const [name, value] = prev.split(': ');
        return { ...all, [name]: value };
      }, {});
  }, [order.name]);

  const serialNumberTitle = useSelector(getSerialNumberTitle);

  const [serialNumberValue, setSerialNumberValue] = useState({
    [serialNumberTitle]: String(order.serialNumber),
  });
  const [customPromptsValue, setCustomPromptsValue] = useState(
    customPromptsMap,
  );

  const handleSave = () => {
    const serialNumber = serialNumberValue[serialNumberTitle];
    const serialNumberPart = product.hasSerialNumbers
      ? `${serialNumberTitle}: ${serialNumber}`
      : '';
    const customPromptsPart = Object.entries(customPromptsValue)
      .map(([p, v]) => `${p}: ${v}`)
      .join(', ');
    const nameWithUpdatedPrompts = `${product.name} ${firstSymbol ??
      ''}${serialNumberPart}${
      !customPromptsPart || !customPromptsPart.length || !serialNumberPart
        ? ''
        : ', '
    }${customPromptsPart}${secondSymbol ?? ''}`;
    dispatch(
      updateProductOrder({
        orderIndex: order.orderIndex,
        name: nameWithUpdatedPrompts as any,
        serialNumber,
      }),
    );
  };

  return (
    <div className="product-serial-number">
      <div className="serial-number-header">
        <h5>{t('inputs.serialNumber')}</h5>
        <Button onClick={handleSave} disabled={!!invalidPrompts.length}>
          Save
        </Button>
      </div>
      {!!product.hasSerialNumbers && (
        <CustomPromptsForm
          prompts={[serialNumberTitle]}
          value={serialNumberValue}
          onChange={(sn, v) => setSerialNumberValue({ [sn]: v })}
          validate={validate}
          getErrorText={getErrorText}
        />
      )}
      <CustomPromptsForm
        prompts={customPrompts}
        value={customPromptsValue}
        onChange={(cp, v) =>
          setCustomPromptsValue(prev => ({ ...prev, [cp]: v }))
        }
        validate={validate}
        getErrorText={getErrorText}
      />
    </div>
  );
};

export default SerialNumberEditForm;
