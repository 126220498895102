import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { version } from 'external_data';
import { clockInOutRecords } from 'services/ErplyAPI/clockInOut';
import { REDUX_USERNAME, REDUX_CLIENTCODE } from 'constants/persistence';
import { useLocalStorage } from 'utils/hooks/statelike';
import { getUserRights, attemptLogin } from 'actions/Login';
import { getWarehouses } from 'actions/warehouses';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { loginInput } from 'components/FieldTypes/skins/skins.module.scss';
import Loader from 'components/Loader';

import InputFieldBase from '../../../components/FieldTypes/InputField';
import { styles } from '../util';

const InputField = props => (
  <InputFieldBase size="lg" className={loginInput} {...props} />
);

// TODO: Refactor ideas
//   Extract clockin/out functionality into a standalone flow that doesn't actually log in, but just temporarily uses the entered credentials
//   Embed that flow in this component (as a child component?), rather than having to dedicate three states and two separate pages for it in index
const LoginView = ({
  onSignin,
  onSignup,
  onPinSelect,
  pinLoginEnabled,
  isClokingInOut,
  toggleClockInOut,
  warningMessage,
  updateView,
  updateClockInOutState,
}) => {
  const [clientCode, setClientCode] = useLocalStorage(REDUX_CLIENTCODE, '');
  const [username, setUsername] = useLocalStorage(REDUX_USERNAME, '');
  const [password, setPassword] = useState('');
  const [loadingText, setLoadingText] = useState(null);

  const [hideSignUpButton] = useLocalStorage('hideSignUpButton', 0);
  const [hideSignInUsingLoginAppButton] = useLocalStorage(
    'hideLoginAppButton',
    0,
  );

  const { t: tClockIn } = useTranslation('clockInOut');
  const { t } = useTranslation('login');

  useEffect(() => {
    setPassword('');
  }, []);

  const dispatch = useDispatch();
  const signIn = () => onSignin(clientCode, username, password);
  const clockIn = async () => {
    let loginData;
    try {
      setLoadingText(tClockIn('loading.signingIn'));
      loginData = await dispatch(attemptLogin(clientCode, username, password));
    } catch (error) {
      setLoadingText(null);
      warningMessage(t('validation:ErplyAPI.error.credentials'));
      return false;
    }

    try {
      setLoadingText(tClockIn('loading.fetchingClockInOutRecords'));
      const lastClockIn = await clockInOutRecords({
        employeeID: loginData.employeeID,
        notClockedOut: 1,
      }).then(R.findLast(r => r.OutUnixTime === 0));

      const clockInOutData = {
        username,
        clientCode,
        password,
        isClockedIn: !!lastClockIn,
        employeeID: loginData.employeeID,
        clockInUnixTime: lastClockIn?.InUnixTime,
        warehouseID: lastClockIn?.warehouseID,
      };

      updateClockInOutState(clockInOutData);

      if (clockInOutData.isClockedIn) {
        setLoadingText(null);
        return updateView('clockInOutInterface');
      }

      // Path for clocking in
      setLoadingText(tClockIn('loading.fetchingLocations'));
      await dispatch([
        getUserRights({ userID: loginData.userID }),
        getWarehouses(),
      ]);
      setLoadingText(null);
      return updateView('locationSelection');
    } catch (e) {
      console.error('Failed to clock in', e);
      setLoadingText(null);
      warningMessage(t('common:genericError'));
    }
  };
  const onSubmit = () => (isClokingInOut ? clockIn() : signIn());

  useShortcut('Enter', onSubmit);
  const iconStyling = {
    fontSize: '40px',
    transform: 'scale(0.7, 0.7)',
    visibility: pinLoginEnabled ? 'visible' : 'hidden',
    marginLeft: '25px',
  };

  return (
    <Loader loadingText={loadingText} show={!!loadingText}>
      <Card data-testid="login-container" style={styles.card}>
        <Card.Header style={styles.header}>
          <span data-testid="title">
            {isClokingInOut ? tClockIn('title') : t('login.title')}
          </span>
          <span style={{ flexGrow: 1 }} />
          {isClokingInOut ? (
            <span
              onClick={() => toggleClockInOut(false)}
              className="Clickable-icon icon_menu" // icon_grid-3x3 for login
              data-testid="menu-icon"
              style={{
                fontSize: '40px',
                // transform: "scale(0.7, 0.7)" for login
              }}
            />
          ) : (
            <>
              <span
                className="Clickable-icon icon_clock_alt"
                data-testid="clock-icon"
                style={{ iconStyling }}
                onClick={() => toggleClockInOut(true)}
              />
              <span
                onClick={onPinSelect}
                className="Clickable-icon icon_grid-3x3"
                data-testid="grid-icon"
                style={iconStyling}
              />
            </>
          )}
        </Card.Header>
        <Card.Body data-testid="login-body" className="p-sm-4 p-0 pb-4">
          <InputField
            name="clientCode"
            title={t('inputs.clientCode')}
            type="text"
            data-testid="clientCode"
            value={clientCode}
            onChange={e => setClientCode(e.target.value)}
            autoFocus={!clientCode}
          />
          <InputField
            name="username"
            title={t('inputs.username')}
            type="text"
            data-testid="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            autoFocus={clientCode && !username}
          />
          <InputField
            name="password"
            title={t('inputs.password')}
            type="password"
            data-testid="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoFocus={clientCode && username && !password}
          />
          <Container>
            <Row className="dark-row">
              <Col sm={6} xs={12} style={{ flexGrow: 1, maxWidth: '100%' }}>
                <Button
                  onClick={onSubmit}
                  style={{ flexGrow: 1, ...styles.button }}
                  size="lg"
                  variant="secondary"
                  data-testid="login-clockin-button"
                >
                  <span data-testid="login-clockin-title">
                    {isClokingInOut ? tClockIn('title') : t('login.title')}
                  </span>
                </Button>
              </Col>

              {!hideSignUpButton ? (
                <Col sm={6} xs={12}>
                  <Button
                    onClick={() => onSignup(clientCode, username, password)}
                    style={{ flexGrow: 1, ...styles.button }}
                    size="lg"
                    variant="primary"
                    data-testid="signup-button"
                  >
                    <span data-testid="signup-title">{t('signup.title')}</span>
                  </Button>
                </Col>
              ) : null}
              {!hideSignInUsingLoginAppButton ? (
                <Col sm={12} xs={12}>
                  <Button
                    onClick={() => {
                      const posLocation = new URL(window.location);
                      const posParams = new URLSearchParams(posLocation.search);
                      posParams.delete('clientCode');
                      posParams.set('auth', 'cookie');
                      posLocation.search = `?${posParams.toString()}&clientCode={CLIENTCODE}`;
                      const target = posLocation.href;

                      const login = new URL(
                        /^(pos-sb\.|localhost)/.test(window.location.host)
                          ? 'https://login-sb.erply.com'
                          : 'https://login.erply.com',
                      );
                      const loginParams = new URLSearchParams();
                      loginParams.set('target', target);
                      if (window.wrapperInfo) {
                        // check if the POS is rendered from a Wrapper App
                        loginParams.set('hideSignUp', 'true');
                      }
                      login.search = `?${loginParams.toString()}`;
                      window.location.href = login.href;
                    }}
                    style={{ flexGrow: 1, ...styles.button }}
                    size="lg"
                    variant={hideSignUpButton ? 'primary' : 'secondary'}
                  >
                    <span>{t('sso.title')}</span>
                  </Button>
                </Col>
              ) : null}
            </Row>
          </Container>
          <span data-testid="version-number" style={styles.version}>
            {version}
          </span>
        </Card.Body>
      </Card>
    </Loader>
  );
};

LoginView.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onPinSelect: PropTypes.func.isRequired,
  pinLoginEnabled: PropTypes.bool,
  isClokingInOut: PropTypes.bool,
  toggleClockInOut: PropTypes.func,
  warningMessage: PropTypes.func,
  updateClockInOutState: PropTypes.func,
};

export default LoginView;
