/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';

import { getCAFAEndpoint } from 'reducers/configs/serviceEnpoints';
import { capitalizeFirstLetter, sleep } from 'utils';
import {
  REDUX_SESSIONKEY,
  REDUX_CLIENTCODE,
  REDUX_APP_NAME as application,
} from 'constants/persistence';
import { proxy } from 'services/shared';

const crypto = require('crypto');

/**
 * Gets the endpoint for CAFA.
 * @param {isV3} bool Determines is the needed url is v3 or not.
 * @returns {string} Universal URL available to make CAFA requests.
 */
const baseURL = (isV3 = true) => {
  const { url } = getCAFAEndpoint();
  if (url === undefined)
    throw new Error(
      'Cannot access CAFA because service endpoints are not yet loaded',
    );

  if (isV3) {
    return `${proxy}${url}v3/configuration`;
  }
  return `${proxy}${url}configuration`;
};
const waitForBaseUrl = async (isV3 = true) => {
  while (true) {
    try {
      return baseURL(isV3);
    } catch (e) {
      // Try again in 100ms
      // eslint-disable-next-line no-await-in-loop
      await sleep(0.1);
    }
  }
};
/**
 *  Get single configuration
 *
 * @param param
 * @property {string} param.level - operating level ['company', 'warehouse', 'pos', 'user']
 * @property {string} param.level_id  - instance ID of the selected operating level
 * @property {string} param.type  - configuration type - ex. 'payment', 'scale', etc.
 * @property {string} param.name - configuration name - ex. 'adyen', 'netsCloud', etc.
 * @property {boolean} [param.lookDeeper] - should the CAFA search for same configuration in upper levels if it can not find anything for the current level
 *
 * @returns {Promise<T>}
 */
export const getConfiguration = ({
  level,
  name,
  type,
  level_id = '',
  lookDeeper = false,
}) => {
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY));
  const clientCode = Number(JSON.parse(localStorage.getItem(REDUX_CLIENTCODE)));
  return waitForBaseUrl(false).then(url =>
    axios.get(url, {
      headers: {
        sessionKey,
        clientCode,
        'Look-Deeper': lookDeeper,
      },
      params: {
        application,
        level: capitalizeFirstLetter(level),
        level_id: level === 'Company' ? '' : level_id.toString(),
        type,
        name,
      },
    }),
  );
};
/**
 *  Get multiple configuration
 *
 * @param params {Object} - configuration query parameters
 * @param {string } [params.level] - operating level ['company', 'warehouse', 'pos', 'user']
 * @param {string} [params.level_id]  - instance ID of the selected operating level
 * @param {string} [params.type]  - configuration type - ex. 'payment', 'scale', etc.
 * @param {string} [params.name] - configuration name - ex. 'adyen', 'netsCloud', etc.
 * @param {boolean} [params.lookDeeper] - should the CAFA search for same configuration in upper levels if it can not find anything for the current level
 *
 * @returns {Promise<T>}
 */
export const getBulkConfigurations = async ({
  name,
  level,
  level_id,
  type,
  lookDeeper = false,
}) => {
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY));
  if (!sessionKey) throw new Error('Session key missing');

  const clientCode = Number(JSON.parse(localStorage.getItem(REDUX_CLIENTCODE)));
  if (!clientCode) throw new Error('Client code missing');

  const url = await waitForBaseUrl(false);
  const response = await axios.get(url, {
    headers: {
      sessionKey,
      clientCode,
      'Look-Deeper': lookDeeper,
    },
    params: {
      application,
      level: capitalizeFirstLetter(level),
      level_id: level === 'Company' ? '' : level_id.toString(),
      type,
      name,
    },
  });
  return response;
};

/**
 *  Save single configuration
 *
 * @param params {Object} - configuration query parameters
 * @param {string } params.level - operating level ['company', 'warehouse', 'pos', 'user']
 * @param {string} params.level_id  - instance ID of the selected operating level
 * @param {string} params.type  - configuration type - ex. 'payment', 'scale', etc.
 * @param {string} params.name - configuration name - ex. 'adyen', 'netsCloud', etc.
 * @param {string | number | boolean} params.value - configuration
 *
 * @returns {Promise<T>}
 */
export const saveConfiguration = ({ level, level_id, name, type, value }) => {
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY));
  const clientCode = Number(JSON.parse(localStorage.getItem(REDUX_CLIENTCODE)));

  return waitForBaseUrl(true).then(url =>
    axios.put(
      url,
      {
        application,
        level: capitalizeFirstLetter(level),
        level_id: level === 'Company' ? '' : level_id.toString(),
        name,
        type,
        value,
      },
      {
        headers: { sessionKey, clientCode },
      },
    ),
  );
};
/**
 *  Save single configuration
 *
 * @param params {Object} - configuration query parameters
 * @param {string } params.level - operating level ['company', 'warehouse', 'pos', 'user']
 * @param {string} params.level_id  - instance ID of the selected operating level
 * @param {string} params.type  - configuration type - ex. 'payment', 'scale', etc.
 * @param {string} params.name - configuration name - ex. 'adyen', 'netsCloud', etc.
 * @param {any} params.value - configuration
 *
 * @returns {Promise<T>}
 */
export const updateConfiguration = ({ level, level_id, name, type, value }) => {
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY));
  const clientCode = Number(JSON.parse(localStorage.getItem(REDUX_CLIENTCODE)));
  return waitForBaseUrl().then(url =>
    axios.put(
      url,
      {
        application,
        level: capitalizeFirstLetter(level),
        level_id: level === 'Company' ? '' : level_id.toString(),
        name,
        type,
        value,
      },
      {
        headers: { sessionKey, clientCode },
      },
    ),
  );
};
/**
 *  Delete single configuration
 *
 * @param {string } level - operating level ['company', 'warehouse', 'pos', 'user']
 * @param {string} level_id  - instance ID of the selected operating level
 * @param {string} type  - configuration type - ex. 'payment', 'scale', etc.
 * @param {string} name - configuration name - ex. 'adyen', 'netsCloud', etc.
 *
 * @returns {Promise<T>}
 */
export const deleteConfiguration = (id) => {
  const sessionKey = JSON.parse(localStorage.getItem(REDUX_SESSIONKEY));
  const clientCode = Number(JSON.parse(localStorage.getItem(REDUX_CLIENTCODE)));
  return waitForBaseUrl().then(url =>
    axios.delete(`${url}/${id}`, {
      headers: { sessionKey, clientCode },
    }),
  );
};

export const fetchSplitsConfigByIDFromCAFA = async splitsCafaID => {
  const unixTimestamp = Math.round(new Date().getTime() / 1000);
  const path = `104178/v3/configuration/${splitsCafaID}${unixTimestamp}`;

  const trueHash = crypto
    .createHmac('sha256', 'eply-adyen-marketplace')
    .update(path)
    .digest('hex');

  const responseSplits = await axios({
    method: 'get',
    url: `${baseURL(true)}/${splitsCafaID}`,
    headers: {
      'Content-Type': 'application/json',
      clientCode: '104178',
      'hmac-auth': trueHash,
      timestamp: unixTimestamp,
    },
  });

  return responseSplits.data;
};
