import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

// @ts-ignore
interface Props extends ButtonProps {
  className?: string;
  text?: string;
  style?: React.CSSProperties;
  action?: (event: React.MouseEvent) => void;
  iconToDisplay?: string;
  variant?: ButtonProps['variant'] | 'UIButton' | 'POS' | 'outline-POS';
}

const getIconToDisplay = (icon?: string) => {
  if (icon === 'searchIcon') {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 32.24800109863281 32.24800109863281"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        className="flip-horizontal"
      >
        <g>
          <path d="M 19,0C 11.82,0, 6,5.82, 6,13c0,3.090, 1.084,5.926, 2.884,8.158l-8.592,8.592c-0.54,0.54-0.54,1.418,0,1.958 c 0.54,0.54, 1.418,0.54, 1.958,0l 8.592-8.592C 13.074,24.916, 15.91,26, 19,26c 7.18,0, 13-5.82, 13-13S 26.18,0, 19,0z M 19,24 C 12.934,24, 8,19.066, 8,13S 12.934,2, 19,2S 30,6.934, 30,13S 25.066,24, 19,24z" />
        </g>
      </svg>
    );
  }
  if (icon === 'iconPlus') {
    return (
      <svg width="20" height="20" viewBox="0 0 32 32" fill="#000000">
        <g>
          <path d="M 9,18L 16,18 l0,7 C 16,25.552, 16.448,26, 17,26S 18,25.552, 18,25L 18,18 l 7,0 C 25.552,18, 26,17.552, 26,17 C 26,16.448, 25.552,16, 25,16L 18,16 L 18,9 C 18,8.448, 17.552,8, 17,8S 16,8.448, 16,9L 16,16 L 9,16 C 8.448,16, 8,16.448, 8,17C 8,17.552, 8.448,18, 9,18z" />
        </g>
      </svg>
    );
  }
};

/** Bootstrap button, text will be transformed to uppercase. it takes one action which will render a modal with the component that is passed incomponentInModal prop */
const UIButton: React.FC<Props> = ({
  className,
  text,
  variant = 'UIButton',
  style,
  action,
  iconToDisplay,
  ...rest
}) => (
  <Button
    // @ts-ignore
    variant={variant}
    className={className}
    style={style}
    onClick={e => {
      e.stopPropagation();
      action?.(e);
    }}
    {...rest}
  >
    {getIconToDisplay(iconToDisplay) ?? text?.toUpperCase()}
  </Button>
);

export default UIButton;

const trans = ({
  text,
  context,
}: {
  text: string;
  context?: string;
}): React.FC<Props> => props => {
  const { t } = useTranslation('common');
  return <UIButton text={t(text, { context })} {...props} />;
};

/** a UIButton, but with the text already translated */
export const SaveButton = trans({ text: 'save', context: 'changes' });
/** a UIButton, but with the text already translated */
export const AddButton = trans({ text: 'add', context: 'new' });
/** a UIButton, but with the text already translated */
export const UpdateButton = trans({ text: 'update' });
