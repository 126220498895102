import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Divider } from '@material-ui/core';

import CloseButton from 'components/CustomButtons/CloseButton';
import { getPaymentSelected } from 'reducers/Payments';
import { getSetting, getCheckBehavior } from 'reducers/configs/settings';

import { PaymentList, PaymentActions, GiftCard } from '../../components';
import PayCheck from '../../components/PayCheck';

const PaymentMobile: React.FC<{ voidHandler: () => void }> = ({
  voidHandler,
}) => {
  const paymentSelected = useSelector(getPaymentSelected);
  const { t } = useTranslation('payment');

  const checkBehaviour = useSelector(getCheckBehavior);

  const isGiftCardSelected = paymentSelected === 'giftcard';
  const shouldShowCheck =
    checkBehaviour === 'OLD' && paymentSelected.includes('check');

  const getView = useCallback(() => {
    if (isGiftCardSelected) return <GiftCard />;
    if (shouldShowCheck) return <PayCheck />;

    return (
      <>
        <PaymentList />
        <PaymentActions voidHandler={voidHandler} />
      </>
    );
  }, [isGiftCardSelected, shouldShowCheck, voidHandler]);

  return (
    <Box padding="1.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{t('buttons.title')}</Typography>
        <CloseButton action={voidHandler} />
      </Box>
      <Divider style={{ margin: '0.5rem 0' }} />
      {getView()}
    </Box>
  );
};

export default PaymentMobile;
