import React, { useState } from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';

import * as tripos from 'paymentIntegrations/triPOS/requests/triposRequests';
import { addError, addSuccess } from 'actions/Error';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import InputField from 'components/FieldTypes/InputField';
import { useTranslation } from 'react-i18next';

const inputClass = classNames([
  formStyles.formInput,
  formStyles.mediumTitle,
  'tripos-form-input',
]);

const TerminalActivation = ({ laneAction, nextLaneId }) => {
  const { t: rawT } = useTranslation('settingsPayment');
  const t = (text, ...rest) => rawT(`integrations.tripos.${text}`, ...rest);
  const [terminalId, setTerminalId] = useState('');
  const [activationCode, setActivationCode] = useState('');
  const dispatch = useDispatch();
  const activateTerminal = () => {
    if (!terminalId) {
      dispatch(addError(t('serialNumberMissing')));
      return;
    }
    if (!activationCode) {
      dispatch(addError(t('activationCodeMissing')));
    }
    (async () => {
      const { data: newLane } = await tripos.requestPostLane({
        laneId: nextLaneId,
        terminalId,
        activationCode,
      });
      if (!newLane.exceptionMessage) {
        dispatch(addSuccess(t('activated')));
        laneAction({ type: 'addLane', payload: newLane });
      } else dispatch(addError(newLane.exceptionMessage));
    })();
  };
  return (
    <div>
      <h2>{`${t('terminalActivation')}:`}</h2>

      <InputField
        type="text"
        title={t('terminalID')}
        value={terminalId}
        onChange={e => setTerminalId(e.target.value)}
        size="lg"
        className={inputClass}
      />
      <InputField
        type="text"
        title={t('activationCode')}
        value={activationCode}
        onChange={e => setActivationCode(e.target.value)}
        size="lg"
        className={inputClass}
      />
      <Button size="lg" block variant="secondary" onClick={activateTerminal}>
        {t('activateTerminal')}
      </Button>
    </div>
  );
};

export default TerminalActivation;
