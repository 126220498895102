export type MMReceiptTranslations = {
  cardDetails: {
    transactionType: string;
    transactionTypes: {
      SALE: string;
      REFUND: string;
    };
    sum: string;
    typeTrans: string;
    cardNumber: string;
    expirationDate: string;
    referenceNumber: string;
    authorizationCode: string;
    certificateBalance: string;
  };
  offline: {
    stripe: string;
    message: string;
  };
  header: {
    fax: string;
    phone: string;
    customerCardNumber: string;
    salesperson: string;
    storeNumber: string;
    receiptNumber: string;
  };
  productsTable: {
    headers: {
      description: string;
      quantity: string;
      price: string;
      total: string;
    };
    discount: {
      regularPrice: string;
      discount: string;
    };
    totals: {
      types: Record<string, string>;
      quantity: string;
      subtotal: string;
      total: string;
      rounding: string;
      discount: string;
      paid: string;
    };
  };
  givexRefill: {
    account: string;
    amount: string;
    balance: string;
    date: string;
    time: string;
  };
  footer: string[];

  flyerPrice: string;
  change: string;
  rewardsCustomer: string;
  rounding: string;
  amountPaid: string;
  approvalCode: string;
  cardNumber: string;
  cardType: string;
  transactionType: string;
  sale: string;
  credit: string;
  card: string;
  voucher: string;
  rewardsMember: string;
  store: string;
  cashCard: string;
  giftCard: string;
  cash: string;
  register: string;
  giftCertificate: string;
  balance: string;
  account: string;
  amount: string;
  time: string;
};
export const mmReceiptTranslations: Record<
  'en' | 'fr',
  MMReceiptTranslations
> = {
  en: {
    givexRefill: {
      account: 'Account',
      amount: 'Amount',
      balance: 'Balance',
      date: 'Date',
      time: 'Time',
    },
    cardDetails: {
      transactionType: 'Transaction',
      transactionTypes: {
        SALE: 'SALE',
        REFUND: 'REFUND',
      },
      sum: 'Amount paid',
      typeTrans: 'Card type',
      cardNumber: 'Card #',
      expirationDate: 'Expires on',
      referenceNumber: 'Ref #',
      authorizationCode: 'Approval code',
      certificateBalance: 'Balance',
    },
    offline: {
      stripe: 'warning',
      message:
        'This receipt was printed during offline mode. Information on the receipt may be inaccurate and may not reflect the final state of the sale',
    },
    header: {
      receiptNumber: 'Receipt No',
      phone: 'Phone',
      fax: 'Fax',
      customerCardNumber: 'Customer card number',
      salesperson: 'Salesperson',
      storeNumber: 'Store Number',
    },
    productsTable: {
      headers: {
        description: 'Description',
        quantity: 'Quantity',
        price: 'Price',
        total: 'Total',
      },
      discount: {
        discount: 'Discount',
        regularPrice: 'Regular price',
      },
      totals: {
        subtotal: 'Subtotal',
        total: 'Total',
        rounding: 'Rounding',
        discount: 'Total Discount',
        quantity: 'Quantity',
        paid: 'Paid',
        types: {
          CARD: 'CARD',
          'by card': 'by card',
          GIVEX: 'GIFT CARD',
          GIFTCARD: 'CASH CARD',
          CASHCARD: 'CASH CARD',
          'by gift card': 'by cash card',
          CASH: 'CASH',
          'in cash': 'in cash',
        },
      },
    },
    footer: [
      'How are we doing?',
      'Take the survey @ mmfoodmarket.com/customersurvey',
      'Share your opinion for a chance to win a Gift Card',
    ],
    flyerPrice: 'Flyer price',
    rewardsCustomer: 'Rewards customer',
    rounding: 'Rounding',
    change: 'Change',
    amountPaid: 'AmountPaid',
    approvalCode: 'ApprovalCode',
    cardNumber: 'CardNumber',
    cardType: 'CardType',
    transactionType: 'TransactionType',
    sale: 'Sale',
    credit: 'Credit',
    card: 'Card',
    voucher: 'Voucher',
    rewardsMember: 'Rewards Member',
    store: 'Store',
    cashCard: 'CashCard',
    giftCard: 'GiftCard',
    cash: 'Cash',
    register: 'Register',
    giftCertificate: 'Gift_Certificate',
    balance: 'Balance',
    account: 'Account',
    amount: 'Amount',
    time: 'Time',
  },
  fr: {
    givexRefill: {
      balance: 'Solde',
      account: 'Compte',
      amount: 'Montant',
      time: 'Heure',
      date: 'Date',
    },
    cardDetails: {
      transactionType: 'Transaction',
      transactionTypes: {
        SALE: 'VENTE',
        REFUND: 'REMBOURSEMENT',
      },
      sum: 'Montant payé',
      typeTrans: 'Type de carte',
      cardNumber: 'No de Carte',
      expirationDate: 'Expire',
      referenceNumber: 'No de Réf',
      authorizationCode: 'Confirmation',
      certificateBalance: 'Solde',
    },
    // Translations used on the receipt
    offline: {
      stripe: 'Avertissement',
      message:
        'Ce reçu a été imprimé en mode hors ligne. Les informations figurant sur le reçu pourraient être inexactes et ne pas correspondre aux données finales de la vente.',
    },
    header: {
      receiptNumber: 'Facture N°',
      phone: 'Téléphone',
      fax: 'Télécopieur',
      customerCardNumber: 'Numéro carte client',
      salesperson: 'Vendeur',
      storeNumber: 'Boutique N°',
    },
    productsTable: {
      headers: {
        description: 'Description',
        quantity: 'Quantité',
        price: 'Prix',
        total: 'Total',
      },
      discount: {
        discount: 'Remise',
        regularPrice: 'Prix régulier',
      },
      totals: {
        quantity: 'Quantité',
        subtotal: 'Sous-total',
        discount: 'Remise totale',
        paid: 'Payé',
        total: 'Total',
        rounding: 'Arrondi',
        types: {
          CARD: 'PAR CARTE',
          'by card': 'PAR CARTE',
          GIVEX: 'CARTE-CADEAU',
          GIFTCARD: 'CARTE-CADEAU',
          'by gift card': 'CARTE-CADEAU',
          CASH: 'COMPTANT',
          'in cash': 'COMPTANT',
        },
      },
    },
    footer: [
      'Votre avis nous intéresse!',
      'Répondez à notre sondage au Customer survey http://www.lesalimentsmm.com/sondageclient',
      'Faites-nous part de vos commentaires et courez la chance de gagner une carte-cadeau.',
    ],

    // Translations provided but not found on the receipt
    flyerPrice: '',
    rewardsCustomer: 'Client Récompenses',
    rounding: 'Arrondissement',
    change: 'Rendu monnaie',
    amountPaid: 'Montant payé',
    approvalCode: 'Code de Confirmation',
    cardNumber: 'No. de carte',
    cardType: 'Type de carte',
    transactionType: 'Transaction',
    sale: 'Vente',
    credit: 'Crédit',
    card: 'CARTE',
    voucher: "BON D'ACHAT",
    rewardsMember: 'Membre Récompenses',
    store: 'Magasin',
    cashCard: "BON D'ACHAT",
    giftCard: 'CARTE CADEAU',
    cash: 'COMPTANT',
    register: 'Caisse',
    giftCertificate: 'CERTIFICAT_CADEAU',
    balance: 'Solde',
    account: 'Compte',
    amount: 'Montant',
    time: 'Heure',
  },
};
