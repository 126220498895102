import { addSuccess } from 'actions/Error';
import { deletePayment } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';

import { JunctionAPIResponse, JunctionPaymentObjectType } from '../types';

import handlePrint from './handlePrint';

const handleSuccess = ({
  payment,
  response,
  updateMessage,
  shouldVoid = false,
}: {
  payment: any;
  response: JunctionAPIResponse;
  updateMessage: (arg: string) => void;
  shouldVoid?: boolean;
}) => async dispatch => {
  const [paymentObj]: JunctionPaymentObjectType[] = response?.records ?? [];

  const extendedPayment = {
    cardHolder: paymentObj.cardHolder,
    cardNumber: paymentObj.cardNumber,
    cardType: paymentObj.cardType,
    signature: paymentObj.signature,
    aid: paymentObj.aid,
    applicationLabel: paymentObj.applicationLabel,
    pinStatement: paymentObj.pinStatement,
    cryptogramType: paymentObj.cryptogramType,
    cryptogram: paymentObj.cryptogram,
    transactionId: paymentObj.referenceNumber,
    amount: Number(paymentObj.approvedAmount),
    attributes: {
      refNo: paymentObj.referenceNumber,
      authCode: paymentObj.authCode,
    },
  };

  updateMessage(paymentObj.hostResponse);
  if (shouldVoid) {
    dispatch(addSuccess('Void Successful'));
    dispatch(deletePayment({ key: payment.key }));
    await dispatch(handlePrint({ response }));
  } else {
    dispatch(addSuccess('Transaction Successful'));
    dispatch(setPayment({ ...payment, ...extendedPayment, paid: true }));
    await dispatch(handlePrint({ response }));
  }
};

export default handleSuccess;
