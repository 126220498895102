import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useAsync } from 'react-use';

import { RootState } from 'reducers';
import { getAllLocations } from 'paymentIntegrations/stripe/API';
import { StripeLocation } from 'paymentIntegrations/stripe/types';

const useAllLocations = () => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { value: locations = [], error, loading } = useAsync<StripeLocation[]>(
    () => dispatch(getAllLocations()),
    [dispatch],
  );
  return { locations, error, loading };
};

export default useAllLocations;
