import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import uuidv4 from 'uuid/v4';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { addError, addSuccess, addWarning } from 'actions/Error';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { setPayment } from 'actions/Payments/setPayment';
import {
  getBalance as getBalanceSelector,
  getPayments,
} from 'reducers/Payments';
import { PaymentObj } from 'paymentIntegrations/types';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { getIsAReturn } from 'reducers/sales';
import { createConfirmation } from 'actions/Confirmation';
import { RootState } from 'reducers';

import { checkBalance } from '../API/viiAPI';
import { addViiPaymentData, getViiGiftCardProduct } from '../redux/actions';
import { noResponse } from '../constants';

const GiftCard = ({ typeID, children }) => {
  const { t } = useTranslation('payment');

  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();

  const viiProduct = useSelector(getViiGiftCardProduct);

  const balance = useSelector(getBalanceSelector);
  const payments: Record<string, PaymentObj> = useSelector(getPayments);
  const isReturn = useSelector(getIsAReturn);
  const inputRef = useRef<HTMLInputElement>();

  const [processing, setProcessing] = useState(false);
  const [cardNumber, setCardNumber] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const [inputAmount, setInputAmount] = useState('');
  const [fetchedData, setFetchedData] = useState({
    availableFunds: '',
    cardNumber: '',
    pin: '',
  });

  const extRef = uuidv4();

  const replaceValidateWithAdd = useMemo(
    () => fetchedData.cardNumber === cardNumber && fetchedData.pin === pin,
    [fetchedData.cardNumber, fetchedData.pin, cardNumber, pin],
  );

  const withProcessing = action => () => {
    if (processing) return;
    setProcessing(true);
    action().catch(e => {
      dispatch(addError(e.message));
      setProcessing(false);
    });
  };

  const close = () => {
    dispatch(setPaymentSelected(''));
  };

  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  const onChange = (key, value) => {
    switch (key) {
      case 'Number':
        setCardNumber(value);
        break;
      case 'PIN':
        setPin(value);
        break;
      case 'Sum':
        setInputAmount(value);
        break;
      default:
        // do nothing
        break;
    }
  };

  const handleSearch = async () => {
    const existsInPayments = Object.values(payments).filter(
      payment => payment.cardNumber === cardNumber,
    ).length;
    if (existsInPayments) {
      setProcessing(false);
      return dispatch(
        addWarning(
          t('alerts.giftcardSerialAlreadyAdded', { serial: cardNumber }),
          {
            dismissible: false,
            errorType: 'giftCard',
            selfDismiss: 2000,
          },
        ),
      );
    }
    setProcessing(true);
    /*
      Ordinary sale workflow.
      Need to check balance to see if card has been issued and if it has sufficient balance to pay for the transaction.
      If balance is too low, set the amount to the available one and display an error.
      If balance is larger than needed to pay, set the amount to the toPay sum.
     */
    if (!isReturn) {
      return dispatch(
        checkBalance({
          CardNumber: cardNumber,
          viiTranId: uuidv4(),
          PIN: pin,
        }),
      )
        .then(res => {
          if (res && Number(res.ResponseCode?.[0]) === 0) {
            if (res.AvailableBalance?.[0] === '0.00') {
              setProcessing(false);
              setFetchedData({
                availableFunds: ``,
                cardNumber,
                pin,
              });
              dispatch(addError('Card has no available balance!'));
              return null;
            }
            // Check if Card is active
            if (Number(res.CardStatusId?.[0]) !== 1) {
              setFetchedData({
                availableFunds: ``,
                cardNumber,
                pin,
              });
              setProcessing(false);
              return dispatch(
                addError(
                  'Card is no longer active, please use a different card',
                ),
              );
            }
            const amount = Number(res.AvailableBalance?.[0]).toFixed(2);
            setFetchedData({
              availableFunds: amount,
              cardNumber,
              pin,
            });
            onChange('Sum', amount);
            setProcessing(false);
            return dispatch(
              addSuccess(`Card found. Available funds: ${amount}.`),
            );
          }
          setProcessing(false);
          setFetchedData({
            availableFunds: ``,
            cardNumber,
            pin,
          });
          return dispatch(addError(res?.ResponseMessage?.[0] ?? noResponse));
        })
        .catch(err => {
          setProcessing(false);
          setFetchedData({
            availableFunds: ``,
            cardNumber,
            pin,
          });
          return dispatch(addError(err.message ?? err));
        });
    }
    /*
      Return sale workflow.
      Need to check if a card is either not issued (need to give a new card) 
        or is active (funds can be loaded onto the card).
      By Vii logic, refunds can only be made up to the amount that was initially loaded onto the card.
      This can only be checked when the Load request is fired aka during payment.
    */
    return dispatch(
      checkBalance({
        CardNumber: cardNumber,
        viiTranId: uuidv4(),
        PIN: pin,
      }),
    )
      .then(res => {
        if (res && Number(res.ResponseCode?.[0]) === 0) {
          // Check if Card is active
          if (Number(res.CardStatusId?.[0]) !== 1) {
            setFetchedData({
              availableFunds: ``,
              cardNumber,
              pin,
            });
            setProcessing(false);
            return dispatch(
              addError('Card is no longer active, please use a different card'),
            );
          }
          setProcessing(false);
          setFetchedData({
            availableFunds: Number(res.AvailableBalance?.[0]).toFixed(2),
            cardNumber,
            pin,
          });
          return dispatch(
            addSuccess(`Card found. Enter amount of funds to add.`),
          );
        }
        setProcessing(false);
        setFetchedData({
          availableFunds: ``,
          cardNumber,
          pin,
        });
        return dispatch(addError(res?.ResponseMessage?.[0] ?? noResponse));
      })
      .catch(err => {
        setProcessing(false);
        return dispatch(addError(err.message ?? err));
      });
  };

  const handleAdd = async () => {
    if (Number(inputAmount) > Math.abs(balance)) {
      setInputAmount(`${-balance}`);
      await new Promise(() =>
        dispatch(
          createConfirmation(
            () => {
              // do nothing
            },
            null,
            {
              title: 'Payment amount changed',
              body: `Payment sum was larger than required to cover the balance.\nSum reduced to ${-balance}`,
            },
          ),
        ),
      );
      return;
    }
    dispatch(
      setPayment({
        key: extRef,
        amount: inputAmount,
        type: 'CARD',
        paymentIntegration: 'vii',
        caption: viiProduct?.name ?? 'Vii',
        cardNumber,
      }),
    ).then(res => {
      dispatch(
        addViiPaymentData(extRef, {
          Amount: inputAmount,
          CardNumber: cardNumber,
          ExternalReference: extRef,
          viiTranId: uuidv4(),
          PIN: pin,
        }),
      );
      dispatch(setPaymentSelected(''));
      dispatch(
        addSuccess(
          `${viiProduct?.name ??
            'Vii'} payment for ${inputAmount} added successfully!`,
        ),
      );
      return res;
    });
  };

  const getErrorState = () => {
    let error = false;
    let helperText = '';
    if (isReturn) {
      helperText = !inputAmount
        ? 'Sum must be specified'
        : 'Sum must be between 20 and 900 (inclusive)';
      error =
        !inputAmount || Number(inputAmount) > 900 || Number(inputAmount) < 20;
    } else if (!inputAmount) {
      helperText = 'Sum must be specified';
      error = true;
    } else if (Number(inputAmount) === 0) {
      helperText = 'Sum must be positive';
      error = true;
    } else if (Number(inputAmount) > Number(fetchedData.availableFunds)) {
      helperText = 'Insufficient funds';
      error = true;
    } else {
      helperText = 'Payment to be deducted from card';
      error = false;
    }
    return {
      helperText,
      error,
    };
  };

  useShortcut('Enter', handleSearch);

  // If not as late in code, would return TS errors.
  if (typeID !== 'vii') {
    return children;
  }

  const newBalance = (
    Number(fetchedData.availableFunds) -
    (isReturn ? -Number(inputAmount ?? 0) : Number(inputAmount ?? 0))
  ).toFixed(2);

  return (
    <>
      <Grid container style={{ justifyContent: 'space-around' }} spacing={2}>
        <Grid xs={12} item>
          <TextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '15%' }}>
                  CARD NUMBER
                </InputAdornment>
              ),
            }}
            inputRef={inputRef}
            fullWidth
            value={cardNumber}
            autoFocus={true}
            onChange={e => onChange('Number', e.target.value)}
            error={!cardNumber}
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ minWidth: '15%' }}>
                  CARD PIN
                </InputAdornment>
              ),
            }}
            fullWidth
            value={pin}
            onChange={e => onChange('PIN', e.target.value)}
            type="password"
            error={!pin}
          />
        </Grid>
        {fetchedData.availableFunds && replaceValidateWithAdd && (
          <>
            <Grid xs={12} item>
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ minWidth: '15%' }}
                    >
                      AMOUNT
                    </InputAdornment>
                  ),
                }}
                fullWidth
                size="medium"
                value={inputAmount}
                onChange={e => onChange('Sum', e.target.value)}
                error={getErrorState().error}
                helperText={getErrorState().helperText}
              />
            </Grid>
            <Grid item>
              <Typography>
                {`Available card balance: ${fetchedData.availableFunds}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {`Available card balance after transaction: ${newBalance}`}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        style={{ justifyContent: 'flex-start' }}
        spacing={2}
      >
        <Grid item style={{ marginTop: '20px' }}>
          {fetchedData.availableFunds && replaceValidateWithAdd ? (
            <Button
              variant="contained"
              color="secondary"
              disabled={!inputAmount || getErrorState().error}
              onClick={handleAdd}
            >
              Add
            </Button>
          ) : (
            <Button
              disabled={processing || !pin || !cardNumber}
              onClick={withProcessing(handleSearch)}
              variant="contained"
              color="secondary"
            >
              {processing && (
                <LinearProgress
                  variant="indeterminate"
                  style={{ position: 'absolute', inset: 0 }}
                />
              )}
              Validate
            </Button>
          )}
        </Grid>
        <Grid item style={{ marginTop: '20px' }}>
          <Button
            variant="contained"
            type="button"
            onClick={close}
            disabled={processing}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ marginTop: '40px', justifyContent: 'flex-start' }}
      >
        <Grid item xs={6}>
          <Typography variant="h5">Balance</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">{balance?.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default GiftCard;
