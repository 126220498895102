import debug from 'debug';

export const pluginID = 'm&mPlugin';

export const modals = {
  ADDRESS_SEARCH: 'address-search',
  LOAD_COUPONS: 'load-coupons',
  ORDER_NOTIFICATIONS: 'order-notifications',
  PIR_FORM: 'pir-form',
  EMAIL_VALIDATION: 'email-validation',
};

export const seniorTagID = 29;

export const messageTypes = {
  PAY_FOR_WEBSHOP_ORDER: 'PAY_FOR_WEBSHOP_ORDER',
  PRINT_RECEIPT: 'PRINT_RECEIPT',
  CLOSE_APP: 'CLOSE_APP',
} as const;

export const mmLog = debug(pluginID);

/**
 * Per initial requirements, match only receipts (CASHINVOICE) and invoice waybills (INVWAYBILL)
 */
export const ALLOWED_DOCUMENT_TYPES = [
  'INVWAYBILL',
  'CASHINVOICE',
  // 'WAYBILL',
  // 'PREPAYMENT',
  // 'OFFER',
  // 'EXPORTINVOICE',
  // 'RESERVATION',
  // 'CREDITINVOICE',
  // 'ORDER',
  // 'INVOICE',

  // 'SERVICE_INVOICE',
  // 'SERVICE_CASH_INVOICE',
  // 'SERVICE_INVOICE_WAYBILL'
];