import {
  doGenericRequest,
  doClientRequest,
  requestAllRecords,
  doBulkRequest,
} from './core/ErplyAPI';
import {
  ChangePasswordParams,
  CreateInstallationResponse,
  GetUserGroupsParams,
  GetUserGroupsResponse,
  GetUserRightsParams,
  GetUserRightsResponse,
  GetZReportParams,
  GetZReportResponse,
  SwitchUserParams,
  VerifyUserParams,
  VerifyUserResponse,
} from './core/types';

export const changePassword = (params: ChangePasswordParams) =>
  doClientRequest({
    request: 'changePassword',
    ...params,
  });

export const changePin = ({ userID, cardCode }) =>
  doClientRequest({
    request: 'saveUser',
    cardCode,
    userID,
  });

export const loginWithPass = (params: VerifyUserParams) =>
  doClientRequest<VerifyUserResponse>({
    request: 'verifyUser',
    ...params,
    sessionLength: 86400,
  });

export const loginWithPin = (params: SwitchUserParams) =>
  doClientRequest<VerifyUserResponse>({
    request: 'switchUser',
    ...params,
    sessionLength: 86400,
  });

export const createDemoUser = ({ country, email }) =>
  doGenericRequest<CreateInstallationResponse>({
    sendEmail: 1,
    doNotCreateUser: 0,
    firstName: 'Demo',
    lastName: 'User',
    countryCode: country,
    companyName: 'Demo Company',
    phone: '555 - 555 - 555',
    email,
    password: 'demo1234',
    website: '',
    notes: '',
    demo: true,
    reseller: '',
    username: email,
    request: 'createInstallation',
  });

export const signUp = ({
  countryCode,
  email,
  firstName,
  lastName,
  companyName,
  phone,
  website,
  password,
  nrStores,
}) =>
  doGenericRequest<CreateInstallationResponse>({
    sendEmail: 1,
    doNotCreateUser: 0,
    firstName,
    lastName,
    countryCode,
    companyName,
    phone,
    email,
    password,
    website,
    // eslint-disable-next-line @typescript-eslint/camelcase
    notes: JSON.stringify({ stores_count: nrStores }),
    reseller: '',
    username: email,
    request: 'createInstallation',
  });

export const getCompanyInfo = () =>
  doClientRequest({ request: 'getCompanyInfo' });

export const getPointsOfSale = params =>
  doClientRequest({
    request: 'getPointsOfSale',
    ...params,
  });

export const getHealthStatus = () =>
  doClientRequest({
    request: 'health',
  });
export const getPointOfSaleDayTotals = ({
  pointOfSaleID,
  currencyCode,
  openedUnixTime,
  dayID,
}) =>
  doClientRequest({
    request: 'getPointOfSaleDayTotals',
    pointOfSaleID,
    currencyCode,
    openedUnixTime,
    dayID,
  });

/**
 * The parameter 'tenders' must be a dictionary mapping names of tender types to their total sums
 *
 * NB: The name should not contain the leading 'counted-' prefix,
 *
 * Example: {'CASH':5, 'CARD-':12, 'CARD-VISA':0.01}
 */
export const closeDay = ({
  pointOfSaleID,
  openedUnixTime,
  closedUnixTime,
  closedSum,
  bankedSum,
  notes,
  currencyCode,
  /** counted-* and varianceReasonID-* entries */
  ...others
}) => {
  return doClientRequest({
    request: 'POSCloseDay',
    pointOfSaleID,
    openedUnixTime,
    closedUnixTime,
    closedSum,
    bankedSum,
    notes,
    currencyCode,
    ...others,
  });
};

/**
 * Supports drawer open day functionality
 * @param pointOfSaleID
 * @param openedUnixTime
 * @param openedSum
 * @param currencyCode
 * @returns {Promise<*>}
 */
export const openDayV2 = ({
  pointOfSaleID,
  openedUnixTime,
  openedSum,
  currencyCode,
}) =>
  doClientRequest({
    request: 'POSOpenDayV2',
    pointOfSaleID,
    openedUnixTime,
    openedSum,
    currencyCode,
  });

/**
 *
 * @param params {}
 * @param params.employeeID - employee id of sale id / if skipped API uses logged in employee
 * @param params.pointOfSaleID - point of sale id
 * @param params.currencyCode - currency code[3 chars length]
 * @returns {Promise<*>}
 */
export const checkIsDayOpen = params =>
  doClientRequest({
    request: 'isPOSDayOpen',
    ...(params ?? {}),
  });

export const getZReport = (params: GetZReportParams) =>
  doClientRequest<GetZReportResponse>({
    request: 'getReports',
    type: 'ZReport',
    ...params,
    format: 'HTML',
  });

export const getUserRights = ({ userID, ...rest }: GetUserRightsParams) =>
  doClientRequest<GetUserRightsResponse>({
    request: 'getUserRights',
    userID,
    ...rest,
    getCurrentUser: userID === undefined ? 1 : undefined,
  });

export const getEmployees = async (params = {}, from = 0, count = Infinity) =>
  requestAllRecords(
    {
      ...params,
      request: 'getEmployees',
    },
    from,
    count,
  ).then(({ records }) => records);

export const getEmployeesStats = () =>
  doClientRequest({
    request: 'getEmployeeStats',
  });

export const getReasonCodes = ({ purpose, recordsOnPage, pageNo, lang }) =>
  doClientRequest({
    request: 'getReasonCodes',
    purpose,
    recordsOnPage,
    pageNo,
    lang,
  });

export const getUserOperationsLog = params =>
  doClientRequest({
    request: 'getUserOperationsLog',
    ...params,
  });

export const getUserGroups = (params?: GetUserGroupsParams) =>
  doClientRequest<GetUserGroupsResponse>({
    request: 'getUserGroups',
    ...params,
  });

export const saveAssignment = params =>
  doClientRequest<{ assignmentID: number }>({
    request: 'saveAssignment',
    ...params,
  });

export const batchSaveRequests = params => doBulkRequest(params);
