/**
 * Keeps track of the last 100 dispatched actions
 */
export const reduxActionLog = [];
export default () => next => action => {if (action?.type) {
    if (reduxActionLog.length > 100) reduxActionLog.shift();
    reduxActionLog.push(action);
  }
  return next(action);
};
