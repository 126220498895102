/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from 'components/FieldTypes/skins/skins.module.scss';
import InputField from 'components/FieldTypes/InputField';
import SaveButton from 'components/CustomButtons/SaveButton';
import { fetchClockedInEmployees, resetLastClockIn } from 'actions/clockInOut';
import CloseButton from 'components/CustomButtons/CloseButton';
import { addWarning, dismissAll, dismissType } from 'actions/Error';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { loginWithPass, loginWithPin } from 'services/ErplyAPI/api';
import * as api from 'services/ErplyAPI/clockInOut';
import { fetchSalesData } from 'actions/Login';

const ClockOut = ({ clockedInEmployee, username }) => {
  const [password, updatePassword] = useState('');

  const loggedInEmployeeID = useSelector(getLoggedInEmployeeID);
  const { posID } = useSelector(getSelectedPos);
  const selectedWarehouse = useSelector(getSelectedPos).warehouseID;
  const dispatch = useDispatch();
  const { t } = useTranslation('clockInOut');

  const requestClockOut = async () => {
    const clockedOutResponse = await api.clockOut({
      employeeID: clockedInEmployee.employeeID,
      pointOfSaleID: posID,
      warehouseID: selectedWarehouse,
      InUnixTime: clockedInEmployee.clockInUnixTime,
      OutUnixTime: Math.round(new Date().getTime() / 1000),
    });

    if (clockedInEmployee.employeeID === parseInt(loggedInEmployeeID, 10)) {
      dispatch(resetLastClockIn());
    }

    if (clockedOutResponse.warningNotClockedInYet) {
      return dispatch(
        addWarning(t('alerts.notClockedIn'), {
          selfDismiss: true,
          dismissible: false,
        }),
      );
    }
    return Promise.all([
      dispatch(fetchClockedInEmployees()),
      dispatch(fetchSalesData),
    ]);
  };

  const handleSubmit = () => {
    dispatch(
      addWarning(t('alerts.verifyingPass'), {
        selfDismiss: false,
        dismissible: false,
        errorType: 'clockout-verifying'
      }),
    );
    loginWithPass({ username, password })
      .catch(() => loginWithPin({ username, cardCode: password }))
      .then(([user]) => {
          if (String(user.employeeID) !== String(clockedInEmployee.employeeID)) {
            throw new Error('Credentials do not match employee');
          }
          return requestClockOut();
      })
      .catch(error => {
          dispatch(
            addWarning(t('ErplyAPI.error.credentials', { ns: 'validation' }), {
              selfDismiss: true,
              dismissible: false,
            }),
          );
        })
      .finally(() => dispatch(dismissType('clockout-verifying')))
  };

  return (
    <div data-testid="clock-out-modal">
      <Modal.Header
        style={{
          display: 'flex',
        }}
      >
        <Modal.Title style={{ fontSize: '24px', fontWeight: 700 }}>
          {t('clockOut.title', { name: clockedInEmployee.employeeName })}
        </Modal.Title>
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <SaveButton
            title={t('buttons.clockout')}
            className="clock-out-button"
            action={() => handleSubmit()}
            data-testid="save-btn"
          />
          <CloseButton action={() => dispatch(fetchClockedInEmployees())} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="clock-in-form"
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <Form.Group>
            <InputField
              size="lg"
              data-testid="password"
              title={t('fields.password')}
              name="clock-out-password"
              type="password"
              className={classNames([styles.formInput, styles.mediumTitle])}
              value={password}
              onChange={event => updatePassword(event.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </div>
  );
};

ClockOut.propTypes = {
  /** Selected employee to clock out */
  clockedInEmployee: PropTypes.object.isRequired,
  /** Username of the employee to clock out */
  username: PropTypes.string.isRequired,
};

export default ClockOut;
