import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUserRights } from 'reducers/Login';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { selectPos } from 'actions/PointsOfSale/selectPos';
import { saveSetting } from 'actions/configs';
import { UrlControl } from 'services/UrlControl/UrlControl';

export const usePreventTrespassing = () => {
  const dispatch = useDispatch();
  const userRights = useSelector(getUserRights);
  const allWhRights = Object.values(userRights?.warehouses || {});
  const rightsAreLoaded = !!allWhRights.length;
  const warehouseRights = allWhRights
    .filter(wh => Number(wh.right))
    .map(wh => wh.warehouseID);
  const selectedPos = useSelector(getSelectedPos) || {};
  const currentWarehouse = selectedPos.warehouseID;
  const overridden =
    String(UrlControl.autoSelectPos?.pointOfSaleId) ===
    String(selectedPos?.pointOfSaleID);
  const trespassing =
    rightsAreLoaded &&
    currentWarehouse &&
    warehouseRights.every(id => Number(id) !== Number(currentWarehouse)) &&
    !overridden;

  useEffect(() => {
    if (trespassing) {
      dispatch(selectPos(null));
      // If there is an associated POS, it must be the one they're trespassing in
      // therefore disassociate it
      dispatch(
        saveSetting({
          parameterName: 'CONFIGURATION: associated-pos',
          parameterValue: null,
        }),
      );
    }
  }, [trespassing]);
};
