export const cayanFields: Record<
  string,
  {
    id: string;
    type: 'checkbox' | 'text';
    validate?: (
      val?: string,
    ) => null | { message: string; dict: { field: string } };
  }
> = {
  deviceIPOrHost: {
    id: 'deviceIPOrHost',
    type: 'text',
    validate: (val?: string) =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'deviceIPOrHost' } },
  },
  secureDeviceConnection: {
    id: 'secureDeviceConnection',
    type: 'checkbox',
  },
  cayanVault: {
    id: 'cayanVault',
    type: 'checkbox',
  },
  merchantName: {
    id: 'merchantName',
    type: 'text',
    validate: (val?: string) =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'merchantName' } },
  },
  merchantSiteID: {
    id: 'merchantSiteID',
    type: 'text',
    validate: (val?: string) =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'merchantSiteID' } },
  },
  merchantKey: {
    id: 'merchantKey',
    type: 'text',
    validate: (val?: string) =>
      val?.length
        ? null
        : { message: 'isMissing', dict: { field: 'merchantKey' } },
  },
  // TODO: check if the following parameter form cayan config.json is needed in the form
  // secureDeviceConnection: {
  //   id: 'secureDeviceConnection',
  //   type: 'text',
  //   validate: val =>
  //     val?.length
  //       ? null
  //       : { message: 'isMissing', dict: { field: 'secureDeviceConnection' } },
  // },
};
