import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Modal, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

import {
  getAvailablePlugins,
  getEnabledPlugins,
  getPluginStatus,
} from 'reducers/Plugins';
import { disablePlugin, enablePlugin } from 'actions/Plugins';
import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import Loader from 'components/Loader';
import InputField from 'components/FieldTypes/InputField';
import { getIsLoadingCafa } from 'reducers/cafaConfigs';
import { PluginStatus, PosPlugin } from 'plugins/plugin';
import CloseButton from 'components/CustomButtons/CloseButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';
import { addWarning } from 'actions/Error';
import MarkDownToHtml from 'components/MarkDownToHtml';

import './plugins.scss';
import { modalPages } from 'constants/modalPage';

const variants: {
  error: 'danger';
  valid: 'success';
  warning: 'warning';
} = {
  error: 'danger',
  valid: 'success',
  warning: 'warning',
};

type PluginRowProps = {
  plugin: PosPlugin;
};
const PluginRow: FC<PluginRowProps> = ({ plugin }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<PosPlugin | null>(null);
  const [showInfo, setShowInfo] = useState(false);
  const [promptConfig, setPromptConfig] = useState(false);

  const enabled = useSelector(getEnabledPlugins);
  const isEnabled = plugin =>
    enabled.some(({ plugin: p }) => p?.id === plugin.id);

  useEffect(() => {
    if (!loading) return;
    if (isEnabled(loading)) {
      dispatch(disablePlugin(loading)).then(() => setLoading(null));
    } else {
      dispatch(enablePlugin(loading)).then(() => setLoading(null));
    }
  }, [loading]);

  // Dismiss user prompt for plugin configuration
  useEffect(() => {
    if (promptConfig) {
      setTimeout(() => setPromptConfig(false), 5000);
    }
  }, [promptConfig]);

  const toggle = (plugin: PosPlugin & { status: PluginStatus }) => () => {
    // prompt user to configure Plugin if the status is error
    if (plugin?.status?.type === 'error' && !isEnabled(plugin)) {
      setPromptConfig(true);
      return dispatch(addWarning(plugin.status.message));
    }
    if (loading) return null;
    return setLoading(plugin);
  };

  const status = useSelector(getPluginStatus(plugin.id));

  return (
    <>
      <tr data-testid="plugin-row" onClick={() => setShowInfo(open => !open)}>
        <td className="align-middle" onClick={e => e.stopPropagation()}>
          <Loader block show={loading === plugin}>
            <InputField
              data-testid="toggle"
              data-test-key={plugin.id}
              type="checkbox"
              onChange={toggle({ ...plugin, status })}
              value={isEnabled(plugin)}
            />
          </Loader>
        </td>
        <td
          data-testid="name"
          data-test-key={plugin.id}
          className="h5 align-middle"
        >
          {plugin.name}
        </td>
        <td className="align-middle">
          <div
            className={classNames('configuration-prompt', {
              active:
                promptConfig &&
                (plugin.ComponentConfiguration ||
                  plugin.ComponentConfigurationByLevel),
            })}
          >
            <UIButton
              style={{
                visibility:
                  plugin.ComponentConfiguration ||
                  plugin.ComponentConfigurationByLevel
                    ? 'visible'
                    : 'hidden',
              }}
              text="Configure plugin"
              action={() => {
                dispatch(
                  openModalPage({
                    component: modalPages.ConfigurePlugin,
                    props: { plugin },
                  }),
                );
                setPromptConfig(false);
              }}
              data-testid="config-btn"
              data-test-key={plugin.id}
            />
          </div>
        </td>
        <td className="text-right align-middle">
          <Alert
            data-testid="status"
            data-test-key={plugin.id}
            className="mb-0 text-center"
            variant={variants[status.type] || 'info'}
          >
            {status.message || status.type}
          </Alert>
        </td>
      </tr>
      {showInfo && (plugin.info || plugin.infoMDUrl) && (
        <tr
          data-testid="plugin-info"
          data-test-key={plugin.id}
          className="plugin-info"
        >
          <td colSpan={4}>
            <MarkDownToHtml url={plugin.infoMDUrl} markdown={plugin.info} />
          </td>
        </tr>
      )}
    </>
  );
};

const Plugins: FC = () => {
  const dispatch = useDispatch();

  const [searchPluginVal, setSearchPluginVal] = useState('');
  const available = useSelector(getAvailablePlugins);
  const loadingCafa = useSelector(getIsLoadingCafa);
  const onClose = () => dispatch(previousModalPage());
  useShortcut('Escape', onClose, 100);

  const filterPlugins = (plugins: PosPlugin[]): PosPlugin[] => {
    if (searchPluginVal.trim() === '') {
      return plugins;
    }
    const searchTermsArray = /\s/g.test(searchPluginVal)
      ? searchPluginVal.toLocaleLowerCase().split(' ')
      : [searchPluginVal.toLocaleLowerCase()];
    return plugins.filter(
      av =>
        searchTermsArray.every(searchTerm =>
          av.keywords?.some(kw => kw.includes(searchTerm)),
        ) ||
        searchTermsArray.every(searchTerm => {
          return av.name
            .toLocaleLowerCase()
            .split(' ')
            ?.some(namePart => namePart.includes(searchTerm));
        }),
    );
  };
  // @ts-ignore
  return (
    <Box overflow="hidden">
      <Modal.Header className="d-flex">
        <h3 className="flex-fill">Plugins</h3>
        <UIButton
          text="Changes are saved automatically"
          disabled
          variant="POS"
        />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body className="d-flex flex-column flex-fill">
        <InputField
          autoFocus
          onChange={e => setSearchPluginVal(e.target.value)}
          value={searchPluginVal}
        />
        <Loader
          show={loadingCafa}
          loadingText="Loading configs from CAFA"
          className="plugins-loader"
        >
          <Table className="mt-3 plugins-table">
            <thead>
              <tr>
                <th>Enable</th>
                <th>Name</th>
                <th>Configure</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filterPlugins(available).map(plugin => (
                <PluginRow key={plugin.id} plugin={plugin} />
              ))}
            </tbody>
          </Table>
        </Loader>
      </Modal.Body>
    </Box>
  );
};

export default Plugins;
