import axios from 'axios';

import { PaymentObj } from 'paymentIntegrations/types';

import { IntegrationData } from '../types';

const tsysCanadaPayment = async (
  cardPayment: PaymentObj,
  { terminalID, xWebID, authKey, taxesAmount }: IntegrationData,
): Promise<string> => {
  const ponumber = new Date().getTime();
  const payloadString = `<REQUEST><TRANSACTIONTYPE>SALE</TRANSACTIONTYPE><AMOUNT>${cardPayment.amount}</AMOUNT><TAXAMOUNT>${taxesAmount}</TAXAMOUNT><PONUMBER>${ponumber}</PONUMBER><XWEBID>${xWebID}</XWEBID><XWEBTERMINALID>${terminalID}</XWEBTERMINALID><XWEBAUTHKEY>${authKey}</XWEBAUTHKEY></REQUEST>`;

  const baseURL =
    'https://localsystem.paygateway.com:21113/RcmService.svc/Initialize?callback=jsonpResponse&xl2Parameters=';
  const payment = await axios.get(`${baseURL}${payloadString}`, {
    headers: {
      Accept: '*',
      'Content-Type': 'application/json',
    },
  });

  if (payment && payment.data) {
    return payment.data;
  }

  throw new Error('TSYS payment response contains no payment data');
};

export default tsysCanadaPayment;
