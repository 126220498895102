import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import UIButton from 'components/UIElements/UIButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

import CustomPromptsForm from './CustomPromptsForm';
import useCustomPromptsFormValidation from './useCustomPromptsFormValidation';

const CustomPrompts = ({ resolve, reject, prompts, productName }) => {
  const { t } = useTranslation('customPrompts');

  const dispatch = useDispatch();

  const {
    invalidPrompts,
    validate,
    getErrorText,
  } = useCustomPromptsFormValidation(prompts);

  const [data, setData] = useState(
    Object.fromEntries(prompts.map(p => [p, ''])),
  );

  const confirm = () => {
    if (!invalidPrompts.length) {
      dispatch(previousModalPage());
      resolve(data);
    }
  };

  useShortcut('Enter', confirm);

  const cancel = () => {
    dispatch(previousModalPage());
    reject(new Error(t('errors.customPrompts')));
  };

  return (
    <div data-testid="custom-prompt">
      <Modal.Header>
        <Modal.Title data-testid="title">{productName}</Modal.Title>
        <span style={{ flexGrow: 1 }} />
        <UIButton
          data-testid="save"
          variant="POS"
          text={t('buttons.confirm')}
          action={confirm}
          disabled={!!invalidPrompts.length}
        />

        <CloseButton data-testid="cancel" action={cancel} />
      </Modal.Header>
      <Modal.Body>
        <CustomPromptsForm
          prompts={prompts}
          value={data}
          onChange={(p, v) => setData(d => ({ ...d, [p]: v }))}
          validate={validate}
          getErrorText={getErrorText}
          autoFocus
        />
      </Modal.Body>
    </div>
  );
};

export default CustomPrompts;
