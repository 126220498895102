import { ThunkAction } from 'redux-thunk';

import { pluginAction } from 'actions/Plugins';
import { InitialState } from 'actions/modalPage';
import { getHasProductsInShoppingCart, getTotal } from 'reducers/ShoppingCart';
import { ErplyAttributes } from 'utils';
import {
  getIsAReturn,
  getReturnTotal,
  getCurrentSalesDocument,
  getIsCurrentSaleAReturn,
} from 'reducers/sales';
import { getSelectedCustomer } from 'reducers/customerSearch';
import { getSelectedPos } from 'reducers/PointsOfSale';
import { getLoggedInEmployeeID } from 'reducers/Login';
import { getPosRoundCash } from 'reducers/configs/settings';
import { setPaymentsState, processPayments } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import {
  getCashPayment,
  getRoundedTotal,
  getBalance,
  getPaymentsCurrency,
} from 'reducers/Payments';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getAllPaymentTypes } from 'reducers/PaymentTypes';
import { getPluginConfiguration } from 'reducers/Plugins';

import { SET_ORIGINAL_SALES_DOCUMENT } from './actionTypes';
import { getExchangeState } from './selectors';

type ManagerOverrideReason = {
  /** Unique key of the override reason - saved to the overrides attribute */
  key: string;
  /** Text displayed in the prompt in the POS */
  POSText: string;
  /** Text displayed in the BO in the internal notes field */
  BOText: string;
  /** Validation function to check if a given user group can override this reason */
  isValidOverride: ({ groupID: string, attributes: any }) => boolean;
  context: string;
};

const showManagerOverride: (
  reasons: ManagerOverrideReason[],
) => ThunkAction<any, any, any, any> = pluginAction('showManagerOverride');

const checkBitPerm = group => {
  const v = Number(
    new ErplyAttributes(group.attributes).get(
      `pnp-process-exchanges_permission-exchange`,
    ),
  );
  return !!v;
};

const processPayment = () => async (dispatch, getState) => {
  const state = getState();

  const hasCart = getHasProductsInShoppingCart(state);
  const total = getTotal(state);
  const isAReturn = getIsAReturn(state);
  const returnTotal = getReturnTotal(state);
  const shoppingCartTotal = isAReturn ? returnTotal : total;
  const selectedCustomer = getSelectedCustomer(state);
  const selectedPos = getSelectedPos(state);
  const employeeID = getLoggedInEmployeeID(state);
  const posRoundCash = getPosRoundCash(state);
  const toPay = -getBalance(state);
  const existingPayment = getCashPayment(state) || { amount: 0 };
  const rounding = getRoundedTotal(state) - total;
  const paymentTypes = getAllPaymentTypes(state);
  const currencyCode = getPaymentsCurrency(state).code;
  const currentExchangeConfig: any =
    getPluginConfiguration('pnp-process-exchanges')(state) ?? {};
  const currentSalesDocument = getCurrentSalesDocument(state);
  const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);
  const selectedWarehouseID = getSelectedWarehouseID(state);

  const exchangeState = getExchangeState(state);
  const { exchangeStep, originalSalesDoc } = exchangeState;

  if (hasCart) {
    const salesDocument: any = {};
    const payments = {};

    if (exchangeStep === 1) {
      try {
        await dispatch(
          showManagerOverride([
            {
              key: 'exchange',
              POSText: 'Making a return during an exchange',
              BOText: 'Making a return during an exchange',
              isValidOverride: checkBitPerm,
              context: 'salesDoc',
            },
          ]),
        );
      } catch (error) {
        return false;
      }
      await dispatch({
        type: SET_ORIGINAL_SALES_DOCUMENT,
        payload: currentSalesDocument,
      });

      Object.assign(salesDocument, {
        creditToDocumentID: currentSalesDocument.id,
        type: 'CREDITINVOICE',
        creditInvoiceType: 'RETURN',
        warehouseID: selectedWarehouseID,
        isCashInvoice:
          currentSalesDocument.type === 'INVWAYBILL' ||
          currentSalesDocument.type === 'CASHINVOICE'
            ? 1
            : 0,
      });
    }

    if (exchangeStep === 2) {
      Object.assign(salesDocument, {
        creditToDocumentID: currentSalesDocument.id,
        type: 'CASHINVOICE',
        paymentTypeID: currentExchangeConfig.selectedPaymentType,
      });
    }

    const defineNotesText = (notesOrInternal: string): string => {
      const currentNotes = salesDocument[notesOrInternal] ?? '';
      if (
        originalSalesDoc?.notes?.includes('Exchange based on Receipt') &&
        exchangeStep === 2
      ) {
        const originalSale = originalSalesDoc.notes.slice(25);
        return `Exchange based on Receipt ${originalSalesDoc.number} (${originalSalesDoc.date} ${originalSalesDoc.time}); Original sale: Receipt ${originalSale}\n${currentNotes}`;
      }

      if (exchangeStep === 2) {
        return `Exchange based on Receipt ${originalSalesDoc.number} (${originalSalesDoc.date} ${originalSalesDoc.time})\n${currentNotes}`;
      }

      return currentNotes;
    };

    const initialStateData: any = {
      isCurrentSaleAReturn,
      currentSalesDocument,
      salesDocument: {
        ...salesDocument,
        notes: defineNotesText('notes'),
        internalNotes: defineNotesText('internalNotes'),
      },
      payments: { ...payments },
      shoppingCartTotal,
      selectedCustomer,
      selectedPos,
      employeeID,
      paymentLimits: [],
      posRoundCash,
      customer: null,
      total,
      resetSalesDocumentOnClose: false,
    };

    const initialState = new InitialState(initialStateData);

    await dispatch(setPaymentsState(initialState));
    await dispatch(setPaymentSelected(''));

    if (exchangeStep === 1) {
      const selectedPaymentType = paymentTypes.find(
        type => type.id === currentExchangeConfig.selectedPaymentType,
      );

      await dispatch(
        setPayment({
          key: 'exchange',
          type: selectedPaymentType?.type,
          caption: selectedPaymentType?.name,
          amount: total,
          currencyCode,
        }),
      );
    }
    if (exchangeStep === 2) {
      const existingAmount = Number(existingPayment.amount);
      const newAmount = toPay + existingAmount + rounding;
      const selectedPaymentType = paymentTypes.find(
        type => type.id === currentExchangeConfig.selectedPaymentType,
      );

      await dispatch(
        setPayment({
          key: 'exchange',
          type: selectedPaymentType?.type,
          caption: selectedPaymentType?.name,
          amount: newAmount,
          currencyCode,
        }),
      );
    }
    await dispatch(processPayments());
  }
};

export default processPayment;
