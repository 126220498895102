import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrencyByCode } from 'reducers/configs/currency';
import { getCurrencyCode } from 'reducers/configs/settings';
import { getTotal } from 'reducers/ShoppingCart';

import { getCurrency, getIsRegularCardAndTaxFree } from '../redux';

const Conversion: React.FC<{ children?: never }> = () => {
  const isRegularGiftCardAndTaxFree = useSelector(getIsRegularCardAndTaxFree);
  const currencyCode = useSelector(getCurrencyCode);
  const defaultCurrency = useSelector(getCurrencyByCode(currencyCode));
  const chosenCurrency = useSelector(getCurrency);

  const total = useSelector(getTotal);
  const totalInTargetCurrency =
    total * Number(chosenCurrency?.rate ?? defaultCurrency?.rate);

  return isRegularGiftCardAndTaxFree && chosenCurrency?.code ? (
    <h3 style={{ marginLeft: '1rem' }}>
      {chosenCurrency?.code}-{defaultCurrency?.code ?? ''} vahetus:{' '}
      {totalInTargetCurrency.toFixed(2)}
    </h3>
  ) : null;
};

export default Conversion;
