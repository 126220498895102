export const GET_PRODUCT_GROUPS = 'GET_PRODUCT_GROUPS';
export const GET_PRODUCT_GROUPS_SUCCESS = 'GET_PRODUCT_GROUPS_SUCCESS';
export const GET_PRODUCT_GROUPS_ERROR = 'GET_PRODUCT_GROUPS_ERROR';

export const SET_PRODUCT_GROUP = 'SET_PRODUCT_GROUP';
export const SET_PREV_PRODUCT_GROUP = 'SET_PREV_PRODUCT_GROUP';
export const RESET_PROD_GROUP = 'RESET_PROD_GROUP';

export const SAVE_PRODUCT_GROUPS = 'SAVE_PROD_GROUP';
export const SAVE_PRODUCT_GROUPS_SUCCESS = 'SAVE_PROD_GROUP_SUCCESS';
export const SAVE_PRODUCT_GROUPS_ERROR = 'SAVE_PROD_GROUP_ERROR';
