import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { ErplyApiError } from 'services/ErplyAPI/core/apiErrors';

import { setCanPerformCFRReturn } from '../rdx';
import getFranchiseSalesDocuments from '../requests';

const ComponentHeader: PosPlugin['ComponentHeader'] = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    async function checkForFranchiseReturnRights() {
      try {
        await getFranchiseSalesDocuments({
          nonReturnedItemsOnly: 0,
          types: 'INVWAYBILL,CASHINVOICE,EXPORTINVOICE,INVOICE',
          number: '123456789',
        });
        dispatch(setCanPerformCFRReturn(true));
      } catch (error) {
        const isErplyApiError = error instanceof ErplyApiError;
        if (isErplyApiError && error.errorCode === 1067) {
          dispatch(setCanPerformCFRReturn(false));
        }
      }
    }
    checkForFranchiseReturnRights();
  }, [dispatch]);

  return children;
};

export default ComponentHeader;
