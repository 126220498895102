import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';
import { useDebounce } from 'react-use';

import { getSelectedPos } from 'reducers/PointsOfSale';
import CloseButton from 'components/CustomButtons/CloseButton';
import * as api from 'services/ErplyAPI/sales';
import { getSetting } from 'reducers/configs/settings';
import { SaleDocumentResponse } from 'types/SalesDocument';

import { OfferRow, ScrollableTableContainer } from './components';

type Props = {
  fetchedOffers: SaleDocumentResponse[];
  onClose(): void;
};

const RecentSales: React.FC<Props> = ({ fetchedOffers = [], onClose }) => {
  const [value, setValue] = useState<string | null>(null);
  const [offers, updateOffers] = useState(fetchedOffers);
  const { t } = useTranslation('offer', { keyPrefix: 'load' });

  const { warehouseID: currentWarehouseID } = useSelector(getSelectedPos);

  const onlyCurrentLoc = useSelector(
    getSetting('touchpos_offers_only_from_current_location'),
  );

  const searchOffer = useCallback(
    async (searchVal: string) => {
      const offers = await api.getSalesDocuments({
        warehouseID: onlyCurrentLoc ? currentWarehouseID : undefined,
        type: 'OFFER',
        confirmed: 1,
        numberOrCustomer: searchVal,
        getRowsForAllInvoices: 1,
        getUnfulfilledDocuments: 1,
      });
      updateOffers(offers);
    },
    [currentWarehouseID, onlyCurrentLoc],
  );

  useDebounce(
    () => {
      if (value !== null) {
        searchOffer(value);
      }
    },
    1000,
    [searchOffer, value],
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setValue(value);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="0.75rem"
      padding="1.25rem"
      data-testid="offers-modal"
      overflow="hidden"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t('title')}</Typography>
        <CloseButton action={onClose} />
      </Box>
      <Divider />
      <TextField
        fullWidth
        variant="outlined"
        InputProps={{
          endAdornment: <i className="flip-horizontal  icon_search" />,
        }}
        aria-label="Offers"
        placeholder={t('field', { context: 'placeholder' })}
        value={value ?? ''}
        onChange={handleChange}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            searchOffer(value ?? '');
          }
        }}
        data-testid="offer-input"
      />
      <ScrollableTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('headers.number')}</TableCell>
              <TableCell>{t('headers.date')}</TableCell>
              <TableCell>{t('headers.customer')}</TableCell>
              <TableCell>{t('headers.email')}</TableCell>
              <TableCell>{t('headers.paid')}</TableCell>
              <TableCell>{t('headers.sum')}</TableCell>
              <TableCell>{t('headers.print')}</TableCell>
              <TableCell>{t('headers.inbox')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.length ? (
              offers.map(offer => (
                <OfferRow
                  key={offer.id}
                  data-testid="offer"
                  data-test-key={offer.id}
                  offer={offer}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>{t('noneFound')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollableTableContainer>
    </Box>
  );
};

export default RecentSales;
