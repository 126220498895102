import axios from 'axios';

import { addError } from 'actions/Error';
import { getPluginConfiguration } from 'reducers/Plugins';

import { ENDLESS_AISLE_CONSTANTS, END_POINTS, pluginID } from './constants';
import { Configuration } from './types';

const getSessionKey = (
  params: Omit<Configuration, 'groupID'>,
) => async dispatch => {
  try {
    const { data } = await axios.post(END_POINTS.GET_SESSION_TOKEN(params));
    return data?.access_token;
  } catch (error) {
    dispatch(
      addError(error instanceof Error ? error.message : 'Something went wrong'),
    );
    throw error;
  }
};

export const generateHeaders = () => async (dispatch, getState) => {
  const { groupID, ...rest } = getPluginConfiguration<Configuration>(pluginID)(
    getState(),
  );
  const accessToken = await dispatch(getSessionKey(rest));
  localStorage.setItem(ENDLESS_AISLE_CONSTANTS.ACCESS_TOKEN, accessToken);
};
