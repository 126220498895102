import { v1 as uuidv1 } from 'uuid';
import { createSelector } from 'reselect';

import { openPluginModalPage } from 'actions/modalPage';
import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { getProductsInShoppingCart } from 'reducers/ShoppingCart';
import { addError, addWarning } from 'actions/Error';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { REASONS } from 'constants/reasonCodesDB';
import { getProductsUniversal } from 'actions/productsDB';

import InventoryWriteOff from './modals';
import { modals, pluginID } from './constants';

const getConfiguration = state => getPluginConfiguration<Conf>(pluginID)(state);

type Conf = any;
const inventoryWriteOff: PosPlugin<Conf> = {
  id: pluginID,
  name: 'Inventory Write-Off',
  getStatus: state => {
    const writeOffReasonCodes = getReasonCodes(REASONS.WRITEOFF)(state);
    if (!writeOffReasonCodes.length) {
      return {
        type: 'error',
        message: 'Configure inventory write-off reason codes in BO',
      };
    }
    return {
      type: 'valid',
      message: 'Ready',
    };
  },
  // @ts-ignore
  getCustomButtons: createSelector(
    getConfiguration,
    getProductsInShoppingCart,
    getReasonCodes(REASONS.WRITEOFF),
    (conf: Conf, cart, writeOffReasonCodes) => {
      return {
        functions: [
          {
            id: uuidv1(),
            name: 'Inventory Write-Off',
            actionType: 'action',
            action: async dispatch => {
              if (cart.length > 0) {
                const { products } = await dispatch(
                  getProductsUniversal({
                    productIDs: cart?.map(p => p.productID),
                  }),
                );
                if (
                  products?.some(
                    p => p?.nonStockProduct || p?.type === 'BUNDLE',
                  )
                ) {
                  return dispatch(
                    addError('Cart contains non-stock or bundle products'),
                  );
                }
                if (!writeOffReasonCodes.length) {
                  return dispatch(
                    addError(
                      'Write-off reasons need to be configured in the back office',
                    ),
                  );
                }
                return dispatch(
                  openPluginModalPage(modals.inventoryOffModal)({
                    isPopup: true,
                    props: {
                      cart,
                      writeOffReasonCodes,
                    },
                  }),
                );
              }
              return dispatch(
                addWarning('Please select some items to write off.'),
              );
            },
          },
        ],
        saleOptions: [],
      };
    },
  ),
  components: {
    [modals.inventoryOffModal]: InventoryWriteOff,
  },
};

export default inventoryWriteOff;
