import { withStyles } from '@material-ui/styles';
import { TableContainer } from '@material-ui/core';

const ScrollableTableContainer = withStyles({
  root: {
    flexGrow: 1,
    marginTop: '1rem',
    '& th, & td': { padding: '0.75rem 0.5rem' },
    '& th': {
      fontWeight: 700,
    },
  },
})(TableContainer);

export default ScrollableTableContainer;
