import * as constants from '../constants/assortmentProducts';

const initialState = {
  all: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_ASSORTMENT_PRODUCTS_SUCCESS:
      return {
        ...state,
        all: payload,
      };
    default:
      return state;
  }
};

export function getAssortmentProducts(state) {
  return state.assortmentProducts.all;
}
