import axios from 'axios';

import { getMSEndpointFromLS } from 'reducers/installer';

import { ServiceInfo } from './types';

function getReceiptPrintoutApiUrl(): string {
  const endpoint =
    getMSEndpointFromLS('metricsCollector') ||
    'https://localhost.erply.com:10901/';
  return `${endpoint}api/v1`;
}

export async function getServiceInfo(sessionKey: string, clientCode: string) {
  const url = getReceiptPrintoutApiUrl();
  const { data } = await axios.get<ServiceInfo>(`${url}/service/info`, {
    headers: {
      sessionKey,
      clientCode,
    },
  });
  return data;
}
