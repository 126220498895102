import { createSelector } from 'reselect';

import { getWarehouseById } from 'reducers/warehouses';
import { getSetting } from 'reducers/configs/settings';
import { getSelectedPos } from 'reducers/PointsOfSale';
import {
  getSelectedCustomerGroupPriceListIds,
  getStoreRegionPriceListIds,
} from 'reducers/storeRegions';
import { RootState } from 'reducers';
import { Warehouse } from 'types/Warehouse';

export const getActivePricelistIDsForPOS = createSelector<
  RootState,
  Warehouse,
  number[],
  number[],
  boolean,
  number[]
>(
  state => getWarehouseById((getSelectedPos(state) || {}).warehouseID)(state),
  state => getStoreRegionPriceListIds(state),
  state => getSelectedCustomerGroupPriceListIds(state),
  state => getSetting('pos_allow_selling_only_from_pricelist1')(state),
  (
    wh,
    storeRegionPriceLists,
    selectedCustomerGroupPriceLists,
    priceListOverride,
  ) => {
    const warehousePriceLists = !wh
      ? []
      : [
          wh.pricelistID,
          wh.pricelistID2,
          wh.pricelistID3,
          wh.pricelistID4,
          wh.pricelistID5,
        ];
    // Price list priority {@see https://wiki.erply.com/article/501-store-regions}
    const priceLists = [
      ...storeRegionPriceLists,
      // Region and customer group price lists are applied automatically by the API but cache anyway to support offline mode
      ...selectedCustomerGroupPriceLists,
      ...warehousePriceLists,
    ].filter(a => Number(a) !== 0);

    if (priceListOverride) {
      // Only first price list means first warehouse price list {@see https://wiki.erply.com/article/1705-allow-selling-only-from-price-list-1}
      return wh?.pricelistID ? [wh.pricelistID] : [];
    }
    return priceLists;
  },
);
