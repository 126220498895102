import { createSelector } from 'reselect';

import * as c from 'constants/UI';
import { RootState } from 'reducers';
import { Product } from 'types/Product';

import { getProductGroupByID } from '../productGroupsDB';

interface GridDisplayState {
  opened: boolean;
  view: typeof c.displayView[keyof typeof c.displayView];
  currentProductGroup: string;
  currentProductPage: number;
  shouldGridUpdate: boolean;
}

const initialState: GridDisplayState = {
  opened: true,
  view: c.displayView.main,
  currentProductGroup: '0',
  currentProductPage: 0,
  shouldGridUpdate: false,
};

function reducer(state = initialState, { type, payload }): GridDisplayState {
  switch (type) {
    case c.OPEN_GRID_VIEW:
      return { ...state, opened: true };
    case c.CLOSE_GRID_VIEW:
      return { ...state, opened: false };
    case c.SET_GRID_VIEW:
      return { ...state, view: payload };
    case c.SET_PRODUCT_GROUP:
      return { ...state, currentProductGroup: payload };
    case c.SET_PRODUCT_PAGE:
      return { ...state, currentProductPage: payload };
    case c.SET_GRID_SHOULD_UPDATE:
      return { ...state, shouldGridUpdate: payload };
    default:
      return state;
  }
}

export default reducer;

export function getSelectedProductGroupID(state: RootState) {
  return state.UI.gridDisplay.currentProductGroup;
}

export function getSelectedProductGroup(state: RootState) {
  return getProductGroupByID(getSelectedProductGroupID(state))(state);
}

export function getIsRootProductGroupSelected(state: RootState) {
  return state.UI.gridDisplay.currentProductGroup === '0';
}

export function getSelectedProductPage(state: RootState) {
  return state.UI.gridDisplay.currentProductPage;
}

export const getGridIsOpened = createSelector(
  (state: RootState) => state.UI.gridDisplay.opened,
  () => localStorage.getItem('viewMode'),
  (opened, mode) => (mode ? mode === 'grid' && opened : opened),
);
export function getGridView(state: RootState) {
  return state.UI.gridDisplay.view;
}

export function getIsRetailView(state: RootState) {
  return !getGridIsOpened(state);
}

export function getShouldGridUpdate(state: RootState) {
  return state.UI.gridDisplay.shouldGridUpdate;
}

export const getCheckProductDisplayedInGridView = createSelector<
  RootState,
  string,
  number,
  (product: Product & { page: number }) => boolean
>(
  state => getSelectedProductGroupID(state),
  state => getSelectedProductPage(state),
  (selectedProductGroupID, selectedProductPage) => {
    return (product: Product & { page: number }) => {
      const areQuickButtonsOpened = selectedProductGroupID === '0';

      const matchesSelectedGroup =
        Number(product.groupID) === Number(selectedProductGroupID);

      const isQuickSelectProduct = product.hasQuickSelectButton;

      const matchesSelectedPage = product.page === selectedProductPage + 1;

      const isDisplayedInGridView =
        (areQuickButtonsOpened && isQuickSelectProduct) ||
        (!areQuickButtonsOpened && matchesSelectedGroup && matchesSelectedPage);

      return isDisplayedInGridView;
    };
  },
);
