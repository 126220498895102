/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { isValid } from 'date-fns';

import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { saveCustomer } from 'actions/customerSearch';
import CloseButton from 'components/CustomButtons/CloseButton';
import SaveButton from 'components/CustomButtons/SaveButton';
import { modalPages as mp } from 'constants/modalPage';
import {
  getCustomerRegistryUrl,
  getDefaultCustomerGroup,
  getSelectedCustomer,
} from 'reducers/customerSearch';
import { getCanAddCustomers } from 'reducers/Login';
import { getSetting } from 'reducers/configs/settings';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { addWarning } from 'actions/Error';
import {
  getDefaultCustomerFormValues,
  getGeneralCustomerSettings,
  getDefaultHomeStoreID,
} from 'reducers/cafaConfigs';
import { RootState } from 'reducers';
import { PluginComponent } from 'plugins';
import { Customer } from 'types/Customer';

import { CUSTOMER_TYPES } from '../../../Settings/views/Customers/types';

import {
  companyInitState,
  personInitState,
  Person,
  Company,
} from './formShapes';
import CustomerForm from './CustomerForm';

import './CreateCustomer.sass';

const CreateCustomer = ({
  newCustomer,
  newCustomerData = {},
  customer: inputCustomer,
  onClose,
}) => {
  const selectedCustomer = useSelector(getSelectedCustomer);

  const customer = inputCustomer || selectedCustomer;

  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const { t } = useTranslation('createCustomer');

  const customerAddition = !customer.customerID;

  const hasRightsToAddCustomer = useSelector(getCanAddCustomers) === 1;
  const isTrespassing = !hasRightsToAddCustomer && customerAddition;
  useEffect(() => {
    if (isTrespassing) {
      onClose().then(
        dispatch(
          addWarning(t(`alerts.cannotAddCustomer`), {
            selfDismiss: 3000,
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrespassing]);

  const customerRegistry = useSelector(getCustomerRegistryUrl);
  const { customer_form_configuration: configuration } = useSelector(
    getGeneralCustomerSettings,
  );
  const defaultCustomerValues = useSelector(getDefaultCustomerFormValues);
  const allowCreateNewCompany = !useSelector(
    getSetting('brazil_disable_create_company'),
  );
  const warehouseID = useSelector(getSelectedWarehouseID);
  const defaultPersonHomeStoreID = useSelector(
    getDefaultHomeStoreID(CUSTOMER_TYPES.PERSON),
  );
  const defaultCompanyHomeStoreID = useSelector(
    getDefaultHomeStoreID(CUSTOMER_TYPES.COMPANY),
  );

  /**
   * This function will parse the date so it would be visible in our birthday input
   * If date format is not valid, input will be empty
   * @param birthday birthday to parse
   * @returns parsed date, format is 'YYYY-MM-DD'
   */
  function formatBirthday(birthday: string) {
    const date = new Date(birthday);
    if (!isValid(date)) return '';
    return Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date);
  }

  const defaultGroupID = useSelector(getDefaultCustomerGroup)?.customerGroupID;

  const [PERSON, setPERSON] = useState<Person>({
    // new customer default data
    ...personInitState,
    // configured default data
    ...(newCustomer ? defaultCustomerValues[CUSTOMER_TYPES.PERSON] : {}),
    // homestoreID for a new customer
    homeStoreID: defaultPersonHomeStoreID,
    // default group for new customer
    groupID:
      defaultCustomerValues[CUSTOMER_TYPES.PERSON].groupID ?? defaultGroupID,
    // default address info for a new customer
    addresses: [
      {
        address: defaultCustomerValues[CUSTOMER_TYPES.PERSON].address ?? '',
        address2: defaultCustomerValues[CUSTOMER_TYPES.PERSON].address2 ?? '',
        city: defaultCustomerValues[CUSTOMER_TYPES.PERSON].city ?? '',
        country: defaultCustomerValues[CUSTOMER_TYPES.PERSON].country ?? '',
      },
    ],
    // if new customer - data for new customer
    // if existing customer - data from existing customer (overrides homeStoreID)
    ...(newCustomer
      ? newCustomerData
      : {
        ...customer,
        birthday: formatBirthday(customer.birthday),
      }),
  });
  const [COMPANY, setCOMPANY] = useState<Company>({
    ...companyInitState,
    ...(newCustomer ? defaultCustomerValues[CUSTOMER_TYPES.COMPANY] : {}),
    homeStoreID: defaultCompanyHomeStoreID,
    addresses: [
      {
        address: defaultCustomerValues[CUSTOMER_TYPES.COMPANY].address ?? '',
        address2: defaultCustomerValues[CUSTOMER_TYPES.COMPANY].address2 ?? '',
        city: defaultCustomerValues[CUSTOMER_TYPES.COMPANY].city ?? '',
        country: defaultCustomerValues[CUSTOMER_TYPES.COMPANY].country ?? '',
      },
    ],

    ...(newCustomer ? {} : customer),
  });

  /**
   * @remarks
   * allowedCustomerTypes refers to a singular type, not plural.
   */
  const [activeTab, setActiveTab] = useState<
    typeof CUSTOMER_TYPES[keyof typeof CUSTOMER_TYPES]
  >(
    customer.id && !newCustomer
      ? customer.customerType
      : configuration.allowedCustomerTypes || CUSTOMER_TYPES.PERSON,
  );

  const CUSTOMER = {
    [CUSTOMER_TYPES.PERSON]: PERSON,
    [CUSTOMER_TYPES.COMPANY]: COMPANY,
  }[activeTab];

  const [invalidFieldsPerCustomerType, setFormIsValid] = useState<
    Record<string, string[]>
  >({
    [CUSTOMER_TYPES.PERSON]: [],
    [CUSTOMER_TYPES.COMPANY]: [],
  });

  const setFieldIsValid = useCallback(
    (fieldName: keyof Customer | 'senior' | 'suspended', isValid: boolean) =>
      setFormIsValid(
        R.over(
          R.lensProp(activeTab),
          R.ifElse(() => isValid, R.without([fieldName]), R.union([fieldName])),
        ),
      ),
    [activeTab],
  );

  useEffect(() => {
    const newCustomer = {
      addressTypeID: customer.addressTypeID,
      addresses: customer.addresses || [],
      newAddress: {},
    };
    const newCompany = {
      addressTypeID: customer.addressTypeID,
      addresses: customer.addresses || [],
      newAddress: {},
      birthday: '00-00-0000',
    };
    if (customerRegistry) {
      Object.assign(newCustomer, { gender: customer.gender });
      Object.assign(newCustomer, { euCustomerType: customer.euCustomerType });
      Object.assign(newCompany, { gender: customer.gender });
      Object.assign(newCompany, { euCustomerType: customer.euCustomerType });
    }
    setPERSON(p => ({ ...p, newCustomer }));
    setCOMPANY(c => ({ ...c, newCompany }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const setCustomer =
    activeTab === CUSTOMER_TYPES.PERSON ? setPERSON : setCOMPANY;

  const onSave = useCallback(() => {
    const emailOptOut = Number(CUSTOMER.emailOptOut);
    const signUpStoreID = newCustomer
      ? Number(warehouseID)
      : CUSTOMER.signUpStoreID;
    dispatch(
      saveCustomer(
        {
          ...CUSTOMER,
          emailOptOut,
          signUpStoreID,
        },
        () => {
          dispatch(
            openModalPage({
              component: mp.customerView,
              groupID: mp.customerView,
              replace: true,
            }),
          );
        },
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, saveCustomer, openModalPage, CUSTOMER]);

  const closeCreateCustomer = useCallback(() => {
    if (CUSTOMER.id) {
      dispatch(closeModalPage());
      dispatch(
        openModalPage({
          component: mp.customerView,
          groupID: mp.customerView,
          replace: true,
        }),
      );
    } else {
      dispatch(closeModalPage());
    }
  }, [dispatch, CUSTOMER.id]);

  const customerState = CUSTOMER;

  const customerTypes = useMemo(() => {
    if (customerState.id) return [customerState.customerType];
    if (!configuration.allowedCustomerTypes.length) {
      return Object.values(CUSTOMER_TYPES);
    }

    return [configuration.allowedCustomerTypes].filter(
      (type): type is 'PERSON' | 'COMPANY' => type !== '',
    );
  }, [
    customerState.id,
    configuration.allowedCustomerTypes,
    customerState.customerType,
  ]);

  return (
    <div
      className="create-customer-page"
      data-testid="create-customer-container"
    >
      <Modal.Header>
        <Modal.Title
          data-testid="title"
          style={{ fontSize: '24px', fontWeight: 700 }}
        >
          {t('title', {
            context:
              customerState.id === selectedCustomer.id ? 'edit' : 'create',
          })}
        </Modal.Title>
        <div className="create-customer-page-action-buttons">
          <PluginComponent
            hookname="UICustomerFormBetaSaveButton"
            props={{
              disabled: !!invalidFieldsPerCustomerType[activeTab].length,
              // TODO: fix types - there are many customer types conflicting with each other
              // @ts-ignore - fix later
              customer: customerState,
              onSave,
            }}
          >
            <SaveButton
              data-testid="save-button"
              action={onSave}
              disabled={!!invalidFieldsPerCustomerType[activeTab].length}
            />
          </PluginComponent>
          <CloseButton action={closeCreateCustomer} />
        </div>
      </Modal.Header>
      <Modal.Body>
        {allowCreateNewCompany && (
          <Nav variant="pills" fill>
            {customerTypes.map(el => (
              <Nav.Item key={el}>
                <Nav.Link
                  className={el === activeTab ? 'nav-link active' : 'nav-link'}
                  onClick={() => setActiveTab(el)}
                  eventKey={el}
                  data-testid={el}
                >
                  {t(`type.${el.toLowerCase()}`)}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        )}
        <CustomerForm
          onChange={setCustomer}
          customer={customerState}
          setFieldIsValid={setFieldIsValid}
          configuration={configuration}
        />
      </Modal.Body>
    </div>
  );
};

CreateCustomer.propTypes = {
  /** If true, displays a new customer form regardless of whether the POS has a customer already selected */
  newCustomer: PropTypes.bool,
  customer: PropTypes.object.isRequired,
};
export default CreateCustomer;
