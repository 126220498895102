import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';

type Props = TextFieldProps & {
  extraOptions?: ReactNode;
};

function ThemeDropdown({ extraOptions, ...props }: Props) {
  const { t } = useTranslation('settingsTheme');

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      label={t('label')}
      placeholder={t('emptyOption')}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      {...props}
    >
      {['light', 'dark', 'highContrast'].map(value => (
        <MenuItem key={value} value={value}>
          {t(`options.${value}`)}
        </MenuItem>
      ))}
      {extraOptions}
    </TextField>
  );
}

export default ThemeDropdown;
