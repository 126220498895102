import React, { ChangeEvent, FC } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Field } from 'containers/Forms/Customers/views/CreateCustomerBeta/components';
import Checkbox from 'components/Checkbox';

interface Props {
  suspended?: boolean;
  handleChange: (event: ChangeEvent<{}>) => void;
}

const SuspendedField: FC<Props> = ({ suspended, handleChange }) => {
  const { t } = useTranslation('createCustomer');
  return (
    <Field
      // There is no CAFA config for 'suspended' field use config for the replaced field instead
      name="doNotSell"
      wrapper={Grid}
      wrapperProps={{ item: true, xs: 12 }}
    >
      <Checkbox
        id="suspended"
        name="suspended"
        value={!!suspended}
        onChange={handleChange}
        label={t('fields.doNotSell')}
        formControlProps={{ fullWidth: true }}
      />
    </Field>
  );
};

export default SuspendedField;
