import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';

import { suretaxReducer } from 'plugins/suretax/redux';
import { PosPlugin } from 'plugins/plugin';

/**
 * Add an input at the top of the shopping cart to accept a shipping address.
 */
export const UITableBill: PosPlugin['UITableBill'] = ({ children }) => {
  const dispatch = useDispatch();
  const savedZip = useSelector(suretaxReducer.getShippingZip);

  const [zip, setZip] = useState(savedZip);
  useEffect(() => setZip(savedZip), [savedZip]);

  return (
    <>
      <TextField
        fullWidth
        label={zip ? 'Ship to (ZIP)' : 'No shipping, in-store pickup'}
        value={zip}
        onChange={e => setZip(e.target.value)}
        onBlur={e => {
          if (zip !== savedZip) dispatch(suretaxReducer.setShippingZip(zip));
        }}
        helperText={zip !== savedZip ? 'Unsaved changes' : ''}
      />
      {children}
    </>
  );
};
