import React from 'react';
import { useSelector } from 'react-redux';

import { PluginComponent } from 'plugins';
import { getUISetting } from 'reducers/configs/settings';
import { useAppDispatch } from 'reducers';
import Icon from 'components/Icon';
import { softLogout } from 'actions/Login';

import StyledButton from './StyledButton';

const YellowButtons: React.FC<{ width: number }> = ({ width }) => {
  const hideSwitchUser = useSelector(getUISetting('hideSwitchUser'));
  const dispatch = useAppDispatch();

  return (
    <PluginComponent hookname="UICustomYellowCTA">
      {hideSwitchUser ? null : (
        <StyledButton
          variant="contained"
          data-testid="soft-logout-btn"
          onClick={() => dispatch(softLogout())}
          width={width}
          color="grid_yellow"
        >
          <Icon name="erply_users" style={{ fontSize: '32px' }} />
        </StyledButton>
      )}
      <StyledButton
        onClick={() =>
          dispatch(softLogout({ resetPOS: false, path: '/erply/pin' }))
        }
        data-testid="hard-logout-btn"
        variant="contained"
        width={width}
        color="grid_yellow"
      >
        <Icon name="erply_lock" style={{ fontSize: '32px' }} />
      </StyledButton>
    </PluginComponent>
  );
};

export default YellowButtons;
