import { useSelector } from 'react-redux';

import {
  GetProductsUniversalOptions,
  ProductRequestParams,
} from 'actions/productsDB';
import {
  getFetchOnlyProductsThatAreInStock,
  getOrderProductsByInProductGroup,
  getOrderProductsDirectionInProductGroup,
  getSetting,
  getUseLocalProductDB,
} from 'reducers/configs/settings';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import { getSelectedCustomerID } from 'reducers/customerSearch';

import useProducts from './useProducts';

const options: GetProductsUniversalOptions = {
  withMeta: true,
  localFirst: true,
};

const useProductsPerGroup = (
  params: ProductRequestParams & {
    groupID: number;
    pageNo: number;
    recordsOnPage: number;
  },
) => {
  const orderBy = useSelector(getOrderProductsByInProductGroup);
  const orderByDir = useSelector(getOrderProductsDirectionInProductGroup);
  const getItemsFromFirstPriceListOnly = useSelector(
    getSetting('pos_allow_selling_only_from_pricelist1'),
  )
    ? 1
    : 0;
  const warehouseID = useSelector(getSelectedWarehouseID);

  const isUsingLocalProductsDb = useSelector(getUseLocalProductDB);
  const fetchOnlyProductsInStock = useSelector(
    getFetchOnlyProductsThatAreInStock,
  );
  const clientID = useSelector(getSelectedCustomerID);

  return useProducts(
    {
      ...params,
      orderBy,
      orderByDir,
      includeMatrixVariations: 0,
      getWarehouseSpecificVAT: 1,
      getProductsFor: 'SALES',
      getItemsFromFirstPriceListOnly,
      warehouseID,
      getOnlyItemsInStock:
        isUsingLocalProductsDb && fetchOnlyProductsInStock ? 1 : 0,
      getPriceListPrices: 1,
      clientID,
    },
    isUsingLocalProductsDb ? { ...options, addToCachedItems: true } : options,
  );
};

export default useProductsPerGroup;
