import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, TextField } from '@material-ui/core';

import { SaveButton } from 'components/UIElements/UIButton';
import CloseButton from 'components/CustomButtons/CloseButton';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import {
  commitTempSettings,
  dropTempSettings,
  saveTempSetting,
} from 'actions/configs';
import { getProductNoteButtonsConfigs } from 'reducers/configs/settings';

import NoteButtonsComponent from '../../../ProductOrderView/components/Notes/NoteButtonsComponent';

const settingsKeys = {
  notes: 'pos_product_quick_notes',
  previewCount: 'pos_product_quick_notes_preview_count',
} as const;

const QuickNotesConfiguration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingsNoteButtons');

  const [newNote, setNewNote] = useState('');
  const [error, setError] = useState('');
  const [expanded, setExpanded] = useState(false);

  const { notes, previewCount } = useSelector(getProductNoteButtonsConfigs);

  const addNewNote = () => {
    if (notes.includes(newNote.trim())) {
      setError(t('errors.noteExists'));
    } else {
      dispatch(
        saveTempSetting(settingsKeys.notes, notes.concat(newNote.trim())),
      );
      setNewNote('');
    }
  };

  const removeNote = note => {
    const after = notes.filter(n => n !== note);
    dispatch(saveTempSetting(settingsKeys.notes, after));
  };

  const deleteAllNotes = () =>
    dispatch(saveTempSetting(settingsKeys.notes, ''));


  const onClose = () => {
    dispatch(dropTempSettings(...Object.values(settingsKeys)));
    dispatch(previousModalPage());
  };

  const onSave = () => {
    dispatch(commitTempSettings(...Object.values(settingsKeys)));
    dispatch(previousModalPage());
  };

  return (
    <div className="erply-settings">
      <Modal.Header>
        <span>{t('title')}</span>
        <span style={{ flexGrow: 1 }} />
        <SaveButton action={onSave} variant="POS" />
        <CloseButton action={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              value={previewCount}
              name={settingsKeys.previewCount}
              onChange={e =>
                dispatch(saveTempSetting(settingsKeys.previewCount, Number(e.target.value)))
              }
              inputProps={{ type: 'number' }}
              label={t('inputLabels.numberOfNotes')}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              multiline
              rows={2}
              value={newNote}
              onChange={e => {
                setError('');
                setNewNote(e.target.value);
              }}
              label={t('inputLabels.newNote')}
              error={Boolean(error)}
              helperText={error}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disableElevation
              style={{ width: '100%' }}
              onClick={addNewNote}
              disabled={Boolean(!newNote.trim().length)}
            >
              {t('actions.addNew')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              style={{ width: '100%' }}
              onClick={deleteAllNotes}
              disabled={!notes.length}
            >
              {t('actions.deleteAll')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <NoteButtonsComponent
              currentNote=""
              onClick={note => removeNote(note)}
              notes={notes}
              previewCount={expanded ? notes.length : previewCount}
              onExpandClick={() => setExpanded(true)}
            />
          </Grid>
          {expanded && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                style={{ width: '100%' }}
                onClick={() => setExpanded(false)}
              >
                {t('actions.shrink')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Modal.Body>
    </div>
  );
};

export default QuickNotesConfiguration;
