import { PosPlugin } from 'plugins/plugin';

import CFRReducer, { setIsOnlineReturn } from './rdx';
import { meta } from './meta';
import {
  lockPaymentsOnOnlineReturn,
  setSalesDocumentAttributes,
  confirmIdentity,
  forbidNewProductsOnOnlineReturn,
  modifyReturnDocuments,
} from './hooks';
import {
  getFunctionButtons,
  getIsPaymentDisabled,
  getSaleOptionButtons,
} from './overrides';
import {
  UICustomReturnCTA,
  UIExchangeModalTitle,
  ComponentHeader,
  UIProductReturnCloseButton,
  UIReturnOrderProduct,
  UIOrderActionSelection,
  UIReturnTableExtraData,
} from './components';
import setCurrentSalesDocPayments from './hooks/setCurrentSalesDocPayments';

const CrossFranchiseReturn: PosPlugin = {
  ...meta,
  getStatus: () => {
    return {
      type: 'warning',
      message:
        'Status will be validated before performing a return transaction',
    };
  },
  reduxReducer: CFRReducer as PosPlugin['reduxReducer'],
  onFetchSalesDocsForReturn: {
    on: modifyReturnDocuments,
  },
  onAddProduct: {
    on: forbidNewProductsOnOnlineReturn,
  },
  selectorOverrides: {
    getIsPaymentDisabled,
    getSaleOptionButtons,
    getFunctionButtons,
  },
  onOpenPaymentModal: {
    on: lockPaymentsOnOnlineReturn,
  },
  onSaveSalesDocument: {
    on: setSalesDocumentAttributes,
    after: () => async dispatch => {
      dispatch(setIsOnlineReturn(false));
    },
  },
  onStartNewSale: {
    after: () => async dispatch => {
      dispatch(setIsOnlineReturn(false));
    },
  },
  onPickupOrder: {
    before: confirmIdentity,
  },
  onSetCurrentSalesDocPayments: {
    on: setCurrentSalesDocPayments,
  },
  UICustomReturnCTA,
  UIExchangeModalTitle,
  ComponentHeader,
  UIProductReturnCloseButton,
  UIReturnOrderProduct,
  UIOrderActionSelection,
  UIReturnTableExtraData,
};

export default CrossFranchiseReturn;
