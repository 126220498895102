import { batch } from 'react-redux';
import i18next from 'i18next';

import { addWarning, addError, dismissAll } from 'actions/Error';
import { openModalPage } from './ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { getSelectedPos } from 'reducers/PointsOfSale';
import * as api from 'services/ErplyAPI/clockInOut';
import {
  RESET_LAST_CLOCK_IN,
  SET_LAST_CLOCK_IN,
  START_LOADING,
  STOP_LOADING,
} from 'constants/employees';
import { getLoggedInEmployeeID } from 'reducers/Login';

/** Fetch clocked-in employees and then open the clockinout view */
export function fetchClockedInEmployees() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      addWarning(i18next.t('alerts:loading.clockedInEmployees'), {
        dismissible: false,
      }),
    );
    const requestParams = {
      warehouseIDs: getSelectedPos(state).warehouseID,
    };
    try {
      const clockedInEmployees = await api.clockedInEmployees(requestParams);
      batch(() => {
        dispatch(
          openModalPage({
            component: modalPages.clockIn,
            props: { clockedInEmployees },
            groupID: modalPages.clockIn,
            replace: true,
          }),
        );
        dispatch(dismissAll());
      });
    } catch (err) {
      batch(() => {
        dispatch(dismissAll());
        dispatch(
          addError(i18next.t('alerts:loading.clockedInEmployees_error'), {
            selfDismiss: true,
          }),
        );
      });
    }
  };
}

export function setLastClockIn(time) {
  return {
    type: SET_LAST_CLOCK_IN,
    payload: time,
  };
}

export function resetLastClockIn() {
  return {
    type: RESET_LAST_CLOCK_IN,
    payload: [],
  };
}

export function lastClockInTime() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING });
      const employees = await api.clockedInEmployees({
        warehouseIDs: getSelectedPos(getState()).warehouseID,
      });
      const lastTime = employees.filter(
        employee =>
          employee.employeeID ===
          parseInt(getLoggedInEmployeeID(getState()), 10),
      );
      dispatch(setLastClockIn(lastTime));
    } catch (e) {
      // TODO: What to do here
      // TODO: Why is the console translated - previously a banner?
      console.error(
        i18next.t('alerts:loading.clockinTime', { context: 'error' }),
        e,
      );
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };
}
