import React, { useEffect, useContext, useMemo } from 'react';

import { usePosSendMessage } from 'utils/hooks/useWrapper';
import { Ctx } from 'containers/Forms/Settings/components/CtxInputs';
import { wrapperApiVersion } from 'external_data';
import { WrapperForm, useWrapperFields } from 'components/WrapperForm';

const WrapperPaymentConfiguration = () => {
  const superContext = useContext(Ctx);

  const setConfig = usePosSendMessage('config:setConfig');

  const valuesInErply = superContext.values.configuration;
  useEffect(() => {
    if (superContext.values.integration === 'wrapper') {
      superContext.values.setEnableUpdate(true);
    }
  }, [superContext.values.integration]);
  const onChange = (key, value) =>
    superContext.onChange('configuration', { ...valuesInErply, [key]: value });
  const setValue = (newValue) =>
    superContext.onChange('configuration', { ...valuesInErply, ...newValue })

  useEffect(() => setConfig(valuesInErply), [valuesInErply]);

  return (
    <>
      Wrapper websocket API version: {wrapperApiVersion}
      <hr />
      <WrapperForm
        value={valuesInErply}
        onChange={onChange}
        setValue={setValue}
        getMessage="config:getFields"
        setMessage="config:setFields"
        updateMessage="config:setConfig"
      />
    </>
  );
};
export default WrapperPaymentConfiguration;
