import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { PosPlugin } from 'plugins/plugin';
import { getSelectedWarehouseID } from 'reducers/warehouses';
import InputField from 'components/FieldTypes/InputField';
import {
  formInput,
  longTitle,
} from 'components/FieldTypes/skins/skins.module.scss';
import { Ctx, Text } from 'containers/Forms/Settings/components/CtxInputs';
import { getCustomers as fetchCustomers } from 'services/ErplyAPI/customers';
import { createConfirmation } from 'actions/Confirmation';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { openModalPage } from 'actions/ModalPage/openModalPage';
import { modalPages } from 'constants/modalPage';
import { getCustomerGroups } from 'reducers/customerSearch';
import UIButton from 'components/UIElements/UIButton';
import {
  getPluginConfiguration,
  getPluginConfigurationAtLevel,
} from 'reducers/Plugins';
import { commitTempSettings, saveTempSetting } from 'actions/configs';
import { getSetting } from 'reducers/configs/settings';
import { addWarning, dismissAll } from 'actions/Error';

type Configuration = { groupID?: string };
const pluginID = 'pnp-customNewCustomer';
const pluginName = '[PnP] Limited customer creation card';

const Configuration = ({
  current,
  save,
}: {
  current?: Configuration;
  save: (val: Configuration) => void;
}) => {
  const dispatch = useDispatch();
  const groups = useSelector(getCustomerGroups).map(g => ({
    name: g.name,
    value: g.customerGroupID,
  }));
  const [id, setID] = useState(current?.groupID ?? 'undefined');
  const setting = useSelector(getSetting('brazil_disable_create_company'));

  return (
    <>
      <UIButton
        text="save"
        action={() => {
          dispatch(commitTempSettings(['brazil_disable_create_company']));
          save({ groupID: id === 'undefined' ? undefined : id });
        }}
      />
      <InputField
        onChange={() =>
          dispatch(saveTempSetting('brazil_disable_create_company', !setting))
        }
        type="checkbox"
        value={setting}
      >
        POS setting brazil_disable_create_company (this should be checked)
      </InputField>
      <InputField
        type="select"
        title="Default group for new customers"
        options={[{ name: '-- inherit --', value: 'undefined' }].concat(groups)}
        value={id}
        onChange={e => setID(e.target.value)}
      />
    </>
  );
};

const customNewCustomerPlugin: PosPlugin<
  Configuration | {},
  Configuration,
  Configuration,
  Configuration,
  null
> = {
  id: pluginID,
  name: pluginName,

  getStatus: state => {
    const config = getPluginConfiguration<Configuration>(pluginID)(state);
    const companyConf = getPluginConfigurationAtLevel<Configuration>(
      pluginID,
      'Company',
      '',
    )(state);
    if (!config?.groupID) {
      return {
        type: 'warning',
        message: 'Default customer group not configured',
      };
    }
    if (!companyConf?.groupID) {
      return {
        type: 'warning',
        message:
          'Default customer group configured for this POS/warehouse, but no default set at the company level',
      };
    }
    return { type: 'valid', message: 'OK' };
  },
  ComponentConfigurationByLevel: {
    Company: Configuration,
    Warehouse: Configuration,
    Pos: Configuration,
  },
  combineConfiguration: (c = {}, w = {}, p = {}, u) => {
    return {
      ...c,
      ...w,
      ...p,
    };
  },
  /**
   * If creating a new customer, check first for duplicate emails and offer a popup to cancel and select existing customer instead
   */
  onSaveCustomer: {
    on: (p, ap) => async (dispatch, getState) => {
      // If editing a customer instead of creating, always allow
      if (ap.params.id) return ap;

      // Else check for duplicates
      // Customer is now an array
      // If email is an empty string, resolve the promise with [] else send a request
      const [
        duplicateEmailCustomers,
        duplicateCodeCustomers,
      ] = await Promise.all([
        p.email.trim().length === 0
          ? Promise.resolve([])
          : fetchCustomers({ searchEmail: p.email }),
        fetchCustomers({ searchRegistryCode: p.code }),
      ]);
      // case where both email and code are duplicates
      if (duplicateEmailCustomers.length && duplicateCodeCustomers.length) {
        await dispatch(dismissAll());
        if (
          // case where duplicate customer is the same
          duplicateEmailCustomers[0].customerID ===
          duplicateCodeCustomers[0].customerID
        ) {
          await new Promise((resolve, reject) =>
            [
              addWarning('Customer with this code already exists', {
                selfDismiss: true,
              }),
              createConfirmation(resolve, reject, {
                title: 'Customer duplicate',
                body:
                  'A customer with this data already exists, do you want to go to that customer instead?',
                confirmText: [
                  {
                    name: 'Open customer with same data',
                    value: duplicateCodeCustomers[0],
                  },
                ],
                cancelText: 'Back to editing',
              }),
            ].map(dispatch),
          ).then(
            // Open existing customer
            async () => {
              await dispatch(setCustomer({ data: duplicateEmailCustomers[0] }));
              await dispatch(previousModalPage());
              await dispatch(
                openModalPage({ component: modalPages.createCustomer }),
              );
              throw new Error('Opening existing customer');
            },
            // Return back to customer editing menu
            () => {
              throw new Error('Customer with this code already exists');
            },
          );
        } else {
          // case where customers aren't the same
          await new Promise((resolve, reject) =>
            [
              addWarning('Customer with this code already exists', {
                selfDismiss: true,
              }),
              createConfirmation(resolve, reject, {
                title: 'Duplicate customer',
                body: 'Customers with these email and code already exist',
                confirmText: [
                  {
                    name: 'Open customer with duplicate email',
                    value: duplicateEmailCustomers[0].customerID,
                  },
                  {
                    name: 'Open customer with duplicate code',
                    value: duplicateCodeCustomers[0].customerID,
                  },
                ],
                cancelText: 'Back to editing',
              }),
            ].map(dispatch),
          ).then(
            // Open existing customer
            async ({ submit: id }: any) => {
              await dispatch(setCustomer({ data: id }));
              await dispatch(previousModalPage());
              await dispatch(
                openModalPage({ component: modalPages.createCustomer }),
              );
              throw new Error('Opening existing customer');
            },
            // Return back to editing menu
            async () => {
              throw new Error('Customer with this code already exists');
            },
          );
        }
      }
      // case where only email is duplicate
      else if (duplicateEmailCustomers.length) {
        await new Promise((resolve, reject) =>
          dispatch(
            createConfirmation(resolve, reject, {
              title: 'Duplicate customer',
              body:
                'A customer with this email already exists, do you want to go to that customer instead?',
              confirmText: 'Open customer with existing email',
              cancelText: 'Create new duplicate customer',
            }),
          ),
        ).then(
          // Open existing customer
          async () => {
            await dispatch(setCustomer({ data: duplicateEmailCustomers[0] }));
            await dispatch(previousModalPage());
            await dispatch(
              openModalPage({ component: modalPages.createCustomer }),
            );
            throw new Error('Opening existing customer');
          },
          // Create new duplicate customer (default behaviour)
          () => undefined,
        );
      }
      // case where only code is duplicate
      else if (duplicateCodeCustomers.length) {
        await dispatch(dismissAll());
        await new Promise((resolve, reject) =>
          [
            addWarning('Customer with this code already exists', {
              selfDismiss: true,
            }),
            createConfirmation(resolve, reject, {
              title: 'Duplicate customer',
              body: 'A customer with this code already exists',
              confirmText: 'Open customer with existing code',
              cancelText: 'Back to editing',
            }),
          ].map(dispatch),
        ).then(
          // Open existing customer
          async () => {
            await dispatch(setCustomer({ data: duplicateCodeCustomers[0] }));
            await dispatch(previousModalPage());
            await dispatch(
              openModalPage({ component: modalPages.createCustomer }),
            );
            throw new Error('Opening existing customer');
          },
          () => {
            // Return back to editing menu
            throw new Error('Customer with this code already exists');
          },
        );
      }
      // Default behaviour if: Not a duplicate / User selected to create new duplicate
      return {
        ...ap,
        params: { ...ap.params, phone: ap.params.phone.replace(/-/g, '') },
      };
    },
  },
  UICustomerForm: ({ value, onChange }) => {
    const { groupID } = useSelector(
      getPluginConfiguration<Configuration>(pluginID),
    );
    const warehouseID = useSelector(getSelectedWarehouseID);
    const phoneIsValid =
      /^(\d{3})-(\d{3})-(\d{4})$/.test(value.phone) && value.phone.length < 13;
    const codeIsValid = /^LOY-.+$/g.test(value.code);
    // allow empty email, but check email for validity as soon as the user enters something
    const emailIsValid =
      value.email.length === 0 ||
      /[^@]+@[^@.]+\.[^.@]+/.test(value.email.toLowerCase());
    const newValid = phoneIsValid && codeIsValid && emailIsValid;

    const currentValid = !value.invalid;
    useEffect(() => {
      if (newValid !== currentValid) {
        onChange(v => ({ ...v, invalid: !newValid }));
      }
    }, [newValid, currentValid]);
    useEffect(() => {
      onChange(v => ({ ...v, groupID }));
    }, [groupID, value.groupID]);
    useEffect(() => {
      onChange(v => ({ ...v, emailOptOut: false }));
    }, [value.emailOptOut]);
    useEffect(() => {
      if (value.phone.length > 12) {
        onChange(v => ({ ...v, phone: value.phone.substring(0, 12) }));
      }
    }, [value.phone]);

    useEffect(() => {
      if (!value.homeStoreID) {
        onChange(v => ({ ...v, homeStoreID: warehouseID }));
      }
    }, [value, warehouseID]);

    useEffect(() => {
      if (!value.signUpStoreID) {
        onChange(v => ({ ...v, signUpStoreID: warehouseID }));
      }
    }, [value, warehouseID]);

    const phs = [
      'John',
      'Smith',
      'LOY-1234567890',
      'JohnSmith@gmail.com',
      '123-456-7890',
    ];

    return (
      <Ctx.Provider
        value={{
          values: value,
          onChange: (k, v) => onChange(c => ({ ...c, [k]: v })),
          sharedProps: {
            className: classNames(formInput, longTitle),
            prependTitle: true,
          },
        }}
      >
        {!groupID && (
          <span style={{ color: 'salmon' }}>
            Default group not configured in Plugins → {pluginName}
            <br />
            New customer will be created in the default customer group.
          </span>
        )}
        <Text
          data-testid="first-name"
          title="First name"
          name="firstName"
          placeholder={phs[0]}
        />
        <Text
          data-testid="last-name"
          title="Last name"
          name="lastName"
          placeholder={phs[1]}
        />
        <Text
          title="Customer number"
          name="code"
          autoFocus
          placeholder={phs[2]}
          isInvalid={!codeIsValid}
          data-testid="customer-number"
        />
        <Text
          title="E-mail"
          name="email"
          isInvalid={!emailIsValid}
          placeholder={phs[3]}
          data-testid="email"
        />
        <Text
          title="Phone"
          name="phone"
          formatter={(newVal, oldVal) => {
            if (newVal.replace('-', '').length === 10) {
              newVal = newVal.replace(
                /^(\d{3})\-?(\d{3})\-?(\d{4})$/,
                '$1-$2-$3',
              );
            }
            newVal = `${newVal}`.replace(/[^0-9-]/, '').slice(0, 12);
            if (
              (/^(\d{3})$/.test(newVal) && /^(\d{2})$/.test(oldVal)) ||
              (/^(\d{3}-\d{3})$/.test(newVal) && /^(\d{3}-\d{2})$/.test(oldVal))
            ) {
              return `${newVal}-`;
            }
            if (
              (/^((\d){3}-\d{3})$/.test(newVal) &&
                /^(\d{3}-\d{3}-)$/.test(oldVal)) ||
              (/^((\d){3})$/.test(newVal) && /^(\d{3}-)$/.test(oldVal))
            ) {
              return newVal.slice(0, -1);
            }
            return newVal;
          }}
          placeholder={phs[4]}
          isInvalid={!phoneIsValid}
          data-testid="phone"
        />
        <br />
      </Ctx.Provider>
    );
  },
};
export default customNewCustomerPlugin;
