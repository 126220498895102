import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';

import { SingleTenderConfiguration, Tender } from './types';

type TenderName = keyof SingleTenderConfiguration;
type Config = SingleTenderConfiguration[TenderName];
type FieldName = keyof Config;
type FieldValue = Config[FieldName];

interface Props {
  translationPrefix: string;
  tenders: Tender[];
  configuration: SingleTenderConfiguration;
  onChange: (tender: TenderName, field: FieldName, value: FieldValue) => void;
  disabled?: boolean;
}

function TenderConfig({
  translationPrefix,
  tenders,
  configuration,
  onChange,
  disabled,
}: Props) {
  const { t } = useTranslation('settingsPayment');
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t(`common.type`)}</TableCell>
          <TableCell style={{ width: '150px' }}>{t(`common.limit`)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tenders.map(({ transKey, configName }) => {
          const config = configuration[configName];
          return (
            <TableRow key={transKey}>
              <TableCell style={{ verticalAlign: 'inherit' }}>
                <FormControlLabel
                  label={t(`${translationPrefix}.tenders.${transKey}`)}
                  control={
                    <Checkbox
                      checked={config.allowed}
                      onChange={e =>
                        onChange(configName, 'allowed', e.target.checked)
                      }
                      disabled={disabled}
                    />
                  }
                  data-testid="ctxinput-checkbox"
                  data-test-key={`pos_allow_${translationPrefix}_${configName}`}
                />
              </TableCell>
              <TableCell colSpan={2}>
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  value={config.limit}
                  inputProps={{
                    'data-testid': 'ctxinput-text',
                    'data-test-key': `pos_allow_${translationPrefix}_${configName}_limit`,
                  }}
                  onChange={e => onChange(configName, 'limit', e.target.value)}
                  disabled={disabled || !config.allowed}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default TenderConfig;
