import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { Grid, styled, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { FormCustomer } from 'types/Customer';
import { PluginComponent } from 'plugins';
import { getCustomerTableFields } from 'reducers/cafaConfigs';

import { useCustomerFieldMapping } from './useCustomerFieldMapping';

type Item = {
  label?: string;
  required: boolean;
  enabled: boolean;
  order: {
    x: number;
    y: number;
  };
};

const DataRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.5rem 0',
  borderBottom: `1px solid ${theme.palette.divider}`,

  '& :first-child': {
    fontWeight: 'bold',
    marginRight: '14px',
  },
  '& :last-child': {
    textAlign: 'end',
  },
}));

function useSplitFieldsIntoColumns(customer: FormCustomer) {
  const { customerType } = customer;
  const fields = useSelector(getCustomerTableFields)[customerType];

  const sortedFields = Object.fromEntries(
    Object.entries(fields).sort(([, a], [, b]) => a.order - b.order),
  );

  const fieldsToShow = Object.entries<Item>(
    R.pipe(
      R.mapObjIndexed((val, key, obj) => {
        const keys = Object.keys(obj);
        const index = keys.indexOf(key);
        const isOdd = index % 2;
        const isFirstColumn = !isOdd;
        const y = isFirstColumn ? index : Math.floor(index - keys.length / 2);
        return R.pipe(R.assoc('order', { x: isFirstColumn ? 0 : 1, y }))(val);
      }),
    )(sortedFields),
  );

  const sortByOrder = (fields: [string, Item][]) =>
    fields.sort(([, { order: orderA }], [, { order: orderB }]) =>
      orderA.y > orderB.y ? 1 : -1,
    );

  const leftFields = sortByOrder(fieldsToShow).filter(
    ([, value]) => value.order.x === 0,
  );

  const rightFields = sortByOrder(fieldsToShow).filter(
    ([, value]) => value.order.x === 1,
  );

  return {
    leftFields,
    rightFields,
  };
}

interface FieldRowProps {
  fieldName: string;
  label?: string;
  getFieldLabel(fieldName: string): string;
}

function FieldRow({ fieldName, label, getFieldLabel }: FieldRowProps) {
  const { t } = useTranslation(['createCustomer', 'customer']);

  return (
    <DataRow key={fieldName}>
      <Typography style={{ fontWeight: 'bold' }} key={`${fieldName}-label`}>
        {t(`fields.${fieldName}`, '', { ns: 'createCustomer' }) ||
          t(`customerView.fields.${fieldName}`, label ?? '', {
            ns: 'customer',
          })}
      </Typography>
      <Typography key={`${fieldName}-value`}>
        {getFieldLabel(fieldName)}
      </Typography>
    </DataRow>
  );
}

interface CustomerTableBetaProps {
  customer: FormCustomer;
}

function CustomerTableBeta({ customer }: CustomerTableBetaProps) {
  const { leftFields, rightFields } = useSplitFieldsIntoColumns(customer);
  const getData = useCustomerFieldMapping(customer);

  return (
    <PluginComponent hookname="UICustomerTableBeta" props={{ customer }}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          {leftFields.map(([fieldName, { label }]) => (
            <FieldRow
              fieldName={fieldName}
              label={label}
              getFieldLabel={getData}
            />
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          {rightFields.map(([fieldName, { label }]) => (
            <FieldRow
              fieldName={fieldName}
              label={label}
              getFieldLabel={getData}
            />
          ))}
        </Grid>
      </Grid>
    </PluginComponent>
  );
}

export default CustomerTableBeta;
