/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
import { batch } from 'react-redux';
import i18next from 'i18next';
import * as R from 'ramda';

import * as shoppingCartActionTypes from 'constants/ShoppingCart';
import { modalPages as modals } from 'constants/modalPage';
import {
  checkAvailability,
  checkStockFarFromMinimum,
  getHasProductDiscountReasonByIndex,
  getHasProductReturnReasonByIndex,
  getHasProductsInShoppingCart,
  getIsProductNonDiscountable,
  getProductInOrderByIndex,
  getProductsInShoppingCart,
  getReturnReasonByOrderIndex,
  getTotalCountInCart,
} from 'reducers/ShoppingCart';
import { getSelectedCustomerID } from 'reducers/customerSearch';
import { getProductByID } from 'reducers/cachedItems/products';
import {
  getAllowEditingExistingLayaways,
  getCustomPromptsForProduct,
  getSetting,
  getShowPricesWithTax,
  getSerialNumberTitle,
  getPriceEditingAllowedForExchangesAndReturns,
  getDiscountLimit,
} from 'reducers/configs/settings';
import {
  getCurrentSalesDocument,
  getHasCurrentSalesDocument,
  getIsAReturn,
  getIsCurrentSaleAReturn,
} from 'reducers/sales';
import {
  setCurrentSalesDocPayments,
  setCurrentSalesDocument,
  setIsCurrentSaleAReturn,
  startNewSale,
  setUsedRewardPoints,
  setPickupInProgress,
  setIsCurrentSaleGiftReturn,
} from 'actions/sales';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';
import { getPluginLifecycleHook } from 'reducers/Plugins';
import { REASONS } from 'constants/reasonCodesDB';
import { getReasonCodes } from 'reducers/reasonCodesDB';
import { openManagerOverride } from 'containers/Forms/ManagerOverride/actions';
import { openModalPage } from 'actions/ModalPage/openModalPage';

import { addError, addWarning } from './Error';
import { createConfirmation } from './Confirmation';
import { getRightsForUserID } from './Login';
import { getProductsUniversal, resetProductCache } from './productsDB';
import { calculate, recalculateAfter } from './ShoppingCart/calculate';
import { checkForLimitAmountPerSale } from './Sales/checkForLimitAmountPerSale';

export function addMultiProductsSuccess(products) {
  return {
    type: shoppingCartActionTypes.ADD_MULTI_PRODUCTS_SUCCESS,
    payload: products,
  };
}

export function addMultiProductsFailure(error) {
  return {
    type: shoppingCartActionTypes.ADD_MULTI_PRODUCTS_FAILURE,
    payload: error,
  };
}

// Order of execution in this action has a purposes, please be cautions if refactoring
/**
 * @param orderIndex - products orderIndex in the salesDoc
 * @param params.showWarning - set to false if you have some warning in case document gets cleared, otherwise true or don't send
 */

export function removeProduct(orderIndex, params = { showWarning: true }) {
  return async (dispatch, getState) => {
    if (!orderIndex) {
      console.error('removeProductAction called without orderIndex');
      return;
    }
    const { before, on, after } = getPluginLifecycleHook('onRemoveOrder')(
      getState(),
    );
    await dispatch(before({ orderIndex }));

    const state = getState();
    const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);
    const hasCurrentSalesDocument = getHasCurrentSalesDocument(state);
    const shouldRemoveGroupedProducts = getSetting(
      'touchpos_remove_grouped_products_in_shopping_cart',
    )(state);
    const products = getProductsInShoppingCart(state);

    const discountReason = getHasProductDiscountReasonByIndex(orderIndex)(
      getState(),
    );
    const returnReason = getHasProductReturnReasonByIndex(orderIndex)(
      getState(),
    );

    if (discountReason) dispatch(clearDiscountReason(orderIndex));

    if (returnReason) dispatch(clearReturnReason(orderIndex));

    const canEditProductsInLayways = getAllowEditingExistingLayaways(state);
    const currentSalesDocument = getCurrentSalesDocument(state);
    const { type, invoiceState } = currentSalesDocument;
    const isLayaway = type === 'PREPAYMENT' && invoiceState === 'READY';
    const isInvoice = type === 'INVOICE' && invoiceState === 'READY';

    if (!canEditProductsInLayways && isLayaway) {
      return dispatch(addWarning(i18next.t('alerts:layawayCartEditLocked')));
    }

    if (isInvoice) {
      return dispatch(addWarning(i18next.t('alerts:invoiceCartEditLocked')));
    }

    if (shouldRemoveGroupedProducts) {
      await Promise.all(
        products
          .filter(pr => String(pr.parentRowID) === String(orderIndex)) // orderIndex can be string or number
          .map(({ orderIndex }) => dispatch(removeProduct(orderIndex))),
      ).catch(e => console.error('Failed to remove products', products, e));
    }

    // New getState to exclude auto-removed products from ↑
    const remainingProducts = products.filter(p => p.orderIndex !== orderIndex);
    const noNegativeProducts = remainingProducts.every(
      p => Number(p.amount) > 0,
    );
    const noProducts = remainingProducts.length === 0;
    try {
      await dispatch(on({ orderIndex }));
    } catch (e) {
      return;
    }

    // Prompt a confirmation on removing last product in cart
    if (
      orderIndex &&
      ((noNegativeProducts && isCurrentSaleAReturn) ||
        (noProducts && hasCurrentSalesDocument))
    ) {
      if (params.showWarning) {
        dispatch(addWarning(i18next.t('alerts:clearSale')));
      }
      dispatch(startNewSale());
    }
    batch(() => {
      dispatch({
        type: shoppingCartActionTypes.REMOVE_PRODUCT,
        payload: orderIndex,
      });
      dispatch(calculate());
    });
    // Plugin hook
    dispatch(after({ orderIndex }));
  };
}

export function increaseOrderAmount(orderIndex) {
  return async (dispatch, getState) => {
    const { amount } = getProductInOrderByIndex(orderIndex)(getState());
    await dispatch(updateOrderAmount(Number(amount) + 1 || 1, orderIndex));
  };
}

export function decreaseOrderAmount(orderIndex) {
  return async (dispatch, getState) => {
    const { amount } = getProductInOrderByIndex(orderIndex)(getState());
    await dispatch(updateOrderAmount(Number(amount) - 1 || -1, orderIndex));
  };
}

export function updateOrderAmount(
  amount,
  orderIndex,
  shouldCalculateAfter = true,
) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      await i18next.loadNamespaces('alerts');

      const shoppingCartItems = getProductsInShoppingCart(state);
      const currentProduct = shoppingCartItems.find(
        product => product.orderIndex === orderIndex,
      );
      const returnReasonCodes = getReasonCodes(REASONS.RETURN)(state);

      const {
        products: [selectedProduct],
      } = await dispatch(
        getProductsUniversal({ productID: currentProduct.productID, getLocalStockInfo: 1 }),
      );

      const shouldAddProduct = await dispatch(
        checkForLimitAmountPerSale(
          selectedProduct,
          shoppingCartItems,
          amount,
          orderIndex,
        ),
      );

      if (!shouldAddProduct) {
        throw new Error('Product not added to cart due to limit per sale');
      }

      const { before, on, after } = getPluginLifecycleHook(
        'onUpdateOrderAmount',
      )(state);
      try {
        await dispatch(before({ amount, orderIndex }));
      } catch (e) {
        return undefined; // No error if cancelled by plugin
      }
      const order = getProductInOrderByIndex(orderIndex)(state);
      const returnReason = getReturnReasonByOrderIndex(order.orderIndex)(state);
      const isCurrentSaleAReturn = getIsCurrentSaleAReturn(state);

      const { productID, amount: currentAmount } = order;
      const totalInCart = getTotalCountInCart(productID)(state);
      const originalProduct = getProductByID(productID)(state);
      // Prevent setting negative amount to non-refundable product
      if (originalProduct.nonRefundable && Number(amount) < 0) {
        return dispatch(
          addWarning(
            i18next.t('alerts:nonRefundableProductError', {
              name: originalProduct.name,
            }),
          ),
        );
      }
      const canEditProductsInLayways = getAllowEditingExistingLayaways(state);
      const currentSalesDocument = getCurrentSalesDocument(state);
      const {
        type,
        invoiceState,
        fulfillableRows = [],
        id,
      } = currentSalesDocument;
      const isPickedUpOrder =
        type === 'ORDER' && invoiceState === 'READY' && id;
      const isLayaway = type === 'PREPAYMENT' && invoiceState === 'READY';
      const isInvoice = type === 'INVOICE' && invoiceState === 'READY';

      if (!canEditProductsInLayways && isLayaway) {
        return dispatch(
          addWarning(i18next.t('shoppingCart:alerts.layawayCartEditLocked')),
        );
      }

      if (isInvoice) {
        return dispatch(addWarning(i18next.t('alerts:invoiceCartEditLocked')));
      }

      if (isPickedUpOrder) {
        const fulfillableRow = fulfillableRows.find(
          fr => fr.orderIndex === orderIndex,
        );
        if (!fulfillableRow || Number(amount) > Number(fulfillableRow.amount)) {
          return dispatch(
            addWarning(
              i18next.t('alerts:orderCartEditLocked', { context: 'increase' }),
            ),
          );
        }
      }

      if (getIsCurrentSaleAReturn(getState())) {
        if (Number(currentAmount) < 0) {
          return dispatch(
            addWarning(
              i18next.t('shoppingCart:alerts.referencedReturnRowsLocked'),
            ),
          );
        }
        if (Number(amount) < 0) {
          return dispatch(
            addWarning(
              i18next.t('shoppingCart:alerts.referencedReturnRowsExclusive'),
            ),
          );
        }
      }

      // Only trigger override if triggering return (from positive to negative)
      if (Number(amount) < 0 && Number(currentAmount) > 0) {
        await i18next.loadNamespaces('managerOverride');
        await dispatch(
          openManagerOverride([
            {
              key: 'returnWithoutReceipt',
              text: i18next.t('managerOverride:reasons.returnWithoutReceipt'),
              isPermitted: user =>
                getRightsForUserID(Number(user.userID)).then(
                  ({ rightMakePOSReturnsWithoutReceipt }) =>
                    !!Number(rightMakePOSReturnsWithoutReceipt),
                ),
            },
          ]),
        );
      }

      if (
        Number(amount) < 0 &&
        returnReasonCodes.length > 0 &&
        !returnReason &&
        !isCurrentSaleAReturn
      ) {
        await dispatch(
          openModalPage({
            component: modals.ProductReturnReasons,
            isPopup: true,
            props: {
              reasons: returnReasonCodes,
              order,
            },
          }),
        );
      }

      const pluginOverride = await dispatch(
        on({ amount, orderIndex }, { amount, orderIndex }),
      );

      // changing product's amount so that the store needs to give it out
      if (
        // return changed to ordinary sale
        (Number(currentAmount) < 0 && Number(amount) > 0) ||
        // ordinary sale
        Number(amount) > 0
      ) {
        if (!checkAvailability(productID, amount, orderIndex)(getState())) {
          const warningType = getSetting('touchpos_out_of_stock_warning')(
            getState(),
          );
          const translate = (subKey, ...rest) =>
            i18next.t(
              `shoppingCart:alerts.productOutOfStock.${subKey}`,
              ...rest,
            );
          const transData = {
            /** Currect actual stock (including products already in the cart but not sold yet) */
            stock: selectedProduct.free,
            /** The amount of this product that would be in the shopping cart if we continue */
            inCartAfter:
              totalInCart - Number(currentAmount) + Number(amount || 1),
            /**
             * The name of the product which stock is checked
             * NB: Not the overwritten itemName of this row, since stock is calculated for the product card
             */
            productName: selectedProduct.name,
          };
          switch (warningType) {
            case 'block':
              dispatch(addError(translate('block', transData)));
              return undefined;
            case 'confirmation':
              await new Promise((resolve, reject) =>
                dispatch(
                  createConfirmation(resolve, reject, {
                    title: translate('confirmation.title', transData),
                    body: translate('confirmation.body', transData),
                  }),
                ),
              );
              break;
            case 'warning':
              dispatch(
                addWarning(translate('warning', transData), {
                  selfDismiss: 3000,
                }),
              );
              break;
            default:
              // off
              break;
          }
        }

        const farFromMinimum = checkStockFarFromMinimum(
          productID,
          amount,
        )(getState());

        if (!farFromMinimum) {
          dispatch(
            createConfirmation(
              () => {
                // do nothing
              },
              null,
              {
                title: i18next.t(
                  `shoppingCart:alerts.productCloseToOutOfStock.title`,
                ),
                body: i18next.t(
                  `shoppingCart:alerts.productCloseToOutOfStock.body`,
                ),
              },
            ),
          );
        }
      }

      dispatch({
        type: shoppingCartActionTypes.UPDATE_ORDER_AMOUNT,
        payload: {
          amount: pluginOverride?.amount ?? amount,
          orderIndex: pluginOverride?.orderIndex ?? orderIndex,
        },
      });
      await dispatch(after({ amount, orderIndex }, pluginOverride));
      if (shouldCalculateAfter) return dispatch(calculate());
    } catch (err) {
      console.error('Failed to update order amount', err);
      return dispatch(
        addError(i18next.t('shoppingCart:alerts.updateAmountFailed')),
      );
    }
  };
}

export function updateOrderDiscount(
  manualDiscount,
  orderIndex,
  shouldCalculateAfter = true,
) {
  return async (dispatch, getState) => {
    const { rows = [] } = getCurrentSalesDocument(getState());
    const isReturn = getIsAReturn(getState());
    const isReferencedReturn = isReturn && rows.length > 0;
    const allowOvercharge = getSetting('brazil_allow_overcharge')(getState());
    const lowerLimit = allowOvercharge ? Number.NEGATIVE_INFINITY : 0;
    const allowPriceEditingForExchangesAndReferencedReturns = getPriceEditingAllowedForExchangesAndReturns(
      getState(),
    );

    const payload =
      isReferencedReturn && !allowPriceEditingForExchangesAndReferencedReturns
        ? {}
        : {
            manualDiscount: Math.max(manualDiscount, lowerLimit),
            orderIndex,
          };

    dispatch({
      type: shoppingCartActionTypes.UPDATE_ORDER_DISCOUNT,
      payload,
    });
    if (shouldCalculateAfter) return dispatch(calculate());
  };
}

export function updateProductNotes(notes, orderIndex) {
  return recalculateAfter({
    type: shoppingCartActionTypes.UPDATE_PRODUCT_NOTES,
    payload: { notes, orderIndex },
  });
}

export function updateOrderPrice(price, priceVAT, orderIndex) {
  return recalculateAfter({
    type: shoppingCartActionTypes.UPDATE_ORDER_PRICE,
    payload: { price, priceVAT, orderIndex },
  });
}

export function clearOrderPrice(orderIndex) {
  return recalculateAfter({
    type: shoppingCartActionTypes.CLEAR_ORDER_PRICE,
    payload: { orderIndex },
  });
}

/**
 * Override adding product container(deposit fee product)
 *
 * @param orderIndex {number|string} - order index of the product int he shopping cart
 * @param shouldNotHaveContainer {boolean | undefined} - should the container be added to shopping cart
 * @returns {{payload: {orderIndex: *}, type: *}}
 */
export function updateProductContainerOverride(
  orderIndex,
  shouldHaveContainer,
) {
  return recalculateAfter({
    type: shoppingCartActionTypes.UPDATE_NO_PRODUCT_CONTAINER,
    payload: { orderIndex, addContainerOverride: shouldHaveContainer },
  });
}

export function resetShoppingCart() {
  return async (dispatch, getState) => {
    const { before, on, after } = getPluginLifecycleHook('onResetShoppingCart')(
      getState(),
    );

    await dispatch(before());

    await dispatch(on());

    await dispatch(resetProductCache());
    dispatch({
      type: shoppingCartActionTypes.RESET_SHOPPING_CART,
    });

    await dispatch(after());
  };
}

export function setOrderEmployeeID(employeeID, orderIndex) {
  return recalculateAfter({
    type: shoppingCartActionTypes.SET_EMPLOYEE,
    payload: {
      orderIndex,
      employeeID,
    },
  });
}

export function addMultiProducts(orders = []) {
  return async (dispatch, getState) => {
    const { productsDict } = await dispatch(
      getProductsUniversal(
        { productIDs: orders.map(o => o.productID) },
        { addToCachedItems: true },
      ),
    );
    const reasonCodes = getReasonCodes(REASONS.DISCOUNT)(getState());

    const { before, on, after } = getPluginLifecycleHook('onAddMultiProducts')(
      getState(),
    );

    try {
      await dispatch(before(orders));
    } catch (e) {
      return;
    }

    await dispatch({
      type: shoppingCartActionTypes.ADD_MULTI_PRODUCTS_START,
    });

    const textFreeItems = [];

    // For initiation if the flag "Cashier Must Enter Price" is visible, on multiple products added from a saved sale
    const masterOrders = R.pipe(
      // Filter non-refundable products
      R.filter(order => {
        // Dealing with free-tex line products. These items have an ID value of 0. They will not be found in the productsDict henceforth they need to be "stored" in a separate constant to later be passed on.
        if (order.productID !== '0') {
          const { name, nonRefundable } = productsDict[order.productID];
          if (nonRefundable && order.amount < 0) {
            dispatch(
              addWarning(
                i18next.t('alerts:nonRefundableProductError', { name }),
              ),
            );
            return false;
          }
          return true;
        }
        textFreeItems.push(order);
      }),
      // Filter products which are container fees, unless they are return
      R.pipe(
        R.prepend({ amount: 0 }),
        R.aperture(2), // Take pairs of elements
        R.filter(([master, child]) => {
          const { containerID, containerAmount } = productsDict[
            master.productID
          ] ?? { amount: 0 };
          const { productID, amount } = child;

          const isContainer = R.equals(
            [containerID, containerAmount * master.amount].map(String),
            [productID, amount].map(String),
          );
          if (isContainer) {
            return master.addContainerOverride === false;
          }
          return true;
        }),
        R.map(([master, child]) => child),
      ),
      R.map(({ itemName, discount, ...o }) => ({
        manualDiscount: discount,
        discount,
        name: itemName,
        ...o,
      })),
      R.map(R.omit(['rowNetTotal', 'rowTotal', 'rowVAT'])),
    )(orders);

    // Serial number logic block ///
    const serialNumberPromiseMap = (inputValues, mapper) => {
      const reducer = (accumulator, inputValue) =>
        accumulator.then(async acc => {
          return mapper(inputValue).then(result => {
            return acc.push(result) && acc;
          });
        });

      return inputValues.reduce(reducer, Promise.resolve([]));
    };

    const setSerialNumber = async item => {
      const serialNumberTitle = getSerialNumberTitle(getState());
      // For products with custom serial(s), display serials input
      const customPrompts = getCustomPromptsForProduct(item.productID)(
        getState(),
      );
      if (customPrompts.length && !item.name.includes(serialNumberTitle)) {
        const customData = await dispatch(
          openModalPage({
            isPopup: true,
            component: modals.customPrompts,
            props: {
              productID: item.productID,
              prompts: customPrompts,
              productName: item.name,
            },
          }),
        );
        item.name = `${item.name} (${Object.entries(customData)
          .map(([k, v]) => `${k}: ${v}`)
          .join(', ')})`;
      }
    };
    // End of serial number logic block ///////
    try {
      await serialNumberPromiseMap(masterOrders, setSerialNumber);
      const finalFreeTextLineProducts = textFreeItems.map(item => ({
        ...item,
        name: (item.name?.trim() || item.itemName) ?? '',
      }));

      const params = await dispatch(
        on(orders, [...masterOrders, ...finalFreeTextLineProducts]),
      ).catch(() => {
        throw 'pluginerror';
      });
      await dispatch(addMultiProductsSuccess(params));
      params.forEach(param => {
        // set the discount reasons in redux for products which have a discount
        if (Number(param.amount) > 0 && param.returnReasonID) {
          const reason = reasonCodes.find(
            r => r.reasonID === Number(param.returnReasonID),
          );
          dispatch(setDiscountReason(reason, param.orderIndex));
        }
      });
      await dispatch(calculate());
      await dispatch(after(orders, params));
    } catch (e) {
      if (e !== 'pluginerror') console.error('Failed to load shopping cart', e);
    }
  };
}

export function updateProductOrder({
  name = undefined,
  amount = undefined,
  manualDiscount = undefined,
  employeeID = undefined,
  orderIndex,
  price = undefined,
  clearPrice = false,
  notes = undefined,
  ...other
}) {
  return async (dispatch, getState) => {
    const currentOrder = getProductInOrderByIndex(orderIndex)(getState());
    if (name && currentOrder.name !== name) {
      await dispatch({
        type: shoppingCartActionTypes.UPDATE_ORDER_NAME,
        payload: { name, orderIndex },
      });
    }

    // != since values can be either string or a number or both (ex -1 & '-1') - simplifies comparison
    if (amount && currentOrder.amount != amount) {
      await dispatch(updateOrderAmount(amount, orderIndex, false));
    }

    if (manualDiscount || manualDiscount === 0) {
      await dispatch(updateOrderDiscount(manualDiscount, orderIndex, false));
    }

    if (employeeID && currentOrder.employeeID !== employeeID) {
      await dispatch({
        type: shoppingCartActionTypes.SET_EMPLOYEE,
        payload: {
          orderIndex,
          employeeID,
        },
      });
    }

    if (clearPrice) {
      await dispatch(clearOrderPrice(orderIndex));
    } else if (price && currentOrder.price != price) {
      // Update price only if there's a difference
      await dispatch(updateOrderPrice(price, undefined, orderIndex));
    }
    if (notes) await dispatch(updateProductNotes(notes, orderIndex));

    await dispatch({
      type: shoppingCartActionTypes.UPDATE_ORDER_OTHER,
      payload: {
        orderIndex,
        ...other,
      },
    });
    dispatch(calculate());
  };
}

export function clearSaleDiscount() {
  return recalculateAfter({
    type: shoppingCartActionTypes.RESET_DISCOUNT,
    payload: {},
  });
}

export function applySaleDiscount({ amount, percentage }) {
  return async (dispatch, getState) => {
    const maxDiscount = getDiscountLimit(getState());
    const { before, on, after } = getPluginLifecycleHook('onApplyDiscount')(
      getState(),
    );

    const products = getProductsInShoppingCart(getState());
    const params = { amount, percentage, products };
    await dispatch(before(params));

    if (!getHasProductsInShoppingCart(getState())) {
      dispatch(clearGlobalDiscountReason());
      return;
    }
    let total = products
      .filter(p => !getIsProductNonDiscountable(p)(getState()))
      .filter(p => !p.computed && !p.isGiftCard && !p.isRegularGiftCard)
      .map(({ amount, finalPrice, finalPriceWithVAT, manualDiscount }) => {
        const price = getShowPricesWithTax(getState())
          ? finalPriceWithVAT
          : finalPrice;
        return amount * (price * (100 / (100 - manualDiscount)));
      })
      .reduce((a, b) => a + b, 0);

    total = await dispatch(on(params, total));
    const targetPercentage = amount ? (100 * amount) / total : percentage;

    let payload = { amount, percentage, actual: targetPercentage / 100 };

    const discountLimit = Math.min(100, maxDiscount);
    if (targetPercentage > discountLimit) {
      // Load namespace in to avoid untranslated texts
      await i18next.loadNamespaces('managerOverride');

      payload = await dispatch(
        openManagerOverride([
          {
            key: 'maxDiscount',
            text: i18next.t('managerOverride:reasons.maxDiscount', {
              target: targetPercentage,
              limit: maxDiscount,
            }),
            isPermitted: user =>
              getRightsForUserID(Number(user.userID)).then(
                ({ maxDiscount }) =>
                  targetPercentage === 0 || // Not setting a discount
                  maxDiscount === 0 || // No discount limit
                  Math.abs(targetPercentage) <= maxDiscount, // Discount is below limit
              ),
          },
        ]),
        // Use desired discount if got permission
        // otherwise use current user limit
      )
        .then(
          () => targetPercentage,
          () => {
            // Notify user about the actual applied discount in case manager override was cancelled
            if (Number(payload.percentage) !== Number(targetPercentage)) {
              dispatch(
                addWarning(
                  i18next.t(
                    'shoppingCart:alerts.maxDiscountManagerOverrideCancelled',
                    {
                      discount: payload.percentage,
                    },
                  ),
                ),
              );
            }
            return (
              Math.sign(targetPercentage) *
              Math.min(
                Math.max(0, maxDiscount), // Negative limits count as no discount allowed
                Math.abs(targetPercentage), // Otherwise take either the desired discount or max discount, whichever is smaller
              )
            );
          },
        )
        .then(async discount => {
          const reduction = discount / targetPercentage;
          return {
            amount: amount * reduction,
            percentage: percentage * reduction,
            actual: discount / 100,
          };
        });
    }

    batch(() => {
      dispatch({
        type: shoppingCartActionTypes.APPLY_DISCOUNT,
        payload,
      });
      dispatch(calculate());
    });

    await dispatch(after(params, payload));
  };
}

export function applyPromotion(promotion) {
  return recalculateAfter({
    type: shoppingCartActionTypes.APPLY_PROMOTION,
    payload: promotion,
  });
}

export function removePromotion(id) {
  return recalculateAfter({
    type: shoppingCartActionTypes.REMOVE_PROMOTION,
    payload: id,
  });
}

export function applyCoupon(coupon) {
  return recalculateAfter({
    type: shoppingCartActionTypes.APPLY_COUPONS,
    payload: [coupon],
  });
}

export function removeCoupon(id) {
  return recalculateAfter({
    type: shoppingCartActionTypes.REMOVE_COUPON,
    payload: id,
  });
}

export function setVatRateForSale(vatRateId) {
  return recalculateAfter({
    type: shoppingCartActionTypes.SET_VATRATE,
    payload: vatRateId,
  });
}

export function saleIsNotAnOffer() {
  return {
    type: shoppingCartActionTypes.IS_NOT_OFFER,
  };
}

export function setSelectedOffers(id) {
  return {
    type: shoppingCartActionTypes.SET_SELECTED_OFFERS,
    payload: id,
  };
}

export function setDiscountReason(reason, orderIndex) {
  return {
    type: shoppingCartActionTypes.SET_DISCOUNT_REASON,
    payload: { reason, orderIndex },
  };
}

export function setReturnReason(reason, orderIndex) {
  return {
    type: shoppingCartActionTypes.SET_RETURN_REASON,
    payload: { reason, orderIndex },
  };
}

export function clearReturnReasons() {
  return {
    type: shoppingCartActionTypes.CLEAR_RETURN_REASONS,
  };
}

export function clearDiscountReasons() {
  return {
    type: shoppingCartActionTypes.CLEAR_DISCOUNT_REASONS,
  };
}

export function clearDiscountReason(orderIndex) {
  return {
    type: shoppingCartActionTypes.CLEAR_DISCOUNT_REASON,
    payload: orderIndex,
  };
}

export function clearReturnReason(orderIndex) {
  return {
    type: shoppingCartActionTypes.CLEAR_RETURN_REASON,
    payload: orderIndex,
  };
}

export function clearGlobalReturnReason() {
  return {
    type: shoppingCartActionTypes.CLEAR_GLOBAL_RETURN_REASON,
  };
}

export function clearGlobalDiscountReason() {
  return {
    type: shoppingCartActionTypes.CLEAR_GLOBAL_DISCOUNT_REASON,
  };
}
