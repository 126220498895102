import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CloseButton from 'components/CustomButtons/CloseButton';
import Loader from 'components/Loader';
import { markOnlineOrderIdsAsViewed } from 'plugins/mm/rdx';
import GoErpApp from 'components/GoErpApp/GoErpApp';

import { fetchOnlineOrderData } from '../actions';

import { handleMessage } from './messageHandler';

function OrderNotifications({ onClose }) {
  const { t } = useTranslation('orderNotifications');
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(markOnlineOrderIdsAsViewed());
      dispatch(fetchOnlineOrderData());
    };
  }, [dispatch]);

  async function messageHandler(messageEvent: MessageEvent) {
    setLoading(true);
    await dispatch(handleMessage(messageEvent));
    setLoading(false);
  }

  function handleLoad() {
    setLoading(false);
    dispatch(fetchOnlineOrderData());
  }

  return (
    <div data-testid="OrderNotifications">
      <Box display="flex" alignItems="center" padding="1rem">
        <Typography variant="h5">{t('title')}</Typography>
        <Box flexGrow={1} />
        <CloseButton action={onClose} />
      </Box>
      <Divider />
      <Box>
        <Loader show={loading} block>
          <GoErpApp
            title={t('title')}
            url="/order-notification-page"
            onStartLoading={() => setLoading(true)}
            onLoad={handleLoad}
            onMessage={messageHandler}
            iframeProps={{
              style: {
                width: '100%',
                height: 'calc(100vh - 118px)',
              },
            }}
          />
        </Loader>
      </Box>
    </div>
  );
}

export default OrderNotifications;
