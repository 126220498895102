import { getPluginSelector } from 'reducers/Plugins';

import { pluginID } from '../constants';
import { PergamonFiscalRecord } from '../types';

type State = {
  fiscalRecord: PergamonFiscalRecord | null;
  shouldSaveToFiscal: boolean;
};

// actions
const types = {
  SET_RECORD: `plugin ${pluginID}: SET_RECORD`,
  RESET_RECORD: `plugin ${pluginID}: RESET_RECORD`,
  SET_SHOULD_SAVE_TO_FISCAL: `plugin ${pluginID}: SET_SHOULD_SAVE_TO_FISCAL`,
};

export const setRecord = (record: PergamonFiscalRecord) => ({
  type: types.SET_RECORD,
  payload: record,
});

export const resetRecord = {
  type: types.RESET_RECORD,
};

export const setShouldSaveToFiscal = (value: boolean) => ({
  type: types.SET_SHOULD_SAVE_TO_FISCAL,
  payload: value,
});

// reducer
const initState: State = {
  fiscalRecord: null,
  shouldSaveToFiscal: false,
};

export const reducer = (
  state: State = initState,
  { type, payload }: { type: string; [key: string]: unknown },
): State => {
  switch (type) {
    case types.SET_RECORD:
      return {
        ...state,
        fiscalRecord: payload as PergamonFiscalRecord,
      };
    case types.RESET_RECORD:
      return {
        ...state,
        fiscalRecord: null,
      };
    case types.SET_SHOULD_SAVE_TO_FISCAL:
      return {
        ...state,
        shouldSaveToFiscal: payload as boolean,
      };
    default:
      return state;
  }
};

// selectors
export const getFiscalRecord = getPluginSelector<State>(pluginID)(
  (state: State) => state.fiscalRecord,
);

export const getShouldSaveToFiscal = getPluginSelector<State>(pluginID)(
  (state: State) => state.shouldSaveToFiscal,
);
