import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getSelectedWarehouse } from 'reducers/warehouses';

import { PosPlugin } from '../plugin';

import data from './states.json';

const CustomAddressFormBeta: PosPlugin['UIAddressFormBeta'] = ({
  children,
  address,
  onChange,
}) => {
  const countryOptions = useMemo(
    () => [
      <MenuItem value="">Select</MenuItem>,
      ...data.Country.filter(c => c.Active).map(c => (
        <MenuItem key={c.ISO} value={c.ISO}>
          {c['Country Name']}
        </MenuItem>
      )),
    ],
    [data],
  );

  const stateOptions = useMemo(
    () => [
      <MenuItem value="">Select</MenuItem>,
      ...data.State.filter(c => address?.country === c.Code).map(c => (
        <MenuItem key={c['State Code']} value={c['State Code']}>
          {c['State Display Name']}
        </MenuItem>
      )),
    ],
    [data, address?.country],
  );

  const { country } = useSelector(getSelectedWarehouse);
  const countryIsISOCode = useMemo(
    () => data.Country.some(({ ISO }) => ISO === country),
    [country],
  );

  useEffect(() => {
    if (!address?.country && countryIsISOCode) {
      onChange('country', country);
    }
  });

  return R.pipe(
    React.Children.toArray,
    R.map(
      R.over(
        R.lensPath(['props', 'children']),
        R.pipe(
          React.Children.toArray,
          R.map(
            R.pipe(
              R.when(
                ch => ch?.props?.children?.props?.name === 'country',
                R.pipe(
                  R.assocPath(['props', 'children', 'props', 'select'], true),
                  R.assocPath(
                    ['props', 'children', 'props', 'children'],
                    countryOptions,
                  ),
                ),
              ),
              R.when(
                ch => ch?.props?.children?.props?.name === 'state',
                R.pipe(
                  R.assocPath(['props', 'children', 'props', 'select'], true),
                  R.assocPath(
                    ['props', 'children', 'props', 'children'],
                    stateOptions,
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  )(children);
};

export default CustomAddressFormBeta;
