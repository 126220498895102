import { getSelectedCustomer } from 'reducers/customerSearch';
import { createConfirmation } from 'actions/Confirmation';
import { PosPlugin } from 'plugins/plugin';
import { addWarning } from 'actions/Error';

export const openSuspendedCustomerConfirmation: Required<
  PosPlugin
>['onSetCustomer']['after'] = (_, customerData) => async dispatch => {
  if (customerData.suspended) {
    return new Promise(resolve => {
      dispatch(
        createConfirmation(resolve, undefined, {
          title: 'Warning',
          body: 'Customer suspended, sales prohibited',
          confirmText: 'OK',
        }),
      );
    });
  }
};

export const cancelAction = () => async (dispatch, getState) => {
  const customer = getSelectedCustomer(getState());
  if (customer.suspended) {
    dispatch(addWarning('Selling to the selected customer is prohibited'));
    throw new Error('Customer is suspended');
  }
};
