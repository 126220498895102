import { partnerKey } from 'services/shared';

import { doClientRequest } from './core/ErplyAPI';

export const getCurrency = () =>
  doClientRequest({
    request: 'getCurrencies',
  });

export const getConfParameters = () =>
  doClientRequest({
    request: 'getConfParameters',
  });

export const getObjects = () =>
  doClientRequest({
    request: 'getObjects',
    partnerKey,
  });

export const saveConfParameter = params =>
  doClientRequest({
    ...params,
    request: 'saveConfParameter',
  });

export const getGateways = params =>
  doClientRequest({ ...params, request: 'getGateways' });

export const saveGatewaySetup = params =>
  doClientRequest({ ...params, request: 'saveGatewaySetup' });

export const getGatewaySetups = params =>
  doClientRequest({ ...params, request: 'getGatewaySetups' });

export const getActiveLanguages = () => {
  return doClientRequest({
    request: 'getActiveLanguages',
  });
};

export const getDefaultLanguage = () => {
  return doClientRequest({
    request: 'getDefaultLanguage',
  });
};

export const getAllLanguages = () => {
  return doClientRequest({
    request: 'getAllLanguages',
  });
};
