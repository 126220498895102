import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { Button, styled } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { RootState } from 'reducers';
import { PosPlugin } from 'plugins/plugin';
import { openPluginModalPage } from 'actions/modalPage';
import { modals } from 'plugins/saiNumberCapture/constants';

import { setSAINumber, setScannedQuery } from '../redux/actions';
import { getShouldLock } from '../redux/selectors';

import DisabledShortcuts from './DisabledShortcuts';

const AddSAINumberButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1800,
  pointerEvents: 'auto',
  transform: 'translate(-50%, 280%)',
  padding: '2rem',
});

const UICol1: Required<PosPlugin>['UICol1'] = ({ children }) => {
  const dispatch = useDispatch<
    ThunkDispatch<RootState, unknown, Action<string>>
  >();

  const shouldLock = useSelector(getShouldLock);

  if (!shouldLock) return children;

  const container = document.getElementById('pos-col-1');

  if (!container) return children;

  async function addSAINumber() {
    try {
      const saiNumber = await dispatch(
        openPluginModalPage(modals.SAINumberModal)({
          isPopup: true,
        }),
      );
      dispatch(setSAINumber(saiNumber));
    } catch (error) {
      // ignore error
    } finally {
      dispatch(setScannedQuery(null));
    }
  }

  return (
    <DisabledShortcuts>
      {R.pipe(
        R.assocPath(['props', 'style'], {
          opacity: 0.25,
          pointerEvents: 'none',
          position: 'relative',
        }),
        R.over(R.lensPath(['props', 'children']), component => {
          return [
            component,
            ReactDOM.createPortal(
              <div
                style={{
                  position: 'relative',
                }}
              >
                <AddSAINumberButton
                  onClick={addSAINumber}
                  variant="contained"
                  color="secondary"
                >
                  Start a sale with a SAI number
                </AddSAINumberButton>
              </div>,
              container,
            ),
          ];
        }),
      )(children)}
    </DisabledShortcuts>
  );
};

export default UICol1;
