import { createSelector } from 'reselect';

import { createConfirmation } from 'actions/Confirmation';
import {
  getHasPayButtonBeenClicked,
  getSalesDocument,
  getTotal,
} from 'reducers/Payments';
import { getCurrentSalesDocument, getIsAReturn } from 'reducers/sales';
import { openPluginModalPage } from 'actions/modalPage';

function showQffUnavailableConfirmation() {
  return createConfirmation(() => null, null, {
    title: 'Unavailable',
    body: 'QFF not available for Returns or Click and Connect',
  });
}

export const openQffModal = () => async (dispatch, getState) => {
  const isReturn = getIsAReturn(getState());
  const salesDocument = getCurrentSalesDocument(getState());
  const isOnlineOrder = salesDocument.deliveryTypeName === 'Click and Collect';
  if (isReturn || isOnlineOrder) {
    await dispatch(showQffUnavailableConfirmation());
    return;
  }
  dispatch(openPluginModalPage('QffCardModal')({ isPopup: true }));
};

export const getShouldSkipQffModal = createSelector(
  getSalesDocument,
  getTotal,
  getHasPayButtonBeenClicked,
  (salesDocument, total, payButtonClicked) => {
    const isReturn = salesDocument.type === 'CREDITINVOICE';
    const isOnlineOrder =
      salesDocument.deliveryTypeName === 'Click and Collect';
    const isCancellation = salesDocument.invoiceState === 'CANCELLED';
    const isLayaway = salesDocument.type === 'PREPAYMENT';
    const isFullyPaying = salesDocument.total <= total;
    const isOrder = salesDocument.type === 'ORDER';

    return (
      isReturn ||
      isOnlineOrder ||
      isCancellation ||
      (isLayaway && !isFullyPaying) ||
      (isOrder && !payButtonClicked)
    );
  },
);
