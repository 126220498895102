import { PosPlugin } from 'plugins/plugin';

import lastChangeReducer from './redux';
import { headerComponent } from './ui';

const lastChangePlugin: PosPlugin = {
  id: 'last-sale-change-in-header',
  name: 'Show change for last sale in header',
  info: 'Displays in the header the amount of change of the last sale',
  keywords: ['common', 'UI', 'header', 'change'],
  reduxReducer: lastChangeReducer,
  ComponentHeader: headerComponent,
};
export default lastChangePlugin;
