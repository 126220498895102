import React, { useMemo, useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import InputField from 'components/FieldTypes/InputField';
import UIButton from 'components/UIElements/UIButton';
import { getProductsUniversal } from 'actions/productsDB';

import constants from '../constants';
import { mergeLevel } from '../utils';

import KittedItemConfiguration from './KittedItemConfiguration';

const ComponentConfiguration = ({ byLevel }) => {
  const dispatch = useDispatch();
  const [level, setLevel] = useState('pos');
  const [searchText, setSearchText] = useState('');
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<
    Record<string, any> | undefined
  >();

  const handleChange = e => {
    setSearchText(e.target.value);
  };

  const searchProducts = async () => {
    const { products: matchingProducts } = (await dispatch(
      getProductsUniversal({ searchNameIncrementally: searchText }),
    )) as any;
    setProducts(matchingProducts);
  };

  const selectProduct = async productID => {
    const chosenProduct = products.find(p => p.productID === productID);
    await dispatch(
      getProductsUniversal(
        {
          productIDs: chosenProduct?.relatedProducts?.map(pid => Number(pid)),
        },
        { addToCachedItems: true, withMeta: true },
      ),
    );
    setSelectedProduct(products.find(p => p.productID === productID));
  };

  const syncConfigsFromLevels = () => {
    let result = {};
    // get merged configuration for the levels above the currently selected one
    constants.levelsOptions
      .slice(
        0,
        constants.levelsOptions.findIndex(l => l === level),
      )
      .forEach(key => {
        result = mergeLevel(byLevel[key].current, result);
      });

    return result;
  };

  const merged = useMemo(() => syncConfigsFromLevels(), [byLevel, level]);

  return !selectedProduct ? (
    <div data-testid="pnp-related-products-conf">
      <Form.Group>
        <Form.Label>Select CAFA level for your configuration:</Form.Label>
        <InputField
          type="select"
          onChange={e => setLevel(e.target.value)}
          options={constants.levelsOptions}
          value={level}
          data-testid="level"
        />
      </Form.Group>
      <Form
        onSubmit={async e => {
          e.preventDefault();
          await searchProducts();
        }}
      >
        <Form.Group>
          <Form.Label>Search for product name or code</Form.Label>
          <InputField
            className="flex-fill"
            onChange={handleChange}
            value={searchText}
            data-testid="search-text"
          />
        </Form.Group>
        <UIButton
          variant="POS"
          type="submit"
          text="Search"
          action={searchProducts}
          data-testid="search-btn"
        />
      </Form>
      <ListGroup className="mt-2">
        {products.map(p => (
          <ListGroup.Item
            key={p.productID}
            action
            onClick={() => selectProduct(p.productID)}
            data-testid="product"
            data-test-key={p.productID}
          >
            {p.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  ) : (
    <KittedItemConfiguration
      product={selectedProduct}
      inheritedConfiguration={merged}
      configuration={byLevel[level].current}
      save={byLevel[level].save}
      goBack={() => setSelectedProduct(undefined)}
    />
  );
};

export default ComponentConfiguration;
