/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Section } from 'containers/Forms/Settings/components/Inputs';
import formStyles from 'components/FieldTypes/skins/skins.module.scss';
import nets from 'paymentIntegrations/netsCloud/requests';
import {
  downloadDataset,
  downloadSoftware,
} from 'paymentIntegrations/netsCloud';
import InputField from 'components/FieldTypes/InputField';
import { sanitizePrice } from 'actions/CreateNew';
import TestButtonBlock from 'components/UIElements/PaymentTestBlock';

import { Ctx } from '../../../../components/CtxInputs';

import styles from './NetsConfiguration.module.scss';
import NetsIntegrationForm from './NetsIntegrationForm';

const inputClass = classNames([
  formStyles.formInput,
  formStyles.mediumTitle,
  'nets-form-input',
]);

const Nets = () => {
  const dispatch = useDispatch();

  const superContext = useContext(Ctx);
  const { configuration } = superContext.values;
  const terminalId = configuration.terminal;

  const [testingAmount, setTestingAmount] = useState('1.00');
  const [currentAction, setCurrentAction] = useState('');
  const [isOngoing, setIsOngoing] = useState(false);
  const [status, setStatus] = useState({});
  const [lastTransactionId, setLastTransactionId] = useState('');
  const [lastTransactionAmount, setLastTransactionAmount] = useState('');

  const setSuccess = key => setStatus({ ...status, [key]: 'Success' });
  const setFailure = key => setStatus({ ...status, [key]: 'Failure' });

  const testButtons = [
    {
      variant: 'success',
      title: `Test connection`,
      type: 'connection',
      info: 'Test connection with the terminal',
      disabled: isOngoing || !(Number(testingAmount) > 0),
      action: () =>
        nets
          .getTerminalInfo({ config: configuration, params: { terminalId } })
          // @ts-ignore
          .then(({ data }) => {
            if (!Object.keys(data).length) {
              throw new Error('EmptyError response');
            }
            if (!data.terminal.terminalConnected) {
              throw new Error('Terminal not connected');
            }
          }),
    },
    {
      variant: 'success',
      title: `Download software`,
      type: 'software',
      info: 'Download latest software for the terminal',
      disabled: isOngoing || !(Number(testingAmount) > 0),
      action: () => dispatch(downloadSoftware()),
    },
    {
      variant: 'success',
      title: `Download dataset`,
      type: 'dataset',
      info: 'Download and print terminal dataset',
      disabled: isOngoing || !(Number(testingAmount) > 0),
      action: () => dispatch(downloadDataset({ terminalId })),
    },
    {
      variant: 'success',
      title: `Pay ${testingAmount}$`,
      type: 'sale',
      info: 'Make a payment',
      disabled: isOngoing || !(Number(testingAmount) > 0),
      action: async () => {
        const { data } = await nets.createTransaction({
          config: configuration,
          params: {
            terminalId,
            allowPinBypass: true,
            amount: Number(testingAmount),
          },
        });
        if ('result' in data) {
          setLastTransactionId(data.result[0].transactionId);
          setLastTransactionAmount(testingAmount);
        } else {
          throw new Error(data.failure.error);
        }
      },
    },
    {
      variant: 'danger',
      title: 'Cancel',
      info: 'Cancel ongoing transaction',
      type: 'cancel',
      disabled: !isOngoing,
      action: () =>
        nets.cancelOngoing({ config: configuration, params: { terminalId } }),
    },
    {
      variant: 'danger',
      title: `Return ${lastTransactionAmount || ''}$`,
      type: 'return',
      info: 'Return previous payment transaction',
      disabled: isOngoing || !(lastTransactionId && lastTransactionAmount),
      action: async () => {
        await nets.returnTransaction({
          config: configuration,
          params: {
            terminalId,
            amount: Number(lastTransactionAmount),
          },
        });

        setLastTransactionId('');
        setLastTransactionAmount('');
      },
    },
    {
      variant: 'danger',
      title: 'Void payments',
      type: 'void',
      info: 'Void last payment',
      disabled: isOngoing || !(lastTransactionId && lastTransactionAmount),
      action: async () => {
        await nets.voidTransaction({
          config: configuration,
          params: {
            terminalId,
            transactionId: lastTransactionId,
            amount: Number(lastTransactionAmount),
          },
        });
        setLastTransactionId('');
        setLastTransactionAmount('');
      },
    },
    {
      variant: 'light',
      title: 'Close batch',
      info: 'Close the current batch',
      type: 'close',
      disabled: isOngoing,
    },
  ];

  const onClickHandler = async ({ type, action }) => {
    try {
      setIsOngoing(true);
      setCurrentAction(type);
      await action();
      setSuccess(type);
    } catch (err) {
      setFailure(type);
      console.error('Nets action failed with error', err);
    } finally {
      setIsOngoing(false);
    }
  };

  const returnTestButtons = testButtons.map(b => (
    <TestButtonBlock
      key={b.type}
      clickHandler={() => onClickHandler({ action: b.action, type: b.type })}
      button={b}
      processing={isOngoing && b.type === currentAction}
      status={status[b.type]}
    />
  ));

  return (
    <div className={styles['nets-settings']}>
      <NetsIntegrationForm />
      <h2>Tests:</h2>
      <InputField
        type="text"
        title="Testing amount"
        value={testingAmount}
        onChange={e => setTestingAmount(sanitizePrice(e.target.value))}
        size="lg"
        className={inputClass}
      />
      <Section>
        <div className="my-2">
          <span className="mx-2">
            {isOngoing
              ? `Performing ${currentAction} request`
              : 'Press a button to perform a test request'}
          </span>
        </div>
        {returnTestButtons}
      </Section>
    </div>
  );
};

export default Nets;
