import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { Customer } from 'types/Customer';
import UIButton from 'components/UIElements/UIButton';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import { addWarning } from 'actions/Error';
import { setCustomer } from 'actions/CustomerSearch/setCustomer';

const CustomersResults: FC<{ customers: Customer[] }> = ({ customers }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('scannerSelection');

  const selectCustomer = customer => {
    dispatch(
      addWarning(
        t('customerSelected', {
          fullName: customer.fullName,
        }),
        {
          dismissible: true,
          selfDismiss: true,
        },
      ),
    );
    dispatch(setCustomer({ data: customer }));
    dispatch(closeModalPage());
  };

  return (
    <Table>
      <TableHead>
        <TableRow style={{ textAlign: 'center' }}>
          <TableCell
            style={{
              width: '500px',
              paddingLeft: '1em',
            }}
          >
            <span>{t('customers.headers.fullName')}</span>
          </TableCell>
          <TableCell style={{ width: '500px' }}>
            <span>{t('customers.headers.groupName')}</span>
          </TableCell>
          <TableCell style={{ width: '200px' }}>
            <span>{t('customers.headers.birthday')}</span>
          </TableCell>
          <TableCell style={{ width: '100px' }}>
            <span>{t('select')}</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customers.map(customer => (
          <TableRow
            style={{ textAlign: 'center' }}
            key={customer.customerID}
            data-testid="scanningDuplicateProduct"
            data-test-key={customer.customerID}
          >
            <TableCell>
              <span>{customer.fullName}</span>
            </TableCell>
            <TableCell>
              <span>{customer.groupName}</span>
            </TableCell>
            <TableCell>
              <span>{customer.birthday}</span>
            </TableCell>
            <TableCell>
              <UIButton
                action={() => selectCustomer(customer)}
                text={t('select')}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomersResults;
