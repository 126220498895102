import { CardPaymentHooks, PaymentObj } from 'paymentIntegrations/types';

import { ApiResponse } from '../types';

import { parseJSONPTerminalResponse, printReceipts } from './utils';

const handlePaymentSuccess = (
  response: string,
  cardPayment: PaymentObj,
  { updateMessage, beforeDocSave, enableButtons }: CardPaymentHooks,
) => async (dispatch): Promise<ApiResponse | null> => {
  if (response) {
    const terminalResponse = parseJSONPTerminalResponse(response);

    if (
      terminalResponse.RcmResponse?.RESPONSE?.HOSTRESPONSEDESCRIPTION ===
      'Approval'
    ) {
      const {
        APPROVEDAMOUNT,
        CARDBRAND,
        ACCOUNT,
        TRANSACTIONID,
        RECEIPTTEXT,
      } = terminalResponse.RcmResponse.RESPONSE;

      await beforeDocSave({
        key: cardPayment.key,
        type: 'CARD',
        amount: APPROVEDAMOUNT,
        cardType:
          CARDBRAND.toUpperCase() === 'AMERICAN EXPRESS' ? 'AMEX' : CARDBRAND,
        cardNumber: ACCOUNT.slice(-4),
        attributes: {
          refNo: TRANSACTIONID,
        },
        paid: true,
      });

      dispatch(printReceipts(RECEIPTTEXT));

      return terminalResponse;
    }
  }

  updateMessage(
    `Unsuccessful payment. Response is missing - ${JSON.stringify(response)}`,
  );
  enableButtons(['retry', 'close']);
  return null;
};

export default handlePaymentSuccess;
