/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { createSelector } from 'reselect';

import ListItem from 'components/ListItem';
import { round, isEmpty } from 'utils';
import { getDenominations } from 'reducers/configs/settings';
import { addWarning, dismissType } from 'actions/Error';
import {
  getCustomer,
  getBalance,
  getPayments,
  getIsPaymentDisabled,
  getIsSerialGiftcardDisabled,
  getIsGiftcardDisabled,
  getNewPaymentKeyForType,
  getPaymentsCurrency,
  getPaymentsTotal,
  getPaymentIsDefaultCustomer,
  getIsReturnPayment,
} from 'reducers/Payments';
import { setPaymentEditValue } from 'actions/Payments';
import { setPayment } from 'actions/Payments/setPayment';
import { setPaymentSelected } from 'actions/Payments/setPaymentSelected';
import { getGiftcardTypes, getRegularGiftcards } from 'reducers/giftcards';
import { PluginComponent } from 'plugins';
import Loader from 'components/Loader';

const useActions = () => {
  const dispatch = useDispatch();
  const balance = useSelector(getBalance);
  const payments = useSelector(getPayments);
  const isSaleAsReturn = useSelector(getPaymentsTotal) < 0;
  const { code } = useSelector(getPaymentsCurrency);
  const { t } = useTranslation('payment');
  const { availableCredit } = useSelector(getCustomer);
  const paymentKey = useSelector(getNewPaymentKeyForType('check'));

  const addTip = async () => {
    dispatch(setPaymentEditValue(''));

    const tip = 0;
    await dispatch(
      setPayment({
        key: 'tip',
        amount: round(tip),
        caption: t('tenders.tip'),
        type: 'TIP',
      }),
    );
    await dispatch(setPaymentSelected('tip'));
  };

  const addCashPayment = event => {
    dispatch(setPaymentEditValue(''));

    const cashPayment =
      Number(event.target.value) +
      Number(
        isEmpty(payments) || !payments[`${code}-cash`]
          ? 0
          : Math.abs(payments[`${code}-cash`].amount),
      );

    dispatch(
      setPayment({
        amount: round(cashPayment),
        key: `${code}-cash`,
        caption: t('tenders.cash'),
        type: 'CASH',
        currencyRate: 1,
      }),
    ).then(() => dispatch(setPaymentSelected('')));
  };

  const addCheckPayment = () => {
    dispatch(dismissType('check'));
    if (balance === 0) {
      return dispatch(
        addWarning(t('alerts:payments.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
    }
    // if check limit exceeded, don't allow continuation
    if (balance < 0 || isSaleAsReturn) {
      return dispatch(
        setPayment({
          key: paymentKey,
          caption: t('tenders.check'),
          type: 'CHECK',
          amount: 0.0,
        }),
      );
    }
    return dispatch(
      addWarning(t('alerts.payCheckUnnecessary'), {
        dismissible: false,
        type: 'payCheck',
        selfDismiss: 2000,
      }),
    );
  };

  const addStoreCreditPayment = async () => {
    dispatch(setPaymentEditValue(''));

    const storePayment = {
      key: 'storeCredit',
      caption: t('tenders.storecredit'),
      type: 'STORECREDIT',
    };
    // get the initial value of the storeCredit that has to be added
    const currentCreditAmount = payments.storeCredit
      ? Number(payments.storeCredit.amount)
      : 0;

    // if storeCredit is negative or there is change we are or have the chnace to add storeCredit to profile
    if (currentCreditAmount > 0 || balance > 0) {
      // get the initial value of the storeCredit that has to be added

      // if the balance is more than the initially added storeCredit amount, add the change to the storeCredit amount
      storePayment.amount = currentCreditAmount - balance;

      dispatch(setPayment(storePayment));
    } else {
      storePayment.amount =
        -balance >= availableCredit ? availableCredit : -balance;
      await dispatch(setPayment(storePayment));
      if (payments.storeCredit) await setPaymentSelected('storeCredit');
    }
  };

  const addGiftCards = async type => {
    dispatch(setPaymentEditValue(''));

    dispatch(dismissType('giftCard'));
    if (balance === 0) {
      dispatch(
        addWarning(t('alerts:payments.amountCovered'), {
          selfDismiss: 3000,
        }),
      );
      return;
    }
    if (balance < 0 || isSaleAsReturn) {
      const paymentKey = type ? `giftcard-${type.id}` : 'giftcard';
      dispatch(setPaymentSelected(paymentKey));
      return;
    }
    dispatch(
      addWarning(t('alerts.giftcardUnnecessary'), {
        dismissible: false,
        type: 'giftCard',
        selfDismiss: 2000,
      }),
    );
  };

  return {
    addCashPayment,
    addCheckPayment,
    addGiftCards,
    addStoreCreditPayment,
    addTip,
  };
};

const ButtonItem = ({ style, ...props }) => (
  <ListItem
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontFamily: 'Proxima Nova',
      minWidth: 80,
      minHeight: 76,
      height: 'inherit',
      width: 'inherit',
      hyphens: 'auto',
      ...style,
    }}
    {...props}
  />
);

const getIsStoreCreditDisabled = createSelector(
  getPayments,
  getBalance,
  getCustomer,
  getPaymentIsDefaultCustomer,
  (payments, balance, { availableCredit }, isDefaultCustomer) => {
    if (isDefaultCustomer) return true;

    const storeCreditPayment = payments.storeCredit;
    const hasStoreCredit = Number(storeCreditPayment?.amount) < 0;

    if (hasStoreCredit || balance > 0) {
      return (
        (Object.keys(payments).length === 1 && storeCreditPayment) ||
        balance <= 0
      );
    }
    return !(balance < 0 && Number(availableCredit) > 0) || balance === 0;
  },
);

export default () => {
  const { t } = useTranslation('payment');
  const denominations = useSelector(getDenominations());

  const isGiftCardDisabled = useSelector(getIsGiftcardDisabled);
  const checkPaymentIsDisabled = useSelector(getIsPaymentDisabled);
  const serialGiftcardDisabled = useSelector(getIsSerialGiftcardDisabled);
  const customer = useSelector(getCustomer);
  const { code } = useSelector(getPaymentsCurrency);

  const isSaleAsReturn = useSelector(getIsReturnPayment);
  const giftcardTypes = useSelector(getGiftcardTypes);
  const regularGiftCards = useSelector(getRegularGiftcards);
  const [loadingRegularGiftCard, setLoadingRegularGiftCard] = useState(
    !regularGiftCards,
  );
  const balance = useSelector(getBalance);
  const payments = useSelector(getPayments);

  const {
    addCashPayment,
    addCheckPayment,
    addGiftCards,
    addStoreCreditPayment,
    addTip,
  } = useActions();

  // determine if the store credit button should be disabled
  const storeCreditDisabled = useSelector(getIsStoreCreditDisabled);
  const showDefaultGCButton =
    (!serialGiftcardDisabled && giftcardTypes.length === 0) ||
    !isGiftCardDisabled;
  const showCustomGCButtons = !serialGiftcardDisabled;

  useEffect(() => {
    if (regularGiftCards || !showDefaultGCButton)
      return setLoadingRegularGiftCard(false);

    // If hangs for 10 secs, set loading to false to allow to proceed
    const i = setTimeout(() => {
      setLoadingRegularGiftCard(false);
    }, 10000);

    return () => clearTimeout(i);
  }, [regularGiftCards, showDefaultGCButton]);

  return (
    <div
      style={{
        display: 'inline-grid',
        gridTemplateColumns: '0fr 0fr 0fr',
        fontSize: 16,
      }}
      data-testid="keypad-container"
    >
      <PluginComponent
        hookname="UIKeyPad"
        props={{
          denominations,
          customer,
          balance,
          payments,
          code,
          isSaleAsReturn,
        }}
      >
        {!checkPaymentIsDisabled('TIP') && !isSaleAsReturn && (
          <ButtonItem
            children={t('tenders.tip')}
            variant="grid_yellow"
            action={addTip}
            key="payment-tip"
            data-testid="payment-tip"
          />
        )}

        {!checkPaymentIsDisabled('CHECK') && (
          <ButtonItem
            children={t('tenders.check')}
            variant="grid_yellow"
            action={addCheckPayment}
            disabled={checkPaymentIsDisabled('CHECK')}
            key="payment-check"
            data-testid="payment-check"
          />
        )}

        {!checkPaymentIsDisabled('STORECREDIT') && (
          <ButtonItem
            children={t('tenders.storecredit')}
            variant="grid_yellow"
            action={addStoreCreditPayment}
            disabled={storeCreditDisabled}
            key="payment-store-credit"
            data-testid="payment-store-credit"
          />
        )}
        {/* Custom gift card types (buttons) */}
        {showCustomGCButtons &&
          giftcardTypes.map(type => (
            <ButtonItem
              key={type.nameEN}
              data-testid="payment-custom-giftcard"
              data-test-key={type.nameEN}
              variant="grid_yellow"
              action={() => addGiftCards(type)}
            >
              <span
                data-testid="payment-custom-giftcard-icon"
                data-test-key={type.nameEN}
                className="icon_gift_alt"
                style={{ fontSize: 24 }}
              />
              <span
                data-testid="payment-custom-giftcard-name"
                data-test-key={type.nameEN}
                style={{ fontSize: 10 }}
              >
                {type.name}
              </span>
            </ButtonItem>
          ))}
        {/* Default gift card button (not visible if regular gift cards disabled and serial gift cards either disabled or have custom types) */}
        {showDefaultGCButton && (
          <Loader
            show={loadingRegularGiftCard}
            block
            data-testid="regular-gift-card-key-pad-loader"
          >
            <ButtonItem
              data-testid="payment-giftcard"
              variant="grid_yellow"
              action={() => addGiftCards()}
            >
              <Trans
                i18nKey="payment:buttons.giftcard"
                tOptions={{ context: !giftcardTypes.length ? 'combined' : '' }}
              >
                <span
                  data-testid="payment-giftcard-icon"
                  className="icon_gift_alt"
                  style={{ fontSize: 24 }}
                />
                <span style={{ fontSize: 10 }} />
              </Trans>
            </ButtonItem>
          </Loader>
        )}
        {!checkPaymentIsDisabled('CASH') &&
          denominations.map(({ mark, value }) => (
            <ButtonItem
              disabled={checkPaymentIsDisabled('CASH')}
              children={mark}
              data-testid="payment-denomination"
              data-test-key={value}
              variant="grid_light"
              key={mark}
              value={value}
              action={addCashPayment}
            />
          ))}
      </PluginComponent>
    </div>
  );
};
