import { PatchScript } from '@pos-refacto/patchscript-with-react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { format } from 'date-fns';

import { printPatchscript } from 'actions/integrations/printer';
import { RItem } from 'containers/Forms/Settings/views/Debug/components/ReceiptSchema/Editor/schema/comms';
import { SaleDocumentResponse } from 'types/SalesDocument';
import { getVatRateByID } from 'reducers/vatRatesDB';
import { getEmployeeByIDAsync } from 'utils/hooks/useEmployeeHook';

type Fields = {
  documentId: string;
  passportNumber: string;
  domicile: string;
  numberOfPackages: string;
  lastName: string;
  firstName: string;
};

export const handleTaxFreePrint = (
  {
    documentId,
    domicile,
    firstName,
    lastName,
    numberOfPackages,
    passportNumber,
  }: Fields,
  document: SaleDocumentResponse,
) => async (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
  getState: () => unknown,
) => {
  const blankLine: RItem = { type: 'text', pieces: [{ text: ' ' }] };

  const [vatrate] = document.vatTotalsByTaxRate;
  const rate = getVatRateByID(vatrate.vatrateID)(getState())?.rate;
  const employee = await dispatch(getEmployeeByIDAsync(document.employeeID));

  const patchScript: PatchScript = [
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: '!!! ATTENTION !!!' }],
    },
    blankLine,
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: 'TAX FREE FORM' }],
    },
    blankLine,
    blankLine,
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: '*YOUR REFUND AMOUNT*' }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [
        { text: `${document.currencyCode}: ${document.vatTotal.toFixed(2)}` },
      ],
    },
    blankLine,
    {
      align: 'center',
      type: 'text',
      pieces: [{ text: '!! HOW TO GET !!' }],
    },
    blankLine,
    {
      align: 'center',
      type: 'text',
      pieces: [{ text: '!! YOUR MONEY BACK !!' }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: 'FOLLOW THE STEPS 1-2-3-4' }],
    },
    {
      type: 'text',
      pieces: [{ text: '1. FILL IN CUSTOMER DETAILS' }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `PASSPORT/ID NO: ${passportNumber}` }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `FULL NAME: ${firstName} ${lastName}` }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `PERMANENT HOME ADDRESS: ${domicile}` }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: '2. READ AND SIGN LEGAL DECLARATION' }],
    },
    {
      type: 'text',
      pieces: [
        {
          text:
            'I have purchased the goods according to the attached receipt(s). I will export the goods within 3 months plus the month of purchase. I also declare that I am not a resident in the EU or Norway',
        },
      ],
    },
    blankLine,
    {
      type: 'text',
      pieces: [
        {
          text:
            'I consent to the processing of my personal data as set in the Privacy Policy overleaf or in a separate document hereby submitted to me. I understand the refund will not be made if all mandatory and regulatory requirements are not met',
        },
      ],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: 'CUSTOMER SIGNATURE' }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: '3. SELECT REFUND METHOD' }],
    },
    {
      type: 'text',
      pieces: [
        {
          text:
            '[ X ] A. If your tax free transaction is physically validated the refund will be paid by bank transfer phisically',
        },
      ],
    },
    blankLine,
    {
      type: 'text',
      pieces: [
        {
          text:
            '[ ] B. If your tax free transcation is phisically validated you will receiver your immediate refund at the purchase store',
        },
      ],
    },
    blankLine,
    blankLine,
    {
      type: 'text',
      pieces: [{ text: 'CASH REFUND RECEIVED.' }],
    },
    {
      type: 'text',
      pieces: [{ text: 'CUSTOMER SIGNATURE' }],
    },
    {
      type: 'text',
      pieces: [{ text: 'X' }],
    },
    blankLine,
    blankLine,
    blankLine,
    blankLine,
    {
      type: 'text',
      pieces: [{ text: '4. EXPORT VERIFICATION' }],
    },
    {
      type: 'text',
      pieces: [
        {
          text:
            'The goods listed on the attached receipt(s) have been presented for export. Customs or other authorized verification of export from the EU.',
        },
      ],
    },
    blankLine,
    blankLine,
    blankLine,
    blankLine,
    blankLine,
    blankLine,
    blankLine,
    blankLine,
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: 'Signature and date' }],
    },
    blankLine,
    blankLine,
    {
      type: 'text',
      pieces: [{ text: document.pointOfSaleName }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [
        { text: `RETAILER SIGNATURE: ${format(new Date(), 'yyyy-MM-dd')}` },
      ],
    },
    blankLine,
    blankLine,
    blankLine,
    blankLine,
    {
      type: 'text',
      pieces: [
        {
          text:
            '...............................................................',
        },
      ],
    },
    { type: 'text', pieces: [{ text: 'Goods description' }] },
    { type: 'text', pieces: [{ text: 'Goods according to receipt(s)' }] },
    {
      type: 'text',
      pieces: [{ text: `No. of packages: ${numberOfPackages}` }],
    },
    { type: 'text', pieces: [{ text: `Receipt number: ${documentId}` }] },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `Total sales amount: ${document.total.toFixed(2)}` }],
    },
    {
      type: 'text',
      pieces: [{ text: `incl VAT ${rate}% ${document.vatTotal.toFixed(2)}` }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `REFUND AMOUNT: ${document.vatTotal.toFixed(2)}` }],
    },
    blankLine,
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: 'NO STAMP = NO REFUND' }],
    },
    blankLine,
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: `Phone: ${employee?.phone ?? 'No phone available'}` }],
    },
    blankLine,
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: `Email: ${employee?.email ?? 'No email available'}` }],
    },
  ];

  return dispatch(printPatchscript(patchScript));
};

export const handleShopCopyPrint = (
  {
    passportNumber,
    firstName,
    lastName,
    domicile,
    numberOfPackages,
    documentId,
  }: Fields,
  document: SaleDocumentResponse,
) => async (
  dispatch: ThunkDispatch<unknown, unknown, Action>,
  getState: () => unknown,
) => {
  const blankLine: RItem = { type: 'text', pieces: [{ text: ' ' }] };
  const [vatrate] = document.vatTotalsByTaxRate;
  const rate = getVatRateByID(vatrate.vatrateID)(getState())?.rate;

  const patchScript: PatchScript = [
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: 'SHOP COPY' }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: 'RETAILER DETAILS' }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: document.pointOfSaleName }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `Date of issue: ${format(new Date(), 'yyyy-MM-dd')}` }],
    },
    {
      type: 'text',
      pieces: [{ text: 'CUSTOMER DETAILS' }],
    },
    {
      type: 'text',
      pieces: [{ text: `PASSPORT/ID NO: ${passportNumber}` }],
    },
    {
      type: 'text',
      pieces: [{ text: `FULL NAME: ${firstName} ${lastName}` }],
    },
    {
      type: 'text',
      pieces: [{ text: `PERMANENT HOME ADDRESS: ${domicile}` }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `DESCRIPTION OF GOODS: ${document.currencyCode}` }],
    },
    {
      type: 'text',
      pieces: [{ text: 'Goods according to receipt(s)' }],
    },
    {
      type: 'text',
      pieces: [{ text: `No. of packages: ${numberOfPackages}` }],
    },
    {
      type: 'text',
      pieces: [{ text: `Receipt number: ${documentId}` }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `Total sales amount: ${document.total.toFixed(2)}` }],
    },
    {
      type: 'text',
      pieces: [{ text: `incl VAT ${rate}%: ${document.vatTotal.toFixed(2)}` }],
    },
    blankLine,
    {
      type: 'text',
      pieces: [{ text: `REFUND AMOUNT: ${document.vatTotal.toFixed(2)}` }],
    },
    blankLine,
    {
      type: 'text',
      align: 'center',
      pieces: [{ text: 'NOT VALID FOR REFUND' }],
    },
  ];
  return dispatch(printPatchscript(patchScript));
};
