import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Button, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';

import { PosPlugin } from 'plugins/plugin';
import { getPluginConfiguration } from 'reducers/Plugins';
import { SuretaxApi } from 'plugins/suretax/api/SuretaxApi';

import * as meta from './meta';

export const CompanyConfiguration: Required<
  PosPlugin<meta.Configuration>
>['ComponentConfigurationByLevel']['Company'] = ({ current, save }) => {
  const [state, setState] = useState(current);
  useEffect(() => setState(current), [current]);

  const isModified = !R.equals(state, current);

  return (
    <>
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="API endpoint"
        value={state?.endpoint}
        onChange={e => setState(R.assoc('endpoint', e.target.value))}
      />
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="Client number"
        value={state?.clientNumber}
        onChange={e => setState(R.assoc('clientNumber', e.target.value))}
      />
      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        label="Validation key"
        type="password"
        value={state?.validationKey}
        onChange={e => setState(R.assoc('validationKey', e.target.value))}
      />

      <Button
        variant="contained"
        color="primary"
        disabled={!isModified}
        onClick={() => state && save(state)}
      >
        Save
      </Button>
    </>
  );
};

const getConf = getPluginConfiguration<meta.Configuration>(meta.pluginID);
export const getSuretaxApi = createSelector(
  getConf,
  conf => new SuretaxApi(conf.endpoint, conf.clientNumber, conf.validationKey),
);
