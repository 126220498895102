import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import uuidv4 from 'uuid/v4';

import { modalPages as modals } from 'constants/modalPage';
import { previousModalPage } from 'actions/ModalPage/previousModalPage';
import { closeModalPage } from 'actions/ModalPage/closeModalPage';
import CloseButton from 'components/CustomButtons/CloseButton';
import { getLastProductIndex } from 'reducers/ShoppingCart';
import { getDefaultCurrency } from 'reducers/configs/settings';
import { RootState } from 'reducers';
import { addProduct } from 'actions/ShoppingCart/addProduct';


import { addViiProductData } from '../redux/actions';
import { ViiGiftCard } from '../types';
import { useShortcut } from 'utils/hooks/keyboard/useShortcut';

type Props = {
  cardNumber: string;
  productCard: ViiGiftCard;
};

const ViiGiftCardAmountInputModal: React.FC<Props> = ({
  cardNumber,
  productCard,
}) => {
  const dispatch: ThunkDispatch<RootState, unknown, Action> = useDispatch();
  const latestOrderIndex = useSelector(getLastProductIndex);

  const defaultCurrency: string = useSelector(getDefaultCurrency);

  const amountRegex = /^(-?\d*\.?\d+)$/;

  const [inputAmount, setInputAmount] = useState('');

  const handleSearch = async () => {
    // Do nothing if there's an error
    if (!!helperText()) {
      return;
    }
    const idToUse = uuidv4();
    dispatch(
      addViiProductData({
        orderIndex: (latestOrderIndex ?? 0) + 1,
        vii: {
          CardNumber: cardNumber,
          Amount: Number(inputAmount).toFixed(2),
          ExternalReference: idToUse,
          viiTranId: idToUse,
        },
      }),
    );
    await dispatch(
      addProduct(
        {
          productID: productCard.productID,
          giftCardSerial: cardNumber,
          amount: Number(inputAmount) > 0 ? 1 : -1,
          price: Number(inputAmount)
            .toFixed(2)
            .replace('-', ''),
        },
        {
          shouldMerge: false,
          openPOE: false,
        },
      ),
    );
    dispatch(closeModalPage('ViiGiftCardInputModal'));
    return dispatch(closeModalPage(modals.giftCardSerial));
  };

  useShortcut('Enter', () => {
    handleSearch();
  }, 101);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current?.focus?.();
  }, []);

  const helperText = () => {
    if (!inputAmount) {
      return 'No amount specified';
    }
    if (!amountRegex.test(inputAmount)) {
      return 'Amount must be numeric';
    }
    if (
      Math.abs(Number(inputAmount)) < 20 ||
      Math.abs(Number(inputAmount)) > 999
    ) {
      return 'Amount must be between 20 and 999 (inclusive)';
    }
    return undefined;
  };

  return (
    <Box padding="1rem">
      <Box display="flex" alignItems="center" marginBottom="0.75rem">
        <Box>
          <Typography variant="h5">
            {`Enter ${productCard.name ?? 'Vii Giftcard'} Amount`}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" flex={1}>
          <Button
            disabled={!!helperText()}
            onClick={handleSearch}
            variant="contained"
            color="secondary"
            style={{ alignItems: 'flex-end', position: 'relative' }}
          >
            SAVE
          </Button>
          <CloseButton action={() => dispatch(previousModalPage())} />
        </Box>
      </Box>
      <TextField
        fullWidth
        variant="outlined"
        inputRef={inputRef}
        autoFocus={true}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              Giftcard Load Amount
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{defaultCurrency}</InputAdornment>
          ),
        }}
        value={inputAmount}
        onChange={e => setInputAmount(e.target.value?.replace(',', '.'))}
        error={!!helperText()}
        helperText={helperText()}
      />
    </Box>
  );
};

export default ViiGiftCardAmountInputModal;
