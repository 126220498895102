import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

import UIButton from 'components/UIElements/UIButton';
import InputField from 'components/FieldTypes/InputField';

import { Configuration } from './types';

import {
  getAfterSaleAction,
  getDiscountAsPercentage,
  getOpenCustomerFormOnCreate,
} from '.';

type WorkflowConfig = Pick<
  Configuration,
  'rowDiscountAsPercentage' | 'afterSaleAction' | 'openCustomerFormOnCreate'
>;

export const WorkflowConfig: FC<{
  config: WorkflowConfig;
  save: (nc: WorkflowConfig) => void;
}> = ({ config, save }) => {
  const { t } = useTranslation('customerDisplay');
  const rowDiscountAsPercentage = useSelector(getDiscountAsPercentage);
  const afterSaleAction = useSelector(getAfterSaleAction);
  const openCustomerFormOnCreate = useSelector(getOpenCustomerFormOnCreate);

  const [workflowConfig, setWorkflowConfig] = useState(config);

  const workflowConfigOnChange = e => {
    const { name, value } = e.target;
    setWorkflowConfig({
      ...workflowConfig,
      [name]: value,
    });
  };

  const tempConf: WorkflowConfig = useMemo(() => {
    return R.mergeRight(
      {
        rowDiscountAsPercentage,
        afterSaleAction,
        openCustomerFormOnCreate,
      },
      workflowConfig,
    );
  }, [
    afterSaleAction,
    rowDiscountAsPercentage,
    openCustomerFormOnCreate,
    workflowConfig,
  ]);

  const afterSaleOptions = [
    { name: t('options.afterSale.nothing'), value: 'nothing' },
    { name: t('options.afterSale.email'), value: 'email' },
    { name: t('options.afterSale.receiptQR'), value: 'receiptQR' },
    { name: t('options.afterSale.customQR'), value: 'customQR' },
  ];
  return (
    <>
      <h4>{t('titles.workflowConfig')}</h4>
      <InputField
        value={tempConf?.rowDiscountAsPercentage}
        name="rowDiscountAsPercentage"
        type="checkbox"
        onChange={workflowConfigOnChange}
      >
        {t(`titles.showRowDiscountAsPercentage`)}
      </InputField>
      <InputField
        value={tempConf?.openCustomerFormOnCreate}
        name="openCustomerFormOnCreate"
        type="checkbox"
        onChange={workflowConfigOnChange}
      >
        {t(`titles.openCustomerFormOnCreate`)}
      </InputField>
      <InputField
        value={tempConf?.afterSaleAction}
        name="afterSaleAction"
        type="select"
        title={t(`titles.afterSaleChoice`)}
        options={afterSaleOptions}
        onChange={workflowConfigOnChange}
      />
      <UIButton
        disabled={R.equals(config, tempConf)}
        action={() => save(workflowConfig)}
        text={t('buttons.saveWorkflow')}
      />
      <hr />
    </>
  );
};
