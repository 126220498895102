import { PosPlugin } from 'plugins/plugin';
import { getSetting } from 'reducers/configs/settings';

export const applyDefaultLanguage: Required<
  PosPlugin
>['onSaveCustomer']['on'] = (_p, ap) => async (_dispatch, getState) => {
  if ('language' in ap.params) return ap;
  const defaultLanguage = getSetting('pos_default_language')(getState());
  return {
    ...ap,
    params: {
      ...ap.params,
      language: ['en', 'fr'].includes(defaultLanguage.isoCode)
        ? defaultLanguage.isoCode
        : 'en',
    },
  };
};
